import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { PendsAttemptHistory } from "./pends-attempt-history.model";

@Injectable()
export class InternalPendsDetailAttemptHistoryService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getPendGdFromPath(): string {
    return this.route.snapshot.parent.params.pendGd;
  }

  getAttemptHistoryList(): Observable<PendsAttemptHistory[]> {
    const chasePendId = +this.getPendGdFromPath();
    const url = `${this.baseApiUrl}Pend/AttemptHistory?chasePendId=${chasePendId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "PendsAttemptHistory"))
    );
  }
}
