import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { CheckboxValidateComponent } from "../../../../../../../shared/checkbox-validate/checkbox-validate.component";
import { GridPipeName } from "../../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { GridRequest } from "../../../../../../../shared/grid/models/grid-request.model";
import { DateFormats } from "../../../../../../../utilities/contracts/helper-types";
import { MemberWithChases } from "../../../../psr/address-detail/address-detail-info/member-with-chases.model";
import { AddressDetailStateService } from "../../../address-detail-state.service";
import { RetrievalAddressDetailInfoService } from "../../address-detail-info.service";

@Component({
  selector: "retrieval-address-detail-info-grids-members",
  templateUrl: "./address-detail-info-grids-members.component.html",
  styleUrls: ["./address-detail-info-grids-members.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsMembersComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  memberChaseGridConfiguration = new GridConfiguration();
  membersGridConfiguration = new GridConfiguration();
  membersGridRequest: GridRequest;
  expandedRows = {};
  nonActionableChasesChecked = false;
  memberGridDataAll: any[] = [];
  memberGridData: any[] = [];
  memberGridDataRequestInitiated = false;
  @ViewChild("isValidCheckbox", { static: true }) isValidCheckbox: TemplateRef<CheckboxValidateComponent>;

  constructor(
    private service: RetrievalAddressDetailInfoService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        if (state.hasMasterDocumentSourceId && !this.memberGridDataRequestInitiated) {
          this.memberGridDataRequestInitiated = true;
          this.service
            .getMembers(state.masterDocumentSourceId)
            .subscribe(items => {
              this.memberGridDataAll = items as any;
              items.forEach(item => {
                this.expandedRows[item.id] = 1;
              });
              this.memberChaseGridConfiguration.expandedRows = this.expandedRows;
              this.memberGridData = this.getMemberGridData();

              this.changeDetector.markForCheck();
            });
        }
      })
    );
    this.createGrids();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  createGrids(): void {
    this.membersGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "id", header: "Member ID" }),
        new GridColumnDefinition({ field: "name", header: "Name" }),
        new GridColumnDefinition({ field: "dob", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "gender", header: "Gender" }),
        new GridColumnDefinition({ field: "chases", header: "Chases" }),
        new GridColumnDefinition({ field: "projectCount", header: "Projects" }),
        new GridColumnDefinition({ header: "Validation", template: this.isValidCheckbox, isSortableColumn: false }),
      ],
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      rowExpandMode: "multiple",
      showActionColumn: false,
      showMenu: false,
      showRowExpansionColumn: true,
      trackByField: "id",
      expandedRows: this.expandedRows,
    });

    this.memberChaseGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
        new GridColumnDefinition({ field: "projectName", header: "Project" }),
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "chartAppointmentDate", header: "Scheduled Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      ],
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      showActionColumn: false,
      showMenu: false,
    });
  }

  getMemberGridData(): any[] {
    if (this.nonActionableChasesChecked) {
      return this.memberGridDataAll;
    } else {
      const excludeDisabledChases = this.memberGridDataAll.map(a => ({ ...a }));
      excludeDisabledChases.forEach(x => {
        const chases = x.chaseList.filter(c => !c.disabled);
        return x.chaseList = chases;
      });
      return excludeDisabledChases;
    }
  }

  saveMemberValidation(event: any, memberData: MemberWithChases): void {
    memberData.isVerified = event.checked;
    this.service.saveRetrievalMemberValidation(memberData).subscribe();
  }
}
