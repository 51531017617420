import { ChangeDetectorRef, Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class HighlightService {
  private focus: {[key: string]: boolean} = {};
  private timeout: {[key: string]: any} = {};
  readonly BLUR_WAIT_TIME = 200;
  onBlurGroup = new Subject<number>();


  constructor(private changeDetector: ChangeDetectorRef) { }


  hasFocus(index: number = null): boolean {
    const name = this.getPropertyName(index);
    return this.focus[name];
  }

  className(index: number = null): any {
    return {
      "control--focus": this.hasFocus(index) ,
    };
  }

  onFocus(index: number = null): void {
    if (this.hasFocus(index)) {
      clearTimeout(this.getTimeout(index));
    }

    this.setFocus(index, true);
  }

  onBlur(index: number = null): void {
    this.setTimeout(index, () => {
      this.setFocus(index, false);
      this.onBlurGroup.next(index);
      this.changeDetector.markForCheck();
    });
  }


  private setFocus(index: number = null, value: boolean): void {
    const name = this.getPropertyName(index);
    this.focus[name] = value;
  }

  private setTimeout(index: number = null, value: () => void): void {
    const name = this.getPropertyName(index);
    this.timeout[name] = window.setTimeout(value, this.BLUR_WAIT_TIME);
  }

  private getTimeout(index: number = null): any {
    const name = this.getPropertyName(index);
    return this.timeout[name];
  }

  private getPropertyName(index: number = null): string {
    return (index || "focus").toString();
  }
}
