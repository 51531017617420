import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { List } from "immutable";
import { timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ListItem } from "../../../../shared/list/list-item";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { EmailNotification } from "../data-load-email-notification.model";
import { DataLoadState } from "../data-load-state.model";
import { DataLoadService } from "../data-load.service";

@Component({
  selector: "app-data-load-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  dataLoadId: number;
  dataLoadState: DataLoadState;
  isProcessingVisible = false;
  isLoadComplete = false;
  summaryReport = List<ListItem>();
  isUploadSuccess = false;
  dataLoadOption: string;
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private dataLoadService: DataLoadService,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.sink.add(
      this.dataLoadService.state.subscribe(state => {
        this.dataLoadState = state;
        this.changeDetector.markForCheck();
      })
    );

    this.getSummaryReport(this.currentDataLoadId);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get currentDataLoadId(): number {
    return this.dataLoadState.dataLoadId;
  }

  get isLoadProcess(): boolean {
    return this.dataLoadState.isLoadProcess;
  }

  get isReportLoaded(): boolean {
    return NumberHelper.isGreaterThan(this.summaryReport.size, 0);
  }

  get summaryHeader(): string {
    return this.isLoadProcess ? "IMPORT SUMMARY" : "DATA LOAD SUMMARY";
  }

  get validationStatusMessage(): string {
    if (this.isDataLoadNoWarningsEnabled) {
      return "PASSED";
    } else if (this.isDataLoadWithWarningsEnabled) {
      return "PASSED WITH WARNING";
    } else if (this.isPartialDataLoadNoWarningsEnabled || this.isPartialDataLoadWithWarningsEnabled) {
      return "PARTIALLY FAILED";
    } else if (this.isDataLoadDisabled) {
      return "FAILED";
    }
  }

  get totalChasesPassedToLoad(): number {
    return this.isReportLoaded ? Number(this.summaryReport.find(x => x.key === "Total Chases Passed Validation").value) : 0;
  }

  get loadChasesText(): string {
    return this.isPartialDataLoadNoWarningsEnabled ?
      `LOAD ${this.totalChasesPassedToLoad} CHASES WITH WARNINGS`
      : `LOAD ${this.totalChasesPassedToLoad} CHASES WITH WARNINGS`;
  }

  // get dataLoadOption(): string {
  //  return this.isReportLoaded ? this.summaryReport.find(x => x.key.toLowerCase() === "data load option").value : "";
  // }

  get isDataLoadNoWarningsEnabled(): boolean {
    if (!StringHelper.isAvailable(this.dataLoadOption)) {
      return false;
    }

    return this.dataLoadOption.toLowerCase() === "data load enabled";
  }

  get isDataLoadWithWarningsEnabled(): boolean {
    if (!StringHelper.isAvailable(this.dataLoadOption)) {
      return false;
    }

    return this.dataLoadOption.toLowerCase() === "data load enabled with warnings";
  }

  get isPartialDataLoadNoWarningsEnabled(): boolean {
    if (!StringHelper.isAvailable(this.dataLoadOption)) {
      return false;
    }

    return this.dataLoadOption.toLowerCase() === "partial data load enabled";
  }

  get isPartialDataLoadWithWarningsEnabled(): boolean {
    if (!StringHelper.isAvailable(this.dataLoadOption)) {
      return false;
    }

    return this.dataLoadOption.toLowerCase() === "partial data load enabled with warnings";
  }

  get isDataLoadDisabled(): boolean {
    if (!StringHelper.isAvailable(this.dataLoadOption)) {
      return false;
    }

    return this.dataLoadOption.toLowerCase() === "data load disabled";
  }

  get isPartialDataLoadEnabled(): boolean {
    return this.isPartialDataLoadNoWarningsEnabled || this.isPartialDataLoadWithWarningsEnabled;
  }

  get isDataLoadWithIssues(): boolean {
    return this.isDataLoadWithWarningsEnabled
      || this.isPartialDataLoadNoWarningsEnabled
      || this.isPartialDataLoadWithWarningsEnabled
      || this.isDataLoadDisabled;
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);
    return dataList;
  }

  checkProgress(projectId: number, dataLoadId: number): void {
    timer(0, 2000).pipe(
      takeWhile(() => !this.isLoadComplete)
    ).subscribe(() => {
      this.isProcessDone(projectId, dataLoadId);
    });
  }

  getErrorReport(): void {
    const reports: string[] = [
      this.dataLoadService.getDetailErrorReport(this.dataLoadState.dataLoadId, this.dataLoadState.projectName),
      this.dataLoadService.getSummaryErrorReport(this.dataLoadState.dataLoadId, this.dataLoadState.projectName),
    ];
    if (this.isPartialDataLoadEnabled) {
      reports.push(this.dataLoadService.getChaseOmissionFromLoadReport(this.dataLoadState.dataLoadId, this.dataLoadState.projectName));
      if (this.dataLoadState.projectType === "HEDIS" || this.dataLoadState.projectType === "RISK") {
        reports.push(this.dataLoadService.getErrorChasesFromLoadReport(this.dataLoadState.dataLoadId, this.dataLoadState.projectName, this.dataLoadState.projectId));
        this.dataLoadService.getListOfFilesToLoad(this.dataLoadState.projectId).subscribe(data => {
          const diagnosisFile = data.find(x => x.fileTypeName === "Diagnosis" || x.fileTypeName === "Attribute");
          if (diagnosisFile && diagnosisFile.networkFileLocation) {
            reports.push(this.dataLoadService.getErrorDiagnosisFromLoadReport(
              this.dataLoadState.dataLoadId,
              this.dataLoadState.projectName,
              this.dataLoadState.projectId,
              this.dataLoadState.projectType)
            );
          }
          const complianceFile = data.find(x => x.fileTypeName === "Compliance");
          if (complianceFile && complianceFile.networkFileLocation) {
            reports.push(this.dataLoadService.getErrorComplianceFromLoadReport(
              this.dataLoadState.dataLoadId,
              this.dataLoadState.projectName,
              this.dataLoadState.projectId)
            );
          }
        });
      }
    }

    this.downloadReport(reports);
  }

  getSummaryReport(dataLoadId: number): void {
    this.dataLoadService
      .getSummaryReport(dataLoadId)
      .subscribe(result => {
        const summaryReport = result.map(summaryItem => {
          if (summaryItem.key === "Process start date" || summaryItem.key === "Process end date") {
            const formattedDate = DateHelper.formatToLocalTime(summaryItem.value);
            const newItem = new ListItem({ ...summaryItem, value: formattedDate });
            return newItem;
          }
          return summaryItem;
        });

        const summaryReportTemp = this.assignAndNotify(summaryReport);
        this.dataLoadOption = summaryReportTemp.find(s => s.key.toLowerCase() === "data load option").value;
        this.summaryReport = summaryReportTemp.filterNot(s => s.key.toLowerCase() === "data load option").toList();

        this.summaryReport = this.summaryReport.push(
          new ListItem({ key: "Validation Status", value: this.validationStatusMessage, url: "" })
        );
        this.changeDetector.markForCheck();
      });
  }

  getValidationMessageClass(value: string): string {
    switch (value) {
      case "FAILED":
      case "PARTIALLY FAILED":
        return "summary-body__details--danger";
        break;
      case "PASSED WITH WARNING":
      case "PASSED":
        return "summary-body__details--pass";
        break;
      default:
        return "summary-body__details";
        break;
    }
  }

  loadFile(projectId: number, dataLoadId: number, dataLoadType: string): void {
    const processMode = this.isPartialDataLoadEnabled ? "Partial Load" : "Load";
    this.dataLoadService
      .loadData(projectId, dataLoadId, dataLoadType, processMode)
      .subscribe(
        result => {
          if (NumberHelper.isGreaterThan(result, 0)) {
            this.messagingService.showToast("Your request is being processed and you will be alerted when the process will be completed", SeverityType.SUCCESS);
            this.checkProgress(projectId, dataLoadId);
          }
        },
        err => {
          this.isLoadComplete = true;
        });
  }

  startDataLoad(): void {
    this.isLoadComplete = false;
    this.dataLoadId = NumberHelper.randomWholeNumber(1000000000, 2000000000);
    this.dataLoadService.setState({
      dataLoadId: this.dataLoadId,
    });
    this.isProcessingVisible = true;
    this.loadFile(this.dataLoadState.projectId, this.dataLoadId, this.dataLoadState.loadType);
  }

  trackByIndex(index, item) {
    return index;
  }

  private downloadReport(reports: string[]) {
    let i = 0;
    const intervalId = setInterval(() => {
      if (i === reports.length) {
        clearInterval(intervalId);
      }
      window.open(reports[i], "_self");
      i++;
    },                             7000);
  }

  private isProcessDone(projectId: number, dataLoadId: number): void {
    this.dataLoadService.isProcessDone(projectId, dataLoadId)
      .subscribe(result => {
        this.isLoadComplete = result;
        if (this.isLoadComplete) {
          this.isProcessingVisible = false;
          this.isUploadSuccess = true;
          this.getSummaryReport(this.currentDataLoadId);
          this.sendEmailNotification();
          this.dataLoadService.setState({
            isLoadProcess: !this.isLoadComplete,
          });
        }
        this.changeDetector.markForCheck();
      });
  }

  private sendEmailNotification(): void {
    const emailNotification = new EmailNotification({
      projectId: this.dataLoadState.projectId,
      projectName: this.dataLoadState.projectName,
      loadDate: this.summaryReport.find(x => x.key === "Load Date").value,
      totalChases: parseInt(this.summaryReport.find(x => x.key === "Total Chase Records")?.value, 10),
    });

    this.dataLoadService
      .sendEmailNotification(emailNotification)
      .subscribe();
  }
}
