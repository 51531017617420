import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { MessagingService } from "../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../../../../core/navigation/parameter.service";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../../utilities/contracts/string-helper";
import { VALIDATION_REASON_CODES } from "../../../../../chase-detail/chase-detail-chart/attributes";
import { CodingModel } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/coding-model.model";
import { Diagnosis } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { RiskEntity } from "../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskHelper } from "../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { DocumentViewerSessionService } from "../../../../document-viewer-session.service";

@Injectable({
  providedIn: "root",
})
export class DiagnosisService {
  private newDiagnosis = new BehaviorSubject<string>("");
  private isDiagnosisSelected = new BehaviorSubject<boolean>(false);
  private chaseId: number;
  private diseaseDetails = new Map<string, DiseaseDetail[]>();

  vrcForDiagnoses: string[] = [];
  private tabSelected = new Subject<string>();

  newDiagnosis$ = this.newDiagnosis.asObservable();
  isDiagnosisSelected$ = this.isDiagnosisSelected.asObservable();
  tabSelected$ = this.tabSelected.asObservable();

  vrc108Message = "VRC 108 has been appended, ensure this is a Medicare Program & Mental Health Diagnosis before submitting.  If not Medicare program, please remove VRC108.";


  constructor(
    private readonly riskService: RiskService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly parameterService: ParameterService,
    private messagingService: MessagingService,
    private readonly documentViewerSessionService: DocumentViewerSessionService) {
    this.activatedRoute.paramMap.subscribe(params => {
      if (this.chaseId !== +params.get("chaseGd")) {
        this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
      }
    });
  }


  selectedTab(tab: string): void {
    this.tabSelected.next(tab);
  }

  addNewDiagnosis(riskEntity: RiskEntity): void {
    this.newDiagnosis.next(riskEntity.id);
  }

  resetNewDiagnosis(): void {
    this.newDiagnosis.next("");
  }

  removeDiseaseDetail(diagnosisId: string): void {
    this.diseaseDetails.delete(diagnosisId);
  }

  getDiseaseDetail(diagnosisId: string): DiseaseDetail {
    const diseaseDetail = this.getDiseaseDetails(diagnosisId);
    return diseaseDetail[0];
  }

  getDiseaseDetails(diagnosisId: string): DiseaseDetail[] {
    return this.diseaseDetails.get(diagnosisId) ?? [];
  }

  getHccForEve$(diagnosis: Diagnosis): Observable<DiseaseDetail[]> {
    const diseaseDetails: DiseaseDetail[] = this.getDiseaseDetails(diagnosis.id);

    if (this.hasDiseaseDetails(diagnosis.id) && ArrayHelper.isAvailable(diseaseDetails)) {
      return of(diseaseDetails);
    }

    diagnosis.codingModels
      .map(model => diseaseDetails.push
        .apply(this.setDiseaseDetails(model.values, diagnosis.diseaseName, diagnosis.icd, model.codingModel)));

    this.addDiseaseDetail(diagnosis.id, diseaseDetails);
    return of(diseaseDetails);
  }

  getHccForAdmin$(diagnosis: Diagnosis): Observable<DiseaseDetail[]> {
    const icdCode = diagnosis.icd.replace(".", "");
    const dateOfService = diagnosis.endDate;

    return this.getHcc$(diagnosis.id, icdCode, dateOfService);
  }

  updateDataEntryPageNumber(pageNumber: number, validationResult: string): void {
    if (NumberHelper.isAvailable(pageNumber) && StringHelper.isAvailable(validationResult)) {
      this.documentViewerSessionService.updateDataEntryPageNumber(pageNumber);
    }
  }

  toggleDiagnosisSelected(isOpen: boolean): void {
    this.isDiagnosisSelected.next(isOpen);
  }

  waitForElement(selector: string): Promise<HTMLElement> {
    return new Promise(resolve => {
      if (document.getElementById(selector)) {
        return resolve(document.getElementById(selector));
      }

      // This observer will resolve when element is created
      const observer = new MutationObserver(_ => {
        if (document.getElementById(selector)) {
          resolve(document.getElementById(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  checkIfPartOfVrcRules(vrc: string, vrcRules: string[]): boolean {
    return vrcRules.some(val => vrc === val);
  }

  checkIfPartOfVrcRulesInAllDxs(vrc: string, arr: string[]): boolean {
    let isVrcPartOfDx = true;
    for (const listOfVrc of arr) {
      const vrcForDiagnosis = listOfVrc.split(",");
      isVrcPartOfDx = vrcForDiagnosis.includes(vrc);
      if (!isVrcPartOfDx) {
        break;
      }
    }

    return isVrcPartOfDx;
  }

  checkIfVRCMatches(riskState: RiskState, vrcRules: string[]): boolean {
    let isVRCMatched = true;
    this.vrcForDiagnoses = [];

    const selectedEncounterDiagnoses = riskState.selectedEncounter.diagnoses;
    const diagnosesWithVRCAttribute = selectedEncounterDiagnoses.filter(riskEntity => {
      const VRCAttribute = {
        ...RiskHelper.getAttribute(VALIDATION_REASON_CODES.attributeCode, riskEntity),
      };
      return !ObjectHelper.isEmpty(VRCAttribute);
    });

    this.vrcForDiagnoses = diagnosesWithVRCAttribute.map(riskEntity => {
      const VRCAttribute = {
        ...RiskHelper.getAttribute(VALIDATION_REASON_CODES.attributeCode, riskEntity),
      };
      if (!ObjectHelper.isEmpty(VRCAttribute)) {
        return VRCAttribute.value.toString();
      }
    });

    for (const dxs of this.vrcForDiagnoses) {
      const vrcForDiagnosis = dxs.split(",");
      for (const dx of vrcForDiagnosis) {
        if (this.checkIfPartOfVrcRules(dx, vrcRules)) {
          isVRCMatched = this.checkIfPartOfVrcRulesInAllDxs(dx, this.vrcForDiagnoses);
          if (!isVRCMatched) { break; }
        }
      }
      if (!isVRCMatched) { break; }
    }

    return isVRCMatched;
  }

  checkIfVRC108Selected(vrcInputValue) {
    const vrcInputHasValues = ArrayHelper.isAvailable(vrcInputValue);
    if (vrcInputHasValues && vrcInputValue.some(data => data.value === "108")) {
      this.messagingService.showMessage(this.vrc108Message, SeverityType.ERROR);
    }
    return vrcInputHasValues;
  }

  displayCodingModelLabel(diagnosis: Diagnosis): string {
    const codingModels = ArrayHelper.isAvailable(diagnosis.codingModels) ?
      diagnosis.codingModels :
      this.createCodingModelFromAttributes(diagnosis.hccs, diagnosis.rxHcc);

    if (!ArrayHelper.isAvailable(codingModels)) {
      return "";
    }

    return codingModels
      .map(model => `${model.codingModel.toUpperCase()}: ${model.values?.join(", ")}`)
      .join("<br/>")
      .concat("<br/>");
  }

  private createCodingModelFromAttributes(hccAttribute:  string, rxHccAttribute: string): CodingModel[] {
    const codingModelsToReturn: CodingModel[] = [];

    if (StringHelper.isAvailable(hccAttribute)) {
      const newCodingModels = this.deconstructAttributeIntoCodingModel(hccAttribute);
      codingModelsToReturn.push(...newCodingModels);
    }

    if (StringHelper.isAvailable(rxHccAttribute)) {
      const newCodingModels = this.deconstructAttributeIntoCodingModel(rxHccAttribute);
      codingModelsToReturn.push(...newCodingModels);
    }

    return codingModelsToReturn;
  }

  private deconstructAttributeIntoCodingModel(attribute:  string): CodingModel[] {
    const codingModels = attribute
      .split(";")
      .filter(Boolean);

    return codingModels.map(codingModel => {
      const codingModelValues = codingModel.split(":");
      return new CodingModel(
        codingModelValues[0],
        codingModelValues[1]?.split(",")
      );
    });
  }

  displayHccLabel(diseases: DiseaseDetail[]): string {
    const groupedDiseases = diseases
      .filter(diseaseDetail => StringHelper.isAvailable(diseaseDetail.categoryCode))
      .reduce(
        (acc, {modelType, categoryCode}) => {
          if (!acc[modelType]) {
            acc[modelType] = [];
          }
          acc[modelType].push(categoryCode);
          return acc;
        },
        {});

    return ObjectHelper.isEmpty(groupedDiseases) ? "" :
      Object.keys(groupedDiseases)
        .map(modelType => `${modelType.toUpperCase()}: ${groupedDiseases[modelType]?.join(", ")}`)
        .join("<br/>")
        .concat("<br/>");
  }

  private getHcc$(diagnosisId: string, icdCode: string, dateOfService: Date): Observable<DiseaseDetail[]> {
    const existingDiseases = this.getDiseaseDetails(diagnosisId);

    if (this.hasDiseaseDetails(diagnosisId) && ArrayHelper.isAvailable(existingDiseases)) {
      return of(existingDiseases);
    }

    if (NumberHelper.isGreaterThan(this.chaseId, 0) && StringHelper.isAvailable(icdCode) && DateHelper.isAvailable(dateOfService)) {
      return this.riskService.getDiseaseDetails(this.chaseId, icdCode, dateOfService)
        .pipe(
          filter(diseaseDetails => ArrayHelper.isAvailable(diseaseDetails)),
          map(diseaseDetails => {
            this.addDiseaseDetail(diagnosisId, diseaseDetails);
            return diseaseDetails;
          })
        );
    }

    return of([]);
  }

  private setDiseaseDetails(collection: string[], diseaseName: string, icdCode: string, modelType: string): DiseaseDetail[] {
    return collection.map(categoryCode => new DiseaseDetail({
      categoryCode: categoryCode.toString(),
      modelType,
      diseaseName,
      icdCode,
    }));
  }

  private addDiseaseDetail(diagnosisId: string, details: DiseaseDetail[]): void {
    this.diseaseDetails.set(diagnosisId, [...details]);
  }

  private hasDiseaseDetails(diagnosisId: string): boolean {
    return this.diseaseDetails.has(diagnosisId);
  }
}
