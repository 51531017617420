<div [formGroup]="formGroup" class="control control--input control--signin-user-dropdown" [hidden]="hidden">
  <p-dropdown #signInUserDropdown
              [hidden]="!hasOptions"
              dropdownIcon="pi pi-caret-down"
              [styleClass]="classes"
              [readonly]="model.readonly"
              [options]="options"
              [placeholder]="model.placeholder"
              [name]="model.key"
              [inputId]="model.key"
              (onChange)="onChange.emit($event)"
              (onInput)="onInput.emit($event)"
              (onFocus)="onFocus.emit($event)"
              (onBlur)="onBlur.emit($event)"
              [formControlName]="model.key">
    <ng-template let-item pTemplate="selectedItem">
      <span class="option-orgName">{{ item.extra }}</span>: 
      <span class="option-username--selected">{{ item.text }}</span>
    </ng-template>
    <ng-template let-items pTemplate="item">
      <span class="option-orgName">{{ items.extra }}</span>:
      <span class="option-username">{{ items.text }}</span>
    </ng-template>
  </p-dropdown>
  <div *ngIf="!hasOptions" class="text--danger">Information Not Available.</div>
</div>







