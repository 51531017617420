import { SafeResourceUrl } from "@angular/platform-browser";
import { IAutoMapper } from "../../core/automapper/interfaces";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapProviderPacketLogoResponseModel = (automapper: IAutoMapper): void => {

  automapper
    .createMap("default", "ProviderPacketLogoResponseModel")
    .forMember("providerPacketLogo", o => o.providerPacketLogo)
    .forMember("fileName", o => o.fileName);

};

export class ProviderPacketLogoResponseModel {
  providerPacketLogo: SafeResourceUrl;
  fileName: string;

  constructor(options: {
    providerPacketLogo: string;
    fileName: string;
  }) {
    this.providerPacketLogo = options.providerPacketLogo;
    this.fileName = StringHelper.clean(options.fileName);
  }
}
