<b>1. Our Commitment to Privacy</b><br /><br />
Privacy is highly important to Health Data Vision, Inc. (d/b/a Reveleer)(“<b>Reveleer</b>”, “<b>Us</b>” or
“<b>We</b>”).<br /><br />
This Privacy Policy (this “<b>Policy</b>”) sets out the key elements of how we address the privacy and security
of information entrusted to us by our customers, customers and agents who access and use our platform of online
services and products (collectively, the “<b>Services</b>”), as well as the privacy of information entrusted to
us by business partners, prospects and others who seek information and/or contact us through www.reveleer.com
(individually, collectively, and including any subdomains, the “<b>Site</b>”). The Site can be used and accessed
by the public as a source of general information about Reveleer. Our customers and business partners also can
access the Services via the Site.<br /><br />
As privacy laws and practices evolve, we will amend this Policy from time to time. While we will endeavor to
give reasonable notice of such changes, we do reserve the right, where necessary, to do so without prior
notice.<br /><br />
<b>2. What is considered private?</b><br /><br />
Information that is used by a government authority, financial institution or insurance carrier to distinguish a
person from other individuals (e.g., social security number, social insurance number, credit card information,
or insurance policy number) is private. Such information can be used to identify an individual. Certain
information may be used to contact a person directly (e.g., an email address, home mailing address or telephone
number). Accordingly, “<b>Personal Information</b>” means information that can be connected to a particular
person through certain identifiers, including: name, physical address, driver’s license, state identification
card, Social Security number (SSN), account number, credit/debit card, passport or alien registration numbers —
among other items. Personal Information does NOT include publicly available information that is lawfully made
available to the general public from federal, state or local government records or widely distributed media.
“<b>PHI</b>” is any Personal Information collected from an individual, transmitted or maintained in any form or
medium that: (i) is created or received by Reveleer, a healthcare provider, health plan, employee, subcontractor
or healthcare clearing house; and (ii)relates to the past, present or future physical or mental health or
condition of an individual or the provision of healthcare to an individual, and that identifies the individual
or with respect to which there is a reasonable basis to believe that the information can be used to identify the
individual. If we wish to refer only to information about a specific individual’s health but not to other forms
of Personal Information, we will refer to “<b>PHI</b>” herein.<br /><br />
In the United States, the laws that primarily govern how we deal with the Personal Information and PHI which you
provide to us in relation to the Services are described in Section 8 below. An individual’s business contact
information and business title generally are exempt from privacy laws.<br /><br />
This Policy also will apply to non-personal information if such information can be used in combination with
other Personal Information or non-personal information to identify an individual.<br /><br />
Please be aware that this Policy only covers information manually submitted to, or automatically collected by,
us through use of the Site and/or the Services. If you contact or exchange information with another Reveleer
customer or business partner in person or through a means other than through the Site or Services, such activity
is not covered by this Policy. Additionally, if you are not a customer or a business partner of Reveleer by way
of written agreement, and are contacting us out of interest in the Services, a business partnership or a job
opportunity, please be aware that the information that you share with us is not covered by this Policy, unless
required by law.<br /><br />
<b>3. Personal Information Collected by the Services</b><br /><br />
There are two ways Personal Information can be submitted to us. The first is through direct submission or what
we call ‘<b>Manual Submission</b>’ and the second is by way of ‘<b>Automatic Submission</b>’ triggered by any
interaction with the Site through a computer or mobile device.<br /><br />
Manual Submission<br /><br />
Personal Information can be submitted to us directly when you communicate with us offline (in person or by
telephone), via email or via the Site (by entering data or uploading files) or when you authorize Reveleer to
access, retrieve and/or import Personal Information from another user or third party on your behalf.
Additionally, if you become a customer of Reveleer, you will be required to register by submitting Personal
Information via the Services, email or offline. This could include name, email address, mailing address,
telephone number(s) and other contact and billing information.<br /><br />
Automatic Submission<br /><br />
Whenever your computer or mobile device visits, logs in or otherwise interacts with the Site, we gather data
from your device and the operating software of your device transmits a ‘request’ to us. That request includes
non-personal information that is necessary to identify and route the information your device is requesting. This
communication is necessary for all website and Internet services.<br /><br />
We also use cookies (sometimes referred to as “web beacons” or “server logs”). Cookies are files that web
browsers place on a computer’s hard drive that tell us whether customers or visitors have been to the Site
previously, and they often include an anonymous unique identifier. For more information about cookies, and how
to disable cookies, visit www.allaboutcookies.org. Data collected using cookies can include:<br /><br />
• Date and time a ‘request’ is transmitted through the Site<br /><br />
• The model of the device making the request<br /><br />
• The type and version of the operating software running on the device<br /><br />
• The web browser used on the device and making the request<br /><br />
• IP address<br /><br />
• Geographic location<br /><br />
• Time zone<br /><br />
• Search terms used<br /><br />
• URLs visited<br /><br />
• Information about some of the cookies that are installed on your computer, mobile device or tablet<br /><br />
• Internet service provider<br /><br />
• Previous activity on the Site<br /><br />
Additionally, we use Google Analytics to track and analyze page usage behavior to improve performance in the use
of the Services and the Site. We use this to track only what page you are clicking on, and do not use it to
track any Personal Information. You can read more about how Google uses your Personal Information here. You can
also opt-out of Google Analytics here.<br /><br />
Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal
from your browser. For greater clarification, while our data collection practices won’t be altered, they also
won’t be applied in situations where you have prevented the tracking from occurring.<br /><br />
<b>4. Purpose</b><br /><br />
Reveleer is a cloud-based Software-as-a-Service (SaaS) platform designed to [empower risk adjustment and quality
teams to gain total control, choice, and transparency over their government programs]. We primarily collect
Personal Information as necessary to communicate with you and/or to provide the Services. Some Personal
Information (but not PHI) also may be collected for marketing and sales purposes (e.g., if you complete a form
to register for a webinar or download content, or if you visit a part of our Site where we deploy cookies from
LinkedIn, Facebook, Twitter, etc. (in which case, their privacy policies will apply).<br /><br />

Personal Information and non-personal information may be collected and used for the following
reasons:<br /><br />

• to register customer accounts<br /><br />
• to contact customers to discuss their experience with the Services, current and future needs as a customer, or
to communicate future promotions or special events which might benefit them<br /><br />
• to contact a prospective customer<br /><br />
• to provide our cloud-hosted SaaS Services<br /><br />
• to operate, maintain, manage and administer the Services, including processing registrations and payments, and
diagnosing technical problems<br /><br />
• to respond to questions and communications<br /><br />
• to make service or administrative announcements to customers about unscheduled downtime or new features,
services, products, functionality, terms, or other aspects of the Services<br /><br />
• to perform audits, research, measurements and analyses in an effort to maintain, administer, support, enhance
and protect the Services, including determining usage trends and patterns and measuring the effectiveness of
content, advertising, features or services<br /><br />
• to create new features, products or services<br /><br />
• to contribute to certain health and medical research (only non-personal information will be used)*<br /><br />
• to provide benchmarking and performance tracking solutions*<br /><br />

*We may track and analyze non-identifying, aggregate usage, and volume statistical information from our visitors
and customers and may provide such information to third parties. We are committed to ensuring privacy and
protecting Personal Information. We also are committed to providing valuable insights and analytics to enable
better performance and quality.
We use cookies to enhance the quality of the Services by, for example:<br /><br />
• saving user preferences<br /><br />
• preserving session settings and activities<br /><br />
• providing limited auto-fill functionality for those who use the Services frequently<br /><br />
• analyzing various features and content of the Services<br /><br />
<b>5. Cookies</b><br /><br />
Reveleer uses a persistent cookie to help save and retrieve org codes for a user who has accessed the Services.
We issue a session cookie only to record encrypted authentication information for the duration of a specific
session. The session cookie does not include the username or password of a customer. For user convenience, in
relation to touchscreen logins, we also use a non-session-based cookie to store a user’s ID; however, this is
configurable. We do not store passwords in session cookies, persistent cookies or headers. If a cookie is
rejected, access to and usage of the Services will be denied. The Site uses cookies and other tracking
technology to give you the best user experience.<br /><br />
We will never sell your Personal Information (or non-personal information if it can be used in any way to
identify you).<br /><br />
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
or for operational, statutory/regulatory or other legal reasons.<br /><br />
<b>6. Support, Education Services and Purchase Orders</b><br /><br />
Personal Information collected through the Site may be accessed and used by Reveleer to respond to customer
requests for support, to provide education or consulting services and/or to confirm customer compliance with the
terms of its purchase (as set forth in signed orders). This may include testing and applying new product or
system versions, patches, updates and upgrades; monitoring and testing system usage and performance; and,
resolving bugs and other issues which a customer reports to Reveleer.<br /><br />
Personal Information collected for these purposes is only used for time periods relevant to fulfill such
purposes.<br /><br />
From time to time, we may ask customers to complete surveys or ratings about the provision of the Services or of
their own health care practices and operations. You should assume that the content of any Personal Information
you provide would not be maintained in confidence. We will, however, tell you why we are collecting your
responses and how they will be used. In completing such surveys, be mindful of what Personal Information is
disclosed. We recommend against sharing any PI, PHI or other sensitive information that could be traced,
directly or indirectly, to any individual.<br /><br />
<b>7. Consent and Authorization</b><br /><br />
By visiting the Site, you are consenting to the use of your Personal Information for the aforementioned
purposes. On occasion, we may request additional consent in connection with the use or sharing of Personal
Information for a purpose not stated in this Policy or because the law requires such consent.<br /><br />
If you are a customer or business partner of Reveleer, we will never use your Personal Information in a manner
not otherwise provided for in our written contracts with you, authorization forms you provide to us, or this
Policy.<br /><br />
<b>8. Protecting Health Information</b><br /><br />
As a provider of [hosted, electronic health record solutions], Reveleer customers are health care providers and
subject to laws and regulations governing the use and disclosure of PHI. In the United States, the Health
Insurance Portability and Accountability Act of 1996 (“HIPAA”), the Health Information Technology for Economic
and Clinical Health of 2009 (“HITECH”), along with the regulations adopted under those statutes, and similar
state laws (where those laws are more stringent than HIPAA) govern the handling of PHI. Other laws may apply
with respect to specific customers, as set forth in our contracts with those customers. Health care providers
are considered to be Covered Entities under HIPAA and are subject to its rules regarding PHI. If a provider
delegates some of its work to a third party, and that party must access PHI in order to perform the work, then
such party is considered by HIPAA to be a Business Associate and is subject to the same rules regarding the
protection of PHI as the Covered Entity. To enforce protection, HIPAA requires Covered Entities to execute a
“Business Associate Agreement” or ”BAA” with each of its Business Associates. Our U.S.-based customers are
required to sign a BAA with us. As a Business Associate, we are required to use reasonable and appropriate
measures to safeguard the confidentiality, integrity and accessibility of PHI that is stored and processed on
behalf of Covered Entities. From time to time, the terms of Reveleer’s standard BAA and/or similar agreements
may be posted on the Site.<br /><br />
<b>9. Sharing Your Personal Information</b><br /><br />
<b>9.1. Third-Party Websites, Software and Services</b><br />
Our Services and Site contain links to third-party websites, software and services. Customers and visitors who
access a linked website via the Site may be disclosing Personal Information. It is the responsibility of the
user to keep Personal Information private and confidential. Additionally, we allow third-parties to offer
services to our customers through integration with the Reveleer MRCS platform (“<b>Connected Services</b>”).
Customers’ use of Connected Services is optional. Customers that choose to use a Connected Service acknowledge
and authorize the transmission of Personal Information to a third party. We are not responsible for, nor can we
control, the privacy practices of third parties. A third party’s use, storage and sharing of your Personal
Information is subject to its own privacy policies and not this Policy.<br /><br />
<b>9.2. Business Reorganizations or New Management</b><br />
There are two situations where we will need to share your Personal Information with a third party as a result of
a business reorganization. The first situation concerns the acquisition of Reveleer by a third party, and the
second concerns the acquisition of our customers. A reorganization involves a sale, merger, transfer, exchange
or other disposition of all or part of a business. If such a transaction occurs, be aware that your Personal
Information may be made available to the acquiring party. If the reorganization concerns one of our customers,
Reveleer requires the parties participating in the sale to show written evidence of the completed transaction,
or some alternate form of written authorization (by both the buyer and the seller), to transfer Personal
Information hosted by the Services from the seller to the buyer. A change in management of a customer facility
could involve similar authorization requirements, if data must be transferred from the prior management company
to the new management company (or to the owner). We will not disclose your Personal Information to a party
without sufficient and proper authorization from you, unless required by law.<br /><br />
<b>9.3. Legal Procedures</b><br />
We may need to preserve, use or disclose your Personal Information in response to a court order, subpoena,
search warrant, judicial proceeding or other legal process, if we have a good faith belief that the law requires
us to do so, or to otherwise protect our rights. Some legal procedures may prohibit or prevent us from notifying
users, other individuals or entities identified in such procedures or may compel us to take measures otherwise
in violation of this Policy or a written agreement you have with us.<br /><br />
Personal Information preserved as a result of legal procedures can be maintained for an indefinite period of
time and for as long as we have a good faith belief that it is necessary and appropriate under the
circumstances. These procedures may also involve your information; for example, if your contractual relationship
with us has been terminated or disabled.<br /><br />
<b>10. Security, Threats and Breach Notification</b><br /><br />
Our Services have physical, administrative and technical security measures in place to protect against the loss,
misuse, unauthorized access and alteration of data and Personal Information under our direct control. When the
Services are accessed using current browser technology, Secure Socket Layer (“<b>SSL</b>”) technology protects
information using both server authentication and data encryption to help ensure that data is safe, secure, and
available only to you. Reveleer also implements an advanced security methodology based on dynamic data and
encoded session identifications, and hosts the Services in a secure server environment which uses a firewall and
other advanced technology to prevent interference or access from outside intruders. Unique user names and
passwords also are required and must be entered each time a customer logs into the Services.<br /><br />
We are committed to educating our staff about the protection of Personal Information, and the importance of
compliance with relevant privacy legislation and company policies. Employees and contractors are required to
sign confidentiality agreements.<br /><br />
These safeguards help prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of
Personal Information; however, it is important to remember that no system can guarantee 100% security at all
times. In the event that we detect a threat to security or a security vulnerability, we may attempt to contact
you to recommend protective measures. Additionally, incidents of suspected or actual unauthorized handling of
Personal Information are always directed to Reveleer’s Compliance team, which is responsible for determining
escalation and response procedures, depending on the severity and nature of the incident. Incidents involving
unauthorized handling of PHI will be governed by relevant legislation and, where applicable, the provisions of a
BAA or similar agreement with a customer. If Reveleer determines that Personal Information has been
misappropriated or otherwise wrongly acquired, Reveleer will report such misappropriation or acquisition to you
promptly.<br /><br />
For customers who purchase Connected Services, it is important to note that the third-party vendors that provide
Connected Services to you may have different procedures in place to protect your Personal Information than the
standards Reveleer has implemented. We cannot be responsible for their policies or their compliance with them,
regardless of whether we have integrated their solutions with our Services and/or made them available to
you.<br /><br />
<b>11. Openness, Transparency and Access to Personal Information</b><br /><br />
Upon written request by an authorized individual, Reveleer will allow access to any PHI collected and stored
about such individual, unless providing access could reasonably be expected to interfere with the administration
or enforcement of the law or it is impracticable or impossible for Reveleer to retrieve the PHI. However,
Reveleer will first direct the individual to the applicable customer with the request that the customer provide
such access to the individual as the majority of our contracts make the customer the appropriate party to
respond to access requests. When provided with reliable evidence of an error in PHI data, Reveleer will correct
any inaccurate PHI, unless to do so would interfere with the administration or enforcement of the law. However,
where such a request comes from an individual whose PHI allegedly contains an error, Reveleer will first direct
the individual to the applicable customer, with the request that the customer review the request and inform
Reveleer whether there is, in fact, any clinical merit to the claim that an error exists (and, if so, will
require the customer’s written authorization and instruction to correct such error). Unless otherwise prohibited
or restricted by the applicable customer, Reveleer may transmit any corrected PHI to third parties that have had
access to the erroneous PHI. Please note that any deletions performed by Reveleer to correct an error in PHI
will only be “soft” deletes (i.e., the data will no longer be viewable from the front end of the platform). In
order to be able to address any concerns about fraud which may be raised in the future by, for example, a
resident or a government agency, we will retain evidence of: (i) the deletion; (ii) your authorization to make
the deletion; and, (iii) the prior version of the data.<br /><br />
If customers or their users need to update or change their Personal Information stored by us, they may do so by
editing the organization or user record via the Services.<br /><br />
<b>12. Retention and Deletion</b><br /><br />
Reveleer will retain Personal Information: as necessary for the purposes outlined in this Policy; for as long as
a customer account remains active; as required to manage and administer the Services; as required to carry out
legal responsibilities (e.g., legal holds and other legal procedures); to resolve a dispute (including
enforcement of a contract); or, as communicated to you at the time of collection. After all applicable retention
periods have expired, we will delete or destroy your Personal Information in a manner designed to ensure that it
cannot be reconstructed or read. If, at any time, it is not feasible for us to delete or destroy your Personal
Information, we will continue using the same safeguards of protection and security outlined in this Policy and
related subordinate policies, for as long as it cannot be destroyed.<br /><br />
<b>13. Opt-Out Policy</b><br /><br />
We offer visitors to the Site and our customers using the Services a means to choose how we may use the
information they provide to us. If, at any time, you change your mind about:<br /><br />
• our use of Personal Information submitted to the Site;<br /><br />
• our use of Personal Information submitted via the Services;<br /><br />
• receiving notices from us (including automatic notifications about updates to the Services and the frequency
with which we send you such messages); or<br /><br />
• receiving marketing or sales notices from us, including special offers, product enhancement details, event
information, etc.;<br /><br />
• sharing your non-personal information with third parties (as described in this Policy), send us a request
specifying your choice or change of permission by contacting us.<br /><br />
Please note that, if you choose to impose certain restrictions on our use of your Personal Information – e.g.,
if we may no longer access your database to perform any necessary quality testing or disaster recovery testing –
you may no longer be able to use the Services. Similarly, if you choose to unsubscribe from receiving
notifications or messages from us, your customer experience in using the Services may be compromised. If
complying with your request would result in termination of the Services, we will make that clear to you and
confirm that this is what you want before proceeding.<br /><br />
<b>14. Changes</b><br /><br />
We may update this policy from time to time in order to reflect, for example, changes to our practices or for
other operational, statutory/regulatory or other legal reasons.<br /><br />
<b>15. Contact Us</b><br /><br />
If you believe your Personal Information has been used in a way that is inconsistent with this Policy or your
specified preferences, or if you have further questions related to our privacy practices, please contact us via
phone, electronically, or by mail as follows:<br /><br />
Phone: (866) 969-3222<br />
Email: info@reveleer.com<br />
Address: 425 West Broadway, Suite 110, Glendale, CA 91204