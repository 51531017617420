import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, Injectable, Input } from "@angular/core";
import * as moment from "moment";
import { DownloaderService } from "../../../core/downloader/downloader.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { StateMachineExecutionStatus } from "../../../shared/state-machine/state-machine-execution-status.enum";
import { StateMachineType } from "../../../shared/state-machine/state-machineType.enum";
import { JobQueueItem } from "./jobqueue-item.model";

@Component({
  selector: "app-jobqueue-item",
  templateUrl: "./jobqueue-item.component.html",
  styleUrls: ["./jobqueue-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

@Injectable()
export class JobqueueItemComponent  {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private downloaderService: DownloaderService
  ) { }

  @Input() jobQueueItem: JobQueueItem;

  statusName(): string {

    switch (this.jobQueueItem.statusName) {

      case "Completed":
        return "Done";
        break;

      case "Running":
        return "Processing";
        break;

      case "PermanentError":
        return "Errors";
        break;

      default:
        return this.jobQueueItem.statusName;
    }
  }
  getStateMachineIcon(): string {

    switch (this.jobQueueItem.stateMachineId) {

      case StateMachineType.ChartDownload:
      case StateMachineType.InvoiceDownload:
        return "cloud-download-alt";
        break;
      case StateMachineType.MRCSChartUpload:
        return "cloud-upload-alt";
        break;
      case StateMachineType.HedisAuditPackageRequest:
        return "archive";
        break;
      case StateMachineType.BulkIntake:
        return "cloud-upload-alt";
        break;
      case StateMachineType.DataLoad:
        return "cloud-upload-alt";
        break;
      default:
        return "folder";
        break;

    }
  }


  getStatusClass(): string {

    switch (this.jobQueueItem.statusId) {
      case StateMachineExecutionStatus.Completed:
          return "doneStatus";

      case StateMachineExecutionStatus.Running:
      case StateMachineExecutionStatus.Continue:
      case StateMachineExecutionStatus.Waiting:
      case StateMachineExecutionStatus.Retry:
      case StateMachineExecutionStatus.Unprocessed:
          return "runningStatus";

      case StateMachineExecutionStatus.Cancelled:
      case StateMachineExecutionStatus.PermanentError:
          return "errorStatus";

      default:
          return "errorStatus";
  }
  }

  statusIcon(): string {

    switch  (this.jobQueueItem.statusId) {

        case StateMachineExecutionStatus.Completed:
            return "check";

        case StateMachineExecutionStatus.Running:
        case StateMachineExecutionStatus.Continue:
        case StateMachineExecutionStatus.Waiting:
        case StateMachineExecutionStatus.Retry:
        case StateMachineExecutionStatus.Unprocessed:
            return "sync";

        case StateMachineExecutionStatus.Cancelled:
        case StateMachineExecutionStatus.PermanentError:
            return "exclamation-triangle";

        default:
            return "exclamation-triangle";

    }
  }

  navigateToJob() {
    if (this.jobQueueItem.jobLink.includes("file/download")) {
      this.downloaderService.fromPath(this.jobQueueItem.jobLink);
    } else {
      window.open(this.jobQueueItem.jobLink, "_self");
    }
  }

  getInfoLink() {
    return this.jobQueueItem.statusId === StateMachineExecutionStatus.Completed
      ? this.jobQueueItem.jobLink
      : "";
  }

  getInfoText(): string {
    return `${this.jobQueueItem.objectType} ${this.jobQueueItem.objectId}`;

  }

  get useInfoFormat1(): boolean {
    return (
      !this.useInfoFormat2 &&
      !this.useInfoFormat3 &&
      !this.useInfoFormat4 &&
      !this.useInfoFormat5
    );
  }

  get useInfoFormat2(): boolean {
    if (this.jobQueueItem.stateMachineId === StateMachineType.BulkIntake) {
      return true;
    } else {
      return false;
    }
  }

  get useInfoFormat3(): boolean {
    if (this.jobQueueItem.stateMachineId === StateMachineType.InvoiceDownload ||
        this.jobQueueItem.stateMachineId === StateMachineType.ReportDownload) {
      return true;
    } else {
      return false;
    }
  }

  get useInfoFormat4(): boolean {
    if (this.jobQueueItem.stateMachineId === StateMachineType.ChartDownload) {
      return true;
    } else {
      return false;
    }
  }

  get useInfoFormat5(): boolean {
    if (this.jobQueueItem.stateMachineId === StateMachineType.DataLoad) {
      return true;
    } else {
      return false;
    }
  }

  openWindow(target: string): void {
    if (this.jobQueueItem.jobLink.includes("file/download")) {
      this.downloaderService.fromPath(this.jobQueueItem.jobLink);
    } else {
      window.open(`${this.baseApiUrl}${this.jobQueueItem.jobLink}`, target);
    }

  }
  formatStartTime(): string {


    return moment(this.jobQueueItem.startDateTime).local().format("MM/DD/YYYY hh:mm A");

  }

  formatDuration(): string {

    if (this.jobQueueItem.elapsedSeconds !== undefined && this.jobQueueItem.elapsedSeconds !== 0) {
      return moment.utc(Math.abs(this.jobQueueItem.elapsedSeconds) * 1000).format("mm [mins], ss [secs]");
    }

  }

}
