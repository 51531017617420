import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { obsolete } from "../../../utilities/decorators/obsolete.decorator";
import { ButtonAction } from "../button-action.model";

@Component({
  selector: "app-split-button",
  template: `
  <p-splitButton
    [ngClass]="classes"
    [label]="text"
    [styleClass]="styleClass"
    [disabled]="disabled"
    [model]="primeModels"
    (onClick)="onClick.emit($event)"
    (onDropdownClick)="onDropdownClick.emit($event)"></p-splitButton>
  `,
  styleUrls: ["./split-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitButtonComponent implements OnInit {
  @Input() text = "";
  @Input() classes = "";
  @obsolete("Use 'classes' instead.") @Input() styleClass = "";
  @Input() disabled = false;
  @Input() model: ButtonAction[];
  @Output() onClick = new EventEmitter<any>();
  @Output() onDropdownClick = new EventEmitter<any>();
  capitalizedText = "";

  constructor() { }

  get primeModels(): { label: string; command(): void }[] {
    return this.model.map(item => ({ label: item.name, command: item.action }));
  }

  ngOnInit(): void {
    this.capitalizedText = StringHelper.toTitleCase(this.text);
  }
}
