import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { AnnotationSource } from "./annotation-source-enum";
import { Annotations } from "./annotations.model";

export class ChaseDocumentPages {
  chaseDocumentPageId: number;
  annotationSource: AnnotationSource;
  annotations: Annotations[];

  constructor(options: {
    chaseDocumentPageId: number;
    annotations: Annotations[];
  }) {
    this.chaseDocumentPageId = options.chaseDocumentPageId;
    this.annotations = ArrayHelper.isAvailable(options.annotations) ? options.annotations : [];
  }
}
