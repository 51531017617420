import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CallFlowStateService {
  private state = new BehaviorSubject<boolean>(false);
  state$ = this.state.asObservable();

  setCallInProgress(): void {
    this.state.next(true);
  }

  resetCallInProgress(): void {
    this.state.next(false);
  }
}
