import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Listbox } from "primeng/listbox";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { CheckboxGroup } from "../checkbox-group/checkbox-group.model";
import { SelectableInput } from "../selectable-input.model";

@Component({
  selector: "form-checkbox-group-omission-codes",
  templateUrl: "./checkbox-group-omission-codes.component.html",
  styleUrls: ["./checkbox-group-omission-codes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupOmissionCodesComponent extends DynamicControlDirective<CheckboxGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();

  @ViewChild(Listbox, { static: true }) primeListbox: Listbox;
  @Input() showSelectAll = true;

  checkbox = true;
  selectedOptions: SelectableInput;
  allSelectedOptions: SelectableInput[];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  editDxCode(event, item: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.onChange.emit(item);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get optionText(): string {
    return "text";
  }

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get primeAllChecked(): boolean {
    return this.primeListbox != null && this.primeListbox.allChecked;
  }

  get enableToggleButton(): boolean {
    return this.control.enabled && !this.model.isAdmin;
  }

  get toggleButtonText(): string {
    return this.primeAllChecked ? "Deselect All" : "Select All";
  }

  toggleAll(event): void {
    this.primeListbox.toggleAll(event);
  }

  get classes(): string {
    return this.getClasses("checkbox-group");
  }
}

