import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { InternalPendsDetailCommentsService } from "./internal-pends-detail-comments.service";

@Component({
  selector: "app-internal-pends-detail-comments",
  templateUrl: "./internal-pends-detail-comments.component.html",
  styleUrls: ["./internal-pends-detail-comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InternalPendsDetailCommentsService],
})
export class InternalPendsDetailCommentsComponent implements OnInit {
  commentItems = List<CommentItem>();
  commentText: string;

  constructor(
    private service: InternalPendsDetailCommentsService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.service
      .getComments()
      .subscribe(items => this.commentItems = this.assignAndNotify(items));
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  saveComments() {
    if (StringHelper.isAvailable(this.commentText)) {
      this.service
        .saveComment(this.commentText)
        .subscribe(() => {
          this.service
            .getComments()
            .subscribe(items => this.commentItems = this.assignAndNotify(items));
          this.changeDetector.markForCheck();
          this.commentText = null;
        });
    }
  }

}
