import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { List } from "immutable";
import { ListItem } from "../../list/list-item";

@Component({
  selector: "app-banner-statistics",
  templateUrl: "./banner-statistics.component.html",
  styleUrls: ["./banner-statistics.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerStatisticsComponent {
  @Input() bannerStatistics = List<ListItem>();
  constructor() { }

  trackByIndex(index, item) {
    return index;
  }

}
