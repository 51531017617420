import { Component, Input } from "@angular/core";
import { ActionButton } from "./action-button.model";

@Component({
  selector: "dev-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
  @Input() model: ActionButton;

  get name(): string {
    return this.model.name;
  }

  action(): void {
    this.model.action();
  }
}
