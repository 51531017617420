import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-clinical-productivity",
    templateUrl: "./clinical-productivity.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductivityComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CLINICAL_PRODUCTIVITY_REPORT;

    constructor() {
        this.filters = ["Projects", "CompletionDate", "Products", "LOB", "RetrievalOwner"];
    }

}
