import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { CHART_PAGE_NUMBER, ENTITY_SOURCE_CATEGORY_NAME } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { DocumentViewerSessionService } from "../../shared/document/document-page-viewer/document-viewer-session.service";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { FormService } from "../form.service";

@Injectable({
  providedIn: "root",
})
export class InputsService {

  constructor(
    private readonly formService: FormService,
    private readonly documentViewerSessionService: DocumentViewerSessionService
  ) { }

  onTextboxClick(control: AbstractControl, input: any): void {
    const parentControls = control.parent.controls;
    const inputPageNumberAttributeValue = input.pageNumber;
    const saveInfo = this.formService.getApiObject(control);
    const entitySourceCategoryNameKey = `${saveInfo.entityId}_${ENTITY_SOURCE_CATEGORY_NAME.attributeId}`;
    const chartPageNumberKey = `${saveInfo.entityId}_${CHART_PAGE_NUMBER.attributeId}`;
    const chartPageNumberControl = parentControls[chartPageNumberKey];

    if (this.isEntitySourceNLPOrDVA(entitySourceCategoryNameKey, parentControls)) {
      const annotationPageNumber = NumberHelper.isAvailable(inputPageNumberAttributeValue) ? inputPageNumberAttributeValue : +chartPageNumberControl?.value;
      this.documentViewerSessionService.updateDataEntryPageNumber(annotationPageNumber);
    }
  }

  private isEntitySourceNLPOrDVA(key: string, controls: any): boolean {
    if (controls.hasOwnProperty(key)) {
      const control = controls[key];
      return control.value === "EVE" || control.value === "DVA+" || control.value === "DVA-";
    }
  }
}
