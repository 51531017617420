import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { fromEvent } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { HighlightAnnotationService } from "../../../highlight-annotation/highlight-annotation.service";
import { AnnotationSource } from "./annotation-source-enum";
import { Annotations } from "./annotations.model";
import { ChaseDocumentPages } from "./chase-document-pages.model";

@Component({
  selector: "app-document-page-annotations",
  template: `<canvas id="annotCanvas" #canvas [ngStyle]="{'opacity': '0.65', 'width': '100%', 'height': '100%'}"></canvas>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageAnnotationsComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("canvas") canvas: ElementRef;
  private context: CanvasRenderingContext2D;
  @Input() canvasHeight: number;
  @Input() canvasWidth: number;
  @Input() pageAnnotations: Annotations[];
  @Input() documentPageId: number;
  @Input() status: string;
  @Input() isReadOnly = false;
  @Input() zoomPercentage = 100;
  @Input() margin = 0;
  @Input() zoomStyle;
  @Input() imageWidth;
  @Input() isDiagnosisSelected = false;
  @Input() chasePageAnnotations: Annotations[];
  @Input() chaseDocumentPages: ChaseDocumentPages[] = [];
  @Output() onChange = new EventEmitter<ChaseDocumentPages>();
  annotations: Annotations[] = [];
  clientX: number;
  clientY: number;
  startX: number;
  startY: number;
  widthX: number;
  heightY: number;
  userId: number;
  deletingAnnotation = false;
  private localStorageIsWindowOpen = "isWindowOpen";
  detach: boolean = this.localService.get(this.localStorageIsWindowOpen, null) === "1";

  @HostListener("mousedown", ["$event"])
  onMousedown(event: MouseEvent) {
    if (this.isReadOnly) { return; }
    if (ArrayHelper.isAvailable(this.annotations)) {
      this.annotations = this.filterData(this.annotations);
      this.annotations.forEach((item, index) => {
        if (this.deleteAnnotation(item, event)) {
          if (item.annotationSourceId === AnnotationSource.Nlp && !item.reviewedByUser) { return; }

          this.deletingAnnotation = true;
          const tempAnnotations = this.annotations.filter((_, i) => i !== index);
          this.annotations = tempAnnotations;
          this.displayPreviousAnnotations();
          this.emitChange(this.annotations);
        } else {
          this.clientX = event.clientX;
          this.clientY = event.clientY;
        }
      });
    } else {
      this.clientX = event.clientX;
      this.clientY = event.clientY;
    }
    this.highlightAnnotationService.isEditingHighlight(true);
  }

  @HostListener("mouseup")
  onMouseup() {
    if (this.isReadOnly) { return; }
    if (!this.deletingAnnotation && this.minimumAnnotation) {
      const imageSize = document.querySelector("#documentsImg") as HTMLElement;
      const zoomStyleHeight = Number(this.zoomStyle.height.slice(0, -2));
      const newAnnotation = {
        startX: this.detach ?
          (this.startX - this.margin) / imageSize.offsetWidth :
          (this.startX / this.canvasWidth) / this.adjustedZoomPercentage,
        widthX: this.detach ?
          (((this.widthX * 100) / 100) / imageSize.offsetWidth) :
          (this.widthX / this.canvasWidth) / this.adjustedZoomPercentage,
        startY: this.detach ?
          (this.startY) / (zoomStyleHeight / 1558) :
          this.startY / this.adjustedZoomPercentage,
        heightY: this.detach ?
          this.heightY / (zoomStyleHeight / 1558) :
          this.heightY / this.adjustedZoomPercentage,
        status: this.status,
        userId: this.userId,
        createDate: new Date(),
      };
      if (this.isNewAnnotation(newAnnotation)) {
        this.annotations.push(newAnnotation);
        this.emitChange();
      }
    }

    if (this.deletingAnnotation) {
      this.deletingAnnotation = false;
      this.emitChange();
    }
    this.highlightAnnotationService.isEditingHighlight(false);
  }

  constructor(
    private highlightAnnotationService: HighlightAnnotationService,
    private localService: LocalService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userId = this.userId = this.userService.getUserToken().userId;
    this.annotations = this.pageAnnotations;
  }

  ngAfterViewInit() {
    this.initializeCanvas();
    this.displayPreviousAnnotations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.canvasWidth) {
      if (changes.canvasWidth.isFirstChange()) { return; }

      if (changes.canvasWidth.currentValue !== changes.canvasWidth.previousValue) {
        this.initializeCanvas();
        this.displayPreviousAnnotations();
      }
    }

    if (changes.pageAnnotations) {
      if (changes.pageAnnotations.isFirstChange()) { return; }

      if (changes.pageAnnotations.currentValue !== changes.pageAnnotations.previousValue) {
        this.annotations = this.pageAnnotations;
        if (this.isDiagnosisSelected) {
          const filteredAnnots: Annotations[] = [];
          this.chasePageAnnotations.map(data => {
            if (data.annotationSourceId !== AnnotationSource.Nlp) {
            filteredAnnots.push(data);
            }
          });

          const userAnnotations = this.filterData(filteredAnnots);
          this.annotations.push(...userAnnotations);

        } else {
          this.annotations = this.filterData(this.annotations);
        }
        this.initializeCanvas();
        this.displayPreviousAnnotations();
      }
    }

    if (changes.zoomPercentage) {
      if (changes.zoomPercentage.isFirstChange()) { return; }

      if (changes.zoomPercentage.currentValue !== changes.zoomPercentage.previousValue) {
        this.displayPreviousAnnotations();
      }
    }

    if (changes.margin) {
      if (changes.margin.isFirstChange()) { return; }

      if (changes.margin.currentValue !== changes.margin.previousValue) {
        this.displayPreviousAnnotations();
      }
    }
  }

  filterData(filteredAnnots: Annotations[]): Annotations[] {
    const check = {};
    const res: Annotations[] = [];
    filteredAnnots.map((data, i) => {
        if (!check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`]) {
            check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`] = true;
            res.push(filteredAnnots[i]);
        }
    });
    return res;
  }

  emitChange(annotations: Annotations[] = []): void {
    const chaseDocumentPages = new ChaseDocumentPages({
      chaseDocumentPageId: this.documentPageId,
      annotations: annotations.length > 0 ? annotations : this.annotations,
    });
    this.onChange.emit(chaseDocumentPages);
  }

  get minimumAnnotation(): boolean {
    return (
      this.widthX > 9 && this.heightY > 9 ||
      this.widthX < -9 && this.heightY < -9 ||
      this.widthX > 9 && this.heightY < -9 ||
      this.widthX < -9 && this.heightY > 9
    );
  }

  get adjustedZoomPercentage(): number {
    return this.zoomPercentage / 100;
  }

  private initializeCanvas(): void {
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;
    this.context = canvasElement.getContext("2d");
    const image = new Image();
    canvasElement.width = this.canvasWidth;
    canvasElement.height = this.canvasHeight;

    image.onload = () => {
      this.context.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);
    };
    this.onMousemove(canvasElement);
  }

  private onMousemove(canvasElement: HTMLCanvasElement) {
    fromEvent(canvasElement, "mousedown")
      .pipe(
        switchMap(() => {
          return fromEvent(canvasElement, "mousemove")
            .pipe(
              takeUntil(fromEvent(canvasElement, "mouseup")),
              takeUntil(fromEvent(canvasElement, "mouseleave"))
            );
        })
      ).subscribe((res: MouseEvent) => {
        const domRect = canvasElement.getBoundingClientRect();
        const start = {
          x: Math.round(this.clientX - domRect.left),
          y: Math.round(this.clientY - domRect.top),
        };
        const move = {
          x: Math.round(res.clientX - domRect.left) - start.x,
          y: Math.round(res.clientY - domRect.top) - start.y,
        };

        this.annotateChart(start, move);
      });
  }

  private annotateChart(start: { x: number; y: number }, move: { x: number; y: number }) {
    if (!this.context || this.isReadOnly) { return; }
    if (!this.deletingAnnotation) {
      this.startX = start.x;
      this.startY = start.y;
      this.widthX = move.x;
      this.heightY = move.y;
      this.displayPreviousAnnotations();

      this.context.beginPath();
      this.context.globalCompositeOperation = "multiply";
      this.context.fillStyle = "rgba(233,189,54, 0.4)";
      this.context.fillRect(start.x, start.y, move.x, move.y);
      this.context.stroke();
    }
  }

  private isNewAnnotation(newAnnotation: Annotations): boolean {
    if (ArrayHelper.isAvailable(this.annotations)) {
      const annotationIndex = this.annotations.findIndex(item => {
        return item.startX === newAnnotation.startX && item.startY === newAnnotation.startY;
      });
      return annotationIndex === -1;
    }

    return true;
  }

  private getNewPercents() {
    const marginArray = [60, 80, 100, 120, 140, 160, 180, 200, 220];
    const indexZoom = marginArray.indexOf(this.zoomPercentage);
    let newPercent = 1;
    switch (indexZoom) {
      case 0:
        newPercent = 1;
        break;
      case 1:
        newPercent = 0.83;
        break;
      case 2:
        newPercent = 0.71;
        break;
      case 3:
        newPercent = 0.62;
        break;
      case 4:
        newPercent = 0.55;
        break;
      case 5:
        newPercent = 0.498;
        break;
      case 6:
        newPercent = 0.45;
        break;
      case 7:
        newPercent = 0.412;
        break;
      case 8:
        newPercent = 0.385;
        break;
      default:
        newPercent = 1.68;
        break;
    }

    return newPercent;
  }

  private displayPreviousAnnotations(): void {
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    const newPercent = this.getNewPercents();
    this.margin = NumberHelper.isAvailable(this.margin) ? this.margin : 0;
    const adjustedCanvas = this.detach ? this.canvasWidth * 0.7 : this.canvasWidth;
    const zoomStyleHeight = Number(this.zoomStyle.height.slice(0, -2));
    this.annotations.forEach((item, idx) => {
      this.context.beginPath();
      this.context.globalCompositeOperation = "multiply";
      const fillStyleColor = this.getFillStyle(item);
      this.context.fillStyle = item.annotationSourceId !== AnnotationSource.Nlp ? fillStyleColor : `${fillStyleColor.slice(0, -6)})`;

      if (this.detach) {
        const newYPercent = item.startY / 1558;
        const newYPercentNLP = (item.startY + item.heightY) / 1558;
        const xInit = this.margin > 0 ? item.startX * this.imageWidth + (this.margin >= 0 ? this.margin : 0) :
          item.startX * this.imageWidth * (this.canvasWidth / this.imageWidth);
        const widthX = this.margin > 0 ? item.widthX * this.imageWidth :
          (item.widthX * this.imageWidth) * (this.canvasWidth / this.imageWidth);
        const yInit = item.annotationSourceId !== AnnotationSource.Nlp ? (newYPercent * zoomStyleHeight) * newPercent :
        ((newYPercentNLP * zoomStyleHeight) * newPercent) - (((2 / 1558) * zoomStyleHeight) * newPercent);
        const yHeight = item.annotationSourceId !== AnnotationSource.Nlp ? ((item.heightY / 1558) * zoomStyleHeight) * newPercent :
        ((4 / 1558) * zoomStyleHeight) * newPercent;
        this.context.fillRect(
          xInit,
          yInit,
          widthX,
          yHeight);
      }
      if (!this.detach) {
        const yInit = item.annotationSourceId !== AnnotationSource.Nlp ? item.startY : item.startY + item.heightY;
        const yHeight = item.annotationSourceId !== AnnotationSource.Nlp ? item.heightY : (this.adjustedZoomPercentage * 4);
        this.context.fillRect(
          ((item.startX * this.canvasWidth) * this.adjustedZoomPercentage),
          (yInit),
          ((item.widthX) * (adjustedCanvas)) * this.adjustedZoomPercentage,
          yHeight);
      }
      this.context.stroke();
    });
  }

  private getFillStyle(item: Annotations): string {
    if (item.annotationSourceId === AnnotationSource.Nlp && !item.reviewedByUser) {
      return StringHelper.isAvailable(item.colorCode) ? this.highlightAnnotationService.hexToRGB(item.colorCode) :
      "rgba(131, 188, 106, 0.4)";
    } else {
      return "rgba(233,189,54, 0.4)";
    }
  }

  private setXYDetachValues(annotation) {
    const newPercent = this.getNewPercents();
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    const newXStart = annotation.startX + (annotation.widthX < 0 ? annotation.widthX : 0);
    const newYStart = annotation.startY + (annotation.heightY < 0 ? annotation.heightY : 0);
    const zoomStyleHeight = Number(this.zoomStyle.height.slice(0, -2));
    const newYPercent = newYStart / 1558;
    const newYHeight = ((annotation.heightY * (annotation.heightY < 0 ? -1 : 1)) + newYStart) / 1558;
    const startX = (newXStart) * imageSize.offsetWidth + (this.margin >= 0 ? this.margin : 0);
    const startY = (newYPercent * zoomStyleHeight) * newPercent;
    const widthX = (startX + ((annotation.widthX * (annotation.widthX < 0 ? -1 : 1)) * imageSize.offsetWidth));
    const heightY = (newYHeight * zoomStyleHeight) * newPercent;
    return [startX, startY, widthX, heightY];
  }

  private deleteAnnotation(annotation: Annotations, event: MouseEvent): boolean {
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;
    const domRect = canvasElement.getBoundingClientRect();
    const clickX = event.clientX - domRect.left;
    const clickY = event.clientY - domRect.top;
    let startX = (annotation.startX * this.canvasWidth) * this.adjustedZoomPercentage;
    let startY = annotation.startY * this.adjustedZoomPercentage;
    let widthX = (startX + (annotation.widthX * this.canvasWidth)) * this.adjustedZoomPercentage;
    let heightY = (annotation.startY + annotation.heightY) * this.adjustedZoomPercentage;
    if (this.detach) {
      [startX, startY, widthX, heightY] = this.setXYDetachValues(annotation);

      return clickX >= startX && clickX <= widthX && clickY >= startY && clickY <= heightY;
    } else {
      return (
        // Delete from quadrant I
        (startX <= clickX &&
          widthX >= clickX &&
          startY <= clickY &&
          heightY >= clickY) ||

        // Delete from quadrant II
        (startX >= clickX &&
          widthX <= clickX &&
          startY <= clickY &&
          heightY >= clickY) ||

        // Delete from quadrant III
        (startX >= clickX &&
          widthX <= clickX &&
          startY >= clickY &&
          heightY <= clickY) ||

        // Delete from quadrant IV
        (startX <= clickX &&
          widthX >= clickX &&
          startY >= clickY &&
          heightY <= clickY)
      );
    }
  }

}
