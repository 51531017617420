import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { BASE_API_URL } from "../../core/environment.tokens";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { ActionButton } from "../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../zdevcontrols/dev-controller/dev-controller.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { NationalProviderGridService } from "./national-provider-grid.service";
import { MasterProvider } from "./national-provider.model";
import { SelectButtonComponent } from "./select-button/select-button.component";

@Component({
  selector: "app-national-provider-grid",
  templateUrl: "./national-provider-grid.component.html",
  styleUrls: ["./national-provider-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NationalProviderGridComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @ViewChild("selectButtonComponent", { static: true }) selectButtonComponent: TemplateRef<SelectButtonComponent>;
  @ViewChild("callButtonComponent", { static: true }) callButtonComponent: TemplateRef<SelectButtonComponent>;
  refreshProviderGrid = new EventEmitter<boolean>(true);
  @Input() additionalFilters: GridFilter[] = [];
  @Output() onSelect = new EventEmitter<MasterProvider>();
  @Output() onInitiateCall = new EventEmitter<MasterProvider>();
  @Input() callProvider = false;
  request: GridRequest;
  readonly form: FormGroup;
  filterform: FormGroup;

  get nameInput(): Textbox {
    return this.getInput("name") as Textbox;
  }
  set nameInput(value: Textbox) {
    this.setInput("name", value);
  }

  get npiInput(): Textbox {
    return this.getInput("npi") as Textbox;
  }
  set npiInput(value: Textbox) {
    this.setInput("npi", value);
  }

  get specialityInput(): Textbox {
    return this.getInput("speciality") as Textbox;
  }
  set specialityInput(value: Textbox) {
    this.setInput("speciality", value);
  }

  get addressInput(): Textbox {
    return this.getInput("address") as Textbox;
  }
  set addressInput(value: Textbox) {
    this.setInput("address", value);
  }

  get cityInput(): Textbox {
    return this.getInput("city") as Textbox;
  }
  set cityInput(value: Textbox) {
    this.setInput("city", value);
  }

  get stateInput(): Textbox {
    return this.getInput("state") as Textbox;
  }
  set stateInput(value: Textbox) {
    this.setInput("state", value);
  }

  get zipInput(): Textbox {
    return this.getInput("zip") as Textbox;
  }
  set zipInput(value: Textbox) {
    this.setInput("zip", value);
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private nationalProviderGridService: NationalProviderGridService,
    private readonly devService: DevControllerService
  ) { }

  ngOnInit() {
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshProviderGrid.emit() })]);

    this.configuration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "nationalProviderId", header: "NPI", width: "90px" }),
        new GridColumnDefinition({ field: "providerFirstName", header: "First Name" }),
        new GridColumnDefinition({ field: "providerLastName", header: "Last Name" }),
        new GridColumnDefinition({ field: "providerSpecialty", header: "Specialty" }),
        new GridColumnDefinition({ field: "address1", header: "Address1" }),
        new GridColumnDefinition({ field: "address2", header: "Address2" }),
        new GridColumnDefinition({ field: "city", header: "City" }),
        new GridColumnDefinition({ field: "state", header: "State", width: "55px" }),
        new GridColumnDefinition({ field: "zipCode", header: "Zip", width: "60px" }),
        new GridColumnDefinition({ field: "phoneNumber", header: "Phone", width: "90px" }),
        new GridColumnDefinition({ field: "faxNumber", header: "Fax", width: "90px" }),
        new GridColumnDefinition({ template: this.selectButtonComponent, header: "", show: !this.callProvider }),
        new GridColumnDefinition({ template: this.callButtonComponent, header: "", show: this.callProvider }),
      ],
      pageSize: 5,
      pageSizeOptions: [5, 10],
      showActionColumn: false,
      showExportAction: false,
    });

    this.loadGrid();
  }

  refreshGrid() {
    this.refreshProviderGrid.emit();
  }

  private loadGrid(): void {
    this.request = new GridRequest({
      url: this.requestUrl,
      filters: this.getFilters(),
    });
  }

  private getFilters(): GridFilter[] {
    const totalFilters = [
      ...this.additionalFilters,
      new GridFilter({
        input: new Textbox(),
        key: "name",
        name: "Name",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "npi",
        name: "NPI",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "speciality",
        name: "Speciality",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "address",
        name: "Address",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "city",
        name: "City",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "state",
        name: "State",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "zip",
        name: "Zip",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "phone",
        name: "Phone",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "fax",
        name: "Fax",
      }),
    ];

    const distinctFilters = totalFilters.filter((filter, i, filters) => {
      return filters.findIndex(a => a.key === filter.key) === i;
    });

    return distinctFilters;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get configuration(): GridConfiguration {
    return this.nationalProviderGridService.configuration;
  }

  set configuration(value: GridConfiguration) {
    this.nationalProviderGridService.configuration = value;
  }

  get data(): any[] {
    return this.nationalProviderGridService.data;
  }

  get requestUrl(): string {
    return this.nationalProviderGridService.URL;
  }
}
