import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import { DateHelper } from "../../utilities/contracts/date-helper";
import { Inclusions, UnitOfTime, dateTypes } from "../../utilities/contracts/helper-types";

export const dateGreaterThanValidator = (start: dateTypes, inclusion: Inclusions = "[]", granularity: UnitOfTime = "day"): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    // TODO: Throwing an error will probably need to change at some point.
    DateHelper.throwIsAvailableError(start, "start");

    if (!DateHelper.isAvailable(control.value)) {
      return null;
    }

    if (DateHelper.isAfter(control.value, start)) {
      return null;
    }

    return {
      dateGreaterThan: {
        start: DateHelper.format(start),
      },
    };
  };
};

@Directive({
  selector: "[app-date-greater-than]",
  providers: [{ provide: NG_VALIDATORS, useExisting: DateGreaterThanValidatorDirective, multi: true }],
})
export class DateGreaterThanValidatorDirective implements Validator {
  @Input() start: dateTypes;
  @Input() inclusion: Inclusions = "[]";
  @Input() granularity: UnitOfTime = "day";

  validate(control: AbstractControl): ValidationErrors | null {
    return dateGreaterThanValidator(this.start, this.inclusion, this.granularity)(control);
  }
}
