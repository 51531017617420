import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { IDateRange } from "../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AddressesQueueService } from "../../addresses-queue/addresses-queue.service";
import { Appointment } from "../appointment.model";
import { AppointmentService } from "../appointment.service";
import { AppointmentsForm } from "../appointments-form.model";

@Component({
  selector: "retrieval-schedule-appointment-modal",
  templateUrl: "./schedule-appointment-modal.component.html",
  styleUrls: ["./schedule-appointment-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentModalComponent implements OnInit, OnDestroy {
  @Input() retrievalType: string;
  @Input() appointment: Appointment;
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onSuccess = new EventEmitter<AppointmentsForm[]>(true);
  private sink = new Subscription();
  isBackdatedAppointmentModalVisible = false;
  saveAppointmentInProgress = false;

  form: FormGroup;
  addressInput: Textbox;
  noteInput: Textarea;
  userAppointments: number[] = [];
  userOptions: SelectableInput[];
  filteredOptions: SelectableInput[];
  hasOptions = true;
  selectedOnEdit: SelectableInput;
  readonly DEFAULT_TIME: Date = new Date("Tue Jan 1 2019 00:00:00 GMT+0800 (PST)");


  constructor(
    private cd: ChangeDetectorRef,
    private formService: FormService,
    private appointmentService: AppointmentService,
    private addressesQueueService: AddressesQueueService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    // TODO: Must use *ngIf with the visible property in order to run ngOnInit.
    this.getUsers();
    this.createForm();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get addressId(): number {
    return this.appointment.masterDocumentSourceId;
  }

  get appointmentId(): number {
    return this.appointment.appointmentId;
  }

  get isEditingMode(): boolean {
    return this.appointmentId > 0;
  }

  get header(): string {
    return this.isEditingMode ? "Edit Appointment" : "Schedule a New Appointment";
  }

  get appointments(): FormArray {
    return this.form.get("appointments") as FormArray;
  }

  get hasAppointments(): boolean {
    return ArrayHelper.isAvailable(this.appointments.value);
  }

  userChanged(userId: number, index: number): void {
    this.userAppointments[index] = userId;
    this.hasOptions = true;
  }

  setVisible(value: boolean): void {
    if (this.visible !== value) {
      if (value === false) {
        this.onHide.emit();
      }

      this.visible = value;
      this.visibleChange.emit(value);
      this.cd.markForCheck();
    }
  }

  onAddAppointment(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.addAppointment();
  }

  onRemoveAppointment(event: MouseEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.appointments.removeAt(index);
  }

  onRemoveUser(event: MouseEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.userAppointments.splice(index, 1);
  }

  validateNotes() {
    const notes = this.form.value?.appointmentNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, ""))
      || ((notes?.replace(/ /g, "").length < 4)) || ((notes?.replace(/ /g, "").length > 1000)))) {
      this.form.get(this.noteInput.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.form.get(this.noteInput.key).setErrors(null);
    }
  }

  onSaveAppointment(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.hasOptions = ArrayHelper.isAvailable(this.userAppointments) && this.userAppointments[0] !== undefined && this.userAppointments.filter(x => x === 0).length === 0;
    this.formService.markAllAsTouched(this.form);
    this.setAppointmentsErrors(this.appointments.value);
    this.validateNotes();
    if (this.form.valid && this.hasOptions) {
      this.isBackdatedAppointmentModalVisible = this.hasBackDatedAppointment();
      if (!this.isBackdatedAppointmentModalVisible) {
        this.saveAppointments();
      }
    }
  }

  filterOptions(event): void {
    this.filteredOptions = this.userOptions.filter(x => x.text.toLowerCase().toString().includes(event.query.toLowerCase()));
  }

  saveAppointments(): void {
    this.cd.markForCheck();
    this.saveAppointmentInProgress = true;

    this.form.value.appointments = this.form.value.appointments.map(o =>
    ({
      ...o,
      appointmentStartTime: this.convertTimetoString(o.appointmentStartTime),
      appointmentEndTime: this.convertTimetoString(o.appointmentEndTime),
    }));

    const appointments: AppointmentsForm[] = [];
    let isDuplicateUser = false;
    this.userAppointments.map(userId => {
        if (NumberHelper.isAvailable(userId)) {
          if (appointments.find(x => x.assignedToUser === userId)) {
            isDuplicateUser = true;
          }
          appointments.push({
              ...this.form.value,
              appointmentId: this.appointmentId,
              addressId: this.addressId,
              assignedToUser: userId,
          });
        }
    });

    if (isDuplicateUser) {
      this.saveAppointmentInProgress = false;
      this.messagingService.showToast(`Duplicate user name is not allowed.`, SeverityType.ERROR);
    } else {
      this.appointmentService.saveList(appointments)
        .pipe(finalize(() => {
          this.saveAppointmentInProgress = false;
          this.cd.markForCheck();
        }))
        .subscribe(
          () => {
            this.messagingService.showToast(`Appointment ${this.isEditingMode ? "edited" : "scheduled"} successfully.`, SeverityType.SUCCESS);
            this.onSuccess.emit(appointments);
            this.setVisible(false);
          },
          err => {
            if (err.error.search("Please pick another datetime") <= -1) {
              if (err.error.search("Overlapping appointment with same date and time. ") !== 0) {
                this.messagingService.showToast(`Error while ${this.isEditingMode ? "editing" : "scheduling"} appointment. Please try again.`, SeverityType.ERROR);
              }
            }
          }
        );
    }

  }

  private convertTimetoString(time: any): string {
    return DateHelper.isAvailable(time) ? DateHelper.convert24to12(DateHelper.format(time, "HH:mm A")) : time;
  }

  private getUsers(): void {
    this.addressesQueueService.getRetrievalUsers(this.addressId)
      .subscribe(options => {
        this.userOptions = options;

        if (this.isEditingMode) {
          this.selectedOnEdit = this.userOptions.find(x => x.value === this.appointment.assignedToUserId);
        }
        this.cd.markForCheck();
        this.formService.updateDom.next();
      });
  }

  private createForm(): void {

    this.addressInput = new Textbox({
      key: "address",
      label: "Address",
      placeholder: "Address",
      value: this.appointment.masterDocumentSourceName,
      readonly: true,
    });

    this.noteInput = new Textarea({
      key: "appointmentNotes",
      label: "Note",
      placeholder: "Appointment note...",
      value: this.appointment.note,
      validators: [Validators.required, Validators.maxLength(1000)],
      errorMessages: {
        required: "An appointment note is required.",
        maxlength: "The note must be 1000 characters or less.",
      },
    });

    this.form = this.formService.createFormGroup([
      this.addressInput,
      this.noteInput,
    ]);

    this.form.addControl("appointments", new FormArray([]));
    this.addAppointment(this.appointment);
    this.addUser(this.appointment.assignedToUserId);
  }

  addUser(userId: number): void {
    this.userAppointments.push(userId);
  }

  private addAppointment({ start, end }: IDateRange = {} as any): void {
    const hasStart = DateHelper.isAvailable(start);
    const hasEnd = DateHelper.isAvailable(end);
    const startTime = hasStart ? start : new Date(2019, 0, 1, 9, 0, 0);
    const endTime = hasEnd ? end : new Date(2019, 0, 1, 17, 0, 0);
    const newAppointment = new FormGroup({
      appointmentDate: new FormControl(hasStart || hasEnd ? DateHelper.format(start) : null),
      appointmentStartTime: new FormControl(startTime),
      appointmentEndTime: new FormControl(endTime),
    });
    this.appointments.push(newAppointment);
    this.cd.markForCheck();
  }

  private setAppointmentsErrors(appointments: any[]): void {
    this.appointments.setErrors(null);

    if (ArrayHelper.isAvailable(appointments)) {
      appointments.forEach((a, i) => this.setAppointmentErrors(i));
    } else {
      this.appointments.setErrors({ error: "Please add an appointment." });
    }
  }

  private setAppointmentErrors(index: number): boolean {
    // TODO: Create custom validators.
    const appointment = this.appointments.at(index);
    appointment.setErrors(null);

    const { start, end } = this.getRange(index);
    if (!DateHelper.isAvailable((this.appointments.at(index).value || {}).appointmentDate)) {
      appointment.setErrors({ error: "Select a date." });
    } else if (!DateHelper.isAvailable(start)) {
      appointment.setErrors({ error: "Select a start time." });
    } else if (!DateHelper.isAvailable(end) || DateHelper.isBefore(end, start, true, "minute")) {
      appointment.setErrors({ error: "Select an end time that is after the start time." });
    }

    return appointment.valid;
  }

  private hasBackDatedAppointment(): boolean {
    const today = new Date();
    for (let i = 0; i < this.appointments.length; ++i) {
      const { start } = this.getRange(i);
      if (DateHelper.isBefore(start, today, true, "minute")) {
        return true;
      }
    }
    return false;
  }

  private getRange(index: number): IDateRange {
    const { appointmentDate, appointmentStartTime, appointmentEndTime } = this.appointments.at(index).value || {} as any;
    let start = null;
    let end = null;

    const formattedStartTime = DateHelper.isDate(appointmentStartTime) ? DateHelper.format(appointmentStartTime, "HH:mm A") : appointmentStartTime;
    const formattedEndTime = DateHelper.isDate(appointmentEndTime) ? DateHelper.format(appointmentEndTime, "HH:mm A") : appointmentEndTime;

    if (DateHelper.isAvailable(appointmentDate) && StringHelper.isAvailable(formattedStartTime)) {
      const date = DateHelper.removeTime(appointmentDate);

      const convertedStartTime = DateHelper.convert24to12(formattedStartTime);
      start = DateHelper.create(`${DateHelper.format(date, "YYYY-MM-DD")}T${convertedStartTime}:00`);

      if (formattedEndTime) {
        if (StringHelper.isAvailable(formattedEndTime)) {
          const convertedEndTime = DateHelper.convert24to12(formattedEndTime);
          end = DateHelper.create(`${DateHelper.format(date, "YYYY-MM-DD")}T${convertedEndTime}:00`);
        } else if (DateHelper.isDate(formattedEndTime)) {
          end = formattedEndTime;
        }
      }
    }

    return { start, end };
  }

  trackByIndex(index, item) {
    return index;
  }

  onUserClear(event: any, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.userAppointments[index] = 0;
  }

  requiredClass(index: number): string {
    if (!NumberHelper.isGreaterThan(this.userAppointments[index], 0, false)) {
      return "user_required";
    }
    return "";
  }
}
