import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";

export class StepsMenuItem {
    readonly label: string;
    complete: boolean;

    constructor(options: {
      label: string;
      complete?: boolean;
    }

    ) {
      this.label = options.label;
      this.complete = BooleanHelper.tryGet(options.complete, false);
    }
  }

