import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { List } from "immutable";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { ListItem } from "../list-item";

@Component({
  selector: "app-list-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
  @Input() summaryItems = List<ListItem>();

  trackByIndex(index, item) {
    return index;
  }

  getTitleForLongText(value: string) {
    return NumberHelper.isGreaterThan(value.length, 20, true) ? value : "";
  }
}
