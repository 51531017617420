import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MasterProvider } from "../national-provider.model";

@Component({
  selector: "app-national-provider-select-button",
  templateUrl: "./select-button.component.html",
  styleUrls: ["./select-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent {
  @Input() data: MasterProvider;
  @Input() buttonText: string;
  @Output() onClick = new EventEmitter<MasterProvider>();


  get text(): string {
    return this.buttonText; // TODO: Change the text based on if it's in the service provider list for the chase.
  }


  clicked(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.onClick.emit(this.data);
  }
}
