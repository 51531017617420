<div class="notifications" [ngClass]="unreadAndActiveClass" (click)="show($event)">
  <app-icon iconName="bell"></app-icon>
  <span>{{ countText }}</span>
</div>

<app-popover #overlay appendTo="body">
  <div *ngFor="let item of notifications; trackBy: trackByIndex" class="notification--item">
    <div>
      {{ item.objectType }}
      <a *ngIf="item.objectId !== 0" class="link" [routerLink]="[item.alertURL]" (click)="overlay.hide()">{{ item.objectId }}</a>
      {{ item.messsage }}
    </div>
    <div class="notification--item--timestamp">
      <span>{{ item.timeStamp }}</span>
    </div>
  </div>

  <div class="see-all--container">
    <a routerLink="/notification" (click)="overlay.hide()">See All</a>
  </div>
</app-popover>
