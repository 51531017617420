import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription, combineLatest } from "rxjs";
import { distinctUntilKeyChanged } from "rxjs/operators";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../utilities/contracts/date-helper";
import { DynamicEntityAttribute } from "../../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ENCOUNTER_FOUND } from "../../../../../chase-detail/chase-detail-chart/attributes";
import { Encounter } from "../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskState } from "../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailStateService } from "../../../../../chase-detail/chase-detail-state.service";
import { DocumentViewerSessionService } from "../../../../document-viewer-session.service";
import { DiagnosisService } from "../diagnosis/diagnosis.service";
import { ClinicalTerm } from "../eve-clinical-terms/clinical-term.model";
import { ClinicalTermsService } from "../eve-clinical-terms/clinical-terms.service";

@Component({
  selector: "member-risk-encounter-v2",
  templateUrl: "./encounter.component.html",
  styleUrls: ["./encounter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterV2Component implements OnInit, OnDestroy {

  private sink = new SubSink();
  isReadOnly = false;
  isSelectedEncounterValid = false;
  riskState: RiskState;
  clinicalTerms: ClinicalTerm[] = [];
  selectedEncounter: Encounter;
  private riskAndChaseState$: Subscription;
  isEncounterAvailable = true;

  constructor(
    private readonly riskService: RiskService,
    private readonly diagnosisService: DiagnosisService,
    private readonly clinicalTermsService: ClinicalTermsService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly documentViewerService: DocumentViewerSessionService,
    private readonly changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initRiskAndChaseSubscription();
    this.sink.add(
      this.riskService.data
        .subscribe(riskState => {
          this.isReadOnly = !riskState.assignedToCurrentUser;
          this.isSelectedEncounterValid = riskState.isSelectedEncounterValid;
          this.riskState = riskState;
          this.isEncounterAvailable = ArrayHelper.isAvailable(riskState.encounters);
        }),
      this.riskAndChaseState$
    );
  }


  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  initRiskAndChaseSubscription(): void {
    this.riskAndChaseState$ = combineLatest([
      this.riskService.data.pipe(distinctUntilKeyChanged("chaseId")),
      this.chaseDetailStateService.selectedEncounter$]).subscribe(
        ([riskState, encounter]) => {
          this.getClinicalTerms(riskState.chaseId, DateHelper.format(encounter?.startDate), DateHelper.format(encounter?.endDate));
      });
  }

  getClinicalTerms(chaseId: number, dosFrom: string, dosTo: string): void {
    this.sink.add(
      this.clinicalTermsService.getClinicalTerms(chaseId, dosFrom, dosTo).subscribe(clinicalTerms => {
      this.clinicalTerms = clinicalTerms;
      this.changeDetector.markForCheck();
    }));
  }

  addDiagnosis() {
    const newDiagnosis = this.riskService.addNewDiagnosis();
    this.diagnosisService.addNewDiagnosis(newDiagnosis);
  }

  addEncounter() {
    const newEncounter = this.riskService.addNewEncounter();
    const attributes = [{
      ...ENCOUNTER_FOUND,
      entityTypeId: newEncounter.entityTypeId,
      chaseId: newEncounter.chaseId,
      value: "1",
    }];
    this.saveAttributes(attributes as DynamicEntityAttribute[]);
    this.addDiagnosis();
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]): void {
    const selectedIndex = this.riskService.data.value.selectedEncounterIndex;
    this.riskService
      .save(attributes, true)
      .subscribe(
        newAttributes => this.riskService.setEncounterAttributes(selectedIndex, newAttributes)
      );
  }

  get hasClinicalTerms(): boolean {
    return ArrayHelper.isAvailable(this.clinicalTerms);
  }

  onPageChange(pageNumber: number): void {
    this.documentViewerService.updateDataEntryPageNumber(pageNumber);
  }

  handleReadOnly(event) {
    this.isReadOnly = event;
  }
}
