export enum StateMachineExecutionStatus {
    PermanentError = -10,
    Cancelled = -9,
    Running = -4,
    Continue = -3,
    Waiting = -2,
    Retry = -1,
    Unprocessed = 0,
    Completed = 1,
}
