
import { List } from "immutable";
import { UserModel } from "../../../core/user/user-model";
import { StringHelper } from "../../../utilities/contracts/string-helper";


export class UserGroupItem {
  readonly groupName: string;
  readonly userChatGroup: List<UserModel>;

  constructor(options: {
    groupName: string;
    userChatGroup: List<UserModel>;
  }) {
    this.groupName = StringHelper.clean(options.groupName);
    this.userChatGroup = options.userChatGroup;
  }
}

