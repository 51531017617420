<div class="member-home-container">
  <div class="header bold" data-qa="member-page-header">MEMBER</div>
  <div class="dashboard-container">
    <app-header-statistics [headerStatistics]="memberStatisticsForHeader"></app-header-statistics>
  </div>

    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="labelModelMemberComplianceCode !== undefined">
        <!-- MemberCompliance Bar Chart -->
        <app-kpi [cModel]="chartModelMemberComplianceCode" [cDataLabels]="labelModelMemberComplianceCode" [cDataModel]="arrMemberComplianceCode" [cOptions]="dataOptionsMemberComplianceCode"></app-kpi>
      </div>
    </div>

    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="labelModelExemptedMembers !== undefined">
        <!-- Exempted Members Bar Chart -->
        <app-kpi [cModel]="chartModelExemptedMembers" [cDataLabels]="labelModelExemptedMembers" [cDataModel]="arrExemptedMembers" [cOptions]="dataOptionsExemptedMembers"></app-kpi>
      </div>
    </div>

  </div>
