import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
export class TagBulkUpdate {
  readonly clientId: string;
  readonly chaseIds: string[];
  readonly chaseIdCsv: string;
  readonly addressIds: string[];
  readonly addressIdCsv: string;
  readonly documentIds?: string[];
  readonly documentIdCsv?: string;
  readonly tagId: number;
  readonly replaceTagId: number;
  readonly tagItems: SelectableInput[];
  readonly notes: string;
  readonly bulkAction: number;
  readonly organizationId: number;
  readonly tagTypeId: number;
  readonly tagSourceId: number;
  readonly manageTagActionId: number;
  readonly projectId?: number;

  constructor(options: {
    clientId: string;
    chaseIds: string[];
    chaseIdCsv: string;
    addressIds: string[];
    addressIdCsv: string;
    documentIds?: string[];
    documentIdCsv?: string;
    tagId: number;
    replaceTagId: number;
    tagItems: SelectableInput[];
    notes: string;
    bulkAction: number;
    organizationId: number;
    tagTypeId: number;
    tagSourceId: number;
    manageTagActionId: number;
    projectId?: number;
  }) {
    this.clientId = options.clientId;
    this.chaseIds = options.chaseIds;
    this.chaseIdCsv = options.chaseIdCsv;
    this.addressIds = options.addressIds;
    this.addressIdCsv = options.addressIdCsv;
    this.documentIds = options.documentIds;
    this.documentIdCsv = options.documentIdCsv;
    this.tagId = options.tagId;
    this.replaceTagId = options.replaceTagId;
    this.tagItems = options.tagItems;
    this.notes = StringHelper.clean(options.notes);
    this.bulkAction = options.bulkAction;
    this.organizationId = options.organizationId;
    this.tagTypeId = options.tagTypeId;
    this.tagSourceId = options.tagSourceId;
    this.manageTagActionId = options.manageTagActionId;
    this.projectId = NumberHelper.isGreaterThan(options.projectId, 0) ? options.projectId : null;
  }
}
