import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { GridView } from "./grid-view.model";

export const mapGridViewsState = (automapper: IAutoMapper): void => {
  automapper
    .createMap("GridViews", "GridViewsState")
    .forMember("gridName", o => o.gridName)
    .forMember("views", o => o.views.map(automapper.curry("GridView", "GridView")))
    .forMember("selectedIndex", o => null)
    .forMember("defaultIndex", o => 0)
    .forMember("publicIndex", o => o.views.findIndex(x => x.organizationId));
};

export class GridViewsState {
  gridName: string;
  views: GridView[];
  selectedIndex: number | null;
  defaultIndex: number;
  publicIndex: number;


  constructor(options: Partial<GridViewsState>) {
    this.gridName = StringHelper.clean(options.gridName);
    this.views = ArrayHelper.clean(options.views);
    this.defaultIndex = options.defaultIndex;
    this.publicIndex = options.publicIndex;

    // Note: Keep this last
    this.setSelectedIndex(options.selectedIndex);
  }


  get selectedView(): GridView {
    return ArrayHelper.isAvailable(this.views) ? this.views[this.selectedIndex] : {} as any;
  }

  setSelectedIndex(value: number | null): void {
    this.selectedIndex = NumberHelper.isBetween(value, 0, this.views.length, "[)") ? value : this.publicIndex !== -1 ? this.publicIndex : this.defaultIndex;
  }
}
