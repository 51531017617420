import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class AddressListItem {
  readonly addressId: number;
  readonly address: string;
  readonly chaseCount: number;
  readonly status: string;
  readonly phone: number;
  readonly documentSourceTypeId: number;

  constructor(options: {
    addressId: number;
    address: string;
    chaseCount: number;
    status: string;
    phone: number;
    documentSourceTypeId: number;
  }) {
    this.addressId = options.addressId;
    this.address = StringHelper.clean(options.address);
    this.chaseCount = options.chaseCount;
    this.status = options.status;
    this.phone = options.phone;
    this.documentSourceTypeId = options.documentSourceTypeId;
  }
}
