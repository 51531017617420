import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { SessionService } from "../../core/storage/session.service";
import { FormService } from "../../dynamic-forms/form.service";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-sso",
  templateUrl: "./sso.component.html",
  styleUrls: ["./sso.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoComponent implements OnInit {
  formGroup: FormGroup;
  emailInput: Textbox;
  providerInput: Dropdown;
  isProviderVisible = false;
  refresh = false;
  claims: any;
  isSsoConfigured = true;

  constructor(
    private readonly formService: FormService,
    private readonly authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private readonly session: SessionService
    ) { }

  ngOnInit(): void {
    this.createForm();

    const email = this.session.get("ssoLoginEmail", "");
    if (StringHelper.isAvailable(email)) {
      this.formGroup.controls.emailInput.setValue(email);
      this.ssoLogin();
    }
  }

  private createForm(): void {
    this.emailInput = new Textbox({
        key: "emailInput",
        label: "Email",
        autocomplete: "email",
        placeholder: "Enter Email",
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Email is required.",
        },
      });
    this.providerInput = new Dropdown({
        key: "providerInput",
        label: "Provider",
        placeholder: "Select Service Organization",
        hidden: true,
        validators: [Validators.required],
        errorMessages: {
          required: "Provider is required",
        },
      });

    this.formGroup = this.formService.createFormGroup([this.emailInput, this.providerInput]);
  }

  ssoLogin(): void {
    if (!StringHelper.isAvailable(this.formGroup.get(this.providerInput.key).value)) {
      const email = this.formGroup.get(this.emailInput.key).value;
      if (StringHelper.isAvailable(email)) {
        this.session.put("ssoLoginEmail", email);
        this.authService.getSsoConfig(email)
        .pipe(map((x: any) => x.map(item => new SelectableInput({text: item.key, value: item.value, extra: item.url}))))
        .subscribe(options => {
          if (ArrayHelper.isAvailable(options)) {
            if (options.length > 1) {
              this.isSsoConfigured = true;
              this.providerInput = new Dropdown({ ...this.providerInput, options, hidden: false });
              this.emailInput = new Textbox({ ...this.emailInput, readonly: true });
            } else {
              this.onProviderSelect(options[0].value, options[0].extra);
              window.location.href = "login/sso-redirect";
            }
          } else {
            this.isSsoConfigured = false;
          }
          this.changeDetector.markForCheck();
        });
      } else {
        this.formService.markAllAsTouched(this.formGroup);
      }
    } else {
      window.location.href = "login/sso-redirect";
    }
  }

  onProviderSelect(clientId: string, directoryIdUrl: string) {
    if (StringHelper.isAvailable(directoryIdUrl)) {
      this.session.put("ssoDirectoryIdUrl", directoryIdUrl);
    } else {
      this.session.put("ssoDirectoryIdUrl", this.providerInput.options.find(x => x.value === clientId).extra);
    }
    this.session.put("ssoClientId", clientId);
    this.session.put("ssoClientIdValidation", clientId);
  }
}
