<div *ngIf="open; else: openButton" class="dev-controller-container" [ngStyle]="styles">
  <div class="top-bar-container" (mousedown)="startTrackingMouse($event)" (document:mouseup)="stopTrackingMouse()">
    <div class="close-button" (click)="open = false;"></div>
  </div>
  <div class="body-container">
    <dev-action-button *ngFor="let control of controls; trackBy: trackByIndex" [model]="control"></dev-action-button>
  </div>
</div>
<ng-template #openButton>
  <div 
  [title]="buttonTitle"
  class="open-button" 
  (click)="openDevController()" 
  [ngClass]="{ 'disabled': !hasControls }"></div>
</ng-template>