export enum LookerIvaDashboardType {
  IVAOverview = 36,
  PendsSummary = 51,
  ClinicalSummary = 75,
  IvaProjectSummary = 89,
  Top50AidsWithOpenChases = 105,
  ProjectStatus = 102,
  PendReport = 103,
  ClinicalAccuracyByChaseWithErrorDetailReport = 108,
  ClinicalProductivityAndAccuracy = 106,
  ClinicalProductivityReport = 126,
  ClientOverread2Report = 127,
  CoderProductivityReport = 128,
  RetrievalReport = 129,
  RetrievalReportByChase = 130,
  IntakeProductivityReport = 131,
  UserProductivityReport = 132,
  CallCountDetailReportLegacy = 133,
  ProviderOutreachByContactTypeReport = 135,
  TrainingFeedbackReport = 136,
  FtDailySummaryReport = 137,
  AutoCloseReport = 146,
  NlpIcdDashboard = 157,
  SftpReport = 159,
  RXCStatusReport = 162,
  ENRStatusReport = 163,
  HCCByStratum = 164,
  HCCByEnrolleeID = 165,
  IVADiagnosisData = 166,
  ProviderValidationReport = 167,
  HSTPrioritization = 189,
  ChaseMoveBackReport = 190,
  ATTPrioritization = 191,
  DetailClinicalProductivityAndAccuracyOverall = 285,
  ProgramRetrievalProjections = 305,
  IvaClinicalProductionInventoryQAScores = 306,
  IvaClientOverread2HistoryReport = 315,
  ChaseMoveBackHistoryReportLegacy = 318,
  PendSummaryNew = 338,
  RetrievalSummary = 339,
  DobDiscrepancy = 361,
  EdgeHccPerformanceSummary = 380,
  CustomerInvoiceDetail = 391,
  ChartRouting = 393,
  PendReportDW = 411,
  CallCountDetailReport = 426,
  ChaseMoveBackHistoryReport = 430,
  LifeOfAChaseDW = 447,
  ChaseMoveReport = 466,
  ChaseCountsByStatusReport = 498,
  DobDiscrepancyDW = 511,
  SLAMilestoneReport = 512,
  ChaseStatusUnderlyingReport = 533,
  ProjectStatusDW= 554,
  RetrievalSummaryDw = 591,
  TopAidsWithOpenChases = 592,
  PendSummaryDW = 600,
  ChaseMoveReportDW = 619,
}
