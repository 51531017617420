// TODO: If we use ScreenObject then do we need this?
export enum EntityType {
  WORKFLOW = 1,
  MEMBER_VALIDATION = 91,
  MISCELLANEOUS = 99,
  ENCOUNTER = 114,
  DIAGNOSIS = 115,
  CONFIRMATION = 120,
  SIGNATURE_CONFIRMATION = 176,
}
