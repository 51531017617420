import { dateTypes } from "../../../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";

export class AddressDetailInfoAccessEdit {
  adminContactName?: string;
  adminContactPhone?: string;
  itContactName?: string;
  itContactPhone?: string;
  emrSystem?: string;
  editNotes?: string;
  url: string;
  credentialStatus: string;
  twoFactorAuthorization: string;
  userName: string;
  password: string;
  expirationDate: dateTypes;

  constructor(options: Partial<AddressDetailInfoAccessEdit> = {}) {
    this.adminContactName = options.adminContactName || "";
    this.adminContactPhone = options.adminContactPhone;
    this.itContactName = options.itContactName || "";
    this.itContactPhone = options.itContactPhone;
    this.emrSystem = options.emrSystem || "";
    this.editNotes = options.editNotes || "";
    this.url = StringHelper.clean(options.url);
    this.credentialStatus = StringHelper.clean(options.credentialStatus);
    this.twoFactorAuthorization = options.twoFactorAuthorization;
    this.userName = StringHelper.clean(options.userName);
    this.password = StringHelper.clean(options.password);
    this.expirationDate = options.expirationDate;
  }

}
