import { Autocomplete } from "../../inputs/autocomplete/autocomplete.model";
import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { ConditionalNrcGroupComponent } from "./conditional-nrc-group.component";


export interface IConditionalNrcGroupOptions extends IDynamicGroupOptions {
  header?: string;
}

export class ConditionalNrcGroup extends DynamicGroup implements IConditionalNrcGroupOptions {
  header: string;

  readonly controlType = "conditional-nrc-group";
  readonly componentType = ConditionalNrcGroupComponent;


  constructor(options: IConditionalNrcGroupOptions = {}) {
    super(options);
    this.header = options.header || "";
  }


  get nrcControl(): Autocomplete {
    return this.controls[0] as Autocomplete;
  }
}
