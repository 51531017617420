import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-validation-reason-code",
  templateUrl: "./validation-reason-code.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationReasonCodeComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.VALIDATION_REASON_CODE_REPORT;

  constructor() {
    this.filters = ["Projects", "VrcCode", "Products", "LOB", "RetrievalOwner", "ChaseTags", "AbstractionDate"];
  }

}
