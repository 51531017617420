import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";

@NgModule({
  declarations: [
  ],
  imports: [
    ButtonModule,
    GridModule,
    DynamicFormsModule,
  ],
  exports: [
    DynamicFormsModule,
  ],
})
export class NationalProviderGridModule { }
