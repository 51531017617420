import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapProjectTemplateModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProjectTemplateModel")
    .forMember("templateId", o => o.templateId)
    .forMember("name", o => o.name);
};

export class ProjectTemplateModel {
  readonly templateId: number;
  readonly name: string;

  constructor(options: {
    templateId: number;
    name: string;
  }) {
    this.templateId = options.templateId;
    this.name = options.name;
  }
}
