
export class ChartLakeReviewModel {
    readonly chaseID: number;
    readonly measureCode: string;
    readonly MasterMemberId: number;
    readonly memberSourceAliasID: string;
    readonly memberFirstName: string;
    readonly memberLastName: string;
    readonly memberDateOfBirth: string;
    readonly serviceProviders?: boolean;
    readonly chaseDocumentPageCount?: number;
    readonly dateOfService?: string;
    readonly projectName?: string;
    readonly chartAcquired?: string;
    readonly serviceProviderName?: string;
    readonly chaseMemberList: ChartLakeReviewModel[];
    constructor(options: {
      chaseID: number;
      measureCode: string;
      MasterMemberId: number;
      memberSourceAliasID: string;
      memberFirstName: string;
      memberLastName: string;
      memberDateOfBirth: string;
      serviceProviders?: boolean;
      chaseDocumentPageCount?: number;
      dateOfService?: string;
      projectName?: string;
      chartAcquired?: string;
      serviceProviderName?: string;
      chaseMemberList: ChartLakeReviewModel[];
  }) {
        this.chaseID = options.chaseID;
        this.measureCode = options.measureCode;
        this.MasterMemberId = options.MasterMemberId;
        this.memberSourceAliasID = options.memberSourceAliasID;
        this.memberFirstName = options.memberFirstName;
        this.memberLastName = options.memberLastName;
        this.memberDateOfBirth = options.memberDateOfBirth;
        this.serviceProviders = options.serviceProviders;
        this.chaseDocumentPageCount = options.chaseDocumentPageCount;
        this.dateOfService = options.dateOfService;
        this.projectName = options.projectName;
        this.chaseMemberList = options.chaseMemberList;
        this.chartAcquired = options.chartAcquired;
        this.serviceProviderName = options.serviceProviderName;
    }
}

