import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-clinical-accuracy-by-chase-with-error-detail-legacy",
  templateUrl: "./clinical-accuracy-by-chase-with-error-detail-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalAccuracyByChaseWithErrorDetailLegacyComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CLINICAL_ACCURACY_BY_CHASE_WITH_ERROR_DETAIL_LEGACY;

  constructor() {
    this.filters = ["Projects", "Products", "LOB", "RetrievalOwner"];
  }

}
