export class StringHelper {
  static isAvailable(value: string | number): boolean {
    if (typeof value !== "string") {
      return false;
    }

    if (value.trim() === "") {
      return false;
    }

    return true;
  }

  static throwIsAvailableError(value: string, propertyName: string): void {
    if (!this.isAvailable(value)) {
      throw new Error(`The property '${propertyName}' cannot be null, empty, or whitespace.`);
    }
  }

  static clean(value: string, defaultValue = ""): string {
    const cleanValue = this.isAvailable(value)
      ? value.trim()
      : defaultValue;

    return cleanValue;
  }

  static removeNonAlphaNumericCharacters(value: string): string {
    if (!this.isAvailable(value)) {
      return "";
    }

    // This will also keep underscores.
    // https://stackoverflow.com/a/9364527/2573621
    return value.replace(/\W/g, "");
  }

  static removeValueWithSeparator(list: string, value: string, separator: string = ","): string {
    // https://stackoverflow.com/questions/1306164/remove-value-from-comma-separated-values-string
    const values = list.split(separator);
    for (let i = 0; i < values.length; i++) {
      if (values[i] === value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }

  static replaceAll(value: string, find: string, replace: string): string {
    // https://stackoverflow.com/a/1144788/2573621
    const safeFind = StringHelper.escapeRegExp(find);
    const regEx = new RegExp(safeFind, "g");
    const result = value.replace(regEx, replace);
    return result;
  }

  static toTitleCase(value: string) {
    const cleanValue = this.clean(value);
    if (!this.isAvailable(cleanValue)) {
      return "";
    }

    const words = cleanValue.split(/\s+/g);
    const titleCaseWords = words.map(word => {
      const firstLetter = word.substring(0, 1);
      const upperCaseFirstLetter = firstLetter.toUpperCase();

      const otherLetters = word.substring(1);
      const lowerCaseLetters = otherLetters.toLowerCase();

      const titleCaseWord = `${upperCaseFirstLetter}${lowerCaseLetters}`;
      return titleCaseWord;
    });

    const phrase = titleCaseWords.join(" ");
    return phrase;
  }

  static createId(): string {
    // https://stackoverflow.com/a/19842865/2573621
    return Math.random().toString(16).slice(2);
  }

  private static escapeRegExp(value: string): string {
    const result = value.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return result;
  }

  static isEmptyGuid(value: string): boolean {
    return value.trim() === "00000000-0000-0000-0000-000000000000";
  }

  static compareNumbersForSortASC(a: number, b: number): number {
    return a - b;
  }

  static compareNumbersForSortDESC(a: number, b: number): number {
    return b - a;
  }
}
