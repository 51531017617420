import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  @Input() key = "";

  @Output() onClose: EventEmitter<any> = new EventEmitter();
}
