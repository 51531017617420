import { DocumentThumbnailType } from "./document-thumbnail-type.enum";

export class DocumentThumbnail {
  thumbnailBackgroundColor: string;
  thumbnailSpineColor: string;
  thumbnailType: DocumentThumbnailType;

  constructor(options: Partial<DocumentThumbnail> = {}) {
    this.thumbnailBackgroundColor = options.thumbnailBackgroundColor;
    this.thumbnailSpineColor = options.thumbnailSpineColor;
    this.thumbnailType = options.thumbnailType;
  }

  get thumbnailColors(): any[] {
    return [
      { background: "CFE2F3", spine: "4D81D9" },
      { background: "D7E9D3", spine: "57C785" },
      { background: "FEF1CC", spine: "F8C020" },
      { background: "F7CBCA", spine: "E97588" },
      { background: "E7CBED", spine: "B45AC9" },
    ];
  }
}
