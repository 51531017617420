export class AddressSearchRequest {
  readonly retrievalLocationId?: number;
  readonly addressGroup?: string;
  readonly address1?: string;
  readonly address2?: string;
  readonly addressCity?: string;
  readonly addressState?: string;
  readonly addressZip?: string;
  readonly contactData?: string;
  readonly name?: string;
  readonly memberFirstName?: string;
  readonly memberLastName?: string;
  readonly memberDateOfBirth?: string;
  readonly notes?: string;
  readonly groupName?: string;
  readonly phone?: string;
  readonly faxNumber?: string;
  readonly contactName?: string;
  readonly sortDirection?: string;
  readonly sortField?: string;
  readonly getAidWithOpenChaseOnly: boolean;

  constructor(options: {
    readonly retrievalLocationId?: number;
    readonly addressGroup?: string;
    readonly address1?: string;
    readonly address2?: string;
    readonly addressCity?: string;
    readonly addressState?: string;
    readonly addressZip?: string;
    readonly contactData?: string;
    readonly memberFirstName?: string;
    readonly memberLastName?: string;
    readonly memberDateOfBirth?: string;
    readonly notes?: string;
    readonly groupName?: string;
    readonly phone?: string;
    readonly faxNumber?: string;
    readonly contactName?: string;
    readonly sortDirection?: string;
    readonly sortField?: string;
    readonly getAidWithOpenChaseOnly?: boolean;
  }) {

    this.retrievalLocationId = options.retrievalLocationId;
    this.addressGroup = options.addressGroup;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.addressCity = options.addressCity;
    this.addressState = options.addressState;
    this.addressZip = options.addressZip;
    this.contactData = options.contactData;
    this.memberFirstName = options.memberFirstName;
    this.memberLastName = options.memberLastName;
    this.memberDateOfBirth = options.memberDateOfBirth;
    this.notes = options.notes;
    this.groupName = options.groupName;
    this.phone = options.phone;
    this.faxNumber = options.faxNumber;
    this.contactName = options.contactName;
    this.getAidWithOpenChaseOnly = options.getAidWithOpenChaseOnly;

    this.sortDirection = options.sortDirection;
    this.sortField = options.sortField;

  }
}
