import { IAutoMapper } from "../interfaces";

export const mapDocumentPage = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentPage")
    .forMember("createDate", o => o.createDate)
    .forMember("documentPageId", o => o.pageId)
    .forMember("documentPageNumber", o => o.documentPageNumber)
    .forMember("documentQueueId", o => o.documentQueueId)
    .forMember("documentTypeId", o => o.pageTypeId)
    .forMember("isCdnChartImageExist", o => o.isCdnChartImageExist)
    .forMember("image", o => o.image)
    .forMember("imagePath", o => o.imagePath)
    .forMember("imageType", o => o.imageType)
    .forMember("isNewPage", o => o.isNewPage)
    .forMember("lastModified", o => o.lastModified)
    .forMember("numberOfPages", o => o.numberOfPages)
    .forMember("ocrMatches", o => automapper.mapMany("default", "OcrMatch", o.ocrMatches))
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("rowSerialNumber", o => o.rowSerialNumber)
    .forMember("viewed", o => o.viewed)
    .forMember("evidence", o => o.evidence)
    .forMember("recordCount", o => o.recordCount);
};
