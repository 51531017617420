<app-detail-main-template>
  <div container-header>
    <h3>{{headerTitle}}</h3>
    <div class="sub-menu__container--new-removed-page ">
      <div class="item">
        <a class="link"
           [ngClass]="selectedTabs('new')"
           (click)="toggleTab('new')">
          <div class="button">
            <div class="description">New </div>
          </div>
        </a>
      </div>
      <div class="item">
        <a class="link"
           [ngClass]="selectedTabs('removed')"
           (click)="toggleTab('removed')">
          <div class="button">
            <div class="description">Removed </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div container-body>

    <!--<h3>{{headerTitle}}</h3>-->
    <!--TODO: Uncomment for the next sprint when DB becomes available
    <div class="newpages-container">
      <div class="newpages-container__item" *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex">
        <span>{{item.key}}</span><br>
        <span class="value bold">{{item.value}}</span>
      </div>
    </div>-->

    <div class="ui-g ui-fluid  section tabContent">
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="this.selectedTab === 'new'">
        <app-server-grid [configuration]="newOrRemovedGridConfiguration"
                         [(request)]="newOrRemovedGridSearchRequest"
                         [(selection)]="newOrRemovedPagesSelection"
                         [actions]="actions"
                         [refresh]="refreshGrid"
                         [filterTemplate]="filterTemplate"
                         [actionTemplate]="actionTemplate"
                         (dataLoaded)="gridDataLoaded($event)"
                         [isMemberFile]="true">
                         
            <ng-template #filterTemplate let-form>
              <form [formGroup]="form">
              <app-tab-menu orientation="left">
                <app-tab-panel header="Assigned To">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__fourths" header="Chase Compliance">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="complianceInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel header="Chase ID / Client Chase Key">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__halves" header="Health Plans">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="plansInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__fourths" header="Measures">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__halves" header="Projects">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
                  </div>
                </app-tab-panel>
                <app-tab-panel class="checkbox-group__halves" header="Status">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="statusesInput"></form-factory>
                  </div>
                </app-tab-panel>
              </app-tab-menu>
            </form>
          </ng-template>

          <ng-template #actionTemplate
                       let-rowData let-localActionPopover="actionPopover">
            <div class="action-template-container">
              <app-button *ngFor="let action of rowActions; trackBy: trackByIndex"
                          [text]="action.name"
                          (onClick)="action.action(rowData); localActionPopover.hide();"
                          [disabled]="action.disabled"></app-button>
            </div>
          </ng-template>

        </app-server-grid>

        <div class="newpages-container__no-pages" *ngIf="noNewOrRemovedPages">
          No New Pages to Review
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="this.selectedTab === 'removed'">
        <app-server-grid [configuration]="newOrRemovedGridConfiguration"
                         [(request)]="newOrRemovedGridSearchRequest"
                         [(selection)]="newOrRemovedPagesSelection"
                         [actions]="actions"
                         [refresh]="refreshGrid"
                         [filterTemplate]="filterTemplate"
                         [actionTemplate]="actionTemplate"
                         (dataLoaded)="gridDataLoaded($event)"
                         [isMemberFile]="true">

          <ng-template #filterTemplate let-form>
            <form [formGroup]="form">
              <app-tab-menu orientation="left">

                <app-tab-panel header="Chase ID / Client Chase Key">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel class="checkbox-group__halves" header="Projects">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel class="checkbox-group__fourths" header="Measures">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel class="checkbox-group__fourths" header="Chase Compliance">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="complianceInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel class="checkbox-group__halves" header="Status">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="statusesInput"></form-factory>
                  </div>
                </app-tab-panel>

                <app-tab-panel header="Assigned To">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
                  </div>
                </app-tab-panel>

              </app-tab-menu>
            </form>
          </ng-template>

          <ng-template #actionTemplate
                       let-rowData let-localActionPopover="actionPopover">
            <div class="action-template-container">
              <app-button *ngFor="let action of rowActions; trackBy: trackByIndex"
                          [text]="action.name"
                          (onClick)="action.action(rowData); localActionPopover.hide();"
                          [disabled]="action.disabled"></app-button>
            </div>
          </ng-template>

        </app-server-grid>

        <div class="newpages-container__no-pages" *ngIf="noNewOrRemovedPages">
          No Removed Pages to Review
        </div>
      </div>
    </div>
  </div>
</app-detail-main-template>


