import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ProjectType } from "../project/project-type.enum";

export class EntityAttribute {
  readonly projectIdsAsCsv?: string;
  readonly projectTypeId?: ProjectType;
  readonly attributeId?: number;

  constructor(options: Partial<EntityAttribute> = {}) {
    this.projectIdsAsCsv = StringHelper.clean(options.projectIdsAsCsv);
    this.projectTypeId = options.projectTypeId;
    this.attributeId = options.attributeId;
  }
}

