<div class="ui-md-10 ui-md-offset-2 final-review">
  <div class="ui-fluid text-center">
    <h1 class="final-header">you're almost done!</h1>
    <h2 class="header-text">review the information below, then click finalize.</h2>
    <form class="final-detail">
      <div class="ui-g-6 ui-md-1 ui-lg-2 text-left" *ngIf="!isPursuit">
        <h3>CHASE KEY</h3>
        {{chaseKey}}
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left" *ngIf="!isPursuit">
        <h3>PROJECT</h3>
        {{projectName}}
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left" *ngIf="!isPursuit">
        <h3>MEMBER</h3>
        {{memberName}}
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left" *ngIf="!isPursuit">
        <h3>MEMBER DOB</h3>
        {{memberDob}}
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left" *ngIf="!isPursuit">
        <h3>ADDRESS</h3>
        {{address}}
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left">
        <h3>PROVIDERS</h3>
        <div *ngFor="let name of providerNames; trackBy: trackByIndex">{{name}}</div>
      </div>
      <div class="ui-g-6 ui-md-2 ui-lg-2 text-left" *ngIf="isShowMeasureText && !isPursuit">
        <h3>MEASURE</h3>
        {{measureCode}}
      </div>
      <div *ngIf="isShowEncounterDiagnosisText" class="ui-g-6 ui-md-1 ui-lg-2 text-left">
        <h3>ENCOUNTERS</h3>
        {{encounters}}
      </div>
      <div *ngIf="isShowEncounterDiagnosisText" class="ui-g-6 ui-md-1 ui-lg-2 text-left">
        <h3>DIAGNOSIS</h3>
        {{diagnosis}}
      </div>
      <div *ngIf="isShowGapText" class="ui-g-6 ui-md-1 ui-lg-1 text-left">
        <h3>GAPS</h3>
        {{gaps}}
      </div>
      <div class="ui-g-12 ui-md-12 text-center padding-bottom-0 process-button mt-5">
        <app-button class="button" text="FINALIZE" [disabled]="isDisabled" (onClick)="newChaseAction()"></app-button>
      </div>
    </form>
  </div>
</div>
