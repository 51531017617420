<app-modal class="error-container"
           [(visible)]="visible"
           [foreground]="false"
           (onHide)="clearErrors()"
           header="You have the following error(s) with your submission:">

  <app-button class="button"
              *ngFor="let error of errors; trackBy: trackByIndex"
              [immutableText]="getDisplayText(error)"
              (onClick)="gotoError(error)"></app-button>

</app-modal>
