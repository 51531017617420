import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class HighlightAnnotationService {
  private isEditing = new BehaviorSubject<boolean>(false);
  private shouldReload = new BehaviorSubject<number>(0);
  isEditing$ = this.isEditing.asObservable();
  shouldReload$ = this.shouldReload.asObservable();

  isEditingHighlight(editStatus: boolean): void {
    this.isEditing.next(editStatus);
  }

  reloadPage(changeStatus: number): void {
    this.shouldReload.next(changeStatus);
  }

  hexToDec(hex) {
    return parseInt(hex, 16);
  }

  hexToRGB(hexColor): string {
    let color = "";
    switch (hexColor) {
      case "#D1D0FC":
        color = "#7156BE";
        break;
      case "#BFECFC":
        color = "#4499CA";
        break;
      case "#FFFF00":
        color = "#E9BD36";
        break;
      case "#F8B7BD":
        color = "#B44646";
        break;
      case "#DEFBD3":
        color = "#41C276";
        break;
      default:
        color = hexColor;
        break;
    }
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return `rgba(${this.hexToDec(result[1])}, ${this.hexToDec(result[2])}, ${this.hexToDec(result[3])}, 0.4)`;
  }
}
