<app-button
  *ngIf="hasViews"
  class="item"
  classes="small secondary"
  text="Create New View"
  (onClick)="onCreateNewViewClick($event)">
</app-button>
<app-grid-view
  *ngFor="let view of gridViews; index as index; trackBy: trackByIndex"
  class="item item--link"
  [active]="getActiveClass(index)"
  [gridView]="view"
  [onEditGridView]="onEditGridView"
  (click)="onSelectView($event, view)">
</app-grid-view>

<app-grid-view-editor
  *ngIf="isGridViewEditorVisible"
  [(visible)]="isGridViewEditorVisible"
  (onHide)="closeGridViewEditor()"
  [configuration]="configuration"
  [gridView]="existingGridView"
  [gridViewState]="views"
  [refreshViews]="refreshViews"
  [displayCheckedItemsInGridView]="displayCheckedItemsInGridView">
</app-grid-view-editor>