<div
  [formGroup]="formGroup"
  class="control control--group"
  [ngClass]="highlighter.className()"
  [hidden]="hidden">
  <div *ngIf="model.hasHeader" class="control__header">
    <div class="control__header__label">{{ model.header }}</div>
  </div>
  <form-factory
    *ngFor="let model of model.controls; let j = index; trackBy: trackByIndex"
    [formGroup]="control"
    [model]="model"
    (onChange)="onChange.emit($event)"
    (onFocus)="highlighter.onFocus()"
    (onBlur)="highlighter.onBlur()">
  </form-factory>
</div>