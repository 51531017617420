import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../../shared/list/list-item";

@Injectable({
  providedIn: "root",
})
export class ClinicalService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getStatisticsData(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}dashboard/clinical/statistics`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  getAverageTimeChaseKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/clinical/averagetimechase`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getChasesCompletedKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/clinical/chasescompleted`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getNextChaseId(roleId: number): Observable<number> {
    const url = `${this.baseApiUrl}dashboard/nextchase?functionalRoleId=${roleId}`;
    return this.http.get(url, { observe: "response" }).pipe(map((response: HttpResponse<any>): number => response.ok ? response.body : 0));
  }

  getNextEnabled(): Observable<boolean> {
    const url = `${this.baseApiUrl}dashboard/clinical/getnextenabled`;
    return this.http.get(url).pipe(map((response: any) => response as boolean));
  }
}
