import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-customer-invoice-detail",
  templateUrl: "./customer-invoice-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerInvoiceDetailComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.CUSTOMER_INVOICE_DETAIL;

  constructor() {
    this.filters = ["Projects", "BillingDate"];
  }

}
