import { ClientNodeModel } from "./client-node.model";

export class ServiceOrgNodeModel {
  readonly organizationId: number;
  readonly organizationName: string;
  readonly clients: ClientNodeModel[];
  constructor(options: {

   organizationId: number;
   organizationName: string;
   clients: ClientNodeModel[];
  }) {

    this.organizationId = options.organizationId;
    this.organizationName = options.organizationName;
    this.clients = options.clients;

  }
}
