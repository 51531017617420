import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { HealthCardIcd } from "./health-card-icd";

export const mapChaseDetailHealthCard = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChaseDetailHealthCard")
    .forMember("addedDiagnosisCount", o => o.addedDiagnosisCount)
    .forMember("categoryCode", o => o.categoryCode)
    .forMember("categoryId", o => o.categoryId)
    .forMember("categoryName", o => o.categoryName)
    .forMember("categorySource", o => o.categorySource)
    .forMember("categoryStatus", o => o.categoryStatus)
    .forMember("deletedDiagnosisCount", o => o.deletedDiagnosisCount)
    .forMember("diagnosisData", o => o.diagnosisData)
    .forMember("healthCardDiagnosesData", o => {
      const isArray = Array.isArray(o.healthCardDiagnosesData.healthCardDiagnosisData) && o.healthCardDiagnosesData.healthCardDiagnosisData.length > 0;
      return isArray ? automapper.mapMany("default", "HealthCardIcd", o.healthCardDiagnosesData.healthCardDiagnosisData) : {};
    })
    .forMember("modelDisplayName", o => o.modelDisplayName)
    .forMember("modelId", o => o.modelId)
    .forMember("modelType", o => o.modelType)
    .forMember("modelName", o => o.modelName)
    .forMember("notCodedDiagnosisCount", o => o.notCodedDiagnosisCount)
    .forMember("radvdeHccFlag", o => o.radvdeHccFlag)
    .forMember("validatedDiagnosisCount", o => o.validatedDiagnosisCount);
};
export class ChaseDetailHealthCard {
  addedDiagnosisCount?: number;
  categoryCode?: number;
  categoryId?: number;
  categoryName?: string;
  categorySource?: string;
  categoryStatus?: string;
  deletedDiagnosisCount?: number;
  diagnosisData?: string;
  healthCardDiagnosesData?: HealthCardIcd[];
  modelDisplayName?: string;
  modelId?: number;
  modelType?: string;
  modelName?: string;
  notCodedDiagnosisCount?: number;
  radvdeHccFlag?: number;
  validatedDiagnosisCount?: number;

  constructor(options: Partial<ChaseDetailHealthCard> = {}) {
    this.addedDiagnosisCount = options.addedDiagnosisCount;
    this.categoryCode = options.categoryCode;
    this.categoryId = options.categoryId;
    this.categoryName = StringHelper.clean(options.categoryName);
    this.categorySource = StringHelper.clean(options.categorySource);
    this.categoryStatus = StringHelper.clean(options.categoryStatus);
    this.deletedDiagnosisCount = options.deletedDiagnosisCount;
    this.diagnosisData = options.diagnosisData;
    this.healthCardDiagnosesData = options.healthCardDiagnosesData;
    this.modelDisplayName = StringHelper.clean(options.modelDisplayName);
    this.modelId = options.modelId;
    this.modelType = StringHelper.clean(options.modelType);
    this.modelName = StringHelper.clean(options.modelName);
    this.notCodedDiagnosisCount = options.notCodedDiagnosisCount;
    this.radvdeHccFlag = options.radvdeHccFlag;
    this.validatedDiagnosisCount = options.validatedDiagnosisCount;
  }
}
