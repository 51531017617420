import { Injectable } from "@angular/core";
import { MessagingService } from "../messaging/messaging.service";
import { SeverityType } from "../messaging/severity-type.enum";

@Injectable({
  providedIn: "root",
})
export class ClipboardService {
  constructor(private messagingService: MessagingService) { }


  copy(value: string): boolean {
    const isSuccessful = this.tryPerformActionOnHiddenTextArea(this.performCopy(value));

    if (isSuccessful) {
      this.messagingService.showToast("Copied to Clipboard!", SeverityType.SUCCESS);
    } else {
      this.messagingService.showToast("Failed to copy text.", SeverityType.ERROR);
    }

    return isSuccessful;
  }


  private tryPerformActionOnHiddenTextArea(callbackFn: (element: HTMLTextAreaElement) => boolean): boolean {
    const element = document.createElement("textarea");
    element.style.position = "fixed";
    element.style.opacity = "0";
    document.body.appendChild(element);

    const result = callbackFn(element);

    document.body.removeChild(element);
    return result;
  }

  private performCopy(value: string): (element: HTMLTextAreaElement) => boolean {
    return (element: HTMLTextAreaElement) => {
      element.value = value;
      element.focus();
      element.select();
      return document.execCommand("copy");
    };
  }
}
