import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CalendarModule } from "primeng/calendar";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { SelectButtonModule } from "primeng/selectbutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { TabViewModule } from "primeng/tabview";
import { ProgressBarModule } from "../../../shared/progress-bar/progress-bar.module";
import { SharedModule } from "../../../shared/shared.module";
import { AuditPackagesComponent } from "../audit/audit-packages/audit-packages.component";
import { DataLoadModule } from "../data-load/data-load.module";
import { ApprovalCenterComponent } from "./approval-center/approval-center.component";
import { UploadBulkManageTagComponent } from "./approval-center/upload-bulk-manage-tag/upload-bulk-manage-tag.component";
import { UploadBulkComponent } from "./approval-center/upload-bulk/upload-bulk.component";
import { UploadDocumentsComponent } from "./approval-center/upload-documents/upload-documents.component";
import { BulkOutreachArchiveComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-archive/bulk-outreach-archive.component";
import { BulkOutreachNewBulkActionComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-new-bulk-action/bulk-outreach-new-bulk-action.component";
import { BulkOutreachPendingActionsComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-pending-actions/bulk-outreach-pending-actions.component";
import { BulkOutreachComponent } from "./bulk-updates/bulk-outreach/bulk-outreach.component";
import { BulkUpdateAddressComponent } from "./bulk-updates/bulk-update-address/bulk-update-address.component";
import { BulkUpdateChaseComponent } from "./bulk-updates/bulk-update-chase/bulk-update-chase.component";
import { BulkUpdateDocumentComponent } from "./bulk-updates/bulk-update-document/bulk-update-document.component";
import { BulkUpdateManageTagComponent } from "./bulk-updates/bulk-update-manage-tag/bulk-update-manage-tag.component";
import { BulkUpdatePendComponent } from "./bulk-updates/bulk-update-pend/bulk-update-pend.component";
import { BulkUpdateTagComponent } from "./bulk-updates/bulk-update-tag/bulk-update-tag.component";
import { BulkUpdatesComponent } from "./bulk-updates/bulk-updates.component";
import { ChartDownloadComponent } from "./chart-download/chart-download.component";
import { ChaseQueryComponent } from "./chase-query/chase-query.component";
import { DataExtractComponent } from "./data-extract/data-extract.component";
import { GapQueryComponent } from "./gap-query/gap-query.component";
import { InvoiceDownloadComponent } from "./invoice-download/invoice-download.component";
import { IvaSubmissionComponent } from "./iva-submission/iva-submission.component";
import { MyQueriesComponent } from "./my-queries/my-queries.component";
import { NewProjectComponent } from "./new-project/new-project.component";
import { NewProjectService } from "./new-project/new-project.service";
import { ChartChangeActionGridComponent } from "./project-config/chart-change-action-grid/chart-change-action-grid.component";
import { ProjectInvoiceSettingComponent } from "./project-config/invoice-config/invoice-config.component";
import { IvaMappingDocumentComponent } from "./project-config/iva-mapping-document/iva-mapping-document.component";
import { ProjectConfigComponent } from "./project-config/project-config.component";
import { ProjectFilesComponent } from "./project-files/project-files.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { ProjectReportsComponent } from "./project-reports/project-reports.component";
import { ProjectRoutingModule } from "./project-routing.module";
import { ProjectComponent } from "./project.component";

@NgModule({
  imports: [
    CalendarModule,
    CommonModule,
    DataLoadModule,
    EditorModule,
    FileUploadModule,
    ProgressBarModule,
    ProjectRoutingModule,
    SelectButtonModule,
    SharedModule,
    SplitButtonModule,
    TabViewModule,
  ],
  declarations: [
    ApprovalCenterComponent,
    AuditPackagesComponent,
    BulkOutreachArchiveComponent,
    BulkOutreachComponent,
    BulkOutreachNewBulkActionComponent,
    BulkOutreachPendingActionsComponent,
    BulkUpdateAddressComponent,
    BulkUpdateChaseComponent,
    BulkUpdateDocumentComponent,
    BulkUpdateManageTagComponent,
    BulkUpdatePendComponent,
    BulkUpdatesComponent,
    BulkUpdateTagComponent,
    ChartChangeActionGridComponent,
    ChartDownloadComponent,
    ChaseQueryComponent,
    DataExtractComponent,
    GapQueryComponent,
    InvoiceDownloadComponent,
    IvaMappingDocumentComponent,
    MyQueriesComponent,
    NewProjectComponent,
    ProjectComponent,
    ProjectConfigComponent,
    ProjectFilesComponent,
    ProjectInvoiceSettingComponent,
    ProjectListComponent,
    ProjectReportsComponent,
    UploadBulkComponent,
    UploadDocumentsComponent,
    UploadBulkManageTagComponent,
    IvaSubmissionComponent,

  ],
  providers: [
    NewProjectService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ProjectModule { }
