import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DocumentPage } from "../../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPageNlpMatches } from "../document-page-nlp/document-page-nlp-matches.model";
import { NlpWord } from "../document-page-nlp/nlp-word.model";

@Component({
  selector: "app-document-page-highlight-ocr-mapping",
  templateUrl: "./document-page-highlight-ocr-mapping.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageHighlightOcrMappingComponent {
  @Input() page: DocumentPage;
  @Input() nlpData: DocumentPageNlpMatches;
  @Input() nlpStyles: any;
  @Input() zoomPercentage = 100;

  getNlpWords(page: DocumentPage): NlpWord[] {
    const matchedMemberWords = this.getMatchedNlpWords(page);
    const words = matchedMemberWords == null ? [] : matchedMemberWords;

    return words;
  }

  getMatchedNlpWords(page: DocumentPage): NlpWord[] {
    const matchedWords = this.nlpData.nlpMatches.filter(a => a.pageNumber === page.pageNumber);
    return matchedWords;
  }

  getWordNlpStyles(word: NlpWord): any {
    const isStatusPositive = word.status?.toUpperCase() === "POSITIVE";
    const style = {
      width: `${(word.boundingBoxes[1].x - word.boundingBoxes[0].x) * this.zoomPercentage}%`,
      height: `${(word.boundingBoxes[1].y - word.boundingBoxes[0].y) * this.zoomPercentage}%`,
      top: `${word.boundingBoxes[0].y * this.zoomPercentage}%`,
      left: `${word.boundingBoxes[0].x * this.zoomPercentage}%`,
      position: "absolute",
      "border-width": "3px",
      "border-style": "solid",
      "border-color": isStatusPositive ? "#00FF00" : "#FF0000",
    };

    return style;
  }

  trackByIndex(index, item) {
    return index;
  }

}
