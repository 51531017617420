export class GapSearchRequest {
  chaseIdAndClientChaseKey?: string;
  measuresCodes?: string;
  numeratorCodes?: string;
  clientId?: number;
  providerName?: string;
  projects?: string;
  memberFirstName?: string;
  memberLastName?: string;
  memberDob?: string;
  addressId?: number;
  assignedToUserId?: number;
  chartCommitmentDate?: string;
  statuses?: string;
  gapComplianceCodes?: string;
  complianceCodes?: string;
  sampleComplianceCodes?: string;

  sortField?: string;
  sortDirection?: string;
  startRecord?: number;
  endRecord?: number;

  constructor(options: {
     chaseIdAndClientChaseKey?: string;
     measuresCodes?: string;
     numeratorCodes?: string;
     clientId?: number;
     providerName?: string;
     projects?: string;
     memberFirstName?: string;
     memberLastName?: string;
     memberDob?: string;
     addressId?: number;
     assignedToUserId?: number;
     chartCommitmentDate?: string;
     statuses?: string;
     gapComplianceCodes?: string;
     complianceCodes?: string;
     sampleComplianceCodes?: string;

     sortField?: string;
     sortDirection?: string;
     startRecord?: number;
     endRecord?: number;
  }) {
    this.chaseIdAndClientChaseKey = options.chaseIdAndClientChaseKey;
    this.measuresCodes = options.measuresCodes;
    this.numeratorCodes = options.numeratorCodes;
    this.clientId = options.clientId;
    this.providerName = options.providerName;
    this.projects = options.projects;
    this.memberFirstName = options. memberFirstName;
    this.memberLastName = options.memberLastName;
    this.memberDob = options.memberDob;
    this.addressId = options.addressId;
    this.assignedToUserId = options.assignedToUserId;
    this.chartCommitmentDate = options.chartCommitmentDate;
    this.statuses = options.statuses;
    this.gapComplianceCodes = options.gapComplianceCodes;
    this.complianceCodes = options.complianceCodes;
    this.sampleComplianceCodes = options.sampleComplianceCodes;

    this.sortField = options.sortField;
    this.sortDirection = options.sortDirection;
    this.startRecord = options.startRecord;
    this.endRecord = options.endRecord;
  }
}
