import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-icon-facebook",
  template: `<a
                href="{{url}}"
                target="_blank"
                class="fa-stack">
                <i class="fab fa-facebook-f fa-stack-1x"></i>
            </a>`,
  styleUrls: ["./icon-social-media.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFacebookComponent {

  get url(): string {
    return "https://www.facebook.com/reveleer";
  }
}
