import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InfoItemTemplateComponent } from "./info-item-template/info-item-template.component";
import { InfoTemplateComponent } from "./info-template.component";

@NgModule({
  declarations: [
    InfoTemplateComponent,
    InfoItemTemplateComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InfoTemplateComponent,
    InfoItemTemplateComponent,
  ],
})
export class InfoTemplateModule { }
