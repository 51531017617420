<div class="color-picker" *ngIf="showTagTemplate" [ngClass]="lowerBoundClass">
  <div [class]="createNewTagHoverClass()">
    <span class="color-picker__tag-info--icon" (click)="popover.toggle($event)">
      <app-icon [iconName]="getIcon()"
                [iconStyle]="getIcon()"></app-icon>
      <span [class]="colorPickerHeaderClass">{{ tagHeaderText }}</span>
    </span>
  </div>

    <app-popover #popover class="color-palette-panel" [ngClass]="getPopoverClass()">
          <div *ngFor="let colorItem of defaultColors; let i = index; trackBy: trackByIndex"
               [class]="getClass(colorItem)"
               (click)="changeColor(colorItem);"></div>
    </app-popover>
    
</div>
