import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class ProjectCreateModel {
  projectTypeId: number;
  clientId: number;
  templateId: number;
  projectName: string;
  projectOwnerUserId: number;
  projectStartDate: string;
  projectEndDate: string;
  projectReviewStartDate: string;
  projectReviewEndDate: string;
  measurementYear?: number;
  projectRetrievalTypeId?: number;
  reportingYear?: number;
  codingReviewMode?: string;
  projectId?: number;
  lineOfBusiness?: number;
  constructor(options: Partial<ProjectCreateModel>) {
    StringHelper.throwIsAvailableError(options.projectName, "projectName");

    this.projectTypeId = options.projectTypeId;
    this.clientId = options.clientId;
    this.templateId = options.templateId;
    this.projectName = StringHelper.clean(options.projectName);
    this.projectOwnerUserId = options.projectOwnerUserId;
    this.projectStartDate = StringHelper.clean(options.projectStartDate);
    this.projectEndDate = StringHelper.clean(options.projectEndDate);
    this.projectRetrievalTypeId = options.projectRetrievalTypeId;
    this.projectReviewStartDate = StringHelper.clean(options.projectReviewStartDate);
    this.projectReviewEndDate = StringHelper.clean(options.projectReviewEndDate);
    this.measurementYear = options.measurementYear;
    this.reportingYear = options.reportingYear;
    this.codingReviewMode = StringHelper.clean(options.codingReviewMode);
    this.projectId = options.projectId;
    this.lineOfBusiness = options.lineOfBusiness;
  }
}
