import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { SelectButtonModule } from "primeng/selectbutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { IconModule } from "../../../shared/icon/icon.module";
import { KpiModule } from "../../../shared/kpi/kpi.module";
import { SharedModule } from "../../../shared/shared.module";
import { ProviderService } from "../../api/provider/provider.service";
import { ClinicalModule } from "../clinical/clinical.module";
import { InternalPendsModule } from "../internal-pends/internal-pends.module";
import { VendorDetailStateService } from "../invoice/vendor-detail-info/vendor-detail-state.service";
import { ChaseDetailService } from "../member/chase-detail/chase-detail.service";
import { ProjectFileService } from "../project/project-files/project-file.service";
import { AccessInfoViewEditComponent } from "./access-info-view-edit/access-info-view-edit.component";
import { AccessInfoViewEditService } from "./access-info-view-edit/access-info-view-edit.service";
import { RetrievalAddressDetailCommentsComponent } from "./address-detail/address-detail-comments/address-detail-comments.component";
import { AddressDetailContactHistoryItemComponent } from "./address-detail/address-detail-contact-history-item/address-detail-contact-history-item.component";
import { RetrievalAddressDetailContactHistoryComponent } from "./address-detail/address-detail-contact-history/address-detail-contact-history.component";
import { RetrievalAddressDetailHeaderComponent } from "./address-detail/address-detail-header/address-detail-header.component";
import { RetrievalAddressDetailInfoAccessEditComponent } from "./address-detail/address-detail-info/address-detail-info-access-edit/address-detail-info-access-edit.component";
import { RetrievalAddressDetailInfoAccessDocsComponent } from "./address-detail/address-detail-info/address-detail-info-access/address-detail-info-access-docs/address-detail-info-access-docs.component";
import { RetrievalAddressDetailInfoAccessComponent } from "./address-detail/address-detail-info/address-detail-info-access/address-detail-info-access.component";
import { RetrievalAddressDetailInfoAppointmentsComponent } from "./address-detail/address-detail-info/address-detail-info-appointments/address-detail-info-appt.component";
import { RetrievalAddressDetailInfoAssignmentComponent } from "./address-detail/address-detail-info/address-detail-info-assignment/address-detail-info-assignment.component";
import { RetrievalAddressDetailInfoContactComponent } from "./address-detail/address-detail-info/address-detail-info-contact/address-detail-info-contact.component";
import { AddressDetailInfoContactService } from "./address-detail/address-detail-info/address-detail-info-contact/address-detail-info-contact.service";
import { RetrievalAddressDetailInfoDataComponent } from "./address-detail/address-detail-info/address-detail-info-data/address-detail-info-data.component";
import { RetrievalAddressDetailInfoEditComponent } from "./address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.component";
import { RetrievalAddressDetailInfoGridsChasesComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-chases/address-detail-info-grids-chases.component";
import { RetrievalAddressDetailInfoGridsGapPursuitsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-gppursuit/address-detail-info-grids-gppursuit.component";
import { RetrievalAddressDetailInfoGridsInvoicesComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-invoices/address-detail-info-grids-invoices.component";
import { RetrievalAddressDetailInfoGridsMembersComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-members/address-detail-info-grids-members.component";
import { RetrievalAddressDetailInfoGridsPendsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-pends/address-detail-info-grids-pends.component";
import { RetrievalAddressDetailInfoGridsProvidersComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-providers/address-detail-info-grids-providers.component";
import { ProviderAddressesComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-providers/provider-addresses/provider-addresses.component";
import { ValidationCheckboxComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-providers/validation-checkbox.component";
import { RetrievalAddressDetailInfoGridsPursuitsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-pursuits/address-detail-info-grids-pursuits.component";
import { RetrievalAddressDetailInfoGridsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids.component";
import { AddressDetailInfoTimelineComponent } from "./address-detail/address-detail-info/address-detail-info-timeline/address-detail-info-timeline.component";
import { AddressDetailInfoVendorAssignmentComponent } from "./address-detail/address-detail-info/address-detail-info-vendor-assignment/address-detail-info-vendor-assignment.component";
import { RetrievalAddressDetailInfoComponent } from "./address-detail/address-detail-info/address-detail-info.component";
import { RetrievalAddressDetailInfoService } from "./address-detail/address-detail-info/address-detail-info.service";
import { AddressDetailStateService } from "./address-detail/address-detail-state.service";
import { RetrievalAddressDetailTimelineComponent } from "./address-detail/address-detail-timeline/address-detail-timeline.component";
import { RetrievalAddressDetailComponent } from "./address-detail/address-detail.component";
import { AddressDetailService } from "./address-detail/address-detail.service";
import { AddressSearchContactInfoComponent } from "./address-search/address-search-contact-info/address-search-contact-info.component";
import { AddressSearchComponent } from "./address-search/address-search.component";
import { AddressSearchService } from "./address-search/address-search.service";
import { AddressTimelineStateService } from "./address-timeline/address-timeline-state.service";
import { AddressesQueueComponent } from "./addresses-queue/addresses-queue.component";
import { AddressesQueueService } from "./addresses-queue/addresses-queue.service";
import { DeleteAppointmentModalComponent } from "./appointment/delete-appointment-modal/delete-appointment-modal.component";
import { ScheduleAppointmentModalComponent } from "./appointment/schedule-appointment-modal/schedule-appointment-modal.component";
import { CallFlowStateService } from "./call-flow/call-flow-state.service";
import { CallFlowComponent } from "./call-flow/call-flow.component";
import { CallNotesComponent } from "./call-flow/call-notes/call-notes.component";
import { CallNotesService } from "./call-flow/call-notes/call-notes.service";
import { UrlHelperService } from "./call-flow/url-helper.service";
import { ChaseUploadDocumentComponent } from "./chase-upload-document/chase-upload-document.component";
import { ChaseUploadDocumentService } from "./chase-upload-document/chase-upload-document.service";
import { CommitmentDateComponent } from "./commitment-date/commitment-date.component";
import { CommitmentDateService } from "./commitment-date/commitment-date.service";
import { ContactHistoryComponent } from "./contact-history/contact-history.component";
import { CoverLetterTemplateComponent } from "./cover-letter-template/cover-letter-template.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DetailComponent } from "./detail/detail.component";
import { EmrAssignmentComponent } from "./emr/emr-assignment/emr-assignment.component";
import { EmrDetailAccessInfoComponent } from "./emr/emr-detail/emr-detail-access-info/emr-detail-access-info.component";
import { EmrDetailAccessInfoService } from "./emr/emr-detail/emr-detail-access-info/emr-detail-access-info.service";
import { EmrDetailCallComponent } from "./emr/emr-detail/emr-detail-call/emr-detail-call.component";
import { EmrDetailCommentsComponent } from "./emr/emr-detail/emr-detail-comments/emr-detail-comments.component";
import { EmrDetailCommentsService } from "./emr/emr-detail/emr-detail-comments/emr-detail-comments.service";
import { EmrDetailContactHistoryComponent } from "./emr/emr-detail/emr-detail-contact-history/emr-detail-contact-history.component";
import { EmrDetailContactHistoryService } from "./emr/emr-detail/emr-detail-contact-history/emr-detail-contact-history.service";
import { EmrDetailEditComponent } from "./emr/emr-detail/emr-detail-edit/emr-detail-edit.component";
import { EmrDetailEditService } from "./emr/emr-detail/emr-detail-edit/emr-detail-edit.service";
import { EmrDetailHeaderComponent } from "./emr/emr-detail/emr-detail-header/emr-detail-header.component";
import { EmrDetailHeaderService } from "./emr/emr-detail/emr-detail-header/emr-detail-header.service";
import { EmrDetailInfoComponent } from "./emr/emr-detail/emr-detail-info/emr-detail-info.component";
import { EmrDetailInfoService } from "./emr/emr-detail/emr-detail-info/emr-detail-info.service";
import { EmrDetailTimelineComponent } from "./emr/emr-detail/emr-detail-timeline/emr-detail-timeline.component";
import { EmrDetailTimelineService } from "./emr/emr-detail/emr-detail-timeline/emr-detail-timeline.service";
import { EmrDetailComponent } from "./emr/emr-detail/emr-detail.component";
import { ExpectedDueDateComponent } from "./expected-due-date/expected-due-date.component";
import { FtAssignmentComponent } from "./field-tech/ft-assignment/ft-assignment.component";
import { FtDetailAccessInfoComponent } from "./field-tech/ft-detail/ft-detail-access-info/ft-detail-access-info.component";
import { FtDetailAccessInfoService } from "./field-tech/ft-detail/ft-detail-access-info/ft-detail-access-info.service";
import { FtDetailCallComponent } from "./field-tech/ft-detail/ft-detail-call/ft-detail-call.component";
import { FtDetailCommentsComponent } from "./field-tech/ft-detail/ft-detail-comments/ft-detail-comments.component";
import { FtDetailCommentsService } from "./field-tech/ft-detail/ft-detail-comments/ft-detail-comments.service";
import { FtDetailContactHistoryComponent } from "./field-tech/ft-detail/ft-detail-contact-history/ft-detail-contact-history.component";
import { FtDetailContactHistoryService } from "./field-tech/ft-detail/ft-detail-contact-history/ft-detail-contact-history.service";
import { FtDetailEditComponent } from "./field-tech/ft-detail/ft-detail-edit/ft-detail-edit.component";
import { FtDetailEditService } from "./field-tech/ft-detail/ft-detail-edit/ft-detail-edit.service";
import { FtDetailHeaderComponent } from "./field-tech/ft-detail/ft-detail-header/ft-detail-header.component";
import { FtDetailHeaderService } from "./field-tech/ft-detail/ft-detail-header/ft-detail-header.service";
import { FtDetailInfoComponent } from "./field-tech/ft-detail/ft-detail-info/ft-detail-info.component";
import { FtDetailInfoService } from "./field-tech/ft-detail/ft-detail-info/ft-detail-info.service";
import { FtDetailTimelineComponent } from "./field-tech/ft-detail/ft-detail-timeline/ft-detail-timeline.component";
import { FtDetailTimelineService } from "./field-tech/ft-detail/ft-detail-timeline/ft-detail-timeline.service";
import { FtDetailComponent } from "./field-tech/ft-detail/ft-detail.component";
import { AddressAssignmentComponent } from "./psr/address-assignment/address-assignment.component";
import { AddressDetailBusinessHoursComponent } from "./psr/address-detail/address-detail-business-hours/address-detail-business-hours.component";
import { AddressDetailCallComponent } from "./psr/address-detail/address-detail-call/address-detail-call.component";
import { AddressDetailCallService } from "./psr/address-detail/address-detail-call/address-detail-call.service";
import { AddressDetailCommentsComponent } from "./psr/address-detail/address-detail-comments/address-detail-comments.component";
import { AddressDetailContactHistoryComponent } from "./psr/address-detail/address-detail-contact-history/address-detail-contact-history.component";
import { AddressDetailContactHistoryService } from "./psr/address-detail/address-detail-contact-history/address-detail-contact-history.service";
import { AddressDetailEditComponent } from "./psr/address-detail/address-detail-edit/address-detail-edit.component";
import { AddressDetailEditService } from "./psr/address-detail/address-detail-edit/address-detail-edit.service";
import { AddressDetailHeaderComponent } from "./psr/address-detail/address-detail-header/address-detail-header.component";
import { AddressDetailHeaderService } from "./psr/address-detail/address-detail-header/address-detail-header.service";
import { AddressDetailInfoComponent } from "./psr/address-detail/address-detail-info/address-detail-info.component";
import { AddressDetailInfoService } from "./psr/address-detail/address-detail-info/address-detail-info.service";
import { AddressDetailTimelineComponent } from "./psr/address-detail/address-detail-timeline/address-detail-timeline.component";
import { AddressDetailTimelineService } from "./psr/address-detail/address-detail-timeline/address-detail-timeline.service";
import { AddressDetailComponent } from "./psr/address-detail/address-detail.component";
import { RecordingPlaybackComponent } from "./recording-playback/recording-playback.component";
import { DocumentReviewComponent } from "./retreival-document-review/document-review/document-review.component";
import { RetreivalDocumentIntakeComponent } from "./retreival-document-review/retreival-document-intake/retreival-document-intake.component";
import { RetreivalDocumentReviewComponent } from "./retreival-document-review/retreival-document-review.component";
import { RetrievalCallFlowComponent } from "./retrieval-call-flow/retrieval-call-flow.component";
import { RetrievalCallFlowService } from "./retrieval-call-flow/retrieval-call-flow.service";
import { RetrievalDocumentListComponent } from "./retrieval-document-list/retrieval-document-list.component";
import { RetrievalDocumentListService } from "./retrieval-document-list/retrieval-document-list.service";
import { RetrievalDocumentServiceService } from "./retrieval-document-service.service";
import { RetrievalOutreachComponent } from "./retrieval-outreach/retrieval-outreach.component";
import { RetrievalPageComponent } from "./retrieval-page/retrieval-page.component";
import { RetrievalRoutingModule } from "./retrieval-routing.module";
import { RetrievalComponent } from "./retrieval.component";
import { ThirdPartyComponent } from "./third-party/third-party.component";

@NgModule({
  imports: [
    RetrievalRoutingModule,
    FormsModule, // TODO: Remove when forms are done.
    // ProgressBarModule, //TODO: Further we require it & change it.
    CommonModule,
    SharedModule,
    SplitButtonModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    SelectButtonModule,
    ClinicalModule,
    AutoCompleteModule, // TODO: remove dependency when we can
    CalendarModule,
    InputTextModule,
    KpiModule,
    InputTextareaModule,
    IconModule,
    InternalPendsModule,
    SharedModule,
  ],
  declarations: [
    AccessInfoViewEditComponent,
    AddressAssignmentComponent,
    AddressDetailBusinessHoursComponent,
    AddressDetailCallComponent,
    AddressDetailCommentsComponent,
    AddressDetailComponent,
    AddressDetailContactHistoryComponent,
    AddressDetailContactHistoryItemComponent,
    AddressDetailEditComponent,
    AddressDetailHeaderComponent,
    AddressDetailInfoComponent,
    AddressDetailInfoTimelineComponent,
    AddressDetailInfoVendorAssignmentComponent,
    AddressDetailTimelineComponent,
    AddressesQueueComponent,
    AddressSearchComponent,
    AddressSearchContactInfoComponent,
    CallFlowComponent,
    CallNotesComponent,
    ChaseUploadDocumentComponent,
    CommitmentDateComponent,
    ContactHistoryComponent,
    CoverLetterTemplateComponent,
    DashboardComponent,
    DeleteAppointmentModalComponent,
    DetailComponent,
    DocumentReviewComponent,
    EmrAssignmentComponent,
    EmrDetailAccessInfoComponent,
    EmrDetailCallComponent,
    EmrDetailCommentsComponent,
    EmrDetailComponent,
    EmrDetailContactHistoryComponent,
    EmrDetailEditComponent,
    EmrDetailHeaderComponent,
    EmrDetailInfoComponent,
    EmrDetailTimelineComponent,
    ExpectedDueDateComponent,
    FtAssignmentComponent,
    FtDetailAccessInfoComponent,
    FtDetailCallComponent,
    FtDetailCommentsComponent,
    FtDetailComponent,
    FtDetailContactHistoryComponent,
    FtDetailEditComponent,
    FtDetailHeaderComponent,
    FtDetailInfoComponent,
    FtDetailTimelineComponent,
    ProviderAddressesComponent,
    RecordingPlaybackComponent,
    RetreivalDocumentIntakeComponent,
    RetreivalDocumentReviewComponent,
    RetrievalAddressDetailCommentsComponent,
    RetrievalAddressDetailComponent,
    RetrievalAddressDetailContactHistoryComponent,
    RetrievalAddressDetailHeaderComponent,
    RetrievalAddressDetailInfoAccessComponent,
    RetrievalAddressDetailInfoAccessDocsComponent,
    RetrievalAddressDetailInfoAccessEditComponent,
    RetrievalAddressDetailInfoAppointmentsComponent,
    RetrievalAddressDetailInfoAssignmentComponent,
    RetrievalAddressDetailInfoComponent,
    RetrievalAddressDetailInfoContactComponent,
    RetrievalAddressDetailInfoDataComponent,
    RetrievalAddressDetailInfoEditComponent,
    RetrievalAddressDetailInfoGridsChasesComponent,
    RetrievalAddressDetailInfoGridsComponent,
    RetrievalAddressDetailInfoGridsGapPursuitsComponent,
    RetrievalAddressDetailInfoGridsInvoicesComponent,
    RetrievalAddressDetailInfoGridsMembersComponent,
    RetrievalAddressDetailInfoGridsPendsComponent,
    RetrievalAddressDetailInfoGridsProvidersComponent,
    RetrievalAddressDetailInfoGridsPursuitsComponent,
    RetrievalAddressDetailTimelineComponent,
    RetrievalCallFlowComponent,
    RetrievalComponent,
    RetrievalDocumentListComponent,
    RetrievalOutreachComponent,
    RetrievalPageComponent,
    ScheduleAppointmentModalComponent,
    ThirdPartyComponent,
    ValidationCheckboxComponent,
  ],
  providers: [
    AccessInfoViewEditService,
    AddressDetailCallService,
    AddressDetailContactHistoryService,
    AddressDetailEditService,
    AddressDetailHeaderService,
    AddressDetailInfoService,
    AddressDetailInfoContactService,
    AddressDetailService,
    AddressDetailStateService,
    AddressDetailTimelineService,
    AddressesQueueService,
    AddressSearchService,
    AddressTimelineStateService,
    CallFlowStateService,
    CallNotesService,
    ChaseDetailService,
    ChaseUploadDocumentService,
    CommitmentDateService,
    EmrDetailAccessInfoService,
    EmrDetailCommentsService,
    EmrDetailContactHistoryService,
    EmrDetailEditService,
    EmrDetailHeaderService,
    EmrDetailInfoService,
    EmrDetailTimelineService,
    FtDetailAccessInfoService,
    FtDetailCommentsService,
    FtDetailContactHistoryService,
    FtDetailEditService,
    FtDetailHeaderService,
    FtDetailInfoService,
    FtDetailTimelineService,
    ProjectFileService,
    ProviderService,
    RetrievalAddressDetailInfoService,
    RetrievalCallFlowService,
    RetrievalDocumentListService,
    RetrievalDocumentServiceService,
    VendorDetailStateService,
    UrlHelperService,
  ],
  entryComponents: [AddressDetailComponent],
  exports: [CallFlowComponent,
            CallNotesComponent,
            AddressDetailContactHistoryItemComponent,
  ],
})
export class RetrievalModule { }
