import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { PendListItem } from "../../../internal-pends/internal-pends-listing/pend-list-item.model";
import { MemberWithChases } from "../../psr/address-detail/address-detail-info/member-with-chases.model";

@Injectable({
  providedIn: "root",
})
export class RetrievalAddressDetailInfoService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getTimelineItems(addressId: number): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/retrievalTimeline?documentSourceId=${addressId}&maxRecords=5`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }

  getMembers(addressId: number): Observable<MemberWithChases[]> {
    const url = `${this.baseApiUrl}members/listbyaid?addressId=${addressId}`;

    return this.http.get(url)
      .pipe(
      map(this.automapper.curryMany("default", "MemberWithChases"))
      );
  }

  getPends(addressId: number): Observable<PendListItem[]> {
    const url = `${this.baseApiUrl}reporting/pend/list`;
    const headers = new HttpHeaders();
    const request = { MasterDocumentSourceId: addressId };

    return this.http.post(url, request, { headers }).pipe(
      map(this.automapper.curryMany("default", "PendListItem"))
    );
  }

  saveRetrievalMemberValidation(memberValidationData: MemberWithChases): Observable<null> {
    const url = `${this.baseApiUrl}retrieval/memberValidation`;
    return this.http.post(url, memberValidationData) as Observable<null>;
  }
}
