export class ValidFileExtension {

  static isValidExtension(value: string): boolean {

    const extension = ["png", "jpg", "jpeg", "tiff", "txt", "doc", "docx", "xls", "xlsx", "pdf", "zip", "xml", "pptx", "csv"];
    if (value) {
      if (extension.includes(value.substring(value.lastIndexOf(".") + 1, value.length))) {
        return true;
      }
      return false;
    }

  }

}
