import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { WorkflowStatus } from "../../../api/workflow/worklow-status.enum";
import { AddressesQueueListItem } from "./addresses-queue-list-item.model";
import { AddressesQueueSearchRequest } from "./addresses-queue-search-request.model";

@Injectable()
export class AddressesQueueService {

  constructor(@Inject(BASE_API_URL)
  private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) { }

  getAddressesQueueList(
    addressesQueueSearchRequest: AddressesQueueSearchRequest
  ): Observable<AddressesQueueListItem[]> {

    const url = `${this.baseApiUrl}retrieval/addressesqueue`;

    return this.http.post(url, addressesQueueSearchRequest).pipe(
      map(
        this.automapper.curryMany("default", "AddressesQueueListItem")
      )
    );
  }

  getProjectList(): Observable<SelectableInput[]> {
    // OBSOLETE
    // TODO: Delete this method and use "app/platform/modules/retrieval/retrieval-page/retrieval-page.service.ts"
    const url = `${this.baseApiUrl}project/lookup`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("LookupModel", "SelectableInput"))
    );
  }

  updateAddressesQueueList(projectId: number): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/addressqueuepriority?projectId=${projectId}&statusId=${WorkflowStatus.MRQA}`;
    return this.http.post(url, {});
  }

  getRetrievalUsers(addressId: number | number[], isSpecialHandling?: boolean): Observable<SelectableInput[]> {
    const addressIds = ArrayHelper.isAvailable(addressId as number[]) ? addressId : [addressId];
    const url = `${this.baseApiUrl}retrieval/retrievalusers?addressIdsCsv=${addressIds}&isSpecialHandling=${isSpecialHandling}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("UserModel", "SelectableInput"))
    );
  }

  assignAddressesToUser(addressId: number | number[], userId: number, type: string = ""): Observable<any> {
    const addressIds = ArrayHelper.isAvailable(addressId as number[]) ? addressId : [addressId];
    const baseUrl = `${this.baseApiUrl}retrieval/assignaddresses?addressIdsCsv=${addressIds}&userId=${userId}`;
    const url = StringHelper.isAvailable(type) ? `${baseUrl}&type=${type}` : baseUrl;
    return this.http.post(url, {});
  }
}
