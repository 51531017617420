import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-user-productivity",
  templateUrl: "./user-productivity.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProductivityComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.USER_PRODUCTIVITY_REPORT;

    constructor() {
        this.filters = ["Projects", "StartDate",  "EndDate"];
    }

}
