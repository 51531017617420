import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { ArrayHelper } from "../../../../../../../../../../utilities/contracts/array-helper";

@Component({
  selector: "member-diagnosis-scoring-checkbox",
  templateUrl: "./scoring.component.html",
  styleUrls: ["./scoring.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoringCheckboxComponent {
  @Input() icdCode: any;
  @Input() isMultipleCodingModel: boolean;
  @Input() index: number;
  @Input() idx: number;
  @Input() checkboxStatus = false;
  @Input() enableCheckbox = true;
  @Output() checkboxVal = new EventEmitter();
  IDLOOKUP = "icd-checkbox-";
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  onCheckboxChange(event) {
    this.checkboxVal.emit({ check: event.target.checked, index: this.index });
  }

  resetCheckbox(index) {
    const element = document.getElementById(this.IDLOOKUP + index) as HTMLInputElement;
    element.checked = false;
  }

  getHccId(): string {
    if (ArrayHelper.isAvailable(this.icdCode?.hccModelIds) && !this.isMultipleCodingModel) {
      return this.icdCode.hccModelIds.join(", ");
    }
    return;
  }

}
