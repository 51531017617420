<div class="call-flow__outgoing" *ngIf="!isIncomingCall">
  <app-iframe *ngIf="!isCommunicationEksUsed" [visible]="showCallFlowAndDialpad"
              [load]="showCallFlowAndDialpad"
              iframeSrc="{{ twilioUrl }}"
              iframeHeight="{{ twilioHeight }}">
  </app-iframe>
  <iframe class="full" #iframe frameborder="0" width="100%" height="420px" allowfullscreen ></iframe>
  <div class="call-connecting-container" *ngIf="displayCallConnecting">
    <div class="call-status">
      <div class="call-flow__call-connecting">Call connecting... </div>
      <div class="call-flow__call-connecting__question">Did you connect successfully?</div>
      <div class="call-result">
        <div class="call-result-item">
          <app-button classes="danger" text="NO" (onClick)="saveCallResultType(false)"></app-button>
        </div>
        <div class="call-result-item">
          <app-button text="YES, CONTINUE" (onClick)="saveCallResultType(true)"></app-button>
        </div>
      </div>
    </div>
  </div>

  <div class="call-not-connected-container" *ngIf="displayCouldNotConnect && !vendorCall">
    <div class="call-script__header">
      UNABLE TO CONNECT
    </div>
    <div class="call-flow__unsuccessful">
      <div class="call-flow__subheader">Please give a reason for why you couldn't complete this call.</div>
      <form [formGroup]="formGroupUnSuccessfulCall">
        <div class="ui-fluid">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="formGroupUnSuccessfulCall"
                          [model]="callCategories"></form-factory>
            <div class="ellipsis" *ngIf="isUnsuccessfulFormInvalid('callCategories')">
              This field is required.
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12 dropdown__unsuccessful">
            <form-factory [formGroup]="formGroupUnSuccessfulCall"
                          [model]="callUnsuccessfulSubtypes"></form-factory>
            <div class="ellipsis" *ngIf="isUnsuccessfulFormInvalid('callUnsuccessfulSubtypes')">
              This field is required.
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <label class="call-script__label">Next Call Date and Time</label>
            <p-calendar [showIcon]=true
                        [readonlyInput]="true"
                        [hideOnDateTimeSelect]="true"
                        placeholder="Date"
                        [showTime]="true"
                        [(ngModel)]="nextCallDate"
                        [ngModelOptions]="{standalone: true}"
                        [minDate]="minDate">
              <p-footer class="call-script__next-calldate__footer">Time</p-footer>
            </p-calendar>
          </div>
          <div class="call-script__saveNotes">
            <app-button classes="danger" text="SUBMIT CALL NOTES" (onClick)="saveUnSuccessfulCallNotes()"></app-button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="call-connected-container" *ngIf="displayCouldConnect && !vendorCall">
    <div class="call-script__header">
      CALL SCRIPT
    </div>

    <div class="call-script__section" *ngIf="displayValidationSteps">
      <div class="call-script__subheader">
        <div class="call-script__subheader-elements steps-menu__number">1</div>
        <div class="call-script__subheader-elements">Validate Address</div>
      </div>
      <div class="call-script__validation" *ngIf="scriptStepOneInProgress">
        <div class="call-script__description">
          Validate the Address Information.
          <br />
          The Address can be located at the top left of the page under Address info.
        </div>
        <app-button text="FINISHED VALIDATING" (onClick)="onAddressValidation()"></app-button>
      </div>
    </div>

    <div class="call-script__section" *ngIf="displayValidationSteps">
      <div class="call-script__subheader">
        <div class="call-script__subheader-elements steps-menu__number">2</div>
        <div class="call-script__subheader-elements">Validate Provider Details</div>
      </div>
      <div class="call-script__validation" *ngIf="scriptStepTwoInProgress">
        <div class="call-script__description">
          Validate each Provider on the table to the left by checking the "Is Valid" checkbox in the "Validation" column.
        </div>
        <app-button text="FINISHED VALIDATING" (onClick)="onProviderValidation()"></app-button>
      </div>
    </div>

    <div class="call-script__section">
      <div class="call-script__subheader">
        <div class="call-script__subheader-elements steps-menu__number">3</div>
        <div class="call-script__subheader-elements">Finish Call & Enter Notes</div>
      </div>
      <div *ngIf="scriptStepThreeInProgress">
        <form [formGroup]="formGroupCallNotes">
          <div class="ui-fluid">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div>
                <form-factory [formGroup]="formGroupCallNotes"
                              [model]="callCategories"></form-factory>
                <div class="ellipsis" *ngIf="isFieldInvalid('callCategories')">
                  This field is required.
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div>
                <form-factory [formGroup]="formGroupCallNotes"
                              [model]="callSuccessfulSubtypes"></form-factory>
                <div class="ellipsis" *ngIf="isFieldInvalid('callSuccessfulSubtypes')">
                  This field is required.
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="formGroupCallNotes" [model]="callNotes"></form-factory>
              <div class="ellipsis" *ngIf="isFieldInvalid('callNotes')">
                Call Notes is required and must be 1000 characters or less
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="formGroupCallNotes" [model]="spokeTo"></form-factory>
              <div class="ellipsis" *ngIf="isFieldInvalid('spokeTo')">
                Spoke to is required and must be 100 characters or less
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <label class="call-script__label">Next Call Date and Time</label>
              <p-calendar [showIcon]=true
                          [readonlyInput]="true"
                          [hideOnDateTimeSelect]="true"
                          placeholder="Date"
                          [showTime]="true"
                          [(ngModel)]="nextCallDate"
                          [ngModelOptions]="{standalone: true}"
                          [minDate]="minDate">
                <p-footer class="call-script__next-calldate__footer">Time</p-footer>
              </p-calendar>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <label class="call-script__label">Commitment Date</label>
              <p-calendar [showIcon]=true
                          [readonlyInput]="true"
                          [hideOnDateTimeSelect]="true"
                          placeholder="Date"
                          [(ngModel)]="commitDate"
                          [ngModelOptions]="{standalone: true}"
                          [minDate]="minDate">
              </p-calendar>
            </div>
          </div>

        </form>
        <div class="call-script__saveNotes">
          <app-button text="SUBMIT CALL NOTES" (onClick)="saveSuccessfulCallNotes()"></app-button>
        </div>
      </div>
    </div>
  </div>

  <div class="call-connected-container" *ngIf="vendorCallNotesVisible">
    <div class="call-script__header">
      ENTER CALL NOTES
    </div>
    <div class="call-script__section">
      <div>
        <form [formGroup]="formGroupCallNotes">
          <div class="ui-fluid">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="formGroupCallNotes" [model]="callNotes"></form-factory>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="displayCouldConnect">
              <form-factory [formGroup]="formGroupCallNotes" [model]="spokeTo"></form-factory>
            </div>
          </div>
        </form>
        <div class="call-script__saveNotes">
          <app-button text="SUBMIT CALL NOTES" (onClick)="saveVendorCallNotes()"></app-button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="call-flow__incoming" *ngIf="isIncomingCall">
  <div class="call-script__section">
    <div class="call-script__subheader">
      <div class="call-script__subheader-elements">Enter Incoming Call Notes</div>
    </div>
    <div>
      <form [formGroup]="formGroupCallNotes">
        <div class="ui-fluid">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="formGroupCallNotes" [model]="callNotes"></form-factory>
            <div class="ellipsis" *ngIf="isFieldInvalid('callNotes')">
              Call Notes is required and must be 1000 characters or less
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="formGroupCallNotes" [model]="spokeTo"></form-factory>
            <div class="ellipsis" *ngIf="isFieldInvalid('spokeTo')">
              Spoke to is required and must be 100 characters or less
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <label class="call-script__label">Next Call Date and Time</label>
            <p-calendar [showIcon]=true
                        [readonlyInput]="true"
                        [hideOnDateTimeSelect]="true"
                        placeholder="Date"
                        [showTime]="true"
                        [(ngModel)]="nextCallDate"
                        [ngModelOptions]="{standalone: true}"
                        [minDate]="minDate">
              <p-footer class="call-script__next-calldate__footer">Time</p-footer>
            </p-calendar>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <label class="call-script__label">Commitment Date</label>
            <p-calendar [showIcon]=true
                        [readonlyInput]="true"
                        [hideOnDateTimeSelect]="true"
                        placeholder="Date"
                        [(ngModel)]="commitDate"
                        [ngModelOptions]="{standalone: true}"
                        [minDate]="minDate">
            </p-calendar>
          </div>
        </div>

      </form>
      <div class="call-script__saveNotes">
        <app-button text="SUBMIT CALL NOTES" (onClick)="saveIncomingCallNotes()"></app-button>
      </div>
    </div>
  </div>

</div>

<app-confirmation-modal class="navigationConfirmationModal"
                        [(visible)]="showConfirmCloseDialpad"
                        header="WARNING"
                        (onYes)="closeCallFlow()"
                        (onCancel)="cancelCloseCallFlow()"
                        acceptText="CONTINUE"
                        rejectText="CANCEL">
  {{closeCallConfirmationText}}
</app-confirmation-modal>


<app-modal [(visible)]="isVerifyCallModalVisible"
           header="WARNING"
           class="navigationConfirmationModal">
  {{isVerifyCallModalText}}
</app-modal>
