<div class="compliance-default-grid">
  <app-server-grid 
    [configuration]="gridConfiguration" 
    [(request)]="request"
    [refresh]="gridRefresh"
    [isLoadOnInit]="isLoadOnInit">

    <ng-template #chaseIdColumn let-rowData>
      <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
    </ng-template>

  </app-server-grid>
</div>
