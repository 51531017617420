export class OverreadFeedbackStatusChange {
  abstractorReceiptStatus: string;
  chaseOverreadFeedbackId: number;
  commentText: string;
  correctDataset: string;
  overreaderReceiptStatus: string;
  overreadFeedbackStatus: string;

  constructor(options: Partial<OverreadFeedbackStatusChange>) {
    this.abstractorReceiptStatus = options.abstractorReceiptStatus;
    this.chaseOverreadFeedbackId = options.chaseOverreadFeedbackId;
    this.commentText = options.commentText;
    this.correctDataset = options.correctDataset;
    this.overreaderReceiptStatus = options.overreaderReceiptStatus;
    this.overreadFeedbackStatus = options.overreadFeedbackStatus;
  }
}
