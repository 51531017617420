import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";

@Injectable({
  providedIn: "root",
})
export class InvoiceSettingService {

  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService) { }

  getInvoiceTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}invoice/invoicetype`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("InvoiceType", "SelectableInput")));
  }
}
