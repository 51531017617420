<div class="card-header">
  <div class="card-header__title">
    <div *ngIf="disease$ | async as diseases; else eveDx" class="card-header__title--container card-header__description" [ngClass]="isBold">
      <span *ngIf="isCodingModelAvailable(diagnosis); else noCodingModels" class="card-header__description--hcc" [innerHTML]="codingModelLabel(diagnosis)"></span>
      <ng-template #noCodingModels>
          <span class="card-header__description--hcc" [innerHTML]="hccLabel(diseases)"></span>
      </ng-template>
      {{ getICDCode(diagnosis.icd) }}: {{ diseases[0]?.diseaseName }}
      <br>
      <span class="card-header__dos" *ngIf="!isCardOpen">
        {{dos}}
      </span>
      <span class="card-header__dos" *ngIf="!isCardOpen">
        {{vrcs}}
      </span>
    </div>
    <ng-template #eveDx>
      <div [ngClass]="isBold" class="card-header__eveDx--header card-header__eveDx"
            *ngIf="diagnosis.isEveDiagnosis && diagnosis.nlpReviewResult === 'ADD'; else noInformation">
        <span class="card-header__description--hcc"
              [innerHTML]="codingModelLabel(diagnosis)">
        </span>
        <span>{{ diagnosis.diseaseName }}</span>
        <br/>
        <span *ngIf="!isCardOpen" class="card-header__eveDx--dos">{{dos}}</span>&nbsp;
        <span *ngIf="!isCardOpen" class="card-header__eveDx--dos">{{vrcs}}</span>
      </div>
      <div *ngIf="!isCardOpen" class="card-header__eveDx">
        <member-diagnosis-slider-eve [coincidenceVal]="diagnosis.confidenceScore"></member-diagnosis-slider-eve>
      </div>
    </ng-template>
    <ng-template #noInformation>
      <p [ngClass]="isBold">NO INFORMATION</p>
    </ng-template>
  </div>
  <div class="card-header__actions">
    <app-badge [text]="badges[badgeStatus]" [isLarge]="false" cssClasses="badge-diagnoses {{badgeStatus}}" *ngIf="showBadge">
    </app-badge>
    <div *ngIf="showEveIcon" class="card-header__eveIcon" [ngClass]="eveIconClass">
      <app-icon iconName="user"></app-icon>
    </div>
    <app-button classes="transparent small" icon="trash" [focusable]="false" styleType="pi" type="pi"
                (onClick)="onDeleteClick($event)" *ngIf="hasDeleteEnabled">
    </app-button>
  </div>
</div>
