import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { DocumentSourceType } from "../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../address-detail-state.model";
import { AddressDetailStateService } from "../address-detail-state.service";

@Component({
  selector: "retrieval-address-detail-header-new",
  templateUrl: "./address-detail-header.component.html",
  styleUrls: ["./address-detail-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailHeaderComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  addressDetailState: AddressDetailState;

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get addressId() {
    return this.addressDetailState.masterDocumentSourceId;
  }

  get address() {
    return `${this.addressDetailState.address1} ${this.addressDetailState.address2} ${this.addressDetailState.city} ${this.addressDetailState.state} ${this.addressDetailState.postalCode}`;
  }

  get retrievalType() {
    return this.addressDetailState.documentSourceTypeName;
  }

  get lastCalled() {
    return this.addressDetailState.lastContactedDate != null ? DateHelper.format(this.addressDetailState.lastContactedDate) : "N/A";
  }

  get isAidValid(): boolean {
    return this.addressDetailState.allProvidersVerified && this.addressDetailState.isContactVerified;
  }

  get aIdValidDateLabel(): string {
    return this.isAidValid ? DateHelper.format(this.addressDetailState.masterDocumentSourceVerifiedDate) : "Missing Validation";
  }

  get iconName() {
    if (NumberHelper.isAvailable(this.addressDetailState.documentSourceTypeId)) {

      switch (this.addressDetailState.documentSourceTypeId) {
        case DocumentSourceType.PSR: {
           return "user-md";
        }
        case DocumentSourceType.EMR: {
          return "laptop";
        }
        case DocumentSourceType.FIELDTECH: {
          return "male";
        }
        case DocumentSourceType.THIRDPARTY: {
          return "dollar-sign";
        }
        default: {
          return "circle";
        }
      }
    }

    return "circle";
  }
}
