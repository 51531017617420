<div class="padding-member-grid">
  <app-basic-grid [configuration]="membersGridConfiguration"
                  [data]="memberGridData"
                  [rowExpandTemplate]="rowExpandTemplate">

    <ng-template #rowExpandTemplate let-rowData>
      <app-basic-grid class="grid-row-expansion" [configuration]="memberChaseGridConfiguration" [data]="rowData.chaseList"></app-basic-grid>
    </ng-template>

    <ng-template #isValidCheckbox let-rowData>
      <app-checkbox-validate [isVerified]="rowData.isVerified" (onValidateChange)="saveMemberValidation($event, rowData)"></app-checkbox-validate>
    </ng-template>

  </app-basic-grid>
</div>
