import { NgModule } from "@angular/core";
import { PanelModule } from "../panel/panel.module";
import { PrivacyPolicyModalComponent } from "./privacy-policy-modal.component";
import { PrivacyPolicyComponent } from "./privacy-policy.component";

@NgModule({
  declarations: [PrivacyPolicyComponent, PrivacyPolicyModalComponent],
  imports: [
    PanelModule,
  ],
  exports: [PrivacyPolicyComponent, PrivacyPolicyModalComponent],
})
export class PrivacyPolicyModule { }
