import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ParameterService } from "../../../core/navigation/parameter.service";
import { AuditQueryChartService } from "../../../platform/modules/audit/audit-query/audit-query-chart/audit-query-chart.service";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { CalculatedGroupsService } from "../calculated-groups/calculated-groups.service";
import { DatesBetween } from "../ppc/dates-between/dates-between.model";
import { DischargeGroup } from "./discharge-group.model";

@Component({
  selector: "form-discharge-group",
  templateUrl: "./discharge-group.component.html",
  styleUrls: ["./discharge-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DischargeGroupComponent extends DynamicControlDirective<DischargeGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();
  reconciliation: DatesBetween;
  chaseId: number;

  constructor(
    private calculatedGroupsService: CalculatedGroupsService,
    private changeDetector: ChangeDetectorRef,
    private readonly auditQueryChartService: AuditQueryChartService,
    private readonly parameterService: ParameterService
  ) {
    super();
  }

  ngOnInit() {
    this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);

    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.calculatedGroupsService.getDischargeDates(this.chaseId);
    } else {
      this.sink.add(this.auditQueryChartService.auditChaseDetails.subscribe(data => {
        if (data?.chaseId) {
          this.chaseId = data.chaseId;
          this.calculatedGroupsService.getDischargeDates(this.chaseId);
        }
      }));
    }

    this.sink.add(this.calculatedGroupsService.dischargeDates.subscribe(dischargeDates => {
      this.reconciliation = new DatesBetween({
        startDate: dischargeDates.reconcilationFromDate,
        endDate: dischargeDates.reconcilationThruDate,
      });
      this.changeDetector.markForCheck();
    }));
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  onChangeEvent(event: DynamicFormEvent): void {
    const originalSuccessFn = event.successFn || (() => { });
    event.successFn = () => {
      originalSuccessFn();
      this.calculatedGroupsService.getDischargeDates(this.chaseId);
    };

    this.onChange.emit(event);
  }
}
