export enum PendType {
  PC100 = 1,
  PC101 = 2,
  PC102 = 3,
  PC103 = 4,
  PC104 = 5,
  PC105 = 7,
  PC106 = 8,
  PC107 = 9,
  PC108 = 10,
  PC109 = 11,
  PC110 = 12,
  PC301 = 52,
  PC304 = 63,
  PC700 = 65,
}

