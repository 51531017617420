<app-server-grid [configuration]="configuration"
                 [(request)]="request"
                 [(selection)]="selection"
                 [actions]="actions"
                 [refresh]="refreshGrid"
                 [isLoadOnInit]="isLoadOnInit"
                 [filterTemplate]="filterForm"
                 [actionTemplate]="actionTemplate"
                 [views]="views"
                 [refreshViews]="refreshViews"
                 [displayCheckedItemsInGridView]="displayCheckedItemsInGridView"
                 [filterHeaderText]="filterHeaderText"
                 [isExcelExportDuplicateRow]="isExcelExportDuplicateRow"
                 [excelExportExpandColumnName]="excelExportExpandColumnName">

  <ng-template #splHandlingColumn let-rowData let-rowIndex="index">
    <app-icon iconStyle="mrcs-icon" iconType="mrcs-icon" iconName="special-assignment-address active" *ngIf="rowData.specialHandling ===1"></app-icon>
  </ng-template>

  <ng-template #filterForm let-form>
    <form [formGroup]="form" class="ui-modal-width">
      <app-tab-menu orientation="left">

        <app-tab-panel header="AID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="aidInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Group Name">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form"
                          [model]="groupNameInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Provider Name">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="providerNameInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Address">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="cityInput"></form-factory>
            <form-factory [formGroup]="form" [model]="stateInput"></form-factory>
            <form-factory [formGroup]="form" [model]="postalCodeInput"></form-factory>
          </div>
        </app-tab-panel>

        <!--TODO: Add back after DB makes fix -->
        <!--
          <app-tab-panel header="Client">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="clientInput"></form-factory>
            </div>
          </app-tab-panel>
        -->

        <app-tab-panel header="Project(s)" selected="true">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Address Group">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressGroupIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Status" *ngIf="showStatusFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="statusInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Assigned To" *ngIf="showAssignedToFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Retrieval Type" *ngIf="showRetrievalTypeFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="documentSourceTypesInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Tags">
          <label class="tag-label">Tags</label>
          <div class="tag__filterform">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div class="ui-g-2 ui-md-2 ui-lg-2">
                <form-factory class="tag__operatorform"
                              [formGroup]="form"
                              [model]="tagsSearchOperatorInput"
                              (onChange)="getSearchOperatorValue($event)">
                </form-factory>
              </div>
              <div class="ui-g-10 ui-md-10 ui-lg-10">
                <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                            class="tag-content__item tag-content__item--multitag-close-icon"
                            classes="transparent small"
                            icon="times-circle"
                            (onClick)="resetTagSearchInput()">
                </app-button>
                <form-factory class="tag__panelform"
                              [formGroup]="form"
                              [model]="tagsInput"
                              (onPanelShow)="onShowEvent(true)"
                              (onPanelHide)="onShowEvent(false)"
                              (keyup)="onKeyUp($event)">
                </form-factory>
              </div>
            </div>

          </div>
        </app-tab-panel>
        <app-tab-panel header="Special Handling" >
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="shReasonInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Retriever" >
          <div class="ui-g-12 ui-md-12 ui-lg-12 co-retrieval-filter">
            <form-factory [formGroup]="form" [model]="coRetrievalOwner"></form-factory>
          </div>
        </app-tab-panel>

      </app-tab-menu>
    </form>
  </ng-template>

  <ng-template #actionTemplate
               let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();"
                  [disabled]="isActionDisabled(rowData, action.name)"></app-button>
    </div>
  </ng-template>

</app-server-grid>

<app-address-assignment-modal [(visible)]="isAssignModalVisible"
                              (onHide)="closeModal()"
                              (onUpdate)="gridRefresh()"
                              [(addresses)]="selectedAddresses"
                              [isUnassignEnabled]="isUnassignEnabled"></app-address-assignment-modal>

<app-manage-tag-modal [(visible)]="isManageTagModalVisible"
                      (onHide)="closeModal()"
                      [(selectedData)]="selectedAddresses"
                      [entityName]="entityName"
                      [totalEntityCount]="totalEntityCount"
                      [tagType]="tagType"
                      (onUpdate)="gridRefresh()">
</app-manage-tag-modal>
