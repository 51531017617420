import { IAutoMapper } from "../interfaces";

export const mapUserRoleModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "UserRoleModel")
    .forMember("userRoleId", o => o.userRoleId)
    .forMember("roleId", o => o.roleId)
    .forMember("roleName", o => o.roleName)
    .forMember("organizationId", o => o.organizationId)
    .forMember("organizationName", o => o.organizationName)
    .forMember("clientId", o => o.clientId)
    .forMember("clientName", o => o.clientName)
    .forMember("projectId", o => o.projectId)
    .forMember("projectName", o => o.projectName)
    .forMember("roleGroupName", o => o.roleGroupName)
    .forMember("roleTypeName", o => o.roleTypeName);
};
