import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-manually-created-chases-report",
  templateUrl: "./manually-created-chases-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManaullyCreatedChasesReportComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.MANUALLY_CREATED_CHASES_REPORT;

    constructor() {
      this.filters = ["Projects", "DataLoadDate"];
    }

}
