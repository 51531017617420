import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { ProviderDetailStateService } from "../../provider-detail-state.service";
import { EmailVerificationService } from "../email-verification/email-verification.service";

@Component({
  selector: "app-thankyou",
  templateUrl: "./thankyou.component.html",
  styleUrls: ["./thankyou.component.scss"],
})
export class ThankyouComponent implements OnInit, OnDestroy {
    welcomeText = "";
    supportText = "";
    imagePath = "";
    orgId = "";
    private sink = new SubSink();
    constructor(
        private providerDetailStateService: ProviderDetailStateService
    ) { }
    ngOnInit() {

        this.sink.add(this.providerDetailStateService.state.subscribe(result => {
            this.imagePath = result.filter(x => x.attributeCode === "Logo")[0].attributeValue;

        }));
    }
    ngOnDestroy(): void {
        this.sink.unsubscribe();
    }
}
