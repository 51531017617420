import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-pends-summary",
  templateUrl: "./pends-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendsSummaryComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.PENDS_SUMMARY_NEW;

  constructor() {
    this.filters = ["Projects", "Address", "Hcc", "PendOwner", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
  }
}
