import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseGridComponent } from "../../../../shared/chase-grid/chase-grid.component";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ClinicalQueueService } from "./clinical-queue.service";

@Component({
  selector: "clinical-queue",
  templateUrl: "./clinical-queue.component.html",
  styleUrls: ["./clinical-queue.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ClinicalQueueComponent implements OnInit {

  get memberIdInput(): Textbox {
    return this.getInput("MemberId") as Textbox;
  }

  get memberNameInput(): Textbox {
    return this.getInput("MemberName") as Textbox;
  }

  get genderInput(): Autocomplete {
    return this.getInput("MemberGender");
  }
  set genderInput(value: Autocomplete) {
    this.setInput("MemberGender", value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private clinicalQueueService: ClinicalQueueService,
    private retrievalService: RetrievalPageService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private readonly automapper: AutomapperService,
    private formService: FormService
  ) {
  }

  get hasSelectedProjectId(): boolean {
    return NumberHelper.isGreaterThan(this.selectedProjectId, 0);
  }
  @ViewChild(ChaseGridComponent) chaseGridComponent: ChaseGridComponent;
  @ViewChild("chaseGrid") chaseGrid: any;
  filters: GridFilter[];
  sortDirection = -1;
  sortField = "ChasePriority";
  isQueueModalVisible = false;
  projectList: SelectableInput[];
  isUpdateQueueDisabled = true;
  selectedTab = "chases";
  memberId: string;

  membersGridConfiguration = new GridConfiguration();
  membersGridRequest: GridRequest;
  membersGridSelection: any;
  actions: BulkAction[];
  refreshMembersGrid = new EventEmitter<boolean>(true);
  @Input() url = `${this.baseApiUrl}members/list`;

  chasesGridConfiguration = new GridConfiguration();
  chasesGridRequest: GridRequest;
  chasesGridSelection: any;
  chaseActions: BulkAction[];
  refreshChaseGrid = new EventEmitter<boolean>(true);
  // Note: selectedChases is used for actions / bulk actions.
  selectedChases: any[];
  isAssignToChasesModalVisible = false;
  @Input() isUnassignEnabled = false;
  additionalBulkActions: BulkAction[] = [];
  additionalColumns: GridColumnDefinition[] = [];
  // Empty method to pass to shared chase grid for bulk actions override.
  unassignToChases: () => {};
  queueFormGroup: FormGroup;
  projectInput: Dropdown;
  selectedChaseIdsForAssignment: number[];

  ngOnInit() {
    this.createQueuePriortyForm();
    this.getProjects();
    this.createGrid();
    this.createMemberGrid();
    this.loadGrid();
  }

  onUpdateQueue(): void {
    this.isUpdateQueueDisabled = true;
    this.clinicalQueueService.updateClinicalQueueList(this.selectedProjectId.toString())
      .subscribe(() => {
        this.queueFormGroup.reset();
        this.isUpdateQueueDisabled = true;
        this.isQueueModalVisible = false;
        this.messagingService.showToast("Queue updated successfully.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      });

    this.refreshGrid();
  }

  private getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(projects => {
        this.projectList = projects;
        this.projectInput = new Dropdown({ ...this.projectInput, options: [...this.projectList] } as any);
        this.changeDetector.markForCheck();
      });
  }

  private createGrid(): void {
    this.filters = [
      new GridFilter({
        input: new Textbox(),
        key: "FunctionalRoleIds",
        value: "10,11,12", // MRR, OR1, OR2
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "StatisticsFilter",
        value: "Unassigned",
        show: false,
      }),
    ];
    this.additionalBulkActions = [
      new BulkAction({
        name: "Unassign Chase(s)",
        action: this.unassignToChases,
        disabled: true,
      }),
    ];


    this.additionalColumns = [
          new GridColumnDefinition({ field: "dateAssigned", header: "Date Assigned", show: false }),
          new GridColumnDefinition({ field: "assignedTo", header: "Assigned To", show: false }),
          new GridColumnDefinition({ field: "chartAcquired", header: "Chart Acquired", show: false }),
      ];
  }

  private refreshGrid() {
    this.chaseGridComponent.refreshGrid.next();
  }

  toggleTab(tab: string): void {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
    this.changeDetector.markForCheck();
  }

  createMemberGrid(): void {
    this.membersGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "memberId", header: "Member ID" }),
        new GridColumnDefinition({ field: "memberName", header: "Name" }),
        new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "memberGender", header: "Gender" }),
        new GridColumnDefinition({ field: "chases", header: "Chases" }),
        new GridColumnDefinition({ field: "projectCount", header: "Projects" }),
      ],
      selectionMode: "multiple",
      pageSize: 10,
      stateName: "clinical_queue_members",
      pageSizeOptions: [10, 25, 50, 100],
      rowExpandMode: "single",
      bulkActions: [
        new BulkAction({
          name: "Assign Member Chase(s)",
          action: this.openAssignToChasesModal,
          showBulkAction: true,
        }),
      ],
      showRowExpansionColumn: true,
      trackByField: "memberId",
    });
    this.actions = this.membersGridConfiguration.bulkActions;
  }

  private loadGrid(): void {
    this.membersGridRequest = new GridRequest({
      url: this.url,
      filters: this.getFilters(),
    });

    this.createChaseGrid();
  }

  private setSelectedChases(rowData: any): void {

    let gridRowData = rowData;
    if (ArrayHelper.isAvailable(this.chasesGridSelection)) {
      gridRowData = rowData.filter(chase => chase.memberId > 0);
      const [childChase] = this.chasesGridSelection;

      if (!(gridRowData.findIndex(member => member.memberId === childChase.memberId) >= 0)) {
        const childChaseIds = this.chasesGridSelection.map(chase => chase.chaseId).join(",");
        gridRowData.push({ chaseData: childChaseIds });
      }
    }

    this.selectedChases = ArrayHelper.clean(gridRowData, [gridRowData]);
    this.selectedChaseIdsForAssignment = this.getChaseIdsForAssignment();
  }

  private getChaseIdsForAssignment(): number[] {
    if (!ArrayHelper.isAvailable(this.selectedChases)) {
      return [];
    }

    const chaseData = this.selectedChases.map(chase => +chase.chaseId || chase.chaseData);
    const flattenedChaseIds = [].concat(...chaseData);
    return flattenedChaseIds;
  }

  closeModal(): void {
    this.selectedChases = [];
    this.isAssignToChasesModalVisible = false;
  }

  private getFilters(): GridFilter[] {
    const totalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "MemberId",
        name: "Member ID",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberName",
        name: "Name",
      }),
      new GridFilter({
        input: new Autocomplete({
          placeholder: "Select Gender...",
          options: [
            new SelectableInput({ text: "Female", value: "F" }),
            new SelectableInput({ text: "Male", value: "M" }),
          ],
        }),
        key: "MemberGender",
        name: "Gender",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "FunctionalRoleIds",
        value: "10,11,12",
        show: false,
      }),
    ];

    const distinctFilters = totalFilters.filter((filter, i, filters) => {
      return filters.findIndex(a => a.key === filter.key) === i;
    });

    return distinctFilters;
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.membersGridRequest == null) {
      return null;
    }

    return this.membersGridRequest.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.membersGridRequest == null) {
      return null;
    }

    this.membersGridRequest.setInput<T>(key, value);
  }

  createChaseGrid(): void {
    this.chasesGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
        new GridColumnDefinition({ field: "projectName", header: "Project" }),
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "chartAppointmentDate", header: "Scheduled Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      ],
      selectionMode: "multiple",
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      showExportAction: false,
      showMenu: false,
      bulkActions: [
        new BulkAction({
          name: "Assign Chase(s)",
          action: this.openAssignToChasesModal,
          showBulkAction: true,
        }),
      ],
    });

    this.chasesGridRequest = new GridRequest({
      url: `${this.baseApiUrl}chase/query`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "MemberId",
          value: this.memberId,
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "StatisticsFilter",
          value: "Unassigned",
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "FunctionalRoleIds",
          value: "10,11,12",
          show: false,
      }),
      ],
    });

    this.chaseActions = this.chasesGridConfiguration.bulkActions;
    this.refreshChaseGrid.emit();
  }

  onRowUnselect(event) {
    this.selectChildChases(event.data.memberId, false);
  }

  onRowSelect(event) {
    this.selectChildChases(event.data.memberId, true);
  }

  private selectChildChases(memberId: number, isDisabled: boolean): void {
    if (this.chaseGrid) {

      let selectedChases = this.chaseGrid.data;
      const [chaseData] = selectedChases;
      const isSameMember = memberId === chaseData.memberId;

      if (isSameMember) {
        this.chasesGridSelection = [];
        selectedChases.forEach(data => {
          data.disabled = isDisabled;
        });

        this.chasesGridConfiguration.isHeaderCheckBoxDisabled = isDisabled;
      } else {
        selectedChases = this.chasesGridSelection;
        this.chasesGridSelection = [];
      }

      const isBulkActionDisabled = this.membersGridSelection.length > 0 || false;

      this.chasesGridConfiguration.bulkActions.forEach(bulkAction => {
        bulkAction.disabled = isBulkActionDisabled;
      });

      // code does not work without timeout
      setTimeout(() => {
        this.chasesGridSelection = selectedChases;
        this.changeDetector.markForCheck();
      },         0);
    }
  }

  openAssignToChasesModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (rowData !== null) {
      this.isAssignToChasesModalVisible = true;
    } else {
      this.selectedChases = [];
      this.messagingService.showToast("Please select chase.", SeverityType.ERROR);
    }
  }

  onRowExpandChange(event) {
    if (event) {
      this.chasesGridConfiguration.isHeaderCheckBoxDisabled = false;
      this.chasesGridSelection = [];
      this.chasesGridConfiguration.bulkActions.forEach(bulkAction => {
        bulkAction.disabled = false;
      });
      this.memberId = event.data.memberId;
      this.createChaseGrid();
    }
  }

  gridDataLoaded(data: any) {
    this.changeDetector.markForCheck();
    this.membersGridSelection = [];
  }

  gridRefresh(): void {
    this.refreshChaseGrid.emit();
    this.refreshMembersGrid.emit();
    this.chasesGridSelection = [];
  }

  createQueuePriortyForm(): void {
    this.projectInput =
      new Dropdown({
        key: "projects",
        label: "Project",
        placeholder: "Select Project",
        appendTo: "body",
      });
    this.queueFormGroup = this.formService.createFormGroup([this.projectInput]);
    }

  get selectedProjectId(): number {
    return Number(this.queueFormGroup.get(this.projectInput.key).value);
  }
}
