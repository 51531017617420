import { PhoneNumberPipe } from "../../../shared/pipes/phone.pipe";
import { TagItems } from "../../../shared/tags/model/tag-items.model";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class ChaseItem {
  private phonePipe = new PhoneNumberPipe();
  disabled?: boolean;
  readonly assignedTo: string;
  readonly assignedToUserId: number;
  readonly chaseCommentFormatted: string;
  readonly chartCommitmentDate: string;
  readonly chartExpectedDate: string;
  readonly chartRequestDate: string;
  readonly chaseComplianceCode: string;
  readonly chaseId: number;
  readonly chasePendId: number;
  readonly coRetrievalOwner: string;
  readonly commentExists: boolean;
  readonly createUserId: number;
  readonly dateOfService: string;
  readonly documentRequestId: number;
  readonly isClinical?: boolean;
  readonly isClinicalAndNonClinicalChases?: boolean;
  readonly isThirdParty?: boolean;
  readonly masterDocumentSourceId?: number;
  readonly totalOpenGaps?: number;
  readonly openGaps?: string;
  readonly measureCode: string;
  readonly measureID: number;
  readonly measureName: string;
  readonly memberDateOfBirth: string;
  readonly memberFirstName: string;
  readonly memberGender: string;
  readonly memberID: number;
  readonly memberLastName: string;
  readonly memberName?: string;
  readonly memberValidationReason: string;
  readonly numeratorList: string;
  readonly organizationId: number;
  readonly parentChaseId?: number;
  readonly pendCode: string;
  readonly pendOwner: string;
  readonly pendStatus: string;
  readonly pendStatusId: number;
  readonly product: string;
  readonly projectID: number;
  readonly projectName: string;
  readonly projectReviewPeriod: string;
  readonly provider: string;
  readonly providerCount: number;
  readonly providerList: string;
  readonly pursuitChases?: string;
  readonly recordCount: number;
  readonly recordNumber: number;
  readonly reportingStatusName: string;
  readonly sampleComplianceCode: string;
  readonly scheduledDate: string;
  readonly serviceProviderData: string;
  readonly serviceProviders: string;
  readonly serviceProviderName?: string;
  readonly tags: string;
  readonly tagData: string;
  readonly tagItems?: TagItems[];
  readonly tagsText: string;
  readonly workflowStatusId: number;
  readonly workflowStatusName: string;
  readonly previousMasterDocumentSourceID?: number;
  readonly outreachTemplateName: string;
  readonly tin: string;
  readonly npi?: string;
  readonly contactName: string;
  readonly contactPhone: string;
  readonly isGapProject: boolean;

  constructor(options: Partial<ChaseItem> = {}) {
    this.assignedTo = StringHelper.clean(options.assignedTo);
    this.assignedToUserId = options.assignedToUserId;
    this.chaseCommentFormatted = StringHelper.clean(options.chaseCommentFormatted);
    this.chartCommitmentDate = StringHelper.clean(options.chartCommitmentDate);
    this.chartExpectedDate = StringHelper.clean(options.chartExpectedDate);
    this.chartRequestDate = StringHelper.clean(options.chartRequestDate);
    this.chaseComplianceCode = StringHelper.clean(options.chaseComplianceCode);
    this.chaseId = options.chaseId;
    this.chasePendId = options.chasePendId;
    this.commentExists = options.commentExists;
    this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
    this.createUserId = options.createUserId;
    this.dateOfService = StringHelper.clean(options.dateOfService);
    this.disabled = false;
    this.documentRequestId = options.documentRequestId;
    this.isClinical = options.isClinical;
    this.isClinicalAndNonClinicalChases = options.isClinicalAndNonClinicalChases;
    this.isThirdParty = options.isThirdParty;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.totalOpenGaps = options.totalOpenGaps;
    this.openGaps = StringHelper.clean(options.openGaps);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.measureID = options.measureID;
    this.measureName = StringHelper.clean(options.measureName);
    this.memberDateOfBirth = StringHelper.clean(options.memberDateOfBirth);
    this.memberFirstName = StringHelper.clean(options.memberFirstName);
    this.memberGender = StringHelper.clean(options.memberGender);
    this.memberID = options.memberID;
    this.memberLastName = StringHelper.clean(options.memberLastName);
    this.memberName = StringHelper.clean(options.memberName);
    this.memberValidationReason = StringHelper.clean(options.memberValidationReason);
    this.numeratorList = StringHelper.clean(options.numeratorList);
    this.organizationId = options.organizationId;
    this.parentChaseId = options.parentChaseId;
    this.pendCode = StringHelper.clean(options.pendCode);
    this.pendOwner = StringHelper.clean(options.pendOwner);
    this.pendStatus = StringHelper.clean(options.pendStatus);
    this.pendStatusId = options.pendStatusId;
    this.product = StringHelper.clean(options.product);
    this.projectID = options.projectID;
    this.projectName = StringHelper.clean(options.projectName);
    this.projectReviewPeriod = options.projectReviewPeriod;
    this.providerCount = options.providerCount;
    this.providerList = StringHelper.clean(options.providerList);
    this.pursuitChases = StringHelper.clean(options.pursuitChases);
    this.recordCount = options.recordCount;
    this.recordNumber = options.recordNumber;
    this.reportingStatusName = StringHelper.clean(options.reportingStatusName);
    this.sampleComplianceCode = StringHelper.clean(options.sampleComplianceCode);
    this.serviceProviderData = StringHelper.clean(options.serviceProviderData);
    this.serviceProviders = StringHelper.clean(options.serviceProviders);
    this.serviceProviderName = StringHelper.clean(options.serviceProviderName);
    this.tags = options.tags;
    this.tagData = StringHelper.clean(options.tagData);
    this.tagItems = options.tagItems;
    this.tagsText = StringHelper.clean(options.tagsText);
    this.workflowStatusId = options.workflowStatusId;
    this.workflowStatusName = StringHelper.clean(options.workflowStatusName);
    this.previousMasterDocumentSourceID = options.previousMasterDocumentSourceID;
    this.outreachTemplateName = StringHelper.clean(options.outreachTemplateName);
    this.tin = options.tin;
    this.npi = StringHelper.clean(options.npi);
    this.contactName = options.contactName;
    this.contactPhone = this.phonePipe.transform(options.contactPhone);
    this.isGapProject = options.isGapProject;
  }

  get chaseID(): number {
    return this.chaseId;
  }
}
