export class NumeratorListItem {
  readonly numeratorId: number;
  readonly numeratorCode: string;
  readonly numeratorName: string;
  readonly chaseId?: number;
  readonly displayNumeratorCode?: number;

  constructor(options: {
    numeratorId: number;
    numeratorCode: string;
    numeratorName: string;
    chaseId?: number;
    displayNumeratorCode?: number;
  }) {
    this.numeratorId = options.numeratorId;
    this.numeratorCode = options.numeratorCode;
    this.numeratorName = options.numeratorName;
    this.chaseId = options.chaseId;
    this.displayNumeratorCode = options.displayNumeratorCode;
  }
}
