export enum DocumentStateGroup {
  Active = 1,
  OnHold = 2,
  Error = 3,
  Completed = 4,
  Removed = 5,
  SystemRemoved = 6,
  SystemProcessing = 7,

}
