<div class="main">
    <div class="logo logo__text">
        <div class="logo__text--icon">
            <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-text--red"></app-icon>
        </div>
    </div>
</div>

<div class="provider-wrapper" *ngIf="showProviderPortal">

    <div class="provider-block" >
        <div class="provider-block__head text-center">
            <h2 *ngIf="!showUploadPortal">
                PROVIDER GATEWAY CHART DROP
                <p>We appreciate your assistance in providing medical records through Provider Gateway’s secure Chart Drop</p>
                <label class="d-block">Please enter your secure 8-digit PIN # to continue</label>
            </h2>

            <h2 class="welcome" *ngIf="showUploadPortal">
                Welcome to Chart Drop!
            </h2>

            <div class="provider-inputs">
                <div class="input-cell">
                    <form-factory #formRow [formGroup]="formGroup" [model]="pinCode" (onInput)="keyUpEvent()">
                    </form-factory>
                </div>
            </div>

            <p class="confirmation-message"  *ngIf="showUploadPortal">
                You will receive a confirmation number after your documents have been successfully uploaded
            </p>

        </div>
         <div class="provider-inner drag-drop-rw" *ngIf="showUploadPortal">
            <div class="drag-drop-blc">
                <p-fileUpload #fileUpload name="file[]" (onSelect)="onFileSelect($event, fileUpload)"
                    (onRemove)="onFileRemove($event)" (uploadHandler)="uploadDocuments()" customUpload="true"
                    multiple="true" accept=".pdf" [maxFileSize]="maxFileSizeForProviderUploadInBytes" chooseLabel="CHOOSE FILES"
                    uploadLabel="VALIDATE" [showUploadButton]="false" [showCancelButton]="false" placeholder="testing"
                    invalidFileSizeMessageSummary="{0}: Invalid file size, " 
                    dragDropSupport="true" mode="advanced"
                    invalidFileSizeMessageDetail="maximum upload size is {maxFileSizeForProviderUploadInMB | number:'1.0-0'} MB. Reach out to your Project Manager">
                    <ng-template pTemplate="content">
                            <div class="inner-blc">
                                    <div class="file-upload-container__text">
                                        <span class="drag-drop" >Drag & Drop Medical Records Here</span>
                                    </div>
                                    <div class="drag-drop-icn">
                                        <span>
                                            <span class="top"></span>
                                            <span class="btm"></span>
                                        </span>
                                    </div>
                                    <div class="or-line">
                                        <span></span>
                                        <label>OR</label>
                                        <span></span>
                                    </div>
                                </div>
                    </ng-template>
                </p-fileUpload>

            </div>
            <div class="drag-drop-list" *ngIf="filesUploaded">
                <div class="list-blc">
                    <div class="list-item" *ngFor="let file of selectedFileList; index as i; trackBy: trackByIndex">
                        <label (mouseenter)="file.name.length > 28 ? fileNamePopover.show($event) : fileNamePopover.hide()" (mouseout)="fileNamePopover.hide()">{{file.name | titlecase }}</label>
                        <app-popover #fileNamePopover appendTo="body">
                            <div class="popover-text">{{file.name}}</div>
                        </app-popover>
                    <div class="chip__item chip__item--icon" (click)="onFileRemove(file, i)">
                        <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
                    </div>
                    </div>
                </div>
                <div class="list-blc-btn">
                    <button class="btn btn-success" (click)="uploadDocuments()">SUBMIT</button>
                </div>
            </div>
            <div class="drag-drop-size" >
                 <div class="tips-troubleshooting">
                    <a href="https://reveleer.freshdesk.com/support/solutions/articles/48001221874-provider-gateway-tips-troubleshooting" target="_blank" >Tips & Troubleshooting</a>
                </div>
                <div class="limit" *ngIf="filesUploaded" >
                    <span  >File Upload Limit is 1.5GB</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dialog_outer"  *ngIf="showOnSuccess">

  <div class="provider-block  text-center">
    <div class="provider-block__icon">
        <app-icon iconStyle="reveleer" iconType="reveleer" iconName="upload-success"></app-icon>
    </div>
   <div class="provider-block__head thankshead">
      <h3>Thank you!</h3>
       <p> Your files have been received.</p>
     </div>
    <div class="provider-block__confirmation">
      <p>Your confirmation number is:</p>
      <label>{{uploadBatchCode}}</label>
      <p><button class="new_download_receipt" (click)="downloadReceipt()">Download Receipt</button></p>
    </div>
    <div class="provider-block__more">
     <p>Want to upload more documents?</p>
      <button class="new_upload_btn" (click)="startBulkUpload()">Start New Upload</button>
    </div>
  </div>
  
</div>
<div class="dialog_outer"  *ngIf="showOnPartial">
    <div class="provider-block  text-center">
      <div class="provider-block__icon uploadpartial">
        <app-icon iconStyle="fal" iconType="fa" iconName="exclamation-triangle"></app-icon>
      </div>
     <div class="provider-block__head thankshead">
        <h3>Thank you!</h3>
         <p>Some records did not upload successfully. See receipt for more details.</p>
       </div>
      <div class="provider-block__confirmation">
        <p>Your confirmation number for successful records is:</p>
        <label>{{uploadBatchCode}}</label>
        <p><button class="new_download_receipt" (click)="downloadReceipt()">Download Receipt</button></p>
      </div>
      <div class="provider-block__more">
       <p>Want to upload more documents?</p>
        <button class="new_upload_btn" (click)="startBulkUpload()">Start New Upload</button>
      </div>
    </div>
</div>
<div class="provider-wrapper error_block"  *ngIf="showOnFailure">
    <div class="provider-block  text-center" >
        <div class="provider-block__icon">
            <app-icon iconStyle="reveleer" iconType="reveleer" iconName="upload-error"></app-icon>
        </div>
        <div class="provider-block__head bottom_margin thankshead">
         <h3>Uh-Oh!</h3>
         <p>There was an error with your upload. Please try again.</p>
       </div>
       <div class="provider-block__more">
        <button class="new_upload_btn new_upload_btn_if_error" (click)="startBulkUpload()">Start New Upload</button>
     </div>
   </div>
</div>
<div class="dialog_outer"  *ngIf="isUploadLoader">

    <div class="provider-block  text-center">
      <div class="provider-block__icon uploadloader">
        <app-icon iconStyle="fal" iconType="fa" iconName="arrow-circle-up"></app-icon>
      </div>
     <div class="provider-block__loader">
        <h3>Upload in Progress...</h3>
         <p> Please do not close your browser or leave this page until <br> you receive an upload confirmation. </p>
    </div>
      
    </div>
    
  </div>
