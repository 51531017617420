import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MasterProvider } from "../../../../shared/national-provider-grid/national-provider.model";
import { AddressSearchRequest } from "../../dashboard/retrieval/address-search-request.model";
import { AddressSearchResult } from "./address-search-result.model";
import { CreateAddress } from "./create-address.model";

@Injectable({
  providedIn: "root",
})
export class AddressSearchService {
  private pSearchAddress = new BehaviorSubject<MasterProvider>(null);
  searchAddress = this.pSearchAddress.asObservable();

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  addressSearch(addressSearchRequest: AddressSearchRequest): Observable<AddressSearchResult[]> {
    const url = `${this.baseApiUrl}retrieval/searchList`;

    return this.http.post(url, addressSearchRequest).pipe(
      map(this.automapper.curryMany("address-search-item", "AddressSearchResult")));
  }

  createAddress(createAddressModel: CreateAddress): Observable<number> {
    const url = `${this.baseApiUrl}retrieval/createAddress`;

    return this.http.post(url, createAddressModel) as Observable<number>;
  }

  chaseAddressSearch(addressSearchRequest: AddressSearchRequest): Observable<AddressSearchResult[]> {
    const url = `${this.baseApiUrl}retrieval/address/search`;

    return this.http.post(url, addressSearchRequest).pipe(
      map(this.automapper.curryMany("address-search-item", "AddressSearchResult")));
  }

  populateAddressSearch(provider: MasterProvider) {
    this.pSearchAddress.next(provider);
  }
}
