import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Inject, OnInit, ViewChild } from "@angular/core";
import { SelectItem } from "primeng/api";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { AddressesQueueService } from "../../retrieval/addresses-queue/addresses-queue.service";
import { ActionItem } from "../../retrieval/retrieval-page/retreival-page-action-item";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";

@Component({
  selector: "app-master-document-source-list",
  templateUrl: "./master-document-source-list.component.html",
  styleUrls: ["./master-document-source-list.component.scss"],
})
export class MasterDocumentSourceListComponent implements DoCheck, OnInit {
  gridRefresh = new EventEmitter<null>();
  gridConfigurationModel = new GridConfiguration();
  searchRequest: GridRequest;
  serverGridSelection: any;
  addressId: number;
  address: string;
  isAssignModelVisible = false;
  selectedUser: SelectableInput;
  assignedUsers: SelectItem[];
  assignedUserListFiltered: SelectItem[];
  get addressIdInput(): Textbox {
    return this.getInput("Id") as Textbox;
  }
  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects") as CheckboxGroup;
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }
  actionItems: ActionItem[] = [];
  organizationStatisticIds: string;
  projectStatisticIds: string;
  @ViewChild(ServerGridComponent, { static: true }) private serverGridComponent: ServerGridComponent;
  defaultTotalCount = 500;
  totalChasesCount: number;
  totalChasesMessage: string;

  addressQueueFunctions = {
    openAssignModel: (rowData: any) => {
      const selectedRows = rowData;
      this.addressId = selectedRows.masterDocumentSourceID;
      this.isAssignModelVisible = true;
    },
  };


  constructor(
    private retrievalService: RetrievalPageService,
    private addressesQueueService: AddressesQueueService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private automapper: AutomapperService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) { }

  ngOnInit() {
    this.actionItems = [new ActionItem("Assign To", "openAssignModel")];
    this.getUser();
    this.getProjects();
    this.createGrid();
  }

  ngDoCheck() {
    if (this.serverGridComponent.data.length > 0) {
      this.totalChasesCount = ArrayHelper.isAvailable(this.serverGridComponent.data) ? this.serverGridComponent.data[0].recordCount : 0;
      if (this.totalChasesCount > 0) {
        this.totalChasesMessage = this.totalChasesCount > this.defaultTotalCount
          ? `Top ${this.defaultTotalCount} of ${this.totalChasesCount} record(s).`
          : `Top ${this.totalChasesCount} records.`;
      }
    }
  }

  get selectedUserId(): number {
    if (this.selectedUser == null) {
      return null;
    }

    return +this.selectedUser.value;
  }

  get hasSelectedUser(): boolean {
    return NumberHelper.isGreaterThan(this.selectedUserId, 0);
  }

  getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectsInput = { ...this.projectsInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  getUser(): void {
    this.retrievalService.getAssignedToUsersList()
      .subscribe((data: any[]): void => {
        const users = [];
        users.push({ label: "Select User", value: "" });
        for (const item of data) {
          users.push({ label: item.name, value: item.userId });
        }

        this.assignedUsers = users;
        this.changeDetector.markForCheck();
      });
  }

  private createGrid() {
    this.gridConfigurationModel = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "masterDocumentSourceID", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceID" }),
        new GridColumnDefinition({ field: "address", header: "Address" }),
        new GridColumnDefinition({ field: "commitmentDate", header: "Commitment Date" }),
        new GridColumnDefinition({ field: "nextAppointmentDate", header: "Next Appointment Date" }),
        new GridColumnDefinition({ field: "userName", header: "Assigned To" }),
        new GridColumnDefinition({ field: "chaseCount", header: "Original Chases" }),
        new GridColumnDefinition({ field: "chasePendCount", header: "Pends" }),
        new GridColumnDefinition({ field: "status", header: "Status" }),
        new GridColumnDefinition({ field: "project", header: "Project(s)" }),
      ],
      pageSize: 25,
    });

    // TODO: Use session storage service...
    this.organizationStatisticIds = window.sessionStorage.getItem("OrganizationStatisticIds");
    if (!StringHelper.isAvailable(this.organizationStatisticIds)) {
      this.projectStatisticIds = window.sessionStorage.getItem("ProjectStatisticIds");
    }

    window.sessionStorage.removeItem("OrganizationStatisticIds");
    window.sessionStorage.removeItem("ProjectStatisticIds");

    this.searchRequest = new GridRequest({
      url: `${this.baseApiUrl}reporting/scheduling/list`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "OrganizationStatisticsIds",
          value: this.organizationStatisticIds,
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ProjectStatisticsIds",
          value: this.projectStatisticIds,
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "Id",
          name: "AID",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Projects",
        }),
      ],
    });
  }

  trackByIndex(index, item) {
    return index;
  }

  filterOptions(event): void {
    this.assignedUserListFiltered = this.assignedUsers.filter(item => {
      return item.label.toLowerCase().indexOf(event.query.toLowerCase()) >= 0;
    });
    this.changeDetector.markForCheck();
  }

  onAssign() {
    this.addressesQueueService.assignAddressesToUser(this.addressId, this.selectedUserId)
      .subscribe(() => {
        this.refreshGrid();
        this.selectedUser = null;
        this.isAssignModelVisible = false;
        this.messagingService.showToast("Assigned successfully.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      });
  }


  private refreshGrid(): void {
    this.gridRefresh.emit();
  }

  private getInput(key: string): DynamicInput {
    if (this.searchRequest == null) {
      return null;
    }

    return this.searchRequest.getInput(key);
  }

  private setInput(key: string, value: DynamicInput): void {
    if (this.searchRequest == null) {
      return null;
    }

    this.searchRequest.setInput(key, value);
  }
}
