<div class="diagnoses-header">
  <div class="diagnoses-header__title">Coder Dx</div>
  <app-badge [text]="totalDiagnoses" [isLarge]="false" cssClasses="badge-diagnoses counter"></app-badge>
</div>
<p-accordion (onOpen)="onTabOpen($event)" class="app-accordion">
  <p-accordionTab *ngFor="let coderDiagnosis of coderDiagnoses; trackBy: trackByIndex; let i = index"
                  [(selected)]="activeState[i]"
                  class="ui-accordion-tab"
                  id="coder-{{coderDiagnosis.id}}">
    <p-header>
      <app-card-header
        [diagnosis]="coderDiagnosis"
        [badgeStatus]="getBadgeStatus(coderDiagnosis, i)"
        [hasDeleteEnabled]="activeState[i]"
        [isCardOpen]="activeState[i]"
        (onDelete)="showDeleteDiagnosisModal($event)">
      </app-card-header>
    </p-header>
    <form-factory class="diagnosis-save-group" [formGroup]="form" [model]="saveGroup" (keyup)="handleKeyUp()" (onChange)="handleChange($event)">
    </form-factory>
    <div>
      <app-button [disabled]="!isSaveEnabled" [class.disabled]="!isSaveEnabled" class="diagnosis-save-button" title="Submit" (onClick)="saveDiagnosis()" text="Save Diagnosis"></app-button>
      <div class="exempt" *ngIf="isOverread">
        <form-factory [formGroup]="form" [model]="exemptInput" (onChange)="tryEnablingSaveButton()"></form-factory>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<app-confirmation-modal
  class="delete-diagnosis-modal"
  [(visible)]="isConfirmDeleteVisible"
  header="WARNING"
  acceptText="DELETE"
  rejectText="CANCEL"
  (onYes)="deleteDiagnosis()">
  <b>Are you sure you want to delete this diagnosis?</b>
</app-confirmation-modal>
