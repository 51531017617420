import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";

@Injectable({
  providedIn: "root",
})
export class CoverLetterTemplateService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getCoverLetterTemplate(gapType: string = ""): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}provider/outreach/templates?gapType=${gapType}`;
    // TODO: add caching
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("TemplateName", "SelectableInput"))
    );
  }
}
