import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-risk-clinical-summary",
  templateUrl: "./risk-clinical-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskClinicalSummaryComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.RISK_CLINICAL_SUMMARY;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
