import { NgModule } from "@angular/core";
import { FileUploadModule } from "primeng/fileupload";
import { SharedModule } from "../../../shared/shared.module";
import { DataLoadRoutingModule } from "./data-load-routing.module";
import { DataLoadComponent } from "./data-load.component";
import { SummaryComponent } from "./summary/summary.component";
import { DataLoadUploadComponent } from "./upload/upload.component";

@NgModule({
  imports: [
    DataLoadRoutingModule,
    SharedModule,
    FileUploadModule,
  ],
  declarations: [
    DataLoadComponent,
    DataLoadUploadComponent,
    SummaryComponent,
  ],
})
export class DataLoadModule { }
