import { List } from "immutable";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";

export class ArrayHelper {
  static isAvailable(value: any[] | List<any>): boolean {
    if (!Array.isArray(value) && !List.isList(value)) {
      return false;
    }

    const list = List(value);
    return list.size > 0;
  }

  static throwIsAvailableError(value: any[], propertyName: string): void {
    if (!this.isAvailable(value)) {
      throw new Error(`The property '${propertyName}' cannot be null or empty.`);
    }
  }

  static toArray(value: any[] | List<any>): any[] {
    return List.isList(value) ? (value as List<any>).toArray() : (value as any[]);
  }

  static addItemToSelect(data: SelectableInput[], text: string, value: string | number, extra?: any): SelectableInput[] {
    data.unshift(new SelectableInput({
      text, value, extra,
    }));

    return data;
  }

  static clean(value: any[], defaultValue = []): any[] {
    return this.isAvailable(value) ? value : defaultValue;
  }

  static chunkArray(value: any[], chunkSize: number): any[] {
    if (!this.isAvailable(value)) {
      return [];
    }

    const chunked = [];
    let index = 0;
    while (index < value.length) {
      chunked.push(value.slice(index, chunkSize + index));
      index += chunkSize;
    }
    return chunked;
  }

  static compareArrayIsEqual(array1?: any[], array2?: any[]) {
    if (this.isAvailable(array1) && this.isAvailable(array2)) {
      const result = array1.length === array2.length &&
        array1.every(element => {
          return array2.includes(element);
        });
      return result;
    }
    return false;
  }

  static groupBy<T>(array: T[], key: string): Map<number, T[]> {
    const groupedMap = array.reduce(
      (entryMap, e) => entryMap.set(e[key], [...entryMap.get(e[key]) || [], e]),
      new Map()
    );
    return groupedMap;
  }

  static getUniqueObjectByKey<T>(array: T[], key: string): any[] {
    const uniqueMap = [...new Map(array.map(item => [item[key], item])).values()];
    return uniqueMap;
  }
}
