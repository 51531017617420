import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BASE_API_URL } from "../../../core/environment.tokens";

import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AuditService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
    ) { }
  userAuditLog(pageTypeId: number, pageUrl: string): Observable<boolean> {
    const url = `${this.baseApiUrl}audit/saveuserauditlog?pageTypeId=${pageTypeId}&url=${pageUrl}`;
    return this.http.post(url, pageTypeId) as Observable<boolean>;
  }
}
