import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ListItem } from "../../../../shared/list/list-item";
import { AccessInfoModel } from "./access-info-view-edit-model";

@Injectable({
  providedIn: "root",
})
export class AccessInfoViewEditService {
  refresh = new Subject<any>();
  reset = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute

  ) { }

  refreshFunction() {

    this.refresh.next();
  }

  resetRetrievalId(retrievalId: number) {

    this.reset.next(retrievalId);
  }

  getAccessInfoDetailsForEdit(retrievalId: number): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/accessInfoDetailsForEdit?retrievalId=${retrievalId}`;

    return this.http.get(url);

  }

  updateAccessInfo(accessInfoEditModel: AccessInfoModel, retrievalId: number): Observable<number> {

    const url = `${this.baseApiUrl}retrieval/UpdateAccessInfo?retrievalId=${retrievalId}`;

    return this.http
      .post(url, accessInfoEditModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? 1 : 0)
      );

  }

  getAccessInfoSummaryItems(retrievalId: number): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}retrieval/accessInfoSummary?retrievalId=${retrievalId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  upload(formData: FormData, retrievalId: number): Observable<any> {
    const url = `${this.baseApiUrl}retrievalDocument/accessInfoUpload?retrievalId=${retrievalId}`;

    return this.http.post(url, formData);

  }
}
