import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { DownloaderService } from "../../../../core/downloader/downloader.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { SessionService } from "../../../../core/storage/session.service";
import { UserService } from "../../../../core/user/user.service";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ChaseGridComponent } from "../../../../shared/chase-grid/chase-grid.component";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { AuditService } from "../../audit/audit.service";
import { ProviderPacketItem } from "../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { PROJECT_CHASE_QUERY_GRID, PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES } from "../../member/chase-detail/chase-detail-chart/attributes";
import { PageTypeName } from "../../retrieval/retreival-document-review/page-type.enum";
import { ChaseQueryService } from "./chase-query.service";
import { RouteFilters } from "./route-filters.model";

@Component({
    selector: "project-chase-query",
    templateUrl: "./chase-query.component.html",
    styleUrls: ["./chase-query.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseQueryComponent implements OnInit, OnDestroy {
    private sink = new SubSink();
    @ViewChild(ChaseGridComponent, { static: true }) chaseGridComponent: ChaseGridComponent;
    additionalFilters: GridFilter[] = [];
    additionalColumns: GridColumnDefinition[] = [];
    chaseGridStateName = PROJECT_CHASE_QUERY_GRID.attributeCode;
    viewAttributeId = PROJECT_CHASE_QUERY_GRID.attributeId;
    saveQueryAttributeId = PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES.attributeId;
    selectedChases: any[];
    routeParameters: RouteFilters;
    showNextFilters = true;
    isSOCoRetrievalOwner = false;
    loggedInUser: UserToken;
    constructor(
        private service: ChaseQueryService,
        private messagingService: MessagingService,
        private router: Router,
        private parameterService: ParameterService,
        private sessionService: SessionService,
        private readonly userService: UserService,
        private auditService: AuditService,
        private downloaderService: DownloaderService
 ) {
      this.loggedInUser = this.userService.getUserToken();
      this.isSOCoRetrievalOwner = this.loggedInUser.isCoRetrievalEnabled;
    }
    ngOnInit() {
        this.userAuditLog();
        this.getRouteParameters();
        this.createChaseGrid();
        this.sink.add(
            this.router.events.subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    this.showFilters();
                }
            })
        );
    }
    userAuditLog(): void {
         const url = window.location.href;
         this.auditService.userAuditLog(PageTypeName.ChaseQuery, url)
          .subscribe(() => {
            });
       }

    ngOnDestroy() {
        this.sink.unsubscribe();
    }

    showFilters() {
        this.chaseGridComponent.serverGridComponent.showFilters();
    }

    printProvider(rowData: any) {
        const selectedRows = rowData;
        const arrchaseid = [];
        arrchaseid.push(selectedRows.chaseId);
        const projectId = selectedRows.projectID;

        const providerPacketItem = new ProviderPacketItem({
            chaseIds: arrchaseid,
            projectId,
            toName: "",
            toPhone: "",
            toFax: "",
            fromName: "",
            fromPhone: "",
            providerName: "",
            serviceOrgName: "",
        });

        this.service
            .printProviderPacket(providerPacketItem)
            .subscribe(item => {
                if (item !== "") {
                    this.downloaderService.fromRawUrl(item);
                } else {
                    this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
                }
            });
    }

    private createChaseGrid(): void {
        this.additionalFilters = [
            new GridFilter({
                input: new Radiobutton({
                    options: [
                        new SelectableInput({ text: "Yes", value: 1 }),
                        new SelectableInput({ text: "No", value: 0 }),
                        new SelectableInput({ text: "Show All", value: "" }),
                    ],
                }),
                key: "encounterFound",
                name: "DOS Confirmed",
            }),
            new GridFilter({
                input: new Radiobutton({
                    options: [
                        new SelectableInput({ text: "Yes", value: 1 }),
                        new SelectableInput({ text: "No", value: 0 }),
                        new SelectableInput({ text: "Show All", value: "" }),
                    ],
                }),
                key: "hccDiscrepency",
                name: "Hcc Discrepency",
            }),
            new GridFilter({
                input: new Radiobutton({
                    options: [
                        new SelectableInput({ text: "Parent Chases", value: "ParentChase" }),
                        new SelectableInput({ text: "Pursuit Chases", value: "PursuitChase" }),
                        new SelectableInput({ text: "Show All", value: "All" }),
                        new SelectableInput({ text: "Exclude", value: "None" }),
                    ],
                }),
                key: "pursuit",
                name: "Pursuits",
                value: this.pursuitFilterValue,
            }),
        ];
        this.additionalColumns = [new GridColumnDefinition({ field: "memberValidationReason", header: "Move Back Reason" })];

        if (this.isSOCoRetrievalOwner) {
        this.additionalFilters.push(new GridFilter({
          input: new Radiobutton({
            options: [
              new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
              new SelectableInput({ text: "Client", value: "Client" }),

            ],
          }),
          key: "coRetrievalOwner",
          name: "Co Retrieval Owner",
          show: true,
        })
        );
        this.additionalColumns.push(new GridColumnDefinition({ field: "coRetrievalOwner", header: "Retriever" }));
      }
    }

    get pursuitFilterValue(): string {
        return this.routeParameters != null ? this.routeParameters.pursuits : "";
    }

    private getRouteParameters(): void {
        this.routeParameters = new RouteFilters(
            this.getRouteValues("ProjectID"),
            this.getRouteValues("ChaseStatus"),
            this.getRouteValues("Measure"),
            this.getRouteValues("ChaseCompliance"),
            this.getRouteValues("SampleCompliance"),
            this.getRouteValues("LastCodedBy"),
            this.getRouteValues("PendStatus"),
            this.getRouteValues("Pursuits")
        );
    }

    private getRouteValues(parameterName: string): string {
        return this.parameterService.getNormal(parameterName);
    }

    createNewChase() {
        this.sessionService.put("gapChaseCreation", false);
        this.router.navigate(["members", "chase-steps-menu"]);
    }
}
