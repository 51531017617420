<form [formGroup]="formGroupAuditQueryFilters"
  class="form-factory-container">
  <div class="audit-query-grid-filters__filters-container">
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__filter-container--input">
      <form-factory
        [formGroup]="formGroupAuditQueryFilters"
        [model]="projects"
        (onChange)="projectSelectionChange($event)">
      </form-factory>
    </div>
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__filter-container--input">
      <form-factory
        [formGroup]="formGroupAuditQueryFilters"
        [model]="measures"
        (onChange)="measureSelectionChange($event)">
      </form-factory>
    </div>
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__filter-container--input">
      <form-factory
        [formGroup]="formGroupAuditQueryFilters"
        [model]="numerators">
      </form-factory>
    </div>
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__filter-container--input">
      <form-factory
        [formGroup]="formGroupAuditQueryFilters"
        [model]="complianceCodes">
      </form-factory>
    </div>
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__btns">
      <app-button
        class="header-button"
        (onClick)="applyFilters()"
        text="APPLY">
      </app-button>
    </div>
    <div class="audit-query-grid-filters__filter-container audit-query-grid-filters__btns">
      <app-button
        (onClick)="clearFilters()"
        class="reset"
        text="RESET"
        styleClass="ui-button-secondary">

      </app-button>
    </div>
  </div>
</form>
