<app-collapsible-panel>
    <header>
      <app-button class="button" styleType="far" type="fa" [icon]="collapseIcon" classes="transparent small" (onClick)="toggle($event)"></app-button>
      <div class="filter__header">QUERY RESULTS ({{ totalRecords }})</div>
      <app-button class="button clear-button--right" text="Modify" classes="transparent" (onClick)="modifyQuery()"></app-button>
    </header>
    <div class="chip"
         *ngFor="let filter of filteredFilters; let i = index; trackBy: trackByIndex"
         (click)="delete($event, i)">
      <div class="chip__item chip__item--name">
        {{ filter.text }}:&nbsp;
      </div>
      <div class="chip__item chip__item--value">
        {{ filter.value }}
      </div>
      <div class="chip__item chip__item--icon">
        <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
      </div>
    </div>
  </app-collapsible-panel>
  