<div class="grid-header bold">CONTACT ATTEMPT HISTORY</div>

<div class="contact-history-container">

  <app-basic-grid [configuration]="contactHistoryGridConfiguration"
                  [data]="contactHistoryGridData"
                  [(selection)]="contactHistoryGridSelection">
  </app-basic-grid>

</div>
