<div class="document-page-assignment__header">WORKING PAGES<app-tool-tip
  text="Working pages is where you can see all pages identified as Chases or to be Ignored. Click the Commit Chase Assignments button to attach these pages to the Chases.">
</app-tool-tip></div>
<app-server-grid #gridWorkingPageAssignments [configuration]="workingPageAssignmentsServerGridConfiguration"
  [(request)]="workingPageAssignmentsServerRequest" [refresh]="refreshWorkingPageAssignmentGrid"
  [actions]="workingPageAssignmentsActions" [actionTemplate]="workingPageAssignmentsActionsTemplate"
  (dataLoaded)="workingPageAssignmentsGridDataLoaded($event)" >

  <ng-template #workingChaseIdColumn let-rowData let-index="index">
    <div *ngIf="displayWorkingChaseId(rowData.chaseId,index)" (mouseenter)="projectNamePopover.show($event)" (mouseout)="projectNamePopover.hide()">
      {{rowData.chaseId}}
        <app-popover #projectNamePopover [appendTo]="body">
            <div class="popover-text">{{rowData.projectName}}</div>
        </app-popover>
    </div>
  </ng-template>
  <ng-template #workingPageRangeColumn let-rowData let-index="index">
    <a href="javascript:void(0)" (click)="goToWorkingDocumentPageClick(rowData,index)">({{rowData.startPage}} -
      {{rowData.endPage}})</a>
  </ng-template>
  <ng-template #workingPageAssignmentsActionsTemplate let-rowData let-index="index">
    <div class="action-template-container">
      <app-button *ngIf="showWorkingPageAssignmentGotoAction(rowData,index)" text="GO TO" (onClick)="goToWorkingDocumentPageClick(rowData,index)"></app-button>
      <app-button *ngIf="showWorkingPageAssignmentRemoveAction(rowData,index)" text="Remove" (onClick)="workingPageAssignmentRemoveClick(rowData,index)"></app-button>
      <app-button *ngIf="showChangeToIgnoreAction(rowData,index)" text="IGNORE" (onClick)="workingPageAssignmentChangeToIgnoreActionClick(rowData,index)"></app-button>
      <app-button *ngIf="showChangeToChaseAction(rowData,index)" text="CHASE" (onClick)="workingPageAssignmentChangeToChaseActionClick(rowData,index)"></app-button>

    </div>
  </ng-template>

</app-server-grid>

<app-button class="document-page-assignment__buttons--commit-chase text-right" text="COMMIT CHASE ASSIGNMENTS" [disabled]="!hasUncommitedWorkingPageAssignments" (onClick)="workingPageAssignmentSubmitAllClick()">
</app-button>

<div class="document-page-assignment__header">COMMITTED PAGES<app-tool-tip
  text="Committed Pages is where you can see all pages attached to Chases, Pends, and Invoices.">
</app-tool-tip></div>
<app-server-grid #gridPageAssignments [configuration]="pageAssignmentsServerGridConfiguration"
  [(request)]="pageAssignmentsServerRequest" [refresh]="refreshPageAssignmentGrid"
  [actionTemplate]="pageAssignmentsActionsTemplate" (dataLoaded)="pageAssignmentsGridDataLoaded($event)"
  [isLoadOnInit]="true">

  <ng-template #chaseIdColumn let-rowData let-index="index">
    <div (mouseenter)="projectNamePopover.show($event)" (mouseout)="projectNamePopover.hide()">
      {{rowData.chaseId}}
        <app-popover #projectNamePopover [appendTo]="body">
            <div class="popover-text">{{rowData.projectName}}</div>
        </app-popover>
    </div>
  </ng-template>
  <ng-template #pageRangeColumn let-rowData let-index="index">
    <a href="javascript:void(0)" (click)="goToPageClick(rowData, index)">({{rowData.startPage}} -
      {{rowData.endPage}})</a>
  </ng-template>
  <ng-template #pageAssignmentsActionsTemplate let-rowData let-index="index">
    <div class="action-template-container">
      <app-button *ngIf="showWorkingPageAssignmentGotoAction(rowData)" text="GO TO" (onClick)="goToPageClick(rowData,index)"></app-button>
      <app-button *ngIf="showPageAssignmentRemoveAction(rowData)" text="Remove" (onClick)="pageAssignmentRemoveClick(rowData,index)"></app-button>
      <app-button *ngIf="showViewInvoiceAction(rowData)" text="View Invoice" (onClick)="viewInvoiceClick(rowData)"></app-button>
     </div>
  </ng-template>

</app-server-grid>
<app-confirmation-modal #confirmationForInActiveChases [(visible)]="showConfirmationForInActiveChases"
  header="ATTENTION" acceptText="YES" rejectText="NO">
  {{confirmationTextForAttachingToInactiveChase}}
</app-confirmation-modal>
<app-confirmation-modal #confirmationForActiveChases [(visible)]="showConfirmationForActiveChases" header="ATTENTION"
  acceptText="CONTINUE" rejectText="NO">
  {{confirmationTextForAttachingToActiveChase}}

</app-confirmation-modal>
<app-modal #notAllowedModal [(visible)]="showNotAllowedModal" header="NOT ALLOWED">
  <div>{{attachChaseNotAllowedMsg}}</div>
</app-modal>
<app-confirmation-modal #confirmationForRemoveChase [(visible)]="showConfirmationForRemoveChase" header="ATTENTION"
                        acceptText="REMOVE" rejectText="NO"
                        (onYes)="removeCommittedPageAssignment()"
                        (onCancel)="cancelRemoveCommittedPageAssignment()"
                        >
  {{confirmationTextForRemoveChase}}

</app-confirmation-modal>
