<div class="contact-history-container">

  <h3>CONTACT ATTEMPT HISTORY</h3>
  
<div class="contact-history">
  <div>
    <div class="text-right">
      <app-button classes="transparent small"
                  (onClick)="showFilters()"
                  styleType="pi"
                  type="pi"
                  icon="filter">
      </app-button>
    </div>
    <div>
      <app-filter [formFilterRequest]="formFilterRequest"  (isShowFilterModal)="showFilters()" (removeFilter)="onRemoveFilter($event)" [isRemovableItem]="false" [totalRecords]="totalRecords"></app-filter>
    </div>
  </div>
  <div *ngFor="let item of contactHistoryGridData; trackBy: trackByIndex">
    {{item.specificDay}}
    <br>
    <app-address-detail-contact-history-item [items]="item.timelineGroup" (retryFax)="retryFax($event)"></app-address-detail-contact-history-item>
    <br>
  </div>
</div>
</div>

<app-modal class="contact-history__filter" [(visible)]="isFiltersVisible" header="History Query">
  <app-tab-menu orientation="left">
      <app-tab-panel header="Contact Date">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactDateInput"></form-factory>
          </div>
      </app-tab-panel>

      <app-tab-panel class="checkbox-group__halves" header="Contact Type">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="contactMethodTypeInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Result">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="callResultInput"></form-factory>
        </div>
      </app-tab-panel>
  </app-tab-menu>

  <footer>
    <app-button class="reset-filter-button" (onClick)="resetAllFilters()" text="Clear"></app-button>
    <app-button (onClick)="closeFilters()" text="Run Query"></app-button>
    <div class="error-message" *ngIf="showErrorMessage">{{errorMessage}}</div>
  </footer>

</app-modal>

