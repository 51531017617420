import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { ListItem } from "../../../../../../shared/list/list-item";
import { FtDetailInfoService } from "../ft-detail-info/ft-detail-info.service";
import { FtDetailAccessInfoService } from "./ft-detail-access-info.service";

@Component({
  selector: "retrieval-ft-detail-access-info",
  templateUrl: "./ft-detail-access-info.component.html",
  styleUrls: ["./ft-detail-access-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDetailAccessInfoComponent implements OnInit {

  summaryItems = List<ListItem>();
  retrievalId: number;
  subscription: Subscription;

  constructor(
    private service: FtDetailAccessInfoService,
    private ftDetailservice: FtDetailInfoService,
    private changeDetector: ChangeDetectorRef
  ) {

    this.retrievalId = this.service.getFTGdFromPath();
  }

  ngOnInit() {
    this.ftDetailservice
      .getSummaryItems()
      .subscribe(items => this.summaryItems = this.assignAndNotify(items));

    this.subscription = this.ftDetailservice.reset
      .subscribe(id => {
        this.updatesummary(id);
      });
  }

  updatesummary(id) {
    this.ftDetailservice
      .getSummaryItems()
      .subscribe(items => this.summaryItems = this.assignAndNotify(items));
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

}
