import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UserToken } from "../../auth/user-token.model";
import { BASE_API_URL } from "../../core/environment.tokens";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { UserService } from "../../core/user/user.service";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { PageTypeName } from "../../platform/modules/retrieval/retreival-document-review/page-type.enum";
import { DocumentAssignmentSummary } from "../../platform/modules/retrieval/retreival-document-review/retreival-document-intake/document-assignment-summary.model";
import { RetrievalDocumentServiceService } from "../../platform/modules/retrieval/retrieval-document-service.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { SourceDocumentType } from "../document/document-page-viewer/source-document-type.enum";
import { BulkAction } from "../grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { ServerGridComponent } from "../grid/server-grid/server-grid.component";
import { ConfirmationModalComponent } from "../panel/confirmation-modal/confirmation-modal.component";
import { ModalComponent } from "../panel/modal/modal.component";


@Component({
  selector: "app-document-page-assignment",
  templateUrl: "./document-page-assignment.component.html",
  styleUrls: ["./document-page-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageAssignmentComponent implements OnInit {

  @Input() documentId: number;
  @Input() sourceDocumentTypeId: number;
  @Output() onWorkingPageAssignmentsLoaded = new EventEmitter();
  @Output() onPageAssignmentsLoaded = new EventEmitter();
  @Output() onGotoPage = new EventEmitter();

  pageAssignments: DocumentAssignmentSummary[];
  pageAssignmentsServerGridConfiguration = new GridConfiguration();
  pageAssignmentsServerGridActions: BulkAction[] = [];
  pageAssignmentsServerRequest: GridRequest;
  pageAssignmentsServerGridSelection: any[];
  refreshPageAssignmentGrid = new EventEmitter<boolean>(true);
  pageAssignmentsActions: BulkAction[];

  workingPageAssignments: DocumentAssignmentSummary[];
  workingPageAssignmentsServerGridConfiguration = new GridConfiguration();
  workingPageAssignmentsServerGridActions: BulkAction[] = [];
  workingPageAssignmentsServerRequest: GridRequest;
  workingPageAssignmentsServerGridSelection: any[];
  refreshWorkingPageAssignmentGrid = new EventEmitter<boolean>(true);
  workingPageAssignmentsActions: BulkAction[];
  workingPageAssigmentsRowIndex: number;

  confirmationTextForAttachingToActiveChase: string;
  confirmationTextForAttachingToInactiveChase: string;
  confirmationTextForRemoveChase = "Are you sure you want to remove these pages? This can not be undone";

  chaseActive: boolean;
  selectedWorkingPageAssignmentRow = -1;
  selectedPageAssignmentRow = -1;

  showNotAllowedModal = false;
  showConfirmationForInActiveChases = false;
  showConfirmationForActiveChases = false;
  showConfirmationForRemoveChase = false;
  committedPageAssignmentToRemove: DocumentAssignmentSummary;
  isChildChasesCreated: boolean[];
  attachChaseNotAllowedMsg: string;
  isViewInvoicePermission = false;
  private user: UserToken;

  @ViewChild("gridWorkingPageAssignments", { static: true }) gridWorkingPageAssignments: ServerGridComponent;
  @ViewChild("gridPageAssignments", { static: true }) gridPageAssignments: ServerGridComponent;
  @ViewChild("workingChaseIdColumn", { static: true }) workingChaseIdColumn: TemplateRef<any>;
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<any>;
  @ViewChild("workingPageRangeColumn", { static: true }) workingPageRangeColumn: TemplateRef<any>;
  @ViewChild("pageRangeColumn", { static: true }) pageRangeColumn: TemplateRef<any>;
  @ViewChild("confirmationForInActiveChases", { static: true }) confirmationForInActiveChases: ConfirmationModalComponent;
  @ViewChild("confirmationForActiveChases", { static: true }) confirmationForActiveChases: ConfirmationModalComponent;
  @ViewChild("notAllowedModal", { static: true }) notAllowedModal: ModalComponent;
  @ViewChild("confirmationForRemoveChase", { static: true }) confirmationForRemoveChase: ConfirmationModalComponent;


  constructor(

    private changeDetector: ChangeDetectorRef,
    private retrievalDocumentService: RetrievalDocumentServiceService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private messagingService: MessagingService,
    public router: Router,
    private userService: UserService
   ) { }

  ngOnInit() {
     this.user = this.userService.getUserToken();
     this.isViewInvoicePermission = this.user.isViewInvoicePermission;
     this.setServerGridConfiguration();
     this.setServerGridRequest();
     this.setWorkingServerGridRequest();
     this.setWorkingServerGridConfiguration();
     this.changeDetector.markForCheck();
  }

  workingPageAssignmentSubmitAllClick() {
    this.workingPageAssignmentSubmitAll();

  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  async workingPageAssignmentSubmitAll(): Promise<void> {
    const groupedByChaseId = this.groupBy(this.gridWorkingPageAssignments.data.filter(r => r.pageTypeId === PageTypeName.MedicalRecord), assignment => assignment.chaseId);
    for (const [key, value] of groupedByChaseId.entries()) {
      const chaseId = key;
      await this.retrievalDocumentService.checkOverlappingChasePageRange(
        chaseId, value[0].documentId, value[0].startPage, value[0].endPage, value[0].pageTypeId, value[0].document2Id).toPromise().then(async result => {
          if (result) {
            await this.workingPageAssignmentSubmit(chaseId).then(response => {
            });
          }
        });
    }
  }

  viewInvoiceClick(rowData) {
    if (NumberHelper.isAvailable(rowData.invoiceId)) {
      this.router.navigate([`/invoices/detail/${rowData.invoiceId}/chase`]);
   } else {
    this.router.navigate([`/invoices/add/${rowData.chaseId}/chase`]);
   }
  }

  setPageActionRow(rowData, index) {
    if (this.selectedPageAssignmentRow >= 0 && this.selectedPageAssignmentRow < this.gridPageAssignments.data.length) {
      this.gridPageAssignments.data[this.selectedPageAssignmentRow].rowClass = "";
    }
    this.selectedPageAssignmentRow = index;
    rowData.rowClass = "highlight-row";
    this.gridPageAssignments.markForCheck();
  }

  setActionRow(rowData, index) {
    if (this.selectedWorkingPageAssignmentRow >= 0 && this.selectedWorkingPageAssignmentRow < this.gridWorkingPageAssignments.data.length) {
      this.gridWorkingPageAssignments.data[this.selectedWorkingPageAssignmentRow].rowClass = "";
    }
    this.selectedWorkingPageAssignmentRow = index;
    rowData.rowClass = "highlight-row";
    this.gridWorkingPageAssignments.markForCheck();
  }

  setErrorRow(rowData, index) {

    rowData.rowClass = "highlight-row";
    this.gridWorkingPageAssignments.markForCheck();
  }

  setQuestionRow(rowData, index) {

    rowData.rowClass = "highlight-row";
    this.gridWorkingPageAssignments.markForCheck();
  }

  setClearRow(rowData, index) {

    rowData.rowClass = null;
    this.gridWorkingPageAssignments.markForCheck();
  }

  workingPageAssignmentSubmit(chaseId: number): Promise<boolean> {

    // we only want to return after user answers confirms or methods completed
    // as this may be used in a loop and we want to use app-modal for each iteration and cant do without promise
    // because we are using single modal components
    return new Promise((promiseResponse, rej) => {

      this.retrievalDocumentService.validateChaseStatusForDocAttachment(chaseId)
        .subscribe((result: string) => {
          this.showNotAllowedModal = false;
          if (this.sourceDocumentTypeId === SourceDocumentType.Document) {
            this.confirmationTextForAttachingToActiveChase = `Data Entry has been completed for Chase: ${chaseId}. These pages will be attached to a child chase.`;
            this.confirmationTextForAttachingToInactiveChase = `Chase ${chaseId} is currently Inactive. By attaching this document, the chase will become Active. Are you sure you want to continue?`;
          } else if (this.sourceDocumentTypeId === SourceDocumentType.Chase) {
            this.confirmationTextForAttachingToActiveChase = `Data Entry has been completed for Chase: ${chaseId}. These pages will be attached to a child chase.`;
            this.confirmationTextForAttachingToInactiveChase = `Chase ${chaseId} is Inactive. Click continue to activate the chase and attach the document.`;
          }

          switch (result) {
            // inactive Chase actions
            case "inactiveNotAllowed":

              this.chaseActive = false;
              this.attachChaseNotAllowedMsg = `Chase Id: ${chaseId} is Inactive and attaching pages to Inactive chases is not allowed for this project`;
              this.changeDetector.markForCheck();
              this.notAllowedModal.onHide.subscribe(() => {
                promiseResponse(true);
              });
              this.showNotAllowedModal = true;

              break;

            case "inactiveAutoApprove":

              promiseResponse(true);
              this.submitWorkingDocumentPageRangesToEntity(chaseId);
              break;

            case "inactiveApprovalCenter":

              promiseResponse(true);
              this.submitWorkingDocumentPageRangesToEntity(chaseId);
              break;


            // inProgress Chase Actions
            case "inProgressNotAllowed":

              this.attachChaseNotAllowedMsg = `Chase Id: ${chaseId} is In-Progress and attaching pages to In-Progress chases is not allowed for this project`;
              this.changeDetector.markForCheck();
              this.notAllowedModal.onHide.subscribe(() => {
                promiseResponse(true);
              });

              this.showNotAllowedModal = true;
              break;

            case "inProgressAutoApprove":

              promiseResponse(true);
              this.submitWorkingDocumentPageRangesToEntity(chaseId);
              break;

            case "inProgressApprovalCenter":

              promiseResponse(true);
              this.submitWorkingDocumentPageRangesToEntity(chaseId);
              break;

            case "invalid":
            case "notAuthorized":

              this.attachChaseNotAllowedMsg = `Chase Id: ${chaseId} is not valid`;

              this.notAllowedModal.onHide.subscribe(() => {
                promiseResponse(true);
              });

              this.showNotAllowedModal = true;

              break;

            case "attachAlways":
            default:
              // return promise first so process does not hang if error later??
              promiseResponse(true);
              this.submitWorkingDocumentPageRangesToEntity(chaseId);


              break;
          }

        }); // end switch

    }); // end of promise

  }
  submitWorkingDocumentPageRangesToEntity(entityId) {

    // get all page Ranges for this entity that can be Committed
    const entityworkingPageAssignments = this.workingPageAssignments.filter(r => r.chaseId === entityId && (r.pageTypeId === PageTypeName.MedicalRecord || r.pageTypeId === PageTypeName.QA));

    // submit multiple page ranges to MT
    this.retrievalDocumentService.submitWorkingDocumentPageRangesToEntity(entityworkingPageAssignments, this.sourceDocumentTypeId)
      .subscribe((result: number) => {
        this.messagingService.showToast(`Chase: ${entityId} Page Assignment Successful`, SeverityType.SUCCESS);
        if (result > 0 && result !== entityId) {
          this.messagingService.showToast(`Child Chase(s) Created!`, SeverityType.SUCCESS);
        }
        this.reloadGrids();
      });

  }

  workingPageAssignmentChangeToIgnoreActionClick(sourcePageAssignment: DocumentAssignmentSummary, index) {

    this.setActionRow(sourcePageAssignment, index);
    this.workingPageAssignmentChangeToIgnore(sourcePageAssignment);

  }

  workingPageAssignmentChangeToIgnore(sourcePageAssignment: DocumentAssignmentSummary) {

    const targetPageAssignment = new DocumentAssignmentSummary(
      sourcePageAssignment.documentId,
      null,
      sourcePageAssignment.startPage,
      sourcePageAssignment.endPage,
      null,
      sourcePageAssignment.docType,
      PageTypeName[PageTypeName.Ignore],
      sourcePageAssignment.documentTypeId,
      sourcePageAssignment.documentAssignmentTypeId,
      PageTypeName.Ignore,
      null,
      sourcePageAssignment.workingDocumentId,
      sourcePageAssignment.document2Id,
      sourcePageAssignment.document2TypeId,
      sourcePageAssignment.removeable
    );


    this.retrievalDocumentService.changeWorkingDocumentPageAssignments(sourcePageAssignment, this.sourceDocumentTypeId, targetPageAssignment)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Working Assignment changed to Ignore", SeverityType.SUCCESS);
          this.reloadGrids();
        }

      });
  }

  workingPageAssignmentChangeToChaseActionClick(sourcePageAssignment: DocumentAssignmentSummary, index) {

    this.setActionRow(sourcePageAssignment, index);
    this.workingPageAssignmentChangeToChase(sourcePageAssignment);

  }

  workingPageAssignmentChangeToChase(sourcePageAssignment: DocumentAssignmentSummary) {

    const targetPageAssignment = new DocumentAssignmentSummary(
      sourcePageAssignment.documentId,
      sourcePageAssignment.chaseId,
      sourcePageAssignment.startPage,
      sourcePageAssignment.endPage,
      null,
      sourcePageAssignment.docType,
      PageTypeName[PageTypeName.MedicalRecord],
      sourcePageAssignment.documentTypeId,
      sourcePageAssignment.documentAssignmentTypeId,
      PageTypeName.MedicalRecord,
      null,
      sourcePageAssignment.workingDocumentId,
      sourcePageAssignment.document2Id,
      sourcePageAssignment.document2TypeId,
      sourcePageAssignment.removeable
    );

    this.retrievalDocumentService.changeWorkingDocumentPageAssignments(sourcePageAssignment, this.sourceDocumentTypeId, targetPageAssignment)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Working Pages changed to Chase", SeverityType.SUCCESS);
          this.reloadGrids();
        }

      });
  }

  workingPageAssignmentRemoveClick(rowData, index) {

    // this removes just one row of workingPageAssignments

    this.setActionRow(rowData, index);
    const workingPageAssignments = [];
    workingPageAssignments.push(rowData);

    this.workingPageAssignmentRemove(workingPageAssignments);
  }


  workingPageAssignmentRemove(workingPageAssignments) {

    // submit multiple page ranges to MT
    this.retrievalDocumentService.removeWorkingDocumentPageAssignments(workingPageAssignments, this.sourceDocumentTypeId)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Working Assignment Page Deletion Successful", SeverityType.SUCCESS);
          this.reloadGrids();
        }

      });
  }

  showPageAssignmentRemoveAction(rowData: DocumentAssignmentSummary): boolean {
    return this.sourceDocumentTypeId === SourceDocumentType.Document && rowData.pageTypeId === PageTypeName.MedicalRecord;
  }
  showViewInvoiceAction(rowData: DocumentAssignmentSummary): boolean {
    return this.isViewInvoicePermission  && rowData.pageTypeId === PageTypeName.Invoice;
  }
  pageAssignmentRemoveClick(rowData: DocumentAssignmentSummary) {

    this.committedPageAssignmentToRemove = rowData;
    this.showConfirmationForRemoveChase = true;
  }

  removeCommittedPageAssignment() {

    this.pageAssignmentRemove(this.committedPageAssignmentToRemove);
  }

  cancelRemoveCommittedPageAssignment() {

    this.committedPageAssignmentToRemove = null;
  }

  pageAssignmentRemove(rowData: DocumentAssignmentSummary): void {

    this.retrievalDocumentService.deletePagesToEntity("chase"
      ,                                               String(rowData.chaseId)
      ,                                               this.sourceDocumentTypeId === SourceDocumentType.Document ? this.documentId : null
      ,                                               rowData.documentTypeId
      ,                                               rowData.startPage
      ,                                               rowData.endPage
      ,                                               this.sourceDocumentTypeId)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Chase Page Deletion Successful", SeverityType.SUCCESS);
          this.reloadGrids();
        }
      });
    this.committedPageAssignmentToRemove = null;
  }


  goToWorkingDocumentPageClick(rowData, index) {

    // UX team Only want one goto row highlighter accross both page assignment grids
    if (this.selectedPageAssignmentRow >= 0 && this.selectedPageAssignmentRow < this.gridPageAssignments.data.length) {
      this.gridPageAssignments.data[this.selectedPageAssignmentRow].rowClass = "";
      this.gridPageAssignments.markForCheck();
    }

    this.setActionRow(rowData, index);
    this.onGotoPage.emit(rowData.startPage);
  }

  goToPageClick(rowData, index) {
    // UX Only want one goto row highlighter accross both page assignment grids
    if (this.selectedWorkingPageAssignmentRow >= 0 && this.selectedWorkingPageAssignmentRow < this.gridWorkingPageAssignments.data.length) {
      this.gridWorkingPageAssignments.data[this.selectedWorkingPageAssignmentRow].rowClass = "";
      this.gridWorkingPageAssignments.markForCheck();
    }
    this.setPageActionRow(rowData, index);
    this.onGotoPage.emit(rowData.startPage);
  }

  displayWorkingChaseId(chaseId: number, rowIndex: number): boolean {

    if (chaseId === 0) {
      return false;
    }

    return true;
  }
  showChangeToChaseAction(rowData, index): boolean {

    return rowData.pageTypeId === PageTypeName.QA;
  }

  showChangeToIgnoreAction(rowData, index): boolean {

    return rowData.pageTypeId === PageTypeName.QA || rowData.pageTypeId === PageTypeName.QAInvoice;
  }

  showWorkingPageAssignmentRemoveAction(rowData, index): boolean {

    return rowData.pageTypeId !== PageTypeName.Unassigned && rowData.pageTypeId !== PageTypeName.Invoice && rowData.pageTypeId !== PageTypeName.Approval;
  }

  showWorkingPageAssignmentGotoAction(rowData, index): boolean {

    return true;
  }
  workingPageAssignmentEditClick(rowData, index) {
    this.selectedWorkingPageAssignmentRow = index;

  }

  private setWorkingServerGridConfiguration(): void {

    this.workingPageAssignmentsServerGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({
          field: "documentId", header: "Chase ID", showTitle: false, template: this.workingChaseIdColumn, width: "20%",
        }),
        new GridColumnDefinition({
          field: "pageTypeDisplayName", header: "Page Type", width: "25%",
          styles: (value: number, rowData: any) => {

            const color = (rowData.pageTypeId === PageTypeName.Unassigned || rowData.pageTypeId === PageTypeName.QAInvoice || rowData.pageTypeId === PageTypeName.QA) ? "red" : "";
            return { color };

          },
        }),
        new GridColumnDefinition({ field: "startPage", header: "Page Range", template: this.workingPageRangeColumn, width: "20%" }),
      ],
      selectionMode: "none",
      hasPagination: false,
      showExportAction: false,
      showMenu: false,
    });

    this.workingPageAssignmentsActions = this.workingPageAssignmentsServerGridActions;
  }

  private setServerGridConfiguration(): void {

    this.pageAssignmentsServerGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", showTitle: false, template: this.chaseIdColumn, width: "20%" }),
        new GridColumnDefinition({ field: "pageType", header: "Page Type", width: "30%" }),
        new GridColumnDefinition({
          field: "startPage", header: "Page Range", template: this.pageRangeColumn, width: "25%",
        }),
        new GridColumnDefinition({ field: "result", header: "", show: false }),
      ],
      selectionMode: "none",
      hasPagination: false,
      showExportAction: false,
      showMenu: false,
    });

    this.pageAssignmentsActions = this.pageAssignmentsServerGridActions;
  }

  private setWorkingServerGridRequest(): void {

    this.workingPageAssignmentsServerRequest = new GridRequest({
      url: `${this.baseApiUrl}document/page/assignments`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "documentId",
          show: false,
          value: this.documentId.toString(),
        }),
        new GridFilter({
          input: new Textbox(),
          key: "sourceDocumentTypeId",
          show: false,
          value: this.sourceDocumentTypeId.toString(),
        }),
        new GridFilter({
          input: new Textbox(),
          key: "documentAssignmentTypeId",
          show: false,
          value: "2",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "workingDocumentPageStatusId",
          show: false,
          value: this.sourceDocumentTypeId === SourceDocumentType.Chase ? "1" : null,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "workingPageStatusIdList",
          show: false,
          value: this.sourceDocumentTypeId === SourceDocumentType.Document ? "1,3" : null,
        }),
      ],
      sortField: "startPage",

    });

    this.refreshWorkingPageAssignmentGrid.emit();
  }

  private setServerGridRequest(): void {

    this.pageAssignmentsServerRequest = new GridRequest({
      url: `${this.baseApiUrl}document/page/assignments`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "documentId",
          show: false,
          value: this.documentId.toString(),
        }),
        new GridFilter({
          input: new Textbox(),
          key: "sourceDocumentTypeId",
          show: false,
          value: this.sourceDocumentTypeId.toString(),
        }),
        new GridFilter({
          input: new Textbox(),
          key: "documentAssignmentTypeId",
          show: false,
          value: "1",
        }),
      ],
      sortField: "startPage",
    });

    this.refreshPageAssignmentGrid.emit();
  }

  refresh() {

    this.reloadGrids();
  }

  private reloadGrids() {

    this.setServerGridRequest();
    this.setWorkingServerGridRequest();
    this.changeDetector.markForCheck();

  }

  workingPageAssignmentsGridDataLoaded(dataRows) {
    this.workingPageAssignments = dataRows;
  }

  pageAssignmentsGridDataLoaded(dataRows) {

    this.pageAssignments = dataRows;
    this.onPageAssignmentsLoaded.emit(dataRows);
  }

  // get any Unassigned pages. An unassigned page is any row in workingPageAssignements except pageType = "ignore"
  // because the Ignore page type is considered assigned or identified.
  getUnassignedPages(): DocumentAssignmentSummary[] {

    if (!ArrayHelper.isAvailable(this.workingPageAssignments)) {

      return [];
    }

    return this.workingPageAssignments.filter(f => f.pageType.toLowerCase() !== "ignore" && f.pageType.toLowerCase() !== "invoice" && f.pageType.toLowerCase() !== "approval");
  }

  get hasUncommitedWorkingPageAssignments(): boolean {

    if (!ArrayHelper.isAvailable(this.workingPageAssignments)) {

      return false;
    }
    return this.workingPageAssignments.filter(f => f.pageTypeId === PageTypeName.MedicalRecord).length > 0;
  }

}
