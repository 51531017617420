import { NgModule } from "@angular/core";
import { TooltipModule } from "primeng/tooltip";
import { IconModule } from "../icon/icon.module";
import { ToolTipComponent } from "./tool-tip.component";

@NgModule({
  imports: [
    IconModule,
    TooltipModule,
  ],
  declarations: [
    ToolTipComponent,
  ],
  exports: [
    ToolTipComponent,
  ],
})
export class ToolTipModule { }
