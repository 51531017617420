import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { GridPipeName } from "../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { GridRequest } from "../../../../../../shared/grid/models/grid-request.model";
import { DateFormats } from "../../../../../../utilities/contracts/helper-types";

@Component({
  selector: "app-bulk-outreach-pending-actions",
  templateUrl: "./bulk-outreach-pending-actions.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOutreachPendingActionsComponent implements OnInit {
  serverGridConfiguration = new GridConfiguration();
  serverRequest: GridRequest;

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) { }

  ngOnInit() {
    this.creategrid();
  }

  creategrid() {
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "bulkOutreachId", header: "JOB ID" }),
      new GridColumnDefinition({ field: "projectCount", header: "PROJECTS" }),
      new GridColumnDefinition({ field: "addressCount", header: "AIDs" }),
      new GridColumnDefinition({ field: "chaseCount", header: "CHASES" }),
      new GridColumnDefinition({ field: "bulkOutreachType", header: "TYPE" }),
      new GridColumnDefinition({ field: "senderName", header: "SENDER" }),
      new GridColumnDefinition({ field: "fromFaxNumber", header: "FROM" }),
      new GridColumnDefinition({ field: "status", header: "STATUS" }),
      new GridColumnDefinition({ field: "createDateTime", header: "REQUESTED", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
    ];
    this.serverGridConfiguration.stateName = "bulk_outreach_pending_actions";
    this.serverGridConfiguration.pageSize = 10;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}bulkoutreach/request/pending`,
    });
  }

}
