import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { first, map } from "rxjs/operators";
import { AuthService } from "../../auth/auth.service";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { MessagingService } from "../../core/messaging/messaging.service";
import { FormService } from "../../dynamic-forms/form.service";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../dynamic-forms/inputs/textarea/textarea.model";
import { TextboxType } from "../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { PendCategories } from "../../platform/modules/service-org-admin/service-org-config/model/pend-categories.enum";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { CreatePendService } from "./create-pend.service";

@Directive()
export abstract class CreateEditPendDirective implements OnInit {
  originalPendCodeOptions: SelectableInput[] = [];
  private pClinical = null;
  @Input()
  get clinical(): boolean {
    return this.pClinical;
  }
  set clinical(value: boolean) {
    this.pClinical = value;
  }

  private pMixChases = false;
  @Input()
  get hasClinicalNonClinicalChases(): boolean {
    return this.pMixChases;
  }
  set hasClinicalNonClinicalChases(value: boolean) {
    this.pMixChases = value;
    this.setPendCodesInputOptions();
  }

  private pPageType = "";
  @Input()
  get pageType(): string {
    return this.pPageType;
  }
  set pageType(value: string) {
    this.pPageType = value;
    this.setPendCodesInputOptions();
  }

  private pChasesStatus = [];
  @Input()
  get selectedChasesStatus(): any[] {
    return this.pChasesStatus;
  }
  set selectedChasesStatus(value: any[]) {
    if (value) {
      this.getPendCodes();
    }
    this.pChasesStatus = value;
    this.setPendCodesInputOptions();
  }
  @Input() chasePendIds = [];
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onShow = new EventEmitter<null>(true);
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);

  form: FormGroup;
  pendCodesInput = new Dropdown({
    key: "pendTypeId",
    label: "Pend Code",
    placeholder: "Select...",
    validators: [Validators.required],
    errorMessages: {
      required: "Pend Code is required.",
    },
    appendTo: "body",
  });
  statusInput = new Dropdown({
    key: "pendStatusId",
    label: "Status",
    placeholder: "Select...",
    appendTo: "body",
  });
  ownerInput = new Dropdown({
    key: "owner",
    label: "Owner",
    validators: [Validators.required],
    errorMessages: {
      required: "Owner is required.",
    },
    appendTo: "body",
  });

  pendReasonInput = new Dropdown({
    key: "pendReasonId",
    label: "Reason",
    placeholder: "Select from List",
    disabled: true,
    hidden: true,
    appendTo: "body",
  });

  companyInput = new Dropdown({
    key: "pendCompanyId",
    label: "Company",
    placeholder: "Select...",
    disabled: true,
    hidden: true,
    validators: [Validators.required],
    errorMessages: {
      required: "Company is required.",
    },
    appendTo: "body",
  });
  amountInput = new Textbox({
    key: "amount",
    label: "Amount",
    type: TextboxType.NUMBER,
    disabled: true,
    hidden: true,
    validators: [
      Validators.min(0),
      Validators.max(10000),
      Validators.pattern(/^\d*(\.\d{0,2})?$/), // NOTE: https://stackoverflow.com/a/46203928/2573621
    ],
    errorMessages: {
      required: "Enter number between 0 - 10000.",
      min: "Enter number between 0 - 10000.",
      max: "Enter number between 0 - 10000.",
      pattern: "Enter a dollar amount between 0 - 10000.",
    },
    placeholder: "0.00",
  });
  invoiceInput = new Textbox({
    key: "invoiceNumber",
    label: "Invoice Number",
    disabled: true,
    hidden: true,
    validators: [
      Validators.maxLength(10),
    ],
    errorMessages: {
      maxlength: "Invoice number can not be more than 10 characters.",
    },
  });
  assignedToInput = new Dropdown({
    key: "assignedToUserId",
    label: "Assigned To",
    placeholder: "Select...",
    appendTo: "body",
  });
  noteInput = new Textarea({
    key: "notes",
    label: "Additional Notes",
    placeholder: "Type Here",
    rows: 4,
    resize: Resize.VERTICAL,
    validators: [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(1000),
    ],
    errorMessages: {
      required: "Write a note between 4 - 1000 characters.",
      minlength: "Write a note between 4 - 1000 characters.",
      maxlength: "Write a note between 4 - 1000 characters.",
    },
  });
  severityInput = new Dropdown({
    key: "pendSeverityId",
    label: "Severity",
    placeholder: "Select...",
    validators: [Validators.required],
    errorMessages: {
      required: "Severity is required.",
    },
    appendTo: "body",
  });

  constructor(
    protected readonly formService: FormService,
    protected readonly automapper: AutomapperService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly messagingService: MessagingService,
    protected readonly createPendService: CreatePendService,
    protected readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.onShow
      .pipe(first())
      .subscribe(() => {
        this.getCompanies();
        this.getPendCodes();
      });
    this.getOwner();

    this.createForm();
  }

  getOwner(): void {
    const organizationName = this.authService.user.organizationName;
    const options = [
      new SelectableInput({ text: organizationName, value: "O" }),
      new SelectableInput({ text: "Customer", value: "C" }),
    ];
    this.ownerInput = new Dropdown({ ...this.ownerInput, options } as any);
  }

  get hasAllOptions(): boolean {
    const hasPendCodes = ArrayHelper.isAvailable(this.pendCodesInput.options);
    const hasCompanies = ArrayHelper.isAvailable(this.companyInput.options);
    return hasPendCodes && hasCompanies;
  }

  get filteredPendCodeOptions(): SelectableInput[] {
    let pendCodes = this.originalPendCodeOptions;
    const autoClosePendBasedOnCategory = pendCodes.filter(pend => pend.extra.pendTypeCategoryId === 2);
    if (this.clinical && (this.pPageType === "or1" || this.pPageType === "clientoverread" || this.pPageType === "mrr")) {
      const clinicalPendCodes = [];
      pendCodes.forEach(x => {
        const pendText = x.text.split("-");
        if (ArrayHelper.isAvailable(pendText) && this.pendCodeToMatch.filter(pend => pend === pendText[0]).length > 0) {
          clinicalPendCodes.push(x.text);
        }
      });
      pendCodes = pendCodes.filter(pendType => clinicalPendCodes.includes(pendType.text));

      this.originalPendCodeOptions.forEach(element => {
        if (element.extra.pendTypeCategoryId === PendCategories.CUSTOM_CREATED_CODES) {
          pendCodes.push(element);
        }
      });

    } else if (this.hasClinicalNonClinicalChases) {
      pendCodes = autoClosePendBasedOnCategory;
    } else if (this.checkChasesStatus) {
      const filteredPendCodeByChaseStatus = [];
      pendCodes.forEach(x => {
        const pendText = x.text.split("-");
        if (ArrayHelper.isAvailable(pendText) && this.pendCodeWithChaseStatus.filter(pend => pend === pendText[0]).length > 0) {
          filteredPendCodeByChaseStatus.push(x.text);
        }
      });
      pendCodes = pendCodes.filter(pendType => filteredPendCodeByChaseStatus.includes(pendType.text));
      this.originalPendCodeOptions.forEach(element => {
        if (element.extra.pendTypeCategoryId === PendCategories.CUSTOM_CREATED_CODES) {
          pendCodes.push(element);
        }
      });
    }

    if (!ArrayHelper.isAvailable(this.chasePendIds)) {
      const autoPend = pendCodes.filter(x => x.text.split("-")[0].indexOf("PC900") === -1);
      pendCodes = autoPend.filter(code => !code.extra.isThirdParty);
    }
    return pendCodes;
  }

  get checkChasesStatus(): boolean {
    if (ArrayHelper.isAvailable(this.selectedChasesStatus)) {
      if (this.selectedChasesStatus.indexOf(5) > -1 || this.selectedChasesStatus.indexOf(6) > -1 || this.selectedChasesStatus.indexOf(7) > -1) {
        return true;
      }
    }
    return false;
  }

  abstract save(): void;
  abstract setInputs(): void;

  close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit();
    this.resetForm();
    this.createForm();
  }

  open(): void {
    this.visible = true;
    this.visibleChange.emit(true);
    this.onShow.emit();
  }

  protected createForm(): void {
    this.form = this.formService.createFormGroup([
      this.pendCodesInput,
      this.statusInput,
      this.ownerInput,
      this.companyInput,
      this.amountInput,
      this.invoiceInput,
      this.assignedToInput,
      this.noteInput,
      this.severityInput,
      this.pendReasonInput,
    ]);
  }

  private setPendCodesInputOptions(): void {
    this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, options: this.filteredPendCodeOptions } as any);
  }

  protected getPendCodes(): void {
    this.createPendService.getPendDropdown(this.clinical, null)
      .pipe(map((pendDropdowns: any) => {
        const pends = pendDropdowns.pendCodes.map(this.automapper.curry("PendCode", "SelectableInput"));
        const statuses = pendDropdowns.pendStatus.map(this.automapper.curry("PendStatus", "SelectableInput"));
        const severity = pendDropdowns.pendSeverity.map(this.automapper.curry("PendSeverity", "SelectableInput"));
        return { pends, statuses, severity };
      }))
      .subscribe(({ pends, statuses, severity }: any) => {
        this.originalPendCodeOptions = pends;
        this.setPendCodesInputOptions();
        if (this.clinical) {
          statuses.splice(-1, 1);
        }
        this.statusInput = new Dropdown({
          ...this.statusInput,
          options: statuses,
          errorMessages: {
            ...this.statusInput.errorMessages,
            enabledoptionsonly: 'Users cannot select "New"',
          },
        } as any);
        this.severityInput = new Dropdown({ ...this.severityInput, options: severity } as any);
        this.form.get(this.statusInput.key).setValidators(this.statusInput.validators);
        this.setInputs();
        this.changeDetector.markForCheck();
      });
  }

  protected getCompanies(): void {
    this.createPendService.getPendCompaniesDropdown()
      .pipe(map(data => data.map(item => new SelectableInput({ text: item.value, value: Number(item.key) }))))
      .subscribe(data => {
        this.companyInput = new Dropdown({ ...this.companyInput, options: data } as any);
        this.setInputs();
      });
  }

  protected markAllAsTouched(): void {
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  protected resetForm(): void {
    this.pendCodesInput = new Dropdown({ ...this.pendCodesInput, disabled: true, hidden: false } as any);
    this.statusInput = new Dropdown({ ...this.statusInput, disabled: true, hidden: false, value: 1 } as any);
    this.ownerInput = new Dropdown({ ...this.ownerInput, disabled: false, hidden: false, value: "O" } as any);
    this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: true, disabled: true } as any);
    this.companyInput = new Dropdown({ ...this.companyInput, disabled: true, hidden: true } as any);
    this.amountInput = new Textbox({ ...this.amountInput, disabled: true, hidden: true } as any);
    this.invoiceInput = new Textbox({ ...this.invoiceInput, disabled: true, hidden: true } as any);
    this.assignedToInput = new Dropdown({ ...this.assignedToInput, disabled: true, hidden: true } as any);
    this.noteInput = new Textarea({ ...this.noteInput, disabled: false, hidden: false } as any);
    this.severityInput = new Dropdown({ ...this.severityInput, disabled: !this.clinical, hidden: !this.clinical } as any);
    this.changeDetector.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }

  get pendCodeToMatch(): string[] {
    const pendCodes: string[] = [];
    switch (this.pPageType) {
      case "mrr":
        pendCodes.push("PC302", "PC303", "PC304");
        break;
      case "or1":
        pendCodes.push("PC301", "PC303", "PC304");
        break;
      case "clientoverread":
        pendCodes.push("PC300", "PC303");
        break;
      default:
        break;
    }
    return pendCodes;
  }

  get pendCodeWithChaseStatus(): string[] {
    const pendCodes: string[] = [];
    if (this.selectedChasesStatus.indexOf(5) > -1) {
      pendCodes.push("PC302", "PC303", "PC304");
    } else if (this.selectedChasesStatus.indexOf(6) > -1) {
      pendCodes.push("PC301", "PC303",  "PC304");
    } else if (this.selectedChasesStatus.indexOf(7) > -1) {
      pendCodes.push("PC300", "PC303");
    }
    return pendCodes;
  }
}
