import { IAutoMapper } from "../interfaces";

export const mapPendsAttemptHistoryItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PendsAttemptHistory")
    .forMember("userName", o => o.userName)
    .forMember("callStartTime", o => o.callStartTime)
    .forMember("contactDetail", o => o.contactDetail)
    .forMember("callResult", o => o.callResult)
    .forMember("contactMethodType", o => o.contactMethodType)
    .forMember("callDuration", o => o.callDuration)
    .forMember("callNote", o => o.callNote)
    .forMember("callNoteDescription", o => o.callNoteDescription);


};
