export class DataSet {
  readonly data: string[][];
  readonly labels: string[];
  readonly urls: string[];
  readonly noOfChases: number[];
  readonly ids?: any;

  constructor(options: {
    data: string[][];
    labels: string[];
    urls: string[];
    noOfChases: number[];
    ids?: any;
  }) {
    this.data = options.data;
    this.labels = options.labels;
    this.urls = options.urls;
    this.noOfChases = options.noOfChases;
    this.ids = options.ids;
  }
}
