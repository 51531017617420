import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";

@Component({
  selector: "app-address-detail-info-timeline",
  templateUrl: "./address-detail-info-timeline.component.html",
  styleUrls: ["./address-detail-info-timeline.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDetailInfoTimelineComponent implements OnInit, OnChanges, OnDestroy {
  @Input() addressId: number;
  private sink = new SubSink();
  timelineItems = List<TimelineItem>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private addressTimelineService: AddressTimelineService,
    private addressTimelineStateService: AddressTimelineStateService
  ) { }

  ngOnInit() {
    this.loadTimelineInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.addressId.firstChange) {
      this.addressId = changes.addressId.currentValue;
      this.loadTimelineInfo();
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  loadTimelineInfo(): void {
    this.fetchTimelineItems(this.addressId);
    this.sink.add(
      this.addressTimelineStateService.timelineItems
        .subscribe(timelineItems => {
        if (ArrayHelper.isAvailable(timelineItems)) {
          this.timelineItems = List(timelineItems.slice(0, 5));
          this.changeDetector.markForCheck();
        }
      })
    );
  }

  showTimelineDetails() {
    return `retrieval/addressdetail/${this.addressId}/timeline`;
  }

  private fetchTimelineItems(addressId: number): void {
    this.sink.add(
      this.addressTimelineService
        .get(addressId)
        .subscribe(items => this.addressTimelineStateService.timelineItems.next(items))
    );
  }
}
