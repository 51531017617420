import { List } from "immutable";
import { TimelineItem } from "../../../shared/timeline/timeline-item.model";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class PendTimelineItem {
  readonly specificDay: string;
  readonly timelineGroup: List<TimelineItem>;

  constructor(options: {
    specificDay: string;
    timelineGroup: List<TimelineItem>;
  }) {
    this.specificDay = StringHelper.clean(options.specificDay);
    this.timelineGroup = options.timelineGroup;
  }
}

