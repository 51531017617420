import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { FileDownloadRequest } from "../../../project/project-files/file-download-request.model";
import { ProjectFileService } from "../../../project/project-files/project-file.service";
import { InternalPendsDetailDocumentsService } from "../internal-pends-detail-documents/internal-pends-detail-documents.service";
import { PendDocumentItem } from "../internal-pends-detail-documents/pend-document-list-item.model";

@Component({
  selector: "app-internal-pends-detail-documents",
  templateUrl: "./internal-pends-detail-documents.component.html",
  styleUrls: ["./internal-pends-detail-documents.component.scss"],
  providers: [InternalPendsDetailDocumentsService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class InternalPendsDetailDocumentsComponent implements OnInit {
  pendDocumentItems = List<PendDocumentItem>();
  display = false;
  documentTypes: any[];
  isUploadDocumentView = false;
  @Output() formClose = new EventEmitter();
  chasePendId: number;
  subscription: Subscription;

  constructor(
    private service: InternalPendsDetailDocumentsService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly filesService: ProjectFileService
  ) {
    this.chasePendId = this.route.snapshot.parent.params.pendGd;
  }

  ngOnInit() {
    this.getPendDocuments();
    this.subscription = this.service.refresh.subscribe(id => {
      this.getPendDocuments();
    });

  }

  showDialog() {
    this.isUploadDocumentView = true;
  }

  reUploadDocument($event) {
    this.isUploadDocumentView = $event;
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  trackByIndex(index) {
    return index;
  }

  getPendDocuments(): void {
    this.service
      .getPendDocuments(this.chasePendId)
      .subscribe(items => {
        this.pendDocumentItems = this.assignAndNotify(items);
      });
  }

  downloadDocument(item: PendDocumentItem): void {
    const request = new FileDownloadRequest();
    request.fileId = item.documentKey;
    request.fileType = "pendFiles";

    this.filesService.downloadFile(request).subscribe(result =>  {
      this.filesService.printFile(result, item.documentKey);
    });
  }

}
