import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {

  @Input() rows: any;
  @Input() totalRecords: any;
  @Output() onPageChange = new EventEmitter<any>();

}
