import { GridView } from "../grid-menu/grid-views/grid-view.model";
import { BasicGridState } from "./basic-grid-state-model";
import { GridColumnDefinition } from "./grid-column-definition.model";
import { GridConfiguration } from "./grid-configuration.model";
import { GridFilter } from "./grid-filter.model";
import { GridRequest } from "./grid-request.model";

export class GridState {
  configuration: GridConfiguration;
  request: GridRequest;
  basicGridState?: BasicGridState;
  selectedView: GridView;


  constructor(a: Partial<GridState> = {}) {
    this.configuration = new GridConfiguration({
      ...a.configuration,
      columns: a.configuration.columns.map(({ template, ...item }) => new GridColumnDefinition(item)),
    });
    this.basicGridState = new BasicGridState();
    this.request = null;
    if (a.request != null) {
      const filters = a.request.filters.map(filter => {
        const newFilter = new GridFilter({
          input: filter.input,
          key: filter.key,
          value: filter.inputValue || filter.value,
          name: (filter as any).name,
          show: filter.show,
          forceFilter: filter.forceFilter,
        } as any);
        newFilter.setForm(a.request.form);
        return newFilter;
      });
      this.request = new GridRequest(a.request);
      this.request.filters = filters;
    }

    if (a.selectedView != null) {
      this.selectedView = new GridView(a.selectedView);
    }
    if (a.basicGridState != null) {
      this.basicGridState = a.basicGridState;
    }
  }


  get hasRequest(): boolean {
    return this.request != null;
  }

  getObjectForJson(): any {
    let request = null;
    if (this.request != null) {
      const filters = this.request.filters.map(filter => ({
        input: filter.input,
        key: filter.key,
        value: filter.inputValue,
        name: (filter as any).name,
        show: filter.show,
        forceFilter: filter.forceFilter,
      }));
      request = {
        ...this.request,
        form: null,
        filters,
      } as any;
    }

    return {
      configuration: { ...this.configuration },
      request,
      basicGridState: {...this.basicGridState},
      selectedView: { ...this.selectedView },
    };
  }
}
