import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { interceptorskipheader } from "../../core/interceptor/loader.interceptor";
import { AlertNotification } from "./alert-notification.model";
import { NotificationItem } from "./notification-item.model";


@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private eventCallback = new Subject<any>(); // Source
  eventCallback$ = this.eventCallback.asObservable(); // Stream

  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private http: HttpClient,
              private readonly automapper: AutomapperService) { }

  getNotification(): Observable<AlertNotification[]> {
    const url = `${this.baseApiUrl}alert/recent`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "AlertNotification"))
    );
  }

  ReadNotification(): Observable<boolean> {

    const url = `${this.baseApiUrl}alert/update`;
    return this.http.get(url, { observe: "response" }).pipe(map((response: HttpResponse<any>): boolean => response.ok ? response.body : 0));
  }


  getAllNotification(startRecord?: number, endRecord?: number): Observable<NotificationItem[]> {

    const url = `${this.baseApiUrl}alert/list?startRecord=${startRecord}&endRecord=${endRecord}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "NotificationItem"))
    );
  }


  getNotificationCount(): Observable<number> {

    const url = `${this.baseApiUrl}alert/count`;
    const headers = new HttpHeaders().set(interceptorskipheader, "");

    return this.http
      .get(url, { observe: "response", headers })
      .pipe(
        map((response: HttpResponse<any>): number =>
          response.ok ? response.body : 0));
  }

  refreshNotifications() {
    const url = `${this.baseApiUrl}alert/count`;
    const headers = new HttpHeaders().set(interceptorskipheader, "");

    this.http
      .get(url, { observe: "response", headers }).subscribe(async data => {
        this.eventCallback.next(data.body);
      });
  }
}
