import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AddressesQueueService } from "../../../retrieval/addresses-queue/addresses-queue.service";
import { AddressBulkUpdate } from "../address-bulk-update.model";
import { BulkUpdateChaseService } from "../bulk-update-chase/bulk-update-chase.service";
import { BulkUpdatesService } from "../bulk-updates.service";
import { BasicGridComponent } from "./../../../../../shared/grid/basic-grid/basic-grid.component";
import { BulkUpdatesComponent } from "./../bulk-updates.component";
import { AddressBulkUpdateActionType } from "./address-bulk-update-action-type.enum";
@Component({
  selector: "app-bulk-update-address",
  templateUrl: "./bulk-update-address.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateAddressComponent implements OnInit, OnChanges {
  gridRefresh = new EventEmitter<null>();
  addressNotes: Textarea;
  formGroup: FormGroup;
  data: any;
  serverGridConfiguration = new GridConfiguration();
  serverRequest = new GridRequest();
  bulkUpdate: AddressBulkUpdate;

  @Output() isProcessingAddressIds = new EventEmitter<boolean>();
  @Input() actionControls: string[] = [];
  @Output() isValidated = new EventEmitter<boolean>();
  @Input() addressbulkUpdateData: AddressBulkUpdate;
  isShowValidateGridData: boolean;
  assignToUsers: Autocomplete;
  @ViewChild(BasicGridComponent) private basicGridComponent: BasicGridComponent;
  isShowAssignUser = false;

  @Input() isAddressAssignUser = false;

  ngOnChanges() {
    this.isShowAssignUser = this.isAddressAssignUser;
  }

  constructor(
    private readonly formService: FormService,
    private bulkUpdateChaseService: BulkUpdateChaseService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private addressesQueueService: AddressesQueueService,
    private bulkUpdatesService: BulkUpdatesService,
    private bulkUpdatesComponent: BulkUpdatesComponent
  ) { }

  ngOnInit() {
    if (this.bulkUpdatesComponent.selectedBulkUpdateActions === AddressBulkUpdateActionType.AssignAddresses) {
      this.validateAddressesAndGetUsers();
      this.initializeFormGroupControls();
      this.loadUserList();
      this.createAddressForm();
      this.isShowAssignUser = true;
    } else {
      this.initializeFormGroupControls();
      this.createAdressFormWithoutAssignUser();
    }
  }

  loadUserList(): void {
    this.assignToUsers = new Autocomplete({
      key: "assignToUsers",
      label: "Assign to User",
      filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    });
  }
  initializeFormGroupControls(): void {
    this.addressNotes = new Textarea({
      key: "addressNotes",
      label: "Note",
      placeholder: "Write a note...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });
  }


  validateToShowAddressGrid() {
    this.bulkUpdatesComponent.validateContinue();
    const notes = this.formGroup.value?.addressNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.addressNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.addressNotes.key).setErrors(null);
    }

    if (this.formGroup.invalid) {
      this.formService.markAllAsTouched(this.formGroup);
      return;
    }
    if (this.isChangeRetrievalTypeSelected && !this.bulkUpdatesComponent.formGroup.controls.changeRetrievalTypeInput?.value) {
      this.bulkUpdatesComponent.isShowControls = true;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isShowValidateGridData = false;
      return;
    }
    if (this.isThirdPartyTypeSelected && !this.bulkUpdatesComponent.formGroup.controls.assignThirdPartyVendorInput?.value) {
      this.bulkUpdatesComponent.isShowControls = true;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isShowValidateGridData = false;
      return;
    }
    if (this.bulkUpdatesComponent.formGroup.invalid || this.bulkUpdatesComponent.hasValidationError) {
      this.bulkUpdatesComponent.isShowControls = true;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isShowValidateGridData = false;
      return;
    } else {
      this.bulkUpdatesComponent.isShowEditBulk = false;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isShowValidateGridData = true;
    }
    this.getValidatedAndProcessAddressIds();
    this.changeDetector.markForCheck();
  }

  getValidatedAndProcessAddressIds(): void {
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "ProjectId",
        value: String(this.bulkUpdatesComponent.addressbulkUpdateData.projectId),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AddressIdCsv",
        value: this.bulkUpdatesComponent.addressbulkUpdateData.addressIds.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.addressNote,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AssignToUserId",
        value: this.isAssignAddressesActionTypeSelected() ? String(this.selectedUserValue()) : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AddressBulkActionType",
        value: String(this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChangeRetrievalTypeTo",
        value: this.isChangeRetrievalTypeSelected ? String(this.bulkUpdatesComponent.addressbulkUpdateData.changeRetrievalTypeTo) : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "VendorID",
        value: this.isThirdPartyTypeSelected ? String(this.bulkUpdatesComponent.addressbulkUpdateData.vendorId) : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "TagID",
        value: this.isEnableSpecialHandlingReasonSelected ? String(this.bulkUpdatesComponent.addressbulkUpdateData.tagId) : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "TagTypeID",
        value: this.isSpecialHandlingReasonSelected ? String(TagType.MASTERDOCUMENTSOURCESPECIALHANDLING) : null,
        show: false,
      }),
    ];

    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "masterDocumentSourceID ", header: "ADDRESS ID" }),
      new GridColumnDefinition({
        field: "projectID ", header: "PROJECT ID", show: !this.isAssignAddressesActionTypeSelected() && !this.isChangeRetrievalTypeSelected
          && !this.isSpecialHandlingReasonSelected && !this.isProviderStatementSelected,
      }),
      new GridColumnDefinition({ field: "oldAssignedUser ", header: "CURRENT ASSIGNED TO USER", show: this.isAssignAddressesActionTypeSelected() }),
      new GridColumnDefinition({ field: "newAssignedUser ", header: "NEW ASSIGNED TO USER", show: this.isAssignAddressesActionTypeSelected() }),
      new GridColumnDefinition({ field: "oldDocumentSourceType ", header: "ORIGINAL RETRIEVAL TYPE", show: this.isChangeRetrievalTypeSelected }),
      new GridColumnDefinition({ field: "newDocumentSourceType ", header: "CHANGE RETRIEVAL TYPE TO", show: this.isChangeRetrievalTypeSelected }),
      new GridColumnDefinition({ field: "oldSHReason ", header: "CURRENT SPECIAL HANDLING REASON", show: this.isSpecialHandlingReasonSelected }),
      new GridColumnDefinition({ field: "newSHReason ", header: "NEW SPECIAL HANDLING REASON", show: this.isEnableSpecialHandlingReasonSelected }),
      new GridColumnDefinition({ field: "currentStatementStatus ", header: "Current Statement Status", show: this.isProviderStatementSelected }),
      new GridColumnDefinition({ field: "newStatementStatus", header: "New Statement Status", show: this.isProviderStatementSelected }),
      new GridColumnDefinition({ field: "validationStatus", header: "Validation Status" }),
      new GridColumnDefinition({ field: "message", header: "COMMENTS" }),
    ];

    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "address/bulkupdate/validate/process";
    this.getBulkValidationData(url);
  }

  getBulkValidationData(url: string) {
    this.serverRequest.setForm();
    const request = this.serverRequest.createRequestObject();
    this.bulkUpdateChaseService.getBulkValidationData(url, request).subscribe(res => {
      this.data = res;
      this.changeDetector.markForCheck();
    });
  }

  refreshGrid(): void {
    this.gridRefresh.emit();
  }

  finalBulkUpdate() {
    this.bulkUpdate = new AddressBulkUpdate({
      projectId: this.bulkUpdatesComponent.addressbulkUpdateData.projectId,
      addressIds: this.bulkUpdatesComponent.addressbulkUpdateData.addressIds,
      notes: `${this.addressNote} via Bulk Action`,
      action: 1,
      addressBulkActionType: this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType,
      assignToUserId: this.isAssignAddressesActionTypeSelected() ? this.selectedUserValue() : null,
      changeRetrievalTypeTo: this.isChangeRetrievalTypeSelected ? this.bulkUpdatesComponent.addressbulkUpdateData.changeRetrievalTypeTo : null,
      vendorId: this.isThirdPartyTypeSelected ? this.bulkUpdatesComponent.addressbulkUpdateData.vendorId : null,
      tagId: this.isEnableSpecialHandlingReasonSelected ? this.bulkUpdatesComponent.addressbulkUpdateData.tagId : null,
      tagTypeId: this.isSpecialHandlingReasonSelected ? TagType.MASTERDOCUMENTSOURCESPECIALHANDLING : null,
    });

    this.bulkUpdateChaseService.finishAddressBulkUpdate(this.bulkUpdate).subscribe(
      {
        next: () => {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        },
        error: () => {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        },
      }
    );
  }

  get addressNote(): string {

    return this.formGroup.get(this.addressNotes.key).value;
  }

  createAddressForm(): void {
    this.formGroup = this.formService.createFormGroup([
      this.assignToUsers,
      this.addressNotes]);
  }
  createAdressFormWithoutAssignUser(): void {
    this.formGroup = this.formService.createFormGroup([
      this.addressNotes]);
  }
  validateAddressesAndGetUsers(): void {
    if (this.isAssignAddressesActionTypeSelected()) {
      const addressIds = this.bulkUpdatesComponent.addressbulkUpdateData.addressIds.map(Number);
      this.addressesQueueService
        .getRetrievalUsers(addressIds)
        .subscribe(users => {
          this.assignToUsers = new Autocomplete({ ...this.assignToUsers, options: users } as any);
          this.changeDetector.markForCheck();
        });
    }

  }

  selectedUserValue(): number {

    return this.formGroup.get(this.assignToUsers.key).value.value;
  }

  isAssignAddressesActionTypeSelected(): boolean {

    return this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.AssignAddresses;
  }

  get isChangeRetrievalTypeSelected(): boolean {

    return this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.ChangeRetrievalType;
  }

  get isThirdPartyTypeSelected(): boolean {

    return NumberHelper.isAvailable(this.bulkUpdatesComponent.addressbulkUpdateData.vendorId);
  }

  get isFinalBulkUpdate(): boolean {
    if (this.basicGridComponent && ArrayHelper.isAvailable(this.basicGridComponent.data)) {
      return this.bulkUpdatesService.checkValidationStatus(this.basicGridComponent.data);
    }
  }

  get isSpecialHandlingReasonSelected(): boolean {
    return this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.EnableSpecialHandling ||
      this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.DisableSpecialHandling;
  }

  get isProviderStatementSelected(): boolean {
    return this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.EnableProviderStatements ||
      this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.DisableProviderStatements;
  }

  get isEnableSpecialHandlingReasonSelected(): boolean {
    return this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.EnableSpecialHandling;
  }

}
