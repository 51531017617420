import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { List } from "immutable";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { MenuItem } from "../menu-item.model";

@Component({
  selector: "app-sub-menu",
  templateUrl: "./sub-menu.component.html",
  styleUrls: ["./sub-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMenuComponent {
  @Input() menuItems: MenuItem[] | List<MenuItem> = [];
  @Output() onClick = new EventEmitter<string>(true);
  @Input() currentRoute = "";
  get hasMenuItmes(): boolean {
    return ArrayHelper.isAvailable(this.menuItems);
  }

  get filteredMenuItems(): List<MenuItem> {
    const items = this.hasMenuItmes ? (this.menuItems as any).filter(item => item.show) : [];
    return List(items);
  }

  isFirstItem(index: number): boolean {
    return index < 1;
  }

  trackByFn(index, item) {
    return index;
  }
  onMenuItemClick(path: string) {
    this.onClick.emit(path);
  }
  getActiveClass(menuItem: any): string {
    let cssClass = "link";
    if (StringHelper.isAvailable(this.currentRoute)) {
      return  cssClass = this.currentRoute.toLowerCase() === menuItem.path.toLowerCase() ? `${cssClass} active` :  `${cssClass}` ;
      }
    return cssClass;
    }
}
