import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-deletes-tracking",
  templateUrl: "./deletes-tracking.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletesTrackingComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.DELETES_TRACKING_DASHBOARD;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects" , "ChaseId", "ConditionCategory", "ChaseTags"];
    // There is an ongoing issue with looker URL's, if the URL is too long the report will crash
    // The hide filters for this report will be hardcoded until we have a workaround
    this.hideFilters = ["Project_ID", "Org_ID", "user_id", "Chase_ID", "Workflow_Status_Name", "AID", "Validation_Reason_Code", "DiagnosisValidationResult", "ClaimID", "Condition_Category", "Chase_Tags",
    ];
  }

}
