<p-overlayPanel
#primePopup
[appendTo]="appendTo"
[dismissable]="!isPinned"
(onShow)="onShow.emit($event)"
(onHide)="onHide.emit($event)">
  <div #contentWrapper>
    <ng-content ></ng-content>
  </div>
</p-overlayPanel>
