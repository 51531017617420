import { IAutoMapper } from "../../../../core/automapper/interfaces";

export const mapChartFilter = (automapper: IAutoMapper): void => {
    automapper
    .createMap("default", "ChartFilter")
        .forMember("filterTypeId", o => o.filterTypeId)
        .forMember("filterTypeName", o => o.filterTypeName)
        .forMember("documentPageIds", o => automapper.mapMany("default", "number", o.documentPageIds));
};

export class ChartFilter {
    filterTypeId: number;
    filterTypeName: string;
    documentPageIds: number[];
}
