import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../../../utilities/contracts/boolean-helper";
import { DxType, RiskDxIcd } from "./risk-dx-icd.model";

export class RiskDx {
  admin?: RiskDxIcd[];
  eve?: RiskDxIcd[];
  coder?: RiskDxIcd[];
  chaseId?: number;
  isReadOnly: boolean;
  masterEveEncounterId: number;

  constructor(options: Partial<RiskDx> = {}) {
    this.chaseId = options.chaseId;
    this.admin = ArrayHelper.clean(options.admin, []);
    this.eve = ArrayHelper.clean(options.eve, []);
    this.coder = ArrayHelper.clean(options.coder, []);
    this.isReadOnly = options.isReadOnly;
    this.masterEveEncounterId = options.masterEveEncounterId;

    this.admin.forEach(group => group.dxType = DxType.ADMIN);
    this.eve.forEach(group => group.dxType = DxType.EVE);
  }

  get dxCount(): number {
    return this.admin?.length + this.eve?.length + this.coder?.length ?? 0;
  }

  get isReadyToSubmit(): boolean {
    return this.eve.every(eve => BooleanHelper.isAvailable(eve.isValidated))
      && this.admin.every(admin => BooleanHelper.isAvailable(admin.isValidated))
      && this.coder.every(coder => BooleanHelper.isAvailable(coder.isValidated));
  }
}
