<p-tabPanel
    #primeTabPanel
    [header]="header"
    [rightIcon]="rightIcon"
    [leftIcon]="leftIcon"
    [selected]="selected"
    [disabled]="disabled" >

    <ng-template pTemplate="content">
        <ng-content></ng-content>
    </ng-template>
</p-tabPanel>
