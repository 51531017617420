import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { TagAddressNotes } from "./model/tag-address-notes.model";
import { TagItems } from "./model/tag-items.model";
import { TagType } from "./model/tag-type.enum";

@Injectable({
  providedIn: "root",
})
export class TagService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getAllTagsList(tagTypeId: TagType, tagID: number, searchText?: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}tags?tagTypeId=${tagTypeId}&tagID=${tagID}&searchText=${searchText}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("TagItem", "SelectableInput"))
    );
  }

  getTagsByObjectId(objectId: number, tagTypeId: TagType): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}tags/tagsById?objectId=${objectId}&tagTypeId=${tagTypeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("TagItem", "SelectableInput"))
    );
  }

  saveTags(tagItems: TagItems): Observable<number> {
    const url = `${this.baseApiUrl}tags/tagitem`;
    return this.http.post(url, tagItems) as Observable<number>;
  }

  associateTags(tagItems: TagItems): Observable<number> {
    const url = `${this.baseApiUrl}tags/associateTag`;
    return this.http.post(url, tagItems) as Observable<number>;
  }

  deleteTag(tagId: number, objectIds?: number[]): Observable<number> {
    const objectQueryString = ArrayHelper.isAvailable(objectIds) ?
          objectIds.map(value => `objectIds=${encodeURIComponent(value.toString())}`).join("&") : "";
    const url = `${this.baseApiUrl}tag/remove?${objectQueryString}&tagId=${tagId}`;
    return this.http.delete(url) as Observable<number>;
  }

  deleteTagByTagType(tagTypeId: number, objectIds?: number[]): Observable<number> {
    const objectQueryString = ArrayHelper.isAvailable(objectIds) ?
          objectIds.map(value => `objectIds=${encodeURIComponent(value.toString())}`).join("&") : "";
    const url = `${this.baseApiUrl}tag/removebytagtype?${objectQueryString}&tagTypeId=${tagTypeId}`;
    return this.http.delete(url) as Observable<number>;
  }

  getTagsList(objectIds: number[]): Observable<SelectableInput[]> {
    const objectQueryString = ArrayHelper.isAvailable(objectIds) ?
    objectIds.map(value => `objectIds=${encodeURIComponent(value.toString())}`).join("&") : "";
    const url = `${this.baseApiUrl}tagList/tagsByObjectIds?${objectQueryString}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("TagItem", "SelectableInput"))
    );
  }
  deleteTags(tagId: number): Observable<null> {
    const url = `${this.baseApiUrl}tag/delete?tagId=${tagId}`;
    return this.http.delete(url) as Observable<null>;
  }

  replaceTags(tagItems: TagItems): Observable<number> {
    const url = `${this.baseApiUrl}tag/replace`;
    return this.http.post(url, tagItems) as Observable<number>;
  }

  saveManageTagNotes(tagAddressNotes: TagAddressNotes): Observable<null> {
    const url = `${this.baseApiUrl}tag/notes/save`;
    return this.http.post(url, tagAddressNotes) as Observable<null>;
  }

  getTagSearchOperator(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}tag/operators`;

    return this.http.get(url)
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput"))
      );
  }

}
