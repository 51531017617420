<div class="dashboard-clinical-container">
  <app-detail-main-template>
    <div container-header>
      <h3>My Chases</h3>
      <div class="chase-land-stats">
        <div class="ui-g">
          <div class="ui-g-8">
            <div class="chase-land-stats_keyItem" *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item)">
              <span>{{item.key}}</span><br>
              <a class="chase-land-stats-item bold" [ngClass]="getHeaderItemClass(item.key)">
                <span (click)="applyFilter(item.key)">{{item.value}}</span>
              </a>
            </div>
            <div *ngIf="isOverreadFeedbackEnabled" class="chase-land-stats_keyItem">
              <span>Overread Feedback\Acknowledgements</span><br>
              <a class="chase-land-stats-item bold" routerLink="/dashboard/clinical/orfeedback">{{ overreadFeedbackCount }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div container-body>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="getClassForColumn()">
          <app-chase-grid [stateName]="stateName"
                          [additionalFilters]="additionalFilters"
                          [isOverreadFeedbackEmpty]="isOverreadFeedbackEmpty"
                          chaseIdRouteUrl="/members/chase/:chaseId"
                          [clinical]="true"
                          [showViews]="true"
                          [viewAttributeId]="viewAttributeId"></app-chase-grid>
        </div>
        <div *ngIf="!hasClientLeadRole" class="ui-g-12 ui-md-12 ui-lg-12 text-center">
          <app-button class="button"
                      *ngIf="hasMultipleRoles"
                      text="GET NEXT AVAILABLE CHASE"
                      classes="primary-action"
                      (onClick)="getNextChaseForMultipleRoles()"
                      [disabled]="!isRequestAdditionalWorkEnabled"></app-button>
          <app-button class="button"
                      *ngIf="hasSingleRole"
                      text="GET NEXT AVAILABLE CHASE"
                      classes="primary-action"
                      (onClick)="getNextChase()"
                      [disabled]="!isRequestAdditionalWorkEnabled"></app-button>
        </div>
      </div>

    </div>

  </app-detail-main-template>
</div>

<app-modal [(visible)]="isRoleModalVisible"
           header="SELECT ROLE"
           (onCancel)="closeRoleModal()">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="clinicalFormGroup" [model]="roleInput"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button"
                text="Get Next Available Chase"
                classes="primary-action"
                (onClick)="getNextChase()"
                [disabled]="!hasSelectedRole || !isRequestAdditionalWorkEnabled"></app-button>
  </footer>
</app-modal>


