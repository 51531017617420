import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chase-move-report",
  templateUrl: "./chase-move-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveReportComponent {

  filters: string[] = ["Projects", "Products", "LOB", "RetrievalOwner", "ExpectedRetrieval", "HealthPlan", "ContractNumber", "MoveDateStart", "MoveDateEnd"];
  dashboardType = LookerRiskDashboardType.CHASE_MOVE_REPORT;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID2, HideFilters.ORG_ID, HideFilters.MOVE_DATE,
                           HideFilters.HEALTH_PLAN, HideFilters.LINE_OF_BUSINESS, HideFilters.CONTRACT_NUMBER,
                           HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.EXPECTED_RETRIEVAL2, HideFilters.PROJECT_NAME];
}
