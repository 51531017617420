export class EmrEditModel {
  retrievalLocationId: number;
  address1?: string;
  address2?: string;
  addressCity?: string;
  addressState?: string;
  addressZip?: string;
  url: string;
  phone?: string;
  email?: string;
  faxNumber?: string;
  contactName?: string;
  groupName?: string;

  constructor(options: {
    retrievalLocationId: number;
    address1?: string;
    address2?: string;
    addressCity?: string;
    addressState?: string;
    addressZip?: string;
    url: string;
    phone?: string;
    email?: string;
    faxNumber?: string;
    contactName?: string;
    groupName?: string;
  }) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.address1 = options.address1 || "";
    this.address2 = options.address2 || "";
    this.addressCity = options.addressCity || "";
    this.addressState = options.addressState || "";
    this.addressZip = options.addressZip || "";
    this.url = options.url;
    this.email = options.email || "";
    this.phone = options.phone || "";
    this.faxNumber = options.faxNumber || "";
    this.contactName = options.contactName || "";
    this.groupName = options.groupName || "";

  }
}
