import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CheckboxModule } from "primeng/checkbox";
import { CheckboxComponent } from "./checkbox.component";

@NgModule({
  imports: [
    CheckboxModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CheckboxComponent,
  ],
  entryComponents: [
    CheckboxComponent,
  ],
  exports: [
    CheckboxComponent,
  ],
})
export class CheckBoxModule { }
