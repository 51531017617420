import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../../../core/storage/local.service";
import { SaveGroup } from "../../../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { DynamicInput } from "../../../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { ProviderDropdown } from "../../../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { SelectableInput } from "../../../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../../../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { TextboxType } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../../../dynamic-forms/validators/date-between.validator";
import { GenericAsyncValidator } from "../../../../../../../../../dynamic-forms/validators/generic.async-validator";
import { ArrayHelper } from "../../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../../../../api/workflow/workflow-status-db.enum";
import { CHART_PAGE_NUMBER, DIAGNOSIS_CODE, EXEMPT_FROM_SCORING, FROM_DATE, NLP_GROUP_ID, NLP_REVIEW_RESULT, OMISSION_CODE, PROVIDER_ID, THRU_DATE, VALIDATION_REASON_CODES } from "../../../../../../chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../../../../../chase-detail/chase-detail-chart/chart.service";
import { Diagnosis } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { IcdService } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/icd.service";
import { RiskEntity } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskHelper } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { ChaseDetailV2ChartRiskService } from "../../../risk.service";
import { DiagnosisService } from "../diagnosis.service";

// TODO: A base class should be extracted because admin and coder components do the same thing at
// a high level: present a Dx. Specific logic for EVE, Admin and Coder Dx can be implemented in a
// child class.
@Component({
  selector: "member-risk-diagnosis-v2-eve",
  templateUrl: "./eve.component.html",
  styleUrls: ["./eve.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisV2EVEComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chaseDetailState: ChaseDetailState;
  @Input() riskState: RiskState;
  @Input() eveCodedDiagnoses: Diagnosis[];
  @Input() showReadOnly: boolean;
  @Output() showVRCWarningForNoMatch = new EventEmitter<boolean>();
  indexCheckbox = null;
  activeState = [];
  selected: { diagnosis: Diagnosis; riskEntity: RiskEntity };
  form: FormGroup;
  saveGroup: SaveGroup;
  isVRCWarningVisible = false;
  serviceProviderRequired = "";
  projectConfigurationOmissionCodeEnabled = "";
  substained: string[] = [];
  endDate = "";
  confidenceScoringTooltip = "The score indicates the confidence level of the accuracy of the relationship between the attribute and its parent entity.";
  evidenceScoringTooltip = "The score indicates the confidence level of the accuracy of the relationship between the Evidence and the parent entity.";
  selectedProvider: string;
  private pageNumberInput: Textbox;
  private providerInput: ProviderDropdown;
  private sink = new SubSink();
  private startDateInput: Textbox;
  private endDateInput: Textbox;
  private icdCodeInput: Autocomplete;
  private vrcInput: TagSearchMultiselect;
  private omissionCodeInput: TagSearchMultiselect;
  private selectedEncounterId: number;
  private saveEnabled: boolean;
  private saving: boolean;
  private deletingDiagnosis: boolean;
  exemptInput: Checkbox;
  isOverread = false;
  isOverread2 = false;
  currentDiagnosisIndex: number;
  tempEveData = [];
  localStorageDiagnosisIndex = "selectedDiagnosisIndex";
  vrcRules: string[];
  vrcForDiagnoses: string[] = [];
  vrcMatched = true;
  isVRCMatching = false;
  isConfirmDeleteVisible = false;
  private reviewAdminEncountersAndDiagnoses = "";
  vrcsExclusion = ["39", "107"];
  private localStorageIsWindowOpen = "isWindowOpen";
  private localStoragePageNumber = "pageNumber";
  private overreadHighlightArray: any[] = [];
  private currentDxCardDetails: any;
  constructor(
    private readonly formService: FormService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private chartService: ChartService,
    private genericAsyncValidator: GenericAsyncValidator,
    private readonly riskService: RiskService,
    private readonly automapper: AutomapperService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly icdService: IcdService,
    private readonly messagingService: MessagingService,
    private authService: AuthService,
    private readonly diagnosisService: DiagnosisService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private localService: LocalService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showReadOnly && !ObjectHelper.isEmpty(this.selected?.diagnosis)) {this.fillData(); }
  }

  get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  get isReviewAdminEncountersAndDiagnosesRequired(): boolean {
    return this.reviewAdminEncountersAndDiagnoses === "1";
  }

  get isvrcRequired(): boolean {
    return this.chaseDetailState.isVrcRequired;
  }

  get isOmissionCodeEnabled(): boolean {
    return this.projectConfigurationOmissionCodeEnabled === "1";
  }

  get totalDiagnoses(): number {
    return this.eveCodedDiagnoses ? this.eveCodedDiagnoses.length : 0;
  }

  get isEmployeeRole(): boolean {
    return (!this.authService.user.isAdminRole || !this.authService.user.isManagerRole || !this.authService.user.isLeadRole) && this.authService.user.isEmployeeRole;
  }

  get enabledCard(): boolean {
    return !this.riskState.isEnabled || !this.riskState.isSelectedEncounterValid;
  }

  get isEnabled(): boolean {
    {
      const selectedEncounterIsSaved = NumberHelper.isGreaterThan(this.riskState.selectedEncounter.entityId, 0);
      return this.riskState.isEnabled && this.riskState.isSelectedEncounterValid && selectedEncounterIsSaved;
    }
  }

  get isSaveEnabled(): boolean {
    return this.saveEnabled;
  }

  ngOnInit(): void {
    this.isOverread = this.chaseDetailState.isOverread;
    this.isOverread2 = this.chaseDetailState.isOverread2;
    this.vrcRules = this.chaseDetailState.diagnosisValidationReasonCodes;
    this.initializeForm();
    this.sink.add(
      this.chaseDetailStateService.isEncounterSelected$
        .pipe(filter(encounter => this.selectedEncounterId !== encounter.encounterId))
        .subscribe(encounter => {
          this.collapseAllTabs();
          this.selectedEncounterId = encounter.encounterId;
        }),
      this.chaseDetailStateService.isOverreadToggle$.subscribe(res => {
        if (this.selectedEncounterId && res) {
          this.toggledHighlights(res);
          this.changeDetector.markForCheck();
        }
      }),
      this.chaseDetailStateService.eveDxTempData$
        .pipe(map(tempData => this.tempEveData = tempData))
        .subscribe(),
      this.chaseDetailV2ChartRiskService.previousUrl$
        .pipe(filter((previousUrl: string) => previousUrl?.includes("submit")))
        .subscribe(_ => this.isDiagnosisSelected()),
      this.riskService.dxCardProvider$
        .pipe(
          filter((newProvider: any) => !ObjectHelper.isEmpty(newProvider) && ArrayHelper.isAvailable(newProvider.providers)),
          map(this.newProvidedAdded.bind(this)))
        .subscribe()
    );
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  getEndDate() {
    const endDate = new Date(this.chaseDetailStateService.isEncounterSelected.value.endDate);
    let month = `${endDate.getMonth() + 1}`;
    const year = `${endDate.getFullYear()}`;
    let day = `${endDate.getDate()}`;

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }

    this.endDate = `${month}/${day}/${year}`;
  }

  selectedCheckbox(event, idx) {
    this.deletingDiagnosis = false;

    const allCheckBoxes = document.getElementsByClassName(`input-checkbox--${idx}`) as any;
    if (this.indexCheckbox !== -1 && this.indexCheckbox !== event.index && event.check) {
      for (let index = 0; index < allCheckBoxes.length; index++) {
        if (index !== event.index) {
          allCheckBoxes[index].checked = false;
        }
      }
    }
    if (event.check) {
      const icd = this.eveCodedDiagnoses[idx].icdCodes[event.index];
      const providerSourceAliasId  = this.eveCodedDiagnoses[idx]?.providerSourceAliasId;
      const ICDCODEVALUE = `${icd.code.replace(/\./g, "")}`;
      const ICDTEXT = `${ICDCODEVALUE} - ${icd.text}`;
      this.currentDxCardDetails.icdCode = icd.code;
      this.currentDxCardDetails.diseaseName = icd.text;
      this.chaseDetailStateService.encounterDetails.next(this.currentDxCardDetails);

      this.form.controls.Diagnosis.get("Icd").setValue(new SelectableInput({ text: ICDTEXT, value: ICDCODEVALUE }));
      this.form.controls.Diagnosis.get("startDate").setValue(icd.dosFrom);
      this.form.controls.Diagnosis.get("endDate").setValue(icd.dosThrough);
      this.form.controls.Diagnosis.get("pageNumber").setValue(icd.pageNumber);
      this.form.controls.Diagnosis.get("Provider").setValue(providerSourceAliasId);
      this.form.controls.Diagnosis.get("vrc").setValue(this.selectedOptions(["00"], this.vrcInput));
      this.vrcInput.selectedOptions = this.selectedOptions(["00"], this.vrcInput);

      const tempDataObj = {
        Icd: new SelectableInput({ text: ICDTEXT, value: ICDCODEVALUE }),
        startDate: icd.dosFrom,
        endDate: icd.dosThrough,
        pageNumber: icd.pageNumber,
        providerSourceAliasID: providerSourceAliasId,
        vrcs: this.vrcInput,
        idx,
        index: event.index,
      };

      const alreadyAddedEve = this.tempEveData.findIndex(x => x.idx === idx);
      if (alreadyAddedEve >= 0) {
        this.tempEveData.splice(alreadyAddedEve, 1);
      }

      this.tempEveData.push(tempDataObj);
      this.chaseDetailStateService.updateEveDxTempData(this.tempEveData);
    } else {
      const alreadyAddedEve = this.tempEveData.findIndex(x => x.idx === idx);
      if (alreadyAddedEve >= 0) { this.tempEveData.splice(alreadyAddedEve, 1); }
      this.chaseDetailStateService.updateEveDxTempData(this.tempEveData);
      this.deletingDiagnosis = true;
      this.resetFields();
    }
    this.indexCheckbox = event.index;

    this.enableSaveButton();
    if (event.check) { this.validateProvider(); }
  }

  saveDiagnosis(): void {
    const dynamicControl: any = this.saveGroup.controls;
    this.saveAttributesByInputs(dynamicControl);
  }

  private validateInputs(): boolean {
    return this.validateVrcCode() && this.validatePageNumber() && this.form.valid &&  this.validateProvider();
  }

  validateProvider(): boolean {
    const providerControl = this.form.controls.Diagnosis.get(this.providerInput.key);
    if (!this.isServiceProviderRequired) {
      return true;
    } else {
      if (providerControl.value == null || !StringHelper.isAvailable(providerControl.value)) {
        providerControl.setErrors({ required: true });
        this.disableSaveButton(false);
        return false;
      } else {
        providerControl.setErrors(null);
        this.enableSaveButton();
        return true;
      }
    }
  }

  private enableSaveButton(): void {
    this.saveEnabled = true;
    this.changeDetector.markForCheck();
  }

  private tryEnablingSaveButton(): void {
    this.deletingDiagnosis = false;
    if (!this.saving && this.validateInputs()) {
      this.enableSaveButton();
    } else {
      this.disableSaveButton(false);
    }
  }

  private disableSaveButton(saving = true): void {
    this.saving = saving;
    this.saveEnabled = false;
    this.changeDetector.markForCheck();
  }

  handleKeyUp(): void {
    this.tryEnablingSaveButton();
  }

  handleChange(event: any, isCheckbox = false): void {
    switch (event.type) {
      case "save":
      case "notify":
        this.tryEnablingSaveButton();
        break;
      default:
        break;
    }

    if (isCheckbox) {
      const selectedExemptDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses
        .find(diagnosis => diagnosis.id === this.selected.diagnosis.id);
      const currentExemptAttribute = selectedExemptDiagnosis.attributes
        .find(attribute => attribute.attributeId === EXEMPT_FROM_SCORING.attributeId);
      let newExemptAttribute;
      if (currentExemptAttribute) {
        newExemptAttribute = { ...currentExemptAttribute, value: event ? "1" : "0" };
      } else {
        newExemptAttribute = {
          chaseId: selectedExemptDiagnosis.chaseId,
          entityId: selectedExemptDiagnosis.entityId,
          entityTypeId: EntityType.DIAGNOSIS,
          parentEntityId: selectedExemptDiagnosis.parentEntityId,
          ...EXEMPT_FROM_SCORING,
          value: event ? "1" : "0",
        };
      }
      this.saveAttributes([newExemptAttribute] as DynamicEntityAttribute[]);
    }
  }

  onTabOpen(event: any) {
    this.selectDiagnosisByIndex(event.index);
  }

  private selectDiagnosisByIndex(diagnosisIndex: number): void {
    this.form.controls.Diagnosis.reset();
    this.currentDiagnosisIndex = diagnosisIndex;
    setTimeout(() => {
      this.selectDiagnosis();
      this.getOverreadHighlights();
      this.disableSaveButton(false);
    });
  }

  toggledHighlights(status: string) {
    this.activeState[this.riskState?.selectedDiagnosisIndex] = true;
    const currentDiagnosis = this.eveCodedDiagnoses[this.riskState?.selectedDiagnosisIndex];
    const currentTab = document.getElementById(`eve-${currentDiagnosis.id}`);
    const pageNumber = currentTab.getElementsByTagName("input")[0];
    const vrcEle = currentTab.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab.getElementsByTagName("p-multiselect")[1];

    if (status === WorkflowStatusDb[WorkflowStatusDb.Abstraction]) {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      this.getOverreadHighlights();
      vrcEle.children[0].classList.remove("control__input--overread");
      omissionEle.children[0].classList.remove("control__input--overread");
      pageNumber.classList.remove("control__input--overread");
    } else {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      const selected = this.overreadHighlightArray.filter(x => x.diagnosisIndex === this.currentDiagnosisIndex)[0];
      if (selected?.vrc) {
        vrcEle.children[0].classList.add("control__input--overread");
      }
      if (selected?.omission) {
        omissionEle.children[0].classList.add("control__input--overread");
      }
      if (selected?.page) {
        pageNumber.classList.add("control__input--overread");
      }
    }
  }

  getOverreadHighlights() {
    const index = this.overreadHighlightArray.findIndex(x => x.diagnosisIndex === this.currentDiagnosisIndex);
    if (index >= 0) {
      this.overreadHighlightArray.splice(index, 1);
    }
    const currentDiagnosis = this.eveCodedDiagnoses[this.currentDiagnosisIndex];
    const currentTab = document.getElementById(`eve-${currentDiagnosis.id}`);
    const pageNumber = currentTab.getElementsByTagName("input")[0];
    const vrcEle = currentTab.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab.getElementsByTagName("p-multiselect")[1];

    const vrcHighlights = vrcEle.children[0]?.classList?.value?.includes("control__input--overread");
    const pageHighlights = pageNumber?.classList?.value?.includes("control__input--overread");
    const omissionHighlights = omissionEle?.children[0]?.classList?.value?.includes("control__input--overread");
    const selectedDiagnosisHighlightedProperties = {
      diagnosisIndex: this.currentDiagnosisIndex,
      vrc: vrcHighlights,
      page: pageHighlights,
      omission: omissionHighlights,
    };
    this.overreadHighlightArray.push(selectedDiagnosisHighlightedProperties);

  }
  onTabClose(event: any) {
    this.diagnosisService.toggleDiagnosisSelected(false);
    localStorage.setItem(this.localStorageDiagnosisIndex, "-1");
  }

  bindTempData(index: number) {
    if (ArrayHelper.isAvailable(this.tempEveData)) {
      const selectdIndex = this.tempEveData.findIndex(x => x.idx === index);
      if (selectdIndex >= 0) {
        this.form.controls.Diagnosis.get("Icd").setValue(this.tempEveData[selectdIndex].Icd);
        this.form.controls.Diagnosis.get("startDate").setValue(this.tempEveData[selectdIndex].startDate);
        this.form.controls.Diagnosis.get("endDate").setValue(this.tempEveData[selectdIndex].endDate);
        this.form.controls.Diagnosis.get("pageNumber").setValue(this.tempEveData[selectdIndex].pageNumber);
        this.form.controls.Diagnosis.get("Provider").setValue(this.tempEveData[selectdIndex].providerSourceAliasId);
        this.vrcInput.selectedOptions = this.selectedOptions(["00"], this.vrcInput);
        this.form.controls.Diagnosis.get("vrc").setValue(this.selectedOptions(["00"], this.vrcInput));
        this.indexCheckbox = this.tempEveData[selectdIndex].index;
      }
    }
  }

  resetFields() {
    this.form.controls.Diagnosis.get("Icd").setValue(null);
    this.form.controls.Diagnosis.get("startDate").setValue(null);
    this.form.controls.Diagnosis.get("endDate").setValue(null);
    this.form.controls.Diagnosis.get("vrc").setValue(null);
    this.form.controls.Diagnosis.get("pageNumber").setValue(null);
    this.form.controls.Diagnosis.get("Provider").setValue(null);
    this.form.controls.Diagnosis.get("omission").setValue(null);
    this.indexCheckbox = -1;
  }

  deleteDiagnosis() {
    const diagnosisDeleting = this.riskState.selectedDiagnosis;
    const isRisk20Project = true;

    if (NumberHelper.isGreaterThan(diagnosisDeleting.entityId, 0)) {
      this.chartService.delete(diagnosisDeleting.attributes)
        .pipe(finalize(() => this.deletingDiagnosis = false))
        .subscribe(
          () => {
            this.riskService.refreshData(this.riskState.chaseId, isRisk20Project);
            this.messagingService.showToast("EVE Dx Updated!", SeverityType.SUCCESS);
            this.collapseAllTabs();
          },
          (e: HttpErrorResponse) => {
            this.form.setErrors({ saveError: e.error });
            this.messagingService.showToast("EVE Dx Not Updated", SeverityType.WARN);
          }
        );
    } else {
      this.riskService.deleteDiagnosis(this.riskState.selectedDiagnosisIndex);
    }
  }

  getBadgeStatus(diagnosis: Diagnosis, index: number) {
    if (this.activeState[index]) {
      return "reviewing";
    }

    if (!StringHelper.isAvailable(diagnosis?.actualVrcs)) {
      return "review";
    }

    if (diagnosis.isValid) {
      return "validated";
    }

    return "notValid";
  }

  trackByIndex(index, item) {
    return index;
  }

  getEncounterDetailsForHeader(index: number) {
    const diagnosis: Diagnosis = this.eveCodedDiagnoses[index];
    const diagnosisId = diagnosis.id;

    const diagnosisData: DiseaseDetail = this.diagnosisService.getDiseaseDetail(diagnosisId);
    const controlvalue = this.providerInput.options.find(option => option.value === diagnosis.providerSourceAliasId);
    this.selectedProvider = controlvalue?.extra?.providerName;
    let icdCode = (diagnosisData?.icdCode || diagnosis.text);
    let diseaseName = diagnosisData?.diseaseName;

    if (StringHelper.isAvailable(diagnosis.text) && StringHelper.isAvailable(diagnosisData?.diseaseName) && diagnosis.text === diagnosisData?.diseaseName) {
      const icdCodeIndex = diagnosis.text.lastIndexOf("-");
      icdCode = diagnosis.text.substring(0, icdCodeIndex).trim();
      diseaseName = diseaseName?.replace(icdCode, "").replace("-", "").trim();
    } else {
      diseaseName = diseaseName?.replace(icdCode, "").replace(":", "").trim();
    }
    const diagnosisdetail = {
      icdCode,
      diseaseName,
      modelDisplayName: diagnosisData?.modelDisplayName,
      dos: diagnosis.endDate || diagnosis.dosFrom,
      isDiagnosSelected: true,
      providerName: this.selectedProvider,
    };
    this.currentDxCardDetails = diagnosisdetail;
    this.chaseDetailStateService.encounterDetails.next(diagnosisdetail);
    this.chaseDetailStateService.isFilterButtonSelected.next(false);
  }

  isConfidenceScoreFound(diagnosis: Diagnosis): boolean {
    return StringHelper.isAvailable(diagnosis.confidenceScore);
  }

  showDeleteDiagnosisModal(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.isEnabled) {
      this.isConfirmDeleteVisible = true;
    }
  }

  checkTempEveDataExist(): boolean {
    let tempEveExist = false;
    const alreadyAddedTempEve = this.tempEveData.findIndex(x => x.idx === this.currentDiagnosisIndex);
    if (StringHelper.isAvailable(this.eveCodedDiagnoses[this.currentDiagnosisIndex].icd)) {
      if (NumberHelper.isGreaterThan(alreadyAddedTempEve, 0, true)) {
        this.tempEveData.splice(alreadyAddedTempEve, 1);
       }
      this.tempEveData = [...this.tempEveData];
      this.chaseDetailStateService.updateEveDxTempData(this.tempEveData);
    } else {
      tempEveExist =  NumberHelper.isGreaterThan(alreadyAddedTempEve, 0, true);
    }
    return tempEveExist;
  }

  private selectDiagnosis(shouldFillData = true): void {
    this.updateSelectedDiagnosis(this.eveCodedDiagnoses[this.currentDiagnosisIndex].id);
    this.updateFormWithProjectConfiguration(this.chaseDetailState);
    const tempEveExist = this.checkTempEveDataExist();

    setTimeout(() => {
      const diagnosis = this.eveCodedDiagnoses[this.currentDiagnosisIndex];
      const riskEntity: RiskEntity = this.riskState.selectedDiagnosis;
      this.selected = { diagnosis, riskEntity };
      this.bindTempData(this.currentDiagnosisIndex);
      if (!tempEveExist) {
        this.getEncounterDetailsForHeader(this.currentDiagnosisIndex);
      }
      this.diagnosisService.updateDataEntryPageNumber(diagnosis.pageNumber, diagnosis.diagnosisValidationResult);
      this.diagnosisService.toggleDiagnosisSelected(true);

      if (shouldFillData && !tempEveExist) {
        this.fillData();
      }

      if (this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
        if (diagnosis?.pageNumber !== null && diagnosis.isValid) {
          this.localService.put(this.localStoragePageNumber, diagnosis?.pageNumber);
        }
      }

    },         500);
  }

  private fillData() {
    this.getEndDate();
    const chaseId = this.riskState.chaseId;
    const workflowStatusName = this.riskState.workflowStatusName.toLowerCase();
    const diagnosisId = this.selected.diagnosis.diagnosisId;
    const entityTypeId = this.selected.riskEntity.entityTypeId;
    const encounterId = this.selected.diagnosis.encounterId;
    const disabled = !this.riskState.isEnabled || !this.riskState.isSelectedEncounterValid;

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.exemptInput,
      ATTRIBUTE_DATA: EXEMPT_FROM_SCORING,
    });
    this.form.controls.exemptInput.setValue(this.selected.diagnosis.exempt);

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.selected.diagnosis.isBotSource,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.pageNumberInput,
      ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
    });

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.selected.diagnosis.isBotSource,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.startDateInput,
      ATTRIBUTE_DATA: FROM_DATE,
    });

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.providerInput,
      ATTRIBUTE_DATA: PROVIDER_ID,
    });

    this.providerInput.options = this.riskState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    const attribute = {
      ...RiskHelper.getAttribute(DIAGNOSIS_CODE.attributeCode, this.selected.riskEntity),
      ...DIAGNOSIS_CODE,
      chaseId,
      entityId: diagnosisId,
      entityTypeId,
      parentEntityId: encounterId,
    };
    this.providerInput.options = this.riskState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    this.providerInput.disabled = disabled;
    const control = this.form.get(this.providerInput.getMasterKey());
    this.providerInput.disabled ? control.disable() : control.enable();


    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.vrcInput,
      hidden: !this.isvrcRequired,
      ATTRIBUTE_DATA: VALIDATION_REASON_CODES,
    });

    if (!ArrayHelper.isAvailable(this.vrcInput.options)) {
      this.vrcInput.options = this.riskState.vrcs.filter(x => x.extra.isActive !== false && !this.vrcsExclusion.includes(x.value.toString()));
    }
    if (StringHelper.isAvailable(this.selected.diagnosis.vrcs)) {
      const selectedValues = this.selected.diagnosis.vrcs.split(",");
      this.vrcInput.selectedOptions = this.selectedOptions(selectedValues, this.vrcInput);
    }

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.omissionCodeInput,
      hidden: !ArrayHelper.isAvailable(this.riskState.omissionCodes) || !this.isOmissionCodeEnabled,
      ATTRIBUTE_DATA: OMISSION_CODE,
    });

    this.omissionCodeInput.options = this.riskState.omissionCodes;

    if (StringHelper.isAvailable(this.selected.diagnosis.omissionCodes)) {
      const ocValues = this.selected.diagnosis.omissionCodes.split(",");
      this.omissionCodeInput.selectedOptions = this.selectedOptions(ocValues, this.omissionCodeInput);
    }

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.icdCodeInput,
      ATTRIBUTE_DATA: DIAGNOSIS_CODE,
    });

    if (StringHelper.isAvailable(this.icdCodeInput.value)) {
      const cleanIcdCode = this.icdCodeInput.value.replace(/\./g, "");
      this.icdService
        .getIcds(chaseId, cleanIcdCode, this.startDateInput.value as string)
        .subscribe(
          result => {
            this.icdCodeInput.options = result;
            // HACK: If the query returns 1 value then select as the value.
            const firstOption = result.length === 1 ? result[0] : null;
            const icdCodeControl = this.form.get(this.icdCodeInput.getMasterKey());

            if (firstOption != null && firstOption.value === cleanIcdCode) {
              icdCodeControl.setValue(firstOption);
              this.updateEncounterDetails(firstOption, cleanIcdCode);
            }
            // Note: If the query return multiple matching value then select first as the value.
            if (NumberHelper.isGreaterThan(result.length, 1)) {
              const firstValue =  result[0];
              icdCodeControl.setValue(firstValue);
              this.updateEncounterDetails(firstValue, cleanIcdCode);
            }

            this.changeDetector.markForCheck();
          }
        );
    }

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.selected.diagnosis.isBotSource,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.endDateInput,
      hidden: true,
      ATTRIBUTE_DATA: THRU_DATE,
    });
    this.form.controls.Diagnosis.get("endDate").setValue(this.endDate);
  }

  selectedOptions(selectedValues: any, multiselect: TagSearchMultiselect): SelectableInput[] {
    const selectedOptions = selectedValues.reduce(
      (acc, value) => {
        const found = multiselect.options?.find(option => option.value === value);
        if (found != null) {
          acc.push(found);
        }
        return acc;
      },
      []);

    return selectedOptions;
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    diagnosisId,
    encounterId,
    entityTypeId,
    disabled,
    workflowStatusName,
    hidden = false,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId: diagnosisId,
      entityTypeId,
      parentEntityId: encounterId,
    };

    input.hidden = hidden;
    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled || attribute.isAdmin;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    input.disabled ? control.disable() : control.enable();
  }

  updateEncounterDetails(selectedOption: SelectableInput, cleanIcdCode: string): void {
    if (!StringHelper.isAvailable(this.currentDxCardDetails.icdCode) && !StringHelper.isAvailable(this.currentDxCardDetails.diseaseName)) {
      this.currentDxCardDetails.icdCode = this.icdCodeInput.value;
      this.currentDxCardDetails.diseaseName = `${selectedOption.text.replace(cleanIcdCode, "").replace("-", "").trim()}`;
      this.chaseDetailStateService.encounterDetails.next(this.currentDxCardDetails);
    }
  }

  private initializeForm(): void {
    this.exemptInput = new Checkbox({
      key: `exemptInput`,
      label: "Exempt",
    });

    // INPUTS
    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page",
      type: TextboxType.NUMBER,
      classOverride: "page",
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a valid page number",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date",
      type: TextboxType.TEXT,
      classOverride: "date-input",
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date From is required",
      },
    });

    this.endDateInput = new Textbox({
      key: "endDate",
      label: "Date Thru",
      type: TextboxType.TEXT,
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date Thru is required",
      },
    });

    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      placeholder: "Select",
    });

    this.vrcInput = new TagSearchMultiselect({
      key: "vrc",
      label: "VRC",
      placeholder: "Select...",
      maxSelectedLabels: 150,
    });

    this.omissionCodeInput = new TagSearchMultiselect({
      key: "omission",
      label: "Omission",
      placeholder: "Select...",
      maxSelectedLabels: 150,
    });

    this.icdCodeInput = new Autocomplete({
      key: "Icd",
      label: "ICD",
      searchMinimum: 2,
      serverFilter: (query, setOptionsFn) =>
        this.icdService.getIcds(this.riskState.chaseId, query, this.startDateInput.value as string).subscribe(setOptionsFn),
      validators: [Validators.required],
      errorMessages: {
        required: "ICD Code is required",
        invalidicd: "Invalid ICD Code",
      },
    });

    // SAVE GROUPS
    this.saveGroup = new SaveGroup({
      key: "Diagnosis",
      isHideClearButton: true,
      controls: [
        this.icdCodeInput,
        this.pageNumberInput,
        this.startDateInput,
        this.providerInput,
        this.vrcInput,
        this.endDateInput,
        this.omissionCodeInput,
      ],
    });

    this.pageNumberInput.parent = this.saveGroup;
    this.startDateInput.parent = this.saveGroup;
    this.icdCodeInput.parent = this.saveGroup;
    this.providerInput.parent = this.saveGroup;
    this.vrcInput.parent = this.saveGroup;
    this.endDateInput.parent = this.saveGroup;
    this.omissionCodeInput.parent = this.saveGroup;

    // CREATE FORM
    this.form = this.formService.createFormGroup([this.saveGroup, this.exemptInput]);
  }

  private updateSelectedDiagnosis(id: number | string) {
    const selectedDiagnosisIndex = StringHelper.isAvailable(id) ?
      this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === id) :
      this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.entityId === id);

    this.riskService.setData({ selectedDiagnosisIndex });
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    if (!this.deletingDiagnosis && !this.validateInputs()) {
      return;
    }

    this.disableSaveButton();

    if (this.deletingDiagnosis) {
      this.deleteDiagnosis();
    } else {
      const attributes = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];

      this.extendAttributes(attributes);
      this.saveAttributes(attributes);
    }
  }

  private validatePageNumber() {
    const pageNumber = +this.form.controls.Diagnosis.get("pageNumber").value;
    return NumberHelper.isAvailable(pageNumber) && pageNumber > 0;
  }

  private validateVrcCode(): boolean {
    if (!this.isvrcRequired) {
      return true;
    } else {
      const vrcInputValue = this.form.controls.Diagnosis.get("vrc").value;
      return this.diagnosisService.checkIfVRC108Selected(vrcInputValue) || !this.isReviewAdminEncountersAndDiagnosesRequired;
    }

  }

  private extendAttributes(attributes: DynamicEntityAttribute[]): void {

    const nlpReviewResultAttribute = RiskHelper.getAttribute(NLP_REVIEW_RESULT.attributeCode, this.selected.riskEntity);
    nlpReviewResultAttribute.parentEntityId = this.riskState.selectedEncounter.entityId;
    attributes.push(nlpReviewResultAttribute);

    const nlpGroupId = RiskHelper.getAttribute(NLP_GROUP_ID.attributeCode, this.selected.riskEntity);
    nlpGroupId.parentEntityId = this.riskState.selectedEncounter.entityId;
    attributes.push(nlpGroupId);

    if (!attributes.some(attr => attr.attributeCode === CHART_PAGE_NUMBER.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(CHART_PAGE_NUMBER.attributeCode, this.selected.riskEntity));
    }
    attributes.push(this.setExemptAttribute());
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null
      && attribute.saveInfo != null
      && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0)
      && !attribute.disabled;
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    const formInput = this.form.get(control.getMasterKey());
    if (saveAttribute.attributeId === VALIDATION_REASON_CODES.attributeId || saveAttribute.attributeId === OMISSION_CODE.attributeId) {
      const selectedValues = Array.isArray(formInput.value) ? (ArrayHelper.isAvailable(formInput.value) ? formInput.value?.map(x => x.value).toString() : undefined) : formInput.value;
      const sortedSelectedValues = selectedValues?.split(",").sort(StringHelper.compareNumbersForSortASC).join(",");
      saveAttribute.value = sortedSelectedValues;
    } else {
      saveAttribute.value = saveAttribute.attributeId === DIAGNOSIS_CODE.attributeId ? formInput.value.value : formInput.value;
    }
    return saveAttribute;
  }

  checkScoringCheckbox(val: any) {
    return ArrayHelper.isAvailable(val?.icdCodes);
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.sink.add(
      this.riskService
        .save(attributes, true)
        .pipe(
          finalize(() => this.saving = false)
        )
        .subscribe(
          newAttributes => {
            this.diagnosisService.removeDiseaseDetail(this.riskState.selectedDiagnosis.id);
            this.riskState.selectedDiagnosis.codingModels = []; // Force to load coding models from attributes
            const diagnosisCode = attributes.findIndex(x => x.attributeCode === "DiagnosisCode");
            if (diagnosisCode >= 0) {
              const index = this.tempEveData.findIndex(x => x.Icd.value === attributes[diagnosisCode].value);
              this.tempEveData.splice(index, 1);
            }
            this.chaseDetailStateService.updateEveDxTempData(this.tempEveData);
            this.riskService.setDiagnosisAttributes(this.riskService.data.value.selectedDiagnosisIndex, newAttributes);
            this.isVRCMatching = this.diagnosisService.checkIfVRCMatches(this.riskState, this.vrcRules);
            this.showVRCWarningForNoMatch.emit(!this.isVRCMatching);
            this.messagingService.showToast("EVE Dx Updated!", SeverityType.SUCCESS);
            this.collapseAllTabs();
            this.diagnosisService.toggleDiagnosisSelected(false);
            this.changeDetector.markForCheck();
          },
          (e: HttpErrorResponse) => {
            this.form.setErrors({ saveError: e.error });
            this.messagingService.showToast("EVE Dx Not Updated", SeverityType.WARN);
          }
        )
    );
  }

  private collapseAllTabs(): void {
    if (this.eveCodedDiagnoses !== undefined) {
      for (let i = 0; i < this.eveCodedDiagnoses.length; i++) {
        this.activeState[i] = false;
      }
      this.changeDetector.markForCheck();
    }
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {

      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "0";

      this.projectConfigurationOmissionCodeEnabled =
        StringHelper.isAvailable(projectConfiguration.omissionCodeEnabled) ? projectConfiguration.omissionCodeEnabled : "0";

      this.reviewAdminEncountersAndDiagnoses =
        StringHelper.isAvailable(projectConfiguration.reviewAdminEncountersAndDiagnoses) ? projectConfiguration.reviewAdminEncountersAndDiagnoses : "0";
    }
  }

  private isDiagnosisSelected(): void {
    if (this.riskState.hasSelectedDiagnosisIndex) {
      const diagnosisId = this.riskState.selectedDiagnosis.id;
      this.currentDiagnosisIndex = this.eveCodedDiagnoses.findIndex(d => d.id === diagnosisId);
      if (NumberHelper.isGreaterThan(this.currentDiagnosisIndex, 0, true)) {
        this.selectDiagnosis();
        this.scrollIntoView(diagnosisId);
        this.activeState[this.currentDiagnosisIndex] = true;
      }
    }
  }

  private scrollIntoView(diagnosisId: string): void {
    this.diagnosisService.waitForElement(`eve-${diagnosisId}`)
      .then((el: HTMLElement) =>
        el.scrollIntoView({ block: "center" })
      );
  }

  private newProvidedAdded(newProvider: any): void {
    const selectedOption = newProvider.providers.find(a => a.extra.nationalProviderId === newProvider.selectedOption);
    this.providerInput.options = newProvider.providers;
    this.form.get("Diagnosis").get("Provider").setValue(selectedOption?.value.toString());
  }

  getCheckedValue(icdCode: any, eveCodedDiagnosis: any, index: number, checkbBoxIndex: number) {
    if (icdCode.code === eveCodedDiagnosis.icd || icdCode.code.replace(/\./g, "") === eveCodedDiagnosis.icd) { return true; } else {
      const selectedIndex = this.tempEveData.findIndex(x => x.idx === index);
      if (selectedIndex >= 0) {
        return this.tempEveData[selectedIndex].index === checkbBoxIndex;
      }
    }
  }

  isMultipleCodingModel(eveCodedDiagnosis) {
      return ArrayHelper.isAvailable(eveCodedDiagnosis.codingModels) && eveCodedDiagnosis.codingModels.length > 1;
  }
  private setExemptAttribute(): DynamicEntityAttribute {
    const exemptFromScoringAttribute = RiskHelper.getAttribute(EXEMPT_FROM_SCORING.attributeCode, this.selected.riskEntity);
    const control = this.form.get(this.exemptInput.getMasterKey()) as FormControl;

    let newExemptAttribute;
    if (!ObjectHelper.isEmpty(exemptFromScoringAttribute)) {
      newExemptAttribute = { ...exemptFromScoringAttribute, value: control.value ? "1" : "0" };
    } else {
      newExemptAttribute = {
        chaseId: this.selected.riskEntity.chaseId,
        entityId: this.selected.riskEntity.entityId,
        entityTypeId: EntityType.DIAGNOSIS,
        parentEntityId: this.selected.riskEntity.parentEntityId,
        ...EXEMPT_FROM_SCORING,
        value: control.value ? "1" : "0",
      };
    }
    return newExemptAttribute;
  }
}
