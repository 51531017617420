export class BulkChasesAssignmentRequest {
    chaseIds: string[];
    projectId?: number;

    constructor(options: {
        chaseIds: string[];
        projectId?: number;
    }) {
        this.chaseIds = options.chaseIds;
        this.projectId = options.projectId;
    }
}
