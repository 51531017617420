<app-detail-header-template>
  <div top-left>
    <div class="container-back-button">
      <app-back-button [text]="backButtonText"></app-back-button>
    </div>
    <h3 class="container-addressId" (click)="navigateNewDesign()">
      {{addressText | uppercase}}
    </h3>
  </div>
  <div top-right>
    <div class="address-item bold"> {{addressLocationText | uppercase}}</div>
    <div class="address-retrieval-type bold">
      PSR <app-icon-stack iconName="user-md" iconBase="circle"></app-icon-stack>
    </div>
  </div>
  <div bottom-left>
    <app-sub-menu [menuItems]="menuItems" ></app-sub-menu>
  </div>
  <div bottom-right>

    <app-button class="header-button" text="EDIT ADDRESS" (onClick)="showEditAddressModal()"></app-button>
    <retrieval-address-detail-edit [isCreateVisible]="isAddressEditView" (formClose)="receiveAddressEditValue($event)"></retrieval-address-detail-edit>

    <p-splitButton class="spilt-button-class" label="CHANGE RETRIEVAL METHOD" [model]="assignmentItems" menuStyleClass="menuPosRight"></p-splitButton>
    <retrieval-address-assignment [documentSourceId]="addressId" [chases]=chaseList [isCreateVisible]="isAddressAssignmentView" (formClose)="receiveAddressAssignmentValue($event)"></retrieval-address-assignment>
    <retrieval-emr-assignment [documentSourceId]="addressId" [chases]=chaseList [isCreateVisible]="isEmrAssignmentView" (formClose)="receiveEmrAssignmentValue($event)"></retrieval-emr-assignment>
    <retrieval-ft-assignment [documentSourceId]="addressId" [chases]=chaseList [isCreateVisible]="isFTAssignmentView" (formClose)="receiveFTAssignmentValue($event)"></retrieval-ft-assignment>

    <app-button class="header-button" text="NEXT ADDRESS" [ngClass]="isFinishCall ? 'show-next-address-button':'hide-next-address-button'" (onClick)="getNextAddress()"></app-button>
    <app-button class="header-button" text="CALL {{phoneNumber | phoneNumber}}" (onClick)="showCallPopover($event)" #call></app-button>

    <app-popover #callPopOver [isPinned]="true">
      <app-iframe [visible]="display" [load]="display" iframeSrc="{{ twiliourl }}" iframeWidth="{{ twilioWidth }}" iframeHeight="{{ twilioHeight }}"></app-iframe>
      <app-button text="Hangup" (onClick)="endCallOnHangup()"></app-button>
    </app-popover>

  </div>
</app-detail-header-template>
