import { StringHelper } from "../../utilities/contracts/string-helper";

export class Legend {
  parentClass?: string;
  childClass?: string;
  textClass?: string;
  text?: string;
  iconName?: string;

  constructor(options: Partial<Legend> = {}) {
    this.parentClass = StringHelper.clean(options.parentClass);
    this.childClass = StringHelper.clean(options.childClass);
    this.textClass = StringHelper.clean(options.textClass);
    this.text = StringHelper.clean(options.text);
    this.iconName = options.iconName ? options.iconName : "circle";
  }
}
