import { IAutoMapper } from "../../../core/automapper/interfaces";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DocumentValidateResponseStatus } from "./document-upload-response.enum";
export const mapDocumentValidateResponse = (automapper: IAutoMapper): void => {
    automapper
      .createMap("default", "DocumentValidateResponse")
      .forMember("status", o => o.status)
      .forMember("message", o => o.message)
      .forMember("chaseId", o => o.chaseId)
      .forMember("fileName", o => o.fileName)
      .forMember("documentTypeId", o => o.documentTypeId)
      .forMember("objectType", o => o.objectType)
      .forMember("objectTypeKeyType", o => o.objectTypeKeyType)
      .forMember("clientId", o => o.clientId)
      .forMember("projectId", o => o.projectId)
      .forMember("fileSize", o => o.fileSize)
      .forMember("progressTemplate", o => o.progressTemplate)
      .forMember("tagTextCsv", o => o.tagTextCsv)
      .forMember("tagTypeId", o => o.tagTypeId)
      .forMember("tagSourceId", o => o.tagSourceId);
  };
export class DocumentValidateResponse {
    readonly status: DocumentValidateResponseStatus;
    readonly message: string;
    readonly chaseId: number;
    readonly fileName: string;
    readonly documentTypeId: number;
    readonly objectType: number;
    readonly objectTypeKeyType: string;
    readonly clientId: number;
    readonly projectId: number;
    readonly fileSize: number;
    readonly progressTemplate: string;
    readonly tagTextCsv: string;
    readonly tagTypeId: number;
    readonly tagSourceId: number;
    constructor(options: {
        status: DocumentValidateResponseStatus;
        message: string;
        chaseId: number;
        fileName: string;
        documentTypeId: number;
        objectType: number;
        objectTypeKeyType: string;
        clientId: number;
        projectId: number;
        fileSize: number;
        progressTemplate: string;
        tagTextCsv: string;
        tagTypeId: number;
        tagSourceId: number;
    }) {
        this.status = options.status;
        this.message = options.message;
        this.chaseId = options.chaseId;
        this.fileName = options.fileName;
        this.documentTypeId = options.documentTypeId;
        this.objectType = options.objectType;
        this.objectTypeKeyType = options.objectTypeKeyType;
        this.clientId = options.clientId;
        this.projectId = options.projectId;
        this.fileSize = options.fileSize;
        this.progressTemplate = StringHelper.clean(options.progressTemplate);
        this.tagTextCsv = options.tagTextCsv;
        this.tagTypeId = options.tagTypeId;
        this.tagSourceId = options.tagSourceId;
    }
}
