<app-basic-grid 
    #chartChangeActionGrid 
    class="chartChangeAction-grid" 
    [configuration]="gridConfiguration"
    [data]="chartChangeActions">

    <ng-template #compliantColumn let-rowData>
        <label class="checkbox-container">
            <input type="checkbox"
                [(ngModel)]="rowData.compliant"
                [disabled]="isDisabledMeasureCode(rowData.measureCode)">
            <span class="checkmark"></span>
        </label>
    </ng-template>

    <ng-template #nonCompliantColumn let-rowData>             
        <label class="checkbox-container">
            <input type="checkbox"
                [(ngModel)]="rowData.nonCompliant"
                [disabled]="isDisabledMeasureCode(rowData.measureCode)">
            <span class="checkmark"></span>
        </label>
    </ng-template>

    <ng-template #partiallyCompliantColumn let-rowData>
        <label class="checkbox-container">
            <input type="checkbox"
                [(ngModel)]="rowData.partiallyCompliant"
                [disabled]="isDisabledMeasureCode(rowData.measureCode)">
            <span class="checkmark"></span>
        </label>
    </ng-template>

    <ng-template #nonCompliantExlusionColumn let-rowData>             
        <label class="checkbox-container">
            <input type="checkbox"
                [(ngModel)]="rowData.nonCompliantExclusion"
                [disabled]="isDisabledMeasureCode(rowData.measureCode)">
            <span class="checkmark"></span>
        </label>
    </ng-template>

</app-basic-grid>