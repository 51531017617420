<div class="v2-chart-risk__sub-menu">
  <member-risk-menu [menuItems]="menuItems" [currentRoute]="pathLastRoute" (selectedSubMenu)="onTabSelected($event)">
  </member-risk-menu>

  <!-- Note: This new menu fo dx-review needs to bind with condition  -->
  <!-- <app-risk-dx-menu [currentRoute]="pathLastRoute"></app-risk-dx-menu> -->

</div>
<member-risk-encounter-list *ngIf="pathLastRoute === 'encounter'"></member-risk-encounter-list>
<div class="v2-chart-risk__coding" [ngClass]="{'encounter': pathLastRoute === 'encounter'}">
  <router-outlet></router-outlet>
</div>