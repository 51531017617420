<div [formGroup]="formGroup" class="control control--input control--multiselect" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <p-multiSelect #primeMultiselect 
    [hidden]="!hasOptions" 
    [options]="options" 
    [filter]="true"
    [showToggleAll]="false" 
    [optionLabel]="optionText" 
    [maxSelectedLabels]="maxSelectedLabels"
    [defaultLabel]="model.placeholder"
    [styleClass]="classes" 
    dataKey="value"
    (onChange)="onChange.emit($event); onInput.emit($event);"
    [id]="model.key" 
    [formControlName]="model.key"
    [(ngModel)]="model.selectedOptions">

  </p-multiSelect>
  <div *ngIf="!hasOptions" class="text--danger">Information Not Available.</div>
</div>
