import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class PendsAttemptHistory {
  readonly userName: string;
  readonly callStartTime: string;
  readonly contactDetail: string;
  readonly callResult: string;
  readonly contactMethodType: string;
  readonly callDuration: string;
  readonly callNote: string;
  readonly callNoteDescription: string;

  constructor(options: {
    userName: string;
    callStartTime: string;
    contactDetail: string;
    callResult: string;
    contactMethodType: string;
    callDuration: string;
    callNote: string;
    callNoteDescription: string;

  }) {
    this.userName = StringHelper.clean(options.userName);
    this.callStartTime = StringHelper.clean(options.callStartTime);
    this.contactDetail = StringHelper.clean(options.contactDetail);
    this.callResult = StringHelper.clean(options.callResult);
    this.contactMethodType = StringHelper.clean(options.contactMethodType);
    this.callDuration = StringHelper.clean(options.callDuration);
    this.callNote = StringHelper.clean(options.callNote);
    this.callNoteDescription = StringHelper.clean(options.callNoteDescription);
  }

}
