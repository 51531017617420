export class UserRoleModel {
  readonly userRoleId: number;
  readonly roleId: number;
  readonly roleName?: string;
  readonly organizationId?: number;
  readonly organizationName?: string;
  readonly clientId?: number;
  readonly clientName?: string;
  readonly projectId?: number;
  readonly projectName?: string;
  readonly roleGroupName?: string;
  readonly roleTypeName?: string;

  constructor(options: {
    userRoleId: number;
    roleId: number;
    roleName?: string;
    organizationId?: number;
    organizationName?: string;
    clientId?: number;
    clientName?: string;
    projectId?: number;
    projectName?: string;
    roleGroupName?: string;
    roleTypeName?: string;
  }) {
    this.userRoleId = options.userRoleId;
    this.roleId = options.roleId;
    this.roleName = options.roleName;
    this.organizationId = options.organizationId;
    this.organizationName = options.organizationName;
    this.clientId = options.clientId;
    this.clientName = options.clientName;
    this.projectId = options.projectId;
    this.projectName = options.projectName;
    this.roleGroupName = options.roleGroupName;
    this.roleTypeName = options.roleTypeName;
  }
}
