export class ChaseSearchRequest {
  constructor(
    readonly sortField: string,
    readonly sortDirection: string,
    readonly chaseId?: number,
    readonly functionalRoleId?: number,
    readonly documentSourceId?: number,
    readonly assignedToUserId?: number,
    readonly memberName?: string,
    readonly memberDateOfBirth?: string,
    readonly providerName?: string,
    readonly providerPhone?: string,
    readonly providerFax?: string,
    readonly maxRecords?: number,
    readonly startRecord?: number,
    readonly endRecord?: number,
    readonly memberFirstName?: string,
    readonly memberLastName?: string,
    readonly documentSourceTypeId?: number,
    readonly pursuit?: string,
    readonly sampleComplianceCodesAsCsv?: string,
    readonly reportingStatusIdsCsv?: string,
    readonly hideOpenPends?: string,
    readonly workFlowStatus?: string
  ) {
    this.sortField = sortField;
    this.sortDirection = sortDirection;
    this.chaseId = chaseId;
    this.functionalRoleId = functionalRoleId;
    this.documentSourceId = documentSourceId;
    this.assignedToUserId = assignedToUserId;
    this.memberName = memberName; // Obsolete // TODO: MemberName is obsoleted to filter chases?
    this.memberDateOfBirth = memberDateOfBirth;
    this.providerName = providerName;
    this.providerPhone = providerPhone;
    this.providerFax = providerFax;
    this.maxRecords = maxRecords;
    this.startRecord = startRecord;
    this.endRecord = endRecord;
    this.memberFirstName = memberFirstName;
    this.memberLastName = memberLastName;
    this.documentSourceTypeId = documentSourceTypeId;
    this.pursuit = pursuit;
    this.sampleComplianceCodesAsCsv = sampleComplianceCodesAsCsv;
    this.reportingStatusIdsCsv = reportingStatusIdsCsv;
    this.hideOpenPends = hideOpenPends;
    this.workFlowStatus = workFlowStatus;
  }
}
