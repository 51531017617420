import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { VendorExclusion } from "../../../api/vendor/vendorExclusion.model";
import { ProjectDocumentType } from "../project-files/project-document-type.enum";
import { AuthorizationLetterAttribute } from "./models/authorization-letter-attribute.model";
import { MappingDocument } from "./models/mapping-document.model";
import { MeasureDocumentsSummary } from "./models/measure-documents-summary.model";
import { Measures } from "./models/measures.model";
import { ProjectAttribute } from "./models/project-attribute.model";
import { ProjectConfigSummary } from "./models/project-config-summary.model";
import { ProviderPacket } from "./models/provider-packet.model";

@Injectable({ providedIn: "root" })
export class ProjectConfigurationService {
  refresh = new Subject<any>();
  constructor(@Inject(BASE_API_URL)
  private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) { }

  refreshFunction() {
    this.refresh.next();
  }

  getProjectAttributes(projectId: number): Observable<ProjectAttribute[]> {
    const url = `${this.baseApiUrl}projectconfiguration/data?projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ProjectAttribute"))
    );
  }

  getProjectAttribute(projectId: number, attributeId: number): Observable<ProjectAttribute> {
    const url = `${this.baseApiUrl}projectconfiguration/data/attribute?projectId=${projectId}&attributeId=${attributeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ProjectAttribute"))
    );
  }

  getProjectAttributeList(projectId: number, attributeIdsAsCsv: string): Observable<ProjectAttribute[]> {
    const url = `${this.baseApiUrl}projectconfiguration/data/attributes?projectId=${projectId}&attributeIdsAsCsv=${attributeIdsAsCsv}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ProjectAttribute"))
    );
  }


  getCodingReviewModeOptionsList(attributeId: number): Observable<ProjectAttribute[]> {
    const url = `${this.baseApiUrl}projectconfiguration/codingreviewmodes?attributeId=${attributeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }


  saveProjectAttributes(projectConfigurationSummary: ProjectConfigSummary, projectName?: string, projectStatus?: number, providerPacketLogo?: FormData) {
    const formData = providerPacketLogo ?? new FormData();
    formData.append("projectConfigurationSummary", JSON.stringify(projectConfigurationSummary));
    formData.append("projectName", projectName);
    formData.append("projectStatus", projectStatus?.toString());
    const url = `${this.baseApiUrl}projectconfiguration/save`;

    return this.http.post(url, formData);
  }

  saveMeasureDocuments(MeasureDocumentsSummaryModel: MeasureDocumentsSummary): Observable<any> {
    const url = `${this.baseApiUrl}projectconfiguration/measuredocuments/save`;
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http
      .post(url, MeasureDocumentsSummaryModel, { headers, observe: "response" })
      .pipe(
        map((res: HttpResponse<any>) => res.ok)
      );
  }

  uploadAuthLetter(formData: FormData): Observable<AuthorizationLetterAttribute[]> {
    const url = `${this.baseApiUrl}projectconfiguration/authorizationletter/upload`;

    return this.http
      .post(url, formData)
      .pipe(
        map(this.automapper.curryMany("default", "AuthorizationLetterAttribute"))
      );
  }

  uploadIVAMappingDocument(formData: FormData): Observable<MappingDocument> {
    const url = `${this.baseApiUrl}projectconfiguration/ivamappingdocument/upload`;

    return this.http
      .post(url, formData)
      .pipe(
        map(this.automapper.curry("default", "MappingDocument"))
      );
  }

  generatePreviewPacket(ProviderPacketModel: ProviderPacket): Observable<string> {
    const url = `${this.baseApiUrl}projectconfiguration/providerpacket/preview`;
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http
      .post(url, ProviderPacketModel, { headers }).pipe(
        map((response: any) => response as string)
      );
  }

  getDataExtractTypes(projectId): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}project/extracttypes?projectId=${projectId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("ExtractType", "SelectableInput"))
    );
  }

  getVendorsByProject(projectId: number): Observable<VendorExclusion[]> {
    return this.http.get(`${this.baseApiUrl}projectconfiguration/vendor?projectId=${projectId}`).pipe(map(this.automapper.curryMany("default", "VendorExclusion")));
  }

  approveOrDenyVendor(vendorExclusion: VendorExclusion, type: string): Observable<null> {
    const url = `${this.baseApiUrl}projectconfiguration/vendor/${type}`;
    return this.http.post(url, vendorExclusion) as Observable<null>;
  }

  downloadFile(projectDocumentId: string, userId: number, fileType: string, projectDocumentTypeId: ProjectDocumentType) {
    this.http.get(
      `${this.baseApiUrl}file/download?fileId=${projectDocumentId}&fileType=${fileType}&userId=${userId}&projectDocumentTypeId=${projectDocumentTypeId}`,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";

            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  getComputedValueForChartsBot(projectId: number): Observable<number> {
    const url = `${this.baseApiUrl}projectconfiguration/chartsbotoverread?projectId=${projectId}`;
    return this.http.get(url) as Observable<number>;
  }
  getComputedValueForChartsFirstPassBot(projectId: number): Observable<number> {
    const url = `${this.baseApiUrl}projectconfiguration/chartsbotfirstpassabstraction?projectId=${projectId}`;
    return this.http.get(url) as Observable<number>;
  }
  getAllMeasureResult(projectId: number): Observable<Measures[]> {
    const url = `${this.baseApiUrl}projectconfiguration/bot/measures?projectId=${projectId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "Measures"))
    );
  }

}
