import { IAutoMapper } from "../../../core/automapper/interfaces";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export const mapEntityError = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "EntityError")
    .forMember("chaseId", o => o.chaseId)
    .forMember("entityId", o => o.entityId)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("projectTypeName", o => o.projectTypeName)
    .forMember("validationMessageType", o => o.validationMessageType)
    .forMember("validationMessage", o => o.validationMessage);
};


export interface IEntityErrorOptions {
  chaseId?: number;
  entityId?: number;
  entityTypeName?: string;
  projectTypeName?: string;
  validationMessageType?: string;
  validationMessage?: string;
}

export class EntityError {
  chaseId: number;
  entityId: number;
  entityTypeName: string;
  projectTypeName: string;
  validationMessageType: string;
  validationMessage: string;


  constructor(options: IEntityErrorOptions = {}) {
    this.chaseId = options.chaseId;
    this.entityId = options.entityId;
    this.entityTypeName = StringHelper.clean(options.entityTypeName);
    this.projectTypeName = StringHelper.clean(options.projectTypeName);
    this.validationMessageType = StringHelper.clean(options.validationMessageType);
    this.validationMessage = StringHelper.clean(options.validationMessage);
  }
}
