import { IAutoMapper } from "../../core/automapper/interfaces";
import { BooleanHelper } from "../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapMenuItems = (automapper: IAutoMapper): void => {

    automapper
        .createMap("default-sub-menu", "MenuItem")
        .forMember("description", o => o.description)
        .forMember("path", o => o.path)
        .forMember("iconName", o => o.iconName)
        .forMember("show", o => o.show)
        .forMember("count", o => o.count);

    automapper
        .createMap("default-sub-menu-with-extra", "MenuItem")
        .forMember("description", o => o.description)
        .forMember("path", o => o.path)
        .forMember("iconName", o => o.iconName)
        .forMember("extra", o => o.extra)
        .forMember("show", o => o.show);

    automapper
        .createMap("default", "MenuItem")
        .forMember("description", o => o.label)
        .forMember("path", o => o.routerLink)
        .forMember("iconName", o => o.icon)
        .forMember("show", o => o.show)
        .forMember("command", o => o.command)
        .forMember("children", o => {
            const isArray = Array.isArray(o.items) && o.items.length > 0;

            return isArray ? automapper.mapMany("default", "MenuItem", o.items) : [];
        });
};

export class MenuItem {
    readonly description: string;
    readonly path: string;
    readonly iconName: string;
    command: any;
    readonly children: MenuItem[];
    show: boolean;
    clicked: boolean;
    isNavigationLink: boolean;
    extra: any;
    count: any;

    constructor(options: {
        description: string;
        path: string;
        iconName?: string;
        command?: any;
        children?: MenuItem[];
        show?: boolean;
        clicked?: boolean;
        isNavigationLink?: boolean;
        extra?: any;
        count?: any;
    }) {
        StringHelper.throwIsAvailableError(options.description, "description");

        this.description = StringHelper.clean(options.description);
        this.path = StringHelper.clean(options.path);
        this.iconName = StringHelper.clean(options.iconName).toLowerCase();
        this.command = options.command;
        this.children = options.children || [];
        this.show = BooleanHelper.tryGet(options.show, true);
        this.clicked = BooleanHelper.tryGet(options.show, false);
        this.isNavigationLink = BooleanHelper.tryGet(options.isNavigationLink, false);
        this.extra = options.extra || {};
        this.count = options.count;
    }

    get hasIconName(): boolean {
        return StringHelper.isAvailable(this.iconName);
    }

    get hasChildren(): boolean {
        return this.children.length > 0;
    }
}
