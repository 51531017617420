import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-risk-project-health",
  templateUrl: "./risk-project-health.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskProjectHealthComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.RISK_PROJECT_HEALTH;

    constructor() {
      this.filters = ["Projects", "Hcc", "Products", "Address"];
    }
}
