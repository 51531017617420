import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { LocalService } from "../../../../../../core/storage/local.service";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { DatesOfServiceValidation } from "../../../../../../shared/dates-of-service/dates-of-service-validation.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../api/member-validation/entity-type.enum";
import { ChaseDetailV2ChartRiskService } from "../../../chase-detail-v2/chase-detail-v2-chart/risk/risk.service";
import { ChaseDetail } from "../../chase-detail.model";
import { DiseaseDetail } from "./diagnosis/disease-detail.model";
import { Encounter } from "./encounter/encounter.model";
import { RiskEntity } from "./risk-entity.model";
import { RiskHelper } from "./risk-helper.model";
import { RiskState } from "./risk-state.model";

@Injectable()
export class RiskService {
  data = new BehaviorSubject<RiskState>(new RiskState());
  nextButton = new Subject();
  isMemberValidate = new BehaviorSubject<any>(null);
  selectedEncounterIndex = new BehaviorSubject<number>(0);

  private dxCardProvider = new Subject();
  dxCardProvider$ = this.dxCardProvider.asObservable();

  private localStorageEncounterAfterScreenSync =  "encounterAfterScreenSync";
  private localStoragePageReload = "isReloadPage";
  private localStorageIsWindowOpen = "isWindowOpen";
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly localService: LocalService
  ) { }

  getData(chaseId: number, isRisk20Project: boolean = false): Observable<ChaseDetail> {
    const url = `${this.baseApiUrl}chase/get/risk/data?chaseId=${chaseId}&isRisk20Project=${isRisk20Project}`;
    return this.http.get(url)
      .pipe(map(this.automapper.curry("default", "ChaseDetail")));
  }

  getDiseaseDetails(chaseId: number, icdCode: string, dateOfService: dateTypes): Observable<DiseaseDetail[]> {
    const url = `${this.baseApiUrl}risk/disease/detail?chaseId=${chaseId}&icdCode=${icdCode}&dateOfService=${DateHelper.format(dateOfService)}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DiseaseDetail"))
    );
  }

  getEncounterTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}risk/encountertypes`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getNextButtonClicked() {
    return this.nextButton.asObservable();
  }

  get isReloadPage(): boolean {
    return  this.localService.get(this.localStoragePageReload, "0") === "0";
   }

  get isDuplicateWindowOpen(): boolean {
    return this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  }

  initData(chaseId: number, isRisk20Project: boolean = false, displayAdminEncounterWithNoHcc: boolean = true, callbackFn: () => void): void {
    if (!this.data.value.hasEncounters) {
      this.getData(chaseId, isRisk20Project).subscribe(chaseDetail => {
        let encounters = chaseDetail.data.filter(entity => entity.entityTypeId === EntityType.ENCOUNTER);
        let encounterIndex = isRisk20Project ? this.chaseDetailV2ChartRiskService.getGroupEncounterIndex(encounters) : 0;
        const screenSyncEncounterIndex = this.localService.get(this.localStorageEncounterAfterScreenSync, null);
        if (screenSyncEncounterIndex !== null && !this.isDuplicateWindowOpen && !this.isReloadPage) {
          encounterIndex = screenSyncEncounterIndex;
          this.localService.delete(this.localStorageEncounterAfterScreenSync);
        }
        encounters = this.filterEncounterForNonHccDx(encounters, displayAdminEncounterWithNoHcc);
        this.setData({
          encounters,
          memberValidation: chaseDetail.data.find(entity => entity.entityTypeId === EntityType.MEMBER_VALIDATION),
          providers: chaseDetail.providers,
          selectedEncounterIndex: encounterIndex,
          workflowStatusName: chaseDetail.workflowStatusName,
          memberVerified: chaseDetail.memberVerified,
        });
        this.updateSelectedEncounterIndex(encounterIndex);
        callbackFn();
      });
    }
  }

  private filterEncounterForNonHccDx(encounters: RiskEntity[], displayAdminEncounterWithNoHcc: boolean): RiskEntity[] {
    const filterEncounter = this.automapper.mapMany("RiskData", "Encounter", encounters);
    return encounters.filter((item, index) => {
      const filterItem = filterEncounter[index];
      const { id, isAdmin, isEve, isCoder, diagnoses } = filterItem;
      const isIdMatch = item.id === id;
      const encounterHasDxs = ArrayHelper.isAvailable(diagnoses);

      const isAdminMatch = displayAdminEncounterWithNoHcc ? isAdmin : isAdmin && encounterHasDxs;

      return isIdMatch && (isAdminMatch || isEve || isCoder);
    });
  }

  loadData(chaseId: number, status: string, isRisk20Project: boolean = false): Observable<ChaseDetail> {
    const url = `${this.baseApiUrl}chase/get/risk/data?chaseId=${chaseId}&workflowStatus=${status}&isRisk20Project=${isRisk20Project}`;
    return this.http.get(url)
      .pipe(map(this.automapper.curry("default", "ChaseDetail")));
  }

  loadDataByStatus(chaseId: number, status: string, isRisk20Project: boolean = false, callbackFn: () => void): void {
    this.loadData(chaseId, status, isRisk20Project).subscribe(chaseDetail => {
      this.setData({
        encounters: chaseDetail.data.filter(entity => entity.entityTypeId === EntityType.ENCOUNTER),
        memberValidation: chaseDetail.data.find(entity => entity.entityTypeId === EntityType.MEMBER_VALIDATION),
        providers: chaseDetail.providers,
        workflowStatusName: chaseDetail.workflowStatusName,
      });
      callbackFn();
    });
  }

  refreshData(chaseId: number, isRisk20Project: boolean = false): void {
    this.getData(chaseId, isRisk20Project).subscribe(chaseDetail => this.setData({
      encounters: chaseDetail.data.filter(entity => entity.entityTypeId === 114),
      memberValidation: chaseDetail.data.find(entity => entity.entityTypeId === 91),
      providers: chaseDetail.providers,
    }));
  }

  isAdminEncounter(encounterIndex: number): boolean {
    if (encounterIndex < 0) {
      return false;
    }

    const encounter = this.data.value.encounters[encounterIndex];
    if (encounter == null) {
      return false;
    }

    const isAdmin = this.automapper.map("RiskData", "Encounter", encounter).isAdmin;
    return isAdmin;
  }

  isAdminDiagnosis(diagnosisIndex: number): boolean {
    if (diagnosisIndex < 0 || !this.data.value.hasSelectedEncounterIndex) {
      return false;
    }

    const diagnosis = this.data.value.selectedEncounter.diagnoses[diagnosisIndex];
    if (diagnosis == null) {
      return false;
    }

    const isAdmin = this.automapper.map("RiskData", "Diagnosis", diagnosis).isAdmin;
    return isAdmin;
  }

  refresh(): void {
    this.data.next(this.data.value);
  }

  setData(data: Partial<RiskState>): void {
    const newData = new RiskState({ ...this.data.value, ...data });
    this.data.next(newData);
  }

  setSelectedEncounterIndex(index: number): void {
    this.setData({
      selectedEncounterIndex: index,
      selectedDiagnosisIndex: null,
    });
    this.updateSelectedEncounterIndex(index);
    this.localService.put(this.localStorageEncounterAfterScreenSync, index);
  }

  setEncounterAttributes(index: number, newAttributes: DynamicEntityAttribute[], providerOptions: SelectableInput[] = null): void {
    const entities = this.data.value.encounters;
    const encounter = this.data.value.encounters[index];
    encounter.attributes = newAttributes;

    const firstAttribute = encounter.attributes.find(attribute => NumberHelper.isGreaterThan(attribute.entityId, 0)) || {} as any;
    encounter.entityId = firstAttribute.entityId;

    if (ArrayHelper.isAvailable(providerOptions)) {
      const providers = providerOptions.map(this.automapper.curry("SelectableInput", "Provider"));
      this.setData({ encounters: entities, providers });
    } else {
      this.setData({ encounters: entities });
    }
  }

  setDiagnosisAttributes(index: number, newAttributes: DynamicEntityAttribute[]): void {
    const entities = this.data.value.encounters;
    const encounter = this.data.value.selectedEncounter;
    const diagnosis = encounter.diagnoses[index];
    diagnosis.attributes = newAttributes;

    const firstAttribute = diagnosis.attributes.find(attribute => NumberHelper.isGreaterThan(attribute.entityId, 0));
    diagnosis.entityId = firstAttribute.entityId;
    this.setData({ encounters: entities });
  }

  addNewEncounter(): RiskEntity {
    const chaseId = this.data.value.chaseId;
    const entityTypeId = EntityType.ENCOUNTER;
    const entityTypeName = "Encounter";
    const newEncounter = new RiskEntity({
      chaseId,
      entityTypeId,
      entityTypeName,
    });
    const selectedEncounterIndex = this.data.value.encounters.length;
    const selectedDiagnosisIndex = null;

    const encounters = this.data.value.encounters;
    encounters.push(newEncounter);

    this.setData({
      encounters,
      selectedEncounterIndex,
      selectedDiagnosisIndex,
    });
    this.updateSelectedEncounterIndex(selectedEncounterIndex);
    return newEncounter;
  }

  addNewDiagnosis(): RiskEntity {
    const encounters = this.data.value.encounters;
    const encounter = this.data.value.selectedEncounter;
    const chaseId = this.data.value.chaseId;
    const entityTypeId = EntityType.DIAGNOSIS;
    const parentEntityId = encounter.entityId;

    const startDate = RiskHelper.getAttribute("StartDate", encounter).value as string;
    const endDate = RiskHelper.getAttribute("EndDate", encounter).value as string;
    const providerSourceAliasID = RiskHelper.getAttribute("ProviderSourceAliasID", encounter).value;

    const attributes = [
      {attributeId: 29, attributeCode: "StartDate", value: DateHelper.format(startDate)},
      {attributeId: 30, attributeCode: "EndDate", value: DateHelper.format(endDate)},
      {attributeId: 223, attributeCode: "ProviderSourceAliasID", value: providerSourceAliasID},
    ];

    const newDiagnosis = new RiskEntity({
      chaseId,
      entityTypeId,
      parentEntityId,
      entityTypeName: "Diagnosis",
      attributes: attributes.map(attr => ({
        ...attr,
        chaseId,
        entityTypeId,
        parentEntityId,
        isAdmin: false,
      }) as any),
    });

    const selectedDiagnosisIndex = encounter.diagnoses.length;
    encounter.diagnoses.push(newDiagnosis);

    this.setData({
      encounters,
      selectedDiagnosisIndex,
    });

    return newDiagnosis;
  }

  deleteEncounter(encounterIndex: number, isEveEncounter: boolean = false): void {
    if (this.isAdminEncounter(encounterIndex)) {
      return;
    }

    const encounters = this.data.value.encounters;
    if (!isEveEncounter) {
      encounters.splice(encounterIndex, 1);
    }

    this.setData({
      encounters,
    });
  }

  deleteDiagnosis(diagnosisIndex: number): void {
    if (this.isAdminDiagnosis(diagnosisIndex)) {
      return;
    }

    const encounters = this.data.value.encounters;
    const encounter = this.data.value.selectedEncounter;
    const newSelectedDiagnosisIndex = this.data.value.selectedDiagnosisIndex === diagnosisIndex ? diagnosisIndex : this.data.value.selectedDiagnosisIndex;

    encounter.diagnoses.splice(diagnosisIndex, 1);
    this.setData({
      encounters,
      selectedDiagnosisIndex: newSelectedDiagnosisIndex,
    });
  }

  save(attributes: DynamicEntityAttribute[], isRisk20Project = false): Observable<DynamicEntityAttribute[]> {
    const url = `${this.baseApiUrl}risk/save?isRisk20Project=${isRisk20Project}`;
    return this.http.post(url, attributes) as Observable<DynamicEntityAttribute[]>;
  }

  clearEncounter(attributes: DynamicEntityAttribute[]): Observable<null> {
    const url = `${this.baseApiUrl}risk/clearencounter`;
    return this.http.post(url, attributes) as Observable<null>;
  }

  getEncounters(chaseId: number, workflowStatusName: string = null): Observable<Encounter[]> {
    const url = `${this.baseApiUrl}risk/encounters?chaseId=${chaseId}&workflowStatusName=${status}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "Encounter"))
    );
  }

  updateSelectedEncounterIndex(selectedEncounterIndex: number): void {
    this.selectedEncounterIndex.next(selectedEncounterIndex);
  }

  submitEncounterValidation(encValidationData: DatesOfServiceValidation, status: string): Observable<null> {
    const url = `${this.baseApiUrl}risk/submitencountervalidation?status=${status}`;
    const data = encValidationData.saveDatesOfServiceValidationInfo();
    return this.http.post(url, data) as Observable<null>;
  }

  updateDxCardProviders(providers: any): void {
    this.dxCardProvider.next(providers);
  }
}
