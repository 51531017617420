import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { MemberTimelineItem } from "../../member-timeline-item.model";
import { ChaseDetailTimelineService } from "./chase-detail-timeline.service";

@Component({
  selector: "member-chase-detail-timeline",
  template: `<app-timeline-detail [timeline]="timelineItems" [isChaseTimeline]="true"></app-timeline-detail>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailTimelineComponent implements OnInit {
  timelineItems = List<MemberTimelineItem>();

  constructor(
    private service: ChaseDetailTimelineService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getTimelines();
  }

  private getTimelines(): void {
    this.service.getChaseTimelineActivityItems().subscribe(timelines => {
      const groupedTimelines = this.mapToGroupedTimelines(timelines);
      this.timelineItems = List(groupedTimelines);
      this.timelineItems.filter(this.addDocIntakeUrlInDocumentId);
      this.changeDetector.markForCheck();
    });
  }

  private mapToGroupedTimelines(timelines: TimelineItem[]): MemberTimelineItem[] {
    const timelineList = timelines.reduce((timeLineGroup, timeLineItem) => {
      const specificDay = DateHelper.format(timeLineItem.timestamp);
      timeLineGroup[specificDay] = timeLineGroup[specificDay] || [];
      timeLineGroup[specificDay].push(timeLineItem);
      return timeLineGroup;
    },                                    {});

    const groupedTimelines = Object.keys(timelineList).map(specificDay => {
      return new MemberTimelineItem({
        specificDay,
        timelineGroup: timelineList[specificDay],
      });
    });
    return groupedTimelines;
  }

  private addDocIntakeUrlInDocumentId(timeline) {
    const docIntakeRoute = "/retrieval/review/intake/";

    const docInTakeAddedUrlTimeline = timeline.timelineGroup.map(item => {
      if (item.type === "file") {
        let note = item.note.replace(/\s+/g, " ").trim();
        const noteWithDocId = note;
        note = note.split(" ");

        if (ArrayHelper.isAvailable(note)) {
          const docId = note[note.length - 1];
          const fileTypeName = note[note.length - 2];
          if (NumberHelper.isAvailable(Number(docId)) && fileTypeName.toLowerCase === "DocumentQueueID".toLowerCase) {
            item.note = `${(noteWithDocId.substring(0, noteWithDocId.lastIndexOf(" ")))}`;
            item.urlPath = docIntakeRoute + docId;
            item.urlText = docId;
          }
        }
      }
    });
    return docInTakeAddedUrlTimeline;
  }
}
