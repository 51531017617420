import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { LandingFilterStateService } from "../../kpi/kpi-filter/landing-filter/landing-filter-state.service";
import { LandingFilter } from "../../kpi/model/landingFliter.model";
import { ListItem } from "../../list/list-item";

@Component({
  selector: "app-header-statistics",
  templateUrl: "./header-statistics.component.html",
  styleUrls: ["./header-statistics.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderStatisticsComponent {

  @Input() headerStatistics = List<ListItem>();
  @Input() landingFilter: LandingFilter;

  private selectedStat = "";

  constructor(
    private router: Router,
    private readonly landingFilterStateService: LandingFilterStateService
  ) { }

  navigateToUrl(url: string, statKey: string) {
    this.selectedStat = statKey;
    if (this.landingFilter != null) {
      this.landingFilterStateService.put(this.landingFilter);
    }

    this.router.navigateByUrl(url);

  }

  trackByIndex(index, item) {
    return index;
  }

  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }
}

