<div class="chase-detail-v2" [ngClass]="{'chase-detail-v2__data-entry': showDataEntry}">
  <div class="chase-detail-v2__header">
    <member-chase-detail-v2-header></member-chase-detail-v2-header>
  </div>
  <div class="chase-detail-v2__body"[ngClass]="{'chase-detail-v2__body--with-navigation': isMainHeaderVisible}" >
    <router-outlet></router-outlet>
  </div>
  <div class="chase-detail-v2__footer">
    <member-chase-detail-v2-footer (showDataEntry)="showEntry($event)" (isMainHeaderVisible)="checkMainHeaderVisible($event)"></member-chase-detail-v2-footer>
  </div>
</div>