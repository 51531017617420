import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";

const routes: Routes = [
  { path: "", redirectTo: "/clinical/audit/auditquery", pathMatch: "full" , canActivate: [AuthGuard]},
  { path: "auditquery", redirectTo: "/clinical/audit/auditquery", canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuditRoutingModule { }
