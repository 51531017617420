import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { List } from "immutable";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { debounceTimeAfterFirst } from "../../../../../utilities/debounce-time-after";
import { AddressTimelineStateService } from "../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../address-timeline/address-timeline.service";
import { RetrievalTimelineItem } from "../../retrieval-timeline-item.model";
import { AddressDetailStateService } from "../address-detail-state.service";

@Component({
  selector: "app-address-detail-timeline-new",
  template: `<app-timeline-detail [timeline]="timelineItems"></app-timeline-detail>`,
  styleUrls: ["./address-detail-timeline.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailTimelineComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  timelineItems = List<RetrievalTimelineItem>();
  addressId: number;
  previousAddressId = 0;

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // TODO: Reset timelines when addressId changes.
    this.sink.add(
      combineLatest([
        this.addressDetailStateService.state,
        this.addressTimelineStateService.timelineItems,
      ])
        .pipe(debounceTimeAfterFirst(50))
        .subscribe(([state, timelineItems]) => {
          if (ArrayHelper.isAvailable(timelineItems)) {
            const groupedTimelines = this.mapToGroupedTimelines(timelineItems);
            this.timelineItems = List(groupedTimelines);
            this.cd.markForCheck();
          } else if (state.hasMasterDocumentSourceId) {
            this.fetchTimelineItems(state.masterDocumentSourceId);
          }
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private fetchTimelineItems(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  private mapToGroupedTimelines(timelines: TimelineItem[]): RetrievalTimelineItem[] {
    const timelineList = timelines.reduce((timeLineGroup, timeLineItem) => {
      const specificDay = DateHelper.format(timeLineItem.timestamp);
      timeLineGroup[specificDay] = timeLineGroup[specificDay] || [];
      timeLineGroup[specificDay].push(timeLineItem);
      return timeLineGroup;
    },                                    {});

    const groupedTimelines = Object.keys(timelineList).map(specificDay => {
      return new RetrievalTimelineItem({
        specificDay,
        timelineGroup: timelineList[specificDay],
      });
    });
    return groupedTimelines;
  }
}
