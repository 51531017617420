import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { CopyChartService } from "../../../platform/api/copy-chart/copy-chart.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { GridColumnDefinition } from "../../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../grid/models/grid-configuration.model";

@Component({
  selector: "app-copy-chart-modal",
  templateUrl: "./copy-chart-modal.component.html",
  styleUrls: ["./copy-chart-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyChartModalComponent {
  chaseGridConfiguration = new GridConfiguration();
  chaseGridData: any[] = [];
  chaseGridSelection: any[];
  chaseId: number;
  selectedChaseId: number[];
  private pMasterMemberId: string;
  @Input()
  get masterMemberId(): string {
    return this.pMasterMemberId;
  }
  set masterMemberId(value: string) {
    this.pMasterMemberId = value;
  }
  private pDataSet: string;
  @Input()
  get dataSet(): string {
    return this.pDataSet;
  }
  set dataSet(value: string) {
    this.pDataSet = value;
  }
  private Visible = false;

  @Input()
  get visible(): boolean {
    return this.Visible;
  }
  set visible(value: boolean) {
    this.Visible = value;
    this.visibleChange.emit(this.visible);
    if (!this.visible) {
        this.closing();
    }
  }

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);

  private pChases = [];
  @Input()
  get chases(): any {
    return this.pChases;
  }
  set chases(value: any) {
    this.pChases = value;
    this.chasesChange.emit(this.chases);
    if (this.pMasterMemberId && this.pDataSet) {
      this.getChasesByMasterMemberId();
    } else {
      this.getChaseListById();
     }
   }

  get chaseIds(): number {
    if (!ArrayHelper.isAvailable(this.chases)) {
      return;
    }

    return this.chases.map(chase => +chase.chaseId || chase.chaseID);
  }
  @Output() chasesChange = new EventEmitter<number[]>();

  chaseIdList: number[] = [];
  rowData: any;

  constructor(
    private readonly copyChartService: CopyChartService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService
  ) {
    this.chaseGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseID", header: "Chase" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "memberName", header: "Member Name"}),
      new GridColumnDefinition({ field: "serviceProviderName", header: "Provider Name" }),
    ];

    this.chaseGridConfiguration.pageSize = 10;
    this.chaseGridConfiguration.selectionMode = "multiple";
    this.chaseGridConfiguration.showActionColumn = false;
  }

  private getChaseListById(): void {
    this.chaseGridSelection = [];
    this.copyChartService.clearChaseGrid();
    if (this.visible && ArrayHelper.isAvailable(this.chases)) {
      this.copyChartService.getChaseListById(this.chaseIds)
        .subscribe(list => {
          this.chaseGridData = list as any;
          this.changeDetector.markForCheck();
        });
    }
  }
  private getChasesByMasterMemberId(): void {
    this.chaseGridConfiguration.columns.map(x => { x.show = x.field === "memberName" ? false : x.show; return x; });
    this.chaseGridSelection = [];
    this.copyChartService.clearChaseGrid();
    if (this.visible) {
      this.copyChartService.getChaseListByMasterMemberId(this.pMasterMemberId, this.chaseIds, this.pDataSet)
        .subscribe(list => {
          this.chaseGridData = list as any;
          if (ArrayHelper.toArray(this.chaseGridData).length === 0) {
            this.messagingService.showMessage("No matching member chases available to copy this chart to", SeverityType.ERROR);
          }
          this.changeDetector.markForCheck();
        });
    }
  }

  onCopyChart(rowData?: any): void {
    this.chaseIdList = [];
    if (rowData !== undefined) {
      const selectedRows = rowData;
      this.chaseIdList.push(selectedRows.chaseID);
    } else {
      const selectedRows = this.chaseGridSelection;
      if (selectedRows.length > 0) {
        selectedRows.forEach(item => {
          this.chaseIdList.push(item.chaseID);
        });
      } else {
        this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
        return;
      }
    }
    this.copyChartToAnotherChase();
    this.visible = false;
    this.chaseGridSelection = [];
  }

  private copyChartToAnotherChase(): void {
    this.copyChartService.copyChartToAnotherChase(this.chaseIds, this.chaseIdList)
      .subscribe(
        data => {
          if (data > 0) {
            this.messagingService.showToast("Chart copied Successfully.", SeverityType.SUCCESS);
            this.changeDetector.markForCheck();
            this.copyChartService.clearChaseGrid();
          } else {
            this.messagingService.showToast("Failed to copy", SeverityType.ERROR);
            this.changeDetector.markForCheck();
            this.copyChartService.clearChaseGrid();
          }
      });
  }

  private closing(): void {
    this.copyChartService.clearChaseGrid();
    this.chaseGridData = [];
    this.chaseIdList = [];
    this.chaseGridSelection = [];
    this.onHide.emit();
  }
  get isChaseSelected(): boolean {
    return this.chaseGridSelection.length === 0 ? true : false;

  }
}

