import { CHART_PAGE_NUMBER, MEMBER_DATE_OF_BIRTH, MEMBER_VALIDATION_REASON, MEMBER_VALIDATION_RESULT } from "../../modules/member/chase-detail/chase-detail-chart/attributes";
import { DynamicEntityAttribute } from "./dynamic-entity-attribute.model";
import { EntityType } from "./entity-type.enum";

export class MemberValidation {
  readonly isValidInfo: DynamicEntityAttribute;
  readonly reasonInfo: DynamicEntityAttribute;
  readonly pageNumberInfo: DynamicEntityAttribute;
  readonly dateOfBirth: DynamicEntityAttribute;
  readonly dateOfBirthValidationResult: DynamicEntityAttribute;
  readonly genderFoundValidationResult: DynamicEntityAttribute;

  constructor(options: {
    isValidInfo?: DynamicEntityAttribute;
    reasonInfo?: DynamicEntityAttribute;
    pageNumberInfo?: DynamicEntityAttribute;
    dateOfBirth?: DynamicEntityAttribute;
    dateOfBirthValidationResult?: DynamicEntityAttribute;
    genderFoundValidationResult?: DynamicEntityAttribute;
  } = {}) {
    this.isValidInfo = options.isValidInfo || new DynamicEntityAttribute();
    this.isValidInfo.attributeId = MEMBER_VALIDATION_RESULT.attributeId;
    this.isValidInfo.attributeCode = MEMBER_VALIDATION_RESULT.attributeCode;
    this.isValidInfo.entityTypeId = EntityType.MEMBER_VALIDATION;

    this.reasonInfo = options.reasonInfo || new DynamicEntityAttribute();
    this.reasonInfo.attributeId = MEMBER_VALIDATION_REASON.attributeId;
    this.reasonInfo.attributeCode = MEMBER_VALIDATION_REASON.attributeCode;
    this.reasonInfo.entityTypeId = EntityType.MEMBER_VALIDATION;

    this.pageNumberInfo = options.pageNumberInfo || new DynamicEntityAttribute();
    this.pageNumberInfo.attributeId = CHART_PAGE_NUMBER.attributeId;
    this.pageNumberInfo.attributeCode = CHART_PAGE_NUMBER.attributeCode;
    this.pageNumberInfo.entityTypeId = EntityType.MEMBER_VALIDATION;

    this.dateOfBirth = options.dateOfBirth || new DynamicEntityAttribute();
    this.dateOfBirth.attributeId = MEMBER_DATE_OF_BIRTH.attributeId;
    this.dateOfBirth.attributeCode = MEMBER_DATE_OF_BIRTH.attributeCode;
    this.dateOfBirth.entityTypeId = EntityType.MEMBER_VALIDATION;

    this.dateOfBirthValidationResult = options.dateOfBirthValidationResult;
    this.genderFoundValidationResult = options.genderFoundValidationResult;
  }


  get isValid(): boolean {
    return this.isValidInfo.value == null
      ? null
      : (this.isValidInfo.value === "true"
        || this.isValidInfo.value === true
        || this.isValidInfo.value === "1"
        || this.isValidInfo.value === 1);
  }
  set isValid(value: boolean) {
    this.isValidInfo.value = value;
  }

  get reason(): string {
    return this.reasonInfo.value as string || "";
  }
  set reason(value: string) {
    this.reasonInfo.value = value;
  }

  get pageNumber(): number {
    if (typeof this.pageNumberInfo.value === "string") {
      return parseInt(this.pageNumberInfo.value, 10);
    }

    if (typeof this.pageNumberInfo.value === "number") {
      return this.pageNumberInfo.value;
    }

    return null;
  }
  set pageNumber(value: number) {
    this.pageNumberInfo.value = value;
  }

  get dob(): string {
    return this.dateOfBirth.value as string || "";
  }
  set dob(value: string) {
    this.dateOfBirth.value = value;
  }

  saveInfo(): DynamicEntityAttribute[] {
    const isValidInfo = { ...this.isValidInfo, options: null };
    const reasonInfo = { ...this.reasonInfo, options: null };
    const pageNumberInfo = { ...this.pageNumberInfo, options: null };
    const dateOfBirth = { ...this.dateOfBirth, options: null };

    return [isValidInfo, reasonInfo, pageNumberInfo, dateOfBirth];
  }
}
