import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ListItem } from "./list-item";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  @Input() items: ListItem[] = [];

  trackByIndex(index, item) {
    return index;
  }
}
