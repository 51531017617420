import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapDocumentUploadResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentUploadResponse")
    .forMember("documentQueueId", o => o.documentQueueId)
    .forMember("stateMachineRequestId", o => o.stateMachineRequestId)
    .forMember("status", o => o.status)
    .forMember("message", o => o.message);
};
export class DocumentUploadResponse {
  documentQueueId: number;
  stateMachineRequestId: string;
  status: string;
  message: string;


  constructor(options: {
    documentQueueId?: number;
    stateMachineRequestId?: string;
    status?: string;
    message?: string;

  }) {
    this.documentQueueId = options.documentQueueId;
    this.stateMachineRequestId = options.stateMachineRequestId;
    this.status = options.status;
    this.message = options.message;
  }
}
