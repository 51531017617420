import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AddressDetailCallService } from "./address-detail-call.service";


@Component({
  selector: "retrieval-address-detail-call",
  templateUrl: "./address-detail-call.component.html",
  styleUrls: ["./address-detail-call.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDetailCallComponent {

  addressId: number;
  address = "address";
  callDirection: string;

  constructor(
    private service: AddressDetailCallService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.addressId = this.service.getAddressGdFromPath();
    this.callDirection = this.service.getCallTypeFromPath();
    }

}
