<app-modal class="manage-retriever-container"
           [(visible)]="visible"
           header="CHANGE RETRIEVER"
           (onCancel)="cancel()">

           <div class="entity-count-label">TOTAL CHASE: {{ totalEntityCount }}</div>

           <br />

  <div class="retriever-container">
    <form [formGroup]="manageRetrieverForm">
      <form-factory [formGroup]="manageRetrieverForm"
                    [model]="retrieverInput"
                    ></form-factory>

    </form>
  </div>

  <footer>
    <app-button classes="secondary"
                text="CONFIRM" [disabled]="!isValid"
                (onClick)="onRetrieverConfirm()">
    </app-button>
  </footer>
</app-modal>