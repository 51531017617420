import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { DynamicControl } from "../../../../../../dynamic-forms/dynamic-control.model";
import { NegativeReasonCodeGroup } from "../../../../../../dynamic-forms/form-groups/negative-reason-code-group/negative-reason-code-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { ChartService } from "../chart.service";

@Component({
  selector: "member-compliance",
  templateUrl: "./compliance.component.html",
  styleUrls: ["./compliance.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplianceComponent implements OnInit, AfterViewInit, OnDestroy {
  private sink = new SubSink();
  private measuresToHideGrid = ["FPC"];
  private isComplianceDataLoaded = false;
  @ViewChild("exemptColumn") exemptColumn: TemplateRef<any>;
  @ViewChild("complianceColumn") complianceColumn: TemplateRef<any>;
  @Input() enabled = true;

  private pFormGroup: FormGroup;
  @Input()
  get formGroup(): FormGroup {
    return this.pFormGroup;
  }
  set formGroup(value: FormGroup) {
    this.pFormGroup = value;
    this.setNrcInfo();
  }

  private pFormModels: DynamicControl[];
  @Input()
  get formModels(): DynamicControl[] {
    return this.pFormModels;
  }
  set formModels(value: DynamicControl[]) {
    this.pFormModels = value;
    this.setNrcInfo();
  }

  gridConfiguration: GridConfiguration = new GridConfiguration();
  data = [];
  onChangeSubscription: Subscription;
  isLoading = false;
  chaseId: number;
  chaseDetailState: ChaseDetailState;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chartService: ChartService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly formService: FormService,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        if (this.chaseId !== state.chaseId) {
          this.isComplianceDataLoaded = false;
          this.chaseId = state.chaseId;
        }
        if (this.chaseDetailState.hasMember || !this.getChaseGdFromPath()) {
          if (!this.isComplianceDataLoaded) {
            this.getStartingComplianceGridAsync();
          }
          this.gridConfiguration.showMenu = false;
          this.gridConfiguration.showExportAction = false;
          this.gridConfiguration.showActionColumn = false;
          this.changeDetector.markForCheck();
        }
      })

    );

    this.onChangeSubscription = this.subscribeToChartServiceChangeEvent();
  }

  ngAfterViewInit() {
    setTimeout(() => { this.setNrcInfo(); }, 3000);
  }

  ngOnDestroy() {
    this.onChangeSubscription.unsubscribe();
    this.sink.unsubscribe();
  }

  get hasData(): boolean {
    return ArrayHelper.isAvailable(this.data);
  }

  get measureCode(): string {
    if (!this.hasData) {
      return "";
    }

    const firstNumerator = this.data[0];
    return firstNumerator.measureCode;
  }

  get showForNormalMeasures(): boolean {
    if (!StringHelper.isAvailable(this.measureCode)) {
      return true;
    }

    const hide = this.measuresToHideGrid.includes(this.measureCode);
    return !hide;
  }

  private getStartingComplianceGridAsync(): void {
    this.isLoading = true;
    this.changeDetector.markForCheck();
    this.isComplianceDataLoaded = true;
    this.chartService.getStartingCompliance(this.chaseId)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      }))
      .subscribe((items: any[]) => {
        this.data = items;
        this.setGridColumns();
        this.gridConfiguration.pageSize = this.data.length;
        this.setNrcInfo();
        this.changeDetector.markForCheck();
      });
  }

  private getComplianceGridAsync(): void {
    this.isLoading = true;
    this.isComplianceDataLoaded = true;
    this.changeDetector.markForCheck();

    this.chartService.getCompliance(this.chaseId)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      }))
      .subscribe((items: any[]) => {
        this.data = items;
        this.gridConfiguration.pageSize = this.data.length;
        this.setNrcInfo();
        this.changeDetector.markForCheck();
      });
  }

  setNrcInfo(): void {
    if (ArrayHelper.isAvailable(this.formModels) && this.formGroup != null && ArrayHelper.isAvailable(this.data)) {
      this.formModels
        .filter(model => (model.controlType === "negative-reason-code-group" || model.controlType === "conditional-nrc-group"))
        .map((model: NegativeReasonCodeGroup) => model.nrcControl)
        .forEach(this.setNrcDisabled);
      this.formService.updateDom.next();
    }
  }

  private setNrcDisabled = (model: Autocomplete): void => {
    const modelLabel = model.label.split("-")[1].trim();
    const masterKey = model.getMasterKey();
    const control = this.formGroup.get(masterKey);

    const relevantItems = this.data.filter(item =>
    item.numeratorId !== 0 && modelLabel === item.numeratorName.split(">" || "<")[0].trim()
    );

    const hasComplianceCodeC = relevantItems.some(item =>
      item.chaseCompliance.code[0] === "C"
    );

    if (hasComplianceCodeC) {
      control?.setValue(null); // TODO: Implement logic for <7, <8, and >9
      model.disabled = true;
      control?.disable();
    } else {
      model.disabled = false;
      control?.enable();
    }
    this.changeDetector.markForCheck();
  }

  private setGridColumns(): void {
    this.gridConfiguration.columns = [
      new GridColumnDefinition({
        field: "numeratorName",
        header: "Gap",
        isSortableColumn: false,
        width: "170px",
      }),
      new GridColumnDefinition({
        template: this.complianceColumn,
        header: "Gap Compliance",
        isSortableColumn: false,
        styles: this.getComplianceColor,
      }),
      new GridColumnDefinition({
        field: "sampleCompliance.code",
        header: "Sample Gap Compliance",
        isSortableColumn: false,
        styles: this.getComplianceColor,
      }),
      new GridColumnDefinition({
        field: "chaseCompliance.negativeReasonCodeName",
        header: "NRC",
        isSortableColumn: false,
      }),
    ];

    if (this.chaseDetailState.isOverread || this.chaseDetailState.isOverread2) {
      this.gridConfiguration.columns.push(
        new GridColumnDefinition({
          header: "Exempt",
          template: this.exemptColumn,
          isSortableColumn: false,
          width: "70px",
        })
      );
    }

  }

  private getComplianceColor(value: string): object {
    if (!StringHelper.isAvailable(value)) {
      return { color: "inherit" };
    }

    const isCompliant = value.split("/")[0] === "C";
    const color = isCompliant ? "#1BB934" : "#ED1C24";
    return { color };
  }

  private subscribeToChartServiceChangeEvent() {
    return this.chartService.onChangeSubscribe(() => {
      this.getComplianceGridAsync();
    });
  }

  private getChaseGdFromPath(): number {
    return this.route.snapshot.parent.params.chaseGd;
  }

  showAdminComplianceFlag(rowData): boolean {
    return rowData.chaseCompliance.details === "Compliance forced by dataload";
  }
}
