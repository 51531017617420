import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { DateHelper } from "../../../../../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../../utilities/reg-Ex-Helper";
import { Diagnosis } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { DiagnosisService } from "../diagnosis.service";

@Component({
  selector: "app-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() diagnosis: Diagnosis;
  @Input() badgeStatus: string;
  @Input() isCardOpen = false;
  @Input() hasDeleteEnabled = false;
  @Input() showBadge = false;
  @Output() onDelete = new EventEmitter<MouseEvent>();

  private sink = new SubSink();
  private pipe = new DatePipe("en-US");
  private dateFormat = "MM/dd/yyyy";

  disease$: Observable<DiseaseDetail[]>;

  badges = {
    substantiated: "Substantiated",
    unsubstantiated: "Unsubstantiated",
    validated: "Validated",
    notValid: "Not Valid",
    review: "Review",
    reviewing: "Reviewing",
    add_dx: "Adding New Dx",
  };
  chaseDetailState: ChaseDetailState;


  constructor(
    private diagnosisService: DiagnosisService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService
  ) { }

  ngOnInit(): void {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnChanges(): void {
    if (!this.diagnosis.isEveDiagnosis) {
      this.disease$ = this.diagnosisService.getHccForAdmin$(this.diagnosis);
    }
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  getICDCode(code: string): string {
    const specificityDelimiter = ".";
    if (StringHelper.isAvailable(code) && RegExHelper.isValidICDCodePattern.test(code)) {
      return `${code.substring(0, 3)}${specificityDelimiter}${code.substring(3)}`;
    }
    return code;
  }
  onDeleteClick($event: MouseEvent): void {
    this.onDelete.emit($event);
  }

  isConfidenceScoreFound(diagnosis: Diagnosis): boolean {
    return StringHelper.isAvailable(diagnosis.confidenceScore);
  }

  isCodingModelAvailable(diagnosis: Diagnosis): boolean {
    return diagnosis.hccs.includes(":") || diagnosis.rxHcc.includes(":"); // Workaround for backward compatibility with old coder dx
  }

  get isBold(): string {
    return this.isCardOpen ? "card-header__focus-text" : "card-header__normal-text";
  }

  get dos(): string {
    if (this.diagnosis.isEveDiagnosis && this.isConfidenceScoreFound(this.diagnosis)) {
      return DateHelper.isAvailable(this.diagnosis.dosFrom) ? this.diagnosis.dosFrom : "";
    } else {
      const endDateTemplate = DateHelper.isAvailable(this.diagnosis?.endDate) ? ` -  ${this.pipe.transform(this.diagnosis.endDate, this.dateFormat)}` : "";
      return `
      ${DateHelper.isAvailable(this.diagnosis.startDate) ? this.pipe.transform(this.diagnosis.startDate, this.dateFormat) : ""}
      ${endDateTemplate}`;
    }
  }

  get vrcs(): string {
    return StringHelper.isAvailable(this.diagnosis.vrcs) && this.chaseDetailState.isVrcRequired ? `VRC: ${this.diagnosis.vrcs.split(",").join(", ")}` : "";
  }

  get eveIconClass(): string {
    return this.diagnosis.isEveMatch ? "card-header__eveIcon--isEve" : "card-header__eveIcon--notEve";
  }

  get isEveFoundDiagnosis(): boolean {
    return this.diagnosis.isEveFoundDiagnosis;
  }

  get showEveIcon(): boolean {
    return !this.isEveFoundDiagnosis
      && !this.diagnosis.isManuallyAddedSource
      && !(this.chaseDetailState.isOverread || this.chaseDetailState.isOverread2)
      && this.chaseDetailState.isProjectEnabledForRiskNlp;
  }

  get codingLevel(): string {
    return this.chaseDetailState?.projectConfiguration?.codingLevel ?? "";
  }

  codingModelLabel(diagnosis: Diagnosis): string {
    return this.diagnosisService.displayCodingModelLabel(diagnosis);
  }

  hccLabel(diseases: DiseaseDetail[]): string {
    return this.diagnosisService.displayHccLabel(diseases);
  }
}
