import { IAutoMapper } from "../interfaces";

export const mapBulkUpdateItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "BulkUpdate")
    .forMember("clientId", o => o.clientId)
    .forMember("status", o => o.status)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("chaseIds", o => o.chaseIds)
    .forMember("notes", o => o.notes)
    .forMember("bulkType", o => o.bulkType)
    .forMember("bulkAction", o => o.bulkAction)
    .forMember("owner", o => o.owner)
    .forMember("reason", o => o.reason);

};
