import { IAutoMapper } from "../interfaces";

export const mapClinicalQueueItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClinicalQueueListItem")
    .forMember("srNo", o => o.recordNumber)
    .forMember("chaseID", o => o.chaseID)
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberDateOfBirth", o => o.memberDateOfBirth)
    .forMember("gender", o => o.memberGender)
    .forMember("providerName", o => o.providerName)
    .forMember("projectName", o => o.projectName)
    .forMember("measure", o => o.measureCode);

};
