import { IAutoMapper } from "../interfaces";

export const mapRoleModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "RoleModel")
    .forMember("roleId", o => o.roleId)
    .forMember("roleName", o => o.roleName)
    .forMember("templateType", o => o.templateType)
    .forMember("groupName", o => o.groupName)
    .forMember("roleTypeName", o => o.roleTypeName)
    .forMember("selected", o => false);


};
