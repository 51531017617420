import { Encounters } from "../encounters.model";
import { MeasureAttributeData } from "../measure-attribute-data.model";

export class NewChaseProjectItem {
  chaseKey: number;
  projectId: number;
  projectName: string;
  measureId?: number;
  measureCode: string;
  measureAttributeData?: MeasureAttributeData[];
  productName: string;
  encounters?: Encounters[];
  lineOfBusiness?: string;
  sampleId?: string;
  sequence?: number;
}
