<div *ngFor="let item of items; let i = index; trackBy: trackByIndex">
  <div class="contact-history-container">
    <div class="iconContainer">
      <app-icon-stack [iconName]="item.type" iconBase="circle"></app-icon-stack>
    </div>
    <div class="container">
      <div>
        <div class="container__title">
          {{ item.title }}
        </div>
        <div class="float-right">
            <div *ngIf="isCall(item.contactMethodTypeId)">
                <retrieval-recording-playback [recordingData]="item"></retrieval-recording-playback>
            </div>        
            <div *ngIf="isNotCall(item.contactMethodTypeId)">
                <app-download-provider-packet *ngIf="!item.isRetryFax" [providerPacketData]="item"></app-download-provider-packet>
    
                <app-icon [iconName]="retryIcon" title="Retry Fax"
                    [iconStyle]="retryIcon" *ngIf="item.isRetryFax" (click)="retryFailedFax(item.contactLogId)"></app-icon>
            </div>
        </div>
      </div>
      <div class="container__notes" *ngIf="!isNotes(item.contactMethodTypeId)">
        <pre>{{getNotes(item.note)}}</pre>
      </div>
      <div>by {{ organizationName }} - {{ item.userName }}</div>
      <div>{{ item.callStartTime }}</div>
      <div *ngIf="isCall(item.contactMethodTypeId)">
        <div *ngIf="item.isSuccessful">Spoke to: {{item.spokeTo}}</div>
      </div>
      <div *ngIf="hasNextCallDate(item)">
        <span>Next Call Date: {{item.nextCallDate}}</span>
        <br>
      </div>
      <div *ngIf="hasCallNotes(item)">
        <span class="container__commitment" *ngIf="isMethodTypeCall(item.contactMethodTypeId)">Commitment Date : {{showCommitmentDate(item)}}</span>
        Note: {{showCallNotes(item)}}
      </div>
      <div *ngIf="hasStatus(item)" [innerHTML]="showStatus(item)"></div>
    </div>
  </div>
</div>
