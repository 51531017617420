import { MeasureDocuments } from "./measure-documents.model";

export class MeasureDocumentsSummary {
    projectId: number;
    isDocumentHasTimeFrame: boolean;
    measureDocumentsList: MeasureDocuments[];

    constructor(options: {
        projectId: number;
        isDocumentHasTimeFrame: boolean;
        measureDocumentsList: MeasureDocuments[];
    }) {
        this.projectId = options.projectId;
        this.isDocumentHasTimeFrame = options.isDocumentHasTimeFrame;
        this.measureDocumentsList = options.measureDocumentsList || [];
    }

    get hasMeasureDocumentsList(): boolean {
      return Array.isArray(this.measureDocumentsList) && this.measureDocumentsList.length > 0;
    }
}
