export class DocumentPageRequest {
  documentQueueId: number;
  begPage: number;
  endPage: number;

  constructor(documentQueueId: number, begPage: number, endPage: number) {
    this.documentQueueId = documentQueueId;
    this.begPage = begPage;
    this.endPage = endPage;
  }
}
