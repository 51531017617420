import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { EntityError } from "../../../../../../../api/member-validation/entity-error.model";

@Component({
  selector: "app-risk-dx-validation-modal",
  templateUrl: "./dx-validation-modal.component.html",
  styleUrls: ["./dx-validation-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxValidationModalComponent implements OnInit, OnChanges {
  @Input() errors: EntityError[] = [];
  visible = false;

  ngOnInit(): void {
    this.setModalVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setModalVisibility();
  }

  clearErrors(): void {
    this.errors = [];
  }

  trackByIndex(index: number): number {
    return index;
  }

  private setModalVisibility(): void {
    this.visible = ArrayHelper.isAvailable(this.errors);
  }
}
