export enum HttpStatusCode {
  Ok = 200,
  BadRequest = 400,
  FailedLogin = 401,
  Unauthorized = 403,
  InvalidToken = 406,
  BrokenRule = 422,
  UnknownError = 500,
  ServiceUnavailable = 503,
}
