import { IAutoMapper } from "../interfaces";

export const mapServiceOrgNodeModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ServiceOrgNodeModel")
    .forMember("organizationId", o => o.organization)
    .forMember("organizationName", o => o.organizationName)
    .forMember("clients", o => {
      const isArray = Array.isArray(o.clients) && o.clients.length > 0;

      return isArray ? automapper.mapMany("default", "ClientNodeModel", o.clients) : [];
    });
};
