import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "member-risk-system-result-key",
  templateUrl: "./risk-system-result-key.component.html",
  styleUrls: ["./risk-system-result-key.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskSystemResultKeyComponent {
  @Input() systemResultKeys: any[];

  trackByIndex(index, item) {
    return index;
  }

}
