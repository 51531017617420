export enum LookerDashboardType {
    HEDIS = 25,
    RISK = 36,
    ClinicalSummary = 38,
    MRR = 39,
    OR1 = 40,
    OR2 = 41,
    RetrievalSummary = 43,
    DocQA = 44,
    PSR = 45,
    FT = 46,
    EMR = 47,
    DocIntake = 48,
    PendSummary = 51,
    GapStatus = 71,
    OpenGapsByMeasure = 78,
    OpenGapsByMember = 80,
    ChpMyScore = 86,
    MemberAdditionalDetails = 87,
    HedisProjectSummary = 89,
    BotOverreadOverview = 94,
    BotOverreadResultAnalysis = 95,
    BotOverreadPerformanceByGap = 96,
    BotOverreadPerformanceByMember = 97,
    BotOverreadPerformanceByProvider = 98,
    ClinicalProductivityOverview = 100,
    ChaseNumeratorLevelReport = 101,
    ProjectStatus = 102,
    PendReport = 103,
    Top50AIDsWithOpenChases = 105,
    ClinicalProductivityAndAccuracyOverall = 106,
    ClinicalAccuracyByMeasureLegacy = 107,
    ClinicalAccuracyByChaseWithErrorDetailReportLegacy = 108,
    ClinicalProductivityReport = 126,
    ClientOverread2Report = 127,
    CoderProductivityReport = 128,
    RetrievalReport = 129,
    RetrievalReportByChase = 130,
    IntakeProductivityReportLegacy = 131,
    UserProductivityReport = 132,
    CallCountDetailReportLegacy = 133,
    ProviderOutreachByContactTypeReport = 135,
    TrainingFeedbackReport = 136,
    FTDailySummaryReport = 137,
    ManuallyCreatedChasesReport = 138,
    MemberMeasureNonCompliantSummaryReport = 139,
    NRCListByMeasure = 142,
    ClinicalQAReportByMeasure = 143,
    GapPerformance = 145,
    SampleComplianceReport = 146,
    SftpReport = 159,
    ChasesInDeliveryWithImages = 160,
    MemberMeasureComplianceReport = 161,
    ProviderValidationReport = 167,
    HedisChaseCountReport = 172,
    ProspectiveChaseCountReport = 173,
    LoadCountReport = 174,
    RecordCountReport = 175,
    ChaseMoveBackReport = 190,
    DetailClinicalProductivityAndAccuracyOverall = 285,
    MemberCentricDetailsReport = 295,
    MemberCentricOverview = 296,
    ProgramRetrievalProjections = 299,
    IntakeSLADashboard = 308,
    ChaseMoveBackHistoryReportLegacy = 318,
    ClinicalPendsWithComplianceChange = 320,
    PendSummaryNew = 338,
    RetrievalSummaryNew = 339,
    ClinicalSummaryNew = 340,
    HedisNew = 341,
    DobDiscrepancy = 361,
    MemberCentricChaseCount = 375,
    AidFeedbackSummary = 377,
    MemberCentricChartCount = 381,
    CustomerInvoiceDetail = 391,
    ChartRouting = 393,
    ChaseSubMeasureReport = 400,
    ChaseNumeratorLevelReportDW = 409,
    MemberComplianceReport = 410,
    PendReportDW = 411,
    IntakeProductivityReport = 422,
    CallCountDetailReport = 426,
    ChaseMoveBackHistoryReport = 430,
    ClinicalAccuracyByChaseWithErrorDetailReport = 432,
    ClinicalAccuracyByMeasure = 435,
    QualityMatchRateReport = 437,
    StalledDocumentsReport = 442,
    ClientOverread2ReportDW = 445,
    LifeOfAChaseDW = 447,
    ProviderGatewayActivityReport = 449,
    NewPagesAddedReport = 451,
    CodingDurationReport = 452,
    CoderProductivityReportDW = 454,
    ClinicalProductivityReportDW = 455,
    ContactsReport = 458,
    EVEReviewedVsEVEFoundReport= 460,
    DetailClinicalProductivityAndAccuracyOverallDW = 462,
    ChaseMoveReport = 466,
    AidFeedbackSummaryDW = 469,
    UserProductivityDashboard = 477,
    ClinicalSummaryDW = 488,
    QualityOverview = 491,
    ChaseSubMeasureReportDW = 496,
    ChaseCountsByStatusReport = 498,
    ChartRoutingDashboard = 503,
    DobDiscrepancyDW = 511,
    SLAMilestoneReport = 512,
    ChaseStatusUnderlyingReport = 533,
    ProjectStatusDW= 554,
    DataLoadErrorReport = 559,
    AnnotationsReport = 560,
    SftpReportDW = 578,
    RetrievalSummaryDw = 591,
    TopAidsWithOpenChases = 592,
    PendsSummaryDW = 600,
    StalledDocumentsReportDW = 618,
    ChaseMoveReportDW = 619,
    TrainingFeedbackReportDW = 622,
    CodingDurationReportDW = 624,
    GapStatusDW = 625,
    SampleComplianceReportDW = 628,
}
