import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";

@Injectable()
export class ChaseDetailHeaderService {
  reset = new Subject<any>();

  constructor(
    private authService: AuthService,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  get showAuditLogSubmenu(): boolean {
    const userDirectoryRoleIds = this.authService.user.directoryRoleIds;

    const directoryRolesToCheck = [
      DirectoryUserRole.Admin,
      DirectoryUserRole.SystemAdministrator,
      DirectoryUserRole.DocumentIntakeManager,
      DirectoryUserRole.DocumentQAManager,
      DirectoryUserRole.EMRManager,
      DirectoryUserRole.ProjectManager,
      DirectoryUserRole.CallCenterManager,
      DirectoryUserRole.ReportingManager,
      DirectoryUserRole.RetrievalLead,
      DirectoryUserRole.FieldTechManager,
      DirectoryUserRole.ThirdPartyManager,
      DirectoryUserRole.ClinicalLead,
      DirectoryUserRole.MRRManager,
      DirectoryUserRole.OverreadManager,
      DirectoryUserRole.ClientOverreadManager,
      DirectoryUserRole.AuditLead,
      DirectoryUserRole.AuditManager,
      DirectoryUserRole.DataManagement,
      DirectoryUserRole.ClientLead,
    ];

    return userDirectoryRoleIds.some(r => directoryRolesToCheck.includes(r));
  }

  resetHeader(obj: any) {
    this.reset.next(obj);
  }

  getChaseGdFromPath(): number {
    return this.route.snapshot.parent.params.chaseGd;
  }

  getMenuItems(): Observable<MenuItem[]> {
    const menuItems = [
      { description: "Review", path: "./" },
      { description: "Chase", path: "info" },
      { description: "Member", path: "memberChases" },
      { description: "Member Gaps", path: "memberGaps", show: false },
      { description: "HCC Health Card", path: "healthCard", show: false },
      { description: "Comments", path: "comments" },
      { description: "Documents", path: "documents", show: false },
      { description: "Timeline", path: "timeline" },
      { description: "Audit Log", path: "auditLog", show: false },
    ];

    return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }
}
