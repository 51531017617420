<div class="access-info-document-container" *ngIf="addressId">
  <div class="access-info-document-container--label">Access Forms</div>
  <retrieval-document-list [retrievalId]="addressId"></retrieval-document-list>

  <div class="upload-button-container">
    <div class="bottom-left-container">
      <app-file-upload 
        accept="image/*,.txt,.doc,.docx,.xls,.xlsx,.pdf,.zip,.xml,.pptx"
        chooseLabel="Upload"
        (onUpload)="uploadDocument($event)">
      </app-file-upload>
    </div>
  </div>
</div>