<div
  *ngIf="isAvailable"
  [formGroup]="formGroup"
  class="control control--group control--negative-reason-code"
  [ngClass]="highlighter.className()"
  [hidden]="hidden">
  <div *ngIf="model.hasHeader" class="control__header">
    <div class="control__header__label">{{ model.header }}</div>
    <div *ngIf="hasError" class="control__header__error" [title]="error">{{ error }}</div>
  </div>
  <form-factory
    *ngFor="let model of model.controls; let i = index; trackBy: trackByIndex"
    class="negative-reaseon-code-group"
    [formGroup]="control"
    [model]="model"
    (onChange)="save(i)"
    (onFocus)="highlighter.onFocus()"
    (onBlur)="highlighter.onBlur()">
  </form-factory>
</div>