export class DynamicFormHelper {
  static createControls(formControls, control) {
    const formControl = control.create();
    Array.isArray(formControls)
      ? formControls.push(formControl)
      : formControls[control.key] = formControl;

    return formControls;
  }
}
