
import { IAutoMapper } from "../../core/automapper/interfaces";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapChasePendItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChasePendItem")
    .forMember("chasePendId", o => o.chasePendId)
    .forMember("chaseIds", o => o.chaseIds)
    .forMember("chasePendIds", o => o.chasePendIds)
    .forMember("pendTypeId", o => o.pendTypeId)
    .forMember("pendReasonId", o => o.pendReasonId)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("pendStatusId", o => o.pendStatusId)
    .forMember("notes", o => o.notes)
    .forMember("amount", o => o.amount)
    .forMember("owner", o => o.owner)
    .forMember("pendCompanyId", o => o.pendCompanyId)
    .forMember("invoiceNumber", o => o.invoiceNumber)
    .forMember("chaseId", o => o.chaseId)
    .forMember("documentPageIds", o => o.documentPageIds)
    .forMember("pendSeverityId", o => o.pendSeverityId)
    .forMember("documentPageNumbersAsCsv", o => o.documentPageNumbersAsCsv);
};

export class ChasePendItem {
  chasePendId: number;
  chaseIds: number[];
  pendTypeId: number;
  pendReasonId: number;
  assignedToUserId: number | null;
  pendStatusId: number;
  notes: string;
  chaseId: number;
  owner: string;
  isProvider: boolean;
  chasePendIds: number[];
  amount: number | null;
  pendCompanyId: number;
  invoiceNumber: string;
  documentPageIds: number[];
  pendSeverityId: number;
  documentPageNumbersAsCsv: string;
  documentQueueId?: number;
  pageRangeStart?: number;
  pageRangeEnd?: number;
  pageSource?: string;

  constructor(options: {
    chasePendId?: number;
    chaseIds?: number[];
    pendTypeId?: number;
    pendReasonId?: number;
    assignedToUserId?: number;
    pendStatusId?: number;
    notes?: string;
    chaseId?: number;
    owner?: string;
    isProvider?: boolean;
    chasePendIds?: number[];
    amount?: number;
    pendCompanyId?: number;
    invoiceNumber?: string;
    documentPageIds?: number[];
    pendSeverityId?: number;
    documentPageNumbersAsCsv?: string;
    documentQueueId?: number;
    pageRangeStart?: number;
    pageRangeEnd?: number;
    pageSource?: string;
  }) {
    this.chasePendId = options.chasePendId;
    this.chaseIds = options.chaseIds;
    this.pendTypeId = NumberHelper.isGreaterThan(options.pendTypeId, 0) ? options.pendTypeId : 0;
    this.pendReasonId = NumberHelper.isGreaterThan(options.pendReasonId, 0) ? options.pendReasonId : 0;
    const assignedToUserIdNumber = Number(options.assignedToUserId);
    this.assignedToUserId = NumberHelper.isGreaterThan(assignedToUserIdNumber, 0) ? assignedToUserIdNumber : null;
    this.pendStatusId = options.pendStatusId;
    this.notes = StringHelper.clean(options.notes);
    this.chaseId = options.chaseId;
    this.owner = options.owner;
    this.isProvider = options.isProvider;
    this.chasePendIds = options.chasePendIds;
    const amountNumber = Number(options.amount);
    this.amount = NumberHelper.isGreaterThan(amountNumber, 0) ? amountNumber : null;
    this.pendCompanyId = options.pendCompanyId;
    this.invoiceNumber = StringHelper.clean(options.invoiceNumber);
    this.documentPageIds = ArrayHelper.isAvailable(options.documentPageIds) ? options.documentPageIds : [];
    this.pendSeverityId = options.pendSeverityId;
    this.documentPageNumbersAsCsv = StringHelper.clean(options.documentPageNumbersAsCsv);
    this.documentQueueId = options.documentQueueId;
    this.pageRangeStart = options.pageRangeStart;
    this.pageRangeEnd = options.pageRangeEnd;
    this.pageSource = StringHelper.clean(options.pageSource);
  }
}
