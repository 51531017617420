import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";


export const mapPendCodeCount = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PendCodeCount")
    .forMember("pendTypeCategoryId", o => o.pendTypeCategoryId)
    .forMember("pendCount", o => o.pendCount)
    .forMember("pendTypeCategory", o => o.pendTypeCategory);
};

export class PendCodeCount {
  pendTypeCategoryId: number;
  pendCount: number;
  pendTypeCategory: string;

  constructor(options: {
    pendTypeCategoryId: number;
    pendCount: number;
    pendTypeCategory: string;
  }) {

    this.pendTypeCategoryId = options.pendTypeCategoryId;
    this.pendCount = options.pendCount;
    this.pendTypeCategory = StringHelper.clean(options.pendTypeCategory);
  }

}
