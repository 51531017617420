export type Inclusions = "()" | "[]" | "(]" | "[)";

export type dateTypes = string | Date;

export type UnitOfTime = "second" | "minute" | "hour" | "day" | "week" | "month" | "year";

export enum DateFormats {
  DEFAULT = "MM/DD/YYYY",
  SHORT_INPUT = "MMDDYYYY",
  RFC_DATE = "ddd, DD MMM YYYY",
  GRID_DATE_FORMAT = "MM/dd/yyyy",
}

export interface IDateRange {
  start: dateTypes | null;
  end: dateTypes | null;
}
