<app-detail-main-template>
  <div container-header>
    <div class="statistics"></div>
    <div class="filters">
      <app-landing-filter (addFilter)="applyFilter($event)"></app-landing-filter>
    </div>
  </div>
  <div container-body>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="chaseStatusLabelModel !== undefined">
        <app-kpi [cModel]="chaseStatusChartModel"
                 [cDataLabels]="chaseStatusLabelModel"
                 [cDataModel]="chaseStatusArray"
                 [cOptions]="chaseStatusDataOptions"></app-kpi>
      </div>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="numeratorHitsLabelModel !== undefined">
        <app-kpi [cModel]="numeratorHitsChartModel"
                 [cDataLabels]="numeratorHitsLabelModel"
                 [cDataModel]="numeratorHitsArray"
                 [cOptions]="numeratorHitsDataOptions"></app-kpi>
      </div>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="retrievalProjectionsLabelModel !== undefined">
        <app-kpi [cModel]="retrievalProjectionsChartModel"
                 [cDataLabels]="retrievalProjectionsLabelModel"
                 [cDataModel]="retrievalProjectionsArray"
                 [cOptions]="retrievalProjectionsDataOptions"></app-kpi>
      </div>
    </div>
  </div>
</app-detail-main-template>