import { IAutoMapper } from "../../../core/automapper/interfaces";
import { OcrMatch } from "./ocr-match.model";

export const mapDocumentPageOcrMatches = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentPageOcrMatches")
    //  .forMember("currentIndex", 0)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("ocrMatches", o => automapper.mapMany("default", "OcrMatch", o.ocrMatches));
};

export class DocumentPageOcrMatches {
  pageNumber: number;
  ocrMatches: OcrMatch[];
  currentIndex: number;
  viewerTopOfPageOffeset: number;


  constructor(options: Partial<DocumentPageOcrMatches>) {
    this.pageNumber = options.pageNumber;
    this.ocrMatches = options.ocrMatches;
    this.currentIndex = 0;
    this.viewerTopOfPageOffeset = 0;
  }


  nextWord(): OcrMatch {
    this.currentIndex = (this.currentIndex + 1) % this.ocrMatches.length;
    const word = this.ocrMatches[this.currentIndex];
    return word;
  }

  prevWord(): OcrMatch {
    const tempIndex = this.currentIndex === 0 ? this.ocrMatches.length : this.currentIndex;
    this.currentIndex = tempIndex - 1;
    const word = this.ocrMatches[this.currentIndex];
    return word;
  }

  offset(pageSize: number): number {
    const currentWord = this.ocrMatches[this.currentIndex];
    const yPercentage = currentWord.ocrWords[0].boundingBoxes[0].y;
    let wordOffset = (pageSize * yPercentage) - 100;
    if (wordOffset < 0) {
      wordOffset = 0;
    }
    const pageOffset = (currentWord.pageNumber - 1) * pageSize;
    const total = pageOffset + wordOffset;
    return total;
  }
}

