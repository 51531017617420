import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { GridStateService } from "../../grid-state.service";
import { GridConfiguration } from "../../models/grid-configuration.model";
import { GridView } from "./grid-view.model";
import { GridViewsState } from "./grid-views-state.model";

@Component({
  selector: "app-grid-views",
  templateUrl: "./grid-views.component.html",
  styleUrls: ["./grid-views.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridViewsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @Input() configuration: GridConfiguration = {} as any;
  private pViews: GridViewsState;
  @Input()
  get views(): GridViewsState {
    return this.pViews;
  }
  set views(value: GridViewsState) {
    this.pViews = value || {} as any;
    this.afterSetViews();
  }
  @Input() onViewSelect: EventEmitter<GridView>;
  @Input() refreshViews: EventEmitter<GridView>;

  isGridViewEditorVisible = false;
  existingGridView = null;
  onEditGridView = new EventEmitter<GridView>(true);
  @Input() displayCheckedItemsInGridView: {value: string; disabled: boolean}[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private gridSessionService: GridStateService
  ) { }

  ngOnInit() {
    this.sink.add(
      this.onEditGridView.subscribe(gridView => {
        this.existingGridView = gridView;
        this.setGridViewEditorVisible(true);
      }),
      this.onViewSelect.subscribe(this.updateGrid.bind(this))
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get hasViews(): boolean {
    return this.views != null && ArrayHelper.isAvailable(this.views.views);
  }

  get gridViews(): GridView[] {
    return this.hasViews ? this.views.views : [];
  }

  getActiveClass(index: number): string {
    return index === this.views.selectedIndex ? "active" : "";
  }

  onSelectView(event: MouseEvent, view: GridView): void {
    event.stopPropagation();
    event.preventDefault();

    this.onViewSelect.emit(view);
  }

  onCreateNewViewClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.setGridViewEditorVisible(true);
  }

  closeGridViewEditor(): void {
    this.setGridViewEditorVisible(false);
    this.existingGridView = null;
  }


  private afterSetViews(): void {
    if (this.hasViews) {
      const session = this.gridSessionService.get(this.configuration.stateName) || {} as any;
      const isInSession = session.selectedView != null && NumberHelper.isGreaterThan(session.selectedView.gridViewId, 0);
      const selectedView = isInSession ? session.selectedView : this.views.selectedView;
      this.onViewSelect?.emit(selectedView);
    }
  }

  private updateGrid(view: GridView): void {
    const selectedIndex = this.views.views.findIndex(a => a.gridViewId === view.gridViewId);
    this.views.setSelectedIndex(selectedIndex);
    this.cd.markForCheck();
  }

  private setGridViewEditorVisible(value: boolean): void {
    this.isGridViewEditorVisible = value;
    this.cd.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }
}
