import { ProjectNodeModel } from "./project-node.model";

export class ClientNodeModel {
  readonly clientId: number;
  readonly clientName: string;
  readonly projects: ProjectNodeModel[];
  constructor(options: {

    clientId: number;
    clientName: string;
    projects: ProjectNodeModel[];
  }) {

    this.clientId = options.clientId;
    this.clientName = options.clientName;
    this.projects = options.projects;

  }
}
