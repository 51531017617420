import { MenuItem as PrimeMenuItem } from "primeng/api";
import { MenuItem } from "./menu-item.model";

// TODO: Create spec file.
export abstract class GenericMenuComponent {
  abstract items: MenuItem[] = [];
  refresh = false;

  get hasItems(): boolean {
    return this.items.length > 0;
  }

  private primeMenuItems = [];
  private get hasMenuItems(): boolean {
    return this.primeMenuItems.length > 0;
  }
  get menuItems(): PrimeMenuItem[] {
    if (this.hasItems && this.refresh) {
      this.primeMenuItems = this.toPrimeMenuItems(this.items);
    }

    if (this.hasItems && !this.hasMenuItems) {
      this.primeMenuItems = this.toPrimeMenuItems(this.items);
    }

    return this.primeMenuItems;
  }


  toPrimeMenuItems(items: MenuItem[]) {
    return items.map(this.toPrimeMenuItem);
  }

  private toPrimeMenuItem = (item: MenuItem): PrimeMenuItem => {
    const children = item.hasChildren ? this.toPrimeMenuItems(item.children) : null;

    return this.getPrimeMenuItem(
      item.description,
      item.iconName,
      item.path,
      item.command,
      children,
      item.isNavigationLink);
  }

  private getPrimeMenuItem(
    label: string,
    icon: string,
    path: string,
    command: any,
    items: PrimeMenuItem[],
    isNavigationLink: boolean
  ): PrimeMenuItem {
    if (path) {
      return {
        label,
        icon: `fa fa-${icon}`,
        routerLink: [path],
        command,
        items,
        state: { isNavigationLink },
      } as any;
    } else {
      return {
        label,
        icon: `fa fa-${icon}`,
        command,
        items,
      } as any;
    }
  }
}
