export class ChasePageHistory {

  readonly chaseId: number;
  readonly pageType: string;
  readonly pageNumber: number;
  readonly action: string;
  readonly eventDate: Date;
  readonly userName: string;
  readonly documentPageId: number;
  readonly documentName: string;
  readonly currentlyAssignedToThisChase: boolean;


  constructor(options: {
    chaseId: number;
    pageType: string;
    pageNumber: number;
    action: string;
    eventDate: Date;
    userName: string;
    documentPageId: number;
    documentName: string;
    currentlyAssignedToThisChase: boolean;


  }) {

    this.chaseId = options.chaseId;
    this.pageType = options.pageType;
    this.pageNumber = options.pageNumber;
    this.action = options.action;
    this.eventDate = options.eventDate;
    this.userName = options.userName;
    this.documentPageId = options.documentPageId;
    this.documentName = options.documentName;
    this.currentlyAssignedToThisChase = options.currentlyAssignedToThisChase;

  }
}
