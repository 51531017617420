<app-modal [(visible)]="visible"
           (visibleChange)="change($event)"
           header="Change Document Status">

  <app-document-state-form [entityId]="entityId"
                          [workflowStatus]="workflowStatus"
                          [documentStatus]="documentStatus"
                          (value)="setForm($event)"></app-document-state-form>

  <footer>
    <app-button class="header-button"
                text="Submit"
                (onClick)="submitDocumentStatus()"
                [disabled]="!hasForm()"></app-button>
  </footer>

</app-modal>
