import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chase-counts-by-status-report",
  templateUrl: "./chase-counts-by-status-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseCountsByStatusReportComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CHASE_COUNTS_BY_STATUS;
  hideFilters: string[];

  constructor() {
    this.filters = ["Projects", "CurrentProcessStep", "Products", "LOB", "RetrievalOwner", "CurrentChaseStatus", "ExpectedRetrieval", "ChaseTags", "VendorName", "VendorInvoiceType"];
    this.hideFilters = [
      HideFilters.USER_ID2,
      HideFilters.ORG_ID,
      HideFilters.PROJECT_ID,
      HideFilters.HEALTH_PLAN,
      HideFilters.CONTRACT_NUMBER,
      HideFilters.PROJECT_NAME2,
      HideFilters.PRODUCT,
      HideFilters.LINE_OF_BUSINESS,
      HideFilters.CO_RETRIEVAL2,
      "Current_Chase_Status",
      "Current_Process_Step",
      "Expected_Retrieval",
      "Vendor_Name",
      "Vendor_Invoice_Type",
      "Chase_Tags",
      "Organization_Name",
    ];
  }

}
