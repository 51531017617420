import { IAutoMapper } from "../../../../core/automapper/interfaces";

export const mapOcrPageWorkflowProgress = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "OcrPageWorkflowProgress")
    .forMember("taskId", o => o.taskId)
    .forMember("masterTaskId", o => o.masterTaskId)
    .forMember("taskTypeName", o => o.taskTypeName)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("createDate", o => o.createDate)
    .forMember("updateDate", o => o.updateDate)
    .forMember("status", o => o.status)
    .forMember("errorMessage", o => o.errorMessage);
};

export class OcrPageWorkflowProgress {
  taskId: number;
  masterTaskId?: number;
  taskTypeName: string;
  entityTypeName?: string;
  createDate: Date;
  updateDate?: Date;
  status?: string;
  errorMessage?: string;

  constructor(options: Partial<OcrPageWorkflowProgress>) {
      this.taskId = options.taskId;
      this.masterTaskId = options.masterTaskId;
      this.taskTypeName = options.taskTypeName;
      this.entityTypeName = options.entityTypeName;
      this.createDate = options.createDate;
      this.updateDate = options.updateDate;
      this.status = options.status;
      this.errorMessage = options.errorMessage;
  }
}
