import { IAutoMapper } from "../../../../core/automapper/interfaces";


export const mapBulkOutReachChaseValidation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "BulkChaseValidation")
    .forMember("chaseId", o => o.chaseId)
    .forMember("validationStatus", o => o.validationStatus)
    .forMember("message", o => o.message)
    .forMember("projectId", o => o.projectId)
    .forMember("disabled", o => o.disabled)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId);
};
export class BulkChaseValidation {
  chaseId: number;
  validationStatus: string;
  message: string;
  projectId: number;
  disabled?: boolean;
  masterDocumentSourceId: number;

  constructor(options: {
    chaseId?: number;
    validationStatus?: string;
    message?: string;
    projectId?: number;
    disabled?: boolean;
    masterDocumentSourceId: number;
  }) {
    this.chaseId = options.chaseId;
    this.validationStatus = options.validationStatus;
    this.message = options.message;
    this.projectId = options.projectId;
    this.disabled = options.disabled;
    this.masterDocumentSourceId = options.masterDocumentSourceId;

  }
}
