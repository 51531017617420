<div class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Page Number</label>
  </div>
  <input pInputText class="control__input control__input--textbox"
    [disabled]="model.disabled" type="number" placeholder="Page Number" [(ngModel)]="model.saveInfo.pageNumber" (change)="onChangeEvent($event)"
    (focus)="onFocus.emit($event)" (blur)="onBlur.emit($event)" />
</div>

<div [formGroup]="formGroup" class="control control--input control--textbox">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label" [hidden]="hidden">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <input pInputText [ngClass]="classes" [readonly]="model.readonly" [type]="model.type"
    [autocomplete]="model.autocomplete" [id]="model.key" placeholder="Actual Value" (change)="onChangeEvent($event)"
    (input)="onInput.emit($event)" (focus)="onFocus.emit($event)" (blur)="onBlur.emit($event)"
    [formControlName]="model.key" />
</div>

<div class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Admin Value</label>
  </div>
  <input pInputText class="control__input control__input--textbox" [readonly]="true" [disabled]="model.disabled"
    placeholder="Admin Value" [ngModel]="model.adminValue" (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>
