import { Component, Input } from "@angular/core";

@Component({
  selector: "app-upload-message",
  template: `<p class="upload-message">{{uploadMessage}}</p>`,
  styleUrls: ["./upload-message.component.scss"],
})
export class UploadMessageComponent {

  uploadMessage = "Please make sure files are not password protected.";

}
