export enum DirectoryUserRole {
  SystemAdministrator = 1,
  RetrievalLead = 2,
  DocumentIntake = 3,
  DocumentIntakeManager = 4,
  DocumentQA = 5,
  DocumentQAManager = 6,
  CallCenterRep = 7,
  CallCenterManager = 8,
  FieldTech = 9,
  FieldTechManager = 10,
  EMREmployee = 11,
  EMRManager = 12,
  ThirdParty = 13,
  ThirdPartyManager = 14,
  ClinicalLead = 15,
  Abstractor = 16,
  MRRManager = 17,
  Overreader = 18,
  OverreadManager = 19,
  ClientOverread = 20,
  ClientOverreadManager = 21,
  AuditLead = 22,
  AuditManager = 23,
  ProjectManager = 24,
  Admin = 25,
  DataManagement = 26,
  ClientLead = 31,
  ReportingManager = 32,
  SpecialHandlingManager = 34,
  ReadOnly = 35,
  ManageDocPages = 36,
  PendEmployee = 37,
  PendManager = 38,
}
