<div class="main-container">
    <span class="header bold">AUDIT RESULTS</span>
    <br>
    <div class="filters">
        <form-factory class="filter-input" [formGroup]="form" [model]="projectInput"
            (onChange)="onProjectChange($event)"></form-factory>
        <form-factory class="filter-input" [formGroup]="form" [model]="requestTypeInput"></form-factory>
        <app-button class="filter-input__button" title="GENERATE SUBMISSION FILES" text="GENERATE SUBMISSION FILES"
            [disabled]="!isProjectSelected || !isTypeSelected" (onClick)="onSubmit()"></app-button>
    </div>
    <ng-container *ngIf="!isGridLoading">
        <div *ngIf="gridHasData else noResults">
            <app-basic-grid [configuration]="gridConfigurationModel" [data]="data" [actionTemplate]="actionTemplate">
                <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
                    <div class="action-template-container">
                        <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
                            (onClick)="action.action(rowData); localActionPopover.hide();"
                            [disabled]="rowData.isDisabled"></app-button>
                    </div>
                </ng-template>
                <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
                    <div class="action-template-container">
                        <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
                            (onClick)="action.action(rowData); localActionPopover.hide();"
                            [disabled]="rowData.isDisabled"></app-button>
                    </div>
                </ng-template>
            </app-basic-grid>
        </div>
        <ng-template #noResults>
            <p>No results available</p>
        </ng-template>
    </ng-container>
</div>