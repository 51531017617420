<div class="member-search chase-detail-form">
  <div class="ui-md-3 ui-toolbar-group-left">

    <div class="ui-g-12 ui-md-12 ui-lg-12 member-search-selection">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory class="member-search_existing-member"[formGroup]="formGroupMemberOption"
                      [model]="existingMemberOptions"></form-factory>
      </div>
    </div>

    <div class="ui-fluid" *ngIf="showMemberForm">
      <form [formGroup]="formGroupMemberSearch"
            class="chase-detail-form">
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!searchMemberControls">
          <p>
            Please create a new member by completing the fields below:<br />
            Then click the "Add Member" button.
          </p>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="searchMemberControls">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberIdInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!searchMemberControls">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="clientMemberIdInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="enrolleeIdInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberFirstNameInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberMiddleNameInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberLastNameInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberDateOfBirthInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupMemberSearch"
                        [model]="memberGenderInput"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 label-container" *ngIf="isClientEnrolleeIdInvalid">
            <div *ngFor="let message of validationErrorMessages trackBy: trackByIndex">
              <span class="ellipsis text-danger">{{ message }}</span>
            </div>
          </div>

        <div class="ui-md-12">
          <app-button class="ui-md-6 reset-button text-left" text="RESET" (onClick)="reset()"></app-button>
          <app-button *ngIf="searchMemberControls ; else newMember"  [disabled]="!isMemberSearchFormHasValues"  class="ui-md-6 process-button text-right" text="SEARCH" (onClick)="searchMember()"></app-button>
          <ng-template #newMember><app-button class="ui-md-6 process-button text-right" text="ADD MEMBER" (onClick)="createMember()"></app-button></ng-template>
        </div>

      </form>
    </div>
  </div>

  <div class="ui-md-8 ui-toolbar-group-left ml-2" *ngIf="searchMemberControls">
    <div *ngIf="memberCount<=0" class="grids-display">
      <div class="ellipsis text-center"><h2>No member found matching your search criteria.</h2></div>
    </div>
    <div class="grids-display" *ngIf="isMemberResultsFound">
      <h3>{{memberCount}} RESULT(S)</h3>
      <app-basic-grid class="grids-display"
                      [configuration]="memberSearchGridConfiguration"
                      [data]="memberSearchData"
                      [(selection)]="memberGridSelection">
      </app-basic-grid>
    </div>
    <div class="ui-md-12 ui-toolbar-group-left text-right process-button padding-right-0" *ngIf="!isShowNextStepButton">
      <app-button class="button"
                  (onClick)="nextStep()"
                  text="NEXT STEP"></app-button>

    </div>
  </div>

</div>




