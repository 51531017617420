import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Textarea } from "../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { WorkflowAction } from "../../../platform/api/workflow/workflow-action.enum";
import { WorkflowSubmitRequest } from "../../../platform/api/workflow/workflow-submit.model";
import { WorkflowStatus } from "../../../platform/api/workflow/worklow-status.enum";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { DocumentRequestService } from "../document-request.service";


@Component({
  selector: "app-document-state-form",
  templateUrl: "./document-state-form.component.html",
  styleUrls: ["./document-state-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentStateFormComponent implements OnInit {

  @Input() set entityId(value: number) {
    const cleanValue = Number(value);
    const validValue = NumberHelper.isGreaterThan(cleanValue, 0) ? cleanValue : null;
    this.setEntityId({ value: validValue } as any);
  }
  @Input() set workflowStatus(value: WorkflowStatus) {
    const cleanValue = Number(value);
    const validValue = NumberHelper.isGreaterThan(cleanValue, 0, true) ? cleanValue : null;
    this.setWorkflowStatus({ value: validValue } as any);
  }

  @Input() set documentStatus(value: number) {
    const cleanValue = Number(value);
    const validValue = NumberHelper.isGreaterThan(cleanValue, 0, true) ? cleanValue : null;
    this.setDocumentStatus({ value: validValue } as any);

  }

  constructor(private readonly formService: FormService,
              private readonly documentRequestService: DocumentRequestService,
              private changeDetector: ChangeDetectorRef) { }
  @Output() value = new EventEmitter<WorkflowSubmitRequest>();

  form: FormGroup;
  private pEntityId: Textbox;
  private pWorkflowStatus: Textbox;
  private pDocumentStatus: Textbox;
  private pDocumentStatusList: Dropdown;
  private pNote: Textarea;
  private pStatusDescription: Textbox;

  changedStatusLiteral = "Updated to ";
  noteLiteral = "  Note: ";

  getEntityId(): Textbox {
    return this.pEntityId;
  }
  setEntityId(value: Textbox): void {
    this.pEntityId = new Textbox({ ...this.getEntityId(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getEntityId().key).setValue(this.getEntityId().value);
    }
    this.resetNoteAndDocumentstatus();
  }

  getDocumentStatus(): Textbox {
    return this.pDocumentStatus;
  }

  setDocumentStatus(value: Textbox): void {
    this.pDocumentStatus = new Textbox({ ...this.getDocumentStatus(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getDocumentStatus().key).setValue(this.getDocumentStatus().value);

    }
  }

  getWorkflowStatus(): Textbox {
    return this.pWorkflowStatus;
  }
  setWorkflowStatus(value: Textbox): void {
    this.pWorkflowStatus = new Textbox({ ...this.getWorkflowStatus(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getWorkflowStatus().key).setValue(this.getWorkflowStatus().value);
    }
  }

  getStatusDescription(): Textbox {
    return this.pStatusDescription;
  }

  setStatusDescription(value: Textbox): void {
    this.pStatusDescription = new Textbox({ ...this.getStatusDescription(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getStatusDescription().key).setValue(this.getStatusDescription().value);
    }
  }

  getDocumentStatusList(): Dropdown {
    return this.pDocumentStatusList;
  }
  setDocumentStatusList(value: Dropdown): void {
    this.pDocumentStatusList = new Dropdown({ ...this.pDocumentStatusList, ...value } as any);
  }


  getNote(): Textarea {
    return this.pNote;
  }

  setNote(value: Textarea): void {
    this.pNote = new Textarea({ ...this.pNote, ...value } as any);
  }


  ngOnInit(): void {
    this.createForm();
    this.getStatuses();

  }

  change(): void {
    if (this.form.valid) {
      const workflowSubmitRequest = new WorkflowSubmitRequest(
        this.form.value[this.getEntityId().key],
        this.form.value[this.getWorkflowStatus().key],
        WorkflowAction.ChangeState,
        this.form.value[this.getDocumentStatus().key],
        [],
        `${this.changedStatusLiteral} ${this.form.value[this.getStatusDescription().key]}.${(this.form.value[this.getNote().key]) !== null ? this.noteLiteral + this.form.value[this.getNote().key] : ""}`
      );

      this.value.emit(workflowSubmitRequest);
    } else {
      this.value.emit({} as any);
    }
  }

  private getStatuses() {

    this.documentRequestService.getDocumentStatuses(0, null) // @View=0, @Action = null	/* only Active States
      .subscribe(documentStatusList => {

        // we need to get all Document States as we need Status Name for Note  and filter only for getDocumentState
        const docStatus = this.form.value[this.getDocumentStatus().key].toString();
        const options = [...documentStatusList.filter(o => o.value === docStatus)];
        this.setStatusDescription({ value: options[0].text } as any);
        this.changeDetector.markForCheck();

        // Need to call this so Submit button is enabled.
        this.change();

      });
  }

  private createForm(): void {
    this.setEntityId({
      key: "entityId",
      hidden: true,
      validators: [Validators.required, Validators.min(1)],
    } as any);

    this.setWorkflowStatus({
      key: "workflowStatus",
      hidden: true,
      validators: [Validators.required, Validators.min(0)],
    } as any);

    this.setDocumentStatus({
      key: "documentStatus",
      validators: [Validators.required, Validators.min(0)],
    } as any);

    this.setNote({
      key: "note",
      label: "Note",
      placeholder: "Write a note...",
      rows: 5,
      validators: [Validators.maxLength(255)],
      errorMessages: {
        maxlength: "The note must be maximum of 255 characters.",
      },
    } as any);

    this.setStatusDescription({
      key: "statusDescription",
      label: "Status",
      hidden: true,
      validators: [Validators.required, Validators.minLength(1)],
    } as any);

    this.form = this.formService.createFormGroup([
      this.getEntityId(),
      this.getWorkflowStatus(),
      this.getDocumentStatus(),
      this.getStatusDescription(),
      this.getNote(),
    ]);
  }
  resetNoteAndDocumentstatus() {
    if (this.form) {
      this.form.controls.note.setValue("");
      this.formService.updateDom.next();
    }
    this.changeDetector.markForCheck();
  }
}
