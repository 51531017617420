import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { VendorDetail } from "../vendor-detail/vendor-detail.model";

@Injectable()
export class VendorDetailStateService {
  state = new BehaviorSubject<VendorDetail>(null);

  setData(data: VendorDetail): void {
    const newData = new VendorDetail({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  resetData(data: VendorDetail): void {
    this.state.next(new VendorDetail(data));
  }

}
