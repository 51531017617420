<div class="validation-buttons">
  <app-button class="validation-buttons__valid" [ngClass]="{ 'active': isValid }" text="Valid"
              (click)="toggleActiveButton(true)" [disabled]="disabled">
  </app-button>
  <app-button *ngIf="!isAllDxAddedByCoder"  text="Invalidate All" class="validation-buttons__invalid" [ngClass]="{ 'active': isInvalid }"
              (click)="toggleActiveButton(false)" [disabled]="disabled">
  </app-button>

  <a class="validation-buttons__addDiagnosis" *ngIf="!disabled" (click)="addNewDiagnosis()" >+ Add diagnosis</a>

</div>
