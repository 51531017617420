<div class="chase-detail-form">
  <div class="ui-md-12 ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form [formGroup]="formGroup">
        <div formArrayName="encounter">
          <div class="ui-md-12 ui-toolbar-group-left" *ngFor="let encounter of formGroup.get('encounter')['controls']; index as i; first as isFirst trackBy: trackByIndex" formGroupName="{{i}}">
            <div [ngClass]="{'hide-form-group': encounter.controls.hide.value }">
              <div class="ui-md-12">
                <div class="ui-md-2 ui-toolbar-group-left control">
                  <span *ngIf="isFirst && !isFromParentChase" class="button-container button-encounter__add" (click)="addNewEncounter()" title='Add a new Encounter DOS'>+</span>
                  <span *ngIf="!isFirst || isFromParentChase" class="button-container button-container__delete" (click)="deleteEncounter(encounter)" title='Delete an Encounter DOS'>-</span>
                  <span class="label">CLAIM ID</span>
                  <input formControlName="claimId" placeholder="CLAIM ID" required>
                  <span class="errorMessage" *ngIf="hasDuplicateClaimIdError">
                    Claim Id must be unique.
                  </span>
                </div>
                <div class="ui-md-2 ui-toolbar-group-left control">
                    <form-factory [formGroup]="providerFormGroup" [model]="provider" ></form-factory>
                  </div>
                <div class="ui-md-2 ui-toolbar-group-left control">
                  <form-factory [formGroup]="encounterTypeFormGroup" [model]="encounterType"></form-factory>
                </div>
                <div class="ui-md-2 ui-toolbar-group-left control">
                  <span class="label">ENCOUNTER SERVICE DATE FROM</span>
                  <input formControlName="encounterServiceDateFrom" placeholder="MM/DD/YYYY" required>
                  <span class="errorMessage"
                        *ngIf="this.formGroup.controls.encounter.controls[0].controls.encounterServiceDateFrom.invalid &&
                        this.formGroup.controls.encounter.controls[0].controls.encounterServiceDateFrom.touched">
                    Date should be in MM/DD/YYYY format.
                  </span>
                </div>
                <div class="ui-md-2 ui-toolbar-group-left control">
                  <span class="label">ENCOUNTER SERVICE DATE THRU</span>
                  <input formControlName="encounterServiceDateThru" placeholder="MM/DD/YYYY" required>
                  <span class="errorMessage"
                        *ngIf="this.formGroup.controls.encounter.controls[0].controls.encounterServiceDateThru.invalid &&
                        this.formGroup.controls.encounter.controls[0].controls.encounterServiceDateThru.touched">
                    Date should be in MM/DD/YYYY format.
                  </span>
                </div>              
              </div>
              <div class="ui-md-12">
              <div formArrayName="diagnosis">
                <div class="form-array-margin ui-md-12 ui-toolbar-group-left" *ngFor="let diagnosis of encounter.controls.diagnosis.controls; index as j; first as isFirst trackBy: trackByIndex" formGroupName="{{j}}">      
                  <div [ngClass]="{'hide-form-group': diagnosis.controls.hide.value }" class="ui-md-12">
                    <div class="ui-md-2 ui-toolbar-group-left control">
                      <span *ngIf="isFirst && !isFromParentChase" class="button-container button-diagnosis__add" (click)="addDiagnosis(i)" title='Add a new Diagnosis'>+</span>
                      <span *ngIf="!isFirst && !isFromParentChase" class="button-container button-container__delete" (click)="deleteDiagnosis(diagnosis,i,j)" title='Delete Diagnosis'>-</span>
                      <span class="label">DIAGNOSIS</span>
                      <p-autoComplete *ngIf="!isFromParentChase" class="display-block" formControlName="diagnosisCode" [suggestions]="diagnosisListFiltered" (completeMethod)="filterOptions($event)" field="text" 
                                      placeholder="Enter Diagnosis" [minLength]="3" [forceSelection]="true"></p-autoComplete>
                      <input *ngIf="isFromParentChase" formControlName="diagnosisCode">
                      <span class="errorMessage"
                            *ngIf="encounter.controls.diagnosis.controls[0].controls.diagnosisCode.invalid &&
                                      encounter.controls.diagnosis.controls[0].controls.diagnosisCode.touched">
                        Enter Diagnosis.
                      </span>
                    </div>
                    <div class="ui-md-2 ui-toolbar-group-left control">
                      <span class="label"> DIAGNOSIS SERVICE DATE FROM</span>
                      <input formControlName="diagnosisServiceDateFrom" placeholder="MM/DD/YYYY" required>
                      <span class="errorMessage"
                            *ngIf="encounter.controls.diagnosis.controls[0].controls.diagnosisServiceDateFrom.invalid &&
                            encounter.controls.diagnosis.controls[0].controls.diagnosisServiceDateFrom.touched">
                        Date should be in MM/DD/YYYY format.
                      </span>
                    </div>
                    <div class="ui-md-2 ui-toolbar-group-left control">
                      <span class="label"> DIAGNOSIS SERVICE DATE THRU</span>
                      <input formControlName="diagnosisServiceDateThru" placeholder="MM/DD/YYYY" required>
                      <span class="errorMessage"
                            *ngIf="encounter.controls.diagnosis.controls[0].controls.diagnosisServiceDateThru.invalid &&
                            encounter.controls.diagnosis.controls[0].controls.diagnosisServiceDateThru.touched">
                        Date should be in MM/DD/YYYY format.
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12 label-container" *ngIf="isClaimInvalid">
      <div *ngFor="let message of validationErrorMessages trackBy: trackByIndex">
        <span class="ellipsis text-danger">{{ message }}</span>
    </div>
  </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-button class="reset-button ml" text="RESET" (onClick)="reset()"></app-button>
      <app-button class="ui-md-6 reset-button ml" text="SKIP STEP" (onClick)="callFinalReviewComponent()"></app-button>
      <app-button class="ui-md-4 process-button next-step ml"
                  (onClick)="nextStepProcess()"
                  text="NEXT STEP" [disabled]="!isShowNextStepButton"></app-button>
    </div>
  </div>
</div>

<app-modal [(visible)]="isVisibleMessageModal" header="WARNING">
  <div>
    <p>To skip this page, please clear your entry by clicking on the RESET button to proceed.</p>
  </div>
</app-modal>
