import { IAutoMapper } from "../interfaces";

export const mapDocumentReviewerChaseItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentReviewerChaseListItem")
    .forMember("documentName", o => o.documentName)
    .forMember("documentType", o => o.documentType)
    .forMember("documentDate", o => o.documentDate)
    .forMember("documentState", o => o.documentState)
    .forMember("projectName", o => o.projectName)
    .forMember("url", o => o.url);
};

