import { DynamicGroup } from "../../dynamic-group.model";
import { CalculatedGroup, ICalculatedGroupOptions } from "../calculated-group.model";
import { PrenatalGroupComponent } from "./prenatal-group.component";

export interface IPrenatalGroupOptions extends ICalculatedGroupOptions { }

export class PrenatalGroup extends CalculatedGroup implements IPrenatalGroupOptions {
  readonly controlType = "prenatal-group";
  readonly componentType = PrenatalGroupComponent;


  constructor(options: IPrenatalGroupOptions = {}) {
    super(options);
  }
}
