import { DocumentListItem } from "../../../../shared/document/chase-document-list/document-list-item.model";
import { DocumentThumbnailType } from "../../../../shared/document/document-page-viewer/document-thumbnail-type.enum";
import { DocumentThumbnail } from "../../../../shared/document/document-page-viewer/document-thumbnail.model";
import { Evidence } from "../../../../shared/document/document-page-viewer/evidence.model";
import { BooleanHelper } from "../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class DocumentPage {
  time: number;
  readonly documentPageId: number;
  readonly documentTypeId: number;
  readonly documentQueueId: number;
  pageNumber: number;
  readonly image: string;
  readonly createDate: string;
  viewed: boolean;
  numberOfPages: number;
  imageType: string;
  readonly lastModified: Date;
  readonly imagePath: string;
  readonly isNewPage: number;
  readonly rowSerialNumber: number;
  documentPageNumber: number;
  isCdnChartImageExist?: boolean;
  isThumbnailSelectedInEditMode: boolean;
  filterOnlyByDocumentPageIds?: boolean;
  documentThumbnail?: DocumentThumbnail;
  documentListItem?: DocumentListItem;
  evidence?: Evidence;
  recordCount: number;

  constructor(options: Partial<DocumentPage> = {}) {
    this.time = options.time;
    this.documentPageId = options.documentPageId;
    this.documentTypeId = typeof options.documentTypeId === "string" ? Number(options.documentTypeId) : options.documentTypeId;
    this.documentQueueId = options.documentQueueId;
    this.pageNumber = typeof options.pageNumber === "string" ? Number(options.pageNumber) : options.pageNumber;
    this.image = options.image;
    this.createDate = options.createDate;
    this.viewed = BooleanHelper.tryGet(options.viewed, false);
    this.numberOfPages = typeof options.numberOfPages === "string" ? Number(options.numberOfPages) : options.numberOfPages;
    this.imageType = options.imageType;
    this.lastModified = options.lastModified;
    this.imagePath = this.invalidateCache ? `${options.imagePath}?ver=${NumberHelper.randomWholeNumber(1, 1000)}` : options.imagePath;
    this.isNewPage = typeof options.isNewPage === "string" ? Number(options.isNewPage) : options.isNewPage;
    this.rowSerialNumber = options.rowSerialNumber;
    this.documentPageNumber = options.documentPageNumber;
    this.isCdnChartImageExist = options.isCdnChartImageExist;
    this.isThumbnailSelectedInEditMode = BooleanHelper.tryGet(options.isThumbnailSelectedInEditMode, false);
    this.filterOnlyByDocumentPageIds = BooleanHelper.tryGet(options.filterOnlyByDocumentPageIds, false);
    this.documentThumbnail = new DocumentThumbnail({
      thumbnailSpineColor: options.documentThumbnail?.thumbnailSpineColor,
      thumbnailBackgroundColor: options.documentThumbnail?.thumbnailBackgroundColor,
      thumbnailType: options.documentThumbnail?.thumbnailType,
    });
    this.documentListItem = options.documentListItem  || {} as any;

    this.evidence = new Evidence({
      diagnoses: options.evidence?.diagnoses,
      signs: options.evidence?.signs,
      symptoms: options.evidence?.symptoms,
      medications: options.evidence?.medications,


      dosEvidences: options.evidence?.dosEvidences,
      providerEvidences: options.evidence?.providerEvidences,

      memberNameEvidences: options.evidence?.memberNameEvidences,
      memberDOBEvidences: options.evidence?.memberDOBEvidences,
      memberGenderEvidences: options.evidence?.memberGenderEvidences,

    });
    this.recordCount = options.recordCount;

  }

  get invalidateCache(): boolean {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    return DateHelper.isAfter(DateHelper.create(this.lastModified), yesterday);
  }

  get source(): string {
    return `data:image/${this.imageType};base64,${this.image}`;
  }

  get thumbnailLabel(): string {
    return this.documentThumbnail.thumbnailType === DocumentThumbnailType.ADD_PAGE ? "Add Document" : "Request Document";
  }

  get hasDocumentListItem(): boolean {
    return StringHelper.isAvailable(this.documentListItem.fileName);
  }
}
