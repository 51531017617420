<app-collapsible-modal 
  class="feedback-modal"
  [(visible)]="visible" 
  header="OVERREAD CHALLENGES" 
  rejectText="CLOSE" 
  [allowClose]="false">

  <form class="feedback-modal__toggle" [formGroup]="toggleForm" *ngIf="!isRisk20">
    <form-factory
      [formGroup]="toggleForm"
      [model]="dataEntryToggle"
      (onChange)="onToggle($event.checked)">
    </form-factory>
  </form>

  <app-basic-grid 
    [hidden]="feedbackCompleted"
    class="feedback-modal__grid"
    [configuration]="gridConfiguration"
    [data]="gridData"
    [isAnimationDisabled]="false"
    [rowExpandTemplate]="rowExpandTemplate"
    (rowClick)="onRowClick($event)">

    <ng-template #reviewColumn let-rowData>
      <app-button 
        [ngClass]="reviewButtonClass(rowData.chaseOverreadFeedbackId)"
        classes="small" 
        text="REVIEW CHALLENGE" 
        (onClick)="onOpenReview($event, rowData.chaseOverreadFeedbackId)">
      </app-button>
    </ng-template>

    <ng-template #rowExpandTemplate let-rowData>
      <app-basic-grid 
        [ngStyle]="{'display': getIvaRiskStyle(rowData.abstractorData)}"
        class="feedback-modal__grid--events"
        [ngClass]="isRiskOrIva ? 'feedback-modal__grid--ivaevents' : ''"
        [configuration]="abstractorGridConfiguration"
        [data]="getTemplateData(rowData.abstractorData)">
      </app-basic-grid>

      <app-basic-grid 
        [ngStyle]="{'display': getIvaRiskStyle(rowData.overreaderData)}"
        class="feedback-modal__grid--events"
        [ngClass]="isRiskOrIva ? 'feedback-modal__grid--ivaevents' : ''"
        [configuration]="overreaderGridConfiguration"
        [data]="getTemplateData(rowData.overreaderData)">
      </app-basic-grid>

      <form class="form" [formGroup]="reasonForm">
        <form-factory
          [formGroup]="reasonForm"
          [model]="rowModel(rowData.chaseOverreadFeedbackId)">
        </form-factory>
      </form>
      <app-split-button 
        class="submit-btn"
        classes="danger"
        [model]="splitButtonModel(rowData.chaseOverreadFeedbackId)"
        [text]="splitButtonText(rowData.chaseOverreadFeedbackId)">
      </app-split-button>

      <form class="form feedback-modal__grid--feedback" [formGroup]="feedbackForm">
        <form-factory
          *ngIf="isFeedbackRowOpen(rowData.chaseOverreadFeedbackId)"
          [formGroup]="feedbackForm"
          [model]="feedbackRowModel(rowData.chaseOverreadFeedbackId)">
        </form-factory>
      </form>
      <app-button 
        class="submit-btn"
        *ngIf="isFeedbackRowModelValid(rowData.chaseOverreadFeedbackId)"
        text="SUBMIT FEEDBACK" 
        (onClick)="onFeedbackSubmit(rowData.chaseOverreadFeedbackId)">
      </app-button>
    </ng-template>
  </app-basic-grid>

  <footer *ngIf="feedbackCompleted">
    <div class="footer-text">All done!</div>
    <app-button text="CLOSE" (onClick)="onClose()"></app-button>
    <app-button *ngIf="isGetNextAvailable" classes="danger" text="GET NEXT" (onClick)="onGetNext()"></app-button>
  </footer>
</app-collapsible-modal>