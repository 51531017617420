<div class="color-picker" *ngIf="isCreateTagTemplateVisible">
  <div class="color-picker__tag-info create-new-tag-hover-color">
    <span class="color-picker__tag-info--icon" (click)="popover.toggle($event)">
      <app-icon [iconName]="getIcon()"
                [iconStyle]="getIcon()"></app-icon>
      <span class="color-picker__tag-info--header">{{tagHeaderText}}</span>
    </span>
  </div>

    <app-popover #popover class="color-palette-panel">
          <div *ngFor="let colorItem of defaultColors; let i = index; trackBy: trackByIndex"
               [class]="getClass(colorItem)"
               (click)="changeColor(colorItem)"></div>
    </app-popover>
    
</div>
