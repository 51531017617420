import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-icon",
  template: ``,
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() iconStyle = "fas";
  @Input() iconType = "fa";
  @Input() iconName = "";
  @Input() spin = false;


  ngOnInit() {
    StringHelper.throwIsAvailableError(this.iconStyle, "iconStyle");
    StringHelper.throwIsAvailableError(this.iconType, "iconType");
    StringHelper.throwIsAvailableError(this.iconName, "iconName");
  }


  private get spinClasses(): string {
    if (this.spin && this.iconType === "pi") {
      return " pi-spin";
    } else if (this.spin) {
      return " fa-spin";
    } else {
      return "";
    }
  }

  @HostBinding("class")
  get classes() {
    return `${this.iconStyle} ${this.iconType}-${this.iconName}${this.spinClasses}`;
  }
}
