import { IAutoMapper } from "../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { DocumentPageOcrMatches } from "./document-page-ocr-matches.model";

export const mapDocumentOcrMatch = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentOcrMatch")
    .forMember("documentQueueId", o => o.documentQueueId)
    .forMember("searchPhrase", o => o.searchPhrase)
    .forMember("fileKey", o => o.fileKey)
    .forMember("pages", o => automapper.mapMany("default", "DocumentPageOcrMatches", o.documentPageOCRMatches));
};

export class DocumentOcrMatch {
  readonly documentQueueId: number;
  readonly searchPhrase: string;
  readonly fileKey: string;
  currentOcrPageNumberIndex: number;
  firstViewed = false;
  readonly pages: DocumentPageOcrMatches[];

  constructor(options: {
    documentQueueId?: number;
    searchPhrase?: string;
    fileKey?: string;
    currentOCRPageNumberIndex: number;
    firstViewed: boolean;
    pages: DocumentPageOcrMatches[];
  }) {
    this.documentQueueId = options.documentQueueId;
    this.searchPhrase = options.searchPhrase;
    this.fileKey = options.fileKey;
    this.currentOcrPageNumberIndex = options.currentOCRPageNumberIndex;
    this.firstViewed = options.firstViewed;
    this.pages = options.pages;
  }


  get hasPages(): boolean {
    return ArrayHelper.isAvailable(this.pages);
  }

  get currentPage(): DocumentPageOcrMatches {
    return this.hasPages ? this.pages[this.currentOcrPageNumberIndex] : null;
  }

  nextPage(): number {
    this.currentOcrPageNumberIndex = (this.currentOcrPageNumberIndex + 1) % this.pages.length;
    const pageNumber = this.pages[this.currentOcrPageNumberIndex].pageNumber;
    return pageNumber;
  }

  prevPage(): number {
    const tempIndex = this.currentOcrPageNumberIndex === 0 ? this.pages.length : this.currentOcrPageNumberIndex;
    this.currentOcrPageNumberIndex = tempIndex - 1;
    const pageNumber = this.pages[this.currentOcrPageNumberIndex].pageNumber;
    return pageNumber;
  }
}
