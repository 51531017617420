import { NgModule } from "@angular/core";
import { PdfViewerModule } from "ng2-pdf-viewer";       // TO DO: to create a wrapper for this module.
import { AutoCompleteModule } from "primeng/autocomplete";
import { SplitButtonModule } from "primeng/splitbutton";
import { AddressGridModule } from "../../../shared/address-grid/address-grid.module";
import { SharedModule } from "../../../shared/shared.module";
import { InternalPendsModule } from "../internal-pends/internal-pends.module";
import { ClinicalOrFeedbackComponent } from "./clinical/clinical-orfeedback/clinical-orfeedback.component";
import { ClinicalComponent } from "./clinical/clinical.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DocumentReviewerComponent } from "./document-reviewer/document-reviewer.component";
import { PendsComponent } from "./pends/pends.component";
import { RetrievalComponent } from "./retrieval/retrieval.component";
import { RetrievalResolve } from "./retrieval/retrieval.resolve";

@NgModule({
  imports: [
    SharedModule,
    DashboardRoutingModule,
    SplitButtonModule,
    AutoCompleteModule, // TODO: remove dependency when we create a address assignment modal.
    AddressGridModule,
    PdfViewerModule,
    InternalPendsModule,
  ],
  declarations: [
    RetrievalComponent,
    ClinicalComponent,
    ClinicalOrFeedbackComponent,
    DocumentReviewerComponent,
    PendsComponent,
  ],
  exports: [
    ClinicalOrFeedbackComponent,
  ],
  providers: [
    RetrievalResolve,
  ],
})
export class DashboardModule { }
