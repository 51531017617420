import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-analytics-risk-overview",
    templateUrl: "./analytics-risk-overview.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsRiskOverviewComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.OVERVIEW;

    constructor() {
        this.filters = ["Projects", "Products", "Hcc", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
