import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-chase-move-back-report",
  templateUrl: "./chase-move-back-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveBackReportComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.CHASE_MOVE_BACK_REPORT;

  constructor() {
    this.filters = ["Projects"];
  }

}
