<div [formGroup]="formGroup" class="control control--input control--textarea" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <textarea
    pInputTextarea
    [ngClass]="classes"
    [ngStyle]="{ resize: model.resize }"
    [id]="model.key"
    [rows]="model.rows"
    [placeholder]="model.placeholder"
    (change)="onChange.emit($event)"
    (input)="onInput.emit($event)"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"
    [formControlName]="model.key"></textarea>
  <div class="control__input__character-count">{{ characterCountText }}</div>
</div>