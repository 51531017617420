import { Directive, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { BulkAction } from "./bulk-actions/bulk-action.model";
import { GridView } from "./grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "./grid-menu/grid-views/grid-views-state.model";
import { GridColumnDefinition } from "./models/grid-column-definition.model";
import { GridConfiguration } from "./models/grid-configuration.model";

@Directive()
export abstract class GridDirective {
  @Input() actionTemplate: TemplateRef<any>;
  @Input() filterTemplate: TemplateRef<any>;
  @Input() rowExpandTemplate: TemplateRef<any>;
  @Input() views: GridViewsState = {} as any;
  @Input() refreshViews = new EventEmitter<GridView>(true);
  @Input() rowDisabledCondition?: (rowData: any) => boolean = null;

  @Input() selection: any;
  @Input() showSelector: Function;
  @Output() selectionChange = new EventEmitter<any>(true);
  @Output() onRowSelectChange = new EventEmitter<any>(true);
  @Output() onRowUnselectChange = new EventEmitter<any>(true);
  @Output() onPageChange = new EventEmitter<any>(true);
  @Output() onViewSelect = new EventEmitter<GridView>(true);
  @Output() headerCheckboxToggle = new EventEmitter<any>(true);
  @ViewChild("primeGrid", { static: true }) primeGrid: Table;
  @Output() onRowExpand = new EventEmitter<any>(true);

  protected pConfiguration: GridConfiguration;
  @Input() get configuration(): GridConfiguration {
    return this.pConfiguration;
  }
  set configuration(value: GridConfiguration) {
    this.pConfiguration = value;
    this.configuration.isInit = true;
  }
  @Input() isMemberFile = false;
  @Input() actions: BulkAction[] = [];

  // Obsolete // TODO: update when bulkActions is removed from the GridConfiguration
  get bulkActionsObsolete(): BulkAction[] {
    if (ArrayHelper.isAvailable(this.actions)) {
      return this.actions;
    }

    return this.configuration.hasBulkActions ? this.configuration.bulkActions : [];
  }

  protected get stateKey(): string {
    return this.configuration.hasStateName ? this.configuration.stateName : "";
  }

  protected get hasStateKey(): boolean {
    return StringHelper.isAvailable(this.stateKey);
  }

  abstract data: any[];
  abstract totalRecords;
  abstract initialPageSize;

  get columns(): GridColumnDefinition[] {
    return this.configuration.columns;
  }

  get colspan(): number {
    return this.columns.filter(x => x.show === true).length + Number(this.configuration.showRowExpansionColumn)
      + Number(this.configuration.hasSelectionMode)
      + Number(this.configuration.showActionColumn);
  }

  get pageSize(): number {
    return this.configuration.pageSize;
  }

  get hasViews(): boolean {
    return this.views != null && ArrayHelper.isAvailable(this.views.views);
  }

  get hasPagination(): boolean {
    return this.configuration.hasPagination && this.totalRecords > this.initialPageSize;
  }

  get selectionMode(): string {
    return this.configuration.selectionMode;
  }

  get trackByField(): string {
    return this.configuration.trackByField;
  }

  get hasSelection(): boolean {
    if (this.selection == null) {
      return false;
    }

    const properties = Array.isArray(this.selection) ? this.selection : Object.keys(this.selection);
    return ArrayHelper.isAvailable(properties);
  }

  onSelectionChange(event): void {
    if (this.rowDisabledCondition != null) {
      this.selection = event.filter(row => !row.disabled);
      this.selectionChange.emit(this.selection);
    } else {
      this.selectionChange.emit(event);
    }
  }

  trackByItem(index, item) {
    return StringHelper.isAvailable(this.trackByField)
      ? item[this.trackByField]
      : JSON.stringify(item);
  }

  onHeaderCheckboxToggle(event) {
    return this.headerCheckboxToggle.emit(event);
  }

  onRowExpandToggle(event) {
    return this.onRowExpand.emit(event);
  }

  get rowExpandMode(): string {
    return this.configuration.rowExpandMode;
  }

  get expandedRows(): string {
    return this.configuration.expandedRows;
  }

  onRowSelect(event): void {
    this.onRowSelectChange.emit(event);
  }

  onRowUnselect(event): void {
    this.onRowUnselectChange.emit(event);
  }

  onPage(event): void {
    this.onPageChange.emit(event);
  }
}
