import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { CollapsiblePanelComponent } from "../../panel/collapsible-panel/collapsible-panel.component";
import { GridFilter } from "../models/grid-filter.model";
import { GridRequest } from "../models/grid-request.model";

@Component({
  selector: "app-filter-applied",
  templateUrl: "./filter-applied.component.html",
  styleUrls: ["./filter-applied.component.scss"],
})
export class FilterAppliedComponent {
  @ViewChild(CollapsiblePanelComponent, { static: true }) collapsiblePanel: CollapsiblePanelComponent;
  @Input() totalRecords: number;
  @Input() primeGrid: Table;
  @Input() request: GridRequest;
  @Input() openFilterModalOnFlag = false;
  @Input() isSaveQueryVisible = false;
  @Input() startCollapsed = false;
  @Input() savedQueryName = "";
  @Output() requestChange = new EventEmitter<GridRequest>();
  @Output() isShowFilterModal = new EventEmitter();
  @Output() showSaveQueryModal = new EventEmitter<boolean>();
  @Output() showDeleteSavedQueryModal = new EventEmitter<boolean>();
  @Output() isUpdateQueryViewAndFilters = new EventEmitter<boolean>();

  get filters(): GridFilter[] {
    return this.request.filters;
  }

  get filteredFilters(): SelectableInput[] {
    return this.filters
      .filter(filter => filter.hasValue && filter.show && filter.showChip && StringHelper.isAvailable(filter.displayName)
      && !filter.key.includes("TagSearchOperator"))
      .reduce((chips, filter) => {
        const { displayName, displayValues, forceFilter } = filter;
        displayValues.forEach(value => chips.push(new SelectableInput({ text: displayName, value: value.replace("@@", ","), extra: {forceFilter} })));
        return chips;
      },      []);
  }

  get groupedFilters(): GridFilter[] {
    let filters = this.filters;
    filters = filters?.filter(f => f.hasValue && f.show && f.showChip && StringHelper.isAvailable(f.displayName)
    && !f.key.includes("TagSearchOperator"));
    return filters;
  }

  isNotLastElement(index: number, array: any[]): boolean {
    return (index + 1) < array?.length;
  }

  get hasFilters(): boolean {
    return ArrayHelper.isAvailable(this.filteredFilters);
  }

  get hasForcedFilters(): boolean {
    return this.filteredFilters.some(x => x.extra.forceFilter);
  }

  get collapseIcon(): string  {
    return this.collapsiblePanel.primePanel.collapsed ? "caret-right" : "caret-down";
  }

  @HostBinding("style.display")
  get display(): string {
    return this.hasFilters ? "" : "none";
  }

  delete(event: MouseEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();

    const { text, value } = this.filteredFilters[index];
    const filter = this.filters.find(a => a.displayName === text);
    filter.removeValue(value as string);

    if (!this.openFilterModalOnFlag || (this.openFilterModalOnFlag && this.hasForcedFilters)) {
      this.refresh();
    }

    if (!this.hasFilters || (this.openFilterModalOnFlag && !this.hasForcedFilters)) {
      this.modifyQuery();
    }
  }

  modifyQuery(): void {
    this.isShowFilterModal.emit();
  }

  saveQuery(): void {
    this.showSaveQueryModal.emit(true);
  }

  updateQueryViewAndFilters(): void {
    this.isUpdateQueryViewAndFilters.emit(true);
  }

  deleteQuery(): void {
    this.showDeleteSavedQueryModal.emit(true);
  }

  toggle(event: MouseEvent): void {
    this.collapsiblePanel.primePanel.toggle(event);
  }

  private refresh(): void {
    this.request = new GridRequest(this.request);
    this.requestChange.emit(this.request);
    this.primeGrid.reset();
  }

  trackByIndex(index, item) {
    return index;
  }

  get saveQueryButtonName(): string {
    return StringHelper.isAvailable(this.savedQueryName) ? "Rename" : "Save Query";
  }

  get isSavedQueryNameExists(): boolean {
    return StringHelper.isAvailable(this.savedQueryName);
  }
}
