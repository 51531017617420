<div class="upload-documents-container">
  <h3 data-qa="upload-documents-page-header">UPLOAD BY CHASE</h3>
  <div class="form-container">
    <div class="search-fields-container">
      <div>Fill in any or all of the fields below to search for a Chase ID</div>

      <form [formGroup]="form">
        <div class="topRow">
          <form-factory *ngFor="let model of chaseModels; trackBy: trackByIndex"
                        [formGroup]="form"
                        [model]="model"></form-factory>
        </div>
        <div class="column-container">
          <div class="column1">
            <h3>MEMBER INFO</h3>
            <form-factory *ngFor="let model of memberModels; trackBy: trackByIndex"
                          [formGroup]="form"
                          [model]="model">

            </form-factory>
          </div>
          <div class="column2">
            <h3>PROVIDER INFO</h3>
            <form-factory *ngFor="let model of providerModels; trackBy: trackByIndex"
                          [formGroup]="form"
                          [model]="model"></form-factory>
          </div>
        </div>
        <div style="clear:both"></div>
        <app-button class="button" (onClick)="search()" text="Search"></app-button>
        <app-button class="button" (onClick)="clearControls()" type="button" text="Clear"></app-button>
      </form>
    </div>

    <app-basic-grid class="grids-display"
                    [configuration]="chaseGridConfiguration"
                    [data]="chaseGridData"
                    [(selection)]="chaseGridSelection"
                    [actionTemplate]="chaseGridactionTemplate"
                    [showSelector]="showSelector">

      <ng-template #chaseGridactionTemplate let-i="index" let-rowData let-actionPopover="actionPopover">
        <div class="bottom-left-container">
          <span *ngIf="messageForUpload(rowData)" class="actionTemplateMessage">NOTE: Chase is in {{rowData.workflowStatusName}} Status and uploading File will generate Approval Charge</span>
          <p-fileUpload #fileUpload name="file[]"
                        (onSelect)="onFileSelect($event)"
                        (onRemove)="onFileRemove($event)"
                        (uploadHandler)="uploadDocument($event,fileUpload,rowData,i,actionPopover)"
                        customUpload="true"
                        multiple="multiple"
                        accept="image/*,.txt,.doc,.docx,.xls,.xlsx,.pdf,.zip,.xml,.pptx"
                        maxFileSize="1000000000"
                        chooseLabel="Upload"
                        uploadLabel="Submit"
                        [showUploadButton]="true"
                        [disabled]="!validateForUpload(rowData)">
          </p-fileUpload>
        </div>
      </ng-template>
    </app-basic-grid>
  </div>
</div>


