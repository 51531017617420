import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-provider-header",
  templateUrl: "./provider-header.component.html",
  styleUrls: ["./provider-header.component.scss"],
})
export class ProviderHeaderComponent implements OnInit {

    @Input() welcomeText = "";
    @Input() supportText = "";
    @Input() imagePath = "";
    constructor() { }

    ngOnInit() {
  }

}
