import { StringHelper } from "../../../utilities/contracts/string-helper";

export class RetrievalCallRecordDetail {
  readonly callRecordAudio: string;
  readonly transcriptionText: string;

  constructor(options: {
    callRecordAudio: string;
    transcriptionText: string;
  }) {
    this.callRecordAudio = StringHelper.clean(options.callRecordAudio);
    this.transcriptionText = StringHelper.clean(options.transcriptionText);
  }

}
