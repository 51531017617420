import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoaderService } from "./loader/loader.service";

export const interceptorskipheader = "X-Skip-Interceptor";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];
  constructor(
    private loaderService: LoaderService,
    private readonly router: Router
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.removeRequest(req);
      }
    });

    if (req?.body?.headers?.lazyUpdate[0]?.name === interceptorskipheader) {
      return next.handle(req);
    }

    if (req.headers.has(interceptorskipheader)) {
      const headers = req.headers.delete(interceptorskipheader);
      return next.handle(req.clone({ headers }));
    }
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    return new Observable(observer => {
      const subscription = next.handle(req)
        .pipe(takeUntil(this.loaderService.onCancelPendingRequests()))
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => { this.removeRequest(req); observer.error(err); },
          () => { this.removeRequest(req); observer.complete(); });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }


  private removeRequest(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    this.loaderService.isLoading.next(this.requests.length > 0);
  }
}
