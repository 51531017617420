import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-clinical-accuracy-by-chase-with-error-detail",
  templateUrl: "./clinical-accuracy-by-chase-with-error-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalAccuracyByChaseWithErrorDetailComponent {
    filters: string[] = ["Projects", "Products", "LOB", "RetrievalOwner"];
    dashboardType = LookerRiskDashboardType.CLINICAL_ACCURACY_BY_CHASE_WITH_ERROR_DETAIL;
    hideFilters: string[] = [HideFilters.ABSTRACTION_DATE, HideFilters.PROJECT_ID, HideFilters.USER_ID,
                             HideFilters.ORG_ID, HideFilters.HEALTH_PLAN2, HideFilters.CONTRACT_NUMBER, HideFilters.CONTRACT_NUMBER,
                             HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MEASURE, HideFilters.CODED_BY, HideFilters.CO_RETRIEVAL];

}
