import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { BulkOutReachParameter } from "./bulk-outreach-parameter.model";

export class BulkOutReachRequest {
  readonly bulkOutreachType: string;
  readonly bulkOutreachParameter: BulkOutReachParameter;
  readonly coverLetterTemplateName: string;
  readonly chaseIds: number[];

  constructor(options: {
    bulkOutreachType: string;
    bulkOutreachParameter: any;
    coverLetterTemplateName: string;
    chaseIds: number[];
  }) {
    this.bulkOutreachType = StringHelper.clean(options.bulkOutreachType);
    this.bulkOutreachParameter = options.bulkOutreachParameter;
    this.coverLetterTemplateName = StringHelper.clean(options.coverLetterTemplateName);
    this.chaseIds = options.chaseIds;
  }
}
