import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {

  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService) { }

  getCallsCompleted(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/ChasesCompletedAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getAddressAssignment(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/AddressAssignmentAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getPendCreated(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/PendCreatedAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getAddress(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/AddressAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }
}
