<div class="encounter-list-v2">
  <form-factory [customTemplate]="customItem" [formGroup]="formGroup" [model]="encountersInput"
    (onChange)="setSelectedEncounterIndex($event)">
  </form-factory>
</div>
<ng-template let-item #customItem>
  <div class="dropdown-item">
    {{item?.text}}
    <div *ngIf="item?.extra?.hasEveDx" class="dropdown-item--icon">
      <app-icon iconName="user"></app-icon>
    </div>
  </div>
</ng-template>