import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { NlpWord } from "./nlp-word.model";

export const mapDocumentPageNlpMatches = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentPageNlpMatches")
    .forMember("nlpMatches", o => {
      const isArray = o?.nlpMatches && Array.isArray(o.nlpMatches) && o.nlpMatches.length > 0;
      return isArray ? automapper.mapMany("default", "NlpWord", o.nlpMatches) : [];
    });
};

export class DocumentPageNlpMatches {
  nlpMatches: NlpWord[];
  currentIndex: number;
  viewerTopOfPageOffset: number;
  firstViewed: boolean;

  constructor(options: Partial<DocumentPageNlpMatches>) {
    this.nlpMatches = options.nlpMatches;
    this.currentIndex = 0;
    this.viewerTopOfPageOffset = 0;
    this.firstViewed = options.firstViewed;
  }


  nextWord(): NlpWord {
    this.currentIndex = (this.currentIndex + 1) % this.nlpMatches.length;
    const word = this.nlpMatches[this.currentIndex];
    return word;
  }

  prevWord(): NlpWord {
    const tempIndex = this.currentIndex === 0 ? this.nlpMatches.length : this.currentIndex;
    this.currentIndex = tempIndex - 1;
    const word = this.nlpMatches[this.currentIndex];
    return word;
  }

  offset(pageSize: number): number {
    const currentWord = this.nlpMatches[this.currentIndex];
    const yPercentage = currentWord?.boundingBoxes[0].y;
    let wordOffset = (pageSize * yPercentage) - 100;
    if (wordOffset < 0) {
      wordOffset = 0;
    }
    const pageOffset = (currentWord.pageNumber - 1) * pageSize;
    const total = pageOffset + wordOffset;
    return total;
  }
}
