<div class="chase-detail-form">
  <div class="ui-md-3 ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-12 ui-lg-12 search-selection-group">
      <form-factory class="member-search__existing-member"  [formGroup]="formGroupAddressOption"
                    [model]="existingAddressOptions"></form-factory>
    </div>
    <div class="ui-fluid" *ngIf="showAddressForm">
      <form [formGroup]="formGroupAddressSearch">
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isExistingAddressSelected">
          <p>
            Please create a new address by completing the fields below:<br />
            Then click the "Add Address" button.
          </p>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="isExistingAddressSelected">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="retrievalLocationId"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="addressGroup"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="groupName"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="address1"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="address2"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="city"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="stateList"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="zip"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="contactName"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="phone"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="faxNumber"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isExistingAddressSelected">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="clientAddressId"></form-factory>
        </div>
   
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isExistingAddressSelected && isSOCoRetrievalOwnerEnabled">
          <form-factory [formGroup]="formGroupAddressSearch"
          [model]="coRetrievalOwner"
          ></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12 label-container" *ngIf="isAddressInvalid">
          <div *ngFor="let message of validationErrorMessages trackBy: trackByIndex">
            <span class="ellipsis text-danger">{{ message }}</span>
          </div>
        </div>
        <div class="ui-md-12">
          <app-button class="ui-md-6 reset-button text-left" text="RESET" (onClick)="reset()"></app-button>
          <app-button *ngIf="isExistingAddressSelected; else newAddress" class="ui-md-6 process-button text-right" text="SEARCH" (onClick)="searchAddressAction()"></app-button>
          <ng-template #newAddress><app-button class="ui-md-6 process-button text-right" text="ADD ADDRESS" (onClick)="newAddressAction()"></app-button></ng-template>
        </div>
      </form>
    </div>
  </div>
  <div class="ui-md-8 ui-toolbar-group-left ml-2" *ngIf="showMatchingAddressGrid">
    <div class="grids-display" *ngIf="isAddressSearchResultsFound; else noAddressFound">
      <h3>{{addressCount}} RESULT(S)</h3>
      <app-basic-grid class="grids-display" [configuration]="addressSearchGridConfiguration" [data]="addressSearchData"
        [(selection)]="addressGridSelection" (selectionChange)="selectChangeRetreiver($event)">
      </app-basic-grid>
    </div>
    <ng-template #noAddressFound>
      <div class="display-inline">
        <div class="ellipsis text-center">
          <h2>No addresses found matching your search criteria.</h2>
        </div>
      </div> 
    </ng-template>
  </div>

  <div class="ui-md-8 ui-toolbar-group-left ml-2 text-right process-button" *ngIf="isShowNextStepButton">
    <app-button class="button"
                (onClick)="nextStep()"
                text="NEXT STEP"></app-button>
                
  </div>
  <div class="ui-g-3 ui-md-3 ui-lg-3 ui-toolbar-group-left ml-2" *ngIf="isShowNextStepButton && isSOCoRetrievalOwnerEnabled">
    <form-factory [formGroup]="formGroupAddressSearch"
                  [model]="coRetrievalOwner"
                  ></form-factory>
                </div>
  

</div>
