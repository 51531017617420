import { CalculatedGroup, ICalculatedGroupOptions } from "../calculated-group.model";
import { EddPrenatalGroupComponent } from "./edd-prenatal-group.component";

export interface IEddPrenatalGroupOptions extends ICalculatedGroupOptions { }

export class EddPrenatalGroup extends CalculatedGroup implements IEddPrenatalGroupOptions {
  readonly controlType = "edd-prenatal-group";
  readonly componentType = EddPrenatalGroupComponent;


  constructor(options: IEddPrenatalGroupOptions = {}) {
    super(options);
  }
}
