import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { UserToken } from "../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../core/navigation/parameter.service";
import { UserService } from "../../../../../core/user/user.service";
import { GapGridComponent } from "../../../../../shared/gap-grid/gap-grid.component";
import { BulkAction } from "../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { ChaseAssignService } from "../../../../api/unassign-chase/chase-assign.service";

@Component({
  selector: "app-chase-detail-member-gaps",
  templateUrl: "./chase-detail-member-gaps.component.html",
  styleUrls: ["./chase-detail-member-gaps.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailMemberGapsComponent implements OnInit {
  @ViewChild(GapGridComponent, { static: true }) gapGridComponent: GapGridComponent;
  @Input() isUnassignEnabled = false;
  chaseId: number;
  additionalBulkActions: BulkAction[] = [];
  additionalColumns: GridColumnDefinition[] = [];
  private user: UserToken;
  isAssignToChaseModalVisible = false;
  selectedChaseIdsForAssignment: number[];

  // Note: selectedChases is used for actions / bulk actions.
  selectedChases: any[];
  selection: any[];

  constructor(
    private parameterService: ParameterService,
    private userService: UserService,
    private readonly messagingService: MessagingService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseAssignService: ChaseAssignService
  ) { }

  ngOnInit() {
    this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
    this.user = this.userService.getUserToken();

    this.additionalBulkActions = [
      new BulkAction({
        name: "Assign Chase(s)",
        action: this.openAssignToChaseModal,
        showBulkAction: true,
        disabled: !this.isUserMangerAdminLead,
      }),
      new BulkAction({
        name: "Unassign Chase(s)",
        action: this.unassign.bind(this),
        showBulkAction: true,
      }),
    ];

    this.additionalColumns = [
      new GridColumnDefinition({ field: "chaseSourceAliasId", header: "Chase Key", show: false }),
      new GridColumnDefinition({ field: "clientName", header: "Client", show: false }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First", show: false }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last", show: false }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", show: false }),
      new GridColumnDefinition({ field: "memberGender", header: "Gender", show: false }),
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", show: false }),
      new GridColumnDefinition({ field: "chaseDocumentPageCount", header: "Pages", show: false }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code", show: false }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status", show: false }),
      new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", show: false }),
      new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance", show: false }),
    ];
  }

  private setSelectedChases(rowData: any): void {
    this.selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    this.selectedChaseIdsForAssignment = this.chaseAssignService.getSelectedChaseIds(this.selectedChases);
  }

  openAssignToChaseModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (rowData != null || rowData === undefined) {
      this.isAssignToChaseModalVisible = true;
    } else {
      this.selectedChases = [];
      this.messagingService.showToast("Please select chases from the same project.", SeverityType.ERROR);
    }
  }

  unassign(rowData: any): void {
    this.setSelectedChases(rowData);

    const chaseStatus = this.selectedChases.map(item => item.workflowStatusName)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (chaseStatus.length > 1) {
      this.messagingService.showToast("Chases for this assignment may not be at different workflow statuses", SeverityType.ERROR);
      this.selectedChases = [];
      this.changeDetector.markForCheck();
    } else {
      const unassignedUserId = 0;
      this.chaseAssignService.assignChasesToUser(this.chaseAssignService.getSelectedChaseIds(this.selectedChases), unassignedUserId, null)
        .subscribe(() => {
          this.messagingService.showToast("Unassigned Successfully.", SeverityType.SUCCESS);
          this.resetGridView();
          this.gapGridComponent.refreshGrid.emit();
        });
    }
  }

  resetGridView(): void {
    this.selectedChases = [];
    this.selection = [];
  }

  get isUserMangerAdminLead(): boolean {
    return (this.user.isManagerRole || this.user.isAdminRole || this.user.isLeadRole);
  }
}
