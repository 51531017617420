import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { List } from "immutable";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridView } from "../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../../../../shared/grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { ListItem } from "../../../../shared/list/list-item";
import { TagType } from "../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RETRIEVAL_TP_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { AddressDetailInfoEditService } from "../address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { AddressDetailInfoService } from "../psr/address-detail/address-detail-info/address-detail-info.service";
import { RetrievalPageService } from "../retrieval-page/retrieval-page.service";


@Component({
  selector: "app-third-party",
  templateUrl: "./third-party.component.html",
  styleUrls: ["./third-party.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThirdPartyComponent implements OnInit, OnDestroy {
  @ViewChild("splHandlingCol", { static: true }) splHandlingColumn: TemplateRef<any>;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  private sink = new SubSink();
  refreshViews = new EventEmitter<GridView>(true);
  views: GridViewsState;
  serverGridConfiguration = new GridConfiguration();
  serverRequest: GridRequest;
  actions: BulkAction[];
  selectedAddresses: any[];
  isAssignModalVisible = false;
  refreshGrid = new EventEmitter<null>(true);
  @Input() isUnassignEnabled = true;
  tagType = TagType.MASTERDOCUMENTSOURCE;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  allAvailableTags: SelectableInput[];
  selectedObjectId: number;
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  statisticsFilter: string;
  headerStatistics = List<ListItem>();
  retrievalType = "";
  selectedStat: string;
  isSelectedStat = false;
  searchTextChanged = new Subject<string>();
  get aidInput(): Textbox {
    return this.getInput("masterDocumentSourceID") as Textbox;
  }

  get providerNameInput(): Textbox {
    return this.getInput("ProviderName") as Textbox;
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("ProjectIdsAsCsv");
  }

  set projectsInput(value: CheckboxGroup) {
    this.setInput("ProjectIdsAsCsv", value);
  }

  get statusInput(): CheckboxGroup {
    return this.getInput("StatusNamesAsCsv") as CheckboxGroup;
  }

  set statusInput(value: CheckboxGroup) {
    this.setInput("StatusNamesAsCsv", value);
  }

  get assignedToInput(): Autocomplete {
    return this.getInput("AssignedToUserId");
  }

  set assignedToInput(value: Autocomplete) {
    this.setInput("AssignedToUserId", value);
  }

  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get cityInput(): Textbox {
    return this.getInput("City") as Textbox;
  }

  get stateInput(): Textbox {
    return this.getInput("State") as Textbox;
  }

  get postalCodeInput(): Textbox {
    return this.getInput("PostalCode") as Textbox;
  }

  get groupNameInput(): Textbox {
    return this.getInput("GroupName") as Textbox;
  }

  get addressGroupIdInput(): Textbox {
    return this.getInput("AddressGroupId") as Textbox;
    }

  get vendorNameInput(): CheckboxGroup {
        return this.getInput("VendorIdsAsCsv") as CheckboxGroup;
    }

  set vendorNameInput(value: CheckboxGroup) {
        this.setInput("VendorIdsAsCsv", value);
    }

  get vendorConfirmedInput(): Radiobutton {
        return this.getInput("vendorConfirmedType") as Radiobutton;
  }
  set vendorConfirmedInput(value: Radiobutton) {
    this.setInput("vendorConfirmedType", value);
  }

  get coRetrievalOwnerInput(): Autocomplete {
    return this.getInput("coRetrievalOwner");
  }

  set coRetrievalOwnerInput(value: Autocomplete) {
    this.setInput("coRetrievalOwner", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  get shReasonInput(): CheckboxGroup {
    return this.getInput("SpecialHandling");
  }
  set shReasonInput(value: CheckboxGroup) {
    this.setInput("SpecialHandling", value);
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.serverRequest == null) {
      return null;
    }

    return this.serverRequest.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.serverRequest == null) {
      return null;
    }

    this.serverRequest.setInput<T>(key, value);
  }


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly userService: UserService,
    private readonly automapper: AutomapperService,
    private readonly formService: FormService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly addressDetailInfoService: AddressDetailInfoService,
    private readonly tagService: TagService,
    private gridViewsService: GridViewsService,
    private readonly changeDetector: ChangeDetectorRef,
    private retrievalService: RetrievalPageService,
    private parameterService: ParameterService,
    private addressDetailInfoEditService: AddressDetailInfoEditService
  ) { }

  ngOnInit() {
    this.creategrid();
    this.getAllSelectableInputs();

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView)),
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllTagsList()))
          .subscribe()
    );
    this.retrievalType = this.parameterService.getNormal("type", "thirdparty");
    this.getstats();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  creategrid() {
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "defaultSpecialHandling", header: "SH", template: this.splHandlingColumn, isSortableColumn: false, width: "30px" }),
      new GridColumnDefinition({ field: "masterDocumentSourceID", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceID" }),
      new GridColumnDefinition({ field: "specialHandlingReason", header: "SH Reason" }),
      new GridColumnDefinition({ field: "providerName", header: "Provider Name" }),
      new GridColumnDefinition({ field: "address1", header: "Address 1" }),
      new GridColumnDefinition({ field: "address2", header: "Address 2" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "postalCode", header: "Zip" }),
      new GridColumnDefinition({ field: "phone", header: "Phone", pipeName: GridPipeName.Phone }),
      new GridColumnDefinition({ field: "vendorName", header: "Vendor Name" }),
      new GridColumnDefinition({ field: "vendorInvoiceType", header: "Invoice Type" }),
      new GridColumnDefinition({ field: "projectsName", header: "Project" }),
      new GridColumnDefinition({ field: "groupName", header: "Group Name" }),
      new GridColumnDefinition({ field: "addressGroup", header: "Address Group" }),
      new GridColumnDefinition({ field: "nextCallDate", header: "Next Call Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "latestContactDate", header: "Last Contact Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "chaseCount", header: "Open Chases" }),
      new GridColumnDefinition({ field: "chasePendCount", header: "Pends" }),
      new GridColumnDefinition({ field: "documentSourceTypeName", header: "Retrieval Type" }),
      new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
      new GridColumnDefinition({ field: "status", header: "Status" }),
      new GridColumnDefinition({ field: "vendorConfirmDate", header: "Vendor Confirmed", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
    ];
    this.serverGridConfiguration.pageSize = 10;
    this.serverGridConfiguration.selectionMode = "multiple";
    this.serverGridConfiguration.stateName = RETRIEVAL_TP_GRID.attributeCode;
    this.serverGridConfiguration.showViews = true;
    this.serverGridConfiguration.viewAttributeId = RETRIEVAL_TP_GRID.attributeId;
    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}address/thirdparty`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "masterDocumentSourceID",
          name: "AID",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ProviderName",
          name: "Provider Name",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "ProjectIdsAsCsv",
          name: "Projects",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select User..." }),
          key: "AssignedToUserId",
          name: "Assigned To",
        }),
        new GridFilter({
          input: new TagSearchMultiselect({ placeholder: "Tags" }),
          key: "TagIdsAsCsv",
          name: "Tags",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "StatusNamesAsCsv",
          name: "Status",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "City",
          name: "City",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "State",
          name: "State",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "PostalCode",
          name: "Zip",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "GroupName",
          name: "Group Name",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "AddressGroupId",
          name: "Address Group",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "VendorIdsAsCsv",
          name: "Vendor Name",
        }),
        new GridFilter({
            input: new Radiobutton(),
            key: "vendorConfirmedType",
            name: "Vendor Confirmed",
        }),
        new GridFilter({
          input: new Dropdown({ placeholder: "Select...", appendTo: "body" }),
          key: "coRetrievalOwner",
          name: "Retriever",
      }),
        new GridFilter({
          input: new Dropdown(),
          key: "TagSearchOperator",
          value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
            ? this.selectedTagSearchOperator : null,
          showChip: false,
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "SpecialHandling",
          name: "Special Handling",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "AssignedFilter",
          show: false,
        }),
      ],
    });
    this.getViews();
    this.actions = this.getActions();
    this.refreshGrid.emit();
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.serverGridConfiguration.showViews) {
      this.gridViewsService.get(this.serverGridConfiguration.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
            setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  private getActions(): BulkAction[] {
    const bulkActions = [
      new BulkAction({
        name: "Assign Addresses",
        action: this.openAssignAddressToUser.bind(this),
      }),
      // new BulkAction({
      //  name: "Manage Tags",
      //  action: this.openManageTagModal.bind(this),  /*TODO: This will be a part of next release*/
      //  disabled: this.hasTagManagement,
      // }),
    ];
    return bulkActions;
  }

  openAssignAddressToUser(rowData: any): void {
    this.setSelectedAddresses(rowData);
    this.isAssignModalVisible = true;
  }

  private setSelectedAddresses(rowData: any): void {
    this.selectedAddresses = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
  }

  closeModal(): void {
    this.selectedAddresses = [];
    this.isAssignModalVisible = false;
    this.isManageTagModalVisible = false;
  }

  gridRefresh(): void {
    this.refreshGrid.emit();
  }

  trackByIndex(index, item) {
    return index;
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getShReasons();
    this.getAssignedToUsers();
    this.getStatus();
    this.getVendors();
    this.getVendorConfirmedOptions();
    this.getTagSearchOperator();
    this.getCoRetrievalOptions();
    setTimeout(() => this.showFiltersOrGetData());
  }

  private getShReasons(): void {
    this.addressDetailInfoEditService
      .getSpecialHandlingReasons()
      .subscribe(options => {
        this.shReasonInput = { ...this.shReasonInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getAssignedToUsers(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectsInput = { ...this.projectsInput, options } as any;
        this.setProjectsInput();
        this.formService.updateDom.next();
      });
  }

  private setProjectsInput(): void {
    const projectOption = this.projectsInput;
    const projectFilter = this.serverRequest.getFilter(projectOption.key);
    if (StringHelper.isAvailable(projectFilter.value)) {
      const filteredProjects = projectFilter.value.split(/\s*,\s*/);
      projectFilter.inputValue = [];
      filteredProjects.forEach(selectedProject => {
        projectFilter.inputValue.push(projectOption.options.find(a => a.value === +selectedProject));
      });
      this.formService.updateDom.next();
    }
  }

  private getStatus(): void {
    this.retrievalPageService
      .getStatuses()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.text,
          value: item.text,
        }));
      }))
      .subscribe(result => {
        this.statusInput = { ...this.statusInput, options: result } as any;
        this.formService.updateDom.next();
      });
    }

  showFiltersOrGetData(): void {
    let filters = [];

    filters = this.serverRequest.filters.filter(filter => filter.input.value);

    if (filters.length > 0) {
          setTimeout(() => this.refreshGrid.emit());
      } else {
        this.serverGridComponent.showFilters();
      }
  }

  private getVendors(): void {
        this.addressDetailInfoService
            .getVendors()
            .pipe(map(this.automapper.curryMany("Vendors", "SelectableInput")))
            .subscribe(options => {
                this.vendorNameInput = { ...this.vendorNameInput, options } as any;
                this.formService.updateDom.next();
            });
  }

  openManageTagModal(rowData: any): void {
    this.setSelectedAddresses(rowData);
    if (ArrayHelper.isAvailable(this.selectedAddresses)) {
      this.totalEntityCount = this.selectedAddresses.length;
      this.entityName = "ADDRESS";
      this.isManageTagModalVisible = true;
    }
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  getAllTagsList(): void {
    this.allAvailableTags = [];
    const searchText = this.tagsInput.value;
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.options = [...this.allAvailableTags];
      });
  }
  private getstats(): void {
    this.retrievalService
      .getStatisticsData(this.retrievalType)
      .subscribe(result => {
        this.headerStatistics = List(result);
        this.changeDetector.markForCheck();
      });
  }
  getClass(item: ListItem): string {
    return item.key === this.selectedStat ? "activeStat" : "clearStat";
  }

  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }
  getStatisticsFilter(filterName: string): void {
    this.statisticsFilter = filterName;
    this.isSelectedStat = !this.isSelectedStat;
    this.selectedStat = !this.isSelectedStat ? null : filterName;
    this.serverRequest.getFilter("AssignedFilter").value = this.selectedStat;
    this.refreshGrid.emit();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  private getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }

  getVendorConfirmedOptions(): void {
      const options = [
          new SelectableInput({ text: "Yes - Confirmed (Has a confirmation date)", value: "Yes" }),
          new SelectableInput({ text: "No - Unconfirmed (No confirmation date)", value: "No" }),
          new SelectableInput({ text: "Show All", value: "All" }),
      ];
      this.vendorConfirmedInput = { ...this.vendorConfirmedInput, options } as any;
      this.formService.updateDom.next();
  }

  getCoRetrievalOptions(): void {
    const options = [
        new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
        new SelectableInput({ text: "Client", value: "Client" }),
        new SelectableInput({ text: "Mixed", value: "Mixed" }),
      ];
    this.coRetrievalOwnerInput = { ...this.coRetrievalOwnerInput, options } as any;
    this.formService.updateDom.next();
  }
}
