import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export const mapGapCompliance = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "GapCompliance")
    .forMember("chaseComplianceCode", o => o.chaseComplianceCode)
    .forMember("measureCode", o => o.measureCode)
    .forMember("numeratorCode", o => o.numeratorCode)
    .forMember("numeratorId", o => o.numeratorID)
    .forMember("numeratorName", o => o.numeratorName);
};

export class GapCompliance {
  chaseComplianceCode?: string;
  measureCode?: string;
  numeratorCode?: string;
  numeratorId?: number;
  numeratorName?: string;

  constructor(options: {
    chaseComplianceCode?: string;
    measureCode?: string;
    numeratorCode?: string;
    numeratorId?: number;
    numeratorName?: string;
  }) {
    this.chaseComplianceCode = StringHelper.clean(options.chaseComplianceCode);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.numeratorCode = StringHelper.clean(options.numeratorCode);
    this.numeratorId = options.numeratorId;
    this.numeratorName = StringHelper.clean(options.numeratorName);
  }
}
