<app-modal [(visible)]="isStatusChangeVisible" header={{popUpLabel}} (visibleChange)="visibleChange($event)">
  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12 modal-label">
          <form-factory [formGroup]="formGroup" [model]="statusInput" (onChange)="onSelectType($event)"></form-factory>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="notes"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button *ngIf="!isPendCloseVisible" class="header-button" [text]="text" (onClick)="changeStatus()"></app-button>
    <app-button *ngIf="isPendCloseVisible" class="header-button" [text]="text" (onClick)="conformStatusChange()"></app-button>

  </footer>
</app-modal>

<app-modal
    [(visible)]="isPendStatusChangeVisible"
    [header]="modalWindowContent"
    (onCancel)="closePendPopup()">
  <footer>
    <app-button class="header-button" text="Yes" (onClick)="changeStatus()" [disabled]="false"></app-button>
  </footer>
</app-modal>
