export class FtAppointmentSearchRequest {

  retrievalId?: number;
  assignedTo?: number;
  sortOrder?: string;
  sortDirection?: string;
  startRecord?: number;
  endRecord?: number;

  constructor(
    retrievalId?: number,
    assignedTo?: number,
    sortOrder?: string,
    sortDirection?: string,
    startRecord?: number,
    endRecord?: number

  ) {
    this.retrievalId = retrievalId;
    this.assignedTo = assignedTo;
    this.sortOrder = sortOrder;
    this.sortDirection = sortDirection;
    this.startRecord = startRecord;
    this.endRecord = endRecord;
  }
}
