import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { PanelModule } from "../panel/panel.module";
import { MemberGridComponent } from "./member-grid.component";
import { MemberIdComponent } from "./member-id.component";

@NgModule({
  imports: [
    CommonModule,
    DynamicFormsModule,
    GridModule,
    PanelModule,
    MenuModule,
  ],
  declarations: [
    MemberGridComponent,
    MemberIdComponent,
  ],
  exports: [
    MemberGridComponent,
    MemberIdComponent,
  ],
})
export class MemberGridModule { }
