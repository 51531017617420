import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class DocumentBulkUpdate {
  readonly documentIds: string[];
  readonly documentStateId?: number;
  readonly action: number;
  readonly documentBulkActionType: number;
  readonly assignToUserId?: number;
  readonly documentNotes: string;

  constructor(options: {
    documentIds: string[];
    documentStateId?: number;
    action: number;
    documentBulkActionType: number;
    assignToUserId?: number;
    documentNotes: string;
  }) {
    this.documentIds = options.documentIds;
    this.documentStateId = options.documentStateId;
    this.action = options.action;
    this.documentBulkActionType = options.documentBulkActionType;
    this.assignToUserId = options.assignToUserId;
    this.documentNotes = StringHelper.clean(options.documentNotes);
  }
}
