import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { StepsMenuItem } from "./steps-menu-item";

@Component({
  selector: "app-steps",
  templateUrl: "./steps-menu.component.html",
  styleUrls: ["./steps-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsMenuComponent implements OnChanges {
  @Input() activeIndex = 0;
  @Input() model: StepsMenuItem[];
  @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeIndex && changes.activeIndex.isFirstChange()) {
      return;
    }

    if (changes.activeIndex && changes.activeIndex.currentValue !== changes.activeIndex.previousValue) {
      let previousValue = changes.activeIndex.previousValue;
      previousValue = previousValue > this.model.length - 1 ? this.model.length - 1 : previousValue;
      this.model[previousValue].complete = true;
    }
  }

  itemClick(event: Event, item: StepsMenuItem, i: number): void {
    event.preventDefault();
    event.stopPropagation();

    if (item.complete) {
      this.activeIndexChange.emit(i);
    }
  }

  menuItemClass(item: StepsMenuItem, i: number): any {
    return {
      "steps-menu__item--highlight": (i === this.activeIndex),
      "steps-menu__item--default": (!item.complete),
      "steps-menu__item--complete": (i !== this.activeIndex && item.complete),
    };
  }

  trackByIndex(index, item) {
    return index;
  }
}
