import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "member-diagnosis-slider-eve",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderEVEComponent implements OnInit {

  @Input() coincidenceVal = "0";
  sliderVal = 0;

  ngOnInit(): void {
    this.sliderVal = Math.floor(Number(this.coincidenceVal) * 100);
  }
}
