<div class="health-card">
  <app-basic-grid [configuration]="healthCardGridConfiguration" [data]="healthCardGridData"
    [rowExpandTemplate]="rowExpandTemplate">

    <ng-template #rowExpandTemplate let-rowData>
      <app-basic-grid class="grid-row-expansion" [configuration]="icdGridConfiguration"
        [data]="rowData.healthCardDiagnosesData">
      </app-basic-grid>
    </ng-template>

    <ng-template #netNewHccColumn let-rowData let-rowIndex="index">
      <div class="health-card__netNewHCC">
        <app-icon iconName="check" *ngIf="isNetNewHCC(rowData)"></app-icon>
      </div>
    </ng-template>

  </app-basic-grid>
</div>