import { IAutoMapper } from "../../core/automapper/interfaces";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { DateHelper } from "../../utilities/contracts/date-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { DocumentRequestStatus, getDocumentRequestStatusName } from "./document-request-status.enum";

export const mapDocumentRequest = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentRequest")
    .forMember("documentRequestId", o => o.documentRequestId)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("chaseId", o => o.chaseId)
    .forMember("documentTypeId", o => o.documentTypeId)
    .forMember("documentTypeName", o => o.documentTypeName)
    .forMember("documentsRequested", o => o.documentsRequestedXml.requiredDocuments)
    .forMember("numberOfDocumentsRequested", o => o.numberOfDocumentsRequested)
    .forMember("status", o => o.status)
    .forMember("note", o => o.note)
    .forMember("createdDate", o => o.createdDate)
    .forMember("createdById", o => o.createdById)
    .forMember("createdByName", o => o.createdByName);
};

export class DocumentRequest {
  documentRequestId: number;
  masterDocumentSourceId: number;
  chaseId: number;
  // entityId: number | null;
  documentTypeId: number;
  documentTypeName: string;
  documentsRequested?: SelectableInput[] | any[];
  numberOfDocumentsRequested: number;
  status: DocumentRequestStatus;
  note: string;
  createdDate: Date;
  createdById: number;
  createdByName: string;


  constructor(options: {
    documentRequestId?: number;
    masterDocumentSourceId?: number;
    chaseId?: number;
    documentTypeId?: number;
    documentTypeName?: string;
    documentsRequested?: SelectableInput[] | any[];
    numberOfDocumentsRequested?: number;
    status?: DocumentRequestStatus;
    note?: string;
    createdDate?: Date;
    createdById?: number;
    createdByName?: string;
  } = {}) {
    this.documentRequestId = options.documentRequestId;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.chaseId = options.chaseId;
    this.documentTypeId = options.documentTypeId;
    this.documentTypeName = StringHelper.clean(options.documentTypeName);
    this.documentsRequested = options.documentsRequested;
    this.numberOfDocumentsRequested = options.numberOfDocumentsRequested;
    this.status = options.status;
    this.note = StringHelper.clean(options.note);
    this.createdDate = DateHelper.create(options.createdDate);
    this.createdById = options.createdById;
    this.createdByName = StringHelper.clean(options.createdByName);
  }


  get statusName(): string {
    if (this.status == null) {
      return "";
    }

    const statusName = getDocumentRequestStatusName(this.status);
    return statusName;
  }
}
