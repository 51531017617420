import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { Provider } from "../../../../provider/provider.model";
import { ListItem } from "../../../../shared/list/list-item";
import { MemberCentricChase } from "../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { obsolete } from "../../../../utilities/decorators/obsolete.decorator";
import { DynamicEntity } from "../../../api/member-validation/dynamic-entity.model";
import { WorkflowStatusDb } from "../../../api/workflow/workflow-status-db.enum";
import { ChaseSubmissionItem } from "../../project/project-config/models/chase-submission-item.model";
import { ProjectType } from "../../project/project-type.enum";
import { ChaseDetailInfoPursuitItemModel } from "./chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuit-item/chase-detail-info-pursuit-item-model";

export const mapChaseDetail = (automapper: IAutoMapper): void => {
  automapper
  .createMap("default", "ChaseDetail")
    .forMember("addressId", o => o.addressId)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("assignedToName", o => o.assignedToName)
    .forMember("chaseEntityData", o => automapper.mapMany("default", "DynamicEntity", o.chaseEntityData))
    .forMember("chaseDocumentAnnotation", o => o.chaseDocumentAnnotation)
    .forMember("chaseDocumentId", o => o.chaseDocumentId)
    .forMember("chaseId", o => o.chaseId)
    .forMember("chaseOverreadFeedback", o => o.chaseOverreadFeedback)
    .forMember("chasePendId", o => o.chasePendId)
    .forMember("chaseSourceAliasId", o => o.chaseSourceAliasId)
    .forMember("chaseSubmissionItem", o => o.chaseSubmissionItem)
    .forMember("clientId", o => o.clientId)
    .forMember("clientName", o => o.clientName)
    .forMember("coRetrievalOwner", o => o.coRetrievalOwner)
    .forMember("data", o => automapper.mapMany("default", "RiskEntity", o.data))
    .forMember("diagnosisValidationReasonCodes", o => o.diagnosisValidationReasonCodes)
    .forMember("displayNlpResults", o => o.displayNlpResults)
    .forMember("displayOcrBlocks", o => o.displayOcrBlocks)
    .forMember("documentSourceId", o => o.documentSourceId)
    .forMember("documentSourceTypeId", o => o.documentSourceTypeId)
    .forMember("enrolleeId", o => o.enrolleeId)
    .forMember("gapClosureEnabled", o => o.gapClosureEnabled)
    .forMember("gapProviderOutreachEnabled", o => o.gapProviderOutreachEnabled)
    .forMember("gapPursuitRequiresApproval", o => o.gapPursuitRequiresApproval)
    .forMember("groupName", o => o.groupName)
    .forMember("hccModelDisplayName", o => o.hccModelDisplayName)
    .forMember("hccModelLineOfBusiness", o => o.hccModelLineOfBusiness)
    .forMember("isFirstPassBotEnabled", o => o.isFirstPassBotEnabled)
    .forMember("isMemberCentric", o => o.isMemberCentric)
    .forMember("isProjectEnabledForRiskNlp", o => o.isProjectEnabledForRiskNlp)
    .forMember("keyValueItems", o => automapper.mapMany("default", "ListItem", o.keyValueItems))
    .forMember("lastCoder", o => o.lastCoder)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("masterMemberId", o => o.masterMemberId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("measureId", o => o.measureId)
    .forMember("measureName", o => o.measureName)
    .forMember("measureYear", o => o.measureYear)
    .forMember("memberAgeByMeasureYear", o => o.memberAge)
    .forMember("memberCentricChases", o => automapper.mapMany("default", "MemberCentricChase", o.memberCentricChases))
    .forMember("memberDateOfBirth", o => o.memberDateOfBirth)
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberGender", o => o.memberGender)
    .forMember("memberId", o => o.memberId)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberSourceAliasId", o => o.memberSourceAliasId)
    .forMember("memberValidationReason", o => o.memberValidationReason)
    .forMember("memberVerified", o => o.memberVerified)
    .forMember("negativeReasonCodeRequired", o => o.negativeReasonCodeRequired)
    .forMember("numeratorList", o => o.numeratorList)
    .forMember("ocrDataAvailable", o => o.ocrDataAvailable)
    .forMember("owner", o => o.owner)
    .forMember("parentChaseId", o => o.parentChaseId)
    .forMember("pendCode", o => o.pendCode)
    .forMember("pendName", o => o.pendName)
    .forMember("pendOwner", o => o.pendOwner)
    .forMember("pendStatusId", o => o.pendStatusId)
    .forMember("pendStatusName", o => o.pendStatusName)
    .forMember("product", o => o.product)
    .forMember("projectDocumentReviewLevel", o => o.projectDocumentReviewLevel)
    .forMember("projectDuration", o => o.projectDuration)
    .forMember("projectId", o => o.projectId)
    .forMember("projectName", o => o.projectName)
    .forMember("projectTypeId", o => o.projectTypeId)
    .forMember("projectTypeName", o => o.projectTypeName)
    .forMember("providers", o => automapper.mapMany("default", "Provider", o.providers))
    .forMember("pursuitData", o => automapper.mapMany("default", "ChaseDetailInfoPursuitItem", o.pursuitData))
    .forMember("reportingStatusName", o => o.reportingStatusName)
    .forMember("userDefinedValue", o => o.userDefinedValue)
    .forMember("workflowStatus", o => o.workflowStatusId)
    .forMember("workflowStatusId", o => o.workflowStatusId)
    .forMember("workflowStatusName", o => o.workflowStatusName);
  };

export class ChaseDetail {
  @obsolete() workflowStatusId: WorkflowStatusDb;
  addressId: number;
  assignedToUserId: number;
  assignedToName?: string;
  chaseEntityData?: DynamicEntity[];
  chaseDocumentAnnotation: boolean;
  chaseDocumentId: number;
  chaseId: number;
  chaseOverreadFeedback: boolean;
  chasePendId: number;
  chaseSourceAliasId: string;
  chaseSubmissionItem: ChaseSubmissionItem[];
  clientId: number;
  clientName: string;
  coRetrievalOwner: string;
  data: any[];
  diagnosisValidationReasonCodes: string[];
  displayNlpResults: boolean;
  displayOcrBlocks: boolean;
  documentSourceId: number;
  documentSourceTypeId: number;
  enrolleeId: string;
  gapClosureEnabled: boolean;
  gapProviderOutreachEnabled: boolean;
  gapPursuitRequiresApproval: boolean;
  groupName: string;
  hccModelDisplayName: string;
  hccModelLineOfBusiness: string;
  isFirstPassBotEnabled: boolean;
  isMemberCentric: boolean;
  isProjectEnabledForRiskNlp: boolean;
  keyValueItems: ListItem[];
  lastCoder: string;
  masterDocumentSourceId: number;
  masterMemberId: number;
  measureCode: string;
  measureId: number;
  measureName: string;
  measureYear: number;
  memberAgeByMeasureYear: number;
  memberCentricChases: MemberCentricChase[];
  memberDateOfBirth: Date;
  memberFirstName: string;
  memberGender: "F" | "M";
  memberId: number;
  memberLastName: string;
  memberSourceAliasId: string;
  memberValidationReason: string;
  memberVerified: any;
  negativeReasonCodeRequired: boolean;
  numeratorList: string;
  ocrDataAvailable: boolean;
  owner: string;
  parentChaseId: number;
  pendCode: string;
  pendName: string;
  pendOwner: string;
  pendStatusId: number;
  pendStatusName: string;
  product: string;
  projectDocumentReviewLevel?: number;
  projectDuration: string;
  projectId: number;
  projectName: string;
  projectTypeId: ProjectType;
  projectTypeName: string;
  providers: Provider[];
  pursuitData: ChaseDetailInfoPursuitItemModel[];
  reportingStatusName: string;
  userDefinedValue: string;
  workflowStatus: WorkflowStatusDb;
  workflowStatusName: string;

  constructor(options: Partial<ChaseDetail> = {}) {
    this.addressId = options.addressId;
    this.assignedToUserId = options.assignedToUserId;
    this.assignedToName = StringHelper.clean(options.assignedToName);
    this.chaseEntityData = ArrayHelper.clean(options.chaseEntityData);
    this.chaseDocumentAnnotation = BooleanHelper.tryGet(options.chaseDocumentAnnotation, false);
    this.chaseDocumentId = options.chaseDocumentId;
    this.chaseId = options.chaseId;
    this.chaseOverreadFeedback = BooleanHelper.tryGet(options.chaseOverreadFeedback, false);
    this.chasePendId = options.chasePendId;
    this.chaseSourceAliasId = StringHelper.clean(options.chaseSourceAliasId);
    this.chaseSubmissionItem = ArrayHelper.clean(options.chaseSubmissionItem);
    this.clientId = options.clientId;
    this.clientName = StringHelper.clean(options.clientName);
    this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
    this.data = ArrayHelper.clean(options.data);
    this.diagnosisValidationReasonCodes = ArrayHelper.clean(options.diagnosisValidationReasonCodes);
    this.displayNlpResults = BooleanHelper.tryGet(options.displayNlpResults, false);
    this.displayOcrBlocks = BooleanHelper.tryGet(options.displayOcrBlocks, false);
    this.documentSourceId = options.documentSourceId;
    this.documentSourceTypeId = options.documentSourceTypeId;
    this.enrolleeId = StringHelper.clean(options.enrolleeId);
    this.groupName = StringHelper.clean(options.groupName);
    this.hccModelDisplayName = options.hccModelDisplayName;
    this.hccModelLineOfBusiness = options.hccModelLineOfBusiness;
    this.isFirstPassBotEnabled = BooleanHelper.tryGet(options.isFirstPassBotEnabled, false);
    this.isMemberCentric = BooleanHelper.tryGet(options.isMemberCentric, false);
    this.isProjectEnabledForRiskNlp = BooleanHelper.tryGet(options.isProjectEnabledForRiskNlp, false);
    this.keyValueItems = ArrayHelper.clean(options.keyValueItems);
    this.lastCoder = StringHelper.clean(options.lastCoder);
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.masterMemberId = options.masterMemberId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.measureId = options.measureId;
    this.measureName = StringHelper.clean(options.measureName);
    this.measureYear = options.measureYear;
    this.memberAgeByMeasureYear = options.memberAgeByMeasureYear;
    this.memberCentricChases = ArrayHelper.clean(options.memberCentricChases);
    this.memberDateOfBirth = DateHelper.removeTime(options.memberDateOfBirth);
    this.memberFirstName = StringHelper.clean(options.memberFirstName);
    this.memberGender = options.memberGender;
    this.memberId = options.memberId;
    this.memberLastName = StringHelper.clean(options.memberLastName);
    this.memberSourceAliasId = StringHelper.clean(options.memberSourceAliasId);
    this.memberValidationReason = StringHelper.clean(options.memberValidationReason);
    this.memberVerified = options.memberVerified;
    this.negativeReasonCodeRequired = BooleanHelper.tryGet(options.negativeReasonCodeRequired, true);
    this.numeratorList = StringHelper.clean(options.numeratorList);
    this.ocrDataAvailable = BooleanHelper.tryGet(options.ocrDataAvailable, false);
    this.owner = StringHelper.clean(options.owner);
    this.parentChaseId = options.parentChaseId;
    this.pendCode = StringHelper.clean(options.pendCode);
    this.pendName = StringHelper.clean(options.pendName);
    this.pendOwner = StringHelper.clean(options.pendOwner);
    this.pendStatusId = options.pendStatusId;
    this.pendStatusName = StringHelper.clean(options.pendStatusName);
    this.product = StringHelper.clean(options.product);
    this.projectDocumentReviewLevel = options.projectDocumentReviewLevel;
    this.projectDuration = StringHelper.clean(options.projectDuration);
    this.projectId = options.projectId;
    this.projectName = StringHelper.clean(options.projectName);
    this.projectTypeId = options.projectTypeId;
    this.projectTypeName = StringHelper.clean(options.projectTypeName);
    this.providers = ArrayHelper.clean(options.providers);
    this.pursuitData = ArrayHelper.clean(options.pursuitData);
    this.reportingStatusName = StringHelper.clean(options.reportingStatusName);
    this.userDefinedValue = StringHelper.clean(options.userDefinedValue);
    this.workflowStatus = options.workflowStatus;
    this.workflowStatusId = options.workflowStatusId;
    this.workflowStatusName = StringHelper.clean(options.workflowStatusName);
  }
}
