import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { AbaHeightGroupComponent } from "./aba-height-group.component";

export interface IAbaHeightGroupOptions extends IDynamicGroupOptions {
  memberDateOfBirth?: string;
}

export class AbaHeightGroup extends DynamicGroup implements IAbaHeightGroupOptions {
  readonly controlType = "aba-height-group";
  readonly componentType = AbaHeightGroupComponent;
  memberDateOfBirth: string;


  constructor(options: IAbaHeightGroupOptions = {}) {
    super(options);
    this.memberDateOfBirth = options.memberDateOfBirth;
  }
}
