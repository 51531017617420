<app-detail-header-template>
  <div top-left>
    <div class="container-back-button">
      <app-back-button></app-back-button>
    </div>
    <h3 class="container-title">
      {{pendText | uppercase}} - {{pendCode}} {{pendCodeName}}
    </h3>
  </div>
  <div top-right>
    <div class="member-name-container bold">
      <app-list [items]="memberItems"></app-list>
    </div>
  </div>
  <div bottom-left>
    <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
  </div>  
  <div bottom-right>
    <div *ngIf="isUpdatePendView">
      <app-internal-pends-update-pend [isUpdatePendVisible]="isUpdatePendView" (formClose)="updatePendClose($event)" [owner]="owner" [pendCode]="pendCode" [pendStatusId]="pendStatusId"></app-internal-pends-update-pend>
    </div>
    <div class="container-button">
      <app-button [ngClass]="!isClientUser ? 'show-status-change-button':'hide-status-change-button'" classes="primary-action" class="header-button" text="UPDATE PEND" [icon]="iconName" (onClick)="updatePend()" [disabled]="disabled"></app-button>
    </div>
    </div>  
</app-detail-header-template>
