<div [formGroup]="formGroup" class="control control--input control--switch" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
  </div>
  <div *ngIf="hasSubLabel" class="control__header">
    <label class="control__header__label control__header__label--sublabel" [for]="model.key" [title]="model.subLabel">{{ model.subLabel | titlecase }}</label>
  </div>
  <div class="inline-display">
    <div *ngIf="hasPreceedingOffLabel" class="control__header">
      <label class="control__header__label control__floating-label--left" [for]="model.key" [title]="model.preceedingOffLabel">{{ model.preceedingOffLabel | titlecase }}</label>
    </div>
    <p-inputSwitch [name]="model.key"
                   [inputId]="model.key"
                   [styleClass]="classes"
                   (onChange)="onChange.emit($event); onInput.emit($event);"
                   [formControlName]="model.key">
    </p-inputSwitch>
    <div *ngIf="hasFollowingOnLabel" class="control__header">
      <label class="control__header__label control__floating-label--right" [for]="model.key" [title]="model.followingOnLabel">{{ model.followingOnLabel | titlecase }}</label>
    </div>
  </div>
</div>
