import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { RetrievalPageService } from "../../../../platform/modules/retrieval/retrieval-page/retrieval-page.service";
import { LandingFilter } from "../../model/landingFliter.model";

@Component({
  selector: "app-landing-filter",
  templateUrl: "./landing-filter.component.html",
  styleUrls: ["./landing-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingFilterComponent implements OnInit {
  @Output() addFilter = new EventEmitter<LandingFilter>();
  filterOptions: SelectableInput[];
  filterValues: SelectableInput[];
  clientId: number;
  projectId: number;
  landingFilter: LandingFilter;
  filterForm: FormGroup;
  filterTypeInput: Dropdown;
  filterInput: Dropdown;

  constructor(
    private readonly userService: UserService,
    private readonly automapper: AutomapperService,
    private retrievalService: RetrievalPageService,
    private changeDetector: ChangeDetectorRef,
    private formService: FormService
  ) {
    this.filterOptions = [
      new SelectableInput({ text: "Clients", value: 1 }),
      new SelectableInput({ text: "Projects", value: 2 })];
  }

  ngOnInit() {
    this.initializeForm();
    this.onSelectFilterType(this.selectedFilterOption);
  }

  applyFilter(): void {
    if (this.selectedFilter) {
      if (this.selectedFilterOption === 1) {
        this.clientId = this.selectedFilter;
        this.projectId = null;
      } else if (this.selectedFilterOption === 2) {
        this.projectId = this.selectedFilter;
        this.clientId = null;
      }
      this.landingFilter = new LandingFilter({
        clientId: this.clientId,
        projectId: this.projectId,
      });
      this.addFilter.emit(this.landingFilter);
    }
  }

  private getClientOrgs(): void {
    this.userService
      .getClients()
      .pipe(
        map(this.automapper.curryMany("ClientOrgModel", "SelectableInput"))
      )
      .subscribe(options => {
        this.filterValues = options;
        this.filterInput = new Dropdown({ ...this.filterInput, options: this.filterValues } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getProjectList(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.filterValues = result;
        this.filterInput = new Dropdown({ ...this.filterInput, options: this.filterValues } as any);
        this.changeDetector.markForCheck();
      });
  }

  onSelectFilterType(event): void {
    if (event) {
      this.filterForm.get(this.filterTypeInput.key).setValue(event.value);
      if (this.selectedFilterOption === 1) {
        this.getClientOrgs();
      } else if (this.selectedFilterOption === 2) {
        this.getProjectList();
      }
    } else {
      this.filterValues = [];
    }
    this.changeDetector.markForCheck();
  }

  onSelectFilter(event): void {
    if (event) {
      this.filterForm.get(this.filterInput.key).setValue(event.value);
    }
  }

  clearFilters(): void {
    this.filterValues = [];
    this.filterForm.reset();
    this.landingFilter = new LandingFilter({
      clientId: null,
      projectId: null,
    });
    this.addFilter.emit(this.landingFilter);
  }

  initializeForm(): void {
    this.filterTypeInput = new Dropdown({
      key: "filterType",
      label: "",
      placeholder: "Filter By",
      options: this.filterOptions,
    });

    this.filterInput = new Dropdown({
      key: "filter",
      label: "",
      placeholder: "Select",
    });

    this.filterForm = this.formService.createFormGroup([this.filterTypeInput, this.filterInput]);
  }

  get selectedFilterOption(): number {
    return Number(this.filterForm.get(this.filterTypeInput.key).value);
  }

  get selectedFilter(): number {
    return Number(this.filterForm.get(this.filterInput.key).value);
  }
}
