export class UserAttributeDisplayModel {
  readonly attributeId: number;
  readonly attributeName: string;
  readonly displayName: string;
  readonly roleIds: number[];
  readonly required: boolean;
  readonly displayType: string;
  readonly valueList: any[];

  constructor(options: {

    attributeId: number;
    attributeName: string;
    displayName: string;
    roleIds: number[];
    required: boolean;
    displayType: string;
    valueList: any[];

  }) {

    this.attributeId = options.attributeId;
    this.attributeName = options.attributeName;
    this.displayName = options.displayName;
    this.roleIds = options.roleIds;
    this.required = options.required;
    this.displayType = options.displayType;
    this.valueList = options.valueList;

  }
}
