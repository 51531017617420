import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-special-handling",
  templateUrl: "./special-handling.component.html",
  styleUrls: [ "./special-handling.component.scss" ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialHandlingComponent {
  @Input() specialHandlingReason: string;
  @Input() specialHandlingAdditionalNote: string;
  specialHandlingShortNote: string;
  constructor() { }

  get specialHandlingTitle(): string {
    return this.specialHandlingReason.length > 250 ? this.specialHandlingReason : "";
  }
  get specialHandlingNote(): string {
    if (StringHelper.isAvailable(this.specialHandlingAdditionalNote)) {
      const specialHandlingShortNote = this.specialHandlingAdditionalNote;
      return `Additional Note: ${specialHandlingShortNote.length > 100 ? `${specialHandlingShortNote.substring(0, 100)})...` : specialHandlingShortNote}`;
    }
    return "";
  }

}
