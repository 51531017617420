import { ChangeDetectionStrategy,  Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-open-gaps-by-member",
  templateUrl: "./open-gaps-by-member.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenGapsByMemberComponent {

    filters: string[];
    dashboardType = LookerRiskDashboardType.RISK_OPEN_GAPS_BY_MEMBER;

    constructor() {
        this.filters = ["Projects", "Hcc", "Products", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob" , "MemberKey", "Address", "Npi", "ChaseId"];
    }
}
