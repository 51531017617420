import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { IconModule } from "../icon/icon.module";
import { PanelModule } from "../panel/panel.module";
import { ColorPaletteManageComponent } from "./color-palette-manage/color-palette-manage.component";
import { ColorPaletteComponent } from "./color-palette/color-palette.component";
import { CreateTagComponent } from "./create-tag/create-tag.component";
import { ManageTagModalComponent } from "./manage-tag-modal/manage-tag-modal.component";
import { TagManageComponent } from "./tag-manage/tag-manage.component";
import { ViewTagsComponent } from "./view-tags.component";

@NgModule({
  imports: [
    CommonModule,
    DynamicFormsModule,
    IconModule,
    PanelModule,
    ButtonModule,
  ],
  declarations: [
    ViewTagsComponent,
    CreateTagComponent,
    ColorPaletteComponent,
    ManageTagModalComponent,
    TagManageComponent,
    ColorPaletteManageComponent,
  ],
  exports: [
    ViewTagsComponent,
    CreateTagComponent,
    ColorPaletteComponent,
    ManageTagModalComponent,
    TagManageComponent,
    IconModule,
    PanelModule,
    ColorPaletteManageComponent,
  ],
})
export class TagModule { }
