import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { SessionService } from "../../../../../core/storage/session.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { GridStateService } from "../../../../../shared/grid/grid-state.service";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { MeasureService } from "../../../../api/measure/measure.service";
import { NumeratorService } from "../../../../api/numerator/numerator.service";
import { ChartService } from "../../../member/chase-detail/chase-detail-chart/chart.service";
import { RetrievalPageService } from "../../../retrieval/retrieval-page/retrieval-page.service";
import { AuditPackageGenerateService } from "../audit-package-generate/audit-package-generate.service";
import { AuditQueryGridService } from "../audit-query-grid/audit-query-grid.service";
import { AuditQueryGridFilters } from "./audit-query-grid-filter-model";

@Component({
  selector: "app-audit-query-grid-filters",
  templateUrl: "./audit-query-grid-filters.component.html",
  styleUrls: ["./audit-query-grid-filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryGridFiltersComponent implements OnInit {
  @Input() stateName = "";
  formGroupAuditQueryFilters: FormGroup;
  projects: Dropdown;
  measures: Dropdown;
  numerators: Dropdown;
  complianceCodes: Dropdown;
  landingFilters: GridFilter[] = [];
  private selectedProjectId: number;
  constructor(
    private readonly formService: FormService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly measureService: MeasureService,
    private readonly numeratorService: NumeratorService,
    private readonly chartService: ChartService,
    private readonly auditQueryGridService: AuditQueryGridService,
    private readonly auditPackageGenerateService: AuditPackageGenerateService,
    private readonly automapper: AutomapperService,
    private readonly gridStateService: GridStateService,
    private sessionService: SessionService
  ) {
    this.projects = new Dropdown({
      key: "ProjectId",
      placeholder: "Select a Project...",
      validators: Validators.required,
      errorMessages: {
        required: "Project is required.",
      },
    });

    this.measures = new Dropdown({
      key: "MeasureId",
      placeholder: "Select a Measure...",
    });

    this.numerators = new Dropdown({
      key: "NumeratorId",
      placeholder: "Select a Numerator...",
    });

    this.complianceCodes = new Dropdown({
      key: "ComplianceCode",
      placeholder: "Select a Compliance Code...",
    });
  }

  ngOnInit() {
    this.getProjectList();
    this.getComplianceCodes();
    this.createForm();
    this.prefillFiltersFromState();
  }

  private getProjectList(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(projects => {
        this.projects = new Dropdown({ ...this.projects, options: projects } as any);
        this.formService.updateDom.next();
    });
  }

  private getComplianceCodes(): void {
    const isAuditGrid = true;
    this.chartService.getComplianceCodes(isAuditGrid)
      .subscribe(complianceCodes => {
        this.complianceCodes = new Dropdown({ ...this.complianceCodes, options: complianceCodes } as any);
        this.formService.updateDom.next();
      });
  }

  private getMeasureList(projectId: number): void {
    this.measureService.getMeasuresList(projectId)
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(measures => {
        this.measures = new Dropdown({ ...this.measures, options: measures } as any);

        if (this.prefillFiltersAny && this.landingFilters.find(f => f.key === "MeasureId") != null) {
          const measureId = +this.landingFilters.find(f => f.key === "MeasureId").input.value;
          this.formGroupAuditQueryFilters.get("MeasureId").setValue(measureId);

          this.measureSelectionChange(measureId);

        }

        this.formService.updateDom.next();

      });
  }

  private getNumeratorList(measureId: number): void {
    this.numeratorService.getNumeratorList(this.selectedProjectId, measureId)
      .pipe(map(this.automapper.curryMany("NumeratorListItem", "SelectableInput")))
      .subscribe(numerators => {
        this.numerators = new Dropdown({ ...this.numerators, options: numerators } as any);

        if (this.prefillFiltersAny && this.landingFilters.find(f => f.key === "NumeratorId") != null) {
          const numeratorId = +this.landingFilters.find(f => f.key === "NumeratorId").input.value;
          this.formGroupAuditQueryFilters.get("NumeratorId").setValue(numeratorId);

        }
        this.formService.updateDom.next();
      });
  }

  get prefillFiltersAny(): boolean {
    return ArrayHelper.isAvailable(this.landingFilters);
  }

  prefillFiltersFromState(): void {
    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName)) {
      const gridState = this.gridStateService.get(this.stateName);
      this.landingFilters = gridState.request.filters.filter(filter => this.getSelectedFilters(filter.input.value));
      if (this.landingFilters.length > 0) {
        if (this.landingFilters.find(f => f.key === "ProjectId") != null) {
          const projectId = +this.landingFilters.find(f => f.key === "ProjectId").input.value;
          this.formGroupAuditQueryFilters.get("ProjectId").setValue(projectId);

          this.projectSelectionChange(projectId);

          this.auditPackageGenerateService.refreshAuditPackageGrid(projectId);

        }

        if (this.landingFilters.find(f => f.key === "ComplianceCode") != null) {
          const complianceCode = this.landingFilters.find(f => f.key === "ComplianceCode").input.value;
          this.formGroupAuditQueryFilters.get("ComplianceCode").setValue(complianceCode);
        }
      }
    }
  }

  private getSelectedFilters(filterValue): any {
    if (Array.isArray(filterValue)) {
      if (ArrayHelper.isAvailable(filterValue)) {
        return true;
      } else {
        return false;
      }
    }
    return filterValue;
  }

  projectSelectionChange($event: SelectableInput | number): void {
    this.selectedProjectId = typeof($event) === "number" ? $event :  Number($event.value);
    this.getMeasureList(this.selectedProjectId);
  }

  measureSelectionChange($event: SelectableInput | number): void {
    const measureId = typeof ($event) === "number" ? $event : Number($event.value);
    this.getNumeratorList(measureId);
  }

  get isValid(): boolean {
    if (this.formGroupAuditQueryFilters == null) {
      return false;
    }

    return this.formGroupAuditQueryFilters.valid;
  }

  get hasForm(): boolean {
    return this.formGroupAuditQueryFilters != null;
  }

  private get projectId(): number {
    if (!this.hasForm) {
      return null;
    }

    return +this.formGroupAuditQueryFilters.get("ProjectId").value;
  }

  private get measureId(): number | null {
    if (!this.hasForm) {
      return null;
    }

    return this.formGroupAuditQueryFilters.get("MeasureId").value;
  }

  private get numeratorId(): number | null {
    if (!this.hasForm) {
      return null;
    }

    return this.formGroupAuditQueryFilters.get("NumeratorId").value;
  }

  private get complianceCode(): string | null {
    if (!this.hasForm) {
      return null;
    }

    return this.formGroupAuditQueryFilters.get("ComplianceCode").value;
  }

  applyFilters(): void {
    if (this.isValid) {
      const filter = new AuditQueryGridFilters({
        projectId: this.projectId,
        measureId: this.measureId,
        numeratorId: this.numeratorId,
        complianceCode: this.complianceCode,
      });

      this.auditQueryGridService.resetGridFilter(filter);
      this.auditPackageGenerateService.refreshAuditPackageGrid(this.projectId);
    }
  }

  clearFilters(): void {
    this.gridStateService.delete(this.stateName);
    this.landingFilters = [];

    this.formGroupAuditQueryFilters.reset();

    this.auditQueryGridService.resetGridFilter(new AuditQueryGridFilters());
    this.measures = new Dropdown({ ...this.measures, options: null } as any);
    this.numerators = new Dropdown({ ...this.numerators, options: null } as any);

    this.auditPackageGenerateService.refreshAuditPackageGrid(null);
  }

  private createForm(): void {
    this.formGroupAuditQueryFilters = this.formService.createFormGroup([
      this.projects,
      this.measures,
      this.numerators,
      this.complianceCodes,
    ]);
  }
}
