import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { DateFormats } from "../../../../../utilities/contracts/helper-types";
import { ChaseSearchRequest } from "../../../../api/chase-search/chase-search-request-model";
import { ChaseItem } from "../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../api/chase-search/chase-search.service";
import { DocumentUploadRequestObjectType } from "../../../../api/document-upload/document-upload-request-objecttype.enum";
import { DocumentUploadRequest } from "../../../../api/document-upload/document-upload-request.model";
import { DocumentUploadService } from "../../../../api/document-upload/document-upload.service";
import { ChaseUploadDocumentService } from "../../../retrieval/chase-upload-document/chase-upload-document.service";
import { DocumentTypeName } from "../../../retrieval/retreival-document-review/document-type.enum";

@Component({
  selector: "app-upload-documents",
  templateUrl: "./upload-documents.component.html",
  styleUrls: ["./upload-documents.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentsComponent implements OnInit {

  selectedTab = "chasesearch";
  chaseGridConfiguration = new GridConfiguration();
  chaseGridData: any[] = [];
  chaseGridSelection: ChaseItem;
  private gridSearchRequest: any;

  myPopover: EventEmitter<any>;

  private gridStartRecord = 1;
  private gridEndRecord = 500;
  private AllRecords = 2147483647;
  private pageSize = 10;

  @Output() formClose = new EventEmitter();

  readonly form: FormGroup;
  readonly chaseModels: any[] = [];
  readonly memberModels: any[] = [];

  readonly providerModels: any[] = [];
  private modelErrors: any[] = [];

  selectedFileList: any[] = [];
  formData: FormData = new FormData();
  @ViewChild("chaseGridactionTemplate", { static: true }) template: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private chaseService: ChaseSearchService,
    private readonly formService: FormService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private messagingService: MessagingService,
    private service: ChaseUploadDocumentService,
    private documentUploadService: DocumentUploadService

  ) {


    // Forms setup
    this.chaseModels = [
      new Textbox({
        key: "chaseId",
        label: "Chase Id",
        validators: [Validators.pattern(/^-?(0|[1-9]\d*)?$/)],

      }),
    ];

    this.memberModels = [

      new Textbox({
        key: "memberName",
        label: "Patient Name",
        validators: [Validators.minLength(3), Validators.maxLength(100)],
      }),
      new Textbox({
        key: "memberDOB",
        label: "DOB",
        validators: [Validators.pattern(/^\d{1,2}\/\d{1,2}\/\d{4}$/)],
        placeholder: "MM/DD/YYYY",

      }),
    ];
    this.providerModels = [

      new Textbox({
        key: "providerName",
        label: "Provider Name",
        validators: [Validators.minLength(3), Validators.maxLength(100)],

      }),
      new Textbox({
        key: "providerPhone",
        label: "Phone or Fax",
        validators: [Validators.maxLength(50), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
        placeholder: "XXX-XXX-XXXX",
      }),

    ];

    // TODO: do this until dynamic forms handles error display
    this.modelErrors = [
      { key: "chaseId", message: "Chase Id must be numeric" },
      { key: "memberName", message: "Invalid Patient Name.  Minimum 3 characters and Maximum 100 characters allowed" },
      { key: "memberDOB", message: "DOB Must be in format MM/DD/YYYY" },
      { key: "providerName", message: "Invalid Provider Name. Maximum 100 characters allowed" },
      { key: "providerPhone", message: "Invalid Provider Phone. Must be in format 123-555-1234. Maximum 50 characters allowed" },

    ];

    this.form = formService.createFormGroup(this.chaseModels.concat(this.memberModels.concat(this.providerModels)));


    this.chaseGridConfiguration.columns = [

      new GridColumnDefinition({ field: "chaseID", header: "Chase", width: "8%" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", width: "8%", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
      new GridColumnDefinition({ field: "workflowStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "filename", header: "File Uploaded", width: "20%" }),
    ];

    this.chaseGridConfiguration.pageSize = this.pageSize;
    this.chaseGridConfiguration.selectionMode = "single";
    this.chaseGridConfiguration.showActionColumn = true;

  }


  ngOnInit() {

  }

  search() {
    // TODO: MemberName is obsoleted to filter chases?
    this.gridSearchRequest =
      new ChaseSearchRequest("chaseId", "desc", this.form.controls.chaseId.value, null, null, null, this.form.controls.memberName.value,
                             this.form.controls.memberDOB.value, this.form.controls.providerName.value,
                             this.form.controls.providerPhone.value, this.form.controls.providerPhone.value,
                             this.gridEndRecord, 0, this.gridEndRecord);

    this.chaseService
      .chaseSearch(this.gridSearchRequest)
      .subscribe(items => {
        this.chaseGridData = items as any;
        for (const item of this.chaseGridData) {
          item.filename = "";

        }
        this.changeDetector.markForCheck();
      });

  }

  showSelector(rowData): boolean {


    if (rowData && rowData.hasOwnProperty("workflowStatusId") && rowData.workflowStatusId >= 4) {

      return false;
    }

    return true;


  }

  onFileSelect(event) {
    for (const file of event.files) {
      this.selectedFileList.push(file);
      this.formData.append("Document", file);
    }
  }

  onFileRemove(event) {
    this.formData = new FormData();

    let i = this.selectedFileList.length;
    while (i--) {
      if (this.selectedFileList[i].name === event.file.name) {
        this.selectedFileList.splice(i, 1);
      } else {
        this.formData.append("Document", this.selectedFileList[i]);
      }
    }
  }

  validateForUpload(rowData: ChaseItem) {

    return true;

  }

  messageForUpload(rowData) {

    if (rowData.workflowStatusId > 4) {

      return true;
    }

    return false;

  }

  uploadDocument(event, fileUpload, rowData, rowIndex, actionPopover): void {
    if (this.selectedFileList !== undefined && this.selectedFileList.length > 0) {

      // want to make sure FormData is clear, even on http errors that get trapped, so catch the dialog when it closes and clear formdata and upload
      actionPopover.onHide.subscribe(ev => {
        this.clearFormData();
      });

      const documentUploadRequest = new DocumentUploadRequest(
        this.selectedFileList[0].name,
        DocumentTypeName.DocTypeMedicalRecord,
        DocumentUploadRequestObjectType.Chase,
        rowData.chaseID,
        rowData.projectID,
        11, // approval center
        "approval center",
        this.formData
      );

      this.documentUploadService.upload(documentUploadRequest).subscribe(
        () => {
          this.chaseGridData[rowIndex].filename = documentUploadRequest.fileName;

          this.changeDetector.markForCheck();
          this.messagingService.showToast("Chase document(s) Uploaded successfully.", SeverityType.SUCCESS);
        },
        err => {
          this.messagingService.showToast("Error while Document uploading, please try again.", SeverityType.ERROR);
        });

       // clear form data always
      this.clearFormData();
    } else {
      this.messagingService.showToast("Please select a required document.", SeverityType.ERROR);
    }
  }

  clearFormData() {

    this.formData = new FormData();
    this.selectedFileList = [];
  }
  trackByIndex(index, item) {

    return index;
  }

  toggleTab(tab: string) {

    this.selectedTab = this.selectedTab === tab ? "" : tab;

  }

  clearControls() {

    for (const i in this.form.controls) {

      if (this.form.controls[i]) {
        this.form.controls[i].setValue(null);
      }
    }

  }


}
