import { IAutoMapper } from "../interfaces";

export const mapCommentItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CommentItem")
    .forMember("commentText", o => o.commentText)
    .forMember("createdByUserName", o => o.createdByUserName)
    .forMember("createdDate", o => o.createdDate)
    .forMember("commentType", o => o.commentType);

};
