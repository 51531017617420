import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { ContactRecord } from "../address-detail-contact-record";

@Injectable({
  providedIn: "root",
})
export class AddressDetailCallService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  getAddressGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.AddressGd;
  }

  getCallTypeFromPath(): string {
    return this.route.snapshot.firstChild.firstChild.firstChild.children[0].params.callType;
  }

}
