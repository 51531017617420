<p-dialog [(visible)]="visible" [header]="header" [closable]="false" [draggable]="true" [resizable]="false"
  [modal]="foreground" (onShow)="show()" (onHide)="hide()" (visibleChange)="change($event)">
  <p-header>
    <ng-content select="header"></ng-content>
  </p-header>

  <ng-content></ng-content>

  <p-footer>
    <p-checkbox label="Show Only Members with Charts" [(ngModel)]="onlyChartsInput" class="modal__charts"
      (onChange)="onChange($event);" *ngIf="isMemberQuery" binary="true">
    </p-checkbox>
    <app-button *ngIf="allowClose" classes="secondary" [text]="rejectText" (onClick)="cancel($event)"></app-button>
    <ng-content select="footer"></ng-content>
  </p-footer>

</p-dialog>

<div *ngIf="visible && allowClose" (document:keydown.escape)="cancel()"></div>