import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class ProjectFile {
  readonly filename: string;
  readonly username: string;
  readonly description: string;
  readonly url: string;
  readonly lastmodified: string;
  readonly size: string;
  readonly fileurl: string;
  readonly projectid: string;
  readonly projectdocumentid: string;
  readonly createuserfirstname: string;
  readonly projectName: string;
  readonly extractType: string;
  readonly jobId: number;
  readonly status: string;
  readonly totalLineCount: number;
  readonly chaseCount: number;
  readonly projectType: string;
  readonly isDisabled: boolean;

  constructor(options: {
    filename: string;
    username: string;
    description?: string;
    url: string;
    lastmodified: string;
    size: string;
    fileurl: string;
    projectid: string;
    projectdocumentid: string;
    createuserfirstname: string;
    projectName: string;
    extractType: string;
    jobId: number;
    status: string;
    totalLineCount: number;
    chaseCount: number;
    projectType: string;
    isDisabled: boolean;
  }) {

    this.filename = StringHelper.clean(options.filename);
    this.username = StringHelper.clean(options.username);
    this.description = StringHelper.clean(options.description);
    this.url = StringHelper.clean(options.url);
    this.lastmodified = StringHelper.clean(options.lastmodified);
    this.size = options.size;
    this.fileurl = StringHelper.clean(options.fileurl);
    this.projectid = options.projectid;
    this.projectdocumentid = StringHelper.clean(options.projectdocumentid);
    this.createuserfirstname = StringHelper.clean(options.createuserfirstname);
    this.projectName = StringHelper.clean(options.projectName);
    this.extractType = StringHelper.clean(options.extractType);
    this.jobId = options.jobId;
    this.status = StringHelper.clean(options.status);
    this.totalLineCount = options.totalLineCount;
    this.chaseCount = options.chaseCount;
    this.projectType = options.projectType;
    this.isDisabled = options.isDisabled;
  }


  get hasDescription(): boolean {
    return StringHelper.isAvailable(this.description);
  }
}
