import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapServiceOrgAttribute = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ServiceOrgAttribute")
    .forMember("attributeId", o => o.attributeId)
    .forMember("attributeName", o => o.attributeName)
    .forMember("attributeValue", o => o.attributeValue);
};

export class ServiceOrgAttribute {
  attributeId: number;
  attributeName: string;
  attributeValue: string;

  constructor(options: Partial<ServiceOrgAttribute> = {}) {
    this.attributeId = options.attributeId;
    this.attributeName = options.attributeName;
    this.attributeValue = options.attributeValue;
  }
}
