import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { DocumentService } from "../../../platform/api/document/document.service";
import { WorkflowSubmitRequest } from "../../../platform/api/workflow/workflow-submit.model";
import { WorkflowService } from "../../../platform/api/workflow/workflow.service";
import { WorkflowStatus } from "../../../platform/api/workflow/worklow-status.enum";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ModalComponent } from "../../panel/modal/modal.component";

@Component({
  selector: "app-document-state-modal",
  templateUrl: "./document-state-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentStateModalComponent extends ModalComponent {
  @Input() entityId: number;
  @Input() workflowStatus: WorkflowStatus;
  @Input() visible = false;
  @Input() documentStatus: number;
  @Output() onUpdate = new EventEmitter<null>(true);

  private pForm: WorkflowSubmitRequest = {} as any;
  getForm(): WorkflowSubmitRequest {
    return this.pForm;
  }
  setForm(event: WorkflowSubmitRequest): void {
    this.pForm = event;
  }


  constructor(
    private readonly workflowService: WorkflowService,
    private readonly documentService: DocumentService,
    private readonly messagingService: MessagingService
  ) {
    super();
  }


  hasForm(): boolean {
    const form = this.getForm();
    return form != null && Object.keys(form).some(key => !!form[key]);
  }

  submitDocumentStatus() {
    if (this.hasForm()) {
      const documentQueueId = this.getForm().entityId;
      const note = this.getForm().note;
      this.workflowService.submit(this.getForm())
        .subscribe(
          success => {
            if (success) {
              if (StringHelper.isAvailable(note)) {
                this.documentService.saveNote(documentQueueId, note).subscribe();
              }
              this.onUpdate.emit();
            } else {
              this.errorMessage();
            }
          },
          this.errorMessage.bind(this)
        );
    }
  }


  private errorMessage(error: any = {}): void {
    this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
  }
}
