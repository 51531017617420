import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { ChaseDocumentPages } from "./chase-document-pages.model";

export class DocumentPageAnnotations {
  chaseDocumentId: number;
  chaseDocumentPages: ChaseDocumentPages[];
  memberDocumentPages?: ChaseDocumentPages[];
  encounterDocumentPages?: ChaseDocumentPages[];
  diagnosisDocumentPages?: ChaseDocumentPages[];

  constructor(options: {
    chaseDocumentId: number;
    chaseDocumentPages: ChaseDocumentPages[];
    memberDocumentPages?: ChaseDocumentPages[];
    encounterDocumentPages?: ChaseDocumentPages[];
    diagnosisDocumentPages?: ChaseDocumentPages[];

  }) {
    this.chaseDocumentId = options.chaseDocumentId;
    this.chaseDocumentPages = ArrayHelper.isAvailable(options.chaseDocumentPages) ? options.chaseDocumentPages : [];
    this.memberDocumentPages = ArrayHelper.isAvailable(options.memberDocumentPages) ? options.memberDocumentPages : [];
    this.encounterDocumentPages = ArrayHelper.isAvailable(options.encounterDocumentPages) ? options.encounterDocumentPages : [];
    this.diagnosisDocumentPages = ArrayHelper.isAvailable(options.diagnosisDocumentPages) ? options.diagnosisDocumentPages : [];
  }
}

