import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { LocalService } from "../../../core/storage/local.service";
import { Annotation } from "./annotation.model";

@Injectable({
  providedIn: "root",
})
export class AnnotationService {

  private route = `${this.baseApiUrl}chase/userannotation`;
  private isRefreshAnnotations = new BehaviorSubject<boolean>(false);
  private annotationsNumber = new BehaviorSubject<number>(0);
  isRefreshAnnotations$ = this.isRefreshAnnotations.asObservable();
  private localStorageIsWindowOpen = "isWindowOpen";
  annotationsNumber$ = this.annotationsNumber.asObservable();
  private chaseId = new BehaviorSubject<number | null>(null);
  private currentAnnotations$ = new ReplaySubject<Annotation[]>(1);
  private currentAnnotationsMatches$ = new BehaviorSubject<Annotation[]>([]);
  private entityId$ = new BehaviorSubject<number>(null);

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly automapper: AutomapperService,
    private readonly http: HttpClient,
    private localService: LocalService
  ) { }

  get isDuplicateWindowOpen(): boolean {
    return this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  }

  getChaseIdObservable$(): Observable<number> {
    return this.chaseId.asObservable();
  }

  updateChaseId(chaseId: number): void {
    this.chaseId.next(chaseId);
  }

  getAnnotations$(chaseId: number): Observable<Annotation[]> {
    return this.http.get<Annotation[]>(`${this.baseApiUrl}chase/userannotations?chaseId=${chaseId}`).pipe(
      map(this.automapper.curryMany("default", "Annotation")));
  }

  save(annotation: Annotation): Observable<void> {
    return this.http.post<void>(`${this.route}/save`, annotation);
  }

  edit(annotation: Annotation): Observable<void> {
    return this.http.post<void>(`${this.route}/edit`, annotation);
  }

  delete(annotation: Annotation): Observable<void> {
    return this.http.request<void>("DELETE", `${this.route}/delete`, { body: annotation });
  }

  refreshAnnotations(value: boolean): void {
    this.isRefreshAnnotations.next(value);
  }

  setAnnotationsTotal(annotations: number): void {
    this.annotationsNumber.next(annotations);
  }

  setAnnotations(a: Annotation[]): void {
    this.currentAnnotations$.next(a);
  }

  getCurrentAnnotations$(): Observable<Annotation[]> {
    return this.currentAnnotations$.asObservable();
  }

  setAnnotationsMatches(a: Annotation[]): void {
    this.currentAnnotationsMatches$.next(a);
  }

  getCurrentAnnotationsMatches$(): Observable<Annotation[]> {
    return this.currentAnnotationsMatches$.asObservable();
  }

  getCurrentEntityId$(): Observable<number> {
    return this.entityId$.asObservable();
  }

  setCurrentEntityId$(value: number) {
    this.entityId$.next(value);
  }

}
