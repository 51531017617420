import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { CommentItem } from "../../comments/comment-item/comment-item";
import { GridPipeName } from "../../grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../grid/models/grid-configuration.model";
import { EditPendService } from "../edit-pend.service";

@Component({
  selector: "app-pend-notes-grid",
  templateUrl: "./pend-notes-grid.component.html",
  styleUrls: ["./pend-notes-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendNotesGridComponent implements OnInit {
  @Input() chasePendIds: number[];
  notes: CommentItem[];
  configuration: GridConfiguration;


  constructor(
    private readonly editPendService: EditPendService
  ) { }

  ngOnInit() {
    this.getNotes();
    this.createGrid();
  }


  get hasOneChasePendId(): boolean {
    return ArrayHelper.isAvailable(this.chasePendIds) && this.chasePendIds.length === 1;
  }

  get hasNotes(): boolean {
    return ArrayHelper.isAvailable(this.notes);
  }


  private getNotes(): void {
    if (this.hasOneChasePendId) {
      const firstChasePendId = this.chasePendIds[0];
      this.editPendService
        .getNotesList(firstChasePendId)
        .pipe(map<CommentItem[], CommentItem[]>(this.removeEmptyComments.bind(this)))
        .subscribe(data => this.notes = data);
    }
  }

  private removeEmptyComments(notes: CommentItem[], index: number): CommentItem[] {
    return notes.filter(note => StringHelper.isAvailable(note.commentText));
  }

  private createGrid(): void {
    this.configuration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "commentText", header: "Notes" }),
        new GridColumnDefinition({ field: "createdByUserName", header: "Created By" }),
        new GridColumnDefinition({ field: "createdDate", header: "Created Date", pipeName: GridPipeName.Date, format: "short" }),
      ],
      pageSize: 3,
      showActionColumn: false,
    });
  }
}
