<div class="document-review__container document-review__container-custom">
  <div class="document-review__container-header">
    <div class="container-header-inner">
      <app-detail-header-template>
        <div top-left class="headerDetail">
          <div class="container-back-button">
            <app-back-button text="Go Back"></app-back-button>
          </div>
          <h3 class="container-title">
            {{headerTitle | uppercase}}
          </h3>
          <h4 class="header-title2" *ngIf="runMode === runModeDocument">
            {{headerTitle2}}
          </h4>
          <h4 class="move-back-reason" *ngIf=" chaseDetails">
            {{memberValidationReason}}
          </h4>
        </div>
        <div top-right>
          <app-list *ngIf="chaseDetails"
                    [items]="memberItems"></app-list>
          <app-button *ngIf="hasAssignButton"
                      class="assign-button"
                      text="ASSIGN"
                      (onClick)="openAssignToUserModal()"></app-button>
        </div>

        <div bottom-left>
          <div class="menu-container">
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 0}"
                 (click)="toggleTab(0)">
                <div class="button">
                  <div class="description">Chart</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 1111}"
                 (click)="toggleTab(1111)">
                <div class="button">
                  <div class="description">Comments</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 9999}"
                 (click)="toggleTab(9999)">
                <div class="button">
                  <div class="description">Timeline</div>
                </div>
              </a>
            </div>

            <div class="item"
                 *ngFor="let documentComponent of documentComponents; let i = index; trackBy: trackByIndex">
              <ng-container *ngIf="(documentComponent.sourceDocumentTypeId === runModeDocument && viewSource===true)">
                <a class="link"
                   [ngClass]="{'active': selectedTab === i}"
                   (click)="toggleTab(i)">
                  <div class="button">
                    <div class="description">
                      {{
                    documentComponent.fileName
                      }}
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>

          </div>
        </div>

        <div bottom-right>
          <div class="topline">
            <div class="commands">
              <div *ngIf="runMode===runModeChase">
                <span class="readonly-view"
                      *ngIf="!allowEdit">Read-Only View</span>
                <app-button [disabled]="!allowEdit"
                            class="header-button"
                            text="MOVE BACK"
                            (onClick)="openMoveChaseBackModal()"></app-button>
                <app-button class="header-button"
                            text={{viewSourceLabel}}
                            (onClick)="toggleViewSource()"></app-button>
                <app-button [disabled]="!allowEdit"
                            class="header-button"
                            text="SUBMIT"
                            (onClick)="submitToWorkflow()"></app-button>
                <app-button [disabled]="!allowEdit"
                            class="header-button"
                            text="SUBMIT &amp; GET NEXT"
                            (onClick)="submitToWorkflowAndGetNext()"></app-button>
                <app-button [disabled]="!allowEdit"
                            class="header-button"
                            text="DOWNLOAD"
                            (onClick)="submitChartDownloadRequest()"></app-button>
                <app-button *ngIf="hasUserAssignmentRole"
                            class="header-button"
                            text="ASSIGN"
                            (onClick)="openUserAssignmentModel()"></app-button>
              </div>
              <div *ngIf="runMode===runModeDocument">
                <app-split-button text="SUBMIT & GET NEXT"
                                  [ngClass]="getSplitButtonClass()"
                                  [model]="submitButton"
                                  (onClick)="submitDocumentToWorkflow(true)"
                                  [disabled]="isChartTab"></app-split-button>
                <app-split-button text="HOLD & GET NEXT"
                                  [ngClass]="getSplitButtonClass()"
                                  [model]="holdButton"
                                  (onClick)="openHoldDocumentModal(true)"
                                  [disabled]="isChartTab"></app-split-button>
                <app-split-button text="REMOVE & GET NEXT"
                                  [ngClass]="getSplitButtonClass()"
                                  [model]="removeButton"
                                  (onClick)="openConfirmationForRemoveAndGetNext(true)"
                                  [disabled]="isChartTab"></app-split-button>
              </div>
            </div>
          </div>
        </div>
      </app-detail-header-template>
      <div class="clearFloat"></div>
    </div>

    <div class="chase-details__tag-container" *ngIf="!isChartTab">
      <app-view-tags [tagType]="tagType" [objectId]="objectId" [isGrid]="false"></app-view-tags>
    </div>
  </div>

  <div class="document-review-container">
    <div [ngClass]="getDocumentComponentClass()">
      <div class="documentcomponent" *ngFor="let documentComponent of documentComponents; let i = index;  trackBy: trackByIndex">
        <ng-container *ngIf="this.selectedTab === i">
          <app-retreival-document-intake #doccomponents
                                         [documentId]="documentComponent.documentId"
                                         [sourceDocumentTypeId]="documentComponent.sourceDocumentTypeId"
                                         (changedEvent)="documentComponentChanged($event)"
                                         (notifyDocumentStatusChanged)="documentStatusChanged($event)"
                                         (pageEvent)="receivePageNumber($event)"
                                         (assignedPagesEvent)="receiveAssignedPageNumber($event)"
                                         [currentlyAssignedUser]="currentlyAssignedUser"
                                         (createDosPursuit)="onCreateDosPursuit($event)">

          </app-retreival-document-intake>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="this.selectedTab === 1111">
      <div class="chase-detail-comments-container">
        <textarea maxlength="1000" class="commenttextarea" placeholder="Type here to reply." [(ngModel)]="commentText"></textarea>
        <div class="save-comment"><app-button (onClick)="saveComment()" text="SEND MESSAGE"></app-button></div>
        <app-comments [commentItems]="commentItems"></app-comments>
      </div>
    </ng-container>
    <ng-container *ngIf="this.selectedTab === 9999">
      <app-timeline-detail [timeline]="timelineItems"></app-timeline-detail>
    </ng-container>
  </div>

  <app-hold-document-modal [(visible)]="isHoldDocumentModalVisible"
                           [entityId]="documentId"
                           [workflowStatus]="currentWorkflowStatus"
                           (onUpdate)="holdDocumentModalUpdate()"></app-hold-document-modal>

  <app-document-state-modal [(visible)]="isUnHoldDocumentModalVisible"
                            [entityId]="documentId"
                            [workflowStatus]="currentWorkflowStatus"
                            [documentStatus]="unHoldStatus"
                            (onUpdate)="unHoldDocumentModalUpdate()"></app-document-state-modal>

  <app-confirmation-modal [(visible)]="showConfirmationForRemoveAndGetNext"
                          header="Remove Document"
                          (onYes)="submitDocumentNoMatchToWorkflow()"
                          acceptText="CONTINUE"
                          rejectText="CANCEL">
    This will remove the Document from the Intake Queue permanently, normally this is only done if no Pages could be assigned to a Chase.
    <br />
    Are you sure you want to do this?
  </app-confirmation-modal>

  <app-confirmation-modal [(visible)]="showCreateDosPursuitConfirmation"
                          header="ATTENTION"
                          (onYes)="createDosPursuit()"
                          acceptText="CONTINUE"
                          rejectText="CANCEL">
      {{ dosPursuitCreationText }}
  </app-confirmation-modal>
</div>

<app-chase-move-back [(visible)]="isChaseModalVisible"
                     (onUpdate)="onUpdate()"
                     [chaseId]="chaseId"
                     [addressId]="addressId"
                     [moveBackWarningText]="moveBackWarningText"
                     [workFlowStatusId]="workFlowStatusId"></app-chase-move-back>

<app-confirmation-modal class="submit-getNext"
                        [(visible)]="unAssignedPageAlertVisible"
                        header="ATTENTION"
                        (onYes)="moveToCancelStateAndSubmitAndGetNext()"
                        acceptText="YES"
                        rejectText="CANCEL">
  Pages [{{unAssignedPages}}] are unassigned or uncommitted and will be lost upon submission. The document status will be set to removed.
  <br /><br />
  Are you sure you still want to submit?
</app-confirmation-modal>

<app-modal #userModal [(visible)]="isAssignToUserModalVisible" header="Assigned To">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form [formGroup]="formGroup">
          <form-factory [formGroup]="formGroup" [model]="assignToUsers"></form-factory>
        </form>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button" text="CONTINUE" (onClick)="assignToClick()" [disabled]="!hasSelectedUser"></app-button>
  </footer>
</app-modal>

<app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible"
                                  (onHide)="closeModal()"
                                  [(chases)]="selectedChases"
                                  isUnassignEnabled="false"
                                  (onUpdate)="updateChaseAssignedData()">
</app-chase-assignment-modal>
