
<app-modal [(visible)]="visible"
           [header]="moveType"
           (visibleChange)="visibleChange.emit($event)"
           (onShow)="open()"
           (onHide)="close()">
  <div></div>
  <form [formGroup]="formGroupContact">

    <div class="ui-fluid address-search">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="contactName"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="groupName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="email"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="phone"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="fax"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupContact" [model]="preferredRequestMethod"></form-factory>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12 text-center">
        <div class="ui-g-1 ui-md-1 ui-lg-1"></div>
        <div class="ui-g-3 ui-md-3 ui-lg-3">
          <label class="call-script__label text-center">Commitment Date:</label>
        </div>
        <div class="ui-g-5 ui-md-5 ui-lg-5 no-padding">
          <p-calendar [showIcon]=true
            [readonlyInput]="true"
            [hideOnDateTimeSelect]="true"
            class="appointment__input"
            placeholder="Date"
            [(ngModel)]="commitmentDate"
            [ngModelOptions]="{standalone: true}"
            [minDate]="minDate">
            </p-calendar>
          </div>
      </div>
    </div>
    
  </form>

  <div class="notes-error" *ngIf="notesMissing">
    Enter Notes for chase move.
  </div>

  <textarea 
    class="notes-textarea" 
    placeholder="Enter Notes for Chase Move." 
    [(ngModel)]="notesText"
    (keyup)="onNotesTextChange()">
  </textarea>

  <form-factory 
    [formGroup]="formGroupContact" 
    [model]="inheritChaseInput" 
    *ngIf="isMoveChaseVisible">
  </form-factory>
    
  <footer>
    <app-button class="header-button" text="Move Chases" (onClick)="moveChases()"></app-button>
  </footer>
</app-modal>
