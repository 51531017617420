import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { PendDocumentItem } from "./pend-document-list-item.model";

@Injectable({
  providedIn: "root",
})
export class InternalPendsDetailDocumentsService {
  refresh = new Subject<any>();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getPendDocuments(chasePendId: number): Observable<PendDocumentItem[]> {
    const url = `${this.baseApiUrl}penddocument/penddocuments?chasePendId=${chasePendId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "PendDocumentItem"))
    );
  }

  upload(formData: FormData): Observable<number> {
    const url = `${this.baseApiUrl}penddocument/upload`;

    return this.http.post(url, formData, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {

            return 0;
          }
        })
      );
  }

  refreshFunction() {
    this.refresh.next();
  }

}
