import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Resize } from "../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ListItem } from "../../../../shared/list/list-item";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { AccessInfoModel } from "./access-info-view-edit-model";
import { AccessInfoViewEditService } from "./access-info-view-edit.service";

@Component({
  selector: "retrieval-access-info-view-edit",
  templateUrl: "./access-info-view-edit.component.html",
  styleUrls: ["./access-info-view-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessInfoViewEditComponent implements OnInit {

  subscription: Subscription;

  @Input() retrievalId: number;
  accessInfoEditModel: AccessInfoModel;
  isEditAccessInfoModalVisible = false;
  accessInfoSummaryItems = List<ListItem>();

  selectedFileList: any[] = [];
  formData: FormData = new FormData();

  readonly formGroup: FormGroup;
  readonly contactNameAdmin: Textbox;
  readonly phoneAdmin: Textbox;
  readonly contactNameIT: Textbox;
  readonly phoneIT: Textbox;
  readonly emrSystem: Textbox;
  readonly editNotes: Textarea;

  constructor(
    private readonly formService: FormService,
    private service: AccessInfoViewEditService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService
  ) {

    this.contactNameAdmin = new Textbox({
      key: "adminContactName",
      label: "Admin Contact",
      placeholder: "admin contact name",
      validators: [Validators.required],
      errorMessages: {
        required: "Admin Contact is required.",
      },
    });

    this.phoneAdmin = new Textbox({
      key: "adminPhone",
      label: "Admin Phone",
      placeholder: "admin phone",
      validators: [Validators.required, Validators.maxLength(18), Validators.pattern(RegExHelper.phoneNumber)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.contactNameIT = new Textbox({
      key: "itContactName",
      label: "IT Contact",
      placeholder: "IT contact",
      validators: [Validators.required],
      errorMessages: {
        required: "IT Contact is required.",
      },
    });

    this.phoneIT = new Textbox({
      key: "itPhone",
      label: "IT Phone",
      placeholder: "IT phone",
      validators: [Validators.required, Validators.maxLength(18), Validators.pattern(RegExHelper.phoneNumber)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.emrSystem = new Textbox({
      key: "emrSystem",
      label: "EMR System",
      placeholder: "emr system",
      validators: [Validators.required],
      errorMessages: {
        required: "EMR System is required.",
      },
    });

    this.editNotes = new Textarea({
      key: "editNotes",
      label: "Notes",
      placeholder: "Long form text...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });

    this.formGroup = this.formService.createFormGroup([this.contactNameAdmin, this.phoneAdmin,
                                                       this.contactNameIT, this.phoneIT,
                                                       this.emrSystem, this.editNotes]);
  }

  ngOnInit() {

    this.service
      .getAccessInfoSummaryItems(this.retrievalId)
      .subscribe(items => this.accessInfoSummaryItems = this.assignAndNotifyAccessInfoDetail(items));

    this.service
      .getAccessInfoDetailsForEdit(this.retrievalId)
      .subscribe(this.assignAndNotifyEditAccessInfo);

    this.subscription = this.service.reset
      .subscribe(id => {
        this.updateAccessInfo();
      });

  }
  updateAccessInfo() {

      this.service
        .getAccessInfoSummaryItems(this.retrievalId)
        .subscribe(items => this.accessInfoSummaryItems = this.assignAndNotifyAccessInfoDetail(items));

  }


  private assignAndNotifyEditAccessInfo = (data: AccessInfoModel): void => {

    this.accessInfoEditModel = new AccessInfoModel(data);

    this.loadForm();
  }

  private assignAndNotifyAccessInfoDetail<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  private loadForm() {

    this.formGroup.patchValue({
      adminContactName: this.accessInfoEditModel.adminContactName,
      adminPhone: this.accessInfoEditModel.adminContactPhone,
      itContactName: this.accessInfoEditModel.itContactName,
      itPhone: this.accessInfoEditModel.itContactPhone,
      emrSystem: this.accessInfoEditModel.emrSystem,
      editNotes: this.accessInfoEditModel.editNotes,

    });

    this.changeDetector.markForCheck();
  }

  onUpdateAccessInfo(): void {
    if (this.formGroup.invalid) {
      this.messagingService.showToast("Please fill out all the empty & required fields.", SeverityType.ERROR);

      return;
    }
    this.getFormValues();
    this.service.updateAccessInfo(this.accessInfoEditModel, this.retrievalId)
        .subscribe((data: number) => {
          if (data > 0) {
            this.service.resetRetrievalId(this.retrievalId);

            this.messagingService.showToast("Access Information successfully edited.", SeverityType.SUCCESS);

          } else {
            this.messagingService.showToast("Failed to edit Access Information.", SeverityType.ERROR);
          }
        });
    this.isEditAccessInfoModalVisible = false;
  }

  private getFormValues() {
    this.changeDetector.markForCheck();

    this.accessInfoEditModel.adminContactName = this.formGroup.get("adminContactName").value;
    this.accessInfoEditModel.adminContactPhone = this.formGroup.get("adminPhone").value;
    this.accessInfoEditModel.itContactName = this.formGroup.get("itContactName").value;
    this.accessInfoEditModel.itContactPhone = this.formGroup.get("itPhone").value;
    this.accessInfoEditModel.emrSystem = this.formGroup.get("emrSystem").value;
    this.accessInfoEditModel.editNotes = this.formGroup.get("editNotes").value;
  }

  onFileSelect(event) {
    for (const file of event.files) {
      this.selectedFileList.push(file);
      this.formData.append("Document", file);
    }
  }

  onFileRemove(event) {
    this.formData = new FormData();

    let i = this.selectedFileList.length;
    while (i--) {
      if (this.selectedFileList[i].name === event.file.name) {
        this.selectedFileList.splice(i, 1);
      } else {
        this.formData.append("Document", this.selectedFileList[i]);
      }
    }
  }

  uploadDocument(event, fileUpload): void {
    if (this.selectedFileList !== undefined && this.selectedFileList.length > 0) {

      this.service.upload(this.formData, this.retrievalId).subscribe(
        data => {
          this.service.refreshFunction();
          this.changeDetector.markForCheck();
          this.messagingService.showToast("Access Info document(s) Uploaded successfully.", SeverityType.SUCCESS);
          this.formData = new FormData();
          this.selectedFileList = [];
          fileUpload.clear();
        },
        err => {
          this.messagingService.showToast("Error while uploading Document, please try again.", SeverityType.ERROR);
          this.formData = new FormData();
        }
      );
    } else {
      this.messagingService.showToast("Please select a required document.", SeverityType.ERROR);
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  cancelAccessInfo() {
    this.isEditAccessInfoModalVisible = false;
  }

}
