<div class="info-item"  *ngFor="let item of infoItems; trackBy: trackByIndex" [ngClass]="getClass(item)">
  <div [ngClass]="itemClass(item)">
    <div class="info-item-password" *ngIf="item.key=='Password:'; else accessInfo;">
      <div class="info-item-password__value">
        <div class="info-item-password--label">{{item.key}}</div>
        <div class="info-item-password--value" *ngIf="showPasswordChecked">{{item.value}}</div>
        <div class="info-item-password--value" *ngIf="!showPasswordChecked">{{ '●'.repeat(item.value.length) }}</div>
      </div>
      <div class="info-item-password__show-password">
        <p-checkbox label="Show password"
          [(ngModel)]="showPasswordChecked"
          binary="true"
          (onChange)="changeShowPassword()">
        </p-checkbox>
      </div>
    </div>
    <ng-template #accessInfo>
      <div class="info-item__container info-item__container--label">{{item.key}}</div>
      <div class="info-item__container info-item__container--value">{{item.value}}</div>
    </ng-template>
  </div>
  <div class="special-handling-flag" *ngIf="item.key=='Address:'">
      <app-icon iconStyle="mrcs-icon"
      iconType="mrcs-icon"
      iconName="special-assignment-address"
      *ngIf="!isSpecialHandling"
      title="Enable Special Handling"
      (click)="specialHandling(true)" ></app-icon>
      <app-icon iconStyle="mrcs-icon"
      iconType="mrcs-icon"
      iconName="special-assignment-address active"
      *ngIf="isSpecialHandling"
      title="Edit Special Handling"
      (click)="specialHandling(false)" ></app-icon>
  </div>
</div>
