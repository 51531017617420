<app-modal 
  [(visible)]="visible" 
  header="ASSIGN 3RD PARTY VENDOR" 
  (visibleChange)="change($event)"
  (onCancel)="hide()">
  <p [class]="modalTextClass">{{assignVendorModelText}}</p>
  <form [formGroup]="vendorForm">
    <div class="ui-md-7 ui-md-offset-2"><form-factory [formGroup]="vendorForm" [model]="vendorInput"></form-factory></div>
  </form>
  <footer class="text-center">
    <app-button *ngIf="isVendorSelected" text="Assign" (onClick)="assignAddressToVendor()"></app-button>
  </footer>
</app-modal>
