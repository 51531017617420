
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FormService } from "../../../dynamic-forms/form.service";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { Widget } from "../widget.enum";
import { UserGroupItem } from "./user-chat.model";
import { UserChatService } from "./user-chat.service";

@Component({
  selector: "platform-widget-user-chat",
  templateUrl: "./user-chat.component.html",
  styleUrls: ["./user-chat.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UserChatComponent implements OnInit {
  @Input() selectedPanel = Widget.DIRECTORY;
  userList: UserGroupItem[];
  searchFormGroup: FormGroup;
  userSearchInput: Textbox;
  selectedTab = "contacts";

  constructor(
    private userChatService: UserChatService,
    private readonly formService: FormService,
    public router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initializeUserSearchForm();
    this.getUserList();
  }

  private initializeUserSearchForm(): void {
    this.userSearchInput = new Textbox({
      key: "userSearchInput",
    });
    this.searchFormGroup = this.formService.createFormGroup([this.userSearchInput]);
  }

  get getUserInput(): string {
    return this.searchFormGroup.get("userSearchInput").value;
  }

  toggleTab(tab: string): void {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
  }

  getUserList(): void {
    const userSearchModel = {
      status: "A",
    };
    this.getUserSearchList(userSearchModel);
  }

  getUserSearchList(userSearchModel: any): void {
    this.userChatService.getUserList(userSearchModel).subscribe(data => {
      this.userList = null;
      this.userList = data;
      this.changeDetector.markForCheck();
    });
  }

  trackByIndex(index, item) {
    return index;
  }

  getNameInitials(name: string): string {
    const fullName = name.trim().split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  }

  getFullName(name: string): string {
    return name.length > 28 ? name.substring(0, 28).concat("..") : name;
  }

  onSearch(): void {
    const userSearchModel = {
      status: "A",
      Name: this.getUserInput,
    };
    this.getUserSearchList(userSearchModel);
  }
}
