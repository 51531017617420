import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ClientOrgModel } from "../../../core/user/client-org-model";
import { DataSet } from "../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../shared/list/list-item";
import { LookupModel } from "../../../shared/models/lookup-model";
import { ProjectTemplateModel } from "./project-template.model";
import { Project } from "./project.model";

@Injectable({
  providedIn: "root",
})
export class ProjectService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getProjectStats(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}project/getprojectstatsasync`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getProjectDetail(): Observable<DataSet> {
    const url = `${this.baseApiUrl}project/getprojectbytypeasync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getChasesCompletedStatusProjectWise(): Observable<DataSet> {
    const url = `${this.baseApiUrl}project/getprojectwisechasescompletedstatusasync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getClientOrgsList(): Observable<ClientOrgModel[]> {
    // OBSOLETE
    // TODO: Delete this method and use "app/core/user/user.service.ts"
    const url = `${this.baseApiUrl}serviceorg/client/list`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ClientOrgModel"))
    );
  }

  getProjectTemplateList(): Observable<ProjectTemplateModel[]> {
    const url = `${this.baseApiUrl}project/templates`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ProjectTemplateModel"))
    );
  }

  getProjectStatus(projectId: number): Observable<string> {
    const url = `${this.baseApiUrl}project/status?projectId=${projectId}`;
    return this.http.get(url) as Observable<string>;
  }

  getProjectStatusAndChaseCount(projectId: number): Observable<Project> {
    const url = `${this.baseApiUrl}project/statusandchasecountbyid?projectId=${projectId}`;
    return this.http.get(url) as Observable<Project>;
  }

  getProjectListAll(): Observable<LookupModel[]> {
    const url = `${this.baseApiUrl}project/archive/lookup`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "LookupModel"))
  );
  }

  cloneProject(projectId: number): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}project/clone?projectId=${projectId}`;
    return this.http.get(url) as Observable<HttpResponse<null>>;
  }
}
