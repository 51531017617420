import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { List } from "immutable";
import { AuthService } from "../../../../../auth/auth.service";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { ContactMethodType } from "../../cover-letter-template/contact-method-type.enum";
import { RetrievalContactHistory } from "../../retrieval-detail-contact-history.model";

@Component({
  selector: "app-address-detail-contact-history-item",
  templateUrl: "./address-detail-contact-history-item.component.html",
  styleUrls: ["./address-detail-contact-history-item.component.scss"],
})
export class AddressDetailContactHistoryItemComponent implements OnInit {
  @Input() items: List<RetrievalContactHistory>;
  @Input() index: number;
  @Output() retryFax = new EventEmitter<number>();
  organizationName: string;
  commitmentDate: any;

  isCall(contactMethodType: number): boolean {
    return (contactMethodType === ContactMethodType.Call
      || contactMethodType === ContactMethodType.InvoiceCall
      || contactMethodType === ContactMethodType.PendCall
      || contactMethodType === ContactMethodType.ResearchCall
      || contactMethodType === ContactMethodType.AdditionalCollectionCall
      || contactMethodType === ContactMethodType.VendorCall);

  }

  isNotCall(contactMethodType: number): boolean {
    return (contactMethodType === ContactMethodType.Fax
          || contactMethodType === ContactMethodType.Mail
          || contactMethodType === ContactMethodType.Email
          || contactMethodType === ContactMethodType.ProviderAgingEmail
          || contactMethodType === ContactMethodType.ProviderAgingFax
          || contactMethodType === ContactMethodType.Incoming_Call
          || contactMethodType === ContactMethodType.External
          || contactMethodType === ContactMethodType.AutomatedOutreachFax
          || contactMethodType === ContactMethodType.AutomatedOutreachEmail
          );
  }

  isEmail(contactMethodType: number): boolean {
    return contactMethodType === ContactMethodType.Email;
  }

  isMethodTypeCall(contactMethodType: number): boolean {
    return (contactMethodType === ContactMethodType.Call
       || contactMethodType === ContactMethodType.InvoiceCall
       || contactMethodType === ContactMethodType.PendCall
       || contactMethodType === ContactMethodType.ResearchCall
       || contactMethodType === ContactMethodType.AdditionalCollectionCall
       || contactMethodType === ContactMethodType.Incoming_Call
    );
  }
  isNotes(contactMethodType: number): boolean {
    return (contactMethodType === ContactMethodType.Note
      || contactMethodType === ContactMethodType.VendorNote);
  }

  getNotes(value: string): string {
    if (StringHelper.isAvailable(value)) {
      const mainNote = value.split("Outstanding Records:");
      const notes = mainNote[0].trim();
      return notes.replace("\\n", "");
    }
    return "";
  }

  retryFailedFax(contactLogId: number) {
    this.retryFax.emit(contactLogId);
  }

  get retryIcon(): string {
    return "fas fa-redo-alt";
  }

  get getData(): List<RetrievalContactHistory> {
    return this.items;
  }

  constructor(private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.organizationName = this.authService.user.organizationName;
  }

  trackByIndex(index, item) {
    return index;
  }

  showCallNotes(item: RetrievalContactHistory): string {
    let callNote = StringHelper.isAvailable(item.callNote) ?
      item.callNote : this.isCall(item.contactMethodTypeId) && !item.isSuccessful
        ? item.callResult : "";

    callNote = callNote.replace(RegExHelper.dateOffset, "");
    const lastIndex = callNote.indexOf("@noteCommitmentDate=");
    if (lastIndex > 0) {
        callNote = callNote.slice(0, lastIndex);
      }
    return callNote;
  }

  hasNextCallDate(item: RetrievalContactHistory): boolean {
    return StringHelper.isAvailable(item.nextCallDate);
  }

  showCommitmentDate(item: RetrievalContactHistory): string {
    let callNote = StringHelper.isAvailable(item.callNote) ?
    item.callNote : this.isCall(item.contactMethodTypeId) && !item.isSuccessful
      ? item.callResult : "";

    callNote = callNote.replace(RegExHelper.dateOffset, "");
    const lastIndex = callNote.indexOf("@noteCommitmentDate=");
    if (lastIndex > 0) {
      this.commitmentDate = callNote.slice(lastIndex + 1);
      return this.commitmentDate = DateHelper.format(new Date(this.commitmentDate));
    }
    return " ";
  }

  hasCallNotes(item: RetrievalContactHistory): boolean {
    return StringHelper.isAvailable(item.callNote)
        ? true
        : this.isCall(item.contactMethodTypeId) && !item.isSuccessful;
  }

  hasStatus(item: RetrievalContactHistory): boolean {
    return StringHelper.isAvailable(item.status);
  }

  showStatus(item: RetrievalContactHistory): string {
    return StringHelper.isAvailable(item.callNote) ? `Status: ${item.status}` : `<br>Status: ${item.status}`;
  }

}
