import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { RetrievalDocumentItem } from "./retrieval-document-list-item-model";

@Injectable({
  providedIn: "root",
})
export class RetrievalDocumentListService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getRetrievalDocuments(retrievalId: number): Observable<any> {
    const url = `${this.baseApiUrl}retrievalDocument/accessInfoDocuments?retrievalId=${retrievalId}`;

    return this.http.get(url);
  }

}
