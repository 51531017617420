import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { DischargeGroupComponent } from "./discharge-group.component";


export interface IDischargeGroupOptions extends IDynamicGroupOptions { }

export class DischargeGroup extends DynamicGroup implements IDischargeGroupOptions {
  readonly controlType = "discharge-group";
  readonly componentType = DischargeGroupComponent;


  constructor(options: IDischargeGroupOptions = {}) {
    super(options);
  }
}
