<form-factory [formGroup]="form" [model]="idSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="encSaveGroup" (onChange)="handleChange($event)" class="risk-encounter--enc-save-group"></form-factory>
<form-factory [formGroup]="form" [model]="encounterSaveGroup" (onChange)="handleChange($event)" class="risk-encounter-save-group"></form-factory>
<form-factory [formGroup]="form" [model]="startDateSaveGroup" (onChange)="handleChange($event)"  class="risk-encounter--date-save-group"></form-factory>
<form-factory [formGroup]="form" [model]="endDateSaveGroup" (onChange)="handleChange($event)"  class="risk-encounter--date-save-group"></form-factory>
<form-factory [formGroup]="form" [model]="pageNumberGroup" (onChange)="handleChange($event)" ></form-factory>
<form-factory [formGroup]="form" [model]="providerSaveGroup" (onChange)="handleChange($event)" class="risk-encounter--page-number-save-group"></form-factory>

<app-confirmation-modal [(visible)]="showEncFoundModal" header="Remove Encounter Details?"
  (onYes)="clearEncounterAndSaveEncFound()" (onCancel)="revertEncFound()" class="encounter__confirmation-modal">
  By selecting NO, you are confirming the encounter is not a face to face visit and is not valid for capturing ICD codes.
  Please confirm you would like to proceed with this action.
</app-confirmation-modal>
