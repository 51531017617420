import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { Textarea } from "./textarea.model";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent extends DynamicControlDirective<Textarea> implements OnInit, OnDestroy {
  private sink = new SubSink();

  get characterCount(): number {
    if (this.control == null || !StringHelper.isAvailable(this.control.value)) {
      return 0;
    }

    return this.control.value.length;
  }

  get characterCountText(): string {
    return this.characterCount === 1 ? `${this.characterCount} character` : `${this.characterCount} characters`;
  }


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): string {
    return this.getClasses("textarea");
  }
}
