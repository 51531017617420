import { IAutoMapper } from "../../../../core/automapper/interfaces";

export const mapOcrResult = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "OcrResult")
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("pageText", o => o.pageText)
    .forMember("sourceFilePath", o => o.sourceFilePath)
    .forMember("preProcessedImageFilePath", o => o.preProcessedImageFilePath)
    .forMember("finalOcrCoordinatesPath", o => o.finalOcrCoordinatesPath)
    .forMember("encounterJsonFilePath", o => o.encounterJsonFilePath)
    .forMember("ocrPageKeywordsJsonFilePath", o => o.ocrPageKeywordsJsonFilePath)
    .forMember("ocrPageModelJsonFilePath", o => o.ocrPageModelJsonFilePath)
    .forMember("comprehendJsonFilePath", o => o.comprehendJsonFilePath);
};

export class OcrResult {
  pageNumber?: number;
  pageText?: string;
  sourceFilePath?: string;
  preProcessedImageFilePath?: string;
  finalOcrCoordinatesPath?: string;
  encounterJsonFilePath?: string;
  ocrPageKeywordsJsonFilePath?: string;
  ocrPageModelJsonFilePath?: string;
  comprehendJsonFilePath?: string;

  constructor(options: Partial<OcrResult>) {
    this.pageNumber = options.pageNumber;
    this.pageText = options.pageText;
    this.sourceFilePath = options.sourceFilePath;
    this.preProcessedImageFilePath = options.preProcessedImageFilePath;
    this.finalOcrCoordinatesPath = options.finalOcrCoordinatesPath;
    this.encounterJsonFilePath = options.encounterJsonFilePath;
    this.ocrPageKeywordsJsonFilePath = options.ocrPageKeywordsJsonFilePath;
    this.ocrPageModelJsonFilePath = options.ocrPageModelJsonFilePath;
    this.comprehendJsonFilePath = options.comprehendJsonFilePath;
  }
}
