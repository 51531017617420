import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { Dropdown } from "primeng/dropdown";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { VrcDropdown } from "./vrc-dropdown.model";

@Component({
  selector: "form-vrc-dropdown",
  templateUrl: "./vrc-dropdown.component.html",
  styleUrls: [
    "../dropdown/dropdown.component.scss",
    "./vrc-dropdown.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VrcDropdownComponent extends DynamicControlDirective<VrcDropdown> implements OnInit, OnDestroy {
  private sink = new SubSink();
  selectedItem: string;
  @ViewChild(Dropdown, { static: true }) primeDropdown: Dropdown;


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.changeDetector.markForCheck()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get classes(): any {
    return this.getClasses("dropdown");
  }

  get required(): boolean {
    // HACK: This solution is not sustainable for all validators.
    return this.model && (
      this.model.validators === Validators.required ||
      ArrayHelper.clean(this.model.validators).some(a => a === Validators.required)
    );
  }

  get options(): any[] {
    return this.model.options;
  }

  get selectedValues(): string[] {
    if (!StringHelper.isAvailable(this.control.value)) {
      return [];
    }

    const selectedValues = this.control.value.split(",");
    return selectedValues;
  }

  get selectedOptions(): SelectableInput[] {
    const selectedOptions = this.selectedValues.reduce((acc, value) => {
      const found = this.options.find(option => option.value === value);
      if (found != null) {
        acc.push(found);
      }

      return acc;
    },                                                 []);
    return selectedOptions;
  }

  get selectedText(): string[] {
    return this.selectedOptions.map(item => item.text);
  }


  blurred(event: DynamicFormEvent): void {
    this.onBlur.emit(event);

    // HACK: When the dropdown is touched mark the hidden input as touched.
    this.control.markAsTouched();
    this.formService.updateDom.next();
  }

  itemSelected(): void {
    const selectedIndex = this.selectedValues.findIndex(value => value === this.selectedItem);
    const index = this.options.findIndex(item => item.value === this.selectedItem);
    if (NumberHelper.isLessThan(selectedIndex, 0) && NumberHelper.isGreaterThan(index, 0, true)) {
      const newSelectedValues = [...this.selectedValues];
      newSelectedValues.push(this.options[index].value);
      this.updateValue(newSelectedValues);
    }

    this.selectedItem = null;
    this.primeDropdown.updateSelectedOption(this.selectedItem);
    this.changeDetector.markForCheck();
  }

  remove(index: number): void {
    if (!this.model.disabled && !this.model.readonly) {
      const newSelectedValues = [...this.selectedValues];
      newSelectedValues.splice(index, 1);
      this.updateValue(newSelectedValues);

      const event = new DynamicFormEvent({
        key: this.model.key,
        type: "save",
      } as any);
      this.onChange.emit(event);
    }
  }

  updateValue(newSelectedValues: string[]): void {
    const newValue = newSelectedValues
      .sort((a, b) => Number(a) - Number(b))
      .join(",");
    this.model.value = newValue;
    this.control.setValue(newValue);
    this.onChange.emit(newValue as any);
  }

  trackByIndex(index, item) {
    return index;
  }
}
