import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { StepsMenuItem } from "../../../../shared/menu/steps-menu/steps-menu-item";
import { NewChaseActiveScreen } from "../../member/create-new-chase/chase-active-screen.enum";
import { NewChaseHedisActiveScreen } from "./chase-hedis-active-screen.enum";
import { CreateNewChaseEncountersComponent } from "./create-new-chase-encounters/create-new-chase-encounters.component";
import { CreateChaseService } from "./create-new-chase.service";
import { NewChaseGapActiveScreen } from "./gap-query-active-screen.enum";

@Component({
  selector: "app-create-new-chase",
  templateUrl: "./create-new-chase.component.html",
  styleUrls: ["./create-new-chase.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseComponent implements OnInit {
  @ViewChild(CreateNewChaseEncountersComponent) encountersComponent: CreateNewChaseEncountersComponent;

  newChaseScreens = NewChaseActiveScreen;
  newChaseGapScreens = NewChaseGapActiveScreen;
  newChaseHedisScreens = NewChaseHedisActiveScreen;
  activeScreen: any;
  menuItems: StepsMenuItem[];
  displayEncounterScreen = "none";
  displayGapScreen = "none";
  isGapLoaded = false;
  isGapScreenActive = false;
  isRiskProject = false;
  currentSelectedProjectType: Number;

  constructor(
    private createChaseService: CreateChaseService) {
  }
  ngOnInit() {
      this.createMenuItems();
      this.activeScreen = NewChaseActiveScreen.Project;
    }

  createMenuItems() {
    this.menuItems = [
      new StepsMenuItem({
          label: "PROJECT",
      }),
      new StepsMenuItem({
        label: "MEMBER",
      }),
      new StepsMenuItem({
        label: "PROVIDER ADDRESS",
      }),
      new StepsMenuItem({
        label: "PROVIDER",
      }),
      new StepsMenuItem({
        label: "FINAL REVIEW",
      }),
    ];
  }

  showNextScreen(newChaseActiveScreen: NewChaseActiveScreen) {
    let activeScreen = newChaseActiveScreen;
    if (newChaseActiveScreen === NewChaseActiveScreen.Member && this.createChaseService.chaseModel.isPursuit) {
      activeScreen = NewChaseActiveScreen.Encounters;
    }

    if (activeScreen === NewChaseActiveScreen.Encounters) {
      this.encountersComponent.autoPopulateEncounters();
    }
    this.activeScreen = activeScreen;
    this.encounterScreen();
  }

  showNextGapScreen(newChaseGapActiveScreen: NewChaseGapActiveScreen) {
    this.activeScreen = newChaseGapActiveScreen;
    this.gapScreen();
  }

  showNextHedisScreen(newChaseHedisActiveScreen: NewChaseHedisActiveScreen) {
    this.activeScreen = newChaseHedisActiveScreen;
    this.displayEncounterScreen = (this.isRiskProject && (this.activeScreen === NewChaseActiveScreen.Encounters)) ? "block" : "none";
    this.displayGapScreen = (this.isGapLoaded && (this.activeScreen === NewChaseGapActiveScreen.Gap)) ? "block" : "none";
  }


  get projectScreen(): string {
    return this.activeScreen === NewChaseActiveScreen.Project ? "block" : "none";
  }

  get memberScreen(): string {
    return this.activeScreen === NewChaseActiveScreen.Member ? "block" : "none";
  }

  get addressScreen(): string {
    return this.activeScreen === NewChaseActiveScreen.Address ? "block" : "none";
  }

  get providerScreen(): string {
    return this.activeScreen === NewChaseActiveScreen.Provider ? "block" : "none";
    }

  encounterScreen() {
      this.displayEncounterScreen = this.activeScreen === NewChaseActiveScreen.Encounters ? "block" : "none";
  }

  gapScreen() {
    this.displayGapScreen = this.activeScreen === NewChaseGapActiveScreen.Gap ? "block" : "none";
  }

  get isShowFinalReviewScreen(): boolean {
    return (this.isRiskProject && this.activeScreen === NewChaseActiveScreen.Review) || (this.isGapLoaded && this.activeScreen === NewChaseGapActiveScreen.Review)
    || (!this.isGapLoaded && !this.isRiskProject && this.activeScreen === NewChaseHedisActiveScreen.REVIEW);
  }

  get isShowFinalReviewScreenForRiskAndGap(): boolean {
    return this.isShowFinalReviewScreen && (this.isGapLoaded || this.isRiskProject);
  }

  get isChaseSaved(): boolean {
    return (this.isRiskProject && this.activeScreen === NewChaseActiveScreen.Confirmation) || (this.isGapLoaded && this.activeScreen === NewChaseGapActiveScreen.Confirmation)
    || (!this.isGapLoaded && !this.isRiskProject && this.activeScreen === NewChaseHedisActiveScreen.CONFIRMATION);
  }

  showEncounterStepMenu(event: boolean) {
        this.isRiskProject = event;
        event ? this.menuItems.splice(4, 0, new StepsMenuItem({ label: "ENCOUNTERS" })) : this.createMenuItems();
  }

  selectedProjectType(event: Number) {
      this.currentSelectedProjectType = event;
  }

  showEncounterScreen(event: boolean) {
    if (event) {
        this.showNextScreen(NewChaseActiveScreen.Encounters);
      }
  }

  showGapStepMenu(event: boolean) {
    this.isGapLoaded = event;
    event ? this.menuItems.splice(4, 0, new StepsMenuItem({ label: "GAPS" })) : this.createMenuItems();
  }

  showGapScreen(event: boolean) {
    if (event) {
      this.isGapScreenActive = event;
      this.showNextGapScreen(NewChaseGapActiveScreen.Gap);
  }
  }

  get isShowProviderScreenForRiskAndGap(): boolean {
    return (this.isRiskProject && this.activeScreen === NewChaseActiveScreen.Provider) || (this.isGapLoaded && this.activeScreen === NewChaseGapActiveScreen.Provider)
    || (!this.isGapLoaded && !this.isRiskProject && this.activeScreen === NewChaseHedisActiveScreen.PROVIDER);
  }

}
