import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapDocumentRouting = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentRouting")
    .forMember("retrievalTypeId", o => o.retrievalTypeId)
    .forMember("routeToBot", o => o.routeToBot);
};

export class DocumentRouting {
    retrievalTypeId: number;
    routeToBot: string;

    constructor(options: Partial<DocumentRouting> = {}) {
    this.retrievalTypeId = options.retrievalTypeId;
    this.routeToBot = options.routeToBot;
  }
}
