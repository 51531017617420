export class OverreadFeedbackQuerySearch {
  constructor(
    readonly abstractorUserId?: number,
    readonly overreaderUserId?: number,
    readonly managerUserId?: number,
    readonly abstractorReceiptStatus?: string,
    readonly overreaderReceiptStatus?: string,
    readonly status?: string,
    readonly measureId?: number,
    readonly sortDirection?: string,
    readonly sortField?: string,
    readonly startRecord?: number,
    readonly endRecord?: number,
    readonly getNextFilter?: number
  ) {
    this.abstractorUserId = abstractorUserId;
    this.overreaderUserId = overreaderUserId;
    this.managerUserId = managerUserId;
    this.abstractorReceiptStatus = abstractorReceiptStatus;
    this.overreaderReceiptStatus = overreaderReceiptStatus;
    this.status = status;
    this.measureId = measureId;
    this.sortDirection = sortDirection;
    this.sortField = sortField;
    this.startRecord = startRecord;
    this.endRecord = endRecord;
    this.getNextFilter = getNextFilter;
  }
}
