import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { EntityType } from "../../../../../../api/member-validation/entity-type.enum";
import { EXEMPT_FROM_SCORING } from "../../attributes";
import { Encounter } from "../encounter/encounter.model";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-exempt-encounter",
  template: `<form-factory [formGroup]="form" [model]="exemptInput" (onChange)="save($event)"></form-factory>`,
  styleUrls: ["./exempt-encounter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExemptEncounterComponent implements OnInit {
  @Input() encounter: Encounter;
  form: FormGroup;
  exemptInput: Checkbox;

  constructor(
    private readonly formService: FormService,
    private readonly riskService: RiskService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  save(event: boolean): void {
    const exemptEncounter = this.riskService.data.value.encounters
      .find(encounter => encounter.id === this.encounter.id);

    const attributes = [{
      chaseId: exemptEncounter.chaseId,
      entityId: exemptEncounter.entityId,
      entityTypeId: EntityType.ENCOUNTER,
      parentEntityId: exemptEncounter.parentEntityId,
      ...EXEMPT_FROM_SCORING,
      value: event ? "1" : "0",
    }];

    this.saveAttributes(attributes);
  }

  private saveAttributes(attributes) {
    const exemptEncounterIndex = this.riskService.data.value.encounters
      .findIndex(encounter => encounter.id === this.encounter.id);

    this.riskService
      .save(attributes)
      .subscribe(
        newAttributes => this.riskService.setEncounterAttributes(exemptEncounterIndex, newAttributes),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private initializeForm(): void {
    // INPUTS
    this.exemptInput = new Checkbox({
      key: `Encounter_Exempt`,
      value: this.encounter.exempt,
    });

    // CREATE FORM
    this.form = this.formService.createFormGroup([this.exemptInput]);
  }
}
