export class AccessInfoModel {
  retrievalLocationId: number;
  adminContactName?: string;
  adminContactPhone?: string;
  itContactName?: string;
  itContactPhone?: string;
  emrSystem?: string;
  editNotes?: string;

  constructor(options: {
    retrievalLocationId: number;
    adminContactName?: string;
    adminContactPhone?: string;
    itContactName?: string;
    itContactPhone?: string;
    emrSystem?: string;
    editNotes?: string;
  }) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.adminContactName = options.adminContactName || "";
    this.adminContactPhone = options.adminContactPhone;
    this.itContactName = options.itContactName || "";
    this.itContactPhone = options.itContactPhone;
    this.emrSystem = options.emrSystem || "";
    this.editNotes = options.editNotes || "";
  }

}
