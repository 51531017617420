import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MemberSearchRequest } from "./member-search-request.model";
import { MemberSearchResult } from "./member-search-result.model";

@Injectable({
  providedIn: "root",
})
export class CreateNewChaseMemberService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  memberSearch(memberSearchRequest: MemberSearchRequest): Observable<MemberSearchResult[]> {
    const url = `${this.baseApiUrl}member/search`;

    return this.http.post(url, memberSearchRequest).pipe(
      map(this.automapper.curryMany("default", "MemberSearchResult")));
  }
}
