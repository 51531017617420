<app-server-grid-menu
  [configuration]="configuration"
  [(request)]="request"
  [views]="views"
  [refreshViews]="refreshViews"
  [onViewSelect]="onViewSelect"
  [filterTemplate]="filterTemplate"
  [primeGrid]="primeGrid"
  [filterHeaderText]="filterHeaderText"
  [isExcelExportDuplicateRow]="isExcelExportDuplicateRow"
  [excelExportExpandColumnName]="excelExportExpandColumnName"
  [selectedItems]="selection"
  [actions]="bulkActionsObsolete"
  [isMemberFile]="isMemberFile"
  [filterFooterText]="filterFooterText"
  [isMemberFilterValid]="isMemberFilterValid"
  [totalRecords]="totalRecords"
  [paginationText]="paginationText"
  [isFirstPage]="isFirstPage"
  [isLastPage]="isLastPage"
  (navigationChange)="onNavigationChange($event)"
  [isMemberQuery]="isMemberQuery"
  [isSaveQueryVisible]="isSaveQueryVisible || disableUnusedFilters"
  [onlyChartsInput]="onlyChartsInput"
  (memberChartEmit)="memberChart($event)"
  [displayCheckedItemsInGridView]="displayCheckedItemsInGridView"
  [exportDataFromUrl]="exportDataFromUrl"></app-server-grid-menu>
  
<app-bulk-actions *ngIf="!isMemberFile" [actions]="bulkActionsObsolete" [selectedItems]="selection"></app-bulk-actions>

<app-filter-applied 
  [(request)]="request" 
  [primeGrid]="primeGrid" 
  (isShowFilterModal)="showFilters()" 
  [totalRecords]="totalRecords" 
  [openFilterModalOnFlag]="openFilterModalOnFlag"
  [startCollapsed]="startCollapsed"
  [isSaveQueryVisible]="isSaveQueryVisible"
  (showSaveQueryModal)="showSaveQueryModal()"
  [savedQueryName]="savedQueryName"
  (showDeleteSavedQueryModal)="showDeleteSavedQueryModal($event)"
  (isUpdateQueryViewAndFilters)="updateQueryViewAndFilters()">
</app-filter-applied>

<p-table
  #primeGrid
  [lazy]="true"
  (onPage)="paginate($event)"
  [loading]="isLoading"
  [lazyLoadOnInit]="isLoadOnInit"
  (onLazyLoad)="onLazyLoad($event)"
  [columns]="columns"
  [value]="data"
  [totalRecords]="totalRecords"
  [rows]="pageSize"
  [paginator]="hasPagination"
  [selectionMode]="selectionMode"
  [(selection)]="selection"
  (selectionChange)="onSelectionChange(selection)"
  [rowTrackBy]="trackByItem"
  [dataKey]="trackByField"
  [responsive]="true"
  [resizableColumns]="true"
  (onRowExpand)="onRowExpandToggle($event)"
  [rowExpandMode]="rowExpandMode"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)"
  [first]="first"
  (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)">

  <ng-template pTemplate="header" let-columns>
    <tr app-grid-header [(configuration)]="configuration"></tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded">
    <tr
        [ngClass]="getRowClass(rowData)"
        [pSelectableRow]="selectableRowData(rowData)"
        [pSelectableRowIndex]="rowIndex"
        [pSelectableRowDisabled]="rowData.disabled"        
        app-grid-body
        [configuration]="configuration"
        [rowData]="rowData"
        [rowIndex]="rowIndex"
        [hasSelectedRows]="hasSelection"
        [actionTemplate]="actionTemplate"
        [expanded]="expanded">
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <span class="page-size-text">Page Size: </span>
    <p-dropdown
      [options]="configuration.pageSizeSelectableInputs"
      [(ngModel)]="configuration.pageSize"
      (onChange)="pageSizeChange()"></p-dropdown>
  </ng-template>

  <ng-template pTemplate="rowexpansion"
               let-rowData
               let-columns="columns">
    <tr>
      <td [attr.colspan]="colspan" class="row-expand">
        <ng-template [ngTemplateOutlet]="rowExpandTemplate"
                     [ngTemplateOutletContext]="{ $implicit: rowData }"></ng-template>
      </td>
    </tr>
  </ng-template>

</p-table>

<app-save-query [request]="request" 
                [views]="views" 
                [saveQueryAttributeId]="saveQueryAttributeId" 
                [(visible)]="isSaveQueryModalVisible"
                [savedQueryName]="savedQueryName"
                [isSavedQueryPublic]="isSavedQueryPublic"
                [savedQueryView]="savedQueryView"
                [savedQueryId]="savedQueryId"
                (isSavedQueryUpdated)="updatedSavedQuery($event)"
                (onClose)="onClose()"></app-save-query>
                
<app-save-query-delete  [(visible)]="isdeleteQueryModalVisible"                        
                        [queryId]="savedQueryId"
                        (isSavedQueryDeleted)="deletedSavedQuery($event)"
                        (onClose)="onCloseDelete()"></app-save-query-delete>