import { Inject, Injectable } from "@angular/core";
import { UserToken } from "../../auth/user-token.model";
import { BASE_API_URL } from "../environment.tokens";
import { UserModel } from "../user/user-model";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class GainSightPxService {
  userInfo: UserModel;

  constructor(
    private userService: UserService
  ) { }

  private get hasAptrinsic(): boolean {
    return typeof this.aptrinsic === "function";
  }

  private get aptrinsic() {
    return ((window as any).aptrinsic || {});
  }

  private get roleGroupName(): string {
    return this.userInfo.userRoleModels[0] ? this.userInfo.userRoleModels[0].roleGroupName : "";
  }

  initialize(vistor: UserToken): void {
    this.userService.getUser(vistor.userId)
      .subscribe((result: UserModel) => {
        this.userInfo = result;
        const distinctRoleName = [...new Set(this.userInfo.userRoleModels.map(x => x.roleName))];
        const distinctRoleTypeName = [...new Set(this.userInfo.userRoleModels.map(x => x.roleTypeName))];
        if (this.hasAptrinsic) {
          this.aptrinsic("identify", {
            id: this.userInfo.userId,
            firstName: this.userInfo.firstName,
            lastName: this.userInfo.lastName,
            email: this.userInfo.email,
            organizationID: this.userInfo.organizationId,
            organizationName: this.userInfo.userRoleModels[0].organizationName,
            roleGroupName: this.roleGroupName,
            roleName: distinctRoleName.join("; "),
            roleTypeName: distinctRoleTypeName.join(";"),
          },
                         {
            id: this.userInfo.organizationId,
            name: this.userInfo.userRoleModels[0].organizationName,
          });
        }
      });
  }
}
