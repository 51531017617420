import { IAutoMapper } from "../interfaces";

export const mapClinicalChaseItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClinicalChaseListItem")
    .forMember("chaseId", o => o.chaseId)
    .forMember("lastName", o => o.memberLastName)
    .forMember("firstName", o => o.memberFirstName)
    .forMember("dateOfBirth", o => o.memberDateOfBirth)
    .forMember("measureCode", o => o.measureCode)
    .forMember("reviewPeriod", o => o.projectReviewPeriod)
    .forMember("status", o => o.workflowStatusName);
};

