import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private service: AuthService
  ) { }

  canActivate() {
    const isLoggedIn = this.service.isAuthenticated;
    if (!isLoggedIn) {
      this.service.logout();
    }

    return isLoggedIn;
  }
}
