import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { ProviderPacketModel } from "../chart-upload/email-verification/provider-packet.model";

@Injectable()
export class ReviewAuthLetterService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getContent(verificationCode: string): Observable<ProviderPacketModel> {
    const url = `${this.baseApiUrl}provider/reviewfilepath?verificationCode=${verificationCode}`;

    return this.http.get(url)
      .pipe(
      map(this.automapper.curry("default", "ProviderPacketModel"))
      );
  }

}
