import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { RetrievalTimelineItem } from "../../../retrieval-timeline-item.model";

@Injectable({
  providedIn: "root",
})
export class EmrDetailTimelineService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getEmrGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.EmrGd;
  }

  // OBSOLETE: Use app/platform/modules/retrieval/psr/address-detail/address-detail-timeline/address-detail-timeline.service.ts > getAddressTimelineActivityItems() instead.
  getEmrTimelineActivityItems(): Observable<RetrievalTimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/retrievalTimelineActivity?documentSourceId=${this.getEmrGdFromPath()}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "RetrievalTimelineItem"))
    );
  }
}
