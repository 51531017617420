<app-info-header-template>
  <div left>
    <div class="container-back-button">
      <app-back-button></app-back-button>
    </div>
    <div class="container-address">{{address | uppercase}}</div>
    <div>Last Validated: 
      <span [ngClass]="{'error-icon': !isAidValid}">
        {{aIdValidDateLabel}} 

        <app-icon 
          *ngIf="!isAidValid"
          iconName="question-circle"
          title="Please validate the providers listed at this address and primary contact information.">
        </app-icon>
    </span>
  </div>

  </div>
  <div center>
    <h3 class="container-status"></h3>
  </div>
  <div right>
    <div class="address-summary">
      <div class="address-summary__top">
        <div class="address-summary__top--address-id">ADDRESS ID {{addressId}}</div>
        <div class="address-summary__top--retrieval-type">{{retrievalType | uppercase}}</div>
      </div>
      <div class="address-summary__bottom">
        <div class="address-summary__bottom--container-called">LAST CALLED: <span class="address-summary__bottom--value">{{lastCalled}}</span></div>
      </div>
    </div>
    <app-icon-stack class="address-icon" [iconName]="iconName" iconBase="circle"></app-icon-stack>
  </div>
</app-info-header-template>
