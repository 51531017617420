<app-modal class="address-assignemnt-container"
           [(visible)]="visible"
           header="Assign To">
  <form [formGroup]="usersForm">
    <form-factory [formGroup]="usersForm"
                  [model]="usersInput"></form-factory>
  </form>
  <footer>
    <app-button class="header-button"
                text="ASSIGN"
                (onClick)="onAddressAssign()"
                [disabled]="!hasSelectedUser">
    </app-button>
  </footer>
</app-modal>