import { IAutoMapper } from "../interfaces";

export const mapUpdatePendItems = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "UpdatePendItem")
        .forMember("chasePendId", o => o.chasePendId)
        .forMember("chasePendIds", o => o.chasePendIds)
        .forMember("assignedToUserId", o => o.assignedToUserId)
        .forMember("notes", o => o.notes)
        .forMember("ownedBy", o => o.ownedBy)
        .forMember("pendStatusId", o => o.pendStatusId)
        .forMember("chaseId", o => o.chaseId)
        .forMember("owner", o => o.owner)
        .forMember("amount", o => o.amount)
        .forMember("pendCompanyId", o => o.pendCompanyId)
        .forMember("invoiceNumber", o => o.invoiceNumber)
        .forMember("pendSeverityId", o => o.pendSeverityId)
        .forMember("pendReasonId", o => o.pendReasonId);
};
