<div class="ui-g">
  <div class="ui-g-3">
    <h3>HCPCS Codes</h3>
  </div>
</div>
<app-basic-grid
  #medicalProcedureGrid
  class="selection-grid"
  *ngIf="hasData"
  [configuration]="gridConfiguration"
  [data]="medicalProcedures"
  [(selection)]="selection"
  (selectionChange)="setSelectedDiagnosisIndex()">

</app-basic-grid>