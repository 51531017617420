<div container-header class="analytics-container__header">
  <app-detail-header-template>
    <div class="submenu-content" bottom-left>
      <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
    </div>
    <div bottom-right>
      <app-utc-time-zone></app-utc-time-zone>
    </div>
  </app-detail-header-template>
</div>

<div class="filter-container">
  <div class="filter-applied">
    <app-analytics-filter-applied [formFilterRequest]="formFilterRequest" (isShowFilterModal)="showFilters()" (removeFilter)="onRemoveFilter($event)" [isRemovableItem]="false"></app-analytics-filter-applied>
  </div>
  <div (click)="showFilters()" class="icon-container">
    <app-icon iconName="filter"></app-icon>
  </div>
  <div title="Information about downloads" class="icon-container --margin-right" (click)="showHelpModal()">
    <app-icon iconStyle="far" iconName="question-circle" ></app-icon>
  </div>
</div>

<div>
  <app-modal class="analytics__filter" [(visible)]="isFiltersVisible">
    <app-tab-menu orientation="left" #tabTemplate>
      <app-tab-panel header="Service Organizations" class="checkbox-group__halves" *ngIf="isParentOrg">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="organizationInput" (onChange)="onOrganizationChange($event, model)">
            {{selectedTab}}
          </form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Clients" class="checkbox-group__halves" *ngIf="showFilter('Client')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="clientsInput" (onChange)="onClientChange($event)"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="IVA Projects" class="checkbox-group__halves" *ngIf="showFilter('Project')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="projectsInput" (onChange)="onSelectChange($event, model)"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="IVA Measure" class="checkbox-group__halves" *ngIf="showFilter('Measure')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Pend Date" *ngIf="showFilter('PendDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" (onBlur)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" [model]="pendCreateDateFrom"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
          <form-factory [formGroup]="form" (onChange)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" (onBlur)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" [model]="pendCreateDateTo"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Pend Status" class="checkbox-group__halves" *ngIf="showFilter('PendStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Pend Code" class="checkbox-group__halves" *ngIf="showFilter('PendCode')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Abstraction Date" *ngIf="showFilter('AbstractionDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="abstractionFromDate">
          </form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="abstractionToDate">
          </form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Overread2 Date" *ngIf="showFilter('Overread2Date')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="overread2FromDate" (onChange)="onDateChange(overread2FromDate,overread2ToDate)" (onBlur)="onDateChange(overread2FromDate,overread2ToDate)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
          <form-factory [formGroup]="form" [model]="overread2ToDate" (onChange)="onDateChange(overread2FromDate,overread2ToDate)" (onBlur)="onDateChange(overread2FromDate,overread2ToDate)"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Overread Date" *ngIf="showFilter('OverreadDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('overread')" [model]="overreadFromDate"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('overread')" [model]="overreadToDate"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Chart Received Date" *ngIf="showFilter('ChartReceived')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('chartReceived')" [model]="chartReceivedDateFromInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('chartReceived')" [model]="chartReceivedDateToInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Completion Date" *ngIf="showFilter('completion')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('completion')" [model]="completionFromDate"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('completion')" [model]="completionToDate"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Workflow Status" class="checkbox-group__halves" *ngIf="showFilter('workflowStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="workflowStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Update Date" *ngIf="showFilter('UpdateDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(updateDateFrom,updateDateTo)" (onBlur)="onDateChange(updateDateFrom,updateDateTo)"
                        [model]="updateDateFrom"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(updateDateFrom,updateDateTo)" (onBlur)="onDateChange(updateDateFrom,updateDateTo)"
                        [model]="updateDateTo"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Retrieval Type" class="checkbox-group__halves" *ngIf="showFilter('RetrievalType')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="retrievalTypeInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="File Create Date" *ngIf="showFilter('FileCreateDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="fileCreateDateFromInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="fileCreateDateToInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Retrieval Date" *ngIf="showFilter('RetrievalDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="retrievalFromDate"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="retrievalToDate"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Start Date" *ngIf="showFilter('StartDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="startDateInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="End Date" *ngIf="showFilter('EndDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="endDateInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Enrollee ID" *ngIf="showFilter('EnrolleeID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="enrolleeIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Member ID" *ngIf="showFilter('MemberID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="memberIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Client Member ID" *ngIf="showFilter('ClientMemberID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="clientMemberIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Claim ID" *ngIf="showFilter('ClaimID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="claimIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Dispensing Provider ID" *ngIf="showFilter('DispensingProviderID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="dispensingProviderIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="NDC" *ngIf="showFilter('NDC')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="ndcInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="HCPCS" *ngIf="showFilter('HCPCS')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="hcpcsInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Chase ID" *ngIf="showFilter('ChaseID')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Stratum Level" *ngIf="showFilter('StratumLevel')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="stratumLevelInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="RXC" *ngIf="showFilter('RXC')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="rxcInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Submission Status" *ngIf="showFilter('SubmissionStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="submissionStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="HCC Position" *ngIf="showFilter('HCCPosition')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="hccPositionInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Condition Category" *ngIf="showFilter('HCC')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="hccInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Chase Status" *ngIf="showFilter('ChaseStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="chaseStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Reported By Edge" *ngIf="showFilter('ReportedByEdge')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="reportedByEdgeInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Contact Method" class="checkbox-group__halves" *ngIf="showFilter('ContactMethod')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="contactMethodTypeInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Status" class="checkbox-group__halves" *ngIf="showFilter('CallType')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="callTypeInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Contact Date" *ngIf="showFilter('ContactDate')">
        <div class="-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(contactDateFromInput,contactDateToInput)" (onBlur)="onDateChange(contactDateFromInput,contactDateToInput)"
                        [model]="contactDateFromInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(contactDateFromInput,contactDateToInput)" (onBlur)="onDateChange(contactDateFromInput,contactDateToInput)"
                        [model]="contactDateToInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Products" *ngIf="showFilter('Products')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="productsInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Address" *ngIf="showFilter('Address')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
          <form-factory [formGroup]="form" [model]="addressGroupInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="NPI" *ngIf="showFilter('Npi')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="npiInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Benefit Year" class="checkbox-group__halves" *ngIf="showFilter('BenefitYear')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="benefitYearInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Date Range" *ngIf="showFilter('DateRange')">
        <div class="-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="dateRangeFromInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="dateRangeToInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Date of Service" *ngIf="showFilter('DOS')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="dateOfServiceDateFromInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="dateOfServiceDateToInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Billing Date" *ngIf="showFilter('BillingDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(billingDateFrom,billingDateTo)" (onBlur)="onDateChange(billingDateFrom,billingDateTo)" [model]="billingDateFrom">
          </form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
          <form-factory [formGroup]="form" (onChange)="onDateChange(billingDateFrom,billingDateTo)" (onBlur)="onDateChange(billingDateFrom,billingDateTo)" [model]="billingDateTo">
          </form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Create Date" *ngIf="showFilter('CreateDate')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" (onChange)="onDateChange(createFromDate,createToDate)" (onBlur)="onDateChange(createFromDate,createToDate)" [model]="createFromDate">
          </form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
          <form-factory [formGroup]="form" (onChange)="onDateChange(createFromDate,createToDate)" (onBlur)="onDateChange(createFromDate,createToDate)" [model]="createToDate">
          </form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Pend Owner" *ngIf="showFilter('PendOwner')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="pendOwnerInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Line of Business" *ngIf="showFilter('LOB')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="lineOfBusinessInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Retrieval Owner" *ngIf="showFilter('RetrievalOwner')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="retrievalOwnerInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Expected Retrieval" class="checkbox-group__halves" *ngIf="showFilter('ExpectedRetrieval')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="expectedRetrievalInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Project Status" class="checkbox-group__halves" *ngIf="showFilter('ProjectStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="projectStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Vendor Name" class="checkbox-group__halves" *ngIf="showFilter('VendorName')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="vendorNameInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Special Handling" class="checkbox-group__halves" *ngIf="showFilter('SpecialHandling')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="specialHandlingInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Health Plan" *ngIf="showFilter('HealthPlan')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="healthPlanInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Contract Number" *ngIf="showFilter('ContractNumber')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="contractNumberInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Move Date" class="checkbox-group__halves" *ngIf="showFilter('MoveDateStart') && showFilter('MoveDateEnd')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory (onChange)="updateMinDate(moveDateStart, moveDateEnd)" (onBlur)="updateMinDate(moveDateStart, moveDateEnd)" [formGroup]="form" [model]="moveDateStart"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="moveDateEnd"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Current Chase Status" *ngIf="showFilter('CurrentChaseStatus')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="currentChaseStatus"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Current Process Step" *ngIf="showFilter('CurrentProcessStep')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="currentProcessStep"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Vendor Invoice type" *ngIf="showFilter('VendorInvoiceType')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="vendorInvoiceType"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel class="checkbox-group__halves" header="Type of Pend" *ngIf="showFilter('PendType')">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="pendTypeInput"></form-factory>
        </div>
      </app-tab-panel>
    </app-tab-menu>

    <footer>
      <app-button class="reset-filter-button" (onClick)="resetAllFilters()" text="Reset All"></app-button>
      <app-button (onClick)="closeFilters()" text="Update"></app-button>
      <div class="error-message" *ngIf="showErrorMessage">{{errorMessage}}</div>
    </footer>

  </app-modal>
  <app-iframe [visible]="isVisible" [load]="true" iframeSrc="{{lookerUrl}}" iframeWidth="99%"></app-iframe>
</div>
<app-analytics-instrucions-modal [(isHelpModalOpen)]="isHelpModalOpen"></app-analytics-instrucions-modal>