<app-button
  *ngFor="let action of filteredActions; trackBy: trackByIndex"
  class="button"
  classes="small"
  [text]="action.name"
  (onClick)="onClick(action)"
  [disabled]="!hasSelectedItems || action.disabled">
</app-button>
<app-button *ngFor="let action of filteredIconActions; trackBy: trackByIndex"
            class="icon-button"
            styleType="fal"
            type="fa"
            [icon]="action.iconName"
            (onClick)="onClick(action)"
            [disabled]="!hasSelectedItems || action.disabled">
</app-button>
