import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-training-feedback-dw",
  templateUrl: "./training-feedback-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFeedbackDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.TRAINING_FEEDBACK_REPORT_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
}
