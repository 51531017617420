export class AddressDetailInfoAssignment {
  addressId: number;
  documentSourceTypeId: number;
  assignmentNote: string;

  constructor(options: {
    addressId: number;
    documentSourceTypeId: number;
    assignmentNote: string;
  }) {
    this.addressId = options.addressId;
    this.documentSourceTypeId = options.documentSourceTypeId;
    this.assignmentNote = options.assignmentNote;
  }
}
