export enum WorkflowStatus {
  DataLoad = 0,
  Accquisition = 1,
  MRQA = 2,
  MRR = 3,
  Overread = 4,
  Overread2 = 5,
  Delivery = 6,
  DocumentIntake= 7,
}
