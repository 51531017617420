import { PhoneNumberPipe } from "../../../shared/pipes/phone.pipe";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class Contact {
  private phonePipe = new PhoneNumberPipe();

  id: number;
  contactEmail: string;
  contactFax: string;
  contactName: string;
  contactPhone: string;
  weeklyContactMethodTypeName: string;
  isPrimaryContact: boolean;

  constructor(options: Partial<Contact> = {}) {
    this.id = options.id;
    this.contactEmail = StringHelper.clean(options.contactEmail);
    this.contactFax = this.phonePipe.transform(options.contactFax);
    this.contactName = StringHelper.clean(options.contactName);
    this.contactPhone = this.phonePipe.transform(options.contactPhone);
    this.weeklyContactMethodTypeName = StringHelper.clean(options.weeklyContactMethodTypeName);
    this.isPrimaryContact = BooleanHelper.tryGet(options.isPrimaryContact, false);
  }
}
