import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-dob-discrepancy",
  templateUrl: "./dob-discrepancy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DobDiscrepancyComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.DOB_DISCREPANCY;

  constructor() {
    this.filters = ["Projects", "Products", "LOB", "RetrievalOwner"];
  }

}
