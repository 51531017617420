import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridLoadEvent } from "../../../../../shared/grid/models/grid-load-event.model";
import { MemberListingService } from "../member-listing/member-listing.service";

@Component({
  selector: "member-listing",
  templateUrl: "./member-listing.component.html",
  styleUrls: ["./member-listing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MemberListingService],
})
export class MemberListingComponent {
  private columnHeader: GridColumnDefinition[];
  gridConfigurationModel: GridConfiguration;
  rowDataModel: any[];

  constructor(
    private readonly router: Router,
    private readonly service: MemberListingService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.columnHeader = [
      new GridColumnDefinition({ field: "id", header: "ID", routeUrl: "/members/chase/:id", width: "200px" }),
      new GridColumnDefinition({ field: "name", header: "Name" }),
      new GridColumnDefinition({ field: "address", header: "Address" }),
      new GridColumnDefinition({ field: "address2", header: "Address 2" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "zip", header: "Zip" }),
      new GridColumnDefinition({ field: "dob", header: "DOB" }),
      new GridColumnDefinition({ field: "gender", header: "Gender" }),
      new GridColumnDefinition({ field: "providerId", header: "Provider ID", isSortableColumn: false }),
      new GridColumnDefinition({ field: "chases", header: "Chases" }),
      new GridColumnDefinition({ field: "marital", header: "Marital" }),
    ];

    this.gridConfigurationModel = new GridConfiguration();
    this.gridConfigurationModel.columns = this.columnHeader;
    this.gridConfigurationModel.pageSize = 25;
  }

  loadGridDataSource(event: GridLoadEvent) {
    this.service
      .getMembersList(event.sortField, event.sortDirection, event.startPage, event.endPage)
      .subscribe(this.assignAndNotify);
  }

  loadAddressDetail(documentSourceId) {
    this.router.navigate(["members", "address", documentSourceId]);
  }

  assignAndNotify = <T>(data: T[]): void => {
    this.rowDataModel = data as any;
    this.changeDetector.markForCheck();
  }

}
