import { Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-pends-summary-dw",
  templateUrl: "./pends-summary-dw.component.html",
})
export class PendsSummaryDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.PENDS_SUMMARY_DW;
  hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
}
