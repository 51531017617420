import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-hcc-summary-by-claim-id",
  templateUrl: "./hcc-summary-by-claim-id.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccSummaryByClaimIdComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.HCC_SUMMARY_BY_CLAIM_ID;

    constructor() {
        this.filters = ["Projects", "Address", "Hcc", "MemberId", "MemberFirstName", "MemberLastName",
                        "MemberDob", "MemberKey", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
