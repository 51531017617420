<div class="upload-bulk-container" #uploadContainer>
  <div class="header-container">
    <h3 data-qa="upload-bulk-page-header">BULK UPLOAD</h3>
    <div>Want to work faster not harder? On this page, you can Bulk Upload by Chase IDs, Client Chase Key, or upload documents for Intake.</div>
  </div>
  <div [hidden]="!displayUploadContainer" class="form-container-rw">
    <div class="form-container">
      <form-factory [formGroup]="form"
                    [model]="originalRetrievalMethodInput">
      </form-factory>
    </div>
    <div #container class="form-container format-type-blc">
      <form-factory [formGroup]="form" [model]="formatTypeInput" (onChange)="onSelectFormatType()"></form-factory>
    </div>
    <div class="add-btn-rw">
       <div class="label-block">
        <approval-center-upload-bulk-manage-tag  (tagDataModel)="onSelectedTagData($event)" [tagType]="tagType" [tagActionType]="tagActionType"> </approval-center-upload-bulk-manage-tag>
       </div>
    </div>
    <div *ngIf="isClientChaseKeySelected">
      <div class="form-container">
        <form-factory [formGroup]="form" [model]="projectInput"></form-factory>
      </div>
    </div>
   
  
<div class="bulkupload-wrapper" *ngIf="selectedFormat">
 <div class="provider-block">
      <div class="provider-inner drag-drop-rw">
          <div class="drag-drop-blc">
              <p-fileUpload #fileUpload name="file[]" (onSelect)="onFileSelect($event, fileUpload)"
                  (onRemove)="onFileRemove($event)" (uploadHandler)="validateDocuments()" customUpload="true"
                  multiple="true" accept=".pdf"  [maxFileSize]="maxFileSizeForBulkUploadInBytes" chooseLabel="CHOOSE FILES"
                  uploadLabel="VALIDATE" [showUploadButton]="false" [showCancelButton]="false" placeholder="testing"
                  invalidFileSizeMessageSummary="{0}: Invalid file size, " 
                  dragDropSupport="true" mode="advanced"
                  [disabled]="isUploadStarted"
                  invalidFileSizeMessageDetail="maximum upload size per file is {maxFileSizeForBulkUploadInMB | number:'1.0-0'} MB. Reach out to your Project Manager">
                  <ng-template pTemplate="content">
                          <div class="inner-blc">
                                  <div class="file-bulkupload-container__text" >
                                      <span>Drag & Drop Medical Records Here</span>
                                  </div>
                                  <div class="drag-drop-icn">
                                      <span>
                                          <span class="top"></span>
                                          <span class="btm"></span>
                                      </span>
                                  </div>
                                  <div class="or-line">
                                      <span></span>
                                      <label>OR</label>
                                      <span></span>
                                  </div>
                              </div>
                  </ng-template>
              </p-fileUpload>

          </div>
          <div class="drag-drop-list"  *ngIf="filesUploaded">
              <div class="list-blc">
                  <div class="list-item" *ngFor="let file of selectedFileList; index as i; trackBy: trackByIndex">
                      <label (mouseenter)="file.name.length > 28 ? fileNamePopover.show($event) : fileNamePopover.hide()" (mouseout)="fileNamePopover.hide()">{{ file.name | titlecase }}</label>
                      <app-popover #fileNamePopover appendTo="body">
                          <div class="popover-text">{{file.name}}</div>
                      </app-popover>
                  <div class="chip__item chip__item--icon" (click)="onFileRemove(file, i)">
                      <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
                  </div>
                  </div>
              </div>
              <div class="list-blc-btn">
                  <button class="btn btn-success" (click)="validateDocuments()">SUBMIT</button>
              </div>
          </div>
      </div>
  </div>
</div>
    <div class="results">
      <span *ngIf="showValidationText"  class="results__meassage">{{validationText}}</span>
      <span *ngIf="gridVisible" class="results__total--invalid">{{getSelectedFileText}}</span>
      <app-basic-grid *ngIf="gridVisible" #myGrid class="grids-display"
                      [configuration]="gridConfiguration"
                      [data]="gridData"
                      [(selection)]="gridSelection"
                      [actionTemplate]="actionTemplate">
                      
        <ng-template #fileSizeColumn let-rowData >
          <div>{{rowData.fileSize}}MB</div>
        </ng-template>
       <ng-template #progressColumn let-rowData >
          <div class="status-container" *ngIf="showProgressStatus(rowData)">
            {{getUploadStatus(rowData.uploadStatus)}}
          </div>
          <app-progress-bar-upload [(uploadProgress)]="rowData.uploadProgress" [(showProgressPercentage)]="showProgressPercentage" [(isVisible)]="isUploadStarted" [hasError]="!isValidForSelect(rowData)" [hasRunTimeError]="rowData.hasError"></app-progress-bar-upload>
          <div *ngIf="rowData.hasError" class="errorMessage">Unable to Upload Document</div>
        </ng-template>
        <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
          <div class="action-template-container">
            <app-button text="Delete File" *ngIf="showDeleteButton(rowData)" (onClick)="deleteFileClick(rowData); localActionPopover.hide();"></app-button>
           <app-button text="ReOpen Chase" *ngIf="showReopenButton(rowData)" (onClick)="reOpenChaseClick(rowData); localActionPopover.hide();"></app-button>
          </div>
       </ng-template>
      </app-basic-grid>
    </div>
   
  </div>

</div>
