<div class="container-content">
  <app-provider-header [welcomeText]="welcomeText" [supportText]="supportText" [imagePath]="imagePath"></app-provider-header>

  <div class="ui-g ui-fluid onboarding-heading">
    <div class="ui-g-12 ui-md-12 ui-lg-12 text-center">
      Thank you for uploading!
    </div>
  </div>

</div>
