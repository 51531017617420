import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { CheckboxGroupComponent } from "./checkbox-group.component";

export interface ICheckboxGroupOptions extends IDynamicInputOptions {
  options?: { text: string; value: string | number }[];
  showTooltip?: boolean;
}

export class CheckboxGroup extends DynamicInput implements ICheckboxGroupOptions {
  filter: boolean;
  options: any[];
  hidden: boolean;
  selectedOptions: SelectableInput[];
  showTooltip: boolean;

  readonly controlType = "checkbox-group";
  readonly componentType = CheckboxGroupComponent;


  constructor(options: ICheckboxGroupOptions = {}) {
    super(options);
    this.options = options.options || [];
    this.selectedOptions = options.value || [];
    this.showTooltip = options.showTooltip || false;
  }
}
