export class AuditQueryGridFilters {
  projectId: number;
  measureId: number;
  numeratorId: number;
  complianceCode: string;

  constructor(options: {
    projectId?: number;
    measureId?: number;
    numeratorId?: number;
    complianceCode?: string;
  } = {}) {
    this.projectId = options.projectId;
    this.measureId = options.measureId;
    this.numeratorId = options.numeratorId;
    this.complianceCode = options.complianceCode;
  }
}
