import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-chase-move-back-history-report-legacy",
  templateUrl: "./chase-move-back-history-report-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveBackHistoryReportLegacyComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CLINICAL_ACCURACY_BY_CHASE_WITH_ERROR_DETAIL_LEGACY;

  constructor() {
    this.filters = ["Projects", "Products", "LOB", "RetrievalOwner"];
  }

}
