<app-modal class="manage-tag-container"
           [(visible)]="visible"
           header="Manage {{entityName}} Tags"
           (onCancel)="cancel()">

  <div class="entity-count-label">TOTAL {{entityName}}: {{ totalEntityCount }}</div>

  <br />

  <div class="tag-container table-tags-container">
    <form [formGroup]="manageTagForm">
      <form-factory [formGroup]="manageTagForm"
                    [model]="tagActionInput"
                    (onChange)="getTagActionType($event)"></form-factory>

      <form-factory *ngIf="hasReplaceActionType" [formGroup]="manageTagForm"
                    [model]="currentTagInput"></form-factory>

      <form-factory *ngIf="hasRemoveActionType" [formGroup]="manageTagForm"
                    [model]="removeTagInput"></form-factory>

      <app-create-tag *ngIf="hasAddActionType"
                      [tagType]="tagType"
                      [hasGridSelection]="true"
                      [objectIds]="objectIds"
                      [tagActionType]="tagAction"
                      [value]= "value"
                      [totalEntityCount] = "totalEntityCount"
                      [isCreateTagTemplateVisible]="isCreateTagTemplateVisible"></app-create-tag>

      <app-create-tag *ngIf="hasNewReplaceActionType"
                      [tagType]="tagType"
                      [hasGridSelection]="true"
                      [objectIds]="objectIds"
                      [tagActionType]="tagAction"
                      [value] = "value"
                      [isCreateTagTemplateVisible]="false"></app-create-tag>

    </form>
  </div>

  <footer>
    <app-button classes="secondary"
                text="CONFIRM" [disabled]="!hasTagSelected"
                (onClick)="onTagConfirm()">
    </app-button>
  </footer>
</app-modal>
