import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-life-of-a-chase",
  templateUrl: "./life-of-a-chase.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeOfAChaseComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.LIFE_OF_A_CHASE_REPORT;

    constructor() {
        this.filters = ["Projects", "Address", "ChaseId"];
    }

}
