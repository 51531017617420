import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { PendTimelineItem } from "../../pend-timeline-item.model";
import { InternalPendDetailTimelineService } from "./internal-pend-detail-timeline.service";

@Component({
  selector: "app-internal-pends-detail-timeline",
  template: `<app-timeline-detail [timeline]="timelineItems"></app-timeline-detail>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsDetailTimelineComponent implements OnInit {
  timelineItems = List<PendTimelineItem>();

  constructor(
    private service: InternalPendDetailTimelineService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
      this.getTimelines();
    }


  private getTimelines(): void {
        this.service.getPendTimelineActivityItems().subscribe(timelines => {
            const groupedTimelines = this.mapToGroupedTimelines(timelines);
            this.timelineItems = List(groupedTimelines);
            this.changeDetector.markForCheck();
        });
    }

  private mapToGroupedTimelines(timelines: TimelineItem[]): PendTimelineItem[] {
        const timelineList = timelines.reduce((timeLineGroup, timeLineItem) => {
            const specificDay = DateHelper.format(timeLineItem.timestamp);
            timeLineGroup[specificDay] = timeLineGroup[specificDay] || [];
            timeLineGroup[specificDay].push(timeLineItem);
            return timeLineGroup;
        },                                    {});

        const groupedTimelines = Object.keys(timelineList).map(specificDay => {
            return new PendTimelineItem({
                specificDay,
                timelineGroup: timelineList[specificDay],
            });
        });
        return groupedTimelines;
    }
}
