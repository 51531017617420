import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GenericAsyncValidator } from "../../../../../../dynamic-forms/validators/generic.async-validator";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { MemberValidation } from "../../../../../api/member-validation/member-validation.model";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";

@Component({
  selector: "member-medical-record-dob",
  templateUrl: "./medical-record-dob.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalRecordDobComponent implements OnInit, OnChanges, OnDestroy {
  private sink = new SubSink();
  @Input() set enabled(value: boolean) {
    this.setDisabled(value);
  }
  @Input() chaseId: number;
  @Output() onChange = new EventEmitter<any>(true);
  @Input() configuration: MemberValidation;

  chaseDetailState: ChaseDetailState;
  medicalRecordDobInput: Textbox;
  saveGroup: SaveGroup;
  form: FormGroup;


  constructor(
    private changeDetector: ChangeDetectorRef,
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private genericAsyncValidator: GenericAsyncValidator
  ) { }

  ngOnInit() {
    this.createForm();
    this.getMedicalRecordDob();
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configuration) {
      if (changes.configuration.isFirstChange()) { return; }

      if (changes.configuration.currentValue !== changes.configuration.previousValue) {
        this.getMedicalRecordDob();
      }
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  handleChange(event: DynamicFormEvent): void {
    const formattedDate = DateHelper.format(event.value.MedicalRecordDob);
    this.form.get(this.medicalRecordDobInput.getMasterKey()).setValue(formattedDate);

    if (this.form.valid) {
      this.onChange.emit(this.configuration);
    }
  }

  private get hasForm(): boolean {
    return this.form != null;
  }

  private get control(): FormControl {
    if (!this.hasForm) {
      return null;
    }

    const control = this.form.get(this.medicalRecordDobInput.getMasterKey()) as FormControl;
    return control;
  }

  private setDisabled(enable: boolean): void {
    // HACK: Wait for the form to exist before enabling/disabling.
    if (!this.hasForm) {
      setTimeout(() => this.setDisabled(enable), 100);
      return;
    }

    if (enable) {
      this.control.enable();
    } else {
      this.control.disable();
    }

    this.formService.updateDom.next();
  }

  private createForm(): void {
    this.medicalRecordDobInput = new Textbox({
      key: "MedicalRecordDob",
      label: "MR DOB",
      dataType: "date",
    });
    this.saveGroup = new SaveGroup({
      key: "MedicalRecordDobSaveGroup",
      isHideClearButton: true,
      controls: [this.medicalRecordDobInput],
    });
    this.medicalRecordDobInput.parent = this.saveGroup;
    this.form = this.formService.createFormGroup([this.saveGroup]);

    this.sink.add(
      this.form.get(this.medicalRecordDobInput.getMasterKey()).valueChanges
        .subscribe(value => {
          this.configuration.dob = value == null ? null : value;
  })
    );
  }

  private getMedicalRecordDob(): void {
    this.chaseDetailStateService.setData({
      medicalRecordDateOfBirth: this.configuration.dateOfBirth.value as string,
    });

    this.form.get(this.medicalRecordDobInput.getMasterKey()).setValue(this.configuration.dateOfBirth.value);
  }
}
