import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DocumentPage } from "../../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPageNlpMatches } from "../document-page-nlp/document-page-nlp-matches.model";
import { NlpWord } from "../document-page-nlp/nlp-word.model";

@Component({
  selector: "app-document-page-highlight-dos",
  templateUrl: "./document-page-highlight-dos.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageHighlightDosComponent {
  @Input() page: DocumentPage;
  @Input() nlpData: DocumentPageNlpMatches;
  @Input() nlpStyles: any;
  @Input() zoomPercentage = 100;

  getNlpWords(page: DocumentPage): NlpWord[] {
    const matchedMemberWords = this.getMatchedNlpWords(page);
    const words = matchedMemberWords == null ? [] : matchedMemberWords;

    return words;
  }

  getMatchedNlpWords(page: DocumentPage): NlpWord[] {
    const matchedWords = this.nlpData.nlpMatches.filter(a => a.pageNumber === page.pageNumber);

    return matchedWords;
  }

  getWordNlpStyles(word: NlpWord): any {
    const style = {
      width: `${(word.boundingBoxes[1].x - word.boundingBoxes[0].x) * this.zoomPercentage}%`,
      height: `${(word.boundingBoxes[1].y - word.boundingBoxes[0].y) * this.zoomPercentage}%`,
      top: `${word.boundingBoxes[0].y * this.zoomPercentage}%`,
      left: `${word.boundingBoxes[0].x * this.zoomPercentage}%`,
      position: "absolute",
      backgroundColor: "rgba(131, 188, 106,0.25)",
    };

    return style;
  }

  trackByIndex(index, item) {
    return index;
  }
}
