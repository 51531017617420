import { IAutoMapper } from "../interfaces";

export const mapEditEmrModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "EmrEditModel")
    .forMember("retrievalLocationId", o => o.retrievalLocationId)
    .forMember("address1", o => o.address1)
    .forMember("address2", o => o.address2)
    .forMember("addressCity", o => o.addressCity)
    .forMember("addressState", o => o.addressState)
    .forMember("addressZip", o => o.addressZip)
    .forMember("url", o => o.url)
    .forMember("phone", o => o.phone)
    .forMember("email", o => o.email)
    .forMember("faxNumber", o => o.faxNumber)
    .forMember("contactName", o => o.contactName)
    .forMember("groupName", o => o.groupName);
};
