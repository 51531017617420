export class URIFormatHelper {
  static isAvailable(value: string): boolean {
    if (value == null) {
      return false;
    }

    if (value.trim() === "") {
      return false;
    }

    return true;
  }

  // This function is used to encode a URI, except: , / ? : @ & = + $ # (Use encodeFullUri() to encode these characters).
  static encodeParameters(value: any): string {
    if (!this.isAvailable(value)) {
      return "";
    }

    return encodeURIComponent(value);
  }

  // This function encodes a URI component. Additionally it encodes the following characters: , / ? : @ & = + $ #
  static encodeFullUri(uri: string): string {
    if (!this.isAvailable(uri)) {
      return "";
    }

    return encodeURI(uri);
  }

  // This function decodes a URI component.
  static decodeUriComponent(valueEncoded: string): string {
    if (!this.isAvailable(valueEncoded)) {
      return "";
    }

    return decodeURIComponent(valueEncoded);
  }

  // This function is used to decode a URI.
  static decodeFullUri(uriEncoded: string): string {
    if (!this.isAvailable(uriEncoded)) {
      return "";
    }

    decodeURI(uriEncoded);
  }
}
