import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../../../core/environment.tokens";
import { ClinicalTerm } from "./clinical-term.model";


@Injectable({
  providedIn: "root",
})
export class ClinicalTermsService {

  private route = `${this.baseApiUrl}chase/risk/clinicalterms`;
  private pagesNumber = new Subject<number[]>();
  pagesNumber$ = this.pagesNumber.asObservable();
  private pagesDocumentId = new BehaviorSubject<number[]>([]);
  pagesDocumentId$ = this.pagesDocumentId.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly automapper: AutomapperService,
    private readonly http: HttpClient) { }

  getClinicalTerms(chaseId: number, dosFrom: string, dosTo: string): Observable<ClinicalTerm[]> {
    let params = new HttpParams().append("chaseId", chaseId.toString());
    params = params.append("dosFrom", dosFrom);
    params = params.append("dosThrough", dosTo);

    return this.http.get(`${this.route}`, { params }).pipe(
      map(this.automapper.curryMany("default", "ClinicalTerm"))
    );
  }

  updatePagesDocumentIdFilter(pagesDocumentId: number[]): void {
    this.pagesDocumentId.next(pagesDocumentId);
  }

  updatePagesFilter(pagesNumber: number[]): void {
    this.pagesNumber.next(pagesNumber);
  }

}
