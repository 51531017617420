export enum WorkflowStatusDb {
    Dataload = 1,
    ChartCollection = 2,
    WaitingForChart = 3,
    ChartQA = 4,
    Abstraction = 5,
    Overread = 6,
    Overread2 = 7,
    Delivery = 8,
    Completed = 9,
}
