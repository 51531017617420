<div class="docrequests-header bold">DOCUMENT REQUEST(S) </div>

<div class="docrequests-container">
  <div *ngIf="hasDocumentRequests " class="docrequests-detail">
    <div class="item" *ngFor="let documentRequestItem of documentRequests; let i = index; trackBy: trackByIndex">
      <app-popover #docRequestPopover>
        <div class="item" *ngFor="let item of documentRequestItem.documentsRequested; trackBy: trackByIndex">
          <div class="item-doc">{{ item.documentName }} </div>
        </div>
      </app-popover>
      <div class="docrequest-detail" (mouseenter)="docRequestPopover.show($event)"
        (mouseout)="docRequestPopover.hide()">
        <span class="bold">Document Request
          <a [routerLink]="addressUrlOfChase"> {{documentRequestItem.documentRequestId}}</a>
        </span> - {{documentRequestItem.numberOfDocumentsRequested}} document(s) requested on
        {{documentRequestItem.createdDate | date:"MM/dd/yyyy"}}
        by {{documentRequestItem.createdByName}}
        <p  class="docrequest-detail__header" *ngIf="isNoteExist(documentRequestItem.note)">
          <span class="bold">Note: </span>{{documentRequestItem.note}}</p> 
      </div>
    </div>
  </div>
  <div *ngIf="!hasDocumentRequests " class="bold">
    No Document Request Created
  </div>

</div>