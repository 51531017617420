import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DynamicControl } from "../../../../dynamic-forms/dynamic-control.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { NumeratorListItem } from "../../../api/numerator/numerator-list-item.model";
import { CreateNewChaseAction } from "./create-new-chase-action.enum";
import { NewChaseProjectItem } from "./create-new-chase-project/new-chase-project-item";
import { NewChaseResponse } from "./new-chase-response.model";
import { NewChase } from "./new-chase.model";

@Injectable({
  providedIn: "root",
})
export class CreateChaseService {
  private newChase: NewChase;
  private newChaseProjectItems: NewChaseProjectItem;

  constructor(@Inject(BASE_API_URL)
  private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) {
    this.newChase = new NewChase();
    this.newChaseProjectItems = new NewChaseProjectItem();
  }

  get chaseModel(): NewChase {
    return this.newChase;
  }
  set chaseModel(value: NewChase) {
    this.newChase = value;
  }

  get chaseProjectItems(): NewChaseProjectItem {
    return this.newChaseProjectItems;
  }
  set chaseProjectItems(value: NewChaseProjectItem) {
    this.newChaseProjectItems = value;
  }

  saveChaseDetails(newChase: NewChase): Observable<NewChaseResponse> {
    const url = `${this.baseApiUrl}chase/create`;
    return this.http.post(url, newChase) as Observable<NewChaseResponse>;
  }

  getConfiguration(measureId: number): Observable<DynamicControl[]> {
    const url = `${this.baseApiUrl}chase/measure/attribute?measureId=${measureId}`;

    return this.http.get(url).pipe(
      map((config: any) => this.toDynamicControls(config, null))
    );
  }

  private toDynamicControls = (configuration: any[], parent: DynamicControl = null): DynamicControl[] => {
    return configuration.reduce(this.toControls(parent), []);
  }

  private toControls = (parent: DynamicControl) => {
    return (groups: any[], item: any): DynamicControl[] => {
      for (let i = 0; i < item.minimumEntityCount; i++) {
        const model = this.getControl(item, parent, i);
        groups.push(model);
      }
      return groups;
    };
  }

  private getControl = (configuration: any, parent: DynamicControl, i: number, index: number = null): DynamicControl => {
    const key = this.getKey(configuration, index, i);

    const control = new Textbox({
         key,
         dataType: configuration.attributeDataType,
         label: configuration.attributeName,
         validators: [
            Validators.required,
            configuration.attributeDataType === "date" ? Validators.pattern(RegExHelper.date) : Validators.min(0),
         ],
         errorMessages: {
           required: "Please enter the required field.",
           pattern: configuration.attributeDataType === "date" ? "Date Must be in format MM/DD/YYYY" : "",
         },
         value: configuration.attributeValue,
        });

    return control;
  }

  getKey(configuration: any, index: number, i: number): string {
    if (index != null) {
      return index.toString();
    }

    const entityTypeId = configuration.entityTypeId;
    const attributeId = configuration.attributeId;
    const configKey = entityTypeId && attributeId && i > 0 ? `${entityTypeId}_${attributeId}_${i}` : `${entityTypeId}_${attributeId}`;

    const key = StringHelper.isAvailable(configKey) ? configKey : this.createRandomId();
    return key;

  }

  private createRandomId(): string {
    return Math.random().toString(36).substring(7);
  }

  isChaseKeyValid(projectId: number, chaseKey: string): Observable<boolean> {
    const url = `${this.baseApiUrl}chase/chasekey/unique?projectId=${projectId}&chaseKey=${chaseKey}`;
    return this.http.get(url, { observe: "response" }).pipe(map((response: HttpResponse<any>): boolean => response.ok ? response.body : true));
    }

  validateChaseDetails(newChase: NewChase): Observable<string[]> {
        newChase.action = CreateNewChaseAction.Validate;
        newChase.projectId = this.chaseProjectItems.projectId;
        newChase.measureCode = this.chaseProjectItems.measureCode;
        newChase.chaseKey = this.chaseProjectItems.chaseKey;
        const url = `${this.baseApiUrl}chase/validate`;
        return this.http.post(url, newChase).pipe(map((response: string) => response.trim().split(";").filter(x => x !== "") as string[]));
    }

  getDiagnosis(icdCode: string): Observable<SelectableInput[]> {
        const url = `${this.baseApiUrl}risk/diagnosis?icdCode=${icdCode}`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("Diagnosis", "SelectableInput"))
        );
  }

  clear(): void {
    this.chaseModel = new NewChase();
  }

  getNumeratorList(projectId: number, measureId: number): Observable<NumeratorListItem[]> {
    const url = `${this.baseApiUrl}chase/measure/numerators?projectId=${projectId}&measureId=${measureId}`;
    return this.http.get(url).pipe(map((response: any) => response as NumeratorListItem[]));
  }

  createPursuitChase(parentChaseId: Number, entityIds: Number[], requestType: string): Observable<NewChaseResponse> {
    const url = `${this.baseApiUrl}chase/pursuit/create`;
    return this.http.post(url, {parentChaseId, entityIds, requestType}) as Observable<NewChaseResponse>;
  }

}
