import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-icon-linkedin",
  template: `<a
                href="{{url}}"
                target="_blank"
                class="fab fa-linkedin-in"></a>`,
  styleUrls: ["./icon-social-media.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLinkedinComponent {

  get url(): string {
    return "https://www.linkedin.com/company/reveleer";
  }
}
