import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class CreateAddress {
  readonly addressGroup: string;
  readonly address1: string;
  readonly address2: string;
  readonly addressCity: string;
  readonly addressState: string;
  readonly addressZip: string;
  readonly contactData: string;
  readonly contactName: string;
  readonly groupName: string;
  readonly phone: string;
  readonly faxNumber: string;
  readonly clientAddressId: number;
  readonly coRetrievalOwner: string;
  readonly weeklyContactMethodTypeId: string;
  readonly email: string;

  constructor(options: {
    readonly addressGroup?: string;
    readonly address1: string;
    readonly address2?: string;
    readonly addressCity: string;
    readonly addressState: string;
    readonly addressZip: string;
    readonly contactData?: string;
    readonly contactName?: string;
    readonly groupName?: string;
    readonly phone?: string;
    readonly faxNumber?: string;
    readonly clientAddressId?: number;
    readonly coRetrievalOwner?: string;
    readonly weeklyContactMethodTypeId?: string;
    readonly email?: string;
  }) {
    this.addressGroup = options.addressGroup;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.addressCity = options.addressCity;
    this.addressState = options.addressState;
    this.addressZip = options.addressZip;
    this.contactData = options.contactData;
    this.contactName = options.contactName;
    this.groupName = options.groupName;
    this.phone = options.phone;
    this.faxNumber = options.faxNumber;
    this.clientAddressId = options.clientAddressId;
    this.coRetrievalOwner = options.coRetrievalOwner;
    this.weeklyContactMethodTypeId = options.weeklyContactMethodTypeId;
    this.email = options.email;
   }

  get fullAddress(): string {
    return `${this.address1},${StringHelper.isAvailable(this.address2) ? ` ${this.address2}` : ""}, ${this.addressCity}, ${this.addressState}, ${this.addressZip}`;
  }
}
