import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { obsolete } from "../../utilities/decorators/obsolete.decorator";

@Component({
  selector: "app-button",
  template: `
    <p-button
    [ngClass]="classes"
    [label]="labelText"
    [icon]="iconClass"
    [styleClass]="styleClass"
    [iconPos]="iconPos"
    [disabled]="disabled"
    (onClick)="onClick.emit($event)"></p-button>
  `,
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() text = "";
  @Input() immutableText = "";
  @Input() icon = "";
  @Input() styleType = "fas";
  @Input() type = "fa";
  @Input() classes = "";
  @obsolete("Use 'classes' instead") @Input() styleClass = "";
  @Input() iconPos = "left";
  @Input() disabled = false;
  @Input() focusable = true;
  @Output() onClick = new EventEmitter<any>();

  get labelText(): string {
    return StringHelper.isAvailable(this.immutableText) ?
      this.immutableText :
      StringHelper.toTitleCase(this.text);
  }

  get hasIcon(): boolean {
    return StringHelper.isAvailable(this.icon);
  }
  get iconClass(): string {
    return this.hasIcon ? `${this.styleType} ${this.type}-${this.icon}` : "";
  }


  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (!this.focusable) {
      this.el.nativeElement.firstElementChild.firstElementChild.tabIndex = -1;
    }
  }
}
