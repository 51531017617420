import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class ProviderPacketResponseItem {
  readonly url: string;
  readonly templateId: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly status: string;
  readonly duration: string;

  constructor(options: {
    url: string;
    templateId: string;
    startTime: string;
    endTime: string;
    status: string;
    duration: string;
  }) {
    this.url = StringHelper.clean(options.url);
    this.templateId = StringHelper.clean(options.templateId);
    this.startTime = StringHelper.clean(options.startTime);
    this.endTime = StringHelper.clean(options.endTime);
    this.status = StringHelper.clean(options.status);
    this.duration = StringHelper.clean(options.duration);
  }
}

