import { IAutoMapper } from "../../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

export const mapChaseDetailInfoPursuitItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChaseDetailInfoPursuitItem")
    .forMember("chaseId", o => o.chaseId)
    .forMember("pursuitType", o => o.pursuitType)
    .forMember("workflowStatusId", o => o.workflowStatusId);
};

export interface IPursuitItemOptions {
  chaseId?: number;
  pursuitType?: string;
  workflowStatusId?: number;
}

export class ChaseDetailInfoPursuitItemModel {
  chaseId: number;
  pursuitType: string;
  workflowStatusId: number;

  constructor(options: IPursuitItemOptions = {}) {
    this.chaseId = options.chaseId;
    this.pursuitType = StringHelper.clean(options.pursuitType);
    this.workflowStatusId = options.workflowStatusId;
  }
}
