import { IAutoMapper } from "../interfaces";

export const mapAdressesQueueItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "AddressesQueueListItem")
    .forMember("srNo", o => o.recordNumber)
    .forMember("address", o => o.address)
    .forMember("addressId", o => o.masterDocumentSourceID)
    .forMember("chases", o => o.chaseCount)
    .forMember("type", o => o.documentSourceTypeName)
    .forMember("status", o => o.status)
    .forMember("client", o => o.clientName)
    .forMember("project", o => o.project);

};

