export class RoleModel {

  readonly roleId: number;
  readonly roleName: string;
  readonly templateType: string;
  readonly groupName: string;
  readonly roleTypeName: string;
  selected: boolean;

  constructor(options: {

    roleId: number;
    roleName: string;
    templateType: string;
    groupName: string;
    roleTypeName: string;
    selected: boolean;


  }) {

    this.roleId = options.roleId;
    this.roleName = options.roleName;
    this.templateType = options.templateType;
    this.groupName = options.groupName;
    this.roleTypeName = options.roleTypeName;
    this.selected = options.selected;


  }
}
