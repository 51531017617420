import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Calendar } from "../../../../../../dynamic-forms/inputs/calendar/calendar.model";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { ObjectHelper } from "../../../../../../utilities/contracts/object-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { AccessInfo } from "../../../access-info.model";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailInfoAccessEdit } from "./address-detail-info-access-edit.model";
import { AddressDetailInfoAccessService } from "./address-detail-info-access-edit.service";

@Component({
    selector: "retrieval-address-detail-info-access-edit",
    templateUrl: "./address-detail-info-access-edit.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoAccessEditComponent implements OnInit, OnDestroy {
    @Input() isEditAccessVisible = false;
    @Input() retrievalType: number;
    @Output() formClose = new EventEmitter();

    private sink = new SubSink();
    addressDetailState: AddressDetailState;
    addressDetailInfoAccessEdit: AddressDetailInfoAccessEdit;

    formGroup: FormGroup;
    accessAdminContact: Textbox;
    accessAdminPhone: Textbox;
    accessITContact: Textbox;
    accessITPhone: Textbox;
    accessEmrSystem: Textbox;
    accessEditNotes: Textarea;
    credentialStatus: Dropdown;
    twoFactorAuthorization: Dropdown;
    expirationDate: Calendar;
    userName: Textbox;
    password: Textbox;
    url: Textbox;
    accessEmrSystemDropdown: Dropdown;
    emrSystemOptions = [
        new SelectableInput({ text: "Allscripts", value: "Allscripts"}),
        new SelectableInput({ text: "Athena", value: "Athena" }),
        new SelectableInput({ text: "Cerner", value: "Cerner" }),
        new SelectableInput({ text: "eClinical Works (eCW)", value: "eClinical Works (eCW)" }),
        new SelectableInput({ text: "Elation", value: "Elation" }),
        new SelectableInput({ text: "Epic", value: "Epic" }),
        new SelectableInput({ text: "Greenway/Intergy", value: "Greenway/Intergy" }),
        new SelectableInput({ text: "Medent", value: "Medent" }),
        new SelectableInput({ text: "Meditech", value: "Meditech" }),
        new SelectableInput({ text: "NextGen", value: "NextGen" }),
        new SelectableInput({ text: "Office Ally", value: "Office Ally" }),
        new SelectableInput({ text: "Practice Fusion", value: "Practice Fusion" }),
        new SelectableInput({ text: "Other", value: "Other" }),
      ];

    get isEmr(): boolean {
      return this.retrievalType === DocumentSourceType.EMR;
    }

    get isPSR(): boolean {
        return this.retrievalType === DocumentSourceType.PSR;
    }

    get isFieldTech(): boolean {
      return this.retrievalType === DocumentSourceType.FIELDTECH;
    }

    get showEmrSystem(): boolean {

        return (!this.isEmr && !this.isPSR && !this.isFieldTech) || this.formGroup.get("accessEmrSystemDropdown").value === "Other";
    }

    get getEmrDropdownValue(): string {

        return this.showEmrSystem ? this.formGroup.get("accessEmrSystem").value : this.formGroup.get("accessEmrSystemDropdown").value;
    }

    get getEmrSystemValue(): string {

        return ObjectHelper.isEmpty(this.emrSystemOptions.find(x => x.text.trim().toLowerCase() === this.addressDetailState.accessInfo.accessEmrSystem.trim().toLowerCase()))
        ? "Other" : this.addressDetailState.accessInfo.accessEmrSystem;
    }

    constructor(
        private readonly addressDetailStateService: AddressDetailStateService,
        private addressDetailInfoAccessService: AddressDetailInfoAccessService,
        private readonly formService: FormService,
        private messagingService: MessagingService,
        private changeDetector: ChangeDetectorRef,
        private addressTimelineStateService: AddressTimelineStateService,
        private addressTimelineService: AddressTimelineService,
        private readonly automapper: AutomapperService
    ) { }

    ngOnInit() {
      this.initializeForm();
      this.emrCredentialStatuses();

      this.sink.add(
            this.addressDetailStateService.state.subscribe(state => {
                this.addressDetailState = state;

                if (this.addressDetailState.hasMasterDocumentSourceId) {
                    this.loadForm();
                }

                this.changeDetector.markForCheck();
            })
        );
    }

    ngOnDestroy() {
        this.sink.unsubscribe();
    }

    visibleChange($event) {
        this.isEditAccessVisible = $event;
        this.formClose.emit($event);
    }

    onUpdateAccessInfo(): void {
        this.getFormValues();
        this.addressDetailInfoAccessService.updateAccessInfo(this.addressDetailInfoAccessEdit, this.addressDetailState.masterDocumentSourceId)
            .subscribe((data: number) => {
                if (data > 0) {
                    this.fetchTimelineItmes();
                    this.addressDetailStateService.setData({
                        accessInfo: new AccessInfo({
                            accessAdminContact: this.addressDetailInfoAccessEdit.adminContactName,
                            accessAdminPhone: this.addressDetailInfoAccessEdit.adminContactPhone,
                            accessITContact: this.addressDetailInfoAccessEdit.itContactName,
                            accessITPhone: this.addressDetailInfoAccessEdit.itContactPhone,
                            accessEmrSystem: this.addressDetailInfoAccessEdit.emrSystem,
                            accessEditNotes: this.addressDetailInfoAccessEdit.editNotes,
                            url: this.addressDetailInfoAccessEdit.url,
                            credentialStatus: this.addressDetailInfoAccessEdit.credentialStatus,
                            twoFactorAuthorization: this.addressDetailInfoAccessEdit.twoFactorAuthorization,
                            userName: this.addressDetailInfoAccessEdit.userName,
                            password: this.addressDetailInfoAccessEdit.password,
                            expirationDate: this.addressDetailInfoAccessEdit.expirationDate.toString(),
                        }),
                    });

                    this.messagingService.showToast("Access Information successfully edited.", SeverityType.SUCCESS);
                } else {
                    this.messagingService.showToast("Failed to edit Access Information.", SeverityType.ERROR);
                }
            });

        this.visibleChange(false);
    }

    cancelAccessInfo() {
        this.isEditAccessVisible = false;
    }

    private initializeForm(): void {
        this.accessAdminContact = new Textbox({
            key: "accessAdminContact",
            label: "Admin Contact",
            placeholder: "admin contact name",
        });

        this.accessAdminPhone = new Textbox({
            key: "accessAdminPhone",
            label: "Admin Phone",
            placeholder: "XXX-XXX-XXXX",
            validators: [Validators.maxLength(18), Validators.pattern(RegExHelper.phoneNumber)],
            errorMessages: {
                maxlength: "Phone number cannot be more than 18 characters.",
                pattern: "Phone number should be in right format.",
            },
        });

        this.accessITContact = new Textbox({
            key: "accessITContact",
            label: "IT Contact",
            placeholder: "IT contact",
        });

        this.accessITPhone = new Textbox({
            key: "accessITPhone",
            label: "IT Phone",
            placeholder: "XXX-XXX-XXXX",
            validators: [Validators.maxLength(18), Validators.pattern(RegExHelper.phoneNumber)],
            errorMessages: {
                maxlength: "Phone number cannot be more than 18 characters.",
                pattern: "Phone number should be in right format.",
            },
        });

        this.accessEmrSystem = new Textbox({
            key: "accessEmrSystem",
            label: "EMR System",
            placeholder: "emr system",
        });

        this.accessEmrSystemDropdown = new Dropdown({
            key: "accessEmrSystemDropdown",
            label: "EMR System",
            placeholder: "emr system",
            options: this.emrSystemOptions,
        });

        this.credentialStatus = new Dropdown({
            key: "credentialStatus",
            label: "Credential Status",
            placeholder: "Credential Status",

        });

        this.twoFactorAuthorization = new Dropdown({
            key: "twoFA",
            label: "2FA",
            placeholder: "2FA",
            options: [
              new SelectableInput({ text: "Yes", value: "Yes"}),
              new SelectableInput({ text: "No", value: "No" }),
            ],
        });

        this.expirationDate = new Calendar({
            key: "expirationDate",
            label: "Expiration Date",
            placeholder: "Expiration Date",
        });

        this.userName = new Textbox({
            key: "username",
            label: "Username",
            placeholder: "Username",
        });

        this.password = new Textbox({
            key: "password",
            label: "Password",
            placeholder: "Password",
        });

        this.url = new Textbox({
            key: "url",
            label: "url",
            placeholder: "URL",
        });

        this.accessEditNotes = new Textarea({
            key: "accessEditNotes",
            label: "Notes",
            placeholder: "Long form text...",
            rows: 4,
            resize: Resize.VERTICAL,
            validators: [
                Validators.minLength(4),
                Validators.maxLength(1000),
            ],
            errorMessages: {
                minlength: "Write a note between 4 - 1000 characters.",
                maxlength: "Write a note between 4 - 1000 characters.",
            },
        });

        this.formGroup = this.formService.createFormGroup([
            this.accessAdminContact, this.accessAdminPhone,
            this.accessITContact, this.accessITPhone,
            this.accessEmrSystem, this.credentialStatus,
            this.twoFactorAuthorization, this.expirationDate,
            this.userName, this.password,
            this.url, this.accessEditNotes,
            this.accessEmrSystemDropdown]);
  }

    get isValidForm(): boolean {
    return this.formGroup.invalid;
    }

    private loadForm(): void {
        this.formGroup.patchValue({
            accessAdminContact: this.addressDetailState.accessInfo.accessAdminContact,
            accessAdminPhone: this.addressDetailState.accessInfo.accessAdminPhone,
            accessITContact: this.addressDetailState.accessInfo.accessITContact,
            accessITPhone: this.addressDetailState.accessInfo.accessITPhone,
            accessEmrSystem: this.addressDetailState.accessInfo.accessEmrSystem,
            accessEmrSystemDropdown: this.getEmrSystemValue,
            credentialStatus: this.addressDetailState.accessInfo.credentialStatus,
            twoFA: this.addressDetailState.accessInfo.twoFactorAuthorization,
            expirationDate: this.addressDetailState.accessInfo.expirationDate,
            username: this.addressDetailState.accessInfo.userName,
            password: this.addressDetailState.accessInfo.password,
            url: this.addressDetailState.accessInfo.url,
            accessEditNotes: this.addressDetailState.accessInfo.accessEditNotes,
        });

        this.changeDetector.markForCheck();
    }

    private getFormValues() {

        this.addressDetailInfoAccessEdit = new AddressDetailInfoAccessEdit();

        this.addressDetailInfoAccessEdit.adminContactName = this.formGroup.get("accessAdminContact").value;
        this.addressDetailInfoAccessEdit.adminContactPhone = this.formGroup.get("accessAdminPhone").value;
        this.addressDetailInfoAccessEdit.itContactName = this.formGroup.get("accessITContact").value;
        this.addressDetailInfoAccessEdit.itContactPhone = this.formGroup.get("accessITPhone").value;
        this.addressDetailInfoAccessEdit.emrSystem = this.getEmrDropdownValue;
        this.addressDetailInfoAccessEdit.credentialStatus = this.formGroup.get("credentialStatus").value;
        this.addressDetailInfoAccessEdit.twoFactorAuthorization = this.formGroup.get("twoFA").value;
        this.addressDetailInfoAccessEdit.expirationDate = DateHelper.format(this.formGroup.get("expirationDate").value);
        this.addressDetailInfoAccessEdit.userName = this.formGroup.get("username").value;
        this.addressDetailInfoAccessEdit.password = this.formGroup.get("password").value;
        this.addressDetailInfoAccessEdit.url = this.formGroup.get("url").value;
        this.addressDetailInfoAccessEdit.editNotes = this.formGroup.get("accessEditNotes").value;
        this.changeDetector.markForCheck();
    }

    private fetchTimelineItmes(): void {
        this.addressTimelineService
            .get(this.addressDetailState.masterDocumentSourceId)
            .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
    }

    private emrCredentialStatuses(): void {
      this.addressDetailInfoAccessService.getEmrCredentialStatuses()
        .pipe(map(this.automapper.curryMany("default", "SelectableInput")))
        .subscribe(options => {
          this.credentialStatus = new Dropdown({ ...this.credentialStatus, options } as any);
          this.changeDetector.markForCheck();
        });
    }
}
