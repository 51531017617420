<div class="thumbnail__total-pages"></div>
<cdk-virtual-scroll-viewport #thumbnailViewport class="thumbnail__viewport" [ngClass]="{ 'max': isMaximized }"
  [itemSize]=itemSize [minBufferPx]="itemSize * 3" [maxBufferPx]="itemSize * 5"
  (scrolledIndexChange)="updateIndex($event)">
  <div class="thumbnail__item" *cdkVirtualFor="let thumbnail of allThumbnails; trackBy: trackByFn;"
    (click)="updateCurrentPage(thumbnail.pageNumber);">
    <div *ngIf="hasPageAndSource(thumbnail) else nonDocumentThumbnail;" class="thumbnail__item--image"
      [ngClass]="{'thumbnail__item--overlay': isThumbnailSelected(thumbnail.pageNumber) }">
      <div *ngIf="isDocumentThumbnail(thumbnail); else nonDocumentThumbnail;">
        <ng-container *ngIf="hasThumbnailImageUrl(thumbnail); else placeholderThumbnail">
          <app-badge [isLarge]="false" *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
          <img [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail.pageNumber) }"
            class="thumbnail__image"
            [src]="getThumbnailSource(thumbnail)" alt="Document Preview Not Available" />
          <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__item--label" id="thumbnail{{thumbnail.pageNumber}}">{{thumbnail.pageNumber}}</div>
        </ng-container>
        <ng-template #placeholderThumbnail>
          <app-badge [isLarge]="false" *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
          <div [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail.pageNumber) }"
            class="thumbnail__image thumbnail__placeholder">
            <div class="thumbnail__placeholder--text">Document
              Preview
              Not Available</div>
          </div>
          <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__item--label thumbnail__placeholder--label" id="thumbnail{{thumbnail.pageNumber}}">
            {{thumbnail.pageNumber}}</div>
        </ng-template>
        <div class="overlay"></div>
      </div>
      <ng-template #nonDocumentThumbnail>
        <div class="thumbnail__item--image">
          <div
            class="thumbnail__image thumbnail__noThumbnail thumbnail__spine--{{thumbnail.documentThumbnail.thumbnailSpineColor}} thumbnail__background--{{thumbnail.documentThumbnail.thumbnailBackgroundColor}}">
            <div
              class="thumbnail__noThumbnail--label thumbnail__noThumbnail--label--{{thumbnail.documentThumbnail.thumbnailSpineColor}}">
              <app-icon iconName="file-medical" iconStyle="fal"></app-icon>
            </div>
          </div>
          <span class="thumbnail__item--label thumbnail__coversheet--label">Document
            {{thumbnail.documentQueueId}}</span>
        </div>
      </ng-template>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
<div class="thumbnail__singleExpand">
  <div class="thumbnail__toggle thumbnail__toggle--selected">
    <div class="icon icon--collapse">
      <app-icon iconStyle="far" iconName="square"></app-icon>
    </div>
  </div>
  <div (click)="toggleThumbnailView('expand');" class="thumbnail__toggle">
    <div class="icon">
      <app-icon iconStyle="far" iconName="th"></app-icon>
    </div>
  </div>
</div>