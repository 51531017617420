import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { CatalyticNumerator } from "./catalytic-numerator.model";

export const mapCatalyticResult = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CatalyticResult")
    .forMember("chaseId", o => o.chaseId)
    .forMember("chaseNlpDataId", o => o.chaseNlpDataId)
    .forMember("totalMatch", o => o.totalMatch)
    .forMember("totalNoMatch", o => o.totalNoMatch)
    .forMember("totalPartialMatch", o => o.totalPartialMatch)
    .forMember("notes", o => o.notes)
    .forMember("reviewedByUser", o => o.reviewedByUser)
    .forMember("systemResultsReviewed", o => o.systemResultsReviewed)
    .forMember("numerators", o => {
      const isArray = Array.isArray(o.numerators) && o.numerators.length > 0;
      return isArray ? automapper.mapMany("default", "CatalyticNumerator", o.numerators) : [];
    });
};

export class CatalyticResult {
  chaseId?: number;
  chaseNlpDataId?: number;
  totalMatch?: number;
  totalNoMatch?: number;
  totalPartialMatch?: number;
  notes?: string;
  reviewedByUser?: boolean;
  systemResultsReviewed?: boolean;
  numerators?: CatalyticNumerator[];

  constructor(options: Partial<CatalyticResult> = {}) {
    this.chaseId = options.chaseId;
    this.chaseNlpDataId = options.chaseNlpDataId;
    this.totalMatch = options.totalMatch;
    this.totalNoMatch = options.totalNoMatch;
    this.totalPartialMatch = options.totalPartialMatch;
    this.notes = StringHelper.clean(options.notes);
    this.reviewedByUser = BooleanHelper.tryGet(options.reviewedByUser, false);
    this.systemResultsReviewed = BooleanHelper.tryGet(options.systemResultsReviewed, false);
    this.numerators = ArrayHelper.clean(options.numerators);
  }
}
