import { ChangeDetectionStrategy, Component } from "@angular/core";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  template: `
    <dev-controller *ngIf="!isProduction"></dev-controller>
    <app-toast key="app"></app-toast>
    <app-loader></app-loader>
    <app-messages key="appMessage"></app-messages>
    <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  get isProduction(): boolean {
    return environment.production;
  }
}
