import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActionButtonComponent } from "./action-button/action-button.component";
import { DevControllerComponent } from "./dev-controller/dev-controller.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DevControllerComponent,
    ActionButtonComponent,
  ],
  exports: [
    DevControllerComponent,
  ],
})
export class DevControlsModule { }
