import { Pipe, PipeTransform } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Pipe({
  name: "phoneNumber",
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string | number) {
    const phoneNumberString = (value || "").toString();
    if (!StringHelper.isAvailable(phoneNumberString)) {
      return "";
    }
    const formattingCharacters = ["(", ")", "-"];
    const isPhoneFormatted = formattingCharacters.some(r => phoneNumberString.indexOf(r) >= 0);

    if (!isPhoneFormatted) {
      const areaCode = phoneNumberString.length === 10 ? phoneNumberString.substring(0, 3) : "";
      const firstThree = phoneNumberString.length === 10 ? phoneNumberString.substring(3, 6) : phoneNumberString.substring(0, 3);
      const lastFour = phoneNumberString.length === 10 ? phoneNumberString.substring(6) : phoneNumberString.substring(3);
      const result = `(${areaCode}) ${firstThree}-${lastFour}`;
      return result;
    } else {
      return phoneNumberString;
    }
  }
}
