import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { distinctUntilChanged, map, pairwise } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ProjectType } from "../../project/project-type.enum";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { AnalyticsService } from "../analytics.service";
import { AnalyticsItemRequest } from "../models/analytics-item-request.model";
import { AnalyticsItem } from "../models/analytics-item.model";
import { DashboardCount } from "../models/dashboard-count.model";

@Component({
  selector: "app-analytics-report-landing",
  templateUrl: "./analytics-report-landing.component.html",
  styleUrls: ["./analytics-report-landing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsReportLandingComponent implements OnInit {
  analyticsTypeList: DashboardCount[];
  selectedAnalyticsType: string;
  selectedCategoryName: string;
  reportingList: AnalyticsItem[];
  searchTerm = "";
  reportingCount: number;
  showSearchIcon = true;
  searchPhraseForm: FormGroup;
  searchPhraseInput = new Textbox({ key: "SearchPhrase" });
  analyticsCategoryName: string;
  analyticsType: string;
  concatenatedValueForCategory: string;
  isExtractCategory: boolean;
  isFiltersVisible = false;
  hasSearchResult = true;
  projectsInput: CheckboxGroup;
  form: FormGroup;
  lookId: number;
  reportName: string;

  constructor(
    private readonly router: Router,
    private analyticsService: AnalyticsService,
    private readonly automapper: AutomapperService,
    private readonly retrievalPageService: RetrievalPageService,
    private changeDetector: ChangeDetectorRef,
    private formService: FormService,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getProjects();
    this.searchPhraseInput = new Textbox({
      key: "SearchPhrase",
      placeholder: "Search",
    });
    this.searchPhraseForm = this.formService.createFormGroup([this.searchPhraseInput]);
    this.loadMenuCategoryList();
  }

  private createForm(): void {
    this.projectsInput =
      new CheckboxGroup({
        key: "Projects",
        label: "Projects",
      });

    this.form = this.formService.createFormGroup([this.projectsInput]);
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList(null, this.projectType)
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);

        this.formService.updateDom.next();
      });
  }

  get headerTitle(): string {
    return this.router.url.substring(1).split("/")[1];
  }

  get projectType(): ProjectType {
    switch (this.headerTitle) {
      case "risk":
        return ProjectType.RISK;

      case "hedis":
        return ProjectType.HEDIS;

      case "iva":
        return ProjectType.IVA;

      default:
        break;
    }
  }

  get isUpdateEnabled(): boolean {
    const projects = this.form.get(this.projectsInput.key).value;
    return projects != null && ArrayHelper.isAvailable(projects);
  }

  loadMenuCategoryList(): void {
    this.analyticsService
      .getDashboardCount(this.projectType)
      .subscribe(result => {
        if (!ArrayHelper.isAvailable(result)) {
          return;
        }

        this.analyticsTypeList = result as DashboardCount[];
        this.analyticsType = this.analyticsTypeList[0].analyticsType;
        if (StringHelper.isAvailable(this.analyticsType)) {
          this.loadReportingList(this.analyticsType, "");
        }
        this.changeDetector.markForCheck();
      });
  }

  setSelectedAnalyticsType(analyticsType: string): void {
    this.reset();
    this.concatenatedValueForCategory = "";
    this.selectedAnalyticsType = analyticsType;
    this.loadReportingList(this.selectedAnalyticsType, "");
  }

  setSelectedCategory(category: any, analyticsType: string): void {
    this.reset();
    this.selectedAnalyticsType = "";
    this.selectedCategoryName = category;
    this.analyticsCategoryName = category.categoryName;
    this.concatenatedValueForCategory = `${analyticsType}-${this.analyticsCategoryName}`;
    this.loadReportingList(analyticsType, this.analyticsCategoryName);
  }

  get selectedTab(): string {
    return StringHelper.isAvailable(this.selectedAnalyticsType) ? this.selectedAnalyticsType : "Dashboards";
  }

  selectedCategory(analyticsTypeValue: string, isFirstElement: boolean, selectedCategoryValue: string): string {
    let className = "";
    let concateAnalyticsTypeAndCategory = "";
    if (StringHelper.isAvailable(selectedCategoryValue) != null) {
      concateAnalyticsTypeAndCategory = `${analyticsTypeValue}-${selectedCategoryValue}`;
    }

    if (isFirstElement && this.selectedTab === "Dashboards") {
      className = this.selectedAnalyticsType === "" ? "" : "analytics-category-first-menu-element";
    }

    if (selectedCategoryValue === null && this.selectedAnalyticsType !== "") {
      if (this.selectedAnalyticsType === analyticsTypeValue) {
        className = "analytics-category-first-element";
      }
    } else {
      if (this.concatenatedValueForCategory === concateAnalyticsTypeAndCategory) {
        className = "category li selected";
      }
    }

    return className;
  }

  loadReportingList(analyticsType: string, categoryName: string): void {
    const analyticsItemRequest = new AnalyticsItemRequest({
      analyticsType,
      projectType: this.projectType,
      analyticsCategory: categoryName,
    });
    this.analyticsService
      .getReportingList(analyticsItemRequest)
      .subscribe(data => {
        this.reportingList = data as AnalyticsItem[];
        this.changeDetector.markForCheck();
      });
  }

  getClassForDashboardIcon(analyticsCategory: string, analyticsType: string): string {
    let iconClass = "report_item";
    if (analyticsType === "Dashboards") {
      switch (analyticsCategory) {

        case "Project Status":
          return iconClass += " dashboard-icon-project-status";
        case "Retrieval":
          return iconClass += " dashboard-icon-retrieval";
        case "Data Extracts":
          return iconClass += " dashboard-icon-data-extracts";
        case "Clinical":
          return iconClass += " dashboard-icon-clinical";
        case "Pends":
          return iconClass += " dashboard-icon-pends";
        case "EVE":
          return iconClass += " dashboard-icon-nlp";
        case "Risk Suspecting":
          return iconClass += " dashboard-icon-risk-suspecting";
        case "Billing":
          return iconClass += " dashboard-icon-billing";
        default:
          return "";
      }
    }
    if (analyticsType === "Reports") {
      switch (analyticsCategory) {

        case "Project Status":
          return iconClass += " report-icon-project-status";
        case "Retrieval":
          return iconClass += " report-icon-retrieval";
        case "Data Extracts":
          return iconClass += " report-icon-data-extracts";
        case "Clinical":
          return iconClass += " report-icon-clinical";
        case "Pends":
          return iconClass += " report-icon-pends";
        case "EVE":
          return iconClass += " report-icon-nlp";
        case "Risk Suspecting":
          return iconClass += " report-icon-risk-suspecting";
        case "Billing":
          return iconClass += " report-icon-billing";
        default:
          return "";
      }
    }

    return iconClass;
  }

  getReportIcon(analyticsCategory: string): string {
    this.isExtractCategory = analyticsCategory === "Data Extracts";
    switch (analyticsCategory) {
      case "Project Status":
        return "far fa-chart-line";

      case "Retrieval":
        return "far fa-arrow-from-left";

      case "Clinical":
        return "far fa-files-medical";

      case "Data Extracts":
        return "far fa-arrow-alt-to-bottom";

      case "EVE":
        return "fab fa-digital-ocean";

      case "Pends":
        return "far fa-exclamation-triangle";

      case "Risk Suspecting":
        return "far fa-bullseye-arrow";

      case "Billing":
        return "far fa-dollar-sign";
      default:
        return "";

    }
  }

  fetchTextMatches(): void {
    const reportName = StringHelper.isAvailable(this.searchPhraseForm.get(this.searchPhraseInput.key).value)
      ? this.searchPhraseForm.get(this.searchPhraseInput.key).value : null;
    this.analyticsService
      .searchReport(reportName, this.projectType)
      .subscribe(data => {
        this.reportingList = data as AnalyticsItem[];
        this.reportingCount = data.length;
        this.showSearchIcon = false;
        this.removeActiveClass();
        this.changeDetector.markForCheck();
      });
  }

  trackByFn(index, item) {
    return index;
  }

  resetSearchInput(): void {
    this.removeActiveClass();
    this.searchPhraseForm.get(this.searchPhraseInput.key).reset();
    this.showSearchIcon = true;
    this.hasSearchResult = true;
    this.selectedAnalyticsType = (this.projectType === ProjectType.IVA) ? "Reports" : "Dashboards";
    this.loadReportingList(this.selectedAnalyticsType, this.analyticsCategoryName);
  }

  private removeActiveClass(): void {
    this.selectedAnalyticsType = "";
    this.concatenatedValueForCategory = "";
  }

  navigateToUrl(report: AnalyticsItem) {
    if (StringHelper.isAvailable(report.url)) {
      window.open(report.url, "_blank");
    } else if (report.analyticsSource.toLowerCase() === "looker" && report.analyticsOutput.toLowerCase() === "download") {
      this.lookId = report.lookerIframeId;
      this.reportName = report.name;
      this.isFiltersVisible = true;
    }
  }

  onUpdate(): void {
    const projectIds = this.analyticsService.getValueForAnalyticsRequestAsCsv(this.form.get(this.projectsInput.key).value, true);
    this.analyticsService.downLoadReport(this.lookId, projectIds)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Your request is being processed and you will be alerted when the file is ready for download", SeverityType.SUCCESS);
        } else {
          this.messagingService.showMessage("Your report download request has failed.", SeverityType.ERROR);
        }
      }
    );

    this.isFiltersVisible = false;

  }

  private reset(): void {
    this.searchPhraseForm.get(this.searchPhraseInput.key).reset();
    this.showSearchIcon = true;
    this.hasSearchResult = true;
  }

  onInputSearchText(): void {
    this.searchPhraseForm.get(this.searchPhraseInput.key).valueChanges.pipe(
      distinctUntilChanged(),
      pairwise()
    ).subscribe(([oldSearchValue, newSearchValue]) => {
      if (oldSearchValue !== newSearchValue) {
        this.showSearchIcon = true;
        this.hasSearchResult = false;
      }
    });
  }

  autoHeightClass(value, type) {
    return (NumberHelper.isGreaterThan(value.length, 90) || type === "Data Extracts") ? "report-item-container" : "report-item-container__content";
  }

  getClass(categoryValue: string): string {
    let categoryClass = "category__label";
    switch (categoryValue) {

      case "Retrieval":
        return categoryClass += " --retrieval";

      case "Data Extracts":
        return categoryClass += " --data-extracts";

      case "Project Status":
        return categoryClass += " --project-status";

      case "Clinical":
        return categoryClass += " --clinical";

      case "Pends":
        return categoryClass += " --pends";

      case "EVE":
        return categoryClass += " --nlp";

      case "Risk Suspecting":
        return categoryClass += " --risk-suspecting";

      case "Billing":
        return categoryClass += " --billing";

      default:
        return "";
    }
  }

  getIconClass(categoryValue: string): string {
    switch (categoryValue) {

      case "Project Status":
        return "far fa-chart-line";

      case "Retrieval":
        return "far fa-arrow-from-left";

      case "Clinical":
        return "far fa-files-medical";

      case "Data Extracts":
        return "far fa-arrow-alt-to-bottom";

      case "EVE":
        return "fab fa-digital-ocean";

      case "Pends":
        return "far fa-exclamation-triangle";

      default:
        return "";
    }
  }

  getClassForReportCategory(analyticsType: string): string {
    const styleClass = "category__label";
    return analyticsType === "Reports" ? "--report" : styleClass;
  }

  isCategoryExtract(report: AnalyticsItem): boolean {
    return ((this.selectedAnalyticsType === "Reports" || this.analyticsCategoryName === "Data Extracts"
      || this.analyticsType === "Reports") && this.isExtractCategory && (!StringHelper.isAvailable(report?.url)));
  }

}
