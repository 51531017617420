import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { MessagingService } from "../core/messaging/messaging.service";
import { SeverityType } from "../core/messaging/severity-type.enum";
import { UserModel } from "../core/user/user-model";
import { UserService } from "../core/user/user.service";
import { FormService } from "../dynamic-forms/form.service";
import { TextboxType } from "../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../dynamic-forms/inputs/textbox/textbox.model";
import { RegExHelper } from "../utilities/reg-Ex-Helper";

@Component({
    selector: "app-onboarding",
    templateUrl: "./onboarding.component.html",
    styleUrls: ["./onboarding.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit {

    userModel: UserModel;
    readonly form: FormGroup;
    readonly passwordInputs: any[] = [];
    isValidationFailed = false;

    constructor(
        private readonly formService: FormService,
        private service: UserService,
        private changeDetector: ChangeDetectorRef,
        private router: Router,
        private authService: AuthService,
        private messagingService: MessagingService
    ) {

        this.passwordInputs = [
            new Textbox({
                key: "password",
                label: "Enter New Password",
                placeholder: "New Password",
                type: TextboxType.PASSWORD,
                validators: [Validators.required],
                errorMessages: {
                    required: "Password is required.",

                },
            }),

            new Textbox({
                key: "repeatPassword",
                label: "Repeat Password",
                placeholder: "Confirm New Password",
                type: TextboxType.PASSWORD,
            }),
        ];

        this.form = this.formService.createFormGroup([...this.passwordInputs]);
    }

    ngOnInit() {
        this.service
            .getUser(this.authService.userId)
            .subscribe(this.assignAndNotify);
    }

    private assignAndNotify = (data: UserModel): void => {
        this.userModel = new UserModel(data);
    }


    onUpdate() {
        this.isValidationFailed = false;
        const password = this.form.get("password").value;

        if (password !== this.form.get("repeatPassword").value) {
            this.messagingService.showMessage("Passwords do not match. Please try again.", SeverityType.ERROR, "onboardingMessage");
            return;
        }

        if (RegExHelper.passwordPattern.test(password)) {
            this.userModel.loginPassword = btoa(password);

            this.service.updateUserOnboarding(this.userModel)
              .subscribe(() => {
                this.router.navigateByUrl(this.authService.defaultUserLandingUrl);
            });

        } else {
            this.messagingService.showMessage("Password does not meet the following criteria:", SeverityType.ERROR, "onboardingMessage");
            this.isValidationFailed = true;
        }
    }

    trackByIndex(index, item) {
        return index;
    }

}
