import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, interval, timer } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../auth/auth.service";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../../../core/navigation/parameter.service";
import { UserService } from "../../../../../../../core/user/user.service";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Checkbox } from "../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseIdComponent } from "../../../../../../../shared/chase-grid/chase-id.component";
import { RetrieverUpdateModel } from "../../../../../../../shared/chase-grid/update-retriever-modal/update-retriever-model.model";
import { GridCommentsComponent } from "../../../../../../../shared/comments/grid-comments/grid-comments.component";
import { OpenGapsTemplateComponent } from "../../../../../../../shared/gap-grid/open-gaps-template/open-gaps-template.component";
import { BulkAction } from "../../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridView } from "../../../../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../../../../../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../../../../../../../shared/grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../../../../../../../shared/grid/grid-pipe.enum";
import { GridStateService } from "../../../../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../../../../shared/grid/server-grid/server-grid.component";
import { CreatePendService } from "../../../../../../../shared/pend/create-pend.service";
import { PendStatus } from "../../../../../../../shared/pend/pend-status.enum";
import { TagType } from "../../../../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { DateFormats } from "../../../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { ChaseItem } from "../../../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { GapSearchRequest } from "../../../../../../api/gap/gap-search-request-model";
import { GapService } from "../../../../../../api/gap/gap-service";
import { NumeratorListItem } from "../../../../../../api/numerator/numerator-list-item.model";
import { ProviderService } from "../../../../../../api/provider/provider.service";
import { WorkflowStatusDb } from "../../../../../../api/workflow/workflow-status-db.enum";
import { ClinicalPageService } from "../../../../../clinical/clinical-page/clinical-page.service";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { ProviderPacketItem } from "../../../../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ADDRESS_CHASE_GRID, CHASE_UPLOAD_GRID } from "../../../../../member/chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../../../../member/chase-detail/chase-detail-chart/chart.service";
import { ChaseMoveModel } from "../../../../../project/chase-query/chase-move.model";
import { ChaseQueryService } from "../../../../../project/chase-query/chase-query.service";
import { RouteFilters } from "../../../../../project/chase-query/route-filters.model";
import { AddressSearchResult } from "../../../../address-search/address-search-result.model";
import { CreateAddress } from "../../../../address-search/create-address.model";
import { AddressesQueueService } from "../../../../addresses-queue/addresses-queue.service";
import { CommitmentDateService } from "../../../../commitment-date/commitment-date.service";
import { CommitmentDateUpdateModel } from "../../../../commitment-date/commitmentDateUpdate.model";
import { ContactRequest } from "../../../../cover-letter-template/contact-request.model";
import { EmailRequest } from "../../../../cover-letter-template/email-request.model";
import { FaxRequest } from "../../../../cover-letter-template/fax-request.model";
import { ExpectedDueDate } from "../../../../expected-due-date/expected-due-date.model";
import { DocumentSourceType } from "../../../../retrieval-document-source-type.enum";
import { RetrievalPageService } from "../../../../retrieval-page/retrieval-page.service";
import { AddressDetailState } from "../../../address-detail-state.model";
import { AddressDetailStateService } from "../../../address-detail-state.service";
import { AddressDetailService } from "../../../address-detail.service";

@Component({
  selector: "retrieval-address-detail-info-grids-chases",
  templateUrl: "./address-detail-info-grids-chases.component.html",
  styleUrls: ["./address-detail-info-grids-chases.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsChasesComponent implements OnInit, OnDestroy {
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @ViewChild("openGapsColumn", { static: true }) openGapsColumn: TemplateRef<OpenGapsTemplateComponent>;
  @ViewChild("commentsColumn", { static: true }) commentsColumn: TemplateRef<GridCommentsComponent>;
  @ViewChild("chaseForm") chaseForm;
  @Input() routeParameters: RouteFilters;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseID";
  @Input() url = `${this.baseApiUrl}chase/list`;
  @Input() sortDirection = 1; // -1 or 1
  @Input() sortField = "";
  @Input() showViews = false;
  @Input() viewAttributeId = 0;
  @Input() saveQueryAttributeId = 0;
  filterHeaderText = "Chase Query";
  views: GridViewsState;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  refreshViews = new EventEmitter<GridView>(true);
  addressId: number;
  private user: UserToken;
  isCreatePendView = false;
  isEditPendView = false;
  isRetrieverModalVisible = false;
  isLandingFilterApplied = false;
  @Output() formClose = new EventEmitter();
  private pageSize = 25;
  chaseIdList: number[] = [];
  chasePendIds: number[] = [];
  pendIds: number[] = [];
  chasePendId: number;
  assignedTo: number;
  private documentRetrievalLead = 4;
  isClinical: boolean;
  contactRequest: ContactRequest;
  chaseGridConfiguration = new GridConfiguration();
  chaseGridData: any[] = [];
  chaseGridSelection: any[];
  chaseGridDataAll = [];
  startRecord: number;
  endRecord: number;
  gridSearchRequest: any;
  rowData: any;
  providerPacketItem: ProviderPacketItem;
  isAddressModalVisible = false;
  isConfirmModalVisible = false;
  isMoveVisible = false;
  assignedColumnAdded = false;
  retrievalLocationId: Textbox;
  selectedAddress: AddressSearchResult;
  confirmStatusMessage: any;
  chaseMoveModel: any;
  assignedUser: string;
  projectId: number;
  commitDateUpdateModel: CommitmentDateUpdateModel;
  retrieverUpdateModel: RetrieverUpdateModel;
  expectedDueDateUpdateModel: ExpectedDueDate;
  isCommitmentDateModalVisible = false;
  isExpectedDueDateModalVisible = false;
  selectedChases: any[];
  isPendModalVisible = false;
  @Input() clinical = false;
  nonActionableChasesChecked: Checkbox;
  hideOpenPendsChecked: Checkbox;
  moveChaseText = "Request Move";
  isTemplateModalVisible = false;
  isAssignModalVisible = false;
  userName: string;
  phoneNumber: string;
  userEmail: string;
  private sink = new SubSink();
  addressSearchCallingSource = "Move Chase";
  reportingStatusName = "reportingStatusName";
  private previousMasterDocumentSourceId = 0;
  commitmentDate: Date;
  expectedDueDate: Date;
  form: FormGroup;
  request: GridRequest;
  isPrintProviderPacketModalVisible = false;
  addressDetailState: AddressDetailState;
  isChasePromptVisible = false;
  isOutreachModalVisible = false;
  gapChasesAndPursuits: any[];
  gapDataLoadInititated = false;
  gapDataFetched = false;
  tagType = TagType.CHASE;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  allAvailableTags: SelectableInput[];
  selectedObjectId: number;
  isAdditionalOutreachPromptVisible = false;
  isThirdParty = false;
  commitmentActionLabel: string;
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  actions: BulkAction[];
  threeDotActions: BulkAction[];
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  primeGridData: any[] = [];
  tagSearchOperatorFilterValue = "OR";
  isAdditionColumnAdded = false;
  chaseIds: any[] = [];
  isSOCoRetrievalOwner = false;
  chaseGridFilterForm: FormGroup;
  isReloadFilterInput = false;
  coRetrievalOrganizationName: string;
  isChaseBasedOnCoRetrievalOrganizationName = false;
  private readonly PREFIX = "GRID_";
  gridViewLoadCount = 0;
  userInfo: any;
  isUserInfoPassed = true;
  isContactInfoModalVisible = false;
  newAddressModel: CreateAddress;
  isNewAddress: boolean;
  showPrimaryContactNotSetModal = false;
  showNoGapsModal = false;
  searchTextChanged = new Subject<string>();
  selectedChaseIdsForAssignment: number[];
  hideOpenPendValue = "0";
  showAllChaseValue = "0";
  filter = {};
  chaseList: ChaseItem[];
  @Input() showSaveQuery = false;
  @Output() isSavedQueryDeleted = new EventEmitter<boolean>();
  @Output() isSavedQueryUpdated = new EventEmitter<number>();


  get totalChases(): number {
    return ArrayHelper.isAvailable(this.serverGridComponent.data) ? this.serverGridComponent.data[0].chaseCount : 0;
  }

  get workFlowStatusInput(): Textbox {
    return this.request.getInput("WorkFlowStatus");
  }
  set workFlowStatusInput(value: Textbox) {
    this.request.setInput("WorkFlowStatus", value);
  }

  get showCoRetrievalOwnerFilter(): boolean {
    return this.isSOCoRetrievalOwner;
  }

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseID");
  }

  get documentSourceIdInput(): Textbox {
    return this.getInput("DocumentSourceId");
  }
  set documentSourceIdInput(value: Textbox) {
    this.setInput("DocumentSourceId", value);
  }

  get memberFirstNameInput(): Textbox {
    return this.getInput("MemberFirstName");
  }
  get memberLastNameInput(): Textbox {
    return this.getInput("MemberLastName");
  }
  get memberDobInput(): Textbox {
    return this.getInput("MemberDateOfBirth");
  }

  get chartCommitmentDateInput(): Textbox {
    return this.getInput("ChartCommitmentDate");
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("ProjectList");
  }

  set projectsInput(value: CheckboxGroup) {
    this.setInput("ProjectList", value);
  }
  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasureList");
  }

  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasureList", value);
  }

  get clientInput(): Autocomplete {
    return this.getInput("ClientId");
  }
  set clientInput(value: Autocomplete) {
    this.setInput("ClientId", value);
  }

  get memberKeyInput(): Textbox {
    return this.getInput("MemberSourceAliasID");
  }

  get pendCodesInput(): CheckboxGroup {
    return this.getInput("PendCodeList");
  }
  set pendCodesInput(value: CheckboxGroup) {
    this.setInput("PendCodeList", value);
  }

  get pendStatusInput(): CheckboxGroup {
    return this.getInput("pendStatus");
  }
  set pendStatusInput(value: CheckboxGroup) {
    this.setInput("pendStatus", value);
  }

  get sampleComplianceCodeInput(): CheckboxGroup {
    return this.getInput("SampleComplianceCodesAsCsv");
  }
  set sampleComplianceCodeInput(value: CheckboxGroup) {
    this.setInput("SampleComplianceCodesAsCsv", value);
  }

  get assignedToInput(): Autocomplete {
    return this.request.getInput("AssignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.request.setInput("AssignedToUserId", value);
  }
  get contactNameInput(): Textbox {
    return this.request.getInput("ProviderPhone");
  }
  set contactNameInput(value: Textbox) {
    this.request.setInput("ProviderPhone", value);
  }
  get reportingStatusInput(): CheckboxGroup {
    return this.getInput("ReportingStatusIdsCsv");
  }
  set reportingStatusInput(value: CheckboxGroup) {
    this.setInput("ReportingStatusIdsCsv", value);
  }

  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  get coRetrievalOwnerInput(): Radiobutton {
    return this.getInput("coRetrievalOwner");
  }

  get hideOpenPendInput(): Textbox {
    return this.request.getInput("HideOpenPends");
  }
  set hideOpenPendInput(value: Textbox) {
    this.request.setInput("HideOpenPends", value);
  }

  get outputMode(): Textbox {
    return this.request.getInput("outputMode");
  }
  set outputMode(value: Textbox) {
    this.request.setInput("outputMode", value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private chaseService: ChaseSearchService,
    private providerService: ProviderService,
    private messagingService: MessagingService,
    private parameterService: ParameterService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private chaseQryService: ChaseQueryService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly automapper: AutomapperService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly createPendService: CreatePendService,
    private formService: FormService,
    private readonly chartService: ChartService,
    private gapService: GapService,
    private readonly tagService: TagService,
    private readonly authService: AuthService,
    private gridViewsService: GridViewsService,
    private addressesQueueService: AddressesQueueService,
    private readonly gridStateService: GridStateService,
    private readonly addressDetailService: AddressDetailService,
    private readonly commitmentDateService: CommitmentDateService
  ) {

  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  getValue(key: string): string {
    if (this.request == null) {
      return "";
    }

    const filter = this.request.getFilter(key);
    return filter.value;
  }

  setValue(key: string, value: string): void {
    if (this.request == null) {
      return;
    }

    const filter = this.request.getFilter(key);
    filter.value = value;
  }

  formatMemberDobDate(): void {
    const dobFilter = this.request.getFilter("MemberDateOfBirth");
    const formattedValue = DateHelper.format(dobFilter.value);
    dobFilter.value = formattedValue;
    this.formService.updateDom.next();
  }

  get moveHeaderMessage(): string {
    return this.chaseIdList.length === 1 ?
      "Are you sure you want to move this chase" : "Are you sure you want to move these chases";
  }

  get hasPrimaryContactSet(): boolean {
    return this.addressDetailState?.hasPrimaryContact;
  }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.isSOCoRetrievalOwner = this.user.isCoRetrievalEnabled;
    this.coRetrievalOrganizationName = this.user.coRetrievalOrganization;
    this.addressId = this.parameterService.getNumberNormal("addressId", null);

    this.assignedTo = this.isUserHasEmployeeOrReadOnlyRole ? null : (this.user.functionalRoleIds.indexOf(this.documentRetrievalLead) > -1) ? null : this.user.userId;
    this.moveChaseText = "Move Chase";
    this.createGrid();
    this.nonActionableChasesChecked = new Checkbox({
      key: "nonActionableChasesChecked",
      label: "Show All Chases",
    });
    this.hideOpenPendsChecked = new Checkbox({
      key: "hideOpenPendsChecked",
      label: "Hide Open Pends",
    });
    this.chaseGridFilterForm = this.formService.createFormGroup([this.nonActionableChasesChecked, this.hideOpenPendsChecked]);
    this.sink.add(

      this.addressDetailService.isChaseGridReload.subscribe(isLoadGrid => {
        if (isLoadGrid) {
          this.addressId = this.parameterService.getNumberNormal("addressId", null);
          this.request = new GridRequest({
            url: this.url,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            filters: this.getFilters(),
          });
          this.changeDetector.markForCheck();
        }
      }),

      this.addressDetailStateService.state.subscribe(state => {
        this.chaseGridConfiguration.stateName = ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`);
        this.addressDetailState = state;
        this.actions = this.getActions();
        if (this.addressDetailState?.documentSourceTypeId === DocumentSourceType.MRRUpload || this.addressDetailState?.documentSourceTypeId === DocumentSourceType.PEND) {
          const index = this.actions.findIndex(x => x.name === "Assign Chases");
          this.actions.splice(index, 1);
        }
        this.chaseGridConfiguration.columns.find(x => x.field === "totalOpenGaps").show = this.addressDetailState.hasGapProject;
        this.isThirdParty = state.documentSourceTypeId === DocumentSourceType.THIRDPARTY;
        this.commitmentActionLabel = this.isThirdParty ? "Request Date(s)" : "Commitment Date(s)";
        if (this.addressDetailState?.documentSourceTypeId) {
          this.updateCustomView();
        }
        if (this.addressDetailState.hasMasterDocumentSourceId && this.previousMasterDocumentSourceId !== this.addressDetailState.masterDocumentSourceId) {
          if (NumberHelper.isGreaterThan(this.previousMasterDocumentSourceId, 0)) {
            this.updateChaseGrid();
          }
          this.previousMasterDocumentSourceId = this.addressDetailState.masterDocumentSourceId;
        }

        // TODO: Commenting out till we have Gap Projects, post HEDIS
        // if (this.addressDetailState.hasMasterDocumentSourceId && !ArrayHelper.isAvailable(this.gapChasesAndPursuits) && !this.gapDataLoadInititated) {
        //  this.getGapPursuits();
        // }

        this.changeDetector.markForCheck();
      }),
      this.userService.getUser(this.user.userId).subscribe(result => {
        this.changeDetector.markForCheck();
        this.userName = `${result.firstName} ${result.lastName}`;
        this.phoneNumber = result.phone;
        this.userEmail = result.email;
        this.userInfo = {
          userName: this.userName,
          phoneNumber: this.phoneNumber,
          userEmail: this.userEmail,
        };
      }),

      this.chaseService.reset.subscribe(value =>
        this.updateChaseGrid()),

      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllTagsList()))
          .subscribe()

    );

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView))
    );
    const source = interval(1000);
    source.pipe(takeUntil(timer(5000))).subscribe(() => {
      this.bindChaseGridFromState();
    });
  }


  bindChaseGridFromState() {
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes(ADDRESS_CHASE_GRID.attributeCode) && this.addressId && key !== (this.PREFIX + ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`))) {
        this.gridStateService.delete(key.split("_")[1]);
      }
    });
    const state = this.gridStateService.get(ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`));
    if (this.addressId) {
      const savedAddresId = ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`).split("/");
      const stateExist = Number(savedAddresId[1]) === this.addressId;
      if (!stateExist) {
        this.gridStateService.put(ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`), state);
      }
    }

    this.bindChaseFormValues(state);
    this.setValue("WorkFlowStatus", this.showAllChaseValue);
    this.setValue("HideOpenPends", this.hideOpenPendValue);
    this.formService.updateDom.next();

  }

  createGrid(): void {
    this.updateGridConfiguration(true);
    this.chaseGridConfiguration.isStateDisabled = this.hasRouteParamaters;
    this.request = new GridRequest({
      url: this.url,
      sortDirection: this.sortDirection,
      sortField: this.sortField,
      filters: this.getFilters(),
    });
  }

  gridDataLoaded(data: any) {
    this.changeDetector.markForCheck();
    if (ArrayHelper.isAvailable(data)) {
      this.chaseGridData = data;
      this.chaseGridDataAll = data;
      this.loadChaseGrid(this.chaseGridData);
    } else {
      this.chaseGridDataAll = [];
    }
  }

  updateGridConfiguration(alreadyInitialized = true) {
    this.chaseGridConfiguration = new GridConfiguration({
      isInit: alreadyInitialized,
      stateName: ADDRESS_CHASE_GRID.attributeCode.concat(`/${this.addressId}`),
      isStateDisabled: this.hasRouteParamaters,
      columns: this.getColumns(),
      pageSize: this.pageSize,
      pageSizeOptions: [10, 25, 50, 100],
      selectionMode: "multiple",
      showActionColumn : true,
      showViews: true,
      viewAttributeId: ADDRESS_CHASE_GRID.attributeId,
      showSaveQuery: this.showSaveQuery,
      saveQueryAttributeId: this.saveQueryAttributeId,
      filterGridFn : this.onApplyFilter,
    });
  }

  onApplyFilter = () => {
    let newRequest = [];
    this.chaseGridData = this.chaseGridDataAll;
    Object.keys(this.chaseForm.form.value).forEach(key => {
      if (this.chaseForm.form.value[key] != null && !Array.isArray(this.chaseForm.form.value[key])) {
        if (key === this.assignedToInput.key) {
          const value = NumberHelper.isGreaterThan(this.chaseForm.form.value[key].value, 0, true)
                        ? this.chaseForm.form.value[key].text === "@Me" ? this.chaseForm.form.value[key].extra : this.chaseForm.form.value[key].text
                        : "";
          newRequest.push({
            text: key,
            value,
            label: NumberHelper.isGreaterThan(this.chaseForm.form.value[key].value, 0, true) ? key : this.chaseForm.form.value[key].label,
            controlType: "",
          });
        } else {
          newRequest.push({
            text: key,
            value: this.chaseForm.form.value[key].value ? this.chaseForm.form.value[key].text : this.chaseForm.form.value[key],
            label: this.chaseForm.form.value[key].value ? key : this[key].label,
            controlType: this[key] ? this[key].controlType : "",
          });
        }
      }
      if (ArrayHelper.isAvailable(this.chaseForm.form.value[key])) {
        this.chaseForm.form.value[key].map(item => newRequest.push({
          text: key === "TagIdsAsCsv" ? "tagsText" : key,
          value: key === "TagIdsAsCsv" ? item.text : item.value,
          label: this[key].label,
          controlType: this[key].controlType,
        }));
      }
    });
    newRequest = this.removeTagSearchOperatorChip(newRequest);
    const newFilter = [];
    newRequest.forEach(filter => {
      if ((newFilter.findIndex(item => item.text === filter.text)) > -1) {
        newFilter[newFilter.findIndex(item => item.text === filter.text)].value.push(filter.value);
      } else {
        newFilter.push({ text: filter.text, value: [filter.value], controlType: filter.controlType });
      }
    });
    newFilter.forEach(filter => {
      if (filter.controlType === "multiselect" && filter.text === "tagsText") {
        this.filterTagsText(filter, this.tagSearchOperatorFilterValue);
      }
    });

    const isSelectAllChaseChecked = this.chaseGridFilterForm.get(this.nonActionableChasesChecked.key).value;
    if (isSelectAllChaseChecked) { this.changeChaseGridData(); }
  }

  private getFilters(): GridFilter[] {
    const memberValidator = [
      Validators.maxLength(100),
    ];
    const memberErrorMessage = {
      maxlength: `Minimum 2 characters and Maximum 100 characters allowed`,
    };

    const totalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "DocumentSourceId",
        value: this.addressId.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "HideOpenPends",
        value: this.hideOpenPendValue,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "WorkFlowStatus",
        value: this.showAllChaseValue,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseID",
        name: "Chase ID / Client Chase Key",
        validators: memberValidator,
        errorMessages: memberErrorMessage,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberFirstName",
        name: "First Name",
        validators: memberValidator,
        errorMessages: memberErrorMessage,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberLastName",
        name: "Last Name",
        validators: memberValidator,
        errorMessages: memberErrorMessage,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MemberDateOfBirth",
        name: "DOB",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ProviderPhone",
        name: "Contact Name",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "ProjectList",
        name: "Projects",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "MeasureList",
        name: "Measures",
      }),
      new GridFilter({
      input: new CheckboxGroup(),
      key: "ReportingStatusIdsCsv",
      name: "Status",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChartCommitmentDate",
        name: "Commit Date",
      }),
      new GridFilter({
        input: new CheckboxGroup({showTooltip: true}),
        key: "PendCodeList",
        name: "Pend Codes",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "pendStatus",
        name: "Pend Status",
        value: this.getRouteParamterValue("pendStatuses"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "SampleComplianceCodesAsCsv",
        name: "Sample Compliance",
        value: this.getRouteParamterValue("sampleCompliances"),
      }),
      new GridFilter({
        input: new TagSearchMultiselect({ placeholder: "Tags" }),
        key: "TagIdsAsCsv",
        name: "Tags",
      }),
      new GridFilter({
        input: new Dropdown(),
        key: "TagSearchOperator",
        value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
          ? this.selectedTagSearchOperator : null,
        showChip: false,
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
            new SelectableInput({ text: "Client", value: "Client" }),
          ],
        }),
        key: "coRetrievalOwner",
        name: "Co Retrieval Owner",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "outputMode",
        name: "Output Mode",
        show: false,
      }),
    ];
    return totalFilters;
  }

  bindChaseFormValues(stateForm: any) {
    for (const key in this.chaseForm.form.controls) {
      if (key) {
        switch (key) {
          case this.documentSourceIdInput.key:
            this.documentSourceIdInput.value = this.addressId ?? stateForm[key];
            break;
            case this.chaseIdInput.key:
              this.chaseForm.form.patchValue({ ChaseID: stateForm[key] });
              this.chaseForm.form.controls[this.chaseIdInput.key].updateValueAndValidity({ emitEvent: true });
              this.chaseIdInput.value = stateForm[key];
              break;
          case this.projectsInput.key:
            this.chaseForm.form.patchValue({ ProjectList: stateForm[key] });
            this.chaseForm.form.controls[this.projectsInput.key].updateValueAndValidity({ emitEvent: true });
            this.projectsInput.value = stateForm[key];
            break;
          case this.measuresInput.key:
            this.chaseForm.form.patchValue({ MeasureList: stateForm[key] });
            this.chaseForm.form.controls[this.measuresInput.key].updateValueAndValidity({ emitEvent: true });
            this.measuresInput.value = stateForm[key];
            break;
          case this.reportingStatusInput.key:
            this.chaseForm.form.patchValue({ reportingStatusInput: stateForm[key] });
            this.chaseForm.form.controls[this.reportingStatusInput.key].updateValueAndValidity({ emitEvent: true });
            this.reportingStatusInput.value = stateForm[key];
            break;
          case this.pendCodesInput.key:
            this.chaseForm.form.patchValue({ PendCodeList: stateForm[key] });
            this.chaseForm.form.controls[this.pendCodesInput.key].updateValueAndValidity({ emitEvent: true });
            this.pendCodesInput.value = stateForm[key];
            break;
          case this.pendStatusInput.key:
            this.chaseForm.form.patchValue({ pendStatus: stateForm[key] });
            this.chaseForm.form.controls[this.pendStatusInput.key].updateValueAndValidity({ emitEvent: true });
            this.pendStatusInput.value = stateForm[key];
            break;
          case this.sampleComplianceCodeInput.key:
            this.chaseForm.form.patchValue({ sampleComplianceCodeInput: stateForm[key] });
            this.chaseForm.form.controls[this.sampleComplianceCodeInput.key].updateValueAndValidity({ emitEvent: true });
            this.sampleComplianceCodeInput.value = stateForm[key];
            break;
          case this.tagsInput.key:
            this.chaseForm.form.patchValue({ tagsInput: stateForm[key] });
            this.chaseForm.form.controls[this.tagsInput.key].updateValueAndValidity({ emitEvent: true });
            this.tagsInput.value = stateForm[key];
            break;
          default:
            this.chaseForm.form.get(key).setValue(stateForm[key]);
            break;
        }
      }
    }
    this.formService.updateDom.next();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  loadFilterInput() {
    if (!this.isReloadFilterInput) {
      switch (this.addressDetailState?.documentSourceTypeId) {
        case DocumentSourceType.EMR:
        case DocumentSourceType.FIELDTECH:
        case DocumentSourceType.PSR:
        case DocumentSourceType.THIRDPARTY:
          this.getUsers();
          break;
        default:
          break;
      }
      this.getTagSearchOperator();
      this.getAllSelectableInputs();
      this.isReloadFilterInput = true;
    }
  }

  private getColumns(): GridColumnDefinition[] {
    const defaultColumns = [
      new GridColumnDefinition({ field: "chaseID", template: this.chaseIdColumn, header: "Chase ID", width: "135px" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB" }),
      new GridColumnDefinition({ field: "memberGender", header: "M/F" }),
      new GridColumnDefinition({ field: "memberID", header: "Client Member ID" }),
      new GridColumnDefinition({ field: "serviceProviders", header: "Provider Name" }),
      new GridColumnDefinition({ field: "tin", header: "TIN" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "product", header: "Product" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "totalOpenGaps", header: "Open Gaps", template: this.openGapsColumn, showTitle: false }),
      new GridColumnDefinition({ field: "documentRequestId", header: "Doc Request ID" }),
      new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "contactName", header: "Contact" }),
      new GridColumnDefinition({ field: "contactPhone", header: "Contact Phone" }),
      new GridColumnDefinition({ field: "dateOfService", header: "DOS" }),
      new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "memberValidationReason", header: "Move Back Reason" }),
      new GridColumnDefinition({ field: "pendOwner", header: "Pend Owner" }),
      new GridColumnDefinition({ field: "previousMasterDocumentSourceID", header: "Previous AID", routeUrl: "/retrieval/addressdetail/:previousMasterDocumentSourceID" }),
      new GridColumnDefinition({ field: "outreachTemplateName", header: "Outreach Request" }),
      new GridColumnDefinition({ field: "chaseCommentFormatted", header: "Comments", template: this.commentsColumn, showTitle: false }),
      new GridColumnDefinition({ field: "tagsText", header: "Chase Tag" }),

    ];
    return defaultColumns;
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      new BulkAction({
        name: "Outreach",
        action: this.openOutReachModal.bind(this),
        // TODO: Reinstate when when we have Gap Projects, Post Hedis
        // disabled: true,
      }),
    ];
    if (this.isEmrOrFt) {
      totalBulkActions.push(
        new BulkAction({
          name: "Assign Chases",
          action: this.assignChases,
        })
      );
    }
    totalBulkActions.push(
      new BulkAction({
        name: "Pend Chase(s)",
        action: this.openPendModal.bind(this),
      }),
      new BulkAction({
        name: this.moveChaseText.concat("(s)"),
        action: this.moveChase.bind(this),
      }),
      new BulkAction({
        name: "Manage Tags",
        action: this.openManageTagModal.bind(this),
        disabled: !this.isManageTagDisabled,
      }),
      new BulkAction({
        name: "Print Request(s)",
        action: this.openPrintProvider.bind(this),
        hasIcon: true,
        iconName: "print",
      }),
      new BulkAction({
        name: "Commitment Date",
        action: this.openCommitmentDateModal.bind(this),
      })
    );
    if (this.user.isCoRetrievalEnabled && this.user.isAdminRole) {
      totalBulkActions.push(
        new BulkAction({
          name: "Change Retriever",
          action: this.openChangeRetrieverModal.bind(this),
        })
      );
    }
    return totalBulkActions;
  }

  formatChartCommitmentDate(): void {
    const dobFilter = this.request.getFilter("ChartCommitmentDate");
    const formattedValue = DateHelper.format(dobFilter.value);
    dobFilter.value = formattedValue;
    this.formService.updateDom.next();
  }

  removeTagSearchOperatorChip(newRequest: any): any {
    let request = newRequest;
    newRequest.filter((filter, index) => {
      if (filter.text.toLowerCase() === "tagsearchoperator") {
        this.tagSearchOperatorFilterValue = filter.value.toString();
        request = newRequest.splice(0, index);
      }
    });

    return request;
  }

  filterTagsText(filter: any, tagSearchOperator: string): void {
    filter.value = filter.value.map(x => x.toUpperCase());
    if (tagSearchOperator === "OR") {
      this.chaseGridData = [...this.chaseGridData.filter(x => x.tagsText && x.tagsText.split(",").length > 0 &&
        x.tagsText.toUpperCase().split(",").map(m => m.trim()).some(s => filter?.value?.includes(s.toUpperCase())))];
    } else if (tagSearchOperator === "AND") {
      this.chaseGridData = [...this.chaseGridData.filter(x => filter?.value?.every(e => x.tagsText.toUpperCase().split(",")
        .map(m => m.trim()).includes(e.toUpperCase())))];
    }
  }

  showAllChaseGridData() {
    const isShowAllChases = this.chaseGridFilterForm.get(this.nonActionableChasesChecked.key).value;
    this.showAllChaseValue = isShowAllChases === true ? "1" : "0";
    this.setValue("WorkFlowStatus", this.showAllChaseValue);
    this.updateChaseGrid();
    this.changeDetector.markForCheck();
  }

  changeHideOpenPends(): void {
    const isHideOpenPendsChecked = this.chaseGridFilterForm.get(this.hideOpenPendsChecked.key).value;
    this.hideOpenPendValue = isHideOpenPendsChecked === true ? "1" : "0";
    this.setValue("HideOpenPends", this.hideOpenPendValue);
    this.updateChaseGrid();
    this.changeDetector.markForCheck();
  }

  getChaseGridData(): any[] {

    const isSelectAllChaseChecked = this.chaseGridFilterForm.get(this.nonActionableChasesChecked.key).value;
    const isHideOpenPendsChecked = this.chaseGridFilterForm.get(this.hideOpenPendsChecked.key).value;
    this.chaseGridDataAll.forEach(x => {
      // Disable chases not in "Chart collection", "Waiting for chart" workflow status or which are moved or request made
      // Make them non - actionable.
      if (x.workflowStatusId !== WorkflowStatusDb.ChartCollection && x.workflowStatusId !== WorkflowStatusDb.WaitingForChart) {
        x.disabled = true;
      }
    });

    if (isHideOpenPendsChecked) {
      if (isSelectAllChaseChecked) {
        return this.chaseGridDataAll.filter(c => c.disabled && c.pendStatusId === PendStatus.Closed ||
          c.pendStatusId === PendStatus.Resolved || c.pendStatusId === null || c.pendStatusId === undefined);
      } else {
        return this.chaseGridDataEnabled.filter(c => c.pendStatusId === PendStatus.Resolved || c.pendStatusId === null || c.pendStatusId === undefined);
      }
    }
    if ((this.user.isManagerRole || this.user.isLeadRole) && this.isSOCoRetrievalOwner && !this.user.isAdminRole) {
      this.isChaseBasedOnCoRetrievalOrganizationName = true;
      this.chaseGridDataAll = this.chaseBasedOnCoRetrievalOrganizationName;
    }
    if ((this.user.isEmployeeRole && this.isSOCoRetrievalOwner && (!this.user.isAdminRole || !this.user.isManagerRole || !this.user.isLeadRole))) {
      this.chaseGridDataAll = this.chaseBasedOnCoRetrievalOrganizationName;
    }
    return isSelectAllChaseChecked ? this.chaseGridDataAll : this.chaseGridDataEnabled;
  }
  get chaseBasedOnCoRetrievalOrganizationName(): any[] {
    return this.chaseGridDataAll.filter(c => c.coRetrievalOwner === this.coRetrievalOrganizationName);
  }
  get chaseGridDataEnabled(): any[] {
    return this.chaseGridDataAll.filter(c => !c.disabled);
  }

  get totalOpenChaseCount(): number {
    if (!ArrayHelper.isAvailable(this.chaseGridDataEnabled)) {
      return 0;
    } else {
      return this.chaseGridDataEnabled.length;
    }
  }

  updateChaseGrid() {
    this.refreshGrid.emit();
    this.changeDetector.markForCheck();
  }

  onChangRetrieverType(): void {
    this.updateChaseGrid();

    this.addressDetailService.getAddressDetail(this.addressDetailState.masterDocumentSourceId).subscribe({
      next: addressDetail => {
        this.addressDetailStateService.setData({
          coRetrievalOwner: addressDetail.coRetrievalOwner,
        });
      },
    });
  }

  loadChaseGrid(chaseGridData: ChaseItem[]) {
    this.addressDetailStateService.setData({ chaseGridData });
    this.chaseGridData = this.getChaseGridData();
    this.primeGridData = this.chaseGridData;
    const chases = this.chaseGridDataEnabled.map(x => x.chaseID);
    this.chaseService.getChaseList(chases);
  }

  updateCustomView(): void {
    const isCoRetrievalColumnAdded = this.chaseGridConfiguration.columns.find(x => x.field === "coRetrievalOwner");
    if (this.isThirdParty) {

      if (!this.isAdditionColumnAdded) {
        this.chaseGridConfiguration.columns.push(new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
                                                 new GridColumnDefinition({ field: "chartExpectedDate", header: "Due Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }));

        this.chaseGridConfiguration.columns.map(x => {
          x.header = x.header === "Commit" ? "Request" : x.header;
          x.field = x.header === "Request" ? "chartRequestDate" : x.field;
          x.header = x.header === "Request" ? "Request Date" : x.header;
          return x;
        });
      }

      this.actions.map(x => {
        x.name = x.name === "Commitment Date" ? "Request Date" : x.name;
        return x;
      });

      if (!this.actions.some(a => a.name === "Due Date")) {
        this.actions.push(new BulkAction({
          name: "Due Date",
          action: this.openDueDateModal.bind(this),
        }));
      }

      this.isAdditionColumnAdded = true;
    }
    if (!this.isThirdParty && !this.isAdditionColumnAdded) {
      this.chaseGridConfiguration.columns.push(new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }));
      this.isAdditionColumnAdded = true;
    }

    if (this.isSOCoRetrievalOwner && !isCoRetrievalColumnAdded) {
      this.chaseGridConfiguration.columns.push(new GridColumnDefinition({ field: "coRetrievalOwner", header: "Retriever" }));
    }

    if ((this.isEmrOrFt || this.isChaseBasedOnCoRetrievalOrganizationName || this.isPsrOrThirdParty) && !this.assignedColumnAdded) {
      const index = this.chaseGridConfiguration.columns.findIndex(x => x.field === this.reportingStatusName);
      this.chaseGridConfiguration.columns.splice((index + 1), 0, new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }));
      this.assignedColumnAdded = true;
    }
    const tagsTextIndex = this.chaseGridConfiguration.columns.findIndex(x => x.field === "tagsText");
    if (tagsTextIndex > -1) {
      this.chaseGridConfiguration.columns.push(this.chaseGridConfiguration.columns.splice(tagsTextIndex, 1)[0]);
    }

    this.getViews();
  }

  selectRow(rowData) {
    const key = "chasePendId";
    this.chasePendIds = [];
    const selectedRows = rowData ? ArrayHelper.isAvailable(rowData) ? rowData : [rowData] :
      this.actionableChaseGridSelection;

    if (selectedRows && selectedRows.length > 0) {
      const pendId = selectedRows[0][key];
      this.chaseIdList = [];
      this.pendIds = [];

      if (pendId !== 0) {
        this.chasePendId = pendId;
      }

      const checkValue = selectedRows.filter(c => c.pendCode);

      if (checkValue.length > 0 && checkValue.length < selectedRows.length) {
        this.messagingService.showToast("Please select similar Chases for bulk actions - either with Pend or without Pend", SeverityType.ERROR);
        return;
      }
      selectedRows.forEach(item => {
        this.chaseIdList.push(item.chaseID);
        if (item.chasePendId !== 0) {
          this.chasePendIds.push(item.chasePendId);
          this.pendIds.push(item.chasePendId);
        }
      });
      this.isClinical = false;
      if (!this.isAddressModalVisible) {
        this.pendIds.length > 0 ? this.isEditPendView = true : this.isCreatePendView = true;
      }
      this.formClose.emit();
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }

    this.chaseGridSelection = [];
  }

  get actionableChaseGridSelection(): ChaseItem[] {
    return (ArrayHelper.isAvailable(this.chaseGridSelection))
      ? this.chaseGridSelection.filter(c => !c.disabled)
      : [];
  }

  uploadChase(): void {
    this.router.navigate(["retrieval", "chaseUpload", this.addressId, this.addressDetailState.documentSourceTypeId]);
    this.gridStateService.delete(CHASE_UPLOAD_GRID.attributeCode);
  }

  getChaseCount(rowData?: any): number {
    const enabledChaseIdList = this.enabledChaseIdList(rowData);
    return enabledChaseIdList.length;
  }

  getFaxRequest(rowData?: any): FaxRequest {
    const enabledChaseIdList = this.enabledChaseIdList(rowData);

    return new FaxRequest({
      ...this.getSharedContactRequestProperties(),
      to: this.addressDetailState.contact.contactFax,
      chaseIds: enabledChaseIdList,
    });
  }

  openChasePrompt(rowData?: any): void {
    const enabledChaseIdList = this.enabledChaseIdList(rowData);

    if (!StringHelper.isAvailable(this.addressDetailState.contact.contactFax)) {
      this.messagingService.showToast("The Fax number is invalid.", SeverityType.WARN);
    } else if (!ArrayHelper.isAvailable(enabledChaseIdList)) {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    } else {
      if (NumberHelper.isGreaterThan(enabledChaseIdList.length, 25, true)) {
        this.isChasePromptVisible = true;
      } else {
        this.openTemplateModal(this.getFaxRequest(rowData));
      }
    }
  }

  openFaxModal(rowData?: any): void {
    this.isChasePromptVisible = false;
    this.openTemplateModal(this.getFaxRequest(rowData));
  }

  onCancelPopup() {
    this.isChasePromptVisible = false;
  }

  openEmailModal(rowData?: any): void {
    const enabledChaseIdList = this.enabledChaseIdList(rowData);

    if (!ArrayHelper.isAvailable(enabledChaseIdList)) {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }

    const emailRequest = new EmailRequest({
      ...this.getSharedContactRequestProperties(),
      to: this.addressDetailState.contact.contactEmail,
      toPhone: "",
      chaseIds: enabledChaseIdList,
    });
    this.openTemplateModal(emailRequest);
  }

  private getSharedContactRequestProperties(): any {
    return {
      documentSourceId: this.addressId,
      toPhone: this.addressDetailState.contact.contactPhone,
      toName: this.addressDetailState.contact.contactName,
      from: this.phoneNumber,
      fromName: this.userName,
    };
  }

  private enabledChaseIdList(rowData?: any): number[] {
    const enabledChaseIdList = [];

    if (rowData !== undefined) {
      const selectedRows = rowData;
      enabledChaseIdList.push(selectedRows.chaseID);
    } else {
      const selectedRows = this.actionableChaseGridSelection;

      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach(item => {
          enabledChaseIdList.push(item.chaseID);
        });
      }
    }
    return enabledChaseIdList;
  }

  openTemplateModal(contactRequest: ContactRequest): void {
    if (!contactRequest.isValid) {
      const toValue = StringHelper.isAvailable(contactRequest.toValue) ? ` '${contactRequest.toValue}' ` : " ";
      this.messagingService.showToast(`The ${contactRequest.toLabel}${toValue}is invalid.`, SeverityType.WARN);
    }

    this.contactRequest = contactRequest;
    this.isTemplateModalVisible = true;
    this.changeDetector.markForCheck();
  }

  private getChaseListForBulkAction(rowData?: any, bulkAction?: string): void {
    if (this.request.isAllSelected) {
      const currentFilter = {};

      // save in memory chase list in case of further actions
      // compare saved filter in case of any change, get chase list
      this.request.filters.map(filter => currentFilter[filter.key] = filter.key === "outputMode" ? "Address bulk action" : filter.value);

      if (ArrayHelper.isAvailable(this.chaseList) && JSON.stringify(this.filter) === JSON.stringify(currentFilter)) {
        this.performBulkAction(this.chaseList, bulkAction);
        return;
      }

      this.chaseService.getLightChaseList(currentFilter).subscribe(data => {
        this.performBulkAction(data, bulkAction);
        this.filter = currentFilter;
        this.chaseList = data;
      });
    } else if (rowData) {
      this.performBulkAction(rowData, bulkAction);
    }

    this.changeDetector.markForCheck();
  }

  private performBulkAction(rowData?: any, bulkAction?: string): void {

    switch (bulkAction) {
      case "moveChase":
        this.GetMoveChaseSelectedRow(rowData);
        break;
      case "openPrintProvider":
        this.buildChaseList(rowData);
        this.buildProviderPacketItem();
        break;
      case "openOutReachModal":
        this.validateOutreachChases(rowData);
        break;
      case "assignChases":
        this.selectedChaseIdsForAssignment = this.getChaseIds(rowData);
        this.isAssignModalVisible = true;
        break;
      case "openPendModal":
        this.validatePendChases(rowData);
        break;
      case "openManageTagModal":
        this.manageTagOpenModal(rowData);
        break;
      case "openCommitmentDateModal":
        this.getCommitmentDateModel(rowData);
        break;
      case "openChangeRetrieverModal":
        this.validateRetrievalChases(rowData);
        break;
      default:
        break;
    }
  }

  moveChase(rowData?: any): void {
    this.getChaseListForBulkAction(rowData, "moveChase");
  }

  private GetMoveChaseSelectedRow(rowData?: any): void {
    this.selectRow(rowData);
    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.isAddressModalVisible = true;
    }
  }

  gotoAddressDetail() {
    this.isConfirmModalVisible = false;
    if (this.selectedAddress) {
      this.router.navigateByUrl(`retrieval/addressdetail/${this.selectedAddress.masterDocumentSourceId}`);
    }
  }

  confirmMessage(): void {
    this.isConfirmModalVisible = !this.isConfirmModalVisible;
    this.changeDetector.markForCheck();
  }

  onAddressSelectionForChaseMove($event: AddressSearchResult): void {
    this.isMoveVisible = !this.isNewAddress;
    this.selectedAddress = $event;
    this.isAddressModalVisible = !this.isNewAddress;
  }

  chaseMoved() {
    this.assignedUser = "";
    this.chaseMoveModel = new ChaseMoveModel({
      toAddressId: this.selectedAddress.masterDocumentSourceId.toString(),
      fromAddressId: this.addressId.toString(),
      chaseIds: this.chaseIdList.join(),
      pageType: this.addressDetailState.documentSourceTypeName,
      assignedUser: this.assignedUser,
      status: null ,
      notes: this.selectedAddress.notes,
      isChaseAssign: this.selectedAddress.isChaseAssign,
    });

    this.chaseQryService.chaseMove(this.chaseMoveModel).subscribe(
      () => {
        if (this.isNewAddress) {
          this.messagingService.showToast("New Address Created.", SeverityType.SUCCESS);
        }
        this.confirmStatusMessage = `${this.chaseIdList.length} chase(s) successfully moved to AID ${this.selectedAddress.masterDocumentSourceId}`;

        this.isContactInfoModalVisible = false;
        this.updateChaseGrid();
        this.confirmMessage();
        if (this.isNewAddress) {
          this.updateSetCommitmentDate();
        }
      },
      err => {
          this.messagingService.showToast(`Error while moving chase to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR);
      }
    );

    this.isMoveVisible = false;
    this.isAddressModalVisible = false;
    this.changeDetector.markForCheck();
  }

  onContactInfoSaved($event: AddressSearchResult): void {
    this.selectedAddress = $event;
  }

  closeChaseMovePop() {
    this.isMoveVisible = false;
  }

  openPrintProvider(rowData?: any): void {
    this.getChaseListForBulkAction(rowData, "openPrintProvider");
  }

  private buildChaseList(rowData?: any): void {
    let selectedRows: any;
    const rows = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    this.showNoGapsModal = rows.filter(x => x.isGapProject && x.totalOpenGaps === 0).length > 0;
    const filteredRowData = rows.filter(x => !x.isGapProject || (x.isGapProject && x.totalOpenGaps !== 0));

    this.chaseIdList = this.getChaseIds(filteredRowData);
    this.selectedChases = this.getSelectedChases(filteredRowData);
    selectedRows = ArrayHelper.isAvailable(filteredRowData) ? filteredRowData : [filteredRowData];
    this.projectId = selectedRows[0].projectID;
  }

  private buildProviderPacketItem(): void {

    this.providerPacketItem = new ProviderPacketItem({
      chaseIds: this.chaseIdList,
      projectId: this.projectId,
      toName: this.addressDetailState.contact.contactName,
      toPhone: this.addressDetailState.contact.contactPhone,
      toFax: this.addressDetailState.contact.contactFax,
      toAddress: this.addressDetailState.address1,
      fromName: this.userName,
      fromPhone: this.phoneNumber,
      providerName: "",
      serviceOrgName: this.user.organizationName,
      chaseNumeratorList: this.selectedChaseNumeratorList,
    });

    if (!this.showNoGapsModal) { this.isPrintProviderPacketModalVisible = true; }

    this.changeDetector.markForCheck();
  }

  showPrintPocketModal(): void {
    this.isPrintProviderPacketModalVisible = true;
    this.showNoGapsModal = false;
  }

  get selectedChaseNumeratorList(): NumeratorListItem[] {
    if (!ArrayHelper.isAvailable(this.selectedChases)) {
      return [];
    }
    return this.selectedChases.map(chase =>
      new NumeratorListItem({
        chaseId: Number(chase.chaseID),
        numeratorId: chase.numeratorId,
        numeratorCode: chase.openGaps,
        numeratorName: chase.numeratorName,
      }));
  }

  openCommitmentDateModal(rowData?: any): void {
    this.getChaseListForBulkAction(rowData, "openCommitmentDateModal");
  }

  private getCommitmentDateModel(rowData?: any): void {
    this.selectedRowsForChaseGridAction(rowData);

    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.isCommitmentDateModalVisible = true;
    }

    this.commitDateUpdateModel = new CommitmentDateUpdateModel({
      chaseIds: this.chaseIdList,
      functionalRoleId: FunctionalRole.DOCUMENTRETRIEVAL,
      retrievalLocationId: this.addressId,
    });
    this.commitmentDate = !this.isThirdParty ? (rowData && rowData.chartCommitmentDate ? DateHelper.removeTime(rowData.chartCommitmentDate) : null)
      : (rowData && rowData.chartRequestDate ? DateHelper.removeTime(rowData.chartRequestDate) : null);
  }

  onCommitmentDateUpdate($event) {
    this.updateChaseGrid();
  }

  resetChaseSelection($event) {
    this.chaseIdList = [];
    this.chaseGridSelection = [];
  }

  openDueDateModal(rowData?: any) {
    this.chaseIdList = this.getChaseIds(rowData);

    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.isExpectedDueDateModalVisible = true;
    }

    this.expectedDueDateUpdateModel = new ExpectedDueDate({
      chaseIds: this.chaseIdList,
      functionalRoleId: FunctionalRole.DOCUMENTRETRIEVAL,
      retrievalLocationId: this.addressId,
    });
    this.expectedDueDate = rowData && rowData?.chartExpectedDate ? DateHelper.removeTime(rowData.chartExpectedDate) : null;
  }

  selectedRowsForChaseGridAction(rowData?: any) {
    this.chaseIdList = [];
    if (rowData != null) {
      this.chaseIdList = this.getChaseIds(rowData);
    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => this.chaseIdList.push(item.chaseID));
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }
  }

  openOutReachModal(rowData?: any): void {
    if (!this.hasPrimaryContactSet) {
      this.showPrimaryContactNotSetModal = true;
      return;
    }

    this.selectedChases = [];
    this.getChaseListForBulkAction(rowData, "openOutReachModal");
  }

  private validateOutreachChases(rowData?: any): void {
    this.selectedChases = this.getSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.isOutreachModalVisible = true;
      this.changeDetector.markForCheck();
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  openPendModal(rowData?: any): void {
    this.selectedChases = [];
    this.getChaseListForBulkAction(rowData, "openPendModal");
  }

  private validatePendChases(rowData?: any): void {
    this.selectedChases = this.getSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.isPendModalVisible = true;
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  closeModal(): void {
    this.selectedChases = [];
    this.chaseGridSelection = [];
  }

  closeOutreachModal(): void {
    // TODO: Deactivating just for Hedis - Temporary removal. Reinstate after we have Gap projects
    // this.isAdditionalOutreachPromptVisible = this.additionalGapOutreachChaseCount > 0;
    this.closeModal();
  }

  navigateToAdditionalOutreachTab(): void {
    this.router.navigateByUrl(`retrieval/addressdetail/${this.addressDetailState.masterDocumentSourceId}/gapPursuits`);
  }

  changeChaseGridData(): void {
    this.chaseGridData = this.getChaseGridData();
    this.changeDetector.markForCheck();
  }

  private getChaseIds(rowData: any): number[] {
    const chases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    const chaseIds = chases.map(a => Number(a.chaseID));
    return chaseIds;
  }

  private getSelectedChases(rowData: any): number[] {
    let selectedChases = [];

    if (rowData != null) {
      selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => selectedChases.push(item));
    }

    return selectedChases;
  }

  trackByIndex(index, item) {
    return index;
  }

  sendContactRequest(event: ContactRequest): void {
    this.providerService.sendProviderContactRequest(event).subscribe({
      next: () => {
        this.isTemplateModalVisible = false;
        this.chaseGridSelection = [];
        this.messagingService.showToast(`${event.typeName} Queued for Sending.`, SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      },
      error: () => this.messagingService.showToast(`Error while sending ${event.typeName}, please try again.`, SeverityType.ERROR),
    });
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getMeasures();
    this.getStatuses();
    this.getPendCodes();
    this.getPendStatus();
    this.getComplianceCodes();
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.description,
          value: item.id,
        }));
      }))
      .subscribe(options => {
        this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.measureCode,
          value: item.measureId,
        }));
      }))
      .subscribe(options => {
        this.measuresInput = new CheckboxGroup({ ...this.measuresInput, options } as any);
        this.formService.updateDom.next();
      });
  }
  private getViews(gridView: GridView | null = null): void {
    if (gridView != null) {
      this.gridViewLoadCount = 0;
    }
    if (this.chaseGridConfiguration.showViews && this.gridViewLoadCount === 0) {
      this.gridViewLoadCount = 1;
      this.gridViewsService.get(this.chaseGridConfiguration.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }

        this.changeDetector.markForCheck();
      });
    }
  }
  private getStatuses(): void {
    this.chaseQryService
      .getReportingStatusesList()
      .pipe(map(this.automapper.curryMany("ReportingStatusModel", "SelectableInput")))
      .subscribe(options => {
        this.reportingStatusInput = new CheckboxGroup({ ...this.reportingStatusInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getPendCodes(): void {
    this.createPendService
      .getPendDropdown(this.clinical)
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
      }))
      .subscribe(options => {
        this.pendCodesInput = new CheckboxGroup({ ...this.pendCodesInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getPendStatus(): void {
    this.createPendService
      .getPendStatus(false)
      .pipe(map((result: any) => {
        return result.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
      }))
      .subscribe(result => {
        this.pendStatusInput = new CheckboxGroup({ ...this.pendStatusInput, options: result } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.sampleComplianceCodeInput = new CheckboxGroup({ ...this.sampleComplianceCodeInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  getGapPursuits(): void {
    this.gapDataLoadInititated = true;
    this.gapService
      .gapSearch(new GapSearchRequest({
        addressId: this.addressId,
      }))
      .subscribe(gapPursuitData => {
        this.gapChasesAndPursuits = gapPursuitData;
        this.gapDataFetched = true;
        this.actions.find(x => x.name === "Outreach").disabled = false;
        this.addressDetailStateService.setData({
          openGapPursuitsCount: this.gapChasesAndPursuits.length,
        });
        this.changeDetector.markForCheck();
      });
  }

  get additionalGapOutreachChaseCount(): number {
    return this.openGaps.length;
  }

  get openGaps(): any[] {
    if (!ArrayHelper.isAvailable(this.gapChasesAndPursuits)) {
      return [];
    }

    return this.gapChasesAndPursuits.filter(x => !this.gapService.disabledRowsFilterForGapOutReach(x));
  }

  openManageTagModal(rowData?: any): void {
    this.selectedChases = [];
    this.getChaseListForBulkAction(rowData, "openManageTagModal");
  }

  private manageTagOpenModal(rowData?: any): void {

    this.setSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.totalEntityCount = this.selectedChases.length;
      this.entityName = "CHASE";
      this.isManageTagModalVisible = true;
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  private setSelectedChases(rowData: any): void {
    if (rowData != null) {
      this.selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => this.selectedChases.push(item));
    }
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  getAllTagsList(): void {
    this.allAvailableTags = [];
    const searchText = this.chaseForm.form.get(this.tagsInput.key).value;
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.options = [...this.allAvailableTags];

      });
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  onManageTagUpdate(): void {
    this.updateChaseGrid();
  }

  get isManageTagDisabled(): boolean {
    return (this.authService.user.isAddTag
      || this.authService.user.isAdminRole
      || this.authService.user.isManagerRole
      || this.authService.user.isLeadRole);
  }
  get isEmrOrFt(): boolean {
    return (this.addressDetailState?.documentSourceTypeId === DocumentSourceType.EMR || this.addressDetailState?.documentSourceTypeId === DocumentSourceType.FIELDTECH);
  }

  get isPsrOrThirdParty(): boolean {
    return (this.addressDetailState?.documentSourceTypeId === DocumentSourceType.PSR || this.addressDetailState?.documentSourceTypeId === DocumentSourceType.THIRDPARTY);
  }

  private getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }

  assignChases = (rowData: any): void => {
    this.getChaseListForBulkAction(rowData, "assignChases");
  }

  closeAssignModal() {
    this.chaseIds = null;
    this.updateChaseGrid();
  }

  openChangeRetrieverModal(rowData?: any): void {
    this.chaseIdList = [];
    this.getChaseListForBulkAction(rowData, "openChangeRetrieverModal");
  }

  private validateRetrievalChases(rowData?: any): void {

    this.chaseIdList = this.getChaseIds(rowData);
    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.totalEntityCount = this.chaseIdList.length;
      this.isRetrieverModalVisible = true;
    } else {
      this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
    }
    this.retrieverUpdateModel = new RetrieverUpdateModel({
      chaseIds: this.chaseIdList,
    });
  }

  getUsers() {
    if (this.addressDetailState.masterDocumentSourceId) {
      this.addressesQueueService.getRetrievalUsers(this.addressDetailState.masterDocumentSourceId, this.addressDetailState.isSpecialHandling)
        .subscribe(users => {
          ArrayHelper.addItemToSelect(users, "Unassigned", 0);
          if (this.isCurrentUserAvailable(users)) {
            ArrayHelper.addItemToSelect(users, "@Me", this.authService.userId, users.filter(x => x.value === this.authService.userId)[0]?.text);
          }
          this.assignedToInput = new Autocomplete({ ...this.assignedToInput, options: users } as any);
          this.changeDetector.markForCheck();
        });
    }
  }
  private isCurrentUserAvailable(users: SelectableInput[]): boolean {
    return ArrayHelper.isAvailable(users.filter(user => user.value === this.authService.userId));
  }

  get isUserHasEmployeeOrReadOnlyRole(): boolean {
    return ((this.user.isReadOnlyRole || this.user.isEmployeeRole) && (!this.user.isAdminRole || !this.user.isManagerRole || !this.user.isLeadRole));
  }

  onIsNewAddress($event: CreateAddress): void {
    this.isContactInfoModalVisible = true;
    this.newAddressModel = $event;
    this.isAddressModalVisible = false;
    this.isNewAddress = true;
  }

  onMoveChasesConfirmation(event: Date): void {
    this.isMoveVisible = true;
    this.commitmentDate = event;
  }

  get hasRouteParamaters(): boolean {
    if (this.routeParameters == null) {
      return false;
    }
    const parametersWithValues = Object.values(this.routeParameters).filter(x => StringHelper.isAvailable(x));
    return ArrayHelper.isAvailable(parametersWithValues);
  }

  private getRouteParamterValue(name: string): string {
    return this.hasRouteParamaters ? this.routeParameters[name] : "";
  }

  updateSetCommitmentDate(): void {

    if (!DateHelper.isAvailable(this.commitmentDate)) {
      return;
    }

    this.commitDateUpdateModel = new CommitmentDateUpdateModel({
      chaseIds: this.chaseIdList,
      functionalRoleId: FunctionalRole.DOCUMENTRETRIEVAL,
      retrievalLocationId: this.addressId,
    });

    const formattedDate = DateHelper.format(this.commitmentDate);

    if (!this.isThirdParty) {
      this.commitDateUpdateModel.commitmentDate = formattedDate === "" ? null : formattedDate;
    } else {
      this.commitDateUpdateModel.requestDate = DateHelper.format(this.commitmentDate);
    }
    if (ArrayHelper.isAvailable(this.commitDateUpdateModel.chaseIds)) {
      this.commitmentDateService.updateCommitmentDate(this.commitDateUpdateModel).subscribe(
        data => {
          this.messagingService.showToast("Commitment date updated successfully.", SeverityType.SUCCESS);
        },
        err => {
          this.messagingService.showToast("Error while updating the commitment date", SeverityType.ERROR);
        }
      );
    } else {
        this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
        return;
    }

    this.changeDetector.markForCheck();
  }
}
