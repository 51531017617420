export enum  DocumentValidateResponseStatus {

  NotProcessed = 0,
  Validated = 1,
  NoMatch = 2,
  Error = 3,
  DocumentAttachedAlready = 4,
  ChaseClosed = 5,
  DocumentUploaded = 6,
  ChaseReOpened = 7,
  DocumentDeleted= 8,

}
