import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { NumeratorListItem } from "../../../api/numerator/numerator-list-item.model";
import { ContactMethodType } from "./contact-method-type.enum";

export interface IContactRequestOptions {
  documentSourceId: number;
  from: string;
  fromName: string;
  to: string | number;
  toName: string;
  chaseIds: any[];
  templateName: string;
  toPhone: string; // NOTE: Keeping in case it is being used by some queue.
  chaseNumeratorList?: NumeratorListItem[];
}

export abstract class ContactRequest implements IContactRequestOptions {
  documentSourceId: number;
  from: string;
  fromName: string;
  to: string | number;
  toName: string;
  chaseIds: number[];
  templateName: string;
  toPhone: string; // NOTE: Keeping in case it is being used by some queue.
  chaseNumeratorList?: NumeratorListItem[];

  abstract readonly toLabel: string;
  abstract readonly toValue: string;
  abstract readonly isValid: boolean;
  abstract readonly type: ContactMethodType;


  constructor(options: Partial<IContactRequestOptions>) {
    this.documentSourceId = options.documentSourceId;
    this.from = StringHelper.clean(options.from);
    this.fromName = StringHelper.clean(options.fromName);
    this.to = options.to;
    this.toName = StringHelper.clean(options.toName);
    this.chaseIds = ArrayHelper.clean(options.chaseIds);
    this.templateName = StringHelper.clean(options.templateName);
    this.toPhone = StringHelper.clean(options.toPhone);
    this.chaseNumeratorList = ArrayHelper.clean(options.chaseNumeratorList);
  }


  get totalChases(): number {
    return ArrayHelper.clean(this.chaseIds).length;
  }

  get typeName(): string {
    return ContactMethodType[this.type];
  }
}
