import { FormGroup } from "@angular/forms";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { DropdownComponent } from "./dropdown.component";

export interface IDropdown extends IDynamicInputOptions {
  placeholder?: string;
  options?: SelectableInput[];
  tooltip?: string;
  hasTooltipLabel?: boolean;
  autoDisplayFirst?: boolean;
  hasGroup?: boolean;
  clear?: boolean;
}

export class Dropdown extends DynamicInput implements IDropdown {
  placeholder: string;
  options: SelectableInput[];
  tooltip: string;
  hasTooltipLabel: boolean;
  autoDisplayFirst?: boolean;
  hasGroup: boolean;
  clear: boolean;
  readonly controlType = "dropdown";
  readonly componentType = DropdownComponent;

  constructor(options: IDropdown = {}) {
    super(options);
    this.clear = BooleanHelper.tryGet(options.clear, false);
    this.placeholder = StringHelper.clean(options.placeholder);
    this.options = ArrayHelper.isAvailable(options.options) ? options.options : [];
    this.tooltip = options.tooltip;
    this.hasTooltipLabel = BooleanHelper.tryGet(options.hasTooltipLabel, false);
    this.autoDisplayFirst = BooleanHelper.tryGet(options.autoDisplayFirst, true);
    this.hasGroup = BooleanHelper.tryGet(options.hasGroup, false);
  }


  getValue(form: FormGroup): string {
    if (form == null) {
      throw new Error("No form is present");
    }

    const value = form.get(this.getMasterKey()).value;
    return value;
  }

  getSelectedOption(form: FormGroup): SelectableInput {
    const value = this.getValue(form);
    return StringHelper.isAvailable(value) || NumberHelper.isAvailable(value as any)
      ? this.options.find(option => option.value === value)
      : null;
  }
}
