import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapProjectFileResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProjectFileResponse")
    .forMember("fileName", o => o.fileName)
    .forMember("message", o => o.message)
    .forMember("uploaded", o => o.uploaded);
};

export class ProjectFileResponse {
  readonly fileName: string;
  readonly message: string;
  readonly uploaded: boolean;

  constructor(options: {
    fileName: string;
    message: string;
    uploaded: boolean;
  }) {
    this.fileName = options.fileName;
    this.message = options.message;
    this.uploaded = options.uploaded;
  }
}
