import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { MemberValidation } from "./member-validation.model";

@Injectable({
  providedIn: "root",
})
export class MemberValidationService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  getData(chaseId: number, status: string): Observable<MemberValidation> {
    // TODO: Add projectTypeName when there are more project types.
    const url = `${this.baseApiUrl}membervalidation/get?chaseId=${chaseId}&workflowStatusName=${status}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "MemberValidation"))
    );
  }

  submit(memberValidation: MemberValidation, status: string, isMemberCentricChase?: boolean): Observable<MemberValidation> {
    const url = `${this.baseApiUrl}membervalidation/submit?status=${status}&isMemberCentricChase=${isMemberCentricChase}`;
    const data = memberValidation.saveInfo();
    return this.http.post(url, data).pipe(
      map(this.automapper.curry("default", "MemberValidation"))
    );
  }
}
