import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { SubSink } from "subsink";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { IFilter } from "../basic-filter-applied/basic-filter-applied.component";
import { GridDirective } from "../grid-component.model";
import { GridView } from "../grid-menu/grid-views/grid-view.model";
import { GridStateService } from "../grid-state.service";
import { GridService } from "../grid.service";
import { ILazyLoadEvent } from "../models/api";
import { GridColumnDefinition } from "../models/grid-column-definition.model";
import { GridConfiguration } from "../models/grid-configuration.model";
import { GridState } from "../models/grid-state.model";

@Component({
  selector: "app-basic-grid",
  templateUrl: "./basic-grid.component.html",
  styleUrls: ["../grid.component.scss"],
  animations: [
    trigger("slideOutAnimation", [
      transition(":leave", [
        style({ opacity: 1 }),
        animate("500ms ease-in", style(
          { opacity: 0, transform: "translate3d(100%, 0, 0)" }
        )),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicGridComponent extends GridDirective implements OnInit, OnDestroy {
  @Input() filterHeaderText = "";
  @Input() loading = false;
  @Input() data: any[];
  @Input() first = 0;
  currentPage = 1;
  @Input() request: IFilter[];
  @Input() isAnimationDisabled = true;
  @Input() requestUrl = "";
  @Input() exportDataFromUrl = false;
  @Input() urlOptions: any;
  @Output() removeFilter = new EventEmitter<IFilter>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() rowSelection = new EventEmitter<any>();
  private sink = new SubSink();
  @Input() refresh = new EventEmitter<ILazyLoadEvent>();
  defaultPageSize: number;
  options: SelectableInput[];
  @Input() resizableColumns = true;
  @Input() isChartLakePageSize = false;
  @Input() addressId: number;
  constructor(
    private gridService: GridService,
    private changeDetector: ChangeDetectorRef,
    private readonly gridStateService: GridStateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.refresh != null) {
      this.sink.add(
        this.refresh
          .pipe(debounceTime(500))
          .subscribe(this.loadData.bind(this))
      );
    }

    this.sink.add(
      this.onViewSelect.subscribe(this.updateConfiguration.bind(this))
    );
    if (!this.hasViews && this.stateKey) {
      this.saveState();
    }
    this.defaultPageSize = this.configuration.pageSize;
    if (this.isChartLakePageSize) {
      this.defaultPageSize = 5;
    }
    this.options = this.configuration.pageSizeSelectableInputs;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onRemoveFilter(event: IFilter) {
    if (this.hasStateKey) {
      const state = this.gridStateService.get(this.stateKey);
      if (event.reset) {
        state.basicGridState.basicGridRequest = [];
        this.gridStateService.put(this.stateKey, state);
      } else {
        const index = state.basicGridState.basicGridRequest.findIndex(x => x.value === event.value);
        state.basicGridState.basicGridRequest.splice(index, 1);
        this.gridStateService.put(this.stateKey, state);
      }
    }
    this.removeFilter.emit(event);
  }

  get totalRecords(): number {
    const data = ArrayHelper.toArray(this.data);
    if (ArrayHelper.isAvailable(data)) {
      const enabledRows = data.filter(x => !x.disabled);
      this.configuration.isHeaderCheckBoxDisabled = enabledRows.length === 0;
    }
    return ArrayHelper.isAvailable(data) ? data.length : 0;
  }

  get initialPageSize(): number {
    return this.defaultPageSize;
  }

  setPageByIndex(index: number): void {
    const firstRowIndex = this.gridService.getPageBySelectedRowIndex(this.configuration, index);
    this.primeGrid.first = firstRowIndex;
    this.changeDetector.markForCheck();
  }

  selectableRowData(rowData): any {
    return rowData.disabled ? null : rowData;
  }

  onSelectionChange(selectedRowData): void {
   if (ArrayHelper.isAvailable(selectedRowData)) {
      this.selection = selectedRowData.filter(x => !x.disabled);
      this.changeDetector.markForCheck();
      this.selectionChange.emit(this.selection);
    } else {
      this.selectionChange.emit(selectedRowData);
    }
  }


  loadData(event: ILazyLoadEvent = {}): void {
     this.saveState();
  }

  onRowClick(event: any): void {
    this.rowClick.emit(event);
    this.rowSelection.emit(this.first);
  }

  private updateConfiguration(gridView: GridView): void {
    this.configuration.columns.forEach(a => {
      const meta = gridView.columns[a.field] || { show: false };
      a.show = meta.show;
    });
    this.configuration = new GridConfiguration(this.configuration);
    this.saveState();
    this.changeDetector.markForCheck();
  }

  private saveState(): void {
    const configurationWithoutTemplateProperty = new GridConfiguration({
      ...this.configuration,
      columns: this.configuration.columns.map(({ template, ...item }) => new GridColumnDefinition(item)),
    });

    if (configurationWithoutTemplateProperty.isInit && this.hasStateKey && !this.configuration.isStateDisabled) {

      setTimeout(() => {
        const state = new GridState({
          configuration: this.configuration,
          selectedView: this.hasViews ? this.views.selectedView : null,
        });

        const existingState = this.gridStateService.get(this.stateKey);
        state.basicGridState = existingState ? existingState.basicGridState : null;
        this.request = existingState?.basicGridState.basicGridRequest ? existingState.basicGridState.basicGridRequest : [];

        this.gridStateService.put(this.stateKey, state);
      });
    }
  }

  paginate(event) {
    this.currentPage = (event.first / event.rows);
    this.first = event.first;
  }

  pageSizeChange(): void {
    this.first = 0;
    this.refresh.emit();
  }

  getRowClass(rowIndex: number, rowData: any): string {
    let cssClass = "";

    cssClass += rowIndex % 2 !== 0 ? "highlight-even-row" : "";
    cssClass += rowData.disabled ? " disable-row" : "";

    return cssClass;
  }
}
