<div class="project-bulk-container">

  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid">
      <div class="ui-g-6 ui-md-6 ui-lg-6">
        <div class="label-container">
          <label class="label" for="user">Notes<span class="error-icon"><app-icon class="error-icon" iconName="question-circle" *ngIf="isShowNotes" [title]="notesErrorMessages"></app-icon></span></label>
        </div>
        <form-factory [formGroup]="formGroup" [model]="chasesNotes" (onInput)="checkNotes()"></form-factory>
      </div>
    </div>

    <div *ngIf="isBulkPendUpdate">
      <div class="ui-g ui-fluid">
        <div class="ui-g-3 ui-md-3 ui-lg-3">
          <form-factory [formGroup]="formGroup" [model]="invoiceNumberInput"></form-factory>
        </div>
      </div>

      <div class="ui-g ui-fluid">
        <div class="ui-g-3 ui-md-3 ui-lg-3">
          <form-factory [formGroup]="formGroup" [model]="invoiceAmountInput"></form-factory>
        </div>
      </div>

      <div class="ui-g ui-fluid">
        <div class="ui-g-3 ui-md-3 ui-lg-3">
          <form-factory [formGroup]="formGroup" [model]="pendCompanyInput"></form-factory>
        </div>
      </div>
    </div>

    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <app-button text="CONTINUE TO VALIDATION" (onClick)="validate()"></app-button>
      </div>
    </div>

  </form>

  <div class="ui-g ui-fluid" *ngIf="isShowValidateGrid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-basic-grid [configuration]="serverGridConfiguration"
                       [data]="data">
      </app-basic-grid>
    </div>
    <div class="ui-g ui-fluid">
      <app-button (onClick)="confirmBulkUpdate()" text="FINISH BULK UPDATE" [disabled]="isFinalBulkUpdate"></app-button>
    </div>
  </div>
</div>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isCancelConfirmationModalVisible"
  header="Close the Chase(s)?"
  (onYes)="finalBulkUpdate()"
  (onCancel)="cancelConfirmation($event)">
  Closing the Pend(s) will also close the associated Chase(s). Those chases will no longer continue to be worked. Do you want to proceed?
</app-confirmation-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isResolveConfirmationModalVisible"
  header="Resolve the Pend(s)?"
  (onYes)="finalBulkUpdate()"
  (onCancel)="cancelConfirmation($event)">
  Resolving the Pend(s) will let work continue on the Chase(s). This should be done once the obstacle preventing the Chase(s) from advancing has been resolved. Do you want to proceed?
</app-confirmation-modal>


