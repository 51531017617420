import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { Observable, Subject } from "rxjs";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { UserService } from "../../../../core/user/user.service";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ButtonAction } from "../../../../shared/button/button-action.model";
import { ListItem } from "../../../../shared/list/list-item";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { PhoneNumberPipe } from "../../../../shared/pipes/phone.pipe";
import { TagType } from "../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ChaseUnasssignModel } from "../../../api/unassign-chase/chase-unassign-model";
import { UnassignChaseService } from "../../../api/unassign-chase/unassign-chase.service";
import { ICanComponentDeactivate } from "../../../api/url-guards/can-deactivate.guard";
import { WorkflowStatusDb } from "../../../api/workflow/workflow-status-db.enum";
import { AuditService } from "../../audit/audit.service";
import { RetrievalService } from "../../dashboard/retrieval/retrieval.service";
import { AccessInfo } from "../access-info.model";
import { AddressTimelineStateService } from "../address-timeline/address-timeline-state.service";
import { CallFlowComponent } from "../call-flow/call-flow.component";
import { CallType } from "../call-flow/call-type.enum";
import { Contact } from "../contact.model";
import { PageTypeName } from "../retreival-document-review/page-type.enum";
import { RetrievalAddressDetailContactHistoryService } from "./address-detail-contact-history/address-detail-contact-history.service";
import { AddressDetailInfoContactService } from "./address-detail-info/address-detail-info-contact/address-detail-info-contact.service";
import { AddressDetailState } from "./address-detail-state.model";
import { AddressDetailStateService } from "./address-detail-state.service";
import { AddressDetailService } from "./address-detail.service";

@Component({
  selector: "retrieval-address-detail-new",
  templateUrl: "./address-detail.component.html",
  styleUrls: ["./address-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailComponent implements ICanComponentDeactivate, OnInit, OnDestroy {
  navigateAwaySelection: Subject<boolean> = new Subject<boolean>();
  private phonePipe = new PhoneNumberPipe();
  private sink = new SubSink();
  addressId: number;
  menuItems = List<MenuItem>();
  splitButtonOptions: ButtonAction[] = [];
  showTimeline = true;
  contactMethodPhone = 1;
  showCallFlowAndDialpad = false;
  freeFormDialpadOnly: boolean;
  addressStateContactPhone: string;
  primaryPhoneNumber: string;
  assignedTo: number;
  user: UserToken;
  callType: string;
  callConnected = false;
  callInitiatedAtAddress = false;
  private previousMasterDocumentSourceId = 0;
  private chaseIdsForUnassignment: number[];
  private documentRetrieval = 5;   // Document Retrieval. TODO: Later create enum for functionalRoleIds
  private callNotesSaved = false;
  hasCallNotesButton = false;
  showCallNotesModal = false;
  private retrievalType: number;
  showConfirmChangeNavigationToNonAidPage = false;
  addressStatistics: List<ListItem>;
  tagType = TagType.MASTERDOCUMENTSOURCE;
  objectId: number;
  allAvailableTags: SelectableInput[];
  previousRetrievalType: number;
  specialHandlingReason: string;
  specialHandlingAdditionalNote: string;
  isSpecialHandling: boolean;
  isNavigatedFromSpecialHandling = false;
  hasRouteShParameter = false;
  providerDetails = null;
  providerCall = false;
  isValidAddressId = false;

  gridSearchRequest: any;
  @ViewChild("callflowComponent", { static: false }) callFlowComponent: CallFlowComponent;

  constructor(
    private router: Router,
    private readonly addressDetailService: AddressDetailService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private retrievalService: RetrievalService,
    private parameterService: ParameterService,
    private chaseUnassignService: UnassignChaseService,
    private retrievalAddressDetailContactHistoryService: RetrievalAddressDetailContactHistoryService,
    private readonly route: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService,
    private addressTimelineStateService: AddressTimelineStateService,
    private readonly tagService: TagService,
    private userService: UserService,
    private auditService: AuditService,
    private readonly addressDetailContactService: AddressDetailInfoContactService
  ) { }

  ngOnInit() {
    this.userAuditLog();
    this.addressId = this.parameterService.getNumberNormal("addressId", null);
    this.callType = this.parameterService.getNormal("calltype", "");
    this.objectId = this.addressId;
    // this.getAllTagsList(); TODO: This will be a part of next release
    this.user = this.userService.getUserToken();
    if (this.callType === CallType.Incoming) {
      this.toggleDialpad();
    }

    this.addressDetailService.initiateCallProvider(null);
    this.addressDetailContactService.setAddressContact(null);

    this.sink.add(
      this.route.paramMap.subscribe(params => {
        this.addressId = this.parameterService.getNumberNormal("addressId", null);
        this.addressDetailService.getAddressDetail(this.addressId).subscribe({
          next: addressDetail => {
            this.isValidAddressId = true;
            if (this.previousMasterDocumentSourceId !== addressDetail.masterDocumentSourceId) {
              this.addressDetailService.clearProviderSearchResults(this.previousMasterDocumentSourceId);

              this.addressDetailStateService.resetData({
                ...addressDetail,
                accessInfo: new AccessInfo({ ...addressDetail }),
                contact: new Contact({ ...addressDetail, isPrimaryContact: true }),
              });
              this.isSpecialHandling = addressDetail.isSpecialHandling;
              this.specialHandlingReason = addressDetail.specialHandlingText;
              this.specialHandlingAdditionalNote = addressDetail.specialHandlingComment;
              this.addressDetailStateService.setData({ openChaseCount: addressDetail.openChaseCount, totalChaseCount: addressDetail.totalChaseCount});
              this.addressTimelineStateService.timelineItems.next([]);
              if (NumberHelper.isGreaterThan(this.previousMasterDocumentSourceId, 0)) {
                this.addressDetailService.loadChaseGrid(true);
              }
            }

            this.previousMasterDocumentSourceId = addressDetail.masterDocumentSourceId;
            this.previousRetrievalType = addressDetail.documentSourceTypeId;
            this.changeDetector.markForCheck();
          },
          error: error => this.messagingService.showToast(error, SeverityType.ERROR),
        });
      }),

      this.addressDetailStateService.state.subscribe(state => {
        this.retrievalType = state.documentSourceTypeId;
        this.isSpecialHandling = state.isSpecialHandling;
        this.specialHandlingReason = state.specialHandlingText;
        this.specialHandlingAdditionalNote = state.specialHandlingComment;
        this.addressStateContactPhone = state.contact.contactPhone;

        this.getAddressDetailStatistics(state);

        if (ArrayHelper.isAvailable(state.chaseGridData)) {
          this.chaseIdsForUnassignment = state.chaseGridData.filter(
            c => c.workflowStatusId === WorkflowStatusDb.ChartCollection || c.workflowStatusId === WorkflowStatusDb.WaitingForChart)
            .map(x => x.chaseID);
        }
      }),

      this.retrievalAddressDetailContactHistoryService.hasCallNotesButton.subscribe(value => {
        this.hasCallNotesButton = value;
      }),

      this.addressDetailContactService.addressContactChanged$.subscribe(value => {
        this.primaryPhoneNumber = value?.contactPhone;
      }),

      this.addressDetailService.callProvider
        .subscribe(data => {
          this.providerDetails = data;
          if (data != null && data != undefined) {
            if (StringHelper.isAvailable(data.phoneNumber)) {
              this.providerCall = true;
              this.toggleDialpad(false);
            }
          }
        }),

      this.addressDetailService.resetProviderCall
        .subscribe(reset => {
          if (reset) {
            this.showCallFlowAndDialpad = false;
            this.callType = CallType.Outgoing;
            this.providerDetails = null;
            this.providerCall = false;
          }
        }),

      this.addressDetailService
        .getMenuItems()
        .subscribe(this.assignAndNotifyMenuItems)
    );
    this.checkRouteHasShParameter();
  }

  ngOnDestroy() {
    this.addressDetailStateService.clearData();
    this.sink.unsubscribe();
  }
  userAuditLog(): void {
    const url = window.location.href;
    this.auditService.userAuditLog(PageTypeName.AddressDetail, url)
   .subscribe(() => {
     });
}
  checkRouteHasShParameter(): void {
    const urlSegments = this.router.url.split("/");
    if (ArrayHelper.isAvailable(urlSegments)) {
      this.hasRouteShParameter = urlSegments.includes("sh");
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.callConnected || (this.callInitiatedAtAddress && !this.callNotesSaved)) {
      setTimeout(() => {
        this.callFlowComponent.showConfirmCloseCallFlow();
        this.changeDetector.markForCheck();
      }
      ,          300);
      if (!this.callConnected) {
        return this.callFlowComponent.validateCallNotesFormToAllowRedirection();
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  continueNavigation(): void {
    this.showConfirmChangeNavigationToNonAidPage = false;
    this.navigateAwaySelection.next(true);
  }

  cancelNavigation(): void {
    this.navigateAwaySelection.next(false);
  }

  toggleDialpad(freeFlowDialpad: boolean = false) {
    if (this.user.isReadOnlyRole) {
      this.messagingService.showMessage("This action cannot be performed by a Read-Only User", SeverityType.ERROR);
      return;
    }
    this.changeDetector.markForCheck();

    if (this.callConnected || (this.callInitiatedAtAddress && !this.callNotesSaved)) {
      this.callFlowComponent.showConfirmCloseCallFlow();
      return;
    } else {
      this.showCallFlowAndDialpad = !this.showCallFlowAndDialpad;

      this.freeFormDialpadOnly = freeFlowDialpad;

      if (this.showCallFlowAndDialpad && this.showTimeline) {
        this.toggleTimeline();
        this.callInitiatedAtAddress = false;
        this.callNotesSaved = false;
      } else if (!this.showCallFlowAndDialpad) {
        this.callType = CallType.Outgoing;
        this.providerDetails = null;
        this.providerCall = false;
      }
    }
  }

  toggleTimeline() {
    this.showTimeline = !this.showTimeline;
  }

  getAddressDetailStatistics(state: AddressDetailState): void {
    const statistics = [
      new ListItem({
        key: "TOTAL CHASE COUNT",
        value: state.totalChaseCount != undefined ? state.totalChaseCount.toString() : "0",
      }),
      new ListItem({
        key: "OPEN CHASE COUNT",
        value: state.openChaseCount != undefined ? state.openChaseCount.toString() : "0",
      }),
      new ListItem({
        key: "TOTAL CALLS",
        value: state.totalCalls != undefined ? state.totalCalls.toString() : "0",
      }),
      new ListItem({
        key: "TOTAL UNIQUE DAYS CALLED",
        value: state.totalUniqueDaysCalled != undefined ? state.totalUniqueDaysCalled.toString() : "0",
      }),
      new ListItem({
        key: "TOTAL EMAILS",
        value: state.totalEmails != undefined ? state.totalEmails.toString() : "0",
      }),
      new ListItem({
        key: "TOTAL FAXES",
        value: state.totalFaxes != undefined ? state.totalFaxes.toString() : "0",
      }),
      new ListItem({
        key: "TOTAL NOTES",
        value: state.totalNotes != undefined ? state.totalNotes.toString() : "0",
      }),
      // TODO: Reinstate when there are Gap Projects, post HEDIS
      // new ListItem({
      //  key: "OPEN GAP PURSUITS",
      //  value: state.openGapPursuitsCount != undefined ? state.openGapPursuitsCount.toString() : "0",
      // }),
    ];

    this.addressStatistics = List(statistics);
  }

  get isTimelineTab(): boolean {
    return this.router.url.includes("timeline");
  }

  get isCommentsTab(): boolean {
    return this.router.url.includes("comments");
  }

  get isContactHistoryTab(): boolean {
    return this.router.url.includes("contactHistory");
  }

  get selectedPhoneNumber(): string {
    return this.phonePipe.transform(StringHelper.isAvailable(this.primaryPhoneNumber)
      ? this.primaryPhoneNumber
      : this.addressStateContactPhone);
  }

  get timelineBtnTextValue(): string {
    return this.showTimeline ? "HIDE TIMELINE" : "ADDRESS TIMELINE";
  }

  get mainWidthClass(): string {
    return (this.showTimeline || this.showCallFlowAndDialpad) ? "route-container--min" : "route-container--max";
  }

  get timelineWidthClass(): string {
    const containerClass = !this.isCommentsTab ? (this.isContactHistoryTab ? "tab__contactHistory" : "") : "tab__comments";
    return (this.showTimeline && !this.showCallFlowAndDialpad && !this.isTimelineTab) ? `${containerClass} timeline-container--open` : `${containerClass} timeline-container--close`;
  }

  get timelineBtnClass(): string {
    let className = "";
    className += this.showTimeline && !this.showCallFlowAndDialpad && !this.isTimelineTab ? "timeline-container__btn--open" : "timeline-container__btn--close";

    const shClass = this.isSpecialHandling ? "specialHandling" : "nonSH";
    const tabClass = !this.isCommentsTab ? (this.isContactHistoryTab ? "contactHistory" : "info") : "comments";
    className += (this.showTimeline && !this.showCallFlowAndDialpad && !this.isTimelineTab) ? ` vertical-button ${shClass}__${tabClass}--open button` : ` vertical-button ${shClass}__${tabClass}--close button`;

    return className;
  }

  get disableCall(): boolean {
    return this.callConnected;
  }

  get showNextAddress(): boolean {
    return this.callNotesSaved && !this.callConnected && !this.isSpecialHandling;
  }

  closeCallflow(): void {
    this.callInitiatedAtAddress = false;
    if (this.showCallFlowAndDialpad) {
      this.toggleDialpad();
    }
  }

  onCallConnection(event: boolean): void {
    this.callConnected = event;
    if (this.callConnected) {
      this.callNotesSaved = false;
      this.callInitiatedAtAddress = true;
    } else if (!this.callConnected && this.callNotesSaved) {
      this.callInitiatedAtAddress = false;
      this.toggleDialpad();
      this.toggleTimeline();
      this.changeDetector.markForCheck();
    }
  }

  private assignAndNotifyMenuItems = (data: MenuItem[]): void => {
    this.menuItems = List(data);
    this.changeDetector.markForCheck();
  }

  getNextAddress(): void {
    if (ArrayHelper.isAvailable(this.chaseIdsForUnassignment)) {
      this.unAssignChasesAtAddressLocation();
    }
    const retrievalType = this.previousRetrievalType !== this.retrievalType ? this.previousRetrievalType : this.retrievalType;
    this.retrievalService.getNextAddressId(retrievalType)
      .subscribe((addressId: number) => {
        if (addressId !== 0 && addressId != null) {
          this.callNotesSaved = false;
          let url = `retrieval/addressdetail/${addressId}`;
          if (this.hasRouteShParameter) {
            url += "/sh";
          }

          this.router.navigateByUrl(url);

        } else {
          this.messagingService.showToast("No Additional Work found to Assign.", SeverityType.INFO);
          this.changeDetector.markForCheck();
        }
      });
  }

  private unAssignChasesAtAddressLocation(): void {
    const unasssignModel = new ChaseUnasssignModel({
      chaseList: this.chaseIdsForUnassignment,
      functionalRoleId: this.documentRetrieval,
      masterDocumentSourceId: this.addressId,
    });

    this.chaseUnassignService.unassignChases(unasssignModel).subscribe(
      data => {
        this.messagingService.showToast("Chases(s) have been unassigned.", SeverityType.SUCCESS);
      },
      err => {
        this.messagingService.showToast("Error while unassigning Chases(s).", SeverityType.ERROR);
      });

  }

  onCallNotesSaved(): void {
    this.callNotesSaved = true;
    if (!this.callConnected) {
      this.callInitiatedAtAddress = false;
      this.toggleDialpad();
      this.toggleTimeline();
      this.changeDetector.markForCheck();
    }
  }

  showCallNotes(): void {
    this.showCallNotesModal = true;
  }

  getAllTagsList(): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null)
      .subscribe(data => {
        this.allAvailableTags = data
          .filter(e => e.text === "Suggestions")
          .concat(
            data.filter(e => e.text !== "Suggestions")
              .sort((a, b) => a.text.localeCompare(b.text))
          );
      });
  }

}
