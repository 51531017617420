import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-create-steps-menu",
  templateUrl: "./create-steps-menu.component.html",
  styleUrls: ["./create-steps-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateStepsMenuComponent {
    constructor(
        private router: Router) {
        }


    showStepsMenuScreen() {
        this.router.navigate(["members", "chase-flow"]);
    }
}
