import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IAnnotationHighlight } from "../../platform/widget/annotations/annotation-highlight.model";
import { MenuItem } from "./menu-item.model";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private isOpen = new BehaviorSubject<boolean>(false);
  private isWidgetOpen = new BehaviorSubject<boolean>(false);
  private isNewAnnotation = new BehaviorSubject<IAnnotationHighlight>({} as any as IAnnotationHighlight);
  private updateHighlightId = new BehaviorSubject<string>("");
  selectedWidget = new BehaviorSubject<string>("");
  private openSelectedMenuItem$ = new Subject<MenuItem>();

  showHeader = new BehaviorSubject<boolean>(true);
  isOpen$ = this.isOpen.asObservable();
  isWidgetOpen$ = this.isWidgetOpen.asObservable();
  isNewAnnotation$ = this.isNewAnnotation.asObservable();
  updateHighlightId$ = this.updateHighlightId.asObservable();
  selectedWidget$ = this.selectedWidget.asObservable();

  toggleMenuState(buttonState: boolean) {
    this.isOpen.next(buttonState);
  }

  toggleWidgetState(buttonState: boolean) {
    this.isWidgetOpen.next(buttonState);
  }

  openNewAnnotation(annotationHighlight: IAnnotationHighlight) {
    this.isNewAnnotation.next({
      createNewAnnotation: annotationHighlight.createNewAnnotation,
      highlightId: annotationHighlight.highlightId,
      page: annotationHighlight.page,
    });
  }

  newHighlightId(highlightId: string) {
    this.updateHighlightId.next(highlightId);
  }

  openMenuItem$(): Observable<MenuItem> {
    return this.openSelectedMenuItem$.asObservable();
  }

  openSelectedMenuItem(menuItem: MenuItem): void {
    this.openSelectedMenuItem$.next(menuItem);
  }
}
