import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-icon-twitter",
  template: `<a
                href="{{url}}"
                target="_blank"
                class="fab fa-twitter"></a>`,
  styleUrls: ["./icon-social-media.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTwitterComponent {

  get url(): string {
    return "https://twitter.com/reveleer";
  }
}
