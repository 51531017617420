import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { DocumentViewerSessionService } from "../../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { ProjectConfiguration } from "../../../../../project/project-config/project-configuration.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { ChartService } from "../../chart.service";
import { Diagnosis } from "../diagnosis/diagnosis.model";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-delete-diagnosis",
  templateUrl: "./delete-diagnosis.component.html",
  styleUrls: ["./delete-diagnosis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDiagnosisComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private unsubscribe = new Subject();
  @Input() diagnosis: Diagnosis;
  index: number;
  enabled: boolean;
  isConfirmDeleteVisible = false;
  enableMrrBot = "";
  projectConfiguration: ProjectConfiguration;
  diagnoses: Diagnosis[];
  selection: Diagnosis;


  constructor(
    private automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private chartService: ChartService,
    private chaseDetailStateService: ChaseDetailStateService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private messagingService: MessagingService,
    private riskService: RiskService
  ) { }

  ngOnInit() {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        const selectedEncounterIsSaved = NumberHelper.isGreaterThan(riskState.selectedEncounter.entityId, 0);
        this.enabled = riskState.isEnabled && riskState.isSelectedEncounterValid && selectedEncounterIsSaved;
        const diagnosisEntities = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
        this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
        this.selection = riskState.hasSelectedDiagnosisIndex ? this.diagnoses[riskState.selectedDiagnosisIndex] : {} as any;

        if (riskState.hasSelectedEncounterIndex) {
          this.index = riskState.selectedEncounter.diagnoses.findIndex(diagnosisItem => diagnosisItem.id === this.diagnosis.id);
        }

        this.projectConfiguration = chaseDetailState.projectConfiguration;
        this.updateProjectConfiguration();

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get isAdminDiagnosis(): boolean {
    if (this.diagnosis == null) {
      return false;
    }

    return this.diagnosis.isAdmin;
  }

  get isBotDiagnosis(): boolean {
    if (this.diagnosis == null) {
      return false;
    }

    return this.diagnosis.isBotSource;
  }

  get isEnableMrrBot(): boolean {
    return this.enableMrrBot === "1";
  }

  showDeleteDiagnosisModal(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.enabled && !this.isAdminDiagnosis) {
      this.isBotDiagnosis ? this.isConfirmDeleteVisible = true : this.deleteDiagnosis();
    }
  }

  deleteDiagnosis(): void {
    this.isConfirmDeleteVisible = false;
    const diagnosisDeleting = this.riskService.data.value.selectedEncounter.diagnoses[this.index];
    if (NumberHelper.isGreaterThan(diagnosisDeleting.entityId, 0)) {
      this.chartService.delete(diagnosisDeleting.attributes)
        .subscribe(
          () => {
            this.riskService.deleteDiagnosis(this.index);
            this.updateDiagnosisSelectionAndChart();
          },
          error => this.messagingService.showMessage("Try to delete the diagnosis again.", SeverityType.ERROR)
        );
    } else {
      this.riskService.deleteDiagnosis(this.index);
      this.updateDiagnosisSelectionAndChart();
    }
  }

  private updateProjectConfiguration(): void {
    if (this.projectConfiguration != null) {
      this.enableMrrBot =
        StringHelper.isAvailable(this.projectConfiguration.enableMrrBot) ? this.projectConfiguration.enableMrrBot : "0";
    }
  }

  private updateDiagnosisSelectionAndChart(): void {
    if (!this.isEnableMrrBot) {
      this.documentViewerSessionService.updateDataEntryPageNumber(this.selection.pageNumber);
    }
  }
}
