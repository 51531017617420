import { IAutoMapper } from "../interfaces";

export const mapUserAttributeModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "UserAttributeModel")
    .forMember("attributeId", o => o.attributeId)
    .forMember("attributeName", o => o.attributeName)
    .forMember("attributeValue", o => o.attributeValue);

};
