export enum FunctionalRole {
    NONE = 0,
    SYSTEMADMINISTRATOR = 1,
    ORGANIZATIONADMINISTRATOR = 2,
    PROJECTADMINISTRATOR = 3,
    DOCUMENTRETRIEVALLEAD = 4,
    DOCUMENTRETRIEVAL = 5,
    DOCUMENTINTAKELEAD = 6,
    DOCUMENTINTAKE = 7,
    DOCUMENTQALEAD = 8,
    DOCUMENTQA = 9,
    ABSTRACTOR = 10,
    OVERREAD = 11,
    CLIENTOVERREAD = 12,
    PSR = 13,
    FIELDTECH = 14,
    EMR = 15,
    RETRIEVALPEND = 16,
    MRR = 17,
    CLINICALPEND = 18,
    DATAMANAGER = 19,
    DATAREADER = 20,
    THIRDPARTY = 22,
    CLINICALLEAD = 25,
}
