import { IAutoMapper } from "../interfaces";

export const mapChasePageHistory = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChasePageHistory")
    .forMember("chaseId", o => o.chaseId)
    .forMember("pageType", o => o.pageType)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("action", o => o.action)
    .forMember("eventDate", o => o.eventDate)
    .forMember("userName", o => o.userName)
    .forMember("documentPageId", o => o.documentPageId)
    .forMember("documentName", o => o.documentName)
    .forMember("currentlyAssignedToThisChase", o => o.currentlyAssignedToThisChase);

};
