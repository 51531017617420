import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { UserGroupItem } from "./user-chat.model";
@Injectable({
  providedIn: "root",
})
export class UserChatService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getUserList(SearchCriteria: any): Observable<UserGroupItem[]> {
    const url = `${this.baseApiUrl}user/usersbygroup`;
    return this.http.post(url, SearchCriteria).pipe(
      map(this.automapper.curryMany("default", "UserGroupItem"))
    );
  }
}
