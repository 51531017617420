<p class="header">congrats!</p>
<p class="sub-header">you've successfully created a new Chase</p>

<div class="chase-detail text-center">
  <p>Your New Chase ID is:</p>
  <a [routerLink]= "['/members/chase/', newChaseId]" (click)="viewChaseDetail()">{{newChaseId}}</a>
</div>
<div class="chase-detail text-center" *ngIf=getMemberCentricParentChaseId>
  <p>Member Centric Parent Chase ID is:</p>
  <a [routerLink]= "['/members/chase/', parentChaseId]" (click)="viewMemberCentricParentChaseDetail()">{{parentChaseId}}</a>
</div>
