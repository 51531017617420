import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserToken } from "../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { PopoverComponent } from "../../../../../../shared/panel/popover/popover.component";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { ChaseSearchService } from "../../../../../api/chase-search/chase-search.service";
import { RetrievalService } from "../../../../dashboard/retrieval/retrieval.service";
import { ContactRecord } from "../../../psr/address-detail/address-detail-contact-record";
import { RetrievalCallFlowService } from "../../../retrieval-call-flow/retrieval-call-flow.service";
import { FtDetailEditService } from "../ft-detail-edit/ft-detail-edit.service";
import { FtEditModel } from "../ft-detail-edit/ft-edit-model";
import { FtDetailHeaderService } from "./ft-detail-header.service";

@Component({
  selector: "retrieval-ft-detail-header",
  templateUrl: "./ft-detail-header.component.html",
  styleUrls: ["./ft-detail-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDetailHeaderComponent implements OnInit, OnDestroy {
  @ViewChild("callPopOver", { static: true }) callPopOver: PopoverComponent;
  private unsubscribe = new Subject();

  ftId: number;
  user: UserToken;
  @Input() display = false;
  backButtonText = "Go Back";
  ftLocationText: string;
  menuItems = List<MenuItem>();
  isFtEditView = false;
  assignmentItems: any[];
  isAddressAssignmentView = false;
  isEmrAssignmentView = false;
  isFTAssignmentView = false;
  ftEditModel: FtEditModel;
  subMenuItemsAsArray: MenuItem[];
  isFinishCall = false;
  chaseList: number[];
  private twilioApiUrl;

  phoneNumber: string;
  twilioWidth = 220;
  twilioHeight = 310;
  private contactMethodPhone = 1;
  private actionSource = "TwilioDisconnect";

  constructor(
    private service: FtDetailHeaderService,
    private ftDetailservice: FtDetailEditService,
    private retrievalService: RetrievalService,
    private chaseService: ChaseSearchService,
    private messagingService: MessagingService,
    private userService: UserService,
    private callService: RetrievalCallFlowService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.ftId = this.service.getFTGdFromPath();
  }

  ngOnInit() {

    this.user = this.userService.getUserToken();

    this.route.paramMap.subscribe(params => {
      this.ftId = +params.get("FtGd");

      this.isFinishCall = false;

      this.service
        .getMenuItems()
        .subscribe(this.assignAndNotifyMenuItems);

      this.ftDetailservice
        .getFtDetailsForEdit()
        .subscribe(this.assignAndNotify);

      this.assignmentItems = [
        {
          label: "To PSR", command: () => {
            this.showAddressAssignmentModal();
          },
        },
        {
          label: "To Field Tech", command: () => {
            this.showFTAssignmentModal();
          },
        },
        {
          label: "To EMR", command: () => {
            this.showEmrAssignmentModal();
          },
        },
      ];

    });

    this.service.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => this.updateAddressHeader(id));

    this.service.resetHeaderMenu
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(tabVisisble => {
      this.updateHeaderMenu(tabVisisble);
      });

    this.chaseService.chaseIdsList
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(item => this.chaseList = item);

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get addressText() {
    return `Address Id ${this.ftId}`;
  }

  get twiliourl() {

    const contactRecordObject =
      new ContactRecord({
        addressId: this.ftId, organizationId: this.user.organizationId, callerUserId: this.user.userId, contactResultType: null,
        contactMethodType: this.contactMethodPhone, contactNumber: this.phoneNumber, chaseIds: this.chaseList,
      });

    const contactRecord = JSON.stringify(contactRecordObject);

    return `${this.twilioApiUrl}?contactRecord=${contactRecord}`;

  }

  updateAddressHeader(id) {
    this.ftDetailservice
      .getFtDetailsForEdit()
      .subscribe(this.assignAndNotify);

  }

  updateHeaderMenu(callTabVisible: boolean) {
    if (!ArrayHelper.isAvailable(this.subMenuItemsAsArray)) {
      setTimeout(() => {
        this.updateHeaderMenu(callTabVisible);
      },         50);
      return;
    }

    this.subMenuItemsAsArray.find(item => item.description === "Address Call").show = callTabVisible;
    if (callTabVisible === false) {
      this.isFinishCall = true;
    }
    this.assignAndNotifyMenuItems(this.subMenuItemsAsArray);
  }

  private assignAndNotifyMenuItems = (data: MenuItem[]): void => {
    const calltype = this.service.getCallTypeFromPath();
    const menuItems = data.map(item => {
      if (item.description === "Address Call" && item.path.indexOf("incoming") < 0 && item.path.indexOf("outgoing") < 0) {

        const path = `${item.path}/${calltype}`;
        return new MenuItem({ ...item, path } as any);
      } else {
        return item;
      }
    });

    this.subMenuItemsAsArray = menuItems;
    this.menuItems = List(menuItems);

    this.changeDetector.markForCheck();
  }

  private assignAndNotify = (data: FtEditModel): void => {

    this.ftEditModel = new FtEditModel(data);

    this.ftLocationText = `${this.ftEditModel.address1} ${this.ftEditModel.address2}, ${this.ftEditModel.addressCity},
                                ${this.ftEditModel.addressState} ${this.ftEditModel.addressZip}`;

    this.phoneNumber = this.ftEditModel.phone;
    this.changeDetector.markForCheck();

    // this.phoneNumber = "3476197023";
  }

  showCallPopover(event) {
    this.updateHeaderMenu(true);
    const calltype = "outgoing";
    this.router.navigateByUrl(`retrieval/ft/${this.ftId}/call/${calltype}`);
    this.display = true;
    this.callPopOver.show(event);
  }

  hideCallPopOver() {
    this.display = false;
    this.callPopOver.hide();
    this.changeDetector.markForCheck();
  }

  endCallOnHangup() {

    this.hideCallPopOver();

    const contactRecord =
      new ContactRecord({ addressId: this.ftId, organizationId: this.user.organizationId, contactResultType: null, contactMethodType: this.contactMethodPhone, contactNumber: null });


    this.callService
      .saveCallResult(contactRecord, this.actionSource)
      .subscribe();
  }

  getNextAddress(): void {
    const retrievalRoleFT = 3;
    this.retrievalService.getNextAddressId(retrievalRoleFT)
      .subscribe((ftId: number) => {
        if (ftId !== 0 && ftId !== null) {
          this.router.navigateByUrl(`retrieval/ft/${ftId}`);
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("No Additional Work found to Assign.", SeverityType.INFO);
          this.changeDetector.markForCheck();
        }
      });
  }

  receiveAddressAssignmentValue($event) {
    this.isAddressAssignmentView = $event;
  }

  showAddressAssignmentModal() {
    this.isAddressAssignmentView = true;
  }

  receiveEmrAssignmentValue($event) {
    this.isEmrAssignmentView = $event;
  }

  showEmrAssignmentModal() {
    this.isEmrAssignmentView = true;
  }

  receiveFTAssignmentValue($event) {
    this.isFTAssignmentView = $event;
  }

  showFTAssignmentModal() {
    this.isFTAssignmentView = true;
  }

  receiveFtEditValue($event) {
    this.isFtEditView = $event;
  }
  showEditFtModal() {
    this.isFtEditView = true;
  }

  navigateNewDesign(): void {
    this.router.navigateByUrl(`retrieval/addressdetail/${this.ftId}`);
  }

}
