import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-call-count-detail-report",
  templateUrl: "./call-count-detail-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallCountDetailReportComponent {
    filters: string[] = ["Projects", "WorkflowStatus", "Products", "LOB", "RetrievalOwner"];
    dashboardType = LookerRiskDashboardType.CALL_COUNT_DETAIL_REPORT;
    hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.WORKFLOW,
                             HideFilters.HEALTH_PLAN2, HideFilters.LINE_OF_BUSINESS, HideFilters.CONTRACT_NUMBER,
                             HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.MEASURE];
}
