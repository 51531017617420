import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-add-encounter",
  templateUrl: "./add-encounter.component.html",
  styleUrls: ["./add-encounter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEncounterComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  enabled: boolean;


  constructor(
    private riskService: RiskService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.riskService.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        this.enabled = state.isEnabled;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  addNewEncounter(): void {
    if (this.enabled) {
      this.riskService.addNewEncounter();
    }
  }
}
