import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FileUploadModule as PUploadModule } from "primeng/fileupload";
import { UploadMessageComponent } from "./upload-message/upload-message.component";
import { UploadNewComponent } from "./upload-new.component";
import { UploadWarningComponent } from "./upload-warning/upload-warning.component";
import { UploadComponent } from "./upload.component";

@NgModule({
  imports: [
    PUploadModule,
    CommonModule,
  ],
  declarations: [
    UploadComponent,
    UploadNewComponent, // TODO: Replace UploadComponent with this new one.
    UploadMessageComponent,
    UploadWarningComponent,
  ],
  exports: [
    UploadComponent,
    UploadNewComponent,
    UploadMessageComponent,
    UploadWarningComponent,
  ],
})
export class UploadModule { }
