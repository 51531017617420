import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { AuditPackageGenerateRequest } from "./audit-package-generate-request.model";

@Injectable({
  providedIn: "root",
})
export class AuditPackageGenerateService {
  private projectIdFilter = new BehaviorSubject<number>(null);
  projectId = this.projectIdFilter.asObservable();

  private pAutoDeleteUserAuditEntry = new BehaviorSubject<number>(null);
  autoDeleteUserAuditEntry = this.pAutoDeleteUserAuditEntry.asObservable();

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient) { }

  refreshAuditPackageGrid(value: number | null) {
    this.projectIdFilter.next(value);
  }

  deleteAuditPackageItem(packageId: number): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}audit/deletePackageItem?auditPackageItem=${packageId}`;
    return this.http.post(url, {}) as Observable<HttpResponse<null>>;
  }

  deleteAuditPackageItemIds(packageIds: any): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}audit/deletepackageitemids?auditPackageItemIds`;
    return this.http.post(url, packageIds) as Observable<HttpResponse<null>>;
  }

  generatePackage(packageData: AuditPackageGenerateRequest): Observable<number> {
    const url = `${this.baseApiUrl}audit/generatePackage`;
    return this.http.post(url, packageData) as Observable<number>;
  }

  autoDeleteAuditPackageItem(packageItemId: number) {
    this.pAutoDeleteUserAuditEntry.next(packageItemId);
  }

  chaseListForComplianceCode(chaseListIds: any): Observable<any> {
    const url = `${this.baseApiUrl}audit/chaselistforcompliancecode`;
    return this.http.post(url, chaseListIds)as Observable<any>;
  }

}
