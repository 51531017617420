export class DocumentPageImage {
  documentPageId: number;
  imageString: string;
  isHighResolutionImage?: boolean;
  constructor(options: DocumentPageImage) {
    this.documentPageId = options.documentPageId;
    this.imageString = options.imageString;
    this.isHighResolutionImage = options.isHighResolutionImage;
  }
}
