import { IAutoMapper } from "../../../../../../../../core/automapper/interfaces";


export const mapClinicalTerm = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClinicalTerm")
    .forMember("documentPageIds", o => o.documentPageIds)
    .forMember("pageNumbers", o => o.pageNumbers)
    .forMember("text", o => o.text);
};

export class ClinicalTerm {

  documentPageIds: number[];
  pageNumbers: number[];
  text: string;

  constructor(options: Partial<ClinicalTerm>) {
    this.documentPageIds = options.documentPageIds;
    this.pageNumbers = options.pageNumbers;
    this.text = options.text;
  }
}
