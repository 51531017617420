import { DateHelper } from "../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export interface IMemberOptions {
  memberId?: number;
  memberSourceAliasId?: string;
  enrolleeId?: string;
  memberFirstName?: string;
  memberLastName?: string;
  memberGender?: string;
  memberDateOfBirth?: Date;
  memberAgeByMeasureYear?: number;
}

export class Member {
  memberId: number;
  memberSourceAliasId: string;
  enrolleeId: string;
  memberFirstName: string;
  memberLastName: string;
  memberGender: string;
  memberDateOfBirth: Date;
  memberAgeByMeasureYear: number;


  constructor(options: IMemberOptions = {}) {
    this.memberId = options.memberId;
    this.memberSourceAliasId = StringHelper.clean(options.memberSourceAliasId);
    this.enrolleeId = StringHelper.clean(options.enrolleeId);
    this.memberFirstName = StringHelper.clean(options.memberFirstName);
    this.memberLastName = StringHelper.clean(options.memberLastName);
    this.memberGender = StringHelper.clean(options.memberGender);
    this.memberDateOfBirth = DateHelper.create(options.memberDateOfBirth, null);
    this.memberAgeByMeasureYear = options.memberAgeByMeasureYear;
  }

  get hasMemberAgeByMeasureYear(): boolean {
    return NumberHelper.isGreaterThan(this.memberAgeByMeasureYear, 0);
  }

  get hasMemberId(): boolean {
    return NumberHelper.isGreaterThan(this.memberId, 0);
  }

  get memberAge(): number {
    // TODO: Add to member object.
    throw new Error("Not Implemented Exception");
    // const age = DateHelper.years(this.memberDateOfBirth, new Date());
    // return age;
  }

}
