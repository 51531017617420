import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapHccModel = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "HccModel")
        .forMember("categoryCode", o => o.categoryCode);
};

export class HccModel {
  readonly categoryCode: string;

  constructor(options: {
    categoryCode: string;
  }) {
      this.categoryCode = options.categoryCode;
  }
}
