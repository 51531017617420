import { FormGroup } from "@angular/forms";
import { CalculatedGroup, ICalculatedGroupOptions } from "../calculated-group.model";
import { EarlyLateIndicatorGroupComponent } from "./early-late-indicator-group.component";

export interface IEarlyLateIndicatorGroupOptions extends ICalculatedGroupOptions { }

export class EarlyLateIndicatorGroup extends CalculatedGroup implements IEarlyLateIndicatorGroupOptions {
  readonly controlType = "early-late-indicator-group";
  readonly componentType = EarlyLateIndicatorGroupComponent;


  constructor(options: IEarlyLateIndicatorGroupOptions = {}) {
    super(options);

    this.create = () => {
      return new FormGroup({});
    };
  }
}
