import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-tool-tip",
  template: `
    <app-icon iconStyle="fal" iconName="info-circle" [pTooltip]="text" [tooltipPosition]="position"></app-icon>
  `,
  styleUrls: ["./tool-tip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolTipComponent {
  @Input() text = "";
  @Input() position = "top";
}
