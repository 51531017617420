import { DateHelper } from "../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class RetrievalContactHistory {
  readonly contactLogId: number;
  readonly userName: string;
  readonly callStartTime: string;
  readonly contactDate: string;
  readonly contactDetail: string;
  readonly callResult: string;
  readonly contactMethodType: string;
  readonly contactMethodTypeId: number;
  readonly contactMethodTypesCsv: string;
  readonly callNote: string;
  readonly callDuration: string;
  readonly callSId: string;
  readonly callRecordSID: string;
  readonly callRecordUrl: string;
  readonly callTranscriptionAwsFileName: string;
  readonly callTranscriptionPreviewText: string;
  readonly providerPacketTemplate: string;
  readonly providerPacketId: number;
  readonly batchNumber: string;
  readonly isRetryFax: boolean;
  readonly type: string;
  readonly title: string;
  readonly spokeTo: string;
  readonly nextCallDate: string;
  readonly note: string;
  readonly masterDocumentSourceId: number;
  readonly isSuccessful: boolean;
  readonly status: string;
  readonly vendorId: number;


  constructor(options: {
    contactLogId?: number;
    userName?: string;
    callStartTime?: string;
    contactDate?: string;
    contactDetail?: string;
    callResult?: string;
    contactMethodType?: string;
    contactMethodTypeId?: number;
    contactMethodTypesCsv?: string;
    callNote?: string;
    callDuration?: string;
    callSId?: string;
    callRecordSID?: string;
    callRecordUrl?: string;
    callTranscriptionAwsFileName?: string;
    callTranscriptionPreviewText?: string;
    providerPacketTemplate?: string;
    providerPacketId?: number;
    batchNumber?: string;
    isRetryFax?: boolean;
    type?: string;
    title?: string;
    spokeTo?: string;
    nextCallDate?: string;
    note?: string;
    masterDocumentSourceId?: number;
    isSuccessful?: boolean;
    status?: string;
    vendorId?: number;
  }) {
    this.contactLogId = options.contactLogId;
    this.userName = StringHelper.clean(options.userName);
    this.callStartTime = DateHelper.format(options.callStartTime, "MM/DD/YYYY, hh:mm A");
    this.contactDate = StringHelper.clean(options.contactDate);
    this.contactDetail = StringHelper.clean(options.contactDetail);
    this.callResult = StringHelper.clean(options.callResult);
    this.contactMethodType = StringHelper.clean(options.contactMethodType);
    this.contactMethodTypeId = options.contactMethodTypeId;
    this.contactMethodTypesCsv = StringHelper.clean(options.contactMethodTypesCsv);
    this.callNote = StringHelper.clean(options.callNote);
    this.callDuration = StringHelper.clean(options.callDuration);
    this.callSId = StringHelper.clean(options.callSId);
    this.callRecordSID = StringHelper.clean(options.callRecordSID);
    this.callRecordUrl = StringHelper.clean(options.callRecordUrl);
    this.callTranscriptionAwsFileName = StringHelper.clean(options.callTranscriptionAwsFileName);
    this.callTranscriptionPreviewText = StringHelper.clean(options.callTranscriptionPreviewText);
    this.providerPacketTemplate = StringHelper.clean(options.providerPacketTemplate);
    this.providerPacketId = options.providerPacketId;
    this.batchNumber = StringHelper.clean(options.batchNumber);
    this.isRetryFax = options.isRetryFax;
    this.type = StringHelper.clean(options.type);
    this.title = StringHelper.clean(options.title);
    this.spokeTo = StringHelper.clean(options.spokeTo);
    this.nextCallDate = StringHelper.clean(options.nextCallDate) ? DateHelper.format(options.nextCallDate, "MM/DD/YYYY") : "";
    this.note = StringHelper.clean(options.note);
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.isSuccessful = options.isSuccessful;
    this.status = StringHelper.clean(options.status);
    this.vendorId = options.vendorId;
  }

}
