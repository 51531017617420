import { IAutoMapper } from "../../../../core/automapper/interfaces";


export const mapBulkUserResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "BulkUserResponse")
    .forMember("totalUsersPassed", o => o.totalUsersPassed)
    .forMember("totalUsersFailed", o => o.totalUsersFailed)
    .forMember("fileContent", o => o.fileContent);
};
export class BulkUserResponse {
  totalUsersPassed?: number;
  totalUsersFailed?: number;
  fileContent?: string;

  constructor(options: {
    totalUsersPassed?: number;
    totalUsersFailed?: number;
    fileContent?: string;
  } = {}) {
    this.totalUsersPassed = options.totalUsersPassed;
    this.totalUsersFailed = options.totalUsersFailed;
    this.fileContent = options.fileContent;

  }
}
