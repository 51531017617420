export class JobQueueItem {
  stepExecutionId: number;
  stateMachineId: number;
  stateMachineName: string;
  stateMachineRequestId: string;
  statusId: number;
  statusName: string;
  startDateTime: Date;
  elapsedSeconds: number;
  message: string;
  objectType: string;
  objectId: string;
  userId: number;
  sourceName: string;
  jobLink: string;

  constructor(options: {
    stepExecutionId?: number;
    stateMachineId?: number;
    stateMachineName?: string;
    stateMachineRequestId?: string;
    statusId?: number;
    statusName?: string;
    startDateTime?: Date;
    elapsedSeconds?: number;
    message?: string;
    objectType?: string;
    objectId?: string;
    userId?: number;
    sourceName?: string;
    jobLink?: string;

  } = {}) {

    this.stepExecutionId = options.stepExecutionId;
    this.stateMachineId = options.stateMachineId;
    this.stateMachineName = options.stateMachineName;
    this.stateMachineRequestId = options.stateMachineRequestId;
    this.statusId = options.statusId;
    this.statusName = options.statusName;
    this.startDateTime = options.startDateTime;
    this.elapsedSeconds = options.elapsedSeconds;
    this.message = options.message;
    this.objectType = options.objectType;
    this.objectId = options.objectId;
    this.userId = options.userId;
    this.sourceName = options.sourceName;
    this.jobLink = options.jobLink;
  }
}
