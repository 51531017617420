<div class="chart-container" [ngClass]="{ 'max': isChartMaxed }">
  <app-document-page-viewer 
    [chaseId]="chaseId" 
    [isReadOnly]="true"
    [(isMaximized)]="isChartMaxed"
    [isArchived]="true">
  </app-document-page-viewer>
</div>
<div class="coding-container">
  <app-accordion-panel>
    <app-accordion-tab *ngFor="let entity of chaseEntityData; trackBy: trackByIndex" header="{{ entity.entityTypeName }}:">
      <div *ngFor="let attribute of entity.attributes; trackBy: trackByIndex">
        {{ attribute.attributeName }}: {{ attribute.value }}
      </div>
    </app-accordion-tab>
  </app-accordion-panel>
</div>

