import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-clinical-production-inventory-and-qa-scores",
  templateUrl: "./clinical-production-inventory-and-qa-scores.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductionInventoryAndQaScoresComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.RISK_CLINICAL_PRODUCTION_INVENTORY_QA_SCORES;

  constructor() {
    this.filters = ["Client", "Projects"];
  }
}
