import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProviderItem } from "../../../api/provider/provider-search-result-item";
import { NewChase } from "./new-chase.model";


@Injectable({
    providedIn: "root",
})

export class GapQueryDataExchangeService {
    private isGapClosure = new BehaviorSubject<boolean>(false);
    private providerDetails = new BehaviorSubject<NewChase>(null);
    private selectedProviderList = new BehaviorSubject<ProviderItem[]>(null);
    private newChaseData = new BehaviorSubject<NewChase>(null);

    setIsGapClosureActive(data: boolean) {
        this.isGapClosure.next(data);
    }

    clearIsGapClosureActive() {
        this.isGapClosure.unsubscribe();
    }

    getIsGapClosureActive(): Observable<boolean> {
        return this.isGapClosure.asObservable();
    }

    setproviderDetails(data: NewChase) {
        this.providerDetails.next(data);
    }

    clearproviderDetails() {
        this.providerDetails.unsubscribe();
    }

    getproviderDetails(): Observable<NewChase> {
        return this.providerDetails.asObservable();
    }

    setproviderList(selectedProviderList: ProviderItem[]) {
        this.selectedProviderList.next(selectedProviderList);
    }

    getproviderList(): Observable<any> {
        return this.selectedProviderList.asObservable();
    }

    setNewChaseData(data: NewChase) {
        this.newChaseData.next(data);
    }

    clearNewChaseData() {
        this.newChaseData.unsubscribe();
    }

    getNewChaseData(): Observable<NewChase> {
        return this.newChaseData.asObservable();
    }

}
