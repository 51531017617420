import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ProviderCustomDetail } from "../../provider-custom-detail.model";
import { ProviderPacketModel } from "./provider-packet.model";

@Injectable({
    providedIn: "root",
})
export class EmailVerificationService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  verifyCode(verificationCode: string) {
    const url = `${this.baseApiUrl}provider/code/verify?verificationCode=${verificationCode}`;
    return this.http.get(url, { observe: "response" }).pipe(
      map((response: HttpResponse<any>): boolean => response.body ? true : false)
    );
    }

  providerAddressDetails(addressId: string) {
      const url = `${this.baseApiUrl}public/provider/address/details?addressId=${addressId}`;
      return this.http.get(url, { observe: "response" }) as Observable<HttpResponse<any>>;
      }
  getDecryptedCode(code: string) {
      const url = `${this.baseApiUrl}public/provider/decryptcode?code=${code}`;
      return this.http.get(url, { observe: "response" }) as Observable<HttpResponse<any>>;
  }

  getEmailHeadings(orgId: string): Observable<ProviderCustomDetail[]> {
      const url = `${this.baseApiUrl}public/provider/details?serviceOrgId=${orgId}`;
      return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "ProviderCustomDetail"))
            );
    }

  verifyUploadAccessCode(verificationCode: string): Observable<ProviderPacketModel> {
      const url = `${this.baseApiUrl}provider/verify/accesscode?verificationCode=${verificationCode}`;

      return this.http.get(url)
        .pipe(
        map(this.automapper.curry("default", "ProviderPacketModel"))
        );
    }


}
