import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ChaseDetailForAuditItem } from "../../chase-detail-for-audit-item.model";

@Injectable({
  providedIn: "root",
})
export class AuditQueryChartService {
  private chaseDetails = new BehaviorSubject<ChaseDetailForAuditItem>(new ChaseDetailForAuditItem());
  auditChaseDetails = this.chaseDetails.asObservable();

  constructor() { }

  setChaseDetails(value: ChaseDetailForAuditItem | null) {
    this.chaseDetails.next(value);
  }
}
