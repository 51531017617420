<div class="gap-query-container">
  <h3 class="gap-query-header">GAP QUERY</h3>
  <div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-gap-grid [stateName]="stateName"
                    [(selection)]="selection"
                    [viewAttributeId]="viewAttributeId"
                    [isSelectionModeMultiple]=true
                    [routeParameters]="routeParameters">
      </app-gap-grid>
    </div>
  </div>
</div>
