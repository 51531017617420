import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { StandardGroupComponent } from "./standard-group.component";

export interface IStandardGroupOptions extends IDynamicGroupOptions { }

export class StandardGroup extends DynamicGroup implements IStandardGroupOptions {
  readonly controlType = "standard-group";
  readonly componentType = StandardGroupComponent;


  constructor(options: IStandardGroupOptions = {}) {
    super(options);
  }
}
