import { IAutoMapper } from "../interfaces";

export const mapProviderListItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("provider-search-item", "ProviderItem")
    .forMember("recordNumber", o => o.recordNumber)
    .forMember("serviceProviderId", o => o.serviceProviderId)
    .forMember("serviceProviderName", o => o.serviceProviderName)
    .forMember("masterProviderId", o => o.masterProviderId)
    .forMember("sourceAliasId", o => o.sourceAliasId)
    .forMember("nationalProviderId", o => o.nationalProviderId)
    .forMember("providerGroup", o => o.providerGroup)
    .forMember("speciality", o => o.speciality)
    .forMember("phoneNumber", o => o.phoneNumber)
    .forMember("faxNumber", o => o.faxNumber)
    .forMember("contact", o => o.contact)
    .forMember("firstName", o => o.firstName)
    .forMember("lastName", o => o.lastName)
    .forMember("specialtyName", o => o.serviceProviderSpecialty)
    .forMember("recordCount", o => o.recordCount)
    .forMember("taxpayerId", o => o.taxpayerId)
    .forMember("isVerified", o => o.isVerified)
    .forMember("isPended", o => o.isPended)
    .forMember("serviceProviderList", o => o.serviceProviderList)
    .forMember("totalAddresses", o => o.totalAddresses);
};
