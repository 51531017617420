<app-grid-menu
[configuration]="configuration"
[data]="data"
[filterTemplate]="filterTemplate"
[selectedItems]="selection"
[primeGrid]="primeGrid"
[views]="views"
[refreshViews]="refreshViews"
[onViewSelect]="onViewSelect"
[requestUrl]="requestUrl"
[exportDataFromUrl]="exportDataFromUrl"
[urlOptions]="urlOptions"
[addressId]="addressId"
[filterHeaderText]="filterHeaderText"></app-grid-menu>

<app-bulk-actions
[actions]="bulkActionsObsolete"
[selectedItems]="selection"></app-bulk-actions>

<app-basic-filter-applied
  [request]="request"
  [primeGrid]="primeGrid"
  (removeFilter)="onRemoveFilter($event)">
</app-basic-filter-applied>

<p-table
#primeGrid
[loading]="loading"
(onPage)="paginate($event)" [first]="first"
[columns]="columns"
[value]="data"
[totalRecords]="totalRecords"
[rows]="pageSize"
[paginator]="hasPagination"
[selectionMode]="selectionMode"
[(selection)]="selection"
(selectionChange)="onSelectionChange(selection)"
[rowTrackBy]="trackByItem"
[dataKey]="trackByField"
[responsive]="true"
[resizableColumns]="resizableColumns"
(onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
[expandedRowKeys]="expandedRows">

  <ng-template pTemplate="header">
    <tr app-grid-header [configuration]="configuration"></tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded">
    <tr
    [ngClass]="getRowClass(rowIndex, rowData)"
    [pSelectableRow]="selectableRowData(rowData)"
    [pSelectableRowIndex]="rowIndex"
    [pSelectableRowDisabled]="rowData.disabled"
    [@.disabled]="isAnimationDisabled"
    [@slideOutAnimation]
    app-grid-body
    [configuration]="configuration"
    [rowData]="rowData"
    [rowIndex]="rowIndex"
    [hasSelectedRows]="hasSelection"
    [actionTemplate]="actionTemplate"
    [showSelector]="showSelector"
    [expanded]="expanded"
    (click)="onRowClick(rowData)"></tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion"
               let-rowData
               let-columns="columns">
    <tr>
      <td [attr.colspan]="colspan" class="row-expand">
        <ng-template [ngTemplateOutlet]="rowExpandTemplate"
                     [ngTemplateOutletContext]="{ $implicit: rowData }"></ng-template>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <span class="page-size-text">Page Size: </span>
    <p-dropdown [options]="options"  [(ngModel)]="configuration.pageSize" (onChange)="pageSizeChange()"></p-dropdown>
  </ng-template>

</p-table>
