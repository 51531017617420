import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-generic-border-template",
  template: `
    <div class="generic-border__header bold">{{ header }}</div>
    <div class="generic-border__container">
      <ng-content></ng-content>
    </div>
`,
  styleUrls: ["./generic-border-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericBorderTemplateComponent {
  @Input() header: string;
}
