import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { DateFormats } from "../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { ProjectType } from "../../../project/project-type.enum";
import { CreateChaseService } from "../create-new-chase.service";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { NewChase } from "../new-chase.model";
import { CreateMember } from "./create-member.model";
import { CreateNewChaseMemberService } from "./create-new-chase-member.service";
import { MemberSearchRequest } from "./member-search-request.model";
import { MemberSearchResult } from "./member-search-result.model";


@Component({
  selector: "app-create-new-chase-member",
  templateUrl: "./create-new-chase-member.component.html",
  styleUrls: ["./create-new-chase-member.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseMemberComponent implements OnInit, OnDestroy {
  formGroupMemberSearch: FormGroup;
  existingMemberOptions: Radiobutton;
  memberIdInput: Textbox;
  enrolleeIdInput: Textbox;
  memberFirstNameInput: Textbox;
  memberMiddleNameInput: Textbox;
  memberLastNameInput: Textbox;
  memberDateOfBirthInput: Textbox;
  memberGenderInput: Dropdown;
  @Output() onShowChild = new EventEmitter<any>();
  selectedMemberValue: string;
  clientMemberIdInput: Textbox;
  memberSearchGridConfiguration = new GridConfiguration();
  memberGridSelection: MemberSearchResult;
  memberSearchModel: MemberSearchRequest;
  memberSearchData: MemberSearchResult[] = [];
  memberCount: number;
  showChaseKeyValidation: boolean;
  createNewMemberModel: CreateMember;
  newChase: NewChase;
  formGroupMemberOption: FormGroup;
  showProjectValidation: boolean;
  @Output() onInput = new EventEmitter<any>();
  private sink = new SubSink();
  isClientEnrolleeIdInvalid: boolean;
  validationErrorMessages: string[];
  @Input() currentSelectedProjectType;

  get isIVAProjectSelected(): boolean {
    return this.currentSelectedProjectType === ProjectType.IVA;
  }

  constructor(
    private readonly formService: FormService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private createNewChaseMemberService: CreateNewChaseMemberService,
    private changeDetector: ChangeDetectorRef,
    private chaseService: CreateChaseService,
    private dataExchangeService: GapQueryDataExchangeService
  ) { }

  ngOnInit() {
    this.createGrid();
    this.newChase = this.chaseService.chaseModel;
    this.createMemberControls();
    this.createMemberOptionForm();
    this.createMemberSearchForm();
    this.checkMemberExisting();
  }

  private createMemberControls(): void {
    this.existingMemberOptions = new Radiobutton({
      key: "member",
      label: "IS THIS AN EXISTING MEMBER?",
      options: [
        new SelectableInput({ text: "Yes", value: 1 }),
        new SelectableInput({ text: "No", value: 2 }),
      ],
    });

    this.memberIdInput = new Textbox({
      key: "memberId",
      label: "MEMBER ID",
      type: TextboxType.NUMBER,
      validators: [
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        pattern: "Member Id must be numeric. Decimal or negative numbers not allowed.",
      },
      placeholder: "Enter Member ID",
    });

    this.enrolleeIdInput = new Textbox({
      key: "enrolleeId",
      label: "ENROLLEE ID",
      errorMessages: {
        pattern: "Enrollee Id must be AlphaNumeric.",
      },
      placeholder: "Enter Enrollee ID",
    });

    this.memberFirstNameInput = new Textbox({
      key: "memberFirstName",
      label: "FIRST NAME",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Invalid Member First Name. Maximum 50 characters allowed",
      },
      placeholder: "Enter First Name",
    });

    this.memberMiddleNameInput = new Textbox({
      key: "memberMiddleName",
      label: "MIDDLE NAME",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Invalid Member Middle Name.  Maximum 50 characters allowed",
      },
      placeholder: "Enter Middle Name",
    });

    this.memberLastNameInput = new Textbox({
      key: "memberLastName",
      label: "LAST NAME",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Invalid Member Last Name.  Maximum 50 characters allowed",
      },
      placeholder: "Enter Last Name",
    });

    this.memberDateOfBirthInput = new Textbox({
      key: "memberDateOfBirth",
      label: "DATE OF BIRTH",
      validators: [Validators.maxLength(10), Validators.pattern(RegExHelper.date)],
      errorMessages: {
        maxlength: "DOB can not be more than 10 characters.",
        pattern: "DOB Must be in format MM/DD/YYYY",
      },
      placeholder: "Enter Date of Birth",
    });

    this.memberGenderInput = new Dropdown({
      key: "memberGender",
      label: "GENDER",
      placeholder: "Select Gender",
      options: [
        new SelectableInput({ text: "Female", value: "F" }),
        new SelectableInput({ text: "Male", value: "M" }),
      ],
      errorMessages: {
        required: "Please select a gender.",
      },
    });

    this.clientMemberIdInput = new Textbox({
      key: "clientMemberId",
      label: "CLIENT MEMBER ID",
      placeholder: "Enter Client Member ID",
    });
  }

  private createMemberOptionForm(): void {
    this.formGroupMemberOption = this.formService.createFormGroup([
      this.existingMemberOptions]);
  }

  private createMemberSearchForm(): void {
    this.formGroupMemberSearch = this.formService.createFormGroup([
      this.memberIdInput,
      this.enrolleeIdInput,
      this.memberFirstNameInput,
      this.memberMiddleNameInput,
      this.memberLastNameInput,
      this.memberDateOfBirthInput,
      this.memberGenderInput,
      this.clientMemberIdInput]);
  }

  checkMemberExisting() {
    this.sink.add(
      this.formGroupMemberOption.get("member").valueChanges.subscribe(() => {
        this.memberFormReset();
        this.showProjectValidation = false;
        this.memberGridSelection = null;
        if (this.showMemberForm  && !this.searchMemberControls) {
          const enrollee = this.formGroupMemberSearch.get(this.enrolleeIdInput.key).value;
          if (this.isIVAProjectSelected) {
            this.enrolleeIdInput = new Textbox({
              ...this.enrolleeIdInput,
              value: enrollee,
              errorMessages: {
                minlength: "Enrollee Id can not be less than 3 character.",
                maxlength: "Enrollee Id can not be more than 40 character.",
              },
            } as any);
            this.formGroupMemberSearch.controls[this.enrolleeIdInput.key].setValidators([Validators.minLength(3), Validators.maxLength(40)]);
            this.formGroupMemberSearch.get(this.enrolleeIdInput.key).updateValueAndValidity();
         } else {
            this.enrolleeIdInput = new Textbox({
              ...this.enrolleeIdInput,
              value: enrollee,
              errorMessages: {
                minlength: "Enrollee Id can not be less than 3 character.",
                maxlength: "Enrollee Id can not be more than 20 character.",
              },
            } as any);
            this.formGroupMemberSearch.controls[this.enrolleeIdInput.key].setValidators([Validators.minLength(3), Validators.maxLength(20)]);
            this.formGroupMemberSearch.get(this.enrolleeIdInput.key).updateValueAndValidity();
          }

          const firstName = this.formGroupMemberSearch.get(this.memberFirstNameInput.key).value;
          this.memberFirstNameInput = new Textbox({
            ...this.memberFirstNameInput,
            value: firstName,
            validators: [Validators.required, Validators.maxLength(50)],
            errorMessages: {
              required: "Member First Name is required.",
              maxlength: "Invalid Member First Name. Maximum 50 characters allowed",
            },
          } as any);

          const middleName = this.formGroupMemberSearch.get(this.memberMiddleNameInput.key).value;
          this.memberMiddleNameInput = new Textbox({
            ...this.memberMiddleNameInput,
            value: middleName,
            validators: [Validators.maxLength(50)],
            errorMessages: {
              maxlength: "Invalid Member Middle Name. Maximum 50 characters allowed",
            },
          } as any);

          const lastName = this.formGroupMemberSearch.get(this.memberLastNameInput.key).value;
          this.memberLastNameInput = new Textbox({
            ...this.memberLastNameInput,
            value: lastName,
            validators: [Validators.required, Validators.maxLength(50)],
            errorMessages: {
              required: "Member Last Name is required.",
              maxlength: "Invalid Member Last Name. Maximum 50 characters allowed",
            },
          } as any);

          const dob = this.formGroupMemberSearch.get(this.memberDateOfBirthInput.key).value;
          this.memberDateOfBirthInput = new Textbox({
            ...this.memberDateOfBirthInput,
            value: dob,
            validators: [Validators.required, Validators.maxLength(10), Validators.pattern(RegExHelper.date)],
            errorMessages: {
              required: "DOB is required.",
              maxlength: "DOB can not be more than 10 characters.",
              pattern: "DOB Must be in format MM/DD/YYYY",
            },
            placeholder: "MM/DD/YYYY",
          } as any);

          const gender = this.formGroupMemberSearch.get(this.memberGenderInput.key).value;
          this.memberGenderInput = new Dropdown({
            ...this.memberGenderInput,
            value: gender,
            validators: [Validators.required],
            errorMessages: {
              required: "Please select a gender.",
            },
          } as any);

          const clientMember = this.formGroupMemberSearch.get(this.clientMemberIdInput.key).value;
          this.clientMemberIdInput = new Textbox({
            ...this.clientMemberIdInput,
            value: clientMember,
            validators: [Validators.required],
            errorMessages: {
              required: "Client Member Id is required.",
            },
          } as any);
          this.changeDetector.markForCheck();
        }
      }));
  }

  get showMemberForm(): boolean {
    return NumberHelper.isGreaterThan(
      this.formGroupMemberOption.get("member").value, 0);
  }

  get searchMemberControls(): boolean {
    const existingMember = this.formGroupMemberOption.get("member").value === 1;
    if (!existingMember) { this.memberCount = undefined; }
    return existingMember;
  }

  get isMemberSearchFormHasValues(): boolean {
    return Object.values(this.formGroupMemberSearch.controls).some(field => field.value !== null && field.value !== "");
  }

  createGrid() {
    this.memberSearchGridConfiguration.columns = [
      new GridColumnDefinition({ field: "memberId", header: "MEMBER ID" }),
      new GridColumnDefinition({ field: "enrolleeId", header: "ENROLLEE ID" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "FIRST NAME" }),
      new GridColumnDefinition({ field: "memberMiddleName", header: "MIDDLE NAME" }),
      new GridColumnDefinition({ field: "memberLastName", header: "LAST NAME" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "memberGender", header: "GENDER" }),
    ];
    this.memberSearchGridConfiguration.pageSize = 10;
    this.memberSearchGridConfiguration.showActionColumn = false;
    this.memberSearchGridConfiguration.selectionMode = "single";
    this.memberSearchGridConfiguration.showExportAction = false;
  }

  searchMember(): void {
      this.memberGridSelection = null;
      this.memberSearchModel = new MemberSearchRequest({
        projectId: this.chaseService.chaseProjectItems.projectId,
        memberId: this.formGroupMemberSearch.get("memberId").value,
        enrolleeId: this.formGroupMemberSearch.get("enrolleeId").value,
        memberFirstName: StringHelper.isAvailable(this.formGroupMemberSearch.get("memberFirstName").value) ? this.formGroupMemberSearch.get("memberFirstName").value : null,
        memberMiddleName: StringHelper.isAvailable(this.formGroupMemberSearch.get("memberMiddleName").value) ? this.formGroupMemberSearch.get("memberMiddleName").value : null,
        memberLastName: StringHelper.isAvailable(this.formGroupMemberSearch.get("memberLastName").value) ? this.formGroupMemberSearch.get("memberLastName").value : null,
        memberDateOfBirth: StringHelper.isAvailable(this.formGroupMemberSearch.get("memberDateOfBirth").value) ? this.formGroupMemberSearch.get("memberDateOfBirth").value : null,
        memberGender: StringHelper.isAvailable(this.formGroupMemberSearch.get("memberGender").value) ? this.formGroupMemberSearch.get("memberGender").value : null,
      });
      this.createNewChaseMemberService.memberSearch(this.memberSearchModel).subscribe(items => {
        this.memberSearchData = items as MemberSearchResult[];
        this.memberCount = this.memberSearchData.length;
        this.changeDetector.markForCheck();
      });


  }

  get isShowNextStepButton(): boolean {

    return !(this.memberGridSelection);
  }

  get isMemberResultsFound(): boolean {

    return this.memberCount > 0;
  }

  nextStep() {
    if (this.memberGridSelection != null) {
      this.newChase.memberId = this.memberGridSelection.memberId;
      this.newChase.memberName = this.memberGridSelection.memberName;
      this.newChase.memberDateOfBirth = DateHelper.format(this.memberGridSelection.memberDateOfBirth.slice(0, this.memberGridSelection.memberDateOfBirth.indexOf("T")));
      this.newChase.chaseMemberData = null;
    }
    this.chaseService.chaseModel = this.newChase;
    this.onShowChild.emit(this.chaseService.chaseModel);
    this.dataExchangeService.setNewChaseData(this.chaseService.chaseModel);
  }

  createMember(): void {
    if (this.formGroupMemberSearch.valid) {
      this.removeControlCharacters();
      this.createNewMemberModel = new CreateMember({
        enrolleeId: this.formGroupMemberSearch.get("enrolleeId").value,
        firstName: this.formGroupMemberSearch.get("memberFirstName").value,
        middleName: this.formGroupMemberSearch.get("memberMiddleName").value,
        lastName: this.formGroupMemberSearch.get("memberLastName").value,
        dateOfBirth: this.formGroupMemberSearch.get("memberDateOfBirth").value,
        gender: this.formGroupMemberSearch.get("memberGender").value,
        clientMemberId: this.formGroupMemberSearch.get("clientMemberId").value,
      });
      this.newChase.memberName = `${this.createNewMemberModel.firstName} ${this.createNewMemberModel.lastName}`;
      this.newChase.memberDateOfBirth = this.createNewMemberModel.dateOfBirth;
      this.newChase.chaseMemberData = this.createNewMemberModel;
      this.chaseService.validateChaseDetails(this.newChase)
            .subscribe(messages => {
        if (ArrayHelper.isAvailable(messages)) {
                    this.validationErrorMessages = messages;
                    this.isClientEnrolleeIdInvalid = true;
                    this.changeDetector.markForCheck();
                } else {
                    this.isClientEnrolleeIdInvalid = false;
                    this.newChase.memberId = null;
                    this.chaseService.chaseModel = this.newChase;
                    this.onShowChild.emit(this.chaseService.chaseModel);
                    this.dataExchangeService.setNewChaseData(this.chaseService.chaseModel);
                    this.showProjectValidation = false;
                    this.markAllAsTouched();
                }
            });
    } else if (!this.formGroupMemberSearch.valid) {
      this.showProjectValidation = false;
      this.markAllAsTouched();
    }
  }

  private markAllAsTouched(): void {
    Object.keys(this.formGroupMemberSearch.controls).forEach(key => {
      const control = this.formGroupMemberSearch.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  onChangeProjectInput(event) {
    if (!ArrayHelper.isAvailable(event.value)) {
      this.showProjectValidation = false;
    }
  }

  memberFormReset(): void {
    this.memberGridSelection = null;
    this.memberSearchData = [];
    this.createMemberSearchForm();
    this.formGroupMemberSearch.reset();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  reset() {
      this.formGroupMemberSearch.reset();
      this.memberGridSelection = null;
      this.memberSearchData = [];
      this.isClientEnrolleeIdInvalid = false;
  }

  private removeControlCharacters() {
    Object.keys(this.formGroupMemberSearch.controls).forEach(key => {
      const control = this.formGroupMemberSearch.get(key).value;
      if (StringHelper.isAvailable(control)) {
        const validValues = control.replace(/[^\x20-\x7E]/g, "");
        this.formGroupMemberSearch.controls[key].setValue(validValues);
      }
    });
    this.formService.updateDom.next();
    }

  trackByIndex(index, item) {
        return index;
    }

}
