import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserToken } from "../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseIdComponent } from "../../../../../../shared/chase-grid/chase-id.component";
import { BulkAction } from "../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { ListItem } from "../../../../../../shared/list/list-item";
import { RetrievalUtilities } from "../../../../../../shared/retrieval/retrieval.utilites";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ChaseSearchRequest } from "../../../../../api/chase-search/chase-search-request-model";
import { ChaseItem } from "../../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../../api/chase-search/chase-search.service";
import { FaxProviderRequest } from "../../../../../api/provider/fax-provider-request-model";
import { ProviderSearchRequest } from "../../../../../api/provider/provider-search-request-model";
import { ProviderService } from "../../../../../api/provider/provider.service";
import { ProviderPacketItem } from "../../../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ChaseMoveModel } from "../../../../project/chase-query/chase-move.model";
import { ChaseQueryService } from "../../../../project/chase-query/chase-query.service";
import { AddressSearchResult } from "../../../address-search/address-search-result.model";
import { CommitmentDateUpdateModel } from "../../../commitment-date/commitmentDateUpdate.model";
import { ContactRequest } from "../../../cover-letter-template/contact-request.model";
import { FaxRequest } from "../../../cover-letter-template/fax-request.model";
import { CoverTemplateDetails } from "../../../psr/address-detail/address-detail-info/cover-template-details";
import { FtAppointmentModel } from "../../ft-appointment-model";
import { FtAppointmentSearchRequest } from "../../ft-appointment-search-model";
import { FtAppointmentService } from "../../ft-appointment.service";
import { FtDetailEditService } from "../ft-detail-edit/ft-detail-edit.service";
import { FtEditModel } from "../ft-detail-edit/ft-edit-model";
import { FtDetailInfoService } from "./ft-detail-info.service";

@Component({
  selector: "retrieval-ft-detail-info",
  templateUrl: "./ft-detail-info.component.html",
  styleUrls: ["./ft-detail-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDetailInfoComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  @Output() formClose = new EventEmitter();
  private ftId: number;
  private user: UserToken;
  timelineItems = List<TimelineItem>();
  summaryItems = List<ListItem>();
  isCreatePendView = false;
  isEditPendView = false;
  private functionalRole: number;
  private documentRetrieval = 5;
  private documentRetrievalLead = 4;
  assignedTo: number;
  chaseIdList: number[] = [];
  pendIds: number[] = [];
  chasePendIds: number[] = [];
  chasePendId: number;
  ftEditModel: FtEditModel;
  private sendProviderFaxModel: FaxProviderRequest;

  providerGridConfiguration = new GridConfiguration();
  providerGridData: any[] = [];
  providerGridSelection: any[];

  appointmentsGridConfiguration = new GridConfiguration();
  appointmentsGridData: FtAppointmentModel[] = [];
  appointmentGridSelection: any[];

  chaseGridConfiguration = new GridConfiguration();
  chaseGridData: any[] = [];
  chaseGridSelection: any[];
  chaseGridDataAll = [];
  startRecord: number;
  endRecord: number;
  private AllRecords = 2147483647;
  private pageSize = 5;
  private gridSearchRequest: any;
  providerPacketItem: ProviderPacketItem;
  isClinical = false;
  isAddressModalVisible = false;
  isConfirmModalVisible = false;
  isMoveVisible = false;

  addressSearchGridConfiguration = new GridConfiguration();
  addressSearchData: any[] = [];
  addressGridselection: any;

  gridConfigurationModel = new GridConfiguration();
  retrievalLocationId: Textbox;
  selectedAddress: AddressSearchResult;
  confirmStatusMessage: any;
  chaseMoveModel: ChaseMoveModel;
  pageType: any;
  assignedUser: string;
  projectId: number;
  commitDateUpdateModel: CommitmentDateUpdateModel;
  isCommitmentDateModalVisible = false;
  addressSearchCallingSource = "Move Chase";

  selectedChases: any[];
  isPendModalVisible = false;
  @Input() clinical = false;
  nonActionableChasesChecked = false;
  moveChaseText = "Request Move";
  isTemplateModalVisible = false;
  templateName: string;
  coverTemplateData: CoverTemplateDetails;
  userName: string;
  phoneNumber: string;
  contactRequest: ContactRequest;

  constructor(
    private service: FtDetailInfoService,
    private chaseService: ChaseSearchService,
    private providerService: ProviderService,
    private ftDetailservice: FtDetailEditService,
    private ftAppointmentService: FtAppointmentService,
    private userService: UserService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private readonly route: ActivatedRoute,
    private chaseQryService: ChaseQueryService
  ) {

  }

  ngOnInit() {
    this.ftId = this.service.getFTGdFromPath();
    this.user = this.userService.getUserToken();
    this.assignedTo = (this.user.functionalRoleIds.indexOf(this.documentRetrievalLead) > -1) ? null : this.user.userId;
    this.moveChaseText = this.isUserMangerAdminLead ? "Move Chase" : "Request Move";
    this.pageType = "FieldTech";

    this.createGrids();

    this.route.paramMap.subscribe(params => {
      this.ftId = +params.get("FtGd");

      this.service
        .getSummaryItems()
        .subscribe(items => this.summaryItems = this.assignAndNotify(items));

      this.service
        .getTimelineItems()
        .subscribe(items => this.timelineItems = this.assignAndNotify(items));

      // Load Provider Grid
      this.startRecord = 1;
      this.endRecord = this.AllRecords;
      this.gridSearchRequest =
        new ProviderSearchRequest(null, null, this.ftId, null, null, null,
                                  null, null, this.startRecord, this.endRecord);

      this.providerService
        .providerSearch(this.gridSearchRequest)
        .subscribe(items => {
          this.providerGridData = items as any;
          this.changeDetector.markForCheck();
        });

      // Load Appointments Grid
      this.startRecord = 1;
      this.endRecord = this.AllRecords;
      this.gridSearchRequest =
        new FtAppointmentSearchRequest(this.ftId, null,
                                       null, null, this.startRecord, this.endRecord);

      this.ftAppointmentService
        .ftAppointmentList(this.gridSearchRequest)
        .subscribe(items => {
          this.appointmentsGridData = items as FtAppointmentModel[];
          this.changeDetector.markForCheck();
        });

      this.functionalRole = this.documentRetrieval;

      this.gridSearchRequest =
        new ChaseSearchRequest(
          null, null, null, null, this.ftId, this.assignedTo, null, null, null, null, null,
          null, this.startRecord, this.endRecord);

      this.chaseService
        .chaseSearch(this.gridSearchRequest)
        .subscribe(items => {
          this.chaseGridDataAll = items as any;
          this.chaseGridData = this.getChaseGridData();
          const chases = this.chaseGridDataEnabled.map(x => x.chaseID);

          this.chaseService.getChaseList(chases);
          this.changeDetector.markForCheck();
        });

      this.ftDetailservice
        .getFtDetailsForEdit()
        .subscribe(this.assignAndNotifyFtDetails);
    });

    this.service.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => this.updateFtDetails(id));

    this.providerService.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.updateProviderGrid());

    this.chaseService.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.updateChaseGrid());

    this.userService.getUser(this.user.userId).subscribe(result => {
      this.changeDetector.markForCheck();
      this.userName = `${result.firstName} ${result.lastName}`;
      this.phoneNumber = result.phone;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createGrids(): void {
    this.providerGridConfiguration.columns = [
      new GridColumnDefinition({
        field: "serviceProviderName",
        header: "Provider Name",
      }),
      new GridColumnDefinition({
        field: "nationalProviderId",
        header: "NPI",
      }),
      new GridColumnDefinition({
        field: "providerGroup",
        header: "Group",
      }),
      new GridColumnDefinition({
        field: "speciality",
        header: "Speciality",
      }),
    ];

    this.providerGridConfiguration.pageSize = this.pageSize;
    this.providerGridConfiguration.selectionMode = "multiple";
    this.providerGridConfiguration.showActionColumn = false;

    this.appointmentsGridConfiguration.columns = [
      new GridColumnDefinition({
        field: "appointmentStartTime",
        header: "Start Date",
      }),
      new GridColumnDefinition({
        field: "appointmentEndTime",
        header: "End Date",
      }),
      new GridColumnDefinition({
        field: "assignedTo",
        header: "Assigned To",
      }),
      new GridColumnDefinition({
        field: "chaseCount",
        header: "Chases",
      }),
      new GridColumnDefinition({
        field: "appointmentStatus",
        header: "Status",
      }),
    ];

    this.appointmentsGridConfiguration.pageSize = this.pageSize;
    this.appointmentsGridConfiguration.selectionMode = "multiple";
    this.appointmentsGridConfiguration.showActionColumn = false;

    this.chaseGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseID", template: this.chaseIdColumn, header: "Chase ID", width: "135px" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "memberGender", header: "M/F" }),
      new GridColumnDefinition({ field: "serviceProviderName", header: "Provider Name" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
    ];

    this.chaseGridConfiguration.pageSize = this.pageSize;
    this.chaseGridConfiguration.selectionMode = "multiple";
    this.chaseGridConfiguration.showActionColumn = true;
    this.chaseGridConfiguration.bulkActions = [
      new BulkAction({
        name: "Pend Chase(s)",
        action: items => this.openPendModal(),
      }),
      new BulkAction({
        name: this.moveChaseText.concat("(s)"),
        action: items => this.moveChase(),
      }),
      new BulkAction({
        name: "Fax Request(s)",
        action: this.openFaxModal.bind(this),
      }),
      new BulkAction({
        name: "Print Request(s)",
        action: items => this.printProvider(),
      }),
      new BulkAction({
        name: "Set Commitment Date",
        action: items => this.openCommitmentDateModal(),
      }),
    ];

    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "addressId", header: "ID", routeUrl: "/retrieval/addressdetail/:addressId" }),
      new GridColumnDefinition({ field: "address", header: "Address" }),
      new GridColumnDefinition({ field: "chaseCount", header: "Chases" }),
      new GridColumnDefinition({ field: "status", header: "Status" }),
      new GridColumnDefinition({ field: "phone", header: "Phone" }),
    ];
    this.gridConfigurationModel.pageSize = 25;
    this.gridConfigurationModel.showActionColumn = false;
  }

  getChaseGridData(): any[] {
    this.chaseGridDataAll.forEach(x => {
      // Disable chases in "Chart collection", "Waiting for chart" workflow status or which are moved or request made.
      // Make them non - actionable.
      if ((x.workflowStatusId !== 2 && x.workflowStatusId !== 3)
        || (x.pendCode === "PC900" && (x.pendStatusId === 1 || x.pendStatusId === 2 || x.pendStatusId === 4))) {
        x.disabled = true;
      }
    });

    return this.nonActionableChasesChecked ? this.chaseGridDataAll : this.chaseGridDataEnabled;
  }

  get chaseGridDataEnabled(): any[] {
    return this.chaseGridDataAll.filter(c => !c.disabled);
  }

  get isUserMangerAdminLead(): boolean {
    return (this.user.isManagerRole || this.user.isAdminRole || this.user.isLeadRole);
  }

  updateFtDetails(id) {
    this.service
      .getSummaryItems()
      .subscribe(items => this.summaryItems = this.assignAndNotify(items));

    this.ftDetailservice
      .getFtDetailsForEdit()
      .subscribe(this.assignAndNotifyFtDetails);

  }

  updateProviderGrid() {

    this.startRecord = 1;
    this.endRecord = this.AllRecords;
    this.gridSearchRequest =
      new ProviderSearchRequest(null, null, this.ftId, null, null, null,
                                null, null, this.startRecord, this.endRecord);

    this.providerService
      .providerSearch(this.gridSearchRequest)
      .subscribe(items => {
        this.providerGridData = items as any;
        this.changeDetector.markForCheck();
      });


    this.chaseService.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.updateChaseGrid());

  }

  updateChaseGrid() {
    this.gridSearchRequest =
      new ChaseSearchRequest(
        null, null, null, null, this.ftId, this.assignedTo, null, null, null, null, null,
        null, this.startRecord, this.endRecord);

    this.chaseService
      .chaseSearch(this.gridSearchRequest)
      .subscribe(items => {
        this.chaseGridDataAll = items as any;
        this.chaseGridData = this.getChaseGridData();
        const chases = this.chaseGridDataEnabled.map(x => x.chaseID);

        this.chaseService.getChaseList(chases);
        this.changeDetector.markForCheck();
      });
  }


  private assignAndNotifyFtDetails = (data: FtEditModel): void => {

    this.ftEditModel = new FtEditModel(data);

    this.changeDetector.markForCheck();

  }

  selectRow(rowData) {

    const key = "chasePendId";
    let selectedRows = [];
    this.chasePendIds = [];
    if (rowData !== undefined) {
      selectedRows.push(rowData);
    } else {
      selectedRows = this.actionableChaseGridSelection;
    }

    if (selectedRows && selectedRows.length > 0) {
      const pendId = selectedRows[0][key];
      this.chaseIdList = [];
      this.pendIds = [];

      if (pendId !== 0) {
        this.chasePendId = pendId;
      }
      const checkValue = selectedRows.filter(c => c.pendCode);

      if (checkValue.length > 0 && checkValue.length < selectedRows.length) {
        this.messagingService.showToast("Please select similar Chases for bulk actions - either with Pend or without Pend", SeverityType.ERROR);
        return;
      }
      selectedRows.forEach(item => {
        this.chaseIdList.push(item.chaseID);
        if (item.chasePendId !== 0) {
          this.chasePendIds.push(item.chasePendId);
          this.pendIds.push(item.chasePendId);
        }
      });
      this.isClinical = false;
      if (!this.isAddressModalVisible) {
        this.pendIds.length > 0 ? this.isEditPendView = true : this.isCreatePendView = true;
      }
      this.formClose.emit();
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }

    this.chaseGridSelection = [];
  }

  get actionableChaseGridSelection(): ChaseItem[] {
    return (ArrayHelper.isAvailable(this.chaseGridSelection))
      ? this.chaseGridSelection.filter(c => !c.disabled)
      : [];
  }

  receiveValue($event) {
    this.chaseGridSelection = [];
    this.isCreatePendView = $event;
  }

  receiveEditPendValue($event) {
    this.isEditPendView = $event;
  }

  uploadChase(): void {
    const retreivalTypeFT = 3;

    this.router.navigate(["retrieval", "chaseUpload", this.ftId, retreivalTypeFT]);
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  showTimelineDetails() {
    return `retrieval/ft/${this.ftId}/timeline`;
  }

  moveChase(rowData?: any): void {
    this.selectRow(rowData);
    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.isAddressModalVisible = true;
    }
  }

  gotoAddressDetail() {
    this.isConfirmModalVisible = false;
    if (this.selectedAddress) {
      const addressType = RetrievalUtilities.getDocumentSourceTypeName(this.selectedAddress);
      this.router.navigateByUrl(`retrieval/${addressType.toLowerCase()}/${this.selectedAddress.masterDocumentSourceId}`);
    }
  }

  confirmMessage(): void {
    this.isConfirmModalVisible = !this.isConfirmModalVisible;
    this.changeDetector.markForCheck();
  }

  onAddressSelectionForChaseMove($event: AddressSearchResult): void {
    this.isMoveVisible = !this.isMoveVisible;
    this.selectedAddress = $event;
  }

  chaseMoved() {
    this.chaseMoveModel = new ChaseMoveModel({
      toAddressId: this.selectedAddress.masterDocumentSourceId.toString(),
      fromAddressId: this.ftId.toString(),
      chaseIds: this.chaseIdList.join(),
      pageType: this.pageType,
      assignedUser: "",
      status: this.isUserMangerAdminLead ? null : "Pending Approval",
      notes: this.selectedAddress.notes,
      isChaseAssign: this.selectedAddress.isChaseAssign,
    });

    this.chaseQryService.chaseMove(this.chaseMoveModel).subscribe(
      () => {
        this.confirmStatusMessage = this.isUserMangerAdminLead ?
          `${this.chaseIdList.length} chase(s) successfully moved to AID ${this.selectedAddress.masterDocumentSourceId}`
          : `Move Request for ${this.chaseIdList.length} chase(s) to AID ${this.selectedAddress.masterDocumentSourceId} successfully submitted`;

        this.updateChaseGrid();
        this.service
          .getTimelineItems()
          .subscribe(items => this.timelineItems = this.assignAndNotify(items));
        this.confirmMessage();
      },
      err => {
        this.isUserMangerAdminLead
          ? this.messagingService.showToast(`Error while moving chase to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR)
          : this.messagingService.showToast(`Error while making chase move request to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR);
      }
    );

    this.isMoveVisible = false;
    this.isAddressModalVisible = false;
    this.changeDetector.markForCheck();
  }

  closeChaseMovePop() {
    this.isMoveVisible = false;
  }


  openFaxModal(rowData: any): void {
    if (!this.tryGetAndSetChaseIdList(rowData)) {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }

    const faxRequest = new FaxRequest({
      ...this.getSharedContactRequestProperties(),
      to: this.ftEditModel.faxNumber,
    });

    this.openTemplateModal(faxRequest);
  }

  printProvider(rowData?: any): void {
    this.chaseIdList = [];
    if (rowData !== undefined) {
      const selectedRows = rowData;
      this.chaseIdList.push(selectedRows.chaseID);
      this.projectId = selectedRows.projectID;
    } else {
      const selectedRows = this.actionableChaseGridSelection;

      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach(item => {
          this.chaseIdList.push(item.chaseID);
          this.projectId = item.projectID;
        });
      } else {
        this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
        return;
      }
    }

    this.providerPacketItem = new ProviderPacketItem({
      chaseIds: this.chaseIdList,
      projectId: this.projectId,
      toName: this.ftEditModel.contactName,
      toPhone: this.ftEditModel.phone,
      toFax: this.ftEditModel.faxNumber,
      fromName: "",
      fromPhone: "",
      providerName: "",
      serviceOrgName: "",
    });

    this.providerService.printProviderPacket(this.providerPacketItem).subscribe(
      data => {
        if (data != null) {
          this.providerService.downloadPdf(data);
          this.chaseGridSelection = [];
          this.changeDetector.markForCheck();
        } else {
          this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
        }
      },
      err => {
        this.messagingService.showToast("Error while printing Provider Packet.", SeverityType.INFO);
      });
  }

  openCommitmentDateModal(rowData?: any) {
    this.selectedRowsForChaseGridAction(rowData);

    if (ArrayHelper.isAvailable(this.chaseIdList)) {
      this.isCommitmentDateModalVisible = true;
    }

    this.commitDateUpdateModel = new CommitmentDateUpdateModel({
      chaseIds: this.chaseIdList,
      functionalRoleId: 5, // Document Retrieval
      retrievalLocationId: this.ftId,
    });
  }

  onCommitmentDateUpdate($event) {
    this.updateChaseGrid();

    this.service
      .getSummaryItems()
      .subscribe(items => this.summaryItems = this.assignAndNotify(items));
  }

  onCommitmentDateModalHide($event) {
    this.chaseIdList = [];
    this.chaseGridSelection = [];
  }

  selectedRowsForChaseGridAction(rowData?: any) {
    this.chaseIdList = [];
    if (rowData != null) {
      const selectedRow = rowData;
      this.chaseIdList.push(selectedRow.chaseID);

    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => this.chaseIdList.push(item.chaseID));
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }
  }

  openPendModal(rowData?: any): void {
    this.selectedChases = [];
    if (rowData != null) {
      this.selectedChases.push(rowData);
    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => this.selectedChases.push(item));
    }

    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.isPendModalVisible = true;
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }
  }

  closeModal(): void {
    this.selectedChases = [];
    this.chaseGridSelection = [];
  }

  changeChaseGridData(): void {
    this.chaseGridData = this.getChaseGridData();
    this.changeDetector.markForCheck();
  }

  openTemplateModal(contactRequest: ContactRequest): void {
    if (!contactRequest.isValid) {
      const toValue = StringHelper.isAvailable(contactRequest.toValue) ? ` '${contactRequest.toValue}' ` : " ";
      this.messagingService.showToast(`The ${contactRequest.toLabel}${toValue}is invalid.`, SeverityType.WARN);
    }

    this.contactRequest = contactRequest;
    this.isTemplateModalVisible = true;
    this.changeDetector.markForCheck();
  }

  sendContactRequest(event: ContactRequest): void {
    this.providerService.sendProviderContactRequest(event).subscribe({
      next: () => {
        this.isTemplateModalVisible = false;
        this.chaseGridSelection = [];
        this.messagingService.showToast(`${event.typeName} Queued for Sending.`, SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      },
      error: () => this.messagingService.showToast(`Error while sending ${event.typeName}, please try again.`, SeverityType.ERROR),
    });
  }

  private tryGetAndSetChaseIdList(rowData: any): boolean {
    const chases = rowData == null ? this.actionableChaseGridSelection : rowData;
    this.chaseIdList = this.getChaseIds(chases);
    return ArrayHelper.isAvailable(this.chaseIdList);
  }

  private getSharedContactRequestProperties(): any {
    return {
      documentSourceId: this.ftId,
      toPhone: this.ftEditModel.phone,
      toName: this.ftEditModel.contactName,
      from: this.phoneNumber,
      fromName: this.userName,
      chaseIds: [...this.chaseIdList],
    };
  }

  private getChaseIds(rowData: any): number[] {
    const chases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    const chaseIds = chases.map(a => Number(a.chaseID));
    return chaseIds;
  }

}
