<app-modal [(visible)]="isUploadDocumentVisible" header="DOCUMENT UPLOAD" (visibleChange)="visibleChange($event)">
  <form [formGroup]="formGroup">
    <div>
      <div class="document-header">DOCUMENT </div>
      <p-fileUpload #form name="file[]" mode="basic" (onSelect)="onUpload($event)" multiple="multiple" customUpload="true" accept="image/*,.txt,.doc,.docx,.xls,.xlsx,.pdf,.zip,.xml,.pptx" maxFileSize="1000000000" chooseLabel="Browse..."></p-fileUpload>
    </div>
    <div>
      <form-factory [formGroup]="formGroup" [model]="descriptionText"></form-factory>
    </div>
  </form>
  <footer>
    <app-button class="header-button" text="UPLOAD"  (onClick)="uploadDocument()" [disabled]="!isEnabled || disableUpload"></app-button>
  </footer>
</app-modal>

