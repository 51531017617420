
export class DocumentAssignmentSummary {

  constructor(
    readonly documentId: number,
    readonly chaseId: number,
    readonly startPage: number,
    readonly endPage: number,
    readonly span: number,
    readonly docType: string,
    readonly pageType: string,
    readonly documentTypeId: number,
    readonly documentAssignmentTypeId: number,
    readonly pageTypeId: number,
    public result?: string,
    readonly workingDocumentId?: number,
    readonly document2Id?: number,
    readonly document2TypeId?: number,
    readonly removeable?: boolean,
    readonly projectName?: string,
    readonly invoiceId?: number

  ) {
    this.documentId = documentId;
    this.chaseId = chaseId;
    this.startPage = startPage;
    this.endPage = endPage;
    this.span = span;
    this.docType = docType;
    this.pageType = pageType;
    this.documentTypeId = documentTypeId;
    this.documentAssignmentTypeId = documentAssignmentTypeId;
    this.pageTypeId = pageTypeId;
    this.result = result;
    this.workingDocumentId = workingDocumentId;
    this.document2Id = document2Id;
    this.document2TypeId = document2TypeId;
    this.removeable = removeable;
    this.projectName = projectName;
    this.invoiceId = invoiceId;
  }
}
