import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { SaveQuery } from "./save-query.model";

@Injectable({
  providedIn: "root",
})
export class MyQueriesService {
  updateQueryFiltersOnly = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
    ) { }

  saveQuery(myQueries: SaveQuery): Observable<SaveQuery> {
    const url = `${this.baseApiUrl}myquery/savequery`;
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post(url, myQueries, { headers }).pipe(
      map(this.automapper.curry("default", "SaveQuery"))
    );
  }

  getSavedQuery(queryId: number): Observable<SaveQuery> {
    const url = `${this.baseApiUrl}myquery/getquery?queryId=${queryId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "SaveQuery"))
    );
  }
}
