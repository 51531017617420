import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class EmrDetailAccessInfoService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private route: ActivatedRoute

  ) { }

  getEmrGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.EmrGd;
  }
}
