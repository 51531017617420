import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { RetrievalCallFlowService } from "../../../retrieval-call-flow/retrieval-call-flow.service";
import { EmrDetailHeaderService } from "../emr-detail-header/emr-detail-header.service";
import { EmrDetailInfoService } from "../emr-detail-info/emr-detail-info.service";
import { EmrDetailEditService } from "./emr-detail-edit.service";
import { EmrEditModel } from "./emr-edit-model";

@Component({
  selector: "retrieval-emr-detail-edit",
  templateUrl: "./emr-detail-edit.component.html",
  styleUrls: ["./emr-detail-edit.component.scss"],
})
export class EmrDetailEditComponent implements OnInit, OnDestroy {
  @Input() isCreateVisible = false;
  @Output() formClose = new EventEmitter();
  private unsubscribe = new Subject();

  emrEditModel: EmrEditModel;
  private emrId: number;

  readonly formGroup: FormGroup;
  url: Textbox;
  email: Textbox;
  phone: Textbox;
  fax: Textbox;
  contactName: Textbox;
  groupName: Textbox;

  constructor(
    private readonly formService: FormService,
    private emrInfoService: EmrDetailInfoService,
    private emrDetailHeaderService: EmrDetailHeaderService,
    private service: EmrDetailEditService,
    private callFlowService: RetrievalCallFlowService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private router: Router,
    private readonly route: ActivatedRoute
  ) {

    this.emrId = this.service.getEmrGdFromPath();

    this.groupName = new Textbox({
      key: "groupName",
      label: "Group Name",
      placeholder: "Group Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Group Name can not be more than 100 characters.",
      },
    });

    this.url = new Textbox({
      key: "url",
      label: "URL",
      placeholder: "Url",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "URL can not be more than 50 characters.",
      },
    });

    this.email = new Textbox({
      key: "email",
      label: "Email",
      placeholder: "Email",
      validators: [Validators.email, Validators.maxLength(50)],
      errorMessages: {
        email: "Please enter a valid email address.",
        maxlength: "Email name can not be more than 50 characters.",
      },
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.fax = new Textbox({
      key: "fax",
      label: "fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
      },
    });


    this.formGroup = this.formService.createFormGroup([this.url, this.email, this.phone, this.fax, this.contactName, this.groupName]);

  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.emrId = +params.get("EmrGd");

      this.service
        .getEmrDetailsForEdit()
        .subscribe(this.assignAndNotify);

    });

    this.service.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => this.updateEmrDetailsForEdit());

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateEmrDetailsForEdit() {
    this.service
      .getEmrDetailsForEdit()
      .subscribe(this.assignAndNotify);
  }

  private assignAndNotify = (data: EmrEditModel): void => {

    this.emrEditModel = new EmrEditModel(data);

    this.loadForm();
  }

  private loadForm() {

    this.formGroup.patchValue({
      url: this.emrEditModel.url,
      email: this.emrEditModel.email,
      phone: this.emrEditModel.phone,
      fax: this.emrEditModel.faxNumber,
      contactName: this.emrEditModel.contactName,
      groupName: this.emrEditModel.groupName,
    });

    this.changeDetector.markForCheck();
  }

  onUpdate(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.getFormValues();

    this.service.updateEmrDetails(this.emrEditModel)
      .subscribe((data: number) => {
        if (data > 0) {
          this.emrInfoService.resetEmr(this.emrId);
          this.emrDetailHeaderService.resetEmr(this.emrId);
          this.callFlowService.resetFunction("");
          this.messagingService.showToast("EMR Site Details successfully edited.", SeverityType.SUCCESS);

        } else {
          this.messagingService.showToast("Failed to edit EMR site Details.", SeverityType.ERROR);
        }
      });

    this.visibleChange(false);

  }

  private getFormValues() {
    this.changeDetector.markForCheck();

    this.emrEditModel.url = this.formGroup.get("url").value;
    this.emrEditModel.email = this.formGroup.get("email").value;
    this.emrEditModel.phone = this.formGroup.get("phone").value;
    this.emrEditModel.faxNumber = this.formGroup.get("fax").value;
    this.emrEditModel.contactName = this.formGroup.get("contactName").value;
    this.emrEditModel.groupName = this.formGroup.get("groupName").value;
  }


  visibleChange($event) {
    this.isCreateVisible = $event;
    this.formClose.emit($event);
  }

}
