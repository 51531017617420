import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AuthorizationLetterAttribute } from "./authorization-letter-attribute.model";

export class ProjectAttribute {
  attributeId: number;
  attributeCode: string;
  attributeName?: string;
  attributeValue: string;
  authorizationLetterAttributes?: AuthorizationLetterAttribute[];
  constructor(options: {
    attributeId: number;
    attributeCode: string;
    attributeName?: string;
    attributeValue: string;
    authorizationLetterAttributes?: AuthorizationLetterAttribute[];
  }) {
    StringHelper.throwIsAvailableError(options.attributeCode, "attributeCode");

    this.attributeId = options.attributeId;
    this.attributeCode = StringHelper.clean(options.attributeCode);
    this.attributeName = StringHelper.isAvailable(options.attributeName) ? StringHelper.clean(options.attributeName) : undefined;
    this.attributeValue = StringHelper.clean(options.attributeValue);
    this.authorizationLetterAttributes = options.authorizationLetterAttributes || [] ;
  }

}
