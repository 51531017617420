import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DocumentListItem } from "../../../../shared/document/chase-document-list/document-list-item.model";
import { ListItem } from "../../../../shared/list/list-item";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { StateMachineExecutionStatus } from "../../../../shared/state-machine/state-machine-execution-status.enum";
import { VendorExclusion } from "../../../api/vendor/vendorExclusion.model";
import { ChaseInvoiceStatusChangeRequest } from "../../approval-center/invoices/chase-invoice-status-change-request.model";
import { DirectoryUserRole } from "../../retrieval/directory-user-role";
import { DocumentQueueResponse } from "./documentqueue-response.model";
import { InvoiceChaseValidation } from "./model/invoice-chase-validation.model";
import { InvoiceDetail } from "./model/invoice-detail.model";
import { InvoiceDocumentDelete } from "./model/invoice-document-delete.model";
import { InvoiceStatusChangeRequest } from "./model/invoice-status-change-request.model";

@Injectable({
  providedIn: "root",
})
export class InvoicesService {

  constructor(private automapper: AutomapperService,
              private authService: AuthService,
              @Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private http: HttpClient) { }

  get isClientLeadRole(): boolean {
    return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.ClientLead) > -1;
  }

  getMenuItems(): Observable<MenuItem[]> {
    const menuItems = [
      {
        description: "Chases",
        path: "chases",
      },
      {
        description: "Documents",
        path: "documents",
      },
      {
        description: "Vendors",
        path: "vendors",
      },

    ];
    if (this.isClientLeadRole) {
      menuItems.splice(0, 2);
    }

    return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }

  getInvoiceStats(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}invoice/stats`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getInvoiceStatuses(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}invoice/statuses`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  validateChases(chaseIds: number[]): Observable<InvoiceChaseValidation[]> {
    const url = `${this.baseApiUrl}invoice/chases/validate`;
    return this.http.post(url, chaseIds).pipe(map(this.automapper.curryMany("default", "InvoiceChaseValidation")));

  }

  saveInvoice(invoiceDetail: InvoiceDetail): Observable<number> {
    const url = `${this.baseApiUrl}invoice`;
    return this.http.post(url, invoiceDetail) as Observable<number>;
  }

  checkInvoiceUploadStatus(stateMachineRequestId: string): Observable<StateMachineExecutionStatus> {
    const url = `${this.baseApiUrl}document/invoice/upload/status?stateMachineRequestId=${stateMachineRequestId}`;
    return this.http.get(url) as Observable<StateMachineExecutionStatus>;
  }

  getDocumentQueueStatus(documentQueueId: number): Observable<DocumentQueueResponse> {
    const url = `${this.baseApiUrl}eventlake/documentqueue?documentQueueId=${documentQueueId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentQueueResponse"))
    );
  }

  getVendorIdByChaseId(chaseId: number): Observable<number> {
    const url = `${this.baseApiUrl}invoice/chase/vendor?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<number>;
  }

  createInvoiceForNET30Vendors(addressId: number, vendorId: number): Observable<null> {
    const url = `${this.baseApiUrl}invoice/net30/vendors?masterDocumentSourceId=${addressId}&vendorId=${vendorId}`;
    return this.http.post(url, {}) as Observable<null>;
  }

  getInvoiceDetails(invoiceId: number): Observable<InvoiceDetail> {
    const url = `${this.baseApiUrl}invoice?invoiceId=${invoiceId}`;
    return this.http.get(url).pipe(map(this.automapper.curry("default", "InvoiceDetail")));
  }

  getInvoiceDocuments(chaseIds: number[], maxRecords: number): Observable<DocumentListItem[]> {
    const chasesQueryString = chaseIds.map(value => `chaseIds=${encodeURIComponent(value.toString())}`).join("&");
    const url = `${this.baseApiUrl}invoice/documents?${chasesQueryString}&maxRecords=${maxRecords}`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "DocumentListItem")));
  }

  updateInvoiceChaseStatus(chaseInvoiceStatusChangeRequest: ChaseInvoiceStatusChangeRequest): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}invoice/chase/status`;
    return this.http.post(url, chaseInvoiceStatusChangeRequest) as Observable<HttpResponse<null>>;
  }

  updateInvoiceStatus(invoiceStatusChangeRequest: InvoiceStatusChangeRequest): Observable<null> {
    const url = `${this.baseApiUrl}invoice/status`;
    return this.http.post(url, invoiceStatusChangeRequest) as Observable<null>;
  }

  getInvoiceChaseStatuses(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}invoice/chase/statuses`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  getInvoiceDetailsByChaseId(chaseId: number): Observable<InvoiceDetail> {
    const url = `${this.baseApiUrl}invoice/chase?chaseId=${chaseId}`;
    return this.http.get(url).pipe(map(this.automapper.curry("default", "InvoiceDetail")));
  }
  removeInvoiceDoc(removeInvoiceDoc: InvoiceDocumentDelete[]): Observable<boolean> {
    const url = `${this.baseApiUrl}invoice/remove/invoiceDoc`;
    return this.http.post(url, removeInvoiceDoc) as Observable<boolean>;
  }

  downloadZippedInvoices(invoices: any[], zipFileName: string): Observable<boolean> {
    const url = `${this.baseApiUrl}invoice/downloadzip?zipFileName=${zipFileName}`;
    return this.http.post(url, invoices) as Observable<boolean>;
  }

  createInvoiceForDeniedNet30Vendor(vendorExclusion: VendorExclusion): Observable<null> {
    const url = `${this.baseApiUrl}invoice/net30/vendors/denied`;
    return this.http.post(url, vendorExclusion) as Observable<null>;
  }

  downloadZippedInvoicesDocuments(invoices: any[], zipFileName: string): Observable<boolean> {
    const url = `${this.baseApiUrl}invoice/documents/downloadzip?zipFileName=${zipFileName}`;
    return this.http.post(url, invoices) as Observable<boolean>;
  }

  getVendorsStats(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}invoice/vendors/stats`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
}
