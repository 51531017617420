import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CheckboxModule } from "primeng/checkbox";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { IconModule } from "../icon/icon.module";
import { InfoItemComponent } from "./info-item/info-item.component";
import { ListComponent } from "./list.component";
import { SummaryComponent } from "./summary/summary.component";

@NgModule({
  declarations: [
    ListComponent,
    SummaryComponent,
    InfoItemComponent,
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    CommonModule,
    FormsModule,
    IconModule,
    RouterModule,
    DynamicFormsModule,
  ],
  exports: [
    ListComponent,
    SummaryComponent,
    InfoItemComponent,
  ],
})
export class ListModule { }
