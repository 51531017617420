import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-intake-productivity-legacy",
  templateUrl: "./intake-productivity-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntakeProductivityLegacyComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.INTAKE_PRODUCTIVITY_REPORT_LEGACY;

  constructor() {
      this.filters = ["Projects", "UpdateDate", "Products", "LOB", "RetrievalOwner"];
  }

}
