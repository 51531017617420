<div class="v2-header__member-info">
  <div class="v2-header__member-info__personal">
    <app-icon (click)="goBack()" iconStyle="far" iconType="fa" iconName="chevron-left"></app-icon>
    <app-list [items]="personalmemberItems"></app-list>
  </div>
  <div class="v2-header__member-info__other">
    <span class="v2-header__member-info__other--required">
      <span class="v2-header__member-info__other--requiredKey" >Project </span>{{ projectName }}
    </span>
    <span class="v2-header__member-info__other--required">
      <span class="v2-header__member-info__other--requiredKey" >LOB </span>{{ lineOfBusiness }}
    </span>
    <span class="v2-header__member-info__other--required">
      <span class="v2-header__member-info__other--requiredKey" >Coding Model </span>{{ codingModel }}
    </span>
    <span class="v2-header__member-info__other--required">
      <span class="v2-header__member-info__other--requiredKey" >Chase ID </span>{{ chaseText }}
    </span>
    <app-list [items]="memberItems"></app-list>
  </div>
</div>