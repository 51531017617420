<div class="jobsqueue" [ngClass]="unreadAndActiveClass" (click)="show($event)">
  <app-icon iconName="cloud"></app-icon>
  <span>{{ countText }}</span>
</div>
<app-popover #overlay appendTo="body">
  <div class="title">
    <span>My jobs as of {{currentDateTime}}</span>
    <!-- commenting "refresh" out until bug where clicking on refresh moves popover to middle of screen-->
    <!--sometimes, can be resolved. Bug assigned to Mike already-->
    <!--<span class="refresh" (click)="refresh($event)">Refresh</span>-->
  </div>
  <div class="--item" *ngFor="let jobQueueItem of jobQueueItems; trackBy: trackByIndex">
    <app-jobqueue-item [jobQueueItem]="jobQueueItem"></app-jobqueue-item>
  </div>

  <div class="see-all--container">
    <a routerLink="/jobsqueue" (click)="overlay.hide()">See All</a>
  </div>
</app-popover>
