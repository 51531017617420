<div class="iconContainer">
  <app-icon-stack [iconName]="timelineItem.type" iconBase="circle"></app-icon-stack>
</div>
<div class="container">
  <div class="titleContainer bold">
    <span class="title">{{ timelineItem.title }}</span>
    <span>{{ timelineItem.details }}</span></div>
  <div class="container__notes" [innerHTML]="getNotes(timelineItem.note)"></div>
  <div class="timestampContainer">
    <span class="user" *ngIf="isPursuitApproved(timelineItem?.title)">by {{ timelineItem.username }}</span>
    <span class="timestamp"> @{{ timelineItem.timestamp }}</span>
  </div>
</div>
