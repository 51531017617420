import { StringHelper } from "../../utilities/contracts/string-helper";

export enum DocumentRequestStatus {
  Active = 65, // 'A'
  Completed = 67, // 'C'
  Deleted = 68, // 'D'
}

export const getDocumentRequestStatusName = (value: number): string => {
  const statusName = Object.keys(DocumentRequestStatus).reduce((name, key) => {
    if (StringHelper.isAvailable(name)) {
      return name;
    }

    const foundName = DocumentRequestStatus[key] === value ? key : "";
    return foundName;
  },                                                           "");
  return statusName;
};
