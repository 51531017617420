import { StringHelper } from "../../../utilities/contracts/string-helper";

export class EmailNotification {
  readonly projectId: number;
  readonly projectName: string;
  readonly loadDate: string;
  readonly totalChases?: number;

  constructor(options: {
    projectId: number;
    projectName: string;
    loadDate: string;
    totalChases?: number;
  }) {
    this.projectId = options.projectId;
    this.projectName = StringHelper.clean(options.projectName);
    this.loadDate = StringHelper.clean(options.loadDate);
    this.totalChases = options.totalChases;
  }
}

