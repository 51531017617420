<app-modal header="Warning"
           [(visible)]="modalVisible"
           (onHide)="onHide($event)">
           You are about to permanently delete this Query.
           Are you sure you want to delete your saved query?
            <footer class="text-center">
              <app-button text="CONTINUE"
                          (onClick)="confirmDeleteQuery()">
              </app-button>
            </footer>

</app-modal>