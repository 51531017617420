import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../core/storage/session.service";
import { DynamicControl } from "../../../../../dynamic-forms/dynamic-control.model";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { ENCOUNTER } from "../../../../api/member-validation/entities";
import { ProviderItem } from "../../../../api/provider/provider-search-result-item";
import { WorkflowStatusDb } from "../../../../api/workflow/workflow-status-db.enum";
import { ClinicalPageService } from "../../../clinical/clinical-page/clinical-page.service";
import { NewProjectService } from "../../../project/new-project/new-project.service";
import { ProjectType } from "../../../project/project-type.enum";
import { RetrievalPageService } from "../../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../../service-org-admin/service-org-config/service-org-config.service";
import { CLAIM_ID, DIAGNOSIS_CODE, ENCOUNTER_TYPE, FROM_DATE, PRODUCT_LIST, PROVIDER_ID, PROVIDER_NAME, THRU_DATE } from "../../chase-detail/chase-detail-chart/attributes";
import { Diagnosis } from "../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { ChaseDetail } from "../../chase-detail/chase-detail.model";
import { ChaseDetailService } from "../../chase-detail/chase-detail.service";
import { CreateChaseService } from "../create-new-chase.service";
import { Encounters } from "../encounters.model";
import { EncounterTypeData } from "../encounterTypeData.model";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { MeasureAttributeData } from "../measure-attribute-data.model";
import { NewChase } from "../new-chase.model";
import { ProjectRetrievalType } from "../project-retrieval-type.enum";
import { NewChaseProjectItem } from "./new-chase-project-item";

@Component({
  selector: "app-create-new-chase-project",
  templateUrl: "./create-new-chase-project.component.html",
  styleUrls: ["./create-new-chase-project.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CreateNewChaseProjectComponent implements OnInit, OnDestroy {
  @Output() onShowChild = new EventEmitter<any>();
  measuresInput: Dropdown;
  formGroupMeasureSelection: FormGroup;
  selectedMeasureName: any;
  chaseKeyInput: Textbox;
  projectInput: Dropdown;
  pursuitInput: Radiobutton;
  pursuitType: Dropdown;
  parentChaseIdInput: Textbox;
  productInput: Dropdown;
  lineOfBusinessInput: Dropdown;
  sampleIdInput: Textbox;
  sequenceInput: Textbox;
  newChase: NewChase;
  measureList: any;
  projectList: any;
  productList: any;
  formGroup = new FormGroup({});
  formModels: DynamicControl[];
  private sink = new SubSink();
  measureAttributeData: MeasureAttributeData[];
  isChaseKeyInvalid: boolean;
  newChaseProjectItem: NewChaseProjectItem;
  @Output() isShowEncounter = new EventEmitter<boolean>(false);
  stateName: string;
  @Output() isShowGap = new EventEmitter<boolean>(false);
  gapChaseCreation = false;
  parentChase: ChaseDetail;
  @Output() selectedProjectType = new EventEmitter<Number>(null);
  constructor(
    private automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private clinicalPageService: ClinicalPageService,
    private createChaseService: CreateChaseService,
    private dataExchangeService: GapQueryDataExchangeService,
    private formService: FormService,
    private messagingService: MessagingService,
    private newProjectService: NewProjectService,
    private retrievalPageService: RetrievalPageService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private chaseDetailService: ChaseDetailService,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.newChase = new NewChase();
    this.createFormControls();
    this.getLineOfBusiness();
    this.getMeasures();
    this.getProjects();
    this.getProducts();
    this.checkIsMeasureSelected();
  }

  get isShowNextStepButton(): boolean {
    return !this.isRiskProjectSelected ? NumberHelper.isAvailable(this.formGroupMeasureSelection.get("measure").value) && this.formGroupMeasureSelection.valid
      && this.formGroup.valid : this.formGroupMeasureSelection.valid;
  }

  getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(result => {
        this.measureList = result;
        this.measuresInput = new Dropdown({ ...this.measuresInput, options: result } as any);
        this.changeDetector.markForCheck();
      });
  }

  getMeasuresByProjectType(projectTypeId?: number): void {
    this.retrievalPageService
      .getMeasuresList(projectTypeId)
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(result => {
        this.measureList = result;
        this.measuresInput = new Dropdown({ ...this.measuresInput, options: result } as any);

        if (projectTypeId === ProjectType.IVA) {
          const measureIVA = this.measuresInput.options.filter(item => item.text === "HST");
          this.measuresInput = new Dropdown({
            ...this.measuresInput,
            options: measureIVA,
          } as any);
          this.formGroupMeasureSelection.get(this.measuresInput.key)?.setValue(this.measuresInput.options[0].value);
          this.formGroupMeasureSelection.get(this.measuresInput.key).disable();
        }

        this.changeDetector.markForCheck();
      });
  }


  getProjects(): void {
    this.gapChaseCreation = this.sessionService.get("gapChaseCreation", false);
    const projectTypeIds = `${ProjectType.HEDIS} , ${ProjectType.RISK}, ${ProjectType.IVA}`;

    this.retrievalPageService
      .getProjectList(null, null, null, projectTypeIds) // fetch project list for type id "1,2" only
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectList = result;
        const options = this.gapChaseCreation ?
          result.filter(x => x.extra.projectRetrivalTypeId === ProjectRetrievalType.Gap) :
          result.filter(x => x.extra.projectRetrivalTypeId !== ProjectRetrievalType.Gap);
        this.projectInput.options = options;
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }


  getLineOfBusinessForIVA() {
    const firstOption = this.lineOfBusinessInput.options.filter(item => item.text === "Commercial");
    this.lineOfBusinessInput = new Dropdown({ ...this.lineOfBusinessInput, options: firstOption} as any);
    this.formGroupMeasureSelection.get(this.lineOfBusinessInput.key).setValue(this.lineOfBusinessInput.options[0].value);
    this.formGroupMeasureSelection.get(this.lineOfBusinessInput.key).disable();
    this.changeDetector.markForCheck();
  }

  getLineOfBusiness() {
    this.newProjectService
      .getLineOfBusiness()
      .subscribe(options => {
        options.forEach(option => {
          option.text = `${option.text}`;
          option.value = `LOB=${option.value}`;
        });
        this.lineOfBusinessInput = new Dropdown({ ...this.lineOfBusinessInput, options } as any);
        this.formGroupMeasureSelection.get(this.lineOfBusinessInput.key).setValue(null);
        this.formGroupMeasureSelection.get(this.lineOfBusinessInput.key).enable();
        this.changeDetector.markForCheck();
      });
  }

  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.productList = productOptions;
    this.productInput = new Dropdown({ ...this.productInput, options: productOptions } as any);

  }

  private createFormControls(): void {
    this.pursuitInput =
      new Radiobutton({
        key: "pursuitInput",
        label: "Pursuit",
        options: [
          new SelectableInput({ text: "Yes", value: "Yes" }),
          new SelectableInput({ text: "No", value: "No" }),
        ],
        value: "No",
    });

    this.pursuitType =
      new Dropdown({
        key: "pursuitType",
        label: "Pursuit Type",
        options: [
           new SelectableInput({ text: "Date Of Service", value: "Date Of Service" }),
           new SelectableInput({ text: "Attestation", value: "Attestation" }),
         ],
        value: "Date Of Service",
    });

    this.parentChaseIdInput =
      new Textbox({
        key: "parentChaseId",
        label: "Chase Id",
    });

    this.chaseKeyInput = new Textbox({
      key: "chaseKey",
      label: "CHASE KEY",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Chase key",
        pattern: "Enter between 1 and 9 digits",
      },
      placeholder: "Enter Chase key",
    });
    this.projectInput = new Dropdown({
      key: "project",
      label: "Project",
      placeholder: "Select Project",
      validators: [Validators.required],
      errorMessages: {
        required: "Select Project",
      },
    });
    this.measuresInput = new Dropdown({
      key: "measure",
      label: "measure",
      placeholder: "Select a Measure",
    });
    this.productInput = new Dropdown({
      key: "product",
      label: "Product",
      placeholder: "Select Product",
    });
    this.lineOfBusinessInput = new Dropdown({
      key: "lineOfBusiness",
      label: "Data Load LOB",
      placeholder: "Select Line Of Business",
    });
    this.sampleIdInput = new Textbox({
      key: "sampleId",
      label: "SAMPLE ID",
      validators: [
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.pattern(RegExHelper.alphaNumeric),
      ],
      errorMessages: {
        minlength: "Sample Id must have minimum of 5 characters.",
        maxlength: "Sample Id must have maximum of 50 characters.",
        pattern: "Sample Id must not contain symbols or special characters.",
      },
      placeholder: "Enter Sample ID",
    });
    this.sequenceInput = new Textbox({
      key: "sequence",
      label: "Sequence",
      validators: [ Validators.pattern(RegExHelper.wholeNumber)],
      errorMessages: {
        pattern: "Sequence must be numeric. Decimal or negative numbers not allowed.",
      },
      placeholder: "Enter Sequence",
    });

    this.formGroupMeasureSelection = this.formService.createFormGroup([
      this.chaseKeyInput,
      this.projectInput,
      this.measuresInput,
      this.productInput,
      this.lineOfBusinessInput,
      this.sampleIdInput,
      this.sequenceInput,
      this.pursuitInput,
      this.pursuitType,
      this.parentChaseIdInput,
    ]);
  }

  nextStepProcess() {
    if (this.formGroupMeasureSelection.valid && this.checkIfHypertensionDateAttributeIsValid(this.formGroup)) {
      this.removeControlCharacters();
      this.newChaseProjectItem = new NewChaseProjectItem();
      this.newChaseProjectItem.chaseKey = this.formGroupMeasureSelection.get("chaseKey").value;
      this.newChaseProjectItem.projectId = this.formGroupMeasureSelection.get("project").value;
      this.newChaseProjectItem.productName = this.formGroupMeasureSelection.get("product").value;
      this.newChaseProjectItem.measureId = this.formGroupMeasureSelection.get("measure").value;
      this.newChaseProjectItem.lineOfBusiness = this.formGroupMeasureSelection.get("lineOfBusiness").value;
      this.newChaseProjectItem.sampleId = StringHelper.isAvailable(this.formGroupMeasureSelection.get("sampleId").value) ? this.formGroupMeasureSelection.get("sampleId").value : null;
      this.newChaseProjectItem.sequence = StringHelper.isAvailable(this.formGroupMeasureSelection.get("sequence").value) ? Number(this.formGroupMeasureSelection.get("sequence").value) : null;

      if (this.createChaseService.chaseModel.isPursuit) {
        this.onShowChild.emit(this.createChaseService.chaseModel);
        this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
        return;
      }

      this.createChaseService.isChaseKeyValid(this.newChaseProjectItem.projectId, this.newChaseProjectItem.chaseKey.toString()).subscribe(isValid => {
        if (isValid) {
          this.isChaseKeyInvalid = false;
          const measureId = this.formGroupMeasureSelection.get("measure").value;
          const selectedMeasure = this.measureList.filter(x => x.value === measureId);
          this.newChaseProjectItem.measureCode = ArrayHelper.isAvailable(selectedMeasure) ? selectedMeasure[0].text : null;
          if (this.formGroup.valid && this.hasAtLeastOneValue(this.formGroup)) {
            this.measureControlsData();
          } else if (!this.formGroup.valid) {
            this.markAllAsTouched();
            return;
          }
          const selectedProject = this.projectList.filter(x => x.value === this.newChaseProjectItem.projectId);
          this.newChaseProjectItem.projectName = selectedProject[0].text;

          const selectedProduct = this.productList.filter(x => x.value === this.newChaseProjectItem.productName);
          this.newChaseProjectItem.productName = selectedProduct[0] ? selectedProduct[0].text : "";

          this.createChaseService.chaseProjectItems = this.newChaseProjectItem;
          this.createChaseService.chaseProjectItems.measureAttributeData = this.measureAttributeData;
          this.createChaseService.chaseModel.measureCode = this.createChaseService.chaseProjectItems.measureCode;
          this.createChaseService.chaseModel.measureId = this.createChaseService.chaseProjectItems.measureId;
          this.createChaseService.chaseModel.projectId = this.createChaseService.chaseProjectItems.projectId;
          this.createChaseService.chaseModel.lineOfBusiness = this.createChaseService.chaseProjectItems.lineOfBusiness;
          this.createChaseService.chaseModel.sampleId = this.createChaseService.chaseProjectItems.sampleId;
          this.createChaseService.chaseModel.sequence = this.createChaseService.chaseProjectItems.sequence;
          this.newChase = this.createChaseService.chaseModel;
          this.onShowChild.emit(this.createChaseService.chaseModel);
          this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
        } else {
          this.isChaseKeyInvalid = true;
          this.changeDetector.markForCheck();
          document.getElementsByClassName("chase-detail-form")[0].scrollIntoView();
        }
      });
    } else {
      this.markAllAsTouched();
    }
  }

  checkIsMeasureSelected() {
    this.sink.add(
      this.formGroupMeasureSelection.get("measure").valueChanges.subscribe(measure => {
        if (measure && !this.isIVAProjectSelected) {
          this.getForm(measure);
          this.changeDetector.markForCheck();
        }
      })
    );
  }

  trackByIndex(index, item) {
    return index;
  }

  getForm(measureId: number): void {
    this.createChaseService.getConfiguration(measureId).subscribe(items => {
      this.formModels = items;
      this.formGroup = this.formService.createFormGroup(items);
      this.changeDetector.markForCheck();
    });
  }

  private markAllAsTouched(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  private hasAtLeastOneValue(control: AbstractControl): boolean {
    const keys = Object.keys(control.value);
    for (const key of keys) {
      const value = control.value[key];
      if (typeof value === "object" && value != null) {
        return this.hasAtLeastOneValue(value);
      } else if (value != null) {
        return true;
      }
    }
    return false;
  }

  private measureControlsData() {
    const entityAttributeData = [];
    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      const keySplitValues = key.toString().split("_");
      const entityTypeId = keySplitValues[0];
      const attributeId = keySplitValues[1];

      entityAttributeData.push({
        entityTypeId: Number(entityTypeId),
        attributeId: Number(attributeId),
        attributeValue: control.value,
      });
    });
    this.measureAttributeData = entityAttributeData;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  reset() {
    this.formGroupMeasureSelection.reset();
    this.formGroup.reset();
    this.isChaseKeyInvalid = false;
  }

  private removeControlCharacters() {
    Object.keys(this.formGroupMeasureSelection.controls).forEach(key => {
      const control = this.formGroupMeasureSelection.get(key).value;
      if (StringHelper.isAvailable(control)) {
        const validValues = control.replace(/[^\x20-\x7E]/g, "");
        this.formGroupMeasureSelection.controls[key].setValue(validValues);
      }
    });
    this.formService.updateDom.next();
  }

  private checkIfHypertensionDateAttributeIsValid(control: AbstractControl): boolean {
    const value = control.value["36_84"];
    if (StringHelper.isAvailable(value) && value === control.value["36_84_1"]) {
      this.messagingService.showToast("Please enter different values for selected measure.", SeverityType.ERROR);
      return false;
    }

    return true;
  }

  get isIVAProjectSelected(): boolean {
    const selectedProject = this.formGroupMeasureSelection.get(this.projectInput.key).value;
    return this.projectList != null && NumberHelper.isAvailable(selectedProject) ? this.projectList.filter(x => x.value === selectedProject)[0].extra.projectTypeId === ProjectType.IVA : false;
  }

  get isRiskProjectSelected(): boolean {
    const selectedProject = this.formGroupMeasureSelection.get(this.projectInput.key).value;
    return this.projectList != null && NumberHelper.isAvailable(selectedProject) ? this.projectList.filter(x => x.value === selectedProject)[0].extra.projectTypeId === ProjectType.RISK : false;
  }

  get isHeidisProjectSelected(): boolean {
    const selectedProject = this.formGroupMeasureSelection.get(this.projectInput.key).value;
    return this.projectList && NumberHelper.isAvailable(selectedProject) && this.projectList.filter(x => x.value === selectedProject)[0].extra.projectTypeId === ProjectType.HEDIS;
  }

  get isGapProjectSelected(): boolean {
    const selectedProjectId = this.formGroupMeasureSelection.get(this.projectInput.key).value;
    return this.projectList !== null && NumberHelper.isAvailable(selectedProjectId) ? this.projectList.filter(x =>
           x.value === selectedProjectId)[0].extra.projectRetrivalTypeId === ProjectRetrievalType.Gap : false;
  }

  get isPursuit(): boolean {
    return this.formGroupMeasureSelection.get(this.pursuitInput.key) && this.formGroupMeasureSelection.get(this.pursuitInput.key).value === "Yes";
  }

  projectSelectionChange(): void {
    this.isShowEncounter.emit(false);
    this.isShowGap.emit(false);
    const selectedProject = this.formGroupMeasureSelection.get(this.projectInput.key).value;
    const projectTypeId = this.projectList.filter(x => x.value === selectedProject)[0].extra.projectTypeId;
    this.selectedProjectType.emit(projectTypeId);
    this.getMeasuresByProjectType(projectTypeId);
    this.formGroupMeasureSelection.get(this.chaseKeyInput.key).reset();
    this.formGroupMeasureSelection.get(this.measuresInput.key).reset();
    this.formGroupMeasureSelection.controls[this.chaseKeyInput.key].clearValidators();
    this.formGroupMeasureSelection.controls[this.chaseKeyInput.key].setValidators([Validators.required]);
    if (this.isRiskProjectSelected) {
      this.formGroupMeasureSelection.get(this.measuresInput.key).reset("");
      this.formGroupMeasureSelection.get(this.sampleIdInput.key).reset("");
      this.formGroupMeasureSelection.get(this.sequenceInput.key).reset("");
      this.isShowEncounter.emit(true);
    } else if (this.isIVAProjectSelected) {
      this.formGroupMeasureSelection.get(this.measuresInput.key).reset("");
      this.formGroupMeasureSelection.get(this.sampleIdInput.key).reset("");
      this.formGroupMeasureSelection.get(this.sequenceInput.key).reset("");
      this.getLineOfBusinessForIVA();
      this.isShowEncounter.emit(true);
      this.formModels = null;
      this.formGroupMeasureSelection.get(this.chaseKeyInput.key)?.setValue("HST-MN-");
      this.formGroupMeasureSelection.controls[this.chaseKeyInput.key].setValidators([Validators.pattern(RegExHelper.manuelIVAchaseKey)]);
      this.formGroupMeasureSelection.controls[this.chaseKeyInput.key].updateValueAndValidity();
    } else {
      this.isGapProjectSelected ? this.isShowGap.emit(true) : this.isShowGap.emit(false);
      this.createChaseService.chaseModel.encounters = null;
      this.dataExchangeService.setIsGapClosureActive(this.isGapProjectSelected);
      this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
      this.getLineOfBusiness();
    }
  }


  setDefaultValueForIVA(): void {
    if (this.isIVAProjectSelected) {
      const letterToCheck = "HST-MN-";
      const chaseKeyValue = this.formGroupMeasureSelection.get(this.chaseKeyInput.key).value;
      if (!chaseKeyValue.startsWith(letterToCheck)) {
        this.formGroupMeasureSelection.get(this.chaseKeyInput.key)?.setValue("HST-MN-");
      }
    }
  }

  validateChase(): void {
    const chaseId = this.formGroupMeasureSelection.get(this.parentChaseIdInput.key).value;
    if (!StringHelper.isAvailable(chaseId)) {
      return;
    }

    this.chaseDetailService.getChaseDetail(Number(chaseId)).subscribe(chaseDetail => {

      if (!this.isValidForPursuitChase(chaseDetail)) {
        this.formGroupMeasureSelection.get(this.parentChaseIdInput.key).setErrors({ "server-error": "Invalid Chase" });
        return;
      }

      this.parentChase = chaseDetail;
      this.populatePursuitChaseInfo();
    });
  }

  private isValidForPursuitChase(chaseDetail: ChaseDetail): boolean {
    if ((chaseDetail.workflowStatusId !== WorkflowStatusDb.Delivery && chaseDetail.workflowStatusId !== WorkflowStatusDb.Completed) ||
          chaseDetail.projectDocumentReviewLevel !== 2 ||
          (chaseDetail.measureCode.toLocaleLowerCase() !== "HCC".toLocaleLowerCase() && chaseDetail.measureCode.toLocaleLowerCase() !== "HST".toLocaleLowerCase()) ||
          chaseDetail.parentChaseId > 0 ||
          chaseDetail.projectTypeId === ProjectType.IVA) {
        return false;
      }

    return true;
  }

  disableControls(): void {
    this.createChaseService.chaseModel.isPursuit = this.isPursuit;
    this.createChaseService.chaseModel.encounters = null;
    if (this.isPursuit) {
      this.formGroupMeasureSelection.get(this.projectInput.key).disable();
      this.formGroupMeasureSelection.get(this.productInput.key).disable();
      this.formGroupMeasureSelection.get(this.chaseKeyInput.key).disable();
      this.formGroupMeasureSelection.get(this.measuresInput.key).disable();
    } else {
      this.formGroupMeasureSelection.get(this.projectInput.key).enable();
      this.formGroupMeasureSelection.get(this.productInput.key).enable();
      this.formGroupMeasureSelection.get(this.chaseKeyInput.key).enable();
      this.formGroupMeasureSelection.get(this.measuresInput.key).enable();
    }
  }

  private populatePursuitChaseInfo(): void {
    this.formGroupMeasureSelection.get(this.parentChaseIdInput.key).setErrors(null);
    this.formGroupMeasureSelection.get(this.projectInput.key).setValue(this.parentChase.projectId);
    this.formGroupMeasureSelection.get(this.productInput.key).setValue(this.parentChase.product);
    this.formGroupMeasureSelection.get(this.chaseKeyInput.key).setValidators(null);

    this.createChaseService.chaseModel.requestType = this.formGroupMeasureSelection.get(this.pursuitType.key).value;
    this.createChaseService.chaseModel.parentChaseId = this.parentChase.chaseId;
    this.createChaseService.chaseModel.memberId = this.parentChase.memberId;
    this.createChaseService.chaseModel.addressId = this.parentChase.addressId;
    this.createChaseService.chaseModel.riskChaseProviderData = this.parentChase.providers.map(provider => {
      return new ProviderItem({
        recordNumber: 0,
        serviceProviderId: provider.providerId,
        sourceAliasId: provider.providerSourceAliasId,
        firstName: null,
        lastName: null,
        serviceProviderName: provider.providerName,
        nationalProviderId: provider.nationalProviderId,
        speciality: provider.providerSpecialty,
        clientProviderId: provider.providerSourceAliasId,
        providerIdAttributeData: PROVIDER_ID,
        providerNameAttributeData: PROVIDER_NAME,
        recordCount: 0,
      });
    });

    this.dataExchangeService.setproviderDetails(this.createChaseService.chaseModel);
    this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
    if (ArrayHelper.isAvailable(this.parentChase.chaseEntityData)) {
      this.createChaseService.chaseModel.encounters = this.parentChase.chaseEntityData
        .filter(item => item.entityTypeName === "Encounter")
        .map(item => {
          return new Encounters({
            claimId: item.attributes.find(attribute => attribute.attributeId === CLAIM_ID.attributeId).value.toString(),
            provider: this.createChaseService.chaseModel.riskChaseProviderData.find(providerItem => providerItem.clientProviderId ===
                      item.attributes.find(attribute => attribute.attributeId === PROVIDER_ID.attributeId).value.toString()),
            encounterType: new EncounterTypeData({
              attributeValue: item.attributes.find(attribute => attribute.attributeId === ENCOUNTER_TYPE.attributeId).value.toString(),
              attributeId: item.attributes.find(attribute => attribute.attributeId === ENCOUNTER_TYPE.attributeId).attributeId,
              attributeCode: item.attributes.find(attribute => attribute.attributeId === ENCOUNTER_TYPE.attributeId).attributeCode.toString(),
            }),
            encounterServiceDateFrom: new Date(item.attributes.find(attribute => attribute.attributeId === FROM_DATE.attributeId).value.toString()),
            encounterServiceDateThru: new Date(item.attributes.find(attribute => attribute.attributeId === THRU_DATE.attributeId).value.toString()),
            claimAttributeData: CLAIM_ID,
            encounterTypeAttributeData: ENCOUNTER_TYPE,
            startDateAttributeData: FROM_DATE,
            endDateAttributeData: THRU_DATE,
            entityTypeData: ENCOUNTER,
            entityId: item.entityId,
            diagnosis: item.diagnoses?.map(diagnosis => {
              return new Diagnosis({
                diagnosisCode: diagnosis.attributes.find(attribute => attribute.attributeId === DIAGNOSIS_CODE.attributeId).value.toString(),
                startDate: new Date(diagnosis.attributes.find(attribute => attribute.attributeId === FROM_DATE.attributeId).value.toString()),
                endDate: new Date(diagnosis.attributes.find(attribute => attribute.attributeId === THRU_DATE.attributeId).value.toString()),
              });
            }),
          });
        });
    }

    this.projectSelectionChange();

    if (this.isRiskProjectSelected) {
      return;
    }

    if (this.formGroupMeasureSelection.get(this.pursuitType.key).value === "Attestation") {
      const attMeasure = this.measuresInput.options.find(measure => measure.text === "ATT");
      this.formGroupMeasureSelection.get(this.measuresInput.key).setValue(attMeasure.value);
    } else {
      this.formGroupMeasureSelection.get(this.measuresInput.key).setValue(this.parentChase.measureId);
    }
  }
}
