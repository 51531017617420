<app-modal 
  [(visible)]="isEditAccessVisible" 
  header="EDIT ACCESS INFORMATION" 
  (visibleChange)="visibleChange($event)"
  (onCancel)="cancelAccessInfo()">
  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="accessAdminContact"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="accessAdminPhone"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="accessITContact"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="accessITPhone"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isEmr || isFieldTech || isPSR">
          <form-factory [formGroup]="formGroup" [model]="accessEmrSystemDropdown"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="showEmrSystem">
          <form-factory [formGroup]="formGroup" [model]="accessEmrSystem"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isEmr">
          <form-factory [formGroup]="formGroup" [model]="credentialStatus"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="twoFactorAuthorization"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="isEmr">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="expirationDate"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="userName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="isEmr">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="password"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="isEmr">
        <form-factory [formGroup]="formGroup" [model]="url"></form-factory>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="accessEditNotes"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="footer-button" text="SAVE" [disabled]="isValidForm" (onClick)="onUpdateAccessInfo()"></app-button>
  </footer>
</app-modal>
