<nav class="risk-dx-menu">
  <a *ngFor="let menu of dxMenuItems; trackBy: trackByFn"
     [routerLink]="menu.path"
     [routerLinkActiveOptions]={exact:true}
     class="risk-dx-menu__link"
     routerLinkActive="active"
     (click)="onSelected(menu)">
    <div class="risk-dx-menu__tab">
      <div class="risk-dx-menu__tab--header">{{menu.description}}</div>
      <div *ngIf="menu.extra.showBadge" class="badge">{{menu.extra.count}}</div>
    </div>
  </a>
  <div class="risk-dx-menu__link risk-dx-menu__submit">
    <app-risk-dx-submit></app-risk-dx-submit>
  </div>
</nav>
