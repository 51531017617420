import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-analytics-instrucions-modal",
  templateUrl: "./analytics-instrucions-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsInstrucionsModalComponent {

  @Input() isHelpModalOpen = false;
  @Output() isHelpModalOpenChange = new EventEmitter<boolean>();

  onClose(): void {
    this.isHelpModalOpen = false;
    this.isHelpModalOpenChange.emit(false);
  }
}
