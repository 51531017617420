import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ContactRecord } from "../../../psr/address-detail/address-detail-contact-record";
import { EmrDetailCallService } from "./emr-detail-call.service";

@Component({
  selector: "retrieval-emr-detail-call",
  templateUrl: "./emr-detail-call.component.html",
  styleUrls: ["./emr-detail-call.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmrDetailCallComponent {

  emrId: number;
  emr = "emr";
  callDirection: string;

  constructor(
    private service: EmrDetailCallService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.emrId = this.service.getEmrGdFromPath();
    this.callDirection = this.service.getCallTypeFromPath();
  }

}
