import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { ContactHistoryService } from "./contact-history.service";

@Component({
  selector: "app-contact-history",
  templateUrl: "./contact-history.component.html",
  styleUrls: ["./contact-history.component.scss"],
  providers: [ContactHistoryService],
})
export class ContactHistoryComponent implements OnInit {

  contactHistoryGridConfiguration = new GridConfiguration();
  contactHistoryGridData: any[] = [];
  contactHistoryGridSelection: any[];

  constructor(private changeDetector: ChangeDetectorRef,
              private service: ContactHistoryService

  ) {
    this.contactHistoryGridConfiguration.columns = [
      new GridColumnDefinition({ field: "userName", header: "User Name" }),
      new GridColumnDefinition({ field: "callStartTime", header: "Call Start Time" }),
      new GridColumnDefinition({ field: "contactDetail", header: "Contact Detail" }),
      new GridColumnDefinition({ field: "callResult", header: "Call Result" }),
      new GridColumnDefinition({ field: "contactMethodType", header: "Contact Type" }),
      new GridColumnDefinition({ field: "callNote", header: "Call Notes" }),
      new GridColumnDefinition({ field: "callDuration", header: "Call Duration" }),
    ];

    this.contactHistoryGridConfiguration.pageSize = 10;
    this.contactHistoryGridConfiguration.selectionMode = "";
    this.contactHistoryGridConfiguration.showActionColumn = false;
  }

  ngOnInit() {
    const callFaxLogIds = window.sessionStorage.getItem("callFaxLogIds");
    if (callFaxLogIds != null) {
      const logIds = JSON.parse(callFaxLogIds);
      const logIdsArray = logIds[0].split(",");
      window.sessionStorage.removeItem("callFaxLogIds");
      this.service
        .getContactHistoryList(logIdsArray)
        .subscribe(this.assignAndNotify);
    }
  }

  assignAndNotify = <T>(data: T[]): void => {
    this.contactHistoryGridData = data as any;
    this.changeDetector.markForCheck();
  }

}
