<td *ngIf="hasRowExpansion" class="grid-body__row-expansion">
  <a href="#"
    [pRowToggler]="rowData">
    <i [ngClass]="expandedIcon"></i>
  </a>
</td>

<td *ngIf="configuration.hasSelectionMode && configuration.showSelectionControl" class="grid-body__selection-mode">
  <p-tableCheckbox *ngIf="configuration.isMultipleSelection && isShowSelector(rowData)"
    [value]="rowData"
    [disabled]="rowData.disabled"></p-tableCheckbox>
  <p-tableRadioButton *ngIf="configuration.isSingleSelection && isShowSelector(rowData)"
    [value]="rowData"></p-tableRadioButton>
</td>

<td *ngFor="let col of filteredColumns; trackBy: trackByIndex"
  [title]="getTitle(rowData, col)"
  class="hdvi-gridcol hdvi-gridcol-{{col.field}}"
  [ngStyle]="{ 'text-align': col.alignment }">

  <span class="ui-column-title">{{col.header}}</span>

  <ng-template *ngIf="col.hasTemplate; else routeUrl"
    [ngTemplateOutlet]="col.template"
    [ngTemplateOutletContext]="{ $implicit: rowData, index: rowIndex }"></ng-template>

  <ng-template #routeUrl>
    <a *ngIf="((col.hasRouteUrl)); else noRouteUrl"
      class="ui-column-value"      
      [ngStyle]="col.styles(getValue(rowData, col), rowData)"
      [routerLink]="generateLink(col,rowData)">{{getValue(rowData, col)}}</a>
  </ng-template>

  <ng-template #noRouteUrl>
    <span class="ui-column-value"      
      [ngStyle]="col.styles(getValue(rowData, col), rowData)">{{getValue(rowData, col)}}</span>
  </ng-template>

</td>

<td *ngIf="hasActionButton" [ngStyle]="{ 'text-align': 'right' }">
  <app-button
    classes="transparent small"
    icon="ellipsis-v"
    (onClick)="gridActionPopover.toggle($event)"
    [disabled]="hasSelectedRows || rowDataDisabled">
  </app-button>
  <app-popover #gridActionPopover appendTo="body">

    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ 
        $implicit: rowData, 
        index: rowIndex,  
        actionPopover: gridActionPopover 
      }">
    </ng-template>

  </app-popover>
</td>
