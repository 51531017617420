import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { BadgeModule } from "../badge/badge.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { IconModule } from "../icon/icon.module";
import { ListModule } from "../list/list.module";
import { PanelModule } from "../panel/panel.module";
import { ToolTipModule } from "../tool-tip/tool-tip.module";
import { UploadModule } from "../upload/upload.module";
import { ChaseDocumentListComponent } from "./chase-document-list/chase-document-list.component";
import { DocumentListComponent } from "./document-list/document-list.component";
import { DocumentHoverHighlightsComponent } from "./document-page-viewer/document-hover-highlights/document-hover-highlights.component";
import { DocumentPageAnnotationsComponent } from "./document-page-viewer/document-page-annotations/document-page-annotations.component";
import { DocumentPageHighlightDosComponent } from "./document-page-viewer/document-page-highlight-dos/document-page-highlight-dos.component";
import { DocumentPageHighlightMemberComponent } from "./document-page-viewer/document-page-highlight-member/document-page-highlight-member.component";
import { DocumentPageHighlightOcrMappingComponent } from "./document-page-viewer/document-page-highlight-ocr-mapping/document-page-highlight-ocr-mapping.component";
import { DocumentPageThumbnailExpandLabeledComponent } from "./document-page-viewer/document-page-thumbnail-expand-labeled/document-page-thumbnail-expand-labeled.component";
import { DocumentPageThumbnailExpandComponent } from "./document-page-viewer/document-page-thumbnail-expand/document-page-thumbnail-expand.component";
import { DocumentPageThumbnailLabelComponent } from "./document-page-viewer/document-page-thumbnail-label/document-page-thumbnail-label.component";
import { DocumentPageThumbnailsComponent } from "./document-page-viewer/document-page-thumbnails/document-page-thumbnails.component";
import { DocumentPageViewerComponent } from "./document-page-viewer/document-page-viewer.component";
import { DocumentRequestArtifactComponent } from "./document-request-artifact/document-request-artifact.component";
import { DocumentRequestGridComponent } from "./document-request-grid/document-request-grid.component";
import { DocumentStateFormComponent } from "./document-state-form/document-state-form.component";
import { DocumentStateModalComponent } from "./document-state-modal/document-state-modal.component";
import { HoldDocumentFormComponent } from "./hold-document-form/hold-document-form.component";
import { HoldDocumentModalComponent } from "./hold-document-modal/hold-document-modal.component";
import { MemberValidationV2Component } from "./member-validation-v2/member-validation-v2.component";
import { MemberValidationYesNoComponent } from "./member-validation/member-validation-yes-no/member-validation-yes-no.component";
import { MemberValidationComponent } from "./member-validation/member-validation.component";
import { RequestDocumentModalComponent } from "./request-document-modal/request-document-modal.component";

@NgModule({
  imports: [
    BadgeModule,
    ButtonModule,
    CommonModule,
    DragDropModule,
    DynamicFormsModule,
    FormsModule,
    GridModule,
    IconModule,
    ListModule,
    PanelModule,
    RouterModule,
    ScrollingModule,
    ToolTipModule,
    UploadModule,
  ],
  declarations: [
    ChaseDocumentListComponent,
    DocumentListComponent,
    DocumentHoverHighlightsComponent,
    DocumentPageAnnotationsComponent,
    DocumentPageHighlightDosComponent,
    DocumentPageHighlightMemberComponent,
    DocumentPageHighlightOcrMappingComponent,
    DocumentPageThumbnailExpandComponent,
    DocumentPageThumbnailExpandLabeledComponent,
    DocumentPageThumbnailLabelComponent,
    DocumentPageThumbnailsComponent,
    DocumentPageViewerComponent,
    DocumentRequestArtifactComponent,
    DocumentRequestGridComponent,
    DocumentStateFormComponent,
    DocumentStateModalComponent,
    HoldDocumentFormComponent,
    HoldDocumentModalComponent,
    MemberValidationComponent,
    MemberValidationV2Component,
    MemberValidationYesNoComponent,
    RequestDocumentModalComponent,
  ],
  exports: [
    ChaseDocumentListComponent,
    DocumentListComponent,
    DocumentPageViewerComponent,
    DocumentRequestArtifactComponent,
    DocumentRequestGridComponent,
    DocumentStateModalComponent,
    HoldDocumentFormComponent,
    HoldDocumentModalComponent,
    MemberValidationComponent,
    MemberValidationV2Component,
    RequestDocumentModalComponent,
    DocumentHoverHighlightsComponent,
    DocumentPageHighlightDosComponent,
    DocumentPageHighlightOcrMappingComponent,
    DocumentPageHighlightMemberComponent,
    DocumentPageAnnotationsComponent,
    MemberValidationYesNoComponent,
  ],
})
export class DocumentModule { }
