import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NotificationItem } from "./notification-item.model";
import { NotificationService } from "./notification.service";


@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class NotificationComponent implements OnInit {

  notificationList: NotificationItem[];
  iconName = "ellipsis-v";
  rows: number;
  totalRecords: number;
  startRecord = 0;
  endRecord = 15;
  recordPerPage = 15;
  notificationsList: NotificationItem[];

  constructor(private readonly service: NotificationService,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.getAllNotification();
  }

  changeDet(): void {
    this.changeDetector.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }

  getUrl(): void {

  }

  onPageChange(event) {
    this.startRecord = ((event.page) * this.recordPerPage) + 1;
    this.endRecord = this.startRecord + (this.recordPerPage - 1);
    this.getAllNotification(this.startRecord, this.endRecord);
  }

  getAllNotification(startRecord?: number, endRecord?: number) {
      this.service
      .getAllNotification(this.startRecord, this.endRecord)
      .subscribe(items => {
        this.notificationList = items;
        this.totalRecords = items[0].NotificationGroup[0].recordCount;
        this.rows = this.recordPerPage;
        this.changeDet();
      });
  }

}

