import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-clinical-productivity-overview",
  templateUrl: "./clinical-productivity-overview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductivityOverviewComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CLINICAL_PRODUCTIVITY_OVERVIEW;

  constructor() {
    this.filters = ["Projects", "Address"];
  }

}
