<div class="collapsible-modal" [ngClass]="modalViewClass">

	<p-dialog 
	  #primeDialog
		[(visible)]="visible"
		[closable]="false" 
		[draggable]="true" 
		[resizable]="false"
		[modal]="foreground" 
		(onShow)="show()" 
		(onHide)="hide()" 
		(visibleChange)="change($event)">

		<p-header class="collapsible-modal__header">
			<div class="collapsible-modal__header--title">{{header}}</div>
			<div class="collapsible-modal__header--button">
				<app-icon iconName={{iconName}} (click)="toggleView()"></app-icon>
			</div>
		</p-header>

		<ng-content></ng-content>

		<p-footer>
			<app-button *ngIf="allowClose" classes="secondary" [text]="rejectText" (onClick)="cancel($event)"></app-button>
			<ng-content select="footer"></ng-content>
		</p-footer>

	</p-dialog>
</div>

<div *ngIf="visible && allowClose" (document:keydown.escape)="cancel()"></div>