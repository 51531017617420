import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { SessionService } from "../core/storage/session.service";
import { PlatformService } from "../platform/platform.service";
import { MenuItem } from "../shared/menu/menu-item.model";
import { ArrayHelper } from "../utilities/contracts/array-helper";
import { StringHelper } from "../utilities/contracts/string-helper";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private platformService: PlatformService,
              private router: Router, private sessionService: SessionService
  ) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const menusData = this.sessionService.get("user_menus", null);
    if (StringHelper.isAvailable(menusData)) {
      const menus = JSON.parse(menusData);
      return this.checkAuthorization(menus, next, state);
    } else {
      return this.getMenusData(next, state);
    }
  }

  checkAuthorization(routes: MenuItem[], next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const menus = routes.map(r => r.children);
    const subMenus = menus.filter(m => m.some(k => [state.url, next.data.defaultPath].includes(k.path)));
    if (ArrayHelper.isAvailable(subMenus)) {
      return true;
    }

    const defaultPaths = Array.isArray(next.data.defaultPath) ? next.data.defaultPath : [next.data.defaultPath];
    const matchAnyPath = menus.some(m => m.some(k => defaultPaths.includes(k.path)));
    if (matchAnyPath) {
      return true;
    }

    this.router.navigate(["403"]);
    return false;
  }

  getMenusData(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.platformService.getMenu().toPromise().then(routes => {
      this.sessionService.put("user_menus", JSON.stringify(routes));
      return this.checkAuthorization(routes, next, state);
    });
  }
}
