<div class="card-holder">
  <app-risk-dx-card-header *ngIf="!addNewCoder" [icdGroup]="icdGroup"></app-risk-dx-card-header>
  <app-risk-dx-grid [(diagnoses)]="icdGroup.diagnoses" *ngIf="!addNewCoder"  [icdGroup]="icdGroup" [disabled]="shouldDisableControl"
   [addNewDx]="addNewDx"
   (removeDx)="removeDx()"
   [riskChaseState]="riskChaseState"
   (saveNewDiagnosis)="saveNewDiagnosis($event)"
   (removeDiagnosis)="removeDiagnosis($event)"
   ></app-risk-dx-grid>

   <app-risk-dx-grid  *ngIf="addNewCoder"
   [addNewDx]="addNewCoder"
   (removeDx)="removeDx()"
   [riskChaseState]="riskChaseState"
   (saveNewDiagnosis)="saveNewDiagnosis($event)"
   (removeDiagnosis)="removeDiagnosis($event)"
   ></app-risk-dx-grid>
  <app-risk-dx-validation-buttons *ngIf="!addNewCoder" (onValid)="onValid()" (onInvalid)="onInvalid()"
                                  [disabled]="shouldDisableControl"
                                  [isValidated]="icdGroup.isValidated"
                                  (addNewDx)="addNewDiagnosis()"
                                  [isAllDxAddedByCoder]="isAllDxAddedByCoder"
                                  ></app-risk-dx-validation-buttons>
</div>
