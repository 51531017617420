import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ContactRecord } from "../../../psr/address-detail/address-detail-contact-record";
import { FtDetailCallService } from "./ft-detail-call.service";

@Component({
  selector: "retrieval-ft-detail-call",
  templateUrl: "./ft-detail-call.component.html",
  styleUrls: ["./ft-detail-call.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDetailCallComponent {

  ftId: number;
  ft = "ft";
  callDirection: string;

  constructor(
    private service: FtDetailCallService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.ftId = this.service.getFTGdFromPath();
    this.callDirection = this.service.getCallTypeFromPath();
  }

}
