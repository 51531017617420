import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { CommitmentDateUpdateModel } from "./commitmentDateUpdate.model";

@Injectable({
  providedIn: "root",
})
export class CommitmentDateService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  updateCommitmentDate(updateCommitmentDateModel: CommitmentDateUpdateModel): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/updateCommitmentDate`;

    return this.http.post(url, updateCommitmentDateModel);
  }
}
