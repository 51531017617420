import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { ChartModel } from "../../../../shared/kpi/model/chart.model";
import { DataModel } from "../../../../shared/kpi/model/data.model";
import { LabelModel } from "../../../../shared/kpi/model/label.model";
import { ListItem } from "../../../../shared/list/list-item";
import { MemberService } from "./member.service";

@Component({
  selector: "platform-member-home",
  templateUrl: "./member.component.html",
  styleUrls: ["./member.component.scss"],
})
export class MemberComponent implements OnInit {
  memberStatisticsForHeader = List<ListItem>();

  chartModelMemberComplianceCode: ChartModel;
  labelModelMemberComplianceCode: LabelModel;
  dataModelMemberComplianceCode: DataModel;
  dataOptionsMemberComplianceCode: any;
  arrMemberComplianceCode: DataModel[] = [];

  chartModelExemptedMembers: ChartModel;
  labelModelExemptedMembers: LabelModel;
  dataModelExemptedMembers: DataModel;
  dataOptionsExemptedMembers: any;
  arrExemptedMembers: DataModel[] = [];

  constructor(private memberService: MemberService, private changeDetector: ChangeDetectorRef, private router: Router) {
    this.chartModelMemberComplianceCode = new ChartModel({
      chartHeight: 400, chartWidth: 1280, chartHeader: "MEMBER COMPLIANCE",
      chartType: "bar", headerClassName: "BarHeader", isResponsive: false,
    });

    this.dataOptionsMemberComplianceCode = {
      responsive: true,
      legend: {
        display: true,
        position: "bottom",
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: false,
          barThickness: 50,
          scaleLabel: {
            display: false,
            labelString: "Member Compliance",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            max: 60,
            min: 0,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrMemberComplianceCode.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const callsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - callsCompletedWidthNum);
            });
          });
        },
      },
    };

    this.chartModelExemptedMembers = new ChartModel({
      chartHeight: 400, chartWidth: 1280, chartHeader: "EXEMPTED MEMBERS",
      chartType: "bar", headerClassName: "BarHeader", isResponsive: false,
    });

    this.dataOptionsExemptedMembers = {
      responsive: true,
      legend: {
        display: true,
        position: "bottom",
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: false,
          barThickness: 50,
          scaleLabel: {
            display: false,
            labelString: "Exempted Members",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            max: 60,
            min: 0,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrExemptedMembers.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const callsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - callsCompletedWidthNum);
            });
          });
        },
      },
    };
  }

  ngOnInit() {
    this.getStatisticsData();
    this.getMemberCompliance();
    this.getExemptedMembers();
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }
  trackByIndex(index, item) {
    return index;
  }

  getStatisticsData(): void {
    this.memberService.getStatisticsData().subscribe(
      result => {
        this.memberStatisticsForHeader = this.assignAndNotify(result);
      });
  }

  getMemberCompliance(): void {
    this.memberService.getMemberCompliance().subscribe(
      result => {
        this.labelModelMemberComplianceCode = new LabelModel({ labels: result.labels });
        this.dataModelMemberComplianceCode = new DataModel({
          data: result.data[0], backgroundColor: "#1a4570", borderColor: "#1a4570", label: "Members",
        });
        this.arrMemberComplianceCode = [];
        this.arrMemberComplianceCode.push(this.dataModelMemberComplianceCode);
        this.changeDetector.markForCheck();
      });
  }

  getExemptedMembers(): void {
    this.memberService.getExemptedMembers().subscribe(
      result => {

        this.labelModelExemptedMembers = new LabelModel({ labels: result.labels });
        this.dataModelExemptedMembers = new DataModel({
          data: result.data[0], backgroundColor: "#1a4570", borderColor: "#1a4570",
          label: "Exemption",
        });
        this.arrExemptedMembers = [];
        this.arrExemptedMembers.push(this.dataModelExemptedMembers);
        this.changeDetector.markForCheck();
      });
  }
}
