import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { ActionButton } from "../../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../../zdevcontrols/dev-controller/dev-controller.service";
import { ProjectDocumentType } from "../../project/project-files/project-document-type.enum";
import { ProjectFileService } from "../../project/project-files/project-file.service";
import { ProjectFile } from "../../project/project-files/project-file/project-file.model";

@Component({
  selector: "app-audit-packages",
  templateUrl: "./audit-packages.component.html",
  styleUrls: ["./audit-packages.component.scss"],
  providers: [ProjectFileService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditPackagesComponent implements OnInit {
  actions: BulkAction[];
  auditPackages: ProjectFile[] = [];
  configuration: GridConfiguration;
  projectId: number;
  readonly projectDocumentTypeId = ProjectDocumentType.AuditPackage;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  userId: number;

  get headerTitle(): string {
    return "Audit Packages";
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly devService: DevControllerService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService,
    private readonly route: ActivatedRoute,
    private readonly service: ProjectFileService, // TODO: remove later
    private readonly userService: UserService
  ) {
    this.userId = this.userService.getUserToken().userId;
  }

  ngOnInit() {
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);

    this.route.paramMap.subscribe(params => {
      this.projectId = +params.get("projectId");
    });

    this.createGrid();
    this.loadGrid();
  }

  createGrid(): void {
    this.configuration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "projectdocumentid", header: "Project File ID", show: false }),
        new GridColumnDefinition({ field: "projectid", header: "Project ID", show: false }),
        new GridColumnDefinition({ field: "filename", header: "File Name" }),
        new GridColumnDefinition({ field: "description", header: "Description" }),
        new GridColumnDefinition({ field: "username", header: "Uploaded by" }),
        new GridColumnDefinition({ field: "lastmodified", header: "Upload Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      ],
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
    });

    this.actions = [
      new BulkAction({
        name: "Download",
        action: this.downLoadPackage.bind(this),
      }),
      new BulkAction({
        name: "Delete",
        action: this.deletePackage.bind(this),
      }),
    ];
  }

  loadGrid() {
    this.service
      .getProjectFiles(this.projectId, 3)
      .subscribe(items => {
        this.auditPackages = items;
        this.changeDetector.markForCheck();
      });
  }

  downLoadPackage(rowData): void {
    this.service.downloadFile2(rowData.projectdocumentid, this.userId, "auditPackage", this.projectDocumentTypeId);
  }

  deletePackage(rowData): void {
    const projectFileDeleteRequest = {
      Filename: rowData.filename,
      ProjectId: this.projectId,
      ProjectDocumentId: rowData.projectdocumentid,
    };
    this.service.deleteProjectFile(projectFileDeleteRequest).subscribe(response => {
      if (response) {
        this.messagingService.showToast(`${projectFileDeleteRequest.Filename} has been deleted successfully.`, SeverityType.SUCCESS);
        this.loadGrid();
      } else {
        this.messagingService.showToast(`There was a problem deleting ${projectFileDeleteRequest.Filename}.`, SeverityType.ERROR);
      }
    });
  }

}
