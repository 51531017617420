import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent extends ModalComponent {
  @Input() acceptText = "Yes";
  @Input() rejectText = "No";
  @Input() isConfirmDisabled = false;
  @Output() onYes: EventEmitter<any> = new EventEmitter();
  confirmCallbackFunction: Function;

  onYesClick(): void {

    this.onYes.emit(null);
    if (this.confirmCallbackFunction) {

      this.confirmCallbackFunction(true);
    }

    this.hide();
  }

  cancel(): void {

    this.onCancel.emit();
    if (this.confirmCallbackFunction) {

      this.confirmCallbackFunction(false);
    }

    this.hide();
  }

  confirm(callbackFunction: Function) {
    this.confirmCallbackFunction = callbackFunction;
    this.show();


  }
}
