import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";


@Injectable({
    providedIn: "root",
})

export class TagStateService {
    closeExtraTooltip = new BehaviorSubject<any>(false);
    private existingTagList = new BehaviorSubject<SelectableInput[]>(null);
    existingTagLists = this.existingTagList.asObservable();

    private allAvailableTags = new BehaviorSubject<SelectableInput[]>(null);
    allAvailableTagList = this.allAvailableTags.asObservable();

    setNewTagToExistingTagList(recentlyAddedTag: SelectableInput[]) {
        this.existingTagList.next(recentlyAddedTag);
    }

    clearData(): void {
        this.existingTagList.next(null);
    }

    setAllAvailableTags(allTags: SelectableInput[]) {
        this.allAvailableTags.next(allTags);
    }

}
