import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../shared/button/button.module";
import { IconModule } from "../shared/icon/icon.module";
import { MessagingModule } from "../shared/messaging/messaging.module";
import { PanelModule } from "../shared/panel/panel.module";
import { PrivacyPolicyModule } from "../shared/privacy-policy/privacy-policy.module";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./login/login.component";
import { MfaComponent } from "./mfa/mfa.component";
import { SsoRedirectComponent } from "./sso-redirect/sso-redirect.component";
import { SsoComponent } from "./sso/sso.component";

@NgModule({
  imports: [
    AuthRoutingModule,
    ButtonModule,
    CommonModule,
    DynamicFormsModule,
    IconModule,
    MessagingModule,
    PanelModule,
    PrivacyPolicyModule,
  ],
  declarations: [LoginComponent, MfaComponent, SsoComponent, SsoRedirectComponent],
})
export class AuthModule { }
