import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../../../auth/auth.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { PopoverComponent } from "../../../shared/panel/popover/popover.component";
import { JobQueueItem } from "../../modules/jobsqueue/jobqueue-item.model";
import { JobQueueUnRead } from "../../modules/jobsqueue/jobqueue-unread.model";
import { JobsqueueService } from "../../modules/jobsqueue/jobsqueue.service";
import { MyJobsRequest } from "../../modules/jobsqueue/myjobs-request.model";


@Component({
  selector: "platform-jobsqueue",
  templateUrl: "./jobsqueue.component.html",
  styleUrls: ["./jobsqueue.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsqueueComponent implements OnInit, OnDestroy {
  @ViewChild("overlay", { static: true }) private overlay: PopoverComponent;
  private intervalUpdateCount;
  private unreadJobsCount: number;
  private newCount: number;
  jobQueueItems: JobQueueItem[] = [];
  pageSize = 5;
  read: boolean;
  jobQueueUnRead: JobQueueUnRead;

  constructor(private changeDetector: ChangeDetectorRef,
              private service: JobsqueueService,
              private authService: AuthService,
              private messagingService: MessagingService
  ) { }

  ngOnInit() {

    this.getUnreadJobsCount();
    this.intervalUpdateCount = setInterval(() => {
      this.getUnreadJobsCount();
    },                                     60000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalUpdateCount);
  }

  get hasUnreadJobs(): boolean {
    return this.unreadJobsCount > 0;
  }

  get countText(): string {
    return this.hasUnreadJobs ? this.unreadJobsCount.toString() : "";
  }

  get unreadAndActiveClass(): string {
    const unread = this.hasUnreadJobs ? "jobs__unread" : "";
    const active = this.overlay.visible ? "jobs__active" : "";
    return `${unread} ${active}`.trim();
  }

  get currentDateTime(): string {

    return moment().format("hh:mm:ss A");
  }

  show(event: MouseEvent) {
    if (!this.overlay.visible) {
      this.overlay.show(event);
      this.markAllAsRead();
      this.changeDetector.markForCheck();
    }
  }

  refresh(event: MouseEvent) {
    if (this.overlay.visible) {
      this.getJobsAndShow(event);
      this.markAllAsRead();
    }
  }

  private getUnreadJobsCount() {
    if (this.authService.isAuthenticated) {
      this.service
        .getMyJobsUnReadCount()
        .subscribe(item => {
          this.jobQueueUnRead = item;
          this.unreadJobsCount = this.jobQueueUnRead.unReadCount;
          this.newCount = this.jobQueueUnRead.newCount;
          this.getJobs();

          if (this.newCount > 0) {
            this.toastNewJobs();
          }

          this.changeDetector.markForCheck();
        });
    }

  }


  private toastNewJobs() {

    this.messagingService.showToast(`You have ${this.newCount} new Job Notifications`, SeverityType.SUCCESS);

  }

  getJobs(): void {
    const myJobsRequest =
      new MyJobsRequest({
        stateMachineId: null,
        statusId: null,
        sortDirection: "desc",
        sortBy: "stepExecutionId",
        pageSize: this.pageSize,
        pageNumber: 1,
      });

    this.service
      .getMyJobs(myJobsRequest)
      .subscribe(items => {
        this.jobQueueItems = items;
        if (this.newCount > 0) {
          this.service.notifyNewJobEvent(this.jobQueueItems);
        }
      });
  }
  private getJobsAndShow(event: MouseEvent): void {

    const myJobsRequest =
      new MyJobsRequest({
        stateMachineId: null,
        statusId: null,
        sortDirection: "desc",
        sortBy: "stepExecutionId",
        pageSize: this.pageSize,
        pageNumber: 1,
      });

    this.service
      .getMyJobs(myJobsRequest)
      .subscribe(items => {
        this.jobQueueItems = items;
        this.overlay.show(event);
        this.changeDetector.markForCheck();
      });

  }


  private markAllAsRead(): void {
    this.service.updateMyJobsUnReadCount().subscribe();
    this.read = false;
    this.unreadJobsCount = 0;
    this.changeDetector.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }

}
