<div class="encounter-v2__header">
  <app-icon iconName="user" *ngIf="isEve"></app-icon>
  {{encounterTitle}}
</div>
<div class="encounter-v2" (click)="showEncountersFilter()">
  <div class="encounter-v2--actions">
    <app-badge text="Adding new encounter" [isLarge]="false" cssClasses="badge-diagnoses add_enc"
               *ngIf="isCoderEncounter"></app-badge>
    <member-risk-encounter-delete [encounter]="encounter" *ngIf="isCoderEncounter"></member-risk-encounter-delete>
  </div>
  <div class="encounter-v2__icon" [ngClass]="eveIconClass">
    <app-icon *ngIf="showEveIcon" iconName="user"></app-icon>
  </div>
  <div class="encounter-v2__group" [ngClass]="{'full-width' : isAdminEncounter}">
    <div class="encounter-v2__row" [ngClass]="{'full-width' : isAdminEncounter}" *ngIf="!isCoderEncounter">
      <div class="encounter-v2__encSaveGroup">
        <div class="encounter-v2__encounter-found-header">Valid Encounter</div>
        <member-risk-validation (onValidated)="onValidated($event)"
                                [isEnabled]="isEnabledByMemberValidation">
        </member-risk-validation>
      </div>
      <div [ngClass]="showCoderClass" class="encounter-v2__idSaveGroup" *ngIf="!isEve">
        <form-factory [formGroup]="form" [model]="idInput" (onChange)="onInputChange($event)"></form-factory>
      </div>
    </div>
    <div [ngClass]="{'encounter-v2__row full-width': isAdminEncounter}">
      <div class="encounter-v2__encounterProvider" *ngIf="isAdminEncounter">
        <form-factory [formGroup]="form" [model]="providerTextInput"></form-factory>
      </div>
      <div [ngClass]="showAdminClass">
        <form-factory [formGroup]="form" [model]="encounterTypeInput" (onChange)="onInputChange($event)"></form-factory>
      </div>
    </div>
  </div>
  <div class="encounter-v2__page-number" *ngIf="!adminEncounterSelected">
    <form-factory [formGroup]="form" [model]="pageNumberInput" (onChange)="onInputChange($event)"></form-factory>
  </div>
  <div class="encounter-v2__date-range" *ngIf="!adminEncounterSelected">
    <form-factory [formGroup]="form" [model]="startDateInput" (onChange)="onInputChange($event)"></form-factory>
  </div>
  <div class="encounter-v2__date-range" *ngIf="!adminEncounterSelected">
    <form-factory [formGroup]="form" [model]="endDateInput" (onChange)="onInputChange($event)"></form-factory>
  </div>
  <div class="encounter-v2__provider" *ngIf="!adminEncounterSelected">
    <form-factory [formGroup]="form" [model]="providerInput" (onChange)="onInputChange($event)"></form-factory>
  </div>
  <div class="encounter-v2__row full-width">
    <app-button *ngIf="showSaveButton" [disabled]="!isSaveEnabled" [class.disabled]="!isSaveEnabled" class="encounter-save-button" title="Submit" text="Save Encounter" (onClick)="save()"></app-button>
    <div class="encounter-v2__exempt" *ngIf="isOverread">
      <form-factory [formGroup]="form" [model]="exemptCheckbox" (onChange)="onInputChange($event, true)"></form-factory>
    </div>
  </div>
</div>
