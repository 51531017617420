import { DateHelper } from "../../utilities/contracts/date-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

export class TimelineItem {
  readonly type: string;
  readonly title: string;
  readonly details: string;
  readonly note: string;
  readonly username: string;
  readonly timestamp: string;
  readonly urlPath?: string;
  readonly urlText?: string;

  constructor(options: Partial<TimelineItem> = {}) {
    StringHelper.throwIsAvailableError(options.type, "type");
    StringHelper.throwIsAvailableError(options.title, "title");
    StringHelper.throwIsAvailableError(options.username, "username");
    StringHelper.throwIsAvailableError(options.timestamp, "timestamp");

    this.type = StringHelper.clean(options.type);
    this.title = StringHelper.clean(options.title);
    this.details = StringHelper.clean(options.details);
    this.note = StringHelper.clean(options.note);
    this.username = StringHelper.clean(options.username);
    this.timestamp = DateHelper.format(options.timestamp, "MM/DD/YYYY hh:mm A");
    this.urlPath = options.urlPath;
    this.urlText = options.urlText;
  }

  get hasDetails(): boolean {
    return StringHelper.isAvailable(this.details);
  }

  get hasNote(): boolean {
    return StringHelper.isAvailable(this.note);
  }

  get hasHeadset(): boolean {
    return StringHelper.isAvailable(this.type) && this.type === "headset";
  }
}

