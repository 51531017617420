import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { CdnFolderModel } from "./cdn-folder.model";
import { DocumentPage } from "./document-page.model";

export class DocumentPages {
  s3ImagesFolders: CdnFolderModel[];
  pages: DocumentPage[];
  isCdnBucketFolderChecked?: boolean;

  constructor(options: Partial<DocumentPages> = {}) {
    this.pages = options.pages;
    this.isCdnBucketFolderChecked = options.isCdnBucketFolderChecked;
    this.s3ImagesFolders = ArrayHelper.isAvailable(options.s3ImagesFolders) ? options.s3ImagesFolders : [];
  }
}
