import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { CreateChaseService } from "../create-new-chase.service";

@Component({
  selector: "app-create-new-chase-success-confirmation",
  templateUrl: "./create-new-chase-success-confirmation.component.html",
  styleUrls: ["./create-new-chase-success-confirmation.component.scss"],
})
export class CreateNewChaseSuccessConfirmationComponent implements OnInit, OnDestroy {
  newChaseId: number;
  parentChaseId: number;
  constructor(private createChaseService: CreateChaseService, private router: Router) {

  }

  ngOnInit() {
    this.newChaseId = this.createChaseService.chaseModel.chaseId;
    this.parentChaseId = this.createChaseService.chaseModel.parentChaseId;
  }

  viewChaseDetail(): void {
    this.router.navigate(["members", "chase", this.newChaseId]);
  }

  viewMemberCentricParentChaseDetail(): void {
    this.router.navigate(["members", "chase", this.parentChaseId]);
  }

  get getMemberCentricParentChaseId(): boolean {
    return NumberHelper.isGreaterThan(this.parentChaseId, 0);
  }

  ngOnDestroy() {
     this.createChaseService.clear();
  }
}
