import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { List } from "immutable";
import { ChaseDocumentService } from "../../../platform/modules/member/chase-document.service";
import { DocumentListItem } from "./document-list-item.model";

@Component({
  selector: "app-chase-document-list",
  template: `
    <app-document-list
      [display]="display"
      [width]="width"
      [height]="height"
      [documents]="documentListItems"></app-document-list>
  `,
  providers: [ChaseDocumentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDocumentListComponent implements OnInit {
  @Input() display = "inline";
  @Input() width = "300px";
  @Input() height = "400px";
  @Input() maxDocuments = 3;
  documentListItems = List<DocumentListItem>();


  constructor(
    private service: ChaseDocumentService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const chaseId = this.service.getChaseGdFromPath();
    this.service
      .getDocumentListItems(chaseId, this.maxDocuments)
      .subscribe(this.assignAndNotifyListDocumentItems);
  }


  private assignAndNotifyListDocumentItems = (data: DocumentListItem[]): void => {
    this.documentListItems = List(data);
    this.changeDetector.markForCheck();
  }
}
