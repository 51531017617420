<div class="diagnoses-header">
  <app-icon iconName="user"></app-icon>&nbsp;
  <div class="diagnoses-header__title">EVE Clinical Terms</div>
  <app-badge [text]="totalDiagnoses" [isLarge]="false" cssClasses="badge-diagnoses counter"></app-badge>
</div>
<table class="terms-item">
  <tr *ngFor="let terms of clinicalTerms; trackBy: trackByIndex">
    <td class="term-description">{{terms.text}}</td>
    <td class="term-pages">
      <div class="term-pages--item" *ngFor="let page of terms.pageNumbers; index as idx; trackBy: trackByIndex"
        (click)="updatePage(page, terms)">
        {{page}}<span *ngIf="idx + 1 < terms.pageNumbers?.length ">, </span>
      </div>
    </td>
  </tr>
</table>
