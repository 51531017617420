import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ChaseDetailHealthCard } from "./chase-detail-health-card.model";

@Injectable({
  providedIn: "root",
})
export class ChaseDetailHealthCardService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private automapper: AutomapperService,
    private http: HttpClient
  ) { }

  getHealthCard(chaseId: number): Observable<ChaseDetailHealthCard[]> {
    const url = `${this.baseApiUrl}chase/get/healthcard?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ChaseDetailHealthCard"))
    );
  }
}
