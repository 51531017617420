import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { obsolete } from "../../../utilities/decorators/obsolete.decorator";

export class DynamicEntityAttribute {
  chaseId: number;
  chaseIds: number[];
  entityId: number;
  entityTypeId: number;
  parentEntityId: number;
  attributeId: number;
  @obsolete("attributeId") id: number;
  @obsolete("attributeCode") code: string;
  attributeCode: string;
  value: string | number | boolean | null;
  options: SelectableInput[];
  isChanged: boolean;
  isAdmin: boolean;
  isSupplemental: boolean;
  sourceTypeName: string;

  pageNumber: number | null;
  validationId: number | null;
  validationNote: string | null;
  isNlp: boolean;
  diagnosisCode: string;
  sourceTypeId: number | null;

  constructor(options: {
    chaseId?: number;
    chaseIds?: number[];
    entityId?: number;
    entityTypeId?: number;
    parentEntityId?: number;
    attributeId?: number;
    attributeCode?: string;
    value?: string | number | boolean | null;
    options?: SelectableInput[];
    isChanged?: boolean;
    isAdmin?: boolean;
    isSupplemental?: boolean;
    sourceTypeName?: string;
    pageNumber?: number | null;
    validationId?: number | null;
    validationNote?: string | null;
    isNlp?: boolean;
    diagnosisCode?: string;
    sourceTypeId?: number | null;
  } = {}) {
    this.chaseId = options.chaseId;
    this.chaseIds = options.chaseIds;
    this.entityId = options.entityId;
    this.entityTypeId = options.entityTypeId;
    this.parentEntityId = options.parentEntityId;
    this.id = (options as any).id || options.attributeId;
    this.attributeId = options.attributeId || (options as any).id;
    this.code = (options as any).code || options.attributeCode || "";
    this.attributeCode = options.attributeCode || (options as any).code || "";
    this.value = options.value || null;
    this.options = options.options || [];
    this.isChanged = BooleanHelper.tryGet(options.isChanged, false);
    this.isAdmin = BooleanHelper.tryGet(options.isAdmin, false);
    this.isSupplemental = BooleanHelper.tryGet(options.isSupplemental, false);
    this.sourceTypeName = options.sourceTypeName;
    this.pageNumber = NumberHelper.isGreaterThan(Number(options.pageNumber), 0) ? Number(options.pageNumber) : null;
    this.validationId = NumberHelper.isGreaterThan(Number(options.validationId), 0) ? Number(options.validationId) : null;
    this.validationNote = options.validationNote;
    this.isNlp = BooleanHelper.tryGet(options.isNlp, false);
    this.diagnosisCode = options.diagnosisCode;
    this.sourceTypeId = options.sourceTypeId;
  }
}
