import { Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-project-status-report-dw",
  templateUrl: "./project-status-report-dw.component.html",
})
export class ProjectStatusReportDwComponent {

    filters: string[];
    hideFilters: string[];
    dashboardType = LookerRiskDashboardType.PROJECT_STATUS_REPORT_DW;

    constructor() {
        this.filters = ["Projects"];
        this.hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
    }
}
