import { FormArray } from "@angular/forms";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { DynamicControl, IDynamicControlOptions } from "../dynamic-control.model";
import { DynamicFormHelper } from "../dynamic-form-helper";
import { DynamicGroup } from "../form-groups/dynamic-group.model";

export interface IDynamicArrayOptions extends IDynamicControlOptions {
  header?: string;
  models?: DynamicGroup[];
  template?(): DynamicGroup;
}

export abstract class DynamicArray extends DynamicControl implements IDynamicArrayOptions {
  header: string;
  template: () => DynamicGroup;
  models: DynamicGroup[];


  constructor(options: IDynamicArrayOptions = {}) {
    super(options);
    this.header = options.header || "";
    this.template = options.template;
    this.models = options.models || [];
  }


  get hasHeader(): boolean {
    return StringHelper.isAvailable(this.header);
  }

  get hasModels(): boolean {
    return Array.isArray(this.models) && this.models.length > 0;
  }

  create(): FormArray {
    const controls = ArrayHelper.isAvailable(this.models)
      ? this.models.reduce(DynamicFormHelper.createControls, [])
      : [this.template().create()];
    const array = new FormArray(controls, this.validators, this.asyncValidators);

    return array;
  }

  push(): void {
    this.models.push(this.template());
  }

  remove(index: number): void {
    this.models.splice(index, 1);
  }
}
