import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-retrieval-report",
  templateUrl: "./retrieval-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalReportComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.RETRIEVAL_REPORT;
  constructor() {
    this.filters = ["Projects", "ChartReceived"];
  }

}
