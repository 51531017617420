import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../../core/automapper/automapper.service";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../../utilities/contracts/number-helper";
import { Diagnosis } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { RiskEntity } from "../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskState } from "../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../chase-detail/chase-detail-state.service";

@Component({
  selector: "member-risk-diagnosis-v2",
  templateUrl: "./diagnosis.component.html",
  styleUrls: ["./diagnosis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisV2Component implements OnInit, OnDestroy {
  private sink = new SubSink();
  diagnoses: Diagnosis[];
  adminDiagnoses: Diagnosis[];
  coderDiagnoses: Diagnosis[];
  eveCodedDiagnoses: Diagnosis[];
  chaseDetailState: ChaseDetailState;
  riskState: RiskState;
  isVRCWarningVisible = false;
  showReadOnly = false;

  constructor(
    private readonly automapper: AutomapperService,
    private readonly riskService: RiskService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).pipe(
        filter(([riskState]) => ArrayHelper.isAvailable(riskState.encounters)),
        tap(() => this.cleanDiagnoses()),
        map(([riskState, chaseDetailState]) => {
          this.chaseDetailState = chaseDetailState;
          this.riskState = riskState;
          this.showReadOnly = (!riskState.isSelectedEncounterValid || !riskState.isEnabled) && riskState.assignedToCurrentUser;
          const diagnosisEntities: RiskEntity[] = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
          this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
          this.adminDiagnoses = this.getAdminDiagnoses();
          this.coderDiagnoses = this.getCoderDiagnoses();
          this.eveCodedDiagnoses = this.getEveCodedDiagnoses();
        })).subscribe(() =>
          this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  showModal(show: boolean): void {
    this.isVRCWarningVisible = show;
  }

  private cleanDiagnoses() {
    this.adminDiagnoses = [];
    this.coderDiagnoses = [];
    this.eveCodedDiagnoses = [];
  }

  private getAdminDiagnoses(): Diagnosis[] {
    return this.diagnoses.filter(option => option.isAdmin && NumberHelper.isAvailable(option.encounterId) && NumberHelper.isAvailable(option.diagnosisId));
  }

  private getCoderDiagnoses(): Diagnosis[] {
    return this.diagnoses.filter(option => option.isManuallyAddedSource);
  }

  private getEveCodedDiagnoses(): Diagnosis[] {
    const unsortedEve = this.diagnoses.filter(option => option.isEveDiagnosis && option.nlpReviewResult === "ADD");
    const sortedEve = this.sortCards(unsortedEve);
    return unsortedEve;
    // TODO: Match sortedEve with eveDiagnosesRisk
    // return sortedEve;
  }

  sortCards(diagnoses: Diagnosis[]): Diagnosis[] {
    const clone = JSON.parse(JSON.stringify(diagnoses));
    for (let a = 0; a < clone.length; a++) {
      for (let b = 0; b < clone.length - a - 1; b++) {
        if (clone[b].confidenceScore < clone[b + 1].confidenceScore) {
          const swap = clone[b];
          clone[b] = clone[b + 1];
          clone[b + 1] = swap;
        }
      }
    }
    return JSON.parse(JSON.stringify(clone));
  }

  get hasAdminDiagnoses() {
    return ArrayHelper.isAvailable(this.adminDiagnoses);
  }

  get hasCoderDiagnoses() {
    return ArrayHelper.isAvailable(this.coderDiagnoses);
  }

  get hasEveDiagnoses() {
    return ArrayHelper.isAvailable(this.eveCodedDiagnoses);
  }
}
