export class EncounterTypeData {
     attributeId?: number;
     attributeCode?: string;
     attributeValue?: string;
     attributeText?: string;

     constructor(options: {
       attributeId?: number;
       attributeCode?: string;
       attributeValue?: string;
       attributeText?: string;
    }) {
      this.attributeId = options.attributeId;
      this.attributeCode = options.attributeCode;
      this.attributeValue = options.attributeValue;
      this.attributeText = options.attributeText;
    }

  }

