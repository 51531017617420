export class WorkflowStatusModel {
  readonly workflowStatusId: number;
  readonly workflowStatusName: string;

  constructor(options: {
    workflowStatusId: number;
    workflowStatusName: string;
  }) {
    this.workflowStatusId = options.workflowStatusId;
    this.workflowStatusName = options.workflowStatusName;
  }
}
