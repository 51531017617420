import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-color-palette-manage",
  templateUrl: "./color-palette-manage.component.html",
  styleUrls: ["./color-palette-manage.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPaletteManageComponent implements OnInit {

  defaultColors: string[];
  @Input() heading: string;
  @Input() color: string;
  @Input() isAddColor: boolean;
  @Output() selectedColor = new EventEmitter();
  @Input() isCreateTagTemplateVisible = false;
  isColorPalatteVisible = false;
  defaultColor = "#DCDCDC";
  tagHeaderText = "Create New Tag";
  @Input() hasTagName: string;
  showColorPalette = false;

  ngOnInit() {
   this.defaultColorPalette();
   if (this.hasTagName !== "") {
     this.showColorPalette = true;
    }
   this.tagHeaderText = this.isAddColor ? "Add color" : this.tagHeaderText;

  }

  private defaultColorPalette(): string[] {
    this.defaultColors = [
      "#DCDCDC",
      "#FFD5D5",
      "#FFDA7D",
      "#C2D7EC",
      "#9ADDB6",
    ];
    return this.defaultColors;
  }

  getClass(color: string): string {
    let hoverClass = "circle";
    switch (color) {

      case "#DCDCDC":
        return hoverClass += " grey";

      case "#C2D7EC":
        return hoverClass += " blue";

      case "#FFD5D5":
        return hoverClass += " pink";

      case "#FFDA7D":
        return hoverClass += " yellow";

      case "#9ADDB6":
        return hoverClass += " green";

      default:
        return "";
    }
  }

  changeColor(color: string) {
    this.color = color;
    this.selectedColor.emit(this.color);
    this.isColorPalatteVisible = false;
  }

  getIcon(): string {
    return "far fa-plus-circle";
  }

  trackByIndex(index, item) {
    return index;
  }

}
