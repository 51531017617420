import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class GapQueryRouteFilters {
  constructor(
    readonly chaseId: string,
    readonly chaseKey: string,
    readonly projectIds: string,
    readonly providerName: string,
    readonly addressId: string,
    readonly gaps: string,
    readonly gapStatus: string,
    readonly product: string,
    readonly memberFirstName: string,
    readonly memberLastName: string,
    readonly chaseStatuses: string,
    readonly measures: string,
    readonly chaseCompliances: string,
    readonly sampleCompliances: string,
    readonly gapCompliances: string,
    readonly pendCode: string,
    readonly pendStatus: string,
    readonly tagId: string
  ) {
    this.chaseId = StringHelper.clean(chaseId);
    this.chaseKey = StringHelper.clean(chaseKey);
    this.projectIds = StringHelper.clean(projectIds);
    this.providerName = StringHelper.clean(providerName);
    this.product = StringHelper.clean(product);
    this.addressId = StringHelper.clean(addressId);
    this.gaps = StringHelper.clean(gaps);
    this.gapStatus = StringHelper.clean(gapStatus);
    this.product = StringHelper.clean(product);
    this.memberFirstName = StringHelper.clean(memberFirstName);
    this.memberLastName = StringHelper.clean(memberLastName);
    this.chaseStatuses = StringHelper.clean(chaseStatuses);
    this.measures = StringHelper.clean(measures);
    this.chaseCompliances = StringHelper.clean(chaseCompliances);
    this.sampleCompliances = StringHelper.clean(sampleCompliances);
    this.gapCompliances = StringHelper.clean(gapCompliances);
    this.pendCode = StringHelper.clean(pendCode);
    this.pendStatus = StringHelper.clean(pendStatus);
    this.tagId = StringHelper.clean(tagId);
  }
}
