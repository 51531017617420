import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ChaseSubmissionItem } from "../../../platform/modules/project/project-config/models/chase-submission-item.model";

@Component({
  selector: "app-drag-list",
  templateUrl: "./drag-list.component.html",
  styleUrls: ["./drag-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragListComponent {
  @Input() value: any[];
  @Output() valueChange = new EventEmitter<ChaseSubmissionItem[]>();

  addItem() {
    this.value.push({ itemText: "" });
    this.change([...this.value]);
  }

  deleteItem(index: number): void {
    this.value.splice(index, 1);
    this.change([...this.value]);
  }

  onTextChange(text: string, index: number): void {
    this.value[index].itemText = text;
    this.change([...this.value]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
    this.change([...this.value]);
  }

  private change(value: ChaseSubmissionItem[]): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  trackByIndex(index, item) {
    return index;
  }
}
