import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-historical-status-indicators-dwreport",
  templateUrl: "./historical-status-indicators-dwreport.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoricalStatusIndicatorsDWReportComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.HISTORICAL_STATUS_INDICATORS_REPORT_DW;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID,
                        HideFilters.SUSPECT_HCC, HideFilters.LAST_DOS_PCP, HideFilters.LAST_DOS_OTHER, HideFilters.PCP_STATE, HideFilters.PRIORITY];
  }

}
