import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { MasterProvider } from "../../../../shared/national-provider-grid/national-provider.model";
import { ChaseSearchRequest } from "../../../api/chase-search/chase-search-request-model";
import { AddressSearchResult } from "../address-search/address-search-result.model";
import { DocumentSourceType } from "../retrieval-document-source-type.enum";
import { AddressDetail } from "./address-detail.model";

@Injectable()
export class AddressDetailService {
  private pCallProvider = new BehaviorSubject<MasterProvider>(null);
  callProvider = this.pCallProvider.asObservable();

  private pResetProviderCall = new BehaviorSubject<boolean>(null);
  resetProviderCall = this.pResetProviderCall.asObservable();

  private pAidChanged = new BehaviorSubject<number>(null);
  aidChanged = this.pAidChanged.asObservable();

  private isLoadChaseGrid = new Subject<boolean>();
  isChaseGridReload = this.isLoadChaseGrid.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getAddressDetail(addressId: number): Observable<AddressDetail> {
    if (addressId == null) {
      return throwError("Address Id is invalid.");
    }

    const url = `${this.baseApiUrl}address/detail?addressId=${addressId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "AddressDetail"))
    );
  }

  getMenuItems(): Observable<MenuItem[]> {
    const url = `${this.baseApiUrl}address/menuitems`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }

  getGridMenuItems(isFieldTech: boolean, documentSourceTypeId: number): Observable<MenuItem[]> {
      const isThirdParty = documentSourceTypeId === DocumentSourceType.THIRDPARTY;
      const menuItems = [
      {
        description: "Chases",
        path: "./",
      },
      {
        description: "Members",
        path: "members",
      },
      {
        description: "Pends",
        path: "pends",
      },
      {
        description: "Providers",
        path: "providers",
      },
      {
        description: "Pursuits",
        path: "pursuits",
      },
      {
            description: "Invoices",
            path: "invoices",
            show: isThirdParty,
      },
    ];

      return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }

  getRetrievalList(gridSearchRequest: ChaseSearchRequest): Observable<AddressSearchResult[]> {
    const url = `${this.baseApiUrl}retrieval/list`;

    return this.http.post(url, gridSearchRequest).pipe(
      map(this.automapper.curryMany("address-search-item", "AddressSearchResult"))
    );
  }

  initiateCallProvider(provider: MasterProvider) {
    this.pCallProvider.next(provider);
  }

  resetProviderCallFlow(value: boolean) {
    this.pResetProviderCall.next(value);
  }

  clearProviderSearchResults(value: number) {
    this.pAidChanged.next(value);
  }

  loadChaseGrid(value: boolean): void {
    this.isLoadChaseGrid.next(value);
  }
}
