import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chase-move-back-history-report",
  templateUrl: "./chase-move-back-history-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveBackHistoryReportComponent  {
  filters: string[] = ["Projects", "Products", "LOB", "RetrievalOwner"];
  dashboardType = LookerRiskDashboardType.CHASE_MOVE_BACK_HISTORY_REPORT;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.MOVEBACK_DATE,
                           HideFilters.HEALTH_PLAN, HideFilters.LINE_OF_BUSINESS, HideFilters.CONTRACT_NUMBER,
                           HideFilters.CO_RETRIEVAL, HideFilters.PRODUCT, HideFilters.MOVEBACK_BY];
}
