import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { CommentItem } from "../comments/comment-item/comment-item";
import { ListItem } from "../list/list-item";
import { ChasePendItem } from "./chase-pend-item.model";

@Injectable({
  providedIn: "root",
})
export class EditPendService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getChasePendData(chasePendId: number): Observable<ChasePendItem> {
    const url = `${this.baseApiUrl}Pend/ChasePendData?chasePendId=${chasePendId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ChasePendItem"))
    );
  }

  getPendDropdown(isClinical: boolean, organizationId: number): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}Pend/GetPendDropdowns?isClinical=${isClinical}&organizationId=${organizationId}`;
    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getAssignedToUsersList(ownedBy: string, chasePendId: number, roleType?: string): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}Pend/AssignToUserList?owner=${ownedBy}&chasePendId=${chasePendId}&roleType=${roleType}`;
    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getNotesList(chasePendId: number): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Pend/Comment?pendId=${chasePendId}&isOnlyLatest=false`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  updatePend(chasePendItem: ChasePendItem): Observable<1 | 0> {
    // TODO: Change this to return a boolean.
    const url = `${this.baseApiUrl}Pend/UpdatePend`;
    return this.http.post(url, chasePendItem).pipe(
      map(response => !!response ? 1 : 0)
    );
  }

  getPendCompaniesDropdown(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}pend/companylist`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }
}
