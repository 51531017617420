import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RiskSystemResultType } from "./risk-system-result-type.enum";

@Component({
  selector: "member-risk-system-result",
  templateUrl: "./risk-system-result.component.html",
  styleUrls: ["./risk-system-result.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskSystemResultComponent {
  @Input() data: any;

  get showResultType(): boolean {
    return this.data.isAdminWithBotSource;
  }

  get resultClass(): string {
    switch (this.data.nlpReviewResult) {
      case RiskSystemResultType.MATCH: {
        return "result-icon__match";
        break;
      }
      case RiskSystemResultType.NO_MATCH: {
        return "result-icon__no-match";
        break;
      }
      case RiskSystemResultType.DOS_MATCH_ONLY: {
        return "result-icon__dos-match-only";
        break;
      }
      default: {
        break;
      }
    }
  }

}
