<div class="login">

    <div class="login__icon">
      <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-vertical"></app-icon>
    </div>
  
      <div class="login__form">
          <form [formGroup]="form">
          <div class="text-center">
        <b>Additional Security Verification</b>
        <br>
        <span class="login__verificationMessage">An email with a 6-digit verification code has been sent to <b>{{contactValue}}</b></span>
        <br><br>
    </div>
        <form-factory [formGroup]="form"
          [model]="mfaCode"></form-factory>

        <div class="ui-g ui-fluid text-center login__errorMessage">
          <app-messages key="loginMessage" class="ui-messages"></app-messages>
        </div>
    
        <app-button (onClick)="verifyCode()" text="VERIFY" [disabled]="!isValidCode"></app-button>
  
        <a class="login__resendCode" (click)="sendCode()">Resend Code</a>
  
      </form>
    </div>
  
  </div>
