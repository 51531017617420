import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chart-routing-dw",
  templateUrl: "./chart-routing-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartRoutingDwComponent {
  filters: string[] = [];
  dashboardType = LookerRiskDashboardType.CHART_ROUTING_DW;
  hideFilters = [HideFilters.USER_ID2, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN,
                 HideFilters.CONTRACT_NUMBER, HideFilters.PROJECT_NAME2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS,
                 HideFilters.CO_RETRIEVAL2, "Current_Chase_Status", "Current_Process_Step",
  ];
}
