<form-standard-group [formGroup]="formGroup" [model]="model" (onChange)="onChangeEvent($event)"></form-standard-group>

<div class="control control--calculated">
  <div class="control__header">
    <label class="control__header__label">Enrollment + 42 Days</label>
  </div>
  <div
    *ngIf="hasEnrollmentPlus42Days"
    class="control__calculated">
    {{ enrollmentPlus42Days | date:"MM/dd/yyyy" }}
  </div>
  <div
    *ngIf="!hasEnrollmentPlus42Days"
    class="control__calculated">
    none
  </div>
</div>
<div class="clearfix"></div>

<form-dates-between [model]="ppc.prenatal" type="Prenatal"></form-dates-between>
<form-dates-between [model]="ppc.postpartum" type="Postpartum"></form-dates-between>