import { FormGroup } from "@angular/forms";
import { DynamicFormHelper } from "../../../dynamic-forms/dynamic-form-helper";
import { DynamicInput } from "../../../dynamic-forms/inputs/dynamic-input.model";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridFilter } from "./grid-filter.model";

export class GridRequest {
    url: string;
    startRecord: number;
    endRecord: number;
    sortField: string;
    sortDirection: number;
    filters: GridFilter[];
    form: FormGroup;
    isAllSelected: boolean;

    constructor(options: {
        url?: string;
        startRecord?: number;
        endRecord?: number;
        sortField?: string;
        sortDirection?: number;
        filters?: GridFilter[];
        form?: FormGroup;
        isAllSelected?: boolean;
    } = {}) {
        this.url = options.url;
        this.startRecord = options.startRecord > 0 ? options.startRecord : 0;
        this.endRecord = options.endRecord > options.startRecord
            ? options.endRecord
            : options.startRecord + 1;
        this.sortField = StringHelper.isAvailable(options.sortField) ? options.sortField : null;
        this.sortDirection = options.sortDirection === -1 ? -1 : 1; // desc : asc
        this.filters = options.filters || [];
        this.form = options.form || null;
        this.isAllSelected = options.isAllSelected || null;
    }

    get inputs(): DynamicInput[] {
        const inputs = this.filters.map(filter => filter.input);
        return inputs;
    }

    getFilter(key: string): GridFilter {
        const foundFilter = this.filters.find(filter => filter.key === key);
        if (foundFilter == null) {
            throw new Error(`The filter '${key}' could not be found.`);
        }

        if (foundFilter?.key === "RetrievalType") {
            const navigationTypeFilter = this.filters.find(filter => filter.key === "NavigationType");
            if (navigationTypeFilter) {
                navigationTypeFilter.value = foundFilter.value;
            }
        }
        return foundFilter;
    }

    getInput<T extends DynamicInput>(key: string): T {
        const filter = this.getFilter(key);
        return filter.input as T;
    }

    setInput<T extends DynamicInput>(key: string, value: T): void {
        const filter = this.getFilter(key);
        filter.input = value;
    }

    setForm(): void {
        const formControls = this.inputs.reduce(DynamicFormHelper.createControls, {});
        this.form = new FormGroup(formControls);
        this.filters.forEach(filter => filter.setForm(this.form));
    }

    createRequestObject(): any {
        const filters = this.filters
            .filter(this.hasValue)
            .reduce(this.arrayToObject, {});

        const result = {
            startRecord: this.startRecord,
            endRecord: this.endRecord,
            sortField: this.sortField,
            sortDirection: this.sortDirection === -1 ? "desc" : "asc",
            ...filters,
        };
        return result;
    }

    resetFilters(): void {
        this.filters
            .filter(filter => this.hasValue(filter) && this.isShowing(filter))
            .forEach(filter => filter.value = filter.input.value = "");
    }


    private hasValue = (filter: GridFilter): boolean => {
        return filter.hasValue;
    }

    private isShowing = (filter: GridFilter): boolean => {
        return filter.show && StringHelper.isAvailable(filter.displayName);
    }

    private arrayToObject = (acc: any, filter: GridFilter): any => {
        acc[filter.key] = filter.value || filter.input.value;
        return acc;
    }
}
