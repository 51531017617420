import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { InputSwitchModule } from "primeng/inputswitch";
import { ToolTipModule } from "../../../shared/tool-tip/tool-tip.module";
import { SwitchComponent } from "./switch.component";

@NgModule({
  imports: [
    CommonModule,
    InputSwitchModule,
    ReactiveFormsModule,
    ToolTipModule,
  ],
  declarations: [
    SwitchComponent,
  ],
  entryComponents: [
    SwitchComponent,
  ],
  exports: [
    SwitchComponent,
  ],
})
export class SwitchModule { }
