import { StringHelper } from "../../../utilities/contracts/string-helper";

export class GridPipe {

  constructor(
    private readonly pipe: { transform: any },
    private readonly isFormattable = true
  ) {
    this.pipe = pipe;
    this.isFormattable = typeof isFormattable === "boolean" ? isFormattable : true;
  }


  transform(value: string, format = "", timeZone = "") {
    // TODO: unit test this.
    const useFormatter = this.isFormattable && StringHelper.isAvailable(format);
    const useTimeZone = StringHelper.isAvailable(format) && StringHelper.isAvailable(timeZone);

    const transformedValue = useFormatter ?
                              (useTimeZone ? this.pipe.transform(value, format, timeZone)
                              : this.pipe.transform(value, format))
                              : this.pipe.transform(value);

    return transformedValue;
  }
}
