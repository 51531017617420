import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { DIAGNOSIS, ENCOUNTER } from "../../../../api/member-validation/entities";
import { ProviderItem } from "../../../../api/provider/provider-search-result-item";
import { CLAIM_ID, DIAGNOSIS_CODE, ENCOUNTER_TYPE, FROM_DATE, THRU_DATE } from "../../chase-detail/chase-detail-chart/attributes";
import { RiskService } from "../../chase-detail/chase-detail-chart/risk/risk.service";
import { CreateChaseService } from "../create-new-chase.service";
import { Encounters } from "../encounters.model";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { NewChase } from "../new-chase.model";

@Component({
  selector: "app-create-new-chase-encounters",
  templateUrl: "./create-new-chase-encounters.component.html",
  styleUrls: ["./create-new-chase-encounters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseEncountersComponent implements OnInit {

    constructor(
      private readonly fb: FormBuilder,
      private changeDetector: ChangeDetectorRef,
      private createChaseService: CreateChaseService,
      private readonly riskService: RiskService,
      private readonly formService: FormService,
      private dataExchangeService: GapQueryDataExchangeService
  ) {
      this.formGroup = fb.group({
          encounter: fb.array([]),
      });
  }

    get isShowNextStepButton(): boolean {
        return this.formGroup.valid || this.createChaseService.chaseModel.isPursuit;
    }

    get hasDuplicateClaimIdError(): boolean {
        return this.formGroup.controls.encounter.errors != null && this.formGroup.controls.encounter.errors.hasOwnProperty("duplicateClaimIdError");
    }
    encounterInput: Textbox;
    diagnosisInput: Dropdown;
    formGroup: FormGroup;
    @Output() onShowChild = new EventEmitter<any>();
    newChase: NewChase;
    isVisibleMessageModal: boolean;
    encounterTypeList: SelectableInput[];
    diagnosisListFiltered: SelectableInput[];
    selectedProvider: string;
    validationErrorMessages: string[];
    isClaimInvalid: boolean;
    provider: Dropdown;
    encounterType: Dropdown;
    providerFormGroup: FormGroup;
    encounterTypeFormGroup: FormGroup;
    formDefaultData: any;
    providerList: ProviderItem[];
    providerOptions: SelectableInput[] = [];
    isFromParentChase = false;

    ngOnInit() {
        this.createForm();
        this.newChase = this.createChaseService.chaseModel;
        this.dataExchangeService.getproviderDetails().subscribe(result => {
            if (result) {
                this.providerList = result.riskChaseProviderData.map(item => {this.providerList = result.riskChaseProviderData; return item; });
                this.providerOptions = result.riskChaseProviderData.map(item => ({ text: item.serviceProviderName,
                                                                                   value: item.clientProviderId, label: item.serviceProviderName, extra: [], disabled: false }));
                this.autoPopulateEncounters();
                this.provider = new Dropdown({ ...this.provider, options: this.providerOptions } as any);
                this.changeDetector.markForCheck();
            }
        });
        this.getEncounterTypes();
    }

    autoPopulateEncounters(): void {
        this.formGroup = this.fb.group({
            encounter: this.fb.array([]),
        });

        if (ArrayHelper.isAvailable(this.newChase.encounters)) {
            this.newChase.encounters.map(encounter => {
                this.addNewEncounter(encounter);
            });
            this.formGroup.disable();
        } else {
            this.formDefaultData = [
                {
                    claimId: "",
                    provider: this.provider,
                    encounterType: this.encounterType,
                    encounterServiceDateFrom: "",
                    encounterServiceDateThru: "",
                    claimAttributeData: ["", ""],
                    encounterTypeAttributeData: ["", ""],
                    startDateAttributeData: ["", ""],
                    endDateAttributeData: ["", ""],
                    entityTypeData: ["", ""],
                    diagnosis: ["", ""],
                },
            ];

            if (this.formDefaultData) {
                this.formDefaultData.forEach(encounter => {
                    this.addNewEncounter(encounter);
                });
            } else {
                this.addNewEncounter();
            }
        }
    }

    addNewEncounter(encounter?: any) {
        const formGroup = this.fb.group({
            claimId: [encounter ? encounter.claimId : ""],
            encounterServiceDateFrom: [encounter ? DateHelper.format(encounter.encounterServiceDateFrom, "MM/DD/YYYY") : "", Validators.pattern(RegExHelper.date)],
            encounterServiceDateThru: [encounter ? DateHelper.format(encounter.encounterServiceDateThru, "MM/DD/YYYY") : "", Validators.pattern(RegExHelper.date)],
            claimAttributeData: CLAIM_ID,
            encounterTypeAttributeData: ENCOUNTER_TYPE,
            startDateAttributeData: FROM_DATE,
            endDateAttributeData: THRU_DATE,
            entityTypeData: ENCOUNTER,
            diagnosis: this.fb.array([]),
            hide: [false],
            entityId: [encounter ? encounter.entityId : null],
        });

        formGroup.addControl("provider", this.providerFormGroup);
        formGroup.addControl("encounterType", this.encounterTypeFormGroup);
        if (this.createChaseService.chaseModel.isPursuit) {
            formGroup.controls.provider.get(this.provider.key).setValue(encounter?.provider?.clientProviderId);
            formGroup.controls.encounterType.get(this.encounterType.key).setValue(encounter?.encounterType?.attributeValue);
        }

        (this.formGroup.get("encounter") as FormArray).push(formGroup);
        const encounterIndex = (this.formGroup.get("encounter") as FormArray).length - 1;
        if (!encounter) {
            this.addDiagnosis(encounterIndex);
        } else {
            encounter.diagnosis.forEach(x => {
                this.addDiagnosis(encounterIndex, x);
            });
        }
        this.formGroup.controls.encounter.setValidators(this.duplicateClaimIdValidator);
        this.formGroup.updateValueAndValidity();
    }

    deleteEncounter(encounter: any): void {
        encounter.controls.hide.setValue(true);
    }

    addDiagnosis(userIndex: number, data?: any) {
        this.isFromParentChase = this.createChaseService.chaseModel.isPursuit;

        const diagnoseFormGroup = this.fb.group({
            diagnosisCode: [data ? data.diagnosisCode.toString() : "", Validators.required],
            diagnosisServiceDateFrom: [data ? DateHelper.format(data.startDate, "MM/DD/YYYY") : "", Validators.pattern(RegExHelper.date)],
            diagnosisServiceDateThru: [data ? DateHelper.format(data.endDate, "MM/DD/YYYY") : "", Validators.pattern(RegExHelper.date)],
            diagnosisCodeAttributeData: DIAGNOSIS_CODE,
            startDateAttributeData: FROM_DATE,
            endDateAttributeData: THRU_DATE,
            entityTypeData: DIAGNOSIS,
            hide: [false],
        });

        if (this.isFromParentChase) {
            diagnoseFormGroup.addControl("entityId", new FormControl(data.entityId));
        }

        (((this.formGroup.controls.encounter as FormArray)
            .controls[userIndex] as FormGroup).controls.diagnosis as FormArray).push(diagnoseFormGroup);
        this.changeDetector.markForCheck();

    }

    deleteDiagnosis(diagnosis: any, encounterIndex: number, index: number): void {
        diagnosis.controls.hide.setValue(true);
        (((this.formGroup.controls.encounter as FormArray)
            .controls[encounterIndex] as FormGroup).controls.diagnosis as FormArray).removeAt(index);

    }

    callFinalReviewComponent(): void {
        if (this.formGroup.value !== null && this.formGroup.touched) {
            this.isVisibleMessageModal = true;
        } else {
            this.onShowChild.emit(this.createChaseService.chaseModel);
            this.changeDetector.markForCheck();
        }
    }

    reset() {
        this.formGroup.reset();
    }

    private setEntityDetails(): void {
        this.newChase.encounters =  this.formGroup.value.encounter.filter(encounter => !encounter.hide);
        this.createChaseService.chaseModel.encounters = this.newChase.encounters;
    }

    nextStepProcess(): void {
        if (this.createChaseService.chaseModel.isPursuit) {
            this.setEntityDetails();
            this.getControlsCount(this.createChaseService.chaseModel.encounters);
            this.onShowChild.emit(this.newChase);
            this.dataExchangeService.setNewChaseData(this.newChase);
            return;
        }

        if (this.formGroup.valid) {
            this.formGroup.value.encounter.map(encounterItem => {
                encounterItem.provider = this.providerList.find(x => x.clientProviderId === encounterItem.provider.provider);
                encounterItem.encounterType = this.encounterTypeList.find(x => x.value === encounterItem.encounterType.encounterType);
                return encounterItem;
            });
            this.formGroup.value.encounter = this.formGroup.value.encounter.filter(encounter => !encounter.hide);
            this.newChase.encounters = this.formGroup.value;
            this.createChaseService.chaseModel.encounters = this.formGroup.value;

            this.createChaseService.validateChaseDetails(this.newChase)
            .subscribe(messages => {
                if (ArrayHelper.isAvailable(messages)) {
                    this.validationErrorMessages = messages;
                    this.isClaimInvalid = true;
                    this.changeDetector.markForCheck();
                } else {
                    this.isClaimInvalid = false;
                    this.getControlsCount(this.formGroup.value.encounter);
                    this.changeDetector.markForCheck();
                    this.onShowChild.emit(this.newChase);
                    this.dataExchangeService.setNewChaseData(this.newChase);
                }
            });
        } else if (!this.formGroup.valid) {
            this.markAllAsTouched();
        }
    }

    private markAllAsTouched(): void {
        Object.keys(this.formGroup.controls).forEach(key => {
            const control = this.formGroup.get(key);
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        });
        this.formService.updateDom.next();
    }

    private getEncounterTypes(): void {
        this.riskService.getEncounterTypes()
            .subscribe(result => {
                this.encounterTypeList = result;
                this.encounterType = new Dropdown({ ...this.encounterType, options: this.encounterTypeList } as any);
                this.changeDetector.markForCheck();
            });
    }

    filterOptions(event): void {
       this.createChaseService.getDiagnosis(event.query)
           .subscribe(result => {
               this.diagnosisListFiltered = result;
               this.changeDetector.markForCheck();
            });
    }

    getControlsCount(encounters: Encounters[]): void {
        let diagnosisCount = 0;
        encounters.map(encounter => diagnosisCount = diagnosisCount + encounter.diagnosis.length);
        this.createChaseService.chaseModel.encounterCount = encounters.length;
        this.createChaseService.chaseModel.diagnosisCount = diagnosisCount;
    }

    trackByIndex(index, item) {
        return index;
    }

    duplicateClaimIdValidator(formControl: AbstractControl): ValidationErrors | null {
        let isValid = true;
        const encounters = formControl.value;
        if (encounters && encounters.length > 0) {
            const addedClaimIds: String[] = [];
            encounters.forEach(encounter => {
                if (encounter.claimId) {
                    if (addedClaimIds.includes(encounter.claimId)) {
                        isValid = false;
                    }
                    addedClaimIds.push(encounter.claimId);
                }
            });
            return isValid ? null : {duplicateClaimIdError: "Duplicate ClaimId" };
        }
    }

    createForm(): void {
        this.provider = new Dropdown({
            key: "provider",
            label: "PROVIDER",
            placeholder: "Select Provider",
            validators: [Validators.required],
            errorMessages: {
              pattern: "The field is required",
            },
        });
        this.encounterType = new Dropdown({
            key: "encounterType",
            label: "ENCOUNTER TYPE",
            placeholder: "Select Encounter Type",
            validators: [Validators.required],
            errorMessages: {
              pattern: "The field is required",
            },
        });
        this.providerFormGroup = this.formService.createFormGroup([this.provider]);
        this.encounterTypeFormGroup = this.formService.createFormGroup([this.encounterType]);
        this.changeDetector.markForCheck();
    }
}
