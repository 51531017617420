<div class="retreival-document-intake-container">

  <div class="leftCol"
       [ngClass]="{ 'max': isChartMaxed }">
    <div *ngIf="showDialog" class="search-panel">
      <app-chase-search #chaseSearch [documentSourceTypeId]="searchDocumentSourceTypeId"
                        (formClose)="searchClosed($event)"></app-chase-search>
    </div>
    <div class="left-column__relative">
      <app-document-page-viewer #pageviewer
                                [sourceDocumentTypeId]="sourceDocumentTypeId"
                                [maxPages]="3"
                                [(isMaximized)]="isChartMaxed"
                                (isMaximizedChange)="pageViewerMaximizeChange($event)"
                                [isOcrDataAvailable]="isOcrDataAvailable"></app-document-page-viewer>
    </div>
  </div>

  <div class="rightCol">
    <app-tab-sub-menu-non-routed [tabMenuItems]="menuItems"
                                 [selectedTab]="selectedTab"
                                 (currentTab)="onSelectTab($event)">
    </app-tab-sub-menu-non-routed>
    <div class="section tabContent" [hidden]="hideTab('Review')">
      <div *ngIf="showMemberValidation"
           class="membervalidation right-column-section-container" [ngClass]="showIncorrectMemberValidationInputs">
        <app-member-validation [configuration]="memberValidationData"
                               (onSubmit)="memberValidationSubmit($event)"
                               (onCorrectMemberSelection)="showMemberValidationInputs($event)"
                               enabled="allowEdit">
        </app-member-validation>
        <div *ngIf="showDosValidationSection" class="membervalidation right-column-section-container">
          <app-dates-of-service [chaseId]="documentId"
                                (onSubmit)="datesOfServiceValidation($event)">

          </app-dates-of-service>
        </div>
      </div>
    </div>
    <div class="section tabContent" [hidden]="hideTab('Identification')">
      <div class="identification right-column-section-container">
        <div *ngIf="sourceDocumentTypeId===1" class="assign ignore">
          <app-button text="IGNORE THIS PAGE"
                      (onClick)="toggleAssignSection('ignore')"
                      [disabled]="!allowEdit"
                      class="identification-button"></app-button>
          <div class="identification-form-container">
            <div *ngIf="currentAssignSection === 'ignore'"
                 class="identification-form-fields-container">
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <form-factory [formGroup]="ignoreForm" [model]="ignoreBegPage"></form-factory>
                </div>
                <div class="assign-row-item">
                  <form-factory [formGroup]="ignoreForm" [model]="ignoreEndPage"></form-factory>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <app-button text="ACCEPT" [disabled]="ignoreForm.invalid" (onClick)="assignIgnoreClick()"></app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="assign chase">
          <app-button class="header-button"
                      text="THIS PAGE IS PART OF A CHASE"
                      (onClick)="toggleAssignSection('chase')"
                      [disabled]="!allowEdit"
                      class="identification-button"></app-button>
          <div class="identification-form-container">
            <div *ngIf="currentAssignSection === 'chase'"
                 class="identification-form-fields-container">
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <form-factory [formGroup]="chaseForm" [model]="chaseBegPage"></form-factory>
                </div>
                <div class="assign-row-item">
                  <form-factory [formGroup]="chaseForm" [model]="chaseEndPage"></form-factory>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <form-factory [formGroup]="chaseForm" [model]="assignChaseId"></form-factory>
                </div>
                <div class="assign-row-item assign-row-button">
                  <app-button text="Search" (onClick)="searchChase(chaseForm.controls.assignChaseId)"></app-button>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <app-button text="ACCEPT" [disabled]="chaseForm.invalid" (onClick)="assignChaseClick()"></app-button>
                </div>
              </div>
            </div>
          </div>
          <app-confirmation-modal #confirmationForInActiveChases
                                  [(visible)]="showConfirmationForInActiveChases">
            {{confirmationTextForAttachingToInactiveChase}}
          </app-confirmation-modal>
          <app-confirmation-modal #confirmationForActiveChases
                                  [(visible)]="showConfirmationForActiveChases"
                                  header="ATTENTION"
                                  acceptText="YES"
                                  rejectText="NO">
            {{confirmationTextForAttachingToActiveChase}}
          </app-confirmation-modal>
          <app-membercentric-doc-attachment [(visible)]="showMemberCentricDocAttachmentInfo"
                                            [memberCentricGridData]="memberCentricData"
                                            [targetChaseId]="targetChaseId"
                                            (onConfirmDocAttachment)="attachDocToMemberCentricChases($event)">
          </app-membercentric-doc-attachment>
          <app-modal #notAllowedModal [(visible)]="showNotAllowedModal"
                     header="NOT ALLOWED">
            <div>{{attachChaseNotAllowedMsg}}</div>
          </app-modal>
        </div>
        <div class="assign invoice">
          <app-button class="header-button"
                      text="THIS PAGE IS PART OF AN INVOICE"
                      (onClick)="toggleAssignSection('invoice')"
                      [disabled]="!allowEdit"
                      class="identification-button"></app-button>
          <div class="identification-form-container">
            <div *ngIf="currentAssignSection === 'invoice'"
                 class="identification-form-fields-container">

              <div class="assignrow-container">
                <div>
                  <div class="assigninvoicecomment">Please enter a Chase ID for this Invoice.</div>
                  <div class="assigninvoicecomment">Multiple Chase IDs can be associated during Invoice Data Entry.</div>
                </div>
                <div class="assign-row-item">
                  <form-factory [formGroup]="invoiceForm" [model]="invoiceBegPage"></form-factory>
                </div>
                <div class="assign-row-item">
                  <form-factory [formGroup]="invoiceForm" [model]="invoiceEndPage"></form-factory>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <form-factory [formGroup]="invoiceForm" [model]="invoiceChaseId"></form-factory>
                </div>
                <div class="assign-row-item assign-row-button">
                  <app-button text="Search" (onClick)="searchChaseThirdParty(invoiceForm.controls.invoiceChaseId)"></app-button>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <app-button text="ACCEPT" [disabled]="invoiceForm.invalid" (onClick)="assignInvoiceClick()"></app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="assign pend">
          <app-button class="header-button identification-button"
                      text="THIS PAGE IS PART OF A PEND"
                      (onClick)="toggleAssignSection('pend')"
                      [disabled]="!allowEdit"></app-button>
          <div *ngIf="currentAssignSection === 'pend'">
            <form [formGroup]="pendForm" class="identification-form-container">
              <div class="identification-form-fields-container">
                <div class="assignrow-container">
                  <div class="assign-row-item">
                    <form-factory [formGroup]="pendForm" [model]="pendBegPage"></form-factory>
                  </div>
                  <div class="assign-row-item">
                    <form-factory [formGroup]="pendForm" [model]="pendEndPage"></form-factory>
                  </div>
                </div>
                <div class="assignrow-container">
                  <div class="assign-row-item">
                    <form-factory [formGroup]="pendForm"
                                  [model]="pendTypes" (onChange)="changePendType()"></form-factory>
                  </div>
                  <div *ngIf="isReasonPendType" class="assign-row-item">
                    <form-factory [formGroup]="pendForm"
                                  [model]="pendReasonInput"></form-factory>
                  </div>
                </div>
                <div *ngIf="isThirdPartyPendTypeSelected">
                  <div class="assignrow-container">
                    <div class="assign-row-item">
                      <form-factory [formGroup]="pendForm"
                                    [model]="pendCompany"></form-factory>
                    </div>
                  </div>
                  <div class="assignrow-container">
                    <div class="assign-row-item">
                      <form-factory [formGroup]="pendForm" [model]="pendAmount"></form-factory>
                    </div>

                    <div class="assign-row-item">
                      <form-factory [formGroup]="pendForm" [model]="pendInvoice"></form-factory>
                    </div>
                  </div>
                </div>
                <div class="assignrow-container">
                  <div class="assign-row-item">
                    <form-factory [formGroup]="pendForm" [model]="assignPendChaseId"></form-factory>
                  </div>
                  <div class="assign-row-item assign-row-button">
                    <app-button text="Search" (onClick)="searchChase(pendForm.controls.assignPendChaseId)"></app-button>
                  </div>
                </div>
                <div class="assignrow-container">
                  <div class="assign-row-item">
                    <app-button text="ACCEPT" [disabled]="pendForm.invalid" (onClick)="assignPendChaseClick()">
                    </app-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="sourceDocumentTypeId===2" class="assign delete">
          <app-button class="header-button identification-button" text="THIS PAGE SHOULD BE DELETED"
                      (onClick)="toggleAssignSection('delete')"
                      [disabled]="!allowEdit"></app-button>
          <div class="identification-form-container">
            <div *ngIf="currentAssignSection === 'delete'" class="identification-form-fields-container">

              <div class="assignrow-container">
                <div class="assign-row-item">
                  <form-factory [formGroup]="deleteForm" [model]="deleteBegPage"></form-factory>
                </div>
                <div class="assign-row-item">
                  <form-factory [formGroup]="deleteForm" [model]="deleteEndPage"></form-factory>
                </div>
              </div>
              <div class="assignrow-container">
                <div class="assign-row-item">
                  <app-button text="ACCEPT" [disabled]="deleteForm.invalid || disableDeleteBtn" (onClick)="deleteFromChaseClick()"></app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="documents-section">
        <app-document-page-assignment #documentPageAssignment
                                      [documentId]="documentId"
                                      [sourceDocumentTypeId]="sourceDocumentTypeId"
                                      (onPageAssignmentsLoaded)="pageAssignmentsLoaded($event)"
                                      (onGotoPage)="onPageAssignmentGotoPage($event)">
        </app-document-page-assignment>
        <div class="documents-section right-column-section-container"
             *ngIf="isRunModeChase">
          <div class="title">DOCUMENT PAGE HISTORY (most recent first)</div>
          <div class="pagehistorycontainer">
            <ul class="pagehistorylist"
                *ngFor="let pageHistory of pageHistories; let i = index; trackBy: trackByIndex ">
              <li>
                <div class="pagehistorylisttdiv">
                  <div>
                    Page: {{pageHistory.pageNumber}} {{pageHistory.pageType}} ({{pageHistory.documentPageId}}) <span *ngIf="pageHistory.currentlyAssignedToThisChase===false">
                      <a *ngIf="allowEdit"
                         (click)="restoreChasePage(pageHistory.documentPageId)">
                        RESTORE
                      </a>
                    </span>
                    <br />
                    {{pageHistory.action}} on {{ pageHistory.eventDate | date:"short" }} by
                    {{pageHistory.userName}}
                    <br />
                    Source File: {{pageHistory.documentName}}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    </div>
  </div>
