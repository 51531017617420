import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../../shared/comments/comment-item/comment-item";
import { URIFormatHelper } from "../../../../../../utilities/uri-format-helper";

@Injectable()
export class EmrDetailCommentsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getEmrGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.EmrGd;
  }

  getComments(): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${this.getEmrGdFromPath()}&isOnlyLatest=false`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveComment(commentText: string) {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${this.getEmrGdFromPath()}&commentText=${URIFormatHelper.encodeParameters(commentText)}`;

    return this.http.post(url, {});

  }
}
