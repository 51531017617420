import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapDocumentData = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentData")
    .forMember("documentQueueId", o => o.documentQueueID)
    .forMember("originalDocumentName", o => o.originalDocumentName)
    .forMember("intakeArchiveFileKey", o => o.intakeArchiveFileKey);
};

export class DocumentData {
  documentQueueId: number;
  originalDocumentName: string;
  intakeArchiveFileKey: string;

  constructor(options: Partial<DocumentData>) {
    this.documentQueueId = options.documentQueueId;
    this.originalDocumentName = options.originalDocumentName;
    this.intakeArchiveFileKey = options.intakeArchiveFileKey;
  }
}
