import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PanelModule } from "../panel/panel.module";
import { CommentItemComponent } from "./comment-item/comment-item.component";
import { CommentsComponent } from "./comments.component";
import { EntityCommentsService } from "./comments.service";
import { GridCommentsComponent } from "./grid-comments/grid-comments.component";


@NgModule({
  imports: [
    CommonModule,
    PanelModule,
  ],
  declarations: [
    GridCommentsComponent,
    CommentsComponent,
    CommentItemComponent,
  ],
  providers: [
    EntityCommentsService,
  ],
  exports: [
    GridCommentsComponent,
    CommentsComponent,
    CommentItemComponent,
  ],
})
export class CommentsModule { }
