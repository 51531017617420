import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { SubSink } from "subsink";
import { MenuItem } from "../../../../../../../shared/menu/menu-item.model";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { RiskService } from "../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { DiagnosisService } from "../risk-encounter/diagnosis/diagnosis.service";
import { Tab } from "./tab-menu.enum";

@Component({
  selector: "member-risk-menu",
  templateUrl: "./risk-menu.component.html",
  styleUrls: ["./risk-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMenuComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @Input() menuItems: MenuItem[] | List<MenuItem> = [];
  @Input() currentRoute = "";
  @Output() selectedSubMenu = new EventEmitter<string>();
  isEncounterClass = false;
  isMemberClass = false;
  isSubmitClass = false;
  nextButtonClicked: Subscription;

  get hasMenuItmes(): boolean {
    return ArrayHelper.isAvailable(this.menuItems);
  }

  get filteredMenuItems(): List<MenuItem> {
    const items = this.hasMenuItmes ? (this.menuItems as any).filter(item => item.show) : [];
    return List(items);
  }

  constructor(
    private riskService: RiskService,
    private router: Router,
    private readonly diagnosisService: DiagnosisService
  ) { }

  ngOnInit() {
    this.sink.add(
      this.nextButtonClicked = this.riskService.getNextButtonClicked().subscribe(() => {
        this.onTabSelected("Encounters");
      }),
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this.router.url.includes("encounter")) {
            this.onTabSelected(Tab.Encounters);
            this.diagnosisService.selectedTab(Tab.Encounters.toLowerCase());
          } else if (this.router.url.includes("submit")) {
            this.onTabSelected(Tab.Submit);
            this.diagnosisService.selectedTab(Tab.Submit.toLowerCase());
          } else {
            this.onTabSelected(Tab.Member);
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  isFirstItem(index: number): boolean {
    return index < 1;
  }

  getActiveClass(menuItem: any): string {
    let cssClass = "risk-menu__link";

    if (menuItem.extra.isDisabled) {
      cssClass += " risk-menu__link--disable";
    }

    switch (menuItem.description) {
      case "Submit":
        return cssClass = this.isSubmitClass || this.currentRoute.toLowerCase() === menuItem.path.toLowerCase() ? `${cssClass} submit-tab` : `${cssClass}`;
      case "Member":
        return cssClass = this.isMemberClass || this.currentRoute.toLowerCase() === menuItem.path.toLowerCase() ? `${cssClass} active` : `${cssClass}`;
      case "Encounters":
        return cssClass = this.isEncounterClass || this.currentRoute.toLowerCase() === menuItem.path.toLowerCase() ? `${cssClass} active` : `${cssClass}`;
      default:
        return cssClass;
    }
  }

  trackByFn(index, item) {
    return index;
  }

  onTabSelected(value: any) {
    switch (value) {
      case "Submit":
        this.isSubmitClass = !this.isSubmitClass;
        this.isMemberClass = false;
        this.isEncounterClass = false;
        break;
      case "Member":
        if (!this.isMemberClass) { this.isMemberClass = !this.isMemberClass; }
        this.isSubmitClass = false;
        this.isEncounterClass = false;
        break;
      case "Encounters":
        this.isEncounterClass = !this.isEncounterClass;
        this.isSubmitClass = false;
        this.isMemberClass = false;
        this.diagnosisService.resetNewDiagnosis();
        break;
      default:
        break;
    }

    this.selectedSubMenu.emit(value);
  }
}

