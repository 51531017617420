<h3>{{headerTitle}}</h3>
<div class="retrieval__stats" *ngIf="!isClinicalPend">
  <app-header-statistics [headerStatistics]="pendStatistics"></app-header-statistics>
</div>
<app-server-grid [configuration]="gridConfigurationModel" [(request)]="searchRequest" [refresh]="gridRefresh"
  [(selection)]="serverGridSelection" [actions]="actions" [isLoadOnInit]="false" [filterTemplate]="filterForm"
  [actionTemplate]="actionTemplate" [views]="views"
  [refreshViews]="refreshViews"
  filterHeaderText="Pend Query">

  <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button text="View Details" (onClick)="viewDetails(rowData); localActionPopover.hide();"></app-button>
      <app-button text="Change Owner & Assign"
        *ngIf="rowData.pendStatus !== 'Closed' && rowData.pendStatus !== 'Resolved'"
        (onClick)="changeOwnerAssignDialog(rowData); localActionPopover.hide();">
      </app-button>
      <app-button text="Change Status" *ngIf="!this.isClientRole && (rowData.pendStatus !== 'Closed')"
        (onClick)="updateStatusDialog(rowData); localActionPopover.hide();">
      </app-button>
      <app-button text="Update Status" *ngIf="this.isClientRole && (rowData.pendStatus !== 'Closed')"
        (onClick)="requestStatusChangeDialog(rowData); localActionPopover.hide();">
      </app-button>
    </div>
  </ng-template>

  <ng-template #filterForm let-form>
    <form [formGroup]="form">
      <app-tab-menu orientation="left">
        <app-tab-panel header="Address">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressGroupNameInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressCityInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressStateInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="AID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Assigned To">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Chase Id">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Health Plans">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="plansInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Invoice Company">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="companyIdToInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__fourths" header="Measures">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
          </div>
        </app-tab-panel>
          <app-tab-panel header="Member">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
              <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
              <form-factory [formGroup]="form" [model]="memberDobInput" (onChange)="formatMemberDobDate()"></form-factory>
              <form-factory [formGroup]="form" [model]="memberKeyInput"></form-factory>
            </div>
          </app-tab-panel>
        <app-tab-panel header="NPI">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="npiInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Owner">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="ownerInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group" header="Pend Codes">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Pend ID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendIdInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel *ngIf="isClinicalPend" class="checkbox-group__fourths" header="Pend Severity">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendSeverityInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Pend Status">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Product">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="productsInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Projects">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Provider">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="providerInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Sample Compliance">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
          </div>
        </app-tab-panel>
        
        <app-tab-panel header="Tags">
          <label class="tag-label">Tags</label>
          <div class="tag__filterform">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div class="ui-g-2 ui-md-2 ui-lg-2">
                <form-factory class="tag__operatorform"
                              [formGroup]="form"
                              [model]="tagsSearchOperatorInput"
                              (onChange)="getSearchOperatorValue($event)">
                </form-factory>
              </div>
              <div class="ui-g-10 ui-md-10 ui-lg-10">
                <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                            class="tag-content__item tag-content__item--multitag-close-icon"
                            classes="transparent small"
                            icon="times-circle"
                            (onClick)="resetTagSearchInput()">
                </app-button>
                <form-factory class="tag__panelform"
                              [formGroup]="form"
                              [model]="tagsInput"
                              (onPanelShow)="onShowEvent(true)"
                              (onPanelHide)="onShowEvent(false)"
                              (keyup)="onKeyUp($event)">
                </form-factory>
              </div>
            </div>
          </div>
        </app-tab-panel>

      </app-tab-menu>
    </form>
  </ng-template>

</app-server-grid>


<app-internal-pends-change-owner [isChangeOwnerVisible]="isChangeOwnerView" (formClose)="reassignUser($event)"
  [pendType]="pendType" [chasePendIds]="chasePendIds">
</app-internal-pends-change-owner>

<app-internal-pends-request-status-change [isStatusChangeVisible]="isStatusChangeView"
  (formClose)="updateStatus($event)" [chasePendIds]="chasePendIds" [isClinical]="isClinicalPend"
  [pendOwners]="pendOwners">
</app-internal-pends-request-status-change>
