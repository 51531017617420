import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export interface ILandingFilterOptions {
  clientId?: number;
  projectId?: number;
  product?: string;
}

export class LandingFilter {

  clientId: number;
  projectId: number;
  product: string;

  constructor(options: ILandingFilterOptions = {}) {
    this.clientId = NumberHelper.isAvailable(options.clientId) ? options.clientId : null;
    this.projectId = NumberHelper.isAvailable(options.projectId) ? options.projectId : null;
    this.product = StringHelper.clean(options.product) ? options.product : null;
  }
}
