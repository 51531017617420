import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { Dropdown } from "primeng/dropdown";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { RiskService } from "../../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk.service";
import { GridFilter } from "../../../shared/grid/models/grid-filter.model";
import { NationalProviderGridComponent } from "../../../shared/national-provider-grid/national-provider-grid.component";
import { NationalProviderGridService } from "../../../shared/national-provider-grid/national-provider-grid.service";
import { MasterProvider } from "../../../shared/national-provider-grid/national-provider.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { Textbox } from "../textbox/textbox.model";
import { NoConfirmProvider } from "./no-confirm-provider.model";

@Component({
  selector: "form-no-confirm-provider",
  templateUrl: "./no-confirm-provider.component.html",
  styleUrls: [
    "./no-confirm-provider.component.scss",
    "../textbox/textbox.component.scss",
    "../dropdown/dropdown.component.scss",
    "../provider-dropdown/provider-dropdown.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoConfirmProviderComponent extends DynamicControlDirective<NoConfirmProvider> implements OnInit, OnDestroy {
  @ViewChild(NationalProviderGridComponent, { static: false }) nationalProviderGridComponent: NationalProviderGridComponent;
  @ViewChild("providerDropdown", { static: true }) primeProviderDropdown: Dropdown;
  private sink = new SubSink();
  additionalFilters: GridFilter[] = [];

  selectedItem: string;
  validationOptions: SelectableInput[];

  visibleProviderSearchModal = false;
  providerSearchText: string;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly nationalProviderGridService: NationalProviderGridService,
    private readonly riskService: RiskService,
    private readonly automapper: AutomapperService
  ) {
    super();
   }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.changeDetector.markForCheck()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );

    if (this.model == null) {
      this.model = {} as any;
    }

    if (this.model.saveInfo == null) {
      this.model.saveInfo = {} as any;
    }

    this.createFilter();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private createFilter(): void {

    this.additionalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "globalSearchText",
        name: "Name",
        value: this.providerSearchText,
        validators: [Validators.minLength(3)],

      }),
    ];
  }


  get options(): SelectableInput[] {
    return this.model.options;
  }
  set options(value: SelectableInput[]) {
    this.formService.updateDom.next();
    this.model.options = ArrayHelper.clean(value);
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): any {
    return this.getClasses("dropdown");
  }

  get enableSearch(): boolean {
    return !this.model.disabled && !this.model.isAdmin && !this.model.readonly;
  }

  get visibleProviderSearchModalHack(): boolean {
    return this.visibleProviderSearchModal && this.enableSearch;
  }

  get adminValueText(): string {
    const adminOptions = this.options.find(item => item.value === this.model.adminValue);
    const text = adminOptions == null ? this.model.adminValue : adminOptions.text;
    return text;
  }

  validationChange(): void {
    if (StringHelper.isAvailable(this.model.adminValue) && this.model.saveInfo.validationId === 1) {
      this.control.setValue(this.model.adminValue);
    }
  }

  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }

  search(event: MouseEvent | null): void {
    if (event != null) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.additionalFilters[0].value = this.providerSearchText;
    this.nationalProviderGridComponent.refreshGrid();
  }

  openProviderSearchModal(): void {
    this.providerSearchText = "";
    this.nationalProviderGridService.clear();
    this.visibleProviderSearchModal = true;
    this.changeDetector.markForCheck();
  }

  closeProviderSearchModal(): void {
    this.visibleProviderSearchModal = false;
    this.providerSearchText = "";
    this.nationalProviderGridService.clear();
    this.changeDetector.markForCheck();
  }

  addAndSelect(provider: MasterProvider) {
    const providerNameUpper = provider.providerName.toUpperCase();
    const isUnknownProvider = providerNameUpper === "UNKNOWN PROVIDER";
    const hasProvider = isUnknownProvider
      ? this.options.some(a => a.extra.providerName === providerNameUpper)
      : this.options.some(a => a.extra.nationalProviderId === provider.nationalProviderId);
    if (hasProvider) {
      this.setValueByMasterProvider(provider, hasProvider);
    } else {
      const chaseId = this.model.saveInfo.chaseId;
      this.nationalProviderGridService.addServiceProvider(chaseId, provider)
        .subscribe(providers => {
          this.riskService.data.value.providers = providers;
          this.model.options = providers.map(this.automapper.curry("Provider", "SelectableInput"));
          this.setValueByMasterProvider(provider, isUnknownProvider);
        });
    }
  }

  private setValueByMasterProvider({ nationalProviderId }: MasterProvider, isUnknownProvider?: boolean): void {
    const selectedOption = isUnknownProvider ?
      this.model.options.find(a => a.extra.providerName === "UNKNOWN PROVIDER")
      : this.model.options.find(a => a.extra.nationalProviderId === nationalProviderId);
    if (selectedOption != null) {
      const value = selectedOption.value;
      this.model.saveInfo.value = value;
      this.control.setValue(value);
      this.closeProviderSearchModal();
      this.primeProviderDropdown.focus();
      const providerobj = {
        selectedOption: nationalProviderId,
        providers: this.model.options,
      };
      this.riskService.updateDxCardProviders(providerobj);
    }
  }

  onSelectedItemChange(event: any): void {
    event.type = "change";
    this.onChange.emit(event);
  }
}
