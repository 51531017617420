import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BASE_API_URL } from "../../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class MedicalRecordUploadService {
  private pIsMemberCentricProject = new BehaviorSubject<boolean>(false);
  isMemberCentricProject = this.pIsMemberCentricProject.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }


  upload(formData: FormData): Observable<any> {
    const url = `${this.baseApiUrl}medicalRecord/documentUpload`;
    return this.http.post(url, formData);
  }

  uploadPresigned(formData: FormData): Observable<any> {
    const url = `${this.baseApiUrl}medicalRecord/documentUploadPreValidation`;
    return this.http.post(url, formData);
  }

  markMemberCentricProject(value: boolean) {
    this.pIsMemberCentricProject.next(value);
  }
}
