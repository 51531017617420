<app-modal
  class="appointment-modal"
  [visible]="visible"
  (visibleChange)="setVisible($event)"
  [header]="header">
  <div>
    <label class="appointments__label">Available Users</label>
    <div *ngIf="!hasOptions" class="error">Assigning a user is required.</div>
    <div *ngFor="let appointment of userAppointments; index as i; trackBy: trackByIndex">
      <div class="ui-g-12 ui-md-12 ui-lg-12 no-padding">
        <div class="ui-g-11 ui-md-11 ui-lg-11 no-padding" [ngClass]="requiredClass(i)">
            <p-autoComplete 
              class="display-block auto-complete-size" 
              [suggestions]="filteredOptions" 
              (completeMethod)="filterOptions($event)" 
              field="text" 
              placeholder="Select User"
              [forceSelection]="true" 
              (onSelect)="userChanged($event.value, i)"
              (onClear)="onUserClear($event, i)"
              [dropdown]="true"
              [ngModel]="selectedOnEdit" >
            </p-autoComplete>
          </div>
          <div class="ui-g-1 ui-md-1 ui-lg-1">
            <app-button
              *ngIf="!isEditingMode"
              styleType="pi"
              type="pi"
              icon="times"
              classes="transparent danger small"
              (onClick)="onRemoveUser($event, i)">
            </app-button>
          </div>
        </div>
    </div>
    
    <app-button
      *ngIf="!isEditingMode"
      classes="link"
      text="Add User"
      (onClick)="addUser(0)">
    </app-button>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12 no-padding">
    <form-factory [formGroup]="form" [model]="addressInput"></form-factory>
  </div>

  <div class="appointments" [formGroup]="form">
    <label class="appointments__label">Appointments</label>
    <div *ngIf="appointments.errors && appointments.touched" class="error">{{ appointments.errors.error | titlecase }}</div>
    <div
      formArrayName="appointments"
      *ngFor="let appointment of appointments.controls; index as i; trackBy: trackByIndex">

      <div class="appointment" [formGroupName]="i">
        <div *ngIf="appointment.invalid" class="error">{{ appointment.errors.error }}</div>
        <p-calendar
          appendTo="body"
          formControlName="appointmentDate"
          class="appointment__input"
          [readonlyInput]="true"
          placeholder="Date">
        </p-calendar>
        <p-calendar
          appendTo="body"
          formControlName="appointmentStartTime"
          class="appointment__input"
          dataType="string"
          [readonlyInput]="true"
          [timeOnly]="true"
          [hourFormat]="12"
          [stepMinute]="15"
          [defaultDate]="DEFAULT_TIME"
          placeholder="Start Time">
        </p-calendar>
        <p-calendar
          appendTo="body"
          formControlName="appointmentEndTime"
          class="appointment__input"
          dataType="string"
          [readonlyInput]="true"
          [timeOnly]="true"
          [hourFormat]="12"
          [stepMinute]="15"
          [defaultDate]="DEFAULT_TIME"
          placeholder="End Time">
        </p-calendar>
        <app-button
          *ngIf="!isEditingMode"
          styleType="pi"
          type="pi"
          icon="times"
          classes="transparent danger small"
          (onClick)="onRemoveAppointment($event, i)">
        </app-button>
      </div>

    </div>
    <app-button
      *ngIf="!isEditingMode"
      classes="link"
      text="Add Appointment"
      (onClick)="onAddAppointment($event)">
    </app-button>
  </div>

  <form-factory [formGroup]="form" [model]="noteInput"></form-factory>

  <footer>
    <app-button
      text="Save"
      (onClick)="onSaveAppointment($event)"
      [disabled]="saveAppointmentInProgress">
    </app-button>
  </footer>

</app-modal>

<app-confirmation-modal
  [(visible)]="isBackdatedAppointmentModalVisible"
  header="Confirm Back-Dated Appointment"
  (onYes)="saveAppointments()"
  acceptText="Ok"
  rejectText="Cancel">
  Warning : You are attempting to schedule an appointment in the past. Click Ok to continue or Cancel to modify.
</app-confirmation-modal>
