import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { StandardGroup } from "../../../../../../dynamic-forms/form-groups/standard-group/standard-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../../../../api/member-validation/dynamic-entity.model";
import { EntityType } from "../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { ACCEPTABLE_SIGNATURE, CHART_PAGE_NUMBER } from "../attributes";
import { ChartService } from "../chart.service";
import { RiskHelper } from "../risk/risk-helper.model";

@Component({
  selector: "member-att",
  templateUrl: "./att.component.html",
  styleUrls: ["./att.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private hasChaseId = false;
  enabled = false;

  form: FormGroup;
  enrolleeStandardGroup: StandardGroup;
  private enrolleeIdInput: Textbox;

  memberStandardGroup: StandardGroup;
  private memberIdInput: Textbox;
  private memberFirstNameInput: Textbox;
  private memberLastNameInput: Textbox;

  renderingProviderStandardGroup: StandardGroup;
  private providerNameInput: Textbox;
  private dateOfServiceListInput: Textbox;

  acceptableSigSaveGroup: SaveGroup;
  private acceptableSigPageNumberInput: Textbox;
  private acceptableSigInput: Dropdown;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        if (!this.hasChaseId && NumberHelper.isGreaterThan(state.chaseId, 0)) {
          this.hasChaseId = true;
          this.chartService.getEntities(state.chaseId).subscribe(entities => this.chaseDetailStateService.setData({ entities }));
        }

        this.enabled = state.isEnabled;

        if (ArrayHelper.isAvailable(state.entities)) {
          this.createForm(state);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        this.deleteAttributesByInputs(event.model.controls);
        break;
      default:
      case "compliance":
        break;
    }
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .map(this.getSaveAttribute.bind(this))
      .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .map(this.getDeleteAttribute.bind(this))
        .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.chartService
      .save2(attributes)
      .subscribe(
        entities => this.chaseDetailStateService.setData({ entities }),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private initializeForm(): void {
    const disabled = !this.enabled;

    this.enrolleeIdInput = new Textbox({
      key: "EncounterId",
      label: "Edge Enrollee ID",
      disabled: true,
    });

    this.memberIdInput = new Textbox({
      key: "memberId",
      label: "Member ID",
      disabled: true,
    });

    this.memberFirstNameInput = new Textbox({
      key: "MemberFirstName",
      label: "Member First Name",
      disabled: true,
    });

    this.memberLastNameInput = new Textbox({
      key: "MemberLastName",
      label: "Member Last Name",
      disabled: true,
    });

    this.providerNameInput = new Textbox({
      key: "ProviderName",
      label: "Rendering Provider Name",
      ...this.providerNameInput,
      disabled: true,
    });

    this.dateOfServiceListInput = new Textbox({
      key: "DateOfServiceList",
      label: "Service Dates",
      ...this.dateOfServiceListInput,
      disabled: true,
      classOverride: "renderingProviderStandardGroup__classOverride",
      title: "test",
    });

    this.acceptableSigPageNumberInput = new Textbox({
      key: "acceptableSigPageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      placeholder: "Page Number",
      validators: [
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        min: "Page Number min is 1",
        pattern: "Page Number must be an integer",
      },
    });

    this.acceptableSigInput = new Dropdown({
      key: "acceptableSig",
      label: "Results",
      placeholder: "Yes/No",
      validators: [Validators.required],
      errorMessages: {
        required: "Acceptable Sig is required",
      },
    });

    this.setSaveGroupAndCreateForm();
    this.subscribeToPageNumberInputs();
  }

  private setSaveGroupAndCreateForm(): void {
    this.enrolleeStandardGroup = new StandardGroup({
      key: "EnrolleeGroup",
      controls: [
        this.enrolleeIdInput,
      ],
    });
    this.enrolleeIdInput.parent = this.enrolleeStandardGroup;

    this.memberStandardGroup = new StandardGroup({
      key: "MemberStandard",
      header: "Member Information",
      controls: [
        this.memberIdInput,
        this.memberFirstNameInput,
        this.memberLastNameInput,
      ],
    });
    this.memberIdInput.parent = this.memberStandardGroup;
    this.memberFirstNameInput.parent = this.memberStandardGroup;
    this.memberLastNameInput.parent = this.memberStandardGroup;

    this.renderingProviderStandardGroup = new StandardGroup({
      controls: [
        this.providerNameInput,
        this.dateOfServiceListInput,
      ],
    });
    this.providerNameInput.parent = this.renderingProviderStandardGroup;
    this.dateOfServiceListInput.parent = this.renderingProviderStandardGroup;

    this.acceptableSigSaveGroup = new SaveGroup({
      key: "acceptableSigSave",
      header: "Is the provider signature acceptable?",
      controls: [this.acceptableSigPageNumberInput, this.acceptableSigInput],
    });
    this.acceptableSigPageNumberInput.parent = this.acceptableSigSaveGroup;
    this.acceptableSigInput.parent = this.acceptableSigSaveGroup;

    this.form = this.formService.createFormGroup([
      this.enrolleeStandardGroup,
      this.memberStandardGroup,
      this.renderingProviderStandardGroup,
      this.acceptableSigSaveGroup,
    ]);
  }

  private subscribeToPageNumberInputs(): void {
    this.sink.add(
      this.form.get(this.acceptableSigPageNumberInput.getMasterKey()).valueChanges.subscribe(
        value => this.acceptableSigInput.saveInfo.pageNumber = value == null ? null : Number(value)));
  }

  private createForm(state: ChaseDetailState): void {
    const chaseId = state.chaseId;
    const workflowStatusName = this.getWorkflowStatusName(state.workflowStatus);
    const disabled = !this.enabled;

    const yesNoOptions = [
      new SelectableInput({ text: "Yes", value: "1" }),
      new SelectableInput({ text: "No", value: "0" }),
    ];

    const miscellaneousEntity = state.miscellaneousEntity;
    const providerName = state.providers[0]?.providerName;
    const dateOfServiceList = miscellaneousEntity.attributes.find(attribute => attribute.attributeId === 469)?.value;

    const signatureConfirmationEntity = state.signatureConfirmationEntity || new DynamicEntity();
    const signatureConfirmationEntityId = signatureConfirmationEntity?.entityId;

    this.form.get(this.enrolleeIdInput.getMasterKey()).setValue(state.enrolleeId);

    this.form.get(this.memberIdInput.getMasterKey()).setValue(state.member?.memberId);
    this.form.get(this.memberFirstNameInput.getMasterKey()).setValue(state.member?.memberFirstName);
    this.form.get(this.memberLastNameInput.getMasterKey()).setValue(state.member?.memberLastName);

    this.form.get(this.providerNameInput.getMasterKey()).setValue(providerName);
    this.form.get(this.dateOfServiceListInput.getMasterKey()).setValue(dateOfServiceList);

    this.setupAttribute({
      chaseId,
      entityId: signatureConfirmationEntityId,
      entityTypeId: EntityType.SIGNATURE_CONFIRMATION,
      disabled,
      workflowStatusName,
      singleEntity: signatureConfirmationEntity,
      input: this.acceptableSigPageNumberInput,
      ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
    });

    this.setupAttribute({
      chaseId,
      entityId: signatureConfirmationEntityId,
      entityTypeId: EntityType.SIGNATURE_CONFIRMATION,
      disabled,
      workflowStatusName,
      singleEntity: signatureConfirmationEntity,
      input: this.acceptableSigInput,
      ATTRIBUTE_DATA: ACCEPTABLE_SIGNATURE,
    });
    this.acceptableSigInput.options = yesNoOptions;
  }

  private getWorkflowStatusName(workflowStatus: WorkflowStatusDb): string {
    return StringHelper.clean(WorkflowStatusDb[workflowStatus]).toLowerCase();
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private getUniqueAttributes(attribute: DynamicEntityAttribute, index: number, attributes: DynamicEntityAttribute[]): boolean {
    return attributes.findIndex(a => a.attributeId === attribute.attributeId && a.isAdmin === attribute.isAdmin) === index;
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disabled,
    workflowStatusName,
    options,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
      options,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    input.disabled ? control.disable() : control.enable();
  }
}
