import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../../../core/automapper/automapper.service";
import { LocalService } from "../../../../../../../../../core/storage/local.service";
import { FormService } from "../../../../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../utilities/contracts/object-helper";
import { Encounter } from "../../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskService } from "../../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP } from "../../../../local-storage-keys";
import { ChaseDetailV2ChartRiskService } from "../../../risk.service";
import { DiagnosisService } from "../../diagnosis/diagnosis.service";

@Component({
  selector: "member-risk-encounter-list",
  templateUrl: "./encounter-list.component.html",
  styleUrls: ["./encounter-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterListComponent implements OnInit {
  private sink = new SubSink();
  chaseDetailState: ChaseDetailState;
  encounters: Encounter[];
  selectedEncounter: Encounter;
  encountersInput: Dropdown;
  formGroup: FormGroup;
  isEncounterSelected = false;
  selectedTab = "member";
  isDiagnosisSelected = false;

  constructor(
    private readonly automapper: AutomapperService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly formService: FormService,
    private readonly riskService: RiskService,
    private readonly diagnosisService: DiagnosisService,
    private readonly localService: LocalService
  ) { }

  ngOnInit(): void {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.selectedTab.subscribe(val => {
        this.selectedTab = val;
        if (val?.toLowerCase() === "encounters" || this.pathCurrentRoute === "encounter") {
          this.isEncounterSelected = true;
        }
      }),

      this.chaseDetailStateService.isDataEntryClicked$.subscribe(data => {
        if (data && this.selectedEncounter) {
          this.isEncounterSelected = true;
        }
      }),
      this.chaseDetailStateService.forceEncounterSelection$.subscribe(isSelected => {
          this.isEncounterSelected = isSelected;
      }),

      this.riskService.data.pipe(
        filter(riskState => ArrayHelper.isAvailable(riskState.encounters)),
        map(riskState => {
          this.encounters = this.automapper.mapMany("RiskData", "Encounter", riskState.encounters);
          this.encounters.forEach(a => a.setProviderName(riskState));
          const selectedEncounter = riskState.hasSelectedEncounterIndex ? this.encounters[riskState.selectedEncounterIndex] : {} as any;
          this.setupOptions(selectedEncounter?.id);

          if (ObjectHelper.isEmpty(this.selectedEncounter) || !this.isEncounterEqual(this.selectedEncounter, selectedEncounter)) {
            this.selectedEncounter = selectedEncounter;
            this.chaseDetailStateService.setEncounterSelected(this.selectedEncounter);
            this.encounterSelectionEvent(this.selectedEncounter);
          }
        })
      ).subscribe()
    );
  }

  get pathCurrentRoute(): string {
    const pathUrl = window.location.href;
    return pathUrl.substring(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
  }

  private initializeForm(): void {
    this.encountersInput = new Dropdown({
      key: "encountersInput",
      label: "Encounters",
      appendTo: "body",
      hasGroup: true,

    });

    this.formGroup = this.formService.createFormGroup([
      this.encountersInput,
    ]);
  }

  private setupOptions(selectedEncounterId: string): void {
    const options = this.chaseDetailV2ChartRiskService.groupEncounter(this.encounters);
    this.encountersInput = new Dropdown({ ...this.encountersInput, options });
    this.formGroup.get(this.encountersInput.getMasterKey()).setValue(selectedEncounterId);
  }

  private encounterSelectionEvent(encounter: Encounter): void {
    if (this.isEncounterSelected && encounter.hasOwnProperty("startDate") && encounter.hasOwnProperty("endDate")) {
      this.isEncounterSelected = false;
      this.chaseDetailStateService.setEncounterSelectionEvent(encounter);
    }
  }

  private isEncounterEqual(from: Encounter, to: Encounter): boolean {
    if (ObjectHelper.isEmpty(to)) {
      return false;
    }
    return from.id === to.id
      && from.providerSourceAliasId === to.providerSourceAliasId
      && from.startDate?.toString() === to.startDate?.toString()
      && from.diagnoses.length === to.diagnoses.length
      && (NumberHelper.isAvailable(from.pageNumber) || NumberHelper.isAvailable(to.pageNumber) ? to.pageNumber === from.pageNumber : true)
      && from.encounterFound === to.encounterFound
      && from.exempt === to.exempt;
  }

  setSelectedEncounterIndex($event: SelectableInput): void {
    this.localService.put(LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP.key, "0");
    this.isEncounterSelected = true;
    this.diagnosisService.toggleDiagnosisSelected(false);
    if (this.selectedEncounter) {
      const index = this.riskService.data.value.encounters.findIndex(encounter => encounter.id === $event.value);
      this.riskService.setSelectedEncounterIndex(index);
      this.diagnosisService.resetNewDiagnosis();
    }
  }
}
