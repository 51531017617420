<app-detail-main-template>
  <div container-header>
    <div class="header bold"
         data-qa="retrieval-page-header">RETRIEVAL</div>
    <div class="statistics">
      <app-header-statistics [headerStatistics]="retrievalStatsList"  [landingFilter]="landingFilter"></app-header-statistics>
    </div>
    <div class="filters">
      <app-landing-filter (addFilter)="applyFilter($event)"></app-landing-filter>
    </div>
  </div>
  <div container-body>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="labelModelChasesStatus !== undefined"
           style="display:none;">
        <app-kpi [cModel]="chartModelChasesStatus"
                 [cDataLabels]="labelModelChasesStatus"
                 [cDataModel]="arrChasesStatus"
                 [cOptions]="dataOptionsChasesStatus">
        </app-kpi>
      </div>
    </div>

    <div class="ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="schedulingStatusLabelModel !== undefined">
        <app-kpi [cModel]="schedulingStatusChartModel"
                 [cDataLabels]="schedulingStatusLabelModel"
                 [cDataModel]="schedulingStatusArray"
                 [cOptions]="schedulingStatusDataOptions"></app-kpi>
      </div>
    </div>

    <div class="ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="retrievalActivityLabelModel !== undefined">
        <app-kpi [cModel]="retrievalActivityChartModel"
                 [cDataLabels]="retrievalActivityLabelModel"
                 [cDataModel]="retrievalActivityArray"
                 [cOptions]="retrievalActivityDataOptions"></app-kpi>
      </div>
    </div>

    <div class="ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="contactActivityLabelModel !== undefined">
        <app-kpi [cModel]="contactActivityChartModel"
                 [cDataLabels]="contactActivityLabelModel"
                 [cDataModel]="contactActivityArray"
                 [cOptions]="contactActivityDataOptions"></app-kpi>
      </div>
    </div>

    <div class="ui-fluid"
         *ngIf="retrievalPendsStatusLabelModel !== undefined">
      <app-kpi [cModel]="retrievalPendsStatusChartModel"
               [cDataLabels]="retrievalPendsStatusLabelModel"
               [cDataModel]="retrievalPendsStatusArray"
               [cOptions]="retrievalPendsStatusDataOptions"></app-kpi>
    </div>

    <div class="ui-fluid"
         *ngIf="retrievalProjectionsLabelModel !== undefined">
      <app-kpi [cModel]="retrievalProjectionsChartModel"
               [cDataLabels]="retrievalProjectionsLabelModel"
               [cDataModel]="retrievalProjectionsArray"
               [cOptions]="retrievalProjectionsDataOptions"></app-kpi>
    </div>

    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="labelModelPends !== undefined"
           style="display:none;">
        <app-kpi [cModel]="chartModelPends"
                 [cDataLabels]="labelModelPends"
                 [cDataModel]="arrPends"
                 [cOptions]="dataOptionsPends">
        </app-kpi>
      </div>
      <div class="ui-g-12 ui-md-5 ui-lg-5"
           *ngIf="labelModelChasesNotRetrieved !==undefined"
           style="display:none;">
        <app-kpi [cModel]="chartModelChasesNotRetrieved"
                 [cDataLabels]="labelModelChasesNotRetrieved"
                 [cDataModel]="arrChasesNotRetrieved"
                 [cOptions]="dataOptionsChasesNotRetrieved">
        </app-kpi>
      </div>
    </div>
    <div class="ui-g ui-fluid"
         style="display:none;">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="labelModelFieldTechAppointments !== undefined">
        <app-kpi [cModel]="chartModelFieldTechAppointments"
                 [cDataLabels]="labelModelFieldTechAppointments"
                 [cDataModel]="arrFieldTechAppointments"
                 [cOptions]="dataOptionsFieldTechAppointments">
        </app-kpi>
      </div>
    </div>
  </div>
</app-detail-main-template>
