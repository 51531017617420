import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { DataLoadState } from "./data-load-state.model";
import { DataLoadService } from "./data-load.service";

@Component({
  selector: "app-data-load",
  templateUrl: "./data-load.component.html",
  styleUrls: ["./data-load.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLoadComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  dataLoadState: DataLoadState;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private dataLoadService: DataLoadService,
    private router: Router
  ) { }

  get confirmationHeaderText(): string {
    if (this.dataLoadState.isDelete) {
      return "Your data load delete was successful";
    } else if (this.dataLoadState.isAdminRefresh) {
      return "Your data load refresh was successful";
    } else {
      return "Your data load was successful";
    }
  }

  ngOnInit() {
    this.sink.add(
      this.dataLoadService.state.subscribe(state => {
        this.dataLoadState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.dataLoadService.clearState();
  }

  newLoad(): void {
    this.dataLoadService.clearState();
  }

  goToChaseQuery(): Promise<boolean> {
    return this.router.navigateByUrl(`project/chasequery?ProjectID=${this.dataLoadState.projectId}`);
  }
}
