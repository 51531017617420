import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ClipboardService } from "../../core/storage/clipboard.service";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-contact-history-note",
  templateUrl: "./contact-history-note.component.html",
  styleUrls: ["./contact-history-note.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactHistoryNoteViewerComponent {
  @Input() note: string;
  @Input() headerText = "NOTE";
  isNoteViewerVisible = false;


  constructor(
    private cd: ChangeDetectorRef,
    private clipboard: ClipboardService
  ) { }


  get hasNote(): boolean {
    return StringHelper.isAvailable(this.note);
  }

  showNoteViewer(): void {
    this.isNoteViewerVisible = true;
    this.cd.markForCheck();
  }

  closeNoteViewer() {
    this.isNoteViewerVisible = false;
    this.cd.markForCheck();
  }

  copyNote() {
    if (this.hasNote) {
      const isSuccess = this.clipboard.copy(this.note);

      if (isSuccess) {
        this.closeNoteViewer();
      }
    }
  }
}
