import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ChartModel } from "../../../../shared/kpi/model/chart.model";
import { DataModel } from "../../../../shared/kpi/model/data.model";
import { LabelModel } from "../../../../shared/kpi/model/label.model";
import { DashboardService } from "../dashboard/dashboard.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  chartModelCallsCompleted: ChartModel;
  dataLabelsCallsCompleted: LabelModel;
  dataModelCallsCompleted: DataModel;
  dataOptionsCallsCompleted: any;
  arrCallsCompleted: DataModel[] = [];

  chartModelPendsCreated: ChartModel;
  dataLabelsPendsCreated: LabelModel;
  dataModelPendsCreated: DataModel;
  dataOptionsPendsCreated: any;
  arrPendsCreated: DataModel[] = [];

  chartModelAddressAssignment: ChartModel;
  dataLabelsAddressAssignment: LabelModel;
  dataModelAddressAssignment: DataModel;
  dataOptionsAddressAssignment: any;
  arrAddressAssignment: DataModel[] = [];

  chartModelAddresses: ChartModel;
  dataLabelsAddresses: LabelModel;
  dataModelAddresses: DataModel;
  dataOptionsAddresses: any;
  arrAddresses: DataModel[] = [];

  constructor(private dashService: DashboardService, private changeDetector: ChangeDetectorRef) {

    this.chartModelCallsCompleted = new ChartModel({
      chartHeader: "CALLS COMPLETED", chartHeight: 400,
      chartWidth: 700, chartType: "bar", isResponsive: true,
    });

    this.dataOptionsCallsCompleted = {
      legend: {
        display: true,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: false,
          barThickness: 45,
          scaleLabel: {
            display: false,
            labelString: "CALLS COMPLETED",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            max: 16,
            min: 0,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrCallsCompleted.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const CallsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - CallsCompletedWidthNum);
            });
          });
        },
      },
    };

    this.chartModelAddressAssignment = new ChartModel({
      chartHeader: "ADDRESS ASSIGNMENT", chartHeight: 400, chartWidth: 400,
      chartType: "doughnut", headerClassName: "DoughnutHeader", canvasClassName: "DoughnutCanvas",
    });

    this.dataOptionsAddressAssignment = {
      legend: {
        position: "bottom",
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.canvas.getContext("2d");
          const divisior = 2;
          const num = 30;
          const cx = canvas.width / divisior;
          const cy = canvas.height / divisior;
          ctx.textAlign = "center";
          ctx.font = "30px verdana";
          ctx.fillStyle = "#797979";
          const text = "65%";
          const a = 30;
          const textX = Math.round((cx - ctx.measureText(text).width) / divisior);
          const textY = cy / divisior;
          ctx.fillText(text, cx, cy - a);
          const subCTX = canvas.canvas.getContext("2d");
          subCTX.font = "13px verdana";
          subCTX.fillStyle = "#797979";
          const subText = "Goal";
          const subTextX = Math.round((cx - subCTX.measureText(subText).width) / a);
          const subTextY = cy / divisior;
          subCTX.fillText(subText, cx, cy);
        },
      },
    };

    this.chartModelAddresses = new ChartModel({
      chartHeader: "ADDRESSES", chartHeight: 400, chartWidth: 400,
      chartType: "doughnut", headerClassName: "DoughnutHeader", canvasClassName: "DoughnutCanvas",
    });

    this.dataOptionsAddresses = {
      legend: {
        position: "bottom",
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const divisior = 2;
          const numY = 30;
          const canvas = ev.chart;
          const ctx = canvas.canvas.getContext("2d");
          const cx = canvas.width / divisior;
          const cy = canvas.height / divisior;
          ctx.textAlign = "center";
          ctx.font = "30px verdana";
          ctx.fillStyle = "#797979";
          const text = "<25";
          const textX = Math.round((cx - ctx.measureText(text).width) / divisior);
          const textY = cy / divisior;
          ctx.fillText(text, cx, cy - numY);
          const subCTX = canvas.canvas.getContext("2d");
          subCTX.font = "13px verdana";
          subCTX.fillStyle = "#797979";
          const subText = "Goal";
          const subTextX = Math.round((cx - subCTX.measureText(subText).width) / divisior);
          const subTextY = cy / divisior;
          subCTX.fillText(subText, cx, cy);
        },
      },
    };

    this.chartModelPendsCreated = new ChartModel({
      chartHeader: "PENDS CREATED", chartHeight: 400, chartWidth: 700,
      chartType: "bar", headerClassName: "BarHeader",
    });

    this.dataOptionsPendsCreated = {
      legend: {
        display: true,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: false,
          barThickness: 45,
          scaleLabel: {
            display: false,
            labelString: "CALLS COMPLETED",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            max: 16,
            min: 0,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrPendsCreated.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const pendCreatdNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - pendCreatdNum);
            });
          });
        },
      },
    };
  }

  ngOnInit() {
    this.getCallsCompleted();
    this.getPendCreated();
    this.getAddressAssignment();
    this.getAddress();
  }

  getCallsCompleted(): void {
    this.dashService.getCallsCompleted().subscribe(
      result => {
        this.dataLabelsCallsCompleted = new LabelModel({ labels: result.labels });
        this.dataModelCallsCompleted = new DataModel({
          data: result.data[0], backgroundColor: "#1a4570",
          borderColor: "#1a4570", label: "Over Time",
        });
        this.arrCallsCompleted = [];
        this.arrCallsCompleted.push(this.dataModelCallsCompleted);
        this.changeDetector.markForCheck();
      });
  }

  getPendCreated(): void {
    this.dashService.getPendCreated().subscribe(
      result => {
        this.dataLabelsPendsCreated = new LabelModel({ labels: result.labels });
        this.dataModelPendsCreated = new DataModel({
          data: result.data[0], backgroundColor: "#1a4570",
          borderColor: "#1a4570", label: "Over Time",
        });
        this.arrPendsCreated = [];
        this.arrPendsCreated.push(this.dataModelPendsCreated);
        this.changeDetector.markForCheck();
      }
    );
  }

  getAddressAssignment(): void {
    this.dashService.getAddressAssignment().subscribe(
      result => {
        this.dataLabelsAddressAssignment = new LabelModel({ labels: result.labels });
        this.dataModelAddressAssignment = new DataModel({
          data: result.data[0], backgroundColor: ["#C9C9C9", "#E4E4E4"],
          borderColor: "#BABABA", hoverBackgroundColor: ["#C9C9C9", "#E4E4E4"],
        });
        this.arrAddressAssignment = [];
        this.arrAddressAssignment.push(this.dataModelAddressAssignment);
        this.changeDetector.markForCheck();
      }
    );
  }

  getAddress(): void {
    this.dashService.getAddress().subscribe(
      result => {
        this.dataLabelsAddresses = new LabelModel({ labels: result.labels });
        this.dataModelAddresses = new DataModel({
          data: result.data[0], backgroundColor: ["#C9C9C9", "#E4E4E4"],
          borderColor: "#BABABA", hoverBackgroundColor: ["#C9C9C9", "#E4E4E4"],
        });
        this.arrAddresses = [];
        this.arrAddresses.push(this.dataModelAddresses);
        this.changeDetector.markForCheck();
      });
  }
}
