import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { Encounter } from "../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskState } from "../../../chase-detail/chase-detail-chart/risk/risk-state.model";

@Injectable({
  providedIn: "root",
})
export class ChaseDetailV2ChartRiskService {
  activateMemberTab = false;
  groupEncounterList: SelectableInput[];

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor(
    private automapper: AutomapperService
  ) { }

  getMenuItems(): Observable<MenuItem[]> {
    const menuItems = [
      { description: "Member", path: "./", extra: { showBadge: false, isDisabled: false } },
      { description: "Encounters", path: "encounter", extra: { showBadge: true, isDisabled: true } },
      { description: "Submit", path: "submit", extra: { showBadge: true, isDisabled: true } },
    ];

    return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu-with-extra", "MenuItem"))
    );
  }

  canActivateMemberTab(isActive) {
    this.activateMemberTab = isActive;
  }

  getGroupEncounterIndex(riskStateEncounters: Encounter[]): number {
    const encounters = this.automapper.mapMany("RiskData", "Encounter", riskStateEncounters);
    const options = this.groupEncounter(encounters);
    const selectedOption = this.getFirstGroupEncounter(options);
    const indexOfEncounterOfFirstGroup = encounters.findIndex(object => {
      return object.id === selectedOption;
    });
    return indexOfEncounterOfFirstGroup;
  }

  private getFirstGroupEncounter(options: SelectableInput[]): string | number {
    if (ArrayHelper.isAvailable(options)) {
      return options[0].items[0].value;
    }
  }

  groupEncounter(encounters: any[]): any[] {
    this.groupEncounterList = [];
    const filteredAdminOptions = encounters.filter(item => item.isAdmin);
    if (ArrayHelper.isAvailable(filteredAdminOptions)) {
      this.groupEncounterList.push(new SelectableInput(
        { text: "Admin", value: null, items: filteredAdminOptions.map(item => new SelectableInput(
          { text: item.dosRange, value: item.id, extra: { hasEveDx: item?.diagnoses.some(dx => dx?.isEveDiagnosis)}})
          )}));
    }
    const filteredCoderOptions = encounters.filter(item => (item.isCoder));
    if (ArrayHelper.isAvailable(filteredCoderOptions)) {
      this.groupEncounterList.push(new SelectableInput(
        { text: "Coder", value: null, items: filteredCoderOptions.map(item => new SelectableInput({ text: item.dosRange, value: item.id })) }));
    }
    const filteredEveOptions = encounters.filter(item => item.isEve);
    if (ArrayHelper.isAvailable(filteredEveOptions)) {
      this.groupEncounterList.push(new SelectableInput(
        { text: "Eve", value: null, items: filteredEveOptions.map(item => new SelectableInput({ text: item.dosRange, value: item.id })) }));
    }
    return this.groupEncounterList;
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  isSubmitEnabled(riskState: RiskState): boolean {
    return riskState.isEnabled;
  }
}
