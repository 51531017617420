import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class DocumentReviewerChaseListItem {
  readonly documentName: string;
  readonly documentType: string;
  readonly documentDate: string;
  readonly documentState: string;
  readonly projectName: string;
  readonly url: string;
  constructor(options: {
    documentName: string;
    documentType: string;
    documentDate: string;
    documentState: string;
    projectName: string;
    url: string;
  }) {
    this.documentName = StringHelper.clean(options.documentName);
    this.documentType = StringHelper.clean(options.documentType);
    this.documentDate = StringHelper.clean(options.documentDate);
    this.documentState = StringHelper.clean(options.documentState);
    this.projectName = StringHelper.clean(options.projectName);
    this.url = StringHelper.clean(options.url);
  }
}
