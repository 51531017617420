import { ENCOUNTER } from "../../../api/member-validation/entities";
import { ProviderItem } from "../../../api/provider/provider-search-result-item";
import { CLAIM_ID, ENCOUNTER_TYPE, FROM_DATE, THRU_DATE } from "../chase-detail/chase-detail-chart/attributes";
import { Diagnosis } from "../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { AttributeData } from "./attributeData.model";
import { EncounterTypeData } from "./encounterTypeData.model";
import { EntityData } from "./entityData.model";

export class Encounters {
    claimId?: string;
    provider?: ProviderItem;
    encounterType?: EncounterTypeData;
    encounterServiceDateFrom?: Date;
    encounterServiceDateThru?: Date;
    entityId?: number;
    hide?: boolean;
    readonly claimAttributeData: AttributeData;
    readonly encounterTypeAttributeData: AttributeData;
    readonly startDateAttributeData: AttributeData;
    readonly endDateAttributeData: AttributeData;
    readonly entityTypeData: EntityData;
    readonly diagnosis?: Diagnosis[];

    constructor(options: {
      claimId?: string;
      provider?: ProviderItem;
      encounterType?: EncounterTypeData;
      encounterServiceDateFrom?: Date;
      encounterServiceDateThru?: Date;
      entityId?: number;
      hide?: boolean;
      readonly claimAttributeData: AttributeData;
      readonly encounterTypeAttributeData: AttributeData;
      readonly startDateAttributeData: AttributeData;
      readonly endDateAttributeData: AttributeData;
      readonly entityTypeData: EntityData;
      readonly diagnosis?: Diagnosis[];

  }) {
      this.claimId = options.claimId;
      this.provider = options.provider;
      this.encounterType = options.encounterType;
      this.encounterServiceDateFrom = options.encounterServiceDateFrom;
      this.encounterServiceDateThru = options.encounterServiceDateThru;
      this.entityId = options.entityId;
      this.claimAttributeData = CLAIM_ID;
      this.encounterTypeAttributeData = ENCOUNTER_TYPE;
      this.startDateAttributeData = FROM_DATE;
      this.endDateAttributeData = THRU_DATE;
      this.entityTypeData = ENCOUNTER;
      this.diagnosis = options.diagnosis;
      this.hide = options.hide;
  }
}
