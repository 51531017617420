export enum AddressBulkUpdateActionType {
    MarkAsContacted = 1,
    AssignAddresses = 2,
    ChangeRetrievalType = 3,
    TagAddresses = 6,
    EnableSpecialHandling = 7,
    DisableSpecialHandling = 8,
    EnableProviderStatements = 9,
    DisableProviderStatements= 11,
    MarkAsValidated= 12,
}
