import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import { DateHelper } from "../../utilities/contracts/date-helper";
import { Inclusions, UnitOfTime, dateTypes } from "../../utilities/contracts/helper-types";

export const dateBetweenValidator = (start: dateTypes, end: dateTypes, inclusion: Inclusions = "[]", granularity: UnitOfTime = "day"): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    // TODO: Throwing an error will probably need to change at some point.
    DateHelper.throwIsAvailableError(start, "start");
    DateHelper.throwIsAvailableError(end, "end");

    if (!DateHelper.isAvailable(control.value)) {
      return null;
    }

    if (DateHelper.isBetween(control.value, start, end, inclusion, granularity)) {
      return null;
    }

    return {
      datebetween: {
        start: DateHelper.format(start),
        end: DateHelper.format(end),
      },
    };
  };
};

@Directive({
  selector: "[app-date-between]",
  providers: [{ provide: NG_VALIDATORS, useExisting: DateBetweenValidatorDirective, multi: true }],
})
export class DateBetweenValidatorDirective implements Validator {
  @Input() start: dateTypes;
  @Input() end: dateTypes;
  @Input() inclusion: Inclusions = "[]";
  @Input() granularity: UnitOfTime = "day";

  validate(control: AbstractControl): ValidationErrors | null {
    return dateBetweenValidator(this.start, this.end, this.inclusion, this.granularity)(control);
  }
}
