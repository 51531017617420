<div class="summary-container">
  <div *ngFor="let status of encounterStatuses; trackBy: trackByIndex">
    <div *ngIf="groupedEncounters[status] as encounters">
      <div class="summary-header">
        <div class="summary-header--title">{{status}} Encounters</div>
        <app-badge [text]="totalEncounters(status)" [isLarge]="false" cssClasses="badge-risk counter">
        </app-badge>
      </div>
      <div class="summary-card primary" *ngFor="let encounter of encounters; trackBy: trackByIndex; let i = index">
        <div class="summary-card--header" (click)="onTabOpen(i, encounter)"
             *ngIf="!activeState[encounter.id]; else expandedData">
          <div>
            <div><b>{{encounter.encounterSourceV2}}</b></div>
            {{encounter.dosRange}}&nbsp;&nbsp;
            {{encounter.provider}}&nbsp;&nbsp;
            {{encounter.encounterType}}
            <div class="summary-card--details" *ngIf="isValidEncounterStatus(status)">
              Total HCCs: {{encounter.totalHccs}}&nbsp;
              Total Validated Dx: {{encounter.totalSubstantiatedDx}}&nbsp;
              Total Not Valid Dx: {{encounter.totalUnsubstantiatedDx}}
            </div>
          </div>
        </div>
        <ng-template #expandedData>
          <div class="summary-card--header" (click)="onTabClose()">
            <div>
              <div class="summary-card--edit">
                <app-icon (click)="editEncounter()" iconName="pencil"></app-icon>
              </div>
              <div class="summary-card--title">
                <div><b>{{encounter.encounterSourceV2}}</b></div>
                <b>DOS:</b> {{encounter.dosRange}}
              </div>
              <div class="summary-card--title" *ngIf="encounter.claimId"><b>Claim ID:</b>
                {{encounter.claimId}}
              </div>
              <div class="summary-card--title">
                {{encounter.provider}}&nbsp;&nbsp;
                {{encounter.encounterType}}
              </div>
            </div>
          </div>
          <div *ngFor="let diagnosisStatus of diagnosisStatuses; trackBy: trackByIndex">
            <div *ngIf="encounter.groupedDiagnoses[diagnosisStatus] as groupedDiagnoses">
              <div class="summary-header">
                <div class="summary-header--title">
                  <span *ngIf="diagnosisStatus === 'Substantiated'">Validated Dx</span>
                  <span *ngIf="diagnosisStatus === 'Unsubstantiated'">Not Valid Dx</span>
                  <span *ngIf="diagnosisStatus !== 'Substantiated' && diagnosisStatus !== 'Unsubstantiated'">Unreviewed Dx</span>
                </div>
                <app-badge [text]="totalDiagnoses(groupedDiagnoses)" [isLarge]="false"
                           cssClasses="badge-risk counter">
                </app-badge>
              </div>
              <section *ngFor="let diagnosis of groupedDiagnoses; trackBy: trackByIndex">
                <div class="summary-card"
                     *ngIf="(diagnosisStatus !== 'Unreviewed') || (diagnosisStatus === 'Unreviewed' && !diagnosis.isEveDiagnosis)">
                  <div class="summary-card--header" (click)="onDiagnosisSelected(i, diagnosis.pageNumber, diagnosis)">
                    <div class="summary-card--edit">
                      <app-icon (click)="editDiagnosis(diagnosis.id)" iconName="pencil"></app-icon>
                    </div>
                    <div>
                      <div><b>{{diagnosis.diagnosisSourceV2}}</b></div>

                      <span *ngIf="diagnosis.isEveDiagnosis; else defaultDiseaseName">
                        {{ diagnosis.icd }}: {{ getDiseaseName(diagnosis.diseaseName, diagnosis.icd) }}
                        <br/>
                        <span [innerHTML]="codingModelLabel(diagnosis)"></span>
                      </span>

                      <ng-template #defaultDiseaseName>
                        <div *ngIf="getDiseaseDetail$(diagnosis) | async as diseases; else noInformation">
                          {{ diagnosis.icd }}: {{ diseases[0]?.diseaseName }}
                          <br />
                          <span *ngIf="isCodingModelAvailable(diagnosis); else noCodingModels"
                            [innerHTML]="codingModelLabel(diagnosis)"></span>
                          <ng-template #noCodingModels>
                            <span class="card-header__description--hcc" [innerHTML]="hccLabel(diseases)">
                            </span>
                            <span *ngIf="showNoHccLabel(diseases)">No HCC</span>
                          </ng-template>
                        </div>
                      </ng-template>

                      <div class="summary-card--details">
                        {{diagnosis.dosRange}}
                        &nbsp;&nbsp;{{getProvider(diagnosis.providerSourceAliasId)}}
                      </div>
                      <div class="summary-card--details">
                        VRC: {{getVrcDescription(diagnosis.vrcs)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="summary-card"
                     *ngIf="diagnosisStatus === 'Unreviewed' && shouldDisplayEveDiagnosis(diagnosis)">
                  <div class="summary-card--header" (click)="onDiagnosisSelected(i, diagnosis.pageNumber, diagnosis)">
                    <div class="summary-card--edit">
                      <app-icon (click)="editDiagnosis(diagnosis.id)" iconName="pencil"></app-icon>
                    </div>
                    <div>
                      <div><b>{{diagnosis.diagnosisSourceV2}}</b></div>
                      <div>
                        {{ diagnosis.text }}
                        <br/>
                        <span [innerHTML]="codingModelLabel(diagnosis)"></span>
                      </div>
                      <div class="summary-card--details">
                        {{diagnosis.dosFrom}}
                        &nbsp;&nbsp;{{getProvider(diagnosis.providerSourceAliasId)}}
                      </div>
                      <div class="summary-card--details">
                        VRC: {{getVrcDescription(diagnosis.vrcs)}}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #noInformation>
  <div>NO INFORMATION</div>
</ng-template>
