import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class ChangeOwnerAssign {
  readonly chasePendIds: number[];
  readonly assignedToUserId: number;
  readonly notes: string;
  readonly ownedBy: string;


  constructor(options: {
    chasePendIds: number[];
    assignedToUserId: number;
    notes: string;
    ownedBy: string;

  }) {
    this.chasePendIds = options.chasePendIds;
    this.assignedToUserId = options.assignedToUserId;
    this.notes = StringHelper.clean(options.notes);
    this.ownedBy = StringHelper.clean(options.ownedBy);

  }
}
