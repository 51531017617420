import { decode, encode } from "base64-url";

export class Base64 {
  static encode(value: string): string {
    return encode(value);
  }

  static encodeNumber(value: number): string {
    return encode(value.toString());
  }

  static decode(value: string): string {
    return decode(value);
  }
}
