import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { ListItem } from "../../../../../../shared/list/list-item";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";
import { VendorDetail } from "../../../../invoice/vendor-detail/vendor-detail.model";
import { MemberWithChases } from "./member-with-chases.model";

@Injectable()
export class AddressDetailInfoService {
  reset = new Subject<any>();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getAddressGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.AddressGd;
  }

  resetAddress(addressId: number) {

    this.reset.next(addressId);
  }

  getSummaryItems(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}address/summary?addressId=${this.getAddressGdFromPath()}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getTimelineItems(): Observable<TimelineItem[]> {
    // TODO: Add address timeline endpoint
    const url = `${this.baseApiUrl}timeline/retrievalTimeline?documentSourceId=${this.getAddressGdFromPath()}&maxRecords=5`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }

  getMembers(addressId: number): Observable<MemberWithChases[]> {
    const url = `${this.baseApiUrl}members/listbyaid?addressId=${addressId}`;

    return this.http.get(url)
      .pipe(
      map(this.automapper.curryMany("default", "MemberWithChases"))
      );
  }

  assignAddressToVendor(addressId: number, vendorId: number): Observable<null> {
    const url = `${this.baseApiUrl}address/vendor/assign?masterDocumentSourceId=${addressId}&vendorId=${vendorId}`;
    return this.http.post(url, {}) as Observable<null>;
  }

  getVendors(invoiceType?: string): Observable<VendorDetail[]> {
    const url = `${this.baseApiUrl}invoice/vendors?invoiceType=${invoiceType}`;
    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("default", "VendorDetail"))
      );
  }

  getChaseIdsToCreatePend(addressId: number, vendorId: number): Observable<number[]> {
    const url = `${this.baseApiUrl}address/vendor/chaseIds?masterDocumentSourceId=${addressId}&vendorId=${vendorId}`;
    return this.http.get(url) as Observable<number[]>;
  }
}
