import { IAutoMapper } from "../interfaces";

export const mapMemberListItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberListItem")
    .forMember("id", o => o.id)
    .forMember("name", o => o.name)
    .forMember("address", o => o.address)
    .forMember("address2", o => o.address2)
    .forMember("city", o => o.city)
    .forMember("state", o => o.state)
    .forMember("zip", o => o.zip)
    .forMember("dob", o => o.dob)
    .forMember("gender", o => o.gender)
    .forMember("providerId", o => o.providerId)
    .forMember("chases", o => o.chases)
    .forMember("marital", o => o.marital)
    .forMember("recordCount", o => o.recordCount);

};
