<a (click)="getProviderAddresses()">
    {{ addressCount }}
</a>

<app-modal class="provider-address"
           header="PROVIDER ADDRESSES"
           [(visible)]="visible">
           <app-basic-grid 
           [configuration]="addressGridConfiguration"
           [data]="addressGridData"
           exportDataFromUrl="false">
         </app-basic-grid>
</app-modal>