import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-stalled-document-report-dw",
  templateUrl: "./stalled-document-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StalledDocumentReportDwComponent {
  filters: string[] = [];
  dashboardType = LookerRiskDashboardType.STALLED_DOCUMENTS_REPORT_DW;
  hideFilters: string[] = [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORGANIZATION_NAME];
}
