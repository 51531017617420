import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-project-status-report",
  templateUrl: "./project-status-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusReportComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.PROJECT_STATUS_REPORT;

    constructor() {
        this.filters = ["Projects", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
