import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { AddressDetailInfoAccessEdit } from "./address-detail-info-access-edit.model";

@Injectable({
  providedIn: "root",
})
export class AddressDetailInfoAccessService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient
  ) { }

  updateAccessInfo(accessInfoEditModel: AddressDetailInfoAccessEdit, retrievalId: number): Observable<number> {
    const url = `${this.baseApiUrl}retrieval/UpdateAccessInfo?retrievalId=${retrievalId}`;

    return this.http
      .post(url, accessInfoEditModel)
      .pipe(
        map((response: any): number => response)
      );
  }

  getEmrCredentialStatuses(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}emr/credentialstatuses`;

    return this.http.get(url) as Observable<SelectableInput[]>;
  }
}
