import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ChaseMoveBack } from "../../modules/retrieval/retreival-document-review/document-review/chase-move-back.model";
import { DynamicEntityAttribute } from "../member-validation/dynamic-entity-attribute.model";
import { WorkflowEntityAttribute } from "./workflow-entity.model";
import { WorkflowSubmitRequest } from "./workflow-submit.model";

@Injectable({
  providedIn: "root",
})
export class WorkflowService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient
  ) { }


  submit(workflowSubmitRequest: WorkflowSubmitRequest): Observable<boolean> {
    const url = `${this.baseApiUrl}workflow/submit`;
    return this.http.post(url, workflowSubmitRequest, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => response.ok)
      );
  }

  chaseMoveBack(chasemoveBack: ChaseMoveBack): Observable<null> {
    const url = `${this.baseApiUrl}workflow/moveback`;
    return this.http.post(url, chasemoveBack) as Observable<null>;
  }

  getSubmissionItemAnswer(chaseId: number): Observable<DynamicEntityAttribute> {
    const url = `${this.baseApiUrl}workflow/submissionitemanswer?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<DynamicEntityAttribute>;
  }

  setEntityAttribute(workflowEntityAttribute: WorkflowEntityAttribute): Observable<null> {
    const url = `${this.baseApiUrl}workflow/entityattribute`;
    return this.http.post(url, workflowEntityAttribute) as Observable<null>;
  }
}
