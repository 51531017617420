import { FormGroup } from "@angular/forms";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { NoConfirmProviderComponent } from "./no-confirm-provider.component";

export interface INoConfirmProvider extends IDynamicInputOptions {
  placeholder?: string;
  options?: SelectableInput[];
  pageNumber?: number;
  adminValue?: string;
  validationId?: string;
  validationNote?: string;
}

export class NoConfirmProvider extends DynamicInput implements INoConfirmProvider {
  placeholder: string;
  options: SelectableInput[];
  pageNumber: number;
  adminValue: string;
  validationId: string;
  validationNote: string;

  readonly controlType = "dropdown";
  readonly componentType = NoConfirmProviderComponent;

  constructor(options: INoConfirmProvider = {}) {
    super(options);
    this.placeholder = StringHelper.clean(options.placeholder);
    this.options = ArrayHelper.clean(options.options);

    this.pageNumber = options.pageNumber;
    this.adminValue = options.adminValue;
    this.validationId = options.validationId;
    this.validationNote = options.validationNote;
  }

  getValue(form: FormGroup): string {
    if (form == null) {
      throw new Error("No form is present");
    }

    const value = form.get(this.getMasterKey()).value;
    return value;
  }

  getSelectedOption(form: FormGroup): SelectableInput {
    const value = this.getValue(form);
    return StringHelper.isAvailable(value) || NumberHelper.isAvailable(value as any)
      ? this.options.find(option => option.value === value)
      : null;
  }
}
