import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseIdComponent } from "../../../../../../shared/chase-grid/chase-id.component";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../../shared/grid/models/grid-request.model";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { ChaseSearchService } from "../../../../../api/chase-search/chase-search.service";

@Component({
  selector: "member-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["../compliance/compliance.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnInit {
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseID";

  gridRefresh = new EventEmitter<null>();
  gridConfiguration = new GridConfiguration();
  request = new GridRequest();
  isLoadOnInit = true;

  @Input()
  set memberId(value: number) {
    if (NumberHelper.isGreaterThan(value, 0)) {
      this.refreshGrid(value);
    } else {
      this.isLoadOnInit = false;
    }
  }


  constructor(private chaseSearchService: ChaseSearchService) { }

  ngOnInit() {
    this.createGrid();
  }


  private createGrid(): void {
    this.gridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({
          field: "measureCode",
          header: "Measure",
        }),
        new GridColumnDefinition({
          field: "sampleID",
          header: "Sample",
        }),
        new GridColumnDefinition({
          field: "chaseID",
          header: "Chase",
          template: this.chaseIdColumn,
        }),
        new GridColumnDefinition({
          field: "serviceProviders",
          header: "Provider",
          isSortableColumn: false,
        }),
        new GridColumnDefinition({
          field: "workflowStatusName",
          header: "Status",
        }),
      ],
      pageSize: 10,
      showMenu: false,
      showActionColumn: false,
      showExportAction: false,
    });
  }

  private refreshGrid(value: number): void {
    this.request = new GridRequest({
      url: this.chaseSearchService.chaseSearchUrl(),
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "memberId",
          value: value.toString(),
          show: false,
        }),
      ],
    });
    this.gridRefresh.emit();
  }
}
