<div class="ui-fluid">
  <div class="ui-g-6 ui-md-4 ui-lg-5 invoice-type padding-0">
    <div *ngIf="isReadOnly;else invoiceTypeDropdown" class="ui-md-12">
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="invoiceTypeName"></form-factory>
    </div>
    <ng-template #invoiceTypeDropdown>
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="invoiceTypes" (onChange)="setPriceValidation(true)"></form-factory>
    </ng-template>
  </div>
  <div *ngIf="hasChartType" class="ui-g-12 ui-md-8 ui-lg-7 padding-0 chart-type-div">
    <div class="ui-md-6 ui-toolbar-group-left">
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="chartType"></form-factory>
    </div>
    <div class="ui-md-6 ui-toolbar-group-left">
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="chartPrice" (onChange)="onPriceChange('chartPrice')"></form-factory>
    </div>
    <div class="ui-md-6 ui-toolbar-group-left">
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="cnrType"></form-factory>
    </div>
    <div class="ui-md-6 ui-toolbar-group-left">
      <form-factory [formGroup]="invoiceSettingFormGroup" [model]="cnrPrice" class="cnr-control" (onChange)="onPriceChange('cnrPrice')"></form-factory>
    </div>
  </div>
</div>

<div class="ui-g-6 ui-md-6 ui-lg-6 invoice-warning-container">
  <app-confirmation-modal [(visible)]="isConfirmationModalVisible"
                          header="WARNING"                        
                          (onYes)="onConfirmationClick()"
                          (onCancel)="onConfirmationClick()">
    This will not retroactively update the value. It will only be applied for future invoices.
    Are you sure you want to continue?
  </app-confirmation-modal>
</div>



