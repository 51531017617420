import { IAutoMapper } from "../interfaces";

export const mapDropdownListItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DropdownListItem")
    .forMember("key", o => o.id)
    .forMember("value", o => o.name);


};
