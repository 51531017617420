import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { DynamicEntity } from "../../../../api/member-validation/dynamic-entity.model";
import { ArchiveChaseDetailService } from "../archive-chase-detail.service";

@Component({
  selector: "member-archive-chase-detail-chart",
  templateUrl: "./archive-chase-detail-chart.component.html",
  styleUrls: ["./archive-chase-detail-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveChaseDetailChartComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  chaseId: number;
  isChartMaxed = false;
  chaseEntityData: DynamicEntity[];

  constructor(
    private archiveChaseDetailService: ArchiveChaseDetailService,
    private readonly changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.chaseId = +params.get("chaseGd");
    });

    this.sink.add(
      this.archiveChaseDetailService.state
        .subscribe(state => {
          this.chaseEntityData = state.chaseEntityData;
          this.changeDetector.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  trackByIndex(index, item) {
    return index;
  }
}
