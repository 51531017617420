import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MenuModule as PrimeMenuModule } from "primeng/menu";
import { PanelMenuModule } from "primeng/panelmenu";
import { TabViewModule } from "primeng/tabview";
import { TieredMenuModule } from "primeng/tieredmenu";
import { IconModule } from "../icon/icon.module";
import { SubMenuComponent } from "../menu/sub-menu/sub-menu.component";
import { MenuComponent } from "./menu/menu.component";
import { StepsMenuComponent } from "./steps-menu/steps-menu.component";
import { TabMenuComponent } from "./tab-menu/tab-menu.component";
import { TabPanelComponent } from "./tab-menu/tab-panel.component";
import { TabSubMenuNonRoutedComponent } from "./tab-sub-menu-non-routed/tab-sub-menu-non-routed.component";
import { TieredMenuComponent } from "./tiered-menu/tiered-menu.component";


@NgModule({
  imports: [
    CommonModule,
    IconModule,
    PanelMenuModule,
    PrimeMenuModule,
    TieredMenuModule,
    TabViewModule,
  ],
  declarations: [
    MenuComponent,
    TieredMenuComponent,
    SubMenuComponent,
    TabMenuComponent,
    TabPanelComponent,
    StepsMenuComponent,
    TabSubMenuNonRoutedComponent,
  ],
  exports: [
    MenuComponent,
    TieredMenuComponent,
    SubMenuComponent,
    TabMenuComponent,
    TabPanelComponent,
    StepsMenuComponent,
    TabSubMenuNonRoutedComponent,
  ],
})
export class MenuModule { }
