import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { TagSearchMultiselectComponent } from "./tag-search-multiselect.component";

export interface ITagSearchMulitselecteOptions extends IDynamicInputOptions {
  placeholder?: string;
  options?: SelectableInput[];
  maxSelectedLabels?: number;
  filterPlaceHolder?: string;
  useFilter?: boolean;
}

export class TagSearchMultiselect extends DynamicInput implements ITagSearchMulitselecteOptions {
  filter: boolean;
  options: SelectableInput[];
  hidden: boolean;
  selectedOptions: SelectableInput[];
  maxSelectedLabels: number;
  placeholder: string;
  filterPlaceHolder: string;
  useFilter: boolean;

  readonly controlType = "multiselect";
  readonly componentType = TagSearchMultiselectComponent;

  constructor(options: ITagSearchMulitselecteOptions = {}) {
    super(options);
    this.placeholder = StringHelper.clean(options.placeholder);
    this.options = ArrayHelper.isAvailable(options.options) ? options.options : [];
    this.selectedOptions = options.value || [];
    this.maxSelectedLabels = options.maxSelectedLabels || 3;
    this.filterPlaceHolder = StringHelper.clean(options.filterPlaceHolder);
    this.useFilter = BooleanHelper.tryGet(options.useFilter, true);
  }
}
