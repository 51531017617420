import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ChaseItem } from "../../../api/chase-search/chase-search-result-item";

@Injectable({
  providedIn: "root",
})
export class BulkUpdatesService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  upload(formData: FormData): Observable<any> {
    const url = `${this.baseApiUrl}bulkupdate/fileupload`;

    return this.http.post(url, formData, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return response.body;
          }
        })
      );
  }

  copyChartToChaseUpload(formData: FormData): Observable<any> {
    const url = `${this.baseApiUrl}bulkupdate/copychartchasefileupload`;

    return this.http.post(url, formData, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return response.body;
          }
        })
      );
  }

  validateChasesIsClinicalOrNonClinical(Chases: string[], ProjectId: number): Observable<ChaseItem[]> {
    const validateChasesIsClinicalOrNonClincalRequest = { Chases, ProjectId};
    const url = `${this.baseApiUrl}bulkupdate/chases/validate`;
    return this.http.post(url, validateChasesIsClinicalOrNonClincalRequest).pipe(
      map(this.automapper.curryMany("chase-search-item", "ChaseItem"))
    );
  }

  checkValidationStatus(data: any): boolean {
        return data.map(x => x.validationStatus).filter(x => x === "Fail").length === data.length;
    }

  validateChasePendIsClinicalOrNonClinical(chasePendIds: string): Observable<boolean> {
    const pendIds: string[] = chasePendIds.split(",");
    const url = `${this.baseApiUrl}bulkupdate/pends/validate`;
    return this.http.post(url, { pendIds }, { observe: "response" }).pipe(map((response: HttpResponse<any>): boolean => response.ok ? response.body : true));


  }

  getBulkValidationData(urlString: string, data: any) {
    const url = `${this.baseApiUrl}${urlString}`;

    return this.http.post(url, data) as Observable<null>;
  }
}
