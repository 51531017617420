import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { ContactLogListService } from "./contact-log-list.service";

@Component({
  selector: "app-contact-log-list",
  templateUrl: "./contact-log-list.component.html",
  styleUrls: ["./contact-log-list.component.scss"],

  providers: [ContactLogListService],
})
export class ContactLogListComponent implements  OnInit {

  contactLogGridConfiguration = new GridConfiguration();
  contactLogGridData: any[] = [];
  contactLogGridSelection: any[];
  totalChasesMessage: string;

  constructor(private changeDetector: ChangeDetectorRef,
              private service: ContactLogListService) {
    this.contactLogGridConfiguration.columns = [
      new GridColumnDefinition({ field: "userName", header: "User Name" }),
      new GridColumnDefinition({ field: "callStartTime", header: "Call Start Time" }),
      new GridColumnDefinition({ field: "contactDetail", header: "Contact Detail" }),
      new GridColumnDefinition({ field: "callResult", header: "Call Result" }),
      new GridColumnDefinition({ field: "contactMethodType", header: "Contact Type" }),
      new GridColumnDefinition({ field: "callNote", header: "Call Notes" }),
      new GridColumnDefinition({ field: "callDuration", header: "Call Duration" }),
    ];

    this.contactLogGridConfiguration.pageSize = 25;
    this.contactLogGridConfiguration.selectionMode = "";
    this.contactLogGridConfiguration.showActionColumn = false;
  }

  ngOnInit() {
    const projectStatisticIds = window.sessionStorage.getItem("ContactActivityIds");
    if (projectStatisticIds != null) {
      const logIds = JSON.parse(projectStatisticIds);

      window.sessionStorage.removeItem("ContactActivityIds");
      this.service
        .getContactLogList(projectStatisticIds)
        .subscribe(this.assignAndNotify);
    }
  }
  assignAndNotify = <T>(data: T[]): void => {
    this.contactLogGridData = data as any;
    if (this.contactLogGridData.length > 0) {
      this.totalChasesMessage = `Top ${this.contactLogGridData.length} records.`;
    }
    this.changeDetector.markForCheck();
  }
}
