import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { CommentItem } from "../../../../../../shared/comments/comment-item/comment-item";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { EmrDetailCommentsService } from "./emr-detail-comments.service";

@Component({
  selector: "retrieval-emr-detail-comments",
  templateUrl: "./emr-detail-comments.component.html",
  styleUrls: ["./emr-detail-comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmrDetailCommentsComponent implements OnInit {

  commentItems = List<CommentItem>();
  commentText: string;

  constructor(
    private service: EmrDetailCommentsService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.getComments();
  }

  getComments() {
    this.service
      .getComments()
      .subscribe(items => this.commentItems = this.assignAndNotify(items));
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  saveComments() {
    if (StringHelper.isAvailable(this.commentText)) {
      this.service.saveComment(this.commentText).subscribe(
        data => {
          this.getComments();
          this.changeDetector.markForCheck();
          this.commentText = null;

        },
        err => {
          this.messagingService.showToast("Failed to save comments, please try again.", SeverityType.ERROR);
          this.commentText = null;
        }
      );
    } else {
      this.messagingService.showToast("Comment Text cannot be blank, please try again.", SeverityType.ERROR);
    }

  }

}
