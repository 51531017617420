import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { SaveGroup } from "../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { MemberValidation } from "../../../platform/api/member-validation/member-validation.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { RegExHelper } from "../../../utilities/reg-Ex-Helper";

@Component({
  selector: "app-member-validation",
  templateUrl: "./member-validation.component.html",
  styleUrls: ["./member-validation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberValidationComponent implements OnInit, OnChanges, OnDestroy {
  private sink = new SubSink();
  @Input() enabled = false;
  @Input() configuration: MemberValidation;
  @Output() onSubmit = new EventEmitter<any>(true);
  @Output() onCorrectMemberSelection = new EventEmitter<any>(true);

  form: FormGroup;
  saveGroup: SaveGroup;
  memberValidationInput: Dropdown;
  memberValidationPageNumberInput: Textbox;
  memberValidationReasonInput: Dropdown;
  validationOptions: SelectableInput[];

  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configuration) {
      if (changes.configuration.isFirstChange()) { return; }

      if (changes.configuration.currentValue !== changes.configuration.previousValue) {
        this.getReasonOptions();
        this.createForm();
      }
    }

    if (changes.enabled) {
      if (changes.enabled.isFirstChange()) { return; }

      if (changes.enabled.currentValue !== changes.enabled.previousValue) {
        const memberValidationInputControl = this.form.get(this.memberValidationInput.key);
        this.enabled ? memberValidationInputControl.enable() : memberValidationInputControl.disable();
      }
    }
  }

  handleChange(event): void {
    this.isCorrectMember = event;
    if (this.isCorrectMember) {
      this.clearNotMemberValidatedForms();
      this.submit();
    }
    this.onCorrectMemberSelection.emit(this.isCorrectMember);
  }

  private getReasonOptions() {
    if (ArrayHelper.isAvailable(this.configuration.reasonInfo.options)) {
      this.memberValidationReasonInput = new Dropdown({
        ...this.memberValidationReasonInput,
        options: this.reasonOptions,
      } as any);
    }
  }

  private createForm() {
    if (!this.hasIsCorrectMember) {
      this.form.get(this.memberValidationInput.getMasterKey()).setValue(1);
      this.isCorrectMember = true;
    } else {
      const correctMember = this.isYes ? 1 : null;
      this.form.get(this.memberValidationInput.getMasterKey()).setValue(correctMember);
    }
    this.changeDetector.markForCheck();
  }

  private initializeForm(): void {
    this.validationOptions = [
      new SelectableInput({ text: "Yes", value: 1 }),
      new SelectableInput({ text: "No", value: 0 }),
    ];

    this.memberValidationInput = new Dropdown({
      key: "memberValidation",
      placeholder: "Select...",
      label: "Correct Member?",
      disabled: this.disableMemberValidationInput,
      options: this.validationOptions,
    });

    this.memberValidationPageNumberInput = new Textbox({
      key: "memberValidationPageNumber",
      placeholder: "Pg.",
      label: "Page Number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.memberValidationReasonInput = new Dropdown({
      key: "memberValidationReason",
      label: "Reason",
      placeholder: "Select...",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Select a reason.",
      },
    });

    this.form = this.formService.createFormGroup([
      this.memberValidationInput,
      this.memberValidationPageNumberInput,
      this.memberValidationReasonInput,
    ]);

    this.sink.add(
      this.form.get(this.memberValidationReasonInput.getMasterKey()).valueChanges
        .subscribe(value =>
          this.configuration.reason = value == null ? null : value),
      this.form.get(this.memberValidationPageNumberInput.getMasterKey()).valueChanges
        .subscribe(value =>
          this.configuration.pageNumber = value == null ? null : Number(value))
    );
  }

  private clearNotMemberValidatedForms(): void {
    this.form.get(this.memberValidationPageNumberInput.getMasterKey()).reset();
    this.form.get(this.memberValidationReasonInput.getMasterKey()).reset();
  }

  get isCorrectMember(): boolean {
    return this.configuration.isValid;
  }
  set isCorrectMember(value: boolean) {
    this.configuration.isValid = value;
  }

  get reasonOptions(): SelectableInput[] {
    return this.configuration.reasonInfo.options;
  }

  get hasIsCorrectMember(): boolean {
    return typeof this.isCorrectMember === "boolean";
  }

  get isYes(): boolean {
    return this.hasIsCorrectMember ? this.isCorrectMember : false;
  }

  get isNo(): boolean {
    return this.hasIsCorrectMember ? !this.isCorrectMember : false;
  }

  get isValid(): boolean {
    return this.isYes || (this.isNo && this.form.valid);
  }

  get disableSubmitButton(): boolean {
    return !this.enabled || !this.isValid;
  }

  get disableMemberValidationInput(): boolean {
    return !this.enabled;
  }

  submit(): void {
    if (this.isValid) {
      this.onSubmit.emit(this.configuration);
    }
  }
}
