import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { UserToken } from "../../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseSearchRequest } from "../../../../../../platform/api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../../platform/api/chase-search/chase-search.service";
import { ChaseIdComponent } from "../../../../../../shared/chase-grid/chase-id.component";
import { BulkAction } from "../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../../shared/grid/models/grid-request.model";
import { InvoiceType } from "../../../../../../shared/invoice/invoice-type.enum";
import { ListItem } from "../../../../../../shared/list/list-item";
import { ChasePendItem } from "../../../../../../shared/pend/chase-pend-item.model";
import { CreatePendService } from "../../../../../../shared/pend/create-pend.service";
import { PendStatus } from "../../../../../../shared/pend/pend-status.enum";
import { RetrievalUtilities } from "../../../../../../shared/retrieval/retrieval.utilites";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ChaseItem } from "../../../../../api/chase-search/chase-search-result-item";
import { FaxProviderRequest } from "../../../../../api/provider/fax-provider-request-model";
import { ProviderSearchRequest } from "../../../../../api/provider/provider-search-request-model";
import { ProviderService } from "../../../../../api/provider/provider.service";
import { ProviderPacketItem } from "../../../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ChaseMoveModel } from "../../../../project/chase-query/chase-move.model";
import { ChaseQueryService } from "../../../../project/chase-query/chase-query.service";
import { AddressSearchResult } from "../../../address-search/address-search-result.model";
import { CommitmentDateUpdateModel } from "../../../commitment-date/commitmentDateUpdate.model";
import { ContactRequest } from "../../../cover-letter-template/contact-request.model";
import { EmailRequest } from "../../../cover-letter-template/email-request.model";
import { FaxRequest } from "../../../cover-letter-template/fax-request.model";
import { AddressDetailEditService } from "../address-detail-edit/address-detail-edit.service";
import { AddressEditModel } from "../address-detail-edit/address-edit-model";
import { AddressDetailInfoService } from "./address-detail-info.service";
import { CoverTemplateDetails } from "./cover-template-details";
import { EmailProviderRequest } from "./email-provider-request-model";

@Component({
    selector: "retrieval-address-detail-info",
    templateUrl: "./address-detail-info.component.html",
    styleUrls: ["./address-detail-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDetailInfoComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject();

    @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;

    @Input() chaseIdRouteUrl = "/members/chase/:chaseId";

    addressId: number;
    private user: UserToken;
    timelineItems = List<TimelineItem>();
    summaryItems = List<ListItem>();
    isCreatePendView = false;
    isEditPendView = false;
    @Output() formClose = new EventEmitter();
    readonly form: FormGroup;
    private pageSize = 25;
    chaseIdList: number[] = [];
    chasePendIds: number[] = [];
    pendIds: number[] = [];
    isPendIds: boolean;
    pendType: SelectItem[] = [];
    pendStatus: SelectItem[] = [];
    value: string;
    subscription: Subscription;
    startPage: number;
    endPage: number;
    chasePendId: number;
    assignedTo: number;
    private documentRetrievalLead = 4;
    private functionalRole: number;
    private documentRetrieval = 5;
    readonly AUTHENTICATION_KEY = "authentication";
    isClinical: boolean;
    selectedTab = "chases";
    contactRequest: ContactRequest;
    providerGridConfiguration = new GridConfiguration();
    providerGridData: any[] = [];
    providerGridSelection: any[];

    chaseGridConfiguration = new GridConfiguration();
    chaseGridData: any[] = [];
    memberGridData: any[] = [];
    memberGridDataAll: any[] = [];
    chaseGridSelection: any[];
    chaseGridDataAll = [];
    startRecord: number;
    endRecord: number;
    private AllRecords = 2147483647;
    private gridSearchRequest: any;
    private sendProviderFaxModel: FaxProviderRequest;
    addressEditModel: AddressEditModel;
    private sendProviderEmailModel: EmailProviderRequest;
    rowData: any;
    providerPacketItem: ProviderPacketItem;
    isAddressModalVisible = false;
    isConfirmModalVisible = false;
    isMoveVisible = false;

    addressSearchGridConfiguration = new GridConfiguration();
    addressSearchData: any[] = [];
    addressGridselection: any;

    gridConfigurationModel = new GridConfiguration();
    retrievalLocationId: Textbox;
    selectedAddress: AddressSearchResult;
    confirmStatusMessage: any;
    pageType: any;
    chaseMoveModel: any;
    assignedUser: string;
    projectId: number;
    commitDateUpdateModel: CommitmentDateUpdateModel;
    isCommitmentDateModalVisible = false;
    addressSearchCallingSource = "Move Chase";

    selectedChases: any[];
    isPendModalVisible = false;
    @Input() clinical = false;
    nonActionableChasesChecked = false;
    moveChaseText = "Request Move";
    isTemplateModalVisible = false;
    templateName: string;
    coverTemplateData: CoverTemplateDetails;
    userName: string;
    phoneNumber: string;
    userEmail: string;
    membersGridConfiguration = new GridConfiguration();
    membersGridRequest: GridRequest;
    expandedRows = {};

    memberChaseGridConfiguration = new GridConfiguration();
    requestCallingSource: string;
    isVendorAssignVisible: boolean;
    vendorForm: FormGroup;
    vendorInput: Dropdown;

    constructor(
        @Inject(BASE_API_URL) private readonly baseApiUrl: string,
        private service: AddressDetailInfoService,
        private chaseService: ChaseSearchService,
        private providerService: ProviderService,
        private addressDetailservice: AddressDetailEditService,
        private messagingService: MessagingService,
        private userService: UserService,
        private changeDetector: ChangeDetectorRef,
        private router: Router,
        private readonly route: ActivatedRoute,
        private chaseQryService: ChaseQueryService,
        private formService: FormService,
        private readonly automapper: AutomapperService,
        private readonly createPendService: CreatePendService
    ) { }

    ngOnInit() {
        this.selectedTab = "chases";
        this.addressId = this.service.getAddressGdFromPath();
        this.user = this.userService.getUserToken();
        this.assignedTo = (this.user.functionalRoleIds.indexOf(this.documentRetrievalLead) > -1) ? null : this.user.userId;
        this.moveChaseText = this.isUserMangerAdminLead ? "Move Chase" : "Request Move";
        this.pageType = "Psr";

        this.createGrids();

        this.route.paramMap.subscribe(params => {
            this.addressId = +params.get("AddressGd");


            this.getSummaryItems();

            this.service
                .getTimelineItems()
                .subscribe(items => this.timelineItems = this.assignAndNotify(items));

            // Load Provider Grid
            this.startRecord = 1;
            this.endRecord = this.AllRecords;
            this.gridSearchRequest =
                new ProviderSearchRequest(null, null, this.addressId, null, null, null,
                                          null, null, this.startRecord, this.endRecord);

            this.providerService
                .providerSearch(this.gridSearchRequest)
                .subscribe(items => {
                    this.providerGridData = items as any;
                    this.changeDetector.markForCheck();
                });

            // Load Chase Grid
            this.functionalRole = this.documentRetrieval;

            this.gridSearchRequest =
                new ChaseSearchRequest(
                    null, null, null, null, this.addressId, this.assignedTo, null, null, null, null, null,
                    null, this.startRecord, this.endRecord);

            this.chaseService
                .chaseSearch(this.gridSearchRequest)
                .subscribe(items => {
                    this.chaseGridDataAll = items as any;
                    this.chaseGridData = this.getChaseGridData();
                    const chases = this.chaseGridDataEnabled.map(x => x.chaseID);

                    this.chaseService.getChaseList(chases);
                    this.changeDetector.markForCheck();
                });

            this.getMemberCentricViewData();


            this.getAddressDetailsForEdit();

            this.membersGridRequest = new GridRequest({
                url: `${this.baseApiUrl}members/listbyaid`,
                filters: [
                    new GridFilter({
                        input: new Textbox(),
                        key: "MasterDocumentSourceId",
                        name: "MasterDocumentSourceId",
                        value: this.addressId.toString(),
                        show: false,
                    }),
                ],
            });

        });

        this.service.reset
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(id => this.updateAddressDetails(id));

        this.providerService.reset
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => this.updateProviderGrid());

        this.chaseService.reset
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => this.updateChaseGrid());

        this.userService.getUser(this.user.userId).subscribe(result => {
            this.changeDetector.markForCheck();
            this.userName = `${result.firstName} ${result.lastName}`;
            this.phoneNumber = result.phone;
            this.userEmail = result.email;
        });
        this.getVendors();

        this.vendorInput = new Dropdown({
            key: "vendorInput",
            placeholder: "Select Vendor",
            appendTo: "body",
        });

        this.vendorForm = this.formService.createFormGroup([this.vendorInput]);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggleTab(tab: string): void {
        this.selectedTab = this.selectedTab === tab ? "" : tab;
        this.changeDetector.markForCheck();
    }

    createGrids(): void {
        this.providerGridConfiguration.columns = [
            new GridColumnDefinition({
                field: "serviceProviderName",
                header: "Provider Name",
            }),
            new GridColumnDefinition({
                field: "nationalProviderId",
                header: "NPI",
            }),
            new GridColumnDefinition({
                field: "providerGroup",
                header: "Group",
            }),
            new GridColumnDefinition({
                field: "speciality",
                header: "Speciality",
            }),
        ];

        this.providerGridConfiguration.pageSize = this.pageSize;
        this.providerGridConfiguration.selectionMode = "multiple";
        this.providerGridConfiguration.showActionColumn = false;

        this.chaseGridConfiguration.columns = [
            new GridColumnDefinition({ field: "chaseID", template: this.chaseIdColumn, header: "Chase ID", width: "135px" }),
            new GridColumnDefinition({ field: "memberFirstName", header: "First" }),
            new GridColumnDefinition({ field: "memberLastName", header: "Last" }),
            new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
            new GridColumnDefinition({ field: "memberGender", header: "M/F" }),
            new GridColumnDefinition({ field: "serviceProviders", header: "Provider Name" }),
            new GridColumnDefinition({ field: "projectName", header: "Project" }),
            new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
            new GridColumnDefinition({ field: "documentRequestId", header: "Doc Request ID" }),
            new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
            new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
            new GridColumnDefinition({ field: "pendCode", header: "Pend", routeUrl: "/pend/detail/:chasePendId" }),
            new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
        ];

        this.chaseGridConfiguration.pageSize = this.pageSize;
        this.chaseGridConfiguration.selectionMode = "multiple";
        this.chaseGridConfiguration.showActionColumn = true;
        this.chaseGridConfiguration.bulkActions = [
            new BulkAction({
                name: "Pend Chase(s)",
                action: items => this.openPendModal(),
            }),
            new BulkAction({
                name: "Fax Request(s)",
                action: this.openFaxModal.bind(this),
            }),
            new BulkAction({
                name: "Email Request(s)",
                action: this.openEmailModal.bind(this),
            }),
            new BulkAction({
                name: this.moveChaseText.concat("(s)"),
                action: items => this.moveChase(),
            }),
            new BulkAction({
                name: "Print Request(s)",
                action: items => this.printProvider(),
            }),
            new BulkAction({
                name: "Set Commitment Date",
                action: items => this.openCommitmentDateModal(),
            }),
        ];

        this.gridConfigurationModel.columns = [
            new GridColumnDefinition({ field: "addressId", header: "ID", routeUrl: "/retrieval/addressdetail/:addressId" }),
            new GridColumnDefinition({ field: "address", header: "Address" }),
            new GridColumnDefinition({ field: "chaseCount", header: "Chases" }),
            new GridColumnDefinition({ field: "status", header: "Status" }),
            new GridColumnDefinition({ field: "phone", header: "Phone" }),
        ];
        this.gridConfigurationModel.pageSize = 25;
        this.gridConfigurationModel.showActionColumn = false;

        this.membersGridConfiguration = new GridConfiguration({
            columns: [
                new GridColumnDefinition({ field: "id", header: "Member ID" }),
                new GridColumnDefinition({ field: "name", header: "Name" }),
                new GridColumnDefinition({ field: "dob", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
                new GridColumnDefinition({ field: "gender", header: "Gender" }),
                new GridColumnDefinition({ field: "chases", header: "Chases" }),
                new GridColumnDefinition({ field: "projectCount", header: "Projects" }),
            ],
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            rowExpandMode: "multiple",
            showActionColumn: false,
            showMenu: false,
            showRowExpansionColumn: true,
            trackByField: "id",
            expandedRows: this.expandedRows,
        });

        this.memberChaseGridConfiguration = new GridConfiguration({
            columns: [
                new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
                new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
                new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
                new GridColumnDefinition({ field: "projectName", header: "Project" }),
                new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
                new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
                new GridColumnDefinition({ field: "chartAppointmentDate", header: "Scheduled Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
                new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
            ],
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            showActionColumn: false,
            showMenu: false,
        });

    }

    getChaseGridData(): any[] {
        this.chaseGridDataAll.forEach(x => {
            // Disable chases in "Chart collection", "Waiting for chart" workflow status or which are moved or request made.
            // Make them non - actionable.
            if ((x.workflowStatusId !== 2 && x.workflowStatusId !== 3)
                || (x.pendCode === "PC900" && (x.pendStatusId === 1 || x.pendStatusId === 2 || x.pendStatusId === 4))) {
                x.disabled = true;
            }
        });

        return this.nonActionableChasesChecked ? this.chaseGridDataAll : this.chaseGridDataEnabled;
    }

    get chaseGridDataEnabled(): any[] {
        return this.chaseGridDataAll.filter(c => !c.disabled);
    }

    getMemberCentricViewData(): void {
        this.service
            .getMembers(this.addressId)
            .subscribe(items => {
                this.memberGridDataAll = items as any;
                items.forEach(item => {
                    this.expandedRows[item.id] = 1;
                });
                this.memberChaseGridConfiguration.expandedRows = this.expandedRows;
                this.memberGridData = this.getMemberGridData();

                this.changeDetector.markForCheck();
            });
    }

    getMemberGridData(): any[] {
        if (this.nonActionableChasesChecked) {
            return this.memberGridDataAll;
        } else {
            const excludeDisabledChases = this.memberGridDataAll.map(a => ({ ...a }));
            excludeDisabledChases.forEach(x => {
                const chases = x.chaseList.filter(c => !c.disabled);
                return x.chaseList = chases;
            });
            return excludeDisabledChases;
        }
    }

    get isUserMangerAdminLead(): boolean {
        return (this.user.isManagerRole || this.user.isAdminRole || this.user.isLeadRole);
    }

    updateAddressDetails(id) {
        this.service
            .getSummaryItems()
            .subscribe(items => this.summaryItems = this.assignAndNotify(items));

        this.getAddressDetailsForEdit();
    }

    updateProviderGrid() {
        this.startRecord = 1;
        this.endRecord = this.AllRecords;
        this.gridSearchRequest =
            new ProviderSearchRequest(null, null, this.addressId, null, null, null,
                                      null, null, this.startRecord, this.endRecord);

        this.providerService
            .providerSearch(this.gridSearchRequest)
            .subscribe(items => {
                this.providerGridData = items as any;
                this.changeDetector.markForCheck();
            });
    }

    updateChaseGrid() {
        this.gridSearchRequest =
            new ChaseSearchRequest(
                null, null, null, null, this.addressId, this.assignedTo, null, null, null, null, null,
                null, this.startRecord, this.endRecord);

        this.chaseService
            .chaseSearch(this.gridSearchRequest)
            .subscribe(items => {
                this.chaseGridDataAll = items as any;
                this.chaseGridData = this.getChaseGridData();

                const chases = this.chaseGridDataEnabled.map(x => x.chaseID);

                this.chaseService.getChaseList(chases);
                this.changeDetector.markForCheck();
            });
    }

    private assignAndNotifyAddressDetails = (data: AddressEditModel): void => {
        this.addressEditModel = new AddressEditModel(data);

        this.changeDetector.markForCheck();
    }

    selectRow(rowData) {
        const key = "chasePendId";
        let selectedRows = [];
        this.chasePendIds = [];
        if (rowData !== undefined) {
            selectedRows.push(rowData);
        } else {
            selectedRows = this.actionableChaseGridSelection;
        }

        if (selectedRows && selectedRows.length > 0) {
            const pendId = selectedRows[0][key];
            this.chaseIdList = [];
            this.pendIds = [];

            if (pendId !== 0) {
                this.chasePendId = pendId;
            }

            const checkValue = selectedRows.filter(c => c.pendCode);

            if (checkValue.length > 0 && checkValue.length < selectedRows.length) {
                this.messagingService.showToast("Please select similar Chases for bulk actions - either with Pend or without Pend", SeverityType.ERROR);
                return;
            }
            selectedRows.forEach(item => {
                this.chaseIdList.push(item.chaseID);
                if (item.chasePendId !== 0) {
                    this.chasePendIds.push(item.chasePendId);
                    this.pendIds.push(item.chasePendId);
                }
            });
            this.isClinical = false;
            if (!this.isAddressModalVisible) {
                this.pendIds.length > 0 ? this.isEditPendView = true : this.isCreatePendView = true;
            }
            this.formClose.emit();
        } else {
            this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
            return;
        }

        this.chaseGridSelection = [];
    }

    get actionableChaseGridSelection(): ChaseItem[] {
        return (ArrayHelper.isAvailable(this.chaseGridSelection))
            ? this.chaseGridSelection.filter(c => !c.disabled)
            : [];
    }

    uploadChase(): void {
        const retreivalTypePSR = 1;

        this.router.navigate(["retrieval", "chaseUpload", this.addressId, retreivalTypePSR]);
    }

    openFaxModal(rowData: any): void {
        if (!this.tryGetAndSetChaseIdList(rowData)) {
            this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
            return;
        }

        const faxRequest = new FaxRequest({
            ...this.getSharedContactRequestProperties(),
            to: this.addressEditModel.faxNumber,
        });

        this.openTemplateModal(faxRequest);
    }

    openEmailModal(rowData?: any): void {
        if (!this.tryGetAndSetChaseIdList(rowData)) {
            this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
            return;
        }

        const emailRequest = new EmailRequest({
            ...this.getSharedContactRequestProperties(),
            to: this.addressEditModel.email,
            toPhone: "",
        });
        this.openTemplateModal(emailRequest);
    }

    private getSharedContactRequestProperties(): any {
        return {
            documentSourceId: this.addressId,
            toPhone: this.addressEditModel.phone,
            toName: this.addressEditModel.contactName,
            from: this.phoneNumber,
            fromName: this.userName,
            chaseIds: [...this.chaseIdList],
        };
    }

    openTemplateModal(contactRequest: ContactRequest): void {
        if (!contactRequest.isValid) {
            const toValue = StringHelper.isAvailable(contactRequest.toValue) ? ` '${contactRequest.toValue}' ` : " ";
            this.messagingService.showToast(`The ${contactRequest.toLabel}${toValue}is invalid.`, SeverityType.WARN);
        }

        this.contactRequest = contactRequest;
        this.isTemplateModalVisible = true;
        this.changeDetector.markForCheck();
    }

    receiveValue($event) {
        this.chaseGridSelection = [];
        this.isCreatePendView = $event;
    }

    receiveEditPendValue($event) {
        this.isEditPendView = $event;
    }

    private rowIncludes = (parameter: string, find: string): (row: any) => boolean => {
        return (row: any) => {
            if (!StringHelper.isAvailable(find)) {
                return true;
            }

            const value = row[parameter];
            if (!StringHelper.isAvailable(value)) {
                return false;
            }

            const isIncluded = value.toLowerCase().includes(find.toLowerCase());
            return isIncluded;
        };
    }

    private assignAndNotify<T>(data: T[]): List<T> {
        this.changeDetector.markForCheck();
        const dataList = List(data);

        return dataList;
    }

    showTimelineDetails() {
        return `retrieval/addressdetail/${this.addressId}/timeline`;
    }

    moveChase(rowData?: any): void {
        this.selectRow(rowData);
        if (ArrayHelper.isAvailable(this.chaseIdList)) {
            this.isAddressModalVisible = true;
        }
    }

    gotoAddressDetail() {
        this.isConfirmModalVisible = false;
        if (this.selectedAddress) {
            const addressType = RetrievalUtilities.getDocumentSourceTypeName(this.selectedAddress);
            this.router.navigateByUrl(`retrieval/${addressType.toLowerCase()}/${this.selectedAddress.masterDocumentSourceId}`);
        }
    }

    confirmMessage(): void {
        this.isConfirmModalVisible = !this.isConfirmModalVisible;
        this.changeDetector.markForCheck();
    }

    onAddressSelectionForChaseMove($event: AddressSearchResult): void {
        this.isMoveVisible = !this.isMoveVisible;
        this.selectedAddress = $event;
    }

    sendContactRequest(event: ContactRequest): void {
        this.providerService.sendProviderContactRequest(event).subscribe({
            next: () => {
                this.isTemplateModalVisible = false;
                this.chaseGridSelection = [];
                this.messagingService.showToast(`${event.typeName} Queued for Sending.`, SeverityType.SUCCESS);
                this.changeDetector.markForCheck();
            },
            error: () => this.messagingService.showToast(`Error while sending ${event.typeName}, please try again.`, SeverityType.ERROR),
        });
    }

    chaseMoved() {
        this.assignedUser = "";
        this.chaseMoveModel = new ChaseMoveModel({
            toAddressId: this.selectedAddress.masterDocumentSourceId.toString(),
            fromAddressId: this.addressId.toString(),
            chaseIds: this.chaseIdList.join(),
            pageType: this.pageType,
            assignedUser: this.assignedUser,
            status: this.isUserMangerAdminLead ? null : "Pending Approval",
            notes: this.selectedAddress.notes,
            isChaseAssign: this.selectedAddress.isChaseAssign,
        });

        this.chaseQryService.chaseMove(this.chaseMoveModel).subscribe(
            () => {
                this.confirmStatusMessage = this.isUserMangerAdminLead ?
                    `${this.chaseIdList.length} chase(s) successfully moved to AID ${this.selectedAddress.masterDocumentSourceId}`
                    : `Move Request for ${this.chaseIdList.length} chase(s) to AID ${this.selectedAddress.masterDocumentSourceId} successfully submitted`;

                this.updateChaseGrid();
                this.service
                    .getTimelineItems()
                    .subscribe(items => this.timelineItems = this.assignAndNotify(items));
                this.getMemberCentricViewData();
                this.confirmMessage();
            },
            err => {
                this.isUserMangerAdminLead
                    ? this.messagingService.showToast(`Error while moving chase to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR)
                    : this.messagingService.showToast(`Error while making chase move request to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR);
            }
        );

        this.isMoveVisible = false;
        this.isAddressModalVisible = false;
        this.changeDetector.markForCheck();
    }

    closeChaseMovePop() {
        this.isMoveVisible = false;
    }

    printProvider(rowData?: any): void {

        this.chaseIdList = [];
        if (rowData !== undefined) {
            const selectedRows = rowData;
            this.chaseIdList.push(selectedRows.chaseID);
            this.projectId = selectedRows.projectID;
        } else {
            const selectedRows = this.actionableChaseGridSelection;

            if (selectedRows && selectedRows.length > 0) {
                selectedRows.forEach(item => {
                    this.chaseIdList.push(item.chaseID);
                    this.projectId = item.projectID;
                });
            } else {
                this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
                return;
            }
        }
        this.providerPacketItem = new ProviderPacketItem({
            chaseIds: this.chaseIdList,
            projectId: this.projectId,
            toName: this.addressEditModel.contactName,
            toPhone: this.addressEditModel.phone,
            toFax: this.addressEditModel.faxNumber,
            fromName: "",
            fromPhone: "",
            providerName: "",
            serviceOrgName: "",
        });

        this.providerService.printProviderPacket(this.providerPacketItem).subscribe(
            data => {
                if (data != null) {
                    this.providerService.downloadPdf(data);
                    this.chaseGridSelection = [];
                    this.changeDetector.markForCheck();
                } else {
                    this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
                }
            },
            err => {
                this.messagingService.showToast("Error while printing Provider Packet.", SeverityType.INFO);
            });
    }

    openCommitmentDateModal(rowData?: any) {
        this.selectedRowsForChaseGridAction(rowData);

        if (ArrayHelper.isAvailable(this.chaseIdList)) {
            this.isCommitmentDateModalVisible = true;
        }

        this.commitDateUpdateModel = new CommitmentDateUpdateModel({
            chaseIds: this.chaseIdList,
            functionalRoleId: 5, // Document Retrieval
            retrievalLocationId: this.addressId,
        });
    }

    onCommitmentDateUpdate($event) {
        this.updateChaseGrid();

        this.service
            .getSummaryItems()
            .subscribe(items => this.summaryItems = this.assignAndNotify(items));
    }

    onCommitmentDateModalHide($event) {
        this.chaseIdList = [];
        this.chaseGridSelection = [];
    }

    selectedRowsForChaseGridAction(rowData?: any) {
        this.chaseIdList = [];
        if (rowData != null) {
            const selectedRow = rowData;
            this.chaseIdList.push(selectedRow.chaseID);

        } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
            this.actionableChaseGridSelection.forEach(item => this.chaseIdList.push(item.chaseID));
        } else {
            this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
            return;
        }
    }


    openPendModal(rowData?: any): void {
        this.selectedChases = [];
        if (rowData != null) {
            this.selectedChases.push(rowData);
        } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
            this.actionableChaseGridSelection.forEach(item => this.selectedChases.push(item));
        }

        if (ArrayHelper.isAvailable(this.selectedChases)) {
            this.isPendModalVisible = true;
        } else {
            this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
        }
    }

    closeModal(): void {
        this.selectedChases = [];
        this.chaseGridSelection = [];
    }

    changeChaseGridData(): void {
        this.chaseGridData = this.getChaseGridData();
        this.memberGridData = this.getMemberGridData();
        this.changeDetector.markForCheck();
    }


    private tryGetAndSetChaseIdList(rowData: any): boolean {
        const chases = rowData == null ? this.actionableChaseGridSelection : rowData;
        this.chaseIdList = this.getChaseIds(chases);
        return ArrayHelper.isAvailable(this.chaseIdList);
    }

    private getChaseIds(rowData: any): number[] {
        const chases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
        const chaseIds = chases.map(a => Number(a.chaseID));
        return chaseIds;
    }

    trackByIndex(index, item) {
        return index;
    }

    assignVendor() {
        this.isVendorAssignVisible = true;
        if (NumberHelper.isGreaterThan(this.addressEditModel.vendorId, 0)) {
            this.vendorForm.patchValue({
                vendorInput: this.addressEditModel.vendorId,
            });
        }
    }

    cancelAssignment() {
        this.isVendorAssignVisible = false;
        this.vendorForm.reset();
    }

    private getVendors(): void {
        const invoiceType = `${InvoiceType.NET30},${InvoiceType.COD}`;
        this.service
            .getVendors(invoiceType)
            .pipe(map(this.automapper.curryMany("Vendors", "SelectableInput")))
            .subscribe(options => {
                this.vendorInput = new Dropdown({ ...this.vendorInput, options } as any);
            });

        this.changeDetector.markForCheck();
    }

    get isVendorSelected(): boolean {
        return NumberHelper.isGreaterThan(this.vendorId, 0);
    }

    get vendorId(): number {
        return this.vendorForm.get("vendorInput").value;
    }

    assignAddressToVendor() {
        this.service.assignAddressToVendor(this.addressId, this.vendorId)
            .subscribe(() => {
                this.isVendorAssignVisible = false;
                this.getSummaryItems();
                this.getAddressDetailsForEdit();
                this.createPendForDeniedInvoice(this.addressId, this.vendorId);
            });
    }

    get assignVendorButtonText(): string {
        const hasAddress = this.addressEditModel != null;
        const hasVendorId = hasAddress && NumberHelper.isGreaterThan(this.addressEditModel.vendorId, 0);
        const vendorAssignmentButtonText = hasVendorId ? "RE-ASSIGN 3RD PARTY VENDOR" : "ASSIGN 3RD PARTY VENDOR";
        return vendorAssignmentButtonText;
    }

    getSummaryItems() {
        this.service
            .getSummaryItems()
            .subscribe(items => this.summaryItems = this.assignAndNotify(items));
    }

    getAddressDetailsForEdit() {
        this.addressDetailservice
            .getAddressDetailsForEdit()
            .subscribe(this.assignAndNotifyAddressDetails);
    }

    createPendForDeniedInvoice(addressId: number, vendorId: number) {

        this.service
            .getChaseIdsToCreatePend(addressId, vendorId)
            .subscribe(result => {
                const chaseIds = result;

                if (ArrayHelper.isAvailable(chaseIds)) {
                    const chasePendItem = new ChasePendItem({
                        owner: "C",
                        pendTypeId: 1, // TODO: To be replace with new pend type.
                        pendStatusId: PendStatus.New,
                        chaseIds,
                        notes: "Invoice amount is above the approved threshold",
                    });

                    this.createPendService.save(chasePendItem).subscribe(() => this.updateChaseGrid());
                    this.changeDetector.markForCheck();
                }
            });
    }

}
