import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chase-move-report-dw",
  templateUrl: "./chase-move-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveReportDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.CHASE_MOVE_REPORT_DW;
  hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
}
