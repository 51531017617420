import { TagItems } from "../../../../shared/tags/model/tag-items.model";
import { StringHelper } from "../../../../utilities/contracts/string-helper";


export class DocumentApprovalSearchResponse {

  documentApprovalId: number;
  documentQueueId: number;
  approvalType: string;
  chaseId: number;
  projectName: string;
  createDate: Date;
  createdBy: string;
  documentApprovalStateId: number;
  approvalState: string;
  recordCount: number;
  approvalStatusDirectoryUserID?: number;
  approvalStatusDate?: Date;
  chaseStatus?: string;
  measure?: string;
  retreivalType?: string;
  numberOfPages?: number;
  approvalStatusUserName?: string;
  measureCode?: string;
  sampleComplianceCode?: string;
  chaseSourceAliasId?: string;
  tagItems?: TagItems[];
  workingDocumentId?: number;

  constructor(options: {
    documentApprovalId: number;
    documentQueueId: number;
    approvalType: string;
    chaseId: number;
    projectName: string;
    createDate: Date;
    createdBy: string;
    documentApprovalStateId: number;
    approvalState: string;
    recordCount: number;
    approvalStatusDirectoryUserID?: number;
    approvalStatusDate?: Date;
    chaseStatus?: string;
    measure?: string;
    retreivalType?: string;
    numberOfPages?: number;
    approvalStatusUserName?: string;
    measureCode?: string;
    sampleComplianceCode?: string;
    chaseSourceAliasId?: string;
    tagItems?: TagItems[];
    workingDocumentId?: number;
  }) {

    this.documentApprovalId = options.documentApprovalId;
    this.documentQueueId = options.documentQueueId;
    this.approvalType = options.approvalType;
    this.chaseId = options.chaseId;
    this.projectName = options.projectName;
    this.createDate = options.createDate;
    this.createdBy = StringHelper.clean(options.createdBy);
    this.documentApprovalStateId = options.documentApprovalStateId;
    this.approvalState = options.approvalState;
    this.recordCount = options.recordCount;
    this.approvalStatusDirectoryUserID = options.approvalStatusDirectoryUserID;
    this.approvalStatusDate = options.approvalStatusDate;
    this.chaseStatus = options.chaseStatus;
    this.measure = options.measure;
    this.retreivalType = options.retreivalType;
    this.numberOfPages = options.numberOfPages;
    this.approvalStatusUserName = options.approvalStatusUserName;
    this.measureCode = options.measureCode;
    this.sampleComplianceCode = options.sampleComplianceCode;
    this.chaseSourceAliasId = StringHelper.clean(options.chaseSourceAliasId) ;
    this.tagItems = options.tagItems;
    this.workingDocumentId = options.workingDocumentId;
  }

}
