export enum DocumentUploadRequestRetrievalType {
    EMRUpload = 1,
    Mail = 2,
    Fax = 3,
    FieldTechUpload = 4,
    MRRUpload = 5,
    Intake = 6,
    ProviderEmail = 7,
    PSRUpload = 8,
    PendUpload = 9,
    FTP = 10,
    BulkUpload = 11,
    SMRC = 12,
    InvoiceUpload = 13,
}
