import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { CreatePendService } from "../../../../../shared/pend/create-pend.service";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { PendType } from "../../../../../shared/pend/pend-type.enum";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";
import { InternalPendsDetailHeaderService } from "../internal-pends-detail-header/internal-pends-detail-header.service";
import { InternalPendsDetailInfoService } from "../internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsUpdatePendService } from "./internal-pends-update-pend.service";
import { UpdatePend } from "./update-pend.model";

@Component({
  selector: "app-internal-pends-update-pend",
  templateUrl: "./internal-pends-update-pend.component.html",
  styleUrls: ["./internal-pends-update-pend.component.scss"],
})
export class InternalPendsUpdatePendComponent implements OnInit {
  @Input() isUpdatePendVisible = false;
  @Output() formClose = new EventEmitter();
  private pendId: number;
  @Input() chasePendIds = [];
  formGroup: FormGroup;
  pendInvoice: Textbox;
  pendAmount: Textbox;
  pendCompany: any[];
  pendOwner: SelectableInput[];
  ownedBy: string;
  assignedUsers: SelectableInput[];
  isPendReasonVisible = false;
  pendReasons: SelectableInput[];
  pendStatus: SelectableInput[];
  pendNotes: Textarea;

  isClientOnly = false;
  @Input() isClinical = false;
  user: UserToken;
  statusData = "";
  @Input() owner;
  @Input() pendStatusId;
  chaseId: string;
  btnLabel = "UPDATE PEND";
  headerLabel: string;
  isOwnerChanged = false;

  // For PendCode control
  @Input() pendCode: string;
  private updatePend: UpdatePend;
  isPendStatusChangeVisible = false;
  isPendCloseVisible = false;
  isPendOwnerChange = false;
  isCancelConfirmationModalVisible = false;
  isResolveConfirmationModalVisible = false;

  // For valdiation
  checkError = false;
  showInvoiceError = false;
  showAmountError = false;
  showCompanyError = false;
  showAssignedUserError = false;
  showStatusError = false;
  showNoteError = false;
  private modelErrors: any[] = [];
  private newLine = "\r";
  required = "Field is Required";
  noteErrorTitle: string;
  pendSeverityInput: Dropdown;
  pendCompanyInput: Dropdown;
  pendOwnerInput: Dropdown;
  pendUserInput: Dropdown;
  pendStatusInput: Dropdown;
  pendReasonInput: Dropdown;

  constructor(
    private readonly formService: FormService,
    private service: InternalPendsUpdatePendService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private readonly route: ActivatedRoute,
    private messagingService: MessagingService,
    private serviceSet: InternalPendsDetailInfoService,
    private serviceHeaderSet: InternalPendsDetailHeaderService,
    protected readonly automapper: AutomapperService,
    protected readonly createPendService: CreatePendService
  ) {

    this.user = this.authService.user;
    this.pendId = this.service.getPendGdFromPath();
  }


  ngOnInit() {
    this.getFormLoad();
    if ((this.pendStatusId === "New" || this.pendStatusId === "In Progress") && this.selectedStatus !== PendStatus.Resolved && this.selectedStatus !== PendStatus.Closed) {
      if (this.pendId || this.chasePendIds.length > 0) {
        this.getData(this.pendId);

      }
    }
  }

  get isClinicalPend(): boolean {
    return this.pendCode === "PC300"
      || this.pendCode === "PC302" || this.pendCode === "PC303";
  }

  get isOwnerVisible(): boolean {
    return ! (this.pendCode === "PC304" || this.pendCode === "PC301");
  }

  getFormLoad() {

    this.getPendReasonDropdown();

    this.user = this.authService.user;
    const organizationName = this.user.organizationName;
    this.route.paramMap.subscribe(params => {
      this.pendId = +params.get("pendGd");

      if (this.pendId > 0) {
        this.chasePendIds = [];
        this.chasePendIds.push(this.pendId);
      }
    });
    this.getPendCompaniesDropdown();
    this.getPendStatusDropdown();

    this.pendInvoice = new Textbox({
      key: "pendInvoice",
      label: "Invoice Number",
      validators: [Validators.required],
    });

    this.pendAmount = new Textbox({
      key: "pendAmount",
      label: "Amount",
      validators: [Validators.required],
    });

    this.pendOwner = [
      new SelectableInput({ text: organizationName, value: "O" }),
      new SelectableInput({ text: "Client", value: "C" }),
    ];

    this.pendNotes = new Textarea({
      key: "pendNotes",
      label: "Notes",
      placeholder: "Long form text...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });

    this.pendSeverityInput = new Dropdown({
      key: "pendSeverityId",
      label: "Severity",
      placeholder: "Select...",
      disabled: !this.isClinicalPend,
      hidden: !this.isClinicalPend,
      validators: [Validators.required],
      errorMessages: {
        required: "Severity is required.",
      },
      appendTo: "body",
    });
    this.pendCompanyInput = new Dropdown({
      key: "company",
      label: "Company",
      placeholder: "Select",
      validators: [Validators.required],
      errorMessages: {
        required: "Company is required.",
      },
      appendTo: "body",
    });
    this.pendOwnerInput = new Dropdown({
      key: "owner",
      label: "Owner",
      options: this.pendOwner,
      placeholder: "Select Owner",
      appendTo: "body",
    });
    this.pendUserInput = new Dropdown({
      key: "assignedUser",
      label: "Assigned To",
      placeholder: "Select User",
      validators: [Validators.required],
      errorMessages: {
        required: "User is required.",
      },
      appendTo: "body",
    });
    this.pendStatusInput = new Dropdown({
      key: "status",
      label: "Status",
      placeholder: "Select Status",
      validators: [Validators.required],
      errorMessages: {
        required: "Status is required.",
      },
      appendTo: "body",
    });
    this.pendReasonInput = new Dropdown({
      key: "reason",
      label: "Reason",
      placeholder: "Select from List",
      appendTo: "body",
    });


    this.formGroup = this.hasPendCode ?
      this.formService.createFormGroup([this.pendInvoice, this.pendAmount, this.pendNotes, this.pendCompanyInput,
                                        this.pendOwnerInput, this.pendReasonInput, this.pendUserInput, this.pendStatusInput]) :
      this.formService.createFormGroup([this.pendNotes, this.pendSeverityInput, this.pendCompanyInput,
                                        this.pendOwnerInput, this.pendReasonInput, this.pendUserInput, this.pendStatusInput]);

    this.checkReasonPendCodes();
  }

  get hasPendCode(): boolean {
    switch (this.pendCode) {
      case "PC104":
      case "PC117":
      case "PC120":
      case "PC200":
      case "PC201":
      case "PC501":
      case "PC502":
      case "PC505":
        return true;
      default:
        return false;
    }
  }

  visibleChange($event) {
    this.showStatusError = false;
    this.showAssignedUserError = false;
    this.showNoteError = false;
    this.showAmountError = false;
    this.showInvoiceError = false;
    this.showCompanyError = false;
    this.formGroup.reset();
    this.isUpdatePendVisible = $event;
    this.formClose.emit($event);
  }

  onSelectPendInvoice(event) {
    if (event) {
      this.showInvoiceError = false;
      this.pendInvoice.value = event;
    } else {
      this.showInvoiceError = true;
    }
  }

  onSelectPendAmount(event) {
    if (event) {
      this.showAmountError = false;
      this.pendAmount.value = event;
    } else {
      this.showAmountError = true;
    }
  }

  onSelectPendCompany(event) {
    if (event) {
      this.showCompanyError = false;
      this.formGroup.get(this.pendCompanyInput.key).setValue(event.value);
    } else {
      this.showCompanyError = true;
    }
    this.changeDetector.markForCheck();
  }

  onSelectPendOwner(event) {
    if (event) {
      this.formGroup.get(this.pendOwnerInput.key).setValue(event.value);
      if (this.pendStatusId === "New" || this.pendStatusId === "In Progress") {
        this.getUsersDropdown(this.selectedPendOwner);
      }
      this.getPendStatusDropdown();
      this.isPendOwnerChange = true;
    }
  }

  onSelectPendUser(event) {
    if (event) {
      this.showAssignedUserError = false;
      this.formGroup.get(this.pendUserInput.key).setValue(event.value);
      this.user = event;
    } else {
      this.showAssignedUserError = true;
    }
  }

  onSelectPendReason(event): void {
    if (event) {
      this.formGroup.get(this.pendReasonInput.key).setValue(event.value);
    }
  }

  onSelectPendStatus(event) {
    if (event) {
      this.showStatusError = false;
      this.formGroup.get(this.pendStatusInput.key).setValue(event.value);
      this.isPendCloseVisible = this.selectedStatus === PendStatus.Closed;
      if (!this.isPendOwnerChange && !(this.pendCode === "PC301" || this.pendCode === "PC304")) {
        this.isPendStatusChangeVisible = true;
        this.headerLabel = "Are you sure you don't want to change ownership of this Pend?";
      }
    } else {
      this.showStatusError = true;
    }
  }

  onSelectPendNotes(event) {
    if (this.formGroup.invalid) {
      this.formService.markAllAsTouched(this.formGroup);
    }

    this.checkReasonPendCodes();
  }

  // Get Companies dropdown list
  getPendCompaniesDropdown() {

    this.service.getPendCompaniesDropdown()
      .subscribe((data: any[]): void => {
        this.pendCompany = data.map(item => ({ text: item.value, value: item.key, label: item.value }));
        this.pendCompanyInput = new Dropdown({ ...this.pendCompanyInput, options: [...this.pendCompany] } as any);
        this.changeDetector.markForCheck();
      });

  }

  // Get Users dropdown list
  getUsersDropdown(ownedBy) {
    const roleType = this.isClinicalPend ? "C" : "N";
    this.service.getAssignedToUsersList(ownedBy, this.pendId, roleType)
      .subscribe((data: any[]): void => {
        const users = [];
        for (const item of data) {
          users.push({ text: item.loginName, value: item.userID, label: item.loginName });
        }
        this.assignedUsers = users;
        this.pendUserInput = new Dropdown({ ...this.pendUserInput, options: [...this.assignedUsers] } as any);
        this.changeDetector.markForCheck();
      });
  }

  getPendReasonDropdown() {


    if (this.pendCode === PendType[PendType.PC100] || this.pendCode === PendType[PendType.PC107]) {

      this.isPendReasonVisible = true;

      this.createPendService.getPendReasons(PendType[this.pendCode])
        .subscribe((data: any[]): void => {
          const reasons = [];
          for (const item of data) {
            reasons.push({ text: item.text, value: Number(item.value), label: item.text });
          }
          this.pendReasons = reasons;
          this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, options: [...this.pendReasons] } as any);
          this.changeDetector.markForCheck();
        });
    }

  }

  // Get PendStatus dropdown list
  getPendStatusDropdown() {
    const userDirectoryRoleIds = this.authService.user.directoryRoleIds;
    const directoryRolesToCheck = [
      DirectoryUserRole.ThirdPartyManager,
      DirectoryUserRole.EMRManager,
      DirectoryUserRole.FieldTechManager,
      DirectoryUserRole.ClientOverreadManager,
      DirectoryUserRole.ProjectManager,
      DirectoryUserRole.CallCenterManager,
      DirectoryUserRole.ReportingManager,
    ];
    const isReturnAllPendStatus = userDirectoryRoleIds.some(role => directoryRolesToCheck.includes(role));

    this.service.getPendStatusDropdown(this.isClinical, this.user.organizationId, this.isClientOnly, isReturnAllPendStatus)
      .subscribe((data: any[]): void => {
        let pendStatus = [];
        let pendSeverity = [];
        const status = "pendStatus";
        const severity = "pendSeverity";
        pendStatus = data[status];
        pendSeverity = data[severity];
        const severityInputs = pendSeverity.map(this.automapper.curry("PendSeverity", "SelectableInput"));
        this.pendSeverityInput = new Dropdown({ ...this.pendSeverityInput, options: severityInputs } as any);
        this.pendStatus = pendStatus.map(item => ({ text: item.description, value: item.pendStatusId, disabled: false, extra: [], label: item.description }));
        if (this.isClientUser) {
          if (this.pendStatusId === "Request to Resolve" || this.pendStatusId === "Request to Close") {
            this.pendStatus.push();
          }
        } else {
          if (this.pendStatusId === "Request to Resolve" || this.pendStatusId === "Request to Close") {
            this.pendStatus.splice(0, 1);
          }
          if (isReturnAllPendStatus) {
            const indexOfRequestToResolve = this.pendStatus.map(option => option.text).indexOf("Request to Resolve");
            if (this.selectedPendOwner !== "C") {
              this.pendStatus.splice(indexOfRequestToResolve, 1);
              const indexOfRequestToClose = this.pendStatus.map(option => option.text).indexOf("Request to Close");
              this.pendStatus.splice(indexOfRequestToClose, 1);
            }
            this.pendStatus.splice(indexOfRequestToResolve, 1);
          }

          this.pendStatus.shift();
          if (this.isClinicalPend) {
            this.pendStatus.splice(-1, 1);
          }
        }
        this.pendStatusInput = new Dropdown({ ...this.pendStatusInput, options: [...this.pendStatus] } as any);
        this.changeDetector.markForCheck();
      });
  }

  getData(pendId) {
    this.service.getPendData(pendId).subscribe(result => {
      this.updatePend = result;
      this.formGroup.get(this.pendCompanyInput.key).setValue(this.updatePend.pendCompanyId);
      this.formGroup.get(this.pendStatusInput.key).setValue(this.updatePend.pendStatusId);
      this.formGroup.get(this.pendUserInput.key).setValue(this.updatePend.assignedToUserId);
      if (NumberHelper.isGreaterThan(this.updatePend.pendReasonId, 0)) {
        this.formGroup.get(this.pendReasonInput.key).setValue(this.updatePend.pendReasonId);
      }
      this.owner = this.updatePend.owner.substr(0, 1);
      this.ownedBy = this.updatePend.ownedBy.substr(0, 1);
      this.formGroup.get(this.pendOwnerInput.key).setValue(this.updatePend.owner.substr(0, 1));

      this.formGroup.patchValue({
        pendAmount: this.updatePend.amount,
        pendInvoice: this.updatePend.invoiceNumber,
        pendNotes: this.updatePend.notes,
        pendOwner: this.updatePend.owner.substr(0, 1),
        pendSeverityId: this.updatePend.pendSeverityId,
      });

      this.getUsersDropdown(this.selectedPendOwner);
      this.changeDetector.markForCheck();

    });
  }

  getPendValidated() {
    if (this.updatePend.pendCompanyId === 0) {
      this.showCompanyError = true;
      this.checkError = true;
    }

    if (!NumberHelper.isAvailable(this.updatePend.amount) || this.updatePend.amount === 0) {
      this.checkError = true;
      this.showAmountError = true;
    }

    if (!StringHelper.isAvailable(this.updatePend.invoiceNumber) || this.updatePend.invoiceNumber === "") {
      this.checkError = true;
      this.showInvoiceError = true;
    }
  }

  confirmUpdatePendView(): void {
    switch (this.selectedStatus) {
      case PendStatus.Resolved:
        this.isResolveConfirmationModalVisible = true;
        break;
      case PendStatus.Closed:
        this.isCancelConfirmationModalVisible = true;
        break;
      default:
        this.updatePendView();
        break;
    }
  }

  updatePendView() {
    this.isPendStatusChangeVisible = false;

    this.updatePend = new UpdatePend({
      chasePendId: null,
      chasePendIds: this.chasePendIds,
      assignedToUserId: this.selectedUser ? this.selectedUser : 0,
      pendReasonId: this.selectedPendReasonId ? this.selectedPendReasonId : 0,
      notes: this.formGroup.value.pendNotes,
      ownedBy: this.selectedPendOwner ? this.selectedPendOwner : "O",
      pendStatusId: this.selectedStatus ? this.selectedStatus : 0,
      chaseId: Number(this.chaseId),
      owner: this.selectedPendOwner ? this.selectedPendOwner : "O",
      amount: +this.formGroup.value.pendAmount,
      pendCompanyId: this.selectedPendCompany || null,
      invoiceNumber: this.formGroup.value.pendInvoice || null,
      pendSeverityId: this.formGroup.value.pendSeverityId,
    });

    // validate all fields before updating pend.
    this.checkError = false;
    if (this.updatePend.pendStatusId === 0) {
      this.showStatusError = true;
      this.checkError = true;
    }

    if ((this.updatePend.notes.trim() === "" || this.updatePend.notes.trim() === null) && !this.checkError
      && (this.pendCode !== PendType[PendType.PC100] && this.pendCode !== PendType[PendType.PC107])) {
      this.showNoteError = true;
      this.checkError = true;
    }

    if (this.hasPendCode) {
      this.getPendValidated();
    }
    this.validateNotes();
    if (this.formGroup.invalid) {
      this.checkError = true;
    }

    if (this.checkError) {
      this.formService.markAllAsTouched(this.formGroup);
      return;
    }

    this.service.update(this.updatePend).subscribe(data => {
      this.isPendStatusChangeVisible = false;
      this.showAmountError = false;
      this.showInvoiceError = false;
      this.showStatusError = false;
      this.showAssignedUserError = false;
      this.showNoteError = false;
      this.showCompanyError = false;

      if (data > 0) {
        this.serviceSet.resetFunction(this.pendId);
        this.serviceHeaderSet.resetFunction(this.pendId);
        this.messagingService.showToast("Pend Updated successfully", SeverityType.SUCCESS);
      } else {
        this.messagingService.showToast("Pend updation Failed", SeverityType.WARN);
      }
      this.changeDetector.markForCheck();
    });
    this.formGroup.reset();
    this.visibleChange(false);

  }
  validateNotes() {
    const notes = this.formGroup.value?.pendNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.pendNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.pendNotes.key).setErrors(null);
    }
  }

  confirmStatusChange() {
    this.isPendStatusChangeVisible = true;
  }

  closePopup() {
    this.isPendStatusChangeVisible = false;
  }

  // TODO: temporary until Dynamic Forms handles error display
  private DisplayErrors() {
    let ErrorMessages = "Invalid Entries: \r";

    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        const control = this.formGroup.controls[key];
        if (control.invalid) {
          const modelError = this.modelErrors.filter(obj => {
            return obj.key === key;
          });
          if (modelError) {
            ErrorMessages += modelError[0].message + this.newLine;
          }
        }
      }
    }
    this.noteErrorTitle = ErrorMessages;
  }

  protected markAllAsTouched(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  get isClientUser(): boolean {
    return this.authService.user.directoryRoleIds.indexOf(31) > -1;
  }


  private checkReasonPendCodes(): void {

    if (this.pendCode === PendType[PendType.PC100] || this.pendCode === PendType[PendType.PC107]) {
      this.isPendReasonVisible = true;
      this.formGroup.get(this.pendNotes.key).setValidators([Validators.minLength(4), Validators.maxLength(1000)]);
      this.formService.markAllAsTouched(this.formGroup);
      this.changeDetector.markForCheck();
    } else {
      this.isPendReasonVisible = false;
      this.formGroup.get(this.pendNotes.key).setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(1000)]);
      if (this.formGroup.invalid) {
        this.formService.markAllAsTouched(this.formGroup);
      }

      this.changeDetector.markForCheck();
    }


  }

  get selectedStatus(): number {
    return Number(this.formGroup.get(this.pendStatusInput.key).value);
  }

  get selectedPendReasonId(): number {
    return Number(this.formGroup.get(this.pendReasonInput.key).value);
  }

  get selectedUser(): number {
    return Number(this.formGroup.get(this.pendUserInput.key).value);
  }

  get selectedPendOwner(): string {
    return this.formGroup.get(this.pendOwnerInput.key).value;
  }

  get selectedPendCompany(): number {
    return Number(this.formGroup.get(this.pendCompanyInput.key).value);
  }

  cancelConfirmation($event): void {
    this.isCancelConfirmationModalVisible = false;
    this.isResolveConfirmationModalVisible = false;
  }
}
