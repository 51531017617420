import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-data-load-error-report",
  templateUrl: "./data-load-error-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLoadErrorReportComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.DATA_LOAD_ERROR_REPORT;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [
      HideFilters.PROJECT_ID,
      HideFilters.USER_ID,
      HideFilters.ORG_ID,
      "Object_Name"];
  }

}
