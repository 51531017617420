
export class BulkOutReachQueryResult {
  readonly projectCount: number;
  readonly addressCount: number;
  readonly chaseCount: number;

  constructor(options: {
    projectCount: number;
    addressCount: number;
    chaseCount: number;
  }) {
    this.projectCount = options.projectCount;
    this.addressCount = options.addressCount;
    this.chaseCount = options.chaseCount;
  }
}
