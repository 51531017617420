import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { ChartService } from "../../chart.service";
import { Encounter } from "../encounter/encounter.model";
import { RiskEntity } from "../risk-entity.model";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-delete-encounter",
  templateUrl: "./delete-encounter.component.html",
  styleUrls: ["./delete-encounter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteEncounterComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @Input() encounter: Encounter;
  private encounterToDeleteIndex: number;
  private encounterToDelete: RiskEntity;
  enabled: boolean;
  isConfirmDeleteVisible = false;

  constructor(
    private riskService: RiskService,
    private chartService: ChartService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.riskService.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        this.enabled = state.isEnabled;

        if (state.hasSelectedEncounterIndex) {
          this.encounterToDeleteIndex = state.encounters.findIndex(encounterItem => encounterItem.id === this.encounter.id);
          this.encounterToDelete = state.encounters[this.encounterToDeleteIndex];
        }

        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  get isAdminEncounter(): boolean {
    if (this.encounter == null) {
      return false;
    }

    return this.encounter.isAdmin;
  }

  get hasDiagnoses(): boolean {
    return ArrayHelper.isAvailable(this.encounterToDelete.diagnoses);
  }


  deleteEncounter(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.hasDiagnoses) {
      this.isConfirmDeleteVisible = true;
      this.riskService.setSelectedEncounterIndex(this.encounterToDeleteIndex);
    } else if (this.enabled && !this.isAdminEncounter) {
      const encounterHasBeenSaved = NumberHelper.isGreaterThan(this.encounterToDelete.entityId, 0);
      if (encounterHasBeenSaved) {
        this.deleteEncounterFromServer();
      } else {
        this.deleteEncounterFromState();
      }
    }
  }

  deleteEncounterFromServer(): void {
    const attributes = this.encounterToDelete.attributes;
    const processChildEntity = this.hasDiagnoses ? 1 : 0;

    this.chartService.deleteEncounter(attributes, processChildEntity).subscribe(
      () => this.deleteEncounterFromState(),
      error => this.messagingService.showMessage("Try to delete the encounter again.", SeverityType.ERROR)
    );
  }

  private deleteEncounterFromState(): void {
    this.riskService.deleteEncounter(this.encounterToDeleteIndex);
  }
}
