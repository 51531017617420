import { IAutoMapper } from "../interfaces";

export const mapLookupModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "LookupModel")
    .forMember("id", o => o.id)
    .forMember("name", o => o.name)
    .forMember("description", o => o.description)
    .forMember("projectTypeId", o => o.projectTypeId)
    .forMember("projectStatus", o => o.projectStatus)
    .forMember("projectTypeName", o => o.projectTypeName)
    .forMember("clientId", o => o.clientId)
    .forMember("clientName", o => o.clientName)
    .forMember("projectRetrivalTypeId", o => o.projectRetrivalTypeId);
};
