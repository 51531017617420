import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DownloaderService } from "../../core/downloader/downloader.service";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { ProviderService } from "../../platform/api/provider/provider.service";
import { ContactMethodType } from "../../platform/modules/retrieval/cover-letter-template/contact-method-type.enum";
import { RetrievalContactHistory } from "../../platform/modules/retrieval/retrieval-detail-contact-history.model";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-download-provider-packet",
  templateUrl: "./download-provider-packet.component.html",
  styleUrls: ["./download-provider-packet.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadProviderPacketComponent {
  @Input() providerPacketData: RetrievalContactHistory;

  constructor(
    private providerService: ProviderService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private downloaderService: DownloaderService
  ) { }

  get hasProviderPacket(): boolean {
    return ((
      this.contactMethodType === ContactMethodType.Fax ||
      this.contactMethodType === ContactMethodType.Email ||
      this.contactMethodType === ContactMethodType.Mail ||
      this.contactMethodType === ContactMethodType.ProviderAgingEmail ||
      this.contactMethodType === ContactMethodType.ProviderAgingFax ||
      this.contactMethodType === ContactMethodType.AutomatedOutreachFax ||
      this.contactMethodType === ContactMethodType.AutomatedOutreachEmail)
      && NumberHelper.isAvailable(this.providerPacketData.providerPacketId)
      && StringHelper.isAvailable(this.providerPacketData.providerPacketTemplate));
  }

  get providerPacketTemplate(): string {
    return this.providerPacketData.providerPacketTemplate;
  }

  get iconName(): string {
    return "download";
  }

  get contactMethodType(): number {
    return this.providerPacketData.contactMethodTypeId;
  }

  async downloadProviderPacket(): Promise<void> {
    const packet = await this.providerService.downloadProviderPacket(this.providerPacketData.providerPacketId, this.providerPacketData.contactMethodType).toPromise();

    if (StringHelper.isAvailable(packet)) {
      this.downloaderService.fromRawUrl(packet);
      this.changeDetector.markForCheck();
    } else {
      this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
    }
  }
}
