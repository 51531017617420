import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, retry } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../../shared/list/list-item";

@Injectable()
export class MemberService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  // Get data set for Member Statistics.
  getStatisticsData(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}Member/MemberStatistics`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  // Get data set for Member Compliance.
  getMemberCompliance(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Member/MemberCompliance`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  // Get data set for Exempted Members.
  getExemptedMembers(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Member/ExemptedMembers`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }
}
