import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-retrieval-summary-dw",
  templateUrl: "./retrieval-summary-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalSummaryDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.RETRIEVAL_SUMMARY_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_NAME2,
                           HideFilters.CUSTOMER_NAME, HideFilters.PROJECT_TYPE_ID2, HideFilters.TEMPLATE_ID2];
}
