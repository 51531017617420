import { StringHelper } from "../../../utilities/contracts/string-helper";

export class AccessInfo {
  accessAdminContact: string;
  accessAdminPhone: string;
  accessITContact: string;
  accessITPhone: string;
  accessEmrSystem: string;
  accessEditNotes: string;
  url: string;
  credentialStatus: string;
  twoFactorAuthorization: string;
  userName: string;
  password: string;
  expirationDate: string;

  constructor(options: Partial<AccessInfo> = {}) {
    this.accessAdminContact = StringHelper.clean(options.accessAdminContact);
    this.accessAdminPhone = StringHelper.clean(options.accessAdminPhone);
    this.accessITContact = StringHelper.clean(options.accessITContact);
    this.accessITPhone = StringHelper.clean(options.accessITPhone);
    this.accessEmrSystem = StringHelper.clean(options.accessEmrSystem);
    this.accessEditNotes = StringHelper.clean(options.accessEditNotes);
    this.url = StringHelper.clean(options.url);
    this.credentialStatus = StringHelper.clean(options.credentialStatus);
    this.twoFactorAuthorization = options.twoFactorAuthorization;
    this.userName = StringHelper.clean(options.userName);
    this.password = StringHelper.clean(options.password);
    this.expirationDate = options.expirationDate;
  }
}
