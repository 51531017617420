<div>
  <form-factory [formGroup]="form"
                [model]="auditReadyInput"
                (onChange)="auditReadyStatusChange($event)"></form-factory>
</div>

<app-confirmation-modal [(visible)]="showConfirmationForAutoDeleteAuditEntry"
                        header="ATTENTION"
                        (onYes)="onChangeStatusConfirm()"
                        (onCancel)="onCancelStatusChange()"
                        acceptText="CONTINUE"
                        rejectText="CANCEL">
  <p class="audit-item-readiness__helper-text">
    Please Note: Audit Packet contains this chase.
    The Selected page(s) will be removed from the audit packet.
  </p>
</app-confirmation-modal>
