import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BASE_API_URL } from "../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class DownloaderService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient

  ) { }

  fromPath(path: string) {
    // path: api/file/download?fileType=..&fileId=...
    this.http.get(
      `${this.baseApiUrl}${path}`,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  fromRawUrl(url: string) {
    // url: https://dev.api.healthdatavision.com/api/file/download?fileType=..&fileId=...
     this.http.get(
      url,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }


}
