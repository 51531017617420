import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class PendListItem {
  readonly chasePendId: number;
  readonly pendCode: string;
  readonly pendTypeDescription: string;
  readonly owner: string;
  readonly assignedToUser: string;
  readonly pendStatus: string;
  readonly chaseId?: number;
  readonly masterDocumentSourceId?: number;
  readonly lastUpdatedDate?: string;
  readonly project?: number;
  readonly isClinical: string;
  readonly pendTypeShortDescription: string;
  readonly projectName?: string;
  readonly createdDate?: string;
  readonly measureCode?: string;
  readonly coRetrievalOwner?: string;
  readonly pendStatusId?: number;
  readonly isThirdParty?: boolean;

  constructor(options: {
    chasePendId: number;
    pendCode: string;
    pendTypeDescription: string;
    owner: string;
    assignedToUser: string;
    pendStatus: string;
    chaseId?: number;
    masterDocumentSourceId?: number;
    lastUpdatedDate?: string;
    project?: number;
    isClinical: string;
    pendTypeShortDescription: string;
    projectName?: string;
    createdDate?: string;
    measureCode?: string;
    coRetrievalOwner?: string;
    pendStatusId?: number;
    isThirdParty?: boolean;
  }) {
    this.chasePendId = options.chasePendId;
    this.pendCode = StringHelper.clean(options.pendCode);
    this.pendTypeDescription = StringHelper.clean(options.pendTypeDescription);
    this.owner = StringHelper.clean(options.owner);
    this.assignedToUser = StringHelper.clean(options.assignedToUser);
    this.pendStatus = StringHelper.clean(options.pendStatus);
    this.chaseId = options.chaseId;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.lastUpdatedDate = StringHelper.clean(options.lastUpdatedDate);
    this.project = options.project;
    this.isClinical = StringHelper.clean(options.isClinical);
    this.pendTypeShortDescription = StringHelper.clean(options.pendTypeShortDescription);
    this.projectName = StringHelper.clean(options.projectName);
    this.createdDate = StringHelper.clean(options.createdDate);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
    this.pendStatusId = options.pendStatusId;
    this.isThirdParty = options.isThirdParty;

  }
}
