import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-provider-gateway-activity",
  templateUrl: "./provider-gateway-activity.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderGatewayActivityComponent {
  filters: string[] = ["Address", "DocumentQueueId", "DocumentStatus", "ConfirmationNumber", "ProviderGatewayPin"];
  dashboardType = LookerRiskDashboardType.PROVIDER_GATEWAY_ACTIVITY;
  hideFilters: string[] = [HideFilters.ORG_ID, HideFilters.ORGANIZATION_NAME, HideFilters.DOCUMENT_ID, HideFilters.DOCUMENT_STATE,
                           HideFilters.AID, HideFilters.AID_GROUP, HideFilters.CONFIRMATION_NUMBER, HideFilters.PROVIDER_GATEWAY_PIN];
}
