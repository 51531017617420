import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class BulkUpdate {
  readonly clientId?: number;
  readonly status: string;
  readonly assignedToUserId?: number;
  readonly chaseIds: string[];
  readonly notes?: string;
  readonly bulkType?: number;
  readonly bulkAction?: number;
  readonly owner?: string;
  readonly action?: number;
  readonly chaseRequestUpdate?: number;
  readonly pendType?: string;
  amount?: number | null;
  pendCompanyId?: number;
  invoiceNumber?: string | number;
  pendReasonId?: number;
  pendSeverityId?: number;
  isThirdParty?: boolean;
  readonly projectId?: number;
  dueDate?: string;
  requestedDate?: string;
  commitmentDate?: string;
  originalChaseIdCsv?: string;
  copyOverChaseIdCsv?: string;
  constructor(options: {
    clientId?: number;
    status: string;
    assignedToUserId?: number;
    chaseIds: string[];
    notes?: string;
    bulkType?: number;
    bulkAction?: number;
    owner?: string;
    action?: number;
    chaseRequestUpdate?: number;
    pendType?: string;
    amount?: number;
    pendCompanyId?: number;
    invoiceNumber?: string | number;
    pendReasonId?: number;
    pendSeverityId?: number;
    isThirdParty?: boolean;
    projectId?: number;
    dueDate?: string;
    requestedDate?: string;
    commitmentDate?: string;
    originalChaseIdCsv?: string;
    copyOverChaseIdCsv?: string;
  }) {
    this.clientId = options.clientId;
    this.status = options.status;
    this.assignedToUserId = options.assignedToUserId;
    this.chaseIds = options.chaseIds;
    this.notes = StringHelper.clean(options.notes);
    this.bulkType = options.bulkType;
    this.bulkAction = options.bulkAction;
    this.owner = options.owner;
    this.action = options.action;
    this.pendType = StringHelper.clean(options.pendType, null);
    const amountNumber = Number(options.amount);
    this.amount = NumberHelper.isGreaterThan(amountNumber, 0) ? amountNumber : null;
    this.pendCompanyId = options.pendCompanyId;
    this.invoiceNumber = options.invoiceNumber;
    this.pendReasonId = options.pendReasonId;
    this.pendSeverityId = options.pendSeverityId;
    this.isThirdParty = options.isThirdParty;
    this.projectId = NumberHelper.isGreaterThan(options.projectId, 0) ? options.projectId : null;
    this.dueDate = options.dueDate;
    this.requestedDate = options.requestedDate;
    this.chaseRequestUpdate = options.chaseRequestUpdate;
    this.commitmentDate = options.commitmentDate;
    this.copyOverChaseIdCsv = options.copyOverChaseIdCsv;
    this.originalChaseIdCsv = options.originalChaseIdCsv;
  }
}
