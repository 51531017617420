import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BasicGridComponent } from "../../../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { Diagnosis } from "../../risk/diagnosis/diagnosis.model";
import { RiskService } from "../../risk/risk.service";

@Component({
  selector: "member-rxcm-medical-procedure-grid",
  templateUrl: "./medical-procedure-grid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalProcedureGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild("diagnosesGrid", { static: true }) medicalProcedureGrid: BasicGridComponent;

  // TODO: Need to change Diagnosis model to more of a generic name since diagnosis is an entity type along with medical procedure.
  medicalProcedures: Diagnosis[];
  selection: Diagnosis;

  constructor(
    private readonly riskService: RiskService,
    private readonly route: ActivatedRoute,
    private readonly automapper: AutomapperService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.riskService.data.subscribe(state => {
        const diagnosisEntities = state.hasSelectedEncounterIndex ? state.selectedEncounter.diagnoses : [];
        this.medicalProcedures = this.automapper.mapMany("RxcmData", "Diagnosis", diagnosisEntities);
        this.selection = state.hasSelectedDiagnosisIndex ? this.medicalProcedures[state.selectedDiagnosisIndex] : {} as any;

        if (this.medicalProcedureGrid != null) {
          this.medicalProcedureGrid.setPageByIndex(state.selectedDiagnosisIndex);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasData(): boolean {
    return ArrayHelper.isAvailable(this.medicalProcedures);
  }

  get gridConfiguration(): GridConfiguration {
    const medicalProcedureColumns = [
      new GridColumnDefinition({ header: "Pg#", field: "pageNumber", width: "50px" }),
      new GridColumnDefinition({ header: "CODES", field: "code", width: "150px" }),
      ];

    return new GridConfiguration({
      columns: medicalProcedureColumns,
      pageSize: 5,
      showActionColumn: false,
      showMenu: false,
      selectionMode: "single",
      showSelectionControl: false,
    });
  }

  setSelectedDiagnosisIndex(): void {
    if (this.selection) {
      const selectedDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === this.selection.id);
      this.riskService.setData({ selectedDiagnosisIndex });
    }
  }
}
