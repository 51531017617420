<div *ngFor="let annotation of annotationsData; let i=index; trackBy: trackByIndex">
    <div class="hoverElement" [ngStyle]="{
        'height.px': annotation.yHeight,
        'left.px': annotation.xInit,
        'top.px': annotation.yInit,
        'width.px': annotation.xWidth}" 
        [ngClass]="{'highlight-border': activeHighlightsStatus[i] }"
        (click)="highlightAnnotation(annotation.data.highlightId)">

        <div class="menu">
            <div *ngIf="showAnnotationIcon(annotation.data.highlightId)" class="button__add--medical" (click)="addAnnotation(annotation.data)">
                <app-icon class="button__add-icon medical" iconName="fa-regular fa-notes-medical"></app-icon>
                <span class="button__add--tooltip" tooltipPosition="top">Annotate</span>
            </div>
            <div *ngIf="showCopyButton(annotation)" class="button__add--medical" (click)="copyText(annotation)">
                <app-icon class="button__add-icon medical" iconName="fa-regular fa-copy"></app-icon>
                <span class="button__add--tooltip" tooltipPosition="top">Copy</span>
            </div>
            <div *ngIf="!isReadOnly" class="button__add--eraser" (click)="deleteAnnotation({data: annotation.data, pageId: annotation.pageId })">
                <app-icon class="button__add-icon eraser" iconName="fa-regular fa-eraser"></app-icon>
                <span class="button__add--tooltip" tooltipPosition="top">Erase</span>
            </div>
        </div>
    </div>
</div>
<div>
    <div *ngFor="let nlp of nlpAnnotations; trackBy: trackByIndex">
        <div class="hoverElementNLP" [ngStyle]="{'top.px': nlp.yInit, 'left.px': nlp.xInit, 'height.px': nlp.yHeight, 'width.px': nlp.xWidth}" *ngIf="!isEditing">
            <div class="nlpHighlight" [style.backgroundColor]="nlp.colorCodeRGBA"></div>
        </div>
    </div>
</div>
