import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { FormService } from "../../form.service";
import { HighlightService } from "../../highlighter/highlight.service";
import { SaveGroup } from "./save-group.model";

@Component({
  selector: "form-save-group",
  templateUrl: "./save-group.component.html",
  styleUrls: [
    "../dynamic-group.scss",
    "../../highlighter/highlight.scss",
    "./save-group.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveGroupComponent extends DynamicControlDirective<SaveGroup> implements OnInit, OnDestroy {
  private unsubscribe = new Subject();


  constructor(
    readonly highlighter: HighlightService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());
    this.formService.updateDom
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());
    this.highlighter.onBlurGroup
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.save());
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  get classes(): any {
    return {
      ...this.highlighter.className(),
      "control-container__invlaid": this.hasError,
    };
  }

  save(): void {
    if (this.control.pending) {
      setTimeout(() => this.save(), 100);
    } else if (this.control.valid && this.hasAtLeastOneValue(this.control)) {
      const event = new DynamicFormEvent({
        key: this.model.key,
        type: "save",
        value: this.control.value,
        control: this.control,
        model: this.model,
      });
      this.onChange.emit(event);
    }
  }

  remove(): void {
    if (this.formService.isDisabled(this.model)) {
      return;
    }

    const controls = (this.control as FormGroup).controls as { [key: string]: FormControl };

    const event = new DynamicFormEvent({
      key: this.model.key,
      type: "clear",
      value: this.control.value,
      control: this.control,
      model: this.model,
      successFn: () => {
        for (const key in controls) {
          if (controls.hasOwnProperty(key)) {
            const control = controls[key];
            if (!ArrayHelper.isAvailable((control as any).controls)) {
              control.setValue(null);
            }
          }
        }

        this.model.controls.forEach(control => {
          if ((control as any).saveInfo) {
            (control as any).saveInfo.entityId = null;
          }
        });
      },
    });
    this.onChange.emit(event);
  }

  notify(): void {
    this.onChange.emit(new DynamicFormEvent({
      key: this.model.key,
      type: "notify",
      value: this.control.value,
      control: this.control,
      model: this.model,
    }));
  }

  handleChangeEvents(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.save();
        break;
      case "notify":
        this.notify();
        break;
      default:
        break;
    }
  }


  trackByIndex(index, item) {
    return index;
  }

  private hasAtLeastOneValue(control: AbstractControl): boolean {
    const keys = Object.keys(control.value);
    for (const key of keys) {
      const value = control.value[key];
      if (typeof value === "object" && value != null) {
        return this.hasAtLeastOneValue(value);
      } else if (value != null) {
        return true;
      }
    }

    return false;
  }

  private isCompleteRecursive(group: object): boolean {
    return Object.values(group).every(value => {
      const isAnObject = typeof value === "object" && value != null;
      return isAnObject ? this.isCompleteRecursive(value) : StringHelper.isAvailable((value || "").toString());
    });
  }

  get showHighlighterClass(): boolean {
    return this.model.isShowHighlighterClass;
  }

}
