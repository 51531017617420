import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { OnboardingRoutingModule } from "./onboarding-routing.module";
import { OnboardingComponent } from "./onboarding.component";

@NgModule({
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    SharedModule,
  ],
  declarations: [OnboardingComponent],

})
export class OnboardingModule { }
