import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { GridStateService } from "../../../../../../shared/grid/grid-state.service";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailService } from "../../address-detail.service";

@Component({
  selector: "retrieval-address-detail-info-grids",
  templateUrl: "./address-detail-info-grids.component.html",
  styleUrls: ["./address-detail-info-grids.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  menuItems = List<MenuItem>();
  documentSourceTypeId: number;
  ADDRESS_PEND_GRID = "AddressPendGrid";

  constructor(
    private router: Router,
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly addressDetailService: AddressDetailService,
    private readonly gridStateService: GridStateService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
        this.addressDetailStateService.state.subscribe(state => {
          this.documentSourceTypeId = state.documentSourceTypeId;
          this.addressDetailService
          .getGridMenuItems(state.isFieldTech, state.documentSourceTypeId)
        .subscribe(this.assignAndNotifyMenuItems);
      })
    );
    this.removePendFilterInSession();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.removePendFilterInSession();
  }

  private removePendFilterInSession() {
    this.gridStateService.delete(this.ADDRESS_PEND_GRID);
  }

  private assignAndNotifyMenuItems = (data: MenuItem[]): void => {
    this.menuItems = List(data);
    this.changeDetector.markForCheck();
  }

  get menuHeaderText(): string {
    switch (true) {
      case (this.router.url.includes("members")):
        return "MEMBER CHASES AT THIS ADDRESS";
      case (this.router.url.includes("pends")):
        return "PENDS AT THIS ADDRESS";
      case (this.router.url.includes("providers")):
        return "PROVIDERS AT THIS ADDRESS";
      case (this.router.url.includes("pursuits")):
        return "PURSUITS AT THIS ADDRESS";
      case (this.router.url.includes("invoices") && this.isThirdParty):
        return "INVOICES AT THIS ADDRESS";
      default:
        return "CHASES AT THIS ADDRESS";
    }
  }

  get isThirdParty(): boolean {
    return this.documentSourceTypeId === DocumentSourceType.THIRDPARTY;
  }
}
