<div class="header-container">
  <div class="top-left-container">
    <ng-content select="[top-left]"></ng-content>    
  </div>
  <div class="top-right-container">
    <ng-content select="[top-right]"></ng-content>    
  </div>
  <div class="clearfix"></div>
  <div class="bottom-right-container">
    <ng-content select="[bottom-right]"></ng-content>    
  </div>  
  <div class="bottom-left-container">
    <ng-content select="[bottom-left]"></ng-content>    
  </div>  
  <div class="clearfix"></div>
</div>
