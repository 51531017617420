import { IAutoMapper } from "../interfaces";

export const mapProviderPacketResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProviderPacketResponseItem")
    .forMember("url", o => o.url)
    .forMember("templateId", o => o.templateId)
    .forMember("startTime", o => o.startTime)
    .forMember("endTime", o => o.endTime)
    .forMember("status", o => o.status)
    .forMember("duration", o => o.duration);
};

