import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { List } from "immutable";
import { FormService } from "../../../dynamic-forms/form.service";
import { TextboxType } from "../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { ListItem } from "../list-item";

@Component({
  selector: "app-info-item-container",
  templateUrl: "./info-item.component.html",
  styleUrls: ["./info-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoItemComponent implements OnInit, OnChanges {
   @Input() infoItems = List<ListItem>();
   @Input() className: string;
   @Input() isSpecialHandling = false;
   @Output() enableSpecialHandling = new EventEmitter<boolean>();
   passwordForm: FormGroup;
   showPasswordChecked = false;
   passwordModel: Textbox;

   constructor(
     private readonly formService: FormService,
     private changeDetector: ChangeDetectorRef
  ) { }

   ngOnChanges(changes: SimpleChanges) {
    if (changes.infoItems) {
      if (changes.infoItems.isFirstChange()) { return; }

      if (changes.infoItems.currentValue !== changes.infoItems.previousValue
        && changes.infoItems.currentValue.find(x => x.key === "Password:") != undefined) {
          const oldpwd = changes.infoItems.previousValue.find(x => x.key === "Password:").value;
          const newpwd = changes.infoItems.currentValue.find(x => x.key === "Password:").value;
          if (oldpwd !== newpwd && ArrayHelper.isAvailable(this.infoItems)) {
            this.setPasswordModel(false);
          }
      }
    }
  }

   ngOnInit() {
    this.passwordModel = new Textbox({
      key: "password",
      label: "Password",
      type: TextboxType.PASSWORD,
      readonly: true,
    });
    this.passwordForm = this.formService.createFormGroup([this.passwordModel]);

    if (this.infoItems.find(x => x.key === "Password:") != undefined) {
      this.passwordForm.get("password").setValue(this.infoItems.find(x => x.key === "Password:").value);
      this.setPasswordModel(false);
    }
  }

   setPasswordModel(show: boolean): void {
    this.passwordForm.get("password").setValue(this.infoItems.find(x => x.key === "Password:").value);
    this.passwordModel.type = show ? TextboxType.TEXT : TextboxType.PASSWORD;
    this.formService.updateDom.next();
    this.changeDetector.markForCheck();
   }

   getClass(item: ListItem): string {
     return item.value === this.className ? "activeItem" : "";
  }

   itemClass(item: ListItem): string {
    let addressClass = item.key === "Password:" ?  "password-detail" : "address-detail";
    addressClass += item.key === "Address:" ? " special-handling-address" : "";
    return addressClass;
    }

   trackByIndex(index, item) {
    return index;
  }

   specialHandling(value: boolean): void {
    this.enableSpecialHandling.emit(value);
  }

   changeShowPassword(): void {
     this.setPasswordModel(this.showPasswordChecked);
   }
}
