import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MenuModule as PrimeMenuModule } from "primeng/menu";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { SharedModule } from "../../shared/shared.module";
import { AnnotationCardComponent } from "./annotations/annotation-card/annotation-card.component";
import { AnnotationsComponent } from "./annotations/annotations.component";
import { UserChatComponent } from "./user-chat/user-chat.component";
import { WidgetComponent } from "./widget.component";

@NgModule({
  declarations: [
    AnnotationsComponent,
    AnnotationCardComponent,
    UserChatComponent,
    WidgetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PrimeMenuModule,
    DynamicFormsModule,
    SharedModule,
  ],
  exports: [
    WidgetComponent,
  ],
})

export class WidgetModule { }
