export class LabelModel {
  readonly labels: string[];

  constructor(options: {
    labels: string[];
  }) {
    this.labels = options.labels;
  }
}

