import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { Encounter } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskService } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk.service";
import { DatesOfServiceValidation } from "./dates-of-service-validation.model";

@Component({
  selector: "app-dates-of-service",
  templateUrl: "./dates-of-service.component.html",
  styleUrls: ["./dates-of-service.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatesOfServiceComponent implements OnInit {
  @Input() chaseId: number;
  @Input() showToast = false;
  @Output() onSubmit = new EventEmitter<Encounter[]>(true);
  datesOfServiceForm: FormGroup;
  form: FormGroup;
  encounters: Encounter[] = [];
  status: string = null;

  constructor(
    private riskService: RiskService,
    private readonly messagingService: MessagingService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadEncounterValidationData();
  }

  loadEncounterValidationData(): void {
    this.datesOfServiceForm = this.fb.group({
      dateOfServiceValidationEntity: this.fb.array([]),
    });

    this.riskService.getEncounters(this.chaseId).subscribe(items => {
      this.encounters = items;
      this.encounters.forEach(data => {
        this.buildListOfDatesOfServiceValidationEntity(this.datesOfServiceForm.get("dateOfServiceValidationEntity") as FormArray, data);
      });
      this.onSubmit.emit(this.encounters);
      this.changeDetector.markForCheck();
    });
  }


  buildListOfDatesOfServiceValidationEntity(formArray, data: Encounter) {
    formArray.push(
      new FormGroup({
        providerName: new FormControl(data.provider),
        datesOfServiceRange: new FormControl(data.dosRange),
        encounterFound: new FormControl(data.enc),
        providerSignaturePresent: new FormControl(data.sig),
        chartPageNumber: new FormControl(data.chartPageNumber),
      })
    );
  }

  get dosValidatonControls() {
    return (this.datesOfServiceForm.get("dateOfServiceValidationEntity") as FormArray).controls;
  }

  handleChangeEncounterFound(encFound: boolean, index: number): void {
    this.encounters[index].enc = encFound;
    if (!encFound) {
      this.encounters[index].sig = null;
    }
    this.save(index);
  }

  handleChangeProviderSignaturePresent(event: boolean, index: number): void {
    this.encounters[index].sig = event;
    this.save(index);
  }

  handleChangeChartPageNumber(index: number): void {
    this.encounters[index].chartPageNumber = this.chartPageNumbers.at(index).get("chartPageNumber").value;
    this.save(index);
  }

  save(index: number): void {
    const chartPageNumberValidation = new DatesOfServiceValidation({
      chaseId: Number(this.chaseId),
      entityId: this.encounters[index].entityId,
    });
    chartPageNumberValidation.encounterFound = this.encounters[index].enc;
    chartPageNumberValidation.providerSignaturePresent = this.encounters[index].sig;
    chartPageNumberValidation.chartPageNumber = this.encounters[index].chartPageNumber;

    this.onSubmit.emit(this.encounters);
    this.riskService.submitEncounterValidation(chartPageNumberValidation, this.status).subscribe({
      next: () => {
        this.datesOfServiceForm.get("dateOfServiceValidationEntity").reset();
        this.loadEncounterValidationData();
        if (this.showToast) {
          this.messagingService.showToast("Dates of Services Validation Saved", SeverityType.SUCCESS);
        }
      },
      error: () => {
        this.messagingService.showToast("Dates of Services Validation Not Saved", SeverityType.ERROR);
      },
    });
  }

  get chartPageNumbers(): FormArray {
    return this.datesOfServiceForm.get("dateOfServiceValidationEntity") as FormArray;
  }

  trackByIndex(index, item) {
    return index;
  }
}
