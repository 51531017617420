import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ProjectType } from "../../project/project-type.enum";

export class AnalyticsItemRequest {
  analyticsType?: string;
  analyticsCategory?: string;
  projectType?: ProjectType;
  reportUrl?: string;

  constructor(options: {
    analyticsType?: string;
    analyticsCategory?: string;
    projectType?: ProjectType;
    reportUrl?: string;

  }) {
    this.analyticsType = StringHelper.clean(options.analyticsType);
    this.analyticsCategory = StringHelper.clean(options.analyticsCategory);
    this.projectType = options.projectType;
    this.reportUrl = StringHelper.clean(options.reportUrl);
  }
}
