<div class="container-body">
  <div container-header>
    <div class="process-steps">
      <app-steps [model]="menuItems"
        [(activeIndex)]="activeScreen"
        (activeIndexChange)="showNextHedisScreen($event)"></app-steps>
      <ng-template *ngIf="isGapLoaded"><app-steps [model]="menuItems"
                 [(activeIndex)]="activeScreen"
                 (activeIndexChange)="showNextGapScreen($event)"></app-steps>
      </ng-template>
      <ng-template *ngIf="isRiskProject"><app-steps [model]="menuItems"
                  [(activeIndex)]="activeScreen"
                  (activeIndexChange)="showNextScreen($event)"></app-steps>
      </ng-template>
    </div>
  </div>
  <div class="container-content">
    <app-create-new-chase-project [style.display]="projectScreen" 
    (selectedProjectType)="selectedProjectType($event)"
    (onShowChild)="showNextScreen(newChaseScreens.Member)" (isShowEncounter)="showEncounterStepMenu($event)" (isShowGap)="showGapStepMenu($event)"></app-create-new-chase-project>
    <app-create-new-chase-member [currentSelectedProjectType]="currentSelectedProjectType" [style.display]="memberScreen" (onShowChild)="showNextScreen(newChaseScreens.Address)"></app-create-new-chase-member>
    <app-create-new-chase-address [style.display]="addressScreen" (onShowChild)="showNextScreen(newChaseScreens.Provider)"></app-create-new-chase-address>
      <app-create-new-chase-provider [style.display]="providerScreen" (onShowChild)="showNextHedisScreen(newChaseHedisScreens.REVIEW)" [isRiskProject]="isRiskProject"></app-create-new-chase-provider>    
    <ng-template *ngIf="isRiskProject">
    <app-create-new-chase-provider [style.display]="providerScreen" (isShowEncounterScreen)="showEncounterScreen($event)" (onShowChild)="showNextScreen(newChaseScreens.Encounters)" [isRiskProject]="isRiskProject"></app-create-new-chase-provider>
  </ng-template>
    <ng-template *ngIf="isGapLoaded">
      <app-create-new-chase-provider [style.display]="providerScreen" (isShowGapScreen)="showGapScreen($event)" (onShowChild)="showNextGapScreen(newChaseGapScreens.Review)" [isRiskProject]="isRiskProject"></app-create-new-chase-provider>    
      </ng-template>
    <app-create-new-chase-encounters [style.display]="displayEncounterScreen" (onShowChild)="showNextScreen(newChaseScreens.Review)"></app-create-new-chase-encounters>
    <app-create-new-chase-gap [style.display]="displayGapScreen" (onShowChild)="showNextGapScreen(newChaseGapScreens.Review)" [isGapScreenActive]="isGapScreenActive"></app-create-new-chase-gap>
    <app-create-new-chase-final-process *ngIf="isShowFinalReviewScreenForRiskAndGap; else hedisFinalStep" (onShowChild)="showNextScreen(newChaseScreens.Confirmation)"></app-create-new-chase-final-process>    
    <ng-template #hedisFinalStep>
    <app-create-new-chase-final-process *ngIf="isShowFinalReviewScreen" (onShowChild)="showNextHedisScreen(newChaseHedisScreens.CONFIRMATION)"></app-create-new-chase-final-process>
    </ng-template>
  </div>
</div>
<div class="splash-page" *ngIf="isChaseSaved">
  <app-create-new-chase-success-confirmation></app-create-new-chase-success-confirmation>
</div>

