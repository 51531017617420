import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapTagAddressNotes = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "TagAddressNotes")
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("callNotes", o => o.callNotes);
};

export class TagAddressNotes {
  masterDocumentSourceId: number;
  manageTagNotes: string;

  constructor(options: {
    masterDocumentSourceId: number;
    manageTagNotes: string;
    }) {
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.manageTagNotes = options.manageTagNotes;
    }
}
