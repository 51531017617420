import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ListItem } from "../../../../../shared/list/list-item";
import { UpdatePend } from "./update-pend.model";

@Injectable()
export class InternalPendsUpdatePendService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getPendGdFromPath(): number {
    return this.route.snapshot.params.pendGd;
  }

  getPendCompaniesDropdown(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}pend/companylist`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  getAssignedToUsersList(ownedBy: string, chasePendId: number, roleType?: string): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}pend/assigntouserlist?owner=${ownedBy}&chasePendId=${chasePendId}&roleType=${roleType}`;

    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getPendStatusDropdown(isClinical: boolean, organizationId: number, isClientOnly: boolean, isReturnAllPendStatus?: boolean): Observable<SelectItem[]> {
        const url = `${this.baseApiUrl}Pend/GetPendDropdowns?isClinical=${isClinical}&organizationId=${organizationId}&isClientOnly=${isClientOnly}&isReturnAllPendStatus=${isReturnAllPendStatus}`;

        return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getPendData(chasePendId: number): Observable<UpdatePend> {
    const url = `${this.baseApiUrl}Pend/ChasePendData?chasePendId=${chasePendId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "UpdatePendItem"))
    );
  }

  update(updatePend: UpdatePend): Observable<number> {
    const url = `${this.baseApiUrl}pend/updatependview`;

    return this.http.post(url, updatePend, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {

            return 0;
          }
        })
      );
  }
}
