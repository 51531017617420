import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { List } from "immutable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ListItem } from "../../../../../shared/list/list-item";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { AuditQueryHeaderService } from "./audit-query-header.service";

@Component({
  selector: "app-audit-query-header",
  templateUrl: "./audit-query-header.component.html",
  styleUrls: ["./audit-query-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryHeaderComponent implements OnInit, OnDestroy {
  @Output() currentTab = new EventEmitter<string>();
  @Input() stateName = "";
  private unsubscribe = new Subject();
  selectedTab = "auditquery";
  chaseDetails = List<ListItem>();
  moveToPreviousUrl = false;
  constructor(
    private auditQueryHeaderService: AuditQueryHeaderService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.auditQueryHeaderService.changeTab(this.selectedTab);
    this.auditQueryHeaderService.selectedTab
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.toggleTab(data);
      });

    this.auditQueryHeaderService.setChaseDetails([]);
    this.auditQueryHeaderService.chaseDetails
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(items => {
        this.chaseDetails = List(items);

        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggleTab(tab: string): void {
    this.selectedTab = (tab === "") ? this.selectedTab : tab;
    this.currentTab.emit(this.selectedTab);
    this.moveToPreviousUrl = tab === "auditquery";
  }

  get chaseDetailsVisible(): boolean {
    return (NumberHelper.isGreaterThan(this.chaseDetails.size, 0) && this.selectedTab === "chart");
  }

  get headerText() {
    return `Audit Query`;
  }

}
