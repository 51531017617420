import { IAutoMapper } from "../core/automapper/interfaces";
import { StringHelper } from "../utilities/contracts/string-helper";

export const mapProvider = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Provider")
    .forMember("providerId", o => o.providerId)
    .forMember("providerName", o => o.providerName)
    .forMember("providerSourceAliasId", o => o.providerSourceAliasId)
    .forMember("providerSpecialty", o => o.providerSpecialty)
    .forMember("nationalProviderId", o => o.nationalProviderId);

  automapper
    .createMap("SelectableInput", "Provider")
    .forMember("providerId", o => o.extra.providerId)
    .forMember("providerName", o => o.extra.providerName)
    .forMember("providerSourceAliasId", o => o.extra.providerSourceAliasId)
    .forMember("providerSpecialty", o => o.extra.providerSpecialty)
    .forMember("nationalProviderId", o => o.extra.nationalProviderId);
};

export interface IProviderOptions {
  providerId?: number;
  providerName?: string;
  providerSourceAliasId?: string;
  providerSpecialty?: string;
  nationalProviderId?: string;
}

export class Provider {
  providerId: number;
  providerName: string;
  providerSourceAliasId: string;
  providerSpecialty: string;
  nationalProviderId: string;


  constructor(options: IProviderOptions = {}) {
    this.providerId = options.providerId;
    this.providerName = StringHelper.clean(options.providerName);
    this.providerSourceAliasId = StringHelper.clean(options.providerSourceAliasId);
    this.providerSpecialty = StringHelper.clean(options.providerSpecialty);
    this.nationalProviderId = StringHelper.clean(options.nationalProviderId);
  }
}
