import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SessionService } from "../../core/storage/session.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { GridService } from "../grid/grid.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";

@Component({
  selector: "app-chase-grid-chase-id",
  templateUrl: "./chase-id.component.html",
  styleUrls: ["./chase-id.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseIdComponent {
  @Input() data: any;
  @Input() chaseIdUrl: string;
  @Input() isOverreadFeedbackEmpty = true;

  constructor(
    private router: Router,
    private gridService: GridService,
    private readonly messagingService: MessagingService,
    private readonly sessionService: SessionService
  ) { }

  get chaseId(): number {
    return this.data.chaseId || this.data.chaseID;
  }

  get isNewPage(): boolean {
    return this.data.chaseDocumentChange;
  }

  get parentChaseId(): number {
    return this.data.parentChaseId;
  }

  get pursuitChaseIds(): number[] {
    return StringHelper.isAvailable(this.data.pursuitChases) ? this.data.pursuitChases.split(",").map(Number) : [];
  }

  get hasParentOrPursuitChases(): boolean {
    return this.hasParent || this.hasPursuit;
  }

  get hasParent(): boolean {
    return NumberHelper.isAvailable(this.parentChaseId) && this.parentChaseId > 0;
  }

  get hasPursuit(): boolean {
    return ArrayHelper.isAvailable(this.pursuitChaseIds);
  }

  get parentOrPursuitText(): string {
    if (this.hasParent) {
      return "P";
    }

    if (this.hasPursuit) {
      return this.pursuitChaseIds.length.toString();
    }

    return "";
  }

  get gapText(): string {
    if (this.isGapChase) {
      return "G-";
    }

    return "";
   }

  get isGapChase(): boolean {
    return this.data.numeratorList !== "" && (this.data.numeratorList != null || this.data.numeratorList != undefined);
  }

  get popOverTitle(): string {
    if (this.hasParent) {
      return "Parent Chase";
    }

    if (this.hasPursuit) {
      return "Pursuit Chase(s)";
    }

    return "";
  }

  get relatedChaseIds(): number[] {
    if (this.hasParent) {
      return [this.parentChaseId];
    }

    if (this.hasPursuit) {
      return this.pursuitChaseIds;
    }

    return [];
  }

  get rowEnabled(): boolean {
    return true;
  }

  savePreviousUrl(): void {
    if (!this.router.url.includes("search")) { this.sessionService.put("chase_prev_url", this.router.url); }
  }

  getChaseDetailURL(): string {
    const routeurl = this.isNewPage ? "/members/chase/:chaseId/expanded/newPages" : this.chaseIdUrl;

    const url = this.gridService.generateLink(
      new GridColumnDefinition({ routeUrl: routeurl } as any),
      this.data
    );
    return url;
  }

  isOverreadFeedbackNotEmpty() {
    this.messagingService.showToast("Please review your Overread Feedback");
  }

  getChildURL(chaseId: number): string {
       return `/members/chase/${chaseId}`;
  }
}
