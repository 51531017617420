import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment } from "../environments/environment";
import { LoginGuard } from "./auth/login-guard.service";
import { ModulePreloadingStrategy } from "./module-preloading-strategy";
import { ExternalUrlService } from "./platform/api/url-guards/external-url.service";
import { HomePageUrlService } from "./platform/api/url-guards/home-page-url.service";
import { AccessDeniedComponent } from "./platform/error/access-denied/access-denied.component";
import { PageNotFoundComponent } from "./platform/error/page-not-found/page-not-found.component";
import { NotificationComponent } from "./platform/notification/notification.component";
import { PlatformComponent } from "./platform/platform.component";
import { ProviderUploadPortalComponent } from "./provider/provider-upload-portal/provider-upload-portal.component";
import { DevelopmentGuard } from "./zdevcontrols/development-guard.service";

const routes: Routes = [
  { path: "login", loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule) },
  {
    path: "",
    component: PlatformComponent,
    children: [
      { path: "", canActivate: [HomePageUrlService], component: HomePageUrlService },
      { path: "admin", loadChildren: () => import("./platform/modules/service-org-admin/service-org-admin.module").then(m => m.ServiceOrgAdminModule), data: { preload: true } },
      { path: "analytics", loadChildren: () => import("./platform/modules/analytics/analytics.module").then(m => m.AnalyticsModule), data: { preload: true } },
      { path: "approvalcenter", loadChildren: () => import("./platform/modules/approval-center/approval-center.module").then(m => m.ApprovalCenterModule), data: { preload: true } },
      { path: "audit", loadChildren: () => import("./platform/modules/audit/audit.module").then(m => m.AuditModule), data: { preload: true } },
      { path: "bulkactions", loadChildren: () => import("./platform/modules/project/project.module").then(m => m.ProjectModule), data: { preload: true } },
      { path: "chartlake", loadChildren: () => import("./platform/modules/chart-lake/chart-lake.module").then(m => m.ChartLakeModule), data: { preload: true } },
      { path: "clinical", loadChildren: () => import("./platform/modules/clinical/clinical.module").then(m => m.ClinicalModule), data: { preload: true } },
      { path: "dashboard", loadChildren: () => import("./platform/modules/dashboard/dashboard.module").then(m => m.DashboardModule) },
      { path: "dataload", loadChildren: () => import("./platform/modules/data-load/data-load.module").then(m => m.DataLoadModule) },
      { path: "eventlake", loadChildren: () => import("./platform/modules/event-lake/event-lake.module").then(m => m.EventLakeModule), data: { preload: true } },
      { path: "help", loadChildren: () => import("./platform/modules/help/help.module").then(m => m.HelpModule) },
      { path: "invoices", loadChildren: () => import("./platform/modules/invoice/invoice.module").then(m => m.InvoiceModule), data: { preload: true } },
      { path: "jobsqueue", loadChildren: () => import("./platform/modules/jobsqueue/jobsqueue.module").then(m => m.JobsQueueModule), data: { preload: true } },
      { path: "members", loadChildren: () => import("./platform/modules/member/member.module").then(m => m.MemberModule), data: { preload: true } },
      { path: "notification", component: NotificationComponent },
      { path: "organization", loadChildren: () => import("./platform/modules/service-org-admin/service-org-admin.module").then(m => m.ServiceOrgAdminModule), data: { preload: true } },
      { path: "pend", loadChildren: () => import("./platform/modules/internal-pends/internal-pends.module").then(m => m.InternalPendsModule), data: { preload: true } },
      { path: "project", loadChildren: () => import("./platform/modules/project/project.module").then(m => m.ProjectModule), data: { preload: true } },
      { path: "query", loadChildren: () => import("./platform/modules/project/project.module").then(m => m.ProjectModule), data: { preload: true } },
      { path: "reporting", loadChildren: () => import("./platform/modules/reporting/reporting.module").then(m => m.ReportinglModule), data: { preload: true } },
      { path: "retrieval", loadChildren: () => import("./platform/modules/retrieval/retrieval.module").then(m => m.RetrievalModule), data: { preload: true } },
      { path: "search", loadChildren: () => import("./platform/search/search.module").then(m => m.SearchModule), data: { preload: true, isGlobalSearch: true } },
      { path: "user", loadChildren: () => import("./platform/modules/user/user.module").then(m => m.UserModule), data: { preload: true } },
      { path: "403", component: AccessDeniedComponent },
      { path: "404", component: PageNotFoundComponent },
    ],
    canActivate: [LoginGuard],
  },
  { path: "onboarding", loadChildren: () => import("./onboarding/onboarding.module").then(m => m.OnboardingModule) },
  { path: "provider", loadChildren: () => import("./provider/provider.module").then(m => m.ProviderModule) },
  { path: "providergateway", component: ProviderUploadPortalComponent },
  { path: "providergateway/:code", component: ProviderUploadPortalComponent },
  { path: "toolbox", canActivate: [DevelopmentGuard], loadChildren: () => import("./toolbox/toolbox.module").then(m => m.ToolboxModule) },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      enableTracing: false, // !environment.production, // <-- debugging purposes only
      preloadingStrategy: environment.production ? ModulePreloadingStrategy : null,
      onSameUrlNavigation: "reload",
    }
  )],
  exports: [RouterModule],
  providers: [
    ModulePreloadingStrategy,
    ExternalUrlService,
  ],
})
export class AppRoutingModule { }
