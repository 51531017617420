import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../core/storage/session.service";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { CreateNewChaseAction } from "../create-new-chase-action.enum";
import { CreateChaseService } from "../create-new-chase.service";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { MeasureAttributeData } from "../measure-attribute-data.model";
import { NewChaseResponse } from "../new-chase-response.model";
import { NewChase } from "../new-chase.model";

@Component({
  selector: "app-create-new-chase-final-process",
  templateUrl: "./create-new-chase-final-process.component.html",
  styleUrls: ["./create-new-chase-final-process.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseFinalProcessComponent implements OnInit {
  @Output() onShowChild = new EventEmitter<any>();
  chaseId: number;
  chaseKey: number;
  projectName: string;
  memberName: string;
  memberDob: string;
  address: string;
  providerNames: string[] = [];
  measureCode: string;
  newChase: NewChase;
  measureAttributeData: MeasureAttributeData[];
  encounters: number;
  diagnosis: number;
  gaps: number;
  isDisabled = false;
  isPursuit = false;

  constructor(private createChaseService: CreateChaseService,
              private messagingService: MessagingService,
              private router: Router,
              private sessionService: SessionService,
              private dataExchangeService: GapQueryDataExchangeService) { }

  ngOnInit() {
    this.newChase = this.createChaseService.chaseModel;
    this.dataExchangeService.getNewChaseData().subscribe(result => {
      this.newChase = result;
    });
    this.isPursuit = this.createChaseService.chaseModel.isPursuit;
    this.chaseKey = this.createChaseService.chaseProjectItems.chaseKey;
    this.projectName = this.createChaseService.chaseProjectItems.projectName;
    this.memberName = this.newChase.memberName;
    this.memberDob = this.newChase.memberDateOfBirth;
    this.address = this.newChase.chaseAddress;
    this.measureCode = this.createChaseService.chaseProjectItems.measureCode;
    this.measureAttributeData = this.createChaseService.chaseProjectItems.measureAttributeData;
    this.encounters = this.newChase.encounterCount;
    this.diagnosis = this.newChase.diagnosisCount;
    this.gaps = this.newChase.gapsCount;
    this.newChase.riskChaseProviderData.forEach(x => {
      this.providerNames.push(x.serviceProviderName);
    });
  }

  private setResponse(response: NewChaseResponse): void {
    this.isDisabled = false;
    this.newChase.chaseId = response.chaseID;
    this.newChase.parentChaseId = response.mrrChaseID;
    this.onShowChild.emit(this.newChase);
    this.sessionService.delete("gapChaseCreation");
  }

  newChaseAction(): void {
    if (this.createChaseService.chaseModel.isPursuit) {
        this.createChaseService.createPursuitChase(this.newChase.parentChaseId, this.getEntityIdList(), this.newChase.requestType)
        .subscribe(
          response => {
            this.setResponse(response);
          },
          err => {
            this.isDisabled = false;
            this.messagingService.showToast("Error while creating new Chase, please try again.", SeverityType.ERROR);
          }
        );
        return;
    }

    this.isDisabled = true;
    this.newChase.chaseKey = this.createChaseService.chaseProjectItems.chaseKey;
    this.newChase.projectName = this.createChaseService.chaseProjectItems.projectName;
    this.newChase.projectId = this.createChaseService.chaseProjectItems.projectId;
    this.newChase.measureCode = this.createChaseService.chaseProjectItems.measureCode;
    this.newChase.measureAttributeData = this.createChaseService.chaseProjectItems.measureAttributeData;
    this.newChase.productName = this.createChaseService.chaseProjectItems.productName;
    this.newChase.action = CreateNewChaseAction.Save;
    this.createChaseService.saveChaseDetails(this.newChase).subscribe(
      response => {
        this.setResponse(response);
      },
      err => {
        this.isDisabled = false;
        this.messagingService.showToast("Error while creating new Chase, please try again.", SeverityType.ERROR);
      }
    );
    }

  get isShowEncounterDiagnosisText(): boolean {
        return this.newChase.encounters != null;
    }

  get isShowGapText(): boolean {
    return this.newChase.numeratorList != null;
  }

  trackByIndex(index, item) {
      return index;
  }

  get isShowMeasureText(): boolean {
    return StringHelper.isAvailable(this.measureCode);
  }

  private getEntityIdList(): number[] {
    return this.newChase.encounters.map(encounter => encounter.entityId);
  }
}
