import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";
import { GridPipeName } from "../grid-pipe.enum";
import { GridService } from "../grid.service";
import { ILazyLoadEvent } from "../models/api";
import { GridColumnDefinition } from "../models/grid-column-definition.model";
import { GridConfiguration } from "../models/grid-configuration.model";
import { GridLoadEvent } from "../models/grid-load-event.model";

@Component({
  selector: "app-obsolete-grid",
  templateUrl: "./obsolete-grid.component.html",
  styleUrls: ["./obsolete-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObsoleteGridComponent implements OnInit {

  isLoading: boolean;
  sortingOptions: string[];
  isSortByDesc: boolean;
  selectedSortingField: string;

  @Input() gridConfiguration: GridConfiguration;
  @Input() rowData: any[];
  @Output() rowSelect = new EventEmitter();
  @Output() loadGrid = new EventEmitter<ILazyLoadEvent>();

  //  returns selected row(s) in the grid
  selectedRows: any[];

  constructor(
    private service: GridService
  ) {}

  ngOnInit() {
    this.isSortByDesc = true;
    this.sortingOptions = this.service.getSortingFieldsObsolete(this.gridConfiguration.columns);
  }


  get totalRecords(): number {
    const hasRowData = Array.isArray(this.rowData) && this.rowData.length > 0;

    return hasRowData ? this.rowData[0].recordCount : 0;
  }

  getValue(row: any, col: GridColumnDefinition): string {
    const value = row[col.field];
    const finalValue = col.hasPipe ? this.usePipe(value, col.pipeName, col.format) : value;

    return finalValue;
  }

  usePipe(data: string, pipeName: GridPipeName, format: string) {
    return this.service.usePipe(data, pipeName, format);
  }

  getSelectedRows()  {
     return this.service.getSelectedRows(this.gridConfiguration.selectionMode, this.selectedRows);
  }

  gridRowSelect(event) {
    this.rowSelect.emit(event);
  }

  generateLink(column: GridColumnDefinition, row: any) {
    return this.service.generateLink(column, row);
  }

  showAction(event, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  loadGridLazy(event: ILazyLoadEvent) {
    const gridEvent = new GridLoadEvent(event); // TODO: Delete <-- This is not necessary? Just have GridLoadEvent implement ILazyLoadEvent?

    this.callGridLoadEvent(gridEvent);
  }

  filterData() {
    if (this.selectedSortingField != null) {
      const event = this.service.getSortingOptions(this.selectedSortingField, this.isSortByDesc, this.gridConfiguration.pageSize);
      this.callGridLoadEvent(event);
    }
  }

  callGridLoadEvent(event) {
    this.loadGrid.emit(event);
  }

  trackByFn(index, item) {
    return item.id;
  }

  trackByRowIndex(index, item) {
    return index;
  }

}
