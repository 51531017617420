import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../../core/user/user.service";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { IFilter } from "../../../../../../../shared/grid/basic-filter-applied/basic-filter-applied.component";
import { BulkAction } from "../../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridView } from "../../../../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridPipeName } from "../../../../../../../shared/grid/grid-pipe.enum";
import { GridStateService } from "../../../../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { CreatePendService } from "../../../../../../../shared/pend/create-pend.service";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../../../../utilities/contracts/helper-types";
import { ChaseSearchRequest } from "../../../../../../api/chase-search/chase-search-request-model";
import { ChaseItem } from "../../../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { ClinicalPageService } from "../../../../../clinical/clinical-page/clinical-page.service";
import { RetrievalPageService } from "../../../../retrieval-page/retrieval-page.service";
import { AddressDetailStateService } from "../../../address-detail-state.service";
import { RetrievalAddressDetailInfoService } from "../../address-detail-info.service";


@Component({
  selector: "retrieval-address-detail-info-grids-pends",
  templateUrl: "./address-detail-info-grids-pends.component.html",
  styleUrls: ["./address-detail-info-grids-pends.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsPendsComponent implements OnInit, OnDestroy {


  get actionableChaseGridSelection(): ChaseItem[] {
    return (ArrayHelper.isAvailable(this.chaseGridSelection))
      ? this.chaseGridSelection.filter(c => !c.disabled)
      : [];
  }
  private sink = new SubSink();
  pendsGridConfiguration = new GridConfiguration();
  pendsGridData: any[] = [];
  private pageSize = 25;
  private user: UserToken;
  isSOCoRetrievalOwner = false;

  form: FormGroup;
  request: IFilter[];
  @Input() clinical = false;
  chasePendId: Textbox;
  pendCode: CheckboxGroup;
  measureCode: CheckboxGroup;
  pendStatus: CheckboxGroup;
  chaseId: Textbox;
  projectName: CheckboxGroup;
  isReloadFilterInput = false;
  refreshViews = new EventEmitter<GridView>(true);
  GRID_ADDRESS_PEND = "AddressPendGrid";

  isPendModalVisible = false;
  actions: BulkAction[];

  selectedChases: any[];
  chaseGridSelection: any[];

  addressId: number;
  gridSearchRequest: any;
  startRecord: number;
  endRecord: number;

  constructor(
    private service: RetrievalAddressDetailInfoService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef,
    private formService: FormService,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly createPendService: CreatePendService,
    private readonly gridStateService: GridStateService,
    private messagingService: MessagingService,
    private userService: UserService,
    private chaseService: ChaseSearchService
  ) { }


  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.isSOCoRetrievalOwner = this.user.isCoRetrievalEnabled;

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressId = state.masterDocumentSourceId;
        this.pendsGridConfiguration.stateName = this.GRID_ADDRESS_PEND;
        this.actions = this.getActions();
        if (state.hasMasterDocumentSourceId) {
          this.service
            .getPends(state.masterDocumentSourceId)
            .subscribe(items => {
              this.pendsGridData = items as any;
              this.changeDetector.markForCheck();
            });
        }
      })
    );
    this.createGrids();
    this.form = this.formService.createFormGroup([
      this.chasePendId,
      this.pendCode,
      this.measureCode,
      this.pendStatus,
      this.chaseId,
      this.projectName,
    ]);
    this.getAllSelectableInputs();
    this.bindPendGridFromState();
  }

  bindPendGridFromState() {
    const state = this.gridStateService.get(this.GRID_ADDRESS_PEND);
    if (state?.basicGridState.basicGridForm) {
      const stateForm = JSON.parse(state.basicGridState.basicGridForm);
      this.request = state.basicGridState.basicGridRequest;
      this.bindPendFormValues(stateForm);
      this.formService.updateDom.next();
    }
  }
  bindPendFormValues(stateForm: any) {
    for (const key in this.form.controls) {
      if (key) {
        switch (key) {
          case this.chasePendId.key:
            this.form.patchValue({ chasePendId: stateForm[key] });
            this.form.controls[this.chasePendId.key].updateValueAndValidity({ emitEvent: true });
            this.chasePendId.value = stateForm[key];
            break;
          case this.pendCode.key:
            this.form.patchValue({ pendCode: stateForm[key] });
            this.form.controls[this.pendCode.key].updateValueAndValidity({ emitEvent: true });
            this.pendCode.value = stateForm[key];
            break;
          case this.measureCode.key:
            this.form.patchValue({ measureCode: stateForm[key] });
            this.form.controls[this.measureCode.key].updateValueAndValidity({ emitEvent: true });
            this.measureCode.value = stateForm[key];
            break;
          case this.pendStatus.key:
            this.form.patchValue({ pendStatus: stateForm[key] });
            this.form.controls[this.pendStatus.key].updateValueAndValidity({ emitEvent: true });
            this.pendStatus.value = stateForm[key];
            break;
          case this.chaseId.key:
              this.form.patchValue({ chaseId: stateForm[key] });
              this.form.controls[this.chaseId.key].updateValueAndValidity({ emitEvent: true });
              this.chaseId.value = stateForm[key];
              break;
          case this.projectName.key:
            this.form.patchValue({ projectName: stateForm[key] });
            this.form.controls[this.projectName.key].updateValueAndValidity({ emitEvent: true });
            this.projectName.value = stateForm[key];
            break;
          default:
            this.form.get(key).setValue(stateForm[key]);
            break;
        }
      }
    }
    this.formService.updateDom.next();
  }

  getAllSelectableInputs(): void {
    this.getMeasures();
    this.getProjects();
    this.getPendCodesAndStatuses();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  createGrids(): void {
    this.pendsGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chasePendId", header: "Pend ID", routeUrl: "/pend/detail/:chasePendId"}),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "owner", header: "Pend Owner" }),
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId"}),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "createdDate", header: "Creation Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "lastUpdatedDate", header: "Last Updated", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
    ];

    if (this.isSOCoRetrievalOwner) {
      this.pendsGridConfiguration.columns.push(new GridColumnDefinition({ field: "coRetrievalOwner", header: "Retriever"}));
     }

    this.pendsGridConfiguration.pageSize = this.pageSize;
    this.pendsGridConfiguration.selectionMode = "multiple";
    this.pendsGridConfiguration.showActionColumn = false;
    this.initializeGridFilter();
    this.pendsGridConfiguration.filterGridFn = this.onApplyFilter;
  }

  initializeGridFilter() {
    this.chasePendId = new Textbox({
      key: "chasePendId",
      label: "Pend ID",
    });
    this.pendCode = new CheckboxGroup({
      key: "pendCode",
      label: "Pend Codes",
      showTooltip: true,
    });
    this.measureCode = new CheckboxGroup({
      key: "measureCode",
      label: "Measures",
    });
    this.pendStatus = new CheckboxGroup({
      key: "pendStatus",
      label: "Pend Status",
    });
    this.chaseId = new Textbox({
      key: "chaseId",
      label: "Chase ID / Client Chase Key",
    });
    this.projectName = new CheckboxGroup({
      key: "projectName",
      label: "Projects",
    });
  }


  onApplyFilter = () => {
    const newRequest = [];
    Object.keys(this.form.value).forEach(key => {
      if (this.form.value[key] != null && !Array.isArray(this.form.value[key])) {
          newRequest.push({
            text: key,
            value: this.form.value[key].value ? this.form.value[key].text : this.form.value[key],
            label: this.form.value[key].value ? key : this[key].label,
            controlType: this[key] ? this[key].controlType : "",
          });
      }
      if (ArrayHelper.isAvailable(this.form.value[key])) {
        this.form.value[key].map(item => newRequest.push({
          text: key,
          value: item.value,
          label: this[key].label,
          controlType: this[key].controlType,
        }));
      }
    });
    const newFilter = [];
    newRequest.forEach(filter => {
      if ((newFilter.findIndex(item => item.text === filter.text)) > -1) {
        newFilter[newFilter.findIndex(item => item.text === filter.text)].value.push(filter.value);
      } else {
        newFilter.push({ text: filter.text, value: [filter.value], controlType: filter.controlType });
      }
    });
    this.request = newRequest;
    this.savePendGridFiltersState();
  }

  savePendGridFiltersState(event?: IFilter) {
    const state = this.gridStateService.get(this.GRID_ADDRESS_PEND);
    if (event) {
      this.request = state.basicGridState.basicGridRequest;
    }
    state.basicGridState.basicGridForm = JSON.stringify(this.form.value);
    state.basicGridState.basicGridRequest = event?.reset ? [] : this.request;
    this.gridStateService.put(this.GRID_ADDRESS_PEND, state);
  }

  onRemoveFilter(event: IFilter): void {
    if (event.reset) {
      this.form.reset();
    } else if (this.form.get(event.text) && ArrayHelper.isAvailable(this.form.get(event.text).value)) {
      const filteredValue = this.form.get(event.text).value.filter(item => item.value !== event.value);
      this.form.get(event.text).setValue(filteredValue);
    } else {
      this.form.get(event.text).reset();
    }
    this.savePendGridFiltersState(event);
  }

  private getPendCodesAndStatuses(): void {
    this.createPendService
      .getPendDropdown(this.clinical)
      .pipe(map((result: any) => {
        const pendCodes = result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
        const pendStatuses = result.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
        return { pendCodes, pendStatuses };
      }))
      .subscribe(({ pendCodes, pendStatuses }: any) => {
        this.pendCode = new CheckboxGroup({ ...this.pendCode, options: pendCodes } as any);
        this.pendStatus = new CheckboxGroup({ ...this.pendStatus, options: pendStatuses} as any);
        this.formService.updateDom.next();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.measureCode,
          value: item.measureCode,
        }));
      }))
      .subscribe(options => {
        this.measureCode = new CheckboxGroup({ ...this.measureCode, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
      }))
      .subscribe(options => {
        this.projectName = new CheckboxGroup({ ...this.projectName, options } as any);
        this.formService.updateDom.next();
      });
  }

  openPendModal(rowData?: any): void {
    this.selectedChases = [];
    this.selectedChases = this.getSelectedChases(rowData);

    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.isPendModalVisible = true;
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  private getSelectedChases(rowData: any): number[] {
    let selectedChases = [];

    if (rowData != null) {
      selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    } else if (ArrayHelper.isAvailable(this.actionableChaseGridSelection)) {
      this.actionableChaseGridSelection.forEach(item => selectedChases.push(item));
    }

    return selectedChases;
  }

  updateChaseGrid() {
    this.gridSearchRequest = new ChaseSearchRequest(
      null, null, null, null, this.addressId, null, null, null, null, null, null,
      null, this.startRecord, this.endRecord);

    this.chaseService
      .chaseSearch(this.gridSearchRequest)
      .subscribe(chaseGridData => {
        this.addressDetailStateService.setData({ chaseGridData });
      });
  }

  closeModal(): void {
    this.selectedChases = [];
    this.chaseGridSelection = [];
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      new BulkAction({
        name: "Pend Chase(s)",
        action: this.openPendModal.bind(this),
      }),
    ];
    return totalBulkActions;
  }

}
