<div class="container-main">
  <h3 class="bold">OVERREAD FEEDBACK</h3>
  <div class="sub-menu__container">
    <app-button [ngClass]="criticalErrorBtnClass" classes="secondary small" text="COMPLETE" (onClick)="toggleCriticalErrors()"></app-button>
    <app-button [ngClass]="nonCriticalErrorBtnClass" classes="secondary small" text="INCOMPLETE" (onClick)="toggleNoncriticalErrors()"></app-button>
  </div>
  <div class="sub-menu__export" *ngIf="exportAllBtn" >
    <app-button classes="secondary small" [text]="exportButtonText"  (onClick)="export()" ></app-button>
  </div>
  
  <app-basic-grid 
    class="grids-display"
    [configuration]="gridConfiguration"
    [data]="gridData"
    >

    <ng-template #chaseIdColumn let-rowData>
      <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
    </ng-template>

    <ng-template #reviewColumn let-rowData>
      <div *ngIf="isStatusCompleted(rowData) && isManagerGrid; else review">
        {{ receiptStatus(rowData) }}
      </div>
      <ng-template #review>
        <a (click)="navigateToFeedback(rowData)">{{reviewButtonText(rowData)}}</a>
      </ng-template>
    </ng-template>

  </app-basic-grid>
</div>