import { IAutoMapper } from "../../core/automapper/interfaces";
import { DynamicEntityAttribute } from "../../platform/api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../platform/api/member-validation/entity-type.enum";
import { ACCEPTABLE_SIGNATURE, CHART_PAGE_NUMBER, ENCOUNTER_FOUND } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";

export const mapMemberValidation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberValidation")
    .forMember("isValidInfo", o => automapper.map("default", "DynamicEntityAttribute", o.isValid))
    .forMember("reasonInfo", o => automapper.map("default", "DynamicEntityAttribute", o.reason))
    .forMember("pageNumberInfo", o => automapper.map("default", "DynamicEntityAttribute", o.pageNumber))
    .forMember("dateOfBirth", o => automapper.map("default", "DynamicEntityAttribute", o.dateOfBirth));
};


export class DatesOfServiceValidation {
  readonly encounterFoundInfo: DynamicEntityAttribute;
  readonly providerSignaturePresentInfo: DynamicEntityAttribute;
  readonly chartPageNumberInfo: DynamicEntityAttribute;
  chaseId: number;
  entityId: number;

  constructor(options: {
    encounterFoundInfo?: DynamicEntityAttribute;
    providerSignaturePresentInfo?: DynamicEntityAttribute;
    chartPageNumberInfo?: DynamicEntityAttribute;
    chaseId?: number;
    entityId?: number;
  } = {}) {
    this.encounterFoundInfo = options.encounterFoundInfo || new DynamicEntityAttribute();
    this.encounterFoundInfo.attributeId = ENCOUNTER_FOUND.attributeId;
    this.encounterFoundInfo.attributeCode = ENCOUNTER_FOUND.attributeCode;
    this.encounterFoundInfo.entityTypeId = EntityType.ENCOUNTER;
    this.encounterFoundInfo.chaseId = options.chaseId;
    this.encounterFoundInfo.entityId = options.entityId;

    this.providerSignaturePresentInfo = options.providerSignaturePresentInfo || new DynamicEntityAttribute();
    this.providerSignaturePresentInfo.attributeId = ACCEPTABLE_SIGNATURE.attributeId;
    this.providerSignaturePresentInfo.attributeCode = ACCEPTABLE_SIGNATURE.attributeCode;
    this.providerSignaturePresentInfo.entityTypeId = EntityType.ENCOUNTER;
    this.providerSignaturePresentInfo.chaseId = options.chaseId;
    this.providerSignaturePresentInfo.entityId = options.entityId;

    this.chartPageNumberInfo = options.chartPageNumberInfo || new DynamicEntityAttribute();
    this.chartPageNumberInfo.attributeId = CHART_PAGE_NUMBER.attributeId;
    this.chartPageNumberInfo.attributeCode = CHART_PAGE_NUMBER.attributeCode;
    this.chartPageNumberInfo.entityTypeId = EntityType.ENCOUNTER;
    this.chartPageNumberInfo.chaseId = options.chaseId;
    this.chartPageNumberInfo.entityId = options.entityId;
  }

  get encounterFound(): boolean {
    return this.encounterFoundInfo.value == null
      ? null
      : (this.encounterFoundInfo.value === "true"
        || this.encounterFoundInfo.value === true
        || this.encounterFoundInfo.value === "1"
        || this.encounterFoundInfo.value === 1);
  }
  set encounterFound(value: boolean) {
    this.encounterFoundInfo.value = value;
  }

  get providerSignaturePresent(): boolean {
    return this.providerSignaturePresentInfo.value == null
      ? null
      : (this.providerSignaturePresentInfo.value === "true"
        || this.providerSignaturePresentInfo.value === true
        || this.providerSignaturePresentInfo.value === "1"
        || this.providerSignaturePresentInfo.value === 1);
  }
  set providerSignaturePresent(value: boolean) {
    this.providerSignaturePresentInfo.value = value;
  }

  get chartPageNumber(): number {
    if (typeof this.chartPageNumberInfo.value === "string") {
      return parseInt(this.chartPageNumberInfo.value, 10);
    }

    if (typeof this.chartPageNumberInfo.value === "number") {
      return this.chartPageNumberInfo.value;
    }

    return null;
  }
  set chartPageNumber(value: number) {
    this.chartPageNumberInfo.value = value;
  }

  saveDatesOfServiceValidationInfo(): DynamicEntityAttribute[] {
    const encounterFoundInfo = { ...this.encounterFoundInfo, options: null };
    const providerSignaturePresentInfo = { ...this.providerSignaturePresentInfo, options: null };
    const chartPageNumberInfo = { ...this.chartPageNumberInfo, options: null };

    return [encounterFoundInfo, providerSignaturePresentInfo, chartPageNumberInfo];
  }
}
