import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { TextboxType } from "../textbox/textbox-type.enum";
import { TextboxSourceComponent } from "./textbox-source.component";

export interface ITextboxSourceOptions extends IDynamicInputOptions {
  type?: TextboxType;
  dataType?: string;
  placeholder?: string;
  autocomplete?: string;
  title?: string;
  classOverride?: string;
  maxlength?: number;
}

export class TextboxSource extends DynamicInput implements ITextboxSourceOptions {
  type: TextboxType;
  dataType: string;
  placeholder: string;
  autocomplete: string;
  title: string;
  classOverride: string;
  maxlength: number;

  readonly controlType = "textbox-source";
  readonly componentType = TextboxSourceComponent;

  constructor(options: ITextboxSourceOptions = {}) {
    super(options);
    this.type = options.type || TextboxType.TEXT;
    this.dataType = options.dataType || "";
    this.placeholder = options.placeholder || "";
    this.autocomplete = options.autocomplete || "off";
    this.title = options.title || "";
    this.classOverride = options.classOverride || "";
    this.maxlength = options.maxlength;
    this.disabled = true;
  }
}
