import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-confidence-score",
  templateUrl: "./confidence-score-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfidenceScoreReportComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.ACCURACY_REPORT;

  constructor() {
    this.filters = ["Projects", "DateRange", "DiagnosisCode"];
  }
}
