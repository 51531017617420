import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FileUpload } from "primeng/fileupload";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../utilities/contracts/array-helper";

export interface IOnUploadNew {
  formData: FormData;
  success(): void;
}

@Component({
  selector: "app-file-upload-new",
  templateUrl: "./upload-new.component.html",
  styleUrls: ["./upload-new.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadNewComponent implements OnChanges {
  @ViewChild("primeUpload", { static: true }) primeUpload: FileUpload;
  @Input() accept = ".pdf";
  @Input() mode = "advanced";
  @Input() auto = "auto";
  @Input() multiple = true;
  @Input() chooseLabel = "Select Documents";
  @Input() data: KeyValuePairs<string> = [];
  @Input() successMessage = "Uploaded successfully.";
  @Input() disabled = false;
  @Input() showUploadButton = true;
  @Input() showCancelButton = true;
  @Output() onSubmit: EventEmitter<null> = new EventEmitter();
  @Output() onUpload = new EventEmitter<IOnUploadNew>(true);
  // NOTE: DO NOT USE "onSelect". This is not approved because it is returning a primeng event and now this component is tied to primeng.
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() hasError = false;
  @Input() uploadErrorMessage = "";
  errorMessage: any;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.uploadErrorMessage) {
      this.errorMessage = [
          {severity: "error", summary: "", detail: `${this.uploadErrorMessage}`},
      ];
    }
  }

  uploadDocument(event): void {
    const files = event.files;

    if (ArrayHelper.isAvailable(files)) {
      const formData = this.createFormData(files);

      this.onUpload.emit({
        formData,
        success: () => {
          this.primeUpload.clear();
          this.changeDetector.markForCheck();
        },
      });
    } else {
      this.messagingService.showToast(
        "Please select a file.",
        SeverityType.ERROR
      );
    }
  }

  clearUpload(): void {
    this.primeUpload.clear();
    this.changeDetector.markForCheck();
  }

  private createFormData(files: any): FormData {

    const formData = new FormData();

    files.forEach(file => formData.append("Document", file));

    if (this.data != null && this.data.length > 0) {
      this.data.forEach(data => formData.append(data.key, data.value));
    }

    return formData;
  }

}
