import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { DocumentListItem } from "../../../shared/document/chase-document-list/document-list-item.model";
import { ChaseDocumentPage } from "./chase-document-page.model";


@Injectable()
export class ChaseDocumentService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private route: ActivatedRoute,
    private readonly automapper: AutomapperService
  ) { }


  // TODO: Are we moving this to a core service?
  getChaseGdFromPath() {
    let id = this.route.snapshot.params.chaseGd;
    if (!id) {
      id = this.route.snapshot.parent.params.chaseGd;
    }

    return id;
  }

  // Get (n) Pages for a Chase
  getChaseDocumentPages(chaseId: number, begPage: number, endPage: number, documentTypeId: number): Observable<ChaseDocumentPage[]> {
    const url =
      `${this.baseApiUrl}chasedocument/page?chaseId=${chaseId}&begPage=${begPage}&endPage=${endPage}&documentTypeId=${documentTypeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ChaseDocumentPage"))
    );
  }

  // Get List of Documents for a Chase which includes document properties and image string of first page
  getDocumentListItems(chaseId: number, maxDocuments: number): Observable<DocumentListItem[]> {
    const url = `${this.baseApiUrl}chasedocument/list?chaseId=${chaseId}&maxRecords=${maxDocuments}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DocumentListItem"))
    );
  }

  getChasePreview(chaseId: number): Observable<DocumentListItem> {
    return this.getDocumentListItems(chaseId, 1).pipe(
      map(items => items[0] || null)
    );
  }
}

