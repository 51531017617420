import { IAutoMapper } from "../interfaces";

export const mapProviderPacketModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProviderPacketModel")
    .forMember("providerPacketId", o => o.providerPacketId)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("filePath", o => o.filePath)
    .forMember("fileDate", o => o.fileDate)
    .forMember("uploadKey", o => o.uploadKey)
    .forMember("fileName", o => o.fileName)
    .forMember("groupName", o => o.groupName)
    .forMember("organizationId", o => o.organizationId);
};
