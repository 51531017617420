import { IAutoMapper } from "../../core/automapper/interfaces";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapMemberCentricChaseSearchResult = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberCentricChase")
    .forMember("chaseId", o => o.chaseId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("status", o => o.reportingStatusName)
    .forMember("sampleCompliance", o => o.sampleComplianceCode)
    .forMember("chaseCompliance", o => o.chaseComplianceCode)
    .forMember("disabled", o => o.disabled)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("workflowStatusId", o => o.workflowStatusId)
    .forMember("memberValidationEntityId", o => o.memberValidationEntityId);
};

export class MemberCentricChase {
  readonly chaseId: number;
  readonly measureCode: string;
  readonly status: string;
  readonly sampleCompliance: string;
  readonly chaseCompliance: string;
  readonly disabled: boolean;
  readonly assignedToUserId?: number;
  readonly workflowStatusId?: number;
  readonly memberValidationEntityId?: number;
  constructor(options: {
    chaseId: number;
    measureCode: string;
    status: string;
    sampleCompliance: string;
    chaseCompliance: string;
    disabled: boolean;
    assignedToUserId?: number;
    workflowStatusId?: number;
    memberValidationEntityId?: number;
  }) {
    this.chaseId = options.chaseId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.status = StringHelper.clean(options.status);
    this.sampleCompliance = StringHelper.clean(options.sampleCompliance);
    this.chaseCompliance = StringHelper.clean(options.chaseCompliance);
    this.disabled = options.disabled;
    this.assignedToUserId = options.assignedToUserId;
    this.workflowStatusId = options.workflowStatusId;
    this.memberValidationEntityId = options.memberValidationEntityId;
  }
}
