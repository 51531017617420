import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { RiskDxDeleteDiagnosis } from "../dx-models/request/risk-dx-delete.modal";
import { RiskDxValidationDiagnosis } from "../dx-models/request/risk-dx-validation-diagnosis.model";
import { RiskDxValidation } from "../dx-models/request/risk-dx-validation.model";
import { RiskDxDiagnosis } from "../dx-models/response/risk-dx-diagnosis.model";
import { RiskDxIcd } from "../dx-models/response/risk-dx-icd.model";
import { RiskDxService } from "../risk-dx.service";

@Component({
  selector: "app-risk-dx-card",
  templateUrl: "./dx-card.component.html",
  styleUrls: ["./dx-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxCardComponent implements OnInit {
  @Input() chaseId: number;
  @Input() isReadOnly: boolean;
  @Input() addNewCoder: boolean;
  @Input() icdGroup: RiskDxIcd;
  @Input() riskChaseState: ChaseDetailState;
  @Input() encounterId: number;
  @Input() masterEveEncounterId: number;
  @Output() icdGroupChange: EventEmitter<RiskDxIcd> = new EventEmitter();
  @Output() hideNetNewHccCard: EventEmitter<boolean> = new EventEmitter();
  addNewDx = false;

  private isSaving = false;
  private sink = new SubSink();

  get shouldDisableControl(): boolean {
    return this.isSaving || this.isReadOnly;
  }

  get isAllDxAddedByCoder(): boolean {
    return this.icdGroup?.diagnoses?.every(item => item.isCoderAddedDiagnosis);
  }

  constructor(private readonly riskService: RiskDxService,
              private readonly riskDxService: RiskDxService,
              private readonly messagingService: MessagingService,
              private readonly changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.sink.add(
      this.riskService.resetValidationButtons$
        .pipe(
          filter(icdGroup => this.icdGroup.hccId === icdGroup.hccId && this.icdGroup.dxType === icdGroup.dxType))
        .subscribe(() => this.emitValidationChange(null))
    );
  }

  addNewDiagnosis(): void {
    this.addNewDx = true;
  }

  removeDx(): void {
    this.addNewDx = false;
    this.hideNetNewHccCard.emit();
  }

  removeDiagnosis($event): void {
    const selectedDx = this.getDeletedDxForValidation($event);

    const deleteRequest = new RiskDxDeleteDiagnosis({
      chaseId: this.riskChaseState.chaseId,
      entityId: selectedDx[0].entityId,
    });
    this.riskService.deleteDiagnosis(deleteRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  saveNewDiagnosis($event): void {
    let selectedDx;
    if ($event.isDxAddedFromNewCard) {
      $event.encounterId = this.encounterId;
      selectedDx = [$event];
    } else {
      this.clearSelectedNlpDxs();
      this.icdGroup.diagnoses.push($event);
      this.setValidationButtonsState();
      this.onValidatingGuard();
      selectedDx = this.getSelectedDxForValidation();
    }
    const validationRequest = this.createRiskValidationRequest(selectedDx);

    this.riskService.saveDiagnosis(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.addCard(false);
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  onValid(): void {
    this.setValidationButtonsState();
    this.onValidatingGuard();
    const selectedDx = this.getSelectedDxForValidation();
    const validationRequest = this.createRiskValidationRequest(selectedDx);
    this.riskService.setValid(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  onInvalid(): void {
    this.setValidationButtonsState();
    this.selectAllDiagnoses();
    const selectedDxs = this.getSelectedDxForInvalidation();
    const validationRequest = this.createRiskValidationRequest(selectedDxs);
    this.riskService.setInvalid(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(false)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      )
      .subscribe();
  }

  private selectAllDiagnoses(): void {
    this.icdGroup.diagnoses = this.icdGroup.diagnoses
      .map(diagnosis => {
        return { ...diagnosis, isSelected: true };
      });
  }

  private getDeletedDxForValidation(selectedItem: RiskDxDiagnosis): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses
      .filter(diagnosis =>
        selectedItem.code === diagnosis.code &&
        selectedItem.dosFrom === diagnosis.dosFrom &&
        selectedItem.providerName === diagnosis.providerName &&
        selectedItem.pageNumber === diagnosis.pageNumber
      );
  }

  private getSelectedDxForValidation(): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses
      .filter(diagnosis => diagnosis.isSelected);
  }

  private getSelectedDxForInvalidation(): RiskDxDiagnosis[] {
    const selectedDX = [this.icdGroup.diagnoses[0]];
    if (selectedDX[0].isAdmin && !selectedDX[0].pageNumber) {
       selectedDX[0].pageNumber = 1;
    }
    return selectedDX;
  }

  private createRiskValidationRequest(riskDxDiagnosis: RiskDxDiagnosis[]): RiskDxValidation {
    const dxActionRequests = riskDxDiagnosis
      .map(diagnosis => new RiskDxValidationDiagnosis({
        diagnosisEndDate: diagnosis.dosThrough,
        diagnosisFromDate: diagnosis.dosFrom,
        diagnosisId: diagnosis.diagnosisId,
        diagnosisPageNumber: diagnosis.pageNumber,
        documentPageId: diagnosis.documentPageId,
        encounterId: this.masterEveEncounterId,
        entityId : diagnosis.entityId,
        hcc: this.icdGroup?.hccId.trim(),
        icdCode: diagnosis.code,
        isCoderAddedDiagnosis: diagnosis.isCoderAddedDiagnosis,
        isEveData: true,
        isSelected: diagnosis.isSelected,
        modelId: this.icdGroup?.modelId,
        providerName: diagnosis.providerName,
        vrcCode: diagnosis.vrcCode,
      }));

    return new RiskDxValidation(this.chaseId, dxActionRequests);
  }

  private setValidationButtonsState(isSaving: boolean = true): void {
    this.isSaving = isSaving;
    this.changeDetector.markForCheck();
  }

  private emitValidationChange(isValid: boolean | null): void {
    if (this.icdGroup) {
      this.icdGroup.isValidated = isValid;
      this.icdGroupChange.emit(this.icdGroup);
    }
  }

  private onValidatingGuard(): void {
    if (!this.icdGroup?.diagnoses?.some(dx => dx.isSelected)) {
      const errorMessage = "Please, select one diagnosis";
      this.messagingService.showToast(errorMessage, SeverityType.ERROR);
      this.setValidationButtonsState(false);
      throw new Error(errorMessage);
    }
  }

  private clearSelectedNlpDxs(): void {
    this.icdGroup?.diagnoses.forEach(dx => {
      if (!dx.isCoderAddedDiagnosis) {
        dx.isSelected = false;
      }
    });
  }
}
