<div class="address-detail-info-container">
  <div class="leftColumn">
    <app-list-summary [summaryItems]="summaryItems"></app-list-summary>
    <div class="provider-header bold grids-display">
      PROVIDERS AT THIS ADDRESS
    </div>

    <app-basic-grid class="grids-display"
                    [configuration]="providerGridConfiguration"
                    [data]="providerGridData"
                    [(selection)]="providerGridSelection"
                    [actionTemplate]="providerGridActionTemplate">

      <ng-template #providerGridActionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
        <pre>Index: {{ i }}</pre>
        <pre>JSON: {{ rowData | json }}</pre>
        <app-button text="Print To Console" (onClick)="printActionArgumentsToConsole(i, rowData); localActionPopover.hide();"></app-button>
      </ng-template>
    </app-basic-grid>


    <div class="grids-display">
      <div class="chase-header header bold">CHASES AT THIS ADDRESS</div>
      <div class="sub-menu__container--address-detail-info">
        <div class="item">
          <a class="link"
             [ngClass]="{'active': selectedTab === 'chases'}"
             (click)="toggleTab('chases')">
            <div class="button">
              <div class="description">Chase View</div>
            </div>
          </a>
        </div>
        <div class="item">
          <a class="link"
             [ngClass]="{'active': selectedTab === 'members'}"
             (click)="toggleTab('members')">
            <div class="button">
              <div class="description">Member View</div>
            </div>
          </a>
        </div>
        <div class="chase-grid-sub-header">
          <div class="show-all-chases-checkbox">
            <p-checkbox
                        label="Show All Chases"
                        [(ngModel)]="nonActionableChasesChecked"
                        binary="true"
                        (onChange)="changeChaseGridData()">
            </p-checkbox>
          </div>
          <div class="header-button upload-button" *ngIf="this.selectedTab === 'chases'">
            <!--TODO : Assign vendor button option will be add in changeRetrievalMethod dropdown.-->
            <!--<app-button text={{assignVendorButtonText}} (onClick)="assignVendor()"></app-button>-->
            <app-button text="Upload" (onClick)="uploadChase()"></app-button>
          </div>
        </div>
      </div>

      <app-pend-modal [(visible)]="isPendModalVisible"
                      (onHide)="closeModal()"
                      (onUpdate)="updateChaseGrid()"
                      [(chases)]="selectedChases"
                      [clinical]="clinical"></app-pend-modal>
      <retrieval-commitment-date
                                 [(visible)]="isCommitmentDateModalVisible"
                                 [updateCommitmentData]="commitDateUpdateModel"
                                 (onUpdate)="onCommitmentDateUpdate($event)"
                                 (onHide)="onCommitmentDateModalHide($event)">
      </retrieval-commitment-date>

      <div class="overviewtab section tab-content"
           [hidden]="this.selectedTab !== 'chases'">
        <app-basic-grid class="grids-display"
                        [configuration]="chaseGridConfiguration"
                        [data]="chaseGridData"
                        [(selection)]="chaseGridSelection"
                        [actionTemplate]="chaseGridactionTemplate">

          <ng-template #chaseGridactionTemplate let-rowData let-localActionPopover="actionPopover">
            <div class="action-template-container">
              <app-button text="Pend Chase" (onClick)="openPendModal(rowData); localActionPopover.hide();"></app-button>
              <app-button text="Fax Request" (onClick)="openFaxModal(rowData); localActionPopover.hide();"></app-button>
              <app-button text="Email Request" (onClick)="openEmailModal(rowData); localActionPopover.hide();"></app-button>
              <app-button text="Print Request" (onClick)="printProvider(rowData); localActionPopover.hide();"></app-button>
              <app-button [text]="moveChaseText" (onClick)="isAddressModalVisible=true;moveChase(rowData); localActionPopover.hide();">
              </app-button>
              <app-button text="Set Commitment Date" (onClick)="openCommitmentDateModal(rowData)"></app-button>
            </div>
          </ng-template>

          <ng-template #chaseIdColumn let-rowData>
            <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
          </ng-template>

        </app-basic-grid>
      </div>
      <div class="overviewtab section tab-content"
           [hidden]="this.selectedTab !== 'members'">
        <app-basic-grid #chaseGrid [configuration]="membersGridConfiguration"
                        [data]="memberGridData"

                        [rowExpandTemplate]="rowExpandTemplate">
          <ng-template #rowExpandTemplate
                       let-rowData>
            <app-basic-grid [configuration]="memberChaseGridConfiguration"
                            [data]="rowData.chaseList">
            </app-basic-grid>
          </ng-template>

        </app-basic-grid>
      </div>
    </div>
    <div class="chase-header bold">DOCUMENT REQUESTS</div>
    <app-document-request-grid [addressId]="addressId"></app-document-request-grid>
  </div>

  <div class="rightColumn timeline-display">
    <div class="header bold">Address Timeline</div>
    <app-timeline [timelineItems]="timelineItems"></app-timeline>
    <div class="showall-timeline">
      <a [href]="showTimelineDetails()">See All</a>
    </div>
  </div>

  <retrieval-address-search [(visible)]="isAddressModalVisible"
                            [callingSource]="addressSearchCallingSource"
                            (onAddressSelection)="onAddressSelectionForChaseMove($event)">
  </retrieval-address-search>

  <retrieval-cover-letter-template
                                   [(visible)]="isTemplateModalVisible"
                                   [request]="contactRequest"
                                   (onApplyCoverTemplate)="sendContactRequest($event)">
  </retrieval-cover-letter-template>

  <app-modal [(visible)]="isConfirmModalVisible">
    {{confirmStatusMessage}}
    <footer>
      <app-button
                  class="header-button"
                  text="VIEW THIS ADDRESS"
                  (onClick)="gotoAddressDetail()"
                  [disabled]="false">
      </app-button>
    </footer>
  </app-modal>

  <app-modal [(visible)]="isMoveVisible" header="Are you sure you want to move these chases?">
    <footer>
      <app-button class="header-button" text="Yes" (onClick)="chaseMoved()" [disabled]="false"></app-button>
      <app-button class="header-button" text="No" (onClick)="closeChaseMovePop()" [disabled]="false"></app-button>
    </footer>
  </app-modal>

  <app-modal [(visible)]="isVendorAssignVisible" header="ASSIGN 3RD PARTY VENDOR">
    <p>You are about to permanently assign a 3rd party vendor to this AID. Select a vendor from the dropdown menu and click "Assign".</p>

    <form [formGroup]="vendorForm" class="ui-md-7 ui-md-offset-2">
      <form-factory [formGroup]="vendorForm"
                    [model]="vendorInput"></form-factory>
    </form>

    <footer class="text-center">
      <app-button text="Cancel" (onClick)="cancelAssignment()"></app-button>
      <app-button *ngIf="isVendorSelected" text="Assign" (onClick)="assignAddressToVendor()"></app-button>
    </footer>

  </app-modal>
</div>
