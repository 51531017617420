import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-retrieval-report-by-chase",
  templateUrl: "./retrieval-report-by-chase.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalReportByChaseComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.RETRIEVAL_REPORT_BY_CHASE;

  constructor() {
    this.filters = ["Projects", "RetrievalType" , "RetrievalDate"];
  }

}
