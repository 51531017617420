<div class="list">
  <div class="list__item" *ngFor="let item of items; trackBy: trackByIndex">
    <div class="list__item--key">{{item.key}}</div>
    <div class="list__item--value" title="{{item.value}}">
      <a *ngIf="item.hasUrl" [routerLink]="item.url">{{item.value}}</a>
      {{!item.hasUrl ? item.value : ""}}
    </div>
  </div>
  <div class="clearfix"></div>
</div>
