import { IAutoMapper } from "../../../core/automapper/interfaces";
import { DateHelper } from "../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../utilities/contracts/helper-types";

export const mapDischargeCalculatedDates = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DischargeCalculatedDates")
    .forMember("reconcilationFromDate", o => DateHelper.removeTime(o.reconcilationFromDate))
    .forMember("reconcilationThruDate", o => DateHelper.removeTime(o.reconcilationThruDate));
};

export interface IDischargeCalculatedDatesOptions {
  reconcilationFromDate?: dateTypes;
  reconcilationThruDate?: dateTypes;
}

export class DischargeCalculatedDates {
  reconcilationFromDate: Date;
  reconcilationThruDate: Date;

  constructor(options: IDischargeCalculatedDatesOptions = {}) {
    this.reconcilationFromDate = DateHelper.create(options.reconcilationFromDate, null);
    this.reconcilationThruDate = DateHelper.create(options.reconcilationThruDate, null);
  }
}
