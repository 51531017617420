import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ListItem } from "../../../shared/list/list-item";
import { PendStatus } from "../../../shared/pend/pend-status.enum";
import { WorkflowStatusDb } from "../workflow/workflow-status-db.enum";
import { GapSearchRequest } from "./gap-search-request-model";

@Injectable({
  providedIn: "root",
})
export class GapService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  gapSearchUrl(): string {
    return `${this.baseApiUrl}gap/query`;
  }

  gapSearch(searchRequest: GapSearchRequest): Observable<any> {
    const url = this.gapSearchUrl();

    return this.http.post(url, searchRequest);
  }

  disabledRowsFilterForGapOutReach = (rowData: any): boolean => {
    // Disable gap chases for which project isn't configured as Gap or Project configured as Gap but
    // Gap chase in "Chart collection", "Waiting for chart" workflow status or which are moved or request made. Make them non - actionable.
    return (!rowData.gapProviderOutreachEnabled)
      || (rowData.gapProviderOutreachEnabled &&
        ((rowData.workflowStatusId !== WorkflowStatusDb.ChartCollection && rowData.workflowStatusId !== WorkflowStatusDb.WaitingForChart)
          || (rowData.pendCode === "PC900"
            && (rowData.pendStatusId === PendStatus.New
              || rowData.pendStatusId === PendStatus.InProgress
              || rowData.pendStatusId === PendStatus.Closed))));
  }

  getGapStatuses(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}gap/statuses`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

}
