<div
  *ngIf="!isAdminDiagnosis"
  class="button button__delete"
  (click)="showDeleteDiagnosisModal($event)">×</div>

<app-confirmation-modal 
  class="delete-diagnosis-modal" 
  [(visible)]="isConfirmDeleteVisible"
  header="WARNING"
  acceptText="DELETE"
  rejectText="CANCEL" 
  (onYes)="deleteDiagnosis()">
  If you disagree with the BOT diagnosis, the diagnosis will be deleted.<br /><br />
  <b>Are you sure you want to delete this BOT suggested diagnosis?</b>
</app-confirmation-modal>