import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ProviderPacketItem } from "../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ChaseMoveModel } from "./chase-move.model";
import { ReportingStatusModel } from "./Reporting-status.model";

@Injectable({
  providedIn: "root",
})
export class ChaseQueryService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  printProviderPacket(providerPacketItem: ProviderPacketItem): Observable<string> {
    const url = `${this.baseApiUrl}pend/providerpacket`;
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post(url, providerPacketItem, { headers }).pipe(
      map((response: any) => response as string)
    );
  }

  getReportingStatusesList(bulkOutReach?: boolean): Observable<ReportingStatusModel[]> {
    const url = `${this.baseApiUrl}chase/reportingstatuses?bulkOutReach=${bulkOutReach}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ReportingStatusModel"))
    );
  }

  chaseMove(chaseMoveModel: ChaseMoveModel): Observable<string> {
    const url = `${this.baseApiUrl}chase/move`;
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, chaseMoveModel, { headers }).pipe(
      map((response: any) => response as string)
    );
  }
}
