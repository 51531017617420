export class DropdownListItem {
  readonly key: string;
  readonly value: string;

  constructor(options: {
    key: string;
    value: string;

  }

  ) {

    this.key = options.key;
    this.value = options.value;

  }
}
