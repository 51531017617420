import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})

export class DocumentReviewerStateService {
    private url = new BehaviorSubject<string>(null);

    setUrl(data: string) {
        this.url.next(data);
    }

    clearUrl() {
        this.url.next(null);
    }

    getUrl(): Observable<string> {
        return this.url.asObservable();
    }
}
