import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-back-button",
  template: `<div class="back-button" (click)="goBack()"><app-icon iconName="arrow-left"></app-icon></div>`,
  styleUrls: ["./back-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() text = "";
  @Input() moveToPreviousUrl  = true;
  iconName = "arrow-left";
  @Output() onBackClick = new EventEmitter<string>(true);

  constructor(public location: Location) { }

  goBack() {
    if (this.moveToPreviousUrl) {
      this.location.back();
    }
    this.onBackClick.emit();
  }
}
