import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { TextboxType } from "./textbox-type.enum";
import { TextboxComponent } from "./textbox.component";

export interface ITextboxOptions extends IDynamicInputOptions {
  type?: TextboxType;
  dataType?: string;
  placeholder?: string;
  autocomplete?: string;
  title?: string;
  classOverride?: string;
  maxlength?: number;
  tooltip?: string;
  pageNumber?: number;
  labelPosition?: string;
}

export class Textbox extends DynamicInput implements ITextboxOptions {
  type: TextboxType;
  dataType: string;
  placeholder: string;
  autocomplete: string;
  title: string;
  classOverride: string;
  maxlength: number;
  tooltip: string;
  pageNumber?: number;
  labelPosition?: string;
  readonly controlType = "textbox";
  readonly componentType = TextboxComponent;

  constructor(options: ITextboxOptions = {}) {
    super(options);
    this.type = options.type || TextboxType.TEXT;
    this.dataType = options.dataType || "";
    this.placeholder = options.placeholder || "";
    this.autocomplete = options.autocomplete || "off";
    this.title = options.title || "";
    this.classOverride = options.classOverride || "";
    this.maxlength = options.maxlength;
    this.tooltip = options.tooltip;
    this.pageNumber = options.pageNumber;
    this.labelPosition = options.labelPosition;
  }
}
