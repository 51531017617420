import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { ListItem } from "../../../../../shared/list/list-item";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { ArchiveChaseDetail } from "../archive-chase-detail.model";
import { ArchiveChaseDetailService } from "../archive-chase-detail.service";

@Component({
  selector: "member-archive-chase-detail-header",
  templateUrl: "./archive-chase-detail-header.component.html",
  styleUrls: ["./archive-chase-detail-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveChaseDetailHeaderComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  chaseId: number;
  archiveChaseDetail: ArchiveChaseDetail;

  constructor(
    private archiveChaseDetailService: ArchiveChaseDetailService,
    private readonly changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.chaseId = +params.get("chaseGd");
    });

    this.sink.add(
      this.archiveChaseDetailService.state
        .subscribe(state => {
          this.archiveChaseDetail = state;
          this.changeDetector.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get memberItems(): List<ListItem> {
    const memberKeys = ["Member:", "DOB:", "Age:", "Gender:", "Location:", "Member ID:", "Member Key:", "Project:", "Measure:", "Client Chase Key:"];

    const list = [
      new ListItem({
        key: "Member:",
        value: this.archiveChaseDetail.memberName,
      }),
      new ListItem({
        key: "DOB:",
        value: this.archiveChaseDetail.dateOfBirth ? DateHelper.format(this.archiveChaseDetail.dateOfBirth.split("T")[0]) : "",
      }),
      new ListItem({
        key: "Age:",
        value: this.archiveChaseDetail.memberAge ? this.archiveChaseDetail.memberAge.toString() : "",
      }),
      new ListItem({
        key: "Gender:",
        value: this.archiveChaseDetail.gender,
      }),
      new ListItem({
        key: "Location:",
        value: this.archiveChaseDetail.memberLocation,
      }),
      new ListItem({
        key: "Member ID:",
        value: this.archiveChaseDetail.memberId ? this.archiveChaseDetail.memberId.toString() : "",
      }),
      new ListItem({
        key: "Member Key:",
        value: this.archiveChaseDetail.memberSourceAliasId,
      }),
      new ListItem({
        key: "Project:",
        value: this.archiveChaseDetail.projectName,
      }),
      new ListItem({
        key: "Measure:",
        value: this.archiveChaseDetail.measureCode,
      }),
      new ListItem({
        key: "Client Chase Key:",
        value: this.archiveChaseDetail.chaseSourceAliasId ? this.archiveChaseDetail.chaseSourceAliasId.toString() : "",
      }),
    ];

    const memberItems = list
      .filter(item => memberKeys.includes(item.key))
      .sort((a, b) => {
        const aValue = memberKeys.findIndex(key => key === a.key);
        const bValue = memberKeys.findIndex(key => key === b.key);
        return aValue - bValue;
      });

    return List(memberItems);
  }
}
