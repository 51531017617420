import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../../core/navigation/parameter.service";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { Appointment } from "../../../appointment/appointment.model";
import { AppointmentService } from "../../../appointment/appointment.service";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AppointmentsSearchRequest } from "./address-detail-info-appt.model";

@Component({
  selector: "retrieval-address-detail-info-appointments",
  templateUrl: "./address-detail-info-appt.component.html",
  styleUrls: ["./address-detail-info-appt.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoAppointmentsComponent implements OnInit, OnDestroy, OnChanges {
  private sink = new SubSink();
  private readonly ALL_RECORDS = 2147483647;
  addressId: number;
  appointmentsGridConfiguration: GridConfiguration;
  appointmentsGridData: any[] = [];
  isScheduleAppointmentModalVisible = false;
  isDeleteAppointmentModalVisible = false;
  retrievalType = "";
  appointment: Appointment;
  @Input() navigatedAddressId: number;

  constructor(
    private appointmentService: AppointmentService,
    private addressDetailStateService: AddressDetailStateService,
    private automapper: AutomapperService,
    private messagingService: MessagingService,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private parameterService: ParameterService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.addressId = this.parameterService.getNumberNormal("addressId", null);
    this.initializeData();
  }

  initializeData(): void {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        if (state.hasMasterDocumentSourceId) {
          this.retrievalType = state.documentSourceTypeName;
          this.appointment = this.automapper.map("AddressDetailState", "Appointment", state);
        }
      })
    );
    this.createGrids();
    this.fetchAppointments();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navigatedAddressId.currentValue) {
      this.addressId = changes.navigatedAddressId.currentValue;
      this.initializeData();
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get appointmentId(): number {
    return this.appointment.appointmentId;
  }

  appointmentsSaved(): void {
    this.fetchAppointments();
    this.fetchTimelineItems();
  }

  fetchAppointments(): void {
    const start = 1;
    const end = this.ALL_RECORDS;
    const gridSearchRequest = new AppointmentsSearchRequest(this.addressId, null, null, null, start, end);
    this.appointmentService
      .getForAddressDetails(gridSearchRequest)
      .subscribe(items => {
        this.appointmentsGridData = items;
        this.cd.markForCheck();
      });
  }

  clickedEditAppointment(event: MouseEvent, rowData: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.appointment = this.automapper.map("EditRowData", "Appointment", rowData);
    this.showScheduleAppointmentModal();
  }

  showScheduleAppointmentModal(event: MouseEvent = null): void {
    if (event != null) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.isScheduleAppointmentModalVisible = true;
    this.cd.markForCheck();
  }

  clickedDeleteAppointment(event: MouseEvent, rowData: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.appointment = this.automapper.map("EditRowData", "Appointment", rowData);
    this.isDeleteAppointmentModalVisible = true;
    this.cd.markForCheck();
  }

  deleteAppointment(): void {
    this.appointmentService.delete(this.addressId, this.appointmentId).subscribe({
      next: () => {
        this.messagingService.showToast("Appointment cancelled successfully.", SeverityType.SUCCESS);
        this.fetchAppointments();
        this.fetchTimelineItems();
        this.resetAppointment();
        this.isScheduleAppointmentModalVisible = false;
        this.cd.markForCheck();
      },
      error: () => this.messagingService.showToast("Error while canceling Appointment, please try again.", SeverityType.ERROR),
    });
  }

  resetAppointment(): void {
    this.appointment = new Appointment({
      masterDocumentSourceId: this.appointment.masterDocumentSourceId,
      masterDocumentSourceName: this.appointment.masterDocumentSourceName,
    });
  }

  private createGrids(): void {
    this.appointmentsGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "appointmentStartTime", header: "Start Date" }),
        new GridColumnDefinition({ field: "appointmentEndTime", header: "End Date" }),
        new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
        new GridColumnDefinition({ field: "chaseCount", header: "Chases" }),
        new GridColumnDefinition({ field: "appointmentStatus", header: "Status" }),
      ],
      pageSize: 10,
      selectionMode: "multiple",
      showActionColumn: true,
      pageSizeOptions: [10, 25, 50, 100],
    });
    this.cd.markForCheck();
  }

  private fetchTimelineItems(): void {
    this.addressTimelineService
      .get(this.addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }
}
