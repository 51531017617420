import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { UserModel } from "../../../../../core/user/user-model";
import { WorkflowStatusModel } from "../../chase-query/workflow-status.model";
import { AddressBulkUpdate } from "../address-bulk-update.model";
import { BulkUpdate } from "../bulk-update.model";
import { BulkChasesAssignmentRequest } from "../model/bulk-chase-assign.model";

@Injectable({
  providedIn: "root",
})
export class BulkUpdateChaseService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getWorkflowStatusesList(): Observable<WorkflowStatusModel[]> {
    const url = `${this.baseApiUrl}chase/workflowstatuses`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "WorkflowStatusModel"))
    );
  }

  getPendCodes(organizationId: number): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}Pend/GetPendDropdowns?isClinical=0&organizationId=${organizationId}`; // Revise

    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getUsersList(userBulkUpdate: BulkChasesAssignmentRequest): Observable<UserModel[]> {
    const url = `${this.baseApiUrl}bulkupdate/user/list`;

    return this.http.post(url, userBulkUpdate) as Observable<UserModel[]>;
  }

  finishBulkUpdate(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/chases/movereassign`;

    return this.http.post(url, bulkUpdate, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {
            return 0;
          }
        })
      );
  }

  finishActivateBulkUpdate(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/validate/chases/activate`;

    return this.http.post(url, bulkUpdate, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {
            return 0;
          }
        })
      );
  }
  finishDueOrRequestedOrCommitmentDateBulkUpdate(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/validate/chases/thirdPartyChase`;
    return this.http.post(url, bulkUpdate) as Observable<null>;
  }
  finishBulkUpdateChases(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/validate/pendchases`;
    return this.http.post(url, bulkUpdate, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {
            return 0;
          }
        })
      );
  }

  finishBulkUpdatePend(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/validate/pend`;
    return this.http.post(url, bulkUpdate, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {
            return 0;
          }
        })
      );
  }

  finishAddressBulkUpdate(bulkUpdate: AddressBulkUpdate): Observable<null> {
    const url = `${this.baseApiUrl}address/bulkupdate/validate/process`;

    return this.http.post(url, bulkUpdate) as Observable<null>;
  }

  finishCopyOverChartBulkUpdate(bulkUpdate: BulkUpdate): Observable<number> {
    const url = `${this.baseApiUrl}bulkupdate/validate/chase/copyoverchartchase`;

    return this.http.post(url, bulkUpdate, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {
            return 0;
          }
        })
      );
  }

  getBulkValidationData(urlString: string, data: any) {
    const url = `${this.baseApiUrl}${urlString}`;

    return this.http.post(url, data) as Observable<null>;
  }
}
