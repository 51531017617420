<div class="add-tag-error" *ngIf="hasValidationError">Add Tag is required.</div>
<div class="tag__content--view" *ngIf="hasAddTag">
  <div [ngClass]="ui-chips">
    <div class="left_panel">
      <ul class="ui-inputtext">
        <li>
          <ul class="ui-inputtext">
            <li #token *ngFor="let item of selectedTags; let i = index; trackBy: trackByIndex"
                class="ui-chips-token" (click)="onItemClick()">
              <span title='{{getCharactersCount(item.text)}}' class="ui-chips-token-label"
                    [ngStyle]="{'background-color':item.extra.color}">{{field ? chipItemsData(item,field) : item.text}}</span>
              <app-icon *ngIf="item.extra.isSelected" [iconName]="getTagIcon('times')"
                        [iconStyle]="getTagIcon('times')" (click)="removeItem(i)"></app-icon>
              <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
            </li>
          </ul>
        </li>
        <li class="tags-list" [ngClass]="getClassForSelect()">
          <div [ngClass]="getClassForPlusIcon()" (click)="addTags('true')" *ngIf="isTagAvailable">
            <app-icon [iconName]="getTagIcon('add')" [iconStyle]="getTagIcon('add')"></app-icon>
          </div>
          <div [ngClass]="getClassForNewTagIcon()" (click)="addTags('true')" *ngIf="isTagUnAvailable">
            <app-icon [iconName]="getTagIcon('add')" [iconStyle]="getTagIcon('add')"></app-icon>
            <span class="tag-list__add-label">Add Tag</span>
          </div>
          <div class="right_panel">
            <ul *ngIf="isVisible" class="add-tag">
              <li class="ui-chips-input-token select-bottom" [ngClass]="lowerBoundClass">
                <form [formGroup]="tagFormGroup">
                  <form-factory class="tag__inputform" [formGroup]="tagFormGroup" [model]="addTagInput"
                                (onInput)="onAddTags($event)" (keyup.enter)="onTagEnter()"  (keyup)="onAddTagInputKeyUp($event)">
                  </form-factory>
                  <app-color-palette-manage [isCreateTagTemplateVisible]="isCreateTagTemplateVisible"
                                            (selectedColor)="onSelectedColor($event)" [hasTagName]="tagName"></app-color-palette-manage>
                </form>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="tag__content--input" *ngIf="hasCurrentTag">
      <label class="tag__content--label">Current Tag</label>
      <form-factory class="tag__inputform" [formGroup]="tagFormGroup" [model]="tagInput" (onInput)="onTagInput($event)" (keyup)="onTagInputKeyUp($event)">
      </form-factory>
    </div>
    <div class="tag__content--input" *ngIf="removeTag">
      <form-factory class="tag__inputform" [formGroup]="tagFormGroup" [model]="removeTagInput" (onInput)="onTagInput($event)" (keyup)="onRemoveTagInputKeyUp($event)">
      </form-factory>
    </div>
    <div class="tag__content--input" *ngIf="replaceTag">
      <label class="tag__content--label">Replacement Tag</label>
      <form-factory class="tag__inputform" [formGroup]="tagFormGroup" [model]="replaceTagInput" (onInput)="onReplaceTagInput($event)" (keyup)="onReplaceTagInputKeyUp($event)">
      </form-factory>
    </div>
  </div>
</div>
