<div class="reporting-address-container">
  <div class="header bold">Scheduling Status</div>
  <div class="header bold rightAlign">{{this.totalChasesMessage}}</div>
  <app-server-grid [configuration]="gridConfigurationModel"
                   [request]="searchRequest"
                   [refresh]="gridRefresh"
                   [(selection)]="serverGridSelection"
                   [actionTemplate]="actionTemplate"
                   [filterTemplate]="filterForm">

    <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
      <div class="action-template-container">
        <app-button *ngFor="let actionItem of actionItems; trackBy: trackByIndex" [text]="actionItem.actionText" (onClick)="addressQueueFunctions[actionItem.actionFunctionName](rowData); localActionPopover.hide();"></app-button>
      </div>
    </ng-template>

    <ng-template #filterForm let-form>
      <app-tab-menu orientation="left">

        <app-tab-panel header="ID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form"
                          [model]="addressIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Project(s)">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form"
                          [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>

      </app-tab-menu>
    </ng-template>

  </app-server-grid>

  <app-modal [(visible)]="isAssignModelVisible" header="Assigned To">
    <form>
      <div class="ui-g ui-fluid basic-filter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <label>User</label>
          <p-autoComplete [dropdown]="true"
                          [forceSelection]="true"
                          field="label"
                          dataKey="value"
                          [suggestions]="assignedUserListFiltered"
                          (completeMethod)="filterOptions($event)"
                          [(ngModel)]="selectedUser"
                          appendTo="body"
                          placeholder="Select User"
                          [ngModelOptions]="{standalone: true}"></p-autoComplete>
        </div>
      </div>
    </form>
    <footer>
      <app-button class="header-button" text="ASSIGN" (onClick)="onAssign()" [disabled]="!hasSelectedUser"></app-button>
    </footer>
  </app-modal>

</div>
