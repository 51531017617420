<div class="grid-header bold">CONTACT LOG</div>

<div class="contact-log-container">
  <div class="header bold rightAlign">{{this.totalChasesMessage}}</div>
  <app-basic-grid [configuration]="contactLogGridConfiguration"
                  [data]="contactLogGridData"
                  [(selection)]="contactLogGridSelection">
  </app-basic-grid>

</div>
