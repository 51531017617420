import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { ConfirmNoPageNumber } from "./confirm-no-page-number.model";

@Component({
  selector: "app-confirm-no-page-number",
  templateUrl: "./confirm-no-page-number.component.html",
  styleUrls: [
    "../dropdown/dropdown.component.scss",
    "../textarea/textarea.component.scss",
    "../textbox/textbox.component.scss",
    "../confirm/confirm.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ConfirmNoPageNumberComponent extends DynamicControlDirective<ConfirmNoPageNumber>implements OnInit, OnDestroy {
  private sink = new SubSink();

  selectedItem: string;
  validationOptions: SelectableInput[];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() =>
        this.changeDetector.markForCheck()),

      this.formService.updateDom.subscribe(() =>
        this.changeDetector.markForCheck())
    );

    this.validationOptions = [
      new SelectableInput({ text: "Yes", value: 1 }),
      new SelectableInput({ text: "No", value: 2 }),
      new SelectableInput({ text: "Yes - With Transformation", value: 3 }),
    ];

    if (this.model == null) {
      this.model = {} as any;
    }

    if (this.model.saveInfo == null) {
      this.model.saveInfo = {} as any;
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): any {
    // this.changeDetector.markForCheck();

    // let classes = "input";

    // if (this.model.isAdmin) {
    //   classes = `${classes} input__admin`;
    //   return classes;
    // }

    // if (this.model.isChanged) {
    //   const className = `input__changed-${this.model.workflowStatusName}`;
    //   classes = `${classes} ${className}`;
    // }

    return this.getClasses("confirm-no-page-number");
  }

  get hiddenNote(): boolean {
    return this.hidden || this.model.saveInfo == null || this.model.saveInfo.validationId == null || this.model.saveInfo.validationId === 1;
  }

  validationChange(): void {
    if (StringHelper.isAvailable(this.model.adminValue) && this.model.saveInfo.validationId === 1) {
      this.control.setValue(this.model.adminValue);
    }
  }

  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }

}
