<th *ngIf="hasRowExpansion" style="width:1.5rem">
  <!-- Row Expansion -->
</th>

<th *ngIf="configuration.hasSelectionMode && configuration.showSelectionControl" style="width: 3em">
  <p-tableHeaderCheckbox *ngIf="displayHeaderCheckbox" [disabled]="configuration.isHeaderCheckBoxDisabled">
  </p-tableHeaderCheckbox>
</th>

<th
  *ngFor="let col of filteredColumns; trackBy: trackByIndex"
  pResizableColumn
  [pSortableColumn]="col.columnField"
  [title]="col.header"
  [ngStyle]="{ 'width': col.width }">

  <div *ngIf="configuration.showGridHeader" class="header-container">
    <span class="header-container__text">{{ col.header }}</span>
    <p-sortIcon [field]="col.columnField"></p-sortIcon>
  </div>

</th>

<th *ngIf="hasActionButton" style="width: 70px">
  <!-- Action -->
</th>