export class MoveBackChasePageItem {
  readonly documentQueueId: number;
  readonly chaseId: number;
  readonly pageNumber: number;
  readonly documentPageNumber: number;

  constructor(options: {
    documentQueueId: number;
    chaseId: number;
    pageNumber: number;
    documentPageNumber: number;
  }) {
    this.documentQueueId = options.documentQueueId;
    this.chaseId = options.chaseId;
    this.pageNumber = options.pageNumber;
    this.documentPageNumber = options.documentPageNumber;
  }
}
