import { Injectable } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { StorageTypes } from "./storage-type.enum";
import { StorageValueHelper } from "./storage-value-helper.model";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  put(key: string, value: StorageTypes): void {
    StringHelper.throwIsAvailableError(key, "key");

    const stringValue = StorageValueHelper.create(value);
    window.sessionStorage.setItem(key, stringValue);
  }

  get<T>(key: string, defaultValue: T): T {
    StringHelper.throwIsAvailableError(key, "key");

    const value = window.sessionStorage.getItem(key);
    const realValue = StringHelper.isAvailable(value) ? StorageValueHelper.get(value) : defaultValue;
    return realValue as any;
  }

  delete(key: string = ""): void {
    window.sessionStorage.removeItem(key);
  }

  clear(): void {
    window.sessionStorage.clear();
  }

  hasSessionKey(sessionKey: string): boolean {
    const key = sessionKey;
    const filter = this.get<any>(key, null);
    const hasKey = filter != null;
    return hasKey;
  }
}
