import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { Provider } from "../../provider/provider.model";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { MasterProvider } from "./national-provider.model";

@Injectable({
  providedIn: "root",
})

export class NationalProviderGridService {
  readonly GLOBAL_SEARCH_KEY = "globalSearchText";
  readonly URL = `${this.baseApiUrl}provider/master/search`;
  updateDom = new Subject();
  configuration: GridConfiguration;
  data: MasterProvider[] = [];
  providerSearchText = "";
  providerSearched = false;
  lastUsedAid: number;
  aidChanged: boolean;

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) {
    this.clear();
  }

  clear(): void {
    this.data = this.addUnknownProvider([]);
  }

  search(search: string): void {
    const cleanSearch = StringHelper.clean(search);
    if (cleanSearch.length >= 3) {
      this.getMasterProviders(search)
        .pipe(map(this.addUnknownProvider.bind(this)))
        .subscribe((data: MasterProvider[]) => {
          this.data = data;
          this.updateDom.next();
        });
    }
  }

  getMasterProviders(search: string): Observable<MasterProvider[]> {
    const cleanSearch = StringHelper.clean(search);
    if (cleanSearch.length >= 3) {
      return this.http.post(this.URL, { [this.GLOBAL_SEARCH_KEY]: cleanSearch })
        .pipe(map(this.automapper.curryMany("default", "MasterProvider")));
    } else {
      return of([]);
    }
  }

  addServiceProvider(chaseId: number, masterProvider: MasterProvider): Observable<Provider[]> {
    const url = `${this.baseApiUrl}provider/service/add`;
    const serviceProvider = {
      ...masterProvider,
      chaseId,
    };
    return this.http.post(url, serviceProvider)
      .pipe(map(this.automapper.curryMany("default", "Provider")));
  }

  private addUnknownProvider(providers: MasterProvider[]): MasterProvider[] {
    providers.unshift(new MasterProvider({
      providerFirstName: "Unknown",
      providerLastName: "Provider",
    }));
    return providers;
  }

  providerToolSearch(search: string): void {
    const cleanSearch = StringHelper.clean(search);
    if (cleanSearch.length >= 3) {
      this.getMasterProviders(search)
        .subscribe((data: MasterProvider[]) => {
          this.data = data;
          this.updateDom.next();
        });
    }
  }
}
