import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-hcc-summary-by-project",
  templateUrl: "./hcc-summary-by-project.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccSummaryByProjectComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.HCC_SUMMARY_BY_PROJECT;

    constructor() {
        this.filters = ["Projects", "Hcc", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
