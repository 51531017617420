import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { AddressDetailTimelineService } from "../../../psr/address-detail/address-detail-timeline/address-detail-timeline.service";
import { RetrievalTimelineItem } from "../../../retrieval-timeline-item.model";
import { FtDetailTimelineService } from "./ft-detail-timeline.service";

@Component({
  selector: "retrieval-ft-detail-timeline",
  template: `<app-timeline-detail [timeline]="timelineItems"></app-timeline-detail>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDetailTimelineComponent implements OnInit {
  timelineItems = List<RetrievalTimelineItem>();

  constructor(
    private service: AddressDetailTimelineService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
      this.getTimelines();
    }

  private getTimelines(): void {
        this.service.getAddressTimelineActivityItems().subscribe(timelines => {
            const groupedTimelines = this.mapToGroupedTimelines(timelines);
            this.timelineItems = List(groupedTimelines);
            this.changeDetector.markForCheck();
        });
    }

  private mapToGroupedTimelines(timelines: TimelineItem[]): RetrievalTimelineItem[] {
        const timelineList = timelines.reduce((timeLineGroup, timeLineItem) => {
            const specificDay = DateHelper.format(timeLineItem.timestamp);
            timeLineGroup[specificDay] = timeLineGroup[specificDay] || [];
            timeLineGroup[specificDay].push(timeLineItem);
            return timeLineGroup;
        },                                    {});

        const groupedTimelines = Object.keys(timelineList).map(specificDay => {
            return new RetrievalTimelineItem({
                specificDay,
                timelineGroup: timelineList[specificDay],
            });
        });
        return groupedTimelines;
    }
}
