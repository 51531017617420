import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { interceptorskipheader } from "../../../core/interceptor/loader.interceptor";
import { JobQueueItem } from "./jobqueue-item.model";
import { JobQueueUnRead } from "./jobqueue-unread.model";
import { MyJobsRequest } from "./myjobs-request.model";

@Injectable({
  providedIn: "root",
})
export class JobsqueueService {
  private pNewJobEvent = new BehaviorSubject<JobQueueItem[]>([]);
  newJobEvent = this.pNewJobEvent.asObservable();

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getMyJobs(myJobsRequest: MyJobsRequest): Observable<JobQueueItem[]> {
    const url = `${this.baseApiUrl}statemachine/myjobs`;

    return this.http.post(url, myJobsRequest).pipe(
      map(this.automapper.curryMany("default", "JobQueueItem"))
    );
  }

  getMyJobsUnReadCount(): Observable<JobQueueUnRead> {
    const url = `${this.baseApiUrl}statemachine/myjobs/unread`;

    const headers = new HttpHeaders().set(interceptorskipheader, "");

    return this.http.get(url, { headers }).pipe(
      map(this.automapper.curry("default", "JobQueueUnRead"))
      );
  }

  updateMyJobsUnReadCount(): Observable<number> {
    const url = `${this.baseApiUrl}statemachine/myjobs/markasread`;

    const headers = new HttpHeaders().set(interceptorskipheader, "");

    return this.http
      .post(url, null, { observe: "response", headers })
      .pipe(
        map((response: HttpResponse<any>): number =>
          response.ok ? response.body : 0));
  }

  notifyNewJobEvent(jobItems: JobQueueItem[]) {
    this.pNewJobEvent.next(jobItems);
  }
}
