export class ChaseDocumentPage {
  readonly chaseId: number;
  readonly chaseDocumentPageId: number;
  readonly documentTypeId: number;
  readonly page: number;
  readonly image: string;

  constructor(options: {
    chaseId: number;
    chaseDocumentPageId: number;
    documentTypeId: number;
    page: number;
    image: string;
  }) {
    this.chaseId = options.chaseId;
    this.chaseDocumentPageId = options.chaseDocumentPageId;
    this.documentTypeId = options.documentTypeId;
    this.page = options.page;
    this.image = options.image;
  }
}
