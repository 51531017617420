import { StringHelper } from "../contracts/string-helper";

/* tslint:disable:only-arrow-functions */
export function getClassName(constructor: any): string {
  const className = constructor.name || (constructor.constructor || {}).name || "";
  return className;
}

export function getPropertyType(propertyObject: any): string {
  if (propertyObject == null) {
    return "Property";
  } else if (typeof propertyObject.value === "function") {
    return "Method";
  } else if (typeof propertyObject.get === "function") {
    return "Getter";
  } else if (typeof propertyObject.set === "function") {
    return "Setter";
  }
}

export function obsolete(replaceWith?: string): any {
  return (constructor: any, propertyName: string, propertyObject: any): any => {
    const className = getClassName(constructor);
    const propertyType = getPropertyType(propertyObject);
    const base = StringHelper.isAvailable(propertyName) ? `${propertyType} '${propertyName}' in class '${className}' is obsolete.` : `Class '${className}' is obsolete.`;
    const final = StringHelper.isAvailable(replaceWith) ? `${base} Please use '${replaceWith}' instead.` : base;
    console.warn(final);
  };
}
/* tslint:enable:only-arrow-functions */
