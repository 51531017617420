import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ChaseDetailAuditLog } from "./chase-detail-audit-log.model";

@Injectable({
  providedIn: "root",
})
export class ChaseDetailAuditLogService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private automapper: AutomapperService,
    private http: HttpClient
  ) { }

  getAuditLogDetails(chaseId: number): Observable<ChaseDetailAuditLog> {
    const url = `${this.baseApiUrl}chase/auditlog?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ChaseDetailAuditLog"))
    );
  }
}
