import { StringHelper } from "../../utilities/contracts/string-helper";

export class ListItem {
  readonly key: string;
  readonly value: string;
  readonly url: string;

  constructor(options: {
    key: string;
    value: string;
    url?: string;
  }) {
    StringHelper.throwIsAvailableError(options.key, "key");

    this.key = this.clean(options.key);
    this.value = this.clean(options.value);
    this.url = this.clean(options.url);
  }

  get hasUrl(): boolean {
    return StringHelper.isAvailable(this.url);
  }

  private clean(value: string): string {
    return (value || "").trim();
  }


}
