import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, PartialObserver } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { ActionButton } from "../action-button/action-button.model";

@Injectable({
  providedIn: "root",
})
export class DevControllerService {
  controls = new BehaviorSubject<ActionButton[]>([]);


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) { }


  clear(): void {
    this.controls.next([
      new ActionButton({
        name: "Print User",
        action: () => console.log(this.authService.user),
      }),
    ]);
  }

  push(item: ActionButton[]): void {
    if (!environment.production) {
      const items = this.controls.getValue();
      items.push(...item);
      this.controls.next(items);
    }
  }
}
