import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { distinctUntilKeyChanged, filter } from "rxjs/operators";
import { SubSink } from "subsink";
import { ParameterService } from "../../core/navigation/parameter.service";
import { DocumentPageService } from "../../shared/document/document-page.service";
import { MenuService } from "../../shared/menu/menu.service";
import { RegExHelper } from "../../utilities/reg-Ex-Helper";
import { PlatformService } from "../platform.service";
import { Annotation } from "./annotations/annotation.model";
import { AnnotationService } from "./annotations/annotation.service";
import { Widget } from "./widget.enum";

interface IWidgetApps {
  description: string;
  icon: string;
  name: string;
  show?: boolean;
}

@Component({
  selector: "platform-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WidgetComponent implements OnInit, OnDestroy {

  private sink = new SubSink();
  private currentUrl: string = null;
  isChat = false;
  private chaseIdSubscription: Subscription;
  isAnnotation = false;
  isDirectory = false;
  isShowWidgetPanel = false;
  selectedPanel: string;
  chaseId: number;
  page: number;
  documentPageId: number;
  urlForAnnotationRegex = RegExHelper.validAnnotationPattern;
  getAnnotations$: Subscription;
  annotationsCount = 0;

  widgetApps: IWidgetApps[];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private platformService: PlatformService,
    public menuService: MenuService,
    private parameterService: ParameterService,
    private pageService: DocumentPageService,
    public router: Router,
    private annotationService: AnnotationService
  ) { }

  get annotationNumberToShow(): number {
      return this.annotationsCount < 10 ? this.annotationsCount : 9;
    }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    if (this.isValidUrl(this.urlForAnnotationRegex, this.currentUrl)) {
      this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
    }

    if (!this.chaseId) {
      this.chaseIdSubscription = this.annotationService.getChaseIdObservable$()
        .subscribe(newChaseId => (this.chaseId = newChaseId));
      this.sink.add(this.chaseIdSubscription);
    }
    this.widgetApps = [
      { name: Widget.DIRECTORY, description: "Directory", icon: "address-book", show: false },
      { name: Widget.CHAT, description: "Conversations", icon: "comments-alt", show: false },
      { name: Widget.ANNOTATIONS, description: "Annotations", icon: "notes-medical", show: true },
    ];

    this.getAnnotations();

    this.sink.add(
      this.annotationService.isRefreshAnnotations$.subscribe(refreshAnnotations => {
        if (refreshAnnotations && !this.isShowWidgetPanel) {
          this.getAnnotations();
        }
      }),
      this.platformService.isShowWidgetPanel$.subscribe(res => {
        this.isShowWidgetPanel = res;
        this.changeDetector.markForCheck();
      }),
      this.annotationService.annotationsNumber$.subscribe(res => {
        this.annotationsCount = res;
        this.changeDetector.markForCheck();
      }),
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.currentUrl = event.url;
          this.widgetApps = this.widgetApps.map(app => {
            const returnValue = { ...app };

            return returnValue;
          });
          if (this.isValidUrl(this.urlForAnnotationRegex, this.currentUrl)) {
            this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
          } else {
            this.closePanel();
          }
          this.changeDetector.markForCheck();
        }),
      this.menuService.isWidgetOpen$.subscribe(res => {
        if (res) { this.showSelectedWidget("annotations"); }
      }),
      this.pageService.documentPageNumber$.pipe(distinctUntilKeyChanged("documentPageId")).subscribe(event => {
        this.page = event.page;
        this.documentPageId = event.documentPageId;
        this.changeDetector?.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private isValidUrl(regexp: RegExp, test: string) {
    return regexp.test(test);
  }

  getActiveClass(value: string): string {
    let cssClass = "";
    switch (value) {
      case Widget.CHAT:
        return cssClass = this.isChat ? `${cssClass} active` : `${cssClass}`;
      case Widget.DIRECTORY:
        return cssClass = this.isDirectory ? `${cssClass} active` : `${cssClass}`;
      case Widget.ANNOTATIONS:
        return cssClass = this.isAnnotation ? `${cssClass} active` : `${cssClass}`;
      default:
        return cssClass;
    }
  }

  showPanel(app: string): void {
    this.selectedPanel === app ? this.closePanel() : this.showSelectedWidget(app);
  }

  closePanel(): void {
    this.hideAllPanels();
    this.platformService.isShowWidgetPanel.next(false);
    this.selectedPanel = "";
    this.menuService.toggleWidgetState(false);
    this.menuService.openNewAnnotation({ createNewAnnotation: false});
    this.changeDetector.markForCheck();
  }

  getClass(showHeader: any): string {
    let cssClass = "";
    return cssClass = ((this.router.url.includes("/members/chase")
      || this.router.url.includes("/members/mrr/chase")
      || this.router.url.includes("/retrieval/review/qa")
      || this.router.url.includes("/members/or1/chase")
      || this.router.url.includes("/members/clientoverread/chase")) && !showHeader) ? `router_width` : `header_width`;
  }

  trackByIndex(index, item) {
    return index;
  }

  private hideAllPanels(): void {
    this.isChat = false;
    this.isDirectory = false;
    this.isAnnotation = false;
    this.changeDetector.markForCheck();
  }

  private showSelectedWidget(value: string): void {
    this.hideAllPanels();
    this.platformService.isShowWidgetPanel.next(true);
    switch (value) {
      case Widget.CHAT:
        this.isChat = true;
        break;
      case Widget.DIRECTORY:
        this.isDirectory = true;
        break;
      case Widget.ANNOTATIONS:
        this.isAnnotation = true;
        this.menuService.selectedWidget.next(Widget.ANNOTATIONS);
        break;
      default:
        break;
    }
    this.selectedPanel = value;
    this.changeDetector.markForCheck();
  }

  showAnnotationsBadge(appName): boolean {
    return this.annotationsCount > 0 && appName === "annotations";
  }

  showPlusSign(): boolean {
    return this.annotationsCount > 9;
  }

  onPageChange(page: number): void {
    if (this.page !== page) {
      this.page = page;
      this.pageService.updateOutsidePage(page);
      this.changeDetector.markForCheck();
    }
  }

  getAnnotations(): void {
    this.sink.add(
      this.annotationService.getAnnotations$(this.chaseId).subscribe((res: Annotation[]) => {
        this.annotationsCount = res.length;
        this.annotationService.setAnnotations([...res]);
        this.annotationService.setAnnotationsTotal(this.annotationsCount);
        this.changeDetector.markForCheck();
      })
    );
  }
}
