<div *ngIf="hasNote" class="note-viewer">
  <app-icon class="note-link" (click)="showNoteViewer()" iconName="file"></app-icon>
  <span class="note-description">{{ note }}</span>

  <app-modal [(visible)]="isNoteViewerVisible" [header]="headerText" (onCancel)="closeNoteViewer()">
    <div class="note-detail">{{note}}</div>
    <footer class="text-center">
      <app-button classes="secondary" text="COPY NOTE" (onClick)="copyNote()"></app-button>
    </footer>
  </app-modal>
</div>
