import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { NonCompliantInfo } from "./non-compliant-info.model";

export const mapExlcusionAndContra = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ExlcusionAndContra")
    .forMember("exclusionList", o => automapper.mapMany("ExclusionAndContra", "NonCompliantInfo", o.exclusionList))
    .forMember("exclusionOptions", o => automapper.mapMany("default", "SelectableInput", o.exclusionOptions))
    .forMember("contra", o => automapper.map("ExclusionAndContra", "NonCompliantInfo", o.contra))
    .forMember("lessThanSeven", o => {
      if (o && o.lessThanSeven) {
        return automapper.map("ExclusionAndContra", "NonCompliantInfo", o.lessThanSeven);
      } else {
        return null;
      }
    });
};

export class ExlcusionAndContra {

  exclusion: NonCompliantInfo;
  contra: NonCompliantInfo;
  lessThanSeven: NonCompliantInfo;
  exclusionList: NonCompliantInfo[];
  exclusionOptions: SelectableInput[];

  constructor(options: {
    contra?: NonCompliantInfo;
    lessThanSeven?: NonCompliantInfo;
    exclusionList?: NonCompliantInfo[];
    exclusionOptions?: SelectableInput[];
  } = {}) {
    this.contra = options.contra || new NonCompliantInfo();
    this.lessThanSeven = options.lessThanSeven;
    this.exclusionList = options.exclusionList || [];
    this.exclusionOptions = options.exclusionOptions || [];
  }

  get hasLessThanSeven(): boolean {
    return this.lessThanSeven != null;
  }

}
