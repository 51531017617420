
    <p-fileUpload
      #primeUpload
      name="file[]"
      (uploadHandler)="uploadDocument($event)"
      (onRemove)="removeEvent($event)"
      customUpload="true"
      [multiple]="multiple"
      [accept]="accept"
      maxFileSize="1000000000"
      [chooseLabel]="chooseLabel"
      [uploadLabel]="uploadLabel"
      [showUploadButton]="showUploadButton"
      [showCancelButton]="showCancelButton"
      [disabled]="disabled"
      [auto]="isAutoUpload"
      (onSelect)="onFileSelected($event)"
      (onClear)="cancelAllDocuments()"
    ></p-fileUpload>
  