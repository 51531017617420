<div class="addresses-queue-container">
  <app-detail-main-template>
    <div container-header>
      <h3>Address Assignment</h3>
      <div class="statistics">
      </div>
      <div class="filters">
        <app-button class="button"
                    (onClick)="isQueueModalVisible=true"
                    icon="sliders-h"
                    text="QUEUE PRIORITIES"
                    id="button-filter"></app-button>
      </div>
    </div>
    <div container-body>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <app-address-grid [url]="url"
                            [stateName]="stateName"
                            [isSelectionModeMultiple]="isSelectionModeMultiple"
                            [isUnassignEnabled]="false"
                            [additionalBulkActions]="additionalBulkActions"
                            [additionalFilters]="additionalFilters"
                            [isLoadOnInit]="false"
                            [showViews]="true"
                            [viewAttributeId]="viewAttributeId"></app-address-grid>
        </div>
      </div>
    </div>
  </app-detail-main-template>
</div>

<app-modal [(visible)]="isQueueModalVisible"
           header="QUEUE PRIORITIES">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="addressQueueForm" [model]="projectInput"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button"
                text="UPDATE QUEUE"
                (onClick)="onUpdateQueue()"
                [disabled]="!hasSelectedProjectId"></app-button>
  </footer>
</app-modal>
