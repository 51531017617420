<div class="ui-g ui-fluid">
  <!--ADDRESS ASSIGNMENT -->
  <div id="addressAssignment" class="ui-g-12 ui-md-5 ui-lg-5">
      <app-kpi [cModel]="chartModelAddressAssignment"  [cDataLabels]="dataLabelsAddressAssignment" [cDataModel]="arrAddressAssignment" [cOptions]="dataOptionsAddressAssignment" >
      </app-kpi>
  </div>
  <!--CALLS COMPLETED -->
  <div class="ui-g-12 ui-md-7">
      <app-kpi [cModel]="chartModelCallsCompleted" [cDataLabels]="dataLabelsCallsCompleted" [cDataModel]="arrCallsCompleted" [cOptions]="dataOptionsCallsCompleted">
      </app-kpi>
  </div>
</div>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-md-5 ui-lg-5">

  </div>
  <div class="ui-g-12 ui-md-7">

  </div>
</div>
<div class="ui-g ui-fluid">
  <!--ADDRESSES -->
  <div class="ui-g-12 ui-md-5 ui-lg-5">
      <app-kpi [cModel]="chartModelAddresses" [cDataLabels]="dataLabelsAddresses" [cDataModel]="arrAddresses" [cOptions]="dataOptionsAddresses">
      </app-kpi>
  </div>
  <!--PENDS CREATED -->
  <div class="ui-g-12 ui-md-7">
      <app-kpi [cModel]="chartModelPendsCreated" [cDataLabels]="dataLabelsPendsCreated" [cDataModel]="arrPendsCreated" [cOptions]="dataOptionsPendsCreated">
      </app-kpi>
  </div>
</div>