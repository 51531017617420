import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-chart-routing",
  templateUrl: "./chart-routing.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartRoutingComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.CHART_ROUTING;

  constructor() {
    this.filters = ["CreateDate"]; }
}
