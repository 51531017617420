import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-hcc-by-dos",
  templateUrl: "./hcc-by-dos.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccByDosComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.HCC_BY_DOS;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
