import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { ProviderItem } from "../../../../api/provider/provider-search-result-item";
import { ProviderService } from "../../../../api/provider/provider.service";
import { PROVIDER_ID, PROVIDER_NAME } from "../../chase-detail/chase-detail-chart/attributes";
import { CreateChaseService } from "../create-new-chase.service";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { NewChase } from "../new-chase.model";

@Component({
  selector: "app-create-new-chase-provider",
  templateUrl: "./create-new-chase-provider.component.html",
  styleUrls: ["./create-new-chase-provider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseProviderComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private readonly formService: FormService,
              private changeDetector: ChangeDetectorRef, private messagingService: MessagingService, private providerService: ProviderService,
              private createChaseService: CreateChaseService, private dataExchangeService: GapQueryDataExchangeService,
              @Inject(BASE_API_URL) private readonly baseApiUrl: string) {

  }

  get showProviderForm(): boolean {
    return NumberHelper.isGreaterThan(this.formGroupProviderOptions.get("ProviderSearch").value, 0);
  }

  get isProviderSearch(): boolean {
    const existingProvider = this.formGroupProviderOptions.get("ProviderSearch").value === 1;
    if (!existingProvider) { this.providerCount = undefined; }
    return existingProvider;
  }

  get isProviderResultsFound(): boolean {
    return NumberHelper.isGreaterThan(this.providerCount, 0);
  }

  get isShowNextStepButton(): boolean {
    return this.providerGridSelection != null;
  }
  get isRiskProjectSelected(): boolean {
    return !StringHelper.isAvailable(this.createChaseService.chaseModel.measureCode);
  }

  get providerDetails(): ProviderItem {
    const fName = StringHelper.isAvailable(this.formGroupProvider.get("firstName").value) ? this.formGroupProvider.get("firstName").value : null;
    const lName = StringHelper.isAvailable(this.formGroupProvider.get("lastName").value) ? this.formGroupProvider.get("lastName").value : null;
    return new ProviderItem({
      recordNumber: 0,
      serviceProviderId: null,
      sourceAliasId: null,
      firstName: fName,
      lastName: lName,
      serviceProviderName: `${fName} ${lName}`,
      nationalProviderId: StringHelper.isAvailable(this.formGroupProvider.get("npId").value) ? this.formGroupProvider.get("npId").value : null,
      speciality: StringHelper.isAvailable(this.formGroupProvider.get("specialty").value) ? this.formGroupProvider.get("specialty").value : null,
      clientProviderId: this.formGroupProvider.get("clientProviderId").value ? this.formGroupProvider.get("clientProviderId").value : null,
      providerIdAttributeData: PROVIDER_ID,
      providerNameAttributeData: PROVIDER_NAME,
      recordCount: 0,
    });
  }
  private sink = new SubSink();
  @Output() onShowChild = new EventEmitter<any>();
  formGroupProvider: FormGroup;
  formGroupProviderOptions: FormGroup;
  npId: Textbox;
  firstName: Textbox;
  lastName: Textbox;
  specialty: Textbox;
  existingProviderOptions: Radiobutton;
  provider: ProviderItem;
  providerGridConfiguration = new GridConfiguration();
  selectedProviderGridConfiguration = new GridConfiguration();
  providerSearchData: ProviderItem[] = [];
  providerGridSelection: ProviderItem;
  providerGridSelectionList: ProviderItem[] = [];
  newChase: NewChase;
  providerCount: number;
  clientProviderId: Textbox;
  newChaseData: NewChase;
  isProviderInvalid: boolean;
  isProviderAdded = false;
  validationErrorMessages: string[];
  selectedProviderList: ProviderItem[] = [];
  @ViewChild("providerColumn", { static: true }) providerColumn: TemplateRef<any>;
  @ViewChild("removeProviderColumn", { static: true }) removeProviderColumn: TemplateRef<any>;
  @Output() isShowEncounterScreen = new EventEmitter<boolean>();
  @Output() isShowGapScreen = new EventEmitter<boolean>();
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  isGapClosureActive = false;
  providerGridSearchRequest: GridRequest;
  providerGridData: ProviderItem[];
  @Input() isRiskProject = false;


  ngOnInit() {
    this.initializeProviderGrid();
    this.initializeSelectedProviderGrid();
    this.initializeProviderFormControls();
    this.createProviderOptions();
    this.newChase = this.createChaseService.chaseModel;
    this.dataExchangeService.getNewChaseData().subscribe(result => {
      this.newChase = result;
    });
    this.createProviderForm();
    this.checkProviderOption();
    this.loadGrid();
    this.newChaseData = new NewChase();
  }

  private loadGrid(): void {
    const providerFilters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "FirstName",
        value: StringHelper.isAvailable(this.formGroupProvider.get("firstName").value) ? this.formGroupProvider.get("firstName").value : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "LastName",
        value: StringHelper.isAvailable(this.formGroupProvider.get("lastName").value) ? this.formGroupProvider.get("lastName").value : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "NationalProviderId",
        value: StringHelper.isAvailable(this.formGroupProvider.get("npId").value) ? this.formGroupProvider.get("npId").value : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Speciality",
        value: StringHelper.isAvailable(this.formGroupProvider.get("specialty").value) ? this.formGroupProvider.get("specialty").value : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ClientProviderId",
        value: this.formGroupProvider.get("clientProviderId").value ? this.formGroupProvider.get("clientProviderId").value : null,
        show: false,
      }),
    ];
    this.providerGridSearchRequest = new GridRequest({
      url: `${this.baseApiUrl}provider/list`,
      filters: providerFilters,

    });
    this.refreshGrid.emit();
  }

  private initializeProviderGrid(): void {
    this.providerGridConfiguration.columns = [
      new GridColumnDefinition({ field: "nationalProviderId", header: "NPI" }),
      new GridColumnDefinition({ field: "speciality", header: "SPECIALTY" }),
      new GridColumnDefinition({ field: "firstName", header: "FIRST NAME" }),
      new GridColumnDefinition({ field: "lastName", header: "LAST NAME" }),
      new GridColumnDefinition({ header: "", template: this.providerColumn, isSortableColumn: false, show: this.isRiskProject}),
    ];
    this.providerGridConfiguration.showExportAction = false;
    this.providerGridConfiguration.pageSize = 10;
    this.providerGridConfiguration.showMenu = false;
    this.providerGridConfiguration.selectionMode = this.isRiskProject ? "none" : "single";
    this.providerGridConfiguration.showActionColumn = false;
  }

  private initializeSelectedProviderGrid(): void {
    this.selectedProviderGridConfiguration.columns = [
      new GridColumnDefinition({ field: "nationalProviderId", header: "NPI" }),
      new GridColumnDefinition({ field: "speciality", header: "SPECIALTY" }),
      new GridColumnDefinition({ field: "firstName", header: "FIRST NAME" }),
      new GridColumnDefinition({ field: "lastName", header: "LAST NAME" }),
      new GridColumnDefinition({ header: "", template: this.removeProviderColumn, isSortableColumn: false}),

    ];
    this.selectedProviderGridConfiguration.showExportAction = false;
    this.selectedProviderGridConfiguration.pageSize = 10;
    this.selectedProviderGridConfiguration.selectionMode = "none";
    this.selectedProviderGridConfiguration.showActionColumn = false;
  }

  private initializeProviderFormControls(): void {
    this.npId = new Textbox({
      key: "npId",
      type: TextboxType.NUMBER,
      label: "NPI",
      placeholder: "Enter NPI",
      validators: [Validators.pattern(RegExHelper.wholeNumber)],
      errorMessages: {
        pattern: "NPI should be numeric. Decimal or negative numbers not allowed.",
      },
    });

    this.firstName = new Textbox({
      key: "firstName",
      label: "FIRST NAME",
      placeholder: "Enter First Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "First Name can not be more than 50 characters.",
      },
    });
    this.lastName = new Textbox({
      key: "lastName",
      label: "LAST NAME",
      placeholder: "Enter Last Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Last Name can not be more than 50 characters.",
      },
    });
    this.specialty = new Textbox({
      key: "specialty",
      label: "SPECIALTY",
      placeholder: "Enter Specialty",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Specialty can not be more than 50 characters.",
      },
    });
    this.existingProviderOptions = new Radiobutton({
      key: "ProviderSearch",
      label: "IS THIS AN EXISTING PROVIDER?",
      options: [
        new SelectableInput({ text: "Yes", value: 1 }),
        new SelectableInput({ text: "No", value: 2 }),
      ],
    });

    this.clientProviderId = new Textbox({
      key: "clientProviderId",
      label: "CLIENT PROVIDER ID",
      placeholder: "Enter Client Provider ID",
    });
  }

  private createProviderForm(): void {
    this.formGroupProvider = this.formService.createFormGroup([
      this.npId,
      this.firstName,
      this.lastName,
      this.specialty,
      this.clientProviderId]);
  }

  private createProviderOptions(): void {
    this.formGroupProviderOptions = this.formService.createFormGroup([this.existingProviderOptions]);
  }

  searchProviderAction(): void {
    this.loadGrid();
    this.initializeProviderGrid();
    this.initializeSelectedProviderGrid();
    this.changeDetector.markForCheck();
  }

  gridData($event: ProviderItem[]): void {
    this.providerGridData = $event;
    this.providerSearchData = this.providerGridData as ProviderItem[];
    this.providerSearchData.map(x => { x.clientProviderId = x.sourceAliasId; return x; });
    this.providerCount = this.providerSearchData.length;
  }

  private markAllAsTouched(): void {
    Object.keys(this.formGroupProvider.controls).forEach(key => {
      const control = this.formGroupProvider.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  newProviderAction(): void {
    if (this.formGroupProvider.valid) {
      this.changeDetector.markForCheck();
      this.removeControlCharacters();
      this.dataExchangeService.getIsGapClosureActive().subscribe(result => {
        this.isGapClosureActive = result;
      });
      this.newChase.providerId = null;
      this.newChase.providerName = this.providerDetails.fullName;
      this.newChase.chaseProviderData = this.providerDetails;
      this.newChaseData.chaseProviderData = this.providerDetails;
      this.providerGridSelection = this.providerDetails;
      this.newChase.riskChaseProviderData = [];
      this.newChase.riskChaseProviderData.push(this.providerDetails);
      this.createChaseService.validateChaseDetails(this.newChaseData)
            .subscribe(messages => {
                if (ArrayHelper.isAvailable(messages)) {
                    this.validationErrorMessages = messages;
                    this.isProviderInvalid = true;
                    this.changeDetector.markForCheck();
                } else {
                    this.validationErrorMessages = null;
                    this.isProviderInvalid = false;
                    this.createChaseService.chaseModel = this.newChase;
                    if (!this.isRiskProjectSelected && this.isGapClosureActive) {
                      this.isShowEncounterScreen.emit(false);
                      this.isShowGapScreen.emit(true);
                    } else if (this.isRiskProjectSelected && !this.isGapClosureActive) {
                      this.isShowEncounterScreen.emit(true);
                      this.isShowGapScreen.emit(false);
                    }
                    this.onShowChild.emit(this.createChaseService.chaseModel);
                    this.dataExchangeService.setproviderDetails(this.createChaseService.chaseModel);
                    this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
          }
        });
    } else {
      this.markAllAsTouched();
    }
  }

  checkProviderOption(): void {
    this.sink.add(
      this.formGroupProviderOptions.get("ProviderSearch").valueChanges.subscribe(() => {
        this.providerGridSelection = null;
        if (this.showProviderForm && !this.isProviderSearch) {
          this.reset();
          this.initializeAddProviderForm();
        }
        this.createProviderForm();
        this.changeDetector.markForCheck();
      })
    );
  }

  private initializeAddProviderForm() {
    this.npId = new Textbox({
      ...this.npId,
      value: this.formGroupProvider.get(this.npId.key).value,
      validators: [Validators.required, Validators.pattern(RegExHelper.wholeNumber)],
      errorMessages: {
        required: "NPI is required.",
        pattern: "NPI should be numeric. Decimal or negative numbers not allowed.",
      },
    } as any);

    this.firstName = new Textbox({
      ...this.firstName,
      value: this.formGroupProvider.get(this.firstName.key).value,
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "First Name is required.",
        maxlength: "First Name can not be more than 50 characters.",
      },
    } as any);

    this.lastName = new Textbox({
      ...this.lastName,
      value: this.formGroupProvider.get(this.lastName.key).value,
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "Last Name is required.",
        maxlength: "Last Name can not be more than 50 characters.",
      },
    } as any);

    this.specialty = new Textbox({
      ...this.specialty,
      value: this.formGroupProvider.get(this.specialty.key).value,
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Specialty can not be more than 50 characters.",
      },
    } as any);

    this.clientProviderId = new Textbox({
      ...this.clientProviderId,
      value: this.formGroupProvider.get(this.clientProviderId.key).value,
    } as any);
  }

  nextStep() {
    this.dataExchangeService.getIsGapClosureActive().subscribe(result => {
      this.isGapClosureActive = result;
    });
    if (this.providerGridSelection != null) {
      this.newChase.providerId = this.providerGridSelection.serviceProviderId;
      this.newChase.providerName = this.providerGridSelection.serviceProviderName;
      this.newChase.projectId = this.createChaseService.chaseModel.projectId;
      this.newChase.riskChaseProviderData = [];
      if (!this.isRiskProjectSelected && this.isGapClosureActive) {
      this.newChase.chaseProviderData = this.providerGridSelection;
      this.newChase.riskChaseProviderData.push(this.providerGridSelection);
      this.createChaseService.chaseModel = this.newChase;
      this.isShowEncounterScreen.emit(false);
      this.isShowGapScreen.emit(true);
      } else if (this.isRiskProjectSelected && this.isRiskProject) {
        const sortedProviderList = this.providerGridSelectionList.sort((n1, n2) => {
          if (n1.fullName > n2.fullName) {
              return 1;
          }

          if (n1.fullName < n2.fullName) {
              return -1;
          }

          return 0;
      });
        this.newChase.riskChaseProviderData = sortedProviderList;
        this.createChaseService.chaseModel = this.newChase;
        this.isShowEncounterScreen.emit(true);
        this.isShowGapScreen.emit(false);
      } else {
        this.newChase.riskChaseProviderData.push(this.providerGridSelection);
      }
      this.createChaseService.chaseModel = this.newChase;
      this.onShowChild.emit(this.createChaseService.chaseModel);
      this.dataExchangeService.setproviderDetails(this.createChaseService.chaseModel);
      this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  reset() {
      this.formGroupProvider.reset();
      this.isProviderInvalid = false;
      this.providerGridSelectionList = [];
      this.providerGridSelection = null;
      this.isProviderAdded = false;
  }

  private removeControlCharacters() {
    Object.keys(this.formGroupProvider.controls).forEach(key => {
      const control = this.formGroupProvider.get(key).value;
      if (StringHelper.isAvailable(control)) {
        const validValues = control.replace(/[^\x20-\x7E]/g, "");
        this.formGroupProvider.controls[key].setValue(validValues);
      }
    });
    this.formService.updateDom.next();
    }

  trackByIndex(index, item) {
        return index;
    }

  onActionEvent(selectedProvider: ProviderItem) {
      this.providerGridSelection = selectedProvider;
      this.providerGridSelection.providerIdAttributeData = PROVIDER_ID;
      this.providerGridSelection.providerNameAttributeData = PROVIDER_NAME;
      const checkProviderExistence = this.checkProviderExistence(selectedProvider);
      if (!checkProviderExistence) {
      this.providerGridSelectionList.push(this.providerGridSelection);
      }
      this.isProviderAdded = this.providerGridSelectionList.length > 0;
    }

  onActionDeleteEvent(selectedProvider: ProviderItem) {
      this.providerGridSelectionList.forEach((item, index) => {
        if (item === selectedProvider) {
          this.providerGridSelectionList.splice(index, 1);
        }
        if (this.providerGridSelectionList.length === 0) {
          this.isProviderAdded = false;
          this.providerGridSelection = null;
        }
      });
    }

  checkProviderExistence(selectedProvider): boolean {
      const provider = this.providerGridSelectionList.find(x => x.nationalProviderId === selectedProvider.nationalProviderId);
      return provider ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isRiskProject?.currentValue !== changes?.isRiskProject?.previousValue) {
      this.providerGridSelection = null;
      this.providerGridSelectionList = [];
      this.isProviderAdded = false;
      this.newChase = this.createChaseService.chaseModel;
      this.initializeProviderGrid();
      this.initializeSelectedProviderGrid();
    }
  }
}
