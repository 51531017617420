import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { MfaComponent } from "./mfa/mfa.component";
import { SsoRedirectComponent } from "./sso-redirect/sso-redirect.component";
import { SsoComponent } from "./sso/sso.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "mfa", component: MfaComponent },
  { path: "sso", component: SsoComponent },
  { path: "sso-redirect", component: SsoRedirectComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
