import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";

@Injectable()
export class NegativeReasonCodeService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  // TODO: remove the hardcoded chase id.
  saveNegativeReasonCode(chaseId: number = 1866241, negativeReasonCode: any): Observable<any> {
    const url = `${this.baseApiUrl}negativereasoncode/save?chaseId=${chaseId}`;
    return this.http.post(url, negativeReasonCode);
  }
}
