import { AbstractControl } from "@angular/forms";
import { StringHelper } from "../utilities/contracts/string-helper";

export class DynamicFormEvent {
  key: string;
  type: string;
  value: any;
  control: AbstractControl;
  model: any;
  successFn: () => void;


  constructor(options: {
    key: string;
    type: string;
    value: any;
    control: AbstractControl;
    model: any;
    successFn?(): void;
  }) {
    StringHelper.throwIsAvailableError(options.key, "key");
    this.throwTypeIsAvailableError(options.type);

    this.key = StringHelper.clean(options.key);
    this.type = StringHelper.clean(options.type);
    this.value = options.value || {};
    this.control = options.control || {} as any;
    this.model = options.model || {};
    this.successFn = options.successFn || (() => { });
  }


  private throwTypeIsAvailableError(type: string): void {
    if (!["save", "delete", "compliance", "notify", "clear"].includes(type)) {
      throw new Error(`The type '${type}' is not a valid event type.`);
    }
  }
}
