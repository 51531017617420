import { IAutoMapper } from "../interfaces";

export const mapDocumentApprovalSearchResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentApprovalSearchResponse")
    .forMember("documentApprovalId", o => o.documentApprovalId)
    .forMember("documentQueueId", o => o.documentQueueId)
    .forMember("approvalType", o => o.approvalType)
    .forMember("chaseId", o => o.chaseId)
    .forMember("projectName", o => o.projectName)
    .forMember("createDate", o => o.createDate)
    .forMember("createdBy", o => o.createdBy)
    .forMember("documentApprovalStateId", o => o.documentApprovalStateId)
    .forMember("approvalState", o => o.approvalState)
    .forMember("recordCount", o => o.recordCount)
    .forMember("documentApprovalStateId", o => o.documentApprovalStateId)
    .forMember("approvalStatusDate", o => o.approvalStatusDate)

    .forMember("chaseStatus", o => o.chaseStatus)
    .forMember("measure", o => o.measure)
    .forMember("retreivalType", o => o.retreivalType)
    .forMember("numberOfPages", o => o.numberOfPages)
    .forMember("approvalStatusUserName", o => o.approvalStatusUserName)
    .forMember("measureCode", o => o.measureCode)
    .forMember("sampleComplianceCode", o => o.sampleComplianceCode)
    .forMember("chaseSourceAliasId", o => o.chaseSourceAliasId)
    .forMember("tagItems", o => o.tagItems)
    .forMember("workingDocumentId", o => o.workingDocumentId);

};
