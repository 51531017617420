import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { GridHeaderStatsModule } from "./grid-header-stats/grid-header-stats.module";
import { HeaderStatisticsModule } from "./header-statistics/header-statistics.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HeaderStatisticsModule,
        GridHeaderStatsModule,
    ],
    exports: [
        HeaderStatisticsModule,
        GridHeaderStatsModule,
    ],
})
export class StatisticsModule { }
