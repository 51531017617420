import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { HighlightService } from "../../highlighter/highlight.service";
import { StandardGroup } from "./standard-group.model";

@Component({
  selector: "form-standard-group",
  templateUrl: "./standard-group.component.html",
  styleUrls: [
    "../dynamic-group.scss",
    "../../highlighter/highlight.scss",
    "./standard-group.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardGroupComponent extends DynamicControlDirective<StandardGroup> {
  constructor(readonly highlighter: HighlightService) {
    super();
  }


  trackByIndex(index, item) {
    return index;
  }
}
