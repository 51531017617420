import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { ChartModel } from "../../../shared/kpi/model/chart.model";
import { DataModel } from "../../../shared/kpi/model/data.model";
import { LabelModel } from "../../../shared/kpi/model/label.model";
import { LandingFilter } from "../../../shared/kpi/model/landingFliter.model";
import { ListItem } from "../../../shared/list/list-item";
import { RetrievalService } from "../retrieval/retrieval.service";

@Component({
  selector: "retrieval-main",
  templateUrl: "./retrieval.component.html",
  styleUrls: ["./retrieval.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RetrievalComponent implements OnInit {
  today: number = Date.now();
  retrievalStatsList = List<ListItem>();
  landingFilter: LandingFilter;

  chartModelChasesStatus: ChartModel;
  labelModelChasesStatus: LabelModel;
  dataOptionsChasesStatus: any;
  dataModelChasesStatus: DataModel;
  arrChasesStatus: DataModel[] = [];

  chartModelPends: ChartModel;
  labelModelPends: LabelModel;
  dataOptionsPends: any;
  dataModelPends: DataModel;
  arrPends: DataModel[] = [];

  chartModelChasesNotRetrieved: ChartModel;
  labelModelChasesNotRetrieved: LabelModel;
  dataOptionsChasesNotRetrieved: any;
  dataModelChasesNotRetrieved: DataModel;
  arrChasesNotRetrieved: DataModel[] = [];

  chartModelFieldTechAppointments: ChartModel;
  labelModelFieldTechAppointments: LabelModel;
  dataOptionsFieldTechAppointments: any;
  dataModelFieldTechAppointments: DataModel;
  arrFieldTechAppointments: DataModel[] = [];

  schedulingStatusChartModel: ChartModel;
  schedulingStatusLabelModel: LabelModel;
  schedulingStatusDataOptions: any;
  schedulingStatusArray: DataModel[] = [];

  retrievalActivityChartModel: ChartModel;
  retrievalActivityLabelModel: LabelModel;
  retrievalActivityDataOptions: any;
  retrievalActivityArray: DataModel[] = [];

  contactActivityChartModel: ChartModel;
  contactActivityLabelModel: LabelModel;
  contactActivityDataOptions: any;
  contactActivityArray: DataModel[] = [];

  retrievalPendsStatusChartModel: ChartModel;
  retrievalPendsStatusLabelModel: LabelModel;
  retrievalPendsStatusDataOptions: any;
  retrievalPendsStatusArray: DataModel[] = [];

  retrievalProjectionsChartModel: ChartModel;
  retrievalProjectionsLabelModel: LabelModel;
  retrievalProjectionsDataOptions: any;
  retrievalProjectionsArray: DataModel[] = [];

  schedulingMaxValue: number;
  clientId: number;
  projectId: number;

  constructor(
    private retrievalService: RetrievalService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {

    this.chartModelChasesStatus = new ChartModel(
      {
        chartHeader: "CHASES STATUS", chartType: "bar", chartWidth: 1200,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.dataOptionsChasesStatus = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 80,
          scaleLabel: {
            display: true,
            labelString: "CHASES STATUS",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            min: 0,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrChasesStatus.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const callsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - callsCompletedWidthNum);
            });
          });
        },
      },
    };

    this.chartModelPends = new ChartModel({
      chartHeader: "PENDS", chartHeight: 400, chartWidth: 1500,
      chartType: "pie", headerClassName: "DoughnutHeader", isResponsive: false, canvasClassName: null,
    });

    this.dataOptionsPends = {
      legend: {
        position: "bottom",
      },
      tooltips: {
        enabled: true,
      },
      hover: {
        animationDuration: 0,
      },
      scales: {
        xAxes: [{
          display: false,
          scaleLabel: {
            display: false,
            labelString: "Duration ---->",
          },

        }],
        yAxes: [{
          display: false,
          scaleLabel: {
            display: false,
            labelString: "No of Pends ---->",
          },
        }],
      },
    };

    this.chartModelChasesNotRetrieved = new ChartModel({
      chartHeader: "CHASES NOT RETRIEVED", chartHeight: 400, chartWidth: 450,
      chartType: "pie", headerClassName: "pieheaderII", canvasClassName: "DoughnutCanvas",
    });

    this.dataOptionsChasesNotRetrieved = {
      legend: {
        position: "bottom",
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        animationDuration: 0,
      },
    };

    this.chartModelFieldTechAppointments = new ChartModel(
      {
        chartHeader: "FIELD TECH APPOINTMENTS", chartType: "bar", chartWidth: 1200,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.dataOptionsFieldTechAppointments = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 80,
          scaleLabel: {
            display: true,
            labelString: "FIELD TECH APPOINTMENTS",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrFieldTechAppointments.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const callsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - callsCompletedWidthNum);
            });
          });
        },
      },
    };

    this.retrievalActivityChartModel = new ChartModel(
      {
        chartHeader: "RETRIEVAL ACTIVITY", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.retrievalActivityDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "Intake",
                      fillStyle: "#63A0F7",
                    };
                  case 1:
                    return {
                      text: "QA",
                      fillStyle: "#EE6547",
                    };
                  case 2:
                    return {
                      text: "PSR",
                      fillStyle: "#C8D0DB",
                    };
                  case 3:
                    return {
                      text: "EMR",
                      fillStyle: "#FAEF3C",
                    };
                  case 4:
                    return {
                      text: "Field Tech",
                      fillStyle: "#1263D8",
                    };
                  case 5:
                    return {
                      text: "Pended",
                      fillStyle: "#0BB455",
                    };
                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "RETRIEVAL ACTIVITY",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };

    this.contactActivityChartModel = new ChartModel(
      {
        chartHeader: "CONTACT ACTIVITY", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.contactActivityDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "Calls",
                      fillStyle: "#EE6547",
                    };
                  case 1:
                    return {
                      text: "Emails",
                      fillStyle: "#FAEF3C",
                    };
                  case 2:
                    return {
                      text: "Faxes",
                      fillStyle: "#0BB455",
                    };

                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "CONTACT ACTIVITY",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };

    this.retrievalPendsStatusChartModel = new ChartModel(
      {
        chartHeader: "RETRIEVAL PENDS", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.retrievalPendsStatusDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "NEW",
                      fillStyle: "#63A0F7",
                    };
                  case 1:
                    return {
                      text: "IN PROGRESS",
                      fillStyle: "#EE6547",
                    };
                  case 2:
                    return {
                      text: "RESOLVED",
                      fillStyle: "#C8D0DB",
                    };
                  case 3:
                    return {
                      text: "CLOSED",
                      fillStyle: "#FAEF3C",
                    };
                  case 4:
                    return {
                      text: "REQUEST TO RESOLVE",
                      fillStyle: "#1263D8",
                    };
                  case 5:
                    return {
                      text: "REQUEST TO CLOSE",
                      fillStyle: "#0BB455",
                    };
                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "PENDS",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };

    this.retrievalProjectionsChartModel = new ChartModel(
      {
        chartHeader: "RETRIEVAL PROJECTIONS", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.retrievalProjectionsDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "Chases",
                      fillStyle: "#b3daff",
                    };

                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "CHASES",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };

  }

  ngOnInit() {
    this.getRetrievalStats();
    this.getChaseStatusForTimePeriodBarKpi();
    this.getPendsPieKpi();
    this.getChasesNotRetrievedPieKpi();
    this.getFieldTechAppointmentsBarKpi();
    this.getSchedulingStatusKpi();
    this.getRetrievalActivityKpi();
    this.getContactActivityKpi();
    this.getRetrievalPendsKpi();
    this.getRetrievalProjectionsKpi();
  }

  applyFilter($event) {
    this.clientId = $event.clientId;
    this.projectId = $event.projectId;
    this.getRetrievalStats();
    this.getSchedulingStatusKpi();
    this.getRetrievalActivityKpi();
    this.getContactActivityKpi();
    this.getRetrievalPendsKpi();
    this.getRetrievalProjectionsKpi();
    this.landingFilter = new LandingFilter($event);
  }

  initializeSchedulingKpi(): void {
    this.schedulingStatusChartModel = new ChartModel(
      {
        chartHeader: "SCHEDULING STATUS", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.schedulingStatusDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "To Be Contacted",
                      fillStyle: "#add8e6",
                    };
                  case 1:
                    return {
                      text: "Contacted",
                      fillStyle: "#565656",
                    };
                  case 2:
                    return {
                      text: "Past Due",
                      fillStyle: "#f2917d",
                    };
                  case 3:
                    return {
                      text: "Partially Scheduled",
                      fillStyle: "#bff298",
                    };
                  case 4:
                    return {
                      text: "Scheduled",
                      fillStyle: "#fcffaf",
                    };
                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "SCHEDULING STATUS",
          },
        }],
        yAxes: [{
          type: "logarithmic",
          ticks: {
            min: 0,
            max: this.schedulingMaxValue,
            callback(value, index, values) {
              return Number(value.toString());
            },
          },
          afterBuildTicks(chart) {
            const num = parseFloat(chart.max);
            chart.ticks = [];
            const percentages = [0, 1, 30, 55]; // used to calculate Percentage to display graph values in Kpi.
            for (const row of percentages) {
              const value = (num - (num - (num * row / 100))).toFixed();
              const digits = Math.ceil(Math.log(parseInt(value, 10)) / Math.LN10) < 3 ? 10 : 100;
              chart.ticks.push(Math.ceil(parseInt(value, 10) / digits) * digits);
            }
            const maxValueRound = Math.ceil((Math.log(parseInt(chart.max, 10)) / Math.LN10)) < 3 ? 10 : 100;
            chart.ticks.push(Math.ceil(parseInt(chart.max, 10) / maxValueRound) * maxValueRound);
          },
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };
  }

  getRetrievalStats(): void {
    this.retrievalService.getRetrievalStats(this.clientId, this.projectId).subscribe(
      result => {
        this.retrievalStatsList = this.assignAndNotify(result);
      });
  }

  getChaseStatusForTimePeriodBarKpi(): void {
    return;
  }

  getPendsPieKpi(): void {
    return;
  }

  getChasesNotRetrievedPieKpi(): void {
    return;
  }

  getFieldTechAppointmentsBarKpi(): void {
    return;
  }

  getSchedulingStatusKpi(): void {
    this.retrievalService.getSchedulingStatusKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.schedulingMaxValue = this.getKpiMaxValue(result.data) + 100;

        this.schedulingStatusLabelModel = new LabelModel({ labels: result.labels });
        this.schedulingStatusArray = [
          new DataModel({
            label: "To Be Contacted", data: result.data[0], backgroundColor: "#add8e6",
            borderColor: "#add8e6", fill: false,
            parameters: result.ids,
            url: [`reporting/documentsource`],
          }),
          new DataModel({
            label: "Contacted", data: result.data[1], backgroundColor: "#565656",
            borderColor: "#565656", fill: false,
            url: [`reporting/documentsource`],
          }),
          new DataModel({
            label: "Past Due", data: result.data[2], backgroundColor: "#f2917d",
            borderColor: "#f2917d", fill: false,
            url: [`reporting/documentsource`],
          }),
          new DataModel({
            label: "Partially Scheduled", data: result.data[3], backgroundColor: "#bff298",
            borderColor: "#bff298", fill: false,
            url: [`reporting/documentsource`],
          }),
          new DataModel({
            label: "Scheduled", data: result.data[4], backgroundColor: "#fcffaf",
            borderColor: "#fcffaf", fill: false,
            url: [`reporting/documentsource`],
          }),
        ];

        this.initializeSchedulingKpi();
        this.changeDetector.markForCheck();
      });
  }

  getRetrievalActivityKpi(): void {
    this.retrievalService.getRetrievalActivityKpi(this.clientId, this.projectId)
      .subscribe(result => {

        this.retrievalActivityLabelModel = new LabelModel({ labels: result.labels });
        this.retrievalActivityArray = [
          new DataModel({
            label: "Intake", data: result.data[0], backgroundColor: "#63A0F7",
            borderColor: "#63A0F7", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "QA", data: result.data[1], backgroundColor: "#EE6547",
            borderColor: "#EE6547", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "PSR", data: result.data[2], backgroundColor: "#C8D0DB",
            borderColor: "#C8D0DB", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "EMR", data: result.data[3], backgroundColor: "#FAEF3C",
            borderColor: "#FAEF3C", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "Field Tech", data: result.data[4], backgroundColor: "#1263D8",
            borderColor: "#1263D8", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "Pended", data: result.data[5], backgroundColor: "#0BB455",
            borderColor: "#0BB455", fill: false,
            url: [`reporting/chase`],
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }

  getContactActivityKpi(): void {
    this.retrievalService.getContactActivityKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.contactActivityLabelModel = new LabelModel({ labels: result.labels });
        this.contactActivityArray = [
          new DataModel({
            label: "Calls", data: result.data[0], backgroundColor: "#EE6547",
            borderColor: "#EE6547", fill: false,
            parameters: result.ids,
            url: [`reporting/contactlog`],
          }),
          new DataModel({
            label: "Emails", data: result.data[1], backgroundColor: "#FAEF3C",
            borderColor: "#FAEF3C", fill: false,
            parameters: result.ids,
            url: [`reporting/contactlog`],
          }),
          new DataModel({
            label: "Faxes", data: result.data[2], backgroundColor: "#0BB455",
            borderColor: "#0BB455", fill: false,
            url: [`reporting/contactlog`],
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }

  loadAddressDetail(documentSourceId) {
    this.router.navigate(["retrieval", "addressdetail", documentSourceId]);
  }

  loadEMRDetail(documentSourceId) {
    this.router.navigate(["retrieval", "emr", documentSourceId]);
  }

  loadFTDetail(documentSourceId) {
    this.router.navigate(["retrieval", "ft", documentSourceId]);
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);
    return dataList;
  }

  getRetrievalPendsKpi(): void {
    this.retrievalService.getRetrievalPendsKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.retrievalPendsStatusLabelModel = new LabelModel({ labels: result.labels });
        this.retrievalPendsStatusArray = [
          new DataModel({
            label: "New",
            data: result.data[0],
            backgroundColor: "#63A0F7",
            borderColor: "#63A0F7",
            fill: false,
            parameters: result.ids,
            url: [`reporting/pend`],
          }),
          new DataModel({
            label: "In Progress", data: result.data[1], backgroundColor: "#EE6547",
            borderColor: "#EE6547", fill: false,
            url: [`reporting/pend`],
          }),
          new DataModel({
            label: "Resolved", data: result.data[2], backgroundColor: "#C8D0DB",
            borderColor: "#C8D0DB", fill: false,
            parameters: result.ids,
            url: [`reporting/pend`],
          }),
          new DataModel({
            label: "Closed", data: result.data[3], backgroundColor: "#FAEF3C",
            borderColor: "#FAEF3C", fill: false,
            url: [`reporting/pend`],
          }),
          new DataModel({
            label: "Request to Resolve", data: result.data[4], backgroundColor: "#1263D8",
            borderColor: "#1263D8", fill: false,
            url: [`reporting/pend`],
          }),
          new DataModel({
            label: "Request to Close", data: result.data[5], backgroundColor: "#0BB455",
            borderColor: "#0BB455", fill: false,
            url: [`reporting/pend`],
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }

  getKpiMaxValue(data): number {
    let max = -Infinity;
    for (const item of data) {
      let len = item.length;
      while (len--) {
        if (Number(item[len]) > max) {
          max = Number(item[len]);
        }
      }
    }
    return max;
  }

  getRetrievalProjectionsKpi() {
    this.retrievalService.getRetrievalProjectionsKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.retrievalProjectionsLabelModel = new LabelModel({ labels: result.labels });
        this.retrievalProjectionsArray = [
          new DataModel({
            label: "CHASES", data: result.data[0], backgroundColor: "#b3daff",
            borderColor: "#b3daff", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }
}
