<div class="address-detail-comments-container">
  <textarea maxlength="1000"
            class="address-detail-comments-container__commenttextarea"
            placeholder="Type here to reply."
            [(ngModel)]="commentText"></textarea>
  <div class="address-detail-comments-container__save-comment">
    <app-button (onClick)="saveComments()"
                text="ADD COMMENTS"
                [disabled]="!enableAddCommentBtn"></app-button>
  </div>
  <app-comments [commentItems]="commentItems"></app-comments>
</div>