import { IAutoMapper } from "../automapper/interfaces";

export const mapClientOrgModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClientOrgModel")
    .forMember("clientId", o => o.clientId)
    .forMember("name", o => o.name);
};

export class ClientOrgModel {
  readonly clientId?: number;
  readonly name: string;

  constructor(options: {
    clientId?: number;
    name: string;
  }) {
    this.clientId = options.clientId;
    this.name = options.name;
  }
}
