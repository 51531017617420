import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SignInUserDropdown } from "./signin-user-dropdown.model";

@Component({
  selector: "app-signin-user-dropdown",
  templateUrl: "./signin-user-dropdown.component.html",
  styleUrls: [
    "./signin-user-dropdown.component.scss",
    "../dropdown/dropdown.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninUserDropdownComponent extends DynamicControlDirective<SignInUserDropdown> implements OnInit, OnDestroy {
  private sink = new SubSink();

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }


  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.changeDetector.markForCheck()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }


  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get classes(): string {
    return this.getClasses("dropdown");
  }

}
