<div class="control control--input control--textbox" [hidden]="hidden">
  <div class="control__header">
    <label class="control__header__label">Page Number</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="model.readonly"
    [disabled]="model.disabled"
    type="number"
    placeholder="Page Number"
    [(ngModel)]="model.saveInfo.pageNumber"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>

<div
  [formGroup]="formGroup"
  [hidden]="hidden"
  class="control control--input control--dropdown control--provider-dropdown">

  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>

  <p-dropdown
    #providerDropdown
    appendTo="body"
    [styleClass]="classes"
    [options]="options"
    [placeholder]="model.placeholder"
    [name]="model.key"
    [inputId]="model.key"
    (onChange)="onChange.emit($event)"
    (onInput)="onInput.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    [formControlName]="model.key">
  </p-dropdown>

  <app-button
    class="control__search"
    styleType="pi"
    type="pi"
    icon="search"
    [focusable]="false"
    [disabled]="!enableSearch"
    (onClick)="openProviderSearchModal()">
  </app-button>

</div>

<div class="control control--input control--textbox" [hidden]="hidden">
  <div class="control__header">
    <label class="control__header__label">Admin Value</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="true"
    [disabled]="model.disabled"
    placeholder="Admin Value"
    [ngModel]="adminValueText"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>

<div class="control control--input control--dropdown" [hidden]="hidden">
  <div class="control__header">
    <label class="control__header__label">Confirm</label>
  </div>
  <p-dropdown
    appendTo="body"
    styleClass="control__input control__input--dropdown"
    [options]="validationOptions"
    placeholder="Confirm"
    [(ngModel)]="model.saveInfo.validationId"
    (ngModelChange)="validationChange()"
    [disabled]="model.disabled"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)">
  </p-dropdown>
</div>

<div class="control control--input control--textarea" [hidden]="hiddenNote">
  <div class="control__header">
    <label class="control__header__label">Note</label>
  </div>
  <textarea pInputTextarea
    class="control__input control__input--textarea"
    [ngStyle]="{ resize: false }"
    [rows]="2"
    placeholder="Write a note..."
    [(ngModel)]="model.saveInfo.validationNote"
    [disabled]="model.disabled || hiddenNote"
    required
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)">
  </textarea>
</div>

<!-- NOTE: The ngIf fixes a bug from sharing the app national provider grid -->
<app-modal class="search" *ngIf="visibleProviderSearchModalHack" [(visible)]="visibleProviderSearchModal">
  <div class="control control--input control--textbox control--search">
    <div class="control__header">
      <label class="control__header__label" for="SearchProviderInput">Search for Provider</label>
    </div>
    <input
      pInputText
      class="control__input control__input--textbox"
      name="SearchProviderInput"
      id="SearchProviderInput"
      [readonly]="model.readonly"
      [disabled]="model.disabled"
      placeholder="Ex: John Smith Los Angeles 90012"
      [(ngModel)]="providerSearchText"
      (keyup.enter)="search(null)" />
    <app-button text="Search" (onClick)="search($event)"></app-button>
    <div class="search__example">Search across all columns (Ex: John Smith Los Angeles 90012)</div>
  </div>
  <div class="clearfix"></div>
  <app-national-provider-grid class="providers" (onSelect)="addAndSelect($event)" [additionalFilters]="additionalFilters"></app-national-provider-grid>
</app-modal>