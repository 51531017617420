import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from "primeng/inputtext";
import { SelectButtonModule } from "primeng/selectbutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { TabViewModule } from "primeng/tabview";
import { Checkbox } from "../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { SharedModule } from "../../../shared/shared.module";
import { InternalPendsDetailHeaderService } from "../internal-pends/internal-pends-detail/internal-pends-detail-header/internal-pends-detail-header.service";
import { InternalPendsDetailInfoService } from "../internal-pends/internal-pends-detail/internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsModule } from "../internal-pends/internal-pends.module";
import { ChaseListComponent } from "./chase-list/chase-list.component";
import { ContactLogListComponent } from "./contact-log-list/contact-log-list.component";
import { MasterDocumentSourceListComponent } from "./master-document-source-list/master-document-source-list.component";
import { PendListComponent } from "./pend-list/pend-list.component";
import { ReportingRoutingModule } from "./reporting-routing.module";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    SplitButtonModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    SelectButtonModule,
    AutoCompleteModule,
    CalendarModule,
    InputTextModule,
    TabViewModule,
    ReportingRoutingModule,
    InternalPendsModule,
  ],
  declarations: [
    MasterDocumentSourceListComponent,
    ChaseListComponent,
    ContactLogListComponent,
    PendListComponent,
  ],
  providers: [
    InternalPendsDetailInfoService,
    InternalPendsDetailHeaderService,
  ],
  exports: [
    TabViewModule,
  ],
})
export class ReportinglModule { }
