<div class="fBlock">
  <div class="thumbnail__filter">
    <form-factory [formGroup]="formGroup" [model]="thumbnailFilter" (onChange)="thumbnailFilterChange($event)">
    </form-factory>
  </div>
  <div class="thumbnail__move-back-pages" *ngIf="hasMoveBackPermission">
    <div class="thumbnail__move-back-pages__allow-move-back">
      <form-factory [formGroup]="formGroup" [model]="moveBackDocumentEnabled"
        (onChange)="onChangeMoveBackDocumentEnabled($event)"></form-factory>
    </div>
    <div class="thumbnail__move-back-pages__move-back-reasons">
      <app-bulk-actions-dropdown *ngIf="thumbnailEditMode" class="grid__menu__item bulk-action"
        [actions]="moveBackChasePageTypeOptions" [selectedItems]="selectedItems"
        (onSelectionChange)="onSelectionChange($event)" [hasData]=true>
      </app-bulk-actions-dropdown>
    </div>
  </div>
  
  <div class="thumbnail__add-document">
    <app-file-upload #fileUpload chooseLabel="ADD" [data]="uploadData" [successMessage]="uploadSuccessMessage"
      [showUploadButton]=false [showCancelButton]=false [isAutoUploadThumbnail]="usePresignedURLs" [isAutoUpload]="!usePresignedURLs" (onUpload)="uploadDocument($event)">
    </app-file-upload>
  </div>
  <app-request-document-modal [(visible)]="isRequestDocumentModalOpen" [chaseId]="chaseId" [addressId]="addressId">
  </app-request-document-modal>
</div>
<cdk-virtual-scroll-viewport #thumbnailExpandedViewport class="thumbnail__viewport" [ngClass]="{ 'max': isMaximized }"
  [itemSize]=itemSize [minBufferPx]="itemSize * 2" [maxBufferPx]="itemSize * 3"
  (scrolledIndexChange)="updateIndex($event)">
  <div class="thumbnail__container" *cdkVirtualFor="let rowOfThumbnails of allThumbnails;">
    <div class="thumbnail__row" *ngFor="let thumbnail of rowOfThumbnails; trackBy: trackByIndex; let i = index;">
      <ng-container *ngIf="hasPageAndSource(thumbnail)">
        <div *ngIf="isDocumentThumbnail(thumbnail); else nonDocumentThumbnail;" (click)="thumbnailClicked(thumbnail)"
          class="thumbnail__cell" [ngClass]="{'thumbnail__cell--overlay': isThumbnailSelected(thumbnail) }">
          <ng-container *ngIf="hasThumbnailImageUrl(thumbnail); else placeholderThumbnail">
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <img [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__image--style thumbnail__spine--{{thumbnail.documentThumbnail.thumbnailSpineColor}}"
              [src]="getThumbnailSource(thumbnail)" alt="Document Preview Not Available" />
            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">{{thumbnail.pageNumber}}</div>
          </ng-container>



          <ng-template #placeholderThumbnail>
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <div [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__placeholder  thumbnail__non-document thumbnail__spine--{{thumbnail.documentThumbnail.thumbnailSpineColor}}">
              <div class="thumbnail__non-document--text">Document
                Preview
                Not Available</div>
            </div>
            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">
              {{thumbnail.pageNumber}}</div>
          </ng-template>
          <div class="overlay"></div>
        </div>
        <ng-template #nonDocumentThumbnail>
          <ng-container *ngIf="isCoversheetThumbnail(thumbnail); else nonCoversheetThumbnail">
            <div class="thumbnail__wrapper">
             <div (click)="coversheetClicked(thumbnail)" class="thumbnail__image thumbnail__coversheet">
            <div
                class="thumbnail__non-document thumbnail__spine--{{thumbnail.documentThumbnail.thumbnailSpineColor}} thumbnail__background--{{thumbnail.documentThumbnail.thumbnailBackgroundColor}}">
                <div
                  class="thumbnail__non-document--label thumbnail__non-document--label--{{thumbnail.documentThumbnail.thumbnailSpineColor}}">
                  <app-icon iconName="file-medical" iconStyle="fal"></app-icon>
                </div>
              </div>             
              <div *ngIf="thumbnail.hasDocumentListItem" class="thumbnail__coversheet__tooltip">
                <div class="thumbnail__coversheet__tooltip--item"><span class="bold">File Name:
                  </span>{{thumbnail.documentListItem.fileName}}</div>
                <div class="thumbnail__coversheet__tooltip--item"><span class="bold">Date Attached:
                  </span>{{thumbnail.documentListItem.createDate | date: 'MM-dd-yyyy':'GMT'}}</div>
                <div class="thumbnail__coversheet__tooltip--item"><span class="bold">Uploaded By:
                  </span>{{thumbnail.documentListItem.createBy}}</div>
                <div class="thumbnail__coversheet__tooltip--item"><span class="bold">Retrieval Source:
                  </span>{{thumbnail.documentListItem.retrievalTypeName}}</div>
                <div class="thumbnail__coversheet__tooltip--item"><span class="bold">Total Pages:
                  </span>{{thumbnail.documentListItem.numberOfPages}}</div>
              </div>         
             
            </div>
            <div class="thumbnail__cell--label">Document <span>{{thumbnail.documentQueueId}}</span></div>
          </div>
          </ng-container>
          <ng-template #nonCoversheetThumbnail>
            <div class="thumbnail__cell" (click)="nonThumbnailClicked(thumbnail)">
              <div [ngClass]="{' thumbnail__action-document': !hasThumbnails() }" class="thumbnail__image thumbnail__non-document">
                <div
                  class="thumbnail__non-document--label thumbnail__non-document--label--{{thumbnail.documentThumbnail.thumbnailSpineColor}}">
                  <app-icon [iconName]="getNonThumbnailIcon(thumbnail)" iconStyle="fal"></app-icon>
                </div>
              </div>
              <span class="thumbnail__cell--label">{{thumbnail.thumbnailLabel}}</span>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
    </div>
  </div>

</cdk-virtual-scroll-viewport>

<div class="thumbnail__singleExpand">
  <div (click)="toggleThumbnailView('collapse');" class="thumbnail__toggle">
    <div class="icon">
      <app-icon iconStyle="far" iconName="square"></app-icon>
    </div>
  </div>
  <div class="thumbnail__toggle thumbnail__toggle--selected">
    <div class="icon">
      <app-icon iconStyle="far" iconName="th"></app-icon>
    </div>
  </div>
</div>

<app-confirmation-modal class="delete-confirmation-modal" [(visible)]="isConfirmMoveBackAllVisible" header="ATTENTION"
  acceptText="CONTINUE" rejectText="CANCEL" (onYes)="moveBackChasePages()">
  {{ moveChaseBackConfirmationText }}
</app-confirmation-modal>