export class RetrieverUpdateModel {
    retrieverName: string;
    chaseIds: number[];

    constructor(options: {
        retrieverName?: string;
        chaseIds: number[];
    }) {
      this.retrieverName = options.retrieverName;
      this.chaseIds = options.chaseIds;
    }
  }
