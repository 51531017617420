import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { FileDownloadRequest } from "../../../../project/project-files/file-download-request.model";
import { ProjectFileService } from "../../../../project/project-files/project-file.service";
import { InternalPendsDetailInfoService } from "../../internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsDetailDocumentsService } from "../internal-pends-detail-documents.service";
import { PendDocumentItem } from "../pend-document-list-item.model";

@Component({
  selector: "app-internal-pends-document-list",
  templateUrl: "./internal-pends-document-list.component.html",
  styleUrls: ["./internal-pends-document-list.component.scss"],
  providers: [InternalPendsDetailDocumentsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsDocumentListComponent implements OnInit {
  pendDocumentItems = List<PendDocumentItem>();
  @Input() display = "inline";
  @Input() width = "300px";
  @Input() height = "400px";
  chasePendId: number;
  subscription: Subscription;

  constructor(
    private service: InternalPendsDetailDocumentsService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private internalPendsDetailInfoService: InternalPendsDetailInfoService,
    private readonly filesService: ProjectFileService
  ) {
    this.chasePendId = this.route.snapshot.parent.params.pendGd;
  }

  ngOnInit() {
    this.getLatestPendDocuments();
    this.subscription = this.internalPendsDetailInfoService.refresh.subscribe(id => {
      this.getLatestPendDocuments();
    });
  }

  downloadDocument(item: PendDocumentItem): void {
    const request = new FileDownloadRequest();
    request.fileId = item.documentKey;
    request.fileType = "pendFiles";

    this.filesService.downloadFile(request).subscribe(result =>  {
      this.filesService.printFile(result, item.documentKey);
    });
  }

  getLatestPendDocuments(): void {
    this.service
      .getPendDocuments(this.chasePendId)
      .subscribe(items => {
        this.pendDocumentItems = this.assignAndNotify(items.slice(0, 6));
      });
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  trackByIndex(index, item) {
    return index;
  }
}
