import { IAutoMapper } from "../../../core/automapper/interfaces";
import { TagSourceType } from "./tag-source-type.enum";
import { TagType } from "./tag-type.enum";

export const mapTagItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "TagItems")
    .forMember("assignToTagId", o => o.assignToTagId)
    .forMember("tagId", o => o.tagId)
    .forMember("organizationId", o => o.organizationId)
    .forMember("tagTypeId", o => o.tagTypeId)
    .forMember("tagSourceId", o => o.tagSourceId)
    .forMember("tagText", o => o.tagText)
    .forMember("color", o => o.color)
    .forMember("objectIds", o => o.objectIds);
};

export class TagItems {
  assignToTagId: number;
  tagId: number;
  organizationId: number;
  tagTypeId: TagType;
  tagSourceId: TagSourceType;
  tagText: string;
  color: string;
  objectIds: number[];

  constructor(options: {
    assignToTagId?: number;
    tagId?: number;
    organizationId?: number;
    tagTypeId?: TagType;
    tagSourceId?: TagSourceType;
    tagText?: string;
    color?: string;
    objectIds?: number[];

  }) {
    this.assignToTagId = options.assignToTagId;
    this.tagId = options.tagId;
    this.organizationId = options.organizationId;
    this.tagTypeId = options.tagTypeId;
    this.tagSourceId = options.tagSourceId;
    this.tagText = options.tagText;
    this.color = options.color;
    this.objectIds = options.objectIds;
  }
}
