import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { VendorDetail } from "../../../../invoice/vendor-detail/vendor-detail.model";
import { AddressDetailInfoAssignment } from "./address-detail-info-assignment.model";

@Injectable({
  providedIn: "root",
})
export class AddressDetailInfoAssignmentService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  saveAssignment(data: AddressDetailInfoAssignment) {
    const url = `${this.baseApiUrl}address/assign`;

    return this.http.post(url, data);
  }

  assignAddressToVendor(addressId: number, vendorId: number): Observable<null> {
        const url = `${this.baseApiUrl}address/vendor/assign?masterDocumentSourceId=${addressId}&vendorId=${vendorId}`;
        return this.http.post(url, {}) as Observable<null>;
  }

  getVendors(invoiceType?: string): Observable<VendorDetail[]> {
    const url = `${this.baseApiUrl}invoice/vendors?invoiceTypes=${invoiceType}`;
    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("default", "VendorDetail"))
      );
    }

  confirmVendor(addressId: number): Observable<null> {
      const url = `${this.baseApiUrl}address/vendor/confirm?masterDocumentSourceId=${addressId}`;
      return this.http.post(url, {}) as Observable<null>;
  }

  deleteInvoices(addressId: number): Observable<null> {
      const url = `${this.baseApiUrl}invoice/delete?masterDocumentSourceId=${addressId}`;
      return this.http.post(url, {}) as Observable<null>;
  }

  getAllChasesForDeniedVendor(addressId: number, vendorId: number): Observable<number[]> {
    const url = `${this.baseApiUrl}address/vendor/chaseIds?masterDocumentSourceId=${addressId}&vendorId=${vendorId}`;
    return this.http.get(url) as Observable<number[]>;
  }

  createInvoiceShellForNet30DeniedVendor(chaseIds: number[], invoiceAmount: number): Observable<null> {
    const url = `${this.baseApiUrl}invoice/net30/denied/vendor?invoiceAmount=${invoiceAmount}`;
    return this.http.post(url, chaseIds) as Observable<null>;
  }

}
