import { StringHelper } from "../../../utilities/contracts/string-helper";


export class ProviderPacketModel {
  providerPacketId: number;
  masterDocumentSourceId: number;
  filePath: string;
  fileDate: string;
  uploadKey: string;
  fileName: string;
  groupName: string;
  organizationId: number;

  constructor(options: {
    providerPacketId: number;
    masterDocumentSourceId: number;
    filePath: string;
    fileDate: string;
    uploadKey: string;
    fileName: string;
    groupName: string;
    organizationId: number;

  }
  ) {
    this.providerPacketId = options.providerPacketId;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.filePath = StringHelper.clean(options.filePath);
    this.fileDate = StringHelper.clean(options.fileDate);
    this.uploadKey = StringHelper.clean(options.uploadKey);
    this.fileName = StringHelper.clean(options.fileName);
    this.groupName = StringHelper.clean(options.groupName);
    this.organizationId = options.organizationId;
  }
}
