import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-dynamic-entity-validation",
  templateUrl: "./dynamic-entity-validation.component.html",
  styleUrls: ["./dynamic-entity-validation.component.scss"],
})
export class DynamicEntityValidationComponent implements OnInit, OnChanges {
  @Input() isValidInfo: boolean;
  @Output() onSelect = new EventEmitter<boolean>();
  @Input() textPositive = "Yes";
  @Input() textNegative = "No";
  @Input() isValidationEnabled = true;
  yesBtnToggle = false;
  noBtnToggle = false;
  constructor() { }

  ngOnInit(): void {
  }

  get yesBtnClass(): string {
    return this.yesBtnToggle ? "yes-btn yes-btn--active" : "yes-btn";
  }

  get noBtnClass(): string {
    return this.noBtnToggle ? "no-btn no-btn--active" : "no-btn";
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isValidInfo) {
      if (changes.isValidInfo.currentValue != null && changes.isValidInfo.currentValue != undefined) {
        if (changes.isValidInfo.currentValue === true) {
          this.yesBtnToggle = true;
        } else if (changes.isValidInfo.currentValue === false) {
          this.noBtnToggle = true;
        }
      } else if (changes.isValidInfo.currentValue == null) {
        this.yesBtnToggle = null;
        this.noBtnToggle = null;
      }
    }
  }

  onValidationSelection(event: MouseEvent, isValid: boolean): void {
    isValid ? this.toggleYesBtn() : this.toggleNoBtn();
    event.stopPropagation();
    event.preventDefault();
    this.onSelect.emit(isValid);
  }

  private toggleYesBtn(): void {
    this.yesBtnToggle = true;
    this.noBtnToggle = false;
  }

  private toggleNoBtn(): void {
    this.noBtnToggle = true;
    this.yesBtnToggle = false;
  }

}
