import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-stalled-documents-report",
  templateUrl: "./stalled-documents-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StalledDocumentsReportComponent {
  filters: string[] = ["RetrievalType", "FileCreateDate"];
  dashboardType = LookerRiskDashboardType.STALLED_DOCUMENTS_REPORT;
  hideFilters: string[] = [HideFilters.ORG_ID];
}
