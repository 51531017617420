<app-modal
  [(visible)]="visible"
  (visibleChange)="change($event)"
  header="Hold Document">

  <app-hold-document-form
    [entityId]="entityId"
    [workflowStatus]="workflowStatus"
    (value)="setForm($event)"></app-hold-document-form>

  <footer>
    <app-button
      class="header-button"
      text="Submit"
      (onClick)="submitDocumentHold()"
      [disabled]="!hasForm()"></app-button>
  </footer>

</app-modal>
