<div class="chase-detail-document-button">
  <div class="chase-document-actions">
    <app-button text="REQUEST ADDITIONAL DOCUMENTATION" [hidden]="requestAdditionalDocumentsButtonHidden" (onClick)="openAdditionalDocumentationModal()"></app-button>
    <div class="upload-action">
      <app-upload-message></app-upload-message>
      <app-file-upload #fileUpload
                       chooseLabel="CHOOSE MEDICAL RECORD(S)"
                       [data]="uploadData"
                       [successMessage]="uploadSuccessMessage"
                       (onUpload)="uploadDocumentsSeperately($event)">
      </app-file-upload>
      <app-membercentric-doc-attachment [(visible)]="showMemberCentricDocAttachmentInfo"
                                        [memberCentricGridData]="memberCentricData"
                                        [targetChaseId]="chaseId"
                                        (onConfirmDocAttachment)="uploadDocToMemberCentricChases($event)">
      </app-membercentric-doc-attachment>
    </div>   
  </div>
</div>
<div class="document-list">
  <app-chase-document-list display="inline" width="300px" height="400px" maxDocuments="0"></app-chase-document-list>
</div>

<app-request-document-modal [(visible)]="isRequestDocumentModalOpen" [chaseId]="chaseId" [addressId]="addressId"></app-request-document-modal>
<app-confirmation-modal
  [(visible)]="showUploadWarningModal"
  header="WARNING"
  (onCancel)="cancelUpload()"
  (onYes)="continueWithUploadSeparately()"
  acceptText="OK"
  rejectText="CANCEL">
  The current chart is being processed, in order to perform data entry you should move the chart back to MRR, upload the record and re-abstract the information.
</app-confirmation-modal>
