<div container-header class="analytics-container__header">
  <app-detail-header-template>
    <div class="submenu-content" bottom-left>
      <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
    </div>
    <div bottom-right>
      <app-utc-time-zone></app-utc-time-zone>
    </div>
  </app-detail-header-template>
</div>
<div>
  <div class="filter-container">
    <div class="filter-applied">
      <app-analytics-filter-applied [formFilterRequest]="formFilterRequest" (isShowFilterModal)="showFilters()"
        (removeFilter)="onRemoveFilter($event)" [isRemovableItem]="false"></app-analytics-filter-applied>
    </div>
    <div (click)="showFilters()" class="icon-container" [hidden]="isFilterIconVisible">
      <app-icon iconName="filter"></app-icon>
    </div>
    <div title="Information about downloads" class="icon-container --margin-right" (click)="showHelpModal()">
      <app-icon iconStyle="far" iconName="question-circle" ></app-icon>
    </div>
  </div>
  <div>
    <app-modal class="analytics__filter" [(visible)]="isFiltersVisible">
      <app-tab-menu orientation="left" #tabTemplate>
        <app-tab-panel header="Service Organizations" class="checkbox-group__halves" *ngIf="isParentOrg">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="organizationInput"
                          (onChange)="onOrganizationChange($event, model)"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="HEDIS Projects" class="checkbox-group__halves" *ngIf="showFilter('Project')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput" (onChange)="onProjectChange()">
              {{selectedTab}}
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Overread2 Date" *ngIf="showFilter('Overread2Date')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="overread2FromDate" (onChange)="onDateChange(overread2FromDate,overread2ToDate)" (onBlur)="onDateChange(overread2FromDate,overread2ToDate)"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" [model]="overread2ToDate" (onChange)="onDateChange(overread2FromDate,overread2ToDate)" (onBlur)="onDateChange(overread2FromDate,overread2ToDate)"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="HEDIS Measures" class="checkbox-group__halves" *ngIf="showFilter('Measure')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measuresInput" (onChange)="onSelectChange($event)"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="GAP" class="checkbox-group__halves" *ngIf="showFilter('Gap')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="numeratorsInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Retrieval Type" class="checkbox-group__halves" *ngIf="showFilter('RetrievalType')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="retrievalTypeInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Address" *ngIf="showFilter('Address')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressGroupInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member ID" *ngIf="showFilter('MemberId')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberIdInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member First Name" *ngIf="showFilter('MemberFirstName')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member Last Name" *ngIf="showFilter('MemberLastName')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member DOB" *ngIf="showFilter('MemberDob')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberDobInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Abstraction Date" *ngIf="showFilter('AbstractionDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(abstractionFromDate,abstractionToDate)" (onBlur)="onDateChange(abstractionFromDate,abstractionToDate)"
                          [model]="abstractionFromDate">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}" #abstractDate>
            <form-factory [formGroup]="form" (onChange)="onDateChange(abstractionFromDate,abstractionToDate)" (onBlur)="onDateChange(abstractionFromDate,abstractionToDate)"
                          [model]="abstractionToDate">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="DE Date" *ngIf="showFilter('DEDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="deFromDate">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="deToDate">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Pend Date" *ngIf="showFilter('PendDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" (onBlur)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" [model]="pendCreateDateFrom">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" (onBlur)="onDateChange(pendCreateDateFrom,pendCreateDateTo)" [model]="pendCreateDateTo">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Pend Status" class="checkbox-group__halves" *ngIf="showFilter('PendStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Pend Code" class="checkbox-group__halves" *ngIf="showFilter('PendCode')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member Key" *ngIf="showFilter('MemberKey')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberKeyInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="NPI" *ngIf="showFilter('NPI')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="npiInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Chase ID" *ngIf="showFilter('ChaseId')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Response Date" *ngIf="showFilter('ResponseDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(responseDateFromInput,responseDateToInput)" (onBlur)="onDateChange(responseDateFromInput,responseDateToInput)"
                          [model]="responseDateFromInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}" #responseDate>
            <form-factory [formGroup]="form" (onChange)="onDateChange(responseDateFromInput,responseDateToInput)" (onBlur)="onDateChange(responseDateFromInput,responseDateToInput)"
                          [model]="responseDateToInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Chart Received Date" *ngIf="showFilter('ChartReceived')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('chartReceived')"
                          [model]="chartReceivedDateFromInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="dateValidationToAndForm('chartReceived')"
                          [model]="chartReceivedDateToInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Completion Date" *ngIf="showFilter('CompletionDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="validateDateRange(completionFromDate, completionToDate)"
                          [model]="completionFromDate"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="validateDateRange(completionFromDate, completionToDate)"
                          [model]="completionToDate"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Update Date" *ngIf="showFilter('UpdateDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(updateDateFrom,updateDateTo)" (onBlur)="onDateChange(updateDateFrom,updateDateTo)"
                          [model]="updateDateFrom"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="onDateChange(updateDateFrom,updateDateTo)" (onBlur)="onDateChange(updateDateFrom,updateDateTo)"
                          [model]="updateDateTo"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Retrieval Date" *ngIf="showFilter('RetrievalDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="retrievalDateFrom"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="retrievalDateTo"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Start Date" *ngIf="showFilter('StartDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="startDateInput" (onChange)="onDateChange(startDateInput,endDateInput)" (onBlur)="onDateChange(startDateInput,endDateInput)"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="End Date" *ngIf="showFilter('EndDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" [model]="endDateInput" (onChange)="onDateChange(startDateInput,endDateInput)" (onBlur)="onDateChange(startDateInput,endDateInput)"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Workflow Status" class="checkbox-group__halves" *ngIf="showFilter('WorkflowStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="workflowStatusInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Contact Date" *ngIf="showFilter('ContactDate')">
          <div class="-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(contactDateFromInput,contactDateToInput)" (onBlur)="onDateChange(contactDateFromInput,contactDateToInput)"
                          [model]="contactDateFromInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="onDateChange(contactDateFromInput,contactDateToInput)" (onBlur)="onDateChange(contactDateFromInput,contactDateToInput)"
                          [model]="contactDateToInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="File Create Date" *ngIf="showFilter('FileCreateDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="fileCreateDateFromInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="fileCreateDateToInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Contact Method" class="checkbox-group__halves" *ngIf="showFilter('ContactMethod')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactMethodTypeInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Status" class="checkbox-group__halves" *ngIf="showFilter('CallType')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="callTypeInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Load Date" *ngIf="showFilter('LoadDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="loadStartDateInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="loadEndDateInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Dataload Date" *ngIf="showFilter('DataLoadDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="dataLoadStartDateInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="dataLoadEndDateInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member DOB" *ngIf="showFilter('MemberDobFromTo')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberDobFromInput">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberDobToInput">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Member Gender" *ngIf="showFilter('MemberGender')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberGenderInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Address" *ngIf="showFilter('AddressGroup')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
            <form-factory [formGroup]="form" [model]="cityInput"></form-factory>
            <form-factory [formGroup]="form" [model]="stateInput"></form-factory>

          </div>
        </app-tab-panel>
        <app-tab-panel header="Business Date" *ngIf="showFilter('BusinessDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="businessDateFromInput">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="businessDateToInput">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Billing Date" *ngIf="showFilter('BillingDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(billingDateFrom,billingDateTo)" (onBlur)="onDateChange(billingDateFrom,billingDateTo)" [model]="billingDateFrom">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="onDateChange(billingDateFrom,billingDateTo)" (onBlur)="onDateChange(billingDateFrom,billingDateTo)" [model]="billingDateTo">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Create Date" *ngIf="showFilter('CreateDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" (onChange)="onDateChange(createFromDate,createToDate)" (onBlur)="onDateChange(createFromDate,createToDate)" [model]="createFromDate">
            </form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12" [ngClass]="{'analytics__filter--highlight': showBorder}">
            <form-factory [formGroup]="form" (onChange)="onDateChange(createFromDate,createToDate)" (onBlur)="onDateChange(createFromDate,createToDate)" [model]="createToDate">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Products" *ngIf="showFilter('Products')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="productsInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Line of Business" *ngIf="showFilter('LOB')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="lineOfBusinessInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Numerator" class="checkbox-group__halves" *ngIf="showFilter('Numerator')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="numeratorInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Chase Compliance" *ngIf="showFilter('Compliance')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="complianceInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Sample Compliance" *ngIf="showFilter('SampleCompliance')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Health Plan" *ngIf="showFilter('HealthPlan')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="healthPlanInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Contract Number" *ngIf="showFilter('ContractNumber')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contractNumberInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Pend Owner" *ngIf="showFilter('PendOwner')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendOwnerInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Retrieval Owner" *ngIf="showFilter('RetrievalOwner')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="retrievalOwnerInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Document Queue ID" *ngIf="showFilter('DocumentQueueId')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="documentQueueId"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Document State group" *ngIf="showFilter('DocumentStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="documentStatus"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Confirmation Number" *ngIf="showFilter('ConfirmationNumber')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="confirmationNumber"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Provider Gateway PIN" *ngIf="showFilter('ProviderGatewayPin')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="providerGatewayPin"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Contact Method" class="checkbox-group__halves" *ngIf="showFilter('ContactMethodId')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactMethodInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Contact Method Status" class="checkbox-group__halves" *ngIf="showFilter('ContactMethodStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactStatusInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Start Date" class="checkbox-group__halves" *ngIf="showFilter('ContactStartDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactDateStart"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="End Date" class="checkbox-group__halves" *ngIf="showFilter('ContactEndDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="contactDateEnd"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Expected Retrieval" class="checkbox-group__halves" *ngIf="showFilter('ExpectedRetrieval')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="expectedRetrievalInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Project Status" class="checkbox-group__halves" *ngIf="showFilter('ProjectStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectStatusInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Vendor Name" class="checkbox-group__halves" *ngIf="showFilter('VendorName')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="vendorNameInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Special Handling" class="checkbox-group__halves" *ngIf="showFilter('SpecialHandling')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="specialHandlingInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Move Date" class="checkbox-group__halves" *ngIf="showFilter('MoveDateStart') && showFilter('MoveDateEnd')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory (onChange)="updateMinDate(moveDateStart, moveDateEnd)" (onBlur)="updateMinDate(moveDateStart, moveDateEnd)" [formGroup]="form" [model]="moveDateStart"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="moveDateEnd"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Abstraction By" *ngIf="showFilter('AbstractionBy')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="abstractionBy"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Pend By" *ngIf="showFilter('PendBy')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendBy"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Overread2 By" *ngIf="showFilter('Overread2By')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="overread2By"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Coded By" *ngIf="showFilter('CodedBy')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="codedBy"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Updated By" *ngIf="showFilter('UpdatedBy')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="updatedBy"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="User Name" *ngIf="showFilter('UserName')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="userName"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Retrieved Date" class="checkbox-group__halves" *ngIf="showFilter('RetrievedDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory (onChange)="updateMinDate(retrievedDateStart, retrievedDateEnd)" (onBlur)="updateMinDate(retrievedDateStart, retrievedDateEnd)" [formGroup]="form" [model]="retrievedDateStart"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="retrievedDateEnd"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Moveback Date" class="checkbox-group__halves" *ngIf="showFilter('MovebackDate')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory (onChange)="updateMinDate(movebackDateStart, movebackDateEnd)" (onBlur)="updateMinDate(movebackDateStart, movebackDateEnd)" [formGroup]="form" [model]="movebackDateStart"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="movebackDateEnd"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Date Range" class="checkbox-group__halves" *ngIf="showFilter('DateRange')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory (onChange)="updateMinDate(dateRangeStart, dateRangeEnd)" (onBlur)="updateMinDate(dateRangeStart, dateRangeEnd)" [formGroup]="form" [model]="dateRangeStart"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="dateRangeEnd"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Login" *ngIf="showFilter('Login')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="login"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="User Email" *ngIf="showFilter('UserEmail')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="userEmail"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="User Status" *ngIf="showFilter('UserStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="userStatus"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Current Chase Status" *ngIf="showFilter('CurrentChaseStatus')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="currentChaseStatus"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Current Process Step" *ngIf="showFilter('CurrentProcessStep')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="currentProcessStep"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Vendor Invoice type" *ngIf="showFilter('VendorInvoiceType')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="vendorInvoiceType"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Type of Pend" *ngIf="showFilter('PendType')">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendTypeInput"></form-factory>
          </div>
        </app-tab-panel>
      </app-tab-menu>
      <footer>
        <app-button class="reset-filter-button" (onClick)="resetAllFilters()" text="Reset All"></app-button>
        <app-button (onClick)="closeFilters()" text="Update"></app-button>
        <div class="error-message" *ngIf="showErrorMessage">{{errorMessage}}</div>
      </footer>

    </app-modal>
    <app-iframe [visible]="isVisible" [load]="true" iframeSrc="{{lookerUrl}}" iframeWidth="99%"></app-iframe>
  </div>

</div>
<app-analytics-instrucions-modal [(isHelpModalOpen)]="isHelpModalOpen"></app-analytics-instrucions-modal>