import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { BooleanHelper } from "../../utilities/contracts/boolean-helper";
import { ListItem } from "../list/list-item";
import { ChasePendItem } from "./chase-pend-item.model";


@Injectable({
  providedIn: "root",
})
export class CreatePendService {
  reset = new Subject<any>();


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService
  ) { }


  resetFunction(pendId: number) {
    this.reset.next(pendId);
  }

  resetChaseInfo(): void {
    this.reset.next();
  }

  save(chasePendModel: ChasePendItem): Observable<number> {
    const url = `${this.baseApiUrl}Pend/SavePend`;

    return this.http.post(url, chasePendModel, { observe: "response" }).pipe(
      map((response: HttpResponse<any>) => response.ok ? 1 : 0)
    );
  }

  getPendDropdown(isClinical?: boolean, organizationId: number = null): Observable<any> {
    // TODO: Delete the organizationId from this method. Never send the organizationId.
    const urlBase = `${this.baseApiUrl}Pend/GetPendDropdowns`;
    const url = BooleanHelper.isAvailable(isClinical) ? `${urlBase}?isClinical=${isClinical}` : urlBase;

    return this.http.get(url);
  }

  getPendReasons(pendTypeId: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}pend/pendreasons?pendTypeId=${pendTypeId}`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "SelectableInput")));
  }

  getPendCompaniesDropdown(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}pend/companylist`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));

  }

  getPendStatus(isClinical: boolean): Observable<any> {
    const urlBase = `${this.baseApiUrl}pend/getpendstatus`;
    const url = BooleanHelper.isAvailable(isClinical) ? `${urlBase}?isClinical=${isClinical}` : urlBase;

    return this.http.get(url);
  }

  getAllPendDropdown(isClinical: boolean, isActive: boolean): Observable<any> {
    const urlBase = `${this.baseApiUrl}Pend/GetPendDropdowns`;
    const url = `${urlBase}?isClinical=${isClinical}&isActive=${isActive}`;

    return this.http.get(url);
  }
  getRetrievalPendStatisticsData() {
    const url = `${this.baseApiUrl}pend/retrieval/statistics`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }
}
