import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ChaseSearchRequest } from "./chase-search-request-model";
import { ChaseItem } from "./chase-search-result-item";


@Injectable({
  providedIn: "root",
})
export class ChaseSearchService {
  reset = new Subject<any>();
  private chaseIdList = new BehaviorSubject([]);
  chaseIdsList = this.chaseIdList.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  resetFunction(value: string) {

    this.reset.next(value);
  }

  getChaseList(chaseList: number[]) {
    this.chaseIdList.next(chaseList);
  }

  chaseSearchUrl(): string {
    return `${this.baseApiUrl}chase/list`;
  }

  chaseSearch(searchRequest: ChaseSearchRequest): Observable<ChaseItem[]> {
    const url = this.chaseSearchUrl();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const simpleClass = {
      sortOrder: "chaseId",
      sortDirection: "desc",
    };
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, searchRequest, { headers }).pipe(
      map(this.automapper.curryMany("chase-search-item", "ChaseItem"))
    );
  }

  getLightChaseList(searchRequest: {}): Observable<ChaseItem[]> {
    const url = this.chaseSearchUrl();
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, searchRequest, { headers }).pipe(
      map(this.automapper.curryMany("chase-search-item", "ChaseItem"))
    );
  }
}

