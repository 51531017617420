<div [formGroup]="formGroup" [ngClass]="model.classOverride" class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.labelPosition === 'right'; else defaultPosition" class="control__input--labelPosition">
    <div *ngIf="model.hasLabel" class="control__header">
      <label class="control__header__label" [for]="model.key" [title]="model.label">{{ getLabel(model.label, useTitleCase) }}</label>
      <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
      <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
    </div>
    <input pInputText [ngClass]="classes" [readonly]="model.readonly" [type]="model.type"
      [autocomplete]="model.autocomplete" [id]="model.key" [placeholder]="model.placeholder"
      (change)="onChangeEvent($event)" (input)="onInput.emit($event)" (onKeyUp)="onKeyUp.emit($event)"
      (focus)="onFocus.emit($event)" (click)="onClickEvent($event)" (blur)="onBlur.emit($event)"
      [formControlName]="model.key" [title]="model.title" [maxlength]="model.maxlength" />
  </div>

  <ng-template #defaultPosition>
    <div *ngIf="model.hasLabel" class="control__header">
      <label class="control__header__label" [for]="model.key" [title]="model.label">{{ getLabel(model.label, useTitleCase) }}</label>
      <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
      <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
    </div>
    <input
      pInputText
      [ngClass]="classes"
      [readonly]="model.readonly"
      [type]="model.type"
      [autocomplete]="model.autocomplete"
      [id]="model.key"
      [placeholder]="model.placeholder"
      (change)="onChangeEvent($event)"
      (input)="onInput.emit($event)"
      (onKeyUp)="onKeyUp.emit($event)"
      (focus)="onFocus.emit($event)"
      (click)="onClickEvent($event)"
      (blur)="onBlur.emit($event)"
      [formControlName]="model.key"
      [title]="model.title"
      [maxlength]="model.maxlength" />
  </ng-template>


</div>