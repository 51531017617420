import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { AuthService } from "../../../../auth/auth.service";
import { LocalService } from "../../../../core/storage/local.service";
import { MemberValidation } from "../../../../platform/api/member-validation/member-validation.model";
import { RiskService } from "../../../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk.service";
import { DirectoryUserRole } from "../../../../platform/modules/retrieval/directory-user-role";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { TagStateService } from "../../../tags/tag-state.service";

@Component({
  selector: "app-member-validation-yes-no",
  templateUrl: "./member-validation-yes-no.component.html",
  styleUrls: ["./member-validation-yes-no.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberValidationYesNoComponent implements OnInit, OnChanges {
  @Input() isChaseEnabled = false;
  @Input() configuration: MemberValidation;
  @Output() onSelect = new EventEmitter<boolean>();
  @Output() onSelect2 = new EventEmitter<number>();
  @Input() textPositive = "Yes";
  @Input() textNegative1 = "No";
  @Input() textNegative2 = "";

  yesBtnToggle = false;
  noBtnToggle = false;
  noBtnToggle2 = false;

  memberDOB =  false;
  memberGender = false;
  memberName = false;
  isReadOnly = false;

  isMemberValidate = false;

  @ViewChild("yesBtn") yesBtn: ElementRef;

  private sink = new SubSink();

  get yesBtnClass(): string {
    return this.yesBtnToggle ? "yes-btn yes-btn--active" : "yes-btn";
  }

  get noBtnClass(): string {
    return this.noBtnToggle ? "no-btn no-btn--active" : "no-btn";
  }

  get noBtnClass2(): string {
    return this.noBtnToggle2 ? "no-btn2 no-btn2--active" : "no-btn2";
  }

  openChaseAndUserInfo = "openChaseAndUserInfo";
  globalLocalStoreChaseId = null;
  chaseId = null;

  constructor(private tagStateService: TagStateService, private riskService: RiskService,
              private changeDetector: ChangeDetectorRef,
              private localService: LocalService,
              private readonly authService: AuthService,
              private readonly route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.sink.add(
      this.riskService.data
        .subscribe(riskState => {
          this.chaseId = riskState.chaseId;
          this.isReadOnly = !riskState.assignedToCurrentUser;
          if (this.isReadOnly) {
            this.yesBtnToggle = false;
            this.noBtnToggle = false;
          }
          if (!this.isMemberValidate) {
            if (riskState.memberVerified) {
              this.memberName = riskState.memberVerified.memberName;
              this.memberDOB =  riskState.memberVerified.memberDOB;
              this.memberGender = riskState.memberVerified.memberGender;
              this.yesBtnToggle = this.memberName;
              this.noBtnToggle = !this.memberName;
              this.changeDetector.markForCheck();
            }
          }
        })
    );

  }

  isRoleAbstractorOrOverreader(): boolean {
    const arr = this.authService.user.directoryRoleIds;
    if ((arr.length === 1 && arr[0] === DirectoryUserRole.Abstractor) || (arr.length === 1 && arr[0] === DirectoryUserRole.Overreader)) {
      const localChaseUserInfo =  this.localService.get(this.openChaseAndUserInfo, null);
      if (localChaseUserInfo) {
        const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
        this.globalLocalStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
        return this.chaseId !== this.globalLocalStoreChaseId;
      }
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configuration) {
      if (changes.configuration.isFirstChange()) { return; }

      if (changes.configuration.currentValue !== changes.configuration.previousValue) {
        if (changes.configuration.currentValue.isValidInfo.value === "1") {
          this.yesBtnToggle = true;
          this.noBtnToggle = false;
          this.isMemberValidate = true;
        }
      }
    }
  }

  onMemberValidationSelection(event: MouseEvent, isYes: boolean, secondNegative: boolean = false): void {
    if (!this.isChaseEnabled || !NumberHelper.isGreaterThan(this.configuration.dateOfBirth.chaseId, 0)) {
      return;
    }
    if (secondNegative) {
      this.toggleNoBtn2();
      this.emitOnSelect(event, isYes, true);
    } else {
      isYes ? this.toggleYesBtn() : this.toggleNoBtn();
      this.emitOnSelect(event, isYes);
    }
    this.tagStateService.closeExtraTooltip.next(false);
  }

  private toggleYesBtn(): void {
    this.yesBtnToggle = true;
    this.noBtnToggle = false;
    this.noBtnToggle2 = false;
  }

  private toggleNoBtn(): void {
    this.noBtnToggle = true;
    this.yesBtnToggle = false;
    this.noBtnToggle2 = false;
  }

  private toggleNoBtn2(): void {
    this.noBtnToggle = false;
    this.yesBtnToggle = false;
    this.noBtnToggle2 = true;
  }

  private emitOnSelect(event: MouseEvent, isMemberValidated: boolean, secondNegative: boolean = false): void {
    event.stopPropagation();
    event.preventDefault();
    secondNegative ? this.onSelect2.emit(2) : this.onSelect.emit(isMemberValidated);
    if (!secondNegative) {
      this.riskService.isMemberValidate.next(isMemberValidated);
    }
  }
}
