import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AddressDetailState, IAddressDetailStateOptions } from "../platform/modules/retrieval/address-detail/address-detail-state.model";

@Injectable({
  providedIn: "root",
})
export class ProviderDetailStateService {
    state = new BehaviorSubject<any[]>([]);

    setData(data: any[]): void {
        this.state.next(data);
    }
}
