
export class ChaseMoveBack {
  readonly chaseId: number;
  readonly workflowStatusId: number;
  readonly addressId: number;
  readonly chaseNote: string;

  constructor(options: {
    chaseId: number;
    workflowStatusId: number;
    addressId: number;
    chaseNote: string;
  }) {
    this.chaseId = options.chaseId;
    this.workflowStatusId = options.workflowStatusId;
    this.chaseNote = options.chaseNote;
    this.addressId = options.addressId;
  }
}
