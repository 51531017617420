import { NgZone } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { DocumentPage } from "../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../platform/modules/retrieval/retreival-document-review/document-pages.models";
import { DocumentListItem } from "../shared/document/chase-document-list/document-list-item.model";
import { DocumentThumbnailType } from "../shared/document/document-page-viewer/document-thumbnail-type.enum";
import { DocumentThumbnail } from "../shared/document/document-page-viewer/document-thumbnail.model";
import { SourceDocumentType } from "../shared/document/document-page-viewer/source-document-type.enum";
import { ArrayHelper } from "./contracts/array-helper";
import { NumberHelper } from "./contracts/number-helper";
import { StringHelper } from "./contracts/string-helper";

export class LoaderHelper {

  protected cdnBucketFolders: any[] = [];
  protected currentPages: DocumentPage[] = [];
  protected currentPagesWithCoversheet: DocumentPage[] = [];
  protected scrollIndexChange$: Subject<number> = new Subject();
  protected currentSpineColorIndex: number;
  protected readonly startRange = 10;
  protected readonly endRange = 10;
  protected readonly lowerLoadRange = 12;
  protected readonly upperLoadRange = 10;
  private readonly defaultSpineColor = "#f5f5f5";
  private readonly defaultBackgroundColor = "#f5f5f5";

  constructor(
    protected readonly zone: NgZone) { }

  protected loadThumbnailsPages<T extends { [key: string]: any }>(
    service: T,
    method: keyof T,
    pages: number[],
    cleanDocumentId: number,
    cdnBucketFolders: any[],
    isBucketFolderChecked: boolean,
    thumbnailFilterValue: number,
    mainFn: (thumbnailPages) => void, finalFn?: () => void): void {
    this.load(service, method, pages, cleanDocumentId, cdnBucketFolders, isBucketFolderChecked, thumbnailFilterValue).pipe(
      finalize(() => finalFn ? finalFn() : undefined)
    ).subscribe(thumbnailPages => {
      this.getCdnImages(thumbnailPages);
      let start = thumbnailPages.pages[0].pageNumber - 1;
      const elements = [...thumbnailPages.pages];
      elements.forEach(element => {
        this.currentPages.splice(start, 1, element);
        start++;
      });
      mainFn(thumbnailPages);
    });
  }

  protected loadInitialDocumentPages<T extends { [key: string]: any }>(
    service: T,
    method: keyof T,
    pages: number[],
    cleanDocumentId: number,
    cdnBucketFolders: any[],
    sourceDocumentTypeId: number,
    sessionOptimizeImageSaved: boolean,
    pageTypeId: number, mainFn: (documentPages) => void, finalFn?: () => void, errorFn?: () => void): void {
    this.loadPdfPages(service, method, pages, cleanDocumentId, cdnBucketFolders, sourceDocumentTypeId, sessionOptimizeImageSaved, pageTypeId).pipe(
      finalize(() => finalFn ? finalFn() : undefined)
    ).subscribe(pdfPages => {
      this.getCdnImages(pdfPages);
      pdfPages.pages.forEach(page => this.currentPages[page.pageNumber - 1] = page);
      mainFn(pdfPages);
    },          errorFn);
  }

  protected loadSubsequentDocumentPages<T extends { [key: string]: any }>(
    service: T,
    method: keyof T,
    pages: number[],
    cleanDocumentId: number,
    cdnBucketFolders: any[],
    sourceDocumentTypeId: number,
    sessionOptimizeImageSaved: boolean,
    pageTypeId: number,
    isBucketFolderChecked: boolean,
    mainFn: (documentPages) => void,
    finalFn?: () => void,
    errorFn?: () => void,
    includeImageString = false): void {
    this.loadSubsequentPdfPages(service, method, pages, cleanDocumentId, cdnBucketFolders, sourceDocumentTypeId, sessionOptimizeImageSaved, pageTypeId, isBucketFolderChecked, includeImageString).pipe(
      finalize(() => finalFn ? finalFn() : undefined)
    ).subscribe(pdfPages => {
      this.getCdnImages(pdfPages);
      pdfPages.pages.forEach(page => this.currentPages[page.pageNumber - 1] = page);
      mainFn(pdfPages);
    },          errorFn);
  }

  private loadPdfPages(
    service,
    method,
    pages: number[],
    cleanDocumentId: number,
    cdnBucketFolders: any[],
    sourceDocumentTypeId: number,
    sessionOptimizeImageSaved: boolean,
    pageTypeId: number): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];

    return service[method](
      cleanDocumentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      sessionOptimizeImageSaved,
      pageTypeId,
      null,
      false,
      cdnBucketFolders);
  }

  private loadSubsequentPdfPages(
    service,
    method,
    pages: number[],
    cleanDocumentId: number,
    cdnBucketFolders: any[],
    sourceDocumentTypeId: number,
    sessionOptimizeImageSaved: boolean,
    pageTypeId: number,
    isBucketFolderChecked: boolean,
    includeImageString = false): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];

    return service[method](
      cleanDocumentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      sessionOptimizeImageSaved,
      pageTypeId,
      includeImageString,
      isBucketFolderChecked,
      null,
      true,
      false,
      cdnBucketFolders);
  }

  private load(service, method, pages: number[], cleanDocumentId: number, cdnBucketFolders: any[], isBucketFolderChecked: boolean, thumbnailFilterValue: number): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    const documentPageIds = null;
    const isRisk20Project = null;
    const groupName = null;
    const selectedDiagnosisType = null;
    const isNoEvidenceFilterSelected = false;
    const selectedTabText = null;
    const isSplitScreenMainTab = false;
    const diagnosisFilterSelected = false;
    const encounterStartDate = null;
    const encounterEndDate = null;
    const isProjectEnabledForRiskNlp = false;
    const filterOnlyByDocumentPageIds = false;
    return service[method](
      cleanDocumentId,
      SourceDocumentType.DocumentThumbnail,
      begPage,
      endPage,
      thumbnailFilterValue, null, isBucketFolderChecked,
      documentPageIds, isRisk20Project, groupName,
      selectedDiagnosisType, isNoEvidenceFilterSelected, selectedTabText, isSplitScreenMainTab, diagnosisFilterSelected,
      encounterStartDate, encounterEndDate, filterOnlyByDocumentPageIds, isProjectEnabledForRiskNlp, this.cdnBucketFolders
    );
  }

  protected getCdnImages(documentPages: DocumentPages): void {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  protected isInRange(pageNumber: number, lowerRange: number, upperRange: number, array: DocumentPage[], prop: string): boolean {
    const lowerSlice = array.slice(pageNumber - lowerRange, pageNumber);
    const upperSlice = array.slice(pageNumber, pageNumber + upperRange);
    const section = [...lowerSlice, ...upperSlice];
    const hasElement = section.every(el => el && StringHelper.isAvailable(el[prop]));
    return !hasElement;
  }

  protected getPagesToLoad(pageNumber: number, lowerRange: number, upperRange: number, array: DocumentPage[], prop: string): number[] {
    const pages = [];
    Array.from(array).forEach((page, index) => {
      if (NumberHelper.isBetween(index, (pageNumber - lowerRange), (pageNumber + upperRange)) && page && !StringHelper.isAvailable(page[prop])) {
        pages.push(index + 1);
      }
    });
    return pages;
  }

  protected updatePageStyle(statePages: DocumentPage[]): DocumentPage[] {
    const styleMap = new Map<number, { spineColor: string; backgroundColor: string }>();
    this.currentSpineColorIndex = 0;
    const updatedPages = [...statePages];
    const documentQueueIds = Array.from(new Set([...statePages.map(pages => pages.documentQueueId)]));
    documentQueueIds.forEach(docId => {
      let spineColor = this.defaultSpineColor;
      let backgroundColor = this.defaultBackgroundColor;
      const documentThumbnail = new DocumentThumbnail();
      if (NumberHelper.isAvailable(docId)) {
        const colorIndex = this.currentSpineColorIndex % documentThumbnail.thumbnailColors.length;
        spineColor = documentThumbnail.thumbnailColors[colorIndex].spine;
        backgroundColor = documentThumbnail.thumbnailColors[colorIndex].background;
        this.currentSpineColorIndex++;
      }
      styleMap.set(docId ? docId : 0, { spineColor, backgroundColor });
    });
    updatedPages.forEach(page => {
      const docId = page.documentQueueId || 0;
      const style = styleMap.get(docId);
      if (page?.documentThumbnail) {
        page.documentThumbnail.thumbnailSpineColor = style.spineColor;
        page.documentThumbnail.thumbnailBackgroundColor = style.backgroundColor;
        page.documentThumbnail.thumbnailType = DocumentThumbnailType.THUMBNAIL_IMAGE;
      }
    });
    return updatedPages;
  }

  protected updateThumbnailStyle(statePages: DocumentPage[], documentList: DocumentListItem[]): DocumentPage[] {
    const pagesWithCoversheet = Array.from(statePages);
    const addPage = new DocumentPage({
      documentQueueId: null,
      documentThumbnail: new DocumentThumbnail({
        thumbnailType: DocumentThumbnailType.ADD_PAGE,
      }),
    });
    const requestPage = new DocumentPage({
      documentQueueId: null,
      documentThumbnail: new DocumentThumbnail({
        thumbnailType: DocumentThumbnailType.REQUEST_PAGE,
      }),
    });
    const groupPages = ArrayHelper.groupBy(statePages, "documentQueueId");
    let count = - 1;
    for (const [key, value] of groupPages) {
      const hasDocumentQeueId = NumberHelper.isAvailable(key);
      if (hasDocumentQeueId) {
        const element = value[0];
        const documentListItem = hasDocumentQeueId ? this.getDocumentProperties(key, documentList) : undefined;
        const coversheet = new DocumentPage({
          documentQueueId: key,
          documentThumbnail: new DocumentThumbnail({
            thumbnailSpineColor: element.documentThumbnail.thumbnailSpineColor,
            thumbnailBackgroundColor: element.documentThumbnail.thumbnailBackgroundColor,
            thumbnailType: DocumentThumbnailType.COVERSHEET,
          }),
          documentListItem: new DocumentListItem({ ...documentListItem }),
        });
        const start = element.pageNumber + count;
        const deleteCount = 0;
        pagesWithCoversheet.splice(start, deleteCount, coversheet);
        count++;
      }
    }
    pagesWithCoversheet.unshift(addPage);
    pagesWithCoversheet.unshift(requestPage);
    return pagesWithCoversheet;
  }

  private getDocumentProperties(id: number, documentListItems: DocumentListItem[]): DocumentListItem {
    return documentListItems?.find(item => item.documentQueueId === id);
  }
}
