import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";


@Component({
  selector: "app-pend-list",
  templateUrl: "./pend-list.component.html",
  styleUrls: ["./pend-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendListComponent implements  OnInit {
  gridRefresh = new EventEmitter<null>();
  gridConfigurationModel = new GridConfiguration();
  searchRequest: GridRequest;
  serverGridSelection: any;
  iconName = "chevron-down";
  isChangeOwnerView = false;
  pendGrid: ServerGridComponent;
  chasePendIds: number[] = [];
  isAdvanceFilter = false;
  pendType: string;
  filterColumnValue: string;
  filterColumn: string;
  readonly filterDropdownModel: any[] = [];
  readonly filterTextBoxModel: any[] = [];
  readonly chaseIdModel: any[] = [];
  readonly addressIdModel: any[] = [];
  statisticsFilter = "Site";
  selectedStat: string;
  isChangeOwnerDisabled = true;
  pendId: number;

  retrievalFilterInput: Textbox;
  getProject: any[];
  getPendCode: any[];
  form: FormGroup;
  readonly Projects: Textbox;

  checkBoxSet: CheckboxGroup;
  PendCode: CheckboxGroup;
  Owner: Radiobutton;
  AID: Textbox;
  ChaseId: Textbox;
  filters: any[];
  pendStatisticIds: string;

  isClientRole = false;
  isStatusChangeView = false;
  @ViewChild(ServerGridComponent, { static: true }) private serverGridComponent: ServerGridComponent;

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseId");
  }
  get addressIdInput(): Textbox {
    return this.getInput("MasterDocumentSourceId");
  }
  get ownerInput(): Radiobutton {
    return this.getInput("Owner");
  }
  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }
  get pendCodesInput(): CheckboxGroup {
    return this.getInput("PendCodes");
  }
  set pendCodesInput(value: CheckboxGroup) {
    this.setInput("PendCodes", value);
  }

  constructor(
    private createPendService: CreatePendService,
    private readonly router: Router,
    private readonly formService: FormService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private authService: AuthService,
    private messagingService: MessagingService,
    private retrievalService: RetrievalPageService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly automapper: AutomapperService
  ) {
  }

  ngOnInit() {
    this.isClientRole = this.authService.user.directoryRoleIds.indexOf(31) > -1 ? true : false;
    this.pendType = this.route.snapshot.paramMap.get("pendType");
    this.filterColumnValue = this.route.snapshot.paramMap.get("filterColumnValue");
    this.filterColumn = this.route.snapshot.paramMap.get("filterColumn");
    this.getProjects();
    this.getPendCodeSet();
    this.createGrid();
    this.loadGridDataSource("Site");
  }

  private createGrid(): void {
    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "chasePendId", header: "Pend ID", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "pendTypeShortDescription", header: "Pend Description", title: "pendTypeDescription" }),
      new GridColumnDefinition({ field: "owner", header: "Owner" }),
      new GridColumnDefinition({ field: "assignedToUser", header: "Assigned To" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "lastUpdatedDate", header: "Last Updated", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
    ];
    this.gridConfigurationModel.pageSize = 25;
    this.gridConfigurationModel.stateName = "pendListAll";
    this.gridConfigurationModel.bulkActions = [
      new BulkAction({ name: "CHANGE OWNER & ASSIGN", action: () => this.changeOwnerAssignDialog() }),
    ];

    if (this.isClientRole) {
      this.gridConfigurationModel.bulkActions.push(new BulkAction({ name: "REQUEST TO CLOSE", action: () => this.requestStatusChangeDialog() }));

    } else {
      this.gridConfigurationModel.bulkActions.push(new BulkAction({ name: "CHANGE STATUS", action: () => this.updateStatusDialog() }));
    }
    this.gridConfigurationModel.selectionMode = "multiple";
    this.gridConfigurationModel.showActionColumn = true;
    this.gridConfigurationModel.filterGridFn = this.onApplyFilter;
    this.filterForm();
    this.getProjects();
    this.getPendCodeSet();
  }

  toggleAdvanceFilter() {
    this.isAdvanceFilter = !this.isAdvanceFilter;
  }

  getStatisticsFilter(filterName: string): void {
    this.statisticsFilter = filterName === "Site" ? null : filterName;
    this.loadGridDataSource(this.statisticsFilter);
    this.selectedStat = filterName;
  }

  loadGridDataSource(statisticsFilter: string) {
    this.pendStatisticIds = window.sessionStorage.getItem("PendStatisticIds");
    window.sessionStorage.removeItem("PendStatisticIds");
    this.searchRequest = new GridRequest({
      url: `${this.baseApiUrl}reporting/pend/list`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "PendStatisticsIds",
          value: this.pendStatisticIds,
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "searchFilterOption",
          value: "1",
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "IsClinical",
          value: "",
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ChaseId",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MasterDocumentSourceId",
          name: "AID",
        }),
        new GridFilter({
          input: new Radiobutton({
            options: [
              new SelectableInput({ text: "Organization", value: "Organization" }),
              new SelectableInput({ text: "Client", value: "Client" }),
            ],
          }),
          key: "Owner",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Projects",
        }),
        new GridFilter({
          input: new CheckboxGroup({showTooltip: true}),
          key: "PendCodes",
        }),
        new GridFilter({
          input: new Textbox(),
          key: this.filterColumn,
          value: this.filterColumnValue,
          show: false,
        }),
      ],
    });

    const pendFilter = window.sessionStorage.getItem("pendFilter");
    if (pendFilter != null) {
      const sessionFilter = JSON.parse(pendFilter);
      this.searchRequest.filters.push(new GridFilter({
        input: new Textbox(),
        key: sessionFilter[0].key,
        value: sessionFilter[0].value,
        show: false,
      }));
      window.sessionStorage.removeItem("pendFilter");
    }
  }

  onApplyFilter = () => {
    const Id = this.form.get("ChaseId").value;
    const AID = this.form.get("AID").value;
    const Projects = this.form.get("Projects").value;
    const pendCodes = this.form.get("pendCodes").value;
    const Owner = this.form.get("Owner").value;
    this.filters = [];
    if (StringHelper.isAvailable(Id)) {
      this.filters.push(new GridFilter({ key: "ChaseId", value: Id }));
    }

    if (StringHelper.isAvailable(AID)) {
      this.filters.push(new GridFilter({ key: "MasterDocumentSourceId", value: AID }));
    }


    if (StringHelper.isAvailable(Owner)) {
      this.filters.push(new GridFilter({ key: "Owner", value: Owner }));
    }

    let ProjectsName = "";
    let ProjectsId = "";
    if (Projects !== null && Projects.length !== 0) {
      for (const i of Projects) {
        ProjectsId += `${i.value},`;
        ProjectsName += `${i.text},`;
      }
      this.filters.push(new GridFilter({ key: "Projects", value: ProjectsId.slice(0, -1), text: ProjectsName }));
    }
    let PendCodeName = "";
    let PendCodeValue = "";
    if (pendCodes !== null && pendCodes.length !== 0) {
      for (const i of pendCodes) {
        PendCodeValue += `${i.value},`;
        PendCodeName += `${i.text},`;
      }
      this.filters.push(new GridFilter({ key: "PendCodes", value: PendCodeValue.slice(0, -1), text: PendCodeName }));
    }
    this.filters.push(new GridFilter({ key: "IsClinical", value: this.pendType === "retrieval" ? "false" : "true", show: false }));
    this.search(this.filters);
    this.changeDetector.markForCheck();
  }

  private getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectsInput = { ...this.projectsInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getPendCodeSet(): void {
    this.createPendService
      .getPendDropdown(false, 1)
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
      }))
      .subscribe(result => {
        this.pendCodesInput = { ...this.pendCodesInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  private refreshGrid() {
    this.gridRefresh.emit();
  }

  pendSplit(code): void {
  }

  filterForm() {
    this.AID = new Textbox({
      key: "AID",
      label: "SELECT AID",
      placeholder: "AID",
    });
    this.ChaseId = new Textbox({
      key: "ChaseId",
      label: "SELECT CHASE ID",
      placeholder: "CHASE ID",
    });
    this.checkBoxSet = new CheckboxGroup({
      key: "Projects",
      label: "SELECT PROJECT",
      options: this.getProject,
    });

    this.PendCode = new CheckboxGroup({
      key: "pendCodes",
      label: "SELECT PROJECT",
      options: this.getPendCode,
    });

    this.Owner = new Radiobutton({
      key: "Owner",
      label: "Select OWNER",
      name: "Owner",
      options: [ // options will be used to create the radio buttons. In this example, there will be four radio buttons created.
        { text: "Organization", value: "Organization" },
        { text: "Client", value: "Client" },
      ],
    });

    this.form = this.formService.createFormGroup([this.checkBoxSet, this.PendCode, this.ChaseId, this.AID, this.Owner]);
  }


  search(filters: GridFilter[]) {
    this.searchRequest = new GridRequest({
      url: `${this.baseApiUrl}pends`,
      filters,
    });

    this.refreshGrid();
  }

  changeOwnerAssignDialog(rowData?: any): void {
    if (rowData !== undefined) {
      this.serverGridSelection = [];
      this.serverGridSelection.push(rowData);
    }

    const selectedRows = this.serverGridSelection;
    this.chasePendIds = [];

    if (selectedRows && selectedRows.length > 0) {
      const projectIds = selectedRows.map(item => item.projectId)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (projectIds.length === 1) {
        selectedRows.forEach(item => {
          this.chasePendIds.push(item.chasePendId);
        });
      } else {
        this.messagingService.showToast("Selected pends are not from same project.", SeverityType.WARN);
        return;
      }

      let pendstatus = "";
      selectedRows.forEach(item => {
        if (item.pendStatus === "Resolved" || item.pendStatus === "Closed") {
          pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
        }
      });

      if (pendstatus.length > 0) {
        this.messagingService.showToast(`Selected pends having ${pendstatus.replace(/,\s*$/, "")} status cannot be assigned.`, SeverityType.WARN);
        return;
      }

      this.isChangeOwnerView = true;


    } else {
      this.messagingService.showToast("Please select at least one Pend", SeverityType.WARN);
    }
  }

  selectChangeOwner(event) {
    if (event !== null && event !== undefined) {
      let selectedRows = [];
      selectedRows = this.serverGridSelection;
      if (selectedRows && selectedRows.length > 0) {
        const key = "pendStatus";
        const pendstatus = selectedRows[selectedRows.length - 1][key];
        if (pendstatus === "New" || pendstatus === "In Progress") {
          this.isChangeOwnerDisabled = false;
        } else {
          this.messagingService.showToast(`Selected pends having ${pendstatus} status cannot be assigned.`, SeverityType.WARN);
        }
      } else {
        this.isChangeOwnerDisabled = true;
      }
    }
  }

  reassignUser($event) {
    this.isChangeOwnerView = $event;
    this.refreshGrid();
  }

  trackByIndex(index, item) {
    return index;
  }

  private getInput<T extends DynamicInput>(key: string): T {
    if (this.searchRequest == null) {
      return null;
    }

    return this.searchRequest.getInput<T>(key);
  }

  private setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.searchRequest == null) {
      return null;
    }

    this.searchRequest.setInput<T>(key, value);
  }

  viewDetails(rowData: any) {
    const selectedRows = rowData;
    this.pendId = selectedRows.chasePendId;
    this.router.navigate(["pend", "detail", this.pendId]);
  }

  requestStatusChangeDialog(rowData?: any) {
    if (rowData !== undefined) {
      this.serverGridSelection = [];
      this.serverGridSelection.push(rowData);
    }

    let pendstatus = "";
    this.serverGridSelection.forEach(item => {
      if (item.pendStatus === "Closed") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot be assigned.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      this.serverGridSelection.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
      });

      this.isStatusChangeView = true;
    }
  }

  updateStatusDialog(rowData?: any) {
    if (rowData !== undefined) {
      this.serverGridSelection = [];
      this.serverGridSelection.push(rowData);
    }

    let pendstatus = "";
    this.serverGridSelection.forEach(item => {
      if (item.pendStatus === "Closed") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot be assigned.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      this.serverGridSelection.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
      });

      this.isStatusChangeView = true;
      this.isClientRole = false;
    }
  }

  updateStatus($event) {
    this.isStatusChangeView = $event;
    this.refreshGrid();
  }

}
