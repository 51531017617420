import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

export class RiskError {
  entityId: number;
  parentEntityId: number | null;
  message: string;

  constructor(options: {
    entityId?: number;
    parentEntityId?: number | null;
    message?: string;
  } = {}) {
    this.entityId = NumberHelper.isGreaterThan(options.entityId, 0) ? options.entityId : null;
    this.parentEntityId = NumberHelper.isGreaterThan(options.parentEntityId, 0) ? options.parentEntityId : null;
    this.message = StringHelper.clean(options.message);
  }


  get hasParent(): boolean {
    return NumberHelper.isGreaterThan(this.parentEntityId, 0);
  }
}
