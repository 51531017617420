import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { TimelineItem } from "../../../../shared/timeline/timeline-item.model";
import { ContactRecord } from "../psr/address-detail/address-detail-contact-record";
import { AddressEditModel } from "../psr/address-detail/address-detail-edit/address-edit-model";
import { CallNotesModel } from "./callNotes-model";

@Injectable({
  providedIn: "root",
})
export class RetrievalCallFlowService {
  reset = new Subject<any>();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  resetFunction(value: string) {

    this.reset.next(value);
  }

  saveCallResult(contactRecord: ContactRecord, actionSource: string) {

    const url = `${this.baseApiUrl}retrieval/saveContactRecord?actionSource=${actionSource}`;

    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, contactRecord, { headers });

  }

  getAddressDetailsForEdit(retrievalId: number): Observable<AddressEditModel> {
    const url = `${this.baseApiUrl}Address/AddressDetailsForEdit?addressId=${retrievalId}`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curry("default", "AddressEditModel"))
      );
  }

  updateAddressDetails(addressEditModel: AddressEditModel): Observable<number> {
    const url = `${this.baseApiUrl}Address/UpdateAddressDetails`;

    return this.http
      .post(url, addressEditModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? 1 : 0)
      );
  }

  getCallTypesDropdown(isCallSuccess: number): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}retrieval/callTypesList?isCallSuccessful=${isCallSuccess}`;

    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  saveCallNotes(callNotesSaveModel: CallNotesModel): Observable<number> {
    const url = `${this.baseApiUrl}retrieval/saveCallNotes`;

    return this.http
      .post(url, callNotesSaveModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? 1 : 0)
      );
  }

  isFollowUpCall(retrievalId: number): Observable<number> {
    const url = `${this.baseApiUrl}retrieval/checkForFollowUpCall?retrievalId=${retrievalId}`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.body === true ? 1 : 0)
      );
  }

  getCallNotesTimelineItems(retrievalId: number): Observable<TimelineItem[]> {
    // TODO: Add address timeline endpoint
    const url = `${this.baseApiUrl}timeline/retrievalCallNotesTimeline?documentSourceId=${retrievalId}&maxRecords=0`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }
}
