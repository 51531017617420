<app-modal
  header="Create Pend"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
  (onShow)="open()"
  (onHide)="close()"
  (onCancel)="close()">

  <div>Chase Ids: {{ chaseIdsText }}</div>

  <br />

  <div class="input-container">
    <div [hidden]="pendCodesInput.hidden" class="input">
      <form-factory
        [formGroup]="form"
        [model]="pendCodesInput"
        (onChange)="checkIfInvoicePendCode()">
      </form-factory>
    </div>

    <div [hidden]="input.hidden" class="input" *ngFor="let input of inputs; trackBy: trackByIndex">
      <form-factory [formGroup]="form" [model]="input"></form-factory>
    </div>
  </div>

  <form-factory [formGroup]="form" [model]="noteInput"></form-factory>

  <footer>
    <app-button class="header-button" text="Save" (onClick)="save()"></app-button>
  </footer>
</app-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isConfirmationModalVisible"
  header="Close the Chase?"
  (onYes)="savePend()"
  (onCancel)="cancelConfirmation($event)">
  Selecting this type of Pend will automatically close the Chase. Do you want to proceed?
</app-confirmation-modal>