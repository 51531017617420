<app-modal class="outreach"
           header="OUTREACH"
           [(visible)]="visible"
           (onCancel)="close()"
           (visibleChange)="onVisibleChange($event)">
  <h4 class="text-center outreach__sub-header">{{ subHeader }}</h4>

  <div *ngIf="isOutReachTypeSelected && !isOutreachTypeExternal" class="cover-letter-template__body">
    <div class="cover-letter-template__body--details">
      <div class="cover-letter-template__detail--inline">
        <div class="cover-letter-template__detail--label bold">{{ toOutreachLabel | uppercase }} :</div>
        <div class="cover-letter-template__detail--value">{{ toName }}</div>
      </div>
      <div *ngIf="isOutreachTypeFax" class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">{{ toLabel | uppercase }} :</div>
        <div class="cover-letter-template__detail--value">{{ to }}</div>
      </div>
    </div>
    <div *ngIf="!isOutreachTypeMail" class="cover-letter-template__body--details">
      <div class="cover-letter-template__detail--inline">
        <div class="cover-letter-template__detail--label bold">FROM :</div>
        <div class="cover-letter-template__detail--value">{{ fromName }}</div>
      </div>
      <div *ngIf="isOutreachTypeFax" class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">TOTAL PACKAGES : </div>
        <div class="cover-letter-template__detail--value">{{ batchedFaxCount }}</div>
      </div>
    </div>
    <br />
  </div>

  <form-factory class="outreach__form"
                [formGroup]="formGroup"
                [model]="outreachTypeInput">
  </form-factory>
  <div class="cover-letter-template__body--templateDropDown">
    <form-factory [formGroup]="formGroup"
                  [model]="coverLetterTemplate">
    </form-factory>
  </div>
  <footer class="text-center">
    <app-button text="CONFIRM"
                (onClick)="intiateOutreach()"
                [disabled]="!isValid">
    </app-button>
  </footer>
</app-modal>

<app-confirmation-modal [(visible)]="isFaxChasePromptVisible"
                        header="WARNING"
                        (onYes)="openFaxModal()"
                        (onCancel)="onCancelFax()"
                        class="text-center">
  You are about to send a large fax of {{ totalChases }} chases. For improved delivery performance, this delivery will be broken up into multiple faxes. Would you like to proceed?
</app-confirmation-modal>

