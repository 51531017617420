import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { DateFormats } from "../../../../../utilities/contracts/helper-types";
import { Appointment } from "../appointment.model";

@Component({
  selector: "retrieval-delete-appointment-modal",
  templateUrl: "./delete-appointment-modal.component.html",
  styleUrls: ["./delete-appointment-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAppointmentModalComponent {
  @Input() appointment: Appointment;
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onYes: EventEmitter<null> = new EventEmitter();
  @Output() onCancel: EventEmitter<null> = new EventEmitter(true);


  constructor(
    private cd: ChangeDetectorRef
  ) { }


  get hasAppointment(): boolean {
    return this.appointment != null;
  }

  get address(): string {
    return this.hasAppointment ? this.appointment.masterDocumentSourceName : "";
  }

  get appointmentStartDateTime(): string {
    return this.hasAppointment ? DateHelper.format(this.appointment.start, `${DateFormats.DEFAULT} hh:mm A`) : "";
  }

  clickedOnYes(): void {
    this.onYes.emit();
    this.setVisible(false);
  }

  clickedOnNo(): void {
    this.onCancel.emit();
    this.setVisible(false);
  }

  setVisible(value: boolean): void {
    if (this.visible !== value) {
      this.visible = value;
      this.visibleChange.emit(value);
      this.cd.markForCheck();
    }
  }
}
