import { IAutoMapper } from "../interfaces";

export const mapListItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ListItem")
    .forMember("key", o => o.key)
    .forMember("value", o => o.value)
    .forMember("url", o => o.url);

  automapper
    .createMap("DataLoadSummaryReport", "ListItem")
    .forMember("key", o => o.summaryType)
    .forMember("value", o => o.summaryValue)
    .forMember("url", o => o.url);
};
