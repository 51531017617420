<div class="attempt-history-container">
  <h3 class="bold">ATTEMPT HISTORY</h3>


  <app-basic-grid [configuration]="gridConfigurationModel"
                  [data]="rowDataModel"
                  [(selection)]="contactHistoryGridSelection">

    <ng-template #noteColumn let-rowData>
      <app-contact-history-note [note]="rowData.callNote"></app-contact-history-note>
    </ng-template>
  </app-basic-grid>
</div>
