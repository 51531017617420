export class RiskDxDeleteDiagnosis {
    chaseId: number;
    entityId: number;

    constructor(options: RiskDxDeleteDiagnosis) {
      this.chaseId = options.chaseId;
      this.entityId = options.entityId;
    }
  }

