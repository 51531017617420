import { DynamicGroup } from "../../form-groups/dynamic-group.model";
import { DynamicArray, IDynamicArrayOptions } from "../dynamic-array.model";
import { FixedArrayComponent } from "./fixed-array.component";

export interface IFixedArrayOptions extends IDynamicArrayOptions {
  models?: DynamicGroup[];
}

export class FixedArray extends DynamicArray implements IFixedArrayOptions {
  readonly controlType = "fixed-array";
  readonly componentType = FixedArrayComponent;


  constructor(options: IFixedArrayOptions = {}) {
    super(options);
  }
}
