import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { ConditionalGroupComponent } from "./conditional-group.component";

export interface IConditionalGroupOptions extends IDynamicGroupOptions { }

export class ConditionalGroup extends DynamicGroup implements IConditionalGroupOptions {
  readonly controlType = "conditional-group";
  readonly componentType = ConditionalGroupComponent;


  constructor(options: IConditionalGroupOptions = {}) {
    super(options);
  }
}
