<div [formGroup]="formGroup" class="control control--group control--array" [hidden]="hidden">
  <div [formArrayName]="model.key">
    <div *ngIf="model.hasHeader" class="control__header">
      <div class="control__header__label">
        {{ model.header }}
      </div>
    </div>
    <div
      *ngFor="let group of model.models; index as i; trackBy: trackByIndex"
      [formGroupName]="i"
      class="control control--group"
      [ngClass]="classes(i)">

      <div *ngIf="group.hasHeader" class="control__header">
        <div class="control__header__label">{{ group.header }}</div>
        <div
          *ngIf="hasGroupError(i)"
          class="control__header__error"
          [title]="groupError(i)">
          {{ groupError(i) }}
        </div>
      </div>
      <form-factory
        *ngFor="let _model of group.controls; trackBy: trackByIndex"
        [formGroup]="control.at(i)"
        [model]="_model"
        (onFocus)="highlighter.onFocus(i)"
        (onBlur)="highlighter.onBlur(i)">
      </form-factory>
      <button
        class="control__delete"
        title="Delete"
        *ngIf="!group.isAdmin"
        [disabled]="model.disabled"
        tabindex="-1"
        (click)="remove(i)">
        ×
      </button>

    </div>
  </div>
</div>