import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { BulkAction } from "../../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { ChaseSearchRequest } from "../../../../../../api/chase-search/chase-search-request-model";
import { ChaseItem } from "../../../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { ProviderSearchRequest } from "../../../../../../api/provider/provider-search-request-model";
import { ProviderItem } from "../../../../../../api/provider/provider-search-result-item";
import { ProviderService } from "../../../../../../api/provider/provider.service";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { ChaseMoveModel } from "../../../../../project/chase-query/chase-move.model";
import { ChaseQueryService } from "../../../../../project/chase-query/chase-query.service";
import { AddressSearchResult } from "../../../../address-search/address-search-result.model";
import { CreateAddress } from "../../../../address-search/create-address.model";
import { CommitmentDateService } from "../../../../commitment-date/commitment-date.service";
import { CommitmentDateUpdateModel } from "../../../../commitment-date/commitmentDateUpdate.model";
import { DocumentSourceType } from "../../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../../address-detail-state.model";
import { AddressDetailStateService } from "../../../address-detail-state.service";
import { AddressDetailService } from "../../../address-detail.service";

@Component({
  selector: "retrieval-address-detail-info-grids-providers",
  templateUrl: "./address-detail-info-grids-providers.component.html",
  styleUrls: ["./address-detail-info-grids-providers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsProvidersComponent implements OnInit, OnDestroy {
  startRecord: number;
  endRecord: number;
  providerGridConfiguration = new GridConfiguration();
  providerGridData: any[] = [];
  providerGridSelection: any[];
  private sink = new SubSink();
  private pageSize = 25;
  private AllRecords = 2147483647;
  private gridSearchRequest: any;
  providerGridDataRequestInitiated = false;
  actions: BulkAction[];
  isAddressModalVisible = false;
  selectedAddress: AddressSearchResult;
  isMoveVisible = false;
  addressSearchCallingSource = "Move Provider Chases";
  isContactInfoModalVisible = false;
  confirmStatusMessage: any;
  chaseIdList: number[] = [];
  isConfirmModalVisible = false;
  newAddressModel: CreateAddress;
  assignedUser: string;
  chaseMoveModel: any;
  addressId: number;
  addressDetailState: AddressDetailState;
  isThirdParty = false;
  commitmentDate: Date;
  selectedProviders: ProviderItem[] = [];
  allChases: ChaseItem[] = [];
  isNewAddress: boolean;
  commitDateUpdateModel: CommitmentDateUpdateModel;
  providerSearchToolVisible = false;
  @ViewChild("isValidCheckbox", { static: true }) isValidCheckbox: TemplateRef<any>;
  @ViewChild("AddressesColumn", { static: true }) AddressesColumn: TemplateRef<any>;

  get moveHeaderMessage(): string {
    return this.selectedProviders.length === 1 ?
      "Are you sure you want to move this provider" : "Are you sure you want to move these providers";
  }

  constructor(
    private providerService: ProviderService,
    private chaseQryService: ChaseQueryService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private chaseService: ChaseSearchService,
    private readonly addressDetailService: AddressDetailService,
    private readonly commitmentDateService: CommitmentDateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.startRecord = 1;
    this.endRecord = this.AllRecords;

    this.createGrids();

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        if (state.hasMasterDocumentSourceId && !this.providerGridDataRequestInitiated) {
          this.providerGridDataRequestInitiated = true;
          this.addressId = state.masterDocumentSourceId;
          this.addressDetailState = state;
          this.isThirdParty = state.documentSourceTypeId === DocumentSourceType.THIRDPARTY;

          this.gridSearchRequest = new ProviderSearchRequest(
            null, null, state.masterDocumentSourceId,
            null, null, null, null, null, this.startRecord, this.endRecord);

          this.updateGridData();
        }
      })
    );

    this.actions = this.getActions();
  }

  updateGridData($event: boolean = true): void {
    this.providerService
    .providerSearch(this.gridSearchRequest)
    .subscribe(items => {
      this.providerGridData = items as any;
      this.changeDetector.markForCheck();
    });
  }

  private getActions(): BulkAction[] {
    return [
      new BulkAction({
        name: "Move Provider",
        action: this.moveProvider.bind(this),
      }),
    ];
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  createGrids(): void {
    this.providerGridConfiguration.columns = [
      new GridColumnDefinition({ field: "serviceProviderName", header: "Provider Name" }),
      new GridColumnDefinition({ field: "nationalProviderId", header: "NPI" }),
      new GridColumnDefinition({ field: "providerGroup", header: "Group" }),
      new GridColumnDefinition({ field: "speciality", header: "Speciality" }),
      new GridColumnDefinition({ field: "taxpayerId", header: "Tax ID" }),
      new GridColumnDefinition({ field: "totalAddresses", template: this.AddressesColumn, header: "Addresses", isSortableColumn: false }),
      new GridColumnDefinition({ header: "Validation", template: this.isValidCheckbox, isSortableColumn: false }),
    ];

    this.providerGridConfiguration.pageSize = this.pageSize;
    this.providerGridConfiguration.selectionMode = "multiple";
    this.providerGridConfiguration.showActionColumn = false;
  }

  chaseMoved(): void {
    this.assignedUser = "";
    this.chaseMoveModel = new ChaseMoveModel({
      toAddressId: this.selectedAddress.masterDocumentSourceId.toString(),
      fromAddressId: this.addressId.toString(),
      chaseIds: this.chaseIdList.join(),
      pageType: this.addressDetailState.documentSourceTypeName,
      assignedUser: this.assignedUser,
      status: null ,
      notes: this.selectedAddress.notes,
      isChaseAssign: this.selectedAddress.isChaseAssign,
    });

    this.chaseQryService.chaseMove(this.chaseMoveModel).subscribe(
      () => {
        this.confirmStatusMessage = `${this.chaseIdList.length} chase(s) associated with selected provider(s) moved to AID ${this.selectedAddress.masterDocumentSourceId}`;

        this.updateGridData();
        this.isMoveVisible = false;
        this.isAddressModalVisible = false;
        this.changeDetector.markForCheck();
        this.isContactInfoModalVisible = false;
        this.confirmMessage();
        if (this.isNewAddress) {
          this.updateSetCommitmentDate();
        }
      },
      err => {
          this.messagingService.showToast(`Error while moving chase to Address ${this.selectedAddress.masterDocumentSourceId}, please try again.`, SeverityType.ERROR);
      }
    );

  }

  confirmMessage(): void {
    this.isConfirmModalVisible = !this.isConfirmModalVisible;
    this.changeDetector.markForCheck();
  }

  moveProvider(rowData?: any): void {
    if (ArrayHelper.isAvailable(this.providerGridData) && ArrayHelper.isAvailable(rowData)) {
      this.isAddressModalVisible = true;
      this.selectRow(rowData);
      this.getChases();
    }
  }

  gotoAddressDetail() {
    this.isConfirmModalVisible = false;
    if (this.selectedAddress) {
      this.router.navigateByUrl(`retrieval/addressdetail/${this.selectedAddress.masterDocumentSourceId}`);
    }
  }

  closeChaseMovePop() {
    this.isMoveVisible = false;
  }

  onAddressSelectionForProviderMove($event: AddressSearchResult): void {
    this.isMoveVisible = !this.isNewAddress;
    this.selectedAddress = $event;
    this.isAddressModalVisible = !this.isNewAddress;
  }

  onContactInfoSaved($event: AddressSearchResult): void {
    this.selectedAddress = $event;
  }

  onIsNewAddress($event: CreateAddress): void {
    this.isContactInfoModalVisible = true;
    this.newAddressModel = $event;
    this.isAddressModalVisible = false;
    this.isNewAddress = true;
  }

  onMoveChasesConfirmation(event: Date): void {
    this.isMoveVisible = true;
    this.commitmentDate = event;
  }

  getChases(): void {
    const chaseSearchRequest = new ChaseSearchRequest(
      null, null, null, null, this.addressId, null, null, null, null, null, null,
      null, null, null);
    this.chaseIdList = [];

    this.chaseService
      .chaseSearch(chaseSearchRequest)
      .subscribe(chaseGridData => {
        this.allChases = chaseGridData;

        this.allChases.forEach(x => {
          const providerSelected = this.selectedProviders.filter(provider => provider.nationalProviderId === x.npi
            && provider.serviceProviderName.toLocaleLowerCase() === x.serviceProviders.toLocaleLowerCase());

          if (ArrayHelper.isAvailable(providerSelected)) {
            this.chaseIdList.push(x.chaseId);
          }
        });
      });
  }

  selectRow(rowData) {
    this.selectedProviders = [];
    const selectedRows = rowData && ArrayHelper.isAvailable(rowData) ? rowData : null;

    if (selectedRows && selectedRows.length > 0) {
      selectedRows.forEach(item => {
        this.selectedProviders.push(item);
      });
    }
  }

  updateSetCommitmentDate(): void {

    if (DateHelper.isAvailable(this.commitmentDate)) {
      return;
    }

    this.commitDateUpdateModel = new CommitmentDateUpdateModel({
      chaseIds: this.chaseIdList,
      functionalRoleId: FunctionalRole.DOCUMENTRETRIEVAL,
      retrievalLocationId: this.addressId,
    });

    const formattedDate = DateHelper.format(this.commitmentDate);

    if (!this.isThirdParty) {
      this.commitDateUpdateModel.commitmentDate = formattedDate === "" ? null : formattedDate;
    } else {
      this.commitDateUpdateModel.requestDate = DateHelper.format(this.commitmentDate);
    }
    if (ArrayHelper.isAvailable(this.commitDateUpdateModel.chaseIds)) {
      this.commitmentDateService.updateCommitmentDate(this.commitDateUpdateModel).subscribe(
        data => {
          this.messagingService.showToast("Commitment date updated successfully.", SeverityType.SUCCESS);
        },
        err => {
          this.messagingService.showToast("Error while updating the commitment date", SeverityType.ERROR);
        }
      );
    } else {
        this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
        return;
    }

    this.changeDetector.markForCheck();
  }

  searchProvider(): void {
    this.addressDetailService.resetProviderCallFlow(true);
    this.providerSearchToolVisible = true;
  }

  onProviderDisabled(): void {
    const hasProviders = ArrayHelper.isAvailable(this.providerGridData);
    const foundInvalidProviders = ArrayHelper.isAvailable(this.providerGridData.filter(provider => !provider.isVerified));
    this.addressDetailStateService.setData({
        masterDocumentSourceVerifiedDate: new Date(),
        allProvidersVerified: !hasProviders || !foundInvalidProviders,
      });

  }
}
