export const MEASURES_FOR_NLP_FIRST_PASS = [
    "A1C9",
    "AWV",
    "BCS-E",
    "BCS",
    "BPD",
    "CBP",
    "CCS",
    "CDC",
    "CIS",
    "COA",
    "COL",
    "EED",
    "FMC",
    "FSA-P",
    "GSD",
    "HBD",
    "IMA",
    "KED",
    "LSC",
    "MR-P",
    "OMW",
    "OMW-P",
    "PA-P",
    "PPC",
    "SPD",
    "TRC",
    "W30",
    "WCC",
    "WCV",
];
