import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { AuthService } from "../../../../auth/auth.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { SignInUserDropdown } from "../../../../dynamic-forms/inputs/signin-user-dropdown/signin-user-dropdown.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { SingleSignInService } from "./single-sign-in.service";

@Component({
  selector: "platform-single-sign-in",
  templateUrl: "./single-sign-in.component.html",
  styleUrls: ["./single-sign-in.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSignInComponent implements OnInit, OnDestroy {
  usersForm: FormGroup;
  usersInput: SignInUserDropdown;
  usersOptions: SelectableInput[];
  hasSingleSO = false;
  selectedUserId: any;
  private sink = new SubSink();

  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private singleSignInService: SingleSignInService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initializeUserForm();
    this.getUsersWithSameEmailAddress();

    this.sink.add(
      this.singleSignInService.isLoginRedirect.subscribe(loginRedirect => {
        if (loginRedirect) {
          const analyticsUrl = "/analytics/";
          if (this.router.routerState.snapshot.url.substring(0, analyticsUrl.length) === analyticsUrl) {
            Object.keys(localStorage).forEach(key => {
              if (key.includes(analyticsUrl)) {
                localStorage.removeItem(key);
              }
            });
          }
        } else {
          this.setSignleSignInUserInput();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  get loggedInUserId(): number {
    return this.authService.userId;
  }

  get loggedInUserEmailAddress(): string {
    return this.authService.userEmailAddress;
  }

  get loggedInUserGroupName(): string {
    if (this.authService.user.isAdminRole) {
      return "Admin";
    } else if (this.authService.user.isReadOnlyRole) {
      return "Read Only";
    } else if (this.authService.user.isLeadRole) {
      return "Lead";
    } else if (this.authService.user.isManagerRole) {
      return "Manager";
    } else if (this.authService.user.isEmployeeRole) {
      return "Employee";
    } else {
      return "HDVI";
    }
  }

  private initializeUserForm(): void {
    this.usersInput =
      new SignInUserDropdown({
        key: "loginUser",
        label: "",
        placeholder: "Select User",
        disabled: false,
      });
    this.usersForm = this.formService.createFormGroup([this.usersInput]);
  }

  private getUsersWithSameEmailAddress(): void {
    if (NumberHelper.isAvailable(this.loggedInUserId)) {
      this.singleSignInService.getUsersWithSameEmailAddress(this.loggedInUserId, this.loggedInUserEmailAddress, this.loggedInUserGroupName)
        .subscribe(data => {
          this.usersOptions = data;
          this.usersInput = new SignInUserDropdown({ ...this.usersInput, options: data } as any);
          if (ArrayHelper.isAvailable(this.usersInput.options)) {
            this.disableUserDropdownControl();
          }
          this.setSignleSignInUserInput();
          this.changeDetector.markForCheck();
        });
    }
  }

  private disableUserDropdownControl(): void {
    this.usersInput.options.length <= 1 ? this.usersForm.get(this.usersInput.key).disable()
      : this.usersForm.get(this.usersInput.key).enable();

    if (this.usersInput.options.length <= 1) {
      this.hasSingleSO = true;
    }
  }

  get isCurrentUserAvailable(): boolean {
    return ArrayHelper.isAvailable(this.usersOptions) ?
        ArrayHelper.isAvailable(this.usersOptions.filter(user => user.value === this.loggedInUserId))
      : false;
  }

  private setSignleSignInUserInput(): void {
    if (this.isCurrentUserAvailable) {
      const optionsValue = this.usersInput.options.find(a => a.value === this.loggedInUserId);
      this.usersForm.get("loginUser").setValue(optionsValue.value);
      this.formService.updateDom.next();
    }
  }

  getUserInputValue(selectedUser: any): void {
    this.selectedUserId = selectedUser.value;
    this.authService.singleSignIn(this.selectedUserId);
  }

  getClass(): string {
    let styleClass = "user-sign-in__container";
    if (this.hasSingleSO) {
      styleClass += " --content";
    }
    return styleClass;
  }

}
