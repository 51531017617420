import { IAutoMapper } from "../interfaces";

export const mapRetrievalContactHistoryItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "RetrievalContactHistory")
    .forMember("contactLogId", o => o.contactLogId)
    .forMember("userName", o => o.userName)
    .forMember("callStartTime", o => o.callStartTime)
    .forMember("contactDate", o => o.contactDate)
    .forMember("contactDetail", o => o.contactDetail)
    .forMember("callResult", o => o.callResult)
    .forMember("contactMethodType", o => o.contactMethodType)
    .forMember("contactMethodTypeId", o => o.contactMethodTypeId)
    .forMember("callNote", o => o.callNote)
    .forMember("callDuration", o => o.callDuration)
    .forMember("callSId", o => o.callSId)
    .forMember("callRecordSID", o => o.callRecordSID)
    .forMember("callRecordUrl", o => o.callRecordUrl)
    .forMember("callTranscriptionAwsFileName", o => o.callTranscriptionAwsFileName)
    .forMember("callTranscriptionPreviewText", o => o.callTranscriptionPreviewText)
    .forMember("providerPacketTemplate", o => o.providerPacketTemplate)
    .forMember("providerPacketId", o => o.providerPacketId)
    .forMember("batchNumber", o => o.batchNumber)
    .forMember("isRetryFax", o => o.isRetryFax)
    .forMember("type", o => o.type)
    .forMember("title", o => o.title)
    .forMember("spokeTo", o => o.spokeTo)
    .forMember("nextCallDate", o => o.nextCallDate)
    .forMember("note", o => o.note)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("isSuccessful", o => o.isSuccessful)
    .forMember("status", o => o.status);
};
