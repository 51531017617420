<div class="container">
  <app-detail-main-template>
    <div container-header>
      <h3>3rd Party Copy Center</h3>
      <div class="dashboard-container">
        <div *ngFor="let item of headerStatistics;">
          <div class="item" *ngIf="item.key === 'Special Handling'" [ngClass]="getStatisticsClass(item)">
            <span class="item-header">{{item.key}}</span>
            <a class="users-land-stats-item bold">
              <span  (click)="getStatisticsFilter(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
   
    <div container-body>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <app-server-grid [configuration]="serverGridConfiguration"
                           [(request)]="serverRequest"
                           [filterTemplate]="filterForm"
                           [actionTemplate]="actionTemplate"
                           [actions]="actions"
                           [isLoadOnInit]="false"
                           [refresh]="refreshGrid"
                           [views]="views"
                           [refreshViews]="refreshViews">


            <ng-template #splHandlingCol let-rowData let-rowIndex="index">
              <app-icon iconStyle="mrcs-icon" iconType="mrcs-icon" iconName="special-assignment-address active" *ngIf="rowData.specialHandling ===1"></app-icon>
            </ng-template>
            <ng-template #filterForm let-form>
              <form [formGroup]="form" class="ui-modal-width">
                <app-tab-menu orientation="left">

                  <app-tab-panel header="AID">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="aidInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Provider Name">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="providerNameInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Project(s)">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Assigned To">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
                    </div>
                  </app-tab-panel>


                  <app-tab-panel header="Status">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="statusInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Address">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="cityInput"></form-factory>
                      <form-factory [formGroup]="form" [model]="stateInput"></form-factory>
                      <form-factory [formGroup]="form" [model]="postalCodeInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Group Name">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="groupNameInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Address Group">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="addressGroupIdInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel class="checkbox-group__halves" header="Vendor Name">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="vendorNameInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Vendor Confirmed">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="vendorConfirmedInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Tags">
                    <label class="tag-label">Tags</label>
                    <div class="tag__filterform">
                      <div class="ui-g-12 ui-md-12 ui-lg-12">
                        <div class="ui-g-2 ui-md-2 ui-lg-2">
                          <form-factory class="tag__operatorform"
                                        [formGroup]="form"
                                        [model]="tagsSearchOperatorInput"
                                        (onChange)="getSearchOperatorValue($event)">
                          </form-factory>
                        </div>
                        <div class="ui-g-10 ui-md-10 ui-lg-10">
                          <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                                      class="tag-content__item tag-content__item--multitag-close-icon"
                                      classes="transparent small"
                                      icon="times-circle"
                                      (onClick)="resetTagSearchInput()">
                          </app-button>
                          <form-factory class="tag__panelform"
                                        [formGroup]="form"
                                        [model]="tagsInput"
                                        (onPanelShow)="onShowEvent(true)"
                                        (onPanelHide)="onShowEvent(false)"
                                        (keyup)="onKeyUp($event)">
                          </form-factory>
                        </div>
                      </div>

                    </div>
                  </app-tab-panel>
                  <app-tab-panel header="Special Handling">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="shReasonInput"></form-factory>
                    </div>
                  </app-tab-panel>
                  
                  <app-tab-panel header="Retriever">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="coRetrievalOwnerInput"> </form-factory>
                    </div>
                  </app-tab-panel>

                </app-tab-menu>
              </form>
            </ng-template>

            <ng-template #actionTemplate
                         let-rowData let-localActionPopover="actionPopover">
              <div class="action-template-container">
                <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                            [text]="action.name"
                            (onClick)="action.action(rowData); localActionPopover.hide();"
                            [disabled]="action.disabled"></app-button>
              </div>
            </ng-template>
          </app-server-grid>
        </div>
      </div>
    </div>
  </app-detail-main-template>
</div>

<app-address-assignment-modal [(visible)]="isAssignModalVisible"
  (onHide)="closeModal()"
  (onUpdate)="gridRefresh()"
  [(addresses)]="selectedAddresses"
  [isUnassignEnabled]="isUnassignEnabled"></app-address-assignment-modal>

<app-manage-tag-modal [(visible)]="isManageTagModalVisible"
                      (onHide)="closeModal()"
                      [(selectedData)]="selectedAddresses"
                      [entityName]="entityName"
                      [totalEntityCount]="totalEntityCount"
                      [tagType]="tagType"
                      (onUpdate)="gridRefresh()">
</app-manage-tag-modal>
