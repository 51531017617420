import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../../core/navigation/parameter.service";
import { SessionService } from "../../../../../../core/storage/session.service";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Switch } from "../../../../../../dynamic-forms/inputs/switch/switch.model";
import { DocumentListItem } from "../../../../../../shared/document/chase-document-list/document-list-item.model";
import { CacheTracker } from "../../../../../../shared/document/document-page-viewer/cache-tracker.model";
import { DocumentPageViewerComponent } from "../../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { DocumentThumbnailState } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-state.model";
import { DocumentThumbnailType } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-type.enum";
import { DocumentPageService } from "../../../../../../shared/document/document-page.service";
import { DocumentRequestService } from "../../../../../../shared/document/document-request.service";
import { BulkAction } from "../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { MemberCentricChase } from "../../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { IOnUpload, UploadComponent } from "../../../../../../shared/upload/upload.component";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { debounceTimeAfterFirst } from "../../../../../../utilities/debounce-time-after";
import { LoaderHelper } from "../../../../../../utilities/loader-helper.class";
import { MedicalRecordUploadService } from "../../../../../api/medical-record-upload/medical-record-upload.service";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { JobsqueueService } from "../../../../jobsqueue/jobsqueue.service";
import { BulkUpdate } from "../../../../project/bulk-updates/bulk-update.model";
import { DirectoryUserRole } from "../../../../retrieval/directory-user-role";
import { DocumentPage } from "../../../../retrieval/retreival-document-review/document-page.model";
import { RetrievalDocumentServiceService } from "../../../../retrieval/retrieval-document-service.service";
import { DocumentSourceType } from "../../../../retrieval/retrieval-document-source-type.enum";
import { ChartService } from "../../../chase-detail/chase-detail-chart/chart.service";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { ChaseDocumentService } from "../../../chase-document.service";
import { MoveBackChasePageItem } from "../../../move-back-chase-page-item-model";
import { MoveBackChasePageRequest } from "../../../move-back-chase-page-request.model";
import { MoveBackChasePageType } from "../../../move-back-chase-page-type.enum";
import { DocumentViewerSessionService } from "../../document-viewer-session.service";

@Component({
  selector: "app-chase-document-page-thumbnail-expand",
  templateUrl: "./chase-document-page-thumbnail-expand.component.html",
  styleUrls: ["./chase-document-page-thumbnail-expand.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDocumentPageThumbnailExpandComponent extends LoaderHelper implements OnInit, OnChanges, OnDestroy {

  @Input()
  set currentTabSelected(val: string) {
    this.memberTabSelected = val === "member";
    this.encounterTabSelected = val === "encounters";
    this.submitTabSelected = val === "submit";

    this.memberNameFilter = true;
    this.dobFilter = true;
    this.genderFilter = true;
    this.providerFilter = true;
    this.dosFilter = true;
    this.diagnosisFilter = true;
    this.signsFilter = true;
    this.sympotomsFilter = true;
    this.medicationsFilter = true;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private documentRequestService: DocumentRequestService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly chartService: ChartService,
    private formService: FormService,
    private parameterService: ParameterService,
    private sessionService: SessionService,
    private jobsqueueService: JobsqueueService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private messagingService: MessagingService,
    private userService: UserService,
    private chaseDocumentService: ChaseDocumentService,
    protected readonly zone: NgZone,
    protected readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private documentPageService: DocumentPageService,
    private router: Router
  ) {
    super(zone);
  }

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }

  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get allThumbnails(): any[] {
    const pages = [...this.currentPagesWithCoversheet];
    return ArrayHelper.chunkArray(pages, this.NUMBER_OF_THUMBNAILS_PER_ROW);
  }

  get currentThumbnailNumber(): number {
    return this.state.currentThumbnailNumber;
  }

  set currentThumbnailNumber(value: number) {
    this.state.currentThumbnailNumber = value;
    this.changeDetector.markForCheck();
  }

  get currentDocumentQueueId(): number {
    return this.state.currentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    this.state.currentDocumentQueueId = value;
    this.changeDetector.markForCheck();
  }

  get hasCurrentPage(): boolean {
    return this.state.hasCurrentPage;
  }

  get totalPages(): number {
    return this.state.totalPages;
  }

  get isEditMode(): boolean {
    return this.isEditMode;
  }

  get moveChaseBackConfirmationText(): string {
    return this.removeAllPages ?
      "You are about to remove all pages for this chase. This will result in all chases for this member to move back to chart collection. Do you want to continue?"
      : "You are about to remove pages that were used during data entry. This will move all chases back to data entry. Do you want to continue?";
  }

  get removeAllPages(): boolean {
    return this.selectedItems.length === this.totalChartPages;
  }

  get isMoveBackMemberChasesToAbstraction(): boolean {
    return (this.isChaseBeyondMrr && this.selectedItems.some(s => this.chartDataEntryPages.includes(s.pageNumber)));
  }

  get uploadData(): KeyValuePairs<string> {
    if (!this.projectId) {
      return null;
    } else {

      const projectIdValue = this.projectId == null ? "" : this.projectId;

      return [
        { key: "ChaseId", value: this.chaseId.toString() },
        { key: "ProjectId", value: projectIdValue.toString() },
        { key: "RetrievalTypeId", value: DocumentSourceType.MRRUpload.toString() },
      ];
    }
  }

  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  usePresignedURLs: boolean;

  private localStoragePageNumber = "pageNumber";

  @Output() selectedDocumentThumbnailItems = new EventEmitter<any>();
  @Output() updateTotalPages = new EventEmitter<number>();


  readonly AUTHENTICATION_KEY = "authentication";
  @Input() chaseId: number;
  @Input() addressId: number;
  @Input() projectId: number;
  @Input() currentChartPageNumber = 1;
  @Input() isMaximized = false;
  @Input() documentPageIds: number[] = [];
  @Input() isSelectedThumbnailEventOption = new BehaviorSubject(null);
  @Output() resetMoveBackToggleSwitch = new EventEmitter<any>();
  @Output() toggleThumbnailViewClicked = new EventEmitter<{ view: string; pageNumber: number }>();
  @Output() expandedView = new EventEmitter<boolean>();

  @ViewChild(CdkVirtualScrollViewport, { static: true }) private thumbnailExpandedViewport: CdkVirtualScrollViewport;
  @ViewChild("fileUpload", { static: true }) fileUpload: UploadComponent;

  getSelectedFilters: string[] = ["Member Name", "DOB", "Gender", "Provider", "DOS"];
  memberTabSelected = false;
  encounterTabSelected = false;
  submitTabSelected = false;

  memberNameFilter = true;
  dobFilter = true;
  genderFilter = true;
  providerFilter = true;
  dosFilter = true;

  diagnosisFilter = true;
  signsFilter = true;
  sympotomsFilter = true;
  medicationsFilter = true;
  thumbnailEditMode = false;

  private readonly THUMBNAILKEY = "THUMBNAIL_FILTER";
  private readonly NUMBER_OF_THUMBNAILS_PER_ROW = 5;
  private currentThumbnailNumber$ = new BehaviorSubject<number>(0);
  private cacheTracker: CacheTracker;
  private sink = new SubSink();
  private state: DocumentThumbnailState;
  readonly itemSize = 240;
  protected scrollIndexChange$: Subject<number> = new Subject();

  private readonly startLoadRange = 22;
  private readonly endLoadRange = 20;

  formGroup: FormGroup;
  thumbnailFilter: Dropdown;
  clearCache = false;
  selectedThumbnail: number;
  thumbnailFilterValue: number;
  docType: string;

  moveBackDocumentEnabled: Switch;
  moveBackChasePageTypeOptions: BulkAction[] = [];
  moveBackChasePageType: Dropdown;
  selectedItems: MoveBackChasePageItem[] = [];
  selectedChasePageMoveType: string;
  private user: UserToken;
  hasMoveBackPermission: boolean;
  isRequestDocumentModalOpen = false;
  uploadSuccessMessage = "Thank you for uploading this medical record.  We are now processing the file.  Please check back shortly and your document will be attached.";
  uploadSuccessMessage1 = "Thank you for uploading medical record : ";
  uploadSuccessMessage2 = ".  We are now processing the file.  Please check back shortly and your document will be attached.";
  isConfirmMoveBackAllVisible = false;
  isChaseBeyondMrr = false;
  chartDataEntryPages: number[];
  memberChases: MemberCentricChase[];
  totalChartPages: number;
  currentDocQueueId: number = null;
  isFromToggledView = false;
  isBucketFolderChecked = false;
  prevMouseClick = 0;
  documentListItems: DocumentListItem[];
  maxDocuments = 0;
  diagnosisFilterSelected = false;
  thumbnailViewWithFilter = false;
  currentPageNumber = 0;

  ngOnInit() {
    this.state = new DocumentThumbnailState();
    this.cacheTracker = new CacheTracker();

    this.sink.add(
      this.currentThumbnailNumber$
        .pipe(debounceTimeAfterFirst(50))
        .subscribe(pageNumber => {
          this.currentThumbnailNumber = pageNumber;
        }),
      this.chaseDetailStateService.isChaseBeyondMrr.subscribe(data => {
        this.isChaseBeyondMrr = data;
      }),

      this.chaseDetailStateService.selectedFilters.subscribe(data => {

        if (data != null) {
          this.getSelectedFilters = data;

          this.memberNameFilter = this.getSelectedFilters.indexOf("Member Name") > -1;
          this.dobFilter = this.getSelectedFilters.indexOf("DOB") > -1;
          this.genderFilter = this.getSelectedFilters.indexOf("Gender") > -1;
          this.providerFilter = this.getSelectedFilters.indexOf("Provider") > -1;
          this.dosFilter = this.getSelectedFilters.indexOf("DOS") > -1;

          this.diagnosisFilter = this.getSelectedFilters.indexOf("Diagnosis") > -1;
          this.signsFilter = this.getSelectedFilters.indexOf("Signs") > -1;
          this.sympotomsFilter = this.getSelectedFilters.indexOf("Symptoms") > -1;
          this.medicationsFilter = this.getSelectedFilters.indexOf("Medications") > -1;
        }
        this.changeDetector.markForCheck();
      }),


      this.chaseDetailStateService.memberChases.subscribe(data => {
        this.memberChases = data;
      }),
      this.chaseDetailStateService.isDiagnosisSelected.subscribe(data => {
        this.diagnosisFilterSelected = data;
      }),
      this.chaseDetailStateService.inititateProcessForMoveBackChasePages.subscribe(data => {
        if (data) {
          this.inititateMoveBackChasePages();
        }
      }),

      this.jobsqueueService.newJobEvent.subscribe(data => {
        if (data.find(d => d.stateMachineName === "MRCS Chart Upload")) {
          this.resetThumbnailView();
          this.initialThumbnailLoad();
          this.changeDetector.markForCheck();
        }
      }),
      this.chaseDetailStateService.resetMoveBackToggleFromHeader.subscribe(toggleSwitch => {
        if (toggleSwitch !== null) {
          this.onChangeMoveBackDocumentEnabled(toggleSwitch);
        }
        this.changeDetector.markForCheck();
      })
    );

    this.isSelectedThumbnailEventOption.subscribe(event => {
      this.onSelectionChange(event);
    });

    this.expandedView.emit(true);
    this.setMoveBackProcess();
    this.initializeForm();
    this.getThumbnailFilterOptions();
    this.setThumbnailFilter();
    this.initialThumbnailLoad();
    this.getDocumentListItems();
    this.changeDetector.detectChanges();

    this.sink.add(
      this.scrollIndexChange$.pipe(
        filter(v => NumberHelper.isAvailable(v)),
        debounceTime(500)
      ).subscribe(index => this.updatePageAndIndex(index)),
      this.documentPageService.chartPageChange$?.pipe(
        distinctUntilChanged(),
        debounceTime(100),
        filter(n => NumberHelper.isAvailable(n) && n !== this.currentPageNumber)
      ).subscribe(index => {
        const pageNumber = (index - 1);
        this.currentPageNumber = pageNumber;
        this.currentThumbnailNumber = pageNumber;
        this.state.currentThumbnailNumber = pageNumber;
        this.selectedThumbnail = index;
        this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.selectedThumbnail / this.NUMBER_OF_THUMBNAILS_PER_ROW));
      })
    );

    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  private updatePageAndIndex(index: number): void {
    if (index !== this.currentPageNumber) {
      const pageNumber = index * this.NUMBER_OF_THUMBNAILS_PER_ROW;
      this.currentThumbnailNumber = pageNumber;
      this.state.currentThumbnailNumber = pageNumber;
      this.currentPageNumber = pageNumber;
      this.loadThumbnails(pageNumber);
    }
  }

  loadThumbnails(pageNumber: number): void {
    // Need to add + 1 since the array starts at 0 but the pages start at 1
    const actualPageNumber = pageNumber + 1;
    const isInRange = this.isInRange(actualPageNumber, this.startRange, this.endRange, this.currentPages, "image");
    if (!isInRange || this.currentPages.length <= 0) {
      return;
    }
    const pagesToLoad = this.getPagesToLoad(pageNumber, this.startLoadRange, this.endLoadRange, this.currentPages, "image");
    if (!ArrayHelper.isAvailable(pagesToLoad)) {
      return;
    }
    this.loadThumbnailsPages(
      this.retrievalDocumentService,
      "getDocumentThumbnailsWithDocQueueId",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.isBucketFolderChecked,
      this.thumbnailFilterValue,
      (documentPages => {
        this.cdnBucketFolders = [...documentPages.s3ImagesFolders];
        this.setPages([...this.currentPages]);
      }));
  }

  getEvidencedata(item: any, val: string): string {
    const evidenceData: any = [];

    if (val === "text") {
      item.map(ele => { evidenceData.push(ele.text); }).join(",");
      return evidenceData;
    }

    if (val === "chartlocation") {
      item.map(ele => { evidenceData.push(ele.chartLocation); }).join(",");
      return evidenceData;
    }
  }

  @HostListener("window:beforeunload")
  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
    this.resetThumbnailView();
    this.expandedView.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentChartPageNumber) {
      if (changes.currentChartPageNumber.currentValue !== changes.currentChartPageNumber.previousValue) {
        this.currentThumbnailNumber$.next(changes.currentChartPageNumber.currentValue);
        this.selectedThumbnail = changes.currentChartPageNumber.currentValue;
        this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.selectedThumbnail / this.NUMBER_OF_THUMBNAILS_PER_ROW));
        this.changeDetector.markForCheck();
      }
    }
  }

  initializeForm(): void {
    this.thumbnailFilter = new Dropdown({
      key: "thumbnailFilter",
    });

    this.moveBackDocumentEnabled = new Switch({
      key: "moveBackDocumentEnabled",
      followingOnLabel: "MANAGE",
    });

    this.formGroup = this.formService.createFormGroup([this.thumbnailFilter, this.moveBackDocumentEnabled]);
  }

  setMoveBackProcess(): void {
    this.user = this.userService.getUserToken();
    const directoryRoleIdsForMoveBackPermission = [
      DirectoryUserRole.Admin,
      DirectoryUserRole.ClientLead,
      DirectoryUserRole.ClinicalLead,
      DirectoryUserRole.MRRManager,
      DirectoryUserRole.OverreadManager,
      DirectoryUserRole.ClientOverreadManager,
      DirectoryUserRole.ManageDocPages];
    const userContainsMoveBackRole = this.user.directoryRoleIds.filter(d => directoryRoleIdsForMoveBackPermission.includes(d));
    this.hasMoveBackPermission = ArrayHelper.isAvailable(userContainsMoveBackRole);
    this.chasePagesMoveBackReasons();

    this.getDataEntryPages();
  }

  getDataEntryPages(): void {
    this.chartService.getDataEntryPages(this.chaseId).subscribe(data => {
      this.chartDataEntryPages = data;
    });
  }

  chasePagesMoveBackReasons(): void {
    for (const element of Object.keys(MoveBackChasePageType)) {
      if (!isNaN(Number(element))) {
        const x = String(MoveBackChasePageType[element]);
        const reasonType = x.replace(/([a-z])([A-Z])/g, "$1 $2");

        this.moveBackChasePageTypeOptions.push(new BulkAction
          ({
            name: reasonType,
            action: this.inititateMoveBackChasePages.bind(this),
          }));
      }
    }
  }

  moveBackChasePages(): void {
    const moveBackRequest = new MoveBackChasePageRequest({
      moveBackChasePageType: Number(this.selectedChasePageMoveType),
      moveBackChasePageItems: this.selectedItems,
    });

    this.retrievalDocumentService.moveBackChasePages(moveBackRequest).subscribe(
      () => {
        if (MoveBackChasePageType.Remove === moveBackRequest.moveBackChasePageType && ArrayHelper.isAvailable(this.selectedItems)) {
          this.updateTotalPages.next(this.selectedItems.length);
        }
        this.messagingService.showToast("Moved back Chase Pages.", SeverityType.SUCCESS);
        if (this.isMoveBackMemberChasesToAbstraction && !this.removeAllPages) {
          this.moveBackMemberChasesToAbstraction();
        }
        this.sessionService.delete(this.THUMBNAILKEY);
        this.resetThumbnailView();
        this.initialThumbnailLoad();
      },
      err => {
        this.messagingService.showToast("Failed to Moved back Chase Pages.", SeverityType.ERROR);
      });
  }

  inititateMoveBackChasePages(): void {
    if (this.removeAllPages || this.isMoveBackMemberChasesToAbstraction) {
      this.isConfirmMoveBackAllVisible = true;
    } else {
      this.moveBackChasePages();
    }
  }

  getDocumentListItems(): void {
    this.chaseDocumentService
      .getDocumentListItems(this.chaseId, this.maxDocuments)
      .subscribe(result => {
        this.documentListItems = result;
        this.currentPagesWithCoversheet = [...this.updateThumbnailStyle(this.currentPages, result)];
        this.changeDetector.markForCheck();
      });
  }

  private moveBackMemberChasesToAbstraction(): void {
    const chaseToMoveBacktoAbstraction = this.memberChases.filter(m => m.workflowStatusId === WorkflowStatusDb.Overread
      || m.workflowStatusId === WorkflowStatusDb.Overread2
      || m.workflowStatusId === WorkflowStatusDb.Delivery
      || m.workflowStatusId === WorkflowStatusDb.Completed);

    const moveBackMemberChasesModel = new BulkUpdate({
      chaseIds: chaseToMoveBacktoAbstraction.map(c => c.chaseId.toString()),
      status: WorkflowStatusDb.Abstraction.toString(),
      action: 1,
      chaseRequestUpdate: 1,
    });
    this.retrievalDocumentService.moveChases(moveBackMemberChasesModel).subscribe(
      () => {
        this.messagingService.showToast("Moved back All Member Chase(s) to Data Entry.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      },
      err => {
        this.messagingService.showToast("Failed to Move back All Member Chase Pages to Data Entry.", SeverityType.ERROR);
        this.changeDetector.markForCheck();
      });
  }

  updateCurrentPage(index: number): void {
    const pageNumber = index * this.NUMBER_OF_THUMBNAILS_PER_ROW;
    this.scrollToIndex(pageNumber);
    this.documentViewerSessionService.updateDataEntryPageNumber(index);
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page != null && StringHelper.isAvailable(page.source);
  }

  hasThumbnailImageUrl(thumbnail: DocumentPage): boolean {
    return StringHelper.isAvailable(thumbnail.image);
  }

  hasThumbnails(): boolean {
    return ArrayHelper.isAvailable(this.pages);
  }

  isThumbnailSelected(thumbnail: DocumentPage): boolean {
    if (this.thumbnailEditMode) {
      return thumbnail.isThumbnailSelectedInEditMode;
    } else {
      return this.selectedThumbnail === thumbnail.pageNumber;
    }
  }

  updateIndex(index): void {
    this.scrollIndexChange$.next(index);
  }

  getThumbnailSource(thumbnail: DocumentPage): string {
    return this.hasThumbnailImageUrl ? (thumbnail.isCdnChartImageExist ? thumbnail.source.replace("data:image/jpg;base64,", "")
      : thumbnail.source) : "";
  }

  isDocumentThumbnail(thumbnail: DocumentPage): boolean {
    return thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.THUMBNAIL_IMAGE;
  }

  isCoversheetThumbnail(thumbnail: DocumentPage): boolean {
    return thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.COVERSHEET;
  }

  toggleThumbnailView(view: string): void {
    this.toggleThumbnailViewClicked.emit({ view, pageNumber: this.selectedThumbnail });
  }

  thumbnailFilterChange(event: SelectableInput): void {
    this.thumbnailFilterValue = Number(event.value);
    this.sessionService.put(this.THUMBNAILKEY, this.thumbnailFilterValue);
    this.resetMoveBackSwitch();
    this.thumbnailEditMode = false;
    this.isBucketFolderChecked = false;
    this.cleanCacheAfterNGDestory();
    this.initialThumbnailLoad();
    this.changeDetector.markForCheck();
  }

  thumbnailClicked(thumbnail: DocumentPage): void {
    if (this.thumbnailEditMode) {
      thumbnail.isThumbnailSelectedInEditMode = !thumbnail.isThumbnailSelectedInEditMode;
      this.getDataForMoveBack(thumbnail);
    } else {
      this.updateCurrentPage(thumbnail.pageNumber);
    }

    const thisClick = Date.now();

    if (thisClick - this.prevMouseClick < 250) {
      this.toggleThumbnailView("collapse");
    }

    this.prevMouseClick = thisClick;
  }

  coversheetClicked(thumbnail: DocumentPage): void {
    if (this.thumbnailEditMode) {
      const filteredDocumentQueueIdPages: DocumentPage[] = this.pages.filter(page => page?.documentQueueId === thumbnail.documentQueueId);
      const isAllPagesSelectedForEdit = filteredDocumentQueueIdPages.every(filteredpage => filteredpage.isThumbnailSelectedInEditMode);

      filteredDocumentQueueIdPages.forEach(filteredthumbnail => {
        if (isAllPagesSelectedForEdit) {
          filteredthumbnail.isThumbnailSelectedInEditMode = false;
          this.getDataForMoveBack(filteredthumbnail);
        } else {
          if (!filteredthumbnail.isThumbnailSelectedInEditMode) {
            filteredthumbnail.isThumbnailSelectedInEditMode = true;
            this.getDataForMoveBack(filteredthumbnail);
          }
        }
      });
    }
  }

  nonThumbnailClicked(thumbnail: DocumentPage): void {
    thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.ADD_PAGE ? this.triggerUpload() : this.openAdditionalDocumentationModal();
  }

  triggerUpload() {
    const elementList = document.querySelectorAll('input[type="file"]');
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    elementList[0].dispatchEvent(clickEvent);
  }

  onChangeMoveBackDocumentEnabled(event: any): void {
    if (event.checked) {
      this.selectedItems = [];
    } else {
      this.resetSelectedInEditModeThumbnails();
    }

    this.thumbnailEditMode = event.checked;
  }

  onSelectionChange(event: any): void {
    const selectedReasonString = String(event);
    const reasonEnum = selectedReasonString.replace(/\s/g, "");
    this.selectedChasePageMoveType = MoveBackChasePageType[reasonEnum];
  }

  getNonThumbnailIcon(thumbnail: DocumentPage): string {
    const typeOfThumbnail = thumbnail.documentThumbnail.thumbnailType;

    switch (typeOfThumbnail) {
      case DocumentThumbnailType.ADD_PAGE:
        return "plus";
      case DocumentThumbnailType.REQUEST_PAGE:
        return "tasks";
      default:
        return "";
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  private getDataForMoveBack(thumbnail: DocumentPage) {
    this.state.setThumbnailPage(thumbnail);

    if (thumbnail.isThumbnailSelectedInEditMode) {
      const selectedItemToMoveBack = new MoveBackChasePageItem({
        documentQueueId: thumbnail.documentQueueId,
        chaseId: this.chaseId,
        pageNumber: thumbnail.pageNumber,
        documentPageNumber: thumbnail.documentPageNumber,
      });
      this.selectedItems = [...this.selectedItems, selectedItemToMoveBack];
      this.selectedDocumentThumbnailItems.emit(this.selectedItems);
    } else {
      const thumbnailToRemoveIndex = this.selectedItems.findIndex(x => x.pageNumber === thumbnail.pageNumber);
      const selectedItemsToMoveBack = [...this.selectedItems];

      if (NumberHelper.isGreaterThan(thumbnailToRemoveIndex, 0, true)) {
        selectedItemsToMoveBack.splice(thumbnailToRemoveIndex, 1);
        this.selectedItems = [...selectedItemsToMoveBack];
        this.selectedDocumentThumbnailItems.emit(this.selectedItems);
      }
    }
  }

  private resetSelectedInEditModeThumbnails() {
    this.pages.forEach(page => {
      if (page?.isThumbnailSelectedInEditMode) {
        page.isThumbnailSelectedInEditMode = false;
        this.state.setThumbnailPage(page);
      }
    });
  }

  private initialThumbnailLoad(): void {
    const pagesToLoad = [1, 15];
    pagesToLoad.forEach(pageNumber => this.state?.setPage({ pageNumber } as any));
    this.loadThumbnailsPages(
      this.retrievalDocumentService,
      "getDocumentThumbnailsWithDocQueueId",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.isBucketFolderChecked,
      this.thumbnailFilterValue, thumbnailPages => {
        const length = (thumbnailPages && ArrayHelper.isAvailable(thumbnailPages.pages)) ? thumbnailPages.pages[0].numberOfPages : 0;
        this.cacheTracker = new CacheTracker();
        // Set Pages
        this.currentDocumentQueueId = thumbnailPages.pages[0]?.documentQueueId;
        const thumbnailPagesLength = thumbnailPages.pages.length;
        const emptyArray: DocumentPage[] = new Array(length - thumbnailPagesLength).fill({});
        const pagestToSet = [...thumbnailPages.pages, ...emptyArray].map((page: DocumentPage, index) => {
          if (!StringHelper.isAvailable(page.image)) {
            return new DocumentPage({ pageNumber: index + 1 });
          }
          return page;
        });
        this.setPages(pagestToSet);
        if (NumberHelper.isGreaterThan(this.selectedThumbnail, 1)) {
          this.isFromToggledView = true;
        } else {
          this.currentThumbnailNumber = 1;
          this.scrollToIndex(0);
        }
        this.changeDetector.markForCheck();
      },
      () => { });
  }

  private scrollToIndex(pageNumber: number): void {
    this.thumbnailExpandedViewport.scrollToIndex(Math.floor(pageNumber / this.NUMBER_OF_THUMBNAILS_PER_ROW) - 1);
  }

  private setPages(pages: DocumentPage[]): void {
    this.currentPages = this.updatePageStyle(pages);
    this.currentPagesWithCoversheet = [...this.updateThumbnailStyle(pages, this.documentListItems)];
    this.changeDetector.markForCheck();
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;
    this.selectedItems = [];

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });
  }

  private getThumbnailFilterOptions(): void {
    this.documentRequestService
      .getDocumentThumbnailFilter(this.chaseId)
      .subscribe(options => {
        options.unshift(new SelectableInput({ text: "Complete Document File", value: null }));
        const isV2Chase = this.router.url.includes("/members/chasev2");
        if (isV2Chase) {
          options.forEach((option, index) => {
            if (option.text === "New Pages") {
              options.splice(index, 1);
            }
          });
        }

        this.thumbnailFilter = new Dropdown({ ...this.thumbnailFilter, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  private setThumbnailFilter(): void {
    if (this.hasDocTypeInUrl()) {
      this.thumbnailFilterValue = this.getDocTypeValue();
    } else if (this.hasSessionKey(this.THUMBNAILKEY)) {
      this.thumbnailFilterValue = this.getSessionFilterValue();
      this.sessionService.put(this.THUMBNAILKEY, this.thumbnailFilterValue);
    } else {
      this.thumbnailFilterValue = null;
    }
    this.formGroup.get(this.thumbnailFilter.key).setValue(this.thumbnailFilterValue);
  }

  private getSessionFilterValue(): number {
    const sessionValue = this.sessionService.get(this.THUMBNAILKEY, null);
    return StringHelper.isAvailable(sessionValue) ? Number(sessionValue) : sessionValue;
  }

  private hasSessionKey(sessionKey: string): boolean {
    const key = sessionKey;
    const filterKey = this.sessionService.get<any>(key, null);
    const hasKey = filterKey != null;
    return hasKey;
  }

  private hasDocTypeInUrl(): boolean {
    this.docType = this.parameterService.getNormal("docType", "");
    return StringHelper.isAvailable(this.docType);
  }

  private getDocTypeValue(): number {
    if (this.docType.toLowerCase() === "newpages") {
      return -999999999;
    }
  }

  private resetThumbnailView(): void {
    this.currentSpineColorIndex = 0;
    this.resetMoveBackSwitch();
    this.thumbnailEditMode = false;
    this.getThumbnailFilterOptions();
    this.setThumbnailFilter();
    this.changeDetector.markForCheck();
  }

  private resetMoveBackSwitch(): void {
    this.formGroup.get(this.moveBackDocumentEnabled.key).setValue(false);
    this.moveBackDocumentEnabled = new Switch({ ...this.moveBackDocumentEnabled, followingOnLabel: "MANAGE" });
    this.selectedItems = [];
    this.resetMoveBackToggleSwitch.emit(false);
    this.chaseDetailStateService.resetMoveBackToggle.next(false);
  }

  openAdditionalDocumentationModal(): void {
    this.isRequestDocumentModalOpen = true;
  }

  uploadDocument(event: IOnUpload): void {
    const documents = event.formData.getAll("Document");
    const chaseId = event.formData.get("ChaseId");
    const projectId = event.formData.get("ProjectId");
    const retrievalTypeId = event.formData.get("RetrievalTypeId");

    let fileNum = 0;
    let fileNumCounter = 0;

    for (const document of documents) {
      const doc = (document as File);
      const fileName = doc.name;
      const newFormData = new FormData();

      newFormData.append("Document", document);
      newFormData.append("ChaseId", chaseId);
      newFormData.append("ProjectId", projectId);
      newFormData.append("RetrievalTypeId", retrievalTypeId);

      if (this.usePresignedURLs) {
        const name = event.presignedURLList[fileNumCounter.toString()];
        const OFN = event.originalFilenameList[fileNumCounter.toString()];
        fileNum = 0;
        newFormData.append("Size", doc.size.toString());
        newFormData.append("0", name);
        newFormData.append("OFN0", OFN);
        for (const item in event.presignedURLList) {
          if (StringHelper.isAvailable(item)) {
            event.formData.append(fileNum.toString(), event.presignedURLList[item].toString());
          }}
        newFormData.append("NumFiles", "1");
        newFormData.delete("Document");
        fileNumCounter++;
        this.medicalRecordUploadService.uploadPresigned(newFormData).subscribe(
          data => {
            this.sessionService.delete(this.THUMBNAILKEY);
            this.resetThumbnailView();
            this.initialThumbnailLoad();
            this.changeDetector.markForCheck();
            this.messagingService.showToast(`${this.uploadSuccessMessage1}${fileName}${this.uploadSuccessMessage2}`, SeverityType.SUCCESS);
            this.fileUpload.deleteFile(fileName);
            event.success();
          },
          err => {
          }
        );
        } else {
          this.medicalRecordUploadService.upload(newFormData).subscribe(
            data => {
              this.sessionService.delete(this.THUMBNAILKEY);
              this.resetThumbnailView();
              this.initialThumbnailLoad();
              this.changeDetector.markForCheck();
              this.messagingService.showToast(`${this.uploadSuccessMessage1}${fileName}${this.uploadSuccessMessage2}`, SeverityType.SUCCESS);
              this.fileUpload.deleteFile(fileName);
            },
            err => {
              this.messagingService.showToast(`Error while uploading Document: ${fileName}, please try again.`, SeverityType.ERROR);
            }
          );
        }
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
