import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BasicGridDemoComponent } from "./basic-grid-demo/basic-grid-demo.component";
import { ButtonsComponent } from "./buttons/buttons.component";
import { AppColorsComponent } from "./colors/app-colors/app-colors.component";
import { BrandColorsComponent } from "./colors/brand-colors/brand-colors.component";
import { ColorsComponent } from "./colors/colors.component";
import { MessagingColorsComponent } from "./colors/messaging-colors/messaging-colors.component";
import { DocumentComponent } from "./document/document.component";
import { FlexComponent } from "./flex/flex.component";
import { FormsComponent } from "./forms/forms.component";
import { IconsComponent } from "./icons/icons.component";
import { MenusComponent } from "./menus/menus.component";
import { NavigationComponent } from "./menus/navigation/navigation.component";
import { StepsMenuComponent } from "./menus/steps-menu/steps-menu.component";
import { ExampleTabMenuComponent } from "./menus/tab-menu/example-tab-menu.component";
import { TabMenuComponent } from "./menus/tab-menu/tab-menu.component";
import { ModalsAndPanelsComponent } from "./modals-and-panels/modals-and-panels.component";
import { ToolboxRoutingModule } from "./toolbox-routing.module";
import { ToolboxComponent } from "./toolbox.component";
import { TypographyComponent } from "./typography/typography.component";
import { UploadComponent } from "./upload/upload.component";

@NgModule({
  imports: [
    ToolboxRoutingModule,
    SharedModule,
  ],
  declarations: [
    ToolboxComponent,
    BasicGridDemoComponent,
    DocumentComponent,
    ColorsComponent,
    IconsComponent,
    BrandColorsComponent,
    ButtonsComponent,
    MenusComponent,
    StepsMenuComponent,
    TabMenuComponent,
    ExampleTabMenuComponent,
    NavigationComponent,
    TypographyComponent,
    AppColorsComponent,
    MessagingColorsComponent,
    FormsComponent,
    UploadComponent,
    ModalsAndPanelsComponent,
    FlexComponent,
  ],
  exports: [
    ToolboxComponent,
  ],
})
export class ToolboxModule { }
