<div class="result-modal">

  <p-dialog 
    [(visible)]="visible" 
    [header]="header"
    [closable]="false" 
    [draggable]="true" 
    [resizable]="false"
    [modal]="foreground" 
    (onShow)="show()" 
    (onHide)="hide()" 
    (visibleChange)="change($event)">

    <ng-content></ng-content>

    <p-footer>
      <ng-content select="footer"></ng-content>
    </p-footer>

  </p-dialog>
</div>

<div *ngIf="visible && allowClose" (document:keydown.escape)="cancel()"></div>