<div class="dataload-container">
  <div class="ui-g-5 upload__selectionInputs">
    <form-factory [formGroup]="dataLoadFormGroup" [model]="projectsInput" (onChange)="onProjectChange($event, false)">
    </form-factory>
  </div>
  <div class="upload__selectionInputs project">
    <form-factory [formGroup]="dataLoadFormGroup" [model]="projectIdInput" (onChange)="onProjectChange($event, true)">
    </form-factory>
  </div>
  <div *ngIf="showLoadTypeSection" class="ui-g-5 upload__selectionInputs">
    <form-factory [formGroup]="dataLoadFormGroup" [model]="loadTypesInput" (onChange)="onLoadTypeChange($event)">
    </form-factory>
  </div>
</div>
<div *ngIf="showUploadSection">
  <div class="upload__section upload__section--top">
    <span class="upload__instruction--header bold">Data Import Instructions</span><span
      class="upload__instruction--link" (click)="getDownloadInstruction()">DOWNLOAD INSTRUCTIONS</span>
    <p class="upload__instruction--text">We've put together a detail data requirements that you can access to get
      familiar with our upload requirements.
      Please
      download the attached instruction file to get the complete list of required fields and rules for your data upload.
    </p>
    <p class="upload__instruction--text">Please read each data requirement carefully and ensure that your files have
      the required data to avoid
      interruptions
      with your data upload.</p>
  </div>
  <div class="upload__documentUpload upload__section upload__section--bottom">
    <div *ngFor="let requiredFile of requiredFiles; let i=index; trackBy: trackByIndex"
    [ngClass]="fileUploadSpacing(requiredFile)">
      <div class="required-files__file-type">
        <span>{{requiredFile.fileTypeName}} File</span><span *ngIf="!requiredFile.fileTypeIsOptional">*</span>
      </div>
      <div class="file-format">{{requiredFile.fileFormat}}</div>
      <div class="required-files__file">
        <div class="required-files__name"></div>
        <app-file-upload-new class="required-files__upload" #appFileUpload name="dataload[]" chooseLabel="BROWSE"
          accept=".txt,.xml,.csv" [multiple]="false" [showUploadButton]="true" [showCancelButton]="true" customUpload="true"
          [data]="getUploadData(requiredFile)" (onUpload)="uploadDocument($event, i, appFileUpload, requiredFile)"
          (resetFileUpload)="resetFileUpload()" [hasError]="requiredFile.hasError" [uploadErrorMessage]="requiredFile.errorMessage">
        </app-file-upload-new>
      </div>
    </div>
    <div class="upload__instruction--text upload__spacing" *ngIf="showDiagnosisFileNotRequired">Note: A diagnosis file upload is not needed for this data load because VRC code is not required for the selected
    project.</div>
    <div class="text-right">
      <app-button classes="secondary" class="upload__documentUpload--button" text="RESET" (onClick)="resetFileUpload();deleteFile()">
      </app-button>
      <app-button class="upload__documentUpload--button" text="{{processButtonText}}"
        (onClick)="startDataValidation()" [disabled]="!enableProcessButton">
      </app-button>
    </div>
  </div>
</div>
<app-button *ngIf="showDeleteSection" classes="secondary" class="delete-button" text="DELETE"
  (onClick)="startDataValidation()">
</app-button>

<app-confirmation-modal 
  class="delete-confirmation-modal"
  [(visible)]="isConfirmDeleteVisible"
  header="ARE YOU SURE?"
  acceptText="CONFIRM"
  rejectText="CANCEL"
  (onYes)="deleteData()">
  You are about to permanently delete your data from this project.<br />Are you sure you want to delete them?
</app-confirmation-modal>

<app-result-modal 
  class="processing-modal" 
  [(visible)]="isProcessingVisible"
  header="PLEASE WAIT...{{processHeaderText}} IS IN PROGRESS" 
  [allowClose]="false">
  <div class="processing-modal__icon-spinner">
    <app-icon iconName="spinner" spin=true></app-icon>
  </div>
  <div class="processing-modal__message">This might take a few minutes</div>
</app-result-modal>