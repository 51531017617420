import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { RetrievalCallFlowService } from "../../../retrieval-call-flow/retrieval-call-flow.service";
import { FtDetailHeaderService } from "../ft-detail-header/ft-detail-header.service";
import { FtDetailInfoService } from "../ft-detail-info/ft-detail-info.service";
import { FtDetailEditService } from "./ft-detail-edit.service";
import { FtEditModel } from "./ft-edit-model";

@Component({
  selector: "retrieval-ft-detail-edit",
  templateUrl: "./ft-detail-edit.component.html",
  styleUrls: ["./ft-detail-edit.component.scss"],
})
export class FtDetailEditComponent implements OnInit, OnDestroy {
  @Input() isCreateVisible = false;
  @Output() formClose = new EventEmitter();
  private unsubscribe = new Subject();

  ftEditModel: FtEditModel;
  private ftId: number;

  readonly formGroup: FormGroup;
  email: Textbox;
  phone: Textbox;
  fax: Textbox;
  contactName: Textbox;
  groupName: Textbox;

  constructor(
    private readonly formService: FormService,
    private ftInfoService: FtDetailInfoService,
    private ftDetailHeaderService: FtDetailHeaderService,
    private service: FtDetailEditService,
    private callFlowService: RetrievalCallFlowService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private router: Router,
    private readonly route: ActivatedRoute
  ) {

    this.ftId = this.service.getFTGdFromPath();

    this.groupName = new Textbox({
      key: "groupName",
      label: "Group Name",
      placeholder: "Group Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Group Name can not be more than 100 characters.",
      },
    });

    this.email = new Textbox({
      key: "email",
      label: "Email",
      placeholder: "Email",
      validators: [Validators.email, Validators.maxLength(50)],
      errorMessages: {
        email: "Please enter a valid email address.",
        maxlength: "Email name can not be more than 50 characters.",
      },
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.fax = new Textbox({
      key: "fax",
      label: "fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
      },
    });

    this.formGroup = this.formService.createFormGroup([this.email, this.phone, this.fax, this.contactName, this.groupName]);

  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {

      this.ftId = +params.get("FtGd");

      this.service
        .getFtDetailsForEdit()
        .subscribe(this.assignAndNotify);
    });

    this.service.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => this.updateFtDetailsForEdit());

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateFtDetailsForEdit() {
    this.service
      .getFtDetailsForEdit()
      .subscribe(this.assignAndNotify);
  }

  private assignAndNotify = (data: FtEditModel): void => {

    this.ftEditModel = new FtEditModel(data);

    this.loadForm();
  }

  private loadForm() {

    this.formGroup.patchValue({
      email: this.ftEditModel.email,
      phone: this.ftEditModel.phone,
      fax: this.ftEditModel.faxNumber,
      contactName: this.ftEditModel.contactName,
      groupName: this.ftEditModel.groupName,
    });

    this.changeDetector.markForCheck();
  }

  onUpdate(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.getFormValues();

    this.service.updateFtDetails(this.ftEditModel)
      .subscribe((data: number) => {
        if (data > 0) {
          this.ftInfoService.resetFt(this.ftId);
          this.ftDetailHeaderService.resetFt(this.ftId);
          this.callFlowService.resetFunction("");
          this.messagingService.showToast("Field Tech Site Details successfully edited.", SeverityType.SUCCESS);

        } else {
          this.messagingService.showToast("Failed to edit Field Tech site Details.", SeverityType.ERROR);
        }
      });

    this.visibleChange(false);

  }

  private getFormValues() {
    this.changeDetector.markForCheck();

    this.ftEditModel.email = this.formGroup.get("email").value;
    this.ftEditModel.phone = this.formGroup.get("phone").value;
    this.ftEditModel.faxNumber = this.formGroup.get("fax").value;
    this.ftEditModel.contactName = this.formGroup.get("contactName").value;
    this.ftEditModel.groupName = this.formGroup.get("groupName").value;
  }

  visibleChange($event) {
    this.isCreateVisible = $event;
    this.formClose.emit($event);
  }

}
