<div class="drag-list" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="drag-list__body" *ngFor="let item of value; index as i; trackBy: trackByIndex" cdkDrag>
    <div class="drag-list__item">
      <input 
        class="drag-list__input" 
        type="text" value={{item.itemText}} 
        placeholder="Enter text here"
        (input)="onTextChange($event.target.value, i)">
      <div class="drag-list__buttons">
        <div class="drag-list__handle" cdkDragHandle>
          <i class="fas fa-grip-vertical"></i>
        </div>
        <div class="drag-list__delete" (click)="deleteItem(i)">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </div>
  </div>
  <app-button class="add-btn" text="Add new" (onClick)="addItem()"></app-button>
</div>