<app-modal [(visible)]="isUpdatePendVisible" header="UPDATE PEND" (visibleChange)="visibleChange($event)" class="internal-pends-update-pend-modal">
  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid basic-filter">

      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="hasPendCode">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <span class="error-icon-invoice setPosition"><app-icon iconName="question-circle" *ngIf="showInvoiceError" [title]="required"></app-icon></span>
          <form-factory [formGroup]="formGroup" (keypress)="onSelectPendInvoice($event)" [model]="pendInvoice"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <span class="error-icon-amount setPosition"><app-icon iconName="question-circle" *ngIf="showAmountError" [title]="required"></app-icon></span>
          <form-factory [formGroup]="formGroup" (keypress)="onSelectPendAmount($event)" [model]="pendAmount"></form-factory>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="formGroup" [model]="pendCompanyInput" (onChange)="onSelectPendCompany($event)"></form-factory>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isOwnerVisible">
          <form-factory [formGroup]="formGroup" [model]="pendOwnerInput" (onChange)="onSelectPendOwner($event)"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="(pendStatusId === 'New') || (pendStatusId === 'In Progress')">
          <form-factory [formGroup]="formGroup" [model]="pendUserInput" (onChange)="onSelectPendUser($event)"></form-factory>
      </div>


      <div class="ui-g-12 ui-md-12 ui-lg-6">
        <form-factory [formGroup]="formGroup" [model]="pendStatusInput" (onChange)="onSelectPendStatus($event)"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isClinicalPend">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="pendSeverityInput"> </form-factory>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isPendReasonVisible">
        <form-factory [formGroup]="formGroup" [model]="pendReasonInput" (onChange)="onSelectPendReason($event)"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="pendNotes" (keypress)="onSelectPendNotes($event)"></form-factory>
      </div>

    </div>
  </form>
  <footer>
    <app-button classes="primary-action" class="header-button" text={{btnLabel}} (onClick)="confirmUpdatePendView()"></app-button>
  </footer>
</app-modal>


<app-modal [(visible)]="isPendStatusChangeVisible" (onCancel)="closePopup()">
  {{headerLabel}}
  <footer>
    <app-button classes="primary-action" class="header-button" text="Yes" (onClick)="closePopup()" [disabled]="false"></app-button>
  </footer>
</app-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isCancelConfirmationModalVisible"
  header="Close the Chase(s)?"
  (onYes)="updatePendView()"
  (onCancel)="cancelConfirmation($event)">
  Closing the Pend(s) will also close the associated Chase(s). Those chases will no longer continue to be worked. Do you want to proceed?
</app-confirmation-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isResolveConfirmationModalVisible"
  header="Resolve the Pend(s)?"
  (onYes)="updatePendView()"
  (onCancel)="cancelConfirmation($event)">
  Resolving the Pend(s) will let work continue on the Chase(s). This should be done once the obstacle preventing the Chase(s) from advancing has been resolved. Do you want to proceed?
</app-confirmation-modal>


