import { DynamicGroup } from "../../form-groups/dynamic-group.model";
import { DynamicArray, IDynamicArrayOptions } from "../dynamic-array.model";
import { SaveArrayComponent } from "./save-array.component";

export interface ISaveArrayOptions extends IDynamicArrayOptions {
  models?: DynamicGroup[];
}

export class SaveArray extends DynamicArray implements ISaveArrayOptions {
  readonly controlType = "save-array";
  readonly componentType = SaveArrayComponent;


  constructor(options: ISaveArrayOptions = {}) {
    super(options);
  }
}
