import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class LoaderService {
   isLoading = new BehaviorSubject(false);
   private cancelPendingRequests$ = new Subject<void>();

   constructor() {}

   /** Cancels all pending Http requests. */
   cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }

   onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable();
  }
}
