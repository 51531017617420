import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Provider } from "../../../../../../provider/provider.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ENCOUNTER_FOUND, FROM_DATE, MEMBER_VALIDATION_RESULT } from "../attributes";
import { RiskEntity } from "./risk-entity.model";
import { RiskError } from "./risk-error-modal/risk-error.model";
import { RiskHelper } from "./risk-helper.model";

export class RiskState {
  chaseId: number;
  encounters: RiskEntity[];
  selectedEncounterIndex: number;
  selectedDiagnosisIndex: number;
  vrcs: SelectableInput[];
  encounterTypes: SelectableInput[];
  providers: Provider[];
  errors: RiskError[];
  memberValidation: RiskEntity;
  assignedToCurrentUser: boolean;
  auditMode: boolean;
  workflowStatusName: string;
  omissionCodes: SelectableInput[];
  memberVerified: any;

  constructor(options: Partial<RiskState> = {}) {
    this.chaseId = options.chaseId;
    this.vrcs = ArrayHelper.clean(options.vrcs);
    this.encounterTypes = ArrayHelper.clean(options.encounterTypes);
    this.providers = ArrayHelper.clean(options.providers);
    this.memberValidation = options.memberValidation || {} as any;
    this.assignedToCurrentUser = BooleanHelper.tryGet(options.assignedToCurrentUser, false);
    this.auditMode = BooleanHelper.tryGet(options.auditMode, false);
    this.workflowStatusName = StringHelper.clean(options.workflowStatusName);
    this.omissionCodes = ArrayHelper.clean(options.omissionCodes);
    this.memberVerified = options.memberVerified;
    // NOTE: Order matters.
    this.encounters = ArrayHelper.clean(options.encounters);
    this.setSelectedEncounterIndex(options.selectedEncounterIndex);
    this.setSelectedDiagnosisIndex(options.selectedDiagnosisIndex);
    this.errors = ArrayHelper.clean(options.errors);
  }


  get hasEncounters(): boolean {
    return ArrayHelper.isAvailable(this.encounters);
  }

  get encounterCount(): number {
    return this.encounters.length;
  }

  get hasSelectedEncounterIndex(): boolean {
    return this.selectedEncounterIndex != null;
  }

  get selectedEncounter(): RiskEntity {
    return this.hasSelectedEncounterIndex ? this.encounters[this.selectedEncounterIndex] : {} as any;
  }

  get hasSelectedDiagnosisIndex(): boolean {
    return this.selectedDiagnosisIndex != null;
  }

  get selectedDiagnosis(): RiskEntity {
    return this.hasSelectedDiagnosisIndex ? this.selectedEncounter.diagnoses[this.selectedDiagnosisIndex] : {} as any;
  }

  get hasVrcs(): boolean {
    return ArrayHelper.isAvailable(this.vrcs);
  }

  get hasEncounterTypes(): boolean {
    return ArrayHelper.isAvailable(this.encounterTypes);
  }

  get hasOmissionCodes(): boolean {
    return ArrayHelper.isAvailable(this.omissionCodes);
  }

  get hasErrors(): boolean {
    return ArrayHelper.isAvailable(this.errors);
  }

  get hasMemberValidation(): boolean {
    return this.memberValidation != null && ArrayHelper.isAvailable(this.memberValidation.attributes);
  }

  get isMemberValidated(): boolean {
    return this.hasMemberValidation && this.validationResult();
  }

  validationResult(): boolean {
    const attribute = this.memberValidation.attributes.filter(a => a.attributeId === MEMBER_VALIDATION_RESULT.attributeId);
    if (ArrayHelper.isAvailable(attribute)) {
      return Number(attribute[0].value) === 1;
    }
    return false;
  }

  get isEncYes(): boolean {
    if (!this.hasSelectedEncounterIndex) {
      return false;
    }

    const isEncYes = Number((this.selectedEncounter.attributes.find(attribute => attribute.attributeId === ENCOUNTER_FOUND.attributeId) || {} as any).value) === 1;
    return isEncYes;
  }

  get isSelectedEncounterValid(): boolean {
    return this.isEncYes;
  }

  get validEncounters(): RiskEntity[] {
    const validEncounters = this.encounters.filter(encounter => {
      return Number((encounter.attributes.find(attribute => attribute.attributeId === ENCOUNTER_FOUND.attributeId && !attribute.isNlp) || {} as any).value) === 1;
    });
    return validEncounters;
  }

  get reviewedEncounters(): RiskEntity[] {
    return this.encounters.filter(encounter =>
      encounter.attributes.some(attribute => attribute.attributeId === ENCOUNTER_FOUND.attributeId)
    );
  }

  get adminEncounters(): RiskEntity[] {
    return this.encounters.filter(encounter =>
      RiskHelper.getAttribute("ClaimID", encounter).isAdmin);
  }

  get adminEncountersCount(): number {
    return this.adminEncounters.length;
  }

  get reviewedAdminEncounters(): RiskEntity[] {
    return this.adminEncounters.filter(encounter =>
      encounter.attributes.some(attribute => attribute.attributeId === ENCOUNTER_FOUND.attributeId)
    );
  }

  get reviewedAdminEncountersCount(): number {
    return this.reviewedAdminEncounters.length;
  }

  get validEncounterCount(): number {
    return this.validEncounters.length;
  }

  get reviewedEncounterCount(): number {
    return this.reviewedEncounters.length;
  }

  get isEnabled(): boolean {
    // If Chart page displayed from Audit Module, make the chart page READ-ONLY
    // TODO: Fix logic to remove the ternary.
    return this.auditMode ? false : (this.isMemberValidated && this.assignedToCurrentUser);
  }

  bySourceAliasId(sourceAliasId: string): any {
    return (provider: Provider): boolean => {
      return provider.providerSourceAliasId === sourceAliasId;
    };
  }

  setSelectedEncounterIndex(value: number): void {
    if (!this.hasEncounters || value == null) {
      this.selectedEncounterIndex = null;
      return;
    }

    const cleanValue = Number(value);

    const min = 0;
    this.selectedEncounterIndex = NumberHelper.isGreaterThan(cleanValue, min, true) ? cleanValue : min;

    const max = this.encounters.length - 1;
    if (NumberHelper.isGreaterThan(cleanValue, max)) {
      this.selectedEncounterIndex = max;
    }
  }

  setSelectedDiagnosisIndex(value: number): void {
    if (!this.hasEncounters || !this.hasSelectedEncounterIndex || value == null || !ArrayHelper.isAvailable(this.selectedEncounter.diagnoses)) {
      this.selectedDiagnosisIndex = null;
      return;
    }

    const cleanValue = Number(value);

    const min = 0;
    this.selectedDiagnosisIndex = NumberHelper.isGreaterThan(cleanValue, min, true) ? cleanValue : min;

    const max = this.selectedEncounter.diagnoses.length - 1;
    if (NumberHelper.isGreaterThan(cleanValue, max)) {
      this.selectedDiagnosisIndex = max;
    }
  }

  private sortEncounters(encounters) {
    if (ArrayHelper.isAvailable(encounters)) {
      return encounters.sort((a, b) => (this.getStartDateValue(a) < this.getStartDateValue(b)) ? 1 : -1);
    }
  }

  private getStartDateValue(encounters) {
    const startDate = encounters.attributes.find(x => x.attributeId === FROM_DATE.attributeId).value;
    return DateHelper.create(startDate, null);
  }
}
