import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class RouteFilters {
    constructor(
        readonly projectIds: string,
        readonly chaseStatuses: string,
        readonly measures: string,
        readonly chaseCompliances: string,
        readonly sampleCompliances: string,
        readonly lastCodedBy: string,
        readonly pendStatuses: string,
        readonly pursuits: string
    ) {
        this.projectIds = StringHelper.clean(projectIds);
        this.chaseStatuses = StringHelper.clean(chaseStatuses);
        this.measures = StringHelper.clean(measures);
        this.chaseCompliances = StringHelper.clean(chaseCompliances);
        this.sampleCompliances = StringHelper.clean(sampleCompliances);
        this.lastCodedBy = StringHelper.clean(lastCodedBy);
        this.pendStatuses = StringHelper.clean(pendStatuses);
        this.pursuits = StringHelper.clean(pursuits);
    }
}
