<div class="navigation">
  
  <div class="logo logo__bird">
    <div class="logo__bird--icon" (click)="navigateToLoginLanding()">
      <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-icon--transparent"></app-icon>
    </div>
  </div>
   
  <ul class="navigation__menu" *ngFor="let item of items; trackBy: trackByIndex; last as isLast" [ngClass]="{ 'last' : isLast }">
    <li [ngClass]="getActiveMenuClass(item)" class="navigation__menuItem" (click)="showSubMenu(item)">
      <img class="navigation__menuItem--icon-image" src="assets/icons/{{item.iconName}}.svg" />
      <div class="navigation__menuItem--title">{{item.description}}</div>
    </li>
  </ul>
</div>

<div class="navigation__subMenu" [ngClass]="{ 'showSubMenu': isOpen, 'hideSubMenu': !isOpen }"><app-menu [items]="menuItem"></app-menu></div>