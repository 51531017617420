export class DocumentHoverHighlightsRequest {
    chaseId: number;
    documentPageId: number;
    pageNumber: number;
    startX: number;
    widthX: number;
    startY: number;
    heightY: number;

    constructor(options: {
        chaseId: number;
        documentPageId: number;
        pageNumber: number;
        startX: number;
        widthX: number;
        startY: number;
        heightY: number;
    }) {
        this.chaseId = options.chaseId;
        this.documentPageId = options.documentPageId;
        this.pageNumber = options.pageNumber;
        this.startX = options.startX;
        this.widthX = options.widthX;
        this.startY = options.startY;
        this.heightY = options.heightY;
    }
}
