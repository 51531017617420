<div class="tag-container ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isGrid">
    <div [ngClass]="'ui-chips ui-widget'" [ngStyle]="style" [class]="styleClass">
      <div class="left_panel">
        <ul class="ui-inputtext ui-state-default ui-corner-all" [ngClass]="{'ui-state-focus':focus,'ui-state-disabled':disabled}">
          <li>
            <ul class="ui-inputtext ui-state-default ui-corner-all" [ngClass]="{'ui-state-focus':focus,'ui-state-disabled':disabled}"
                *ngIf="isTagsAvailable">
              <ng-container *ngFor="let item of value; let i = index; trackBy: trackByIndex">
                
                <ng-container *ngIf="isChaseV2">
                  <ng-container *ngIf="i < 4">
                        <li #token 
                        class="ui-chips-token ui-state-highlight ui-corner-all" (click)="onItemClick()">
                      
                          <span title='{{getCharactersCount(item.text)}}' [class]="chipClass"
                            [ngStyle]="{'background-color':item.extra.color}">{{field ? chipItemsData(item,field) : item.text}}</span>
                            <app-icon *ngIf="item.extra.isSelected && hasTagManagement" [iconName]="getTagIcon('times')" [iconStyle]="getTagIcon('times')" (click)="removeItem($event,i,item.value)"></app-icon> 
                      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
                    </li>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!isChaseV2">
                  <li #token 
                  class="ui-chips-token ui-state-highlight ui-corner-all" (click)="onItemClick()">
                
                    <span title='{{getCharactersCount(item.text)}}' [class]="chipClass"
                      [ngStyle]="{'background-color':item.extra.color}">{{field ? chipItemsData(item,field) : item.text}}</span>
                      <app-icon *ngIf="item.extra.isSelected && hasTagManagement" [iconName]="getTagIcon('times')" [iconStyle]="getTagIcon('times')" (click)="removeItem($event,i,item.value)"></app-icon>
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
              </li>
                </ng-container>
              </ng-container>
              <div class="extraTags" *ngIf="showExtraTags && isChaseV2" >
                <ng-container *ngFor="let item of value; let i = index; trackBy: trackByIndex">
                <ng-container *ngIf="i >= 4">
              <li #token 
                  class="ui-chips-token ui-state-highlight ui-corner-all inExtraTag" (click)="onItemClick()">
                <span title='{{getCharactersCount(item.text)}}' [class]="chipClass"
                    [ngStyle]="{'background-color':item.extra.color}">{{field ? chipItemsData(item,field) : item.text}}</span>
              <app-icon *ngIf="item.extra.isSelected && hasTagManagement" [iconName]="getTagIcon('times')" [iconStyle]="getTagIcon('times')" (click)="removeItem($event,i,item.value)"></app-icon>
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
              </li>
              </ng-container>
            </ng-container>
            <div class="arrow-down"></div>
            </div>
            <div class="toggleButton" *ngIf="isChaseV2 && showTagsEllipsesIcon"  (click)="showOtherTags()" >
              <app-icon iconName="ellipsis-h" [ngClass]="{'active':activeToggle}" ></app-icon>
            </div>
            </ul>
          </li>
          <li class="tags-list" [ngClass]="getClassForSelect()">
            <div [ngClass]="getClassForPlusIcon()" (click)="addTags('true')" *ngIf="(isTagsAvailable || isVisible) && hasTagManagement">
              <app-icon [iconName]="getTagIcon('add')" [iconStyle]="getTagIcon('add')"></app-icon>
            </div>
            <div [ngClass]="getClassForNewTagIcon()" (click)="addTags('true')" *ngIf="!isTagsAvailable && !isVisible && hasTagManagement">
              <app-icon [iconName]="getTagIcon('add')" [iconStyle]="getTagIcon('add')"></app-icon>
              <span class="tag-list__add-label">Add Tag</span>
            </div>
            <div class="right_panel">
              <ul *ngIf ="isVisible" class="add-tag">
                <li class="ui-chips-input-token">
                 <app-create-tag (visible)="visibleChange($event)" [tagType]="tagType" [objectIds]="objectId" [value]="value" [isGrid]="isGrid" (onAdd)="addedTag($event);"></app-create-tag>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isGrid" [ngClass]="addColumnBorder" (click)="onItemClick(item)">
      <ul class="ui-inputtext ui-state-default ui-corner-all" [ngClass]="{'ui-state-focus':focus,'ui-state-disabled':disabled}">
          <li #token *ngFor="let item of value; let i = index; trackBy: trackByIndex" class="ui-chips-grid ui-state-highlight ui-corner-all">
            <app-icon *ngIf="isSelected" [iconName]="getTagIcon('times')" [iconStyle]="getTagIcon('times')" (click)="removeItem($event,i,item.value)"></app-icon>
            <span class="ui-chips-grid-label" [ngStyle]="{'background-color':item.extra.color}">{{field ? chipItemsData(item,field) : item.text}}</span>
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
          </li>
          <li class="ui-chips-input-grid">
              <app-create-tag [tagType]="tagType" [objectIds]="objectId" [value] ="value" [rowIndex]="rowIndex" (getCurrentIndex)="getCurrentIndex($event);" (onAdd)="addedTag($event);" [isGrid]="isGrid"></app-create-tag>
          </li>
        </ul>
  </div>
