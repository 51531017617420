import { Component, HostBinding, Input } from "@angular/core";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BulkAction } from "./bulk-action.model";

@Component({
  selector: "app-bulk-actions",
  templateUrl: "./bulk-actions.component.html",
  styleUrls: ["./bulk-actions.component.scss"],
})
export class BulkActionsComponent {
  @Input() actions: BulkAction[];
  @Input() selectedItems: any[];


  get hasActions(): boolean {
    return ArrayHelper.isAvailable(this.filteredActions);
  }

  get filteredActions(): BulkAction[] {
    return this.actions.filter(a => a.showBulkAction && !a.hasIcon);
  }

  get filteredIconActions(): BulkAction[] {
    return this.actions.filter(a => a.showBulkAction && a.hasIcon);
  }

  get hasSelectedItems(): boolean {
    return ArrayHelper.isAvailable(this.selectedItems);
  }

  get showBulkActions(): boolean {
    return this.hasActions && this.hasSelectedItems;
  }

  @HostBinding("style.display")
  get display(): string {
    return this.showBulkActions ? null : "none";
  }

  onClick(action: BulkAction): void {
    action.action(this.selectedItems);
  }


  trackByIndex(index, item) {
    return index;
  }
}
