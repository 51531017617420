import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { interceptorskipheader } from "../../../core/interceptor/loader.interceptor";
import { LookupModel } from "../../../shared/models/lookup-model";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { DocumentTagUploadRequest } from "../../modules/project/approval-center/upload-bulk-tag.model";
import { DocumentUploadRequest } from "./document-upload-request.model";
import { DocumentUploadResponse } from "./document-upload-response.model";
import { DocumentValidateResponse } from "./document-validate-response.model";

@Injectable({
    providedIn: "root",
})
export class DocumentUploadService {

    constructor(
        @Inject(BASE_API_URL) private readonly baseApiUrl: string,
        private http: HttpClient,
        private automapper: AutomapperService
    ) { }


    upload(documentUploadRequest: DocumentUploadRequest): Observable<HttpResponse<DocumentUploadResponse>> {

        const formData = documentUploadRequest.formData;

        formData.append("fileName", documentUploadRequest.fileName);
        formData.append("documentTypeId", documentUploadRequest.documentTypeId.toString());
        formData.append("objectType", documentUploadRequest.objectType.toString());
        formData.append("objectId", documentUploadRequest.objectId.toString());
        formData.append("source", documentUploadRequest.source);
        formData.append("retrievalTypeId", documentUploadRequest.retreivalTypeId.toString());
        if (NumberHelper.isAvailable(documentUploadRequest.projectId)) {
            formData.append("projectId", documentUploadRequest.projectId.toString());
        }

        const url = `${this.baseApiUrl}document/upload`;

        return this.http.post(url, formData, { observe: "response" }) as Observable<HttpResponse<DocumentUploadResponse>>;

    }

    uploadPresigned(documentUploadRequest: DocumentUploadRequest): Observable<HttpResponse<DocumentUploadResponse>> {

        const formData = documentUploadRequest.formData;

        formData.append("fileName", documentUploadRequest.fileName);
        formData.append("documentTypeId", documentUploadRequest.documentTypeId.toString());
        formData.append("objectType", documentUploadRequest.objectType.toString());
        formData.append("objectId", documentUploadRequest.objectId.toString());
        formData.append("source", documentUploadRequest.source);
        formData.append("retrievalTypeId", documentUploadRequest.retreivalTypeId.toString());
        if (NumberHelper.isAvailable(documentUploadRequest.projectId)) {
            formData.append("projectId", documentUploadRequest.projectId.toString());
        }

        const url = `${this.baseApiUrl}document/uploadPresigned`;

        return this.http.post(url, formData, { observe: "response" }) as Observable<HttpResponse<DocumentUploadResponse>>;

    }

    uploadGateway(formData: FormData): Observable<any> {
        const url = `${this.baseApiUrl}provider/charts/upload`;
        const headers = new HttpHeaders().set(interceptorskipheader, "");
        return this.http.post(url, formData, { observe: "response", headers });
      }

    bulkvalidate(formData: FormData): Observable<DocumentValidateResponse[]> {
        const url = `${this.baseApiUrl}document/upload/bulk/validate`;
        return this.http.post(url, formData).pipe(map(this.automapper.curryMany("default", "DocumentValidateResponse")));

    }

    deleteActiveDocument(chaseId: number): Observable<boolean> {

        const url = `${this.baseApiUrl}chasedocument/deactivate?chaseId=${chaseId}`;

        return this.http.post(url, null, { observe: "response" })
            .pipe(
                map((response: HttpResponse<any>) => response.ok)
            );

    }

    reOpenChase(chaseId: number): Observable<boolean> {

        const url = `${this.baseApiUrl}chase/reopen?chaseId=${chaseId}`;

        return this.http.post(url, null, { observe: "response" })
            .pipe(
                map((response: HttpResponse<any>) => response.ok)
            );

    }
    getProjectList(clientId: number): Observable<LookupModel[]> {
        const url = `${this.baseApiUrl}project/lookup?clientId=${clientId}`;
        return this.http.get(url).pipe(map(this.automapper.curryMany("default", "LookupModel")));
    }
    getMaxFileSizeForBulkUpload(): Observable<number> {
        const url = `${this.baseApiUrl}document/maxfilesizeforbulkupload`;
        return this.http.get(url) as Observable<number>;
    }
    documentTagBulkUpload(documentTagUploadRequest: DocumentTagUploadRequest): Observable<null> {
        const url = `${this.baseApiUrl}document/tags/add`;
        return this.http.post(url, documentTagUploadRequest) as Observable<null>;
      }
}
