import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";

@Component({
  selector: "member-chase-detail-info-current-pends",
  templateUrl: "./chase-detail-info-current-pends.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailInfoCurrentPendsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  chaseDetailState: ChaseDetailState;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sink.add(this.chaseDetailStateService.state.subscribe(state => {
      this.chaseDetailState = state;
      this.changeDetector.markForCheck();
    }));
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  get pendInfo(): string {
    return `${this.chaseDetailState.owner} : ${this.chaseDetailState.pendCode} - ${this.chaseDetailState.pendName} (${this.chaseDetailState.pendStatusName.toUpperCase()})`;
  }

  get pendURL(): string {
    return `/pend/detail/${this.chaseDetailState.chasePendId}`;
  }
}
