import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { CommentItem } from "./comment-item/comment-item";

@Injectable({
  providedIn: "root",
})
export class EntityCommentsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getEntityComments(entityId: number, entityType: string): Observable<CommentItem[]> {
    let url = "";

    switch (entityType) {
      case "chase":
        url = `${this.baseApiUrl}Chase/Comments?chaseId=${entityId}`;
        break;

      case "address":
        url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${entityId}`;
        break;

      default:
        break;
    }

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

}
