import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { Annotation } from "../../../../platform/widget/annotations/annotation.model";
import { AnnotationService } from "../../../../platform/widget/annotations/annotation.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { HighlightAnnotationService } from "../../../highlight-annotation/highlight-annotation.service";
import { MenuService } from "../../../menu/menu.service";
import { AnnotationSource } from "../document-page-annotations/annotation-source-enum";
import { Annotations } from "../document-page-annotations/annotations.model";
import { ChaseDocumentPages } from "../document-page-annotations/chase-document-pages.model";
import { DocumentHoverHighlightsRequest } from "./document-hover-highlights-request.model";
import { DocumentHoverHighlightsService } from "./document-hover-highlights.service";

@Component({
  selector: "app-document-hover-highlights",
  templateUrl: "./document-hover-highlights.component.html",
  styleUrls: ["./document-hover-highlights.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentHoverHighlightsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  constructor(private localService: LocalService,
              private menuService: MenuService,
              private userService: UserService,
              private changeDetector: ChangeDetectorRef,
              private highlightAnnotationService: HighlightAnnotationService,
              private documentHoverHighlightsService: DocumentHoverHighlightsService,
              private messagingService: MessagingService,
              private annotationService: AnnotationService
    ) {}

  @Input() hoverAnnotations: any[] = [];
  @Input() canvasHeight: number;
  @Input() canvasWidth: number;
  @Input() chaseId: number;
  @Input() documentPageId: number;
  @Input() status: string;
  @Input() isReadOnly = false;
  @Input() zoomPercentage = 100;
  @Input() margin = 0;
  @Input() pageNumber: number;
  @Input() zoomStyle;
  @Input() imageWidth;
  @Input() isDiagnosisSelected = false;
  @Input() chasePageAnnotations: Annotations[];
  @Input() chaseDocumentPages: ChaseDocumentPages[] = [];
  @Output() highlightToDelete = new EventEmitter();
  private localStorageIsWindowOpen = "isWindowOpen";
  private sink = new SubSink();
  private user: UserToken;
  activeHighlightsStatus = [];
  detach: boolean = this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  firstNewAnnotation = true;
  isEditing = false;
  nlpAnnotations: any[] = [];
  nonNlpAnnotations: any[] = [];
  preventHighlight = false;

  get adjustedZoomPercentage(): number {
    return this.zoomPercentage / 100;
  }

  get annotationsData() {
    return this.nonNlpAnnotations;
  }

  ngOnInit(): void {
    if (ArrayHelper.isAvailable(this.hoverAnnotations)) {
      this.displayPreviousAnnotations();
    }
    this.user = this.userService.getUserToken();
    if (ArrayHelper.isAvailable(this.nonNlpAnnotations)) {
      this.nonNlpAnnotations.map(data => this.activeHighlightsStatus.push(false));
    }
    this.sink.add(
      this.menuService.updateHighlightId$.subscribe(highlightId => {
        if (StringHelper.isAvailable(highlightId) && ArrayHelper.isAvailable(this.nonNlpAnnotations)) {
        setTimeout(() => {
            this.nonNlpAnnotations.map((annotation, idx) => {
              this.activeHighlightsStatus[idx] = annotation.data?.highlightId === highlightId ? !this.activeHighlightsStatus[idx] : false;
            });
            this.changeDetector.markForCheck();
          },       100);
        }
      }),
      this.highlightAnnotationService.isEditing$.subscribe(status => {
        this.isEditing = status;
      })
    );
  }

  ngAfterViewInit() {
    this.displayPreviousAnnotations();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  filterData(filteredAnnots: Annotations[]): Annotations[] {
    const check = {};
    const res: Annotations[] = [];
    filteredAnnots.map((data, i) => {
        if (!check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`]) {
            check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`] = true;
            res.push(filteredAnnots[i]);
        }
    });
    return res;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canvasWidth) {
      if (changes.canvasWidth.isFirstChange()) { return; }

      if (changes.canvasWidth.currentValue !== changes.canvasWidth.previousValue) {
        this.displayPreviousAnnotations();
      }
    }

    if (changes.hoverAnnotations) {
      if (changes.hoverAnnotations.isFirstChange()) { return; }

      if (changes.hoverAnnotations.currentValue !== changes.hoverAnnotations.previousValue) {
        this.nonNlpAnnotations = this.hoverAnnotations.filter(annotation => annotation.annotationSourceId !== AnnotationSource.Nlp);
        if (this.isDiagnosisSelected) {
          const filteredAnnots: Annotations[] = [];
          this.chasePageAnnotations.map(data => {
            if (data.annotationSourceId !== AnnotationSource.Nlp) {
            filteredAnnots.push(data);
            }
          });

          const userAnnotations = this.filterData(filteredAnnots);
          this.nonNlpAnnotations.push(...userAnnotations);

        } else {
          this.nonNlpAnnotations = this.filterData(this.nonNlpAnnotations);
        }
        this.displayPreviousAnnotations();
      }
    }

    if (changes.zoomPercentage) {
      if (changes.zoomPercentage.isFirstChange()) { return; }

      if (changes.zoomPercentage.currentValue !== changes.zoomPercentage.previousValue) {
        this.displayPreviousAnnotations();
      }
    }

    if (changes.margin) {
      if (changes.margin.isFirstChange()) { return; }
      if (changes.margin.currentValue !== changes.margin.previousValue) {
        this.displayPreviousAnnotations();
      }
    }
    this.changeDetector.markForCheck();
  }

  private displayPreviousAnnotations(): void {
    this.margin = NumberHelper.isAvailable(this.margin) ? this.margin : 0;
    const adjustedCanvas = this.detach ? this.canvasWidth * 0.7 : this.canvasWidth;
    const zoomStyleHeight = Number(this.zoomStyle.height.slice(0, -2));
    let xInit = 0;
    let yInit = 0;
    let xWidth = 0;
    let yHeight = 0;
    const newAnnotData: any[] = [];
    const newNLPAnnotData: any[] = [];
    this.hoverAnnotations.forEach((item, idx) => {
      const newYPercent = item.startY / 1558;
      if (this.detach) {
        xInit = this.margin > 0 ? item.startX * this.imageWidth + (this.margin >= 0 ? this.margin : 0) :
          item.startX * this.imageWidth * (this.canvasWidth / this.imageWidth);
        yInit = (newYPercent * zoomStyleHeight);
        xWidth = this.margin > 0 ? item.widthX * this.imageWidth :
          (item.widthX * this.imageWidth) * (this.canvasWidth / this.imageWidth);
        yHeight = ((item.heightY / 1558) * zoomStyleHeight);
      } else {
        xInit = (item.startX * this.canvasWidth * (1)) * this.adjustedZoomPercentage;
        yInit = (item.startY + 1) * (this.adjustedZoomPercentage !== 1 ? this.adjustedZoomPercentage + 0.41 : this.adjustedZoomPercentage);
        xWidth = ((item.widthX * (1)) * (adjustedCanvas * (1))) * this.adjustedZoomPercentage;
        yHeight = item.heightY * (this.adjustedZoomPercentage !== 1 ? this.adjustedZoomPercentage + 0.41 : this.adjustedZoomPercentage);
      }
      if (item.widthX < 0) {
        xInit += xWidth;
        xWidth *= -1;
      }
      if (item.heightY < 0) {
        yInit += yHeight;
        yHeight *= -1;
      }
      if (item.annotationSourceId !== AnnotationSource.Nlp) {
        newAnnotData.push({xInit, yInit, xWidth, yHeight, annotationSourceId: item.annotationSourceId, id: idx, pageId: this.documentPageId, data: item});
      } else {
        const colorCodeRGBA = StringHelper.isAvailable(item.colorCode) ? this.highlightAnnotationService.hexToRGB(item.colorCode) : "rgba(65, 194, 118, 0.4)";
        const colorCodeRGB = `${colorCodeRGBA.slice(0, -6)})`;
        newNLPAnnotData.push({xInit, yInit, xWidth, yHeight, colorCodeRGBA, colorCodeRGB,
                              colorCode: item.colorCode, annotationSourceId: item.annotationSourceId, id: idx,
                              pageId: this.documentPageId, data: item});
      }
    });
    this.nonNlpAnnotations = newAnnotData;
    this.nlpAnnotations = newNLPAnnotData;
    this.changeDetector.markForCheck();
  }

  addAnnotation(annotation: Annotation): void {
    this.preventHighlight = true;
    if (this.firstNewAnnotation) {
        setTimeout(() => {
          this.menuService.openNewAnnotation({ createNewAnnotation: true, highlightId: annotation.highlightId, page: this.pageNumber });
        },         1000);
        this.firstNewAnnotation = false;
      } else {
      this.menuService.openNewAnnotation({ createNewAnnotation: true, highlightId: annotation.highlightId, page: this.pageNumber });
      }
    this.menuService.toggleWidgetState(true);
    this.changeDetector.markForCheck();
  }

  deleteAnnotation(annotationToDelete): void {
    this.preventHighlight = true;
    this.highlightToDelete.emit(annotationToDelete);
    this.changeDetector.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }

  highlightAnnotation(highlightId: string): void {
    if (this.preventHighlight) {
      this.preventHighlight = false;
      return;
    }

    let idToPass = "";
    this.nonNlpAnnotations.map((annotation, idx) => {
      if (annotation.data.highlightId === highlightId) {
        this.activeHighlightsStatus[idx] = !this.activeHighlightsStatus[idx];
        idToPass = this.activeHighlightsStatus[idx] ? highlightId : "-";
      } else {
        this.activeHighlightsStatus[idx] = false;
      }
    });
    this.menuService.openNewAnnotation({ createNewAnnotation: false, highlightId: idToPass, page: this.pageNumber });
    this.menuService.toggleWidgetState(true);
    this.annotationService?.setAnnotationsMatches([]);
    this.changeDetector.markForCheck();
  }

  showAnnotationIcon(highlightId: string): boolean {
    return StringHelper.isAvailable(highlightId);
  }

  copyText(annotation): void {
    this.preventHighlight = true;
    const request = this.getCopyTextRequestBody(annotation);
    this.sink.add(
      this.documentHoverHighlightsService.getHighlightText(request).subscribe(
        text => this.copyTextToClipboard(text)
      )
    );
  }

  showCopyButton(annotation): boolean {
    return annotation?.annotationSourceId === AnnotationSource.User && this.user?.enableAnnotationCopyFeature === true;
  }

  private copyTextToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(
      () => this.messagingService.showToast("Copied to Clipboard!", SeverityType.SUCCESS),
      () => this.messagingService.showToast("Failed to copy text, please try again", SeverityType.ERROR)
    );
  }

  private getCopyTextRequestBody(annotation): DocumentHoverHighlightsRequest {
    const chaseId = this.chaseId;
    const documentPageId = this.documentPageId;
    const pageNumber = this.pageNumber;
    const startX = annotation.data.startX;
    const widthX = annotation.data.widthX;
    const startY = annotation.data.startY;
    const heightY = annotation.data.heightY;
    const options = { chaseId, documentPageId, pageNumber, startX, widthX, startY, heightY };
    return new DocumentHoverHighlightsRequest(options);
  }

}
