export enum ContactMethodType {
RecordCollectionCall = 1,
Fax = 2,
FaxBlast = 3,
Email = 4,
IncomingCall = 5,
Other = 6,
Note = 7,
Mail = 8,
WeeklyEmailStatement = 9,
InvoiceCall = 10,
PendCall = 11,
ResearchCall = 12,
AdditionalCollectionCall = 13,
WeeklyFaxStatement = 14,
VendorCall = 15,
VendorNote = 16,
MDPortalRequest = 17,
}
