
<form-factory [formGroup]="form" [model]="firstNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="lastNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="dobSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="genderSaveGroup" (onChange)="handleChange($event)"></form-factory>

<h3>HCPCS Information</h3>
<div class="rxcm">
    <form-factory class="rxcm__memberID" [formGroup]="form" [model]="memberStandardGroup"></form-factory>
    <form-factory class="rxcm__claimId"[formGroup]="form" [model]="claimIdSaveGroup" (onChange)="handleChange($event)"></form-factory>
</div>
<form-factory [formGroup]="form" [model]="fromDateSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="thruDateSaveGroup" (onChange)="handleChange($event)"></form-factory>