import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { RetrieverUpdateModel } from "./update-retriever-model.model";
import { UpdateRetrieverModelService } from "./update-retriever-model.service";

@Component({
  selector: "app-update-retriever-model",
  templateUrl: "./update-retriever-model.component.html",
  styleUrls: ["./update-retriever-model.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateRetrieverModelComponent implements OnInit {
  manageRetrieverForm: FormGroup;
  retrieverInput: Dropdown;
  @Input() totalEntityCount: number;
  @Input() updateRetrieverData: RetrieverUpdateModel;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);
  private pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
    this.getRetrieverOptions();

    if (!this.visible) {
      this.closing();
    }
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly messagingService: MessagingService,
    private retrieverService: UpdateRetrieverModelService
  ) { }

  ngOnInit(): void {
    this.initializeRetrieverForm();
  }

  private initializeRetrieverForm(): void {
    this.retrieverInput = new Dropdown({
      key: "retrieverInput",
      label: "Select Retriever",
      placeholder: "Select From List",
      validators: [Validators.required],
      errorMessages: {
        required: "Retriever is required.",
      },
      appendTo: "body",
    });
    this.manageRetrieverForm = this.formService.createFormGroup([this.retrieverInput]);
  }

  private getRetrieverOptions(): void {
    let result = [];
    if (this.visible) {
      result = [
        new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
        new SelectableInput({ text: "Client", value: "Client" }),
      ];
      this.retrieverInput = new Dropdown({ ...this.retrieverInput, options: result } as any);
      this.changeDetector.markForCheck();
    }
  }

  cancel(): void {
    this.manageRetrieverForm.reset();
  }

  private closing(): void {
    this.onHide.emit();
  }

  get isValid(): boolean {
    if (this.manageRetrieverForm == null) {
      return false;
    } else {
      return this.manageRetrieverForm.valid;
    }
  }

  onRetrieverConfirm(): void {
      const retrieverName = this.manageRetrieverForm.get("retrieverInput").value;
      this.updateRetrieverData.retrieverName =  retrieverName;

      if (ArrayHelper.isAvailable(this.updateRetrieverData.chaseIds)) {
      this.retrieverService.updateRetrieverModel(this.updateRetrieverData).subscribe(
        data => {
          this.messagingService.showToast(`Retriever updated successfully.`, SeverityType.SUCCESS);
          this.onUpdate.emit(null);
        },
        err => {
          this.messagingService.showToast(`Error while updating Retriever`, SeverityType.ERROR);
        }
      );
    } else {
        this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
        return;
    }
      this.visible = false;
      this.manageRetrieverForm.reset();
      this.changeDetector.markForCheck();

  }
}
