<app-create-pend-modal
  [visible]="visible && hasNormalChases"
  (visibleChange)="visible = $event"
  (onUpdate)="onUpdate.emit()"
  [chaseIds]="chaseIds"
  [clinical]="clinical"
  [hasClinicalNonClinicalChases]="hasClinicalNonClinicalChases"
  [pageType]="pageType"
  [selectedChasesStatus]="selectedChasesStatus">
</app-create-pend-modal>

<app-edit-pend-modal
  [visible]="visible && hasPendChases && isCurrentSelectedPendCodes"
  (visibleChange)="visible = $event"
  (onUpdate)="onUpdate.emit()"
  [chasePendIds]="chasePendIds"
  [chaseIds]="chaseIds"
  [chases]="chases"
  [clinical]="clinical"
  [hasClinicalNonClinicalChases]="hasClinicalNonClinicalChases">
</app-edit-pend-modal>
