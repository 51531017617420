import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SearchService {
  searching = new BehaviorSubject(false);

  private tagIdsState = new BehaviorSubject<string>(null);
  selectedTagIds = this.tagIdsState.asObservable();

  private tagSearchOperatorState = new BehaviorSubject<string>(null);
  selectedTagSearchOperator = this.tagSearchOperatorState.asObservable();

  setSearchTagIds(tagIds: string): void {
    this.tagIdsState.next(tagIds);
  }

  setTagSearchOperator(searchOperator: string): void {
    this.tagSearchOperatorState.next(searchOperator);
  }

  clearData(): void {
    this.tagIdsState.next(null);
    this.tagSearchOperatorState.next(null);
  }
}
