import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseIdComponent } from "../../../../../shared/chase-grid/chase-id.component";
import { BulkAction } from "../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../../shared/grid/server-grid/server-grid.component";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { ChaseAssignService } from "../../../../api/unassign-chase/chase-assign.service";
import { ChaseDetailState } from "../chase-detail-state.model";
import { ChaseDetailStateService } from "../chase-detail-state.service";

@Component({
  selector: "member-chases-list",
  templateUrl: "./chase-detail-member-chases.component.html",
  styleUrls: ["./chase-detail-member-chases.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailMemberChasesComponent implements OnInit {
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @ViewChild("gapColumn", { static: true }) gapColumn: TemplateRef<any>;

  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  @Input() url = `${this.baseApiUrl}chase/query`;
  @Input() additionalBulkActions: BulkAction[] = [];
  private sink = new SubSink();
  chaseDetailState: ChaseDetailState;
  memberId: number;

  memberChasesGridConfiguration = new GridConfiguration();
  memberChasesGridRequest: GridRequest = new GridRequest();
  memberChasesGridSelection: any;
  actions: BulkAction[];
  refreshGrid = new EventEmitter<boolean>(true);

  // Note: selectedChases is used for actions / bulk actions.
  selectedChases: any[];
  isAssignToChaseModalVisible = false;
  @Input() isUnassignEnabled = false;
  private user: UserToken;
  tagType = TagType.CHASE;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  allAvailableTags: SelectableInput[];
  selectedObjectId: number;
  selectedChaseIdsForAssignment: number[];

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly messagingService: MessagingService,
    private readonly changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private readonly chaseAssignService: ChaseAssignService,
    private chaseDetailStateService: ChaseDetailStateService,
    private authService: AuthService,
    private readonly tagService: TagService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    // this.getAllTagsList(); TODO: This will be a part of next release
    this.sink.add(this.chaseDetailStateService.state.subscribe(state => {
      this.chaseDetailState = state;
      if (this.chaseDetailState.hasMember && this.chaseDetailState.member.hasMemberId) {
        this.memberId = this.chaseDetailState.member.memberId;
        this.createMemberChasesGrid();
        this.loadGrid();
        this.changeDetector.markForCheck();
      }
    }));
  }

  get isUserMangerAdminLead(): boolean {
    return (this.user.isManagerRole || this.user.isAdminRole || this.user.isLeadRole);
  }

  get hasMemberId(): boolean {
    return NumberHelper.isGreaterThan(this.memberId, 0);
  }

  createMemberChasesGrid(): void {
    this.memberChasesGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", template: this.chaseIdColumn, header: "Chase ID"}),
        new GridColumnDefinition({ field: "projectName", header: "Project" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ template: this.gapColumn, header: "Total Gaps", show: this.chaseDetailState.hasGaps }),
        new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
        new GridColumnDefinition({ field: "groupName", header: "Group Name" }),
        new GridColumnDefinition({ field: "product", header: "Product" }),
        new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance" }),
        new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
        new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
        // new GridColumnDefinition({ header: "Tags", template: this.tagColumn, width: "900px"}), TODO: This will be a part of next release
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
        new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
        ],
      selectionMode: "multiple",
      pageSize: 25,

      bulkActions: [
        new BulkAction({
          name: "Assign Chase(s)",
          action: this.openAssignToChaseModal,
          showBulkAction: true,
          disabled: !this.isUserMangerAdminLead,
        }),
        new BulkAction({
          name: "Unassign Chase(s)",
          action: this.unassign.bind(this),
          showBulkAction: true,
        }),
        // new BulkAction({
        //  name: "Manage Tags",
        //  action: this.openManageTagModal,  /*TODO: This will be a part of next release*/
        //  disabled: this.hasTagManagement,
        // }),
      ],
    });

    this.actions = this.memberChasesGridConfiguration.bulkActions;
  }

  private loadGrid(): void {
    this.memberChasesGridRequest = new GridRequest({
      url: this.url,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "MemberId",
          value: this.memberId.toString(),
          show: false,
        }),
      ],
    });
    this.refreshGrid.emit();
  }

  private setSelectedChases(rowData: any): void {
    this.selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    this.selectedChaseIdsForAssignment = this.chaseAssignService.getSelectedChaseIds(this.selectedChases);
  }

  openAssignToChaseModal = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (rowData != null || rowData === undefined) {
      this.isAssignToChaseModalVisible = true;
    } else {
      this.selectedChases = [];
      this.messagingService.showToast("Please select chases from the same project.", SeverityType.ERROR);
    }
  }

  closeModal(): void {
    this.selectedChases = [];
    this.isAssignToChaseModalVisible = false;
    this.isManageTagModalVisible = false;
  }

  gridDataLoaded(data: any) {
    this.changeDetector.markForCheck();
    this.memberChasesGridSelection = [];
  }

  getTotalGaps(data: string): number {
    return data ? data.split(";").length - 1 : 0;
  }

  unassign(rowData: any): void {
    this.setSelectedChases(rowData);

    const chaseStatus = this.selectedChases.map(item => item.workflowStatusName)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (chaseStatus.length > 1) {
      this.messagingService.showToast("Chases for this assignment may not be at different workflow statuses", SeverityType.ERROR);
      this.selectedChases = [];
      this.changeDetector.markForCheck();
    } else {
     const unassignedUserId = 0;
     this.chaseAssignService.assignChasesToUser(this.chaseAssignService.getSelectedChaseIds(this.selectedChases), unassignedUserId, null)
        .subscribe(() => {
          this.messagingService.showToast("Unassigned Successfully.", SeverityType.SUCCESS);
          this.closeModal();
          this.changeDetector.markForCheck();
          this.refreshGrid.emit();
        });
    }
    }

  openManageTagModal = (rowData: any): void => {
     this.setSelectedChases(rowData);
     if (ArrayHelper.isAvailable(this.selectedChases)) {
         this.totalEntityCount = this.selectedChases.length;
         this.entityName = "CHASE";
         this.isManageTagModalVisible = true;
     }
  }

  getAllTagsList(): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null)
      .subscribe(data => {
        this.allAvailableTags = data
          .filter(e => e.text === "Suggestions")
          .concat(
            data.filter(e => e.text !== "Suggestions")
              .sort((a, b) => a.text.localeCompare(b.text))
          );
      });
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

}
