<div class="filterContainer">
  <form-factory [formGroup]="filterForm" [model]="filterTypeInput" (onChange)="onSelectFilterType($event)"></form-factory>
</div>
<div class="filterContainer">
  <form-factory [formGroup]="filterForm" [model]="filterInput" (onChange)="onSelectFilter($event)"></form-factory>
</div>
<div class="filterContainer">

  <app-button (onClick)="clearFilters()"
              text="RESET"
              styleClass="ui-button-secondary" class="margin-right-10" ></app-button>

  <app-button (onClick)="applyFilter()"
              icon="sliders-h"
              text="APPLY"></app-button>
</div>
