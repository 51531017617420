import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { CatalyticLocations } from "./catalytic-locations.model";

export const mapCatalyticSupportingLocation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CatalyticSupportingLocation")
    .forMember("locations", o => {
      const isArray = Array.isArray(o.locations) && o.locations.length > 0;
      return isArray ? automapper.mapMany("default", "CatalyticLocations", o.locations) : [];
    });
};

export class CatalyticSupportingLocation {
  readonly locations?: CatalyticLocations[];

  constructor(options: Partial<CatalyticSupportingLocation> = {}) {
    this.locations = ArrayHelper.clean(options.locations);
  }
}
