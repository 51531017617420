<app-modal class="request-document-modal-container" [(visible)]="visible" (visibleChange)="visibleChange.emit($event)" (onHide)="close()"
  (onShow)="onShow.emit($event)" header="Request Additional Documentation">
  <form [formGroup]="formGroupDocumentRequest" class="form-factory-container">
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="required-docs-container">
          <form-factory [formGroup]="formGroupDocumentRequest" [model]="requiredDocsForMedicalRecord"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroupDocumentRequest" [model]="notes"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button text="Request" (onClick)="sendRequest()" [disabled]="!isValid"></app-button>
  </footer>
</app-modal>
