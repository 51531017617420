<div class="package-grid">
  <div class="package-grid-header bold">AUDIT PACKAGE </div>
  <div *ngIf="!auditPackageGridData" class="text">Choose a Project, Measure and Numerator above to begin.</div>

  <app-server-grid [configuration]="auditPackageGridConfiguration"
                   [(request)]="auditPackageGridSearchRequest"
                   [isLoadOnInit]="true"
                   [refresh]="refreshGrid"
                   [(selection)]="auditPackageGridSelection"
                   (dataLoaded)="gridData($event)">

    <ng-template #actionItem let-rowData>
      <div class="delete-btn" title="Remove">
        <app-button text="x"
                    (onClick)="deletePackageItem(rowData)">
        </app-button>
      </div>
    </ng-template>

  </app-server-grid>

  <app-button class="header-button"
              text="CREATE PACKAGE"
              (onClick)="verifyIfAssignedToOtherUser()"
              [disabled]="!auditPackageDataAvailable">
  </app-button>

  <app-modal [(visible)]="isFileNameEntryVisible" class="audit-package-generate-modal">
    <label>Enter Package Name</label>
    <div>
      <!-- TODO: Refactor using dynamic iputs -->
      <input type="text" pInputText [(ngModel)]="filename" />
    </div>
    <footer>
      <app-button class="header-button" text="GENERATE PACKAGE" (onClick)="createPackage()" [disabled]="!fileNameAvailable"></app-button>
    </footer>
  </app-modal>

  <app-confirmation-modal [(visible)]="showConfirmationForPackageCreation"
                          header="ATTENTION"
                          (onYes)="onConfirmPackageCreation()"
                          acceptText="CONTINUE"
                          rejectText="CANCEL">
    <p class="audit-package-generate-modal__helper-text">
      Please Note: Chase(s) are currently assigned to another user.
    </p>
  </app-confirmation-modal>

  <app-modal class="audit-package-generate-modal"
             [(visible)]="showNotificationForAuditGeneration"
             rejectText="CONTINUE">
    {{complianceChangeStatus}}
  </app-modal>

</div>
