<div class="dashboard-pend-container">
  <app-detail-main-template>
    <div container-header>
      <h3>My Pends</h3>
    </div>
    <div container-body>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <app-pend-grid [stateName]="stateName"
                         [isLoadOnInit]="isLoadOnInit"
                         [additionalFilters]="additionalFilters"
                         [additionalBulkActions]="additionalBulkActions"
                         [isSelectionModeMultiple]=true
                         [(selection)]="selection"
                         [showViews]="true"
                        [viewAttributeId]="viewAttributeId">
          </app-pend-grid>
        </div>
      </div>

    </div>

  </app-detail-main-template>
</div>

<app-internal-pends-change-owner [isChangeOwnerVisible]="isChangeOwnerView"
                                 (formClose)="reassignUser($event)"
                                 [chasePendIds]="chasePendIds">
</app-internal-pends-change-owner>

<app-internal-pends-request-status-change [isStatusChangeVisible]="isStatusChangeView"
                                          (formClose)="updateStatus($event)"
                                          [chasePendIds]="chasePendIds"
                                          [pendOwners]="pendOwners"
                                          [assignedUserId]="assignedUserId">
</app-internal-pends-request-status-change>
