<div class="grid-header bold">CONTACT ATTEMPT HISTORY</div>

<div class="contact-history-container">

  <app-basic-grid [configuration]="contactHistoryGridConfiguration"
                  [data]="contactHistoryGridData"
                  [(selection)]="contactHistoryGridSelection">

    <ng-template #recColumn let-rowData>
      <retrieval-recording-playback [recordingData]="rowData"></retrieval-recording-playback>
    </ng-template>

    <ng-template #noteColumn let-rowData>
      <app-contact-history-note [note]="rowData.callNote"></app-contact-history-note>
    </ng-template>
  </app-basic-grid>

</div>
