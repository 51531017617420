import { FormControl, FormGroup } from "@angular/forms";
import { BooleanHelper } from "../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { DynamicControl, IDynamicControlOptions } from "../dynamic-control.model";

export interface IDynamicInputOptions extends IDynamicControlOptions {
  value?: any;
  label?: string;
  saveInfo?: any;
  updateOn?: "change" | "blur" | "submit";
  readonly?: boolean;
  hide?: boolean;
}

export abstract class DynamicInput extends DynamicControl implements IDynamicInputOptions {
  value: any;
  label: string;
  saveInfo: any;
  updateOn: "change" | "blur" | "submit";
  readonly: boolean;

  constructor(options: IDynamicInputOptions = {}) {
    super(options);
    this.value = options.value;
    this.label = options.label || "";
    this.updateOn = options.updateOn || "change";
    this.saveInfo = options.saveInfo || {};
    this.readonly = BooleanHelper.tryGet(options.readonly, false);
  }


  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.label);
  }

  create(): FormControl {
    const formState = {
      value: this.value || null,
      disabled: this.disabled || this.isAdmin,
    };
    const control = new FormControl(formState, {
      validators: this.validators,
      asyncValidators: this.asyncValidators,
      updateOn: this.updateOn,
    });

    (control as any).saveInfo = { ...this.saveInfo };

    return control;
  }

  getValue(form: FormGroup): any {
    if (form == null) {
      return null;
    }

    const value = form.get(this.getMasterKey()).value;
    return value;
  }

  getMasterKey(): string {
    return this.parent == null ? this.key : `${this.getParentKey(this.parent)}.${this.key}`;
  }

  private getParentKey = (parent: DynamicControl): string => {
    if (parent.parent == null) {
      return parent.key;
    }

    return `${this.getParentKey(parent.parent)}.${parent.key}`;
  }
}
