import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ChaseItem } from "../../../api/chase-search/chase-search-result-item";
import { AccessInfo } from "../access-info.model";
import { Contact } from "../contact.model";
import { ContactMethodType } from "../cover-letter-template/contact-method-type.enum";
import { DocumentSourceType } from "../retrieval-document-source-type.enum";

export interface IAddressDetailStateOptions {
    accessInfo?: AccessInfo;
    address1?: string;
    address2?: string;
    allProvidersVerified?: boolean;
    hasGapProject?: boolean;
    assignedTo?: string;
    chaseGridData?: ChaseItem[];
    city?: string;
    contact?: Contact;
    contacts?: Contact[];
    coRetrievalOwner?: string;
    documentSourceTypeName?: string;
    documentSourceTypeId?: number;
    groupName?: string;
    invoiceSettingData?: string;
    invoiceType?: string;
    isContactVerified?: boolean;
    isSpecialHandling?: boolean;
    lastContactedDate?: Date;
    masterDocumentSourceId?: number;
    masterDocumentSourceStatus?: string;
    masterDocumentSourceVerifiedDate?: Date;
    nextCallDateTime?: string;
    openChaseCount?: number;
    totalChaseCount?: number;
    openGapPursuitsCount?: number;
    organizationId?: number;
    postalCode?: string;
    retrievalTypeId?: number;
    specialHandlingId?: number;
    specialHandlingText?: string;
    specialHandlingComment?: string;
    specialHandlingUpdateDate?: Date;
    specialHandlingUpdateUserName?: string;
    state?: string;
    totalCalls?: number;
    totalUniqueDaysCalled?: number;
    totalEmails?: number;
    totalFaxes?: number;
    totalNotes?: number;
    uploadAccessCode?: string;
    vendorConfirmDate?: string;
    vendorId?: number;
    vendorInvoicing?: string;
    vendorName?: string;
    weeklyContactMethodTypeId?: ContactMethodType;
    weeklyContactMethodTypeName?: string;
    hasPrimaryContact?: boolean;
}

export class AddressDetailState {
    accessInfo: AccessInfo;
    address1: string;
    address2: string;
    allProvidersVerified: boolean;
    assignedTo: string;
    chaseGridData: ChaseItem[];
    city: string;
    contact: Contact;
    contacts: Contact[];
    coRetrievalOwner: string;
    documentSourceTypeName: string;
    documentSourceTypeId: number;
    groupName: string;
    hasGapProject: boolean;
    invoiceSettingData: string;
    invoiceType: string;
    isContactVerified: boolean;
    isSpecialHandling: boolean;
    lastContactedDate: Date;
    masterDocumentSourceId: number;
    masterDocumentSourceStatus: string;
    masterDocumentSourceVerifiedDate: Date;
    nextCallDateTime: string;
    openChaseCount?: number;
    totalChaseCount?: number;
    openGapPursuitsCount?: number;
    organizationId: number;
    postalCode: string;
    retrievalTypeId: number;
    specialHandlingId: number;
    specialHandlingText: string;
    specialHandlingComment: string;
    specialHandlingUpdateDate: Date;
    specialHandlingUpdateUserName: string;
    state: string;
    totalCalls: number;
    totalUniqueDaysCalled: number;
    totalEmails: number;
    totalFaxes: number;
    totalNotes: number;
    uploadAccessCode: string;
    vendorConfirmDate: string;
    vendorId: number;
    vendorInvoicing: string;
    vendorName: string;
    weeklyContactMethodTypeId: ContactMethodType;
    weeklyContactMethodTypeName: string;
    hasPrimaryContact: boolean;
    constructor(options: IAddressDetailStateOptions = {}) {
        this.accessInfo = new AccessInfo(options.accessInfo || {});
        this.address1 = StringHelper.clean(options.address1);
        this.address2 = StringHelper.clean(options.address2);
        this.allProvidersVerified = options.allProvidersVerified;
        this.assignedTo = StringHelper.clean(options.assignedTo);
        this.chaseGridData = ArrayHelper.clean(options.chaseGridData);
        this.city = StringHelper.clean(options.city);
        this.contact = options.contact ? options.contact : new Contact();
        this.contacts = ArrayHelper.clean(options.contacts);
        this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
        this.documentSourceTypeName = StringHelper.clean(options.documentSourceTypeName);
        this.documentSourceTypeId = options.documentSourceTypeId;
        this.groupName = StringHelper.clean(options.groupName);
        this.hasGapProject = options.hasGapProject;
        this.invoiceSettingData = StringHelper.clean(options.invoiceSettingData);
        this.invoiceType = StringHelper.clean(options.invoiceType);
        this.isContactVerified = options.isContactVerified;
        this.isSpecialHandling = options.isSpecialHandling;
        this.lastContactedDate = DateHelper.create(options.lastContactedDate, null);
        this.masterDocumentSourceId = options.masterDocumentSourceId;
        this.masterDocumentSourceStatus = StringHelper.clean(options.masterDocumentSourceStatus);
        this.masterDocumentSourceVerifiedDate = options.masterDocumentSourceVerifiedDate;
        this.nextCallDateTime = StringHelper.clean(options.nextCallDateTime);
        this.openChaseCount = options.openChaseCount;
        this.totalChaseCount = options.totalChaseCount;
        this.openGapPursuitsCount = options.openGapPursuitsCount;
        this.organizationId = options.organizationId;
        this.postalCode = StringHelper.clean(options.postalCode);
        this.retrievalTypeId = options.retrievalTypeId;
        this.specialHandlingId = options.specialHandlingId;
        this.specialHandlingText = options.specialHandlingText;
        this.specialHandlingComment = options.specialHandlingComment;
        this.specialHandlingUpdateDate = DateHelper.create(options.specialHandlingUpdateDate, null);
        this.specialHandlingUpdateUserName = options.specialHandlingUpdateUserName;
        this.state = StringHelper.clean(options.state);
        this.totalCalls = options.totalCalls;
        this.totalUniqueDaysCalled = options.totalUniqueDaysCalled;
        this.totalEmails = options.totalEmails;
        this.totalFaxes = options.totalFaxes;
        this.totalNotes = options.totalNotes;
        this.uploadAccessCode = StringHelper.clean(options.uploadAccessCode);
        this.vendorConfirmDate = StringHelper.clean(options.vendorConfirmDate);
        this.vendorId = options.vendorId;
        this.vendorInvoicing = StringHelper.clean(options.vendorInvoicing);
        this.vendorName = StringHelper.clean(options.vendorName);
        this.weeklyContactMethodTypeId = options.weeklyContactMethodTypeId;
        this.weeklyContactMethodTypeName = StringHelper.clean(options.weeklyContactMethodTypeName);
        this.hasPrimaryContact = options.hasPrimaryContact;
    }

    get hasMasterDocumentSourceId(): boolean {
        return NumberHelper.isAvailable(this.masterDocumentSourceId);
    }

    get is3rdParty(): boolean {
        return this.documentSourceTypeId === DocumentSourceType.THIRDPARTY;
    }

    get isEMR(): boolean {
        return this.documentSourceTypeId === DocumentSourceType.EMR;
    }

    get isFieldTech(): boolean {
        return this.documentSourceTypeId === DocumentSourceType.FIELDTECH;
    }

    get isPSR(): boolean {
        return this.documentSourceTypeId === DocumentSourceType.PSR;
    }

    get isUnknownVendor(): boolean {
        return StringHelper.isAvailable(this.vendorName) && this.vendorName.toLowerCase() === "unknown vendor";
    }
}
