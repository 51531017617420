import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class CommitmentDateUpdateModel {
  retrievalLocationId: number;
  commitmentDate: string;
  requestDate: string;
  functionalRoleId: number;
  chaseIds: number[];

  constructor(options: {
    retrievalLocationId: number;
    commitmentDate?: string;
    requestDate?: string;
    functionalRoleId: number;
    chaseIds: number[];

  }) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.commitmentDate =  StringHelper.clean(options.commitmentDate);
    this.requestDate = StringHelper.clean(options.requestDate);
    this.functionalRoleId = options.functionalRoleId;
    this.chaseIds = options.chaseIds;
  }
}

