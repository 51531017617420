import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { FormService } from "../form.service";
import { ValidationService } from "./validation.service";

@Injectable({
  providedIn: "root",
})
export class GenericAsyncValidator implements AsyncValidator {
  constructor(
    private service: ValidationService,
    private formService: FormService
  ) { }


  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!(control as any).saveInfo) {
      return of(null);
    }

    if ((control as any).pristine) {
      return of(null);
    }

    return timer(500).pipe(
      switchMap(() => this.apiValidate(control)),
      first(),
      map((error: string) => StringHelper.isAvailable(error) ? ({ genericAsyncValidator: error }) : null)
    );
  }

  private apiValidate(control: any): Observable<string> {
    const validateInfo = this.formService.getApiObject(control);
    return this.service.validate(validateInfo);
  }
}
