import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { ChaseSearchComponent } from "../../../shared/chase-search/chase-search.component";
import { CanDeactivateGuard } from "../../api/url-guards/can-deactivate.guard";
import { ClinicalPageComponent } from "../clinical/clinical-page/clinical-page.component";
import { InternalPendsListingComponent } from "../internal-pends/internal-pends-listing/internal-pends-listing.component";
import { RetrievalAddressDetailCommentsComponent } from "./address-detail/address-detail-comments/address-detail-comments.component";
import { RetrievalAddressDetailContactHistoryComponent } from "./address-detail/address-detail-contact-history/address-detail-contact-history.component";
import { RetrievalAddressDetailInfoGridsChasesComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-chases/address-detail-info-grids-chases.component";
import { RetrievalAddressDetailInfoGridsInvoicesComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-invoices/address-detail-info-grids-invoices.component";
import { RetrievalAddressDetailInfoGridsMembersComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-members/address-detail-info-grids-members.component";
import { RetrievalAddressDetailInfoGridsPendsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-pends/address-detail-info-grids-pends.component";
import { RetrievalAddressDetailInfoGridsProvidersComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-providers/address-detail-info-grids-providers.component";
import { RetrievalAddressDetailInfoGridsPursuitsComponent } from "./address-detail/address-detail-info/address-detail-info-grids/address-detail-info-grids-pursuits/address-detail-info-grids-pursuits.component";
import { RetrievalAddressDetailInfoComponent } from "./address-detail/address-detail-info/address-detail-info.component";
import { RetrievalAddressDetailTimelineComponent } from "./address-detail/address-detail-timeline/address-detail-timeline.component";
import { RetrievalAddressDetailComponent } from "./address-detail/address-detail.component";
import { AddressesQueueComponent } from "./addresses-queue/addresses-queue.component";
import { ChaseUploadDocumentComponent } from "./chase-upload-document/chase-upload-document.component";
import { ContactHistoryComponent } from "./contact-history/contact-history.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DetailComponent } from "./detail/detail.component";
import { EmrDetailAccessInfoComponent } from "./emr/emr-detail/emr-detail-access-info/emr-detail-access-info.component";
import { EmrDetailCallComponent } from "./emr/emr-detail/emr-detail-call/emr-detail-call.component";
import { EmrDetailCommentsComponent } from "./emr/emr-detail/emr-detail-comments/emr-detail-comments.component";
import { EmrDetailContactHistoryComponent } from "./emr/emr-detail/emr-detail-contact-history/emr-detail-contact-history.component";
import { EmrDetailInfoComponent } from "./emr/emr-detail/emr-detail-info/emr-detail-info.component";
import { EmrDetailTimelineComponent } from "./emr/emr-detail/emr-detail-timeline/emr-detail-timeline.component";
import { EmrDetailComponent } from "./emr/emr-detail/emr-detail.component";
import { FtDetailAccessInfoComponent } from "./field-tech/ft-detail/ft-detail-access-info/ft-detail-access-info.component";
import { FtDetailCallComponent } from "./field-tech/ft-detail/ft-detail-call/ft-detail-call.component";
import { FtDetailCommentsComponent } from "./field-tech/ft-detail/ft-detail-comments/ft-detail-comments.component";
import { FtDetailContactHistoryComponent } from "./field-tech/ft-detail/ft-detail-contact-history/ft-detail-contact-history.component";
import { FtDetailInfoComponent } from "./field-tech/ft-detail/ft-detail-info/ft-detail-info.component";
import { FtDetailTimelineComponent } from "./field-tech/ft-detail/ft-detail-timeline/ft-detail-timeline.component";
import { FtDetailComponent } from "./field-tech/ft-detail/ft-detail.component";
import { AddressDetailBusinessHoursComponent } from "./psr/address-detail/address-detail-business-hours/address-detail-business-hours.component";
import { AddressDetailCallComponent } from "./psr/address-detail/address-detail-call/address-detail-call.component";
import { AddressDetailCommentsComponent } from "./psr/address-detail/address-detail-comments/address-detail-comments.component";
import { AddressDetailContactHistoryComponent } from "./psr/address-detail/address-detail-contact-history/address-detail-contact-history.component";
import { AddressDetailInfoComponent } from "./psr/address-detail/address-detail-info/address-detail-info.component";
import { AddressDetailTimelineComponent } from "./psr/address-detail/address-detail-timeline/address-detail-timeline.component";
import { AddressDetailComponent } from "./psr/address-detail/address-detail.component";
import { DocumentReviewComponent } from "./retreival-document-review/document-review/document-review.component";
import { RetreivalDocumentReviewComponent } from "./retreival-document-review/retreival-document-review.component";
import { RetrievalPageComponent } from "./retrieval-page/retrieval-page.component";
import { RetrievalUrlService } from "./retrieval-url.service";
import { RetrievalComponent } from "./retrieval.component";
import { ThirdPartyComponent } from "./third-party/third-party.component";

const childRoutes: Routes = [
  {
    path: "", component: RetrievalAddressDetailInfoComponent, children: [
      { path: "", component: RetrievalAddressDetailInfoGridsChasesComponent },
      { path: "call/:calltype", component: RetrievalAddressDetailInfoGridsChasesComponent },
      { path: "members", component: RetrievalAddressDetailInfoGridsMembersComponent },
      { path: "pends", component: RetrievalAddressDetailInfoGridsPendsComponent },
      { path: "providers", component: RetrievalAddressDetailInfoGridsProvidersComponent },
      { path: "pursuits", component: RetrievalAddressDetailInfoGridsPursuitsComponent },
      { path: "invoices", component: RetrievalAddressDetailInfoGridsInvoicesComponent },
    ],
  },
  { path: "comments", component: RetrievalAddressDetailCommentsComponent },
  { path: "contactHistory", component: RetrievalAddressDetailContactHistoryComponent },
  { path: "timeline", component: RetrievalAddressDetailTimelineComponent },
];
const routes: Routes = [
  { path: "", component: RetrievalComponent },
  { path: "pend/:pendType/view", component: InternalPendsListingComponent, canActivate: [AuthGuard] },
  {
    path: "address/:AddressGd", component: AddressDetailComponent, children: [
      { path: "", component: AddressDetailInfoComponent },
      { path: "comments", component: AddressDetailCommentsComponent },
      { path: "contactHistory", component: AddressDetailContactHistoryComponent },
      { path: "businessHours", component: AddressDetailBusinessHoursComponent },
      { path: "call/:callType", component: AddressDetailCallComponent },
      { path: "timeline", component: AddressDetailTimelineComponent },
    ],
  },
  {
    path: "addressdetail/:addressId/sh", component: RetrievalAddressDetailComponent, children: childRoutes,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: "addressdetail/:addressId", component: RetrievalAddressDetailComponent, children: childRoutes,
    canDeactivate: [CanDeactivateGuard],
  },
  { path: "address/:AddressId/:DocumentSourceTypeID", canActivate: [RetrievalUrlService], component: RetrievalUrlService },
  { path: "assignment", component: AddressesQueueComponent, canActivate: [AuthGuard] },
  { path: "chaseUpload/:RetrievalId/:RetrievalTypeId", component: ChaseUploadDocumentComponent },
  { path: "contacthistory", component: ContactHistoryComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "detail", component: DetailComponent },
  {
    path: "emr/:EmrGd", component: EmrDetailComponent, children: [
      { path: "", component: EmrDetailInfoComponent },
      { path: "accessInfo", component: EmrDetailAccessInfoComponent },
      { path: "comments", component: EmrDetailCommentsComponent },
      { path: "contactHistory", component: EmrDetailContactHistoryComponent },
      { path: "call/:callType", component: EmrDetailCallComponent },
      { path: "timeline", component: EmrDetailTimelineComponent },
    ],
  },
  {
    path: "ft/:FtGd", component: FtDetailComponent, children: [
      { path: "", component: FtDetailInfoComponent },
      { path: "accessInfo", component: FtDetailAccessInfoComponent },
      { path: "comments", component: FtDetailCommentsComponent },
      { path: "contactHistory", component: FtDetailContactHistoryComponent },
      { path: "call/:callType", component: FtDetailCallComponent },
      { path: "timeline", component: FtDetailTimelineComponent },
    ],
  },
  { path: "review", component: RetreivalDocumentReviewComponent, canActivate: [AuthGuard] },
  { path: "review/intake/:documentId", component: DocumentReviewComponent, data: { defaultPath: ["/retrieval/review", "/dashboard/documentreviewer"] }, canActivate: [AuthGuard] },
  { path: "review/qa/:chaseId", component: DocumentReviewComponent, data: { defaultPath: ["/retrieval/views/docqa", "/dashboard/documentreviewer"] }, canActivate: [AuthGuard] },
  { path: "review/search", component: ChaseSearchComponent },
  { path: "view/:type", component: RetrievalPageComponent, canActivate: [AuthGuard] },
  { path: "views/:pageType", component: ClinicalPageComponent, canActivate: [AuthGuard] },
  { path: "thirdparty", component: ThirdPartyComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RetrievalRoutingModule { }
