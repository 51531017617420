import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { ListboxModule } from "primeng/listbox";
import { PaginatorModule } from "primeng/paginator";
import { TreeModule } from "primeng/tree";
import { DynamicFormsModule } from "../dynamic-forms/dynamic-forms.module";
import { CallFlowService } from "../platform/modules/retrieval/call-flow/call-flow.service";
import { AddressGridModule } from "./address-grid/address-grid.module";
import { BadgeModule } from "./badge/badge.module";
import { ButtonModule } from "./button/button.module";
import { ChaseGridModule } from "./chase-grid/chase-grid.module";
import { ChaseSearchModule } from "./chase-search/chase-search.module";
import { CheckboxValidateComponent } from "./checkbox-validate/checkbox-validate.component";
import { CommentsModule } from "./comments/comments.module";
import { ContactHistoryNoteModule } from "./contact-history-note/contact-history-note.module";
import { CustomPendCheckboxGroupComponent } from "./custom-pend-checkbox-group/custom-pend-checkbox-group.component";
import { DatesOfServiceComponent } from "./dates-of-service/dates-of-service.component";
import { DetailHeaderTemplateModule } from "./detail-header-template/detail-header-template.module";
import { DetailMainTemplateComponent } from "./detail-main-template/detail-main-template.component";
import { DetailTemplateModule } from "./detail-template/detail-template.module";
import { DocumentPageAssignmentComponent } from "./document-page-assignment/document-page-assignment.component";
import { ChaseMoveBackComponent } from "./document/chase-move-back/chase-move-back.component";
import { DocumentModule } from "./document/document.module";
import { DownloadProviderPacketComponent } from "./download-provider-packet/download-provider-packet.component";
import { DragListComponent } from "./drag-list/drag-list/drag-list.component";
import { FilterComponent } from "./filter/filter.component";
import { GapGridComponent } from "./gap-grid/gap-grid.component";
import { OpenGapsTemplateComponent } from "./gap-grid/open-gaps-template/open-gaps-template.component";
import { GenericBorderTemplateModule } from "./generic-border-template/generic-border-template.module";
import { GridModule } from "./grid/grid.module";
import { IconModule } from "./icon/icon.module";
import { IframeComponent } from "./iframe/iframe.component";
import { InfoHeaderTemplateComponent } from "./info-header-template/info-header-template.component";
import { InfoTemplateModule } from "./info-template/info-template.module";
import { InvoiceGridModule } from "./invoice-grid/invoice-grid.module";
import { InvoiceSettingModule } from "./invoice/invoice-setting.module";
import { KpiModule } from "./kpi/kpi.module";
import { LegendComponent } from "./legend/legend.component";
import { ListModule } from "./list/list.module";
import { LogoComponent } from "./logo/logo.component";
import { MemberGridModule } from "./member-grid/member-grid.module";
import { MembercentricDocAttachmentComponent } from "./membercentric-doc-attachment/membercentric-doc-attachment.component";
import { MenuModule } from "./menu/menu.module";
import { TabPanelComponent } from "./menu/tab-menu/tab-panel.component";
import { MessagingModule } from "./messaging/messaging.module";
import { NationalProviderGridModule } from "./national-provider-grid/national-provider-grid.module";
import { PaginationComponent } from "./paginator/pagination/pagination.component";
import { PanelModule } from "./panel/panel.module";
import { PendGridModule } from "./pend-grid/pend-grid.module";
import { PendModule } from "./pend/pend.module";
import { PhoneNumberPipe } from "./pipes/phone.pipe";
import { SetTimeZoneTransformPipe } from "./pipes/SetTimeZoneTransformPipe";
import { PrintProviderPacketComponent } from "./print-provider-packet/print-provider-packet.component";
import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";
import { ProgressBarModule } from "./progress-bar/progress-bar.module";
import { ProviderSearchToolComponent } from "./provider-search-tool/provider-search-tool.component";
import { SpecialHandlingComponent } from "./special-handling/special-handling.component";
import { BannerStatisticsComponent } from "./statistics/banner-statistics/banner-statistics.component";
import { StatisticsModule } from "./statistics/statistics.module";
import { TagModule } from "./tags/tag.module";
import { TimelineModule } from "./timeline/timeline.module";
import { ToolTipModule } from "./tool-tip/tool-tip.module";
import { UploadModule } from "./upload/upload.module";
import { DynamicEntityValidationComponent } from "./validation-yes-no/dynamic-entity-validation/dynamic-entity-validation.component";

@NgModule({
    imports: [
        AddressGridModule,
        BadgeModule,
        ButtonModule,
        ChaseGridModule,
        CommonModule,
        ContactHistoryNoteModule,
        DetailHeaderTemplateModule,
        DetailTemplateModule,
        DialogModule,
        DocumentModule,
        DragDropModule,
        DropdownModule,
        DynamicFormsModule,
        FormsModule,
        GenericBorderTemplateModule,
        GridModule,
        IconModule,
        InfoTemplateModule,
        InvoiceSettingModule,
        KpiModule,
        ListboxModule,
        ListModule,
        MenuModule,
        MessagingModule,
        NationalProviderGridModule,
        PaginatorModule,
        PanelModule,
        PendGridModule,
        PendModule,
        PrivacyPolicyModule,
        ProgressBarModule,
        ReactiveFormsModule,
        RouterModule,
        StatisticsModule,
        TagModule,
        TimelineModule,
        ToolTipModule,
        TreeModule,
        UploadModule,
    ],
    declarations: [
        BannerStatisticsComponent,
        ChaseMoveBackComponent,
        CheckboxValidateComponent,
        CustomPendCheckboxGroupComponent,
        DetailMainTemplateComponent,
        DocumentPageAssignmentComponent,
        DownloadProviderPacketComponent,
        DragListComponent,
        FilterComponent,
        GapGridComponent,
        IframeComponent,
        InfoHeaderTemplateComponent,
        LegendComponent,
        LogoComponent,
        MembercentricDocAttachmentComponent,
        OpenGapsTemplateComponent,
        PaginationComponent,
        PhoneNumberPipe,
        PrintProviderPacketComponent,
        SetTimeZoneTransformPipe,
        SpecialHandlingComponent,
        DynamicEntityValidationComponent,
        DatesOfServiceComponent,
        ProviderSearchToolComponent,
    ],
    providers: [
        CallFlowService,
    ],
    entryComponents: [
        CustomPendCheckboxGroupComponent,
    ],
    exports: [
        AddressGridModule,
        BadgeModule,
        BannerStatisticsComponent,
        ButtonModule,
        ChaseGridModule,
        ChaseMoveBackComponent,
        ChaseSearchModule,
        CheckboxValidateComponent,
        CommentsModule,
        CommonModule,
        ContactHistoryNoteModule,
        DatesOfServiceComponent,
        DetailHeaderTemplateModule,
        DetailMainTemplateComponent,
        DetailTemplateModule,
        DialogModule,
        DocumentModule,
        DocumentPageAssignmentComponent,
        DownloadProviderPacketComponent,
        DragListComponent,
        DropdownModule,
        DynamicFormsModule,
        FilterComponent,
        FormsModule,
        GapGridComponent,
        GenericBorderTemplateModule,
        GenericBorderTemplateModule,
        GridModule,
        IconModule,
        IframeComponent,
        InfoHeaderTemplateComponent,
        InfoTemplateModule,
        InvoiceGridModule,
        InvoiceSettingModule,
        KpiModule,
        LegendComponent,
        ListModule,
        LogoComponent,
        MembercentricDocAttachmentComponent,
        MemberGridModule,
        MenuModule,
        MessagingModule,
        NationalProviderGridModule,
        OpenGapsTemplateComponent,
        PaginationComponent,
        PaginatorModule,
        PanelModule,
        PendGridModule,
        PendModule,
        PhoneNumberPipe,
        PrintProviderPacketComponent,
        ProgressBarModule,
        ProviderSearchToolComponent,
        ReactiveFormsModule,
        RouterModule,
        SetTimeZoneTransformPipe,
        SpecialHandlingComponent,
        StatisticsModule,
        TagModule,
        TimelineModule,
        ToolTipModule,
        UploadModule,
        DynamicEntityValidationComponent,
        TabPanelComponent,
    ],
})
export class SharedModule { }
