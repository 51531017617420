import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-risk-nlp-icd-dashboard-overview",
  templateUrl: "./risk-nlp-icd-dashboard-overview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskNLPICDDashboardOverviewComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.RISK_NLP_ICD_DASHBOARD_OVERVIEW;

  constructor() {
    this.filters = ["Projects", "Hcc", "Address", "DOS"];
  }

}
