import { Component, ComponentFactoryResolver, ComponentRef, OnChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { DynamicControlDirective } from "../dynamic-control-component.model";
import { DynamicControl } from "../dynamic-control.model";

@Component({
  selector: "form-factory",
  template: `
    <div [formGroup]="formGroup">
      <ng-container #dynamicComponent></ng-container>
    </div>
  `,
  styleUrls: ["./factory.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FactoryComponent extends DynamicControlDirective<DynamicControl> implements OnChanges {
  @ViewChild("dynamicComponent", { read: ViewContainerRef, static: true }) component: ViewContainerRef;
  componentRef: ComponentRef<DynamicControlDirective<DynamicControl>>;


  constructor(private componentFactory: ComponentFactoryResolver) {
    super();
  }

  ngOnChanges() {
    this.destroy();
    this.create();
  }


  private destroy(): void {
    this.componentRef = null;
    this.component.clear();
  }

  private create(): void {
    const componentType = this.model.componentType;
    const factory = this.componentFactory.resolveComponentFactory(componentType);
    this.componentRef = this.component.createComponent<DynamicControlDirective<DynamicControl>>(factory);

    this.componentRef.instance.formGroup = this.formGroup;
    this.componentRef.instance.model = this.model;
    this.componentRef.instance.onChange = this.onChange;
    this.componentRef.instance.onClick = this.onClick;
    this.componentRef.instance.onInput = this.onInput;
    this.componentRef.instance.onFocus = this.onFocus;
    this.componentRef.instance.onBlur = this.onBlur;
    this.componentRef.instance.onPanelShow = this.onPanelShow;
    this.componentRef.instance.onPanelHide = this.onPanelHide;
    this.componentRef.instance.customTemplate = this.customTemplate;
  }
}
