<div [formGroup]="formGroup" class="control control--input control--checkbox-group" [hidden]="hidden">
  <div class="control__header__box">
    <div *ngIf="model.hasLabel" class="control__header">
      <label class="control__header__label" [for]="model.key" [title]="label">{{ label | titlecase}}</label>
      <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    </div>
    <div class="control__header toggle-all" *ngIf="showSelectAll">
      <app-button
        [disabled]="!enableToggleButton"
        classes="unselected-btn"
        [text]="toggleButtonText"
        (onClick)="toggleAll($event)">
      </app-button>
    </div>
  </div>
  <p-listbox
    [hidden]="!hasOptions"
    [options]="options"
    [multiple]="true"
    [checkbox]="true"
    [filter]="false"
    [showToggleAll]="false"
    [optionLabel]="optionText"
    [styleClass]="classes"
    dataKey="value"
    (onChange)="onChange.emit($event); onInput.emit($event);"    
    [id]="model.key"
    [formControlName]="model.key"
    [(ngModel)]="model.selectedOptions">
    
    <ng-template *ngIf="model.showTooltip" let-item pTemplate="items">
      <label [title]="item.value.extra">{{ item.label }}</label>
    </ng-template>
  </p-listbox>
  <div *ngIf="!hasOptions" class="text--danger">Information Not Available.</div>
</div>
