import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textarea } from "../../../dynamic-forms/inputs/textarea/textarea.model";
import { DocumentService } from "../../../platform/api/document/document.service";
import { DocumentTypeName } from "../../../platform/modules/retrieval/retreival-document-review/document-type.enum";
import { DocumentRequest } from "../document-request.model";
import { DocumentRequestService } from "../document-request.service";

@Component({
  selector: "app-request-document-modal",
  templateUrl: "./request-document-modal.component.html",
  styleUrls: ["./request-document-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDocumentModalComponent implements OnInit {
  @Input() chaseId: number;
  @Input() addressId: number;
  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  inputs: DynamicInput[];
  formGroupDocumentRequest: FormGroup;
  requiredDocsForMedicalRecord: CheckboxGroup;
  notes: Textarea;
  selectedDocumentType: SelectableInput;

  constructor(
    private readonly messagingService: MessagingService,
    private readonly formService: FormService,
    private readonly documentService: DocumentService,
    private readonly documentRequestService: DocumentRequestService,
    private changeDetector: ChangeDetectorRef,
    protected readonly automapper: AutomapperService
  ) {

    this.requiredDocsForMedicalRecord = new CheckboxGroup({
      key: "RequiredDocsForMedicalRecord",
      label: "Documents that are missing",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Please select documents that are missing",
      },
    });

    this.notes = new Textarea({
      key: "Note",
      label: "Note (Provider Facing)",
      placeholder: "Write a note...",
      validators: [
        Validators.maxLength(1000),
      ],
      errorMessages: {
        maxlength: "Too many characters. Less than 1000 characters is required.",
      },
    });

  }

  ngOnInit() {
    this.createForm();
    this.getRequiredDocumentsForChase();
  }


  private getRequiredDocumentsForChase(): void {
    this.documentService
      .getDocumentsForChase(this.chaseId)
      .pipe(map(this.automapper.curryMany("DocumentRequirement", "SelectableInput")))
      .subscribe(result => {
        this.requiredDocsForMedicalRecord = new CheckboxGroup({ ...this.requiredDocsForMedicalRecord, options: result } as any);
        this.createForm();
        this.changeDetector.markForCheck();
      });
  }


  get hasForm(): boolean {
    return this.formGroupDocumentRequest != null;
  }

  get isValid(): boolean {
    if (this.formGroupDocumentRequest == null) {
      return false;
    }

    return this.formGroupDocumentRequest.valid;
  }

  private get documentTypeId(): number {
    if (!this.hasForm) {
      return null;
    }

    return +this.formGroupDocumentRequest.get("RequestType").value;
  }

  private get note(): string {
    if (!this.hasForm) {
      return "";
    }

    return this.formGroupDocumentRequest.get("Note").value;
  }

  private get documentsRequested(): SelectableInput[] {
    if (!this.hasForm) {
      return [];
    }

    return this.formGroupDocumentRequest.get("RequiredDocsForMedicalRecord").value;
  }

  sendRequest(): void {
    if (this.isValid) {
      const data = new DocumentRequest({
        masterDocumentSourceId: this.addressId,
        chaseId: this.chaseId,
        documentTypeId: DocumentTypeName.DocTypeMedicalRecord,
        documentsRequested: this.documentsRequested,
        note: this.note,
      });

      this.documentRequestService.create(data).subscribe(
        () => this.messagingService.showToast("Successful Document Request!", SeverityType.SUCCESS),
        () => this.messagingService.showToast("Document Request Error.", SeverityType.ERROR)
      );
      this.close();
    }
  }

  private createForm(): void {
    this.formGroupDocumentRequest = this.formService.createFormGroup([
      this.requiredDocsForMedicalRecord,
      this.notes,
    ]);
  }

  close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.formGroupDocumentRequest.reset();
    this.selectedDocumentType = null;
    this.onHide.emit();
  }

}
