import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ListItem } from "../../../../shared/list/list-item";
import { LookupModel } from "../../../../shared/models/lookup-model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ClinicalMeasureListItem } from "../../clinical/clinical-page/clinical-measure-list-item.model";
import { DOCUMENT_REQUIRMENT } from "../../member/chase-detail/chase-detail-chart/attributes";
import { Project } from "../../project/project.model";

@Injectable({ providedIn: "root" })
export class RetrievalPageService {

    constructor(
        @Inject(BASE_API_URL)
        private readonly baseApiUrl: string,
        private http: HttpClient,
        private automapper: AutomapperService
    ) { }


    getStatisticsData(type: string): Observable<ListItem[]> {
        const url = `${this.baseApiUrl}retrieval/statistics?type=${type}`;
        return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
    }

    getProjectList(projectId?: number, projectTypeId?: number, projectStatus?: string, projectTypeIds?: string): Observable<LookupModel[]> {
        const params: string[] = [];
        if (NumberHelper.isAvailable(projectId)) {
            params.push(`projectId=${projectId}`);
        }
        if (NumberHelper.isAvailable(projectTypeId)) {
            params.push(`projectTypeId=${projectTypeId}`);
        }
        if (StringHelper.isAvailable(projectStatus)) {
          params.push(`status=${projectStatus}`);
        }
        if (StringHelper.isAvailable(projectTypeIds)) {
            params.push(`projectTypeIds=${projectTypeIds}`);
        }
        const url = ArrayHelper.isAvailable(params) ?
            `${this.baseApiUrl}project/lookup?${params.join("&")}` : `${this.baseApiUrl}project/lookup`;

        return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "LookupModel"))
        );
    }
    getProjectListForDocumentReq(isDocumentHasTimeFrame: boolean): Observable<LookupModel[]> {
    const attributeId = DOCUMENT_REQUIRMENT.attributeId;
    const url = `${this.baseApiUrl}project/documentrequest?attributeId=${attributeId}&&isDocumentHasTimeFrame=${isDocumentHasTimeFrame}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "LookupModel"))
    );
  }
    getProjects(projectFilter: Project): Observable<LookupModel[]> {
        const url = `${this.baseApiUrl}project/projects`;
        return this.http.post(url, projectFilter).pipe(map(this.automapper.curryMany("default", "LookupModel")));
    }

    getAssignedToUsersList(): Observable<SelectItem[]> {
        const url = `${this.baseApiUrl}user/all`;
        return this.http.post(url, {}).pipe(map((response: any) => response as SelectItem[]));
    }
    getMeasuresList(projectTypeId?: number): Observable<ClinicalMeasureListItem[]> {
    const url = `${this.baseApiUrl}measure/byprojecttypeid?projectTypeId=${projectTypeId}`;

    return this.http.get(url).pipe(map((response: any) => response as ClinicalMeasureListItem[]));
  }


    getRetrievalUsers(retrievaltype: string): Observable<any> {
        const url = `${this.baseApiUrl}retrieval/users?retrievalType=${retrievaltype}`;
        return this.http.post(url, {});
    }

    getAssignedToUsersFilterList(sourceType: string): Observable<SelectableInput[]> {
        const url = `${this.baseApiUrl}retrieval/assignedtousers?sourceType=${sourceType}`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("UserModel", "SelectableInput"))
        );
    }

    getDocumentSourceTypes(): Observable<SelectableInput[]> {
        const url = `${this.baseApiUrl}document/source/types`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "SelectableInput"))
        );
    }

    getStatuses(): Observable<SelectableInput[]> {
        const url = `${this.baseApiUrl}retrieval/statistic/types`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "SelectableInput"))
        );
    }
}
