<div class="chase-detail-form">
  <div class="ui-md-3 ui-toolbar-group-left">
    <div [ngClass]="{'pursuit__get-next': isPursuit }" *ngIf="!isIVAProjectSelected" class="ui-g-12 ui-md-12 ui-lg-12">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroupMeasureSelection"
                      [model]="pursuitInput" (onChange)="disableControls()"></form-factory>
      </div >
      <div *ngIf="isPursuit" class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroupMeasureSelection"
                      [model]="pursuitType"></form-factory>
      </div>
      <div *ngIf="isPursuit" class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroupMeasureSelection"
                      [model]="parentChaseIdInput" (onBlur)="validateChase()"></form-factory>
      </div>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroupMeasureSelection"
                    [model]="projectInput" (onChange)="projectSelectionChange()"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroupMeasureSelection"
                    [model]="productInput"></form-factory>
    </div>
    <div *ngIf="isHeidisProjectSelected || isIVAProjectSelected" class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroupMeasureSelection" [model]="lineOfBusinessInput">
      </form-factory>
    </div>
    <!-- TODO: We will be moving these two inputs to member section in future sprint -->
    <div *ngIf="false" class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroupMeasureSelection" [model]="sampleIdInput">
      </form-factory>
    </div>
    <div *ngIf="false" class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroupMeasureSelection" [model]="sequenceInput">
      </form-factory>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      
      <form-factory [formGroup]="formGroupMeasureSelection"
                    [model]="chaseKeyInput" (onInput)="setDefaultValueForIVA()"></form-factory>
      <div class="label-container" *ngIf="isChaseKeyInvalid">
        <div class="ellipsis text-danger">Chase Key must be unique</div>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isRiskProjectSelected">
      <form [formGroup]="formGroupMeasureSelection">
          <form-factory [formGroup]="formGroupMeasureSelection"
                        [model]="measuresInput"></form-factory>
      </form>
      <div class="measure__section" *ngIf="!isIVAProjectSelected">
        <form [formGroup]="formGroup">
          <form-factory *ngFor="let model of formModels; trackBy: trackByIndex"
                        [formGroup]="formGroup"
                        [model]="model"></form-factory>
        </form>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-button class="ui-md-6 reset-button text-left" text="RESET" (onClick)="reset()"></app-button>
      <app-button class="ui-md-6 process-button" [disabled]="!isShowNextStepButton"
                  (onClick)="nextStepProcess()"
                  text="NEXT STEP"></app-button>
    </div>
  </div>


</div>
