import { Component, Input, TemplateRef } from "@angular/core";
import { GridService } from "../grid.service";
import { GridColumnDefinition } from "../models/grid-column-definition.model";
import { GridConfiguration } from "../models/grid-configuration.model";

// Primeng is forcing us to use an attribute selector.
/* tslint:disable:component-selector */
@Component({
  selector: "[app-grid-body]",
  templateUrl: "./grid-body.component.html",
  styleUrls: [
    "../grid.component.scss",
    "./grid-body.component.scss",
  ],
})
export class GridBodyComponent {


  constructor(private service: GridService) { }

  get filteredColumns(): GridColumnDefinition[] {
    return this.configuration.columns.filter(a => a.show);
  }

  get hasActionButton(): boolean {
    return this.configuration.showActionColumn;
  }

  get hasRowExpansion(): boolean {
    return this.configuration.showRowExpansionColumn;
  }

  get expandedIcon(): string {
    return this.expanded ? "pi pi-chevron-down" : "pi pi-chevron-right";
  }

  get rowDataDisabled(): boolean {
    return this.rowData.disabled;
  }
  @Input() configuration: GridConfiguration;
  @Input() rowData: any;
  @Input() rowIndex: number;
  @Input() actionTemplate: TemplateRef<any>;
  @Input() showSelector: Function;
  @Input() hasSelectedRows = false;
  @Input() expanded: any;
  actionRowData: any;
  S;

  isShowSelector(rowData): boolean {
    return !rowData.disabled;
  }

  getValue(row: any, col: GridColumnDefinition): string {
    const fields = col.field.split(".");
    return this.getPropertyValue(row, col, fields);
  }

  getTitle(row: any, col: GridColumnDefinition): string {
    if (!col.showTitle) {
      return "";
    }
    if (!col.hasTitle) {
      return this.getValue(row, col);
    }

    const titles = col.title.split(".");
    return this.getPropertyValue(row, col, titles);
  }
  private getPropertyValue(row: any, col: GridColumnDefinition, property: any) {
    const value = property.length > 1 ? this.getDeepValue(row, property) : row[property];
    const finalValue = col.hasPipe ? this.service.usePipe(value, col.pipeName, col.format, col.timeZone) : value;
    return finalValue;
  }

  private getDeepValue(row: any, fields: string[]): string {
    return fields.reduce((result, field) => result[field], row);
  }

  generateLink(column: GridColumnDefinition, row: any) {
    return this.service.generateLink(column, row);
  }
  trackByIndex(index, item) {
    return index;
  }
}
/* tslint:enable:component-selector */
