import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../api/member-validation/dynamic-entity-attribute.model";

export class AuditDetail {
  coderClass?: string;
  coderName?: string;
  attributes?: DynamicEntityAttribute[];
  diagnoses?: DynamicEntityAttribute[];

  constructor(options: Partial<AuditDetail> = {}) {
    this.coderClass = StringHelper.clean(options.coderClass);
    this.coderName = StringHelper.clean(options.coderName);
    this.attributes = ArrayHelper.clean(options.attributes);
    this.diagnoses = ArrayHelper.clean(options.diagnoses);

  }
}
