import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription, fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { ActionButton } from "../action-button/action-button.model";
import { DevControllerService } from "./dev-controller.service";

@Component({
  selector: "dev-controller",
  templateUrl: "./dev-controller.component.html",
  styleUrls: ["./dev-controller.component.scss"],
})
export class DevControllerComponent implements OnInit {
  private onMouseMoveSubscription: Subscription;
  open = false;
  xPos = 100;
  yPos = 100;
  controls: ActionButton[];

  constructor(
    private readonly service: DevControllerService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this.service.clear());
    this.service.controls.subscribe(controls => this.controls = controls);
  }


  get hasControls(): boolean {
    return ArrayHelper.isAvailable(this.controls);
  }

  get buttonTitle(): string {
    return this.hasControls
      ? "Click to reveal the development helper."
      : "No functions available. Currently disabled.";
  }

  get styles(): any {
    return {
      top: `${this.yPos}px`,
      left: `${this.xPos}px`,
    };
  }

  openDevController(): void {
    if (this.hasControls) {
      this.open = true;
    }
  }

  stopTrackingMouse(): void {
    if (this.onMouseMoveSubscription) {
      this.onMouseMoveSubscription.unsubscribe();
    }
  }

  startTrackingMouse(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const xDelta = event.clientX - this.xPos;
    const yDelta = event.clientY - this.yPos;
    this.onMouseMoveSubscription = fromEvent<MouseEvent>(document, "mousemove", { passive: true })
      .subscribe(e => {
        e.stopPropagation();
        e.preventDefault();

        setTimeout(() => {
          this.xPos = e.clientX - xDelta;
          this.yPos = e.clientY - yDelta;
        });
      });
  }


  trackByIndex(index, item) {
    return index;
  }
}
