import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../../core/user/user.service";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Switch } from "../../../../../../../dynamic-forms/inputs/switch/switch.model";
import { Resize } from "../../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { OverreadFeedbackDetail } from "../overread-feedback-detail.model";
import { OverreadFeedbackQueryItem } from "../overread-feedback-query-item.model";
import { OverreadFeedbackStatusChange } from "../overread-feedback-status-change.model";
import { OverreadFeedbackStatus } from "../overread-feedback-status.enum";
import { OverreadFeedbackService } from "../overread-feedback.service";

@Component({
  selector: "member-feedback-modal",
  templateUrl: "./feedback-modal.component.html",
  styleUrls: ["./feedback-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chaseId: number;
  @Input() isRisk20 = false;
  @Input() isRiskOrIva: boolean;
  @Input() chaseOverreadDetail: any;
  @Output() onWorkflowStatusToggle = new EventEmitter<boolean>();
  @ViewChild("reviewColumn", { static: true }) reviewColumn: TemplateRef<any>;
  private sink = new SubSink();
  private user: UserToken;
  challengeReasonForm: FormGroup;
  challengeFeedbackForm: FormGroup;
  feedbackForm: FormGroup;
  toggleForm: FormGroup;
  dataEntryToggle: Switch;
  challengeReasonText: Textbox[];
  challengeFeedbackText: Textbox[];
  challengeText: Textarea[];
  gridConfiguration = new GridConfiguration();
  abstractorGridConfiguration = new GridConfiguration();
  overreaderGridConfiguration = new GridConfiguration();
  expandedRows = {};
  gridData: OverreadFeedbackDetail[] = [];
  nextOverreadFeedback: OverreadFeedbackQueryItem[] = [];
  acceptClassIndex: number[] = [];
  challengeClassIndex: number[] = [];
  reviewClassIndex: number[] = [];
  visible = false;
  statusInReview = true;
  abstractorReview = true;
  isGetNextAvailable = true;
  constructor(
    private router: Router,
    private userService: UserService,
    private formService: FormService,
    private overreadFeedbackService: OverreadFeedbackService,
    private readonly messagingService: MessagingService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.createToggleForm();
    this.createGrids();
    this.getOverreadFeedbackData();
  }

  getOverreadFeedbackData() {

    const overreadDetail = this.chaseOverreadDetail.filter(x =>  x.status !== OverreadFeedbackStatus.MANAGER);
    if (ArrayHelper.isAvailable(overreadDetail) && this.reviewsHaveBeenCompleted(overreadDetail)) {
      this.statusInReview = false;

      if (overreadDetail[0].abstractorUserId === this.user.userId) {
        const abstractorGridData = overreadDetail.filter(x => x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED);
        this.initializeGridData(abstractorGridData);
      } else if (overreadDetail[0].overreaderUserId === this.user.userId) {
        this.abstractorReview = false;
        const overreaderGridData = overreadDetail.filter(x => x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED);
        this.initializeGridData(overreaderGridData);
      }
    } else if (ArrayHelper.isAvailable(overreadDetail) && overreadDetail.filter(x => x.status === OverreadFeedbackStatus.ABSTRACTOR).length > 0) {
      const abstractorGridData = overreadDetail.filter(x => x.status === OverreadFeedbackStatus.ABSTRACTOR);
      this.initializeGridData(abstractorGridData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chaseId?.previousValue && changes.chaseId.previousValue !== changes.chaseId.currentValue) {
      this.visible = false;
      this.getOverreadFeedbackData();
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onToggle(event: boolean): void {
    this.onWorkflowStatusToggle.emit(event);
  }

  onAccept(event: MouseEvent, id: number): void {
    event.stopPropagation();
    const request = new OverreadFeedbackStatusChange({
      chaseOverreadFeedbackId: id,
      overreadFeedbackStatus: OverreadFeedbackStatus.COMPLETED,
      abstractorReceiptStatus: OverreadFeedbackStatus.RECEIVED,
    });

    this.toggleActiveButton(id);
    this.closeRow(id);
    this.removeGridRow(id);
    this.updateStatusChange(request);
  }

  onChallenge(event: MouseEvent, id: number): void {
    event.stopPropagation();
    this.toggleChallengeButton(id);
  }

  onChallengeSubmit(id: number): void {
    const request = new OverreadFeedbackStatusChange({
      chaseOverreadFeedbackId: id,
      overreadFeedbackStatus: OverreadFeedbackStatus.MANAGER,
      commentText: this.feedbackForm.get(id.toString()).value,
      abstractorReceiptStatus: OverreadFeedbackStatus.RECEIVED,
    });

    this.closeRow(id);
    this.removeGridRow(id);
    this.updateStatusChange(request);
  }

  onAcknowledgeSubmit(id: number): void {
    const request = new OverreadFeedbackStatusChange({
      chaseOverreadFeedbackId: id,
    });

    if (this.abstractorReview) {
      request.abstractorReceiptStatus = OverreadFeedbackStatus.RECEIVED;
    } else {
      request.overreaderReceiptStatus = OverreadFeedbackStatus.RECEIVED;
    }

    this.closeRow(id);
    this.removeGridRow(id);
    this.updateStatusChange(request);
  }

  onClose(): void {
    this.visible = false;
  }

  onGetNext(): void {
    const getNextChase = {
      getNextFilter: 1,
      startRecord: 1,
      endRecord: 1,
    };
    this.overreadFeedbackService.getOverreadFeedbackList(getNextChase).subscribe(nextOverreadFeedback => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (ArrayHelper.isAvailable(nextOverreadFeedback)) {
        this.router.navigate(["members", "chase", nextOverreadFeedback[0].chaseId]);
      } else {
        this.isGetNextAvailable = false;
        const errorMessage = "All overread feedback chases have been completed.";
        this.messagingService.showToast(errorMessage, SeverityType.ERROR);
        this.changeDetector.markForCheck();
      }
    });
  }

  onOpenReview(event: MouseEvent, id: number): void {
    event.stopPropagation();
    this.toggleReviewButton(id);
  }

  acceptButtonClass(id: number): string {
    return (this.acceptClassIndex.indexOf(id) > -1) ? "accept-btn--active" : "accept-btn";
  }

  challengeButtonClass(id: number): string {
    return (this.challengeClassIndex.indexOf(id) > -1) ? "challenge-btn--active" : "challenge-btn";
  }

  reviewButtonClass(id: number): string {
    return (this.reviewClassIndex.indexOf(id) > -1) ? "review-btn--active" : "review-btn";
  }

  rowModel(id: number): Textarea {
    return this.challengeText.find(item => item.key === id.toString());
  }

  challengeReasonRowModel(id: number): Textbox {
    return this.challengeReasonText.find(item => item.key === id.toString());
  }

  challengeFeedbackModel(id: number): Textbox {
    return this.challengeFeedbackText.find(item => item.key === id.toString());
  }

  isRowModelValid(id: number): boolean {
    return this.feedbackForm.get(id.toString()).valid && this.challengeClassIndex.includes(id);
  }

  getTemplateData(rowData: string): any[] {
    const templateData = [];
    rowData.split("CHAR(13)+CHAR(10)").map(gridData => {
      templateData.push({ gridData });
    });

    return templateData;
  }

  onRowClick(event: OverreadFeedbackDetail): void {
    this.expandedRows[event.chaseOverreadFeedbackId] ? this.closeRow(event.chaseOverreadFeedbackId) : this.openRow(event.chaseOverreadFeedbackId);
  }


  get feedbackCompleted(): boolean {
    return this.gridData.length === 0;
  }

  private initializeGridData(gridData: OverreadFeedbackDetail[]): void {
    if (ArrayHelper.isAvailable(gridData)) {
      if (this.isRiskOrIva) {
        gridData.forEach(element => {
          element.abstractorData = element.abstractorData.replace(new RegExp("\n", "g"), "\n\n");
          if (StringHelper.isAvailable(element.abstractorData)) {
            element.abstractorData = `1. ${element.abstractorData}`;
            let numberedData = "";
            element.abstractorData.split("\n\n").forEach((splitData, i) => {
              if (i) {
                numberedData += `\n\n${(i + 1)}. ${splitData}`;
              } else {
                numberedData = splitData;
              }
            });
            element.abstractorData = numberedData;
          }
          element.overreaderData = element.overreaderData.replace(new RegExp("\n", "g"), "\n\n");
          if (StringHelper.isAvailable(element.overreaderData)) {
            element.overreaderData = `1. ${element.overreaderData}`;
            let numberedData = "";
            element.overreaderData.split("\n\n").forEach((splitData, i) => {
              if (i) {
                numberedData += `\n\n${(i + 1)}. ${splitData}`;
              } else {
                numberedData = splitData;
              }
            });
            element.overreaderData = numberedData;
          }
        });
      }
      this.gridData = gridData;
      this.visible = true;
      this.createGridForm();
      this.openRow(gridData[0].chaseOverreadFeedbackId);
      this.changeDetector.markForCheck();
    }
  }

  getIvaRiskStyle(data: string) {
    return !StringHelper.isAvailable(data) ? "none" : "";
  }

  private removeGridRow(id: number): void {
    const index = this.gridData.findIndex(item => item.chaseOverreadFeedbackId === id);
    this.gridData.splice(index, 1);
    this.changeDetector.markForCheck();

    if (ArrayHelper.isAvailable(this.gridData)) {
      this.openRow(this.gridData[0].chaseOverreadFeedbackId);
    }
  }

  private openRow(id: number): void {
    this.expandedRows[id] = true;
  }

  private closeRow(id: number): void {
    this.expandedRows[id] = false;
  }

  private toggleActiveButton(id: number): void {
    if (this.acceptClassIndex.indexOf(id) === -1) {
      this.acceptClassIndex.push(id);
    }
    if (this.challengeClassIndex.indexOf(id) > -1) {
      const index = this.challengeClassIndex.indexOf(id);
      this.challengeClassIndex.splice(index, 1);
    }
  }

  private toggleChallengeButton(id: number): void {
    if (this.challengeClassIndex.indexOf(id) === -1) {
      this.challengeClassIndex.push(id);
    }
    if (this.acceptClassIndex.indexOf(id) > -1) {
      const index = this.acceptClassIndex.indexOf(id);
      this.acceptClassIndex.splice(index, 1);
    }
  }

  private toggleReviewButton(id: number): void {
    if (this.reviewClassIndex.indexOf(id) === -1) {
      this.reviewClassIndex.push(id);
      this.openRow(id);
    } else if (this.reviewClassIndex.indexOf(id) > -1) {
      const index = this.reviewClassIndex.indexOf(id);
      this.reviewClassIndex.splice(index, 1);
      this.closeRow(id);
    }
  }

  private createGrids(): void {
    this.gridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "entityTypeName", header: this.isRiskOrIva ? "Feedback" : "Event Type", width: "75%" }),
        new GridColumnDefinition({ template: this.reviewColumn, isSortableColumn: false, header: this.statusInReview ? "Acceptance" : "Feedback", width: "25%" }),
      ],
      pageSize: 100,
      rowExpandMode: "multiple",
      showActionColumn: false,
      showMenu: false,
      trackByField: "chaseOverreadFeedbackId",
      expandedRows: this.expandedRows,
      hasPagination: false,
    });

    this.abstractorGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "gridData", header: this.isRiskOrIva ? "Coder Entered" : "Abstractor Entered", isSortableColumn: false }),
      ],
      pageSize: 100,
      showActionColumn: false,
      showMenu: false,
      hasPagination: false,
    });

    this.overreaderGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "gridData", header: "Overreader Entered", isSortableColumn: false }),
      ],
      pageSize: 100,
      showActionColumn: false,
      showMenu: false,
      hasPagination: false,
    });
  }

  private createToggleForm(): void {
    this.dataEntryToggle = new Switch({
      key: "switch",
      label: "Show Original Data Entry",
    });

    this.toggleForm = this.formService.createFormGroup([this.dataEntryToggle]);
  }

  private createGridForm(): void {
    this.challengeReasonText = this.gridData.map(item => new Textbox({
      key: (item.chaseOverreadFeedbackId).toString(),
      label: this.isRiskOrIva ? "REASON FOR CHALLENGE" : "REASON FOR CHALLENGE (include page number)",
      value: item.abstractorComment,
      readonly: true,
    }));

    this.challengeFeedbackText = this.gridData.map(item => new Textbox({
      key: (item.chaseOverreadFeedbackId).toString(),
      label: "MANAGER FEEDBACK",
      value: item.managerComment,
      readonly: true,
    }));

    this.challengeText = this.gridData.map(item => new Textarea({
      key: (item.chaseOverreadFeedbackId).toString(),
      label: "REASON FOR CHALLENGE (include page number)",
      placeholder: "Type Here",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(280)],
      errorMessages: {
        required: "Write a description between 4 - 280 characters.",
        minlength: "Write a description between 4 - 280 characters.",
        maxlength: "Write a description between 4 - 280 characters.",
      },
    }));

    this.challengeReasonForm = this.formService.createFormGroup(this.challengeReasonText);
    this.challengeFeedbackForm = this.formService.createFormGroup(this.challengeFeedbackText);
    this.feedbackForm = this.formService.createFormGroup(this.challengeText);
  }

  private updateStatusChange(request: OverreadFeedbackStatusChange): void {
    this.overreadFeedbackService.updateOverreadFeedbackStatus(request).subscribe(() => {
      this.messagingService.showToast(`Updated successfully.`, SeverityType.SUCCESS);
    });
  }

  private reviewsHaveBeenCompleted(overreadDetail: any): boolean {
    let reviewsHaveBeenCompleted = true;
    overreadDetail.forEach(detail => {
      if (detail.status !== OverreadFeedbackStatus.COMPLETED) {
        reviewsHaveBeenCompleted = false;
      }
    });
    return reviewsHaveBeenCompleted;
  }
}
