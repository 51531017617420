import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { List } from "immutable";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BasicGridComponent } from "../../../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ListItem } from "../../../../../../../shared/list/list-item";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { ProjectConfiguration } from "../../../../../project/project-config/project-configuration.model";
import { ChaseDetailState } from "../../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { Encounter } from "../encounter/encounter.model";
import { RiskEntity } from "../risk-entity.model";
import { RiskSystemResultKey } from "../risk-system-result-key/risk-system-result-key.model";
import { RiskSystemResultComponent } from "../risk-system-result/risk-system-result.component";
import { RiskService } from "../risk.service";
import { DeleteEncounterComponent } from "./delete-encounter.component";
import { ExemptEncounterComponent } from "./exempt-encounter.component";

@Component({
  selector: "member-risk-encounter-grid",
  templateUrl: "./encounter-grid.component.html",
  styleUrls: ["./encounter-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild("deleteButton", { static: true }) deleteButton: TemplateRef<DeleteEncounterComponent>;
  @ViewChild("exemptColumn", { static: true }) exemptColumn: TemplateRef<ExemptEncounterComponent>;
  @ViewChild("systemColumn", { static: true }) systemColumn: TemplateRef<RiskSystemResultComponent>;
  @ViewChild("encounterGrid") encounterGrid: BasicGridComponent;
  gridConfiguration = new GridConfiguration();
  chaseDetailState: ChaseDetailState;
  encounters: Encounter[];
  enableMrrBot = "";
  projectConfiguration: ProjectConfiguration;
  selectedEncounter: RiskEntity;
  selection: Encounter;
  systemResultKeys: RiskSystemResultKey[];

  constructor(
    private readonly riskService: RiskService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly automapper: AutomapperService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        this.chaseDetailState = chaseDetailState;
        this.encounters = this.automapper.mapMany("RiskData", "Encounter", riskState.encounters);
        this.encounters.forEach(a => a.setProviderName(riskState));
        this.selection = riskState.hasSelectedEncounterIndex ? this.encounters[riskState.selectedEncounterIndex] : {} as any;

        if (this.encounterGrid != null) {
          this.encounterGrid.setPageByIndex(riskState.selectedEncounterIndex);
        }

        this.projectConfiguration = chaseDetailState.projectConfiguration;
        this.updateProjectConfiguration();
        if (this.chaseDetailState?.chaseId) {
          this.gridConfiguration = this.getGridConfiguration();
        }
        this.changeDetector.markForCheck();
      })
    );
    this.gridConfiguration = this.getGridConfiguration();
    this.setSystemResultKey();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get isShowNlpComponent(): boolean {
    return this.chaseDetailState.isMrr && this.isEnableMrrBot && this.chaseDetailState.isMeasureCodesToEnableNlp;
  }

  get headerStatistics(): List<ListItem> {
    const headerStats = ([
      new ListItem({ key: "Total", value: this.totalEncounters.toString() }),
      new ListItem({ key: "Admin", value: this.adminEncounters.toString() }),
      new ListItem({ key: "Coder", value: this.addedEncounters.toString() }),
    ]);

    if (this.isShowNlpComponent) {
      headerStats.push(
        new ListItem({ key: "Bot", value: this.addedBotEncounters.toString() })
      );
    }

    return List(headerStats);
  }


  getGridConfiguration(): GridConfiguration {
    const encounterColumns = [
      new GridColumnDefinition({ header: "Claim ID", field: "claimId", width: "100px" }),
      new GridColumnDefinition({ header: "DOS", field: "dosRange", width: "150px" }),
      new GridColumnDefinition({ header: "Provider", field: "provider" }),
      new GridColumnDefinition({ header: "Enc", field: "encYn", width: "50px" }),
      new GridColumnDefinition({ header: "", template: this.systemColumn, isSortableColumn: false, alignment: "right", width: "45px" }),
      new GridColumnDefinition({ header: "Source", field: "encounterSource" }),
      new GridColumnDefinition({ header: "Diagnosis", field: "diagnosisCount", width: "50px" }),
      new GridColumnDefinition({ header: "", template: this.deleteButton, isSortableColumn: false, width: "45px" }),
    ];

    if (this.chaseDetailState.isOverread) {
      encounterColumns.splice(
        encounterColumns.length - 1,
        0,
        new GridColumnDefinition({ header: "Exempt", template: this.exemptColumn, isSortableColumn: false, width: "100px" })
      );
    }

    return new GridConfiguration({
      columns: encounterColumns,
      pageSize: 10,
      showActionColumn: false,
      showMenu: false,
      selectionMode: "single",
      showSelectionControl: false,
    });
  }

  get isEnableMrrBot(): boolean {
    return this.enableMrrBot === "1";
  }

  setSelectedEncounterIndex(): void {
    if (this.selection) {
      const index = this.riskService.data.value.encounters.findIndex(encounter => encounter.id === this.selection.id);
      this.riskService.setSelectedEncounterIndex(index);
    }
  }

  private setSystemResultKey(): void {
    this.systemResultKeys = [
      new RiskSystemResultKey({ className: "result-icon__match", resultKeyText: "BOT MATCH" }),
      new RiskSystemResultKey({ className: "result-icon__no-match", resultKeyText: "BOT NO MATCH" }),
    ];
  }

  private updateProjectConfiguration(): void {
    if (this.projectConfiguration != null) {
      this.enableMrrBot =
        StringHelper.isAvailable(this.projectConfiguration.enableMrrBot) ? this.projectConfiguration.enableMrrBot : "0";
    }
  }

  private get totalEncounters(): number {
    return this.encounters.length;
  }

  private get adminEncounters(): number {
    return this.encounters.filter(option => option.isAdmin).length;
  }

  private get addedEncounters(): number {
    return this.encounters.filter(option => option.isManuallyAddedSource).length;
  }

  private get addedBotEncounters(): number {
    return this.encounters.filter(option => option.isBotSource).length;
  }

}
