import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { Contact } from "../../../contact.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailService } from "../../address-detail.service";
import { AddressDetailInfoEditService } from "../address-detail-info-edit/address-detail-info-edit.service";

@Injectable()
export class AddressDetailInfoContactService {

  private pAddressContact = new BehaviorSubject<Contact>(null);
  addressContactChanged$ = this.pAddressContact.asObservable();

  constructor(
    private readonly addressDetailService: AddressDetailService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly addressDetailInfoEditService: AddressDetailInfoEditService) {
  }

  setAsPrimaryContact(masterDocumentSourceId: number, contact: Contact): void {
    this.addressDetailInfoEditService.updatePrimaryContact(masterDocumentSourceId, contact)
      .pipe(
        mergeMap(_ => this.addressDetailService.getAddressDetail(masterDocumentSourceId)),
        map(addressDetail => this.addressDetailStateService.setData({ contacts: addressDetail?.contacts, contact, hasPrimaryContact: addressDetail?.hasPrimaryContact })))
      .subscribe();
  }

  refreshContactList(masterDocumentSourceId: number): void {
    this.addressDetailService.getAddressDetail(masterDocumentSourceId)
      .subscribe(detail => this.addressDetailStateService.setData({ contacts: detail?.contacts }));
  }

  setAddressContact(value: Contact): void {
    this.pAddressContact.next(value);
  }
}
