import { IAutoMapper } from "../../../core/automapper/interfaces";
import { AnnotationType } from "../../modules/data-load/annotationtype.enum";

export const mapAnnotation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Annotation")
    .forMember("annotationId", o => o.annotationId)
    .forMember("callerUserId", o => o.callerUserId)
    .forMember("callerUserName", o => o.callerUserName)
    .forMember("canDelete", o => o.canDelete)
    .forMember("canEdit", o => o.canEdit)
    .forMember("chaseId", o => o.chaseId)
    .forMember("createDateTime", o => o.createDateTime)
    .forMember("highlightId", o => o.highlightId)
    .forMember("isEdited", o => o.isEdited)
    .forMember("documentPageId", o => o.documentPageId)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("text", o => o.text)
    .forMember("pageOrDataload", o => o.pageOrDataload)
    .forMember("annotationDBId", o => o.annotationDBId)
    .forMember("annotationTypeId", o => o.annotationTypeId)
    .forMember("entityId", o => o.entityId);
};
export class Annotation {

  annotationId: string;
  callerUserId: number;
  callerUserName: string;
  canDelete: boolean;
  canEdit: boolean;
  chaseId: number;
  createDateTime: Date;
  documentPageId: number;
  highlightId: string;
  isEdited: boolean;
  pageNumber: number;
  text: string;
  pageOrDataload: string;
  annotationDBId: number;
  annotationTypeId: number;
  entityId: number;

  constructor(options: Partial<Annotation>) {
    this.annotationId = options.annotationId;
    this.annotationDBId = options.annotationDBId;
    this.entityId = options.entityId;
    this.annotationTypeId = options.annotationTypeId;
    this.callerUserId = options.callerUserId;
    this.callerUserName = options.callerUserName;
    this.canDelete = options.canDelete;
    this.canEdit = options.canEdit;
    this.chaseId = options.chaseId;
    this.createDateTime = options.createDateTime;
    this.documentPageId = options.documentPageId;
    this.highlightId = options.highlightId;
    this.isEdited = options.isEdited;
    this.pageNumber = options.pageNumber;
    this.text = options.text;
    this.pageOrDataload = options.annotationTypeId === AnnotationType.ByClient ? "Admin" : `PAGE: ${options.pageNumber}`;
  }
}
