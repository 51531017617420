<div class="username__container" [ngClass]="activeClass" (click)="popover.toggle($event)">
  <span class="username__container--orgName">{{ organizationName }}</span> : <span class="username__container--loginName">{{ username }}</span>
</div>

<app-popover #popover [isPinned]="false" class="user-popup">
  <div class="users-list__content">
    <platform-single-sign-in></platform-single-sign-in>
  </div>
    <div [ngClass]="getMenuContainerClass(organizationName)">
    <!-- TODO: Create a Menu Item Component OR... Is there a shared copmonent -->
    <!-- TODO: Continued - Checkout primeng MegaMenu -->
    <div class="user-circle" [ngClass]="backgroundClass" (click)="gotoUser()">
      <div class="circle-text">
        <span class="initial-name">{{ initialUserFirstName }}</span>
        <span class="initial-name">{{ initialUserLastName }}</span>
      </div>
    </div>
    <a href="user">
      View Profile
    </a>
    <div [ngClass]="getOrgNameTextClass(organizationName)">
      <div class="username__container--orgName">{{ organizationName }}</div>
      <div [ngClass]="getUserNameTextClass()">{{ username }}</div>
    </div>
    <div class="menu__container--sign-out">
      <app-button
                  text="SIGN OUT"
                  (onClick)="logout()">
      </app-button>
    </div>
  </div>
</app-popover>
