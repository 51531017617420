import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { BooleanHelper } from "../../utilities/contracts/boolean-helper";

@Component({
  selector: "app-privacy-policy-modal",
  templateUrl: "./privacy-policy-modal.component.html",
  styleUrls: ["./privacy-policy-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyModalComponent {
  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  @Output() onShow: EventEmitter<null> = new EventEmitter(true);
  @Output() onHide: EventEmitter<null> = new EventEmitter(true);


  show(): void {
    this.onShow.emit();
    this.visible = true;
    this.change();
  }

  hide(): void {
    this.onHide.emit();
    this.visible = false;
    this.change();
  }

  change(event: boolean = null): void {
    const visible = BooleanHelper.tryGet(event, this.visible);
    this.visibleChange.emit(visible);
  }
}
