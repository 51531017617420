<app-button classes="transparent small" icon="trash" [focusable]="false" styleType="pi" type="pi"
    (onClick)="showDeleteEncounterModal($event)">
</app-button>

<app-confirmation-modal 
  [(visible)]="isConfirmDeleteVisible"
  header="WARNING"
  acceptText="DELETE"
  rejectText="CANCEL" 
  (onYes)="deleteEncounter()">
  <b>Are you sure you want to delete this encounter?</b>
</app-confirmation-modal>