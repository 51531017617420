import { IAutoMapper } from "../../../core/automapper/interfaces";

export const mapBoundingBox = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "BoundingBox")
    .forMember("x", o => o.x)
    .forMember("y", o => o.y);

};

export class BoundingBox {
  readonly x: number;
  readonly y: number;

  constructor(options: BoundingBox) {
    this.x = options.x;
    this.y = options.y;
  }
}

