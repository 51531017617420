import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class AnalyticsRequest {
  readonly projectIdsAsCsv?: string;
  readonly measureIdsAsCsv?: string;
  readonly productName?: string;
  readonly addressId?: string;
  readonly city?: string;
  readonly state?: string;
  readonly addressGroup?: string;
  readonly hccAsCsv?: string;
  readonly dashboardType?: number;
  readonly memberId?: string;
  readonly memberFirstName?: string;
  readonly memberLastName?: string;
  readonly memberDob?: string;
  readonly memberDobFrom?: string;
  readonly memberDobTo?: string;
  readonly memberGender?: string;
  readonly memberKey?: string;
  readonly npi?: string;
  readonly chaseId?: string;
  readonly userFirstName?: string;
  readonly userLastName?: string;
  readonly diagnosisCode?: string;
  readonly dateOfService?: string;
  readonly vrcCode?: string;
  readonly gapsAsCsv?: string;
  readonly abstractionToDate?: string;
  readonly abstractionFromDate?: string;
  readonly overreadToDate?: string;
  readonly overreadFromDate?: string;
  readonly overread2FromDate?: string;
  readonly overread2ToDate?: string;
  readonly pendStatusCsv?: string;
  readonly pendCodeCsv?: string;
  readonly pendTypeIdCsv?: string;
  readonly pendToDate?: string;
  readonly pendFromDate?: string;
  readonly responseDateFrom?: string;
  readonly responseDateTo?: string;
  readonly coderId?: string;
  readonly coderName?: string;
  readonly overReaderId?: string;
  readonly overReaderName?: string;
  readonly codingDate?: string;
  readonly overReadDate?: string;
  readonly reportChaseID?: string;
  readonly conditionCategoryAsCsv?: string;
  readonly chartReceivedDateFrom?: string;
  readonly chartReceivedDateTo?: string;
  readonly updateFromDate?: string;
  readonly updateToDate?: string;
  readonly completionFromDate?: string;
  readonly completionToDate?: string;
  readonly workflowStatusCsv?: string;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly contactFromDate?: string;
  readonly contactToDate?: string;
  readonly retrievalFromDate?: string;
  readonly retrievalToDate?: string;
  readonly retrievalTypeAsCsv?: string;
  readonly deFromDate?: string;
  readonly deToDate?: string;
  readonly fileCreateFromDate?: string;
  readonly fileCreateToDate?: string;
  readonly contactMethodTypeAsCsv?: string;
  readonly contactResultTypeAsCsv?: string;
  readonly loadStartDate?: string;
  readonly loadEndDate?: string;
  readonly dataLoadStartDate?: string;
  readonly dataLoadEndDate?: string;
  readonly dateOfServiceFromDate?: string;
  readonly dateOfServiceToDate?: string;
  readonly chaseStatusAsCsv?: string;
  readonly reportedByEdge?: string;
  readonly enrolleeId?: string;
  readonly clientMemberId?: string;
  readonly claimId?: string;
  readonly dispensingProviderId?: string;
  readonly ndc?: string;
  readonly hcpcs?: string;
  readonly hiosAsCsv?: string;
  readonly stratumLevelAsCsv?: string;
  readonly rxcAsCsv?: string;
  readonly submissionStatusAsCsv?: string;
  readonly hccPositionAsCsv?: string;
  readonly serviceOrgIdsAsCsv?: string;
  readonly businessDateFrom?: string;
  readonly businessDateTo?: string;
  readonly benefitYear?: string;
  readonly dateRangeFrom?: string;
  readonly dateRangeTo?: string;
  readonly clientIdsAsCsv?: string;
  readonly billingToDate?: string;
  readonly billingFromDate?: string;
  readonly createFromDate?: string;
  readonly createToDate?: string;
  readonly numeratorCodesAsCsv?: string;
  readonly lineOfBusinessAsCsv?: string;
  readonly complianceCodesAsCsv?: string;
  readonly healthPlanNamesAsCsv: string;
  readonly contractNumbersAsCsv: string;
  readonly pendOwnerAsCsv: string;
  readonly paymentYear?: string;
  readonly serviceYear?: string;
  readonly rafCalcSource?: string;
  readonly retrievalOwner?: string;
  readonly hideFilters?: string[];
  readonly sampleComplianceCodesAsCsv?: string;
  readonly documentQueueId?: string;
  readonly documentStatusAsCsv?: string;
  readonly confirmationNumber?: string;
  readonly providerGatewayPin?: string;
  readonly contactMethodAsCsv?: string;
  readonly contactMethodStatus?: string;
  readonly contactStartDate?: string;
  readonly contactEndDate?: string;
  readonly expectedRetrieval?: string;
  readonly projectStatus?: string;
  readonly vendorName?: string;
  readonly specialHandling?: string;
  readonly moveStartDate?: string;
  readonly moveEndDate?: string;
  readonly abstractionBy?: string;
  readonly pendBy?: string;
  readonly movebackBy?: string;
  readonly overread2By?: string;
  readonly codedBy?: string;
  readonly updatedBy?: string;
  readonly userName?: string;
  readonly retrievedDateStart?: string;
  readonly retrievedDateEnd?: string;
  readonly movebackDateStart?: string;
  readonly movebackDateEnd?: string;
  readonly chaseTags?: string;
  readonly dateRangeStart?: string;
  readonly dateRangeEnd?: string;
  readonly login?: string;
  readonly userEmail?: string;
  readonly userStatus?: string;
  readonly currentChaseStatus?: string;
  readonly currentProcessStep?: string;
  readonly qaDateFrom?: string;
  readonly qaDateTo?: string;
  readonly vendorInvoiceType?: string;
  readonly pendTypeAsCsv: string;

  constructor(options: Partial<AnalyticsRequest> = {}) {
    this.projectIdsAsCsv = StringHelper.clean(options.projectIdsAsCsv);
    this.measureIdsAsCsv = StringHelper.clean(options.measureIdsAsCsv);
    this.productName = StringHelper.clean(options.productName);
    this.addressId = StringHelper.clean(options.addressId);
    this.city = StringHelper.clean(options.city);
    this.state = StringHelper.clean(options.state);
    this.addressGroup = StringHelper.clean(options.addressGroup);
    this.hccAsCsv = StringHelper.clean(options.hccAsCsv);
    this.dashboardType = options.dashboardType;
    this.memberId = StringHelper.clean(options.memberId);
    this.memberFirstName = StringHelper.clean(options.memberFirstName);
    this.memberLastName = StringHelper.clean(options.memberLastName);
    this.memberDob = StringHelper.clean(options.memberDob);
    this.memberDobFrom = StringHelper.clean(options.memberDobFrom);
    this.memberDobTo = StringHelper.clean(options.memberDobTo);
    this.memberGender = StringHelper.clean(options.memberGender);
    this.memberKey = StringHelper.clean(options.memberKey);
    this.npi = StringHelper.clean(options.npi);
    this.chaseId = StringHelper.clean(options.chaseId);
    this.userFirstName = StringHelper.clean(options.userFirstName);
    this.userLastName = StringHelper.clean(options.userLastName);
    this.diagnosisCode = StringHelper.clean(options.diagnosisCode);
    this.dateOfService = StringHelper.clean(options.dateOfService);
    this.vrcCode = StringHelper.clean(options.vrcCode);
    this.gapsAsCsv = StringHelper.clean(options.gapsAsCsv);
    this.abstractionToDate = StringHelper.clean(options.abstractionToDate);
    this.abstractionFromDate = StringHelper.clean(options.abstractionFromDate);
    this.overreadToDate = StringHelper.clean(options.overreadToDate);
    this.overreadFromDate = StringHelper.clean(options.overreadFromDate);
    this.pendStatusCsv = StringHelper.clean(options.pendStatusCsv);
    this.pendCodeCsv = StringHelper.clean(options.pendCodeCsv);
    this.pendTypeIdCsv = StringHelper.clean(options.pendTypeIdCsv);
    this.pendToDate = StringHelper.clean(options.pendToDate);
    this.pendFromDate = StringHelper.clean(options.pendFromDate);
    this.responseDateFrom = StringHelper.clean(options.responseDateFrom);
    this.responseDateTo = StringHelper.clean(options.responseDateTo);
    this.coderId = StringHelper.clean(options.coderId);
    this.coderName = StringHelper.clean(options.coderName);
    this.overReaderId = StringHelper.clean(options.overReaderId);
    this.overReaderName = StringHelper.clean(options.overReaderName);
    this.codingDate = StringHelper.clean(options.codingDate);
    this.overReadDate = StringHelper.clean(options.overReadDate);
    this.reportChaseID = StringHelper.clean(options.reportChaseID);
    this.conditionCategoryAsCsv = StringHelper.clean(options.conditionCategoryAsCsv);
    this.chartReceivedDateFrom = StringHelper.clean(options.chartReceivedDateFrom);
    this.chartReceivedDateTo = StringHelper.clean(options.chartReceivedDateTo);
    this.updateFromDate = StringHelper.clean(options.updateFromDate);
    this.updateToDate = StringHelper.clean(options.updateToDate);
    this.completionFromDate = StringHelper.clean(options.completionFromDate);
    this.completionToDate = StringHelper.clean(options.completionToDate);
    this.workflowStatusCsv = StringHelper.clean(options.workflowStatusCsv);
    this.startDate = StringHelper.clean(options.startDate);
    this.endDate = StringHelper.clean(options.endDate);
    this.contactFromDate = StringHelper.clean(options.contactFromDate);
    this.contactToDate = StringHelper.clean(options.contactToDate);
    this.retrievalFromDate = StringHelper.clean(options.retrievalFromDate);
    this.retrievalToDate = StringHelper.clean(options.retrievalToDate);
    this.retrievalTypeAsCsv = StringHelper.clean(options.retrievalTypeAsCsv);
    this.deFromDate = StringHelper.clean(options.deFromDate);
    this.deToDate = StringHelper.clean(options.deToDate);
    this.fileCreateFromDate = StringHelper.clean(options.fileCreateFromDate);
    this.fileCreateToDate = StringHelper.clean(options.fileCreateToDate);
    this.contactMethodTypeAsCsv = StringHelper.clean(options.contactMethodTypeAsCsv);
    this.contactResultTypeAsCsv = StringHelper.clean(options.contactResultTypeAsCsv);
    this.loadStartDate = StringHelper.clean(options.loadStartDate);
    this.loadEndDate = StringHelper.clean(options.loadEndDate);
    this.dataLoadStartDate = StringHelper.clean(options.dataLoadStartDate);
    this.dataLoadEndDate = StringHelper.clean(options.dataLoadEndDate);
    this.dateOfServiceFromDate = StringHelper.clean(options.dateOfServiceFromDate);
    this.dateOfServiceToDate = StringHelper.clean(options.dateOfServiceToDate);
    this.chaseStatusAsCsv = StringHelper.clean(options.chaseStatusAsCsv);
    this.reportedByEdge = StringHelper.clean(options.reportedByEdge);
    this.enrolleeId = StringHelper.clean(options.enrolleeId);
    this.clientMemberId = StringHelper.clean(options.clientMemberId);
    this.claimId = StringHelper.clean(options.claimId);
    this.dispensingProviderId = StringHelper.clean(options.dispensingProviderId);
    this.ndc = StringHelper.clean(options.ndc);
    this.hcpcs = StringHelper.clean(options.hcpcs);
    this.hiosAsCsv = StringHelper.clean(options.hiosAsCsv);
    this.stratumLevelAsCsv = StringHelper.clean(options.stratumLevelAsCsv);
    this.rxcAsCsv = StringHelper.clean(options.rxcAsCsv);
    this.submissionStatusAsCsv = StringHelper.clean(options.submissionStatusAsCsv);
    this.hccPositionAsCsv = StringHelper.clean(options.hccPositionAsCsv);
    this.serviceOrgIdsAsCsv = StringHelper.clean(options.serviceOrgIdsAsCsv);
    this.businessDateFrom = StringHelper.clean(options.businessDateFrom);
    this.businessDateTo = StringHelper.clean(options.businessDateTo);
    this.benefitYear = StringHelper.clean(options.benefitYear);
    this.dateRangeFrom = StringHelper.clean(options.dateRangeFrom);
    this.dateRangeTo = StringHelper.clean(options.dateRangeTo);
    this.clientIdsAsCsv = StringHelper.clean(options.clientIdsAsCsv);
    this.overread2ToDate = StringHelper.clean(options.overread2ToDate);
    this.overread2FromDate = StringHelper.clean(options.overread2FromDate);
    this.billingToDate = StringHelper.clean(options.billingToDate);
    this.billingFromDate = StringHelper.clean(options.billingFromDate);
    this.createFromDate = StringHelper.clean(options.createFromDate);
    this.createToDate = StringHelper.clean(options.createToDate);
    this.numeratorCodesAsCsv = StringHelper.clean(options.numeratorCodesAsCsv);
    this.lineOfBusinessAsCsv = StringHelper.clean(options.lineOfBusinessAsCsv);
    this.complianceCodesAsCsv = StringHelper.clean(options.complianceCodesAsCsv);
    this.healthPlanNamesAsCsv = StringHelper.clean(options.healthPlanNamesAsCsv);
    this.contractNumbersAsCsv = StringHelper.clean(options.contractNumbersAsCsv);
    this.pendOwnerAsCsv = StringHelper.clean(options.pendOwnerAsCsv);
    this.paymentYear = StringHelper.clean(options.paymentYear);
    this.serviceYear = StringHelper.clean(options.serviceYear);
    this.rafCalcSource = StringHelper.clean(options.rafCalcSource);
    this.retrievalOwner = StringHelper.clean(options.retrievalOwner);
    this.hideFilters = ArrayHelper.clean(options.hideFilters);
    this.sampleComplianceCodesAsCsv = StringHelper.clean(options.sampleComplianceCodesAsCsv);
    this.documentQueueId = StringHelper.clean(options.documentQueueId);
    this.confirmationNumber = StringHelper.clean(options.confirmationNumber);
    this.providerGatewayPin = StringHelper.clean(options.providerGatewayPin);
    this.documentStatusAsCsv = StringHelper.clean(options.documentStatusAsCsv);
    this.contactMethodAsCsv = StringHelper.clean(options.contactMethodAsCsv);
    this.contactMethodStatus = StringHelper.clean(options.contactMethodStatus);
    this.contactStartDate = StringHelper.clean(options.contactStartDate);
    this.contactEndDate = StringHelper.clean(options.contactEndDate);
    this.expectedRetrieval = StringHelper.clean(options.expectedRetrieval);
    this.projectStatus = StringHelper.clean(options.projectStatus);
    this.vendorName = StringHelper.clean(options.vendorName);
    this.specialHandling = StringHelper.clean(options.specialHandling);
    this.moveStartDate = StringHelper.clean(options.moveStartDate);
    this.moveEndDate = StringHelper.clean(options.moveEndDate);
    this.abstractionBy = StringHelper.clean(options.abstractionBy);
    this.pendBy = StringHelper.clean(options.pendBy);
    this.movebackBy = StringHelper.clean(options.movebackBy);
    this.overread2By = StringHelper.clean(options.overread2By);
    this.codedBy = StringHelper.clean(options.codedBy);
    this.updatedBy = StringHelper.clean(options.updatedBy);
    this.userName = StringHelper.clean(options.userName);
    this.retrievedDateStart = StringHelper.clean(options.retrievedDateStart);
    this.retrievedDateEnd = StringHelper.clean(options.retrievedDateEnd);
    this.movebackDateStart = StringHelper.clean(options.movebackDateStart);
    this.movebackDateEnd = StringHelper.clean(options.movebackDateEnd);
    this.chaseTags = StringHelper.clean(options.chaseTags);
    this.dateRangeStart = StringHelper.clean(options.dateRangeStart);
    this.dateRangeEnd = StringHelper.clean(options.dateRangeEnd);
    this.login = StringHelper.clean(options.login);
    this.userEmail = StringHelper.clean(options.userEmail);
    this.userStatus = StringHelper.clean(options.userStatus);
    this.currentChaseStatus = StringHelper.clean(options.currentChaseStatus);
    this.currentProcessStep = StringHelper.clean(options.currentProcessStep);
    this.qaDateFrom = StringHelper.clean(options.qaDateFrom);
    this.qaDateTo = StringHelper.clean(options.qaDateTo);
    this.vendorInvoiceType = StringHelper.clean(options.vendorInvoiceType);
    this.pendTypeAsCsv = StringHelper.clean(options.pendTypeAsCsv);
  }
}

