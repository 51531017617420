import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GenericBorderTemplateComponent } from "./generic-border-template.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    GenericBorderTemplateComponent,
  ],
  exports: [
    GenericBorderTemplateComponent,
  ],
})

export class GenericBorderTemplateModule {

}
