import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { IDateRange, dateTypes } from "../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export const mapAppointment = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Appointment")
    .forMember("appointmentId", o => o.appointmentId)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("masterDocumentSourceName", o => o.masterDocumentSourceName)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("note", o => o.note)
    .forMember("start", o => o.start)
    .forMember("end", o => o.end);

  automapper
    .createMap("ScheduleRowData", "Appointment")
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceID)
    .forMember("masterDocumentSourceName", o => o.address);

  automapper
    .createMap("EditRowData", "Appointment")
    .forMember("appointmentId", o => o.appointmentId)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("masterDocumentSourceName", o => o.masterDocumentSourceName)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("note", o => o.notes)
    .forMember("start", o => new Date(o.appointmentStartTime))
    .forMember("end", o => new Date(o.appointmentEndTime));

  automapper
      .createMap("AddressDetailState", "Appointment")
      .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
      .forMember("masterDocumentSourceName", o => o.address1);
};


export class Appointment implements IDateRange {
  appointmentId: number | null;
  masterDocumentSourceId: number;
  masterDocumentSourceName: string;
  assignedToUserId: number | null;
  note: string;
  start: dateTypes;
  end: dateTypes;

  constructor(a: Partial<Appointment>) {
    this.appointmentId = a.appointmentId;
    this.masterDocumentSourceId = a.masterDocumentSourceId;
    this.masterDocumentSourceName = StringHelper.clean(a.masterDocumentSourceName);
    this.assignedToUserId = a.assignedToUserId;
    this.note = StringHelper.clean(a.note);
    this.start = DateHelper.create(a.start, null);
    this.end = DateHelper.create(a.end, null);
  }
}
