import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { BASE_API_URL } from "../../../../../../../core/environment.tokens";
import { CheckboxGroup } from "../../../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../../../shared/grid/models/grid-request.model";
import { DocumentSourceType } from "../../../../retrieval-document-source-type.enum";
import { AddressDetailStateService } from "../../../address-detail-state.service";

@Component({
  selector: "app-address-detail-info-grids-invoices",
  templateUrl: "./address-detail-info-grids-invoices.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsInvoicesComponent implements OnInit {
    invoicesGridConfiguration = new GridConfiguration();
    invoicesServerRequest: GridRequest;
    refreshGrid = new EventEmitter<boolean>(true);
    documentSourceTypeId: number;

    constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string,
                private readonly addressDetailStateService: AddressDetailStateService,
                private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.getAddressId();
        this.createGrids();
    }

    createGrids() {
        this.invoicesGridConfiguration.columns = [
            new GridColumnDefinition({ field: "invoiceId", header: "INVOICE ID" }),
            new GridColumnDefinition({ field: "chaseId", header: "CHASE ID" }),
            new GridColumnDefinition({ field: "vendorName", header: "VENDOR" }),
            new GridColumnDefinition({ field: "invoiceType", header: "INVOICE TYPE" }),
            new GridColumnDefinition({ field: "invoiceStatus", header: "INVOICE STATUS" }),
            new GridColumnDefinition({ field: "status", header: "APPROVAL STATUS" }),
        ];
        this.invoicesGridConfiguration.showActionColumn = false;
        this.invoicesGridConfiguration.pageSize = 25;
    }

    getAddressId(): void {
        this.addressDetailStateService.state.subscribe(state => {
            this.documentSourceTypeId = state.documentSourceTypeId;
            if (state.hasMasterDocumentSourceId) {
                const addressId = state.masterDocumentSourceId.toString();
                this.invoicesServerRequest = new GridRequest({
                    url: `${this.baseApiUrl}invoice/chases`,
                    filters: [
                        new GridFilter({
                            input: new Textbox(),
                            key: "AddressId",
                            name: "AID",
                            value: addressId,
                            show: false,
                        }),
                        new GridFilter({
                            input: new CheckboxGroup(),
                            key: "chaseInvoiceStatusAsCsv",
                            name: "Chase Invoice Status",
                            value: "Auto-Approved,Approved",
                            show: false,
                        }),
                    ],
                });
                this.refreshGrid.emit();
                this.changeDetector.markForCheck();
            }
        });
    }

    get isThirdParty(): boolean {
        return this.documentSourceTypeId === DocumentSourceType.THIRDPARTY;
    }

}
