import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-internal-pends-detail",
  templateUrl: "./internal-pends-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsDetailComponent {

}
