export const LOCALSTORAGE_PAGEAFTERSCREENSYNC = { key: "pageAfterScreenSync" };
export const LOCALSTORAGE_PAGEBEFORESCREENSYNC = { key: "pageBeforeScreenSync" };
export const LOCALSTORAGE_PAGERELOAD = { key: "isReloadPage" };
export const LOCALSTORAGE_DOCUMENTIDS = { key: "documentStorageIds" };
export const LOCALSTORAGE_DIAGNOSISINDEX = { key: "selectedDiagnosisIndex" };
export const LOCALSTORAGE_ENCOUNTERID = { key: "selectedEncounterId" };
export const LOCALSTORAGE_TABSELECTED = { key: "tabSelected" };
export const LOCALSTORAGE_CURRENTDXPAGENUMBER = { key: "currentDxPageNumber" };
export const LOCALSTORAGE_ISWINDOWOPEN = { key: "isWindowOpen" };
export const LOCALSTORAGE_PAGENUMBER = { key: "pageNumber" };
export const LOCALSTORAGE_SELECTEDDIAGNOSIS = { key: "localStorageSelectedDiagnosis" };
export const LOCALSTORAGE_SELECTEDDIAGNOSISDX = { key: "localStorageSelectedDiagnosisdx" };
export const LOCALSTORAGE_ENCOUNTERSTARTDATE = { key: "localStorageEncounterStartDate" };
export const LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP = { key: "restrictEncounterPageJump" };
export const LOCALSTORAGE_ENCOUNTERAFTERSCREENSYNC = { key: "encounterAfterScreenSync" };
