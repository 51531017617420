import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { ContactMethodType } from "./contact-method-type.enum";
import { ContactRequest } from "./contact-request.model";
import { CoverLetterTemplateService } from "./cover-letter-template.service";

@Component({
  selector: "retrieval-cover-letter-template",
  templateUrl: "./cover-letter-template.component.html",
  styleUrls: ["./cover-letter-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverLetterTemplateComponent implements OnInit {
  @Input() request: ContactRequest;
  @Input() visible = false;
  @Input() isGap = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onApplyCoverTemplate = new EventEmitter<ContactRequest>();

  coverLetterTemplate: Dropdown;
  coverTemplateFromGroup: FormGroup;


  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    protected readonly messagingService: MessagingService,
    private service: CoverLetterTemplateService
  ) { }

  ngOnInit() {
    this.createForm();

    if (this.request == null) {
      this.request = {} as any;
    }
  }


  get typeName(): string {
    return this.request.typeName;
  }

  get header(): string {
    return `Send ${this.typeName}`;
  }

  get to(): string {
    return this.request.toValue;
  }

  get toLabel(): string {
    return this.request.toLabel;
  }

  get toName(): string {
    return this.request.toName;
  }

  get from(): string {
    return this.request.from;
  }

  get fromName(): string {
    return this.request.fromName;
  }

  get totalChases(): number {
    return this.request.totalChases;
  }

  get batchedFaxCount(): number {
    return Math.ceil(this.request.totalChases / 25);
  }

  get isFaxCoverSheet(): boolean {
    return this.request.type === ContactMethodType.Fax;
  }

  open(): void {
    this.visible = true;
    this.visibleChange.emit(true);
    this.getCoverLetterTemplateList();
    this.changeDetector.markForCheck();
  }

  close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.coverTemplateFromGroup.reset();
    this.changeDetector.markForCheck();
  }

  requestClicked(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.coverTemplateFromGroup.valid) {
      this.sendRequest();
      this.close();
    } else {
      this.formService.markAllAsTouched(this.coverTemplateFromGroup);
      this.changeDetector.markForCheck();
    }
  }


  private sendRequest(): void {
    this.request.templateName = this.coverTemplateFromGroup.get("coverLetterTemplate").value;
    this.onApplyCoverTemplate.emit(this.request);
    this.changeDetector.markForCheck();
  }

  private createForm(): void {
    this.coverLetterTemplate = new Dropdown({
      key: "coverLetterTemplate",
      label: "Select Template",
      placeholder: "Select Cover Letter Template",
      validators: [Validators.required],
      errorMessages: {
        required: "Cover letter template is required.",
      },
      appendTo: "body",
    });


    this.coverTemplateFromGroup = this.formService.createFormGroup([this.coverLetterTemplate]);
  }

  private getCoverLetterTemplateList(): void {
    this.service.getCoverLetterTemplate()
      .subscribe(templatelist => {
        this.coverLetterTemplate = new Dropdown({ ...this.coverLetterTemplate, options: templatelist } as any);
        this.changeDetector.markForCheck();
      });
  }
}
