import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
export class DocumentTagUploadRequest {

  readonly tagTextCsv: string = "";
  readonly tagTypeId: number = 0;
  readonly tagSourceId: number = 0 ;
  readonly tagItems: SelectableInput[] = null;
  readonly objectIds: any[] = null;


  constructor(options: {
    tagTextCsv: string;
    tagTypeId: number;
    tagSourceId: number;
    tagItems: SelectableInput[];
    objectIds: any[];

  }) {
    this.tagTextCsv = options.tagTextCsv;
    this.tagTypeId = options.tagTypeId;
    this.tagSourceId = options.tagSourceId;
    this.tagItems = options.tagItems;
    this.objectIds = options.objectIds;

  }
}
