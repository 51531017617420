import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { RiskService } from "../../../../chase-detail/chase-detail-chart/risk/risk.service";

@Component({
  selector: "member-risk-read-only",
  templateUrl: "./risk-read-only.component.html",
  styleUrls: ["./risk-read-only.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskReadOnlyComponent implements OnDestroy, OnInit {

  @Input() isReadOnly: boolean;
  private sink = new SubSink();

  constructor(
    private readonly riskService: RiskService,
    private readonly changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.isReadOnly === undefined) {
      this.sink.add(
        this.riskService.data
          .subscribe(riskState => {
            this.isReadOnly = !riskState.assignedToCurrentUser;
            this.changeDetector.detectChanges();
          })
        );
      }
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }
}
