import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { DocumentSourceType } from "../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../address-detail-state.model";
import { AddressDetailStateService } from "../address-detail-state.service";

@Component({
  selector: "retrieval-address-detail-info-new",
  templateUrl: "./address-detail-info.component.html",
  styleUrls: ["./address-detail-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  isFieldTech = false;
  isEmr = false;
  addressDetailState: AddressDetailState;

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.isFieldTech = state.isFieldTech;
        this.isEmr = state.isEMR;
        this.addressDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  get addressId(): number {
    return this.addressDetailState.masterDocumentSourceId;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get isContactSection(): boolean {
    return this.isFieldTech || this.isEmr;
  }

  get retrievalType(): number {
    return this.isContactSection
      ? this.isFieldTech
        ? DocumentSourceType.FIELDTECH : DocumentSourceType.EMR
      : null;
  }
}
