import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../../../../auth/auth.service";
import { JobQueueItem } from "../jobqueue-item.model";
import { JobsqueueService } from "../jobsqueue.service";
import { MyJobsRequest } from "../myjobs-request.model";

@Component({
  selector: "app-jobqueue-list",
  templateUrl: "./jobqueue-list.component.html",
  styleUrls: ["./jobqueue-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobqueueListComponent implements OnInit {
  @Input() pageSize = 200;
  @Input() pageNumber = 1;

  jobQueueItems: JobQueueItem[] = [];
  holdStartDateTime: Date = null;

  constructor(private changeDetector: ChangeDetectorRef,
              private service: JobsqueueService,
              private authService: AuthService

  ) { }

  ngOnInit() {

    this.loadData();
  }

  loadData() {

    const myJobsRequest =
      new MyJobsRequest({
        stateMachineId: null,
        statusId: null,
        sortDirection: "desc",
        sortBy: "stepExecutionId",
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      });

    this.service
      .getMyJobs(myJobsRequest)
      .subscribe(items => {
        this.showItemsAndNotify(items);
        this.changeDetector.markForCheck();
      });
  }

  showItemsAndNotify(newJobQueueItems: JobQueueItem[]) {

    this.jobQueueItems = newJobQueueItems;

  }

  isNewStartDate(jobQueueItem: JobQueueItem): boolean {

    const lastStartDateTime = moment(this.holdStartDateTime).local().format("LL");
    const thisStartDateTime = moment(jobQueueItem.startDateTime).local().format("LL");

    const newStartDateTime = lastStartDateTime !== thisStartDateTime;
    this.holdStartDateTime = jobQueueItem.startDateTime;

    return newStartDateTime;

  }

  formatHoldStartDateTime(): string {

    return moment(this.holdStartDateTime).local().format("MM/DD/YYYY");
  }

  trackByIndex(index, item) {
    return index;
  }

}
