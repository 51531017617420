import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-provider-status",
  templateUrl: "./provider-status.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderStatusComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.PROVIDER_STATUS;

    constructor() {
        this.filters = ["Projects", "Address", "Hcc", "Npi"];
    }

}
