import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../../shared/list/list-item";
import { DocumentReviewerChaseListItem } from "./document-reviewer-chase-list-item.model";


@Injectable({
  providedIn: "root",
})
export class DocumentReviewerService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getStatisticsData(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}dashboard/documentreviewer/statistics`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  getDocumentChaseList(): Observable<DocumentReviewerChaseListItem[]> {

    const url = `${this.baseApiUrl}dashboard/documentreviewer/chasesdocument`;

    return this.http.get(url).pipe(
      map(
        this.automapper.curryMany("default", "DocumentReviewerChaseListItem")
      )
    );
  }

  getAverageTimeDocumentKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/documentreviewer/averagetimeperdocument`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getDocumentCompletedKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/documentreviewer/documentcompleted`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getNextChaseId(roleId: number): Observable<any> {
    const url = `${this.baseApiUrl}dashboard/documentreviewer/nextchase?functionalRoleId=${roleId}`;
    return this.http.get(url);
  }

  getNextAssignedChaseId(roleId: number): Observable<any> {
    const url = `${this.baseApiUrl}dashboard/documentreviewer/assignedchase?functionalRoleId=${roleId}`;
    return this.http.get(url);
  }
}
