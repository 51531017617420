import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";

import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProjectReportsService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getChaseStatusReportKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/chasestatuskpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getNumeratorHitsKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/numeratorhitskpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

}
