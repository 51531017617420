import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../../auth/auth.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridStateService } from "../../../../shared/grid/grid-state.service";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { PendGridComponent } from "../../../../shared/pend-grid/pend-grid.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { DASHBOARD_MY_PEND_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";

@Component({
  selector: "app-my-pends",
  templateUrl: "./pends.component.html",
  styleUrls: ["./pends.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendsComponent implements OnInit {
  @ViewChild(PendGridComponent, { static: true }) pendGridComponent: PendGridComponent;
  additionalFilters: GridFilter[];
  additionalBulkActions: BulkAction[] = [];
  selection: any[];
  chasePendIds: number[] = [];
  pendOwners: string[];
  selectedPends: any[];
  isChangeOwnerView = false;
  isStatusChangeView = false;
  isClientRole = false;
  isLoadOnInit: boolean;
  viewAttributeId = DASHBOARD_MY_PEND_GRID.attributeId;
  stateName = DASHBOARD_MY_PEND_GRID.attributeCode;
  assignedUserId: number;

  get isAllSelectedPendOwnerIdentical(): boolean {
    return this.pendOwners.every((owner, i, pendOwner) => owner === pendOwner[0]);
  }

  constructor(
    private readonly authService: AuthService,
    private messagingService: MessagingService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly gridStateService: GridStateService
  ) { }

  ngOnInit(): void {
    this.isClientRole = this.authService.user.directoryRoleIds.indexOf(31) > -1;
    this.createGrid();
    this.isLoadOnInit = !this.gridStateService.hasKey(this.stateName);
    if (!this.isLoadOnInit) { setTimeout(() => this.refreshGrid()); }
  }

  private createGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
        value: this.authService.user.userId.toString(),
        show: false,
      }),
    ];
    this.additionalBulkActions = [
      new BulkAction({
        name: "Change Owner & Assign",
        action: this.changeOwnerAssignDialog,
      }),
      new BulkAction({
        name: this.isClientRole ? "REQUEST TO CLOSE" : "CHANGE STATUS",
        action: this.isClientRole ? this.requestStatusChangeDialog : this.updateStatusDialog,
      }),
    ];
  }

  private setSelectedPends(rowData: any): void {
    this.selectedPends = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
  }

  changeOwnerAssignDialog = (rowData: any): void => {
    this.setSelectedPends(rowData);
    const selectedRows = this.selectedPends;
    this.chasePendIds = [];

    if (selectedRows && selectedRows.length > 0) {
      const projectIds = selectedRows.map(item => item.projectId)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (projectIds.length === 1) {
        selectedRows.forEach(item => {
          this.chasePendIds.push(item.chasePendId);
        });
      } else {
        this.messagingService.showToast("Selected pends are not from same project.", SeverityType.WARN);
        return;
      }

      let pendstatus = "";
      selectedRows.forEach(item => {
        if (item.pendStatus === "Resolved" || item.pendStatus === "Closed") {
          pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
        }
      });
      if (pendstatus.length > 0) {
        this.messagingService.showToast(`Selected pends having ${pendstatus.replace(/,\s*$/, "")} status cannot be assigned.`, SeverityType.WARN);
        return;
      }
      this.isChangeOwnerView = true;


    } else {
      this.messagingService.showToast("Please select at least one Pend", SeverityType.WARN);
    }
  }

  updateStatusDialog = (rowData: any): void =>  {
    this.setSelectedPends(rowData);
    const selectedRows = this.selectedPends;
    let pendstatus = "";
    selectedRows.forEach(item => {
      if (item.pendStatus === "Closed" || item.pendStatus === "Resolved") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot change status.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      selectedRows.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
      });

      this.pendOwners = [];
      selectedRows.forEach(item => {
        const pendOwner = item.owner === "Client" ? "C" : "O";
        this.pendOwners.push(pendOwner);
      });
      if (NumberHelper.isGreaterThan(this.pendOwners.length, 1) && !this.isAllSelectedPendOwnerIdentical) {
        this.messagingService.showToast("The status of the selected pends having different owners cannot be changed.", SeverityType.WARN);
        this.changeDetector.markForCheck();
        return;
      }
      this.assignedUserId = this.authService.user.userId;
      this.isStatusChangeView = true;
      this.isClientRole = false;
    }
  }

  requestStatusChangeDialog = (rowData: any): void =>  {
    this.setSelectedPends(rowData);
    const selectedRows = this.selectedPends;
    let pendstatus = "";
    selectedRows.forEach(item => {
      if (item.pendStatus === "Closed") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot change status.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      selectedRows.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
      });

      this.isStatusChangeView = true;
    }
  }

  reassignUser($event: boolean): void {
    this.isChangeOwnerView = $event;
    this.gridRowSelection();
  }

  updateStatus($event: boolean): void {
    this.isStatusChangeView = $event;
    this.gridRowSelection();
  }

  private gridRowSelection() {
    this.refreshGrid();
    this.selectedPends = [];
    this.selection = [];
  }

  private refreshGrid() {
    this.pendGridComponent.refreshGrid.emit();
  }

}
