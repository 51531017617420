import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { List } from "immutable";
import { DocumentListItem } from "../chase-document-list/document-list-item.model";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent {
  @Input() display: "block" | "inline" = "inline";
  @Input() width = "300px";
  @Input() height = "400px";
  @Input() documents = List<DocumentListItem>();

  trackByIndex(index, item) {
    return index;
  }
}
