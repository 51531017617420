import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { TagActionType } from "../model/tag-action-type.enum";

@Component({
  selector: "app-color-palette",
  templateUrl: "./color-palette.component.html",
  styleUrls: ["./color-palette.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPaletteComponent implements OnInit, OnChanges {

  defaultColors: string[];
  @Input() heading: string;
  @Input() color: string;
  @Output() selectedColor = new EventEmitter();
  @Input() isCreateTagTemplateVisible = false;
  isColorPalatteVisible = false;
  defaultColor = "#DCDCDC";
  tagHeaderText = "Create New Tag";
  @Input() hastextValue = "";
  @Input() lowerBoundClass = "";
  @Input() hasTags: any;
  private pSelectedTagAction = null;
  @Input()
  get selectedTagAction(): TagActionType {
    return this.pSelectedTagAction;
  }
  set selectedTagAction(tagAction: TagActionType) {
    this.pSelectedTagAction = tagAction;
  }

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
   this.defaultColorPalette();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hastextValue && changes.hastextValue.currentValue) {
      this.hastextValue = changes.hastextValue.currentValue;
    }
  }

  private defaultColorPalette(): string[] {
    this.defaultColors = [
      "#DCDCDC",
      "#FFD5D5",
      "#FFDA7D",
      "#C2D7EC",
      "#9ADDB6",
    ];
    return this.defaultColors;
  }

  changeColor(color: string) {
    this.color = color;
    this.selectedColor.emit(this.color);
    this.isColorPalatteVisible = false;
  }

  getIcon(): string {
    const iconClass = "far fa-plus-circle";
    return this.selectedTagAction ? `${iconClass} managet-tag` : `${iconClass}`;
  }

  trackByIndex(index, item) {
    return index;
  }

  hideCreateTagTemplate(value: boolean): void {
    this.isCreateTagTemplateVisible = value;
    this.changeDetector.detectChanges();
  }

  createNewTagHoverClass(): string {
    return "color-picker__tag-info create-new-tag-hover-color";
  }

  getPopoverClass(): string {
    return this.hasTags.length === 0 ? "popover-panel" : "";
  }

  getClass(color: string) {
    let hoverClass = "circle";
    switch (color) {

      case "#DCDCDC":
        return hoverClass += " grey";

      case "#C2D7EC":
        return hoverClass += " blue";

      case "#FFD5D5":
        return hoverClass += " pink";

      case "#FFDA7D":
        return hoverClass += " yellow";

      case "#9ADDB6":
        return hoverClass += " green";

      default:
        return "";
    }
  }

  get showTagTemplate(): boolean {
    return this.selectedTagAction ? (StringHelper.isAvailable(this.hastextValue) && this.isCreateTagTemplateVisible) : StringHelper.isAvailable(this.hastextValue);
  }

  get colorPickerHeaderClass(): string {
    return this.selectedTagAction ? "color-picker__tag-info--manage-header" : "color-picker__tag-info--header";
  }

}
