import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-info-item-template",
  templateUrl: "./info-item-template.component.html",
  styleUrls: ["./info-item-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoItemTemplateComponent {
}
