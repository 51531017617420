import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonModule as PButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { IconModule } from "../icon/icon.module";
import { BackButtonComponent } from "./back-button.component";
import { ButtonComponent } from "./button.component";
import { SplitButtonComponent } from "./split-button/split-button.component";

@NgModule({
  imports: [
    CommonModule,
    PButtonModule,
    IconModule,
    SplitButtonModule,
  ],
  declarations: [
    BackButtonComponent,
    ButtonComponent,
    SplitButtonComponent,
  ],
  exports: [
    BackButtonComponent,
    ButtonComponent,
    SplitButtonComponent,
  ],
})
export class ButtonModule { }
