import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class Attribute {
  chaseId: number;
  entityId: number;
  entityTypeId: number;
  sourceTypeId: number;
  dataType: string;
  id: number;
  code: string;
  value: string | number;

  constructor(options: {
    chaseId?: number;
    entityId?: number;
    entityTypeId?: number;
    sourceTypeId?: number;
    dataType?: string;
    id?: number;
    code?: string;
    value?: string | number;
  } = {}) {
    this.chaseId = options.chaseId || null;
    this.entityId = options.entityId || null;
    this.entityTypeId = options.entityTypeId || null;
    this.sourceTypeId = options.sourceTypeId || null;
    this.dataType = options.dataType || "";
    this.id = options.id || null;
    this.code = StringHelper.clean(options.code);
    this.value = typeof options.value === "string"
      ? StringHelper.clean(options.value)
      : (options.value || null);
  }
}
