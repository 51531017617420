import { Configuration } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { AuthService } from "./auth/auth.service";

export const msalConfig: Configuration = {
    auth: {
      clientId: AuthService.getClientId(),
      redirectUri: environment.ssoRedirectUrl,
      authority: AuthService.getDirectoryIdUrl(),
    },
};
