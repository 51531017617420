import { IAutoMapper } from "../../../core/automapper/interfaces";
import { BoundingBox } from "./bounding-box.model";

export const mapOcrWord  = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "OcrWord")
    .forMember("text", o => o.text)
    .forMember("boundingBoxes", o => {
      const isArray = Array.isArray(o.bounding_box) && o.bounding_box.length > 0;
      return isArray ? automapper.mapMany("default", "BoundingBox", o.bounding_box) : [];
    });
};

export class OcrWord {
  readonly text: string;
  readonly boundingBoxes: BoundingBox[];
  readonly phraseIndex: number;

  constructor(options: OcrWord) {
    this.text = options.text;
    this.boundingBoxes = options.boundingBoxes;
    this.phraseIndex = options.phraseIndex;
  }
}
