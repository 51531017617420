<div class="chase-detail-v2">
    <div class="chase-detail-v2__left">
        <div class="chase-detail-v2__left__title-block">

            <h2 class="chase-detail-v2__left__title-block--title" *ngIf="memberTabSelected">
                {{chaseTitle}}
            </h2>

            <h2 class="chase-detail-v2__left__title-block--title" *ngIf="encounterTabSelected || submitEncounterSelected || submitTabSelected">
                    <ng-container *ngIf="!isDiagnosisSelected; else showICDCodeDescription">
                        Encounter Validation
                    </ng-container>
                    <ng-template #showICDCodeDescription>
                        <span>{{getICDCode(icdCode)}}</span> {{diseaseName}}
                    </ng-template>
            </h2>

            <span class="chase-detail-v2__left__title-block--count">{{pageCount}}</span>

        </div>
    </div>
    <div class="chase-detail-v2__right">

        <div class="chase-detail-v2__left--values" *ngIf="encounterTabSelected || submitTabSelected">

            <ng-container *ngIf="!isDiagnosisSelected; else showDOSAndProvider">
                <span class="chase-detail-v2__left--values--type"><h3>DOS:</h3> {{encounterStartDate}} - {{encounterEndDate}}</span>
            </ng-container>
            <ng-template #showDOSAndProvider>
                <span class="chase-detail-v2__left--values--type" *ngIf="submitDiagnosisSelected"><h3>DOS:</h3> {{dosDate}}</span>
                <span class="chase-detail-v2__left--values--type"
                    *ngIf="encounterTabSelected && !submitDiagnosisSelected"><h3>DOS:</h3>{{dosDate}}</span>
                <span *ngIf="providerName" class="chase-detail-v2__left--values--type chase-detail-v2__left--values--provider"><h3>Provider: </h3><span class="name" title="{{providerName}}" > {{providerName}}</span></span>
            </ng-template>

        </div>

        <div class="chase-detail-v2__right--search">
            <input type="text" placeholder="Search Medical Record" class="chase-detail-v2__right--search--input">
            <button type="button" class="chase-detail-v2__right--search--button">
                <app-icon iconName="fa-search" iconBaseStyle="far"></app-icon>
            </button>
        </div>
        <div class="chase-detail-v2__right--filters">

            <ng-container *ngIf="showThumbnailFilters">
                <span>Filter By: </span>
                <ul class="chase-detail-v2__right--filters--links" #allFilters >
                    <ng-container *ngFor="let link of filterLinks; trackBy: trackByIndex">
                        <ng-container *ngIf="memberTabSelected">
                            <ng-container class="div" *ngFor="let links of link.member; trackBy: trackByIndex; index as i">
                                <li [ngClass]="{'active': i !== 3}" title="{{links}}" (click)="selectFilter(links)">{{links}}</li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="encounterTabSelected || submitEncounterSelected || submitTabSelected">
                            <ng-container *ngIf="!isDiagnosisSelected; else showDiagnosis">
                                <ng-container class="div" *ngFor="let links of link.encounter; trackBy: trackByIndex; index as i">
                                    <li [ngClass]="{'active': i !== 2}" title="{{links}}" id="{{links}}" (click)="selectFilter(links)">{{links}}</li>
                                </ng-container>
                            </ng-container>
                            <ng-template #showDiagnosis>
                                <ng-container class="div" *ngFor="let links of link.diagnosis; trackBy: trackByIndex; index as i">
                                    <li [ngClass]="{'active': i !== 4}" title="{{links}}" (click)="selectFilter(links)">{{links}}</li>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </ul>
            </ng-container>

             <ng-container *ngIf="showChartHeaderFilter" >

               <div *ngIf="isExpandedView" class="thumbnail__move-back-pages">
                    <div class="thumbnail__move-back-pages__move-back-reasons">
                      <app-bulk-actions-dropdown class="grid__menu__item bulk-action"
                        [actions]="moveBackChasePageTypeOptions" [selectedItems]="selectedItemsThumbnail"
                        (onSelectionChange)="onSelectionChange($event)" [hasData]=true>
                      </app-bulk-actions-dropdown>
                    </div>
                </div>

            </ng-container>

            <div class="thumbnail__move-back-pages" *ngIf="isExpandedView">
                <form-factory [formGroup]="formGroup" [model]="moveBackDocumentEnabled"
                  (onChange)="onChangeMoveBackDocumentEnabled($event)"></form-factory>
            </div>

        </div>
    </div>
</div>
