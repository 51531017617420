import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class ContactRecord {
  readonly addressId?: number;
  readonly vendorId?: number;
  readonly assignedTo?: number;
  readonly organizationId?: number;
  readonly callerUserId?: number;
  readonly contactResultType?: number;
  readonly contactMethodType?: number;
  readonly contactNumber?: string;
  readonly chaseIds?: number[];
  readonly nextCallDateTime?: string;
  readonly calledAddress?: boolean;
  readonly callNotes?: string;
  readonly contactLogId?: number;

  constructor(options: {
    addressId?: number;
    vendorId?: number;
    assignedTo?: number;
    organizationId?: number;
    callerUserId?: number;
    contactResultType?: number;
    contactMethodType?: number;
    contactNumber?: string;
    chaseIds?: number[];
    nextCallDateTime?: string;
    calledAddress?: boolean;
    callNotes?: string;
    contactLogId?: number;
  }) {

    this.addressId = options.addressId;
    this.vendorId = options.vendorId;
    this.assignedTo = options.assignedTo;
    this.organizationId = options.organizationId;
    this.callerUserId = options.callerUserId;
    this.contactResultType = options.contactResultType;
    this.contactMethodType = options.contactMethodType;
    this.contactNumber = StringHelper.isAvailable(options.contactNumber) ? options.contactNumber.replace(/[^0-9]/g, "") : "";
    this.chaseIds = options.chaseIds;
    this.nextCallDateTime = options.nextCallDateTime;
    this.calledAddress = options.calledAddress;
    this.callNotes = options.callNotes;
    this.contactLogId = options.contactLogId;
  }
}
