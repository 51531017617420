<app-modal
  [(visible)]="visible"
  header={{headerLabel}}
  (visibleChange)="visibleChange.emit($event)"
  (onShow)="onShow.emit($event)"
  (onHide)="onModalHide()"
  class="commit-modal commitment-date">
  <div class="commitment-item">
    <label>{{label}}</label>
    <p-calendar
      appendTo="body"
      [showIcon]=true
      [readonlyInput]="true"
      [hideOnDateTimeSelect]="true"
      placeholder="Date"
      [(ngModel)]="commitDate"
      [minDate]="minimumDate">
    </p-calendar>
    <div *ngIf="!isThirdParty" class="delete-button">
      <app-button classes="small secondary"
                  text=" X Clear Date"
                  (onClick)="clearCommitmentDate()">
      </app-button>
    </div>
  </div>
  <footer>
    <app-button
      class="header-button"
      text="SUBMIT"
      (onClick)="onSetCommitmentDate()">
    </app-button>
  </footer>
</app-modal>
