import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TabViewModule } from "primeng/tabview";
import { TreeModule } from "primeng/tree";
import { SharedModule } from "../../../shared/shared.module";
import { AuditPageEntryService } from "../member/chase-detail/chase-detail-chart/audit-page-entry/audit-page-entry.service";
import { MemberModule } from "../member/member.module";
import { AuditPackageGenerateComponent } from "./audit-query/audit-package-generate/audit-package-generate.component";
import { AuditPackageGenerateService } from "./audit-query/audit-package-generate/audit-package-generate.service";
import { AuditQueryChartComponent } from "./audit-query/audit-query-chart/audit-query-chart.component";
import { AuditQueryChartService } from "./audit-query/audit-query-chart/audit-query-chart.service";
import { AuditQueryGridActionComponent } from "./audit-query/audit-query-grid-action/audit-query-grid-action.component";
import { AuditQueryGridFiltersComponent } from "./audit-query/audit-query-grid-filters/audit-query-grid-filters.component";
import { AuditQueryGridFiltersService } from "./audit-query/audit-query-grid-filters/audit-query-grid-filters.service";
import { AuditQueryGridComponent } from "./audit-query/audit-query-grid/audit-query-grid.component";
import { AuditQueryGridService } from "./audit-query/audit-query-grid/audit-query-grid.service";
import { AuditQueryHeaderComponent } from "./audit-query/audit-query-header/audit-query-header.component";
import { AuditQueryHeaderService } from "./audit-query/audit-query-header/audit-query-header.service";
import { AuditQueryItemReadinessComponent } from "./audit-query/audit-query-item-readiness/audit-query-item-readiness.component";
import { AuditQueryComponent } from "./audit-query/audit-query.component";
import { AuditRoutingModule } from "./audit-routing.module";
import { AuditService } from "./audit.service";

@NgModule({
  imports: [
    AuditRoutingModule,
    SharedModule,
    FormsModule, // TODO: Remove when forms are done.
    TreeModule,
    MemberModule,
    TabViewModule,
  ],
  declarations: [
    AuditQueryComponent,
    AuditQueryHeaderComponent,
    AuditQueryGridComponent,
    AuditQueryChartComponent,
    AuditPackageGenerateComponent,
    AuditQueryGridFiltersComponent,
    AuditQueryGridActionComponent,
    AuditQueryItemReadinessComponent,
  ],
  providers: [
    AuditQueryGridFiltersService,
    AuditQueryGridService,
    AuditQueryHeaderService,
    AuditQueryChartService,
    AuditPageEntryService,
    AuditPackageGenerateService,
    AuditService,
  ],
})
export class AuditModule { }
