import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { RequestStatusChange } from "./request-status-change.model";

@Injectable()
export class InternalPendsRequestStatusChangeService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private route: ActivatedRoute
  ) { }


  getPendDropdown(isClinical: boolean, organizationId: number, isClientOnly: boolean, isReturnAllPendStatus: boolean): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}Pend/GetPendDropdowns?isClinical=${isClinical}&organizationId=${organizationId}&isClientOnly=${isClientOnly}&isReturnAllPendStatus=${isReturnAllPendStatus}`;

    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  updateStatusChange(requestStatusChange: RequestStatusChange): Observable<any> {
    const url = `${this.baseApiUrl}Pend/UpdatePendStatus`;
    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, requestStatusChange, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): any => {
          if (response.ok) {

            return 1;
          } else {

            return 0;
          }
        })
      );
  }
}
