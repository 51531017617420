import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TableModule } from "primeng/table";
import { CheckboxGroupModule } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.module";
import { CheckBoxModule } from "../../dynamic-forms/inputs/checkbox/checkbox.module";
import { SwitchModule } from "../../dynamic-forms/inputs/switch/switch.module";
import { TextboxModule } from "../../dynamic-forms/inputs/textbox/textbox.module";
import { ButtonModule } from "../button/button.module";
import { IconModule } from "../icon/icon.module";
import { PanelModule } from "../panel/panel.module";
import { ToolTipModule } from "../tool-tip/tool-tip.module";
import { BasicFilterAppliedComponent } from "./basic-filter-applied/basic-filter-applied.component";
import { BasicGridComponent } from "./basic-grid/basic-grid.component";
import { BulkActionsDropdownComponent } from "./bulk-actions-dropdown/bulk-actions-dropdown.component";
import { BulkActionsComponent } from "./bulk-actions/bulk-actions.component";
import { FilterAppliedComponent } from "./filter-applied/filter-applied.component";
import { GridBodyComponent } from "./grid-body/grid-body.component";
import { GridHeaderComponent } from "./grid-header/grid-header.component";
import { BasicGridMenuComponent } from "./grid-menu/basic-grid-menu.component";
import { GridViewEditorComponent } from "./grid-menu/grid-views/grid-view-editor/grid-view-editor.component";
import { GridViewComponent } from "./grid-menu/grid-views/grid-view/grid-view.component";
import { GridViewsComponent } from "./grid-menu/grid-views/grid-views.component";
import { ServerGridMenuComponent } from "./grid-menu/server-grid-menu.component";
import { GridService } from "./grid.service";
import { ObsoleteGridComponent } from "./obsolete-grid/obsolete-grid.component";
import { SaveQueryDeleteComponent } from "./save-query-delete/save-query-delete.component";
import { SaveQueryComponent } from "./save-query/save-query.component";
import { ServerGridComponent } from "./server-grid/server-grid.component";


@NgModule({
  imports: [
    CommonModule,
    CheckboxGroupModule,
    CheckBoxModule,
    DropdownModule, // TODO: Delete when it won't break.
    TextboxModule,
    FormsModule,
    IconModule,
    RouterModule,
    TableModule,
    OverlayPanelModule,
    InputSwitchModule,
    ButtonModule,
    PanelModule,
    ReactiveFormsModule,
    SwitchModule,
    ToolTipModule,
  ],
  declarations: [
    BasicGridComponent,
    ObsoleteGridComponent,
    BasicGridMenuComponent,
    ServerGridMenuComponent,
    ServerGridComponent,
    GridHeaderComponent,
    GridBodyComponent,
    FilterAppliedComponent,
    BulkActionsComponent,
    GridViewsComponent,
    GridViewEditorComponent,
    GridViewComponent,
    BasicFilterAppliedComponent,
    BulkActionsDropdownComponent,
    SaveQueryComponent,
    SaveQueryDeleteComponent,
  ],
  exports: [
    BasicGridComponent,
    BulkActionsDropdownComponent,
    ServerGridComponent,
    ObsoleteGridComponent,
    SaveQueryComponent,
  ],
  providers: [
    GridService,
  ],
})
export class GridModule { }
