<div>
  <app-detail-header-template>
    <div class="outreach-container" top-left>
      <h3>Bulk Outreach</h3>
      <div> 
        <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
     </div>
    </div>
  </app-detail-header-template>
  <router-outlet></router-outlet>
</div>
