import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-provider-status-dw",
  templateUrl: "./provider-status-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderStatusDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.PROVIDER_STATUS_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.LINE_OF_BUSINESS, HideFilters.PRODUCT_NAME, HideFilters.RETRIVAL_OWNER];
}
