import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SubSink } from "subsink";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { GapGridComponent } from "../../../../shared/gap-grid/gap-grid.component";
import { PROJECT_GAP_QUERY_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { GapQueryRouteFilters } from "./qap-query-route-filters.model";

@Component({
  selector: "app-gap-query",
  templateUrl: "./gap-query.component.html",
  styleUrls: ["./gap-query.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapQueryComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild(GapGridComponent, { static: true }) gapGridComponent: GapGridComponent;
  stateName = PROJECT_GAP_QUERY_GRID.attributeCode;
  @Input() isUnassignEnabled = false;
  @Input() clinical: boolean | null;
  @Input() pageType = "";
  selection: any[];
  viewAttributeId = PROJECT_GAP_QUERY_GRID.attributeId;
  routeParameters: GapQueryRouteFilters;

  constructor(
    private router: Router,
    private parameterService: ParameterService
  ) { }

  ngOnInit() {
    this.getRouteParameters();

    this.sink.add(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.showFilters();
        }
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  showFilters() {
    this.gapGridComponent.serverGridComponent.showFilters();
  }

  private getRouteParameters(): void {
    this.routeParameters = new GapQueryRouteFilters(
      this.getRouteValues("ChaseID"),
      this.getRouteValues("ChaseKey"),
      this.getRouteValues("ProjectID"),
      this.getRouteValues("ProviderName"),
      this.getRouteValues("AddressID"),
      this.getRouteValues("Gaps"),
      this.getRouteValues("GapStatus"),
      this.getRouteValues("Product"),
      this.getRouteValues("MemberFirstName"),
      this.getRouteValues("MemberLastName"),
      this.getRouteValues("ChaseStatus"),
      this.getRouteValues("Measure"),
      this.getRouteValues("ChaseCompliance"),
      this.getRouteValues("SampleCompliance"),
      this.getRouteValues("GapCompliance"),
      this.getRouteValues("PendCode"),
      this.getRouteValues("PendStatus"),
      this.getRouteValues("TagId")
    );
  }

  private getRouteValues(parameterName: string): string {
    return this.parameterService.getNormal(parameterName);
  }
}
