import { IAutoMapper } from "../core/automapper/interfaces";
import { HomePages } from "../platform/modules/user/login-landing-page/homepages.model";
import { StringHelper } from "../utilities/contracts/string-helper";

export const mapUserToken = (automapper: IAutoMapper): void => {

  automapper
    .createMap("default", "UserToken")
    .forMember("token", o => o.token)
    .forMember("userId", o => o.userId)
    .forMember("username", o => o.userName)
    .forMember("userOnboardingUrl", o => o.userOnboardingUrl)
    .forMember("directoryRoleIds", o => o.directoryRoleIds)
    .forMember("functionalRoleIds", o => o.functionalRoleIds)
    .forMember("homePageUrl", o => o.homePageUrl)
    .forMember("defaultRole", o => o.defaultRole)
    .forMember("primaryUserRoleId", o => o.primaryUserRoleId)
    .forMember("userHomePagesOptions", o => o.userHomePagesOptions)
    .forMember("organizationId", o => o.organizationId)
    .forMember("organizationName", o => o.organizationName)
    .forMember("isTemporaryPassword", o => o.isTemporaryPassword)
    .forMember("isManagerRole", o => o.isManagerRole)
    .forMember("isAdminRole", o => o.isAdminRole)
    .forMember("isReadOnlyRole", o => o.isReadOnlyRole)
    .forMember("isLeadRole", o => o.isLeadRole)
    .forMember("isEmployeeRole", o => o.isEmployeeRole)
    .forMember("isMFAEnabled", o => o.isMFAEnabled)
    .forMember("emailAddress", o => o.emailAddress)
    .forMember("phoneNumber", o => o.phoneNumber)
    .forMember("concordFaxUserName", o => o.concordFaxUserName)
    .forMember("firstName", o => o.firstName)
    .forMember("lastName", o => o.lastName)
    .forMember("isTagManagement", o => o.isTagManagement)
    .forMember("isAddTag", o => o.isAddTag)
    .forMember("isRemoveTag", o => o.isRemoveTag)
    .forMember("isParentOrg", o => o.isParentOrg)
    .forMember("internetSpeedTestThresholdValue", o => o.internetSpeedTestThresholdValue)
    .forMember("internetSpeedTestInterval", o => o.internetSpeedTestInterval)
    .forMember("internetSpeedTestFileSize", o => o.internetSpeedTestFileSize)
    .forMember("internetSpeedTestMessageCount", o => o.internetSpeedTestMessageCount)
    .forMember("enableThumbnailView", o => o.enableThumbnailView)
    .forMember("isCoRetrievalEnabled", o => o.isCoRetrievalEnabled)
    .forMember("enableFirstPassBot", o => o.enableFirstPassBot)
    .forMember("coRetrievalOrganization", o => o.coRetrievalOrganization)
    .forMember("enableChartLakeMatchSection", o => o.enableChartLakeMatchSection)
    .forMember("isViewInvoicePermission", o => o.isViewInvoicePermission)
    .forMember("enableAnnotationCopyFeature", o => o.enableAnnotationCopyFeature)
    .forMember("isSsoUser", o => o.isSsoUser)
    .forMember("isSsoLoginMandatory", o => o.isSsoLoginMandatory)
    .forMember("providerPacketLogoUploadSizeInBytes", o => o.providerPacketLogoUploadSizeInBytes)
    .forMember("isMDPortalSettingEnabled", o => o.isMDPortalSettingEnabled)
    .forMember("isPreSignedUrlUploadEnabled", o => o.isPreSignedUrlUploadEnabled)
    .forMember("isReadOnlyErrorMessageSuppressed", o => o.isReadOnlyErrorMessageSuppressed)
    .forMember("isCommunicationEksEnabled", o => o.isCommunicationEksEnabled)
    .forMember("communicationMsBaseUrl", o => o.communicationMsBaseUrl)
    .forMember("sessionId", o => o.sessionId);
};

export class UserToken {
  readonly token: string;
  concordFaxUserName: string;
  coRetrievalOrganization: string;
  defaultRole: string;
  directoryRoleIds: number[];
  emailAddress: string;
  enableAnnotationCopyFeature: boolean;
  enableChartLakeMatchSection: boolean;
  enableFirstPassBot: boolean;
  enableThumbnailView: boolean;
  firstName: string;
  functionalRoleIds: number[];
  homePageUrl: string;
  internetSpeedTestFileSize: number;
  internetSpeedTestInterval: number;
  internetSpeedTestMessageCount: number;
  internetSpeedTestThresholdValue: number;
  isAddTag: boolean;
  isAdminRole: boolean;
  isCoRetrievalEnabled: boolean;
  isEmployeeRole: boolean;
  isLeadRole: boolean;
  isManagerRole: boolean;
  isMFAEnabled: boolean;
  isParentOrg: boolean;
  isReadOnlyRole: boolean;
  isRemoveTag: boolean;
  isSsoUser: boolean;
  isSsoLoginMandatory: boolean;
  isTagManagement: boolean;
  isTemporaryPassword: boolean;
  isViewInvoicePermission: boolean;
  lastName: string;
  organizationId: number;
  organizationName: string;
  phoneNumber: string;
  primaryUserRoleId: string;
  providerPacketLogoUploadSizeInBytes?: number;
  userHomePagesOptions: HomePages;
  userId: number;
  sessionId: string;
  username: string;
  userOnboardingUrl: string;
  isMDPortalSettingEnabled: boolean;
  isPreSignedUrlUploadEnabled: boolean;
  isReadOnlyErrorMessageSuppressed: boolean;
  isCommunicationEksEnabled: boolean;
  communicationMsBaseUrl: string;

  constructor(options: {
    concordFaxUserName?: string;
    coRetrievalOrganization?: string;
    defaultRole?: string;
    directoryRoleIds?: number[];
    emailAddress?: string;
    enableAnnotationCopyFeature?: boolean;
    enableChartLakeMatchSection?: boolean;
    enableFirstPassBot?: boolean;
    enableThumbnailView?: boolean;
    firstName?: string;
    functionalRoleIds?: number[];
    homePageUrl?: string;
    internetSpeedTestFileSize?: number;
    internetSpeedTestInterval?: number;
    internetSpeedTestMessageCount?: number;
    internetSpeedTestThresholdValue?: number;
    isAddTag?: boolean;
    isAdminRole?: boolean;
    isCoRetrievalEnabled?: boolean;
    isEmployeeRole?: boolean;
    isLeadRole?: boolean;
    isManagerRole?: boolean;
    isMFAEnabled?: boolean;
    isParentOrg?: boolean;
    isReadOnlyRole?: boolean;
    isRemoveTag?: boolean;
    isSsoUser?: boolean;
    isSsoLoginMandatory?: boolean;
    isTagManagement?: boolean;
    isTemporaryPassword?: boolean;
    isViewInvoicePermission?: boolean;
    lastName?: string;
    organizationId?: number;
    organizationName?: string;
    phoneNumber?: string;
    primaryUserRoleId?: string;
    providerPacketLogoUploadSizeInBytes?: number;
    token?: string;
    userHomePagesOptions?: HomePages;
    userId?: number;
    sessionId?: string;
    username?: string;
    userOnboardingUrl?: string;
    isMDPortalSettingEnabled?: boolean;
    isPreSignedUrlUploadEnabled?: boolean;
    isReadOnlyErrorMessageSuppressed?: boolean;
    isCommunicationEksEnabled?: boolean;
    communicationMsBaseUrl?: string;
  } = {}) {
    this.concordFaxUserName = options.concordFaxUserName;
    this.coRetrievalOrganization = options.coRetrievalOrganization;
    this.defaultRole = options.defaultRole;
    this.directoryRoleIds = options.directoryRoleIds;
    this.emailAddress = options.emailAddress;
    this.enableAnnotationCopyFeature = options.enableAnnotationCopyFeature;
    this.enableChartLakeMatchSection = options.enableChartLakeMatchSection;
    this.enableFirstPassBot = options.enableFirstPassBot;
    this.enableThumbnailView = options.enableThumbnailView;
    this.firstName = options.firstName;
    this.functionalRoleIds = options.functionalRoleIds;
    this.homePageUrl = options.homePageUrl;
    this.internetSpeedTestFileSize = options.internetSpeedTestFileSize;
    this.internetSpeedTestInterval = options.internetSpeedTestInterval;
    this.internetSpeedTestMessageCount = options.internetSpeedTestMessageCount;
    this.internetSpeedTestThresholdValue = options.internetSpeedTestThresholdValue;
    this.isAddTag = options.isAddTag;
    this.isAdminRole = options.isAdminRole;
    this.isCoRetrievalEnabled = options.isCoRetrievalEnabled;
    this.isEmployeeRole = options.isEmployeeRole;
    this.isLeadRole = options.isLeadRole;
    this.isManagerRole = options.isManagerRole;
    this.isMFAEnabled = options.isMFAEnabled;
    this.isParentOrg = options.isParentOrg;
    this.isReadOnlyRole = options.isReadOnlyRole;
    this.isRemoveTag = options.isRemoveTag;
    this.isSsoUser = options.isSsoUser;
    this.isSsoLoginMandatory = options.isSsoLoginMandatory;
    this.isTagManagement = options.isTagManagement;
    this.isTemporaryPassword = options.isTemporaryPassword;
    this.isViewInvoicePermission = options.isViewInvoicePermission;
    this.lastName = options.lastName;
    this.organizationId = options.organizationId;
    this.organizationName = options.organizationName;
    this.phoneNumber = options.phoneNumber;
    this.primaryUserRoleId = StringHelper.clean(options.primaryUserRoleId);
    this.providerPacketLogoUploadSizeInBytes = options.providerPacketLogoUploadSizeInBytes;
    this.token = options.token;
    this.userHomePagesOptions = options.userHomePagesOptions;
    this.userId = options.userId;
    this.sessionId = StringHelper.clean(options.sessionId);
    this.username = StringHelper.clean(options.username);
    this.userOnboardingUrl = StringHelper.clean(options.userOnboardingUrl);
    this.isMDPortalSettingEnabled = options.isMDPortalSettingEnabled;
    this.isPreSignedUrlUploadEnabled = options.isPreSignedUrlUploadEnabled;
    this.isReadOnlyErrorMessageSuppressed = options.isReadOnlyErrorMessageSuppressed;
    this.isCommunicationEksEnabled = options.isCommunicationEksEnabled;
    this.communicationMsBaseUrl = StringHelper.clean(options.communicationMsBaseUrl);
  }

  get isAuthenticated(): boolean {
    return StringHelper.isAvailable(this.username);
  }
}
