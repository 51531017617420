import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { Dialog } from "primeng/dialog";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-collapsible-modal",
  templateUrl: "./collapsible-modal.component.html",
  styleUrls: ["./collapsible-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleModalComponent extends ModalComponent {
  @Input() foreground = false;
  @ViewChild("primeDialog", { static: true }) primeDialog: Dialog;

  isExpand = true;

  get iconName(): string {
    return this.isExpand ? "minus-square" : "plus-square";
  }

  get modalViewClass(): string {
    return this.isExpand ? "collapsible-modal__expand" : "collapsible-modal__collapse";
  }

  toggleView(): void {
    this.isExpand = !this.isExpand;
  }
}
