import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgIdleModule } from "@ng-idle/core";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProgressBarModule } from "primeng/progressbar";
import { SharedModule } from "../shared/shared.module";
import { ChaseSearchService } from "./api/chase-search/chase-search.service";
import { ProviderService } from "./api/provider/provider.service";
import { ExternalUrlService } from "./api/url-guards/external-url.service";
import { HomePageUrlService } from "./api/url-guards/home-page-url.service";
import { AccessDeniedComponent } from "./error/access-denied/access-denied.component";
import { PageNotFoundComponent } from "./error/page-not-found/page-not-found.component";
import { AccountMenuComponent } from "./header/account-menu/account-menu.component";
import { SingleSignInComponent } from "./header/account-menu/single-sign-in/single-sign-in.component";
import { GlobalSearchComponent } from "./header/global-search/global-search.component";
import { HeaderComponent } from "./header/header.component";
import { JobsqueueComponent } from "./header/jobsqueue/jobsqueue.component";
import { NotificationsComponent } from "./header/notifications/notifications.component";
import { SearchService } from "./header/search.service";
import { IdleComponent } from "./idle/idle.component";
import { InternetSpeedComponent } from "./internet-speed/internet-speed.component";
import { AnalyticsModule } from "./modules/analytics/analytics.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { JobsQueueModule } from "./modules/jobsqueue/jobsqueue.module";
import { ReportinglModule } from "./modules/reporting/reporting.module";
import { RetrievalModule } from "./modules/retrieval/retrieval.module";
import { NavigationComponent } from "./navigation/navigation.component";
import { NotificationComponent } from "./notification/notification.component";
import { PlatformComponent } from "./platform.component";
import { PlatformService } from "./platform.service";
import { WidgetModule } from "./widget/widget.module";

@NgModule({
  imports: [
    AnalyticsModule,
    BrowserAnimationsModule,
    DashboardModule,
    JobsQueueModule,
    OverlayPanelModule,
    ProgressBarModule,
    ReportinglModule,
    RetrievalModule,
    SharedModule,
    WidgetModule,
    NgIdleModule.forRoot(),
  ],
  declarations: [
    PlatformComponent,
    GlobalSearchComponent,
    HeaderComponent,
    PageNotFoundComponent,
    NotificationComponent,
    AccessDeniedComponent,
    NotificationsComponent,
    AccountMenuComponent,
    JobsqueueComponent,
    IdleComponent,
    SingleSignInComponent,
    NavigationComponent,
    InternetSpeedComponent,
  ],
  providers: [
    PlatformService,
    ProviderService,
    ChaseSearchService,
    HomePageUrlService,
    ExternalUrlService,
    SearchService,
  ],
  exports: [
    PlatformComponent,
  ],
})
export class PlatformModule { }
