import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { NumeratorItem } from "./numerator-item.model";
import { NumeratorListItem } from "./numerator-list-item.model";

@Injectable({
    providedIn: "root",
})
export class NumeratorService {
    constructor(
        @Inject(BASE_API_URL) private readonly baseApiUrl: string,
        private readonly http: HttpClient
    ) { }

    getNumeratorList(projectId?: number, measureId?: number, organizationId?: number): Observable<NumeratorListItem[]> {
        const url = `${this.baseApiUrl}numerator/list?projectId=${projectId}&measureId=${measureId}&organizationId=${organizationId}`;
        return this.http.get(url).pipe(
            map((response: any) => response as NumeratorListItem[]));
    }

    getNumerators(): Observable<NumeratorItem[]> {
        const url = `${this.baseApiUrl}numerators`;
        return this.http.get(url).pipe(
            map((response: any) => response as NumeratorItem[]));
    }
}
