<div class="ui-md-12">
    <div class="ui-md-3 ui-toolbar-group-left ml">
        <div class="gap-header">Choose one or more gaps for measure: {{newChase.measureCode}}</div>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12 ml">
        <div class="ui-g-12 ui-md-12 ui-lg-12 ml">
            <div class="checkbox-group__fourths">
                <form [formGroup]="gapFormGroup">
                    <form-factory [formGroup]="gapFormGroup" [model]="numeratorInput"></form-factory>
                </form>
            </div>
        </div>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12 mt">
        <app-button class="reset-button ml" text="RESET" (onClick)="reset()"></app-button>
        <app-button class="ui-md-4 process-button next-step ml" (onClick)="nextStepProcess()" text="NEXT STEP"
            [disabled]="!isShowNextStepButton"></app-button>
    </div>
</div>