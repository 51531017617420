import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { UserModel } from "../../../core/user/user-model";
import { DataSet } from "../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../shared/list/list-item";


@Injectable({
  providedIn: "root",
})
export class ClinicalService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }


  getStatisticsData(clientId: number | null = null, projectId: number | null = null): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}clinical/clinicalStatistics?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }


  getDataSetForPendsCountByPendStatus(): Observable<DataSet> {
    const url = `${this.baseApiUrl}clinical/PendsCountByPendStatus`;

    return this.http.get(url).pipe(map(this.automapper.curry("default", "DataSet")));
  }


  getDataSetForChaseCompliance(): Observable<DataSet> {
    const url = `${this.baseApiUrl}clinical/ChaseCompliance`;

    return this.http.get(url).pipe(map(this.automapper.curry("default", "DataSet")));
  }


  getQAScoreTrendGraph(): Observable<DataSet> {
    const url = `${this.baseApiUrl}clinical/QAScore`;

    return this.http.get(url).pipe(map(this.automapper.curry("default", "DataSet")));
  }

  getUserFirstLastName(userName): Observable<UserModel> {
    const url = `${this.baseApiUrl}clinical/UserDetailsByUserNameAsync?userName=${userName}`;

    return this.http.get(url).pipe(map((response: any) => response as UserModel));
  }

  getMessageForMoreCharts(): Observable<string> {
    const url = `${this.baseApiUrl}clinical/AcceptRequestMessageAsync`;

    return this.http.get(url).pipe(map((response: any) => response as string));
  }
}
