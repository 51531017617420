import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../core/storage/session.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { Member } from "../../../clinical/member.model";
import { ProjectType } from "../../../project/project-type.enum";
import { Project } from "../../../project/project.model";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";
import { ChaseDetailInfoService } from "../../chase-detail/chase-detail-info/chase-detail-info.service";
import { ChaseDetailState } from "../../chase-detail/chase-detail-state.model";

import { ChaseDetailHeaderService } from "../../chase-detail/chase-detail-header/chase-detail-header.service";
import { ChaseDetailStateService } from "../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../chase-detail/chase-detail.service";

import { DOCUMENT } from "@angular/common";

@Component({
  selector: "member-chase-detail-v2-footer",
  templateUrl: "./chase-detail-v2-footer.component.html",
  styleUrls: ["./chase-detail-v2-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2FooterComponent implements OnInit, OnDestroy, AfterViewChecked {

  private sink = new SubSink();
  readonly AUTHENTICATION_KEY = "authentication";

  chaseDetailState: ChaseDetailState;
  chaseId: number;
  fromPage: "review" | "mrr" | "or1" | "clientoverread" | string;
  menuItems = List<MenuItem>();
  chaseDetails = List<ListItem>();
  isClinical: boolean;
  workFlowStatusId = 4; // TODO: As matching status not available in workflowstatus enum. Will replace & get from enum when its same.

  selectedChases: any[];
  isPendModalVisible = false;
  isAssignToChaseModalVisible = false;
  @Input() clinical = true;
  tagType = TagType.CHASE;
  @ViewChild("containerHeight", {static: true}) containerHeight: ElementRef;
  selectedChaseStatus: any[];
  hideLeftNavigation = false;
  showTopBanner = false;

  @Output() showDataEntry = new EventEmitter();
  @Output() isMainHeaderVisible = new EventEmitter();

  constructor(
    private readonly chaseDetailService: ChaseDetailService,
    private chaseDetailStateService: ChaseDetailStateService,
    private chaseDetailHeaderService: ChaseDetailHeaderService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly messagingService: MessagingService,
    private router: Router,
    private chaseDetailInfoService: ChaseDetailInfoService,
    private renderer: Renderer2,
    private menuService: MenuService,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  get pendButtonLabel(): string {
    return this.isNewPend ? "Pend" : "Edit Pend";
  }
  get pendButtonClass(): string {
    if (this.showAssignButton) {
      return this.assignedToCurrentUser ? "chase-button chase-button--left_margin" : "chase-button chase-button--left_margin disabledState";
    } else {
      return this.assignedToCurrentUser ? "chase-button" : "chase-button disabledState";
    }
  }

  get hasChaseDetails(): boolean {
    return this.chaseDetails.size > 0;
  }

  get pendChaseId(): string {
    if (!this.hasChaseDetails) {
      return "";
    }

    const pendChaseId = this.chaseDetails.find(item => item.key === "ChasePendId");
    return pendChaseId.value;
  }

  get isNewPend(): boolean {
    const isExistingPendClosed = this.pendStatusId === PendStatus.Resolved || this.pendStatusId === PendStatus.Closed;
    const hasPendChaseId = NumberHelper.isGreaterThan(Number(this.pendChaseId), 0);
    return (hasPendChaseId && isExistingPendClosed) || !hasPendChaseId;
  }

  get pendCode(): string {
    if (!this.hasChaseDetails) {
      return "";
    }

    const pendCode = this.chaseDetails.find(item => item.key === "Pend Code");
    return pendCode.value;
  }

  get pendStatusId(): number {
    if (!this.hasChaseDetails) {
      return null;
    }

    const pendStatusId = this.chaseDetails.find(item => item.key === "PendStatusId");
    return +pendStatusId.value;
  }

  get isOverread1(): boolean {
    return StringHelper.isAvailable(this.fromPage) && this.fromPage === "or1";
  }

  get isOverread2(): boolean {
    return StringHelper.isAvailable(this.fromPage) && this.fromPage === "clientoverread";
  }

  get enablePendModal(): boolean {
    return this.isOverread1 || this.isOverread2 || this.isMRR;
  }

  get projectType(): ProjectType {
    return this.chaseDetailState.projectTypeId;
  }

  get isRisk(): boolean {
    return this.projectType === ProjectType.RISK;
  }

  get isIVA(): boolean {
    return this.projectType === ProjectType.IVA;
  }

  get addressId(): number {
    if (!ArrayHelper.isAvailable(this.chaseDetails)) {
      return null;
    }

    const addressId = this.chaseDetails.find(item => item.key === "AID");
    if (addressId == null || !StringHelper.isAvailable(addressId.value)) {
      return null;
    }

    return +addressId.value;
  }

  get isMRR(): boolean {
    return StringHelper.isAvailable(this.fromPage) && this.fromPage === "mrr";
  }

  get isChaseInfoTabSelected(): boolean {
    const pathUrl = window.location.href;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return (activePath === "info" || activePath === this.chaseId.toString() || activePath === "newpages" || activePath === "removepages" || activePath === "encounter");
  }

  get isHealthCardTabSelected(): boolean {
    return this.router.url.includes("healthCard");
  }

  get isChaseInfoTab(): boolean {
    const pathUrl = window.location.href;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === "info";
  }

  get isChaseTab(): boolean {
    const pathUrl = window.location.href;
    const activePath = pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
    return activePath === this.chaseId.toString();
  }

  get hasChaseAssignAccess(): boolean {
    const roles = [DirectoryUserRole.ClinicalLead, DirectoryUserRole.MRRManager, DirectoryUserRole.OverreadManager,
                   DirectoryUserRole.ClientOverreadManager, DirectoryUserRole.AuditLead, DirectoryUserRole.AuditManager, DirectoryUserRole.Admin,
                  ];
    if (this.chaseDetailState.workflowStatusName === "Overread2") {
      roles.push(DirectoryUserRole.ClientLead);
     }
    return this.authService.user.directoryRoleIds.some(roleId => roles.includes(roleId));
  }

  get showAssignButton(): boolean {
    return this.hasChaseAssignAccess ;
  }

  get pathLastRoute(): string {
    const pathUrl = window.location.href;
    return pathUrl.substr(pathUrl.lastIndexOf("/") + 1).trim().toLowerCase();
  }


  ngOnInit() {
    this.menuService.showHeader.next(this.showTopBanner);
    this.toggleLeftNavigation();
    this.sink.add(
      this.route.paramMap.
        subscribe(params => this.chaseId = +params.get("chaseGd")),

      this.chaseDetailStateService.state
        .subscribe(state => {
          this.chaseDetailState = state;
          if (this.chaseDetailState.chaseId) {
            this.setMenuItems();
          }
          this.changeDetector.markForCheck();
        }),

      this.chaseDetailService.chaseDetailsChange
        .subscribe(chaseDetails => {
          this.chaseDetails = List(chaseDetails);
          if (!this.assignedToCurrentUser) {
            this.messagingService.showMessage("You are not currently assigned to this Chase.  Certain functions will not be available to you.", SeverityType.WARN);
          }
          this.changeDetector.markForCheck();
        })
    );

    this.fromPage = this.route.snapshot.paramMap.get("fromPage");
    if (this.isChaseInfoTabSelected) {
      this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
    }
  }

  toggleLeftNavigation() {
    this.hideLeftNavigation = !this.hideLeftNavigation;
    if (this.hideLeftNavigation) {
      this.renderer.addClass(this.document.body, "hideLeftNavigation");
      this.menuService.showHeader.next(false);
      this.isMainHeaderVisible.emit(false);
    } else {
      this.renderer.removeClass(this.document.body, "hideLeftNavigation");
      this.menuService.showHeader.next(true);
      this.isMainHeaderVisible.emit(true);
    }
  }
  navigateToLoginLanding(): void {
    this.router.navigateByUrl(`user/loginLanding`);
  }


  ngOnDestroy() {
    this.chaseDetailStateService.clearData();
    this.sink.unsubscribe();
    this.renderer.removeClass(this.document.body, "hideLeftNavigation");
    this.menuService.showHeader.next(true);
  }

  private setMenuItems(): void {
    const user = this.sessionService.get<any>(this.AUTHENTICATION_KEY, {});
    this.chaseDetailHeaderService
      .getMenuItems()
      .subscribe(menuItems => {
        menuItems.forEach(item => {
          if (item.path === "auditLog") {
            item.show = this.chaseDetailHeaderService.showAuditLogSubmenu;
          }
          if (item.path === "documents") {
            item.show = !user.enableThumbnailView;
          }
          if (item.path === "healthCard") {
            item.show = this.isRisk || this.isIVA;
          }
        });
        this.menuItems = List(menuItems);

        this.changeDetector.markForCheck();
      });
  }

  onUpdate($event) {
    this.router.navigate(["clinical", "view", "mrr"]);
  }

  setSelectedChases(): void {
    const createPend = {
      chaseId: this.chaseId,
      assignedToUserId: this.chaseDetailState.assignedToUserId,
    };
    const editPend = {
      chasePendId: this.pendChaseId,
      pendCode: this.pendCode,
      chaseId: this.chaseId,
      pendStatusId: this.pendStatusId,
      assignedToUserId: this.chaseDetailState.assignedToUserId,
    };
    this.selectedChases = this.isNewPend ? [createPend] : [editPend];
  }

  openPendModal(): void {
    this.setSelectedChases();
    this.checkChasesStatus();
    this.isPendModalVisible = true;
  }

  closeModal(): void {
    this.selectedChases = [];
  }

  updateChaseData() {
    this.sink.add(
      this.chaseDetailService.getSummaryItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailService.chaseDetailsChange.next(items);
          this.setPendDetails(items);
        })
    );
  }

  updateTimelineData() {
    this.sink.add(
      this.chaseDetailInfoService.getTimelineItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailStateService.timelineItems.next(items);
        })
    );
  }


  onMenuItemClick(path: string): void {
    path === "info" || "./" ? this.toggleClasses(true) : this.toggleClasses(false);
    if (path === "./") {
      this.showDataEntry.emit(true);
    } else {
      this.showDataEntry.emit(false);
    }
  }

  onBackArrowClick() {
    setTimeout(() => {
      this.toggleClasses(this.isChaseInfoTabSelected);
    },         1000);
  }

  toggleClasses(isChaseInfoTab: boolean): void {
    if (isChaseInfoTab) {
      this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
    } else {
      this.removeContainerHeightClass();
    }
  }

  ngAfterViewChecked(): void {
    this.sink.add(this.chaseDetailStateService.hasPageChanged.subscribe(value => {
      if (this.isChaseInfoTabSelected && !value) {
        this.containerHeight?.nativeElement?.offsetParent?.classList.add("container-header-wrap");
      }
      if (!this.isChaseInfoTabSelected && !value) {
        this.removeContainerHeightClass();
      }
    }));
  }

  private removeContainerHeightClass(): void {
    const el1 = this.containerHeight?.nativeElement?.offsetParent?.lastElementChild.getElementsByClassName("container-header-wrap")[0];
    const el2 = this.containerHeight?.nativeElement?.offsetParent?.lastElementChild.getElementsByClassName("container-body-wrap")[0];
    if (el1) {
      this.renderer.removeClass(el1, "container-header-wrap");
    }
    if (el2) {
      this.renderer.removeClass(el2, "container-body-wrap");
    }
  }

  checkChasesStatus(): void {
    this.selectedChaseStatus = [this.chaseDetailState.workflowStatus];
    this.clinical = this.chaseDetailState.workflowStatus === 2 || this.chaseDetailState.workflowStatus === 3 ? false : true;
  }

  private setSelectedChasesForAssignment(): void {
    this.selectedChases = [this.chaseId];
  }

  openAssignModal() {
    this.setSelectedChasesForAssignment();
    this.isAssignToChaseModalVisible = true;
  }

  updateChaseAssignedData() {
    this.getChaseDetails();
  }

  private getChaseDetails(): void {
    this.chaseDetailService.getChaseDetail(this.chaseId).subscribe(chaseDetail => {
      this.chaseDetailService.chaseDetailsChange.next(chaseDetail.keyValueItems);
      this.setPendDetails(chaseDetail.keyValueItems);
      const project = new Project({ ...chaseDetail });
      this.chaseDetailStateService.setData({
        ...chaseDetail,
        member: new Member({ ...chaseDetail }),
        project,
      });
    });
  }

  setPendDetails(items: ListItem[]): void {
    items.forEach(x => {
      switch (x.key) {
        case "PendStatusId":
          this.chaseDetailStateService.setData({
            pendStatusId: Number(x.value),
            hasPend: NumberHelper.isGreaterThan(Number(x.value), 0),
          });
          break;
        case "Pend Code":
          this.chaseDetailStateService.setData({
            pendCode: x.value,
          });
          break;
        case "PendCodeName":
          this.chaseDetailStateService.setData({
            pendName: x.value,
          });
          break;
        case "ChasePendId":
          this.chaseDetailStateService.setData({
            chasePendId: Number(x.value),
          });
          break;
        case "PendStatusName":
            this.chaseDetailStateService.setData({
              pendStatusName: x.value,
            });
            break;
        case "Status":
          this.chaseDetailStateService.setData({ reportingStatusName: x.value });
          break;
        default:
          break;
      }
    });
  }

}
