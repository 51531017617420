import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";

@Injectable({
  providedIn: "root",
})
export class OmissionCodeService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  getOmissionCodes(chaseId: number, attributeCode: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}risk/omissioncodes`;
    const attributeOption = { chaseId, attributeCode };
    return this.http.post(url, attributeOption).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );

  }
}
