<div class="retreival-document-review-container">
  <h3 data-qa="document-intake-page-header">Document Intake</h3>
  <div class="intake-menu-container">
    <app-tab-menu class="review-tabs-menu" orientation="top" (activeIndexChange)="onViewClicked($event)">
      <app-tab-panel header="Active" [selected]="view === 0"></app-tab-panel>
      <app-tab-panel header="Archive" [selected]="view === 1"></app-tab-panel>
      <app-tab-panel header="Processing" [selected]="view === 2"></app-tab-panel>
    </app-tab-menu>
  </div>
  <div class="users-land-stats-container">

    <div class="reviewheader" *ngIf="serverRequest">
      <div *ngIf="isActiveView" class="item-container">
        <div class="users-land-stats-keyItem" *ngFor="let item of getActiveStats(); let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item, lastActiveStat)">

          <span>{{item.key}}</span>
          <a class="users-land-stats-item bold">
            <span (click)="getDocuments(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
          </a>

        </div>
      </div>
      <div *ngIf="isArchiveView" class="item-container">
        <div class="users-land-stats-keyItem" *ngFor="let item of getArchiveStats(); let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item, lastArchiveStat)">

          <span>{{item.key}}</span>
          <a class="users-land-stats-item bold">
            <span (click)="getDocuments(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
          </a>

        </div>
      </div>
      <div *ngIf="isProcessingView" class="item-container">
        <div class="users-land-stats-keyItem" *ngFor="let item of getProcessingStats(); let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item, lastProcessingStat)">

          <span>{{item.key}}</span>
          <a class="users-land-stats-item bold">
            <span (click)="getDocuments(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
          </a>

        </div>
      </div>
    </div>
  <hr />

  <div class="document-intake-page-grid">
    <app-server-grid [configuration]="serverGridConfiguration"
                     [(request)]="serverRequest"
                     [refresh]="refreshGrid"
                     [(selection)]="serverGridSelection"
                     [actions]="serverGridActions"
                     [actionTemplate]="actionTemplate"
                     (dataLoaded)="gridDataLoaded($event)"
                     [filterTemplate]="filterForm"
                     [isLoadOnInit]="false"
                     [startCollapsed]="true">


      <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
        <div class="action-template-container">
          <app-button text="Assign To" (onClick)="openAssignModel(rowData); localActionPopover.hide();"></app-button>
          <app-button text="Download" (onClick)="downloadClick(rowData); localActionPopover.hide();"></app-button>
          <app-button text="Remove" (onClick)="removeClick(rowData); localActionPopover.hide();"></app-button>
        </div>
      </ng-template>

      <ng-template #filterForm let-form>
        <app-tab-menu orientation="left">

          <app-tab-panel header="File Name">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="filenameInput"></form-factory>
            </div>
          </app-tab-panel>
          <app-tab-panel header="Assigned To" [disabled]="disableIfProcessingView()">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="assignedToUserIdInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Status" class="filter">
            <div class="ui-g-12 ui-md-12 ui-lg-12 filter__status--header">
              <h4 class="filter__sub-header">STATUS</h4>
              <app-button classes="small"
                          [text]="toggleButtonText"
                          (onClick)="toggleAll($event)">
              </app-button>
            </div>
            <div class="ui-g-12 ui-md-10 ui-lg-10 filter__status" [ngClass]="getFilterClass(0)">
              <h4 class="filter__sub-header">Active (Non-Hold)</h4>
              <form-factory [formGroup]="form" [model]="activeStatusInput"></form-factory>
            </div>
            <div class="ui-g-12 ui-md-10 ui-lg-10 filter__status" [ngClass]="getFilterClass(0)">
              <h4 class="filter__sub-header">On Hold</h4>
              <form-factory [formGroup]="form" [model]="statusInput"></form-factory>
            </div>
            <div class="ui-g-12 ui-md-10 ui-lg-10 filter__status" [ngClass]="getFilterClass(1)">
              <h4 class="filter__sub-header">Completed</h4>
              <form-factory [formGroup]="form" [model]="completedStatusInput"></form-factory>
            </div>
            <div class="ui-g-12 ui-md-10 ui-lg-10 filter__status" [ngClass]="getFilterClass(1)">
              <h4 class="filter__sub-header">Removed</h4>
              <form-factory [formGroup]="form" [model]="removedStatusInput"></form-factory>
            </div>
            <div class="ui-g-12 ui-md-10 ui-lg-10 filter__status" [ngClass]="getFilterClass(2)">
              <h4 class="filter__sub-header">Processing</h4>
              <form-factory [formGroup]="form" [model]="processingStatusInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Document ID" >
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="documentIdInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Retrieval Method">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="retrievalMethodInput"></form-factory>
            </div>
          </app-tab-panel>
          
            <app-tab-panel header="Last Updated By" [disabled]="disableIfProcessingView()">
              <div class="ui-g-12 ui-md-12 ui-lg-12">
                <form-factory [formGroup]="form" [model]="lastUpdatedInput"></form-factory>
              </div>
            </app-tab-panel>
          
          <app-tab-panel header="Tags" *ngIf="tagsInputFilter.show" [disabled]="disableIfProcessingView()">
            <label class="tag-label">Tags</label>
            <div class="tag__filterform">
              <div class="ui-g-12 ui-md-12 ui-lg-12">
                <div class="ui-g-2 ui-md-2 ui-lg-2">
                  <form-factory class="tag__operatorform"
                                [formGroup]="form"
                                [model]="tagsSearchOperatorInput"
                                (onChange)="getSearchOperatorValue($event)">
                  </form-factory>
                </div>
                <div class="ui-g-10 ui-md-10 ui-lg-10">
                  <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                              class="tag-content__item tag-content__item--multitag-close-icon"
                              classes="transparent small"
                              icon="times-circle"
                              (onClick)="resetTagSearchInput()">
                  </app-button>
                  <form-factory class="tag__panelform"
                                [formGroup]="form"
                                [model]="tagsInput"
                                (onPanelShow)="onShowEvent(true)"
                                (onPanelHide)="onShowEvent(false)"
                                (keyup)="onKeyUp($event)">
                  </form-factory>
                </div>
              </div>

            </div>
          </app-tab-panel>
          <app-tab-panel header="AID">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="aidInput"></form-factory>
            </div>
          </app-tab-panel>
          <app-tab-panel header="Confirmation Number">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="confirmationInput"></form-factory>
            </div>
          </app-tab-panel>
        </app-tab-menu>
       
      </ng-template>

    </app-server-grid>

    <app-modal [(visible)]="isAssignModelVisible" header="Assigned To">
      <form>
        <div class="ui-g ui-fluid basic-filter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form [formGroup]="formGroup">
              <form-factory [formGroup]="formGroup" [model]="assignToUsers"></form-factory>
            </form>
          </div>
        </div>
      </form>
      <footer>
        <app-button class="header-button" text="ASSIGN" (onClick)="assignToClick()"
                    [disabled]="!hasSelectedUser"></app-button>
      </footer>
    </app-modal>


    <app-manage-tag-modal [(visible)]="isManageTagModalVisible"
                          (onHide)="closeModal()"
                          [(selectedData)]="selectedDocuments"
                          [entityName]="entityName"
                          [totalEntityCount]="totalEntityCount"
                          [tagType]="tagType"
                          (onUpdate)="gridRefresh()">
    </app-manage-tag-modal>
  </div>
</div>
