import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DashboardTagCount } from "./dashboard-tag-count.model";


export const mapTotalTagCount = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "TotalTagCount")
    .forMember("tagCount", o => o.tagCount)
    .forMember("tagTypeName", o => o.tagTypeName)
    .forMember("tagDashboardCount", o => o.tagDashboardCount);
};

export class TotalTagCount {
  tagCount: number;
  tagTypeName: string;
  tagDashboardCount: DashboardTagCount[];

  constructor(options: {
    tagCount: number;
    tagTypeName: string;
    tagDashboardCount: DashboardTagCount[];
  }) {

    this.tagCount = options.tagCount;
    this.tagTypeName = StringHelper.clean(options.tagTypeName);
    this.tagDashboardCount = options.tagDashboardCount || [];
  }

}
