<div [formGroup]="formGroup" class="control control--input control--checkbox-group" [hidden]="hidden">
    <div *ngIf="model.hasLabel" class="control__header">
      <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
      <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    </div>
    <p-listbox
      [hidden]="!hasOptions"
      [options]="options"
      [multiple]="true"
      [checkbox]="checkbox"
      [filter]="false"
      [showToggleAll]="false"
      [optionLabel]="optionText"
      [styleClass]="classes"
      dataKey="value"
      (onChange)="onChange.emit($event);"
      [id]="model.key"
      [formControlName]="model.key"
      [(ngModel)]="model.selectedOptions">
     <ng-template let-items pTemplate="item">
     <span></span>
     </ng-template>
     <ng-template let-item pTemplate="items">
            <span class="custom-pend">{{ item.label }}         
            </span>
            &nbsp;<a (click)= "editPendCode(item, model); $event.stopPropagation();" class="editPend">edit</a>
     </ng-template>
    </p-listbox>
</div>
  