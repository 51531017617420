export class ChaseDetailForAuditItem {
  chaseId: number;
  memberId: number;
  projectId: number;
  measureCode: string;
  measureId: number;
  numeratorCode: string;
  numeratorName: string;
  numeratorId: number;
  memberFirstName: string;
  memberLastName: string;
  memberDOB: string;
  action: string;
  auditPackageItemId?: number;
  auditReady?: string;
  auditPackageId: number;
  auditPackageIds?: number[];

  constructor(options: {
    chaseId?: number;
    memberId?: number;
    projectId?: number;
    measureCode?: string;
    measureId?: number;
    numeratorCode?: string;
    numeratorId?: number;
    memberFirstName?: string;
    memberLastName?: string;
    memberDOB?: string;
    auditReady?: string;
    action?: string;
    auditPackageItemId?: number;
    auditPackageId?: number;
    auditPackageIds?: number[];
  } = {}) {
    this.chaseId = options.chaseId;
    this.memberId = options.memberId;
    this.projectId = options.projectId;
    this.measureCode = options.measureCode;
    this.measureId = options.measureId;
    this.numeratorCode = options.numeratorCode;
    this.numeratorId = options.numeratorId;
    this.memberFirstName = options.memberFirstName;
    this.memberLastName = options.memberLastName;
    this.memberDOB = options.memberDOB;
    this.auditReady = options.auditReady;
    this.action = options.action;
    this.auditPackageItemId = options.auditPackageItemId;
    this.auditPackageId = options.auditPackageId;
    this.auditPackageIds = options.auditPackageIds;
  }
}
