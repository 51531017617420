import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { CatalyticSupportingLocation } from "./catalytic-supporting-location.model";

export const mapCatalyticNumerator = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CatalyticNumerator")
    .forMember("entityTypeId", o => o.entityTypeId)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("matchResult", o => o.matchResult)
    .forMember("accepted", o => o.accepted)
    .forMember("dateOfService", o => o.dateOfService)
    .forMember("medicalRecordPageNumber", o => o.medicalRecordPageNumber)
    .forMember("botPageNumber", o => o.botPageNumber)
    .forMember("result", o => o.result)
    .forMember("eventDeleted", o => o.eventDeleted)
    .forMember("eventId", o => o.eventId)
    .forMember("supportingLocation", o => {
      return o.supportingLocation ? automapper.map("default", "CatalyticSupportingLocation", o.supportingLocation) : {};
    });
};

export class CatalyticNumerator {
  entityTypeId?: number;
  entityTypeName?: string;
  matchResult?: number;
  accepted?: number;
  dateOfService?: string;
  medicalRecordPageNumber?: string;
  botPageNumber?: string;
  result?: string;
  eventDeleted?: boolean;
  eventId?: string;
  supportingLocation?: CatalyticSupportingLocation;

  constructor(options: Partial<CatalyticNumerator> = {}) {
    this.entityTypeId = options.entityTypeId;
    this.entityTypeName = StringHelper.clean(options.entityTypeName);
    this.matchResult = options.matchResult;
    this.accepted = options.accepted;
    this.dateOfService = StringHelper.clean(options.dateOfService);
    this.medicalRecordPageNumber = StringHelper.clean(options.medicalRecordPageNumber);
    this.botPageNumber = StringHelper.clean(options.botPageNumber);
    this.result = StringHelper.clean(options.result);
    this.eventDeleted = options.eventDeleted;
    this.eventId = StringHelper.clean(options.eventId);
    this.supportingLocation = options.supportingLocation;
  }
}
