import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { AnalyticsCategory } from "./analytics-category.model";


export const mapAnalyticsDashboardCount = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DashboardCount")
    .forMember("count", o => o.count)
    .forMember("analyticsType", o => o.analyticsType)
    .forMember("analyticsCategories", o => o.analyticsCategories);
};

export class DashboardCount {
  count: number;
  analyticsType: string;
  analyticsCategories: AnalyticsCategory[];

  constructor(options: {
    count: number;
    analyticsType: string;
    analyticsCategories: AnalyticsCategory[];
  }) {
    this.count = options.count;
    this.analyticsType = StringHelper.clean(options.analyticsType);
    this.analyticsCategories = options.analyticsCategories || [];
  }
}
