<div class="sub-menu__container">
  <div
  class="item"
  *ngFor="let menuItem of filteredMenuItems; let i = index; trackBy: trackByFn">
    <a
    [ngClass]="getActiveClass(menuItem)"
    [routerLink]="menuItem.path"
    routerLinkActive="active"    
    [routerLinkActiveOptions]="{ exact: isFirstItem(i) }"
    (click)="onMenuItemClick(menuItem.path)">
      <div class="button">
        <div class="description">{{menuItem.description}}</div>
      </div>
    </a>
  </div>
</div>
