import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { RadiobuttonComponent } from "./radiobutton.component";

export interface IRadiobuttonOptions extends IDynamicInputOptions {
  options?: { text: string; value: string | number }[];
  name?: string;
  subLabel?: string;
  tooltip?: string;
}

export class Radiobutton extends DynamicInput implements IRadiobuttonOptions {
    name: string;
    options: any[];
    subLabel: string;
    tooltip: string;

    readonly controlType = "radiobutton";
    readonly componentType = RadiobuttonComponent;


    constructor(options: IRadiobuttonOptions = {}) {
      super(options);
      this.options = options.options || [];
      this.name = options.name || this.createRandomName();
      this.subLabel = options.subLabel;
      this.tooltip = options.tooltip;
    }


    private createRandomName = (): string => {
      return Math.random().toString(36).substr(2, 9);
    }
  }
