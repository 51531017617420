import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SwitchComponent } from "./switch.component";

export interface ISwitchOptions extends IDynamicInputOptions {
  name?: string;
  subLabel?: string;
  preceedingOffLabel?: string;
  followingOnLabel?: string;
  tooltip?: string;
}

export class Switch extends DynamicInput implements ISwitchOptions {
    name: string;
    subLabel: string;
    preceedingOffLabel: string;
    followingOnLabel: string;
    options: any[];
    tooltip: string;

    readonly controlType = "switch";
    readonly componentType = SwitchComponent;

    constructor(options: ISwitchOptions = {}) {
      super(options);
      this.name = options.name || this.createRandomName();
      this.subLabel = options.subLabel;
      this.preceedingOffLabel = options.preceedingOffLabel;
      this.followingOnLabel = options.followingOnLabel;
      this.tooltip = options.tooltip;
    }

    private createRandomName = (): string => {
      return Math.random().toString(36).substr(2, 9);
    }
  }
