import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BASE_API_URL } from "../../../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class ChaseUploadDocumentService {

  constructor(
    private route: ActivatedRoute
  ) { }

  getRetrievalIdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.RetrievalId;
  }

  getRetrievalTypeIdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.RetrievalTypeId;
  }

}
