import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { List } from "immutable";
import { SubSink } from "subsink";
import { ListItem } from "../../../../../../shared/list/list-item";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";

@Component({
  selector: "retrieval-address-detail-info-access",
  templateUrl: "./address-detail-info-access.component.html",
  styleUrls: ["./address-detail-info-access.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoAccessComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  isEditAccessView = false;
  @Input() retrievalType: number;

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get addressId(): number {
    return this.addressDetailState.masterDocumentSourceId;
  }

  get accessInfo(): List<ListItem> {
    const adminInfo = [
      new ListItem({
        key: "Admin Contact:",
        value: this.addressDetailState.accessInfo.accessAdminContact,
      }),
      new ListItem({
        key: "Admin Phone:",
        value: this.addressDetailState.accessInfo.accessAdminPhone,
      }),
      new ListItem({
        key: "IT Contact:",
        value: this.addressDetailState.accessInfo.accessITContact,
      }),
      new ListItem({
        key: "IT Phone:",
        value: this.addressDetailState.accessInfo.accessITPhone,
      }),
      new ListItem({
        key: "EMR System:",
        value: this.addressDetailState.accessInfo.accessEmrSystem,
      }),
    ];

    if (this.retrievalType === DocumentSourceType.EMR) {
      adminInfo.push(
        new ListItem({
          key: "NOTES:",
          value: this.addressDetailState.accessInfo.accessEditNotes,
        })
      );
    }

    return List(adminInfo);
  }

  get emrCredentialInfo(): List<ListItem> {
    let credentialInfo = [];
    if (this.retrievalType === DocumentSourceType.FIELDTECH) {
      credentialInfo = [
        new ListItem({
          key: "NOTES:",
          value: this.addressDetailState.accessInfo.accessEditNotes,
        }),
      ];
    } else if (this.retrievalType === DocumentSourceType.EMR) {
      credentialInfo = [
        new ListItem({
          key: "URL:",
          value: this.addressDetailState.accessInfo.url,
        }),
        new ListItem({
          key: "Credential Status:",
          value: this.addressDetailState.accessInfo.credentialStatus,
        }),
        new ListItem({
          key: "2FA:",
          value: StringHelper.isAvailable(this.addressDetailState.accessInfo.twoFactorAuthorization) ? this.addressDetailState.accessInfo.twoFactorAuthorization.toString() : "",
        }),
        new ListItem({
          key: "Expiration Date:",
          value: StringHelper.isAvailable(this.addressDetailState.accessInfo.expirationDate) ? this.addressDetailState.accessInfo.expirationDate.toString() : "",
        }),
        new ListItem({
          key: "Username:",
          value: this.addressDetailState.accessInfo.userName.toString(),
        }),
        new ListItem({
          key: "Password:",
          value: this.addressDetailState.accessInfo.password.toString(),
        }),
      ];
    }

    return List(credentialInfo);
  }

  showEditAccessModal() {
    this.isEditAccessView = true;
    this.changeDetector.markForCheck();
  }

  receiveEditAccessValue($event) {
    this.isEditAccessView = $event;
  }

}
