import { IAutoMapper } from "../../../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../../../utilities/contracts/string-helper";
import { Encounter, IEncounterOptions } from "../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskHelper } from "../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";

export const mapEncounterSummary = (automapper: IAutoMapper): void => {
    automapper
        .createMap("RiskData", "EncounterSummary")
        .forMember("id", o => o.id)
        .forMember("encounterId", o => o.entityId)
        .forMember("isAdmin", o => RiskHelper.getAttribute("ClaimID", o).isAdmin)
        .forMember("enc", RiskHelper.getBoolValueCurry("EncounterFound"))
        .forMember("sig", RiskHelper.getBoolValueCurry("AcceptableSignature"))
        .forMember("claimId", RiskHelper.getStringValueCurry("ClaimID"))
        .forMember("providerSourceAliasId", RiskHelper.getStringValueCurry("ProviderSourceAliasID"))
        .forMember("provider", RiskHelper.getStringValueCurry("ProviderName"))
        .forMember("startDate", RiskHelper.getStringValueCurry("StartDate"))
        .forMember("endDate", RiskHelper.getStringValueCurry("EndDate"))
        .forMember("exempt", RiskHelper.getBoolValueCurry("ExemptFromScoring"))
        .forMember("nlpReviewResult", RiskHelper.getStringValueCurry("NlpReviewResult"))
        .forMember("diagnoses", o => ArrayHelper.isAvailable(o.diagnoses) ? automapper.mapMany("RiskData", "Diagnosis", o.diagnoses) : [])
        .forMember("isNlp", o => RiskHelper.getAttribute("ClaimID", o).isNlp)
        .forMember("pageNumber", RiskHelper.getNumberValueCurry("ChartPageNumber"))
        .forMember("type", RiskHelper.getStringValueCurry("EncounterType"))
        .forMember("encounterFound", RiskHelper.getStringValueCurry("EncounterFound"))
        .forMember("encounterFoundFromNLP", RiskHelper.getBoolValueCurryFromNLP("EncounterFound"));
};

export class EncounterSummary extends Encounter {
    private readonly substantiated = "Substantiated";
    private readonly unsubstantiated = "Unsubstantiated";

    groupedDiagnoses: {};
    encounterType: string;

    constructor(options: IEncounterOptions = {}) {
        super(options);
    }

    get totalSubstantiatedDx(): number {
        return this.diagnoses.filter(({ diagnosisStatus }) => diagnosisStatus === this.substantiated)?.length;
    }

    get totalUnsubstantiatedDx(): number {
        return this.diagnoses.filter(({ diagnosisStatus }) => diagnosisStatus === this.unsubstantiated)?.length;
    }

    get totalHccs(): number {
        return this.diagnoses.filter(d => StringHelper.isAvailable(d.hccs))?.length ?? 0;
    }

    get getEncounterStatus(): string {
        return this.encounterFound === "1" && !this.encounterFoundFromNLP ? "Valid" : this.encounterFound === "0" && !this.encounterFoundFromNLP ? "Non-Valid" : "Unreviewed";
    }

    setEncounterType(state: RiskState): void {
        const selectedEncounterType = state.encounterTypes.find(p => p.value === this.type);
        this.encounterType = StringHelper.clean((selectedEncounterType || {} as any).text);
      }
}

