 <div class="main">
  <div class="left_panel">
    <div class="logo">
      <app-logo></app-logo>
    </div>
  </div>
  <div class="right_panel">
    <div class="global-search" *ngIf="!isParentOrg">
      <platform-global-search></platform-global-search>
    </div>

    <div class="notifications" *ngIf="!isParentOrg">
      <platform-notifications></platform-notifications>
    </div>

    <div class="jobsqueue" *ngIf="!isParentOrg">
      <platform-jobsqueue></platform-jobsqueue>
    </div>

    <div class="account-menu">
      <platform-account-menu></platform-account-menu>
    </div>
  </div>
</div>
