import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Listbox } from "primeng/listbox";
import { SubSink } from "subsink";
import { DynamicControlDirective } from "../../dynamic-forms/dynamic-control-component.model";
import { FormService } from "../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-custom-pend-checkbox-group",
  templateUrl: "./custom-pend-checkbox-group.component.html",
  styleUrls: ["./custom-pend-checkbox-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPendCheckboxGroupComponent extends DynamicControlDirective<CheckboxGroup> implements OnInit, OnDestroy {
  @ViewChild(Listbox, { static: true }) primeListbox: Listbox;
  @Input() showSelectAll = true;
  private sink = new SubSink();
  @Output() selectedCheckBoxValue = new EventEmitter();
  checkbox = true;
  binary = false;
  selectedOptions: SelectableInput;
  allSelectedOptions: SelectableInput[];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  editPendCode(item: any) {
    this.onChange.emit(item.value.value);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get optionText(): string {
    return "text";
  }

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get primeAllChecked(): boolean {
    return this.primeListbox != null && this.primeListbox.allChecked;
  }

  get enableToggleButton(): boolean {
    return this.control.enabled && !this.model.isAdmin;
  }

  get toggleButtonText(): string {
    return this.primeAllChecked ? "Deselect All" : "Select All";
  }

  toggleAll(event): void {
    this.primeListbox.toggleAll(event);
  }

  get classes(): string {
    return this.getClasses("checkbox-group");
  }
}
