export class ClinicalQueueListItem {
  readonly srNo: number;
  readonly chaseID: number;
  readonly memberFirstName: string;
  readonly memberLastName: string;
  readonly memberDateOfBirth: string;
  readonly gender: string;
  readonly providerName: string;
  readonly projectName: string;
  readonly measure: string;

  constructor(options: {
    srNo: number;
    chaseID: number;
    memberFirstName: string;
    memberLastName: string;
    memberDateOfBirth: string;
    gender: string;
    providerName: string;
    projectName: string;
    measure: string;
  }) {
    this.srNo = options.srNo;
    this.chaseID = options.chaseID;
    this.memberFirstName = options.memberFirstName;
    this.memberLastName = options.memberLastName;
    this.memberDateOfBirth = options.memberDateOfBirth;
    this.gender = options.gender;
    this.providerName = options.providerName;
    this.projectName = options.projectName;
    this.measure = options.measure;
  }
}
