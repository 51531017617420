import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { FunctionalRole } from "../../../dashboard/retrieval/functional-role.enum";
import { CreateAddress } from "../../../retrieval/address-search/create-address.model";
import { ContactMethodType } from "../../cover-letter-template/contact-method-type.enum";
import { AddressSearchService } from ".././address-search.service";
import { AddressSearchResult } from "../address-search-result.model";

@Component({
    selector: "retrieval-address-search-contact-info",
    templateUrl: "./address-search-contact-info.component.html",
    styleUrls: ["./address-search-contact-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchContactInfoComponent implements OnInit {
    @Input() visible = false;
    @Input() addressModel: CreateAddress;
    @Input() commitmentDate: Date = null;
    @Input() moveType: string;

    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() onShow = new EventEmitter<null>(true);
    @Output() onHide = new EventEmitter<null>(true);
    @Output() onMoveChases = new EventEmitter<Date>();
    @Output() onAddressSelection = new EventEmitter<AddressSearchResult>();

    formGroupContact: FormGroup;
    contactName: Textbox;
    groupName: Textbox;
    email: Textbox;
    phone: Textbox;
    fax: Textbox;
    preferredRequestMethod: Dropdown;
    inheritChaseInput: Checkbox;
    notesMissing = false;
    notesText: string;
    addressGridselection: AddressSearchResult;

    get minDate(): Date {
        return new Date();
      }

    get isInvalidEmail(): boolean {
        return this.formGroupContact.get("preferredRequestMethod").value === ContactMethodType.Email.toString() && !StringHelper.isAvailable(this.formGroupContact.get("email").value);
    }

    get isInvalidFax(): boolean {
        return this.formGroupContact.get("preferredRequestMethod").value === ContactMethodType.Fax.toString() && !StringHelper.isAvailable(this.formGroupContact.get("fax").value);
    }

    get isMoveChaseVisible(): boolean {
        const functionalRoleIds = this.authService.user.functionalRoleIds;
        const allowedFunctionalRoleIds = [FunctionalRole.SYSTEMADMINISTRATOR,
                                          FunctionalRole.PROJECTADMINISTRATOR,
                                          FunctionalRole.DOCUMENTRETRIEVALLEAD,
                                          FunctionalRole.DOCUMENTINTAKELEAD,
                                          FunctionalRole.DOCUMENTQALEAD,
                                          FunctionalRole.DATAMANAGER,
                                          FunctionalRole.DATAREADER];
        return allowedFunctionalRoleIds.some(r => functionalRoleIds.indexOf(r) >= 0);
    }

    constructor(
        private readonly formService: FormService,
        private addressSearchService: AddressSearchService,
        private messagingService: MessagingService,
        private authService: AuthService,
        private changeDetector: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.createComponents();
        this.createForm();
    }

    createComponents(): void {
        this.contactName = new Textbox({
            key: "contactName",
            label: "Contact Name",
            placeholder: "Contact Name",
            validators: [Validators.maxLength(100)],
            errorMessages: {
            maxlength: "Contact Name can not be more than 100 characters.",
            },
        });

        this.groupName = new Textbox({
            key: "groupName",
            type: TextboxType.TEXT,
            label: "Group Name",
            validators: [Validators.required],
            errorMessages: {
                pattern: "Group name required",
            },
        });

        this.email = new Textbox({
            key: "email",
            type: TextboxType.TEXT,
            label: "Email",
            validators: [Validators.pattern(RegExHelper.emailPattern)],
            errorMessages: {
                pattern: "Email should be in right format.",
            },
        });

        this.phone = new Textbox({
            key: "phone",
            label: "Phone",
            validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), Validators.required],
            errorMessages: {
            maxlength: "Phone number cannot be more than 18 characters.",
            pattern: "Phone number should be in right format.",
            },
        });

        this.fax = new Textbox({
            key: "fax",
            label: "fax",
            placeholder: "XXX-XXX-XXXX",
            validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
            errorMessages: {
              maxlength: "Fax number cannot be more than 18 characters.",
              pattern: "Fax number should be in right format.",
            },
          });

        this.preferredRequestMethod = new Dropdown({
            key: "preferredRequestMethod",
            label: "Preferred Request Method",
            placeholder: "Select...",
            options: [
              new SelectableInput({ text: ContactMethodType[ContactMethodType.Fax], value: ContactMethodType.Fax.toString() }),
              new SelectableInput({ text: ContactMethodType[ContactMethodType.Email], value: ContactMethodType.Email.toString() }),
              new SelectableInput({ text: ContactMethodType[ContactMethodType.Mail], value: ContactMethodType.Mail.toString() }),
            ],
            validators: [Validators.required],
          });

        this.inheritChaseInput = new Checkbox({
            key: "inheritChase",
            label: "Chases being moved will inherit assignment from the destination AID (optional)",
        });
    }

    private createForm(): void {
        this.formGroupContact = this.formService.createFormGroup([
            this.contactName,
            this.groupName,
            this.email,
            this.phone,
            this.fax,
            this.preferredRequestMethod,
            this.inheritChaseInput]);
    }

    confirmChaseMove(): void {
        if (StringHelper.isAvailable(this.notesText)) {
            this.onMoveChases.emit(this.commitmentDate);
            this.addressGridselection.notes = this.notesText;
            this.onAddressSelection.emit(this.addressGridselection);
        } else {
            this.notesMissing = true;
        }
    }

    moveChases() {
        this.createNewAddress();
    }

    open(): void {
        this.visible = true;
        this.visibleChange.emit(true);
        this.onShow.emit(null);
    }

    close(): void {
        this.visible = false;
        this.visibleChange.emit(false);
        this.onHide.emit(null);
    }

    createNewAddress(): void {
        this.notesMissing = !StringHelper.isAvailable(this.notesText);
        if (this.emailFaxValidation()) {
            return;
        } else if (this.formGroupContact.valid && StringHelper.isAvailable(this.notesText)) {
            const createNewAddressModel = new CreateAddress({
                ...this.addressModel,
                groupName: this.formGroupContact.get("groupName").value,
                contactName: this.formGroupContact.get("contactName").value,
                email: this.formGroupContact.get("email").value,
                phone: this.formGroupContact.get("phone").value,
                faxNumber: this.formGroupContact.get("fax").value,
                weeklyContactMethodTypeId: this.formGroupContact.get("preferredRequestMethod").value,
            });

            this.addressSearchService.createAddress(createNewAddressModel).subscribe(
                newAID => {

                    this.addressGridselection = new AddressSearchResult(
                        {
                            masterDocumentSourceId: newAID,
                            documentSourceTypeName: "PSR",
                        });
                    this.confirmChaseMove();
                },
                err => {
                    this.messagingService.showToast("Error while creating new Address, please try again.", SeverityType.ERROR);
                }
            );
            this.changeDetector.markForCheck();

        } else if (!this.formGroupContact.valid) {
            this.markAllAsTouched();
        }
    }

    emailFaxValidation(): boolean {
        if (this.isInvalidEmail) {
            this.formGroupContact.get("email").setErrors({required: true});
            this.markAllAsTouched();
            return true;
        }

        if (this.isInvalidFax) {
            this.formGroupContact.get("fax").setErrors({required: true});
            this.markAllAsTouched();
            return true;
        }

        let faxHasErrors = false;
        let emailHasErrors = false;
        if (this.formGroupContact.get("fax").invalid) {
            Object.keys(this.formGroupContact.get("fax").errors).forEach(keyError => {
                if (keyError !== "required") {

                    faxHasErrors = true;
                }
            });

            if (!faxHasErrors) {
                this.formGroupContact.get("fax").setErrors(null);
                return false;
            }
        }

        if (this.formGroupContact.get("email").invalid) {
            Object.keys(this.formGroupContact.get("email").errors).forEach(keyErrorEmail => {
                if (keyErrorEmail !== "required") {

                    emailHasErrors = true;
                }
            });

            if (!emailHasErrors) {
                this.formGroupContact.get("email").setErrors(null);
                return false;
            }
        }

        return false;
    }

    private markAllAsTouched(): void {
        Object.keys(this.formGroupContact.controls).forEach(key => {
            const control = this.formGroupContact.get(key);
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        });
        this.formService.updateDom.next();
    }

    onNotesTextChange(): void {
        this.notesMissing = !StringHelper.isAvailable(this.notesText);
    }
}
