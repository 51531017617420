export class CodingModel {
  codingModel: string;
  codingModelId: number;
  values: string[];

  constructor(codingModel: string, values: string[]) {
    this.codingModel = codingModel;
    this.values = values;
  }
}
