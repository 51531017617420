import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../core/storage/local.service";
import { MenuService } from "../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { Widget } from "../widget.enum";
import { IAnnotationEvent } from "./annotation-event.model";
import { Annotation } from "./annotation.model";
import { AnnotationService } from "./annotation.service";

enum IconType {
  PLUS = "plus",
  TIMES = "times",
}

@Component({
  selector: "platform-widget-annotations",
  templateUrl: "./annotations.component.html",
  styleUrls: ["./annotations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AnnotationsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() chaseId: number;
  @Input() page: number;
  @Input() documentPageId: number;
  @Input() selectedPanel = Widget.ANNOTATIONS;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() sendNewHighlightId: EventEmitter<string> = new EventEmitter();

  private sink = new SubSink();
  private localStorageIsWindowOpen = "isWindowOpen";
  annotations: Annotation[] = [];
  isAdding = false;
  icon = IconType.PLUS;
  newAnnotation: Annotation;
  isLoading = true;
  isEditing = false;
  highlightId = "";
  highlightMatches: Annotation[] = [];
  getAnnotations$: Subscription;
  saveAnnotation$: Subscription;
  editAnnotation$: Subscription;
  deleteAnnotation$: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService,
    private annotationService: AnnotationService,
    private localService: LocalService,
    private menuService: MenuService
    ) { }

  get isDuplicateWindowOpen(): boolean {
    return this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  }

  ngOnInit(): void {
    this.sink.add(
      this.getAnnotations$,
      this.saveAnnotation$,
      this.editAnnotation$,
      this.deleteAnnotation$,
      this.annotationService.isRefreshAnnotations$.subscribe(refreshAnnotations => {
        if (refreshAnnotations) { this.getAnnotations(); }
      })
    );
    this.getAnnotations();
    this.highlightMatches = [];
  }

  ngAfterViewInit(): void {
    this.sink.add(
      this.menuService.isNewAnnotation$.subscribe(annotationHighlight => {
        if (annotationHighlight.createNewAnnotation && !this.isAdding && !this.isEditing) {
          this.highlightId = StringHelper.isAvailable(annotationHighlight.highlightId) ? annotationHighlight.highlightId : null;
          this.page = NumberHelper.isAvailable(annotationHighlight.page) ? annotationHighlight.page : 0;
          this.onAction();
        }
        if (!annotationHighlight.createNewAnnotation && !this.isAdding && !this.isEditing && StringHelper.isAvailable(annotationHighlight.highlightId)) {
          this.highlightId = StringHelper.isAvailable(annotationHighlight.highlightId) ? annotationHighlight.highlightId : "";
          this.changeDetector?.markForCheck();
        }
      }),
      this.annotationService.getCurrentAnnotationsMatches$().subscribe(matches => {
        this.highlightMatches = [...matches];
        this.changeDetector?.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.annotationService?.setAnnotationsMatches([]);
    this.sink.unsubscribe();
  }

  getAnnotations(): void {
    this.getAnnotations$ = this.annotationService.getAnnotations$(this.chaseId)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(res => this.onSuccessAnnotations(res), () => this.showError());
  }

  onSuccessAnnotations(res: Annotation[]): void {
    this.annotations = [...res];
    this.annotationService.setAnnotations([...this.annotations]);
    this.annotationService.setAnnotationsTotal(this.annotations.length);
    this.changeDetector.markForCheck();
  }

  onAction(): void {
    this.isAdding = !this.isAdding;
    if (this.isAdding) {
      this.newAnnotation = this.getNewAnnotation();
      setTimeout(() => {
        const textareas = document.getElementsByClassName("text --regular ng-untouched ng-pristine ng-invalid") as HTMLCollectionOf<HTMLElement>;
        textareas[0]?.focus();
      },         200);
    }
    this.updateHighlightId("");
    this.changeDetector.markForCheck();
  }

  getNewAnnotation(): Annotation {
    const newAnnotation: Partial<Annotation> = {
      chaseId: this.chaseId,
      pageNumber: this.page,
      text: "",
      createDateTime: new Date(),
      documentPageId: this.documentPageId,
      highlightId: this.highlightId,
    };
    return newAnnotation as Annotation;
  }

  onSave(event: IAnnotationEvent): void {
    this.isAdding = false;
    this.isLoading = true;
    this.save(event.annotation);
    this.annotationService.setAnnotationsTotal(this.annotations.length + 1);
    this.changeDetector.markForCheck();
  }

  onDelete(event: IAnnotationEvent): void {
    this.delete(event.annotation, () => this.annotations.splice(event.index, 1));
    this.annotationService.setAnnotationsTotal(this.annotations.length - 1);
  }

  onEdit(event: IAnnotationEvent): void {
    this.edit(event.annotation, () => this.annotations[event.index] = event.previousAnnotation);
  }

  trackByIndex(index: number): number {
    return index;
  }

  arrayHasItems(array: Annotation[]): boolean {
    return ArrayHelper.isAvailable(array);
  }

  save(annotation: Annotation): void {
    this.saveAnnotation$ = this.annotationService.save(annotation).subscribe(
      () => this.getAnnotations(),
      () => this.showError()
    );
  }

  edit(annotation: Annotation, errorFn: () => void): void {
    this.editAnnotation$ = this.annotationService.edit(annotation).pipe(
      finalize(() => this.changeDetector?.markForCheck())
    ).subscribe(
      () => undefined,
      () => {
        errorFn();
        this.showError();
      }
    );
  }

  delete(annotation: Annotation, successFn: () => void): void {
    this.deleteAnnotation$ = this.annotationService.delete(annotation).pipe(
      finalize(() => this.changeDetector?.markForCheck())
    ).subscribe(
      successFn,
      () => this.showError()
    );
  }

  showError(message = "Oops! There was a hiccup. Please refresh and try again."): void {
    this.messagingService.showToast(message, SeverityType.ERROR);
  }

  onChangePage(page: number): void {
    this.pageChange.emit(page);
    this.changeDetector?.markForCheck();
  }

  updateHighlightId(event: string): void {
    this.highlightId = event === this.highlightId || event === undefined ? "-" : event;
    this.menuService.newHighlightId(this.highlightId);
  }

  onCardClick(entityId: number): void {
    this.annotationService.setCurrentEntityId$(entityId);
  }

}
