import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from "@angular/core";
import { CommentItem } from "./comment-item/comment-item";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent {
  @Input() commentItems: CommentItem[] = [];

  trackByIndex(index, item) {
    return index;
  }
}
