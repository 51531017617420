import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { IAutoMapper } from "../interfaces";

export const mapDynamicEntityAttribute = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DynamicEntityAttribute")
    .forMember("chaseId", o => o.chaseId)
    .forMember("entityId", o => o.entityId)
    .forMember("entityTypeId", o => o.entityTypeId)
    .forMember("id", o => o.id)
    .forMember("isChanged", o => o.isChanged)
    .forMember("code", o => o.code)
    .forMember("value", o => o.value)
    .forMember("options", o => {
      let selectableInputOptions = o.options;
      if (typeof o.options === "string" && o?.options) {
        const options = JSON.parse(o.options);
        if (ArrayHelper.isAvailable(options)) {
          selectableInputOptions = (options as any[]).map(automapper.curry("default", "SelectableInput"));
        }
      }

      return selectableInputOptions;
    });
};

