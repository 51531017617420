import { IAutoMapper } from "../interfaces";

export const mapBulkOutReachQueryResult = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "BulkOutReachQueryResult")
    .forMember("projectCount", o => o.projectCount)
    .forMember("addressCount", o => o.addressCount)
    .forMember("chaseCount", o => o.chaseCount);

};
