import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export const mapDocumentQueueResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentQueueResponse")
    .forMember("createDate", o => o.createDate)
    .forMember("updateDate", o => o.updateDate)
    .forMember("documentStateID", o => o.documentStateID);
};
export class DocumentQueueResponse {
  createDate: string;
  updateDate: string;
  documentStateID: number;

  constructor(options: {
    createDate?: string;
    updateDate?: string;
    documentStateID?: number;

  }) {
    this.createDate = StringHelper.clean(options.createDate);
    this.updateDate = options.updateDate;
    this.documentStateID = options.documentStateID;
  }
}
