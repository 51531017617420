import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BasicGridComponent } from "../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { CreatePendService } from "../../../../../shared/pend/create-pend.service";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { BulkUpdateChaseService } from "../bulk-update-chase/bulk-update-chase.service";
import { BulkUpdate } from "../bulk-update.model";
import { BulkUpdatesComponent } from "../bulk-updates.component";
import { BulkUpdatesService } from "../bulk-updates.service";

@Component({
  selector: "app-bulk-update-pend",
  templateUrl: "./bulk-update-pend.component.html",
  providers: [BulkUpdateChaseService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdatePendComponent implements OnInit {
  isCancelConfirmationModalVisible = false;
  isResolveConfirmationModalVisible = false;
  formData: FormData = new FormData();
  formGroup: FormGroup;
  chasesNotes: Textarea;
  checkError = false;
  isShowValidateGrid = false;
  serverGridConfiguration = new GridConfiguration();
  serverRequest = new GridRequest();
  serverGridSelection: any;
  bulkUpdate: BulkUpdate;
  data: any;
  @Output() toggleControl = new EventEmitter();
  @Input() actionControls: string[] = [];
  bulkUpdateActionItem = "bulkUpdateActions";
  chaseIds = "chaseIds";
  chaseIdsList: string[];
  private modelErrors: any[] = [];
  private newLine = "\r";
  notesErrorMessages = "";
  selectedPendStatus: number;
  pendOwner: string;
  pendStatus: string;
  isShowNotes: boolean;

  invoiceNumberInput: Textbox;
  invoiceAmountInput: Textbox;
  pendCompanyInput: Dropdown;
  @Output() isShowContinueButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isShowEditBulk: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(BasicGridComponent) private basicGridComponent: BasicGridComponent;


  constructor(
    private readonly formService: FormService,
    private service: BulkUpdateChaseService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private createPendService: CreatePendService,
    private readonly automapper: AutomapperService,
    private bulkUpdatesComponent: BulkUpdatesComponent,
    private bulkUpdatesService: BulkUpdatesService
  ) { }

  ngOnInit() {
    this.getNotes();
    if (this.isBulkPendUpdate) {
      this.getPendCompanyDropdownList();
      this.getThirdPartyPendControls();
      this.formGroup = this.formService.createFormGroup([this.invoiceNumberInput, this.invoiceAmountInput, this.pendCompanyInput, this.chasesNotes]);
    } else {
      this.formGroup = this.formService.createFormGroup([this.chasesNotes]);
    }
  }

  getNotes(): void {
    this.chasesNotes = new Textarea({
      key: "chasesNotes",
      label: "",
      placeholder: "Type notes here...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
    });
    this.modelErrors = [
      { key: "chasesNotes", message: "Please enter notes. Minimum 4 characters and Maximum 1000 characters allowed" },
    ];
  }

  validate() {
    this.bulkUpdatesComponent.validateContinue();
    this.checkError = false;
    const notes = this.formGroup.value?.chasesNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.chasesNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.chasesNotes.key).setErrors(null);
    }

    if (this.formGroup.invalid) {
      this.notesErrorMessages = "";
      this.isShowNotes = true;
      this.DisplayErrors();
      this.checkError = true;
      this.formService.markAllAsTouched(this.formGroup);
    }

    if (this.checkError) {
      return;
    }

    if (this.isBulkPendUpdate) {
      this.validateInvoicePends();
    } else {
      this.getValidatedCreatePends();
    }
    this.isShowContinueButton.emit(false);
  }

  getValidatedCreatePends(): void {
    if (!this.actionControls[this.chaseIds]) {
      this.formGroup.controls.selectedChaseIds.setErrors({ required: true });
      return;
    }
    this.isShowValidateGrid = true;
    this.isShowEditBulk.emit(false);
    if (NumberHelper.isAvailable(this.bulkUpdatesComponent.bulkPendActionValue as number)) {
      this.selectedPendStatus = this.bulkUpdatesComponent.bulkPendActionValue as number;
    } else {
      this.pendOwner = this.bulkUpdatesComponent.bulkPendActionValue as string;
    }
    this.pendStatus = NumberHelper.isAvailable(this.selectedPendStatus) ? String(this.selectedPendStatus) : null;

    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chasePendID ", header: "Pend" }),
      new GridColumnDefinition({ field: "oldPendStatus", header: "Old PendStatus", show: StringHelper.isAvailable(this.pendStatus) }),
      new GridColumnDefinition({ field: "newPendStatus ", header: "New PendStatus", show: StringHelper.isAvailable(this.pendStatus) }),
      new GridColumnDefinition({ field: "oldPendOwner", header: "Old PendOwner", show: StringHelper.isAvailable(this.pendOwner) }),
      new GridColumnDefinition({ field: "newPendOwner", header: "New PendOwner", show: StringHelper.isAvailable(this.pendOwner) }),
      new GridColumnDefinition({ field: "validationStatus", header: "ValidationStatus" }),
      new GridColumnDefinition({ field: "message", header: "Message" }),
    ];
    const filters = [
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Status",
        value: this.pendStatus,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Owner",
        value: this.pendOwner,
        show: false,
      }),
    ];
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/pend";
    this.getBulkValidationData(url);
  }

  getBulkValidationData(url: string) {
    this.serverRequest.setForm();
    const request = this.serverRequest.createRequestObject();
    this.service.getBulkValidationData(url, request).subscribe(res => {
      this.data = res;
      this.changeDetector.markForCheck();
    });
  }

  validateInvoicePends(): void {
    this.isShowValidateGrid = true;
    this.isShowEditBulk.emit(true);
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chasePendID ", header: "Pend" }),
      new GridColumnDefinition({ field: "oldInvoiceNumber", header: "Old Invoice Number" }),
      new GridColumnDefinition({ field: "oldAmount ", header: "Old Invoice Amount" }),
      new GridColumnDefinition({ field: "oldPendCompany", header: "Old Company" }),
      new GridColumnDefinition({ field: "validationStatus", header: "ValidationStatus" }),
      new GridColumnDefinition({ field: "message", header: "Message" }),
    ];
    const filters = [
      new GridFilter({
        input: new Textbox(),
        key: "ChaseIdCsv",
        value: this.actionControls[this.chaseIds],
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("chasesNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "invoiceNumber",
        value: this.formGroup.get("invoiceNumber").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "amount",
        value: this.formGroup.get("amount").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "pendCompanyId",
        value: this.formGroup.get("pendCompanyId").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "isThirdParty",
        value: "true",
        show: false,
      }),
    ];
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    const url = "bulkupdate/validate/pend";
    this.getBulkValidationData(url);
  }

  confirmBulkUpdate() {
    const pendStatus = this.getPendStatus(this.actionControls[this.bulkUpdateActionItem]);
    switch (pendStatus) {
      case PendStatus.Resolved:
        this.isResolveConfirmationModalVisible = true;
        break;
      case PendStatus.Closed:
        this.isCancelConfirmationModalVisible = true;
        break;
      default:
        this.finalBulkUpdate();
        break;
    }
  }
  finalBulkUpdate() {
    this.chaseIdsList = this.actionControls[this.chaseIds].split(",");
    this.bulkUpdate = new BulkUpdate({
      clientId: 0,
      chaseIds: this.chaseIdsList,
      status: this.pendStatus,
      assignedToUserId: 0,
      notes: `${this.formGroup.get("chasesNotes").value} via Bulk Action`,
      bulkType: 2,
      bulkAction: this.actionControls[this.bulkUpdateActionItem],
      owner: this.pendOwner,
      action: 1,
      pendType: null,
      amount: this.formGroup.get("amount") ? this.formGroup.get("amount").value : null,
      invoiceNumber: this.formGroup.get("invoiceNumber") ? this.formGroup.get("invoiceNumber").value : null,
      pendCompanyId: this.formGroup.get("pendCompanyId") ? this.formGroup.get("pendCompanyId").value : null,
      isThirdParty: this.isBulkPendUpdate,
      projectId: null,
    });
    this.service.finishBulkUpdatePend(this.bulkUpdate).subscribe(data => {
      if (data > 0) {
        this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      } else {
        this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
        this.changeDetector.markForCheck();
      }
    });
  }

  hasValue(): boolean {
    return this.formGroup.get("chasesNotes").value;
  }

  private DisplayErrors() {
    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        const control = this.formGroup.controls[key];
        if (control.invalid) {
          const modelError = this.modelErrors.filter(obj => {
            return obj.key === key;
          });

          if (modelError) {
            this.notesErrorMessages += modelError[0].message + this.newLine;
          }
        }
      }
    }
  }

  getThirdPartyPendControls() {
    this.invoiceNumberInput = new Textbox({
      key: "invoiceNumber",
      label: "Invoice Number",
      validators: [
        Validators.maxLength(10),
      ],
      errorMessages: {
        maxlength: "Invoice number can not be more than 10 characters.",
      },
    });
    this.invoiceAmountInput = new Textbox({
      key: "amount",
      label: "Invoice Amount",
      type: TextboxType.NUMBER,
      validators: [
        Validators.min(0),
        Validators.max(10000),
        Validators.pattern(RegExHelper.invoiceAmount),
      ],
      errorMessages: {
        min: "Enter number between 0 - 10000.",
        max: "Enter number between 0 - 10000.",
        pattern: "Enter a dollar amount between 0 - 10000.",
      },
      placeholder: "0.00",
    });
    this.pendCompanyInput = new Dropdown({
      key: "pendCompanyId",
      label: "Select Company",
      placeholder: "Select...",
    });
  }

  protected getPendCompanyDropdownList(): void {
    this.createPendService.getPendCompaniesDropdown()
      .pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(data => {
        this.pendCompanyInput = new Dropdown({ ...this.pendCompanyInput, options: data } as any);
      });
  }

  get isBulkPendUpdate(): boolean {

    return BooleanHelper.isAvailable(this.bulkUpdatesComponent.bulkPendActionValue as boolean);
  }

  get isFinalBulkUpdate(): boolean {
    if (this.basicGridComponent && ArrayHelper.isAvailable(this.basicGridComponent.data)) {
      return this.bulkUpdatesService.checkValidationStatus(this.basicGridComponent.data);
    }
  }

  checkNotes(): void {
    this.isShowNotes = this.formGroup.invalid;
  }

  cancelConfirmation($event): void {
    this.isCancelConfirmationModalVisible = false;
    this.isResolveConfirmationModalVisible = false;
  }

  getPendStatus(action): PendStatus | null {
    switch (action) {
      case 1:
        return PendStatus.Resolved;
      case 2:
        return PendStatus.Closed;
      default:
        return null;
    }
  }
}
