<div class="container">
  <div [ngClass]="getStatusClass()">
    <div class="row1">
      <div class="col1">
        <div class="iconContainer iconFolder">
          <app-icon [iconName]="getStateMachineIcon()"></app-icon>
        </div>
      </div>
      <div class="col2">

        <div *ngIf="useInfoFormat1">
          <div class="info">
            <div><span class="label">{{jobQueueItem.sourceName}}</span>   </div>
            <span class="label">TYPE: </span>{{ jobQueueItem.stateMachineName}}/ <span class="label">FOR: </span>
            <a [routerLink]="getInfoLink()" [ngClass]="{'pointer-none': jobQueueItem.statusId !== 1}"><span class="bold">{{getInfoText()}}</span></a>
          </div>
        </div>
        <div *ngIf="useInfoFormat2">
          <div class="info">
            <div class="title">
              <a [routerLink]="getInfoLink()" [ngClass]="{'pointer-none': jobQueueItem.statusId !== 1}"><span class="bold">{{ jobQueueItem.sourceName}}</span></a>
            </div>
            <span class="label">TYPE: </span>{{ jobQueueItem.stateMachineName}}/ <span class="label">FOR: </span>
            <span>{{getInfoText()}}</span>
          </div>
        </div>
        <div *ngIf="useInfoFormat3">
          <div class="info">
            <div class="title">
              <a   (click)="openWindow('_self')" [ngClass]="{'pointer-none': jobQueueItem.statusId !== 1}"><span class="bold">{{ jobQueueItem.sourceName}}</span></a>
            </div>
            <span class="label">TYPE: </span>{{ jobQueueItem.stateMachineName}}
          </div>

        </div>
        <div *ngIf="useInfoFormat4">
          <div class="info">
            <div><span class="label">{{jobQueueItem.sourceName}}</span>   </div>
            <span class="label">TYPE: </span>{{ jobQueueItem.stateMachineName}}/ <span class="label">FOR: </span>
            <a  (click)="navigateToJob()" [ngClass]="{'pointer-none': jobQueueItem.statusId !== 1}"><span class="bold">{{getInfoText()}}</span></a>
          </div>
        </div>
        <div *ngIf="useInfoFormat5">
          <div class="info">
            <div><span class="label">{{jobQueueItem.sourceName}}</span>   </div>
            <span class="label">TYPE: </span>{{ jobQueueItem.stateMachineName}}/ <span class="label">FOR: </span>
            <span class="bold">{{getInfoText()}}</span>
          </div>
        </div>
      </div>
      <div class="col3">
        <div class="iconContainer statusIcon">
          <app-icon [iconName]="statusIcon()"></app-icon>
        </div>
      </div>
    </div>
    <div class="row2">
      <div class="col1"><span class="label">START TIME: </span>{{formatStartTime()}} / <span class="label">DURATION:  </span>{{formatDuration()}}</div>
      <div class="col2"><span class="label">STATUS: {{statusName()}}</span> </div>
    </div>
  </div>
</div>


