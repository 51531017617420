import { InvoiceChartType } from "./chart-type.model";
import { InvoiceType } from "./invoice-type.enum";

export class InvoiceSetting {
  vendorId: number;
  invoiceTypeId: InvoiceType;
  chartTypes: InvoiceChartType[];
  price: number;
  isReadOnly: boolean;

  constructor(options: {
    invoiceTypeId: InvoiceType;
    chartTypes?: InvoiceChartType[];
    price?: number;
    vendorId?: number;
    isReadOnly?: boolean;
  }) {
    this.invoiceTypeId = options.invoiceTypeId;
    this.chartTypes = options.chartTypes;
    this.price = options.price;
    this.vendorId = options.vendorId;
    this.isReadOnly = options.isReadOnly;
  }
}
