<div class="member-gaps-container">
  <app-gap-grid [chaseId]="chaseId"
                [additionalBulkActions]="additionalBulkActions"
                [additionalColumns]="additionalColumns"
                [(selection)]="selection"
                [isSelectionModeMultiple]=true
                [isLoadOnInit]="true">
  </app-gap-grid>

  <app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible"
                              (onHide)="resetGridView()"
                              (onUpdate)="gapGridComponent.refreshGrid.emit()"
                              [(chases)]="selectedChaseIdsForAssignment"
                              [isUnassignEnabled]="isUnassignEnabled">
  </app-chase-assignment-modal>
</div>
