import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { RetrievalCallRecordDetail } from "../../../retrieval-detail-call-record";
import { RetrievalContactHistory } from "../../../retrieval-detail-contact-history.model";

@Injectable({
  providedIn: "root",
})
export class FtDetailContactHistoryService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getFTGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd;
  }

  getContactHistoryList(): Observable<RetrievalContactHistory[]> {
    const ftId = this.getFTGdFromPath();
    const url = `${this.baseApiUrl}Retrieval/ContactHistory?documentSourceId=${ftId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "RetrievalContactHistory"))
    );
  }


}
