<div class="chart-download-container">
  <div class="header bold">CHART DOWNLOAD - {{projectName}}</div>

  <div>
    <app-basic-grid [configuration]="gridConfigurationModel"
                     [data]="projectFiles" 
                     [actionTemplate]="actionTemplate">

      <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
        <div class="action-template-container">
          <app-button class="uploading" text="View File" (onClick)="onViewFile(rowData)"></app-button>
          <app-button text="Delete File" (onClick)="onDeleteFile(rowData); localActionPopover.hide();"></app-button>
        </div>
      </ng-template>


    </app-basic-grid>
  </div>

</div>
