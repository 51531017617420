import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-detail-template",
  templateUrl: "./detail-template.component.html",
  styleUrls: ["./detail-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailTemplateComponent {}

