import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

@Injectable({
  providedIn: "root",
})
export class IcdService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  getIcds(chaseId: number, icdCode: string, dateOfService: dateTypes): Observable<SelectableInput[]> {
    const cleanIcdCode = StringHelper.clean(icdCode);
    const baseUrl = `${this.baseApiUrl}risk/icd?chaseId=${chaseId}&icdCode=${cleanIcdCode}`;

    const validDate = DateHelper.format(dateOfService);
    const url = StringHelper.isAvailable(validDate) ? `${baseUrl}&dateOfService=${validDate}` : baseUrl;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("Icd", "SelectableInput"))
    );
  }


  getIcdsForDxCard(chaseId: number, icdCode: string, dateOfService: dateTypes, modelId?: string, categoryCode?: string): Observable<SelectableInput[]> {
    const cleanCategoryCode = StringHelper.clean(categoryCode);
    const cleanIcdCode = StringHelper.clean(icdCode);
    const baseUrl = `${this.baseApiUrl}risk/icdfilteredbyhcc?chaseId=${chaseId}&icdCode=${cleanIcdCode}&modelId=${modelId}&categoryCode=${cleanCategoryCode}`;

    const validDate = DateHelper.format(dateOfService);
    const url = StringHelper.isAvailable(validDate) ? `${baseUrl}&dateOfService=${validDate}` : baseUrl;
    const requestBody = {
      chaseId,
      icdCode: cleanIcdCode,
      modelId,
      categoryCode: cleanCategoryCode,
      dateOfService: DateHelper.format(dateOfService),
    };
    return this.http.post(url, requestBody).pipe(
      map(this.automapper.curryMany("Icd", "SelectableInput"))
    );
  }


}
