import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnInit, ViewChild } from "@angular/core";
import { TabPanel } from "primeng/tabview";
import { StringHelper } from "../../../utilities/contracts/string-helper";

@Component({
  selector: "app-tab-panel",
  templateUrl: "./tab-panel.component.html",
  styleUrls: ["./tab-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPanelComponent implements OnInit, DoCheck {
  @Input() selected: boolean;
  @Input() header = "";
  @Input() rightIcon: string;
  @Input() leftIcon: string;
  @Input() disabled = false;
  private previousIsActive = false;

  @ViewChild(TabPanel, { static: true }) primeTabPanel: TabPanel;


  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.header = StringHelper.toTitleCase(this.header);
    this.cd.markForCheck();
  }

  ngDoCheck() {
    if (this.primeTabPanel.selected !== this.previousIsActive) {
      this.previousIsActive = this.primeTabPanel.selected;
      this.cd.markForCheck();
    }

  }
}
