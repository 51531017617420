<app-modal 
  [(visible)]="modalVisible"
  (onHide)="onHide()"
  header="SAVE QUERY">
  <form [formGroup]="formGroup">
    <form-textbox [formGroup]="formGroup" [model]="queryNameInput"></form-textbox>
    <form-checkbox [formGroup]="formGroup" [model]="isPublicQueryCheckbox" ></form-checkbox>
  </form>
  <footer class="text-center">
    <app-button text="SAVE"
                (onClick)="saveQuery()"
                [disabled]="!isValid">
    </app-button>
  </footer>
</app-modal>