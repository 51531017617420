import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ChaseDetailStateService } from "../../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { CalculatedGroupDirective } from "../calculated-group-component.model";
import { PpcService } from "../ppc.service";

@Component({
  selector: "app-early-late-indicator-group",
  templateUrl: "./early-late-indicator-group.component.html",
  styleUrls: ["./early-late-indicator-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EarlyLateIndicatorGroupComponent extends CalculatedGroupDirective {
  constructor(
    ppcService: PpcService,
    changeDetector: ChangeDetectorRef,
    chaseDetailStateService: ChaseDetailStateService
  ) {
    super(ppcService, changeDetector, chaseDetailStateService);
  }


  get earlyOrLateText(): string {
    if (!this.hasPpc || !StringHelper.isAvailable(this.ppc.enrollmentType)) {
      return "";
    }

    const type = this.ppc.enrollmentType.toLowerCase();
    switch (type) {
      case "early":
        return "Member is Early Enrollment";
      case "late":
        return "Member is Late Enrollment";
      default:
        return "None"; // throw new Error(`The enrollment type '${type}' does not exist.`);
    }
  }

  get fontColorClass(): string {
    if (!this.hasPpc || !StringHelper.isAvailable(this.ppc.enrollmentType)) {
      return "";
    }

    const type = this.ppc.enrollmentType.toLowerCase();
    switch (type) {
      case "early":
        return "control__header__label--early";
      case "late":
      return "control__header__label--late";
      default:
        return "";
    }
  }
}
