import { IAutoMapper } from "../interfaces";

export const mapJobQueueItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "JobQueueItem")
    .forMember("stepExecutionId", o => o.stepExecutionId)
    .forMember("stateMachineId", o => o.stateMachineId)
    .forMember("stateMachineName", o => o.stateMachineName)
    .forMember("stateMachineRequestId", o => o.stateMachineRequestId)
    .forMember("statusId", o => o.statusId)
    .forMember("statusName", o => o.statusName)
    .forMember("startDateTime", o => o.startDateTime)
    .forMember("elapsedSeconds", o => o.elapsedSeconds)
    .forMember("message", o => o.message)
    .forMember("objectType", o => o.objectType)
    .forMember("objectId", o => o.objectId)
    .forMember("userId", o => o.userId)
    .forMember("sourceName", o => o.sourceName)
    .forMember("jobLink", o => o.jobLink);

};
