import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";

@Injectable({
  providedIn: "root",
})
export class ChaseDetailTimelineService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getChaseIdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.chaseGd;
  }

  getChaseTimelineActivityItems(): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/chaseTimelineActivity?chaseId=${this.getChaseIdFromPath()}`;

    return this.http.get(url).pipe(
        map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }
}
