import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { RiskService } from "../risk.service";
import { RiskError } from "./risk-error.model";

@Component({
  selector: "member-risk-error-modal",
  templateUrl: "./risk-error-modal.component.html",
  styleUrls: ["./risk-error-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskErrorModalComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  visible = false;
  errors: RiskError[];

  constructor(
    private riskService: RiskService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.riskService.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        this.visible = state.hasErrors;
        this.errors = state.hasErrors ? state.errors : [];
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  getDisplayText(error: RiskError): string {
    return error.message;
  }

  gotoError(error: RiskError): void {
    const selectedIndices = this.getIndexes(error);
    this.riskService.setData(selectedIndices);
  }

  clearErrors(): void {
    this.riskService.setData({ errors: [] });
  }

  private getIndexes(error: RiskError): { selectedEncounterIndex: number; selectedDiagnosisIndex: number } {
    const encounterEntityId = error.hasParent ? error.parentEntityId : error.entityId;
    const selectedEncounterIndex = this.riskService.data.value.encounters.findIndex(encounter => encounter.entityId === encounterEntityId);

    if (!NumberHelper.isBetween(selectedEncounterIndex, 0, this.riskService.data.value.encounters.length, "[)")) {
      throw new Error(`The index found for error '${error.message}' is invalid.`);
    }

    const selectedDiagnosisIndex = error.hasParent ? this.riskService.data.value.encounters[selectedEncounterIndex].diagnoses.findIndex(diagnosis => diagnosis.entityId === error.entityId) : 0;

    return { selectedEncounterIndex, selectedDiagnosisIndex };
  }

  trackByIndex(index, item) {
    return index;
  }
}
