import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { GridColumnDefinition } from "../../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../../shared/grid/models/grid-configuration.model";
import { ChaseSearchRequest } from "../../../../../../../api/chase-search/chase-search-request-model";
import { ADDRESS_CHASE_GRID } from "../../../../../../member/chase-detail/chase-detail-chart/attributes";
import { AddressDetailService } from "../../../../address-detail.service";

@Component({
  selector: "app-provider-addresses",
  templateUrl: "./provider-addresses.component.html",
  styleUrls: ["./provider-addresses.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderAddressesComponent {
  @Input() providerGridData: any;
  visible = false;
  addressGridConfiguration = new GridConfiguration();
  private pageSize = 10;
  addressGridData: any[] = [];

  constructor(
    private addressDetailService: AddressDetailService,
    private changeDetector: ChangeDetectorRef) { }

  get addressCount(): number {
    return this.providerGridData.totalAddresses;
  }

  get serviceProviderName(): string {
    return this.providerGridData.serviceProviderName;
  }

  loadGridData(): void {
    const gridSearchRequest = new ChaseSearchRequest(
      null, null, null, null, null, null, null, null, this.serviceProviderName, null, null, null, null, null);
    this.addressDetailService.getRetrievalList(gridSearchRequest)
    .subscribe(data => {
      if (data) {
        this.addressGridData = data;
        this.changeDetector.markForCheck();
      }
    });
  }

  createGrid(): void {
    this.addressGridConfiguration.columns = [
      new GridColumnDefinition({ field: "addressId", header: "AID", routeUrl: "/retrieval/addressdetail/:addressId" }),
      new GridColumnDefinition({ field: "address1", header: "Address 1" }),
      new GridColumnDefinition({ field: "address2", header: "Address 2" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "status", header: "Status" }),
      new GridColumnDefinition({ field: "groupName", header: "Group Name" }),

    ];
    this.addressGridConfiguration.pageSize = this.pageSize;
    this.addressGridConfiguration.stateName = ADDRESS_CHASE_GRID.attributeCode;
    this.addressGridConfiguration.showActionColumn = false;
    this.addressGridConfiguration.showExportAction = false;
    this.addressGridConfiguration.showMenu = false;
    this.addressGridConfiguration.showSelectionControl = false;
  }

  getProviderAddresses() {
    this.visible = true;
    this.loadGridData();
    this.createGrid();
  }

}
