<app-modal class="print-provider-packet"
           header="PRINT REQUEST"
           [(visible)]="visible"
           (onShow)="open()"
           (onCancel)="close()"
           (visibleChange)="onVisibleChange($event)">
    <h4 class="text-center print-provider-packet__sub-header">{{subHeader}}: {{totalChases}}</h4>
    <div *ngIf="showWarningMessage" class="text-center print-provider-packet__warning-message">For best outcome, your print request will be compiled into {{numberChasesToProcess}} chases at a time.</div>
    <form-factory class="print-provider-packet__form" [formGroup]="formGroup" [model]="coverLetterTemplateInput" (onChange)="onCoverLetterChange($event)"></form-factory>
    <footer class="text-center">
        <app-button text="SUBMIT" (onClick)="printProviderPacket()" [disabled]="!isValid"></app-button>
    </footer>
</app-modal>
