import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ArchiveChaseDetail } from "./archive-chase-detail.model";

@Injectable()
export class ArchiveChaseDetailService {
  state = new BehaviorSubject<ArchiveChaseDetail>(new ArchiveChaseDetail());

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  setData(data: Partial<ArchiveChaseDetail>): void {
    const newData = new ArchiveChaseDetail({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  clearData(): void {
    this.state.next(new ArchiveChaseDetail());
  }

  getArchiveChaseDetail(chaseId: number): Observable<ArchiveChaseDetail> {
    const url = `${this.baseApiUrl}chase/archive?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ArchiveChaseDetail"))
    );
  }
}
