import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { InternalPendsDetailAttemptHistoryComponent } from "./internal-pends-detail/internal-pends-detail-attempt-history/internal-pends-detail-attempt-history.component";
import { InternalPendsDetailCommentsComponent } from "./internal-pends-detail/internal-pends-detail-comments/internal-pends-detail-comments.component";
import { InternalPendsDetailDocumentsComponent } from "./internal-pends-detail/internal-pends-detail-documents/internal-pends-detail-documents.component";
import { InternalPendsDetailInfoComponent } from "./internal-pends-detail/internal-pends-detail-info/internal-pends-detail-info.component";
import { InternalPendsDetailTimelineComponent } from "./internal-pends-detail/internal-pends-detail-timeline/internal-pends-detail-timeline.component";
import { InternalPendsDetailComponent } from "./internal-pends-detail/internal-pends-detail.component";

const routes: Routes = [
  { path: ":pendType/view", redirectTo: "/retrieval/pend/:pendType/view" },
  {
    path: "detail/:pendGd", component: InternalPendsDetailComponent, data: { defaultPath: ["/retrieval/pend/retrieval/view", "/clinical/pend/clinical/view", "/dashboard/pends"] },
    canActivate: [AuthGuard], children: [
      { path: "", component: InternalPendsDetailInfoComponent },
      { path: "comments", component: InternalPendsDetailCommentsComponent },
      { path: "documents", component: InternalPendsDetailDocumentsComponent },
      { path: "timeline", component: InternalPendsDetailTimelineComponent },
      { path: "history", component: InternalPendsDetailAttemptHistoryComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InternalPendsRoutingModule { }
