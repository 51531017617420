<div class="ui-g-12 ui-md-12 ui-lg-12 grid_container">
  <app-server-grid [configuration]="serverGridConfiguration"
                   [(request)]="serverRequest"
                   [actionTemplate]="actionTemplate"
                   [actions]="actions" >

                   <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
                      <div class="action-template-container">
                        <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
                          (onClick)="onActionClick(action, localActionPopover, rowData)" [disabled]="isDisabledAction(rowData)"></app-button>
                      </div>
                   </ng-template>
  </app-server-grid>
</div>
