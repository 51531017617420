import { IAutoMapper } from "../interfaces";

export const mapClientNodeModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClientNodeModel")
    .forMember("clientId", o => o.clientId)
    .forMember("clientName", o => o.clientName)
    .forMember("projects", o => {
      const isArray = Array.isArray(o.projects) && o.projects.length > 0;

      return isArray ? automapper.mapMany("default", "ProjectNodeModel", o.projects) : [];
    });
};
