<div #containerBody class="chase-detail-info-container"
[ngClass]="(this.menuService.showHeader | async) ? 'compress' : 'expand'">
  <div class="leftColumn">
    <app-list-summary [summaryItems]="summaryItems"></app-list-summary>
    <app-generic-border-template [header]="'Chase Gaps'" class="bold" *ngIf="chaseDetailState.hasGaps">
      <div *ngFor="let item of gapCompliance; let i = index; trackBy: trackByIndex">
        {{item.measureCode}} -
        {{item.numeratorName}} -
        <span [ngClass]="getStatusClass(item.status)">{{item.status}}</span>
      </div>
    </app-generic-border-template>
    <app-document-request-artifact [documentRequests]="documentRequests" [addressUrlOfChase]="addressUrlOfChase"></app-document-request-artifact>
    <member-chase-detail-info-current-pends></member-chase-detail-info-current-pends>
    <member-chase-detail-info-pursuits [pursuitItems]="pursuitItems"></member-chase-detail-info-pursuits>
    <div class="comments-header bold">LATEST COMMENT</div>
    <app-comments [commentItems]="commentItems"></app-comments>
  </div>
  <div class="rightColumn">
    <div class="header bold">CHASE TIMELINE</div>
    <app-timeline [timelineItems]="timelineItems" [isChaseTimeline]="true"></app-timeline>
    <div class="showall-timeline">
      <a [href]="showTimelineDetails()">See All</a>
    </div>
  </div>
</div>
