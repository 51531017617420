import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-hcc-summary-by-member",
  templateUrl: "./hcc-summary-by-member.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccSummaryByMemberComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.HCC_SUMMARY_BY_MEMBER;

    constructor() {
        this.filters = ["Projects", "Hcc", "MemberId", "MemberFirstName", "MemberLastName",
                        "MemberDob", "MemberKey", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
