import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MenuItem } from "../../shared/menu/menu-item.model";
import { MenuService } from "../../shared/menu/menu.service";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "platform-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  @Input() items: MenuItem[] = [];

  private sink = new SubSink();

  menuItem: MenuItem[] = [];
  isOpen = false;
  firstRouteName = "";

  constructor(
    private changeDetector: ChangeDetectorRef,
    private menuService: MenuService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.firstRouteName = this.router.url.split("/")[1];
    this.selectAdminMenu(this.router.url.split("/"));
    this.sink.add(
      this.menuService.isOpen$.subscribe(value => {
        if (!value) { this.items.forEach(x => x.clicked = false); }
        this.isOpen = value;
        this.changeDetector.markForCheck();
      }),
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.firstRouteName = event.url.split("/")[1];
          this.selectAdminMenu(event.url.split("/"));
          this.changeDetector.markForCheck();
        }
      }),
      this.menuService.openMenuItem$().pipe(
        filter(menuItem => StringHelper.isAvailable(menuItem?.path)),
        map(menuItem => this.items.find(item => item.path === menuItem.path))
      ).subscribe(menuItem => this.showSubMenu(menuItem))
    );
  }

  getActiveMenuClass(item: MenuItem): string {
    const clicked = this.items.some(x => x.clicked);

    return clicked ? item.clicked ? "active" : "" : this.firstRouteName === item.path.toLowerCase() ? "active" : "";
  }

  showSubMenu(item: MenuItem): void {
    this.items.forEach(x => x.path === item.path ? x.clicked = true : x.clicked = false);
    this.menuItem = [new MenuItem(item)];
    this.menuService.toggleMenuState(true);
  }

  trackByIndex(index, item) {
    return index;
  }

  navigateToLoginLanding(): void {
    this.router.navigateByUrl(`user/loginLanding`);
  }

  selectAdminMenu(urlArray: string[]) {
    if (this.firstRouteName === "project" && NumberHelper.isAvailable(Number(urlArray[2])) && urlArray.length <= 3) {
      this.firstRouteName = "admin";
    }
  }

}
