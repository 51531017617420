import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ChaseDetailCommentsService } from "./chase-detail-comments.service";

@Component({
  selector: "member-chase-detail-comments",
  templateUrl: "./chase-detail-comments.component.html",
  styleUrls: ["./chase-detail-comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChaseDetailCommentsService],
})
export class ChaseDetailCommentsComponent implements OnInit {
  commentItems = List<CommentItem>();
  commentText = "";


  constructor(
    private readonly service: ChaseDetailCommentsService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getComments();
  }


  getComments(): void {
    this.service
      .getComments()
      .subscribe(items => {
        this.commentItems = this.assignAndNotify(items);
      });
  }

  saveComment(): void {
    if (StringHelper.isAvailable(this.commentText)) {
      this.service
        .saveComment(this.commentText)
        .subscribe(() => {
          this.getComments();
          this.commentText = "";
        });
    }
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }
}
