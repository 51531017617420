import { DatePipe } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { NumeratorItem } from "../../../api/numerator/numerator-item.model";
import { NumeratorService } from "../../../api/numerator/numerator.service";
import { BulkUpdateChaseService } from "../../project/bulk-updates/bulk-update-chase/bulk-update-chase.service";
import { ProjectType } from "../../project/project-type.enum";
import { Project } from "../../project/project.model";
import { RetrievalDocumentServiceService } from "../../retrieval/retrieval-document-service.service";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { AnalyticsRequest } from "../analytics-request.model";
import { AnalyticsService } from "../analytics.service";
import { ContactMethodType } from "../contact-method-type.enum";
import { AnalyticsItemRequest } from "../models/analytics-item-request.model";
import { AnalyticsHedisDashboard } from "./analytics-hedis-dashboard.model";
import { UserToken } from "../../../../auth/user-token.model";
import { PLAN_ID, CONTRACT_NUMBER, PRODUCT_LIST, MEASURE_YEAR } from "../../member/chase-detail/chase-detail-chart/attributes";
import { ServiceOrgAttribute } from "../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";
import { NewProjectService } from "../../project/new-project/new-project.service";
import { ChartService } from "../../member/chase-detail/chase-detail-chart/chart.service";
import { EntityAttribute } from "../entity-attribute.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { LookerDashboardType } from "../looker-dashboard-type.enum";
import { Subject } from "rxjs";
import { SharedFilters } from "../analytics-shared-filters.class";
import { AddressDetailInfoService } from "../../retrieval/psr/address-detail/address-detail-info/address-detail-info.service";
import { VendorDetail } from "../../invoice/vendor-detail/vendor-detail.model";
import { AddressDetailInfoEditService } from "../../retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { ChaseQueryService } from "../../project/chase-query/chase-query.service";
import { DateHelper } from "../../../../utilities/contracts/date-helper";

// TODO: Fix Cyclomatic-comlexity with pageType()
/* tslint:disable:cyclomatic-complexity */
@Component({
  selector: "app-analytics-hedis",
  templateUrl: "./analytics-hedis.component.html",
  styleUrls: ["./analytics-hedis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class AnalyticsHedisComponent extends SharedFilters implements OnInit, AfterViewInit, OnDestroy {
  projectsInput: CheckboxGroup;
  measuresInput: CheckboxGroup;
  numeratorsInput: CheckboxGroup;
  form: FormGroup;
  hedisProjectTypeId = ProjectType.HEDIS;
  isVisible = false;
  lookerUrl: string;
  isFiltersVisible = false;
  addressIdInput: Textbox;
  cityInput: Textbox;
  stateInput: Textbox;
  addressGroupInput: Textbox;
  memberIdInput: Textbox;
  memberFirstNameInput: Textbox;
  memberLastNameInput: Textbox;
  memberDobInput: Textbox;
  memberDobFromInput: Textbox;
  memberDobToInput: Textbox;
  memberGenderInput: Dropdown;
  memberKeyInput: Textbox;
  overread2FromDate: Calendar;
  overread2ToDate: Calendar;
  npiInput: Textbox;
  chaseIdInput: Textbox;
  dashboardType: string;
  projectIdsAsCsv: string;
  measureIdsAsCsv: string;
  formFilterRequest: FormGroup;
  menuItems = List<MenuItem>();
  @Output() removeFilter = new EventEmitter<FormGroup>();
  analyticsFilter: AnalyticsRequest;
  pFormGroupKey: string;
  isFilterUpdated = false;
  filters = ["Project", "Measure", "Address"];
  numeratorItems: NumeratorItem[];
  abstractionFromDate: Calendar;
  abstractionToDate: Calendar;
  deFromDate: Textbox;
  deToDate: Textbox;
  pendCodesInput: CheckboxGroup;
  pendStatusInput: CheckboxGroup;
  pendCreateDateFrom: Calendar;
  pendCreateDateTo: Calendar;
  billingDateFrom: Calendar;
  billingDateTo: Calendar;
  responseDateFromInput: Calendar;
  responseDateToInput: Calendar;
  chartReceivedDateFromInput: Textbox;
  chartReceivedDateToInput: Textbox;
  completionFromDate: Calendar;
  completionToDate: Calendar;
  updateDateFrom: Calendar;
  updateDateTo: Calendar;
  startDateInput: Calendar;
  endDateInput: Calendar;
  workflowStatusInput: CheckboxGroup;
  retrievalDateFrom: Textbox;
  retrievalDateTo: Textbox;
  retrievalTypeInput: CheckboxGroup;
  contactDateFromInput: Calendar;
  contactDateToInput: Calendar;
  fileCreateDateFromInput: Textbox;
  fileCreateDateToInput: Textbox;
  callTypeInput: CheckboxGroup;
  contactMethodTypeInput: CheckboxGroup;
  loadStartDateInput: Textbox;
  loadEndDateInput: Textbox;
  dataLoadStartDateInput: Textbox;
  dataLoadEndDateInput: Textbox;
  organizationInput: CheckboxGroup;
  businessDateFromInput: Textbox;
  businessDateToInput: Textbox;
  createFromDate: Calendar;
  createToDate: Calendar;
  productsInput: CheckboxGroup;
  lineOfBusinessInput: CheckboxGroup;
  numeratorInput: CheckboxGroup;
  complianceInput: CheckboxGroup;
  pendOwnerInput: CheckboxGroup;
  pendTypeInput: CheckboxGroup;
  showBorder = false;
  dateFilterInput: any;
  toDateFilterInput: any;
  toDateFilter = "To Date";
  currentDateFilters: string;
  currentDateFilterTab: string;
  isDateErrorExist: boolean = false;
  dateErrorMessage: string;
  isParentOrg = false;
  errorMessage = "Please select at least one Project";
  showErrorMessage = false;
  userToken: UserToken;
  organizationId: number;
  retrievalOwnerInput: Radiobutton;
  private hideFilters: string[] = [];
  private selectedProject$: Subject<SelectableInput> = new Subject();
  isHelpModalOpen = false;
  isFilterIconVisible = false;

  @ViewChild("tabTemplate", { static: true }) tabTemplate: any;
  @ViewChild("responseDate", { read: ViewContainerRef }) responseDateTarget: ViewContainerRef;
  @ViewChild("abstractDate", { read: ViewContainerRef }) abstractDateTarget: ViewContainerRef;
  constructor(private readonly formService: FormService,
    private analyticsService: AnalyticsService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private session: LocalService,
    private readonly createPendService: CreatePendService,
    private readonly router: Router,
    private numeratorService: NumeratorService,
    private readonly bulkUpdateChaseService: BulkUpdateChaseService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly userService: UserService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private newProjectService: NewProjectService,
    private readonly chartService: ChartService,
    private datePipe: DatePipe,
    private messagingService: MessagingService,
    private addressDetailService: AddressDetailInfoService,
    private addressDetailInfoService: AddressDetailInfoEditService,
    private chaseQueryService: ChaseQueryService,
  ) {
    super();
  }
  ngOnInit() {
    this.userToken = this.userService.getUserToken();
    this.isParentOrg = this.userToken.isParentOrg;
    this.organizationId = this.userToken.organizationId;
    if (!this.hasRestrictedProjectSelection()) {
      this.getNumerators();
    }
    this.getMenuItems();
    this.createForm();
    this.getPendCodes();
    this.getPendStatus();
    this.getRetreivalTypes();
    this.getCallTypes();
    this.getContactMethodTypes();
    this.getAllSelectableInputs();
    this.getCurrentChaseStatus();
    if (StringHelper.isAvailable(this.getRouteUrlValueForSession)) {
      this.showFiltersOrGetData();
    }
    this.getSharedFilterData();
    this.sink.add(
      this.selectedProject$.pipe(
        distinctUntilChanged((previous, current) => previous.value === current.value)
      ).subscribe(project =>
        this.analyticsService.getNumerators(project.value.toString(), MEASURE_YEAR.attributeId.toString()).subscribe(
          items => this.updateNumeratorOptions(items)
        ))
    );
    this.getVendorInvoiceTypes();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.getPendOwners();
    this.getPendTypes();
    this.getContactMethodStatus(() => {
      this.setControlValue(this.contactStatusInput, true);
    });
    this.getProjectStatus(() => {
      this.setControlValue(this.projectStatusInput, true);
    });
  }

  private getSharedFilterData(): void {
    this.genericGet(this.analyticsService, 'getDocumentStateGroups', (options) => {
      this.documentStatus = new CheckboxGroup({ ...this.documentStatus, options: [...options] } as any);
      this.setControlValue(this.documentStatus, false);
    });
    const contactMethod = Object.keys(ContactMethodType).filter(v => !isNaN(Number(v))).join(",");
    this.genericGet(this.analyticsService, 'getContactMethodTypes', (options) => {
      this.contactMethodInput = new CheckboxGroup({ ...this.contactMethodInput, options: [...options] } as any);
      this.setControlValue(this.contactMethodInput, false);
    }, contactMethod);
    this.genericGet(this.addressDetailService, 'getVendors', (options: VendorDetail[]) => {
      const mappedOptions = options.map((vendor) => new Object({text: vendor.name, value: vendor.name}));
      this.vendorNameInput = new CheckboxGroup({ ...this.vendorNameInput, options: [...mappedOptions] } as any);
      this.setControlValue(this.vendorNameInput, false);
    });
    this.genericGet(this.addressDetailInfoService, 'getSpecialHandlingReasons', (options) => {
      const mappedOptions = options.map((items) => new Object({text: items.text, value: items.text}));
      this.specialHandlingInput = new CheckboxGroup({ ...this.specialHandlingInput, options: [...mappedOptions] } as any);
      this.setControlValue(this.specialHandlingInput, false);
    });
    this.genericGet(this.retrievalPageService, "getDocumentSourceTypes", (result) => {
      const expectedRetrievalOptions = result.map(opt => new SelectableInput({text: opt.text, value: opt.text}));
      this.expectedRetrievalInput = new CheckboxGroup({ ...this.expectedRetrievalInput, options: [...expectedRetrievalOptions] } as any);
      this.setControlValue(this.expectedRetrievalInput, false);
    });
  }

  private createForm(): void {
    const futureDate = this.analyticsService.getCalenderFutureDate();
    this.projectsInput =
      new CheckboxGroup({
        key: "Projects",
        label: "Current HEDIS Projects",
      });
    this.measuresInput =
      new CheckboxGroup({
        key: "Measures",
        label: "HEDIS Measures",
      });
    this.cityInput =
      new Textbox({
        key: "city",
        label: "City",
      });
    this.stateInput =
      new Textbox({
        key: "state",
        label: "State",
      });
    this.memberIdInput =
      new Textbox({
        key: "memberId",
        label: "Member ID",
      });
    this.memberFirstNameInput =
      new Textbox({
        key: "memberFirstName",
        label: "Member First Name",
      });
    this.memberLastNameInput =
      new Textbox({
        key: "memberLastName",
        label: "Member Last Name",
      });
    this.memberDobInput =
      new Textbox({
        key: "memberDob",
        label: "Member DOB",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.memberDobFromInput =
      new Textbox({
        key: "memberDobFrom",
        label: "Member DOB From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.memberDobToInput =
      new Textbox({
        key: "memberDobTo",
        label: "Member DOB To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.memberGenderInput = new Dropdown({
      key: "memberGender",
      label: "GENDER",
      placeholder: "Select Gender",
      options: [
        new SelectableInput({ text: "Female", value: "F" }),
        new SelectableInput({ text: "Male", value: "M" }),
      ],
      appendTo: "body",
    });
    this.memberKeyInput =
      new Textbox({
        key: "memberKey",
        label: "Member Key",
      });
    this.overread2FromDate =
      new Calendar({
        key: "overread2FromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.overread2ToDate =
      new Calendar({
        key: "overread2ToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.createFromDate =
      new Calendar({
        key: "createFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.createToDate =
      new Calendar({
        key: "createToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.npiInput =
      new Textbox({
        key: "npi",
        label: "NPI",
      });
    this.chaseIdInput =
      new Textbox({
        key: "chaseId",
        label: "Chase ID",
      });
    this.numeratorsInput =
      new CheckboxGroup({
        key: "Gaps",
        label: "GAP",
      });
    this.retrievalTypeInput =
      new CheckboxGroup({
        key: "RetrievalType",
        label: "Retrieval Types",
      });
    this.abstractionFromDate =
      new Calendar({
        key: "abstractionFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.abstractionToDate =
      new Calendar({
        key: "abstractionToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.deFromDate =
      new Textbox({
        key: "deFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.deToDate =
      new Textbox({
        key: "deToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.pendCreateDateFrom =
      new Calendar({
        key: "pendFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.pendCreateDateTo =
      new Calendar({
        key: "pendToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.billingDateFrom =
      new Calendar({
        key: "billingFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.billingDateTo =
      new Calendar({
        key: "billingToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.pendCodesInput = new CheckboxGroup({
      key: "pendCodes",
      label: "SELECT PEND(S)",
    });
    this.pendStatusInput = new CheckboxGroup({
      key: "pendStatus",
      label: "SELECT PEND STATUS(S)",
    });
    this.responseDateFromInput =
      new Calendar({
        key: "responseDateFrom",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.responseDateToInput =
      new Calendar({
        key: "responseDateTo",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.chartReceivedDateFromInput =
      new Textbox({
        key: "chartReceivedDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.chartReceivedDateToInput =
      new Textbox({
        key: "chartReceivedDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.completionFromDate =
      new Calendar({
        key: "completionFromDate",
        label: "From",
        placeholder: "Enter From Date",
        errorMessages: {
          isValid: 'Please select a From Date that is before the To Date.'
        }
      });
    this.completionToDate =
      new Calendar({
        key: "completionToDate",
        label: "To",
        placeholder: "Enter To Date",
        errorMessages: {
          isValid: 'Please select a To Date that is after the From Date.'
        }
      });
    this.updateDateFrom =
      new Calendar({
        key: "updateFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.updateDateTo =
      new Calendar({
        key: "updateToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.retrievalDateFrom =
      new Textbox({
        key: "retrievalFromDate",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.retrievalDateTo =
      new Textbox({
        key: "retrievalToDate",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.startDateInput =
      new Calendar({
        key: "startDate",
        label: "Start Date",
        placeholder: "Enter Start Date",
        maxDate: new Date()
      });
    this.endDateInput =
      new Calendar({
        key: "endDate",
        label: "End Date",
        placeholder: "Enter End Date",
        maxDate: futureDate
      });
    this.workflowStatusInput =
      new CheckboxGroup({
        key: "workflowStatus",
        label: "SELECT WORKFLOW STATUS(S)",
      });
    this.contactDateFromInput =
      new Calendar({
        key: "contactFromDate",
        label: "From",
        placeholder: "Enter From Date",
        maxDate: new Date()
      });
    this.contactDateToInput =
      new Calendar({
        key: "contactToDate",
        label: "To",
        placeholder: "Enter To Date",
        maxDate: futureDate
      });
    this.fileCreateDateFromInput =
      new Textbox({
        key: "fileCreateDateFrom",
        label: "From",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.fileCreateDateToInput =
      new Textbox({
        key: "fileCreateDateTo",
        label: "To",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.callTypeInput =
      new CheckboxGroup({
        key: "callType",
        label: "SELECT STATUS(S)",
      });
    this.contactMethodTypeInput =
      new CheckboxGroup({
        key: "contactMethod",
        label: "SELECT CONTACT METHOD",
      });
    this.loadStartDateInput =
      new Textbox({
        key: "loadStartDate",
        label: "Start Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.loadEndDateInput =
      new Textbox({
        key: "loadEndDate",
        label: "End Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dataLoadStartDateInput =
      new Textbox({
        key: "dataLoadStartDate",
        label: "Start Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.dataLoadEndDateInput =
      new Textbox({
        key: "dataLoadEndDate",
        label: "End Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.businessDateFromInput =
      new Textbox({
        key: "businessDateFromInput",
        label: "From Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.businessDateToInput =
      new Textbox({
        key: "businessDateToInput",
        label: "To Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY/MM/DD format.",
        },
      });
    this.organizationInput =
      new CheckboxGroup({
        key: "organization",
        label: "Service Organizations",
      });
    this.productsInput =
      new CheckboxGroup({
        key: "products",
        label: "Products",
      });
    this.lineOfBusinessInput = new CheckboxGroup({
      key: "lineOfBusiness",
      label: "Line Of Business",
    });
    this.numeratorInput =
      new CheckboxGroup({
        key: "numerators",
        label: "Numerator",
      });
    this.complianceInput =
      new CheckboxGroup({
        key: "compliance",
        label: "Compliance",
      });
    this.healthPlanInput =
      new CheckboxGroup({
        key: "healthPlan",
        label: "Health Plan",
      });
    this.contractNumberInput =
      new CheckboxGroup({
        key: "contractNumber",
        label: "Contract Number",
      });
    this.pendOwnerInput = new CheckboxGroup({
      key: "pendOwner",
      label: "Pend Owner",
      options: [
        { text: "Client", value: "Client" },
        { text: "Organization", value: "Organization" },
      ],
    });
    this.pendTypeInput = new CheckboxGroup({
      key: "pendType",
      label: "Pend Type",
      options: [
        { text: "RETRIEVAL", value: "RETRIEVAL" },
        { text: "CLINICAL", value: "CLINICAL" },
      ],
    });
    this.setDateDefaultValues(this.filters);
    this.createform();
  }
  createform() {
    this.form = this.formService.createFormGroup([
      this.pendStatusInput,
      this.projectsInput,
      this.measuresInput,
      this.addressIdInput,
      this.cityInput,
      this.stateInput,
      this.addressGroupInput,
      this.memberIdInput,
      this.memberFirstNameInput,
      this.memberLastNameInput,
      this.memberDobInput,
      this.memberDobFromInput,
      this.memberDobToInput,
      this.memberKeyInput,
      this.memberGenderInput,
      this.npiInput,
      this.chaseIdInput,
      this.numeratorsInput,
      this.abstractionFromDate,
      this.abstractionToDate,
      this.deFromDate,
      this.deToDate,
      this.pendCodesInput,
      this.pendCreateDateFrom,
      this.pendCreateDateTo,
      this.billingDateFrom,
      this.billingDateTo,
      this.responseDateFromInput,
      this.responseDateToInput,
      this.chartReceivedDateFromInput,
      this.chartReceivedDateToInput,
      this.completionFromDate,
      this.completionToDate,
      this.updateDateFrom,
      this.updateDateTo,
      this.startDateInput,
      this.endDateInput,
      this.workflowStatusInput,
      this.retrievalDateFrom,
      this.retrievalDateTo,
      this.retrievalTypeInput,
      this.contactDateFromInput,
      this.contactDateToInput,
      this.fileCreateDateFromInput,
      this.fileCreateDateToInput,
      this.callTypeInput,
      this.contactMethodTypeInput,
      this.loadStartDateInput,
      this.loadEndDateInput,
      this.dataLoadStartDateInput,
      this.dataLoadEndDateInput,
      this.organizationInput,
      this.businessDateFromInput,
      this.businessDateToInput,
      this.overread2FromDate,
      this.overread2ToDate,
      this.createFromDate,
      this.createToDate,
      this.productsInput,
      this.lineOfBusinessInput,
      this.numeratorInput,
      this.complianceInput,
      this.healthPlanInput,
      this.contractNumberInput,
      this.pendOwnerInput,
      this.retrievalOwnerInput,
      this.sampleComplianceInput,,
      this.documentQueueId,
      this.documentStatus,
      this.confirmationNumber,
      this.providerGatewayPin,
      this.contactMethodInput,
      this.contactStatusInput,
      this.contactDateStart,
      this.contactDateEnd,
      this.expectedRetrievalInput,
      this.projectStatusInput,
      this.vendorNameInput,
      this.specialHandlingInput,
      this.moveDateStart,
      this.moveDateEnd,
      this.abstractionBy,
      this.pendBy,
      this.movebackBy,
      this.overread2By,
      this.codedBy,
      this.updatedBy,
      this.userName,
      this.retrievedDateStart,
      this.retrievedDateEnd,
      this.movebackDateStart,
      this.movebackDateEnd,
      this.dateRangeStart,
      this.dateRangeEnd,
      this.userStatus,
      this.userEmail,
      this.login,
      this.currentChaseStatus,
      this.currentProcessStep,
      this.vendorInvoiceType,
      this.pendTypeInput
    ]);
    this.setForm(this.form);
  }
  private getProjects(): void {
    if (this.isParentOrg) {
      const selectedOrgIds = this.organizationInput.selectedOptions.map(v => v.value).join(",");
      if (StringHelper.isAvailable(selectedOrgIds)) {
        this.analyticsService
          .getProjects(selectedOrgIds, ProjectType.HEDIS).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
          .subscribe(options => {
            this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
            this.setControlValue(this.projectsInput, false);
            this.getMeasuresByProject();
            this.formService.updateDom.next();
          });
      }
    } else {
      const projectFilter = new Project({projectTypeId: ProjectType.HEDIS});
      this.retrievalPageService
        .getProjects(projectFilter).pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
        .subscribe(options => {
          this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
          this.setControlValue(this.projectsInput, false);
          this.formService.updateDom.next();
        });
    }
  }
  private getOrganizations(): void {
    this.analyticsService.getOrganizations().pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.organizationInput = new CheckboxGroup({ ...this.organizationInput, options } as any);
        this.setControlValue(this.organizationInput, false);
        this.getProjects();
        this.changeDetector.markForCheck();
      });
  }
  private getMeasures(): void {
    this.retrievalPageService
      .getMeasuresList(this.hedisProjectTypeId).pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => { this.bindMeasureList(options); });
  }
  private getMeasuresByProject(): void {
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    if (StringHelper.isAvailable(selectedProjectIds)) {
      this.analyticsService
        .getMeasuresList(selectedProjectIds).pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
        .subscribe(options => { this.bindMeasureList(options); });
    }
  }
  getLineOfBusiness() {
    this.newProjectService
      .getLineOfBusiness()
      .subscribe(options => {
        options.forEach(option => {
          option.text = `${option.text}`;
          option.value = `${option.value}`;
        });
        this.lineOfBusinessInput = new CheckboxGroup({ ...this.lineOfBusinessInput, options } as any);
        this.setControlValue(this.lineOfBusinessInput, false);
        this.changeDetector.markForCheck();
      });
  }
  private bindMeasureList(options: any) {
    this.measuresInput = new CheckboxGroup({ ...this.measuresInput, options } as any);
    this.setControlValue(this.measuresInput, true);
    this.formService.updateDom.next();
  }

  private getNumerators(): void {
    this.numeratorService.getNumerators().subscribe(items => this.updateNumeratorOptions(items));
  }

  private updateNumeratorOptions(items: NumeratorItem[]): void {
    this.numeratorItems = items;
    const numerators = this.numeratorItems.map(item => new SelectableInput({ text: item.numeratorName, value: item.numeratorName }));
    this.numeratorInput = new CheckboxGroup({ ...this.numeratorInput, options: numerators } as any);
    this.setControlValue(this.numeratorInput, false);
    this.formService.updateDom.next();
    if (StringHelper.isAvailable(this.analyticsFilter.gapsAsCsv) && ArrayHelper.isAvailable(this.numeratorItems)) {
      this.setNumeratorControlValues();
      this.setControlValue(this.numeratorsInput, false);
    }
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.complianceInput = { ...this.complianceInput, options } as any;
        this.setControlValue(this.complianceInput);
        this.sampleComplianceInput = { ...this.sampleComplianceInput, options } as any;
        this.setControlValue(this.sampleComplianceInput);
        this.formService.updateDom.next();
      });
  }
  private getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.setControlValue(this.productsInput,true);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }
  private getHealthPlans(): void {
   /* let projectIds = '';*/
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    const request = new EntityAttribute({
      projectIdsAsCsv: selectedProjectIds,
      projectTypeId: ProjectType.HEDIS,
      attributeId: PLAN_ID.attributeId,
    });
    this.analyticsService.getEntityAttributes(request).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.healthPlanInput = new CheckboxGroup({ ...this.healthPlanInput, options } as any);
        this.setControlValue(this.healthPlanInput);
        this.formService.updateDom.next()
      });
  }
  private getContractNumbers(): void {
    /* let projectIds = '';*/
    const selectedProjectIds = this.projectsInput.selectedOptions.map(v => v.value).join(",");
    const request = new EntityAttribute({
      projectIdsAsCsv: selectedProjectIds,
      projectTypeId: ProjectType.HEDIS,
      attributeId: CONTRACT_NUMBER.attributeId,
    });
    this.analyticsService.getEntityAttributes(request).pipe(map(this.automapper.curryMany("ListItem", "SelectableInput")))
      .subscribe(options => {
        this.contractNumberInput = new CheckboxGroup({ ...this.contractNumberInput, options } as any);
        this.setControlValue(this.contractNumberInput);
        this.formService.updateDom.next()
      });
  }

  private getPendOwners(): void {
    this.pendOwnerInput = new CheckboxGroup({ ...this.pendOwnerInput } as any);
    this.setControlValue(this.pendOwnerInput, true);
  }

  private getPendTypes(): void {
    this.pendTypeInput = new CheckboxGroup({ ...this.pendTypeInput } as any);
    this.setControlValue(this.pendTypeInput, true);
  }
  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.productsInput = new CheckboxGroup({ ...this.productsInput, options: productOptions } as any);
    this.setControlValue(this.productsInput, true);
  }
  checkFromDateGreaterThanToDate() {
    if (this.form.get(this.dateFilterInput.key).value && this.form.get(this.toDateFilterInput.key).value) {
      {
        if ((new Date(this.form.get(this.dateFilterInput.key).value)) < (new Date(this.form.get(this.toDateFilterInput.key).value))) {
          this.errorMessage = "";
          this.form.get(this.dateFilterInput.key).setErrors(null);
        }
        else if (this.form.get(this.dateFilterInput.key).value == null) {
          this.form.get(this.dateFilterInput.key).setErrors({ required: true });
          this.errorMessage = "Please select a To Date that is after the From Date.";
        }
      }
    }
    else if (!this.isDateErrorExist && !this.filters.includes("UpdateDate")) {
      this.form.get(this.dateFilterInput.key).setErrors(null);
    }
  }
  getTab(tab: string) {
    if (tab && (tab === this.currentDateFilterTab)) {
      this.checkFromDateGreaterThanToDate()
    }
    if (tab && (tab !== this.currentDateFilterTab)) {
      this.checkFromDateGreaterThanToDate()
    }
    if (this.form.get(this.updateDateFrom.key).invalid) {
      this.errorMessage = "Please select Update Date";
    }
    if (this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "Please select at least one Project";
    }
    if (tab && (tab === this.currentDateFilterTab) && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = "";
    }
    if (tab && (tab === this.currentDateFilterTab) && this.isDateErrorExist && !this.form.get(this.projectsInput.key).invalid) {
      this.errorMessage = this.dateErrorMessage;
    }
    if (this.isDateErrorExist && tab && (tab !== this.currentDateFilterTab)) {
      this.errorMessage = this.dateErrorMessage;
    }

  }
  get selectedTab(): string {
    if (this.tabTemplate.tabPanels) {
      const selectedTab = this.tabTemplate.tabPanels._results.filter(x => x.previousIsActive === true);
      if (ArrayHelper.isAvailable(selectedTab)) {
        return selectedTab[0].header;
      }
    } else {
      return "";
    }
  }
  onDateChange(fromDate: Calendar, toDate: Calendar) {
    if (this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
      {
        if ((new Date(this.form.get(fromDate.key).value)) < (new Date(this.form.get(toDate.key).value))) {
          this.showBorder = false;
          this.form.get(fromDate.key).setErrors(null);
          this.form.get(toDate.key).setErrors(null);
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        } else {
          if (this.filters.includes("UpdateDate")) {
            this.form.get(toDate.key).setErrors({ required: true });
          }
          else {
            this.form.get(fromDate.key).setErrors({ required: true });
          }
          this.showBorder = true;
          this.isDateErrorExist = false;
          this.getTab(this.selectedTab);
        }
      }
    }
    else if (this.form.get(fromDate.key).value && !this.form.get(toDate.key).value) {
      this.isDateErrorExist = true;
      this.dateErrorMessage = `Please Select a  ${toDate.label.replace("Date", "")} Date.`;
      this.form.get(fromDate.key).setErrors({ "server-error": "" });
      this.showBorder = false;
    }
    else {
      if (!this.form.get(fromDate.key).value && this.form.get(toDate.key).value) {
        this.isDateErrorExist = true;
        this.form.get(fromDate.key).markAsTouched();
        this.dateErrorMessage = `Please Select a  ${fromDate.label.replace("Date", "")} Date.`;
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
        this.showBorder = false;
      }
      else {
        this.form.get(fromDate.key).setErrors({ "server-error": "" });
      }
    }
  }
  dateValidationToAndForm(value: string) {
    if (value === "abstraction") {
      if (StringHelper.isAvailable(this.form.get(this.abstractionToDate.key).value)
        || StringHelper.isAvailable(this.form.get(this.abstractionFromDate.key).value)
      ) {
        const abstractionFromDate = this.form.get(this.abstractionFromDate.key).value;
        this.abstractionFromDate = new Calendar({
          ...this.abstractionFromDate,
          value: abstractionFromDate,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date",
          },
        } as any);
        const abstractionToDate = this.form.get(this.abstractionToDate.key).value;
        this.abstractionToDate = new Calendar({
          ...this.abstractionToDate,
          value: abstractionToDate,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date",
          },
        } as any);
      } else {
        const abstractionFromDate = this.form.get(this.abstractionFromDate.key).value;
        this.abstractionFromDate = new Calendar({
          ...this.abstractionFromDate,
          value: abstractionFromDate,
          placeholder: "Enter From Date",
        } as any);
        const abstractionToDate = this.form.get(this.abstractionToDate.key).value;
        this.abstractionToDate = new Calendar({
          ...this.abstractionToDate,
          value: abstractionToDate,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "pend") {
      if (StringHelper.isAvailable(this.form.get(this.pendCreateDateTo.key).value)
        || StringHelper.isAvailable(this.form.get(this.pendCreateDateFrom.key).value)
      ) {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date",
          },
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date",
          },
        } as any);
      } else {
        const pendFromDate = this.form.get(this.pendCreateDateFrom.key).value;
        this.pendCreateDateFrom = new Calendar({
          ...this.pendCreateDateFrom,
          value: pendFromDate,
          placeholder: "Enter From Date",
        } as any);
        const pendToDate = this.form.get(this.pendCreateDateTo.key).value;
        this.pendCreateDateTo = new Calendar({
          ...this.pendCreateDateTo,
          value: pendToDate,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "responseDate") {
      if (StringHelper.isAvailable(this.form.get(this.responseDateFromInput.key).value)
        || StringHelper.isAvailable(this.form.get(this.responseDateToInput.key).value)
      ) {
        const responseDateFromInput = this.form.get(this.responseDateFromInput.key).value;
        this.responseDateFromInput = new Calendar({
          ...this.responseDateFromInput,
          value: responseDateFromInput,
          validators: [Validators.required],
          placeholder: "Enter From Date",
          errorMessages: {
            required: "Enter From Date",
          },
        } as any);
        const responseDateToInput = this.form.get(this.responseDateToInput.key).value;
        this.responseDateToInput = new Calendar({
          ...this.responseDateToInput,
          value: responseDateToInput,
          validators: [Validators.required],
          placeholder: "Enter To Date",
          errorMessages: {
            required: "Enter To Date",
          },
        } as any);
      } else {
        const responseDateFromInput = this.form.get(this.responseDateFromInput.key).value;
        this.responseDateFromInput = new Calendar({
          ...this.responseDateFromInput,
          value: responseDateFromInput,
          placeholder: "Enter From Date",
        } as any);
        const responseDateToInput = this.form.get(this.responseDateToInput.key).value;
        this.responseDateToInput = new Calendar({
          ...this.responseDateToInput,
          value: responseDateToInput,
          placeholder: "Enter To Date",
        } as any);
      }
    }
    if (value === "chartReceived") {
      if (StringHelper.isAvailable(this.form.get(this.chartReceivedDateFromInput.key).value)
        || StringHelper.isAvailable(this.form.get(this.chartReceivedDateToInput.key).value)
      ) {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
            required: "Field is required.",
          },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern), Validators.required],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
            required: "Field is required.",
          },
        } as any);
      } else {
        const chartReceivedDateFromInput = this.form.get(this.chartReceivedDateFromInput.key).value;
        this.chartReceivedDateFromInput = new Textbox({
          ...this.chartReceivedDateFromInput,
          value: chartReceivedDateFromInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
          },
        } as any);
        const chartReceivedDateToInput = this.form.get(this.chartReceivedDateToInput.key).value;
        this.chartReceivedDateToInput = new Textbox({
          ...this.chartReceivedDateToInput,
          value: chartReceivedDateToInput,
          validators: [Validators.pattern(RegExHelper.dateYearFirstSlashPattern)],
          errorMessages: {
            pattern: "Date should be in YYYY/MM/DD format.",
          },
        } as any);
      }
    }
    const pendStatus = this.form.get(this.pendStatusInput.key).value;
    this.pendStatusInput = new CheckboxGroup({
      ...this.pendStatusInput,
      value: pendStatus,
    } as any);
    this.getPendStatus();
    this.createform();
    this.formService.markAllAsTouched(this.form);
  }
  closeFilters(): void {
    if (this.filters.includes("FileCreateDate")) {
      this.verifyDateRange(this.fileCreateDateFromInput, this.fileCreateDateToInput);
    }
    if (this.filters.includes(this.currentDateFilters)) {
      this.getTab(this.selectedTab);
    }
    if (this.dateFilterInput && this.toDateFilterInput && !this.filters.includes("UpdateDate")) {
      this.setDefaultDateValues();
    }
    if (this.isValidForm && !this.isDateErrorExist) {
      this.getLookerUrl(true, true);
      this.isFiltersVisible = false; this.showErrorMessage = false;
    } else {
      this.validateMoreThan2DateFilters();
      this.showErrorMessage = true;
    }
  }
  setDefaultDateValues() {
    if (this.dateFilterInput.key && !this.form.get(this.dateFilterInput.key).value && this.toDateFilterInput.key && !this.form.get(this.toDateFilterInput.key).value) {
      this.form.get(this.dateFilterInput.key).setValue("01/01/2020");
      this.form.get(this.toDateFilterInput.key).setValue(new Date());
    }
  }
  validateMoreThan2DateFilters() {
    if (this.filters.includes("ResponseDate") && this.filters.includes("AbstractionDate")) {
      const responseElementRef: HTMLElement = this.responseDateTarget.element.nativeElement;
      const abstractElementRef: HTMLElement = this.abstractDateTarget.element.nativeElement;
      if (this.form.get(this.responseDateFromInput.key).value && this.form.get(this.responseDateToInput.key).value) {
        if (new Date(this.form.get(this.responseDateFromInput.key).value) < new Date(this.form.get(this.responseDateToInput.key).value)) {
          responseElementRef.classList.remove("analytics__filter--highlight");
          this.form.get(this.abstractionFromDate.key).setErrors(null);

        } else {
          this.form.get(this.abstractionFromDate.key).setErrors({ required: true });
          this.errorMessage = "Please select a To Date that is after the From Date.";
          responseElementRef.classList.add("analytics__filter--highlight");
        }
      }
      else if (this.form.get(this.responseDateFromInput.key).value && !this.form.get(this.responseDateToInput.key).value) {
      }
      else if (!this.form.get(this.responseDateFromInput.key).value && this.form.get(this.responseDateToInput.key).value) {
        this.form.get(this.abstractionFromDate.key).markAsTouched();
      }
      else {
        this.form.get(this.abstractionFromDate.key).setErrors(null);
      }
      if (this.form.get(this.abstractionFromDate.key).value && this.form.get(this.abstractionToDate.key).value) {
        if (new Date(this.form.get(this.abstractionFromDate.key).value) < new Date(this.form.get(this.abstractionToDate.key).value)) {
          abstractElementRef.classList.remove("analytics__filter--highlight");
          this.form.get(this.abstractionFromDate.key).setErrors(null);

        } else {
          abstractElementRef.classList.add("analytics__filter--highlight");
          this.form.get(this.abstractionFromDate.key).setErrors({ required: true });
          this.errorMessage = "Please select a To Date that is after the From Date.";
        }
      }
      else if (this.form.get(this.abstractionFromDate.key).value && !this.form.get(this.abstractionToDate.key).value) {
      }
      else if (!this.form.get(this.abstractionFromDate.key).value && this.form.get(this.abstractionToDate.key).value) {
        this.form.get(this.abstractionFromDate.key).markAsTouched();
      }
      else {
        this.form.get(this.abstractionFromDate.key).setErrors(null);
      }
    }
  }

  private getLookerUrl(isUpdateFilter: boolean, isChangeFormat?: boolean): void {
    this.isFilterUpdated = isUpdateFilter;
    if (!isUpdateFilter) {
      this.setFilteredInputValue(this.form, this.analyticsFilter);
      this.formFilterRequest = this.form;
    } else {
      this.formFilterRequest = this.form;
    }
    const analyticsRequest = this.bindFilterData(isChangeFormat);
    this.analyticsService.getLookerUrl(analyticsRequest).subscribe(data => {
      this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
      this.isVisible = true;
      this.lookerUrl = data;
      this.changeDetector.markForCheck();
    });
  }
  bindFilterData(isUpdate?: boolean): AnalyticsRequest {
    const [toDateAbstract, fromDateAbstract] = this.getDateFromDatePicker(this.abstractionToDate, this.abstractionFromDate, isUpdate);
    const [toDateCompletion, fromDateCompletion] = this.getDateFromDatePicker(this.completionToDate, this.completionFromDate, isUpdate);
    const [toDateResponse, fromDateResponse] = this.getDateFromDatePicker(this.responseDateToInput, this.responseDateFromInput, isUpdate);
    const [toDatePend, fromDatePend] = this.getDateFromDatePicker(this.pendCreateDateTo, this.pendCreateDateFrom, isUpdate);
    const [startDate, endDate] = this.getDateFromDatePicker(this.startDateInput, this.endDateInput, isUpdate);
    const [toDateOverread2, fromDateOverread2] = this.getDateFromDatePicker(this.overread2ToDate, this.overread2FromDate, isUpdate);
    const [toDateBilling, fromDateBilling] = this.getDateFromDatePicker(this.billingDateTo, this.billingDateFrom, isUpdate);
    const [toDateUpdate, fromDateUpdate] = this.getDateFromDatePicker(this.updateDateTo, this.updateDateFrom, isUpdate);
    const [toContactDate, fromContactDate] = this.getDateFromDatePicker(this.contactDateToInput, this.contactDateFromInput, isUpdate);
    const [toCreateDate, fromCreateDate] = this.getDateFromDatePicker(this.createToDate, this.createFromDate, isUpdate);
    const [contactStartDate, contactEndDate] = this.getDateFromDatePicker(this.contactDateStart, this.contactDateEnd);
    const [moveStartDate, moveEndDate] = this.getDateFromDatePicker(this.moveDateStart, this.moveDateEnd);
    const [retrievedDateStart, retrievedDateEnd] = this.getDateFromDatePicker(this.retrievedDateStart, this.retrievedDateEnd);
    const [movebackDateStart, movebackDateEnd] = this.getDateFromDatePicker(this.movebackDateStart, this.movebackDateEnd);
    const [dateRangeStart, dateRangeEnd] = this.getDateFromDatePicker(this.dateRangeStart, this.dateRangeEnd);

    return new AnalyticsRequest({
      projectIdsAsCsv: this.getFilterValue(this.projectsInput.key, true, true),
      measureIdsAsCsv: this.getFilterValue(this.measuresInput.key, true, false),
      addressId: this.getFilterValue(this.addressIdInput.key, false),
      city: this.getFilterValue(this.cityInput.key, false),
      state: this.getFilterValue(this.stateInput.key, false),
      addressGroup: this.getFilterValue(this.addressGroupInput.key, false),
      dashboardType: this.pageType,
      memberId: this.form.get("memberId").value,
      memberFirstName: this.form.get("memberFirstName").value,
      memberLastName: this.form.get("memberLastName").value,
      memberDob: this.form.get("memberDob").value,
      memberDobFrom: this.form.get("memberDobFrom").value,
      memberDobTo: this.form.get("memberDobTo").value,
      memberGender: this.form.get("memberGender").value,
      memberKey: this.form.get("memberKey").value,
      npi: this.form.get("npi").value,
      chaseId: this.form.get("chaseId").value,
      gapsAsCsv: this.getFilterValue(this.numeratorsInput.key, true, true),
      abstractionToDate: toDateAbstract,
      abstractionFromDate: fromDateAbstract,
      overread2ToDate: toDateOverread2,
      overread2FromDate: fromDateOverread2,
      pendStatusCsv: this.getFilterValue(this.pendStatusInput.key, true, true),
      pendCodeCsv: this.getFilterValue(this.pendCodesInput.key, true, false),
      pendTypeIdCsv: this.getFilterValue(this.pendCodesInput.key, true, true),
      pendToDate: toDatePend,
      pendFromDate: fromDatePend,
      responseDateFrom: fromDateResponse,
      responseDateTo: toDateResponse,
      chartReceivedDateFrom: this.form.get("chartReceivedDateFrom").value,
      chartReceivedDateTo: this.form.get("chartReceivedDateTo").value,
      completionFromDate: fromDateCompletion,
      completionToDate: toDateCompletion,
      updateFromDate: fromDateUpdate,
      updateToDate: toDateUpdate,
      workflowStatusCsv: this.getFilterValue(this.workflowStatusInput.key, true, true),
      startDate,
      endDate,
      retrievalFromDate: this.getFilterValue(this.retrievalDateFrom.key, false),
      retrievalToDate: this.getFilterValue(this.retrievalDateTo.key, false),
      retrievalTypeAsCsv: this.getFilterValue(this.retrievalTypeInput.key, true, true),
      contactFromDate: fromContactDate,
      contactToDate: toContactDate,
      deFromDate: this.form.get("deFromDate").value,
      deToDate: this.form.get("deToDate").value,
      fileCreateFromDate: this.form.get("fileCreateDateFrom").value,
      fileCreateToDate: this.form.get("fileCreateDateTo").value,
      contactMethodTypeAsCsv: this.getFilterValue(this.contactMethodTypeInput.key, true, true),
      contactResultTypeAsCsv: this.getFilterValue(this.callTypeInput.key, true, true),
      loadStartDate: this.form.get("loadStartDate").value,
      loadEndDate: this.form.get("loadEndDate").value,
      dataLoadStartDate: this.form.get("dataLoadStartDate").value,
      dataLoadEndDate: this.form.get("dataLoadEndDate").value,
      serviceOrgIdsAsCsv: this.getFilterValue(this.organizationInput.key, true, true),
      businessDateFrom: this.form.get("businessDateFromInput").value,
      businessDateTo: this.form.get("businessDateToInput").value,
      billingFromDate: fromDateBilling,
      billingToDate: toDateBilling,
      createFromDate: fromCreateDate,
      createToDate: toCreateDate,
      productName: this.getFilterValue(this.productsInput.key, true,true),
      numeratorCodesAsCsv: this.getFilterValue(this.numeratorInput.key, true, true),
      lineOfBusinessAsCsv: this.getFilterValue(this.lineOfBusinessInput.key, true, true),
      complianceCodesAsCsv: this.getFilterValue(this.complianceInput.key, true, true),
      sampleComplianceCodesAsCsv: this.getFilterValue(this.sampleComplianceInput.key, true, true),
      healthPlanNamesAsCsv: this.getFilterValue(this.healthPlanInput.key, true, true),
      contractNumbersAsCsv: this.getFilterValue(this.contractNumberInput.key, true, true),
      pendOwnerAsCsv: this.getFilterValue(this.pendOwnerInput.key, true, true),
      retrievalOwner: this.getFilterValue(this.retrievalOwnerInput.key, false, true),
      documentQueueId: this.getFilterValue(this.documentQueueId.key, false),
      confirmationNumber: this.getFilterValue(this.confirmationNumber.key, false),
      providerGatewayPin: this.getFilterValue(this.providerGatewayPin.key, false),
      contactMethodAsCsv: this.getFilterValue(this.contactMethodInput.key, true, true),
      contactMethodStatus: this.getFilterValue(this.contactStatusInput.key, true, true),
      documentStatusAsCsv: this.getFilterValue(this.documentStatus.key, true, true),
      expectedRetrieval: this.getFilterValue(this.expectedRetrievalInput.key, true, true),
      projectStatus: this.getFilterValue(this.projectStatusInput.key, true, true),
      vendorName: this.getFilterValue(this.vendorNameInput.key, true, true),
      specialHandling: this.getFilterValue(this.specialHandlingInput.key, true, true),
      pendTypeAsCsv: this.getFilterValue(this.pendTypeInput.key, true, true),
      contactStartDate,
      contactEndDate,
      moveStartDate,
      moveEndDate,
      abstractionBy: this.getFilterValue(this.abstractionBy.key, false),
      pendBy: this.getFilterValue(this.pendBy.key, false),
      movebackBy: this.getFilterValue(this.movebackBy.key, false),
      overread2By: this.getFilterValue(this.overread2By.key, false),
      codedBy: this.getFilterValue(this.codedBy.key, false),
      updatedBy: this.getFilterValue(this.updatedBy.key, false),
      userName: this.getFilterValue(this.userName.key, false),
      retrievedDateStart,
      retrievedDateEnd,
      movebackDateStart,
      movebackDateEnd,
      dateRangeStart,
      dateRangeEnd,
      login: this.getFilterValue(this.login.key, false),
      userEmail: this.getFilterValue(this.userEmail.key, false),
      userStatus: this.getFilterValue(this.userStatus.key, true, true),
      currentChaseStatus: this.getFilterValue(this.currentChaseStatus.key, true, true),
      currentProcessStep: this.getFilterValue(this.currentProcessStep.key, true, true),
      vendorInvoiceType: this.getFilterValue(this.vendorInvoiceType.key, true, true),
      hideFilters: this.hideFilters,
    });
  }

  getDateFromDatePicker(toKey: any, fromKey: any, isUpdate?: boolean): [string, string] {
    let todate = "";
    let fromdate = "";
    if ((this.form.get(toKey.key).value || this.form.get(fromKey.key).value) && isUpdate) {
      const toDatePicker = this.form.get(toKey.key).value;
      const fromDatePicker = this.form.get(fromKey.key).value;
      todate = toDatePicker ? new Date(toDatePicker).toLocaleDateString("en-ZA") : null;
      fromdate = fromDatePicker ? new Date(fromDatePicker).toLocaleDateString("en-ZA") : null;
    } else {
      todate = this.datePipe.transform(this.form.get(toKey.key).value, "MM/dd/yyyy");
      this.form.get(toKey.key).setValue(todate);
      fromdate = this.datePipe.transform(this.form.get(fromKey.key).value, "MM/dd/yyyy");
      this.form.get(fromKey.key).setValue(fromdate);

      const toDatePicker = this.form.get(toKey.key).value;
      const fromDatePicker = this.form.get(fromKey.key).value;
      todate = toDatePicker ? new Date(toDatePicker).toLocaleDateString("en-ZA") : null;
      fromdate = fromDatePicker ? new Date(fromDatePicker).toLocaleDateString("en-ZA") : null;
    }
    return [todate, fromdate];
  }
  showFilters(): void {
    this.isFiltersVisible = true;
    if (this.filters.includes(this.currentDateFilters)) {
      this.form.get(this.dateFilterInput.key).setValidators(null)
      this.form.get(this.dateFilterInput.key).setErrors(null);
    }
    if (this.filters.includes("ResponseDate") && this.filters.includes("AbstractionDate")) {
      this.form.get(this.abstractionFromDate.key).setValidators(null)
      this.form.get(this.abstractionFromDate.key).setErrors(null);
    }
  }

  get pageType(): any {
    const dashboard = AnalyticsHedisDashboard.getDashboardType(this.getRouteUrlValue);
    if (ArrayHelper.isAvailable(dashboard.filters)) {
      this.filters = dashboard.filters;
    }
    if (ArrayHelper.isAvailable(dashboard.hideFilters)) {
      this.hideFilters = dashboard.hideFilters;
    }
    const dashboardsWithoutFilter = [LookerDashboardType.SftpReportDW,LookerDashboardType.StalledDocumentsReportDW];
    if (dashboardsWithoutFilter.includes(dashboard.dashboardType)) {
      this.filters = dashboard.filters;
      this.isFilterIconVisible=true;
    }
    return dashboard.dashboardType;
  }
  showFilter(filter: string): boolean {
    return this.filters.includes(filter);
  }
  showFiltersOrGetData(): void {
    const filterData = this.session.get(this.getRouteUrlValueForSession, new AnalyticsRequest());
    if (StringHelper.isAvailable(this.getRouteUrlValueForSession) && this.session.get(this.getRouteUrlValueForSession, null)) {
      const sessionAnalyticsFilters = this.session.get(this.getRouteUrlValueForSession, null);
      this.analyticsFilter = sessionAnalyticsFilters;
    } else {
      this.analyticsFilter = filterData;
    }
    this.dashboardType = this.pageType;
    const filterResult = Object.keys(filterData).map(key => ({ key, value: filterData[key] }));
    if (this.filters.includes("Project")) {
      this.projectsInput =
        new CheckboxGroup({
          key: "Projects",
          label: "HEDIS Projects",
          validators: [Validators.required],
          errorMessages: {
            required: "Please select at least one Project.",
          },
        });
      this.isFiltersVisible = filterResult.filter(c => c.value).length < 2;
    }
    if (this.filters.includes("UpdateDate")) {
      this.updateDateFrom =
        new Calendar({
          key: "updateFromDate",
          label: "From",
          placeholder: "Enter From Date",
          maxDate: new Date(),
          validators: [Validators.required],
          errorMessages: {
            required: "Please select Update Date.",
          },
        });
      this.isFiltersVisible = filterResult.filter(c => c.value).length < 2;
    }
    this.getAbstractDateValidation();
    this.createform();
    this.setServiceOrganizationValidation();
    if (!this.isFiltersVisible) {
      this.getLookerUrl(false);
    }
    this.session.delete(this.getRouteUrlValueForSession);
  }
  private setServiceOrganizationValidation(): void {
    if (this.isParentOrg) {
      this.form.get("organization").setValidators([Validators.required]);
      this.form.get("organization").updateValueAndValidity();
      this.errorMessage = "Please select at least one Service Organization.";
      this.isFiltersVisible = !StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv);
    } else {
      this.form.get("organization").clearValidators();
      this.form.get("organization").updateValueAndValidity();
    }
  }
  getAbstractDateValidation() {
    if (this.filters.includes("AbstractionDate")) {
      this.dateFilterInput = this.abstractionFromDate;
      this.toDateFilterInput = this.abstractionToDate;
      this.currentDateFilters = "AbstractionDate";
      this.currentDateFilterTab = "Abstraction Date";
      const abstractionFromDate = this.form.get(this.abstractionFromDate.key).value;
      this.abstractionFromDate = new Calendar({
        ...this.abstractionFromDate,
        value: abstractionFromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("Overread2Date")) {
      this.dateFilterInput = this.overread2FromDate;
      this.toDateFilterInput = this.overread2ToDate;
      this.currentDateFilters = "Overread2Date";
      this.currentDateFilterTab = "Overread2 Date";
      const overread2FromDate = this.form.get(this.overread2FromDate.key).value;
      this.overread2FromDate = new Calendar({
        ...this.overread2FromDate,
        value: overread2FromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("CompletionDate")) {
      this.dateFilterInput = this.completionFromDate;
      this.toDateFilterInput = this.completionToDate;
      this.currentDateFilters = "CompletionDate";
      this.currentDateFilterTab = "Completion Date";
      const completionFromDate = this.form.get(this.completionFromDate.key).value;
      this.completionFromDate = new Calendar({
        ...this.completionFromDate,
        value: completionFromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("ResponseDate")) {
      this.dateFilterInput = this.responseDateFromInput;
      this.toDateFilterInput = this.responseDateToInput;
      this.currentDateFilters = "ResponseDate";
      this.currentDateFilterTab = "Response Date";
      const responseDateFromInput = this.form.get(this.responseDateFromInput.key).value;
      this.responseDateFromInput = new Calendar({
        ...this.responseDateFromInput,
        value: responseDateFromInput,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("PendDate")) {
      this.dateFilterInput = this.pendCreateDateFrom;
      this.toDateFilterInput = this.pendCreateDateTo;
      this.currentDateFilters = "PendDate";
      this.currentDateFilterTab = "Pend Date";
      const pendCreateDateFrom = this.form.get(this.pendCreateDateFrom.key).value;
      this.pendCreateDateFrom = new Calendar({
        ...this.pendCreateDateFrom,
        value: pendCreateDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("UpdateDate")) {
      this.dateFilterInput = this.updateDateFrom;
      this.toDateFilterInput = this.updateDateTo;
      this.currentDateFilters = "UpdateDate";
      this.currentDateFilterTab = "Update Date";
      const updateDateTo = this.form.get(this.updateDateTo.key).value;
      this.updateDateTo = new Calendar({
        ...this.updateDateTo,
        value: updateDateTo,
        validators: [Validators.required],
        placeholder: "Enter To Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("ContactDate")) {
      this.dateFilterInput = this.contactDateFromInput;
      this.toDateFilterInput = this.contactDateToInput;
      this.currentDateFilters = "ContactDate";
      this.currentDateFilterTab = "Contact Date";
      const contactDateFromInput = this.form.get(this.contactDateFromInput.key).value;
      this.contactDateFromInput = new Calendar({
        ...this.contactDateFromInput,
        value: contactDateFromInput,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("BillingDate")) {
      this.dateFilterInput = this.billingDateFrom;
      this.toDateFilterInput = this.billingDateTo;
      this.currentDateFilters = "BillingDate";
      this.currentDateFilterTab = "Billing Date";
      const billingDateFrom = this.form.get(this.billingDateFrom.key).value;
      this.billingDateFrom = new Calendar({
        ...this.billingDateFrom,
        value: billingDateFrom,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a To Date that is after the From Date.",
        },
      } as any);
    }
    if (this.filters.includes("StartDate")) {
      this.dateFilterInput = this.startDateInput;
      this.toDateFilterInput = this.endDateInput;
      this.currentDateFilters = "StartDate";
      this.currentDateFilterTab = "Start Date";
      this.toDateFilter = "End Date";
      const startDateInput = this.form.get(this.startDateInput.key).value;
      this.startDateInput = new Calendar({
        ...this.startDateInput,
        value: startDateInput,
        validators: [Validators.required],
        placeholder: "Enter Start Date",
        errorMessages: {
          required: "Please select a End Date that is after the Start Date.",
        },
      } as any);
    }
    if (this.filters.includes("CreateDate")) {
      this.dateFilterInput = this.createFromDate;
      this.toDateFilterInput = this.createToDate;
      this.currentDateFilters = "CreateDate";
      this.currentDateFilterTab = "Create Date";
      const createFromDate = this.form.get(this.createFromDate.key).value;
      this.createFromDate = new Calendar({
        ...this.createFromDate,
        value: createFromDate,
        validators: [Validators.required],
        placeholder: "Enter From Date",
        errorMessages: {
          required: "Please select a End Date that is after the Start Date.",
        },
      } as any);
    }
  }
  getAllSelectableInputs(): void {
    if (!this.isParentOrg) {
      this.getProjects();
      this.getMeasures();
    }
    this.getWorkflowStatus();
    this.getOrganizations();
    this.getProducts();
    this.getLineOfBusiness();
    this.getComplianceCodes();
    this.getHealthPlans();
    this.getContractNumbers();
  }
  resetAllFilters(): void {
    this.showErrorMessage = false;
    this.dateErrorMessage = "";
    this.isDateErrorExist = false;
    this.form.reset();
    this.showBorder = false;
    if (this.filters.includes("Project")) {
      this.isVisible = false;
      this.session.delete(this.getRouteUrlValueForSession);
    } else {
      this.getLookerUrl(false);
    }
    this.removeFilter.emit(this.formFilterRequest);
  }
  getMenuItems() {
    const analyticsItemRequest = new AnalyticsItemRequest({
      projectType: ProjectType.HEDIS,
      reportUrl: this.getRouteUrlValue,
    });

    this.analyticsService
      .getAnalyticMenuItems(analyticsItemRequest)
      .subscribe(menuItem => {
        this.menuItems = this.assignAndNotify(menuItem);
      });
  }
  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const items = List(data);
    return items;
  }
  onSelectChange(): void { this.setNumeratorControlValues(); }
  onRemoveFilter(event: FormGroup) {
    this.removeFilter.emit(event);
    const projectFilteredValues = [];
    const measureFilteredValues = [];
    const gapFilteredValues = [];
    const pendStatusFilteredValues = [];
    const pendCodesFilteredValues = [];
    const workFlowStatusFilteredValues = [];
    const organizationFilteredValues = [];
    const lineOfBusinessFilteredValues = [];
    const numeratorFilteredValues = [];
    const productFilteredValues = [];
    const complianceFilteredValues = [];
    const healthPlanFilteredValues = [];
    const contractNumberFilteredValues = [];
    Object.keys(event.controls).forEach(key => {
      const controlValue = this.formFilterRequest.get(key);
      if (ArrayHelper.isAvailable(controlValue.value)) {
        controlValue.value.forEach(selectedValue => {
          const projectFilter = (key === selectedValue.text) ? this.projectsInput.options.find(a => a.text === selectedValue.value)
            : this.projectsInput.options.find(a => a.text === selectedValue.text);
          const measureFilter = (key === selectedValue.text) ? this.measuresInput.options.find(a => a.text === selectedValue.value)
            : this.measuresInput.options.find(a => a.text === selectedValue.text);
          const gapFilter = (key === selectedValue.text) ? this.numeratorsInput.options.find(a => a.text === selectedValue.value)
            : this.numeratorsInput.options.find(a => a.text === selectedValue.text);
          const pendStatusFilter = (key === selectedValue.text) ? this.pendStatusInput.options.find(a => a.text === selectedValue.value)
            : this.pendStatusInput.options.find(a => a.text === selectedValue.text);
          const pendCodesFilter = (key === selectedValue.text) ? this.pendCodesInput.options.find(a => a.text === selectedValue.value)
            : this.pendCodesInput.options.find(a => a.text === selectedValue.text);
          const workflowStatusFilter = (key === selectedValue.text) ? this.workflowStatusInput.options.find(a => a.text === selectedValue.value)
            : this.workflowStatusInput.options.find(a => a.text === selectedValue.text);
          const organizationFilter = (key === selectedValue.text) ? this.organizationInput.options.find(a => a.text === selectedValue.value)
            : this.organizationInput.options.find(a => a.text === selectedValue.text);
          const lineofBusinessFilter = (key === selectedValue.text) ? this.lineOfBusinessInput.options.find(a => a.text === selectedValue.value)
            : this.lineOfBusinessInput.options.find(a => a.text === selectedValue.text);
          const numeratorFilter = (key === selectedValue.text) ? this.numeratorInput.options.find(a => a.text === selectedValue.value)
            : this.numeratorInput.options.find(a => a.text === selectedValue.text);
          const productFilter = (key === selectedValue.text) ? this.productsInput.options.find(a => a.text === selectedValue.value)
            : this.productsInput.options.find(a => a.text === selectedValue.text);
          const complianceFilter = (key === selectedValue.text) ? this.complianceInput.options.find(a => a.text === selectedValue.value)
            : this.complianceInput.options.find(a => a.text === selectedValue.text);
          const healthPlanFilter = (key === selectedValue.text) ? this.healthPlanInput.options.find(a => a.text === selectedValue.value)
            : this.healthPlanInput.options.find(a => a.text === selectedValue.text);
          const contractNumberFilter = (key === selectedValue.text) ? this.contractNumberInput.options.find(a => a.text === selectedValue.value)
            : this.contractNumberInput.options.find(a => a.text === selectedValue.text);

          if (typeof projectFilter === "object" && projectFilter != null) {
            projectFilteredValues.push({ text: projectFilter.text, value: projectFilter.value });
            this.form.get(this.projectsInput.key).setValue(projectFilteredValues);
          }
          if (typeof measureFilter === "object" && measureFilter != null) {
            measureFilteredValues.push({ text: measureFilter.text, value: measureFilter.value });
            this.form.get(this.measuresInput.key).setValue(measureFilteredValues);
          }
          if (typeof gapFilter === "object" && gapFilter != null) {
            gapFilteredValues.push({ text: gapFilter.text, value: gapFilter.value });
            this.form.get(this.numeratorsInput.key).setValue(gapFilteredValues);
          }
          if (typeof pendStatusFilter === "object" && pendStatusFilter != null) {
            pendStatusFilteredValues.push({ text: pendStatusFilter.text, value: pendStatusFilter.value });
            this.form.get(this.pendStatusInput.key).setValue(pendStatusFilteredValues);
          }
          if (typeof pendCodesFilter === "object" && pendCodesFilter != null) {
            pendCodesFilteredValues.push({ text: pendCodesFilter.text, value: pendCodesFilter.value });
            this.form.get(this.pendCodesInput.key).setValue(pendCodesFilteredValues);
          }
          if (typeof workflowStatusFilter === "object" && workflowStatusFilter != null) {
            workFlowStatusFilteredValues.push({ text: workflowStatusFilter.text, value: workflowStatusFilter.value });
            this.form.get(this.workflowStatusInput.key).setValue(workFlowStatusFilteredValues);
          }
          if (typeof organizationFilter === "object" && organizationFilter != null) {
            organizationFilteredValues.push({ text: organizationFilter.text, value: organizationFilter.value });
            this.form.get(this.organizationInput.key).setValue(organizationFilteredValues);
          }
          if (typeof lineofBusinessFilter === "object" && lineofBusinessFilter != null) {
            lineOfBusinessFilteredValues.push({ text: lineofBusinessFilter.text, value: lineofBusinessFilter.value });
            this.form.get(this.lineOfBusinessInput.key).setValue(lineOfBusinessFilteredValues);
          }
          if (typeof productFilter === "object" && productFilter != null) {
            productFilteredValues.push({ text: productFilter.text, value: productFilter.value });
            this.form.get(this.productsInput.key).setValue(productFilteredValues);
          }
          if (typeof numeratorFilter === "object" && numeratorFilter != null) {
            numeratorFilteredValues.push({ text: numeratorFilter.text, value: numeratorFilter.value });
            this.form.get(this.numeratorInput.key).setValue(numeratorFilteredValues);
          }
          if (typeof complianceFilter === "object" && complianceFilter != null) {
            complianceFilteredValues.push({ text: complianceFilter.text, value: complianceFilter.value });
            this.form.get(this.complianceInput.key).setValue(complianceFilteredValues);
          }
          if (typeof healthPlanFilter === "object" && healthPlanFilter != null) {
            healthPlanFilteredValues.push({ text: healthPlanFilter.text, value: healthPlanFilter.value });
            this.form.get(this.healthPlanInput.key).setValue(healthPlanFilteredValues);
          }
          if (typeof contractNumberFilter === "object" && contractNumberFilter != null) {
            contractNumberFilteredValues.push({ text: contractNumberFilter.text, value: contractNumberFilter.value });
            this.form.get(this.contractNumberInput.key).setValue(contractNumberFilteredValues);
          }
        });
      }
    });
    if (this.filters.includes("Project")) {
      if (ArrayHelper.isAvailable(projectFilteredValues)) {
        this.getLookerUrl(true);
      } else {
        const analyticsRequest = this.bindFilterData();
        this.session.put(this.getRouteUrlValueForSession, analyticsRequest);
        this.isFiltersVisible = true; this.isVisible = false;
      }
    } else {
      this.getLookerUrl(true);
    }
  }
  get getRouteUrlValue(): string {
    return this.router.url;
  }

  get getRouteUrlValueForSession(): string {
    return `${window.location.href}/${this.organizationId}`;
  }
  private setControlValue(control: CheckboxGroup, isStringValue = true): void {
    let filters: string[];
    if (control.key === "Projects" && StringHelper.isAvailable(this.analyticsFilter.projectIdsAsCsv)) {
      filters = this.analyticsFilter.projectIdsAsCsv.split(",");
    } else if (control.key === "Measures" && StringHelper.isAvailable(this.analyticsFilter.measureIdsAsCsv)) {
      filters = this.analyticsFilter.measureIdsAsCsv.split(",");
    } else if (control.key === "Gaps" && StringHelper.isAvailable(this.analyticsFilter.gapsAsCsv)) {
      filters = this.analyticsFilter.gapsAsCsv.split(",");
    } else if (control.key === this.pendStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.pendStatusCsv)) {
      filters = this.analyticsFilter.pendStatusCsv.split(",");
    } else if (control.key === this.organizationInput.key && StringHelper.isAvailable(this.analyticsFilter.serviceOrgIdsAsCsv)) {
      filters = this.analyticsFilter.serviceOrgIdsAsCsv.split(",");
    } else if (control.key === this.productsInput.key && StringHelper.isAvailable(this.analyticsFilter.productName)) {
      filters = this.analyticsFilter.productName.split(",");
    } else if (control.key === this.numeratorInput.key && StringHelper.isAvailable(this.analyticsFilter.numeratorCodesAsCsv)) {
      filters = this.analyticsFilter.numeratorCodesAsCsv.split(",");
    } else if (control.key === this.lineOfBusinessInput.key && StringHelper.isAvailable(this.analyticsFilter.lineOfBusinessAsCsv)) {
      filters = this.analyticsFilter.lineOfBusinessAsCsv.split(",");
    } else if (control.key === this.complianceInput.key && StringHelper.isAvailable(this.analyticsFilter.complianceCodesAsCsv)) {
      filters = this.analyticsFilter.complianceCodesAsCsv.split(",");
    } else if (control.key === this.sampleComplianceInput.key && StringHelper.isAvailable(this.analyticsFilter.sampleComplianceCodesAsCsv)) {
      filters = this.analyticsFilter.sampleComplianceCodesAsCsv.split(",");
    } else if (control.key === this.healthPlanInput.key && StringHelper.isAvailable(this.analyticsFilter.healthPlanNamesAsCsv)) {
      filters = this.analyticsFilter.healthPlanNamesAsCsv.split(",");
    } else if (control.key === this.contractNumberInput.key && StringHelper.isAvailable(this.analyticsFilter.contractNumbersAsCsv)) {
      filters = this.analyticsFilter.contractNumbersAsCsv.split(",");
    } else if (control.key === this.pendOwnerInput.key && StringHelper.isAvailable(this.analyticsFilter.pendOwnerAsCsv)) {
      filters = this.analyticsFilter.pendOwnerAsCsv.split(",");
    } else if (control.key === this.retrievalOwnerInput.key && StringHelper.isAvailable(this.analyticsFilter.retrievalOwner)) {
      filters = this.analyticsFilter.retrievalOwner.split(",");
    } else if (control.key === this.userStatus.key && StringHelper.isAvailable(this.analyticsFilter.userStatus)) {
      filters = this.analyticsFilter.userStatus.split(",");
    } else if (control.key === this.retrievalTypeInput.key && StringHelper.isAvailable(this.analyticsFilter.retrievalTypeAsCsv)) {
      filters = this.analyticsFilter.retrievalTypeAsCsv.split(",");
    } else if (control.key === this.fileCreateDateFromInput.key && StringHelper.isAvailable(this.analyticsFilter.fileCreateFromDate)) {
      filters = this.analyticsFilter.fileCreateFromDate.split(",");
    } else if (control.key === this.fileCreateDateToInput.key && StringHelper.isAvailable(this.analyticsFilter.fileCreateToDate)) {
      filters = this.analyticsFilter.fileCreateToDate.split(",");
    } else if (control.key === this.documentStatus.key && StringHelper.isAvailable(this.analyticsFilter.documentStatusAsCsv)) {
      filters = this.analyticsFilter.documentStatusAsCsv.split(",");
    } else if (control.key === this.contactMethodInput.key && StringHelper.isAvailable(this.analyticsFilter.contactMethodAsCsv)) {
      filters = this.analyticsFilter.contactMethodAsCsv.split(",");
    } else if (control.key === this.contactStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.contactMethodStatus)) {
      filters = this.analyticsFilter.contactMethodStatus.split(",");
    } else if (control.key === this.expectedRetrievalInput.key && StringHelper.isAvailable(this.analyticsFilter.expectedRetrieval)) {
      filters = this.analyticsFilter.expectedRetrieval.split(",");
    } else if (control.key === this.projectStatusInput.key && StringHelper.isAvailable(this.analyticsFilter.projectStatus)) {
      filters = this.analyticsFilter.projectStatus.split(",");
    } else if (control.key === this.vendorNameInput.key && StringHelper.isAvailable(this.analyticsFilter.vendorName)) {
      filters = this.analyticsFilter.vendorName.split(",");
    } else if (control.key === this.specialHandlingInput.key && StringHelper.isAvailable(this.analyticsFilter.specialHandling)) {
      filters = this.analyticsFilter.specialHandling.split(",");
    }  else if (control.key === this.currentChaseStatus.key && StringHelper.isAvailable(this.analyticsFilter.currentChaseStatus)) {
      filters = this.analyticsFilter.currentChaseStatus.split(",");
    }  else if (control.key === this.currentProcessStep.key && StringHelper.isAvailable(this.analyticsFilter.currentProcessStep)) {
      filters = this.analyticsFilter.currentProcessStep.split(",");
    } else if (control.key === this.vendorInvoiceType.key && StringHelper.isAvailable(this.analyticsFilter.vendorInvoiceType)) {
      filters = this.analyticsFilter.vendorInvoiceType.split(",");
    } else if (control.key === this.pendTypeInput.key && StringHelper.isAvailable(this.analyticsFilter.pendTypeAsCsv)) {
      filters = this.analyticsFilter.pendTypeAsCsv.split(",");
    }
    if (ArrayHelper.isAvailable(filters)) {
      const filterValue = [];
      filters.forEach(item => {
        if (ArrayHelper.isAvailable(control.options)) {
          const controlValues = isStringValue ? control.options.find(a => a.text === item) : control.options.find(a => a.value === item || a.value === +item);
          filterValue.push(controlValues);
        }
      });
      if (control.key === "Gaps" && ArrayHelper.isAvailable(filterValue)) {
        const controlFilteredValues = filterValue.filter(x => x !== undefined);
        control.selectedOptions = controlFilteredValues;
      } else {
        control.selectedOptions = filterValue;
      }
      this.form.get(control.key).setValue(control.selectedOptions);
    }
    if (this.hasRestrictedProjectSelection() && ArrayHelper.isAvailable(this.projectsInput.selectedOptions)) {
      this.selectedProject$.next(this.projectsInput.selectedOptions[0]);
    }
  }
  private setFilteredInputValue(formControls: FormGroup, analyticsFilter: AnalyticsRequest): void {
    Object.keys(formControls.controls).forEach(key => {
      for (const analyticFilterKey of Object.keys(analyticsFilter)) {
        this.pFormGroupKey = analyticFilterKey;
        if (key === analyticFilterKey || key === this.controlKeyToMatch) {
          const analyticFilterValue = analyticsFilter[analyticFilterKey];
          this.form.get(key).setValue(analyticFilterValue);
        }
      }
    });
  }

  get controlKeyToMatch(): string {
    let controlKey: string;
    switch (this.pFormGroupKey) {
      case "addressId":
        controlKey = "address";
        break;
      case "fileCreateFromDate":
        controlKey = "fileCreateDateFrom";
        break;
      case "fileCreateToDate":
        controlKey = "fileCreateDateTo";
        break;
      default:
        break;
    }
    return controlKey;
  }
  get checkAbstractionDate(): boolean {
    return ((!StringHelper.isAvailable(this.form.get("abstractionToDate").value) && !StringHelper.isAvailable(this.form.get("abstractionFromDate").value))
      || (StringHelper.isAvailable(this.form.get("abstractionToDate").value) && StringHelper.isAvailable(this.form.get("abstractionFromDate").value)
      ));
  }
  getFilterValue(key: string, isCsvValue = true, isUseValue = true) {
    if (this.isFilterUpdated && isCsvValue) {
      return this.analyticsService.getValueForAnalyticsRequestAsCsv(this.form.get(key).value, isUseValue);
    } else if (this.isFilterUpdated) {
      return this.form.get(key).value;
    } else {
      switch (key) {
        case "Projects":
          return this.analyticsFilter.projectIdsAsCsv;
        case "address":
          return this.analyticsFilter.addressId;
        case "Measures":
          return this.analyticsFilter.measureIdsAsCsv;
        case "Gaps":
          return this.analyticsFilter.gapsAsCsv;
        case "pendCodes":
          if (isUseValue) {
            return this.analyticsFilter.pendTypeIdCsv;
          }
          else {
            return this.analyticsFilter.pendCodeCsv
          }
        case "pendStatus":
          return this.analyticsFilter.pendStatusCsv;
        case this.workflowStatusInput.key:
          return this.analyticsFilter.workflowStatusCsv;
        case this.organizationInput.key:
          return this.analyticsFilter.serviceOrgIdsAsCsv;
        case this.productsInput.key:
          return this.analyticsFilter.productName;
        case this.lineOfBusinessInput.key:
          return this.analyticsFilter.lineOfBusinessAsCsv;
        case this.numeratorInput.key:
          return this.analyticsFilter.numeratorCodesAsCsv;
        case this.complianceInput.key:
          return this.analyticsFilter.complianceCodesAsCsv;
        case this.sampleComplianceInput.key:
          return this.analyticsFilter.sampleComplianceCodesAsCsv;
        case this.healthPlanInput.key:
          return this.analyticsFilter.healthPlanNamesAsCsv;
        case this.contractNumberInput.key:
          return this.analyticsFilter.contractNumbersAsCsv;
        case this.pendOwnerInput.key:
          return this.analyticsFilter.pendOwnerAsCsv;
        case this.retrievalOwnerInput.key:
          return this.analyticsFilter.retrievalOwner;
        case this.retrievalTypeInput.key:
          return this.analyticsFilter.retrievalTypeAsCsv;
        case this.fileCreateDateFromInput.key:
          return this.analyticsFilter.fileCreateFromDate;
        case this.fileCreateDateToInput.key:
          return this.analyticsFilter.fileCreateToDate;
        case this.documentStatus.key:
          return this.analyticsFilter.documentStatusAsCsv;
        case this.contactMethodInput.key:
          return this.analyticsFilter.contactMethodAsCsv;
        case this.contactStatusInput.key:
          return this.analyticsFilter.contactMethodStatus;
        case this.expectedRetrievalInput.key:
          return this.analyticsFilter.expectedRetrieval;
        case this.projectStatusInput.key:
          return this.analyticsFilter.projectStatus;
        case this.vendorNameInput.key:
          return this.analyticsFilter.vendorName;
        case this.specialHandlingInput.key:
          return this.analyticsFilter.specialHandling;
        case this.userStatus.key:
          return this.analyticsFilter.userStatus;
        case this.currentChaseStatus.key:
          return this.analyticsFilter.currentChaseStatus;
        case this.currentProcessStep.key:
          return this.analyticsFilter.currentProcessStep;
        case this.vendorInvoiceType.key:
          return this.analyticsFilter.vendorInvoiceType;
        case this.pendTypeInput.key:
            return this.analyticsFilter.pendTypeAsCsv;
        default:
          return this.analyticsFilter[key];
      }
    }
  }
  private setPendCodesControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.pendTypeIdCsv)) {
      const pendFilteredValues = this.analyticsFilter.pendTypeIdCsv.split(",");
      pendFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.pendCodesInput.options)) {
          filterValue.push(this.pendCodesInput.options.find(a => a.value === Number(item)));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.pendCodesInput.key).setValue(control.selectedOptions);
    }
  }
  getPendCodes() {
    this.createPendService.getPendDropdown()
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({ text: item.displayName.split("-")[0], value: item.pendTypeId }));
      }))
      .subscribe(options => {
        this.pendCodesInput = new CheckboxGroup({ ...this.pendCodesInput, options } as any);
        this.setPendCodesControlValue(this.pendCodesInput);
        this.formService.updateDom.next();
      });
  }
  private getPendStatus(): void {
    this.createPendService.getPendStatus(false).pipe(map((result: any) => {
      return result.pendStatus.map(item => new SelectableInput({ text: item.description, value: item.description }));
    })).subscribe(result => {
      this.pendStatusInput = { ...this.pendStatusInput, options: result } as any;
      this.setControlValue(this.pendStatusInput, true);
      this.formService.updateDom.next();
    });
  }
  private setNumeratorControlValues(): void {
    const measures = this.form.get(this.measuresInput.key).value;
    if (ArrayHelper.isAvailable(measures)) {
      const measureIds = measures.map(item => item.value);
      const numerators = this.numeratorItems.filter(x => x.measureYear === 2019 && measureIds.indexOf(x.measureId) > -1)
        .map(item => new SelectableInput({ text: `${item.measureCode}${item.numeratorCode}`, value: item.numeratorCode }));
      this.numeratorsInput = new CheckboxGroup({ ...this.numeratorsInput, options: numerators } as any);
      this.formService.updateDom.next();
    }
  }
  private setProductsInput(): void {
    if (StringHelper.isAvailable(this.analyticsFilter.productName)) {
      const optionsValue = this.productsInput.options.find(a => a.value === this.analyticsFilter.productName);
      this.productsInput.value = [optionsValue];
      this.form.get(this.productsInput.key).setValue(this.productsInput.value[0]);
    }
  }
  private getWorkflowStatus(): void {
    this.bulkUpdateChaseService.getWorkflowStatusesList().subscribe(result => {
      const workflowStatus = result.map(item => new SelectableInput({ text: item.workflowStatusName, value: item.workflowStatusName }));
      this.workflowStatusInput = { ...this.workflowStatusInput, options: workflowStatus } as any;
      this.currentProcessStep = new CheckboxGroup({ ...this.currentProcessStep, options: workflowStatus } as any);
      this.setControlValue(this.currentProcessStep, false);
      this.setWorkFlowStatusControlValue(this.workflowStatusInput);
      this.formService.updateDom.next();
    });
  }
  private setWorkFlowStatusControlValue(control: CheckboxGroup): void {
    const filterValue = [];
    if (StringHelper.isAvailable(this.analyticsFilter.workflowStatusCsv)) {
      const workFlowStatusFilteredValues = this.analyticsFilter.workflowStatusCsv.split(",");
      workFlowStatusFilteredValues.forEach((item: string) => {
        if (ArrayHelper.isAvailable(this.workflowStatusInput.options)) {
          filterValue.push(this.workflowStatusInput.options.find(a => a.value === item));
        }
      });
      control.selectedOptions = filterValue;
      this.form.get(this.workflowStatusInput.key).setValue(control.selectedOptions);
    }
  }
  private getRetreivalTypes(): void {
    this.retrievalDocumentService.getRetrievalTypes().pipe(map((result: any) => {
      return result.map(item => new SelectableInput({ text: item.text, value: item.text }));
    }))
      .subscribe(result => {
        this.retrievalTypeInput = new CheckboxGroup({ ...this.retrievalTypeInput, options: result } as any);
        this.setControlValue(this.retrievalTypeInput, false);
        this.changeDetector.markForCheck();
      });
  }
  private getCallTypes(): void {
    this.analyticsService.getCallTypes().subscribe(options => {
      this.callTypeInput = new CheckboxGroup({ ...this.callTypeInput, options }) as any;
      this.formService.updateDom.next();
    });
  }
  private getContactMethodTypes(): void {
    this.analyticsService.getContactMethodTypes(`${ContactMethodType.RecordCollectionCall},${ContactMethodType.Fax},${ContactMethodType.IncomingCall}`)
      .subscribe(options => {
        this.contactMethodTypeInput = new CheckboxGroup({ ...this.contactMethodTypeInput, options }) as any;
        this.formService.updateDom.next();
      });
  }
  get isValidForm(): boolean {
    let result = true;
    if (this.form.invalid) {
      this.formService.markAllAsTouched(this.form);
      this.changeDetector.markForCheck();
      result = false;
    }
    return result;
  }
  onOrganizationChange(): void { this.getProjects(); }

  onProjectChange(): void {
    if (this.isParentOrg) {
      this.getMeasuresByProject();
    }
    this.getHealthPlans();
    this.getContractNumbers();
    if (this.hasRestrictedProjectSelection()) {
      this.onInputChange(this.projectsInput.selectedOptions, "Project");
    }
  }

  onInputChange(array: SelectableInput[], message: string): void {
    if (ArrayHelper.isAvailable(array) && array.length > 1) {
      this.projectsInput.selectedOptions = [array.shift()];
      this.messagingService.showToast(`You can only select one ${message}`, SeverityType.ERROR);
    }
    if (ArrayHelper.isAvailable(array)) {
      this.selectedProject$.next(array[0]);
    }
  }

  private hasRestrictedProjectSelection(): boolean {
    const pageType = this.pageType.toString();
    const restrictedDashboards = [
      LookerDashboardType.ChaseSubMeasureReport.toLocaleString(),
      LookerDashboardType.ChaseSubMeasureReportDW.toLocaleString(),
      LookerDashboardType.ChaseNumeratorLevelReport.toLocaleString(),
      LookerDashboardType.ChaseNumeratorLevelReportDW.toLocaleString(),
    ];
    return StringHelper.isAvailable(pageType) && restrictedDashboards.includes(pageType);
  }

  validateDateRange(from: Calendar, to: Calendar): void {
    const fromDate = this.form.get(from.key);
    const toDate = this.form.get(to.key);
    if (!DateHelper.isAvailable(fromDate.value) || !DateHelper.isAvailable(toDate.value)) {
      return;
    }
    if (DateHelper.isAfter(fromDate.value, toDate.value)) {
      fromDate.setErrors({ isValid: true });
      this.dateErrorMessage = 'Please select a From Date that is before the To Date.';
      this.isDateErrorExist = true;
      this.getTab(this.selectedTab);
      return;
    }
    if (DateHelper.isBefore(toDate.value, fromDate.value)) {
      toDate.setErrors({ isValid: true });
      this.dateErrorMessage = 'Please select a To Date that is after the From Date.';
      this.isDateErrorExist = true;
      this.getTab(this.selectedTab);
      return;
    }
    fromDate.setErrors(null);
    toDate.setErrors(null);
    fromDate.updateValueAndValidity();
    toDate.updateValueAndValidity();
    this.dateErrorMessage = null;
    this.isDateErrorExist = false;
    this.getTab(this.selectedTab);
  }

  updateMinDate(fromDate: Calendar, toDate: Calendar): void {
    this.moveDateEnd = new Calendar({ ...toDate, minDate: new Date(this.form.get(fromDate.key).value)})
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

  private getCurrentChaseStatus(): void {
    this.genericGet(this.chaseQueryService, "getReportingStatusesList", (options) => {
      options = [...options.map(o => new SelectableInput({ text: o?.reportingStatusName, value: o?.reportingStatusName }))];
      this.currentChaseStatus = new CheckboxGroup({ ...this.currentChaseStatus, options } as any);
        this.setControlValue(this.currentChaseStatus, false);
        this.formService.updateDom.next();
    }, false);
  }

  private getVendorInvoiceTypes(): void {
    this.analyticsService.getVendorInvoiceTypes().subscribe(
      options => {
        const mappedOptions = options.map(o => new SelectableInput({ text: o.name, value: o.name }));
        this.vendorInvoiceType = new CheckboxGroup({ ...this.vendorInvoiceType, options: mappedOptions});
        this.setControlValue(this.vendorInvoiceType, false);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      }
    );
  }

  showHelpModal(): void {
    this.isHelpModalOpen = true;
  }
}
/* tslint:enable:cyclomatic-complexity */
