import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export interface IProjectOptions {
    clientId?: number;
    clientName?: string;
    projectId?: number;
    projectName?: string;
    projectTypeId?: number;
    projectTypeName?: string;
    projectDuration?: string;
    projectTemplateIdsAsCsv?: string;
    chaseCount?: number;
    projectStatus?: string;
}

export class Project {
    clientId: number;
    clientName: string;
    projectId: number;
    projectName: string;
    projectTypeId: number;
    projectTypeName: string;
    projectDuration: string;
    projectTemplateIdsAsCsv: string;
    chaseCount: number;
    projectStatus: string;
    constructor(options: IProjectOptions = {}) {
        this.clientId = options.clientId;
        this.clientName = StringHelper.clean(options.clientName);
        this.projectId = options.projectId;
        this.projectName = StringHelper.clean(options.projectName);
        this.projectTypeId = options.projectTypeId;
        this.projectTypeName = StringHelper.clean(options.projectTypeName);
        this.projectDuration = StringHelper.clean(options.projectDuration);
        this.projectTemplateIdsAsCsv = StringHelper.clean(options.projectTemplateIdsAsCsv);
        this.chaseCount = options.chaseCount;
        this.projectStatus = options.projectStatus;
    }

    get hasClientId(): boolean {
        return NumberHelper.isGreaterThan(this.clientId, 0);
    }

    get hasProjectId(): boolean {
        return NumberHelper.isGreaterThan(this.projectId, 0);
    }
}
