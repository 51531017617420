import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ChaseDetailStateService } from "../../../member/chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../../member/chase-detail/chase-detail.service";
import { ChaseDetailForAuditItem } from "../../chase-detail-for-audit-item.model";
import { AuditQueryChartService } from "./audit-query-chart.service";

@Component({
  selector: "app-audit-query-chart",
  templateUrl: "./audit-query-chart.component.html",
  styleUrls: ["./audit-query-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryChartComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  chaseDetails: ChaseDetailForAuditItem;
  constructor(
    private readonly auditQueryChartService: AuditQueryChartService,
    private chaseDetailStateService: ChaseDetailStateService,
    private chaseDetailService: ChaseDetailService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.auditQueryChartService.setChaseDetails(null);

    this.auditQueryChartService.auditChaseDetails
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.chaseDetails = data;
        if (data != null) {
          this.getChaseDetailState();
        }
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getChaseDetailState(): void {
    this.chaseDetailService.getChaseDetail(this.chaseDetails.chaseId)
      .subscribe(chaseDetail => {
        this.chaseDetailStateService.setData({
          ...chaseDetail,
      });
    });
  }

}
