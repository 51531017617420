import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ListItem } from "../../../../../shared/list/list-item";

@Injectable()
export class AuditQueryHeaderService {
  private tab = new BehaviorSubject<string>("");
  selectedTab = this.tab.asObservable();

  private chaseDetailsForAudit = new BehaviorSubject<ListItem[]>([]);
  chaseDetails = this.chaseDetailsForAudit.asObservable();

  constructor() { }

  changeTab(value: string) {
    this.tab.next(value);
  }

  setChaseDetails(value: ListItem[]) {
    this.chaseDetailsForAudit.next(value);
  }
}
