import { IAutoMapper } from "../../../../core/automapper/interfaces";

export const mapOcrPageSection = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "OcrPageSection")
    .forMember("keywordGroupName", o => o.keywordGroupName)
    .forMember("memberName", o => o.memberName)
    .forMember("providerName", o => o.providerName)
    .forMember("dateOfService", o => o.dateOfService)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("attributeCode", o => o.attributeCode)
    .forMember("attributeValue", o => o.attributeValue);
};

export class OcrPageSection {
  keywordGroupName: string;
  memberName: string;
  providerName: string;
  dateOfService: string;
  entityTypeName: string;
  attributeCode: string;
  attributeValue: string;

  constructor(options: Partial<OcrPageSection>) {
    this.keywordGroupName = options.keywordGroupName;
    this.memberName = options.memberName;
    this.providerName = options.providerName;
    this.dateOfService = options.dateOfService;
    this.entityTypeName = options.entityTypeName;
    this.attributeCode = options.attributeCode;
    this.attributeValue = options.attributeValue;
  }
}
