<div #containerHeight>
  <app-detail-header-template>

    <div top-left class="headerDetail">
      <div class="container-back-button">
        <app-back-button text="Go Back" (onBackClick)="onBackArrowClick()"></app-back-button>
      </div>
      <h3 class="container-title">
        {{chaseText}}
      </h3>
    </div>

    <div top-right class="member-items">
      <app-list [items]="memberItems"></app-list>
      <i class="fas fa-angle-down carot-icon" *ngIf="!showTopBanner" (click)="toggleTopBanner()"></i>
      <i class="fas fa-angle-up carot-icon" *ngIf="showTopBanner" (click)="toggleTopBanner()"></i>
    </div>

    <div bottom-left>
      <app-sub-menu [menuItems]="menuItems" [currentRoute]="pathLastRoute" (onClick)="onMenuItemClick($event)">
      </app-sub-menu>
    </div>


    <div bottom-right>
      <span class="readonly-view" *ngIf="!assignedToCurrentUser">Read-Only View</span>
    </div>
  </app-detail-header-template>

  <div class="chase-detail__button-container">
    <app-button *ngIf="showAssignButton" text="Assign" class="chase-button" (onClick)="openAssignModal()"></app-button>
    <app-button *ngIf="!isHealthCardTabSelected" [ngClass]="pendButtonClass" [text]="pendButtonLabel" [disabled]="!assignedToCurrentUser"
      (onClick)="openPendModal()"></app-button>

    <div class="chase-detail__tag-container" *ngIf="isChaseInfoTabSelected">
      <app-view-tags [tagType]="tagType" [objectId]="chaseId" [isGrid]="false" (onUpdate)="updateTimelineData()">
      </app-view-tags>

    </div>
  </div>
</div>

<div class="chase-detail-pend-modal">
  <app-pend-modal [(visible)]="isPendModalVisible" (onHide)="closeModal()" (onUpdate)="updateChaseData()"
    [(chases)]="selectedChases" [clinical]="clinical" [pageType]="fromPage"
    [selectedChasesStatus]="selectedChaseStatus"></app-pend-modal>

</div>

<app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible" (onHide)="closeModal()"
  (onUpdate)="updateChaseAssignedData()" [(chases)]="selectedChases" [pageType]="fromPage" isUnassignEnabled="false">
</app-chase-assignment-modal>
