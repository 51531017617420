import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
  @Input() legendData: any[];

  trackByIndex(index, item) {
    return index;
  }

}
