import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-coder-productivity",
    templateUrl: "./coder-productivity.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoderProductivityComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CODER_PRODUCTIVITY_REPORT;

    constructor() {
        this.filters = ["Projects", "Products", "LOB", "RetrievalOwner", "CompletionDate"];
    }

}
