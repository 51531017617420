import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ListItem } from "../../../../../shared/list/list-item";
import { AuditPageEntryService } from "../../../member/chase-detail/chase-detail-chart/audit-page-entry/audit-page-entry.service";
import { ChaseDetailStateService } from "../../../member/chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../../member/chase-detail/chase-detail.service";
import { ChaseDetailForAuditItem } from "../../chase-detail-for-audit-item.model";
import { AuditQueryChartService } from "../audit-query-chart/audit-query-chart.service";
import { AuditQueryHeaderService } from "../audit-query-header/audit-query-header.service";

@Component({
  selector: "app-audit-query-grid-action",
  template: `
    <app-button class="header-button" [text]="actionText" (onClick)="editSelectAction()" [disabled]="disabled"></app-button>
  `,
  styleUrls: ["./audit-query-grid-action.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryGridActionComponent {
  @Input() data: ChaseDetailForAuditItem;
  @Output() chaseId = new EventEmitter<string>();
  @Output() showWarning = new EventEmitter();

  private unsubscribe = new Subject();

  constructor(
    private readonly chaseService: ChaseDetailService,
    private readonly auditQueryHeaderService: AuditQueryHeaderService,
    private readonly auditQueryChartService: AuditQueryChartService,
    private readonly auditPageEntryService: AuditPageEntryService,
    private chaseDetailStateService: ChaseDetailStateService
  ) { }

  get actionText(): string {
    return this.data.action.toUpperCase();
  }

  editSelectAction(): void {
      this.auditQueryChartService.setChaseDetails(null);
      this.auditPageEntryService.editPageEntry(null);
      this.auditQueryHeaderService.changeTab("chart");
      this.getChaseSummaryItems();

      // SetTimeout is used here to allow the previous dom to get destroyed before loading the new dom for chart tab.
      setTimeout(() => {
        this.auditQueryChartService.setChaseDetails(this.data);
        this.resetAuditPackageItem();
      },         500);

      this.getChaseDetailsForAudit();
  }

  get disabled(): boolean {
    return (this.data.auditReady.toLowerCase() !== "yes");
  }

  private getChaseSummaryItems(): void {
    this.chaseDetailStateService.setData({
      chaseId: this.data.chaseId,
    });

    this.chaseService.getSummaryItems(this.data.chaseId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(items => {
        this.chaseService.chaseDetailsChange.next(items);
      });
  }

  resetAuditPackageItem(): void {
    if (this.actionText === "EDIT") {
      this.auditPageEntryService.editPageEntry(this.data.auditPackageItemId);
    }
  }

  private getChaseDetailsForAudit(): void {
    const chaseDetails = [
      new ListItem({
        key: "MeasureID",
        value: this.data.measureCode,
      }),
      new ListItem({
        key: "Member Name",
        value: `${this.data.memberFirstName} ${this.data.memberLastName}`,
      }),
      new ListItem({
        key: "DOB",
        value: this.data.memberDOB,
      }),
      new ListItem({
        key: "Numerator",
        value: this.data.numeratorName,
      }),
      new ListItem({
        key: "Chase ID",
        value: String(this.data.chaseId),
      }),
    ];

    this.auditQueryHeaderService.setChaseDetails(chaseDetails);
  }

}
