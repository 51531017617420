import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { BoundingBox } from "../../../../../../shared/document/document-page-viewer/bounding-box.model";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";

export const mapCatalyticLocations = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CatalyticLocations")
    .forMember("documentPageId", o => o.documentPageId)
    .forMember("eventId", o => o.eventId)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("text", o => o.text)
    .forMember("boxes", o => o.boxes)
    .forMember("boundingBox", o => {
      const isArray = Array.isArray(o.boundingBox) && o.boundingBox.length > 0;
      return isArray ? automapper.mapMany("default", "BoundingBox", o.boundingBox) : [];
    });
};

export class CatalyticLocations {
  documentPageId?: number;
  eventId?: string;
  pageNumber?: number;
  text?: string;
  boxes?;
  boundingBox: BoundingBox[];

  constructor(options: Partial<CatalyticLocations> = {}) {
    this.documentPageId = options.documentPageId;
    this.eventId = StringHelper.clean(options.eventId);
    this.pageNumber = options.pageNumber;
    this.text = StringHelper.clean(options.text);
    this.boxes = options.boxes;
    this.boundingBox = options.boundingBox;
  }
}
