<div class="sso">

    <div class="sso__icon">
      <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-vertical"></app-icon>
    </div>
  
    <div class="sso__form">
      <form [formGroup]="formGroup">
        <form-factory [formGroup]="formGroup" [model]="emailInput"></form-factory>  
        <form-factory [formGroup]="formGroup" [model]="providerInput" (onChange)="onProviderSelect($event.value, null)"></form-factory>
        <div *ngIf="!isSsoConfigured" class="ssoMessage">Sorry, your organization is not enabled for Single Sign-On. Return to Login</div>
        <app-button (onClick)="ssoLogin()" text="CONTINUE"></app-button>  
      </form>
    </div>
    <div class="sso__logout">
        <a href="login" target="_self">Return to Login</a>
      </div>  
  </div>
