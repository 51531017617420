import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export const mapGridView = (automapper: IAutoMapper): void => {
  const convertToObject = (obj: any, name: string): { [key: string]: IGridViewMeta } => {
    obj[name] = { show: true };
    return obj;
  };

  automapper
    .createMap("GridView", "GridView")
    .forMember("gridViewId", o => o.gridViewId)
    .forMember("name", o => o.name)
    .forMember("columns", o => o.columns.reduce(convertToObject, {}))
    .forMember("userId", o => o.userId)
    .forMember("organizationId", o => o.organizationId);
};

interface IGridViewMeta {
  show: boolean;
}

export class GridView {
  gridViewId: number;
  name: string;
  columns: { [key: string]: IGridViewMeta };
  userId: number | null;
  organizationId: number | null;

  constructor(a: Partial<GridView>) {
    this.gridViewId = a.gridViewId;
    this.name = StringHelper.clean(a.name);
    this.columns = a.columns == null ? {} : a.columns;
    this.userId = a.userId;
    this.organizationId = a.organizationId;
  }
}
