import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";

@Injectable({
  providedIn: "root",
})
export class AddressDetailTimelineService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getAddressGdFromPath(): number {
        if (this.route.snapshot.firstChild.firstChild.firstChild.params.AddressGd) {
            return this.route.snapshot.firstChild.firstChild.firstChild.params.AddressGd;
        } else if (this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd) {
            return this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd;
        } else {
            return this.route.snapshot.firstChild.firstChild.firstChild.params.EmrGd;
        }

  }

  getAddressTimelineActivityItems(): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/retrievalTimelineActivity?documentSourceId=${this.getAddressGdFromPath()}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }
}
