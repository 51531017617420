<div class="container-content">
  <app-provider-header [welcomeText]="welcomeText" [supportText]="supportText" [imagePath]="imagePath"></app-provider-header>
  <div class="ui-g ui-fluid">
    <div class="form-container">
      <form [formGroup]="formGroup">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="codeForEmail" (onInput)="keyUpEvent()"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <app-button class="header-button" text="VERIFY" (onClick)="onVerify()"></app-button>
        </div>
      </form>
    </div>
  </div>
</div>
