import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Listbox } from "primeng/listbox";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { CheckboxGroup } from "./checkbox-group.model";

@Component({
  selector: "form-checkbox-group",
  templateUrl: "./checkbox-group.component.html",
  styleUrls: ["./checkbox-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent extends DynamicControlDirective<CheckboxGroup> implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(Listbox, { static: true }) primeListbox: Listbox;
  @Input() showSelectAll = true;
  label = "";
  private sink = new SubSink();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
    this.label = StringHelper.toTitleCase(this.model.label);
  }

  // TODO: Remove when updating primeng to a version that has built in disabled support
  ngAfterViewInit() {
    this.options.forEach(x => {
      if (x.disabled) {
        this.primeListbox.el.nativeElement.querySelector(`[aria-label="${x.text}"]`)?.classList.add("list-items__disabled");
      }
    });
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get optionText(): string {
    return "text";
  }

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get primeAllChecked(): boolean {
    return this.primeListbox != null && this.primeListbox.allChecked;
  }

  get enableToggleButton(): boolean {
    return this.control.enabled && !this.model.isAdmin;
  }

  get toggleButtonText(): string {
    return this.primeAllChecked ? "Deselect All" : "Select All";
  }

  toggleAll(event): void {
    this.primeListbox.toggleAll(event);
  }

  get classes(): string {
    return this.getClasses("checkbox-group");
  }
}
