import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { CollapsiblePanelComponent } from "../panel/collapsible-panel/collapsible-panel.component";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent {

  @ViewChild(CollapsiblePanelComponent, { static: true }) collapsiblePanel: CollapsiblePanelComponent;
  @Input() totalRecords: number;
  @Input() formFilterRequest: FormGroup;
  formFilters: SelectableInput[];
  @Input() isRemovableItem = false;
  @Output() removeFilter = new EventEmitter<FormGroup>();
  @Output() isShowFilterModal = new EventEmitter();

  get filteredFilters(): SelectableInput[] {
    const filtersValues: any = [];
    if (this.formFilterRequest != null) {
      Object.keys(this.formFilterRequest.controls)
        .map(x => {
          const filterToBeAdded = this.formFilterRequest.controls[x].value;
          if (ArrayHelper.isAvailable(filterToBeAdded)) {
            filterToBeAdded.map(y => {
              filtersValues.push({ key: x, value: y.text });
            });
          }
          if (StringHelper.isAvailable(filterToBeAdded) || NumberHelper.isAvailable(filterToBeAdded)) {
            filtersValues.push({ key: x, value: filterToBeAdded });
          }
        });
      this.formFilters = filtersValues
        .reduce((chips, filter) => {
          const { key, value } = filter;
          chips.push(new SelectableInput({ text: key, value }));
          return chips;
        },      []);
    }

    return this.formFilters;
  }

  get hasFilters(): boolean {
    return ArrayHelper.isAvailable(this.filteredFilters);
  }
  get collapseIcon(): string {
    return this.collapsiblePanel.primePanel.collapsed ? "plus-square" : "minus-square";
  }

  @HostBinding("style.display")
  get display(): string {
    return this.hasFilters ? "" : "none";
  }

  delete(event: MouseEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();

    const { text, value } = this.filteredFilters[index];
    if (ArrayHelper.isAvailable(this.formFilters)) {
      const filterToBeDeleted = this.formFilterRequest.controls[text].value;
      if (Array.isArray(filterToBeDeleted)) {
        this.formFilters = this.formFilterRequest.controls[text].value.filter(a => a != null && a.text !== value);
        this.formFilterRequest.controls[text].setValue(this.formFilters);

      } else if (typeof filterToBeDeleted === "string" || typeof filterToBeDeleted === "number") {
        this.formFilterRequest.controls[text].setValue("");
      } else if (typeof filterToBeDeleted === "object" && filterToBeDeleted != null) {
        this.formFilterRequest.controls[text].setValue(null);
      }

      this.removeFilter.emit(this.formFilterRequest);
    }
  }

  modifyQuery(): void {
    this.isShowFilterModal.emit();
  }

  clearAllFilters(): void {
    this.formFilters = [];
    this.formFilterRequest.reset();
    this.removeFilter.emit(this.formFilterRequest);

  }
  toggle(event: MouseEvent): void {
    this.collapsiblePanel.primePanel.toggle(event);
  }

  trackByIndex(index, item) {
    return index;
  }

}
