<div class="hccs--container">
  <h4 class="hcc--header">{{ hasHccs ? "" : "No" }} HCC Information</h4>
  <div *ngFor="let hcc of hccs; trackBy: trackByIndex">
    <div class="hcc--item hcc--item__code">{{ hcc.categoryCode }}</div>
    <div class="hcc--item hcc--item__name">{{ hcc.categoryName }}</div>
    <div class="clearfix"></div>
  </div>
  <h4 class="hcc--header">ICD Information</h4>
  <div>
    {{ icdDescription }}
  </div>
</div>

<form-factory
  *ngIf="hasSelectedDiagnosis"
  class="diagnosis-save-group"
  [formGroup]="form"
  [model]="saveGroup"
  (onChange)="handleChange($event)"></form-factory>

<app-modal 
  class="diagnosis-vrc-mismatch-modal" 
  header="ATTENTION" 
  [(visible)]="isVRCWarningVisible" 
  rejectText="Close">
  <div class="text-center">
    There is a diagnosis VRC mismatch for this claims DOS.<br />
    Please resolve issue prior to chart submission.
  </div>
</app-modal>