export class ProviderSearchRequest {

   serviceProviderId?: number;
   masterProviderId?: number;
   masterDocumentSourceId?: number;
   documentSourceId?: number;
   chaseList?: string;
   providerName?: string;
   sortOrder?: string;
   sortDirection?: string;
   startRecord?: number;
   endRecord?: number;
   serviceProviderList?: string;
   isVerified?: boolean;

   constructor(
    serviceProviderId?: number,
    masterProviderId?: number,
    masterDocumentSourceId?: number,
    documentSourceId?: number,
    chaseList?: string,
    providerName?: string,
    sortOrder?: string,
    sortDirection?: string,
    startRecord?: number,
    endRecord?: number,
    serviceProviderList?: string,
    isVerified?: boolean

  ) {

    this.serviceProviderId = serviceProviderId;
    this.masterProviderId = masterProviderId;
    this.masterDocumentSourceId = masterDocumentSourceId;
    this.documentSourceId = documentSourceId;
    this.chaseList = chaseList;
    this.providerName = providerName;
    this.sortOrder = sortOrder;
    this.sortDirection = sortDirection;
    this.startRecord = startRecord;
    this.endRecord = endRecord;
    this.serviceProviderList = serviceProviderList;
    this.isVerified = isVerified;

  }
}
