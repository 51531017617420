import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ModalComponent } from "../../../../../../shared/panel/modal/modal.component";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ChaseSearchRequest } from "../../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../api/chase-search/chase-search.service";
import { ChaseUnasssignModel } from "../../../../../api/unassign-chase/chase-unassign-model";
import { UnassignChaseService } from "../../../../../api/unassign-chase/unassign-chase.service";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { DirectoryUserRole } from "../../../directory-user-role";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailInfoAssignment } from "./address-detail-info-assignment.model";
import { AddressDetailInfoAssignmentService } from "./address-detail-info-assignment.service";

@Component({
  selector: "retrieval-address-detail-info-assignment",
  templateUrl: "./address-detail-info-assignment.component.html",
  styleUrls: ["./address-detail-info-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoAssignmentComponent extends ModalComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private gridSearchRequest: any;
  private user: UserToken;
  private previousMasterDocumentSourceId = 0;
  addressDetailState: AddressDetailState;
  chases: number[];
  retrievalAssignmentNote: Textarea;
  assignedTo: number;
  formGroup: FormGroup;
  @Input() assignmentType: DocumentSourceType;
  @Output() retrievalTypeChange = new EventEmitter<boolean>();

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private addressDetailInfoAssignmentService: AddressDetailInfoAssignmentService,
    private unassignChaseService: UnassignChaseService,
    private messagingService: MessagingService,
    private chaseSearchService: ChaseSearchService,
    private userService: UserService,
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private router: Router
  ) { super(); }

  ngOnInit() {
    this.initializeForm();
    this.user = this.userService.getUserToken();
    this.assignedTo = (this.user.functionalRoleIds.indexOf(DirectoryUserRole.DocumentIntakeManager) > -1) ? null : this.user.userId;

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;

        if (ArrayHelper.isAvailable(state.chaseGridData)) {
          this.chases = state.chaseGridData.map(a => a.chaseId || a.chaseID);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  updateChaseGrid(): void {
    this.gridSearchRequest = new ChaseSearchRequest(
      null, null, null, null, this.addressDetailState.masterDocumentSourceId, this.assignedTo, null, null, null, null, null,
      null, null, null);

    this.chaseSearchService
      .chaseSearch(this.gridSearchRequest)
      .subscribe(chaseGridData => {
        this.addressDetailStateService.setData({ chaseGridData });
        this.chases = chaseGridData.map(a => a.chaseId || a.chaseID);
      });
  }

  validateNotes() {
    const notes = this.formGroup.value?.retrievalAssignmentNote;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, ""))
      || ((notes?.replace(/ /g, "").length < 4)) || ((notes?.replace(/ /g, "").length > 1000)))) {
      this.formGroup.get(this.retrievalAssignmentNote.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.retrievalAssignmentNote.key).setErrors(null);
    }
  }
  saveAssignNotes(): void {
    this.validateNotes();
    if (this.formGroup.invalid) {
      this.messagingService.showToast("Please fill out the required field.", SeverityType.ERROR);

      return;
    }

    this.addressDetailInfoAssignmentService.saveAssignment(new AddressDetailInfoAssignment({
      addressId: this.addressDetailState.masterDocumentSourceId,
      documentSourceTypeId: this.assignmentType,
      assignmentNote: this.formGroup.get("retrievalAssignmentNote").value,
    })).subscribe(
      data => {
        this.retrievalTypeChange.emit(true);
        this.messagingService.showToast(`Address Reassigned to ${this.assignmentTypeName}.`, SeverityType.SUCCESS);
        this.unassignChasesAtAddressLocation();
        this.updateChaseGrid();
        this.addressDetailStateService.setData({ documentSourceTypeId: this.assignmentType, documentSourceTypeName: this.assignmentTypeName });
        this.fetchTimelineItems();
        this.router.navigate(["retrieval", "addressdetail", this.addressDetailState.masterDocumentSourceId]);
        this.hide();
      },
      err => {
        this.messagingService.showToast(`Failed to reassign Address to ${this.assignmentTypeName}.`, SeverityType.ERROR);
      }
    );
  }

  change(event: boolean): void {
    this.formGroup.reset();
    super.change(event);
  }

  get assignmentTypeName(): string {
    return DocumentSourceType[this.assignmentType];
  }

  private initializeForm(): void {
    this.retrievalAssignmentNote = new Textarea({
      key: "retrievalAssignmentNote",
      label: "Notes",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });

    this.formGroup = this.formService.createFormGroup([this.retrievalAssignmentNote]);
  }

  private unassignChasesAtAddressLocation(): void {
    const functionalRoleIdRetrieval = 5;
    const unasssignModel = new ChaseUnasssignModel({
      chaseList: this.chases,
      functionalRoleId: functionalRoleIdRetrieval,
      masterDocumentSourceId: +this.addressDetailState.masterDocumentSourceId.toString(),
    });

    this.unassignChaseService.unassignChases(unasssignModel).subscribe(
      data => {
        this.messagingService.showToast("Chases(s) have been unassigned.", SeverityType.SUCCESS);
        this.addressDetailStateService.setData({ documentSourceTypeId: this.assignmentType, documentSourceTypeName: this.assignmentTypeName, assignedTo: "" });
      },
      err => {
        this.messagingService.showToast("Error while unassigning Chases(s).", SeverityType.ERROR);
      });
  }

  private fetchTimelineItems(): void {
    this.addressTimelineService
      .get(this.addressDetailState.masterDocumentSourceId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }
}
