import { IAutoMapper } from "../interfaces";

export const mapMemberValidation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberValidation")
    .forMember("isValidInfo", o => automapper.map("default", "DynamicEntityAttribute", o.isValid))
    .forMember("reasonInfo", o => automapper.map("default", "DynamicEntityAttribute", o.reason))
    .forMember("pageNumberInfo", o => automapper.map("default", "DynamicEntityAttribute", o.pageNumber))
    .forMember("dateOfBirth", o => automapper.map("default", "DynamicEntityAttribute", o.dateOfBirth))
    .forMember("dateOfBirthValidationResult", o => o.dateOfBirthValidationResult)
    .forMember("genderFoundValidationResult", o => o.genderFoundValidationResult);
};
