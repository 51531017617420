import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../core/navigation/parameter.service";
import { FormService } from "../../../dynamic-forms/form.service";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ProviderDetailStateService } from "../../provider-detail-state.service";
import { ReviewAuthLetterService } from "../../review-auth-letter/review-auth-letter.service";
import { EmailVerificationService } from "./email-verification.service";

@Component({
  selector: "app-email-verification",
  templateUrl: "./email-verification.component.html",
  styleUrls: ["./email-verification.component.scss"],
  providers: [EmailVerificationService, ReviewAuthLetterService],
})
export class EmailVerificationComponent implements OnInit {

  readonly formGroup: FormGroup;
  codeForEmail: Textbox;
  filePath = "";
  welcomeText = "";
  supportText = "";
  imagePath = "";
  organizationId = "";
  password = "";


  constructor(
    private readonly formService: FormService,
    private router: Router,
    private service: EmailVerificationService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private reviewAuthLetterService: ReviewAuthLetterService,
    private parameterService: ParameterService,
    private providerDetailStateService: ProviderDetailStateService
  ) {

      this.codeForEmail = new Textbox({
      key: "verificationCode",
      label: "Code from Email",
      placeholder: "Code from Email",
      validators: [Validators.maxLength(10), Validators.required],
      errorMessages: {
        maxlength: "Code cannot be more than 10 characters.",
      },
    });

      this.formGroup = this.formService.createFormGroup([this.codeForEmail]);
    }

  ngOnInit() {
        this.organizationId = this.parameterService.getNormal("organizationId", "");
        this.password = this.parameterService.getNormal("password", "");
        this.getHeaderDetails();
        if (StringHelper.isAvailable(this.password)) {

          this.getDecryptedPasswordCode();
        }
    }
  getDecryptedPasswordCode(): void {
      this.service.getDecryptedCode(this.password).subscribe(result => {
        if (result) {

          this.formGroup.controls.verificationCode
          .setValue(result.body);
        }
      });
    }

  onVerify() {
    const verificationCode = this.formGroup.get("verificationCode").value;
    if (verificationCode.toString().length !== 10) {
      this.messagingService.showToast("Invalid verification code !", SeverityType.ERROR);
      return false;
    }
    if (verificationCode) {
      this.service.verifyCode(verificationCode).subscribe(result => {
        if (result) {
            localStorage.setItem("verificationcode", verificationCode);
            localStorage.setItem("orgId", this.organizationId);
            this.reviewAuthLetterService
            .getContent(verificationCode)
            .subscribe(items => {
              if (items.filePath !== "") {
                this.router.navigate([`/provider/reviewauth`]);
              } else {
                this.messagingService.showToast("No Provider Packet available.", SeverityType.INFO);
              }
            });

        } else {
          this.messagingService.showToast("Invalid verification code !", SeverityType.ERROR);
        }
        this.changeDetector.markForCheck();
      });
    } else {
      this.messagingService.showToast("Verification code cannot be empty.", SeverityType.WARN);
    }
    }

  getHeaderDetails() {
      this.service.getEmailHeadings(this.organizationId).subscribe({
          next: result => {
              this.providerDetailStateService.setData(result);
              this.welcomeText = "Please enter the security code from your secure email to access Medical Record Request";
              this.imagePath = result.filter(x => x.attributeCode === "Logo")[0].attributeValue;
              this.changeDetector.markForCheck();
            },
          error: () => this.messagingService.showToast("Error occurred while loading the page.", SeverityType.ERROR),
        });
    }

  keyUpEvent() {
     this.formGroup.controls.verificationCode
        .setValue(this.formGroup.controls.verificationCode.value.replace(/ /g, ""));
    }
}
