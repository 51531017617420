import { IAutoMapper } from "../../../../core/automapper/interfaces";

export const mapAddressSearchResult = (automapper: IAutoMapper): void => {
    automapper
        .createMap("address-search-item", "AddressSearchResult")
        .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
        .forMember("documentSourceTypeName", o => o.documentSourceTypeName)
        .forMember("address", o => o.address)
        .forMember("city", o => o.city)
        .forMember("address1", o => o.address1)
        .forMember("address2", o => o.address2)
        .forMember("addressState", o => o.state)
        .forMember("addressZip", o => o.postalCode)
        .forMember("phone", o => o.phone)
        .forMember("faxNumber", o => o.faxNumber)
        .forMember("contactName", o => o.contactName)
        .forMember("groupName", o => o.groupName)
        .forMember("addressId", o => o.masterDocumentSourceID)
        .forMember("coRetrievalOwner", o => o.coRetrievalOwner)
        .forMember("retrievalTypeName", o => o.retrievalTypeName)
        .forMember("state", o => o.state)
        .forMember("status", o => o.status);
};
export class AddressSearchResult {
    masterDocumentSourceId: number;
    documentSourceTypeName: string;
    address?: string;
    city?: string;
    notes?: string;
    address1?: string;
    address2?: string;
    addressState?: string;
    addressZip?: string;
    contactName?: string;
    groupName?: string;
    addressId?: number;
    phone?: string;
    faxNumber?: string;
    isChaseAssign?: boolean;
    coRetrievalOwner?: string;
    retrievalTypeName?: string;
    state?: string;
    status?: string;

    constructor(options: {
        masterDocumentSourceId: number;
        documentSourceTypeName: string;
        address?: string;
        city?: string;
        notes?: string;
        address1?: string;
        address2?: string;
        addressState?: string;
        addressZip?: string;
        contactName?: string;
        groupName?: string;
        addressId?: number;
        phone?: string;
        faxNumber?: string;
        isChaseAssign?: boolean;
        coRetrievalOwner?: string;
        retrievalTypeName?: string;
        state?: string;
        status?: string;
    }) {
        this.masterDocumentSourceId = options.masterDocumentSourceId;
        this.documentSourceTypeName = options.documentSourceTypeName;
        this.address = options.address;
        this.city = options.city;
        this.notes = options.notes;
        this.address1 = options.address1;
        this.address2 = options.address2;
        this.addressState = options.addressState;
        this.addressZip = options.addressZip;
        this.contactName = options.contactName;
        this.groupName = options.groupName;
        this.addressId = options.addressId;
        this.phone = options.phone;
        this.faxNumber = options.faxNumber;
        this.isChaseAssign = options.isChaseAssign;
        this.coRetrievalOwner = options.coRetrievalOwner;
        this.retrievalTypeName = options.retrievalTypeName;
        this.state = options.state;
        this.status = options.status;
    }
}

