import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ContactHistoryNoteViewerComponent } from "../../../../../shared/contact-history-note/contact-history-note.component";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { InternalPendsDetailAttemptHistoryService } from "./internal-pends-detail-attempt-history.service";

@Component({
  selector: "app-internal-pends-detail-attempt-history",
  templateUrl: "./internal-pends-detail-attempt-history.component.html",
  styleUrls: ["./internal-pends-detail-attempt-history.component.scss"],
  providers: [InternalPendsDetailAttemptHistoryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class InternalPendsDetailAttemptHistoryComponent implements OnInit {
  gridConfigurationModel = new GridConfiguration();
  rowDataModel: any[] = [];
  contactHistoryGridSelection: any[];
  private pageSize = 10;
  @ViewChild("noteColumn", { static: true }) noteColumn: TemplateRef<ContactHistoryNoteViewerComponent>;

  constructor(
    private service: InternalPendsDetailAttemptHistoryService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "userName", header: "User Name" }),
      new GridColumnDefinition({ field: "callStartTime", header: "Contact Date" }),
      new GridColumnDefinition({ field: "contactDetail", header: "Contact Detail" }),
      new GridColumnDefinition({ field: "callResult", header: "Result" }),
      new GridColumnDefinition({ field: "contactMethodType", header: "Contact Type" }),
      new GridColumnDefinition({ field: "callDuration", header: "Duration" }),
      new GridColumnDefinition({ field: "callNoteDescription", header: "Notes", isSortableColumn: false }),
    ];

    this.gridConfigurationModel.pageSize = this.pageSize;
    this.gridConfigurationModel.selectionMode = "";
    this.gridConfigurationModel.showActionColumn = false;

    this.service
      .getAttemptHistoryList()
      .subscribe(this.assignAndNotify);
  }

  assignAndNotify = <T>(data: T[]): void => {
    this.rowDataModel = data as any;
    this.changeDetector.markForCheck();
  }

}
