import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { CreatePendService } from "../../../../../../shared/pend/create-pend.service";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { CoverLetterTemplateService } from "../../../../retrieval/cover-letter-template/cover-letter-template.service";
import { RetrievalPageService } from "../../../../retrieval/retrieval-page/retrieval-page.service";
import { ChaseQueryService } from "../../../chase-query/chase-query.service";
import { BulkChaseValidation } from "../../bulk-chase-validation.model";
import { BulkOutreachService } from "../bulk-outreach.service";
import { BulkOutReachQueryResult } from "./bulk-outreach-query-result.model";
import { BulkOutReachRequest } from "./bulk-outreach-request.model";

@Component({
  selector: "app-bulk-outreach-new-bulk-action",
  templateUrl: "./bulk-outreach-new-bulk-action.component.html",
  styleUrls: ["./bulk-outreach-new-bulk-action.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOutreachNewBulkActionComponent implements OnInit {
  actionTypeInput: Dropdown;
  from: Textbox;
  faxNumberInput: Textbox;
  projectsInput: CheckboxGroup;
  pendCodesInput: CheckboxGroup;
  chaseStatusInput: CheckboxGroup;
  chaseIds: Textarea;
  formOptions: Radiobutton;
  formGroup: FormGroup;
  dataChaseIds: FormGroup;
  showQueryResults: boolean;
  bulkOutReachQueryResult: BulkOutReachQueryResult;
  showInputs = true;
  bulkOutReachRequest: BulkOutReachRequest;
  coverTemplateFromGroup: FormGroup;
  coverLetterTemplate: Dropdown;
  outReachRequest: BulkOutReachRequest;
  formGroupOption: FormGroup;
  selectedChases: BulkChaseValidation[] = [];
  setChaseIds: number[];
  chaseGridDataAll: BulkChaseValidation[] = [];
  form: FormGroup;
  isFiltersVisible: boolean;
  projectFilters: SelectableInput[];
  pendCodeFilters: SelectableInput[];
  chaseStatusFilters: SelectableInput[];
  chaseFilters: SelectableInput[];
  queryHeaderText = "QUERY";
  validationIssuesCount: number;
  isDisableChaseBox: boolean;
  invalidChases: BulkChaseValidation[] = [];
  chaseCount: number;
  fromFaxNumber: string;
  senderName: string;
  projectCount: number;
  masterDocumentSourceIdCount: number;
  projectList: number[];

  get isActionTypeFax(): boolean {
    return this.actionType === "Fax";
  }

  get actionType(): string {
    return this.formGroup.get(this.actionTypeInput.key).value;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formService: FormService,
    private router: Router,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly automapper: AutomapperService,
    private readonly createPendService: CreatePendService,
    private bulkOutreachService: BulkOutreachService,
    private userService: UserService,
    private chaseQueryService: ChaseQueryService,
    protected readonly messagingService: MessagingService,
    private readonly templateService: CoverLetterTemplateService
  ) {
    this.actionTypeInput = new Dropdown({
      key: "actionType",
      label: "ACTION TYPE",
      placeholder: "Select Action Type",
      value: "Fax",
      options: [
        new SelectableInput({ text: "Fax", value: "Fax" }),
        new SelectableInput({ text: "Mail", value: "Mail" }),
        new SelectableInput({ text: "External", value: "External" }),
      ],
      validators: [Validators.required],
      errorMessages: {
        required: "Please select action type.",
      },
    });
    this.from = new Textbox({
      key: "from",
      label: "WHO IS SENDING THE FAX?",
      value: this.userService.getUserToken().username,
    });
    this.faxNumberInput = new Textbox({
      key: "faxNumber",
      label: "WHAT NUMBER IS THE FAX COMING FROM?",
      placeholder: "Fax Number",
      validators: [Validators.maxLength(18), Validators.pattern(RegExHelper.wholeNumber)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.projectsInput = new CheckboxGroup({
      key: "PROJECT",
      label: "SELECT PROJECTS(S)",
      validators: [Validators.required],
      appendTo: "body",
    });

    this.pendCodesInput = new CheckboxGroup({
      key: "PEND",
      label: "SELECT PEND(S)",
      appendTo: "body",
    });

    this.chaseStatusInput = new CheckboxGroup({
      key: "STATUS",
      label: "STATUS",
      validators: [Validators.required],
      appendTo: "body",
    });

    this.chaseIds = new Textarea({
      key: "chaseIds",
      label: "CHASE IDs",
      placeholder: "Enter Chase IDs comma separated",
      rows: 10,
      resize: Resize.NONE,
      validators: [Validators.required, Validators.pattern("^[0-9 \n]+(,[0-9 \n]+)*$")],
      errorMessages: {
        required: "Associated Chases is required.",
        pattern: "Chases can be only comma separated numeric Ids.",
      },
    });

    this.formOptions = new Radiobutton({
      key: "formItem",
      label: "DO YOU HAVE A LIST OF CHASE IDs?",
      options: [
        new SelectableInput({ text: "Yes", value: 1 }),
        new SelectableInput({ text: "No", value: 2 }),
      ],
    });

    this.formGroup = this.formService.createFormGroup([
      this.actionTypeInput,
      this.from,
      this.faxNumberInput,
    ]);

    this.dataChaseIds = this.formService.createFormGroup([
      this.chaseIds,
    ]);

    this.form = this.formService.createFormGroup([
      this.projectsInput,
      this.pendCodesInput,
      this.chaseStatusInput,
    ]);
  }

  updateActionTypeLabel(): void {
    this.from.label = `WHO IS SENDING THE ${this.actionType.toUpperCase()}?`;

    this.formService.updateDom.next();
  }

  ngOnInit() {
    this.getProjectInputs();
    this.getPendCodes();
    this.getReportingStatuses();
    this.getCoverLetterTemplateList();
    this.createItemizeOptionForm();
    this.coverLetterTemplate = new Dropdown({
      key: "coverLetterTemplate",
      label: "COVER LETTER TEMPLATE",
      placeholder: "Select Cover Letter Template",
      validators: [Validators.required],
      errorMessages: {
        required: "Cover letter template is required.",
      },
    });

    this.coverTemplateFromGroup = this.formService.createFormGroup(
      [this.coverLetterTemplate]
    );
  }

  getProjectInputs(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  getPendCodes() {
    this.createPendService
      .getPendDropdown(false)
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({
          text: item.displayName.split("-")[0],
          value: item.pendTypeId,
        }));
      }))
      .subscribe(options => {
        this.pendCodesInput = new CheckboxGroup({ ...this.pendCodesInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  runQuery() {
    if (this.formGroup.invalid) {
      this.markAllAsTouched();
    } else {
      this.submitQueryRequest();
      this.changeDetector.markForCheck();
    }
  }

  submitQueryRequest(): void {
    const projects = [];
    const projectsIds = this.form.get("PROJECT").value;
    projectsIds.forEach(item => {
      projects.push(item.value);
    });
    const pendCodes = [];
    if (this.form.get("PEND").value) {
      const pendIds = this.form.get("PEND").value;
      pendIds.forEach(item => {
        pendCodes.push(item.value);
      });
    }
    const chaseStatus = [];
    if (this.form.get("STATUS").value) {
      const status = this.form.get("STATUS").value;
      status.forEach(item => {
        chaseStatus.push(item.value);
      });
    }

    const bulkOutreachParameterValues = {
      fromFaxNumber: this.formGroup.get("faxNumber").value,
      senderName: this.formGroup.get("from").value,
      pendTypeIds: pendCodes,
      projectIds: projects,
      reportingStatus: chaseStatus,
    };
    this.bulkOutReachRequest = new BulkOutReachRequest({
      bulkOutreachType: this.formGroup.get("actionType").value,
      bulkOutreachParameter: bulkOutreachParameterValues,
      coverLetterTemplateName: "",
      chaseIds: null,
    });


    this.bulkOutreachService.previewBulkOutreachRequest(this.bulkOutReachRequest)
      .subscribe(result => {
        this.bulkOutReachQueryResult = result;
        this.showQueryResults = true;
        this.changeDetector.markForCheck();
      });
  }

  resetValues(): void {
    this.resetFormGroup();
    this.resetControls();
    this.updateActionTypeLabel();
  }

  resetFormGroup(): void {
    this.formGroup.reset();
    this.formGroup.patchValue(
      {
        from: this.userService.getUserToken().username,
        pends: 2,
        actionType: "Fax",
      });
  }

  resetControls(): void {
    this.form.reset();
    this.dataChaseIds.reset();
    this.chaseGridDataAll = [];
    this.changeDetector.markForCheck();
    this.showQueryResults = false;
    this.isDisableChaseBox = false;
    this.validationIssuesCount = 0;
    this.queryHeaderText = "QUERY";
    this.dataChaseIds.enable();
    this.coverTemplateFromGroup.reset();
    this.projectFilters = [];
    this.chaseStatusFilters = [];
    this.pendCodeFilters = [];
  }

  submitBulkFaxRequest() {

    if (!this.formGroup.invalid) {
      if (this.coverTemplateFromGroup.get("coverLetterTemplate").value == null) {
        this.messagingService.showToast("Please select cover letter template.", SeverityType.WARN);
        return;
      } else if (!this.hasChaseIds) {
        this.outReachRequest = new BulkOutReachRequest({
          bulkOutreachType: this.bulkOutReachRequest.bulkOutreachType,
          bulkOutreachParameter: this.bulkOutReachRequest.bulkOutreachParameter,
          coverLetterTemplateName: this.coverTemplateFromGroup.get("coverLetterTemplate").value,
          chaseIds: null,
        });
        this.bulkOutreachService.createBulkOutreachRequest(this.outReachRequest)
          .subscribe(() => {
            this.showInputs = false;
            this.changeDetector.markForCheck();
          });
      } else {
        this.submitBulkFaxRequestWithChases();
      }
    } else {
      this.formService.markAllAsTouched(this.formGroup);
      this.changeDetector.markForCheck();
    }

  }
  submitBulkFaxRequestWithChases() {
    const bulkOutreachParameterValues = {
      fromFaxNumber: this.formGroup.get("faxNumber").value,
      senderName: this.formGroup.get("from").value,
      pendTypeIds: null,
      projectIds: this.projectList,
      reportingStatus: null,
    };

    this.outReachRequest = new BulkOutReachRequest({
      bulkOutreachType: this.formGroup.get("actionType").value,
      bulkOutreachParameter: bulkOutreachParameterValues,
      coverLetterTemplateName: this.coverTemplateFromGroup.get("coverLetterTemplate").value,
      chaseIds: this.setChaseIds,
    });
    this.bulkOutreachService.createBulkOutreachRequest(this.outReachRequest)
      .subscribe(() => {
        this.showInputs = false;
        this.changeDetector.markForCheck();
      });

  }

  newBulkAction() {
    this.showInputs = true;
    this.showQueryResults = false;
    this.resetValues();
    this.formGroupOption.reset();
  }
  pendingActions() {
    this.router.navigate(["/bulkactions/bulkoutreach/pendingActions"]);
  }
  viewArchive() {
    this.router.navigate(["/bulkactions/bulkoutreach/archive"]);
  }

  protected markAllAsTouched(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  getReportingStatuses(): void {
    this.chaseQueryService
      .getReportingStatusesList(true)
      .pipe(map(this.automapper.curryMany("ReportingStatusModel", "SelectableInput")))
      .subscribe(result => {
        this.chaseStatusInput = new CheckboxGroup({ ...this.chaseStatusInput, options: result } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getCoverLetterTemplateList(): void {
    this.templateService.getCoverLetterTemplate()
      .subscribe(templatelist => {
        this.coverLetterTemplate = new Dropdown({ ...this.coverLetterTemplate, options: templatelist } as any);
        this.changeDetector.markForCheck();
      });
  }


  private createItemizeOptionForm(): void {
    this.formGroupOption = this.formService.createFormGroup([this.formOptions]);
  }

  get showControl(): boolean {
    return NumberHelper.isGreaterThan(
      this.formGroupOption.get("formItem").value, 0);
  }

  get showChaseBox(): boolean {
    return this.formGroupOption.get("formItem").value === 1;
  }

  get getSelectedChases(): number[] {
    if (this.dataChaseIds.get(this.chaseIds.key) !== null) {

      const chaseIds = this.dataChaseIds.get(this.chaseIds.key).value.toString();
      const withoutLinesBreaks = chaseIds.replace(/(\r\n|\n|\r)/gm, ",");
      const withoutSpace = withoutLinesBreaks.replace(/\s+/g, "").trim();
      const withoutLastComma = withoutSpace.replace(/,\s*$/, "");

      const csvValues = withoutLastComma.toString().split(",")
        .filter(c => StringHelper.isAvailable(c) && NumberHelper.isGreaterThan(Number(c), 0))
        .toString();

      this.dataChaseIds.controls.chaseIds.setValue(csvValues);
      return csvValues.split(",")
        .filter(c => StringHelper.isAvailable(c) && NumberHelper.isGreaterThan(Number(c), 0))
        .map(Number);
    }
    return [];
  }

  onValidate(): void {
    if (!this.formGroup.invalid) {
      this.validateChases();
    } else {
      this.formService.markAllAsTouched(this.formGroup);
      this.changeDetector.markForCheck();
    }
  }

  validateChases(): void {
    if (ArrayHelper.isAvailable(this.getSelectedChases)) {
      this.bulkOutreachService
        .validateChasesIds(this.getSelectedChases)
        .subscribe(result => {
          if (ArrayHelper.isAvailable(result)) {
            this.dataChaseIds.disable();
            this.isDisableChaseBox = true;
            this.queryHeaderText = "VALIDATION";
            this.chaseGridDataAll = result;
            this.getChaseGridData();
            this.validationIssuesCount = this.chaseGridDataAll.filter(chase => chase.validationStatus === "Fail").length;
            this.invalidChases = this.chaseGridDataAll.filter(chase => chase.validationStatus === "Fail");
            this.selectedChases = this.chaseGridDataAll.filter(chase => chase.validationStatus === "Success");
            const selectedProject = this.selectedChases.map(x => x.projectId);
            this.projectCount = selectedProject.filter((n, i) => selectedProject.indexOf(n) === i).length;
            const selectedAID = this.selectedChases.map(x => x.masterDocumentSourceId);
            this.masterDocumentSourceIdCount = selectedAID.filter((n, i) => selectedAID.indexOf(n) === i).length;
            this.projectList = selectedProject.filter((n, i) => selectedProject.indexOf(n) === i);
            this.chaseCount = this.chaseGridDataAll.length;
            this.fromFaxNumber = this.formGroup.get("faxNumber").value;
            this.senderName = this.formGroup.get("from").value;
            this.showQueryResults = true;
            this.changeDetector.markForCheck();
          }
        }
        );
    }
  }

  onEdit(): void {
    this.dataChaseIds.enable();
    this.showQueryResults = false;
    this.isDisableChaseBox = false;
  }

  get isShowValidationIssues(): boolean {
    return NumberHelper.isGreaterThan(this.validationIssuesCount, 0);
  }

  getChaseGridData(): void {
    this.setChaseIds = [];
    this.chaseGridDataAll.forEach(x => {
      if (x.validationStatus !== "Success") {
        x.disabled = true;
      } else {
        this.setChaseIds.push(x.chaseId);
      }
    });
  }

  onSelectionChange(event): void {
    this.setChaseIds = [];
    this.selectedChases = [];
    for (const val of event) {
      if (val.chaseId !== 0) {
        this.setChaseIds.push(val.chaseId);
        this.selectedChases.push(val);
      }

    }
  }

  get isValidChaseIds(): boolean {
    return this.dataChaseIds.get(this.chaseIds.key).valid;
  }

  get hasChaseIds(): boolean {
    return this.dataChaseIds.get(this.chaseIds.key).value;
  }

  get checkFaxNumber(): boolean {
    return this.formGroup.invalid;
  }

  validateChaseIds(val): void {
    if (val) {
      this.chaseGridDataAll = [];
    }

  }

  showFilters(): void {
    this.isFiltersVisible = true;
  }

  applyFilters(): void {
    this.isFiltersVisible = false;
    const projectValue = this.form.controls.PROJECT.value;
    this.projectFilters = this.getChip(projectValue, "PROJECT");
    const statusValue = this.form.controls.STATUS.value;
    this.chaseStatusFilters = this.getChip(statusValue, "STATUS");
    const pendCodeValue = this.form.controls.PEND.value;
    this.pendCodeFilters = this.getChip(pendCodeValue, "PEND");
  }

  resetAllFilters(): void {
    this.form.reset();
    this.showQueryResults = false;
  }

  get projectsFilters(): SelectableInput[] {
    return this.projectFilters;
  }

  get statusFilters(): SelectableInput[] {
    return this.chaseStatusFilters;
  }

  get pendCodesFilters(): SelectableInput[] {
    return this.pendCodeFilters;
  }

  getChip(formValue: any, type: string): SelectableInput[] {
    const filtersValues: any = [];
    if (this.form != null) {
      Object.keys(this.form.controls)
        .map(x => {
          if (ArrayHelper.isAvailable(formValue) && x === type) {
            formValue.map(y => {
              filtersValues.push({ key: type, value: y.text });
            });
          }

        });
      this.chaseFilters = filtersValues
        .filter(filter => StringHelper.isAvailable(filter.value) || ArrayHelper.isAvailable(filter.value))
        .reduce((chips, filter) => {
          const { key, value } = filter;
          chips.push(new SelectableInput({ text: key, value }));
          return chips;
        },      []);
    }
    return this.chaseFilters;
  }

  get hasProjectFilters(): boolean {
    return ArrayHelper.isAvailable(this.projectsFilters);
  }

  get hasStatusFilters(): boolean {
    return ArrayHelper.isAvailable(this.statusFilters);
  }

  get hasPendCodesFilters(): boolean {
    return ArrayHelper.isAvailable(this.pendCodesFilters);
  }

  delete(event: MouseEvent, index: number, filterType: string): void {
    event.stopPropagation();
    event.preventDefault();
    switch (filterType) {
      case "project":
        const deletedProjectFilter = this.projectsFilters[index].value;
        const projectFilters = this.form.controls.PROJECT.value.filter(x => x.text !== deletedProjectFilter);
        this.form.controls.PROJECT.setValue(projectFilters);
        break;

      case "status":
        const deletedStatusFilter = this.statusFilters[index].value;
        const statusFilters = this.form.controls.STATUS.value.filter(x => x.text !== deletedStatusFilter);
        this.form.controls.STATUS.setValue(statusFilters);
        break;

      case "pend":
        const deletedPendFilter = this.pendCodesFilters[index].value;
        const pendFilters = this.form.controls.PEND.value.filter(x => x.text !== deletedPendFilter);
        this.form.controls.PEND.setValue(pendFilters);
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  trackByIndex(index, item) {
    return index;
  }

  downloadValidationIssues(): void {
    const userId = this.userService.getUserToken().userId;
    this.bulkOutreachService.downloadInvalidChases(this.invalidChases.map(x => x.chaseId).toString(), userId);
  }

  onChangeFormOptionsInput(): void {
    this.resetControls();
  }

  get isFormInvalid(): boolean {
    return this.hasProjectFilters && this.hasStatusFilters;
  }
}
