import { IAutoMapper } from "../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { RiskEntity } from "../../modules/member/chase-detail/chase-detail-chart/risk/risk-entity.model";
import { DynamicEntityAttribute } from "./dynamic-entity-attribute.model";

export const mapDynamicEntity = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DynamicEntity")
    .forMember("id", o => o.id)
    .forMember("chaseId", o => o.chaseId)
    .forMember("entityId", o => o.entityId)
    .forMember("entityTypeId", o => o.entityTypeId)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("attributes", o => o.attributes)
    .forMember("diagnoses", o => ArrayHelper.isAvailable(o.diagnoses) ? automapper.mapMany("default", "RiskEntity", o.diagnoses) : []);
};

export interface IDynamicEntityOptions {
  id?: string;
  chaseId?: number;
  entityId?: number;
  entityTypeId?: number;
  entityTypeName?: string;
  attributes?: DynamicEntityAttribute[];
  diagnoses?: RiskEntity[];
}

export class DynamicEntity {
  id: string; // NOTE: Front End ID
  chaseId: number;
  entityId: number;
  entityTypeId: number;
  entityTypeName: string;
  attributes: DynamicEntityAttribute[];
  diagnoses?: RiskEntity[];


  constructor(options: IDynamicEntityOptions = {}) {
    this.id = StringHelper.clean(options.id, StringHelper.createId());
    this.chaseId = options.chaseId;
    this.entityId = options.entityId;
    this.entityTypeId = options.entityTypeId;
    this.entityTypeName = options.entityTypeName;
    this.attributes = ArrayHelper.clean(options.attributes);
    this.diagnoses = ArrayHelper.clean(options.diagnoses);
  }
}
