import { IAutoMapper } from "../../../core/automapper/interfaces";
import { OcrWord } from "./ocr-word.model";

export const mapOcrMatch = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "OcrMatch")
    .forMember("fileKey", o => o.fileKey)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("ocrWords", o => automapper.mapMany("default", "OcrWord", o.ocrWords));
};

export class OcrMatch {
  readonly fileKey: string;
  readonly pageNumber: number;
  readonly ocrWords: OcrWord[];

  constructor(options: OcrMatch) {
    this.fileKey = options.fileKey;
    this.pageNumber = options.pageNumber;
    this.ocrWords = options.ocrWords;
  }
}

