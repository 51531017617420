import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { ActionButton } from "../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../zdevcontrols/dev-controller/dev-controller.service";
import { GridPipeName } from "../../grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../grid/models/grid-configuration.model";
import { DocumentRequest } from "../document-request.model";
import { DocumentRequestService } from "../document-request.service";

@Component({
  selector: "app-document-request-grid",
  templateUrl: "./document-request-grid.component.html",
  styleUrls: ["./document-request-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentRequestGridComponent implements OnInit {
  private pAddressId: number;
  @Input()
  get addressId(): number {
    return this.pAddressId;
  }
  set addressId(value: number) {
    this.pAddressId = NumberHelper.isGreaterThan(value, 0) ? value : null;
    this.getDocumentRequests();
  }

  data: DocumentRequest[] = [];
  configuration: GridConfiguration;


  constructor(
    private readonly devService: DevControllerService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentRequestService: DocumentRequestService
  ) { }

  ngOnInit() {
    this.devService.push([new ActionButton({ name: "Refresh Document Request Grid", action: this.getDocumentRequests })]);
    this.createGrid();
  }


  private getDocumentRequests = (): void => {
    if (NumberHelper.isAvailable(this.addressId)) {
      const documentRequestModel = new DocumentRequest({masterDocumentSourceId: this.addressId});
      this.documentRequestService.get(documentRequestModel).subscribe(data => {
        this.data = data;
        this.changeDetector.markForCheck();
      });
    }
  }

  private createGrid(): void {
    this.configuration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "documentRequestId", header: "Request ID", width: "120px" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", width: "120px" }),
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", width: "120px" }),
        new GridColumnDefinition({ field: "documentTypeName", header: "Type", width: "150px" }),
        new GridColumnDefinition({ field: "statusName", header: "Status", width: "100px" }),
        new GridColumnDefinition({ field: "note", header: "Note" }),
        new GridColumnDefinition({ field: "createdByName", header: "Created By", width: "150px" }),
        new GridColumnDefinition({ field: "createdDate", header: "Created", pipeName: GridPipeName.Date, width: "120px" }),
      ],
      pageSize: 5,
      showActionColumn: false, // TODO: Remove when ready.
    });
  }
}
