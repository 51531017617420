import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-result-modal",
  templateUrl: "./result-modal.component.html",
  styleUrls: ["./result-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultModalComponent extends ModalComponent { }
