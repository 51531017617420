import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class ExemptService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient
  ) { }


  save(data: any): Observable<null> {
    const url = `${this.baseApiUrl}measurecompliance/numerator/save`;
    return this.http.post(url, data) as Observable<null>;
  }
}
