<div class="member-dob-gen">
  <form-factory [formGroup]="form" [model]="memberDobReasonSaveGroup"
    (onChange)="handleChange($event); showMemberDobInput($event)">
  </form-factory>
</div>
<div *ngIf="showMemberDob" class="member-dob-gen">
  <member-medical-record-dob [configuration]="configuration" [chaseId]="chaseId"
    (onChange)="memberValidationSubmit($event)">
  </member-medical-record-dob>
</div>
<div class="member-dob-gen">
  <form-factory [formGroup]="form" [model]="memberGenderReasonSaveGroup" (onChange)="handleChange($event)">
  </form-factory>
</div>