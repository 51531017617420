<div class="member-dob-gen mb-15">

  <div class="member-dob-gen__dobMissing">
    <p class="member-dob-gen__title">DOB is Present/Valid?</p>
    <app-button [ngClass]="yesBtnClass"  [disabled]="!isMemberPresent" classes="small" [text]="textYes" (onClick)="onMemberDobReasonSelection('yes')"></app-button>
  <app-button [ngClass]="noBtnClass"  [disabled]="!isMemberPresent" classes="small" [text]="textDobMissing" (onClick)="onMemberDobReasonSelection('dobMissing')"></app-button>
  <app-button [ngClass]="noBtnClass2"  [disabled]="!isMemberPresent" classes="small" [text]="textDobInCorrect" (onClick)="onMemberDobReasonSelection('dobInCorrect')"></app-button> 

  </div>
  <div *ngIf="showMemberDob" class="member-dob-gen__noDob">
    <member-medical-record-dob [configuration]="configuration" [chaseId]="chaseId"
      (onChange)="memberValidationSubmit($event)">
    </member-medical-record-dob>
  </div>

</div>

<div class="member-dob-gen mb-0">

  <div>
    <p class="member-dob-gen__title" >Gender is Present</p>
    <app-button [ngClass]="yesGenderClass" [disabled]="!isMemberPresent" classes="small" [text]="textYesGenderPresent" (onClick)="onMemberGenderReasonSelection('yes')"></app-button>
    <app-button [ngClass]="noGenderClass" [disabled]="!isMemberPresent" classes="small" [text]="textNoGenderPresent" (onClick)="onMemberGenderReasonSelection('no')"></app-button>
  </div>
</div>
<div class="margin-next-btn">
  <ng-container *ngIf="isNextBtnEnabled && isMemberPresent">
    <app-button class="nextBtn" classes="small" [text]="textNext" [disabled]="!isMemberPresent" (onClick)="moveToNextTab()"></app-button> 
  </ng-container>  
</div>