import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { CallFlowStateService } from "../../../call-flow/call-flow-state.service";
import { Contact } from "../../../contact.model";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailInfoEdit } from "../address-detail-info-edit/address-detail-info-edit.model";
import { AddressDetailInfoEditService } from "../address-detail-info-edit/address-detail-info-edit.service";
import { AddressDetailInfoContactService } from "./address-detail-info-contact.service";

@Component({
  selector: "app-address-detail-info-contact",
  templateUrl: "./address-detail-info-contact.component.html",
  styleUrls: ["./address-detail-info-contact.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoContactComponent implements OnInit {
  @Output() onSelected = new EventEmitter<Contact>();

  contactsInput: Dropdown;
  validInput: Checkbox;
  formGroup: FormGroup;
  isPrimaryContactModalVisible = false;
  isCallInProgress: boolean;
  addressDetailState: AddressDetailState;

  private sink = new SubSink();
  private selectedContact: Contact;
  private contacts: Contact[];
  private masterDocumentSourceId: number;

  constructor(
    private readonly formService: FormService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly addressDetailInfoContactService: AddressDetailInfoContactService,
    private addressDetailInfoEditService: AddressDetailInfoEditService,
    private messagingService: MessagingService,
    private callFlowStateService: CallFlowStateService) { }

  get isPrimaryContact(): boolean {
    return this.selectedContact?.isPrimaryContact;
  }

  get hasContacts(): boolean {
    return ArrayHelper.isAvailable(this.addressDetailState.contacts);
  }

  get hasOnlyOneContact(): boolean {
      return this.hasContacts && this.addressDetailState.contacts.length === 1;
  }

  get shouldDisplayContactDropdown(): boolean {
      return this.hasContacts && !this.hasOnlyOneContact;
  }

  ngOnInit(): void {
    this.initializeForm();

    this.sink.add(
      this.addressDetailStateService.state
        .pipe(
          filter(state => state.contacts !== this.contacts),
          map(state => {
            this.addressDetailState = state;
            this.contacts = state.contacts;

            this.formGroup.get(this.validInput.key).setValue(state.isContactVerified);
            this.masterDocumentSourceId = state.masterDocumentSourceId;
            this.contactsInput.options = this.buildContactList();
          }))
        .subscribe(_ => this.selectDefaultContact()),
      this.callFlowStateService.state$.subscribe(isCallInProgressState => {
          this.isCallInProgress = isCallInProgressState;
      })
    );
  }

  onSelectContact($event: any): void {
    // if (this.isCallInProgress) {
    //  this.messagingService.showToast("Call in progress.", SeverityType.WARN);
    //  this.formGroup.get(this.contactsInput.key).setValue(this.selectedContact);
    //  return;
    // }
    this.selectedContact = this.contacts.find(contact => contact.id === $event.value);
    this.selectContact(this.selectedContact);
    this.addressDetailStateService.setData({contact: this.selectedContact});
  }

  onSetAsPrimaryContact(): void {
    this.isPrimaryContactModalVisible = false;
    this.addressDetailInfoContactService.setAsPrimaryContact(this.masterDocumentSourceId, this.selectedContact);
    this.formGroup.get(this.validInput.key).setValue(false);
    this.addressDetailStateService.setData({isContactVerified: false});
  }

  showConfirmationModal(): void {
    if (this.contacts.some(contact => contact.isPrimaryContact)) {
      this.isPrimaryContactModalVisible = true;
      return;
    }
    this.onSetAsPrimaryContact();
  }

  private initializeForm(): void {
    this.contactsInput = new Dropdown({
      key: "contactsInput",
    });
    this.validInput = new Checkbox({
      key:  "validInput",
      label: "Is Valid",
    });

    this.formGroup = this.formService.createFormGroup([
      this.contactsInput,
      this.validInput,
    ]);
  }

  private buildContactList(): SelectableInput[] {
    return this.contacts
      .map(contact => new SelectableInput(
        {
          text: `${contact.contactName} ${this.isPrimaryContactLabel(contact.isPrimaryContact)}`,
          value: contact.id,
        }));
  }

  private selectDefaultContact(): void {
    this.selectedContact = this.contacts.find(contact => contact.isPrimaryContact) || this.contacts[0];
    this.formGroup.controls.contactsInput.setValue(this.selectedContact?.id);
    this.selectContact(this.selectedContact);
  }

  private selectContact(contact: Contact): void {
    this.onSelected.emit(contact);
    this.addressDetailInfoContactService.setAddressContact(contact);
  }

  private isPrimaryContactLabel(isPrimaryContact: boolean): string {
    return isPrimaryContact ? "(PRIMARY)" : "";
  }

  saveContactValid(): void {

    if (!StringHelper.isAvailable(StringHelper.removeNonAlphaNumericCharacters(this.addressDetailState.contact.contactPhone)) ||
        (!StringHelper.isAvailable(StringHelper.removeNonAlphaNumericCharacters(this.addressDetailState.contact.contactFax)) &&
        !StringHelper.isAvailable(this.addressDetailState.contact.contactEmail))) {
          this.messagingService.showToast("Phone number and (fax or email) has to be enter before validating the primary contact.", SeverityType.INFO);
          this.formGroup.get(this.validInput.key).setValue(this.addressDetailState.isContactVerified);
          return;
    }

    const addressDetailInfoEdit = new AddressDetailInfoEdit();
    addressDetailInfoEdit.retrievalLocationId = this.addressDetailState.masterDocumentSourceId;
    addressDetailInfoEdit.email = this.addressDetailState.contact.contactEmail;
    addressDetailInfoEdit.phone = StringHelper.removeNonAlphaNumericCharacters(this.addressDetailState.contact.contactPhone);
    addressDetailInfoEdit.faxNumber = StringHelper.removeNonAlphaNumericCharacters(this.addressDetailState.contact.contactFax);
    addressDetailInfoEdit.contactName = this.addressDetailState.contact.contactName;
    addressDetailInfoEdit.address1 = this.addressDetailState.address1;
    addressDetailInfoEdit.address2 = this.addressDetailState.address2;
    addressDetailInfoEdit.addressCity = this.addressDetailState.city;
    addressDetailInfoEdit.addressState = this.addressDetailState.state;
    addressDetailInfoEdit.addressZip = this.addressDetailState.postalCode;
    addressDetailInfoEdit.groupName = this.addressDetailState.groupName;
    addressDetailInfoEdit.isContactVerified = this.formGroup.get(this.validInput.key).value;
    addressDetailInfoEdit.weeklyContactMethodTypeId = this.addressDetailState.weeklyContactMethodTypeId;

    this.addressDetailInfoEditService.updateAddressDetails(addressDetailInfoEdit).subscribe(() => {

        this.addressDetailStateService.setData({
          isContactVerified: addressDetailInfoEdit.isContactVerified,
          masterDocumentSourceVerifiedDate: new Date(),
        });
    });

  }
}
