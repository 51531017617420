import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { ProviderDropdown } from "../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";

@Component({
  selector: "member-service-provider",
  templateUrl: "./service-provider.component.html",
  styleUrls: ["./service-provider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceProviderComponent implements OnInit, OnDestroy {
  private sink = new SubSink();

  @Input() chaseId: number;
  @Input() set enabled(value: boolean) {
    this.setDisabled(value);
  }
  @Output() onChange = new EventEmitter<DynamicFormEvent>(true);
  form: FormGroup;
  providerSaveGroup: SaveGroup;
  providerInput: ProviderDropdown;
  providerdropdownInput: Dropdown;
  chaseDetailState: ChaseDetailState;

  constructor(
    private automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly formService: FormService
  ) { }

  ngOnInit(): void {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        if (ArrayHelper.isAvailable(this.chaseDetailState.providers)) {
          this.getProviderDetails();
        }
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private initializeForm(): void {
    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
    });

    this.form = this.formService.createFormGroup([this.providerInput]);
  }

  private getProviderDetails(): void {
    this.providerInput.options = this.chaseDetailState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    this.setSaveInfo();
    this.changeDetector.markForCheck();
  }

  private setSaveInfo(): void {
    const options = JSON.stringify(this.providerInput.options);
    const saveInfo = {
      chaseId: this.chaseDetailState.chaseId,
      options,
    };
    (this.control as any).saveInfo = saveInfo;
    this.providerInput.saveInfo = saveInfo;
  }

  private get hasForm(): boolean {
    return this.form != null;
  }

  private get control(): FormControl {
    if (!this.hasForm) {
      return null;
    }

    const control = this.form.get(this.providerInput.getMasterKey()) as FormControl;
    return control;
  }

  private setDisabled(enable: boolean): void {
    // HACK: Wait for the form to exist before enabling/disabling.
    if (!this.hasForm) {
      setTimeout(() => this.setDisabled(enable), 100);
      return;
    }

    if (enable) {
      this.control.enable();
    } else {
      this.control.disable();
    }

    this.formService.updateDom.next();
  }
}
