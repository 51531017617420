import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class BulkAction {
  name: string;
  action: (items: any[]) => void;
  disabled?: boolean;
  showBulkAction?: boolean;
  hasIcon?: boolean;
  iconName?: string;
  alwaysEnabled?: boolean;

  constructor(options: BulkAction) {
    this.name = StringHelper.clean(options.name);
    this.action = options.action || (() => {});
    this.disabled = BooleanHelper.tryGet(options.disabled, false);
    this.showBulkAction = BooleanHelper.tryGet(options.showBulkAction, true);
    this.hasIcon = BooleanHelper.tryGet(options.hasIcon, false);
    this.iconName = StringHelper.clean(options.iconName);
    this.alwaysEnabled = BooleanHelper.tryGet(options.alwaysEnabled, false);
  }
}
