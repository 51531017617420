import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { InvoiceChartType } from "../../../../../shared/invoice/chart-type.model";
import { InvoiceSetting } from "../../../../../shared/invoice/invoice-setting.model";
import { InvoiceType } from "../../../../../shared/invoice/invoice-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { VendorExclusion } from "../../../../api/vendor/vendorExclusion.model";
import { InvoicesService } from "../../../invoice/invoices/invoices.service";
import { ProjectConfigurationService } from "../project-config.service";

@Component({
  selector: "app-project-invoice-config",
  templateUrl: "invoice-config.component.html",
  styleUrls: ["invoice-config.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProjectInvoiceSettingComponent implements OnInit {
  @Input() vendorDetail: VendorExclusion;
  invoiceSetting: InvoiceSetting;
  projectInvoiceFormGroup: FormGroup;
  vendorName: Textbox;
  isConfirmationModalVisible: boolean;
  @Output() onUpdate = new EventEmitter<null>(true);

  constructor(
    private formService: FormService,
    private projectConfigService: ProjectConfigurationService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private invoicesService: InvoicesService
  ) { }

  ngOnInit() {
    this.setupProjectInvoiceFormGroup();
    this.setInvoiceSetting();
  }

  approve(): void {
    const vendorExclusion = new VendorExclusion({
      vendorExclusionId: this.vendorDetail.vendorExclusionId,
    });
    this.updateConfig(vendorExclusion, "approve");
  }

  deny(): void {
    this.isConfirmationModalVisible = true;
  }

  get isVendorApproved(): boolean {
    return !NumberHelper.isAvailable(this.vendorDetail.vendorExclusionId) || NumberHelper.isLessThan(this.vendorDetail.vendorExclusionId, 0, true);
  }

  get isInvoiceNET30(): boolean {
    return this.vendorDetail.invoiceTypeId === InvoiceType.NET30;
  }
  get isInvoiceManaged(): boolean {
    return this.vendorDetail.invoiceTypeId === InvoiceType.MANAGED;
  }

  cancel(): void {
    this.isConfirmationModalVisible = false;
  }

  continue(): void {
    this.isConfirmationModalVisible = false;
    const vendorExclusion = new VendorExclusion({
      vendorId: this.vendorDetail.vendorId,
      projectId: this.vendorDetail.projectId,
      invoiceTypeId: this.vendorDetail.invoiceTypeId,
    });
    this.updateConfig(vendorExclusion, "deny");
  }

  private updateConfig(vendorExclusion: VendorExclusion, type: string): void {
    this.projectConfigService
      .approveOrDenyVendor(vendorExclusion, type)
      .subscribe(() => {
        this.onUpdate.emit();
        this.messagingService.showToast("Invoice configuration updated.", SeverityType.SUCCESS);
        if (type === "deny" && this.vendorDetail.invoiceTypeId === 1) {
          this.createInvoiceForDeniedNet30Vendor(vendorExclusion);
        }
        this.changeDetector.markForCheck();
      });
  }

  private setupProjectInvoiceFormGroup(): void {
    this.vendorName = new Textbox({
      key: "vendorName",
      label: "3RD PARTY VENDOR",
      value: this.vendorDetail.name,
      readonly: true,
    });

    this.projectInvoiceFormGroup = this.formService.createFormGroup([this.vendorName]);
  }

  private setInvoiceSetting(): void {
    this.invoiceSetting = new InvoiceSetting({
      invoiceTypeId: this.vendorDetail.invoiceTypeId,
      vendorId: this.vendorDetail.vendorId,
      chartTypes: [],
      isReadOnly: true,
    });

    if (
      (this.isInvoiceNET30 || this.isInvoiceManaged) &&
      this.vendorDetail != null &&
      this.vendorDetail.vendorInvoiceSetting != null &&
      this.vendorDetail.vendorInvoiceSetting.vendorInvoiceSettingChartTypeData != null &&
      ArrayHelper.isAvailable(this.vendorDetail.vendorInvoiceSetting.vendorInvoiceSettingChartTypeData.vendorInvoiceSettingChartType)
    ) {
      this.vendorDetail.vendorInvoiceSetting.vendorInvoiceSettingChartTypeData.vendorInvoiceSettingChartType.forEach((data, i) => {
        if (data != null) {
          this.invoiceSetting.chartTypes.push(new InvoiceChartType(data.type, data.flatRatePrice));
        }
      });
    }
  }

  createInvoiceForDeniedNet30Vendor(vendorExclusion: VendorExclusion): void {
    this.invoicesService.createInvoiceForDeniedNet30Vendor(vendorExclusion)
      .subscribe();
  }
}
