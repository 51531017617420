<div class="project-invoice-detail">
  <div class="ui-md-12">
    <form-factory [formGroup]="projectInvoiceFormGroup" [model]="vendorName"></form-factory>
  </div>

  <app-invoice-setting [invoiceSetting]="invoiceSetting"></app-invoice-setting>
  <div class="ui-md-12 ui-toolbar-group-left text-right" *ngIf="isInvoiceNET30">
    <app-button classes="success" class="button"
                text="APPROVE"
                [disabled]="isVendorApproved"
                (onClick)="approve()"></app-button>
    <app-button classes="danger"
                text="DENY"
                [disabled]="!isVendorApproved"
                (onClick)="deny()"></app-button>
  </div>
</div>

<app-confirmation-modal [(visible)]="isConfirmationModalVisible"
                        header="WARNING"
                        (onYes)="continue()"
                        (onCancel)="cancel()">
  Clicking Deny will automatically Pend all Chases that fall under that criteria. Are you sure you want to Deny it?
</app-confirmation-modal>
