import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { ExpectedDueDate } from "./expected-due-date.model";
import { ExpectedDueDateService } from "./expected-due-date.service";

@Component({
  selector: "retrieval-expected-due-date",
  templateUrl: "./expected-due-date.component.html",
  styleUrls: ["./expected-due-date.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpectedDueDateComponent implements OnInit, OnChanges {
  minimumDate: Date;
  formGroupExpectedDueDate: FormGroup;
  expectedDueDateInput = new Calendar();

  @Input() updateExpectedDueDateData: ExpectedDueDate;
  @Input() isExpectedDueDateModalVisible = false;
  @Input() visible = false;

  private pExpectedDueDate: Date;
  @Input()
  get expectedDueDate(): Date {
    return this.pExpectedDueDate;
  }
  set expectedDueDate(value: Date) {
    this.pExpectedDueDate = value;
  }
  @Output() expectedDueDateChange: EventEmitter<Date> = new EventEmitter();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<null> = new EventEmitter();
  @Output() onCancel: EventEmitter<null> = new EventEmitter();
  @Output() onUpdate: EventEmitter<null> = new EventEmitter();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly expectedDueDateService: ExpectedDueDateService,
    private readonly formService: FormService,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.minimumDate = new Date();

    this.expectedDueDateInput = new Calendar({
      key: "expectedDueDate",
      label: "Due Date",
      placeholder: "MM/DD/YYYY",
    });

    this.formGroupExpectedDueDate = this.formService.createFormGroup([this.expectedDueDateInput]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expectedDueDate) {
      if (changes.expectedDueDate?.isFirstChange()) { return; }

      if (changes.expectedDueDate.currentValue !== changes.expectedDueDate.previousValue) {
        if (this.expectedDueDate) {
          this.loadForm();
        }
      }
    }
  }

  loadForm(): void {
    this.formGroupExpectedDueDate.get(this.expectedDueDateInput.key).setValue(this.expectedDueDate);
  }

  onSetDueDate() {
    const formattedDate = DateHelper.format(this.formGroupExpectedDueDate.value.expectedDueDate);
    this.updateExpectedDueDateData.expectedDueDate = formattedDate === "" ? null : formattedDate;

    if (ArrayHelper.isAvailable(this.updateExpectedDueDateData.chaseIds)) {

      this.expectedDueDateService.updateDueDate(this.updateExpectedDueDateData).subscribe(
        data => {
          this.messagingService.showToast("Due date updated successfully.", SeverityType.SUCCESS);
          this.onUpdate.emit(null);
        },
        err => {
          this.messagingService.showToast("Error while updating due date", SeverityType.ERROR);
        }
      );
    } else {
      this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
      return;
    }
    this.onModalHide();
    this.changeDetector.markForCheck();
  }

  onModalHide() {
    this.visible = false;
    this.visibleChange.emit(false);
    this.expectedDueDate = null;
    this.onHide.emit(null);
    this.formGroupExpectedDueDate.get(this.expectedDueDateInput.key).setValue(this.expectedDueDate);
    this.changeDueDate(this.expectedDueDate);
  }

  clearExpectedDueDate(): void {
    this.expectedDueDate = null;
    this.formGroupExpectedDueDate.get(this.expectedDueDateInput.key).setValue(this.expectedDueDate);
  }

  changeDueDate(value: Date): void {
    this.expectedDueDateChange.emit(value);
  }
}
