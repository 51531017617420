import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { HccModel } from "../../project/hcc.model";

@Injectable({
  providedIn: "root",
})
export class AnalyticsRiskService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService,
    private route: Router
  ) { }

  getHccList(): Observable<HccModel[]> {
    const url = `${this.baseApiUrl}project/hcc`;
    return this.http.post(url, {}).pipe(map(this.automapper.curryMany("default", "HccModel")));
  }

  getHccListByProject(projectIds?: string): Observable<HccModel[]> {
    const url = `${this.baseApiUrl}project/hccbyprojectids?projectIdsAsCsv=${projectIds}`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "HccModel")));
  }

  get analyticsRiskRouteFromPath(): string {
    return this.route.url;
  }

  getVrcCodes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}risk/vrc`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "SelectableInput")));
  }

  getChaseQAList(userType: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}analytics/coderAndReader/list?userType=${userType}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getRestrictedProjects(): Observable<number[]> {
    const url = `${this.baseApiUrl}analytics/restrictedprojects`;
    return this.http.get<number[]>(url);
  }

}
