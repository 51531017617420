import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";


export const mapTags = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Tags")
    .forMember("tagId", o => o.tagId)
    .forMember("tagText", o => o.tagText)
    .forMember("color", o => o.color)
    .forMember("totalObject", o => o.totalObject)
    .forMember("tagTypeId", o => o.tagTypeId)
    .forMember("recordCount", o => o.recordCount)
    .forMember("tagSourceId", o => o.tagSourceId);
};

export class Tags {
  tagId: number;
  tagText: string;
  color: string;
  totalObject: number;
  tagTypeId: number;
  recordCount: number;
  tagSourceId: number;

  constructor(options: {
    tagId: number;
    tagText: string;
    color: string;
    totalObject: number;
    tagTypeId: number;
    recordCount: number;
    tagSourceId: number;
  }) {

    this.tagId = options.tagId;
    this.tagText = StringHelper.clean(options.tagText);
    this.color = StringHelper.clean(options.color);
    this.totalObject = options.totalObject;
    this.tagTypeId = options.tagTypeId;
    this.recordCount = options.recordCount;
    this.tagSourceId = options.tagSourceId;
  }

}

