export class CreateMember {
  readonly enrolleeId?: string;
  readonly firstName: string;
  readonly middleName?: string;
  readonly lastName: string;
  readonly dateOfBirth: string;
  readonly gender: string;
  readonly clientMemberId: number;

  constructor(options: {
    readonly enrolleeId?: string;
    readonly firstName: string;
    readonly middleName?: string;
    readonly lastName: string;
    readonly dateOfBirth: string;
    readonly gender: string;
    readonly clientMemberId?: number;

  }) {
    this.enrolleeId = options.enrolleeId;
    this.firstName = options.firstName;
    this.middleName = options.middleName;
    this.lastName = options.lastName;
    this.dateOfBirth = options.dateOfBirth;
    this.gender = options.gender;
    this.clientMemberId = options.clientMemberId;
  }
}
