<app-basic-grid 
  [configuration]="providerGridConfiguration"
  [data]="providerGridData"
  [(selection)]="providerGridSelection"
  [actions]="actions" 
  [actionTemplate]="actionTemplate">

  <ng-template #AddressesColumn let-rowData>
    <app-provider-addresses [providerGridData]="rowData"></app-provider-addresses>
  </ng-template>

  <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
        (onClick)="action.action(rowData); localActionPopover.hide();" [disabled]="action.disabled"></app-button>
    </div>
  </ng-template>

  <ng-template #isValidCheckbox let-rowData>
    <app-validation-checkbox [providerGridData]="rowData" (providerValidationChanged)="onProviderDisabled()"></app-validation-checkbox>
  </ng-template>

</app-basic-grid>

<app-modal [(visible)]="isConfirmModalVisible" (onCancel)="updateGridData($event)">
  {{confirmStatusMessage}}
  <footer>
    <app-button class="header-button" text="VIEW THIS ADDRESS" (onClick)="gotoAddressDetail()" [disabled]="false">
    </app-button>
  </footer>
</app-modal>

<app-modal [(visible)]="isConfirmModalVisible">
  {{confirmStatusMessage}}
  <footer>
    <app-button class="header-button" text="VIEW THIS ADDRESS" (onClick)="gotoAddressDetail()" [disabled]="false">
    </app-button>
  </footer>
</app-modal>

<retrieval-address-search [(visible)]="isAddressModalVisible" [callingSource]="addressSearchCallingSource"
  (onAddressSelection)="onAddressSelectionForProviderMove($event)" [(masterDocumentSourceId)]="addressId" (isNewAddress)="onIsNewAddress($event)">
</retrieval-address-search>

<retrieval-address-search-contact-info 
  [(visible)]="isContactInfoModalVisible" 
  (onMoveChases)="onMoveChasesConfirmation($event)" 
  [addressModel]="newAddressModel"
  (onAddressSelection)="onContactInfoSaved($event)"
  [moveType]="'Move provider'">
</retrieval-address-search-contact-info>

<app-modal [(visible)]="isMoveVisible" [header]="moveHeaderMessage"
  (onCancel)="closeChaseMovePop()">
  <footer>
    <app-button class="header-button" text="Yes" (onClick)="chaseMoved()" [disabled]="false"></app-button>
  </footer>
</app-modal>

<div class="clearfix"></div>
<div class="provider-search">
  <app-button text="Provider Search" classes="primary-action" (onClick)="searchProvider()"></app-button>
</div>
<app-provider-search-tool *ngIf="providerSearchToolVisible"
                          [(visible)]="providerSearchToolVisible"
                          [callProvider]="true">
</app-provider-search-tool>
