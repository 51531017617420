import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { RetrievalContactHistory } from "../../retrieval/retrieval-detail-contact-history.model";

@Injectable()
export class ContactLogListService {

  constructor(@Inject(BASE_API_URL) private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService
  ) { }

  getContactLogList(projectStatisticIds: string): Observable<RetrievalContactHistory[]> {
    const url = `${this.baseApiUrl}reporting/contactlog/list?projectStatisticId=${projectStatisticIds}`;
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "RetrievalContactHistory"))
    );
  }
}
