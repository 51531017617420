import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { BODY_HEIGHT_DATE, BODY_HEIGHT_UNIT, BODY_HEIGHT_VALUE, CHART_PAGE_NUMBER } from "../../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { ChaseDetailService } from "../../../platform/modules/member/chase-detail/chase-detail.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { FormService } from "../../form.service";
import { HighlightService } from "../../highlighter/highlight.service";
import { AbaHeightGroup } from "./aba-height-group.model";

@Component({
  selector: "form-aba-height-group",
  templateUrl: "./aba-height-group.component.html",
  styleUrls: [
    "../dynamic-group.scss",
    "../../highlighter/highlight.scss",
    "./aba-height-group.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbaHeightGroupComponent extends DynamicControlDirective<AbaHeightGroup> implements OnInit, AfterViewChecked, OnDestroy {
  private sink = new SubSink();
  private previousAge = 0;

  constructor(
    readonly highlighter: HighlightService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailService.chaseDetailsChange.subscribe(chaseDetails => {
        this.model.memberDateOfBirth = chaseDetails.find(item => item.key === "DOB").value;
      }),
      this.control.statusChanges
        .subscribe(() => this.changeDetector.markForCheck()),
      this.formService.updateDom
        .subscribe(() => this.changeDetector.markForCheck()),
      this.highlighter.onBlurGroup
        .subscribe(() => this.save())
    );
    this.updateInputs();
  }

  ngAfterViewChecked() {
    this.updateInputs();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get classes(): any {
    return {
      ...this.highlighter.className(),
      "control-container__invlaid": this.hasError,
    };
  }

  save(): void {
    if (this.control.pending) {
      setTimeout(() => this.save(), 100);
    } else if (this.control.valid && this.hasAtLeastOneValue(this.control)) {
      const event = new DynamicFormEvent({
        key: this.model.key,
        type: "save",
        value: this.control.value,
        control: this.control,
        model: this.model,
      });
      this.onChange.emit(event);
    }
  }

  remove(): void {
    if (this.formService.isDisabled(this.model)) {
      return;
    }

    const controls = (this.control as FormGroup).controls as { [key: string]: FormControl };

    const event = new DynamicFormEvent({
      key: this.model.key,
      type: "delete",
      value: this.control.value,
      control: this.control,
      model: this.model,
      successFn: () => {
        for (const key in controls) {
          if (controls.hasOwnProperty(key)) {
            const control = controls[key];
            if (!ArrayHelper.isAvailable((control as any).controls)) {
              control.setValue(null);
            }
          }
        }

        this.model.controls.forEach(control => {
          (control as any).saveInfo.entityId = null;
        });
      },
    });
    this.onChange.emit(event);
  }

  handleChangeEvents(event: DynamicFormEvent): void {
    this.updateInputs();
    switch (event.type) {
      case "save":
        this.save();
        break;
      default:
        break;
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  private hasAtLeastOneValue(control: AbstractControl): boolean {
    const keys = Object.keys(control.value);
    for (const key of keys) {
      const value = control.value[key];
      if (typeof value === "object" && value != null) {
        return this.hasAtLeastOneValue(value);
      } else if (value != null) {
        return true;
      }
    }

    return false;
  }

  private isCompleteRecursive(group: object): boolean {
    return Object.values(group).every(value => {
      const isAnObject = typeof value === "object" && value != null;
      return isAnObject ? this.isCompleteRecursive(value) : StringHelper.isAvailable((value || "").toString());
    });
  }

  private updateInputs() {
    const abaHeightDateInput = this.model.controls.find(
      (a: any) => a.saveInfo.id === BODY_HEIGHT_DATE.attributeId
    );

    if (abaHeightDateInput) {
      const abaHeightDateValue = this.control.value[abaHeightDateInput.key];

      if (DateHelper.isAvailable(abaHeightDateValue) && DateHelper.isAvailable(this.model.memberDateOfBirth)) {
        const unitInput = this.model.controls.find(
          (a: any) => a.saveInfo.id === BODY_HEIGHT_UNIT.attributeId
        );
        const valueInput = this.model.controls.find(
          (a: any) => a.saveInfo.id === BODY_HEIGHT_VALUE.attributeId
        );
        const chartInput = this.model.controls.find(
          (a: any) => a.saveInfo.id === CHART_PAGE_NUMBER.attributeId
        );
        const ageAtEnteredDate = DateHelper.ageInYears(
          this.model.memberDateOfBirth,
          abaHeightDateValue
        );
        if (this.previousAge !== ageAtEnteredDate) {
          this.previousAge = ageAtEnteredDate;
          if (ageAtEnteredDate > 19) {
            unitInput.hidden = true;
            valueInput.hidden = true;
            chartInput.hidden = true;
          } else {
            unitInput.hidden = false;
            valueInput.hidden = false;
            chartInput.hidden = false;
          }
        }
      }
    }
  }
}
