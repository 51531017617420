import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { DataSet } from "../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../shared/list/list-item";

@Injectable({
  providedIn: "root",
})
export class RetrievalService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getRetrievalStats(clientId: number | null = null, projectId: number | null = null): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}Retrieval/RetrievalStatsAsync?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getChaseStatusForTimePeriodBarKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/ChaseStatusForTimePeriodAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getPendsPieKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/PendsAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getChasesNotRetrievedPieKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/ChasesNotRetrievedAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getFieldTechAppointmentsBarKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}Retrieval/FieldTechAppointmentsAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getSchedulingStatusKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/schedulingstatuskpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getRetrievalActivityKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/retrievalactivitykpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getContactActivityKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/contactactivitykpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getRetrievalPendsKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/retrievalpendskpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getRetrievalProjectionsKpi(clientId: number | null = null, projectId: number | null = null): Observable<DataSet> {
    const url = `${this.baseApiUrl}reporting/retrieval/ProjectionKpi?clientId=${clientId}&projectId=${projectId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }
}
