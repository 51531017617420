import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SaveQuery } from "../save-query/save-query.model";
import { MyQueriesService } from "../save-query/save-query.service";

@Component({
  selector: "app-save-query-delete",
  templateUrl: "./save-query-delete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveQueryDeleteComponent {
  @Input() queryId = 0;
  @Output() isSavedQueryDeleted = new EventEmitter<boolean>();

  modalVisible = false;
  @Input() get visible(): boolean {
    return this.modalVisible;
  }

  set visible(value: boolean) {
    this.modalVisible = value;
  }

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  onHide(e: any) {
    this.onClose.emit(this.modalVisible);
  }

  constructor(
    private readonly myQueriesService: MyQueriesService
  ) { }

  confirmDeleteQuery() {
    const query = new SaveQuery({
      applicationAttributeId: this.queryId,
      columns: null,
      filters: null,
    });

    this.myQueriesService.saveQuery(query)
      .subscribe(() => this.isSavedQueryDeleted.emit(true));
  }

}
