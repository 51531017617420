import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { TextboxType } from "../textbox/textbox-type.enum";
import { ConfirmComponent } from "./confirm.component";

export interface IConfirm extends IDynamicInputOptions {
  type?: TextboxType;
  pageNumber?: number;
  adminValue?: string;
  validationId?: string;
  validationNote?: string;
  dataType?: string;
  autocomplete?: string;
}

export class Confirm extends DynamicInput implements IConfirm {
  type: TextboxType;
  pageNumber: number;
  adminValue: string;
  validationId: string;
  validationNote: string;
  dataType: string;
  autocomplete: string;

  readonly controlType = "text";
  readonly componentType = ConfirmComponent;

  constructor(options: IConfirm = {}) {
    super(options);
    this.type = options.type || TextboxType.TEXT;
    this.pageNumber = options.pageNumber;
    this.adminValue = options.adminValue;
    this.validationId = options.validationId;
    this.validationNote = options.validationNote;
    this.dataType = options.dataType;
    this.autocomplete = options.autocomplete || "off";
  }
}
