<div class="global-search">
  <div [ngClass]="getClassForSearchContainer()">
    <div class="global-search__content">
      <div class="global-search__content--tag-icon"
           [ngClass]="getTagIconClass()"
           (click)="onTagIconClick()">
        <app-icon [iconName]="getTagIcon()"
                  [iconStyle]="getTagIcon()">
        </app-icon>
      </div>
      <div class="global-search__content--entity">
        <form-factory class="global-search__searchform"
                      [formGroup]="searchFormGroup"
                      [model]="entityInput"
                      (onChange)="getEntityTypeValue($event)">
        </form-factory>
      </div>
      <div class="global-search__content--operator">
        <form-factory *ngIf="tagSearchActive"
                      class="global-search__operatorform"
                      [formGroup]="searchFormGroup"
                      [model]="tagsSearchOperatorInput"
                      (onChange)="getSearchOperatorValue($event)">
        </form-factory>
      </div>
      <div class="global-search__content--input">
        <form-factory *ngIf="!tagSearchActive"
                      class="global-search__searchform"
                      [formGroup]="searchFormGroup"
                      [model]="globalSearchInput"
                      (onInput)="onGlobalSearchInput()"
                      (keyup.enter)="search()">
        </form-factory>
        <form-factory *ngIf="tagSearchActive"
                      class="global-search__searchform"
                      [formGroup]="searchFormGroup"
                      [model]="globalTagSearchInput"
                      (onPanelShow)="onShowEvent(true)"
                      (onPanelHide)="onShowEvent(false)"
                      (keyup)="onKeyUp($event)">
        </form-factory>
      </div>
      <div class="global-search__content--button">
        <app-button *ngIf="isShowSearchIcon && !tagSearchActive"
                    class="search-bar__item search-bar__item--icon search-bar__item--search-icon"
                    classes="transparent small"
                    icon="play fa-search"
                    (onClick)="search()">
        </app-button>
        <app-button *ngIf="!isShowSearchIcon && !tagSearchActive"
                    class="search-bar__item search-bar__item--icon search-bar__item--search-icon"
                    classes="transparent small"
                    icon="times-circle"
                    (onClick)="resetSearchInput()">
        </app-button>
      </div>

      <div class="global-search__content--multisearch-button">
        <app-button *ngIf="isShowSearchIcon && tagSearchActive && overlayPanelVisible"
                    class="multisearch-bar__item multisearch-bar__item--icon multisearch-bar__item--multitag-search-icon"
                    classes="transparent small"
                    icon="play fa-search"
                    [disabled]="tagSearchActive && !isTagDataAvailable"
                    (onClick)="search()">
        </app-button>
        <app-button *ngIf="!isShowSearchIcon && tagSearchActive && overlayPanelVisible"
                    class="multisearch-bar__item multisearch-bar__item--icon multisearch-bar__item--multitag-search-icon"
                    classes="transparent small"
                    icon="times-circle"
                    (onClick)="resetSearchInput()">
        </app-button>
      </div>
    </div>
  </div>
</div>
