import { Component, OnInit } from "@angular/core";

@Component({
  selector: "retrieval-ft-detail",
  templateUrl: "./ft-detail.component.html",
  styleUrls: ["./ft-detail.component.scss"],
})
export class FtDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
