<platform-idle></platform-idle>
<app-internet-speed></app-internet-speed>
<div class="platform__header" *ngIf="this.menuService.showHeader | async">
  <platform-header></platform-header>
</div>
<div class="platform__body">
  <div>
    <platform-navigation [items]="items"></platform-navigation>
  </div>
  <div [ngClass]="getClass(this.menuService.showHeader | async)">
    <router-outlet></router-outlet>
  </div>
  <div class="platform__widget" *ngIf="isEnableWidgetSection">
    <platform-widget> </platform-widget>
  </div>
</div>
