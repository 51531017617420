import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../../../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../../../../utilities/contracts/number-helper";
import { ChartService } from "../../../../../../../chase-detail/chase-detail-chart/chart.service";
import { Encounter } from "../../../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskEntity } from "../../../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskService } from "../../../../../../../chase-detail/chase-detail-chart/risk/risk.service";

@Component({
  selector: "member-risk-encounter-delete",
  templateUrl: "./delete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterDeleteComponent implements OnInit, OnDestroy {
  @Input() encounter: Encounter;

  private isEnabled: boolean;
  private encounterToDeleteIndex: number;
  private encounterToDelete: RiskEntity;
  private sink = new SubSink();

  isConfirmDeleteVisible = false;

  constructor(
    private riskService: RiskService,
    private chartService: ChartService,
    private messagingService: MessagingService) { }

  get hasDiagnoses(): boolean {
    return ArrayHelper.isAvailable(this.encounterToDelete.diagnoses);
  }

  ngOnInit(): void {
    this.sink.add(
      this.riskService.data.pipe(
        filter(state => state.hasSelectedEncounterIndex),
        map(state => {
          this.isEnabled = state.isEnabled;
          this.encounterToDeleteIndex = state.selectedEncounterIndex;
          this.encounterToDelete = state.selectedEncounter;
        })
      ).subscribe()
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  showDeleteEncounterModal(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.isEnabled) {
      this.isConfirmDeleteVisible = true;
    }
  }

  deleteEncounter(): void {
    this.isConfirmDeleteVisible = false;
    const encounterHasBeenSaved = NumberHelper.isGreaterThan(this.encounterToDelete.entityId, 0);

    if (encounterHasBeenSaved) {
      this.deleteEncounterFromServer();
      return;
    }

    this.deleteEncounterFromState();
  }

  private deleteEncounterFromServer(): void {
    const attributes = this.encounterToDelete.attributes;
    const processChildEntity = this.hasDiagnoses ? 1 : 0;

    this.chartService.deleteEncounter(attributes, processChildEntity).subscribe(
      () => this.deleteEncounterFromState(),
      () => this.messagingService.showMessage("Try to delete the encounter again.", SeverityType.ERROR)
    );
  }

  private deleteEncounterFromState(): void {
    this.riskService.deleteEncounter(this.encounterToDeleteIndex);
  }
}
