import { IAutoMapper } from "../core/automapper/interfaces";
import { StringHelper } from "../utilities/contracts/string-helper";


export const mapProviderCustomDetail = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "ProviderCustomDetail")
        .forMember("attributeCode", o => o.attributeCode)
        .forMember("attributeValue", o => o.attributeValue);
};

export class ProviderCustomDetail {
    attributeCode: string;
    attributeValue: string;

    constructor(options: {
        attributeCode: string;
        attributeValue: string;

    }) {
        this.attributeCode = StringHelper.clean(options.attributeCode);
        this.attributeValue = StringHelper.clean(options.attributeValue);
    }
}

