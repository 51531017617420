import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { InputsService } from "../inputs.service";
import { Textbox } from "./textbox.model";

@Component({
  selector: "form-textbox",
  templateUrl: "./textbox.component.html",
  styleUrls: ["./textbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextboxComponent extends DynamicControlDirective<Textbox> implements OnInit, OnDestroy {
  private sink = new SubSink();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly inputsService: InputsService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): string {
    return this.getClasses("textbox");
  }

  get hasTooltip(): boolean {
    return StringHelper.isAvailable(this.model.tooltip);
  }
  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }

  onClickEvent(event): void {
    this.inputsService.onTextboxClick(this.control, this.model);
  }

  @HostListener("wheel", ["$event"])
  onWheel(event: Event) {
    event.preventDefault();
  }
}
