<div class="control control--input control--textbox" [hidden]="hidden" *ngIf="!hidePageNumber">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Page Number</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="model.readonly"
    [disabled]="model.disabled"
    type="number"
    placeholder="{{pageNumberPlaceholder}}"
    [(ngModel)]="model.saveInfo.pageNumber"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"
    (input)="validateInput($event)"
    />
</div>

<div [formGroup]="formGroup" class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase}}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <input
    pInputText
    [ngClass]="classes"
    [readonly]="model.readonly ||  hideAdminValue"
    [type]="model.type"
    [autocomplete]="model.autocomplete"
    [id]="model.key"
    placeholder="Actual Value"
    (change)="onChangeEvent($event)"
    (input)="onInput.emit($event)"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"
    [formControlName]="model.key" />
</div>

<div class="control control--input control--textbox" [hidden]="hidden"  *ngIf="!hideAdminValue">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Admin Value</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="true"
    [disabled]="model.disabled"
    placeholder="Admin Value"
    [ngModel]="model.adminValue"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>

<div class="control control--input control--dropdown" [hidden]="hidden">
  <div class="control__header">
    <label class="control__header__label">Confirm</label>
  </div>
  <p-dropdown
    appendTo="body"
    styleClass="control__input control__input--dropdown"
    [options]="validationOptions"
    placeholder="Confirm"
    [(ngModel)]="model.saveInfo.validationId"
    (ngModelChange)="validationChange()"
    [disabled]="model.disabled"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)">
  </p-dropdown>
</div>

<div class="control control--input control--textarea" [hidden]="hiddenNote">
  <div class="control__header">
    <label class="control__header__label">Note</label>
  </div>
  <textarea pInputTextarea
    class="control__input control__input--textarea"
    [ngStyle]="{ resize: false }"
    [rows]="2"
    placeholder="Write a note..."
    [(ngModel)]="model.saveInfo.validationNote"
    [disabled]="model.disabled || hiddenNote"
    required
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"></textarea>
</div>