import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { GridViewsState } from "./grid-views-state.model";

@Injectable({ providedIn: "root" })
export class GridViewsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }


  get(attributeId: number): Observable<GridViewsState> {
    const url = `${this.baseApiUrl}gridviews/list?attributeId=${attributeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("GridViews", "GridViewsState"))
    );
  }
}
