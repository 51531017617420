import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ChartModel } from "../../../../shared/kpi/model/chart.model";
import { DataModel } from "../../../../shared/kpi/model/data.model";
import { LabelModel } from "../../../../shared/kpi/model/label.model";
import { RetrievalService } from "../../retrieval/retrieval.service";
import { ProjectReportsService } from "./project-reports.service";


@Component({
  selector: "app-project-reports",
  templateUrl: "./project-reports.component.html",
})
export class ProjectReportsComponent implements OnInit {

  readonly AUTHENTICATION_KEY = "authentication";

  chaseStatusChartModel: ChartModel;
  chaseStatusLabelModel: LabelModel;
  chaseStatusDataOptions: any;
  chaseStatusArray: DataModel[] = [];

  numeratorHitsChartModel: ChartModel;
  numeratorHitsLabelModel: LabelModel;
  numeratorHitsDataOptions: any;
  numeratorHitsArray: DataModel[] = [];

  retrievalProjectionsChartModel: ChartModel;
  retrievalProjectionsLabelModel: LabelModel;
  retrievalProjectionsDataOptions: any;
  retrievalProjectionsArray: DataModel[] = [];

  roleId: any[] = [];
  roleIdCount = 0;
  isRoleModalVisible = false;
  isInComingModalVisible = false;
  selectedRole: string;
  numeratorHitMaxValue: number;
  clientId: number;
  projectId: number;

  constructor(

    private projectReportService: ProjectReportsService,
    private changeDetector: ChangeDetectorRef,
    private retrievalService: RetrievalService
  ) {

    this.chaseStatusChartModel = new ChartModel(
      {
        chartHeader: "CHASE STATUS", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.chaseStatusDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "Chases",
                      fillStyle: "#b3daff",
                    };
                  case 1:
                    return {
                      text: "Addreses",
                      fillStyle: "#ffd699",
                    };

                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "CHASE STATUS",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };

    this.retrievalProjectionsChartModel = new ChartModel(
      {
        chartHeader: "RETRIEVAL PROJECTIONS", chartType: "bar", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.retrievalProjectionsDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "Chases",
                      fillStyle: "#b3daff",
                    };

                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          barThickness: 15,
          scaleLabel: {
            display: true,
            labelString: "CHASES",
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
    };
  }

  ngOnInit() {
    this.getChaseStatusReportKpi();
    this.getNumeratorHitsKpi();
    this.getRetrievalProjectionsKpi();
  }

  initializeNumeratorHitsKpi(): void {

    this.numeratorHitsChartModel = new ChartModel(
      {
        chartHeader: "NUMERATOR HITS", chartType: "horizontalBar", chartWidth: 1280,
        chartHeight: 1500, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.numeratorHitsDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "MR-Compliant",
                      fillStyle: "#fcffaf",
                    };
                  case 1:
                    return {
                      text: "Non-Compliant",
                      fillStyle: "#C8D0DB",
                    };
                  case 2:
                    return {
                      text: "NC-Contra",
                      fillStyle: "#f2917d",
                    };
                  case 3:
                    return {
                      text: "NC-Exclude",
                      fillStyle: "#bff298",
                    };
                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        axis: "yAxes",
        mode: "nearest",
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: "NUMERATOR HITS",
          },
          type: "logarithmic",
          ticks: {
            min: 0,
            max: this.numeratorHitMaxValue,
            callback(value, index, values) {
              return Number(value.toString());
            },
          },
          afterBuildTicks(chart) {
            const num = parseFloat(chart.max);
            chart.ticks = [];
            const percentages = [0, 1, 30, 55]; // used to calculate Percentage to display graph values in Kpi.
            for (const row of percentages) {
              const value = (num - (num - (num * row / 100))).toFixed();
              const digits = Math.ceil(Math.log(parseInt(value, 10)) / Math.LN10) < 3 ? 10 : 100;
              chart.ticks.push(Math.ceil(parseInt(value, 10) / digits) * digits);
            }
            const maxValueRound = Math.ceil((Math.log(parseInt(chart.max, 10)) / Math.LN10)) < 3 ? 10 : 100;
            chart.ticks.push(Math.ceil(parseInt(chart.max, 10) / maxValueRound) * maxValueRound);
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          barThickness: 8,
        }],
      },
      hover: {
        animationDuration: 0,
      },
    };

  }

  getChaseStatusReportKpi(): void {
    this.projectReportService.getChaseStatusReportKpi(this.clientId, this.projectId)
      .subscribe(result => {

        this.chaseStatusLabelModel = new LabelModel({ labels: result.labels });
        this.chaseStatusArray = [
          new DataModel({
            label: "CHASES", data: result.data[0], backgroundColor: "#b3daff",
            borderColor: "#b3daff", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "ADDRESSES", data: result.data[1], backgroundColor: "#ffd699",
            borderColor: "#ffd699", fill: false,
            url: [`reporting/documentsource`],
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }

  getNumeratorHitsKpi(): void {
    this.projectReportService.getNumeratorHitsKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.numeratorHitMaxValue = this.getKpiMaxValue(result.data) + 100;

        this.numeratorHitsLabelModel = new LabelModel({ labels: result.labels });
        this.numeratorHitsArray = [
          new DataModel({
            label: "MR-Compliant", data: result.data[0], backgroundColor: "#fcffaf",
            borderColor: "#fcffaf", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "Non-Compliant", data: result.data[1], backgroundColor: "#C8D0DB",
            borderColor: "#C8D0DB", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "NC-Contra", data: result.data[2], backgroundColor: "#f2917d",
            borderColor: "#f2917d", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "NC-Exclude", data: result.data[3], backgroundColor: "#bff298",
            borderColor: "#bff298", fill: false,
            url: [`reporting/chase`],
          }),
        ];
        this.initializeNumeratorHitsKpi();
        this.changeDetector.markForCheck();
      });
  }

  getKpiMaxValue(data): number {
    let max = -Infinity;
    for (const item of data) {
      let len = item.length;
      while (len--) {
        if (Number(item[len]) > max) {
          max = Number(item[len]);
        }
      }
    }
    return max;
  }

  getRetrievalProjectionsKpi() {
    this.retrievalService.getRetrievalProjectionsKpi(this.clientId, this.projectId)
      .subscribe(result => {

        this.retrievalProjectionsLabelModel = new LabelModel({ labels: result.labels });
        this.retrievalProjectionsArray = [
          new DataModel({
            label: "CHASES", data: result.data[0], backgroundColor: "#b3daff",
            borderColor: "#b3daff", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
        ];

        this.changeDetector.markForCheck();
      });
  }

  applyFilter($event) {
    this.clientId = $event.clientId;
    this.projectId = $event.projectId;
    this.getChaseStatusReportKpi();
    this.getNumeratorHitsKpi();
    this.getRetrievalProjectionsKpi();
  }
}
