import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../../core/automapper/automapper.service";
import { SelectableInput } from "../../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Provider } from "../../../../../../../../provider/provider.model";
import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../../utilities/contracts/string-helper";
import { Diagnosis } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { Encounter } from "../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskState } from "../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailStateService } from "../../../../../chase-detail/chase-detail-state.service";
import { DiagnosisService } from "../../risk-encounter/diagnosis/diagnosis.service";
import { EncounterSummary } from "./summary.model";

@Component({
  selector: "member-risk-submit-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskSubmitSummaryComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private providers: Provider[];
  private vrcs: SelectableInput[];

  private readonly validEncounter = "Valid";
  private readonly nonValidEncounter = "Non-Valid";
  private readonly unreviewed = "Unreviewed";
  private readonly substantiated = "Substantiated";
  private readonly unsubstantiated = "Unsubstantiated";


  encounters: EncounterSummary[];
  activeState: string[] = [];
  groupedEncounters = {};
  encounterStatuses = [this.validEncounter, this.nonValidEncounter, this.unreviewed];
  diagnosisStatuses = [this.substantiated, this.unsubstantiated, this.unreviewed];

  constructor(
    private readonly riskService: RiskService,
    private readonly automapper: AutomapperService,
    private readonly diagnosisService: DiagnosisService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.sink.add(
      this.riskService.data
        .pipe(
          map((riskState: RiskState) => {
            this.encounters = this.automapper.mapMany("RiskData", "EncounterSummary", riskState.encounters);
            this.groupedEncounters = this.groupEncounters(this.encounters);
            this.changeDetector?.markForCheck();
            this.filterValidDiagnoses();
            this.providers = riskState.providers;
            this.vrcs = riskState.vrcs;
            this.encounters.forEach(encounter => {
              encounter.setProviderName(riskState);
              encounter.setEncounterType(riskState);
              encounter.groupedDiagnoses = this.groupDiagnoses(encounter.diagnoses);
            });
          })
        ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  onTabOpen(event: any, encounter: Encounter): void {
    const encounterDate = {
      startDate: encounter?.startDate,
      endDate: encounter?.endDate,
      submitTab: true,
    };
    this.chaseDetailStateService.submitTabIsEncounterSelected.next(encounterDate);
    this.collapseAllTabs();
    this.activeState[encounter.id] = !this.activeState[encounter.id];
    this.setSelectedEncounter(encounter.id);
    this.diagnosisService.updateDataEntryPageNumber(encounter.pageNumber, encounter.encounterFound);
  }

  onDiagnosisSelected(event: any, pageNumber: number, diagnosis: Diagnosis): void {
    const selectedProvider = this.getProvider(diagnosis.providerSourceAliasId);
    const selectedDiagnosis = this.diagnosisService.getDiseaseDetail(diagnosis.id);
    const diagnosisData = {
      diseaseName: selectedDiagnosis?.diseaseName,
      dos: diagnosis.dosRange,
      icdCode: selectedDiagnosis?.icdCode,
      providerName: selectedProvider,
      submitEncounterSelected: true,
      submitDiagnosisSelected: true,
    };
    this.chaseDetailStateService.submitTabIsDiagnosisSelected.next(diagnosisData);
    this.diagnosisService.toggleDiagnosisSelected(true);
    this.diagnosisService.updateDataEntryPageNumber(pageNumber, diagnosis.diagnosisValidationResult);
  }

  onTabClose(): void {
    this.collapseAllTabs();
  }

  editEncounter(): void {
    this.router.navigate(["../encounter"], { relativeTo: this.activatedRoute });
  }

  editDiagnosis(id: string): void {
    const selectedDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === id);
    this.riskService.setData({ selectedDiagnosisIndex });

    setTimeout(() => {
      this.router.navigate(["../encounter"], { relativeTo: this.activatedRoute });
    });
  }

  getProvider(providerSourceAliasId: string): string {
    return this.providers.find(p => p.providerSourceAliasId === providerSourceAliasId)?.providerName;
  }

  getVrcDescription(vrcs: string): string {
    const vrcList = vrcs?.split(",");
    vrcList?.forEach((vrc, index, array) => array[index] = this.vrcs.find(v => v.value === vrc)?.text);
    return vrcList?.join(", ");
  }

  getDiseaseDetail$(diagnosis: Diagnosis): Observable<DiseaseDetail[]> {
    if (diagnosis.isEveDiagnosis) {
      return this.diagnosisService.getHccForEve$(diagnosis);
    } else {
      return this.diagnosisService.getHccForAdmin$(diagnosis);
    }
  }

  isValidEncounterStatus(encounterStatus: string): boolean {
    return encounterStatus === this.validEncounter;
  }

  totalEncounters(status: string): string {
    return this.groupedEncounters[status]?.length?.toString();
  }

  totalDiagnoses(diagnoses: Diagnosis[]): string {
    return diagnoses?.length?.toString();
  }

  showNoHccLabel(diseaseDetails: DiseaseDetail[]): boolean {
    return diseaseDetails.every(detail => !StringHelper.isAvailable(detail.categoryCode));
  }

  codingModelLabel(diagnosis: Diagnosis): string {
    const label = this.diagnosisService.displayCodingModelLabel(diagnosis);
    return StringHelper.isAvailable(label) ? label : "No HCC";
  }

  hccLabel(diseases: DiseaseDetail[]): string {
    return this.diagnosisService.displayHccLabel(diseases);
  }

  trackByIndex(index, item) {
    return index;
  }

  getDiseaseName(disease: string, icd: string): string {
   return disease.replace(icd, "").replace(":", "").trim();
  }

  shouldDisplayEveDiagnosis(diagnosis: Diagnosis): boolean {
    return diagnosis.isEveDiagnosis && ArrayHelper.isAvailable(diagnosis.codingModels);
  }

  isCodingModelAvailable(diagnosis: Diagnosis): boolean {
    return diagnosis.hccs.includes(":") || diagnosis.rxHcc.includes(":"); // Workaround for backward compatibility with old coder dx
  }

  private groupEncounters(encounters: EncounterSummary[]) {
    return encounters?.reduce(
      (acc, encounter) => {
        const key = encounter.getEncounterStatus ;

        if (!this.isEncounterEveAndUnreviewed(encounter)) {
          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(encounter);
        }
        return acc;
      },
      {});
  }

  private isEncounterEveAndUnreviewed(encounter: EncounterSummary) {
    return !StringHelper.isAvailable(encounter.encounterFound) && encounter.isEve;
  }

  private groupDiagnoses(diagnoses: Diagnosis[]) {
    return diagnoses?.reduce(
      (acc, diagnosis) => {
        const key = !StringHelper.isAvailable(diagnosis.diagnosisStatus) ? "Unreviewed" : diagnosis.diagnosisStatus;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(diagnosis);
        return acc;
      },
      {});
  }

  private filterValidDiagnoses(): void {
    this.encounters.forEach(encounter =>
      encounter.diagnoses = encounter.diagnoses.filter(d =>
        (d.isAdmin && NumberHelper.isAvailable(d.encounterId) && NumberHelper.isAvailable(d.diagnosisId)) ||
        d.isManuallyAddedSource || d.isEveDiagnosis)
    );
  }

  private collapseAllTabs(): void {
    if (ArrayHelper.isAvailable(this.encounters)) {
      this.encounters.forEach(encounter => {
        this.activeState[encounter.id] = "";
      });
    }
  }

  private setSelectedEncounter(id: string): void {
    const index = this.riskService.data.value.encounters.findIndex(encounter => encounter.id === id);
    this.riskService.setSelectedEncounterIndex(index);
  }
}
