import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { DocumentViewerSessionService } from "../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { ListItem } from "../../../../../../shared/list/list-item";
import { CollapsibleModalComponent } from "../../../../../../shared/panel/collapsible-modal/collapsible-modal.component";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { ChartService } from "../chart.service";
import { AcceptanceResultComponent } from "./acceptance-result/acceptance-result.component";
import { AcceptanceType } from "./acceptance-type.enum";
import { CatalyticNumerator } from "./catalytic-numerator.model";
import { CatalyticResult } from "./catalytic-result.model";
import { CatalyticResultService } from "./catalytic-result.service";
import { MatchResultType } from "./match-result-type.enum";
import { SystemResultComponent } from "./system-result/system-result.component";

@Component({
  selector: "member-nlp-hedis",
  templateUrl: "./nlp-hedis.component.html",
  styleUrls: ["./nlp-hedis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NlpHedisComponent implements OnInit, OnDestroy, OnChanges {
  private sink = new SubSink();

  @ViewChild("systemColumn", { static: true }) systemColumn: TemplateRef<SystemResultComponent>;
  @ViewChild("acceptanceColumn", { static: true }) acceptanceColumn: TemplateRef<AcceptanceResultComponent>;
  @ViewChild(CollapsibleModalComponent, { static: true }) collapsibleModal: CollapsibleModalComponent;
  @Input() chaseId: number;
  @Input() measureYear: number;
  @Input() isFormReloadingComplete: boolean;

  pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
  }

  @Output() visibleChange = new EventEmitter<boolean>();

  catalyticNumerators: CatalyticNumerator[];
  catalyticResult: CatalyticResult;
  chaseDetailState: ChaseDetailState;
  gridConfiguration: GridConfiguration;
  selection: CatalyticNumerator;

  formGroup: FormGroup;

  constructor(
    private catalyticResultService: CatalyticResultService,
    private chartService: ChartService,
    private chaseDetailStateService: ChaseDetailStateService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
    this.getCatalyticResult(this.chaseId);
    this.createGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    // TODO: Try to minimize the Cognitive Complexity
    if (changes.isFormReloadingComplete) {
      if (changes.isFormReloadingComplete.isFirstChange()) { return; }

      if (changes.isFormReloadingComplete.currentValue !== changes.isFormReloadingComplete.previousValue) {
        if (this.isFormReloadingComplete) {
          this.chaseDetailState.displayNlpResults = false;
        }
      }
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasCatalyticResult(): boolean {
    return !!(this.catalyticResult);
  }

  get infoItems(): ListItem[] {
    const list = [
      new ListItem({
        key: "MATCH",
        value: this.hasCatalyticResult && this.catalyticResult.totalMatch ? this.catalyticResult.totalMatch.toString() : "0",
      }),
      new ListItem({
        key: "NO MATCH",
        value: this.hasCatalyticResult && this.catalyticResult.totalNoMatch ? this.catalyticResult.totalNoMatch.toString() : "0",
      }),
      new ListItem({
        key: "PARTIAL MATCH",
        value: this.hasCatalyticResult && this.catalyticResult.totalPartialMatch ? this.catalyticResult.totalPartialMatch.toString() : "0",
      }),
    ];

    return list;
  }

  get isReviewedByUser(): boolean {
    return this.hasCatalyticResult && this.catalyticResult.reviewedByUser;
  }

  get enableSubmitButton(): boolean {
    return this.hasNumeratorsBeenReviewed;
  }

  get hasNumeratorsBeenReviewed(): boolean {
    const numerators = this.hasCatalyticResult ? this.catalyticResult.numerators : [];
    return numerators.every(a => NumberHelper.isAvailable(a.accepted));
  }

  get isAllNumeratorsAgreed(): boolean {
    const numerators = this.catalyticResult.numerators;
    return numerators.every(numerator => numerator.accepted === 1);
  }

  saveCatalyticResult(isOnlyUpdatingNumeratorAcceptance: boolean = false): void {
    if (!isOnlyUpdatingNumeratorAcceptance) {
      this.catalyticResult.systemResultsReviewed = true;
    }

    this.catalyticResultService
      .saveCatalyticResult(this.catalyticResult)
      .subscribe(
        () => {
          if (!isOnlyUpdatingNumeratorAcceptance) {
            this.messagingService.showToast("Results have been reviewed and submitted.", SeverityType.SUCCESS);
            this.submitOrRefreshForm();
          }
          this.changeDetector.markForCheck();
        },
        err => {
          this.messagingService.showToast("Submission failed.", SeverityType.ERROR);
        }
      );
  }

  selectedNumerator(): void {
    if (this.selection) {
      this.documentViewerSessionService.updateCatalyticNumeratorState(this.selection);
    }
  }

  updateAcceptanceResult(numerator: any): void {
    const isOnlyUpdatingNumeratorAcceptance = true;
    const catalyticNumerator =
      ArrayHelper.isAvailable(numerator) ? numerator : [numerator];

    const updatedNumerators = this.catalyticResult.numerators.map(
      obj => catalyticNumerator.find(o =>
        o.entityTypeId === obj.entityTypeId) || obj);

    this.catalyticResult = this.updateCatalyticResult({ numerators: updatedNumerators });
    this.saveCatalyticResult(isOnlyUpdatingNumeratorAcceptance);

    this.changeDetector.markForCheck();
  }

  private createGrid(): void {
    const catalyticResultColumns = [
      new GridColumnDefinition({ header: "GAP", field: "entityTypeName" }),
      new GridColumnDefinition({ header: "MR DOS", field: "dateOfService" }),
      new GridColumnDefinition({ header: "MR RESULT", field: "result" }),
      new GridColumnDefinition({ header: "EVE PG #", field: "botPageNumber", width: "50px" }),
      new GridColumnDefinition({ header: "MR PG #", field: "medicalRecordPageNumber", width: "50px" }),
      new GridColumnDefinition({ header: "SYSTEM RESULTS", template: this.systemColumn, isSortableColumn: false }),
      new GridColumnDefinition({ header: "ACCEPTANCE", template: this.acceptanceColumn, isSortableColumn: false, width: "150px" }),
    ];

    this.gridConfiguration = new GridConfiguration({
      columns: catalyticResultColumns,
      showActionColumn: false,
      showMenu: false,
      hasPagination: false,
      selectionMode: "single",
      showSelectionControl: false,
    });
  }

  private getCatalyticResult(chaseId: number): void {
    this.catalyticResultService
      .getCatalyticResult(chaseId)
      .subscribe(result => {
        this.catalyticResult = result;
        this.catalyticNumerators = result.numerators;

        this.catalyticNumerators.map(obj => obj.result = this.chartService.getLabel(obj.result, this.measureYear));
        this.changeDetector.markForCheck();
        setTimeout(() => {
          this.collapsibleModal.primeDialog.center();
        },         0);
      });
  }

  private getDeletedNumerators(): void {
    const numerators: CatalyticNumerator[] = this.catalyticResult.numerators;
    const numeratorsToDelete: string[] = numerators
      .filter(numerator =>
        numerator.accepted === AcceptanceType.DISAGREE && (numerator.matchResult === MatchResultType.MATCH || numerator.matchResult === MatchResultType.PARTIALMATCH))
      .map(numerator =>
        numerator.eventId);

    this.documentViewerSessionService.updateCatalyticDeletedNumerators(numeratorsToDelete);
  }

  private submitOrRefreshForm() {
    this.getDeletedNumerators();
    const isReloadCodingForm = true;
    this.chartService.updateRefreshCodingForm(isReloadCodingForm);
    this.chartService.onChangeEmit();
  }

  private updateCatalyticResult(data: CatalyticResult): CatalyticResult {
    return new CatalyticResult({ ...this.catalyticResult, ...data });
  }
}
