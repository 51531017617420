import { IAutoMapper } from "../interfaces";

export const mapAssignedAddressItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "AddressListItem")
    .forMember("addressId", o => o.masterDocumentSourceId)
    .forMember("address", o => o.address)
    .forMember("chaseCount", o => o.chaseCount)
    .forMember("status", o => o.status)
    .forMember("phone", o => o.phone)
    .forMember("city", o => o.city)
    .forMember("state", o => o.state)
    .forMember("groupName", o => o.groupName)
    .forMember("documentSourceTypeId", o => o.documentSourceTypeId);
};

