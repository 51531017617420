import { Component, OnInit } from "@angular/core";

@Component({
  selector: "retrieval-address-detail-business-hours",
  templateUrl: "./address-detail-business-hours.component.html",
  styleUrls: ["./address-detail-business-hours.component.scss"],
})
export class AddressDetailBusinessHoursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
