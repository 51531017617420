import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-gap-status",
    templateUrl: "./gap-status.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapStatusComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.RISK_GAP_STATUS;

    constructor() {
        this.filters = ["Projects", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "MemberKey", "Address", "Npi", "ChaseId"];
    }
}
