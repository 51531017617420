import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { ModalComponent } from "../panel/modal/modal.component";
import { MemberCentricChase } from "./membercentric-chase.model";

@Component({
  selector: "app-membercentric-doc-attachment",
  templateUrl: "./membercentric-doc-attachment.component.html",
  styleUrls: ["./membercentric-doc-attachment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembercentricDocAttachmentComponent extends ModalComponent {
  @Input() memberCentricGridData: MemberCentricChase[];
  @Input() targetChaseId: number;
  @Output() onConfirmDocAttachment = new EventEmitter<number[]>();

  gridConfiguration = new GridConfiguration();
  gridSelection: MemberCentricChase[];

  constructor(
  ) { super(); }

  onVisibleChange($event): void {
    if ($event) {
      this.resetGrid();
    }

    this.visibleChange.emit($event);
  }

  attachDocToMemberCentricChases(): void {
    const chaseIds = this.gridSelection.map(x => x.chaseId);
    this.onConfirmDocAttachment.emit(chaseIds);

    this.visible = false;
  }

  onHeaderToggle($event): void {
    if (!$event.checked) {
      this.resetGrid();
    }
  }

  resetGrid(): void {
    this.gridConfiguration = new GridConfiguration();
    this.gridSelection = [];
    this.gridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "status", header: "Status" }),
      new GridColumnDefinition({ field: "sampleCompliance", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "chaseCompliance", header: "Chase Compliance" }),
    ];

    this.gridConfiguration.hasPagination = false;
    this.gridConfiguration.selectionMode = "multiple";
    this.gridConfiguration.showActionColumn = false;
    this.gridConfiguration.showExportAction = false;
    this.gridConfiguration.showMenu = false;

    const preselectedRows = this.memberCentricGridData.filter(x => x.disabled || x.chaseId === +this.targetChaseId);
    this.gridSelection.push(...preselectedRows);
  }
}
