import { CalculatedGroup, ICalculatedGroupOptions } from "../calculated-group.model";
import { DodDeliveryGroupComponent } from "./dod-delivery-group.component";

export interface IDodDeliveryGroupOptions extends ICalculatedGroupOptions { }

export class DodDeliveryGroup extends CalculatedGroup implements IDodDeliveryGroupOptions {
  readonly controlType = "dod-delivery-group";
  readonly componentType = DodDeliveryGroupComponent;


  constructor(options: IDodDeliveryGroupOptions = {}) {
    super(options);
  }
}
