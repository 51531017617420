import { Component, Input } from "@angular/core";
import { DatesBetween } from "./dates-between.model";

@Component({
  selector: "form-dates-between",
  templateUrl: "./dates-between.component.html",
  styleUrls: ["./dates-between.component.scss"],
})
export class DatesBetweenComponent {
  @Input() type = "";
  @Input() model: DatesBetween;

  get hasModel(): boolean {
    return this.model != null;
  }

  get startDate(): Date {
    return this.hasStartDate ? this.model.startDate : null;
  }

  get endDate(): Date {
    return this.hasEndDate ? this.model.endDate : null;
  }

  get hasStartDate(): boolean {
    return this.hasModel && this.model.hasStartDate;
  }

  get hasEndDate(): boolean {
    return this.hasModel && this.model.hasEndDate;
  }
}
