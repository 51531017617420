import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { PopoverComponent } from "../../panel/popover/popover.component";
import { DocumentRequest } from "../document-request.model";

@Component({
  selector: "app-document-request-artifact",
  templateUrl: "./document-request-artifact.component.html",
  styleUrls: ["./document-request-artifact.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentRequestArtifactComponent {
  @Input() documentRequests: DocumentRequest[] = [];
  @Input() addressUrlOfChase: string;

  get hasDocumentRequests(): boolean {
    return ArrayHelper.isAvailable(this.documentRequests);
  }

  trackByIndex(index, item) {
    return index;
  }
  isNoteExist(note: string): boolean {
      return StringHelper.isAvailable(note);
  }
}
