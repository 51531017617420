<div [formGroup]="formGroup"
     class="control control--input control--checkbox"
     [hidden]="hidden">
  <p-checkbox binary="true"
              [label]="model.label"
              [ngClass]="classes"
              (onChange)="onChange.emit($event); onInput.emit($event);"
              [formControl]="formGroup.controls[model.key]"></p-checkbox>
</div>

