import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";

@Component({
  selector: "app-chart-change-action-grid",
  templateUrl: "./chart-change-action-grid.component.html",
  styleUrls: ["../project-config.component.scss", "./chart-change-action-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartChangeActionGridComponent implements OnInit {

  // This variable is used to store desired disabled measures in the chart action grid. i.e. ["CBP", "BPD", "HBD", "APH", "HPCMI"];
  private disabledMeasureCodes = [];

  gridConfiguration: GridConfiguration;
  @Input() chartChangeActions: any[];
  @ViewChild("compliantColumn", { static: true }) compliantColumn: TemplateRef<any>;
  @ViewChild("nonCompliantColumn", { static: true }) nonCompliantColumn: TemplateRef<any>;
  @ViewChild("partiallyCompliantColumn", { static: true }) partiallyCompliantColumn: TemplateRef<any>;
  @ViewChild("nonCompliantExlusionColumn", { static: true }) nonCompliantExlusionColumn: TemplateRef<any>;


  ngOnInit(): void {
    this.buildGridConfiguration();
  }

  isDisabledMeasureCode(measureCode: string): boolean {
    return this.disabledMeasureCodes.includes(measureCode);
  }

  private buildGridConfiguration(): void {
    const chartChangeActionGridColumns = [
      new GridColumnDefinition({ header: "MEASURE", field: "measureCode", width: "70px" }),
      new GridColumnDefinition({ header: "COMPLIANT", template: this.compliantColumn, isSortableColumn: false, width: "80px", alignment: "center" }),
      new GridColumnDefinition({ header: "PARTIAL COMPLIANT", template: this.partiallyCompliantColumn, isSortableColumn: false, width: "110px", alignment: "center" }),
      new GridColumnDefinition({ header: "NON-COMPLIANT", template: this.nonCompliantColumn, isSortableColumn: false, width: "100px", alignment: "center" }),
      new GridColumnDefinition({ header: "EXCLUSION", template: this.nonCompliantExlusionColumn, isSortableColumn: false, width: "70px", alignment: "center" }),
    ];

    this.gridConfiguration = new GridConfiguration({
      columns: chartChangeActionGridColumns,
      showActionColumn: false,
      showMenu: false,
      showSelectionControl: false,
      hasPagination: false,
    });
  }
}
