export class Evidence {
    diagnoses: any;
    signs: any;
    symptoms: any;
    medications: any;

    dosEvidences: any;
    providerEvidences: any;

    memberNameEvidences: any;
    memberDOBEvidences: any;
    memberGenderEvidences: any;

    constructor(options) {

      this.diagnoses = options?.diagnoses;
      this.signs = options?.signs;
      this.symptoms = options?.symptoms;
      this.medications = options?.medications;

      this.dosEvidences = options?.dosEvidences;
      this.providerEvidences = options?.providerEvidences;

      this.memberNameEvidences = options?.memberNameEvidences;
      this.memberDOBEvidences = options?.memberDOBEvidences;
      this.memberGenderEvidences = options?.memberGenderEvidences;

    }
}
