import { LookerDashboardType } from "../looker-dashboard-type.enum";

export class AnalyticsHedisModel {
  readonly filters?: string[];
  readonly dashboardType?: LookerDashboardType;
  readonly hideFilters?: string[];

  constructor(options: Partial<AnalyticsHedisModel> = {}) {
    this.filters = options.filters;
    this.dashboardType = options.dashboardType;
    this.hideFilters = options.hideFilters;
  }
}
