import { StringHelper } from "../../utilities/contracts/string-helper";

export class ActionButton {
  name: string;
  action: () => void;


  constructor(options: ActionButton) {
    StringHelper.throwIsAvailableError(options.name, "name");
    if (typeof options.action !== "function") {
      throw new Error(`The property 'action' must be a function.`);
    }

    this.name = StringHelper.clean(options.name);
    this.action = options.action;
  }
}
