import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, LowerCasePipe, PercentPipe, UpperCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { PhoneNumberPipe } from "../pipes/phone.pipe";
import { GridPipeName } from "./grid-pipe.enum";
import { GridColumnDefinition } from "./models/grid-column-definition.model";
import { GridConfiguration } from "./models/grid-configuration.model";
import { GridPipe } from "./models/grid-pipe.model";

@Injectable()
export class GridService {
  private pipes = {
    currency: new GridPipe(new CurrencyPipe("en")),
    date: new GridPipe(new DatePipe("en")),
    decimal: new GridPipe(new DecimalPipe("en")),
    json: new GridPipe(new JsonPipe(), false),
    lowercase: new GridPipe(new LowerCasePipe(), false),
    percent: new GridPipe(new PercentPipe("en"), false),
    // slice: new GridPipe(new SlicePipe()),
    uppercase: new GridPipe(new UpperCasePipe(), false),
    phoneNumber: new GridPipe(new PhoneNumberPipe()),
  };

  generateLink(column: GridColumnDefinition, row: any) {
    return this.contructRoute(column, row);

  }

  getSelectedRows(selectMode: string, selectedRows: any[]) {
    // ngprime will convert the selectedRows array to a single object if in single selection mode.
    // But we want to expose array publicly so caller does not have to call different methods for single and multiple selection modes.
    // so we will always return array
    if (selectMode === "single") {
      const holdRow = selectedRows;
      const tempRows = [];
      tempRows.push(holdRow);

      return tempRows;
    }

    return selectedRows;
  }

  usePipe(data: string, pipeType: GridPipeName, format = "", timeZone = "") {
    const pipeName = pipeType.toString();
    const pipe = this.pipes[pipeName] as GridPipe;

    return pipe.transform(data, format, timeZone);
  }

  getSortOptions(columns: GridColumnDefinition[]): SelectableInput[] {
    return columns
      .filter(column => column.show && column.isSortableColumn)
      .reduce(this.createSortingOptions, []);
  }

  private createSortingOptions = (options, column) => {
    options.push(new SelectableInput({
      text: `${column.header} (asc)`,
      value: `${column.header} (asc)`,
      extra: {
        field: column.field,
        isAscendingMultiplier: 1,
      },
    }));
    options.push(new SelectableInput({
      text: `${column.header} (desc)`,
      value: `${column.header} (desc)`,
      extra: {
        field: column.field,
        isAscendingMultiplier: -1,
      },
    }));
    return options;
  }

  // TODO: Obsolete - delete
  getSortingFieldsObsolete(columns: GridColumnDefinition[]) {
    return columns.filter(value => value.show && value.isSortableColumn)
      .map(x => x.header);
  }

  getSortingOptions(sortingField, isSortByDesc, pageSize) {
    const event = {
      sortField: sortingField,
      sortDirection: isSortByDesc ? "desc" : "asc",
      start: 1,
      end: pageSize + 1,
    };

    return event;
  }

  private contructRoute(column: GridColumnDefinition, row: any) {
    const parameters = column.routeUrl.split("/");

    const routePath = parameters.reduce((path, parameter) => {
      if (parameter === "") {
        return path;
      } else if (parameter.charAt(0) === ":") {
        const paramField = parameter.slice(1);
        return `${path}${row[paramField]}/`;
      } else {
        return `${path}${parameter}/`;
      }
    },                                  "/");

    return routePath.charAt(routePath.length - 1) === "/"
      ? routePath.substring(0, routePath.length - 1)
      : routePath;
  }

  getPageBySelectedRowIndex({ pageSize }: GridConfiguration, index: number): number {
    const firstRow = Math.floor(index / pageSize) * pageSize;
    return firstRow;
  }
}
