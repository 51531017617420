import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconModule } from "../icon/icon.module";
import { TimelineDetailDayComponent } from "./timeline-detail/timeline-detail-day.component";
import { TimelineDetailItemComponent } from "./timeline-detail/timeline-detail-item.component";
import { TimelineDetailComponent } from "./timeline-detail/timeline-detail.component";
import { TimelineItemComponent } from "./timeline-item.component";
import { TimelineComponent } from "./timeline.component";

@NgModule({
  declarations: [
    TimelineComponent,
    TimelineItemComponent,
    TimelineDetailComponent,
    TimelineDetailItemComponent,
    TimelineDetailDayComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
  ],
  exports: [
    TimelineComponent,
    TimelineDetailComponent,
  ],
})
export class TimelineModule { }
