import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { AuthService } from "../../../../auth/auth.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../core/storage/session.service";
import { UserModel } from "../../../../core/user/user-model";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { AddressGridComponent } from "../../../../shared/address-grid/address-grid.component";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { ListItem } from "../../../../shared/list/list-item";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StatesListService } from "../../../api/states-list/states-list.service";
import { DASHBOARD_MY_ADDRESS_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { CallType } from "../../retrieval/call-flow/call-type.enum";
import { DirectoryUserRole } from "../../retrieval/directory-user-role";
import { DocumentSourceType } from "../../retrieval/retrieval-document-source-type.enum";
import { AddressSearchRequest } from "./address-search-request.model";
import { FunctionalRole } from "./functional-role.enum";
import { RetrievalService } from "./retrieval.service";

@Component({
  selector: "app-retrieval",
  templateUrl: "./retrieval.component.html",
  styleUrls: ["./retrieval.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalComponent implements OnInit {
  // inputs for address-grid
  @ViewChild(AddressGridComponent, { static: true }) addressGridComponent: AddressGridComponent;
  url = `${this.baseApiUrl}retrieval/list`;
  viewAttributeId = DASHBOARD_MY_ADDRESS_GRID.attributeId;
  stateName = DASHBOARD_MY_ADDRESS_GRID.attributeCode;
  additionalFilters: GridFilter[] = [];
  additionalColumns: GridColumnDefinition[] = [];

  readonly AUTHENTICATION_KEY = "authentication";
  headerStatistics = List<ListItem>();

  roleId: any[] = [];
  roleIdCount = 0;
  isRoleModalVisible = false;
  isInComingModalVisible = false;
  roleList: SelectableInput[];
  isGetNextDisabled = true;
  isRequestAdditionalWorkEnabled = true;
  selectedUser: SelectableInput;
  refreshGrid = new EventEmitter<null>(true);
  userId: number;
  userModel: UserModel;

  outboundCommunicationDataOptions: any;

  readonly formGroupAddressSearch: FormGroup;
  retrievalLocationId: Textbox;
  address1: Textbox;
  address2: Textbox;
  city: Textbox;
  currentState: any;
  zip: Textbox;
  groupName: Textbox;
  contact: Textbox;
  memberFirstName: Textbox;
  memberLastName: Textbox;
  memberDateOfBirth: Textbox;
  addressSearchModel: AddressSearchRequest;
  noAddressSearchResultsFound = false;

  addressSearchGridConfiguration = new GridConfiguration();
  addressSearchData: any[] = [];
  addressGridselection: any;
  isGotoAddressDetailDisabled = true;
  roleInput: Dropdown;
  stateInput: Dropdown;
  retrievalRoleFormGroup: FormGroup;
  aidGridFilterForm: FormGroup;
  showAidsWithOpenChasesOnly: Checkbox;

  get isUserEmployeeRole(): boolean {
    const hasEmployeeRole = this.userModel.userRoleModels
      .some(role => role.roleGroupName.toLowerCase() === "employee");

    return hasEmployeeRole;
  }

  get isSelectionModeMultiple(): boolean {
    return !this.isUserEmployeeRole;
    }

  get hasClientLeadRole(): boolean {
      return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.ClientLead) > -1;
    }

  get hasEMREmployeeRole(): boolean {
      return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.EMREmployee) > -1;
    }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private retrievalService: RetrievalService,
    private readonly formService: FormService,
    private stateService: StatesListService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private readonly session: SessionService,
    private messagingService: MessagingService,
    private route: ActivatedRoute,
    private readonly authService: AuthService
  ) {
    this.createRetrievalFormGroup();
    this.rolelistSet();

    this.retrievalLocationId = new Textbox({
      key: "retrievalLocationId",
      label: "Address ID",
    });

    this.address1 = new Textbox({
      key: "address1",
      label: "Street1",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "address1 name can not be more than 50 characters.",
      },
    });

    this.address2 = new Textbox({
      key: "address2",
      label: "Street2",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxLength: "address2 name can not be more than 50 characters.",
      },
    });

    this.city = new Textbox({
      key: "city",
      label: "City",
      placeholder: "Cityname",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxLength: "city name can not be more than 50 characters.",
      },
    });

    this.zip = new Textbox({
      key: "postalCode",
      label: "Zip",
      validators: [Validators.minLength(5), Validators.maxLength(10)],
      errorMessages: {
        minLength: "city name can not be less than 5 characters.",
        maxLength: "city name can not be more than 10 characters.",
      },
    });

    this.groupName = new Textbox({
      key: "group",
      label: "Group Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Email name can not be more than 50 characters.",
      },
    });

    this.contact = new Textbox({
      key: "contactData",
      label: "Contact",
    });

    this.memberFirstName = new Textbox({
      key: "memberFirstName",
      label: "Member First Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxLength: "Member First Name can not be more than 50 characters.",
      },
    });

    this.memberLastName = new Textbox({
      key: "memberLastName",
      label: "Member Last Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxLength: "Member Last Name can not be more than 50 characters.",
      },
    });

    this.memberDateOfBirth = new Textbox({
      key: "memberDateOfBirth",
      label: "Member Date of Birth",
      validators: [Validators.maxLength(10)],
      errorMessages: {
        maxLength: "Member Last Name can not be more than 10 characters.",
      },
    });

    this.stateInput =
        new Dropdown({
          key: "state",
          label: "State",
          placeholder: "Select State",
          appendTo: "body",
    });

    this.formGroupAddressSearch = this.formService.createFormGroup([
      this.retrievalLocationId,
      this.address1,
      this.address2,
      this.city,
      this.zip,
      this.groupName,
      this.contact,
      this.memberFirstName,
      this.memberLastName,
      this.memberDateOfBirth,
      this.stateInput,
    ]);

    this.addressSearchGridConfiguration.columns = [
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID" }),
      new GridColumnDefinition({ field: "documentSourceTypeName", header: "Source", show: false }),
      new GridColumnDefinition({ field: "address", header: "Address" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
    ];

    this.addressSearchGridConfiguration.pageSize = 5;
    this.addressSearchGridConfiguration.pageSizeOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
    this.addressSearchGridConfiguration.selectionMode = "single";
    this.addressSearchGridConfiguration.showActionColumn = false;
  }

  rolelistSet(): void {

      this.roleList = [
          new SelectableInput({ text: "Select Role", value: null }),
    ];
      const user = this.session.get<any>(this.AUTHENTICATION_KEY, {});
      this.roleId = user.functionalRoleIds.filter(item => item === FunctionalRole.PSR || item === FunctionalRole.EMR || item === FunctionalRole.THIRDPARTY);
      const uniqueRoleId = this.roleId.filter((x, i, a) => x && a.indexOf(x) === i);
      this.roleIdCount = uniqueRoleId.length;
      for (const id of uniqueRoleId) {
      switch (id) {
          case FunctionalRole.PSR: {
              this.roleList.push(new SelectableInput({ text: "PSR", value: DocumentSourceType.PSR }));
              break;
        }
          case FunctionalRole.EMR: {
              this.roleList.push(new SelectableInput({ text: "EMR", value: DocumentSourceType.EMR }));
              break;
          }
          case FunctionalRole.THIRDPARTY: {
              this.roleList.push(new SelectableInput({ text: "3rd Party", value: DocumentSourceType.THIRDPARTY }));
              break;
          }
        default: {
          break;
        }
      }
    }
      if (this.roleIdCount === 1) {
        this.retrievalRoleFormGroup.get(this.roleInput.key).setValue(Number(this.roleList[1].value));
    }
      this.roleInput = new Dropdown({ ...this.roleInput, options: [...this.roleList] } as any);
  }

  ngOnInit() {
    this.getStatisticsData();
    this.getStateListForAddress();
    this.userModel = this.route.snapshot.data.userModel;
    this.createAddressGrid();

    this.showAidsWithOpenChasesOnly = new Checkbox({
      key: "showAidsWithOpenChasesOnly",
      label: "Only Show AIDs with Open Chases",
      value: true,
    });

    this.aidGridFilterForm = this.formService.createFormGroup([this.showAidsWithOpenChasesOnly]);

  }

  getStatisticsData(): void {
    this.retrievalService.getStatisticsData().subscribe(result => {
      this.headerStatistics = this.assignAndNotify(result);
    });
  }

  addressSearchReset(): void {
    this.formGroupAddressSearch.reset();
    this.addressSearchData = [];
    this.noAddressSearchResultsFound = false;
  }


  getNextAddress(): void {
    this.isRequestAdditionalWorkEnabled = false;
    this.retrievalService.getNextAddressId(+this.selectedRole)
      .subscribe((AddressId: number) => {
        if (AddressId !== 0 && AddressId !== null) {
          this.router.navigate(["retrieval", "addressdetail", AddressId]);
        } else {
          this.messagingService.showToast("No Additional Work found to Assign.", SeverityType.INFO);
          this.isRequestAdditionalWorkEnabled = true;
          this.changeDetector.markForCheck();
        }
      });
  }

  onSelectType(event) {
    this.isGetNextDisabled = (event === null || undefined);
  }

  findAddresses(): void {
    this.isGotoAddressDetailDisabled = true;
    this.addressGridselection = null;

    this.addressSearchModel = new AddressSearchRequest({
      retrievalLocationId: this.formGroupAddressSearch.get("retrievalLocationId").value,
      address1: this.formGroupAddressSearch.get("address1").value,
      address2: this.formGroupAddressSearch.get("address2").value,
      getAidWithOpenChaseOnly: this.aidGridFilterForm.get(this.showAidsWithOpenChasesOnly.key).value,
      addressCity: this.formGroupAddressSearch.get("city").value,
      addressState: this.formGroupAddressSearch.get("state").value,
      addressZip: this.formGroupAddressSearch.get("postalCode").value,
      groupName: this.formGroupAddressSearch.get("group").value,
      contactData: this.formGroupAddressSearch.get("contactData").value,
      memberFirstName: this.formGroupAddressSearch.get("memberFirstName").value,
      memberLastName: this.formGroupAddressSearch.get("memberLastName").value,
      memberDateOfBirth: this.formGroupAddressSearch.get("memberDateOfBirth").value,
    });

    this.retrievalService
      .addressSearch(this.addressSearchModel)
      .subscribe(items => {
        this.addressSearchData = items as any;
        this.noAddressSearchResultsFound = items?.length === 0;
        this.changeDetector.markForCheck();
      });

  }

  private getStateListForAddress(): void {
    this.stateService.getStateList()
      .subscribe(statelist => {
        this.stateInput = new Dropdown({ ...this.stateInput, options: statelist } as any);
        this.changeDetector.markForCheck();
      });
  }

  onSelectState(event) {
    if (event) {
      this.formGroupAddressSearch.get(this.stateInput.key).setValue(event.value);
    }
  }

  selectAddress(event) {
    this.isGotoAddressDetailDisabled = (event !== null && event !== undefined) ? false : true;
  }

  gotoAddressDetail() {
    const selectedAddress = this.addressGridselection;
    if (selectedAddress != null) {

      let documentSource = "";

      switch (selectedAddress.documentSourceTypeName) {
        case "PSR": {
          documentSource = "address";
          break;
        }

        case "EMR": {
          documentSource = "emr";
          break;
        }

        case "Field Tech": {
          documentSource = "ft";
          break;
        }

        default: {
          break;
        }

      }

      this.router.navigateByUrl(`retrieval/addressdetail/${selectedAddress.masterDocumentSourceId}/call/${CallType.Incoming}`);
    }
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  private createAddressGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
        value: this.userModel.userId.toString(),
        show: false,
      }),
    ];

    this.additionalColumns = [
      new GridColumnDefinition({
        field: "assignedTo",
        header: "Assigned To",
        show: false,
      }),
    ];
  }

  get selectedState(): number {
    return NumberHelper.isGreaterThan(this.formGroupAddressSearch.get(this.stateInput.key).value, 0) ?
    Number(this.formGroupAddressSearch.get(this.stateInput.key).value) : null;
  }

  createRetrievalFormGroup(): void {
    this.roleInput =
        new Dropdown({
          key: "roles",
          label: "Role",
          placeholder: "Select Role",
          appendTo: "body",
    });
    this.retrievalRoleFormGroup = this.formService.createFormGroup([this.roleInput]);
  }

  get selectedRole(): number {
    return Number(this.retrievalRoleFormGroup.get(this.roleInput.key).value);
  }

  show() {
    this.aidGridFilterForm.get(this.showAidsWithOpenChasesOnly.key).setValue(true);
    this.changeDetector.markForCheck();
    this.isInComingModalVisible = true;
  }

  close() {
    this.isInComingModalVisible = false;
    this.changeDetector.markForCheck();
  }

}
