import { IAutoMapper } from "../interfaces";

export const mapUserModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "UserModel")
    .forMember("userId", o => o.userId)
    .forMember("organizationId", o => o.organizationId)
    .forMember("firstName", o => o.firstName)
    .forMember("lastName", o => o.lastName)
    .forMember("loginName", o => o.loginName)
    .forMember("backgroundCheckRequested", o => o.backgroundCheckRequested)
    .forMember("paymentSetupRequested", o => o.paymentSetupRequested)
    .forMember("email", o => o.email)
    .forMember("phone", o => o.phone)
    .forMember("status", o => o.status)
    .forMember("coRetreivalOrganization", o => o.coRetreivalOrganization)
    .forMember("userRoleModels", o => {
      const isArray = Array.isArray(o.userRoleModels) && o.userRoleModels.length > 0;

      return isArray ? automapper.mapMany("default", "UserRoleModel", o.userRoleModels) : [];
    })
    .forMember("defaultRole", o => o.defaultRole)
    .forMember("primaryUserRoleId", o => o.primaryUserRoleId)
    .forMember("address1", o => o.address1)
    .forMember("address2", o => o.address2)
    .forMember("city", o => o.city)
    .forMember("state", o => o.state)
    .forMember("postalCode", o => o.postalCode)
    .forMember("userAttributes", o => {
      const isArray = Array.isArray(o.userAttributes) && o.userAttributes.length > 0;

      return isArray ? automapper.mapMany("default", "UserAttributeModel", o.userAttributes) : [];
    })
    .forMember("userMeasure", o => o.userMeasure)
    .forMember("isOrganizationUser", o => o.isOrganizationUser)
    .forMember("clinicianType", o => o.clinicianType)
    .forMember("directoryUserType", o => o.directoryUserType);
};
