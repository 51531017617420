import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ChartModel } from "../model/chart.model";
import { DataModel } from "../model/data.model";
import { LabelModel } from "../model/label.model";

@Component({
  selector: "app-kpi",
  templateUrl: "./kpi.component.html",
  styleUrls: ["./kpi.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiComponent implements OnChanges {

  @Input() cModel: ChartModel;
  @Input() cDataLabels: LabelModel;
  @Input() cDataModel: DataModel[];
  @Input() cOptions: any;
  filters: any[];

  data: any;
  options: any;
  url: string;
  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = this.cOptions;
    this.generateKpiChart(this.cDataLabels.labels, this.cDataModel, this.cModel);
  }

  private generateKpiChart(dataLabels: string[], dataModel: DataModel[], chartModel: ChartModel) {
    this.data = {
      labels: dataLabels,
      datasets: dataModel,
      chart: chartModel,
    };
  }

  selectData(event) {
    this.filters = [];

    switch (this.data.chart.chartHeader) {

      case "PEND DISTRIBUTION BY PEND CODE":
        this.filters.push({ key: "PendCode", value: event.element._model.label, text: event.element._model.label });
        window.sessionStorage.setItem("pendFilter", JSON.stringify(this.filters));
        this.url = this.data.datasets[event.element._datasetIndex].url[0];
        break;

      case "PEND DISTRIBUTION BY PROJECT":
        const projectId = this.data.datasets[event.element._datasetIndex].parameters[event.element._index];
        this.filters.push({ key: "ProjectId", value: projectId.toString(), text: event.element._model.label });
        window.sessionStorage.setItem("pendFilter", JSON.stringify(this.filters));
        this.url = this.data.datasets[event.element._datasetIndex].url[0];
        break;

      case "PENDS":
        this.url = this.data.datasets[event.element._datasetIndex].url[event.element._index];
        break;

      case "CALLS AND FAXES":
        const logIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("callFaxLogIds", JSON.stringify(logIds));
        this.url = this.data.datasets[0].url[0];
        break;

      case "SCHEDULING STATUS":
        const statusIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("OrganizationStatisticIds", statusIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      case "RETRIEVAL ACTIVITY":
        const projectIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("ProjectStatisticIds", projectIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      case "CHASE STATUS":
        const chaseStatusIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("ProjectStatisticIds", chaseStatusIds[0]);
        this.url = this.data.datasets[event.element._datasetIndex].url[0];
        break;

      case "CONTACT ACTIVITY":
        const contactlogIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("ContactActivityIds", contactlogIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      case "RETRIEVAL PENDS":
        const pendslogIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("PendStatisticIds", pendslogIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      case "NUMERATOR HITS":
        const numeratorIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("ProjectStatisticIds", numeratorIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      case "RETRIEVAL PROJECTIONS":
        const projectionIds = this.data.datasets[0].parameters[event.element._index][event.element._datasetIndex];
        window.sessionStorage.setItem("ProjectStatisticIds", projectionIds[0]);
        this.url = this.data.datasets[0].url[0];
        break;

      default:
        break;
    }

    if (!StringHelper.isAvailable(this.url)) {

      switch (this.data.labels[event.element._index]) {
        case "Retrieved":
          this.filters.push({ key: "Statuses", value: "4,5,6,7,8", text: "Chart QA, Abstraction,Overread,Overread2, Delivery" });
          window.sessionStorage.setItem("chaseFilter", JSON.stringify(this.filters));
          this.url = this.data.datasets[event.element._datasetIndex].url[event.element._index];
          break;
        case "Not Retrieved":
          this.filters.push({ key: "Statuses", value: "2,3", text: "Chart collection, Waiting for chart" });
          window.sessionStorage.setItem("chaseFilter", JSON.stringify(this.filters));
          this.url = this.data.datasets[event.element._datasetIndex].url[event.element._index];
          break;
        case "Pended":
          this.url = this.data.datasets[event.element._datasetIndex].url[event.element._index];
          break;

        default:
          break;
      }
    }

    localStorage.setItem("key", "");
    return this.router.navigate([this.url]);
  }
}
