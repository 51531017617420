import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { AuditDetail } from "./audit-detail.model";

export class AuditData {
  coderAuditDetail?: AuditDetail[];
  entityTypeName?: string;

  constructor(options: Partial<AuditData> = {}) {
    this.coderAuditDetail = ArrayHelper.clean(options.coderAuditDetail);
    this.entityTypeName = options.entityTypeName;
  }
}
