<div container-header class="myscore-container__header">
  <app-detail-header-template>
    <div class="submenu-content" bottom-left>
      <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
    </div>
  </app-detail-header-template>
</div>
<div>
  <app-iframe [visible]="isVisible" [load]="true" iframeSrc="{{lookerUrl}}" iframeWidth="99%"></app-iframe>
</div>


