export class ProviderDownloadHeader {
    readonly display: string;
    readonly column: string;
    private constructor(display: string, column: string) {
        this.display = display;
        this.column = column;
    }
    static create(display: string, column: string): ProviderDownloadHeader {
        return new ProviderDownloadHeader(display, column);
    }
}

export enum BulkUploadStatus {
    Failure,
    Partial,
    Success,
}
