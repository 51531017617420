import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonModule } from "../button/button.module";
import { IconModule } from "../icon/icon.module";
import { PanelModule } from "../panel/panel.module";
import { ContactHistoryNoteViewerComponent } from "./contact-history-note.component";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    PanelModule,
  ],
  declarations: [
    ContactHistoryNoteViewerComponent,
  ],
  exports: [
    ContactHistoryNoteViewerComponent,
  ],
})
export class ContactHistoryNoteModule { }
