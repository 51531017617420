<member-risk-read-only></member-risk-read-only>
<div class="risk-data-entry">
  <member-risk-encounter-data-entry *ngIf="isEncounterAvailable" (isReadOnly)="handleReadOnly($event)"></member-risk-encounter-data-entry>
  <div class="bottom-space">
    <member-risk-diagnosis-v2></member-risk-diagnosis-v2>
    <member-risk-eve-clinical-terms *ngIf="hasClinicalTerms" (pageChange)="onPageChange($event)" [clinicalTerms]="clinicalTerms"></member-risk-eve-clinical-terms>
  </div>
  <div class="floating-button" *ngIf="!isReadOnly">
    <div class="floating-button--icon">
      <app-icon iconStyle="fas" iconName="plus"></app-icon>
      <div class="floating-button--tooltip">
        <div class="floating-button--options" (click)="addEncounter()">Add Encounter</div>
        <div class="floating-button--options" (click)="addDiagnosis()" *ngIf="isSelectedEncounterValid">Add Diagnosis</div>
      </div>
    </div>
  </div>
</div>