import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { ParameterService } from "../../core/navigation/parameter.service";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { AddressDetailService } from "../../platform/modules/retrieval/address-detail/address-detail.service";
import { AddressSearchService } from "../../platform/modules/retrieval/address-search/address-search.service";
import { GridFilter } from "../grid/models/grid-filter.model";
import { NationalProviderGridComponent } from "../national-provider-grid/national-provider-grid.component";
import { NationalProviderGridService } from "../national-provider-grid/national-provider-grid.service";
import { MasterProvider } from "../national-provider-grid/national-provider.model";
import { ModalComponent } from "../panel/modal/modal.component";

@Component({
  selector: "app-provider-search-tool",
  templateUrl: "./provider-search-tool.component.html",
  styleUrls: ["./provider-search-tool.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderSearchToolComponent extends ModalComponent implements OnInit, OnDestroy  {
  @ViewChild(NationalProviderGridComponent, { static: false }) nationalProviderGridComponent: NationalProviderGridComponent;
  @Input() callProvider = false;
  additionalFilters: GridFilter[] = [];
  providerSearchText: string;
  private sink = new SubSink();
  lastSearchText = "";
  visibleProviderSearchModal = false;
  private currentAddressId: number;

  constructor(
    private readonly nationalProviderGridService: NationalProviderGridService,
    private readonly addressDetailService: AddressDetailService,
    private readonly addressSearchService: AddressSearchService,
    private readonly route: ActivatedRoute,
    private parameterService: ParameterService,
    private readonly changeDetector: ChangeDetectorRef
  ) { super(); }

  ngOnInit(): void {
    this.sink.add(
      this.route.paramMap.subscribe(params => {
        this.currentAddressId = this.parameterService.getNumberNormal("addressId", null);
      }),

      this.addressDetailService.aidChanged
        .subscribe(() => {
          if (this.currentAddressId !== this.nationalProviderGridService.lastUsedAid) {
            this.nationalProviderGridService.lastUsedAid = this.currentAddressId;
            this.nationalProviderGridService.aidChanged = true;
            this.nationalProviderGridService.providerSearched = false;
          }
        })
      );
    this.createFilter();
  }

  private createFilter(): void {

    this.additionalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "globalSearchText",
        name: "Name",
        value: this.providerSearchText,
        validators: [Validators.minLength(3)],

      }),
    ];
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  showProviderSearchTool(): void {
    if (this.nationalProviderGridService.aidChanged && !this.nationalProviderGridService.providerSearched) {
      this.providerSearchText = "";
      this.nationalProviderGridService.data = [];
      this.nationalProviderGridService.updateDom.next();
    } else {
      this.providerSearchText = this.nationalProviderGridService.providerSearchText;
    }

    this.visible = true;
    this.visibleProviderSearchModal = true;
    this.show();
    this.changeDetector.markForCheck();
  }

  hideProviderSearchTool(): void {
    if (this.nationalProviderGridService.aidChanged && !this.nationalProviderGridService.providerSearched) {
      this.providerSearchText = "";
      this.nationalProviderGridService.data = [];
      this.nationalProviderGridService.updateDom.next();
    }
    this.visible = false;
    this.visibleProviderSearchModal = false;
    this.hide();
    this.changeDetector.markForCheck();

  }

  cancel(): void {
    this.onCancel.emit();
    this.hideProviderSearchTool();
  }

  search(event: MouseEvent | null): void {
    if (event != null) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.additionalFilters[0].value = this.providerSearchText;
    this.nationalProviderGridComponent.refreshGrid();
  }

  selectProvider(provider: MasterProvider): void {
    this.addressSearchService.populateAddressSearch(provider);
  }

  initiateCall(provider: MasterProvider): void {
    this.addressDetailService.initiateCallProvider(provider);
    this.hideProviderSearchTool();
  }
}
