import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../../auth/auth.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { InternalPendsDetailHeaderService } from "../internal-pends-detail-header/internal-pends-detail-header.service";

@Component({
  selector: "app-internal-pends-detail-header",
  templateUrl: "./internal-pends-detail-header.component.html",
  styleUrls: ["./internal-pends-detail-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsDetailHeaderComponent implements OnInit {
  pendId: number;
  iconName = "chevron-down";
  menuItems = List<MenuItem>();
  memberItems = List<ListItem>();
  pendCode: string;
  pendCodeName: string;
  owner: string;
  display = false;
  isChangeOwnerView = false;
  isStatusChangeView = false;
  AssignedTo: SelectItem[];
  selectedAssignedTo: string;
  NotesText: string;
  status: SelectItem[];
  selectedStatus: string;
  @Output() formClose = new EventEmitter();
  id: string;
  subscription: Subscription;
  btnLabel = "REQUEST STATUS CHANGE";
  isClientRole = false;
  disabled = true;
  pendStatusId: string;
  // Update pend merge
  isUpdatePendView = false;
  isClientUser = false;


  constructor(
    private service: InternalPendsDetailHeaderService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.pendId = this.service.getPendGdFromPath();
  }

  ngOnInit() {
    this.isClientUser = this.authService.user.directoryRoleIds.indexOf(31) > -1;

    this.route.paramMap.subscribe(params => {
      this.pendId = +params.get("pendGd");

      this.subscription = this.service.reset.subscribe(id => {
        this.updatePendHeaderInfo(id);
      }
      );

      this.service
        .getPendHeaderInfo(this.pendId)
        .subscribe(this.assignAndNotifyHeaderInfo);

      this.service
        .getMenuItems()
        .subscribe(items => this.menuItems = this.assignAndNotifyMenuItems(items));
    });

  }

  updatePendHeaderInfo(id) {
    this.service
      .getPendHeaderInfo(this.pendId)
      .subscribe(this.assignAndNotifyHeaderInfo);
  }

  get pendText() {
    return `Pend Id ${this.pendId}`;
  }

  assignAndNotifyMenuItems = (data: MenuItem[]): List<MenuItem> => {
    this.changeDetector.markForCheck();
    this.menuItems = List(data);

    return this.menuItems;
  }

  assignAndNotifyHeaderInfo = (data: ListItem[]): void => {
    const Item = data.splice(0, 5);
    this.memberItems = List(data);
    this.pendCode = Item[1].value;
    this.pendCodeName = Item[2].value;
    this.owner = Item[3].value;
    this.pendStatusId = Item[4].value;

    if (this.isClientUser) {
      switch (this.pendStatusId) {
        case "New":
          this.disabled = false;
          break;
        case "In Progress":
          this.disabled = false;
          break;
        case "Resolved":
          this.disabled = true;
          break;
        case "Closed":
          this.disabled = true;
          break;
        default:
        case "":
          this.disabled = false;
          break;
          }
    } else {

      switch (this.pendStatusId) {
        case "New":
          this.disabled = false;
          break;
        case "In Progress":
          this.disabled = false;
          break;
        case "Request to Resolve":
          this.disabled = false;
          break;
        case "Request to Close":
          this.disabled = false;
          break;
        case "Resolved":
          this.disabled = true;
          break;
        case "Closed":
          this.disabled = true;
          break;
        default:
        case "":
          this.disabled = false;
          break;

      }
    }

    this.isClientRole === true ? this.btnLabel = " REQUEST STATUS CHANGE" : this.btnLabel = "STATUS CHANGE";
    this.changeDetector.markForCheck();
  }

  changeOwnerAssignDialog() {
    this.isChangeOwnerView = true;
  }

  requestStatusChangeDialog() {
    this.isStatusChangeView = true;
  }

  reassignUser($event) {
    this.isChangeOwnerView = $event;
  }

  updateStatus($event) {
    this.isStatusChangeView = $event;
  }

  updateStatusDialog() {
    this.isStatusChangeView = true;
    this.isClientRole = false;
  }

  updatePend() {
    this.isUpdatePendView = true;
  }

  updatePendClose($event) {
    this.isUpdatePendView = $event;
  }
}
