import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { MemberValidation } from "../../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../../api/member-validation/member-validation.service";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { DATE_OF_BIRTH_VALIDATION_RESULT, GENDER_FOUND } from "../attributes";
import { ChartService } from "../chart.service";
import { DobReasonType } from "./dob-reason-type.enum";

@Component({
  selector: "member-member-dob-gen",
  templateUrl: "./member-dob-gen.component.html",
  styleUrls: ["./member-dob-gen.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberDobGenComponent implements OnInit {
  private sink = new SubSink();

  @Input() set enabled(value: boolean) {
    this.setDisabled(value);
    this.getDobAndGenAttributes(value);
  }
  @Input() chaseId: number;
  @Input() configuration: MemberValidation;
  @Output() onChange = new EventEmitter<DynamicFormEvent>(true);

  showMemberDob = false;

  form: FormGroup;
  memberDobReasonSaveGroup: SaveGroup;
  memberDobReasonInput: Dropdown;
  memberGenderReasonSaveGroup: SaveGroup;
  memberGenderReasonInput: Dropdown;
  memberValidationData = new MemberValidation();
  chaseDetailState: ChaseDetailState;

  constructor(
    private readonly chartService: ChartService,
    private readonly formService: FormService,
    private readonly memberValidationService: MemberValidationService,
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      })
    );
  }

  handleChange(event): void {
    if (this.form.valid) {
      this.onChange.emit(event);
    }
  }

  showMemberDobInput(event): void {
    this.showMemberDob = event.value.memberDobReason === DobReasonType.DOB_INCORRECT;
  }

  getDobAndGenAttributes(enable): void {
    if (enable) {
      this.getDobDetail();
      this.getGenderDetail();
    }
  }

  memberValidationSubmit(memberValidation: MemberValidation, showToast: boolean = false): void {
    this.memberValidationService.submit(memberValidation, WorkflowStatusDb[this.chaseDetailState.workflowStatus]).subscribe();
  }

  private get hasForm(): boolean {
    return this.form != null;
  }

  private initializeForm(): void {
    const dobOptions = [
      new SelectableInput({ text: "Yes", value: DobReasonType.YES }),
      new SelectableInput({ text: "No -DOB is Missing", value: DobReasonType.DOB_MISSING }),
      new SelectableInput({ text: "No -DOB is Incorrect", value: DobReasonType.DOB_INCORRECT }),
    ];

    const genderOptions = [
      new SelectableInput({ text: "Yes", value: "1" }),
      new SelectableInput({ text: "No", value: "0" }),
    ];

    this.memberDobReasonInput = new Dropdown({
      key: "memberDobReason",
      label: "Is DOB present/valid?",
      options: dobOptions,
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
      },
    });

    this.memberGenderReasonInput = new Dropdown({
      key: "memberGenderReason",
      label: "Is Gender Present?",
      options: genderOptions,
    });

    // SAVE GROUPS
    this.memberDobReasonSaveGroup = new SaveGroup({
      key: "memberDobReasonSaveGroup",
      isHideClearButton: true,
    });
    this.setupSaveGroups({
      saveGroup: this.memberDobReasonSaveGroup,
      inputs: [this.memberDobReasonInput],
    });

    this.memberGenderReasonSaveGroup = new SaveGroup({
      key: "memberGenderReasonSaveGroup",
      isHideClearButton: true,
    });
    this.setupSaveGroups({
      saveGroup: this.memberGenderReasonSaveGroup,
      inputs: [this.memberGenderReasonInput],
    });

    this.form = this.formService.createFormGroup([
      this.memberDobReasonSaveGroup,
      this.memberGenderReasonSaveGroup,
    ]);
  }

  private getDobDetail(): void {
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.chartService.getMemberValidationAttributeByCode(this.chaseId, DATE_OF_BIRTH_VALIDATION_RESULT.attributeCode).subscribe(dobReason => {
        if (dobReason.value) {
          this.setSaveInfo(dobReason, this.memberDobReasonInput);
          this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(dobReason.value);
        } else {
          this.form.get(this.memberDobReasonInput.getMasterKey()).setValue(DobReasonType.YES);
          dobReason.value = DobReasonType.YES;
          this.setSaveInfo(dobReason, this.memberDobReasonInput);
          this.chartService.save([this.memberDobReasonInput.saveInfo]);
        }
        this.showMemberDob = dobReason.value === DobReasonType.DOB_INCORRECT;
      });
    }
  }

  private getGenderDetail(): void {
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.chartService.getMemberValidationAttributeByCode(this.chaseId, GENDER_FOUND.attributeCode).subscribe(genderReason => {
        if (genderReason.value) {
          this.setSaveInfo(genderReason, this.memberGenderReasonInput);
          this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue(genderReason.value);
        } else {
          this.form.get(this.memberGenderReasonInput.getMasterKey()).setValue("1");
          genderReason.value = "1";
          this.setSaveInfo(genderReason, this.memberGenderReasonInput);
          this.chartService.save([this.memberDobReasonInput.saveInfo]);
        }
      });
    }
  }

  private setDisabled(enable: boolean): void {
    // HACK: Wait for the form to exist before enabling/disabling.
    if (!this.hasForm) {
      setTimeout(() => this.setDisabled(enable), 100);
      return;
    }

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (enable) {
        control.enable();
      } else {
        control.disable();
      }
    });


    this.formService.updateDom.next();
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }

  private setSaveInfo(medicalRecordDob: DynamicEntityAttribute, input): void {
    const saveInfo = { ...medicalRecordDob };
    input.saveInfo = saveInfo;
  }
}
