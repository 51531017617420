<app-collapsible-panel [startCollapsed]=true>
  <header>
    <app-button class="button" styleType="far" type="fa" [icon]="collapseIcon" classes="transparent small" (onClick)="toggle($event)"></app-button>
    <div class="filter-text">Filters ({{ filterCount }})</div>
    <app-button class="button clear-button--right" text="CLEAR" classes="transparent" (onClick)="clearAllFilters()"></app-button>
  </header>
<div
  class="chip"
  *ngFor="let filter of request; let i = index; trackBy: trackByIndex"
  (click)="delete($event, i, filter)">
  <div class="chip__item chip__item--name">
    {{ filter.label }}:&nbsp;
  </div>
  <div class="chip__item chip__item--value">
    {{ filter.value }}
  </div>
  <div class="chip__item chip__item--icon">
    <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
  </div>
</div>
</app-collapsible-panel>