import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthService } from "../../../auth/auth.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { ChipAutocomplete } from "../../../dynamic-forms/inputs/chip-autocomplete/chip-autocomplete.model";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { AddressTimelineStateService } from "../../../platform/modules/retrieval/address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../platform/modules/retrieval/address-timeline/address-timeline.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { CreateTagComponent } from "../create-tag/create-tag.component";
import { TagActionType } from "../model/tag-action-type.enum";
import { TagAddressNotes } from "../model/tag-address-notes.model";
import { TagItems } from "../model/tag-items.model";
import { TagType } from "../model/tag-type.enum";
import { TagService } from "../tag.service";
@Component({
  selector: "app-manage-tag-modal",
  templateUrl: "./manage-tag-modal.component.html",
  styleUrls: ["./manage-tag-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTagModalComponent implements OnInit {
  @ViewChild(CreateTagComponent) createTagComponent: CreateTagComponent;
  @Input() entityName: string;
  @Input() totalEntityCount: number;
  manageTagForm: FormGroup;
  tagActionInput: Dropdown;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);
  hasAddActionType = false;
  hasReplaceActionType = false;
  @Input() tagType: TagType;
  tagAction: TagActionType;
  currentTagInput: ChipAutocomplete;
  removeTagInput: ChipAutocomplete;
  tagItems: TagItems;
  hasRemoveActionType = false;
  isCreateTagTemplateVisible = false;
  hasNewReplaceActionType = false;
  value: any;
  private pVisible = false;
  tagAddressNotes: TagAddressNotes;
  manageTagNotes = "";
  @Input() masterDocumentSourceId: number;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
    this.getTagActions();

    if (!this.visible) {
      this.closing();
    }
  }

  private pSelectedData = [];
  @Input()
  get selectedData(): any[] {
    return this.pSelectedData;
  }
  set selectedData(value: any[]) {
    this.pSelectedData = value;
  }

  get selectedTag(): SelectableInput {
    if (this.manageTagForm == null) {
      return null;
    }

    return this.manageTagForm.get("currentTag").value || this.manageTagForm.get("removeTag").value;
  }
  set selectedTag(value: SelectableInput) {
    if (this.manageTagForm == null) {
      return;
    }

    this.manageTagForm.get("currentTag").setValue(value);
    this.manageTagForm.get("removeTag").setValue(value);
  }

  get selectedTagId(): number {
    if (this.selectedTag == null) {
      return null;
    }

    return +this.selectedTag.value;
  }

  get objectIds(): number[] {
    if (!ArrayHelper.isAvailable(this.selectedData)) {
      return [];
    }

    switch (this.tagType) {
      case TagType.CHASE:
        return this.selectedData.map(chase => +chase.chaseID);

      case TagType.MASTERDOCUMENTSOURCE:
        return this.selectedData.map(address => +address.masterDocumentSourceID);

      case TagType.DOCUMENT:
        return this.selectedData.map(document => +document.documentId);

      default:
        break;
    }

  }

  get selectedTagAction(): SelectableInput {
    if (this.manageTagForm == null) {
      return null;
    }

    return this.manageTagForm.get("tagAction").value;
  }
  set selectedTagAction(value: SelectableInput) {
    if (this.manageTagForm == null) {
      return;
    }

    this.manageTagForm.get("tagAction").setValue(value);
  }

  get selectedTagActionId(): number {
    if (this.selectedTagAction == null) {
      return null;
    }

    return +this.selectedTagAction.value;
  }

  get hasTagText(): boolean {
    return StringHelper.isAvailable(this.createTagComponent?.pTagText) && StringHelper.isAvailable(this.createTagComponent?.tagColor);
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly messagingService: MessagingService,
    private tagService: TagService,
    private authService: AuthService,
    private addressTimelineService: AddressTimelineService,
    private addressTimelineStateService: AddressTimelineStateService
  ) { }

  ngOnInit() {
    this.initializeTagForm();
  }


  private initializeTagForm(): void {
    this.tagActionInput = new Dropdown({
      key: "tagAction",
      label: "Tag Action",
      placeholder: "Select From List",
      appendTo: "body",
    });
    this.currentTagInput = new ChipAutocomplete({
      key: "currentTag",
      label: "Current Tag",
      placeholder: "Type Here",
    });
    this.removeTagInput = new ChipAutocomplete({
      key: "removeTag",
      label: "Tag",
      placeholder: "Type Here",
    });
    this.manageTagForm = this.formService.createFormGroup([this.tagActionInput, this.currentTagInput, this.removeTagInput]);
  }

  private getTagActions(): void {
    let result = [];
    if (this.visible) {
      result = [
        new SelectableInput({ text: "Add", value: 1 }),
        new SelectableInput({ text: "Remove", value: 2 }),
        new SelectableInput({ text: "Replace", value: 3 }),
      ];
      if (this.hasEmployeeRole) {
        result = result.slice(0, 2);
      }
      this.tagActionInput = new Dropdown({ ...this.tagActionInput, options: result } as any);
      this.changeDetector.markForCheck();
    }
  }

  private closing(): void {
    this.onHide.emit();
    this.hasAddActionType = false;
    this.hasReplaceActionType = false;
    this.hasRemoveActionType = false;
  }

  onTagConfirm(): void {
    const entityName = this.totalEntityCount > 1 ? "chases" : "chase";
    switch (this.tagAction) {
      case TagActionType.ADD:
          this.tagItems = new TagItems({
            tagId: this.createTagComponent.tagId,
            objectIds: this.objectIds,
          });
          if (NumberHelper.isGreaterThan(this.createTagComponent.tagId, 0)) {
          this.associateTags(this.tagItems);
          this.saveManageTagNotes(this.createTagComponent.pTagText, this.totalEntityCount, "");
          this.messagingService.showToast(`${this.createTagComponent.pTagText} tag has been applied to ${this.totalEntityCount} ${entityName}`, SeverityType.SUCCESS);
          this.isCreateTagTemplateVisible = false;
          this.onUpdate.emit();
        } else {
          this.createTagComponent.saveNewTag();
          this.saveManageTagNotes(this.createTagComponent.pTagText, this.totalEntityCount, "");
          this.isCreateTagTemplateVisible = false;
          this.onUpdate.emit();
        }
          this.visible = false;
          this.manageTagForm.reset();
          this.changeDetector.markForCheck();
          break;

      case TagActionType.REMOVE:
        if (this.manageTagForm.value != null) {
        this.tagService.deleteTag(this.selectedTagId, this.objectIds)
          .subscribe(result => {
            if (result) {
              this.saveManageTagNotes(this.selectedTag.text, result, "");
              this.messagingService.showToast(`${this.selectedTag.text} tag has been removed from ${result} ${entityName}`, SeverityType.SUCCESS);
              this.isCreateTagTemplateVisible = false;
              this.onUpdate.emit();
              this.visible = false;
              this.manageTagForm.reset();
              this.changeDetector.markForCheck();
            }
          });
        }
        break;

      case TagActionType.REPLACE:
        if (this.manageTagForm.value != null) {
          const newTag = this.createTagComponent;
          const replacedTagValue = this.createTagComponent.tagForm.value;
          this.tagItems = new TagItems({
            assignToTagId: replacedTagValue.manageTags.extra.tagId,
            tagId: this.selectedTagId,
            objectIds: this.objectIds,
          });
          this.tagService.replaceTags(this.tagItems)
          .subscribe(result => {
            if (NumberHelper.isGreaterThan(newTag.tagId, 0)) {
              this.saveManageTagNotes(this.selectedTag.text, result, replacedTagValue.manageTags.text);
              this.messagingService.showToast(`${this.selectedTag.text} tag has been replaced from ${result} ${entityName}`, SeverityType.SUCCESS);
              this.hasNewReplaceActionType = false;
              this.isCreateTagTemplateVisible = false;
              this.onUpdate.emit();
              this.visible = false;
              this.createTagComponent.tagForm.reset();
              this.changeDetector.markForCheck();
            } else {
              this.createTagComponent = newTag;
              const selectTagId = this.createTagComponent.tagId;
              if (!NumberHelper.isGreaterThan(selectTagId, 0)) {
                this.createTagComponent.saveNewTag();
                this.isCreateTagTemplateVisible = false;
                this.onUpdate.emit();
                this.visible = false;
                this.manageTagForm.reset();
                this.changeDetector.markForCheck();
              }
            }
          });
        }
        break;

      default:
        break;

  }
  }

  getTagActionType(data: any): void {
    switch (data.value) {
      case TagActionType.ADD:
          this.getTagsList();
          this.tagAction = TagActionType.ADD;
          this.isCreateTagTemplateVisible = true;
          this.hasAddActionType = true;
          this.hasReplaceActionType = false;
          this.hasRemoveActionType = false;
          this.hasNewReplaceActionType = false;
          this.manageTagForm.get(this.currentTagInput.key).setValue(null);
          this.manageTagForm.get(this.removeTagInput.key).setValue(null);
          break;

      case TagActionType.REMOVE:
        this.getTagsList();
        this.tagAction = TagActionType.REMOVE;
        this.hasAddActionType = false;
        this.hasReplaceActionType = false;
        this.hasRemoveActionType = true;
        this.hasNewReplaceActionType = false;
        this.manageTagForm.get(this.currentTagInput.key).setValue(null);
        break;

      case TagActionType.REPLACE:
        this.getTagsList();
        this.tagAction = TagActionType.REPLACE;
        this.hasAddActionType = false;
        this.hasNewReplaceActionType = true;
        this.hasReplaceActionType = true;
        this.hasRemoveActionType = false;
        this.manageTagForm.get(this.removeTagInput.key).setValue(null);
        break;

      default:
        break;
    }
  }

  getTagsList(): void {
    this.tagService.getTagsList(this.objectIds)
      .subscribe(data => {
        const sortedTagList = data
          .filter(e => e.text === "Suggestions")
          .concat(
            data.filter(e => e.text !== "Suggestions")
              .sort((a, b) => a.text.localeCompare(b.text))
          );
        this.value = data;
        this.currentTagInput = new ChipAutocomplete({ ...this.currentTagInput, options: sortedTagList } as any);
        this.removeTagInput = new ChipAutocomplete({ ...this.removeTagInput, options: sortedTagList } as any);
        this.changeDetector.markForCheck();
      });
  }

  cancel(): void {
    this.manageTagForm.reset();
    this.hasNewReplaceActionType = false;
    this.createTagComponent?.tagForm.reset();
  }

  private associateTags(tagItems: TagItems): void {
      this.tagService.associateTags(tagItems).subscribe(tagId => {
        if (tagId > 0) {
        this.isCreateTagTemplateVisible = false;
        this.onUpdate.emit();
        this.manageTagForm.reset();
        this.visible = false;
        this.changeDetector.markForCheck();
      }
    });
  }

  get hasEmployeeRole(): boolean {
    return !this.authService.user.isAdminRole &&
      !this.authService.user.isLeadRole &&
      !this.authService.user.isManagerRole;
  }

  get hasTagSelected(): boolean {
    switch (this.tagAction) {
      case TagActionType.ADD:
        return this.hasTagText;
      case TagActionType.REMOVE:
        return StringHelper.isAvailable(this.selectedTag?.text);
      case TagActionType.REPLACE:
        return StringHelper.isAvailable(this.selectedTag?.text) && NumberHelper.isGreaterThan(this.createTagComponent?.tagId, 0);
      default:
        return false;
    }
  }

  saveManageTagNotes(tagtext: string, totalEntityCount: number, replacedTagtext: string): void {
    const entityName = totalEntityCount > 1 ? "chases" : "chase";
    const tagName = tagtext.toUpperCase();
    switch (this.tagAction) {
      case TagActionType.ADD:
        this.manageTagNotes = `${tagName} tag has been applied to ${totalEntityCount} ${entityName}`;
        break;
      case TagActionType.REMOVE:
        this.manageTagNotes = `${tagName} tag has been removed from ${totalEntityCount} ${entityName}`;
        break;
      case TagActionType.REPLACE:
        this.manageTagNotes = `${tagName} tag has been replaced with ${replacedTagtext} from ${totalEntityCount} ${entityName}`;
        break;
      default:
        break;
    }

    this.tagAddressNotes = new TagAddressNotes({
      masterDocumentSourceId: this.masterDocumentSourceId,
      manageTagNotes: this.manageTagNotes,
    });

    this.tagService.saveManageTagNotes(this.tagAddressNotes).subscribe(() => {
      this.fetchAddressTimelineItems(this.masterDocumentSourceId);
      this.changeDetector.markForCheck();
    });
  }

  private fetchAddressTimelineItems(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

}
