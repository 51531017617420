import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserToken } from "../../auth/user-token.model";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { ServiceOrgNodeModel } from "../../platform/modules/service-org-admin/user-admin/service-org-node.model";
import { UserResetPassword } from "../../platform/modules/user/profile-info/user-resetpassword.model";
import { ListItem } from "../../shared/list/list-item";
import { SessionService } from "../storage/session.service";
import { ClientOrgModel } from "./client-org-model";
import { RoleModel } from "./role-model";
import { UserAttributeDisplayModel } from "./user-attribute-display-model";
import { UserModel } from "./user-model";
import { UserQuickOptions } from "./user-quickoptions.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  readonly userSessionName: string = "authentication";


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private readonly automapper: AutomapperService,
    private readonly session: SessionService
  ) { }


  getUserAttributesDisplay(organizationId: number): Observable<UserAttributeDisplayModel[]> {
    const url = `${this.baseApiUrl}user/attributesdisplay/list?functionalGroupName=User specialty`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "UserAttributeDisplayModel"))
    );
  }

  getClientsAndProjects(organizationId: number): Observable<ServiceOrgNodeModel> {
    const url = `${this.baseApiUrl}serviceorg/client/project/list?serviceOrgId=${organizationId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ServiceOrgNodeModel"))
    );
  }

  getUser(userId: number, status?: string): Observable<UserModel> {
    let url = `${this.baseApiUrl}user/?userId=${userId}`;

    if (status != null) {
      url += `&status=${status}`;
    }

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "UserModel"))
    );
  }

  getApplicationRoles(): Observable<RoleModel[]> {
    const url = `${this.baseApiUrl}user/applicationrole/list`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "RoleModel"))
    );
  }

  updateUser(userModel: UserModel): Observable<number> {
    const url = `${this.baseApiUrl}user`;

    return this.http
      .post(url, userModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? response.body : 0)
      );
  }

  updateUserProfile(userModel: UserModel): Observable<number> {
    const url = `${this.baseApiUrl}userprofile`;

    return this.http
      .post(url, userModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? response.body : 0)
      );
  }

  resetPassword(resetPassword: UserResetPassword): Observable<number> {
    const url = `${this.baseApiUrl}user/resetpassword`;

    return this.http
      .post(url, resetPassword, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? response.body : 0)
      );
  }

  // TODO: Currently we used map method for mapping and will change to curryMany in future.
  getDefaultRoles(): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}user/defaultRoles`;
    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  updateUserOnboarding(userModel: UserModel): Observable<number> {
    const url = `${this.baseApiUrl}user/useronboarding`;

    return this.http
      .post(url, userModel, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? response.body : 0)
      );
  }

  getUserStats(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}user/stats`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getUserToken(): UserToken {
    return this.session.get(this.userSessionName, new UserToken());
  }

  getGroupNames(): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}user/group/list`;
    return this.http.get(url).pipe(map((response: any) => response as SelectItem[]));
  }

  getClients(): Observable<ClientOrgModel[]> {
    const url = `${this.baseApiUrl}serviceorg/client/list`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ClientOrgModel")));
  }

  updateUserStatus(rowData: any): Observable<number> {
    const url = `${this.baseApiUrl}user/status`;

    return this.http
      .post(url, rowData, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => response.ok ? response.body : 0)
      );
  }

  getOrganizationUsers(): Observable<UserModel[]> {
    const url = `${this.baseApiUrl}user/organization-users`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "UserModel"))
    );
  }

  userAdminResetPassword(resetPassword: UserResetPassword): Observable<boolean> {
    const url = `${this.baseApiUrl}user/admin/resetpassword`;

    return this.http
      .post(url, resetPassword, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok ? response.body : false)
      );
  }

  getUsersWithQuickOptions(userQuickOptions?: UserQuickOptions): Observable<UserModel[]> {
    const quickOptions = (userQuickOptions == null) ?
      new UserQuickOptions({ includeMe: true, includeUnassigned: true }) : userQuickOptions;
    const url = `${this.baseApiUrl}users`;
    return this.http.post(url, quickOptions).pipe(
      map(this.automapper.curryMany("default", "UserModel"))
    );
  }

  getUserMatrixUrl(): Observable<string> {
    const url = `${this.baseApiUrl}user/view/permission`;
    return this.http.get(url) as Observable<string>;
  }
}
