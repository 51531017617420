import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MemberListItem } from "./member-list-item.model";


@Injectable()
export class MemberListingService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getMembersList(
    sortField: string,
    sortDirection: string,
    startPage: number,
    endPage: number
  ): Observable<MemberListItem[]> {

    const url = `${this.baseApiUrl}members?\
sortField=${sortField}&\
sortDirection=${sortDirection}&\
startRecord=${startPage}&\
endRecord=${endPage}`;

    return this.http.post(url, {}).pipe(
      map(
        this.automapper.curryMany("default", "MemberListItem")
      )
    );
  }
}
