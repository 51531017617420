import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { AccordionTab } from "primeng/accordion";

@Component({
  selector: "app-accordion-tab",
  template: `
    <p-accordionTab #primeAccordionTab [header]="header" [selected]="isSelected">
      <ng-content></ng-content>
    </p-accordionTab>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionTabComponent {
  @ViewChild(AccordionTab, { static: true }) primeAccordionTab: AccordionTab;
  @Input() header = "";
  @Input() isSelected = true;
}
