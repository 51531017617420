import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { ParameterService } from "../../../../../core/navigation/parameter.service";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ProjectType } from "../../../project/project-type.enum";
import { ChaseDetailState } from "../chase-detail-state.model";
import { ChaseDetailStateService } from "../chase-detail-state.service";
import { ChaseDetailHealthCard } from "./chase-detail-health-card.model";
import { ChaseDetailHealthCardService } from "./chase-detail-health-card.service";

@Component({
  selector: "member-chase-detail-health-card",
  templateUrl: "./chase-detail-health-card.component.html",
  styleUrls: ["./chase-detail-health-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailHealthCardComponent implements OnInit, OnDestroy {

  @ViewChild("netNewHccColumn", { static: true }) netNewHccColumn: TemplateRef<any>;
  chaseId: number;
  healthCardGridConfiguration = new GridConfiguration();
  icdGridConfiguration = new GridConfiguration();
  healthCardGridData: ChaseDetailHealthCard[] = [];
  chaseDetailState: ChaseDetailState;
  private sink = new SubSink();
  constructor(
    private readonly chaseDetailHealthCardService: ChaseDetailHealthCardService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly parameterService: ParameterService,
    private readonly chaseDetailStateService: ChaseDetailStateService

  ) { }

  ngOnInit(): void {
    this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
      })
    );
    this.createHealthCardGrid();
    this.createIcdGrid();
    this.getHealthCardGridData();
  }

  createHealthCardGrid(): void {
    const columns = [
      new GridColumnDefinition({ field: "categoryCode", header: "Category Code" }),
      new GridColumnDefinition({ field: "modelDisplayName", header: "Coding Model" }),
      new GridColumnDefinition({ field: "categorySource", header: "Source" }),
      new GridColumnDefinition({ template: this.netNewHccColumn, header: "Net New HCC" }),
      new GridColumnDefinition({ field: "validatedDiagnosisCount", header: "HCC Dx Count" }),
      new GridColumnDefinition({ field: "categoryStatus", header: "Status", styles: this.geStatusColor }),
    ];

    if (this.chaseDetailState?.isIVAProject) {
      columns.splice(2, 0, new GridColumnDefinition({ field: "radvdeHccFlag", header: "RADVDE HCC Flag" }));
    }
    this.healthCardGridConfiguration = new GridConfiguration({
      columns,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      rowExpandMode: "multiple",
      showActionColumn: false,
      showMenu: false,
      showRowExpansionColumn: true,
      trackByField: "categoryCode",
    });
  }

  createIcdGrid(): void {
    this.icdGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "icdCode", header: "ICD Code" }),
        new GridColumnDefinition({ field: "diseaseName", header: "ICD Description" }),
        new GridColumnDefinition({ field: "dosRange", header: "DOS" }),
        new GridColumnDefinition({ field: "validationReasonCode", header: "VRC" }),
      ],
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      showActionColumn: false,
      showMenu: false,
    });
  }

  getHealthCardGridData(): void {
    this.chaseDetailHealthCardService.getHealthCard(this.chaseId)
      .subscribe(result => {
        this.healthCardGridData = result;
        this.changeDetector.markForCheck();
      });
  }

  isNetNewHCC(rowData: ChaseDetailHealthCard): Boolean {
    return rowData.categorySource === "Coder";
  }

  private geStatusColor(value: string, rowData: any): object {
    if (value === "Substantiated") {
      return { color: "#57c785" };
    } else if (value === "Unsubstantiated") {
      return { color: "#fe3b1f" };
    } else {
      return { color: "#666666" };
    }
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}
