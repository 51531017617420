<app-modal class="copy-chart-modal" [(visible)]="visible" header="Copy Chart">
  <app-basic-grid class="grids-display"
                  [configuration]="chaseGridConfiguration"
                  [data]="chaseGridData"
                  [(selection)]="chaseGridSelection">
  </app-basic-grid>

  <footer>
    <app-button class="header-button" text="Copy Chart" (onClick)="onCopyChart(rowData)" [disabled]="isChaseSelected"></app-button>
  </footer>
</app-modal>


