<!-- TODO: Use DocumentListComponent? -->
<ul [ngClass]="display">
  <li *ngFor="let item of pendDocumentItems; let i = index; trackBy: trackByIndex">
    <div class="document-list-image-container">
      <a>
        <app-icon iconName="file" class="fileIcon" (click)="downloadDocument(item)" ></app-icon>
      </a>
    </div>
    <div class="document-list-info-container" [ngClass]="display">
      <div class="descr">
        {{ item.description }}
      </div>
    </div>
    <div class="document-list-createDate">
      Uploaded By: {{ item.createUser }}
    </div>
    <div class="document-list-createDate">
      Uploaded Date: {{ item.createDate | date:"short" }}
    </div>
  </li>
</ul>