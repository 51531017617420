import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UserToken } from "../../auth/user-token.model";
import { UserService } from "../../core/user/user.service";
import { InternetSpeedService } from "./internet-speed.service";

@Component({
  selector: "app-internet-speed",
  templateUrl: "./internet-speed.component.html",
  styleUrls: ["./internet-speed.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternetSpeedComponent implements OnInit, OnDestroy {
  private intervalSpeedCheck;
  downloadSize: number;
  thresholdValue: number;
  interval: number;
  startTime: number;
  messageCount: number;
  isVisible = false;
  user: UserToken;
  functionCount = 0;
  thresholdCount = 0;

  constructor(
    private service: InternetSpeedService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getUserToken();
    this.interval = this.user.internetSpeedTestInterval;
    this.downloadSize = this.user.internetSpeedTestFileSize;
    this.thresholdValue = this.user.internetSpeedTestThresholdValue;
    this.messageCount = this.user.internetSpeedTestMessageCount;

    // commented due to prod issue
    // this.intervalSpeedCheck = setInterval(() => {
    //  this.startTime = new Date().getTime();
    //  this.checkInternetSpeed();
    // },                                    this.interval);
  }

  checkInternetSpeed() {
    this.functionCount++;
    this.service.getFileContent().subscribe(() => {
      const endTime = new Date().getTime();
      const timeDuration = (endTime - this.startTime) / 1000;
      const loadedBits = this.downloadSize * 8;

      const bps = (loadedBits / timeDuration).toFixed(2);
      const speedInKbps = (parseInt(bps, 10) / 1024).toFixed(2);
      const speedInMbps = (parseInt(speedInKbps, 10) / 1024).toFixed(2);

      if (parseInt(speedInMbps, 10) < this.thresholdValue) {
        this.thresholdCount++;
        if (this.thresholdCount === this.messageCount && this.functionCount === this.messageCount) {
          this.resetCounter();
          this.isVisible = true;
          setTimeout(() => { this.isVisible = false; this.changeDetector.markForCheck(); }, 5000);
          this.changeDetector.markForCheck();
        }
      } else {
        this.resetCounter();
      }
    });
  }

  private resetCounter(): void {
    this.functionCount = 0;
    this.thresholdCount = 0;
  }

  ngOnDestroy() {
    clearInterval(this.intervalSpeedCheck);
  }
}
