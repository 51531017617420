<app-server-grid
     [configuration]="configuration"
     [filterTemplate]="npiFilterTemplate"
     [(request)]="request"
     [refresh]="refreshProviderGrid">
  <ng-template #selectButtonComponent let-rowData>
    <app-national-provider-select-button [data]="rowData"
                                         buttonText="Select"
                                         (onClick)="onSelect.emit($event)"></app-national-provider-select-button>
  </ng-template>
  <ng-template #callButtonComponent let-rowData>
    <app-national-provider-select-button [data]="rowData"
                                         buttonText="Call"
                                         (onClick)="onInitiateCall.emit($event)"></app-national-provider-select-button>
  </ng-template> 

  <ng-template #npiFilterTemplate let-form >
      <app-tab-menu orientation="left">

        <app-tab-panel header="Name">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory class="control--group" [formGroup]="form" [model]="nameInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="NPI">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="npiInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Speciality">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="specialityInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Address">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="addressInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="City">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="cityInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="State">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="stateInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Zip">
          <div class="ui-g-12 ui-md-12 ui-lg-12 npi-container">
            <form-factory [formGroup]="form" [model]="zipInput"></form-factory>
          </div>
        </app-tab-panel>

      </app-tab-menu>
  </ng-template>

</app-server-grid>