import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";


export const mapPendCodeList = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PendCodeList")
    .forMember("pendTypeId", o => o.pendTypeId)
    .forMember("pendCode", o => o.pendCode)
    .forMember("pendName", o => o.pendName)
    .forMember("pendTypeCategoryId", o => o.pendTypeCategoryId)
    .forMember("pendTypeCategory", o => o.pendTypeCategory);
};

export class PendCodeList {
  pendTypeId: number;
  pendCode: string;
  pendName: string;
  pendTypeCategoryId: number;
  pendTypeCategory: string;

  constructor(options: {
    pendTypeId: number;
    pendCode: string;
    pendName: string;
    pendTypeCategoryId: number;
    pendTypeCategory: string;
  }) {

    this.pendTypeId = options.pendTypeId;
    this.pendCode = StringHelper.clean(options.pendCode);
    this.pendName = StringHelper.clean(options.pendName);
    this.pendTypeCategoryId = options.pendTypeCategoryId;
    this.pendTypeCategory = options.pendTypeCategory;
  }

}
