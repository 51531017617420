import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { MeasureListItem } from "./measure-list-item.model";

@Injectable({
  providedIn: "root",
})
export class MeasureService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  getMeasuresList(projectId: number): Observable<MeasureListItem[]> {
    const url = `${this.baseApiUrl}measure/list?projectId=${projectId}`;
    return this.http.get(url).pipe(
      map((response: any) => response as MeasureListItem[]));
  }

}
