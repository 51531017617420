import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { ListItem } from "../../../../../../shared/list/list-item";
import { TimelineItem } from "../../../../../../shared/timeline/timeline-item.model";

@Injectable()
export class FtDetailInfoService {
  reset = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getFTGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd;
  }

  resetFt(FtId: number) {

    this.reset.next(FtId);
  }

  getSummaryItems(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}fieldTech?ftId=${this.getFTGdFromPath()}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getTimelineItems(): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/retrievalTimeline?documentSourceId=${this.getFTGdFromPath()}&maxRecords=5`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }
}
