import { AddressSearchResult } from "../../platform/modules/retrieval/address-search/address-search-result.model";

export class RetrievalUtilities {
  static getDocumentSourceTypeName(selectedAddress: AddressSearchResult): string {
    if (selectedAddress) {

      let documentSource = "";

      switch (selectedAddress.documentSourceTypeName) {
        case "EMR": {
          documentSource = "emr";
          break;
        }

        case "Field Tech": {
          documentSource = "ft";
          break;
        }

        case "PSR":

        default: {
          documentSource = "address";
          break;
        }
      }

      return documentSource;
    }
  }
}
