export class PageEntryItem {
  readonly startPage: number;
  readonly endPage: number;

  constructor(options: {
    startPage: number;
    endPage: number;
  }) {
    this.startPage = options.startPage;
    this.endPage = options.endPage;
  }
}
