import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-roi-dw",
  templateUrl: "./roi-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiDWComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.ROI_DW;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
  }

}
