import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ChaseDetailStateService } from "../../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { CalculatedGroupDirective } from "../calculated-group-component.model";
import { PpcService } from "../ppc.service";

@Component({
  selector: "form-dod-delivery-group",
  templateUrl: "./dod-delivery-group.component.html",
  styles: [`
    :host {
      display: block;
      width: 100%;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DodDeliveryGroupComponent extends CalculatedGroupDirective {
  constructor(
    ppcService: PpcService,
    changeDetector: ChangeDetectorRef,
    chaseDetailStateService: ChaseDetailStateService
  ) {
    super(ppcService, changeDetector, chaseDetailStateService);
  }

  get isPssMeasure(): boolean {
    return this.measureCode === "PSS";
  }
}
