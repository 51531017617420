import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class MappingDocument {
    fileName: string;
    filePath: string;
    fileType: string;
    fileSize: string;
    documentType: string;
    uploadDateTime: string;
    userName: string;

    constructor(options: {
    fileName: string;
    filePath: string;
    fileType: string;
    fileSize: string;
    documentType: string;
    uploadDateTime: string;
    userName: string;
  }) {
    this.fileName = StringHelper.clean(options.fileName);
    this.filePath = StringHelper.clean(options.filePath);
    this.fileType = StringHelper.clean(options.fileType);
    this.fileSize = StringHelper.clean(options.fileSize);
    this.documentType = StringHelper.clean(options.documentType);
    this.uploadDateTime = StringHelper.clean(options.uploadDateTime);
    this.userName = StringHelper.clean(options.userName);
  }

}
