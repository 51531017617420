import { IAutoMapper } from "../interfaces";

export const mapChaseDocumentPages = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChaseDocumentPage")
    .forMember("chaseId", o => o.chaseId)
    .forMember("chaseDocumentPageId", o => o.chaseDocumentPageId)
    .forMember("documentTypeId", o => o.documentTypeId)
    .forMember("page", o => o.page)
    .forMember("image", o => o.image);

};
