<div class="my-queries-query__container">
  <div class="my-queries-query--header">
    <h3>MY QUERIES
    </h3>
    <form [formGroup]="formGroup">
      <form-factory [formGroup]="formGroup" [model]="myQueryDropDownInput"
        (onChange)="onViewSelection($event.value,true)"></form-factory>
    </form>
  </div>
  <div class="my-queries-query--noqueries" *ngIf="noQuerySetup">
    <h3 class="text-unset">
      Hi there! It looks like you don't have any saved Queries yet.
    </h3>
    <app-button classes="secondary small" text="Run Query" (click)="goToChaseQuery()"></app-button>
  </div>
  <div class="ui-g ui-fluid" [ngClass]="isGridVisible">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-chase-grid [stateName]="myQueryGridStateName" [showViews]="false" [viewAttributeId]="myQueryViewAttributeId"
        [additionalFilters]="additionalFilters" [additionalColumns]="additionalColumns"
        [showNextFilters]="showNextFilters" [showSaveQuery]="true" [isMyQueryPage]="isMyQueryPage"
        (isSavedQueryDeleted)="deletedSavedQuery()" (isSavedQueryUpdated)="updatedSavedQuery($event)">
      </app-chase-grid>
    </div>
  </div>
</div>

<app-modal [(visible)]="visible" header="SELECT QUERY">
  <form [formGroup]="formGroup">
    <div class="ui-g-12 ui-md-12 ui-lg-12 formGroup--dropdown">
      <form-factory [formGroup]="formGroup" [model]="queryInput" (onChange)="onViewSelection($event.value)">
      </form-factory>
    </div>
  </form>
  <footer class="text-center">
    <app-button text="RUN QUERY" (onClick)="getQueryFilters()" [disabled]="!isRunQuery">
    </app-button>
  </footer>
</app-modal>