import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

@Injectable({
  providedIn: "root",
})
export class VrcService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  getVrcs(measureYear: number, projectType: string, workflowStatus: string, chaseId: number): Observable<SelectableInput[]> {
    if (!NumberHelper.isGreaterThan(measureYear, 1000)
      || !StringHelper.isAvailable(projectType)
      || !StringHelper.isAvailable(workflowStatus)
      || !NumberHelper.isGreaterThan(chaseId, 0)) {
      throw new Error("Do not have enough information to get the validation reason codes.");
    }

    const url = `${this.baseApiUrl}risk/validationreasoncodes`;
    const search = { measureYear, projectType, workflowStatusName: workflowStatus, chaseId, attributeId: null };
    return this.http.post(url, search).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }
}
