import { IAutoMapper } from "../../../core/automapper/interfaces";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridView } from "../grid-menu/grid-views/grid-view.model";


export const mapSaveQuery = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "SaveQuery")
    .forMember("applicationAttributeId", o => o.applicationAttributeId)
    .forMember("attributeId", o => o.attributeId)
    .forMember("configurationName", o => o.configurationName)
    .forMember("columns", o => o.columns)
    .forMember("filters", o => o.filters);
};

export class SaveQuery {
  applicationAttributeId: number;
  attributeId: number;
  configurationName: string;
  columns: string[];
  filters: SelectableInput[];
  organizationId: number | null;
  isSavedQueryPublic: boolean;
  savedQueryView: GridView;

  constructor(a: Partial<SaveQuery>) {
    this.applicationAttributeId = a.applicationAttributeId;
    this.attributeId = a.attributeId;
    this.configurationName = StringHelper.clean(a.configurationName);
    this.columns = a.columns;
    this.filters = a.filters;
    this.organizationId = a.organizationId;
    this.isSavedQueryPublic = BooleanHelper.tryGet(a.isSavedQueryPublic, false);
    this.savedQueryView = a.savedQueryView;
  }
}
