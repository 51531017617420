import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { DownloaderService } from "../../../../core/downloader/downloader.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { NotificationService } from "../../../notification/notification.service";
import { IvaSubmissionService } from "./iva-submission.service";
import { SubmissionRequest } from "./ive-submission.model";

enum FormInputs {
  PROJECT = "project",
  REQUEST_TYPE = "requestType",
}

@Component({
  selector: "app-iva-submission",
  templateUrl: "./iva-submission.component.html",
  styleUrls: ["./iva-submission.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IvaSubmissionComponent implements OnInit {

  constructor(
    protected readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private ivaSubmissionService: IvaSubmissionService,
    private messagingService: MessagingService,
    private downloaderService: DownloaderService,
    private notificationService: NotificationService) {
    this.updateGridConfig();
  }

  private sink = new SubSink();
  private submissionResults: SubmissionRequest[] = [];
  private gridLoading = true;
  private currentProjectId = 0;
  private bulkActions: BulkAction[] = [];

  gridConfigurationModel = new GridConfiguration();

  form: FormGroup;
  projectInput = new Dropdown({
    key: "project",
    label: "Project",
    placeholder: "Select a project...",
  });

  requestTypeInput = new Dropdown({
    key: "requestType",
    label: "Request Type",
    placeholder: "Select a request type...",
  });

  get isProjectSelected(): boolean {
    return NumberHelper.isGreaterThan(this.form.get(FormInputs.PROJECT)?.value, 0);
  }

  get isTypeSelected(): boolean {
    return NumberHelper.isGreaterThan(this.form.get(FormInputs.REQUEST_TYPE)?.value, 0);
  }

  get data(): SubmissionRequest[] {
    return this.submissionResults;
  }

  get gridHasData(): boolean {
    return ArrayHelper.isAvailable(this.data);
  }

  get isGridLoading(): boolean {
    return this.gridLoading;
  }

  get actions(): BulkAction[] {
    return this.bulkActions;
  }

  ngOnInit(): void {
    this.createForm();
    this.getRequestTypes();
    this.getRequestGrid();
    this.getProjectList();
  }

  private getRequestTypes(): void {
    this.sink.add(
      this.ivaSubmissionService.getRequestTypes().subscribe(options => {
        this.requestTypeInput = new Dropdown({ ...this.requestTypeInput, options: [...options] });
        this.changeDetector.markForCheck();
      })
    );
  }

  private getRequestGrid(projectId?: number): void {
    this.gridLoading = true;
    this.sink.add(
      this.ivaSubmissionService.getResultsGrid(projectId).pipe(
        finalize(() => this.gridLoading = false)
      ).subscribe(results => {
        this.submissionResults = [...results];
        this.changeDetector.markForCheck();
      })
    );
  }

  private getProjectList(): void {
    this.sink.add(
      this.ivaSubmissionService.getProjects().subscribe(options => {
        this.projectInput = new Dropdown({ ...this.projectInput, options: [...options] });
        this.changeDetector.markForCheck();
      })
    );
  }

  private getPresignedUrl(requestId: number): void {
    this.sink.add(
      this.ivaSubmissionService.getPreSignedUrl(requestId).subscribe(url => this.downloaderService.fromRawUrl(url))
    );
  }

  protected createForm(): void {
    this.form = this.formService.createFormGroup([
      this.projectInput, this.requestTypeInput,
    ]);
  }

  private updateGridConfig(): void {
    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "requestId", header: "Request ID" }),
      new GridColumnDefinition({ field: "projectName", header: "Project Name" }),
      new GridColumnDefinition({ field: "hios", header: "HIOS ID" }),
      new GridColumnDefinition({ field: "totalMember", header: "Total Members" }),
      new GridColumnDefinition({ field: "requestTypeName", header: "Request Type" }),
      new GridColumnDefinition({ field: "requestUserName", header: "Requested By" }),
      new GridColumnDefinition({ field: "auditRequestDate", header: "Request Date", pipeName: GridPipeName.Date, format: "MM-dd-yyyy hh:mm a" }),
      new GridColumnDefinition({ field: "auditStatusName", header: "Status" }),
    ];
    this.gridConfigurationModel.pageSize = 10;
    this.gridConfigurationModel.showActionColumn = true;
    this.gridConfigurationModel.showExportAction = false;
    this.bulkActions = this.getActions();
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      new BulkAction({
        name: "Download File",
        action: this.onDownloadFile.bind(this),
      }),
    ];
    return totalBulkActions;
  }

  onDownloadFile(rowData): void {
    this.getPresignedUrl(rowData.requestId);
  }

  onSubmit(): void {
    if (!this.isProjectSelected || !this.isTypeSelected) {
      return;
    }
    const projectId = this.form.get(FormInputs.PROJECT)?.value;
    const requestTypeId = this.form.get(FormInputs.REQUEST_TYPE)?.value;

    const request = new SubmissionRequest();
    request.requestTypeId = requestTypeId;
    request.projectId = projectId;
    this.messagingService.showToast("Request sent", SeverityType.SUCCESS);
    this.sink.add(
      this.ivaSubmissionService.submitRequest(request).pipe(
        finalize(() => this.getRequestGrid(this.currentProjectId))
      ).subscribe(() => this.notificationService.refreshNotifications())
    );
  }

  onProjectChange(event: { value: number }): void {
    this.submissionResults = [];
    this.getRequestGrid(event.value);
    this.currentProjectId = event.value;
    this.changeDetector.markForCheck();
  }

}
