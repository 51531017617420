import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { TimelineItem } from "../../../../shared/timeline/timeline-item.model";
import { ChaseSearchRequest } from "../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../api/chase-search/chase-search.service";
import { ProviderSearchRequest } from "../../../api/provider/provider-search-request-model";
import { ProviderItem } from "../../../api/provider/provider-search-result-item";
import { ProviderService } from "../../../api/provider/provider.service";
import { EmrDetailEditService } from "../emr/emr-detail/emr-detail-edit/emr-detail-edit.service";
import { EmrDetailHeaderService } from "../emr/emr-detail/emr-detail-header/emr-detail-header.service";
import { FtDetailEditService } from "../field-tech/ft-detail/ft-detail-edit/ft-detail-edit.service";
import { FtDetailHeaderService } from "../field-tech/ft-detail/ft-detail-header/ft-detail-header.service";
import { ContactRecord } from "../psr/address-detail/address-detail-contact-record";
import { AddressDetailEditService } from "../psr/address-detail/address-detail-edit/address-detail-edit.service";
import { AddressEditModel } from "../psr/address-detail/address-detail-edit/address-edit-model";
import { AddressDetailHeaderService } from "../psr/address-detail/address-detail-header/address-detail-header.service";
import { CallNotesModel } from "./callNotes-model";
import { RetrievalCallFlowService } from "./retrieval-call-flow.service";

@Component({
  selector: "retrieval-call-flow",
  templateUrl: "./retrieval-call-flow.component.html",
  styleUrls: ["./retrieval-call-flow.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalCallFlowComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @Input() retrievalId: number;
  @Input() retrievalType: string;
  @Input() callType: string;
  isCallConnectingDisplay = true;
  isCallScriptDisplay = false;
  isProviderValidationDisplay = false;
  isContactDetailsValidationDisplay = false;
  isProviderValidated = false;
  displayCallNotes = false;
  displayCouldNotConnect = false;
  providerValidatedChecked = false;
  contactDetailsValidatedChecked = false;
  callNotesEnteredChecked = false;
  providerValidatedLabel = "First, validate the details for the providers at this address: ";
  contactDetailsValidatedLabel = "Validate the contact information for this address. ";
  callNotesLabel = "Finish the call and enter call notes.";
  displayCallResultTypeRequired = false;


  isCallModalVisible = false;
  private contactMethodPhone = 1;  // Incoming Call
  private actionSource = "MRCSAction";

  providerGridConfiguration = new GridConfiguration();
  providerGridData: any[] = [];
  providerGridSelection: any[];
  startRecord: number;
  endRecord: number;
  private AllRecords = 2147483647;
  private gridSearchRequest: any;
  private pageSize = 5;
  isEditProviderModalVisible = false;

  readonly formGroupProviderDetails: FormGroup;
  providerPhone: Textbox;
  providerFax: Textbox;
  providerContactName: Textbox;
  providerEditModel: ProviderItem;

  readonly formGroupContactDetails: FormGroup;
  address1: Textbox;
  address2: Textbox;
  city: Textbox;
  zip: Textbox;
  state: Textbox;
  email: Textbox;
  phone: Textbox;
  fax: Textbox;
  contactName: Textbox;
  addressEditModel: AddressEditModel;
  addressDetailsEdited = false;

  readonly formGroupCallNotes: FormGroup;
  callNotes: Textarea;
  spokeTo: Textbox;
  nextCallDate: Date = null;
  nextCallDateTime: Date;
  commitDate: Date = null;
  callSuccessfulResultTypes: SelectableInput[];
  callNotesSaveModel: CallNotesModel;
  private documentRetrieval = 5;   // Document Retrieval
  callUnSuccessfulResultTypes: SelectableInput[];
  isCallSuccessful: number;
  chaseList: number[];
  timelineItems = List<TimelineItem>();
  chaseData: any;
  callNotesCallType = "";
  minDate: Date;
  selectedCallResultTypeInput: Dropdown;
  formGroupUnSuccessfulCallNotes: FormGroup;
  unSuccessfulResultTypesInput: Dropdown;

  constructor(
    private service: RetrievalCallFlowService,
    private providerService: ProviderService,
    private readonly formService: FormService,
    private addressDetailHeaderService: AddressDetailHeaderService,
    private emrDetailHeaderService: EmrDetailHeaderService,
    private ftDetailHeaderService: FtDetailHeaderService,
    private addressDetailEditService: AddressDetailEditService,
    private emrDetailEditService: EmrDetailEditService,
    private ftDetailEditService: FtDetailEditService,
    private chaseService: ChaseSearchService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private router: Router
  ) {

    this.providerGridConfiguration.columns = [
      new GridColumnDefinition({
        field: "serviceProviderName",
        header: "Provider Name",
      }),

      new GridColumnDefinition({
        field: "nationalProviderId",
        header: "NPI",
      }),
      new GridColumnDefinition({
        field: "providerGroup",
        header: "Group",
      }),
      new GridColumnDefinition({
        field: "speciality",
        header: "Speciality",
      }),
      new GridColumnDefinition({
        field: "contact",
        header: "Contact",
      }),
      new GridColumnDefinition({
        field: "phoneNumber",
        header: "Phone",
      }),
      new GridColumnDefinition({
        field: "faxNumber",
        header: "Fax",
      }),
    ];

    this.providerGridConfiguration.pageSize = this.pageSize;
    this.providerGridConfiguration.selectionMode = "multiple";
    this.providerGridConfiguration.showActionColumn = false;

    // PROVIDER DETAILS FORM
    this.providerPhone = new Textbox({
      key: "providerPhone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.providerFax = new Textbox({
      key: "providerFax",
      label: "Fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.providerContactName = new Textbox({
      key: "providerContactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
      },
    });

    this.formGroupProviderDetails = this.formService.createFormGroup([this.providerPhone, this.providerFax, this.providerContactName]);


    // CONTACT DETAILS FORM
    this.address1 = new Textbox({
      key: "address1",
      label: "Address",
      disabled: true,
      placeholder: "Address Line1",
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "This field is required.",
        maxlength: "address1 name can not be more than 50 characters.",
      },
    });

    this.address2 = new Textbox({
      key: "address2",
      label: "",
      disabled: true,
      placeholder: "Address Line2",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxLength: "address2 name can not be more than 50 characters.",
      },
    });

    this.city = new Textbox({
      key: "city",
      label: "City",
      disabled: true,
      placeholder: "City",
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "This field is required.",
        maxLength: "city name can not be more than 50 characters.",
      },
    });

    this.state = new Textbox({
      key: "state",
      label: "State",
      disabled: true,
      placeholder: "State",
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "This field is required.",
        maxLength: "State name can not be more than 50 characters.",
      },
    });


    this.zip = new Textbox({
      key: "postalCode",
      label: "Postal Code",
      disabled: true,
      placeholder: "Postal Code",
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(10)],
      errorMessages: {
        required: "This field is required.",
        minLength: "city name can not be less than 5 characters.",
        maxLength: "city name can not be more than 10 characters.",
      },
    });

    this.email = new Textbox({
      key: "email",
      label: "Email",
      placeholder: "Email",
      validators: [Validators.email, Validators.maxLength(50)],
      errorMessages: {
        email: "Please enter a valid email address.",
        maxlength: "Email name can not be more than 50 characters.",
      },
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.fax = new Textbox({
      key: "fax",
      label: "fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
      },
    });

    this.formGroupContactDetails = this.formService.createFormGroup([this.address1, this.address2, this.city, this.state, this.zip, this.email, this.phone, this.fax, this.contactName]);


    // CALL NOTES FORM
    this.callNotes = new Textarea({
      key: "callNotes",
      placeholder: "Please describe the call here...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],

    });

    this.spokeTo = new Textbox({
      key: "spokeTo",
      label: "Spoke To",
      validators: [Validators.required, Validators.maxLength(100)],

    });

    this.selectedCallResultTypeInput = new Dropdown({
      key: "selectedCallResultTypeInput",
      value: "-1",
    });

    this.formGroupCallNotes = this.formService.createFormGroup([this.callNotes, this.spokeTo, this.selectedCallResultTypeInput]);
    this.createUnsuccessfulCallForm();
    this.getCallSubTypesDropdowns();

    this.minDate = new Date();
  }

  ngOnInit() {

    if (this.callType === "incoming") {
      this.isCallConnectingDisplay = false;
      this.displayCallNotes = true;
      this.callTabDisplay(true);
      this.formGroupCallNotes.get(this.selectedCallResultTypeInput.key).setValue(1); // Successful Call
      this.contactMethodPhone = 5; // Incoming Call
      this.callNotesCallType = "MRCSActionIncomingCall";

      // Load Chase Data
      const assignedTo = null;
      this.gridSearchRequest =
        new ChaseSearchRequest(
          null, null, null, null, this.retrievalId, assignedTo, null, null, null, null, null,
          null, this.startRecord, this.endRecord);

      this.chaseService
        .chaseSearch(this.gridSearchRequest)
        .subscribe(items => {
          this.chaseData = items as any;
          this.chaseList = items.filter(x => x.workflowStatusId === 2 || x.workflowStatusId === 3).map(x => x.chaseID);
        });
    } else if (this.callType === "outgoing") {
      this.callNotesCallType = "MRCSCallNotesSave";

      this.chaseService.chaseIdsList
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(item => this.chaseList = item);

      this.getProviderGrid();

      this.getAddressDetailsForEdit();

      this.service.reset
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(value => {
          this.getProviderGrid();
          this.getAddressDetailsForEdit();
        });

    }

    this.service
      .getCallNotesTimelineItems(this.retrievalId)
      .subscribe(items => this.timelineItems = this.assignAndNotifyCallNotesTimeline(items));

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAddressDetailsForEdit() {
    this.service
      .getAddressDetailsForEdit(this.retrievalId)
      .subscribe(this.assignAndNotifyAddressDetails);
  }

  getProviderGrid() {
    this.startRecord = 1;
    this.endRecord = this.AllRecords;
    this.gridSearchRequest =
      new ProviderSearchRequest(null, null, this.retrievalId, null, null, null,
                                null, null, this.startRecord, this.endRecord);

    this.providerService
      .providerSearch(this.gridSearchRequest)
      .subscribe(items => {
        this.providerGridData = items as any;
        this.changeDetector.markForCheck();
      });
  }

  private assignAndNotifyAddressDetails = (data: AddressEditModel): void => {

    this.addressEditModel = new AddressEditModel(data);

    this.loadEditContactDetailsForm();

    this.loadCallNotesForm();
  }

  private assignAndNotifyCallNotesTimeline<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  private loadEditContactDetailsForm() {

    this.formGroupContactDetails.patchValue({
      address1: this.addressEditModel.address1,
      address2: this.addressEditModel.address2,
      city: this.addressEditModel.addressCity,
      state: this.addressEditModel.addressState,
      postalCode: this.addressEditModel.addressZip,
      email: this.addressEditModel.email,
      phone: this.addressEditModel.phone,
      fax: this.addressEditModel.faxNumber,
      contactName: this.addressEditModel.contactName,
    });

    this.changeDetector.markForCheck();
  }

  private loadCallNotesForm() {
    if (this.addressEditModel.nextCallDateTime !== "0001-01-01T00:00:00Z" && this.addressEditModel.nextCallDateTime !== ""
      && this.addressEditModel.nextCallDateTime !== "1/1/1900 12:00:00 AM") {
      this.nextCallDate = new Date(this.addressEditModel.nextCallDateTime);

    }

    this.changeDetector.markForCheck();
  }

  onContactDetailsUpdate(): void {
    if (this.formGroupContactDetails.invalid) {
      return;
    }

    this.getContactDetailsFormValues();

    this.service.updateAddressDetails(this.addressEditModel)
      .subscribe((data: number) => {
        if (data > 0) {
          this.addressDetailsEdited = true;


          this.messagingService.showToast("Address Details successfully edited.", SeverityType.SUCCESS);

        } else {
          this.messagingService.showToast("Failed to edit Address Details.", SeverityType.ERROR);
        }

        this.changeDetector.markForCheck();

      });

  }

  openProviderEditModal(rowData: any) {
    this.isEditProviderModalVisible = true;

    this.formGroupProviderDetails.patchValue({
      providerPhone: rowData.phoneNumber,
      providerFax: rowData.faxNumber,
      providerContactName: rowData.contact,

    });
    this.changeDetector.markForCheck();

  }

  onUpdateProviderEdits(): void {
    if (this.formGroupProviderDetails.invalid) {
      return;
    }

    this.getProviderEditsFormValues();

    this.providerService.updateProviderInfo(this.providerEditModel, this.retrievalId)
      .subscribe((data: number) => {
        if (data > 0) {
          // this.ftInfoService.resetFt(this.ftId);
          // this.ftDetailHeaderService.resetFt(this.ftId);

          this.messagingService.showToast("Provider Details successfully edited.", SeverityType.SUCCESS);

        } else {
          this.messagingService.showToast("Failed to edit Provider Details.", SeverityType.ERROR);
        }
      });

  }

  private getProviderEditsFormValues() {
    this.changeDetector.markForCheck();

    this.providerEditModel.phoneNumber = this.formGroupProviderDetails.get("phone").value;
    this.providerEditModel.faxNumber = this.formGroupProviderDetails.get("fax").value;
    this.providerEditModel.contact = this.formGroupProviderDetails.get("contactName").value;
  }

  private getContactDetailsFormValues() {
    this.changeDetector.markForCheck();

    this.addressEditModel.email = this.formGroupContactDetails.get("email").value;
    this.addressEditModel.phone = this.formGroupContactDetails.get("phone").value;
    this.addressEditModel.faxNumber = this.formGroupContactDetails.get("fax").value;
    this.addressEditModel.contactName = this.formGroupContactDetails.get("contactName").value;
  }

  saveCallResultType(callResult) {

    const contactRecord =
      new ContactRecord({ addressId: this.retrievalId, contactResultType: callResult, contactMethodType: this.contactMethodPhone });

    this.service
      .saveCallResult(contactRecord, this.actionSource)
      .subscribe();

    if (callResult === 1) {
      this.service.isFollowUpCall(this.retrievalId)
        .subscribe((data: number) => {
          if (data > 0) {
            this.isCallConnectingDisplay = false;
            this.displayCallNotes = true;

          } else {
            this.isCallConnectingDisplay = false;
            this.isCallScriptDisplay = true;
            this.providerValidationDisplay(true);
          }

          this.changeDetector.markForCheck();

        });

    } else if (callResult === 2) {
      this.isCallConnectingDisplay = false;
      this.displayCouldNotConnect = true;
    }

  }

  providerValidationDisplay($event) {
    this.isProviderValidationDisplay = $event;
  }

  providerValidated() {
    this.providerValidationDisplay(false);
    this.providerValidatedChecked = true;
    this.providerValidatedLabel = "Providers are validated. ";
    this.contactRecordValidationDisplay(true);

  }

  contactRecordValidationDisplay($event) {
    this.isContactDetailsValidationDisplay = $event;
  }

  contactDetailsValidated() {
    this.contactRecordValidationDisplay(false);
    this.contactDetailsValidatedChecked = true;
    this.contactDetailsValidatedLabel = "Contact details are validated. ";
    this.callNotesLabel = "Finally, enter call notes below:";
    this.displayCallNotesSection(true);
  }

  displayCallNotesSection($event) {
    this.displayCallNotes = $event;
  }

  // Get data for dropdowns
  getCallSubTypesDropdowns() {

    this.isCallSuccessful = 1;
    this.service.getCallTypesDropdown(this.isCallSuccessful)
      .subscribe((data: any[]): void => {
        const callSuccessfulTypesList = [];
        callSuccessfulTypesList.push({ text: "Select a call result...", value: -1, label: "Select a call result..."});
        for (const item of data) {
          callSuccessfulTypesList.push({ text: item.contactResultTypeName, value: item.contactResultTypeId , label: item.contactResultTypeName});
        }
        this.callSuccessfulResultTypes = callSuccessfulTypesList;
        this.selectedCallResultTypeInput = new Dropdown({...this.selectedCallResultTypeInput, options: this.callSuccessfulResultTypes});
        this.changeDetector.markForCheck();
      });

    this.isCallSuccessful = 0;
    this.service.getCallTypesDropdown(this.isCallSuccessful)
      .subscribe((data: any[]): void => {
        const callUnSuccessfulTypesList = [];
        callUnSuccessfulTypesList.push({ label: "Select a Reason...", value: -1, text: "Select a Reason..."});
        for (const item of data) {
          callUnSuccessfulTypesList.push({ label: item.contactResultTypeName, value: item.contactResultTypeId, text: item.contactResultTypeName});
        }
        this.callUnSuccessfulResultTypes = callUnSuccessfulTypesList;
        this.unSuccessfulResultTypesInput = new Dropdown({...this.unSuccessfulResultTypesInput, options: this.callUnSuccessfulResultTypes});
        this.changeDetector.markForCheck();
      });
  }

  onSelectType(event) {
    if (event) {
      this.formGroupCallNotes.get(this.selectedCallResultTypeInput.key).setValue(event.value);

      this.displayCallResultTypeRequired = (this.selectedCallResultType === -1)
                                           ? this.displayCallResultTypeRequired = true
                                           : this.displayCallResultTypeRequired = false;

    }
  }

  saveSuccessfulCallNotes(): void {
    if (this.selectedCallResultType === undefined || this.selectedCallResultType === -1) {

      if (this.selectedCallResultType === undefined || this.selectedCallResultType === -1) {
        this.displayCallResultTypeRequired = true;
      }

      return;
    }

    this.callNotesEnteredChecked = true;
    this.getCallNotesFormValues();

    if (this.validateCallNotesForm() === true) {
      this.saveCallNotes();
      this.updateAddressDetails();
    }

  }

  private getCallNotesFormValues() {
    this.changeDetector.markForCheck();

    this.callNotesSaveModel = new CallNotesModel({
      retrievalLocationId: this.retrievalId,
      contactMethodType: this.contactMethodPhone,
      actionSource: this.callNotesCallType,
      functionalRoleId: this.documentRetrieval,
      callResultType: this.selectedCallResultType,
      callNotes: this.formGroupCallNotes.get("callNotes").value,
      spokeTo: this.formGroupCallNotes.get("spokeTo").value,
      nextCallDateTime: this.nextCallDate ? this.nextCallDate.toString() : null,
      commitDate: this.commitDate,
    });

  }

  validateCallNotesForm() {

    let isValid = false;
    this.markControlsAsTouched();

    // check for basic validators
    if (this.formGroupCallNotes.invalid) {
      this.messagingService.showMessage("Please correct invalid entries.", SeverityType.ERROR);
      return false;
    }

    isValid = true;


    return isValid;
  }

  saveCallNotes() {
    this.service.saveCallNotes(this.callNotesSaveModel)
      .subscribe((data: number) => {
        if (data > 0) {

          this.messagingService.showToast("Call Notes successfully saved.", SeverityType.SUCCESS);
          this.callTabDisplay(false);
          this.router.navigateByUrl(`retrieval/${this.retrievalType}/${this.retrievalId}`);

        } else {
          this.messagingService.showToast("Failed to save Call Notes.", SeverityType.ERROR);
        }
      });

  }

  // We want error messages to display after user touches field AND if the user does not touch a field
  // and is field is invalid on form submission so we have to do this to mark controls as touched so
  // error messsges display
  markControlsAsTouched() {
    Object.keys(this.formGroupCallNotes.controls).forEach(field => {
      const control = this.formGroupCallNotes.get(field);
      control.markAsTouched({ onlySelf: true });
    });

  }

  isFieldInvalid(field: string) {
    return !this.formGroupCallNotes.get(field).valid && this.formGroupCallNotes.get(field).touched;
  }

  updateAddressDetails() {
    switch (this.retrievalType) {
      case "address": {
        this.addressDetailHeaderService.resetAddress(this.retrievalId);
        this.addressDetailEditService.resetAddress(this.retrievalId);
        break;
      }

      case "emr": {
        this.emrDetailHeaderService.resetEmr(this.retrievalId);
        this.emrDetailEditService.resetEmr(this.retrievalId);
        break;
      }

      case "ft": {
        this.ftDetailHeaderService.resetFt(this.retrievalId);
        this.ftDetailEditService.resetFt(this.retrievalId);
        break;
      }

      default: {
        break;
      }

    }
  }

  callTabDisplay(display) {
    const callTabNotVisible = false;

    switch (this.retrievalType) {
      case "address": {
        this.addressDetailHeaderService.resetSubMenu(display);
        break;
      }

      case "emr": {
        this.emrDetailHeaderService.resetSubMenu(display);
        break;
      }

      case "ft": {
        this.ftDetailHeaderService.resetSubMenu(display);
        break;
      }

      default: {
        break;
      }

    }

  }

  visibleChange($event) {
    this.isCallModalVisible = $event;
  }

  saveUnSuccessfulCallNotes(): void {
    if (this.selectedUnSuccessfulCallResultType === undefined || this.selectedUnSuccessfulCallResultType === -1) {
      this.displayCallResultTypeRequired = true;
      return;
    }

    this.changeDetector.markForCheck();

    this.callNotesSaveModel = new CallNotesModel({
      retrievalLocationId: this.retrievalId,
      contactMethodType: this.contactMethodPhone,
      actionSource: this.callNotesCallType,
      functionalRoleId: this.documentRetrieval,
      callResultType: this.selectedUnSuccessfulCallResultType,
      commitDate: this.commitDate,
    });

    this.saveCallNotes();

  }

  get selectedCallResultType(): number {
    return Number(this.formGroupCallNotes.get(this.selectedCallResultTypeInput.key).value);
  }

  createUnsuccessfulCallForm(): void {
    this.unSuccessfulResultTypesInput = new Dropdown({
      key: "selectedUnSuccessfulCallResultType",
      value: "-1",
    });
    this.formGroupUnSuccessfulCallNotes = this.formService.createFormGroup([this.unSuccessfulResultTypesInput]);
    this.changeDetector.markForCheck();
  }

  get selectedUnSuccessfulCallResultType(): number {
    return Number(this.formGroupUnSuccessfulCallNotes.get(this.unSuccessfulResultTypesInput.key).value);
  }

  onSelectCallUnsuccessFulType(event) {
    if (event) {
      this.formGroupCallNotes.get(this.unSuccessfulResultTypesInput.key).setValue(event.value);

      this.displayCallResultTypeRequired = (this.selectedUnSuccessfulCallResultType === -1)
                                           ? this.displayCallResultTypeRequired = true
                                           : this.displayCallResultTypeRequired = false;

    }
  }

}
