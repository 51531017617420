import { IAutoMapper } from "../interfaces";

export const mapUserAttributeDisplayModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "UserAttributeDisplayModel")
    .forMember("attributeId", o => o.attributeId)
    .forMember("attributeName", o => o.attributeName)
    .forMember("displayName", o => o.displayName)
    .forMember("roleIds", o => o.roleIds)
    .forMember("required", o => o.required)
    .forMember("displayType", o => o.displayType)
    .forMember("valueList", o => o.valueList);


};
