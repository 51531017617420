<div class="onboarding">
  <div class="onboarding__icon">
    <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-vertical"></app-icon>
  </div>
  <div class="onboarding__welcome text-center">
    Welcome!
  </div>
  <div class="onboarding__label text-center">Please create a new password.</div>
  <div class="onboarding__form">
    <form [formGroup]="form">
      <form-factory *ngFor="let model of passwordInputs; trackBy: trackByIndex"
                    [formGroup]="form"
                    [model]="model"></form-factory>
      <app-messages key="onboardingMessage"></app-messages>
      <div *ngIf="isValidationFailed" class="criteria">
        <ul>
          <li>At least eight characters in length</li>
          <li>One uppercase letter</li>
          <li>One lowercase letter</li>
          <li>One number</li>
          <li>One special character from #?@$%^&*-_.</li>
        </ul>
      </div>
      <app-button (onClick)="onUpdate()" text="SAVE NEW PASSWORD" [disabled]="!form.valid"></app-button>
    </form>
  </div>
</div>
