import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export const mapServiceOrgConfigModel = (automapper: IAutoMapper): void => {

  automapper
    .createMap("default", "ServiceOrgConfigModel")
    .forMember("name", o => o.name)
    .forMember("contactName", o => o.contactName)
    .forMember("contactData", o => o.contactData)
    .forMember("organizationAttributeData", o => o.organizationAttributeData)
    .forMember("companyLogo", o => o.companyLogo)
    .forMember("isOrganizationEnabledToDisplaySingleSignOnSettings", o => o.isOrganizationEnabledToDisplaySingleSignOnSettings);

};

export class ServiceOrgConfigModel {
  name: string;
  contactName: string;
  contactData: string;
  organizationAttributeData: string;
  companyLogo?: FormData;
  isOrganizationEnabledToDisplaySingleSignOnSettings?: boolean;

  constructor(options: {
    name: string;
    contactName: string;
    contactData: string;
    organizationAttributeData: string;
    companyLogo?: FormData;
    isOrganizationEnabledToDisplaySingleSignOnSettings?: boolean;

  }) {
    this.name = StringHelper.clean(options.name);
    this.contactName = StringHelper.clean(options.contactName);
    this.contactData = StringHelper.clean(options.contactData);
    this.organizationAttributeData = StringHelper.clean(options.organizationAttributeData);
    this.companyLogo = options.companyLogo;
    this.isOrganizationEnabledToDisplaySingleSignOnSettings = options.isOrganizationEnabledToDisplaySingleSignOnSettings;
  }

}
