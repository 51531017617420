<div class="container-document">
  <app-button text="UPLOAD DOCUMENT" (onClick)="showDialog()"></app-button>
</div>
<div>
  <app-internal-pends-upload-document
                                      [isUploadDocumentVisible]="isUploadDocumentView"
                                      (formClose)="reUploadDocument($event)"></app-internal-pends-upload-document>
</div>

<div [ngClass]="display" class="pendDocumentUploadList-container">
  <div *ngFor="let item of pendDocumentItems; let i = index; trackBy: trackByIndex" style="display:inline-block"
       class="pendDocumentList">
    <div class="document-list-imageContainer">
      <a>
        <app-icon iconName="file" class="fileIcon" (click)="downloadDocument(item)" ></app-icon>
      </a>
    </div>

    <div class="document-list-info-container" [ngClass]="display">
      <div class="document-list-Description">
        {{ item.description }}
      </div>
      <div class="document-list-createDate">
        Uploaded By: {{ item.createUser }}
      </div>
      <div class="document-list-createDate">
        Uploaded Date: {{ item.createDate | date:"short" }}
      </div>
    </div>
    <hr />
  </div>

</div>
