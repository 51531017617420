import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ProjectCreateModel } from "./project-create.model";

@Injectable()
export class NewProjectService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  createProject(ProjectCreate: ProjectCreateModel): Observable<number> {
    const url = `${this.baseApiUrl}project/create`;

    return this.http.post(url, ProjectCreate).pipe(map((response: any) => response as number));
  }

  updateProject(ProjectCreate: ProjectCreateModel): Observable<null> {
    const url = `${this.baseApiUrl}project/update`;

    return this.http.post(url, ProjectCreate) as Observable<null>;
  }

  getOwners(SearchCriteria): Observable<SelectItem[]> {
    const url = `${this.baseApiUrl}user/users`;

    return this.http.post(url, SearchCriteria)
      .pipe(
        map((response: any) => response as SelectItem[]));
  }

  getProjectTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}project/projecttypes`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("ProjectType", "SelectableInput"))
      );
  }

  getLineOfBusiness(modelType: string = "Hcc"): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}project/lineOfbusiness?modelType=${modelType}`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("string", "SelectableInput"))
      );
  }

  getHCC(lineOfBusiness?: string): Observable<SelectableInput[]> {
    const url = StringHelper.isAvailable(lineOfBusiness) ?
      `${this.baseApiUrl}project/hccmodel?lineOfBusiness=${lineOfBusiness}` : `${this.baseApiUrl}project/hccmodel`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("hccCodes", "SelectableInput"))
      );
  }
}
