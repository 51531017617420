import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuditQueryGridFilters } from "./audit-query-grid-filter-model";

@Injectable({
  providedIn: "root",
})
export class AuditQueryGridFiltersService {
  private gridFilters = new BehaviorSubject<AuditQueryGridFilters>(new AuditQueryGridFilters());
  auditGridFilter = this.gridFilters.asObservable();

  constructor(
  ) { }

  resetGridFilter(value: AuditQueryGridFilters) {
    this.gridFilters.next(value);
  }

}
