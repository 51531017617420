<div class="chase-query-container">
  <h3 class="chase-query-header">Chase Query</h3>
  <div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <div *ngIf="chaseGridStateName">
        <app-button class="header-button" text="CREATE A NEW CHASE" (onClick)="createNewChase()"></app-button>
      </div>
      <app-chase-grid [stateName]="chaseGridStateName"
                      [(selection)]="selectedChases"
                      [clinical]="null"
                      [showViews]="true"
                      [viewAttributeId]="viewAttributeId"
                      [additionalFilters]="additionalFilters"
                      [additionalColumns]="additionalColumns"
                      [routeParameters]="routeParameters"
                      [showNextFilters]="showNextFilters"
                      [saveQueryAttributeId]="saveQueryAttributeId"
                      [showSaveQuery]="true">
      </app-chase-grid>
    </div>
  </div>
</div>
