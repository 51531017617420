<app-modal
  #modal
  class="call-notes-modal"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
  header="ADD NOTE"
  (onShow)="show()"
  (onHide)="hide()">

  <form-factory [formGroup]="formGroup" [model]="callNotes"></form-factory>

  <footer>
    <app-button text="SUBMIT NOTE" (onClick)="saveCallNotes()"></app-button>
  </footer>

</app-modal>