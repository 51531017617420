<div class="thumbnail-label__container">
    <div class="content--diagnosis">
        <div class="content__label">
            <div><span class="content__description"><b>DOS:</b>&nbsp;5/19/2022</span></div>
            <div><span class="content__description"><b>Chart Location:</b>&nbsp;Assessment</span></div>
        </div>
    </div>
    <div class="content--provider">
        <div class="content__label">
            <div><span class="content__description"><b>Provider:</b>&nbsp;Dr Angela Diaz</span></div>
            <div><span class="content__description"><b>Chart Location:</b>&nbsp;Assessment</span></div>
        </div>
    </div>
    <div class="content--symptoms">
        <div class="content__label">
            <div><span class="content__description"><b>Symptoms:</b>&nbsp;Pain</span></div>
            <div><span class="content__description"><b>Chart Location:</b>&nbsp;Progress Notes</span></div>
        </div>
    </div>
    <div class="content--medications">
        <div class="content__label">
            <div><span class="content__description"><b>Medications:</b>&nbsp;Rosiglitazone (Avandia), Rosiglitazone (Avandia), Rosiglitazone (Avandia)</span></div>
            <div><span class="content__description"><b>Chart Location:</b>&nbsp;Medications</span></div>
        </div>
    </div>
</div>