<app-modal
  #modal
  [rejectText]="rejectText"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [header]="header"
  (onShow)="show()"
  (onHide)="hide()"
  (onCancel)="cancel()">

  <ng-content></ng-content>

  <footer>
    <app-button classes="secondary"
                [text]="acceptText"
                (onClick)="onYesClick()"
                [disabled]="isConfirmDisabled"></app-button>
    <ng-content select="footer"></ng-content>
  </footer>

</app-modal>
