<div class="ui-g">
  <div class="ui-g-2">
    <h3>Diagnoses</h3>
  </div>
  <div class="ui-g-9">
    <app-header-statistics [headerStatistics]="headerStatistics"></app-header-statistics>
    <member-risk-system-result-key *ngIf="isShowNlpComponent" class="result-key" [systemResultKeys]="systemResultKeys">
    </member-risk-system-result-key>
  </div>
  <div class="ui-g-1">
    <member-add-diagnosis></member-add-diagnosis>
  </div>
</div>

<app-basic-grid #diagnosesGrid [configuration]="gridConfiguration" [data]="diagnoses" class="selection-grid"
  [(selection)]="selection" (selectionChange)="setSelectedDiagnosis()">

  <ng-template #deleteButton let-i="index" let-rowData>
    <member-delete-diagnosis [diagnosis]="rowData"></member-delete-diagnosis>
  </ng-template>

  <ng-template #exemptColumn let-rowData>
    <member-exempt-diagnosis [diagnosis]="rowData"></member-exempt-diagnosis>
  </ng-template>

  <ng-template #systemColumn let-rowData>
    <member-risk-system-result [data]="rowData"></member-risk-system-result>
  </ng-template>

  <ng-template #pageNumberColumn let-rowData>
    <span class="link diagnoses-grid__gridcol--pageNumber" title="{{rowData.pageNumber}}" (click)="gotoDocumentPage($event, rowData.pageNumber)">{{rowData.pageNumber}}</span>
  </ng-template>
</app-basic-grid>