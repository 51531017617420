<div [formGroup]="formGroup" [ngClass]="model.classOverride" class="control control--input control--textbox-source"
  [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <input 
    pInputText 
    [ngClass]="classes" 
    [readonly]="model.readonly" 
    [type]="model.type"
    [autocomplete]="model.autocomplete" 
    [id]="model.key" 
    [placeholder]="model.placeholder" 
    [formControlName]="model.key" 
    [title]="model.title"
    [maxlength]="model.maxlength" />
</div>
