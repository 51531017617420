<div class="info-header">
  <div class="info-header__section info-header__section--left">
    <ng-content select="[left]"></ng-content>    
  </div>
  <div class="info-header__section info-header__section--center">
    <ng-content select="[center]"></ng-content>    
  </div>
  <div class="info-header__section info-header__section--right">
    <ng-content select="[right]"></ng-content>    
  </div>
</div>
