import { IAutoMapper } from "../interfaces";

export const mapTimelineItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "TimelineItem")
    .forMember("type", o => o.type)
    .forMember("title", o => o.title)
    .forMember("details", o => o.detail)
    .forMember("note", o => o.note)
    .forMember("username", o => o.username)
    .forMember("timestamp", o => o.timestamp);

};
