import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { forkJoin } from "rxjs";
import { UserToken } from "../../../../auth/user-token.model";
import { UserService } from "../../../../core/user/user.service";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseGridComponent } from "../../../../shared/chase-grid/chase-grid.component";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { ListItem } from "../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { CLINICAL_MRR_GRID, CLINICAL_OR2_GRID, CLINICAL_OR_GRID, RETRIEVAL_DOCUMENT_QA_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { OverreadFeedbackQuerySearch } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-search.model";
import { OverreadFeedbackStatus } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";
import { ClinicalPageService } from "./clinical-page.service";

@Component({
  selector: "clinical-page",
  templateUrl: "./clinical-page.component.html",
  styleUrls: ["./clinical-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalPageComponent implements OnInit {
  @ViewChild(ChaseGridComponent, { static: true }) chaseGridComponent: ChaseGridComponent;
  private isInitialized = false;
  private readonly possiblePageTypes = ["docqa", "mrr", "or1", "clientoverread"];
  private user: UserToken;
  pageType = "";
  headerStatistics = List<ListItem>();
  additionalFilters: GridFilter[] = [];
  additionalColumns: GridColumnDefinition[] = [];
  headerTitle: string;
  chaseIdRouteUrl: string;
  stateName: string;
  viewAttributeId: number;
  overreadFeedbackChallengeCount = 0;
  constructor(
    private clinicalPageService: ClinicalPageService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private overreadFeedbackService: OverreadFeedbackService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.route.paramMap.subscribe(params => {
      this.clearPage();

      this.pageType = params.get("pageType");
      if (!this.hasValidPageType) {
        this.router.navigate(["404"]);
      }

      switch (this.pageType) {
        case "mrr":
          this.headerTitle = "Medical Record Review";
          this.chaseIdRouteUrl = "/members/chase/:chaseId";
          this.stateName = CLINICAL_MRR_GRID.attributeCode;
          this.viewAttributeId = CLINICAL_MRR_GRID.attributeId;
          break;
        case "or1":
          this.headerTitle = "Overread";
          this.chaseIdRouteUrl = "/members/chase/:chaseId";
          this.stateName = CLINICAL_OR_GRID.attributeCode;
          this.viewAttributeId = CLINICAL_OR_GRID.attributeId;
          break;
        case "clientoverread":
          this.headerTitle = "Client Overread";
          this.chaseIdRouteUrl = "/members/chase/:chaseId";
          this.stateName = CLINICAL_OR2_GRID.attributeCode;
          this.viewAttributeId = CLINICAL_OR2_GRID.attributeId;
          break;
        default:
        case "docqa":
          this.headerTitle = "Document QA";
          this.chaseIdRouteUrl = "/retrieval/review/qa/:chaseId";
          this.stateName = RETRIEVAL_DOCUMENT_QA_GRID.attributeCode;
          this.viewAttributeId = RETRIEVAL_DOCUMENT_QA_GRID.attributeId;
          break;
      }

      this.getStatistics();
      this.createGrid();

      if (this.isInitialized) {
        // HACK: the serverGridComponent doesn't update fast enough in this situation.
        setTimeout(() => {
          this.chaseGridComponent.pageType = this.pageType;
          this.chaseGridComponent.stateName = this.stateName;
          this.chaseGridComponent.additionalFilters = this.additionalFilters;
          this.chaseGridComponent.createGrid();
          this.chaseGridComponent.serverGridComponent.configuration = this.chaseGridComponent.configuration;
          this.chaseGridComponent.serverGridComponent.request = this.chaseGridComponent.request;
          this.chaseGridComponent.getAllSelectableInputs();
          this.chaseGridComponent.showFiltersOrGetData();
        });
      }

      this.isInitialized = true;
    });
  }

  get selectedStatisticsValue(): string {
    return this.chaseGridComponent.getValue("StatisticsFilter");
  }

  set selectedStatisticValue(value: string) {
    const actualValue = value === "Chases" ? "" : value;
    this.chaseGridComponent.setValue("StatisticsFilter", actualValue);
  }

  applyStatisticsFilter(filterName: string): void {
    if (filterName === "Feedback Challenges") {
      this.router.navigate(["/clinical/view/challenges"]);
    } else {
      this.selectedStatisticValue = filterName;
      this.chaseGridComponent.serverGridComponent.request.startRecord = 1;
      this.chaseGridComponent.refreshGrid.emit();
    }
  }

  statisticClass(name: string): string {
    const compareAgainst = StringHelper.isAvailable(this.selectedStatisticsValue) ? this.selectedStatisticsValue : "Chases";
    return compareAgainst === name ? "activeStat" : "clearStat";
  }

  statisticSelectedClass(name: string): string {
    const compareAgainst = StringHelper.isAvailable(this.selectedStatisticsValue) ? this.selectedStatisticsValue : "Chases";
    return compareAgainst === name ? "active" : "";
  }

  private getStatistics(): void {
    const type = this.pageType;
    if (type === "or1") {
    forkJoin([
      this.clinicalPageService
        .getStatisticsData(type),
      this.overreadFeedbackService.getFeedbackChallengeStatsCount(),
    ]).subscribe(([overreadStats, feedbackChallengeStatsCount]) => {
        this.overreadFeedbackChallengeCount = feedbackChallengeStatsCount;
        overreadStats.push(new ListItem({ key: "Feedback Challenges", value: this.overreadFeedbackChallengeCount.toString(), url: "/clinical/view/challenges" }));
        this.headerStatistics = List(overreadStats);
        this.changeDetector.markForCheck();
    });
  } else {
    this.clinicalPageService
      .getStatisticsData(type)
      .subscribe(overreadStats => {
        this.headerStatistics = List(overreadStats);
        this.changeDetector.markForCheck();
      });
  }
  }

  private createGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "NavigationType",
        value: this.pageType,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "StatisticsFilter",
        value: this.selectedStatisticsValue,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChartAcquired",
        name: "Chart Acquired Date",
        value: null,
        show: true,
      }),
    ];
    if (this.pageType === "docqa") {
      this.additionalColumns = [new GridColumnDefinition({ field: "memberValidationReason", header: "Move Back Reason" })];
    }
    this.changeDetector.markForCheck();
  }


  private get hasValidPageType(): boolean {
    return StringHelper.isAvailable(this.pageType) && this.possiblePageTypes.includes(this.pageType);
  }

  private clearPage(): void {
    this.selectedStatisticValue = "";
  }

  get isClinical(): boolean {
    return this.pageType !== "docqa";
  }

  trackByIndex(index, item) {
    return index;
  }
}
