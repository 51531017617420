import { List } from "immutable";
import { TimelineItem } from "../../../shared/timeline/timeline-item.model";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { RetrievalContactHistory } from "./retrieval-detail-contact-history.model";

export class RetrievalTimelineItem {
  readonly specificDay: string;
  readonly timelineGroup: List<TimelineItem> | List<RetrievalContactHistory>;

  constructor(options: {
    specificDay: string;
    timelineGroup: List<TimelineItem> | List<RetrievalContactHistory>;
  }) {
    this.specificDay = StringHelper.clean(options.specificDay);
    this.timelineGroup = options.timelineGroup;
  }
}

