import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ListItem } from "../../../../shared/list/list-item";
import { LookupModel } from "../../../../shared/models/lookup-model";
import { obsolete } from "../../../../utilities/decorators/obsolete.decorator";
import { ClinicalMeasureListItem } from "./clinical-measure-list-item.model";

@Injectable({
  providedIn: "root",
})
export class ClinicalPageService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getStatisticsData(type: string): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}clinical/pagestatistics?type=${type}`;
    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  @obsolete("app/platform/modules/retrieval/retrieval-page/retrieval-page.service.ts") getProjectList(): Observable<LookupModel[]> {
    const url = `${this.baseApiUrl}project/lookup`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "LookupModel"))
    );
  }

  getMeasuresList(): Observable<ClinicalMeasureListItem[]> {
    const url = `${this.baseApiUrl}clinical/measures`;
    return this.http.get(url).pipe(map((response: any) => response as ClinicalMeasureListItem[]));
  }
}
