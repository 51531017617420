import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-project-summary",
  templateUrl: "./project-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.PROJECT_SUMMARY;

    constructor() {
        this.filters = ["Projects", "ChaseTags"];
    }

}
