import { PageEntryItem } from "./page-entry-item.model";

export class AuditPageEntries {
  readonly auditPackageItemId: number;
  readonly auditPackageId: number;
  readonly pageEntries?: PageEntryItem[];
  readonly isDataEntryPrinting?: boolean;
  readonly dataEntryPrintingUrl?: string;

  constructor(options: {
    auditPackageItemId?: number;
    auditPackageId: number;
    pageEntries?: PageEntryItem[];
    isDataEntryPrinting?: boolean;
    dataEntryPrintingUrl?: string;

  }) {
    this.auditPackageItemId = options.auditPackageItemId;
    this.auditPackageId = options.auditPackageId;
    this.pageEntries = options.pageEntries;
    this.isDataEntryPrinting = options.isDataEntryPrinting;
    this.dataEntryPrintingUrl = options.dataEntryPrintingUrl;
  }
}
