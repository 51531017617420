import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconFacebookComponent } from "./icon-facebook.component";
import { IconLinkedinComponent } from "./icon-linkedin.component";
import { IconTwitterComponent } from "./icon-twitter.component";
import { IconComponent } from "./icon.component";
import { IconstackComponent } from "./iconstack.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IconComponent,
    IconstackComponent,
    IconFacebookComponent,
    IconLinkedinComponent,
    IconTwitterComponent,
  ],
  exports: [
    IconComponent,
    IconstackComponent,
    IconFacebookComponent,
    IconLinkedinComponent,
    IconTwitterComponent,
  ],
})
export class IconModule { }
