import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BulkAction } from "../bulk-actions/bulk-action.model";

@Component({
  selector: "app-bulk-actions-dropdown",
  templateUrl: "./bulk-actions-dropdown.component.html",
  styleUrls: ["./bulk-actions-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkActionsDropdownComponent implements OnInit, OnChanges {
  @Input() actions: BulkAction[];
  @Input() selectedItems: any[];
  @Input() hasData: boolean;
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter(null);
  items: SelectableInput[];
  action: string;
  disabled = true;

  constructor(
    private automapper: AutomapperService
  ) { }

  ngOnInit(): void {
    of(this.actions).pipe(
      map(this.automapper.curryMany("BulkAction", "SelectableInput"))
    ).subscribe(data => {
        this.items = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItems) {
      if (changes.selectedItems.isFirstChange()) { return; }
      const index = this.actions.findIndex(x => x.name === this.action);
      if (ArrayHelper.isAvailable(changes.selectedItems.currentValue)) {
        this.items.filter(x => !this.alwaysEnabledKeys.includes(x.text)).forEach(x => x.disabled = false);
        if (index > 0) {
          this.disabled = false;
        }
      } else {
        this.items.filter(x => !this.alwaysEnabledKeys.includes(x.text)).forEach(x => x.disabled = true);
        if (index > 0) {
          this.disabled = true;
        }
      }
    }

    if (changes.actions && ArrayHelper.isAvailable(this.selectedItems)) {
      this.items.filter(x => !this.alwaysEnabledKeys.includes(x.text))
        .forEach(x => {
          const index = this.actions.findIndex(a => a.name === x.text);
          if (index > 0) {
            x.disabled = this.actions[index].disabled;
          }
        });
    }
  }

  onClick(): void {
    const index = this.actions.findIndex(x => x.name === this.action);
    this.actions[index].action(this.selectedItems);
    this.action = "";
    this.disabled = true;
  }

  enableButton(event: any): void {
    this.disabled = false;
    this.onSelectionChange.emit(event);
  }

  get alwaysEnabledKeys(): string[] {
    return this.actions.filter(x => x.alwaysEnabled).map(x => x.name);
  }

}
