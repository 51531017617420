<div [formGroup]="formGroup" class="control control--input control--calendar" [hidden]="hidden">
<div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    </div>
    <p-calendar appendTo="body"
                [showIcon]="true"
                [styleClass]="classes"
                [readonlyInput]="model.readonly"
                [inputId]="model.key"
                [maxDate]="model.maxDate"
                [hideOnDateTimeSelect]="true"
                [placeholder]="model.placeholder"
                [formControlName]="model.key"
                (onSelect)="onChange.emit($event);"
                (onInput)="onInput.emit($event);"
                (onFocus)="onFocus.emit($event)"
                (onBlur)="onBlur.emit($event)" [minDate]="model.minDate">
    </p-calendar>
  </div>