import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export const mapChaseDetailHealthCardIcd = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "HealthCardIcd")
    .forMember("chaseId", o => o.chaseId)
    .forMember("diseaseId", o => o.diseaseId)
    .forMember("diseaseName", o => o.diseaseName)
    .forMember("endDate", o => o.endDate)
    .forMember("icdCode", o => o.icdCode)
    .forMember("reportingStatusName", o => o.reportingStatusName)
    .forMember("startDate", o => o.startDate)
    .forMember("validationReasonCode", o => o.validationReasonCode);
};

export class HealthCardIcd {
  chaseId?: number;
  reportingStatusName?: number;
  diseaseId?: string;
  diseaseName?: string;
  endDate?: string;
  icdCode?: string;
  startDate?: string;
  validationReasonCode?: string;

  constructor(options: Partial<HealthCardIcd> = {}) {
    this.chaseId = options.chaseId;
    this.reportingStatusName = options.reportingStatusName;
    this.diseaseId = StringHelper.clean(options.diseaseId);
    this.diseaseName = StringHelper.clean(options.diseaseName);
    this.endDate = StringHelper.clean(options.endDate);
    this.icdCode = StringHelper.clean(options.icdCode);
    this.startDate = StringHelper.clean(options.startDate);
    this.validationReasonCode = StringHelper.clean(options.validationReasonCode);
  }

  get dosRange(): string {
    const startDateFormatted = DateHelper.isAvailable(this.startDate) ? DateHelper.format(this.startDate) : "00/00/0000";
    const endDateFormatted = DateHelper.isAvailable(this.endDate) ? DateHelper.format(this.endDate) : "00/00/0000";
    return `${startDateFormatted} - ${endDateFormatted}`;
  }
}
