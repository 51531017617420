import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MemberDetail } from "./member-detail.model";

@Injectable()
export class MemberDetailService {
  state = new BehaviorSubject<MemberDetail>(new MemberDetail());
  showMemberWithChasesFlag = new BehaviorSubject<string>(null);

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  setData(data: Partial<MemberDetail>): void {
    const newData = new MemberDetail({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  clearData(): void {
    this.state.next(new MemberDetail());
  }

  setShowMemberWithChasesFlag(show: string): void {
    this.showMemberWithChasesFlag.next(show);
  }

  getArchiveMemberDetail(masterMemberId: number, dataSet: string): Observable<MemberDetail[]> {
    const url = `${this.baseApiUrl}member/query`;
    const headers = new HttpHeaders().set("content-type", "application/json");
    const searchRequest = {
      masterMemberId,
      dataSet,
    };

    return this.http.post(url, searchRequest, { headers }).pipe(
      map(this.automapper.curryMany("default", "MemberDetail"))
    );
  }
}
