import { IAutoMapper } from "../interfaces";

export const mapAlertNotification = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "AlertNotification")
    .forMember("alertID", o => o.alertID)
    .forMember("alertType", o => o.alertType)
    .forMember("messsage", o => o.messsage)
    .forMember("createdDate", o => o.createdDate)
    .forMember("objectType", o => o.objectType)
    .forMember("objectId", o => o.objectId)
    .forMember("loggedInUserId", o => o.loggedInUserId)
    .forMember("timeStamp", o => o.timeStamp)
    .forMember("alertURL", o => o.alertURL)
    .forMember("recordCount", o => o.recordCount);
};

