import { FormGroup } from "@angular/forms";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { DynamicControl, IDynamicControlOptions } from "../dynamic-control.model";
import { DynamicFormHelper } from "../dynamic-form-helper";

export interface IDynamicGroupOptions extends IDynamicControlOptions {
  header?: string;
  controls?: DynamicControl[];
}

export abstract class DynamicGroup extends DynamicControl implements IDynamicGroupOptions {
  header: string;
  controls: DynamicControl[];


  constructor(options: IDynamicGroupOptions = {}) {
    super(options);
    this.header = options.header || "";
    this.controls = ArrayHelper.clean(options.controls);
  }


  get hasHeader(): boolean {
    return StringHelper.isAvailable(this.header);
  }

  create(): FormGroup {
    const formControls = this.controls.reduce(DynamicFormHelper.createControls, {});
    const form = new FormGroup(formControls);

    return form;
  }
}
