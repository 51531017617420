import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-top50-aids-with-open-chases",
  templateUrl: "./top50-aids-with-open-chases.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Top50AidsWithOpenChasesComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.TOP_AIDS_WITH_OPEN_CHASE;

    constructor() {
        this.filters = ["Projects", "Products", "LOB", "RetrievalOwner"];
    }

}
