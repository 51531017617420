import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Radiobutton } from "../../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { WorkflowEntityAttribute } from "../../../../../api/workflow/workflow-entity.model";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { WorkflowService } from "../../../../../api/workflow/workflow.service";
import { ChaseSubmissionItem } from "../../../../project/project-config/models/chase-submission-item.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { SUBMISSION_ITEM_ANSWER } from "../attributes";

interface ISubmissionItemAnswer {
  [key: number]: string;
}
@Component({
  selector: "member-yes-no-radio",
  templateUrl: "./yes-no-radio.component.html",
  styleUrls: ["./yes-no-radio.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoRadioComponent implements OnInit, OnDestroy, OnChanges {
  private sink = new SubSink();
  private hasChaseId = false;
  chaseId: number;
  @Output() researchValidated = new EventEmitter<boolean>();
  @Output() researchEnabled = new EventEmitter<boolean>();
  @Input() researchDisable = false;
  researchForm: FormGroup;
  checkboxForm: FormGroup;
  researchInputs: Radiobutton[];
  checkboxInput: Checkbox;
  workflowStatus: WorkflowStatusDb;
  chaseSubmissionItem: ChaseSubmissionItem[];
  submissionItemAnswerString: string;

  constructor(
    private readonly formService: FormService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly workflowService: WorkflowService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.researchDisable.currentValue && !changes.researchDisable.previousValue) {
        this.checkboxForm.get("selectAll").disable();
      }
    if (!changes.researchDisable.currentValue && changes.researchDisable.previousValue) {
        this.checkboxForm.get("selectAll").enable();
      }
  }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        if (!this.hasChaseId && NumberHelper.isGreaterThan(state.chaseId, 0) && state.workflowStatus) {
          this.hasChaseId = true;
          this.chaseId = state.chaseId;
          this.workflowStatus = state.workflowStatus;
          this.chaseSubmissionItem = state.chaseSubmissionItem;
          this.loadForm(this.researchItems);
          this.getSubmissionItemAnswer();
          this.changeDetector.markForCheck();
        }
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(key: string): void {
    if (this.researchForm.get(key).value === 0) {
      this.checkboxForm.get("selectAll").setValue(null);
    } else if (Object.keys(this.researchForm.value).every(x => this.researchForm.value[x] === 1)) {
      this.checkboxForm.get("selectAll").setValue(1);
    }

    this.saveSubmissionItemAnswer();
  }

  onSelect(): void {
    if (this.checkboxForm.get("selectAll").value) {
      this.researchInputs.map(x => this.researchForm.get(x.key).setValue(1));
      this.saveSubmissionItemAnswer();
    } else {
      this.researchInputs.map(x => this.researchForm.get(x.key).setValue(null));
      this.researchValidated.emit(false);
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  get researchItems(): ChaseSubmissionItem[] {
    return this.chaseSubmissionItem ? this.chaseSubmissionItem.filter(item => item.workflowStatus === WorkflowStatusDb[this.workflowStatus]) : [];
  }

  get displayResearchItems(): boolean {
    const displayResearchItems = this.researchItems.length > 0;
    this.researchEnabled.emit(displayResearchItems);
    return displayResearchItems;
  }

  private getSubmissionItemAnswer(): void {
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.workflowService.getSubmissionItemAnswer(this.chaseId).subscribe(submissionItemAnswer => {
        this.submissionItemAnswerString = StringHelper.clean(submissionItemAnswer.value as string);
        this.loadFormAnswers(this.submissionItemAnswerString);
      });
    }
  }

  private saveSubmissionItemAnswer(): void {
    if (this.researchForm.valid) {
      const entityObject = new WorkflowEntityAttribute({
        ...SUBMISSION_ITEM_ANSWER,
        chaseId: this.chaseId,
        attributeValue: this.convertToKeyValueString(this.researchForm.value),
      });
      this.workflowService.setEntityAttribute(entityObject).subscribe(() => this.researchValidated.emit(this.researchForm.valid));
    }
  }

  private loadForm(researchItems: ChaseSubmissionItem[]): void {
    this.researchInputs = researchItems.map(item => new Radiobutton({
      key: item.itemId,
      label: item.itemText,
      options: [
        new SelectableInput({ text: "Yes", value: 1 }),
        new SelectableInput({ text: "No", value: 0 }),
      ],
      validators: [Validators.required],
    }));

    this.checkboxInput = new Checkbox({
      key: "selectAll",
      label: "Select All",
    });

    this.researchForm = this.formService.createFormGroup(this.researchInputs);
    this.checkboxForm = this.formService.createFormGroup([this.checkboxInput]);
  }

  private loadFormAnswers(answerString: string): void {
    if (StringHelper.isAvailable(answerString)) {
      const convertedObjAnswers = this.convertToKeyValueObject(answerString);
      Object.keys(convertedObjAnswers).forEach(key => {
        if (this.researchForm.controls.hasOwnProperty(key)) {
          this.researchForm.controls[key].setValue(Number(convertedObjAnswers[key]));
        }
      });
    } else {
      this.researchInputs.forEach(x => this.researchForm.controls[x.key].setValue(0));
    }

    if (Object.keys(this.researchForm.value).every(x => this.researchForm.value[x] === 1)) {
      this.checkboxForm.get("selectAll").setValue(1);
    }

    this.researchValidated.emit(this.researchForm.valid);
  }

  private convertToKeyValueString(formValue: ISubmissionItemAnswer): string {
    let newString = "";
    Object.keys(formValue).forEach(key => newString += `${key}=${this.researchForm.value[key]};`);
    return newString;
  }

  private convertToKeyValueObject(formValue: string): ISubmissionItemAnswer {
    return formValue.slice(0, -1).split(";").reduce((acc, next) => {
      const [key, value] = next.split("=");
      acc[key] = value;
      return acc;
    },                                              {});
  }
}
