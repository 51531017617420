import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "app-document-page-thumbnail-label",
  templateUrl: "./document-page-thumbnail-label.component.html",
  styleUrls: ["./document-page-thumbnail-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageThumbnailLabelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
