import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { TagBulkUpdate } from "../bulk-update-tag.model";

@Injectable({
  providedIn: "root",
})
export class BulkUpdateTagService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  finishChaseTagBulkUpdate(bulkUpdate: TagBulkUpdate): Observable<null> {
    const url = `${this.baseApiUrl}bulkupdate/tag/chases/validate`;

    return this.http.post(url, bulkUpdate) as Observable<null>;
  }

  finishAddressTagBulkUpdate(bulkUpdate: TagBulkUpdate): Observable<null> {
    const url = `${this.baseApiUrl}bulkupdate/tag/addresses/validate`;

    return this.http.post(url, bulkUpdate) as Observable<null>;
  }

  finishDocumentTagBulkUpdate(bulkUpdate: TagBulkUpdate): Observable<null> {
    const url = `${this.baseApiUrl}bulkupdate/tag/documents/validate`;

    return this.http.post(url, bulkUpdate) as Observable<null>;
  }

}
