import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { CreateChaseService } from "../create-new-chase.service";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { NewChase } from "../new-chase.model";


@Component({
  selector: "app-create-new-chase-gap",
  templateUrl: "./create-new-chase-gap.component.html",
  styleUrls: ["./create-new-chase-gap.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewChaseGapComponent implements OnInit, OnChanges {
  @Output() onShowChild = new EventEmitter<any>();
  @Input() isGapScreenActive: boolean;
  newChase: NewChase;
  measureId: number;
  gapFormGroup: FormGroup;
  numeratorInput: CheckboxGroup;
  constructor(private createChaseService: CreateChaseService,
              private readonly formService: FormService,
              private readonly automapper: AutomapperService,
              private changeDetector: ChangeDetectorRef,
              private dataExchangeService: GapQueryDataExchangeService
    ) {
   }

  ngOnInit() {
    this.createForm();
    this.newChase = this.createChaseService.chaseModel;
    this.dataExchangeService.getNewChaseData().subscribe(result => {
      if (result) {
        this.newChase = result;
        this.newChase.measureId = this.createChaseService.chaseProjectItems.measureId;
        this.newChase.measureCode = this.createChaseService.chaseProjectItems.measureCode;
        this.getNumeratorList();
      }
    });
  }

  ngOnChanges(): void {
   if (this.isGapScreenActive) {
     this.getNumeratorList();
   }
  }

  getNumeratorList() {
    const isProviderIdAvailable = NumberHelper.isAvailable(this.createChaseService.chaseModel.projectId);
    if (isProviderIdAvailable) {
      this.createChaseService.getNumeratorList(this.newChase.projectId, this.newChase.measureId)
      .pipe(map(this.automapper.curryMany("NumeratorListItem", "SelectableInput")))
      .subscribe(result => {
        this.numeratorInput = new CheckboxGroup({ ...this.numeratorInput , options: result } as any);
        this.changeDetector.markForCheck();
        this.formService.updateDom.next();
      });
    }
  }

  private createForm(): void {
    this.numeratorInput =
        new CheckboxGroup({
            key: "numerator",
            label: "",
            validators: [Validators.required],
        });

    this.gapFormGroup = this.formService.createFormGroup([
          this.numeratorInput,
      ]);
  }


  get isShowNextStepButton(): boolean {
    return this.gapFormGroup.valid;
}

  nextStepProcess(): void {
  if (this.gapFormGroup.valid) {
      this.newChase.numeratorList = this.gapFormGroup.value;
      this.createChaseService.chaseModel.numeratorList = this.newChase.numeratorList;
      this.getControlsCount();
      this.changeDetector.markForCheck();
      this.onShowChild.emit(this.createChaseService.chaseModel);
      this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
  } else {
      this.markAllAsTouched();
  }
}

  private markAllAsTouched(): void {
  Object.keys(this.gapFormGroup.controls).forEach(key => {
      const control = this.gapFormGroup.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
  });
  this.formService.updateDom.next();
}

  reset() {
    this.gapFormGroup.reset();
}

  getControlsCount(): void {
  Object.keys(this.gapFormGroup.controls)
      .map(x => {
          if (this.gapFormGroup.controls[x].value) {
              this.newChase.gapsCount = this.gapFormGroup.controls[x].value.length;
          }
      });
}

}
