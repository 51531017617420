<app-modal class="search"
           [(visible)]="visible"
           (visibleChange)="visibleChange.emit($event)"
           (onShow)="showProviderSearchTool()"
           (onHide)="hideProviderSearchTool()"
           (onCancel)="cancel()">
  <div class="control control--input control--textbox control--search">
    <div class="control__header">
      <label class="control__header__label" for="SearchProviderInput">Search for Provider</label>
    </div>
    <input pInputText
           class="control__input control__input--textbox"
           name="SearchProviderInput"
           id="SearchProviderInput"
           placeholder="Ex: John Smith Los Angeles 90012"
           [(ngModel)]="providerSearchText"
           (keyup.enter)="search(null)" />
    <app-button text="Search" (onClick)="search($event)"></app-button>
    <div class="search__example">Search across all columns (Ex: John Smith Los Angeles 90012)</div>
  </div>
  <app-national-provider-grid class="providers"
                                [callProvider]="callProvider"
                                (onSelect)="selectProvider($event)"
                                (onInitiateCall)="initiateCall($event)"
                                [additionalFilters]="additionalFilters"></app-national-provider-grid>
</app-modal>
