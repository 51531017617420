import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ListItem } from "../../../shared/list/list-item";
import { MenuItem } from "../../../shared/menu/menu-item.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumeratorItem } from "../../api/numerator/numerator-item.model";
import { ClinicalMeasureListItem } from "../clinical/clinical-page/clinical-measure-list-item.model";
import { ProjectType } from "../project/project-type.enum";
import { AnalyticsRequest } from "./analytics-request.model";
import { EntityAttribute } from "./entity-attribute.model";
import { AnalyticsItemRequest } from "./models/analytics-item-request.model";
import { AnalyticsItem } from "./models/analytics-item.model";
import { DashboardCount } from "./models/dashboard-count.model";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getLookerUrl(analyticsRequest: AnalyticsRequest): Observable<string> {
    const url = `${this.baseApiUrl}analytics/lookerUrl`;

    return this.http.post(url, analyticsRequest).pipe(map((response: any) => response as string));
  }

  getValueForAnalyticsRequestAsCsv(formValues: SelectableInput[], isUseValue: boolean) {
    let valueAsCsv = "";
    if (ArrayHelper.isAvailable(formValues)) {
      valueAsCsv = formValues.filter(a => a != null)
        .map(a => isUseValue ? a.value : a.text)
        .join(",");
    }

    return valueAsCsv;
  }

  getDashboardCount(projectType: ProjectType): Observable<DashboardCount[]> {
    const url = `${this.baseApiUrl}analytics/dashboardcount?projectType=${projectType}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DashboardCount"))
    );
  }

  getAnalyticMenuItems(analyticsItemRequest: AnalyticsItemRequest): Observable<MenuItem[]> {
    const url = `${this.baseApiUrl}analytics/menu`;
    return this.http.post(url, analyticsItemRequest).pipe(map(this.automapper.curryMany("default-sub-menu", "MenuItem")));
  }

  getReportingList(analyticsItemRequest: AnalyticsItemRequest): Observable<AnalyticsItem[]> {
    const url = `${this.baseApiUrl}analytics/reports`;
    return this.http.post(url, analyticsItemRequest).pipe(map(this.automapper.curryMany("default", "AnalyticsItem")));
  }


  searchReport(reportName: string, projectType: ProjectType): Observable<AnalyticsItem[]> {
    const url = `${this.baseApiUrl}analytics/search?reportName=${reportName}&projectType=${projectType}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "AnalyticsItem"))
    );
  }

  downLoadReport(lookId: number, projectIds: string): Observable<boolean> {
    const url = `${this.baseApiUrl}analytics/report/download?lookId=${lookId}&projectIds=${projectIds}`;

    return this.http.post(url, null, { observe: "response" })
      .pipe(map((response: HttpResponse<any>): any => response.ok ? response.body : false));
  }

  getCallTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}retrieval/calltypes`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getContactMethodTypes(contactMethodTypeIdAsCsv: string, contactMethodCategory?: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}retrieval/contactmethodtypes?contactMethodTypeIdAsCsv=${contactMethodTypeIdAsCsv}&contactMethodCategory=${contactMethodCategory}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getHios(projectIds: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}analytics/hios?projectIdAsCsv=${projectIds}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }
  getStratumLevels(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/stratumlevels`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getRxc(projectIds: string): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/rxc?projectIdAsCsv=${projectIds}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getSubmissionStatus(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/submissionstatus`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getHccPositions(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/hccpositions`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getOrganizations(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/organizations`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getProjects(organizationdIds: string, projectTypeId: number): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/projects?organizationIds=${organizationdIds}&projectTypeId=${projectTypeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }
  getMeasuresList(projectIdsAsCsv?: string): Observable<ClinicalMeasureListItem[]> {
    const url = `${this.baseApiUrl}measure/byprojectids?projectIdsAsCsv=${projectIdsAsCsv}`;

    return this.http.get(url).pipe(map((response: any) => response as ClinicalMeasureListItem[]));
  }

  getClients(organizationdIds: string): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/clients?organizationIds=${organizationdIds}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getProjectsByClients(clientIds: string, projectTypeId: number): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/clients/projects?clientIds=${clientIds}&projectTypeId=${projectTypeId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getCalenderFutureDate(): Date {
    return new Date(new Date().setDate(new Date().getDate() + 1));
  }

  getEntityAttributes(entityAttribute: EntityAttribute): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}analytics/entity/attributes`;
    return this.http.post(url, entityAttribute).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getCommonList(type: string): Observable<{ text: string | number; value: string | number }[]> {
    const url = `${this.baseApiUrl}analytics/${type}`;
    return this.http.get<[]>(url).pipe(
      map(this.simpleObjectMapping)
    );
  }

  getNumerators(projectId: string, attributeId: string): Observable<NumeratorItem[]> {
    const url = `${this.baseApiUrl}analytics/numerators`;
    const params = new HttpParams().append("projectId", projectId).append("attributeId", attributeId);
    return this.http.get<NumeratorItem[]>(url, { params });
  }

  getDocumentStateGroups(): Observable<{ text: string | number; value: string | number }[]> {
    const url = `${this.baseApiUrl}analytics/documentstategroup`;
    return this.http.get<[]>(url).pipe(map(documentStates => this.simpleObjectMapping(documentStates, 1)));
  }

  simpleObjectMapping(arr: any[], position = 0) {
    if (ArrayHelper.isAvailable(arr)) {
      return arr.map((object: Object) => {
        const value = Object.values(object)[position]?.toString();
        return { text: value, value };
      });
    }
    return [];
  }

  getVendorInvoiceTypes(): Observable<{ vendorInvoiceTypeId: number; name: string }[]> {
    const url = `${this.baseApiUrl}analytics/invoicetypes`;
    return this.http.get<[]>(url);
  }
}
