<div class="login">

  <div class="login__icon">
    <app-icon iconStyle="reveleer" iconType="reveleer" iconName="logo-vertical"></app-icon>
  </div>

  <div *ngIf="!refresh" class="login__form">
    <!-- the form tag allows a submit upon pressing enter on the keyboard -->
    <form [formGroup]="form">
      <form-factory
        *ngFor="let model of models; trackBy: trackByIndex"
        [formGroup]="form" (onChange)="removeWhiteSpace(model)"
        [model]="model"></form-factory>

      <app-messages key="loginMessage"></app-messages>

      <a class="login__reset-password" href="{{identityMicroserviceUrl}}">Forgot Password?</a>

      <app-button (onClick)="login()" text="LOGIN"></app-button>
    </form>
  </div>

  <div *ngIf="!refresh" class="login__logout">
    Or <a href="login/sso" target="_self">Continue with Single Sign-On</a>
  </div>

  <div *ngIf="refresh" class="login__logout">
    Logging out...
  </div>

</div>

<div class="footer">
  <div class="footer-text">
    By signing in, you are agreeing to our
    <a class="privacy-policy" (click)="showPrivacyPolicy($event)">Privacy Policy</a>
  </div>
  
  <div class="footer-links"> 
    <app-icon-linkedin></app-icon-linkedin>
    <app-icon-facebook></app-icon-facebook>
    <app-icon-twitter></app-icon-twitter>
  </div>
</div>

<app-privacy-policy-modal [(visible)]="isPrivacyPolicyVisible"></app-privacy-policy-modal>