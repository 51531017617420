<div class="dashboard-dr-container">
  <app-detail-main-template>
    <div container-header>
      <h3>My Documents</h3>
      <div class="dashboard-container">
        <div class="item" *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item)">
            <span>{{item.key}}</span><br>
            <a class="users-land-stats-item bold">
              <span (click)="filterGrid(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
            </a>
        </div>
      </div>
    </div>
    <div container-body>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <app-server-grid [configuration]="gridConfiguration"
                          [(request)]="gridRequest"
                          [filterTemplate]="filterTemplate"
                          [refresh]="refreshGrid">

            <ng-template #filterTemplate let-form>
              <form [formGroup]="form" class="ui-modal-width">
                <app-tab-menu orientation="left">
                  <app-tab-panel header="Document Name">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="documentNameInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel class="checkbox-group__halves" header="Projects">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="projectNameInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel class="checkbox-group__halves" header="Status">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="statusInput"></form-factory>
                    </div>
                  </app-tab-panel>

                  <app-tab-panel header="Document Date">
                    <div class="ui-g-12 ui-md-12 ui-lg-12">
                      <form-factory [formGroup]="form" [model]="documentDateInput"
                                    ></form-factory>
                    </div>
                  </app-tab-panel>

                </app-tab-menu>
              </form>
            </ng-template>

          </app-server-grid>
        </div>
        <div *ngIf="!hasClientLeadRole" class="ui-g-12 ui-md-12 ui-lg-12 text-center">          
          <app-button *ngIf="roleIdCount===1"
                      class="work"
                      text="Request Additional Work"
                      (onClick)="getNextChase()"
                      [disabled]="!isRequestAdditionalWorkEnabled"></app-button>
        </div>
        <div *ngIf="!hasClientLeadRole" class="splitbuttoncontainer">
          <app-split-button class="button"
                            *ngIf="roleIdCount>1"
                            text="Request Additional Work"
                            classes="primary-action"
                            [model]="buttonRoleList"
                            [disabled]="!isRequestAdditionalWorkEnabled"></app-split-button>
        </div>
      </div>
    </div>
  </app-detail-main-template>
</div>
