import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
    selector: "app-intake-productivity",
    templateUrl: "./intake-productivity.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntakeProductivityComponent {
    filters: string[] = ["Projects", "UpdateDate", "Products", "LOB", "RetrievalOwner"];
    dashboardType = LookerRiskDashboardType.INTAKE_PRODUCTIVITY_REPORT;
    hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.UPDATE_DATE,
                             HideFilters.HEALTH_PLAN, HideFilters.LINE_OF_BUSINESS, HideFilters.CONTRACT_NUMBER, HideFilters.MEASURE2,
                             HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.UPDATED_BY];
}
