import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../core/automapper/automapper.service";
import { BASE_API_URL } from "../core/environment.tokens";
import { MenuItem } from "../shared/menu/menu-item.model";


@Injectable()
export class PlatformService {
  isReviewVisible = new BehaviorSubject<boolean>(false);
  isEnableWidgetSection = new BehaviorSubject<boolean>(null);
  isShowWidgetPanel = new BehaviorSubject<boolean>(null);
  isShowWidgetPanel$ = this.isShowWidgetPanel.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  getMenu(): Observable<MenuItem[]> {
    const url = `${this.baseApiUrl}Navigation/Metadata`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "MenuItem")));
  }

}
