import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MenuItem } from "../../../../../../../shared/menu/menu-item.model";
import { RiskService } from "../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { RiskDxService } from "../risk-dx.service";

@Component({
  selector: "app-risk-dx-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskDxMenuComponent implements OnInit {
  private sink = new SubSink();
  dxMenuItems: MenuItem[] = [];
  nextButtonClicked: Subscription;
  @Output() onTabSelected = new EventEmitter<MenuItem>();

  constructor(
    private readonly riskDxService: RiskDxService,
    private riskService: RiskService,
    private readonly changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setMenuItems();

    this.sink.add(
      this.riskDxService.data$
        .pipe(
          map(riskData => {
            const { extra } = this.dxMenuItems.find(item => item.description === "Dx Review");
            extra.count = riskData.dxCount;
          })
        )
        .subscribe(_ => this.changeDetector.markForCheck()),
      this.nextButtonClicked = this.riskService.getNextButtonClicked().subscribe(() => {
        const dxMenuItem: MenuItem = new MenuItem(
          {
            description: "Dx Review",
            path: "",
          });
        this.onSelected(dxMenuItem);
      })
    );
  }

  onSelected(menu: MenuItem): void {
    this.onTabSelected.emit(menu);
  }

  trackByFn(index): void {
    return index;
  }

  private setMenuItems(): void {
    this.dxMenuItems = this.riskDxService.menuItems();
  }
}
