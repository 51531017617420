import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../auth/user-token.model";
import { UserService } from "../../../../../core/user/user.service";
import { ChaseIdComponent } from "../../../../../shared/chase-grid/chase-id.component";
import { BasicGridComponent } from "../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridService } from "../../../../../shared/grid/grid.service";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { OverreadFeedbackQueryItem } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-item.model";
import { OverreadFeedbackQuerySearch } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-search.model";
import { OverreadFeedbackStatus } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";

@Component({
  selector: "app-clinical-orfeedback",
  templateUrl: "./clinical-orfeedback.component.html",
  styleUrls: ["./clinical-orfeedback.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalOrFeedbackComponent implements OnInit, OnDestroy {
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @ViewChild("reviewColumn", { static: true }) reviewColumn: TemplateRef<any>;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  private sink = new SubSink();
  private user: UserToken;
  assignedTo: number;
  initializeGridData: OverreadFeedbackQueryItem[] = [];
  gridData: OverreadFeedbackQueryItem[] = [];
  criticalErrorToggle = false;
  nonCriticalErrorToggle = false;
  exportAllBtn = false;
  exportButtonText = "Export All";
  @ViewChild(BasicGridComponent, { static: true }) basicGridComponent: BasicGridComponent;

  constructor(
    private router: Router,
    private overreadFeedbackService: OverreadFeedbackService,
    private userService: UserService,
    private gridService: GridService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.sink.add(
      this.overreadFeedbackService.getOverreadFeedbackList(new OverreadFeedbackQuerySearch())
        .subscribe(data => {
          this.nonCriticalErrorToggle = true;
          if (ArrayHelper.isAvailable(data)) {
            if (this.isManagerGrid) {
              this.initializeGridData = data.filter(x => x.status !== OverreadFeedbackStatus.ABSTRACTOR);
              this.gridData = this.initializeGridData;
            } else {
              this.initializeGridData = data.filter(x => x.status !== OverreadFeedbackStatus.MANAGER && ((
                x.abstractorUserId === this.user.userId && (
                  x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED ||
                  x.status === OverreadFeedbackStatus.ABSTRACTOR) || x.status === OverreadFeedbackStatus.COMPLETED) || (
                  x.overreaderUserId === this.user.userId &&
                  x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED || x.status === OverreadFeedbackStatus.COMPLETED))
              );
              this.gridData = this.initializeGridData;
            }
            this.toggleNoncriticalErrors();
            this.nonCriticalErrorToggle = true;
            this.changeDetector.markForCheck();
          }
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  export() {
    const criticalErrors = this.initializeGridData.filter(x => x.status === OverreadFeedbackStatus.COMPLETED &&
      ((x.abstractorUserId === this.user.userId && x.abstractorReceiptStatus === OverreadFeedbackStatus.RECEIVED && x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED)
        || (x.overreaderUserId === this.user.userId && x.overreaderReceiptStatus === OverreadFeedbackStatus.RECEIVED && x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED)));

    const noncriticalErrors = this.initializeGridData.filter(x => x.status !== OverreadFeedbackStatus.COMPLETED ||
      (x.status === OverreadFeedbackStatus.COMPLETED && (x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.abstractorUserId === this.user.userId) ||
        (x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.overreaderUserId === this.user.userId)));
    const allErrors = [...criticalErrors, ...noncriticalErrors];

    this.basicGridComponent.primeGrid.value = allErrors;
    this.basicGridComponent.primeGrid.exportCSV();
    this.basicGridComponent.primeGrid.value = this.gridData;
  }
  selectionChange(event) {
    const ele = document.getElementById("export");
    ele.style.display = "";
    this.exportAllBtn = false;
  }

  get gridConfiguration(): GridConfiguration {
    const columns = [
      new GridColumnDefinition({ field: "chaseId", template: this.chaseIdColumn, header: "Chase ID", width: "135px" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "abstractorUserName", header: "Abstractor Name", show: this.isManagerGrid }),
      new GridColumnDefinition({ field: "abstractionDate", header: "Date & Time Entered", pipeName: GridPipeName.Date, format: "MM-dd-yyyy hh:mm a", timeZone: "local" }),
      new GridColumnDefinition({ field: "overreaderUserName", header: "OR Name", show: this.isManagerGrid }),
      new GridColumnDefinition({ field: "overreadDate", header: "Date & Time Entered", pipeName: GridPipeName.Date, format: "MM-dd-yyyy hh:mm a", timeZone: "local" }),
      new GridColumnDefinition({ field: "errorReviewCompletedCount", header: "# Of Review Completed" }),
      new GridColumnDefinition({ field: "errorReviewToBeCompletedCount", header: "# Of Review To Be completed" }),
      new GridColumnDefinition({ field: "errorCount", header: "# Of Errors Found" }),
      new GridColumnDefinition({ field: "status", header: "Manager Review", show: this.isManagerGrid }),
      new GridColumnDefinition({ template: this.reviewColumn, isSortableColumn: false, header: "Feedback Status", width: "150px" }),
    ];
    return new GridConfiguration({
      columns,
      pageSize: 25,
      showExportAction: true,
      showActionColumn: false,
      selectionMode: "multiple",
      pageSizeOptions: [10, 25, 50, 100],
    });
  }

  navigateToFeedback(rowData: OverreadFeedbackQueryItem): void {
    const url = this.gridService.generateLink(
      new GridColumnDefinition({ routeUrl: this.chaseIdRouteUrl } as any),
      rowData
    );
    this.router.navigateByUrl(`/${url}`);
  }

  toggleCriticalErrors(): void {
    this.criticalErrorToggle = !this.criticalErrorToggle;
    this.nonCriticalErrorToggle = false;
    this.gridData = this.initializeGridData.filter(x => x.status === OverreadFeedbackStatus.COMPLETED &&
      ((x.abstractorUserId === this.user.userId && x.abstractorReceiptStatus === OverreadFeedbackStatus.RECEIVED && x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED)
        || (x.overreaderUserId === this.user.userId && x.overreaderReceiptStatus === OverreadFeedbackStatus.RECEIVED && x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED)));
    const ele = document.getElementById("export");
    ele.style.display = "none";
    this.exportAllBtn = true;
  }

  toggleNoncriticalErrors(): void {
    this.nonCriticalErrorToggle = !this.nonCriticalErrorToggle;
    this.criticalErrorToggle = false;
    this.gridData = this.initializeGridData.filter(x => x.status !== OverreadFeedbackStatus.COMPLETED ||
      (x.status === OverreadFeedbackStatus.COMPLETED && (x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.abstractorUserId === this.user.userId) ||
        (x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.overreaderUserId === this.user.userId)));
    const ele = document.getElementById("export");
    ele.style.display = "none";
    this.exportAllBtn = true;
  }

  isStatusCompleted(rowData: OverreadFeedbackQueryItem): boolean {
    return rowData.status === OverreadFeedbackStatus.COMPLETED;
  }

  receiptStatus(rowData: OverreadFeedbackQueryItem): string {
    const requested = rowData.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED || rowData.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED;
    return requested ? "Feedback Sent" : "Accepted";
  }

  reviewButtonText(rowData: OverreadFeedbackQueryItem): string {
    const requested = rowData.status !== OverreadFeedbackStatus.ABSTRACTOR;
    let status = this.isManagerGrid ? "Review Challenge" : requested ? "Acknowledge" : "Review Feedback";
    if (this.criticalErrorToggle) {
      const received = rowData.status === OverreadFeedbackStatus.COMPLETED &&
        ((rowData.abstractorReceiptStatus === OverreadFeedbackStatus.RECEIVED && rowData.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED) ||
          (rowData.overreaderReceiptStatus === OverreadFeedbackStatus.RECEIVED && rowData.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED));
      status = received ? "Acknowledged" : status;
    }
    return status;
  }

  get isManagerGrid(): boolean {
    return this.route.snapshot.url[1].path !== "orfeedback";
  }


  getGridData(): OverreadFeedbackQueryItem[] {
    // TODO: Toggle on Error Type when added to DB. Currently toggling complete/incomplete
    const data = this.initializeGridData;
    if (this.criticalErrorToggle) {
      return this.initializeGridData.filter(x => x.status === OverreadFeedbackStatus.COMPLETED);
    }

    if (this.nonCriticalErrorToggle) {
      return this.initializeGridData.filter(x => x.status !== OverreadFeedbackStatus.COMPLETED);
    }

    return data;
  }

  get criticalErrorBtnClass(): string {
    return this.criticalErrorToggle ? "active-button" : "";
  }

  get nonCriticalErrorBtnClass(): string {
    return this.nonCriticalErrorToggle ? "active-button" : "";
  }
}
