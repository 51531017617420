<member-risk-diagnosis-v2-admin *ngIf="hasAdminDiagnoses" 
  [riskState]="riskState" 
  [adminDiagnoses]="adminDiagnoses"
  [chaseDetailState]="chaseDetailState"
  (showVRCWarningForNoMatch)="showModal($event)"
  [showReadOnly]="showReadOnly">
</member-risk-diagnosis-v2-admin>
<member-risk-diagnosis-v2-coder *ngIf="hasCoderDiagnoses"
  [riskState]="riskState"
  [coderDiagnoses]="coderDiagnoses"
  [chaseDetailState]="chaseDetailState"
  (showVRCWarningForNoMatch)="showModal($event)">
</member-risk-diagnosis-v2-coder>
<member-risk-diagnosis-v2-eve *ngIf="hasEveDiagnoses"
  [riskState]="riskState"
  [chaseDetailState]="chaseDetailState"
  [eveCodedDiagnoses]="eveCodedDiagnoses"
  [showReadOnly]="showReadOnly"
  (showVRCWarningForNoMatch)="showModal($event)">
</member-risk-diagnosis-v2-eve>

<app-modal 
  class="diagnosis-vrc-mismatch-modal" 
  header="ATTENTION" 
  [(visible)]="isVRCWarningVisible" rejectText="Close">
  <div class="text-center">
    There is a diagnosis VRC mismatch for this claims DOS.<br />
    Please resolve issue prior to chart submission.
  </div>
</app-modal>
