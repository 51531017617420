import { IAutoMapper } from "../../core/automapper/interfaces";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapMasterProvider = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MasterProvider")
    .forMember("masterProviderId", o => o.masterProviderId)
    .forMember("nationalProviderId", o => o.nationalProviderId)
    .forMember("providerTypeId", o => o.providerTypeId)
    .forMember("providerFirstName", o => o.providerFirstName)
    .forMember("providerLastName", o => o.providerLastName)
    .forMember("providerSpecialty", o => o.providerSpecialty)
    .forMember("address1", o => o.address1)
    .forMember("address2", o => o.address2)
    .forMember("city", o => o.city)
    .forMember("state", o => o.state)
    .forMember("zipCode", o => o.zipCode)
    .forMember("phoneNumber", o => o.phoneNumber)
    .forMember("faxNumber", o => o.faxNumber);
};

export interface IMasterProviderOptions {
  masterProviderId?: number;
  nationalProviderId?: string;
  providerTypeId?: number;
  providerFirstName?: string;
  providerLastName?: string;
  providerSpecialty?: string;
  providerName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  faxNumber?: string;
}

export class MasterProvider {
  masterProviderId: number;
  nationalProviderId: string;
  providerTypeId: number;
  providerFirstName: string;
  providerLastName: string;
  providerSpecialty: string;
  providerName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  faxNumber: string;


  constructor(options: IMasterProviderOptions = {}) {
    this.masterProviderId = options.masterProviderId;
    this.nationalProviderId = StringHelper.clean(options.nationalProviderId);
    this.providerTypeId = options.providerTypeId;
    this.providerFirstName = StringHelper.clean(options.providerFirstName);
    this.providerLastName = StringHelper.clean(options.providerLastName);
    this.providerSpecialty = StringHelper.clean(options.providerSpecialty);
    this.providerName = StringHelper.clean(options.providerName);
    this.address1 = StringHelper.clean(options.address1);
    this.address2 = StringHelper.clean(options.address2);
    this.city = StringHelper.clean(options.city);
    this.state = StringHelper.clean(options.state);
    this.zipCode = StringHelper.clean(options.zipCode);
    this.phoneNumber = StringHelper.clean(options.phoneNumber);
    this.faxNumber = StringHelper.clean(options.faxNumber);
  }


  get providerFullName(): string {
    return StringHelper.clean(`${this.providerFirstName} ${this.providerLastName}`);
  }

  get fullAddress(): string {
    return StringHelper.clean(`${this.address1} ${this.address2} ${this.city}, ${this.state} ${this.zipCode}`);
  }
}
