<app-basic-grid 
  [configuration]="pendsGridConfiguration"
  [data]="pendsGridData"
  [actions]="actions"
  [filterTemplate]="filterTemplate" 
  [request]="request"
  (removeFilter)="onRemoveFilter($event)"
  class="grids-display"
  [(selection)]="chaseGridSelection"
  filterHeaderText="Pend Query"
  >

  <ng-template #filterTemplate>
    <form [formGroup]="form" class="ui-modal-width">
      <app-tab-menu orientation="left">

        <app-tab-panel header="Pend ID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chasePendId"></form-factory>
          </div>
        </app-tab-panel>
        
       <app-tab-panel class="checkbox-group" header="Pend Codes">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendCode"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__fourths" header="Measures">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measureCode"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Pend Status">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendStatus"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Chase ID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chaseId"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel class="checkbox-group__halves" header="Projects">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectName"></form-factory>
          </div>
        </app-tab-panel> 
        
      </app-tab-menu> 
    </form>
  </ng-template>
</app-basic-grid>

<app-pend-modal [(visible)]="isPendModalVisible" 
(onHide)="closeModal()"
(onUpdate)="updateChaseGrid()"
[(chases)]="selectedChases"
[clinical]="clinical">
</app-pend-modal>