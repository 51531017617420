import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-detail-clinical-productivity-and-accuracy-overall",
  templateUrl: "./detail-clinical-productivity-and-accuracy-overall.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailClinicalProductivityAndAccuracyOverallComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.DETAIL_CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL;

  constructor() {
    this.filters = ["Projects", "AbstractionDate"];
  }

}
