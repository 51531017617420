import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Component({
  selector: "app-icon-stack",
  templateUrl: "./iconstack.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconstackComponent implements OnInit {
  defaultStyle = "fas";
  defaultType = "fa";

  @Input() iconStyle = this.defaultStyle;
  @Input() iconType = this.defaultType;
  @Input() iconName = "";
  @Input() iconBaseStyle = this.defaultStyle;
  @Input() iconBaseType = this.defaultType;
  @Input() iconBase = "";


  ngOnInit() {
    StringHelper.throwIsAvailableError(this.iconStyle, "iconStyle");
    StringHelper.throwIsAvailableError(this.iconType, "iconType");
    StringHelper.throwIsAvailableError(this.iconName, "iconName");
    StringHelper.throwIsAvailableError(this.iconBaseStyle, "iconBaseStyle");
    StringHelper.throwIsAvailableError(this.iconBaseType, "iconBaseType");
    StringHelper.throwIsAvailableError(this.iconBase, "iconBase");
  }

  get classes() {
    return `${this.iconStyle} ${this.iconType}-${this.iconName} fa-stack-1x fa-inverse`;
  }

  get baseClasses() {
    return `${this.iconBaseStyle} ${this.iconBaseType}-${this.iconBase} fa-stack-2x`;
  }
}
