import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { ChaseGridModule } from "../chase-grid/chase-grid.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { ChaseSearchComponent } from "./chase-search.component";

@NgModule({
    imports: [
        ButtonModule,
        DynamicFormsModule,
        GridModule,
        ChaseGridModule,
        MenuModule,
    ],
    declarations: [
        ChaseSearchComponent,
    ],
    exports: [
        ChaseSearchComponent,
    ],
})

export class ChaseSearchModule { }
