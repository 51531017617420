import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { UploadStatus } from "../upload-status.enum";

@Component({
  selector: "app-progress-bar-upload",
  templateUrl: "./progress-bar-upload.component.html",
  styleUrls: ["./progress-bar-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarUploadComponent {

  @Input() isVisible = false;
  @Input() uploadProgress = 0;
  @Input() showProgressPercentage = false;
  @Input() hasError = false;
  @Input() hasRunTimeError = false;
  constructor(private readonly changeDetector: ChangeDetectorRef) { }

  checkProgress(fileName: string, gridSelection: any) {
    gridSelection.map(x => {
        if (x.fileName === fileName && x.uploadStatus === UploadStatus.UPLOADING && x.uploadProgress < 90 && !x.hasError) {
            x.uploadProgress = x.uploadProgress + Math.floor(Math.random() * 10) + 1;
        }
        return x;
    });
    this.changeDetector.markForCheck();
  }

  setUploadStatus(uploadingFileName: string, uploadProgress: number, status: UploadStatus, gridSelection: any): void {
    gridSelection.map(x => {
        x.uploadStatus = x.fileName === uploadingFileName ? status : x.uploadStatus;
        x.uploadProgress = x.fileName === uploadingFileName ? uploadProgress : x.uploadProgress;
        return x;
    });

  }

  get showProgressBar(): boolean {
    return this.isVisible && !this.hasError && !this.hasRunTimeError;
  }

}
