import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthService } from "../../../auth/auth.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../core/storage/local.service";
import { FormService } from "../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ChaseAssignService } from "../../../platform/api/unassign-chase/chase-assign.service";
import { ChaseDetailService } from "../../../platform/modules/member/chase-detail/chase-detail.service";
import { AddressDetailState } from "../../../platform/modules/retrieval/address-detail/address-detail-state.model";
import { AddressesQueueService } from "../../../platform/modules/retrieval/addresses-queue/addresses-queue.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { HighlightAnnotationService } from "../../highlight-annotation/highlight-annotation.service";

@Component({
  selector: "app-chase-assignment-modal",
  templateUrl: "./chase-assignment-modal.component.html",
  styleUrls: ["./chase-assignment-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseAssignmentModalComponent implements OnInit {
  @Input() pageType = "";
  usersInput: Autocomplete;
  usersForm: FormGroup;
  memberChaseListTobeAssigned: number[];
  isMemberCentricAssignmentModalVisible = false;
  totalChaseListToBeAssigned: number[];
  isAnyMemberCentricChaseNotSelected = false;
  maximumChasesInput: Dropdown;
  chaseIdsList: any[];
  totalMaxChaseIds: number[];
  selectedMaximumChaseValue: string;
  localStorageCurrentUser = "localStorageCurrentUser";
  isChaseAssigned = false;

  @Input() addressDetailState: AddressDetailState;
  @Input() isEmrOrFt = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() isUnassignEnabled = true;
  @Output() onHide = new EventEmitter<void>(true);
  @Output() onUpdate = new EventEmitter<void>(true);
  @Output() chasesChange = new EventEmitter<number[]>();

  pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
    if (!this.visible) {
      this.closing();
    }
  }

  private pChases = [];
  @Input()
  get chases(): any[] {
    return this.pChases;
  }

  set chases(value: any[]) {
    this.pChases = value;
    this.chasesChange.emit(this.chases);
    if (ArrayHelper.isAvailable(this.pChases) && this.visible) {
      this.checkIfChaseAssigned();
      this.getUsers();
      this.getMaximumChasesOptions();
    }
  }

  get selectedUser(): SelectableInput {

    if (this.usersForm == null) {
      return null;
    }
    return this.usersForm.get("assignToUsers").value;
  }

  set selectedUser(value: SelectableInput) {

    if (this.usersForm == null) {
      return;
    }
    this.usersForm.get("assignToUsers").setValue(value);
  }

  get selectedUserId(): number {

    if (this.selectedUser == null) {
      return null;
    }
    return +this.selectedUser.value;
  }

  get hasSelectedUser(): boolean {
    return NumberHelper.isGreaterThan(this.selectedUserId, 0, true);
  }

  get totalMemberChasesCount(): number {
    if (!ArrayHelper.isAvailable(this.memberChaseListTobeAssigned)) {
      return null;
    }

    return this.memberChaseListTobeAssigned.filter(m => !this.chases.includes(m)).length;
  }

  get showMaximumChaseDropDown(): boolean {
    return this.isEmrOrFt;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly chaseAssignService: ChaseAssignService,
    private chaseDetailService: ChaseDetailService,
    private readonly messagingService: MessagingService,
    private readonly authService: AuthService,
    private addressesQueueService: AddressesQueueService,
    private highlightAnnotationService: HighlightAnnotationService,
    private localService: LocalService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  protected createForm(): void {
    this.usersInput = new Autocomplete({
      key: "assignToUsers",
      label: "User",
      filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    });
    this.maximumChasesInput = new Dropdown({
      key: "maximumChasesInput",
      label: "Maximum Chases",
      placeholder: "Select...",
      appendTo: "body",
    });
    this.usersForm = this.formService.createFormGroup([this.usersInput, this.maximumChasesInput]);
  }

  getMaximumChasesOptions(): void {
    const getMaximumChasesOptionsList = [
      new SelectableInput({ text: "50", value: 1 }),
      new SelectableInput({ text: "100", value: 2 }),
      new SelectableInput({ text: "200", value: 3 }),
      new SelectableInput({ text: "300", value: 4 }),
      new SelectableInput({ text: "500", value: 5 }),
    ];
    this.maximumChasesInput = new Dropdown({ ...this.maximumChasesInput, options: getMaximumChasesOptionsList } as any);
    this.changeDetector.markForCheck();
  }

  onMaximumChaseChange(event): void {
    this.selectedMaximumChaseValue = event.value;
  }

  private getRetrievalUsers() {
    this.addressesQueueService.getRetrievalUsers(this.addressDetailState.masterDocumentSourceId, this.addressDetailState.isSpecialHandling)
      .subscribe(users => {
        ArrayHelper.addItemToSelect(users, "Unassigned", 0);
        if (this.isCurrentUserAvailable(users)) {
          ArrayHelper.addItemToSelect(users, "@Me", this.authService.userId);
        }
        this.usersInput = new Autocomplete({ ...this.usersInput, options: users } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getCoderUsers(): void {
    this.chaseAssignService.getCodersList(this.chases)
      .subscribe(users => {
        if (this.isUnassignEnabled) {
          ArrayHelper.addItemToSelect(users, "Unassigned", 0);
        }
        if (this.isCurrentUserAvailable(users)) {
          ArrayHelper.addItemToSelect(users, "@Me", this.authService.userId);
        }
        this.usersInput = new Autocomplete({ ...this.usersInput, options: users } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getUsers(): void {
    if (this.isEmrOrFt) {
      this.getRetrievalUsers();
    } else {
      this.getCoderUsers();
    }
  }

  private isCurrentUserAvailable(users: SelectableInput[]): boolean {
    return ArrayHelper.isAvailable(users.filter(user => user.value === this.authService.userId));
  }

  checkIfMemberCentricChaseAssignmentNeeded(): void {
    if (this.isAnyMemberCentricChaseNotSelected) {
      this.isMemberCentricAssignmentModalVisible = true;
      this.changeDetector.markForCheck();
    } else {
      this.assignSelectedChasesOnly();
    }
  }

  assignMemberCentricChases(): void {
    this.totalChaseListToBeAssigned = this.memberChaseListTobeAssigned;
    this.onChaseAssign();
  }

  assignSelectedChasesOnly(): void {
    this.totalChaseListToBeAssigned = this.isEmrOrFt ? this.pChases : this.chases;
    if (this.isEmrOrFt && StringHelper.isAvailable(this.selectedMaximumChaseValue.toString())) {
      const maxChaseSelected = this.selectedMaximumChaseValue.toString();
      const chaseIds = this.pChases.toString().split(",").map(Number);
      switch (maxChaseSelected) {
        case "1":
          this.chaseIdsList = chaseIds.slice(0, 50);
          break;
        case "2":
          this.chaseIdsList = chaseIds.slice(0, 100);
          break;
        case "3":
          this.chaseIdsList = chaseIds.slice(0, 200);
          break;
        case "4":
          this.chaseIdsList = chaseIds.slice(0, 300);
          break;
        case "5":
          this.chaseIdsList = chaseIds.slice(0, 500);
          break;
        default:
          break;
      }
      this.totalChaseListToBeAssigned = this.chaseIdsList;
    }
    this.onChaseAssign();
  }

  onChaseAssign(): void {
    const selectedUser = this.selectedUserId;

    if (!ArrayHelper.isAvailable(this.totalChaseListToBeAssigned)) {
      this.messagingService.showToast("No chases selected.", SeverityType.ERROR);
      return;
    }

    this.chaseAssignService.assignChasesToUser(this.totalChaseListToBeAssigned, this.selectedUserId, this.pageType)
      .subscribe(() => {
        if (NumberHelper.isGreaterThan(selectedUser, 0, false)) {
          this.messagingService.showToast("Assigned Successfully.", SeverityType.SUCCESS);
        } else {
          this.messagingService.showToast("Unassigned Successfully.", SeverityType.SUCCESS);
        }
        const shouldReload = !isNaN(this.chases[0]) ? true : false;
        this.visible = false;
        this.chases = [];
        this.isMemberCentricAssignmentModalVisible = false;
        this.changeDetector.markForCheck();
        this.onUpdate.emit();
        if (shouldReload) {
          setTimeout(() => {
            this.localService.put(this.localStorageCurrentUser, selectedUser);
            this.highlightAnnotationService.reloadPage(1);
          },         500);
        }
      });
  }

  clearData(): void {
    this.memberChaseListTobeAssigned = [];
    this.isMemberCentricAssignmentModalVisible = false;
    this.totalChaseListToBeAssigned = [];
    this.isAnyMemberCentricChaseNotSelected = false;
    this.chases = [];
  }

  private closing(): void {
    this.onHide.emit();
    this.clearData();
    this.selectedUser = null;
    this.selectedMaximumChaseValue = "";
    this.createForm();
    this.getMaximumChasesOptions();
  }

  hideMemberCentricAssignmentModal(): void {
    this.isMemberCentricAssignmentModalVisible = false;
  }

  private checkIfChaseAssigned(): void {
    if (ArrayHelper.isAvailable(this.chases) && this.chases[0] != undefined) {
      this.chaseAssignService.checkIfChaseAssigned(this.chases).subscribe(result => {
        this.isChaseAssigned = result;
      });
      this.changeDetector.markForCheck();
    }
  }

  checkIfMemberCentricChase(): void {
    this.memberChaseListTobeAssigned = [];
    this.isAnyMemberCentricChaseNotSelected = false;

    if (ArrayHelper.isAvailable(this.chases)) {
        this.chaseDetailService.getMemberCentricChasesForAssignment(this.chases, this.selectedUserId)
        .subscribe(memberChases => {
          // sp is expected to return the set of original chases even if no MC chases are returned for original set of chases
          if (ArrayHelper.isAvailable(memberChases)) {

            this.memberChaseListTobeAssigned = memberChases.map(m => m.chaseId);

            // remove duplicate member chase Ids, if any
            this.memberChaseListTobeAssigned = this.memberChaseListTobeAssigned.filter(
              (value, index) => this.memberChaseListTobeAssigned.indexOf(value) === index);

            this.isAnyMemberCentricChaseNotSelected = this.memberChaseListTobeAssigned.filter(m => !this.chases.includes(m)).length > 0;

          }

          this.checkIfMemberCentricChaseAssignmentNeeded();
        });
    }
  }
}
