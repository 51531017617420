import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class NewPagesService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient) { }

  markPagesAsRead(chaseRequestIds: number[]): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}newpages/markpagesasread`;
    return this.http.post(url, chaseRequestIds) as Observable<HttpResponse<null>>;
  }

  addMemberPagesForReview(chaseIds: number[]): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}newpages/addmemberpagesforreview`;
    return this.http.post(url, chaseIds) as Observable<HttpResponse<null>>;
  }
}
