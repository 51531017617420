import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../../../auth/auth.service";
import { UserModel } from "../../../../core/user/user-model";
import { UserService } from "../../../../core/user/user.service";

@Injectable()
export class RetrievalResolve implements Resolve<UserModel> {
  userId: number;
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}
  resolve(): Observable<UserModel> {
    this.userId = this.authService.userId;
    return this.userService.getUser(this.userId);
  }
}
