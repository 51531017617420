<div class="steps-menu clearfix">
  <div 
    *ngFor="let item of model; let i = index; trackBy: trackByIndex" 
    class="steps-menu__item" 
    [ngClass]="menuItemClass(item, i)">
    <div class="steps-menu__link" (click)="itemClick($event, item, i)">
      <span class="steps-menu__number">{{i + 1}}</span>
      <span class="steps-menu__title">{{item.label}}</span>
    </div>
  </div>
</div>