import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-clinical-summary",
    templateUrl: "./clinical-summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalSummaryComponent {

    filters: string[];
    dashboardType = LookerRiskDashboardType.CLINICAL_SUMMARY;

    constructor() {
        this.filters = ["Projects", "Hcc", "Products", "Address", "Npi", "LOB", "RetrievalOwner", "ChaseTags"];
    }
}
