import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { URIFormatHelper } from "../../../utilities/uri-format-helper";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  getTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}document/types`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getDocumentsForChase(chaseId: number): Observable<string[]> {
    const url = `${this.baseApiUrl}chasedocument/requireddocuments?chaseId=${chaseId}`;

    return this.http.get(url) as Observable<string[]>;
  }

  saveNote(documentQueueId: number, note: string): Observable<null> {
    const url = `${this.baseApiUrl}document/note/update?documentQueueId=${documentQueueId}&note=${URIFormatHelper.encodeParameters(note)}`;
    return this.http.post(url, {}) as Observable<null>;
  }
}
