import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BasicGridComponent } from "../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { BulkUpdatesService } from "../bulk-updates.service";
import { DocumentBulkUpdate } from "../model/document-bulk-update.model";
import { BulkUpdatesComponent } from "./../bulk-updates.component";
import { BulkUpdateDocumentService } from "./bulk-update-document.service";
import { DocumentBulkUpdateActionType } from "./document-bulk-update-action-type.enum";
@Component({
  selector: "app-bulk-update-document",
  templateUrl: "./bulk-update-document.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateDocumentComponent implements OnInit {
  documentNotes: Textarea;
  documentFormGroup: FormGroup;
  documentServerGridConfiguration = new GridConfiguration();
  documentServerRequest = new GridRequest();
  gridRefresh = new EventEmitter<null>();
  documentBulkUpdate: DocumentBulkUpdate;
  data: any;
  @Output() isProcessingDocumentIds = new EventEmitter<boolean>();
  @Input() actionControls: string[] = [];
  @Output() isValidationContinue = new EventEmitter<boolean>();
  isShowValidateDocumentGridData: boolean;
  @ViewChild(BasicGridComponent) private basicGridComponent: BasicGridComponent;
  @Input() isDocumentGrid: boolean;

  constructor(
    private readonly formService: FormService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private bulkUpdatesService: BulkUpdatesService,
    private bulkUpdateDocumentService: BulkUpdateDocumentService,
    private bulkUpdatesComponent: BulkUpdatesComponent
  ) { }

  ngOnInit(): void {
    this.initializeDocumentFormGroupControls();
    this.createDocumentForm();
  }

  initializeDocumentFormGroupControls(): void {
    this.documentNotes = new Textarea({
      key: "documentNotes",
      label: "Note",
      placeholder: "Write a note...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });
  }

  createDocumentForm(): void {
    this.documentFormGroup = this.formService.createFormGroup([this.documentNotes]);
  }

  get documentNotesValue(): string {
    return this.documentFormGroup.get(this.documentNotes.key).value;
  }

  get isHoldActionTypeSelected(): boolean {
    return this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType === DocumentBulkUpdateActionType.HOLD;
  }

  get isAssignActionTypeSelected(): boolean {
    return this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType === DocumentBulkUpdateActionType.ASSIGN;
  }

  get isRemoveActionTypeSelected(): boolean {
    return this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType === DocumentBulkUpdateActionType.REMOVE;
  }

  get isFinalBulkUpdate(): boolean {
    if (this.basicGridComponent && ArrayHelper.isAvailable(this.basicGridComponent.data)) {
      return this.bulkUpdatesService.checkValidationStatus(this.basicGridComponent.data);
    }
  }

  validateToShowDocumentGrid() {
    this.bulkUpdatesComponent.validateContinue();
    const notes = this.documentFormGroup.value?.documentNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.documentFormGroup.get(this.documentNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.documentFormGroup.get(this.documentNotes.key).setErrors(null);
    }
    if (this.documentFormGroup.invalid) {
      this.formService.markAllAsTouched(this.documentFormGroup);
      return;
    }
    if (!this.bulkUpdatesComponent.selectedHoldStatusValue || !this.bulkUpdatesComponent.formGroup.controls.assignToUsersInput.value) {
      this.bulkUpdatesComponent.isShowControls = true;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isDocumentGrid = false;
    }
    if (!this.bulkUpdatesComponent.hasValidationError && !this.bulkUpdatesComponent.formGroup.invalid) {
      this.bulkUpdatesComponent.isShowEditBulk = false;
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.isDocumentGrid = true;
      this.isShowValidateDocumentGridData = true;
    }

    this.bulkUpdatesComponent.isShowContinueButton = false;
    this.getValidatedAndProcessDocumentIds();

    this.changeDetector.markForCheck();
  }

  getValidatedAndProcessDocumentIds(): void {
    const filters: any[] = [
      new GridFilter({
        input: new Textbox(),
        key: "DocumentIdCsv",
        value: this.bulkUpdatesComponent.documentBulkUpdateData.documentIds.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DocumentNotes",
        value: this.documentNotesValue,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AssignToUserId",
        value: this.isAssignActionTypeSelected ? String(this.bulkUpdatesComponent.documentBulkUpdateData.assignToUserId) : null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DocumentBulkActionType",
        value: String(this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DocumentStateId",
        value: this.isHoldActionTypeSelected || this.isRemoveActionTypeSelected ? String(this.bulkUpdatesComponent.documentBulkUpdateData.documentStateId) : null,
        show: false,
      }),
    ];

    this.documentServerGridConfiguration.columns = [
      new GridColumnDefinition({ field: "documentId", header: "Document ID" }),
      new GridColumnDefinition({ field: "oldStatus", header: this.isHoldActionTypeSelected ? "OLD HOLD STATUS" : "OLD STATUS", show: !this.isAssignActionTypeSelected }),
      new GridColumnDefinition({ field: "newStatus", header: this.isHoldActionTypeSelected ? "NEW HOLD STATUS" : "NEW STATUS", show: !this.isAssignActionTypeSelected }),
      new GridColumnDefinition({ field: "oldAssignment ", header: "OLD ASSIGNMENT", show: this.isAssignActionTypeSelected }),
      new GridColumnDefinition({ field: "newAssignment ", header: "NEW ASSIGNMENT", show: this.isAssignActionTypeSelected }),
      new GridColumnDefinition({ field: "validationStatus", header: "VALIDATION STATUS" }),
      new GridColumnDefinition({ field: "message", header: "COMMENTS" }),
    ];

    this.documentServerGridConfiguration.pageSize = 25;
    this.documentServerGridConfiguration.showActionColumn = false;
    this.documentServerRequest.filters = filters;
    const url = "bulkupdate/document/validate/process";
    this.getBulkValidationData(url);
  }

  getBulkValidationData(url: string) {
    this.documentServerRequest.setForm();
    const request = this.documentServerRequest.createRequestObject();
    this.bulkUpdateDocumentService.getBulkValidationData(url, request).subscribe(res => {
      this.data = res;
      this.changeDetector.markForCheck();
    });
  }
  refreshGrid(): void {
    this.gridRefresh.emit();
  }

  finalBulkUpdate() {
    this.documentBulkUpdate = new DocumentBulkUpdate({
      documentIds: this.bulkUpdatesComponent.documentBulkUpdateData.documentIds,
      documentStateId: (this.isHoldActionTypeSelected || this.isRemoveActionTypeSelected) ? Number(this.bulkUpdatesComponent.documentBulkUpdateData.documentStateId) : null,
      action: 1,
      documentBulkActionType: this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType,
      assignToUserId: this.isAssignActionTypeSelected ? this.bulkUpdatesComponent.documentBulkUpdateData.assignToUserId : null,
      documentNotes: this.bulkUpdatesComponent.documentBulkUpdateData.documentBulkActionType === DocumentBulkUpdateActionType.HOLD ? `Document placed on Hold. Reason: ${this.bulkUpdatesComponent.selectedHoldStatusText}. Note: ${this.documentNotesValue} via Bulk Action` : `${this.documentNotesValue} via Bulk Action`,
    });
    this.bulkUpdateDocumentService.finishDocumentBulkUpdate(this.documentBulkUpdate).subscribe(
      {
        next: () => {
          this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();
        },
        error: () => {
          this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
          this.changeDetector.markForCheck();
        },
      }
    );
  }


}
