import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { CheckboxModule } from "primeng/checkbox";
import { DialogModule } from "primeng/dialog";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PanelModule as PrimePanelModule } from "primeng/panel";
import { ButtonModule } from "../button/button.module";
import { IconModule } from "../icon/icon.module";
import { AccordionPanelCustomComponent } from "./accordion-panel-custom/accordion-panel-custom.component";
import { AccordionTabCustomComponent } from "./accordion-panel-custom/accordion-tab-custom.component";
import { AccordionPanelComponent } from "./accordion-panel/accordion-panel.component";
import { AccordionTabComponent } from "./accordion-panel/accordion-tab.component";
import { CollapsibleModalComponent } from "./collapsible-modal/collapsible-modal.component";
import { CollapsiblePanelComponent } from "./collapsible-panel/collapsible-panel.component";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { ModalComponent } from "./modal/modal.component";
import { PopoverComponent } from "./popover/popover.component";
import { ResultModalComponent } from "./result-modal/result-modal.component";

@NgModule({
  imports: [
    FormsModule,
    AccordionModule,
    ButtonModule,
    CommonModule,
    DialogModule,
    IconModule,
    OverlayPanelModule,
    PrimePanelModule,
    CheckboxModule,
  ],
  declarations: [
    AccordionPanelComponent,
    AccordionTabComponent,
    AccordionPanelCustomComponent,
    AccordionTabCustomComponent,
    CollapsibleModalComponent,
    CollapsiblePanelComponent,
    ConfirmationModalComponent,
    ModalComponent,
    PopoverComponent,
    ResultModalComponent,
  ],
  exports: [
    AccordionPanelComponent,
    AccordionTabComponent,
    AccordionPanelCustomComponent,
    AccordionTabCustomComponent,
    CollapsibleModalComponent,
    CollapsiblePanelComponent,
    ConfirmationModalComponent,
    ModalComponent,
    PopoverComponent,
    ResultModalComponent,
  ],
})
export class PanelModule { }
