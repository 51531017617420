import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { CollapsiblePanelComponent } from "../../panel/collapsible-panel/collapsible-panel.component";

export interface IFilter {
  reset?: boolean;
  text?: string;
  label?: string;
  value?: number;
  controlType?: string;
}
@Component({
  selector: "app-basic-filter-applied",
  templateUrl: "./basic-filter-applied.component.html",
  styleUrls: ["../filter-applied/filter-applied.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicFilterAppliedComponent implements OnChanges {
  @ViewChild(CollapsiblePanelComponent, { static: true }) collapsiblePanel: CollapsiblePanelComponent;
  @Input() request: IFilter[];
  @Input() primeGrid: Table;
  @Output() removeFilter = new EventEmitter<IFilter>();

  ngOnChanges() {
    if (this.hasFilters) {
      const newFilter = [];
      this.request.forEach(filter => {
        if ((newFilter.findIndex(item => item.text === filter.text)) > -1) {
          newFilter[newFilter.findIndex(item => item.text === filter.text)].value.push(filter.value);
        } else {
          newFilter.push({ text: filter.text, value: [filter.value], controlType: filter.controlType });
        }
      });

      newFilter.forEach(filter => {
        if (filter.controlType !== "multiselect" && (filter.text.toLowerCase() !== "tagstext")) {
          if (filter.value.length === 1 && filter.controlType !== "checkbox-group") {
            this.primeGrid.filter(filter.value, filter.text, "contains");
          } else {
            this.primeGrid.filter(filter.value, filter.text, "in");
          }
        }
      });
    } else {
      this.primeGrid.reset();
    }
  }
  @HostBinding("style.display")
  get display(): string {
    return this.hasFilters ? "" : "none";
  }

  get hasFilters(): boolean {
    return ArrayHelper.isAvailable(this.request);
  }

  get filterCount(): number {
    return this.hasFilters ? this.request.length : 0;
  }

  get collapseIcon(): string {
    return this.collapsiblePanel.primePanel.collapsed ? "plus-square" : "minus-square";
  }

  delete(event: MouseEvent, index: number, filter: IFilter): void {
    event.stopPropagation();
    event.preventDefault();
    this.request.splice(index, 1);
    this.removeFilter.emit(filter);
    if (filter.text !== "tagsText") {
      const deletedFilter = this.primeGrid.filters[filter.text].value.filter(item => item !== filter.value);

      if (deletedFilter.length === 1) {
        this.primeGrid.filter(deletedFilter, filter.text, "contains");
      } else {
        this.primeGrid.filter(deletedFilter, filter.text, "in");
      }
    }
  }

  clearAllFilters(): void {
    this.request = [];
    this.removeFilter.emit({ reset: true });
    this.primeGrid.reset();
  }

  toggle(event: MouseEvent): void {
    this.collapsiblePanel.primePanel.toggle(event);
  }

  trackByIndex(index, item) {
    return index;
  }

}
