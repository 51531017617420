import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../../core/storage/session.service";
import { UserService } from "../../../../../../core/user/user.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { InvoiceType } from "../../../../../../shared/invoice/invoice-type.enum";
import { ModalComponent } from "../../../../../../shared/panel/modal/modal.component";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { ChaseSearchRequest } from "../../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../api/chase-search/chase-search.service";
import { ChaseUnasssignModel } from "../../../../../api/unassign-chase/chase-unassign-model";
import { UnassignChaseService } from "../../../../../api/unassign-chase/unassign-chase.service";
import { AccessInfo } from "../../../access-info.model";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { Contact } from "../../../contact.model";
import { DirectoryUserRole } from "../../../directory-user-role";
import { DocumentSourceType } from "../../../retrieval-document-source-type.enum";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailService } from "../../address-detail.service";
import { AddressDetailInfoAssignment } from "../address-detail-info-assignment/address-detail-info-assignment.model";
import { AddressDetailInfoAssignmentService } from "../address-detail-info-assignment/address-detail-info-assignment.service";

@Component({
  selector: "retrieval-address-detail-info-vendor-assignment",
  templateUrl: "./address-detail-info-vendor-assignment.component.html",
  styleUrls: ["./address-detail-info-vendor-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDetailInfoVendorAssignmentComponent extends ModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() is3rdParty = false;
  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  vendorForm: FormGroup;
  vendorInput: Autocomplete;
  chases: number[];
  private previousMasterDocumentSourceId = 0;
  private gridSearchRequest: any;
  private user: UserToken;
  private assignedTo: number;
  @Input() isVendorChanged = false;
  @Output() retrievalTypeChange = new EventEmitter<boolean>();
  assignVendorModelText: string;
  vendorInvoicingDetails: string[];
  chaseIdsList = [];
  @Input() set isAssignmentModalOpened(val: boolean) {
    if (val && !ArrayHelper.isAvailable(this.vendorInput.options)) {
      if (!this.sessionService.get("VENDOR_LIST", null)) {
        this.getVendors();
      } else {
        this.bindVendors();
      }
    }
  }

  constructor(
    private readonly addressDetailService: AddressDetailService,
    private addressDetailInfoAssignmentService: AddressDetailInfoAssignmentService,
    private formService: FormService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private readonly addressDetailStateService: AddressDetailStateService,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private userService: UserService,
    private chaseSearchService: ChaseSearchService,
    private unassignChaseService: UnassignChaseService,
    private readonly sessionService: SessionService
  ) { super(); }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.assignedTo = (this.user.functionalRoleIds.indexOf(DirectoryUserRole.DocumentIntakeManager) > -1) ? null : this.user.userId;

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;

        if (ArrayHelper.isAvailable(state.chaseGridData)) {
          this.chases = state.chaseGridData.map(a => a.chaseId || a.chaseID);
        }

        this.changeDetector.markForCheck();
      })
    );


    this.vendorInput = new Autocomplete({
      key: "vendorInput",
      placeholder: "Search for or select vendor",
    });

    this.vendorForm = this.formService.createFormGroup([this.vendorInput]);

  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  updateChaseGrid(): void {
    this.gridSearchRequest = new ChaseSearchRequest(
      null, null, null, null, this.addressDetailState.masterDocumentSourceId, this.assignedTo, null, null, null, null, null,
      null, null, null);

    this.chaseSearchService
      .chaseSearch(this.gridSearchRequest)
      .subscribe(chaseGridData => {
        this.addressDetailStateService.setData({ chaseGridData });
        this.chases = chaseGridData.map(a => a.chaseId || a.chaseID);
      });
  }

  assignAddressToVendor(): void {
    this.addressDetailInfoAssignmentService.assignAddressToVendor(this.addressDetailState.masterDocumentSourceId, this.vendorId)
      .subscribe(() => {
        this.saveAddressAssignment();
      });
  }

  saveAddressAssignment(): void {
    this.addressDetailInfoAssignmentService.saveAssignment(new AddressDetailInfoAssignment({
      addressId: this.addressDetailState.masterDocumentSourceId,
      documentSourceTypeId: DocumentSourceType.THIRDPARTY,
      assignmentNote: `Address assigned to 3rd Party, Vendor Name: ${this.vendorName}`,
    })).subscribe(
      data => {
        this.retrievalTypeChange.emit(true);
        this.messagingService.showToast(`Address Reassigned to 3rd Party.`, SeverityType.SUCCESS);
        if (!this.is3rdParty) {
          this.unassignChasesAtAddressLocation();
        }
        if (this.isVendorChanged && (this.addressDetailState.vendorId !== this.vendorId)) {
          this.removePreviouslyCreatedInvoices();
        }
        this.updateChaseGrid();
        this.addressDetailService.getAddressDetail(this.addressDetailState.masterDocumentSourceId).subscribe({
          next: addressDetail => {
            this.addressDetailStateService.resetData({
              ...addressDetail,
              accessInfo: new AccessInfo({ ...addressDetail }),
              contact: new Contact({ ...addressDetail }),
            });
            this.fetchTimelineItems(addressDetail.masterDocumentSourceId);
            this.vendorInvoicingDetails = this.addressDetailState.vendorInvoicing.split("/");
            const invoiceType = this.vendorInvoicingDetails[0];
            if (invoiceType === "NET30") {
              this.getAllChasesForDeniedVendor(this.addressDetailState.masterDocumentSourceId, this.addressDetailState.vendorId);
            }
          },
          error: error => this.messagingService.showToast(error, SeverityType.ERROR),
        });

        this.hide();
      },
      err => {
        this.messagingService.showToast(`Failed to reassign Address to 3rd Party.`, SeverityType.ERROR);
      }
    );
  }

  change(event: boolean): void {
    this.vendorForm.reset();
    super.change(event);
  }

  get isVendorSelected(): boolean {
    return NumberHelper.isGreaterThan(this.vendorId, 0);
  }

  get vendorId(): number {
    return this.vendorForm.get("vendorInput").value != null && this.vendorForm.get("vendorInput").value.value;
  }

  get vendorName(): number {
    return this.vendorForm.get("vendorInput").value != null && this.vendorForm.get("vendorInput").value.extra.vendorName;
  }

  private getVendors(): void {
    const invoiceType = `${InvoiceType.NET30},${InvoiceType.COD},${InvoiceType.UNKNOWN},${InvoiceType.MANAGED}`;
    this.addressDetailInfoAssignmentService
      .getVendors(invoiceType)
      .pipe(map(options => options.map(item => new SelectableInput({ text: item.vendorInvoiceTypeId !== InvoiceType.COD ? `${item.name} (${InvoiceType[item.vendorInvoiceTypeId]},${item.vendorChartPrice})` : `${item.name}(${InvoiceType[item.vendorInvoiceTypeId]})`, value: Number(item.vendorId), extra: { vendorName: item.name, vendorInvoiceTypeId: item.vendorInvoiceTypeId } }))))
      .subscribe(options => {
        this.vendorInput = new Autocomplete({ ...this.vendorInput, options } as any);
        this.sessionService.put("VENDOR_LIST", JSON.stringify(this.vendorInput.options));
        this.formService.updateDom.next();
      });

    this.changeDetector.markForCheck();
  }

  private bindVendors(): void {
    this.vendorInput.options = JSON.parse(this.sessionService.get("VENDOR_LIST", null));
  }
  private fetchTimelineItems(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  private unassignChasesAtAddressLocation(): void {
    const functionalRoleIdRetrieval = 5;
    const unasssignModel = new ChaseUnasssignModel({
      chaseList: this.chases,
      functionalRoleId: functionalRoleIdRetrieval,
      masterDocumentSourceId: this.addressDetailState.masterDocumentSourceId,
    });

    this.unassignChaseService.unassignChases(unasssignModel).subscribe(
      data => {
        this.messagingService.showToast("Chases(s) have been unassigned.", SeverityType.SUCCESS);
        this.addressDetailStateService.setData({ documentSourceTypeId: DocumentSourceType.THIRDPARTY, documentSourceTypeName: DocumentSourceType[DocumentSourceType.THIRDPARTY], assignedTo: "" });
      },
      err => {
        this.messagingService.showToast("Error while unassigning Chases(s).", SeverityType.ERROR);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const commonModelText = `Select a vendor from the dropdown menu and click "Assign".`;
      this.assignVendorModelText = this.isVendorChanged ? `${commonModelText}` : `You are about to permanently assign a 3rd party vendor to this AID. ${commonModelText}`;
    }
  }

  get modalTextClass(): string {
    return this.isVendorChanged ? "text-center align-text" : "";
  }

  confirmVendor(): void {
    this.addressDetailInfoAssignmentService.confirmVendor(this.addressDetailState.masterDocumentSourceId)
      .subscribe(
        () => {
          this.messagingService.showToast("Confirmed 3rd Party Vendor to this Address.", SeverityType.SUCCESS);
        },
        err => {
          this.messagingService.showToast("Failed to confirm 3rd party vendor.", SeverityType.ERROR);
        }
      );
  }

  removePreviouslyCreatedInvoices(): void {
    this.addressDetailInfoAssignmentService.deleteInvoices(this.addressDetailState.masterDocumentSourceId).subscribe(() => {
      this.messagingService.showToast("Removed Invoices at this Address.", SeverityType.SUCCESS);
    },
                                                                                                                     err => {
        this.messagingService.showToast("Failed to remove invoices.", SeverityType.ERROR);
      });
  }

  getAllChasesForDeniedVendor(addressId: number, vendorId: number) {
    this.addressDetailInfoAssignmentService
      .getAllChasesForDeniedVendor(addressId, vendorId)
      .subscribe(result => {
        this.chaseIdsList = result;
        if (ArrayHelper.isAvailable(this.chaseIdsList)) {
          const invoiceAmount = Number(this.vendorInvoicingDetails[1].split(": $")[1]);
          this.addressDetailInfoAssignmentService.createInvoiceShellForNet30DeniedVendor(this.chaseIdsList, invoiceAmount).subscribe(() => {
            this.messagingService.showToast("Invoices created for denied vendor.", SeverityType.SUCCESS);
          },
                                                                                                                                     err => {
              this.messagingService.showToast("Failed to create invoices for denied vendor.", SeverityType.ERROR);
            });
          this.changeDetector.markForCheck();
        }
      });
  }
}
