import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SubSink } from "subsink";
import { LocalService } from "../../../../core/storage/local.service";
import { JobsqueueService } from "../../../../platform/modules/jobsqueue/jobsqueue.service";
import { ChartService } from "../../../../platform/modules/member/chase-detail/chase-detail-chart/chart.service";
import { ChaseDetailStateService } from "../../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { ChaseDocumentService } from "../../../../platform/modules/member/chase-document.service";
import { DocumentPage } from "../../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../../../platform/modules/retrieval/retreival-document-review/document-pages.models";
import { RetrievalDocumentServiceService } from "../../../../platform/modules/retrieval/retrieval-document-service.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { debounceTimeAfterFirst } from "../../../../utilities/debounce-time-after";
import { MemberCentricChase } from "../../../membercentric-doc-attachment/membercentric-chase.model";
import { DocumentListItem } from "../../chase-document-list/document-list-item.model";
import { CacheTracker } from "../cache-tracker.model";
import { DocumentPageViewerComponent } from "../document-page-viewer.component";
import { DocumentThumbnailState } from "../document-thumbnail-state.model";
import { DocumentThumbnailType } from "../document-thumbnail-type.enum";
import { DocumentViewerSessionService } from "../document-viewer-session.service";
import { SourceDocumentType } from "../source-document-type.enum";

@Component({
  selector: "app-document-page-thumbnail-expand-labeled",
  templateUrl: "./document-page-thumbnail-expand-labeled.component.html",
  styleUrls: ["./document-page-thumbnail-expand-labeled.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageThumbnailExpandLabeledComponent implements OnInit, OnChanges, OnDestroy {
  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @ViewChild(CdkVirtualScrollViewport, { static: true }) private thumbnailExpandedViewport: CdkVirtualScrollViewport;
  @Input() chaseId: number;
  @Input() addressId: number;
  @Input() projectId: number;
  @Input() currentChartPageNumber = 1;
  @Input() isMaximized = false;

  private readonly NUMBER_OF_THUMBNAILS_PER_ROW = 4;
  private currentThumbnailNumber$ = new BehaviorSubject<number>(0);
  private cacheTracker: CacheTracker;
  private sink = new SubSink();
  private state: DocumentThumbnailState;
  readonly itemSize = 274;

  clearCache = false;
  selectedThumbnail: number;
  thumbnailFilterValue: number;
  memberChases: MemberCentricChase[];
  totalChartPages: number;
  isFromToggledView = false;
  isBucketFolderChecked = false;
  currentSpineColorIndex = 0;
  cdnBucketFolders = [];
  documentListItems: DocumentListItem[];
  maxDocuments = 0;
  isPageJump = true;
  private localStoragePageNumber = "pageNumber";
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly chartService: ChartService,
    private jobsqueueService: JobsqueueService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly zone: NgZone,
    private chaseDocumentService: ChaseDocumentService,
    private localService: LocalService
  ) { }

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }

  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get allThumbnails(): any[] {
    const statePages = [...this.pages];
    return ArrayHelper.chunkArray(statePages, this.NUMBER_OF_THUMBNAILS_PER_ROW);
  }

  get currentThumbnailNumber(): number {
    return this.state.currentThumbnailNumber;
  }
  set currentThumbnailNumber(value: number) {
    this.state.currentThumbnailNumber = value;
    this.changeDetector.markForCheck();
  }

  get currentDocumentQueueId(): number {
    return this.state.currentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    this.state.currentDocumentQueueId = value;
    this.changeDetector.markForCheck();
  }

  get hasCurrentPage(): boolean {
    return this.state.hasCurrentPage;
  }

  get totalPages(): number {
    return this.state.totalPages;
  }

  ngOnInit() {
    this.state = new DocumentThumbnailState();
    this.cacheTracker = new CacheTracker();

    this.sink.add(
      this.currentThumbnailNumber$
        .pipe(debounceTimeAfterFirst(50))
        .subscribe(pageNumber => {
          this.currentThumbnailNumber = pageNumber;
          this.tryLoading();
        }),
      this.chaseDetailStateService.memberChases.subscribe(data => {
        this.memberChases = data;
      }),
      this.jobsqueueService.newJobEvent.subscribe(data => {
        if (data.find(d => d.stateMachineName === "MRCS Chart Upload")) {
          this.resetThumbnailView();
          this.initialThumbnailLoad();
          this.changeDetector.markForCheck();
        }
      })
    );

    this.initialThumbnailLoad();
    this.getDocumentListItems();
    this.changeDetector.detectChanges();
  }

  @HostListener("window:beforeunload")
  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
    this.resetThumbnailView();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentChartPageNumber) {
      if (changes.currentChartPageNumber.currentValue !== changes.currentChartPageNumber.previousValue) {
        this.currentThumbnailNumber$.next(changes.currentChartPageNumber.currentValue);
        this.selectedThumbnail = changes.currentChartPageNumber.currentValue;
        this.changeDetector.markForCheck();
      }
    }
  }

  getDocumentListItems(): void {
    this.chaseDocumentService
      .getDocumentListItems(this.chaseId, this.maxDocuments)
      .subscribe(result => {
        this.documentListItems = result;
        this.changeDetector.markForCheck();
      });
  }

  updateCurrentPage(pageNumber: number): void {
    this.currentThumbnailNumber = pageNumber;
    this.selectedThumbnail = pageNumber;
    this.documentViewerSessionService.updateDataEntryPageNumber(pageNumber);
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page != null && StringHelper.isAvailable(page.source);
  }

  hasThumbnailImageUrl(thumbnail: DocumentPage): boolean {
    return StringHelper.isAvailable(thumbnail.image);
  }

  hasThumbnails(): boolean {
    return ArrayHelper.isAvailable(this.pages);
  }

  isThumbnailSelected(thumbnail: DocumentPage): boolean {
    const pageSelected = this.localService.get(this.localStoragePageNumber, 0);
    if (this.selectedThumbnail === pageSelected) {
      this.isPageJump = true;
    }
    if (this.isPageJump) { this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.selectedThumbnail / this.NUMBER_OF_THUMBNAILS_PER_ROW)); }
    return this.selectedThumbnail === thumbnail.pageNumber;
  }

  updateIndex(index): void {
    this.isPageJump = false;
    const pageNumber = (index + 1) * this.NUMBER_OF_THUMBNAILS_PER_ROW;
    this.currentThumbnailNumber = pageNumber;
    this.tryLoading();
  }

  getThumbnailSource(thumbnail: DocumentPage): string {
    return this.hasThumbnailImageUrl ? (thumbnail.isCdnChartImageExist ? thumbnail.source.replace("data:image/jpg;base64,", "")
      : thumbnail.source) : "";
  }

  isDocumentThumbnail(thumbnail: DocumentPage): boolean {
    return thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.THUMBNAIL_IMAGE;
  }

  thumbnailClicked(thumbnail: DocumentPage): void {
    this.updateCurrentPage(thumbnail.pageNumber);
  }

  trackByIndex(index, item) {
    return index;
  }

  private initialThumbnailLoad(): void {
    const pagesToLoad = [1, 15];
    pagesToLoad.forEach(pageNumber => this.state.setThumbnailPage({ pageNumber } as any));
    this.load(pagesToLoad)
      .subscribe(thumbnailPages => {
        const length = (thumbnailPages && ArrayHelper.isAvailable(thumbnailPages.pages)) ? thumbnailPages.pages[0].numberOfPages : 0;
        this.isBucketFolderChecked = (thumbnailPages && thumbnailPages.isCdnBucketFolderChecked) ? thumbnailPages.isCdnBucketFolderChecked : false;
        this.getCdnImages(thumbnailPages);
        this.cacheTracker = new CacheTracker();
        this.state = new DocumentThumbnailState({
          documentId: this.cleanDocumentId,
          pages: Array.from<DocumentPage>({ length }),
        });
        // Set Pages
        this.currentDocumentQueueId = ArrayHelper.isAvailable(thumbnailPages.pages) ? thumbnailPages.pages[0].documentQueueId : 0;
        this.setPages(thumbnailPages.pages);
        if (NumberHelper.isGreaterThan(this.selectedThumbnail, 1)) {
          this.isFromToggledView = true;
          this.gotoAndLoadSelectedThumbnail();
        } else {
          this.currentThumbnailNumber = 1;
          this.thumbnailExpandedViewport.scrollToIndex(0);
        }
        this.changeDetector.markForCheck();
        // Load next pages
        this.subsequentLoad([16, 21]);
      });

    this.chartService.getTotalChaseDocumentPages(this.chaseId).subscribe(
      data => this.totalChartPages = data
    );
  }

  getCdnImages(documentPages: DocumentPages) {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  private load(pages: number[]): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    const documentPageIds = null;
    const isRisk20Project = null;
    const groupName = null;
    const selectedDiagnosisType = null;
    const isNoEvidenceFilterSelected = false;
    const selectedTabText = null;
    const isSplitScreenMainTab = false;
    const diagnosisFilterSelected = false;
    const encounterStartDate = null;
    const encounterEndDate = null;
    const isProjectEnabledForRiskNlp = false;
    const filterOnlyByDocumentPageIds = false;
    return this.retrievalDocumentService.getDocumentThumbnailsWithDocQueueId(
      this.cleanDocumentId,
      SourceDocumentType.DocumentThumbnail,
      begPage,
      endPage,
      this.thumbnailFilterValue, null, this.isBucketFolderChecked, documentPageIds, isRisk20Project, groupName,
      selectedDiagnosisType, isNoEvidenceFilterSelected, selectedTabText, isSplitScreenMainTab, diagnosisFilterSelected,
      encounterStartDate, encounterEndDate, filterOnlyByDocumentPageIds, isProjectEnabledForRiskNlp, this.cdnBucketFolders);
  }

  private setPages(pages: DocumentPage[]): void {
    pages.forEach(this.setPage.bind(this));
    this.pages$.next(this.pages);
    this.changeDetector.markForCheck();
  }

  private setPage(page: DocumentPage): void {
    const time = performance.now();
    page.time = time;
    this.cacheTracker.add(time);

    if (page.documentQueueId && (this.currentDocumentQueueId !== page.documentQueueId)) {
      this.currentSpineColorIndex++;
      this.currentDocumentQueueId = page.documentQueueId;
    }

    if (page.documentThumbnail) {
      const colorIndex = this.currentSpineColorIndex % page.documentThumbnail.thumbnailColors.length;
      page.documentThumbnail.thumbnailSpineColor = page.documentThumbnail.thumbnailColors[colorIndex].spine;
      page.documentThumbnail.thumbnailBackgroundColor = page.documentThumbnail.thumbnailColors[colorIndex].background;
      page.documentThumbnail.thumbnailType = DocumentThumbnailType.THUMBNAIL_IMAGE;
    }

    this.state.setThumbnailPage(page);
  }

  private subsequentLoad(pagesToLoad: number[]): void {
    if (this.currentThumbnailNumber > 0 && this.totalPages > 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMoreThumbnails && !this.clearCache) {
            pagesToLoad.forEach(pageNumber => this.setPage({ pageNumber } as any));
            this.load(pagesToLoad)
              .subscribe(thumbnailPages => {
                this.getCdnImages(thumbnailPages);
                this.setPages(thumbnailPages.pages);
                this.cleanCache();
                pagesToLoad[0] += 6;
                pagesToLoad[1] += 6;
                const loadPages = pagesToLoad[0] === this.totalPages ? [this.totalPages] : pagesToLoad;
                if (loadPages[0] <= this.totalPages) { this.subsequentLoad(loadPages); }
              });
          }
        })
      );
    } else if (this.currentThumbnailNumber > 0 && this.totalPages === 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMoreThumbnails && !this.clearCache) {
            this.setPage(2 as any);
            this.load([2])
              .subscribe(thumbnailPages => {
                this.getCdnImages(thumbnailPages);
                this.setPages(thumbnailPages.pages);
                this.cleanCache();
              });
          }
        })
      );
    }
  }

  private cleanCache(): void {
    if (this.cacheTracker.isLimitReached) {
      this.zone.runOutsideAngular(() => {
        const minTime = this.cacheTracker.currentTime;
        setTimeout(() => {
          for (let i = 0; i < this.pages.length; ++i) {
            if (this.pages[i] != null && this.pages[i].time < minTime) {
              this.pages[i] = null;
            }
          }
        });
      });
    }
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });
  }

  private tryLoading(): void {
    if (this.currentThumbnailNumber > 0 && this.totalPages > 0) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMoreThumbnails) {
            const pagesToLoad = this.state.getThumbnailsToLoad();
            if (ArrayHelper.isAvailable(pagesToLoad)) {
              pagesToLoad.forEach(pageNumber => this.setPage({ pageNumber } as any));
              this.load(pagesToLoad).subscribe(thumbnailPages => {
                this.getCdnImages(thumbnailPages);
                this.setPages(thumbnailPages.pages);

                if (this.isFromToggledView) {
                  this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.selectedThumbnail / 5));
                  this.isFromToggledView = false;
                } else {
                  this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.currentThumbnailNumber / 5));
                }

                this.cleanCache();
              });
            }
          }
          this.changeDetector.markForCheck();
        })
      );
    }
  }

  private resetThumbnailView(): void {
    this.currentSpineColorIndex = 0;
    this.changeDetector.markForCheck();
  }

  private gotoAndLoadSelectedThumbnail(): void {
    this.currentThumbnailNumber = this.selectedThumbnail;
    this.tryLoading();
    this.thumbnailExpandedViewport.scrollToIndex(Math.floor(this.selectedThumbnail / 5));
  }
}
