import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ParameterService } from "../../../../../core/navigation/parameter.service";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";

@Injectable({
  providedIn: "root",
})
export class InternalPendDetailTimelineService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private parameterService: ParameterService
  ) { }

  getPendGdFromPath(): string {
    return this.parameterService.getNormal("pendGd", "");
  }

  getPendTimelineActivityItems(): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/pendTimelineActivity?pendId=${this.getPendGdFromPath()}`;
    return this.http.get(url).pipe(
        map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }
}
