import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { AccessInfo } from "../../../access-info.model";
import { AddressTimelineStateService } from "../../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../address-timeline/address-timeline.service";
import { Contact } from "../../../contact.model";
import { ContactMethodType } from "../../../cover-letter-template/contact-method-type.enum";
import { RetrievalCallFlowService } from "../../../retrieval-call-flow/retrieval-call-flow.service";
import { AddressDetailState } from "../../address-detail-state.model";
import { AddressDetailStateService } from "../../address-detail-state.service";
import { AddressDetailInfoContactService } from "../address-detail-info-contact/address-detail-info-contact.service";
import { AddressDetailInfoEdit } from "./address-detail-info-edit.model";
import { AddressDetailInfoEditService } from "./address-detail-info-edit.service";
@Component({
  selector: "retrieval-address-detail-info-edit",
  templateUrl: "./address-detail-info-edit.component.html",
  styleUrls: ["./address-detail-info-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoEditComponent implements OnInit, OnDestroy {
  @Input() isCreateVisible = false;
  @Output() formClose = new EventEmitter();

  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  addressDetailInfoEdit: AddressDetailInfoEdit;
  formGroup: FormGroup;
  addressFormGroup: FormGroup;
  email: Textbox;
  phone: Textbox;
  fax: Textbox;
  contactName: Textbox;
  preferredRequestMethod: Dropdown;
  address1: Textbox;
  address2: Textbox;
  addressCity: Textbox;
  addressState: Textbox;
  addressZip: Textbox;
  groupName: Textbox;
  accessEmrSystem: Textbox;
  accessEmrSystemDropdown: Dropdown;
  emrSystemOptions = [
      new SelectableInput({ text: "Allscripts", value: "Allscripts"}),
      new SelectableInput({ text: "Athena", value: "Athena" }),
      new SelectableInput({ text: "Cerner", value: "Cerner" }),
      new SelectableInput({ text: "eClinical Works (eCW)", value: "eClinical Works (eCW)" }),
      new SelectableInput({ text: "Elation", value: "Elation" }),
      new SelectableInput({ text: "Epic", value: "Epic" }),
      new SelectableInput({ text: "Greenway/Intergy", value: "Greenway/Intergy" }),
      new SelectableInput({ text: "Medent", value: "Medent" }),
      new SelectableInput({ text: "Meditech", value: "Meditech" }),
      new SelectableInput({ text: "NextGen", value: "NextGen" }),
      new SelectableInput({ text: "Office Ally", value: "Office Ally" }),
      new SelectableInput({ text: "Practice Fusion", value: "Practice Fusion" }),
      new SelectableInput({ text: "Other", value: "Other" }),
    ];

  private organizationId: number;
  private contactToEdit: Contact;
  mailingAddress: string;
  isConfirmationModalVisible: boolean;

  get showEmrSystem(): boolean {
    return this.isPSR && this.formGroup.get("accessEmrSystemDropdown").value === "Other";
  }

  get isPSR(): boolean {
    return this.addressDetailState.isPSR;
  }

  get getEmrDropdownValue(): string {
    return this.showEmrSystem ? this.formGroup.get("accessEmrSystem").value : this.formGroup.get("accessEmrSystemDropdown").value;
  }

  get getEmrSystemValue(): string {
    return ObjectHelper.isEmpty(this.emrSystemOptions.find(x => x.text.trim().toLowerCase() === this.addressDetailState.accessInfo.accessEmrSystem.trim().toLowerCase()))
    ? "Other" : this.addressDetailState.accessInfo.accessEmrSystem;
  }

  get isPrimaryContactSet(): boolean {
    return this.addressDetailState?.hasPrimaryContact;
  }

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly addressDetailContactService: AddressDetailInfoContactService,
    private readonly formService: FormService,
    private addressDetailInfoEditService: AddressDetailInfoEditService,
    private retrievalCallFlowService: RetrievalCallFlowService,
    private messagingService: MessagingService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;

        if (this.addressDetailState.hasMasterDocumentSourceId) {
          this.loadForm();
          if (!ObjectHelper.isEmpty(state.contact)) {
            this.contactToEdit = state.contact;
            this.loadContactForm(state.contact);
          }
        }
        this.mailingAddress = this.getMailingAddress(this.addressDetailState);
        this.changeDetector.markForCheck();
      }),
      this.addressDetailContactService.addressContactChanged$
        .pipe(filter(contact => !ObjectHelper.isEmpty(contact)))
        .subscribe(contact => {
          this.contactToEdit = contact;
          this.setControlState(!contact?.isPrimaryContact);
          this.loadContactForm(contact);
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onUpdate(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.getFormValues();

    const editedMailAddress = this.getMailingAddress(this.addressDetailInfoEdit);
    if (this.mailingAddress !== editedMailAddress || this.addressDetailState.city !== this.addressDetailInfoEdit.addressCity) {
      this.isConfirmationModalVisible = true;
    } else {
      this.saveAddressDetail();
    }

    this.visibleChange(false);
  }

  private saveAddressDetail(): void {
    this.addressDetailInfoEdit.retrievalTypeID = this.addressDetailState.documentSourceTypeId;
    this.addressDetailInfoEditService.updateAddressDetails(this.addressDetailInfoEdit)
      .subscribe((addressId: number) => {
        if (addressId === this.addressDetailState.masterDocumentSourceId) {
          this.fetchTimelineItmes();
          this.retrievalCallFlowService.resetFunction("");
          this.addressDetailContactService.refreshContactList(addressId);
          this.addressDetailStateService.setData({
            address1: this.addressDetailInfoEdit.address1,
            address2: this.addressDetailInfoEdit.address2,
            city: this.addressDetailInfoEdit.addressCity,
            state: this.addressDetailInfoEdit.addressState,
            postalCode: this.addressDetailInfoEdit.addressZip,
            groupName: this.addressDetailInfoEdit.groupName,
            contact: new Contact({
              contactEmail: this.addressDetailInfoEdit.email,
              contactFax: this.addressDetailInfoEdit.faxNumber,
              contactName: this.addressDetailInfoEdit.contactName,
              contactPhone: this.addressDetailInfoEdit.phone,
              weeklyContactMethodTypeName: ContactMethodType[this.addressDetailInfoEdit.weeklyContactMethodTypeId],
              isPrimaryContact: this.contactToEdit?.isPrimaryContact,
            }),
            weeklyContactMethodTypeId: this.addressDetailInfoEdit.weeklyContactMethodTypeId,
            accessInfo: new AccessInfo({
              ...this.addressDetailState.accessInfo,
              accessEmrSystem: this.addressDetailInfoEdit.emrSystem,
            }),
            isContactVerified: this.addressDetailInfoEdit.isContactVerified,
            masterDocumentSourceVerifiedDate: this.addressDetailInfoEdit.isContactVerified && this.addressDetailState.allProvidersVerified ? new Date() : null,
          });
        } else {
          this.router.navigate(["retrieval", "addressdetail", addressId]);
        }
        this.messagingService.showToast("Address Details successfully edited.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      });
  }

  private isEmailOrFaxPresent(address: AddressDetailInfoEdit): boolean {
    if (ContactMethodType.Email === address.weeklyContactMethodTypeId) {
      return !!address.email;
    }
    if (ContactMethodType.Fax === address.weeklyContactMethodTypeId) {
      return !!address.faxNumber;
    }
  }

  visibleChange($event) {
    this.isCreateVisible = $event;
    this.formClose.emit($event);
  }

  private initializeForm(): void {
    this.groupName = new Textbox({
      key: "groupName",
      label: "Group Name",
      placeholder: "Group Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Group Name can not be more than 100 characters.",
      },
    });

    this.email = new Textbox({
      key: "email",
      label: "Email",
      placeholder: "Email",
      validators: [Validators.pattern(RegExHelper.emailPattern), Validators.maxLength(100)],
      errorMessages: {
        pattern: "Please enter a valid email address.",
        maxlength: "Email address can not be more than 100 characters.",
        contactFormInvalid: "No Primary Contact Set",
      },
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
        contactFormInvalid: "No Primary Contact Set",
      },
    });

    this.fax = new Textbox({
      key: "fax",
      label: "fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
        contactFormInvalid: "No Primary Contact Set",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
        contactFormInvalid: "No Primary Contact Set",
      },
    });

    this.preferredRequestMethod = new Dropdown({
      key: "preferredRequestMethod",
      label: "Preferred Request Method",
      placeholder: "Select...",
      options: [
        new SelectableInput({ text: ContactMethodType[ContactMethodType.Fax], value: ContactMethodType.Fax.toString() }),
        new SelectableInput({ text: ContactMethodType[ContactMethodType.Email], value: ContactMethodType.Email.toString() }),
        new SelectableInput({ text: ContactMethodType[ContactMethodType.Mail], value: ContactMethodType.Mail.toString() }),
      ],
    });

    this.address1 = new Textbox({
      key: "address1",
      label: "Address 1",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "Address is required.",
        maxlength: "Address must be no longer than 50 characters.",
      },
    });

    this.address2 = new Textbox({
      key: "address2",
      label: "Address 2",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Address 2 must be no longer than 50 characters.",
      },
    });

    this.addressCity = new Textbox({
      key: "addressCity",
      label: "City",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "Address is required.",
        maxlength: "City must be no longer than 50 characters.",
      },
    });

    this.addressState = new Textbox({
      key: "addressState",
      label: "State",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(2)],
      errorMessages: {
        required: "State is required.",
        maxlength: "State must be 2 characters.",
      },
    });

    this.addressZip = new Textbox({
      key: "addressZip",
      label: "Zip",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(5)],
      errorMessages: {
        required: "Zip code is required.",
        maxlength: "Zip must be 5 characters.",
        minlength: "Zip must be5 characters.",
      },
    });

    this.accessEmrSystem = new Textbox({
      key: "accessEmrSystem",
      label: "EMR System",
      placeholder: "EMR System",
    });

    this.accessEmrSystemDropdown = new Dropdown({
      key: "accessEmrSystemDropdown",
      label: "EMR System",
      placeholder: "emr system",
      options: this.emrSystemOptions,
    });

    this.formGroup = this.formService.createFormGroup([
      this.email,
      this.phone,
      this.fax,
      this.contactName,
      this.preferredRequestMethod,
      this.address1,
      this.address2,
      this.addressCity,
      this.addressState,
      this.addressZip,
      this.accessEmrSystem,
      this.groupName,
      this.accessEmrSystemDropdown,
    ]);
  }

  private loadForm() {

    this.formGroup.patchValue({
      preferredRequestMethod: this.addressDetailState?.weeklyContactMethodTypeId?.toString(),
      address1: this.addressDetailState.address1,
      address2: this.addressDetailState.address2,
      addressCity: this.addressDetailState.city,
      addressState: this.addressDetailState.state,
      addressZip: this.addressDetailState.postalCode,
      accessEmrSystem: this.addressDetailState.accessInfo.accessEmrSystem,
      accessEmrSystemDropdown: this.getEmrSystemValue,
      groupName: this.addressDetailState.groupName,
    });

    this.changeDetector.markForCheck();
  }

  private loadContactForm(contact: Contact): void {
    this.formGroup.patchValue({
      email: contact?.contactEmail,
      phone: StringHelper.removeNonAlphaNumericCharacters(contact?.contactPhone),
      fax: StringHelper.removeNonAlphaNumericCharacters(contact?.contactFax),
      contactName: contact?.contactName,
    });
  }

  private getFormValues() {
    this.addressDetailInfoEdit = new AddressDetailInfoEdit();

    this.addressDetailInfoEdit.retrievalLocationId = this.addressDetailState.masterDocumentSourceId;

    this.addressDetailInfoEdit.email = this.formGroup.get("email").value;
    this.addressDetailInfoEdit.phone = this.formGroup.get("phone").value;
    this.addressDetailInfoEdit.faxNumber = this.formGroup.get("fax").value;
    this.addressDetailInfoEdit.contactName = this.formGroup.get("contactName").value;
    const weeklyContactMethodTypeId = Number(this.formGroup.get("preferredRequestMethod").value);
    this.addressDetailInfoEdit.weeklyContactMethodTypeId = NumberHelper.isGreaterThan(weeklyContactMethodTypeId, 0) ? weeklyContactMethodTypeId : null;

    this.addressDetailInfoEdit.address1 = this.formGroup.get("address1").value;
    this.addressDetailInfoEdit.address2 = this.formGroup.get("address2").value;
    this.addressDetailInfoEdit.addressCity = this.formGroup.get("addressCity").value;
    this.addressDetailInfoEdit.addressState = this.formGroup.get("addressState").value;
    this.addressDetailInfoEdit.addressZip = this.formGroup.get("addressZip").value;
    this.addressDetailInfoEdit.emrSystem = this.getEmrDropdownValue;
    this.addressDetailInfoEdit.groupName = this.formGroup.get("groupName").value;
    this.addressDetailInfoEdit.primaryContactPhone = !this.contactToEdit?.isPrimaryContact ? StringHelper.removeNonAlphaNumericCharacters(this.contactToEdit?.contactPhone) : null;
    this.addressDetailInfoEdit.isContactVerified = this.addressDetailState.isContactVerified &&
        (StringHelper.isAvailable(this.addressDetailInfoEdit.phone) &&
        (StringHelper.isAvailable(this.addressDetailInfoEdit.faxNumber) || StringHelper.isAvailable(this.addressDetailInfoEdit.email)));

    this.changeDetector.markForCheck();
  }

  private isHDVIServiceOrg(): boolean {
    return this.organizationId === 1;
  }

  private fetchTimelineItmes(): void {
    this.addressTimelineService
      .get(this.addressDetailState.masterDocumentSourceId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  private getMailingAddress(addressDetail: AddressDetailState | AddressDetailInfoEdit): string {
    const state = addressDetail instanceof AddressDetailState ? addressDetail.state : addressDetail.addressState;
    const zipCode = addressDetail instanceof AddressDetailState ? addressDetail.postalCode : addressDetail.addressZip;
    return `${StringHelper.clean(addressDetail.address1)}${StringHelper.clean(addressDetail.address2)}
            ${StringHelper.clean(state)}${StringHelper.clean(zipCode)}`;
  }

  private setControlState(disable: boolean): void {
    const controlKeys = ["groupName", "preferredRequestMethod", "address1", "address2", "addressCity", "addressState", "addressZip", "accessEmrSystem", "accessEmrSystemDropdown"];

    controlKeys.forEach(key => {
      const control = this.formGroup.controls[key];
      disable ? control.disable() : control.enable();
    });
  }

  cancelEdit(): void {
    this.visibleChange(true);
    this.isConfirmationModalVisible = false;
  }

  continueEdit(): void {
    this.saveAddressDetail();
    this.isConfirmationModalVisible = false;
  }

  cancelChanges(): void {
    if (this.addressDetailState?.hasMasterDocumentSourceId) {
      this.loadForm();
      this.loadContactForm(this.contactToEdit);
      this.changeDetector.markForCheck();
    }
  }
}
