import { Autocomplete } from "../../inputs/autocomplete/autocomplete.model";
import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { NegativeReasonCodeGroupComponent } from "./negative-reason-code-group.component";

export interface INegativeReasonCodeGroupOptions extends IDynamicGroupOptions {
  header?: string;
}

export class NegativeReasonCodeGroup extends DynamicGroup implements INegativeReasonCodeGroupOptions {
  header: string;

  readonly controlType = "negative-reason-code-group";
  readonly componentType = NegativeReasonCodeGroupComponent;


  constructor(options: INegativeReasonCodeGroupOptions = {}) {
    super(options);
    this.header = options.header || "";
  }


  get nrcControl(): Autocomplete {
    return this.controls[0] as Autocomplete;
  }
}
