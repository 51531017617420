<div class="control control--calculated control--start">
  <div class="control__header">
    <label class="control__header__label">{{ type }} Start Date</label>
  </div>
  <div *ngIf="hasStartDate" class="control__calculated">{{ startDate | date:"MM/dd/yyyy" }}</div>
  <div *ngIf="!hasStartDate" class="control__calculated">none</div>
</div>
<div class="control control--calculated control--end">
  <div class="control__header">
    <label class="control__header__label">{{ type }} End Date</label>
  </div>
  <div *ngIf="hasEndDate" class="control__calculated">{{ endDate | date:"MM/dd/yyyy" }}</div>
  <div *ngIf="!hasEndDate" class="control__calculated">none</div>
</div>
<div class="clearfix"></div>