import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-call-count-detail-report-legacy",
  templateUrl: "./call-count-detail-report-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallCountDetailReportLegacyComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.CALL_COUNT_DETAIL_REPORT_LEGACY;

  constructor() {
    this.filters = ["Projects", "WorkflowStatus", "Products", "LOB", "RetrievalOwner"];
  }
}
