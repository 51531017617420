import { IAutoMapper } from "../interfaces";

export const mapDocumentListItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DocumentListItem")
    .forMember("chaseDocumentId", o => o.chaseDocumentId)
    .forMember("chaseId", o => o.chaseId)
    .forMember("documentQueueId", o => o.documentQueueId)
    .forMember("documentTypeId", o => o.documentTypeId)
    .forMember("documentTypeName", o => o.documentTypeName)
    .forMember("fileName", o => o.documentQueueFileName)
    .forMember("numberOfPages", o => o.chasePagesFromDocumentCount)
    .forMember("image", o => o.previewImageContent)
    .forMember("title", o => o.title)
    .forMember("description", o => o.description)
    .forMember("createDate", o => o.createDate)
    .forMember("updateDate", o => o.updateDate)
    .forMember("retrievalTypeId", o => o.retrievalTypeId)
    .forMember("retrievalTypeName", o => o.retrievalTypeName)
    .forMember("documentNumberOfPages", o => o.chaseDocumentPageCount)
    .forMember("createBy", o => o.createBy);

};
