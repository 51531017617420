import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-diagnosis-data-multiple-models-dw",
  templateUrl: "./diagnosis-data-multiple-models-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisDataMultipleModelsDWComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.DIAGNOSIS_DATA_MULTIPLE_MODELS;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["Projects"];
    this.hideFilters = [
      HideFilters.PROJECT_ID,
      HideFilters.PROJECT_NAME,
      HideFilters.PROJECT_NAME2,
      HideFilters.PROJECT_TYPE_ID,
      HideFilters.ORGANIZATION_ID,
      HideFilters.ORG_ID,
      HideFilters.ORGANIZATION_NAME,
      HideFilters.USER_ID];
  }

}
