<app-server-grid [configuration]="auditGridConfiguration"
                 [(request)]="auditGridSearchRequest"
                 [isLoadOnInit]="false"
                 [refresh]="refreshGrid"
                 [(selection)]="auditGridselection"
                 [filterTemplate]="filterForm">

  <ng-template #actionItem let-rowData>
    <app-audit-query-grid-action [data]="rowData"></app-audit-query-grid-action>
  </ng-template>

  <ng-template #auditReadyItem let-rowData>
    <app-audit-query-item-readiness [chaseDetailsForAudit]="rowData"></app-audit-query-item-readiness>
  </ng-template>

  <ng-template #filterForm
               let-form>
    <app-tab-menu orientation="left">

      <app-tab-panel header="Chase">
        <div class="ui-g-12 ui-md-12 ui-lg-12 audit-filter">
          <form-factory [formGroup]="form"
                        [model]="chaseIdInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Member">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
          <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Audit Ready">
        <div class="ui-g-12 ui-md-12 ui-lg-12 audit-filter">
          <form-factory [formGroup]="form" [model]="auditReadyInput"></form-factory>
        </div>
      </app-tab-panel>

    </app-tab-menu>
  </ng-template>

</app-server-grid>

<app-modal [(visible)]="isSelectWarningVisible">
  <div class="text-center">
    Chase has not been flagged as "Audit Ready"
  </div>
</app-modal>
