import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { ProjectDocumentType } from "../project-files/project-document-type.enum";
import { ProjectFileService } from "../project-files/project-file.service";
import { ProjectFile } from "../project-files/project-file/project-file.model";

@Component({
  selector: "app-chart-download",
  templateUrl: "./chart-download.component.html",
  styleUrls: ["./chart-download.component.scss"],
  providers: [ProjectFileService],
})
export class ChartDownloadComponent implements OnInit {

  gridConfigurationModel = new GridConfiguration();
  projectId: number;
  projectFiles: ProjectFile[] = [];
  private userId: number;
  projectName: string;
  readonly projectDocumentTypeId = ProjectDocumentType.ChartDownload;

  constructor(
    private readonly service: ProjectFileService,
    private messagingService: MessagingService,
    private userService: UserService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute
  ) {
    this.userId = this.userService.getUserToken().userId;
    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "projectdocumentid", header: "Project File ID", show: false }),
      new GridColumnDefinition({ field: "projectid", header: "Project ID", show: false }),
      new GridColumnDefinition({ field: "filename", header: "File Name" }),
      new GridColumnDefinition({ field: "username", header: "Requested by" }),
      new GridColumnDefinition({ field: "lastmodified", header: "Request Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
    ];
    this.gridConfigurationModel.pageSize = 25;
    this.gridConfigurationModel.showActionColumn = true;
    this.gridConfigurationModel.showExportAction = false;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = +params.get("projectId");
      this.loadGrid();
      this.getProjectName();
    });

  }

  loadGrid() {
    this.service
      .getProjectFiles(this.projectId, this.projectDocumentTypeId)
      .subscribe(items => {
        this.projectFiles = items;
        this.changeDetector.markForCheck();
      });
  }

  getProjectName() {
    this.service.getProjectList(this.projectId)
      .subscribe(result => {
        this.projectName = result[0].name;
        this.changeDetector.markForCheck();
      });
  }

  onViewFile(rowData): void {
    this.service.downloadFile2(rowData.projectdocumentid, this.userId, "chartDownload", this.projectDocumentTypeId);
  }

  onDeleteFile(rowData): void {
    const projectFileDeleteRequest = {
      Filename: rowData.filename,
      ProjectId: this.projectId,
      ProjectDocumentId: rowData.projectdocumentid,
      FileType: "chartDownload",
    };
    this.service.deleteProjectChartFile(projectFileDeleteRequest).subscribe(response => {
      if (response) {
        this.messagingService.showToast(`${projectFileDeleteRequest.Filename} has been deleted successfully.`, SeverityType.SUCCESS);
        this.loadGrid();
      } else {
        this.messagingService.showToast(`There was a problem deleting ${projectFileDeleteRequest.Filename}.`, SeverityType.ERROR);
      }
    });
  }
}
