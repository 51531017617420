import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ContactMethodType } from "../cover-letter-template/contact-method-type.enum";

@Injectable({
  providedIn: "root",
})
export class RetrievalOutreachService {

  constructor(
    private automapper: AutomapperService
  ) { }

  getOutReachTypes(): Observable<SelectableInput[]> {
    const outreachTypes = [
      new SelectableInput({
        text: "Fax",
        value: ContactMethodType.Fax,
      }),
      new SelectableInput({
        text: "Email",
        value: ContactMethodType.Email,
      }),
      new SelectableInput({
        text: "Mail",
        value: ContactMethodType.Mail,
      }),
      new SelectableInput({
        text: "External",
        value: ContactMethodType.External,
      }),
    ];

    return of(outreachTypes).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }
}
