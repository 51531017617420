import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { PanelModule } from "../panel/panel.module";
import { InvoiceGridComponent } from "./invoice-grid.component";

@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        DynamicFormsModule,
        GridModule,
        MenuModule,
        PanelModule,
        FormsModule,
    ],
    declarations: [
        InvoiceGridComponent,
    ],
    exports: [
        InvoiceGridComponent,
    ],
})

export class InvoiceGridModule { }
