import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { forkJoin } from "rxjs";
import { AuthService } from "../../../auth/auth.service";
import { UserToken } from "../../../auth/user-token.model";
import { UserService } from "../../../core/user/user.service";
import { ChartModel } from "../../../shared/kpi/model/chart.model";
import { DataModel } from "../../../shared/kpi/model/data.model";
import { LabelModel } from "../../../shared/kpi/model/label.model";
import { LandingFilter } from "../../../shared/kpi/model/landingFliter.model";
import { ListItem } from "../../../shared/list/list-item";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { OverreadFeedbackQuerySearch } from "../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-search.model";
import { OverreadFeedbackStatus } from "../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";
import { ProjectReportsService } from "../project/project-reports/project-reports.service";
import { ClinicalService } from "./clinical.service";


@Component({
  selector: "clinical-main",
  templateUrl: "./clinical.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ClinicalComponent implements OnInit {
  private user: UserToken;
  clinicalStatistics = List<ListItem>();
  userName: string;
  firstName: string;
  lastName: string;
  acceptRequestMessage: string;
  landingFilter: LandingFilter;

  chartModelPendsCountByPendStatus: ChartModel;
  labelModelPendsCountByPendStatus: LabelModel;
  dataModelPendsCountByPendStatus: DataModel;
  dataOptionsPendsCountByPendStatus: any;
  arrPendsCountByPendStatus: DataModel[] = [];

  chartModelChaseCompliance: ChartModel;
  labelModelChaseCompliance: LabelModel;

  dataModelChaseCompliance1: DataModel;
  dataModelChaseCompliance2: DataModel;
  dataModelChaseCompliance3: DataModel;
  dataModelChaseCompliance4: DataModel;
  dataOptionsChaseCompliance: any;
  arrChaseCompliance: DataModel[] = [];

  chartModelQAScoreTrendGraph: ChartModel;
  labelModelQAScoreTrendGraph: LabelModel;
  dataModelQAScoreTrendGraph: DataModel;
  dataOptionsQAScoreTrendGraph: any;
  arrQAScoreTrendGraph: DataModel[] = [];

  numeratorHitsChartModel: ChartModel;
  numeratorHitsLabelModel: LabelModel;
  numeratorHitsDataOptions: any;
  numeratorHitsArray: DataModel[] = [];
  numeratorHitMaxValue: number;
  clientId: number;
  projectId: number;

  constructor(
    private userService: UserService,
    private clinicalService: ClinicalService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private projectReportService: ProjectReportsService,
    private overreadFeedbackService: OverreadFeedbackService
  ) {

    this.chartModelPendsCountByPendStatus = new ChartModel(
      {
        chartHeader: "PENDS", chartType: "pie", chartWidth: 1500,
        chartHeight: 400, headerClassName: "DoughnutHeader", isResponsive: false, canvasClassName: null,
      }
    );

    this.dataOptionsPendsCountByPendStatus = {
      legend: {
        position: "bottom",
      },
      tooltips: {
        enabled: true,
      },
      hover: {
        animationDuration: 0,
      },
      scales: {
        xAxes: [{
          display: false,
          scaleLabel: {
            display: false,
            labelString: "Duration ---->",
          },

        }],
        yAxes: [{
          display: false,
          scaleLabel: {
            display: false,
            labelString: "No of Pends ---->",
          },
        }],
      },
    };

    this.chartModelChaseCompliance = new ChartModel(
      {
        chartHeader: "CHASE COMPLIANCE", chartType: "bar", chartWidth: 900,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      }
    );

    this.dataOptionsChaseCompliance = {

      legend: {
        display: true,
        position: "bottom",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: true,
          barThickness: 80,
          scaleLabel: {
            display: true,
            labelString: "Duration ---->",
          },

        }],
        yAxes: [{
          display: true,
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "No of Chases ---->",
          },
        }],

      },
      hover: {
        mode: "index",
      },
    };

    this.chartModelQAScoreTrendGraph = new ChartModel(
      {
        chartHeader: "QA SCORE TREND GRAPH", chartType: "line", chartWidth: 1280,
        chartHeight: 400, headerClassName: "BarHeader", isResponsive: false,
      }
    );

    this.dataOptionsQAScoreTrendGraph = {
      responsive: true,
      legend: {
        display: false,
        position: "bottom",
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [{
          display: true,
          stacked: false,
          barThickness: 80,
          scaleLabel: {
            display: true,
            labelString: "Duration ---->",
          },

        }],
        yAxes: [{
          display: true,
          stacked: false,
          ticks: {
            max: 100,
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "QA Score ---->",
          },
        },
        ],
      },
      hover: {
        animationDuration: 0,
      },
      animation: {
        onComplete: (ev: any) => {
          const canvas = ev.chart;
          const ctx = canvas.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          this.arrQAScoreTrendGraph.forEach((dataset, i) => {
            const meta = canvas.controller.getDatasetMeta(i);
            const callsCompletedWidthNum = 10;
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - callsCompletedWidthNum);
            });
          });
        },
      },
    };

  }

  ngOnInit() {
    this.user = this.userService.getUserToken();

    // this.userName = this.authService.username;

    // this.getUserFirstLastName(this.userName);

    // this.getMessageForMoreCharts();

    this.getStatisticsData();

    // this.getDataSetForPendsCountByPendStatus();

    // this.getDataSetForChaseCompliance();

    // this.getQAScoreTrendGraph();

    this.getNumeratorHitsKpi();
  }


  applyFilter($event) {
    this.clientId = $event.clientId;
    this.projectId = $event.projectId;
    this.getStatisticsData();
    this.getNumeratorHitsKpi();
    this.landingFilter = new LandingFilter($event);
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }


  trackByIndex(index, item) {
    return index;
  }

  getUserFirstLastName(userName): void {
    this.clinicalService.getUserFirstLastName(userName).subscribe(result => {
      this.changeDetector.markForCheck();
      this.firstName = result.firstName;
      this.lastName = result.lastName;
    });
  }

  getMessageForMoreCharts(): void {
    this.clinicalService.getMessageForMoreCharts().subscribe(result => {
      this.changeDetector.markForCheck();
      this.acceptRequestMessage = result;
    });
  }

  getStatisticsData(): void {
    forkJoin([
      this.clinicalService.getStatisticsData(this.clientId, this.projectId),
      this.overreadFeedbackService.getOverreadFeedbackList(new OverreadFeedbackQuerySearch()),
    ]).subscribe(([clinicalStats, overreadStats]) => {
      if (ArrayHelper.isAvailable(overreadStats)) {
        const data = overreadStats.filter(x => x.status !== OverreadFeedbackStatus.ABSTRACTOR);
        if (ArrayHelper.isAvailable(data)) {
          clinicalStats.push(new ListItem({key: "Feedback Challenges", value: data.length.toString(), url: "/clinical/view/challenges"}));
        }
      }
      this.clinicalStatistics = this.assignAndNotify(clinicalStats);
    });
  }

  getDataSetForChaseCompliance(): void {
    this.clinicalService.getDataSetForChaseCompliance()
      .subscribe(result => {
        this.labelModelChaseCompliance = new LabelModel({ labels: result.labels });
        this.arrChaseCompliance = [
          new DataModel({
            label: "Compliant", data: result.data[0],
            backgroundColor: ["#D6E9C6", "#D6E9C6", "#D6E9C6", "#D6E9C6", "#D6E9C6", "#D6E9C6", "#D6E9C6"],
            borderColor: "", fill: false,
          }),
          new DataModel({
            label: "Non Compliant Contra", data: result.data[1],
            backgroundColor: ["#FAEBCC", "#FAEBCC", "#FAEBCC", "#FAEBCC", "#FAEBCC", "#FAEBCC", "#FAEBCC"],
            fill: false,
          }),
          new DataModel({
            label: "Non Compliant Exclusion", data: result.data[2],
            backgroundColor: ["#EBCCD1", "#EBCCD1", "#EBCCD1", "#EBCCD1", "#EBCCD1", "#EBCCD1", "#EBCCD1"],
            fill: false,
          }),
          new DataModel({
            label: "Non Compliant", data: result.data[3],
            backgroundColor: ["#42A5F5", "#42A5F5", "#42A5F5", "#42A5F5", "#42A5F5", "#42A5F5", "#42A5F5"],
            fill: false,
          }),
        ];
        this.changeDetector.markForCheck();
      });
  }

  getDataSetForPendsCountByPendStatus(): void {
    this.clinicalService.getDataSetForPendsCountByPendStatus()
      .subscribe(result => {

        this.labelModelPendsCountByPendStatus = new LabelModel({ labels: result.labels.slice(1, 5) });
        this.dataModelPendsCountByPendStatus = new DataModel(
          {
            label: "Pends by Pend Status",
            data: result.data[0].slice(1, 5),
            url: result.urls.slice(1, 5),
            backgroundColor: ["#42A5F5", "#EBCCD1", "#FAEBCC", "#D6E9C6"],
            hoverBackgroundColor: ["#42A5F5", "#EBCCD1", "#FAEBCC", "#D6E9C6"],
            fill: false,
          }

        );

        this.arrPendsCountByPendStatus = [];
        this.arrPendsCountByPendStatus.push(this.dataModelPendsCountByPendStatus);
        this.changeDetector.markForCheck();
      });
  }

  getQAScoreTrendGraph(): void {
    this.clinicalService.getQAScoreTrendGraph()
      .subscribe(result => {
        this.labelModelQAScoreTrendGraph = new LabelModel({ labels: result.labels });
        this.dataModelQAScoreTrendGraph = new DataModel(
          {
            label: "QA Score Trend",
            data: result.data[0],
            borderColor: "#42a5f5",
            backgroundColor: "#42a5f5",
            fill: false,
          }
        );
        this.arrQAScoreTrendGraph = [];
        this.arrQAScoreTrendGraph.push(this.dataModelQAScoreTrendGraph);
        this.changeDetector.markForCheck();
      });
  }

  initializeNumeratorHitsKpi(): void {

    this.numeratorHitsChartModel = new ChartModel(
      {
        chartHeader: "NUMERATOR HITS", chartType: "horizontalBar", chartWidth: 1280,
        chartHeight: 1500, headerClassName: "BarHeader", isResponsive: false, canvasClassName: null,
      });

    this.numeratorHitsDataOptions = {
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map((labels, i) => {
                switch (i) {
                  case 0:
                    return {
                      text: "MR-Compliant",
                      fillStyle: "#fcffaf",
                    };
                  case 1:
                    return {
                      text: "Non-Compliant",
                      fillStyle: "#C8D0DB",
                    };
                  case 2:
                    return {
                      text: "NC-Contra",
                      fillStyle: "#f2917d",
                    };
                  case 3:
                    return {
                      text: "NC-Exclude",
                      fillStyle: "#bff298",
                    };
                  default:
                    return {
                      text: "",
                      fillStyle: "#fff",
                      strokeStyle: "#fff",
                    };
                }

              });
            }
            return [];
          },
        },
      },
      tooltips: {
        axis: "yAxes",
        mode: "nearest",
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: "NUMERATOR HITS",
          },
          type: "logarithmic",
          ticks: {
            min: 0,
            max: this.numeratorHitMaxValue,
            callback(value, index, values) {
              return Number(value.toString());
            },
          },
          afterBuildTicks(chart) {
            const num = parseFloat(chart.max);
            chart.ticks = [];
            const percentages = [0, 1, 30, 55]; // used to calculate Percentage to display graph values in Kpi.
            for (const row of percentages) {
              const value = (num - (num - (num * row / 100))).toFixed();
              const digits = Math.ceil(Math.log(parseInt(value, 10)) / Math.LN10) < 3 ? 10 : 100;
              chart.ticks.push(Math.ceil(parseInt(value, 10) / digits) * digits);
            }
            const maxValueRound = Math.ceil((Math.log(parseInt(chart.max, 10)) / Math.LN10)) < 3 ? 10 : 100;
            chart.ticks.push(Math.ceil(parseInt(chart.max, 10) / maxValueRound) * maxValueRound);
          },
        }],
        yAxes: [{
          display: true,
          stacked: false,
          barThickness: 8,
        }],
      },
      hover: {
        animationDuration: 0,
      },
    };

  }

  getNumeratorHitsKpi(): void {
    this.projectReportService.getNumeratorHitsKpi(this.clientId, this.projectId)
      .subscribe(result => {
        this.numeratorHitMaxValue = this.getKpiMaxValue(result.data) + 100;

        this.numeratorHitsLabelModel = new LabelModel({ labels: result.labels });
        this.numeratorHitsArray = [
          new DataModel({
            label: "MR-Compliant", data: result.data[0], backgroundColor: "#fcffaf",
            borderColor: "#fcffaf", fill: false,
            parameters: result.ids,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "Non-Compliant", data: result.data[1], backgroundColor: "#C8D0DB",
            borderColor: "#C8D0DB", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "NC-Contra", data: result.data[2], backgroundColor: "#f2917d",
            borderColor: "#f2917d", fill: false,
            url: [`reporting/chase`],
          }),
          new DataModel({
            label: "NC-Exclude", data: result.data[3], backgroundColor: "#bff298",
            borderColor: "#bff298", fill: false,
            url: [`reporting/chase`],
          }),
        ];
        this.initializeNumeratorHitsKpi();
        this.changeDetector.markForCheck();
      });
  }

  getKpiMaxValue(data): number {
    let max = -Infinity;
    for (const item of data) {
      let len = item.length;
      while (len--) {
        if (Number(item[len]) > max) {
          max = Number(item[len]);
        }
      }
    }
    return max;
  }

}
