import { Injectable } from "@angular/core";
import { SessionService } from "../../../../core/storage/session.service";
import { LandingFilter } from "../../model/landingFliter.model";

@Injectable({
  providedIn: "root",
})
export class LandingFilterStateService {
  private readonly HEADER_FILTER_SESSION_KEY = "HEADER_FILTER";


  constructor(private session: SessionService) { }


  get(): LandingFilter {
    const landingFilter: LandingFilter = this.session.get<LandingFilter>(this.HEADER_FILTER_SESSION_KEY, new LandingFilter());
    return landingFilter;
  }

  put(state: LandingFilter): void {
    this.session.put(this.HEADER_FILTER_SESSION_KEY, state);
  }

  clear(): void {
    this.session.delete(this.HEADER_FILTER_SESSION_KEY);
  }
}
