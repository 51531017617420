export class ProjectNodeModel {
  readonly projectId: number;
  readonly projectName: string;
  readonly templateType: string;

  constructor(options: {

    projectId: number;
    projectName: string;
    templateType: string;

  }) {

    this.projectId = options.projectId;
    this.projectName = options.projectName;
    this.templateType = options.templateType;

  }
}
