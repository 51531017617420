import { PhoneNumberPipe } from "../../../../shared/pipes/phone.pipe";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ContactMethodType } from "./contact-method-type.enum";
import { ContactRequest } from "./contact-request.model";

export class FaxRequest extends ContactRequest {
  private phonePipe = new PhoneNumberPipe();
  readonly toLabel = "Fax #";
  readonly type = ContactMethodType.Fax;

  get toValue(): string {
    return this.phonePipe.transform(this.to);
  }

  get isValid(): boolean {
    const toAsString = this.to.toString();
    const checkForAllZeroes = /^\(?(0{3})\)?[ -.]?(0{3})[ -.]?(0{4})$/;

    return StringHelper.isAvailable(toAsString)
     && (ArrayHelper.clean(toAsString.match(checkForAllZeroes)).length < 1);
  }
}
