import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FtDetailAccessInfoService {

  constructor(
    private route: ActivatedRoute
  ) { }

  getFTGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd;
  }
}
