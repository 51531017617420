import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export const mapFileDetail = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "FileDetail")
    .forMember("filePath", o => o.filePath)
    .forMember("fileName", o => o.fileName)
    .forMember("fileSize", o => o.fileSize)
    .forMember("uploadDateTime", o => o.uploadDateTime)
    .forMember("uploadedBy", o => o.uploadedBy)
    .forMember("attributeId", o => o.attributeId)
    .forMember("attributeName", o => o.attributeName);
};
export class FileDetail {
  filePath?: string;
  fileName?: string;
  fileSize?: string;
  uploadDateTime?: string;
  uploadedBy?: string;
  attributeId?: string;
  attributeName?: string;

  constructor(options: {
    filePath?: string;
    fileName?: string;
    fileSize?: string;
    uploadDateTime?: string;
    uploadedBy?: string;
    attributeId?: string;
    attributeName?: string;
  }) {
    this.filePath = StringHelper.clean(options.filePath);
    this.fileName = StringHelper.clean(options.fileName);
    this.fileSize = StringHelper.clean(options.fileSize);
    this.uploadDateTime = StringHelper.clean(options.uploadDateTime);
    this.uploadedBy = StringHelper.clean(options.uploadedBy);
    this.attributeId = StringHelper.clean(options.attributeId);
    this.attributeName = StringHelper.clean(options.attributeName);
  }
}
