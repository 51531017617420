import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { CommitmentDateService } from "./commitment-date.service";
import { CommitmentDateUpdateModel } from "./commitmentDateUpdate.model";

@Component({
  selector: "retrieval-commitment-date",
  templateUrl: "./commitment-date.component.html",
  styleUrls: ["./commitment-date.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommitmentDateComponent implements OnChanges {
  minimumDate: Date;
  @Input() updateCommitmentData: CommitmentDateUpdateModel;
  @Input() visible = false;
  @Input() isCommitmentDateModalVisible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<null> = new EventEmitter();
  @Output() onUpdate: EventEmitter<null> = new EventEmitter();
  private pCommitDate: Date;
  @Input()
  get commitDate(): Date {
    return this.pCommitDate;
  }
  set commitDate(value: Date) {
    this.pCommitDate = value;
  }
  @Output() commitDateChange: EventEmitter<Date> = new EventEmitter();
  @Input() isThirdParty = false;
  label = "";
  headerLabel = "";

  constructor(
    private readonly commitmentDateService: CommitmentDateService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService
  ) {
      this.minimumDate = new Date();
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.label = this.isThirdParty ? "Request Date" : "Commitment Date";
      this.headerLabel = this.isThirdParty ? "Set Request Date" : "Set Commitment Date";
    }
  }

  onSetCommitmentDate() {
    const formattedDate = DateHelper.format(this.commitDate);

    if (!this.isThirdParty) {
      this.updateCommitmentData.commitmentDate = formattedDate === "" ? null : formattedDate;
    } else {
      this.updateCommitmentData.requestDate = DateHelper.format(this.commitDate);
    }
    if (ArrayHelper.isAvailable(this.updateCommitmentData.chaseIds)) {
      this.commitmentDateService.updateCommitmentDate(this.updateCommitmentData).subscribe(
        data => {
          this.messagingService.showToast(`${this.label} updated successfully.`, SeverityType.SUCCESS);
          this.onUpdate.emit(null);
        },
        err => {
          this.messagingService.showToast(`Error while updating ${this.label}`, SeverityType.ERROR);
        }
      );
    } else {
        this.messagingService.showToast("Please select at least one chase.", SeverityType.WARN);
        return;
    }

    this.onModalHide();
    this.changeDetector.markForCheck();
  }

  onModalHide() {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit(null);
    this.commitDate = null;
    this.changeCommitDate(this.commitDate);
  }

  clearCommitmentDate(): void {
    this.commitDate = null;
  }

  changeCommitDate(value: Date): void {
    this.commitDateChange.emit(value);
  }

}
