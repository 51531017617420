import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { FtEditModel } from "./ft-edit-model";

@Injectable()

export class FtDetailEditService {
  reset = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  resetFt(ftId: number) {

    this.reset.next(ftId);
  }

  getFTGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.FtGd;
  }

  getFtDetailsForEdit(): Observable<FtEditModel> {
    const url = `${this.baseApiUrl}fieldTech/ftDetailsForEdit?ftId=${this.getFTGdFromPath()}`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curry("default", "FtEditModel"))
      );
  }

  updateFtDetails(ftEditModel: FtEditModel): Observable<number> {
    const url = `${this.baseApiUrl}fieldTech/UpdateFtDetails`;

    return this.http
    .post(url, ftEditModel)
    .pipe(
      map((response: any) => response as number));
  }
}
