import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ContactMethodType } from "./contact-method-type.enum";
import { ContactRequest } from "./contact-request.model";

export class ExternalRequest extends ContactRequest {
  readonly toLabel = "External";
  readonly type = ContactMethodType.External;

  get toValue(): string {
    return StringHelper.clean(this.to.toString());
  }

  get isValid(): boolean {
    return ArrayHelper.isAvailable(this.chaseIds);
  }
}
