<app-modal class="cover-template"
           [(visible)]="visible"
           header="{{ header | uppercase }}"
           (visibleChange)="visibleChange.emit($event)"
           (onShow)="open()"
           (onHide)="close()"
           (onCancel)="close()">

  <div class="cover-letter-template__body">
    <div class="cover-letter-template__body--details">
      <div class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">TO :</div>
        <div class="cover-letter-template__detail--value">{{ toName }}</div>
      </div>
      <div class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">{{ toLabel | uppercase }} :</div>
        <div class="cover-letter-template__detail--value">{{ to }}</div>
      </div>
    </div>
    <div class="cover-letter-template__body--details">
      <div class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">FROM :</div>
        <div class="cover-letter-template__detail--value">{{ fromName }}</div>
      </div>
      <div class="cover-letter-template__detail">
        <div class="cover-letter-template__detail--label bold">TOTAL CHASES :</div>
        <div class="cover-letter-template__detail--value">{{ totalChases }}</div>
      </div>
    </div>
    <br />
    <div *ngIf="isFaxCoverSheet" class="cover-letter-template__body--details">
      <div class="cover-letter-template__detail--label bold">TOTAL FAX PACKAGES (25 CHASES PER) : {{ batchedFaxCount }}</div>
    </div>
    <div class="cover-letter-template__body--templateDropDown">
      <form-factory [formGroup]="coverTemplateFromGroup" [model]="coverLetterTemplate"></form-factory>
    </div>
  </div>

  <footer>
    <app-button class="header-button" text="{{ typeName | uppercase }}" (onClick)="requestClicked($event)"></app-button>
  </footer>
</app-modal>
