<app-collapsible-panel [startCollapsed]=true>
  <header>
    <app-button class="button" styleType="fas" type="fa" [icon]="collapseIcon" classes="transparent small" (onClick)="toggle($event)"></app-button>
    <div class="filter-text">
      Filters Applied:
      <span *ngFor="let filter of groupedFilters; let i = index; trackBy: trackByIndex">
        {{filter?.displayName + ' (' + filter?.displayValues?.length + ')' }}
        <span *ngIf="isNotLastElement(i, groupedFilters)">, </span>
      </span>
    </div>
    <app-button class="button clear-button--right" text="Save Query" *ngIf="isSavedQueryNameExists" classes="transparent" (onClick)="updateQueryViewAndFilters()"></app-button>
    <app-button class="button clear-button--right" text="Modify" classes="transparent" (onClick)="modifyQuery()"></app-button>
    <app-button class="button clear-button--right" [text]="saveQueryButtonName" *ngIf="isSaveQueryVisible" classes="transparent" (onClick)="saveQuery()"></app-button>
    <app-button class="button clear-button--right" text="Delete" *ngIf="isSavedQueryNameExists" classes="transparent" (onClick)="deleteQuery()"></app-button>
  </header>
  <div
    class="chip"
    *ngFor="let filter of filteredFilters; let i = index; trackBy: trackByIndex"
    (click)="delete($event, i)">
    <div class="chip__item chip__item--name">
      {{ filter.text }}:&nbsp;
    </div>
    <div class="chip__item chip__item--value">
      {{ filter.value }}
    </div>
    <div class="chip__item chip__item--icon">
      <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
    </div>
  </div>
</app-collapsible-panel>