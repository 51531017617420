
import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
export const mapPendCodeItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "CustomPendCode")
    .forMember("PendTypeId", o => o.PendTypeId)
    .forMember("Name", o => o.Name)
    .forMember("Description", o => o.Description)
    .forMember("IsClinical", o => o.IsClinical)
    .forMember("DefaultOwner", o => o.DefaultOwner)
    .forMember("IsAutoClose", o => o.IsAutoClose);

};

export class CustomPendCode {
  pendTypeId: number;
  name: string;
  description: string;
  isClinical: boolean;
  defaultOwner: string;
  isAutoClose: boolean;


  constructor(options: {
    pendTypeId: number;
    name: string;
    description: string;
    isClinical: boolean;
    defaultOwner: string;
    isAutoClose: boolean;

  }) {

    this.pendTypeId = options.pendTypeId;
    this.name = StringHelper.clean(options.name);
    this.description = StringHelper.clean(options.description);
    this.isClinical = options.isClinical;
    this.defaultOwner = options.defaultOwner;
    this.isAutoClose = options.isAutoClose;

  }
}
