import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { BmiGroupComponent } from "./bmi-group.component";

export interface IBmiGroupOptions extends IDynamicGroupOptions {
  memberDateOfBirth?: string;
}

export class BmiGroup extends DynamicGroup implements IBmiGroupOptions {
  readonly controlType = "bmi-group";
  readonly componentType = BmiGroupComponent;
  memberDateOfBirth: string;


  constructor(options: IBmiGroupOptions = {}) {
    super(options);
    this.memberDateOfBirth = options.memberDateOfBirth;
  }
}
