import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { EntityError } from "../../../../../api/member-validation/entity-error.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";

@Component({
  selector: "member-entity-error-modal",
  templateUrl: "./entity-error-modal.component.html",
  styleUrls: ["./entity-error-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityErrorModalComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  visible = false;
  errors: EntityError[];


  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(this.chaseDetailStateService.state.subscribe(state => {
      this.visible = ArrayHelper.isAvailable(state.entityErrors);
      this.errors = ArrayHelper.clean(state.entityErrors);
      this.changeDetector.markForCheck();
    }));
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  getDisplayText(error: EntityError): string {
    return error.validationMessage;
  }

  clearErrors(): void {
    this.chaseDetailStateService.setData({ entityErrors: [] });
  }

  trackByIndex(index, item) {
    return index;
  }
}
