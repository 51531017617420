import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../auth/user-token.model";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { UserService } from "../../core/user/user.service";
import { FormService } from "../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { NumeratorService } from "../../platform/api/numerator/numerator.service";
import { ClinicalPageService } from "../../platform/modules/clinical/clinical-page/clinical-page.service";
import { PLAN_LIST } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { ProjectRetrievalType } from "../../platform/modules/member/create-new-chase/project-retrieval-type.enum";
import { GapQueryRouteFilters } from "../../platform/modules/project/gap-query/qap-query-route-filters.model";
import { AddressDetailStateService } from "../../platform/modules/retrieval/address-detail/address-detail-state.service";
import { RetrievalPageService } from "../../platform/modules/retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../platform/modules/service-org-admin/service-org-config/service-org-config.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { ActionButton } from "../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../zdevcontrols/dev-controller/dev-controller.service";
import { ChaseIdComponent } from "../chase-grid/chase-id.component";
import { BulkAction } from "../grid/bulk-actions/bulk-action.model";
import { GridView } from "../grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../grid/grid-menu/grid-views/grid-views.service";
import { GridStateService } from "../grid/grid-state.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { ServerGridComponent } from "../grid/server-grid/server-grid.component";
import { LandingFilterStateService } from "../kpi/kpi-filter/landing-filter/landing-filter-state.service";

@Component({
  selector: "app-gap-grid",
  templateUrl: "./gap-grid.component.html",
  styleUrls: ["./gap-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @Input() chaseId: number;
  @Input() addressId: number;
  @Input() stateName = "";
  @Input() url = `${this.baseApiUrl}gap/query`;
  @Input() selection: any[];
  @Input() additionalBulkActions: BulkAction[] = [];
  @Input() additionalColumns: GridColumnDefinition[] = [];
  @Input() additionalFilters: GridFilter[] = [];
  @Input() isSelectionModeMultiple = false;
  @Input() rowDisabledCondition;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  @Input() hideDisabledRows = false;
  @Input() viewAttributeId = 0;
  @Input() showViews = false;
  @Input() showActionColumn = false;
  @Input() isLoadOnInit = false;
  @Input() clinical: boolean | null;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  user: UserToken;
  serverGridConfiguration: GridConfiguration;
  serverRequest: GridRequest;
  form: FormGroup;
  actions: BulkAction[];
  landingFilter: any;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  @ViewChild("filterForm", { static: true }) filterForm: TemplateRef<any>;
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  @ViewChild("gapForm") gapForm: FormGroupDirective;
  refreshViews = new EventEmitter<GridView>(true);
  views: GridViewsState;
  gridData: any;
  filterHeaderText = "Gap Query";
  @Input() routeParameters: GapQueryRouteFilters;

  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }

  get measureInput(): CheckboxGroup {
    return this.getInput("MeasuresCodes");
  }
  set measureInput(value: CheckboxGroup) {
    this.setInput("MeasuresCodes", value);
  }

  get numeratorInput(): CheckboxGroup {
    return this.getInput("NumeratorCodes");
  }
  set numeratorInput(value: CheckboxGroup) {
    this.setInput("NumeratorCodes", value);
  }

  get plansInput(): CheckboxGroup {
    return this.getInput("Plans");
  }
  set plansInput(value: CheckboxGroup) {
    this.setInput("Plans", value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly devService: DevControllerService,
    private userService: UserService,
    private readonly automapper: AutomapperService,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly numeratorService: NumeratorService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly formService: FormService,
    private readonly landingFilterStateService: LandingFilterStateService,
    private readonly gridStateService: GridStateService,
    private gridViewsService: GridViewsService,
    private cd: ChangeDetectorRef,
    private readonly addressDetailStateService: AddressDetailStateService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.addressDetailStateService.state.subscribe(state => {
      this.gridData = state.chaseGridData;
    });
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);

    if (this.isQueryView || this.isRetrievalView) {
      this.getAllSelectableInputs();
      this.createGrid();
    } else if (this.isChaseView) {
      this.createSingleChaseGrid();
    }

    this.showFiltersOrGetData();

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView))
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  updateGridConfiguration(alreadyInitialized = true) {
    // HACK: Chase Query uses this method to update the columns
    this.serverGridConfiguration = new GridConfiguration({
      isInit: alreadyInitialized,
      columns: this.getColumns(),
      selectionMode: this.isSelectionModeMultiple ? "multiple" : "",
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      isStateDisabled: this.hasRouteParamaters,
      stateName: this.stateName,
      showViews: this.showViews,
      viewAttributeId: this.viewAttributeId,
      showActionColumn: this.showActionColumn,
    });
  }

  createGrid(): void {
    this.updateGridConfiguration(false);

    this.serverRequest = new GridRequest({
      url: this.url,
      filters: this.getFilters(),
    });
    this.getViews();
    this.actions = this.getActions();
  }

  createSingleChaseGrid(): void {
    this.updateGridConfiguration(false);

    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}gap/query`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "ChaseIdAndClientChaseKey",
          value: this.chaseId.toString(),
          show: false,
        }),
      ],
    });

    this.actions = this.getActions();
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.serverGridConfiguration.showViews) {
      this.gridViewsService.get(this.serverGridConfiguration.viewAttributeId).subscribe(views => {
        this.views = views;

        if (gridView != null) {
            setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }

        this.cd.markForCheck();
      });
    }
  }

  private getColumns(): GridColumnDefinition[] {
    const totalColumns = [
      new GridColumnDefinition({ field: "chaseId", template: this.chaseIdColumn, header: "Chase ID", width: "105px" }),
      new GridColumnDefinition({ field: "chaseSourceAliasId", header: "Client Chase Key" }),
      new GridColumnDefinition({ field: "memberSourceAliasId", header: "Member Key" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "numeratorName", header: "Gap" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
      new GridColumnDefinition({ field: "serviceProviderName", header: "Provider" }),
      new GridColumnDefinition({ field: "reportingStatusName", header: "Chase Status" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "pendStatusName", header: "Pend Status" }),
      new GridColumnDefinition({ field: "numeratorComplianceCode", header: "Gap Compliance" }),
      new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "plan", header: "Health Plan" }),
    ];

    // https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript
    const distinctColumns = totalColumns.filter(({ field: id1 }) =>
                            !this.additionalColumns.some(({ field: id2 }) => id2 === id1));
    return distinctColumns;
  }

  get getFilterForm(): TemplateRef<any> | null {
    return (this.isQueryView || this.isRetrievalView) ? this.filterForm : null;
  }

  get isQueryView(): boolean {
    return this.chaseId == null && this.addressId == null;
  }

  get isChaseView(): boolean {
    return this.chaseId != null;
  }

  get isRetrievalView(): boolean {
    return this.addressId != null;
  }

  get isGapFilterValid(): boolean {
    return ArrayHelper.isAvailable(this.gapForm?.value?.Projects);
  }

  showFiltersOrGetData(): void {
    let filters = [];

    if (this.isLandingFilterApplied) {
      this.gridStateService.delete(this.stateName);
    }

    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName) && !this.hasRouteParamaters) {
      const gridState = this.gridStateService.get(this.stateName);
      filters = gridState.request.filters.filter(filter => filter.input.value);
    } else {
      filters = this.serverRequest.filters.filter(filter => StringHelper.isAvailable(filter.input.value));
    }

    if (!this.isLoadOnInit && history.state.isNavigationLink) {
      this.serverGridComponent.showFilters();
    } else {
      setTimeout(() => this.refreshGrid.emit());
    }
  }

  private getFilters(): GridFilter[] {
    this.landingFilter = this.landingFilterStateService.get();
    if (this.isLandingFilterApplied) {

      this.gridStateService.delete(this.stateName);
    }
    this.landingFilterStateService.clear();

    const totalFilters = [
      ...this.additionalFilters,
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Projects",
        name: "Projects",
        value: this.getRouteParamterValue("projectIds").toString(),
        forceFilter: true,
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "MeasuresCodes",
        name: "Measures",
        value: this.getRouteParamterValue("measures"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "NumeratorCodes",
        name: "Gaps",
        value: this.getRouteParamterValue("numeratorCodes"),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Plans",
        name: "Plans List",
      }),
    ];
    const distinctFilters = totalFilters.filter((gridFilters, i, filters) => {
      return filters.findIndex(a => a.key === gridFilters.key) === i;
    });

    return distinctFilters;
  }

  get isLandingFilterApplied(): boolean {
    return this.landingFilter && NumberHelper.isAvailable(this.landingFilter.clientId) || this.landingFilter && NumberHelper.isAvailable(this.landingFilter.projectId);
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      ...this.additionalBulkActions, // placeholder , if we will need to add common action items
    ];

    const distinctBulkActions = totalBulkActions.filter((gridAction, i, filters) => {
      return filters.findIndex(a => a.name === gridAction.name) === i;
    });

    return distinctBulkActions;
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getMeasures();
    this.getNumerators();
    this.getPlans();
  }

  private getProjects(): void {
    this.retrievalPageService.getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(results => {
        const options = results.filter(x => x.extra.projectRetrivalTypeId === ProjectRetrievalType.Gap);
        this.projectsInput = { ...this.projectsInput, options } as any;
        this.setProjectsInput();
        this.setControlValue(this.projectsInput, false);
        this.formService.updateDom.next();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService.getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => {
        this.measureInput = { ...this.measureInput, options } as any;
        this.setControlValue(this.measureInput, false);
        this.formService.updateDom.next();
      });
  }

  private getNumerators(): void {
    this.numeratorService.getNumeratorList(null, null, this.user.organizationId)
      .pipe(map(this.automapper.curryMany("DisplayNumeratorListItem", "SelectableInput")))
      .subscribe(options => {
        this.numeratorInput = { ...this.numeratorInput, options } as any;
        this.setControlValue(this.numeratorInput, false);
        this.formService.updateDom.next();
      });
  }

  private getInput<T extends DynamicInput>(key: string): T {
    if (this.serverRequest == null) {
      return null;
    }

    return this.serverRequest.getInput<T>(key);
  }

  private setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.serverRequest == null) {
      return null;
    }

    this.serverRequest.setInput<T>(key, value);
  }

  get hasRouteParamaters(): boolean {
    if (this.routeParameters == null) {
      return false;
    }
    const parametersWithValues = Object.values(this.routeParameters).filter(x => StringHelper.isAvailable(x));
    return ArrayHelper.isAvailable(parametersWithValues);
  }

  private getRouteParamterValue(name: string): string {
    return this.hasRouteParamaters ? this.routeParameters[name] : "";
  }

  private setProjectsInput(): void {
    const projectFilter = this.serverRequest.getFilter(this.projectsInput.key);
    if (StringHelper.isAvailable(projectFilter.value)) {
      const optionsValue = this.projectsInput.options.find(a => a.value === +projectFilter.value);
      projectFilter.inputValue = [optionsValue];
    }
  }

  private setControlValue(control: CheckboxGroup, isStringValue = true): void {
    const filter = this.serverRequest.getFilter(control.key);
    if (StringHelper.isAvailable(filter.input.value)) {
      const filterData = filter.input.value.split(",");
      filterData.forEach(item => {
        if (ArrayHelper.isAvailable(control.options)) {
          filter.inputValue.push(control.options.find(a => a.value === (isStringValue ? item : +item)));
        }
      });
      control.selectedOptions = filter.inputValue;
    }
  }

  private getPlans(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PLAN_LIST.attributeId)
      .subscribe(configuration => {
        this.getPlansInput(configuration);
        this.setPlansInput();
        this.formService.updateDom.next();
        this.cd.markForCheck();
      });
   }

  private getPlansInput(configuration: ServiceOrgAttribute): void {
    const plansAttribute = JSON.parse(configuration.attributeValue);
    const plansOptions = plansAttribute.Plans.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.plansInput = { ...this.plansInput, options: plansOptions } as any;
  }

  private setPlansInput(): void {
    const plansFilter = this.serverRequest.getFilter(this.plansInput.key);
    if (StringHelper.isAvailable(plansFilter.value)) {
      const optionsValue = this.plansInput.options.find(a => a.value === +plansFilter.value);
      plansFilter.inputValue = [optionsValue];
      this.formService.updateDom.next();
    }
  }

}
