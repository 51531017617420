import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { BooleanHelper } from "../../../../../../../utilities/contracts/boolean-helper";

@Component({
  selector: "app-risk-dx-validation-buttons",
  templateUrl: "./validation-buttons.component.html",
  styleUrls: ["./validation-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationButtonsComponent {
  @Input() disabled = false;
  @Input() isValidated: boolean;
  @Input() isAllDxAddedByCoder: boolean;
  @Output() onValid = new EventEmitter<DynamicFormEvent>();
  @Output() onInvalid = new EventEmitter<DynamicFormEvent>();
  @Output() addNewDx = new EventEmitter<DynamicFormEvent>();

  get isValid(): boolean {
    return BooleanHelper.isAvailable(this.isValidated) && this.isValidated;
  }

  get isInvalid(): boolean {
    return BooleanHelper.isAvailable(this.isValidated) && !this.isValidated;
  }


  toggleActiveButton(isValidated: boolean): void {
    if (this.disabled) {
      return;
    }

    this.isValidated = isValidated;

    if (this.isValid) {
      this.onValid.emit();
    }

    if (this.isInvalid) {
      this.onInvalid.emit();
    }
  }

  addNewDiagnosis(): void {
    this.addNewDx.emit();
  }
}
