<div class="ui-g">
  <div class="ui-g-3">
    <h3>Encounters</h3>
  </div>
  <div class="ui-g-7">
    <app-header-statistics [headerStatistics]="headerStatistics"></app-header-statistics>
    <member-risk-system-result-key *ngIf="isShowNlpComponent" class="result-key" [systemResultKeys]="systemResultKeys"></member-risk-system-result-key>
  </div>
  <div class="ui-g-2">
    <member-add-encounter class="encounter-grid--member-add-encounter"></member-add-encounter>
  </div>
</div>

<app-basic-grid
  #encounterGrid  
  class="selection-grid"
  [configuration]="gridConfiguration"
  [data]="encounters"
  [(selection)]="selection"
  (selectionChange)="setSelectedEncounterIndex()">
  
  <ng-template #deleteButton let-rowData>
    <member-delete-encounter [encounter]="rowData"></member-delete-encounter>
  </ng-template>
  
  <ng-template #exemptColumn let-rowData>
    <member-exempt-encounter [encounter]="rowData"></member-exempt-encounter>
  </ng-template>
  
  <ng-template #systemColumn let-rowData>
    <member-risk-system-result [data]="rowData"></member-risk-system-result>
  </ng-template>

</app-basic-grid>