import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RetrievalContactHistory } from "../retrieval-detail-contact-history.model";
import { RecordingPlaybackService } from "./recording-playback.service";

@Component({
  selector: "retrieval-recording-playback",
  templateUrl: "./recording-playback.component.html",
  styleUrls: ["./recording-playback.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingPlaybackComponent {
  @Input() recordingData: RetrievalContactHistory;
  readonly callRecordModalHeader = "CALL RECORDING";
  isVisible = false;
  fileUrl: SafeResourceUrl;
  transcription: string;

  constructor(
    private service: RecordingPlaybackService,
    private readonly domSanitizer: DomSanitizer
  ) {
  }

  toggleAndGetCallTranscription() {
    this.toggle();
    if (this.isVisible) {
      this.getCallTranscription();
    }
  }

  private toggle() {
    this.isVisible = !this.isVisible;
  }

  get isAvailable(): boolean {
    return StringHelper.isAvailable(this.callSid) && StringHelper.isAvailable(this.recordingData.callRecordUrl);
  }

  get callSid(): string {
    return StringHelper.clean(this.recordingData.callSId);
  }

  // TODO: This is a temp fix to address disabling of transcription. Keeping the typescript for now as per PR review comments
  get callTranscriptionPreviewText(): string {
    return StringHelper.clean(this.recordingData.callTranscriptionPreviewText).concat("...");
  }

  // TODO: This is a temp fix to address disabling of transcription. Keeping the typescript for now as per PR review comments
  get hasTranscript(): boolean {
    return StringHelper.isAvailable(this.recordingData.callTranscriptionPreviewText);
  }

  get callRecordSid(): string {
    return StringHelper.clean(this.recordingData.callRecordSID);
  }

  get callTo(): string {
    return `CALL TO: ${this.recordingData.contactDetail}`;
  }

  get callTime(): string {
    return this.recordingData.callStartTime;
  }

  get callRecordedAudio(): string {
    if (this.isVisible) {
      const callRecordUrl = this.recordingData.callRecordUrl;
      const wavUrl = callRecordUrl.endsWith("mp3") ? callRecordUrl.replace(new RegExp("mp3" + "$"), "wav") : callRecordUrl;
      return wavUrl;
    }
  }

  close() {
    this.isVisible = false;
  }

  getCallTranscription() {
    if (StringHelper.isAvailable(this.recordingData.callTranscriptionAwsFileName)) {
    this.service.getCallTranscription(this.recordingData.callTranscriptionAwsFileName)
      .subscribe(data => {
        this.transcription = data;
        });
    }
  }

  // TODO: This is a temp fix to address disabling of transcription.Keeping the typescript for now as per PR review comments
  // TODO: Will replace bypassSecurityTrustResourceUrl in future. Witout it, its gives warning: sanitizing unsafe URL blob.
  downloadTranscript() {
    const blob = new Blob([this.transcription], { type: "application/octet-stream" });
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    (window.URL.createObjectURL(blob));
  }

}
