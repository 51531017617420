import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { ToastModule } from "primeng/toast";
import { MessagesComponent } from "./messages/messages.component";
import { ToastComponent } from "./toast/toast.component";

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    MessagesModule,
    MessageModule,
  ],
  declarations: [
    ToastComponent,
    MessagesComponent,
  ],
  exports: [
    ToastComponent,
    MessagesComponent,
  ],
})
export class MessagingModule { }
