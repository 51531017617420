import { IAutoMapper } from "../../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { Diagnosis } from "../diagnosis/diagnosis.model";
import { RiskHelper } from "../risk-helper.model";
import { RiskState } from "../risk-state.model";
import { RiskSystemResultType } from "../risk-system-result/risk-system-result-type.enum";

export const mapEncounter = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Encounter")
    .forMember("id", o => o.id)
    .forMember("isAdmin", o => o.isAdmin)
    .forMember("entityId", o => o.entityId)
    .forMember("enc", o => o.enc)
    .forMember("sig", o => o.sig)
    .forMember("claimId", o => o.claimId)
    .forMember("providerSourceAliasId", o => o.providerSourceAliasId)
    .forMember("provider", o => o.provider)
    .forMember("startDate", o => o.startDate)
    .forMember("endDate", o => o.endDate)
    .forMember("isNlp", o => o.isNlp)
    .forMember("chartPageNumber", o => o.pageNumber);

  automapper
    .createMap("RiskData", "Encounter")
    .forMember("id", o => o.id)
    .forMember("encounterId", o => o.entityId)
    .forMember("isAdmin", o => RiskHelper.getAttribute("ClaimID", o).isAdmin)
    .forMember("enc", RiskHelper.getBoolValueCurry("EncounterFound"))
    .forMember("sig", RiskHelper.getBoolValueCurry("AcceptableSignature"))
    .forMember("claimId", RiskHelper.getStringValueCurry("ClaimID"))
    .forMember("providerSourceAliasId", RiskHelper.getStringValueCurry("ProviderSourceAliasID"))
    .forMember("provider", RiskHelper.getStringValueCurry("ProviderName"))
    .forMember("startDate", RiskHelper.getStringValueCurry("StartDate"))
    .forMember("endDate", RiskHelper.getStringValueCurry("EndDate"))
    .forMember("exempt", RiskHelper.getBoolValueCurry("ExemptFromScoring"))
    .forMember("nlpReviewResult", RiskHelper.getStringValueCurry("NlpReviewResult"))
    .forMember("diagnoses", o => ArrayHelper.isAvailable(o.diagnoses) ? automapper.mapMany("RiskData", "Diagnosis", o.diagnoses) : [])
    .forMember("isNlp", o => RiskHelper.getAttribute("NlpReviewResult", o).isNlp)
    .forMember("pageNumber", RiskHelper.getNumberValueCurry("ChartPageNumber"))
    .forMember("type", RiskHelper.getStringValueCurry("EncounterType"))
    .forMember("encounterFound", RiskHelper.getStringValueCurry("EncounterFound"))
    .forMember("encounterFoundFromNLP", RiskHelper.getStringValueCurry("encounterFoundFromNLP"));
};

export interface IEncounterOptions {
  id?: string;
  isAdmin?: boolean;
  entityId?: number;
  enc?: boolean;
  sig?: boolean;
  claimId?: string;
  providerSourceAliasId?: string;
  provider?: string;
  startDate?: string;
  endDate?: string;
  exempt?: boolean;
  nlpReviewResult?: string;
  diagnoses?: Diagnosis[];
  isNlp?: boolean;
  pageNumber?: number;
  encounterId?: number;
  type?: string;
  encounterFound?: string;
  encounterFoundFromNLP?: boolean;
  chartPageNumber?: number;
}

export class Encounter {
  id: string;
  isAdmin: boolean;
  entityId: number;
  enc: boolean;
  sig: boolean;
  claimId: string;
  providerSourceAliasId: string;
  provider: string;
  startDate: Date;
  endDate: Date;
  exempt: boolean;
  nlpReviewResult: string;
  diagnoses: Diagnosis[];
  isNlp: boolean;
  pageNumber: number;
  encounterId: number;
  type: string;
  encounterFound: string;
  encounterFoundFromNLP: boolean;
  chartPageNumber?: number;

  constructor(options: IEncounterOptions = {}) {
    this.id = StringHelper.clean(options.id, StringHelper.createId());
    this.isAdmin = BooleanHelper.tryGet(options.isAdmin, false);
    this.entityId = options.entityId;
    this.enc = BooleanHelper.tryGet(options.enc, null);
    this.sig = BooleanHelper.tryGet(options.sig, null);
    this.claimId = StringHelper.clean(options.claimId);
    this.providerSourceAliasId = StringHelper.clean(options.providerSourceAliasId);
    this.provider = StringHelper.clean(options.provider);
    this.startDate = DateHelper.create(options.startDate, null); // NOTE: An invalid date breaks the grid selection
    this.endDate = DateHelper.create(options.endDate, null); // NOTE: An invalid date breaks the grid selection
    this.exempt = BooleanHelper.tryGet(options.exempt, false);
    this.nlpReviewResult = StringHelper.clean(options.nlpReviewResult);
    this.diagnoses = ArrayHelper.clean(options.diagnoses);
    this.isNlp = BooleanHelper.tryGet(options.isNlp, false);
    this.pageNumber = options.pageNumber;
    this.encounterId = options.encounterId;
    this.type = StringHelper.clean(options.type);
    this.encounterFound = StringHelper.clean(options.encounterFound);
    this.encounterFoundFromNLP = BooleanHelper.tryGet(options.encounterFoundFromNLP, false);
    this.chartPageNumber = options.chartPageNumber;
  }


  get encYn(): string {
    return this.convertBooleanToYn(this.enc);
  }

  get sigYn(): string {
    return this.convertBooleanToYn(this.sig);
  }

  get dosRange(): string {
    const startDateFormatted = DateHelper.isAvailable(this.startDate) ? DateHelper.format(this.startDate) : "00/00/0000";
    const endDateFormatted = DateHelper.isAvailable(this.endDate) ? DateHelper.format(this.endDate) : "00/00/0000";
    return `${startDateFormatted} - ${endDateFormatted}`;
  }

  get isAdminWithBotSource(): boolean {
    return this.isAdmin && (
      this.nlpReviewResult === RiskSystemResultType.MATCH ||
      this.nlpReviewResult.replace("_", "") === RiskSystemResultType.NO_MATCH.replace("_", "") ||
      this.nlpReviewResult === RiskSystemResultType.DOS_MATCH_ONLY
    );
  }

  get isAdminWithEveMatch(): boolean {
    return this.isAdmin && this.nlpReviewResult === RiskSystemResultType.MATCH;
  }

  get isBotSource(): boolean {
    return !this.isAdmin && this.nlpReviewResult.toUpperCase() === RiskSystemResultType.ADD.toUpperCase();
  }

  get isBotSourceAccepted(): boolean {
    return !this.isAdmin && this.nlpReviewResult === RiskSystemResultType.ACCEPTED;
  }

  get isManuallyAddedSource(): boolean {
    return !this.isAdmin && this.nlpReviewResult === "";
  }

  get diagnosisCount(): number {
    return this.diagnoses.length;
  }

  get encounterSource(): string {
    if (this.isAdminWithBotSource) {
      return "Admin";
    } else if (this.isBotSource) {
      return "Bot";
    } else if (this.isBotSourceAccepted) {
      return "Bot \u2713";
    } else if (this.isManuallyAddedSource) {
      return "Coder";
    } else {
      return "Admin";
    }
  }

  get encounterSourceV2(): string {
    if (this.isAdminWithBotSource) {
      return "Admin Encounter";
    } else if (this.isCoder) {
      return "Coder Encounter";
    } else if (this.isEve) {
      return "EVE Encounter";
    } else {
      return "Admin Encounter";
    }
  }

  get isCoder(): boolean {
    return !this.isAdmin && (this.nlpReviewResult === null || this.nlpReviewResult === "" || this.nlpReviewResult === "NOMATCH");
  }

  get isEve(): boolean {
    return this.isBotSource;
  }

  setProviderName(state: RiskState): void {
    const selectedProvider = state.providers.find(state.bySourceAliasId(this.providerSourceAliasId));
    this.provider = StringHelper.clean((selectedProvider || {} as any).providerName);
  }

  private convertBooleanToYn(value: boolean | null): string {
    if (value == null) {
      return "";
    }

    return value ? "Y" : "N";
  }
}
