<div class="address-detail-info-data">
  <h3 class="address-detail-info-data__header">ADDRESS INFORMATION</h3>

  <app-button classes="small secondary"
              class="address-detail-info-data__edit-button"
              text="EDIT"
              (onClick)="showEditAddressModal()">
  </app-button>

  <app-split-button text="CHANGE RETRIEVAL METHOD"
                    class="button"
                    classes="small secondary"
                    [model]="assignmentItems">
  </app-split-button>

  <app-button *ngIf="hasAssignButton"
              classes="small secondary"
              class="address-detail-info-data__assign-button"
              text="ASSIGN"
              (onClick)="openAssignToUserModal()">
  </app-button>

  <retrieval-address-detail-info-edit [isCreateVisible]="isAddressEditView"
                                      (formClose)="receiveAddressEditValue($event)">
  </retrieval-address-detail-info-edit>

  <retrieval-address-detail-info-assignment [assignmentType]="assignmentType" (retrievalTypeChange)="retrievalTypeChanged($event)"
                                            [(visible)]="isAddressAssignmentView">
  </retrieval-address-detail-info-assignment>

  <retrieval-address-detail-info-vendor-assignment [isAssignmentModalOpened]="isAssignmentModalOpened" [is3rdParty]="is3rdParty" [(visible)]="isAddressVendorAssignmentView" [(isVendorChanged)]="isVendorChanged" (retrievalTypeChange)="retrievalTypeChanged($event)">
  </retrieval-address-detail-info-vendor-assignment>

  <app-modal [(visible)]="isChangeVendorConfirmationModalVisible"
             header="{{changeVendorConfirmationModalHeader}}"
             (onCancel)="cancelAction()"
             class="vendor__confirmation-modal">
    <p class="text-center vendor-model-text">{{changeVendorConfirmationModalText}}</p>
    <footer class="text-center">
      <app-button class="confirm-btn" text="YES" (onClick)="changeVendor()"></app-button>
    </footer>
  </app-modal>
  <app-modal [(visible)]="isResetPINConfirmationModalVisible"
             header="Reset Pin"
             (onCancel)="cancelAction()"
             class="vendor__confirmation-modal">
    <p class="text-center vendor-model-text">You are about to permanently reset the Provider Gateway Pin # for this Address. Do you wish to continue?</p>
    <footer class="text-center">
      <app-button class="confirm-btn" text="YES" (onClick)="resetPIN()"></app-button>
    </footer>
  </app-modal>
  <div class="address-detail-info-data__tag-view-list">
    <app-view-tags [tagType]="tagType" [objectId]="objectId" [isGrid]="false">
    </app-view-tags>
  </div>

</div>

<app-info-template>
  <app-info-item-template>
    <app-info-item-container
        [infoItems]="addressInfo"
        [isSpecialHandling]="isSpecialHandling"
        (enableSpecialHandling)="enableSpecialHandling($event)">
    </app-info-item-container>
  </app-info-item-template>
  <app-info-item-template class="contact-content">
    <app-address-detail-info-contact (onSelected)="onContactSelected($event)"></app-address-detail-info-contact>
    <app-info-item-container [infoItems]="contactInfo"> </app-info-item-container>
    <app-button classes="small link"
                styleClass="resetpin-button"
                [ngClass]="getChangeVendorClass()"
                text="Reset PIN"
                (onClick)="showResetPINConfirmationModal()">
    </app-button>
  </app-info-item-template>
  <app-info-item-template>
    <app-info-item-container [infoItems]="retrievalInfo" [className]="vendorConfirmDate"></app-info-item-container>
    <app-button *ngIf="isVendorAssigned" classes="small secondary-action"
                [ngClass]="getChangeVendorClass()"
                text="CHANGE VENDOR"
                (onClick)="showChangeVendorConfirmationModal()">
    </app-button>
  </app-info-item-template>
</app-info-template>

<app-confirmation-modal [(visible)]="isRetrievalChangeModalVisible"
                        header="RETRIEVAL METHOD CHANGE"
                        (onYes)="onConfirmChange()"
                        (onCancel)="onCancel()"
                        acceptText="CONFIRM"
                        rejectText="CANCEL"
                        class="confirmation-modal">
  <p class="text-center">
    This retrieval method change will result in the removal of any previously
    associated vendor and invoice details. Please confirm if you'd like to continue.
  </p>
</app-confirmation-modal>

<app-modal *ngIf="isEnableSpecialHandling" class="outreach"
           [header]="specialHandlingHeading"
           [(visible)]="isSpecialHandlingModalVisible">
           {{specialHandlingDescription}}
            <footer class="text-center">
              <app-button text="CONTINUE"
                          (onClick)="confirmSpecialHandling()">
              </app-button>
            </footer>
</app-modal>

<app-modal class="outreach"
           [header]="specialHandlingHeading"
           [(visible)]="deleteSpecialHandling">
  {{specialHandlingDescription}}
  <footer class="text-center">
    <app-button text="CONTINUE"
                (onClick)="confirmSpecialHandling()">
    </app-button>
  </footer>
</app-modal>


<app-modal *ngIf="!isEnableSpecialHandling" class="outreach"
           header="Special Handling"
           [(visible)]="isSpecialHandlingModalVisible">
  <div>
    <div class="address-detail-sp-row-container">
      <div>
        <p class="address-detail-sp-field">Reason</p>
        <p class="address-detail-sp-value">{{addressDetailState.specialHandlingText}}</p>
      </div>
      <div>
        <img class="header-icon" src="assets/icons/edit_icon.svg" (click)="enableEditSpecialHandling()"/>
        <img class="header-icon" src="assets/icons/delete_icon.svg" (click)="enableDeleteSpecialHandling()"/>
      </div>
    </div>
    <br>
    <div *ngIf="addressDetailState.specialHandlingComment">
      <p class="address-detail-sp-field">Additional Notes</p>
      <p class="address-detail-sp-value" [title]="addressDetailState.specialHandlingComment">{{addressDetailState.specialHandlingComment.length > 50
        ?
        addressDetailState.specialHandlingComment.slice(0,50) + "..."
        :
        addressDetailState.specialHandlingComment}}</p>
    </div>
    <br>
    <div>
      <p class="address-detail-sp-value">Last updated by {{addressDetailState.specialHandlingUpdateUserName}}</p>
      <p class="address-detail-sp-value">{{formatSpecialHandlingDate()}}</p>
    </div>
  </div>
</app-modal>

<app-modal class="outreach"
           header="Enable Special Handling"
           [(visible)]="isSpecialHandlingReasonVisible">
           Please select reason for Special Handling
          <br><br>
            <form-factory class="outreach__form"
                [formGroup]="formGroup"
                [model]="specialHandlingReason"
                (onChange)="onReasonChange($event)">
            </form-factory>

            <form-factory class="outreach__form" *ngIf="isSpecialHandlingNote"
                [formGroup]="formGroup"
                [model]="specialHandlingNote">
            </form-factory>
            <footer class="text-center">
              <app-button text="CONTINUE"
                          (onClick)="confirmSpecialHandlingReason()">
              </app-button>
            </footer>

</app-modal>

<app-modal class="outreach"
           header="Edit Special Handling"
           [(visible)]="isEditSpecialHandlingReasonVisible">
  Please select reason for Special Handling
  <div *ngIf="!isEditSHReasonVisible">
    <br><br>
    <div class="address-detail-sp-row-container">
      <div>
        <p class="address-detail-sp-field">Reason</p>
        <p class="address-detail-sp-value">{{addressDetailState.specialHandlingText}}</p>
      </div>
      <div>
        <img class="header-icon" src="assets/icons/edit_icon.svg" (click)="enableSHReason()"/>
      </div>
    </div>
  </div>
  <br><br>
  <form-factory *ngIf="isEditSHReasonVisible" class="outreach__form"
                [formGroup]="formGroup"
                [model]="specialHandlingReason"
                (onChange)="onReasonChange($event)"
                [formControl]="specialHandlingReasonControl">
  </form-factory>

  <form-factory class="outreach__form" *ngIf="isSpecialHandlingNote"
                [formGroup]="formGroup"
                [model]="specialHandlingNote">
  </form-factory>
  <footer class="text-center">
    <app-button text="CONTINUE"
                (onClick)="confirmSpecialHandlingReason()">
    </app-button>
  </footer>

</app-modal>

<app-address-assignment-modal [(visible)]="isAssignToUserModalVisible"
                              (onHide)="closeModal()"
                              [(addresses)]="selectedAddress"
                              ></app-address-assignment-modal>
