import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-retrieval-summary",
    templateUrl: "./retrieval-summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalSummaryComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.RETRIEVAL_SUMMARY;

    constructor() {
        this.filters = ["Projects", "Hcc", "Products", "Address", "Npi", "LOB", "RetrievalOwner", "ExpectedRetrieval", "ProjectStatus", "VendorName", "SpecialHandling", "Measure", "ChaseTags", "VendorInvoiceType"];
    }

}
