import * as moment from "moment";
import { DateFormats, Inclusions, UnitOfTime, dateTypes } from "./helper-types";

export class DateHelper {
  static isDate(value: dateTypes): boolean {
    return moment.isDate(value);
  }

  static isAfter(value: dateTypes, earlier: dateTypes, inclusion = false, granularity: UnitOfTime = "day"): boolean {
    this.throwIsAvailableError(earlier, "earlier");

    if (!this.isAvailable(value)) {
      return false;
    }

    const momentValue = moment(this.create(value));
    const earlierDate = this.create(earlier);
    const result = inclusion ? momentValue.isSameOrAfter(earlierDate, granularity) : momentValue.isAfter(earlierDate, granularity);
    return result;
  }

  static isBefore(value: dateTypes, later: dateTypes, inclusion = false, granularity: UnitOfTime = "day"): boolean {
    this.throwIsAvailableError(later, "earlier");

    if (!this.isAvailable(value)) {
      return false;
    }

    const momentValue = moment(this.create(value));
    const laterDate = this.create(later);
    const result = inclusion ? momentValue.isSameOrBefore(laterDate, granularity) : momentValue.isBefore(laterDate, granularity);
    return result;
  }

  static isBetween(value: dateTypes, earlier: dateTypes, later: dateTypes, inclusion: Inclusions = "()", granularity: UnitOfTime = "day"): boolean {
    const isEarlierIncluded = inclusion.charAt(0) === "[";
    const isAfter = this.isAfter(value, earlier, isEarlierIncluded, granularity);

    const isLaterIncluded = inclusion.charAt(1) === "]";
    const isBefore = this.isBefore(value, later, isLaterIncluded, granularity);

    return isAfter && isBefore;
  }

  static create(value: dateTypes, defaultValue = new Date("")): Date {
    if (value == null || (typeof value !== "string" && !this.isDate(value))) {
      return defaultValue; // invalid date
    }

    return moment(value, [
      DateFormats.SHORT_INPUT,
      DateFormats.DEFAULT,
      "M/D/YYYY",
      "M-D-YYYY",
      "YYYY-M-D",
      "YYYY/M/D",
      "YYYY/MM/DD",
      "ddd, DD MMM YYYY",
      moment.ISO_8601,
      moment.RFC_2822,
      moment.defaultFormat,
      moment.defaultFormatUtc,
      DateFormats.RFC_DATE,
    ],            false).toDate();
  }

  static removeTime(value: dateTypes): Date {
    if (!this.isAvailable(value)) {
      return new Date(""); // invalid date
    }

    const date = typeof value === "string" ? this.create(value) : value;
    const utcDateString = date.toUTCString().substring(0, DateFormats.RFC_DATE.length);
    return this.create(utcDateString);
  }

  static format(value: dateTypes, dateFormat: string | DateFormats = DateFormats.DEFAULT): string {
    if (!this.isAvailable(value)) {
      return "";
    }

    const date = this.create(value);
    return moment(date).format(dateFormat);
  }

  static formatToLocalTime(value: dateTypes): string {
    if (!this.isAvailable(value)) {
      return "";
    }

    const gmtDateTime = moment.utc(value, "YYYY-MM-DD HH:mm:ss");
    const local = gmtDateTime.local().format("MM-DD-YYYY HH:mm A");

    return local;
  }

  static formatToUtcTime(value: dateTypes): string {
    if (!this.isAvailable(value)) {
      return "";
    }
    const utcTimeZone = moment.utc(new Date(value)).format("MM/DD/YYYY");
    return utcTimeZone;
  }

  static isAvailable(value: dateTypes): boolean {
    const date = this.create(value);
    return moment(date).isValid();
  }

  static throwIsAvailableError(value: dateTypes, propertyName: string): void {
    if (!this.isAvailable(value)) {
      throw new Error(`The property '${propertyName}' must be a valid date.`);
    }
  }

  static ageInYears(userDob: dateTypes, ageAtThisDate: dateTypes): number {
    this.throwIsAvailableError(userDob, "userDob");
    this.throwIsAvailableError(ageAtThisDate, "ageAtThisDate");

    const date1 = moment(this.create(userDob));
    const date2 = moment(this.create(ageAtThisDate));
    const userAge = date2.diff(date1, "years");
    return userAge;
  }

  static convert24to12(time: string): string {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) {
      hours = hours + 12;
    }
    if (AMPM === "AM" && hours === 12) {
      hours = hours - 12;
    }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
      sHours = `0${sHours}`;
    }
    if (minutes < 10) {
      sMinutes = `0${sMinutes}`;
    }
    return `${sHours}:${sMinutes}`;
  }
}
