import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ContactRecord } from "../../psr/address-detail/address-detail-contact-record";
@Injectable({
  providedIn: "root",
})
export class CallNotesService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }
  saveCallNotes(contactRecord: ContactRecord, actionSource: string) {
    const url = `${this.baseApiUrl}retrieval/saveContactRecord?actionSource=${actionSource}`;
    return this.http.post(url, contactRecord);
  }
}
