import { DocumentTypeName } from "../../modules/retrieval/retreival-document-review/document-type.enum";
import { DocumentUploadRequestObjectType } from "./document-upload-request-objecttype.enum";

export class DocumentUploadRequest {

  constructor(
    readonly fileName: string,
    readonly documentTypeId: DocumentTypeName,
    readonly objectType: DocumentUploadRequestObjectType,
    readonly objectId: number,
    readonly projectId: number,
    readonly retreivalTypeId: number,
    readonly source: string,
    readonly formData: FormData
 ) {
    this.fileName = fileName;
    this.documentTypeId = documentTypeId;
    this.objectType = objectType;
    this.objectId = objectId;
    this.projectId = projectId;
    this.retreivalTypeId = retreivalTypeId;
    this.source = source;
    this.formData = formData;
  }

}
