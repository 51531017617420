import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { GridView } from "./grid-view.model";

@Injectable({ providedIn: "root" })
export class GridViewService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }


  update(attributeId: number, gridView: GridView): Observable<GridView> {
    const url = `${this.baseApiUrl}gridview/update?attributeId=${attributeId}`;
    return this.http.post(url, gridView).pipe(
      map(this.automapper.curry("GridView", "GridView"))
    );
  }

  delete(gridViewId: number): Observable<null> {
    const url = `${this.baseApiUrl}gridview/delete?gridViewId=${gridViewId}`;
    return this.http.delete(url) as Observable<null>;
  }
}
