import { TemplateRef } from "@angular/core";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridPipeName } from "../grid-pipe.enum";

/* tslint:disable:max-classes-per-file */
// This is essentially one class.
abstract class AGridColumnDefinition {
  field?: string;
  title?: string;
  showTitle?: boolean;
  header: string;
  show?: boolean;
  customView?: boolean;
  routeUrl?: string;
  isSortableColumn?: boolean;
  width?: string;
  pipeName?: GridPipeName;
  format?: string;
  timeZone?: string;
  alignment?: string;
  styles?: (value: string | number | boolean, rowData: any) => object;
  template?: TemplateRef<any>;


  constructor(options: AGridColumnDefinition) {
    this.field = StringHelper.clean(options.field);
    this.title = StringHelper.clean(options.title);
    this.header = StringHelper.clean(options.header);
    this.show = BooleanHelper.tryGet(options.show, true);
    this.showTitle = BooleanHelper.tryGet(options.showTitle, true);
    this.customView = BooleanHelper.tryGet(options.customView, true);
    this.routeUrl = StringHelper.clean(options.routeUrl);
    this.isSortableColumn = BooleanHelper.tryGet(options.isSortableColumn, true);
    this.width = StringHelper.clean(options.width);
    this.pipeName = options.pipeName || null;
    this.format = StringHelper.clean(options.format);
    this.timeZone = StringHelper.clean(options.timeZone, "UTC");

    const tempAlignment = StringHelper.clean(options.alignment);
    this.alignment = ["center", "left", "right"].includes(tempAlignment) ? tempAlignment : "left";
    this.styles = options.styles || (() => ({}));
    this.template = options.template;
  }
}

export class GridColumnDefinition extends AGridColumnDefinition {
  constructor(options: AGridColumnDefinition) {
    super(options);
  }

  get columnField(): string {
    return this.isSortableColumn ? this.field : "";
  }

  get hasRouteUrl(): boolean {
    return StringHelper.isAvailable(this.routeUrl);
  }

  get hasPipe(): boolean {
    return this.pipeName != null;
  }

  get hasTitle(): boolean {
    return StringHelper.isAvailable(this.title);
  }

  get hasTemplate(): boolean {
    return this.template != null;
  }
}
/* tslint:enable:max-classes-per-file */
