import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { List } from "immutable";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AddressTimelineStateService } from "../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../address-timeline/address-timeline.service";
import { RetrievalTimelineItem } from "../../retrieval-timeline-item.model";
import { AddressDetailState } from "../address-detail-state.model";
import { AddressDetailStateService } from "../address-detail-state.service";
import { RetrievalAddressDetailCommentsService } from "./address-detail-comments.service";

@Component({
  selector: "app-address-detail-comments-new",
  templateUrl: "./address-detail-comments.component.html",
  styleUrls: ["./address-detail-comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailCommentsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  commentItems = List<CommentItem>();
  commentText: string;
  timelineItems = List<RetrievalTimelineItem>();

  constructor(
    private retrievalAddressDetailCommentsService: RetrievalAddressDetailCommentsService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;
        if (this.addressDetailState.hasMasterDocumentSourceId) {
          this.getComments();
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get enableAddCommentBtn(): boolean {
    return StringHelper.isAvailable(this.commentText);
  }

  getComments() {
    this.retrievalAddressDetailCommentsService
      .getComments(this.addressDetailState.masterDocumentSourceId)
      .subscribe(items => this.commentItems = this.assignAndNotify(items));
  }

  saveComments() {
    if (StringHelper.isAvailable(this.commentText)) {
      this.retrievalAddressDetailCommentsService.saveComment(this.addressDetailState.masterDocumentSourceId, this.commentText).subscribe(
        next => {
          this.getComments();
          this.commentText = null;
          this.fetchTimelineItmes(this.addressDetailState.masterDocumentSourceId);
          this.changeDetector.markForCheck();
        },
        err => {
          this.messagingService.showToast("Failed to save comments, please try again.", SeverityType.ERROR);
          this.commentText = null;
          this.changeDetector.markForCheck();
        }
      );
    } else {
      this.messagingService.showToast("Comment Text cannot be blank, please try again.", SeverityType.ERROR);
    }
  }

  private assignAndNotify<T>(data: T[]): List<T> {
    const dataList = List(data);
    this.changeDetector.markForCheck();

    return dataList;
  }

  private fetchTimelineItmes(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }
}
