import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-chase-status-underlying",
  templateUrl: "./chase-status-underlying.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseStatusUnderlyingComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CHASE_STATUS_UNDERLYING;

    constructor() {
        this.filters = ["Projects", "Address", "MemberId", "MemberFirstName", "MemberLastName",
                        "MemberDob", "MemberKey", "ChaseId"];
    }

}
