<!-- https://material.angular.io/cdk/scrolling/api#CdkFixedSizeVirtualScroll   -->
<cdk-virtual-scroll-viewport #thumbnailExpandedViewport class="thumbnail__viewport" [ngClass]="{ 'max': isMaximized }"
[itemSize]="itemSizeHeight" [minBufferPx]="minBuffer" [maxBufferPx]="maxBuffer">
  <div class="thumbnail__container"  *cdkVirtualFor="let rowOfThumbnails of allThumbnails;">
    <div class="thumbnail__row" *ngFor="let thumbnail of rowOfThumbnails; trackBy: trackByIndex; let i = index;">
      <ng-container *ngIf="hasPageAndSource(thumbnail)">
        <div *ngIf="isDocumentThumbnail(thumbnail);" (click)="thumbnailClicked(thumbnail)" class="thumbnail__cell"
          [ngClass]="{'thumbnail__cell--overlay': isThumbnailSelected(thumbnail) }">
          <ng-container *ngIf="hasThumbnailImageUrl(thumbnail); else placeholderThumbnail">
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <img [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__image--style" [src]="getThumbnailSource(thumbnail)"
              alt="Document Preview Not Available" />

            <div *ngIf="showThumbnailLabels">
              <div *ngIf="encounterTabSelected" class="thumbnail-label__container">
                <ng-container *ngIf="diagnosisFilterSelected">
                  <div class="content--diagnosis"
                    *ngIf="isDiagnosisLabelAvailble(thumbnail.evidence.diagnoses, diagnosisFilter)">
                    <div class="content__label">
                      <div><span class="content__description-dx"
                          title="{{getDiagnosisData(thumbnail.evidence.diagnoses)}}">{{getDiagnosisData(thumbnail.evidence.diagnoses)}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="content--provider" *ngIf="isDiagnosisLabelAvailble(thumbnail.evidence.signs,signsFilter)">
                    <div class="content__label">
                      <div><span class="content__description-dx"
                          title="{{getDiagnosisData(thumbnail.evidence.signs)}}">{{getDiagnosisData(thumbnail.evidence.signs)}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="content--symptoms"
                    *ngIf="isDiagnosisLabelAvailble(thumbnail.evidence.symptoms, symptomsFilter)">
                    <div class="content__label">
                      <div><span class="content__description-dx"
                          title="{{getDiagnosisData(thumbnail.evidence.symptoms)}}">{{getDiagnosisData(thumbnail.evidence.symptoms)}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="content--medications"
                    *ngIf="isDiagnosisLabelAvailble(thumbnail.evidence.medications, medicationsFilter)">
                    <div class="content__label">
                      <div><span class="content__description-dx"
                          title="{{getDiagnosisData(thumbnail.evidence.medications)}}">{{getDiagnosisData(thumbnail.evidence.medications)}}</span>
                      </div>
                    </div>
                  </div>

                </ng-container>
                <ng-container *ngIf="!diagnosisFilterSelected">
                  <div class="content--diagnosis"
                    *ngIf="isEvidenceLabelAvailble(thumbnail.evidence.dosEvidences, dosFilter)">
                    <div class="content__label">
                      <div><span class="content__description"
                          title="{{getEvidencedata(thumbnail.evidence.dosEvidences, 'text')}}"><b>Dos:</b>
                          {{getEvidencedata(thumbnail.evidence.dosEvidences, 'text')}}</span></div>
                      <div><span class="content__description"
                          title="{{getEvidencedata(thumbnail.evidence.dosEvidences, 'chartlocation')}}"><b>Chart
                            Location:</b> {{getEvidencedata(thumbnail.evidence.dosEvidences, 'chartlocation')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="content--provider"
                    *ngIf="isEvidenceLabelAvailble(thumbnail.evidence.providerEvidences, providerFilter)">
                    <div class="content__label">
                      <div><span class="content__description"
                          title="{{getEvidencedata(thumbnail.evidence.providerEvidences, 'text')}}"><b>Provider:</b>
                          {{getEvidencedata(thumbnail.evidence.providerEvidences, 'text')}}</span></div>
                      <div><span class="content__description"
                          title="{{getEvidencedata(thumbnail.evidence.providerEvidences, 'chartlocation')}}"><b>Chart
                            Location:</b> {{getEvidencedata(thumbnail.evidence.providerEvidences,
                          'chartlocation')}}</span></div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="thumbnail-label__container" *ngIf="memberTabSelected">
                <div class="content--diagnosis"
                  *ngIf="isMemberLabelAvailble(thumbnail.evidence.memberNameEvidences, memberNameFilter)">
                  <div class="content__label">
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberNameEvidences, 'text')}}"><b>Member Name:</b>
                        {{getEvidencedata(thumbnail.evidence.memberNameEvidences, 'text')}}</span></div>
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberNameEvidences,'chartlocation')}}"><b>Chart
                          Location:</b>
                        {{getEvidencedata(thumbnail.evidence.memberNameEvidences,'chartlocation')}}</span>
                    </div>
                  </div>
                </div>
                <div class="content--provider"
                  *ngIf="isMemberLabelAvailble(thumbnail.evidence.memberDOBEvidences , dobFilter)">
                  <div class="content__label">
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberDOBEvidences, 'text')}}"><b>DOB:</b>
                        {{getEvidencedata(thumbnail.evidence.memberDOBEvidences, 'text')}}</span></div>
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberDOBEvidences, 'chartlocation')}}"><b>Chart
                          Location:</b> {{getEvidencedata(thumbnail.evidence.memberDOBEvidences,
                        'chartlocation')}}</span>
                    </div>
                  </div>
                </div>
                <div class="content--medications"
                  *ngIf="isMemberLabelAvailble(thumbnail.evidence.memberGenderEvidences , genderFilter)">
                  <div class="content__label">
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberGenderEvidences, 'text')}}"><b>Gender:</b>
                        {{getEvidencedata(thumbnail.evidence.memberGenderEvidences, 'text')}}</span></div>
                    <div><span class="content__description"
                        title="{{getEvidencedata(thumbnail.evidence.memberGenderEvidences, 'chartlocation')}}"><b>Chart
                          Location:</b> {{getEvidencedata(thumbnail.evidence.memberGenderEvidences,
                        'chartlocation')}}</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">{{thumbnail.pageNumber}}</div>
          </ng-container>
          <ng-template #placeholderThumbnail>
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <div [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__placeholder  thumbnail__non-document">
              <div class="thumbnail__non-document--text">Document
                Preview
                Not Available</div>
            </div>
            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">
              {{thumbnail.pageNumber}}</div>
          </ng-template>
          <div class="overlay"></div>
        </div>
      </ng-container>
    </div>
  </div>
</cdk-virtual-scroll-viewport>