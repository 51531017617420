import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { BooleanHelper } from "../../../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";


export const mapMeasures = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Measures")
    .forMember("measureId", o => o.measureId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("measureName", o => o.measureName);
  };

export class Measures {
    measureId?: number;
    measureCode?: string;
    measureName?: string;
    isChecked?: boolean;

    constructor(options: {
    measureId?: number;
    measureCode?: string;
    measureName?: string;
    isChecked?: boolean;
  }) {

    this.measureId = options.measureId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.measureName = StringHelper.clean(options.measureName);
    this.isChecked = BooleanHelper.tryGet(options.isChecked, false);
  }
}
