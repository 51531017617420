import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-risk-nlp-icd-drilldown",
  templateUrl: "./risk-nlp-icd-drilldown.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskNLPICDDrilldownComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.RISK_NLP_ICD_DRILLDOWN;

  constructor() {
    this.filters = ["Projects", "Hcc", "Address", "DOS"];
  }

}
