import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { LogoDataSharingService } from "./logo-data-sharing.service";
import { LogoService } from "./logo.service";
import { ServiceOrgLogoResponseModel } from "./service-org-logo-response.model";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit {
  @Input() orientation = "right";
  logo: SafeResourceUrl;
  hasLogo = true;

  constructor(
    private router: Router,
    private readonly logoService: LogoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private logoDataSharingService: LogoDataSharingService
    ) { }

  ngOnInit() {
    this.getServiceOrgLogoUrl();
    const isValidOrientation = ["right", "left", "top", "bottom"].includes(this.orientation);
    if (!isValidOrientation) {
      throw new Error(`The orientation cannot be ${this.orientation}`);
    }
    this.logoDataSharingService.getLogoUrl().subscribe(response => {
      if (response) {
        this.logo = response.organizationLogo;
        this.changeDetector.markForCheck();
      }
    });
  }

  navigateToLoginLanding(): void {
    this.router.navigateByUrl(`user/loginLanding`);
  }

  getServiceOrgLogoUrl(): void {
    this.logoService
      .getServiceOrgLogoUrl()
      .subscribe((response: ServiceOrgLogoResponseModel) => {
        if (response.organizationLogo && StringHelper.isAvailable(response.fileName)) {
          this.logo = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${response.organizationLogo}`);
          response.organizationLogo = this.logo;
          this.logoDataSharingService.setLogoUrl(response);
        } else {
          this.hasLogo = false;
          this.changeDetector.markForCheck();
        }
      });
    }
}
