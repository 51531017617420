<div class="address-bulk-container">
  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid " *ngIf= "isShowAssignUser">
      <div class="ui-g-3 ui-md-3 ui-lg-3">
        <div class="label-block">
          <form-factory [formGroup]="formGroup" [model]="assignToUsers"></form-factory>
        </div>
      </div>
    </div>
      <div class="ui-g ui-fluid ">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <div class="label-block">
            <form-factory [formGroup]="formGroup" [model]="addressNotes"></form-factory>
          </div>
          </div>
        </div>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <app-button text="CONTINUE TO VALIDATION" (onClick)="validateToShowAddressGrid()"></app-button>
        </div>
      </div>
</form>

  <div class="ui-g ui-fluid" *ngIf="isShowValidateGridData">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-basic-grid [configuration]="serverGridConfiguration"
                       [data]="data">
      </app-basic-grid>
    </div>
    <div class="ui-g ui-fluid"><app-button (onClick)="finalBulkUpdate()" text="FINISH BULK UPDATE" [disabled]="isFinalBulkUpdate"></app-button></div>
  </div>

</div>
