import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { MeasureDocuments } from "./measure-documents.model";
import { ProjectAttribute } from "./project-attribute.model";

export class ProviderPacket {
  projectId: number;
  projectTypeId?: number;
  measureCode: string;
  template: string;
  projectConfigurationAttributes: ProjectAttribute[];
  measureDocumentsList: MeasureDocuments[];

  constructor(options: {
    projectId: number;
    projectTypeId?: number;
    measureCode: string;
    template: string;
    projectConfigurationAttributes: ProjectAttribute[];
    measureDocumentsList: MeasureDocuments[];
  }) {
    StringHelper.throwIsAvailableError(options.measureCode, "measureCode");

    this.projectId = options.projectId;
    this.projectTypeId = options.projectTypeId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.template = StringHelper.clean(options.template);
    this.projectConfigurationAttributes = options.projectConfigurationAttributes || [];
    this.measureDocumentsList = options.measureDocumentsList || [];
  }

  get hasProjectConfigurationAttributes(): boolean {
    return Array.isArray(this.projectConfigurationAttributes) && this.projectConfigurationAttributes.length > 0;
  }

  get hasMeasureDocumentsList(): boolean {
    return Array.isArray(this.measureDocumentsList) && this.measureDocumentsList.length > 0;
  }
}
