import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DocumentHoverHighlightsRequest } from "./document-hover-highlights-request.model";

@Injectable({ providedIn: "root" })
export class DocumentHoverHighlightsService {
    constructor(
        @Inject(BASE_API_URL) private readonly baseApiUrl: string,
        private readonly http: HttpClient) { }

    getHighlightText(request: DocumentHoverHighlightsRequest): Observable<string> {
        const url = `${this.baseApiUrl}chasedocument/ocr/words`;

        return this.http.post(url, request).pipe(
            map((response: string) => response)
        );
    }
}
