import { DateHelper } from "../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class CommentItem {
  readonly commentText: string;
  readonly createdByUserName: string;
  readonly createdDate: Date;
  readonly commentType: string;

  get hasType(): boolean {
    return StringHelper.isAvailable(this.commentType);
  }

  constructor(options: {
    commentText: string;
    createdByUserName: string;
    createdDate: string;
    commentType?: string;
  }) {
    StringHelper.throwIsAvailableError(options.createdDate, "createdDate");

    this.commentText = StringHelper.clean(options.commentText);
    this.createdByUserName = StringHelper.clean(options.createdByUserName);
    this.createdDate = DateHelper.create(options.createdDate);
    this.commentType = StringHelper.clean(options.commentType);
  }

  get hasBody(): boolean {
    return StringHelper.isAvailable(this.commentText);
  }

}

