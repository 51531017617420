<div class="chase-search-header">
  <h2>Search Chase </h2>
  <a class="close-button" (click)="closeForm('')">
    <i class="fa fa-times"></i>
  </a>
</div>
<div class="chase-search-container">
  <form [formGroup]="form">
    <div class="ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12 padding-top-0 padding-bottom-0">
        <div class="ui-g-3">
          <form-factory [formGroup]="form" [model]="addressInput"></form-factory>
        </div>
        <div class="ui-g-3">
          <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
        </div>
        <div class="ui-g-3">
          <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
        </div>
        <div class="ui-g-3">
          <form-factory [formGroup]="form" [model]="memberDateOfBirthInput"></form-factory>
        </div>

      </div>
    </div>

    <div class="ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12 padding-top-0">
        <div class="ui-g-3">
          <form-factory [formGroup]="form" [model]="providerInput"></form-factory>
        </div>
        <div class="ui-g-9 text-right padding-bottom-0 search-button">
          <app-button class="button margin-right-10"
                      (onClick)="search()"
                      text="Search"></app-button>

          <app-button class="button"
                      (onClick)="clearControls()"
                      type="button"
                      text="Clear"></app-button>
        </div>
      </div>
    </div>
  </form>

  <div class="ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12 padding-top-0">
      <h3 class="margin-top-0">RESULTS</h3>
      <div class="filter-grid">
      <app-basic-grid [configuration]="gridConfigurationModel"
                       [data]="chaseData"
                       [(selection)]="selectedChase"
                       [views]="views"
                       [request]="request"
                       [requestUrl]="requestUrl"
                       [refreshViews]="refreshViews"
                       [filterTemplate]="chaseFilterTemplate"
                       exportDataFromUrl=true
                       (removeFilter)="onRemoveFilter($event)"
                       [urlOptions]= "searchRequest">
        <ng-template #chaseIdColumn let-rowData>
          <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
        </ng-template>
        <ng-template #chaseFilterTemplate let-form >
          <form [formGroup]="Filterform" class="ui-modal-width" (click)="loadFilterInput()">
            <app-tab-menu orientation="left">

              <app-tab-panel header="Chase ID">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="chaseID"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__halves" header="Projects">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="projectName"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__fourths" header="Measures">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="measureCode"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__halves" header="Status">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="reportingStatusName"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__fourths" header="Pend Codes">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="pendCode"></form-factory>
                </div>
              </app-tab-panel>

              <app-tab-panel class="checkbox-group__halves" header="Pend Status">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="pendStatus"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="sampleComplianceCode"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel class="checkbox-group__fourths" header="Chase Compliance">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="chaseComplianceCode"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel header="Member">
                <div class="ui-g-12 ui-md-12 ui-lg-12">

                  <form-factory [formGroup]="Filterform" [model]="memberDateOfBirth"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel header="Address">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="masterDocumentSourceId"></form-factory>
                </div>
              </app-tab-panel>
              <app-tab-panel header="Providers">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="Filterform" [model]="serviceProviders"></form-factory>
                </div>
              </app-tab-panel>
            </app-tab-menu>
          </form>
        </ng-template>

      </app-basic-grid>
        </div>
    </div>
  </div>

  <footer class="text-right">
    <app-button class="usebutton"
                (onClick)="selectAndClose()"
                text="USE THIS CHASE"></app-button>
  </footer>
</div>
