import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Panel } from "primeng/panel";

@Component({
  selector: "app-collapsible-panel",
  templateUrl: "./collapsible-panel.component.html",
  styleUrls: ["./collapsible-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsiblePanelComponent implements OnInit {
  @ViewChild("primePanel", { static: true }) primePanel: Panel;
  @Input() startCollapsed = false;

  ngOnInit() {
    if (this.startCollapsed) {
      this.primePanel.collapse(new MouseEvent("click"));
    }
  }

}
