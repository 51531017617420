import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { Contact } from "../contact.model";
import { ContactMethodType } from "../cover-letter-template/contact-method-type.enum";

export const mapAddressDetail = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "AddressDetail")
    .forMember("accessAdminContact", o => o.accessAdminContact)
    .forMember("accessAdminPhone", o => o.accessAdminPhone)
    .forMember("accessData", o => o.accessData)
    .forMember("accessEditNotes", o => o.accessEditNotes)
    .forMember("accessEmrSystem", o => o.accessEmrSystem)
    .forMember("accessITContact", o => o.accessITContact)
    .forMember("accessITPhone", o => o.accessITPhone)
    .forMember("address1", o => o.address1)
    .forMember("address2", o => o.address2)
    .forMember("allProvidersVerified", o => o.allProvidersVerified)
    .forMember("hasGapProject", o => o.hasGapProject)
    .forMember("assignedTo", o => o.assignedTo)
    .forMember("city", o => o.city)
    .forMember("contactData", o => o.contactData)
    .forMember("contactEmail", o => o.contactEmail)
    .forMember("contactFax", o => o.contactFax)
    .forMember("contactName", o => o.contactName)
    .forMember("contactPhone", o => o.contactPhone)
    .forMember("contacts", o => o.contacts)
    .forMember("coRetrievalOwner", o => o.coRetrievalOwner)
    .forMember("credentialStatus", o => o.accessCredentialStatus)
    .forMember("documentSourceTypeName", o => o.documentSourceTypeName)
    .forMember("documentSourceTypeId", o => o.documentSourceTypeId)
    .forMember("expirationDate", o => o.accessExpirationDate)
    .forMember("groupName", o => o.groupName)
    .forMember("hasPrimaryContact", o => o.hasPrimaryContact)
    .forMember("invoiceSettingData", o => o.invoiceSettingData)
    .forMember("invoiceType", o => o.invoiceType)
    .forMember("isContactVerified", o => o.isContactVerified)
    .forMember("isSpecialHandling", o => o.isSpecialHandling)
    .forMember("lastContactedDate", o => o.lastContactedDate)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("masterDocumentSourceStatus", o => o.masterDocumentSourceStatus)
    .forMember("masterDocumentSourceVerifiedDate", o => o.masterDocumentSourceVerifiedDate)
    .forMember("nextCallDateTime", o => o.nextCallDateTime)
    .forMember("openChaseCount", o => o.openChaseCount)
    .forMember("organizationId", o => o.organizationId)
    .forMember("password", o => o.accessUserPassword)
    .forMember("postalCode", o => o.postalCode)
    .forMember("retrievalTypeId", o => o.retrievalTypeId)
    .forMember("specialHandlingId", o => o.specialHandlingId)
    .forMember("specialHandlingText", o => o.specialHandlingText)
    .forMember("specialHandlingComment", o => o.specialHandlingComment)
    .forMember("specialHandlingUpdateDate", o => o.specialHandlingUpdateDate)
    .forMember("specialHandlingUpdateUserName", o => o.specialHandlingUpdateUserName)
    .forMember("state", o => o.state)
    .forMember("totalCalls", o => o.totalCalls)
    .forMember("totalChaseCount", o => o.totalChaseCount)
    .forMember("totalUniqueDaysCalled", o => o.totalUniqueDaysCalled)
    .forMember("totalEmails", o => o.totalEmails)
    .forMember("totalFaxes", o => o.totalFaxes)
    .forMember("totalNotes", o => o.totalNotes)
    .forMember("twoFactorAuthorization", o => o.accessTwoFactorAuthorization)
    .forMember("url", o => o.accessUrl)
    .forMember("uploadAccessCode", o => o.uploadAccessCode)
    .forMember("userName", o => o.accessUserName)
    .forMember("vendorConfirmDate", o => o.vendorConfirmDate)
    .forMember("vendorId", o => o.vendorId)
    .forMember("vendorInvoicing", o => o.vendorInvoicing)
    .forMember("vendorName", o => o.vendorName)
    .forMember("weeklyContactMethodTypeId", o => o.weeklyContactMethodTypeId)
    .forMember("weeklyContactMethodTypeName", o => o.weeklyContactMethodTypeName);
};

export class AddressDetail {
  accessAdminContact?: string;
  accessAdminPhone?: string;
  accessData?: string;
  accessEditNotes?: string;
  accessEmrSystem?: string;
  accessITContact?: string;
  accessITPhone?: string;
  address1?: string;
  address2?: string;
  allProvidersVerified: boolean;
  hasGapProject: boolean;
  assignedTo?: string;
  city?: string;
  contactData?: string;
  contactEmail?: string;
  contactFax?: string;
  contactName?: string;
  contactPhone?: string;
  contacts?: Contact[];
  coRetrievalOwner?: string;
  credentialStatus?: string;
  documentSourceTypeName?: string;
  documentSourceTypeId?: number;
  expirationDate?: string;
  groupName?: string;
  invoiceSettingData?: string;
  invoiceType?: string;
  isContactVerified?: boolean;
  isSpecialHandling?: boolean;
  lastContactedDate?: Date;
  masterDocumentSourceId?: number;
  masterDocumentSourceStatus?: string;
  masterDocumentSourceVerifiedDate?: Date;
  nextCallDateTime?: string;
  organizationId?: number;
  password?: string;
  postalCode?: string;
  retrievalTypeId?: number;
  specialHandlingId?: number;
  specialHandlingText?: string;
  specialHandlingComment?: string;
  specialHandlingUpdateDate?: Date;
  specialHandlingUpdateUserName?: string;
  state?: string;
  totalCalls?: number;
  totalUniqueDaysCalled?: number;
  totalEmails?: number;
  totalFaxes?: number;
  totalNotes?: number;
  twoFactorAuthorization?: string;
  uploadAccessCode?: string;
  url?: string;
  userName?: string;
  vendorConfirmDate?: string;
  vendorId?: number;
  vendorInvoicing?: string;
  vendorName?: string;
  weeklyContactMethodTypeId?: ContactMethodType;
  weeklyContactMethodTypeName?: string;
  hasPrimaryContact: boolean;
  totalChaseCount?: number;
  openChaseCount?: number;
  constructor(options: Partial<AddressDetail> = {}) {
    this.accessAdminContact = StringHelper.clean(options.accessAdminContact);
    this.accessAdminPhone = StringHelper.clean(options.accessAdminPhone);
    this.accessData = StringHelper.clean(options.accessData);
    this.accessEditNotes = StringHelper.clean(options.accessEditNotes);
    this.accessEmrSystem = StringHelper.clean(options.accessEmrSystem);
    this.accessITContact = StringHelper.clean(options.accessITContact);
    this.accessITPhone = StringHelper.clean(options.accessITPhone);
    this.accessData = StringHelper.clean(options.accessData);
    this.address1 = StringHelper.clean(options.address1);
    this.address2 = StringHelper.clean(options.address2);
    this.allProvidersVerified = options.allProvidersVerified;
    this.hasGapProject = options.hasGapProject;
    this.assignedTo = StringHelper.clean(options.assignedTo);
    this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
    this.city = StringHelper.clean(options.city);
    this.contactData = StringHelper.clean(options.contactData);
    this.contactEmail = StringHelper.clean(options.contactEmail);
    this.contactFax = StringHelper.clean(options.contactFax);
    this.contactName = StringHelper.clean(options.contactName);
    this.contactPhone = StringHelper.clean(options.contactPhone);
    this.contacts = ArrayHelper.clean(options.contacts);
    this.isContactVerified = options.isContactVerified;
    this.credentialStatus = StringHelper.clean(options.credentialStatus);
    this.documentSourceTypeName = StringHelper.clean(options.documentSourceTypeName);
    this.documentSourceTypeId = options.documentSourceTypeId;
    this.expirationDate = options.expirationDate;
    this.groupName = StringHelper.clean(options.groupName);
    this.invoiceSettingData = StringHelper.clean(options.invoiceSettingData);
    this.invoiceType = StringHelper.clean(options.invoiceType);
    this.isSpecialHandling = options.isSpecialHandling;
    this.lastContactedDate = DateHelper.create(options.lastContactedDate, null);
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.masterDocumentSourceStatus = StringHelper.clean(options.masterDocumentSourceStatus);
    this.masterDocumentSourceVerifiedDate = options.masterDocumentSourceVerifiedDate;
    this.nextCallDateTime = StringHelper.clean(options.nextCallDateTime);
    this.organizationId = options.organizationId;
    this.password = StringHelper.clean(options.password);
    this.postalCode = StringHelper.clean(options.postalCode);
    this.retrievalTypeId = options.retrievalTypeId;
    this.specialHandlingId = options.specialHandlingId;
    this.specialHandlingText = StringHelper.isAvailable(options.specialHandlingText) ? options.specialHandlingText.replace("SH ", "") : options.specialHandlingText;
    this.specialHandlingComment = StringHelper.isAvailable(options.specialHandlingComment) ? options.specialHandlingComment : "";
    this.specialHandlingUpdateDate = DateHelper.create(options.specialHandlingUpdateDate, null);
    this.specialHandlingUpdateUserName = options.specialHandlingUpdateUserName;
    this.state = StringHelper.clean(options.state);
    this.totalCalls = options.totalCalls;
    this.totalUniqueDaysCalled = options.totalUniqueDaysCalled;
    this.totalEmails = options.totalEmails;
    this.totalEmails = options.totalEmails;
    this.totalFaxes = options.totalFaxes;
    this.totalNotes = options.totalNotes;
    this.twoFactorAuthorization = options.twoFactorAuthorization;
    this.uploadAccessCode = StringHelper.clean(options.uploadAccessCode);
    this.url = StringHelper.clean(options.url);
    this.userName = StringHelper.clean(options.userName);
    this.vendorConfirmDate = StringHelper.clean(options.vendorConfirmDate);
    this.vendorId = options.vendorId;
    this.vendorInvoicing = StringHelper.clean(options.vendorInvoicing);
    this.vendorName = StringHelper.clean(options.vendorName);
    this.weeklyContactMethodTypeId = options.weeklyContactMethodTypeId;
    this.weeklyContactMethodTypeName = StringHelper.clean(options.weeklyContactMethodTypeName);
    this.hasPrimaryContact = options.hasPrimaryContact;
    this.totalChaseCount = options.totalChaseCount;
    this.openChaseCount = options.openChaseCount;
  }
}
