import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-roi",
  templateUrl: "./roi.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiComponent  {
  filters: string[];
  dashboardType = LookerRiskDashboardType.ROI;

  constructor() {
    this.filters = ["Projects", "RafCalcSource", "PaymentYear"];
  }

}
