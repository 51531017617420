<div class="chase-detail-v2-chart">
  <div class="chase-detail-v2-chart__document">
    <div #containerBody class="chase-detail-chart-container"
      [ngClass]="(this.menuService.showHeader | async) ? 'compress' : 'expand'">
      <div id="chart_container" [ngClass]="{ 'max': isChartMaxed }" [ngStyle]="getPopOutPDFViewerStyle()">
        <app-chase-document-page-viewer [chaseId]="chaseId" [isReadOnly]="!assignedToCurrentUser || auditMode"
          [isOcrDataAvailable]="isOcrDataAvailable" [status]="status" [isExpandedViewFromUrl]="isExpandedViewFromUrl"
          [duplicateTab]="duplicateTab" [isSplitScreenMainTab]="isSplitScreenMainTab" [(isMaximized)]="isChartMaxed"
          [showChartThumbnails]="!auditMode" (hasNoDocumentPages)="hasNoDocumentPages($event)"
          (isOpenSplitScreenUrl)="isOpenSplitScreenUrl($event)" [jumpPageNumber]="selectedPageNumber"
          [isSplitScreenEnabled]="(isSplitScreenEnabled || isSplitScreen) && !isCodingReviewModeDX" [isPopOutBtnEnabled]="isPopOutBtnEnabled"
          [mainTab]="mainTab" [tabSelected]="selectedTabText" (selectedFilters)="selectedFilter($event)"
          [documentPageIds]="documentIds" [allEvidencesDocumentPageIds]="allEvidencesDocumentPageIds"
          [showHighlightIcon]="showHighlightIcon"
          >
        </app-chase-document-page-viewer>
      </div>
    </div>
  </div>

  <member-feedback-modal *ngIf="isOverreadFeedbackEnabled && isStatusNotManagerReview && !duplicateTab" [isRisk20]="true"
    [isRiskOrIva]="isRisk || isIva" (onWorkflowStatusToggle)="toggleCodingData($event)" [chaseId]="chaseId"
    [chaseOverreadDetail]="chaseOverreadDetail"
    >
  </member-feedback-modal>

  <member-challenge-modal *ngIf="isOverreadFeedbackEnabled && isStatusOverreadFeedback && isManagerReview && !duplicateTab" [isRisk20]="true"
    [isRiskOrIva]="isRisk || isIva" 
    (onWorkflowStatusToggle)="toggleCodingData($event)" [chaseId]="chaseId"
    [chaseOverreadDetail]="chaseOverreadDetail"
    >
  </member-challenge-modal>
  <div [ngStyle]="getPopOutStyle()" class="chase-detail-v2-chart__coding">
    <ng-template #riskTabMenu></ng-template>
  </div>
</div>