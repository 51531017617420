<div class="member-chases-container">
  <app-server-grid [hidden]="!hasMemberId"
                   [configuration]="memberChasesGridConfiguration"
                   [(request)]="memberChasesGridRequest"
                   [refresh]="refreshGrid"
                   [(selection)]="memberChasesGridSelection"
                   [actions]="actions"
                   (dataLoaded)="gridDataLoaded($event)"
                   [actionTemplate]="memberChasesActionTemplate">

    <ng-template #chaseIdColumn let-rowData>
      <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
    </ng-template>

    <ng-template #gapColumn let-rowData >
      <span [title]="getTotalGaps(rowData.numeratorList)">{{getTotalGaps(rowData.numeratorList)}}</span>
    </ng-template>

    <ng-template #memberChasesActionTemplate
                 let-rowData let-localActionPopover="actionPopover">
      <div class="action-template-container">
        <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                    [text]="action.name"
                    (onClick)="action.action(rowData); localActionPopover.hide();"
                    [disabled]="action.disabled"></app-button>
      </div>
    </ng-template>

  </app-server-grid>
</div>

<app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible"
                            (onHide)="closeModal()"
                            (onUpdate)="refreshGrid.emit()"
                            [(chases)]="selectedChaseIdsForAssignment"
                            [isUnassignEnabled]="isUnassignEnabled"></app-chase-assignment-modal>

<app-manage-tag-modal [(visible)]="isManageTagModalVisible"
                      (onHide)="closeModal()"
                      [(selectedData)]="selectedChases"
                      [entityName]="entityName"
                      [totalEntityCount]="totalEntityCount"
                      [tagType]="tagType"
                      (onUpdate)="refreshGrid.emit()">
</app-manage-tag-modal>

