import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ListItem } from "../../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { InternalPendsDetailHeaderService } from "../internal-pends-detail-header/internal-pends-detail-header.service";
import { InternalPendsDetailInfoService } from "../internal-pends-detail-info/internal-pends-detail-info.service";
import { ChangeOwnerAssign } from "./change-owner-assign.model";
import { InternalPendsChangeOwnerService } from "./internal-pends-change-owner.service";

@Component({
  selector: "app-internal-pends-change-owner",
  templateUrl: "./internal-pends-change-owner.component.html",
  styleUrls: ["./internal-pends-change-owner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InternalPendsChangeOwnerService],
})
export class InternalPendsChangeOwnerComponent implements OnChanges {
  @Input() isChangeOwnerVisible = false;
  @Output() formClose = new EventEmitter();
  @Input() hdviButtonStyle = "inactiveButton";
  @Input() clientButtonStyle = "inactiveButton";
  summaryItems: ListItem[];
  assignTo: string;
  isAssignTo: boolean;
  readonly formGroup: FormGroup;
  readonly owner: Textbox;
  readonly notes: Textarea;
  private changeOwnerAssign: ChangeOwnerAssign;
  private pendId: number;
  @Input() chasePendIds = [];
  isClientOwned = 0;
  @Input() disabled = true;
  private modelErrors: any[] = [];
  private newLine = "\r";
  ownedBy = "O";
  ownerType: string;
  assignedUsers: Dropdown;
  @Input() pendStatusId;
  @Input() pendType: string;
  roleType: string;


  constructor(
    private serviceSet: InternalPendsDetailInfoService,
    private serviceHeaderSet: InternalPendsDetailHeaderService,
    private readonly formService: FormService,
    private service: InternalPendsChangeOwnerService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private messagingService: MessagingService,
    private readonly automapper: AutomapperService,
    private authService: AuthService
  ) {

    this.pendId = this.service.getPendGdFromPath();
    // TODO: Need to figure out what to do if pendid is not coming from the path.
    if (this.pendStatusId === "New" || this.pendStatusId === "In Progress") {
      if (this.pendId) {
        this.getUsersDropdown(this.ownedBy);
      }
    }

    this.notes = new Textarea({
      key: "notes",
      label: "Notes",
      placeholder: "Long form text...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
    });

    this.assignedUsers = new Dropdown({
      key: "assignedUser",
      label: "Assign to User",
      placeholder: "Select User...",
      validators: [Validators.required],
      appendTo: "body",
    });

    // TODO: do this until dynamic forms handles error display
    this.modelErrors = [
      { key: "assignedTo", message: "Please select users" },
      { key: "notes", message: "Please enter notes. Minimum 4 characters and Maximum 1000 characters allowed" },
    ];

    this.formGroup = this.formService.createFormGroup([this.notes, this.assignedUsers]);

  }

  ngOnChanges() {
    if (this.pendStatusId === "New" || this.pendStatusId === "In Progress") {
      if (this.pendId || this.chasePendIds.length > 0) {
        this.getUsersDropdown(this.ownedBy);
      }
    }

  }

  // Get data for dropdowns
  getUsersDropdown(ownedBy) {
    if (this.pendId === null || this.pendId === undefined) {
      this.pendId = this.chasePendIds[0];
    }
    if (this.ownerType !== ownedBy) {
      this.ownerType = ownedBy;
      if (ownedBy === "C") {

        this.hdviButtonStyle = "inactiveButton";
        this.clientButtonStyle = "activeButton";
      } else {
        this.hdviButtonStyle = "activeButton";
        this.clientButtonStyle = "inactiveButton";
      }
    }
    if (StringHelper.isAvailable(this.pendType)) {
      switch (this.pendType) {
        case "clinical":
          this.roleType = "C";
          break;
        case "retrieval":
          this.roleType = "N";
          break;
        default:
          this.roleType = null;
          break;
      }

    } else {
      this.roleType = null;
  }

    this.service.getAssignedToUsersList(ownedBy, this.pendId, this.roleType)
      .pipe(
        map(this.automapper.curryMany("PendUser", "SelectableInput"))
      )
      .subscribe(data => {
        ArrayHelper.addItemToSelect(data, "Unassigned", 0);
        this.assignedUsers = new Dropdown({ ...this.assignedUsers, options: data } as any);
        this.changeDetector.markForCheck();
      });
  }

  changeOwner(): void {

    this.route.paramMap.subscribe(params => {
      this.pendId = +params.get("pendGd");
    });

    if (this.pendId > 0) {
      this.chasePendIds = [];
      this.chasePendIds.push(this.pendId);
    }


    this.changeOwnerAssign = new ChangeOwnerAssign({
      chasePendIds: this.chasePendIds,
      assignedToUserId: this.formGroup.value.assignedUser,
      notes: this.formGroup.value.notes,
      ownedBy: this.ownerType,
    });
    if (this.formGroup.invalid) {
      this.DisplayErrors();
      return;
    }
    this.service.updateChangeOwner(this.changeOwnerAssign).subscribe(data => {
      if (data > 0) {
        this.serviceSet.resetFunction(this.pendId);
        this.serviceHeaderSet.resetFunction(this.pendId);
        this.messagingService.showToast("Pend Reassigned successfully", SeverityType.SUCCESS);
        this.pendId = null;
        this.hdviButtonStyle = "inactiveButton";
        this.clientButtonStyle = "inactiveButton";
        this.assignedUsers = new Dropdown({ ...this.assignedUsers, options: null } as any);
      } else {
        this.messagingService.showToast("Pend Reassigned Failed", SeverityType.WARN);
      }
      this.changeDetector.markForCheck();
    });
    this.formGroup.reset();
    this.visibleChange(false);
  }

  visibleChange($event) {
    this.isChangeOwnerVisible = $event;
    this.formClose.emit($event);
  }

  // TODO: temporary until Dynamic Forms handles error display
  private DisplayErrors() {
    let ErrorMessages = "Invalid Entries: \r";

    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        const control = this.formGroup.controls[key];
        if (control.invalid) {
          const modelError = this.modelErrors.filter(obj => {
            return obj.key === key;
          });

          if (modelError) {
            ErrorMessages += modelError[0].message + this.newLine;
          }
        }
      }
    }

    this.messagingService.showToast(ErrorMessages, SeverityType.ERROR);

  }

  private assignAndNotify = (data: ChangeOwnerAssign[]): void => {

    this.changeDetector.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }
}
