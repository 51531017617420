
<div style="padding: 0px 15px 15px;">

  <div class="current-pends-header bold fontsize">Notification</div>
  <div *ngFor="let item of notificationList;trackBy: trackByIndex">
    <div class="current-pends-header bold">
      {{item.specificDay}}<span> ({{item.NotificationGroup.length}})</span>
    </div>
    <div class="current-pends-container" *ngFor="let value of item.NotificationGroup;trackBy: trackByIndex">

      <div class="current-pends-detail">
        <div>{{value.objectType}} <a class="link" *ngIf="value.objectId!=0" [routerLink]="[value.alertURL]">{{value.objectId}}</a> {{value.messsage}} </div>
      </div>
      <div>
        <span>{{value.timeStamp | date:"short"}}</span>
      </div>
    </div>
  </div>
  <app-pagination [rows]="rows"
                  [totalRecords]="totalRecords"
                  (onPageChange)="onPageChange($event)">
  </app-pagination>
</div>
