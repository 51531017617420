import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../auth/auth.service";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { UserService } from "../../core/user/user.service";
import { FormService } from "../../dynamic-forms/form.service";
import { Autocomplete } from "../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { AddressDetailInfoEditService } from "../../platform/modules/retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { RetrievalPageService } from "../../platform/modules/retrieval/retrieval-page/retrieval-page.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { ActionButton } from "../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../zdevcontrols/dev-controller/dev-controller.service";
import { BulkAction } from "../grid/bulk-actions/bulk-action.model";
import { GridView } from "../grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../grid/grid-pipe.enum";
import { GridStateService } from "../grid/grid-state.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { ServerGridComponent } from "../grid/server-grid/server-grid.component";
import { LandingFilterStateService } from "../kpi/kpi-filter/landing-filter/landing-filter-state.service";
import { TagType } from "../tags/model/tag-type.enum";
import { TagService } from "../tags/tag.service";

@Component({
  selector: "app-address-grid",
  templateUrl: "./address-grid.component.html",
  styleUrls: ["./address-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild("splHandlingColumn", { static: true }) splHandlingColumn: TemplateRef<any>;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  @Input() filterHeaderText = "";
  @Input() retrievalType = "";
  @Input() url = `${this.baseApiUrl}retrieval/list`;
  @Input() isExcelExportDuplicateRow = true;
  @Input() excelExportExpandColumnName = "providerName";
  @Input() isSelectionModeMultiple = false;
  @Input() isUnassignEnabled = true;
  @Input() isLoadOnInit = true;
  @Input() additionalColumns: GridColumnDefinition[] = [];
  @Input() additionalBulkActions: BulkAction[] = [];
  @Input() additionalFilters: GridFilter[] = [];
  @Input() selection: any[];
  @Input() viewAttributeId = 0;
  @Input() showViews = false;
  @Output() refreshData = new EventEmitter<null>(true);
  private readonly possiblePageTypes = ["RetrievalPsrGrid", "RetrievalFtGrid", "RetrievalEmrGrid", "RetrievalShGrid"];
  // Note: selectedAddresses is used for actions / bulk actions.
  selectedAddresses: any[];
  isAssignModalVisible = false;
  actions: BulkAction[];
  configuration: GridConfiguration;
  refreshGrid = new EventEmitter<null>(true);
  request: GridRequest;
  @Input() stateName = "";
  landingFilter: any;
  tagType = TagType.MASTERDOCUMENTSOURCE;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  allAvailableTags: SelectableInput[];
  selectedObjectId: number;
  refreshViews = new EventEmitter<GridView>(true);
  views: GridViewsState;
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  searchTextChanged = new Subject<string>();
  @Input() displayCheckedItemsInGridView: {value: string; disabled: boolean}[] = [];
  get aidInput(): Textbox {
    return this.getInput("Id") as Textbox;
  }

  get groupNameInput(): Textbox {
    return this.getInput("GroupName") as Textbox;
  }

  get providerNameInput(): Textbox {
    return this.getInput("ProviderName") as Textbox;
  }

  get showRetrievalTypeFilter(): boolean {
    const retrievalTypeFilter = this.request.getFilter("NavigationType");
    return retrievalTypeFilter.show;
  }

  get documentSourceTypesInput(): CheckboxGroup {
    return this.getInput("NavigationType");
  }

  set documentSourceTypesInput(value: CheckboxGroup) {
    this.setInput("NavigationType", value);
  }

  get addressGroupIdInput(): Textbox {
    return this.getInput("AddressGroupId") as Textbox;
  }

  get postalCodeInput(): Textbox {
    return this.getInput("PostalCode") as Textbox;
  }

  get cityInput(): Textbox {
    return this.getInput("City") as Textbox;
  }

  get stateInput(): Textbox {
    return this.getInput("State") as Textbox;
  }

  get showStatusFilter(): boolean {
    const statusFilter = this.request.getFilter("AssignedFilter");
    return statusFilter.show;
  }

  get statusInput(): CheckboxGroup {
    return this.getInput("AssignedFilter") as CheckboxGroup;
  }

  set statusInput(value: CheckboxGroup) {
    this.setInput("AssignedFilter", value);
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }

  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }

  get shReasonInput(): CheckboxGroup {
    return this.getInput("SpecialHandling");
  }

  set shReasonInput(value: CheckboxGroup) {
    this.setInput("SpecialHandling", value);
  }

  get coRetrievalOwner(): Dropdown {
    return this.getInput("CoRetrievalOwner");
  }

  set coRetrievalOwner(value: Dropdown) {
    this.setInput("CoRetrievalOwner", value);
  }

  get clientInput(): Autocomplete {
    return this.getInput("ClientId");
  }

  set clientInput(value: Autocomplete) {
    this.setInput("ClientId", value);
  }

  get showAssignedToFilter(): boolean {
    const assignedFilter = this.request.getFilter("AssignedToUserId");
    return assignedFilter.show;
  }

  get assignedToInput(): Autocomplete {
    return this.request.getInput("AssignedToUserId");
  }

  set assignedToInput(value: Autocomplete) {
    this.request.setInput("AssignedToUserId", value);
  }
  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  get isSpecialHandlingType(): boolean {
    if (this.request != null && ArrayHelper.isAvailable(this.request.filters)) {
      const specialHandlingFilter = this.request.filters.find(filter => filter.key === "SpecialHandlingType");
      if (specialHandlingFilter != null) {
        return specialHandlingFilter.show;
      }
      return false;
    }
    return false;
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly devService: DevControllerService,
    private readonly automapper: AutomapperService,
    private readonly formService: FormService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly userService: UserService,
    private readonly landingFilterStateService: LandingFilterStateService,
    private readonly gridStateService: GridStateService,
    private readonly authService: AuthService,
    private messagingService: MessagingService,
    private readonly tagService: TagService,
    private gridViewsService: GridViewsService,
    private addressDetailInfoEditService: AddressDetailInfoEditService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);
    this.getAllSelectableInputs();

    this.createGrid();

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView)),
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getAllTagsList(searchText)))
          .subscribe()
    );
  }

  setStaticFilter(value: string): void {
    this.statusInput.value = [new SelectableInput({text: value, value})];
    this.request.setInput<CheckboxGroup>("AssignedFilter", this.statusInput);
    this.formService.updateDom.next();
    this.serverGridComponent.refresh.next();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  openAssignAddressToUser = (rowData: any): void => {
    this.setSelectedAddresses(rowData);
    this.isAssignModalVisible = true;
  }

  closeModal(): void {
    this.selectedAddresses = [];
    this.selection = [];
    this.isAssignModalVisible = false;
    this.isManageTagModalVisible = false;
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  getValue(key: string): string {
    if (this.request == null) {
      return "";
    }

    const gridFilter = this.request.getFilter(key);
    return gridFilter.value;
  }

  setValue(key: string, value: string): void {
    if (this.request == null) {
      return;
    }

    const gridFilter = this.request.getFilter(key);
    gridFilter.value = value;
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getShReasons();
    this.getClients();
    this.getAssignedToUsers();
    this.getDocumentSourceTypes();
    this.getStatus();
    this.getTagSearchOperator();
    setTimeout(() => this.showFiltersOrGetData());
  }

  createGrid(): void {
    this.configuration = new GridConfiguration({
      columns: this.getColumns(),
      selectionMode: this.isSelectionModeMultiple ? "multiple" : "",
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      stateName: this.stateName,
      showViews: this.showViews,
      viewAttributeId: this.viewAttributeId,
    });

    this.request = new GridRequest({
      url: this.url,
      filters: this.getFilters(),
    });
    this.getViews();

    this.actions = this.getActions();
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.configuration.showViews) {
      this.gridViewsService.get(this.configuration.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  private getColumns(): GridColumnDefinition[] {
    const sh = this.retrievalType === "sh" ? "sh" : "";
    const totalColumns = [
      new GridColumnDefinition({ field: "defaultSpecialHandling", template: this.splHandlingColumn, header: "SH", isSortableColumn: false, width: "30px", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceID" }),
      new GridColumnDefinition({ field: "specialHandlingReason", header: "SH Reason" }),
      new GridColumnDefinition({ field: "masterDocumentSourceID", header: "AID", routeUrl: `/retrieval/addressdetail/:masterDocumentSourceID/${sh}` }),
      new GridColumnDefinition({ field: "groupName", header: "Group Name" }),
      new GridColumnDefinition({ field: "providerName", header: "Provider Name" }),
      new GridColumnDefinition({ field: "address1", header: "Address1" }),
      new GridColumnDefinition({ field: "address2", header: "Address2" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "postalCode", header: "Zip Code" }),
      new GridColumnDefinition({ field: "phone", header: "Phone", pipeName: GridPipeName.Phone }),
      new GridColumnDefinition({ field: "faxNumber", header: "Fax", pipeName: GridPipeName.Phone }),
      new GridColumnDefinition({ field: "project", header: "Project(s)" }),
      new GridColumnDefinition({ field: "addressGroupingData", header: "Address Groups" }),
      new GridColumnDefinition({ field: "latestContactDate", header: "Last Contact Date" }),
      new GridColumnDefinition({ field: "nextAppointmentDate", header: "Appointment Date" }),
      new GridColumnDefinition({ field: "nextCallDate", header: "Next Call Date" }),
      new GridColumnDefinition({ field: "chaseCount", header: "Open Chases" }),
      new GridColumnDefinition({ field: "chasePendCount", header: "Pends" }),
      new GridColumnDefinition({ field: "documentSourceTypeName", header: "Retrieval Type" }),
      new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
      new GridColumnDefinition({ field: "status", header: "Status" }),
      new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
    ];

    if (this.retrievalType === "sh") {
      const shFieldIndex = totalColumns.findIndex(x => x.field === "defaultSpecialHandling");
      if (shFieldIndex > -1) {
        totalColumns.splice(shFieldIndex, 1);
        [totalColumns[0], totalColumns[1]] = [totalColumns[1], totalColumns[0]];
      }
    }

    // https://stackoverflow.com/questions/37585309/replacing-objects-in-array
    const distinctColumns = totalColumns.map(obj =>
      this.additionalColumns.find(col =>
        col.field === obj.field) || obj);
    return distinctColumns;
  }

  private getFilters(): GridFilter[] {

    this.landingFilter = this.landingFilterStateService.get();
    if (this.isLandingFilterApplied) {

      this.gridStateService.delete(this.stateName);
    }
    this.landingFilterStateService.clear();
    const totalFilters = [
      ...this.additionalFilters,
      new GridFilter({
        input: new Textbox(),
        key: "Id",
        name: "AID",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "GroupName",
        name: "Group Name",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ProviderName",
        name: "Provider Name",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "State",
        name: "State",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "City",
        name: "City",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "PostalCode",
        name: "Zip Code",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select..." }),
        key: "ClientId",
        name: "Client",
        value: (this.landingFilter.clientId || "").toString(),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Projects",
        value: (this.landingFilter.projectId || "").toString(),
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AddressGroupId",
        name: "Address Group",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "AssignedFilter",
        name: "Status",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "NavigationType",
        value: this.retrievalType,
        name: "Retrieval Type",
      }),
      new GridFilter({
        input: new TagSearchMultiselect({ placeholder: "Tags" }),
        key: "TagIdsAsCsv",
        name: "Tags",
      }),
      new GridFilter({
        input: new Dropdown(),
        key: "TagSearchOperator",
        value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
          ? this.selectedTagSearchOperator : null,
        showChip: false,
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "SpecialHandling",
        name: "Special Handling",
      }),
      new GridFilter({
        input: new Dropdown({
          placeholder: "Select Retriever...",
          options: [
          new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
          new SelectableInput({ text: "Client", value: "Client" }),
          new SelectableInput({ text: "Mixed", value: "Mixed" }),
        ]}),
        key: "CoRetrievalOwner",
        name: "Retriever",
      }),
    ];

    const distinctFilters = totalFilters.filter((gridFilters, i, filters) => {
      return filters.findIndex(a => a.key === gridFilters.key) === i;
    });

    return distinctFilters;
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      ...this.additionalBulkActions,
      new BulkAction({
        name: "Assign Address",
        action: this.openAssignAddressToUser,
        disabled: this.hasEmployeeRole,
      }),
      // new BulkAction({
      //  name: "Manage Tags",
      //  action: this.openManageTagModal,  /*TODO: This will be a part of next release*/
      //  disabled: this.hasTagManagement,
      // }),
    ];

    const distinctBulkActions = totalBulkActions.filter((gridAction, i, filters) => {
      return filters.findIndex(a => a.name === gridAction.name) === i;
    });

    return distinctBulkActions;
  }

  isActionDisabled(rowData: any, actionName: string): boolean {
    if (actionName === "Assign Address") {
      return this.hasEmployeeRole;
    }
    return rowData.documentSourceTypeName === "PSR" || rowData.documentSourceTypeName === "3rd Party";
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectsInput = { ...this.projectsInput, options } as any;
        this.setProjectsInput();
        this.formService.updateDom.next();
      });
  }

  private setProjectsInput(): void {
    const projectOption = this.projectsInput;
    const projectFilter = this.request.getFilter(this.projectsInput.key);
    if (StringHelper.isAvailable(projectFilter.value)) {
      const filteredProjects = projectFilter.value.split(/\s*,\s*/);
      projectFilter.inputValue = [];
      filteredProjects.forEach(selectedProject => {
        projectFilter.inputValue.push(projectOption.options.find(a => a.value === +selectedProject));
      });
      this.formService.updateDom.next();
    }
  }

  private getShReasons(): void {
    this.addressDetailInfoEditService
      .getSpecialHandlingReasons()
      .subscribe(options => {
        this.shReasonInput = { ...this.shReasonInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getStatus(): void {
    this.retrievalPageService
      .getStatuses()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.text,
          value: item.text,
        }));
      }))
      .subscribe(result => {
        this.statusInput = { ...this.statusInput, options: result } as any;
        this.formService.updateDom.next();
      });
  }

  private getDocumentSourceTypes(): void {
    this.retrievalPageService
      .getDocumentSourceTypes()
      .subscribe(result => {
        this.documentSourceTypesInput = { ...this.documentSourceTypesInput, options: result } as any;
        this.formService.updateDom.next();
      });
  }

  private getClients(): void {
    this.userService
      .getClients()
      .pipe(
        map(this.automapper.curryMany("ClientOrgModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.clientInput = { ...this.clientInput, options } as any;
        this.setClientInput();
        this.formService.updateDom.next();
      });
  }
  hasValidPageType(value: string): boolean {
    return StringHelper.isAvailable(value) && this.possiblePageTypes.includes(value);
  }

  showFiltersOrGetData(): void {
    let filters = [];
    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName)) {
      const gridState = this.gridStateService.get(this.stateName);
      filters = gridState.request.filters.filter(filter => this.getSelectedFilters(filter.input.value));
    } else {
      filters = this.request.filters.filter(filter => StringHelper.isAvailable(filter.input.value));
    }
    if (this.hasValidPageType(this.stateName)) {
      if (filters.length >= 2) {
        setTimeout(() => this.refreshGrid.emit());
      } else {
        this.serverGridComponent.showFilters();
        this.serverGridComponent.data = [];
      }
    } else {
      if (filters.length > 0) {
        setTimeout(() => this.refreshGrid.emit());
      } else {
        this.serverGridComponent.showFilters();
        this.serverGridComponent.data = [];
      }
    }

  }

  private getSelectedFilters(filterValue): any {
    if (Array.isArray(filterValue)) {
      if (ArrayHelper.isAvailable(filterValue)) {
        return true;
      } else {
        return false;
      }
    }
    return filterValue;
  }

  private setClientInput(): void {
    const clientFilter = this.request.getFilter(this.clientInput.key);
    if (StringHelper.isAvailable(clientFilter.value)) {
      const optionsValue = this.clientInput.options.find(a => a.value === +clientFilter.value);
      clientFilter.inputValue = [optionsValue];

      this.formService.updateDom.next();
    }
  }

  private getAssignedToUsers(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private setSelectedAddresses(rowData: any): void {
    this.selectedAddresses = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
  }

  trackByIndex(index, item) {
    return index;
  }

  gridRefresh(): void {
    this.refreshGrid.emit();
    this.refreshData.emit();
  }

  get isLandingFilterApplied(): boolean {

    return NumberHelper.isAvailable(this.landingFilter.clientId) || NumberHelper.isAvailable(this.landingFilter.projectId);
  }

  get hasEmployeeRole(): boolean {
    return !this.authService.user.isAdminRole &&
      !this.authService.user.isLeadRole &&
      !this.authService.user.isManagerRole;
  }

  openManageTagModal = (rowData: any): void => {
    this.setSelectedAddresses(rowData);
    if (ArrayHelper.isAvailable(this.selectedAddresses)) {
      this.totalEntityCount = this.selectedAddresses.length;
      this.entityName = "ADDRESS";
      this.isManageTagModalVisible = true;
    } else {
      this.messagingService.showToast("Address(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  getAllTagsList(searchText: string): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.useFilter = false;
        this.tagsInput.options = [...this.allAvailableTags];
        this.changeDetector.markForCheck();
      });
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }
}
