import { BehaviorSubject, Subject } from "rxjs";
import { DocumentPage } from "../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

export class DocumentThumbnailState {
  private pPages = new BehaviorSubject<(DocumentPage | null)[]>([]);
  private pCurrentPageNumber: number;
  private pCurrentThumbnailNumber: number;
  private pTotalPages: number;
  private pDocumentTypeId: number;
  private pCurrentDocumentQueueId: number;
  private pThumbnailEditMode = false;
  documentId: number;
  minLoadThresholdThumbnails = 10;
  maxLoadThresholdThumbnails = 20;

  private currentPagesList: DocumentPage[] = [];

  constructor(model: Partial<DocumentThumbnailState> = {}) {
    this.setPages(model.pages);
    this.totalPages = model.totalPages;
    this.currentPageNumber = model.currentPageNumber;
    this.documentId = model.documentId;
    this.documentTypeId = model.documentTypeId;
  }

  get currentPages(): DocumentPage[] {
    return this.currentPagesList;
  }

  set currentPages(pages: DocumentPage[]) {
    this.currentPagesList = pages;
  }

  get documentTypeId(): number {
    if (this.pDocumentTypeId == null && this.hasPage(1)) {
      this.documentTypeId = this.getPage(1).documentTypeId;
    }

    return this.pDocumentTypeId;
  }
  set documentTypeId(value: number) {
    this.pDocumentTypeId = value == null ? null : Number(value);
  }

  get totalPages(): number {
    if (NumberHelper.isLessThan(this.pTotalPages, 1) && this.hasPage(1)) {
      this.totalPages = this.getPage(1).numberOfPages;
    }

    return this.pTotalPages;
  }
  set totalPages(value: number) {
    this.pTotalPages = NumberHelper.isGreaterThan(value, 0) ? value : 0;
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.pPages;
  }

  get pages(): (DocumentPage | null)[] {
    return this.pPages.value;
  }

  setPages(value: (DocumentPage | null)[]): void {
    const pages = ArrayHelper.isAvailable(value) ? value : [];
    this.pPages.next(pages);
  }

  get currentPageNumber(): number {
    return this.pCurrentPageNumber;
  }
  set currentPageNumber(value: number) {
    if (!NumberHelper.isAvailable(value)) {
      this.pCurrentPageNumber = null;
    } else if (NumberHelper.isGreaterThan(value, this.totalPages, true)) {
      this.pCurrentPageNumber = this.totalPages;
    } else if (NumberHelper.isLessThan(value, 1, true)) {
      this.pCurrentPageNumber = 1;
    } else {
      this.pCurrentPageNumber = value;
    }
  }

  get currentThumbnailNumber(): number {
    return this.pCurrentThumbnailNumber;
  }
  set currentThumbnailNumber(value: number) {
    if (!NumberHelper.isAvailable(value)) {
      this.pCurrentThumbnailNumber = null;
    } else if (NumberHelper.isGreaterThan(value, this.totalPages, true)) {
      this.pCurrentThumbnailNumber = this.totalPages;
    } else if (NumberHelper.isLessThan(value, 1, true)) {
      this.pCurrentThumbnailNumber = 1;
    } else {
      this.pCurrentThumbnailNumber = value;
    }
  }

  get thumbnailEditMode(): boolean {
    return this.pThumbnailEditMode;
  }
  set thumbnailEditMode(value: boolean) {
    this.pThumbnailEditMode = value;
  }

  get currentDocumentQueueId(): number {
    return this.pCurrentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    if (!NumberHelper.isAvailable(value)) {
      this.pCurrentDocumentQueueId = null;
    } else if (NumberHelper.isLessThan(value, 1, true)) {
      this.pCurrentDocumentQueueId = null;
    } else {
      this.pCurrentDocumentQueueId = value;
    }
  }

  get hasCurrentPage(): boolean {
    return this.currentPage != null;
  }

  get currentPage(): DocumentPage {
    return this.getPage(this.currentPageNumber);
  }

  get shouldLoadMoreThumbnails(): boolean {
    let min = this.currentThumbnailNumber - this.minLoadThresholdThumbnails - 1;
    min = min < 1 ? 1 : min;
    let max = this.currentThumbnailNumber + this.minLoadThresholdThumbnails;
    max = max > this.totalPages ? this.totalPages : max;
    let loadMoreThumbnails = false;
    while (!loadMoreThumbnails && min <= max) {
      loadMoreThumbnails = !this.hasPage(min);
      min++;
    }

    return loadMoreThumbnails;
  }

  get shouldLoadMoreExpandedThumbnails(): boolean {
    let min = this.currentThumbnailNumber - this.minLoadThresholdThumbnails - 1;
    min = min < 1 ? 1 : min;
    let max = this.currentThumbnailNumber + this.minLoadThresholdThumbnails;
    max = max > this.totalPages ? this.totalPages : max;
    let loadMoreThumbnails = false;
    while (!loadMoreThumbnails && min <= max) {
      loadMoreThumbnails = !this.hasPage(min);
      // 15 is initial thumbnails page load size
      if (min <= 15 && loadMoreThumbnails) {
        loadMoreThumbnails = false;
      }
      min++;
    }

    return loadMoreThumbnails;
  }

  hasPage(pageNumber: number): boolean {
    const currentPage = this.getPage(pageNumber);
    const img = currentPage?.image;
    return StringHelper.isAvailable(img);
  }

  getPage(pageNumber: number): DocumentPage {
    return this.pages[pageNumber - 1];
  }

  setPage(page: DocumentPage): void {
    this.pages[page.pageNumber - 1] = page;
  }

  removeUndefinedPage(): void {
    let undefinedPageCount = 0;
    this.pages.forEach((element, index) => {
      if (element === undefined) {
        if (index > -1 && this.pages[index + 1] === undefined) {
          this.pages.splice(index, 2);
          undefinedPageCount += 2;
        } else if (index > -1) {
          this.pages.splice(index, 1);
          undefinedPageCount++;
        }
      }
    });
    this.totalPages = this.totalPages - undefinedPageCount;
  }

  getThumbNailPage(pageNumber: number): DocumentPage {
    return this.pages[pageNumber - 1];
  }

  setThumbnailPage(page: DocumentPage): void {
    const index = NumberHelper.isGreaterThan(page.rowSerialNumber, 0) ? page.rowSerialNumber : page.pageNumber;
    this.pages[index - 1] = page;
  }

  getThumbnailsToLoad(fromToggleView: boolean = false): number[] {
    const currentThumbnailNumber = this.currentThumbnailNumber;
    const maxThreshold = this.hasPage(currentThumbnailNumber) ? this.maxLoadThresholdThumbnails : this.minLoadThresholdThumbnails;

    let min = fromToggleView ? 0 : currentThumbnailNumber - maxThreshold;
    min = min < 1 ? 1 : min;

    let max = currentThumbnailNumber + maxThreshold;
    max = max > this.totalPages ? this.totalPages : max;

    const pages = [];
    for (let i = min; i <= max; ++i) {
      if (!this.hasPage(i)) {
        pages.push(i);
      }
    }

    return pages;
  }
}

