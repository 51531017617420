import { FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { Calendar } from "../../../dynamic-forms/inputs/calendar/calendar.model";
import { CheckboxGroup } from "../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Radiobutton } from "../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { DateHelper } from "../../../utilities/contracts/date-helper";
import { AnalyticsService } from "./analytics.service";

export class SharedFilters {

  private formGroup: FormGroup;
  private subSink;

  constructor() {
    this.subSink = new SubSink();
  }

  get sink(): SubSink {
    return this.subSink;
  }

  documentQueueId = new Textbox({
    key: "documentQueueId",
    label: "Document Queue ID",
  });

  confirmationNumber = new Textbox({
    key: "confirmationNumber",
    label: "Confirmation Number",
  });

  providerGatewayPin = new Textbox({
    key: "providerGatewayPin",
    label: "Provider Gateway PIN",
  });

  documentStatus = new CheckboxGroup({
    key: "documentStatus",
    label: "Document State group",
  });

  vendorInvoiceType = new CheckboxGroup({
    key: "vendorInvoiceType",
    label: "Vendor Invoice Type",
  });

  sampleComplianceInput = new CheckboxGroup({
    key: "sampleCompliance",
    label: "Sample Compliance",
  });

  contactMethodInput = new CheckboxGroup({
    key: "contactMethod",
    label: "Contact Method",
  });

  expectedRetrievalInput = new CheckboxGroup({
    key: "expectedRetrieval",
    label: "Expected Retrieval",
  });

  vendorNameInput = new CheckboxGroup({
    key: "vendorName",
    label: "Vendor Name",
  });

  specialHandlingInput = new CheckboxGroup({
    key: "specialHandling",
    label: "Special Handling",
  });

  projectStatusInput = new CheckboxGroup({
    key: "projectStatus",
    label: "Project Status",
    options: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
  });

  contactStatusInput = new CheckboxGroup({
    key: "contactMethodStatus",
    label: "Contact Method Status",
    options: [
      { text: "Successful", value: "Successful" },
      { text: "Failed", value: "Failed" },
    ],
  });

  contactDateStart = new Calendar({
    key: "contactStartDate",
    label: "Start Date",
    placeholder: "Enter Start Date",
    maxDate: new Date(),
  });

  contactDateEnd = new Calendar({
    key: "contactEndDate",
    label: "End Date",
    placeholder: "Enter End Date",
    maxDate: new Date(),
  });

  moveDateStart = new Calendar({
    key: "moveFromDate",
    label: "From Date",
    placeholder: "Enter From Date",
    maxDate: new Date(),
  });

  moveDateEnd = new Calendar({
    key: "moveToDate",
    label: "To Date",
    placeholder: "Enter To Date",
    maxDate: new Date(),
  });

  dateRangeStart = new Calendar({
    key: "dateRangeStart",
    label: "Start Date",
    placeholder: "Enter Start Date",
    maxDate: new Date(),
    errorMessages: {
      isValid: "Please select a From Date that is before the To Date.",
    },
  });

  dateRangeEnd = new Calendar({
    key: "dateRangeEnd",
    label: "End Date",
    placeholder: "Enter End Date",
    maxDate: new Date(),
    errorMessages: {
      isValid: "Please select a To Date that is after the From Date.",
    },
  });

  qaDateFrom = new Calendar({
    key: "qaDateFrom",
    label: "Start Date",
    placeholder: "Enter Start Date",
    maxDate: new Date(),
    errorMessages: {
      isValid: "Please select a From Date that is before the To Date.",
    },
  });

  qaDateTo = new Calendar({
    key: "qaDateTo",
    label: "End Date",
    placeholder: "Enter End Date",
    maxDate: new Date(),
    errorMessages: {
      isValid: "Please select a To Date that is after the From Date.",
    },
  });

  retrievedDateStart = new Calendar({
    key: "retrievedDateStart",
    label: "Retrieved From Date",
    placeholder: "Enter From Date",
    maxDate: new Date(),
  });

  retrievedDateEnd = new Calendar({
    key: "retrievedDateEnd",
    label: "Retrieved To Date",
    placeholder: "Enter To Date",
    maxDate: new Date(),
  });

  movebackDateStart = new Calendar({
    key: "movebackDateStart",
    label: "Moveback From Date",
    placeholder: "Enter From Date",
    maxDate: new Date(),
  });

  movebackDateEnd = new Calendar({
    key: "movebackDateEnd",
    label: "Moveback To Date",
    placeholder: "Enter To Date",
    maxDate: new Date(),
  });

  addressIdInput = new Textbox({
    key: "address",
    label: "AID",
  });

  addressGroupInput = new Textbox({
    key: "addressGroup",
    label: "AID Group",
  });

  abstractionBy = new Textbox({
    key: "abstractionBy",
    label: "Abstraction By",
  });

  pendBy = new Textbox({
    key: "pendBy",
    label: "Pend By",
  });

  movebackBy = new Textbox({
    key: "movebackBy",
    label: "Moveback By",
  });

  overread2By = new Textbox({
    key: "overread2By",
    label: "Overread2 By",
  });

  codedBy = new Textbox({
    key: "codedBy",
    label: "Coded By",
  });

  updatedBy = new Textbox({
    key: "updatedBy",
    label: "Updated By",
  });

  userName = new Textbox({
    key: "userName",
    label: "User Name",
  });

  retrievalOwnerInput = new Radiobutton({
    options: [
      new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
      new SelectableInput({ text: "Client", value: "Client" }),
    ],
    key: "retrievalOwner",
    label: "Retrieval Owner",
  });

  userStatus = new CheckboxGroup({
    options: [
      new SelectableInput({ text: "Active", value: "Active" }),
      new SelectableInput({ text: "Inactive", value: "Inactive" }),
    ],
    key: "userStatus",
    label: "User Status",
  });

  healthPlanInput = new CheckboxGroup({
    key: "healthPlan",
    label: "Health Plan",
  });

  contractNumberInput = new CheckboxGroup({
    key: "contractNumber",
    label: "Contract Number",
  });

  chaseTags = new Textbox({
    key: "chaseTags",
    label: "Chase Tags",
  });

  userEmail = new Textbox({
    key: "userEmail",
    label: "User Email",
  });

  login = new Textbox({
    key: "login",
    label: "login",
  });

  currentChaseStatus = new CheckboxGroup({
    key: "currentChaseStatus",
    label: "Current Chase Status",
  });

  currentProcessStep = new CheckboxGroup({
    key: "currentProcessStep",
    label: "Current Process Step",
  });

  /**
   * @deprecated Use `GenericGet` instead
   * @param analyticsService AnalyticsService
   * @param fn void
   */
  getDocumentStatus(analyticsService: AnalyticsService, fn: (value) => void): void {
    analyticsService.getDocumentStateGroups().subscribe(fn);
  }

  /**
   * @deprecated Use `GenericGet` instead
   * @param analyticsService AnalyticsService
   * @param fn void
   */
  getContactMethods(analyticsService: AnalyticsService, contactMethod: string, fn: (value) => void): void {
    analyticsService.getContactMethodTypes(contactMethod).subscribe(fn);
  }

  genericGet<T extends { [key: string]: any }>(service: T, method: keyof T, fn: (value?) => void, params?: any) {
    this.sink.add(
      service[method](params)?.subscribe(fn)
    );
  }

  getContactMethodStatus(fn: () => void): void {
    this.contactMethodInput = new CheckboxGroup({ ...this.contactMethodInput } as any);
    fn();
  }

  getProjectStatus(fn: () => void): void {
    this.projectStatusInput = new CheckboxGroup({ ...this.projectStatusInput } as any);
    fn();
  }

  setForm(form: FormGroup): void {
    this.formGroup = form;
  }

  setDateDefaultValues(filters: string[]): void {
    if (filters.includes("MoveDateStart") && filters.includes("MoveDateEnd")) {
      this.moveDateStart = new Calendar({
        ...this.moveDateStart,
        value: new Date(DateHelper.format("2020-01-01", "YYYY/MM/DD")),
      });
      this.moveDateEnd = new Calendar({
        ...this.moveDateEnd,
        minDate: new Date(this.moveDateStart.value),
        value: new Date(),
      });
    }
    if (filters.includes("DateRange")) {
      this.dateRangeStart = new Calendar({
        ...this.dateRangeStart,
        value: new Date(DateHelper.format("2020-01-01", "YYYY/MM/DD")),
      });
      this.dateRangeEnd = new Calendar({
        ...this.dateRangeEnd,
        minDate: new Date(this.dateRangeStart.value),
        value: new Date(),
      });
    }
  }

  verifyDateRange(startDateInput: Textbox, endDateInput: Textbox): void {
    const startDate = this.formGroup.get(startDateInput.key).value;
    const endDate = this.formGroup.get(endDateInput.key).value;
    if (DateHelper.isAvailable(startDate) && DateHelper.isAvailable(endDate)) {
      return;
    }
    if (DateHelper.isAvailable(startDate) && !DateHelper.isAvailable(endDate)) {
      this.formGroup.get(endDateInput.key).patchValue(DateHelper.format(new Date(), "YYYY/MM/DD"));
    }
    if (!DateHelper.isAvailable(startDate) && DateHelper.isAvailable(endDate)) {
      const date = DateHelper.create(endDate);
      date.setFullYear(date.getFullYear() - 1);
      this.formGroup.get(startDateInput.key).patchValue(DateHelper.format(date, "YYYY/MM/DD"));
    }
  }

}
