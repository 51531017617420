import { WorkflowAction } from "./workflow-action.enum";
import { WorkflowStatus } from "./worklow-status.enum";

export class WorkflowSubmitRequest {

  constructor(
    readonly entityId: number,
    readonly workflowStatus: WorkflowStatus,
    readonly workflowAction: WorkflowAction,
    readonly content: string,
    readonly entityIds?: number[],
    readonly note?: string
  ) {

    this.entityId = entityId;
    this.workflowStatus = workflowStatus;
    this.workflowAction = workflowAction;
    this.content = content;
    this.entityIds = entityIds;
    this.note = note;
  }
}
