import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "primeng/checkbox";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { SelectButtonModule } from "primeng/selectbutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { SharedModule } from "../../../shared/shared.module";
import { ChaseDetailStateService } from "../member/chase-detail/chase-detail-state.service";
import { InternalPendsChangeOwnerComponent } from "./internal-pends-detail/internal-pends-change-owner/internal-pends-change-owner.component";
import { InternalPendsDetailAttemptHistoryComponent } from "./internal-pends-detail/internal-pends-detail-attempt-history/internal-pends-detail-attempt-history.component";
import { InternalPendsDetailAttemptHistoryService } from "./internal-pends-detail/internal-pends-detail-attempt-history/internal-pends-detail-attempt-history.service";
import { InternalPendsDetailCommentsComponent } from "./internal-pends-detail/internal-pends-detail-comments/internal-pends-detail-comments.component";
import { InternalPendsDetailDocumentsComponent } from "./internal-pends-detail/internal-pends-detail-documents/internal-pends-detail-documents.component";
import { InternalPendsDocumentListComponent } from "./internal-pends-detail/internal-pends-detail-documents/internal-pends-document-list/internal-pends-document-list.component";
import { InternalPendsUploadDocumentComponent } from "./internal-pends-detail/internal-pends-detail-documents/internal-pends-upload-document/internal-pends-upload-document.component";
import { InternalPendsDetailHeaderComponent } from "./internal-pends-detail/internal-pends-detail-header/internal-pends-detail-header.component";
import { InternalPendsDetailHeaderService } from "./internal-pends-detail/internal-pends-detail-header/internal-pends-detail-header.service";
import { InternalPendsDetailInfoComponent } from "./internal-pends-detail/internal-pends-detail-info/internal-pends-detail-info.component";
import { InternalPendsDetailInfoService } from "./internal-pends-detail/internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsDetailTimelineComponent } from "./internal-pends-detail/internal-pends-detail-timeline/internal-pends-detail-timeline.component";
import { InternalPendsDetailComponent } from "./internal-pends-detail/internal-pends-detail.component";
import { InternalPendsRequestStatusChangeComponent } from "./internal-pends-detail/internal-pends-request-status-change/internal-pends-request-status-change.component";
import { InternalPendsRequestStatusChangeService } from "./internal-pends-detail/internal-pends-request-status-change/internal-pends-request-status-change.service";
import { InternalPendsUpdatePendComponent } from "./internal-pends-detail/internal-pends-update-pend/internal-pends-update-pend.component";
import { InternalPendsUpdatePendService } from "./internal-pends-detail/internal-pends-update-pend/internal-pends-update-pend.service";
import { InternalPendsListingComponent } from "./internal-pends-listing/internal-pends-listing.component";
import { InternalPendsRoutingModule } from "./internal-pends-routing.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    SharedModule,
    InternalPendsRoutingModule,
    DropdownModule,
    FileUploadModule,
    SplitButtonModule,
    CheckboxModule,
    SelectButtonModule,
  ],
  declarations: [
    InternalPendsDetailComponent,
    InternalPendsDetailAttemptHistoryComponent,
    InternalPendsDetailCommentsComponent,
    InternalPendsDetailDocumentsComponent,
    InternalPendsDetailHeaderComponent,
    InternalPendsDetailInfoComponent,
    InternalPendsDetailTimelineComponent,
    InternalPendsChangeOwnerComponent,
    InternalPendsRequestStatusChangeComponent,
    InternalPendsListingComponent,
    InternalPendsUploadDocumentComponent,
    InternalPendsDocumentListComponent,
    InternalPendsUpdatePendComponent,
  ],
  providers: [
    ChaseDetailStateService,
    InternalPendsDetailAttemptHistoryService,
    InternalPendsDetailInfoService,
    InternalPendsDetailHeaderService,
    InternalPendsRequestStatusChangeService,
    InternalPendsUpdatePendService,
  ],
  exports: [
    InternalPendsChangeOwnerComponent,
    InternalPendsRequestStatusChangeComponent,
    InternalPendsUpdatePendComponent,
  ],
})
export class InternalPendsModule { }
