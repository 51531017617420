import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { VrcDropdownComponent } from "./vrc-dropdown.component";

export interface IVrcDropdown extends IDynamicInputOptions {
  placeholder?: string;
  options?: SelectableInput[];
}

export class VrcDropdown extends DynamicInput implements IVrcDropdown {
  placeholder: string;
  options: SelectableInput[];

  readonly controlType = "dropdown";
  readonly componentType = VrcDropdownComponent;

  constructor(options: IVrcDropdown = {}) {
    super(options);
    this.placeholder = StringHelper.clean(options.placeholder);
    this.options = ArrayHelper.isAvailable(options.options) ? options.options : [];
  }
}
