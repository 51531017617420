import { Component, Input } from "@angular/core";
import { GridColumnDefinition } from "../models/grid-column-definition.model";
import { GridConfiguration } from "../models/grid-configuration.model";

// Primeng is forcing us to use an attribute selector.
/* tslint:disable:component-selector */
@Component({
  selector: "[app-grid-header]", // needed to apply the view to <tr> tag
  templateUrl: "./grid-header.component.html",
  styleUrls: ["../grid.component.scss"],
})
export class GridHeaderComponent {
  @Input() configuration: GridConfiguration;

  get filteredColumns(): GridColumnDefinition[] {
    return this.configuration.columns.filter(a => a.show);
  }

  get hasActionButton(): boolean {
    return this.configuration.showActionColumn;
  }

  get hasRowExpansion(): boolean {
    return this.configuration.showRowExpansionColumn;
  }

  get displayHeaderCheckbox(): boolean {
    return this.configuration.isMultipleSelection && this.configuration.showHeaderCheckbox;
  }

  trackByIndex(index, item) {
    return index;
  }
}
/* tslint:enable:component-selector */
