export class AuditPackageGenerateRequest {
  projectIds: number[];
  auditPackageItemIds: number[];
  auditPackageIds: number[];
  packageName: string;

  constructor(options: {
    projectIds: number[];
    auditPackageItemIds: number[];
    auditPackageIds: number[];
    packageName: string;
  }) {
    this.projectIds = options.projectIds;
    this.auditPackageItemIds = options.auditPackageItemIds;
    this.auditPackageIds = options.auditPackageIds;
    this.packageName = options.packageName;
  }
}
