<div class="dashboard-retrieval-container">
  <h3>{{headerTitle}}</h3>

  <div [ngClass]="{'sub-menu__container--retrieval-page': !isPsr}" *ngIf="isScheduleTabVisible">
    <div class="item">
      <a class="link"
         [ngClass]="{'active': isOverview}"
         (click)="toggleTab('overview')">
        <div class="button">
          <div class="description">Overview</div>
        </div>
      </a>
    </div>

    <div class="item">
      <a class="link"
         [ngClass]="{'active': isScheduled}"
         (click)="toggleTab('scheduled')">
        <div class="button">
          <div class="description">Schedule</div>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="isOverview" class="overviewtab section tabContent">
    <div class="dashboard-container">
      <div class="item" *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex" [ngClass]="getStatisticsClass(item)">
        <div *ngIf="item.key !== 'Special Handling'">
          <span>{{item.key}}</span><br>
          <a class="users-land-stats-item bold">
            <span (click)="getStatisticsFilter(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
          </a>
        </div>
      </div>
      <div *ngIf="!isSpecialHandling" class="specialHandling">
        <div *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex">
          <div class="item" *ngIf="item.key === 'Special Handling'" [ngClass]="getStatisticsClass(item)">
            <span>{{item.key}}</span><br>
            <a class="users-land-stats-item bold">
              <span (click)="getStatisticsFilter(item.key)" [ngClass]="getClass(item)">{{item.value}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="getClassForColumn()">
      <app-address-grid [stateName]="stateName"
                        [url]="url"
                        [additionalFilters]="additionalFilters"
                        [additionalBulkActions]="additionalBulkActions"
                        [isSelectionModeMultiple]="isGridMultiSelect"
                        [isLoadOnInit]="isLoadOnInit"
                        [showViews]="true"
                        [viewAttributeId]="viewAttributeId"
                        [retrievalType]="retrievalType"
                        [displayCheckedItemsInGridView]="displayCheckedItemsInGridView">
      </app-address-grid>
    </div>

  </div>

  <div *ngIf="isScheduled && !isPsr" class="userdetails section tabContent">
    <div class="provider-header bold grids-display">
      <h3>{{headerTitle}} USERS</h3>
    </div>

    <app-basic-grid class="grids-display"
                    [configuration]="retrievalUserListGridConfiguration"
                    [data]="retrievalUserListGridData"
                    [(selection)]="retrievalUserListGridSelection">
    </app-basic-grid>

    <div>
      <h3>{{headerTitle}} APPOINTMENTS</h3>

      <app-server-grid [configuration]="appointmentsGridConfiguration"
                       [(request)]="searchAppointmentsRequest"
                       [refresh]="appointmentGridRefresh"
                       [(selection)]="appointmentsGridSelection"
                       [actionTemplate]="appointmentActionTemplate"
                       [filterTemplate]="filterForm">

        <ng-template #appointmentActionTemplate let-rowData let-localActionPopover="actionPopover">
          <div class="action-template-container">
            <app-button *ngFor="let actionItem of appointmentActionItems; trackBy: trackByIndex"
                        [text]="actionItem.actionText"
                        (onClick)="appointmentGridActions[actionItem.actionFunctionName](rowData); localActionPopover.hide();">
            </app-button>
          </div>
        </ng-template>

        <ng-template #filterForm let-form>
          <form [formGroup]="form" class="ui-modal-width">
            <app-tab-menu orientation="left">

              <app-tab-panel header="Assigned To">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
                </div>
              </app-tab-panel>

            </app-tab-menu>
          </form>
        </ng-template>

      </app-server-grid>
    </div>
  </div>

  <retrieval-schedule-appointment-modal *ngIf="isAppointmentModalVisible"
                                        [(visible)]="isAppointmentModalVisible"
                                        [retrievalType]="retrievalType"
                                        [appointment]="selectedRowData"
                                        (onHide)="resetModalProperties()"
                                        (onSuccess)="updatePageAfterAppointmentSave()">
  </retrieval-schedule-appointment-modal>

  <retrieval-delete-appointment-modal [(visible)]="isCancelConfirmationModalVisible"
                                      [appointment]="selectedRowData"
                                      (onYes)="cancelAppointment()"
                                      (onCancel)="resetModalProperties()">
  </retrieval-delete-appointment-modal>
</div>
