import { HttpBackend, HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../core/environment.tokens";

@Injectable({
  providedIn: "root",
})
export class InternetSpeedService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    handler: HttpBackend,
    private readonly http: HttpClient) {
    this.http = new HttpClient(handler);
  }

  getFileContent(): Observable<string> {
    const url = `${this.baseApiUrl}utility/internet/speedtest`;
    return this.http.get(url) as Observable<string>;
  }

}
