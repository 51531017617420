import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-risk-nlp-dashboard-overview",
  templateUrl: "./risk-nlp-dashboard-overview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskNLPDashboardOverviewComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.RISK_NLP_DASHBOARD_OVERVIEW;

  constructor() {
    this.filters = ["Projects", "Hcc", "Address", "DOS", "Products", "LOB", "RetrievalOwner"];
  }
}
