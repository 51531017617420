<app-modal
  header="Edit Pend"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
  (onShow)="open()"
  (onHide)="close()"
  (onCancel)="close()">

  <div>Pend Ids: {{ chasePendIdsText }}</div>

  <br />

  <div class="input-container">
    <div [hidden]="pendCodesInput.hidden" class="input">
      <form-factory
        [formGroup]="form"
        [model]="pendCodesInput"
        (onChange)="checkIfInvoicePendCode()">
      </form-factory>
    </div>

    <div [hidden]="input.hidden" class="input" *ngFor="let input of inputs; trackBy: trackByIndex">
      <form-factory [formGroup]="form" [model]="input"></form-factory>
    </div>
  </div>

  <form-factory [formGroup]="form" [model]="noteInput"></form-factory>

  <app-pend-notes-grid *ngIf="visible" [chasePendIds]="chasePendIds"></app-pend-notes-grid>

  <footer>
    <app-button class="header-button" text="Save" (onClick)="save()"></app-button>
  </footer>
</app-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isCancelConfirmationModalVisible"
  header="Close the Chase(s)?"
  (onYes)="savePend()"
  (onCancel)="cancelConfirmation($event)">
  Closing the Pend(s) will also close the associated Chase(s). Those chases will no longer continue to be worked. Do you want to proceed?
</app-confirmation-modal>

<app-confirmation-modal
  class="confirmation"
  [(visible)]="isResolveConfirmationModalVisible"
  header="Resolve the Pend(s)?"
  (onYes)="savePend()"
  (onCancel)="cancelConfirmation($event)">
  Resolving the Pend(s) will let work continue on the Chase(s). This should be done once the obstacle preventing the Chase(s) from advancing has been resolved. Do you want to proceed?
</app-confirmation-modal>

