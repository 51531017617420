export class EmitState {

  constructor(
     readonly eventName: string,
     readonly eventContent: any


  ) {

    this.eventName = eventName || "";
    this.eventContent = eventContent;

  }
}
