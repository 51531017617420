<div class="sub-menu__container">
  <div class="item">
    <a class="link" [ngClass]="{'active': selectedTab === 'contacts'}">
      Contacts
    </a>
  </div>
</div>
<div>
  <div>
    <div class="user-search__content--input">
      <form-factory class="user-search__searchform" [formGroup]="searchFormGroup" [model]="userSearchInput"
        (keyup.enter)="onSearch()">
      </form-factory>
    </div>
    <div class="user-search__content--button">
      <app-button class="search-bar__item search-bar__item--icon search-bar__item--search-icon"
        classes="transparent small" icon="play fa-search" (onClick)="onSearch()">
      </app-button>
    </div>
  </div>

  <div class="user-list" *ngIf="userList && this.selectedTab === 'contacts'">
    <ng-container *ngFor="let item of userList; let i = index; trackBy: trackByIndex">
      <h5 class="user-list__heading">{{item.groupName}}s</h5>
      <div class="user-list__title" *ngFor="let user of item.userChatGroup; let i = index; trackBy: trackByIndex">
        <div class="user-list__circles">
          {{getNameInitials(user.name)}}
        </div>
        <div class="user-list__details">
          <div class="status">
          </div>
          <div class="user-list__listTitle">
            <h5 class="user-list__name">{{getFullName(user.name)}}</h5>
            <span class="user-list__role">{{user.primaryRoleName}}</span>
          </div>
          <div class="user-list__buttons">
            <app-icon iconStyle="fas" iconName="comment-alt"></app-icon>
            <app-icon iconStyle="fas" iconName="phone-alt"></app-icon>

          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>
