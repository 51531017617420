import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";

export const mapOverreadFeedbackQueryItem = (automapper: IAutoMapper): void => {
  automapper.createMap("default", "OverreadFeedbackQueryItem")
    .forMember("chaseId", o => o.chaseId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("abstractorUserId", o => o.abstractorUserId)
    .forMember("abstractorUserName", o => o.abstractorUserName)
    .forMember("abstractionDate", o => o.abstractionDate)
    .forMember("overreaderUserId", o => o.overreaderUserId)
    .forMember("overreaderUserName", o => o.overreaderUserName)
    .forMember("overreadDate", o => o.overreadDate)
    .forMember("errorCount", o => o.errorCount)
    .forMember("errorReviewCompletedCount", o => o.errorReviewCompletedCount)
    .forMember("errorReviewToBeCompletedCount", o => o.errorReviewToBeCompletedCount)
    .forMember("abstractorReceiptStatus", o => o.abstractorReceiptStatus)
    .forMember("overreaderReceiptStatus", o => o.overreaderReceiptStatus)
    .forMember("status", o => o.status)
    .forMember("recordCount", o => o.recordCount);
};

export class OverreadFeedbackQueryItem {
  chaseId: number;
  measureCode: string;
  abstractorUserId: number;
  abstractorUserName: string;
  abstractionDate: Date;
  overreaderUserId: number;
  overreaderUserName: string;
  overreadDate: Date;
  managerUserId: number;
  managerUserName: string;
  errorCount: number;
  errorReviewCompletedCount: number;
  errorReviewToBeCompletedCount: number;
  abstractorReceiptStatus: string;
  overreaderReceiptStatus: string;
  status: string;
  recordCount: number;

  constructor(options: Partial<OverreadFeedbackQueryItem>) {
    this.chaseId = options.chaseId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.abstractorUserId = options.abstractorUserId;
    this.abstractorUserName = StringHelper.clean(options.abstractorUserName);
    this.abstractionDate = options.abstractionDate;
    this.overreaderUserId = options.overreaderUserId;
    this.overreaderUserName = StringHelper.clean(options.overreaderUserName);
    this.overreadDate = options.overreadDate;
    this.managerUserId = options.managerUserId;
    this.managerUserName = StringHelper.clean(options.managerUserName);
    this.errorCount = options.errorCount;
    this.errorReviewCompletedCount = options.errorReviewCompletedCount;
    this.errorReviewToBeCompletedCount = options.errorReviewToBeCompletedCount;
    this.abstractorReceiptStatus = StringHelper.clean(options.abstractorReceiptStatus);
    this.overreaderReceiptStatus = StringHelper.clean(options.overreaderReceiptStatus);
    this.status = StringHelper.clean(options.status);
    this.recordCount = options.recordCount;
  }
}
