<div class="ui-md-12 bulk-outreach_container ui-toolbar-group-left" *ngIf="showInputs">
  <div class="bulk-outreach_container__description">
    Bulk Outreach actions are easy to execute. Simply select the "Action Type" and follow the prompts.
  </div>
  <div class="ui-md-3 ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroup"
                    [model]="actionTypeInput" (onChange)="updateActionTypeLabel()"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory [formGroup]="formGroup"
                    [model]="from"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12" [hidden]="!isActionTypeFax">
      <form-factory [formGroup]="formGroup"
                    [model]="faxNumberInput"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory class="control control--group" [formGroup]="formGroupOption"
                    [model]="formOptions" (onChange)="onChangeFormOptionsInput()"></form-factory>

    </div>
    <div *ngIf="showControl">
      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="showChaseBox">
        <form-factory [formGroup]="dataChaseIds" (onChange)="validateChaseIds(isValidChaseIds)" [model]="chaseIds"></form-factory>     
      </div>

      <div *ngIf="!showChaseBox">
        <app-button classes="small" icon="filter" styleType="pi"
                    type="pi" text="SELECT {{actionType}} CRITERIA" (onClick)="showFilters()"></app-button>
      </div>

      <div *ngIf="hasProjectFilters && !showChaseBox" class="filter-container">
        PROJECTS:
        <div class="chip__filter">
          <div class="chip"
               *ngFor="let filter of projectsFilters; let i = index; trackBy: trackByIndex"
               (click)="delete($event, i, 'project')">
            <div class="chip__item chip__item--name">
              {{ filter.text }}:&nbsp;
            </div>
            <div class="chip__item chip__item--value">
              {{ filter.value }}
            </div>
            <div class="chip__item chip__item--icon">
              <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="hasStatusFilters && !showChaseBox" class="filter-container">
        STATUS:
        <div class="chip__filter">
          <div class="chip"
               *ngFor="let filter of statusFilters; let i = index; trackBy: trackByIndex"
               (click)="delete($event, i, 'status')">
            <div class="chip__item chip__item--name">
              {{ filter.text }}:&nbsp;
            </div>
            <div class="chip__item chip__item--value">
              {{ filter.value }}
            </div>
            <div class="chip__item chip__item--icon">
              <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="hasPendCodesFilters && !showChaseBox" class="filter-container">
        PEND CODES:
        <div class="chip__filter">
          <div class="chip"
               *ngFor="let filter of pendCodesFilters; let i = index; trackBy: trackByIndex"
               (click)="delete($event, i, 'pend')">
            <div class="chip__item chip__item--name">
              {{ filter.text }}:&nbsp;
            </div>
            <div class="chip__item chip__item--value">
              {{ filter.value }}
            </div>
            <div class="chip__item chip__item--icon">
              <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div [ngClass]="showChaseBox ? 'bulk-outreach_button-container' : 'bulk-outreach_button-container button-container'" *ngIf="showControl">
      <span>
        <app-button *ngIf="((hasProjectFilters || hasPendCodesFilters || hasStatusFilters) || hasChaseIds)" class="button" text="RESET" (onClick)="resetValues()"></app-button>
        <app-button class="query-button" *ngIf="(hasProjectFilters || hasPendCodesFilters || hasStatusFilters) && !showChaseBox" text="RUN QUERY" (onClick)="runQuery()" [disabled]="!isFormInvalid"></app-button>
        <app-button *ngIf="showChaseBox && isValidChaseIds" class="query-button" text="VALIDATE CHASES" (onClick)="onValidate()"></app-button>
        <app-button *ngIf="isDisableChaseBox" text="EDIT" (onClick)="onEdit()"></app-button>
      </span>
    </div>

  </div>
  
  <div *ngIf="showQueryResults" class="ui-md-3 ui-md-offset-3 ui-toolbar-group-left">
    <div class="header">{{ queryHeaderText }} RESULTS</div>
    <div class="ui-g-12 ui-md-12 ui-lg-12 query-results">

      <div class="label ui-md-12 ui-toolbar-group-left"> <span class="ui-md-6 ui-toolbar-group-left"> TYPE </span> <span class="ui-md-6 ui-toolbar-group-left"> DETAILS</span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> PROJECTS </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? projectCount : bulkOutReachQueryResult.projectCount}}</span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> AIDs </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? masterDocumentSourceIdCount : bulkOutReachQueryResult.addressCount}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> CHASES </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? chaseCount : bulkOutReachQueryResult.chaseCount}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> SENDER </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? senderName : bulkOutReachRequest.bulkOutreachParameter.senderName}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row" [hidden]="isActionTypeMail"> <span class="ui-md-6 ui-toolbar-group-left"> FAX FROM NO. </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? fromFaxNumber : bulkOutReachRequest.bulkOutreachParameter.fromFaxNumber}}</span></div>

      <div *ngIf="!isShowValidationIssues" class="ui-md-11 ui-toolbar-group-left query-results__cover-letter">
        <form-factory [formGroup]="coverTemplateFromGroup" [model]="coverLetterTemplate"></form-factory>
      </div>
      <div class="ui-md-12 ui-toolbar-group-left text-center">
        <div class="errorText" *ngIf="isShowValidationIssues">{{ validationIssuesCount }} validation issues found</div>
        <app-button *ngIf="!isShowValidationIssues" text="SUBMIT {{actionType}} REQUEST" (onClick)="submitBulkFaxRequest()"></app-button>
        <app-button *ngIf="isShowValidationIssues" text="DOWNLOAD VALIDATION ISSUES" (onClick)="downloadValidationIssues()"></app-button>
      </div>

    </div>
  </div>

</div>


<div class="bulk-outreach_submission_container" *ngIf="!showInputs">
  <div class="bulk-outreach_submission_container__title">Your {{actionType}} submission was successful!</div>
  <div class="bulk-outreach_submission_container__description">Feel free to check back anytime to see the status of your pending {{actionType}}.</div>
  <div>
    <span class="bulk-outreach_submission_container__button"><app-button text="START A NEW BULK ACTION" (onClick)="newBulkAction()"></app-button></span>
    <span class="bulk-outreach_submission_container__button"><app-button text="VIEW PENDING ACTIONS" (onClick)="pendingActions()"></app-button></span>
    <span class="bulk-outreach_submission_container__button"><app-button text="VIEW ARCHIVE" (onClick)="viewArchive()"></app-button></span>
  </div>
  <div class="ui-md-3 ui-toolbar-group-left query-results submission-box">
      <div class="label ui-md-12 ui-toolbar-group-left"> <span class="ui-md-6 ui-toolbar-group-left"> TYPE </span> <span class="ui-md-6 ui-toolbar-group-left"> DETAILS</span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> PROJECTS </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? projectCount : bulkOutReachQueryResult.projectCount}}</span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> AIDs </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? chaseCount : bulkOutReachQueryResult.addressCount}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> CHASES </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? chaseCount : bulkOutReachQueryResult.chaseCount}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> SENDER </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? senderName : bulkOutReachRequest.bulkOutreachParameter.senderName}} </span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__text query-results__row" [hidden]="isActionTypeMail"> <span class="ui-md-6 ui-toolbar-group-left"> FAX FROM NO. </span> <span class="ui-md-6 ui-toolbar-group-left"> {{hasChaseIds ? fromFaxNumber : bulkOutReachRequest.bulkOutreachParameter.fromFaxNumber}}</span></div>
      <div class="ui-md-12 ui-toolbar-group-left query-results__row"> <span class="ui-md-6 ui-toolbar-group-left"> COVER LETTER TEMPLATE </span> <span class="ui-md-6 ui-toolbar-group-left"> {{outReachRequest.coverLetterTemplateName}}</span></div>
  </div>

</div>

  <app-modal class="filter" [(visible)]="isFiltersVisible">
    <app-tab-menu orientation="left">
      <app-tab-panel header="Projects" class="checkbox-group__halves">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="projectsInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Status" class="checkbox-group__halves">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="chaseStatusInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Pend Codes" class="checkbox-group__halves">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
        </div>
      </app-tab-panel>     
    </app-tab-menu>

    <footer>
      <app-button (onClick)="resetAllFilters()" text="Reset All"></app-button>
      <app-button class="apply-button" (onClick)="applyFilters()" text="Apply"></app-button>
    </footer>
  </app-modal>
