import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-open-gaps-by-measure",
    templateUrl: "./open-gaps-by-measure.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenGapsByMeasureComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.RISK_OPEN_GAPS_BY_MEASURE;

    constructor() {
        this.filters = ["Projects", "Hcc", "Products", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "MemberKey", "Address", "Npi", "ChaseId"];
    }

}
