import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Dropdown as PrimeDropdown } from "primeng/dropdown";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { Dropdown } from "./dropdown.model";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent extends DynamicControlDirective<Dropdown> implements OnInit, AfterViewInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild("primeDropdown") primeDropdown: PrimeDropdown;
  @Input() customTemplate: TemplateRef<any>;

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.changeDetector.markForCheck()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngAfterViewInit() {
    /* tslint:disable:prefer-template */
    const superAppendOverlay = this.primeDropdown.appendOverlay.bind(this.primeDropdown);
    this.primeDropdown.appendOverlay = function () {
      superAppendOverlay();
      this.overlay.style.minWidth = this.containerViewChild.nativeElement.offsetWidth + "px";
    };
    /* tslint:enable:prefer-template */
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get classes(): string {
    return this.getClasses("dropdown");
  }

  get hasTooltip(): boolean {
    return StringHelper.isAvailable(this.model.tooltip) && this.model.hasTooltipLabel;
  }

  get clearOption(): boolean {
    return this.model.clear;
  }

  getText(item: SelectableInput): string {
    return StringHelper.isAvailable(item?.text) ? item.text : item?.label;
  }

  onSelectedItemChange(event: any): void {
    event.type = "change";
    this.onChange.emit(event);
  }
}
