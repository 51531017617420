<app-modal [(visible)]="visible"
           [header]="header"
           (visibleChange)="visibleChange.emit($event)"
           (onShow)="open()"
           (onHide)="close()">
  <div *ngIf="findAddress">Fill in any or all of the fields below to search for an address ID:</div>
  <form [formGroup]="formGroupAddressSearch">

    <div class="ui-fluid address-search">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="retrievalLocationId"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="groupName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="address1"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="contact"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="address2"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="memberFirstName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="city"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="memberLastName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <div class="statedropdown">
            <form-factory [formGroup]="formGroupAddressSearch" [model]="stateList"></form-factory>
          </div>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="memberDateOfBirth"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="zip"></form-factory>
        </div>
      </div>

    </div>
    <app-button class="header-button buttons-space-margin" [text]="addressActionText" (onClick)="addressAction()"></app-button>
    <app-button class="header-button" classes="primary-action" text="Provider Search" (onClick)="onShowProviderSearch()"></app-button>
  </form>

  <div *ngIf="noAddressSearchResultsFound" class="display-inline ui-g-12 ui-md-12">
    <div *ngIf="findAddress" class="ellipsis">No addresses found matching your search criteria.</div>
    <div>
      <app-button class="link" [text]="toggleAddressActionText" [hidden]="addressActionLinkHidden" (onClick)="toggleAction()"></app-button>
    </div>
  </div>
  <div *ngIf="findAddress" class="grids-display">
    RESULTS
    <app-basic-grid class="grids-display"
                    [configuration]="addressSearchGridConfiguration"
                    [data]="addressSearchData"
                    [(selection)]="addressGridselection">
    </app-basic-grid>
  </div>

  <div [hidden]="!notesVisible">
    <div class="ellipsis notes" *ngIf="notesMissing">Notes is required for Chase Move and should be between 4 - 1000 characters.</div>
    <textarea class="notes-textarea" placeholder="Enter Notes for Chase Move." [(ngModel)]="notesText" (keyup)="onNotesTextChange()"></textarea>
    <form-factory [formGroup]="formGroupAddressSearch" [model]="inheritChaseInput" *ngIf="isMoveChaseVisible"></form-factory>
  </div>
  <footer>
    <app-button class="header-button" text="USE THIS ADDRESS" (onClick)="confirmChaseMove()" [disabled]="isGotoAddressDetailDisabled"></app-button>
  </footer>
  <app-provider-search-tool *ngIf="providerToolVisible"
                            [(visible)]="providerToolVisible"
                            [callProvider]="false">
  </app-provider-search-tool>

</app-modal>

