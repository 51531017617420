<app-collapsible-modal 
  class="feedback-modal"
  [(visible)]="visible" 
  header="OVERREAD FEEDBACK" 
  rejectText="CLOSE" 
  [allowClose]="false">

  <form class="feedback-modal__toggle" [formGroup]="toggleForm" *ngIf="!isRisk20">
    <form-factory
      [formGroup]="toggleForm"
      [model]="dataEntryToggle"
      (onChange)="onToggle($event.checked)">
    </form-factory>
  </form>

  <app-basic-grid 
    [hidden]="feedbackCompleted"
    class="feedback-modal__grid"
    [configuration]="gridConfiguration"
    [data]="gridData"
    [isAnimationDisabled]="false"
    [rowExpandTemplate]="rowExpandTemplate"
    (rowClick)="onRowClick($event)">

    <ng-template #reviewColumn let-rowData>
      <div *ngIf="statusInReview; else feedback">
        <app-button 
          [ngClass]="acceptButtonClass(rowData.chaseOverreadFeedbackId)"
          classes="small success" 
          text="ACCEPT" 
          (onClick)="onAccept($event, rowData.chaseOverreadFeedbackId)">
        </app-button>
        <app-button 
          [ngClass]="challengeButtonClass(rowData.chaseOverreadFeedbackId)"
          classes="small danger" 
          text="CHALLENGE" 
          (onClick)="onChallenge($event, rowData.chaseOverreadFeedbackId)">
        </app-button>
      </div>

      <ng-template #feedback>
        <app-button 
          [ngClass]="reviewButtonClass(rowData.chaseOverreadFeedbackId)"
          classes="small" 
          text="REVIEW FEEDBACK" 
          (onClick)="onOpenReview($event, rowData.chaseOverreadFeedbackId)">
        </app-button>
      </ng-template>
    </ng-template>

    <ng-template #rowExpandTemplate let-rowData>
      <div *ngIf="statusInReview; else acknowledgement">
        <app-basic-grid 
          [ngStyle]="{'display': getIvaRiskStyle(rowData.abstractorData)}"
          class="feedback-modal__grid--events"
          [ngClass]="isRiskOrIva ? 'feedback-modal__grid--ivaevents' : ''"
          [configuration]="abstractorGridConfiguration"
          [data]="getTemplateData(rowData.abstractorData)">
        </app-basic-grid>
  
        <app-basic-grid 
          [ngStyle]="{'display': getIvaRiskStyle(rowData.overreaderData)}"
          class="feedback-modal__grid--events"
          [ngClass]="isRiskOrIva ? 'feedback-modal__grid--ivaevents' : ''"
          [configuration]="overreaderGridConfiguration"
          [data]="getTemplateData(rowData.overreaderData)">
        </app-basic-grid>
  
        <form class="form feedback-modal__grid--challenge" [formGroup]="feedbackForm">
          <form-factory
            [formGroup]="feedbackForm"
            [model]="rowModel(rowData.chaseOverreadFeedbackId)">
          </form-factory>
        </form>
        <app-button 
          class="submit-btn"
          *ngIf="isRowModelValid(rowData.chaseOverreadFeedbackId)"
          text="SUBMIT CHALLENGE" 
          (onClick)="onChallengeSubmit(rowData.chaseOverreadFeedbackId)">
        </app-button>
      </div>

      <ng-template #acknowledgement>
        <form class="form" [formGroup]="challengeReasonForm">
          <form-factory
            [formGroup]="challengeReasonForm"
            [model]="challengeReasonRowModel(rowData.chaseOverreadFeedbackId)">
          </form-factory>
        </form>
        <form class="form" [formGroup]="challengeFeedbackForm">
          <form-factory
            [formGroup]="challengeFeedbackForm"
            [model]="challengeFeedbackModel(rowData.chaseOverreadFeedbackId)">
          </form-factory>
        </form>
        <app-button 
          class="submit-btn"
          classes="success"
          text="FEEDBACK RECEIVED" 
          (onClick)="onAcknowledgeSubmit(rowData.chaseOverreadFeedbackId)">
        </app-button>
      </ng-template>
    </ng-template>
  </app-basic-grid>

  <footer *ngIf="feedbackCompleted">
    <div class="footer-text">Thank you for reviewing your feedback!</div>
    <app-button text="CLOSE" (onClick)="onClose()"></app-button>
    <app-button *ngIf="isGetNextAvailable" classes="danger" text="GET NEXT" (onClick)="onGetNext()"></app-button>
  </footer>
</app-collapsible-modal>