import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { MemberCentricChase } from "../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { TimelineItem } from "../../../../shared/timeline/timeline-item.model";
import { DynamicEntity } from "../../../api/member-validation/dynamic-entity.model";
import { EntityType } from "../../../api/member-validation/entity-type.enum";
import { DiagnosisService } from "../chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/diagnosis.service";
import { DocumentViewerSessionService } from "../chase-detail-v2/document-viewer-session.service";
import { Diagnosis } from "./chase-detail-chart/risk/diagnosis/diagnosis.model";
import { Encounter } from "./chase-detail-chart/risk/encounter/encounter.model";
import { ChaseDetailState } from "./chase-detail-state.model";

@Injectable()
export class ChaseDetailStateService {

  constructor(
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly diagnosisService: DiagnosisService) {
  }

  private eveDxTempData = new BehaviorSubject<any[]>([]);
  eveDxTempData$ = this.eveDxTempData.asObservable();

  private pIsChaseBeyondMrr = new BehaviorSubject<boolean>(false);
  isChaseBeyondMrr = this.pIsChaseBeyondMrr.asObservable();

  private pMemberChases = new BehaviorSubject<MemberCentricChase[]>([]);
  memberChases = this.pMemberChases.asObservable();

  submitTabIsEncounterSelected = new BehaviorSubject<any>(null);
  submitTabIsDiagnosisSelected = new BehaviorSubject<any>(null);

  isEncounterSelected = new BehaviorSubject<Encounter>(null);
  isEncounterSelected$: Observable<Encounter> = this.isEncounterSelected.asObservable();

  selectedFilters = new BehaviorSubject<any>(null);
  encounterCoderDxDetails = new BehaviorSubject<any>(null);

  isDiagnosisSelected = new BehaviorSubject<any>(null);
  encounterDetails = new BehaviorSubject<any>(null);
  isFilterButtonSelected = new BehaviorSubject<boolean>(null);
  selectedTab = new BehaviorSubject<string>(null);

  private encounterSelectionEvent = new BehaviorSubject<any>(null);
  encounterSelectionEvent$ = this.encounterSelectionEvent.asObservable();

  private selectedEncounterEvent$ = new ReplaySubject<Encounter>(1);

  private autoSelectFilterButton = new Subject<boolean[]>();
  autoSelectFilterButton$ = this.autoSelectFilterButton.asObservable();

  get selectedEncounter$(): Observable<Encounter> {
    return this.selectedEncounterEvent$.asObservable();
  }

  private isOverreadToggle = new BehaviorSubject<any>(null);
  isOverreadToggle$ = this.isOverreadToggle.asObservable();

  private isDataEntryClicked = new BehaviorSubject<any>(null);
  isDataEntryClicked$ = this.isDataEntryClicked.asObservable();

  private forceEncounterSelection = new Subject<boolean>();
  forceEncounterSelection$ = this.forceEncounterSelection.asObservable();

  resetMoveBackToggle = new BehaviorSubject<any>(false);
  resetMoveBackToggleFromHeader = new BehaviorSubject<any>(null);
  inititateProcessForMoveBackChasePages = new BehaviorSubject<any>(false);
  // TODO: Figure out state management for chase detail page (including chart stuff).
  state = new BehaviorSubject<ChaseDetailState>(new ChaseDetailState());
  timelineItems = new BehaviorSubject<TimelineItem[]>([]);
  isPreviousButtonClicked = new BehaviorSubject<boolean>(false);
  hasPageChanged = this.isPreviousButtonClicked.asObservable();

  thumbnailLabelFilterByDiagnosis = new BehaviorSubject<Diagnosis>(null);

  private isEncounterValidated = new BehaviorSubject<any>(null);
  isEncounterValidated$ = this.isEncounterValidated.asObservable();

  private encounterValidationResults: any = {};

  // Shouldn't updating the state work via observables?
  setData(data: Partial<ChaseDetailState>): void {
    const newData = new ChaseDetailState({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  updateEveDxTempData(data: any): void {
    this.eveDxTempData.next(data);
  }
  setMemberValidationEntity(memberValidationEntity: DynamicEntity): void {
    const entities = this.state.value.entities;
    const memberValidationEntityIndex = entities.findIndex(a => a.entityTypeId === EntityType.MEMBER_VALIDATION);
    if (memberValidationEntityIndex < 0) {
      entities.push(memberValidationEntity);
    } else {
      entities[memberValidationEntityIndex] = memberValidationEntity;
    }
    this.setData({ entities });
  }

  clearData(): void {
    this.state.next(new ChaseDetailState());
    this.timelineItems.next([]);
  }

  setPageChangeValue(isPageChanged: boolean): void {
    this.isPreviousButtonClicked.next(isPageChanged);
  }

  chaseBeyondMrr(value: boolean) {
    this.pIsChaseBeyondMrr.next(value);
  }

  setMemberChases(value: MemberCentricChase[]) {
    this.pMemberChases.next(value);
  }

  dataEntryClicked(encounter: Encounter): void {
    this.diagnosisService.toggleDiagnosisSelected(false);
    this.documentViewerSessionService.setNoEvidenceSelected(false);
    this.documentViewerSessionService.updateDataEntryPageNumberEncounterSelected(encounter.pageNumber, encounter.encounterFound);
    this.isDataEntryClicked.next(true);
    this.setEncounterSelectionEvent(encounter);
  }

  triggerEncounterSelection(val: boolean): void {
    this.forceEncounterSelection.next(val);
  }

  setEncounterSelectionEvent(encounter: Encounter): void {
    if (this.isEncounterSelected) {
      this.selectedEncounterEvent$.next(encounter);
    }
    if (this.isEncounterSelected && encounter.hasOwnProperty("startDate") && encounter.hasOwnProperty("endDate")) {
      this.encounterSelectionEvent.next([encounter.startDate, encounter.endDate]);
    }
  }

  setEncounterSelected(selectedEncounter: Encounter): void {
    this.isEncounterSelected.next(selectedEncounter);
  }

  setOverreadToggleStatus(status: string): void {
    this.isOverreadToggle.next(status);
  }

  setEncounterValidated(encounterId: number, status: boolean): void {
    this.encounterValidationResults[encounterId] = { status };
    this.isEncounterValidated.next(this.encounterValidationResults);
  }

  setFilterButtonSelection(value: boolean[]): void {
    this.autoSelectFilterButton.next(value);
  }
}
