import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SessionService } from "../../../../core/storage/session.service";
import { DocumentPagesViewed } from "../../../../shared/document/document-page-viewer/document-pages-viewed.model";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { DocumentPage } from "../../retrieval/retreival-document-review/document-page.model";
import { CatalyticNumerator } from "../chase-detail/chase-detail-chart/nlp-hedis/catalytic-numerator.model";


@Injectable({
  providedIn: "root",
})
export class DocumentViewerSessionService {
  private readonly PREFIX = "DOCUMENT_VIEWER";
  catalyticNumeratorState = new BehaviorSubject<CatalyticNumerator>(new CatalyticNumerator());
  catalyticDeletedNumerators = new BehaviorSubject<string[]>(null);
  catalyticSavedNumeratorSet = new BehaviorSubject<string[]>(null);
  catalyticDeletedNumeratorSet = new BehaviorSubject<string[]>(null);

  private dataEntryPageNumber = new Subject<number>();
  dataEntryPageNumber$: Observable<number> = this.dataEntryPageNumber.asObservable();

  private currentPageNumber = new BehaviorSubject<number>(0);
  currentPageNumber$ = this.currentPageNumber.asObservable();

  selectedDiagnosisIcdDescription = new BehaviorSubject<string>(null);
  isMemberValidated = new BehaviorSubject<boolean>(null);

  private isNoEvidenceFilterSelected = new BehaviorSubject<boolean>(false);
  isNoEvidenceFilterSelected$: Observable<boolean> = this.isNoEvidenceFilterSelected.asObservable();

  private updatedDocumentPageIds = new Subject<number[]>();
  updatedDocumentPageIds$: Observable<number[]> = this.updatedDocumentPageIds.asObservable();

  constructor(private session: SessionService) { }

  setCurrentPageNumber(page: number): void {
    this.currentPageNumber.next(page);
  }

  updateDocumentPageIds(documentIds: number[]) {
    this.updatedDocumentPageIds.next(documentIds);
  }

  add(sourceId: number, documentId: number, { documentTypeId, pageNumber }: DocumentPage): void {
    const state = this.get(sourceId, documentId, documentTypeId);
    state[pageNumber] = true;
    this.put(sourceId, documentId, documentTypeId, state);
  }

  remove(sourceId: number, documentId: number, documentTypeId: number, startPage: number, endPage: number): void {
    const oldState = this.get(sourceId, documentId, documentTypeId);
    const numberOfPagesDeleted = endPage - startPage + 1;

    const newState = Object.keys(oldState).reduce((accNewState, key) => {
      const pageNumber = Number(key);
      if (NumberHelper.isLessThan(pageNumber, startPage)) {
        accNewState[pageNumber] = oldState[pageNumber];
      } else if (NumberHelper.isGreaterThan(pageNumber, endPage)) {
        const newPageNumber = pageNumber - numberOfPagesDeleted;
        accNewState[newPageNumber] = oldState[pageNumber];
      }

      return accNewState;
    },                                            new DocumentPagesViewed());

    this.put(sourceId, documentId, documentTypeId, newState);
  }

  valid(sourceId: number, documentId: number, documentTypeId: number, totalPages: number): boolean {
    const state = this.get(sourceId, documentId, documentTypeId);
    const pagesViewed = Object.values(state);
    return this.validating(pagesViewed, totalPages);
  }

  validPages(sourceId: number, documentId: number, documentTypeId: number, startPage: number, endPage: number): boolean {
    const totalPages = endPage - startPage + 1;

    const state = this.get(sourceId, documentId, documentTypeId);
    const pagesViewed = Object.keys(state).reduce((accPagesViewed, key) => {
      const pageNumber = Number(key);
      if (
        NumberHelper.isGreaterThan(pageNumber, startPage, true) &&
        NumberHelper.isLessThan(pageNumber, endPage, true)
      ) {
        accPagesViewed.push(state[pageNumber]);
      }

      return accPagesViewed;
    },                                            []);

    return this.validating(pagesViewed, totalPages);
  }

  hasKey(sourceId: number, documentId: number, { documentTypeId }: DocumentPage): boolean {
    const key = this.getKey(sourceId, documentId, documentTypeId);
    const state = this.session.get<any>(key, null);
    const hasKey = state != null && typeof state === "object";
    return hasKey;
  }

  delete(sourceId: number, documentId: number, documentTypeId: number): void {
    const key = this.getKey(sourceId, documentId, documentTypeId);
    this.session.delete(key);
  }

  updateCatalyticNumeratorState(numerator: CatalyticNumerator): void {
    this.catalyticNumeratorState.next(numerator);
  }

  updateCatalyticDeletedNumerators(deletedNumeratorIds: string[]): void {
    this.catalyticDeletedNumerators.next(deletedNumeratorIds);
  }

  updateCatalyticSavedNumeratorSet(savedNumeratorIds: string[]): void {
    this.catalyticSavedNumeratorSet.next(savedNumeratorIds);
  }

  updateCatalyticDeletedNumeratorSet(deletedNumeratorIds: string[]): void {
    this.catalyticDeletedNumeratorSet.next(deletedNumeratorIds);
  }

  updateDataEntryPageNumber(pageNumber: number): void {
    this.dataEntryPageNumber.next(pageNumber);
  }

  updateDataEntryPageNumberEncounterSelected(pageNumber: number, encounterFound: string, isDiagnosisSelected: boolean = false): void {
    if (NumberHelper.isAvailable(pageNumber)
      && StringHelper.isAvailable(encounterFound)
      && !isDiagnosisSelected) {
      this.dataEntryPageNumber.next(pageNumber);
    }
  }

  updateSelectedIcdDescription(icdDescription: string): void {
    this.selectedDiagnosisIcdDescription.next(icdDescription);
  }

  updateIsMemberValidated(isMemberValidated: boolean): void {
    this.isMemberValidated.next(isMemberValidated);
  }

  setNoEvidenceSelected(isSelected: boolean): void {
    this.isNoEvidenceFilterSelected.next(isSelected);
  }

  private getKey(sourceId: number, documentId: number, documentTypeId: number): string {
    const cleanSourceId = Number(sourceId);
    const cleanDocumentId = Number(documentId);
    const cleanDocumentTypeId = Number(documentTypeId);
    if (
      NumberHelper.isLessThan(cleanSourceId, 1)
      || NumberHelper.isLessThan(cleanDocumentId, 1)
      || NumberHelper.isLessThan(cleanDocumentTypeId, 1)
    ) {
      throw new Error(`The sourceId, documentId, and documentTypeId have to be greater than 0.`);
    }

    const key = `${this.PREFIX}_sid_${cleanSourceId}_id_${cleanDocumentId}_tid_${cleanDocumentTypeId}`;
    return key;
  }

  private get(sourceId: number, documentId: number, documentTypeId: number): DocumentPagesViewed {
    const key = this.getKey(sourceId, documentId, documentTypeId);
    const state = this.session.get<DocumentPagesViewed>(key, {});
    return state;
  }

  private put(sourceId: number, documentId: number, documentTypeId: number, state: DocumentPagesViewed): void {
    const key = this.getKey(sourceId, documentId, documentTypeId);
    this.session.put(key, state);
  }

  private validating(pagesViewed: boolean[], totalPages: number): boolean {
    if (pagesViewed.length !== totalPages) {
      return false;
    }

    const pagesViewedTrue = pagesViewed.every(value => value);
    return pagesViewedTrue;
  }
}
