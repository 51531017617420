<app-basic-grid 
[configuration]="configuration" 
[data]="data"
[actionTemplate]="actionTemplate">

<ng-template #actionTemplate>
  *Coming Soon
</ng-template>

</app-basic-grid>
