import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "../../../auth/auth.service";
import { UserToken } from "../../../auth/user-token.model";

@Injectable()
export class HomePageUrlService implements CanActivate {

  user: UserToken;
  homePageUrl = "";

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    this.user = this.authService.user;
    this.homePageUrl = this.authService.defaultUserLandingUrl;
    this.router.navigateByUrl(this.homePageUrl);

    return false;
  }
}
