import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { IOnUpload } from "../../../../../../../shared/upload/upload.component";
import { AccessInfoViewEditService } from "../../../../access-info-view-edit/access-info-view-edit.service";

@Component({
  selector: "retrieval-address-detail-info-access-docs",
  templateUrl: "./address-detail-info-access-docs.component.html",
  styleUrls: ["./address-detail-info-access-docs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoAccessDocsComponent {
  @Input() addressId: number;

  constructor(
    private service: AccessInfoViewEditService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService
  ) { }

  uploadDocument(event: IOnUpload): void {
    this.service.upload(event.formData, this.addressId).subscribe(
      data => {
        this.service.refreshFunction();
        this.changeDetector.markForCheck();
        this.messagingService.showToast("Access Info document(s) Uploaded successfully.", SeverityType.SUCCESS);
        event.success();
      },
      err => {
        this.messagingService.showToast("Error while uploading Document, please try again.", SeverityType.ERROR);
      }
    );
  }
}
