<div class="document-page-viewer">
  <app-document-page-thumbnails *ngIf="!showExpandedView"
  [ngClass]="{'smallView': showChartThumbnails}"
    (toggleThumbnailViewClicked)="toggleThumbnailView($event)" class="document-page-viewer__thumbnails" [ngStyle]="{display: isDuplicateWindowOpen ?'none':''}"
    [chaseId]="chaseId" [currentChartPageNumber]="currentPageNumber" [isMaximized]="isMaximized"></app-document-page-thumbnails>
  <app-document-page-thumbnail-expand *ngIf="showExpandedView && showChartThumbnails && !isSplitScreenMainTab"
    (toggleThumbnailViewClicked)="toggleThumbnailView($event)" [ngClass]="{'expandedView': showExpandedView}"
    class="document-page-viewer__thumbnails"
    [chaseId]="chaseId"
    [addressId]="addressId"
    [projectId]="projectId"
    [isMaximized]="isMaximized"
    (updateTotalPages)="updateTotalPages($event)"
    [currentChartPageNumber]="currentPageNumber"></app-document-page-thumbnail-expand>
  <app-document-page-thumbnail-expand-labeled *ngIf="isSplitScreenMainTab"
    [ngClass]="{'expandedView': isSplitScreenMainTab}"
    class="document-page-viewer__thumbnails"
    [chaseId]="chaseId"
    [addressId]="addressId"
    [projectId]="projectId"
    [isMaximized]="isMaximized"
    [currentChartPageNumber]="currentPageNumber"></app-document-page-thumbnail-expand-labeled>
  <div class="document-page-viewer__chart" [ngClass]="{'expandedView': showExpandedView}" >
    <div class="command-bar" id="commandBar">
      <div class="command-bar__item command-bar__item--extraSpacer" *ngIf="isSplitScreenEnabled">
        <app-button *ngIf="isPopOutBtnEnabled"
                    class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="external-link-alt"
                    styleType="far" (onClick)="openSplitScreenURL()">
        </app-button>
      </div>
      <div #ocrSearchContainer *ngIf="isOcrSearchEnabled" class="command-bar__item">
        <div #ocrSearchInput class="command-bar__item command-bar__item--ocr-input" (click)="preventHide($event)">
          <form-factory [formGroup]="searchPhraseForm" [model]="searchPhraseInput"
                        (onFocus)="onOcrSearchPhraseFocus(ocrSearchInput, ocrSearchResults)">
          </form-factory>
        </div>
        <app-button *ngIf="showOcrSearchIcon"
                    class="command-bar__item command-bar__item--left command-bar__item--ocr-search-icon"
                    classes="transparent x-small" icon="play fa-search" [disabled]="!isOcrFocus"
                    (onClick)="fetchOcrMatches(ocrSearchResults)">
        </app-button>
        <app-button *ngIf="!showOcrSearchIcon"
                    class="command-bar__item command-bar__item--left command-bar__item--ocr-search-icon"
                    classes="transparent x-small" icon="times-circle" (onClick)="resetOcrSearchInput()">
        </app-button>
        <div #ocrSearchResults hidden class="ocr-results" (click)="preventHide($event)">
          <div *ngIf="isOcrSearchInProgress; else elseBlock" class="ocr-results__search">
            search in progress...
          </div>
          <ng-template #elseBlock>
            <div *ngIf="!isOcrSearchInProgress && !hasOcrSearchResults" class="ocr-results__search">
              No Matches Found.
            </div>
            <div *ngIf="hasOcrSearchResults" class="ocr-results__description">
              <div class="command-bar__item">
                <b>Page Results</b>: {{ ocrDocumentResultsSummary }}
              </div>
              <div class="command-bar__item command-bar__item--traversing-btns command-bar__item--float-right">
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                            classes="transparent x-small" icon="play fa-flip-horizontal" (onClick)="gotoPrevOCRMatchPage()">
                </app-button>
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--right"
                            classes="transparent x-small" icon="play" (onClick)="gotoNextOCRMatchPage()">
                </app-button>
              </div>
            </div>

            <div *ngIf="hasOcrSearchResults" class="ocr-results__summary">
              <div class="command-bar__item">
                <b>Results per Page</b>: {{ ocrDocumentPageWordsSummary }}
              </div>
              <div class="command-bar__item  command-bar__item--traversing-btns command-bar__item--float-right">
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                            classes="transparent x-small" icon="play fa-flip-horizontal" (onClick)="gotoPrevOCRMatchPageWord()">
                </app-button>
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--right"
                            classes="transparent x-small" icon="play" (onClick)="gotoNextOCRMatchPageWord()">
                </app-button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div #nlpSearchContainer *ngIf="isNlpSearchEnabled" class="command-bar__item">
        <div #nlpSearchInput class="command-bar__item command-bar__item--nlp-input" (click)="preventHide($event)">
          <form-factory [formGroup]="nlpPhraseForm" [model]="nlpPhraseInput">
          </form-factory>
        </div>
        <app-button class="command-bar__item command-bar__item--left command-bar__item--ocr-search-icon"
                    classes="transparent x-small" icon="times-circle" (onClick)="deactivateNlp()">
        </app-button>

        <div #nlpSearchResults class="nlp-results" (click)="preventHide($event)">
          <div *ngIf="isNlpSearchInProgress; else nlpSearchComplete" class="nlp-results__search">
            nlp search in progress...
          </div>
          <ng-template #nlpSearchComplete>
            <div *ngIf="!isNlpSearchInProgress && !hasNlpMatches" class="ocr-results__search">
              No Supporting Evidence Found.
            </div>
            <div *ngIf="hasNlpMatches" class="ocr-results__description">
              <div class="command-bar__item">
                {{nlpDescription}}
              </div>
              <div class="command-bar__item command-bar__item--traversing-btns  command-bar__item--float-right">
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                            icon="play fa-flip-horizontal" (onClick)="gotoPrevNlpMatchPageWord()">
                </app-button>
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--right" icon="play"
                            (onClick)="gotoNextNlpMatchPageWord()">
                </app-button>
              </div>
            </div>
            <div *ngIf="hasNlpMatches" class="nlp-results__summary">
              <div class="command-bar__item">
                {{nlpWordSummary}} <div class="nlp-results__DOS">
                  DOS: <span [ngClass]="getNlpDosClass"
                             (click)="gotoCurrentDiagnosisDosPage(nlpDosPageNumberOfCurrentDiagnosis);">{{nlpDosOfCurrentDiagnosis}}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="command-bar__item command-bar__item--spacer"></div>
      <app-button *ngIf="!isReadOnly && annotationsEnabled" [ngClass]="highlightBtnClass"
                  class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="highlighter"
                  styleType="far" [disabled]="isMaximized" (onClick)="toggleAnnotation()">
      </app-button>
      <div class="command-bar__item command-bar__item--btn-spacer"></div>
      <app-button *ngIf="isNlpEnabled" [ngClass]="negativeExclusionBtnClass"
                  class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="exclamation-triangle"
                  styleType="far" [disabled]="isMaximized" (onClick)="getNlpData('negative_exclusion')">
      </app-button>
      <div class="command-bar__item command-bar__item--btn-spacer"></div>
      <app-button *ngIf="isNlpEnabled && displayOcrBlocks" [ngClass]="ocrMappingBtnClass"
                  class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="draw-square"
                  styleType="far" [disabled]="isMaximized" (onClick)="getOcrMappingData()">
      </app-button>
      <div class="command-bar__item command-bar__item--btn-spacer"></div>
      <app-button *ngIf="isNlpEnabled" [ngClass]="memberBtnClass"
                  class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="user-alt"
                  styleType="far" [disabled]="isMaximized" (onClick)="getMemberData()">
      </app-button>


      <div class="command-bar__item command-bar__item--float-right">
        <div *ngIf="isDataEntryWorkflow" class="command-bar__item" title="Click Optimize to generate a higher resolution document.">
          <app-button [disabled]="!enableOptimizeImageButton" class="command-bar__item command-bar__item--optimize" text="Optimize"
                      (onClick)="optimizeChart()">
          </app-button>
        </div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--left" icon="plus"
                    (onClick)="zoomIn()">
        </app-button>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--right" icon="minus"
                    (onClick)="zoomOut()">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button *ngIf="!isReadOnly" class="command-bar__item command-bar__item--icon command-bar__item--left"
                    icon="undo-alt" [disabled]="disableChartFunctions" (onClick)="rotate('left')">
        </app-button>
        <app-button *ngIf="!isReadOnly" class="command-bar__item command-bar__item--icon command-bar__item--right"
                    icon="redo-alt" [disabled]="disableChartFunctions" (onClick)="rotate('right')">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <div class="command-bar__item">
          <form-factory class="command-bar__item command-bar__item--page-number-input"
                        [formGroup]="currentPageNumberForm" [model]="currentPageNumberInput" (onChange)="onGotoPage($event)">
          </form-factory>
          <span>&nbsp;of&nbsp;{{ totalPages }}</span>
        </div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                    icon="play fa-flip-horizontal" [disabled]="!isCurrentPageLoaded" (onClick)="prevPage()">
        </app-button>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--right" icon="play"
                    [disabled]="!isCurrentPageLoaded" (onClick)="nextPage()">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
      </div>
    </div>
    <div id="pageViewer">
      <cdk-virtual-scroll-viewport #viewport class="document"  [ngClass]="{ 'max': isMaximized, 'expand': this.menuService.showHeader }" [itemSize]="itemSizeHeight" [minBufferPx]="minBuffer"
                                   [maxBufferPx]="maxBuffer"
                                   (document:keydown.ArrowRight)="nextPage()" (document:keydown.ArrowLeft)="prevPage()">

                                   <div class="document__page" [ngClass]="{'scroll-item': isWindowOpen}" [style.height.px]="itemSizeHeight"
             *cdkVirtualFor="let page of pages$; templateCacheSize: 0; trackBy: trackByIndex;">
          <app-document-page-annotations *ngIf="annotationToggle && hasPageAndSource(page)"
                                         class="document__page__annotations" [isReadOnly]="isReadOnly" [status]="status" [imageWidth]="imageWidthSize"
                                         [documentPageId]="page.documentPageId" [pageAnnotations]="getPageAnnotations(page.documentPageId)"
                                         [zoomStyle]="zoomStyle" [canvasHeight]="canvasHeight" [canvasWidth]="canvasWidth" [zoomPercentage]="zoomPercentage" [margin]="margin" (onChange)="saveDocumentAnnotations($event)">
          </app-document-page-annotations>
          <app-document-hover-highlights *ngIf="annotationToggle && hasPageAndSource(page)"
            [hoverAnnotations]="getPageAnnotations(page.documentPageId)"
            [chasePageAnnotations]="getPageAnnotationsForChase(page.documentPageId)"
            [canvasHeight]="canvasHeight"
            [canvasWidth]="canvasWidth"
            [chaseId]="chaseId"
            [imageWidth]="imageWidthSize"
            [zoomPercentage]="zoomPercentage"
            [documentPageId]="page.documentPageId"
            [zoomStyle]="zoomStyle"
            [margin]="margin"
            [isReadOnly]="isReadOnly"
            [pageNumber]="page.pageNumber"
            (highlightToDelete)="highlightToDelete($event)">
          </app-document-hover-highlights>
          <div class="text-center">
            <img cdkDrag *ngIf="hasPageAndSource(page)" class="document__page__image" [src]="getSource(page, page.source)"
               [ngStyle]="zoomStyle" id="documentsImg"/>
          </div>
          <ng-container *ngIf="hasOcrWordsAndDocumentMetaData(page)">
            <div *ngFor="let word of getOcrWords(page); trackBy: trackByIndex;" [ngStyle]="getWordStyles(word)"></div>
          </ng-container>
          <ng-container *ngIf="hasNlpWordsAndDocumentMetaData(page)">
            <div *ngFor="let word of getNlpWords(page); trackBy: trackByIndex;" [ngStyle]="getNlpWordStyles(word)">
            </div>
          </ng-container>
          <ng-container *ngIf="hasMemberNlpWordsAndDocumentMetaData(page)">
            <app-document-page-highlight-member [page]="page" [nlpData]="memberNlpMatch">
            </app-document-page-highlight-member>
          </ng-container>
          <ng-container *ngIf="hasOcrMappingNlpWordsAndDocumentMetaData(page)">
            <app-document-page-highlight-ocr-mapping [zoomPercentage]="zoomPercentage" [page]="page" [nlpData]="ocrMappingNlpMatch">
            </app-document-page-highlight-ocr-mapping>
          </ng-container>
          <ng-container *ngIf="hasDosNlpWordsAndDocumentMetaData(page)">
            <app-document-page-highlight-dos [zoomPercentage]="zoomPercentage" [page]="page" [nlpData]="dosNlpMatch">
            </app-document-page-highlight-dos>
          </ng-container>

        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
