
<form-factory [formGroup]="form" [model]="firstNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="lastNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="dobSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="genderSaveGroup" (onChange)="handleChange($event)"></form-factory>

<h3>NDC Information</h3>
<form-factory [formGroup]="form" [model]="memberStandardGroup" class="rxcp__member-container1"></form-factory>
<form-factory [formGroup]="form" [model]="claimIdSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="prescriptionFillSaveGroup" (onChange)="handleChange($event)"></form-factory>
<form-factory [formGroup]="form" [model]="nationalDrugCodeSaveGroup" (onChange)="handleChange($event)"></form-factory>
