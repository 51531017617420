<!-- TODO: Use DocumentListComponent? -->
<ul [ngClass]="display">
  <li *ngFor="let item of retrievalDocumentItems; let i = index; trackBy: trackByIndex">
    <div class="document-list-image-container">
      <a (click)="downloadFile(item.documentPath)">
        <app-icon iconName="file" class="fileIcon"></app-icon>
      </a>
    </div>
    <div class="document-list-info-container" [ngClass]="display">
      <a (click)="downloadFile(item.documentPath)">
        <div class="descr">
          {{item.fileName}}
        </div>
      </a>
    </div>
    <!--<div class="document-list-createDate">
      Uploaded By: {{item.createUser}}
    </div>
    <div class="document-list-createDate">
      Uploaded Date: {{item.createDate}}
    </div>-->
  </li>
</ul>
