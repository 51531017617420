import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridService } from "../grid.service";
import { GridMenuDirective } from "./grid-menu-component.model";

@Component({
  selector: "app-grid-menu",
  templateUrl: "./grid-menu.component.html",
  styleUrls: ["./grid-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicGridMenuComponent extends GridMenuDirective {
  @Input() data: any[] = [];
  @Input() filterHeaderText = "";
  @Input() filterFooterText = "";
  @Input() isMemberQuery = false;
  @Input() onlyChartsInput = false;
  @Input() requestUrl = "";
  @Input() exportDataFromUrl = false;
  @Input() urlOptions:  any;
  @Input() addressId: number;
  displayPageControls = false;
  @Output() memberChartEmit: EventEmitter<boolean> = new EventEmitter();
  constructor(
    service: GridService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClient
  ) {
    super(service);
    this.isClearFilterButtonVisible = false;
  }


  sortGrid(): void {
    this.data.sort(this.sortBySortField);
  }

  filterGrid(): void {
    this.configuration.filterGridFn();
  }

  export(): void {
    if (this.exportDataFromUrl && StringHelper.isAvailable(this.requestUrl)) {
      this.exportFromRequest();
    } else {
      this.primeGrid.exportCSV({ selectionOnly: this.hasSelectedItems });
    }
    this.selectedItems = [];
    this.primeGrid.selectionChange.emit(this.selectedItems);
  }

  private exportFromRequest(): void {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = this.requestUrl;

    const options = {
      ...this.urlOptions,
    };

    if (url.includes("chase")) {
      if (this.hasSelectedItems) {
        options.chaseIds = this.selectedItems.map(s => s.chaseId);
      } else if (NumberHelper.isAvailable(this.addressId)) {
        options.documentSourceId = this.addressId;
        options.startRecord = 0;
        options.endRecord = 100000;
      }
    } else {
      options.startRecord = 0;
      options.endRecord = 1000000;
      options.IsExportFromDatabase = true;
    }
    options.localTimeZone = localTimeZone;
    this.primeGrid.loading = true;

    this.changeDetector.markForCheck();
    this.http
      .post(url, options)
      .pipe(finalize(() => {
        this.primeGrid.loading = false;
        this.changeDetector.markForCheck();
      }))
      .subscribe((data: any) => {
        const savePrevData = this.primeGrid.value;
        this.primeGrid.value = ArrayHelper.isAvailable(data) ? data : [];
        this.primeGrid.exportCSV();
        this.primeGrid.value = savePrevData;
      });
  }

  get filterForm(): FormGroup {
    // Not Implemented
    return null;
  }

  clearFilters(): void {
    // not implemented
  }

  closeFilters(): void {
    this.primeGrid.reset();
    this.filterGrid();
    this.isFiltersVisible = false;
  }

  clearUnusedFilters(): void {
    // not implemented
  }

  get isFilterFormValid(): boolean {
    return true;
  }


  private sortBySortField = (a: any, b: any): number => {
    const aField = a[this.sortField];
    const bField = b[this.sortField];

    let sortValue = 0;
    if (aField < bField) {
      sortValue = -1;
    } else if (aField > bField) {
      sortValue = 1;
    }

    return sortValue * this.isAscending;
  }

  memberChart(event: boolean) {
    this.memberChartEmit.emit(event);
  }
}
