import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class ClinicalChaseListItem {
  readonly chaseId: number;
  readonly lastName: string;
  readonly firstName: string;
  readonly dateOfBirth: string;
  readonly measureCode: string;
  readonly reviewPeriod: string;
  readonly status: string;

  constructor(options: {
    chaseId: number;
    lastName: string;
    firstName: string;
    dateOfBirth: string;
    measureCode: string;
    reviewPeriod: string;
    status: string;
  }) {
    this.chaseId = options.chaseId;
    this.lastName = StringHelper.clean(options.lastName);
    this.firstName = StringHelper.clean(options.firstName);
    this.dateOfBirth = StringHelper.clean(options.dateOfBirth);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.reviewPeriod = StringHelper.clean(options.reviewPeriod);
    this.status = StringHelper.clean(options.status);
  }
}
