import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { filter } from "rxjs/operators";
import { SubSink } from "subsink";
import { BooleanHelper } from "../../../../../../../../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../../utilities/contracts/object-helper";
import { ChaseDetailStateService } from "../../../../../../../chase-detail/chase-detail-state.service";

@Component({
  selector: "member-risk-validation",
  templateUrl: "./validation.component.html",
  styleUrls: ["./validation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private isValidated: boolean;
  private isClearedOut: boolean;
  private selectedEncounterId: number;

  @Input() textPositive = "Yes";
  @Input() textNegative = "No";
  @Input() isEnabled = false;
  @Output() onValidated = new EventEmitter<{ isValidated: boolean; isClearedOut: boolean }>();

  constructor(
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  get yesButton(): string {
    return this.isValidated ? "yes-btn yes-btn__active" : "yes-btn";
  }

  get noButton(): string {
    return BooleanHelper.isAvailable(this.isValidated) && !this.isValidated ? "no-btn no-btn__active" : "no-btn";
  }

  ngOnInit() {
    this.sink.add(

      this.chaseDetailStateService.isEncounterSelected$
        .pipe(filter(encounter => !ObjectHelper.isEmpty(encounter)))
        .subscribe(encounter => this.selectedEncounterId = encounter.encounterId),

      this.chaseDetailStateService.isEncounterValidated$
        .pipe(filter(validation => !ObjectHelper.isEmpty(validation) && NumberHelper.isAvailable(this.selectedEncounterId)))
        .subscribe(validation => {
          this.isValidated = validation[this.selectedEncounterId]?.status;
          this.changeDetector.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onSelection(event: MouseEvent, isPositive: boolean): void {
    event.preventDefault();
    this.isClearedOut = this.isValidated === isPositive;
    this.isValidated = isPositive && !this.isClearedOut;
    this.onValidated.emit({ isValidated: this.isValidated, isClearedOut: this.isClearedOut });
  }
}
