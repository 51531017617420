<div>
  <div class="div-header bold"> ACCESS INFORMATION</div>
  <div class="header-button">
    <app-button text="Edit Access Info" (onClick)="isEditAccessInfoModalVisible=true"></app-button>
  </div>
</div>
<div class="clearfix"></div>
<div class="summary-container">
  <div class="summary-detail" *ngFor="let item of accessInfoSummaryItems; trackBy: trackByIndex">
    <div *ngIf="item.key != 'Notes'">
      <div class="summary-key bold">{{item.key}}</div>
    </div>
    <!--<div class="summary-key bold">{{item.key}}</div>-->
    <div class="summary-value"><a *ngIf="item.hasUrlh" [routerLink]="item.url">{{item.value}}</a>{{!item.hasurl ? item.value : ""}}</div>
  </div>

  <div class="access-info-document-container">
    <div class="header-padding bold"> Access Forms</div>
    <retrieval-document-list [retrievalId]="retrievalId"></retrieval-document-list>
  </div>

  <div class="upload-button-container">
    <div class="bottom-left-container">
      <p-fileUpload #fileUpload name="file[]"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="onFileRemove($event)"
                    (uploadHandler)="uploadDocument($event, fileUpload)"
                    customUpload="true"
                    accept="image/*,.txt,.doc,.docx,.xls,.xlsx,.pdf,.zip,.xml,.pptx"
                    maxFileSize="1000000000"
                    chooseLabel="Upload"
                    uploadLabel="Submit"
                    [showUploadButton]="true">
      </p-fileUpload>
    </div>
  </div>
</div>



<app-modal [(visible)]="isEditAccessInfoModalVisible" header="EDIT ACCESS INFORMATION">
    <form [formGroup]="formGroup">
      <div class="ui-g ui-fluid basic-filter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="contactNameAdmin"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="phoneAdmin"></form-factory>
          </div>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="contactNameIT"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="phoneIT"></form-factory>
          </div>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="emrSystem"></form-factory>
          </div>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroup" [model]="editNotes"></form-factory>
        </div>
      </div>
    </form>
    <footer>
      <app-button class="footer-button" text="SAVE" (onClick)="onUpdateAccessInfo()"></app-button>
      <app-button class="footer-button" text="CANCEL" (onClick)="cancelAccessInfo()"></app-button>
    </footer>
</app-modal>
