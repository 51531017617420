export enum PageTypeName {

  MedicalRecord = 1,
  Attestation = 2,
  CoverSheet = 3,
  Invoice = 4,
  PrePayInvoice = 5,
  ProviderMessage = 6,
  GeneratedCoverSheet = 7,
  Ignore = 8,
  Unassigned = 9,
  QA = 10,
  QAInvoice = 11,
  Approval= 12,
  ChaseQuery= 13,
  ChaseDetail= 14,
  AddressDetail= 15,

}
