import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ContactMethodType } from "./contact-method-type.enum";
import { ContactRequest } from "./contact-request.model";

export class EmailRequest extends ContactRequest {
  readonly toLabel = "Email";
  readonly type = ContactMethodType.Email;

  get toValue(): string {
    return StringHelper.clean(this.to.toString());
  }

  get isValid(): boolean {
    const toAsString = this.to.toString();

    return StringHelper.isAvailable(toAsString);
  }
}
