import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";

export class DataLoadState {
  clientName: string;
  dataLoadId: number;
  displaySummary: boolean;
  loadType: string;
  isLoadProcess: boolean;
  projectId: number;
  projectName: string;
  projectType: string;

  constructor(options: Partial<DataLoadState> = {}) {
    this.clientName = options.clientName;
    this.dataLoadId = options.dataLoadId;
    this.displaySummary = BooleanHelper.tryGet(options.displaySummary, false);
    this.loadType = options.loadType;
    this.isLoadProcess = BooleanHelper.tryGet(options.isLoadProcess, true);
    this.projectId = options.projectId;
    this.projectName = options.projectName;
    this.projectType = options.projectType;
  }

  get isFull(): boolean {
    return this.loadType === "Full";
  }

  get isIncremental(): boolean {
    return this.loadType === "Incremental";
  }

  get isAdminRefresh(): boolean {
    return this.loadType === "AdminRefresh";
  }

  get isDelete(): boolean {
    return this.loadType === "Delete";
  }

}
