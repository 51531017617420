import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { GridPipeName } from "../../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { DateFormats } from "../../../../../../../utilities/contracts/helper-types";
import { ChaseSearchRequest } from "../../../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { AddressDetailStateService } from "../../../address-detail-state.service";

@Component({
  selector: "retrieval-address-detail-info-grids-pursuits",
  templateUrl: "./address-detail-info-grids-pursuits.component.html",
  styleUrls: ["./address-detail-info-grids-pursuits.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailInfoGridsPursuitsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private pageSize = 25;
  private gridSearchRequest: any;
  pursuitsGridConfiguration = new GridConfiguration();
  pursuitsGridData: any[] = [];
  assignedTo: number;
  addressId: number;
  startRecord: number;
  endRecord: number;

  constructor(
    private chaseService: ChaseSearchService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressId = state.masterDocumentSourceId;

        if (state.hasMasterDocumentSourceId) {
          this.gridSearchRequest = new ChaseSearchRequest(
            null, null, null, null, this.addressId, null, null, null, null,
            null, null, null, null, null, null, null, null, "PursuitChase");

          this.chaseService
            .chaseSearch(this.gridSearchRequest)
            .subscribe(items => {
              this.pursuitsGridData = items as any;
              this.changeDetector.markForCheck();
            });
        }
      })
    );
    this.createGrids();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  createGrids(): void {
    this.pursuitsGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "memberGender", header: "M/F" }),
      new GridColumnDefinition({ field: "serviceProviders", header: "Provider Name" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "documentRequestId", header: "Doc Request ID" }),
      new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "chartCommitmentDate", header: "Commit", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
    ];

    this.pursuitsGridConfiguration.pageSize = this.pageSize;
    this.pursuitsGridConfiguration.selectionMode = "multiple";
    this.pursuitsGridConfiguration.showActionColumn = false;
  }
}
