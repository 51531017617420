import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridView } from "../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../../../../shared/grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridStateService } from "../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { ListItem } from "../../../../shared/list/list-item";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { TagType } from "../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { StatesListService } from "../../../api/states-list/states-list.service";
import { ClinicalPageService } from "../../clinical/clinical-page/clinical-page.service";
import { CLINICAL_PENDS_GRID, PLAN_LIST, PRODUCT_LIST, RETRIEVAL_PENDS_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../member/chase-detail/chase-detail-chart/chart.service";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";

@Component({
  selector: "pend-internal-pends-listing",
  templateUrl: "./internal-pends-listing.component.html",
  styleUrls: ["./internal-pends-listing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsListingComponent implements OnInit, OnDestroy {

  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  private sink = new SubSink();
  refreshViews = new EventEmitter<GridView>(true);
  views: GridViewsState;
  gridRefresh = new EventEmitter<null>();
  gridConfigurationModel = new GridConfiguration();
  searchRequest: GridRequest;
  serverGridSelection: any;
  isChangeOwnerView = false;
  pendGrid: ServerGridComponent;
  chasePendIds: number[] = [];
  isAdvanceFilter = false;
  pendType: string;
  readonly filterDropdownModel: any[] = [];
  readonly filterTextBoxModel: any[] = [];
  readonly chaseIdModel: any[] = [];
  readonly addressIdModel: any[] = [];
  statisticsFilter = "Site";
  selectedStat: string;
  isChangeOwnerDisabled = true;
  pendId: number;
  retrievalFilterInput: Textbox;
  getProject: any[];
  getPendCode: any[];
  form: FormGroup;
  readonly Projects: Textbox;

  checkBoxSet: CheckboxGroup;
  PendCode: CheckboxGroup;
  Owner: Radiobutton;
  AID: Textbox;
  ChaseId: Textbox;
  chasePendId: Textbox;
  filters: any[];
  pendStatistics = List<ListItem>();
  isClientRole = false;
  isStatusChangeView = false;
  actions: BulkAction[];
  isLandingFilterApplied = false;
  previousUrl: string;
  pendOwners: string[];
  allAvailableTags: SelectableInput[];
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  tagType = TagType.CHASE;
  searchTextChanged = new Subject<string>();

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseId");
  }
  get providerInput(): Textbox {
    return this.getInput("providerName");
  }
  get npiInput(): Textbox {
    return this.getInput("nationalProviderId");
  }
  get addressIdInput(): Textbox {
    return this.getInput("MasterDocumentSourceId");
  }
  get ownerInput(): Radiobutton {
    return this.getInput("Owner");
  }
  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }
  get pendCodesInput(): CheckboxGroup {
    return this.getInput("PendCodes");
  }
  set pendCodesInput(value: CheckboxGroup) {
    this.setInput("PendCodes", value);
  }
  get pendSeverityInput(): CheckboxGroup {
    return this.getInput("PendSeverityIds");
  }
  set pendSeverityInput(value: CheckboxGroup) {
    this.setInput("PendSeverityIds", value);
  }
  get pendStatusInput(): CheckboxGroup {
    return this.getInput("PendsStatus");
  }
  set pendStatusInput(value: CheckboxGroup) {
    this.setInput("PendsStatus", value);
  }
  get assignedToInput(): Autocomplete {
    return this.getInput("AssignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.setInput("AssignedToUserId", value);
  }
  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasureIds");
  }
  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasureIds", value);
  }
  get addressGroupNameInput(): Textbox {
    return this.getInput("GroupName");
  }
  get addressCityInput(): Textbox {
    return this.getInput("City");
  }
  get companyIdToInput(): Autocomplete {
    return this.getInput("CompanyId");
  }
  set companyIdToInput(value: Autocomplete) {
    this.setInput("CompanyId", value);
  }
  get addressStateInput(): Autocomplete {
    return this.getInput("State");
  }
  set addressStateInput(value: Autocomplete) {
    this.setInput("State", value);
  }
  get productsInput(): Autocomplete {
    return this.getInput("Product");
  }
  set productsInput(value: Autocomplete) {
    this.setInput("Product", value);
  }

  get plansInput(): CheckboxGroup {
    return this.getInput("Plans");
  }
  set plansInput(value: CheckboxGroup) {
    this.setInput("Plans", value);
  }

  get pendIdInput(): Textbox {
    return this.getInput("chasePendId");
  }

  get stateName(): string {
    return this.isClinicalPend ? CLINICAL_PENDS_GRID.attributeCode : RETRIEVAL_PENDS_GRID.attributeCode;
  }

  get viewAttributeId(): number {
    return this.isClinicalPend ? CLINICAL_PENDS_GRID.attributeId : RETRIEVAL_PENDS_GRID.attributeId;
  }

  get isClinicalPend(): boolean {
    return this.pendType !== "retrieval";
  }

  get sampleComplianceInput(): CheckboxGroup {
    return this.getInput("SampleComplianceCodesAsCsv");
  }
  set sampleComplianceInput(value: CheckboxGroup) {
    this.setInput("SampleComplianceCodesAsCsv", value);
  }

  get headerTitle(): string {
    return this.isClinicalPend ? "Clinical Pends" : "Retrieval Pends";
  }

  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  get memberFirstNameInput(): Textbox {
    return this.getInput("MemberFirstName");
  }

  get memberLastNameInput(): Textbox {
    return this.getInput("MemberLastName");
  }

  get memberDobInput(): Textbox {
    return this.getInput("MemberDob");
  }

  get memberKeyInput(): Textbox {
    return this.getInput("MemberSourceAliasID");
  }

  constructor(
    private createPendService: CreatePendService,
    private readonly router: Router,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly formService: FormService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private authService: AuthService,
    private readonly tagService: TagService,
    private messagingService: MessagingService,
    private retrievalService: RetrievalPageService,
    private readonly gridStateService: GridStateService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly automapper: AutomapperService,
    private readonly userService: UserService,
    private stateService: StatesListService,
    private readonly chartService: ChartService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private parameterService: ParameterService,
    private gridViewsService: GridViewsService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.isClientRole = this.authService.user.directoryRoleIds.indexOf(31) > -1;
      this.pendType = this.parameterService.getNormal("pendType", "");

      this.getProjects();
      this.getPendCodeSet();
      this.getMeasures();
      this.getAssignedToUsers();
      this.getCompanies();
      this.getStateListForAddress();
      this.getProducts();
      this.getPlans();
      this.createGrid();
      this.loadGridDataSource("Site");
      this.showFiltersOrGetData();
      this.getComplianceCodes();
      this.getTagSearchOperator();
      if (!this.isClinicalPend) {
        this.getRetrievalStats();
      }
    });

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView)),
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getAllTagsList(searchText)))
          .subscribe()
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private createGrid(): void {

    this.gridConfigurationModel.stateName = this.stateName;

    this.gridConfigurationModel.columns = [
      new GridColumnDefinition({ field: "chasePendId", header: "Pend ID", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "pendSeverityName", header: "Pend Severity", show: this.isClinicalPend }),
      new GridColumnDefinition({ field: "pendTypeDescription", header: "Pend Description", title: "pendTypeDescription" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "memberSourceAliasID", header: "Member Key" }),
      new GridColumnDefinition({ field: "owner", header: "Owner" }),
      new GridColumnDefinition({ field: "plan", header: "Health Plan" }),
      new GridColumnDefinition({ field: "createdBy", header: "Created By" }),
      new GridColumnDefinition({ field: "assignedToUser", header: "Assigned To" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
      new GridColumnDefinition({ field: "providerNameAsCsv", header: "Provider"}),
      new GridColumnDefinition({ field: "nationalProviderIdAsCsv", header: "NPI"}),
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
      new GridColumnDefinition({ field: "addressGrouping", header: "AID Group" }),
      new GridColumnDefinition({ field: "pendCompanyName", header: "Invoice Company" }),
      new GridColumnDefinition({ field: "amount", header: "Invoice Amount" }),
      new GridColumnDefinition({ field: "product", header: "Product" }),
      new GridColumnDefinition({ field: "groupName", header: "Address Group" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "createdDate", header: "Creation Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "lastUpdatedDate", header: "Last Updated", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
      new GridColumnDefinition({ field: "taxPayerId", header: "TIN" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB" }),

    ];
    this.gridConfigurationModel.pageSize = 25;
    this.gridConfigurationModel.selectionMode = "multiple";
    this.gridConfigurationModel.showActionColumn = true;
    this.gridConfigurationModel.filterGridFn = this.onApplyFilter;
    this.gridConfigurationModel.showViews = true;
    this.gridConfigurationModel.viewAttributeId = this.viewAttributeId;

    this.actions = [
      new BulkAction({
        name: "CHANGE OWNER & ASSIGN",
        action: () => this.changeOwnerAssignDialog(),
      }),

      new BulkAction({
        name: this.isClientRole ? "UPDATE STATUS" : "CHANGE STATUS",
        action: () => this.isClientRole ? this.requestStatusChangeDialog() : this.updateStatusDialog(),
      }),
    ];

    this.getViews();
    this.filterForm();
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.gridConfigurationModel.showViews) {
      this.gridViewsService.get(this.gridConfigurationModel.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  getRetrievalStats() {
    this.createPendService.getRetrievalPendStatisticsData().subscribe(retrievalStats => {
      this.pendStatistics = List(retrievalStats);
      this.changeDetector.markForCheck();
    });
  }

  loadGridDataSource(statisticsFilter: string) {
    const memberValidator = [
      Validators.minLength(2),
      Validators.maxLength(100),
    ];
    const memberErrorMessage = {
      minlength: `Minimum 2 characters and Maximum 100 characters allowed`,
      maxlength: `Minimum 2 characters and Maximum 100 characters allowed`,
    };

    this.searchRequest = new GridRequest({
      url: `${this.baseApiUrl}pends`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "searchFilterOption",
          value: "1",
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "IsClinical",
          value: this.isClinicalPend.toString(),
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ChaseId",
          name: "Chase Id",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "providerName",
          name: "Provider",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "nationalProviderId",
          name: "NPI",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MasterDocumentSourceId",
          name: "AID",
        }),

        new GridFilter({
          input: new Radiobutton({
            options: [
              new SelectableInput({ text: "Organization", value: "Organization" }),
              new SelectableInput({ text: "Client", value: "Client" }),
            ],
          }),
          key: "Owner",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Projects",
        }),
        new GridFilter({
          input: new CheckboxGroup({showTooltip: true}),
          key: "PendCodes",
          name: "Pend Codes",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "PendSeverityIds",
          name: "Pend Severity",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "PendsStatus",
          name: "Pend Status",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select User..." }),
          key: "AssignedToUserId",
          name: "Assigned To",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "MeasureIds",
          name: "Measures",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select Company..." }),
          key: "CompanyId",
          name: "Invoice Company",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select State..." }),
          key: "State",
          name: "State",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "GroupName",
          name: "Group Name",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "City",
          name: "City",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select Product..." }),
          key: "Product",
          name: "Product",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ClientId",
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "chasePendId",
          name: "Pend ID",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "SampleComplianceCodesAsCsv",
          name: "Sample Compliance",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Plans",
          name: "Plans List",
        }),
        new GridFilter({
          input: new TagSearchMultiselect({ placeholder: "Tags" }),
          key: "TagIdsAsCsv",
          name: "Tags",
        }),
        new GridFilter({
          input: new Dropdown(),
          key: "TagSearchOperator",
          value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
            ? this.selectedTagSearchOperator : null,
          showChip: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MemberFirstName",
          name: "First Name",
          validators: memberValidator,
          errorMessages: memberErrorMessage,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MemberLastName",
          name: "Last Name",
          validators: memberValidator,
          errorMessages: memberErrorMessage,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MemberDob",
          name: "DOB",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "MemberSourceAliasID",
          name: "Member Key",
        }),
      ],
    });

    const pendFilter = window.sessionStorage.getItem("pendFilter");
    if (pendFilter != null) {
      const sessionFilter = JSON.parse(pendFilter);
      this.searchRequest.filters.push(new GridFilter({
        input: new Textbox(),
        key: sessionFilter[0].key,
        value: sessionFilter[0].value,
        show: false,
      }));
      window.sessionStorage.removeItem("pendFilter");
    }

    this.refreshGrid();
  }

  private showFiltersOrGetData(): void {
    let retainfilters = [];

    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName)) {
      const gridState = this.gridStateService.get(this.stateName);
      retainfilters = gridState.request.filters.filter(retainfilter => retainfilter.input.value);
    } else {
      this.gridStateService.delete(this.stateName);
      retainfilters = this.searchRequest.filters.filter(retainfilter => StringHelper.isAvailable(retainfilter.input.value));
    }

    if (retainfilters.length > 2) {
      setTimeout(() => this.gridRefresh.emit());
    } else {
      this.serverGridComponent.showFilters();
    }
  }

  onApplyFilter = () => {
    const Id = this.form.get("ChaseId").value;
    const AID = this.form.get("AID").value;
    const Projects = this.form.get("Projects").value;
    const pendCodes = this.form.get("pendCodes").value;
    const Owner = this.form.get("Owner").value;

    this.filters = [];
    if (StringHelper.isAvailable(Id)) {
      this.filters.push(new GridFilter({ key: "ChaseId", value: Id }));
    }

    if (StringHelper.isAvailable(AID)) {
      this.filters.push(new GridFilter({ key: "MasterDocumentSourceId", value: AID }));
    }

    if (StringHelper.isAvailable(Owner)) {
      this.filters.push(new GridFilter({ key: "Owner", value: Owner }));
    }

    let ProjectsName = "";
    let ProjectsId = "";
    if (Projects !== null && Projects.length !== 0) {
      for (const i of Projects) {
        ProjectsId += `${i.value},`;
        ProjectsName += `${i.text},`;
      }
      this.filters.push(new GridFilter({ key: "Projects", value: ProjectsId.slice(0, -1), text: ProjectsName }));
    }
    let PendCodeName = "";
    let PendCodeValue = "";
    if (pendCodes !== null && pendCodes.length !== 0) {
      for (const i of pendCodes) {
        PendCodeValue += `${i.value},`;
        PendCodeName += `${i.text},`;
      }
      this.filters.push(new GridFilter({ key: "PendCodes", value: PendCodeValue.slice(0, -1), text: PendCodeName }));
    }
    this.filters.push(new GridFilter({ key: "IsClinical", value: this.isClinicalPend.toString(), show: false }));
    this.search(this.filters);
    this.changeDetector.markForCheck();
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  getAllTagsList(searchText: string): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.useFilter = false;
        this.tagsInput.options = [...this.allAvailableTags];
        this.changeDetector.markForCheck();
      });
  }

  private getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }


  private getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectsInput = { ...this.projectsInput, options: result } as any;
        this.setProjectsInput();
        this.changeDetector.markForCheck();
      });
  }

  private setProjectsInput(): void {
    const projectOption = this.projectsInput;
    const projectFilter = this.searchRequest.getFilter(this.projectsInput.key);

    if (StringHelper.isAvailable(projectFilter.value)) {
      const filteredProjects = projectFilter.value.split(/\s*,\s*/);
      projectFilter.inputValue = [];
      filteredProjects.forEach(selectedProject => {
        projectFilter.inputValue.push(projectOption.options.find(a => a.value === +selectedProject));
      });
      this.formService.updateDom.next();
    }
  }

  private getPendCodeSet(): void {
    this.createPendService
      .getPendDropdown(this.isClinicalPend, 1)
      .pipe(map((result: any) => {

        const pendCodes = result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
        const severity = result.pendSeverity.map(this.automapper.curry("PendSeverity", "SelectableInput"));
        const pendStatuses = result.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
        return { pendCodes, severity, pendStatuses };
      }))
      .subscribe(({ pendCodes, severity, pendStatuses }: any) => {
        this.pendCodesInput = { ...this.pendCodesInput, options: pendCodes } as any;
        this.pendSeverityInput = { ...this.pendSeverityInput, options: severity } as any;
        this.pendStatusInput = { ...this.pendStatusInput, options: pendStatuses } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => {
        this.measuresInput = { ...this.measuresInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private refreshGrid() {
    this.gridRefresh.emit();
  }

  filterForm() {
    this.AID = new Textbox({
      key: "AID",
      label: "SELECT AID",
      placeholder: "AID",
    });
    this.ChaseId = new Textbox({
      key: "ChaseId",
      label: "SELECT CHASE ID",
      placeholder: "CHASE ID",
    });
    this.checkBoxSet = new CheckboxGroup({
      key: "Projects",
      label: "SELECT PROJECT",
      options: this.getProject,
    });

    this.PendCode = new CheckboxGroup({
      key: "pendCodes",
      label: "SELECT PROJECT",
      options: this.getPendCode,
    });

    this.chasePendId = new Textbox({
      key: "chasePendId",
      label: "SELECT Pend ID",
      placeholder: "Pend ID",
    });

    this.Owner = new Radiobutton({
      key: "Owner",
      label: "Select OWNER",
      name: "Owner",
      options: [ // options will be used to create the radio buttons. In this example, there will be four radio buttons created.
        { text: "Organization", value: "Organization" },
        { text: "Client", value: "Client" },
      ],
    });

    this.form = this.formService.createFormGroup([this.checkBoxSet, this.PendCode, this.chasePendId, this.ChaseId, this.AID, this.Owner]);
  }

  search(filters: GridFilter[]) {
    this.searchRequest = new GridRequest({
      url: `${this.baseApiUrl}pends`,
      filters,
    });
    this.refreshGrid();
  }

  changeOwnerAssignDialog(rowData?: any): void {
    let selectedRows = this.serverGridSelection;
    if (rowData !== undefined) {
      selectedRows = [];
      selectedRows.push(rowData);
    }

    this.chasePendIds = [];

    if (selectedRows && selectedRows.length > 0) {
      const projectIds = selectedRows.map(item => item.projectId)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (projectIds.length === 1) {
        selectedRows.forEach(item => {
          this.chasePendIds.push(item.chasePendId);
        });
      } else {
        this.messagingService.showToast("Selected pends are not from same project.", SeverityType.WARN);
        return;
      }

      let pendstatus = "";
      selectedRows.forEach(item => {
        if (item.pendStatus === "Resolved" || item.pendStatus === "Closed") {
          pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
        }
      });

      if (pendstatus.length > 0) {
        this.messagingService.showToast(`Selected pends having ${pendstatus.replace(/,\s*$/, "")} status cannot be assigned.`, SeverityType.WARN);
        return;
      }

      this.isChangeOwnerView = true;


    } else {
      this.messagingService.showToast("Please select at least one Pend", SeverityType.WARN);
    }
  }

  reassignUser($event) {
    if (!$event) {
      this.serverGridSelection = 0;
    }
    this.isChangeOwnerView = $event;
    this.refreshGrid();
  }

  trackByIndex(index, item) {
    return index;
  }

  private getInput<T extends DynamicInput>(key: string): T {
    if (this.searchRequest == null) {
      return null;
    }

    return this.searchRequest.getInput<T>(key);
  }

  private setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.searchRequest == null) {
      return null;
    }

    this.searchRequest.setInput<T>(key, value);
  }

  viewDetails(rowData: any) {
    const selectedRows = rowData;
    this.pendId = selectedRows.chasePendId;
    this.router.navigate(["pend", "detail", this.pendId]);
  }

  requestStatusChangeDialog(rowData?: any) {
    let selectedRows = this.serverGridSelection;
    if (rowData !== undefined) {
      selectedRows = [];
      selectedRows.push(rowData);
    }

    let pendstatus = "";
    selectedRows.forEach(item => {
      if (item.pendStatus === "Closed") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot be changed.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      this.pendOwners = [];
      selectedRows.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
        const pendOwner = item.owner === "Client" ? "C" : "O";
        this.pendOwners.push(pendOwner);
      });

      if (NumberHelper.isGreaterThan(this.pendOwners.length, 1) && !this.isAllSelectedPendOwnerIdentical) {
        this.messagingService.showToast("The status of the selected pends having different owners cannot be changed.", SeverityType.WARN);
        this.changeDetector.markForCheck();
        return;
      }
      this.isStatusChangeView = true;
    }
  }

  updateStatusDialog(rowData?: any) {
    let selectedRows = this.serverGridSelection;
    if (rowData !== undefined) {
      selectedRows = [];
      selectedRows.push(rowData);
    }

    let pendstatus = "";
    selectedRows.forEach(item => {
      if (item.pendStatus === "Closed" || item.pendStatus === "Resolved") {
        pendstatus += pendstatus.includes(item.pendStatus) ? `` : `${item.pendStatus}, `;
      }
    });

    if (pendstatus.length > 0) {
      this.messagingService.showToast(`Selected pend(s) having ${pendstatus.replace(/,\s*$/, "")} status cannot be changed.`, SeverityType.WARN);
      return;
    } else {
      this.chasePendIds = [];
      selectedRows.forEach(item => {
        this.chasePendIds.push(item.chasePendId);
      });

      this.pendOwners = [];
      selectedRows.forEach(item => {
        const pendOwner = item.owner === "Client" ? "C" : "O";
        this.pendOwners.push(pendOwner);
      });
      if (NumberHelper.isGreaterThan(this.pendOwners.length, 1) && !this.isAllSelectedPendOwnerIdentical) {
        this.messagingService.showToast("The status of the selected pends having different owners cannot be changed.", SeverityType.WARN);
        this.changeDetector.markForCheck();
        return;
      }
      this.isStatusChangeView = true;
      this.isClientRole = false;
    }
  }

  updateStatus($event) {
    if (!$event) {
      this.serverGridSelection = 0;
    }
    this.isStatusChangeView = $event;
    this.refreshGrid();
  }

  private getAssignedToUsers(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getStateListForAddress(): void {
    this.stateService.getStateList()
      .subscribe(stateList => {
        stateList.unshift(new SelectableInput({ text: "Select State", value: "" }));
        this.addressStateInput = { ...this.addressStateInput, options: stateList } as any;
        this.formService.updateDom.next();
      });
  }

  private getCompanies(): void {
    this.createPendService.getPendCompaniesDropdown()
      .pipe(map(data => data.map(item => new SelectableInput({ text: item.value, value: item.key }))))
      .subscribe(data => {
        data.unshift(new SelectableInput({ text: "Select Company", value: "" }));
        this.companyIdToInput = { ...this.companyIdToInput, options: data } as any;
        this.formService.updateDom.next();
      });
  }

  private getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    productOptions.unshift(new SelectableInput({ text: "*clear filter", value: "" }));
    this.productsInput = new Autocomplete({ ...this.productsInput, options: productOptions } as any);
  }

  private getPlans(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PLAN_LIST.attributeId)
      .subscribe(configuration => {
        this.getPlansInput(configuration);
        this.setPlansInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
   }

  private getPlansInput(configuration: ServiceOrgAttribute): void {
    const plansAttribute = JSON.parse(configuration.attributeValue);
    const plansOptions = plansAttribute.Plans.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.plansInput = { ...this.plansInput, options: plansOptions } as any;
  }

  private setPlansInput(): void {
    const plansFilter = this.searchRequest.getFilter(this.plansInput.key);
    if (StringHelper.isAvailable(plansFilter.value)) {
      const optionsValue = this.plansInput.options.find(a => a.value === +plansFilter.value);
      plansFilter.inputValue = [optionsValue];
      this.formService.updateDom.next();
    }
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.sampleComplianceInput = { ...this.sampleComplianceInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  get isAllSelectedPendOwnerIdentical(): boolean {
    return this.pendOwners.every((owner, i, pendOwner) => owner === pendOwner[0]);
  }
  private setDefaultGridFilters(): void {
    const defaultPendStatuses = [
      new SelectableInput({ text: "New", value: "New" }),
      new SelectableInput({ text: "In Progress", value: "In Progress" }),
    ];
    this.pendStatusInput = { ...this.pendStatusInput, value: defaultPendStatuses } as any;
  }
  formatMemberDobDate(): void {
    const dobFilter = this.searchRequest.getFilter("MemberDob");
    const formattedValue = DateHelper.format(dobFilter.value);
    dobFilter.value = formattedValue;
    this.formService.updateDom.next();
  }
}
