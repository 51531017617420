<div class="chase-detail-form">
  <div *ngIf="isRiskProject" class="provider-header">Add or Search for Provider(s), then click the 'Add Provider' button to be included for member encounter identification.</div>
  <div class="ui-md-3 ui-toolbar-group-left">
    <div class="ui-g-12 ui-md-12 ui-lg-12 search-selection-group">
      <form-factory class="member-search__existing-member" [formGroup]="formGroupProviderOptions"
                    [model]="existingProviderOptions"></form-factory>
    </div>
    <div class="ui-fluid" *ngIf="showProviderForm">
      <form [formGroup]="formGroupProvider">
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isProviderSearch">
          <p>
            Please create a new Provider by completing the fields below:<br />
            Then click the "Add Provider" button.
          </p>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupProvider" [model]="npId"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="formGroupProvider" [model]="specialty"></form-factory>
          </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupProvider" [model]="firstName"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupProvider" [model]="lastName"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="!isProviderSearch">
          <form-factory [formGroup]="formGroupProvider" [model]="clientProviderId"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12 label-container" *ngIf="isProviderInvalid">
            <div *ngFor="let message of validationErrorMessages trackBy: trackByIndex">
              <span class="ellipsis text-danger">{{ message }}</span>
          </div>
        </div>
        <div class="ui-md-12">
          <app-button class="ui-md-6 reset-button text-left" text="RESET" (onClick)="reset()"></app-button>
          <app-button *ngIf="isProviderSearch ; else newProvider" class="ui-md-6 process-button text-right" text="SEARCH" (onClick)="searchProviderAction()"></app-button>
          <ng-template #newProvider><app-button class="ui-md-6 process-button text-right" text="ADD PROVIDER" (onClick)="newProviderAction()"></app-button></ng-template>
        </div>
      </form>
    </div>
  </div>
  <div class="ui-md-8 ui-toolbar-group-left ml-2" [hidden]="!isProviderSearch">
    <div *ngIf="providerCount<=0" class="display-inline">
      <div class="ellipsis text-center"><h2>No providers found matching your search criteria.</h2></div>
    </div>
    <div class="grids-display" [hidden]="!isProviderResultsFound">
      <h3>{{providerCount}} RESULTS</h3>
      <app-server-grid [configuration]="providerGridConfiguration"
      [request]="providerGridSearchRequest"
      [isLoadOnInit]="false"
      [refresh]="refreshGrid"
      [(selection)]="providerGridSelection"
      (dataLoaded)="gridData($event)">

      <ng-template #providerColumn let-rowData>
          <app-button class="small btn-provider"
              text="ADD PROVIDER"
              (onClick)="onActionEvent(rowData)">
          </app-button>
      </ng-template>

</app-server-grid>
    </div>
    <div class="grids-display" [hidden]="!isProviderAdded">
        <div class="selected-provider-grid">Selected Providers</div>
        <app-basic-grid class="grids-display"
                        [configuration]="selectedProviderGridConfiguration"
                        [data]="providerGridSelectionList"
                        [(selection)]="providerGridSelection">
                        <ng-template #removeProviderColumn let-rowData>
                            <app-button 
                              class="small btn-remove" 
                              text="REMOVE" 
                              (click)="onActionDeleteEvent(rowData)">
                            </app-button>
                          </ng-template>
        </app-basic-grid>
      </div>

    <div class="ui-md-8 ui-toolbar-group-left ml-2 text-right process-button" *ngIf="isShowNextStepButton">
      <app-button class="button"
                  (onClick)="nextStep()"
                  text="NEXT STEP"></app-button>

    </div>
  </div>
</div>
