import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { URIFormatHelper } from "../../../../../utilities/uri-format-helper";

@Injectable({
  providedIn: "root",
})
export class RetrievalAddressDetailCommentsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getComments(addressId: number): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${addressId}&isOnlyLatest=false`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveComment(addressId: number, commentText: string) {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${addressId}&commentText=${URIFormatHelper.encodeParameters(commentText)}`;

    return this.http.post(url, {});
  }
}
