<cdk-virtual-scroll-viewport #thumbnailExpandedViewport class="thumbnail__viewport" [ngClass]="{ 'max': isMaximized }"
  [itemSize]=itemSize [minBufferPx]="itemSize * 2" [maxBufferPx]="itemSize * 3"
  (scrolledIndexChange)="updateIndex($event)">
  <div class="thumbnail__container" *cdkVirtualFor="let rowOfThumbnails of allThumbnails;">
    <div class="thumbnail__row" *ngFor="let thumbnail of rowOfThumbnails; trackBy: trackByIndex; let i = index;">
      <ng-container *ngIf="hasPageAndSource(thumbnail)">
        <div *ngIf="isDocumentThumbnail(thumbnail);" (click)="thumbnailClicked(thumbnail)"
          class="thumbnail__cell" [ngClass]="{'thumbnail__cell--overlay': isThumbnailSelected(thumbnail) }">
          <ng-container *ngIf="hasThumbnailImageUrl(thumbnail); else placeholderThumbnail">
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <img [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__image--style"
              [src]="getThumbnailSource(thumbnail)" alt="Document Preview Not Available" />
            <app-document-page-thumbnail-label></app-document-page-thumbnail-label>
            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">{{thumbnail.pageNumber}}</div>
          </ng-container>
          <ng-template #placeholderThumbnail>
            <app-badge *ngIf="thumbnail.isNewPage" class="thumbnail__image--badge"></app-badge>
            <div [ngClass]="{'thumbnail__image--selected': isThumbnailSelected(thumbnail) }"
              class="thumbnail__image thumbnail__placeholder  thumbnail__non-document">
              <div class="thumbnail__non-document--text">Document
                Preview
                Not Available</div>
            </div>
            <div *ngIf="hasPageAndSource(thumbnail)" class="thumbnail__cell--label">
              {{thumbnail.pageNumber}}</div>
          </ng-template>
          <div class="overlay"></div>
        </div>
      </ng-container>
    </div>
  </div>
</cdk-virtual-scroll-viewport>