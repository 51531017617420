<app-modal class="member-centric-modal"
           #modal
           [(visible)]="visible"
           header="ADD ADDITIONAL DOCUMENTS"
           (visibleChange)="onVisibleChange($event)">

  <div class="label-align">Choose which related chases you'd like to also apply this document to</div>
  <app-basic-grid class="grids-display"
                  [configuration]="gridConfiguration"
                  [data]="memberCentricGridData"
                  [(selection)]="gridSelection"
                  (headerCheckboxToggle)="onHeaderToggle($event)">
  </app-basic-grid>

  <div class="label-align">Note: All checked chases will be moved to medical record review (MRR)</div>

  <footer>
    <app-button class="header-button"
                text="CONFIRM"
                (onClick)="attachDocToMemberCentricChases()">
    </app-button>
  </footer> 
</app-modal>
