import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { DocumentBulkUpdate } from "../model/document-bulk-update.model";

@Injectable({
  providedIn: "root",
})
export class BulkUpdateDocumentService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  finishDocumentBulkUpdate(documentBulkUpdate: DocumentBulkUpdate): Observable<null> {
    const url = `${this.baseApiUrl}bulkupdate/document/validate/process`;
    return this.http.post(url, documentBulkUpdate) as Observable<null>;
  }

  getBulkValidationData(urlString: string, data: any) {
    const url = `${this.baseApiUrl}${urlString}`;

    return this.http.post(url, data) as Observable<null>;
  }
}
