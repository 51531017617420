<app-modal [(visible)]="isCreateVisible" header="EDIT EMR DETAILS" (visibleChange)="visibleChange($event)">

  <form [formGroup]="formGroup">

    <div class="ui-fluid">

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="groupName"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="url"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="email"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="phone"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="fax"></form-factory>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="contactName"></form-factory>
      </div>

    </div>

  </form>

  <footer>
    <app-button class="header-button" text="APPLY EDITS" (onClick)="onUpdate()"></app-button>
  </footer>

</app-modal>
