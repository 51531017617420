import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ContactHistoryNoteViewerComponent } from "../../../../../../shared/contact-history-note/contact-history-note.component";
import { GridPipeName } from "../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { DateFormats } from "../../../../../../utilities/contracts/helper-types";
import { RecordingPlaybackComponent } from "../../../recording-playback/recording-playback.component";
import { RecordingPlaybackService } from "../../../recording-playback/recording-playback.service";
import { AddressDetailContactHistoryService } from "./address-detail-contact-history.service";

@Component({
  selector: "retrieval-address-detail-contact-history",
  templateUrl: "./address-detail-contact-history.component.html",
  styleUrls: ["./address-detail-contact-history.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDetailContactHistoryComponent implements OnInit {
  @ViewChild("recColumn", { static: true }) recColumn: TemplateRef<RecordingPlaybackComponent>;
  @ViewChild("noteColumn", { static: true }) noteColumn: TemplateRef<ContactHistoryNoteViewerComponent>;
  contactHistoryGridConfiguration = new GridConfiguration();
  contactHistoryGridData: any[] = [];
  contactHistoryGridSelection: any[];
  private pageSize = 10;

  constructor(
    private service: AddressDetailContactHistoryService,
    private changeDetector: ChangeDetectorRef,
    private recordingPlaybackService: RecordingPlaybackService
  ) { }

  ngOnInit() {
    const showRecordingColumn = this.recordingPlaybackService.showRecordingPlayIcon;
    this.contactHistoryGridConfiguration.columns = [
      new GridColumnDefinition({ field: "userName", header: "User Name" }),
      new GridColumnDefinition({ field: "callStartTime", header: "Contact Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "contactDetail", header: "Contact Detail" }),
      new GridColumnDefinition({ field: "callResult", header: "Result" }),
      new GridColumnDefinition({ field: "contactMethodType", header: "Contact Type" }),
      new GridColumnDefinition({ header: "Notes", template: this.noteColumn, isSortableColumn: false }),
      new GridColumnDefinition({ header: "Rec", template: this.recColumn, isSortableColumn: false, show: showRecordingColumn }),
      new GridColumnDefinition({ field: "callDuration", header: "Duration" }),
    ];

    this.contactHistoryGridConfiguration.pageSize = this.pageSize;
    this.contactHistoryGridConfiguration.selectionMode = "";
    this.contactHistoryGridConfiguration.showActionColumn = false;

    this.service
      .getContactHistoryList()
      .subscribe(this.assignAndNotify);
  }

  assignAndNotify = <T>(data: T[]): void => {
    this.contactHistoryGridData = data as any;
    this.changeDetector.markForCheck();
  }

}
