import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IPageEvent } from "./document-page-event.model";

@Injectable({
  providedIn: "root",
})
export class DocumentPageService {

  private documentPage$ = new BehaviorSubject<IPageEvent>({ page: 1, documentPageId: 1 });
  private outsidePageChange$ = new Subject<number>();
  private chartPageChanges$: Subject<number> = new Subject();

  constructor() { }

  get chartPageChange$(): Observable<number> {
    return this.chartPageChanges$.asObservable();
  }

  set chartPageChange(value: number) {
    this.chartPageChanges$.next(value);
  }

  get documentPageNumber$(): Observable<IPageEvent> {
    return this.documentPage$.asObservable();
  }

  get outsidePage$(): Observable<number> {
    return this.outsidePageChange$.asObservable();
  }

  updateOutsidePage(page: number): void {
    this.outsidePageChange$.next(page);
  }

  updateDocumentPageInfo(pageEvent: IPageEvent): void {
    this.documentPage$.next(pageEvent);
  }
}
