import { Component, OnInit } from "@angular/core";

@Component({
  selector: "retrieval-emr-detail",
  templateUrl: "./emr-detail.component.html",
  styleUrls: ["./emr-detail.component.scss"],
})
export class EmrDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
