<div class="internal-pend-info-container">
  <div class="leftColumn">
    <app-list-summary [summaryItems]="summaryItems"></app-list-summary>
    <div class="pend-actions bold"
      [ngClass]="!showPendAction ? 'show-status-change-button':'hide-status-change-button'">
      PEND ACTIONS
      <div>
        <app-button class="header-button" text="PRINT PROVIDER PACKAGE" (click)="printProvider()"></app-button>
        <div class="upload-action">
          <app-upload-message></app-upload-message>
          <app-file-upload #fileUpload
                           chooseLabel="UPLOAD MEDICAL RECORD"
                           [data]="uploadData"
                           (onUpload)="uploadDocument($event)">
          </app-file-upload>
          <app-membercentric-doc-attachment [(visible)]="showMemberCentricDocAttachmentInfo"
                                            [memberCentricGridData]="memberCentricData"
                                            [targetChaseId]="chaseId"
                                            (onConfirmDocAttachment)="uploadDocToMemberCentricChases($event)">
          </app-membercentric-doc-attachment>
        </div>
      </div>
    </div>
    <app-document-request-artifact [documentRequests]="documentRequests" [addressUrlOfChase]="addressUrlOfChase">
    </app-document-request-artifact>
    <div class="comments-header bold">LATEST COMMENT</div>
    <app-comments [commentItems]="commentItems"></app-comments>
    <textarea class="commenttextarea" maxlength="1000" placeholder="Type here to reply..."
      [(ngModel)]="commentText"></textarea>
    <div class="save-comment">
      <app-button (click)="loadViewConversation(pendId)" text="VIEW CONVERSATION"></app-button>
      <app-button class="align-right" (click)="saveComments()" text="SEND MESSAGE"></app-button>
    </div>

    <div class="pend-document-list-container">
      <div class=" section-headers">LATEST DOCUMENTS</div>
      <div>
        <app-internal-pends-document-list></app-internal-pends-document-list>
        <div>
          <app-button text="VIEW ALL DOCUMENTS" (click)="showAllDocument(pendId)"></app-button> &nbsp;&nbsp;
          <div class="upload-action">
            <app-button text="UPLOAD DOCUMENT" (onClick)="showDialog()"></app-button>
          </div>
          <div>
            <app-internal-pends-upload-document [isUploadDocumentVisible]="isUploadDocumentView"
              (formClose)="reUploadDocument($event)"></app-internal-pends-upload-document>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rightColumn">
    <div class="header bold">PEND TIMELINE</div>
    <app-timeline [timelineItems]="timelineItems"></app-timeline>
  </div>
</div>

<app-confirmation-modal
  [(visible)]="showUploadWarningModal"
  header="WARNING"
  (onCancel)="cancelUpload()"
  (onYes)="continueWithUpload(urlCopy)"
  acceptText="OK"
  rejectText="CANCEL">
  The current chart is being processed, in order to perform data entry you should move the chart back to MRR, upload the record and re-abstract the information.
</app-confirmation-modal>
