import { AfterContentChecked, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, ViewChild } from "@angular/core";
import { Accordion } from "primeng/accordion";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { AccordionTabComponent } from "./accordion-tab.component";

@Component({
  selector: "app-accordion-panel",
  template: `
    <p-accordion #primeAccordionPanel multiple="true">
      <ng-content></ng-content>
    </p-accordion>
  `,
  styleUrls: ["./accordion-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Accordion],
})
export class AccordionPanelComponent implements AfterContentChecked {
  @ViewChild(Accordion, { static: true }) primeAccordionPanel: Accordion;
  @ContentChildren(AccordionTabComponent) accordionTabs: QueryList<AccordionTabComponent>;
  @Input() expandIcon = "fas fa-caret-right";
  @Input() collapseIcon = "fas fa-caret-down";

  ngAfterContentChecked() {
    if (NumberHelper.isGreaterThan(this.accordionTabs.length, 0)) {
      if (this.accordionTabs.first.primeAccordionTab.accordion.expandIcon !== this.expandIcon &&
          this.accordionTabs.first.primeAccordionTab.accordion.collapseIcon !== this.collapseIcon) {
            this.accordionTabs.first.primeAccordionTab.accordion.expandIcon = this.expandIcon;
            this.accordionTabs.first.primeAccordionTab.accordion.collapseIcon = this.collapseIcon;
      }
    }
  }
}
