import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { UserToken } from "../../../../../auth/user-token.model";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { Switch } from "../../../../../dynamic-forms/inputs/switch/switch.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ModalComponent } from "../../../../panel/modal/modal.component";
import { GridConfiguration } from "../../../models/grid-configuration.model";
import { GridView } from "../grid-view.model";
import { GridViewService } from "../grid-view.service";
import { GridViewsState } from "../grid-views-state.model";

@Component({
  selector: "app-grid-view-editor",
  templateUrl: "./grid-view-editor.component.html",
  styleUrls: ["./grid-view-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridViewEditorComponent extends ModalComponent implements OnInit {
  @Input() configuration: GridConfiguration;
  @Input() gridView: GridView | null;
  @Input() gridViewState: GridViewsState;
  @Input() refreshViews: EventEmitter<GridView>;
  private user: UserToken;
  formGroup: FormGroup;
  nameInput: Textbox;
  columnsInput: CheckboxGroup;
  publicInput: Switch;
  inputs: DynamicInput[];
  isDeleteGridViewModalVisible = false;
  nameErrorMessage = "Please name your table.";
  colErrorMessage = "You must select at least one column.";
  @Input() displayCheckedItemsInGridView: { value: string; disabled: boolean }[] = [];

  constructor(
    private formService: FormService,
    private automapper: AutomapperService,
    private gridViewService: GridViewService,
    private cd: ChangeDetectorRef,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.user = this.userService.getUserToken();

    this.nameInput = new Textbox({
      key: "name",
      label: "TITLE YOUR VIEW",
      placeholder: "Enter Title",
      validators: [Validators.required],
    });

    this.columnsInput = new CheckboxGroup({
      key: "columns",
      label: "Columns",
      options: this.configuration.columns.filter(x => x.customView).map(this.automapper.curry("GridColumnDefinition", "SelectableInput")),
      validators: [Validators.required],
    });
    this.publicInput = new Switch({
      key: "public",
      preceedingOffLabel: "Save as default team view",
    });
    if (this.displayCheckedItemsInGridView !== undefined && ArrayHelper.isAvailable(this.displayCheckedItemsInGridView)) {
      this.displayCheckedItemsInGridView.forEach(view => {
        const selectedOption = this.columnsInput.options.find(x => x.value === view.value);
        if (selectedOption !== null && selectedOption !== "" && selectedOption !== undefined) {
          selectedOption.disabled = view.disabled;
          this.columnsInput.selectedOptions.push(selectedOption);
        }
      });
    }
    if (this.hasGridView) {
      this.nameInput.value = this.gridView.name;
      this.columnsInput.selectedOptions = this.configuration.columns.reduce((acc, column) => {
        const gridViewColumn = this.gridView.columns[column.field];
        if (gridViewColumn != null && gridViewColumn.show) {
          acc.push(this.automapper.map("GridColumnDefinition", "SelectableInput", column));
        }
        return acc;
      },                                                                    []);
      if (this.gridView.organizationId === this.user.organizationId && this.gridView.userId === this.user.userId) {
        this.publicInput.value = true;
      }
    }

    this.formGroup = this.formService.createFormGroup([this.nameInput, this.columnsInput, this.publicInput]);
  }

  get hasNameError(): boolean {
    return this.formGroup.get(this.nameInput.getMasterKey()).touched && this.formGroup.get(this.nameInput.getMasterKey()).invalid;
  }

  get hasColError(): boolean {
    return this.formGroup.get(this.columnsInput.getMasterKey()).touched && this.formGroup.get(this.columnsInput.getMasterKey()).invalid;
  }

  get hasGridView(): boolean {
    return this.gridView != null;
  }

  get deleteHeader(): string {
    return "Are you sure?";
  }

  @HostBinding("style.display")
  get display(): string {
    return this.visible ? "block" : "none";
  }

  get isUserAdmin(): boolean {
    return this.user.isAdminRole;
  }

  saveGridView(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.formGroup.valid) {
      const name = StringHelper.clean(this.formGroup.get(this.nameInput.getMasterKey()).value);
      const orgId = this.formGroup.get(this.publicInput.getMasterKey()).value ? this.user.organizationId : null;
      const columns = this.formGroup.get(this.columnsInput.getMasterKey()).value.map(a => a.value);
      const gridView = new GridView({
        gridViewId: this.hasGridView ? this.gridView.gridViewId : null,
        name,
        columns,
        organizationId: orgId,
      });
      this.save(gridView);
    } else {
      this.formService.markAllAsTouched(this.formGroup);
    }
  }

  showDeleteModal(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDeleteGridViewModalVisible = true;
    this.cd.markForCheck();
  }

  deleteGridView(): void {
    const defaultView = this.gridViewState.views.find(x => NumberHelper.isGreaterThan(x.organizationId, 0));
    const nextView = defaultView ? defaultView : this.gridViewState.views[0];
    this.gridViewService.delete(this.gridView.gridViewId).subscribe(() => {
      this.successfulUpdate(nextView);
    });
  }


  private save(gridView: GridView): void {
    this.gridViewService.update(this.configuration.viewAttributeId, gridView).subscribe(this.successfulUpdate.bind(this));
  }

  private successfulUpdate(grid: GridView): void {
    this.refreshViews.emit(grid);
    this.hide();
  }

  trackByIndex(index, item) {
    return index;
  }
}
