import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-top-aids-with-open-chases",
  templateUrl: "./top-aids-with-open-chases.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopAidsWithOpenChasesComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.TOP_AIDS_WITH_OPEN_CHASES;
  hideFilters: string[] = [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID];
}
