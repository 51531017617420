<div class="grids-display">
  <div class="sub-menu__container--address-detail-info">
    <div class="chase-grid-sub-header">
      <div class="show-all-chases-checkbox">
        <form-factory [formGroup]="chaseGridFilterForm" [model]="nonActionableChasesChecked" (onChange)="showAllChaseGridData()"></form-factory>
      </div>
      <div class="show-all-chases-checkbox">
        <form-factory [formGroup]="chaseGridFilterForm" [model]="hideOpenPendsChecked" (onChange)="changeHideOpenPends()"></form-factory>
      </div>
      <div class="header-button upload-button">
        <app-button text="Upload" (onClick)="uploadChase()"></app-button>
      </div>
    </div>
  </div>

  <div class="chase-grid-container">
    <app-server-grid class="grids-display" [configuration]="chaseGridConfiguration"
                 [(request)]="request"
                 [views]="views"
                 [refreshViews]="refreshViews"
                 [(selection)]="chaseGridSelection"
                 [actions]="actions"
                 [refresh]="refreshGrid"
                 (dataLoaded)="gridDataLoaded($event)"
                 [filterTemplate]="chaseFilterTemplate"
                 [actionTemplate]="chaseActionTemplate"
                 [filterHeaderText]="filterHeaderText">
                 <ng-template #chaseActionTemplate let-rowData let-localActionPopover="actionPopover">
                  <div class="action-template-container">
                    <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                                [text]="action.name"
                                (onClick)="action.action(rowData); localActionPopover.hide();"
                                [disabled]="action.disabled"></app-button>
                  </div>
                </ng-template>
                 <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
                  <div class="action-template-container">
                    <app-button *ngFor="let action of actions; trackBy: trackByIndex" [text]="action.name"
                      (onClick)="action.action(rowData); localActionPopover.hide();" [disabled]="action.disabled"></app-button>
                  </div>
                </ng-template>
          
                <ng-template #chaseIdColumn let-rowData>
                  <app-chase-grid-chase-id [data]="rowData" [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
                </ng-template>
          
                <ng-template #openGapsColumn let-rowData>
                  <app-open-gaps-template [data]="rowData"></app-open-gaps-template>
                </ng-template>
          
                <ng-template #commentsColumn let-rowData>
                  <app-grid-comments [entityId]="rowData.chaseID" entityType="chase" [isCommentPresent]="rowData.commentExists"></app-grid-comments>
                </ng-template>
          
                <ng-template #chaseFilterTemplate let-form>
                  <form [formGroup]="form" class="ui-modal-width" #chaseForm="ngForm" (click)="loadFilterInput()">
                    <app-tab-menu orientation="left">
                      <app-tab-panel header="Chase ID">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel header="Member">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
                          <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
                          <form-factory [formGroup]="form" [model]="memberDobInput" (onChange)="formatMemberDobDate()"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group__halves" header="Projects">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group__fourths" header="Measures">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group__halves" header="Status">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="reportingStatusInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel header="Commit Date">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="chartCommitmentDateInput"
                            (onChange)="formatChartCommitmentDate()"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group" header="Pend Codes">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group__halves" header="Pend Status">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="sampleComplianceCodeInput"></form-factory>
                        </div>
                      </app-tab-panel>
                      <app-tab-panel header="Tags">
                        <label class="tag-label">Tags</label>
                        <div class="tag__filterform">
                          <div class="ui-g-12 ui-md-12 ui-lg-12">
                            <div class="ui-g-2 ui-md-2 ui-lg-2">
                              <form-factory class="tag__operatorform" [formGroup]="form" [model]="tagsSearchOperatorInput"
                        (onChange)="getSearchOperatorValue($event)">
                      </form-factory>
                            </div>
                            <div class="ui-g-10 ui-md-10 ui-lg-10">
                              <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                                class="tag-content__item tag-content__item--multitag-close-icon" classes="transparent small"
                                icon="times-circle" (onClick)="resetTagSearchInput()">
                              </app-button>
                              <form-factory class="tag__panelform" [formGroup]="form" [model]="tagsInput"
                                (onPanelShow)="onShowEvent(true)" (onPanelHide)="onShowEvent(false)" (keyup)="onKeyUp($event)">
                              </form-factory>
                            </div>
                          </div>
          
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel header="Retriever" *ngIf="showCoRetrievalOwnerFilter">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="coRetrievalOwnerInput"> </form-factory>
                        </div>
                      </app-tab-panel>
          
                      <app-tab-panel header="Assigned To" >
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
                        </div>
                      </app-tab-panel>
                      <app-tab-panel header="Contact">
                        <div class="ui-g-12 ui-md-12 ui-lg-12">
                          <form-factory [formGroup]="form" [model]="contactNameInput"></form-factory>
                        </div>
                      </app-tab-panel>
                    </app-tab-menu>
                  </form>
                </ng-template>

</app-server-grid>
  </div>
</div>

<app-retrieval-outreach [(visible)]="isOutreachModalVisible" [isGap]="false" [selectedChaseList]="selectedChases"
  [additionalOutreachChaseList]="openGaps" [userInfo]="userInfo" [isUserInfoPassed]="isUserInfoPassed" (onUpdate)="updateChaseGrid()" (onHide)="closeOutreachModal()">
</app-retrieval-outreach>

<app-confirmation-modal [(visible)]="isAdditionalOutreachPromptVisible" header="ATTENTION" acceptText="Yes"
  rejectText="Close" (onYes)="navigateToAdditionalOutreachTab()" class="text-center">
  This Address also has Open Gap Pursuits.
  <br /><br />
  Would you like to perform Outreach for the Gap Pursuits as well?
  <br /><br />
  <div class="bold">
    Total Gap Pursuits : {{ additionalGapOutreachChaseCount }}
  </div>
  <br />
</app-confirmation-modal>

<app-pend-modal [(visible)]="isPendModalVisible" (onHide)="closeModal()" (onUpdate)="updateChaseGrid()"
  [(chases)]="selectedChases" [clinical]="clinical">
</app-pend-modal>

<retrieval-commitment-date [(visible)]="isCommitmentDateModalVisible" [updateCommitmentData]="commitDateUpdateModel"
  (onUpdate)="onCommitmentDateUpdate($event)" (onHide)="resetChaseSelection($event)" [(commitDate)]="commitmentDate"
  [(isThirdParty)]="isThirdParty">
</retrieval-commitment-date>

<app-update-retriever-model [(visible)]="isRetrieverModalVisible" [updateRetrieverData]="retrieverUpdateModel"
  (onHide)="resetChaseSelection($event)" [totalEntityCount]="totalEntityCount" (onUpdate)="onChangRetrieverType()">
</app-update-retriever-model>

<retrieval-expected-due-date [(visible)]="isExpectedDueDateModalVisible"
  [updateExpectedDueDateData]="expectedDueDateUpdateModel" (onUpdate)="updateChaseGrid()"
  (onHide)="resetChaseSelection($event)" [(expectedDueDate)]="expectedDueDate">
</retrieval-expected-due-date>

<app-manage-tag-modal [(visible)]="isManageTagModalVisible" (onHide)="closeModal()" [(selectedData)]="selectedChases"
  [(masterDocumentSourceId)]="addressId" [entityName]="entityName" [totalEntityCount]="totalEntityCount"
  [tagType]="tagType" (onUpdate)="onManageTagUpdate()">
</app-manage-tag-modal>

<retrieval-address-search [(visible)]="isAddressModalVisible" [callingSource]="addressSearchCallingSource"
  (onAddressSelection)="onAddressSelectionForChaseMove($event)"  [(masterDocumentSourceId)]="addressId" (isNewAddress)="onIsNewAddress($event)">
</retrieval-address-search>

<retrieval-cover-letter-template [(visible)]="isTemplateModalVisible" [request]="contactRequest"
  (onApplyCoverTemplate)="sendContactRequest($event)">
</retrieval-cover-letter-template>

<app-chase-assignment-modal [(visible)]="isAssignModalVisible" (onHide)="closeModal()" (onUpdate)="closeAssignModal()"
  [(chases)]="selectedChaseIdsForAssignment" [addressDetailState]="addressDetailState" [isEmrOrFt]="isEmrOrFt"></app-chase-assignment-modal>

<app-print-provider-packet-modal [(visible)]="isPrintProviderPacketModalVisible" (onHide)="closeModal()"
  [providerPacketItem]="providerPacketItem">
</app-print-provider-packet-modal>

<app-modal [(visible)]="isConfirmModalVisible">
  {{confirmStatusMessage}}
  <footer>
    <app-button class="header-button" text="VIEW THIS ADDRESS" (onClick)="gotoAddressDetail()" [disabled]="false">
    </app-button>
  </footer>
</app-modal>

<app-modal [(visible)]="isMoveVisible" [header]="moveHeaderMessage"
  (onCancel)="closeChaseMovePop()">
  <footer>
    <app-button class="header-button" text="Yes" (onClick)="chaseMoved()" [disabled]="false"></app-button>
  </footer>
</app-modal>

<retrieval-address-search-contact-info
  [(visible)]="isContactInfoModalVisible"
  (onMoveChases)="onMoveChasesConfirmation($event)"
  [addressModel]="newAddressModel"
  (onAddressSelection)="onContactInfoSaved($event)"
  [moveType]="'Move chase'">
</retrieval-address-search-contact-info>

<app-confirmation-modal [(visible)]="isChasePromptVisible" header="WARNING" (onYes)="openFaxModal(rowData)"
  (onCancel)="onCancelPopup()" class="text-center">
  You are about to send a large fax of {{ getChaseCount(rowData) }} chases. For improved delivery performance, this
  delivery will be broken up into multiple faxes. Would you like to proceed?
</app-confirmation-modal>

<app-modal [(visible)]="showPrimaryContactNotSetModal" header="ATTENTION" class="text-center">
  A primary contact must be set to perform outreach.
</app-modal>

<app-modal [(visible)]="showNoGapsModal" header="ATTENTION" class="text-center">
  You are about to send a request that contains chases with 0 open gaps. These chases will not be included in the request. Do you want to continue?
  <footer>
    <app-button class="header-button" text="Yes" (onClick)="showPrintPocketModal()"></app-button>
  </footer>
</app-modal>
