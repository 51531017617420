import { MoveBackChasePageItem } from "./move-back-chase-page-item-model";

export class MoveBackChasePageRequest {
  readonly moveBackChasePageType: number;
  readonly moveBackChasePageItems: MoveBackChasePageItem[];

  constructor(options: {
    moveBackChasePageType: number;
    moveBackChasePageItems: MoveBackChasePageItem[];
  }) {
    this.moveBackChasePageType = options.moveBackChasePageType;
    this.moveBackChasePageItems = options.moveBackChasePageItems;
  }
}
