import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { List } from "immutable";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { AddressGridComponent } from "../../../../shared/address-grid/address-grid.component";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { RetrievalService } from "../../dashboard/retrieval/retrieval.service";
import { RETRIEVAL_ASSIGNMENT_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { AddressesQueueService } from "./addresses-queue.service";


@Component({
  selector: "retrieval-addresses-queue",
  templateUrl: "./addresses-queue.component.html",
  styleUrls: ["./addresses-queue.component.scss"],
  providers: [AddressesQueueService],
})
export class AddressesQueueComponent implements OnInit {
  // inputs for address-grid
  @ViewChild(AddressGridComponent, { static: true }) addressGridComponent: AddressGridComponent;
  url = `${this.baseApiUrl}retrieval/list`;
  stateName = RETRIEVAL_ASSIGNMENT_GRID.attributeCode;
  viewAttributeId = RETRIEVAL_ASSIGNMENT_GRID.attributeId;
  isSelectionModeMultiple = true;
  additionalBulkActions: BulkAction[] = [];
  additionalFilters: GridFilter[] = [];

  refreshGrid = new EventEmitter<null>(true);
  addressSearchRequest: GridRequest;
  isQueueModalVisible = false;
  projects: SelectableInput[];
  users: SelectableInput[];
  addressQueueForm: FormGroup;
  projectInput: Dropdown;

  constructor(

    private addressesQueueService: AddressesQueueService,
    private retrievalService: RetrievalService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private formService: FormService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) { }

  ngOnInit() {
    this.createGrid();
    this.initializeForm();
    this.addressesQueueService
      .getProjectList()
      .subscribe(result => {
        this.projects = result;
        this.projectInput = new Dropdown({ ...this.projectInput, options: this.projects } as any);
        this.changeDetector.markForCheck();
      });

  }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  get hasSelectedProjectId(): boolean {
    return NumberHelper.isGreaterThan(this.selectedProjectId, 0);
  }

  onUpdateQueue(): void {
    this.addressesQueueService.updateAddressesQueueList(this.selectedProjectId)
      .subscribe(() => {
        this.updateGrid();
        this.messagingService.showToast("Queue updated successfully.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
      });

    this.addressQueueForm.get(this.projectInput.key).setValue(null);
    this.isQueueModalVisible = false;
    this.addressSearchRequest = new GridRequest({
      url: this.url,
    });
    this.updateGrid();
  }

  updateGrid(): void {
    this.addressGridComponent.refreshGrid.emit();
  }

  private createGrid(): void {
    this.additionalFilters = [ ];
  }

  initializeForm(): void {
    this.projectInput = new Dropdown({
      key: "project",
      label: "Project",
      placeholder: "Select Project",
      appendTo: "body",
    });
    this.addressQueueForm = this.formService.createFormGroup([this.projectInput]);
  }

  get selectedProjectId(): number {
    return Number(this.addressQueueForm.get(this.projectInput.key).value);
  }
}
