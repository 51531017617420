import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { ArchiveChaseDetailChartComponent } from "./archive-chase-detail/archive-chase-detail-chart/archive-chase-detail-chart.component";
import { ArchiveChaseDetailComponent } from "./archive-chase-detail/archive-chase-detail.component";
import { ChaseDetailV2ChartComponent } from "./chase-detail-v2/chase-detail-v2-chart/chase-detail-v2-chart.component";
import { DxContainerComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk-dx/dx-container/dx-container.component";
import { EncounterV2Component } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/encounter/encounter.component";
import { RiskMemberComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-member/risk-member.component";
import { RiskSubmitComponent } from "./chase-detail-v2/chase-detail-v2-chart/risk/risk-submit/risk-submit.component";
import { ChaseDetailV2Component } from "./chase-detail-v2/chase-detail-v2.component";
import { ChaseDetailAuditLogComponent } from "./chase-detail/chase-detail-audit-log/chase-detail-audit-log.component";
import { ChaseDetailChartComponent } from "./chase-detail/chase-detail-chart/chase-detail-chart.component";
import { ChaseDetailCommentsComponent } from "./chase-detail/chase-detail-comments/chase-detail-comments.component";
import { ChaseDetailDocumentsComponent } from "./chase-detail/chase-detail-documents/chase-detail-documents.component";
import { ChaseDetailHealthCardComponent } from "./chase-detail/chase-detail-health-card/chase-detail-health-card.component";
import { ChaseDetailInfoComponent } from "./chase-detail/chase-detail-info/chase-detail-info.component";
import { ChaseDetailMemberChasesComponent } from "./chase-detail/chase-detail-member-chases/chase-detail-member-chases.component";
import { ChaseDetailMemberGapsComponent } from "./chase-detail/chase-detail-member-gaps/chase-detail-member-gaps.component";
import { ChaseDetailTimelineComponent } from "./chase-detail/chase-detail-timeline/chase-detail-timeline.component";
import { ChaseDetailComponent } from "./chase-detail/chase-detail.component";
import { ChasePageUrlService } from "./chase-page-url.service";
import { CreateNewChaseComponent } from "./create-new-chase/create-new-chase.component";
import { CreateStepsMenuComponent } from "./create-new-chase/create-steps-menu/create-steps-menu.component";
import { MemberListingComponent } from "./member/member-listing/member-listing.component";
import { MemberComponent } from "./member/member.component";
import { NewPagesGridComponent } from "./new-pages/new-pages-grid/new-pages-grid.component";

const sharedChildRoutes: Routes = [
  { path: "auditLog", component: ChaseDetailAuditLogComponent },
  { path: "comments", component: ChaseDetailCommentsComponent },
  { path: "documents", component: ChaseDetailDocumentsComponent },
  { path: "expanded/:docType", component: ChaseDetailChartComponent },
  { path: "healthCard", component: ChaseDetailHealthCardComponent },
  { path: "info", component: ChaseDetailInfoComponent },
  { path: "memberChases", component: ChaseDetailMemberChasesComponent },
  { path: "memberGaps", component: ChaseDetailMemberGapsComponent },
  { path: "timeline", component: ChaseDetailTimelineComponent },
];

const childRoutes: Routes = [
  { path: "", component: ChaseDetailChartComponent },
  ...sharedChildRoutes,
];

const childRoutesV2: Routes = [
  {
    path: "", component: ChaseDetailV2ChartComponent, children: [
      { path: "", component: RiskMemberComponent },
      { path: "encounter", component: EncounterV2Component },
      { path: "submit", component: RiskSubmitComponent },
      { path: "dx-review", component: DxContainerComponent },
    ],
  },
  ...sharedChildRoutes,
];

const routes: Routes = [
  { path: "", component: MemberComponent },
  {
    path: "archive/:chaseGd", component: ArchiveChaseDetailComponent, children: [
      { path: "", component: ArchiveChaseDetailChartComponent },
    ],
  },
  { path: "chase-flow", component: CreateNewChaseComponent },
  { path: "chase-steps-menu", component: CreateStepsMenuComponent },
  {
    path: "chase/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailComponent,  children: childRoutes,
  },
  {
    path: "or1/chase/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailComponent,  children: childRoutes,
  },
  {
    path: "clientoverread/chase/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailComponent,  children: childRoutes,
  },
  {
    path: "mrr/chase/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailV2Component, children: childRoutes,
  },
  // V2
  {
    path: "chasev2/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailV2Component, children: childRoutesV2,
  },
  {
    path: "or1/chasev2/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailComponent, children: childRoutesV2,
  },
  {
    path: "clientoverread/chasev2/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailComponent, children: childRoutesV2,
  },
  {
    path: "mrr/chasev2/:chaseGd", canActivate: [ChasePageUrlService], component: ChaseDetailV2Component, children: childRoutesV2,
  },
  { path: "list", component: MemberListingComponent }, // TODO: Delete route and files associated.
  { path: "newpages", component: NewPagesGridComponent , canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule { }
