import { IAutoMapper } from "../../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { dateTypes } from "../../../../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { DIAGNOSIS } from "../../../../../../api/member-validation/entities";
import { EveIcd } from "../../../../chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/eve-icd.model";
import { AttributeData } from "../../../../create-new-chase/attributeData.model";
import { EntityData } from "../../../../create-new-chase/entityData.model";
import { DIAGNOSIS_CODE, FROM_DATE, THRU_DATE } from "../../attributes";
import { RiskHelper } from "../risk-helper.model";
import { RiskSystemResultType } from "../risk-system-result/risk-system-result-type.enum";
import { CodingModel } from "./coding-model.model";
import { DiagnosisValidationResult } from "./diagnosis-validation-result.enum";

export const mapDiagnosis = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "Diagnosis")
    .forMember("id", o => o.id)
    .forMember("isAdmin", o => o.isAdmin)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("startDate", o => o.startDate)
    .forMember("endDate", o => o.endDate)
    .forMember("icd", o => o.icd)
    .forMember("hccs", o => o.hccs)
    .forMember("vrcs", o => o.vrcs)
    .forMember("isEveDiagnosis", o => o.isEveDiagnosis)
    .forMember("isNlp", o => o.isNlp);

  automapper
    .createMap("RiskData", "Diagnosis")
    .forMember("id", o => o.id)
    .forMember("confidenceScore", o => o.confidenceScore)
    .forMember("dosFrom", o => o.dosFrom)
    .forMember("dosThrough", o => o.dosThrough)
    .forMember("text", o => o.text)
    .forMember("isAdmin", o => RiskHelper.getAttribute("EndDate", o).isAdmin)
    .forMember("encounterId", o => o.parentEntityId)
    .forMember("diagnosisId", o => RiskHelper.getAttribute("EndDate", o).entityId)
    .forMember("pageNumber", RiskHelper.getNumberValueCurry("ChartPageNumber"))
    .forMember("startDate", RiskHelper.getStringValueCurry("StartDate"))
    .forMember("endDate", RiskHelper.getStringValueCurry("EndDate"))
    .forMember("icd", RiskHelper.getStringValueCurry("DiagnosisCode"))
    .forMember("hccs", RiskHelper.getStringValueCurry("Hcc"))
    .forMember("rxHcc", RiskHelper.getStringValueCurry("RxHcc"))
    .forMember("vrcs", RiskHelper.getStringValueCurry("ValidationReasonCode"))
    .forMember("diagnosisValidationResult", RiskHelper.getStringValueCurry("DiagnosisValidationResult"))
    .forMember("omissionCodes", RiskHelper.getStringValueCurry("OmissionCode"))
    .forMember("nlpReviewResult", RiskHelper.getStringValueCurry("NlpReviewResult"))
    .forMember("exempt", RiskHelper.getBoolValueCurry("ExemptFromScoring"))
    .forMember("isNlp", o => RiskHelper.getAttribute("EndDate", o).isNlp)
    .forMember("providerSourceAliasId", RiskHelper.getStringValueCurry("ProviderSourceAliasID"))
    .forMember("diagnosisCode", o => o.diagnosisCode)
    .forMember("icdCodes", o => o.icdCodes)
    .forMember("hccModelIds", o => o.hccModelIds)
    .forMember("rxHCCs", o => o.rxHCCs)
    .forMember("codingModels", o => o.codingModels);

  // Because measure RXCM uses same model as HCC/HST, we are reusing the diagnosis model to display service codes.
  automapper
    .createMap("RxcmData", "Diagnosis")
    .forMember("id", o => o.id)
    .forMember("isAdmin", o => RiskHelper.getAttribute("ServiceCode", o).isAdmin)
    .forMember("pageNumber", RiskHelper.getNumberValueCurry("ChartPageNumber"))
    .forMember("code", RiskHelper.getStringValueCurry("ServiceCode"));
};

export interface IDiagnosisOptions {
  id?: string;
  confidenceScore?: string;
  dosFrom?: string;
  dosThrough?: string;
  text?: string;
  isAdmin?: boolean;
  pageNumber?: number;
  startDate?: dateTypes;
  endDate?: dateTypes;
  icd?: string;
  hccs?: string;
  rxHcc?: string;
  vrcs?: string;
  diagnosisValidationResult?: string;
  omissionCodes?: string;
  nlpReviewResult?: string;
  exempt?: boolean;
  code?: string;
  encounterId?: number;
  diagnosisId?: number;
  isEveDiagnosis?: boolean;
  readonly diagnosisCodeAttributeData?: AttributeData;
  readonly startDateAttributeData?: AttributeData;
  readonly endDateAttributeData?: AttributeData;
  readonly entityTypeData?: EntityData;
  isNlp?: boolean;
  providerSourceAliasId?: string;
  diagnosisCode?: string;
  icdCodes?: EveIcd[];
  hccModelIds?: string[];
  rxHCCs?: string[];
  codingModels?: CodingModel[];
}

export class Diagnosis {
  id: string;
  confidenceScore: string;
  dosFrom: string;
  dosThrough: string;
  text: string;
  isAdmin: boolean;
  pageNumber: number;
  startDate: Date;
  endDate: Date;
  icd: string;
  hccs: string;
  rxHcc: string;
  vrcs: string;
  diagnosisValidationResult: string;
  omissionCodes: string;
  nlpReviewResult: string;
  exempt: boolean;
  code: string;
  encounterId: number;
  diagnosisId: number;
  isEveDiagnosis?: boolean;
  readonly diagnosisCodeAttributeData: AttributeData;
  readonly startDateAttributeData: AttributeData;
  readonly endDateAttributeData: AttributeData;
  readonly entityTypeData: EntityData;
  isNlp: boolean;
  providerSourceAliasId: string;
  diagnosisCode: string;
  icdCodes: EveIcd[];
  hccModelIds: string[];
  rxHCCs: string[];
  actualVrcs: string;
  codingModels: CodingModel[];

  constructor(options: IDiagnosisOptions = {}) {
    this.id = StringHelper.isAvailable(options.id) ? options.id : StringHelper.createId();
    this.confidenceScore = StringHelper.clean(options.confidenceScore);
    this.dosFrom = StringHelper.clean(options.dosFrom);
    this.dosThrough = StringHelper.clean(options.dosThrough);
    this.text = StringHelper.clean(options.text);
    this.isAdmin = BooleanHelper.tryGet(options.isAdmin, true);
    this.pageNumber = NumberHelper.isAvailable(options.pageNumber) ? options.pageNumber : null;
    this.startDate = DateHelper.create(options.startDate, null); // NOTE: An invalid date breaks the grid selection
    this.endDate = DateHelper.create(options.endDate, null); // NOTE: An invalid date breaks the grid selection
    this.icd = StringHelper.clean(options.icd);
    this.hccs = StringHelper.clean(options.hccs);
    this.rxHcc = StringHelper.clean(options.rxHcc);
    this.vrcs = StringHelper.isAvailable(options.vrcs) && options.vrcs.includes("01") ?
      StringHelper.removeValueWithSeparator(options.vrcs, "01") : options.vrcs;
    this.actualVrcs = options.vrcs;
    this.diagnosisValidationResult = StringHelper.clean(options.diagnosisValidationResult);
    this.nlpReviewResult = StringHelper.clean(options.nlpReviewResult);
    this.exempt = BooleanHelper.tryGet(options.exempt, false);
    this.code = StringHelper.clean(options.code);
    this.isEveDiagnosis = options.nlpReviewResult === "ADD";
    this.encounterId = NumberHelper.isAvailable(options.encounterId) ? options.encounterId : null;
    this.diagnosisId = NumberHelper.isAvailable(options.diagnosisId) ? options.diagnosisId : null;
    this.diagnosisCodeAttributeData = DIAGNOSIS_CODE;
    this.startDateAttributeData = FROM_DATE;
    this.endDateAttributeData = THRU_DATE;
    this.entityTypeData = DIAGNOSIS;
    this.isNlp = BooleanHelper.tryGet(options.isNlp, true);
    this.providerSourceAliasId = StringHelper.clean(options.providerSourceAliasId);
    this.omissionCodes = StringHelper.clean(options.omissionCodes);
    this.diagnosisCode = options.diagnosisCode;
    this.icdCodes = ArrayHelper.clean(options.icdCodes);
    this.hccModelIds = this.selectDefaultDiseaseCodes(options.hccModelIds, this.hccs);
    this.rxHCCs = this.selectDefaultDiseaseCodes(options.rxHCCs, this.rxHcc);
    this.codingModels = ArrayHelper.clean(options.codingModels, []);
  }

  get dosRange(): string {
    const startDateFormatted = DateHelper.isAvailable(this.startDate) ? DateHelper.format(this.startDate) : "00/00/0000";
    const endDateFormatted = DateHelper.isAvailable(this.endDate) ? DateHelper.format(this.endDate) : "00/00/0000";
    return `${startDateFormatted} - ${endDateFormatted}`;
  }

  get isAdminWithBotSource(): boolean {
    return this.isAdmin && (
      this.nlpReviewResult === RiskSystemResultType.MATCH ||
      this.nlpReviewResult === RiskSystemResultType.NO_MATCH ||
      this.nlpReviewResult === RiskSystemResultType.DOS_MATCH_ONLY
    );
  }

  get isBotSource(): boolean {
    return !this.isAdmin && this.nlpReviewResult === RiskSystemResultType.ADD;
  }

  get isBotSourceAccepted(): boolean {
    return !this.isAdmin && this.nlpReviewResult === RiskSystemResultType.ACCEPTED;
  }

  get isManuallyAddedSource(): boolean {
    return !this.isAdmin && this.nlpReviewResult === "";
  }

  get isEveFoundDiagnosis(): boolean {
    return !this.isAdmin && this.isEveDiagnosis;
  }

  get isEveMatch(): boolean {
    return this.nlpReviewResult === RiskSystemResultType.MATCH;
  }

  get diagnosisSource(): string {
    if (this.isAdminWithBotSource) {
      return "Admin";
    } else if (this.isBotSource) {
      return "Bot";
    } else if (this.isBotSourceAccepted) {
      return "Bot \u2713";
    } else if (this.isManuallyAddedSource) {
      return "Coder";
    } else {
      return "Admin";
    }
  }

  get diagnosisSourceV2(): string {
    if (this.isAdminWithBotSource) {
      return "Admin Dx";
    }
    if (this.isManuallyAddedSource) {
      return "Coder Dx";
    }
    if (this.isEveDiagnosis) {
      return "EVE Dx";
    }
    return "Admin Dx";
  }

  get diagnosisStatus(): string {
    if (this.isValid) {
      return "Substantiated";
    }
    if (StringHelper.isAvailable(this.diagnosisValidationResult) && !this.isValid) {
      return "Unsubstantiated";
    }
    return "";
  }

  get isValid(): boolean {
    // TODO: change the first condition 'this.actualVrcs === "01"'
    return this.actualVrcs === DiagnosisValidationResult.NotRequired ||
      this.diagnosisValidationResult === DiagnosisValidationResult.Added ||
      this.diagnosisValidationResult === DiagnosisValidationResult.Valid ||
      this.diagnosisValidationResult === DiagnosisValidationResult.Submittable;
  }

  get diseaseName(): string {
    return StringHelper.isAvailable(this.text) && !StringHelper.isAvailable(this.icd)
      ? this.text
      : `${this.icd}: ${this.icdDescription}`;
  }

  get icdDescription(): string {
    return this.icdCodes.find(icd => icd.code.replace(".", "") === this.icd.replace(".", ""))?.text;
  }

  private diseaseCodesToArray(codes: string): string[] {
    return StringHelper.isAvailable(codes) ?
      codes.replace(/.$/, "")
        .split(";") : [];
  }

  private selectDefaultDiseaseCodes(diseaseCodes: string[], diseaseCode: string): string[] {
    return ArrayHelper.isAvailable(diseaseCodes) && !StringHelper.isAvailable(this.icd)
      ? diseaseCodes
      : this.diseaseCodesToArray(diseaseCode);
  }
}
