import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ExpectedDueDate } from "./expected-due-date.model";

@Injectable({
  providedIn: "root",
})
export class ExpectedDueDateService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  updateDueDate(expectedDueDateUpdateModel: ExpectedDueDate): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/updateExpectedDueDate`;

    return this.http.post(url, expectedDueDateUpdateModel);
  }
}
