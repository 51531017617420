import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { CollapsiblePanelComponent } from "../../../../shared/panel/collapsible-panel/collapsible-panel.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

@Component({
  selector: "app-analytics-filter-applied",
  templateUrl: "./analytics-filter-applied.component.html",
  styleUrls: ["./analytics-filter-applied.component.scss"],
})
export class AnalyticsFilterAppliedComponent {
  @ViewChild(CollapsiblePanelComponent, { static: true }) collapsiblePanel: CollapsiblePanelComponent;
  @Input() totalRecords: number;
  @Input() formFilterRequest: FormGroup;
  analyticsDashboardFilters: SelectableInput[];
  @Input() isRemovableItem = false;
  @Output() removeFilter = new EventEmitter<FormGroup>();
  @Output() isShowFilterModal = new EventEmitter();

  get filteredAnalyticsFilters(): SelectableInput[] {
    this.isRemovableItem = false;
    const filtersValues: any = [];
    if (this.formFilterRequest != null) {
      Object.keys(this.formFilterRequest.controls)
        .map(x => {
          if (ArrayHelper.isAvailable(this.formFilterRequest.controls[x].value)) {
            this.formFilterRequest.controls[x].value.map(y => {
              if (!this.isRemovableItem && y != null) {
                filtersValues.push({ key: x, value: y.text });
              } else if (this.isRemovableItem && y != null && y.text === x) {
                filtersValues.push({ key: x, value: y.value });
              }
            });
          }
          if (StringHelper.isAvailable(this.formFilterRequest.controls[x].value)) {
            filtersValues.push({ key: x, value: this.formFilterRequest.controls[x].value });
          } else if ((x === "products" || "vrcCode") && (this.formFilterRequest.controls[x].value != null
            && this.formFilterRequest.controls[x].value !== "")) {
            if (!isNaN(Date.parse(this.formFilterRequest.controls[x].value))) {
              filtersValues.push({ key: x, value: new Date(this.formFilterRequest.controls[x].value).toLocaleDateString("en-US") });
            }
          }
        });
      this.analyticsDashboardFilters = filtersValues
        .filter(filter => StringHelper.isAvailable(filter.value) || ArrayHelper.isAvailable(filter.value))
        .reduce((chips, filter) => {
          const { key, value } = filter;
          chips.push(new SelectableInput({ text: key, value }));
          return chips;
        },      []);


    }

    return this.analyticsDashboardFilters;
  }

  get groupedFilters(): {name: string; count: number}[] {
    if (!ArrayHelper.isAvailable(this.filteredAnalyticsFilters)) {
      return [];
    }
    const uniqueFilters = [];
    const uniqueKeys = [...new Set(this.filteredAnalyticsFilters.map(item => item?.text))];
    uniqueKeys.forEach(key => {
      const count = this.filteredAnalyticsFilters.filter(f => f.text === key)?.length || 0;
      uniqueFilters.push({name: key, count});
    });
    return uniqueFilters;
  }

  isNotLastElement(index: number, array: any[]): boolean {
    return (index + 1) < array?.length;
  }

  get hasFilters(): boolean {
    return ArrayHelper.isAvailable(this.filteredAnalyticsFilters);
  }

  get collapseIcon(): string {
    return this.collapsiblePanel.primePanel.collapsed ? "caret-right" : "caret-down";
  }

  @HostBinding("style.display")
  get display(): string {
    return this.hasFilters ? "" : "none";
  }

  delete(event: MouseEvent, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    const deletedFilterValues = [...this.analyticsDashboardFilters];
    const deletedFilter = deletedFilterValues.splice(index, 1);
    Object.keys(this.formFilterRequest.value).forEach(key => {
      deletedFilter.forEach(selectedValue => {
        if (selectedValue.text === key) {
          const filteredOptions = this.analyticsDashboardFilters.filter(x => x.text === selectedValue.text);
          if (ArrayHelper.isAvailable(filteredOptions)) {
            const filters = filteredOptions.filter(a => a.value !== selectedValue.value);
            this.formFilterRequest.controls[deletedFilter[0].text].setValue(typeof filters !== "object" ? "" : filters);
          }
        }
      });
    });
    if (!this.hasFilters) {

      this.modifyQuery();
    }
    this.removeFilter.emit(this.formFilterRequest);
    this.isRemovableItem = true;
  }

  modifyQuery(): void {
    this.isShowFilterModal.emit();
  }

  clearAllFilters(): void {
    this.analyticsDashboardFilters = [];
    this.formFilterRequest.reset();
    this.removeFilter.emit(this.formFilterRequest);

  }
  toggle(event: MouseEvent): void {
    this.collapsiblePanel.primePanel.toggle(event);
  }

  trackByIndex(index, item) {
    return index;
  }


}
