import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { ChaseDetailForAuditItem } from "../../chase-detail-for-audit-item.model";
import { AuditQueryGridFilters } from "../audit-query-grid-filters/audit-query-grid-filter-model";

@Injectable({
  providedIn: "root",
})
export class AuditQueryGridService {
  private gridFilters = new BehaviorSubject<AuditQueryGridFilters>(new AuditQueryGridFilters());
  auditGridFilter = this.gridFilters.asObservable();
  auditGridFilterToRefreshGrid: AuditQueryGridFilters;

  private pGridRefreshFromState = new BehaviorSubject(null);
  gridRefreshFromStateFilter = this.pGridRefreshFromState.asObservable();

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  resetGridFilter(value: AuditQueryGridFilters) {
    this.gridFilters.next(value);
    this.auditGridFilterToRefreshGrid = value;
  }

  refreshAuditGrid() {
    this.gridFilters.next(this.auditGridFilterToRefreshGrid);
  }

  reloadAuditGridFromState(value?: any) {
    this.pGridRefreshFromState.next(value);
  }

  saveAuditReadyStatus(chaseAuditData: ChaseDetailForAuditItem): Observable<number> {
    const url = `${this.baseApiUrl}audit/updatechaseauditdata`;

    return this.http.post(url, chaseAuditData)
      .pipe(map((response: any) => response as number));
  }
}
