import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AddressDetailState, IAddressDetailStateOptions } from "./address-detail-state.model";

@Injectable()
export class AddressDetailStateService {
  state = new BehaviorSubject<AddressDetailState>(new AddressDetailState());

  setData(data: IAddressDetailStateOptions): void {
    const newData = new AddressDetailState({ ...this.state.value, ...data });
    this.state.next(newData);
  }

  resetData(data: IAddressDetailStateOptions): void {
    this.state.next(new AddressDetailState(data));
  }

  clearData(): void {
    this.state.next(new AddressDetailState());
  }
}
