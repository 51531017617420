import { OperatorFunction, SchedulerLike, concat } from "rxjs";
import { asyncScheduler } from "rxjs/internal/scheduler/async";
import { debounceTime, publish, take } from "rxjs/operators";

export const debounceTimeAfter = <T>(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, any> => {
  return publish(value =>
    concat(
      value.pipe(take(amount)),
      value.pipe(debounceTime(dueTime, scheduler))
    )
  );
};

export const debounceTimeAfterFirst = <T>(
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, any> => {
  return debounceTimeAfter(1, dueTime, scheduler);
};
