import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { UserService } from "../../core/user/user.service";
import { FormService } from "../../dynamic-forms/form.service";
import { Autocomplete } from "../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { Radiobutton } from "../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { StatesListService } from "../../platform/api/states-list/states-list.service";
import { ClinicalPageService } from "../../platform/modules/clinical/clinical-page/clinical-page.service";
import { PRODUCT_LIST } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../platform/modules/member/chase-detail/chase-detail-chart/chart.service";
import { RetrievalPageService } from "../../platform/modules/retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../platform/modules/service-org-admin/service-org-config/service-org-config.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { DateFormats } from "../../utilities/contracts/helper-types";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { ActionButton } from "../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../zdevcontrols/dev-controller/dev-controller.service";
import { BulkAction } from "../grid/bulk-actions/bulk-action.model";
import { GridView } from "../grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../grid/grid-pipe.enum";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";
import { GridFilter } from "../grid/models/grid-filter.model";
import { GridRequest } from "../grid/models/grid-request.model";
import { ServerGridComponent } from "../grid/server-grid/server-grid.component";
import { CreatePendService } from "../pend/create-pend.service";
import { TagType } from "../tags/model/tag-type.enum";
import { TagService } from "../tags/tag.service";

@Component({
  selector: "app-pend-grid",
  templateUrl: "./pend-grid.component.html",
  styleUrls: ["./pend-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendGridComponent implements OnInit, OnDestroy  {
  private sink = new SubSink();
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  @ViewChild("pendForm") pendForm;
  @Input() url = `${this.baseApiUrl}pends`;
  @Input() additionalColumns: GridColumnDefinition[] = [];
  @Input() additionalFilters: GridFilter[] = [];
  @Input() additionalBulkActions: BulkAction[] = [];
  @Input() selection: any[];
  @Input() isSelectionModeMultiple = false;
  @Input() isLoadOnInit = false;
  @Input() viewAttributeId = 0;
  @Input() showViews = false;
  @Input() stateName = "";
  refreshViews = new EventEmitter<GridView>(true);
  views: GridViewsState;
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  configuration: GridConfiguration;
  request: GridRequest;
  actions: BulkAction[];
  pendId: number;
  allAvailableTags: SelectableInput[];
  overlayPanelVisible = false;
  isShowCloseIcon = false;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  tagType = TagType.CHASE;
  searchTextChanged = new Subject<string>();

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseId");
  }

  get pendIdInput(): Textbox {
    return this.getInput("chasePendId");
  }

  get addressIdInput(): Textbox {
    return this.getInput("MasterDocumentSourceId");
  }

  get ownerInput(): Radiobutton {
    return this.getInput("Owner");
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }

  get pendCodesInput(): CheckboxGroup {
    return this.getInput("PendCodes");
  }
  set pendCodesInput(value: CheckboxGroup) {
    this.setInput("PendCodes", value);
  }

  get pendSeverityInput(): CheckboxGroup {
    return this.getInput("PendSeverityIds");
  }
  set pendSeverityInput(value: CheckboxGroup) {
    this.setInput("PendSeverityIds", value);
  }

  get pendStatusInput(): CheckboxGroup {
    return this.getInput("PendsStatus");
  }
  set pendStatusInput(value: CheckboxGroup) {
    this.setInput("PendsStatus", value);
  }

  get showAssignedToFilter(): boolean {
    const assignedFilter = this.request.getFilter("AssignedToUserId");
    return assignedFilter.show;
  }
  get assignedToInput(): Autocomplete {
    return this.getInput("AssignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.setInput("AssignedToUserId", value);
  }

  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasureIds");
  }
  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasureIds", value);
  }

  get addressGroupNameInput(): Textbox {
    return this.getInput("GroupName");
  }

  get addressCityInput(): Textbox {
    return this.getInput("City");
  }

  get companyIdToInput(): Autocomplete {
    return this.getInput("CompanyId");
  }
  set companyIdToInput(value: Autocomplete) {
    this.setInput("CompanyId", value);
  }

  get addressStateInput(): Autocomplete {
    return this.getInput("State");
  }
  set addressStateInput(value: Autocomplete) {
    this.setInput("State", value);
  }

  get productsInput(): Autocomplete {
    return this.getInput("Product");
  }
  set productsInput(value: Autocomplete) {
    this.setInput("Product", value);
  }

  get sampleComplianceInput(): CheckboxGroup {
    return this.getInput("SampleComplianceCodesAsCsv");
  }
  set sampleComplianceInput(value: CheckboxGroup) {
    this.setInput("SampleComplianceCodesAsCsv", value);
  }

  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv");
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator");
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly devService: DevControllerService,
    private readonly router: Router,
    private stateService: StatesListService,
    private createPendService: CreatePendService,
    private retrievalService: RetrievalPageService,
    private gridViewsService: GridViewsService,
    private readonly automapper: AutomapperService,
    private readonly tagService: TagService,
    private readonly formService: FormService,
    private readonly userService: UserService,
    private readonly chartService: ChartService,
    private readonly clinicalPageService: ClinicalPageService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService
  ) { }

  ngOnInit(): void {
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);
    this.getAllSelectableInputs();
    this.createPendGrid();
    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView)),
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getAllTagsList(searchText)))
          .subscribe()
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  getAllSelectableInputs(): void {
    this.getProjects();
    this.getPendCodeSet();
    this.getPendStatus();
    this.getMeasures();
    this.getAssignedToUsers();
    this.getCompanies();
    this.getStateListForAddress();
    this.getProducts();
    this.getComplianceCodes();
    this.getTagSearchOperator();
  }

  updateGridConfiguration(alreadyInitialized = true) {
    this.configuration = new GridConfiguration({
      isInit: alreadyInitialized,
      stateName: this.stateName,
      columns: this.getColumns(),
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      selectionMode: this.isSelectionModeMultiple ? "multiple" : "",
      showViews: this.showViews,
      viewAttributeId: this.viewAttributeId,
    });
  }

  createPendGrid(): void {
    this.updateGridConfiguration(false);
    this.request = new GridRequest({
      url: this.url,
      filters: this.getFilters(),
    });
    this.getViews();
    this.actions = this.getActions();
  }

  private getColumns(): GridColumnDefinition[] {
    const totalColumns = [
      new GridColumnDefinition({ field: "chasePendId", header: "Pend ID", routeUrl: "/pend/detail/:chasePendId" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
      new GridColumnDefinition({ field: "pendSeverityName", header: "Pend Severity" }),
      new GridColumnDefinition({ field: "pendTypeDescription", header: "Pend Description", title: "pendTypeDescription" }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "owner", header: "Owner" }),
      new GridColumnDefinition({ field: "assignedToUser", header: "Assigned To" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
      new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID" }),
      new GridColumnDefinition({ field: "addressGrouping", header: "AID Group" }),
      new GridColumnDefinition({ field: "pendCompanyName", header: "Invoice Company" }),
      new GridColumnDefinition({ field: "amount", header: "Invoice Amount" }),
      new GridColumnDefinition({ field: "product", header: "Product" }),
      new GridColumnDefinition({ field: "groupName", header: "Address Group" }),
      new GridColumnDefinition({ field: "city", header: "City" }),
      new GridColumnDefinition({ field: "state", header: "State" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "createdDate", header: "Creation Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "lastUpdatedDate", header: "Last Updated", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
      new GridColumnDefinition({ field: "taxPayerId", header: "TIN" }),
    ];
    const distinctColumns = totalColumns.map(obj =>
      this.additionalColumns.find(col =>
        col.field === obj.field) || obj);

    return distinctColumns;
  }

  private getFilters(): GridFilter[] {

    const totalFilters = [
      ...this.additionalFilters,
      new GridFilter({
        input: new Textbox(),
        key: "searchFilterOption",
        value: "1",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChaseId",
        name: "Chase Id",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "MasterDocumentSourceId",
        name: "AID",
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Organization", value: "Organization" }),
            new SelectableInput({ text: "Client", value: "Client" }),
          ],
        }),
        key: "Owner",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "Projects",
      }),
      new GridFilter({
        input: new CheckboxGroup({showTooltip: true}),
        key: "PendCodes",
        name: "Pend Codes",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "PendSeverityIds",
        name: "Pend Severity",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "PendsStatus",
        name: "Pend Status",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "AssignedToUserId",
        name: "Assigned To",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "MeasureIds",
        name: "Measures",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select Company..." }),
        key: "CompanyId",
        name: "Invoice Company",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select State..." }),
        key: "State",
        name: "State",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "GroupName",
        name: "Group Name",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "City",
        name: "City",
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select Product..." }),
        key: "Product",
        name: "Product",
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ClientId",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "chasePendId",
        name: "Pend ID",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "SampleComplianceCodesAsCsv",
        name: "Sample Compliance",
      }),
      new GridFilter({
        input: new TagSearchMultiselect({ placeholder: "Tags" }),
        key: "TagIdsAsCsv",
        name: "Tags",
      }),
      new GridFilter({
        input: new Dropdown(),
        key: "TagSearchOperator",
        value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
          ? this.selectedTagSearchOperator : null,
        showChip: false,
      }),
    ];

    const distinctFilters = totalFilters.filter((gridFilters, i, filters) => {
      return filters.findIndex(a => a.key === gridFilters.key) === i;
    });
    return distinctFilters;
  }

  private getActions(): BulkAction[] {
    const totalBulkActions = [
      ...this.additionalBulkActions,
      new BulkAction({
        name: "View Details",
        action: this.viewDetails.bind(this),
        showBulkAction: false,
        disabled: false,
      }),
    ];

    const distinctBulkActions = totalBulkActions.filter((filter, i, filters) => {
      return filters.findIndex(a => a.name === filter.name) === i;
    });

    return distinctBulkActions;
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.configuration.showViews) {
      this.gridViewsService.get(this.configuration.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  getValue(key: string): string {
    if (this.request == null) {
      return "";
    }

    const filter = this.request.getFilter(key);
    return filter.value;
  }

  setValue(key: string, value: string): void {
    if (this.request == null) {
      return;
    }

    const filter = this.request.getFilter(key);
    filter.value = value;
  }

  private getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectsInput = { ...this.projectsInput, options: result } as any;
        this.setProjectsInput();
        this.changeDetector.markForCheck();
      });
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  getAllTagsList(searchText: string): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null, searchText)
      .subscribe(data => {
        this.allAvailableTags = data;
        this.tagsInput.useFilter = false;
        this.tagsInput.options = [...this.allAvailableTags];
        this.changeDetector.markForCheck();
      });
  }

  private getTagSearchOperator(): void {
    this.tagService
      .getTagSearchOperator()
      .subscribe(options => {
        this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }

  private setProjectsInput(): void {
    const projectOption = this.projectsInput;
    const projectFilter = this.request.getFilter(this.projectsInput.key);

    if (StringHelper.isAvailable(projectFilter.value)) {
      const filteredProjects = projectFilter.value.split(/\s*,\s*/);
      projectFilter.inputValue = [];
      filteredProjects.forEach(selectedProject => {
        projectFilter.inputValue.push(projectOption.options.find(a => a.value === +selectedProject));
      });
      this.formService.updateDom.next();
    }
  }

  private getPendCodeSet(): void {
    this.createPendService
      .getPendDropdown(null, null)
      .pipe(map((result: any) => {
        const pendCodes = result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
        const severity = result.pendSeverity.map(this.automapper.curry("PendSeverity", "SelectableInput"));
        return { pendCodes, severity };
      }))
      .subscribe(({ pendCodes, severity }: any) => {
        this.pendCodesInput = { ...this.pendCodesInput, options: pendCodes } as any;
        this.pendSeverityInput = { ...this.pendSeverityInput, options: severity } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getPendStatus(): void {
    this.createPendService
      .getPendDropdown(false)
      .pipe(map((result: any) => {
        return result.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
      }))
      .subscribe(result => {
        this.pendStatusInput = { ...this.pendStatusInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => {
        this.measuresInput = { ...this.measuresInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getAssignedToUsers(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getStateListForAddress(): void {
    this.stateService.getStateList()
      .subscribe(stateList => {
        stateList.unshift(new SelectableInput({ text: "Select State", value: "" }));
        this.addressStateInput = { ...this.addressStateInput, options: stateList } as any;
        this.formService.updateDom.next();
      });
  }

  private getCompanies(): void {
    this.createPendService.getPendCompaniesDropdown()
      .pipe(map(data => data.map(item => new SelectableInput({ text: item.value, value: item.key }))))
      .subscribe(data => {
        data.unshift(new SelectableInput({ text: "Select Company", value: "" }));
        this.companyIdToInput = { ...this.companyIdToInput, options: data } as any;
        this.formService.updateDom.next();
      });
  }

  private getProducts(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PRODUCT_LIST.attributeId)
      .subscribe(configuration => {
        this.getProductsInput(configuration);
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
  }

  private getProductsInput(configuration: ServiceOrgAttribute): void {
    const productAttribute = JSON.parse(configuration.attributeValue);
    const productOptions = productAttribute.Products.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    productOptions.unshift(new SelectableInput({ text: "*clear filter", value: "" }));
    this.productsInput = new Autocomplete({ ...this.productsInput, options: productOptions } as any);
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.sampleComplianceInput = { ...this.sampleComplianceInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  isActionDisabled(pendStatus?: string, actionName?: string): boolean {
    switch (actionName) {
      case "Change Owner & Assign":
      case "REQUEST TO CLOSE":
      case "CHANGE STATUS":
        return (pendStatus === "Closed") || (pendStatus === "Resolved");

      case "View Details":
        return false;
      default:
        return true;
    }
  }

  viewDetails(rowData: any): void {
    const selectedRows = rowData;
    this.pendId = selectedRows.chasePendId;
    this.router.navigate(["pend", "detail", this.pendId]);
  }

}
