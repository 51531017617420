import { IAutoMapper } from "../interfaces";

export const mapDataSetItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DataSet")
    .forMember("data", o => o.data)
    .forMember("labels", o => o.labels)
    .forMember("urls", o => o.urls)
    .forMember("noOfChases", o => o.noOfChases)
    .forMember("ids", o => o.ids);

};
