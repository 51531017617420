<div class="diagnoses-header">
  <app-icon iconName="user"></app-icon>&nbsp;
  <div class="diagnoses-header__title">EVE Dx</div>
  <app-badge [text]="totalDiagnoses" [isLarge]="false" cssClasses="badge-diagnoses counter"></app-badge>
</div>
<member-risk-read-only [isReadOnly]="showReadOnly"></member-risk-read-only>

<p-accordion (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)" class="app-accordion">
  <p-accordionTab *ngFor="let eveCodedDiagnosis of eveCodedDiagnoses; trackBy: trackByIndex; let i = index"
                  [(selected)]="activeState[i]"
                  class="app-accordion_card"
                  id="eve-{{eveCodedDiagnosis.id}}">
    <p-header>
      <app-card-header [diagnosis]="eveCodedDiagnosis" [badgeStatus]="getBadgeStatus(eveCodedDiagnosis, i)"
        [isCardOpen]="activeState[i]" [hasDeleteEnabled]="activeState[i] && (isOverread || isOverread2)" (onDelete)="showDeleteDiagnosisModal($event)"
        [showBadge]="!showReadOnly">
      </app-card-header>
    </p-header>
    <div class="diagnosis-heading" *ngIf="checkScoringCheckbox(eveCodedDiagnosis)">
      <div class="diagnosis-heading--code">ICD</div>
      <div class="diagnosis-heading--description">ICD Description</div>
      <div class="diagnosis-heading--score">Confidence Score <app-tool-tip [text]="evidenceScoringTooltip"></app-tool-tip></div>
    </div>
    <div *ngFor="let icdCode of eveCodedDiagnosis.icdCodes; index as idx; trackBy: trackByIndex">
      <ng-container *ngIf="activeState[i]">
        <member-diagnosis-scoring-checkbox
          [isMultipleCodingModel]="isMultipleCodingModel(eveCodedDiagnosis)"
          [icdCode]="icdCode"
          [index]="idx"
          [idx]="i"
          (checkboxVal)="selectedCheckbox($event, i)"
          [enableCheckbox]="enabledCard"
          [checkboxStatus]="getCheckedValue(icdCode, eveCodedDiagnosis, i, idx)">
        </member-diagnosis-scoring-checkbox>
      </ng-container>
    </div>
    <form-factory class="diagnosis-save-group" [formGroup]="form" [model]="saveGroup" (keyup)="handleKeyUp()" (onChange)="handleChange($event)">
    </form-factory>
    <div>
      <app-button [disabled]="!isSaveEnabled" [class.disabled]="!isSaveEnabled" class="diagnosis-save-button" title="Submit" (onClick)="saveDiagnosis()" text="Save Diagnosis"></app-button>
      <div class="exempt" *ngIf="isOverread">
        <form-factory [formGroup]="form" [model]="exemptInput" (onChange)="tryEnablingSaveButton()"></form-factory>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<app-confirmation-modal class="delete-diagnosis-modal" [(visible)]="isConfirmDeleteVisible" header="WARNING"
  acceptText="DELETE" rejectText="CANCEL" (onYes)="deleteDiagnosis()">
  <b>Are you sure you want to delete this diagnosis?</b>
</app-confirmation-modal>
