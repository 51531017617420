import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { AddressGridComponent } from "../../../../shared/address-grid/address-grid.component";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ListItem } from "../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { RETRIEVAL_EMR_GRID, RETRIEVAL_FT_GRID, RETRIEVAL_PSR_GRID, RETRIEVAL_SH_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { AppointmentService } from "../appointment/appointment.service";
import { ActionItem } from "./retreival-page-action-item";
import { RetrievalPageService } from "./retrieval-page.service";

@Component({
  selector: "app-retrieval-page",
  templateUrl: "./retrieval-page.component.html",
  styleUrls: ["./retrieval-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalPageComponent implements OnInit {
  get assignedToInput(): Autocomplete {
    return this.getInput("AssignedTo") as Autocomplete;
  }
  set assignedToInput(value: Autocomplete) {
    this.setInput("AssignedTo", value);
  }


  constructor(
    private retrievalService: RetrievalPageService,
    private cd: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private messagingService: MessagingService,
    private automapper: AutomapperService,
    private readonly authService: AuthService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private parameterService: ParameterService,
    private appointmentService: AppointmentService
  ) { }

  @ViewChild(AddressGridComponent) addressGridComponent: AddressGridComponent;
  url = `${this.baseApiUrl}retrieval/list`;
  stateName: string;
  viewAttributeId: number;
  additionalFilters: GridFilter[] = [];
  additionalBulkActions: BulkAction[] = [];
  isLoadOnInit = false;
  private isInitialized = false;
  appointmentGridRefresh = new EventEmitter<null>(true);

  headerTitle = "";
  headerStatistics = List<ListItem>();
  retrievalType = "";
  statisticsFilter: string;
  retrievalUserList: SelectableInput[] = [];
  filters: any[];

  form: FormGroup;
  selectedTab = "overview";
  appointmentActionItems: ActionItem[] = [];
  appointmentsGridConfiguration = new GridConfiguration();
  searchAppointmentsRequest: GridRequest;
  appointmentsGridSelection: any[];
  isAppointmentModalVisible = false;
  selectedStat: string;

  retrievalUserListGridConfiguration = new GridConfiguration();
  retrievalUserListGridData: any[] = [];
  retrievalUserListGridSelection: any[];

  selectedRowData: any;
  addressId: number;
  appointmentId: number;
  isCancelConfirmationModalVisible = false;
  setSourceTypeName: string;
  displayCheckedItemsInGridView: {value: string; disabled: boolean}[] = [];
  get isPsr(): boolean {
    return this.retrievalType === "psr";
  }

  get isOverview(): boolean {
    return this.selectedTab === "overview";
  }

  get isScheduled(): boolean {
    return this.selectedTab === "scheduled";
  }

  get isSpecialHandling(): boolean {
    return this.retrievalType === "sh";
  }

  get isScheduleTabVisible(): boolean {
    return !this.isPsr && !this.isSpecialHandling;
  }

  get isGridMultiSelect(): boolean {
    return this.isPsr || this.isSpecialHandling;
  }

  addressQueueFunctions = {
    openScheduleAppointmentModal: (rowData: any) => {
      this.selectedRowData = this.automapper.map("ScheduleRowData", "Appointment", rowData);
      this.addressId = this.selectedRowData.masterDocumentSourceId;
      this.isAppointmentModalVisible = true;
      this.cd.markForCheck();
    },
  };

  appointmentGridActions = {
    editAppointment: (rowData: any) => {
      this.selectedRowData = this.automapper.map("EditRowData", "Appointment", rowData);
      this.addressId = this.selectedRowData.masterDocumentSourceId;
      this.appointmentId = this.selectedRowData.appointmentId;
      this.isAppointmentModalVisible = true;
      this.cd.markForCheck();
    },

    cancelAppointment: (rowData: any) => {
      this.selectedRowData = this.automapper.map("EditRowData", "Appointment", rowData);
      this.addressId = this.selectedRowData.masterDocumentSourceId;
      this.appointmentId = this.selectedRowData.appointmentId;
      this.isCancelConfirmationModalVisible = true;
      this.cd.markForCheck();
    },
  };

  ngOnInit() {
    this.displayCheckedItemsInGridView.push({value: "defaultSpecialHandling", disabled: true});
    this.route.paramMap.subscribe(params => {
      this.selectedTab = "overview";
      this.additionalBulkActions = [];
      this.retrievalType = this.parameterService.getNormal("type", "psr");
      switch (this.retrievalType) {
        case "emr":
          this.headerTitle = "EMR";
          this.appointmentActionItems = [
            new ActionItem("Edit Appointment", "editAppointment"),
            new ActionItem("Cancel Appointment", "cancelAppointment"),
          ];
          this.additionalBulkActions = [
            new BulkAction({
              name: "Schedule",
              action: this.addressQueueFunctions.openScheduleAppointmentModal,
            }),
          ];
          this.stateName = RETRIEVAL_EMR_GRID.attributeCode;
          this.viewAttributeId = RETRIEVAL_EMR_GRID.attributeId;
          break;
        case "ft":
          this.headerTitle = "Field Tech Scheduling";
          this.appointmentActionItems = [
            new ActionItem("Edit Appointment", "editAppointment"),
            new ActionItem("Cancel Appointment", "cancelAppointment"),
          ];
          this.additionalBulkActions = [
            new BulkAction({
              name: "Schedule",
              action: this.addressQueueFunctions.openScheduleAppointmentModal,
            }),
          ];
          this.stateName = RETRIEVAL_FT_GRID.attributeCode;
          this.viewAttributeId = RETRIEVAL_FT_GRID.attributeId;
          break;
        case "sh":
          this.headerTitle = "Special Handling";
          this.additionalBulkActions = [
            new BulkAction({
              name: "Schedule",
              action: this.addressQueueFunctions.openScheduleAppointmentModal,
              showBulkAction: false,
            }),
          ];
          this.stateName = RETRIEVAL_SH_GRID.attributeCode;
          this.viewAttributeId = RETRIEVAL_SH_GRID.attributeId;
          break;
        default:
        case "psr":
          this.headerTitle = "Call Center Rep";
          this.stateName = RETRIEVAL_PSR_GRID.attributeCode;
          this.viewAttributeId = RETRIEVAL_PSR_GRID.attributeId;
          break;
      }

      this.getAssignedToUserList();
      this.getstats();
      this.statisticsFilter = "Site";
      if (!this.isPsr) {
        this.loadRetrievalUsersGrid(this.retrievalType);
      }

      this.appointmentsGridConfiguration = new GridConfiguration({
        columns: [
          new GridColumnDefinition({ field: "appointmentId", header: "AppointmentID" }),
          new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AddressID" }),
          new GridColumnDefinition({ field: "masterDocumentSourceName", header: "Address" }),
          new GridColumnDefinition({ field: "appointmentStartTime", header: "Appointment Start Time" }),
          new GridColumnDefinition({ field: "appointmentEndTime", header: "Appointment End Time" }),
          new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
          new GridColumnDefinition({ field: "chaseCount", header: "Chases" }),
          new GridColumnDefinition({ field: "notes", header: "Notes" }),
        ],
        pageSize: 25,
        showActionColumn: true,
      });
      this.searchAppointmentsRequest = new GridRequest({
        url: `${this.baseApiUrl}retrieval/appointments/list`,
        filters: [
          new GridFilter({
            input: new Textbox(),
            key: "RetrievalType",
            value: this.retrievalType === "emr" ? "EMR" : "FT",
            show: false,
          }),
          new GridFilter({
            input: new Autocomplete({ placeholder: "Select User" }),
            key: "AssignedTo",
            name: "Assigned To",
          }),
        ],
      });

      this.retrievalUserListGridConfiguration = new GridConfiguration({
        columns: [
          new GridColumnDefinition({ field: "userName", header: "Name" }),
          new GridColumnDefinition({ field: "nextAppointmentDate", header: "Next Appointment" }),
          new GridColumnDefinition({ field: "appointmentCount", header: "Appointments" }),
          new GridColumnDefinition({ field: "chaseCount", header: "Chases" }),
        ],
        pageSize: 25,
        showActionColumn: false,
      });

      setTimeout(() => this.refreshAppointmentGrid(), 300); // TODO: Find better fix.

      this.createAddressGrid();
      if (this.isInitialized) {
        // HACK: the serverGridComponent doesn't update fast enough in this situation.
        setTimeout(() => {
          this.addressGridComponent.additionalFilters = this.additionalFilters;
          this.addressGridComponent.createGrid();
          this.addressGridComponent.serverGridComponent.configuration = this.addressGridComponent.configuration;
          this.addressGridComponent.serverGridComponent.request = this.addressGridComponent.request;
          this.addressGridComponent.getAllSelectableInputs();
          this.addressGridComponent.getTagSearchOperator();
          this.addressGridComponent.showFiltersOrGetData();
        });
      }
      this.isInitialized = true;
      this.cd.markForCheck();
    });
  }

  toggleTab(tab: string): void {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
  }

  getStatisticsFilter(filterName: string): void {
    this.statisticsFilter = filterName === "Site" ? null : filterName;
    this.selectedStat = filterName;
    this.addressGridComponent.setStaticFilter(this.statisticsFilter);
    this.addressGridComponent.request.startRecord = 0;
    this.addressGridComponent.serverGridComponent.defaultPageSize = 10;
    this.addressGridComponent.refreshGrid.emit();
  }

  getClass(item: ListItem): string {
    return item.key === this.selectedStat ? "activeStat" : "clearStat";
  }

  cancelAppointment(): void {
    this.appointmentService.delete(this.addressId, this.appointmentId).subscribe({
      next: () => {
        this.messagingService.showToast("Appointment cancelled successfully.", SeverityType.SUCCESS);
        this.updatePageAfterAppointmentSave();
        this.resetModalProperties();
        this.isCancelConfirmationModalVisible = false;
        this.cd.markForCheck();
      },
      error: () => this.messagingService.showToast("Error while canceling Appointment, please try again.", SeverityType.ERROR),
    });
  }

  resetModalProperties(): void {
    this.selectedRowData = null;
    this.addressId = null;
    this.appointmentId = null;
  }

  updatePageAfterAppointmentSave(): void {
    this.statisticsFilter = "Site";
    this.refreshAppointmentGrid();
    if (!this.isPsr) {
      this.loadRetrievalUsersGrid(this.retrievalType);
    }
  }

  private createAddressGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new CheckboxGroup(),
        key: "NavigationType",
        name: "Retrieval Type",
        show: false,
        value: this.retrievalType,
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "RetrievalType",
        name: "Retrieval Type",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "SpecialHandlingType",
        show: this.isSpecialHandling,
      }),
    ];
  }

  private getstats(): void {
    this.retrievalService
      .getStatisticsData(this.retrievalType)
      .subscribe(result => {
        this.headerStatistics = List(result);
        this.cd.markForCheck();
      });
  }

  private loadRetrievalUsersGrid(retrievaltype): void {
    this.retrievalService
      .getRetrievalUsers(retrievaltype)
      .subscribe(items => {
        this.retrievalUserListGridData = items as any;
        this.cd.markForCheck();
      });
  }

  private refreshAppointmentGrid(): void {
    this.appointmentGridRefresh.emit();
  }

  private getAssignedToUserList(): void {
    this.retrievalService.getAssignedToUsersFilterList(this.sourceTypeName)
      .subscribe(users => {
        ArrayHelper.addItemToSelect(users, "@Me", this.authService.userId);
        this.assignedToInput = { ...this.assignedToInput, options: users } as any;
        this.cd.markForCheck();
      });
  }

  private getFilter(key: string): GridFilter {
    if (this.searchAppointmentsRequest == null) {
      return null;
    }

    return this.searchAppointmentsRequest.getFilter(key);
  }

  private getInput(key: string): DynamicInput {
    if (this.searchAppointmentsRequest == null) {
      return null;
    }

    return this.searchAppointmentsRequest.getInput(key);
  }

  private setInput(key: string, value: DynamicInput): void {
    if (this.searchAppointmentsRequest == null) {
      return null;
    }

    this.searchAppointmentsRequest.setInput(key, value);
  }

  get sourceTypeName(): string {
    return this.setSourceTypeName = this.headerTitle.toLowerCase() === "field tech scheduling" ? "Field Tech" : this.headerTitle;
  }

  getClassForColumn(): string {
    const columnClass = "psr-address-grid";
    return (this.retrievalType !== "psr") ? "address-grid" : columnClass;
  }

  trackByIndex(index, item) {
    return index;
  }

  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }
}
