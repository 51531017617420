import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-client-overread2-dw",
  templateUrl: "./client-overread2-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOverread2DWComponent {
  filters: string[] = ["Projects", "Overread2Date"];
  dashboardType = LookerRiskDashboardType.CLIENT_OVERREAD2_REPORT_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.MOVEBACK_DATE,
                           HideFilters.HEALTH_PLAN, HideFilters.LINE_OF_BUSINESS, HideFilters.CONTRACT_NUMBER,
                           HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.OVERREAD2_BY, HideFilters.OVERREAD2_DATE, HideFilters.MEASURE2];
}
