import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ModalComponent } from "../../../../../shared/panel/modal/modal.component";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { VendorDetailStateService } from "../../../invoice/vendor-detail-info/vendor-detail-state.service";
import { RetrievalAddressDetailContactHistoryService } from "../../address-detail/address-detail-contact-history/address-detail-contact-history.service";
import { AddressDetailStateService } from "../../address-detail/address-detail-state.service";
import { AddressTimelineStateService } from "../../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../address-timeline/address-timeline.service";
import { ContactMethodType } from "../../cover-letter-template/contact-method-type.enum";
import { ContactRecord } from "../../psr/address-detail/address-detail-contact-record";
import { CallNotesModel } from "../../retrieval-call-flow/callNotes-model";
import { CallNotesService } from "./call-notes.service";

@Component({
  selector: "app-call-notes",
  templateUrl: "./call-notes.component.html",
  styleUrls: ["./call-notes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallNotesComponent extends ModalComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  formGroup: FormGroup;
  callNotes: Textarea;
  callNotesSave: CallNotesModel;
  addressId: number;
  vendorId: number;
  readonly callNotesCallType = "MRCSCallNotesSave";
  readonly noteError: string = "Write a note between 4 - 1100 characters.";

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private readonly callNotesService: CallNotesService,
    private readonly formService: FormService,
    private messagingService: MessagingService,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private vendorDetailStateService: VendorDetailStateService,
    private retrievalAddressDetailContactHistoryService: RetrievalAddressDetailContactHistoryService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeForm();
    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressId = state.masterDocumentSourceId;
      }),
      this.vendorDetailStateService.state.subscribe(state => {
        if (state != null && state.hasVendorId) {
          this.vendorId = state.vendorId;
        }
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  change(event: boolean): void {
    this.formGroup.reset();
    super.change(event);
  }

  saveCallNotes(): void {
    this.formService.markAllAsTouched(this.formGroup);
    if (this.formGroup.invalid) {
      this.messagingService.showToast(this.noteError, SeverityType.ERROR);
      return;
    }

    const contactRecord =
      new ContactRecord({
        addressId: this.addressId,
        vendorId: this.vendorId,
        contactResultType: null,
        contactMethodType: (NumberHelper.isAvailable(this.addressId) || this.addressId != undefined)
          ? ContactMethodType.Note : ContactMethodType.VendorNote,
        calledAddress: false,
        callNotes: this.formGroup.get("callNotes").value,
      });
    this.hide();
    this.callNotesService.saveCallNotes(contactRecord, this.callNotesCallType).subscribe(
      () => {
        this.messagingService.showToast("Call Notes successfully saved.", SeverityType.SUCCESS);
        this.fetchTimelineItems();
        this.addressDetailStateService.setData(this.addressDetailStateService.state.value);
        this.vendorDetailStateService.setData(this.vendorDetailStateService.state.value);
        this.retrievalAddressDetailContactHistoryService.refreshContactHistoryEvent(true);
      },
      err => {
        this.messagingService.showToast("Failed to save Call Notes.", SeverityType.ERROR);
      }
    );
  }

  private initializeForm(): void {

    this.callNotes = new Textarea({
      key: "callNotes",
      placeholder: "Type here to add note.",
      label: "Note",
      rows: 10,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required, Validators.minLength(4), Validators.maxLength(1100),
      ],

      errorMessages: {
        required: this.noteError,
        minlength: this.noteError,
        maxlength: this.noteError,
      },

    });
    this.formGroup = this.formService.createFormGroup([this.callNotes]);
  }

  private fetchTimelineItems(): void {
    this.addressTimelineService
      .get(this.addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }
}
