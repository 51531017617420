import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UserToken } from "../../auth/user-token.model";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { MessagingService } from "../messaging/messaging.service";
import { SeverityType } from "../messaging/severity-type.enum";
import { HttpStatusCode } from "../meta/http-status-code.enum";
import { UserService } from "../user/user.service";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  user: UserToken;

  constructor(
    private messagingService: MessagingService,
    private userService: UserService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.user = this.userService.getUserToken();

    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // This is where you would handle logging, success response, etc
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case HttpStatusCode.UnknownError:
                if (this.user.isReadOnlyErrorMessageSuppressed) {
                  this.messagingService.showMessage(
                    `An unexpected error has occurred.
                    URL: ${req?.url}
                    Method: ${req?.method}
                    Response Type: ${req?.responseType}
                    Status Code: ${error?.status}
                    Timestamp: ${new Date().toISOString()}`,
                    SeverityType.ERROR);
                  this.addErrorTracking(req, error);
                } else {
                  if (this.user.isReadOnlyRole) {
                    this.messagingService.showMessage("This action cannot be performed by a Read-Only User", SeverityType.ERROR);
                  }
                }
                break;
              case HttpStatusCode.FailedLogin:
                if (this.isAvailableError(error)) {
                  const message = StringHelper.clean(error.error);
                  this.messagingService.showMessage(message, SeverityType.ERROR, "loginMessage");
                }
                break;
              case HttpStatusCode.ServiceUnavailable:
                if (this.isAvailableError(error)) {
                  const message = StringHelper.clean(error.error);
                  this.messagingService.showMessage("Sorry for the brief interruption. Please try again.", SeverityType.ERROR);
                }
                break;
              case HttpStatusCode.BrokenRule:
              case HttpStatusCode.BadRequest:
                if (this.isAvailableError(error)) {
                  const message = StringHelper.clean(error.error);
                  const updatedMessage = message?.includes("ChartPageNumber") ? message.replace("ChartPageNumber", "Page #") : message;
                  this.messagingService.showMessage(updatedMessage, SeverityType.ERROR);
                }
                break;
              default:
                break;
            }
          }
        }
      )
    );
  }

  private addErrorTracking(req: HttpRequest<any>, error: HttpErrorResponse): void {
    console.group("Errror track information");
    console.log("URl: ", req?.url);
    console.log("Method: ", req?.method);
    console.log("Response type: ", req?.responseType);
    console.log("Status code: ", error?.status);
    console.log("Timestamp: ", new Date().toISOString());
    console.groupEnd();
  }

  isAvailableError({ error }: HttpErrorResponse): boolean {
    return StringHelper.isAvailable(error) && error !== "null";
  }
}
