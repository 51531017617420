import { StringHelper } from "../../../utilities/contracts/string-helper";

export class ChartModel {
  readonly chartHeader: string;
  readonly chartType: string;
  readonly chartWidth: number;
  readonly chartHeight: number;
  readonly headerClassName: string;
  readonly isResponsive: boolean;
  readonly canvasClassName: string;

  constructor(options: {
    chartHeader?: string;
    chartType?: string;
    chartWidth?: number;
    chartHeight?: number;
    headerClassName?: string;
    isResponsive?: boolean;
    canvasClassName?: string;
  }) {
    this.chartHeader = StringHelper.clean(options.chartHeader);
    this.chartType = StringHelper.clean(options.chartType);
    this.chartWidth = options.chartWidth;
    this.chartHeight = options.chartHeight;
    this.headerClassName = StringHelper.clean(options.headerClassName);
    this.isResponsive = options.isResponsive;
    this.canvasClassName = StringHelper.clean(options.canvasClassName);
  }
}
