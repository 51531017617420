import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { ChaseAssignService } from "../../../api/unassign-chase/chase-assign.service";
import { ClinicalPageService } from "../../clinical/clinical-page/clinical-page.service";
import { ProviderPacketItem } from "../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { ChaseQueryService } from "../../project/chase-query/chase-query.service";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";

@Component({
  selector: "reporting-chase-list",
  templateUrl: "./chase-list.component.html",
  styleUrls: ["./chase-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseListComponent implements DoCheck, OnInit {
  gridRefresh = new EventEmitter<null>();
  serverGridSelection: any[];
  @ViewChild(ServerGridComponent, { static: true }) private serverGridComponent: ServerGridComponent;
  totalChasesCount: number;
  totalChasesMessage: string;
  defaultTotalCount = 500;
  selectedValue: any[];
  chaseId: number;
  isCreatePendView = false;
  isEditPendView = false;
  chasePendId: number;
  chaseIds: number[] = [];
  chasePendIds: number[] = [];
  pendIds: number[] = [];
  @Output() formClose = new EventEmitter(); // TODO: Remove?...
  providerPacketItem: ProviderPacketItem;
  isAssignModalVisible = false;
  clinicalPageUserList: SelectableInput[];
  isChaseAssignDisabled = true;
  chaseGridConfiguration = new GridConfiguration();
  chaseSearchRequest: GridRequest;
  projectStatisticIds: string;
  chaseAssignForm: FormGroup;
  userInput: Dropdown;

  get chaseIdInput(): Textbox {
    return this.getInput("Id") as Textbox;
  }
  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects") as CheckboxGroup;
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }
  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasuresCodes") as CheckboxGroup;
  }
  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasuresCodes", value);
  }
  get statusesInput(): CheckboxGroup {
    return this.getInput("Statuses") as CheckboxGroup;
  }
  set statusesInput(value: CheckboxGroup) {
    this.setInput("Statuses", value);
  }

  selectedChases: any[];
  isPendModalVisible = false;

  constructor(
    private route: ActivatedRoute,
    private service: ChaseQueryService,
    private changeDetector: ChangeDetectorRef,
    private retrievalService: RetrievalPageService,
    private messagingService: MessagingService,
    private router: Router,
    private clinicalPageService: ClinicalPageService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly chaseAssignService: ChaseAssignService,
    private readonly automapper: AutomapperService,
    private formService: FormService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectStatisticIds = window.sessionStorage.getItem("ProjectStatisticIds");
      window.sessionStorage.removeItem("ProjectStatisticIds");
      this.getProjectList();
      this.getReportingStatuses();
      this.getMeasuresList();
      this.createGrid();
    });
  }


  private createGrid(): void {
    this.chaseGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase Id", routeUrl: "/members/chase/:chaseId" }),
        new GridColumnDefinition({ field: "projectName", header: "Project Name" }),
        new GridColumnDefinition({ field: "memberName", header: "Member Name " }),
        new GridColumnDefinition({ field: "memberId", header: "Client Member Id" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "chaseSourceAliasId", header: "Client Chase Key" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "Address Id", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
        new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
        new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
        new GridColumnDefinition({ field: "chaseComplianceCode", header: "Compliance" }),
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "workflowStatusName", header: "workflow Status", show: false }),
        new GridColumnDefinition({ field: "chasePendId", header: "Chase Pend Id", show: false }),
      ],
      pageSize: 25,
      selectionMode: "multiple",
      bulkActions: [
        new BulkAction({
          name: "Pend Chase(s)",
          action: this.openPendModal.bind(this),
        }),
        new BulkAction({
          name: "Assign Chase(s)",
          action: items => this.openAssignReassignModal(),
        }),
      ],
    });

    this.chaseSearchRequest = new GridRequest({
      url: `${this.baseApiUrl}reporting/chase/list`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "ProjectStatisticsIds",
          value: this.projectStatisticIds,
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "Id",
          name: "Chase Id / Client Chase Key",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Projects",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "MeasuresCodes",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Statuses",
        }),
      ],
    });
  }

  private refreshGrid(): void {
    this.gridRefresh.emit();
  }

  ngDoCheck() {
    if (this.serverGridComponent.data.length > 0) {
      this.totalChasesCount = ArrayHelper.isAvailable(this.serverGridComponent.data) ? this.serverGridComponent.data[0].chaseCount : 0;
      if (this.totalChasesCount > 0) {
        this.totalChasesMessage = this.totalChasesCount > this.defaultTotalCount
          ? `Top ${this.defaultTotalCount} of ${this.totalChasesCount} record(s).`
          : `Top ${this.totalChasesCount} records.`;
      }
    }
  }

  private getProjectList(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectsInput = { ...this.projectsInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getMeasuresList(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(result => {
        this.measuresInput = { ...this.measuresInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  private getReportingStatuses(): void {
    this.service
      .getReportingStatusesList()
      .pipe(map(this.automapper.curryMany("ChaseReportingStatusModel", "SelectableInput")))
      .subscribe(result => {
        this.statusesInput = { ...this.statusesInput, options: result } as any;
        this.changeDetector.markForCheck();
      });
  }

  viewDetails(rowData: any) {
    const selectedRows = rowData;
    this.chaseId = selectedRows.chaseId;
    this.router.navigate(["members", "chase", this.chaseId]);
  }

  selectRow(rowData) {
    const key = "chasePendId";
    let selectedRows = [];
    this.chaseIds = [];
    this.chasePendIds = [];
    if (rowData !== undefined) {
      selectedRows.push(rowData);
    } else {
      selectedRows = this.serverGridSelection;
    }

    if (selectedRows && selectedRows.length > 0) {
      const pendId = selectedRows[0][key];
      this.chaseIds = [];
      this.pendIds = [];

      if (pendId !== 0) {
        this.chasePendId = pendId;
      }
      const checkValue = selectedRows.filter(c => c.pendCode);

      if (checkValue.length > 0 && checkValue.length < selectedRows.length) {
        this.messagingService.showToast("Please select similar Chases for bulk actions - either with Pend or without Pend", SeverityType.ERROR);
        return;
      }


      selectedRows.forEach(item => {
        this.chaseIds.push(item.chaseId);
        if (item.chasePendId !== 0) {
          this.chasePendIds.push(item.chasePendId);
          this.pendIds.push(item.chasePendId);

        }
      });

      this.pendIds.length > 0 ? this.isEditPendView = true : this.isCreatePendView = true;
      this.formClose.emit();
    } else {
      this.messagingService.showToast("Please select at least one Chase", SeverityType.WARN);
    }

    this.serverGridSelection = [];

  }

  editPend($event) {
    this.isEditPendView = $event;
  }

  receiveValue($event) {
    this.isCreatePendView = $event;
  }

  printProvider(rowData: any) {
    const selectedRows = rowData;
    const arrchaseid = [];
    arrchaseid.push(selectedRows.chaseId);
    const projectId = selectedRows.projectID;

    this.providerPacketItem = new ProviderPacketItem({
      chaseIds: arrchaseid,
      projectId,
      toName: "",
      toPhone: "",
      toFax: "",
      fromName: "",
      fromPhone: "",
      providerName: "",
      serviceOrgName: "",
    });

    this.service
      .printProviderPacket(this.providerPacketItem)
      .subscribe(item => {
        if (item !== "") {
          window.open(item, "_self");
        } else {
          this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
        }
      });
  }

  openAssignReassignModal(rowData?: any) {
    let selectedRows = [];
    this.chaseIds = [];

    if (rowData !== undefined) {
      this.chaseIds.push(rowData.chaseId);
    } else {
      selectedRows = this.serverGridSelection;
    }

    const array = selectedRows;
    const unique = {};
    const distinct = [];
    for (const i of array) {
      if (typeof (unique[i.reportingStatusName]) === "undefined") {
        distinct.push(i.reportingStatusName);
      }
      unique[i.reportingStatusName] = 0;
    }

    if (distinct.length > 1) {
      this.messagingService.showToast("Unable to bulk assign.  Selected Chases must be in the same status", SeverityType.ERROR);
      return;
    }

    selectedRows.forEach(item => {
      this.chaseIds.push(item.chaseId);
    });

    this.isAssignModalVisible = true;
    this.initializeControl();
    this.getClinicalUserList();
  }

  initializeControl(): void {
    this.userInput = new Dropdown({
      key: "user",
      label: "User",
      appendTo: "body",
    });
    this.chaseAssignForm = this.formService.createFormGroup([this.userInput]);
  }

  getClinicalUserList() {
    this.chaseAssignService.getCodersList(this.chaseIds)
      .subscribe((data: any[]): void => {
        const users = [];
        users.push({ text: "Select User", value: "0", label: "Select User" });
        for (const item of data) {
          users.push({ text: item.text, value: item.value, label: item.text });
        }

        this.clinicalPageUserList = users;
        this.userInput = new Dropdown({ ...this.userInput, options: this.clinicalPageUserList } as any);
        this.changeDetector.markForCheck();
      });
  }

  onChaseAssign() {
    this.isChaseAssignDisabled = true;

    if (!ArrayHelper.isAvailable(this.chaseIds)) {
      this.messagingService.showToast("No chases selected.", SeverityType.ERROR);
      return;
    }

    this.chaseAssignService.assignChasesToUser(this.chaseIds, Number(this.selectedUser))
      .subscribe(() => {
        this.chaseAssignForm.get(this.userInput.key).setValue("0");
        this.chaseIds = [];
        this.serverGridSelection = [];
        this.isChaseAssignDisabled = true;
        this.isAssignModalVisible = false;
        this.messagingService.showToast("Assigned Successfully.", SeverityType.SUCCESS);
        this.refreshGrid();
        this.changeDetector.markForCheck();
      });
  }

  onSelectType(event) {
    this.isChaseAssignDisabled = (event.value === "0");
  }

  filterObj(text: string, value: string): void {
    this.selectedValue = [];
    const textList = text.split(",");
    const valueList = value.split(",");

    for (const key in textList) {
      if (textList[key]) {
        this.selectedValue.push({ text: textList[key].trim(), value: valueList[key] });
      }
    }
  }


  private getInput(key: string): DynamicInput {
    if (this.chaseSearchRequest == null) {
      return null;
    }

    return this.chaseSearchRequest.getInput(key);
  }

  private setInput(key: string, value: DynamicInput) {
    if (this.chaseSearchRequest == null) {
      return null;
    }

    this.chaseSearchRequest.setInput(key, value);
  }


  setSelectedChases(rowData?: any): void {
    this.selectedChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
  }

  openPendModal(rowData?: any): void {
    if (rowData != null) {
      this.setSelectedChases(rowData);
      this.isPendModalVisible = true;
    } else {
      this.messagingService.showToast("Please select at least one Chase", SeverityType.WARN);
      return;
    }
  }

  closeModal(): void {
    this.selectedChases = [];
    this.serverGridSelection = [];
  }

  get selectedUser(): string {
    return this.chaseAssignForm.get(this.userInput.key).value;
  }
}
