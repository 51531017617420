export enum AnnotationType {
        // Annotation attached to a chase, created during Dataload
        ByClient= 1,

        // Annotation attached to a chase, created by user
        ByUser = 2,

        // Annotation attached to an entity (e.g. diagnosis), created during Dataload
        ObjectByClient= 3,

        // Annotation attached to an entity (e.g. diagnosis), created by user
        ObjectByUser= 4,
}
