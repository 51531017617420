import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { LookupModel } from "../../../../shared/models/lookup-model";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.scss"],
  providers: [RetrievalPageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListComponent implements OnInit {
  projects = List<LookupModel>();
  downloadFileType: string;


  constructor(
    private retrievalPageService: RetrievalPageService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.downloadFileType = params.get("fileType");
        this.getProjectList();
      }
    );
  }

  get headerTitle(): string {
    return this.downloadFileType === "auditpackages" ? "Audit Packages" : "Project Files";
  }

  getProjectList(): void {
    this.retrievalPageService
      .getProjectList()
      .subscribe(result => {
        this.projects = List(result);
        this.changeDetector.markForCheck();
      });
  }

  trackByIndex(index, item) {
    return index;
  }
}
