import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { ClientOrgModel } from "../../../../core/user/client-org-model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { CustomPendCode } from "./model/custom-pend-code.model";
import { DocumentRouting } from "./model/document-routing.model";
import { OrganizationPendCode } from "./model/organization-pend-code.model";
import { PendCodeCount } from "./model/Pend-Code-Count.model";
import { PendCodeList } from "./model/Pend-Code-List.model";
import { ServiceOrgAttribute } from "./model/service-org-attribute.model";
import { ServiceOrgConfigModel } from "./model/service-org-config.model";
import { SsoConfiguration } from "./model/sso-configuration.model";
import { Tags } from "./model/Tags.model";
import { TotalTagCount } from "./model/total-tag-count.model";

@Injectable({
  providedIn: "root",
})
export class ServiceOrgConfigurationService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  getServiceOrgConfigurations(): Observable<ServiceOrgConfigModel> {
    const url = `${this.baseApiUrl}serviceorg/data`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ServiceOrgConfigModel"))
    );
  }

  getServiceOrgConfigurationByAttribute(attributeId: number): Observable<ServiceOrgAttribute> {
    const url = `${this.baseApiUrl}serviceorg/data/attribute?attributeId=${attributeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ServiceOrgAttribute"))
    );
  }

  getDocumentRoutingByAttribute(attributeId: number): Observable<DocumentRouting[]> {
    const url = `${this.baseApiUrl}serviceorg/data/attribute?attributeId=${attributeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DocumentRouting"))
    );
  }

  saveServiceOrgConfigurationDocumentRoutingAttribute(attributes: DocumentRouting[]): Observable<any> {
    const url = `${this.baseApiUrl}serviceorg/savedocumentroutingattribute`;
    return this.http.post(url, attributes);
  }

  getSsoConfigurationByAttribute(attributeId: number): Observable<SsoConfiguration[]> {
    const url = `${this.baseApiUrl}serviceorg/data/attribute?attributeId=${attributeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SsoConfiguration"))
    );
  }

  saveServiceOrgEnableSsoAttribute(value: number): Observable<null> {
    const url = `${this.baseApiUrl}serviceorg/saveenablessoattribute?value=${value}`;
    return this.http.post(url, {}) as Observable<null>;
  }

  saveServiceOrgConfigurationSsoConfigurationAttribute(attributes: SsoConfiguration[]): Observable<any> {
    const url = `${this.baseApiUrl}serviceorg/savessoconfigurationattribute`;
    return this.http.post(url, attributes);
  }

  saveServiceOrgConfigurations(serviceOrgConfigurationModel: ServiceOrgConfigModel): Observable<number> {

    const formData = serviceOrgConfigurationModel.companyLogo;
    formData.append("name", serviceOrgConfigurationModel.name);
    formData.append("contactName", serviceOrgConfigurationModel.contactName);
    formData.append("contactData", serviceOrgConfigurationModel.contactData);
    formData.append("organizationAttributeData", serviceOrgConfigurationModel.organizationAttributeData);
    const url = `${this.baseApiUrl}serviceorg/save`;

    return this.http
      .post(url, formData) as Observable<number>;
  }

  getClientsNameList(): Observable<ClientOrgModel[]> {
    const url = `${this.baseApiUrl}serviceorg/clients/name`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ClientOrgModel")));
  }

  saveClientName(clientName: ClientOrgModel): Observable<null> {
    const url = `${this.baseApiUrl}serviceorg/client/save`;

    return this.http
      .post(url, clientName) as Observable<null>;
  }
  getDashboardCount(): Observable<TotalTagCount[]> {
    const url = `${this.baseApiUrl}serviceorg/GetTagDashboardCountAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TotalTagCount"))
    );

  }

  getTagList(tagTypeId: number, startRecord?: number, endRecord?: number): Observable<Tags[]> {
    const url = `${this.baseApiUrl}serviceorg/tags?tagTypeID=${tagTypeId}&startRecord=${startRecord}&endRecord=${endRecord}`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "Tags")));
  }

  getTagSearchList(tagTypeId: string, search: string, startRecord?: number, endRecord?: number): Observable<Tags[]> {
    const url = `${this.baseApiUrl}serviceorg/tag/search?tagTypeID=${tagTypeId}&search=${search}&startRecord=${startRecord}&endRecord=${endRecord}`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "Tags")));
  }

  getPendCodeDashboardCount(): Observable<PendCodeCount[]> {
    const url = `${this.baseApiUrl}serviceorg/GetPendCodeDashboardCountAsync`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "PendCodeCount"))
    );

  }
  getValueForPendCodeAsCsv(formValues: SelectableInput[], isUseValue: boolean) {
    let valueAsCsv = "";

    if (ArrayHelper.isAvailable(formValues)) {
      valueAsCsv = formValues.filter(a => a != null)
          .map(a => isUseValue ? a.value : a.text)
          .join(",");
      }
    return valueAsCsv;

  }

  savePendCods(pendCodeCsv: OrganizationPendCode): Observable<null> {
    const url = `${this.baseApiUrl}serviceorg/pendcodes/save`;

    return this.http
      .post(url, pendCodeCsv) as Observable<null>;
  }
  saveCustomPendsCode(customPendCode: CustomPendCode): Observable<null> {

    const url = `${this.baseApiUrl}serviceorg/custompendcode/save`;

    return this.http
      .post(url, customPendCode) as Observable<null>;
  }
  getPendCodeByCategory(categoryId: number): Observable<PendCodeList[]> {
    const url = `${this.baseApiUrl}serviceorg/getpendcodebycategory?categoryId=${categoryId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "PendCodeList"))
    );

  }

  getPendCodeByTypeId(pendCodeTypeId: number): Observable<CustomPendCode> {
    const url = `${this.baseApiUrl}serviceorg/getpendtypebyid?pendTypeId=${pendCodeTypeId}`;
    return this.http.get(url) as Observable<CustomPendCode>;

  }

  getProviderAgingSampleImageUrl(): Observable<string> {
    const url = `${this.baseApiUrl}serviceorg/provideraging/sampleimage`;
    return this.http.get(url) as Observable<string>;
  }

}
