import { Injectable } from "@angular/core";
import { SessionService } from "../../core/storage/session.service";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { GridState } from "./models/grid-state.model";

@Injectable({
  providedIn: "root",
})
export class GridStateService {
  private readonly PREFIX = "GRID_";

  constructor(private session: SessionService) { }

  get(name: string): GridState {
    const key = this.getKey(name);
    const state = this.session.get<any>(key, null);
    const gridState = state == null ? null : new GridState(state);
    return gridState as any;
  }

  put(name: string, state: GridState): void {
    const key = this.getKey(name);
    const objectForJson = state.getObjectForJson();
    this.session.put(key, objectForJson);
  }

  hasKey(name: string): boolean {
    const key = this.getKey(name);
    const state = this.session.get<any>(key, null);
    const hasKey = state != null && typeof state === "object";
    return hasKey;
  }

  delete(name: string): void {
    const key = this.getKey(name);
    this.session.delete(key);
  }

  private getKey(name: string): string {
    const cleanName = StringHelper.clean(name);
    StringHelper.throwIsAvailableError(cleanName, "name");

    const key = `${this.PREFIX}${cleanName}`;
    return key;
  }
}
