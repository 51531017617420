import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-provider-outreach-by-contact-type-report",
  templateUrl: "./provider-outreach-by-contact-type-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderOutreachByContactTypeReportComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.PROVIDER_OUTREACH_BY_CONTACT_TYPE_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
