import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { DownloaderService } from "../../../../core/downloader/downloader.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { AccessInfoViewEditService } from "../access-info-view-edit/access-info-view-edit.service";
import { RetrievalDocumentItem } from "./retrieval-document-list-item-model";
import { RetrievalDocumentListService } from "./retrieval-document-list.service";

@Component({
  selector: "retrieval-document-list",
  templateUrl: "./retrieval-document-list.component.html",
  styleUrls: ["./retrieval-document-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalDocumentListComponent implements OnInit, OnChanges {

  retrievalDocumentItems = List<RetrievalDocumentItem>();
  @Input() display = "block";
  @Input() retrievalId;
  subscription: Subscription;
  constructor(
    private service: RetrievalDocumentListService,
    private accessInfoViewEditService: AccessInfoViewEditService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private downloaderService: DownloaderService,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getRetrievalDocuments();

    this.subscription = this.accessInfoViewEditService.refresh.subscribe(id => {
      this.getRetrievalDocuments();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.retrievalId.currentValue) {
      this.getRetrievalDocuments();

      this.subscription = this.accessInfoViewEditService.refresh.subscribe(id => {
        this.getRetrievalDocuments();
      });
    }
  }

  getRetrievalDocuments(): void {
    this.service
      .getRetrievalDocuments(this.retrievalId)
      .subscribe(items => {
        this.retrievalDocumentItems = this.assignAndNotify(items);
      });
  }

  downloadFile(url: string) {
    this.downloaderService.fromRawUrl(url);
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  trackByIndex(index, item) {
    return index;
  }

}
