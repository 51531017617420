<div class="header" *ngIf="!isHeaderHidden">
  <span class="secondary-text">{{annotation.pageOrDataload}}</span>
    <span class="secondary-text">{{ annotation.createDateTime | date: 'MM/dd/yyyy' }}</span>
</div>
<div class="annotation-card" [ngClass]="{'--pointer': isReadOnly, '--no-pointer reduced-padding': !isReadOnly, 'border': annotation.highlightId === highlightId || addBorder(annotation)}"
  (click)="handleClick()">
    <div *ngIf="!editing && !isHeaderHidden" class="annotation-card__header" id="card-header" (click)="$event.stopPropagation()"
        [ngClass]="showMenu() ? 'annotation-card__header--visible' : 'annotation-card__header--hidden'">
        <app-icon (click)="menu.toggle($event);$event.stopPropagation()" iconName="ellipsis-h"></app-icon>
    </div>
    <div class="annotation-card__details">
        <span *ngIf="isReadOnly" class="secondary-text user">{{annotation.callerUserName}} annotated:</span>
        <textarea #annotationText [rows]="annotationRows" [disabled]="isReadOnly" required="true" (input)="resize($event)"
            [(ngModel)]="annotation.text" name="annotationText" #textForm="ngModel" class="text" placeholder="Type your annotation here..."
            [ngClass]="hasMaxHeight(annotationText.scrollHeight) ? '--scroll' : '--regular'"></textarea>
    </div>
    <div class="annotation-card__footer" (click)="$event.stopPropagation()">
        <ng-container *ngIf="!isReadOnly">
            <div class="annotation-card__footer error-container">
                <span class="annotation-card__footer error-container counter"
                    [ngClass]="{ 'error-text' : isInvalid(textForm, annotation), 'secondary-text' : !isInvalid(textForm, annotation)}">
                    {{annotation.text?.length}} / 1000</span>
                <span *ngIf="isInvalid(textForm, annotation)" class="error-text">Write an annotation between 1 - 1000
                    characters.</span>
            </div>
            <div class="annotation-card__footer icon --cancel" (click)="onCancel(textForm)">
                <app-icon iconName="times"></app-icon>
            </div>
            <div class="annotation-card__footer icon --continue"
                [ngClass]="{ 'disabled': isInvalid(textForm, annotation) }" (click)="onSubmit(textForm, annotation)">
                <app-icon iconName="check"></app-icon>
            </div>
        </ng-container>
        <!-- TODO: Revert when persisted -->
        <!-- <span *ngIf="annotation.isEdited && isReadOnly" class="secondary-text">Edited</span> -->
    </div>
</div>
<p-menu styleClass="options-menu" [style]="{'width': '95px'}" appendTo="body" #menu [popup]="true" [model]="menuItems">
</p-menu>
