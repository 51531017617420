import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class RequestStatusChange {
  readonly chasePendIds: number[];
  readonly assignedToUserId: number;
  readonly notes: string;
  readonly owner: string;
  readonly pendStatusId: number;
  readonly chaseId: number;

  constructor(options: {
    chasePendIds: number[];
    assignedToUserId: number;
    notes: string;
    owner: string;
    pendStatusId: number;
    chaseId: number;

  }) {
    this.chasePendIds = options.chasePendIds;
    this.assignedToUserId = options.assignedToUserId;
    this.notes = StringHelper.clean(options.notes);
    this.owner = StringHelper.clean(options.owner);
    this.pendStatusId = options.pendStatusId;
    this.chaseId = options.chaseId;
  }
}
