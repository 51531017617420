import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { TimelineItem } from "../timeline-item.model";

@Component({
  selector: "app-timeline-detail-item",
  templateUrl: "./timeline-detail-item.component.html",
  styleUrls: ["./timeline-detail-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDetailItemComponent {
  @Input() item: TimelineItem;
  @Input() index: number;
  @Input() isChaseTimeline = false;
  get showDetails(): boolean {
    return this.item.hasDetails || this.item.hasHeadset;
  }

  get hasUrl(): boolean {
    return StringHelper.isAvailable(this.item.urlPath) && StringHelper.isAvailable(this.item.urlText);
  }

  getNotes(value: string) {
    if (StringHelper.isAvailable(value)) {
      const noteParts = value.split("\r\nAdditional Notes: ");
      const mainNote = noteParts[0].trim();
      const outstandingNotes = mainNote.split("Outstanding Records:");
      const outstandingNote = outstandingNotes[0].trim();
      const approvedByNotes = outstandingNote.split("Approved by:");
      const notes = approvedByNotes[0].trim();
      return notes.replace("\\n", "");
    }
  }

  getAdditionalNote(value: string): string {
    if (StringHelper.isAvailable(value)) {
      const noteParts = value.split("\r\nAdditional Notes: ");
      return noteParts.length > 1 ? `Additional Notes: ${noteParts[1]}` : "";
    }
  }

  getFilterNotes(value): string {
    if (StringHelper.isAvailable(value)) {
      const outstandingNotes = value.split("Outstanding Records:");
      if (outstandingNotes.length > 1) {
        return `Outstanding Records: ${outstandingNotes[1].trim().replace(/\\n/g, "")}`;
      }
      const approvedNotes = value.split("Approved by:");
      if (approvedNotes.length > 1) {
        return `Approved by: ${approvedNotes[1].trim().replace(/\\n/g, "")}`;
      }
    }
    return "";
  }

  isPursuitApproved(value: string) {
    return !(value === "Pursuit Approved" && this.isChaseTimeline);
  }
}
