import { Type } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { BooleanHelper } from "../utilities/contracts/boolean-helper";
import { StringHelper } from "../utilities/contracts/string-helper";

export interface IDynamicControlOptions {
  key?: string;
  parent?: DynamicControl;
  validators?: any;
  asyncValidators?: any;
  errorMessages?: any;
  disabled?: boolean;
  hidden?: boolean;
  isAdmin?: boolean;
  isChanged?: boolean;
  workflowStatusName?: string;
  appendTo?: string;
  isNlp?: boolean;
  isSupplemental?: boolean;
  isDva?: boolean;
  useTitleCase?: boolean;
}

export abstract class DynamicControl implements IDynamicControlOptions {
  validators: any;
  asyncValidators: any;
  errorMessages: any;
  key: string;
  parent: DynamicControl;
  disabled: boolean;
  hidden: boolean;
  isAdmin: boolean;
  isChanged: boolean;
  workflowStatusName: string;
  appendTo: string;
  isNlp: boolean;
  isSupplemental: boolean;
  isDva: boolean;
  useTitleCase: boolean;

  abstract readonly controlType: string;
  abstract readonly componentType: Type<any>;

  constructor(options: IDynamicControlOptions = {}) {
    this.validators = options.validators;
    this.asyncValidators = options.asyncValidators;
    this.key = StringHelper.clean(options.key);
    this.parent = options.parent;
    this.disabled = BooleanHelper.tryGet(options.disabled, false);
    this.errorMessages = options.errorMessages || {};
    this.hidden = BooleanHelper.tryGet(options.hidden, false);
    this.isAdmin = BooleanHelper.tryGet(options.isAdmin, false);
    this.isChanged = BooleanHelper.tryGet(options.isChanged, false);
    this.workflowStatusName = StringHelper.clean(options.workflowStatusName).toLowerCase();
    this.appendTo = StringHelper.clean(options.appendTo);
    this.isNlp = BooleanHelper.tryGet(options.isNlp, false);
    this.isSupplemental = BooleanHelper.tryGet(options.isSupplemental, false);
    this.isDva = BooleanHelper.tryGet(options.isDva, false);
    this.useTitleCase = BooleanHelper.tryGet(options.useTitleCase, true);
  }


  abstract create(): AbstractControl;
}
