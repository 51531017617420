import { StringHelper } from "../../../utilities/contracts/string-helper";

export class DataModel {
  readonly label: string;
  readonly data: string[];
  readonly url: string[];
  readonly backgroundColor: string[] | string;
  readonly borderColor: string;
  readonly borderWidth: number;
  readonly borderSkipped: string;
  readonly hoverBackgroundColor: string[];
  readonly hoverBorderColor: string[] | string;
  readonly hoverBorderWidth: number;
  readonly fill: boolean;
  readonly pointBackgroundColor: string;
  readonly pointBorderColor: string;
  readonly pointHoverBackgroundColor: string;
  readonly pointHoverBorderColor: string;
  readonly parameters: any;

  constructor(options: {
    label?: string;
    data: string[];
    url?: string[];
    backgroundColor?: string[] | string;
    borderColor?: string;
    borderWidth?: number;
    borderSkipped?: string;
    hoverBackgroundColor?: string[];
    hoverBorderColor?: string[] | string;
    hoverBorderWidth?: number;
    fill?: boolean;
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointHoverBackgroundColor?: string;
    pointHoverBorderColor?: string;
    parameters?: any;
  }) {
    this.label = StringHelper.clean(options.label);
    this.data = options.data;
    this.url = options.url;
    this.backgroundColor = options.backgroundColor;
    this.borderColor = StringHelper.clean(options.borderColor);
    this.borderWidth = options.borderWidth;
    this.borderSkipped = StringHelper.clean(options.borderSkipped);
    this.hoverBackgroundColor = options.hoverBackgroundColor;
    this.hoverBorderColor = options.hoverBorderColor;
    this.hoverBorderWidth = options.hoverBorderWidth;
    this.fill = typeof options.fill === "boolean" ? options.fill : false;
    this.pointBackgroundColor = StringHelper.clean(options.pointBackgroundColor);
    this.pointBorderColor = StringHelper.clean(options.pointBorderColor);
    this.pointHoverBackgroundColor = StringHelper.clean(options.pointHoverBackgroundColor);
    this.pointHoverBorderColor = StringHelper.clean(options.pointHoverBorderColor);
    this.parameters = options.parameters;
  }
}
