import { ChaseItem } from "../../../../../api/chase-search/chase-search-result-item";
import { MemberListItem } from "../../../../member/member/member-listing/member-list-item.model";

export class MemberWithChases extends MemberListItem {

  projectCount: number;
  chaseList: ChaseItem[];

  constructor(options: MemberWithChases

  ) {
    super(options);
    this.projectCount = options.projectCount;
    this.chaseList = options.chaseList;
  }
}
