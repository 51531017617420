import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ListItem } from "../../../../../shared/list/list-item";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";
import { InternalPendsDetailHeaderService } from "../internal-pends-detail-header/internal-pends-detail-header.service";
import { InternalPendsDetailInfoService } from "../internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsRequestStatusChangeService } from "./internal-pends-request-status-change.service";
import { RequestStatusChange } from "./request-status-change.model";

@Component({
  selector: "app-internal-pends-request-status-change",
  templateUrl: "./internal-pends-request-status-change.component.html",
  styleUrls: ["./internal-pends-request-status-change.component.scss"],
})
export class InternalPendsRequestStatusChangeComponent implements OnChanges, OnInit {
  @Input() isStatusChangeVisible = false;
  @Output() formClose = new EventEmitter();
  readonly formGroup: FormGroup;
  readonly notes: Textarea;
  private modelErrors: any[] = [];
  pendStatus: SelectItem[];
  @Input() isClinical = false;
  user: UserToken;
  @Input() owner;
  popUpLabel = "REQUEST STATUS CHANGE";
  private pendId: number;
  private requestStatusChange: RequestStatusChange;
  private newLine = "\r";
  isClientOnly = false;
  text: string;
  summaryItems = List<ListItem>();
  statusData = "";
  @Input() chasePendIds = [];
  isClientRole = false;
  isPendStatusChangeVisible = false;
  modalWindowContent = "";
  chaseId: string;
  isPendCloseVisible = false;
  @Input() pendOwners: string[];
  @Input() assignedUserId: number;
  statusInput: Dropdown;

  constructor(
    private serviceSet: InternalPendsDetailInfoService,
    private serviceHeaderSet: InternalPendsDetailHeaderService,
    private readonly formService: FormService,
    private service: InternalPendsRequestStatusChangeService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private messagingService: MessagingService,
    private authService: AuthService
  ) {
    this.notes = new Textarea({
      key: "notes",
      label: "Notes",
      placeholder: "Long form text...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
    });

    // TODO: do this until dynamic forms handles error display
    this.modelErrors = [
      { key: "notes", message: "Please enter notes. Minimum 4 characters and Maximum 1000 characters allowed" },
    ];
    this.statusInput = new Dropdown({
      key: "status",
      label: "New Status",
      placeholder: "Select Status",
      appendTo: "body",
    });

    this.formGroup = this.formService.createFormGroup([this.notes, this.statusInput]);

    this.user = this.authService.user;
  }

  ngOnChanges() {
    if (this.isClientRole === true) {
      this.getPendDropdowns();
    } else {
      this.getPendStatus();
    }
  }

  ngOnInit() {
    this.isClientRole = this.authService.user.directoryRoleIds.indexOf(31) > -1 ? true : false;

    if (this.isClientRole === true) {
      this.isClientOnly = true;
      this.getPendDropdowns();
    } else {
      this.getPendStatus();
    }

    this.route.paramMap.subscribe(params => {
      if (params.get("pendGd") != null) {
        this.pendId = +params.get("pendGd");
        this.serviceSet.getSummaryItems(this.pendId).subscribe(data => {
          this.summaryItems = data as any;
          this.statusData = data.find(x => x.key === "PendStatusId").value;
          this.chaseId = data.find(x => x.key === "Chase ID").value;
        });
      }
    });

  }

  getPendDropdowns() {
    this.isClientOnly = this.isClientRole;
    this.popUpLabel = "STATUS CHANGE";
    this.text = "UPDATE STATUS";

    this.service.getPendDropdown(this.isClinical, this.user.organizationId, this.isClientOnly, false)
      .subscribe((data: any[]): void => {
        let pendStatus = [];
        const status = "pendStatus";
        pendStatus = data[status];
        this.pendStatus = pendStatus.map(item => ({ label: item.description, value: item.pendStatusId }));

        if (this.isClientRole) {

          if (this.statusData === "5" || this.statusData === "6") {
            this.pendStatus.splice(0, 2);
          }
        }
        this.changeDetector.markForCheck();
      });
  }

  visibleChange($event) {
    this.isStatusChangeVisible = $event;
    this.formClose.emit($event);
  }

  onSelectType(event) {
    if (event) {
      this.formGroup.get(this.statusInput.key).setValue(event.value);
      switch (this.selectedStatus) {
        case PendStatus.Closed:
          this.isPendCloseVisible = true;
          this.modalWindowContent = "Closing the Pend(s) will also close the associated Chase(s). Those chases will no longer continue to be worked. Do you want to proceed?";
          break;
        case PendStatus.Resolved:
          this.isPendCloseVisible = true;
          this.modalWindowContent = "Resolving the Pend(s) will let work continue on the Chase(s). This should be done once the obstacle preventing the Chase(s) from advancing has been resolved. Do you want to proceed?";
          break;
        default:
          this.isPendCloseVisible = false;
          break;
      }
    }
  }

  changeStatus(): void {
    this.isPendStatusChangeVisible = false;
    if (this.pendId > 0) {
      this.chasePendIds = [];
      this.chasePendIds.push(this.pendId);
    }

    this.requestStatusChange = new RequestStatusChange({
      chasePendIds: this.chasePendIds,
      assignedToUserId: this.assignedUserId,
      notes: this.formGroup.value.notes,
      owner: this.pendOwners[0],
      pendStatusId: this.selectedStatus,
      chaseId: Number(this.chaseId),

    });
    if (this.formGroup.invalid) {
      this.DisplayErrors();
      return;
    }

    this.service.updateStatusChange(this.requestStatusChange).subscribe(data => {
      this.isPendStatusChangeVisible = false;
      if (data > 0) {
        this.serviceSet.resetFunction(this.pendId);
        this.serviceHeaderSet.resetFunction(this.pendId);
        this.messagingService.showToast("Pend status updated successfully.", SeverityType.SUCCESS);
      } else {
        this.messagingService.showToast("Pend status update failed.", SeverityType.WARN);
      }
      this.changeDetector.markForCheck();
    });
    this.formGroup.reset();
    this.visibleChange(false);
  }

  private DisplayErrors() {
    let ErrorMessages = "Invalid Entries: \r";

    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        const control = this.formGroup.controls[key];
        if (control.invalid) {
          const modelError = this.modelErrors.filter(obj => {
            return obj.key === key;
          });

          if (modelError) {
            ErrorMessages += modelError[0].message + this.newLine;
          }
        }
      }
    }

    this.messagingService.showToast(ErrorMessages, SeverityType.ERROR);

  }

  getPendStatus() {
    this.isClientOnly = false;
    this.popUpLabel = "STATUS CHANGE";
    this.text = "UPDATE STATUS";
    this.service.getPendDropdown(this.isClinical, this.user.organizationId, this.isClientOnly, this.isReturnAllPendStatus)
      .subscribe((data: any[]): void => {
        let pendStatus = [];
        const status = "pendStatus";
        pendStatus = data[status];
        this.pendStatus = pendStatus.map(item => new SelectableInput({ text: item.description, value: item.pendStatusId }));

        if (this.statusData === "1") {
          this.pendStatus.shift();
        } else {
          this.pendStatus.splice(0, 1);
          if (this.isClinical) {
            this.pendStatus.splice(-1, 1);
          }
          this.pendStatus = this.getPendStatusOnRoleBase(this.pendStatus);
        }
        this.statusInput = new Dropdown({ ...this.statusInput, options: [...this.pendStatus] } as any);
        this.changeDetector.markForCheck();
      });

  }

  conformStatusChange() {
    this.isPendStatusChangeVisible = true;
  }

  closePendPopup() {
    this.isPendStatusChangeVisible = false;
  }

  get isReturnAllPendStatus(): boolean {
    const userDirectoryRoleIds = this.authService.user.directoryRoleIds;
    const directoryRolesToCheck = [
      DirectoryUserRole.ThirdPartyManager,
      DirectoryUserRole.EMRManager,
      DirectoryUserRole.FieldTechManager,
      DirectoryUserRole.ClientOverreadManager,
      DirectoryUserRole.ProjectManager,
      DirectoryUserRole.CallCenterManager,
      DirectoryUserRole.ReportingManager,
    ];
    const filteredPendStatusOnRolebase = userDirectoryRoleIds.some(role => directoryRolesToCheck.includes(role));
    return filteredPendStatusOnRolebase;
  }

  get isPendOwnerClient(): boolean {
    return this.pendOwners.includes("Client");
  }

  getPendStatusOnRoleBase(pendStatus: SelectItem[]): SelectItem[] {
    if (this.isReturnAllPendStatus) {
      const indexOfRequestToResolve = pendStatus.map(option => option.label).indexOf("Request to Resolve");
      if (!this.isPendOwnerClient) {
        const indexOfRequestToClose = pendStatus.map(option => option.label).indexOf("Request to Close");
        this.pendStatus.splice(indexOfRequestToClose, 1);
      }
      this.pendStatus.splice(indexOfRequestToResolve, 1);
    }
    return this.pendStatus;
  }

  get selectedStatus(): number {
    return Number(this.formGroup.get(this.statusInput.key).value);
  }
}
