import { SafeResourceUrl } from "@angular/platform-browser";
import { IAutoMapper } from "../../core/automapper/interfaces";
import { StringHelper } from "../../utilities/contracts/string-helper";

export const mapServiceOrgLogoResponseModel = (automapper: IAutoMapper): void => {

  automapper
    .createMap("default", "ServiceOrgLogoResponseModel")
    .forMember("organizationLogo", o => o.organizationLogo)
    .forMember("fileName", o => o.fileName);

};

export class ServiceOrgLogoResponseModel {
  organizationLogo: SafeResourceUrl;
  fileName: string;

  constructor(options: {
    organizationLogo: string;
    fileName: string;
  }) {
    this.organizationLogo = options.organizationLogo;
    this.fileName = StringHelper.clean(options.fileName);
  }
}
