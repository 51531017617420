<app-detail-main-template>
  <div container-header>
    <div class="header bold"
         data-qa="clinical-page-header">CLINICAL</div>
    <div class="progress-container"
         style="display:none;">
      <div>
        <div>
          <a><span class="bold">{{firstName}} {{lastName}}:</span></a>
          <span class="requestText">
            "{{acceptRequestMessage}}".
          </span>
        </div>
        <div>
          <app-button text="ACCEPT REQUEST"></app-button>
        </div>
      </div>
    </div>
    <div class="statistics">
      <app-header-statistics [headerStatistics]="clinicalStatistics" [landingFilter]="landingFilter"></app-header-statistics>
    </div>
    <div class="filters">
      <app-landing-filter (addFilter)="applyFilter($event)"></app-landing-filter>
    </div>
  </div>
  <div container-body>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="labelModelPendsCountByPendStatus !== undefined"
           style="display:none;">
        <app-kpi [cModel]="chartModelPendsCountByPendStatus"
                 [cDataLabels]="labelModelPendsCountByPendStatus"
                 [cDataModel]="arrPendsCountByPendStatus"
                 [cOptions]="dataOptionsPendsCountByPendStatus">
        </app-kpi>
      </div>

      <div class="ui-g-12 ui-md-6 ui-lg-4"
           *ngIf="labelModelChaseCompliance !== undefined"
           style="display:none;">
        <app-kpi [cModel]="chartModelChaseCompliance"
                 [cDataLabels]="labelModelChaseCompliance"
                 [cDataModel]="arrChaseCompliance"
                 [cOptions]="dataOptionsChaseCompliance">
        </app-kpi>
      </div>
    </div>
    <div class="ui-g ui-fluid"
         style="display:none;">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="labelModelQAScoreTrendGraph !== undefined">
        <app-kpi [cModel]="chartModelQAScoreTrendGraph"
                 [cDataLabels]="labelModelQAScoreTrendGraph"
                 [cDataModel]="arrQAScoreTrendGraph"
                 [cOptions]="dataOptionsQAScoreTrendGraph"></app-kpi>
      </div>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12"
           *ngIf="numeratorHitsLabelModel !== undefined">
        <app-kpi [cModel]="numeratorHitsChartModel"
                 [cDataLabels]="numeratorHitsLabelModel"
                 [cDataModel]="numeratorHitsArray"
                 [cOptions]="numeratorHitsDataOptions"></app-kpi>
      </div>
    </div>
  </div>
</app-detail-main-template>
