import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export const mapMemberDetail = (automapper: IAutoMapper): void => {
  automapper
  .createMap("default", "MemberDetail")
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberDateOfBirth", o => o.memberDateOfBirth)
    .forMember("memberGender", o => o.memberGender)
    .forMember("memberId", o => o.memberId)
    .forMember("masterMemberId", o => o.masterMemberId)
    .forMember("sourceAliasId", o => o.sourceAliasId)
    .forMember("dataSet", o => o.dataSet);
  };

export class MemberDetail {
  memberFirstName: string;
  memberLastName: string;
  memberDateOfBirth: string;
  memberGender: string;
  masterMemberId: number;
  memberId: number;
  sourceAliasId: string;
  dataSet: string;

  constructor(options: Partial<MemberDetail> = {}) {
    this.memberFirstName = StringHelper.clean(options.memberFirstName);
    this.memberLastName = StringHelper.clean(options.memberLastName);
    this.memberDateOfBirth = StringHelper.clean(options.memberDateOfBirth);
    this.memberGender = StringHelper.clean(options.memberGender);
    this.memberId = options.memberId;
    this.masterMemberId = options.masterMemberId;
    this.sourceAliasId = StringHelper.clean(options.sourceAliasId);
    this.dataSet = StringHelper.clean(options.dataSet);
  }

  get memberName(): string {
    return `${this.memberFirstName} ${this.memberLastName}`;
  }
}
