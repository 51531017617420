import { BooleanHelper } from "../../../../utilities/contracts/boolean-helper";

export class CallNotesModel {
  contactMethodType: number;
  actionSource: string;
  callResultType?: number;
  functionalRoleId?: number;
  retrievalLocationId?: number;
  callNotes?: string;
  spokeTo?: string;
  nextCallDate?: Date;
  nextCallDateTime?: string;
  commitDate?: Date;
  chaseIds?: number[];
  vendorId?: number;
  contactLogId?: number;
  constructor(options: {
    contactMethodType: number;
    actionSource: string;
    callResultType?: number;
    functionalRoleId?: number;
    retrievalLocationId?: number;
    callNotes?: string;
    spokeTo?: string;
    nextCallDate?: Date;
    nextCallDateTime?: string;
    commitDate?: Date;
    chaseIds?: number[];
    vendorId?: number;
    contactLogId?: number;
  }) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.contactMethodType = options.contactMethodType;
    this.actionSource = options.actionSource || "";
    this.functionalRoleId = options.functionalRoleId;
    this.callResultType = options.callResultType;
    this.callNotes = options.callNotes || "";
    this.spokeTo = options.spokeTo || "";
    this.nextCallDate = options.nextCallDate;
    this.nextCallDateTime = options.nextCallDateTime || "";
    this.commitDate = options.commitDate;
    this.chaseIds = options.chaseIds;
    this.vendorId = options.vendorId;
    this.contactLogId = options.contactLogId;
  }
}
