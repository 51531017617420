import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable()
export class ModulePreloadingStrategy implements PreloadingStrategy {
  secondsUntilLoading = 3;

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      this.secondsUntilLoading += 1;
      setTimeout(load, this.secondsUntilLoading * 1000);
    }

    return of(null);
  }
}
