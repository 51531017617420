
<app-modal 
  id="move-back-model" 
  [(visible)]="visible" 
  (visibleChange)="visibleChange.emit($event)" 
  (onHide) ="close()" 
  header="MOVE BACK"
  (onCancel)="close()">

  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid basic-filter">    
      <p class="p_margin">{{moveBackWarningText}}</p>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="formGroup" [model]="chaseNotes"></form-factory>

      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button" text="Save" (click)="moveChase()" [disabled]="!formGroup.valid"></app-button>
  </footer>

</app-modal>
