import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { MasterProvider } from "../../../../shared/national-provider-grid/national-provider.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ChaseSearchService } from "../../../api/chase-search/chase-search.service";
import { ProviderSearchRequest } from "../../../api/provider/provider-search-request-model";
import { ProviderItem } from "../../../api/provider/provider-search-result-item";
import { ProviderService } from "../../../api/provider/provider.service";
import { AnalyticsService } from "../../analytics/analytics.service";
import { VendorDetailStateService } from "../../invoice/vendor-detail-info/vendor-detail-state.service";
import { VendorDetail } from "../../invoice/vendor-detail/vendor-detail.model";
import { RetrievalAddressDetailContactHistoryService } from "../address-detail/address-detail-contact-history/address-detail-contact-history.service";
import { AddressDetailState } from "../address-detail/address-detail-state.model";
import { AddressDetailStateService } from "../address-detail/address-detail-state.service";
import { AddressDetailService } from "../address-detail/address-detail.service";
import { AddressTimelineStateService } from "../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../address-timeline/address-timeline.service";
import { ContactMethodType } from "../cover-letter-template/contact-method-type.enum";
import { ContactRecord } from "../psr/address-detail/address-detail-contact-record";
import { CallNotesModel } from "../retrieval-call-flow/callNotes-model";
import { CallFlowStep } from "./call-flow-step.enum";
import { CallFlowService } from "./call-flow.service";
import { CallResultType } from "./call-result-type.enum";
import { CallType } from "./call-type.enum";
import { UrlHelperService } from "./url-helper.service";

@Component({
  selector: "app-call-flow",
  templateUrl: "./call-flow.component.html",
  styleUrls: ["./call-flow.component.scss"],
  providers: [CallFlowService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallFlowComponent implements OnInit {
  @ViewChild("iframe") iframe: ElementRef;
  constructor(
    private callFlowService: CallFlowService,
    private readonly addressDetailService: AddressDetailService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private userService: UserService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private readonly formService: FormService,
    private readonly chaseSearchService: ChaseSearchService,
    private router: Router,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private providerService: ProviderService,
    private analyticsService: AnalyticsService,
    private vendorDetailStateService: VendorDetailStateService,
    private retrievalAddressDetailContactHistoryService: RetrievalAddressDetailContactHistoryService,
    private urlHelperService: UrlHelperService
  ) {
  }

  get displayCallConnecting(): boolean {
    return this.callConnectionResult === CallResultType.None
      && (this.callConnected || this.callDisconnectedAndCallflowNotInitiated);
  }

  get displayCouldNotConnect(): boolean {
    return this.callConnectionResult === CallResultType.Unsuccessful
      && this.currentSuccessfulCallFlowStep !== CallFlowStep.CallFlowCompleted;
  }

  get displayCouldConnect(): boolean {
    return this.callConnectionResult === CallResultType.Successful
      && this.currentSuccessfulCallFlowStep !== CallFlowStep.CallFlowCompleted;
  }

  get scriptStepTwoInProgress(): boolean {
    return this.currentSuccessfulCallFlowStep === CallFlowStep.ValidateProvider;
  }

  get scriptStepOneInProgress(): boolean {
    return (this.currentSuccessfulCallFlowStep === CallFlowStep.ValidateAddress);
  }

  get scriptStepThreeInProgress(): boolean {
    return this.currentSuccessfulCallFlowStep === CallFlowStep.SubmitCallNotes;
  }

  get providerValidated(): boolean {
    return this.callFlowStepsCompleted.includes(CallFlowStep.ValidateProvider);
  }

  get addressValidated(): boolean {
    return this.callFlowStepsCompleted.includes(CallFlowStep.ValidateAddress);
  }

  get callNotesInProgress(): boolean {
    return this.providerValidated && this.addressValidated && !this.scriptStepThreeInProgress;
  }

  get minDate(): Date {
    return new Date();
  }

  get isIncomingCall(): boolean {
    return this.callType === CallType.Incoming;
  }

  get anyChaseNotPendedAtUnVerifiedProvider(): boolean {
    if (!ArrayHelper.isAvailable(this.providersAtThisAddress)) {
      return;
    }

    return this.providersAtThisAddress.some(p => !p.isVerified && !p.isPended);
  }

  get followupCall(): boolean {
    return this.callType === CallType.Followup;
  }

  get displayValidationSteps(): boolean {
    return (!this.followupCall || (this.followupCall && this.anyChaseNotPendedAtUnVerifiedProvider));
  }

  get vendorCallNotesVisible(): boolean {
    return (this.vendorCall && this.vendorCallNotesInProgress);
  }

  get callNotesToSave(): string {
    const callNotesTemp = this.formGroupCallNotes.get("callNotes").value;

    return this.commitDate ?
      `${callNotesTemp}@noteCommitmentDate=${this.commitDate}` : callNotesTemp;
  }

  @Output() onCallConnection = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onCallNotesSave = new EventEmitter<null>();
  @Input() showCallFlowAndDialpad: boolean;
  @Input() freeFormDialpadOnly: boolean;
  @Input() callType: string;
  @Input() vendorCall = false;
  @Input() hasRouteShParameter = false;
  @Input() providerDetails: MasterProvider = null;

  private twilioApiUrl: string;
  private communicationApiUrl: string;
  contactRecord: ContactRecord;
  private contactMethodType = ContactMethodType.Call;
  twilioHeight = 420;
  callConnectionResult = CallResultType.None;
  callConnected = false;
  private actionSource = "MRCSAction";
  callUnSuccessfulResultTypes: SelectableInput[];
  formGroupCallUnSuccessful: FormGroup;
  isCallSuccessful: number;
  callNotesSave: CallNotesModel;
  callNotesCallType = "";
  private documentRetrieval = 5;   // Document Retrieval. TODO: Later create enum for functionalRoleIds
  currentSuccessfulCallFlowStep: string;
  private callDisconnectedAndCallflowNotInitiated: boolean;
  private callFlowStepsCompleted: string[] = [];
  formGroupCallNotes: FormGroup;
  isNoteError: boolean;
  callNotes: Textarea;
  spokeTo: Textbox;
  callSuccessfulSubtypes: Dropdown;
  callCategories: Dropdown;
  nextCallDate: Date = null;
  commitDate: Date = null;
  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  private documentRetrievalLead = 4; // TODO: Create enums for functional rol
  contactMethodPhone = 1;
  user: UserToken;
  twilioUrl: string;
  callNotesSaved = false;
  showConfirmCloseDialpad = false;
  isVerifyCallModalVisible = false;
  closeCallConfirmationText: string;
  isVerifyCallModalText: string;
  providerSearchRequest: ProviderSearchRequest;
  startRecord = 1;
  endRecord = 2147483647;
  providersAtThisAddress: ProviderItem[];
  formGroupUnSuccessfulCall: FormGroup;
  callUnsuccessfulSubtypes: Dropdown;
  vendorDetailState: VendorDetail;
  vendorCallNotesInProgress = false;
  contactLogId: number;
  providerInfoFetched = false;
  closeDialpad = false;
  isCommunicationEksUsed = false;
  communicationMicroserviceUrl: string;

  getTwiliourl(): string {
    const contactRecordString = JSON.stringify(this.contactRecord);
    if (this.isCommunicationEksUsed) {
      return `${this.twilioApiUrl}?contactRecord=${contactRecordString}&url=${this.communicationMicroserviceUrl}&freeFormDialpadOnly=${this.freeFormDialpadOnly}`;
    }
    return `${this.twilioApiUrl}?contactRecord=${contactRecordString}&freeFormDialpadOnly=${this.freeFormDialpadOnly}`;
  }

  @HostListener("window:message", ["$event"])
  onReceive(ev: MessageEvent) {
    switch (ev.data.callStatus) {
      case "twilio_connect":
        this.contactLogId = ev.data.contactLogId;
        this.callConnected = true;
        this.callConnectionResult = CallResultType.None;
        this.callDisconnectedAndCallflowNotInitiated = false;
        this.currentSuccessfulCallFlowStep = CallFlowStep.NotStarted;
        this.callFlowStepsCompleted = [];
        this.formGroupCallNotes.reset();
        if (!this.vendorCall) {
          this.formGroupUnSuccessfulCall.reset();
        }
        this.nextCallDate = null;
        this.commitDate = null;
        this.onCallConnection.emit(true);

        break;

      case "twilio_disconnect":
        this.contactLogId = ev.data.contactLogId;
        this.callDisconnectedAndCallflowNotInitiated =
          this.currentSuccessfulCallFlowStep === CallFlowStep.NotStarted;
        this.callConnected = false;
        this.onCallConnection.emit(false);

        break;

      case "close_dialpad":
        // this.addressDetailStateService.setData({});
        // this.vendorDetailStateService.setData(null);
        this.closeDialpad = true;
        this.showConfirmCloseCallFlow();

        break;

      case "previousContactCallNotes_NotSaved":
        this.showConfirmCloseCallFlow();

        break;

      default: break;
    }
  }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.isCommunicationEksUsed = this.user.isCommunicationEksEnabled;
    this.communicationMicroserviceUrl = `${this.user.communicationMsBaseUrl}api/`;
    this.twilioApiUrl = `${this.communicationMicroserviceUrl}call/newview` ;

    if (this.vendorCall) {
      this.createVendorCallForm();

      this.sink.add(
        this.vendorDetailStateService.state.subscribe(state => {
          if (state != null) {
            this.vendorDetailState = state;
            this.contactRecord = new ContactRecord({
              vendorId: state.vendorId,
              organizationId: this.user.organizationId,
              callerUserId: this.user.userId,
              contactResultType: null,
              contactMethodType: ContactMethodType.VendorCall,
              contactNumber: this.vendorDetailState?.phone,
            });
          }
        })
      );
    } else {
      this.initializeAddressCallSetup();
      this.sink.add(
        this.addressDetailStateService.state.subscribe(state => {
          if (state != null) {
            this.addressDetailState = state;
            this.contactRecord = new ContactRecord({
              addressId: state.masterDocumentSourceId,
              assignedTo: (this.user.functionalRoleIds.indexOf(this.documentRetrievalLead) > -1) ? null : this.user.userId,
              organizationId: this.user.organizationId,
              callerUserId: this.user.userId,
              contactResultType: null,
              contactMethodType: this.contactMethodPhone,
              contactNumber: StringHelper.isAvailable(this.providerContactNumber) ? this.providerContactNumber : state.contact.contactPhone,
              nextCallDateTime: state.nextCallDateTime,
            });


            this.providerSearchRequest = new ProviderSearchRequest(
              null, null, state.masterDocumentSourceId,
              null, null, null, null, null, this.startRecord, this.endRecord);

            if (!this.providerInfoFetched && state.hasMasterDocumentSourceId) {
              this.providerInfoFetched = true;
              this.providerService
                .providerSearch(this.providerSearchRequest)
                .subscribe(providers => {
                  this.providersAtThisAddress = providers;
                  this.changeDetector.markForCheck();
                });
            }
          }
        })
      );
    }

    if (this.callType === CallType.Incoming) {
      this.callNotesCallType = "MRCSActionIncomingCall"; // TODO: Create enum to remove hard coded string
      this.contactMethodType = ContactMethodType.Incoming_Call;
      this.formGroupCallNotes.get("callSuccessfulSubtypes").setValue(CallResultType.Successful);
    } else {
      this.callNotesCallType = "MRCSCallNotesSave";
      this.contactMethodType = this.vendorCall ? ContactMethodType.VendorCall : ContactMethodType.Call;
      this.currentSuccessfulCallFlowStep = CallFlowStep.NotStarted;
      if (!this.callConnected && !this.callNotesSaved) {
        this.twilioUrl = this.getTwiliourl();

        if (this.isCommunicationEksUsed) {
          this.urlHelperService.get(this.getTwiliourl())
            .subscribe(blob => {
              const communicationMSUrl = this.communicationMicroserviceUrl.replace("/api/", "");
              let data = blob;
              data = data.replaceAll('href="/communicationservice', `href="${communicationMSUrl}`);
              data = data.replaceAll('src="/communicationservice', `src="${communicationMSUrl}`);

              const doc = this.iframe.nativeElement.contentWindow.document;
              doc.open();
              doc.write(data);
              doc.close();
            });

          this.changeDetector.markForCheck();
        }
      }
    }
  }

  private updateMasterDocumentSourceStatus() {
    this.addressDetailService.getAddressDetail(this.addressDetailState.masterDocumentSourceId).subscribe({
      next: addressDetail => {

        this.addressDetailStateService.setData({
          masterDocumentSourceStatus: addressDetail.masterDocumentSourceStatus,

        });
        this.changeDetector.markForCheck();
      },
    });
  }

  initializeAddressCallSetup(): void {
    this.callCategories = new Dropdown({
      key: "callCategories",
    });
    this.callSuccessfulSubtypes = new Dropdown({
      key: "callSuccessfulSubtypes",
      validators: [Validators.required],
      errorMessages: {
        required: "Please select a call Result.",
      },
    });
    this.callUnsuccessfulSubtypes = new Dropdown({
      key: "callUnsuccessfulSubtypes",
      validators: [Validators.required],
      errorMessages: {
        required: "Please select a unsuccessful call Result.",
      },
    });

    this.callNotes = new Textarea({
      key: "callNotes",
      placeholder: "Please describe the call here...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000)],
    });

    this.spokeTo = new Textbox({
      key: "spokeTo",
      label: "Spoke To",
      validators: [Validators.required, Validators.maxLength(100)],
    });

    this.getCallCategories();

    this.formGroupCallNotes = this.formService.createFormGroup([this.callCategories, this.callSuccessfulSubtypes, this.callNotes, this.spokeTo]);
    this.formGroupUnSuccessfulCall = this.formService.createFormGroup([this.callCategories, this.callUnsuccessfulSubtypes]);
    this.changeDetector.markForCheck();
  }

  createVendorCallForm(): void {
    this.callNotes = new Textarea({
      key: "callNotes",
      placeholder: "Please describe the call here...",
      rows: 4,
      resize: Resize.VERTICAL,
      validators: [Validators.maxLength(1000)],
    });

    this.spokeTo = new Textbox({
      key: "spokeTo",
      label: "Spoke To",
      validators: [Validators.maxLength(100)],
    });

    this.formGroupCallNotes = this.formService.createFormGroup([this.callNotes, this.spokeTo]);
    this.changeDetector.markForCheck();
  }

  isFieldInvalid(field: string) {
    return !this.formGroupCallNotes.get(field).valid && this.formGroupCallNotes.get(field).touched;
  }

  isUnsuccessfulFormInvalid(field: string) {
    return !this.formGroupUnSuccessfulCall.get(field).valid && this.formGroupUnSuccessfulCall.get(field).touched;
  }

  showConfirmCloseCallFlow1(): void {
    this.showConfirmCloseDialpad = (this.callConnected || this.currentSuccessfulCallFlowStep !== CallFlowStep.CallFlowCompleted);

    if (this.callConnected) {
      this.closeCallConfirmationText = "The call is still in progress. Closing the dialpad will end the call.";
    } else if (this.displayCallConnecting) {
      this.closeCallConfirmationText = "Please select Yes or No.";
    } else {
      this.onHide.emit();
    }
  }

  showConfirmCloseCallFlow(): void {
    const displayWarning = (this.callConnected || this.currentSuccessfulCallFlowStep !== CallFlowStep.CallFlowCompleted);

    if (this.callConnected) {
      this.isVerifyCallModalText = "There is currently a call in progress. To leave this page you must end the call and submit call notes";
      this.isVerifyCallModalVisible = displayWarning;
    } else {
      if (this.displayCallConnecting) {
        this.closeDialpad = false;
        this.isVerifyCallModalText = "Please select if you were able to connect successfully.";
        this.isVerifyCallModalVisible = displayWarning;
      } else if (this.displayCouldConnect && this.displayValidationSteps && (this.scriptStepOneInProgress || this.scriptStepTwoInProgress)) {
        this.closeDialpad = false;
        this.isVerifyCallModalText = "Please complete validations to continue.";
        this.isVerifyCallModalVisible = displayWarning;
      } else if (this.displayCouldConnect && this.scriptStepThreeInProgress) {
        this.saveSuccessfulCallNotes();
      } else if (this.displayCouldNotConnect) {
        this.saveUnSuccessfulCallNotes();
      } else {
        if (this.closeDialpad) {
          this.addressDetailStateService.setData({});
          this.vendorDetailStateService.setData(null);
        }
        this.onHide.emit();
      }
    }

    setTimeout(() => this.changeDetector.markForCheck(), 600);
  }

  closeCallFlow(): void {
    if (this.callConnectionResult !== CallResultType.None) {
      this.endActiveCall();
    }
    this.onCallConnection.emit(false);
    this.onHide.emit();
    this.showConfirmCloseDialpad = false;
    this.providerInfoFetched = false;
  }

  cancelCloseCallFlow(): void {
    this.showConfirmCloseDialpad = false;
  }

  private endActiveCall(): void {
    this.addressDetailService.initiateCallProvider(null);
    if (this.callNotesSaved) {
      this.isVerifyCallModalText = "";
      this.isVerifyCallModalVisible = false;
    }
  }

  saveCallResultType(callResult: boolean): void {
    if (this.vendorCall) {
      this.saveVendorCallResult(callResult);
    } else {
      this.getCallResultTypesDropdowns(callResult);
      this.callConnectionResult = callResult ? CallResultType.Successful : CallResultType.Unsuccessful;

      const contactRecord =
        new ContactRecord({
          addressId: this.contactRecord.addressId,
          contactResultType: this.callConnectionResult,
          contactMethodType: this.contactMethodType,
          contactLogId: this.contactLogId,
        });

      this.callFlowService
        .saveCallResult(contactRecord, this.actionSource)
        .subscribe();

      this.loadCallNotesForm();

      if (this.callConnectionResult === CallResultType.Successful) {
        if (this.providerContactNumber != null) {
          const callCategory = this.callCategories.options.find(c => c.text === "Research Call");
          this.formGroupCallNotes.get("callCategories").setValue(callCategory.value);
          this.formGroupCallNotes.get("callCategories").disable();

          const callNotes = `Provider lookup: ${this.providerDetails.providerFirstName} ${this.providerDetails.providerLastName} at ${this.providerDetails.address1} ${this.providerDetails.address2} ${this.providerDetails.city} ${this.providerDetails.state}`;
          this.formGroupCallNotes.get("callNotes").setValue(callNotes);

          this.currentSuccessfulCallFlowStep = CallFlowStep.SubmitCallNotes;
          this.changeDetector.markForCheck();
        } else {
          this.callFlowService.isFollowUpCall(this.contactRecord.addressId).subscribe(
            (data: boolean) => {
              if (data) {
                this.callType = CallType.Followup;
                this.currentSuccessfulCallFlowStep = this.anyChaseNotPendedAtUnVerifiedProvider
                  ? CallFlowStep.ValidateAddress
                  : CallFlowStep.SubmitCallNotes;

              } else {
                this.currentSuccessfulCallFlowStep = CallFlowStep.ValidateAddress;
              }
              this.changeDetector.markForCheck();
            });
        }
      } else if (this.callConnectionResult === CallResultType.Unsuccessful) {
        if (this.providerContactNumber != null) {
          const callCategory = this.callCategories.options.find(c => c.text === "Research Call");
          this.formGroupUnSuccessfulCall.get("callCategories").setValue(callCategory.value);
          this.formGroupUnSuccessfulCall.get("callCategories").disable();
        }

        this.loadCallNotesForm();
        this.currentSuccessfulCallFlowStep = CallFlowStep.NotStarted;
        this.endActiveCall();
      }
    }
  }

  get providerContactNumber(): string {
    if (this.providerDetails == null && this.providerDetails == undefined) {
      return null;
    }
    if (StringHelper.isAvailable(this.providerDetails.phoneNumber)) {
      return this.providerDetails.phoneNumber;
    } else {
      return null;
    }
  }

  saveVendorCallResult(callResult: boolean): void {
    this.callConnectionResult = callResult ? CallResultType.Successful : CallResultType.Unsuccessful;

    const contactRecord =
      new ContactRecord({
        vendorId: this.contactRecord.vendorId,
        contactResultType: this.callConnectionResult,
        contactMethodType: this.contactMethodType,
        contactLogId: this.contactLogId,
      });

    this.callFlowService
      .saveCallResult(contactRecord, this.actionSource)
      .subscribe();

    this.vendorCallNotesInProgress = true;
    if (this.callConnectionResult === CallResultType.Unsuccessful) {
      this.currentSuccessfulCallFlowStep = CallFlowStep.NotStarted;
      this.endActiveCall();
    }

    this.changeDetector.markForCheck();
  }

  private getCallCategories(): void {
    this.analyticsService
      .getContactMethodTypes("", "call")
      .pipe(
        tap(clients => clients.unshift(new SelectableInput({ text: "Select Call Type", value: -1 })))
      )
      .subscribe(options => {
        this.callCategories = new Dropdown({ ...this.callCategories, options }) as any;
        this.formService.updateDom.next();
      });
  }

  private getCallResultTypesDropdowns(callResult: boolean): void {
    this.callFlowService.getCallTypesDropdown(callResult)
      .pipe(map(this.automapper.curryMany("CallResultType", "SelectableInput")))
      .subscribe(options => {
        if (callResult) {
          this.callSuccessfulSubtypes = new Dropdown({
            ...this.callSuccessfulSubtypes,
            options: ArrayHelper.addItemToSelect(options, "Select a Call Result", ""),
          } as any);
          this.changeDetector.markForCheck();
        } else {
          this.callUnSuccessfulResultTypes = options;
          this.callUnsuccessfulSubtypes = new Dropdown({
            ...this.callUnsuccessfulSubtypes,
            options: ArrayHelper.addItemToSelect(this.callUnSuccessfulResultTypes, "Select a Reason...", ""),
          } as any);
          this.changeDetector.markForCheck();
        }
      });
  }

  private loadCallNotesForm() {
    const contactNextCall = new Date(this.contactRecord.nextCallDateTime.toString());
    const contactNextCallDate = new Date(contactNextCall.getFullYear(), contactNextCall.getMonth(), contactNextCall.getDate());
    const today = new Date();
    const todayUTC = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());

    this.nextCallDate = DateHelper.isAvailable(contactNextCallDate) && contactNextCallDate > todayUTC ? contactNextCallDate : null;
    this.changeDetector.markForCheck();
  }

  saveUnSuccessfulCallNotes(): void {
    if (!this.validateCallNotesForm(this.formGroupUnSuccessfulCall)) {
      return;
    }

    if (this.formGroupUnSuccessfulCall.get("callCategories").value != null && this.formGroupUnSuccessfulCall.get("callCategories").value > -1) {
      this.contactMethodType = this.formGroupUnSuccessfulCall.get("callCategories").value;
    }

    this.callNotesSave = new CallNotesModel({
      contactLogId: this.contactLogId,
      retrievalLocationId: this.contactRecord.addressId,
      contactMethodType: this.contactMethodType,
      actionSource: this.callNotesCallType,
      functionalRoleId: this.documentRetrieval,
      callResultType: this.formGroupUnSuccessfulCall.get(this.callUnsuccessfulSubtypes.key).value,
      nextCallDateTime: this.nextCallDate ? this.nextCallDate.toString() : "",
    });

    this.saveCallNotes();
    this.endActiveCall();
    this.currentSuccessfulCallFlowStep = CallFlowStep.CallFlowCompleted;
    this.callConnected = false;
  }

  private saveCallNotes(): void {
    const calledAddress = true;
    this.callFlowService.saveCallNotes(this.callNotesSave, calledAddress).subscribe(
      () => {
        this.messagingService.showToast("Call Notes successfully saved.", SeverityType.SUCCESS);
        this.updateMasterDocumentSourceStatus();
        this.callNotesSaved = true;
        if (this.currentSuccessfulCallFlowStep === CallFlowStep.CallFlowCompleted) {
          this.isVerifyCallModalText = "";
          this.isVerifyCallModalVisible = false;
        }
        this.onCallNotesSave.emit();
        this.retrievalAddressDetailContactHistoryService.refreshContactHistoryEvent(true);
        if (!this.vendorCall) {
          this.chaseSearchService.resetFunction(this.contactRecord.addressId.toString());
          this.addressDetailStateService.setData({
            nextCallDateTime: this.callNotesSave.nextCallDateTime,
          });
          this.fetchTimelineItems();
        }

        this.addressDetailStateService.setData({
          lastContactedDate: new Date(),
        });

        if (this.closeDialpad) {
          this.addressDetailStateService.setData({});
          this.vendorDetailStateService.setData(null);
          this.onHide.emit();
        }
      },
      err => {
        this.messagingService.showToast("Failed to save Call Notes.", SeverityType.ERROR);
      }
    );
  }

  private getCallNotesFormValues(): void {
    this.changeDetector.markForCheck();

    if (this.formGroupCallNotes.get("callCategories").value != null && this.formGroupCallNotes.get("callCategories").value > -1) {
      this.contactMethodType = this.formGroupCallNotes.get("callCategories").value;
    }

    this.callNotesSave = new CallNotesModel({
      contactLogId: this.contactLogId,
      retrievalLocationId: this.contactRecord.addressId,
      contactMethodType: this.contactMethodType,
      actionSource: this.callNotesCallType,
      functionalRoleId: this.documentRetrieval,
      callResultType: this.formGroupCallNotes.get("callSuccessfulSubtypes").value,
      callNotes: this.callNotesToSave,
      spokeTo: this.formGroupCallNotes.get("spokeTo").value,
      nextCallDateTime: this.nextCallDate ? this.nextCallDate.toString() : "",
      commitDate: this.commitDate,
    });
  }

  private getVendorCallNotesFormValues(): void {
    this.changeDetector.markForCheck();

    this.callNotesSave = new CallNotesModel({
      contactLogId: this.contactLogId,
      vendorId: this.contactRecord.vendorId,
      contactMethodType: this.contactMethodType,
      actionSource: this.callNotesCallType,
      callNotes: this.callNotesToSave,
      spokeTo: this.formGroupCallNotes.get("spokeTo").value,
    });
  }

  saveSuccessfulCallNotes(): boolean {
    this.getCallNotesFormValues();
    if (this.validateCallNotesForm(this.formGroupCallNotes)) {
      this.currentSuccessfulCallFlowStep = CallFlowStep.CallFlowCompleted;
      this.saveCallNotes();
      return true;
    }

    return false;
  }

  saveVendorCallNotes(): void {
    this.getVendorCallNotesFormValues();
    this.vendorCallNotesInProgress = false;
    if (this.validateCallNotesForm(this.formGroupCallNotes)) {
      this.currentSuccessfulCallFlowStep = CallFlowStep.CallFlowCompleted;
      this.saveCallNotes();
    }
  }

  private validateCallNotesForm(formGroupToValidate: FormGroup): boolean {
    let isValid = false;
    this.markControlsAsTouched(formGroupToValidate);

    if (formGroupToValidate.invalid) {
      this.messagingService.showMessage("Please correct invalid entries.", SeverityType.ERROR);
      return false;
    }

    isValid = true;

    return isValid;
  }

  validateCallNotesFormToAllowRedirection(): boolean {
    let isValid = false;

    if (this.displayCouldConnect && this.scriptStepThreeInProgress) {
      this.markControlsAsTouched(this.formGroupCallNotes);
      isValid = !this.formGroupCallNotes.invalid;
    } else if (this.displayCouldNotConnect) {
      this.markControlsAsTouched(this.formGroupUnSuccessfulCall);
      isValid = !this.formGroupUnSuccessfulCall.invalid;
    }

    return isValid;
  }

  private markControlsAsTouched(formGroupToMarkAsTouched: FormGroup): void {
    Object.keys(formGroupToMarkAsTouched.controls).forEach(field => {
      const control = formGroupToMarkAsTouched.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  setValidationStep(validationStep: string): void {
    this.currentSuccessfulCallFlowStep = validationStep;
    if (validationStep.toLowerCase() === "validateProvider") {
      let url = `retrieval/addressdetail/${this.contactRecord.addressId}`;
      if (this.hasRouteShParameter) {
        url += "/sh/providers";
      } else {
        url += "/providers";
      }
      this.router.navigateByUrl(url);
    }
  }

  onProviderValidation(): void {
    if (!this.callFlowStepsCompleted.includes(CallFlowStep.ValidateProvider)) {
      this.callFlowStepsCompleted.push(CallFlowStep.ValidateProvider);
      this.currentSuccessfulCallFlowStep = CallFlowStep.SubmitCallNotes;
    } else {
      this.currentSuccessfulCallFlowStep = CallFlowStep.EditCompleted;
    }
  }

  onAddressValidation(): void {
    if (!this.callFlowStepsCompleted.includes(CallFlowStep.ValidateAddress)) {
      this.callFlowStepsCompleted.push(CallFlowStep.ValidateAddress);
      this.currentSuccessfulCallFlowStep = CallFlowStep.ValidateProvider;
      let url = `retrieval/addressdetail/${this.contactRecord.addressId}`;
      if (this.hasRouteShParameter) {
        url += "/sh/providers";
      } else {
        url += "/providers";
      }
      this.router.navigateByUrl(url);
    } else {
      this.currentSuccessfulCallFlowStep = CallFlowStep.EditCompleted;
    }
  }

  saveIncomingCallNotes(): void {
    if (this.saveSuccessfulCallNotes()) {
      this.closeCallFlow();
    }
  }

  private fetchTimelineItems(): void {
    this.addressTimelineService
      .get(this.addressDetailState.masterDocumentSourceId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

}
