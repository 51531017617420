import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AutoComplete } from "primeng/autocomplete";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { ConfirmAutocomplete } from "./confirm-autocomplete.model";

// FIXME: @obsolete() // TODO: npm run build //in order to test.
@Component({
  selector: "form-confirm-autocomplete",
  templateUrl: "./confirm-autocomplete.component.html",
  styleUrls: [
    "../autocomplete/autocomplete.component.scss",
    "../dropdown/dropdown.component.scss",
    "../textarea/textarea.component.scss",
    "../textbox/textbox.component.scss",
    "./confirm-autocomplete.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmAutocompleteComponent extends DynamicControlDirective<ConfirmAutocomplete> implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe = new Subject();
  filteredOptions: SelectableInput[] = [];
  @ViewChild(AutoComplete, { static: true }) primeAutocomplete: AutoComplete;
  selectedItem: string;
  validationOptions: SelectableInput[];


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());

    this.formService.updateDom
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());


    this.validationOptions = [
      new SelectableInput({ text: "Yes", value: 1 }),
      new SelectableInput({ text: "No", value: 2 }),
      new SelectableInput({ text: "Yes - With Transformation", value: 3 }),
    ];

    if (this.model == null) {
      this.model = {} as any;
    }

    if (this.model.saveInfo == null) {
      this.model.saveInfo = {} as any;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    if (this.primeAutocomplete.dropdownButton != null) {
      this.primeAutocomplete.dropdownButton.nativeElement.tabIndex = -1;
    }

    if (this.model.hasServerFilter) {
      this.filterOptions({ query: this.model.value });
    }
  }


  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get hiddenNote(): boolean {
    return this.hidden || this.model.saveInfo.validationId === 1;
  }


  validationChange(): void {
    if (StringHelper.isAvailable(this.model.adminValue) && this.model.saveInfo.validationId === 1) {
      this.control.setValue(this.model.adminValue);
    }
  }

  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }


  get classes(): string {
    return this.getClasses("autocomplete");
  }


  filterOptions(event): void {
    if (this.model.hasServerFilter) {
      this.model.serverFilter(event.query, this.assignToFilterOptoins.bind(this));
    } else {
      this.filteredOptions = this.model.options.filter(item => this.filter(item, event.query));
    }
  }

  private assignToFilterOptoins(options: SelectableInput[]): void {
    this.filteredOptions = options;

    // HACK: If the query returns 1 value then select as the value.
    const firstOption = this.filteredOptions.length === 1 ? this.filteredOptions[0] : null;
    if (firstOption != null && firstOption.value === this.model.value) {
      this.control.setValue(firstOption);
    }

    this.changeDetector.markForCheck();
  }

  private filter = (item: SelectableInput, query: string): boolean => {
    return this.model.filter
      ? this.model.filter(item, query)
      : this.contains(item, query);
  }

  private contains(item: SelectableInput, query: string): boolean {
    return item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }


  onEvent(event) {
    this.onChange.emit(event);
    this.onInput.emit(event);
  }
}
