import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnInit } from "@angular/core";
import { UserToken } from "../../../../../auth/user-token.model";
import { UserService } from "../../../../../core/user/user.service";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { GridView } from "../grid-view.model";

@Component({
  selector: "app-grid-view",
  templateUrl: "./grid-view.component.html",
  styleUrls: ["./grid-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridViewComponent implements OnInit {
  @Input() active = "";
  @Input() gridView: GridView;
  @Input() onEditGridView = new EventEmitter<GridView>(true);
  private user: UserToken;
  isEditViewButtonVisible = false;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUserToken();
  }

  get text(): string {
    return this.gridView.name;
  }

  get editable(): boolean {
    return NumberHelper.isGreaterThan(this.gridView.userId, 0) && this.gridView.userId === this.user.userId;
  }

  @HostBinding("style.textDecoration")
  get textDecoration(): string {
    return this.active === "active" ? "underline" : "none";
  }

  @HostListener("mouseenter")
  showEditViewButton(): void {
    this.isEditViewButtonVisible = this.editable;
  }

  @HostListener("mouseleave")
  hideEditViewButton(): void {
    this.isEditViewButtonVisible = false;
  }

  editGridView(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onEditGridView.emit(this.gridView);
  }
}
