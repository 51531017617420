<div #containerHeight class="chase-navigation-tags">
  <div class="chase-navigation">
    <div class="nav-logo">
      <div class="nav-logo_logo" *ngIf="hideLeftNavigation" (click)="navigateToLoginLanding()">
        <app-icon
          iconStyle="reveleer"
          iconType="reveleer"
          iconName="logo-icon--transparent"
        ></app-icon>
      </div>
      <div class="nav-logo_nav-controller">
          <app-icon iconName="chevron-left"></app-icon>

        <div class="toogle-nav" (click)="toggleLeftNavigation()">
          <span *ngIf="hideLeftNavigation">MAIN</span>
          <span *ngIf="!hideLeftNavigation">HIDE</span>
        </div>
      </div>
    </div>

    <app-sub-menu
      [menuItems]="menuItems"
      [currentRoute]="pathLastRoute"
      (onClick)="onMenuItemClick($event)"
    >
    </app-sub-menu>
  </div>

  <div class="chase-tags">
    <div class="chase-detail__button-container">
      <div class="chase-detail__tag-container" *ngIf="isChaseInfoTabSelected">
        <app-view-tags
          [tagType]="tagType"
          [objectId]="chaseId"
          [isGrid]="false"
          (onUpdate)="updateTimelineData()"
        >
        </app-view-tags>
      </div>

      <div class="assign-pend-buttons">
        <app-button
        *ngIf="showAssignButton"
        text="Assign"
        class="chase-button"
        (onClick)="openAssignModal()"
      ></app-button>
      <app-button
        *ngIf="!isHealthCardTabSelected"
        [ngClass]="pendButtonClass"
        [text]="pendButtonLabel"
        [disabled]="!assignedToCurrentUser"
        (onClick)="openPendModal()"
      ></app-button>
      </div>
    </div>
  </div>
</div>
<div class="chase-detail-pend-modal">
  <app-pend-modal [(visible)]="isPendModalVisible" (onHide)="closeModal()" (onUpdate)="updateChaseData()"
    [(chases)]="selectedChases" [clinical]="clinical" [pageType]="fromPage"
    [selectedChasesStatus]="selectedChaseStatus"></app-pend-modal>

</div>

<app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible" (onHide)="closeModal()"
  (onUpdate)="updateChaseAssignedData()" [(chases)]="selectedChases" [pageType]="fromPage" isUnassignEnabled="false">
</app-chase-assignment-modal>
