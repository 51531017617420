<div class="address-detail-info-access">
  <h3 class="address-detail-info-access__header">ACCESS INFORMATION</h3>
  <app-button classes="small secondary" 
              class="address-detail-info-access__edit-button" 
              text="EDIT"
              (onClick)="showEditAccessModal()"></app-button>
  <retrieval-address-detail-info-access-edit
    [isEditAccessVisible]="isEditAccessView"
    (formClose)="receiveEditAccessValue($event)"
    [retrievalType]="retrievalType"></retrieval-address-detail-info-access-edit>
</div>
<app-info-template>
  <app-info-item-template>
    <app-info-item-container [infoItems]="accessInfo"></app-info-item-container>
  </app-info-item-template>
  <app-info-item-template>
    <app-info-item-container [infoItems]="emrCredentialInfo"></app-info-item-container>
  </app-info-item-template>
  <app-info-item-template>
    <retrieval-address-detail-info-access-docs [addressId]="addressId"></retrieval-address-detail-info-access-docs>
  </app-info-item-template>
</app-info-template>
