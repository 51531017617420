<div class="chase-query-container">
  <div class="header bold">CHASES</div>
  <div class="header bold rightAlign">{{this.totalChasesMessage}}</div>
  <div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-server-grid
        [configuration]="chaseGridConfiguration"
        [request]="chaseSearchRequest"
        [refresh]="gridRefresh"
        [(selection)]="serverGridSelection"
        [filterTemplate]="filterForm"
        [actionTemplate]="actionTemplate">

        <ng-template #filterForm let-form>
          <form [formGroup]="form">
            <app-tab-menu orientation="left">

              <app-tab-panel header="Chase Id / Client Chase Key">
                <div class="ui-g-12 ui-md-12 ui-lg-12">
                  <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
                </div>
              </app-tab-panel>

              <app-tab-panel header="Project(s)">
                <form-factory
                  class="checkbox-group--halves"
                  [formGroup]="form"
                  [model]="projectsInput">
                </form-factory>
              </app-tab-panel>

              <app-tab-panel header="Status">
                <form-factory
                  class="checkbox-group--halves"
                  [formGroup]="form"
                  [model]="statusesInput">
                </form-factory>
              </app-tab-panel>

              <app-tab-panel header="Measure(s)">
                <form-factory
                  class="checkbox-group--fourths"
                  [formGroup]="form"
                  [model]="measuresInput">
                </form-factory>
              </app-tab-panel>

            </app-tab-menu>
          </form>
        </ng-template>

        <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
          <div class="action-template-container">
            <app-button text="View Details" (onClick)="viewDetails(rowData); localActionPopover.hide();"></app-button>
            <app-button text="Pend Chase" (onClick)="openPendModal(rowData); localActionPopover.hide();"></app-button>
            <app-button *ngIf="rowData.workflowStatusName!='Chart collection'" text="Assign/Reassign Chase"
              (onClick)="openAssignReassignModal(rowData); localActionPopover.hide();"></app-button>
            <app-button text="Print Provider Packet" (onClick)="printProvider(rowData)"></app-button>
          </div>
        </ng-template>

      </app-server-grid>
    </div>
  </div>
</div>



<app-pend-modal
  [(visible)]="isPendModalVisible"
  (onHide)="closeModal()"
  (onUpdate)="gridRefresh.emit()"
  [(chases)]="selectedChases">
</app-pend-modal>

<app-modal [(visible)]="isAssignModalVisible" header="Assigned To">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="chaseAssignForm" [model]="userInput" (onChange)="onSelectType($event)"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button" text="ASSIGN" (onClick)="onChaseAssign()" [disabled]="isChaseAssignDisabled">
    </app-button>
  </footer>
</app-modal>