import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { ChangeOwnerAssign } from "./change-owner-assign.model";

@Injectable()
export class InternalPendsChangeOwnerService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private route: ActivatedRoute
  ) { }

  getPendGdFromPath(): number {
    return this.route.snapshot.params.pendGd;
  }

  getAssignedToUsersList(ownedBy: string, chasePendId: number, roleType?: string): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}pend/assigntouserlist?owner=${ownedBy}&chasePendId=${chasePendId}&roleType=${roleType}`;
    return this.http.get(url).pipe(map((response: any) => response as SelectableInput[]));
  }

  updateChangeOwner(changeOwnerAssign: ChangeOwnerAssign): Observable<number> {
    const url = `${this.baseApiUrl}pend/updatechangeownerandassign`;

    return this.http.post(url, changeOwnerAssign, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          } else {

            return 0;
          }
        })
      );
  }

}
