import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { ChartFilter } from "./chart-filter.model";

export const mapChartFilters = (automapper: IAutoMapper): void => {
    automapper
    .createMap("default", "ChartFilters")
        .forMember("chaseId", o => o.chaseId)
        .forMember("memberFilters", o => automapper.mapMany("default", "ChaseFilter", o.memberFilters))
        .forMember("encounterFilters", o => automapper.mapMany("default", "ChaseFilter", o.encounterFilters))
        .forMember("diagnosisFilters", o => automapper.mapMany("default", "ChaseFilter", o.diagnosisFilters));
};

export class ChartFilters {
    chaseId: number;
    memberFilters: ChartFilter[];
    encounterFilters: ChartFilter[];
    diagnosisFilters: ChartFilter[];

    constructor(options: Partial<ChartFilters> = {}) {
        this.chaseId = options.chaseId;
        this.memberFilters = options.memberFilters;
        this.encounterFilters = options.encounterFilters;
        this.diagnosisFilters = options.diagnosisFilters;
    }
}
