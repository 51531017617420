import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { AuditService } from "../../audit/audit.service";
import { Member } from "../../clinical/member.model";
import { ProjectConfigurationService } from "../../project/project-config/project-config.service";
import { ProjectConfiguration } from "../../project/project-config/project-configuration.model";
import { Project } from "../../project/project.model";
import { PageTypeName } from "../../retrieval/retreival-document-review/page-type.enum";
import { DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED, DEMOGRAPHIC_REQUIRED, ENABLEMRRBOT, ENCOUNTER_TYPE_REQUIRED, MEMBER_CENTRIC_CHASE_RETRIEVAL, OMISSION_CODE_ENABLED, REVIEW_PERIOD_FROM_DATE, REVIEW_PERIOD_THRU_DATE, SERVICE_PROVIDER_REQUIRED, VALIDATION_REASON_CODE_REQUIRED } from "./chase-detail-chart/attributes";
import { ChaseDetailStateService } from "./chase-detail-state.service";
import { ChaseDetailService } from "./chase-detail.service";

@Component({
  selector: "member-chase-detail",
  templateUrl: "./chase-detail.component.html",
  styleUrls: ["./chase-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private chaseId: number;


  constructor(
    private readonly createPendService: CreatePendService,
    private chaseDetailService: ChaseDetailService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private projectConfigurationService: ProjectConfigurationService,
    private auditService: AuditService
  ) { }

  ngOnInit() {
    this.userAuditLog();
    this.sink.add(
      this.activatedRoute.paramMap.subscribe(params => {
        if (this.chaseId !== +params.get("chaseGd")) {
          this.chaseId = +params.get("chaseGd");
          this.getChaseDetails();
          this.getChaseOCRDetails();
          this.changeDetector.markForCheck();
        }
      }),
      this.createPendService.reset.subscribe(this.getChaseDetails)
    );
  }

  ngOnDestroy() {
    this.chaseDetailService.chaseDetailsChange.next([]);
  }
  userAuditLog(): void {
     const url = window.location.href;
     this.auditService.userAuditLog(PageTypeName.ChaseDetail, url)
    .subscribe(() => {
      });
 }

  private getChaseDetails(): void {
    this.chaseDetailService.getChaseDetail(this.chaseId).subscribe(chaseDetail => {
      this.chaseDetailService.chaseDetailsChange.next(chaseDetail.keyValueItems);
      const project = new Project({ ...chaseDetail });
      this.getProjectConfiguration(project);
      this.chaseDetailStateService.setData({
        ...chaseDetail,
        member: new Member({ ...chaseDetail }),
        project,
      });
    });
  }

  private getChaseOCRDetails(): void {
    this.chaseDetailService.getChaseOCRDetails(this.chaseId).subscribe(res => {
      this.chaseDetailService.ocrDataAvailableChange.next(res);
    });
  }

  private getProjectConfiguration({ projectId }: Project): void {

    const attributeIdsAsCsv = `${REVIEW_PERIOD_FROM_DATE.attributeId},
      ${REVIEW_PERIOD_THRU_DATE.attributeId},
      ${SERVICE_PROVIDER_REQUIRED.attributeId},
      ${VALIDATION_REASON_CODE_REQUIRED.attributeId},
      ${ENCOUNTER_TYPE_REQUIRED.attributeId},
      ${ENABLEMRRBOT.attributeId},
      ${MEMBER_CENTRIC_CHASE_RETRIEVAL.attributeId},
      ${OMISSION_CODE_ENABLED.attributeId},
      ${DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED.attributeId},
      ${DEMOGRAPHIC_REQUIRED.attributeId}`;

    this.projectConfigurationService.getProjectAttributeList(projectId, attributeIdsAsCsv)
      .subscribe(configs => {
        this.chaseDetailStateService.setData({
          projectConfiguration: new ProjectConfiguration({
            reviewPeriodFromDate: configs.find(x => x.attributeId === REVIEW_PERIOD_FROM_DATE.attributeId).attributeValue,
            reviewPeriodThruDate: configs.find(x => x.attributeId === REVIEW_PERIOD_THRU_DATE.attributeId).attributeValue,
            serviceProviderRequired: configs.find(x => x.attributeId === SERVICE_PROVIDER_REQUIRED.attributeId).attributeValue,
            validationReasonCodeRequired: configs.find(x => x.attributeId === VALIDATION_REASON_CODE_REQUIRED.attributeId).attributeValue,
            encounterTypeRequired: configs.find(x => x.attributeId === ENCOUNTER_TYPE_REQUIRED.attributeId).attributeValue,
            enableMrrBot: configs.find(x => x.attributeId === ENABLEMRRBOT.attributeId).attributeValue,
            memberCentricChaseRetrieval: configs.find(x => x.attributeId === MEMBER_CENTRIC_CHASE_RETRIEVAL.attributeId).attributeValue,
            omissionCodeEnabled: configs.find(x => x.attributeId === OMISSION_CODE_ENABLED.attributeId).attributeValue,
            dataEntryProviderConfirmationEnabled: configs.find(x => x.attributeId === DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED.attributeId).attributeValue,
            demographicRequired: configs.find(x => x.attributeId === DEMOGRAPHIC_REQUIRED.attributeId).attributeValue,
          }),
        });
      });
  }
}
