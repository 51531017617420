import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { InvoiceType } from "../../../../shared/invoice/invoice-type.enum";
import { PhoneNumberPipe } from "../../../../shared/pipes/phone.pipe";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { AdditionalDetailData } from "./vendor-additional-detail-data.model";
import { VendorContactDetailData } from "./vendor-contact-detail-data.model";
import { VendorInvoiceSettingData } from "./vendor-invoice-setting-data.model";

export const mapVendorDetail = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "VendorDetail")
        .forMember("vendorId", o => o.vendorId)
        .forMember("name", o => o.name)
        .forMember("phoneNumber", o => o.phoneNumber)
        .forMember("faxNumber", o => o.faxNumber)
        .forMember("address1", o => o.address1)
        .forMember("address2", o => o.address2)
        .forMember("city", o => o.city)
        .forMember("state", o => o.state)
        .forMember("status", o => o.status)
        .forMember("postalCode", o => o.postalCode)
        .forMember("vendorContactData", o => o.vendorContactData)
        .forMember("vendorAdditionalData", o => o.vendorAdditionalData)
        .forMember("preferredPaymentMethod", o => o.preferredPaymentMethod)
        .forMember("portalUrl", o => o.portalUrl)
        .forMember("notes", o => o.notes)
        .forMember("vendorInvoiceSettingData", o => o.vendorInvoiceSettingData)
        .forMember("vendorInvoiceTypeId", o => o.vendorInvoiceTypeId)
        .forMember("vendorChartPrice", o => o.vendorChartPrice)
        .forMember("phone", o => o.phone)
        .forMember("fax", o => o.fax)
        .forMember("masterDocumentSourceCount", o => o.masterDocumentSourceCount)
        .forMember("chases", o => o.chases)
        .forMember("invoices", o => o.invoices)
        .forMember("unpaidInvoiceCount", o => o.unpaidInvoiceCount);
};
export class VendorDetail {
  private phonePipe = new PhoneNumberPipe();

  vendorId: number;
  name: string;
  phoneNumber: string;
  faxNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  status: string;
  postalCode: string;
  vendorContactData: VendorContactDetailData;
  vendorAdditionalData?: AdditionalDetailData;
  preferredPaymentMethod: string;
  portalUrl: string;
  notes: string;
  vendorInvoiceSettingData: VendorInvoiceSettingData;
  vendorInvoiceTypeId: InvoiceType;
  vendorChartPrice: string;
  phone: string;
  fax: string;
  masterDocumentSourceCount: number;
  chases: number;
  invoices: number;
  unpaidInvoiceCount: number;

  constructor(options: {
    vendorId?: number;
    name?: string;
    phoneNumber?: string;
    faxNumber?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    status?: string;
    postalCode?: string;
    vendorContactData?: VendorContactDetailData;
    vendorAdditionalData?: AdditionalDetailData;
    preferredPaymentMethod?: string;
    portalUrl?: string;
    notes?: string;
    vendorInvoiceSettingData?: VendorInvoiceSettingData;
    vendorChartPrice?: string;
    vendorInvoiceTypeId: InvoiceType;
    phone?: string;
    fax?: string;
    masterDocumentSourceCount?: number;
    chases?: number;
    invoices?: number;
    unpaidInvoiceCount?: number;
  }) {
    this.vendorId = options.vendorId;
    this.name = options.name;
    this.phoneNumber = options.phoneNumber;
    this.faxNumber = options.faxNumber;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.city = options.city;
    this.state = options.state;
    this.status = options.status;
    this.postalCode = options.postalCode;
    this.vendorContactData = options.vendorContactData;
    this.vendorAdditionalData = options.vendorAdditionalData;
    this.preferredPaymentMethod = options.preferredPaymentMethod;
    this.portalUrl = options.portalUrl;
    this.notes = options.notes;
    this.vendorInvoiceSettingData = options.vendorInvoiceSettingData;
    this.vendorInvoiceTypeId = options.vendorInvoiceTypeId;
    this.vendorChartPrice = options.vendorChartPrice;
    this.phone = this.phonePipe.transform(options.phone);
    this.fax = options.fax;
    this.masterDocumentSourceCount = options.masterDocumentSourceCount;
    this.chases = options.chases;
    this.invoices = options.invoices;
    this.unpaidInvoiceCount = options.unpaidInvoiceCount;
  }

  get hasVendorId(): boolean {
    return NumberHelper.isAvailable(this.vendorId);
  }
}
