import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TimelineItem } from "./timeline-item.model";

@Component({
  selector: "app-timeline-item",
  templateUrl: "./timeline-item.component.html",
  styleUrls: ["./timeline-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineItemComponent {
  @Input() timelineItem: TimelineItem;
  @Input() isChaseTimeline = false;
  getNotes(value: string) {
    return value.replace("\\n", "<br>");
  }
  isPursuitApproved(value: string) {
    return !(value === "Pursuit Approved" && this.isChaseTimeline);
  }
}
