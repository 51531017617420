import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../dynamic-forms/form.service";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../dynamic-forms/inputs/dynamic-input.model";
import { TextboxType } from "../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { RegExHelper } from "../../utilities/reg-Ex-Helper";
import { InvoiceSetting } from "./invoice-setting.model";
import { InvoiceSettingService } from "./invoice-setting.service";
import { InvoiceType } from "./invoice-type.enum";

@Component({
  selector: "app-invoice-setting",
  templateUrl: "./invoice-setting.component.html",
  styleUrls: ["./invoice-setting.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InvoiceSettingService],
})

export class InvoiceSettingComponent implements OnInit {
  invoiceSettingFormGroup: FormGroup;
  invoiceTypes: Dropdown;
  chartType: Textbox;
  cnrType: Textbox;
  chartPrice: Textbox;
  cnrPrice: Textbox;
  codValue: Textbox;
  invoiceTypeName: Textbox;
  isConfirmationModalVisible = false;
  private pInvoiceSetting: InvoiceSetting;
  @Input() vendorFormData: FormGroup;
  @Output() onFormGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Input()
  get invoiceSetting(): InvoiceSetting {
    return this.pInvoiceSetting;
  }
  set invoiceSetting(value: InvoiceSetting) {
    this.pInvoiceSetting = value;
    this.setInputsIfEdit();
  }

  constructor(private readonly formService: FormService, private changeDetector: ChangeDetectorRef, private service: InvoiceSettingService) {
    this.initializeControls();
    this.createForm();
  }

  ngOnInit() {
    this.getInvoiceTypes();
    this.addGroupToVendorForm();
  }

  initializeControls(): void {
    this.invoiceTypes = new Dropdown({
      key: "invoiceTypes",
      label: "INVOICE TYPE",
      placeholder: "Select Type",
      validators: [Validators.required],
    });
    this.invoiceTypeName = new Textbox({
      key: "invoiceTypeName",
      label: "INVOICE TYPE",
      placeholder: "Invoice Type",
      readonly: true,
    });
    this.chartType = new Textbox({
      key: "chartType",
      label: "CHART TYPE",
      value: "Chart",
      readonly: true,
    });
    this.cnrType = new Textbox({
      key: "cnrType",
      label: "",
      value: "CNR",
      readonly: true,
    });
    this.chartPrice = new Textbox({
      key: "chartPrice",
      label: "THRESHOLD",
      type: TextboxType.NUMBER,
      placeholder: "Enter Price",
    });
    this.cnrPrice = new Textbox({
      key: "cnrPrice",
      label: ".",
      type: TextboxType.NUMBER,
      placeholder: "Enter Price",
    });
    this.codValue = new Textbox({
      key: "codValue",
      label: "COD THRESHOLD",
      placeholder: "Enter Price",
    });
  }

  private createForm(): void {
    this.invoiceSettingFormGroup = this.formService.createFormGroup([
      this.invoiceTypes,
      this.invoiceTypeName,
      this.chartType,
      this.cnrType,
      this.chartPrice,
      this.cnrPrice,
      this.codValue]);

  }

  setInputsIfEdit(): void {
    if (this.hasInvoiceSetting) {
      this.invoiceSettingFormGroup.get(this.invoiceTypes.key).setValue(this.invoiceSetting.invoiceTypeId);
      this.invoiceSettingFormGroup.get(this.invoiceTypeName.key).setValue(InvoiceType[this.invoiceSetting.invoiceTypeId]);
      this.invoiceSettingFormGroup.get(this.codValue.key).setValue(this.invoiceSetting.price);
      if (this.hasChartType) {
        if (this.invoiceSetting.chartTypes[0]) {
          this.invoiceSettingFormGroup.get(this.chartType.key).setValue(this.invoiceSetting.chartTypes[0].code);
          this.invoiceSettingFormGroup.get(this.chartPrice.key).setValue(this.invoiceSetting.chartTypes[0].threshold);
        }
        if (this.invoiceSetting.chartTypes[1]) {
          this.invoiceSettingFormGroup.get(this.cnrType.key).setValue(this.invoiceSetting.chartTypes[1].code);
          this.invoiceSettingFormGroup.get(this.cnrPrice.key).setValue(this.invoiceSetting.chartTypes[1].threshold);
        }
        this.setPriceValidation(false);
      }
      this.changeDetector.markForCheck();
      this.formService.updateDom.next();
    }
  }

  setPriceValidation(resetValue: boolean): void {
    if (resetValue) {
      this.invoiceSettingFormGroup.get(this.chartPrice.key).reset();
      this.invoiceSettingFormGroup.get(this.cnrPrice.key).reset();
    }

    const validators = [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
      Validators.pattern(RegExHelper.invoiceAmount),
    ];

    const errorMessages = {
      required: "Enter number between 0 - 10000.",
      min: "Enter number between 0 - 10000.",
      max: "Enter number between 0 - 10000.",
      pattern: "Enter a dollar amount between 0 - 10000 and only two numbers are allowed after decimal point.",
    };

    if (this.hasChartType) {
      this.invoiceSettingFormGroup.get(this.chartType.key).setValue("Chart");
      this.invoiceSettingFormGroup.get(this.cnrType.key).setValue("CNR");
      this.chartPrice = new Textbox({
        ...this.chartPrice,
        validators,
        errorMessages,
      } as any);
      this.cnrPrice = new Textbox({
        ...this.cnrPrice,
        validators,
        errorMessages,
      } as any);

      this.setValidators([this.chartPrice, this.cnrPrice]);

    } else {
      this.clearValidators([this.chartPrice.key, this.cnrPrice.key]);

    }
    this.changeDetector.markForCheck();
  }

  private setValidators(controls: DynamicInput[]): void {
    controls.forEach(control => {
      this.invoiceSettingFormGroup.get(control.key).setValidators(control.validators);
    });
  }

  private clearValidators(path: string[]): void {
    path.forEach(control => {
      this.invoiceSettingFormGroup.get(control).clearValidators();
      this.invoiceSettingFormGroup.get(control).updateValueAndValidity();
    });
  }

  get hasChartType(): boolean {
    const invoiceType = this.invoiceSettingFormGroup.get(this.invoiceTypes.key).value;
    return invoiceType === InvoiceType.NET30 || invoiceType === InvoiceType.MANAGED;
  }

  private getInvoiceTypes(): void {
    if (!this.isReadOnly) {
      this.service
        .getInvoiceTypes()
        .subscribe(options => {
          this.invoiceTypes = new Dropdown({ ...this.invoiceTypes, options } as any);
        });

      this.changeDetector.markForCheck();
      this.formService.updateDom.next();
    }
  }

  get isReadOnly(): boolean {
    return this.invoiceSetting != null && this.invoiceSetting.isReadOnly;
  }

  onConfirmationClick() {
    this.isConfirmationModalVisible = false;
    this.changeDetector.markForCheck();
  }

  onPriceChange(controlKey: string): void {
    this.isConfirmationModalVisible = this.hasVendorId && this.hasInvoicePriceValue(controlKey);
  }

  get hasInvoiceSetting(): boolean {
    return this.invoiceSetting != null;
  }

  get hasVendorId(): boolean {
    return this.hasInvoiceSetting &&
      NumberHelper.isGreaterThan(this.invoiceSetting.vendorId, 0);
  }

  hasInvoicePriceValue(controlKey: string): boolean {
    return StringHelper.isAvailable(this.invoiceSettingFormGroup.get(controlKey).value);
  }

  private addGroupToVendorForm(): void {
    if (this.vendorFormData != null) {
      this.vendorFormData.addControl("invoiceSettings",  this.invoiceSettingFormGroup);
      this.onFormGroupChange.emit(this.vendorFormData);
    }
  }

}
