/* tslint:disable:max-file-line-count */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Resize } from "../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../dynamic-forms/inputs/textarea/textarea.model";
import { DocumentRequestService } from "../../../../shared/document/document-request.service";
import { InvoiceType } from "../../../../shared/invoice/invoice-type.enum";
import { TagType } from "../../../../shared/tags/model/tag-type.enum";
import { IOnUpload } from "../../../../shared/upload/upload.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ChaseItem } from "../../../api/chase-search/chase-search-result-item";
import { ProjectFileService } from "../../project/project-files/project-file.service";
import { AddressDetailInfoAssignmentService } from "../../retrieval/address-detail/address-detail-info/address-detail-info-assignment/address-detail-info-assignment.service";
import { AddressDetailInfoEditService } from "../../retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.service";
import { DocumentSourceType } from "../../retrieval/retrieval-document-source-type.enum";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";
import { AddressBulkUpdate } from "./address-bulk-update.model";
import { BulkAction } from "./bulk-action.enum";
import { BulkType } from "./bulk-type.enum";
import { BulkUpdateAction } from "./bulk-update-action.enum";
import { AddressBulkUpdateActionType } from "./bulk-update-address/address-bulk-update-action-type.enum";
import { DocumentBulkUpdateActionType } from "./bulk-update-document/document-bulk-update-action-type.enum";
import { BulkUpdateManageTagComponent } from "./bulk-update-manage-tag/bulk-update-manage-tag.component";
import { BulkUpdatesService } from "./bulk-updates.service";
import { DocumentBulkUpdate } from "./model/document-bulk-update.model";

@Component({
  selector: "app-bulk-updates",
  templateUrl: "./bulk-updates.component.html",
  styleUrls: ["./bulk-updates.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BulkUpdatesService],
})
export class BulkUpdatesComponent implements OnInit {

  private readonly uploadChaseLimitForCopyCharts = 10000;
  private readonly uploadChaseLimitForBulkUpdate = 1000;

  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private service: BulkUpdatesService,
    private messagingService: MessagingService,
    protected readonly authService: AuthService,
    private retrievalService: RetrievalPageService,
    private automapper: AutomapperService,
    private addressDetailInfoAssignmentService: AddressDetailInfoAssignmentService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly documentRequestService: DocumentRequestService,
    private userService: UserService,
    private addressDetailInfoEditService: AddressDetailInfoEditService,
    private projectFileService: ProjectFileService,
    private serviceOrg: ServiceOrgConfigurationService
  ) {
    this.minimumDate = new Date();
  }
  get checkFileUploadPattern(): boolean {
    const pattern = /[a-zA-Z!#$%&'()-*+./:<=>?@[\]^_`{|}~]/;
    let selectedIds = null;
    if (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase) {
      if (this.showOriginalChasesId) {
        selectedIds = this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.originalChaseIds.value);
      } else if (this.showCopyOverChasesId) {
        selectedIds = this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.copyOverChaseIds.value);
      }
    } else {
      if (this.showChasesId) {
        selectedIds = this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.selectedChaseIds.value);
      }
    }
    if (selectedIds != null) {
      if (selectedIds.match(pattern)) {
        this.showUploadErrorMessage();
        return false;
      } else {
        this.isShowContinueButton = true;
        this.hasClinicalPends = false;
        return true;
      }
    } else {
      if (this.showChaseskey) {
        this.isShowContinueButton = true;
        this.hasClinicalPends = false;
        return true;
      }
    }

  }

  get isBulkPendUpdate(): boolean {
    return BooleanHelper.tryGet(this.bulkPendActionValue as boolean, false);
  }

  get bulkPendActionValue(): number | string | boolean | null {
    switch (this.formValues[this.bulkUpdateActionItem]) {
      case BulkUpdateAction.Resolve:
        return 3;
      case BulkUpdateAction.Close:
        return 4;
      case BulkUpdateAction.ChangeOwnerToClient:
        return "C";
      case BulkUpdateAction.ChangeOwnerToSO:
        return "O";
      case BulkUpdateAction.Update3rdPartyFields:
        return true;
      default:
        return null;
    }
  }

  get isShowPend(): boolean {
    return this.selectedBulkUpdateType === BulkType.Pends;
  }

  get isShowClientOrgAndChaseControls(): boolean {
    return this.selectedBulkUpdateType === BulkType.Chases;
  }

  get isShowProjectsAndAddressControls(): boolean {
    return this.selectedBulkUpdateType === BulkType.Addresses;
  }

  get bulkUpdateIds(): string {
    switch (this.selectedBulkUpdateType) {
      case BulkType.Chases: return "Chase IDs";
      case BulkType.Pends: return "Pend IDs";
      case BulkType.Addresses: return "Address IDs";
      case BulkType.Documents: return "Document IDs";
      default: return "";
    }
  }

  get selectedIdsValue(): string {
    return this.formGroup.get(this.selectedChaseIds.getMasterKey()).value;
  }

  get isBulkUpdateActionsSelected(): boolean {
    return !NumberHelper.isAvailable(this.selectedBulkUpdateActions);
  }

  get selectedProjectValue(): number {
    return this.formGroup.get(this.projectInput.key).value;
  }

  get isClientOrgSelected(): boolean {
    return (this.formGroup.controls.clientOrgSelected.touched && !NumberHelper.isGreaterThan(this.selectedClientOrg, 0));
  }

  get isMarkAsContactedActionTypeSelected(): boolean {
    return this.isShowProjectsAndAddressControls && this.selectedBulkUpdateActions === AddressBulkUpdateActionType.MarkAsContacted;
  }

  get isChangeRetrievalActionTypeSelected(): boolean {
    return this.selectedBulkUpdateActions === AddressBulkUpdateActionType.ChangeRetrievalType;
  }

  get retrievalTypeId(): number {
    return this.formGroup.get("changeRetrievalTypeInput").value;
  }

  get isThirdPartyRetrievalTypeSelected(): boolean {
    return (this.formGroup.get("changeRetrievalTypeInput").value === String(DocumentSourceType.THIRDPARTY) && this.selectedBulkUpdateActions === AddressBulkUpdateActionType.ChangeRetrievalType);
  }

  get thirdPartyVendorId(): number {
    return this.formGroup.get("assignThirdPartyVendorInput").value;
  }

  get hasManageTagControl(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Chases || this.selectedBulkUpdateType === BulkType.Addresses || this.selectedBulkUpdateType === BulkType.Documents) &&
      (this.tagType === TagType.CHASE && this.selectedBulkUpdateActions === BulkAction.TagChases) ||
      (this.tagType === TagType.MASTERDOCUMENTSOURCE && this.selectedBulkUpdateActions === AddressBulkUpdateActionType.TagAddresses) ||
      (this.tagType === TagType.DOCUMENT && this.selectedBulkUpdateActions === BulkAction.TagChases);
  }

  get isChangeManageActionTypeSelected(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Chases) && (this.selectedBulkUpdateActions === BulkAction.TagChases);
  }

  get isDocumentManageActionTypeSelected(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Documents) && (this.selectedBulkUpdateActions === BulkAction.TagChases);
  }

  get hasAddressManageActionTypeSelected(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Addresses) && (this.selectedBulkUpdateActions === AddressBulkUpdateActionType.TagAddresses);
  }

  get isDueDateSelected(): boolean {
    return this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === 8;
  }
  get isRequestedDateSelected(): boolean {
    return this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === 7;
  }
  get isCommitmentDateSelected(): boolean {
    return this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === 9;
  }
  get isShowDocumentHoldControls(): boolean {
    return this.selectedBulkUpdateType === BulkType.Documents;
  }

  get isHoldActionTypeSelected(): boolean {
    return this.isShowDocumentHoldControls && this.selectedBulkUpdateActions === DocumentBulkUpdateActionType.HOLD;
  }

  get isAssignActionTypeSelected(): boolean {
    return this.isShowDocumentHoldControls && this.selectedBulkUpdateActions === DocumentBulkUpdateActionType.ASSIGN;
  }

  get isRemoveActionTypeSelected(): boolean {
    return this.isShowDocumentHoldControls && this.selectedBulkUpdateActions === DocumentBulkUpdateActionType.REMOVE;
  }

  get selectedHoldStatusValue(): number {
    return this.formGroup.get(this.holdStatusInput.key).value;
  }

  get selectedHoldStatusText(): string {
    return this.holdStatusInput.options.find(a => a.value === this.selectedHoldStatusValue).text;
  }

  get selectAssignedUserId(): number {
    return this.isAssignActionTypeSelected ? this.formGroup.get("assignToUsersInput").value.value : null;
  }

  get isOriginalChaseId(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Chases) && (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase);
  }

  get isOverChartToChase(): boolean {
    return (this.selectedBulkUpdateType === BulkType.Chases) && (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase);
  }

  get documentStatus(): number {
    let selctedDocumentStatusId = 0;
    if (this.isHoldActionTypeSelected) {
      selctedDocumentStatusId = this.selectedHoldStatusValue;
    } else if (this.isRemoveActionTypeSelected) {
      selctedDocumentStatusId = 102;
    }
    return selctedDocumentStatusId;
  }

  get customFileUploadClass(): string {
    const fileUploadClass = "ui-g-6 ui-md-6 ui-lg-6 custom_uploadsection";
    return StringHelper.isAvailable(this.bulkUpdateIds) ? `${fileUploadClass}` : `${fileUploadClass} custom_upload`;
  }

  refreshFlag = false;
  minimumDate: Date;
  selectedBulkUpdateActions: number;
  formValues: string[] = [];
  tagFormValues: any[] = [];
  selectedBulkUpdateType: number;
  selectedChaseIds: Textarea;
  originalChaseIds: Textarea;
  copyOverChaseIds: Textarea;
  isShowChaseIDs = false;
  isShowEditBulk = false;
  isShowControls = false;
  formData: FormData = new FormData();
  formGroup: FormGroup;
  selectedFileList: any[] = [];
  checkError = false;
  bulkUpdateTypeItem = "bulkUpdateType";
  bulkUpdateActionItem = "bulkUpdateActions";
  chaseIds = "chaseIds";
  documentIds = "documentIds";
  projectId = "projectId";
  isShowContinueButton = true;
  isShowCopyChartChaseInputs = false;
  ids = "Chase IDs";
  selectedClientOrg: number;
  clientOrgsId = "clientId";
  chaseFormatTypeInput: Dropdown;
  chaseFormatTypeSelected = "";
  isFormatTypeChasesKey = false;
  isShowChaseFormatType = false;
  originalChaseId = "originalChaseId";
  copyOverChaseId = "copyOverChaseId";
  showChasesId = true;
  showChaseskey = false;
  showOriginalChasesId = false;
  showCopyOverChasesId = false;
  bulkUpdateChaseKey = "";
  showClientBasedOnChaseFormatType = true;
  bulkInvoicePendsMessage: string;
  readonly ADDRESS_IDS_FORM_KEY = "ADDRESS_IDS";
  readonly PROJECT_ID_FORM_KEY = "PROJECT_ID";
  bulkUpdateTypesInput: Dropdown;
  bulkUpdateActions: Dropdown;
  actionResult: SelectableInput[] = [];
  projectInput: Dropdown;
  projectList: any;
  addressbulkUpdateData: AddressBulkUpdate;
  changeRetrievalTypeInput: Dropdown;
  assignThirdPartyVendorInput: Dropdown;
  applyDueDateInput: Calendar;
  applyRequestedDateInput: Calendar;
  applyCommitmentDateInput: Calendar;
  selectedRequestedDate = "selectedRequestedDate";
  selectedDueDate = "selectedDueDate";
  selectedCommitmentDate = "selectCommitmentDate";
  clienOrgsInput: Dropdown;
  isClinical: boolean;
  clinicalChases: ChaseItem[] = [];
  clinicalType: string;
  hasClinicalPends = false;
  tagActionInput: Dropdown;
  isTagBulkActionSelected = false;
  tagType: TagType;
  tagActionType = "";
  isShowTags = false;
  @ViewChild(BulkUpdateManageTagComponent) bulkUpdateManageTagComponent: BulkUpdateManageTagComponent;
  isAddTagValid = false;
  hasAddTagEnable = false;
  showAddTagValidationError = false;
  hasValidationError = false;
  holdStatusInput: Dropdown;
  view = 0; // 0 = Default for Hold Status
  assignToUsersInput: Autocomplete;
  private userId: number;
  assignToUserList: SelectableInput[] = [];
  documentBulkUpdateData: DocumentBulkUpdate;
  hasClinicalNonClinicalChases = false;
  specialHandlingReasonInput: Dropdown;
  isMoveChase = false;
  isDocumentGrid = false;
  isAssignAddressSelected = false;
  organizationAttributeData = null;
  isChaseAssignUser = false;
  isAddressAssignUser = false;

  handleRefresh(event: boolean): void {}

  ngOnInit() {
    this.userId = this.userService.getUserToken().userId;
    this.initializeBulkUpdateForm();
    this.getBulkUpdateTypes();
    this.getTagAction();
    this.getClientOrgs();
    this.getProjects();
    this.getVendors();
    this.getChangeRetrievalTypes();
    this.getHoldStatus();
    this.getAssignToUserList();
    this.selectedBulkUpdateActions = 1;
    this.getBulkUpdateActions(this.selectedBulkUpdateActions);
    this.getChaseFormatTypes();
    this.getSpecialHandlingReasons();
  }

  initializeBulkUpdateForm(): void {
    this.selectedChaseIds = new Textarea({
      key: "selectedChaseIds",
      label: "",
      placeholder: "Type IDs separated by commas...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [Validators.required],
    });
    this.originalChaseIds = new Textarea({
      key: "originalChaseIds",
      label: "",
      placeholder: "Type Original IDs separated by commas...",
      rows: 6,
      resize: Resize.VERTICAL,
    });
    this.copyOverChaseIds = new Textarea({
      key: "copyOverChaseIds",
      label: "",
      placeholder: "Type Copy Over IDs separated by commas...",
      rows: 6,
      resize: Resize.VERTICAL,
    });
    this.bulkUpdateTypesInput = new Dropdown({
      key: "bulkUpdateTypesInput",
      label: "Select a Type of Bulk Update",
      placeholder: "Select Bulk Type",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Bulk update type required",
      },
    });
    this.bulkUpdateActions = new Dropdown({
      key: "bulkUpdateActions",
      label: "Choose a Bulk Action",
      placeholder: "Select Bulk Action",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Bulk Action required",
      },
    });
    this.projectInput = new Dropdown({
      key: "projectInput",
      label: "Project",
      placeholder: "Select Project",
    });
    this.changeRetrievalTypeInput = new Dropdown({
      key: "changeRetrievalTypeInput",
      label: "Change Retrieval Type To",
      placeholder: "Select Retrieval Type",
    });
    this.assignThirdPartyVendorInput = new Dropdown({
      key: "assignThirdPartyVendorInput",
      label: "Assign Third Party Vendor",
      placeholder: "Select Vendor",
    });
    this.tagActionInput = new Dropdown({
      key: "tagActionInput",
      label: "Choose Tag Action",
      placeholder: "Select Tag Action Type",
    });
    this.holdStatusInput = new Dropdown({
      key: "holdStatusInput",
      label: "Hold Status",
      placeholder: "Select Hold Status",
    });
    this.assignToUsersInput = new Autocomplete({
      key: "assignToUsersInput",
      label: "User",
      filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    });
    this.clienOrgsInput = new Dropdown({
      key: "clientOrgSelected",
      label: "Select a Client",
      placeholder: "Select Client ",
    });
    this.chaseFormatTypeInput = new Dropdown({
      key: "chaseFormatTypeInput",
      label: "Select Format Type",
      placeholder: "Select Format ",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Format Type required",
      },
    });
    this.applyDueDateInput = new Calendar({
      key: "applyDueDateInput",
      label: "Select Due Date",
      placeholder: "Due Date",

    });
    this.applyRequestedDateInput = new Calendar({
      key: "applyRequestedDateInput",
      label: "Select Request Date",
      placeholder: "Request Date",

    });
    this.applyCommitmentDateInput = new Calendar({
      key: "applyCommitmentDateInput",
      label: "Select Commitment Date",
      placeholder: "Commitment Date",
      minDate: new Date(),
    });
    this.specialHandlingReasonInput = new Dropdown({
      key: "specialHandlingReasonInput",
      label: "Special Handling Reason",
      placeholder: "Select Special Handling Reason",
    });

    this.formGroup = this.formService.createFormGroup([
      this.selectedChaseIds,
      this.originalChaseIds,
      this.copyOverChaseIds,
      this.bulkUpdateTypesInput,
      this.projectInput,
      this.changeRetrievalTypeInput,
      this.assignThirdPartyVendorInput,
      this.tagActionInput,
      this.holdStatusInput,
      this.assignToUsersInput,
      this.bulkUpdateActions,
      this.clienOrgsInput,
      this.chaseFormatTypeInput,
      this.applyDueDateInput,
      this.applyRequestedDateInput,
      this.specialHandlingReasonInput,
      this.applyCommitmentDateInput,
    ]);
  }

  getBulkUpdateActions(bulkUpdateType): void {

    switch (bulkUpdateType) {
      case BulkType.Chases:
        this.tagType = TagType.CHASE;
        this.actionResult = [
          new SelectableInput({ text: "Move Chases", value: 1 }),
          new SelectableInput({ text: "Reassign Chases", value: 2 }),
          new SelectableInput({ text: "Activate Chases", value: 3 }),
          new SelectableInput({ text: "Pend Chases", value: 4 }),
          new SelectableInput({ text: "Tag Chases", value: 6 }),
          new SelectableInput({ text: "Apply Request Date to 3rd Party Chases", value: 7 }),
          new SelectableInput({ text: "Apply Due Date to 3rd Party Chases", value: 8 }),
          new SelectableInput({ text: "Update Commitment Dates", value: 9 }),
          new SelectableInput({ text: "Copy Charts to Chases", value: 10 }),
        ];
        this.bulkUpdateActions = new Dropdown({ ...this.bulkUpdateActions, options: this.actionResult } as any);
        this.changeDetector.markForCheck();
        break;
      case BulkType.Pends:
        const serviceOrgName = this.authService.user.organizationName;
        const changeOwnerToClient = `Change Owner: ${serviceOrgName} to Client`;
        const changeOwnerToSO = `Change Owner: Client to  ${serviceOrgName}`;
        this.actionResult = [
          new SelectableInput({ text: "Resolve", value: 1 }),
          new SelectableInput({ text: "Close", value: 2 }),
          new SelectableInput({ text: changeOwnerToClient, value: 3 }),
          new SelectableInput({ text: changeOwnerToSO, value: 4 }),
        ];
        this.bulkUpdateActions = new Dropdown({ ...this.bulkUpdateActions, options: this.actionResult } as any);
        this.changeDetector.markForCheck();
        break;
      case BulkType.Addresses:
        this.tagType = TagType.MASTERDOCUMENTSOURCE;
        this.actionResult = [
          new SelectableInput({ text: "Mark as Contacted", value: 1 }),
          new SelectableInput({ text: "Assign addresses", value: 2 }),
          new SelectableInput({ text: "Change Retrieval Type", value: 3 }),
          new SelectableInput({ text: "Tag Addresses", value: 6 }),
          new SelectableInput({ text: "Enable Special Handling", value: 7 }),
          new SelectableInput({ text: "Disable Special Handling", value: 8 }),
          new SelectableInput({ text: "Mark as Validated", value: 12 }),

        ];
        this.bulkUpdateActions = new Dropdown({ ...this.bulkUpdateActions, options: this.actionResult } as any);
        this.changeDetector.markForCheck();
        break;
      case BulkType.Documents:
        this.tagType = TagType.DOCUMENT;
        this.actionResult = [
          new SelectableInput({ text: "Hold", value: DocumentBulkUpdateActionType.HOLD }),
          new SelectableInput({ text: "Assign", value: DocumentBulkUpdateActionType.ASSIGN }),
          new SelectableInput({ text: "Remove", value: DocumentBulkUpdateActionType.REMOVE }),
          new SelectableInput({ text: "Tag Documents", value: BulkAction.TagChases }),
        ];
        this.bulkUpdateActions = new Dropdown({ ...this.bulkUpdateActions, options: this.actionResult } as any);
        this.changeDetector.markForCheck();
        break;
      default:
        break;
    }

  }

  getBulkUpdateTypes(): void {
    const result = [
      new SelectableInput({ text: "Chases", value: 1 }),
      new SelectableInput({ text: "Pend", value: 2 }),
      new SelectableInput({ text: "Address", value: 3 }),
      new SelectableInput({ text: "Documents", value: 4 }),
    ];
    this.bulkUpdateTypesInput = new Dropdown({ ...this.bulkUpdateTypesInput, options: result } as any);
    this.changeDetector.markForCheck();
  }

  getChaseFormatTypes(): void {
    const result = [
      new SelectableInput({ text: "Chase Id", value: "chaseid" }),
      new SelectableInput({ text: "Client Chase Key", value: "chaseskey" }),
    ];
    this.chaseFormatTypeInput = new Dropdown({ ...this.chaseFormatTypeInput, options: result } as any);
    this.changeDetector.markForCheck();
  }
  validateContinue(): void {
    this.hasValidationError = false;
    if (this.isShowControls && this.isShowEditBulk) {
      this.isShowControls = false;
      this.isShowEditBulk = false;
    } else {
      this.isShowEditBulk = false;
      this.checkError = false;
      if (this.formGroup.invalid) {
        this.checkError = true;
        if (this.selectedBulkUpdateActions !== BulkAction.CopyChartToChase) {
          this.isShowChaseIDs = this.formGroup.controls.selectedChaseIds.invalid;
        }

        this.formService.markAllAsTouched(this.formGroup);
        this.changeDetector.markForCheck();
        return;
      }
      this.setProjectValidation();
      this.validateProjectIdAndClientId();
      if (this.isMarkAsContactedActionTypeSelected && !this.formGroup.controls.projectInput.touched) {
        this.formService.markAllAsTouched(this.formGroup);
        this.changeDetector.markForCheck();
        return;
      }
      this.validateThirdPartyAndRetrival();

      this.setManageTypeValidation();
      if ((this.isChangeManageActionTypeSelected || this.hasAddressManageActionTypeSelected) && !this.formGroup.controls.tagActionInput.touched) {
        this.formService.markAllAsTouched(this.formGroup);
        this.changeDetector.markForCheck();
        return;
      }
      this.validateProjectIdAndClientId();
      if ((this.isChangeManageActionTypeSelected || this.hasAddressManageActionTypeSelected || this.isDocumentManageActionTypeSelected) && (this.tagActionType === "Remove" || this.tagActionType === "Replace")) {
        this.bulkUpdateManageTagComponent.setTagFormGroupValidation();
        this.formService.markAllAsTouched(this.bulkUpdateManageTagComponent.tagFormGroup);
        this.changeDetector.markForCheck();
        const removeTagValue = this.bulkUpdateManageTagComponent.tagFormGroup.get(this.bulkUpdateManageTagComponent.removeTagInput.key).value;
        const replaceTagValue = this.bulkUpdateManageTagComponent.tagFormGroup.get(this.bulkUpdateManageTagComponent.replaceTagInput.key).value;
        const currentTagValue = this.bulkUpdateManageTagComponent.tagFormGroup.get(this.bulkUpdateManageTagComponent.tagInput.key).value;
        this.checkManageTagBulkActionForValidation(removeTagValue, replaceTagValue, currentTagValue);
      }
      if ((this.isChangeManageActionTypeSelected || this.hasAddressManageActionTypeSelected || this.isDocumentManageActionTypeSelected) && this.tagActionType === "Add") {
        this.hasAddTagEnable = true;
        this.bulkUpdateManageTagComponent.setAddTagFormValidation(this.hasAddTagEnable);
        this.checkAddTagValidationError();
      }
      this.ValidateThirdPartyAIDs();
      this.SelectDueOrRequestedDate();
      this.checkDocumentBulkActionValidation();
      this.validateSpecialHandlingReason();
      this.checkCopyChartToChaseValidation();
      this.selectCommitmentDate();
      this.showHideControlsOnValidation();
      this.refreshFlag = !this.refreshFlag;

    }
  }

  ValidateThirdPartyAIDs() {
    if (this.isDueDateSelected) {
      this.applyDueDateInput = new Calendar({
        ...this.applyDueDateInput,
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Due Date is Required",
        },
      } as any);
      this.setValidators([this.applyDueDateInput]);
    } else {
      this.clearValidators([this.applyDueDateInput.key]);
    }
    if (this.isRequestedDateSelected) {
      this.applyRequestedDateInput = new Calendar({
        ...this.applyRequestedDateInput,
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Requested Date is Required",
        },
      } as any);
      this.setValidators([this.applyRequestedDateInput]);
    } else {
      this.clearValidators([this.applyRequestedDateInput.key]);
    }
    if (this.isCommitmentDateSelected) {
      this.applyCommitmentDateInput = new Calendar({
        ...this.applyCommitmentDateInput,
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Commitment Date is Required",
        },
      } as any);
      this.setValidators([this.applyCommitmentDateInput]);
    } else {
      this.clearValidators([this.applyCommitmentDateInput.key]);
    }

  }
  validateProjectIdAndClientId(): void {
    if (this.isFormatTypeChasesKey) {
      this.formValues[this.projectId] = this.selectedProjectValue;
    }
    if (this.showChasesId || this.showOriginalChasesId) {
      this.checkClientValidation();
    }
  }
  validateThirdPartyAndRetrival() {
    if (this.selectedBulkUpdateType === 3) {
      this.setThirdPartyValidation();
      if (this.isThirdPartyRetrievalTypeSelected && !this.formGroup.controls.assignThirdPartyVendorInput.touched) {
        this.formService.markAllAsTouched(this.formGroup);
        this.changeDetector.markForCheck();
        return;
      }
      this.setChangeRetrievalTypeValidation();
      if (this.isChangeRetrievalActionTypeSelected && !this.formGroup.controls.changeRetrievalTypeInput.touched) {
        this.formService.markAllAsTouched(this.formGroup);
        this.changeDetector.markForCheck();
        return;
      }
    }
  }
  onSelectType(event) {
    this.clearFormGroupValidators();
    this.selectedBulkUpdateActions = 1;
    this.tagType = null;
    this.bulkInvoicePendsMessage = "";
    this.selectedBulkUpdateType = event.value;
    this.getBulkUpdateActions(event.value);
    this.isShowContinueButton = true;
    this.isShowControls = false;
    this.hasClinicalPends = false;
    this.isShowCopyChartChaseInputs = false;
    this.formGroup.controls.bulkUpdateActions.setValue(this.selectedBulkUpdateActions);
    this.showAndHideFormatType(this.selectedBulkUpdateType);
    this.formGroup.controls.chaseFormatTypeInput.setValue("chaseid");
    this.onFormatTypeChange("chaseid");
    if (this.selectedBulkUpdateType === BulkType.Chases) {
      this.formValues[this.bulkUpdateActionItem] = this.selectedBulkUpdateActions;
    }
    this.showChildControls();
  }

  onSelectChaseIds(event) {
    if (event) {
      this.isShowChaseIDs = false;
      this.selectedChaseIds.value = event;
      this.hasClinicalPends = false;
    } else {
      this.isShowChaseIDs = true;
    }
  }

  onOriginalChaseIds(event) {
    if (event) {
      this.originalChaseIds.value = event;
      this.hasClinicalPends = false;
    }
  }

  onCopyOverChaseIds(event) {
    if (event) {
      this.copyOverChaseIds.value = event;
      this.hasClinicalPends = false;
    }
  }

  onBulkActionChange(event) {
    this.clearFormGroupValidators();
    this.isShowContinueButton = true;
    this.isShowControls = false;
    this.hasClinicalPends = false;
    this.selectedBulkUpdateActions = event.value;
    this.formValues[this.bulkUpdateActionItem] = this.selectedBulkUpdateActions;
    if (this.isBulkPendUpdate && !this.hasManageTagControl) {
      this.bulkInvoicePendsMessage = "Please make sure pend codes are invoice specific.";
    }
    if (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase) {
      this.isFormatTypeChasesKey = false;
      this.isShowCopyChartChaseInputs = true;
      this.showClientBasedOnChaseFormatType = true;
      this.formGroup.controls.chaseFormatTypeInput.setValue("chaseid");
      this.showChasesId = false;
      this.showChaseskey = false;
      this.showOriginalChasesId = true;
      this.formValues[this.clientOrgsId] = null;
      this.clearValidators([this.selectedChaseIds.key]);
      this.clearValidators([this.projectInput.key]);
    } else {
      this.isShowCopyChartChaseInputs = false;
      if (this.chaseFormatTypeSelected === "chaseskey") {
        this.showChasesId = false;
        this.showChaseskey = true;
      } else {
        this.showChasesId = true;
        this.showChaseskey = false;
      }
      this.showOriginalChasesId = false;
      this.clearValidators([this.originalChaseIds.key]);
      this.clearValidators([this.copyOverChaseIds.key]);
    }
    this.showChildControls();
  }

  private showChildControls() {
    if (this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === BulkAction.MoveChases) {
      this.isMoveChase = true;
      this.isChaseAssignUser = false;
      this.isShowControls = true;
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
    } else if (this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === BulkAction.ReassignChases) {
      this.isMoveChase = false;
      this.isChaseAssignUser = true;
    } else if (this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === BulkAction.ActivateChases) {
      this.isMoveChase = false;
      this.isChaseAssignUser = false;
      this.isShowControls = true;
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
    } else if (this.selectedBulkUpdateType === BulkType.Chases && this.selectedBulkUpdateActions === BulkAction.TagChases) {
      this.isShowControls = true;
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
    } else if (this.selectedBulkUpdateType === BulkType.Chases
      && (this.selectedBulkUpdateActions === BulkAction.CommitmentDateUpdateChases || BulkAction.RequestDate3rdPartyChases || BulkAction.DueDate3rdPartyChases || BulkAction.CopyChartToChase)) {
      this.isMoveChase = false;
      this.isChaseAssignUser = false;
    } else if (this.selectedBulkUpdateType === BulkType.Pends) {
      this.isShowControls = true;
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
    } else if (this.selectedBulkUpdateType === BulkType.Documents) {
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
      this.isShowControls = true;
      this.isDocumentGrid = false;
    } else if (this.selectedBulkUpdateType === BulkType.Addresses && this.selectedBulkUpdateActions !== AddressBulkUpdateActionType.AssignAddresses) {
      this.isShowControls = true;
      this.isShowContinueButton = false;
      this.isShowEditBulk = false;
      this.isAddressAssignUser = false;
    } else if (this.selectedBulkUpdateType === BulkType.Addresses && this.selectedBulkUpdateActions === AddressBulkUpdateActionType.AssignAddresses) {
      this.isAddressAssignUser = true;
    } else {
      this.isShowControls = false;
    }
  }

  onFormatTypeChange(event): void {
    this.chaseFormatTypeSelected = event;
    if (this.chaseFormatTypeSelected === "chaseskey") {
      if (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase) {
        this.isShowCopyChartChaseInputs = false;
        this.selectedBulkUpdateActions = 1;
        this.formGroup.controls.bulkUpdateActions.setValue(this.selectedBulkUpdateActions);
      }
      this.showChaseskey = true;
      this.showChasesId = false;
      this.showOriginalChasesId = false;
      this.showCopyOverChasesId = false;
      this.isFormatTypeChasesKey = true;
      this.showClientBasedOnChaseFormatType = false;
      this.bulkUpdateChaseKey = "Chases Key";
      this.formValues[this.clientOrgsId] = null;
      this.clearFormGroupValidators();
      this.formGroup.controls.projectInput.setValue("");
      this.clearValidators([this.clienOrgsInput.key]);
    } else {
      this.showChaseskey = false;
      this.showChasesId = true;
      this.showOriginalChasesId = true;
      this.showCopyOverChasesId = true;
      this.isFormatTypeChasesKey = false;
      this.showClientBasedOnChaseFormatType = true;
      this.bulkUpdateChaseKey = "";
      this.formValues[this.projectId] = null;
      this.clearFormGroupValidators();
      this.clearValidators([this.projectInput.key]);
    }
  }
  showAndHideFormatType(selectedBulkUpdateType: number): void {
    this.isShowChaseFormatType = selectedBulkUpdateType === 1 ? true : false;
  }


  uploadFile(event: IOnUpload): void {
    this.service.upload(event.formData).subscribe(
      data => {
        const addressIds = data[0].split(",");
        const uniqueAddressIds = [...new Set(addressIds)];
        this.formGroup.controls.selectedChaseIds.setValue(uniqueAddressIds.join(",").replace(/['"]+/g, ""));
        if (!this.checkFileUploadPattern) {
          return;
        } else {
          this.isShowChaseIDs = false;
          this.changeDetector.markForCheck();
          this.showUploadSuccessMessage();

          this.formData = new FormData();
          this.selectedFileList = [];
        }
        event.success();
      },
      err => {
        this.messagingService.showToast("Error while uploading File, please try again.", SeverityType.ERROR);
      }
    );
  }

  uploadCopyChartChaseFile(event: IOnUpload): void {
    this.service.copyChartToChaseUpload(event.formData).subscribe(
      data => {
        const originalChaseId = data[0].replace(/['"]+/g, "");
        const copyOverChartChaseIds = data[1].replace(/['"]+/g, "");
        const isValid = this.validateCountAndExceedLimit(originalChaseId, copyOverChartChaseIds, this.isBulkCopyChasesSelected());
        if (!isValid) {
          this.formGroup.controls.originalChaseIds.setValue(originalChaseId);
          this.formGroup.controls.copyOverChaseIds.setValue(copyOverChartChaseIds);
          if (!this.checkFileUploadPattern) {
            return;
          } else {
            this.isShowChaseIDs = false;
            this.changeDetector.markForCheck();
            this.showUploadSuccessMessage();

            this.formData = new FormData();
            this.selectedFileList = [];
          }
        }
        event.success();
      },
      err => {
        this.messagingService.showToast("Error while uploading File, please try again.", SeverityType.ERROR);
      }
    );
  }

  validateCountAndExceedLimit(originalChaseId, copyOverChartChaseIds, isBulkChartCopy = false): boolean {
    const limit = isBulkChartCopy ? this.uploadChaseLimitForCopyCharts : this.uploadChaseLimitForBulkUpdate;
    const originalChaseIds = originalChaseId.split(",");
    const copyChartChaseIds = copyOverChartChaseIds.split(",");
    const isEmptyElementOriginalChase = originalChaseIds.includes("");
    const isEmptyElementCopyOverChase = copyChartChaseIds.includes("");
    if (isEmptyElementOriginalChase || isEmptyElementCopyOverChase || originalChaseIds.length !== copyChartChaseIds.length) {
      this.messagingService.showToast("Incorrect chase counts entered.", SeverityType.ERROR);
      return true;
    } else if (originalChaseIds.length > limit || copyChartChaseIds.length > limit) {
      this.messagingService.showToast(`Chase count exceeds bulk change limit (${limit}), please reduce chase count to copy over.`, SeverityType.ERROR);
      return true;
    }
    return false;
  }

  editBulkUpdate() {
    this.isShowControls = true;
  }

  onShowButton(isShowContinueButton: boolean): void {
    this.isShowContinueButton = isShowContinueButton;
  }

  onShowControls(isShowControls: boolean): void {
    this.isShowControls = isShowControls;
  }

  onShowEditButton(isShowEditButton: boolean): void {
    this.isShowEditBulk = isShowEditButton;
  }

  getClientOrgs(): void {
    this.userService
      .getClients()
      .pipe(
        map(this.automapper.curryMany("ClientOrgModel", "SelectableInput"))
      )
      .subscribe(options => {
        this.clienOrgsInput = new Dropdown({ ...this.clienOrgsInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  onSelectClientOrg(event) {
    this.selectedClientOrg = event;
  }

  onChange() {
    if ((this.selectedBulkUpdateType !== BulkType.Documents
      && this.selectedBulkUpdateType !== BulkType.Chases && this.selectedBulkUpdateType !== BulkType.Pends)
      && (this.selectedBulkUpdateType === BulkType.Addresses && this.selectedBulkUpdateActions === AddressBulkUpdateActionType.AssignAddresses)
    ) {
      this.isShowControls = false;
      this.isShowContinueButton = true;
      this.hasClinicalPends = false;
    }
  }

  private showUploadSuccessMessage() {
    this.messagingService.showToast(`${this.bulkUpdateIds} Populated Successfully.`, SeverityType.SUCCESS);
  }

  private showUploadErrorMessage() {
    this.messagingService.showToast(`Invalid ${this.bulkUpdateIds}`, SeverityType.ERROR);
    this.isShowControls = false;
  }

  private getProjects(): void {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        this.projectList = result;
        this.projectInput = new Dropdown({ ...this.projectInput, options: result } as any);
        this.changeDetector.markForCheck();
      });
  }

  private parseSpaceSeparatedToCommaSeparated(value: string): string {
    const withoutLinesBreaks = value.replace(/(\r\n|\n|\r)/gm, ",");
    const withoutSpace = withoutLinesBreaks.replace(/\s+/g, "").trim();
    const withoutLastComma = withoutSpace.replace(/,\s*$/, "");

    const csvValues = withoutLastComma.toString().split(",")
      .filter(c => StringHelper.isAvailable(c) && NumberHelper.isGreaterThan(Number(c), 0))
      .toString();

    return csvValues;

  }
  private convertToCvs(value: string): string {
    const withoutLinesBreaks = value.replace(/(\r\n|\n|\r)/gm, ",");
    const withoutSpace = withoutLinesBreaks.replace(/\s+/g, "").trim();
    const withoutLastComma = withoutSpace.replace(/,\s*$/, "");

    const csvValues = withoutLastComma.toString().split(",")
      .filter(c => StringHelper.isAvailable(c))
      .toString();

    return csvValues;

  }

  private setValidators(controls: DynamicInput[]): void {
    controls.forEach(control => {
      this.formGroup.get(control.key).setValidators(control.validators);
    });
  }

  private clearValidators(path: string[]): void {
    path.forEach(control => {
      this.formGroup.get(control).clearValidators();
      this.formGroup.get(control).updateValueAndValidity();
    });
  }

  setProjectValidation(): void {
    if (this.isMarkAsContactedActionTypeSelected) {
      this.projectInput = new Dropdown({
        ...this.projectInput,
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Project required",
        },
      } as any);
      this.setValidators([this.projectInput]);
    } else {
      this.clearValidators([this.projectInput.key]);
    }
    if (this.isFormatTypeChasesKey) {
      this.projectInput = new Dropdown({
        ...this.projectInput,
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Project required",
        },
      } as any);
      this.setValidators([this.projectInput]);

    } else {
      this.clearValidators([this.projectInput.key]);
    }
  }

  private getChangeRetrievalTypes(): void {
    this.retrievalPageService
      .getDocumentSourceTypes()
      .subscribe(result => {
        this.changeRetrievalTypeInput = new Dropdown({ ...this.changeRetrievalTypeInput, options: result } as any);
        this.formService.updateDom.next();
      });
  }

  private getVendors(): void {
    const invoiceType = `${InvoiceType.NET30},${InvoiceType.COD}`;
    this.addressDetailInfoAssignmentService
      .getVendors(invoiceType)
      .pipe(map(this.automapper.curryMany("Vendors", "SelectableInput")))
      .subscribe(options => {
        this.assignThirdPartyVendorInput = new Dropdown({ ...this.assignThirdPartyVendorInput, options } as any);
      });
  }

  setThirdPartyValidation() {
    if (this.isThirdPartyRetrievalTypeSelected) {
      this.assignThirdPartyVendorInput = new Dropdown({
        ...this.assignThirdPartyVendorInput,
        validators: [Validators.required],
        errorMessages: { required: "Vendor required" },
      } as any);
      this.setValidators([this.assignThirdPartyVendorInput]);
    } else {
      this.clearValidators([this.assignThirdPartyVendorInput.key]);
    }
  }

  setChangeRetrievalTypeValidation() {
    if (this.isChangeRetrievalActionTypeSelected) {
      this.changeRetrievalTypeInput = new Dropdown({
        ...this.changeRetrievalTypeInput,
        validators: [Validators.required],
        errorMessages: { required: "Retrieval type required" },
      } as any);
      this.setValidators([this.changeRetrievalTypeInput]);
    } else {
      this.clearValidators([this.changeRetrievalTypeInput.key]);
    }
  }

  private setPendChasesValidate(): void {
    const chaseIds = this.selectedIdsValue.split(",");
    const projecctId = this.formValues[this.projectId];
    if (ArrayHelper.isAvailable(chaseIds)) {
      this.service
        .validateChasesIsClinicalOrNonClinical(chaseIds, projecctId)
        .subscribe(result => {
          if (ArrayHelper.isAvailable(result)) {
            this.clinicalType = result[0].workflowStatusName;
            this.isClinical = result[0].isClinical;
            this.hasClinicalNonClinicalChases = result[0].isClinicalAndNonClinicalChases;
            this.changeDetector.markForCheck();
          }
        },         err => {
          this.isShowControls = false;
          this.isShowContinueButton = true;
          this.changeDetector.markForCheck();
        }
        );
    }
  }

  private setChasePendIdsValidate(): void {
    const chaseIds = this.formValues[this.chaseIds];
    if (StringHelper.isAvailable(chaseIds)) {
      this.service
        .validateChasePendIsClinicalOrNonClinical(chaseIds)
        .subscribe(result => {
          if (!result) {
            this.isShowControls = true;
            this.changeDetector.markForCheck();
          }
        },         err => {
          this.isShowControls = false;
          this.isShowContinueButton = true;
          this.hasClinicalPends = true;
          this.changeDetector.markForCheck();
        }
        );
    }
  }

  getTagAction(): void {
    const result = [
      new SelectableInput({ text: "Add Tags", value: 1 }),
      new SelectableInput({ text: "Remove Tags", value: 2 }),
      new SelectableInput({ text: "Replace Tags", value: 3 }),
    ];
    this.tagActionInput = new Dropdown({ ...this.tagActionInput, options: result } as any);
    this.changeDetector.markForCheck();
  }

  onManageTagSelection(event: any): void {
    this.isTagBulkActionSelected = true;
    this.showAddTagValidationError = false;
    if (this.bulkUpdateManageTagComponent) {
      this.bulkUpdateManageTagComponent.pTagText = "";
      this.bulkUpdateManageTagComponent.tagFormGroup.reset();
    }
    switch (event.value) {
      case 1:
        this.tagActionType = "Add";
        break;
      case 2:
        this.tagActionType = "Remove";
        break;
      case 3:
        this.tagActionType = "Replace";
        break;

      default:
        break;
    }
  }

  onSelectedTagData(selectedTag): void {
    this.tagFormValues = selectedTag;
  }

  private checkTagChasesValid(): void {
    if (this.formGroup.invalid) {
      this.isShowControls = false;
      this.isShowContinueButton = true;
    }
  }

  setManageTypeValidation() {
    if (this.isChangeManageActionTypeSelected || this.hasAddressManageActionTypeSelected || this.isDocumentManageActionTypeSelected) {
      this.tagActionInput = new Dropdown({
        ...this.tagActionInput,
        validators: [Validators.required],
        errorMessages: { required: "Tag Action Type is required" },
      } as any);
      this.setValidators([this.tagActionInput]);
    } else {
      this.clearValidators([this.tagActionInput.key]);
    }
  }

  addTagValidation(isValid): void {
    this.isAddTagValid = isValid;
  }

  private checkBulkActionType(): void {
    switch (this.selectedBulkUpdateType) {
      case BulkType.Chases:
        this.formValues[this.bulkUpdateTypeItem] = this.selectedBulkUpdateType;
        this.formValues[this.bulkUpdateActionItem] = this.selectedBulkUpdateActions;
        this.formValues[this.clientOrgsId] = this.showChaseskey ? null : this.selectedClientOrg;
        if (this.selectedBulkUpdateActions !== BulkAction.CopyChartToChase) {
          if (this.showChasesId) {
            this.formValues[this.chaseIds] = this.parseSpaceSeparatedToCommaSeparated(this.selectedIdsValue);
          }
          if (this.showChaseskey) {
            this.formValues[this.chaseIds] = this.convertToCvs(this.selectedIdsValue);
          }
        }
        if (this.selectedBulkUpdateActions === BulkAction.PendChases) {
          this.isClinical = false;
          this.hasClinicalNonClinicalChases = false;
          if (this.showChasesId) {
            this.formGroup.controls.selectedChaseIds.setValue(this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.selectedChaseIds.value));
          }
          if (this.showChaseskey) {
            this.formGroup.controls.selectedChaseIds.setValue(this.convertToCvs(this.formGroup.controls.selectedChaseIds.value));
          }
          this.setPendChasesValidate();
        }
        break;
      case BulkType.Pends:
        this.formValues[this.bulkUpdateActionItem] = this.selectedBulkUpdateActions;
        this.formValues[this.chaseIds] = this.parseSpaceSeparatedToCommaSeparated(this.selectedIdsValue);
        if (this.formValues[this.bulkUpdateActionItem] === BulkUpdateAction.Close) {
          this.isClinical = false;
          this.setChasePendIdsValidate();
        }
        break;
      case BulkType.Addresses:
        this.setBulkUpdateAddressData();
        break;
      case BulkType.Documents:
        this.documentBulkUpdateData = new DocumentBulkUpdate(
          {
            documentIds: this.parseSpaceSeparatedToCommaSeparated(this.selectedIdsValue).split(","),
            documentStateId: this.documentStatus,
            action: 0,
            documentBulkActionType: this.selectedBulkUpdateActions,
            assignToUserId: this.isAssignActionTypeSelected ? this.selectAssignedUserId : null,
            documentNotes: "",
          });
        this.formValues[this.documentIds] = this.convertToCvs(this.selectedIdsValue);

        break;
      default:
        break;
    }
    if (!this.checkFileUploadPattern) {
      return;
    }
    if (this.selectedBulkUpdateActions === BulkAction.CopyChartToChase) {
      this.setOriginalAndCopyOverFormValue();
    } else {
      if (this.showChasesId) {
        this.formGroup.controls.selectedChaseIds.setValue(this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.selectedChaseIds.value));
      }
      if (this.showChaseskey) {
        this.formGroup.controls.selectedChaseIds.setValue(this.convertToCvs(this.formGroup.controls.selectedChaseIds.value));
      }
    }

    this.isShowContinueButton = false;
    if (this.selectedBulkUpdateActions === BulkAction.TagChases || this.selectedBulkUpdateActions === AddressBulkUpdateActionType.TagAddresses) {
      this.checkTagChasesValid();
    }
    this.changeDetector.markForCheck();
  }

  setOriginalAndCopyOverFormValue(): void {
    if (this.showOriginalChasesId) {
      this.formGroup.controls.originalChaseIds.setValue(this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.originalChaseIds.value));
      this.formValues[this.originalChaseId] = this.formGroup.controls.originalChaseIds.value;
      this.formGroup.controls.copyOverChaseIds.setValue(this.parseSpaceSeparatedToCommaSeparated(this.formGroup.controls.copyOverChaseIds.value));
      this.formValues[this.copyOverChaseId] = this.formGroup.controls.copyOverChaseIds.value;
    }
    if (this.validateCountAndExceedLimit(this.formGroup.controls.originalChaseIds.value, this.formGroup.controls.copyOverChaseIds.value, this.isBulkCopyChasesSelected())) {
      this.isShowControls = false;
      this.isShowContinueButton = true;
      return;
    }
  }

  private checkManageTagBulkActionForValidation(removeTagValue: SelectableInput, replaceTagValue: SelectableInput, currentTagValue: SelectableInput): void {
    if (this.tagActionType === "Remove" && !removeTagValue) {
      this.hasValidationError = true;
      this.isShowContinueButton = true;
      return;
    } else if (this.tagActionType === "Replace" && ((!currentTagValue) || (!replaceTagValue))) {
      this.hasValidationError = true;
      this.isShowContinueButton = true;
      return;
    }
  }

  private checkAddTagValidationError(): void {
    if (this.isAddTagValid) {
      this.showAddTagValidationError = this.isAddTagValid;
      this.hasValidationError = true;
      this.isShowContinueButton = true;
      return;
    }
  }

  private showHideControlsOnValidation(): void {
    if (!this.hasValidationError) {
      this.isShowControls = true;
      this.checkBulkActionType();
    } else {
      this.isShowControls = false;
      this.isShowContinueButton = true;
    }
  }
  SelectDueOrRequestedDate() {
    if (this.isDueDateSelected) {
      const dueDate = DateHelper.format(this.formGroup.value.applyDueDateInput);
      this.formValues[this.selectedDueDate] = dueDate;
    }
    if (this.isRequestedDateSelected) {
      const requestedDate = DateHelper.format(this.formGroup.value.applyRequestedDateInput);
      this.formValues[this.selectedRequestedDate] = requestedDate;
    }
  }
  selectCommitmentDate(): void {

    if (this.isCommitmentDateSelected) {
      const commitmentDate = DateHelper.format(this.formGroup.value.applyCommitmentDateInput);
      this.formValues[this.selectedCommitmentDate] = commitmentDate;
    }
  }

  private getHoldStatus(): void {
    this.documentRequestService
      .getDocumentStatuses(this.view)
      .subscribe(result => {
        this.holdStatusInput = new Dropdown({ ...this.holdStatusInput, options: result } as any);
        this.formService.updateDom.next();
      });
  }

  getAssignToUserList(): void {
    this.documentRequestService
      .getAssignToUserListForDocument()
      .subscribe(assignToUserList => {
        assignToUserList.sort((a, b) => a.text.localeCompare(b.text));
        ArrayHelper.addItemToSelect(assignToUserList, "@Me", this.userId);
        this.assignToUserList = ArrayHelper.addItemToSelect(assignToUserList, "Unassigned", 0);
        this.assignToUsersInput = new Autocomplete({ ...this.assignToUsersInput, options: assignToUserList } as any);
        this.changeDetector.markForCheck();
      });
  }

  setHoldStatusValidation(): void {
    if (this.isHoldActionTypeSelected) {
      this.holdStatusInput = new Dropdown({
        ...this.holdStatusInput,
        validators: [Validators.required],
        errorMessages: { required: "Hold Status required" },
      } as any);
      this.setValidators([this.holdStatusInput]);
    } else {
      this.clearValidators([this.holdStatusInput.key]);
    }
  }
  setClientOrgValidation(): void {
    if (this.isShowClientOrgAndChaseControls) {
      this.clienOrgsInput = new Dropdown({
        ...this.clienOrgsInput,
        validators: [Validators.required],
        errorMessages: { required: "Client required" },
      } as any);
      this.setValidators([this.clienOrgsInput]);
    } else {
      this.clearValidators([this.clienOrgsInput.key]);
    }
  }

  setAssignedToUserValidation(): void {
    if (this.isAssignActionTypeSelected) {
      this.assignToUsersInput = new Autocomplete({
        ...this.assignToUsersInput,
        validators: [Validators.required],
        errorMessages: { required: "Assigned User required" },
      } as any);
      this.setValidators([this.assignToUsersInput]);
    } else {
      this.clearValidators([this.assignToUsersInput.key]);
    }
  }

  private checkDocumentBulkActionValidation(): void {
    this.setHoldStatusValidation();
    if (this.isHoldActionTypeSelected && (!this.formGroup.controls.holdStatusInput.touched
      || !StringHelper.isAvailable(this.formGroup.controls.holdStatusInput.value))) {
      this.checkFormGroupInvalid();
    }

    this.setAssignedToUserValidation();
    if (this.isAssignActionTypeSelected && (!this.formGroup.controls.assignToUsersInput.touched
      || !this.formGroup.controls.assignToUsersInput.value)) {
      this.checkFormGroupInvalid();
    }
  }

  private checkClientValidation(): void {
    this.setClientOrgValidation();
    if (this.isShowClientOrgAndChaseControls && (!this.formGroup.controls.clientOrgSelected.touched || !NumberHelper.isGreaterThan(this.selectedClientOrg, 0))) {
      this.checkFormGroupInvalid();
    }
  }

  private checkFormGroupInvalid(): void {
    this.formService.markAllAsTouched(this.formGroup);
    this.changeDetector.markForCheck();
    this.hasValidationError = true;
    return;
  }

  getError(field: string): string {
    return field === "bulkUpdateIds" ? `${this.bulkUpdateIds} required` : "required";
  }

  private clearFormGroupValidators(): void {
    if (this.formGroup.invalid) {
      this.clearValidators([
        this.changeRetrievalTypeInput.key,
        this.assignThirdPartyVendorInput.key,
        this.tagActionInput.key,
        this.holdStatusInput.key,
        this.assignToUsersInput.key,
        this.projectInput.key,
        this.chaseFormatTypeInput.key,
        this.applyDueDateInput.key,
        this.applyRequestedDateInput.key,
        this.specialHandlingReasonInput.key,
        this.applyCommitmentDateInput.key,
      ]);
    }
  }

  get hasAddressEnableSpecialHandlingActionSelected(): boolean {
    return this.selectedBulkUpdateType === BulkType.Addresses && this.selectedBulkUpdateActions === 7;
  }

  get hasAddressSpecialHandlingActionSelected(): boolean {
    return this.selectedBulkUpdateType === BulkType.Addresses && (this.selectedBulkUpdateActions === 7 || this.selectedBulkUpdateActions === 8);
  }

  get specialHandlingReasonId(): number {
    return this.formGroup.get(this.specialHandlingReasonInput.key).value;
  }

  private getSpecialHandlingReasons(): void {
    this.addressDetailInfoEditService.getSpecialHandlingReasons()
      .subscribe(data => {
        this.specialHandlingReasonInput = new Dropdown({ ...this.specialHandlingReasonInput, options: data });
        this.changeDetector.markForCheck();
      });
  }

  validateSpecialHandlingReason(): void {
    this.setSpecialHandlingReasonValidation();
    if (this.hasAddressEnableSpecialHandlingActionSelected && (!this.formGroup.controls.specialHandlingReasonInput.touched ||
      !NumberHelper.isAvailable(this.formGroup.controls.specialHandlingReasonInput.value))) {
      this.checkFormGroupInvalid();
    }
  }

  setSpecialHandlingReasonValidation(): void {
    if (this.hasAddressEnableSpecialHandlingActionSelected) {
      this.specialHandlingReasonInput = new Dropdown({
        ...this.specialHandlingReasonInput,
        validators: [Validators.required],
        errorMessages: { required: "Special Handling Reason required" },
      } as any);
      this.setValidators([this.specialHandlingReasonInput]);
    } else {
      this.clearValidators([this.specialHandlingReasonInput.key]);
    }
  }

  private setBulkUpdateAddressData(): void {
    this.addressbulkUpdateData = new AddressBulkUpdate(
      {
        addressIds: this.parseSpaceSeparatedToCommaSeparated(this.selectedIdsValue).split(","),
        projectId: this.selectedProjectValue,
        action: 0,
        notes: "",
        addressBulkActionType: this.selectedBulkUpdateActions,
        assignToUserId: null,
        changeRetrievalTypeTo: this.retrievalTypeId,
        vendorId: this.isThirdPartyRetrievalTypeSelected ? this.thirdPartyVendorId : null,
        tagId: this.hasAddressEnableSpecialHandlingActionSelected ? this.specialHandlingReasonId : null,
        tagTypeId: this.hasAddressSpecialHandlingActionSelected ? TagType.MASTERDOCUMENTSOURCESPECIALHANDLING : null,
      });
  }
  checkCopyChartToChaseValidation() {
    this.setCopyChartToChaseValidation();
    if ((this.isOriginalChaseId || this.isOverChartToChase) && (!StringHelper.isAvailable(this.formGroup.controls.originalChaseIds.value)
      || !StringHelper.isAvailable(this.formGroup.controls.originalChaseIds.value))) {
      this.checkFormGroupInvalid();
    } else {
      if ((this.showChasesId || this.showChaseskey) && !StringHelper.isAvailable(this.formGroup.controls.selectedChaseIds.value) && this.selectedBulkUpdateActions !== BulkAction.CopyChartToChase) {
        this.checkFormGroupInvalid();
      }
    }
  }
  setCopyChartToChaseValidation(): void {
    if (this.isOriginalChaseId) {
      this.originalChaseIds = new Textarea({
        ...this.originalChaseIds,
        validators: [Validators.required],
        errorMessages: { required: "Original Chase Id required" },
      } as any);
      this.setValidators([this.originalChaseIds]);
    } else {
      this.clearValidators([this.originalChaseIds.key]);
    }

    if (this.isOverChartToChase) {
      this.copyOverChaseIds = new Textarea({
        ...this.copyOverChaseIds,
        validators: [Validators.required],
        errorMessages: { required: "Copy Over Chase Id required" },
      } as any);
      this.setValidators([this.copyOverChaseIds]);
    } else {
      this.clearValidators([this.copyOverChaseIds.key]);
    }
  }

  downloadTemplate() {
    const templateType = "bulkuploadcopycharttemplate";
    this.projectFileService.downloadFile2(null, null, templateType, null);
  }

  private isBulkCopyChasesSelected(): boolean {
    return this.selectedBulkUpdateActions === BulkAction.CopyChartToChase;
  }
}
