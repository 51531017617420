export class DocumentListItem {
  readonly chaseDocumentId: number;
  readonly chaseId: number;
  readonly documentQueueId: number;
  readonly documentTypeId: number;
  readonly documentTypeName: string;
  readonly fileName: string;
  readonly numberOfPages: number;
  readonly image: string;
  readonly title: string;
  readonly description: string;
  readonly createDate: string;
  readonly updateDate: string;
  readonly retrievalTypeId: number;
  readonly retrievalTypeName: string;
  readonly documentNumberOfPages: number;
  readonly createBy: string;

  constructor(options: {
     chaseDocumentId: number;
     chaseId: number;
     documentQueueId: number;
     documentTypeId: number;
     documentTypeName: string;
     fileName: string;
     numberOfPages: number;
     image: string;
     title: string;
     description: string;
     createDate: string;
     updateDate: string;
     retrievalTypeId: number;
     retrievalTypeName: string;
     documentNumberOfPages: number;
     createBy: string;

  }) {
    this.chaseId = options.chaseId;
    this.chaseDocumentId = options.chaseDocumentId;
    this.documentQueueId = options.documentQueueId;
    this.documentTypeId = options.documentTypeId;
    this.documentTypeName = options.documentTypeName;
    this.fileName = options.fileName;
    this.numberOfPages = options.numberOfPages;
    this.title = options.title;
    this.image = options.image;
    this.description = options.description;
    this.createDate = options.createDate;
    this.updateDate = options.updateDate;
    this.retrievalTypeId = options.retrievalTypeId;
    this.retrievalTypeName = options.retrievalTypeName;
    this.documentNumberOfPages = options.documentNumberOfPages;
    this.createBy = options.createBy;

  }
}
