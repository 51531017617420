import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../core/environment.tokens";
import { Attribute } from "../../platform/modules/member/chase-detail/chase-detail-chart/attribute.model";

@Injectable({
  providedIn: "root",
})
export class ValidationService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient
  ) { }


  validate(saveInfo: Attribute): Observable<any> {
    const url = `${this.baseApiUrl}measure/validate`;
    return this.http.post(url, saveInfo);
  }
}
