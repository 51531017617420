export class MemberSearchRequest {
  readonly projectId: number;
  readonly memberId?: number;
  readonly enrolleeId?: string;
  readonly memberFirstName?: string;
  readonly memberMiddleName?: string;
  readonly memberLastName?: string;
  readonly memberDateOfBirth?: string;
  readonly memberGender?: string;

  constructor(options: {
    readonly projectId: number;
    readonly memberId?: number;
    readonly enrolleeId?: string;
    readonly memberFirstName?: string;
    readonly memberMiddleName?: string;
    readonly memberLastName?: string;
    readonly memberDateOfBirth?: string;
    readonly memberGender?: string;

  }) {
    this.projectId = options.projectId;
    this.memberId = options.memberId;
    this.enrolleeId = options.enrolleeId;
    this.memberFirstName = options.memberFirstName;
    this.memberMiddleName = options.memberMiddleName;
    this.memberLastName = options.memberLastName;
    this.memberDateOfBirth = options.memberDateOfBirth;
    this.memberGender = options.memberGender;

  }
}
