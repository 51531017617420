import { IAutoMapper } from "../interfaces";

export const mapMappingDocument = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MappingDocument")
    .forMember("fileName", o => o.fileName)
    .forMember("filePath", o => o.filePath)
    .forMember("fileType", o => o.fileType)
    .forMember("fileSize", o => o.fileSize)
    .forMember("documentType", o => o.documentType)
    .forMember("uploadDateTime", o => o.uploadDateTime)
    .forMember("userName", o => o.userName);

};
