import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-coding-duration-report",
  templateUrl: "./coding-duration-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodingDurationReportComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.CODING_DURATION_REPORT;
  hideFilters: string[];

  constructor() {
      this.hideFilters = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID2, HideFilters.COMPLETION_DATE, HideFilters.PROJECT_NAME2];
      this.filters = ["Projects", "CompletionDate"];
  }

}
