<p-dropdown class="bulk-action"
  [options]="items" 
  [(ngModel)]="action"
  (ngModelChange)="enableButton($event)"
  placeholder="Select Next Action"
  dropdownIcon="pi pi-caret-down">
</p-dropdown>

<app-button
  class="button"
  classes="large"
  text="GO"
  (onClick)="onClick()"
  [disabled]="disabled">
</app-button>
