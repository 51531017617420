import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../../auth/auth.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { CodingReviewMode } from "../project/project-config/coding-review-mode.enum";
import { CODING_REVIEW_MODE, ENABLE_RISK_20 } from "./chase-detail/chase-detail-chart/attributes";

@Injectable()
export class ChasePageUrlService implements CanActivate {

  isRiskType30 = false;
  isRiskType21 = false;
  isRiskType20 = false;

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private router: Router,
    private http: HttpClient,
    private readonly authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userId = this.authService.userId;
    const isV2Chase = state.url.includes("chasev2");
    const chaseId = +route.params.chaseGd;

    const attributeIdsAsCsv = `${ENABLE_RISK_20.attributeId},${CODING_REVIEW_MODE.attributeId}`;

    const url = `${this.baseApiUrl}chase/risktype?chaseId=${chaseId}&userId=${userId}&attributeIdsAsCsv=${attributeIdsAsCsv}`;
    return new Observable(subscribe => {
      if (isV2Chase) {
        subscribe.next(true);
        subscribe.complete();
        return;
      }
      this.http.get(url).subscribe(response => {
        if (response && ArrayHelper.isAvailable(response as [])) {
          this.initializeAttributeValuesToVariables(response);
          if (this.isRiskType30 && (this.isRiskType21 || this.isRiskType20)) {
            window.location.href = `/app/chases/${chaseId}`;
            subscribe.next(false);
            subscribe.complete();
            return;
          }
          this.router.navigateByUrl(`/members/chasev2/${chaseId}`);
        }
        subscribe.next(true);
        subscribe.complete();
        return;
      });
    });
  }

  initializeAttributeValuesToVariables(response: any): void {
    response.forEach(element => {

      if (element.attributeId === CODING_REVIEW_MODE.attributeId) {
        const value = element.attributeValue;
        this.isRiskType21 = value === CodingReviewMode.DIAGNOSIS;
        this.isRiskType30 = value === CodingReviewMode.DIAGNOSIS_V3;
      }

      if (element.attributeId === ENABLE_RISK_20.attributeId) {
        const value = element.attributeValue;
        this.isRiskType20 = Number(value) === 1;
      }

    });
  }

 }
