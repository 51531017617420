import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ChaseUnasssignModel } from "../../../../api/unassign-chase/chase-unassign-model";
import { UnassignChaseService } from "../../../../api/unassign-chase/unassign-chase.service";
import { EmrAssignmentService } from "./emr-assignment.service";

@Component({
  selector: "retrieval-emr-assignment",
  templateUrl: "./emr-assignment.component.html",
  styleUrls: ["./emr-assignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmrAssignmentService],
})
export class EmrAssignmentComponent {

  @Input() documentSourceId = "";
  notesText = "";
  @Input() isCreateVisible = false;
  @Input() chases: number[] = [];
  @Output() formClose = new EventEmitter();

  constructor(
    private service: EmrAssignmentService,
    private messagingService: MessagingService,
    private chaseUnassignService: UnassignChaseService,
    private userService: UserService,
    private router: Router
  ) { }

  saveAssignNotes(): void {
    if (StringHelper.isAvailable(this.notesText) && ((this.notesText.length >= 4) && (this.notesText.length <= 1000))) {
      this.service.saveAssignment(this.notesText, this.documentSourceId).subscribe(
        data => {
            this.messagingService.showToast("Address Reassigned to EMR.", SeverityType.SUCCESS);
            this.unAssignChasesAtAddressLocation();
        },
        err => {
          this.messagingService.showToast("Failed to reassign Address to EMR.", SeverityType.ERROR);
        }
      );
    } else {
      this.messagingService.showToast("Notes cannot be empty and should be between 4 - 1000 characters.", SeverityType.ERROR);
      return;
    }
    this.close();

    const userToken = this.userService.getUserToken();
    const homePageUrl = userToken.homePageUrl;

    this.router.navigateByUrl(homePageUrl);
  }

  private unAssignChasesAtAddressLocation(): void {
    const functionalRoleIdRetrieval = 5;
    const unasssignModel = new ChaseUnasssignModel({
      chaseList: this.chases,
      functionalRoleId: functionalRoleIdRetrieval,
      masterDocumentSourceId: +this.documentSourceId,
    });

    this.chaseUnassignService.unassignChases(unasssignModel).subscribe(
      data => {
        this.messagingService.showToast("Chases(s) have been unassigned.", SeverityType.SUCCESS);
      },
      err => {
        this.messagingService.showToast("Error while unassigning Chases(s).", SeverityType.ERROR);
      });

  }

  close() {
    this.visibleChange(false);
  }

  visibleChange($event) {
    this.isCreateVisible = $event;
    this.formClose.emit($event);
  }

  get isNoteError(): boolean {
    return (this.notesText.length > 0 && this.notesText.length < 4) || (this.notesText.length > 1000);
  }
}
