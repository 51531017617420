import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { NumeratorListItem } from "../../../../api/numerator/numerator-list-item.model";
import { ContactMethodType } from "../../../retrieval/cover-letter-template/contact-method-type.enum";

export class ProviderPacketItem {
  readonly projectId: number;
  readonly chaseIds: number[];
  readonly toName: string;
  readonly toPhone: string;
  readonly toFax: string;
  readonly toAddress: string;
  readonly fromName: string;
  readonly fromPhone: string;
  readonly providerName: string;
  readonly serviceOrgName: string;
  readonly templateName: string;
  readonly chaseNumeratorList?: NumeratorListItem[];
  readonly masterDocumentSourceId: number;
  readonly type: ContactMethodType;

  constructor(options: Partial<ProviderPacketItem> = {}) {
    this.projectId = options.projectId;
    this.chaseIds = options.chaseIds;
    this.toName = StringHelper.clean(options.toName);
    this.toPhone = StringHelper.clean(options.toPhone);
    this.toFax = StringHelper.clean(options.toFax);
    this.toAddress = StringHelper.clean(options.toAddress);
    this.fromName = StringHelper.clean(options.fromName);
    this.fromPhone = StringHelper.clean(options.fromPhone);
    this.providerName = StringHelper.clean(options.providerName);
    this.serviceOrgName = StringHelper.clean(options.serviceOrgName);
    this.templateName = StringHelper.clean(options.templateName);
    this.chaseNumeratorList = options.chaseNumeratorList;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.type = options.type;
  }
}

