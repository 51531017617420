import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { FormService } from "../../form.service";
import { HighlightService } from "../../highlighter/highlight.service";
import { FixedArray } from "./fixed-array.model";

@Component({
  selector: "app-fixed-array",
  templateUrl: "./fixed-array.component.html",
  styleUrls: [
    "../dynamic-array.scss",
    "../../highlighter/highlight.scss",
    "./fixed-array.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedArrayComponent extends DynamicControlDirective<FixedArray> implements OnInit, OnDestroy {
  private unsubscribe = new Subject();


  constructor(
    readonly highlighter: HighlightService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());
    this.formService.updateDom
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());
    this.highlighter.onBlurGroup
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(index => this.toSave(index));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  get control(): FormArray {
    return this.formGroup.get(this.model.key) as FormArray;
  }

  push(): void {
    this.formService.push(this.control as any, this.model);
  }

  remove(index: number): void {
    if (this.formService.isDisabled(this.model)) {
      return;
    }

    const event = this.createDynamicFormEvent(index, "delete");
    event.successFn = () => {
      this.formService.remove(index, this.control as any, this.model);
    };
    this.onChange.emit(event);
  }

  toSave(groupIndex: number): void {
    const control = this.control.at(groupIndex);
    if (control.pending) {
      setTimeout(() => this.toSave(groupIndex), 100);
    } else if (control.valid && this.hasAtLeastOneValue(control)) {
      const event = this.createDynamicFormEvent(groupIndex, "save");
      this.onChange.emit(event);
    }
  }

  classes(groupIndex: number): any {
    return {
      ...this.highlighter.className(groupIndex),
      "control-container__invlaid": this.hasGroupError(groupIndex),
    };
  }

  groupError(groupIndex: number): string {
    const control = this.control.at(groupIndex);
    const keys = Object.keys(control.errors || {});
    if (!ArrayHelper.isAvailable(keys)) {
      return "";
    }

    const firstError = keys[0];
    let message;
    if (this.model && this.model.errorMessages) {
      message = this.model.errorMessages[firstError];
    }

    if (!StringHelper.isAvailable(message)) {
      message = control.errors[firstError];
    }

    if (typeof message !== "string") {
      // TODO: Force error messages to be present?
      message = "";
    }

    return message;
  }

  hasGroupError(groupIndex: number): boolean {
    const control = this.control.at(groupIndex);
    return control
      && control.invalid
      && StringHelper.isAvailable(this.groupError(groupIndex))
      && (control.dirty || control.touched);
  }

  private createDynamicFormEvent(index: number, type: string): DynamicFormEvent {
    const control = this.control.at(index);
    const event = new DynamicFormEvent({
      key: `${this.model.key}.${index}`,
      type,
      value: control.value,
      control,
      model: this.model.models[index],
    });
    return event;
  }

  private hasAtLeastOneValue(control: AbstractControl): boolean {
    const keys = Object.keys(control.value);
    for (const key of keys) {
      const value = control.value[key];
      if (typeof value === "object" && value != null) {
        return this.hasAtLeastOneValue(value);
      } else if (value != null) {
        return true;
      }
    }

    return false;
  }


  trackByIndex(index, item) {
    return index;
  }
}
