import { Injectable, NgModule, enableProdMode } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MSAL_INSTANCE, MsalModule, MsalService } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { msalConfig } from "./auth-config";
import { AuthModule } from "./auth/auth.module";
import { CoreModule } from "./core/core.module";
import { OnboardingModule } from "./onboarding/onboarding.module";
import { PlatformModule } from "./platform/platform.module";
import { ProviderModule } from "./provider/provider.module";
import { MessagingModule } from "./shared/messaging/messaging.module";
import { ToolboxModule } from "./toolbox/toolbox.module";
import { DevControlsModule } from "./zdevcontrols/zdevcontrols.module";

export const MSALInstanceFactory = (): IPublicClientApplication => new PublicClientApplication(msalConfig);

const developmentModules = [];
if (environment.production) {
  enableProdMode();
} else {
  developmentModules.push(ToolboxModule);
  // developmentModules.push(DevControlsModule); // TODO: Remove this module from production build.
}

@Injectable({ providedIn: "root" })
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    DevControlsModule,
    ...developmentModules,
    BrowserModule,
    CoreModule,
    PlatformModule,
    AuthModule,
    FormsModule,
    BrowserAnimationsModule,
    OnboardingModule,
    MessagingModule,
    ProviderModule,
    MsalModule,
  ],
  providers: [
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
