import { IAutoMapper } from "../../../core/automapper/interfaces";
import { VendorInvoiceSettingData } from "../../modules/invoice/vendor-detail/vendor-invoice-setting-data.model";

export const mapVendorExclusion = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "VendorExclusion")
    .forMember("vendorId", o => o.vendorId)
    .forMember("name", o => o.name)
    .forMember("vendorInvoiceSetting", o => o.vendorInvoiceSetting)
    .forMember("invoiceTypeId", o => o.invoiceTypeId)
    .forMember("vendorExclusionId", o => o.vendorExclusionId)
    .forMember("projectId", o => o.projectId);
};
export class VendorExclusion {
  vendorId: number;
  name: string;
  vendorInvoiceSetting: VendorInvoiceSettingData;
  invoiceTypeId: number;
  vendorExclusionId: number;
  projectId: number;
  constructor(options: {
    vendorId?: number;
    projectId?: number;
    vendorExclusionId?: number;
    invoiceTypeId?: number;
    name?: string;
    vendorInvoiceSetting?: VendorInvoiceSettingData;
  }
  ) {
    this.vendorId = options.vendorId;
    this.projectId = options.projectId;
    this.vendorExclusionId = options.vendorExclusionId;
    this.invoiceTypeId = options.invoiceTypeId;
    this.name = options.name;
    this.vendorInvoiceSetting = options.vendorInvoiceSetting;
  }
}
