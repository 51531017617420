<div class="container">
  <h3>CUSTOMIZE YOUR VIEW</h3>
  <app-button
    class="exit"
    classes="transparent large"
    styleType="pi"
    type="pi"
    icon="times"
    (onClick)="hide()">
  </app-button>
  
  <div class="info-container">
    <span>For optimum visibility, we recommend selecting up to 8 columns. All columns are available upon export.</span>
  </div>
  
  <form class="form" [formGroup]="formGroup">
    <form-textbox [formGroup]="formGroup" [model]="nameInput"></form-textbox>
    <form-checkbox-group [formGroup]="formGroup" [model]="columnsInput" [showSelectAll]="false"></form-checkbox-group>
    <form-switch *ngIf="isUserAdmin" [formGroup]="formGroup" [model]="publicInput"></form-switch>
  </form>
  
  <div class="footer">
    <app-button
      class="footer__item"
      classes="secondary"
      text="Save"
      (onClick)="saveGridView($event)">
    </app-button>
    <app-button
      *ngIf="hasGridView"
      class="footer__item"
      classes="secondary"
      text="Delete"
      (onClick)="showDeleteModal($event)">
    </app-button>
  </div>
  <div class="control__header__error" *ngIf="hasNameError" [title]="nameErrorMessage">{{nameErrorMessage}}</div>
  <div class="control__header__error" *ngIf="hasColError" [title]="colErrorMessage">{{colErrorMessage}}</div>
</div>

<div class="mask" (click)="hide()"></div>

<app-confirmation-modal
  [header]="deleteHeader"
  [(visible)]="isDeleteGridViewModalVisible"
  (onYes)="deleteGridView()">
  You are about to permanently delete this custom table. Are you sure you want to delete it?
</app-confirmation-modal>