import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { List } from "immutable";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { LocalService } from "../../../../../../../core/storage/local.service";
import { DocumentViewerSessionService } from "../../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { BasicGridComponent } from "../../../../../../../shared/grid/basic-grid/basic-grid.component";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ListItem } from "../../../../../../../shared/list/list-item";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { ProjectConfiguration } from "../../../../../project/project-config/project-configuration.model";
import { ChaseDetailState } from "../../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { Diagnosis } from "../diagnosis/diagnosis.model";
import { RiskSystemResultKey } from "../risk-system-result-key/risk-system-result-key.model";
import { RiskSystemResultComponent } from "../risk-system-result/risk-system-result.component";
import { RiskService } from "../risk.service";
import { DeleteDiagnosisComponent } from "./delete-diagnosis.component";
import { ExemptDiagnosisComponent } from "./exempt-diagnosis.component";

@Component({
  selector: "member-risk-diagnoses-grid",
  templateUrl: "./diagnoses-grid.component.html",
  styleUrls: ["./diagnoses-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosesGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @ViewChild("deleteButton", { static: true }) deleteButton: TemplateRef<DeleteDiagnosisComponent>;
  @ViewChild("exemptColumn", { static: true }) exemptColumn: TemplateRef<ExemptDiagnosisComponent>;
  @ViewChild("systemColumn", { static: true }) systemColumn: TemplateRef<RiskSystemResultComponent>;
  @ViewChild("diagnosesGrid") diagnosesGrid: BasicGridComponent;
  @ViewChild("pageNumberColumn", { static: true }) pageNumberColumn: TemplateRef<any>;
  gridConfiguration = new GridConfiguration();
  chaseDetailState: ChaseDetailState;
  diagnoses: Diagnosis[];
  enableMrrBot = "";
  projectConfiguration: ProjectConfiguration;
  selection: Diagnosis;
  systemResultKeys: RiskSystemResultKey[];
  localStorageKey = "popOutAction";
  localStorageDiagnosisSelection = "diagnosisSelection";

  constructor(
    private readonly riskService: RiskService,
    private chaseDetailStateService: ChaseDetailStateService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private readonly automapper: AutomapperService,
    private readonly changeDetector: ChangeDetectorRef,
    private localService: LocalService
  ) { }

  ngOnInit() {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        this.chaseDetailState = chaseDetailState;
        const diagnosisEntities = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
        this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
        this.selection = riskState.hasSelectedDiagnosisIndex ? this.diagnoses[riskState.selectedDiagnosisIndex] : {} as any;

        if (this.diagnosesGrid != null) {
          this.diagnosesGrid.setPageByIndex(riskState.selectedDiagnosisIndex);
        }

        this.projectConfiguration = chaseDetailState.projectConfiguration;
        this.updateProjectConfiguration();
        if (this.chaseDetailState?.chaseId) {
          this.gridConfiguration = this.getGridConfiguration();
        }
        this.changeDetector.markForCheck();
      })
    );
    this.gridConfiguration = this.getGridConfiguration();
    this.setSystemResultKey();
  }

  ngOnDestroy() {
    this.localService.delete(this.localStorageKey);
    this.localService.delete(this.localStorageDiagnosisSelection);
    this.sink.unsubscribe();
  }

  get isShowNlpComponent(): boolean {
    return this.chaseDetailState.isMrr && this.isEnableMrrBot && this.chaseDetailState.isMeasureCodesToEnableNlp;
  }

  get headerStatistics(): List<ListItem> {
    const headerStats = ([
      new ListItem({ key: "Total", value: this.totalDiagnoses.toString() }),
      new ListItem({ key: "Admin", value: this.adminDiagnoses.toString() }),
      new ListItem({ key: "Coder", value: this.addedDiagnoses.toString() }),
    ]);

    if (this.isShowNlpComponent) {
      headerStats.push(
        new ListItem({ key: "Bot", value: this.botDiagnoses.toString() })
      );
    }

    return List(headerStats);
  }

  getGridConfiguration(): GridConfiguration {
    const diagnsisColumns = [
      new GridColumnDefinition({ header: "Pg#", template: this.pageNumberColumn, width: "50px" }),
      new GridColumnDefinition({ header: "DOS", field: "dosRange", width: "150px" }),
      new GridColumnDefinition({ header: "ICD", field: "icd", width: "90px" }),
      new GridColumnDefinition({ header: "HCC Category", field: "hccs" }),
      new GridColumnDefinition({ header: "VRC", field: "vrcs" }),
      new GridColumnDefinition({ header: "", template: this.systemColumn, isSortableColumn: false, alignment: "right", width: "45px" }),
      new GridColumnDefinition({ header: "SOURCE", field: "diagnosisSource" }),
      new GridColumnDefinition({ header: "", template: this.deleteButton, isSortableColumn: false, width: "45px" }),
    ];

    if (this.chaseDetailState.isOverread) {
      diagnsisColumns.splice(
        diagnsisColumns.length - 1,
        0,
        new GridColumnDefinition({ header: "Exempt", template: this.exemptColumn, isSortableColumn: false, width: "100px" })
      );
    }

    return new GridConfiguration({
      columns: diagnsisColumns,
      pageSize: 10,
      showActionColumn: false,
      showMenu: false,
      selectionMode: "single",
      showSelectionControl: false,
    });
  }

  get isEnableMrrBot(): boolean {
    return this.enableMrrBot === "1";
  }

  setSelectedDiagnosis(): void {
    if (this.selection) {
      const selectedDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === this.selection.id);
      this.riskService.setData({ selectedDiagnosisIndex });

      if (this.localService.get(this.localStorageKey, null)) {
        this.localService.delete(this.localStorageDiagnosisSelection);
        this.localService.put(this.localStorageDiagnosisSelection, this.selection.pageNumber.toString());
      }

      if (!this.isEnableMrrBot) {
        this.documentViewerSessionService.updateDataEntryPageNumber(this.selection.pageNumber);
      }
    }
  }

  gotoDocumentPage(event: MouseEvent, pageNumber: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.documentViewerSessionService.updateDataEntryPageNumber(pageNumber);
  }

  private setSystemResultKey(): void {
    this.systemResultKeys = [
      new RiskSystemResultKey({ className: "result-icon__match", resultKeyText: "BOT FULL MATCH" }),
      new RiskSystemResultKey({ className: "result-icon__dos-match-only", resultKeyText: "BOT PARTIAL MATCH - DOS ONLY" }),
      new RiskSystemResultKey({ className: "result-icon__no-match", resultKeyText: "BOT NO MATCH" }),
    ];
  }

  private updateProjectConfiguration(): void {
    if (this.projectConfiguration != null) {
      this.enableMrrBot =
        StringHelper.isAvailable(this.projectConfiguration.enableMrrBot) ? this.projectConfiguration.enableMrrBot : "0";
    }
  }

  private get totalDiagnoses(): number {
    return this.diagnoses.length;
  }

  private get adminDiagnoses(): number {
    return this.diagnoses.filter(option => option.isAdmin).length;
  }

  private get addedDiagnoses(): number {
    return this.diagnoses.filter(option => option.isManuallyAddedSource).length;
  }

  private get botDiagnoses(): number {
    return this.diagnoses.filter(option => option.isBotSource).length;
  }
}
