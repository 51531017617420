<div class="dashboard-retrieval-container">
<app-detail-main-template>
  <div container-header>
    <h3>My Addresses</h3>
    <div class="statistics">
      <app-header-statistics [headerStatistics]="headerStatistics"></app-header-statistics>
    </div>
  </div>
  <div container-body>
    <div class="ui-g ui-fluid">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <app-address-grid [url]="url"
                          [stateName]="stateName"
                          [isSelectionModeMultiple]="isSelectionModeMultiple"
                          [additionalColumns]="additionalColumns"
                          [additionalFilters]="additionalFilters"
                          [isLoadOnInit]="false"
                          [showViews]="true"
                          [viewAttributeId]="viewAttributeId"
                          filterHeaderText="Address Query">
        </app-address-grid>
      </div>
      <div *ngIf="!hasClientLeadRole && !hasEMREmployeeRole" class="ui-g-12 ui-md-12 ui-lg-12 text-center">
        <app-button class="button button-margin"
                    *ngIf="roleIdCount>1"
                    text="REQUEST ADDITIONAL WORK"
                    (onClick)="(isRoleModalVisible=true)"></app-button>
        <app-button class="button button-margin"
                    *ngIf="roleIdCount===1"
                    text="REQUEST ADDITIONAL WORK"
                    (onClick)="getNextAddress()"
                    [disabled]="!isRequestAdditionalWorkEnabled"></app-button>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12 text-center">
        <app-button class="button button-margin"
                    text="INCOMING CALL"
                    classes="primary-action"
                    (onClick)="isInComingModalVisible=true;addressSearchReset();"></app-button>
      </div>
    </div>
      
  </div>
</app-detail-main-template>
</div>

<app-modal [(visible)]="isRoleModalVisible"
           header="SELECT ROLE"
           class="select-role-modal">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="retrievalRoleFormGroup" [model]="roleInput" (onChange)="onSelectType($event)"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button"
                text="Request Work"
                (onClick)="getNextAddress()"
                [disabled]="isGetNextDisabled || !isRequestAdditionalWorkEnabled">
    </app-button>
  </footer>
</app-modal>

<app-modal [(visible)]="isInComingModalVisible"
           header="ADDRESS SEARCH"
           class="address-search-modal"
           (onShow)="show()"
           (onCancel)="close()">
  Fill in any or all of the fields below to search for an address ID:
  <form [formGroup]="formGroupAddressSearch">

    <div class="ui-fluid address-search">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="retrievalLocationId"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="groupName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="address1"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="contact"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="address2"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="memberFirstName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="city"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="memberLastName"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="stateInput" (onChange)="onSelectState($event)"></form-factory>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch"
                        [model]="memberDateOfBirth"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <form-factory [formGroup]="formGroupAddressSearch" [model]="zip"></form-factory>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6 ui-md-6 ui-lg-6">
          <app-button class="header-button"
                      text="FIND ADDRESSES"
                      (onClick)="findAddresses()"></app-button>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6 show-all-aids-checkbox">
          <form-factory [formGroup]="aidGridFilterForm"
                        [model]="showAidsWithOpenChasesOnly"
                        (onChange)="findAddresses()"></form-factory>
        </div>
      </div>

    </div>
  </form>
  <div *ngIf="noAddressSearchResultsFound"
       class="ellipsis">No addresses found matching your search criteria.</div>
  <div class="grid-header">
    RESULTS
  </div>
  <div class="address-search-grid">
    <app-basic-grid class="grids-display"
                    [configuration]="addressSearchGridConfiguration"
                    [data]="addressSearchData"
                    [(selection)]="addressGridselection"
                    (selectionChange)="selectAddress($event)">
    </app-basic-grid>
  </div>
  <footer>
    <app-button class="header-button"
                text="USE THIS ADDRESS"
                (onClick)="gotoAddressDetail()"
                [disabled]="isGotoAddressDetailDisabled"></app-button>
  </footer>
</app-modal>
