import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() header = "";
  @Input() rejectText = "Cancel";
  @Input() foreground = true;
  @Input() allowClose = true;
  @Input() visible = false;
  @Input() isMemberQuery = false;
  @Input() onlyChartsInput = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() memberChart: EventEmitter<boolean> = new EventEmitter();
  @Output() onShow: EventEmitter<null> = new EventEmitter(true);
  @Output() onHide: EventEmitter<null> = new EventEmitter(true);
  @Output() onCancel: EventEmitter<null> = new EventEmitter(true);
  @Output() onResponse: EventEmitter<null> = new EventEmitter(true);

  show(): void {
    this.onShow.emit();
    this.change(true);
  }

  hide(): void {
    this.onHide.emit();
    this.change(false);
  }

  cancel(event: MouseEvent = null): void {
    if (event != null) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.onCancel.emit();
    this.hide();
  }

  change(value: boolean): void {
    this.visible = value;
    this.visibleChange.emit(value);
  }
  onChange(event) {
    this.memberChart.emit(event.checked);
  }
}
