import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { CatalyticResult } from "./catalytic-result.model";

@Injectable({
  providedIn: "root",
})
export class CatalyticResultService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getCatalyticResult(chaseId: number): Observable<CatalyticResult> {
    const url = `${this.baseApiUrl}chase/nlp/data?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "CatalyticResult"))
    );
  }

  saveCatalyticResult(data: CatalyticResult): Observable<CatalyticResult> {
    const url = `${this.baseApiUrl}chase/nlp/data/review`;
    return this.http.post(url, data) as Observable<null>;
  }
}
