import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { Calendar } from "./calendar.model";

@Component({
  selector: "form-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent extends DynamicControlDirective<Calendar> implements OnInit, OnDestroy {
  private sink = new SubSink();

  constructor(private readonly changeDetector: ChangeDetectorRef,
              private readonly formService: FormService
    ) {
      super();
     }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): string {
    return this.getClasses("calendar");
  }

}
