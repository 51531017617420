import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridStateService } from "../../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AuditQueryGridFilters } from "../audit-query-grid-filters/audit-query-grid-filter-model";
import { AuditQueryGridService } from "./audit-query-grid.service";

@Component({
  selector: "app-audit-query-grid",
  templateUrl: "./audit-query-grid.component.html",
  styleUrls: ["./audit-query-grid.component.scss"],
})
export class AuditQueryGridComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  @Input() stateName = "";
  gridfilters: AuditQueryGridFilters;
  isSelectWarningVisible = false;

  auditGridConfiguration = new GridConfiguration();
  auditGridSearchRequest: GridRequest;
  auditGridselection: any[];
  refreshGrid = new EventEmitter<GridConfiguration>(true);
  @ViewChild("actionItem", { static: true }) actionItem: TemplateRef<any>;
  @ViewChild("auditReadyItem", { static: true }) auditReadyItem: TemplateRef<any>;

  get chaseIdInput(): Textbox {
    return this.getInput("ChaseId") as Textbox;
  }

  get memberFirstNameInput(): Textbox {
    return this.getInput("MemberFirstName") as Textbox;
  }

  get memberLastNameInput(): Textbox {
    return this.getInput("MemberLastName") as Textbox;
  }

  get auditReadyInput(): CheckboxGroup {
    return this.getInput("AuditReadyStatusCsv") as CheckboxGroup;
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private auditQueryGridService: AuditQueryGridService,
    private readonly gridStateService: GridStateService
  ) {
  }

  ngOnInit() {
    this.createGrid();

    this.sink.add(
      this.auditQueryGridService.gridRefreshFromStateFilter
        .subscribe(data => {
          this.getGridDataFromFilterState();
      }),

      this.auditQueryGridService.auditGridFilter
        .subscribe(data => {
          this.gridfilters = data;
          this.loadGrid();
      })
    );

  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private loadGrid(): void {
      this.auditGridSearchRequest = new GridRequest({
        url: `${this.baseApiUrl}audit/queryGrid/list`,
        filters: [
          new GridFilter({
            input: new Textbox(),
            key: "ChaseId",
            name: "Chase ID",
          }),
          new GridFilter({
            input: new Textbox(),
            key: "MemberFirstName",
            name: "First Name",
          }),
          new GridFilter({
            input: new Textbox(),
            key: "MemberLastName",
            name: "Last Name",
          }),
          new GridFilter({
            input: new CheckboxGroup({
              options: [
                new SelectableInput({ text: "Yes", value: "yes" }),
                new SelectableInput({ text: "No", value: "no" }),
                new SelectableInput({ text: "Blank", value: "blank" }),
              ],
            }),
            key: "AuditReadyStatusCsv",
            name: "Audit Ready",
          }),
          new GridFilter({
            input: new Textbox(),
            key: "ProjectId",
            value: this.gridfilters?.projectId?.toString(),
            name: "Project",
            showChip: false,
            show: false,
          }),
          new GridFilter({
            input: new Textbox(),
            key: "MeasureId",
            value: this.gridfilters?.measureId?.toString(),
            name: "Measure",
            show: false,
          }),
          new GridFilter({
            input: new Textbox(),
            key: "NumeratorId",
            value: this.gridfilters?.numeratorId?.toString(),
            name: "Numerator",
            show: false,
          }),
          new GridFilter({
            input: new Textbox(),
            key: "ComplianceCode",
            value: this.gridfilters?.complianceCode,
            name: "ComplianceCode",
            show: false,
          }),
        ],
      });
      this.refreshGrid.emit();
  }

  private createGrid(): void {
    this.auditGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: "/members/chase/:chaseId" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "numeratorName", header: "Numerator" }),
        new GridColumnDefinition({ field: "memberFirstName", header: "First" }),
        new GridColumnDefinition({ field: "memberLastName", header: "Last" }),
        new GridColumnDefinition({ field: "memberSourceAliasId", header: "Client Member Key" }),
        new GridColumnDefinition({ field: "memberDOB", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
        new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance" }),
        new GridColumnDefinition({ field: "sampleChaseComplianceCode", header: "Sample Compliance" }),
        new GridColumnDefinition({ header: "Audit Ready", template: this.auditReadyItem, isSortableColumn: false }),
        new GridColumnDefinition({ field: "auditReadyUserName", header: "User" }),
        new GridColumnDefinition({ header: "Actions", template: this.actionItem, isSortableColumn: false}),
      ],
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      selectionMode: "none",
      stateName: this.stateName,
    });

    this.auditGridConfiguration.showExportAction = false;
    this.auditGridConfiguration.showActionColumn = false;
  }

  getGridDataFromFilterState(): void {
    let filters = [];
    if (StringHelper.isAvailable(this.stateName) && this.gridStateService.hasKey(this.stateName)) {
      const gridState = this.gridStateService.get(this.stateName);
      filters = gridState.request.filters.filter(filter => this.getSelectedFilters(filter.input.value));
      if (filters.length > 0) {
        setTimeout(() => this.refreshGrid.emit());
      }
    } else {
      this.auditQueryGridService.resetGridFilter(new AuditQueryGridFilters());
    }
  }

  private getSelectedFilters(filterValue): any {
    if (Array.isArray(filterValue)) {
      if (ArrayHelper.isAvailable(filterValue)) {
        return true;
      } else {
        return false;
      }
    }
    return filterValue;
  }

  onShowWarning(): void {
    this.isSelectWarningVisible = true;
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.auditGridSearchRequest == null) {
      return null;
    }

    return this.auditGridSearchRequest.getInput<T>(key);
  }
}
