import { IAutoMapper } from "../../../../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";

export const mapEveIcd = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "EveIcd")
    .forMember("code", o => o.code)
    .forMember("confidenceScore", o => o.confidenceScore)
    .forMember("dosFrom", o => o.dosFrom)
    .forMember("dosThrough", o => o.dosThrough)
    .forMember("text", o => o.text)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("hccModelIds", o => o.hccModelIds)
    .forMember("rxHCCs", o => o.rxHCCs)
    .forMember("providerSourceAliasId", o => o.providerSourceAliasId);
};

export class EveIcd {
  code?: string;
  confidenceScore?: string;
  dosFrom?: string;
  dosThrough?: string;
  text?: string;
  pageNumber?: number;
  providerSourceAliasId?: string;
  hccModelIds?: string[];
  rxHCCs?: string[];

  constructor(options: Partial<EveIcd> = {}) {
    this.code = StringHelper.clean(options.code);
    this.confidenceScore = StringHelper.clean(options.confidenceScore);
    this.dosFrom = StringHelper.clean(options.dosFrom);
    this.dosThrough = StringHelper.clean(options.dosThrough);
    this.text = StringHelper.clean(options.text);
    this.pageNumber = options.pageNumber;
    this.hccModelIds = options.hccModelIds;
    this.rxHCCs = options.rxHCCs;
    this.providerSourceAliasId = StringHelper.clean(options.providerSourceAliasId);
  }
}
