import { Inclusions } from "./helper-types";

export class NumberHelper {
  static isAvailable(value: number): boolean {
    if (typeof value !== "number") {
      return false;
    }

    const isNaN = this.isNaN(value);
    const isInfinity = this.isInfinity(value);
    return !isNaN && !isInfinity;
  }

  static isInfinity(value: number): boolean {
    const isInifinity = value === Infinity;
    const isPositiveInfinity = value === +Infinity;
    const isNegativeInfinity = value === -Infinity;
    return isInifinity || isPositiveInfinity || isNegativeInfinity;
  }

  static isNaN(value: number): boolean {
    return value !== value;
  }

  static isGreaterThan(value: number, minValue: number, inclusion = false): boolean {
    if (!this.isAvailable(value)) {
      return false;
    }

    const result = inclusion ? value >= minValue : value > minValue;
    return result;
  }

  static isLessThan(value: number, maxValue, inclusion = false): boolean {
    return this.isGreaterThan(maxValue, value, inclusion);
  }

  static isBetween(
    value: number,
    minValue: number,
    maxValue: number,
    inclusion: Inclusions = "()"
  ): boolean {
    const isMinIncluded = inclusion.charAt(0) === "[";
    const isGreaterThan = this.isGreaterThan(value, minValue, isMinIncluded);

    const isMaxIncluded = inclusion.charAt(1) === "]";
    const isLessThan = this.isLessThan(value, maxValue, isMaxIncluded);

    return isGreaterThan && isLessThan;
  }

  static randomWholeNumber(minValue: number, maxValue: number): number {
    return Math.floor(Math.random() * (maxValue - minValue) + minValue);
  }
}
