import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { FormService } from "../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseSearchRequest } from "../../platform/api/chase-search/chase-search-request-model";
import { ChaseItem } from "../../platform/api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../platform/api/chase-search/chase-search.service";
import { DOCUMENT_INTAKE_SEARCH_GRID } from "../../platform/modules/member/chase-detail/chase-detail-chart/attributes";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { RegExHelper } from "../../utilities/reg-Ex-Helper";
import { ChaseIdComponent } from "../chase-grid/chase-id.component";
import { IFilter } from "../grid/basic-filter-applied/basic-filter-applied.component";
import { BasicGridComponent } from "../grid/basic-grid/basic-grid.component";
import { GridView } from "../grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../grid/grid-menu/grid-views/grid-views.service";
import { GridStateService } from "../grid/grid-state.service";
import { GridColumnDefinition } from "../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../grid/models/grid-configuration.model";

@Component({
  selector: "app-chase-search",
  templateUrl: "./chase-search.component.html",
  styleUrls: ["./chase-search-component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseSearchComponent implements OnInit, OnDestroy {

  @Output() formClose = new EventEmitter();
  @ViewChild("chaseIdColumn", { static: true }) chaseIdColumn: TemplateRef<ChaseIdComponent>;
  readonly form: FormGroup;
  Filterform: FormGroup;
  readonly addressInput: Textbox;
  readonly memberFirstNameInput: Textbox;
  readonly memberLastNameInput: Textbox;
  readonly memberDateOfBirthInput: Textbox;
  readonly providerInput: Textbox;

  gridConfigurationModel: GridConfiguration;
  chaseData: any[];
  selectedChase: ChaseItem | ChaseItem[];
  @Input() selectionMode = "single";
  @Input() documentSourceTypeId?: number;
  @Input() chaseIdRouteUrl = "/members/chase/:chaseId";
  @ViewChild(BasicGridComponent, { static: true }) basicGridComponent: BasicGridComponent;
  views: GridViewsState;
  refreshViews = new EventEmitter<GridView>(true);
  private sink = new SubSink();

  private gridEndRecord = 500;
  searchRequest: any;
  request: IFilter[];
  chaseID: Textbox;
  isReloadFilterInput = false;
  projectName: CheckboxGroup;
  memberDateOfBirth: Textbox;
  measureCode: CheckboxGroup;
  reportingStatusName: CheckboxGroup;
  chartCommitmentDate: Textbox;
  pendCode: CheckboxGroup;
  pendStatus: CheckboxGroup;
  sampleComplianceCode: CheckboxGroup;
  chaseComplianceCode: CheckboxGroup;
  masterDocumentSourceId: Textbox;
  serviceProviders: Textbox;
  constructor(
    private readonly formService: FormService,
    private service: ChaseSearchService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private gridViewsService: GridViewsService,
    private readonly gridStateService: GridStateService
  ) {

    this.addressInput = new Textbox({
      key: "addressId",
      type: TextboxType.NUMBER,
      label: "AID",
      validators: [Validators.pattern(RegExHelper.number)],
      errorMessages: {
        pattern: `Address Id must be numeric.`,
      },
    });

    this.memberFirstNameInput = new Textbox({
      key: "memberFirstName",
      label: "First Name",
      validators: [
        Validators.minLength(2),
        Validators.maxLength(100),
      ],
      errorMessages: {
        minlength: `Invalid Patient First Name.  Minimum 2 characters and Maximum 100 characters allowed`,
        maxlength: `Invalid Patient First Name.  Minimum 2 characters and Maximum 100 characters allowed`,
      },
    });

    this.memberLastNameInput = new Textbox({
      key: "memberLastName",
      label: "Last Name",
      validators: [
        Validators.minLength(2),
        Validators.maxLength(100),
      ],
      errorMessages: {
        minlength: `Invalid Patient Last Name.  Minimum 2 characters and Maximum 100 characters allowed`,
        maxlength: `Invalid Patient Last Name.  Minimum 2 characters and Maximum 100 characters allowed`,
      },
    });

    this.memberDateOfBirthInput = new Textbox({
      key: "memberDOB",
      label: "DOB",
      validators: [Validators.maxLength(10), Validators.pattern(RegExHelper.dateWithAndWithoutBackSlash)],
      errorMessages: {
        maxlength: "DOB can not be more than 10 characters.",
        pattern: "DOB Must be in format MM/DD/YYYY or MMDDYYYY",
      },
      placeholder: "MM/DD/YYYY or MMDDYYYY",
    });

    this.providerInput = new Textbox({
      key: "providerName",
      label: "Provider Name",
      validators: [
        Validators.minLength(3),
        Validators.maxLength(100),
      ],
      errorMessages: {
        minlength: `Invalid Provider Name. Minimum 3 characters and Maximum 100 characters allowed`,
        maxlength: `Invalid Provider Name. Minimum 3 characters and Maximum 100 characters allowed`,
      },
    });

    this.form = this.formService.createFormGroup([
      this.addressInput,
      this.memberFirstNameInput,
      this.memberLastNameInput,
      this.memberDateOfBirthInput,
      this.providerInput]
    );
  }

  ngOnInit() {
    this.createGrid();

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView))
    );
    this.Filterform = this.formService.createFormGroup([
    this.chaseID,
    this.projectName,
    this.reportingStatusName,
    this.memberDateOfBirth,
    this.measureCode,
    this.pendCode,
    this.pendStatus,
    this.sampleComplianceCode,
    this.chaseComplianceCode,
    this.masterDocumentSourceId,
    this.serviceProviders]);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  createGrid(): void {
    this.gridConfigurationModel = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseID", template: this.chaseIdColumn, header: "Chase" }),
        new GridColumnDefinition({ field: "projectName", header: "Project" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "workflowStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
        new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
        new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
        new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance" }),
        new GridColumnDefinition({ field: "memberLastName", header: "Last Name" }),
        new GridColumnDefinition({ field: "memberFirstName", header: "First Name" }),
        new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID" }),
        new GridColumnDefinition({ field: "serviceProviders", header: "Provider" }),
        new GridColumnDefinition({ field: "memberValidationReason", header: "Move Back Reason" }),
        new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
      ],
      selectionMode: this.selectionMode,
      showExportAction: false,
      showActionColumn: false,
      viewAttributeId: DOCUMENT_INTAKE_SEARCH_GRID.attributeId,
      stateName: DOCUMENT_INTAKE_SEARCH_GRID.attributeCode,
      showViews: true,
    });
    this.gridConfigurationModel.filterGridFn = this.onApplyFilter;
    this.getViews();
    this.loadFilter();
  }
  onApplyFilter = () => {
    const newRequest = [];
    Object.keys(this.Filterform.value).forEach(key => {
      if (this.Filterform.value[key] != null && !Array.isArray(this.Filterform.value[key])) {
          newRequest.push({
            text: key,
            value: this.Filterform.value[key].value ? this.Filterform.value[key].text : this.Filterform.value[key],
            label: this.Filterform.value[key].value ? key : this[key].label,
            controlType: this[key] ? this[key].controlType : "",
          });

      }
      if (ArrayHelper.isAvailable(this.Filterform.value[key])) {
        this.Filterform.value[key].map(item => newRequest.push({
          text:   key,
          value: item.value,
          label: this[key].label,
          controlType: this[key].controlType,
        }));
      }
    });

    const newFilter = [];
    newRequest.forEach(filter => {
      if ((newFilter.findIndex(item => item.text === filter.text)) > -1) {
        newFilter[newFilter.findIndex(item => item.text === filter.text)].value.push(filter.value);
      } else {
        newFilter.push({ text: filter.text, value: [filter.value], controlType: filter.controlType });
      }
    });

    this.request = newRequest;
    this.saveChaseSearchFiltersState();
  }
  saveChaseSearchFiltersState(event?: IFilter) {
    const state = this.gridStateService.get(DOCUMENT_INTAKE_SEARCH_GRID.attributeCode);
    if (event) {
      this.request = state.basicGridState.basicGridRequest;
    }
    state.basicGridState.basicGridRequest = event?.reset ? [] : this.request;
    this.gridStateService.put(DOCUMENT_INTAKE_SEARCH_GRID.attributeCode, state);
  }
  get requestUrl(): string {
    return this.service.chaseSearchUrl();
  }
  private getViews(gridView: GridView | null = null): void {
    if (this.gridConfigurationModel.showViews) {
      this.gridViewsService.get(this.gridConfigurationModel.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.basicGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }
  private loadFilter(): void {
     this.chaseID = new Textbox({
      key: "chaseID",
      label: "Chase ID",
    });
     this.projectName = new CheckboxGroup({
      key: "projectName",
      label: "Projects",
    });
     this.measureCode = new CheckboxGroup({
      key: "measureCode",
      label: "Measures",
    });
     this.reportingStatusName = new CheckboxGroup({
      key: "reportingStatusName",
      label: "Status",
    });
     this.pendCode = new CheckboxGroup({
      key: "pendCode",
      label: "Pend Codes",
    });
     this.pendStatus = new CheckboxGroup({
      key: "pendStatus",
      label: "Pend Status",
    });
     this.sampleComplianceCode = new CheckboxGroup({
      key: "sampleComplianceCode",
      label: "Sample Compliance",
    });
     this.chaseComplianceCode = new CheckboxGroup({

      key: "chaseComplianceCode",
      label: "Chase Compliance",

    });
     this.memberDateOfBirth = new Textbox({
      key: "memberDateOfBirth",
      label: "DOB",
    });
     this.masterDocumentSourceId = new Textbox({
      key: "masterDocumentSourceId",
      label: "AID",
    });
     this.serviceProviders = new Textbox({
      key: "serviceProviders",
      label: "Providers",
      });
  }
  onRemoveFilter(event: IFilter): void {
    if (event.reset) {
      this.Filterform.reset();
    } else if (ArrayHelper.isAvailable(this.form.get(event.text).value)) {
      const filteredValue = this.form.get(event.text).value.filter(item => item.value !== event.value);
      this.Filterform.get(event.text).setValue(filteredValue);
    } else {
      this.Filterform.get(event.text).reset();
    }
  }
  selectAndClose() {
    if (this.selectedChase) {
      const chaseId = (ArrayHelper.isAvailable(this.castToType<ChaseItem[]>(this.selectedChase))) ?
        this.castToType<ChaseItem[]>(this.selectedChase).map(chase => chase.chaseID).join() :
        this.castToType<ChaseItem>(this.selectedChase).chaseID.toString();
      this.closeForm(chaseId);
    } else {
      this.messagingService.showToast("Please select a Chase first.", SeverityType.WARN);
    }
  }

  closeForm(selectedChaseIds: string) {
    this.formClose.emit(selectedChaseIds);
  }

  search() {
    if (this.form.invalid) {

      return;
    }

    this.callSearch(1, 99);

  }
  loadFilterInput(): void {
    if (!this.isReloadFilterInput) {
      this.getProjects();
      this.getMeasures();
      this.getStatuses();
      this.getPendCodes();
      this.getPendStatus();
      this.getComplianceCodes();
      this.getchaseComplianceCode();
      this.isReloadFilterInput = true;
    }
 }
  private getStatuses(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      value: item.reportingStatusName,
      text: item.reportingStatusName,
    })
    );
    const options = this.getUniqueListBy(data, "text");
    this.reportingStatusName = new CheckboxGroup({ ...this.reportingStatusName, options } as any);
    this.formService.updateDom.next();
  }
  private getProjects(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      value: item.projectName,
      text: item.projectName,
    })
    );
    const options = this.getUniqueListBy(data, "text");
    this.projectName = new CheckboxGroup({ ...this.projectName, options } as any);
    this.formService.updateDom.next();
  }
  private getMeasures(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      value: item.measureCode,
      text: item.measureCode,
    })
    );
    let options = this.getUniqueListBy(data, "text");
    options = this.removeWhiteSpace(options);
    this.measureCode = new CheckboxGroup({ ...this.measureCode, options } as any);
    this.formService.updateDom.next();
  }
  private getPendCodes(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      value: item.pendCode,
      text: item.pendCode,
    })
    );
    let options = this.getUniqueListBy(data, "text");
    options = this.removeWhiteSpace(options);
    this.pendCode = new CheckboxGroup({ ...this.pendCode, options } as any);
    this.formService.updateDom.next();
  }
  private getPendStatus(): void {
  const data = this.chaseData.map(item => new SelectableInput({
          text: item.pendStatus,
          value: item.pendStatus,
  }));
  let options = this.getUniqueListBy(data, "text");
  options = this.removeWhiteSpace(options);
  this.pendStatus = new CheckboxGroup({ ...this.pendStatus, options } as any);
  this.formService.updateDom.next();
  }
  private getComplianceCodes(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      text: item.sampleComplianceCode,
      value: item.sampleComplianceCode,
    }));
    let options = this.getUniqueListBy(data, "text");
    options = this.removeWhiteSpace(options);
    this.sampleComplianceCode = new CheckboxGroup({ ...this.sampleComplianceCode, options } as any);
    this.formService.updateDom.next();
  }
  private getchaseComplianceCode(): void {
    const data = this.chaseData.map(item => new SelectableInput({
      text: item.chaseComplianceCode,
      value: item.chaseComplianceCode,
    }));
    let options = this.getUniqueListBy(data, "text");
    options = this.removeWhiteSpace(options);
    this.chaseComplianceCode = new CheckboxGroup({ ...this.chaseComplianceCode, options } as any);
    this.formService.updateDom.next();
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }
  removeWhiteSpace(arr) {
    const filteredArray = arr.filter((element: SelectableInput) => {
      if (Object.keys(element.text).length !== 0) {
        return true;
      }
      return false;
    });
    return filteredArray;
  }

  callSearch(startRecord: number, endRecord: number) {
    // TODO: MemberName is obsoleted to filter chases?
     this.searchRequest =
      new ChaseSearchRequest("chaseId", "desc", null, null, this.form.controls.addressId.value, null, null,
                             this.getDOB(this.form.controls.memberDOB.value), this.form.controls.providerName.value,
                             null, null, this.gridEndRecord, startRecord, endRecord, this.form.controls.memberFirstName.value,
                             this.form.controls.memberLastName.value, this.documentSourceTypeId);

     this.service
      .chaseSearch(this.searchRequest)
      .subscribe(this.assignAndNotify);
  }

  clearControls() {
    this.form.reset();
  }

  private getDOB(validateData: string): string {
    let finalData = validateData;
    if (finalData) {
      const slash = /[\/.]/gm;
      if (!slash.test(validateData)) {
        finalData = `${validateData.substring(0, 2)}/${validateData.substring(2, 4)}/${validateData.substring(4)}`;
      }
    }
    return finalData;
  }

  private assignAndNotify = (data: ChaseItem[]): void => {
    this.chaseData = data as any;
    this.isReloadFilterInput = false;
    this.loadFilterInput();
    this.changeDetector.markForCheck();
  }

  trackByIndex0(index, item) {
    return index;
  }
  trackByIndex1(index, item) {
    return index;
  }
  trackByIndex2(index, item) {
    return index;
  }

  private castToType<T>(value: any): T {
    return value as T;
  }
}
