<div [formGroup]="formGroup" class="control control--input control--radiobutton" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
  </div>
  <div #radioBtn>
  <p-radioButton *ngFor="let option of options; trackBy: trackByIndex"
                 [label]="option.text"
                 [name]="groupName"
                 [value]="option.value"
                 [ngClass]="classes"
                 (click)="onChangeEvent($event, option)"
                 (onClick)="onChangeEvent($event, option)"
                 (onFocus)="onFocus.emit($event)"
                 (onBlur)="onBlur.emit($event)"
                 [formControlName]="model.key">
  </p-radioButton>
</div>
  <div *ngIf="hasSubLabel" class="control__header">
    <label class="control__header__label control__header__label--subLabel" [for]="model.key" [title]="model.subLabel">{{ model.subLabel | titlecase }}</label>
  </div>
</div>
