import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { FormService } from "../../form.service";
import { TagSearchMultiselect } from "./tag-search-multiselect.model";

@Component({
  selector: "form-tag-search-multiselect",
  templateUrl: "./tag-search-multiselect.component.html",
  styleUrls: ["./tag-search-multiselect.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSearchMultiselectComponent extends DynamicControlDirective<TagSearchMultiselect> implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  private sink = new SubSink();
  @ViewChild("primeMultiselect", { static: true }) primeMultiselect: MultiSelect;
  options: any[];
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngDoCheck() {
    if (this.options !== this.model.options) {
      this.options = this.model.options;
      if (!this.model.useFilter) {
        this.changeDetector.markForCheck();
        }
    }
  }

  ngAfterViewInit(): void {
    this.sink.add(
      this.primeMultiselect.onChange.subscribe((event: any) => this.onChange.next(new DynamicFormEvent({
        key: this.model.key,
        type: "notify",
        value: event.value,
        control: this.control,
        model: this.model,
      })))
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get optionText(): string {
    return "text";
  }
  get isTriggerInput(): boolean {
     return this.model.key === "vrc" || this.model.key === "omission";
  }

  get selectedOptionsData(): number {
    return this.model.selectedOptions.length;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get maxSelectedLabels(): number {
    return NumberHelper.isGreaterThan(this.model.maxSelectedLabels, 0) ? this.model.maxSelectedLabels : 3;
  }

  get classes(): string {
    return this.getClasses("multiselect");
  }

  get emptyFilterMessage(): string {
    return this.model.useFilter ? "No results found" : "";
  }

  get tooltipTitle(): string {
    return ArrayHelper.isAvailable(this.model.selectedOptions) ?
      this.model.selectedOptions.map(x => x.text).toString()
      : "";
  }

  get isSearchMode(): boolean {
    return StringHelper.isAvailable(this.primeMultiselect.filterValue);
  }

  get selectedOptionsText(): string {
    return  ArrayHelper.isAvailable(this.model.selectedOptions) ?
      this.model.selectedOptions.length < 4 ?
      this.model.key === "vrc" ?
      this.model.selectedOptions.map(x => x.value).join(", ") :
      this.model.selectedOptions.map(x => x.text).join(", ") :
      `${this.model.selectedOptions.length} Options Selected` :
      "";
  }

  itemSelected(event: DynamicFormEvent): void {
     this.onChange.emit(event);
     this.changeDetector.markForCheck();
    }
  onPanelHideSelected(): void {
     if (this.isTriggerInput) {
         const  event = new DynamicFormEvent({
          key: this.model.key,
          type: "save",
          value: this.control.value,
          control: this.control,
          model: this.model,
        });

         this.onChange.emit(event);
    }
     this.changeDetector.markForCheck();
    }

}
