import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export const mapAnalyticsItem = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "AnalyticsItem")
        .forMember("analyticsItemID", o => o.analyticsItemID)
        .forMember("name", o => o.name)
        .forMember("description", o => o.description)
        .forMember("analyticsType", o => o.analyticsType)
        .forMember("analyticsSource", o => o.analyticsSource)
        .forMember("analyticsCategory", o => o.analyticsCategory)
        .forMember("analyticsOutput", o => o.analyticsOutput)
        .forMember("url", o => o.url)
        .forMember("lookerIframeId", o => o.lookerIframeId)
        .forMember("isNewReport", o => o.isNewReport);
};

export class AnalyticsItem {
    analyticsItemID?: number;
    name?: string;
    description?: string;
    analyticsType?: string;
    analyticsSource?: string;
    analyticsCategory?: string;
    analyticsOutput?: string;
    url?: string;
    lookerIframeId?: number;
    isNewReport?: boolean;

    constructor(options: {
        analyticsItemID?: number;
        name?: string;
        description?: string;
        analyticsType?: string;
        analyticsSource?: string;
        analyticsCategory?: string;
        analyticsOutput?: string;
        url?: string;
        lookerIframeId?: number;
        isNewReport?: boolean;
    }) {
        this.analyticsItemID = options.analyticsItemID;
        this.name = StringHelper.clean(options.name);
        this.description = StringHelper.clean(options.description);
        this.analyticsType = StringHelper.clean(options.analyticsType);
        this.analyticsSource = StringHelper.clean(options.analyticsSource);
        this.analyticsCategory = StringHelper.clean(options.analyticsCategory);
        this.analyticsOutput = StringHelper.clean(options.analyticsOutput);
        this.url = StringHelper.clean(options.url);
        this.lookerIframeId = options.lookerIframeId;
        this.isNewReport = options.isNewReport;

    }
}
