import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
    selector: "app-pend-report",
    templateUrl: "./pend-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendReportComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.PEND_REPORT;

    constructor() {
      this.filters = ["Projects", "PendDate", "PendStatus", "PendCode", "PendOwner", "Products", "LOB", "RetrievalOwner"];
    }
}
