import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-provider-validation",
  templateUrl: "./provider-validation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderValidationComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.PROVIDER_VALIDATION_REPORT;

  constructor() {
    this.filters = ["Projects"];
  }
}
