import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ExemptService } from "./exempt.service";

@Component({
  selector: "member-compliance-exempt",
  template: `
    <form-factory [formGroup]="form" [model]="exempt" (onChange)="save($event)"></form-factory>
  `,
  styleUrls: ["./exempt.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExemptComponent implements OnInit {
  @Input() data: any;
  exempt: Checkbox;
  form: FormGroup;


  constructor(
    private readonly formService: FormService,
    private readonly exemptService: ExemptService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit() {
    const numeratorName = StringHelper.removeNonAlphaNumericCharacters(this.data.numeratorName);
    this.exempt = new Checkbox({
      key: `${numeratorName}_Exempt`,
      value: BooleanHelper.tryGet(this.data.exemptFromScoring, false),
    });

    this.form = this.formService.createFormGroup([this.exempt]);
  }


  save(event: any): void {
    this.data.exemptFromScoring = event.checked;
    this.exemptService.save(this.data).subscribe(
      () => { /* do nothing on success */ },
      error => {
        this.data.exemptFromScoring = !event;
        this.form.get(this.exempt.key).setValue(this.data.exemptFromScoring);
        this.messagingService.showMessage(`${event ? "Exempting" : "Including"} '${this.data.numeratorName}' failed. Please try again.`, SeverityType.ERROR);
        this.changeDetector.markForCheck();
        this.formService.updateDom.next();
      }
    );
  }
}
