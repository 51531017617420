<div class="landing-header-container">
  <app-detail-header-template>
    <div class="risk-header-section" top-left>
      <div class="risk-header-section__title">ANALYTICS</div>
      <div class="risk-header-section__description">
        Here's a list of our interactive dashboards and reports. All reports and dashboards are categorized for your convenience. All data for dashboards, reports, and data extracts is in UTC time zone.
      </div>
    </div>
  </app-detail-header-template>
</div>


<div class="landing-main-container">
  <div class="ui-md-3 analytics-list-container">
    <div *ngFor="let type of analyticsTypeList; let i = index; trackBy: trackByFn; let first= first;" [ngClass]="{ first: first }">
      <div class="analytics-category"
           [ngClass]="selectedCategory(type.analyticsType, first, null)"
           (click)="setSelectedAnalyticsType(type.analyticsType)">
        {{ type.analyticsType }}
        <span class="count">{{type.count}}</span>
      </div>

      <ul class="category__label" [ngClass]="getClassForReportCategory(type.analyticsType)">
        <li *ngFor="let categories of type.analyticsCategories; let i = index; trackBy: trackByFn"
            [class]="getClass(categories.categoryName)"
            [ngClass]="selectedCategory(type.analyticsType, false, categories.categoryName)"
            (click)="setSelectedCategory(categories, type.analyticsType)">
          <span class="setposition"> {{categories.categoryName}}</span>
          <span class="count"> {{categories.count}}</span>
        </li>
      </ul>
    </div>
    <div class="more-information">
      <a href="https://reveleer.freshdesk.com/support/solutions/folders/48000675936" target="_blank">
        <span>Learn more about our Analytics </span><app-icon iconStyle="fas fa-arrow-circle-right" iconType="fas fa-arrow-circle-right" iconName="More Information"></app-icon>
      </a>
    </div>
  </div>

  <div class="ui-md-6 reporting-list-container">
    <div class="reporting-list-container__item"
         *ngFor="let reports of reportingList; let i = index; trackBy: trackByFn">
      <div class="outer">
        <div class="report-icon-container">
          <a (click)="navigateToUrl(reports.url)">
            <div class="report_item" [ngClass]="getClassForDashboardIcon(reports.analyticsCategory, reports.analyticsType)">
              <app-icon [iconName]="getReportIcon(reports.analyticsCategory)"
                        [iconStyle]="getReportIcon(reports.analyticsCategory)"></app-icon>
            </div>
          </a>
        </div>

        <div [ngClass]="autoHeightClass(reports.description, reports.analyticsCategory)" (click)="navigateToUrl(reports)">
          <div class="report-item-container--direction">
            <span class="report-item-container--name">{{ reports.name }}</span>
            <span class="report-item-container--description">{{reports.description}}</span>
            <span *ngIf="isCategoryExtract(reports)" class="report-item-container--text">* Please note this report is download only</span>
          </div>
        </div>
      </div>
      <label *ngIf="reports.isNewReport" class="new_label">New</label>
    </div>

    <div class="search-container" *ngIf="(!showSearchIcon || !hasSearchResult) && reportingCount<= 0">
      <p class="search-container__no-result-text">Sorry, no results. Please try a new search.</p>
    </div>
  </div>

  <div class="ui-md-3 search-section">
    <div class="search-section__input">
      <form-factory [formGroup]="searchPhraseForm"
                    [model]="searchPhraseInput"
                    (onInput)="onInputSearchText()"
                    (keyup.enter)="fetchTextMatches()">
      </form-factory>

    </div>
    <app-button *ngIf="showSearchIcon"
                class="search-bar__item search-bar__item--icon search-bar__item--search-icon"
                classes="transparent small"
                icon="play fa-search"
                (onClick)="fetchTextMatches(searchResults)">
    </app-button>
    <app-button *ngIf="!showSearchIcon"
                class="search-bar__item search-bar__item--icon search-bar__item--search-icon"
                classes="transparent small"
                icon="times-circle"
                (onClick)="resetSearchInput()">
    </app-button>
  </div>
</div>

<app-modal class="analytics__filter" [(visible)]="isFiltersVisible">
  <app-tab-menu orientation="left">
    <app-tab-panel header="Projects" class="checkbox-group__halves">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
      </div>
    </app-tab-panel>
  </app-tab-menu>

  <footer>
    <app-button (onClick)="onUpdate()" text="Update" [disabled]="!isUpdateEnabled"></app-button>
  </footer>

</app-modal>
