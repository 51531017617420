import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EmailVerificationComponent } from "./chart-upload/email-verification/email-verification.component";
import { ThankyouComponent } from "./chart-upload/thankyou/thankyou.component";
import { ReviewAuthLetterComponent } from "./review-auth-letter/review-auth-letter.component";

const routes: Routes = [
  { path: "request/verify/:organizationId", component: EmailVerificationComponent },
  { path: "request/verify/:organizationId/:password", component: EmailVerificationComponent },
  { path: "reviewauth", component: ReviewAuthLetterComponent },
  { path: "thankyou", component: ThankyouComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProviderRoutingModule { }
