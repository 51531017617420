import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class ExpectedDueDate {
  retrievalLocationId: number;
  expectedDueDate: string;
  functionalRoleId: number;
  chaseIds: number[];

  constructor(options: Partial<ExpectedDueDate> = {}) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.expectedDueDate = StringHelper.clean(options.expectedDueDate);
    this.functionalRoleId = options.functionalRoleId;
    this.chaseIds = options.chaseIds;
  }
}
