import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { ProviderPacketLogoResponseModel } from "./provider-packet-logo-response.model";
import { ServiceOrgLogoResponseModel } from "./service-org-logo-response.model";

@Injectable({
    providedIn: "root",
})

export class LogoService {

    constructor(
        @Inject(BASE_API_URL)
        private readonly baseApiUrl: string,
        private readonly http: HttpClient,
        private readonly automapper: AutomapperService
    ) { }

    getServiceOrgLogoUrl(): Observable<ServiceOrgLogoResponseModel> {
        const url = `${this.baseApiUrl}serviceorg/getServiceOrgLogo`;
        return this.http.get(url).pipe(
            map(this.automapper.curry("default", "ServiceOrgLogoResponseModel"))
          );
    }

    deleteServiceOrgLogo(fileName: string): Observable<null> {
        const url = `${this.baseApiUrl}serviceorg/deleteServiceOrgLogo?fileName=${fileName}`;
        return this.http.delete(url) as Observable<null>;
    }

    getProviderPacketLogoUrl(projectId: number): Observable<ProviderPacketLogoResponseModel> {
        const url = `${this.baseApiUrl}projectconfiguration/providerpacketlogo?projectId=${projectId}`;
        return this.http.get(url).pipe(
            map(this.automapper.curry("default", "ProviderPacketLogoResponseModel"))
          );
    }
}
