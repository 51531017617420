export enum StateMachineType {
    ChartIntake = 1,
    ChartDownload = 2,
    MRCSChartUpload = 3,
    HedisAuditPackageRequest = 4,
    ConvertPDF = 5,
    DataExport = 6,
    CatalyticNightlyChaseList = 7,
    FaxBlast = 8,
    BulkIntake = 9,
    AnthemReportDailyDelivery = 10,
    AnthemQSHRReportDailyDelivery = 11,
    PackageChart = 12,
    DataLoad = 13,
    AnthemHedisExtractDelivery = 14,
    CatalyticNightlyHedisNlpSystemResultsDelivery = 15,
    CatalyticNightlyHedisDataExportDelivery = 16,
    InvoiceDownload = 17,
    AthenaEHRXmlToPDFConversion = 18,
    ConvertPDFToHighResolutionImage = 19,
    MDPMemberChartRequestAndIngestion = 20,
    ChartIntakeTest = 21,
    ReportDownload = 22,
    DataLoadSftp = 23,
}
