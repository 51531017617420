import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SystemResultType } from "./system-result.enum";

@Component({
  selector: "member-system-result",
  templateUrl: "./system-result.component.html",
  styleUrls: ["./system-result.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemResultComponent {
  @Input() matchResult: number;

  get resultClass(): string {
    switch (this.matchResult) {
      case SystemResultType.MATCH: {
        return "result-icon__match";
        break;
      }
      case SystemResultType.NOMATCH: {
        return "result-icon__no-match";
        break;
      }
      case SystemResultType.PARTIALMATCH: {
        return "result-icon__partial-match";
        break;
      }
      default: {
        return "result-icon__na-match";
        break;
      }
    }
  }

  get resultText(): string {
    switch (this.matchResult) {
      case SystemResultType.MATCH: {
        return "Match";
        break;
      }
      case SystemResultType.NOMATCH: {
        return "No Match";
        break;
      }
      case SystemResultType.PARTIALMATCH: {
        return "Partial Match";
        break;
      }
      default: {
        return "N/A";
        break;
      }
    }
  }

}
