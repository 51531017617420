import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { SubSink } from "subsink";
import { AuthService } from "../../auth/auth.service";
import { IDLE_PERIOD_IN_SEC, TIMEOUT_PERIOD_IN_SEC } from "../../core/environment.tokens";

@Component({
  selector: "platform-idle",
  templateUrl: "./idle.component.html",
  styleUrls: ["./idle.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdleComponent implements OnInit, OnDestroy {
  private sink = new SubSink();

  idleState = "";
  isWarningModalVisible = false;

  constructor(
    private idle: Idle,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    @Inject(IDLE_PERIOD_IN_SEC) private readonly idlePeriodInSec: number,
    @Inject(TIMEOUT_PERIOD_IN_SEC) private readonly timeoutPeriodInSec: number
  ) { }

  ngOnInit() {
    this.initializeTimer();
    this.resetTimer();

    this.sink.add(
      this.idle.onTimeout.subscribe(() => {
        this.isWarningModalVisible = false;
        this.authService.logout(true);
        this.changeDetector.markForCheck();
      }),
      this.idle.onIdleStart.subscribe(() => {
        this.isWarningModalVisible = true;
        this.idle.setAutoResume(AutoResume.notIdle);
        this.changeDetector.markForCheck();
      }),
      this.idle.onTimeoutWarning.subscribe(countdown => {
        this.idleState = `Your session will expire in ${countdown} seconds!`;
        const expiryValue = localStorage.getItem("ng2Idle.main.expiry");
        if (!expiryValue) {
          this.isWarningModalVisible = false;
          this.idle.timeout();
        }
        this.changeDetector.markForCheck();
      }),
      this.idle.onIdleEnd.subscribe(() => {
        this.isWarningModalVisible = false;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  initializeTimer(): void {
    this.idle.setIdle(this.idlePeriodInSec);
    this.idle.setTimeout(this.timeoutPeriodInSec);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  resetTimer() {
    this.idle.watch();
    this.isWarningModalVisible = false;
    this.changeDetector.markForCheck();
  }

  logout(): void {
    this.isWarningModalVisible = false;
    this.idle.timeout();
    this.clearNg2IdleStorage();
    this.changeDetector.markForCheck();
  }

  private clearNg2IdleStorage(): void {
    localStorage.removeItem("ng2Idle.main.idling");
    localStorage.removeItem("ng2Idle.main.expiry");
  }
}
