import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { List } from "immutable";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { MenuItem } from "../menu-item.model";

@Component({
  selector: "app-tab-sub-menu-non-routed",
  templateUrl: "./tab-sub-menu-non-routed.component.html",
  styleUrls: ["./tab-sub-menu-non-routed.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabSubMenuNonRoutedComponent implements OnInit {
  @Input() tabMenuItems: MenuItem[] = [];
  @Output() currentTab = new EventEmitter<MenuItem>();
  @Input() selectedTab: MenuItem;

  constructor() { }

  ngOnInit(): void {

  }

  get filteredMenuItems(): List<MenuItem> {
    const items = this.hasMenuItmes ? (this.tabMenuItems as any).filter(item => item.show) : [];
    return List(items);
  }

  get hasMenuItmes(): boolean {
    return ArrayHelper.isAvailable(this.tabMenuItems);
  }

  trackByFn(index, item) {
    return index;
  }

  toggleTab(tab: MenuItem): void {
    this.selectedTab = (tab.description === "") ? this.selectedTab : tab;
    this.currentTab.emit(this.selectedTab);
  }

  getActiveClass(menuItem: MenuItem): string {
    const cssClass = "link";

    if (StringHelper.isAvailable(menuItem.description) && this.selectedTab != undefined) {
      return this.selectedTab.description === menuItem.description ? `${cssClass} active` : `${cssClass}`;
    }

    return cssClass;
  }

}
