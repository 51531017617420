import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { CommentsModule } from "../comments/comments.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { PanelModule } from "../panel/panel.module";
import { PendModule } from "../pend/pend.module";
import { TagModule } from "../tags/tag.module";
import { ChaseAssignmentModalComponent } from "./chase-assignment-modal/chase-assignment-modal.component";
import { ChaseGridComponent } from "./chase-grid.component";
import { ChaseIdComponent } from "./chase-id.component";
import { CopyChartModalComponent } from "./copy-chart-modal/copy-chart-modal.component";
import { UpdateRetrieverModelComponent } from "./update-retriever-modal/update-retriever-model.component";

@NgModule({
  imports: [
    ButtonModule,
    CommentsModule,
    CommonModule,
    DynamicFormsModule,
    GridModule,
    PanelModule,
    PendModule,
    MenuModule,
    TagModule,
  ],
  declarations: [
    ChaseGridComponent,
    ChaseAssignmentModalComponent,
    CopyChartModalComponent,
    ChaseIdComponent,
    UpdateRetrieverModelComponent,
  ],
  exports: [
    ChaseGridComponent,
    ChaseAssignmentModalComponent,
    CopyChartModalComponent,
    ChaseIdComponent,
    UpdateRetrieverModelComponent,
  ],
})
export class ChaseGridModule { }
