import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { Resize } from "./resize.enum";
import { TextareaComponent } from "./textarea.component";

export interface ITextareaOptions extends IDynamicInputOptions {
  placeholder?: string;
  resize?: Resize;
  rows?: number;
}

export class Textarea extends DynamicInput implements ITextareaOptions {
    placeholder: string;
    resize: Resize;
    rows: number;

    readonly controlType = "textarea";
    readonly componentType = TextareaComponent;


    constructor(options: ITextareaOptions = {}) {
      super(options);
      this.placeholder = options.placeholder || "";
      this.resize = ["none", "vertical"].includes(options.resize) ? options.resize : Resize.NONE;
      this.rows = options && options.rows > 3 ? options.rows : 3;
    }
  }
