import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthService } from "../../../auth/auth.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { AddressDetailStateService } from "../../../platform/modules/retrieval/address-detail/address-detail-state.service";
import { AddressesQueueService } from "../../../platform/modules/retrieval/addresses-queue/addresses-queue.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

@Component({
  selector: "app-address-assignment-modal",
  templateUrl: "./address-assignment-modal.component.html",
})
export class AddressAssignmentModalComponent implements OnInit {
  usersInput: Autocomplete;
  usersForm: FormGroup;
  isLoaded = false;

  get selectedUser(): SelectableInput {
    if (this.usersForm == null) {
      return null;
    }

    return this.usersForm.get("assignToUsers").value;
  }
  set selectedUser(value: SelectableInput) {
    if (this.usersForm == null) {
      return;
    }

    this.usersForm.get("assignToUsers").setValue(value);
  }

  get selectedUserId(): number {
    if (this.selectedUser == null) {
      return null;
    }

    return +this.selectedUser.value;
  }

  get hasSelectedUser(): boolean {
    return NumberHelper.isGreaterThan(this.selectedUserId, 0, true);
  }

  private pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
    this.getUsers(false);
    if (!this.visible) {
      this.closing();
    }
  }
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);

  @Input() isUnassignEnabled = true;

  private pAddresses = [];
  @Input()
  get addresses(): any[] {
    return this.pAddresses;
  }
  set addresses(value: any[]) {
    this.pAddresses = value;
    this.addressesChange.emit(this.addresses);
    if (value !== undefined && value.length > 0) {
      this.getUsers(true);
    }
  }

  get addressIds(): number[] {
    if (!ArrayHelper.isAvailable(this.addresses)) {
      return [];
    }

    return this.addresses.map(address => +address.masterDocumentSourceID);
  }

  get isSpecialHandling(): boolean {
    return this.addresses.some(x => x.specialHandling === 1);
  }

  @Output() addressesChange = new EventEmitter<number[]>();

  constructor(
    private addressesQueueService: AddressesQueueService,
    private changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly messagingService: MessagingService,
    private readonly authService: AuthService,
    private readonly addressDetailStateService: AddressDetailStateService
  ) { }

  ngOnInit() {
    this.usersInput = new Autocomplete({
      key: "assignToUsers",
      label: "User",
      filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    });
    this.usersForm = this.formService.createFormGroup([this.usersInput]);
  }

  onAddressAssign(): void {
    this.saveAdressAssignment();
    this.addresses = [];
    this.visible = false;
  }

  private getUsers(isRequired: boolean): void {
    if (this.visible && ArrayHelper.isAvailable(this.addresses)) {
      if (!this.isLoaded || isRequired) {
        this.isLoaded = true;
        this.addressesQueueService.getRetrievalUsers(this.addressIds, this.isSpecialHandling)
          .subscribe(users => {
            if (this.isUnassignEnabled) {
              ArrayHelper.addItemToSelect(users, "Unassigned", 0);
            }
            if (this.isCurrentUserAvailable(users)) {
              ArrayHelper.addItemToSelect(users, "@Me", this.authService.userId);
            }
            this.usersInput = new Autocomplete({ ...this.usersInput, options: users } as any);
            this.changeDetector.markForCheck();
          });
      }
    }
  }

  private isCurrentUserAvailable(users: SelectableInput[]): boolean {
    return ArrayHelper.isAvailable(users.filter(user => user.value === this.authService.userId));
  }

  private saveAdressAssignment(): void {
    const selectedUser = this.selectedUser.text;
    this.addressesQueueService.assignAddressesToUser(this.addressIds, this.selectedUserId)
      .subscribe(() => {
        if (StringHelper.isAvailable(selectedUser)) {
          this.addressDetailStateService.setData({
            assignedTo: selectedUser,
          });
        }
        this.messagingService.showToast("Assigned Successfully.", SeverityType.SUCCESS);
        this.changeDetector.markForCheck();
        this.onUpdate.emit();
      });
  }

  private closing(): void {
    this.onHide.emit();
    this.selectedUser = null;
  }

}
