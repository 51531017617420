export class DocumentIntakeStats {

  constructor(
    readonly totalDocuments: number,
    readonly assigned: number,
    readonly unAssigned: number,
    readonly active: number,
    readonly onHold: number,
    readonly removed: number,
    readonly completed: number,
    readonly processing: number

  ) {

    this.totalDocuments = totalDocuments;
    this.assigned = assigned;
    this.unAssigned = unAssigned;
    this.active = active;
    this.onHold = onHold;
    this.removed = removed;
    this.completed = completed;
    this.processing = processing;

  }
}
