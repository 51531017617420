import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { LocalService } from "../../../../../../core/storage/local.service";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Switch } from "../../../../../../dynamic-forms/inputs/switch/switch.model";
import { BulkAction } from "../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { DocumentPage } from "../../../../retrieval/retreival-document-review/document-page.model";
import { RetrievalDocumentServiceService } from "../../../../retrieval/retrieval-document-service.service";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { MoveBackChasePageItem } from "../../../move-back-chase-page-item-model";
import { MoveBackChasePageType } from "../../../move-back-chase-page-type.enum";
import { DiagnosisService } from "../../chase-detail-v2-chart/risk/risk-encounter/diagnosis/diagnosis.service";
import { DocumentViewerSessionService } from "../../document-viewer-session.service";

@Component({
  selector: "app-chase-detail-v2-chart-viewer-header",
  templateUrl: "./chase-detail-v2-chart-viewer-header.component.html",
  styleUrls: ["./chase-detail-v2-chart-viewer-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2ChartViewerHeaderComponent implements OnInit, OnDestroy {

  memberTabSelected = false;
  encounterTabSelected = false;
  submitTabSelected = false;
  totalPageNumbers = 0;
  icdCode: string;
  diseaseName: string;
  modelDisplayName: string;
  dosDate: any;
  isDiagnosisSelected = false;
  submitEncounterSelected = false;
  submitDiagnosisSelected = false;
  encounterStartDate: any;
  encounterEndDate: any;
  providerName: string;
  moveBackDocumentEnabled: Switch;
  formGroup: FormGroup;
  selectedItems: MoveBackChasePageItem[] = [];
  showChartHeaderFilter = false;
  moveBackChasePageTypeOptions: BulkAction[] = [];
  selectedItemsThumbnail: any;
  isExpandedView = false;
  isExpandedLabelView = false;

  private sink = new SubSink();
  private dateFormat = "MM/dd/yyyy";
  private pipe = new DatePipe("en-US");
  private localStorageIsWindowOpen = "isWindowOpen";
  private isProjectEnabledForRiskNlp = false;
  private isOverread = false;
  private isOverread2 = false;
  private allMemberFilters: any = [];
  private allEncounterFilters: any = [];
  private allDiagnosisFilters: any = [];

  get isWindowOpen(): any {
    return this.localService.get(this.localStorageIsWindowOpen, null) === "1";
  }
  @Output() selectedFilters = new EventEmitter<any>();
  @Output() onSelectionthumbnailEvent = new EventEmitter<any>();
  @Output() isMoveBackDocumentToggleEnabled = new EventEmitter<any>();
  @Output() inititateProcessForMoveBackChasePages = new EventEmitter<any>();
  @Input() documentPageIds: number[] = [];
  @Input() allEvidencesDocumentPageIds: number[] = [];
  @Input() isSplitScreenMainTab = false;
  @Input() selectedDiagnosisFilterButton: boolean[] = [];
  @ViewChild("allFilters") clickedfilter: ElementRef;

  filterLinks: any = [
    {
      diagnosis: ["Diagnosis", "Signs", "Symptoms", "Medications", "No Evidence"],
    },
    {
      member: ["Member Name", "DOB", "Gender", "No Evidence"],
    },
    {
      encounter: ["DOS", "Provider", "No Evidence"],
    },
  ];

  @Input()
  set currentTabSelected(val: string) {
    this.memberTabSelected = val === "member";
    this.encounterTabSelected = val === "encounters";
    this.submitTabSelected = val === "submit";

    if (this.memberTabSelected) {
      this.submitEncounterSelected = false;
    }
  }

  @Input()
  set selectedThumbnailData(thumbnail: DocumentPage) {
    this.selectedItemsThumbnail = thumbnail;
  }
  @Input()
  set expandedView(value: any) {
    this.isExpandedView = value;
  }

  @Input()
  set expandedLabelView(value: any) {
    this.isExpandedLabelView = value;
  }

  @Input()
  set resetMoveBackDocumentToggleSwitch(event) {
    if (!event) {
      if (this.moveBackDocumentEnabled) {
        this.formGroup.get(this.moveBackDocumentEnabled.key).setValue(false);
      }
      this.selectedItems = [];
      this.showChartHeaderFilter = false;
      this.isMoveBackDocumentToggleEnabled.emit(false);
    }
    this.onChangeMoveBackDocumentEnabled(event);
  }
  get chaseTitle(): any {
    if (this.memberTabSelected) {
      return "Member Validation";
    }
    if (this.encounterTabSelected) {
      return this.isDiagnosisSelected;
    }
    if (this.submitTabSelected) {
      return "E08 Diabetes mellitus due to underlying condition";
    }
  }

  get pageCount(): number {
    const count = this.totalPageNumbers;
    return count;
  }

  get showThumbnailFilters(): boolean {
    return !(this.isOverread || this.isOverread2) && this.isProjectEnabledForRiskNlp && this.isExpandedLabelView;
  }

  constructor(
    private renderer: Renderer2,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly diagnosisService: DiagnosisService,
    private retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private formService: FormService,
    private localService: LocalService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.chasePagesMoveBackReasons();

    this.sink.add(
      this.diagnosisService.isDiagnosisSelected$.subscribe(isOpen => {
        this.isDiagnosisSelected = isOpen;
        this.changeDetector.markForCheck();
      }),

      this.chaseDetailStateService.state
        .pipe(
          map(state => {
            this.isProjectEnabledForRiskNlp = state.isProjectEnabledForRiskNlp;
            this.isOverread = state.isOverread;
            this.isOverread2 = state.isOverread2;
          }))
        .subscribe(),

      this.chaseDetailStateService.isEncounterSelected$.subscribe(data => {
        const startDate = this.pipe.transform(data?.startDate, this.dateFormat);
        const endDate = this.pipe.transform(data?.endDate, this.dateFormat);
        this.encounterStartDate = startDate;
        this.encounterEndDate = endDate;
        this.changeDetector.markForCheck();
      }),

      this.chaseDetailStateService.encounterDetails.subscribe(data => {
        const dosDate = DateHelper.isAvailable(data?.dos) ? this.pipe.transform(data?.dos, this.dateFormat) : "";

        this.icdCode = data?.icdCode;
        this.diseaseName = data?.diseaseName;
        this.modelDisplayName = data?.modelDisplayName;
        this.dosDate = dosDate;
        this.providerName = data?.providerName;
        this.submitDiagnosisSelected = false;
        this.changeDetector?.markForCheck();
      }),

      this.chaseDetailStateService.isDataEntryClicked$.subscribe(_ => {
        if (this.allMemberFilters.indexOf("No Evidence") > - 1) {
          const index = this.allMemberFilters.indexOf("No Evidence");
          this.allMemberFilters.splice(index, 1);
        }
        if (this.allEncounterFilters.indexOf("No Evidence") > - 1) {
          const index = this.allEncounterFilters.indexOf("No Evidence");
          this.allEncounterFilters.splice(index, 1);
        }
        if (this.allDiagnosisFilters.indexOf("No Evidence") > - 1) {
          const index = this.allDiagnosisFilters.indexOf("No Evidence");
          this.allDiagnosisFilters.splice(index, 1);
        }
        this.changeDetector.markForCheck();
      }),

      this.chaseDetailStateService.submitTabIsEncounterSelected.subscribe(data => {
        if (data) {
          this.submitEncounterSelected = data?.submitEncounterSelected;
          this.encounterStartDate = data?.startDate;
          this.encounterEndDate = data?.endDate;
          this.changeDetector.markForCheck();
        }
      }),

      this.chaseDetailStateService.submitTabIsDiagnosisSelected.subscribe(data => {
        if (data) {
          this.diseaseName = data?.diseaseName;
          this.icdCode = data?.icdCode;
          this.dosDate = data?.dos;
          this.providerName = data?.providerName;
          this.submitDiagnosisSelected = data?.submitDiagnosisSelected;
          this.changeDetector.markForCheck();
        }
      }),

      this.chaseDetailStateService.encounterCoderDxDetails.pipe(filter(data => data && data.dosStartDate), map(data => {
        const dosDate = this.pipe.transform(data?.dosStartDate, this.dateFormat);
        this.dosDate = dosDate;
        this.diseaseName = data?.diseaseName;
        this.icdCode = data?.icdCode;
        this.providerName = data?.providerName;
        this.changeDetector.markForCheck();
      })
      ).subscribe(),

      this.retrievalDocumentService.totalDocumentPages.subscribe(data => {
        this.totalPageNumbers = data;
        this.changeDetector?.markForCheck();
      }),

      this.chaseDetailStateService.resetMoveBackToggle.subscribe(toggleSwitch => {
        if (!toggleSwitch) {
          this.formGroup.get(this.moveBackDocumentEnabled.key).setValue(false);
          this.selectedItems = [];
          this.showChartHeaderFilter = false;
          this.isMoveBackDocumentToggleEnabled.emit(false);
        }
        this.changeDetector.markForCheck();
      }),

      this.chaseDetailStateService.autoSelectFilterButton$.subscribe(data => {
        if (data && ArrayHelper.isAvailable(data)) {
          this.allDiagnosisFilters = [];
          this.allMemberFilters = [];
          this.allEncounterFilters = [];

          if (data.every(value => !value)) {
            this.getSelectedFilters("No Evidence");
            return;
          }

          data.forEach((val, index) => {
            // TODO: Refactor this method
            if (val) {
              switch (index) {
                case 0: {
                  this.getSelectedFilters("Diagnosis");
                  break;
                }
                case 1: {
                  this.getSelectedFilters("Signs");
                  break;
                }
                case 2: {
                  this.getSelectedFilters("Symptoms");
                  break;
                }
                case 3: {
                  this.getSelectedFilters("Medications");
                  break;
                }
                case 4: {
                  this.getSelectedFilters("DOS");
                  break;
                }
                case 5: {
                  this.getSelectedFilters("Provider");
                  break;
                }
                case 6: {
                  this.getSelectedFilters("Member Name");
                  break;
                }
                case 7: {
                  this.getSelectedFilters("DOB");
                  break;
                }
                case 8: {
                  this.getSelectedFilters("Gender");
                  break;
                }
                case 9: {
                  this.getSelectedFilters("No Evidence");
                  break;
                }
                default:
                  break;
              }
            }
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  getICDCode(code: string): string {
    const specificityDelimiter = ".";
    if (StringHelper.isAvailable(code) && RegExHelper.isValidICDCodePattern.test(code)) {
      return `${code.substring(0, 3)}${specificityDelimiter}${code.substring(3)}`;
    }
    return code;
  }

  trackByIndex(index, item) {
    return index;
  }

  private getSelectedFilters(filteredData: any): any[] {
    if (this.memberTabSelected) {
      return this.memberFilterSelection(filteredData);
    }

    if (this.isDiagnosisSelected) {
      return this.diagnosisFilterSelection(filteredData);
    }

    if (this.encounterTabSelected || this.submitTabSelected) {
      return this.encounterFilterSelection(filteredData);
    }
  }

  private memberFilterSelection(filteredData): any[] {
    this.allMemberFilters = this.filterSelection(this.allMemberFilters, filteredData);

    if (this.clickedfilter) {
      this.unselectFilters();
      this.autoSelectFilters(this.allMemberFilters);
    }

    return this.allMemberFilters;
  }

  private encounterFilterSelection(filteredData): any[] {
    this.allEncounterFilters = this.filterSelection(this.allEncounterFilters, filteredData);

    if (this.clickedfilter) {
      this.unselectFilters();
      this.autoSelectFilters(this.allEncounterFilters);
    }

    return this.allEncounterFilters;
  }

  private diagnosisFilterSelection(filteredData): any[] {
    this.allDiagnosisFilters = this.filterSelection(this.allDiagnosisFilters, filteredData);

    if (this.clickedfilter) {
      this.unselectFilters();
      this.autoSelectFilters(this.allDiagnosisFilters);
    }

    return this.allDiagnosisFilters;
  }

  private filterSelection(filtersCollection: any[], filteredData: string): any[] {
    // TODO: this function was extracted with the original logic to avoid duplicated code. Consider refactor it in the future
    if (filtersCollection.includes(filteredData)) {
      if (filtersCollection.indexOf(filteredData) > -1) {
        const index = filtersCollection.indexOf(filteredData);
        filtersCollection.splice(index, 1);
      }

    } else {
      filtersCollection.push(filteredData);
    }

    if (filtersCollection.length === 0) {
      filtersCollection.push(filteredData);
    }

    return filtersCollection;
  }

  private unselectFilters(): void {
    // TODO: this function was extracted with the original logic to avoid duplicated code. Consider refactor it in the future
    for (const selectedFilter of this.clickedfilter.nativeElement.children) {
      this.renderer.removeClass(selectedFilter, "active");
      this.renderer.removeStyle(selectedFilter, "background");
    }
  }

  private autoSelectFilters(filtersCollection: any[]): void {
    // TODO: this function was extracted with the original logic to avoid duplicated code. Consider refactor it in the future
    filtersCollection.forEach(element => {
      const htmlElements = [...this.clickedfilter.nativeElement.children];
      const selectedFilter = htmlElements.find(htmlElement => htmlElement.innerText === element);
      if (selectedFilter) {
        this.setFilterActiveState(element, selectedFilter);
      }
    });
  }

  private setFilterActiveState(element: any, selectedFilter: any): void {
    this.renderer.addClass(selectedFilter, "active");
    switch (element) {
      case "Member Name":
      case "Diagnosis":
      case "DOS":
        this.renderer.setStyle(selectedFilter, "background", "#E7F4E2");
        break;

      case "DOB":
      case "Signs":
      case "Provider":
        this.renderer.setStyle(selectedFilter, "background", "#E1F5FC");
        break;

      case "Gender":
      case "Medications":
        this.renderer.setStyle(selectedFilter, "background", "#E6E5FF");
        break;

      case "Symptoms":
        this.renderer.setStyle(selectedFilter, "background", "#F4E3E4");
        break;

      case "No Evidence":
        this.renderer.setStyle(selectedFilter, "background", "#c7c7c7");
        break;

      default:
        break;
    }
  }

  selectFilter(filteredData: string): any {
    // TODO: Refactor this method, as it contains the main logic for the filters selection
    const filters = this.getSelectedFilters(filteredData);
    if (filters?.includes("No Evidence")) {
      this.documentViewerSessionService.setNoEvidenceSelected(true);
    } else {
      this.documentViewerSessionService.setNoEvidenceSelected(false);
    }

    this.chaseDetailStateService.isFilterButtonSelected.next(true);
    this.chaseDetailStateService.selectedFilters.next(filters);
  }

  initializeForm(): void {

    this.moveBackDocumentEnabled = new Switch({
      key: "moveBackDocumentEnabled",
    });

    this.formGroup = this.formService.createFormGroup([this.moveBackDocumentEnabled]);
  }

  onChangeMoveBackDocumentEnabled(event: any): void {
    if (event.checked) {
      this.moveBackDocumentEnabled = new Switch({ ...this.moveBackDocumentEnabled, followingOnLabel: "" });
      this.selectedItems = [];
      this.showChartHeaderFilter = true;
      this.chaseDetailStateService.resetMoveBackToggleFromHeader.next(event);
    } else {
      this.moveBackDocumentEnabled = new Switch({ ...this.moveBackDocumentEnabled, followingOnLabel: "" });
      this.showChartHeaderFilter = false;
      this.chaseDetailStateService.resetMoveBackToggleFromHeader.next(event);
    }
  }

  chasePagesMoveBackReasons(): void {
    for (const element of Object.keys(MoveBackChasePageType)) {
      if (!isNaN(Number(element))) {
        const x = String(MoveBackChasePageType[element]);
        const reasonType = x.replace(/([a-z])([A-Z])/g, "$1 $2");

        this.moveBackChasePageTypeOptions.push(new BulkAction
          ({
            name: reasonType,
            action: this.inititateMoveBackChasePages.bind(this),
          }));
      }
    }
  }

  onSelectionChange(event: any): void {
    this.onSelectionthumbnailEvent.emit(event);
  }

  inititateMoveBackChasePages(): void {
    this.chaseDetailStateService.inititateProcessForMoveBackChasePages.next(true);
  }
}
