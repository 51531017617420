<app-detail-header-template>
  <div top-left>
    <div class="container-back-button">
      <app-back-button [text]="backButtonText"></app-back-button>
    </div>
    <h3 class="container-ftId" (click)="navigateNewDesign()">
      {{addressText | uppercase}}
    </h3>
  </div>
  <div top-right>
    <div class="ft-item bold"> {{ftLocationText | uppercase}}</div>
    <div class="ft-retrieval-type bold">
      FT <app-icon-stack iconName="male" iconBase="circle"></app-icon-stack>
    </div>
  </div>
  <div bottom-left>
    <app-sub-menu [menuItems]="menuItems"></app-sub-menu>
  </div>
  <div bottom-right>
    <app-button class="header-button" text="EDIT ADDRESS" (onClick)="showEditFtModal()"></app-button>
    <retrieval-ft-detail-edit [isCreateVisible]="isFtEditView" (formClose)="receiveFtEditValue($event)"></retrieval-ft-detail-edit>

    <p-splitButton class="spilt-button-class" label="CHANGE RETRIEVAL METHOD" [model]="assignmentItems" menuStyleClass="menuPosRight"></p-splitButton>
    <retrieval-address-assignment [documentSourceId]="ftId" [chases]=chaseList [isCreateVisible]="isAddressAssignmentView" (formClose)="receiveAddressAssignmentValue($event)"></retrieval-address-assignment>
    <retrieval-emr-assignment [documentSourceId]="ftId" [chases]=chaseList [isCreateVisible]="isEmrAssignmentView" (formClose)="receiveEmrAssignmentValue($event)"></retrieval-emr-assignment>
    <retrieval-ft-assignment [documentSourceId]="ftId" [chases]=chaseList [isCreateVisible]="isFTAssignmentView" (formClose)="receiveFTAssignmentValue($event)"></retrieval-ft-assignment>

    <app-button class="header-button" text="NEXT ADDRESS" [ngClass]="isFinishCall ? 'show-call-button':'hide-call-button'" (onClick)="getNextAddress()"></app-button>
    <app-button class="header-button" text="CALL {{phoneNumber | phoneNumber}}" [ngClass]="!isFinishCall ? 'show-call-button':'hide-call-button'" (onClick)="showCallPopover($event)" #call></app-button>

    <app-popover #callPopOver [isPinned]="true">
      <app-iframe [visible]="display" [load]="display" iframeSrc="{{ twiliourl }}" iframeWidth="{{ twilioWidth }}" iframeHeight="{{ twilioHeight }}"></app-iframe>
      <app-button text="Hangup" (onClick)="endCallOnHangup()"></app-button>
    </app-popover>

  </div>
</app-detail-header-template>
