import { IAutoMapper } from "../interfaces";

export const mapMemberWithChasesItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberWithChases")
    .forMember("chases", o => o.chases)
    .forMember("dob", o => o.memberDateOfBirth)
    .forMember("gender", o => o.memberGender)
    .forMember("id", o => o.memberId)
    .forMember("name", o => o.memberName)
    .forMember("projectCount", o => o.projectCount)
    .forMember("chaseList", o => o.chaseList)
    .forMember("isVerified", o => o.isVerified);
};
