
export class AddressesQueueListItem {
  readonly srNo: number;
  readonly addressId: number;
  readonly address: string;
  readonly chases: number;
  readonly type: string;
  readonly status: string;
  readonly client: string;
  readonly project: string;

  constructor(options: {
    srNo: number;
    addressId: number;
    address: string;
    chases: number;
    type: string;
    status: string;
    client: string;
    project: string;
  }) {
    this.srNo = options.srNo;
    this.addressId = options.addressId;
    this.address = options.address;
    this.chases = options.chases;
    this.type = options.type;
    this.status = options.status;
    this.client = options.client;
    this.project = options.project;
  }
}
