import { IAutoMapper } from "../../../core/automapper/interfaces";
import { DateHelper } from "../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DatesBetween } from "./dates-between/dates-between.model";

export const mapPpcDates = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PpcDates")
    .forMember("enrollmentType", o => o.enrollmentType)
    .forMember("enrollmentPlus42Days", o => DateHelper.removeTime(o.enrollmentPlus42Days))
    .forMember("prenatal", o => automapper.map("default", "DatesBetween", o.prenatal))
    .forMember("postpartum", o => automapper.map("default", "DatesBetween", o.postpartum))
    .forMember("prenatalDod", o => automapper.map("default", "DatesBetween", o.prenatalDod))
    .forMember("postpartumDod", o => automapper.map("default", "DatesBetween", o.postpartumDod))
    .forMember("prenatalEdd", o => automapper.map("default", "DatesBetween", o.prenatalEdd));

  automapper
    .createMap("PpcCalculatedFields", "PpcDates")
    .forMember("enrollmentType", o => o.enrollmentType)
    .forMember("enrollmentPlus42Days", o => DateHelper.removeTime(o.enrollmentDatePlus42Days))
    .forMember("prenatal", o => automapper.map("prenatal", "DatesBetween", o))
    .forMember("postpartum", o => automapper.map("postpartum", "DatesBetween", o))
    .forMember("prenatalDod", o => automapper.map("prenatalDod", "DatesBetween", o))
    .forMember("postpartumDod", o => automapper.map("postpartumDod", "DatesBetween", o))
    .forMember("prenatalEdd", o => automapper.map("prenatalEdd", "DatesBetween", o));
};


export class PpcDates {
  private possibleEnrollmentTypes = ["Late", "Early"];
  enrollmentType: string;
  enrollmentPlus42Days: Date;
  prenatal: DatesBetween;
  postpartum: DatesBetween;
  prenatalDod: DatesBetween;
  postpartumDod: DatesBetween;
  prenatalEdd: DatesBetween;


  constructor(options: {
    enrollmentType?: string;
    enrollmentPlus42Days?: string;
    prenatal?: DatesBetween;
    postpartum?: DatesBetween;
    prenatalDod?: DatesBetween;
    postpartumDod?: DatesBetween;
    prenatalEdd?: DatesBetween;
  } = {}) {
    this.enrollmentPlus42Days = new Date(options.enrollmentPlus42Days);
    this.prenatal = options.prenatal || new DatesBetween();
    this.postpartum = options.postpartum || new DatesBetween();
    this.prenatalDod = options.prenatalDod || new DatesBetween();
    this.postpartumDod = options.postpartumDod || new DatesBetween();
    this.prenatalEdd = options.prenatalEdd || new DatesBetween();

    if (StringHelper.isAvailable(options.enrollmentType)) {
      if (this.possibleEnrollmentTypes.some(a => a === options.enrollmentType)) {
        this.enrollmentType = options.enrollmentType;
      } else {
        throw new Error(`The enrollment type '${options.enrollmentType}' is not a valid type.`);
      }
    } else {
      this.enrollmentType = "";
    }
  }


  get hasEnrollmentPlus42Days(): boolean {
    return DateHelper.isAvailable(this.enrollmentPlus42Days);
  }

  get hasPrenatal(): boolean {
    return this.hasDatesBetweenObject(this.prenatal);
  }

  get hasPostpartum(): boolean {
    return this.hasDatesBetweenObject(this.postpartum);
  }

  get hasPrenatalDod(): boolean {
    return this.hasDatesBetweenObject(this.prenatalDod);
  }

  get hasPostpartumDod(): boolean {
    return this.hasDatesBetweenObject(this.postpartumDod);
  }

  get hasPrenatalEdd(): boolean {
    return this.hasDatesBetweenObject(this.prenatalEdd);
  }


  private hasDatesBetweenObject(value: DatesBetween): boolean {
    return value != null && (value.hasStartDate || value.hasEndDate);
  }
}
