import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { ListItem } from "../../../../../shared/list/list-item";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { PendListItem } from "../../internal-pends-listing/pend-list-item.model";
import { ProviderPacketItem } from "./provider-packet-item.model";
import { ProviderPacketResponseItem } from "./provider-packet-response-packet-item.model";

@Injectable()
export class InternalPendsDetailInfoService {
  reset = new Subject<any>();
  refresh = new Subject<any>();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getPendGdFromPath(): number {
    return this.route.snapshot.params.pendGd;
  }

  resetFunction(pendId: number) {

    this.reset.next(pendId);
  }

  refreshFunction() {

    this.refresh.next();
  }

  getSummaryItems(pendId): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}Pend?pendId=${pendId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ListItem"))
    );
  }

  getComments(pendId): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Pend/Comment?pendId=${pendId}&isOnlyLatest=true`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  getTimelineItems(pendId): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}Timeline/GetPendTimeline?pendId=${pendId}&maxRecords=5`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }

  saveComment(pendId, commentText: string) {
    const url = `${this.baseApiUrl}Pend/Comment?pendId=${pendId}&commentText=${encodeURIComponent(commentText)}`;

    return this.http.post(url, {})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  getPendId(pendListItem: PendListItem, isNext: boolean): Observable<number> {
    const url = `${this.baseApiUrl}Pend/GetPreviousNextPend?isNext=${isNext}`;


    return this.http.post(url, pendListItem).pipe(map((response: any) => response as number));
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an ErrorObservable with a user-facing error message
    return throwError(
      "Something bad happened; please try again later.");
  }

  printProviderPacket(providerPacketItem: ProviderPacketItem): Observable<string> {
    const url = `${this.baseApiUrl}pend/providerpacket`;

    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, providerPacketItem, { headers }).pipe(
      map((response: any) => response as string)
    );
  }

}
