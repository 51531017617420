import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { SessionService } from "../../core/storage/session.service";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-sso-redirect",
  templateUrl: "./sso-redirect.component.html",
  styleUrls: ["../sso/sso.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoRedirectComponent implements OnInit {

  constructor(
    private session: SessionService,
    private readonly authService: AuthService,
    private readonly msalService: MsalService
    ) { }

  ngOnInit(): void {
    if (this.session.hasSessionKey("ssoClientIdValidation")) {
      this.session.delete("ssoClientIdValidation");
      const email = this.session.get("ssoLoginEmail", "");
      if (StringHelper.isAvailable(email)) {
        const request = {
          scopes: ["user.read"],
          loginHint: email,
        };
        this.msalService.loginRedirect(request);
      } else {
        this.msalService.loginRedirect();
      }
    } else {
      this.msalService.instance.handleRedirectPromise().then(res => {
        if (res != null && res.account != null) {
          this.session.delete("ssoClientId");
          this.session.delete("ssoDirectoryIdUrl");
          this.session.delete("ssoLoginEmail");
          this.msalService.instance.setActiveAccount(res.account);

          const username = res.account.username;
          const password = res.account.idTokenClaims.aud;
          const idToken = res.idToken;
          this.authService.ssoLogin(username, password, idToken);
        }
      });
    }
  }

}
