<div class="dos">
  <div class="dos__header">Dates of Service</div>

  <div class="dos__encounters">
    <div class="dos__encounters__validation-entitites"
         *ngFor="let item of dosValidatonControls; let i=index; trackBy: trackByIndex"
         [formGroup]="item">
      <div class="dos__encounters__validation-entitites__provider">{{item.get('providerName').value}}</div>
      <div class="dos__encounters__validation-entitites__item">
        <div class="dos__encounters__validation-entitites__item--label">{{item.get('datesOfServiceRange').value}}</div>
        <div class="dos__encounters__validation-entitites__item--value">
          <app-dynamic-entity-validation [isValidInfo]="item.get('encounterFound').value"
                                         (onSelect)="handleChangeEncounterFound($event, i)"></app-dynamic-entity-validation>
        </div>
      </div>
      <div class="dos__encounters__validation-entitites__item">
        <div class="dos__encounters__validation-entitites__item--label">Provider Signature Present</div>
        <div class="dos__encounters__validation-entitites__item--value">
          <app-dynamic-entity-validation [isValidInfo]="item.get('providerSignaturePresent').value"
                                         (onSelect)="handleChangeProviderSignaturePresent($event, i)"
                                         [isValidationEnabled]="item.get('encounterFound').value"></app-dynamic-entity-validation>
        </div>
      </div>
      <div class="dos__encounters__validation-entitites__item--page">
        <div>
          <label class="dos__encounters__validation-entitites__item--page--label">Page Number</label>
        </div>
        <div class="dos__encounters__validation-entitites__item--value">
          <input type="text"
                 size="10"
                 pInputText
                 formControlName="chartPageNumber"
                 placeholder="Page"
                 (blur)="handleChangeChartPageNumber(i)">
        </div>
      </div>
    </div>
  </div>
</div>

