import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ClinicalTerm } from "./clinical-term.model";
import { ClinicalTermsService } from "./clinical-terms.service";

@Component({
  selector: "member-risk-eve-clinical-terms",
  templateUrl: "./eve-clinical-terms.component.html",
  styleUrls: ["./eve-clinical-terms.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EveClinicalTermsComponent {
  @Input() clinicalTerms: ClinicalTerm[];
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  constructor(private clinicalTermsService: ClinicalTermsService) { }

  get totalDiagnoses() {
    return this.clinicalTerms ? this.clinicalTerms.length : 0;
  }

  updatePage(pageNumber: number, terms: ClinicalTerm): void {
    this.pageChange.emit(pageNumber);
    this.clinicalTermsService.updatePagesDocumentIdFilter(terms.documentPageIds);
    this.clinicalTermsService.updatePagesFilter(terms.pageNumbers);
  }

  trackByIndex(index) {
    return index;
  }
}
