import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { List } from "immutable";
import { CommentItem } from "../comment-item/comment-item";
import { EntityCommentsService } from "../comments.service";

@Component({
  selector: "app-grid-comments",
  templateUrl: "./grid-comments.component.html",
  styleUrls: ["./grid-comments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCommentsComponent {
  @Input() entityId: number;
  @Input() entityType: string;
  @Input() isCommentPresent = false;
  commentItems = List<CommentItem>();
  isCommentsModalVisible = false;

  constructor(
    private readonly commentsService: EntityCommentsService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  private assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  viewAllEntityComments(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.commentsService
      .getEntityComments(this.entityId, this.entityType)
      .subscribe(items => {
        this.commentItems = this.assignAndNotify(items);
        this.isCommentsModalVisible = true;

      });
  }

  get commentsLinkText(): string {
    return this.isCommentPresent ? "View Comments" : "";
  }

}
