export class ClinicalMeasureListItem {
  readonly measureId: number;
  readonly measureCode: string;
  readonly measureName: string;

  constructor(options: {
    measureId: number;
    measureCode: string;
    measureName: string;
  }) {
    this.measureId = options.measureId;
    this.measureCode = options.measureCode;
    this.measureName = options.measureName;
  }
}
