<td>
    <div class="dx-grid__table--checkbox">
        <form-factory [formGroup]="diagnosisForm" class="dx-grid__table--checkbox--alignment"
            [model]="diagnosisInput"></form-factory>
    </div>
</td>
<td class="ellipsis">
    <div class="dx-grid__table">
        <form-factory (onBlur)="validateDate()" [formGroup]="diagnosisForm" class="provider_dropdown diagnosisInput"
            [model]="startDateInput"></form-factory>
    </div>
</td>
<td>
    <div class="dx-grid__table--icdHolde">
        <form-factory class="diagnosis-save-group provider_dropdown diagnosisInput" [formGroup]="diagnosisForm"
            [model]="icdCodeInput"></form-factory>
    </div>
</td>
<td class="ellipsis">
    <form-factory [formGroup]="diagnosisForm" [model]="providerInput" class="provider_dropdown"></form-factory>
</td>
<td>
    <div class="dx-grid__table dx-grid__table--button">
        <form-factory [formGroup]="diagnosisForm" class="provider_dropdown diagnosisInput" [model]="pageNumberInput"></form-factory>
        <app-button class="trash-button" icon="trash" styleType="far" (onClick)="hideForm()"></app-button>

        <app-button class="dx-grid__table--pageNumber--saveButton" [disabled]="!isFormValid" title="Save"
            [disabled]="!isFormValid" (onClick)="saveNewDiagnosis()" text="Save"></app-button>
    </div>
</td>