import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-client-overread2",
  templateUrl: "./client-overread2.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOverread2Component {
  filters: string[];
  dashboardType = LookerRiskDashboardType.CLIENT_OVERREAD2_REPORT;

  constructor() {
    this.filters = ["Projects", "Overread2Date", "Products", "LOB", "RetrievalOwner"];
  }

}
