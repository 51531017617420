export class ChaseMoveModel {
    readonly chaseIds: string;
    readonly toAddressId: string;
    readonly fromAddressId: string;
    readonly pageType: string;
    readonly assignedUser: string;
    readonly status: string;
    readonly notes: string;
    readonly isChaseAssign?: boolean;

    constructor(options: {
        chaseIds: string;
        toAddressId: string;
        fromAddressId: string;
        pageType: string;
        assignedUser: string;
        status: string;
        notes: string;
        isChaseAssign?: boolean;

    }) {
        this.chaseIds = options.chaseIds;
        this.toAddressId = options.toAddressId;
        this.fromAddressId = options.fromAddressId;
        this.pageType = options.pageType;
        this.assignedUser = options.assignedUser;
        this.status = options.status;
        this.notes = options.notes;
        this.isChaseAssign = options.isChaseAssign;
    }
}
