export class MyJobsRequest {
  userId: number;
  stateMachineId?: number;
  statusId?: number;
  sortDirection?: string;
  sortBy?: string;
  pageSize?: number;
  pageNumber?: number;


  constructor(options: {
    userId?: number;
    stateMachineId?: number;
    statusId?: number;
    sortDirection?: string;
    sortBy?: string;
    pageSize?: number;
    pageNumber?: number;

  } = {}) {

    this.userId = options.userId;
    this.stateMachineId = options.stateMachineId;
    this.statusId = options.statusId;
    this.sortDirection = options.sortDirection;
    this.sortBy = options.sortBy;
    this.pageSize = options.pageSize;
    this.pageNumber = options.pageNumber;


  }
}
