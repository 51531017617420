import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-persistency-report",
  templateUrl: "./persistency-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersistencyReportComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.PERSISTENCY_REPORT;

  constructor() {
    this.filters = ["Projects", "PaymentYear", "RafCalcSource"];
  }
}
