import { StringHelper } from "../../../utilities/contracts/string-helper";
import { ILazyLoadEvent } from "./api";
import { IFilterMetadata } from "./filter-metadata.interface";

export class GridLoadEvent implements ILazyLoadEvent {
  startPage?: number;
  endPage?: number;
  sortField?: string;
  sortDirection?: string;
  filters?: { [s: string]: IFilterMetadata };

  constructor(
    lazyLoadEvent: ILazyLoadEvent
  ) {
    this.startPage = lazyLoadEvent.first + 1;
    this.endPage = this.startPage + lazyLoadEvent.rows;
    this.sortField = StringHelper.clean(lazyLoadEvent.sortField);
    this.sortDirection = lazyLoadEvent.sortOrder === 1 ? "asc" : "desc";
    this.filters = lazyLoadEvent.filters;
  }
}
