import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { FormService } from "../../dynamic-forms/form.service";
import { Dropdown } from "../../dynamic-forms/inputs/dropdown/dropdown.model";
import { ProviderService } from "../../platform/api/provider/provider.service";
import { ProviderPacketItem } from "../../platform/modules/internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { CoverLetterTemplateService } from "../../platform/modules/retrieval/cover-letter-template/cover-letter-template.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { ModalComponent } from "../panel/modal/modal.component";

@Component({
  selector: "app-print-provider-packet-modal",
  templateUrl: "./print-provider-packet.component.html",
  styleUrls: ["./print-provider-packet.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintProviderPacketComponent extends ModalComponent implements OnInit {
  @Input() providerPacketItem: ProviderPacketItem;
  @Input() gapType = "";

  @Output() onPrintComplete = new EventEmitter<boolean>();

  numberChasesToProcess = 500;
  selectedCoverLetterTemplate: string;

  formGroup: FormGroup;
  coverLetterTemplateInput: Dropdown;

  constructor(
    private formService: FormService,
    private coverLetterTemplateService: CoverLetterTemplateService,
    private providerService: ProviderService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { super(); }

  ngOnInit() {
    this.initializeForm();

    if (this.providerPacketItem == null) {
      this.providerPacketItem = {} as any;
    }
  }

  get totalChases(): number {
    return ArrayHelper.isAvailable(this.providerPacketItem.chaseIds) ? this.providerPacketItem.chaseIds.length : 0;
  }

  get isValid(): boolean {
    if (this.formGroup == null) {
      return false;
    }

    return this.formGroup.valid;
  }

  get showWarningMessage(): boolean {
    return NumberHelper.isGreaterThan(this.totalChases, this.numberChasesToProcess, true);
  }

  get subHeader(): string {
    return StringHelper.isAvailable(this.gapType)
      ? this.gapType.toLowerCase() === "gap loaded"
        ? "TOTAL GAP CHASES"
        : "TOTAL GAP PURSUITS"
      : "TOTAL CHASES";
  }

  async printProviderPacket(): Promise<void> {
    const chaseIdsToProcess = ArrayHelper.chunkArray(this.providerPacketItem.chaseIds, this.numberChasesToProcess);
    const chaseNumeratorsToProcess = ArrayHelper.chunkArray(this.providerPacketItem.chaseNumeratorList, this.numberChasesToProcess);
    this.onPrintComplete.emit();
    this.close();

    chaseIdsToProcess.forEach(async (item, index) => {
      const providerPacketItem: ProviderPacketItem = { ...this.providerPacketItem, chaseIds: item, templateName: this.selectedCoverLetterTemplate,
                                                       chaseNumeratorList: chaseNumeratorsToProcess[index] };
      const data = await this.providerService.printProviderPacket(providerPacketItem).toPromise();

      if (data != null) {
        this.providerService.downloadPdf(data);
        this.formService.updateDom.next();

      } else {
        this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
      }
    });
  }


  open(): void {
    this.getCoverLetterTemplateList();
    this.changeDetector.markForCheck();
  }

  close(): void {
    this.visible = false;
    this.onHide.emit();
    this.formGroup.reset();
    this.changeDetector.markForCheck();
  }

  onVisibleChange(visible: boolean): void {
    this.visibleChange.emit(visible);
  }

  onCoverLetterChange(event) {
    this.selectedCoverLetterTemplate = event.value;
  }

  private initializeForm(): void {
    this.coverLetterTemplateInput = new Dropdown({
      key: "coverLetterTemplate",
      label: "SELECT TEMPLATE",
      placeholder: "Select Cover Letter Body",
      validators: Validators.required,
      errorMessages: {
        required: "Cover letter template is required.",
      },
      appendTo: "body",
    });

    this.formGroup = this.formService.createFormGroup([this.coverLetterTemplateInput]);
  }

  private getCoverLetterTemplateList(): void {
    this.coverLetterTemplateService.getCoverLetterTemplate(this.gapType)
      .subscribe(templatelist => {
        this.coverLetterTemplateInput = new Dropdown({ ...this.coverLetterTemplateInput, options: templatelist } as any);
        this.changeDetector.markForCheck();
      });
  }
}
