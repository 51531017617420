import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { PanelModule } from "../panel/panel.module";
import { PendGridComponent } from "./pend-grid.component";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DynamicFormsModule,
    GridModule,
    PanelModule,
    MenuModule,
  ],
  declarations: [
    PendGridComponent,
  ],
  exports: [
    PendGridComponent,
  ],
})
export class PendGridModule { }
