import { DatePipe } from "@angular/common";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class PendDocumentItem {
  readonly pendDocumentId: number;
  readonly chasePendId: number;
  readonly description: string;
  readonly documentKey: string;
  readonly createUser: string;
  readonly createDate: DatePipe;
  readonly url: string;

  constructor(options: {
    pendDocumentId: number;
    chasePendId: number;
    description: string;
    documentKey: string;
    createUser: string;
    createDate: DatePipe;
    url: string;
  }) {
    this.pendDocumentId = options.pendDocumentId;
    this.chasePendId = options.chasePendId;
    this.description = StringHelper.clean(options.description);
    this.documentKey = StringHelper.clean(options.documentKey);
    this.createUser = StringHelper.clean(options.createUser);
    this.createDate = options.createDate;
    this.url = StringHelper.clean(options.url);
  }
}
