export class JobQueueUnRead {
  unReadCount: number;
  newCount: number;

  constructor(options: {
    unReadCount?: number;
    newCount?: number;

  } = {}) {

    this.unReadCount = options.unReadCount;
    this.newCount = options.newCount;

  }
}
