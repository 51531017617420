<div class="widget__buttons" [ngClass]="getClass(this.menuService.showHeader | async)">
  <ng-container *ngFor="let app of widgetApps; let i=index; trackBy: trackByIndex">
    <div *ngIf="app.show" class="widget__buttons--icon" [ngClass]="getActiveClass(app.name)" (click)="showPanel(app.name)">
      <app-icon iconStyle="fas" iconName="{{app.icon}}"></app-icon>
      <div class="widget__badge-annot-counter" *ngIf="showAnnotationsBadge(app.name)">{{annotationNumberToShow}}<span class="plus-sign" *ngIf="showPlusSign()">+</span></div>
      <span class="widget__buttons--icon--tooltip"> {{app.description}}</span>
    </div>
  </ng-container>
  <div *ngIf="isShowWidgetPanel" class="widget__buttons--closeIcon" (click)="closePanel()">
    <app-icon iconStyle="fal" iconType="fa" iconName="angle-right"></app-icon>
  </div>
</div>
<div class="widget__panel" *ngIf="isShowWidgetPanel">
  <div class="widget__panel--inner-wrapper" [ngClass]="getClass(this.menuService.showHeader | async)">
    <ng-container *ngIf="isDirectory">
      <platform-widget-user-chat [selectedPanel]="selectedPanel"></platform-widget-user-chat>
    </ng-container>
    <ng-container *ngIf="isChat">
      <platform-widget-user-chat [selectedPanel]="selectedPanel"></platform-widget-user-chat>
    </ng-container>
    <ng-container *ngIf="isAnnotation">
      <platform-widget-annotations (pageChange)="onPageChange($event)" [documentPageId]="documentPageId" [page]="page" [chaseId]="chaseId" [selectedPanel]="selectedPanel"></platform-widget-annotations>
    </ng-container>
  </div>
</div>