import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { FtAppointmentModel } from "./ft-appointment-model";
import { FtAppointmentSearchRequest } from "./ft-appointment-search-model";

@Injectable({
  providedIn: "root",
})
export class FtAppointmentService {

  constructor(@Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
              private http: HttpClient,
              private automapper: AutomapperService) { }

  ftAppointmentList(searchRequest: FtAppointmentSearchRequest): Observable<any> {
    const url = `${this.baseApiUrl}fieldTech/appointments`;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, searchRequest, { headers });

  }

}
