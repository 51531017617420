import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MessageService } from "primeng/api";
import { AutomapperService } from "./automapper/automapper.service";
import { createMappings } from "./automapper/create-mappings";
import { InterceptorList } from "./interceptor/interceptor-list";
import { LoaderComponent } from "./interceptor/loader/loader.component";
import { LoaderService } from "./interceptor/loader/loader.service";
import { MessagingService } from "./messaging/messaging.service";

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
  ],
  declarations: [LoaderComponent],
  providers: [
    MessageService,
    MessagingService,
    // TODO: Delete paths and link.service
    { provide: "PATHS", useValue: [
      "/",
      "/login",
      "/member/chase",
      "/member/chase/info",
      "/member/chase/comments",
      "/member/chase/documents",
    ]},
    InterceptorList,
    LoaderService,
  ],
  exports: [LoaderComponent],
})
export class CoreModule {
  constructor(private readonly automapper: AutomapperService) {
    createMappings(this.automapper);
  }
}
