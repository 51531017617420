import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { BulkOutreachService } from "./bulk-outreach.service";

@Component({
  selector: "app-bulk-outreach",
  templateUrl: "./bulk-outreach.component.html",
  styleUrls: ["./bulk-outreach.component.scss"],
  providers: [BulkOutreachService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOutreachComponent implements OnInit {

  actionTypeInput: Dropdown;
  faxNumberInput: Textbox;
  projectsInput: CheckboxGroup;
  formGroup: FormGroup;
  menuItems = List<MenuItem>();
  bulkActionType: SelectItem[];

  constructor(
    private service: BulkOutreachService,
    private changeDetector: ChangeDetectorRef,
    private formService: FormService
  ) {
    this.actionTypeInput = new Dropdown({
      key: "actionType",
      label: "Action Type",
      placeholder: "Select Action Type",
      options: [new SelectableInput({ text: "Fax", value: "1" })],
      value: "1",

    });
    this.faxNumberInput = new Textbox({
      key: "faxNumber",
      label: "Fax Number",
      placeholder: "Fax Number",
      validators: [Validators.required],
      errorMessages: {
        required: "Fax Number is required.",
      },
    });


    this.formGroup = this.formService.createFormGroup([
      this.actionTypeInput,
      this.faxNumberInput,
    ]);
  }

  ngOnInit() {
    this.service
      .getMenuItems()
      .subscribe(menutItems => {
        this.menuItems = List(menutItems);
        this.changeDetector.markForCheck();
      });

  }
}
