export enum GridPipeName {

  Currency = "currency",
  Date = "date",
  Decimal = "decimal",
  Json = "json",
  Lowercase = "lowercase",
  Uppercase = "uppercase",
  Percent = "percent",
  Phone = "phoneNumber",
  // TODO
  // Slice = "slice",
  // Async = "async"

}
