<ul [ngClass]="display">
  <li *ngFor="let item of documents; let i = index; trackBy: trackByIndex">
    <div class="document-list-image-container">
      <img class="mainimage" *ngIf="item.image" [src]="'data:image/jpg;base64,'+item.image" />
    </div>
    <div class="document-list-info-container">
      <div class="numberofpages">{{ item.numberOfPages }} Pages</div>
      <div class="bold filename">{{ item.fileName }}</div>

      <div>Retrieval Source : {{ item.retrievalTypeName }}</div>
      <div>Updated on {{ item.createDate | date: 'shortDate' }}</div>
      <div>Updated by {{ item.createBy }}</div>
    </div>
  </li>
</ul>
