import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { ChaseDetailInfoPursuitItemModel } from "./chase-detail-info-pursuit-item/chase-detail-info-pursuit-item-model";

@Component({
  selector: "member-chase-detail-info-pursuits",
  templateUrl: "./chase-detail-info-pursuits.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailInfoPursuitsComponent {
  @Input() pursuitItems: ChaseDetailInfoPursuitItemModel[] = [];

  constructor() { }

  get hasPursuitItems(): boolean {
    return ArrayHelper.isAvailable(this.pursuitItems);
  }

  trackByIndex(index) {
    return index;
  }

}
