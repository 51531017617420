import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapInvoiceChaseValidation = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "InvoiceChaseValidation")
    .forMember("chaseId", o => o.chaseId)
    .forMember("validationStatus", o => o.validationStatus)
    .forMember("message", o => o.message)
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("address", o => o.address)
    .forMember("invoiceType", o => o.invoiceType);
};
export class InvoiceChaseValidation {
  chaseId: number;
  validationStatus: string;
  message: string;
  memberFirstName: string;
  memberLastName: string;
  address: string;
  invoiceType: string;

  constructor(options: {
    chaseId?: number;
    validationStatus?: string;
    message?: string;
    memberFirstName?: string;
    memberLastName?: string;
    address?: string;
    invoiceType: string;
  }) {
    this.chaseId = options.chaseId;
    this.validationStatus = options.validationStatus;
    this.message = options.message;
    this.memberFirstName = options.memberFirstName;
    this.memberLastName = options.memberLastName;
    this.address = options.address;
    this.invoiceType = options.invoiceType;
  }
}
