import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DataSet } from "../../../../shared/kpi/model/data-set.model";
import { ListItem } from "../../../../shared/list/list-item";
import { AddressListItem } from "./address-list-item.model";
import { AddressSearchRequest } from "./address-search-request.model";

@Injectable({
  providedIn: "root",
})
export class RetrievalService {

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getStatisticsData(): Observable<ListItem[]> {
    const url = `${this.baseApiUrl}dashboard/statistics`;

    return this.http.get(url).pipe(map(this.automapper.curryMany("default", "ListItem")));
  }

  getOutboundCommunicationKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/outboundcommunication`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getAddressesCompletedKpi(): Observable<DataSet> {
    const url = `${this.baseApiUrl}dashboard/addresscompleted`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DataSet"))
    );
  }

  getNextAddressId(roleId: number): Observable<number> {
    const url = `${this.baseApiUrl}dashboard/retrieval/nextaddress?documentSourceTypeId=${roleId}`;
    return this.http.get(url, { observe: "response" }).pipe(map((response: HttpResponse<any>): number => response.ok ? response.body : 0));
  }

  addressSearch(addressSearchRequest: AddressSearchRequest): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/searchList`;

    return this.http.post(url, addressSearchRequest);

  }

}
