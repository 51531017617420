import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { WorkflowStatus } from "../../../api/workflow/worklow-status.enum";

@Injectable({
  providedIn: "root",
})
export class ClinicalQueueService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  updateClinicalQueueList(projectId: string): Observable<any> {
    const url = `${this.baseApiUrl}retrieval/addressqueuepriority?projectId=${projectId}&statusId=${WorkflowStatus.Overread2}`;
    return this.http.post(url, {});
  }
}
