import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-info-template",
  templateUrl: "./info-template.component.html",
  styleUrls: ["./info-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTemplateComponent {
}
