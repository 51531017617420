import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-user-productivity-dashboard",
  templateUrl: "./user-productivity-dashboard.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProductivityDashboardComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.USER_PRODUCTIVITY_DASHBOARD;
  hideFilters: string[] = [];

  constructor() {
    this.filters = ["HealthPlan", "ContractNumber", "Products", "LOB", "UserEmail", "Login", "UserStatus"];
    this.hideFilters = [
      HideFilters.ORG_ID,
      "So Name",
      "Login",
      "User Email",
      "Clinician Type",
      "User Type",
    ];
  }

}
