import { IAutoMapper } from "../interfaces";

export const mapPendDocumentItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PendDocumentItem")
    .forMember("pendDocumentId", o => o.pendDocumentId)
    .forMember("chasePendId", o => o.chasePendId)
    .forMember("description", o => o.description)
    .forMember("documentKey", o => o.documentKey)
    .forMember("createUser", o => o.createUser)
    .forMember("createDate", o => o.createDate)
    .forMember("url", o => o.url);
};
