import { BooleanHelper } from "../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

export interface IButtonAction {
  name?: string;
  key?: string;
  disabled?: boolean;
  action?(): void;
}

export class ButtonAction {
  name: string;
  key: string;
  action: () => void;
  disabled?: boolean;

  constructor(options: IButtonAction = {}) {
    this.name = StringHelper.clean(options.name);
    this.key = StringHelper.clean(options.key);
    this.action = options.action || (() => {});
    this.disabled = BooleanHelper.tryGet(options.disabled, false);
  }
}
