import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";

@Injectable()
export class ExternalUrlService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot): boolean {
      window.open(route.data.externalUrl, "_blank");

      return false;
  }
}
