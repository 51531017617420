import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class AuthorizationLetterAttribute {
  attributeName: string;
  attributeValue: string;

  constructor(options: {
    attributeName: string;
    attributeValue: string;
  }) {
    this.attributeName = StringHelper.clean(options.attributeName);
    this.attributeValue = StringHelper.clean(options.attributeValue);
  }

}
