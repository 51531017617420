import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { DocumentRequest } from "./document-request.model";

@Injectable({
  providedIn: "root",
})
export class DocumentRequestService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  create(data: DocumentRequest): Observable<number> {
    const url = `${this.baseApiUrl}documentrequest/create`;
    return this.http.post(url, data) as Observable<number>;
  }

  get(documentRequestModel: DocumentRequest): Observable<DocumentRequest[]> {
    const url = `${this.baseApiUrl}documentrequest/getDocumentRequests`;
    return this.http.post(url, documentRequestModel).pipe(
      map(this.automapper.curryMany("default", "DocumentRequest")));
  }

  getDocumentStatuses(view: number, action?: number): Observable<SelectableInput[]> {
        const url = `${this.baseApiUrl}document/status/list?view=${view}&actionValue=${action}`;
        return this.http.get(url).pipe(
            map(this.automapper.curryMany("default", "SelectableInput")));
  }

  getAssignToUserListForDocument(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}document/assignto/list`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("DropDownListItem", "SelectableInput"))
    );
  }

  getDocumentThumbnailFilter(chaseId: number): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}document/thumbnail/filters?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("ThumbnailFilter", "SelectableInput"))
    );
  }
}
