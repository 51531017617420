import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

export class RiskSystemResultKey {
  className?: string;
  resultKeyText?: string;

  constructor(options: Partial<RiskSystemResultKey> = {}) {
   this.className = StringHelper.clean(options.className);
   this.resultKeyText = StringHelper.clean(options.resultKeyText);
  }
}
