import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-iframe",
  template: `
    <iframe
      *ngIf="visible"
      [src]="source"
      [width]="iframeWidth"
      [height]="iframeHeight"
      frameborder="0"
      allowfullscreen
      allow="microphone">
      </iframe>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IframeComponent {
  @Input() iframeSrc: string;
  @Input() iframeWidth = "100%";
  @Input() iframeHeight = "100%";
  @Input() visible = false;
  @Input() load = true;

  constructor(
    private readonly domSanitizer: DomSanitizer
  ) { }


  get source(): SafeResourceUrl {
    const source = this.load ? this.iframeSrc : "";

    return this.domSanitizer.bypassSecurityTrustResourceUrl(source);
  }
}
