import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../../shared/comments/comment-item/comment-item";
import { URIFormatHelper } from "../../../../../../utilities/uri-format-helper";


@Injectable()
export class AddressDetailCommentsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getAddressGdFromPath(): string {
    return this.route.snapshot.parent.params.AddressGd;
  }

  getComments(): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${this.getAddressGdFromPath()}&isOnlyLatest=false`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveComment(commentText: string) {
    const url = `${this.baseApiUrl}Retrieval/Comment?documentSourceId=${this.getAddressGdFromPath()}&commentText=${URIFormatHelper.encodeParameters(commentText)}`;

    return this.http.post(url, {});

  }

}
