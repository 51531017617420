import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { NgModel } from "@angular/forms";
import { MenuItem } from "primeng";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { AnnotationType } from "../../../modules/data-load/annotationtype.enum";
import { IAnnotationEvent } from "../annotation-event.model";
import { Annotation } from "../annotation.model";

@Component({
  selector: "platform-widget-annotation-card",
  templateUrl: "./annotation-card.component.html",
  styleUrls: ["./annotation-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationCardComponent implements OnInit, AfterViewInit {

  @ViewChild("annotationText", { static: false }) textArea: ElementRef;

  @Input() annotation: Annotation;
  @Input() annotationRows = 1;
  @Input() highlightId = "";
  @Input() highlightMatches: Annotation[] = [];
  @Input() index: number;
  @Input() isReadOnly = true;
  @Input() isHeaderHidden = false;
  @Input() isAdding: boolean;
  @Input() editing: boolean;
  @Output() editingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() save: EventEmitter<IAnnotationEvent> = new EventEmitter();
  @Output() delete: EventEmitter<IAnnotationEvent> = new EventEmitter();
  @Output() cancel: EventEmitter<Boolean> = new EventEmitter();
  @Output() pageNumber: EventEmitter<number> = new EventEmitter();
  @Output() updateHighlightId: EventEmitter<string> = new EventEmitter();
  @Output() entityId: EventEmitter<number> = new EventEmitter();

  previousAnnotation: Annotation;
  menuItems: MenuItem[] = [];
  private readonly maxHeight = 228;

  constructor(
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.setMenuItems();
  }

  ngAfterViewInit(): void {
    this.updateTextAreaSize();
  }

  private updateTextAreaSize(height?: number): void {
    setTimeout(() => {
      const element = { target: { scrollHeight: height || this.textArea.nativeElement.scrollHeight } };
      this.resize(element);
    },         0);
  }

  private resetTextAreaSize(): void {
    this.annotationRows = 1;
    this.updateTextAreaSize(25);
    this.updateTextAreaSize();
  }

  private setMenuItems(): void {
    const editItem = { label: "EDIT", command: () => this.onEdit() };
    const deleteItem = { label: "DELETE", command: () => this.onDelete(this.annotation) };
    if (this.annotation.canEdit) {
      this.menuItems.push(editItem);
    }
    if (this.annotation.canDelete) {
      this.menuItems.push(deleteItem);
    }
    this.changeDetector?.markForCheck();
  }

  showMenu(): boolean {
    return (this.annotation.canDelete || this.annotation.canEdit) && this.isReadOnly && !this.editing && !this.isAdding;
  }

  isInvalid(textForm: NgModel, annotation: Annotation): boolean {
    const text = annotation.text?.trim();
    return (textForm.invalid || text?.length > 1000 || text?.length < 1) && (textForm.dirty || textForm.touched);
  }

  isValid(textForm: NgModel, annotation: Annotation): boolean {
    const text = annotation?.text?.trim();
    return textForm.valid && !textForm.pristine && text.length <= 1000 && text.length > 0;
  }

  onEdit(): void {
    this.annotationRows = 5;
    this.isReadOnly = false;
    this.editingChange.emit(true);
    this.previousAnnotation = JSON.parse(JSON.stringify(this.annotation));
    setTimeout(() => {
      this.updateTextAreaSize(Math.max(this.textArea.nativeElement.scrollHeight, 85));
      this.textArea.nativeElement.focus();
      this.changeDetector?.markForCheck();
    },         0);
  }

  onSubmit(textForm: NgModel, annotation: Annotation): void {
    if (this.isValid(textForm, annotation)) {
      this.annotation.text = this.annotation.text.trim();
      this.editingChange.emit(false);
      this.isReadOnly = true;
      textForm.reset(this.annotation.text);
      this.annotation.isEdited = true;
      const index = this.index;
      const previousAnnotation = this.previousAnnotation;
      this.save.emit({ annotation, index, previousAnnotation });
      this.resetTextAreaSize();
      this.changeDetector?.markForCheck();
    }
  }

  onCancel(textForm: NgModel): void {
    this.cancel.emit(true);
    this.editingChange.emit(false);
    textForm.reset(this.previousAnnotation?.text);
    this.isReadOnly = true;
    this.resetTextAreaSize();
    this.changeDetector?.markForCheck();
  }

  onDelete(annotation: Annotation): void {
    const index = this.index;
    this.delete.emit({ annotation, index });
    this.changeDetector?.markForCheck();
  }

  resize(element) {
    const offset = 5;
    this.renderer.setStyle(this.textArea.nativeElement, "height", "auto");
    if (element.target.scrollHeight - offset <= this.maxHeight) {
      this.renderer.setStyle(this.textArea.nativeElement, "height", `${element.target.scrollHeight}px`);
    } else {
      this.renderer.setStyle(this.textArea.nativeElement, "height", `${this.maxHeight}px`);
    }
    this.changeDetector?.markForCheck();
  }

  hasMaxHeight(height: number): boolean {
    return NumberHelper.isGreaterThan(height, this.maxHeight);
  }

  handleClick(): void {
    if (this.isReadOnly) {
      this.pageNumber.emit(this.annotation.pageNumber);
    }
    const isDataLoadAnnotation = (annotation: Annotation): boolean => {
      return (
      annotation.annotationTypeId === AnnotationType.ObjectByClient ||
      annotation.annotationTypeId === AnnotationType.ObjectByUser
      );
    };
    if (isDataLoadAnnotation(this.annotation) && NumberHelper.isAvailable(this.annotation?.entityId)) {
      this.entityId.emit(this.annotation.entityId);
    }
    this.updateHighlightId.emit(this.annotation.highlightId);
    this.changeDetector?.markForCheck();
  }

  addBorder(annotation: Annotation): boolean {
    if (!ArrayHelper.isAvailable(this.highlightMatches)) {
      return false;
    }
    if (this.highlightMatches.find(a => a?.entityId === annotation?.entityId)) {
      return true;
    }
    return false;
  }
}
