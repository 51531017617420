<div class="call-connecting-container" [ngClass]="isCallConnectingDisplay ? 'show-div':'hide-div'">

  <div class="call-header">
    CALL CONNECTION
  </div>

  <div class="address-detail-call-container">
    <div class="call-status">
      <h3>Call connecting...</h3>

      <p>Did you connect successfully?</p>
      <div class="call-result">
        <div class="call-result-item">
          <app-button text="NO, I COULDN'T CONNECT" (onClick)="saveCallResultType(2)"></app-button>
        </div>
        <div class="call-result-item">
          <app-button text="YES, CONTINUE THE CALL" (onClick)="saveCallResultType(1)"></app-button>
        </div>

      </div>

    </div>
  </div>

</div>

<div class="call-script-container" [ngClass]="isCallScriptDisplay ? 'show-div':'hide-div'">
  <div class="call-script-header">
    CALL SCRIPT
  </div>
  <div class="checkbox-row">
    <p-checkbox label={{providerValidatedLabel}} [(ngModel)]="providerValidatedChecked" binary="true"></p-checkbox>
    <!--<input type="checkbox" (change)="toggleEditable($event)" value="true" />-->
    <span class="text-link" [ngClass]="providerValidatedChecked ? 'show-text':'hide-text'" (click)="isProviderValidationDisplay=true;providerValidatedChecked=false;providerValidatedLabel='First, validate the details for the providers at this address: '">Click here to edit.</span>
  </div>

  <div class="provider-validation-section" [ngClass]="isProviderValidationDisplay ? 'show-div':'hide-div'">

    <div class="provider-grid">
      <app-basic-grid class="grids-display"
                      [configuration]="providerGridConfiguration"
                      [data]="providerGridData"
                      [(selection)]="providerGridSelection"
                      [actionTemplate]="providerGridActionTemplate">
        <ng-template #providerGridActionTemplate let-rowData let-localActionPopover="actionPopover">
          <app-button text="Edit This Provider" (onClick)="openProviderEditModal(rowData); localActionPopover.hide();"></app-button>
        </ng-template>
      </app-basic-grid>
    </div>

    <app-button class="header-button" text="THESE PROVIDER DETAILS ARE CORRECT" (onClick)="providerValidated()"></app-button>

  </div>

  <div class="checkbox-row">
    <p-checkbox label={{contactDetailsValidatedLabel}} [(ngModel)]="contactDetailsValidatedChecked" binary="true"></p-checkbox>
    <!--<input type="checkbox" (change)="toggleEditable($event)" value="true" />-->
    <span class="text-link" [ngClass]="contactDetailsValidatedChecked ? 'show-text':'hide-text'" (click)="isContactDetailsValidationDisplay=true;contactDetailsValidatedChecked=false;contactDetailsValidatedLabel='Validate the contact information for this address. '">Click here to edit.</span>
  </div>

  <div class="contact-details-validation-section" [ngClass]="isContactDetailsValidationDisplay ? 'show-div':'hide-div'">
    <form [formGroup]="formGroupContactDetails">

      <div class="ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="address1"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="address2"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="city"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div class="ui-g-6 ui-md-6 ui-lg-7">
            <form-factory [formGroup]="formGroupContactDetails" [model]="state"></form-factory>
          </div>
          <div class="ui-g-6 ui-md-6 ui-lg-5">
            <form-factory [formGroup]="formGroupContactDetails" [model]="zip"></form-factory>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="email"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="phone"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="fax"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupContactDetails" [model]="contactName"></form-factory>
        </div>
      </div>

    </form>

    <div class="contact-details-action-buttons">
      <app-button class="header-button" text="THESE CONTACT DETAILS ARE CORRECT" (onClick)="contactDetailsValidated()"></app-button>
      <app-button class="header-button" text="SAVE EDITS" (onClick)="onContactDetailsUpdate()"></app-button>
    </div>
  </div>

  <div class="checkbox-row">
    <p-checkbox label={{callNotesLabel}} [(ngModel)]="callNotesEnteredChecked" binary="true"></p-checkbox>
  </div>
</div>

<div class="call-notes-container" [ngClass]="displayCallNotes ? 'show-div':'hide-div'">
  <div class="leftColumn">

    <div class="call-script-header ">
      CALL NOTES
    </div>

    <form [formGroup]="formGroupCallNotes">

      <div class="ui-fluid">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div>
            <form-factory [formGroup]="formGroupCallNotes" [model]="selectedCallResultTypeInput" (onChange)="onSelectType($event)" [ngClass]="callType == 'incoming' ? 'hide-div':'show-div'"></form-factory>
            <div class="ellipsis" [ngClass]="displayCallResultTypeRequired ? 'show-div':'hide-div'">This field is required.</div>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupCallNotes" [model]="callNotes"></form-factory>
          <div class="ellipsis" *ngIf="isFieldInvalid('callNotes')">
            Call Notes is required and must be 1000 characters or less
          </div>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroupCallNotes" [model]="spokeTo"></form-factory>
          <div class="ellipsis" *ngIf="isFieldInvalid('spokeTo')">
            Spoke to is required and must be 100 characters or less
          </div>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <label class="label">Next Call Date and Time</label>
            <p-calendar appendTo="body"
                        [showIcon]=true
                        [readonlyInput]="true"
                        [hideOnDateTimeSelect]="true"
                        placeholder="Date" [showTime]="true"
                        [(ngModel)]="nextCallDate"
                        [ngModelOptions]="{standalone: true}"
                        [minDate]="minDate">
              <p-footer style="margin-left:45%">Time</p-footer>
            </p-calendar>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-6">
            <label class="label">Commitment Date</label>
            <p-calendar appendTo="body"
                        [showIcon]=true
                        [readonlyInput]="true"
                        [hideOnDateTimeSelect]="true"
                        placeholder="Date"
                        [(ngModel)]="commitDate"
                        [ngModelOptions]="{standalone: true}"
                        [minDate]="minDate">
            </p-calendar>
          </div>
        </div>
      </div>

    </form>
    <app-button class="header-button" text="SUBMIT CALL NOTES" (onClick)="saveSuccessfulCallNotes()"></app-button>
  </div>

  <div class="rightColumn timeline-display">
    <div class="header bold">Notes History</div>
    <app-timeline [timelineItems]="timelineItems"></app-timeline>
  </div>

</div>
<div class="call-not-connected-container" [ngClass]="displayCouldNotConnect ? 'show-div':'hide-div'">
  <div class="call-script-header ">
    COULDN'T CONNECT
  </div>
  <div class="ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <div>
        Please give a reason for why you couldn't complete this call.
        <div class="dropdown-unsuccessful">
          <div class="ellipsis" [ngClass]="displayCallResultTypeRequired ? 'show-div':'hide-div'">This field is required.</div>
          <form-factory [formGroup]="formGroupUnSuccessfulCallNotes" [model]="unSuccessfulResultTypesInput" (onChange)="onSelectCallUnsuccessFulType($event)"></form-factory>
        </div>
      </div>
      <app-button class="header-button" text="SUBMIT CALL NOTES" (onClick)="saveUnSuccessfulCallNotes()"></app-button>
    </div>
  </div>
</div>



