import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../../../auth/auth.service";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { InternalPendsDetailInfoService } from "../../internal-pends-detail-info/internal-pends-detail-info.service";
import { InternalPendsDetailDocumentsService } from "../internal-pends-detail-documents.service";

@Component({
  selector: "app-internal-pends-upload-document",
  templateUrl: "./internal-pends-upload-document.component.html",
  styleUrls: ["./internal-pends-upload-document.component.scss"],
})
export class InternalPendsUploadDocumentComponent {
  chasePendId: number;
  @Input() isUploadDocumentVisible = false;
  @Output() formClose = new EventEmitter();
  disableUpload = false;
  readonly descriptionText: Textarea;
  formGroup: FormGroup;
  uploadedFiles: File[] = [];

  @ViewChild("form", { static: true }) input;

  constructor(
    private readonly formService: FormService,
    private service: InternalPendsDetailDocumentsService,
    private readonly route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private messagingService: MessagingService,
    private internalPendsDetailInfoService: InternalPendsDetailInfoService
  ) {
    this.chasePendId = this.route.snapshot.parent.params.pendGd;
    this.descriptionText =
      new Textarea({
        key: "description",
        label: "Description",
        placeholder: "Long form text...",
        rows: 6,
        resize: Resize.VERTICAL,
        validators: [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(255),
        ],
        errorMessages: {
          required: "Write a description between 4 - 255 characters.",
          minlength: "Write a description between 4 - 255 characters.",
          maxlength: "Write a description between 4 - 255 characters.",
        },

      });

    this.formGroup = this.formService.createFormGroup([this.descriptionText]);
  }

  get isEnabled(): boolean {
    return this.formGroup.valid && ArrayHelper.isAvailable(this.uploadedFiles);
  }

  visibleChange($event) {
    this.isUploadDocumentVisible = $event;
    this.formClose.emit($event);
    this.formGroup.reset();

    this.input.clear();
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  uploadDocument(): void {
    const notes = this.formGroup.value?.description;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.descriptionText.key).setErrors({ "server-error": "Write a description between 4 - 255 characters." });
    } else {
      this.formGroup.get(this.descriptionText.key).setErrors(null);
    }

    if (this.formGroup.valid && ArrayHelper.isAvailable(this.uploadedFiles)) {
      this.disableUpload = true;
      const formData = new FormData();
      const description = this.formGroup.get("description").value;

      formData.append("Description", description);
      formData.append("CallUserId", this.authService.userId.toString());
      formData.append("ChasePendId", this.chasePendId.toString());
      this.uploadedFiles.forEach(file => {
        formData.append("Document", file);
      });

      this.service.upload(formData).subscribe(data => {
        if (data > 0) {
          this.service.refreshFunction();
          this.internalPendsDetailInfoService.refreshFunction();
          this.messagingService.showToast("Document Uploaded successfully.", SeverityType.SUCCESS);
          this.uploadedFiles = [];
          this.formGroup.reset();
          this.visibleChange(false);
          this.disableUpload = false;
        } else {
          this.messagingService.showToast("Error while Document uploading, please try again.", SeverityType.ERROR);
          this.disableUpload = false;
        }
      });
    } else {
      this.formService.markAllAsTouched(this.formGroup);
      this.disableUpload = false;
    }
    this.changeDetector.markForCheck();
  }
}

