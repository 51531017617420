<div class="control control--input control--dropdown control--vrc-dropdown" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div *ngIf="hasError" class="control__header__error" [title]="error">{{ error }}</div>
  </div>
  <p-dropdown
    appendTo="body"
    [styleClass]="classes"
    [options]="options"
    [placeholder]="model.placeholder"
    [required]="required"
    [name]="model.key"
    [inputId]="model.key"
    [(ngModel)]="selectedItem"
    [disabled]="model.disabled"
    (onChange)="itemSelected()"
    (onInput)="onInput.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="blurred($event)">
  </p-dropdown>
  <div *ngFor="let description of selectedText; let index = index; trackBy: trackByIndex" class="selected">
    {{ description | titlecase }}
    <span class="button selected__delete" (click)="remove(index)">×</span>
  </div>
</div>
<div [formGroup]="formGroup" [hidden]="true">
  <input
    [hidden]="false"
    type="text"
    [formControlName]="model.key">
</div>