import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-audit-query",
  templateUrl: "./audit-query.component.html",
  styleUrls: ["./audit-query.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryComponent {
  selectedTab = "auditquery";
  stateName = "audit-query";

  onSelectTab($event: string): void {
    this.selectedTab = $event;
  }

}
