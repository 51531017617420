export class AuditPackageGenerateItem {
  auditPackageItemId: number;
  auditPackageId: number;
  chaseId: number;
  projectId: number;
  measureCode: string;
  numeratorCode: string;
  pageRanges: string;
  auditReadyUserId: number;
  chaseComplianceCode: string;

  constructor(options: Partial<AuditPackageGenerateItem>) {
    this.auditPackageItemId = options.auditPackageItemId;
    this.auditPackageId = options.auditPackageId;
    this.chaseId = options.chaseId;
    this.projectId = options.projectId;
    this.measureCode = options.measureCode;
    this.numeratorCode = options.numeratorCode;
    this.pageRanges = options.pageRanges;
    this.auditReadyUserId = options.auditReadyUserId;
    this.chaseComplianceCode = options.chaseComplianceCode;
  }
}
