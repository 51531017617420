<div class="sortingPanel">
    <span>Sort:</span>
    <select id="sort" (change)="filterData()" [(ngModel)]="selectedSortingField">
      <option *ngFor="let option of sortingOptions, trackBy: trackByFn" [value]="option">
        {{option}}
      </option>
    </select>
    <p-inputSwitch onLabel="Desc" offLabel="Asc" [(ngModel)]="isSortByDesc" (onChange)="filterData()" class="pInputSwitch"></p-inputSwitch>
  </div>
  <p-table [columns]="gridConfiguration.columns"
           [value]="rowData"
           [rows]="gridConfiguration.pageSize"
           [paginator]="gridConfiguration.hasPagination"
           [scrollable]="gridConfiguration.isScrollable"
           [selectionMode]="gridConfiguration.selectionMode" [(selection)]="selectedRows"
           (onRowSelect)="gridRowSelect($event)"
           [rowTrackBy]="trackByRowIndex"
           [responsive]="true"
           [totalRecords]="totalRecords"
           [loading]="isLoading"
           (onLazyLoad)="loadGridLazy($event)"
           lazy="true">
  
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns; trackBy: trackByFn" [style.width]="col.width">
      </colgroup>
    </ng-template>
  
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns; trackBy: trackByFn"
            [pSortableColumn]="col.columnField"
            [hidden]="col.hidden">
         {{ col.header }}
  
          <p-sortIcon [field]="col.field" *ngIf="col.isSortableColumn"></p-sortIcon>
        </th>
        <th *ngIf="gridConfiguration.showActionColumn">Action</th>
      </tr>
    </ng-template>
  
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
  
        <td *ngFor="let col of columns; trackBy: trackByIndex" [hidden]="col.hidden" align="{{col.alignment}}" class="hdvi-gridcol-{{col.field}}">
          <span class="ui-column-title">{{col.header}}</span>
          <a [routerLink]="generateLink(col,rowData)" *ngIf="col.hasRouteUrl; else noRouteUrl">{{getValue(rowData, col)}}</a>
          <ng-template #noRouteUrl>
            <span>{{getValue(rowData, col)}}</span>
          </ng-template>
        </td>
  
        <td *ngIf="gridConfiguration.showActionColumn">
          <div>
            <span class="ui-column-title">Action</span>
            <i class="fas fa-chevron-circle-down" (click)="showAction($event,op2)" [class.active]="isActive"></i>
          </div>
        </td>
  
      </tr>
    </ng-template>
  
  </p-table>
  <p-overlayPanel #op2 [dismissable]="false" appendTo="body">
    <i class="fa fa-id-badge"></i>&nbsp;
    <i class="fa fa-user-circle"></i>&nbsp;
    <i class="fas fa-columns"></i>
  </p-overlayPanel>
  