import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { List } from "immutable";
import { Subscription } from "rxjs";
import { filter, pairwise } from "rxjs/operators";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { ButtonAction } from "../../../../../shared/button/button-action.model";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { DocumentListItem } from "../../../../../shared/document/chase-document-list/document-list-item.model";
import { DocumentViewerSessionService } from "../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { EmitState } from "../../../../../shared/models/emit-state.model";
import { ModalComponent } from "../../../../../shared/panel/modal/modal.component";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { WorkflowAction } from "../../../../api/workflow/workflow-action.enum";
import { WorkflowSubmitRequest } from "../../../../api/workflow/workflow-submit.model";
import { WorkflowService } from "../../../../api/workflow/workflow.service";
import { WorkflowStatus } from "../../../../api/workflow/worklow-status.enum";
import { DocumentReviewerStateService } from "../../../dashboard/document-reviewer/document-reviewer-state.service";
import { DocumentReviewerService } from "../../../dashboard/document-reviewer/document-reviewer.service";
import { FunctionalRole } from "../../../dashboard/retrieval/functional-role.enum";
import { ChaseDetailCommentsService } from "../../../member/chase-detail/chase-detail-comments/chase-detail-comments.service";
import { ChaseDetailService } from "../../../member/chase-detail/chase-detail.service";
import { MemberTimelineItem } from "../../../member/member-timeline-item.model";
import { DirectoryUserRole } from "../../directory-user-role";
import { RetrievalDocumentServiceService } from "../../retrieval-document-service.service";
import { DocumentStateGroup } from "../document-state-group.enum";
import { DocumentState } from "../document-state.enum";
import { RetreivalDocumentIntakeComponent } from "../retreival-document-intake/retreival-document-intake.component";
import { ChaseMoveBack } from "./chase-move-back.model";

@Component({
    selector: "app-document-review",
    templateUrl: "./document-review.component.html",
    styleUrls: ["./document-review.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MessagingService, ChaseDetailCommentsService],
})
export class DocumentReviewComponent implements OnInit, AfterViewInit, OnDestroy {
    loggedInUser: UserToken;
    chaseId: number;
    documentId: number;
    documentStatus: string;
    runMode: number;
    runModeDocument = 1;
    runModeChase = 2;
    isHoldDocumentModalVisible = false;
    isHoldDocumentModalGetNext = false;
    showConfirmationForRemoveAndGetNext = false;
    isGetNextOnRemoveDocument = false;

    form: FormGroup;
    // TODO: Break apart in own properites
    holdDocumentInputs: DynamicInput[];

    documentComponents: any[] = [];
    selectedTab = 0;
    viewSource = false;
    readonly DEFAULT_HOMEPAGE_URL = "/dashboard/retrieval";
    readonly SUBMIT_HOMEPAGE_URL = "/retrieval/views/docqa";
    homePageUrl: string;
    // in ChaseMode this will be the first component on the screen which is the Chase Chart Pages
    firstComponent: RetreivalDocumentIntakeComponent;
    idSubscriber: Subscription;
    chaseDetails: ListItem[];
    isChaseModalVisible = false;
    moveBackWarningText = "You are about to move this chase back to Retrieval.";
    workFlowStatusId = 2; // TODO: As matching status not available in workflowstatus enum. Will replace & get from enum when its same.
    readonly currentWorkflowStatus = WorkflowStatus.DocumentIntake;
    unAssignedPageAlertVisible = false;
    headerLabel: string;
    @ViewChildren("doccomponents") components: QueryList<RetreivalDocumentIntakeComponent>;
    chaseMoveBack: ChaseMoveBack;
    numberOfPages: Number;
    isGetNextEnabled = false;
    numberOfAssignedPages: Number;
    currentlyAssignedUser: number;


    submitButton: ButtonAction[] = [];
    removeButton: ButtonAction[] = [];
    holdButton: ButtonAction[] = [];
    commentItems = List<CommentItem>();
    commentText = "";
    timelineItems = List<MemberTimelineItem>();
    tagType = TagType.DOCUMENT;
    previousUrl: string;
    readonly MY_DOCUMENTS_URL = "/dashboard/documentreviewer";
    isAssignToUserModalVisible = false;
    assignToUsers: Autocomplete;
    formGroup: FormGroup;
    assignToUserList: SelectableInput[];
    @ViewChild("userModal", { static: true }) private userModal: ModalComponent;
    isAssignToChaseModalVisible = false;
    selectedChases: any[];
    readonly DOCUMENT_INTAKE_URL = "/retrieval/review";
    isUnHoldDocumentModalVisible = false;
    isUnHoldDocumentModalGetNext = false;
    unHoldStatus = DocumentState.DocumentNotMatchedToChase;
    documentStateGroupId: number;
    documentStateId: number;
    newPendStatus: string;
    showCreateDosPursuitConfirmation = false;
    getNext = false;

    constructor(private service: RetrievalDocumentServiceService,
                private changeDetector: ChangeDetectorRef,
                private route: ActivatedRoute,
                private router: Router,
                private workflowService: WorkflowService,
                private documentReviewerService: DocumentReviewerService,
                private userService: UserService,
                private messagingService: MessagingService,
                private readonly chaseDetailService: ChaseDetailService,
                private chaseDetailCommentsService: ChaseDetailCommentsService,
                private documentReviewerStateService: DocumentReviewerStateService,
                private readonly formService: FormService,
                private readonly authService: AuthService,
                private readonly documentViewerSessionService: DocumentViewerSessionService) { }
    ngOnInit() {
      this.loggedInUser = this.userService.getUserToken();
      this.homePageUrl = this.loggedInUser.homePageUrl || this.DEFAULT_HOMEPAGE_URL;
      this.idSubscriber = this.route.params
            .subscribe(params => {
                this.documentId = params.documentId;
                this.chaseId = params.chaseId;
                this.setRunMode();
                this.tagType = (this.runMode === this.runModeChase) ? TagType.CHASE : TagType.DOCUMENT;
                this.setupSplitButtons();
                this.loadDocumentComponents();
            });
      this.initializeFormGroupControls();
      if (this.runMode === this.runModeChase) {
          this.getComments();
        } else if (this.runMode === this.runModeDocument) {
          this.getAssignToUserList();
          this.getDocumentIntakeComments();
          this.getDocumentTimelines();
        }

      this.getTimelines();
      this.documentReviewerStateService.getUrl().subscribe(url => {
          if (url) {
            this.previousUrl = url;
          }
      });
      this.router.events.pipe(filter(event => event instanceof RoutesRecognized),
                              pairwise()
      ).subscribe((url: any) => {
        this.previousUrl = url[0].urlAfterRedirects;
        this.documentReviewerStateService.setUrl(this.previousUrl);
      });
    }

    setupSplitButtons() {
        this.submitButton = [
            new ButtonAction({
                name: "SUBMIT",
                action: () => this.submitDocumentToWorkflow(false),
            }),
      ];

        this.holdButton = [
          new ButtonAction({
            name: "HOLD",
            action: () => this.openHoldDocumentModal(false),
          }),
      ];

        this.removeButton = [
          new ButtonAction({
            name: "REMOVE",
            action: () => this.openConfirmationForRemoveAndGetNext(false),
          }),
      ];

    }

    moveChaseBackToCollection(): any {
        this.chaseMoveBack = new ChaseMoveBack({
            chaseId: this.chaseId,
            workflowStatusId: this.workFlowStatusId,
            chaseNote: `Chase ${this.chaseId} moved back. Incorrect document attached (all pages moved to new chase id(s)).`,
            addressId: this.addressId,
        });

        this.workflowService.chaseMoveBack(this.chaseMoveBack).subscribe((result: any) => { });
        this.chaseDetailCommentsService.saveChaseComment(this.chaseMoveBack.chaseId, this.chaseMoveBack.chaseNote).subscribe(() => { });
    }

    ngAfterViewInit() {
        this.components.changes.subscribe(() => {
            this.firstComponent = this.components.first;
        });

        // Have to do this otherwise will not always throw change event
        this.components.notifyOnChanges();
    }

    get memberItems(): List<ListItem> {
        const memberKeys = ["DOB", "Sex", "Project", "Member ID", "Member Name"];
        const memberItems = this.chaseDetails
            .filter(item => memberKeys.includes(item.key))
            .sort((a, b) => {
                const aValue = memberKeys.findIndex(key => key === a.key);
                const bValue = memberKeys.findIndex(key => key === b.key);
                return aValue - bValue;
            });

        return List(memberItems);
    }

    get addressId(): number {
        if (!ArrayHelper.isAvailable(this.chaseDetails)) {
            return null;
        }

        const addressId = this.chaseDetails.find(item => item.key === "AID");
        if (addressId == null || !StringHelper.isAvailable(addressId.value)) {
            return null;
        }

        return +addressId.value;
    }

    get memberValidationReason(): string {
        if (!ArrayHelper.isAvailable(this.chaseDetails)) {
            return null;
        }

        const memberValidationReasonItem = this.chaseDetails.find(item => item.key === "MemberValidationReason");
        if (memberValidationReasonItem == null  || !StringHelper.isAvailable(memberValidationReasonItem.value)) {
            return null;
        }

        return `CLINICAL REVIEW: ${memberValidationReasonItem.value}`;
    }

    get currentDocumentStatus(): string {
        if (this.runMode === this.runModeChase) {
            if (!ArrayHelper.isAvailable(this.chaseDetails)) {
                return null;
            }
            const docStatus = this.chaseDetails.find(item => item.key === "Status");
            if (docStatus === null || !StringHelper.isAvailable(docStatus.value)) {
                return null;
            }
            return `(${docStatus.value})`;
        } else {
            return `(${this.documentStatus})`;
        }
    }

    get allowEdit(): boolean {
    if (this.runMode === this.runModeChase && this.currentlyAssignedTo !== this.loggedInUser.userId) {
            return false;
        }

    return true;
    }


    get currentlyAssignedTo(): number {
        if (!this.chaseDetails) {

            return null;
        }

        const assignedToItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
        if (assignedToItem == null) {
            return null;
        }

        return +assignedToItem.value;
    }

    get viewSourceLabel(): string {
        return this.viewSource ? "HIDE SOURCE" : "VIEW SOURCE";
    }

    get headerTitle(): string {
        if (this.runMode === this.runModeDocument) {
            return `Document ID: ${this.documentId} ${this.currentDocumentStatus} `;
        } else {
            return `Chase ID: ${this.chaseId} ${this.currentDocumentStatus} `;
        }
    }

    get headerTitle2(): string {
      if (this.runMode === this.runModeDocument && this.firstComponent) {
        return NumberHelper.isGreaterThan(this.firstComponent.documentAddressId, 0) ? `FILE NAME: ${this.firstComponent.documentFileName} \xa0\xa0\xa0 AID: ${this.firstComponent.documentAddressId}`
                                                                               : `FILE NAME: ${this.firstComponent.documentFileName}`;
      } else {
        return null;
      }
    }


    get hasForm(): boolean {
        return this.form != null;
    }

    get isValid(): boolean {
        if (this.form == null) {
            return false;
        }

        return this.form.valid;
    }

    get unAssignedPages(): number[] {
      if (!this.firstComponent || !ArrayHelper.isAvailable(this.firstComponent.unassignedPages)) {

        return null;
      }

      return this.firstComponent.unassignedPages;
    }

    get objectId(): number {
      return this.runMode === this.runModeChase ? this.chaseId : this.runMode === this.runModeDocument ? this.documentId : 0;
    }

    loadDocumentComponents(): void {
        this.documentComponents = [];

        // load doc component for Chase Pages and all Documents components for each Document used in Chase Pages.
        if (this.runMode === this.runModeChase) {
            this.loadForChase();
        } else {
            this.loadForIntake();
        }


    }

    loadForIntake() {

        // just add one entry to documentComponents and  <app-retreival-document-intake> will render
        this.documentComponents.push({ documentId: this.documentId, sourceDocumentTypeId: this.runModeDocument, fileName: " " });

        this.changeDetector.markForCheck();
    }

    loadForChase() {
        // Load the chase as first component, only the chase pages will show in Chart component.
        this.chaseDetailService.getSummaryItems(this.chaseId)
            .subscribe(chaseDetails => {
                this.chaseDetails = chaseDetails;
                if (!this.allowEdit) {
                    this.messagingService.showMessage("You are not currently assigned to this Chase.  Certain functions will not be available to you.", SeverityType.WARN);
                }
                this.changeDetector.markForCheck();
            });

        this.documentComponents.push({ documentId: this.chaseId, sourceDocumentTypeId: this.runModeChase });


        // Load all the original source Documents that were used for the Chase Pages but all pages from this document will display in component.
        this.service.getDocumentListItems(this.chaseId, 99, false)
            .subscribe((documents: DocumentListItem[]) => {

                for (const document of documents) {

                    this.documentComponents.push({ documentId: document.documentQueueId, sourceDocumentTypeId: this.runModeDocument, fileName: document.fileName });
                }
                this.changeDetector.markForCheck();
            });


    }

    setRunMode() {
        if (this.documentId && this.documentId > 0) {
            this.runMode = this.runModeDocument;
        } else if (this.chaseId && this.chaseId > 0) {
            this.runMode = this.runModeChase;
        }
    }

    toggleTab(tab: number) {
      this.selectedTab = this.selectedTab === tab ? 0 : tab;
      if (this.selectedTab === 9999) {
        if (this.runMode === this.runModeDocument) {
          this.getDocumentTimelines();
        } else {
          this.getTimelines();
        }
      }
    }

    toggleViewSource() {

        this.viewSource = !this.viewSource;
        if (this.viewSource === false) {
            this.selectedTab = 0;
        }
    }

    trackByIndex(index, item) {
        return index;
    }

    receivePageNumber($event) {
        this.numberOfPages = $event;
    }

    receiveAssignedPageNumber($event) {
        this.numberOfAssignedPages = $event;
    }

    documentComponentChanged(event: EmitState) {

        // Reload documents  because Document may have been source of Chase Page deleted and should not be displayed anymore
        if (event.eventName.toLowerCase() === "deletedchasepages" || event.eventName.toLowerCase() === "assignchasepages" || event.eventName.toLowerCase() === "movechasepages") {
            this.loadDocumentComponents();
        } else if (event.eventName.toLowerCase() === "membernotvalid") {
            this.getNextDocOrChase();
        }
    }

    documentStatusChanged(event) {
      this.documentStatus = event.documentStateDescription;
      this.documentStateGroupId = event.documentStateGroupId;
      this.documentStateId = event.documentStateId;

      // tried using ButtonAction.disabled but not working properly so have to add and remove as needed.
      if (this.documentStateGroupId === DocumentStateGroup.OnHold) {
        if (this.holdButton.filter(f => f.name.toLowerCase() === "unhold").length === 0) {
          this.holdButton.push(new ButtonAction({
            name: "UNHOLD",
            action: () => this.openUnHoldDocumentModal(false),
          }));
        }
      } else {
        this.holdButton = this.holdButton.filter(f => f.name.toLowerCase() !== "unhold");
      }
    }

    createWorfklowSubmitReqest() {

        let workflowSubmitRequest: WorkflowSubmitRequest;

        if (this.runMode === this.runModeChase) {

            workflowSubmitRequest =
                new WorkflowSubmitRequest(
                    this.chaseId,
                    WorkflowStatus.MRQA,
                    WorkflowAction.Completed,
                    ""
                );

        } else {
            workflowSubmitRequest =
                new WorkflowSubmitRequest(
                    this.documentId,
                    WorkflowStatus.DocumentIntake,
                    WorkflowAction.Completed,
                    ""
                );
        }

        return workflowSubmitRequest;
    }

    submitToWorkflow() {
      this.getNext = false;
      if (this.numberOfPages === 0) {
              this.moveChaseBackToCollection();
              this.router.navigate([this.SUBMIT_HOMEPAGE_URL]);
          } else {
            if (!this.validateForm()) {
                return;
            }
            if (StringHelper.isAvailable(this.newPendStatus) && !this.isPursuitChase) {
          this.showCreateDosPursuitConfirmation = true;
        } else {

              const workflowSubmitRequest = this.createWorfklowSubmitReqest();

              this.workflowService.submit(workflowSubmitRequest)
              .subscribe((result: boolean) => {

                if (result && this.hasDocumentQAEmployeeRole) {
                  this.router.navigate([this.MY_DOCUMENTS_URL]);
                } else if (result) {
                  this.router.navigate([this.SUBMIT_HOMEPAGE_URL]);
                } else {
                  this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
                }
              });
            }
          }
    }

    createDosPursuit(): void {
      const workflowSubmitRequest = this.createWorfklowSubmitReqest();

      this.workflowService.submit(workflowSubmitRequest)
        .subscribe((result: boolean) => {
          if (result) {
            if (this.getNext) {
              this.getNextAssignedDocOrChase();
            } else {
              this.router.navigate([this.SUBMIT_HOMEPAGE_URL]);
            }
          } else {
            this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
          }
        });
    }

    get dosPursuitCreationText(): string {
      return "You have indicated that there are missing dates of service or signatures. Submitting this chase will create a pursuit. Do you want to continue?";
    }

    get isPursuitChase(): boolean {
      if (!ArrayHelper.isAvailable(this.chaseDetails)) {
        return null;
      }
      const parentChaseId = this.chaseDetails.find(item => item.key === "Parent Chase Id").value;
      return StringHelper.isAvailable(parentChaseId);

    }

    // After submitting get next chase/document for the user
    submitToWorkflowAndGetNext() {
      this.getNext = true;

      if (this.numberOfPages === 0) {
        this.moveChaseBackToCollection();
        this.getNextAssignedDocOrChase();
      } else {
        if (!this.validateForm()) {
          return;
        }
        if (StringHelper.isAvailable(this.newPendStatus) && !this.isPursuitChase) {
          this.showCreateDosPursuitConfirmation = true;
        } else {

          const workflowSubmitRequest = this.createWorfklowSubmitReqest();

          this.workflowService.submit(workflowSubmitRequest)
            .subscribe((result: boolean) => {
              if (result) {
                this.getNextAssignedDocOrChase();
              } else {
                this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
              }
            });
        }
      }
    }

    submitDocumentToWorkflow(getNext: boolean) {
        if (ArrayHelper.isAvailable(this.unAssignedPages)) {
            this.isGetNextEnabled = getNext;
            this.unAssignedPageAlertVisible = true;
        } else {
          if (!this.validateForm()) {
              return;
          }

          const workflowSubmitRequest = this.createWorfklowSubmitReqest();

          this.workflowService.submit(workflowSubmitRequest)
              .subscribe((result: boolean) => {
                  if (result) {
                      if (getNext) {
                          this.getNextAssignedDocOrChase();
                      } else {
                          if (this.previousUrl !== this.MY_DOCUMENTS_URL) {
                            this.router.navigate([this.DOCUMENT_INTAKE_URL]);
                          } else {
                            this.router.navigate([this.MY_DOCUMENTS_URL]);
                          }
                      }
                  } else {
                      this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
                  }
              });
          }

    }

    onCreateDosPursuit(event: any): void {
      this.newPendStatus = event;
    }

    openConfirmationForRemoveAndGetNext(getNext: boolean): void {
        this.showConfirmationForRemoveAndGetNext = true;
        this.isGetNextOnRemoveDocument = getNext;

        this.changeDetector.markForCheck();
    }


    submitDocumentNoMatchToWorkflow() {
        // No validation needed for Remove
        this.showConfirmationForRemoveAndGetNext = false;

        const workflowSubmitRequest =
            new WorkflowSubmitRequest(
                this.documentId,
                WorkflowStatus.DocumentIntake,
                WorkflowAction.Cancelled,
                ""
            );

        this.workflowService.submit(workflowSubmitRequest)
            .subscribe((result: boolean) => {
                if (result) {
                    if (this.isGetNextOnRemoveDocument) {
                        this.getNextAssignedDocOrChase();
                    } else {
                        if (this.previousUrl !== this.MY_DOCUMENTS_URL) {
                            this.router.navigate([this.DOCUMENT_INTAKE_URL]);
                          } else {
                            this.router.navigate([this.MY_DOCUMENTS_URL]);
                          }
                    }
                } else {
                    this.messagingService.showMessage("Remove Failed", SeverityType.ERROR);
                }
            });
    }

    submitChartDownloadRequest() {


        this.service.submitChartDownloadRequest(this.chaseId)
            .subscribe((result: boolean) => {
                if (result) {
                    this.messagingService.showToast("Your Chart download request is being processed and you will be alerted when the file is ready for download", SeverityType.SUCCESS);
                } else {
                    this.messagingService.showMessage("Your Chart download request has failed.", SeverityType.ERROR);
                }
            });
    }
    getNextAssigned(): void {
        const functionalRoleId = this.runMode === this.runModeDocument ? FunctionalRole.DOCUMENTINTAKE : FunctionalRole.DOCUMENTQA;

        this.service.getNextAssigned(functionalRoleId)
            .subscribe(result => {
                if (result < 1) {
                    this.messagingService.showMessage("No more work available", SeverityType.INFO);
                    this.router.navigate([this.homePageUrl]);

                } else if (functionalRoleId === FunctionalRole.DOCUMENTINTAKE) {
                    this.router.navigate(["retrieval", "review", "intake", result]);
                } else {
                    this.router.navigate(["retrieval", "review", "qa", result]);
                }

            });
    }

    getNextDocOrChase(): void {
        const functionalRoleId = this.runMode === this.runModeDocument ? FunctionalRole.DOCUMENTINTAKE : FunctionalRole.DOCUMENTQA;

        this.documentReviewerService.getNextChaseId(functionalRoleId)
            .subscribe((result: any) => {
                if (result.chaseId === undefined) {
                    this.messagingService.showMessage("No more work available", SeverityType.INFO);
                    this.router.navigate([this.homePageUrl]);

                }

                const documentType = result.documentType === "MR Intake" ? "intake" : "qa";
                this.router.navigate(["retrieval", "review", documentType, result.chaseId]);
            });
    }

    validateForm() {

        let isValid = false;

        this.runMode === this.runModeChase ? isValid = this.validateForChase() : isValid = this.validateForDocument();

        return isValid;

    }

    validateForChase(): boolean {

        // check all pages have been viewed in the pageViewer component
        if (!this.firstComponent.validateForChaseSubmit()) {

            return false;
        }

        return true;

    }

    validateForDocument(): boolean {

        // check all pages have been viewed in the pageViewer component
        if (!this.firstComponent.validateForDocumentSubmit()) {
            return false;
        }

        return true;

    }

    openHoldDocumentModal(getNext: boolean): void {
        this.isHoldDocumentModalVisible = true;
        this.isHoldDocumentModalGetNext = getNext;

        this.changeDetector.markForCheck();
    }

    holdDocumentModalUpdate(): void {
        this.isHoldDocumentModalVisible = false;

        if (this.isHoldDocumentModalGetNext) {
            this.getNextAssignedDocOrChase();
        } else {
            if (this.previousUrl !== this.MY_DOCUMENTS_URL) {
                this.router.navigate([this.DOCUMENT_INTAKE_URL]);
            } else {
                this.router.navigate([this.MY_DOCUMENTS_URL]);
            }
        }

  }

    openUnHoldDocumentModal(getNext: boolean): void {
    this.isUnHoldDocumentModalVisible = true;
    this.isUnHoldDocumentModalGetNext = getNext;

    this.changeDetector.markForCheck();
  }

    unHoldDocumentModalUpdate(): void {
    this.isUnHoldDocumentModalVisible = false;

    if (this.isUnHoldDocumentModalGetNext) {
      this.getNextAssignedDocOrChase();
    } else {
      if (this.previousUrl !== this.MY_DOCUMENTS_URL) {
        this.router.navigate([this.DOCUMENT_INTAKE_URL]);
      } else {
        this.router.navigate([this.MY_DOCUMENTS_URL]);
      }
    }
  }

    openMoveChaseBackModal(): void {
        this.isChaseModalVisible = true;
    }

    onUpdate() {
        const roles = [DirectoryUserRole.SystemAdministrator, DirectoryUserRole.Admin,
                       DirectoryUserRole.RetrievalLead , DirectoryUserRole.DocumentQAManager];

        const hasPermissionToViewDocQAPage = this.loggedInUser.directoryRoleIds.some(roleId => roles.includes(roleId));
        if (hasPermissionToViewDocQAPage) {
            this.router.navigate(["retrieval", "views", "docqa"]);
        } else {
            this.router.navigate(["dashboard", "documentreviewer"]);
        }
    }

    closePopup() {
        this.unAssignedPageAlertVisible = false;
    }

    moveToCancelStateAndSubmit() {
        this.unAssignedPageAlertVisible = false;

        let workflowSubmitRequest: WorkflowSubmitRequest;

        workflowSubmitRequest =
            new WorkflowSubmitRequest(
                this.documentId,
                WorkflowStatus.DocumentIntake,
                WorkflowAction.Cancelled,
                ""
            );

        if (!this.validateForm()) {

            return;
        }

        this.workflowService.submit(workflowSubmitRequest)
            .subscribe((result: boolean) => {
                if (result) {
                    this.navigateToHomePage();
                } else {
                    this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
                }
            });
    }

    moveToCancelStateAndSubmitAndGetNext() {
        this.unAssignedPageAlertVisible = false;

        let workflowSubmitRequest: WorkflowSubmitRequest;

        workflowSubmitRequest =
            new WorkflowSubmitRequest(
                this.documentId,
                WorkflowStatus.DocumentIntake,
                WorkflowAction.Cancelled,
                ""
            );

        if (!this.validateForm()) {

            return;
        }

        this.workflowService.submit(workflowSubmitRequest)
            .subscribe((result: boolean) => {
                if (result) {
                    if (this.isGetNextEnabled) {
                        this.getNextAssignedDocOrChase();
                    } else {
                        if (this.previousUrl !== this.MY_DOCUMENTS_URL) {
                            this.router.navigate([this.DOCUMENT_INTAKE_URL]);
                        } else {
                            this.router.navigate([this.MY_DOCUMENTS_URL]);
                        }
                    }
                } else {
                    this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
                }
            });
    }

    navigateToHomePage(): void {
        this.router.navigateByUrl(this.homePageUrl);
  }
    updateChaseAssignedData(): void {
    this.loadDocumentComponents();
    this.currentlyAssignedUser = this.currentlyAssignedTo;
    this.changeDetector.markForCheck();
  }

    getNextAssignedDocOrChase(): void {
        const functionalRoleId = this.runMode === this.runModeDocument ? FunctionalRole.DOCUMENTINTAKE : FunctionalRole.DOCUMENTQA;

        this.documentReviewerService.getNextAssignedChaseId(functionalRoleId)
            .subscribe((result: any) => {
                if (NumberHelper.isGreaterThan(result.chaseId, 0)) {
                  const documentType = result.documentType === "MR Intake" ? "intake" : "qa";
                  this.router.navigate(["retrieval", "review", documentType, result.chaseId]);
                  this.documentViewerSessionService.dataEntryPageNumber.next(1);
                  if (this.runMode === this.runModeChase) {
                    this.chaseId = result.chaseId;
                    this.getComments();
                  }
                  if (this.runMode === this.runModeDocument) {
                    this.documentId = result.chaseId;
                    this.getDocumentIntakeComments();
                  }
                } else {
                  this.messagingService.showMessage("No more work available", SeverityType.INFO);
                  this.navigateToHomePage();
                }
            });
    }

    getComments(): void {
        this.service
          .getChaseComments(this.chaseId)
          .subscribe(comments => {
            this.commentItems = this.assignAndNotify(comments);
          });
    }

    private assignAndNotify<T>(comments: T[]): List<T> {
        this.changeDetector.markForCheck();
        const commentsItems = List(comments);
        return commentsItems;
    }

    saveComment(): void {
      if (StringHelper.isAvailable(this.commentText)) {
        if (this.runMode === this.runModeChase) {
          this.service
            .saveChaseComment(this.commentText, this.chaseId)
            .subscribe(() => {
              this.getComments();
              this.commentText = "";
            });
        } else if (this.runMode === this.runModeDocument) {
          this.service
            .saveDocumentComment(this.documentId, this.commentText)
            .subscribe(() => {
              this.getDocumentIntakeComments();
              this.commentText = "";
            });
        }

        }
    }

    private getTimelines(): void {
    this.service.getChaseTimelineActivityItems(this.chaseId).subscribe(timelines => {
      const groupedTimelines = this.mapToGroupedTimelines(timelines);
      this.timelineItems = List(groupedTimelines);
      this.timelineItems.filter(this.addDocIntakeUrlInDocumentId);
      this.changeDetector.markForCheck();
    });
  }

    private mapToGroupedTimelines(timelines: TimelineItem[]): MemberTimelineItem[] {
    const timelineList = timelines.reduce((timeLineGroup, timeLineItem) => {
      const specificDay = DateHelper.format(timeLineItem.timestamp);
      timeLineGroup[specificDay] = timeLineGroup[specificDay] || [];
      timeLineGroup[specificDay].push(timeLineItem);
      return timeLineGroup;
    },                                    {});

    const groupedTimelines = Object.keys(timelineList).map(specificDay => {
      return new MemberTimelineItem({
        specificDay,
        timelineGroup: timelineList[specificDay],
      });
    });
    return groupedTimelines;
  }

    private getDocumentIntakeComments(): void {
      this.service
        .getDocumentComments(this.documentId)
        .subscribe(documentComments => {
          this.commentItems = this.assignAndNotify(documentComments);
        });
    }

    get isChartTab(): boolean {
      return (this.selectedTab === 1111 || this.selectedTab === 9999);
    }

    private getDocumentTimelines(): void {
      this.service.getDocumentTimelineActivity(this.documentId).subscribe(timelines => {
          const groupedTimelines = this.mapToGroupedTimelines(timelines);
          this.timelineItems = List(groupedTimelines);
          this.changeDetector.markForCheck();
        });
  }

    getDocumentComponentClass(): string {
      return (this.selectedTab === 0) ? "document-review-container-inner" : "";
  }

    getSplitButtonClass(): string {
      return this.isChartTab ? "split-button-disabled" : "spilt-button-class";
    }

    ngOnDestroy(): void {
       this.documentReviewerStateService.clearUrl();
    }

    openAssignToUserModal(): void {
        this.clearUserAssignmentModal();
        this.isAssignToUserModalVisible = true;
    }

    initializeFormGroupControls(): void {
        this.assignToUsers = new Autocomplete({
          key: "assignToUsers",
          label: "User",
          filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
        });

        this.formGroup = this.formService.createFormGroup([this.assignToUsers]);
    }

    getAssignToUserList(): void {
        this.service
          .getDocumentIntakeAssignTo([this.documentId])
          .subscribe(assignToUserList => {
            ArrayHelper.addItemToSelect(assignToUserList, "@Me", this.loggedInUser.userId);
            this.assignToUserList = ArrayHelper.addItemToSelect(assignToUserList, "Unassigned", 0);
            this.assignToUsers = new Autocomplete({ ...this.assignToUsers, options: assignToUserList } as any);
            this.changeDetector.markForCheck();
        });
    }

    assignToClick() {
        const workflowSubmitRequest =
          new WorkflowSubmitRequest(
            this.documentId,
            WorkflowStatus.DocumentIntake,
            WorkflowAction.Assigned,
            `\{'assignToUserId':${this.selectedUserId}\}`,
            [this.documentId]
          );

        this.workflowService.submit(workflowSubmitRequest)
          .subscribe((result: boolean) => {
            if (result) {
              this.messagingService.showToast("Assignment Successful", SeverityType.SUCCESS);
              this.userModal.hide();
            } else {
              this.messagingService.showMessage("Assignment Failed", SeverityType.ERROR);
            }
        });
    }

    get hasAssignButton(): boolean {
          return this.runMode === this.runModeDocument && this.hasPermissionToViewAssignButton;
    }

    get hasPermissionToViewAssignButton(): boolean {
        const roles = [DirectoryUserRole.SystemAdministrator, DirectoryUserRole.Admin,
                       DirectoryUserRole.RetrievalLead , DirectoryUserRole.DocumentIntakeManager];

        return this.loggedInUser.directoryRoleIds.some(roleId => roles.includes(roleId));
    }

    get selectedUser(): SelectableInput {
        if (this.formGroup == null) {
          return null;
        }

        return this.formGroup.get(this.assignToUsers.key).value;
    }

    set selectedUser(value: SelectableInput) {
        if (this.formGroup == null) {
          return;
        }

        this.formGroup.get(this.assignToUsers.key).setValue(value);
    }

    get selectedUserId(): number {
        if (this.selectedUser == null) {
          return null;
        }

        return +this.selectedUser.value;
    }

    get hasSelectedUser(): boolean {
        return NumberHelper.isGreaterThan(this.selectedUserId, 0, true);
    }

    clearUserAssignmentModal(): void {
        this.selectedUser = null;
        this.assignToUserList = [];
        this.changeDetector.markForCheck();
  }

    openUserAssignmentModel(): void {
    this.selectedChases = [Number(this.chaseId)];
    this.isAssignToChaseModalVisible = true;
  }

    closeModal(): void {
    this.selectedChases = [];
  }

    get hasRetrievalLeadRole(): boolean {
    return this.authService.user.directoryRoleIds.includes(DirectoryUserRole.RetrievalLead);
  }

    get hasDocumentQAManagerRole(): boolean {
    return this.authService.user.directoryRoleIds.includes(DirectoryUserRole.DocumentQAManager);
  }

    get hasDocumentQAEmployeeRole(): boolean {
    return this.authService.user.directoryRoleIds.includes(DirectoryUserRole.DocumentQA);
  }

    get hasUserAssignmentRole(): boolean {
    return this.loggedInUser.isAdminRole ||
      this.hasRetrievalLeadRole ||
      this.hasDocumentQAManagerRole;
  }

    private addDocIntakeUrlInDocumentId(timeline): boolean {
    const docIntakeRoute = "/retrieval/review/intake/";

    const docInTakeAddedUrlTimeline = timeline.timelineGroup.filter(x => x.type === "file").map(item => {
        let note = item.note.replace(/\s+/g, " ").trim();
        const noteWithDocId = note;
        note = note.split(" ");

        if (!ArrayHelper.isAvailable(note)) {
          return;
        }

        const docId = note[note.length - 1];
        const fileTypeName = note[note.length - 2];
        if (NumberHelper.isAvailable(Number(docId)) && fileTypeName.toLowerCase() === "documentqueueid") {
            item.note = `${noteWithDocId.substring(0, noteWithDocId.lastIndexOf(" "))}`;
            item.urlPath = docIntakeRoute + docId;
            item.urlText = docId;
        }
    });

    return docInTakeAddedUrlTimeline;
  }
}
