import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubSink } from "subsink";
import { DownloaderService } from "../../../../../core/downloader/downloader.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";
import { DocumentRequest } from "../../../../../shared/document/document-request.model";
import { DocumentRequestService } from "../../../../../shared/document/document-request.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MemberCentricChase } from "../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { MemberCentricDocAttachmentService } from "../../../../../shared/membercentric-doc-attachment/membercentric-doc-attachment.service";
import { TimelineItem } from "../../../../../shared/timeline/timeline-item.model";
import { IOnUpload } from "../../../../../shared/upload/upload.component";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { MedicalRecordUploadService } from "../../../../api/medical-record-upload/medical-record-upload.service";
import { NumeratorListItem } from "../../../../api/numerator/numerator-list-item.model";
import { WorkflowStatusDb } from "../../../../api/workflow/workflow-status-db.enum";
import { NotificationService } from "../../../../notification/notification.service";
import { Member } from "../../../clinical/member.model";
import { ChaseDetailState } from "../../../member/chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../member/chase-detail/chase-detail-state.service";
import { ChaseDetail } from "../../../member/chase-detail/chase-detail.model";
import { ChaseDetailService } from "../../../member/chase-detail/chase-detail.service";
import { Project } from "../../../project/project.model";
import { DocumentSourceType } from "../../../retrieval/retrieval-document-source-type.enum";
import { InternalPendsDetailInfoService } from "./internal-pends-detail-info.service";
import { ProviderPacketItem } from "./provider-packet-item.model";

@Component({
  selector: "app-internal-pends-detail-info",
  templateUrl: "./internal-pends-detail-info.component.html",
  styleUrls: ["./internal-pends-detail-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPendsDetailInfoComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  iconNameAttachment = "paperclip";
  iconNameImage = "image";
  timelineItems = List<TimelineItem>();
  summaryItems = List<ListItem>();
  commentItems = List<CommentItem>();
  commentText: string;
  pendId: number;
  isNext: boolean;
  id: string;
  subscription: Subscription;
  isUploadDocumentView = false;
  selectedFileList: any[] = [];
  formData: FormData = new FormData();
  projectList: SelectItem[];
  projectId: any;
  providerPacketItem: ProviderPacketItem;
  providerPacketURL: any;
  pendType: string;
  keyStatus: string;
  isClinical: string;
  documentRequests: DocumentRequest[];
  addressUrlOfChase: string;
  uploadSuccessMessage = "Thank you for uploading this medical record.  We are now processing the file.  Please check back shortly and your document will be attached.";
  showUploadWarningModal = false;
  upLoadInfo: IOnUpload;
  chaseDetailState: ChaseDetailState;
  memberCentricData: MemberCentricChase[];
  showMemberCentricDocAttachmentInfo = false;
  openGaps: string;
  private usePresignedURLs: boolean;
  urlCopy: String[] = [];
  constructor(
    private service: InternalPendsDetailInfoService,
    private chaseDetailStateService: ChaseDetailStateService,
    private chaseDetailService: ChaseDetailService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private documentRequestService: DocumentRequestService,
    private memberCentricDocAttachmentService: MemberCentricDocAttachmentService,
    private userService: UserService,
    private notificationService: NotificationService,
    private downloaderService: DownloaderService,
    private router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.pendId = this.service.getPendGdFromPath();
    this.keyStatus = localStorage.getItem("pendStatusItem");
  }

  ngOnInit() {
    this.sink.add(
      this.route.paramMap.subscribe(params => {
        this.pendId = +params.get("pendGd");

        this.subscription = this.service.reset.subscribe(id => {
          this.updatesummary(id);
        }
        );

        this.service
          .getTimelineItems(this.pendId)
          .subscribe(items => this.timelineItems = this.assignAndNotify(items));

        this.service
          .getComments(this.pendId)
          .subscribe(items => this.commentItems = this.assignAndNotify(items));

        this.service
          .getSummaryItems(this.pendId)
          .pipe(
            map(items => this.mapSummaryItems(items)),
            switchMap(chaseId => this.chaseDetailService.getChaseDetail(chaseId)),
            map(chaseDetail => this.mapChaseDetail(chaseDetail)),
            switchMap(chaseDetail => this.memberCentricDocAttachmentService.verfiyIfMemberCentricChase(chaseDetail.chaseId, chaseDetail.projectId)),
            map(memberCentricChase => this.mapMemberCentricChase(memberCentricChase))
          ).subscribe(_ => this.changeDetector.markForCheck());
      }),

      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.changeDetector.markForCheck();
      }));

    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  private mapSummaryItems(items: ListItem[]): number {
    this.addressUrlOfChase = items.find(item => item.key === "Address ID").url;
    this.projectId = items.find(x => x.key === "ProjectId").value;
    this.isClinical = items.find(x => x.key === "IsClinical").value.toLowerCase();
    const chaseId = +items.find(item => item.key === "Chase ID").value;
    this.openGaps = items.find(x => x.key === "OpenGaps").value;
    this.summaryItems = this.assignAndNotify(
      items.filter(x => x.key !== "ProjectId" && x.key !== "PendStatusId" && x.key !== "PendStatusId" && x.key !== "Address URL"
        && x.key !== "IsClinical" && x.key !== "OpenGaps"));
    this.getRequiredDocumentsForChase();

    return chaseId;
  }

  private mapChaseDetail(chaseDetail: ChaseDetail): ChaseDetail {
    this.chaseDetailStateService.setData({
      ...chaseDetail,
      member: new Member({...chaseDetail}),
      project: new Project({...chaseDetail}),
    });

    return chaseDetail;
  }

  private mapMemberCentricChase(data: MemberCentricChase[]): void {
    if (ArrayHelper.isAvailable(data)) {
      this.memberCentricData = data;
    }
  }

  updatesummary(id) {
    this.service
      .getTimelineItems(id)
      .subscribe(items => this.timelineItems = this.assignAndNotify(items));

    this.service
      .getComments(id)
      .subscribe(items => this.commentItems = this.assignAndNotify(items));

    this.service
      .getSummaryItems(id)
      .subscribe(items => {
        this.openGaps = items.find(x => x.key === "OpenGaps").value;
        this.summaryItems = this.assignAndNotify(items.filter(x => x.key !== "ProjectId" && x.key !== "PendStatusId"
          && x.key !== "Address URL" && x.key !== "IsClinical" && x.key !== "OpenGaps"));
        this.getRequiredDocumentsForChase();
      });
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  getRequiredDocumentsForChase(): DocumentRequest[] {
    const chaseId = this.summaryItems.find(item => item.key === "Chase ID").value;
    const documentRequestModel = new DocumentRequest({ chaseId: Number(chaseId) });
    this.documentRequestService.get(documentRequestModel).subscribe(data => {
      this.documentRequests = data;
      this.changeDetector.markForCheck();
    });

    return this.documentRequests;
  }

  saveComments() {
    if (StringHelper.isAvailable(this.commentText)) {
      this.service
        .saveComment(this.pendId, this.commentText)
        .subscribe(() => {
          this.service
            .getComments(this.pendId)
            .subscribe(items => this.commentItems = this.assignAndNotify(items));
          this.service.reset.next(this.pendId);
          this.changeDetector.markForCheck();
          this.commentText = null;
        });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    localStorage.removeItem("pendStatusItem");
    this.sink.unsubscribe();
  }

  loadViewConversation(pendId) {
    this.router.navigate(["pend", "detail", pendId, "comments"]);
  }

  showDialog() {
    this.isUploadDocumentView = true;
  }

  reUploadDocument($event) {
    this.isUploadDocumentView = $event;
  }

  showAllDocument(pendId) {
    this.router.navigate(["pend", "detail", pendId, "documents"]);
  }

  printProvider() {
    const arrchaseid = [];
    arrchaseid.push(this.summaryItems.find(x => x.key === "Chase ID").value);

    this.providerPacketItem = new ProviderPacketItem({
      chaseIds: arrchaseid,
      projectId: this.projectId,
      toName: "",
      toPhone: this.summaryItems.find(x => x.key === "Provider Phone Number")?.value,
      toFax: this.summaryItems.find(x => x.key === "Provider Fax Number")?.value,
      fromName: "",
      fromPhone: "",
      providerName: "",
      serviceOrgName: "",
      chaseNumeratorList: this.selectedChaseNumeratorList,
    });

    this.service
      .printProviderPacket(this.providerPacketItem)
      .subscribe(item => {
        if (item !== "") {
          this.downloaderService.fromRawUrl(item);
        } else {
          this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
        }
      });

  }

  get selectedChaseNumeratorList(): NumeratorListItem[] {
    const numeratorList: NumeratorListItem[] = [];
    numeratorList.push(
      new NumeratorListItem({
        chaseId: Number(this.summaryItems.find(x => x.key === "Chase ID").value),
        numeratorId: 0,
        numeratorCode: this.openGaps,
        numeratorName: "",
      })
    );
    return numeratorList;
  }

  uploadDocument(event: IOnUpload): void {
    this.upLoadInfo = event;
    let fileNum = 0;
    for (const item in event.originalFilenameList) {
      if (StringHelper.isAvailable(item)) {
        this.upLoadInfo.formData.append(`OFN${fileNum}`, event.originalFilenameList[fileNum]);
        fileNum++;
      }
    }
    if (this.isMemberCentricChase) {
      this.urlCopy = event.presignedURLList;
      this.continueWithUpload(event.presignedURLList);
    } else {
      this.showUploadWarningModal = this.chaseDetailState.workflowStatus > WorkflowStatusDb.Abstraction && this.chaseDetailState.hasChaseDocumentId;
      if (!this.showUploadWarningModal) {
        this.urlCopy = event.presignedURLList;
        this.continueWithUpload(event.presignedURLList);
      }
    }
    this.urlCopy = event.presignedURLList;
  }

  uploadDocToMemberCentricChases(selectedChaseIds: number[]): void {
    const memberCentricChaseData = [];
    const projectId = this.projectId;
    const projectIdValue = projectId == null ? null : projectId;
    selectedChaseIds.forEach(s => {
      const chaseData = {
        ChaseId: s,
        ProjectId: projectIdValue,
        RetrievallTypeId: DocumentSourceType.PEND,
      };

      memberCentricChaseData.push(chaseData);
    });

    const memberChasesStringified = JSON.stringify(memberCentricChaseData);
    this.upLoadInfo.formData.append("MemberCentricChaseUpload", memberChasesStringified);
    this.continueWithUpload(this.urlCopy);
  }

  continueWithUpload(list: String[]): void {
    if (this.usePresignedURLs) {
      let fileNum = 0;
      for (const item in list) {
        if (StringHelper.isAvailable(item)) {
          this.upLoadInfo.formData.append(fileNum.toString(), list[item].toString());
          fileNum++;
        }}
      this.upLoadInfo.formData.append("NumFiles", list.length.toString());
      this.upLoadInfo.formData.delete("Document");
      this.medicalRecordUploadService.uploadPresigned(this.upLoadInfo.formData).subscribe(
        data => {
          this.changeDetector.markForCheck();
          this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
          this.upLoadInfo.success();
        },
        err => {
          this.upLoadInfo.formData = new FormData();
        });
      } else {
      this.medicalRecordUploadService.upload(this.upLoadInfo.formData).subscribe(
        data => {
          this.changeDetector.markForCheck();
          this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
          this.upLoadInfo.success();
        },
        err => {
          this.messagingService.showToast("Error while uploading Document, please try again.", SeverityType.ERROR);
      });
    }
  }

  cancelUpload(): void {
    this.showUploadWarningModal = false;
  }

  get uploadData(): KeyValuePairs<string> {
    if (this.summaryItems.isEmpty()) {
      return [];
    } else {
      return [
        { key: "ChaseId", value: this.chaseId },
        { key: "ProjectId", value: this.projectId.toString() },
        { key: "RetrievalTypeId", value: DocumentSourceType.PEND.toString() },
      ];
    }
  }

  get showPendAction(): boolean {
    if (this.isClinical !== undefined) {
      return JSON.parse(this.isClinical);
    } else {
      return false;
    }
  }

  get chaseId(): number {
    return this.chaseDetailState.chaseId;
  }

  get isMemberCentricChase(): boolean {
    return (this.memberCentricData != null && this.memberCentricData.length > 0);
  }
}
