import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { AppointmentsSearchRequest } from "../address-detail/address-detail-info/address-detail-info-appointments/address-detail-info-appt.model";
import { AppointmentsForm } from "./appointments-form.model";

@Injectable({ providedIn: "root" })
export class AppointmentService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }


  getForAddressDetails(searchRequest: AppointmentsSearchRequest): Observable<any[]> {
    // NOTE: "fieldTech" has nothing to do with appointments, it's just where the code is.
    const url = `${this.baseApiUrl}fieldTech/appointments`;
    return this.http.post(url, searchRequest) as Observable<any[]>;
  }

  save(appointments: AppointmentsForm): Observable<null> {
    const { appointmentId } = appointments;
    const url = `${this.baseApiUrl}retrieval/saveAppointments?appointmentId=${appointmentId}`;
    return this.http.post(url, appointments) as Observable<null>;
  }

  saveList(appointments: AppointmentsForm[]): Observable<null> {
    const url = `${this.baseApiUrl}retrieval/appointments/save`;
    return this.http.post(url, appointments) as Observable<null>;
  }

  delete(addressId: number, appointmentId: number): Observable<null> {
    const url = `${this.baseApiUrl}retrieval/cancelAppointment?retrievalId=${addressId}&appointmentId=${appointmentId}&actionCancel=Delete`;
    return this.http.post(url, {}) as Observable<null>;
  }
}
