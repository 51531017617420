
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-clinical-productivity-and-accuracy-overall",
  templateUrl: "./clinical-productivity-and-accuracy-overall.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductivityAndAccuracyOverallComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL;

    constructor() {
      this.filters = ["Projects", "AbstractionDate", "Products", "LOB", "RetrievalOwner"];
    }

}
