import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapMemberSearchResult = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "MemberSearchResult")
    .forMember("memberId", o => o.memberId)
    .forMember("enrolleeId", o => o.enrolleeId)
    .forMember("memberName", o => o.memberName)
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberMiddleName", o => o.memberMiddleName)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberDateOfBirth", o => o.memberDateOfBirth)
    .forMember("memberGender", o => o.memberGender);
};
export class MemberSearchResult {
  memberId?: number;
  enrolleeId?: string;
  memberName?: string;
  memberFirstName?: string;
  memberMiddleName?: string;
  memberLastName?: string;
  memberDateOfBirth?: string;
  memberGender?: string;

  constructor(options: {
    memberId?: number;
    enrolleeId?: string;
    memberName?: string;
    memberFirstName?: string;
    memberMiddleName?: string;
    memberLastName?: string;
    memberDateOfBirth?: string;
    memberGender?: string;
  }) {
    this.memberId = options.memberId;
    this.enrolleeId = options.enrolleeId;
    this.memberName = options.memberName;
    this.memberFirstName = options.memberFirstName;
    this.memberMiddleName = options.memberMiddleName;
    this.memberLastName = options.memberLastName;
    this.memberDateOfBirth = options.memberDateOfBirth;
    this.memberGender = options.memberGender;

  }
}

