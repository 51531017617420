import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { EntityType } from "../../../../../../api/member-validation/entity-type.enum";
import { ENTITY_SOURCE_CATEGORY_NAME, EXEMPT_FROM_SCORING, PROVIDER_ID } from "../../attributes";
import { Diagnosis } from "../diagnosis/diagnosis.model";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-exempt-diagnosis",
  template: `<form-factory [formGroup]="form" [model]="exemptInput" (onChange)="save($event)"></form-factory>`,
  styleUrls: ["./exempt-diagnosis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ExemptDiagnosisComponent implements OnInit {
  @Input() diagnosis: Diagnosis;
  form: FormGroup;
  exemptInput: Checkbox;

  constructor(
    private readonly formService: FormService,
    private readonly riskService: RiskService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  save(event: boolean): void {
    const selectedExemptDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses
      .find(diagnosis => diagnosis.id === this.diagnosis.id);

    const currentExemptAttribute = selectedExemptDiagnosis.attributes
      .find(attribute => attribute.attributeId === EXEMPT_FROM_SCORING.attributeId);

    let newExemptAttribute;
    if (currentExemptAttribute) {
      newExemptAttribute = { ...currentExemptAttribute, value: event ? "1" : "0" };
    } else {
      newExemptAttribute = {
        chaseId: selectedExemptDiagnosis.chaseId,
        entityId: selectedExemptDiagnosis.entityId,
        entityTypeId: EntityType.DIAGNOSIS,
        parentEntityId: selectedExemptDiagnosis.parentEntityId,
        ...EXEMPT_FROM_SCORING,
        value: event ? "1" : "0",
      };
    }

    const diagosisAttributes = selectedExemptDiagnosis.attributes.filter(this.isAvailableAttribute.bind(this));
    diagosisAttributes.push(newExemptAttribute);

    this.saveAttributes(diagosisAttributes);
  }

  private initializeForm(): void {
    this.exemptInput = new Checkbox({
      key: `Diagnosis_Exempt`,
      value: this.diagnosis.exempt,
    });

    this.form = this.formService.createFormGroup([this.exemptInput]);
  }

  private saveAttributes(attributes) {
    const exemptDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses
      .findIndex(diagnosis => diagnosis.id === this.diagnosis.id);

    this.riskService
      .save(attributes)
      .subscribe(
        newAttributes => this.riskService.setDiagnosisAttributes(exemptDiagnosisIndex, newAttributes),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private isAvailableAttribute(attribute: any): boolean {
    return attribute != null
      && NumberHelper.isGreaterThan(attribute.attributeId, 0)
      && !attribute.disabled
      && (!attribute.isAdmin || attribute.attributeId === PROVIDER_ID.attributeId)
      && attribute.attributeId !== EXEMPT_FROM_SCORING.attributeId
      && attribute.attributeId !== ENTITY_SOURCE_CATEGORY_NAME.attributeId;
  }

}
