import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HeaderStatisticsComponent } from "./header-statistics.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    HeaderStatisticsComponent,
  ],
  exports: [
    HeaderStatisticsComponent,
  ],

})
export class HeaderStatisticsModule { }
