import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { CatalyticNumerator } from "../catalytic-numerator.model";

@Component({
  selector: "member-acceptance-result",
  templateUrl: "./acceptance-result.component.html",
  styleUrls: ["./acceptance-result.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptanceResultComponent implements OnInit {
  @Input() reviewedByUser: boolean;
  @Input() numerators: CatalyticNumerator;
  @Output() onSelect = new EventEmitter<CatalyticNumerator>();

  activeBtnToggle = false;
  denyBtnToggle = false;

  ngOnInit() {
    this.setAcceptanceButtons();
  }

  get acceptBtnClass(): string {
    return this.activeBtnToggle ? "accept-btn--active" : "accept-btn";
  }

  get denyBtnClass(): string {
    return this.denyBtnToggle ? "deny-btn--active" : "deny-btn";
  }

  get isReviewedByUser(): boolean {
    return this.reviewedByUser;
  }

  setAcceptanceButtons(): void {
    if (this.isReviewedByUser && NumberHelper.isAvailable(this.numerators.accepted)) {
      this.numerators.accepted ? this.toggleActiveBtn() : this.toggleDenyBtn();
    }
  }

  onReview(event: MouseEvent, isAccepted: boolean): void {
    isAccepted ? this.toggleActiveBtn() : this.toggleDenyBtn();
    this.numerators = new CatalyticNumerator({ ...this.numerators, accepted: isAccepted ? 1 : 0 });
    this.emitOnSelect(event);
  }

  toggleActiveBtn(): void {
    this.activeBtnToggle = true;
    this.denyBtnToggle = false;
  }

  toggleDenyBtn(): void {
    this.denyBtnToggle = true;
    this.activeBtnToggle = false;
  }

  emitOnSelect(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.onSelect.emit(this.numerators);
  }
}
