import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { filter, map, switchMap } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { ParameterService } from "../../../../../core/navigation/parameter.service";
import { UserService } from "../../../../../core/user/user.service";
import { MemberCentricChase } from "../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { MemberCentricDocAttachmentService } from "../../../../../shared/membercentric-doc-attachment/membercentric-doc-attachment.service";
import { IOnUpload, UploadComponent } from "../../../../../shared/upload/upload.component";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { MedicalRecordUploadService } from "../../../../api/medical-record-upload/medical-record-upload.service";
import { WorkflowStatusDb } from "../../../../api/workflow/workflow-status-db.enum";
import { DocumentSourceType } from "../../../retrieval/retrieval-document-source-type.enum";
import { ChaseDetailState } from "../chase-detail-state.model";
import { ChaseDetailStateService } from "../chase-detail-state.service";

@Component({
  selector: "member-chase-detail-documents",
  templateUrl: "./chase-detail-documents.component.html",
  styleUrls: ["./chase-detail-documents.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailDocumentsComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  chaseId: number;
  uploadSuccessMessage = "Thank you for uploading this medical record.  We are now processing the file.  Please check back shortly and your document will be attached.";
  uploadSuccessMessage1 = "Thank you for uploading medical record : ";
  uploadSuccessMessage2 = ".  We are now processing the file.  Please check back shortly and your document will be attached.";
  private usePresignedURLs: boolean;


  isRequestDocumentModalOpen = false;
  showUploadWarningModal = false;
  fromPage: "review" | "mrr" | "or1" | "clientoverread" | string;
  user: UserToken;
  upLoadInfo: IOnUpload;
  chaseDetailState: ChaseDetailState;
  showMemberCentricDocAttachmentInfo = false;
  memberCentricData: MemberCentricChase[];
  isMemberCentricProject = false;
  @ViewChild("fileUpload", { static: true }) fileUpload: UploadComponent;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private parameterService: ParameterService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private memberCentricDocAttachmentService: MemberCentricDocAttachmentService
  ) { }

  ngOnInit() {
    this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
    this.fromPage = this.parameterService.getNormal("fromPage", "");

    this.user = this.userService.getUserToken();

    this.sink.add(
      this.chaseDetailStateService.state
        .pipe(
          map(chaseDetailState => {
            this.chaseDetailState = chaseDetailState;
            return chaseDetailState.project?.projectId;
          }),
          filter(projectId => NumberHelper.isAvailable(projectId)),
          switchMap(projectId => this.memberCentricDocAttachmentService.verfiyIfMemberCentricChase(this.chaseId, projectId)),
          map(memberCentricChase => {
            if (ArrayHelper.isAvailable(memberCentricChase)) {
              this.memberCentricData = memberCentricChase;
            }
          })
        ).subscribe(_ => this.changeDetector.markForCheck()),

      this.medicalRecordUploadService.isMemberCentricProject.subscribe(data => {
        this.isMemberCentricProject = data;
      })
    );

    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  uploadDocument(event: IOnUpload): void {
    this.upLoadInfo = event;
    if (this.uploadWarningLogic) {
      this.showUploadWarningModal = true;
    } else {
    if (this.usePresignedURLs) {
      let fileNum = 0;
      for (const item in event.originalFilenameList) {
        if (StringHelper.isAvailable(item)) {
          this.upLoadInfo.formData.append(`OFN${fileNum}`, event.originalFilenameList[fileNum]);
          fileNum++;
        }
      }
      fileNum = 0;
      for (const item in event.presignedURLList) {
          if (StringHelper.isAvailable(item)) {
            event.formData.append(fileNum.toString(), event.presignedURLList[item].toString());
            fileNum++;
          }}
      event.formData.append("NumFiles", event.presignedURLList.length.toString());
      event.formData.delete("Document");
    }
    this.continueWithUpload();
      }
  }

  uploadDocumentsSeperately(event: IOnUpload): void {
    this.upLoadInfo = event;
    if (this.uploadWarningLogic) {
      this.showUploadWarningModal = true;
    } else {
      if (this.usePresignedURLs) {
      let fileNum = 0;
      for (const item in event.originalFilenameList) {
        if (StringHelper.isAvailable(item)) {
          this.upLoadInfo.formData.append(`OFN${fileNum}`, event.originalFilenameList[fileNum]);
          fileNum++;
        }
      }
      fileNum = 0;
      for (const item in event.presignedURLList) {
          if (StringHelper.isAvailable(item)) {
            event.formData.append(fileNum.toString(), event.presignedURLList[item].toString());
            fileNum++;
          }}
      event.formData.append("NumFiles", event.presignedURLList.length.toString());
      event.formData.delete("Document");
      this.continueWithUploadSeparately();
        }
    }
  }

  uploadDocToMemberCentricChases(selectedChaseIds: number[]): void {
    const memberCentricChaseData = [];
    const projectId = this.chaseDetailState.project.projectId;
    const projectIdValue = projectId == null ? "" : projectId;
    selectedChaseIds.forEach(s => {
      const chaseData = {
        ChaseId: s,
        ProjectId: projectIdValue,
        RetrievallTypeId: DocumentSourceType.MRRUpload,
      };

      memberCentricChaseData.push(chaseData);
    });

    const memberChasesStringified = JSON.stringify(memberCentricChaseData);
    this.upLoadInfo.formData.append("MemberCentricChaseUpload", memberChasesStringified);
    this.continueWithUploadSeparately();
  }

  continueWithUpload(): void {
    if (this.usePresignedURLs) {
        this.medicalRecordUploadService.uploadPresigned(this.upLoadInfo.formData).subscribe(
          data => {
            this.changeDetector.markForCheck();
            this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
            this.upLoadInfo.success();
          },
          err => {
          }
        );
      } else {
        this.medicalRecordUploadService.upload(this.upLoadInfo.formData).subscribe(
          data => {
            this.changeDetector.markForCheck();
            this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
            this.upLoadInfo.success();
          },
          err => {
            this.messagingService.showToast("Error while uploading Document, please try again.", SeverityType.ERROR);
          }
        );
      }
  }

  continueWithUploadSeparately(): void {
    // Going to submit each File seperately to Upload Service

    const documents = this.upLoadInfo.formData.getAll("Document");
    const chaseId = this.upLoadInfo.formData.get("ChaseId");
    const projectId = this.upLoadInfo.formData.get("ProjectId");
    const retrievalTypeId = this.upLoadInfo.formData.get("RetrievalTypeId");
    let fileNumCounter = 0;

    for (const document of documents) {
      const doc = (document as File);
      const fileName = doc.name;
      const newFormData = new FormData();

      newFormData.append("Document", document);
      newFormData.append("ChaseId", chaseId);
      newFormData.append("ProjectId", projectId);
      newFormData.append("RetrievalTypeId", retrievalTypeId);

      if (this.usePresignedURLs) {
        const name = this.upLoadInfo.presignedURLList[fileNumCounter.toString()];
        const OFN = this.upLoadInfo.originalFilenameList[fileNumCounter.toString()];

        newFormData.append("Size", doc.size.toString());
        newFormData.append("0", name);
        newFormData.append("OFN0", OFN);
        newFormData.append("NumFiles", "1");
        newFormData.delete("Document");
        fileNumCounter++;
        this.medicalRecordUploadService.uploadPresigned(newFormData).subscribe(
            data => {
              this.changeDetector.markForCheck();
              this.fileUpload.deleteFile(fileName);
              this.messagingService.showToast(`${this.uploadSuccessMessage1}${fileName}${this.uploadSuccessMessage2}`, SeverityType.SUCCESS);
              this.upLoadInfo.success();
            },
            err => {
            }
          );
        } else {
          this.medicalRecordUploadService.upload(newFormData).subscribe(
            data => {
              this.changeDetector.markForCheck();
              this.messagingService.showToast(`${this.uploadSuccessMessage1}${fileName}${this.uploadSuccessMessage2}`, SeverityType.SUCCESS);
              this.fileUpload.deleteFile(fileName);
            },
            err => {
              this.messagingService.showToast(`Error while uploading Document: ${fileName}, please try again.`, SeverityType.ERROR);
            }
          );
        }
    }
  }
  cancelUpload(): void {
    this.upLoadInfo.formData = null;
    this.showUploadWarningModal = false;
  }

  openAdditionalDocumentationModal(): void {
    this.isRequestDocumentModalOpen = true;
  }

  get addressId(): number {
    if (!NumberHelper.isAvailable(this.chaseDetailState.masterDocumentSourceId)) {
      return null;
    }
    return this.chaseDetailState.masterDocumentSourceId;
  }

  get requestAdditionalDocumentsButtonHidden(): boolean {
    const hidden = (!this.IsClientPlatformUser && (StringHelper.isAvailable(this.fromPage) && !this.isOverread2));

    return hidden;
  }

  get IsClientPlatformUser(): boolean {
    return (this.user.organizationId !== 1); // HDVI Organization ID = 1.
  }

  get isOverread2(): boolean {
    return StringHelper.isAvailable(this.fromPage) && (this.fromPage === "clientoverread");
  }

  get uploadData(): KeyValuePairs<string> {
    if (!this.chaseDetailState.project) {
      return null;
    } else {
      const projectId = this.chaseDetailState.project.projectId;
      const projectIdValue = projectId == null ? "" : projectId;

      return [
        { key: "ChaseId", value: this.chaseId.toString() },
        { key: "ProjectId", value: projectIdValue.toString() },
        { key: "RetrievalTypeId", value: DocumentSourceType.MRRUpload.toString() },
      ];
    }
  }

  get uploadWarningLogic(): boolean {
    return (!this.isMemberCentricProject &&
      this.chaseDetailState.workflowStatus > WorkflowStatusDb.Abstraction &&
      NumberHelper.isGreaterThan(this.chaseDetailState.chaseDocumentId, 0)
      );
  }

  get isMemberCentricChase(): boolean {
    return (this.memberCentricData != null && this.memberCentricData.length > 0);
  }
}
