export class RegExHelper {
  // https://www.regular-expressions.info/dates.html
  static readonly date = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/);
  static readonly dateWithAndWithoutBackSlash = new RegExp(/^(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d$|^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])(19|20)\d\d$/);
  // https://stackoverflow.com/questions/19715303/regex-that-accepts-only-numbers-0-9-and-no-characters
  static readonly wholeNumber = new RegExp(/^[0-9]*$/);

  static readonly manuelIVAchaseKey =  new RegExp(/^HST-MN-[1-9]{1,9}$/);

  static readonly number = new RegExp(/^-?(0|[1-9]\d*)?$/);

  // https://www.esdm.co.uk/a-regular-expression-for-numbers-from-0-to-100
  static readonly number0To100 = new RegExp(/^([0-9]|[1-9][0-9]|100)$/);

  static readonly phoneNumber = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

  static readonly invoiceAmount = new RegExp(/^\d*(\.\d{0,2})?$/);

  static readonly emailPattern = new RegExp(/^([A-Za-z0-9_\'-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/);

  static readonly dateYearFirstPattern = new RegExp(/^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/);

  static readonly passwordPattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/);

  static readonly dateYearFirstSlashPattern = new RegExp(/^(19|20)\d\d[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])$/);

  static readonly tagPattern = new RegExp(/^[a-zA-Z0-9 _]*$/);

  static readonly alphaNumeric = new RegExp(/^[a-zA-Z0-9 ]*$/);

  // Valid characters for a file name are 0-9 a-z A-Z . _ - & and space
  static readonly fileNamePattern = new RegExp(/^[a-zA-Z0-9._ -\d\-&]*$/);

  static readonly emailWithCsvPattern = new RegExp(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/);

  static readonly validAnnotationPattern = new RegExp(/.*\/members\/chase(v2)?\/[0-9]+.*/);

  // Date offset pattern (ex GMT-0600 or GMT+0100)
  static readonly dateOffset = new RegExp(/GMT(-|\+)\d\d\d\d/);

  // Valid ICD Pattern are (ex I472,I4891,K5190)
  static readonly isValidICDCodePattern = new RegExp(/^[^.\\-]{4,}$/);
}
