<div class="contact-wrapper"  *ngIf="shouldDisplayContactDropdown">
    <div class="contact-label">Contact:</div>
    <div class="contact-control">
        <form-factory [formGroup]="formGroup" [model]="contactsInput" (onChange)="onSelectContact($event)">
        </form-factory>   
    </div>
    <div class="contact-control" *ngIf="!isPrimaryContact; else primaryContactTemplate">
        <app-button classes="small link" styleClass="contact-label" text="SET AS PRIMARY CONTACT"
            (onClick)="showConfirmationModal()">
        </app-button>
    </div>
    <ng-template #primaryContactTemplate>
        <div class="contact-label contact-label__primary" >PRIMARY CONTACT</div>
    </ng-template>
</div>  
<div  class="align-content"  [ngClass]="{'top-valid-content': shouldDisplayContactDropdown}">
        <form-factory class="text-right " [formGroup]="formGroup" [model]="validInput" *ngIf="isPrimaryContact" (onChange)="saveContactValid()" >
        </form-factory>
</div>  
<app-confirmation-modal [(visible)]="isPrimaryContactModalVisible" header="WARNING" acceptText="CONTINUE" rejectText="CANCEL"
    (onYes)="onSetAsPrimaryContact()" class="text-center">
    There is already a primary contact set for this address. Do you want to continue?
</app-confirmation-modal>