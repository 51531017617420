import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { MenuModule } from "../menu/menu.module";
import { PanelModule } from "../panel/panel.module";
import { PendModule } from "../pend/pend.module";
import { TagModule } from "../tags/tag.module";
import { AddressAssignmentModalComponent } from "./address-assignment-modal/address-assignment-modal.component";
import { AddressGridComponent } from "./address-grid.component";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DynamicFormsModule,
    GridModule,
    PanelModule,
    PendModule,
    MenuModule,
    TagModule,
  ],
  declarations: [
    AddressGridComponent,
    AddressAssignmentModalComponent,
  ],
  exports: [
    AddressGridComponent,
    AddressAssignmentModalComponent,
  ],
})
export class AddressGridModule { }
