import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { List } from "immutable";
import { PendTimelineItem } from "../../../platform/modules/internal-pends/pend-timeline-item.model";
import { MemberTimelineItem } from "../../../platform/modules/member/member-timeline-item.model";
import { RetrievalTimelineItem } from "../../../platform/modules/retrieval/retrieval-timeline-item.model";

@Component({
  selector: "app-timeline-detail",
  templateUrl: "./timeline-detail.component.html",
  styleUrls: ["./timeline-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDetailComponent {
  @Input() timeline: List<RetrievalTimelineItem> | List<MemberTimelineItem> | List<PendTimelineItem> = List([]);
  @Input() isChaseTimeline = false;
  trackByIndex(index, item) {
    return index;
  }
}
