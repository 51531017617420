{{ text }}
<div
  *ngIf="isEditViewButtonVisible"
  class="pi pi-cog button"
  (click)="editGridView($event)">
</div>
<!-- <app-button
  *ngIf="isEditViewButtonVisible"
  class="button"
  classes="transparent"
  type="pi"
  icon="cog"
  (onClick)="editGridView($event)">
</app-button> -->