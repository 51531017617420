<h3>Enrollee Identification</h3>
<form-factory [formGroup]="form" [model]="enrolleeIdInput"></form-factory>
<form-factory class="subscriber" [formGroup]="form" [model]="subscriberTypeSaveGroup" (onChange)="handleChange($event)"></form-factory>

<div class="enrholder">
    <div class="enrholder__enr">
        <form-factory class="enrholder__enr--member" [formGroup]="form" [model]="memberIdSaveGroup" (onChange)="handleChange($event)"
        (input)="validateInput($event)"
        ></form-factory>
    </div>
    
    <h3>Enrollee Demographics</h3>
    <form-factory [formGroup]="form" [model]="firstNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
    <form-factory [formGroup]="form" [model]="lastNameSaveGroup" (onChange)="handleChange($event)"></form-factory>
    <form-factory [formGroup]="form" [model]="dobSaveGroup" (onChange)="handleChange($event)"></form-factory>
    <form-factory [formGroup]="form" [model]="genderSaveGroup" (onChange)="handleChange($event)"></form-factory>
    
    <h3>Enrollment Period</h3>
    <form-factory [formGroup]="form" [model]="planIdSaveGroup" (onChange)="handleChange($event)"></form-factory>
    <div class="enrholder__enr">
        <form-factory class="enrholder__enr--member" [formGroup]="form" [model]="fromDateSaveGroup" (onChange)="handleChange($event)"></form-factory>
        <form-factory class="enrholder__enr--member" [formGroup]="form" [model]="thruDateSaveGroup" (onChange)="handleChange($event)"></form-factory>
    </div>
    <form-factory [formGroup]="form" [model]="premiumAmountSaveGroup" (onChange)="handleChange($event)"></form-factory>
    <div class="enrholder__enr">
        <form-factory class="enrholder__enr--aptc" [formGroup]="form" [model]="aptcSaveGroup" (onChange)="handleChange($event)"></form-factory>
    </div>
    <form-factory [formGroup]="form" [model]="ratingAreaSaveGroup" (onChange)="handleChange($event)"></form-factory>
    
</div>