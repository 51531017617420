import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Contact } from "../../../contact.model";

import { AddressDetailInfoEdit } from "./address-detail-info-edit.model";

@Injectable({
  providedIn: "root",
})
export class AddressDetailInfoEditService {
  reset = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService
  ) { }

  resetAddress(addressId: number) {

    this.reset.next(addressId);
  }

  getAddressDetailsForEdit(addressId: number): Observable<AddressDetailInfoEdit> {
    const url = `${this.baseApiUrl}Address/AddressDetailsForEdit?addressId=${addressId}`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curry("default", "AddressDetailInfoEdit"))
      );
  }

  updateAddressDetails(data: AddressDetailInfoEdit): Observable<number> {
    const url = `${this.baseApiUrl}Address/UpdateAddressDetails`;

    return this.http
    .post(url, data)
    .pipe(
      map((response: any) => response as number));
  }

  updatePrimaryContact(masterDocumentSourceId: number, contact: Contact): Observable<number> {
    const url = `${this.baseApiUrl}address/primaryContact`;
    const request = {
      retrievalLocationId: masterDocumentSourceId,
      ...contact,
    };

    return this.http.post(url, request)
      .pipe(
        map((response: number) => response)
      );
  }

  resetAccessPortalCode(addressId: number): Observable<null> {
    if (addressId == null) {
      return throwError("Address Id is invalid.");
    }
    const url = `${this.baseApiUrl}address/ResetAccessPortalCode?masterDocumentSourceId=${addressId}`;
    return this.http.post(url, {}) as Observable<null>;
  }
  getSpecialHandlingReasons(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}tag/specialhandlingreasons`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curryMany("TagItem", "SelectableInput"))
      );
  }
}
