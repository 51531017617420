import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { ProviderPacketModel } from "../chart-upload/email-verification/provider-packet.model";
import { ProviderDetailStateService } from "../provider-detail-state.service";
import { ReviewAuthLetterService } from "./review-auth-letter.service";

@Component({
    selector: "app-review-auth-letter",
    templateUrl: "./review-auth-letter.component.html",
    styleUrls: ["./review-auth-letter.component.scss"],
    providers: [ReviewAuthLetterService],
})
export class ReviewAuthLetterComponent implements OnInit, OnDestroy {
    verificationCode: string;
    organizationId: string;
    providerPacketModel: ProviderPacketModel;
    fileContent: string;
    currentBlob: any;
    pdfUrl: string;
    welcomeText = "";
    supportText = "";
    imagePath = "";
    private sink = new SubSink();
    constructor(
        private reviewAuthLetterService: ReviewAuthLetterService,
        private router: Router,
        public location: Location,
        private providerDetailStateService: ProviderDetailStateService
    ) {
        this.verificationCode = localStorage.getItem("verificationcode");
        this.organizationId = localStorage.getItem("orgId");
    }

    ngOnInit() {
        this.sink.add(this.providerDetailStateService.state.subscribe(result => {
            this.welcomeText = `Please review Medical Record request and kindly upload records to
                            <a href='providergateway' target='_blank'>Platform.Reveleer.com/ProviderGateway</a> using your Provider Pin #.`;
            this.imagePath = result.filter(x => x.attributeCode === "Logo")[0].attributeValue;

        }));
        this.getContent(this.verificationCode);
    }

    getContent(verificationCode: string): void {
        this.reviewAuthLetterService
            .getContent(verificationCode)
            .subscribe(items => {
                this.fileContent = items.fileName;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // IE workaround
                    const byteCharacters = atob(this.fileContent);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    this.currentBlob = new Blob([byteArray], { type: "application/pdf" });
                    this.pdfUrl = URL.createObjectURL(this.currentBlob);
                } else {
                    // much easier if not IE
                    const pdfFile = this.convertbase64toArrayBuffer(items.fileName);
                    this.currentBlob = new Blob([pdfFile], { type: "application/pdf" });
                    this.pdfUrl = URL.createObjectURL(this.currentBlob);

                }
            });
    }

    convertbase64toArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    print() {
        this.getContent(this.verificationCode);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const fileName = "MRCS.pdf";
            window.navigator.msSaveOrOpenBlob(this.currentBlob, fileName);
        } else {
            window.open(this.pdfUrl).print();
        }

    }

    ngOnDestroy(): void {
        this.sink.unsubscribe();
    }
}
