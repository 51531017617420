import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserToken } from "../../../auth/user-token.model";
import { LocalService } from "../../../core/storage/local.service";
import { UserService } from "../../../core/user/user.service";
import { FormService } from "../../../dynamic-forms/form.service";
import { Checkbox } from "../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridView } from "../grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../grid-menu/grid-views/grid-views-state.model";
import { GridRequest } from "../models/grid-request.model";
import { SaveQuery } from "./save-query.model";
import { MyQueriesService } from "./save-query.service";

@Component({
  selector: "app-save-query",
  templateUrl: "./save-query.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveQueryComponent implements OnInit, OnChanges {
  @Input() get visible(): boolean {
    return this.modalVisible;
  }

  set visible(value: boolean) {
    this.modalVisible = value;
  }

  constructor(
    private readonly formService: FormService,
    private readonly myQueriesService: MyQueriesService,
    private readonly userService: UserService,
    private router: Router,
    private readonly session: LocalService
  ) { }

  get isValid(): boolean {
    if (this.formGroup == null) {
      return false;
    } else {
      return this.formGroup.valid;
    }
  }
  @Input() request: GridRequest;
  @Input() views: GridViewsState;
  @Input() saveQueryAttributeId = 0;
  @Input() savedQueryName = "";
  @Input() isSavedQueryPublic = false;
  @Input() savedQueryView: GridView;
  @Input() savedQueryId = 0;
  @Output() isSavedQueryUpdated = new EventEmitter<number>();
  modalVisible = false;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  formGroup: FormGroup;
  queryNameInput: Textbox;
  isPublicQueryCheckbox: Checkbox;
  private user: UserToken;

  onHide() {
    this.onClose.emit(this.modalVisible);
  }

  ngOnChanges() {
    if (StringHelper.isAvailable(this.savedQueryName)) {
      this.formGroup?.get(this.queryNameInput.key).setValue(this.savedQueryName);
      this.formGroup?.get(this.isPublicQueryCheckbox.key).setValue(this.isSavedQueryPublic);
    }
  }

  ngOnInit(): void {
    this.user = this.userService.getUserToken();
    this.createSaveQueryForm();
  }

  createSaveQueryForm() {
    this.queryNameInput = new Textbox({
      key: "queryName",
      label: "QUERY NAME",
      placeholder: "Enter Name",
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
      errorMessages: {
        required: "Query Name is required.",
        minlength: "Query Name cannot be less than 4 characters.",
        maxlength: "Query Name cannot be more than 50 characters.",
      },
      value: StringHelper.isAvailable(this.savedQueryName) ? this.savedQueryName : "",
    });

    this.isPublicQueryCheckbox = new Checkbox({
      key: "isPublicQueryCheckbox",
      hidden: !this.user.isAdminRole,
      label: "Make Public to Team",
      value: this.isSavedQueryPublic,
    });

    this.formGroup = this.formService.createFormGroup([this.queryNameInput, this.isPublicQueryCheckbox]);
  }

  saveQuery() {
    const filtersData = this.request.filters
      .filter(filter =>
        filter.hasValue && filter.show && filter.showChip && StringHelper.isAvailable(filter.displayName)
      );

    const columns = NumberHelper.isGreaterThan(this.savedQueryId, 0)
                  ? Object.keys(this.savedQueryView.columns)
                  : Object.keys(this.views.selectedView.columns);

    if (this.formGroup.valid) {
      const query = new SaveQuery({
        applicationAttributeId : NumberHelper.isGreaterThan(this.savedQueryId, 0) ? this.savedQueryId : 0,
        attributeId: this.saveQueryAttributeId,
        configurationName: StringHelper.clean(this.formGroup.get(this.queryNameInput.key).value),
        organizationId: this.formGroup.get(this.isPublicQueryCheckbox.key).value ? this.user.organizationId : null,
        columns,
        filters: filtersData.map(data => new SelectableInput({ text: data.key, value: data.value })),
      });

      this.myQueriesService.saveQuery(query)
        .subscribe(data => {
          if (query.applicationAttributeId === 0) {
            this.session.put("sessionQueryId", data.applicationAttributeId);
            return this.router.navigate(["/query/myqueries"]);
          } else {
             setTimeout(() => {
              this.visible = false;
              this.isSavedQueryUpdated.emit(query.applicationAttributeId);
            });
          }
        });
    } else {
      this.formService.markAllAsTouched(this.formGroup);
    }
  }

}
