export enum PendCategories {
  ALL_PEND_CODES = 0,
  REFUSAL_REQUEST_REJECTED = 1,
  FOUR_DIGIT_PEND_CODES= 2,
  CODES_USED_DURING_DATA_LOADS = 3,
  FAILURE_TO_LOCATE_MEDICAL_RECORDS = 4,
  PROVIDER_INDICATES_CANNOT_RETERIEVE = 5,
  IVA_RISK_SPECIFIC = 6,
  CLINICAL_CODES = 7,
  GENERAL_CODES= 8,
  CUSTOM_CREATED_CODES = 9,
  MEMBER_CENTRIC = 10,
}
