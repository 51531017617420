import { NgModule } from "@angular/core";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TabViewModule } from "primeng/tabview";
import { SharedModule } from "../../../shared/shared.module";
import { AuditModule } from "../audit/audit.module";
import { DashboardModule } from "../dashboard/dashboard.module";
import { InternalPendsModule } from "../internal-pends/internal-pends.module";
import { ProjectModule } from "../project/project.module";
import { ChpMyScoreComponent } from "./chp/chp-myscore.component";
import { ClinicalPageComponent } from "./clinical-page/clinical-page.component";
import { ClinicalQueueComponent } from "./clinical-queue/clinical-queue.component";
import { ClinicalRoutingModule } from "./clinical-routing.module";
import { ClinicalComponent } from "./clinical.component";

@NgModule({
    imports: [
        ClinicalRoutingModule,
        SharedModule,
        TabViewModule,
        AutoCompleteModule, // TODO: remove dependency when we can
        DashboardModule,
        InternalPendsModule,
        AuditModule,
        ProjectModule,
    ],
    declarations: [
        ClinicalComponent,
        ClinicalQueueComponent,
        ClinicalPageComponent,
        ChpMyScoreComponent,
    ],
    exports: [
        ClinicalPageComponent,
        TabViewModule,
    ],
})
export class ClinicalModule { }
