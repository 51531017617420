import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class InternetExplorerInterceptor implements HttpInterceptor {

  // NOTE: https://medium.com/@tiboprea/how-to-fix-internet-explorer-caching-of-ajax-requests-using-angular-5-2c489cf2d1f7
  // Internet Explorer caches all GET requests by default.

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = req;
    if (req.method === "GET") {
      newRequest = req.clone({
        headers: req.headers
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache"),
      });
    }

    return next.handle(newRequest);
  }
}
