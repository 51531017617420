import { IAutoMapper } from "../interfaces";

export const mapProjectAttributes = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProjectAttribute")
    .forMember("attributeId", o => o.attributeId)
    .forMember("attributeCode", o => o.attributeCode)
    .forMember("attributeName", o => o.attributeName)
    .forMember("attributeValue", o => o.attributeValue)
    .forMember("authorizationLetterAttributes", o => o.authorizationLetterAttributes);

};
