export class UserAttributeModel {

  readonly attributeId: number;
  readonly attributeName: string;
  readonly attributeValue: string;

  constructor(options: {

    attributeId: number;
    attributeName: string;
    attributeValue: string;


  }) {

    this.attributeId = options.attributeId;
    this.attributeName = options.attributeName;
    this.attributeValue = options.attributeValue;

  }
}
