import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-provider-outreach-by-contact-type",
  templateUrl: "./provider-outreach-by-contact-type.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderOutreachByContactTypeComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.PROVIDER_OUTREACH_BY_CONTACT_TYPE;

  constructor() {
    this.filters = ["Projects", "ContactDate"];
  }

}
