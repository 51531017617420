import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-hcc-summary-by-aid-dw",
  templateUrl: "./hcc-summary-by-aid-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HccSummaryByAidDwComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.HCC_SUMMARY_BY_AID_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];

}
