<h3 class="header__item">APPOINTMENTS</h3>
<app-button
  class="header__item"
  classes="small secondary"
  text="Schedule Appointment"
  (onClick)="showScheduleAppointmentModal($event)">
</app-button>

<app-basic-grid
  [configuration]="appointmentsGridConfiguration"
  [data]="appointmentsGridData"
  [actionTemplate]="actionTemplate">

  <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button
        text="Edit Appointment"
        (onClick)="clickedEditAppointment($event, rowData); localActionPopover.hide();">
      </app-button>
      <app-button
        text="Cancel Appointment"
        (onClick)="clickedDeleteAppointment($event, rowData); localActionPopover.hide();">
      </app-button>
    </div>
  </ng-template>

</app-basic-grid>

<retrieval-schedule-appointment-modal
  *ngIf="isScheduleAppointmentModalVisible"
  [(visible)]="isScheduleAppointmentModalVisible"
  [retrievalType]="retrievalType"
  [appointment]="appointment"
  (onSuccess)="appointmentsSaved()"
  (onHide)="resetAppointment()">
</retrieval-schedule-appointment-modal>

<retrieval-delete-appointment-modal
  [(visible)]="isDeleteAppointmentModalVisible"
  [appointment]="appointment"
  (onYes)="deleteAppointment()"
  (onCancel)="resetAppointment()">
</retrieval-delete-appointment-modal>