import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { PendStatus } from "../pend-status.enum";

@Component({
  selector: "app-pend-modal",
  templateUrl: "./pend-modal.component.html",
  styleUrls: ["./pend-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendModalComponent implements OnInit {
  @Input() clinical = false;
  @Input() hasClinicalNonClinicalChases = false;
  @Input() selectedChasesStatus: any[] = [];
  @Input() pageType = "";
  private pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;

    if (this.visible) {
      this.validateChases();
    } else {
      this.closing();
    }
  }
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<null>(true);
  @Output() onUpdate = new EventEmitter<null>(true);

  private pChases = [];
  @Input()
  get chases(): any[] {
    return this.pChases;
  }
  set chases(value: any[]) {
    this.pChases = value;
    this.chasesChange.emit(this.chases);
    this.validateChases();
  }
  @Output() chasesChange = new EventEmitter<any[]>();

  currentPendCode: string;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService
  ) { }

  ngOnInit() {
  }


  get hasChases(): boolean {
    return ArrayHelper.isAvailable(this.chases);
  }

  get chaseIds(): number[] {
    if (!this.hasChases) {
      return [];
    }

    return this.chases.map(chase => +(chase.chaseId || chase.chaseID));
  }

  get chasePendIds(): number[] {
    if (!this.hasChases) {
      return [];
    }

    return this.chases
      .map(chase => +chase.chasePendId)
      .filter(pendId => NumberHelper.isGreaterThan(pendId, 0));
  }

  get pendChases(): any[] {
    if (!this.hasChases) {
      return [];
    }

    return this.chases.filter(chase => (chase.pendStatusId === PendStatus.New || chase.pendStatusId === PendStatus.InProgress));

  }

  get hasPendChases(): boolean {
    return ArrayHelper.isAvailable(this.pendChases);
  }

  get normalChases(): any[] {
    if (!this.hasChases) {
      return [];
    }
    return this.chases.filter(chase => (chase.pendStatusId !== PendStatus.New && chase.pendStatusId !== PendStatus.InProgress));

  }

  get hasNormalChases(): boolean {
    return ArrayHelper.isAvailable(this.normalChases);
  }

  get chasePendCode(): any[] {
    if (!this.hasChases) {
      return [];
    }
    return this.chases.filter(chase => (chase.pendCode));
  }

  get isCurrentSelectedPendCodes(): boolean {
    let isNotRepeatedPendCode = true;
    this.currentPendCode = this.chasePendCode[0].pendCode;
    this.chases.forEach(x => {
        if (x.pendCode !== this.currentPendCode) {
          isNotRepeatedPendCode = false;
        }
      });
    return isNotRepeatedPendCode;
  }


  private validateChases(): void {
    if (this.visible && this.hasNormalChases && this.hasPendChases) {
      this.messagingService.showToast("Please select similar Chases for bulk actions - either with Pend or without Pend", SeverityType.ERROR);
      this.visible = false;
      this.changeDetector.markForCheck();
    } else if (this.visible && this.hasPendChases && !this.isCurrentSelectedPendCodes) {
      this.messagingService.showToast("Please select similar Chases for bulk actions - with similar Pend Codes", SeverityType.ERROR);
      this.visible = false;
      this.changeDetector.markForCheck();
    }
  }

  private closing(): void {
    this.visibleChange.emit(false);
    this.onHide.emit();
  }
}
