import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-chase-status-underlying-report-dw",
  templateUrl: "./chase-status-underlying-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseStatusUnderlyingReportDWComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.CHASE_STATUS_UNDERLYING_REPORT;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID];
}
