import { DateHelper } from "../../utilities/contracts/date-helper";

export class AlertNotification {
  readonly alertID: number;
  readonly alertType: number;
  readonly messsage: string;
  readonly createdDate: string;
  readonly objectType: string;
  readonly objectId: number;
  readonly loggedInUserId: number;
  readonly timeStamp: string;
  readonly alertURL: string;
  readonly recordCount?: number;

  constructor(options: {
    alertID: number;
    alertType: number;
    messsage: string;
    createdDate: string;
    objectType: string;
    objectId: number;
    loggedInUserId: number;
    timeStamp: string;
    alertURL: string;
    recordCount?: number;
  }) {
    this.alertID = options.alertID;
    this.alertType = options.alertType;
    this.messsage = options.messsage;
    this.createdDate = options.createdDate;
    this.objectType = options.objectType;
    this.objectId = options.objectId;
    this.loggedInUserId = options.loggedInUserId;
    this.timeStamp = DateHelper.format(options.timeStamp, "MM/DD/YYYY");
    this.alertURL = options.alertURL;
    this.recordCount = options.recordCount;
  }
}
