import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { obsolete } from "../../../../../../utilities/decorators/obsolete.decorator";
import { RetrievalModule } from "../../../retrieval.module";

@Injectable()
export class AddressDetailHeaderService {
  reset = new Subject<any>();
  resetHeaderMenu = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getAddressGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.AddressGd;
  }

  getCallTypeFromPath(): string {
    return this.route.snapshot.firstChild.firstChild.firstChild.children[0].params.callType;
  }

  resetAddress(addressId: number) {

    this.reset.next(addressId);
  }

  resetSubMenu(callTabVisible: boolean) {
    this.resetHeaderMenu.next(callTabVisible);
  }

  @obsolete("addressDetail/address-detail.service/getMenuItems") getMenuItems(): Observable<MenuItem[]> {

    const url = `${this.baseApiUrl}address/menuitems`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }
}
