import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GridColumnDefinition } from "../../grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../grid/models/grid-configuration.model";

@Component({
  selector: "app-kpi-details",
  templateUrl: "./kpi-details.component.html",
  styleUrls: ["./kpi-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiDetailsComponent implements OnInit {

  private columnHeader: GridColumnDefinition[];
  gridConfigurationModel: GridConfiguration;
  rowDataModel: any[];

  constructor(private router: Router, private changeDetector: ChangeDetectorRef) {
    this.columnHeader = [
      new GridColumnDefinition({ field: "id", header: "ChaseID", routeUrl: "/members/chase/:id" }),
      new GridColumnDefinition({ field: "name", header: "Name" }),
      new GridColumnDefinition({ field: "address", header: "Address" }),
    ];

    this.gridConfigurationModel = new GridConfiguration();
    this.gridConfigurationModel.columns = this.columnHeader;
    const pageSize = 25;
    this.gridConfigurationModel.pageSize = pageSize;
  }

  ngOnInit() {
    this.gridConfigurationModel.columns = this.columnHeader;


    // Static data for grid component.
    this.rowDataModel = [
      { id: "1272294", name: "Rajeev Sachdeva", address : "address 1" },
      { id: "1272295", name: "Jason Colbert", address: "address 2" },
      { id: "1272296", name: "Kevin Owens", address: "address 3" },
      { id: "1272297", name: "Ramesh Venkatakrishnan", address: "address 4" },
      { id: "1272298", name: "Bill Brace", address: "address 5" },
    ];
  }
}
