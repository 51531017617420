<div class="clinical-landing-container">

  <h3>{{headerTitle}}</h3>
  <div class="clinical-container">
    <div class="item" *ngFor="let item of headerStatistics; let i = index, trackBy: trackByIndex" [ngClass]="statisticSelectedClass(item.key)">
      <span>{{item.key}}</span><br>
      <a class="users-land-stats-item bold">
        <span (click)="applyStatisticsFilter(item.key)" [ngClass]="statisticClass(item.key)">{{item.value}}</span>
      </a>
    </div>
  </div>

  <div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-chase-grid [stateName]="stateName"
                      [additionalFilters]="additionalFilters"
                      [additionalColumns]="additionalColumns"
                      [clinical]="isClinical"
                      [pageType]="pageType"
                      [chaseIdRouteUrl]="chaseIdRouteUrl"
                      [showViews]="true"
                      [viewAttributeId]="viewAttributeId"></app-chase-grid>
    </div>
  </div>

</div>
