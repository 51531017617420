import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EveIcd } from "../../../chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/eve-icd.model";
import { FROM_DATE } from "../attributes";
import { CodingModel } from "./diagnosis/coding-model.model";

export const mapRiskEntity = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "RiskEntity")
    .forMember("id", o => o.id)
    .forMember("chaseId", o => o.chaseId)
    .forMember("entityId", o => o.entityId)
    .forMember("entityTypeId", o => o.entityTypeId)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("parentEntityId", o => o.parentEntityId)
    .forMember("isEveDiagnosis", o => o.isEveDiagnosis)
    .forMember("text", o => o.text)
    .forMember("diagnosisCode", o => o.diagnosisCode)
    .forMember("confidenceScore", o => o.confidenceScore)
    .forMember("dosFrom", o => o.dosFrom)
    .forMember("dosThrough", o => o.dosThrough)
    .forMember("attributes", o => o.attributes)
    .forMember("icdCodes", o => ArrayHelper.isAvailable(o.icdCodes) ? automapper.mapMany("default", "EveIcd", o.icdCodes) : [])
    .forMember("diagnoses", o => ArrayHelper.isAvailable(o.diagnoses) ? automapper.mapMany("default", "RiskEntity", o.diagnoses) : [])
    .forMember("hccModelIds", o => o.hccModelIds)
    .forMember("rxHCCs", o => o.rxHCCs)
    .forMember("codingModels", o => ArrayHelper.clean(o.codingModels));
};

export interface IRiskEntity {
  id?: string;
  chaseId?: number;
  entityId?: number;
  entityTypeId?: number;
  entityTypeName?: string;
  parentEntityId?: number; // NOTE: Diagnoses need the encounter entity id as the parent.
  isEveDiagnosis?: boolean;
  text?: string;
  diagnosisCode?: string;
  confidenceScore?: string;
  dosFrom?: string;
  dosThrough?: string;
  icdCodes?: EveIcd[];
  attributes?: DynamicEntityAttribute[];
  diagnoses?: RiskEntity[];
  hccModelIds?: string[];
  rxHCCs?: string[];
  codingModels?: CodingModel[];
}

export class RiskEntity {
  id: string;
  chaseId: number;
  entityId: number;
  entityTypeId: number;
  entityTypeName: string;
  parentEntityId: number; // NOTE: Diagnoses need the encounter entity id as the parent.
  isEveDiagnosis?: boolean;
  text?: string;
  diagnosisCode?: string;
  confidenceScore?: string;
  dosFrom?: string;
  dosThrough?: string;
  icdCodes?: EveIcd[];
  attributes: DynamicEntityAttribute[];
  diagnoses: RiskEntity[];
  hccModelIds?: string[];
  rxHCCs?: string[];
  codingModels?: CodingModel[];

  constructor(options: IRiskEntity = {}) {
    this.id = StringHelper.clean(options.id, StringHelper.createId());
    this.chaseId = options.chaseId;
    this.entityId = options.entityId;
    this.entityTypeId = options.entityTypeId;
    this.entityTypeName = options.entityTypeName;
    this.parentEntityId = options.parentEntityId || null;
    this.isEveDiagnosis = options.isEveDiagnosis;
    this.text = options.text;
    this.diagnosisCode = options.diagnosisCode;
    this.confidenceScore = options.confidenceScore;
    this.dosFrom = options.dosFrom;
    this.dosThrough = options.dosThrough;
    this.icdCodes = ArrayHelper.clean(options.icdCodes);
    this.attributes = ArrayHelper.clean(options.attributes);
    this.diagnoses = ArrayHelper.clean(options.diagnoses);
    this.hccModelIds = ArrayHelper.clean(options.hccModelIds);
    this.rxHCCs = ArrayHelper.clean(options.rxHCCs);
    this.codingModels = ArrayHelper.clean(options.codingModels);
  }

  private sortDiagnoses(diagnoses) {
    if (ArrayHelper.isAvailable(diagnoses)) {
      return diagnoses.sort((a, b) => (this.getStartDateValue(a) < this.getStartDateValue(b)) ? 1 : -1);
    }
  }

  private getStartDateValue(diagnosis) {
    const startDate = diagnosis.attributes.find(x => x.attributeId === FROM_DATE.attributeId).value;
    return DateHelper.create(startDate, null);
  }
}
