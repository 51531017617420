import { IAutoMapper } from "../../../../../core/automapper/interfaces";

export const mapSsoConfiguration = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "SsoConfiguration")
    .forMember("entityId", o => o.entityId)
    .forMember("acsUrl", o => o.acsUrl)
    .forMember("identityProvider", o => o.identityProvider)
    .forMember("applicationId", o => o.applicationId)
    .forMember("directoryId", o => o.directoryId)
    .forMember("ssoDomain", o => o.ssoDomain);
};

export class SsoConfiguration {
    entityId?: string;
    acsUrl?: string;
    identityProvider?: string;
    applicationId?: string;
    directoryId?: string;
    ssoDomain?: string;

    constructor(options: Partial<SsoConfiguration> = {}) {
    this.entityId = options.entityId;
    this.acsUrl = options.acsUrl;
    this.identityProvider = options.identityProvider;
    this.applicationId = options.applicationId;
    this.directoryId = options.directoryId;
    this.ssoDomain = options.ssoDomain;
  }
}
