import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-info-header-template",
  templateUrl: "./info-header-template.component.html",
  styleUrls: ["./info-header-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoHeaderTemplateComponent {
}
