<div class="chase-upload-container">

  <div class="container-back-button">
    <app-back-button [text]="backButtonText"></app-back-button>
  </div>

  <h3 class="container-header">
    CHASE UPLOAD
  </h3>

  <div class="chase-header bold">CHASES AT THIS ADDRESS</div>

  <app-server-grid
    [configuration]="chaseGridConfiguration"
    [(request)]="request"
    [refresh]="refreshGrid"
    [isLoadOnInit]="true"
    [(selection)]="chaseGridSelection"
    (selectionChange)="displayDocumentList($event, fileUpload)"
    [actionTemplate]="chaseGridactionTemplate"
    [views]="views"
    [refreshViews]="refreshViews"
    [displayCheckedItemsInGridView]="true"
    [filterTemplate]="filterForm"
    filterHeaderText="Chase Query">

    <ng-template #chaseGridactionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
      <pre>Index: {{ i }}</pre>
      <pre>JSON: {{ rowData | json }}</pre>
      <app-button text="Print To Console" (onClick)="printActionArgumentsToConsole(i, rowData); localActionPopover.hide();"></app-button>
    </ng-template>

    <ng-template #filterForm let-form>
      <form [formGroup]="form" class="ui-modal-width">
        <app-tab-menu orientation="left">
          
          <app-tab-panel header="Assigned To">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel class="checkbox-group" header="Pend Codes">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="pendCode"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel class="checkbox-group__halves" header="Pend Status">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="pendStatus"></form-factory>
            </div>
          </app-tab-panel>
          
          <app-tab-panel class="checkbox-group__halves" header="Project(s)" selected="true">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel class="checkbox-group__fourths" header="Measures">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
            </div>
          </app-tab-panel>

        </app-tab-menu>
      </form>
    </ng-template>

  </app-server-grid>

  <div
    class="required-documents-container header bold"
    [ngClass]="isdisplay ? 'show-document-list':'hide-document-list'">

    <form [formGroup]="requiredDocumentsForm">
      <div class="required-doc-title">
        <h3>REQUIRED DOCUMENTS</h3>
        <div class="selectAll-button">
          <div class="buttons">
            <p-selectButton
              [options]="selectAllButtonOptions"
              formControlName="isSelectAll"
              (onChange)="selectAllButtons($event)"
              [(ngModel)]="selectedAllButton"></p-selectButton>
            <app-button text="CLEAR" (onClick)="clearAllButton()"></app-button>
          </div>
        </div>
      </div>
      <div class="required-docs-container">
        <div
          class="required-doc"
          *ngFor="let item of requiredDocumentsForm.get('RequiredDoc')['controls'] let i=index; trackBy: trackByIndex"
          [formGroup]="item">
          <div class="buttons">
            <p-selectButton
              [options]="requirementsOptions"
              formControlName="isUploaded"
              (onChange)="checkButtonStatus()"
              [(ngModel)]="selectedButton"></p-selectButton>
          </div>
          <div class="description">{{item.get('documentName').value}}</div>
        </div>
      </div>
    </form>

    <div class="upload-button-container">
      <div class="bottom-left-container">
        <app-upload-message></app-upload-message>
        <app-file-upload #fileUpload
          chooseLabel="Upload"
          [data]="uploadData"
          [disabled]="!documentSelected"
          (onUpload)="uploadDocument($event)"></app-file-upload>
      </div>
        
      <div class="bottom-right-container">
        <app-button class="header-button"
                    text="Pend This Chase"
                    [disabled]="!pendSelected"
                    (onClick)="createPend()"></app-button>
      </div>
      <app-pend-modal [(visible)]="isPendModalVisible"
                      (onHide)="closePendModal()"
                      [(chases)]="selectedChases"></app-pend-modal>
    </div>

  </div>

</div>
