import { StringHelper } from "../../../utilities/contracts/string-helper";
import { PROVIDER_ID, PROVIDER_NAME } from "../../modules/member/chase-detail/chase-detail-chart/attributes";
import { AttributeData } from "../../modules/member/create-new-chase/attributeData.model";

export class ProviderItem {

  recordNumber?: number;
  serviceProviderId: number;
  serviceProviderName: string;
  masterProviderId?: number;
  sourceAliasId: string;
  nationalProviderId?: string;
  providerGroup?: string;
  speciality?: string;
  phoneNumber?: string;
  faxNumber?: string;
  contact?: string;
  recordCount?: number;
  firstName?: string;
  lastName?: string;
  masterDocumentSourceId?: number;
  specialtyName?: string;
  clientProviderId?: string;
  taxpayerId?: number;
  isVerified?: boolean;
  isPended?: boolean;
  serviceProviderList?: string;
  providerIdAttributeData?: AttributeData;
  providerNameAttributeData?: AttributeData;
  totalAddresses?: number;

  constructor(options: {
    recordNumber?: number;
    serviceProviderId: number;
    serviceProviderName: string;
    masterProviderId?: number;
    sourceAliasId: string;
    nationalProviderId?: string;
    providerGroup?: string;
    speciality?: string;
    phoneNumber?: string;
    faxNumber?: string;
    contact?: string;
    firstName?: string;
    lastName?: string;
    recordCount?: number;
    masterDocumentSourceId?: number;
    specialtyName?: string;
    clientProviderId?: string;
    taxpayerId?: number;
    isVerified?: boolean;
    isPended?: boolean;
    serviceProviderList?: string;
    providerIdAttributeData?: AttributeData;
    providerNameAttributeData?: AttributeData;
    totalAddresses?: number;
  }) {

    this.recordNumber = options.recordNumber;
    this.serviceProviderId = options.serviceProviderId;
    this.serviceProviderName = options.serviceProviderName;
    this.masterProviderId = options.masterProviderId;
    this.sourceAliasId = StringHelper.clean(options.sourceAliasId);
    this.nationalProviderId = StringHelper.clean(options.nationalProviderId);
    this.providerGroup = StringHelper.clean(options.providerGroup);
    this.speciality = options.speciality;
    this.phoneNumber = StringHelper.clean(options.phoneNumber);
    this.faxNumber = StringHelper.clean(options.faxNumber);
    this.contact = StringHelper.clean(options.contact);
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.recordCount = options.recordCount;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.specialtyName = options.specialtyName;
    this.clientProviderId = options.clientProviderId;
    this.taxpayerId = options.taxpayerId;
    this.isVerified = options.isVerified;
    this.isPended = options.isPended;
    this.serviceProviderList = StringHelper.clean(options.serviceProviderList);
    this.providerIdAttributeData = PROVIDER_ID;
    this.providerNameAttributeData = PROVIDER_NAME;
    this.totalAddresses = options.totalAddresses;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

}
