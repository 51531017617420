import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { URIFormatHelper } from "../../../../../utilities/uri-format-helper";

@Injectable()

export class FtAssignmentService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private route: ActivatedRoute) { }

  getEmrGdFromPath(): number {
    return this.route.snapshot.params.AddressGd;
  }

  saveAssignment(assignmentNotes: string, documentSourceId: string) {
    const url = `${this.baseApiUrl}FieldTech/Assignment?documentSourceId=${documentSourceId}&assignmentNotes=${URIFormatHelper.encodeParameters(assignmentNotes)}`;

    return this.http.post(url, {});

  }
}
