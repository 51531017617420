import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { DateHelper } from "../../../../utilities/contracts/date-helper";

export const mapDatesBetween = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.startDate))
    .forMember("endDate", o => DateHelper.removeTime(o.endDate));

  automapper
    .createMap("prenatal", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.prenatalFromDateReported))
    .forMember("endDate", o => DateHelper.removeTime(o.prenatalToDateReported));

  automapper
    .createMap("postpartum", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.postpartumFromDateReported))
    .forMember("endDate", o => DateHelper.removeTime(o.postpartumToDateReported));

  automapper
    .createMap("prenatalDod", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.prenatalFromDateMedicalRecords))
    .forMember("endDate", o => DateHelper.removeTime(o.prenatalToDateMedicalRecords));

  automapper
    .createMap("postpartumDod", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.postpartumFromDateMedicalRecords))
    .forMember("endDate", o => DateHelper.removeTime(o.postpartumToDateMedicalRecords));

  automapper
    .createMap("prenatalEdd", "DatesBetween")
    .forMember("startDate", o => DateHelper.removeTime(o.prenatalFromDateEstimated))
    .forMember("endDate", o => DateHelper.removeTime(o.prenatalToDateEstimated));
};


export class DatesBetween {
  startDate: Date;
  endDate: Date;


  constructor(options: {
    startDate?: string | Date;
    endDate?: string | Date;
  } = {}) {
    this.startDate = new Date(options.startDate as any || "");
    this.endDate = new Date(options.endDate as any || "");
  }


  get hasStartDate(): boolean {
    return this.isDate(this.startDate);
  }

  get hasEndDate(): boolean {
    return this.isDate(this.endDate);
  }


  private isDate(value: Date): boolean {
    return value != null && value.toString() !== "Invalid Date";
  }
}
