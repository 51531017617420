import { IAutoMapper } from "../interfaces";

export const mapPendListItem = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "PendListItem")
    .forMember("chasePendId", o => o.chasePendId)
    .forMember("pendCode", o => o.pendCode)
    .forMember("owner", o => o.owner)
    .forMember("assignedToUser", o => o.assignedToUser)
    .forMember("pendStatus", o => o.pendStatus)
    .forMember("chaseId", o => o.chaseId)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("lastUpdatedDate", o => o.lastUpdatedDate)
    .forMember("projectId", o => o.projectId)
    .forMember("isClinical", o => o.isClinical)
    .forMember("projectName", o => o.projectName)
    .forMember("createdDate", o => o.createdDate)
    .forMember("measureCode", o => o.measureCode)
    .forMember("coRetrievalOwner", o => o.coRetrievalOwner)
    .forMember("pendStatusId", o => o.pendStatusId)
    .forMember("isThirdParty", o => o.isThirdParty);
};

