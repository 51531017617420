import { IAutoMapper } from "../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ChaseAmountDetail } from "./chase-amount-detail.model";

export const mapInvoiceDetail = (automapper: IAutoMapper): void => {
    automapper
        .createMap("default", "InvoiceDetail")
        .forMember("invoiceId", o => o.invoiceId)
        .forMember("paymentMethod", o => o.paymentMethod)
        .forMember("invoiceAmount", o => o.invoiceAmount)
        .forMember("invoiceNumber", o => o.invoiceNumber)
        .forMember("invoiceReceivedDate", o => o.invoiceReceivedDate)
        .forMember("sortCode", o => o.sortCode)
        .forMember("iban", o => o.iban)
        .forMember("chaseAmountDetails", o => o.chaseAmountDetails)
        .forMember("customerNumber", o => o.customerNumber)
        .forMember("excessAmount", o => o.excessAmount)
        .forMember("vendorId", o => o.vendorId)
        .forMember("documentQueueId", o => o.documentQueueId)
        .forMember("chaseAmount", o => o.chaseAmount)
        .forMember("chaseDocumentIds", o => o.chaseDocumentIds)
        .forMember("isDocumentUploadChanged", o => o.isDocumentUploadChanged)
        .forMember("isDocumentUpload", o => o.isDocumentUpload)
        .forMember("invoiceStatus", o => o.invoiceStatus)
        .forMember("isReadOnly", o => o.isReadOnly)
        .forMember("chaseStatus", o => o.chaseStatus)
        .forMember("invoiceType", o => o.invoiceType)
        .forMember("isWorkingDocumentAvailable", o => o.isWorkingDocumentAvailable)
        .forMember("workingDocumentId", o => o.workingDocumentId)
        .forMember("chartType", o => o.chartType)
        .forMember("vendorMaxChaseAmount", o => o.vendorMaxChaseAmount);

};
export class InvoiceDetail {
    invoiceId: number;
    paymentMethod: string;
    invoiceAmount: number | null;
    invoiceNumber: string;
    invoiceReceivedDate: Date;
    sortCode: string;
    iban: string;
    chaseAmountDetails?: ChaseAmountDetail[];
    customerNumber: string;
    excessAmount?: number | null;
    vendorId: number;
    documentQueueId?: number;
    chaseAmount: number | null;
    chaseDocumentIds: number[];
    isDocumentUploadChanged: boolean;
    isDocumentUpload: boolean;
    invoiceStatus: string;
    isReadOnly: boolean;
    chaseStatus: string;
    invoiceType: string;
    isWorkingDocumentAvailable?: boolean;
    workingDocumentId?: number;
    chartType: string;
    vendorMaxChaseAmount: number | null;
    isOriginalDocumentChanged: boolean;

    constructor(options: Partial<InvoiceDetail>) {
        this.invoiceId = options.invoiceId;
        this.paymentMethod = StringHelper.clean(options.paymentMethod);
        this.invoiceAmount = options.invoiceAmount;
        this.invoiceNumber = StringHelper.clean(options.invoiceNumber);
        this.invoiceReceivedDate = options.invoiceReceivedDate;
        this.sortCode = StringHelper.clean(options.sortCode);
        this.iban = StringHelper.clean(options.iban);
        this.chaseAmountDetails = options.chaseAmountDetails;
        this.customerNumber = StringHelper.clean(options.customerNumber);
        this.excessAmount = options.excessAmount;
        this.vendorId = options.vendorId;
        this.documentQueueId = options.documentQueueId;
        this.chaseAmount = options.chaseAmount;
        this.chaseDocumentIds = options.chaseDocumentIds;
        this.isDocumentUploadChanged = options.isDocumentUploadChanged;
        this.isDocumentUpload = options.isDocumentUpload;
        this.invoiceStatus = StringHelper.clean(options.invoiceStatus);
        this.isReadOnly = options.isReadOnly;
        this.chaseStatus = StringHelper.clean(options.chaseStatus);
        this.invoiceType = StringHelper.clean(options.invoiceType);
        this.isWorkingDocumentAvailable = options.isWorkingDocumentAvailable;
        this.workingDocumentId = options.workingDocumentId;
        this.chartType = options.chartType;
        this.vendorMaxChaseAmount = options.vendorMaxChaseAmount;
        this.isOriginalDocumentChanged = options.isOriginalDocumentChanged;

    }
}
