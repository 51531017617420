<div [formGroup]="formGroup" class="control control--input control--dropdown" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
    <app-tool-tip *ngIf="hasTooltip" [text]="model.tooltip"></app-tool-tip>
  </div>
  <p-dropdown
    #primeDropdown
    [autoDisplayFirst]="model.autoDisplayFirst"
    [appendTo]="model.appendTo"
    [hidden]="!hasOptions"
    dropdownIcon="pi pi-caret-down"
    [styleClass]="classes"
    [readonly]="model.readonly"
    [options]="options"
    [placeholder]="model.placeholder"
    [name]="model.key"
    [inputId]="model.key"
    [showClear]="clearOption"
    (onChange)="onSelectedItemChange($event)"
    (onInput)="onInput.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    [formControlName]="model.key"
    [group]="model.hasGroup">
    <ng-container *ngIf="customTemplate else defaultContent">
      <ng-template let-item pTemplate="item">
        <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: item }"></ng-container>
      </ng-template>
    </ng-container>
    <ng-template #defaultContent let-item pTemplate="group">
      <div class="p-d-flex p-ai-center">
        <span>{{getText(item)}}</span>
      </div>
    </ng-template>
  </p-dropdown>
  <div *ngIf="!hasOptions" class="text--danger">Information Not Available.</div>
</div>
