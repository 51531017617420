import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { BoundingBox } from "../bounding-box.model";

export const mapNlpWord = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "NlpWord")
    .forMember("documentPageId", o => o.documentPageId)
    .forMember("text", o => o.text)
    .forMember("pageNumber", o => o.pageNumber)
    .forMember("status", o => o.status)
    .forMember("isProviderEvidence", o => o.isProviderEvidence)
    .forMember("diagnosisDOS", o => o.diagnosisDOS)
    .forMember("dosPageNumber", o => o.dosPageNumber)
    .forMember("boundingBoxes", o => {
      const isArray = Array.isArray(o.boundingBoxes) && o.boundingBoxes.length > 0;
      return isArray ? automapper.mapMany("default", "BoundingBox", o.boundingBoxes) : [];
    });
};

export class NlpWord {
  readonly documentPageId: number;
  readonly text: string;
  readonly pageNumber: number;
  readonly status: string;
  readonly isProviderEvidence: boolean;
  readonly diagnosisDOS: string;
  readonly dosPageNumber: number;
  readonly boundingBoxes: BoundingBox[];

  constructor(options: Partial<NlpWord>) {
    this.documentPageId = options.documentPageId;
    this.text = options.text;
    this.pageNumber = options.pageNumber;
    this.status = options.status;
    this.isProviderEvidence = options.isProviderEvidence;
    this.diagnosisDOS = options.diagnosisDOS;
    this.dosPageNumber = options.dosPageNumber;
    this.boundingBoxes = options.boundingBoxes;
  }
}
