<div class="diagnoses-header">
  <div class="diagnoses-header__title">Admin Dx</div>
  <app-badge [text]="totalDiagnoses" [isLarge]="false" cssClasses="badge-diagnoses counter"></app-badge>
</div>
<member-risk-read-only [isReadOnly]="showReadOnly"></member-risk-read-only>

<p-accordion (onOpen)="onTabOpen($event)" class="app-accordion" [(activeIndex)]="currentDiagnosisIndex">
  <p-accordionTab *ngFor="let adminDiagnosis of adminDiagnoses; trackBy: trackByIndex; let i = index"
                  [(selected)]="activeState[i]"
                  class="app-accordion_card"
                  id="admin-{{adminDiagnosis.id}}">
    <p-header>
      <app-card-header
        [diagnosis]="adminDiagnosis"
        [badgeStatus]="getBadgeStatus(adminDiagnosis, i)"
        [isCardOpen]="activeState[i]"
        [showBadge]="!showReadOnly">
      </app-card-header>
    </p-header>
    <form-factory class="diagnosis-save-group" [formGroup]="form" [model]="saveGroup" (keyup)="handleKeyUp()" (onChange)="handleChange($event, false, i)">
    </form-factory>
    <div>
      <app-button [disabled]="!isSaveEnabled" [class.disabled]="!isSaveEnabled" class="diagnosis-save-button" title="Submit" (onClick)="saveDiagnosis()" text="Save Diagnosis"></app-button>
      <div class="exempt" *ngIf="isOverread">
        <form-factory [formGroup]="form" [model]="exemptInput" (onChange)="tryEnablingSaveButton()"></form-factory>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
