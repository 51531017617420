import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../auth/user-token.model";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { StatesListService } from "../../../../api/states-list/states-list.service";
import { AddressSearchRequest } from "../../../dashboard/retrieval/address-search-request.model";
import { AddressSearchResult } from "../../../retrieval/address-search/address-search-result.model";
import { AddressSearchService } from "../../../retrieval/address-search/address-search.service";
import { CreateAddress } from "../../../retrieval/address-search/create-address.model";
import { CreateChaseService } from "../create-new-chase.service";
import { GapQueryDataExchangeService } from "../gap-query-data-exchange.service";
import { NewChase } from "../new-chase.model";

@Component({
  selector: "app-create-new-chase-address",
  templateUrl: "./create-new-chase-address.component.html",
  styleUrls: ["./create-new-chase-address.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CreateNewChaseAddressComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  selectedState: string;
  states: SelectableInput[];
  formGroupAddressSearch: FormGroup;
  formGroupAddressOption: FormGroup;
  retrievalLocationId: Textbox;
  addressGroup: Textbox;
  address1: Textbox;
  address2: Textbox;
  city: Textbox;
  stateList: Dropdown;
  zip: Textbox;
  groupName: Textbox;
  contactName: Textbox;
  phone: Textbox;
  faxNumber: Textbox;
  existingAddressOptions: Radiobutton;
  addressSearchRequest: AddressSearchRequest;
  addressSearchGridConfiguration = new GridConfiguration();
  addressSearchData: AddressSearchResult[] = [];
  addressGridSelection: AddressSearchResult;
  newChase: NewChase;
  createAddress: CreateAddress;
  @Output() onShowChild = new EventEmitter<any>();
  addressCount: number;
  clientAddressId: Textbox;
  newChaseData: NewChase;
  isAddressInvalid: boolean;
  validationErrorMessages: string[];
  coRetrievalOwner: Dropdown;
  isSOCoRetrievalOwnerEnabled = false;
  showMatchingAddressGrid = false;

  private user: UserToken;
  constructor(private readonly formService: FormService,
              private changeDetector: ChangeDetectorRef,
              private stateService: StatesListService,
              private addressSearchService: AddressSearchService,
              private createChaseService: CreateChaseService,
              private dataExchangeService: GapQueryDataExchangeService,
              private userService: UserService
              ) {
  }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.isSOCoRetrievalOwnerEnabled = this.user.isCoRetrievalEnabled;
    this.initializeAddressControls();
    this.initializeAddressGrid();
    this.newChase = this.createChaseService.chaseModel;
    this.getRetrieverOptions();
    this.getStateListForAddress();
    this.createAddressOptionForm();
    this.createForm();
    this.checkAddressOption();
    this.newChaseData = new NewChase();
   }

  private getRetrieverOptions(): void {
    let result = [];
    result = [
        new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
        new SelectableInput({ text: "Client", value: "Client" }),
      ];
    this.coRetrievalOwner = new Dropdown({ ...this.coRetrievalOwner, options: result } as any);
    this.changeDetector.markForCheck();
  }
  get showAddressForm(): boolean {
    return NumberHelper.isGreaterThan(this.formGroupAddressOption.get("AIDSearch").value, 0);
  }

  get isExistingAddressSelected(): boolean {
    return this.formGroupAddressOption.get("AIDSearch").value === 1;
  }

  get isAddressSearchResultsFound(): boolean {
    return this.addressCount > 0;
  }

  get isShowNextStepButton(): boolean {
    return this.addressGridSelection != null;
  }

  private createAddressOptionForm(): void {
    this.formGroupAddressOption = this.formService.createFormGroup([
      this.existingAddressOptions]);
  }

  private createForm(): void {
    this.formGroupAddressSearch = this.formService.createFormGroup([
      this.retrievalLocationId,
      this.addressGroup,
      this.address1,
      this.address2,
      this.city,
      this.stateList,
      this.zip,
      this.groupName,
      this.contactName,
      this.phone,
      this.faxNumber,
      this.clientAddressId,
      this.coRetrievalOwner,
      ]);

    if (this.isSOCoRetrievalOwnerEnabled) {
      this.formGroupAddressSearch.get(this.coRetrievalOwner.key).setValidators([Validators.required]);
    }
  }
  private getStateListForAddress(): void {
    this.stateService.getStateList()
      .subscribe(statelist => {
        this.stateList = new Dropdown({ ...this.stateList, options: statelist } as any);
        this.changeDetector.markForCheck();
      });
  }

  searchAddressAction(): void {
    this.addressGridSelection = null;

    this.addressSearchRequest = new AddressSearchRequest({
      retrievalLocationId: StringHelper.isAvailable(this.formGroupAddressSearch.get("retrievalLocationId").value) ? this.formGroupAddressSearch.get("retrievalLocationId").value : null,
      addressGroup: StringHelper.isAvailable(this.formGroupAddressSearch.get("addressGroup").value) ? this.formGroupAddressSearch.get("addressGroup").value : null,
      address1: StringHelper.isAvailable(this.formGroupAddressSearch.get("address1").value) ? this.formGroupAddressSearch.get("address1").value : null,
      address2: StringHelper.isAvailable(this.formGroupAddressSearch.get("address2").value) ? this.formGroupAddressSearch.get("address2").value : null,
      addressCity: StringHelper.isAvailable(this.formGroupAddressSearch.get("city").value) ? this.formGroupAddressSearch.get("city").value : null,
      addressState: StringHelper.isAvailable(this.formGroupAddressSearch.get("state").value) ? this.formGroupAddressSearch.get("state").value : null,
      addressZip: StringHelper.isAvailable(this.formGroupAddressSearch.get("postalCode").value) ? this.formGroupAddressSearch.get("postalCode").value : null,
      groupName: StringHelper.isAvailable(this.formGroupAddressSearch.get("groupName").value) ? this.formGroupAddressSearch.get("groupName").value : null,
      contactName: StringHelper.isAvailable(this.formGroupAddressSearch.get("contactName").value) ? this.formGroupAddressSearch.get("contactName").value : null,
      phone: StringHelper.isAvailable(this.formGroupAddressSearch.get("phone").value) ? this.formGroupAddressSearch.get("phone").value : null,
      faxNumber: StringHelper.isAvailable(this.formGroupAddressSearch.get("faxNumber").value) ? this.formGroupAddressSearch.get("faxNumber").value : null,
    });

    this.addressSearchService
      .chaseAddressSearch(this.addressSearchRequest)
      .subscribe(items => {
        this.addressSearchData = items as AddressSearchResult[];
        this.addressCount = this.addressSearchData.length;
        this.showMatchingAddressGrid = true;
        this.changeDetector.markForCheck();
      });
  }

  addressSearchReset(): void {
    this.addressGridSelection = null;
    this.addressSearchData = [];
    this.selectedState = null;
    this.createForm();
    this.formGroupAddressSearch.reset();
  }

  private markAllAsTouched(): void {
    Object.keys(this.formGroupAddressSearch.controls).forEach(key => {
      const control = this.formGroupAddressSearch.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.formService.updateDom.next();
  }

  newAddressAction(): void {
    if (this.formGroupAddressSearch.valid) {
      this.removeControlCharacters();
      this.createAddress = new CreateAddress({
        addressGroup: this.formGroupAddressSearch.get("addressGroup").value,
        address1: this.formGroupAddressSearch.get("address1").value,
        address2: this.formGroupAddressSearch.get("address2").value,
        addressCity: this.formGroupAddressSearch.get("city").value,
        addressState: this.formGroupAddressSearch.get("state").value,
        addressZip: this.formGroupAddressSearch.get("postalCode").value,
        contactName: this.formGroupAddressSearch.get("contactName").value,
        groupName: this.formGroupAddressSearch.get("groupName").value,
        phone: this.formGroupAddressSearch.get("phone").value,
        faxNumber: this.formGroupAddressSearch.get("faxNumber").value,
        clientAddressId: this.formGroupAddressSearch.get("clientAddressId").value,
        coRetrievalOwner: this.formGroupAddressSearch.get("coRetrievalOwner").value,
      });

      this.changeDetector.markForCheck();
      this.newChase.chaseAddressData = this.createAddress;
      this.newChase.addressId = null;
      this.newChase.chaseAddress = this.createAddress.fullAddress;
      this.newChase.coRetrievalOwner = this.isSOCoRetrievalOwnerEnabled ? this.createAddress.coRetrievalOwner : null;
      this.newChaseData.chaseAddressData = this.createAddress;
      this.newChaseData.chaseAddress = this.createAddress.fullAddress;
      this.newChaseData.coRetrievalOwner = this.isSOCoRetrievalOwnerEnabled ? this.createAddress.coRetrievalOwner : null;
      this.createChaseService.validateChaseDetails(this.newChaseData)
            .subscribe(message => {
               if (ArrayHelper.isAvailable(message)) {
                    this.validationErrorMessages = message;
                    this.isAddressInvalid = true;
                    this.changeDetector.markForCheck();
          } else {
                    this.isAddressInvalid = false;
                    this.createChaseService.chaseModel = this.newChase;
                    this.onShowChild.emit(this.createChaseService.chaseModel);
                    this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
          }
        });
    } else if (!this.formGroupAddressSearch.valid) {
      this.markAllAsTouched();
    }
  }

  checkAddressOption(): void {
    this.sink.add(
      this.formGroupAddressOption.get("AIDSearch").valueChanges.subscribe(() => {
        this.addressGridSelection = null;
        this.showMatchingAddressGrid = false;
        this.isExistingAddressSelected ? this.reset() : this.initializeAddressAddControls();
        this.changeDetector.markForCheck();
      })
    );

  }

  private initializeAddressAddControls(): void {
    const addressGroupValue = this.formGroupAddressSearch.get(this.addressGroup.key).value;
    this.addressGroup = new Textbox({
      ...this.addressGroup,
      value: addressGroupValue,
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Address Group can not be more than 50 characters.",
      },
    } as any);

    const address1Value = this.formGroupAddressSearch.get(this.address1.key).value;
    this.address1 = new Textbox({
      ...this.address1,
      value: address1Value,
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(50)],
      errorMessages: {
        required: "Address 1 is required.",
        minlength: "Address 1 can not be less than 5 characters.",
        maxlength: "Address 1 can not be more than 50 characters.",
      },
    } as any);

    const address2Value = this.formGroupAddressSearch.get(this.address2.key).value;
    this.address2 = new Textbox({
      ...this.address2,
      value: address2Value,
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Address 2 name can not be more than 50 characters.",
      },
    } as any);

    const city = this.formGroupAddressSearch.get(this.city.key).value;
    this.city = new Textbox({
      ...this.city,
      value: city,
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      errorMessages: {
        required: "City is required.",
        minlength: "City name can not be less than 3 characters.",
        maxlength: "City name can not be more than 50 characters.",
      },
    } as any);

    const state = this.formGroupAddressSearch.get(this.stateList.key).value;
    this.stateList = new Dropdown({
      ...this.stateList,
      value: state,
      validators: [Validators.required],
      errorMessages: {
        required: "State is required.",
      },
    } as any);

    const zip = this.formGroupAddressSearch.get(this.zip.key).value;
    this.zip = new Textbox({
      ...this.zip,
      value: zip,
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(10)],
      errorMessages: {
        required: "Zip Code is required.",
        minlength: "Zip Code can not be less than 5 characters.",
        maxlength: "Zip Code can not be more than 10 characters.",
      },
    } as any);

    const contact = this.formGroupAddressSearch.get(this.contactName.key).value;
    this.contactName = new Textbox({
      ...this.contactName,
      value: contact,
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 50 characters.",
      },
    } as any);

    const group = this.formGroupAddressSearch.get(this.groupName.key).value;
    this.groupName = new Textbox({
      ...this.groupName,
      value: group,
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Group Name can not be more than 50 characters.",
      },
    } as any);

    const phoneNum = this.formGroupAddressSearch.get(this.phone.key).value;
    this.phone = new Textbox({
      ...this.phone,
      type: TextboxType.NUMBER,
      value: phoneNum,
      validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
      errorMessages: {
        required: "Phone Number is required.",
        minlength: "Phone Number can not be less than 10 characters.",
        maxlength: "Phone Number can not be more than 10 characters.",
      },
    } as any);

    const faxNo = this.formGroupAddressSearch.get(this.faxNumber.key).value;
    this.faxNumber = new Textbox({
      ...this.faxNumber,
      type: TextboxType.NUMBER,
      value: faxNo,
      validators: [Validators.minLength(10), Validators.maxLength(10)],
      errorMessages: {
        minlength: "Fax Number can not be less than 10 characters.",
        maxlength: "Fax Number can not be more than 10 characters.",
      },
    } as any);

    const clientAddress = this.formGroupAddressSearch.get(this.clientAddressId.key).value;
    this.clientAddressId = new Textbox({
      ...this.clientAddressId,
      value: clientAddress,
    } as any);
    const coRetrievalOwner = this.formGroupAddressSearch.get(this.coRetrievalOwner.key).value;
    this.coRetrievalOwner = new Dropdown({
      ...this.coRetrievalOwner,
      value: coRetrievalOwner,
      } as any);
  }

  private initializeAddressGrid(): void {
    this.addressSearchGridConfiguration.columns = [
      new GridColumnDefinition({ field: "addressId", header: "Address ID" }),
      new GridColumnDefinition({ field: "groupName", header: "Group Name" }),
      new GridColumnDefinition({ field: "address1", header: "Address 1" }),
      new GridColumnDefinition({ field: "address2", header: "Address 2" }),
      new GridColumnDefinition({ field: "city", header: "CITY" }),
      new GridColumnDefinition({ field: "addressState", header: "STATE" }),
      new GridColumnDefinition({ field: "addressZip", header: "ZIP CODE" }),
      new GridColumnDefinition({ field: "contactName", header: "Contact" }),
      new GridColumnDefinition({ field: "phone", header: "Phone" }),
      new GridColumnDefinition({ field: "faxNumber", header: "Fax" }),
    ];
    if (this.isSOCoRetrievalOwnerEnabled) {
     this.addressSearchGridConfiguration.columns.push(new GridColumnDefinition({ field: "coRetrievalOwner", header: "Retreiver"}));
    }
    this.addressSearchGridConfiguration.showExportAction = false;
    this.addressSearchGridConfiguration.pageSize = 10;
    this.addressSearchGridConfiguration.selectionMode = "single";
    this.addressSearchGridConfiguration.showActionColumn = false;
  }

  private initializeAddressControls(): void {
    this.retrievalLocationId = new Textbox({
      key: "retrievalLocationId",
      type: TextboxType.NUMBER,
      label: "ADDRESS ID",
      placeholder: "Enter Address ID",
      validators: [Validators.pattern(RegExHelper.wholeNumber)],
      errorMessages: {
        pattern: "AID should be numeric. Decimal or negative numbers not allowed.",
      },
    });

    this.addressGroup = new Textbox({
      key: "addressGroup",
      label: "ADDRESS GROUP (PGID)",
      placeholder: "Enter Address Group",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        pattern: "Address Group can not be more than 50 characters.",
      },
    });

    this.address1 = new Textbox({
      key: "address1",
      label: "ADDRESS 1",
      placeholder: "Enter Address",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Address 1 can not be more than 50 characters.",
      },
    });
    this.address2 = new Textbox({
      key: "address2",
      label: "ADDRESS 2",
      placeholder: "Enter Address",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Address 2 can not be more than 50 characters.",
      },
    });

    this.city = new Textbox({
      key: "city",
      label: "CITY",
      placeholder: "Enter City",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "City name can not be more than 50 characters.",
      },
    });

    this.zip = new Textbox({
      key: "postalCode",
      label: "ZIP CODE",
      placeholder: "Enter Zip Code",
      validators: [Validators.minLength(5), Validators.maxLength(10)],
      errorMessages: {
        minlength: "Zip Code can not be less than 5 characters.",
        maxlength: "Zip Code can not be more than 10 characters.",
      },
    });

    this.groupName = new Textbox({
      key: "groupName",
      label: "GROUP NAME",
      placeholder: "Enter Group Name",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Group Name can not be more than 50 characters.",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "CONTACT",
      placeholder: "Enter Contact Name",
    });

    this.stateList = new Dropdown({
      key: "state",
      label: "STATE",
      placeholder: "Select State",
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone Number",
      placeholder: "Enter Phone Number",
      validators: [Validators.minLength(5), Validators.maxLength(10)],
      errorMessages: {
        minlength: "Phone Number can not be less than 5 characters.",
        maxlength: "Phone Number can not be more than 10 characters.",
      },
    });

    this.faxNumber = new Textbox({
      key: "faxNumber",
      label: "Fax Number",
      placeholder: "Enter Fax Number",
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "Group Name can not be more than 50 characters.",
      },
    });

    this.existingAddressOptions = new Radiobutton({
      key: "AIDSearch",
      label: "IS THIS AN EXISTING PROVIDER ADDRESS?",
      options: [
        new SelectableInput({ text: "Yes", value: 1 }),
        new SelectableInput({ text: "No", value: 2 }),
      ],
    });

    this.clientAddressId = new Textbox({
      key: "clientAddressId",
      label: "CLIENT ADDRESS ID",
      placeholder: "Enter Client ADDRESS ID",
    });
    this.coRetrievalOwner = new Dropdown({
      key: "coRetrievalOwner",
      label: "Select Retriever",
      placeholder: "Select From List",
      errorMessages: {
        required: "Retriever is required.",
      },
      appendTo: "body",
    });
  }

  nextStep() {
    if (this.isSOCoRetrievalOwnerEnabled && (this.formGroupAddressSearch.get("coRetrievalOwner").value === null || this.formGroupAddressSearch.get("coRetrievalOwner").value === undefined)) {
      this.formGroupAddressSearch.get("coRetrievalOwner").markAsTouched({ onlySelf: true });
      this.formGroupAddressSearch.get("coRetrievalOwner").markAsDirty({ onlySelf: true });

      this.formService.updateDom.next();

      return;
    }

    if (this.addressGridSelection != null) {
      this.newChase.addressId = this.addressGridSelection.addressId;
      this.newChase.chaseAddress = `${this.addressGridSelection.address}, ${this.addressGridSelection.city} ,
                                        ${this.addressGridSelection.addressState}, ${this.addressGridSelection.addressZip}`;
      this.newChase.chaseAddressData = null;
      this.newChase.coRetrievalOwner = this.isSOCoRetrievalOwnerEnabled ? this.formGroupAddressSearch.get("coRetrievalOwner").value : null;
    }

    this.createChaseService.chaseModel = this.newChase;
    this.onShowChild.emit(this.createChaseService.chaseModel);
    this.dataExchangeService.setNewChaseData(this.createChaseService.chaseModel);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  reset() {
    this.addressSearchData = [];
    this.selectedState = null;
    this.formGroupAddressSearch.reset();
    this.isAddressInvalid = false;
  }

  private removeControlCharacters() {
    Object.keys(this.formGroupAddressSearch.controls).forEach(key => {
      const control = this.formGroupAddressSearch.get(key).value;
      if (StringHelper.isAvailable(control)) {
        const validValues = control.replace(/[^\x20-\x7E]/g, "");
        this.formGroupAddressSearch.controls[key].setValue(validValues);
      }
    });
    this.formService.updateDom.next();
    }

  trackByIndex(index, item) {
        return index;
    }
  selectChangeRetreiver(event) {
    if (event !== null && this.isSOCoRetrievalOwnerEnabled) {
        if (event.coRetrievalOwner === "Reveleer" || event.coRetrievalOwner === "Client") {
          this.formGroupAddressSearch.get(this.coRetrievalOwner.key).setValue(event.coRetrievalOwner);
         } else {
          this.formGroupAddressSearch.get(this.coRetrievalOwner.key).setValue(null);
         }
     }
  }
}

