import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../../../../../../auth/auth.service";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { RetrievalCallFlowService } from "../../../retrieval-call-flow/retrieval-call-flow.service";
import { AddressDetailHeaderService } from "../address-detail-header/address-detail-header.service";
import { AddressDetailInfoService } from "../address-detail-info/address-detail-info.service";
import { AddressDetailEditService } from "./address-detail-edit.service";
import { AddressEditModel } from "./address-edit-model";

@Component({
  selector: "retrieval-address-detail-edit",
  templateUrl: "./address-detail-edit.component.html",
  styleUrls: ["./address-detail-edit.component.scss"],
})
export class AddressDetailEditComponent implements OnInit, OnDestroy {
  @Input() isCreateVisible = false;
  @Output() formClose = new EventEmitter();
  private unsubscribe = new Subject();

  addressEditModel: AddressEditModel;
  private addressId: number;
  private organizationId: number;

  readonly formGroup: FormGroup;
  addressFormGroup: FormGroup;
  email: Textbox;
  phone: Textbox;
  fax: Textbox;
  contactName: Textbox;
  address1: Textbox;
  address2: Textbox;
  addressCity: Textbox;
  addressState: Textbox;
  addressZip: Textbox;
  groupName: Textbox;
  readonly addressFormModel: any[] = [];

  constructor(
    private readonly formService: FormService,
    private addressInfoService: AddressDetailInfoService,
    private addressDetailHeaderService: AddressDetailHeaderService,
    private service: AddressDetailEditService,
    private callFlowService: RetrievalCallFlowService,
    private changeDetector: ChangeDetectorRef,
    private messagingService: MessagingService,
    private readonly route: ActivatedRoute,
    private formBuild: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.organizationId = this.authService.user.organizationId;
    this.addressId = this.service.getAddressGdFromPath();
    this.groupName = new Textbox({
      key: "groupName",
      label: "Group Name",
      placeholder: "Group Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Group Name can not be more than 100 characters.",
      },
    });

    this.email = new Textbox({
      key: "email",
      label: "Email",
      placeholder: "Email",
      validators: [Validators.email, Validators.maxLength(50)],
      errorMessages: {
        email: "Please enter a valid email address.",
        maxlength: "Email name can not be more than 50 characters.",
      },
    });

    this.phone = new Textbox({
      key: "phone",
      label: "Phone",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Phone number cannot be more than 18 characters.",
        pattern: "Phone number should be in right format.",
      },
    });

    this.fax = new Textbox({
      key: "fax",
      label: "fax",
      placeholder: "XXX-XXX-XXXX",
      validators: [Validators.maxLength(18), Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)],
      errorMessages: {
        maxlength: "Fax number cannot be more than 18 characters.",
        pattern: "Fax number should be in right format.",
      },
    });

    this.contactName = new Textbox({
      key: "contactName",
      label: "Contact Name",
      placeholder: "Contact Name",
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Contact Name can not be more than 100 characters.",
      },
    });

    this.address1 = new Textbox({
      key: "address1",
      label: "Address 1",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "Address is required.",
        maxlength: "Address must be no longer than 50 characters.",
      },
    });

    this.address2 = new Textbox({
      key: "address2",
      label: "Address 2",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.maxLength(50)],
      errorMessages: {
        maxlength: "City must be no longer than 50 characters.",
      },
    });

    this.addressCity = new Textbox({
      key: "addressCity",
      label: "City",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(50)],
      errorMessages: {
        required: "Address is required.",
        maxlength: "City must be no longer than 50 characters.",
      },
    });

    this.addressState = new Textbox({
      key: "addressState",
      label: "State",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(2)],
      errorMessages: {
        required: "State is required.",
        maxlength: "State must be 2 characters.",
      },
    });

    this.addressZip = new Textbox({
      key: "addressZip",
      label: "Zip",
      disabled: this.isHDVIServiceOrg(),
      validators: [Validators.required, Validators.maxLength(5)],
      errorMessages: {
        required: "Zip code is required.",
        maxlength: "Zip must be 5 characters.",
        minlength: "Zip must be5 characters.",
      },
    });

    this.formGroup = this.formService.createFormGroup([
      this.email,
      this.phone,
      this.fax,
      this.contactName,
      this.address1,
      this.address2,
      this.addressCity,
      this.addressState,
      this.addressZip,
      this.groupName,
    ]);
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.addressId = +params.get("AddressGd");

      this.service
        .getAddressDetailsForEdit()
        .subscribe(this.assignAndNotify);
    });

    this.service.reset
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(id => this.updateAddressDetailsForEdit());
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateAddressDetailsForEdit() {
    this.service
      .getAddressDetailsForEdit()
      .subscribe(this.assignAndNotify);
  }

  private assignAndNotify = (data: AddressEditModel): void => {

    this.addressEditModel = new AddressEditModel(data);

    this.loadForm();
  }

  private loadForm() {

    this.formGroup.patchValue({
      email: this.addressEditModel.email,
      phone: this.addressEditModel.phone,
      fax: this.addressEditModel.faxNumber,
      contactName: this.addressEditModel.contactName,
      address1: this.addressEditModel.address1,
      address2: this.addressEditModel.address2,
      addressCity: this.addressEditModel.addressCity,
      addressState: this.addressEditModel.addressState,
      addressZip: this.addressEditModel.addressZip,
      groupName: this.addressEditModel.groupName,
    });

    this.changeDetector.markForCheck();
  }

  onUpdate(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.getFormValues();
    this.service.updateAddressDetails(this.addressEditModel)
      .subscribe((data: number) => {
        if (data === this.addressId) {
          this.addressInfoService.resetAddress(data);
          this.addressDetailHeaderService.resetAddress(data);
          this.callFlowService.resetFunction("");
        } else {
          this.router.navigate(["retrieval", "addressdetail", data]);
        }
        this.messagingService.showToast("Address Details successfully edited.", SeverityType.SUCCESS);
      });

    this.visibleChange(false);
  }

  private getFormValues() {
    this.changeDetector.markForCheck();

    this.addressEditModel.email = this.formGroup.get("email").value;
    this.addressEditModel.phone = this.formGroup.get("phone").value;
    this.addressEditModel.faxNumber = this.formGroup.get("fax").value;
    this.addressEditModel.contactName = this.formGroup.get("contactName").value;

    this.addressEditModel.address1 = this.formGroup.get("address1").value;
    this.addressEditModel.address2 = this.formGroup.get("address2").value;
    this.addressEditModel.addressCity = this.formGroup.get("addressCity").value;
    this.addressEditModel.addressState = this.formGroup.get("addressState").value;
    this.addressEditModel.addressZip = this.formGroup.get("addressZip").value;
    this.addressEditModel.groupName = this.formGroup.get("groupName").value;
  }


  visibleChange($event) {
    this.isCreateVisible = $event;
    this.formClose.emit($event);
  }

  private isHDVIServiceOrg(): boolean {
    return this.organizationId === 1;
  }
}

