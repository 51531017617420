import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { CanDeactivateGuard } from "../../api/url-guards/can-deactivate.guard";
import { ClinicalOrFeedbackComponent } from "./clinical/clinical-orfeedback/clinical-orfeedback.component";
import { ClinicalComponent } from "./clinical/clinical.component";
import { DocumentReviewerComponent } from "./document-reviewer/document-reviewer.component";
import { PendsComponent } from "./pends/pends.component";
import { RetrievalComponent } from "./retrieval/retrieval.component";
import { RetrievalResolve } from "./retrieval/retrieval.resolve";

const routes: Routes = [
  {
    path: "retrieval",
    component: RetrievalComponent, canActivate: [AuthGuard],
    resolve: {
      userModel: RetrievalResolve,
    },
  },
  { path: "clinical", component: ClinicalComponent, canActivate: [AuthGuard] },
  { path: "clinical/orfeedback", component: ClinicalOrFeedbackComponent, data: { defaultPath: "/dashboard/clinical" }, canActivate: [AuthGuard] },
  { path: "documentreviewer", component: DocumentReviewerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: "pends", component: PendsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
