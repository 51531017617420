<app-modal header="Set Due Date" [(visible)]="visible" (onShow)="onShow.emit($event)" (onHide)="onModalHide()"
  class="expected-due-date-modal">
  <form-factory class="expected-due-date-input" [formGroup]="formGroupExpectedDueDate" [model]="expectedDueDateInput">
  </form-factory>
  <app-button classes="small secondary" text=" X Clear Date" (onClick)="clearExpectedDueDate()">
  </app-button>
  <footer>
    <app-button class="header-button" text="SUBMIT" (onClick)="onSetDueDate()">
    </app-button>
  </footer>
</app-modal>