// NOTE: From MRCS50.dbo.ContactMethodType
export enum ContactMethodType {
  Call = 1,
  Fax = 2,
  Fax_Blast = 3,
  Email = 4,
  Incoming_Call = 5,
  Other = 6,
  Note = 7,
  Mail = 8,
  ProviderAgingEmail = 9,
  InvoiceCall = 10,
  PendCall = 11,
  ResearchCall = 12,
  AdditionalCollectionCall = 13,
  ProviderAgingFax = 14,
  VendorCall = 15,
  VendorNote = 16,
  External = 18,
  AutomatedOutreachFax = 19,
  AutomatedOutreachEmail = 20,
}
