<div class="project-bulk-container">

  <div *ngIf="isShowChaseControls">
      <form [formGroup]="formGroup">
        <div class="ui-g ui-fluid" *ngIf="isShowStatusType">
          <div class="ui-g-3 ui-md-3 ui-lg-3">
            <span class="error-icon"><app-icon class="error-icon" iconName="question-circle" *ngIf="isShowStatusError" [title]="required"></app-icon></span>
            <form-factory [formGroup]="form" [model]="statusInput" (onChange)="onSelectedStatus($event)"></form-factory>
          </div>
        </div>

        <div *ngIf="isShowPendCode">
          <div class="ui-g ui-fluid">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="pendCodesInput" (onChange)="setPendCodeInvoiceControls()"></form-factory>
            </div>
          </div>

          <div class="ui-g ui-fluid" *ngIf="isPendReasonVisible">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="pendReasonInput" ></form-factory>
            </div>
          </div>

          
          <div class="ui-g ui-fluid" *ngIf="isShowPendCodeServerity">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="pendSeverityInput" > </form-factory>
            </div>
          </div>

          <div class="ui-g ui-fluid">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="invoiceNumberInput"></form-factory>
            </div>
          </div>

          <div class="ui-g ui-fluid">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="invoiceAmountInput"></form-factory>
            </div>
          </div>

          <div class="ui-g ui-fluid">
            <div class="ui-g-3 ui-md-3 ui-lg-3">
              <form-factory [formGroup]="formGroup" [model]="pendCompanyInput"></form-factory>
            </div>
          </div>
        </div>

        <div class="ui-g ui-fluid" *ngIf="isShowAssignUser">
          <div class="ui-g-3 ui-md-3 ui-lg-3">
            <span class="error-icon"><app-icon class="error-icon" iconName="question-circle" *ngIf="isShowAssignUsers" [title]="required"></app-icon></span>
            <form-factory [formGroup]="form" [model]="userInput" (onChange)="onSelectedUsers($event)"></form-factory>
          </div>
        </div>

        <div class="ui-g ui-fluid">
          <div class="ui-g-6 ui-md-6 ui-lg-6">
            <form-factory [formGroup]="formGroup" [model]="chasesNotes"></form-factory>
          </div>
        </div>

        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <app-button text="CONTINUE TO VALIDATION" (onClick)="validate()"></app-button>
          </div>
        </div>

      </form>
  </div>

  <div class="ui-g ui-fluid" *ngIf="isShowValidateGrid">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <app-basic-grid [configuration]="serverGridConfiguration"
                       [data]="data">
      </app-basic-grid>
    </div>
    <div class="ui-g ui-fluid"><app-button (onClick)="finalBulkUpdate()" text="FINISH BULK UPDATE" [disabled]="isFinalBulkUpdate"></app-button></div>
  </div>
</div>
