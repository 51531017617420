import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-aid-tag-history-tracking",
  templateUrl: "./aid-tag-history-tracking.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AidTagHistoryTrackingComponent {
  filters: string[] = ["Projects", "Address"];
  dashboardType = LookerRiskDashboardType.AID_TAGS_HISTORY;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.AID, HideFilters.USER_ID2];
}
