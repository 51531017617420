<div *ngIf="isAvailable" class="button">
  <app-icon
    (click)="toggleAndGetCallTranscription()"
    iconStyle="mrcs-icon"
    iconType="mrcs-icon"
    iconName="play-recording">
  </app-icon>

  <app-modal class="call__modal" 
    [(visible)]="isVisible" 
    header="{{callRecordModalHeader | uppercase}}">

    <div class="call__detail">
      <div class="call__detail__info"> {{callTo}}</div>
      <div class="call__detail__info"> {{callTime}}</div>
    </div>

    <audio class="call__audio" controls preload="none">
      <source src="{{callRecordedAudio}}">
    </audio>

    <footer>
    </footer>

  </app-modal>
</div>
