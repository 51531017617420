<div [formGroup]="formGroup" class="control control--input control--autocomplete" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <p-autoComplete 
    [hidden]="!hasOptions"
    [dropdown]="isDropdownEnabled"
    [forceSelection]="!model.readonly"
    [minLength]="model.searchMinimum"
    field="text"
    dataKey="value"
    [inputStyleClass]="classes"
    appendTo="body"
    [inputId]="model.key"
    [readonly]="model.readonly"
    [placeholder]="model.placeholder"
    [suggestions]="filteredOptions"
    (completeMethod)="filterOptions($event)"
    (onSelect)="onEvent($event)"
    (onKeyUp)="onInput.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    [formControlName]="model.key"></p-autoComplete>
    <div *ngIf="!hasOptions" class="text--danger">Information Not Available.</div>
</div>