import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GridHeaderStatsComponent } from "./grid-header-stats.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        GridHeaderStatsComponent,
    ],
    exports: [
        GridHeaderStatsComponent,
    ],

})
export class GridHeaderStatsModule { }
