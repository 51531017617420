import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ListboxModule } from "primeng/listbox";
import { ButtonModule } from "../../../shared/button/button.module";
import { CheckboxGroupComponent } from "./checkbox-group.component";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    ListboxModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CheckboxGroupComponent,
  ],
  entryComponents: [
    CheckboxGroupComponent,
  ],
  exports: [
    CheckboxGroupComponent,
  ],
})
export class CheckboxGroupModule { }
