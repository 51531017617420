import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { CalculatedGroup } from "./calculated-group.model";
import { PpcDates } from "./ppc-dates.model";
import { PpcService } from "./ppc.service";

@Directive()
export class CalculatedGroupDirective extends DynamicControlDirective<CalculatedGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();
  ppc = new PpcDates();
  chaseId: number;
  measureCode: string;

  constructor(
    private readonly ppcService: PpcService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseId = state.chaseId;
        this.measureCode = state.measureCode;
        this.ppcService.getCalculations(this.chaseId);
        this.changeDetector.markForCheck();
      }),

      this.ppcService.ppc.subscribe(ppc => {
        this.ppc = ppc;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  get hasPpc(): boolean {
    return this.ppc != null;
  }

  onChangeEvent(event: DynamicFormEvent): void {
    const originalSuccessFn = event.successFn || (() => { });
    event.successFn = () => {
      originalSuccessFn();
      this.ppcService.getCalculations(this.chaseId);
    };

    this.onChange.emit(event);
  }
}
