import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { ChaseDetailForAuditItem } from "../../chase-detail-for-audit-item.model";
import { AuditPackageGenerateService } from "../audit-package-generate/audit-package-generate.service";
import { AuditQueryGridService } from "../audit-query-grid/audit-query-grid.service";

@Component({
  selector: "app-audit-query-item-readiness",
  templateUrl: "./audit-query-item-readiness.component.html",
  styleUrls: ["./audit-query-item-readiness.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditQueryItemReadinessComponent implements OnInit {
  form: FormGroup;
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter(null);
  @Input() chaseDetailsForAudit: ChaseDetailForAuditItem;
  auditReadyInput: Dropdown;
  showConfirmationForAutoDeleteAuditEntry = false;
  newAuditReadyStatus = "";

  constructor(
    protected readonly formService: FormService,
    private auditQueryGridService: AuditQueryGridService,
    private auditPackageGenerateService: AuditPackageGenerateService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.auditReadyInput = new Dropdown({
      key: "auditReady",
      appendTo: "body",
      autoDisplayFirst: false,
      options: [
        new SelectableInput({ text: "", value: "blank", extra: "blank" }),
        new SelectableInput({ text: "Yes", value: "yes", extra: true }),
        new SelectableInput({ text: "No", value: "no", extra: false }),
      ],
    });
    this.form = this.formService.createFormGroup([this.auditReadyInput]);
    this.form.get(this.auditReadyInput.key).setValue(this.chaseDetailsForAudit.auditReady);

  }

  auditReadyStatusChange($event: SelectableInput): void {
    this.newAuditReadyStatus = $event.value.toString();

    if (this.newAuditReadyStatus.toString().toLowerCase() !== "yes"
      && this.chaseDetailsForAudit.action.toLowerCase() === "edit") {
      this.showConfirmationForAutoDeleteAuditEntry = true;

      return;
    }

    this.updateAuditReadyStatus(this.newAuditReadyStatus);
  }

  private updateAuditReadyStatus(auditReady: string): void {
    // scope for more than one AuditPackageIds, hence an array for now
    const auditPackageIds = [];
    if (NumberHelper.isAvailable(this.chaseDetailsForAudit.auditPackageId)) {
      auditPackageIds.push(this.chaseDetailsForAudit.auditPackageId);
    }

    const updateAuditReadyState = new ChaseDetailForAuditItem({
      auditPackageIds,
      projectId: this.chaseDetailsForAudit.projectId,
      chaseId: this.chaseDetailsForAudit.chaseId,
      numeratorId: this.chaseDetailsForAudit.numeratorId,
      auditReady,
    });

    this.auditQueryGridService.saveAuditReadyStatus(updateAuditReadyState).subscribe(
      data => {
        this.auditQueryGridService.reloadAuditGridFromState();
        this.messagingService.showToast("Audit Ready Status saved successfully.", SeverityType.SUCCESS);

        this.changeDetector.markForCheck();
      },
      err => {
        this.messagingService.showToast("Failed to update Audit Ready Status.", SeverityType.ERROR);
        this.changeDetector.markForCheck();
      }
    );
  }

  onChangeStatusConfirm(): void {
    this.updateAuditReadyStatus(this.newAuditReadyStatus);
    this.auditPackageGenerateService.autoDeleteAuditPackageItem(this.chaseDetailsForAudit.auditPackageItemId);
  }

  onCancelStatusChange(): void {
    this.form.get(this.auditReadyInput.key).setValue(this.chaseDetailsForAudit.auditReady);
  }
}
