import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthService } from "../../../../auth/auth.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { DirectoryUserRole } from "../directory-user-role";

@Injectable({ providedIn: "root" })
export class RecordingPlaybackService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private authService: AuthService
  ) {  }

  getCallTranscription(transcriptionFilename: string): Observable<string> {
    const url = `${this.baseApiUrl}retrieval/call/transcriptiontext?transcriptionFilename=${transcriptionFilename}`;

    return this.http.get(url) as Observable<string>;
  }

  get showRecordingPlayIcon(): boolean {
    const userDirectoryRoleIds = this.authService.user.directoryRoleIds;

    const directoryRolesToCheck = [
        DirectoryUserRole.Admin
      , DirectoryUserRole.SystemAdministrator
      , DirectoryUserRole.DocumentIntakeManager
      , DirectoryUserRole.DocumentQAManager
      , DirectoryUserRole.EMRManager
      , DirectoryUserRole.FieldTechManager
      , DirectoryUserRole.ProjectManager
      , DirectoryUserRole.CallCenterManager
      , DirectoryUserRole.ReportingManager
      , DirectoryUserRole.RetrievalLead,
    ];

    return userDirectoryRoleIds.some(r => directoryRolesToCheck.includes(r));
  }

}
