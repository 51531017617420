import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";

// TODO: Change to EntityHelper? DynamicEntityHelper?
export class RiskHelper {
  static getAttributes(name: string, o: any): DynamicEntityAttribute[] {
    const attribtues = o.attributes.filter(attribute => attribute.attributeCode === name);
    return attribtues;
  }

  static getEve(o: any): any[] {
    return o.hasOwnProperty("isEveDiagnosis") && o.isEveDiagnosis ? o : null;
  }

  static getAttribute(name: string, o: any): DynamicEntityAttribute {
    const relativeAttributes = RiskHelper.getAttributes(name, o);
    const relativeAttribute = relativeAttributes.find(attribute => !attribute.isAdmin) || relativeAttributes.find(attribute => attribute.isAdmin) || {} as any;
    return relativeAttribute;
  }

  static getStringValueCurry(name: string): (o: any) => string {
    return (o: any): string => {
      const relativeAttribute = RiskHelper.getAttribute(name, o);
      return relativeAttribute.value as string;
    };
  }

  static getNumberValueCurry(name: string): (o: any) => number {
    return (o: any): number => {
      const attribute = RiskHelper.getAttribute(name, o);
      const value = RiskHelper.getNumberValue(attribute);
      return value;
    };
  }

  static getNumberValue(attribute: DynamicEntityAttribute): number {
    return Number(attribute.value);
  }

  static getBoolValueCurry(name: string): (o: any) => boolean | null {
    return (o: any): boolean => {
      const attribute = RiskHelper.getAttribute(name, o);
      const value = RiskHelper.getBoolValue(attribute);
      return value;
    };
  }

  static getBoolValueCurryFromNLP(name: string): (o: any) => boolean | null {
    return (o: any): boolean => {
      const attribute = RiskHelper.getAttribute(name, o);
      const value = RiskHelper.getBoolValueFromNLP(attribute);
      return value;
    };
  }

  static getBoolValueFromNLP(attribute: DynamicEntityAttribute): boolean | null {
    return attribute.isNlp;
  }

  static getBoolValue(attribute: DynamicEntityAttribute): boolean | null {
    if (!StringHelper.isAvailable(attribute.value as string)) {
      return null;
    }

    return attribute.value === "1";
  }
}
