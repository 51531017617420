<div class="flexBox">
    <div class="flexBox__member-detail">
      <app-list [items]="personalmemberItems"></app-list>
    </div>
  <div class="member-validation">
    <div>
      <div class="member-validation__header">Member is Correct</div>
       <app-member-validation-yes-no [isChaseEnabled]="enabled" [configuration]="configuration" (onSelect)="handleChange($event)"></app-member-validation-yes-no>
    </div>

    <div class="member-validation__container" *ngIf="isNo">
      <div class="member-validation__container--no">
        <form-factory [formGroup]="form" class="member-validation__container--no--reason" [model]="memberValidationReasonInput">
        </form-factory>
      <form-factory class="member-validation__input member-validation__input--page-number" [formGroup]="form" [model]="memberValidationPageNumberInput">
      </form-factory>
      <div class="align-items">
        <app-button class="member-validation__submit-btn" classes="small secondary-action" (onClick)="submit()" text="Submit">
        </app-button>
      </div>
      </div>
    </div>
  </div>
</div>