<form>
  <div class="ui-g ui-fluid basic-filter">
    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <form-factory
        class="input"
        [formGroup]="form"
        [model]="getDocumentStatus()"
        (onChange)="change()"></form-factory>
      <form-factory
        class="input input__textarea"
        [formGroup]="form"
        [model]="getNote()"
        (onChange)="change()"></form-factory>
    </div>
  </div>
</form>