import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { IconModule } from "../icon/icon.module";
import { PanelModule } from "../panel/panel.module";
import { CreateNewPendModalComponent } from "./create-new-pend-modal/create-new-pend-modal.component";
import { CreatePendModalComponent } from "./create-pend-modal/create-pend-modal.component";
import { EditPendModalComponent } from "./edit-pend-modal/edit-pend-modal.component";
import { PendModalComponent } from "./pend-modal/pend-modal.component";
import { PendNotesGridComponent } from "./pend-notes-grid/pend-notes-grid.component";
@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DynamicFormsModule,
    GridModule,
    IconModule,
    PanelModule,
    DropdownModule, // TODO: Remove when only dynamic forms is used.
    FormsModule, // TODO: Remove when only dynamic forms is used.
  ],
  declarations: [
    PendModalComponent,
    EditPendModalComponent,
    CreatePendModalComponent,
    PendNotesGridComponent,
    CreateNewPendModalComponent,
  ],
  exports: [
    PendModalComponent,
    CreateNewPendModalComponent,
  ],
})
export class PendModule { }
