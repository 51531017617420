import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../auth/auth.service";
import { MessagingService } from "../../../core/messaging/messaging.service";
import { SeverityType } from "../../../core/messaging/severity-type.enum";
import { FormService } from "../../../dynamic-forms/form.service";
import { ChipAutocomplete } from "../../../dynamic-forms/inputs/chip-autocomplete/chip-autocomplete.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ChaseDetailInfoService } from "../../../platform/modules/member/chase-detail/chase-detail-info/chase-detail-info.service";
import { ChaseDetailState } from "../../../platform/modules/member/chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { AddressTimelineStateService } from "../../../platform/modules/retrieval/address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../../platform/modules/retrieval/address-timeline/address-timeline.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../utilities/reg-Ex-Helper";
import { ColorPaletteComponent } from "../color-palette/color-palette.component";
import { TagActionType } from "../model/tag-action-type.enum";
import { TagItems } from "../model/tag-items.model";
import { TagSourceType } from "../model/tag-source-type.enum";
import { TagType } from "../model/tag-type.enum";
import { TagStateService } from "../tag-state.service";
import { TagService } from "../tag.service";

@Component({
  selector: "app-create-tag",
  templateUrl: "./create-tag.component.html",
  styleUrls: ["./create-tag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTagComponent implements OnInit, OnDestroy {
  get tagAction(): string {
    if (this.tagActionType === TagActionType.REPLACE) {
        this.isManageTag = true;
        return "Replacement Tag";
    } else if (this.tagActionType === TagActionType.ADD) {
        this.isManageTag = true;
        return "Tag";
    } else {
      return "Tag";
    }
  }

  constructor(
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private tagService: TagService,
    private messagingService: MessagingService,
    private authService: AuthService,
    private addressTimelineService: AddressTimelineService,
    private addressTimelineStateService: AddressTimelineStateService,
    private tagStateService: TagStateService,
    private chaseDetailInfoService: ChaseDetailInfoService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly el: ElementRef
  ) { }

  get lowerBoundClass(): string {
    return (this.el.nativeElement.getBoundingClientRect().right > (window.innerWidth - 420)) ?  "flip-dropdown" : "";
  }

  isManageTag = false;
  @ViewChild(ColorPaletteComponent) colorPaletteComponent: ColorPaletteComponent;
  tagForm: FormGroup;
  tagInput: ChipAutocomplete;
  tagColor: string;
  defaultTagColor = "#DCDCDC";
  @Input() tagType: TagType;
  @Input() objectIds: number[];
  @Input() value: any;
  @Input() rowIndex: number;
  tagName = "";
  tagItems: TagItems;
  tagId: number;
  pTagText = "";
  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() getCurrentIndex = new EventEmitter();
  @Input() hasGridSelection = false;
  objectIdsList: any[] = [];
  @Input() tagActionType: TagActionType;
  @Output() visible = new EventEmitter();
  @Input() isGrid = false;
  @Input() isCreateTagTemplateVisible: boolean;
  allAvailableTags: SelectableInput[];
  recentlyAddedTag: SelectableInput[];
  subs = new SubSink();
  chaseDetailState: ChaseDetailState;
  @Input() totalEntityCount;
  searchTextChanged = new Subject<string>();
  onModelChange: Function = () => { };

  ngOnInit() {
    this.initializeTagForm();
    this.objectIdsList.push(this.objectIds);
    this.tagInput.options = [];
    this.subs.add(
      this.tagStateService.allAvailableTagList.subscribe(result => {
        if (result) {
          const isTagAvailable = ArrayHelper.isAvailable(result);
          if (isTagAvailable) {
              this.allAvailableTags = result;
          }
        }
      }),
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllTagsList()))
          .subscribe()
      );
  }

  bindDropdown(): void {
    if (!this.isManageTag) {
    this.allAvailableTags = this.allAvailableTags.filter(x => this.value.filter(y => y.value === x.value).length === 0);
    }
    this.tagInput.options = [...this.allAvailableTags];
    this.changeDetector.markForCheck();
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  private initializeTagForm(): void {
    this.tagInput =
    new ChipAutocomplete({
        key: this.hasGridSelection ? "manageTags" : "tags",
        label: this.tagAction,
        placeholder: this.isGrid ? "" : "Type Here",
        validators: [Validators.maxLength(50), Validators.pattern(RegExHelper.tagPattern)],
        errorMessages: {
          maxlength: "Tag text cannot be more than 50 characters.",
          pattern: "Tags Cannot Contain Special Characters.",
        },
        useFilter: false,
      });
    this.tagForm = this.formService.createFormGroup([this.tagInput]);
    this.changeDetector.markForCheck();
  }

  saveNewTag(): void {
    if (this.tagForm.invalid) {
      if (this.tagForm.get(this.tagInput.key).errors.maxlength) {
        this.messagingService.showToast("Tag text cannot be more than 50 characters.", SeverityType.ERROR);
      } else {
        this.messagingService.showToast("Tags Cannot Contain Special Characters.", SeverityType.ERROR);
      }
      return;
    }
    this.tagItems = new TagItems({
      tagId: this.tagId,
      organizationId: this.authService.user.organizationId,
      tagTypeId: this.tagType,
      tagSourceId: TagSourceType.USER,
      tagText: this.pTagText.toUpperCase(),
      color: this.tagColor,
      objectIds: this.hasGridSelection ? this.objectIds : this.objectIdsList,
    });
    this.value = this.value || [];
    if (!this.hasGridSelection) {
      if (this.value.length >= 8) {
      this.messagingService.showToast("Max number of tags reached.", SeverityType.ERROR);
      } else {
        this.saveTags();
      }
    } else {
      this.saveTags();
    }
    this.isCreateTagTemplateVisible = false;
    this.tagForm.reset();
  }

  associateExistingTag(selectedTag: SelectableInput, hasValue?: boolean): void {
    this.tagItems = new TagItems({
      tagId: hasValue ? selectedTag : selectedTag.extra.tagId,
      objectIds: this.hasGridSelection ? this.objectIds : this.objectIdsList,
    });
    const isTagAlreadyAvailable = this.value.filter(x => x.text === selectedTag.text);
    if (isTagAlreadyAvailable.length === 0) {
    this.tagService.associateTags(this.tagItems)
      .subscribe(tagID => {
        this.tagId = tagID;
        if (this.tagId > 0) {
          this.tagService.getAllTagsList(this.tagType, this.tagId).subscribe(result => {
            if (!this.isManageTag) {
                !this.isGrid ? this.tagStateService.setNewTagToExistingTagList(result) : this.onAdd.emit({ value: result[0] });
            }
            this.getTimelineBasedOnTagType();
            this.changeDetector.markForCheck();
          });
          this.visible.emit(false);
          this.tagId = null;
          this.isCreateTagTemplateVisible = false;
          this.tagForm.reset();
        }
      });
    } else {
      this.messagingService.showToast("Cannot insert duplicate tag.", SeverityType.ERROR);
    }
  }

  addTagToExistingTagList(item: SelectableInput): void {
    this.value = this.value || [];
    if (!this.hasGridSelection) {
      if (this.value.length >= 8) {
        this.messagingService.showToast("Max number of tags reached.", SeverityType.ERROR);
      } else {
        this.appendTagToTagList(item);
      }
    } else {
      this.appendTagToTagList(item);
    }
    this.visible.emit(false);
    this.tagForm.reset();
  }

  private appendTagToTagList(item: SelectableInput): void {
    if (item) {
      const isTagAlreadyAvailable = this.value.filter(x => x.text === item.text);
      if (isTagAlreadyAvailable.length === 0) {
            this.associateExistingTag(item);
            this.value.map(x => x.extra.isSelected = false);
      } else {
        this.messagingService.showToast("Cannot insert duplicate tag.", SeverityType.ERROR);
      }
  }
  }

  onSelectedColor(selectedTagColor: string): void {
    this.tagColor = selectedTagColor;
    if (StringHelper.isAvailable(this.tagColor) && StringHelper.isAvailable(this.pTagText) && !this.hasGridSelection) {
      this.saveNewTag();
    } else {
      this.visible.emit(false);
      this.colorPaletteComponent.hideCreateTagTemplate(false);
    }
  }

  onAddTags(event: any): void {
    this.isCreateTagTemplateVisible = this.tagActionType !== TagActionType.REPLACE;
    if (event.text) {
      if (!this.hasGridSelection) {
        this.addTagToExistingTagList(event);
      } else {
        this.pTagText = event.text;
        this.tagId = event.value;
        this.tagColor = event.extra.color;
        this.isCreateTagTemplateVisible = false;
        this.colorPaletteComponent.hideCreateTagTemplate(this.isCreateTagTemplateVisible);
        this.changeDetector.detectChanges();
      }
    } else {
      this.tagName = event.target.value;
      if (StringHelper.isAvailable(event.target.value)) {
        this.newTagValues(this.tagName);
        if (!this.tagForm.valid) {
          this.formService.markAllAsTouched(this.tagForm);
          this.changeDetector.markForCheck();
        }
      }
    }
    if (event.keyCode === 13 && !this.hasGridSelection && this.pTagText !== "") {
      this.tagColor = this.defaultTagColor;
      this.saveNewTag();
    }
  }

  private newTagValues(value: string) {
    this.pTagText = value;
    }

  textboxFocus() {
    this.getCurrentIndex.emit(this.rowIndex);
  }

  textboxBlur() {
    this.getCurrentIndex.emit(-1);
    }

  private getTimelineBasedOnTagType(): void {
    switch (this.tagType) {
      case TagType.MASTERDOCUMENTSOURCE:
        const objectId = this.hasGridSelection ? this.objectIdsList[0] : this.objectIds;
        this.fetchAddressTimelineItems(objectId);
        break;
      case TagType.CHASE:
        const chaseId = this.hasGridSelection ? this.objectIdsList[0] : this.objectIds;
        this.fetchChaseTimelineItems(Number(chaseId));
        break;

      default:
        break;
    }
  }

  hideCreateTagTemplate(value: boolean): void {
    this.isCreateTagTemplateVisible = value;
    this.colorPaletteComponent.hideCreateTagTemplate(this.isCreateTagTemplateVisible);
    this.tagForm.reset();
  }

  private fetchAddressTimelineItems(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  private fetchChaseTimelineItems(chaseId: number): void {
    this.chaseDetailInfoService
      .getTimelineItems(chaseId)
      .subscribe(timelineItems =>
        this.chaseDetailStateService.timelineItems.next(timelineItems));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChange(): void {
    this.isCreateTagTemplateVisible = false;
    this.colorPaletteComponent.hideCreateTagTemplate(this.isCreateTagTemplateVisible);
  }

  getAllTagsList(): void {
    this.allAvailableTags = [];
    const searchText = this.tagForm.get(this.tagInput.key).value;
    this.tagService.getAllTagsList(this.tagType, null, searchText)
        .subscribe(data => {
          this.allAvailableTags = data;
          if (ArrayHelper.isAvailable(this.allAvailableTags)) {
            this.bindDropdown();
          }
        });
  }

  saveTags(): void {
    this.tagService.saveTags(this.tagItems)
    .subscribe(tagID => {
      this.tagId = tagID;
      if (this.tagId > 0) {
        const searchText = this.tagForm.get(this.tagInput.key).value;
        this.tagService.getAllTagsList(this.tagType, this.tagId, searchText).subscribe(result => {
          this.allAvailableTags.push(result[0]);
          this.addTagToExistingTagList(result[0]);
          this.getTimelineBasedOnTagType();
          this.changeDetector.markForCheck();
        });
        if (!this.hasGridSelection) {
        this.messagingService.showToast("New Tag created successfully.", SeverityType.SUCCESS);
        } else {
          const entityName = this.totalEntityCount > 1 ? "chases" : "chase";
          this.messagingService.showToast(`${this.pTagText} tag has been applied to ${this.totalEntityCount} ${entityName}`, SeverityType.SUCCESS);
        }
        this.tagId = null;
      }
    });
  }


}
