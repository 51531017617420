import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { AuthService } from "../../../../auth/auth.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { MasterProvider } from "../../../../shared/national-provider-grid/national-provider.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { StatesListService } from "../../../api/states-list/states-list.service";
import { AddressSearchRequest } from "../../dashboard/retrieval/address-search-request.model";
import { FunctionalRole } from "../../dashboard/retrieval/functional-role.enum";
import { AddressSearchResult } from "./address-search-result.model";
import { AddressSearchService } from "./address-search.service";
import { CreateAddress } from "./create-address.model";

@Component({
    selector: "retrieval-address-search",
    templateUrl: "./address-search.component.html",
    styleUrls: ["./address-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent implements OnInit, OnDestroy {
    @Input() visible = false;
    @Input() callingSource = "";
    @Input() masterDocumentSourceId: number;
    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() onShow = new EventEmitter<null>(true);
    @Output() onHide = new EventEmitter<null>(true);
    @Output() onAddressSelection = new EventEmitter<AddressSearchResult>();
    @Output() onMoveChases = new EventEmitter<null>();
    @Output() isNewAddress = new EventEmitter<CreateAddress>(null);

    private sink = new SubSink();
    formGroupAddressSearch: FormGroup;
    retrievalLocationId: Textbox;
    address1: Textbox;
    address2: Textbox;
    city: Textbox;
    currentState: any;
    selectedState: string;
    states: SelectableInput[];
    stateList: Dropdown;
    zip: Textbox;
    groupName: Textbox;
    contact: Textbox;
    memberFirstName: Textbox;
    memberLastName: Textbox;
    memberDateOfBirth: Textbox;
    inheritChaseInput: Checkbox;
    addressSearchModel: AddressSearchRequest;
    noAddressSearchResultsFound = false;

    addressSearchGridConfiguration = new GridConfiguration();
    addressSearchData: any[] = [];
    addressGridselection: AddressSearchResult;
    private addressSearchGridPageSize = 5;
    addressActionLinkHidden = true;
    toggleAddressActionText: string;
    findAddress = true;
    notesText: string;
    notesMissing = false;
    providerToolVisible = false;
    selectedProvider: MasterProvider;

    constructor(
        private readonly formService: FormService,
        private stateService: StatesListService,
        private addressSearchService: AddressSearchService,
        private messagingService: MessagingService,
        private authService: AuthService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.retrievalLocationId = new Textbox({
            key: "retrievalLocationId",
            type: TextboxType.NUMBER,
            label: "Address ID",
            validators: [Validators.pattern(RegExHelper.wholeNumber)],
            errorMessages: {
                pattern: "AID should be numeric. Decimal or negative numbers not allowed.",
            },
        });

        this.address1 = new Textbox({
            key: "address1",
            label: "Address1",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "Address1 name can not be more than 50 characters.",
            },
        });

        this.address2 = new Textbox({
            key: "address2",
            label: "Address2",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "Address2 name can not be more than 50 characters.",
            },
        });

        this.city = new Textbox({
            key: "city",
            label: "City",
            placeholder: "Cityname",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "City name can not be more than 50 characters.",
            },
        });

        this.zip = new Textbox({
            key: "postalCode",
            label: "Zip",
            validators: [Validators.minLength(5), Validators.maxLength(10)],
            errorMessages: {
                minlength: "Zip Code can not be less than 5 characters.",
                maxlength: "Zip Code can not be more than 10 characters.",
            },
        });

        this.groupName = new Textbox({
            key: "group",
            label: "Group Name",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "Group Name can not be more than 50 characters.",
            },
        });

        this.contact = new Textbox({
            key: "contactData",
            label: "Contact",
        });

        this.memberFirstName = new Textbox({
            key: "memberFirstName",
            label: "Member First Name",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxLength: "Member First Name can not be more than 50 characters.",
            },
        });

        this.memberLastName = new Textbox({
            key: "memberLastName",
            label: "Member Last Name",
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxLength: "Member Last Name can not be more than 50 characters.",
            },
        });

        this.memberDateOfBirth = new Textbox({
            key: "memberDateOfBirth",
            label: "Member Date of Birth",
            validators: [Validators.maxLength(10), Validators.pattern(RegExHelper.date)],
            errorMessages: {
                maxLength: "Member Date of Birth can not be more than 10 characters.",
                pattern: "Member Date of Birth should be in MM/DD/YYYY format.",
            },
        });

        this.stateList = new Dropdown({
            key: "state",
            label: "State",
            placeholder: "Select State",
            appendTo: "body",
        });

        this.inheritChaseInput = new Checkbox({
            key: "inheritChase",
            label: "Chases being moved will inherit assignment from the destination AID (optional)",
        });

        this.addressSearchGridConfiguration.columns = [
            new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID" }),
            new GridColumnDefinition({ field: "documentSourceTypeName", header: "Source", show: false }),
            new GridColumnDefinition({ field: "address", header: "Address" }),
            new GridColumnDefinition({ field: "city", header: "City" }),
            new GridColumnDefinition({ field: "documentSourceTypeName", header: "Retrieval Method" }),
        ];

        this.addressSearchGridConfiguration.pageSize = this.addressSearchGridPageSize;
        this.addressSearchGridConfiguration.selectionMode = "single";
        this.addressSearchGridConfiguration.showActionColumn = false;

    }

    ngOnInit() {
    this.sink.add(
      this.onShow.subscribe(() => {
        this.getStateListForAddress();
      }),

      this.addressSearchService.searchAddress.subscribe(data => {
        if (data != null) {
          this.autoFillAddressSearch(data);
        }
      })
    );

    this.createForm();
  }

    ngOnDestroy() {
    this.sink.unsubscribe();
  }

    autoFillAddressSearch(provider: MasterProvider): void {
    this.formGroupAddressSearch.get(this.address1.key).setValue(provider.address1);
    this.formGroupAddressSearch.get(this.address2.key).setValue(provider.address2);
    this.formGroupAddressSearch.get(this.city.key).setValue(provider.city);
    this.formGroupAddressSearch.get(this.zip.key).setValue(provider.zipCode);
    const state = this.stateList.options.find(s => s.value.toString().toLowerCase() === provider.state.toLowerCase());
    this.formGroupAddressSearch.get(this.stateList.key).setValue(state.value);
    this.providerToolVisible = false;
    this.changeDetector.markForCheck();

  }

    private getStateListForAddress(): void {
        this.stateService.getStateList()
            .subscribe(statelist => {
                this.stateList = new Dropdown({ ...this.stateList, options: statelist } as any);
                this.changeDetector.markForCheck();
            });
    }

    private createForm(): void {
        this.formGroupAddressSearch = this.formService.createFormGroup([
            this.retrievalLocationId,
            this.address1,
            this.address2,
            this.city,
            this.stateList,
            this.zip,
            this.groupName,
            this.contact,
            this.memberFirstName,
            this.memberLastName,
            this.memberDateOfBirth,
            this.inheritChaseInput]);
    }

    get header(): string {
        return this.findAddress ? "ADDRESS SEARCH" : "CREATE NEW ADDRESS";
    }

    get addressActionText(): string {
        return this.findAddress ? "FIND ADDRESSES" : "CREATE ADDRESS + MOVE CHASE";
    }

    toggleAction() {
        this.findAddress = !this.findAddress;
        this.notesMissing = false;
        this.notesText = null;

        if (this.findAddress) {
            this.addressActionLinkHidden = true;
            this.noAddressSearchResultsFound = false;
            this.resetAddressSearchForm();
        } else if (!this.findAddress) {
            this.toggleAddressActionText = "Click here to go back to Search Address";

            this.retrievalLocationId = new Textbox({ ...this.retrievalLocationId, value: null, disabled: true } as any);

            this.groupName = new Textbox({ ...this.groupName, value: null, disabled: true } as any);

            this.memberFirstName = new Textbox({ ...this.memberFirstName, value: null, disabled: true } as any);

            this.memberLastName = new Textbox({ ...this.memberLastName, value: null, disabled: true } as any);

            this.memberDateOfBirth = new Textbox({ ...this.memberDateOfBirth, value: null, disabled: true } as any);

            const address1Value = this.formGroupAddressSearch.get(this.address1.key).value;
            this.address1 = new Textbox({
                ...this.address1,
                value: address1Value,
                validators: [Validators.required, Validators.minLength(5), Validators.maxLength(50)],
                errorMessages: {
                    required: "Street1 is required.",
                    minlength: "Street1 can not be less than 5 characters.",
                    maxlength: "Street1 can not be more than 50 characters.",
                },
            } as any);

            const address2Value = this.formGroupAddressSearch.get(this.address2.key).value;
            this.address2 = new Textbox({
                ...this.address2,
                value: address2Value,
                validators: [Validators.maxLength(50)],
                errorMessages: {
                    maxlength: "Street2 name can not be more than 50 characters.",
                },
            } as any);

            const city = this.formGroupAddressSearch.get(this.city.key).value;
            this.city = new Textbox({
                ...this.city,
                value: city,
                validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
                errorMessages: {
                    required: "City is required.",
                    minlength: "City name can not be less than 3 characters.",
                    maxlength: "City name can not be more than 50 characters.",
                },
            } as any);

            const state = this.formGroupAddressSearch.get(this.stateList.key).value;
            this.stateList = new Dropdown({
                ...this.stateList,
                value: state,
                validators: [Validators.required],
                errorMessages: {
                    required: "State is required.",
                },
            } as any);

            const zip = this.formGroupAddressSearch.get(this.zip.key).value;
            this.zip = new Textbox({
                ...this.zip,
                value: zip,
                validators: [Validators.required, Validators.minLength(5), Validators.maxLength(10)],
                errorMessages: {
                    required: "Zip Code is required.",
                    minlength: "Zip Code can not be less than 5 characters.",
                    maxlength: "Zip Code can not be more than 10 characters.",
                },
            } as any);

            const contact = this.formGroupAddressSearch.get(this.contact.key).value;
            this.contact = new Textbox({
                ...this.contact,
                value: contact,
            } as any);

        }

        this.createForm();
        this.changeDetector.markForCheck();
    }

    get isGotoAddressDetailDisabled(): boolean {
        return !(this.addressGridselection);
    }
    addressAction(): void {
        if (this.findAddress && this.formGroupAddressSearch.valid) {
            this.addressGridselection = null;
            this.addressSearchModel = new AddressSearchRequest({
                retrievalLocationId: this.formGroupAddressSearch.get("retrievalLocationId").value,
                address1: this.formGroupAddressSearch.get("address1").value,
                address2: this.formGroupAddressSearch.get("address2").value,
                addressCity: this.formGroupAddressSearch.get("city").value,
                addressState: this.formGroupAddressSearch.get("state").value,
                addressZip: this.formGroupAddressSearch.get("postalCode").value,
                groupName: this.formGroupAddressSearch.get("group").value,
                contactData: this.formGroupAddressSearch.get("contactData").value,
                memberFirstName: this.formGroupAddressSearch.get("memberFirstName").value,
                memberLastName: this.formGroupAddressSearch.get("memberLastName").value,
                memberDateOfBirth: this.formGroupAddressSearch.get("memberDateOfBirth").value,
            });

            this.addressSearchService
                .addressSearch(this.addressSearchModel)
                .subscribe(items => {
                    this.addressSearchData = items as AddressSearchResult[];
                    if (!ArrayHelper.isAvailable(this.addressSearchData) && (this.callingSource === "Move Chase" || this.callingSource === "Move Provider Chases")) {
                        this.noAddressSearchResultsFound = true;
                        this.toggleAddressActionText = "Click here to create a new address";
                        this.addressActionLinkHidden = false;
                    } else if (!ArrayHelper.isAvailable(this.addressSearchData) && this.callingSource === "Incoming Call") {
                        this.noAddressSearchResultsFound = true;
                        this.addressActionLinkHidden = true;
                    }
                    this.changeDetector.markForCheck();
                });

        } else if (!this.findAddress) {
            this.createNewAddress();
        }
    }

    get notesVisible(): boolean {
        return (ArrayHelper.isAvailable(this.addressSearchData));
    }

    get isMoveChaseVisible(): boolean {
        const functionalRoleIds = this.authService.user.functionalRoleIds;
        const allowedFunctionalRoleIds = [FunctionalRole.SYSTEMADMINISTRATOR,
                                          FunctionalRole.PROJECTADMINISTRATOR,
                                          FunctionalRole.DOCUMENTRETRIEVALLEAD,
                                          FunctionalRole.DOCUMENTINTAKELEAD,
                                          FunctionalRole.DOCUMENTQALEAD,
                                          FunctionalRole.DATAMANAGER,
                                          FunctionalRole.DATAREADER];
        return allowedFunctionalRoleIds.some(r => functionalRoleIds.indexOf(r) >= 0);
    }

    createNewAddress(): void {
        if (this.formGroupAddressSearch.valid) {
            const createNewAddressModel = new CreateAddress({
                address1: this.formGroupAddressSearch.get("address1").value,
                address2: this.formGroupAddressSearch.get("address2").value,
                addressCity: this.formGroupAddressSearch.get("city").value,
                addressState: this.formGroupAddressSearch.get("state").value,
                addressZip: this.formGroupAddressSearch.get("postalCode").value,
                contactData: this.formGroupAddressSearch.get("contactData").value,
            });

            if (this.callingSource === "Move Provider Chases" || this.callingSource === "Move Chase") {
                this.isNewAddress.emit(createNewAddressModel);
                return;
            }

            this.addressSearchService.createAddress(createNewAddressModel).subscribe(
                newAID => {
                    this.messagingService.showToast("New Address Created.", SeverityType.SUCCESS);
                    this.addressGridselection = new AddressSearchResult(
                        {
                            masterDocumentSourceId: newAID,
                            documentSourceTypeName: "PSR",
                        });
                    this.confirmChaseMove();
                },
                err => {
                    this.messagingService.showToast("Error while creating new Address, please try again.", SeverityType.ERROR);
                }
            );
            this.changeDetector.markForCheck();

        } else if (!this.formGroupAddressSearch.valid) {
            this.markAllAsTouched();
        } else if (!StringHelper.isAvailable(this.notesText)) {
            this.notesMissing = true;
        }
    }

    confirmChaseMove() {
        if (StringHelper.isAvailable(this.notesText)) {
            if (this.findAddress && this.addressGridselection.masterDocumentSourceId === this.masterDocumentSourceId) {
                this.messagingService.showToast("Chase cannot be moved to same Address.", SeverityType.WARN);
                return;
            }
            this.addressGridselection.notes = this.notesText;
            this.addressGridselection.isChaseAssign = this.formGroupAddressSearch.get("inheritChase").value;
            this.onAddressSelection.emit(this.addressGridselection);
        } else {
            this.notesMissing = true;
        }
    }

    open(): void {
        this.visible = true;
        this.visibleChange.emit(true);
        this.onShow.emit(null);
    }

    close(): void {
        this.visible = false;
        this.visibleChange.emit(false);
        this.onHide.emit(null);
        this.addressSearchReset();
        this.addressSearchService.populateAddressSearch(null);
    }

    addressSearchReset(): void {
        this.addressGridselection = null;
        this.addressSearchData = [];
        this.selectedState = null;
        this.noAddressSearchResultsFound = false;
        this.notesMissing = false;
        this.notesText = null;

        if (this.callingSource === "Move Chase" || this.callingSource === "Move Provider Chases") {
            this.addressActionLinkHidden = true;
            this.resetAddressSearchForm();
            this.createForm();
            this.findAddress = true;
        }

        this.formGroupAddressSearch.reset();
    }

    private resetAddressSearchForm(): void {
        this.retrievalLocationId = new Textbox({ ...this.retrievalLocationId, disabled: false } as any);

        this.groupName = new Textbox({ ...this.groupName, disabled: false } as any);

        this.memberFirstName = new Textbox({ ...this.memberFirstName, disabled: false } as any);

        this.memberLastName = new Textbox({ ...this.memberLastName, disabled: false } as any);

        this.memberDateOfBirth = new Textbox({ ...this.memberDateOfBirth, disabled: false } as any);

        this.address1 = new Textbox({
            ...this.address1,
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "Address1 name can not be more than 50 characters.",
            },
        } as any);

        this.city = new Textbox({
            ...this.city,
            validators: [Validators.maxLength(50)],
            errorMessages: {
                maxlength: "City name can not be more than 50 characters.",
            },
        } as any);

        this.stateList = new Dropdown({
            ...this.stateList,
            validators: null,
        } as any);

        this.zip = new Textbox({
            ...this.zip,
            validators: [Validators.minLength(5), Validators.maxLength(10)],
            errorMessages: {
                minlength: "Zip Code can not be less than 5 characters.",
                maxlength: "Zip Code can not be more than 10 characters.",
            },
        } as any);
    }

    private markAllAsTouched(): void {
        Object.keys(this.formGroupAddressSearch.controls).forEach(key => {
            const control = this.formGroupAddressSearch.get(key);
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        });
        this.formService.updateDom.next();
    }

    onShowProviderSearch(): void {
        this.providerToolVisible = true;
    }

    onNotesTextChange(): void {
        this.notesMissing = !StringHelper.isAvailable(this.notesText);
    }
}
