import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { TagSearchAutoCompleteComponent } from "./tag-search-autocomplete.component";

export interface ITagSearchAutoCompleteOptions extends IDynamicInputOptions {
  placeholder?: string;
  searchMinimum?: number;
  options?: any[];
  serverFilter?(query: string, setOptionsFn: (options: SelectableInput[]) => void): void | null;
  filter?(item: SelectableInput, query: string): boolean;
}

export class TagSearchAutoComplete extends DynamicInput implements ITagSearchAutoCompleteOptions {
  placeholder: string;
  searchMinimum: number;
  options: any[];
  filter: (item: SelectableInput, query: string) => boolean;
  serverFilter: (query: string, setOptionsFn: (options: SelectableInput[]) => void) => void | null;

  readonly controlType = "autocomplete";
  readonly componentType = TagSearchAutoCompleteComponent;


  constructor(options: ITagSearchAutoCompleteOptions = {}) {
    super(options);
    this.placeholder = options.placeholder || "";
    this.searchMinimum = NumberHelper.isGreaterThan(options.searchMinimum, 0) ? options.searchMinimum : 1;
    this.options = options.options || [];
    this.filter = options.filter || null;
    this.serverFilter = options.serverFilter || null;

    if (!ArrayHelper.isAvailable(this.options)) {
      this.value = options.value;
    } else if (typeof this.value === "string" && StringHelper.isAvailable(this.value)) {
      this.value = this.options.find(item => item.value === this.value);
    }
  }


  get hasServerFilter(): boolean {
    return typeof this.serverFilter === "function";
  }
}
