import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { NoConfirm } from "./no-confirm.model";

@Component({
  selector: "form-no-confirm",
  templateUrl: "./no-confirm.component.html",
  styleUrls: ["../textbox/textbox.component.scss",
              "./no-confirm.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoConfirmComponent extends DynamicControlDirective<NoConfirm> implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): any {
    return this.getClasses("noConfirm");
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());

    this.formService.updateDom
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());

    if (this.model == null) {
      this.model = {} as any;
    }

    if (this.model.saveInfo == null) {
      this.model.saveInfo = {} as any;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  validationChange(): void {
    if (StringHelper.isAvailable(this.model.adminValue) && this.model.saveInfo.validationId === 1) {
      this.control.setValue(this.model.adminValue);
    }
  }

  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }

}

