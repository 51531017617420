import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { MenuItem } from "../../../../../../shared/menu/menu-item.model";
import { RiskDxDeleteDiagnosis } from "./dx-models/request/risk-dx-delete.modal";
import { RiskDxValidation } from "./dx-models/request/risk-dx-validation.model";
import { RiskDxIcd } from "./dx-models/response/risk-dx-icd.model";
import { RiskDx } from "./dx-models/response/risk-dx.model";

@Injectable({
  providedIn: "root",
})
export class RiskDxService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient
  ) {
  }

  private data = new BehaviorSubject<RiskDx>(new RiskDx());
  data$ = this.data.asObservable();

  private refreshDxReviewDataEntry = new Subject<void>();
  refreshDxReviewDataEntry$ = this.refreshDxReviewDataEntry.asObservable();

  private resetValidationButtonsSb = new Subject<RiskDxIcd>();

  private removeDiagnosis = new Subject<boolean>();
  removeDiagnosis$ = this.removeDiagnosis.asObservable();


  resetValidationButtons$ = this.resetValidationButtonsSb.asObservable();


  private addNewCard$ = new BehaviorSubject<boolean>(false);
  addNewHccCard$ = this.addNewCard$.asObservable();

  removecurrentDiagnosis(val: boolean): void {
    this.removeDiagnosis.next(val);
  }

  resetValidationButtons(icdGroup: RiskDxIcd): void {
    this.resetValidationButtonsSb.next(icdGroup);
  }


  refreshDxReview(): void {
    this.refreshDxReviewDataEntry.next();
  }

  menuItems(): MenuItem[] {
    return [
      new MenuItem({description: "Member", path: "./", extra: {showBadge: false, isDisabled: false, count: 0}}),
      new MenuItem({description: "Dx Review", path: "dx-review", extra: {showBadge: true, isDisabled: true, count: 0}}),
    ];
  }

  getData(chaseId: number, status: string): Observable<RiskDx> {
    const url = `${this.baseApiUrl}chase/risk/dxview/data?chaseId=${chaseId}&workflowStatus=${status}`;
    return this.http.get<RiskDx>(url);
  }

  setValid(parameters: RiskDxValidation): Observable<any> {
    const url = `${this.baseApiUrl}chase/risk/dxview/valid`;
    return this.http.post(url, parameters);
  }

  setInvalid(parameters: RiskDxValidation): Observable<any> {
    const url = `${this.baseApiUrl}chase/risk/dxview/invalid`;
    return this.http.post(url, parameters);
  }

  saveDiagnosis(parameters: RiskDxValidation): Observable<any> {
    const url = `${this.baseApiUrl}chase/risk/dxview/adddiagnosis`;
    return this.http.post(url, parameters);
  }

  deleteDiagnosis(parameters: RiskDxDeleteDiagnosis): Observable<any> {
    const url = `${this.baseApiUrl}chase/risk/dxview/deletediagnosis`;
    return this.http.post(url, parameters);
  }

  setData(data: Partial<RiskDx>): void {
    const newData = new RiskDx({ ...this.data.value, ...data });
    this.data.next(newData);
  }

  addCard(val: any) {
    this.addNewCard$.next(val);
  }

}
