import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PendTimelineItem } from "../../../platform/modules/internal-pends/pend-timeline-item.model";
import { MemberTimelineItem } from "../../../platform/modules/member/member-timeline-item.model";
import { RetrievalTimelineItem } from "../../../platform/modules/retrieval/retrieval-timeline-item.model";

@Component({
  selector: "app-timeline-detail-day",
  templateUrl: "./timeline-detail-day.component.html",
  styleUrls: ["./timeline-detail-day.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDetailDayComponent {
  @Input() day: RetrievalTimelineItem | MemberTimelineItem | PendTimelineItem;
  @Input() isChaseTimeline = false;
  trackByIndex(index, item) {
    return index;
  }
}
