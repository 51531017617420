import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-member-additional-details",
  templateUrl: "./member-additional-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberAdditionalDetailsComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.MEMBER_ADDITIONAL_DETAILS;

    constructor() {
        this.filters = ["Projects", "Hcc", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "MemberKey"];
    }

}
