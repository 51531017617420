import { IAutoMapper } from "../interfaces";

export const mapProjectFile = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProjectFile")
    .forMember("filename", o => o.fileName)
    .forMember("username", o => o.userName)
    .forMember("description", o => o.description)
    .forMember("url", o => o.url)
    .forMember("lastmodified", o => o.createDate)
    .forMember("size", o => o.size)
    .forMember("fileurl", o => o.fileUrl)
    .forMember("projectid", o => o.projectId)
    .forMember("projectdocumentid", o => o.projectDocumentId)
    .forMember("createuserfirstname", o => o.createUserFirstName)
    .forMember("projectName", o => o.projectName)
    .forMember("extractType", o => o.extractType)
    .forMember("chaseCount", o => o.chaseCount)
    .forMember("jobId", o => o.jobId)
    .forMember("status", o => o.status)
    .forMember("totalLineCount", o => o.totalLineCount)
    .forMember("projectType", o => o.projectType)
    .forMember("isDisabled", o => o.isDisabled);
};
