<div class="sub-menu__container">
    <div class="item">
        <a class="link">
            Annotations
        </a>
    </div>
</div>
<ng-container *ngIf="!isLoading">
    <div class="card__container" [ngClass]="!arrayHasItems(annotations) ? 'card__container-center' : ''">
        <ng-container *ngFor="let annotation of annotations; let i = index; trackBy: trackByIndex">
            <platform-widget-annotation-card (pageNumber)="onChangePage($event)" [isAdding]="isAdding" [(editing)]="isEditing" [index]="i"
                (save)="onEdit($event)" (delete)="onDelete($event)" [annotation]="annotation" [highlightId]="highlightId"
                (updateHighlightId)="updateHighlightId($event)" [highlightMatches]="highlightMatches" (entityId)="onCardClick($event)">
            </platform-widget-annotation-card>
        </ng-container>
        <ng-container *ngIf="!arrayHasItems(annotations)">
            <app-icon class="card__container--image" iconStyle="mrcs-image" iconType="mrcs-image"
                iconName="annotations-empty"></app-icon>
            <h5 class="card__container--text primary">Start your first Annotation!</h5>
            <h6 class="card__container--text secondary">Click and drag on the document to create a new annotation</h6>
        </ng-container>
    </div>
    <div class="card__new" *ngIf="isAdding">
        <platform-widget-annotation-card (save)="onSave($event)" (cancel)="onAction()" [isHeaderHidden]="true"
        [annotation]="newAnnotation" [isReadOnly]="false" [annotationRows]="5"></platform-widget-annotation-card>
    </div>
</ng-container>