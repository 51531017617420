// NOTE: These come from the database.
// NOTE: Order by attributeId
export const BMI_PERCENTILE = { attributeId: 19, attributeCode: "Percentile", dataType: "percent" };
export const CHART_PAGE_NUMBER = { attributeId: 28, attributeCode: "ChartPageNumber" };
export const FROM_DATE = { attributeId: 29, attributeCode: "StartDate", dataType: "date" };
export const THRU_DATE = { attributeId: 30, attributeCode: "EndDate", dataType: "date" };
export const BMI_DATE = { attributeId: 42, attributeCode: "BmiTestDate", dataType: "date" };
export const BMI_VALUE = { attributeId: 43, attributeCode: "BmiTestValue" };
export const BODY_HEIGHT_VALUE = { attributeId: 44, attributeCode: "BodyHeightTestValue" };
export const BODY_HEIGHT_DATE = { attributeId: 45, attributeCode: "BodyHeightTestDate", dataType: "date" };
export const ENROLLMENT_DATE = { attributeId: 61, attributeCode: "EnrollmentDate", dataType: "date" };
export const EXCLUSION_DATE = { attributeId: 62, attributeCode: "ExclusionDate" };
export const EXCLUSION_TYPE = { attributeId: 148, attributeCode: "ExclusionType" };
export const CONTRA_DATE = { attributeId: 149, attributeCode: "ContraDate" };
export const CONTRA_TYPE = { attributeId: 150, attributeCode: "ContraType" };
export const DOCUMENT_REQUIRMENT = { attributeId: 154, attributeCode: "DocumentRequirement" };
export const MEMBER_VALIDATION_RESULT = { attributeId: 160, attributeCode: "ValidationResult" };
export const MEMBER_VALIDATION_REASON = { attributeId: 161, attributeCode: "MemberValidationReason" };
export const MEASURE_YEAR = { attributeId: 169, attributeCode: "MeasureYear" };
export const PRODUCT = { attributeId: 203, attributeCode: "Product" };
export const CLAIM_ID = { attributeId: 219, attributeCode: "ClaimID" };
export const ENCOUNTER_TYPE = { attributeId: 220, attributeCode: "EncounterType" };
export const PROVIDER_ID = { attributeId: 223, attributeCode: "ProviderSourceAliasID" };
export const DIAGNOSIS_CODE = { attributeId: 224, attributeCode: "DiagnosisCode" };
export const PLAN_ID = { attributeId: 227, attributeCode: "PlanID" };
export const VALIDATION_REASON_CODES = { attributeId: 233, attributeCode: "ValidationReasonCode" };
export const ENCOUNTER_FOUND = { attributeId: 240, attributeCode: "EncounterFound" };
export const ENCOUNTER_FACE_2_FACE = { attributeId: 241, attributeCode: "EncounterIsFaceToFace" };
export const ACCEPTABLE_SIGNATURE = { attributeId: 242, attributeCode: "AcceptableSignature" };
export const REVIEW_PERIOD_FROM_DATE = { attributeId: 244, attributeCode: "ReviewPeriodFromDate", dataType: "date" };
export const REVIEW_PERIOD_THRU_DATE = { attributeId: 245, attributeCode: "ReviewPeriodThruDate", dataType: "date" };
export const CODING_LEVEL = { attributeId: 246, attributeCode: "CodingLevel" };
export const PROVIDER_NAME = { attributeId: 256, attributeCode: "ProviderName" };
export const SIG_FIRST_NAME = { attributeId: 257, attributeCode: "AcceptableSignatureFirstName" };
export const SIG_LAST_NAME = { attributeId: 258, attributeCode: "AcceptableSignatureLastName" };
export const SIG_CREDENTIALS = { attributeId: 259, attributeCode: "AcceptableSignatureCredentials" };
export const SIG_DATE = { attributeId: 260, attributeCode: "AcceptableSignatureDate", dataType: "date" };
export const DATE_OF_BIRTH_FOUND = { attributeId: 263, attributeCode: "DateOfBirthFound" };
export const GENDER_FOUND = { attributeId: 264, attributeCode: "GenderFound" };
export const MEMBER_CENTRIC_CHASE_RETRIEVAL = { attributeId: 265, attributeCode: "MemberCentricChaseRetrieval" };
export const INTERNAL_CLAIM_ID = { attributeId: 268, attributeCode: "InternalClaimID" };
export const PROCESSED_DATE = { attributeId: 269, attributeCode: "ProcessedDate" };
export const FILL_DATE = { attributeId: 270, attributeCode: "FillDate", dataType: "date" };
export const NATIONAL_DRUG_CODE = { attributeId: 271, attributeCode: "NationalDrugCode" };
export const SERVICE_CODE = { attributeId: 272, attributeCode: "ServiceCode" };
export const SUBSCRIBER_TYPE = { attributeId: 273, attributeCode: "SubscriberIndicator" };
export const BODY_HEIGHT_UNIT = { attributeId: 275, attributeCode: "HeightUnit" };
export const MEMBER_ID = { attributeId: 278, attributeCode: "MemberID" };
export const MEMBER_FIRST_NAME = { attributeId: 279, attributeCode: "FirstName" };
export const MEMBER_LAST_NAME = { attributeId: 280, attributeCode: "LastName" };
export const MEMBER_DATE_OF_BIRTH = { attributeId: 281, attributeCode: "DateOfBirth", dataType: "date" };
export const MEMBER_GENDER = { attributeId: 282, attributeCode: "Gender" };
export const PREMIUM_AMOUNT = { attributeId: 283, attributeCode: "PremiumAmount", dataType: "money" };
export const APTC = { attributeId: 284, attributeCode: "AdvancePremiumTaxCredit", dataType: "money" };
export const RATING_AREA = { attributeId: 285, attributeCode: "RatingArea" };
export const DATE_OF_BIRTH_CONFIRM = { attributeId: 286, attributeCode: "DateOfBirthConfirm" };
export const GENDER_CONFIRM = { attributeId: 287, attributeCode: "GenderConfirm" };
export const EDGE_PROVIDER_SOURCE_ALIAS_ID = { attributeId: 288, attributeCode: "EdgeProviderSourceAliasID" };
export const EXEMPT_FROM_SCORING = { attributeId: 297, attributeCode: "ExemptFromScoring" };
export const REVIEW_ALL_PAGES_OF_CHART = { attributeId: 304, attributeCode: "ReviewAllPagesOfChart" };
export const CHASE_SUBMISSION_ITEM = { attributeId: 305, attributeCode: "ChaseSubmissionItem" };
export const GROUP_NAME = { attributeId: 308, attributeCode: "GroupName" };
export const ADDRESS_1 = { attributeId: 309, attributeCode: "Address1" };
export const ADDRESS_2 = { attributeId: 310, attributeCode: "Address2" };
export const CITY = { attributeId: 311, attributeCode: "City" };
export const STATE = { attributeId: 312, attributeCode: "State" };
export const POSTAL_CODE = { attributeId: 313, attributeCode: "PostalCode" };
export const CONTACT_NAME = { attributeId: 314, attributeCode: "ContactName" };
export const PHONE = { attributeId: 315, attributeCode: "Phone", dataType: "phone" };
export const FAX = { attributeId: 316, attributeCode: "Fax", dataType: "phone" };
export const EMAIL = { attributeId: 317, attributeCode: "Email" };
export const PROJECT_CHASE_QUERY_GRID = { attributeId: 332, attributeCode: "ProjectChaseQueryGrid" };
export const SUBMISSION_ITEM_ANSWER = { attributeId: 351, attributeCode: "SubmissionItemAnswer" };
export const PRODUCT_LIST = { attributeId: 362, attributeCode: "ProductList" };
export const GAP_ENABLED = { attributeId: 384, attributeCode: "GapProviderOutreachEnabled" };
export const DATE_OF_BIRTH_VALIDATION_RESULT = { attributeId: 389, attributeCode: "DateOfBirthValidationResult" };
export const PROJECT_GAP_QUERY_GRID = { attributeId: 390, attributeCode: "ProjectGapQueryGrid" };
export const SERVICE_PROVIDER_REQUIRED = { attributeId: 391, attributeCode: "ServiceProviderRequired" };
export const ENCOUNTER_TYPE_REQUIRED = { attributeId: 392, attributeCode: "EncounterTypeRequired" };
export const VALIDATION_REASON_CODE_REQUIRED = { attributeId: 393, attributeCode: "ValidationReasonCodeRequired" };
export const ENABLE_RISK_NLP = { attributeId: 399, attributeCode: "EnableRiskNLP" };
export const ENABLEMRRBOT = { attributeId: 400, attributeCode: "EnableMrrBot" };
export const NLP_REVIEW_RESULT = { attributeId: 417, attributeCode: "NlpReviewResult" };
export const DASHBOARD_MY_CHASES_GRID = { attributeId: 419, attributeCode: "DashboardMyChasesGrid" };
export const RETRIEVAL_PENDS_GRID = { attributeId: 420, attributeCode: "RetrievalPendsGrid" };
export const RETRIEVAL_DOCUMENT_QA_GRID = { attributeId: 421, attributeCode: "RetrievalDocumentQaGrid" };
export const RETRIEVAL_ASSIGNMENT_GRID = { attributeId: 422, attributeCode: "RetrievalAssignmentGrid" };
export const RETRIEVAL_PSR_GRID = { attributeId: 423, attributeCode: "RetrievalPsrGrid" };
export const RETRIEVAL_FT_GRID = { attributeId: 424, attributeCode: "RetrievalFtGrid" };
export const RETRIEVAL_EMR_GRID = { attributeId: 425, attributeCode: "RetrievalEmrGrid" };
export const RETRIEVAL_TP_GRID = { attributeId: 426, attributeCode: "RetrievalTpGrid" };
export const CLINICAL_MRR_GRID = { attributeId: 427, attributeCode: "ClinicalMrrGrid" };
export const CLINICAL_OR_GRID = { attributeId: 428, attributeCode: "ClinicalOrGrid" };
export const CLINICAL_OR2_GRID = { attributeId: 429, attributeCode: "ClinicalOr2Grid" };
export const CLINICAL_PENDS_GRID = { attributeId: 430, attributeCode: "ClinicalPendsGrid" };
export const DASHBOARD_MY_ADDRESS_GRID = { attributeId: 431, attributeCode: "DashboardMyAddressGrid" };
export const DASHBOARD_MY_PEND_GRID = { attributeId: 438, attributeCode: "DashboardMyPendGrid" };
export const ENABLE_PROVIDER_AGING_EMAIL = { attributeId: 442, attributeCode: "ProviderAgingStatement" };
export const MEMBER_MEMBER_QUERY_GRID = { attributeId: 443, attributeCode: "MemberMemberQueryGrid" };
export const PROJECT_MEMBER_QUERY_GRID = { attributeId: 444, attributeCode: "ProjectMemberQueryGrid" };
export const DOCUMENT_ROUTING = { attributeId: 445, attributeCode: "RoutingBotRules" };
export const ENR_MAPPING_DOCUMENT = { attributeId: 449, attributeCode: "ENRMappingDocument" };
export const ENR_CROSSWALK = { attributeId: 450, attributeCode: "ENRCrosswalk" };
export const RXC_MAPPING_DOCUMENT = { attributeId: 451, attributeCode: "RXCMappingDocument" };
export const RXC_CROSSWALK = { attributeId: 452, attributeCode: "RXCCrosswalk" };
export const RETRIEVAL_SH_GRID = { attributeId: 453, attributeCode: "RetrievalShGrid" };
export const DOCUMENT_INTAKE_SEARCH_GRID = { attributeId: 467, attributeCode: "DocumentIntakeSearchGrid" };
export const DATE_OF_SERVICE_LIST = { attributeId: 469, attributeCode: "DateOfServiceList" };
export const APPROVE_PURSUITS_GRID = { attributeId: 470, attributeCode: "ApprovePursuitsGrid" };
export const INVOICE_VENDORS_GRID = { attributeId: 472, attributeCode: "InvoiceVendorsGrid" };
export const ADDRESS_CHASE_GRID = { attributeId: 474, attributeCode: "AddressChaseGrid" };
export const OMISSION_CODE = { attributeId: 487, attributeCode: "OmissionCode" };
export const FIRSTPASS_BOT_SETTING = { attributeId: 492, attributeCode: "FirstPassBotSetting" };
export const CO_RETRIEVAL_ENABLED = { attributeId: 494, attributeCode: "CoRetrievalEnabled" };
export const DATA_ENTRY_PROVIDER_CONFIRMATION_ENABLED = { attributeId: 495, attributeCode: "DataEntryProviderConfirmationEnabled" };
export const OMISSION_CODE_ENABLED = { attributeId: 496, attributeCode: "OmissionCodeEnabled" };
export const ENTITY_SOURCE_CATEGORY_NAME = { attributeId: 497, attributeCode: "EntitySourceCategoryName" };
export const COMPLIANT_NUMERATOR_LIST_ADMIN = { attributeId: 501, attributeCode: "CompliantNumeratorListAdmin" };
export const VENDOR_CHASE_GRID = { attributeId: 502, attributeCode: "VendorChaseGrid" };
export const PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES = { attributeId: 504, attributeCode: "SavedQueriesChaseQueryGrid" };
export const NEPHROPATHY_MEDICARE_FLAG = { attributeId: 508, attributeCode: "NephropathyMedicareFlag" };
export const CHART_LAKE_REVIEWED = { attributeId: 509, attributeCode: "ChartLakeReviewed" };
export const ENABLE_HEDIS_NLP = { attributeId: 513, attributeCode: "EnableHedisNLP" };
export const ENABLE_RISK_20 = { attributeId: 514, attributeCode: "EnableRisk20" };
export const CHART_LAKE_MATCHED_GRID = { attributeId: 536, attributeCode: "ChartLakeMatchesGrid" };
export const ENABLED_SSO = { attributeId: 539, attributeCode: "EnableSSO" };
export const SSO_CONFIGURATION = { attributeId: 540, attributeCode: "SSOConfiguration" };
export const NLP_GROUP_ID = { attributeId: 542, attributeCode: "NlpGroupID" };
export const DEMOGRAPHIC_REQUIRED = { attributeId: 543, attributeCode: "DemographicRequired" };
export const INVOICE_CHASE_GRID = { attributeId: 545, attributeCode: "InvoiceChaseGrid" };
export const REVIEW_ALL_ADMIN_CLAIMS_REQUIRED = { attributeId: 549, attributeCode: "ReviewAllAdminClaimsRequired" };
export const CONTRACT_NUMBER = { attributeId: 562, attributeCode: "ContractNumber" };
export const CHASE_UPLOAD_GRID = { attributeId: 573, attributeCode: "AddressChaseUploadGrid" };
export const CODING_REVIEW_MODE = { attributeId: 584, attributeCode: "CodingReviewMode" };
export const AUTOMATED_OUTREACH_ENABLED = { attributeId: 585, attributeCode: "AutomatedOutreachEnabled" };
export const AUTOMATED_OUTREACH_DAYS_BEFORE_OVERDUE = { attributeId: 586, attributeCode: "AutomatedOutreachDaysBeforeOverdue" };
export const CODING_OPTIMIZATION_MODE = { attributeId: 589, attributeCode: "CodingOptimizationMode"};
export const HCC_CARD_ADDITTION_ENABLED = { attributeId: 601, attributeCode: "HccCardAdditionEnabled"};
export const ENABLE_REACT_APP = { attributeId: 603, attributeCode: "EnableReactApp" };
export const PLAN_LIST = { attributeId: 605, attributeCode: "PlanList" };
