<div class="container-content">
  <div class="ui-g ui-fluid text-center">
    <img [src]="imagePath" class="logo">
  </div>
  <div class="ui-g ui-fluid onboarding-heading">
    <div class="ui-g-12 ui-md-12 ui-lg-12 text-center" [innerHTML]="welcomeText"></div>
  </div>
  <div class="ui-g ui-fluid">
    <p class="text__support"><span>{{supportText}}</span></p>
  </div>
</div>
