import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { MenuService } from "../../menu/menu.service";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @Input() key = "";
  isOpen: boolean;


  constructor(
    private readonly menuService: MenuService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly primeMessageService: MessageService
  ) { }

  ngOnInit() {
    this.menuService.isOpen$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isOpen => {
        this.isOpen = isOpen;
        this.changeDetector.markForCheck();
      });

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => this.primeMessageService.clear());

    this.primeMessageService.messageObserver
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.changeDetector.markForCheck());

    this.primeMessageService.clearObserver
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.changeDetector.markForCheck());
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  get menuOpen(): any {
    return this.isOpen ? "menu-open" : "";
  }
}
