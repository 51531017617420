import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChaseListComponent } from "./chase-list/chase-list.component";
import { ContactLogListComponent } from "./contact-log-list/contact-log-list.component";
import { MasterDocumentSourceListComponent } from "./master-document-source-list/master-document-source-list.component";
import { PendListComponent } from "./pend-list/pend-list.component";

const routes: Routes = [
  { path: "documentsource", component: MasterDocumentSourceListComponent },
  { path: "chase", component: ChaseListComponent },
  { path: "contactlog", component: ContactLogListComponent },
  { path: "pend", component: PendListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportingRoutingModule { }
