
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { combineLatest, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { ChipAutocomplete } from "../../../../../dynamic-forms/inputs/chip-autocomplete/chip-autocomplete.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchAutoComplete } from "../../../../../dynamic-forms/inputs/tag-search-autocomplete/tag-search.model";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";

@Component({
  selector: "approval-center-upload-bulk-manage-tag",
  templateUrl: "./upload-bulk-manage-tag.component.html",
  styleUrls: ["./upload-bulk-manage-tag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadBulkManageTagComponent implements OnInit, OnChanges, OnDestroy {
  tagFormGroup: FormGroup;
  addTagInput: ChipAutocomplete;
  tagInput: TagSearchAutoComplete;
  replaceTagInput: TagSearchAutoComplete;
  removeTagInput: TagSearchAutoComplete;
  isShowSearchIcon = true;
  @Input() tagType: TagType;
  @Input() tagActionType = "";
  addTag = false;
  selectedTags: any;
  tagName = "";
  pTagText = "";
  tagId: number;
  searchTextChanged = new Subject<string>();
  sink = new SubSink();
  searchInputName: string;
  @Input() isCreateTagTemplateVisible: boolean;
  isVisible = false;
  @Output() tagDataModel: EventEmitter<any> = new EventEmitter();
  constructor(
              private readonly formService: FormService,
              private readonly tagService: TagService,
              private readonly changeDetector: ChangeDetectorRef,
              private messagingService: MessagingService,
              private readonly el: ElementRef
              ) { }

  ngOnInit(): void {
    this.initializeTagForm();
    this.sink.add(
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getTagList(searchText)))
          .subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.tagActionType) {
      this.getTagAction();
    }
  }

  get hasCurrentTag(): boolean {
    return this.addTag ;
  }

  get hasAddTag(): boolean {
    return this.addTag;
  }

  get isTagUnAvailable(): boolean {
    return !ArrayHelper.isAvailable(this.selectedTags) && !this.isVisible;
  }

  get isTagAvailable(): boolean {
    return ArrayHelper.isAvailable(this.selectedTags);
  }

  get lowerBoundClass(): string {
     return (this.el.nativeElement.getBoundingClientRect().right > (window.innerWidth - 420)) ?  "flip-dropdown" : "";
  }

  @HostListener("document:click", ["$event", "$event.target"])

  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = targetElement.id === "addTags" || targetElement.className === "far fa-plus-circle fa-far fa-plus-circle"
    || targetElement.className === "color-picker__tag-info--header";
    this.isVisible = !clickedInside ? false : true;
    if (this.selectedTags) {
      if (targetElement.className === "ui-chips-token-label") {
          this.selectedTags = this.selectedTags.map(x => {
            x.extra.isSelected = true;
            return x;
          });
      } else {
          this.selectedTags = this.selectedTags.map(x => {
            x.extra.isSelected = false;
            return x;
          });
      }
    }
  }

  private getTagList(searchText: string): void {
    this.tagService.getAllTagsList(this.tagType, null, searchText).subscribe(result => {
      result.sort((a, b) => a.text.localeCompare(b.text));
      if (this.searchInputName === this.addTagInput.key) {

        this.addTagInput.options = [...result];
      } else {

        this.tagInput.options = [...result];
      }
      this.changeDetector.markForCheck();
      });
  }

  initializeSelectedTag(): void {
    this.selectedTags = [];
    this.initializeTagForm();
  }
  private initializeTagForm(): void {
    this.addTagInput =
      new ChipAutocomplete({
        key: "addTags",
        placeholder: "Type Here",
        label: "addTags",
        validators: [
              Validators.maxLength(50),
              Validators.pattern(RegExHelper.tagPattern)],
        errorMessages: {
              maxlength: "Tag text cannot be more than 50 characters.",
              pattern: "Tags Cannot Contain Special Characters.",
            },
        useFilter: false,
          });
    this.tagInput =
      new TagSearchAutoComplete({
        key: "tags",
        placeholder: "Type Here",
      });
    this.tagFormGroup = this.formService.createFormGroup([this.addTagInput, this.tagInput]);
    this.changeDetector.markForCheck();
  }


  private getTagAction(): void {
   if (this.tagFormGroup) {
    this.selectedTags = [];
    this.isVisible = false;
    this.pTagText = "";
    this.tagFormGroup.reset();
    }
  }

  onTagEnter(): void {
    if (StringHelper.isAvailable(this.pTagText)) {
      if (this.tagFormGroup.invalid) {
        this.messagingService.showToast("Tags Cannot Contain Special Characters or cannot be more than 50 characters.", SeverityType.ERROR);
        return;
      }
      this.tagName = this.pTagText;
      const defaultColor = "#DCDCDC";
      if (StringHelper.isAvailable(this.pTagText)) {
        const newTag = new SelectableInput({
          text: this.tagName.toUpperCase(),
          value: 0,
          extra: {color: defaultColor},
        });
        this.addTagToExistingTagList(newTag);
      }
    }
  }

  onAddTagInputKeyUp(event): void {
    this.searchInputName = this.addTagInput.key;
    this.searchTextChanged.next(event);
  }

  onSelectedColor(selectedTagColor: string): void {

    if (StringHelper.isAvailable(selectedTagColor) && StringHelper.isAvailable(this.pTagText)) {
      if (this.tagFormGroup.invalid) {
        this.messagingService.showToast("Tags Cannot Contain Special Characters or cannot be more than 50 characters.", SeverityType.ERROR);
        return;
      }
      this.tagName = this.pTagText.toUpperCase();
      const newTag = new SelectableInput({
        text: this.tagName,
        value: 0,
        extra: {color: selectedTagColor},
      });
      this.addTagToExistingTagList(newTag);
    } else {
      this.isCreateTagTemplateVisible = false;
    }
  }

  onAddTags(event: any): void {

    this.isCreateTagTemplateVisible = true;
    if (event.text) {
      this.pTagText = event.text;
      this.addTagToExistingTagList(event);
    } else {
      this.pTagText = event.target.value;
      if (!this.tagFormGroup.valid) {
        this.formService.markAllAsTouched(this.tagFormGroup);
        this.changeDetector.markForCheck();
      }
    }
  }

  addTagToExistingTagList(item: SelectableInput): void {
    this.selectedTags = this.selectedTags || [];
    if (this.selectedTags.length >= 8) {
      this.messagingService.showToast("Max number of tags reached.", SeverityType.ERROR);
    } else {
        if (item) {
            const isTagAlreadyAvailable = this.selectedTags.filter(x => x.text === item.text);
            if (isTagAlreadyAvailable.length === 0) {
                this.selectedTags.push(item);
                this.tagDataModel.emit({
                  addedTagList: this.selectedTags,
                });
                this.selectedTags.map(x => x.extra.isSelected = false);
            } else {
              this.messagingService.showToast("Tag already Selected.", SeverityType.ERROR);
            }
        }
    }
    this.isCreateTagTemplateVisible = false;
    this.tagFormGroup.reset();
  }

  getCharactersCount(selectedTags: string): string {
    return selectedTags.length > 12 ? selectedTags.toUpperCase() : "";
  }

  onItemClick(): void {
    this.selectedTags = this.selectedTags.map(x => {
      x.extra.isSelected = true;
      return x;
    });
  }

  getTagIcon(iconName: string): string {
    return iconName === "add" ? "far fa-plus-circle" : "ui-chips-token-icon pi pi-fw pi-times";
  }

  getClassForPlusIcon(): string {
    const styleClass = "tag-icon";
    return this.isVisible ? "tag-unclickable" : styleClass;
  }

  getClassForNewTagIcon(): string {
    const iconClass = "tag-list__add-icon";
    return iconClass;
  }

  getClassForSelect(): string {
    let iconStyle = "";
    if (this.selectedTags && this.isVisible) {
       iconStyle = "tag-select";
    } else if (!this.selectedTags && this.isVisible) {
      iconStyle = "tag-select-item";
    }
    return iconStyle;
  }

  addTags(selectedTags: boolean): void {
    this.isVisible = selectedTags;
    this.pTagText = "";
    this.tagFormGroup.get(this.addTagInput.key).setValue("");
  }

  removeItem(index: number): void {
    this.selectedTags = this.selectedTags.filter((val, i) => i !== index);
    this.tagDataModel.emit({
      addedTagList: this.selectedTags,
    });
  }
  private setValidators(controls: DynamicInput[]): void {
    controls.forEach(control => {
      this.tagFormGroup.get(control.key).setValidators(control.validators);
    });
  }

  private clearValidators(path: string[]): void {
    path.forEach(control => {
      this.tagFormGroup.get(control).clearValidators();
      this.tagFormGroup.get(control).updateValueAndValidity();
    });
  }
  trackByIndex(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }
}
