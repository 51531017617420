import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ChartService } from "../chart.service";

@Component({
  selector: "member-additional-member-detail",
  templateUrl: "./additional-member-detail.component.html",
  styleUrls: ["./additional-member-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalMemberDetailComponent implements OnInit, OnChanges {
  @Input() set enabled(value: boolean) {
    this.setDisabled(value);
  }
  @Input() chaseId: number;
  @Output() onChange = new EventEmitter<DynamicFormEvent>(true);
  form: FormGroup;
  saveGroup: SaveGroup;
  additionalMemberDetailInput: Dropdown;

  constructor(
    private readonly formService: FormService,
    private readonly chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getAdditionalMemberDetail();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chaseId) {
      if (changes.chaseId.isFirstChange()) { return; }
      if (changes.chaseId.currentValue !== changes.chaseId.previousValue) {
        this.getAdditionalMemberDetail();
        this.changeDetector.markForCheck();
      }
    }
  }

  handleChange(event): void {
    if (this.form.valid) {
      this.onChange.emit(event);
    }
  }

  private initializeForm(): void {
    this.additionalMemberDetailInput = new Dropdown({
      key: "AdditionalMemberDetail",
      placeholder: "Select...",
      label: "Additional Chase Details (Optional)",
    });
    this.saveGroup = new SaveGroup({
      key: "AdditionalMemberDetailSaveGroup",
      isHideClearButton: true,
      controls: [this.additionalMemberDetailInput],
    });
    this.additionalMemberDetailInput.parent = this.saveGroup;
    this.form = this.formService.createFormGroup([this.saveGroup]);
  }

  private getAdditionalMemberDetail(): void {

    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.chartService.getAdditionalMemberDetail(this.chaseId).subscribe(additionalMemberDetail => {
        this.additionalMemberDetailInput.options = additionalMemberDetail.options;
        this.setSaveInfo(additionalMemberDetail);
        this.control.setValue(additionalMemberDetail.value);
        this.changeDetector.markForCheck();
      });
    }
  }

  private setSaveInfo(additionalMemberDetail: DynamicEntityAttribute): void {
    const options = JSON.stringify(additionalMemberDetail.options);
    const saveInfo = { ...additionalMemberDetail, options };
    (this.control as any).saveInfo = saveInfo;
    this.additionalMemberDetailInput.saveInfo = saveInfo;
  }

  private get hasForm(): boolean {
    return this.form != null;
  }

  private get control(): FormControl {
    if (!this.hasForm) {
      return null;
    }

    const control = this.form.get(this.additionalMemberDetailInput.getMasterKey()) as FormControl;
    return control;
  }

  private setDisabled(enable: boolean): void {
    // HACK: Wait for the form to exist before enabling/disabling.
    if (!this.hasForm) {
      setTimeout(() => this.setDisabled(enable), 100);
      return;
    }

    if (enable) {
      this.control.enable();
    } else {
      this.control.disable();
    }

    this.formService.updateDom.next();
  }

}
