import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-badge",
  template: `
    <div [ngClass]="classes" class="badge">{{ text }}</div>
  `,
  styleUrls: ["./badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() text = "NEW";
  @Input() isLarge = true;
  @Input() cssClasses = "";

  get classes(): string {
    return this.isLarge ? `badge--large ${this.cssClasses}` : `badge--small ${this.cssClasses}`;
  }
}
