import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-utc-time-zone",
  templateUrl: "./utc-time-zone.component.html",
  styleUrls: ["./utc-time-zone.component.scss"],
})
export class UtcTimeZoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
