import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { List } from "immutable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../core/storage/session.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ButtonAction } from "../../../../shared/button/button-action.model";
import { DocumentRequestService } from "../../../../shared/document/document-request.service";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ListItem } from "../../../../shared/list/list-item";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { DirectoryUserRole } from "../../retrieval/directory-user-role";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { DocumentReviewerStateService } from "./document-reviewer-state.service";
import { DocumentReviewerService } from "./document-reviewer.service";

@Component({
  selector: "app-document-reviewer",
  templateUrl: "./document-reviewer.component.html",
  styleUrls: ["./document-reviewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentReviewerComponent implements OnInit {

  headerStatistics = List<ListItem>();
  selectedRole: string;
  isGetNextDisabled = true;
  isRequestAdditionalWorkEnabled = true;
  readonly AUTHENTICATION_KEY = "authentication";
  roleIdCount = 0;
  roleId: any[] = [];
  gridConfiguration = new GridConfiguration();
  refreshGrid = new EventEmitter<boolean>(true);
  basicGridData: any[] = [];
  gridRequest: GridRequest;

  buttonRoleList: ButtonAction[] = [];
  documentFilter: string;
  selectedStat: string;

  get hasClientLeadRole(): boolean {
      return this.authService.user.directoryRoleIds.indexOf(DirectoryUserRole.ClientLead) > -1;
  }

  constructor(
    private documentReviewerService: DocumentReviewerService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly documentRequestService: DocumentRequestService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private messagingService: MessagingService,
    private readonly session: SessionService,
    private formService: FormService,
    private readonly authService: AuthService,
    private documentReviewerStateService: DocumentReviewerStateService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) {
    const columnHeader = [
      new GridColumnDefinition({ field: "documentName", header: "Document", routeUrl: "/retrieval/review/:url" }),
      new GridColumnDefinition({ field: "documentType", header: "Document Type" }),
      new GridColumnDefinition({ field: "documentDate", header: "Document Date", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "documentState", header: "Status" }),
    ];

    this.gridConfiguration = new GridConfiguration();
    this.gridConfiguration.columns = columnHeader;
    this.gridConfiguration.pageSize = 10;
    this.gridConfiguration.hasPagination = true;
    this.gridConfiguration.showActionColumn = false;

    this.rolelistSet();
  }

  get projectNameInput(): CheckboxGroup {
    return this.getInput("Project");
  }
  set projectNameInput(value: CheckboxGroup) {
    this.setInput("Project", value);
  }
  get statusInput(): CheckboxGroup {
    return this.getInput("Status");
  }
  set statusInput(value: CheckboxGroup) {
    this.setInput("Status", value);
  }
  get documentNameInput(): Textbox {
    return this.getInput("DocumentName");
  }
  set documentNameInput(value: Textbox) {
    this.setInput("DocumentName", value);
  }
  get documentDateInput(): Textbox {
    return this.getInput("DocumentDate");
  }
  set documentDateInput(value: Textbox) {
    this.setInput("DocumentDate", value);
  }

  getInput<T extends DynamicInput>(key: string): T {
    if (this.gridRequest == null) {
      return null;
    }

    return this.gridRequest.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.gridRequest == null) {
      return null;
    }

    this.gridRequest.setInput<T>(key, value);
  }

  rolelistSet(): void {

    const user = this.session.get<any>(this.AUTHENTICATION_KEY, {});
    this.roleId = user.functionalRoleIds.filter(item => item === 7 || item === 9);
    const uniqueRoleId = this.roleId.filter((x, i, a) => x && a.indexOf(x) === i);
    this.roleIdCount = uniqueRoleId.length;

    for (const id of uniqueRoleId) {
      switch (id) {
        case 7: {
          this.buttonRoleList.push(
            new ButtonAction({
              name: "Document Intake",
              action: () => {
                this.selectedRole = "7";
                this.getNextChase();
              },
            })
          );
          break;
        }
        case 9: {
          this.buttonRoleList.push(
            new ButtonAction({
              name: "Document QA",
              action: () => {
                this.selectedRole = "9";
                this.getNextChase();
              },
            })
          );
          break;
        }
        default: {

          break;
        }
      }
    }

    if (this.roleIdCount === 1) {
      this.selectedRole = uniqueRoleId.toString();
    }
  }

  ngOnInit() {
    this.getStatisticsData();
    this.getProjects();
    this.getDocumentStatus();
    this.loadGrid();
  }
  private loadGrid(): void {
    this.gridRequest = new GridRequest({
      url: `${this.baseApiUrl}dashboard/documentreviewer/chasesdocument`,
      filters:
        [
          new GridFilter({
            input: new Textbox(),
            key: "DocumentName",
            name: "Document Name",
            show: true,
          }),
          new GridFilter({
            input: new CheckboxGroup(),
            key: "Project",
            name: "Project",
          }),
          new GridFilter({
            input: new CheckboxGroup(),
            key: "Status",
            name: "Status",
          }),

          new GridFilter({
            input: new Textbox(),
            key: "DocumentDate",
            name: "Document Date",
          }),
          new GridFilter({
            input: new Textbox(),
            key: "DocumentFilter",
            value: this.documentFilter,
            show: false,
          }),
        ],
    });
    this.changeDetector.markForCheck();
    this.refreshGrid.emit();
  }

  getStatisticsData(): void {
    this.documentReviewerService
      .getStatisticsData()
      .subscribe(result => {
        this.headerStatistics = List(result);
        this.changeDetector.markForCheck();
      });
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.description,
          value: item.id,
        }));
      }))
      .subscribe(options => {
        this.projectNameInput = new CheckboxGroup({ ...this.projectNameInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getDocumentStatus(): void {
    const view = 0;
    this.documentRequestService
      .getDocumentStatuses(view)
      .subscribe(options => {
        this.statusInput = new CheckboxGroup({ ...this.statusInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  getNextChase(): void {
    this.isRequestAdditionalWorkEnabled = false;
    this.documentReviewerService.getNextChaseId(+this.selectedRole)
      .subscribe(
        {
          next: (result: any) => {
            if (NumberHelper.isGreaterThan(result.chaseId, 0)) {
              if (result.documentType === "MR Intake") {
                this.router.navigate(["retrieval", "review", "intake", result.chaseId]);
              } else {
                this.router.navigate(["retrieval", "review", "qa", result.chaseId]);
              }
            } else {
              this.messagingService.showToast("No document found.", SeverityType.INFO);
              this.changeDetector.markForCheck();
            }
          },
          error: () => {
            this.isRequestAdditionalWorkEnabled = true;
            this.changeDetector.markForCheck();
          },
        }
      );
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.documentReviewerStateService.setUrl(this.router.url);
    return true;
  }

  getClass(item: ListItem): string {
    const defaultStatFilter = StringHelper.isAvailable(this.selectedStat) ? this.selectedStat : "Documents";
    return item.key === defaultStatFilter ? "activeStat" : "clearStat";
  }

  filterGrid(filterName: string): void {
    switch (filterName) {
      case "Documents":
        this.documentFilter = "Documents";
        break;
      case "Total Completed":
        this.documentFilter = "CompletedDocument";
        break;
      case "Completed Today":
        this.documentFilter = "CompletedTodayDocument";
        break;
      default:
        this.documentFilter = "Documents";
        break;
    }

    this.selectedStat = filterName;
    const gridFilter = this.gridRequest.getFilter("DocumentFilter");
    gridFilter.value = this.documentFilter;
    this.gridRequest.startRecord = 0;
    this.refreshGrid.emit();
  }

  trackByIndex(index, item) {
    return index;
  }

  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }

}
