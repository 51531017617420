import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";
import { SubSink } from "subsink";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { Multiselect } from "./multiselect.model";

@Component({
  selector: "form-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent extends DynamicControlDirective<Multiselect> {

  get optionText(): string {
    return "text";
  }

  get options(): any[] {
    return this.model.options;
  }

  get hasOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get maxSelectedLabels(): number {
    return NumberHelper.isGreaterThan(this.model.maxSelectedLabels, 0) ? this.model.maxSelectedLabels : 3;
  }

  get classes(): string {
    return this.getClasses("multiselect");
  }

}
