<app-detail-main-template>
  <div container-header>
    <h3>{{headerTitle}}</h3>
  </div>
  <div container-body>
    <app-basic-grid [configuration]="configuration"
                    [data]="auditPackages"
                    [actionTemplate]="actionTemplate">
      <ng-template #actionTemplate
                   let-i="index"
                   let-rowData>
        <div class="action-template-container">
          <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                      [text]="action.name"
                      (onClick)="action.action(rowData)"></app-button>
        </div>
      </ng-template>
    </app-basic-grid>
  </div>
</app-detail-main-template>