import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../core/environment.tokens";
import { MemberCentricChase } from "./membercentric-chase.model";

@Injectable({
  providedIn: "root",
})
export class MemberCentricDocAttachmentService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  verfiyIfMemberCentricChase(chaseId: number, projectId: number): Observable<MemberCentricChase[]> {
    const url = `${this.baseApiUrl}membercentric/verify/docattachment?chaseId=${chaseId}&projectId=${projectId}`;

    return this.http.post(url, {}).pipe(
      map(this.automapper.curryMany("default", "MemberCentricChase"))) as Observable<MemberCentricChase[]>;
  }

  attachDocToMemberCentricChases(
    chaseIds: number[],
    documentId: number,
    documentTypeId: number,
    pageRangeStart: number,
    pageRangeEnd: number,
    pageTypeId: number,
    byPassMRQAStatus: boolean = false): Observable<HttpResponse<null>> {

    const url = `${this.baseApiUrl}document/membercentric/assignpagerange?documentId=${documentId}
                      &documentTypeId=${documentTypeId}
                      &pageRangeStart=${pageRangeStart}
                      &pageRangeEnd=${pageRangeEnd}
                      &pageTypeId=${pageTypeId}
                      &byPassMRQAStatus=${byPassMRQAStatus}`;

    return this.http.post(url, chaseIds) as Observable<HttpResponse<null>>;
  }

  copyMrqaChasePagesToMemberCentricChases(
    sourceChaseId: number,
    targetChaseIds: number[],
    documentTypeId: number,
    pageRangeStart: number,
    pageRangeEnd: number,
    pageTypeId: number): Observable<HttpResponse<null>> {

    const url = `${this.baseApiUrl}document/membercentric/copymrqapages?sourcechaseId=${sourceChaseId}
                      &documentTypeId=${documentTypeId}
                      &pageRangeStart=${pageRangeStart}
                      &pageRangeEnd=${pageRangeEnd}
                      &pageTypeId=${pageTypeId}`;

    return this.http.post(url, targetChaseIds) as Observable<HttpResponse<null>>;
  }
}
