export class LookupModel {
  readonly id: number;
  readonly name: string;
  readonly description?: string;
  readonly projectTypeId?: number;
  readonly projectStatus?: number;
  readonly projectTypeName?: string;
  readonly clientId?: number;
  readonly clientName?: string;
  readonly projectRetrivalTypeId?: number;

  constructor(options: {
    id: number;
    name: string;
    description?: string;
    projectTypeId?: number;
    projectStatus?: number;
    projectTypeName?: string;
    clientId?: number;
    clientName?: string;
    projectRetrivalTypeId?: number;
  }
  ) {
    this.id = options.id;
    this.name = options.name;
    this.description = options.description;
    this.projectTypeId = options.projectTypeId;
    this.projectStatus = options.projectStatus;
    this.projectTypeName = options.projectTypeName;
    this.clientId = options.clientId;
    this.clientName = options.clientName;
    this.projectRetrivalTypeId = options.projectRetrivalTypeId;
  }
}
