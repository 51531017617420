import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { GenericMenuComponent } from "../generic-menu-component.model";
import { MenuItem } from "../menu-item.model";
import { MenuService } from "../menu.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends GenericMenuComponent implements OnChanges  {
  @Input() items: MenuItem[] = [];

  constructor(private menuService: MenuService) { super(); }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      if (changes.items.isFirstChange()) { return; }

      if (changes.items.currentValue !== changes.items.previousValue) {
        this.refresh = true;
      }
    }
  }

  closeSubMenu(): void {
    this.menuService.toggleMenuState(false);
  }
}
