<app-collapsible-panel>
  <header>
    <app-button class="button" styleType="far" type="fa" [icon]="collapseIcon" classes="transparent small" (onClick)="toggle($event)"></app-button>
    <div class="filter-text">
      Filters Applied:
      <span *ngFor="let filter of groupedFilters; let i = index; trackBy: trackByIndex">
        {{filter?.name + ' (' + filter?.count + ')' }}
        <span *ngIf="isNotLastElement(i, groupedFilters)">, </span>
      </span>
    </div>
    <app-button class="button clear-button--right" text="Modify" classes="transparent" (onClick)="modifyQuery()"></app-button>
  </header>
  <div class="chip"
       *ngFor="let filter of filteredAnalyticsFilters; let i = index; trackBy: trackByIndex"
       (click)="delete($event, i)">
    <div class="chip__item chip__item--name">
      {{ filter.text }}:&nbsp;
    </div>
    <div class="chip__item chip__item--value">
      {{ filter.value }}
    </div>
    <div class="chip__item chip__item--icon">
      <app-icon iconStyle="pi" iconType="pi" iconName="times"></app-icon>
    </div>
  </div>
</app-collapsible-panel>
