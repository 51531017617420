import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements AfterViewChecked {
  @Input() appendTo: any;
  @Input() isPinned = false;

  @Output() onShow = new EventEmitter<any>();
  @Output() onHide = new EventEmitter<any>();

  @ViewChild("primePopup", { static: true }) private primePopup: OverlayPanel;


  constructor(private readonly changeDetection: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.positionPrimeOverlayPanel();
  }


  get visible(): boolean {
    return this.primePopup.overlayVisible;
  }

  show(event: any, target?: any): void {
    this.primePopup.show(event, target);
    this.changeDetection.markForCheck();
    this.onShow.emit(event);
  }

  hide(): void {
    this.primePopup.hide();
    this.primePopup.render = false;
    this.primePopup.overlayVisible = false;
    this.changeDetection.markForCheck();
    this.onHide.emit(null);
  }

  toggle(event: any, target?: any): void {
    this.primePopup.toggle(event, target);
    this.changeDetection.markForCheck();
  }


  private positionPrimeOverlayPanel(): void {
    // PrimeNG keeps positioning the target wrong.
    if (this.primePopup) {
      const target = this.primePopup.el.nativeElement.firstElementChild as HTMLElement;
      if (target != null) {
        target.style.top = null;
        target.style.left = null;
        target.style.marginTop = "-10px";
      }
    }
  }
}
