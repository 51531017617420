import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Resize } from "../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { TagActionType } from "../../../../../shared/tags/model/tag-action-type.enum";
import { TagSourceType } from "../../../../../shared/tags/model/tag-source-type.enum";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { AddressBulkUpdate } from "../address-bulk-update.model";
import { BulkAction } from "../bulk-action.enum";
import { AddressBulkUpdateActionType } from "../bulk-update-address/address-bulk-update-action-type.enum";
import { TagBulkUpdate } from "../bulk-update-tag.model";
import { BulkUpdatesService } from "../bulk-updates.service";
import { BasicGridComponent } from "./../../../../../shared/grid/basic-grid/basic-grid.component";
import { BulkUpdatesComponent } from "./../bulk-updates.component";
import { BulkUpdateTagService } from "./bulk-update-tag.service";

@Component({
  selector: "app-bulk-update-tag",
  templateUrl: "./bulk-update-tag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateTagComponent implements OnInit {
  serverGridConfiguration = new GridConfiguration();
  serverRequest = new GridRequest();
  gridRefresh = new EventEmitter<null>();
  @Output() isValidatedManageTag = new EventEmitter<boolean>();
  isShowValidateGridData: boolean;
  @Input() actionControls: string[] = [];
  @Input() tagActionType = "";
  @Input() tagFormData: any;
  @Input() tagType: TagType;
  checkError = false;
  isShowValidateGrid = false;
  @Input() isShowChaseControls = true;
  bulkUpdateTypeItem = "bulkUpdateType";
  bulkUpdateActionItem = "bulkUpdateActions";
  chaseIds = "chaseIds";
  clientOrgsId = "clientId";
  projectId = "projectId";
  documentIds = "documentIds";
  tagNotes: Textarea;
  formGroup: FormGroup;
  bulkUpdate: TagBulkUpdate;
  chaseIdsList: string[];
  commonFilters: any[] = [];
  refreshGridData = new EventEmitter<boolean>(true);
  @Input() addressbulkUpdateData: AddressBulkUpdate;
  @Output() isShowContinueButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isShowControls: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isShowEditBulk: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isProcessingManageTag = new EventEmitter<boolean>();
  @ViewChild(BasicGridComponent) private basicGridComponent: BasicGridComponent;
  manageTagId: number;
  data: any;
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private messagingService: MessagingService,
    private bulkUpdatesService: BulkUpdatesService,
    private bulkUpdateTagService: BulkUpdateTagService,
    private authService: AuthService,
    private bulkUpdatesComponent: BulkUpdatesComponent
  ) { }

  ngOnInit() {
    this.initializeFormGroupControls();
    this.createTagForm();
  }

  initializeFormGroupControls(): void {
    this.tagNotes = new Textarea({
      key: "tagNotes",
      label: "Note",
      placeholder: "Write a note...",
      rows: 6,
      resize: Resize.VERTICAL,
      validators: [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(1000),
      ],
      errorMessages: {
        required: "Write a note between 4 - 1000 characters.",
        minlength: "Write a note between 4 - 1000 characters.",
        maxlength: "Write a note between 4 - 1000 characters.",
      },
    });
  }

  createTagForm(): void {
    this.formGroup = this.formService.createFormGroup([this.tagNotes]);
  }

  validateToShowTagGrid() {
    this.bulkUpdatesComponent.validateContinue();
    const notes = this.formGroup.value?.tagNotes;
    if (!StringHelper.isAvailable(notes) || (StringHelper.isAvailable(notes) && (!notes?.replace(/^\s+|\s+$/g, "")) || ((notes?.replace(/ /g, "").length < 4)))) {
      this.formGroup.get(this.tagNotes.key).setErrors({ "server-error": "Write a note between 4 - 1000 characters." });
    } else {
      this.formGroup.get(this.tagNotes.key).setErrors(null);
    }

    if (this.bulkUpdatesComponent.formGroup.invalid || this.bulkUpdatesComponent.hasValidationError || !this.bulkUpdatesComponent.formGroup.controls.tagActionInput.touched) {
      this.bulkUpdatesComponent.isShowContinueButton = false;
      this.bulkUpdatesComponent.isShowControls = true;
      return;
    }

    if (this.formGroup.invalid) {
      this.formService.markAllAsTouched(this.formGroup);
      return;
    }
    this.isShowValidateGridData = true;
    this.getValidatedAndProcessTags();
    this.changeDetector.markForCheck();
  }

  private getTagId(): string {
    if (this.tagActionType === "Remove" || this.tagActionType === "Replace") {
      this.manageTagId = TagActionType.REMOVE;
      return this.tagFormData.tagId;
    }
    return;
  }

  private getSelectedTags(): any {
    if (this.tagActionType === "Add") {
      this.manageTagId = TagActionType.ADD;
      const tagTextCsv = this.tagFormData?.addedTagList?.map(x => x.text).join(",");
      return tagTextCsv;
    }
    return;
  }

  private getReplaceTagId(): string {
    if (this.tagActionType === "Replace") {
      this.manageTagId = TagActionType.REPLACE;
      return this.tagFormData.replaceTagId;
    }
    return;
  }

  getFilters(): void {
    this.commonFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "Notes",
        value: this.formGroup.get("tagNotes").value,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "TagId",
        value: this.getTagId(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "TagTypeId",
        value: String(this.tagType),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "TagTextCsv",
        value: this.getSelectedTags(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ReplaceTagId",
        value: this.getReplaceTagId(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "OrganizationId",
        value: String(this.authService.user.organizationId),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ManageTagActionId",
        value: String(this.manageTagId),
        show: false,
      }),

    ];

  }

  getValidatedAndProcessTags(): void {
    this.getFilters();
    let filters: any[] = [];
    switch (this.tagType) {
      case TagType.MASTERDOCUMENTSOURCE:
        filters = [
          ...this.commonFilters,
          new GridFilter({
            input: new Textbox(),
            key: "AddressIdCsv",
            value: this.bulkUpdatesComponent.addressbulkUpdateData.addressIds.toString(),
            show: false,
          }),
        ];
        break;
      case TagType.DOCUMENT:
        filters = [
          ...this.commonFilters,
          new GridFilter({
            input: new Textbox(),
            key: "DocumentIdCsv",
            value: this.actionControls[this.documentIds],
            show: false,
          }),
        ];
        break;
      case TagType.CHASE:
        filters = [
          ...this.commonFilters,
          new GridFilter({
            input: new Textbox(),
            key: "ClientId",
            value: this.actionControls[this.clientOrgsId] ? this.actionControls[this.clientOrgsId] : null,
            show: false,
          }),
          new GridFilter({
            input: new Textbox(),
            key: "ChaseIdCsv",
            value: this.actionControls[this.chaseIds],
            show: false,
          }),
          new GridFilter({
            input: new Textbox(),
            key: "ProjectId",
            value: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
            show: false,
          }),
        ];
        break;
      default:
        break;
    }

    this.refreshGridData.emit(true);
    let url = "";
    if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.TagChases && this.tagType === TagType.CHASE) {
      this.serverGridConfiguration.columns = [
        new GridColumnDefinition({ field: "chaseId", header: "CHASE ID" }),
        new GridColumnDefinition({ field: "chaseKey", header: "CHASE KEY" }),
        new GridColumnDefinition({ field: "measureCode", header: "MEASURE" }),
        new GridColumnDefinition({ field: "clientName", header: "CLIENT" }),
        new GridColumnDefinition({ field: "projectName", header: "PROJECT" }),
        new GridColumnDefinition({ field: "tagsAddedAsCSV", header: "TAGS ADDED", show: (this.tagActionType === "Add") }),
        new GridColumnDefinition({ field: "tagAdded", header: "TAG ADDED", show: (this.tagActionType === "Replace") }),
        new GridColumnDefinition({ field: "tagRemoved ", header: "TAG REMOVED", show: (this.tagActionType === "Remove" || this.tagActionType === "Replace") }),
        new GridColumnDefinition({ field: "validationStatus", header: "VALIDATION STATUS" }),
        new GridColumnDefinition({ field: "message", header: "MESSAGE" }),
      ];
      url = "bulkupdate/tag/chases/validate";
    } else if (this.actionControls[this.bulkUpdateActionItem] === BulkAction.TagChases && this.tagType === TagType.DOCUMENT) {
      this.serverGridConfiguration.columns = [
        new GridColumnDefinition({ field: "documentQueueId", header: "DOCUMENT ID" }),
        new GridColumnDefinition({ field: "tagNameAdded", header: "TAG ADDED", show: (this.tagActionType === "Replace" || this.tagActionType === "Add") }),
        new GridColumnDefinition({ field: "tagNameRemoved ", header: "TAG REMOVED", show: (this.tagActionType === "Remove" || this.tagActionType === "Replace") }),
        new GridColumnDefinition({ field: "validationStatus", header: "VALIDATION STATUS" }),
        new GridColumnDefinition({ field: "message", header: "MESSAGE" }),
      ];
      url = "bulkupdate/tag/documents/validate";
    } else if (this.bulkUpdatesComponent.addressbulkUpdateData.addressBulkActionType === AddressBulkUpdateActionType.TagAddresses && this.tagType === TagType.MASTERDOCUMENTSOURCE) {
      this.serverGridConfiguration.columns = [
        new GridColumnDefinition({ field: "addressId", header: "ADDRESS ID" }),
        new GridColumnDefinition({ field: "projectName", header: "PROJECT(S)" }),
        new GridColumnDefinition({ field: "tagAdded", header: "TAG ADDED", show: (this.tagActionType === "Replace" || this.tagActionType === "Add") }),
        new GridColumnDefinition({ field: "tagRemoved ", header: "TAG REMOVED", show: (this.tagActionType === "Remove" || this.tagActionType === "Replace") }),
        new GridColumnDefinition({ field: "validationStatus", header: "VALIDATION STATUS" }),
        new GridColumnDefinition({ field: "message", header: "MESSAGE" }),
      ];
      url = "bulkupdate/tag/addresses/validate";
    }
    this.serverGridConfiguration.pageSize = 25;
    this.serverGridConfiguration.showActionColumn = false;
    this.serverRequest.filters = filters;
    this.getBulkValidationData(url);
  }

  getBulkValidationData(url: string) {
    this.serverRequest.setForm();
    const request = this.serverRequest.createRequestObject();
    this.bulkUpdatesService.getBulkValidationData(url, request).subscribe(res => {
      this.data = res;
      this.changeDetector.markForCheck();
    });
  }

  refreshGrid(): void {
    this.gridRefresh.emit();
  }

  get addressNote(): string {
    return this.formGroup.get(this.tagNotes.key).value;
  }

  finalBulkUpdate() {
    switch (this.tagType) {
      case TagType.CHASE:
        this.chaseIdsList = this.actionControls[this.chaseIds].split(",");
        this.bulkUpdate = new TagBulkUpdate({
          clientId: this.actionControls[this.clientOrgsId],
          chaseIdCsv: this.actionControls[this.chaseIds],
          addressIds: null,
          addressIdCsv: null,
          chaseIds: this.chaseIdsList,
          tagId: this.tagFormData.tagId ? this.tagFormData.tagId : 0,
          replaceTagId: this.tagFormData.replaceTagId ? this.tagFormData.replaceTagId : 0,
          tagItems: this.tagFormData.addedTagList,
          notes: `${this.formGroup.get("tagNotes").value} via Bulk Action`,
          bulkAction: this.tagActionType === "Remove" ? 2 : 1,
          organizationId: this.authService.user.organizationId,
          tagTypeId: this.tagType,
          tagSourceId: TagSourceType.USER,
          manageTagActionId: this.manageTagId,
          projectId: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        });
        this.bulkUpdateTagService.finishChaseTagBulkUpdate(this.bulkUpdate).subscribe(
          {
            next: () => {
              this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
              this.changeDetector.markForCheck();
            },
            error: () => {
              this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
              this.changeDetector.markForCheck();
            },
          }
        );
        break;
      case TagType.DOCUMENT:
        const documentIdsList = this.actionControls[this.documentIds].split(",");
        this.bulkUpdate = new TagBulkUpdate({
          clientId: null,
          chaseIdCsv: null,
          addressIds: null,
          addressIdCsv: null,
          chaseIds: null,
          documentIds: documentIdsList,
          documentIdCsv: this.actionControls[this.documentIds],
          tagId: this.tagFormData.tagId ? this.tagFormData.tagId : 0,
          replaceTagId: this.tagFormData.replaceTagId ? this.tagFormData.replaceTagId : 0,
          tagItems: this.tagFormData.addedTagList,
          notes: `${this.formGroup.get("tagNotes").value} via Bulk Action`,
          bulkAction: this.tagActionType === "Remove" ? 2 : 1,
          organizationId: this.authService.user.organizationId,
          tagTypeId: this.tagType,
          tagSourceId: TagSourceType.USER,
          manageTagActionId: this.manageTagId,
          projectId: this.actionControls[this.projectId] ? this.actionControls[this.projectId] : null,
        });
        this.bulkUpdateTagService.finishDocumentTagBulkUpdate(this.bulkUpdate).subscribe(
          {
            next: () => {
              this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
              this.changeDetector.markForCheck();
            },
            error: () => {
              this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
              this.changeDetector.markForCheck();
            },
          }
        );

        break;

      case TagType.MASTERDOCUMENTSOURCE:
        this.bulkUpdate = new TagBulkUpdate({
          clientId: null,
          chaseIds: null,
          chaseIdCsv: null,
          addressIds: this.bulkUpdatesComponent.addressbulkUpdateData.addressIds,
          addressIdCsv: this.bulkUpdatesComponent.addressbulkUpdateData.addressIds.toString(),
          tagId: this.tagFormData.tagId ? this.tagFormData.tagId : 0,
          replaceTagId: this.tagFormData.replaceTagId ? this.tagFormData.replaceTagId : 0,
          tagItems: this.tagFormData.addedTagList,
          notes: `${this.formGroup.get("tagNotes").value} via Bulk Action`,
          bulkAction: this.tagActionType === "Remove" ? 2 : 1,
          organizationId: this.authService.user.organizationId,
          tagTypeId: this.tagType,
          tagSourceId: TagSourceType.USER,
          manageTagActionId: this.manageTagId,
        });
        this.bulkUpdateTagService.finishAddressTagBulkUpdate(this.bulkUpdate).subscribe(
          {
            next: () => {
              this.messagingService.showToast("You have successfully completed bulk update.", SeverityType.SUCCESS);
              this.changeDetector.markForCheck();
            },
            error: () => {
              this.messagingService.showToast("Bulk Update Failed", SeverityType.WARN);
              this.changeDetector.markForCheck();
            },
          }
        );

        break;
      default:
        break;
    }
  }

  get isFinalBulkUpdate(): boolean {
    if (this.basicGridComponent && ArrayHelper.isAvailable(this.basicGridComponent.data)) {
      return this.bulkUpdatesService.checkValidationStatus(this.basicGridComponent.data);
    }
  }

}
