import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { DynamicGroup, IDynamicGroupOptions } from "../dynamic-group.model";
import { SaveGroupComponent } from "./save-group.component";

export interface ISaveGroupOptions extends IDynamicGroupOptions {
  isHideClearButton?: boolean;
  isShowHighlighterClass?: boolean;
 }

export class SaveGroup extends DynamicGroup implements ISaveGroupOptions {
  readonly controlType = "save-group";
  readonly componentType = SaveGroupComponent;

  isHideClearButton: boolean;
  isShowHighlighterClass: boolean;

  constructor(options: ISaveGroupOptions = {}) {
    super(options);
    this.header = options.header || "";
    this.isHideClearButton = BooleanHelper.tryGet(options.isHideClearButton, false);
    this.isShowHighlighterClass = BooleanHelper.tryGet(options.isShowHighlighterClass, true);
  }
}
