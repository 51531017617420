<app-risk-dx-menu (onTabSelected)="selectedTab($event)" ></app-risk-dx-menu>
<div class="card-container" id="scrollElement" >
  <router-outlet></router-outlet>
</div>


<div class="floating-button" *ngIf="showNetNewButton()" >
  <div class="floating-button--icon">
    <app-icon iconStyle="fas" iconName="plus"></app-icon>
    <div class="floating-button--tooltip">
      <div class="floating-button--options" (click)="addNewNetHCC()">Add New HCC</div>        
    </div>
  </div>
</div>