import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RetrievalUrlService implements CanActivate {
  addressId: number;
  documentSourceType: string;
  documentSourceTypeID: number;
  url: string;

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    this.documentSourceTypeID = route.params.DocumentSourceTypeID;
    this.addressId = route.params.AddressId;

    switch (Number(this.documentSourceTypeID)) {
      case 2:
        this.documentSourceType = "emr";
        break;
      case 3:
        this.documentSourceType = "ft";
        break;
      default:
      case 1:
        this.documentSourceType = "address";
        break;
    }

    this.url = `/retrieval/${this.documentSourceType}/${this.addressId}`;
    this.router.navigateByUrl(this.url);

    return false;
  }
}
