import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { interval, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { LocalService } from "../../../../core/storage/local.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ChaseGridComponent } from "../../../../shared/chase-grid/chase-grid.component";
import { GridView } from "../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../../../../shared/grid/grid-menu/grid-views/grid-views.service";
import { GridStateService } from "../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { MyQueriesService } from "../../../../shared/grid/save-query/save-query.service";
import { ModalComponent } from "../../../../shared/panel/modal/modal.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES } from "../../member/chase-detail/chase-detail-chart/attributes";
import { RouteFilters } from "../chase-query/route-filters.model";

@Component({
  selector: "app-my-queries",
  templateUrl: "./my-queries.component.html",
  styleUrls: ["./my-queries.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyQueriesComponent implements OnInit {
  myQueryViewAttributeId = PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES.attributeId;
  visible = false;
  views: GridViewsState;
  selectedView: GridView;
  myQueryGridStateName = PROJECT_CHASE_QUERY_GRID_SAVE_QUERIES.attributeCode;
  additionalFilters: GridFilter[] = [];
  additionalColumns: GridColumnDefinition[] = [];
  routeParameters: RouteFilters;
  isSOCoRetrievalOwner = false;
  showNextFilters = true;
  queryInput: Dropdown;
  myQueryDropDownInput: Dropdown;
  formGroup: FormGroup;
  loggedInUser: UserToken;
  isMyQueryPage = true;
  savedQueryName = "";
  isSavedQueryPublic = false;
  selectedSavedQueryId = 0;
  savedQueryOrgId = 0;
  savedQueryFilters: SelectableInput[];
  isGridShow = false;
  noQuerySetup = false;
  private sink = new SubSink();
  @ViewChild(ChaseGridComponent, { static: true }) chaseGridComponent: ChaseGridComponent;
  @ViewChild(ModalComponent, { static: true }) modalComponent: ModalComponent;
  constructor(
    private myQueriesService: MyQueriesService,
    private gridViewsService: GridViewsService,
    private readonly formService: FormService,
    private parameterService: ParameterService,
    private readonly userService: UserService,
    private cd: ChangeDetectorRef,
    private session: LocalService,
    private router: Router,
    private readonly gridStateService: GridStateService
  ) {
  }

  queryFromSavedState() {
    const gridState = this.gridStateService.get(this.myQueryGridStateName);
    if (gridState?.configuration?.savedQueryView) {
      if (this.views && this.views.views) {
        const view = this.views.views.filter(x => x.gridViewId === gridState.configuration.saveQueryId);
        if (ArrayHelper.isAvailable(view)) {
          this.chaseGridComponent.serverGridComponent.onViewSelect.emit(gridState.configuration.savedQueryView);
          this.chaseGridComponent.refreshGrid.emit();
          this.isGridShow = true;
          if (!history.state.isNavigationLink) {
            this.modalComponent.hide();
          }
          this.selectedSavedQueryId = gridState.configuration.saveQueryId;
          this.formGroup.get(this.queryInput.key).setValue(gridState.configuration.saveQueryId);
          this.formGroup.get(this.myQueryDropDownInput.key).setValue(gridState.configuration.saveQueryId);
          this.cd.markForCheck();
        }
      }
      if (history.state.isNavigationLink) {
        this.modalComponent.show();
      }
    }
  }
  ngOnInit() {
    this.loggedInUser = this.userService.getUserToken();
    this.isSOCoRetrievalOwner = this.loggedInUser.isCoRetrievalEnabled;
    this.sink.add(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          if (history.state.isNavigationLink) {
            this.gridStateService.delete(this.myQueryGridStateName);
          }
          this.modalComponent.show();
        }
      })
    );
    this.getRouteParameters();
    this.createChaseGrid();
    this.queryInput = new Dropdown({
      key: "queryInput",
      label: "My Queries",
      placeholder: "Select From List",
      validators: [Validators.required],
      appendTo: "body",
    });
    this.myQueryDropDownInput = new Dropdown({
      key: "mySelectedInput",
      placeholder: "Select From List",
      validators: [Validators.required],
      appendTo: "body",
    });
    this.formGroup = this.formService.createFormGroup([this.queryInput, this.myQueryDropDownInput]);

    const sessionQueryId = Number(this.session.get("sessionQueryId", 0));
    if (NumberHelper.isAvailable(sessionQueryId) && NumberHelper.isGreaterThan(sessionQueryId, 0)) {
      this.selectedSavedQueryId = sessionQueryId;
      this.session.delete("sessionQueryId");
      const source = interval(1000);
      source.pipe(takeUntil(timer(5000))).subscribe(() => {
        this.bindSavedQuery();
      });
    }
    this.getViewsDropdown();

  }

  private createChaseGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Yes", value: 1 }),
            new SelectableInput({ text: "No", value: 0 }),
            new SelectableInput({ text: "Show All", value: "" }),
          ],
        }),
        key: "encounterFound",
        name: "DOS Confirmed",
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Yes", value: 1 }),
            new SelectableInput({ text: "No", value: 0 }),
            new SelectableInput({ text: "Show All", value: "" }),
          ],
        }),
        key: "hccDiscrepency",
        name: "Hcc Discrepency",
      }),
      new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Parent Chases", value: "ParentChase" }),
            new SelectableInput({ text: "Pursuit Chases", value: "PursuitChase" }),
            new SelectableInput({ text: "Show All", value: "All" }),
            new SelectableInput({ text: "Exclude", value: "None" }),
          ],
        }),
        key: "pursuit",
        name: "Pursuits",
        value: this.pursuitFilterValue,
      }),
    ];
    this.additionalColumns = [new GridColumnDefinition({ field: "memberValidationReason", header: "Move Back Reason" })];

    if (this.isSOCoRetrievalOwner) {
      this.additionalFilters.push(new GridFilter({
        input: new Radiobutton({
          options: [
            new SelectableInput({ text: "Reveleer", value: "Reveleer" }),
            new SelectableInput({ text: "Client", value: "Client" }),

          ],
        }),
        key: "coRetrievalOwner",
        name: "Co Retrieval Owner",
        show: true,
      })
      );
      this.additionalColumns.push(new GridColumnDefinition({ field: "coRetrievalOwner", header: "Retriever" }));
    }
  }

  get pursuitFilterValue(): string {
    return this.routeParameters != null ? this.routeParameters.pursuits : "";
  }

  private getRouteParameters(): void {
    this.routeParameters = new RouteFilters(
      this.getRouteValues("ProjectID"),
      this.getRouteValues("ChaseStatus"),
      this.getRouteValues("Measure"),
      this.getRouteValues("ChaseCompliance"),
      this.getRouteValues("SampleCompliance"),
      this.getRouteValues("LastCodedBy"),
      this.getRouteValues("PendStatus"),
      this.getRouteValues("Pursuits")
    );
  }

  private getRouteValues(parameterName: string): string {
    return this.parameterService.getNormal(parameterName);
  }

  onViewSelection(selectedQuery: number, update?: boolean) {
    this.selectedSavedQueryId = selectedQuery;
    this.selectedView = this.views.views.filter(view => view.gridViewId === this.selectedSavedQueryId)[0];
    this.formService.updateDom.next();
    this.cd.markForCheck();
    if (update) {
      this.getQueryFilters();
    }
  }

  private getViewsDropdown(): void {
    this.gridViewsService.get(this.myQueryViewAttributeId)
      .subscribe(results => {
        this.views = results;
        if (ArrayHelper.isAvailable(this.views.views)) {
          this.modalComponent.show();
          const dropdownValue: SelectableInput[] = [];
          results.views.forEach(a => dropdownValue.push(new SelectableInput({ text: a.name, value: a.gridViewId, extra: a.organizationId })));
          this.queryInput.options = dropdownValue;
          this.myQueryDropDownInput.options = dropdownValue;
          this.chaseGridComponent.serverGridComponent.views = this.views;
          this.formGroup.get(this.queryInput.key).setValue(0);
          this.formGroup.get(this.myQueryDropDownInput.key).setValue(0);
          this.formService.updateDom.next();
          this.cd.markForCheck();
          if (NumberHelper.isGreaterThan(this.selectedSavedQueryId, 0)) {
            this.modalComponent.hide();
            this.formGroup.get(this.queryInput.key).setValue(this.selectedSavedQueryId);
            this.onViewSelection(this.selectedSavedQueryId, true);
          } else {
            this.queryFromSavedState();
          }
        } else {
          this.isMyQueryPage = false;
          this.formGroup.get(this.queryInput.key).setValue(0);
          this.formGroup.get(this.myQueryDropDownInput.key).setValue(0);
          this.modalComponent.hide();
          this.noQuerySetup = true;
        }

      });
  }
  goToChaseQuery() {
    this.session.put("isFromMyQueriesPage", true);
    this.router.navigate(["/query/chasequery"]);
  }
  convertToObject = (obj: any, name: string): { [key: string]: any } => {
    obj[name] = { show: true };
    return obj;
  }

  get isGridVisible(): string {
    return this.isGridShow ? "grid-show" : "grid-hide";
  }

  get isRunQuery(): boolean {
    return this.formGroup.get(this.queryInput.key).value > 0;
  }

  getQueryFilters() {
    this.visible = false;
    this.isGridShow = true;

    this.formGroup.get(this.myQueryDropDownInput.key).setValue(this.selectedSavedQueryId);
    this.formService.updateDom.next();
    this.cd.markForCheck();
    if (!this.selectedView) {
      this.selectedView = this.views.views.filter(view => view.gridViewId === this.selectedSavedQueryId)[0];
    }
    this.myQueriesService.getSavedQuery(this.selectedSavedQueryId).subscribe(result => {
      this.cd.markForCheck();
      this.chaseGridComponent.serverGridComponent.configuration.saveQueryId = result.applicationAttributeId;
      this.chaseGridComponent.serverGridComponent.configuration.saveQueryAttributeId = result.attributeId;
      this.chaseGridComponent.serverGridComponent.configuration.viewAttributeId = result.attributeId;
      this.chaseGridComponent.serverGridComponent.configuration.savedQueryName = result.configurationName;
      this.savedQueryName = result.configurationName;
      this.chaseGridComponent.serverGridComponent.configuration.isSavedQueryPublic = NumberHelper.isAvailable(this.selectedView.organizationId)
        && NumberHelper.isGreaterThan(this.selectedView.organizationId, 0);
      this.isSavedQueryPublic = this.chaseGridComponent.serverGridComponent.configuration.isSavedQueryPublic;
      this.savedQueryOrgId = result.organizationId;

      this.selectedView.columns = result.columns.reduce(this.convertToObject, {});
      this.chaseGridComponent.serverGridComponent.request.filters.map(i => i.value = null);
      this.chaseGridComponent.serverGridComponent.onViewSelect.emit(this.selectedView);
      this.chaseGridComponent.serverGridComponent.configuration.savedQueryView = this.selectedView;
      this.savedQueryFilters = result.filters;
      this.bindSavedQuery();

    },                                                                       error => console.log(error));
  }

  bindSavedQuery() {
    if (this.savedQueryFilters && ArrayHelper.isAvailable(this.savedQueryFilters)) {
      this.savedQueryFilters.forEach(element => {
        const arr = [];
        switch (element.text) {
          case "measureList":
          case "MeasuresCodes":
            const measureFiler: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "MeasuresCodes")[0];
            const splitMeasuresCodes = element.value.toString().split(",");
            splitMeasuresCodes.forEach(splitAr => {
              const option = this.chaseGridComponent.measuresInput.options.filter(y => y.value === Number(splitAr))[0];
              arr.push(option);
              measureFiler.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("MeasuresCodes", measureFiler.input);
            });
            break;
          case "Projects":
          case "projectList":
            const projectFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "Projects")[0];
            const splitProjectList = element.value.toString().split(",");
            splitProjectList.forEach(splitAr => {
              const option = this.chaseGridComponent.projectsInput.options.filter(y => y.value === Number(splitAr))[0];
              arr.push(option);
              projectFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("Projects", projectFilter.input);
            });
            break;
          case "ClientId":
            const clientIdFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "ClientId")[0];
            const clientOption = this.chaseGridComponent.clientInput.options.filter(y => y.value === Number(element.value))[0];
            clientIdFilter.input.value = clientOption;
            clientIdFilter.value = clientOption;
            this.chaseGridComponent.serverGridComponent.request.setInput("ClientId", clientIdFilter.input);
            break;
          case "Product":
            const productFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "Product")[0];
            const productOption = element.value.toString().split(",");
            productOption.forEach(val => {
              const option = this.chaseGridComponent.productsInput.options.filter(y => y.value === val)[0];
              arr.push(option);
              productFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("Product", productFilter.input);
            });
            break;
          case "PendCodes":
            const pendCodeFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "PendCodes")[0];
            const splitPendCodes = element.value.toString().split(",");
            splitPendCodes.forEach(splitAr => {
              const penCodeOption = this.chaseGridComponent.pendCodesInput.options.filter(y => y.value === splitAr)[0];
              arr.push(penCodeOption);
              pendCodeFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("PendCodes", pendCodeFilter.input);
            });
            break;
          case "PendsStatus":
            const pendsStatusFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "PendsStatus")[0];
            const splitPendsStatus = element.value.toString().split(",");
            splitPendsStatus.forEach(splitAr => {
              const option = this.chaseGridComponent.pendStatusInput.options.filter(y => y.value === splitAr)[0];
              arr.push(option);
              pendsStatusFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("PendsStatus", pendsStatusFilter.input);
            });
            break;
          case "ComplianceCodes":
            const complianceCodesFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "ComplianceCodes")[0];
            const splitComplianceCodes = element.value.toString().split(",");
            splitComplianceCodes.forEach(splitAr => {
              const option = this.chaseGridComponent.complianceInput.options.filter(y => y.value === splitAr)[0];
              arr.push(option);
              complianceCodesFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("ComplianceCodes", complianceCodesFilter.input);
            });
            break;
          case "SampleComplianceCodes":
            const sampleComplianceCodesFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "SampleComplianceCodes")[0];
            const splitsampleComplianceCodes = element.value.toString().split(",");
            splitsampleComplianceCodes.forEach(splitAr => {
              const option = this.chaseGridComponent.sampleComplianceInput.options.filter(y => y.value === splitAr)[0];
              arr.push(option);
              sampleComplianceCodesFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("SampleComplianceCodes", sampleComplianceCodesFilter.input);
            });
            break;
          case "AssignedToUserId":
            const assignedToUserIdFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "AssignedToUserId")[0];
            const assignedToUserIdOption = this.chaseGridComponent.assignedToInput.options.filter(y => y.value === Number(element.value))[0];
            assignedToUserIdFilter.input.value = assignedToUserIdOption;
            assignedToUserIdFilter.value = assignedToUserIdOption;
            this.chaseGridComponent.serverGridComponent.request.setInput("AssignedToUserId", assignedToUserIdFilter.input);
            break;
          case "LastCoderUserID":
            const lastCoderUserIDFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "LastCoderUserID")[0];
            const lastCoderUserIDOption = this.chaseGridComponent.lastCoderInput.options.filter(y => y.value === Number(element.value))[0];
            lastCoderUserIDFilter.input.value = lastCoderUserIDOption;
            lastCoderUserIDFilter.value = lastCoderUserIDOption;
            this.chaseGridComponent.serverGridComponent.request.setInput("LastCoderUserID", lastCoderUserIDFilter.input);
            break;
          case "Statuses":
            const statusesFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "Statuses")[0];
            const splitStatuses = element.value.toString().split(",");
            splitStatuses.forEach(splitAr => {
              const optionStatuses = this.chaseGridComponent.statusesInput.options.filter(y => y.value === splitAr)[0];
              arr.push(optionStatuses);
              statusesFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("Statuses", statusesFilter.input);
            });
            break;
          case "TagIdsAsCsv":
            const tagIdsFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "TagIdsAsCsv")[0];
            const splitTagIds = element.value.toString().split(",");
            splitTagIds.forEach(splitAr => {
              const option = this.chaseGridComponent.tagsInput.options.filter(y => y.value === Number(splitAr))[0];
              arr.push(option);
              tagIdsFilter.input.value = arr;
              this.chaseGridComponent.serverGridComponent.request.setInput("TagIdsAsCsv", tagIdsFilter.input);
            });
            break;
          case "memberLastName":
            const memberLastNameFilter: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === "MemberLastName")[0];
            memberLastNameFilter.value = element.value.toString();
            this.chaseGridComponent.serverGridComponent.request.setInput("MemberLastName", memberLastNameFilter.input);
            break;
          default:
            const updated: GridFilter = this.chaseGridComponent.serverGridComponent.request.filters.filter(x => x.key === element.text)[0];
            updated.value = element.value.toString();
            this.chaseGridComponent.serverGridComponent.request.setInput(element.text, updated.input);
            break;
        }
      });
      this.chaseGridComponent.refreshGrid.emit();
    }
  }

  deletedSavedQuery() {
    this.visible = true;
    this.isGridShow = false;
    this.selectedSavedQueryId = 0;
    this.getViewsDropdown();
  }

  updatedSavedQuery(value: number) {
    this.selectedSavedQueryId = value;
    this.getViewsDropdown();
  }
}
