<app-modal [(visible)]="isWarningModalVisible" (onCancel)="logout()" rejectText="Logout" class="idle-modal">
    <header>
        <app-icon-stack class="idle-icon" iconStyle="fad" iconName="hourglass-half" iconBaseStyle="fad"
            iconBase="circle"></app-icon-stack>
    </header>

    <div class="idle-modal__body">
        <p><strong>{{idleState}}</strong></p>
    </div>

    <footer>
        <app-button class="header-button" text="Continue" (onClick)="resetTimer()">
        </app-button>
    </footer>
</app-modal>