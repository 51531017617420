import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-diagnosis-by-hcc",
  templateUrl: "./diagnosis-by-hcc.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisByHccComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.DIAGNOSIS_BY_HCC;

    constructor() {
        this.filters = ["Projects", "Hcc", "ChaseId", "DiagnosisCode", "DateOfService", "Products", "LOB", "RetrievalOwner", "ChaseTags"];
    }

}
