export class WorkflowEntityAttribute {
    readonly chaseId: number;
    readonly attributeId: number;
    readonly attributeCode: string;
    readonly attributeValue: string;

    constructor(options: {
      chaseId: number;
      attributeId: number;
      attributeCode: string;
      attributeValue: string;
    }) {
      this.chaseId = options.chaseId;
      this.attributeId = options.attributeId;
      this.attributeCode = options.attributeCode;
      this.attributeValue = options.attributeValue;
    }
  }
