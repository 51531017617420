import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { MultipleChartsModel } from "../../modules/chart-lake/chart-lake-review/multiple-charts.model";
import { ChaseItem } from "../chase-search/chase-search-result-item";

@Injectable({
  providedIn: "root",
})
export class CopyChartService {
  reset = new Subject<any>();

  constructor(

    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getChaseListById(chaseId: number | number[]): Observable<ChaseItem[]> {
    const chaseIds = ArrayHelper.isAvailable(chaseId as number[]) ? chaseId : [chaseId];
    const url = `${this.baseApiUrl}chase/chaselistbyid?chaseId=${chaseIds}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("chase-search-item", "ChaseItem"))
    );
  }
  getChaseListByMasterMemberId(masterMemberId: any, chaseId: any, dataSet: any): Observable<ChaseItem[]> {
    const url = `${this.baseApiUrl}chase/memberquery?masterMemberId=${masterMemberId}&selectedChase=${chaseId}&dataset=${dataSet}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("chase-search-item", "ChaseItem"))
    );

  }
  copyChartToAnotherChase(sourceChaseId: number, targetChaseId: number[]): Observable<number> {
    const url = `${this.baseApiUrl}chase/copychart?sourceChaseId=${sourceChaseId}`;

    return this.http.post(url, targetChaseId, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>): number => {
          if (response.ok) {
            return 1;
          }
        }));
  }
  copyChartsToMultipleChases(chaseList: MultipleChartsModel[]): Observable<number> {
    const url = `${this.baseApiUrl}chase/copychart/multiple`;

    return this.http.post(url, chaseList, { observe: "response" })
      .pipe(
        map((response: HttpResponse<any>) => response as any));
  }

  clearChaseGrid(): void {
    this.reset.next();
  }

}
