import { IAutoMapper } from "../interfaces";

export const mapChartLakeReviewItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ChartLakeReviewModel")
    .forMember("chaseID", o => o.chaseID)
    .forMember("measureCode", o => o.measureCode)
    .forMember("masterMemberID", o => o.masterMemberID)
    .forMember("memberSourceAliasID", o => o.memberSourceAliasID)
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberDateOfBirth", o => o.memberDateOfBirth)
    .forMember("serviceProviders", o => o.serviceProviders)
    .forMember("chaseDocumentPageCount", o => o.chaseDocumentPageCount)
    .forMember("dateOfService", o => o.dateOfService)
    .forMember("projectName", o => o.projectName)
    .forMember("chaseMemberList", o => o.chaseMemberList)
    .forMember("chartAcquired", o => o.chartAcquired)
    .forMember("serviceProviderName", o => o.serviceProviderName);
};
