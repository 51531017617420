import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { distinctUntilChanged, filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../../../core/storage/local.service";
import { SaveGroup } from "../../../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { DynamicInput } from "../../../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { ProviderDropdown } from "../../../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { SelectableInput } from "../../../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../../../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { TextboxType } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../../../dynamic-forms/validators/date-between.validator";
import { GenericAsyncValidator } from "../../../../../../../../../dynamic-forms/validators/generic.async-validator";
import { MenuService } from "../../../../../../../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../../../../api/workflow/workflow-status-db.enum";
import { Annotation } from "../../../../../../../../widget/annotations/annotation.model";
import { AnnotationService } from "../../../../../../../../widget/annotations/annotation.service";
import { AnnotationType } from "../../../../../../../data-load/annotationtype.enum";
import { CHART_PAGE_NUMBER, DIAGNOSIS_CODE, EXEMPT_FROM_SCORING, FROM_DATE, NLP_REVIEW_RESULT, OMISSION_CODE, PROVIDER_ID, VALIDATION_REASON_CODES } from "../../../../../../chase-detail/chase-detail-chart/attributes";
import { Diagnosis } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { RiskEntity } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskHelper } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { ChaseDetailV2ChartRiskService } from "../../../risk.service";
import { DiagnosisService } from "../diagnosis.service";

@Component({
  selector: "member-risk-diagnosis-v2-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisV2AdminComponent implements OnInit, OnDestroy, OnChanges {
  @Input() adminDiagnoses: Diagnosis[];
  @Input() chaseDetailState: ChaseDetailState;
  @Input() riskState: RiskState;
  @Input() showReadOnly: boolean;

  @Output() showVRCWarningForNoMatch = new EventEmitter<boolean>();

  activeState: boolean[] = [false];
  selected: { diagnosis: Diagnosis; riskEntity: RiskEntity };
  form: FormGroup;
  saveGroup: SaveGroup;
  isVRCMatching = false;
  exemptInput: Checkbox;
  isOverread = false;
  currentDiagnosisIndex: number;
  vrcRules: string[];
  vrcForDiagnoses: string[] = [];
  vrcMatched = true;

  private localStorageIsWindowOpen = "isWindowOpen";
  private localStoragePageNumber = "pageNumber";
  private localStorageCurrentDxPageNumber = "currentDxPageNumber";
  private sink = new SubSink();
  private pageNumberInput: Textbox;
  private providerInput: ProviderDropdown;
  private startDateInput: Textbox;
  private vrcInput: TagSearchMultiselect;
  private omissionCodeInput: TagSearchMultiselect;
  private selectedEncounterId: number;
  private projectConfigurationOmissionCodeEnabled = "";
  private serviceProviderRequired = "";
  private reviewAdminEncountersAndDiagnoses = "";
  private overreadHighlightArray: any[] = [];
  private toggledOverread = false;
  private saving: boolean;
  private saveEnabled: boolean;
  private annotations: Annotation[] = [];

  constructor(
    private readonly formService: FormService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly genericAsyncValidator: GenericAsyncValidator,
    private readonly riskService: RiskService,
    private readonly automapper: AutomapperService,
    private readonly diagnosisService: DiagnosisService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messagingService: MessagingService,
    private authService: AuthService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private localService: LocalService,
    private annotationService: AnnotationService,
    private menuService: MenuService) { }

  get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  get isReviewAdminEncountersAndDiagnosesRequired(): boolean {
    return this.reviewAdminEncountersAndDiagnoses === "1";
  }

  get isvrcRequired(): boolean {
    return this.chaseDetailState.isVrcRequired;
  }

  get totalDiagnoses(): number {
    return this.adminDiagnoses.length;
  }

  get isOmissionCodeEnabled(): boolean {
    return this.projectConfigurationOmissionCodeEnabled === "1";
  }

  get isEmployeeRole(): boolean {
    return (!this.authService.user.isAdminRole || !this.authService.user.isManagerRole || !this.authService.user.isLeadRole) && this.authService.user.isEmployeeRole;
  }

  get isSaveEnabled(): boolean {
    return this.saveEnabled;
  }

  ngOnInit(): void {
    this.isOverread = this.chaseDetailState.isOverread && !this.isEmployeeRole;
    this.vrcRules = this.chaseDetailState.diagnosisValidationReasonCodes;
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.isEncounterSelected$
        .pipe(filter(encounter => this.selectedEncounterId !== encounter.encounterId))
        .subscribe(encounter => {
          this.collapseAllTabs();
          this.selectedEncounterId = encounter.encounterId;
        }),
      this.chaseDetailStateService.isOverreadToggle$.subscribe(res => {
        if (res && this.selectedEncounterId) {
          this.toggledHighlights(res);
          this.changeDetector.markForCheck();
        }
      }),
      this.chaseDetailV2ChartRiskService.previousUrl$
        .pipe(filter((previousUrl: string) => previousUrl?.includes("submit")))
        .subscribe(_ => this.isDiagnosisSelected()),
      this.riskService.dxCardProvider$
        .pipe(
          filter((newProvider: any) => !ObjectHelper.isEmpty(newProvider) && ArrayHelper.isAvailable(newProvider.providers)),
          map(this.newProvidedAdded.bind(this)))
        .subscribe(),
      this.annotationService.getCurrentAnnotations$().subscribe(annotations => this.annotations = annotations),
      this.annotationService.getCurrentEntityId$().pipe(
        filter(entityId => NumberHelper.isAvailable(entityId))
      ).subscribe(entityId => {
        const index = this.adminDiagnoses.findIndex(dx => dx.diagnosisId === entityId);
        if (NumberHelper.isAvailable(index) && index > -1) {
          this.selectDiagnosisByIndex(index);
        }
      })
    );

    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.adminDiagnoses) {
      if (changes.adminDiagnoses.firstChange) { return; }

      if (JSON.stringify(changes.adminDiagnoses.currentValue) !== JSON.stringify(changes.adminDiagnoses.previousValue) && !this.toggledOverread) {
        this.adminDiagnoses = changes.adminDiagnoses.currentValue;
        if (this.selected) {
          this.selected.diagnosis = this.currentDiagnosisIndex ? this.adminDiagnoses[this.currentDiagnosisIndex] : this.adminDiagnoses[0];
        }
        this.fillData();
      }
      if (changes.showReadOnly) {this.fillData(); }
    }
  }

  saveDiagnosis(): void {
    const dynamicControl: any = this.saveGroup.controls;
    this.save(dynamicControl);
  }

  private validateInputs(): boolean {
    return this.form.valid && this.validateVrcCode() && this.validateProvider();
  }

  private validateVrcCode(): boolean {
    if (!this.isvrcRequired) {
      return true;
    } else {
      const vrcInputValue = this.form.controls.Diagnosis.get("vrc").value;
      return this.diagnosisService.checkIfVRC108Selected(vrcInputValue) || !this.isReviewAdminEncountersAndDiagnosesRequired;
    }

  }

  validateProvider(): boolean {
    const providerControl = this.form.controls.Diagnosis.get(this.providerInput.key);
    if (!this.isServiceProviderRequired) {
      return true;
    } else {
      if (providerControl.value == null || !StringHelper.isAvailable(providerControl.value)) {
        providerControl.setErrors({ required: true });
        return false;
      } else {
        providerControl.setErrors(null);
        return true;
      }
    }
  }

  private tryEnablingSaveButton(): void {
    if (!this.saving && this.validateInputs()) {
      this.saveEnabled = true;
      this.changeDetector.markForCheck();
    }
  }

  private setEnableSaveButton(enabled, saving = true): void {
    this.saving = saving;
    this.saveEnabled = enabled;
    this.changeDetector.markForCheck();
  }

  handleKeyUp(): void {
    this.tryEnablingSaveButton();
  }

  handleChange(event: any, isCheckbox = false, index: number): void {
    if (this.currentDiagnosisIndex !== index) {
      return;
    }

    switch (event.type) {
      case "save":
      case "notify":
        this.tryEnablingSaveButton();
        break;
      default:
        break;
    }
  }

  onTabOpen(event: any) {
    this.selectDiagnosisByIndex(event.index);
  }

  private selectDiagnosisByIndex(diagnosisIndex: number): void {
    this.currentDiagnosisIndex = diagnosisIndex;
    const diagnosis: Diagnosis = this.adminDiagnoses[diagnosisIndex];
    this.searchAnnotationMatches(diagnosis);
    this.updateSelectedDiagnosis(diagnosis.id);
    this.getEncounterDetailsForHeader(diagnosis);
    setTimeout(() => {
      this.selectDiagnosis();
      this.getOverreadHighlights();
      this.setEnableSaveButton(!this.isvrcRequired, false);
    });
  }

  private searchAnnotationMatches(diagnosis: Diagnosis): void {
    const isDataLoadAnnotation = (annotation: Annotation): boolean => {
      return (
      annotation.annotationTypeId === AnnotationType.ObjectByClient ||
      annotation.annotationTypeId === AnnotationType.ObjectByUser
      );
    };
    const matches = this.annotations.filter(a => a?.entityId === diagnosis.diagnosisId && isDataLoadAnnotation(a));
    if (ArrayHelper.isAvailable(matches)) {
      this.annotationService.setAnnotationsMatches(matches);
      this.menuService.toggleWidgetState(true);
      return;
    }
    this.annotationService.setAnnotationsMatches([]);
  }

  toggledHighlights(status: string) {
    this.toggledOverread = true;
    this.activeState[this.riskState?.selectedDiagnosisIndex] = true;
    const currentDiagnosis = this.adminDiagnoses[this.riskState?.selectedDiagnosisIndex];
    const currentTab = document.getElementById(`admin-${currentDiagnosis.id}`);
    const pageNumber = currentTab.getElementsByTagName("input")[0];
    const vrcEle = currentTab.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab.getElementsByTagName("p-multiselect")[1];

    if (status === WorkflowStatusDb[WorkflowStatusDb.Abstraction]) {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      this.getOverreadHighlights();
      vrcEle?.children[0].classList.remove("control__input--overread");
      omissionEle?.children[0].classList.remove("control__input--overread");
      pageNumber.classList.remove("control__input--overread");
    } else {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      const selected = this.overreadHighlightArray.filter(x => x.diagnosisIndex === this.currentDiagnosisIndex)[0];
      if (selected?.vrc) {
        vrcEle?.children[0].classList.add("control__input--overread");
      }
      if (selected?.omission) {
        omissionEle?.children[0].classList.add("control__input--overread");
      }
      if (selected?.page) {
        pageNumber.classList.add("control__input--overread");
      }
    }
    setTimeout(() => {
      this.toggledOverread = false;
    },         1500);

  }

  getOverreadHighlights() {
    const index = this.overreadHighlightArray.findIndex(x => x.diagnosisIndex === this.currentDiagnosisIndex);
    if (index >= 0) {
      this.overreadHighlightArray.splice(index, 1);
    }
    const currentDiagnosis = this.adminDiagnoses[this.currentDiagnosisIndex];
    const currentTab = document.getElementById(`admin-${currentDiagnosis.id}`);
    const pageNumber = currentTab.getElementsByTagName("input")[0];
    const vrcEle = currentTab.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab.getElementsByTagName("p-multiselect")[1];

    const vrcHighlights = vrcEle?.children[0]?.classList?.value?.includes("control__input--overread");
    const pageHighlights = pageNumber?.classList?.value?.includes("control__input--overread");
    const omissionHighlights = omissionEle?.children[0]?.classList?.value?.includes("control__input--overread");
    const selectedDiagnosisHighlightedProperties = {
      diagnosisIndex: this.currentDiagnosisIndex,
      vrc: vrcHighlights,
      page: pageHighlights,
      omission: omissionHighlights,
    };
    this.overreadHighlightArray.push(selectedDiagnosisHighlightedProperties);

  }
  getEncounterDetailsForHeader(diagnosisDetail: Diagnosis) {
    const diagnosis: Diagnosis = diagnosisDetail;
    const diagnosisId = diagnosis.id;

    const diagnosisData: DiseaseDetail = this.diagnosisService.getDiseaseDetail(diagnosisId);
    const selectedProvider = this.riskState.providers.find(p => p.providerSourceAliasId === diagnosis.providerSourceAliasId)?.providerName;

    const diagnosisdetail = {
      icdCode: diagnosisData?.icdCode,
      diseaseName: diagnosisData?.diseaseName,
      modelDisplayName: diagnosisData?.modelDisplayName,
      dos: diagnosis.endDate,
      isDiagnosSelected: true,
      providerName: selectedProvider,
    };
    this.chaseDetailStateService.encounterDetails.next(diagnosisdetail);
    this.chaseDetailStateService.isFilterButtonSelected.next(false);
  }

  getBadgeStatus(diagnosis: Diagnosis, index: number) {

    if (this.activeState[index]) {
      return "reviewing";
    }

    if (!StringHelper.isAvailable(diagnosis?.actualVrcs)) {
      return "review";
    }

    if (diagnosis.isValid) {
      return "validated";
    }

    return "notValid";
  }

  trackByIndex(index, item) {
    return index;
  }

  private initializeForm(): void {
    this.exemptInput = new Checkbox({
      key: `exemptInput`,
      label: "Exempt",
    });

    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      classOverride: "pageNumber",
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a valid page number",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date From",
      type: TextboxType.TEXT,
      classOverride: "dateControl",
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date From is required",
      },
    });

    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      placeholder: "Select...",
    });

    this.vrcInput = new TagSearchMultiselect({
      key: "vrc",
      label: "VRC",
      placeholder: "Select...",
      maxSelectedLabels: 150,
    });

    this.omissionCodeInput = new TagSearchMultiselect({
      key: "omission",
      label: "Omission",
      placeholder: "Select...",
      maxSelectedLabels: 150,
    });

    this.saveGroup = new SaveGroup({
      key: "Diagnosis",
      isHideClearButton: true,
      controls: [
        this.pageNumberInput,
        this.startDateInput,
        this.providerInput,
        this.vrcInput,
        this.omissionCodeInput,
      ],
    });

    this.pageNumberInput.parent = this.saveGroup;
    this.startDateInput.parent = this.saveGroup;
    this.providerInput.parent = this.saveGroup;
    this.vrcInput.parent = this.saveGroup;
    this.omissionCodeInput.parent = this.saveGroup;

    this.form = this.formService.createFormGroup([this.saveGroup, this.exemptInput]);
  }

  private selectDiagnosis(shouldFillData = true): void {
    const diagnosis: Diagnosis = this.adminDiagnoses[this.currentDiagnosisIndex];
    this.updateSelectedDiagnosis(diagnosis.id);
    this.updateFormWithProjectConfiguration(this.chaseDetailState);

    setTimeout(() => {
      this.selected = { diagnosis, riskEntity: this.riskState.selectedDiagnosis };
      this.getEncounterDetailsForHeader(diagnosis);
      this.diagnosisService.updateDataEntryPageNumber(diagnosis.pageNumber, diagnosis.diagnosisValidationResult);
      this.diagnosisService.toggleDiagnosisSelected(true);

      if (shouldFillData) {
        this.fillData();
      }

      if (this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
        if (diagnosis?.pageNumber !== null) {
          this.localService.put(this.localStoragePageNumber, diagnosis?.pageNumber);
          this.localService.put(this.localStorageCurrentDxPageNumber, diagnosis?.pageNumber);
        }
      }

    });

  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "0";

      this.projectConfigurationOmissionCodeEnabled =
        StringHelper.isAvailable(projectConfiguration.omissionCodeEnabled) ? projectConfiguration.omissionCodeEnabled : "0";

      this.reviewAdminEncountersAndDiagnoses =
        StringHelper.isAvailable(projectConfiguration.reviewAdminEncountersAndDiagnoses) ? projectConfiguration.reviewAdminEncountersAndDiagnoses : "0";
      }
  }

  private fillData() {
    if (this.selected?.diagnosis && this.selected?.riskEntity) {
      const chaseId = this.riskState.chaseId;
      const workflowStatusName = this.riskState.workflowStatusName.toLowerCase();
      const diagnosisId = this.selected.diagnosis.diagnosisId;
      const entityTypeId = this.selected.riskEntity.entityTypeId;
      const encounterId = this.selected.diagnosis.encounterId;
      const disabled = !this.riskState.isEnabled || !this.riskState.isSelectedEncounterValid;

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.exemptInput,
        ATTRIBUTE_DATA: EXEMPT_FROM_SCORING,
      });
      this.form.controls.exemptInput.setValue(this.selected.diagnosis.exempt);

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled: disabled || this.selected.diagnosis.isBotSource,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.pageNumberInput,
        ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
      });

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled: disabled || this.selected.diagnosis.isBotSource,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.startDateInput,
        ATTRIBUTE_DATA: FROM_DATE,
      });

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.providerInput,
        ATTRIBUTE_DATA: PROVIDER_ID,
      });

      const attribute = {
        ...RiskHelper.getAttribute(DIAGNOSIS_CODE.attributeCode, this.selected.riskEntity),
        ...DIAGNOSIS_CODE,
        chaseId,
        entityId: diagnosisId,
        entityTypeId,
        parentEntityId: encounterId,
      };
      this.providerInput.options = this.riskState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
      this.providerInput.disabled = disabled;
      const control = this.form.get(this.providerInput.getMasterKey());
      this.providerInput.disabled ? control.disable() : control.enable();

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.vrcInput,
        hidden: !this.isvrcRequired,
        ATTRIBUTE_DATA: VALIDATION_REASON_CODES,
      });

      if (!ArrayHelper.isAvailable(this.vrcInput.options)) {
        this.vrcInput.options = this.riskState.vrcs.filter(x => x.extra.isActive !== false);
      }
      if (StringHelper.isAvailable(this.selected.diagnosis.vrcs)) {
        const vrcValues = this.selected.diagnosis.vrcs.split(",");
        this.vrcInput.selectedOptions = this.selectedOptions(vrcValues, this.vrcInput);
      }

      this.setupAttribute({
        chaseId,
        diagnosisId,
        encounterId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity: this.selected.riskEntity,
        input: this.omissionCodeInput,
        hidden: !ArrayHelper.isAvailable(this.riskState.omissionCodes) || !this.isOmissionCodeEnabled,
        ATTRIBUTE_DATA: OMISSION_CODE,
      });

      this.omissionCodeInput.options = this.riskState.omissionCodes;

      if (StringHelper.isAvailable(this.selected.diagnosis.omissionCodes)) {
        const ocValues = this.selected.diagnosis.omissionCodes.split(",");
        this.omissionCodeInput.selectedOptions = this.selectedOptions(ocValues, this.omissionCodeInput);
      }
    }
  }

  private selectedOptions(selectedValues: any, multiselect: TagSearchMultiselect, vrc: boolean = false): SelectableInput[] {
    const selectedOptions = selectedValues.reduce(
      (acc, value) => {
        const found = multiselect.options?.find(option => option.value === value);
        if (found != null) {
          acc.push(found);
        }
        return acc;
      },
      []);

    return selectedOptions;
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    diagnosisId,
    encounterId,
    entityTypeId,
    disabled,
    workflowStatusName,
    hidden = false,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId: diagnosisId,
      entityTypeId,
      parentEntityId: encounterId,
    };

    this.updateAttributeBasedOnProjectConfiguration(attribute);

    input.hidden = hidden;
    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled || (input.key === "pageNumber" ? false : attribute.isAdmin);
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    input.disabled ? control.disable() : control.enable();
  }

  private updateAttributeBasedOnProjectConfiguration(attribute: DynamicEntityAttribute): DynamicEntityAttribute {
    if (!this.isvrcRequired && attribute.attributeCode === VALIDATION_REASON_CODES.attributeCode) {
      attribute.value = "01";
    }
    return attribute;
  }

  private updateSelectedDiagnosis(id: string) {
    const selectedDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === id);
    this.riskService.setData({ selectedDiagnosisIndex });
  }

  private save(controls: DynamicInput[]): void {
    if (!this.validateInputs()) {
      return;
    }

    this.setEnableSaveButton(false);

    const attributes = controls
      .filter(this.isAttributeAvailable.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];

    this.extendAttributes(attributes);
    this.modifySourceTypeIdAttribute(attributes);
    this.saveAttributes(attributes);
  }

  private modifySourceTypeIdAttribute(attributes: DynamicEntityAttribute[]): void {
    const providerAttribute = attributes.find(attribute => attribute.attributeId === PROVIDER_ID.attributeId);
    delete providerAttribute?.sourceTypeId;
  }

  private extendAttributes(attributes: DynamicEntityAttribute[]): void {

    if (!attributes.some(attr => attr.attributeCode === CHART_PAGE_NUMBER.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(CHART_PAGE_NUMBER.attributeCode, this.selected.riskEntity));
    }

    attributes.push(this.setExemptAttribute());
  }

  private isAttributeAvailable(attribute: DynamicInput): boolean {
    return attribute != null
      && attribute.saveInfo != null
      && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0)
      && !attribute.disabled;
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    const formInput = this.form.get(control.getMasterKey());
    if (saveAttribute.attributeId === VALIDATION_REASON_CODES.attributeId || saveAttribute.attributeId === OMISSION_CODE.attributeId) {
      const selectedValues = Array.isArray(formInput.value) ? (ArrayHelper.isAvailable(formInput.value) ? formInput.value?.map(x => x.value).toString() : undefined) : formInput.value;
      const sortedSelectedValues = selectedValues?.split(",").sort(StringHelper.compareNumbersForSortASC).join(",");
      saveAttribute.value = sortedSelectedValues;
    } else {
      saveAttribute.value = saveAttribute.attributeId === DIAGNOSIS_CODE.attributeId ? formInput.value.value : formInput.value;
    }
    return saveAttribute;
  }

  private setExemptAttribute(): DynamicEntityAttribute {
    const exemptFromScoringAttribute = RiskHelper.getAttribute(EXEMPT_FROM_SCORING.attributeCode, this.selected.riskEntity);
    const control = this.form.get(this.exemptInput.getMasterKey()) as FormControl;

    let newExemptAttribute;
    if (!ObjectHelper.isEmpty(exemptFromScoringAttribute)) {
      newExemptAttribute = { ...exemptFromScoringAttribute, value: control.value ? "1" : "0" };
    } else {
      newExemptAttribute = {
        chaseId: this.selected.riskEntity.chaseId,
        entityId: this.selected.riskEntity.entityId,
        entityTypeId: EntityType.DIAGNOSIS,
        parentEntityId: this.selected.riskEntity.parentEntityId,
        ...EXEMPT_FROM_SCORING,
        value: control.value ? "1" : "0",
      };
    }
    return newExemptAttribute;
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.sink.add(
      this.riskService
        .save(attributes, true)
        .pipe(
          finalize(() => this.saving = false)
        )
        .subscribe(
          newAttributes => {
            this.diagnosisService.removeDiseaseDetail(this.riskState.selectedDiagnosis.id);
            const nlpReviewResult = RiskHelper.getAttribute(NLP_REVIEW_RESULT.attributeCode, this.selected.riskEntity);
            if (!ObjectHelper.isEmpty(nlpReviewResult)) {
              newAttributes.push(nlpReviewResult);
            }
            this.riskService.setDiagnosisAttributes(this.riskService.data.value.selectedDiagnosisIndex, newAttributes);
            this.isVRCMatching = this.diagnosisService.checkIfVRCMatches(this.riskState, this.vrcRules);
            this.showVRCWarningForNoMatch.emit(!this.isVRCMatching);
            this.messagingService.showToast("Admin Dx Updated!", SeverityType.SUCCESS);
            this.collapseAllTabs();
            this.diagnosisService.toggleDiagnosisSelected(false);
          },
          (e: HttpErrorResponse) => {
            this.form.setErrors({ saveError: e.error });
            this.messagingService.showToast("Admin Dx Not Updated", SeverityType.WARN);
          }
        )
    );
  }

  private collapseAllTabs(): void {
    for (let i = 0; i < this.adminDiagnoses.length; i++) {
      this.activeState[i] = false;
    }
    this.changeDetector.markForCheck();
  }

  private isDiagnosisSelected(): void {
    if (this.riskState.hasSelectedDiagnosisIndex) {
      const diagnosisId = this.riskState.selectedDiagnosis.id;
      this.currentDiagnosisIndex = this.adminDiagnoses.findIndex(d => d.id === diagnosisId);

      if (NumberHelper.isGreaterThan(this.currentDiagnosisIndex, 0, true)) {
        this.activeState[this.currentDiagnosisIndex] = true;
        this.selectDiagnosis();
        this.scrollIntoView(diagnosisId);
      }
    }
  }

  private scrollIntoView(diagnosisId: string): void {
    this.diagnosisService.waitForElement(`admin-${diagnosisId}`)
      .then((el: HTMLElement) =>
        el.scrollIntoView({ block: "center" })
      );
  }

  private newProvidedAdded(newProvider: any): void {
    const selectedOption = newProvider.providers.find(a => a.extra.nationalProviderId === newProvider.selectedOption);
    this.providerInput.options = newProvider.providers;
    this.form.get("Diagnosis").get("Provider").setValue(selectedOption?.value.toString());
  }
}
