<app-detail-header-template>
  <div top-left>
    <div class="back-btn-container">
      <app-back-button [moveToPreviousUrl]="moveToPreviousUrl" (onBackClick)="toggleTab('auditquery')"></app-back-button>
    </div>
    <h3 class="title-container bold">{{headerText | uppercase}}</h3>
    <div>
      All queries will search for Compliant, Exclusion and Non-Compliant/Contra chases.
    </div>
  </div>
  <div top-right [hidden]="!chaseDetailsVisible">
    <app-list [items]="chaseDetails"></app-list>
  </div>
  <div bottom-left>
    <div class="sub-menu__container--audit-query-header">
      <div class="item">
        <a class="link" [ngClass]="{'active': selectedTab === 'auditquery'}" (click)="toggleTab('auditquery')">
          <div class="button">
            <div class="description">Audit Query</div>
          </div>
        </a>
      </div>
      <div class="item">
        <a class="link" [ngClass]="{'active': selectedTab === 'chart'}" (click)="toggleTab('chart')">
          <div class="button">
            <div class="description">Chart</div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div bottom-right [hidden]="chaseDetailsVisible">
    <app-audit-query-grid-filters [stateName]="stateName"></app-audit-query-grid-filters>
  </div>
</app-detail-header-template>
