import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { FileUploadModule } from "primeng/fileupload";
import { SharedModule } from "../shared/shared.module";
import { EmailVerificationComponent } from "./chart-upload/email-verification/email-verification.component";
import { ProviderHeaderComponent } from "./chart-upload/provider-header/provider-header.component";
import { ThankyouComponent } from "./chart-upload/thankyou/thankyou.component";
import { ProviderRoutingModule } from "./provider-routing.module";
import { ProviderUploadPortalComponent } from "./provider-upload-portal/provider-upload-portal.component";
import { ReviewAuthLetterComponent } from "./review-auth-letter/review-auth-letter.component";

@NgModule({
  imports: [
    CommonModule,
    ProviderRoutingModule,
    SharedModule,
    FileUploadModule,
    PdfViewerModule,

  ],
  declarations:
    [
     EmailVerificationComponent,
     ThankyouComponent,
     ReviewAuthLetterComponent,
     ProviderHeaderComponent,
     ProviderUploadPortalComponent,

    ],
})
export class ProviderModule { }
