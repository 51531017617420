<app-detail-main-template>
  <div container-header>
    <app-detail-header-template>
      <div top-left>
        <h3>Chase Assignment</h3>
      </div>
      <div bottom-left>
        <div class="sub-menu__container--clinical-queue">
          <div class="item">
            <a class="link"
               [ngClass]="{'active': selectedTab === 'chases'}"
               (click)="toggleTab('chases')">
              <div class="button">
                <div class="description">Chases</div>
              </div>
            </a>
          </div>
          <div class="item">
            <a class="link"
               [ngClass]="{'active': selectedTab === 'members'}"
               (click)="toggleTab('members')">
              <div class="button">
                <div class="description">Members</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </app-detail-header-template>
  </div>
  <div container-body>
    <div class="addresses-queue-container">
      <div class="overviewtab section tab-content"
           *ngIf="this.selectedTab === 'chases'">
        <div >
          <div class="filters">
              <app-button class="button float-right pad-right"
                          (onClick)="isQueueModalVisible=true"
                          icon="sliders-h"
                          text="QUEUE PRIORITIES"></app-button>
          </div>

        </div>
    
        <div class="chases-queue-grid-container">
          <div>
            <app-chase-grid stateName="clinical_queue_chases"
                            [additionalColumns] ="additionalColumns"
                            [additionalFilters]="filters"
                            [additionalBulkActions]="additionalBulkActions"
                            [sortDirection]="sortDirection"
                            [clinical]="false"
                            [isUnassignEnabled]="false"
                            [showGridHeader]="true"
                            [sortField]="sortField"></app-chase-grid>
          </div>
        </div>
      </div>
    
      <div class="overviewtab section tab-content"
           *ngIf="this.selectedTab === 'members'">
        <div class="member-chases-container">
          <app-server-grid [configuration]="membersGridConfiguration"
                           [(request)]="membersGridRequest"
                           [refresh]="refreshMembersGrid"
                           [(selection)]="membersGridSelection"
                           [actions]="actions"
                           [filterTemplate]="filterForm"
                           (dataLoaded)="gridDataLoaded($event)"
                           [actionTemplate]="membersActionTemplate"
                           [rowExpandTemplate]="rowExpandTemplate"
                           (onRowExpand)="onRowExpandChange($event)"
                           (onRowSelectChange)="onRowSelect($event)"
                           (onRowUnselectChange)="onRowUnselect($event)"
                           >
    
            <ng-template #membersActionTemplate
                         let-rowData let-localActionPopover="actionPopover">
              <div class="action-template-container">
                <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                            [text]="action.name"
                            (onClick)="action.action(rowData); localActionPopover.hide();"></app-button>
              </div>
            </ng-template>
    
            <ng-template #rowExpandTemplate
                         let-rowData>
              <app-server-grid #chaseGrid [configuration]="chasesGridConfiguration"
                               [(request)]="chasesGridRequest"
                               [refresh]="refreshChaseGrid"
                               [(selection)]="chasesGridSelection"
                               (dataLoaded)="gridDataLoaded($event)"
                               [actions]="chaseActions"
                               [actionTemplate]="chaseActionTemplate">
    
                <ng-template #chaseActionTemplate
                             let-rowData let-localActionPopover="actionPopover">
                  <div class="action-template-container">
                    <app-button *ngFor="let action of chaseActions; trackBy: trackByIndex"
                                [text]="action.name"
                                (onClick)="action.action(rowData); localActionPopover.hide();"></app-button>
                  </div>
                </ng-template>
              </app-server-grid>
            </ng-template>
    
            <ng-template #filterForm
                         let-form>
              <app-tab-menu orientation="left">
    
                <app-tab-panel header="Member ID">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form"
                                  [model]="memberIdInput"></form-factory>
                  </div>
                </app-tab-panel>
    
                <app-tab-panel header="Name">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form"
                                  [model]="memberNameInput"></form-factory>
                  </div>
                </app-tab-panel>
    
                <app-tab-panel header="Gender">
                  <div class="ui-g-12 ui-md-12 ui-lg-12">
                    <form-factory [formGroup]="form"
                                  [model]="genderInput"></form-factory>
                  </div>
                </app-tab-panel>
              </app-tab-menu>
            </ng-template>
    
          </app-server-grid>
        </div>
      </div>
    </div>
  </div>
</app-detail-main-template>

<app-modal [(visible)]="isQueueModalVisible" header="QUEUE PRIORITIES">
  <form>
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <form-factory [formGroup]="queueFormGroup" [model]="projectInput"></form-factory>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button"
                text="UPDATE QUEUE"
                (onClick)="onUpdateQueue()"
                [disabled]="!hasSelectedProjectId"></app-button>
  </footer>
</app-modal>

<app-chase-assignment-modal [(visible)]="isAssignToChasesModalVisible"
                            (onHide)="closeModal()"
                            (onUpdate)="gridRefresh()"
                            [(chases)]="selectedChaseIdsForAssignment"
                            [isUnassignEnabled]="isUnassignEnabled"></app-chase-assignment-modal>
