import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { List } from "immutable";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MenuItem } from "../../../../shared/menu/menu-item.model";
import { AnalyticsRequest } from "../../analytics/analytics-request.model";
import { AnalyticsService } from "../../analytics/analytics.service";
import { LookerDashboardType } from "../../analytics/looker-dashboard-type.enum";
import { ClinicalService } from "../clinical.service";

@Component({
    selector: "app-chp-myscore",
    templateUrl: "./chp-myscore.component.html",
    styleUrls: ["./chp-myscore.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChpMyScoreComponent implements OnInit {
    isVisible = false;
    lookerUrl: string;
    menuItems = List<MenuItem>();

    constructor(
        private analyticsService: AnalyticsService,
        private clinicalService: ClinicalService,
        private authService: AuthService,
        private automapper: AutomapperService,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.getUserFirstLastName();
        this.getLookerUrl();
    }

    private getLookerUrl(): void {
        const analyticsRequest = new AnalyticsRequest({
            dashboardType: LookerDashboardType.ChpMyScore,
        });

        this.analyticsService.getLookerUrl(analyticsRequest).subscribe(data => {
            this.isVisible = true;
            this.lookerUrl = data;
            this.changeDetector.markForCheck();
        });
    }

    private getUserFirstLastName(): void {
        this.clinicalService.getUserFirstLastName(this.authService.username).subscribe(result => {
            const userName = `${result.firstName} ${result.lastName}`;
            this.getMenuItems(userName);
        });
    }

    private getMenuItems(description: string): void {
        const menuItems = [
            {
                description,
                path: "/clinical/chp/myscore",
            },
        ];

        of(menuItems).pipe(
            map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
        ).subscribe(data => {
            this.changeDetector.markForCheck();
            this.menuItems = List(data);
        });
    }
}
