<app-modal [(visible)]="isCreateVisible" header="ASSIGN TO : Field Techs" (visibleChange)="visibleChange($event)">
  <div>
    <div class="assignment-headers">Notes</div>
    <div class="ellipsis notes" *ngIf="isNoteError">Notes is required and should be between 4 - 1000 characters.</div>
    <textarea class="notestextarea" [(ngModel)]="notesText" (keyup)="onChange($event)"></textarea>
  </div>

  <footer>
    <app-button class="header-button" text="ASSIGN" (onClick)="saveAssignNotes()"></app-button>
  </footer>

</app-modal>
