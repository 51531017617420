import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { PpcDates } from "./ppc-dates.model";

@Injectable({
  providedIn: "root",
})
export class PpcService {
  private subscription: Subscription;
  ppc = new BehaviorSubject<PpcDates>(new PpcDates());

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }

  getCalculations(chaseId: number): Subscription {
    const url = `${this.baseApiUrl}ppc/getcalculatedfields?chaseId=${chaseId}`;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = timer(100).pipe(
      switchMap(() => this.http.get(url)),
      map(this.automapper.curry("PpcCalculatedFields", "PpcDates"))
    ).subscribe(ppc => this.ppc.next(ppc));
    return this.subscription;
  }
}
