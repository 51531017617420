import { Location } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, pairwise, takeUntil, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { TagType } from "../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { SearchService } from "../search.service";

@Component({
  selector: "platform-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  searching = false;
  searchFormGroup: FormGroup;
  entityInput: Dropdown;
  globalTagSearchInput: TagSearchMultiselect;
  globalSearchInput: Textbox;
  isShowSearchIcon = true;
  selectedEntityValue = TagType.CHASE;
  tagSearchActive = false;
  tagIconClass = "--tag-icon ";
  selectedEntityName = "Chase";
  defaultSelectedEntityName = "Chase";
  isGlobalSearch = false;
  previousRoute: string;
  searchTerm: any;
  oldSearchValue: any;
  pTagSearchText: string;
  overlayPanelVisible = false;
  tagsSearchOperatorInput: Dropdown;
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  sink = new SubSink();
  searchTextChanged = new Subject<string>();

  constructor(
    private readonly formService: FormService,
    private readonly tagService: TagService,
    private readonly changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private location: Location
  ) { }

  ngOnInit() {
    this.initializeGlobalSearchForm();
    this.getEntityType();
    this.searchService.searching
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searching => this.searching = searching);

    this.route.firstChild.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.isGlobalSearch = data.isGlobalSearch;
      });

    this.route?.firstChild?.firstChild?.paramMap
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        const category = StringHelper.toTitleCase(params.get("category"));
        this.selectedEntityName = StringHelper.isAvailable(category) ? category : "Chase";
        this.searchTerm = params.get("term");
      });

    this.sink.add(
        this.searchTextChanged
            .pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(searchText => this.getAllTagsOnSelectedEntity(searchText)))
            .subscribe()
        );
  }

  private initializeGlobalSearchForm(): void {
    this.entityInput = new Dropdown({
      key: "entityInput",
    });
    this.tagsSearchOperatorInput = new Dropdown({
      key: "tagsSearchOperatorInput",
    });
    this.globalSearchInput = new Textbox({
      key: "globalSearchInput",
      placeholder: "Global Search",
    });
    this.globalTagSearchInput = new TagSearchMultiselect({
      key: "globalTagSearchInput",
      placeholder: "Global Tag Search",
      maxSelectedLabels: 3,
      useFilter: false,
    });

    this.searchFormGroup = this.formService.createFormGroup([this.entityInput, this.tagsSearchOperatorInput, this.globalSearchInput, this.globalTagSearchInput]);
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  private getEntityType(): void {
    const result = [
      new SelectableInput({ text: "Chase", value: 1 }),
      new SelectableInput({ text: "Address", value: 2 }),
      new SelectableInput({ text: "Document", value: 3 }),
    ];
    if (!this.tagSearchActive) {
      result.pop();
    }
    this.entityInput = new Dropdown({ ...this.entityInput, options: result } as any);
    this.changeDetector.markForCheck();
  }

  private getAllTagsOnSelectedEntity(searchText: string): void {
    this.tagService.getAllTagsList(this.selectedEntityValue, null, searchText)
      .subscribe(options => {
        this.globalTagSearchInput.options = [...options];
        this.checkTagDataAvailable();
        this.changeDetector.markForCheck();
      });
  }

  getTagIcon(): string {
    return "fa fa-tag";
  }

  onTagIconClick(): void {
    this.tagSearchActive = !this.tagSearchActive;
    this.isShowSearchIcon = true;
    this.searchFormGroup.reset();
    this.getEntityType();
    this.selectedEntityValue = TagType.CHASE;
    this.selectedEntityName = this.defaultSelectedEntityName;
    if (this.tagSearchActive) {
      this.getTagSearchOperator();
    }
  }

  getTagIconClass(): string {
    return this.tagIconClass = this.tagSearchActive ? "active" : "";
  }

  getEntityTypeValue(event: any): void {
    this.selectedEntityValue = event.value;
    this.isShowSearchIcon = true;
    this.searchTerm = null;
    this.globalTagSearchInput.options = [];
    if (!this.tagSearchActive) {
      this.searchFormGroup.get(this.globalSearchInput.key).setValue("");
    } else {
      this.searchFormGroup.get(this.globalTagSearchInput.key).setValue("");
      this.searchFormGroup.get(this.tagsSearchOperatorInput.key).setValue("OR");
      this.getTagSearchOperator();
    }
    switch (this.selectedEntityValue) {
      case TagType.MASTERDOCUMENTSOURCE:
        this.selectedEntityName = "Address";
        break;

      case TagType.DOCUMENT:
        this.selectedEntityName = "Document";
        break;

      case TagType.CHASE:
        this.selectedEntityName = "Chase";
        break;
      default:
        return;
    }

  }

  onInputSearchText(event: any): void {
    this.searchTerm = event.text;
  }

  resetSearchInput(): void {
    this.searchFormGroup.reset();
    this.searchService.setSearchTagIds(null);
    this.isShowSearchIcon = true;
    if (!this.tagSearchActive) {
      this.searchService.searching.next(false);
      if (!this.hasPreviousRoute) {
        this.previousRoute = this.getPreviousRoute();
      }

      this.router.navigateByUrl(this.previousRoute);
      this.previousRoute = "";
      this.changeDetector.markForCheck();
    } else {
      this.selectedEntityValue = TagType.CHASE;
      this.selectedEntityName = this.defaultSelectedEntityName;
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.searchFormGroup.get(this.globalTagSearchInput.key).setValue("");
      this.location.back();
    }
  }

  search(): void {
    if (this.tagSearchActive) {
      this.onGlobalTagSearchForSelectedValues();
    }
    this.searchTerm = !this.tagSearchActive ? this.searchFormGroup.get("globalSearchInput").value : this.searchTerm;
    if (!StringHelper.isAvailable(this.searchTerm)) {
      return;
    }
    const category = this.selectedEntityName.trim().toLowerCase();
    if (this.isGlobalSearch && !this.hasPreviousRoute) {
      this.previousRoute = this.getPreviousRoute();
    }
    if (!this.tagSearchActive) {
      const term = (this.searchTerm || "").trim().toLowerCase();
      this.router.navigate(["/search", category, term]);
    } else {
      if (this.searchTerm || !NumberHelper.isGreaterThan(this.searchTerm, 0)) {
        this.router.navigate(["/search/tag/", category, "query"]);
        this.searchService.setSearchTagIds(this.searchTerm);
      }
      this.oldSearchValue = this.searchTerm;
    }
    this.isShowSearchIcon = false;
  }

  private getPreviousRoute(): string {
    const firstRouteName = this.router.url.split("/")[1];
    const isAvailable = StringHelper.isAvailable(firstRouteName) && firstRouteName !== "search";
    return isAvailable ? this.router.url : "/";
  }

  get hasPreviousRoute(): boolean {
    return StringHelper.isAvailable(this.previousRoute);
  }

  onGlobalTagSearchInput(event: any): void {
    this.oldSearchValue = this.searchFormGroup.controls.globalTagSearchInput.value;
    const selectedTagValue = event.target ? event.target.value : event.text;
    this.newTagSearchValues(selectedTagValue);
    if (this.oldSearchValue !== event.value || this.oldSearchValue.text !== event.target.value) {
      this.isShowSearchIcon = true;
    }
  }

  private newTagSearchValues(value: string) {
    this.searchTerm = value;
  }

  onGlobalSearchInput(): void {
    this.searchFormGroup.get(this.globalSearchInput.key).valueChanges.pipe(
      distinctUntilChanged(),
      pairwise()
    ).subscribe(([oldSearchValue, newSearchValue]) => {
      if (oldSearchValue !== newSearchValue) {
        this.isShowSearchIcon = true;
      }
    });
  }

  get isTagDataAvailable(): boolean {
    return this.tagSearchActive;
  }

  checkTagDataAvailable(): void {
    const control = this.searchFormGroup.get(this.globalTagSearchInput.getMasterKey());
    if (this.isTagDataAvailable) {
      control.enable();
    } else {
      control.disable();
    }
    this.globalTagSearchInput.disabled = !this.isTagDataAvailable;
    this.globalTagSearchInput.placeholder = this.isTagDataAvailable ? "Global Tag Search" : "No Tags Available";
  }

  onGlobalTagSearchForSelectedValues(): void {
    if (!ArrayHelper.isAvailable(this.searchFormGroup.controls.globalTagSearchInput.value)) {
      return;
    }

    this.searchTerm = this.searchFormGroup.controls.globalTagSearchInput.value.map(x => x.value).toString();
    if (!StringHelper.isAvailable(this.selectedTagSearchOperator)) {
      this.searchService.setTagSearchOperator(this.defaultTagSearchOperator);
    }
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
  }

  private getTagSearchOperator(): void {
    const result = [
      new SelectableInput({ text: "OR", value: "OR" }),
      new SelectableInput({ text: "AND", value: "AND" }),
    ];
    this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options: result } as any);
    this.changeDetector.markForCheck();
  }

  getSearchOperatorValue(event: any): void {
    this.isShowSearchIcon = true;
    this.selectedTagSearchOperator = event.value;
    this.searchService.setTagSearchOperator(this.selectedTagSearchOperator);
  }

  getClassForSearchContainer(): string {
    return this.tagSearchActive ? "tag-search-active" : "tag-search-inactive";
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }
}
