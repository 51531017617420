import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { Base64 } from "../../utilities/base64";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";

@Injectable({
  providedIn: "root",
})
export class ParameterService {
  constructor(private route: ActivatedRoute) { }


  get(name: string, defaultValue = ""): string {
    const parameterBase64 = this.findParameter(name, this.route.snapshot.root);
    const parameter = Base64.decode(parameterBase64);
    return StringHelper.isAvailable(parameter) ? parameter : defaultValue;
  }

  getNumber(name: string, defaultValue: number | null): number {
    const parameter = +this.get(name);
    return NumberHelper.isAvailable(parameter) ? parameter : defaultValue;
  }

  // OBSOLETE - useful until we obfuscate the urls
  getNormal(name: string, defaultValue = ""): string {
    const parameter = this.findParameter(name, this.route.snapshot.root);
    return StringHelper.isAvailable(parameter) ? parameter : defaultValue;
  }

  // OBSOLETE - useful until we obfuscate the urls
  getNumberNormal(name: string, defaultValue: number | null): number {
    const parameter = +this.getNormal(name);
    return NumberHelper.isAvailable(parameter) ? parameter : defaultValue;
  }


  private findParameter(name: string, route: ActivatedRouteSnapshot): string {
    const value = route.params[name] || route.queryParams[name];
    if (StringHelper.isAvailable(value)) {
      return value;
    }

    const snapshots = route.children;
    if (ArrayHelper.isAvailable(snapshots)) {
      return snapshots.reduce((acc, snapshot) => {
        if (StringHelper.isAvailable(acc)) {
          return acc;
        }

        return this.findParameter(name, snapshot);
      },                      "");
    }

    return "";
  }
}
