<app-collapsible-modal class="catalytic-result" header="EVE VALIDATION OF PRIMARY ABSTRACTOR'S DATA ENTRY" [(visible)]="visible" rejectText="close">
  <div class="text-center text-warning">For handwritten and non-English events, please 'Agree' with Bot results.</div>
  <app-info-item-container [infoItems]="infoItems" class="catalytic-result text-center"></app-info-item-container>
  <app-basic-grid [configuration]="gridConfiguration" class="selection-grid" [data]="catalyticNumerators" [(selection)]="selection"
    (selectionChange)="selectedNumerator()">

    <ng-template #systemColumn let-rowData>
      <member-system-result [matchResult]="rowData.matchResult"></member-system-result>
    </ng-template>
    <ng-template #acceptanceColumn let-rowData>
      <member-acceptance-result [numerators]="rowData" [reviewedByUser]="catalyticResult.reviewedByUser"
        (onSelect)="updateAcceptanceResult($event)"></member-acceptance-result>
    </ng-template>

  </app-basic-grid>
  <footer class="text-center">
    <app-button text="SUBMIT" (onClick)="saveCatalyticResult()" [disabled]="!enableSubmitButton"></app-button>
  </footer>
</app-collapsible-modal>
