import { IAutoMapper } from "../interfaces";

export const mapClinicalMeasureItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ClinicalMeasureListItem")
    .forMember("measureId", o => o.measureId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("measureName", o => o.measureName);
};

