<form class="research-container" [formGroup]="researchForm" *ngIf="displayResearchItems">
  <h3 class="label-container">Research Completed</h3>
  <form-factory
    [formGroup]="checkboxForm"
    [model]="checkboxInput"
    (onChange)="onSelect()">
  </form-factory>
  <form-factory 
    *ngFor="let researchInput of researchInputs; trackBy: trackByIndex"
    [formGroup]="researchForm"
    [model]="researchInput"
    (onChange)="handleChange(researchInput.key)">
  </form-factory>
</form>