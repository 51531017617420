
    <iframe
      *ngIf="visible"
      [src]="source"
      [width]="iframeWidth"
      [height]="iframeHeight"
      frameborder="0"
      allowfullscreen
      allow="microphone">
      </iframe>
  