<div class="container-content">

  <app-provider-header [welcomeText]="welcomeText" [supportText]="supportText" [imagePath]="imagePath"></app-provider-header>

  <div>
    <div id="Iframe-Main-content" class="set-margin set-padding set-border set-box-shadow center-block-horiz">
      <div class="responsive-wrapper page-review" style="-webkit-overflow-scrolling: touch;">
        <app-button class="button position" text="Print" (onClick)="print()"></app-button>
        <pdf-viewer [src]="pdfUrl"
                    [render-text]="true"
                    [show-all]="true"
                    style="display: block;"></pdf-viewer>
      </div>
    </div>
  </div>
</div>
