import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { EmrEditModel } from "./emr-edit-model";

@Injectable()

export class EmrDetailEditService {
  reset = new Subject<any>();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getEmrGdFromPath(): number {
    return this.route.snapshot.firstChild.firstChild.firstChild.params.EmrGd;
  }

  resetEmr(emrId: number) {

    this.reset.next(emrId);
  }

  getEmrDetailsForEdit(): Observable<EmrEditModel> {
    const url = `${this.baseApiUrl}emr/emrDetailsForEdit?emrId=${this.getEmrGdFromPath()}`;

    return this.http.get(url)
      .pipe(
        map(this.automapper.curry("default", "EmrEditModel"))
      );
  }

  updateEmrDetails(emrEditModel: EmrEditModel): Observable<number> {
    const url = `${this.baseApiUrl}emr/UpdateEmrDetails`;

    return this.http
    .post(url, emrEditModel)
    .pipe(
      map((response: any) => response as number));
  }
}
