<div *ngIf="showForNormalMeasures; else: hidingNumeratorGrid" class="compliance-default-grid compliance-specific-grid">
  <app-basic-grid [configuration]="gridConfiguration" [data]="data" [loading]="isLoading">

    <ng-template #exemptColumn let-rowData>
      <member-compliance-exempt [data]="rowData"></member-compliance-exempt>
    </ng-template>

    <ng-template #complianceColumn let-rowData>
      <span title="{{rowData.chaseCompliance.code}}" [ngStyle]="this.getComplianceColor(rowData.chaseCompliance.code)" class="ui-column-value">{{rowData.chaseCompliance.code}}</span>
      <div *ngIf="showAdminComplianceFlag(rowData)" class="compliance-default-grid__admin-compliance-flag" title="Compliance Flag">
        <app-icon iconName="flag-alt"></app-icon>
      </div>
    </ng-template>

  </app-basic-grid>
</div>
<ng-template #hidingNumeratorGrid>
  Scoring is hidden for {{ measureCode }}.
</ng-template>