import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { JobqueueListComponent } from "./jobqueue/jobqueue-list.component";
const routes: Routes = [
  { path: "", component: JobqueueListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsQueueRoutingModule { }
