import { IAutoMapper } from "../../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";

export const mapDiseaseDetail = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "DiseaseDetail")
    .forMember("categoryId", o => o.categoryId)
    .forMember("categoryCode", o => o.categoryCode)
    .forMember("categoryName", o => o.categoryName)
    .forMember("diseaseId", o => o.diseaseId)
    .forMember("icdCode", o => o.icdCode)
    .forMember("diseaseName", o => o.diseaseName)
    .forMember("modelId", o => o.modelId)
    .forMember("modelDisplayName", o => o.modelDisplayName)
    .forMember("modelType", o => o.modelType);
};

export interface IDiseaseDetailOptions {
  categoryId?: number;
  categoryCode?: string;
  categoryName?: string;
  diseaseId?: number;
  icdCode?: string;
  diseaseName?: string;
  modelId?: number;
  modelDisplayName?: string;
  modelType?: string;
}

export class DiseaseDetail {
  categoryId: number;
  categoryCode: string;
  categoryName: string;
  diseaseId: number;
  icdCode: string;
  diseaseName: string;
  modelId: number;
  modelDisplayName: string;
  modelType: string;

  constructor(options: IDiseaseDetailOptions = {}) {
    this.categoryId = options.categoryId;
    this.categoryCode = StringHelper.clean(options.categoryCode);
    this.categoryName = StringHelper.clean(options.categoryName);
    this.diseaseId = options.diseaseId;
    this.icdCode = StringHelper.clean(options.icdCode);
    this.diseaseName = StringHelper.clean(options.diseaseName);
    this.modelId = options.modelId;
    this.modelDisplayName = StringHelper.clean(options.modelDisplayName);
    this.modelType = StringHelper.clean(options.modelType);
  }
}
