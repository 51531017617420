import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";

@Injectable()
export class ChaseDetailCommentsService {
  // TODO: create a shared comments service

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getChaseGdFromPath(): string {
    return this.route.snapshot.parent.params.chaseGd;
  }

  getComments(): Observable<CommentItem[]> {
    // TODO: put into the new service
    const url = `${this.baseApiUrl}Chase/Comments?chaseId=${this.getChaseGdFromPath()}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveComment(commentText: string) {
    // TODO: put into the new service
    const url = `${this.baseApiUrl}Chase/Comment?chaseId=${this.getChaseGdFromPath()}&commentText=${encodeURIComponent(commentText)}`;

    return this.http.post(url, {}).pipe(
      catchError(this.handleError)
    );
  }

  saveChaseComment(chaseId: number, commentText: string) {
    // TODO: put into the new service
    const url = `${this.baseApiUrl}Chase/Comment?chaseId=${chaseId}&commentText=${commentText}`;

    return this.http.post(url, {}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},`,
        `body was: ${error.error}`
      );
    }

    return throwError("Something bad happened; please try again later.");
  }
}
