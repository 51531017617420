import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UserService } from "../../core/user/user.service";


@Component({
  selector: "platform-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  isParentOrg = false;
  constructor(
    private readonly userService: UserService
  ) {

  }
  ngOnInit() {
    this.isParentOrg = this.userService.getUserToken().isParentOrg;
  }
}
