export class DocumentItem {

  constructor(
    readonly documentId: number,
    readonly fileName: string,
    readonly numberOfPages: number,
    readonly createDate: string,
    readonly assignedTo: string,
    readonly notes: string,
    readonly status: string

  ) {

    this.documentId = documentId;
    this.fileName = fileName;
    this.numberOfPages = numberOfPages;
    this.assignedTo = assignedTo;
    this.notes = notes;
    this.createDate = createDate;
    this.status = status;

  }
}
