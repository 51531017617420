import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { List } from "immutable";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { SessionService } from "../../../../../core/storage/session.service";
import { ListItem } from "../../../../../shared/list/list-item";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { Member } from "../../../clinical/member.model";
import { ProjectType } from "../../../project/project-type.enum";
import { RiskService } from "../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../chase-detail/chase-detail.service";

@Component({
  selector: "member-chase-detail-v2-header",
  templateUrl: "./chase-detail-v2-header.component.html",
  styleUrls: ["./chase-detail-v2-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailV2HeaderComponent implements OnInit, OnDestroy {

  private sink = new SubSink();
  chaseDetailState: ChaseDetailState;
  chaseId: number;
  menuItems = List<MenuItem>();
  chaseDetails = List<ListItem>();


  constructor(
    private readonly chaseDetailService: ChaseDetailService,
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly messagingService: MessagingService,
    private menuService: MenuService,
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private riskService: RiskService
  ) { }

  ngOnInit() {
    this.sink.add(
      this.route.paramMap.
        subscribe(params => this.chaseId = +params.get("chaseGd")),

      this.chaseDetailStateService.state
        .subscribe(state => {
          this.chaseDetailState = state;
          this.changeDetector.markForCheck();
        }),

      this.chaseDetailService.chaseDetailsChange
        .subscribe(chaseDetails => {
          this.chaseDetails = List(chaseDetails);
          if (!this.assignedToCurrentUser) {
            this.riskService.isMemberValidate.next(false);
            this.messagingService.showMessage("You are not currently assigned to this Chase.  Certain functions will not be available to you.", SeverityType.WARN);
          }
          this.changeDetector.markForCheck();
        })
    );

  }

  ngOnDestroy() {
    this.chaseDetailStateService.clearData();
    this.sink.unsubscribe();
    this.menuService.showHeader.next(true);
  }

  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  get memberItems(): List<ListItem> {
    let memberKeys = [];

    if (this.isRisk || this.isIVA) {
      memberKeys = ["Status", "Measure", "Review Period", "Client Member ID"];
     } else if (this.chaseDetailState.hasGaps) {
      memberKeys = ["Status", "Measure", "Gaps", "Client Member ID"];
    } else {
      memberKeys = ["Status", "Measure", "Specialty", "Client Member ID"];
    }

    const list = [
      new ListItem({
        key: "Status",
        value: this.chaseDetailState.reportingStatusName,
      }),
      new ListItem({
        key: "Measure",
        value: this.chaseDetailState.measureCode,
      }),
      new ListItem({
        key: "Specialty",
        value:  ArrayHelper.isAvailable(this.chaseDetailState.providers) && this.chaseDetailState.providers[0].providerSpecialty !== "" ? this.chaseDetailState.providers[0].providerSpecialty : "N/A",
      }),
      new ListItem({
        key: "Client Chase Key",
        value: this.chaseDetailState.chaseSourceAliasId,
      }),
      new ListItem({
        key: "Project",
        value: this.chaseDetailState.hasProject ? this.chaseDetailState.project.projectName : "",
      }),
      new ListItem({
        key: "Review Period",
        value: this.chaseDetailState.hasProject ? this.chaseDetailState.project.projectDuration : "",
      }),
    ];

    if (this.chaseDetailState.numeratorList) {
      list.push(
        new ListItem({
          key: "Gaps",
          value: this.chaseDetailState.numeratorList,
        })
      );
    }

    const memberItems = list
      .filter(item => memberKeys.includes(item.key))
      .sort((a, b) => {
        const aValue = memberKeys.findIndex(key => key === a.key);
        const bValue = memberKeys.findIndex(key => key === b.key);
        return aValue - bValue;
      });

    return List(memberItems);
  }


  get personalmemberItems(): List<ListItem> {
    let memberKeys = [];

    if (this.isRisk || this.isIVA) {
      memberKeys = ["Member Name", "DOB", "Age", "Sex"];
     } else if (this.chaseDetailState.hasGaps) {
      memberKeys = ["Member Name", "DOB", "Age", "Sex"];
    } else {
      memberKeys = ["Member Name", "DOB", "Age", "Sex"];
    }

    const list = [
      new ListItem({
        key: "DOB",
        value: this.chaseDetailState.hasMember ? DateHelper.format(this.chaseDetailState.member.memberDateOfBirth, "MM/DD/YYYY") : "",
      }),
      new ListItem({
        key: "Age",
        value: this.chaseDetailState.hasMember ? this.chaseDetailState.member.memberAgeByMeasureYear.toString() : "",
      }),
      new ListItem({
        key: "Sex",
        value: this.chaseDetailState.hasMember ? this.chaseDetailState.member.memberGender : "",
      }),
      new ListItem({
        key: "Member Name",
        value: this.chaseDetailState.hasMember ? this.getMemberName(this.chaseDetailState.member) : "",
      }),
    ];

    const memberItems = list
      .filter(item => memberKeys.includes(item.key))
      .sort((a, b) => {
        const aValue = memberKeys.findIndex(key => key === a.key);
        const bValue = memberKeys.findIndex(key => key === b.key);
        return aValue - bValue;
      });

    return List(memberItems);
  }

  goBack(): void {
    const prevUrl = this.sessionService.get("chase_prev_url", null);
    if (prevUrl != null) {
      this.sessionService.delete("chase_prev_url");
      this.router.navigateByUrl(prevUrl);
    } else {
      this.router.navigateByUrl("user/loginLanding");
    }
  }

  private getMemberName(member: Member): string {
    return StringHelper.isAvailable(member.memberLastName) ? `${member.memberLastName}, ${member.memberFirstName}`
      : member.memberLastName;
  }

  get chaseText() {
    return `${this.chaseId}`;
  }

  get projectType(): ProjectType {
    return this.chaseDetailState.projectTypeId;
  }

  get isRisk(): boolean {
    return this.projectType === ProjectType.RISK;
  }

  get isIVA(): boolean {
    return this.projectType === ProjectType.IVA;
  }

  get projectName(): string {
    return this.chaseDetailState?.project?.projectName;
  }

  get lineOfBusiness(): string {
    return this.chaseDetailState?.hccModelLineOfBusiness;
  }

  get codingModel(): string {
    return this.chaseDetailState?.hccModelDisplayName;
  }
}
