<div class="container-main">
  <app-banner-statistics [bannerStatistics]="addressStatistics">
  </app-banner-statistics>
  <app-special-handling *ngIf="isSpecialHandling" [specialHandlingReason]="specialHandlingReason" [specialHandlingAdditionalNote]="specialHandlingAdditionalNote">
  </app-special-handling>
  <retrieval-address-detail-header-new></retrieval-address-detail-header-new>

  <app-sub-menu [menuItems]="menuItems"></app-sub-menu>

  <div class="button-container">
    <app-button class="button-container__button"
                text="ADD NOTE" *ngIf="hasCallNotesButton"
                (onClick)="showCallNotes()"></app-button>

    <app-button class="button-container__button"
                text="NEXT ADDRESS" *ngIf="showNextAddress"
                (onClick)="getNextAddress()"></app-button>

    <app-button class="button-container__button"
                [text]="('CALL ') + (selectedPhoneNumber)"
                (onClick)="toggleDialpad(false)"
                [disabled]="disableCall"></app-button>

    <app-button class="button-container__button"
                styleType="mrcs-icon"
                type="mrcs-icon"
                icon="dialpad"
                (onClick)="toggleDialpad(true)"
                [disabled]="disableCall"></app-button>
  </div>

  <app-call-notes [(visible)]="showCallNotesModal"></app-call-notes>

  <div class="route-container" [ngClass]="mainWidthClass">
    <router-outlet></router-outlet>
  </div>

  <div class="dialpad-container" *ngIf="showCallFlowAndDialpad">
    <app-call-flow #callflowComponent [showCallFlowAndDialpad]="showCallFlowAndDialpad"
                   [freeFormDialpadOnly]="freeFormDialpadOnly"
                   [callType]="callType"
                   [providerDetails]="providerDetails"
                   (onHide)="closeCallflow()"
                   (onCallConnection)="onCallConnection($event)"
                   (onCallNotesSave)="onCallNotesSaved()"
                   [hasRouteShParameter]="hasRouteShParameter">
    </app-call-flow>
  </div>


  <div class="timeline-container"
       [ngClass]="timelineWidthClass">
    <app-button *ngIf="!showCallFlowAndDialpad && !isTimelineTab"
                [text]="timelineBtnTextValue"
                (onClick)="toggleTimeline()"
                [ngClass]="timelineBtnClass"></app-button>
    <app-address-detail-info-timeline [addressId]="addressId"></app-address-detail-info-timeline>
  </div>

  <app-confirmation-modal class="navigationConfirmationModal"
                          [(visible)]="showConfirmChangeNavigationToNonAidPage"
                          header="WARNING"
                          (onYes)="continueNavigation()"
                          (onCancel)="cancelNavigation()"
                          acceptText="CONTINUE"
                          rejectText="CANCEL">
    Warning : Navigating away from this page will disconnect your call.
    <br />
    Are you sure you want to leave this page?
    <br />
  </app-confirmation-modal>

</div>
