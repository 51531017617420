import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-training-feedback",
  templateUrl: "./training-feedback.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFeedbackComponent {
  filters: string[];
  dashboardType = LookerRiskDashboardType.TRAINNIG_FEEDBACK_REPORT;
  constructor() {
    this.filters = ["Projects", "Products", "LOB", "RetrievalOwner"];
  }

}
