import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { WorkflowStatus } from "../../../../../../api/workflow/worklow-status.enum";
import { ChaseDetailInfoPursuitItemModel } from "./chase-detail-info-pursuit-item-model";

@Component({
  selector: "app-chase-detail-info-pursuit-item",
  templateUrl: "./chase-detail-info-pursuit-item.component.html",
  styleUrls: ["./chase-detail-info-pursuit-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDetailInfoPursuitItemComponent {
  @Input() pursuitItem: ChaseDetailInfoPursuitItemModel;

  constructor() { }

  get chaseId(): number {
    return this.pursuitItem.chaseId;
  }

  get pursuitType(): string {
    return this.pursuitItem.pursuitType;
  }

  get workflowStatusId(): number {
    return this.pursuitItem.workflowStatusId;
  }

  get url(): string {
    switch (this.pursuitItem.workflowStatusId) {
      case WorkflowStatus.MRR:
        return `/members/mrr/chase/${this.chaseId}`;
      case WorkflowStatus.Overread:
        return `/members/or1/chase/${this.chaseId}`;
      case WorkflowStatus.Overread2:
        return `/members/clientoverread/chase/${this.chaseId}`;
      default:
        return `/members/chase/${this.chaseId}`;
    }
  }
}
