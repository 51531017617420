import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable, Subject, interval } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { SessionService } from "../../../../../../core/storage/session.service";
import { CacheTracker } from "../../../../../../shared/document/document-page-viewer/cache-tracker.model";
import { DocumentPageViewerComponent } from "../../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { DocumentThumbnailState } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-state.model";
import { DocumentThumbnailType } from "../../../../../../shared/document/document-page-viewer/document-thumbnail-type.enum";
import { SourceDocumentType } from "../../../../../../shared/document/document-page-viewer/source-document-type.enum";
import { DocumentPageService } from "../../../../../../shared/document/document-page.service";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DocumentPage } from "../../../../retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../../../retrieval/retreival-document-review/document-pages.models";
import { RetrievalDocumentServiceService } from "../../../../retrieval/retrieval-document-service.service";
import { ChaseDetailState } from "../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail/chase-detail-state.service";
import { DocumentViewerSessionService } from "../../document-viewer-session.service";

@Component({
  selector: "app-chase-document-page-thumbnails",
  templateUrl: "./chase-document-page-thumbnails.component.html",
  styleUrls: ["./chase-document-page-thumbnails.component.scss"],
})
export class ChaseDocumentPageThumbnailsComponent implements OnInit, OnChanges, OnDestroy {
  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  @Input() chaseId: number; // TODO: Remove when you verify it's not needed.
  @Input() currentChartPageNumber: number;
  @Input() isMaximized = false;
  @Input() documentPageIds: number[] = [];
  @Input() isViewChanged = false;

  @Output() toggleThumbnailViewClicked = new EventEmitter<{ view: string; pageNumber: number }>();

  @ViewChild(CdkVirtualScrollViewport, { static: true }) private thumbnailViewport: CdkVirtualScrollViewport;

  readonly AUTHENTICATION_KEY = "authentication";
  private cacheTracker: CacheTracker;
  private currentThumbnailNumber$ = new BehaviorSubject<number>(0);
  private sink = new SubSink();
  private state: DocumentThumbnailState;
  readonly itemSize = 130;
  isBucketFolderChecked = false;
  chaseDetailState: ChaseDetailState;
  clearCache = false;
  currentDocQueueId: number = null;
  currentMemberId: number;
  currentSpineColorIndex = 0;
  fromThumbnailClicked = false;
  isFromToggledView = false;
  numberOfDistinctDocQueueId = 0;
  scrollToIndex = 0;
  selectedThumbnail: number;
  cdnBucketFolders = [];
  skipCheck = false;
  skipLoading = false;
  private currentPageNumber = 0;
  constructor(
    private changeDetector: ChangeDetectorRef,
    private chaseDetailStateService: ChaseDetailStateService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private retrievalDocumentService: RetrievalDocumentServiceService,
    private sessionService: SessionService,
    private documentPageService: DocumentPageService,
    private zone: NgZone
  ) { }

  private scrollIndexChange$: Subject<number> = new Subject();

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }
  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get currentThumbnailNumber(): number {
    return this.state.currentThumbnailNumber;
  }
  set currentThumbnailNumber(value: number) {
    this.state.currentThumbnailNumber = value;
    this.changeDetector.markForCheck();
  }

  get currentDocumentQueueId(): number {
    return this.state.currentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    this.state.currentDocumentQueueId = value;
    this.changeDetector.markForCheck();
  }

  get hasCurrentPage(): boolean {
    return this.state.hasCurrentPage;
  }

  get totalPages(): number {
    return this.state.totalPages;
  }

  get allThumbnails(): any[] {
    const statePages = [...this.state.currentPages];
    return [...statePages];
  }

  get currentThumbnailElement(): HTMLElement {
    return document.getElementById(`thumbnail${this.currentThumbnailNumber}`);
  }

  intervallTimer = interval(1000);
  ngOnInit() {
    const user = this.sessionService.get<any>(this.AUTHENTICATION_KEY, {});
    this.state = new DocumentThumbnailState();
    this.cacheTracker = new CacheTracker();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        if (!NumberHelper.isAvailable(this.currentMemberId)) {
          this.currentMemberId = this.chaseDetailState.member?.memberId;
        } else if (this.currentMemberId !== this.chaseDetailState.member?.memberId) {
          if (user.enableThumbnailView) {
            this.initialThumbnailLoad();
          }
          this.currentMemberId = this.chaseDetailState.member?.memberId;
          this.changeDetector.detectChanges();
        }
      })
    );

    if (user.enableThumbnailView) {
      this.initialThumbnailLoad();
    }

    this.sink.add(
      this.scrollIndexChange$.pipe(
        filter(v => NumberHelper.isAvailable(v)),
        debounceTime(500)
      ).subscribe(index => this.updatePageAndIndex(index)),
      this.documentPageService.chartPageChange$.pipe(
        filter(pageNumber => NumberHelper.isAvailable(pageNumber)),
        distinctUntilChanged(),
        debounceTime(50)
      ).subscribe(pageNumber => {
        // Need to substract 1 since the array starts at 0 but the pages start at 1
        const actualPageNumber = pageNumber - 1;
        this.thumbnailViewport.scrollToIndex(actualPageNumber, "smooth");
        this.changeDetector.markForCheck();
      })
    );
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentChartPageNumber) {
      if (changes.currentChartPageNumber.currentValue !== changes.currentChartPageNumber.previousValue) {
        this.currentThumbnailNumber$.next(changes.currentChartPageNumber.currentValue);
        this.selectedThumbnail = changes.currentChartPageNumber.currentValue;
        this.changeDetector.markForCheck();
      }
    }
  }

  updateCurrentPage(pageNumber: number): void {
    this.fromThumbnailClicked = true;
    this.currentThumbnailNumber = pageNumber;
    this.selectedThumbnail = pageNumber;
    this.documentViewerSessionService.updateDataEntryPageNumber(pageNumber);
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page != null && StringHelper.isAvailable(page.source);
  }

  hasThumbnailImageUrl(thumbnail: DocumentPage): boolean {
    return StringHelper.isAvailable(thumbnail.image);
  }

  isThumbnailSelected(thumbnailNumber: number): boolean {

    return this.selectedThumbnail === thumbnailNumber;
  }

  updateIndex(index): void {
    this.scrollIndexChange$.next(index);
  }

  private updatePageAndIndex(index: number): void {
    if (!this.isFromToggledView) {
      if (index !== this.currentPageNumber) {
        const pageNumber = index + 1;
        this.currentThumbnailNumber = pageNumber;
        this.state.currentThumbnailNumber = pageNumber;
        this.currentPageNumber = pageNumber;
        this.tryLoading2();
      }
    } else {
      this.thumbnailViewport.scrollToIndex(this.scrollToIndex - 1);
      this.isFromToggledView = false;
    }
  }

  getThumbnailSource(thumbnail: DocumentPage): string {
    return this.hasThumbnailImageUrl ? (thumbnail.isCdnChartImageExist ? thumbnail.source.replace("data:image/jpg;base64,", "")
      : thumbnail.source) : "";
  }

  toggleThumbnailView(view: string): void {
    this.toggleThumbnailViewClicked.emit({ view, pageNumber: this.currentThumbnailNumber });
  }

  isDocumentThumbnail(thumbnail: DocumentPage): boolean {
    return thumbnail.documentThumbnail.thumbnailType === DocumentThumbnailType.THUMBNAIL_IMAGE;
  }

  trackByIndex(index, item) {
    return index;
  }


  private initialThumbnailLoad(): void {
    const pagesToLoad = [1, 15];
    pagesToLoad.forEach(pageNumber => this.state?.setPage({ pageNumber } as any));
    this.load(pagesToLoad)
      .subscribe(thumbnailPages => {
        const length = (thumbnailPages && ArrayHelper.isAvailable(thumbnailPages.pages)) ? thumbnailPages.pages[0].numberOfPages : 0;
        this.cacheTracker = new CacheTracker();
        // Set Pages
        this.currentDocumentQueueId = thumbnailPages.pages[0]?.documentQueueId;
        const thumbnailPagesLength = thumbnailPages.pages.length;
        const emptyArray = new Array(length - thumbnailPagesLength).fill(new DocumentPage());
        const pagestToSet = [...thumbnailPages.pages, ...emptyArray];
        this.setPages(pagestToSet);
        if (NumberHelper.isGreaterThan(this.selectedThumbnail, 1)) {
          this.isFromToggledView = true;
        } else {
          this.currentThumbnailNumber = 1;
          this.thumbnailViewport.scrollToIndex(0);
        }
        this.changeDetector.markForCheck();
        this.isBucketFolderChecked = (thumbnailPages && thumbnailPages.isCdnBucketFolderChecked) ? thumbnailPages.isCdnBucketFolderChecked : false;
        this.getCdnImages(thumbnailPages);
        this.skipCheck = true;
      });
  }

  getCdnImages(documentPages: DocumentPages) {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  private load(pages: number[]): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    return this.retrievalDocumentService.getDocumentThumbnails(this.cleanDocumentId, SourceDocumentType.DocumentThumbnail, begPage, endPage, null, this.isBucketFolderChecked,
                                                               null, true, false, null, this.cdnBucketFolders);
  }

  private setPages(pages: DocumentPage[]): void {
    pages.forEach(this.setPage.bind(this));
    this.state.currentPages = pages;
    this.changeDetector.markForCheck();
  }

  private setPage(page: DocumentPage): void {
    if (StringHelper.isAvailable(page?.source)) {
      const time = performance.now();
      page.time = time;
      this.cacheTracker.add(time);

      if (this.currentDocumentQueueId !== page.documentQueueId) {
        this.currentSpineColorIndex++;
        this.currentDocumentQueueId = page.documentQueueId;
      }

      if (page.documentThumbnail) {
        const colorIndex = this.currentSpineColorIndex % page.documentThumbnail.thumbnailColors.length;
        page.documentThumbnail.thumbnailSpineColor = page.documentThumbnail.thumbnailColors[colorIndex].spine;
        page.documentThumbnail.thumbnailBackgroundColor = page.documentThumbnail.thumbnailColors[colorIndex].background;
        page.documentThumbnail.thumbnailType = DocumentThumbnailType.THUMBNAIL_IMAGE;
      }
    }
  }

  private subsequentLoad(pagesToLoad: number[]): void {
    if (this.skipCheck || (this.currentThumbnailNumber > 0 && this.totalPages > 2)) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.skipCheck || (this.state.shouldLoadMoreThumbnails && !this.clearCache)) {
            this.skipCheck = false;
            pagesToLoad.forEach(pageNumber => this.setPage({ pageNumber } as any));
            this.load(pagesToLoad)
              .subscribe(thumbnailPages => {
                this.getCdnImages(thumbnailPages);
                this.setPages(thumbnailPages.pages);
                this.cleanCache();
                pagesToLoad[0] += 14;
                pagesToLoad[1] += 14;
                const loadPages = pagesToLoad[0] === this.totalPages ? [this.totalPages] : pagesToLoad;
                this.subsequentLoad(loadPages);
                if (!(this.changeDetector as any).destroyed) {
                  this.changeDetector.detectChanges();
                }
              });
          }
        })
      );
    } else if (this.currentThumbnailNumber > 0 && this.totalPages === 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMoreThumbnails && !this.clearCache) {
            this.setPage(2 as any);
            this.load([2])
              .subscribe(thumbnailPages => {
                this.getCdnImages(thumbnailPages);
                this.setPages(thumbnailPages.pages);
                this.cleanCache();
                this.changeDetector.detectChanges();
              });
          }
        })
      );
    }
  }

  private cleanCache(): void {
    if (this.cacheTracker.isLimitReached) {
      this.zone.runOutsideAngular(() => {
        const minTime = this.cacheTracker.currentTime;
        setTimeout(() => {
          for (let i = 0; i < this.pages.length; ++i) {
            if (this.pages[i] != null && this.pages[i].time < minTime) {
              this.pages[i] = null;
            }
          }
        });
      });
    }
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });
  }

  private isInRange(pageNumber: number, lowerRange: number, upperRange: number, array: DocumentPage[], prop: string): boolean {
    const lowerSlice = array.slice(pageNumber - lowerRange, pageNumber);
    const upperSlice = array.slice(pageNumber, pageNumber + upperRange);
    const section = [...lowerSlice, ...upperSlice];
    const hasElement = section.every(el => StringHelper.isAvailable(el[prop]));
    return !hasElement;
  }

  getPagesToLoad(pageNumber: number, lowerRange: number, upperRange: number, array: DocumentPage[], prop: string): number[] {
    const pages = [];
    Array.from(array).forEach((page, index) => {
      if (NumberHelper.isBetween(index, (pageNumber - lowerRange), (pageNumber + upperRange)) && !StringHelper.isAvailable(page[prop])) {
        pages.push(index + 1);
      }
    });
    return pages;
  }

  private tryLoading2(): void {
    const shouldLoad = this.isInRange(this.currentPageNumber, 10, 10, this.state.currentPages, "image");
    if (this.state.currentPages.length > 0 && shouldLoad) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          const pagesToLoad = this.getPagesToLoad(this.currentPageNumber, 17, 15, this.state.currentPages, "image");
          if (ArrayHelper.isAvailable(pagesToLoad)) {
            this.load(pagesToLoad).subscribe(thumbnailPages => {
              this.getCdnImages(thumbnailPages);
              thumbnailPages.pages.forEach(page => this.state.currentPages[page.pageNumber - 1] = page);
              this.setPages([...this.state.currentPages]);
            });
          }
          this.changeDetector.markForCheck();
        })
      );
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
