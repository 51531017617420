export class ChaseUnasssignModel {

  chaseList: number[];
  functionalRoleId: number;
  masterDocumentSourceId: number;

  constructor(options: {
    chaseList: number[];
    functionalRoleId: number;
    masterDocumentSourceId: number;
  }) {
    this.chaseList = options.chaseList;
    this.functionalRoleId = options.functionalRoleId;
    this.masterDocumentSourceId = options.masterDocumentSourceId;
  }
}
