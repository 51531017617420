import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-condition-category-qa-report",
  templateUrl: "./condition-category-qa-report.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionCategoryQaReportComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CONDITION_CATEGORY_QA_REPORT;

    constructor() {
      this.filters = ["Projects", "ConditionCategory", "ChaseIds", "CoderId", "CoderName", "OverReaderId", "OverReaderName", "CodingDate", "OverReadDate", "Products", "LOB", "RetrievalOwner"];
    }

}
