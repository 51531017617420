import { WorkflowSubmitResponse } from "../../../platform/api/workflow/workflow-submit-response.model";
import { IAutoMapper } from "../interfaces";

export const mapWorkflowSubmitResponse = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "WorkflowSubmitResponse")
    .forMember("workflowRequestId", o => o.workflowRequestId)
    .forMember("success", o => o.success)
    .forMember("message", o => o.message);

};
