
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-clinical-accuracy-by-measure",
  templateUrl: "./clinical-accuracy-by-measure.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalAccuracyByMeasureComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.CLINICAL_ACCURACY_BY_MEASURE;

    constructor() {
      this.filters = ["Projects", "AbstractionDate"];
    }

}
