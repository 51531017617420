import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { UserService } from "../../../../core/user/user.service";

@Injectable()
export class UrlHelperService {
  constructor(private http: HttpClient, private userService: UserService) {
  }

  get(url: string): Observable<any> {
    const userToken = this.userService.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${userToken.token}`,
        responseType: "text/html",
        Accept: "text/html; charset=utf-8",
      }),
    };

    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http
        .get(url, httpOptions)
        .subscribe(m => {
          objectUrl = m.toString();
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
}
