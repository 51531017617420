export enum BulkAction {

  MoveChases = 1,
  ReassignChases = 2,
  ActivateChases = 3,
  PendChases = 4,
  TagChases = 6,
  RequestDate3rdPartyChases = 7,
  DueDate3rdPartyChases= 8,
  CommitmentDateUpdateChases= 9,
  CopyChartToChase= 10,
}
