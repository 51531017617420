import { NumeratorListItem } from "../../../api/numerator/numerator-list-item.model";
import { ProviderItem } from "../../../api/provider/provider-search-result-item";
import { CreateAddress } from "../../retrieval/address-search/create-address.model";
import { CreateMember } from "./create-new-chase-member/create-member.model";
import { Encounters } from "./encounters.model";
import { MeasureAttributeData } from "./measure-attribute-data.model";

export class NewChase {
  chaseId: number;
  parentChaseId: number;
  chaseKey: number;
  projectId: number;
  memberId?: number;
  addressId?: number;
  providerId?: number;
  projectName: string;
  memberName: string;
  chaseAddress: string;
  providerName: string;
  measureId?: number;
  measureCode: string;
  patientAdmitDate: string;
  patientDischargeDate: string;
  chaseMemberData?: CreateMember;
  chaseAddressData?: CreateAddress;
  chaseProviderData?: ProviderItem;
  riskChaseProviderData?: ProviderItem[];
  measureAttributeData?: MeasureAttributeData[];
  productName: string;
  action: number;
  encounters?: Encounters[];
  encounterCount: number;
  diagnosisCount: number;
  numeratorList: NumeratorListItem[];
  gapsCount: number;
  memberDateOfBirth: string;
  lineOfBusiness?: string;
  sampleId?: string;
  sequence?: number;
  coRetrievalOwner?: string;
  isPursuit: boolean;
  requestType: string;
  constructor() { }
}
