import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { RetrieverUpdateModel } from "./update-retriever-model.model";

@Injectable({
  providedIn: "root",
})
export class UpdateRetrieverModelService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  updateRetrieverModel(updateRetrieverModelModel: RetrieverUpdateModel): Observable<any> {

    const url = `${this.baseApiUrl}retrieval/retriever/update`;

    return this.http.post(url, updateRetrieverModelModel);
  }
}
