import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { ParameterService } from "../../../../core/navigation/parameter.service";
import { ArchiveChaseDetailService } from "./archive-chase-detail.service";

@Component({
  selector: "member-archive-chase-detail",
  templateUrl: "./archive-chase-detail.component.html",
  styleUrls: ["./archive-chase-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveChaseDetailComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private chaseId: number;

  constructor(
    private archiveChaseDetailService: ArchiveChaseDetailService,
    private readonly router: ActivatedRoute,
    private parameterService: ParameterService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.sink.add(
      this.router.paramMap.subscribe(() => {
        this.chaseId = this.parameterService.getNumberNormal("chaseGd", null);
        this.getArchiveChaseDetails();
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.archiveChaseDetailService.clearData();
    this.sink.unsubscribe();
  }

  private getArchiveChaseDetails(): void {
    this.archiveChaseDetailService.getArchiveChaseDetail(this.chaseId).subscribe(data => {
      this.archiveChaseDetailService.setData({
        ...data,
      });
    });
  }
}
