import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class MemberListItem {
  readonly id: number;
  readonly name: string;
  readonly address: string;
  readonly address2: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly dob: Date;
  readonly gender: string;
  readonly providerId: number;
  readonly chases: number;
  readonly marital: string;
  readonly recordCount: number;
  isVerified?: boolean;

  constructor(options: {
    id?: number;
    name?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    dob?: Date;
    gender?: string;
    providerId?: number;
    chases?: number;
    marital?: string;
    recordCount?: number;
    isVerified?: boolean;
  }) {
    this.id = options.id;
    this.name = StringHelper.clean(options.name);
    this.address = StringHelper.clean(options.address);
    this.address2 = StringHelper.clean(options.address2);
    this.city = StringHelper.clean(options.city);
    this.state = StringHelper.clean(options.state);
    this.zip = StringHelper.clean(options.zip);
    this.dob = DateHelper.removeTime(options.dob);
    this.gender = StringHelper.clean(options.gender);
    this.providerId = options.providerId;
    this.chases = options.chases;
    this.marital = StringHelper.clean(options.marital);
    this.recordCount = options.recordCount;
    this.isVerified = options.isVerified;
  }
}
