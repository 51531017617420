import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { CheckboxComponent } from "./checkbox.component";

export interface ICheckboxOptions extends IDynamicInputOptions { }

export class Checkbox extends DynamicInput implements ICheckboxOptions {
  readonly controlType = "checkbox";
  readonly componentType = CheckboxComponent;


  constructor(options: ICheckboxOptions = {}) {
    super(options);
  }
}
