import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-add-diagnosis",
  templateUrl: "./add-diagnosis.component.html",
  styleUrls: ["./add-diagnosis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDiagnosisComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  enabled: boolean;


  constructor(
    private riskService: RiskService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.riskService.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
        const selectedEncounterIsSaved = NumberHelper.isGreaterThan(state.selectedEncounter.entityId, 0);
        this.enabled = state.isEnabled && state.isSelectedEncounterValid && selectedEncounterIsSaved;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  addNewDiagnosis(): void {
    if (this.enabled) {
      this.riskService.addNewDiagnosis();
    }
  }
}
