<div class="data-load">
  <h2 class="data-load__header">Data Load</h2>
  <div *ngIf="dataLoadState.isLoadProcess; else loadProcessComplete" class="data-load__description">The data load page lets you upload new or update
    your project's chase and attribute
    data.<br />To upload new data to a
    new project, choose Full as the load type. To replace existing data with a new upload, choose Admin Refresh as the
    load
    type. To add new data to an existing project, choose Incremental as the load type.</div>
    <ng-template #loadProcessComplete>
      <div class="data-load__description">
        <div class="confirmation-header">{{confirmationHeaderText}}</div>
        <div *ngIf="!dataLoadState.isDelete">A summary of your data load result is shown below. View your
          project chase list
          by clicking on the View Chase List.
          Perform a new load by clicking on Start New Data Load</div>
        <app-button *ngIf="!dataLoadState.isDelete" classes="secondary" class="confirmation-button" text="VIEW CHASE LIST" (onClick)="goToChaseQuery()">
        </app-button>
        <app-button classes="secondary" class="confirmation-button" text="START NEW DATA LOAD" (onClick)="newLoad()">
        </app-button>
      </div>
    </ng-template>  
  <app-data-load-upload *ngIf="dataLoadState.isLoadProcess" class="data-load__upload"></app-data-load-upload>
  <app-data-load-summary *ngIf="dataLoadState.displaySummary" class="data-load__summary"></app-data-load-summary> 
</div>