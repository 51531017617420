
<div [hidden]="hidden" [formGroup]="formGroup" class="control control--input control--multiselect">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <p-multiSelect #primeMultiselect
    class="multiselect-control"
    dropdownIcon="pi pi-caret-down"
    [options]="options"
    [filter]="true"
    [showToggleAll]="false"
    [optionLabel]="optionText"
    [maxSelectedLabels]="maxSelectedLabels"
    [defaultLabel]="model.placeholder"
    filterPlaceHolder="Search"
    [styleClass]="classes"
    [tooltip]="tooltipTitle"
    tooltipPosition="bottom"
    tooltipStyleClass="custom-tooltip"
    dataKey="value"
    (onChange)="itemSelected($event);"
    [id]="model.key"
    [formControlName]="model.key"
    [(ngModel)]="model.selectedOptions"
    (onPanelShow)="onPanelShow.emit($event);"
    (onPanelHide)="onPanelHideSelected();onPanelHide.emit($event);"
    [emptyFilterMessage]="emptyFilterMessage">

    <ng-container>
      <ng-template let-value pTemplate="selectedItems">
        <div>
          {{ selectedOptionsText }}
        </div>
        <div
          *ngIf="!model.selectedOptions || model.selectedOptions.length === 0"
          class="country-placeholder"
        >
          Select...
        </div>
      </ng-template>
    </ng-container>
    
    <p-footer *ngIf="!hasOptions && isSearchMode">
      No results found
    </p-footer>
  </p-multiSelect> 
</div>