import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TabViewModule } from "primeng/tabview";
import { TreeModule } from "primeng/tree";
import { SharedModule } from "../../../shared/shared.module";
import { JobqueueItemComponent } from "./jobqueue-item.component";
import { JobqueueListComponent } from "./jobqueue/jobqueue-list.component";
import { JobsQueueRoutingModule } from "./jobsqueue-routing-module";
import { JobsqueueService } from "./jobsqueue.service";

@NgModule({
  declarations: [JobqueueListComponent, JobqueueItemComponent],
  imports: [
    JobsQueueRoutingModule,
    SharedModule,
    FormsModule, // TODO: Remove when forms are done.
    TreeModule,
    TabViewModule,
  ],
  providers: [JobsqueueService],
  exports: [JobqueueListComponent, JobqueueItemComponent],
})
export class JobsQueueModule { }
