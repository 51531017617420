import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DropdownModule } from "primeng/dropdown";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { PanelModule } from "../panel/panel.module";
import { InvoiceSettingComponent } from "./invoice-setting.component";

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    DynamicFormsModule,
    PanelModule,
    ButtonModule,
  ],
  declarations: [
    InvoiceSettingComponent,
  ],
  exports: [
    InvoiceSettingComponent,
  ],
})

export class InvoiceSettingModule { }
