<div class="member-validation__header">Correct Member?</div>
<app-member-validation-yes-no [isChaseEnabled]="enabled" [configuration]="configuration" (onSelect)="handleChange($event)"></app-member-validation-yes-no>
<div class="member-validation__container" *ngIf="isNo">
  <form-factory class="member-validation__input" [formGroup]="form" [model]="memberValidationReasonInput">
  </form-factory>
  <form-factory class="member-validation__input member-validation__input--page-number" [formGroup]="form" [model]="memberValidationPageNumberInput">
  </form-factory>
  <app-button class="member-validation__submit-btn" classes="small" (onClick)="submit()" text="Submit"
    [disabled]="disableSubmitButton">
  </app-button>
</div>