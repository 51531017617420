import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { OverreadFeedbackDetail } from "./overread-feedback-detail.model";
import { OverreadFeedbackQueryItem } from "./overread-feedback-query-item.model";
import { OverreadFeedbackQuerySearch } from "./overread-feedback-query-search.model";
import { OverreadFeedbackStatusChange } from "./overread-feedback-status-change.model";

@Injectable({
  providedIn: "root",
})
export class OverreadFeedbackService {

  overreadFeedbackListChange = new BehaviorSubject<OverreadFeedbackQueryItem[]>([]);
  overreadFeedbackListChangeObserver$: Observable<OverreadFeedbackQueryItem[]> = this.overreadFeedbackListChange.asObservable();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getOverreadFeedbackDetail(chaseId: number): Observable<OverreadFeedbackDetail[]> {
    const url = `${this.baseApiUrl}overreadfeedback/detail?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "OverreadFeedbackDetail"))
    );
  }

  updateOverreadFeedbackStatus(overreadFeedbackStatusChangeRequest: OverreadFeedbackStatusChange): Observable<null> {
    const url = `${this.baseApiUrl}overreadfeedback/status`;
    return this.http.post(url, overreadFeedbackStatusChangeRequest) as Observable<null>;
  }

  getOverreadFeedbackList(overreadFeedbackQuerySearch: OverreadFeedbackQuerySearch): Observable<OverreadFeedbackQueryItem[]> {
    const url = `${this.baseApiUrl}overreadfeedback/query`;
    return this.http.post(url, overreadFeedbackQuerySearch).pipe(
      map(this.automapper.curryMany("default", "OverreadFeedbackQueryItem"))
    );
  }

  getFeedbackChallengeStatsCount(): Observable<number> {
    const url = `${this.baseApiUrl}overreadfeedback/challenge/count`;
    return this.http.get(url, { observe: "response" }).pipe(
      map((response: HttpResponse<any>): any => response.ok ? response.body : 0));
  }
}
