export class WorkflowSubmitResponse {
  readonly success: boolean;
  readonly message: string;

  constructor(options: {
    success: boolean;
    message: string;

 }) {

    this.success = options.success;
    this.message = options.message;

  }
}

