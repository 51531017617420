import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

@Injectable({
  providedIn: "root",
})
export class ChaseAssignService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getCodersList(chaseId: number | number[]): Observable<SelectableInput[]> {
    const chaseIds = ArrayHelper.isAvailable(chaseId as number[]) ? chaseId : [chaseId];
    const url = `${this.baseApiUrl}clinical/coders?chaseIds=${chaseIds}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("UserModel", "SelectableInput"))
    );
  }

  assignChasesToUser(chaseId: number | number[], userId: number, type: string = null): Observable<any> {
    const chaseIds = ArrayHelper.isAvailable(chaseId as number[]) ? chaseId : [chaseId];
    const baseUrl = `${this.baseApiUrl}clinical/assignchases?userId=${userId}`;
    const url = StringHelper.isAvailable(type) ? `${baseUrl}&type=${type}` : baseUrl;
    return this.http.post(url, chaseIds);
  }

  checkIfChaseAssigned(chaseIds: number[]): Observable<boolean> {
    const url = `${this.baseApiUrl}chase/validate/assigned`;

    return this.http.post(url, chaseIds) as Observable<boolean>;
  }

  getSelectedChaseIds(selectedChases: any[]): number[] {
    if (!ArrayHelper.isAvailable(selectedChases)) {
      throw new Error("No chases selected");
    }

    return selectedChases.map(chase => +chase.chaseId || chase.chaseID);
  }
}
