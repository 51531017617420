import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProgressBarModule as PrimeProgressBarModule } from "primeng/progressbar";
import { ProgressBarUploadComponent } from "./progress-bar-upload/progress-bar-upload.component";
import { ProgressBarComponent } from "./progress-bar.component";


@NgModule({
  imports: [
    PrimeProgressBarModule,
    CommonModule,
  ],
  declarations: [
    ProgressBarComponent,
    ProgressBarUploadComponent,
  ],
  exports: [
    ProgressBarComponent,
    ProgressBarUploadComponent,
  ],
})
export class ProgressBarModule { }
