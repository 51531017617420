<div class="pends-home-container">
  <div class="header bold">PENDS</div>
  <app-detail-header-template>
    <div bottom-right>
      <div>
        <app-internal-pends-change-owner [isChangeOwnerVisible]="isChangeOwnerView" (formClose)="reassignUser($event)" [chasePendIds]="chasePendIds"></app-internal-pends-change-owner>
        <app-internal-pends-request-status-change [isStatusChangeVisible]="isStatusChangeView" (formClose)="updateStatus($event)"  [chasePendIds]="chasePendIds"></app-internal-pends-request-status-change>
      </div>
    </div>
  </app-detail-header-template>

  <app-server-grid [configuration]="gridConfigurationModel"
                   [(request)]="searchRequest"
                   [refresh]="gridRefresh"
                   [(selection)]="serverGridSelection"
                   [filterTemplate]="filterForm"
                   (selectionChange)="selectChangeOwner($event)"
                   [actionTemplate]="actionTemplate">

    <ng-template #actionTemplate let-rowData let-localActionPopover="actionPopover">
      <div class="action-template-container">
        <app-button text="View Details" (onClick)="viewDetails(rowData); localActionPopover.hide();"></app-button>
        <app-button text="Change Owner & Assign"
          *ngIf="rowData.pendStatus !== 'Closed' && rowData.pendStatus !== 'Resolved'"
          (onClick)="changeOwnerAssignDialog(rowData); localActionPopover.hide();"></app-button>
        <app-button text="Change Status" *ngIf="!this.isClientRole && (rowData.pendStatus !== 'Closed')"
          (onClick)="updateStatusDialog(rowData); localActionPopover.hide();"></app-button>
        <app-button text="Request to Close" *ngIf="this.isClientRole && (rowData.pendStatus !== 'Closed')"
          (onClick)="requestStatusChangeDialog(rowData); localActionPopover.hide();">
        </app-button>
      </div>
    </ng-template>

    <ng-template #filterForm let-form>
      <form [formGroup]="form" class="ui-modal-width">
        <app-tab-menu orientation="left">

          <app-tab-panel header="Projects">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Pend Code">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="AID">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Chase Id">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
            </div>
          </app-tab-panel>

          <app-tab-panel header="Owner">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <form-factory [formGroup]="form" [model]="ownerInput"></form-factory>
            </div>
          </app-tab-panel>

        </app-tab-menu>
      </form>
    </ng-template>

  </app-server-grid>
</div>
