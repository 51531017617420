import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { CommentItem } from "../../../../../shared/comments/comment-item/comment-item";

@Injectable()
export class InternalPendsDetailCommentsService {

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private route: ActivatedRoute
  ) { }

  getPendGdFromPath(): string {
    return this.route.snapshot.parent.params.pendGd;
  }

  getComments(): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Pend/Comment?pendId=${this.getPendGdFromPath()}&isOnlyLatest=false`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveComment(commentText: string) {
    const url = `${this.baseApiUrl}Pend/Comment?pendId=${this.getPendGdFromPath()}&commentText=${encodeURIComponent(commentText)}`;

    return this.http.post(url, {})
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an ErrorObservable with a user-facing error message
    return throwError(
      "Something bad happened; please try again later.");
  }


}
