<app-server-grid [configuration]="configuration"
                 [(request)]="request"
                 [(selection)]="selection"
                 [isLoadOnInit]="isLoadOnInit"
                 [refresh]="refreshGrid"
                 [actions]="actions"
                 [filterTemplate]="pendFilterTemplate"
                 [actionTemplate]="pendActionTemplate"
                 [views]="views"
                 [refreshViews]="refreshViews">

  <ng-template #pendActionTemplate let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();"
                  [disabled]="isActionDisabled(rowData.pendStatus,action.name)"></app-button>
    </div>
  </ng-template>

  <ng-template #pendFilterTemplate let-form>
    <form [formGroup]="form" class="ui-modal-width" #pendForm="ngForm">
      <app-tab-menu orientation="left">

        <app-tab-panel class="checkbox-group__halves" header="Projects">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group" header="Pend Codes">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel *ngIf="isClinicalPend" class="checkbox-group__fourths" header="Pend Severity">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendSeverityInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Pend ID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__fourths" header="Measures">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Pend Status">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Assigned To" *ngIf="showAssignedToFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="AID">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Chase Id">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Owner">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="ownerInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Invoice Company">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="companyIdToInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Product">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="productsInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Address">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressGroupNameInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressCityInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressStateInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Sample Compliance">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="Tags">
          <label class="tag-label">Tags</label>
          <div class="tag__filterform">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div class="ui-g-2 ui-md-2 ui-lg-2">
                <form-factory class="tag__operatorform"
                              [formGroup]="form"
                              [model]="tagsSearchOperatorInput"
                              (onChange)="getSearchOperatorValue($event)">
                </form-factory>
              </div>
              <div class="ui-g-10 ui-md-10 ui-lg-10">
                <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                            class="tag-content__item tag-content__item--multitag-close-icon"
                            classes="transparent small"
                            icon="times-circle"
                            (onClick)="resetTagSearchInput()">
                </app-button>
                <form-factory class="tag__panelform"
                              [formGroup]="form"
                              [model]="tagsInput"
                              (onPanelShow)="onShowEvent(true)"
                              (onPanelHide)="onShowEvent(false)"
                              (keyup)="onKeyUp($event)">
                </form-factory>
              </div>
            </div>
          </div>
        </app-tab-panel>
      </app-tab-menu>
    </form>
  </ng-template>

</app-server-grid>






