import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";

export const mapOverreadFeedbackDetail = (automapper: IAutoMapper): void => {
  automapper.createMap("default", "OverreadFeedbackDetail")
    .forMember("chaseOverreadFeedbackId", o => o.chaseOverreadFeedbackId)
    .forMember("chaseId", o => o.chaseId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("entityTypeId", o => o.entityTypeId)
    .forMember("entityTypeName", o => o.entityTypeName)
    .forMember("abstractorUserId", o => o.abstractorUserId)
    .forMember("abstractorUserName", o => o.abstractorUserName)
    .forMember("overreaderUserId", o => o.overreaderUserId)
    .forMember("overreaderUserName", o => o.overreaderUserName)
    .forMember("abstractorData", o => o.abstractorData)
    .forMember("overreaderData", o => o.overreaderData)
    .forMember("status", o => o.status)
    .forMember("abstractorReceiptStatus", o => o.abstractorReceiptStatus)
    .forMember("overreaderReceiptStatus", o => o.overreaderReceiptStatus)
    .forMember("abstractorComment", o => o.abstractorComment)
    .forMember("overreaderComment", o => o.overreaderComment)
    .forMember("managerComment", o => o.managerComment);
};

export class OverreadFeedbackDetail {
  chaseOverreadFeedbackId: number;
  chaseId: number;
  measureCode: string;
  entityTypeId: number;
  entityTypeName: string;
  abstractorUserId: number;
  abstractorUserName: string;
  overreaderUserId: number;
  overreaderUserName: string;
  abstractorData: string;
  overreaderData: string;
  status: string;
  abstractorReceiptStatus: string;
  overreaderReceiptStatus: string;
  abstractorComment: string;
  overreaderComment: string;
  managerComment: string;

  constructor(options: Partial<OverreadFeedbackDetail>) {
    this.chaseOverreadFeedbackId = options.chaseOverreadFeedbackId;
    this.chaseId = options.chaseId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.entityTypeId = options.entityTypeId;
    this.entityTypeName = StringHelper.clean(options.entityTypeName);
    this.abstractorUserId = options.abstractorUserId;
    this.abstractorUserName = StringHelper.clean(options.abstractorUserName);
    this.overreaderUserId = options.overreaderUserId;
    this.overreaderUserName = StringHelper.clean(options.overreaderUserName);
    this.abstractorData = StringHelper.clean(options.abstractorData);
    this.overreaderData = StringHelper.clean(options.overreaderData);
    this.status = StringHelper.clean(options.status);
    this.abstractorReceiptStatus = StringHelper.clean(options.abstractorReceiptStatus);
    this.overreaderReceiptStatus = StringHelper.clean(options.overreaderReceiptStatus);
    this.abstractorComment = StringHelper.clean(options.abstractorComment);
    this.overreaderComment = StringHelper.clean(options.overreaderComment);
    this.managerComment = StringHelper.clean(options.managerComment);
  }
}
