import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommentItem } from "./comment-item";

@Component({
  selector: "app-comment-item",
  templateUrl: "./comment-item.component.html",
  styleUrls: ["./comment-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentItemComponent {
  @Input() commentItem: CommentItem;

}
