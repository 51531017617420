import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-auto-close-report",
  templateUrl: "./auto-close-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCloseReportComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.AUTO_CLOSE_REPORT;

  constructor() {
    this.filters = ["Projects"];
  }
}
