import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from "@angular/core";
import { TimelineItem } from "./timeline-item.model";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  @Input() timelineItems: TimelineItem[] = [];
  @Input() isChaseTimeline = false;
  trackByIndex(index, item) {
    return index;
  }
}
