<div class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Page Number</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="model.readonly"
    [disabled]="model.disabled"
    type="number"
    placeholder="Page Number"
    [(ngModel)]="model.saveInfo.pageNumber"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>

<div [formGroup]="formGroup" class="control control--input control--autocomplete" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label" [for]="model.key" [title]="model.label">{{ model.label | titlecase }}</label>
    <div class="control__header__error" *ngIf="hasError" [title]="error">{{ error }}</div>
  </div>
  <p-autoComplete
    [dropdown]="!model.readonly"
    [forceSelection]="!model.readonly"
    [minLength]="model.searchMinimum"
    field="text"
    dataKey="value"
    [inputStyleClass]="classes"
    appendTo="body"
    [inputId]="model.key"
    [readonly]="model.readonly"
    [placeholder]="model.placeholder"
    [suggestions]="filteredOptions"
    (completeMethod)="filterOptions($event)"
    (onSelect)="onEvent($event)"
    (onKeyUp)="onInput.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
    [formControlName]="model.key"></p-autoComplete>
</div>

<div class="control control--input control--textbox" [hidden]="hidden">
  <div *ngIf="model.hasLabel" class="control__header">
    <label class="control__header__label">Admin Value</label>
  </div>
  <input
    pInputText
    class="control__input control__input--textbox"
    [readonly]="true"
    [disabled]="model.disabled"
    placeholder="Admin Value"
    [ngModel]="model.adminValue"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)" />
</div>

<div class="control control--input control--dropdown" [hidden]="hidden">
  <div class="control__header">
    <label class="control__header__label">Confirm</label>
  </div>
  <p-dropdown
    appendTo="body"
    styleClass="control__input control__input--dropdown"
    [options]="validationOptions"
    placeholder="Confirm"
    [(ngModel)]="model.saveInfo.validationId"
    (ngModelChange)="validationChange()"
    [disabled]="model.disabled"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)">
  </p-dropdown>
</div>

<div class="control control--input control--textarea" [hidden]="hiddenNote">
  <div class="control__header">
    <label class="control__header__label">Note</label>
  </div>
  <textarea pInputTextarea
    class="control__input control__input--textarea"
    [ngStyle]="{ resize: false }"
    [rows]="2"
    placeholder="Write a note..."
    [(ngModel)]="model.saveInfo.validationNote"
    [disabled]="model.disabled || hiddenNote"
    required
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"></textarea>
</div>