<app-server-grid [configuration]="serverGridConfiguration"
                 [(request)]="serverRequest"
                 [(selection)]="selection"
                 [actions]="actions"
                 [isLoadOnInit]="isLoadOnInit"
                 [refresh]="refreshGrid"
                 [filterTemplate]="getFilterForm"
                 [actionTemplate]="actionTemplate"
                 [rowDisabledCondition]="rowDisabledCondition"
                 [hideDisabledRows]="hideDisabledRows"
                 [views]="views"
                 [refreshViews]="refreshViews"
                 [filterHeaderText]="filterHeaderText"
                 [isMemberFilterValid]="isGapFilterValid"
                 [openFilterModalOnFlag]="true">

  <ng-template #filterForm let-form>
    <form [formGroup]="form" class="ui-modal-width" #gapForm="ngForm">
      <app-tab-menu orientation="left">

        <app-tab-panel class="checkbox-group__halves" header="Gaps">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="numeratorInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__halves" header="Health Plans">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="plansInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__fourths" header="Measures">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measureInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__halves" header="Projects" selected="true">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>

      </app-tab-menu>
    </form>
  </ng-template>

  <ng-template #actionTemplate
               let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();"
                  [disabled]="action.disabled"></app-button>
    </div>
  </ng-template>

  <ng-template #chaseIdColumn let-rowData>
    <app-chase-grid-chase-id [data]="rowData"
                             [chaseIdUrl]="chaseIdRouteUrl">
    </app-chase-grid-chase-id>
  </ng-template>

</app-server-grid>
