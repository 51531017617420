import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../dynamic-forms/form.service";
import { Dropdown } from "../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { Textarea } from "../../../dynamic-forms/inputs/textarea/textarea.model";
import { Textbox } from "../../../dynamic-forms/inputs/textbox/textbox.model";
import { WorkflowAction } from "../../../platform/api/workflow/workflow-action.enum";
import { WorkflowSubmitRequest } from "../../../platform/api/workflow/workflow-submit.model";
import { WorkflowStatus } from "../../../platform/api/workflow/worklow-status.enum";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { DocumentRequestService } from "../document-request.service";

@Component({
  selector: "app-hold-document-form",
  templateUrl: "./hold-document-form.component.html",
  styleUrls: ["./hold-document-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldDocumentFormComponent implements OnInit {
  @Input() set entityId(value: number) {
    const cleanValue = Number(value);
    const validValue = NumberHelper.isGreaterThan(cleanValue, 0) ? cleanValue : null;
    this.setEntityId({ value: validValue } as any);
  }
  @Input() set workflowStatus(value: WorkflowStatus) {
    const cleanValue = Number(value);
    const validValue = NumberHelper.isGreaterThan(cleanValue, 0, true) ? cleanValue : null;
    this.setWorkflowStatus({ value: validValue } as any);
  }

  constructor(private readonly formService: FormService,
              private readonly documentRequestService: DocumentRequestService,
              private changeDetector: ChangeDetectorRef) { }
  @Output() value = new EventEmitter<WorkflowSubmitRequest>();

  form: FormGroup;

  private pEntityId: Textbox;

  private pWorkflowStatus: Textbox;

  private pDocumentStatus: Dropdown;

  private pNote: Textarea;

  holdReasonLiteral = "Document placed on Hold. Reason: ";
  noteLiteral = "  Note: ";

  getEntityId(): Textbox {
    return this.pEntityId;
  }
  setEntityId(value: Textbox): void {
    this.pEntityId = new Textbox({ ...this.getEntityId(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getEntityId().key).setValue(this.getEntityId().value);
    }
    this.resetNoteAndDocumentstatus();
  }
  getWorkflowStatus(): Textbox {
    return this.pWorkflowStatus;
  }
  setWorkflowStatus(value: Textbox): void {
    this.pWorkflowStatus = new Textbox({ ...this.getWorkflowStatus(), ...value } as any);
    if (this.form != null) {
      this.form.get(this.getWorkflowStatus().key).setValue(this.getWorkflowStatus().value);
    }
  }
  getDocumentStatus(): Dropdown {
    return this.pDocumentStatus;
  }
  setDocumentStatus(value: Dropdown): void {
    this.pDocumentStatus = new Dropdown({ ...this.pDocumentStatus, ...value } as any);
  }
  getNote(): Textarea {
    return this.pNote;
  }
  setNote(value: Textarea): void {
    this.pNote = new Textarea({ ...this.pNote, ...value } as any);
  }

  ngOnInit() {
    this.createForm();
    this.getStatuses();
  }


  change(): void {
    if (this.form.valid) {
      const workflowSubmitRequest = new WorkflowSubmitRequest(
        this.form.value[this.getEntityId().key],
        this.form.value[this.getWorkflowStatus().key],
        WorkflowAction.Hold,
        this.form.value[this.getDocumentStatus().key],
        [],
        `${this.holdReasonLiteral} ${this.getDocumentStatus().getSelectedOption(this.form)?.text}.${(this.form.value[this.getNote().key]) !== null ? this.noteLiteral  + this.form.value[this.getNote().key] : ""}`
      );

      this.value.emit(workflowSubmitRequest);
    } else {
      this.value.emit({} as any);
    }
  }

  private createForm(): void {
    this.setEntityId({
      key: "entityId",
      hidden: true,
      validators: [Validators.required, Validators.min(1)],
    } as any);

    this.setWorkflowStatus({
      key: "workflowStatus",
      hidden: true,
      validators: [Validators.required, Validators.min(0)],
    } as any);

    this.setDocumentStatus({
      key: "documentStatus",
      placeholder: "Hold Reason",
      label: "Document Hold Reason",
      validators: Validators.required,
      errorMessages: {
        required: "Hold Reason is required.",
      },
    } as any);

    this.setNote({
      key: "note",
      label: "Note",
      placeholder: "Write a note...",
      rows: 5,
      validators: [
        Validators.minLength(15),
        Validators.maxLength(255),
      ],
      errorMessages: {
        minlength: "The note must be between 15 - 255 characters.",
        maxlength: "The note must be between 15 - 255 characters.",
      },
    } as any);

    this.form = this.formService.createFormGroup([
      this.getEntityId(),
      this.getWorkflowStatus(),
      this.getDocumentStatus(),
      this.getNote(),
    ]);
  }

  private getStatuses() {

      this.documentRequestService.getDocumentStatuses(0, 1) // @View=0, @Action = 1	/* for hold reason drop down.
      .subscribe(documentStatusList => {
        const options = [...documentStatusList];
        this.setDocumentStatus({ options } as any);
        this.changeDetector.markForCheck();
      });
  }
  resetNoteAndDocumentstatus() {
    if (this.form) {
      this.form.controls.note.setValue("");
      this.form.controls.documentStatus.setValue("Hold Reason");
      this.formService.updateDom.next();
    }
    this.changeDetector.markForCheck();
  }
}
