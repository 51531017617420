import { IAutoMapper } from "../../../../core/automapper/interfaces";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { DynamicEntity } from "../../../api/member-validation/dynamic-entity.model";

export const mapArchiveChaseDetail = (automapper: IAutoMapper): void => {
  automapper
  .createMap("default", "ArchiveChaseDetail")
    .forMember("projectId", o => o.projectId)
    .forMember("organizationId", o => o.organizationId)
    .forMember("clientId", o => o.clientId)
    .forMember("projectName", o => o.projectName)
    .forMember("archiveDate", o => o.archiveDate)
    .forMember("chaseId", o => o.chaseId)
    .forMember("chaseSourceAliasId", o => o.chaseSourceAliasId)
    .forMember("intakeDocumentCount", o => o.intakeDocumentCount)
    .forMember("chaseEntityData", o => automapper.mapMany("default", "DynamicEntity", o.chaseEntityData))
    .forMember("memberId", o => o.memberId)
    .forMember("memberSourceAliasId", o => o.memberSourceAliasId)
    .forMember("enrolleeId", o => o.enrolleeId)
    .forMember("firstName", o => o.firstName)
    .forMember("lastName", o => o.lastName)
    .forMember("dateOfBirth", o => o.dateOfBirth)
    .forMember("gender", o => o.gender)
    .forMember("measureCode", o => o.measureCode)
    .forMember("city", o => o.city)
    .forMember("state", o => o.state);
  };

export class ArchiveChaseDetail {
  projectId?: number;
  organizationId?: number;
  clientId?: number;
  projectName?: string;
  archiveDate?: string;
  chaseId?: number;
  chaseSourceAliasId?: number;
  intakeDocumentCount?: number;
  chaseEntityData?: DynamicEntity[];
  memberId?: number;
  memberSourceAliasId?: string;
  enrolleeId?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  measureCode?: string;
  city?: string;
  state?: string;

  constructor(options: Partial<ArchiveChaseDetail> = {}) {
    this.projectId = options.projectId;
    this.organizationId = options.organizationId;
    this.clientId = options.clientId;
    this.projectName = StringHelper.clean(options.projectName);
    this.archiveDate = DateHelper.format(options.archiveDate);
    this.chaseId = options.chaseId;
    this.chaseSourceAliasId = options.chaseSourceAliasId;
    this.intakeDocumentCount = options.intakeDocumentCount;
    this.chaseEntityData = ArrayHelper.clean(options.chaseEntityData);
    this.memberId = options.memberId;
    this.memberSourceAliasId = StringHelper.clean(options.memberSourceAliasId);
    this.enrolleeId = StringHelper.clean(options.enrolleeId);
    this.firstName = StringHelper.clean(options.firstName);
    this.lastName = StringHelper.clean(options.lastName);
    this.dateOfBirth = StringHelper.clean(options.dateOfBirth);
    this.gender = StringHelper.clean(options.gender);
    this.measureCode = StringHelper.clean(options.measureCode);
    this.city = StringHelper.clean(options.city);
    this.state = StringHelper.clean(options.state);
  }

  get memberName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get memberAge(): number {
    return this.dateOfBirth ? DateHelper.ageInYears(this.dateOfBirth, new Date()) : 0;
  }

  get memberLocation(): string {
    return `${this.city}, ${this.state}`;
  }
}
