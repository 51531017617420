import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-clinical-productivity-accuracy-overall-dw",
  templateUrl: "./clinical-productivity-accuracy-overall-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalProductivityAccuracyOverallDWComponent {
  filters: string[] = ["Projects", "AbstractionDate", "Products", "LOB", "RetrievalOwner", "UserEmail"];
  dashboardType = LookerRiskDashboardType.CLINICAL_PRODUCTIVITY_AND_ACCURACY_OVERALL_DW;
  hideFilters: string[] = [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN, HideFilters.CONTRACT_NUMBER,
                           HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MEASURE2, HideFilters.ABSTRACTION_DATE, HideFilters.ABSTRACTOR_BY, "User Email"];
  }
