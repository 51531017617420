<div class="audit-query-detail-container">
  <app-detail-main-template>
    <div container-header>
      <app-audit-query-header [stateName]="stateName" (currentTab)="onSelectTab($event)"></app-audit-query-header>
    </div>
    <div container-body>
      <div class="section tabContent" [hidden]="selectedTab !== 'auditquery'">
        <div class="grid-container">
          <app-audit-query-grid [stateName]="stateName"></app-audit-query-grid>
        </div>
        <div class="package-generate-container">
          <app-audit-package-generate></app-audit-package-generate>
        </div>
      </div>

      <div class="section tabContent" [hidden]="selectedTab !== 'chart'">
        <div class="chart-container">
          <app-audit-query-chart></app-audit-query-chart>
        </div>
      </div>
    </div>
  </app-detail-main-template>
</div>
