import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { CalendarComponent } from "./calendar.component";

export interface ICalendar extends IDynamicInputOptions {
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
}

export class Calendar extends DynamicInput implements ICalendar {
    placeholder: string;
    minDate: Date;
    maxDate: Date;
    readonly controlType = "calendar";
    readonly componentType = CalendarComponent;

    constructor(options: ICalendar = {}) {
        super(options);
        this.placeholder = StringHelper.clean(options.placeholder);
        this.minDate = options.minDate ;
        this.maxDate = options.maxDate ;
    }
}
