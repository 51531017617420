import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { ProviderSearchRequest } from "../../../../../../api/provider/provider-search-request-model";
import { ProviderService } from "../../../../../../api/provider/provider.service";

@Component({
  selector: "app-validation-checkbox",
  template: `<form-factory [formGroup]="form" [model]="validationInput" (onChange)="onChange($event)"></form-factory>`,
  styleUrls: ["./validation-checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ValidationCheckboxComponent implements OnInit {
  @Input() providerGridData: any;
  @Output() providerValidationChanged = new EventEmitter<void>();
  form: FormGroup;
  validationInput: Checkbox;

  constructor(
    private providerService: ProviderService,
    private readonly formService: FormService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  onChange(event: any): void {
    this.saveVerification(event.checked);
  }

  private saveVerification(isValid: boolean): void {
    const verificationOptions = new ProviderSearchRequest(
      null, null, null, null, null, null, null, null, null, null,
      this.providerGridData.serviceProviderList, isValid);

    this.providerGridData.isVerified = isValid;

    this.providerService.updateProviderVerification(verificationOptions).subscribe();
    this.providerValidationChanged.emit();
  }

  private initializeForm(): void {
    this.validationInput = new Checkbox({
      key: "Is_Valid",
      value: this.providerGridData.isVerified,
      label: "Is Valid",
    });

    this.form = this.formService.createFormGroup([this.validationInput]);
  }
}
