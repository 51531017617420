import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";

@Component({
  selector: "app-open-gaps-template",
  templateUrl: "./open-gaps-template.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenGapsTemplateComponent {
  @Input() data: any;

  get totalOpenGaps(): string {
    return this.data.totalOpenGaps === 0 || this.data.totalOpenGaps == null ? "" : this.data.totalOpenGaps.toString();
  }

  get openGapsArray(): any[] {
    return this.data.openGaps.split(",");
  }

  get allOpenGaps(): any[] {
    return ArrayHelper.chunkArray(this.openGapsArray, 6);
  }

  trackByIndex(index, item) {
    return index;
  }

}
