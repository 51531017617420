import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { TextboxSourceComponent } from "./textbox-source.component";

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TextboxSourceComponent,
  ],
  entryComponents: [
    TextboxSourceComponent,
  ],
  exports: [
    TextboxSourceComponent,
  ],
})
export class TextboxSourceModule { }
