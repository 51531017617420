import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { SessionService } from "../core/storage/session.service";
import { MenuItem } from "../shared/menu/menu-item.model";
import { MenuService } from "../shared/menu/menu.service";
import { PlatformService } from "./platform.service";
declare const openHavingIssueWidget: any;

@Component({
  selector: "app-platform",
  templateUrl: "./platform.component.html",
  styleUrls: ["./platform.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  isOpen = false;
  items: MenuItem[] = [];
  isEnableWidgetSection = false;
  isReviewVisible = false;
  isShowWidgetPanel = false;

  constructor(
    private platformService: PlatformService,
    public menuService: MenuService,
    private changeDetector: ChangeDetectorRef,
    public router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.menuService.isOpen$.subscribe(value => {
      this.isOpen = value;
    });

    this.sink.add(
      this.platformService.isEnableWidgetSection.subscribe(res => {
        if (res !== null) {
          this.isEnableWidgetSection = res;
        }
      })
    );

    this.platformService
      .getMenu()
      .subscribe(this.assignAndNotifyMenuItems);
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private assignAndNotifyMenuItems = (data: MenuItem[]): void => {
    this.sessionService.put("user_menus", JSON.stringify(data));
    data.forEach((x: MenuItem) => {
      x.children.forEach((y: MenuItem) => {
        y.command = () => {
          this.menuService.toggleMenuState(false);
        };
        y.isNavigationLink = true;
      }
      );
    });
    this.items = data;
    this.changeDetector.markForCheck();
  }

  getClass(showHeader: any): string {
    if (this.router.url.includes("/approvalcenter/documents")) {
      this.isEnableWidgetSection = false;
    }
    let cssClass = showHeader ? "platform__body--collapse" : "platform__body--content";
    cssClass = this.isEnableWidgetSection ? `${cssClass}  platform__widget--width` : `${cssClass} platform__widget--contentWidth`;
    cssClass = ((this.router.url.includes("/members/chasev2")
      || this.router.url.includes("/members/mrr/chasev2")
      || this.router.url.includes("/members/or1/chasev2")
      || this.router.url.includes("/members/clientoverread/chasev2")) && !showHeader && this.isEnableWidgetSection) ? `platform__body--content contentHeaderWidth` : cssClass;
    // TO DO Later for responsive
    // cssClass = this.isEnableWidgetSection ? this.isShowPanel ? `${cssClass}  platform__widget--panelWidth` : `${cssClass} platform__widget--panelFullWidth` : cssClass;
    return cssClass;
  }
}
