import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { FormService } from "../../../dynamic-forms/form.service";
import { Resize } from "../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../dynamic-forms/inputs/textarea/textarea.model";
import { WorkflowService } from "../../../platform/api/workflow/workflow.service";
import { ChaseMoveBack } from "../../../platform/modules/retrieval/retreival-document-review/document-review/chase-move-back.model";

@Component({
  selector: "app-chase-move-back",
  templateUrl: "./chase-move-back.component.html",
  styleUrls: ["./chase-move-back.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseMoveBackComponent implements OnInit {
  chaseNotes: Textarea;
  formGroup: FormGroup;
  private chaseMoveBack: ChaseMoveBack;
  validateMessage = "Write a note between 4 - 1000 characters.";
  @Input() visible = false;
  @Input() chaseId: number;
  @Input() addressId: number;
  @Output() onHide = new EventEmitter<null>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onUpdate = new EventEmitter<null>(true);
  @Input() moveBackWarningText: string;
  @Input() workFlowStatusId: number;


  constructor(
    private readonly formService: FormService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.chaseNotes = new Textarea({
      key: "chaseNotes",
      label: "Notes",
      placeholder: "Write a note...",
      rows: 1,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
      errorMessages: {
        maxlength: this.validateMessage,
        minlength: this.validateMessage,
        required: this.validateMessage,
      },
    });

    this.formGroup = this.formService.createFormGroup([this.chaseNotes]);
  }


  moveChase(): void {
    if (this.formGroup.valid) {
      this.chaseMoveBack = new ChaseMoveBack({
        chaseId: this.chaseId,
        workflowStatusId: this.workFlowStatusId,
        chaseNote: `Chase ${this.chaseId} moved back. ${this.formGroup.get("chaseNotes").value}`,
        addressId: Number(this.addressId),
      });

      this.workflowService.chaseMoveBack(this.chaseMoveBack).subscribe((result: any) => {
        this.onUpdate.emit();
      });
    }
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit();
  }
}
