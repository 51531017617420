import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../core/environment.tokens";
import { DischargeCalculatedDates } from "../discharge-group/discharge-calculated-dates.model";

@Injectable({
  providedIn: "root",
})
export class CalculatedGroupsService {
  dischargeDates = new BehaviorSubject<DischargeCalculatedDates>({} as any);
  dischargeSubscription: Subscription;


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService
  ) { }


  getDischargeDates(chaseId: number): Subscription {
    const url = `${this.baseApiUrl}measurecalculations/dischargedates?chaseId=${chaseId}`;

    if (this.dischargeSubscription) {
      this.dischargeSubscription.unsubscribe();
    }

    this.dischargeSubscription = timer(100).pipe(
      switchMap(() => this.http.get(url)),
      map(this.automapper.curry("default", "DischargeCalculatedDates"))
    ).subscribe(dischargeDates => this.dischargeDates.next(dischargeDates));

    return this.dischargeSubscription;
  }
}
