import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-suspecting",
  templateUrl: "./suspecting.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspectingComponent {

  filters: string[];
  dashboardType = LookerRiskDashboardType.SUSPECTING;

  constructor() {
    this.filters = [];
  }
}
