import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";

@Component({
  selector: "app-ft-daily-summary",
  templateUrl: "./ft-daily-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtDailySummaryComponent {
    filters: string[];
    dashboardType = LookerRiskDashboardType.FT_DAILY_SUMMARY_REPORT;

    constructor() {
        this.filters = ["Projects"];
    }

}
