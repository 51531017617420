import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { List } from "immutable";
import { map, tap } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { BulkAction } from "../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../../shared/grid/server-grid/server-grid.component";
import { ListItem } from "../../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ClinicalPageService } from "../../../clinical/clinical-page/clinical-page.service";
import { ChaseQueryService } from "../../../project/chase-query/chase-query.service";
import { RetrievalPageService } from "../../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgAttribute } from "../../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../../service-org-admin/service-org-config/service-org-config.service";
import { PLAN_LIST } from "../../chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../chase-detail/chase-detail-chart/chart.service";
import { NewPagesService } from "../new-pages.service";

@Component({
  selector: "member-new-pages-grid",
  templateUrl: "./new-pages-grid.component.html",
  styleUrls: ["./new-pages-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPagesGridComponent implements OnInit {
  @ViewChild(ServerGridComponent, { static: false }) serverGridComponent: ServerGridComponent;
  headerTitle = "NEW AND REMOVED PAGES";
  headerStatistics = List<ListItem>();
  newOrRemovedGridConfiguration = new GridConfiguration();
  newOrRemovedGridSearchRequest: GridRequest;
  newOrRemovedPagesSelection: any[];
  selectedDocId: number;
  actions: BulkAction[];
  form: FormGroup;
  refreshGrid = new EventEmitter<null>(true);
  selectedChases: any[];
  chaseRequestIds: number[];
  noNewOrRemovedPages: boolean;
  @Input() stateName = "";
  selectedTab = "new";
  bulkActionName = "Mark Pages as Read";
  chaseAddedToViewOrMovedToDataEntry = "Add Pages to Member File for Review";
  documentViewType = "newPages";
  previousUrl: string;
  searchRequest: GridRequest;
  get chaseIdInput(): Textbox {
    return this.getInput("ChaseIdAndClientChaseKey");
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("Projects");
  }
  set projectsInput(value: CheckboxGroup) {
    this.setInput("Projects", value);
  }

  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasuresCodes");
  }
  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasuresCodes", value);
  }

  get complianceInput(): CheckboxGroup {
    return this.getInput("ChaseComplianceCodes");
  }
  set complianceInput(value: CheckboxGroup) {
    this.setInput("ChaseComplianceCodes", value);
  }

  get plansInput(): CheckboxGroup {
    return this.getInput("Plans");
  }
  set plansInput(value: CheckboxGroup) {
    this.setInput("Plans", value);
  }


  get sampleComplianceInput(): CheckboxGroup {
    return this.getInput("SampleComplianceCodes");
  }
  set sampleComplianceInput(value: CheckboxGroup) {
    this.setInput("SampleComplianceCodes", value);
  }

  get statusesInput(): CheckboxGroup {
    return this.getInput("Statuses");
  }
  set statusesInput(value: CheckboxGroup) {
    this.setInput("Statuses", value);
  }

  get assignedToInput(): Autocomplete {
    return this.getInput("AssignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.setInput("AssignedToUserId", value);
  }

  get selectedChaseIds(): number[] {
    if (!ArrayHelper.isAvailable(this.selectedChases)) {
      return [];
    }

    return this.selectedChases.map(chase => +chase.chaseId);
  }

  get rowActions(): BulkAction[] {
    const bulkActions = [
      new BulkAction({
        name: this.chaseAddedToViewOrMovedToDataEntry,
        action: this.addPagesToMemberFileForReview,
      }),
      new BulkAction({
        name: this.bulkActionName,
        action: this.markPagesAsRead,
      }),
    ];
    return bulkActions;
  }

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly retrievalPageService: RetrievalPageService,
    private formService: FormService,
    private readonly clinicalPageService: ClinicalPageService,
    private readonly chartService: ChartService,
    private chaseQryService: ChaseQueryService,
    private readonly newPagesService: NewPagesService,
    private readonly userService: UserService,
    private messagingService: MessagingService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService
  ) { }

  ngOnInit(): void {
    const activeTab = localStorage.getItem("activeTab");
    if (activeTab === "removePages") {
      this.selectedTab = "removed";
      this.setupRemoveTabConfig();
    } else {
      this.selectedTab = "new";
      this.setupNewTabConfig();
    }

    this.createGrid();
    this.getProjects();
    this.getMeasures();
    this.getComplianceCodes();
    this.getStatuses();
    this.getUsersToFilter();
    this.getPlans();
    // TODO: Uncomment for the next sprint when DB becomes available
    // this.getStatistics();
  }

  private getStatistics(): void {
    // TODO: Uncomment for the next sprint when DB becomes available
    // const type = this.pageType;
    // this.clinicalPageService
    //  .getStatisticsData(type)
    //  .subscribe(result => {
    //    this.headerStatistics = List(result);
    //    this.changeDetector.markForCheck();
    //  });

  }

  gridDataLoaded(data: any[]) {
    this.noNewOrRemovedPages = !ArrayHelper.isAvailable(data);
  }

  private createGrid(): void {
    this.newOrRemovedGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "chaseId", header: "Chase ID", routeUrl: `/members/chase/:chaseId/expanded/${this.documentViewType}`}),
        new GridColumnDefinition({ field: "projectName", header: "Project" }),
        new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
        new GridColumnDefinition({ field: "chaseComplianceCode", header: "Chase Compliance" }),
        new GridColumnDefinition({ field: "sampleComplianceCode", header: "Sample Compliance" }),
        new GridColumnDefinition({ field: "chaseDocumentChangeDate", header: "Received", pipeName: GridPipeName.Date, format: "MM-dd-yyyy" }),
        new GridColumnDefinition({ field: "reportingStatusName", header: "Status" }),
        new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
        new GridColumnDefinition({ field: "chaseDocumentPageCountChange ", header: "Pages" }),
        new GridColumnDefinition({ field: "chaseRequestId ", header: "ChaseRequestId", show: false }),
        new GridColumnDefinition({ field: "plan", header: "Health Plan" }),
      ],
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100],
      selectionMode: "multiple",
      stateName: this.stateName,
      showExportAction: true,
    });

    this.loadGrid();
    this.actions = this.getActions();
    this.refreshGrid.emit();

  }

  private loadGrid(): void {
    this.newOrRemovedGridSearchRequest = new GridRequest({
      url: `${this.baseApiUrl}newpages/griddata`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "ChaseIdAndClientChaseKey",
          name: "Chase ID / Client Chase Key",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Projects",
          name: "Projects",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "MeasuresCodes",
          name: "Measures",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "ChaseComplianceCodes",
          name: "Chase Compliance",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "SampleComplianceCodes",
          name: "Sample Compliance",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Statuses",
          name: "Status",
        }),
        new GridFilter({
          input: new Autocomplete({ placeholder: "Select User..." }),
          key: "AssignedToUserId",
          name: "Assigned To",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "SelectedPagesType",
          value: this.selectedTab,
          show: false,
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "Plans",
          name: "Plans List",
        }),
      ],
    });
  }

  private getPlans(): void {
    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(PLAN_LIST.attributeId)
      .subscribe(configuration => {
        this.getPlansInput(configuration);
        this.setPlansInput();
        this.formService.updateDom.next();
        this.changeDetector.markForCheck();
      });
   }

  private getPlansInput(configuration: ServiceOrgAttribute): void {
    const plansAttribute = JSON.parse(configuration.attributeValue);
    const plansOptions = plansAttribute.Plans.map(item => new SelectableInput({
      text: item.Name,
      value: item.Name,
    }));
    this.plansInput = { ...this.plansInput, options: plansOptions } as any;
  }

  private setPlansInput(): void {
    const plansFilter = this.searchRequest?.getFilter(this.plansInput.key);
    if (StringHelper.isAvailable(plansFilter?.value)) {
      const optionsValue = this.plansInput.options.find(a => a.value === +plansFilter.value);
      plansFilter.inputValue = [optionsValue];
      this.formService.updateDom.next();
    }
  }


  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.description,
          value: item.id,
        }));
      }))
      .subscribe(options => {
        this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.measureCode,
          value: item.measureId,
        }));
      }))
      .subscribe(options => {
        this.measuresInput = new CheckboxGroup({ ...this.measuresInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getComplianceCodes(): void {
    this.chartService.getComplianceCodes()
      .subscribe(options => {
        this.complianceInput = { ...this.complianceInput, options } as any;
        this.sampleComplianceInput = new CheckboxGroup({ ...this.sampleComplianceInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getStatuses(): void {
    this.chaseQryService
      .getReportingStatusesList()
      .pipe(map(this.automapper.curryMany("ReportingStatusModel", "SelectableInput")))
      .subscribe(options => {
        this.statusesInput = new CheckboxGroup({ ...this.statusesInput, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getUsersToFilter(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getActions(): BulkAction[] {
    const bulkActions = [
      new BulkAction({
        name: "Export",
        action: this.exportChases,
        alwaysEnabled: true,
      }),
      new BulkAction({
        name: this.chaseAddedToViewOrMovedToDataEntry,
        action: this.addPagesToMemberFileForReview,
      }),
      new BulkAction({
        name: this.bulkActionName,
        action: this.markPagesAsRead,
      }),
    ];
    return bulkActions;
  }

  exportChases = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      this.serverGridComponent.exportGridData();
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  addPagesToMemberFileForReview = (rowData: any): void => {
    this.setSelectedChases(rowData);
    if (ArrayHelper.isAvailable(this.selectedChases)) {
      const chaseIds = this.selectedChases.map(a => Number(a.chaseId));
      this.newPagesService.addMemberPagesForReview(chaseIds).subscribe(
        () => {
          this.messagingService.showToast(this.chaseAddedToViewOrMovedToDataEntry, SeverityType.SUCCESS);
          this.refreshGrid.emit();
          this.newOrRemovedPagesSelection = [];
          this.changeDetector.markForCheck();
        },
        () => {
          this.newOrRemovedPagesSelection = [];
          this.messagingService.showToast(`Failed to ${this.chaseAddedToViewOrMovedToDataEntry}`, SeverityType.ERROR);
          this.changeDetector.markForCheck();
        });
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }
  }

  markPagesAsRead = (rowData: any): void => {
    this.chaseRequestIds = [];
    this.chaseRequestIds = this.getChaseRequestIds(rowData);

    if (ArrayHelper.isAvailable(this.chaseRequestIds)) {
      this.newPagesService.markPagesAsRead(this.chaseRequestIds).subscribe(
        () => {
          this.messagingService.showToast(this.bulkActionName, SeverityType.SUCCESS);
          this.refreshGrid.emit();
          this.chaseRequestIds = [];
          this.newOrRemovedPagesSelection = [];
          this.changeDetector.markForCheck();
        },
        () => {
          this.chaseRequestIds = [];
          this.newOrRemovedPagesSelection = [];
          this.messagingService.showToast(`Failed to ${this.bulkActionName} `, SeverityType.ERROR);
          this.changeDetector.markForCheck();
        });
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
    }

  }

  private setSelectedChases(rowData: any): void {
    this.selectedChases = [];
    const rowChases = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    this.selectedChases = rowChases;
  }

  private getChaseRequestIds(rowData: any): number[] {
    const selectedRows = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
    return selectedRows.map(a => Number(a.chaseRequestId));
  }

  trackByIndex(index, item) {
    return index;
  }

  private getInput<T extends DynamicInput>(key: string): T {
    if (this.newOrRemovedGridSearchRequest == null) {
      return null;
    }

    return this.newOrRemovedGridSearchRequest.getInput<T>(key);
  }

  private setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.newOrRemovedGridSearchRequest == null) {
      return null;
    }

    this.newOrRemovedGridSearchRequest.setInput<T>(key, value);
  }

  selectedTabs(tabName: string): string {
    return this.selectedTab === tabName ? "active" : "";
  }

  toggleTab(tab: string): void {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
    if (this.selectedTab === "new") {
      this.setupNewTabConfig();
      this.newOrRemovedGridSearchRequest.getFilter("SelectedPagesType").value = "new";
      this.newOrRemovedGridConfiguration.columns.map(x => { x.routeUrl = x.field === "chaseId" ? `/members/chase/:chaseId/expanded/${this.documentViewType}` : x.routeUrl; return x; });
    } else {
      this.setupRemoveTabConfig();
      this.newOrRemovedGridSearchRequest.getFilter("SelectedPagesType").value = "removed";
      this.newOrRemovedGridConfiguration.columns.map(x => { x.routeUrl = x.field === "chaseId" ? `/members/chase/:chaseId/expanded/${this.documentViewType}` : x.routeUrl; return x; });

    }
    this.newOrRemovedGridSearchRequest.resetFilters();
    this.actions = this.getActions();
    this.newOrRemovedPagesSelection = [];
    this.refreshGrid.emit();
  }
  setupNewTabConfig(): void {
    this.bulkActionName = "Mark Pages as Read";
    this.chaseAddedToViewOrMovedToDataEntry = "Add Pages to Member File for Review";
    this.documentViewType = "newPages";
    localStorage.setItem("activeTab", "newPages");
   }
  setupRemoveTabConfig(): void {
    this.bulkActionName = "Verify Page Removal";
    this.documentViewType = "removePages";
    this.chaseAddedToViewOrMovedToDataEntry = "Move Chases Back To Data Entry";
    localStorage.setItem("activeTab", "removePages");
    }
}
