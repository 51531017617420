import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-life-of-achase-dw",
  templateUrl: "./life-of-achase-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeOfAChaseDWComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.LIFE_OF_A_CHASE_REPORT_DW;
  hideFilters: string[] = [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN2, HideFilters.CONTRACT_NUMBER,
                           HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MEASURE2, HideFilters.PROJECT_NAME2, "Chase_ID", "Customer_Name"];
  }
