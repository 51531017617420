export class DocumentSummary {

  constructor(
    readonly documentId: number,
    readonly sourceDocumentTypeId: number,
    readonly fileName: string,
    readonly numberOfPages: number,
    readonly description: string,
    readonly ocrDataAvailable: boolean,
    readonly documentStateGroupId: number,
    readonly documentStateId: number,
    readonly masterDocumentSourceId?: number
  ) {

    this.documentId = documentId;
    this.sourceDocumentTypeId = sourceDocumentTypeId;
    this.fileName = fileName;
    this.numberOfPages = numberOfPages;
    this.description = description;
    this.ocrDataAvailable = ocrDataAvailable;
    this.documentStateGroupId = documentStateGroupId;
    this.documentStateId = documentStateId;
    this.masterDocumentSourceId = masterDocumentSourceId;
  }
}

