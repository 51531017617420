import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
    selector: "app-sftp-report",
    templateUrl: "./sftp-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SftpReportComponent {
    filters: string[];
    hideFilters: string[];
    dashboardType = LookerRiskDashboardType.SFTP_REPORT;

    constructor() {
        this.filters = ["RetrievalType", "FileCreateDate"];
        this.hideFilters = [HideFilters.PROJECT_ID];
    }

}
