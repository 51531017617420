<div class="tab-sub-menu__container">
  <!--<a *ngFor="let menuItem of filteredMenuItems; let i = index; trackBy: trackByFn"
     [ngClass]="getActiveClass(menuItem)"
     [routerLink]="menuItem.path"
     [routerLinkActiveOptions]="{ exact: isFirstItem(i) }"
     routerLinkActive="active"
     (click)="onTabSelected(menuItem.description)">
    {{menuItem.description}} <span class="badge">12</span>
  </a>-->
  <a *ngFor="let menuItem of filteredMenuItems; let i = index; trackBy: trackByFn"
     [ngClass]="getActiveClass(menuItem)"
     (click)="toggleTab(menuItem)">
    {{menuItem.description}}
  </a>
</div>
