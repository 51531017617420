export class RiskDxValidationDiagnosis {
  diagnosisEndDate: string;
  diagnosisFromDate: string;
  diagnosisId: number;
  diagnosisPageNumber: number;
  documentPageId: number;
  encounterId: number;
  entityId: number;
  hcc: string;
  icdCode: string;
  isCoderAddedDiagnosis: boolean | null;
  isEveData: boolean;
  isSelected: boolean;
  modelId: string;
  providerName: string;
  vrcCode: string;

  constructor(options: RiskDxValidationDiagnosis) {
    this.diagnosisEndDate = options.diagnosisEndDate;
    this.diagnosisFromDate = options.diagnosisFromDate;
    this.diagnosisId = options.diagnosisId;
    this.diagnosisPageNumber = options.diagnosisPageNumber;
    this.documentPageId = options.documentPageId;
    this.encounterId = options.encounterId;
    this.entityId = options.entityId;
    this.hcc = options.hcc;
    this.icdCode = options.icdCode;
    this.isCoderAddedDiagnosis = options.isCoderAddedDiagnosis;
    this.isEveData = options.isEveData;
    this.isSelected = options.isSelected;
    this.modelId = options.modelId;
    this.providerName = options.providerName;
    this.vrcCode = options.vrcCode;
  }
}
