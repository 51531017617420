import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { AuditPageEntries } from "./audit-page-entries.model";

@Injectable({
  providedIn: "root",
})
export class AuditPageEntryService {
  private pages = new BehaviorSubject<number>(null);
  auditPages = this.pages.asObservable();

  private printScreenComplete = new BehaviorSubject<boolean>(false);
  auditPrintScreenComplete = this.printScreenComplete.asObservable();

  constructor(
    @Inject(BASE_API_URL)
    private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  savePageEntries(data: AuditPageEntries): Observable<number> {
    const url = `${this.baseApiUrl}audit/savePageEntries`;

    return this.http.post(url, data)
      .pipe(map((response: any) => response as number));
  }

  editPageEntry(value: number | null) {
    this.pages.next(value);
  }

  codingScreenPrintScreenComplete(value: boolean): void {
    this.printScreenComplete.next(value);
  }

  getPageEntries(auditPackageItemId: number): Observable<AuditPageEntries> {
    const url = `${this.baseApiUrl}audit/pageEntriesForEdit?auditPackageItemId=${auditPackageItemId}`;

    return this.http.get(url)
      .pipe(map((response: any) => response as AuditPageEntries));

  }
}
