import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "../../dynamic-forms/form.service";
import { Checkbox } from "../../dynamic-forms/inputs/checkbox/checkbox.model";

@Component({
  selector: "app-checkbox-validate",
  templateUrl: "./checkbox-validate.component.html",
  styleUrls: ["./checkbox-validate.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxValidateComponent implements OnInit {
  @Input() isVerified: boolean;
  @Output() onValidateChange = new EventEmitter<boolean>(false);
  form: FormGroup;
  validationInput: Checkbox;

  constructor(
    private readonly formService: FormService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.validationInput = new Checkbox({
      key: "Is_Valid",
      value: this.isVerified,
      label: "Is Valid",
    });

    this.form = this.formService.createFormGroup([this.validationInput]);
  }

  onChange(event: boolean): void {
    this.onValidateChange.emit(event);
  }

}
