import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { AuthService } from "../../auth/auth.service";
import { MessagingService } from "../../core/messaging/messaging.service";
import { SeverityType } from "../../core/messaging/severity-type.enum";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { AddressTimelineStateService } from "../../platform/modules/retrieval/address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../../platform/modules/retrieval/address-timeline/address-timeline.service";
import { ArrayHelper } from "../../utilities/contracts/array-helper";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../utilities/contracts/object-helper";
import { CreateTagComponent } from "./create-tag/create-tag.component";
import { TagSourceType } from "./model/tag-source-type.enum";
import { TagType } from "./model/tag-type.enum";
import { TagStateService } from "./tag-state.service";
import { TagService } from "./tag.service";

@Component({
  selector: "app-view-tags",
  templateUrl: "./view-tags.component.html",
  styleUrls: ["./view-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewTagsComponent implements OnInit, OnChanges, OnDestroy {

  get isTagsAvailable(): boolean {
    return ArrayHelper.isAvailable(this.value);
  }

  get isSelected(): boolean {
    return this.selectedObjectId === this.objectId;
  }

  get objectIds(): number[] {
    if (NumberHelper.isAvailable(Number(this.objectId))) {
      return [this.objectId];
    }
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private tagService: TagService,
    private messagingService: MessagingService,
    private authService: AuthService,
    private addressTimelineService: AddressTimelineService,
    private addressTimelineStateService: AddressTimelineStateService,
    private tagStateService: TagStateService,
    private router: Router
  ) { }

  get addColumnBorder(): string {
    return this.rowIndex === this.currentIndex ? "tag-border" : "";
  }

  get hasTagManagement(): boolean {
    return (this.authService.user.isAddTag
      || this.authService.user.isAdminRole
      || this.authService.user.isManagerRole
      || this.authService.user.isLeadRole);
  }
  @ViewChild(CreateTagComponent) createTagComponent: CreateTagComponent;
  isVisible = false;
  @Input() tagType: TagType;
  @Input() objectId: number;
  @Input() isGrid: boolean;
  @Input() gridData: any;
  @Input() rowIndex: number;
  tagName: string;
  value: any;
  currentIndex: number;
  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  enableClosebuttonId = 0;
  sink = new SubSink();
  isCreateTagTemplateVisible = false;
  @Input() selectedObjectId: number;
  @Output() outputObjectId: EventEmitter<any> = new EventEmitter();
  lastactiveObjectId = 0;
  panelClass = "right_panel ";
  @Output() onUpdate = new EventEmitter<null>(true);
  isTagActivated = false;
  chipClass = "ui-chips-token-label";
  showExtraTags = false;
  isChaseV2 = false;
  showTagsEllipsesIcon = false;
  activeToggle = false;
  onModelChange: Function = () => { };
  ngOnInit() {
    this.loadTagInformation();
    if (this.router.routerState.snapshot.url.includes("chasev2")) {
      this.isChaseV2 = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedObjectId && changes.selectedObjectId.currentValue) {
      this.selectedObjectId = changes.selectedObjectId.currentValue;

    }

    if (!changes.objectId.firstChange) {
      this.objectId = changes.objectId.currentValue;
      this.loadTagInformation();
    }

    this.changeDetector.markForCheck();
  }

  loadTagInformation(): void {
    this.getTagsById();
    this.sink.add(
      this.tagStateService.existingTagLists.subscribe(result => {
        if (ArrayHelper.isAvailable(result) && ObjectHelper.isEmpty(this.value.find(x => x.value === result[0].value))) {
          this.value = [...this.value, result[0]];
          this.showTagsEllipsesIcon = this.value.length > 4;
          this.changeDetector.markForCheck();
        }
      }),
      this.tagStateService.closeExtraTooltip.subscribe(data => {
        this.showExtraTags = data;
        this.changeDetector.markForCheck();
      })
    );
  }

  @HostListener("document:click", ["$event", "$event.target"])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = targetElement.id === "tags" || targetElement.className === "far fa-plus-circle fa-far fa-plus-circle"
      || targetElement.className === "color-picker__tag-info--header";
    this.isVisible = !clickedInside ? false : true;
    if (this.value) {
      if (targetElement.className === "ui-chips-token-label active") {
          this.value = this.value.map(x => {
            x.extra.isSelected = (x.extra.tagSourceId === TagSourceType.USER)
              || (x.extra.tagSourceId === TagSourceType.DATALOAD);
            return x;
          });
      } else {
          this.value = this.value.map(x => {
            x.extra.isSelected = false;
            this.chipClass = "ui-chips-token-label";
            return x;
          });
      }
    }

    const clickOnToggleIcon = targetElement.className === "fas fa-ellipsis-h";
    if (clickOnToggleIcon) {
      this.showExtraTags = true;
      this.activeToggle = true;
    } else {
      this.showExtraTags = false;
      this.activeToggle = false;
    }

    const clickOnLabel = targetElement.className === "ui-chips-token-label active";
    if (clickOnLabel && targetElement.offsetParent.classList.contains("inExtraTag")) {
         this.showExtraTags = true;
    }
  }

  getTagsById(): void {
    this.tagService.getTagsByObjectId(this.objectId, this.tagType)
      .subscribe(data => {
        this.value = data;
        this.showTagsEllipsesIcon = this.value.length > 4;
        this.changeDetector.markForCheck();
      });
  }

  getTagIcon(iconName: string): string {
    return iconName === "add" ? "far fa-plus-circle" : "ui-chips-token-icon pi pi-fw pi-times";
  }

  addTags(value: boolean): void {
    this.isVisible = value;
  }

  removeItem(event: Event, index: number, selectedTagValue: number): void {
      const removedItem = this.value[index];
      this.value = this.value.filter((val, i) => i !== index);
      this.onModelChange(this.value);
      this.tagService.deleteTag(selectedTagValue, this.objectIds)
        .subscribe(result => {
          if (result) {
            this.onRemove.emit({
              originalEvent: event,
              value: removedItem,
            });
            this.getTimelineBasedOnTagType();
            if (this.tagType === TagType.CHASE) {
              this.onUpdate.emit();
            }
            this.messagingService.showToast("Tag deleted successfully.", SeverityType.SUCCESS);
          }
        });
      this.showTagsEllipsesIcon = this.value.length > 4;
      this.showExtraTags = this.showTagsEllipsesIcon;
      this.changeDetector.markForCheck();
  }

  getClassForPlusIcon(): string {
    const styleClass = "tag-icon";
    const unClickableClass = "tag-unclickable";
    return this.isVisible ? unClickableClass : styleClass;
  }

  onItemClick(): void {
    if (this.hasTagManagement) {
      if (!this.selectedObjectId) {
        this.selectedObjectId = this.lastactiveObjectId;
      }
      this.value = this.value.map(x => {
        x.extra.isSelected = (x.extra.tagSourceId === TagSourceType.USER)
          || (x.extra.tagSourceId === TagSourceType.DATALOAD);
        return x;
      });
      this.chipClass += " active";
      if (this.createTagComponent) {
        this.isCreateTagTemplateVisible = false;
        this.createTagComponent.hideCreateTagTemplate(this.isCreateTagTemplateVisible);
      }
      this.outputObjectId.emit(this.objectId);
      this.isVisible = false;
    }
  }

  addedTag(recentlyAddedTag: SelectableInput): void {
    this.value = [...this.value, recentlyAddedTag.value];
    this.changeDetector.markForCheck();
  }

  getClassForSelect(): string {
    let iconStyle = "";
    if (this.isTagsAvailable && this.isVisible) {
       iconStyle = "tag-select";
    } else if (!this.isTagsAvailable && this.isVisible) {
      iconStyle = "tag-select-item";
    }
    return iconStyle;
  }

  getCharacterLength(): string {
    let hoverClassForLabel = "ui-chips-token-label";
    if (ArrayHelper.isAvailable(this.value)) {
      this.value.forEach(items => {
        const charLength = items.text.length;
        if (charLength >= 20) {
          return hoverClassForLabel += " :hover";
        }
      });
    }
    return "hide";
  }

  getCharactersCount(value: string): string {
    return value.length > 12 ? value.toUpperCase() : "";
  }

  visibleChange(data) {
    this.isVisible = data;
  }

  getCurrentIndex(index) {
    this.currentIndex = index;
  }

  private getTimelineBasedOnTagType(): void {
    switch (this.tagType) {
      case TagType.MASTERDOCUMENTSOURCE:
        this.fetchAddressTimelineItems(this.objectId);
        break;

      default:
        break;
    }
  }

  private fetchAddressTimelineItems(addressId: number): void {
    this.addressTimelineService
      .get(addressId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  getClassForNewTagIcon(): string {
    const iconClass = "tag-list__add-icon";
    return iconClass;
  }

  ngOnDestroy(): void {
    this.tagStateService.clearData();
    this.sink.unsubscribe();
  }
  showOtherTags() {
    this.showExtraTags = !this.showExtraTags;
  }
}
