import { FormGroup } from "@angular/forms";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicInput, IDynamicInputOptions } from "../dynamic-input.model";
import { SelectableInput } from "../selectable-input.model";
import { ProviderDropdownComponent } from "./provider-dropdown.component";

export interface IProviderDropdown extends IDynamicInputOptions {
  placeholder?: string;
  options?: SelectableInput[];
}

export class ProviderDropdown extends DynamicInput implements IProviderDropdown {
  placeholder: string;
  options: SelectableInput[];

  readonly controlType = "dropdown";
  readonly componentType = ProviderDropdownComponent;

  constructor(options: IProviderDropdown = {}) {
    super(options);
    this.placeholder = StringHelper.clean(options.placeholder);
    this.options = ArrayHelper.clean(options.options);
  }


  getValue(form: FormGroup): string {
    if (form == null) {
      throw new Error("No form is present");
    }

    const value = form.get(this.getMasterKey()).value;
    return value;
  }

  getSelectedOption(form: FormGroup): SelectableInput {
    const value = this.getValue(form);
    return StringHelper.isAvailable(value) || NumberHelper.isAvailable(value as any)
      ? this.options.find(option => option.value === value)
      : null;
  }
}
