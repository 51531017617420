<div class="stat-container">
  <img class="stat-container__item header-icon" src="assets/icons/assignment_late.svg" />
    <div class="stat-container__item text-ellipse" [title]="specialHandlingTitle">
      <span class="bold">SPECIAL HANDLING: {{specialHandlingReason}}</span>
    </div>
  <div class="stat-container__item text-ellipse" [title]="specialHandlingAdditionalNote">
    <span class="bold">{{specialHandlingNote}}</span>
  </div>

</div>
