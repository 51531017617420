<app-modal class="chase-assignemnt-container" [(visible)]="visible" header="Assign Chase(s) To">
  <p *ngIf="isChaseAssigned" class="helper-text">Please Note: Chase(s) are currently assigned to another user.</p>
  <form [formGroup]="usersForm">
    <form-factory [formGroup]="usersForm" [model]="usersInput"></form-factory>
    <form-factory  *ngIf="showMaximumChaseDropDown" [formGroup]="usersForm" [model]="maximumChasesInput" (onChange)="onMaximumChaseChange($event)"></form-factory>
  </form>
  <footer>
    <app-button class="header-button" text="CONTINUE" (onClick)="checkIfMemberCentricChase()" *ngIf="!isEmrOrFt"
    [disabled]="!hasSelectedUser"></app-button>
  <app-button class="header-button" text="ASSIGN" (onClick)="assignSelectedChasesOnly()" *ngIf="isEmrOrFt"
    [disabled]="!hasSelectedUser"></app-button>
  </footer>
</app-modal>

<app-confirmation-modal [(visible)]="isMemberCentricAssignmentModalVisible" header="CHASE ASSIGNMENT"
  acceptText="Yes, assign all member chases" rejectText="No, only assign my selection"
  (onYes)="assignMemberCentricChases()" (onCancel)="assignSelectedChasesOnly()"
                        class="member-centric-assignment text-center">
  There are {{ totalMemberChasesCount }} additional Member chases available for assignment.
  <br />
  Would you also like to assign these?
  <footer>
    <app-button classes="secondary" text="CANCEL" (onClick)="hideMemberCentricAssignmentModal()"
    [disabled]="!hasSelectedUser"></app-button>
  </footer>

</app-confirmation-modal>


