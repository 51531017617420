import { DynamicGroup } from "../../dynamic-group.model";
import { CalculatedGroup, ICalculatedGroupOptions } from "../calculated-group.model";
import { DeliveryGroupComponent } from "./delivery-group.component";

export interface IDeliveryGroupOptions extends ICalculatedGroupOptions { }

export class DeliveryGroup extends CalculatedGroup implements IDeliveryGroupOptions {
  readonly controlType = "delivery-group";
  readonly componentType = DeliveryGroupComponent;


  constructor(options: IDeliveryGroupOptions = {}) {
    super(options);
  }
}
