import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ChartModule } from "primeng/chart";
import { DropdownModule } from "primeng/dropdown";
import { DynamicFormsModule } from "../../dynamic-forms/dynamic-forms.module";
import { ButtonModule } from "../button/button.module";
import { GridModule } from "../grid/grid.module";
import { KpiDetailsComponent } from "./kpi-details/kpi-details.component";
import { LandingFilterComponent } from "./kpi-filter/landing-filter/landing-filter.component";
import { KpiComponent } from "./kpi/kpi.component";

@NgModule({
  imports: [
    CommonModule,
    DynamicFormsModule,
    FormsModule,
    ChartModule,
    DropdownModule,
    GridModule,
    ButtonModule,
  ],
  declarations: [
    KpiComponent,
    KpiDetailsComponent,
    LandingFilterComponent,
  ],
  exports: [
    KpiComponent,
    FormsModule,
    KpiDetailsComponent,
    LandingFilterComponent,
  ],
})
export class KpiModule { }
