import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SelectableInput } from "../selectable-input.model";
import { Confirm } from "./confirm.model";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: [
    "../dropdown/dropdown.component.scss",
    "../textarea/textarea.component.scss",
    "../textbox/textbox.component.scss",
    "./confirm.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent extends DynamicControlDirective<Confirm> implements OnInit, OnDestroy {
  private sink = new SubSink();
  private unsubscribe = new Subject();
  selectedItem: string;
  validationOptions: SelectableInput[];
  measureCode: string;
  hidePageNumber = false;
  hideAdminValue = false;
  pageNumberPlaceholder = "Page Number";
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly chaseDetailStateService: ChaseDetailStateService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        const memberValidationEntityTypeId = 91;
        const subcriberIndicatorCode = 273;
        this.measureCode = state.measureCode;
        if ((this.measureCode === "RXCM" || "RXCP")  && this.model.saveInfo.entityTypeId === memberValidationEntityTypeId) {
          this.hidePageNumber = true;
        }
        if ((this.measureCode === "ENR")  && this.model.saveInfo.attributeId === subcriberIndicatorCode) {
          this.hidePageNumber = true;
          this.hideAdminValue = true;
        }
        if (this.measureCode === "ENR") {
          this.pageNumberPlaceholder = "Page #";
        }
        this.changeDetector.markForCheck();
      })
    );


    this.control.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());

    this.formService.updateDom
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.changeDetector.markForCheck());


    this.validationOptions = [
      new SelectableInput({ text: "Yes", value: 1 }),
      new SelectableInput({ text: "No", value: 2 }),
      new SelectableInput({ text: "Yes - With Transformation", value: 3 }),
    ];

    if (this.model == null) {
      this.model = {} as any;
    }

    if (this.model.saveInfo == null) {
      this.model.saveInfo = {} as any;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  get hasLabel(): boolean {
    return StringHelper.isAvailable(this.model.label);
  }

  get classes(): any {
    return this.getClasses("confirm");
  }

  get hiddenNote(): boolean {
    return this.hidden || this.model.saveInfo == null || this.model.saveInfo.validationId == null || this.model.saveInfo.validationId === 1;
  }


  validationChange(): void {
    if (StringHelper.isAvailable(this.model.adminValue) && this.model.saveInfo.validationId === 1) {
      this.control.setValue(this.model.adminValue);
    }
  }

  onChangeEvent(event): void {
    const saveInfo = this.formService.getApiObject(this.control);
    this.control.setValue(saveInfo.value);
    this.onChange.emit(event);
  }

  validateInput(event: Event): void {
    if (this.measureCode === "ENR") {
      const inputElement = event.target as HTMLInputElement;
      const value = inputElement.value;

      if (value.length > 6) {
        inputElement.value = value.slice(0, 6);
        this.model.saveInfo.pageNumber = Number(inputElement.value);
      }
    }
  }

}
