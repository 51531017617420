export enum WorkflowAction {
  New = 0,
  InProgress = 1,
  Completed = 2,
  Failed = 3,
  Cancelled = 4,
  Hold = 5,
  Retry = 6,
  Validated = 7,
  MemberValidation = 8,
  Assigned = 9,
  ChangeState = 10,


}
