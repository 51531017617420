import { RiskDxValidationDiagnosis } from "./risk-dx-validation-diagnosis.model";

export class RiskDxValidation {
  chaseId: number;
  dxActionRequests: RiskDxValidationDiagnosis[];

  constructor(chaseId: number, dxActionRequests: RiskDxValidationDiagnosis[]) {
    this.chaseId = chaseId;
    this.dxActionRequests = dxActionRequests;
  }
}
