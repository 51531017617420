import { StringHelper } from "../../../../../utilities/contracts/string-helper";

export class UpdatePend {
  readonly chasePendId: number;
  readonly chasePendIds: number[];
  readonly assignedToUserId: number;
  readonly notes: string;
  readonly ownedBy: string;
  readonly pendStatusId: number;
  readonly chaseId: number;
  readonly owner: string;
  readonly amount?: number;
  readonly pendCompanyId?: number;
  readonly invoiceNumber?: string;
  readonly pendSeverityId?: number;
  readonly pendReasonId?: number;

  constructor(options: {
    chasePendId: number;
    chasePendIds: number[];
    assignedToUserId: number;
    notes: string;
    ownedBy: string;
    pendStatusId: number;
    chaseId: number;
    owner: string;
    amount: number;
    pendCompanyId: number;
    invoiceNumber: string;
    pendSeverityId?: number;
    pendReasonId?: number;

  }) {
    this.chasePendId = options.chasePendId;
    this.chasePendIds = options.chasePendIds;
    this.assignedToUserId = options.assignedToUserId;
    this.notes = StringHelper.clean(options.notes);
    this.ownedBy = StringHelper.clean(options.ownedBy);
    this.pendStatusId = options.pendStatusId;
    this.chaseId = options.chaseId;
    this.owner = StringHelper.clean(options.owner);
    this.amount = options.amount;
    this.pendCompanyId = options.pendCompanyId;
    this.invoiceNumber = options.invoiceNumber;
    this.pendSeverityId = options.pendSeverityId;
    this.pendReasonId = options.pendReasonId;
  }
}
