import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ServiceOrgLogoResponseModel } from "./service-org-logo-response.model";

@Injectable({
    providedIn: "root",
})

export class LogoDataSharingService {

    logoUrl = new BehaviorSubject<ServiceOrgLogoResponseModel>(null);
    constructor() {}

    setLogoUrl(data: ServiceOrgLogoResponseModel) {
        this.logoUrl.next(data);
    }

    getLogoUrl(): Observable<ServiceOrgLogoResponseModel> {
        return this.logoUrl.asObservable();
    }

    clearLogoUrl() {
        this.logoUrl.unsubscribe();
    }
}
