import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FormService } from "../../dynamic-forms/form.service";
import { Radiobutton } from "../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { AuthService } from "../auth.service";
import { MfaType } from "../mfa-type.enum";

@Component({
  selector: "app-mfa",
  templateUrl: "./mfa.component.html",
  styleUrls: ["./mfa.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfaComponent implements OnInit {
  form: FormGroup;
  refresh = false;
  contactValue = "";
  mfaCodeSent = false;
  mfaType: Radiobutton;
  mfaCode: Textbox;
  userId: number;

  constructor(
    private readonly authService: AuthService,
    private readonly formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {

    this.userId = this.authService.tempUserTokenForMfa.userId;

    if (!NumberHelper.isAvailable(this.userId)) {
      this.router.navigate(["login"]);
    }

    this.createForm();
    this.sendCode();
  }

  setContactToDisplay(mfaType: number) {
    const contact = mfaType === MfaType.Email ? this.authService.tempUserTokenForMfa.emailAddress : this.authService.tempUserTokenForMfa.phoneNumber;
    this.contactValue = mfaType === MfaType.Email ? `*****${contact.substring(contact.indexOf("@") + 1, contact.length)}` : `******"${contact.substring(contact.length - 4, contact.length)}`;
    this.changeDetector.markForCheck();
  }

  private createForm(): void {

    this.mfaType = new Radiobutton({
      key: "mfaType",
      value: MfaType.Email,
      options: [
        { text: "Email", value: MfaType.Email },
      ],
    });

    this.mfaCode = new Textbox({
      key: "mfaCode",
      label: "Code",
      placeholder: "Enter Code",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Code is required.",
      },
    });

    this.form = this.formService.createFormGroup([this.mfaType, this.mfaCode]);

  }

  trackByIndex(index, item) {
    return index;
  }

  get isValidCode(): boolean {
    return StringHelper.isAvailable(this.form.get("mfaCode").value) &&
     NumberHelper.isGreaterThan(this.form.get("mfaCode").value.length, 6, true);
  }

  verifyCode(): void {
    const code = this.form.get("mfaCode").value;
    const mfaType = this.form.get("mfaType").value;
    this.authService.verifyCode(this.userId, code, mfaType);
    this.changeDetector.markForCheck();
  }

  sendCode(): void {
    const mfaType = this.form.get("mfaType").value;
    this.setContactToDisplay(mfaType);
    this.authService.sendCode(this.userId, mfaType)
      .subscribe(() => {
        this.mfaCodeSent = true;
        this.changeDetector.markForCheck();
      });
  }

}
