import { ProjectAttribute } from "./project-attribute.model";

export class ProjectConfigSummary {
  projectId: number;
  projectConfigurationAttributes: ProjectAttribute[];

  constructor(options: {
      projectId: number;
      projectConfigurationAttributes: ProjectAttribute[];
  }) {
      this.projectId = options.projectId;
      this.projectConfigurationAttributes = options.projectConfigurationAttributes || [];
  }

  get hasProjectConfigurationAttributes(): boolean {
    return Array.isArray(this.projectConfigurationAttributes) && this.projectConfigurationAttributes.length > 0;
  }

}
